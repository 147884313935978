/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "../../AuthStyling";
import CloseMenu from "../menu/closeMenu";

class Locations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidMount() {
    console.log("Locations page");
  }

  render() {
    return (
      <div>
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <div onClick={this.props.menuCollapsedBool}>
            <CloseMenu />
          </div>
          <div className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-10">
                  <h1 className="m-0">Locations Page</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCurrentUser: state.getCurrentUser.userData,
    getLocations: state.getLocations,
    menuCollapsed: state.menuCollapsed.menuCollapsed,
    locationsApi: state.locationsApi
  };
};

const mapDispachToProps = (dispach) => {
  return {
    menuCollapsedBool: () => dispach({ type: "MENU_COLLAPSE" }),
  };
};

export default connect(mapStateToProps, mapDispachToProps)(Locations)
