/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import BarChart from './locationBarStatus'
import GoogleMaps from '../googleMaps/mapsLayout'
import locationsStying from './locationStyling'

class LocationLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isState: true,
        };
    }

    toggleStateOrCity = () => {
        const { citySelected, stateSelected } = this.props
        const { isState } = this.state

        if (isState) {
            this.setState({
                isState: !isState
            })
        } else {
            //  reset selected state and city when moving back to states list
            stateSelected('')
            citySelected('')

            this.setState({
                isState: !isState
            })
        }
    }

    render() {
        const { isState } = this.state

        return (
            <div>
                <div>
                    <div className="row">
                        <div className="col">
                            <div className="container">
                                <BarChart toggleStateOrCity={() => this.toggleStateOrCity()} isState={isState} />
                            </div>
                        </div>
                        <div className="col" style={locationsStying.googleMapsMapSize}>
                            <div className="container">

                                <GoogleMaps />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        getCurrentUser: state.getCurrentUser.userData,
        getLocations: state.getLocations,
        badPickUpGoogleSheet: state.badPickUpGoogleSheet,
        badPickupReport: state.badPickupReport,
        locationsApi: state.locationsApi
    };
};

const mapDispachToProps = (dispach) => {
    return {
        stateSelected: (state) => { dispach({ type: 'STATE_SELECTED', data: state }) },
        citySelected: (state) => { dispach({ type: 'CITY_SELECTED', data: state }) }
    };
}

export default connect(mapStateToProps, mapDispachToProps)(LocationLayout);