/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import StaticPieChartContainer from './pieChart/StaticPieChartContainer'
import BadPickupStatusList from './pieChart/badPickupStatusList'
import graphLayoutStyling from './graphLayoutStyling'
import { ToggleButton } from "../badPickupReport/dayWeekButton";
import { NavLink } from "react-router-dom";

const componentStyles = {
  pieContainer: {
    width: '50%',
    height: '50%',
    paddingLeft: '20%'
  },
  statusInfoPadding: {
    paddingLeft: "4.5%",
  },
  percentageStatusPadding: {
    paddingTop: "4%"
  }
}

class graphLayoutDashBoardDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dailyReportSelected: true,
      todaysPickupIssues: {},
      WeeksPickupIssues: {},
      pageLoading: true,
      pieClicked: false,
      redirect: null
    };
  }

  async componentDidMount() {
    const badPickupReport = await this.props.badPickupReport

    if (badPickupReport) {
      this.setState({
        todaysPickupIssues: badPickupReport.todaysPickupIssues,
        WeeksPickupIssues: badPickupReport.WeeksPickupIssues,
        pageLoading: !this.state.pageLoading
      })
    }

  }

  toggleReport = () => {
    this.setState({
      dailyReportSelected: !this.state.dailyReportSelected
    })
  }

  render() {
    const { dailyReportSelected, todaysPickupIssues, WeeksPickupIssues, activeIndex, pageLoading } = this.state

    return (
      <div>
        <div className="container" style={componentStyles.statusInfoPadding}>

          <div className="row" style={graphLayoutStyling.buttonLocation}>
            <div className="col" style={graphLayoutStyling.BadPickupReportTitleDashboard}>
              <div>
                {` Bad pick up report`}
              </div>
            </div>
            <div className="col">
            </div>
            <div className="col" >
              <div className='container'>
                <ToggleButton selected={dailyReportSelected} toggleSelected={() =>
                  this.toggleReport()
                } />
              </div>
            </div>
          </div>

          <div>
            <div className="row" >
              <div className="col-8" style={componentStyles.pieContainer}>
                <div>
                  < NavLink
                    href="#"
                    to="/badpickupreport"
                    exact
                    style={{ color: 'black' }
                    }
                  >
                    <StaticPieChartContainer
                      pickupIssues={dailyReportSelected ? todaysPickupIssues : WeeksPickupIssues}
                    />
                  </NavLink >
                </div>
              </div>

              <section className="col-4">
                <br />
                <div>
                  <div style={graphLayoutStyling.angWeeklyDailyTitleFont}>Daily Average</div>
                  <div style={graphLayoutStyling.avgOutputReport}>
                    {
                      !pageLoading
                        ? dailyReportSelected
                          ? `${todaysPickupIssues.avg} reports`
                          : `${WeeksPickupIssues.avg} reports`
                        : '...loading'
                    }
                  </div>
                </div>

                <div className="">
                  <br />
                  <div >
                    <div>
                      <BadPickupStatusList
                        pickupIssues={dailyReportSelected ? todaysPickupIssues : WeeksPickupIssues}
                        activeIndexHover={activeIndex}
                      />
                    </div>
                  </div>
                </div>
              </section>

            </div>

          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCurrentUser: state.getCurrentUser.userData,
    getLocations: state.getLocations,
    badPickUpGoogleSheet: state.badPickUpGoogleSheet,
    badPickupReport: state.badPickupReport,
    locationsApi: state.locationsApi,
    selectedPickupReport: state.selectedPickupReport
  };
};


export default (connect(mapStateToProps)(graphLayoutDashBoardDisplay));