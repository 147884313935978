/* eslint-disable */
import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

const RADIAN = Math.PI / 180;

const renderActiveShape = props => {
    const RADIAN = Math.PI / 180;

    const {
        cx,
        cy,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        midAngle
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius - 100) * cos;
    const sy = cy + (outerRadius - 100) * sin;

    return (
        <Sector
            cx={sx}
            cy={sy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill="orange"
        />
    );
};

class PieChartContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: '',
            reportHovered: '',
            pieClicked: false
        }
    }
    // https://medium.com/@dynamicsa420/react-recharts-for-beginners-24b0c8bfb676

    renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, index,
    }) => {

        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    onMouseOver = (index, data) => {
        this.props.selectedPickupReportHandle(data.name)
        this.setState({
            activeIndex: index,
            reportHovered: data.name
        })
    }
    onMouseLeave = (data) => {
        this.props.selectedPickupReportHandle()
        this.setState({
            activeIndex: '',
            reportHovered: ''
        })
    }

    pieClicked = (data) => {
        this.setState({
            pieClicked: false
        })
    }

    render() {
        const { pickupIssues } = this.props
        const { activeIndex } = this.state

        let dataArr = []

        for (let key in pickupIssues) {
            if (key !== 'total' && key !== 'avg' && key !== 'locationReportTracker') {
                dataArr.push({
                    name: key,
                    value: parseFloat(pickupIssues[key])
                })
            }
        }


        const COLORS = ['rgba(252, 200, 22, 0.8)', "rgba(252, 200, 22, 0.6)", "#FCC816"];

        return (
            <ResponsiveContainer width="100%" height={400} >
                <PieChart width={400} height={400} >
                    <Pie
                        data={dataArr}
                        cx={220}
                        cy={150}
                        labelLine={false}
                        label={this.renderCustomizedLabel}
                        outerRadius={150}
                        fill="#8884d8"
                        dataKey="value"
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        onMouseLeave={() => this.onMouseLeave()}
                    >
                        {
                            dataArr.map((entry, index) =>
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                    onMouseOver={() => this.onMouseOver(index, entry)}
                                />)
                        }
                    </Pie>
                </PieChart>
            </ResponsiveContainer >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedPickupReport: state.selectedPickupReport
    };
};

const mapDispachToProps = (dispach) => {
    return {
        selectedPickupReportHandle: (state) => { dispach({ type: 'REPORT_PICKUP_SELECTED', data: state }) },
    };
}

export default (connect(mapStateToProps, mapDispachToProps)(PieChartContainer));


// interactive pie
// https://codesandbox.io/s/so-answer-61684688-0q4un?file=/src/App.js:0-1755

