/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import locationsStying from './locationStyling'
import CityStatusBar from "./cityStatusBar";
import StateStatusBar from "./stateStatusBar";

class BarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dailyReportSelected: true,
            selectValue: ''
        }
    }

    toggleReport = (e) => {
        this.setState({
            selectValue: e.target.value,
            dailyReportSelected: !this.state.dailyReportSelected
        })
    }

    render() {
        const { isState, toggleStateOrCity } = this.props
        const { dailyReportSelected, selectValue } = this.state

        let selectedCityOrState = 'City'

        if (isState) {
            selectedCityOrState = 'States'
        }

        return (
            <div className='container'>
                <div className='container'>
                    <div className='container'>
                        <br />
                        <div className="row">
                            <div className="col-6" onClick={selectedCityOrState === 'States' ? null : toggleStateOrCity} style={locationsStying.StateOrCityTitleFont}>
                                <span style={{ fontSize: '70%' }} className='fas fa-chevron-left'></span>
                                {isState ? ' States' : ' City'}
                            </div>
                            <div className="col-6 text-right">
                                <select
                                    style={locationsStying.selectedBarChartReport}
                                    value={selectValue}
                                    onChange={this.toggleReport}
                                >
                                    <option value="today"> &nbsp; {`Today `} &nbsp;
                                    </option>
                                    <option value="week"> &nbsp; {`Week `} &nbsp;</option>
                                </select>
                            </div>
                        </div>
                        <br />
                    </div>
                    {isState
                        ? <StateStatusBar toggleStateOrCity={toggleStateOrCity} isState={isState} dailyReportSelected={dailyReportSelected} />
                        : <CityStatusBar isState={isState} />
                    }

                    <br />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        getCurrentUser: state.getCurrentUser.userData,
        getLocations: state.getLocations,
        badPickUpGoogleSheet: state.badPickUpGoogleSheet,
        badPickupReport: state.badPickupReport,
        locationsApi: state.locationsApi
    };
};

export default connect(mapStateToProps)(BarChart);


