
const { GoogleSpreadsheet } = require('google-spreadsheet');

const doc = new GoogleSpreadsheet(process.env.REACT_APP_GOOGLE_URL_ID);

doc.useServiceAccountAuth({
    client_email: process.env.REACT_APP_GOOGLE_CLIENT_EMAIL,
    private_key: process.env.REACT_APP_GOOGLE_PRIVATE_KEY
});


export async function badPickUpGoogleSheet() {
    await doc.loadInfo(); // loads document properties and worksheets

    const sheet = doc.sheetsByIndex[2]; // or use doc.sheetsById[id] or doc.sheetsByTitle[title]
    let rows = await sheet.getRows()

    let dataArray = []

    for (let i = 0; i < rows.length; i++) {
        let row = rows[i]._rawData
        dataArray.push({
            'dataTime': row[0] ? row[0] : '',
            'driver name': row[1] ? row[1] : '',
            'customerAddress': row[2] ? row[2] : '',
            'photo': row[3] ? row[3] : '',
            'driverNotes': row[4] ? row[4] : '',
            'pickupIssues': {
                'noRecyclingOut': row[6] ? row[6] : '',
                'cardboardNotFlattened': row[7] ? row[7] : '',
                'contaminated': row[8] ? row[8] : '',
                'bagsNotTied': row[9] ? row[9] : '',
                'glassNotSeparated': row[10] ? row[10] : '',
                'other': row[11] ? row[11] : '',
            },
            'recyclopsNotes': row[12] ? row[12] : '',
        })
    }

    return {
        spreadSheet: doc.title,
        data: dataArray
    }

}
