/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import dashboardStyles from '../../DashboardStyling'

class TopStatusBoxes extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const { numberOfStates } = this.props
    return (
      <div className="row">

        <div className="col-lg-3 col-6">
          <div style={dashboardStyles.head_tiles_green}>
            <div className='container' >
              <div className="row">
                <div className="col-6"
                  style={dashboardStyles.head_tiles_number}
                >
                  {`${numberOfStates}`}
                </div>
                <div className="col-6">
                  Graph goes here
                </div>
              </div>

              <div className="row">
                <div className="col-6"
                  style={dashboardStyles.head_tiles_label}
                >
                  Locations
                </div>
                <div className="col-6" >
                </div>
              </div>

              <div className="row">
                <div className="col-12 text-center"
                  style={dashboardStyles.head_tiles_percent_status}
                >
                  <div >
                    x% Increase from last month
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-6">

          <div style={dashboardStyles.head_tiles_green}>

            <div className='container' >
              <div className="row">
                <div className="col-6"
                  style={dashboardStyles.head_tiles_number}
                >
                  10K
                </div>
                <div className="col-6" >
                  Graph goes here
                </div>
              </div>

              <div className="row">
                <div className="col-6"
                  style={dashboardStyles.head_tiles_label}
                >
                  Customers
                </div>
                <div className="col-6" >

                </div>
              </div>

              <div className="row">
                <div className="col-12 text-center"
                  style={dashboardStyles.head_tiles_percent_status}
                >
                  x% Increase from last month
                </div>
              </div>

            </div>

          </div>
        </div>

        <div className="col-lg-3 col-6">

          <div style={dashboardStyles.head_tiles_red}>

            <div className='container' >
              <div className="row">
                <div className="col-6"
                  style={dashboardStyles.head_tiles_number}
                >
                  10K
                </div>
                <div className="col-6" >
                  Graph goes here
                </div>
              </div>

              <div className="row">
                <div className="col-6"
                  style={dashboardStyles.head_tiles_label}
                >
                  Customers
                </div>
                <div className="col-6" >

                </div>
              </div>

              <div className="row">
                <div className="col-12 text-center"
                  style={dashboardStyles.head_tiles_percent_status}
                >
                  x% Increase from last month
                </div>
              </div>

            </div>

          </div>
        </div>

        <div className="col-lg-3 col-6">
          <div style={dashboardStyles.head_tiles_green}>
            <div className='container' >
              <div className="row">
                <div className="col-6"
                  style={dashboardStyles.head_tiles_number}
                >
                  100
                </div>
                <div className="col-6">
                  icon goes here
                </div>
              </div>

              <div className="row">
                <div className="col-6"
                  style={dashboardStyles.head_tiles_label}
                >
                  <div style={{ paddingLeft: "5%" }}>Tons </div>
                </div>
                <div className="col-6" >
                </div>
              </div>

              <div className="row">
                <div className="col-12 text-center"
                  style={dashboardStyles.head_tiles_percent_status}
                >
                  Amount of material recycled
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCurrentUser: state.getCurrentUser.userData,
    getLocations: state.getLocations,
    menuCollapsed: state.menuCollapsed.menuCollapsed,
  };
};

export default connect(mapStateToProps)(TopStatusBoxes);
