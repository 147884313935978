/* eslint-disable */
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import Dashboard from "../dashboard/dashboard";
import Menu from "../menu/menu";

import ProfileLayout from '../profile/profileLayout'
import Settings from '../settings/settings'
import Locations from '../locations/locations'
import Logout from "../logout/logout";
import PageTemplate from '../page_template/template'

import dashboardStyles from '../dashboard/DashboardStyling'
import BadPickupReport from '../dashboard/display/badPickupReport/badPickupReport'

import { Auth } from 'aws-amplify';

import NotAnEmployee from '../non-employee/errorPage'

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileClicked: true,
      isEmployee: false
    };
  }

  componentDidMount = () => {
    const { isEmployee } = this.state
    // double checking if user is an Recyclops employee
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user.signInUserSession.accessToken.payload["cognito:groups"][1] === process.env.REACT_APP_EMPLOYEE_GROUP || user.signInUserSession.accessToken.payload["cognito:groups"][0] === process.env.REACT_APP_EMPLOYEE_GROUP) {
          this.setState({
            isEmployee: !isEmployee
          })
        }
      })
      .catch((err) => console.log('Error with logged in user. Not an employee', err));
  }

  render() {

    const { isEmployee } = this.state
    const { checkUser } = this.props

    return (
      <div className="wrapper">
        {isEmployee
          ? <Router>
            <section className="section">
              <Menu checkUser={checkUser} />
              <div className="" style={dashboardStyles.dashboard_background_color}>
                <Switch>
                  <Route exact path="/index.html" component={Dashboard} />
                  <Route exact path="/" component={Dashboard} />
                  <Route exact path="/profile" component={ProfileLayout} />
                  <Route exact path="/settings" component={Settings} />
                  <Route exact path="/locations" component={Locations} />
                  <Route exact path="/pagetemplate" component={PageTemplate} />
                  <Route exact path="/badpickupreport" component={BadPickupReport} />
                  <Route exact path="/logout" component={Logout} />
                </Switch>
              </div>
            </section>
          </Router>
          : <NotAnEmployee />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCurrentUser: state.getCurrentUser.userData
  };
};

export default connect(mapStateToProps)(Layout);
