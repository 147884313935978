const badPickupReportStying = {
  page_background_color: {
    backgroundColor: "#F5F5F5",
  },
  page_box_layout: {
    width: '172.5%',
    height: '97%',
    borderRadius: '20px 20px 20px 20px',
  },
  page_title_Font: {
    fontSize: '.9vw',
    fontWeight: "bold"
  },
  reportFont: {
    fontSize: '.9vw',
    color: '#8D8D8D',
    // fontWeight: 'normal' 
  },
  precentFont: {
    fontSize: '.8vw',
    fontWeight: "bold"
  },
  precentFontHovered: {
    // fontSize: '1vw',
    fontWeight: "bold",
    color: 'orange',
  },
  hoveredText: {
    color: 'orange',
    fontSize: '1vw',
  },
  pageDivider: {
    borderBottom: '1px solid #F4F1F1'
  }
};

export default badPickupReportStying