/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";


class CompanyStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        return (
            <div className=''></div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        getCurrentUser: state.getCurrentUser.userData
    };
};

export default connect(mapStateToProps)(CompanyStatus);
