const initialState = {
  pickupReportSelected: ''
}

const selectedPickupReport = (state = initialState, action) => {
  const newState = { ...state }


  if (action.type === 'REPORT_PICKUP_SELECTED') {
    newState.pickupReportSelected = action.data
    return newState
  }
  return newState

};


export default selectedPickupReport
