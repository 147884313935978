/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import badPickupReportStying from '../../badPickupReport/badPickupReportStying'

class BadPickupStatusList extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { pickupIssues, selectedPickupReport } = this.props

        let arr = []

        for (let key in pickupIssues) {
            if (key !== 'total' && key !== 'avg' && key !== 'locationReportTracker') {
                if (key === 'noRecyclingOut') {
                    arr.push({
                        key: 'noRecyclingOut',
                        name: ` No recycling out`,
                        value: pickupIssues[key] === 'NaN%' ? 0 : pickupIssues[key]
                    })
                }
                if (key === 'cardboardNotFlattened') {
                    arr.push({
                        key: 'cardboardNotFlattened',
                        name: ` Cardboard not flattened`,
                        value: pickupIssues[key] === 'NaN%' ? 0 : pickupIssues[key]
                    })
                }
                if (key === 'contaminated') {
                    arr.push({
                        key: 'contaminated',
                        name: ` Contaminated`,
                        value: pickupIssues[key] === 'NaN%' ? 0 : pickupIssues[key]
                    })
                }
                if (key === 'bagsNotTied') {
                    arr.push({
                        key: 'bagsNotTied',
                        name: ` Bags not tied`,
                        value: pickupIssues[key] === 'NaN%' ? 0 : pickupIssues[key]
                    })
                }
                if (key === 'glassNotSeparated') {
                    arr.push({
                        key: 'glassNotSeparated',
                        name: ` Glass not seperated`,
                        value: pickupIssues[key] === 'NaN%' ? 0 : pickupIssues[key]
                    })
                }
                if (key === 'other') {
                    arr.push({
                        key: 'other',
                        name: ` 0ther`,
                        value: pickupIssues[key] === 'NaN%' ? 0 : pickupIssues[key]
                    })
                }
            }
        }

        return (
            <div>
                {arr
                    ? arr.map((data, i) => {
                        return <div key={i} >
                            <span style={selectedPickupReport === data.key ? badPickupReportStying.precentFontHovered : badPickupReportStying.precentFont}>{`${data.value}%` ? data.value : 'n/a'}</span>
                            <span style={selectedPickupReport === data.key ? badPickupReportStying.hoveredText : badPickupReportStying.reportFont}>{`${data.name}`}</span>
                        </div>
                    })
                    : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedPickupReport: state.selectedPickupReport.pickupReportSelected
    };
};

export default (connect(mapStateToProps)(BadPickupStatusList));

