const buttonStying = {
    buttonContainer: {
        width: '50%',
        height: '59%'
    },
    buttonBorder: {
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: '#2A9B8D ',
        width: '9vw'
    },
    whiteFont: {
        color: 'white',
        fontSize: '.8vw'
    },
    greyFont: {
        color: 'grey',
        fontSize: '.8vw'
    },
    greenButton: {
        backgroundColor: '#2A9B8D'
    },
    whiteButton: {
        backgroundColor: 'white'
    }
};

export default buttonStying