/* eslint-disable */
import React, { Component } from "react";
import { Auth } from 'aws-amplify';

class NotAnEmployee extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        // setTimeout(() => { Auth.signOut() }, 300000);
    }

    render() {
        return (
            <div>
                {`Must be an employee of recyclops to access.`}
                <button onClick={() => Auth.signOut()}>Return to Login Page</button>
            </div>
        );
    }
}

export default NotAnEmployee