const stateBoundaries = [
    {
        "datasetid": "us-state-boundaries",
        "recordid": "63cdbd573345cfb26cf78c98bbf5161614566f2b",
        "fields": {
            "arealand": "348013711",
            "objectid": 35,
            "basename": "United States Virgin Islands",
            "stusab": "VI",
            "statens": "01802710",
            "centlon": -64.8387617,
            "state": "78",
            "gid": 13,
            "centlat": 18.0593788,
            "division": 0,
            "areawater": "1550244357",
            "oid": "-1857358206",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                -64.843729999672,
                                18.3937130003038
                            ],
                            [
                                -64.8306170004155,
                                18.3952020001978
                            ],
                            [
                                -64.8166740000574,
                                18.4029059996947
                            ],
                            [
                                -64.8003289998509,
                                18.4075949997621
                            ],
                            [
                                -64.7850010003439,
                                18.4038019999373
                            ],
                            [
                                -64.7780049999629,
                                18.386209000173
                            ],
                            [
                                -64.756923000007,
                                18.3766859999062
                            ],
                            [
                                -64.7438040000044,
                                18.3781310002826
                            ],
                            [
                                -64.7376500004207,
                                18.3762170003899
                            ],
                            [
                                -64.7298299996841,
                                18.3779449996133
                            ],
                            [
                                -64.727025000328,
                                18.3747250001871
                            ],
                            [
                                -64.7081970000824,
                                18.373742000052
                            ],
                            [
                                -64.7016040001075,
                                18.3706359999168
                            ],
                            [
                                -64.6832930000708,
                                18.3725669997141
                            ],
                            [
                                -64.6752489999265,
                                18.3674629998913
                            ],
                            [
                                -64.6684810000495,
                                18.3655769999245
                            ],
                            [
                                -64.6404370000478,
                                18.363997000316
                            ],
                            [
                                -64.6391440000621,
                                18.3551899997673
                            ],
                            [
                                -64.643689000191,
                                18.3441069997742
                            ],
                            [
                                -64.6377349997255,
                                18.3197589996555
                            ],
                            [
                                -64.6638549997298,
                                18.2853760001861
                            ],
                            [
                                -64.6613010004438,
                                18.2673589997501
                            ],
                            [
                                -64.6642589999609,
                                18.2612709996959
                            ],
                            [
                                -64.6782290000832,
                                18.2523160002306
                            ],
                            [
                                -64.6944889999008,
                                18.2487579998992
                            ],
                            [
                                -64.7079069996541,
                                18.2487249997681
                            ],
                            [
                                -64.720173999712,
                                18.2506900002989
                            ],
                            [
                                -64.7487359997476,
                                18.2630590000803
                            ],
                            [
                                -64.7577690001039,
                                18.2608550002774
                            ],
                            [
                                -64.7734199996511,
                                18.2605059997401
                            ],
                            [
                                -64.7828909996753,
                                18.2526930002856
                            ],
                            [
                                -64.8012889997506,
                                18.2451059996837
                            ],
                            [
                                -64.7992609999961,
                                18.2293219998289
                            ],
                            [
                                -64.8027730004303,
                                18.2133720003843
                            ],
                            [
                                -64.8068909998636,
                                18.2051629999707
                            ],
                            [
                                -64.8185169998639,
                                18.1920609998845
                            ],
                            [
                                -64.8333530001747,
                                18.1834559998114
                            ],
                            [
                                -64.849551999893,
                                18.1796829999945
                            ],
                            [
                                -64.8580350004284,
                                18.1795870003758
                            ],
                            [
                                -64.8725339995569,
                                18.1834839999737
                            ],
                            [
                                -64.8897519996245,
                                18.1942359996752
                            ],
                            [
                                -64.8997750000177,
                                18.2065249995874
                            ],
                            [
                                -64.9052399998634,
                                18.2185139997731
                            ],
                            [
                                -64.9061510002576,
                                18.2241929999785
                            ],
                            [
                                -64.921034000424,
                                18.2288429997134
                            ],
                            [
                                -64.9337140000354,
                                18.2379699999478
                            ],
                            [
                                -64.9420610001283,
                                18.2480099999376
                            ],
                            [
                                -64.9464720003629,
                                18.2572230000658
                            ],
                            [
                                -64.9609639998188,
                                18.2560599998827
                            ],
                            [
                                -64.9804139998553,
                                18.2569120001884
                            ],
                            [
                                -64.9978610002517,
                                18.252492999917
                            ],
                            [
                                -65.0198210000917,
                                18.2523549996564
                            ],
                            [
                                -65.0315650000928,
                                18.2562770001029
                            ],
                            [
                                -65.0485809995957,
                                18.2667770001428
                            ],
                            [
                                -65.0501940001468,
                                18.2691289998076
                            ],
                            [
                                -65.0577479998463,
                                18.2541629999811
                            ],
                            [
                                -65.0677319998815,
                                18.2439080002976
                            ],
                            [
                                -65.0809529999913,
                                18.2364819999318
                            ],
                            [
                                -65.10083099965,
                                18.2326290001953
                            ],
                            [
                                -65.1172670000931,
                                18.23477199986
                            ],
                            [
                                -65.1312199995987,
                                18.2417569995878
                            ],
                            [
                                -65.1426220001913,
                                18.2507890004071
                            ],
                            [
                                -65.1477229999889,
                                18.2590850003178
                            ],
                            [
                                -65.154117999597,
                                18.2774250002881
                            ],
                            [
                                -65.1536439999441,
                                18.2931389997995
                            ],
                            [
                                -65.1506989999476,
                                18.302844999769
                            ],
                            [
                                -65.1448089999545,
                                18.3128859999509
                            ],
                            [
                                -65.137688999725,
                                18.3205580003919
                            ],
                            [
                                -65.1399409997855,
                                18.3362909997264
                            ],
                            [
                                -65.1351379999137,
                                18.3508549999627
                            ],
                            [
                                -65.1303469997376,
                                18.3615369997632
                            ],
                            [
                                -65.1174269999267,
                                18.3802330002905
                            ],
                            [
                                -65.1130569997,
                                18.3957579996876
                            ],
                            [
                                -65.1137599996818,
                                18.4068750001337
                            ],
                            [
                                -65.112214999801,
                                18.418827
                            ],
                            [
                                -65.1004130000736,
                                18.4395440001859
                            ],
                            [
                                -65.091790999621,
                                18.4471019997825
                            ],
                            [
                                -65.0805670002019,
                                18.4528070003069
                            ],
                            [
                                -65.0606650002537,
                                18.4597469996518
                            ],
                            [
                                -65.0415220003638,
                                18.4591020001661
                            ],
                            [
                                -65.0172989996944,
                                18.4493229999302
                            ],
                            [
                                -65.007769000119,
                                18.4407029996101
                            ],
                            [
                                -64.9995900002555,
                                18.4455259999609
                            ],
                            [
                                -64.9823889999064,
                                18.4507079999187
                            ],
                            [
                                -64.9691820000403,
                                18.4509469999009
                            ],
                            [
                                -64.950455000077,
                                18.4462940001965
                            ],
                            [
                                -64.9426920001401,
                                18.4541570002839
                            ],
                            [
                                -64.9276419995691,
                                18.4619490000909
                            ],
                            [
                                -64.907594999856,
                                18.4649839999656
                            ],
                            [
                                -64.8950240000245,
                                18.4633640003554
                            ],
                            [
                                -64.8910329999146,
                                18.4621600003593
                            ],
                            [
                                -64.8739119999314,
                                18.4062549996746
                            ],
                            [
                                -64.8649759996594,
                                18.3959270002777
                            ],
                            [
                                -64.8599500002049,
                                18.3942250002094
                            ],
                            [
                                -64.843729999672,
                                18.3937130003038
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -64.9497139995621,
                                17.6532740000199
                            ],
                            [
                                -64.9550319999929,
                                17.6666109996554
                            ],
                            [
                                -64.956714000141,
                                17.6789769999429
                            ],
                            [
                                -64.9517999999412,
                                17.6992810003994
                            ],
                            [
                                -64.9389379998566,
                                17.7175339998102
                            ],
                            [
                                -64.9437309996826,
                                17.7291670001636
                            ],
                            [
                                -64.9468110002966,
                                17.7480279999914
                            ],
                            [
                                -64.9435990003364,
                                17.7626119997236
                            ],
                            [
                                -64.9341960000843,
                                17.7846789999902
                            ],
                            [
                                -64.9267870001497,
                                17.7949619996016
                            ],
                            [
                                -64.9058850001188,
                                17.8122030002453
                            ],
                            [
                                -64.8904820002687,
                                17.8183249999767
                            ],
                            [
                                -64.8692069999689,
                                17.8216280003833
                            ],
                            [
                                -64.8615660002308,
                                17.8209960000593
                            ],
                            [
                                -64.8421410003086,
                                17.8147499999488
                            ],
                            [
                                -64.8222429996601,
                                17.8269179997886
                            ],
                            [
                                -64.7980700001177,
                                17.8330549997094
                            ],
                            [
                                -64.7830749997983,
                                17.8345239999645
                            ],
                            [
                                -64.7676560000547,
                                17.8326440003392
                            ],
                            [
                                -64.7470720000411,
                                17.8351790001325
                            ],
                            [
                                -64.7335449996096,
                                17.8328430001111
                            ],
                            [
                                -64.72438499993,
                                17.8291669995975
                            ],
                            [
                                -64.7138329996322,
                                17.822598999946
                            ],
                            [
                                -64.7005250003821,
                                17.8091980002262
                            ],
                            [
                                -64.6975240003091,
                                17.808761000358
                            ],
                            [
                                -64.6861669999222,
                                17.815087999686
                            ],
                            [
                                -64.6820119997807,
                                17.8227149997795
                            ],
                            [
                                -64.6732780000735,
                                17.8315119996591
                            ],
                            [
                                -64.6523469997303,
                                17.8423829998216
                            ],
                            [
                                -64.6388810002964,
                                17.8448770001462
                            ],
                            [
                                -64.6151859997066,
                                17.8439850000191
                            ],
                            [
                                -64.5923129998226,
                                17.8373420000869
                            ],
                            [
                                -64.5671150000832,
                                17.8227909999835
                            ],
                            [
                                -64.5561910002397,
                                17.8105530000522
                            ],
                            [
                                -64.5298209999902,
                                17.7930029996255
                            ],
                            [
                                -64.5230609996108,
                                17.7850190001199
                            ],
                            [
                                -64.5160049998536,
                                17.7714439997264
                            ],
                            [
                                -64.5126740000677,
                                17.757141999896
                            ],
                            [
                                -64.5135999996323,
                                17.7480290001424
                            ],
                            [
                                -64.5202970002625,
                                17.7298630000647
                            ],
                            [
                                -64.5310160000666,
                                17.7159129998093
                            ],
                            [
                                -64.54874799997,
                                17.7030380002604
                            ],
                            [
                                -64.5609639999742,
                                17.6979670003823
                            ],
                            [
                                -64.5769879997903,
                                17.6945159996938
                            ],
                            [
                                -64.6068229997201,
                                17.677105000136
                            ],
                            [
                                -64.6183330002678,
                                17.6729129997571
                            ],
                            [
                                -64.6336859998892,
                                17.6646430003421
                            ],
                            [
                                -64.6468359998539,
                                17.6602380003955
                            ],
                            [
                                -64.6611859999178,
                                17.6593139996079
                            ],
                            [
                                -64.6878340000017,
                                17.6508030001987
                            ],
                            [
                                -64.7081379996328,
                                17.6475369997437
                            ],
                            [
                                -64.7165590002439,
                                17.6435039999161
                            ],
                            [
                                -64.7374589997267,
                                17.6381380001818
                            ],
                            [
                                -64.7564359999352,
                                17.6306460002304
                            ],
                            [
                                -64.7727459999816,
                                17.6299849996346
                            ],
                            [
                                -64.7888610000343,
                                17.6258440001103
                            ],
                            [
                                -64.801686000309,
                                17.6246950001326
                            ],
                            [
                                -64.811528000054,
                                17.625686999657
                            ],
                            [
                                -64.8256999998427,
                                17.6315439997397
                            ],
                            [
                                -64.843841999825,
                                17.6301039999026
                            ],
                            [
                                -64.8655630001886,
                                17.6353289998433
                            ],
                            [
                                -64.8767959998285,
                                17.6293800001293
                            ],
                            [
                                -64.8925250000919,
                                17.6242300004035
                            ],
                            [
                                -64.9003609998237,
                                17.6234679997523
                            ],
                            [
                                -64.9159140003599,
                                17.6252309997247
                            ],
                            [
                                -64.9258380001206,
                                17.6290430002306
                            ],
                            [
                                -64.9351710000526,
                                17.6351859997286
                            ],
                            [
                                -64.9497139995621,
                                17.6532740000199
                            ]
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 18.3267484,
            "name": "United States Virgin Islands",
            "intptlon": -64.9712505,
            "geo_point_2d": [
                18.0599451556,
                -64.8390395698
            ],
            "geoid": "78",
            "mtfcc": "G4000",
            "region": 9
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -64.8390395698,
                18.0599451556
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "dcc8cf4b67f78f2f31d94ec8993418ffffdf93c7",
        "fields": {
            "arealand": "-1459974156",
            "objectid": 16,
            "basename": "Wisconsin",
            "stusab": "WI",
            "statens": "01779806",
            "centlon": -89.7097713,
            "state": "55",
            "gid": 50,
            "centlat": 44.6273217,
            "division": 3,
            "areawater": "-703725080",
            "oid": "317035270",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -92.8870669998142,
                            45.644148000243
                        ],
                        [
                            -92.882504000142,
                            45.6594709999592
                        ],
                        [
                            -92.8789320003316,
                            45.6656060003126
                        ],
                        [
                            -92.8754879996695,
                            45.6890140000094
                        ],
                        [
                            -92.8701450000226,
                            45.6967569997031
                        ],
                        [
                            -92.8717750002922,
                            45.6997740001271
                        ],
                        [
                            -92.8688620000827,
                            45.7119929997078
                        ],
                        [
                            -92.8691929996205,
                            45.7175679997961
                        ],
                        [
                            -92.8625979999958,
                            45.7222409998213
                        ],
                        [
                            -92.8509329996374,
                            45.7238309999597
                        ],
                        [
                            -92.8488510003546,
                            45.7287509999304
                        ],
                        [
                            -92.8289810001934,
                            45.7337140001747
                        ],
                        [
                            -92.8165590003249,
                            45.7420369999782
                        ],
                        [
                            -92.8098369995803,
                            45.7441720001411
                        ],
                        [
                            -92.8049419996471,
                            45.7479159998752
                        ],
                        [
                            -92.8026300002104,
                            45.751887999756
                        ],
                        [
                            -92.7846170000996,
                            45.7641989997565
                        ],
                        [
                            -92.7764819997187,
                            45.7900039998289
                        ],
                        [
                            -92.7720649996364,
                            45.7952299998324
                        ],
                        [
                            -92.761832999904,
                            45.8012580000347
                        ],
                        [
                            -92.7578150000299,
                            45.8065739999136
                        ],
                        [
                            -92.7579470002744,
                            45.8112159999288
                        ],
                        [
                            -92.7656809998989,
                            45.8272519999053
                        ],
                        [
                            -92.7651460001466,
                            45.8301830001258
                        ],
                        [
                            -92.7594579998199,
                            45.835341000248
                        ],
                        [
                            -92.7510839999628,
                            45.8406169996967
                        ],
                        [
                            -92.7455570001929,
                            45.8414549997674
                        ],
                        [
                            -92.7399910000649,
                            45.8462829999409
                        ],
                        [
                            -92.7361170001308,
                            45.8591290000527
                        ],
                        [
                            -92.7382200002284,
                            45.8620309998893
                        ],
                        [
                            -92.7222089999327,
                            45.8830009998221
                        ],
                        [
                            -92.7125029997319,
                            45.8917049996886
                        ],
                        [
                            -92.7063719997144,
                            45.8954449999269
                        ],
                        [
                            -92.6998149997246,
                            45.8961469998667
                        ],
                        [
                            -92.6773519999194,
                            45.9058179996999
                        ],
                        [
                            -92.6757369997184,
                            45.9074779999995
                        ],
                        [
                            -92.6761669998887,
                            45.9120720000179
                        ],
                        [
                            -92.6595489998708,
                            45.9229370002274
                        ],
                        [
                            -92.6555579997608,
                            45.9245860000511
                        ],
                        [
                            -92.6391199996679,
                            45.9245580001445
                        ],
                        [
                            -92.6401149997278,
                            45.932477999738
                        ],
                        [
                            -92.6388240002902,
                            45.9341659998924
                        ],
                        [
                            -92.6292600003796,
                            45.9324039997268
                        ],
                        [
                            -92.6227200001082,
                            45.9351860000908
                        ],
                        [
                            -92.6143139995657,
                            45.934528999888
                        ],
                        [
                            -92.6024599999597,
                            45.9408150002873
                        ],
                        [
                            -92.5895279995549,
                            45.9417819998003
                        ],
                        [
                            -92.5748920001591,
                            45.9481029998418
                        ],
                        [
                            -92.5519330000613,
                            45.951650999797
                        ],
                        [
                            -92.5498579995529,
                            45.9570389998835
                        ],
                        [
                            -92.5498059996743,
                            45.9679860001963
                        ],
                        [
                            -92.5377090003941,
                            45.9778180001731
                        ],
                        [
                            -92.527051999616,
                            45.9832450001407
                        ],
                        [
                            -92.5194879998708,
                            45.9839169999617
                        ],
                        [
                            -92.5025350001171,
                            45.9799949998502
                        ],
                        [
                            -92.4909960001554,
                            45.9755599997225
                        ],
                        [
                            -92.4697650002365,
                            45.9735690001656
                        ],
                        [
                            -92.4615839998249,
                            45.9788939999129
                        ],
                        [
                            -92.464512000103,
                            45.9850380002164
                        ],
                        [
                            -92.4624769997775,
                            45.9878500001769
                        ],
                        [
                            -92.4533730001744,
                            45.9929130002301
                        ],
                        [
                            -92.4529520002249,
                            45.9977819998409
                        ],
                        [
                            -92.4442939997873,
                            46.0091610001187
                        ],
                        [
                            -92.4422590003601,
                            46.0161769998427
                        ],
                        [
                            -92.4285550003766,
                            46.0242409998322
                        ],
                        [
                            -92.4118960003508,
                            46.0274780003018
                        ],
                        [
                            -92.3926809995925,
                            46.0195400002486
                        ],
                        [
                            -92.37271699972,
                            46.0141979999861
                        ],
                        [
                            -92.3621410000311,
                            46.0131030001404
                        ],
                        [
                            -92.3517600003359,
                            46.0156850001898
                        ],
                        [
                            -92.3499769999054,
                            46.0169819999274
                        ],
                        [
                            -92.3492809995964,
                            46.0236239996893
                        ],
                        [
                            -92.3437449996056,
                            46.0285249999352
                        ],
                        [
                            -92.3424290000537,
                            46.0345409998796
                        ],
                        [
                            -92.3434590002737,
                            46.0429900000764
                        ],
                        [
                            -92.3387330003948,
                            46.0481260000266
                        ],
                        [
                            -92.3363530002878,
                            46.0569979998521
                        ],
                        [
                            -92.3329119999988,
                            46.0626970001601
                        ],
                        [
                            -92.319329000389,
                            46.0692890002554
                        ],
                        [
                            -92.294032999842,
                            46.0743770000359
                        ],
                        [
                            -92.2923189999069,
                            46.6633069997353
                        ],
                        [
                            -92.2912889996869,
                            46.6681370002649
                        ],
                        [
                            -92.2873920001866,
                            46.6673430002477
                        ],
                        [
                            -92.2861920000873,
                            46.6603420001568
                        ],
                        [
                            -92.283899000019,
                            46.6587099997028
                        ],
                        [
                            -92.2743920000097,
                            46.6574409997764
                        ],
                        [
                            -92.2705919996955,
                            46.6507410002997
                        ],
                        [
                            -92.2659930000382,
                            46.6510409999614
                        ],
                        [
                            -92.2565920003342,
                            46.6587409999327
                        ],
                        [
                            -92.2523090001462,
                            46.6570689998481
                        ],
                        [
                            -92.2424930003404,
                            46.6492409998477
                        ],
                        [
                            -92.228492000256,
                            46.6529410000782
                        ],
                        [
                            -92.2163919997044,
                            46.6498409999639
                        ],
                        [
                            -92.2070920002827,
                            46.6519410000614
                        ],
                        [
                            -92.201592000277,
                            46.6566409999867
                        ],
                        [
                            -92.2054920001504,
                            46.6647410002428
                        ],
                        [
                            -92.2040920000346,
                            46.6669410000881
                        ],
                        [
                            -92.1924919999735,
                            46.6767410002535
                        ],
                        [
                            -92.1813589998927,
                            46.6802410000654
                        ],
                        [
                            -92.1758749997805,
                            46.686465999912
                        ],
                        [
                            -92.1762589999201,
                            46.6904729997838
                        ],
                        [
                            -92.182999000208,
                            46.6956519999371
                        ],
                        [
                            -92.1984909997465,
                            46.6961409997266
                        ],
                        [
                            -92.2051919996764,
                            46.698340999773
                        ],
                        [
                            -92.2046910002593,
                            46.7040409999913
                        ],
                        [
                            -92.1973910001047,
                            46.7076410001807
                        ],
                        [
                            -92.1932910002148,
                            46.7112409998956
                        ],
                        [
                            -92.1914910000659,
                            46.716241000064
                        ],
                        [
                            -92.1890909998674,
                            46.7175409999446
                        ],
                        [
                            -92.17429100044,
                            46.7172409998146
                        ],
                        [
                            -92.1672909998611,
                            46.7199410000615
                        ],
                        [
                            -92.1551910002078,
                            46.7159399998771
                        ],
                        [
                            -92.146730000312,
                            46.7157940001577
                        ],
                        [
                            -92.1415220003844,
                            46.7252850002868
                        ],
                        [
                            -92.1434020000008,
                            46.7281519999546
                        ],
                        [
                            -92.1432899998479,
                            46.7346400001177
                        ],
                        [
                            -92.1378900002996,
                            46.7395399999614
                        ],
                        [
                            -92.1165899998854,
                            46.7486399997331
                        ],
                        [
                            -92.0894930002629,
                            46.7492369999054
                        ],
                        [
                            -92.0339890004156,
                            46.7089390001779
                        ],
                        [
                            -92.0202939997545,
                            46.7040409999913
                        ],
                        [
                            -91.9813409999779,
                            46.7230910001729
                        ],
                        [
                            -91.8751860003836,
                            46.7633030000525
                        ],
                        [
                            -91.5921169998389,
                            46.8749350001723
                        ],
                        [
                            -91.5001809998889,
                            46.9133199997186
                        ],
                        [
                            -91.1166090000916,
                            47.0952159998095
                        ],
                        [
                            -90.6546599996645,
                            47.3098220001952
                        ],
                        [
                            -89.9571020001925,
                            47.2911030002522
                        ],
                        [
                            -90.0001449998133,
                            47.2149690002009
                        ],
                        [
                            -90.4183920001204,
                            46.5660990002009
                        ],
                        [
                            -90.4151990004267,
                            46.5631129998376
                        ],
                        [
                            -90.4150439997177,
                            46.5576729997099
                        ],
                        [
                            -90.4080299997934,
                            46.5548389997035
                        ],
                        [
                            -90.4059800002976,
                            46.5485769998376
                        ],
                        [
                            -90.4016479997057,
                            46.544153999769
                        ],
                        [
                            -90.3993360002691,
                            46.5439509999689
                        ],
                        [
                            -90.3955379996046,
                            46.5359459998507
                        ],
                        [
                            -90.3922160000396,
                            46.5360479999015
                        ],
                        [
                            -90.3923280001926,
                            46.5345859998169
                        ],
                        [
                            -90.3951529996402,
                            46.5342269999439
                        ],
                        [
                            -90.393262000153,
                            46.5324330001149
                        ],
                        [
                            -90.3804510001221,
                            46.5365649998173
                        ],
                        [
                            -90.3778890004402,
                            46.5355460002502
                        ],
                        [
                            -90.3755169998308,
                            46.5388310000324
                        ],
                        [
                            -90.36822900027,
                            46.5411320000792
                        ],
                        [
                            -90.3653910004036,
                            46.5395359999771
                        ],
                        [
                            -90.360684999718,
                            46.5413800001485
                        ],
                        [
                            -90.3578500002246,
                            46.5388130000875
                        ],
                        [
                            -90.3506839999643,
                            46.5403249999909
                        ],
                        [
                            -90.3455069999988,
                            46.5454379997567
                        ],
                        [
                            -90.3477320002951,
                            46.5474760001935
                        ],
                        [
                            -90.3468059998323,
                            46.5493499997071
                        ],
                        [
                            -90.3423360000465,
                            46.5478309998489
                        ],
                        [
                            -90.3394039995704,
                            46.5492879999399
                        ],
                        [
                            -90.3437770001701,
                            46.5508539999666
                        ],
                        [
                            -90.3435329997727,
                            46.5524549998777
                        ],
                        [
                            -90.3366209999556,
                            46.5541209997714
                        ],
                        [
                            -90.3322299998125,
                            46.5534319999576
                        ],
                        [
                            -90.3324750000349,
                            46.5516719998825
                        ],
                        [
                            -90.3273790002602,
                            46.551408999697
                        ],
                        [
                            -90.3280579999526,
                            46.5481700001946
                        ],
                        [
                            -90.3267309996317,
                            46.5460709997862
                        ],
                        [
                            -90.321306999794,
                            46.5467419999687
                        ],
                        [
                            -90.3184480000112,
                            46.5442430001573
                        ],
                        [
                            -90.3154770000754,
                            46.5446970001452
                        ],
                        [
                            -90.3170810004057,
                            46.5423480000033
                        ],
                        [
                            -90.3161599999657,
                            46.54050399996
                        ],
                        [
                            -90.3121989999929,
                            46.5402170001142
                        ],
                        [
                            -90.3106189999521,
                            46.5391659999774
                        ],
                        [
                            -90.311613000187,
                            46.5379800002298
                        ],
                        [
                            -90.3094409995941,
                            46.5372849999032
                        ],
                        [
                            -90.3113799996601,
                            46.5340390001464
                        ],
                        [
                            -90.3106039998834,
                            46.5324110002672
                        ],
                        [
                            -90.3130219996253,
                            46.5308890000167
                        ],
                        [
                            -90.3120130002201,
                            46.529192999999
                        ],
                        [
                            -90.3144340003349,
                            46.5236909998741
                        ],
                        [
                            -90.3178350004411,
                            46.5215229999371
                        ],
                        [
                            -90.316554000151,
                            46.5168959999281
                        ],
                        [
                            -90.3140860001804,
                            46.5160119999646
                        ],
                        [
                            -90.3087669999246,
                            46.5167300001309
                        ],
                        [
                            -90.3071969999296,
                            46.5184369998068
                        ],
                        [
                            -90.3033260003685,
                            46.5173599998515
                        ],
                        [
                            -90.2953220004072,
                            46.5185100001494
                        ],
                        [
                            -90.2945890002882,
                            46.5216599999239
                        ],
                        [
                            -90.2844639997876,
                            46.5187150001889
                        ],
                        [
                            -90.2769400002254,
                            46.5244470002103
                        ],
                        [
                            -90.2717959998718,
                            46.5192650001075
                        ],
                        [
                            -90.2753240001995,
                            46.5182300002328
                        ],
                        [
                            -90.2776230001157,
                            46.5148100002361
                        ],
                        [
                            -90.2746520001799,
                            46.5153580002891
                        ],
                        [
                            -90.2704409999619,
                            46.5116830001309
                        ],
                        [
                            -90.2728699995744,
                            46.5086330001826
                        ],
                        [
                            -90.2725509997321,
                            46.5045119998336
                        ],
                        [
                            -90.2709339998814,
                            46.5068520002365
                        ],
                        [
                            -90.2665670000277,
                            46.5078550000096
                        ],
                        [
                            -90.2640060001708,
                            46.5063100002196
                        ],
                        [
                            -90.2654969996248,
                            46.5043000001151
                        ],
                        [
                            -90.261548000246,
                            46.5025619997418
                        ],
                        [
                            -90.2566979996203,
                            46.5048049998045
                        ],
                        [
                            -90.2408970002852,
                            46.5059760001942
                        ],
                        [
                            -90.2312360002902,
                            46.5098930001463
                        ],
                        [
                            -90.2294339995932,
                            46.5078910000106
                        ],
                        [
                            -90.2312530000086,
                            46.5030700002283
                        ],
                        [
                            -90.2291320003677,
                            46.5026510002771
                        ],
                        [
                            -90.2309180002729,
                            46.5015289999116
                        ],
                        [
                            -90.220771999856,
                            46.5035680002264
                        ],
                        [
                            -90.2166369998059,
                            46.5016370000446
                        ],
                        [
                            -90.2111920000518,
                            46.4931110002564
                        ],
                        [
                            -90.2120809998064,
                            46.490611000223
                        ],
                        [
                            -90.2057880003055,
                            46.4847609997172
                        ],
                        [
                            -90.2042119995643,
                            46.4783089998138
                        ],
                        [
                            -90.1971879995943,
                            46.4731589996923
                        ],
                        [
                            -90.1931479999788,
                            46.4723160001249
                        ],
                        [
                            -90.1926569997091,
                            46.4685760002624
                        ],
                        [
                            -90.1888339998286,
                            46.4689190002649
                        ],
                        [
                            -90.1921830000562,
                            46.4657579999483
                        ],
                        [
                            -90.1922270004371,
                            46.4633739997548
                        ],
                        [
                            -90.1936140001341,
                            46.4633790000822
                        ],
                        [
                            -90.1907920001612,
                            46.4598159996999
                        ],
                        [
                            -90.1804180001387,
                            46.4565830001205
                        ],
                        [
                            -90.1810879996102,
                            46.4587520002884
                        ],
                        [
                            -90.1783250000868,
                            46.4574079998446
                        ],
                        [
                            -90.1791710001838,
                            46.4505960000462
                        ],
                        [
                            -90.1766970003654,
                            46.447982999918
                        ],
                        [
                            -90.1783500002012,
                            46.4404649998429
                        ],
                        [
                            -90.1704039999662,
                            46.4394379998501
                        ],
                        [
                            -90.1674199996117,
                            46.4417050000156
                        ],
                        [
                            -90.1671300000817,
                            46.4384529997672
                        ],
                        [
                            -90.1633189998968,
                            46.4347639998753
                        ],
                        [
                            -90.1624200000964,
                            46.4275790002601
                        ],
                        [
                            -90.1596590002229,
                            46.4253939998644
                        ],
                        [
                            -90.1605589998482,
                            46.4228890001064
                        ],
                        [
                            -90.1572980003825,
                            46.4211219999437
                        ],
                        [
                            -90.158921000081,
                            46.4190889999845
                        ],
                        [
                            -90.1577200001569,
                            46.41570599987
                        ],
                        [
                            -90.1590869997624,
                            46.4140300002077
                        ],
                        [
                            -90.158368999712,
                            46.4094539999627
                        ],
                        [
                            -90.1536420000083,
                            46.4070010000175
                        ],
                        [
                            -90.1532679999144,
                            46.4058749998506
                        ],
                        [
                            -90.1549719998038,
                            46.4054570000805
                        ],
                        [
                            -90.1528300002465,
                            46.4037769997101
                        ],
                        [
                            -90.1540970002929,
                            46.4032980000842
                        ],
                        [
                            -90.1531680003553,
                            46.401216999781
                        ],
                        [
                            -90.146667000442,
                            46.3990130001429
                        ],
                        [
                            -90.1477820001524,
                            46.3960560001845
                        ],
                        [
                            -90.1447350000486,
                            46.3932439998136
                        ],
                        [
                            -90.144351999734,
                            46.3900480002124
                        ],
                        [
                            -90.1411740001089,
                            46.3888729999251
                        ],
                        [
                            -90.1396819999317,
                            46.3852590000697
                        ],
                        [
                            -90.1321769997377,
                            46.3811199997641
                        ],
                        [
                            -90.134249999698,
                            46.3791310002334
                        ],
                        [
                            -90.1328079997494,
                            46.3781199998798
                        ],
                        [
                            -90.1344460004149,
                            46.377439000303
                        ],
                        [
                            -90.1339860001074,
                            46.3717890002625
                        ],
                        [
                            -90.1304600003278,
                            46.3711510000849
                        ],
                        [
                            -90.1297320002316,
                            46.3698730000887
                        ],
                        [
                            -90.1313519995571,
                            46.3690530000344
                        ],
                        [
                            -90.1228110001937,
                            46.3636450000365
                        ],
                        [
                            -90.1233679996873,
                            46.3607349998608
                        ],
                        [
                            -90.1187029999079,
                            46.3593450003083
                        ],
                        [
                            -90.1168519997054,
                            46.3506510002151
                        ],
                        [
                            -90.1203959999266,
                            46.3495940000026
                        ],
                        [
                            -90.1236510004429,
                            46.3462649999189
                        ],
                        [
                            -90.1217800001489,
                            46.344399999865
                        ],
                        [
                            -90.1200319998785,
                            46.3447640000419
                        ],
                        [
                            -90.1179840000326,
                            46.3415049996993
                        ],
                        [
                            -90.1214300003445,
                            46.3374249997687
                        ],
                        [
                            -89.091638000012,
                            46.1384469999974
                        ],
                        [
                            -88.850821999966,
                            46.0403120000075
                        ],
                        [
                            -88.8390910003836,
                            46.0299760000124
                        ],
                        [
                            -88.8354080002452,
                            46.0304439999548
                        ],
                        [
                            -88.8243789999215,
                            46.026552999842
                        ],
                        [
                            -88.8190839999552,
                            46.026314000016
                        ],
                        [
                            -88.8154400001748,
                            46.0229739999697
                        ],
                        [
                            -88.8160259999807,
                            46.0205859999584
                        ],
                        [
                            -88.7964599995932,
                            46.0236059999669
                        ],
                        [
                            -88.7962420000333,
                            46.02685400011
                        ],
                        [
                            -88.8006699999863,
                            46.0300360002584
                        ],
                        [
                            -88.7961819997589,
                            46.0337120001507
                        ],
                        [
                            -88.7921620002349,
                            46.0323160000117
                        ],
                        [
                            -88.7887660001516,
                            46.0286230000279
                        ],
                        [
                            -88.7845959999415,
                            46.0326540002285
                        ],
                        [
                            -88.7787389995604,
                            46.0316900000847
                        ],
                        [
                            -88.7787340004358,
                            46.0288750001811
                        ],
                        [
                            -88.7839399998154,
                            46.023843999773
                        ],
                        [
                            -88.7824729997524,
                            46.0167509999357
                        ],
                        [
                            -88.7801560002929,
                            46.0153949999465
                        ],
                        [
                            -88.7698009996386,
                            46.0189439999361
                        ],
                        [
                            -88.7688480003099,
                            46.0218869997878
                        ],
                        [
                            -88.7706209997963,
                            46.0255609999146
                        ],
                        [
                            -88.7663989997076,
                            46.0248820002827
                        ],
                        [
                            -88.7589680000318,
                            46.0196330002884
                        ],
                        [
                            -88.7527199998384,
                            46.0234149999528
                        ],
                        [
                            -88.7389799998699,
                            46.027459000152
                        ],
                        [
                            -88.7342339998995,
                            46.0255449998906
                        ],
                        [
                            -88.7304749995932,
                            46.0267279999362
                        ],
                        [
                            -88.7212610003852,
                            46.0222209999084
                        ],
                        [
                            -88.722217000087,
                            46.017530000023
                        ],
                        [
                            -88.7180240003106,
                            46.0156110002335
                        ],
                        [
                            -88.7183139998405,
                            46.0131820000562
                        ],
                        [
                            -88.7129770000414,
                            46.0127180002706
                        ],
                        [
                            -88.712078000241,
                            46.0163460002705
                        ],
                        [
                            -88.7077149996871,
                            46.0153959999758
                        ],
                        [
                            -88.7037110000566,
                            46.0182930001132
                        ],
                        [
                            -88.6974210000305,
                            46.017489999758
                        ],
                        [
                            -88.6896959997284,
                            46.0140029996945
                        ],
                        [
                            -88.6872689997657,
                            46.0150439997864
                        ],
                        [
                            -88.6788680001444,
                            46.0135950003057
                        ],
                        [
                            -88.6752839997402,
                            46.0113960003094
                        ],
                        [
                            -88.6759159995769,
                            46.0085459998363
                        ],
                        [
                            -88.6712149998124,
                            46.0048589999649
                        ],
                        [
                            -88.6722569997279,
                            46.0026450000494
                        ],
                        [
                            -88.6703300002558,
                            46.0009980000107
                        ],
                        [
                            -88.671005999575,
                            45.9988319999178
                        ],
                        [
                            -88.6667280003082,
                            45.9969319997763
                        ],
                        [
                            -88.6670019999446,
                            45.9937159998114
                        ],
                        [
                            -88.6634829998377,
                            45.9927020002507
                        ],
                        [
                            -88.6706010004174,
                            45.9898300001244
                        ],
                        [
                            -88.6708270003732,
                            45.9885470000379
                        ],
                        [
                            -88.6641620004283,
                            45.9891399999747
                        ],
                        [
                            -88.6629780002226,
                            45.9874989999904
                        ],
                        [
                            -88.6579379996261,
                            45.989419000067
                        ],
                        [
                            -88.651767000324,
                            45.9875660001249
                        ],
                        [
                            -88.6476020001367,
                            45.9886410002779
                        ],
                        [
                            -88.6464699998095,
                            45.9871100001406
                        ],
                        [
                            -88.6385460002142,
                            45.9850609997758
                        ],
                        [
                            -88.6352010001846,
                            45.9852169997913
                        ],
                        [
                            -88.6339380003362,
                            45.9881690001834
                        ],
                        [
                            -88.6159570000123,
                            45.9878960000702
                        ],
                        [
                            -88.6130039996199,
                            45.9909770002351
                        ],
                        [
                            -88.615967999883,
                            45.9935389998601
                        ],
                        [
                            -88.6118489997265,
                            45.9983900001159
                        ],
                        [
                            -88.6115120003409,
                            46.0034779999204
                        ],
                        [
                            -88.6127120004402,
                            46.004461999888
                        ],
                        [
                            -88.6073110001686,
                            46.0076210002055
                        ],
                        [
                            -88.6072709999857,
                            46.0113530002916
                        ],
                        [
                            -88.6041059998811,
                            46.0163039997473
                        ],
                        [
                            -88.601177999603,
                            46.0177699997572
                        ],
                        [
                            -88.5976819999607,
                            46.0174719999101
                        ],
                        [
                            -88.5935479997355,
                            46.0147699999525
                        ],
                        [
                            -88.5928909997845,
                            46.0111250001901
                        ],
                        [
                            -88.588270000386,
                            46.0050980001885
                        ],
                        [
                            -88.5809190001778,
                            46.006784999768
                        ],
                        [
                            -88.5787540001559,
                            46.0096930001071
                        ],
                        [
                            -88.5729570000493,
                            46.0115979998731
                        ],
                        [
                            -88.5717589995998,
                            46.0137739998531
                        ],
                        [
                            -88.5656629997425,
                            46.0156790000452
                        ],
                        [
                            -88.5592970004467,
                            46.0140969997417
                        ],
                        [
                            -88.5550900004266,
                            46.015044999822
                        ],
                        [
                            -88.5491510000297,
                            46.0128839998155
                        ],
                        [
                            -88.5394320003084,
                            46.0144700000666
                        ],
                        [
                            -88.5362680000287,
                            46.0176880001939
                        ],
                        [
                            -88.5325220001411,
                            46.0183710001253
                        ],
                        [
                            -88.5336860002554,
                            46.0211499999454
                        ],
                        [
                            -88.522749999818,
                            46.019364999857
                        ],
                        [
                            -88.5146920003284,
                            46.0200189999955
                        ],
                        [
                            -88.5063639996037,
                            46.0176099998421
                        ],
                        [
                            -88.5065510000997,
                            46.0113030001418
                        ],
                        [
                            -88.5022860003534,
                            46.0069710001655
                        ],
                        [
                            -88.503102000313,
                            46.005964999878
                        ],
                        [
                            -88.5008850004127,
                            46.0006039999498
                        ],
                        [
                            -88.4968519995715,
                            45.9992340001928
                        ],
                        [
                            -88.497978000051,
                            45.9957939998476
                        ],
                        [
                            -88.4893549997735,
                            45.9913670002098
                        ],
                        [
                            -88.475205999551,
                            45.9931289996922
                        ],
                        [
                            -88.4737710001734,
                            45.9949070000266
                        ],
                        [
                            -88.4745829999352,
                            45.9989530002504
                        ],
                        [
                            -88.4707719997502,
                            46.0009980000107
                        ],
                        [
                            -88.458672000097,
                            45.9995140002638
                        ],
                        [
                            -88.4541090004248,
                            45.9975289999001
                        ],
                        [
                            -88.4542349999231,
                            45.9934759999207
                        ],
                        [
                            -88.4500370001239,
                            45.9901120000703
                        ],
                        [
                            -88.4407490003978,
                            45.9908500002199
                        ],
                        [
                            -88.4385580004367,
                            45.9886040000099
                        ],
                        [
                            -88.4347620003203,
                            45.9883399998429
                        ],
                        [
                            -88.4335570001982,
                            45.9854089999752
                        ],
                        [
                            -88.4261309996469,
                            45.9843860002119
                        ],
                        [
                            -88.4231930002214,
                            45.9817250001048
                        ],
                        [
                            -88.4230380004107,
                            45.9783969997695
                        ],
                        [
                            -88.4163809999635,
                            45.9750919997559
                        ],
                        [
                            -88.4099100001873,
                            45.9796699998782
                        ],
                        [
                            -88.4031010003024,
                            45.981193000221
                        ],
                        [
                            -88.3954210002062,
                            45.9803120000979
                        ],
                        [
                            -88.3886440001084,
                            45.9826230001767
                        ],
                        [
                            -88.384259999638,
                            45.9879000003033
                        ],
                        [
                            -88.3848420001443,
                            45.9904490000579
                        ],
                        [
                            -88.3805029998797,
                            45.9917649998408
                        ],
                        [
                            -88.3742980002424,
                            45.989050000108
                        ],
                        [
                            -88.3717580003018,
                            45.9854469998287
                        ],
                        [
                            -88.3598209999569,
                            45.9818920002767
                        ],
                        [
                            -88.3454170003646,
                            45.9739850000487
                        ],
                        [
                            -88.3425230004217,
                            45.971986999979
                        ],
                        [
                            -88.34203600035,
                            45.9689840003089
                        ],
                        [
                            -88.3347400003933,
                            45.9686270001383
                        ],
                        [
                            -88.3302780001052,
                            45.9658350001616
                        ],
                        [
                            -88.3280410001133,
                            45.9635390001792
                        ],
                        [
                            -88.3278760002568,
                            45.9589150002542
                        ],
                        [
                            -88.3301719997999,
                            45.956631999957
                        ],
                        [
                            -88.3272760002072,
                            45.9549570000979
                        ],
                        [
                            -88.3202439998412,
                            45.9600840003042
                        ],
                        [
                            -88.3203080003136,
                            45.9636569998425
                        ],
                        [
                            -88.3130209996795,
                            45.9594310001757
                        ],
                        [
                            -88.3013779999607,
                            45.9564590001018
                        ],
                        [
                            -88.2938490003756,
                            45.9509410002592
                        ],
                        [
                            -88.2853139999617,
                            45.9552619997466
                        ],
                        [
                            -88.269306999864,
                            45.9562719999505
                        ],
                        [
                            -88.2676399997845,
                            45.9578940002487
                        ],
                        [
                            -88.2590259997279,
                            45.9596499997501
                        ],
                        [
                            -88.2549800002646,
                            45.9635120003003
                        ],
                        [
                            -88.2468940002876,
                            45.9635320002813
                        ],
                        [
                            -88.245708000432,
                            45.9627350002287
                        ],
                        [
                            -88.2461789997119,
                            45.9560830000881
                        ],
                        [
                            -88.2448330000228,
                            45.9528639998965
                        ],
                        [
                            -88.2371060000709,
                            45.9482780001597
                        ],
                        [
                            -88.2320439997331,
                            45.9472139997886
                        ],
                        [
                            -88.224222000245,
                            45.948607000036
                        ],
                        [
                            -88.2037970002401,
                            45.9479410002115
                        ],
                        [
                            -88.197759999934,
                            45.9530190001506
                        ],
                        [
                            -88.1920580002619,
                            45.9526509998436
                        ],
                        [
                            -88.1780410002839,
                            45.9470930000428
                        ],
                        [
                            -88.1700890002011,
                            45.9394919998019
                        ],
                        [
                            -88.1638579997262,
                            45.9383810001666
                        ],
                        [
                            -88.1617610003747,
                            45.9407410001564
                        ],
                        [
                            -88.1556389996798,
                            45.9373520001743
                        ],
                        [
                            -88.147650999612,
                            45.9374270000193
                        ],
                        [
                            -88.145710999721,
                            45.9362429999387
                        ],
                        [
                            -88.1463340002352,
                            45.9340690001505
                        ],
                        [
                            -88.1412500001561,
                            45.9308439998302
                        ],
                        [
                            -88.1268080000306,
                            45.9259939998955
                        ],
                        [
                            -88.1266540000449,
                            45.9217909999045
                        ],
                        [
                            -88.102461000411,
                            45.9215460002408
                        ],
                        [
                            -88.0949269999047,
                            45.9153230001605
                        ],
                        [
                            -88.0953140004173,
                            45.9136570001505
                        ],
                        [
                            -88.1022520001737,
                            45.9099419999008
                        ],
                        [
                            -88.1066240000502,
                            45.9022129999457
                        ],
                        [
                            -88.1067579999445,
                            45.8985290002783
                        ],
                        [
                            -88.102526999635,
                            45.8922220002934
                        ],
                        [
                            -88.1012639997865,
                            45.8822819999599
                        ],
                        [
                            -88.0964960000749,
                            45.8798610003058
                        ],
                        [
                            -88.0818199995977,
                            45.8802009998346
                        ],
                        [
                            -88.0697629996022,
                            45.8729380002808
                        ],
                        [
                            -88.0758380004415,
                            45.8696300002843
                        ],
                        [
                            -88.074828000313,
                            45.8649050002919
                        ],
                        [
                            -88.0776529997607,
                            45.8637440001659
                        ],
                        [
                            -88.0824649998532,
                            45.8649610002249
                        ],
                        [
                            -88.0877189998116,
                            45.8569500002183
                        ],
                        [
                            -88.0977580000984,
                            45.8504379997075
                        ],
                        [
                            -88.1051960003453,
                            45.8424130002237
                        ],
                        [
                            -88.1209010003192,
                            45.8329620001566
                        ],
                        [
                            -88.1345170004392,
                            45.8224569998998
                        ],
                        [
                            -88.1360330000076,
                            45.8180909997827
                        ],
                        [
                            -88.1301289997708,
                            45.8097079997687
                        ],
                        [
                            -88.1162659997786,
                            45.8040859997274
                        ],
                        [
                            -88.1076080002394,
                            45.8030399997952
                        ],
                        [
                            -88.1032129998983,
                            45.7912549997249
                        ],
                        [
                            -88.099420000155,
                            45.7899900000908
                        ],
                        [
                            -88.0947539996524,
                            45.7857549999565
                        ],
                        [
                            -88.0799759999663,
                            45.7851239999016
                        ],
                        [
                            -88.0721669999986,
                            45.7800850000952
                        ],
                        [
                            -88.0499600002865,
                            45.7811930000741
                        ],
                        [
                            -88.040936999976,
                            45.7842650001216
                        ],
                        [
                            -88.0397279996559,
                            45.789626000028
                        ],
                        [
                            -88.0272320001675,
                            45.7891969996986
                        ],
                        [
                            -88.0175879998909,
                            45.7924549997333
                        ],
                        [
                            -88.0070430001641,
                            45.7921919997928
                        ],
                        [
                            -87.9914469999702,
                            45.7953930001202
                        ],
                        [
                            -87.987942000107,
                            45.7930750001163
                        ],
                        [
                            -87.980869999558,
                            45.7769769997249
                        ],
                        [
                            -87.9817890003482,
                            45.7750810003019
                        ],
                        [
                            -87.9855970001601,
                            45.7749260000002
                        ],
                        [
                            -87.9896560000421,
                            45.7720249998198
                        ],
                        [
                            -87.9864290000133,
                            45.7695960000422
                        ],
                        [
                            -87.9669699997559,
                            45.7640210002013
                        ],
                        [
                            -87.9634520003722,
                            45.7582200002352
                        ],
                        [
                            -87.952182999849,
                            45.7584160001364
                        ],
                        [
                            -87.9454779997211,
                            45.756687000268
                        ],
                        [
                            -87.93458300019,
                            45.7580960001468
                        ],
                        [
                            -87.9291269996669,
                            45.7603680000667
                        ],
                        [
                            -87.9219939999169,
                            45.7569959999745
                        ],
                        [
                            -87.9165559998354,
                            45.759461000012
                        ],
                        [
                            -87.9113360002122,
                            45.7565389999263
                        ],
                        [
                            -87.9066599996637,
                            45.7595240000867
                        ],
                        [
                            -87.9027070000869,
                            45.757932000193
                        ],
                        [
                            -87.8983629997995,
                            45.752502999931
                        ],
                        [
                            -87.8768980004272,
                            45.7543790001584
                        ],
                        [
                            -87.8733390001374,
                            45.7504389998892
                        ],
                        [
                            -87.8681110001182,
                            45.7494769998507
                        ],
                        [
                            -87.8656809997825,
                            45.7462880001237
                        ],
                        [
                            -87.8622110000794,
                            45.7450180001478
                        ],
                        [
                            -87.8643200000248,
                            45.7371389999014
                        ],
                        [
                            -87.8554800000123,
                            45.7269429997046
                        ],
                        [
                            -87.8373449997027,
                            45.7169179998407
                        ],
                        [
                            -87.8101440003231,
                            45.7102299997308
                        ],
                        [
                            -87.8050810001604,
                            45.7049739999217
                        ],
                        [
                            -87.8090749997452,
                            45.6997170002114
                        ],
                        [
                            -87.8018800000709,
                            45.6938619998644
                        ],
                        [
                            -87.7822259998198,
                            45.6830529998987
                        ],
                        [
                            -87.7808080001606,
                            45.680348999946
                        ],
                        [
                            -87.7816230002954,
                            45.6732800002585
                        ],
                        [
                            -87.7972780000406,
                            45.6708380002225
                        ],
                        [
                            -87.8032900002324,
                            45.6664939997836
                        ],
                        [
                            -87.8241910004384,
                            45.6620439997229
                        ],
                        [
                            -87.8223779998707,
                            45.6609969998294
                        ],
                        [
                            -87.8237009999936,
                            45.6596969997617
                        ],
                        [
                            -87.8224590000616,
                            45.6571510001418
                        ],
                        [
                            -87.8246779996118,
                            45.6532119998244
                        ],
                        [
                            -87.8243920002798,
                            45.6477100001141
                        ],
                        [
                            -87.8101950003768,
                            45.638732000041
                        ],
                        [
                            -87.8045199995706,
                            45.6289870002256
                        ],
                        [
                            -87.7969829995895,
                            45.6236129996865
                        ],
                        [
                            -87.7958799995747,
                            45.6188459999214
                        ],
                        [
                            -87.7808449999706,
                            45.6145990000084
                        ],
                        [
                            -87.7776709996452,
                            45.6092039998595
                        ],
                        [
                            -87.777936999784,
                            45.6044400002782
                        ],
                        [
                            -87.7746820001661,
                            45.6020240002011
                        ],
                        [
                            -87.7775040001389,
                            45.5939080002816
                        ],
                        [
                            -87.7771989996421,
                            45.5884980001985
                        ],
                        [
                            -87.7867659999258,
                            45.5828280003118
                        ],
                        [
                            -87.7887979998783,
                            45.5659470001969
                        ],
                        [
                            -87.7923720002367,
                            45.5630550000346
                        ],
                        [
                            -87.8061039998094,
                            45.5628629999757
                        ],
                        [
                            -87.8104199996094,
                            45.5653079999593
                        ],
                        [
                            -87.8293480003124,
                            45.5687769998378
                        ],
                        [
                            -87.8316909997112,
                            45.5680359998609
                        ],
                        [
                            -87.8336900000517,
                            45.5615559998698
                        ],
                        [
                            -87.8322959997837,
                            45.558767000069
                        ],
                        [
                            -87.8187909999918,
                            45.5521000003023
                        ],
                        [
                            -87.8170889997522,
                            45.5495119999311
                        ],
                        [
                            -87.8083659999156,
                            45.544661999725
                        ],
                        [
                            -87.8033899997915,
                            45.5382719997737
                        ],
                        [
                            -87.8047199995871,
                            45.5312439997197
                        ],
                        [
                            -87.8022409997459,
                            45.5130589999292
                        ],
                        [
                            -87.798793999609,
                            45.5062870000136
                        ],
                        [
                            -87.7932149999606,
                            45.5050280001222
                        ],
                        [
                            -87.7927689998968,
                            45.4999670000331
                        ],
                        [
                            -87.798172999643,
                            45.4936659998174
                        ],
                        [
                            -87.7989600001887,
                            45.4851470000437
                        ],
                        [
                            -87.8068910003551,
                            45.4790920000008
                        ],
                        [
                            -87.8057730002716,
                            45.473139000186
                        ],
                        [
                            -87.8114690000959,
                            45.4679910001881
                        ],
                        [
                            -87.8129760003418,
                            45.4641599999258
                        ],
                        [
                            -87.8202639999025,
                            45.4623140000744
                        ],
                        [
                            -87.8200399995965,
                            45.4604529999621
                        ],
                        [
                            -87.8255089996401,
                            45.4580260001996
                        ],
                        [
                            -87.8312170000583,
                            45.4571849999401
                        ],
                        [
                            -87.8355620001706,
                            45.451102000302
                        ],
                        [
                            -87.8448039998659,
                            45.4484060001437
                        ],
                        [
                            -87.8474290001953,
                            45.4441770002755
                        ],
                        [
                            -87.8552980004374,
                            45.4413789998479
                        ],
                        [
                            -87.8578409998527,
                            45.4370319997553
                        ],
                        [
                            -87.8616970002435,
                            45.4344730000813
                        ],
                        [
                            -87.860431999847,
                            45.4235039997639
                        ],
                        [
                            -87.8571239996272,
                            45.4201919999225
                        ],
                        [
                            -87.8562159996061,
                            45.4161009998967
                        ],
                        [
                            -87.8505330002005,
                            45.4116849997064
                        ],
                        [
                            -87.8493220002306,
                            45.4038719997532
                        ],
                        [
                            -87.8509690002186,
                            45.4019249998827
                        ],
                        [
                            -87.8591310003637,
                            45.3989670001105
                        ],
                        [
                            -87.8596030003668,
                            45.3964089998422
                        ],
                        [
                            -87.8568299998994,
                            45.3931059999985
                        ],
                        [
                            -87.8594180004188,
                            45.3882270001685
                        ],
                        [
                            -87.8709049996037,
                            45.3831160001107
                        ],
                        [
                            -87.8754239997933,
                            45.3793730001818
                        ],
                        [
                            -87.8756919995819,
                            45.3770520002411
                        ],
                        [
                            -87.8714849995618,
                            45.3715460001298
                        ],
                        [
                            -87.8848549996345,
                            45.3627919997412
                        ],
                        [
                            -87.8878280001185,
                            45.3581220000221
                        ],
                        [
                            -87.8880520004245,
                            45.3546969998267
                        ],
                        [
                            -87.8851700001771,
                            45.3517360000477
                        ],
                        [
                            -87.8811139997698,
                            45.3512779998885
                        ],
                        [
                            -87.8703670003767,
                            45.3562670002182
                        ],
                        [
                            -87.8697709996267,
                            45.3586159999008
                        ],
                        [
                            -87.8758449997428,
                            45.3650170002306
                        ],
                        [
                            -87.8729499999749,
                            45.3665259997648
                        ],
                        [
                            -87.8691670002774,
                            45.3660489997448
                        ],
                        [
                            -87.8653440003969,
                            45.3620959998174
                        ],
                        [
                            -87.8657629997983,
                            45.356280000032
                        ],
                        [
                            -87.8634889999965,
                            45.3530200002594
                        ],
                        [
                            -87.8504179996745,
                            45.3474920001447
                        ],
                        [
                            -87.8498989998157,
                            45.3446509997413
                        ],
                        [
                            -87.8514749996586,
                            45.3423350000568
                        ],
                        [
                            -87.8501330001674,
                            45.3404349999342
                        ],
                        [
                            -87.8381409995709,
                            45.3451009998029
                        ],
                        [
                            -87.8353029997045,
                            45.3509799996969
                        ],
                        [
                            -87.8326120001512,
                            45.3522489999908
                        ],
                        [
                            -87.8269179999767,
                            45.350538000205
                        ],
                        [
                            -87.8230280001491,
                            45.3526500000739
                        ],
                        [
                            -87.8100759996528,
                            45.3512690000964
                        ],
                        [
                            -87.8004640000616,
                            45.3536080002011
                        ],
                        [
                            -87.7903219998426,
                            45.3534509999846
                        ],
                        [
                            -87.7830760001147,
                            45.3497250000517
                        ],
                        [
                            -87.7692599999781,
                            45.3511959998355
                        ],
                        [
                            -87.762128000053,
                            45.3484009997539
                        ],
                        [
                            -87.7541040000003,
                            45.3494420000313
                        ],
                        [
                            -87.7514519999067,
                            45.3517550000818
                        ],
                        [
                            -87.750928000025,
                            45.3550370000562
                        ],
                        [
                            -87.7388230003489,
                            45.3568509997342
                        ],
                        [
                            -87.7334089996586,
                            45.3644320000473
                        ],
                        [
                            -87.7188910002635,
                            45.3774620000123
                        ],
                        [
                            -87.70832899992,
                            45.3812180000181
                        ],
                        [
                            -87.70433799981,
                            45.3854639998156
                        ],
                        [
                            -87.6939560002898,
                            45.3898929997954
                        ],
                        [
                            -87.6859339998869,
                            45.3887110001358
                        ],
                        [
                            -87.6828659998667,
                            45.3849500000657
                        ],
                        [
                            -87.6750169997161,
                            45.3824539998224
                        ],
                        [
                            -87.6735129998432,
                            45.3769459997674
                        ],
                        [
                            -87.65735000011,
                            45.3687540000763
                        ],
                        [
                            -87.6558040004043,
                            45.3627129999168
                        ],
                        [
                            -87.6564470001116,
                            45.3584130002631
                        ],
                        [
                            -87.6539189998666,
                            45.3545899998313
                        ],
                        [
                            -87.650721999975,
                            45.3540049997784
                        ],
                        [
                            -87.6477290002979,
                            45.3507209999549
                        ],
                        [
                            -87.6481259999579,
                            45.339395999824
                        ],
                        [
                            -87.655775000092,
                            45.3308459999771
                        ],
                        [
                            -87.6616030001608,
                            45.327607999765
                        ],
                        [
                            -87.6617110001158,
                            45.3207360001729
                        ],
                        [
                            -87.6726690002944,
                            45.3102129999194
                        ],
                        [
                            -87.6718890003198,
                            45.3081060001446
                        ],
                        [
                            -87.6790909996668,
                            45.3058410000504
                        ],
                        [
                            -87.6803300001241,
                            45.3028000000329
                        ],
                        [
                            -87.6874989998591,
                            45.2980550001359
                        ],
                        [
                            -87.6903409999235,
                            45.2902169997758
                        ],
                        [
                            -87.6982479998004,
                            45.281511999802
                        ],
                        [
                            -87.6973730002895,
                            45.2784440003108
                        ],
                        [
                            -87.6992289996165,
                            45.2767459999638
                        ],
                        [
                            -87.6987800000779,
                            45.2694199998907
                        ],
                        [
                            -87.7056430003895,
                            45.2661039997721
                        ],
                        [
                            -87.7104499995609,
                            45.259323999974
                        ],
                        [
                            -87.7077580001827,
                            45.2586369998842
                        ],
                        [
                            -87.7113149999244,
                            45.2523960000887
                        ],
                        [
                            -87.7099330002502,
                            45.2490330001536
                        ],
                        [
                            -87.7113390002138,
                            45.2399650001902
                        ],
                        [
                            -87.7182639995514,
                            45.2383329997877
                        ],
                        [
                            -87.7249920001438,
                            45.2321399999745
                        ],
                        [
                            -87.7251650003961,
                            45.2303320000636
                        ],
                        [
                            -87.7213540002112,
                            45.226846999886
                        ],
                        [
                            -87.7224730001196,
                            45.2233089997689
                        ],
                        [
                            -87.7269520001262,
                            45.2189490001487
                        ],
                        [
                            -87.7261980000908,
                            45.2093909997575
                        ],
                        [
                            -87.7363389995865,
                            45.2046529997849
                        ],
                        [
                            -87.7418100001782,
                            45.197469000033
                        ],
                        [
                            -87.7390069995736,
                            45.1927380001697
                        ],
                        [
                            -87.739396999561,
                            45.1900460001596
                        ],
                        [
                            -87.7352099996324,
                            45.1776420001462
                        ],
                        [
                            -87.7361040003082,
                            45.172244000064
                        ],
                        [
                            -87.7277680000859,
                            45.1695959998881
                        ],
                        [
                            -87.7179100004474,
                            45.1611910001891
                        ],
                        [
                            -87.709539000065,
                            45.158690000049
                        ],
                        [
                            -87.7073909997615,
                            45.1546789998452
                        ],
                        [
                            -87.7034919997131,
                            45.1522059996961
                        ],
                        [
                            -87.6923750004241,
                            45.149505000148
                        ],
                        [
                            -87.6839019999344,
                            45.1441349999698
                        ],
                        [
                            -87.6758159999574,
                            45.1350590003118
                        ],
                        [
                            -87.6782089995848,
                            45.130083999938
                        ],
                        [
                            -87.6747420002548,
                            45.1270179996965
                        ],
                        [
                            -87.6724469996383,
                            45.1212940002972
                        ],
                        [
                            -87.6612960000141,
                            45.1125659999724
                        ],
                        [
                            -87.6612109996253,
                            45.108278999799
                        ],
                        [
                            -87.6599519999748,
                            45.1075120003146
                        ],
                        [
                            -87.6525120000781,
                            45.1086330001328
                        ],
                        [
                            -87.6481910002553,
                            45.1063680001699
                        ],
                        [
                            -87.6315349997042,
                            45.1062239997108
                        ],
                        [
                            -87.6277169998465,
                            45.1033499999353
                        ],
                        [
                            -87.5918799997707,
                            45.0946890001531
                        ],
                        [
                            -87.5853880000782,
                            45.0969249999411
                        ],
                        [
                            -87.44250799958,
                            45.0764339998307
                        ],
                        [
                            -87.405693999886,
                            45.2019690000849
                        ],
                        [
                            -87.3154219996425,
                            45.2406569998735
                        ],
                        [
                            -87.1711700004143,
                            45.3525400001324
                        ],
                        [
                            -87.1011330002048,
                            45.4442300001973
                        ],
                        [
                            -86.7542360001843,
                            45.4436099997207
                        ],
                        [
                            -86.2495479999101,
                            45.2361249999463
                        ],
                        [
                            -86.433127999732,
                            45.1249910001394
                        ],
                        [
                            -86.4999300004149,
                            45.0807999998192
                        ],
                        [
                            -86.6862879998288,
                            44.8810020000028
                        ],
                        [
                            -86.8551839996759,
                            44.5003389998039
                        ],
                        [
                            -86.8476940004488,
                            44.5003389998039
                        ],
                        [
                            -87.000080000233,
                            44.1548209998068
                        ],
                        [
                            -87.000072999662,
                            44.1632359998216
                        ],
                        [
                            -87.0132340003956,
                            44.1319049997823
                        ],
                        [
                            -87.0462489996003,
                            43.999999999853
                        ],
                        [
                            -87.0488470001655,
                            43.999999999853
                        ],
                        [
                            -87.1141020004196,
                            43.7341109999213
                        ],
                        [
                            -87.1250789999665,
                            43.670445000247
                        ],
                        [
                            -87.147166000028,
                            43.3798590001406
                        ],
                        [
                            -87.1250720002938,
                            43.2277370001511
                        ],
                        [
                            -87.1083970003744,
                            43.1250100000078
                        ],
                        [
                            -87.0199350003255,
                            42.4934979997221
                        ],
                        [
                            -87.3750580000652,
                            42.4938150003252
                        ],
                        [
                            -87.7933709995962,
                            42.4917199999595
                        ],
                        [
                            -87.961771999874,
                            42.4947560000858
                        ],
                        [
                            -88.138878000445,
                            42.4956039998572
                        ],
                        [
                            -88.6289970003722,
                            42.4950449996741
                        ],
                        [
                            -88.776493000246,
                            42.4919120002119
                        ],
                        [
                            -89.6698199997799,
                            42.505043999862
                        ],
                        [
                            -89.9552369997463,
                            42.5056509997906
                        ],
                        [
                            -90.0736700001674,
                            42.5082750003305
                        ],
                        [
                            -90.4017029999573,
                            42.5068889998929
                        ],
                        [
                            -90.6428429998685,
                            42.5084810000285
                        ],
                        [
                            -90.6359889997777,
                            42.515409000118
                        ],
                        [
                            -90.6358289999442,
                            42.5179279997136
                        ],
                        [
                            -90.6413730003308,
                            42.5285749997643
                        ],
                        [
                            -90.6446870003983,
                            42.5413169999123
                        ],
                        [
                            -90.654126999562,
                            42.5498999999572
                        ],
                        [
                            -90.6591269999756,
                            42.5578999998577
                        ],
                        [
                            -90.6615270001741,
                            42.5679989998069
                        ],
                        [
                            -90.6779350001297,
                            42.5800310002963
                        ],
                        [
                            -90.6869750001587,
                            42.5917740001689
                        ],
                        [
                            -90.6879989996326,
                            42.5991979999216
                        ],
                        [
                            -90.6939990001288,
                            42.6145090001406
                        ],
                        [
                            -90.7000949999862,
                            42.6224610001319
                        ],
                        [
                            -90.7026709999118,
                            42.630756000099
                        ],
                        [
                            -90.7092039996122,
                            42.6360780003162
                        ],
                        [
                            -90.7202089996465,
                            42.640758000228
                        ],
                        [
                            -90.7694950003361,
                            42.6514429997789
                        ],
                        [
                            -90.8439100002697,
                            42.6630709998636
                        ],
                        [
                            -90.8874299999165,
                            42.6724700003124
                        ],
                        [
                            -90.900261000039,
                            42.6762540000109
                        ],
                        [
                            -90.9211549996739,
                            42.6854060001879
                        ],
                        [
                            -90.9370449995957,
                            42.6833989998717
                        ],
                        [
                            -90.9492100004446,
                            42.6855690003211
                        ],
                        [
                            -90.9650479995896,
                            42.6932329997361
                        ],
                        [
                            -90.977734999772,
                            42.6968159999695
                        ],
                        [
                            -90.9887759997913,
                            42.7087240001163
                        ],
                        [
                            -91.0001280001553,
                            42.716188999763
                        ],
                        [
                            -91.0095770004382,
                            42.7201229998299
                        ],
                        [
                            -91.0172390000927,
                            42.7195660002736
                        ],
                        [
                            -91.0267860002849,
                            42.7242279998871
                        ],
                        [
                            -91.0296919999234,
                            42.7267740001466
                        ],
                        [
                            -91.0320129995808,
                            42.7344840002126
                        ],
                        [
                            -91.0354179998849,
                            42.7373399999279
                        ],
                        [
                            -91.0441390000717,
                            42.7386050002548
                        ],
                        [
                            -91.0499720001633,
                            42.7369050002706
                        ],
                        [
                            -91.0537330001196,
                            42.7382380003222
                        ],
                        [
                            -91.0562970003496,
                            42.747340999843
                        ],
                        [
                            -91.0601720001086,
                            42.7504809999673
                        ],
                        [
                            -91.0646800004276,
                            42.750913999987
                        ],
                        [
                            -91.0657830004424,
                            42.7533869999539
                        ],
                        [
                            -91.0654920001893,
                            42.7570810001507
                        ],
                        [
                            -91.061431999584,
                            42.757974000014
                        ],
                        [
                            -91.0602609997971,
                            42.7618469996807
                        ],
                        [
                            -91.0695490004215,
                            42.769628000113
                        ],
                        [
                            -91.0708299998133,
                            42.7822250000685
                        ],
                        [
                            -91.0780970003559,
                            42.8065259999684
                        ],
                        [
                            -91.0786649997202,
                            42.8276779999353
                        ],
                        [
                            -91.082769999633,
                            42.829976999752
                        ],
                        [
                            -91.0901359999098,
                            42.8292370000261
                        ],
                        [
                            -91.0940600000727,
                            42.8308129998058
                        ],
                        [
                            -91.0951139996837,
                            42.8349660003135
                        ],
                        [
                            -91.0914020001313,
                            42.8498599999896
                        ],
                        [
                            -91.0976560001725,
                            42.8598710001365
                        ],
                        [
                            -91.0982379997804,
                            42.8757979998261
                        ],
                        [
                            -91.100565000184,
                            42.8830780002169
                        ],
                        [
                            -91.1174109998075,
                            42.8958370002869
                        ],
                        [
                            -91.1447939996603,
                            42.9059969997715
                        ],
                        [
                            -91.1461820000805,
                            42.9123380002158
                        ],
                        [
                            -91.1438780001415,
                            42.9206460003185
                        ],
                        [
                            -91.1443149999845,
                            42.9265920000532
                        ],
                        [
                            -91.1498800002875,
                            42.9419549999189
                        ],
                        [
                            -91.1455400001981,
                            42.9565100000035
                        ],
                        [
                            -91.1459350002083,
                            42.9607699998851
                        ],
                        [
                            -91.1480009995976,
                            42.9661550002244
                        ],
                        [
                            -91.1565619999509,
                            42.9782260003007
                        ],
                        [
                            -91.1574900000635,
                            42.9914749998379
                        ],
                        [
                            -91.1746920002376,
                            43.0387130001764
                        ],
                        [
                            -91.1778940001521,
                            43.0642059996809
                        ],
                        [
                            -91.1794569995761,
                            43.06742700031
                        ],
                        [
                            -91.1772639999652,
                            43.0729829997536
                        ],
                        [
                            -91.1751929996547,
                            43.1037709999086
                        ],
                        [
                            -91.1779319997869,
                            43.1288749999039
                        ],
                        [
                            -91.1752529999291,
                            43.1346649999959
                        ],
                        [
                            -91.1561999995526,
                            43.1429450002945
                        ],
                        [
                            -91.1461999996239,
                            43.1524049996772
                        ],
                        [
                            -91.1432830001147,
                            43.1564129998076
                        ],
                        [
                            -91.1386490002974,
                            43.1699930001915
                        ],
                        [
                            -91.1244280001049,
                            43.1878860002178
                        ],
                        [
                            -91.1221700001967,
                            43.197255000236
                        ],
                        [
                            -91.0874560002272,
                            43.2218909999556
                        ],
                        [
                            -91.0663979996624,
                            43.2392930000581
                        ],
                        [
                            -91.059684000212,
                            43.248566000069
                        ],
                        [
                            -91.0579180003983,
                            43.2553660000226
                        ],
                        [
                            -91.0597500003342,
                            43.2590739997603
                        ],
                        [
                            -91.0699369998607,
                            43.2602720002273
                        ],
                        [
                            -91.0726490002288,
                            43.2621290000198
                        ],
                        [
                            -91.0717239995908,
                            43.2713919997991
                        ],
                        [
                            -91.0737100004108,
                            43.2747460000955
                        ],
                        [
                            -91.0856519998803,
                            43.2918699996848
                        ],
                        [
                            -91.1072369998015,
                            43.3136449999018
                        ],
                        [
                            -91.137342999893,
                            43.329756999891
                        ],
                        [
                            -91.1548060001829,
                            43.3348259999954
                        ],
                        [
                            -91.1811150003331,
                            43.3459260002849
                        ],
                        [
                            -91.2039639999277,
                            43.3498520000649
                        ],
                        [
                            -91.2147699997703,
                            43.3658739998174
                        ],
                        [
                            -91.2141720002688,
                            43.3693779999628
                        ],
                        [
                            -91.2060720000481,
                            43.3749759999641
                        ],
                        [
                            -91.1976699997036,
                            43.3953339998705
                        ],
                        [
                            -91.200358999607,
                            43.4127009998384
                        ],
                        [
                            -91.20314399977,
                            43.419805000046
                        ],
                        [
                            -91.2287499999384,
                            43.4455370002522
                        ],
                        [
                            -91.2333670000373,
                            43.4551679998861
                        ],
                        [
                            -91.2322409995579,
                            43.4600180001267
                        ],
                        [
                            -91.2245859995761,
                            43.4655250002099
                        ],
                        [
                            -91.2203989996475,
                            43.4713059998989
                        ],
                        [
                            -91.2160350001669,
                            43.4811419998452
                        ],
                        [
                            -91.2152819999564,
                            43.484797999741
                        ],
                        [
                            -91.2182699996106,
                            43.4972279997589
                        ],
                        [
                            -91.2173530002669,
                            43.5124739998354
                        ],
                        [
                            -91.2226130000731,
                            43.5178919998855
                        ],
                        [
                            -91.2329410000649,
                            43.5239669998905
                        ],
                        [
                            -91.2367249995874,
                            43.5329299998314
                        ],
                        [
                            -91.243182999843,
                            43.5403090003179
                        ],
                        [
                            -91.2432139998051,
                            43.5507219997008
                        ],
                        [
                            -91.2328120001935,
                            43.5648419997293
                        ],
                        [
                            -91.2318649998142,
                            43.5818220001739
                        ],
                        [
                            -91.2391089998924,
                            43.5897600000397
                        ],
                        [
                            -91.261631000147,
                            43.6061750001754
                        ],
                        [
                            -91.2687480000035,
                            43.6153480001049
                        ],
                        [
                            -91.2684569997504,
                            43.6273520001603
                        ],
                        [
                            -91.262396999878,
                            43.6417600000237
                        ],
                        [
                            -91.2638560004434,
                            43.6476619998582
                        ],
                        [
                            -91.2717490000766,
                            43.6549289998829
                        ],
                        [
                            -91.2732520001245,
                            43.666622999937
                        ],
                        [
                            -91.2712979999899,
                            43.6727529997405
                        ],
                        [
                            -91.2727409997634,
                            43.6766089999418
                        ],
                        [
                            -91.2677920003018,
                            43.6956520001303
                        ],
                        [
                            -91.2684550001006,
                            43.7098240000312
                        ],
                        [
                            -91.2587559995724,
                            43.7234260000289
                        ],
                        [
                            -91.2559319999497,
                            43.7298490002645
                        ],
                        [
                            -91.2554309996343,
                            43.7448759997578
                        ],
                        [
                            -91.2441350002451,
                            43.7746669997475
                        ],
                        [
                            -91.262432999863,
                            43.7921699996905
                        ],
                        [
                            -91.2644360004015,
                            43.8003649998626
                        ],
                        [
                            -91.2674359997513,
                            43.8041660001435
                        ],
                        [
                            -91.2678490002032,
                            43.8087610002789
                        ],
                        [
                            -91.2720369999567,
                            43.8137660000509
                        ],
                        [
                            -91.2757369998135,
                            43.8248659998174
                        ],
                        [
                            -91.2776950001461,
                            43.837740999785
                        ],
                        [
                            -91.2841380003332,
                            43.8470649998367
                        ],
                        [
                            -91.2910019995713,
                            43.8527329997589
                        ],
                        [
                            -91.2988149997369,
                            43.8565550003004
                        ],
                        [
                            -91.3109909995581,
                            43.8673810002367
                        ],
                        [
                            -91.3153099997312,
                            43.8818079999966
                        ],
                        [
                            -91.3206049996975,
                            43.888490999873
                        ],
                        [
                            -91.325465000369,
                            43.8922239997409
                        ],
                        [
                            -91.3381409997824,
                            43.8976640001297
                        ],
                        [
                            -91.3477409996781,
                            43.9119640000169
                        ],
                        [
                            -91.3574250001376,
                            43.9172309997956
                        ],
                        [
                            -91.3632270002671,
                            43.9265569996945
                        ],
                        [
                            -91.3647200002693,
                            43.9348800002628
                        ],
                        [
                            -91.3666419997186,
                            43.9374629997026
                        ],
                        [
                            -91.3857939998294,
                            43.9542390001961
                        ],
                        [
                            -91.4060110003201,
                            43.9639290001833
                        ],
                        [
                            -91.4124910003171,
                            43.9734109999851
                        ],
                        [
                            -91.4241340000358,
                            43.9826310000888
                        ],
                        [
                            -91.4312030002118,
                            43.9954449997525
                        ],
                        [
                            -91.4392649998994,
                            44.0009880001859
                        ],
                        [
                            -91.4635150003329,
                            44.0090409999301
                        ],
                        [
                            -91.4808699997695,
                            44.0081450002525
                        ],
                        [
                            -91.4949880000299,
                            44.0125360001001
                        ],
                        [
                            -91.5071210001934,
                            44.0189800000635
                        ],
                        [
                            -91.5243149999715,
                            44.0214329997414
                        ],
                        [
                            -91.5470280000219,
                            44.0222260000451
                        ],
                        [
                            -91.5590039998266,
                            44.0253150000481
                        ],
                        [
                            -91.5800189998354,
                            44.0269250002544
                        ],
                        [
                            -91.5920699999832,
                            44.0313719999982
                        ],
                        [
                            -91.5976169998446,
                            44.0349649997996
                        ],
                        [
                            -91.6035500003937,
                            44.043681000168
                        ],
                        [
                            -91.6104870003251,
                            44.0493100002913
                        ],
                        [
                            -91.6278960001884,
                            44.0558049997577
                        ],
                        [
                            -91.6381399996163,
                            44.0632949996846
                        ],
                        [
                            -91.6447169996976,
                            44.0627819997966
                        ],
                        [
                            -91.6478729995813,
                            44.0641090000362
                        ],
                        [
                            -91.6595110001756,
                            44.0742029997472
                        ],
                        [
                            -91.6670060003238,
                            44.0869639997264
                        ],
                        [
                            -91.6815299995667,
                            44.0974000002313
                        ],
                        [
                            -91.6953099997182,
                            44.0985699997629
                        ],
                        [
                            -91.7074909995623,
                            44.1039059998194
                        ],
                        [
                            -91.7105970001157,
                            44.1204800001286
                        ],
                        [
                            -91.7215519999213,
                            44.1303419999304
                        ],
                        [
                            -91.7306480000268,
                            44.1329000001897
                        ],
                        [
                            -91.7517469997012,
                            44.134785999994
                        ],
                        [
                            -91.7744859996909,
                            44.1475390002434
                        ],
                        [
                            -91.8080640000335,
                            44.1592620000754
                        ],
                        [
                            -91.8173020004292,
                            44.1642349997954
                        ],
                        [
                            -91.8291669999059,
                            44.1783499998752
                        ],
                        [
                            -91.8447539998789,
                            44.184877999693
                        ],
                        [
                            -91.8643870002136,
                            44.1965740001464
                        ],
                        [
                            -91.8751579998962,
                            44.2005750000134
                        ],
                        [
                            -91.8774290002233,
                            44.2129210002704
                        ],
                        [
                            -91.892698000179,
                            44.2311050002326
                        ],
                        [
                            -91.8929629995946,
                            44.2351490001634
                        ],
                        [
                            -91.8870399999896,
                            44.2517720001731
                        ],
                        [
                            -91.8891320002165,
                            44.2560600001597
                        ],
                        [
                            -91.8960080000486,
                            44.2628709998545
                        ],
                        [
                            -91.8963879999902,
                            44.2746899999686
                        ],
                        [
                            -91.9057889996942,
                            44.2816140002522
                        ],
                        [
                            -91.9202819998733,
                            44.2864960000474
                        ],
                        [
                            -91.9246129997419,
                            44.2918150002165
                        ],
                        [
                            -91.924102000279,
                            44.2970950002521
                        ],
                        [
                            -91.9135740002707,
                            44.3103920001173
                        ],
                        [
                            -91.9186249998395,
                            44.3226710001931
                        ],
                        [
                            -91.9255900002584,
                            44.3335480000276
                        ],
                        [
                            -91.9413110001258,
                            44.3409779997263
                        ],
                        [
                            -91.9595230004282,
                            44.3594040001935
                        ],
                        [
                            -91.9668090003391,
                            44.3639860003087
                        ],
                        [
                            -91.9771029999957,
                            44.3681359999626
                        ],
                        [
                            -91.9872889996973,
                            44.3691189997244
                        ],
                        [
                            -92.0061789998672,
                            44.3788250000273
                        ],
                        [
                            -92.0193129999384,
                            44.3812170002698
                        ],
                        [
                            -92.0381470000318,
                            44.3887310000697
                        ],
                        [
                            -92.0564859996576,
                            44.4027289999786
                        ],
                        [
                            -92.0786050004045,
                            44.4048690001617
                        ],
                        [
                            -92.0872389996543,
                            44.4088499999859
                        ],
                        [
                            -92.1123080004206,
                            44.4143319998581
                        ],
                        [
                            -92.1245129996558,
                            44.422114999826
                        ],
                        [
                            -92.1624539996542,
                            44.4272079997312
                        ],
                        [
                            -92.1953780004188,
                            44.4337920003189
                        ],
                        [
                            -92.2151630001912,
                            44.4385030000003
                        ],
                        [
                            -92.2308559995711,
                            44.4444960000234
                        ],
                        [
                            -92.2448840003181,
                            44.4568420002818
                        ],
                        [
                            -92.2739789995579,
                            44.471882000053
                        ],
                        [
                            -92.2910050000048,
                            44.4854640002326
                        ],
                        [
                            -92.3022150000785,
                            44.5002980001132
                        ],
                        [
                            -92.3030460001068,
                            44.5186459996972
                        ],
                        [
                            -92.3108269995871,
                            44.5287559999266
                        ],
                        [
                            -92.3140710002326,
                            44.5380139998854
                        ],
                        [
                            -92.3223410003327,
                            44.5467789997064
                        ],
                        [
                            -92.3361139999132,
                            44.5540039998599
                        ],
                        [
                            -92.3615180004153,
                            44.5589349999888
                        ],
                        [
                            -92.3915449999657,
                            44.5576429997038
                        ],
                        [
                            -92.4021049997611,
                            44.5586060001027
                        ],
                        [
                            -92.4150890000444,
                            44.5603590001373
                        ],
                        [
                            -92.4311010001649,
                            44.5657859998604
                        ],
                        [
                            -92.4407460002664,
                            44.5628549999443
                        ],
                        [
                            -92.4550899995843,
                            44.5620230001726
                        ],
                        [
                            -92.4809860001809,
                            44.5684489998466
                        ],
                        [
                            -92.4937169998459,
                            44.5660520000664
                        ],
                        [
                            -92.5086669999595,
                            44.5703129997796
                        ],
                        [
                            -92.5192180004324,
                            44.5752749996899
                        ],
                        [
                            -92.5279999998202,
                            44.5732500000667
                        ],
                        [
                            -92.5410159998906,
                            44.5671269996812
                        ],
                        [
                            -92.5488890003306,
                            44.5682519997631
                        ],
                        [
                            -92.5515670003634,
                            44.5722149998064
                        ],
                        [
                            -92.5493389996941,
                            44.5778469997078
                        ],
                        [
                            -92.5498269995907,
                            44.5810979998642
                        ],
                        [
                            -92.5689460000895,
                            44.6033719998859
                        ],
                        [
                            -92.5771559999151,
                            44.6050370000785
                        ],
                        [
                            -92.581588000066,
                            44.6008509999148
                        ],
                        [
                            -92.5862160000356,
                            44.6000880000335
                        ],
                        [
                            -92.5887969999841,
                            44.6016980000593
                        ],
                        [
                            -92.590488000353,
                            44.6059440001593
                        ],
                        [
                            -92.601608000015,
                            44.6120380002335
                        ],
                        [
                            -92.6147129997738,
                            44.6116579998054
                        ],
                        [
                            -92.6216420002077,
                            44.614929000208
                        ],
                        [
                            -92.6235660002051,
                            44.6206400001601
                        ],
                        [
                            -92.6202620001834,
                            44.6286539997056
                        ],
                        [
                            -92.6202150003277,
                            44.6341940000406
                        ],
                        [
                            -92.6218470002471,
                            44.6390179996983
                        ],
                        [
                            -92.6320210002531,
                            44.6491869999551
                        ],
                        [
                            -92.6609880003447,
                            44.6608999999722
                        ],
                        [
                            -92.6943199997417,
                            44.6886150000621
                        ],
                        [
                            -92.7320430001801,
                            44.7143449999939
                        ],
                        [
                            -92.7556709999244,
                            44.7232430001043
                        ],
                        [
                            -92.7719480003588,
                            44.7320770001509
                        ],
                        [
                            -92.7923359996555,
                            44.7393620000284
                        ],
                        [
                            -92.8082420003692,
                            44.7510830000029
                        ],
                        [
                            -92.8076869996271,
                            44.759285000258
                        ],
                        [
                            -92.8044450004279,
                            44.7707599999577
                        ],
                        [
                            -92.7991649996319,
                            44.7789690001343
                        ],
                        [
                            -92.7856220002052,
                            44.7918869999466
                        ],
                        [
                            -92.7807540000361,
                            44.8122999999507
                        ],
                        [
                            -92.7729109997333,
                            44.8215030003056
                        ],
                        [
                            -92.7724379999053,
                            44.8281899997304
                        ],
                        [
                            -92.7659319999691,
                            44.8356309998568
                        ],
                        [
                            -92.7695010003047,
                            44.8620010001343
                        ],
                        [
                            -92.7634110002951,
                            44.8741400000499
                        ],
                        [
                            -92.7739459999761,
                            44.8899969999078
                        ],
                        [
                            -92.7745710001401,
                            44.8980839999054
                        ],
                        [
                            -92.7731030002522,
                            44.9013669999404
                        ],
                        [
                            -92.7587010003098,
                            44.908978999945
                        ],
                        [
                            -92.7506449995716,
                            44.937298999984
                        ],
                        [
                            -92.7546030000697,
                            44.9557669997512
                        ],
                        [
                            -92.7606999997519,
                            44.964978999813
                        ],
                        [
                            -92.7672180002821,
                            44.9680839997128
                        ],
                        [
                            -92.7694450002282,
                            44.9721499997619
                        ],
                        [
                            -92.7703459996784,
                            44.9833280002514
                        ],
                        [
                            -92.7690490003931,
                            44.988195000238
                        ],
                        [
                            -92.7712310001334,
                            45.0013780000113
                        ],
                        [
                            -92.7620599996848,
                            45.024320000222
                        ],
                        [
                            -92.7646039998233,
                            45.0287670002637
                        ],
                        [
                            -92.7703619995719,
                            45.0338029997251
                        ],
                        [
                            -92.7955860001489,
                            45.0492439999037
                        ],
                        [
                            -92.8015680002035,
                            45.0565070001207
                        ],
                        [
                            -92.8031239999548,
                            45.0615660000308
                        ],
                        [
                            -92.8015220001727,
                            45.068623000193
                        ],
                        [
                            -92.7926570000459,
                            45.0787620003079
                        ],
                        [
                            -92.7467489998962,
                            45.1070510002393
                        ],
                        [
                            -92.7395829996359,
                            45.1155979999773
                        ],
                        [
                            -92.7406100003811,
                            45.118453999913
                        ],
                        [
                            -92.7456919999121,
                            45.1231119997578
                        ],
                        [
                            -92.7525059998199,
                            45.145965999964
                        ],
                        [
                            -92.7573520002476,
                            45.152350999731
                        ],
                        [
                            -92.7581320002223,
                            45.1596769997219
                        ],
                        [
                            -92.7569070000087,
                            45.1651659997683
                        ],
                        [
                            -92.7524039997126,
                            45.1739159997959
                        ],
                        [
                            -92.7668080002032,
                            45.18546600018
                        ],
                        [
                            -92.7671300004185,
                            45.1941650001125
                        ],
                        [
                            -92.7639080004125,
                            45.2048659999631
                        ],
                        [
                            -92.7540049996699,
                            45.2127689997442
                        ],
                        [
                            -92.7517080003018,
                            45.218666000019
                        ],
                        [
                            -92.7574560000047,
                            45.2305260000408
                        ],
                        [
                            -92.7602639997339,
                            45.2517170002524
                        ],
                        [
                            -92.7536210004286,
                            45.259419000156
                        ],
                        [
                            -92.7519200000139,
                            45.2658770002116
                        ],
                        [
                            -92.7538440000114,
                            45.2709589997707
                        ],
                        [
                            -92.7606119998884,
                            45.2788279997549
                        ],
                        [
                            -92.7620039996083,
                            45.2882129999528
                        ],
                        [
                            -92.7587099996323,
                            45.2909649997698
                        ],
                        [
                            -92.7511550001079,
                            45.2928270000287
                        ],
                        [
                            -92.7371210004115,
                            45.3004590001845
                        ],
                        [
                            -92.7277370004258,
                            45.3092879999422
                        ],
                        [
                            -92.7086749998285,
                            45.3225249999361
                        ],
                        [
                            -92.6989669999778,
                            45.3363740001818
                        ],
                        [
                            -92.6995229996465,
                            45.3424210002209
                        ],
                        [
                            -92.70405400043,
                            45.3536599999878
                        ],
                        [
                            -92.7027229999112,
                            45.3584680002338
                        ],
                        [
                            -92.6885279996579,
                            45.3684289997519
                        ],
                        [
                            -92.6791870002284,
                            45.3727099999109
                        ],
                        [
                            -92.676962999757,
                            45.3801439999731
                        ],
                        [
                            -92.6641040000454,
                            45.3933080001731
                        ],
                        [
                            -92.6504200001533,
                            45.398509999841
                        ],
                        [
                            -92.6505680002914,
                            45.4033109998196
                        ],
                        [
                            -92.6466759999156,
                            45.4132270002599
                        ],
                        [
                            -92.6502689996423,
                            45.4191680002277
                        ],
                        [
                            -92.6466020002957,
                            45.4416350002183
                        ],
                        [
                            -92.6535490002729,
                            45.4553460000073
                        ],
                        [
                            -92.6802340001667,
                            45.4643439997533
                        ],
                        [
                            -92.6825959998321,
                            45.4699089997533
                        ],
                        [
                            -92.6909620001916,
                            45.475442000112
                        ],
                        [
                            -92.7008990003712,
                            45.4916089998334
                        ],
                        [
                            -92.712862999582,
                            45.5048489998076
                        ],
                        [
                            -92.7266770000687,
                            45.514461999916
                        ],
                        [
                            -92.7280229997578,
                            45.525651999948
                        ],
                        [
                            -92.7246500001391,
                            45.5367440000365
                        ],
                        [
                            -92.7260820000419,
                            45.54111199986
                        ],
                        [
                            -92.7455909996298,
                            45.5530160001641
                        ],
                        [
                            -92.7569060001838,
                            45.5574989998254
                        ],
                        [
                            -92.7702229996547,
                            45.566939000089
                        ],
                        [
                            -92.7759879999743,
                            45.5684780000085
                        ],
                        [
                            -92.7857410000308,
                            45.5678879999334
                        ],
                        [
                            -92.8015029999061,
                            45.5628539999273
                        ],
                        [
                            -92.8233089997603,
                            45.5609340000497
                        ],
                        [
                            -92.8464469999581,
                            45.566514999966
                        ],
                        [
                            -92.8710820003562,
                            45.5675809997753
                        ],
                        [
                            -92.8837490004471,
                            45.5754829999659
                        ],
                        [
                            -92.8849539996709,
                            45.5788179999829
                        ],
                        [
                            -92.883277000444,
                            45.5898309997506
                        ],
                        [
                            -92.8864209997338,
                            45.5948809997645
                        ],
                        [
                            -92.8864419996502,
                            45.5986789997729
                        ],
                        [
                            -92.8825290002563,
                            45.6102159999332
                        ],
                        [
                            -92.8880350001098,
                            45.6249590001359
                        ],
                        [
                            -92.8868569997519,
                            45.6357229999685
                        ],
                        [
                            -92.8894329996775,
                            45.6412019999931
                        ],
                        [
                            -92.8870669998142,
                            45.644148000243
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 44.6309071,
            "name": "Wisconsin",
            "intptlon": -89.7093916,
            "geo_point_2d": [
                44.6398938154,
                -89.7328946459
            ],
            "geoid": "55",
            "mtfcc": "G4000",
            "region": 2
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -89.7328946459,
                44.6398938154
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "4b5de78f8bd963de2e025241772a27723b270748",
        "fields": {
            "arealand": "-1896337421",
            "objectid": 10,
            "basename": "Vermont",
            "stusab": "VT",
            "statens": "01779802",
            "centlon": -72.666041,
            "state": "50",
            "gid": 44,
            "centlat": 44.0693874,
            "division": 1,
            "areawater": "1031126051",
            "oid": "-1858637503",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -73.3132829996123,
                            44.2641300000495
                        ],
                        [
                            -73.3109140002743,
                            44.2742979997269
                        ],
                        [
                            -73.3122099997346,
                            44.2800689996869
                        ],
                        [
                            -73.3222160004095,
                            44.3015419999481
                        ],
                        [
                            -73.3241949997602,
                            44.3100330001025
                        ],
                        [
                            -73.3240209996829,
                            44.33383500018
                        ],
                        [
                            -73.327353000192,
                            44.3443600001457
                        ],
                        [
                            -73.3346480003238,
                            44.3568790000231
                        ],
                        [
                            -73.3349500004476,
                            44.3644400001339
                        ],
                        [
                            -73.333613000081,
                            44.3722999999638
                        ],
                        [
                            -73.3150400001034,
                            44.3885260002459
                        ],
                        [
                            -73.309598999649,
                            44.4045050002349
                        ],
                        [
                            -73.2960520000242,
                            44.4283340000149
                        ],
                        [
                            -73.2935940000994,
                            44.4402320000585
                        ],
                        [
                            -73.30012500015,
                            44.4547109999022
                        ],
                        [
                            -73.2986470002164,
                            44.4683969999495
                        ],
                        [
                            -73.2998959998211,
                            44.4766510000025
                        ],
                        [
                            -73.3041070000392,
                            44.4845850001013
                        ],
                        [
                            -73.306713000102,
                            44.5003349997885
                        ],
                        [
                            -73.3208460004309,
                            44.5136299999069
                        ],
                        [
                            -73.3220389999592,
                            44.5252859999063
                        ],
                        [
                            -73.3297459998195,
                            44.5295650002126
                        ],
                        [
                            -73.3316079998927,
                            44.5359199999644
                        ],
                        [
                            -73.3390059999566,
                            44.5433009999509
                        ],
                        [
                            -73.3387520004117,
                            44.5480460002115
                        ],
                        [
                            -73.3440010003472,
                            44.5525089998563
                        ],
                        [
                            -73.3567889999137,
                            44.5579190000975
                        ],
                        [
                            -73.3749500001625,
                            44.575816999964
                        ],
                        [
                            -73.375667000388,
                            44.5820380000689
                        ],
                        [
                            -73.3818489995608,
                            44.589316000005
                        ],
                        [
                            -73.3768060003879,
                            44.5954560001715
                        ],
                        [
                            -73.3768490000456,
                            44.599598999864
                        ],
                        [
                            -73.3807249996295,
                            44.6052400000214
                        ],
                        [
                            -73.3829320003825,
                            44.6121839997604
                        ],
                        [
                            -73.3902309998139,
                            44.6183539997159
                        ],
                        [
                            -73.3864969996218,
                            44.6269250003094
                        ],
                        [
                            -73.3867829998521,
                            44.6363699999932
                        ],
                        [
                            -73.3785599996077,
                            44.6414760002184
                        ],
                        [
                            -73.384483000111,
                            44.6466830002878
                        ],
                        [
                            -73.3783169999334,
                            44.652164999699
                        ],
                        [
                            -73.3790729996187,
                            44.6567720001265
                        ],
                        [
                            -73.3745769998936,
                            44.6618900001269
                        ],
                        [
                            -73.3696679997167,
                            44.6634780001492
                        ],
                        [
                            -73.3727190000184,
                            44.6687390000569
                        ],
                        [
                            -73.3710100001062,
                            44.6727629998659
                        ],
                        [
                            -73.3718429997843,
                            44.6769569997252
                        ],
                        [
                            -73.367208999967,
                            44.6785140000284
                        ],
                        [
                            -73.3701420002679,
                            44.6848550000944
                        ],
                        [
                            -73.3652969996651,
                            44.6875479998151
                        ],
                        [
                            -73.3613080001033,
                            44.6945239998961
                        ],
                        [
                            -73.3659770000807,
                            44.6975589997867
                        ],
                        [
                            -73.365561999979,
                            44.7417860000555
                        ],
                        [
                            -73.3543610001261,
                            44.7552959997836
                        ],
                        [
                            -73.3470850002609,
                            44.7729800000534
                        ],
                        [
                            -73.3357129998055,
                            44.7820859998717
                        ],
                        [
                            -73.3331539995983,
                            44.7887590000772
                        ],
                        [
                            -73.3350440001589,
                            44.8041090001295
                        ],
                        [
                            -73.3534720003715,
                            44.8203859997252
                        ],
                        [
                            -73.3682750001719,
                            44.828007000089
                        ],
                        [
                            -73.3753459999976,
                            44.8363069998952
                        ],
                        [
                            -73.3794519997354,
                            44.8380100001916
                        ],
                        [
                            -73.3794469997125,
                            44.843461000098
                        ],
                        [
                            -73.3813360004481,
                            44.844683000293
                        ],
                        [
                            -73.3803210002968,
                            44.8471729998786
                        ],
                        [
                            -73.381589999993,
                            44.8493539998212
                        ],
                        [
                            -73.3795449996218,
                            44.8516509999239
                        ],
                        [
                            -73.3798219996312,
                            44.8570380002391
                        ],
                        [
                            -73.3719680003561,
                            44.86241499983
                        ],
                        [
                            -73.3691039996521,
                            44.8666809998313
                        ],
                        [
                            -73.360328000112,
                            44.8972360000828
                        ],
                        [
                            -73.3562180001764,
                            44.9044919998408
                        ],
                        [
                            -73.3506520000484,
                            44.9096339998912
                        ],
                        [
                            -73.3411059996811,
                            44.914631999774
                        ],
                        [
                            -73.3389790001924,
                            44.9176809999156
                        ],
                        [
                            -73.3396029996331,
                            44.9433709997122
                        ],
                        [
                            -73.3382440004236,
                            44.9647510000861
                        ],
                        [
                            -73.3502179996801,
                            44.9762220001631
                        ],
                        [
                            -73.3546330001126,
                            44.9873520001485
                        ],
                        [
                            -73.3431240002882,
                            45.0108399999989
                        ],
                        [
                            -73.0651349996246,
                            45.0159570001851
                        ],
                        [
                            -72.9680920000293,
                            45.0140920001978
                        ],
                        [
                            -72.8456330002364,
                            45.016659000173
                        ],
                        [
                            -72.64182199968,
                            45.0147879996864
                        ],
                        [
                            -72.5898799995972,
                            45.0132369999699
                        ],
                        [
                            -72.5559120001656,
                            45.0083040000785
                        ],
                        [
                            -72.4495459997858,
                            45.0085470000565
                        ],
                        [
                            -72.270869000293,
                            45.0041860000335
                        ],
                        [
                            -71.9150090002364,
                            45.0077910000564
                        ],
                        [
                            -71.7630710001659,
                            45.011389000096
                        ],
                        [
                            -71.4650499998578,
                            45.0136380002632
                        ],
                        [
                            -71.4667379998536,
                            45.0115989998661
                        ],
                        [
                            -71.476347999795,
                            45.0094260002512
                        ],
                        [
                            -71.4807680002504,
                            45.0022699999179
                        ],
                        [
                            -71.4888390001588,
                            45.0007819998252
                        ],
                        [
                            -71.4953430004452,
                            45.0046019999761
                        ],
                        [
                            -71.4984170003132,
                            45.0039439997183
                        ],
                        [
                            -71.5043700000555,
                            45.0082730002163
                        ],
                        [
                            -71.5085379997174,
                            45.007891000004
                        ],
                        [
                            -71.5140360000733,
                            45.0043840001661
                        ],
                        [
                            -71.5304030000211,
                            44.9993640002263
                        ],
                        [
                            -71.5370140004376,
                            44.9934809997467
                        ],
                        [
                            -71.5331379999554,
                            44.9908570000481
                        ],
                        [
                            -71.5384329999217,
                            44.9897039998461
                        ],
                        [
                            -71.5413329997124,
                            44.9839370001908
                        ],
                        [
                            -71.5367549999715,
                            44.983898999834
                        ],
                        [
                            -71.5371969998374,
                            44.9807319999392
                        ],
                        [
                            -71.5329710004491,
                            44.9798930000454
                        ],
                        [
                            -71.5311789997978,
                            44.9750149997765
                        ],
                        [
                            -71.5274470001539,
                            44.9731869997257
                        ],
                        [
                            -71.5255249998063,
                            44.9696239997258
                        ],
                        [
                            -71.521952999996,
                            44.9691889997894
                        ],
                        [
                            -71.5229199995684,
                            44.9661559997705
                        ],
                        [
                            -71.5179550002133,
                            44.9676549998909
                        ],
                        [
                            -71.5235949999611,
                            44.9641189998526
                        ],
                        [
                            -71.5162990000045,
                            44.964404000126
                        ],
                        [
                            -71.515000999996,
                            44.958596999718
                        ],
                        [
                            -71.5158570001386,
                            44.952758000224
                        ],
                        [
                            -71.519240999628,
                            44.9496199999525
                        ],
                        [
                            -71.5167579995888,
                            44.9484170001902
                        ],
                        [
                            -71.5177950003797,
                            44.9449939998355
                        ],
                        [
                            -71.5147900001087,
                            44.943694999879
                        ],
                        [
                            -71.5170680001085,
                            44.93911699983
                        ],
                        [
                            -71.5198959999292,
                            44.9384879996993
                        ],
                        [
                            -71.5212969998699,
                            44.9406249999234
                        ],
                        [
                            -71.5228379995526,
                            44.9399150000958
                        ],
                        [
                            -71.5162279998594,
                            44.9351580000183
                        ],
                        [
                            -71.5158680000093,
                            44.931714999865
                        ],
                        [
                            -71.5132219997635,
                            44.929210999846
                        ],
                        [
                            -71.5157230002443,
                            44.9272319996854
                        ],
                        [
                            -71.5124960002155,
                            44.9262030002708
                        ],
                        [
                            -71.5043800001012,
                            44.9191830002543
                        ],
                        [
                            -71.5007000003358,
                            44.9146280002953
                        ],
                        [
                            -71.4999449995772,
                            44.9111740001923
                        ],
                        [
                            -71.4931690002026,
                            44.9113700000929
                        ],
                        [
                            -71.4960170001147,
                            44.9088979997779
                        ],
                        [
                            -71.4949099999019,
                            44.9042990000167
                        ],
                        [
                            -71.5005229998855,
                            44.904713000205
                        ],
                        [
                            -71.5073719999534,
                            44.8994889999279
                        ],
                        [
                            -71.5082730003019,
                            44.8965200001966
                        ],
                        [
                            -71.5138829999125,
                            44.8943110001273
                        ],
                        [
                            -71.5106690003024,
                            44.8904300002553
                        ],
                        [
                            -71.5160849997443,
                            44.8845229997245
                        ],
                        [
                            -71.5189560001209,
                            44.8846639997999
                        ],
                        [
                            -71.5225770003351,
                            44.8796430003088
                        ],
                        [
                            -71.5283719998936,
                            44.8777120000751
                        ],
                        [
                            -71.5287510000103,
                            44.8734939996869
                        ],
                        [
                            -71.5329110001746,
                            44.8702219999823
                        ],
                        [
                            -71.5459230000471,
                            44.8659320000843
                        ],
                        [
                            -71.5494510003748,
                            44.8620840001181
                        ],
                        [
                            -71.5474520000343,
                            44.8553979999886
                        ],
                        [
                            -71.5557940001044,
                            44.8485669999536
                        ],
                        [
                            -71.5515770000386,
                            44.8427569997867
                        ],
                        [
                            -71.5511140002563,
                            44.8369650003034
                        ],
                        [
                            -71.5572539995963,
                            44.8338730001658
                        ],
                        [
                            -71.5618360004334,
                            44.8236250002422
                        ],
                        [
                            -71.5653139996341,
                            44.8241000002395
                        ],
                        [
                            -71.5731510000891,
                            44.8208230003074
                        ],
                        [
                            -71.5768650001897,
                            44.8151969999444
                        ],
                        [
                            -71.5764169995777,
                            44.8127300001436
                        ],
                        [
                            -71.5683840002024,
                            44.8070400000435
                        ],
                        [
                            -71.5727999995615,
                            44.7992060000716
                        ],
                        [
                            -71.5711600001445,
                            44.7943139999912
                        ],
                        [
                            -71.5728170001783,
                            44.7908709998258
                        ],
                        [
                            -71.5791819996492,
                            44.7849360002278
                        ],
                        [
                            -71.5924859995996,
                            44.7826689999468
                        ],
                        [
                            -71.5956409996585,
                            44.779312000267
                        ],
                        [
                            -71.5950990002335,
                            44.7754050000753
                        ],
                        [
                            -71.6005470003607,
                            44.7729340002559
                        ],
                        [
                            -71.6062130000477,
                            44.7668189999592
                        ],
                        [
                            -71.6113549998532,
                            44.7653290002376
                        ],
                        [
                            -71.6128560002513,
                            44.7589979998219
                        ],
                        [
                            -71.616669000086,
                            44.7562989997627
                        ],
                        [
                            -71.6303520001532,
                            44.7533070002109
                        ],
                        [
                            -71.6321599997997,
                            44.7509849999366
                        ],
                        [
                            -71.6313820003732,
                            44.7486930001214
                        ],
                        [
                            -71.6269860002072,
                            44.7470140000596
                        ],
                        [
                            -71.625092000347,
                            44.7433609997292
                        ],
                        [
                            -71.6264110002719,
                            44.7405099998087
                        ],
                        [
                            -71.6249359998131,
                            44.7368110002252
                        ],
                        [
                            -71.6260930002546,
                            44.7285430001539
                        ],
                        [
                            -71.623582999553,
                            44.7273160000234
                        ],
                        [
                            -71.617106999754,
                            44.7287119997194
                        ],
                        [
                            -71.6186440001371,
                            44.7221930001796
                        ],
                        [
                            -71.6125480002797,
                            44.7191859999027
                        ],
                        [
                            -71.6125120002947,
                            44.7166730002357
                        ],
                        [
                            -71.6085200003598,
                            44.7139560002074
                        ],
                        [
                            -71.6050139997734,
                            44.708409999688
                        ],
                        [
                            -71.599391999569,
                            44.7053150001472
                        ],
                        [
                            -71.598589000028,
                            44.7032410001503
                        ],
                        [
                            -71.6007699999434,
                            44.6990170000442
                        ],
                        [
                            -71.5938609996011,
                            44.6964149999448
                        ],
                        [
                            -71.5979370000999,
                            44.6922219999034
                        ],
                        [
                            -71.5939590004087,
                            44.6834249998573
                        ],
                        [
                            -71.5966149998019,
                            44.6780879999061
                        ],
                        [
                            -71.581463000022,
                            44.6732040002432
                        ],
                        [
                            -71.5878769998967,
                            44.6699319997685
                        ],
                        [
                            -71.5849329997251,
                            44.6640540000208
                        ],
                        [
                            -71.5873649997106,
                            44.6597150001654
                        ],
                        [
                            -71.5827219996725,
                            44.6558909997055
                        ],
                        [
                            -71.5752149998286,
                            44.6558299999425
                        ],
                        [
                            -71.5752590002096,
                            44.6531020001466
                        ],
                        [
                            -71.5800710003021,
                            44.6520989999233
                        ],
                        [
                            -71.5770810000997,
                            44.6506860001881
                        ],
                        [
                            -71.5690250002599,
                            44.6502899998951
                        ],
                        [
                            -71.5657940000331,
                            44.6538520002459
                        ],
                        [
                            -71.5628060003789,
                            44.6523559997304
                        ],
                        [
                            -71.5619619999318,
                            44.647188999801
                        ],
                        [
                            -71.5577900000718,
                            44.6419599998262
                        ],
                        [
                            -71.5597040000235,
                            44.6392979999561
                        ],
                        [
                            -71.5637749996011,
                            44.6389429997992
                        ],
                        [
                            -71.5637210000728,
                            44.637247000149
                        ],
                        [
                            -71.5537560003041,
                            44.6304259998833
                        ],
                        [
                            -71.5468190003727,
                            44.629756000218
                        ],
                        [
                            -71.5469309996274,
                            44.6285500001258
                        ],
                        [
                            -71.5521040002933,
                            44.6273140003189
                        ],
                        [
                            -71.5544860000501,
                            44.6295319999806
                        ],
                        [
                            -71.557019000318,
                            44.6294859998649
                        ],
                        [
                            -71.5569270002565,
                            44.6274180001954
                        ],
                        [
                            -71.5480010000303,
                            44.6251369997211
                        ],
                        [
                            -71.5463370003238,
                            44.6229550000756
                        ],
                        [
                            -71.547862999938,
                            44.6222450003166
                        ],
                        [
                            -71.5551270001076,
                            44.6252440001124
                        ],
                        [
                            -71.5565459995916,
                            44.6177440002708
                        ],
                        [
                            -71.5536309997324,
                            44.6061319999773
                        ],
                        [
                            -71.5558590004017,
                            44.6037060000846
                        ],
                        [
                            -71.5617639995651,
                            44.6031340002118
                        ],
                        [
                            -71.5621309999862,
                            44.6011809997697
                        ],
                        [
                            -71.5572789997107,
                            44.5993810000605
                        ],
                        [
                            -71.5509460000268,
                            44.6010199998944
                        ],
                        [
                            -71.5506099995679,
                            44.5996930002017
                        ],
                        [
                            -71.5541510003143,
                            44.5979610000093
                        ],
                        [
                            -71.555188000207,
                            44.5933460000603
                        ],
                        [
                            -71.5459719995526,
                            44.597130000101
                        ],
                        [
                            -71.5468929999926,
                            44.5908370000326
                        ],
                        [
                            -71.538494000021,
                            44.594170000243
                        ],
                        [
                            -71.5378689998571,
                            44.5922620001387
                        ],
                        [
                            -71.5400509995974,
                            44.5885239997672
                        ],
                        [
                            -71.5349089997919,
                            44.5878980001824
                        ],
                        [
                            -71.5384639998838,
                            44.5821309999817
                        ],
                        [
                            -71.5457880003279,
                            44.5779339998956
                        ],
                        [
                            -71.5496639999118,
                            44.5783229998271
                        ],
                        [
                            -71.5516790001458,
                            44.5807799999648
                        ],
                        [
                            -71.5502589999386,
                            44.5837939997258
                        ],
                        [
                            -71.5523650004092,
                            44.5843929999912
                        ],
                        [
                            -71.5548059997172,
                            44.5793689998336
                        ],
                        [
                            -71.5477259996707,
                            44.5713809997083
                        ],
                        [
                            -71.5513119997247,
                            44.5691149998988
                        ],
                        [
                            -71.5577110004291,
                            44.5704040000454
                        ],
                        [
                            -71.5591559998524,
                            44.5681379999297
                        ],
                        [
                            -71.558225000265,
                            44.5653769997547
                        ],
                        [
                            -71.5597170004423,
                            44.5639750002451
                        ],
                        [
                            -71.5682350002395,
                            44.5627599998182
                        ],
                        [
                            -71.5761239996747,
                            44.5650789999011
                        ],
                        [
                            -71.5895780003114,
                            44.5657370001277
                        ],
                        [
                            -71.5949379996767,
                            44.5629199997053
                        ],
                        [
                            -71.5969679999793,
                            44.5594710001866
                        ],
                        [
                            -71.598585999655,
                            44.554794999964
                        ],
                        [
                            -71.5766419997086,
                            44.5423590002947
                        ],
                        [
                            -71.5718670003243,
                            44.5379190001837
                        ],
                        [
                            -71.5734990002437,
                            44.5331430001133
                        ],
                        [
                            -71.5807469996214,
                            44.5245980000985
                        ],
                        [
                            -71.5862859999852,
                            44.522346999851
                        ],
                        [
                            -71.592586999882,
                            44.5232609998411
                        ],
                        [
                            -71.5941140002194,
                            44.5215539998772
                        ],
                        [
                            -71.5919319995808,
                            44.5179070002418
                        ],
                        [
                            -71.5863019998787,
                            44.5146720001108
                        ],
                        [
                            -71.582853000092,
                            44.5081799998353
                        ],
                        [
                            -71.5769480000303,
                            44.5043120002412
                        ],
                        [
                            -71.5770240001983,
                            44.5020609998616
                        ],
                        [
                            -71.58292900026,
                            44.5021980001828
                        ],
                        [
                            -71.5857679999513,
                            44.5061879999485
                        ],
                        [
                            -71.5881329999897,
                            44.506585000167
                        ],
                        [
                            -71.5859200002873,
                            44.4993600001738
                        ],
                        [
                            -71.5894300001734,
                            44.4982230000865
                        ],
                        [
                            -71.5910169998869,
                            44.5011070002462
                        ],
                        [
                            -71.5938849998905,
                            44.5010310002664
                        ],
                        [
                            -71.5952130000363,
                            44.4934090000689
                        ],
                        [
                            -71.5986009997236,
                            44.4868249998025
                        ],
                        [
                            -71.6095260002903,
                            44.4842000002125
                        ],
                        [
                            -71.617843000246,
                            44.4858410000144
                        ],
                        [
                            -71.6262050004076,
                            44.4744660001414
                        ],
                        [
                            -71.6317890000789,
                            44.4740229997364
                        ],
                        [
                            -71.6345049997466,
                            44.4756250000733
                        ],
                        [
                            -71.6342300002853,
                            44.4788439998668
                        ],
                        [
                            -71.6276380001353,
                            44.4796759999815
                        ],
                        [
                            -71.6246630000016,
                            44.4817440002524
                        ],
                        [
                            -71.6268609996354,
                            44.4840249997144
                        ],
                        [
                            -71.6327819995906,
                            44.483796000174
                        ],
                        [
                            -71.6346150002498,
                            44.4804620002755
                        ],
                        [
                            -71.6468370001018,
                            44.4732020002986
                        ],
                        [
                            -71.6469769998438,
                            44.4699449997905
                        ],
                        [
                            -71.6396270003589,
                            44.4663510001767
                        ],
                        [
                            -71.6389860003014,
                            44.4644109999383
                        ],
                        [
                            -71.644266000199,
                            44.460250000242
                        ],
                        [
                            -71.6516929996769,
                            44.460872000144
                        ],
                        [
                            -71.654216999724,
                            44.4588230000812
                        ],
                        [
                            -71.6563709998752,
                            44.4551590002496
                        ],
                        [
                            -71.6577559999223,
                            44.4455519998372
                        ],
                        [
                            -71.6608830003921,
                            44.4399369996809
                        ],
                        [
                            -71.6655459996234,
                            44.4367119999342
                        ],
                        [
                            -71.6779619996442,
                            44.4352550000752
                        ],
                        [
                            -71.6792820002923,
                            44.434337000144
                        ],
                        [
                            -71.6759509996081,
                            44.4289059997096
                        ],
                        [
                            -71.6769669995843,
                            44.4268480001852
                        ],
                        [
                            -71.6827009999418,
                            44.4273400000356
                        ],
                        [
                            -71.6842850001806,
                            44.423645000285
                        ],
                        [
                            -71.6941300002986,
                            44.4193910000864
                        ],
                        [
                            -71.7025800003237,
                            44.4135420003017
                        ],
                        [
                            -71.714279000119,
                            44.4095449996835
                        ],
                        [
                            -71.7257429997376,
                            44.4109639997507
                        ],
                        [
                            -71.7354129999535,
                            44.4100049998813
                        ],
                        [
                            -71.7437259997113,
                            44.4010269998691
                        ],
                        [
                            -71.7484609998109,
                            44.4014379997257
                        ],
                        [
                            -71.7560909996784,
                            44.4064009997941
                        ],
                        [
                            -71.7619659996028,
                            44.4070270000113
                        ],
                        [
                            -71.7784460004269,
                            44.3998350000073
                        ],
                        [
                            -71.7908210004397,
                            44.4002469998478
                        ],
                        [
                            -71.7944829997634,
                            44.39900400014
                        ],
                        [
                            -71.8034879996323,
                            44.3918899997019
                        ],
                        [
                            -71.7998849998599,
                            44.3860720001781
                        ],
                        [
                            -71.8005719999481,
                            44.3840279998724
                        ],
                        [
                            -71.8091319995781,
                            44.3837600002163
                        ],
                        [
                            -71.8144270004427,
                            44.3819220002637
                        ],
                        [
                            -71.8156779996973,
                            44.375024999738
                        ],
                        [
                            -71.8123209999721,
                            44.3716369996912
                        ],
                        [
                            -71.8162889996176,
                            44.3674030000136
                        ],
                        [
                            -71.8124130000336,
                            44.3573249999686
                        ],
                        [
                            -71.8158459999268,
                            44.3540059999298
                        ],
                        [
                            -71.8330890001087,
                            44.3502369997343
                        ],
                        [
                            -71.8528499995916,
                            44.3409220001879
                        ],
                        [
                            -71.8631340001007,
                            44.3398989999983
                        ],
                        [
                            -71.8715880003238,
                            44.3366799999803
                        ],
                        [
                            -71.9005649995628,
                            44.3470410000284
                        ],
                        [
                            -71.9124220004401,
                            44.3481240001679
                        ],
                        [
                            -71.917212999718,
                            44.3466359998913
                        ],
                        [
                            -71.9252250000752,
                            44.3418529997813
                        ],
                        [
                            -71.9290999998342,
                            44.3376260000486
                        ],
                        [
                            -71.9340440001713,
                            44.3360009999833
                        ],
                        [
                            -71.9455949998285,
                            44.337709999882
                        ],
                        [
                            -71.9630370002021,
                            44.3365119998122
                        ],
                        [
                            -71.9814240004067,
                            44.3373659999546
                        ],
                        [
                            -71.9846589999332,
                            44.3360159996896
                        ],
                        [
                            -71.9881379998571,
                            44.3300649998478
                        ],
                        [
                            -72.0090890002918,
                            44.322222000055
                        ],
                        [
                            -72.0192520004272,
                            44.3201930003083
                        ],
                        [
                            -72.0290020001106,
                            44.3223830000388
                        ],
                        [
                            -72.0328020004249,
                            44.3204899999225
                        ],
                        [
                            -72.0336099999887,
                            44.3171409998119
                        ],
                        [
                            -72.0314130001798,
                            44.3152489997279
                        ],
                        [
                            -72.0328779996946,
                            44.3021649996977
                        ],
                        [
                            -72.0385240001885,
                            44.2964430001587
                        ],
                        [
                            -72.0440019995546,
                            44.295337000112
                        ],
                        [
                            -72.0463830003848,
                            44.2919950002009
                        ],
                        [
                            -72.0534630004314,
                            44.2903549998205
                        ],
                        [
                            -72.058636000199,
                            44.2865019998489
                        ],
                        [
                            -72.0643889999247,
                            44.2793229997193
                        ],
                        [
                            -72.068477999944,
                            44.2708009997153
                        ],
                        [
                            -72.0671349997296,
                            44.2682370002715
                        ],
                        [
                            -72.0647539997977,
                            44.2676880002516
                        ],
                        [
                            -72.0583309997021,
                            44.2700830002679
                        ],
                        [
                            -72.060054999683,
                            44.2675890000503
                        ],
                        [
                            -72.0586050002368,
                            44.2650560000888
                        ],
                        [
                            -72.0596429999544,
                            44.2639649997125
                        ],
                        [
                            -72.0594899997935,
                            44.2657650001706
                        ],
                        [
                            -72.0616570003635,
                            44.2635529999177
                        ],
                        [
                            -72.0594599996563,
                            44.2619970001813
                        ],
                        [
                            -72.0615200000961,
                            44.2610729997207
                        ],
                        [
                            -72.0547299995795,
                            44.2478749998118
                        ],
                        [
                            -72.0501209998765,
                            44.2443119998811
                        ],
                        [
                            -72.0476639997766,
                            44.2389560002393
                        ],
                        [
                            -72.0536309997626,
                            44.2253450001435
                        ],
                        [
                            -72.0525779999764,
                            44.2178299998519
                        ],
                        [
                            -72.0553699998121,
                            44.2128329998471
                        ],
                        [
                            -72.0594749997249,
                            44.210560000203
                        ],
                        [
                            -72.057750999744,
                            44.2058370000503
                        ],
                        [
                            -72.0587430003291,
                            44.2020299997079
                        ],
                        [
                            -72.0643270000004,
                            44.1975739997741
                        ],
                        [
                            -72.0661580001114,
                            44.1901279999698
                        ],
                        [
                            -72.0583309997021,
                            44.1813240001259
                        ],
                        [
                            -72.0518299997888,
                            44.1664999997399
                        ],
                        [
                            -72.0489459998916,
                            44.1652410002767
                        ],
                        [
                            -72.0471909999486,
                            44.1616330001713
                        ],
                        [
                            -72.0422929996423,
                            44.162045000216
                        ],
                        [
                            -72.0439110002163,
                            44.1580539996853
                        ],
                        [
                            -72.0430869998607,
                            44.1565519998766
                        ],
                        [
                            -72.0389970000165,
                            44.1567960000205
                        ],
                        [
                            -72.0428269995697,
                            44.1509669998642
                        ],
                        [
                            -72.0419570000817,
                            44.1369980000766
                        ],
                        [
                            -72.0332600001843,
                            44.1316800002757
                        ],
                        [
                            -72.0373030001729,
                            44.1249129998131
                        ],
                        [
                            -72.045131000407,
                            44.1246909998782
                        ],
                        [
                            -72.0531429998659,
                            44.1195110001539
                        ],
                        [
                            -72.0532640002398,
                            44.1146900001713
                        ],
                        [
                            -72.0553399996748,
                            44.1106000002198
                        ],
                        [
                            -72.0521350002856,
                            44.1001560000982
                        ],
                        [
                            -72.0478799996867,
                            44.0965859997242
                        ],
                        [
                            -72.0439100003914,
                            44.0965999999291
                        ],
                        [
                            -72.0422630004034,
                            44.1013530001369
                        ],
                        [
                            -72.0394549997759,
                            44.1035199997369
                        ],
                        [
                            -72.0313060000497,
                            44.1004300001408
                        ],
                        [
                            -72.0306809998857,
                            44.0977140000759
                        ],
                        [
                            -72.0332600001843,
                            44.0904739998783
                        ],
                        [
                            -72.0371049998061,
                            44.0886360002412
                        ],
                        [
                            -72.0434829996958,
                            44.0889019999687
                        ],
                        [
                            -72.0452839996696,
                            44.0873080000416
                        ],
                        [
                            -72.0332450001157,
                            44.0815100003159
                        ],
                        [
                            -72.031840999802,
                            44.0785419998582
                        ],
                        [
                            -72.0380360002919,
                            44.076543000293
                        ],
                        [
                            -72.043513999658,
                            44.0792440000805
                        ],
                        [
                            -72.0528680004046,
                            44.0763059998537
                        ],
                        [
                            -72.0483049998341,
                            44.0697069999302
                        ],
                        [
                            -72.0572929999846,
                            44.0583389997413
                        ],
                        [
                            -72.0680960003525,
                            44.0573779997771
                        ],
                        [
                            -72.0689359997034,
                            44.0542809998722
                        ],
                        [
                            -72.0631219998783,
                            44.0521979998563
                        ],
                        [
                            -72.0619009998626,
                            44.0495960003165
                        ],
                        [
                            -72.0783199996889,
                            44.0433780000917
                        ],
                        [
                            -72.0791440000446,
                            44.0395020002219
                        ],
                        [
                            -72.0748109996278,
                            44.0324070000483
                        ],
                        [
                            -72.0813419996784,
                            44.0283640002779
                        ],
                        [
                            -72.0826689999992,
                            44.0218030002166
                        ],
                        [
                            -72.0854769997284,
                            44.0212610002463
                        ],
                        [
                            -72.0915039999888,
                            44.0244120002256
                        ],
                        [
                            -72.0954259996035,
                            44.0217569997036
                        ],
                        [
                            -72.0953489996106,
                            44.0164620003043
                        ],
                        [
                            -72.0905270003706,
                            44.0099239997485
                        ],
                        [
                            -72.0934880002607,
                            44.0094429998099
                        ],
                        [
                            -72.0935490003601,
                            44.0128610003173
                        ],
                        [
                            -72.0969370000474,
                            44.0150350001507
                        ],
                        [
                            -72.1027650001162,
                            44.0145779998812
                        ],
                        [
                            -72.1057710002121,
                            44.0122810000544
                        ],
                        [
                            -72.1041230003992,
                            44.0023710002262
                        ],
                        [
                            -72.1168649999348,
                            43.9944670003175
                        ],
                        [
                            -72.116635999606,
                            43.9919720001823
                        ],
                        [
                            -72.1120429997965,
                            43.9859520001776
                        ],
                        [
                            -72.1143779996977,
                            43.9680539997182
                        ],
                        [
                            -72.110852999743,
                            43.9669939999015
                        ],
                        [
                            -72.1059999996426,
                            43.9700220001718
                        ],
                        [
                            -72.0909399999242,
                            43.9657800002582
                        ],
                        [
                            -72.1006750004374,
                            43.963004000321
                        ],
                        [
                            -72.1011480002654,
                            43.9608520001216
                        ],
                        [
                            -72.098995999764,
                            43.9575640001908
                        ],
                        [
                            -72.1057400002499,
                            43.9498290002939
                        ],
                        [
                            -72.1183450004166,
                            43.9465319997863
                        ],
                        [
                            -72.119276000004,
                            43.9437469999283
                        ],
                        [
                            -72.1169109999656,
                            43.9338439997613
                        ],
                        [
                            -72.1181470000499,
                            43.9236059998007
                        ],
                        [
                            -72.1204509999889,
                            43.9195550002729
                        ],
                        [
                            -72.1324140002731,
                            43.9133210001536
                        ],
                        [
                            -72.1360610004265,
                            43.9091709999071
                        ],
                        [
                            -72.1505269999431,
                            43.9020989999725
                        ],
                        [
                            -72.1571640002988,
                            43.8949500000773
                        ],
                        [
                            -72.1597279996306,
                            43.8876640000792
                        ],
                        [
                            -72.1721029996434,
                            43.8849859999944
                        ],
                        [
                            -72.1735839999501,
                            43.8796149998776
                        ],
                        [
                            -72.1679070003924,
                            43.8691089997872
                        ],
                        [
                            -72.1846159997486,
                            43.864143000053
                        ],
                        [
                            -72.1880339995731,
                            43.8551549998288
                        ],
                        [
                            -72.1830900001344,
                            43.8446800003165
                        ],
                        [
                            -72.1824490000769,
                            43.8341210002354
                        ],
                        [
                            -72.1889499999902,
                            43.8211899998722
                        ],
                        [
                            -72.1834409997637,
                            43.8104479997505
                        ],
                        [
                            -72.1840509998591,
                            43.8064039998053
                        ],
                        [
                            -72.1909939996383,
                            43.8004839998764
                        ],
                        [
                            -72.1961820003728,
                            43.7909169998755
                        ],
                        [
                            -72.2055360002211,
                            43.7845840002111
                        ],
                        [
                            -72.203934000439,
                            43.779403999842
                        ],
                        [
                            -72.2052299998994,
                            43.7709300002041
                        ],
                        [
                            -72.2185370002229,
                            43.7654649996915
                        ],
                        [
                            -72.2309430001979,
                            43.7481920002804
                        ],
                        [
                            -72.2341779997244,
                            43.7459649998126
                        ],
                        [
                            -72.2466599998673,
                            43.7424859999059
                        ],
                        [
                            -72.2644670001139,
                            43.7338949996947
                        ],
                        [
                            -72.2711050002946,
                            43.7339180000234
                        ],
                        [
                            -72.2753469995764,
                            43.7274409999588
                        ],
                        [
                            -72.2820609999251,
                            43.7234810001117
                        ],
                        [
                            -72.2921780000297,
                            43.7110680003177
                        ],
                        [
                            -72.299564000398,
                            43.7062770002743
                        ],
                        [
                            -72.3017760002755,
                            43.6996549997266
                        ],
                        [
                            -72.3051330000007,
                            43.6953209999658
                        ],
                        [
                            -72.3060179995574,
                            43.6833509997944
                        ],
                        [
                            -72.3014249997479,
                            43.675760000036
                        ],
                        [
                            -72.3031489997288,
                            43.6686190000084
                        ],
                        [
                            -72.3136330002545,
                            43.6569379997919
                        ],
                        [
                            -72.3146940004366,
                            43.6418579999499
                        ],
                        [
                            -72.3291359996637,
                            43.6355300002991
                        ],
                        [
                            -72.3297619996526,
                            43.633447000098
                        ],
                        [
                            -72.3273809997207,
                            43.6306629999203
                        ],
                        [
                            -72.329165999801,
                            43.6268250000772
                        ],
                        [
                            -72.3327829998171,
                            43.624361000044
                        ],
                        [
                            -72.3348270003635,
                            43.6179519997623
                        ],
                        [
                            -72.3331950004441,
                            43.6102849997844
                        ],
                        [
                            -72.3284190003366,
                            43.6070579999416
                        ],
                        [
                            -72.3283270002751,
                            43.6011140001626
                        ],
                        [
                            -72.3496440004077,
                            43.5878169997977
                        ],
                        [
                            -72.3562209995907,
                            43.5866109997365
                        ],
                        [
                            -72.3724879999793,
                            43.5799509999227
                        ],
                        [
                            -72.3802369996725,
                            43.5734349997656
                        ],
                        [
                            -72.3801470001591,
                            43.5649739998716
                        ],
                        [
                            -72.3813380000375,
                            43.5656609997291
                        ],
                        [
                            -72.3829859998504,
                            43.5634180003178
                        ],
                        [
                            -72.3807120000486,
                            43.5507079998008
                        ],
                        [
                            -72.38069699998,
                            43.540355000285
                        ],
                        [
                            -72.3876549998279,
                            43.5296349997411
                        ],
                        [
                            -72.3904170004246,
                            43.527560000167
                        ],
                        [
                            -72.3942620000464,
                            43.5273999999349
                        ],
                        [
                            -72.3988709997494,
                            43.5126530002818
                        ],
                        [
                            -72.3972990001044,
                            43.5089979998611
                        ],
                        [
                            -72.3851059996665,
                            43.5006979998388
                        ],
                        [
                            -72.3810629996779,
                            43.4940369998392
                        ],
                        [
                            -72.3799470001426,
                            43.4906849998804
                        ],
                        [
                            -72.3814750003049,
                            43.4800299998537
                        ],
                        [
                            -72.3830319998812,
                            43.4751390000131
                        ],
                        [
                            -72.3925690000276,
                            43.4668839999829
                        ],
                        [
                            -72.3903710003938,
                            43.4509090002876
                        ],
                        [
                            -72.395816000148,
                            43.437489999802
                        ],
                        [
                            -72.3962759995572,
                            43.4269369997841
                        ],
                        [
                            -72.3999389996041,
                            43.4151350002571
                        ],
                        [
                            -72.4035089997646,
                            43.3915830002666
                        ],
                        [
                            -72.4133970004387,
                            43.3834809999625
                        ],
                        [
                            -72.4160219998698,
                            43.3771639997782
                        ],
                        [
                            -72.4152279996514,
                            43.365605000278
                        ],
                        [
                            -72.4126490002511,
                            43.3622790001882
                        ],
                        [
                            -72.4030819999674,
                            43.3577470000686
                        ],
                        [
                            -72.3922329995687,
                            43.3579300001411
                        ],
                        [
                            -72.3898680004286,
                            43.3565720000319
                        ],
                        [
                            -72.4007010000355,
                            43.3460439999912
                        ],
                        [
                            -72.410558999674,
                            43.3311209999484
                        ],
                        [
                            -72.4026400001015,
                            43.3204699999261
                        ],
                        [
                            -72.3972229999364,
                            43.3170069999926
                        ],
                        [
                            -72.3953149998326,
                            43.3126959997228
                        ],
                        [
                            -72.4027920004375,
                            43.3024420000981
                        ],
                        [
                            -72.4088909997696,
                            43.281966999781
                        ],
                        [
                            -72.4160369999384,
                            43.2702310002274
                        ],
                        [
                            -72.4215610002336,
                            43.2633730000168
                        ],
                        [
                            -72.4354769999292,
                            43.2577269999944
                        ],
                        [
                            -72.4386050002239,
                            43.2524240002781
                        ],
                        [
                            -72.4394749997119,
                            43.2441390002497
                        ],
                        [
                            -72.434164999677,
                            43.2319090002851
                        ],
                        [
                            -72.4410470002551,
                            43.2181919999027
                        ],
                        [
                            -72.438223999559,
                            43.2029180002788
                        ],
                        [
                            -72.4505080002336,
                            43.192610999703
                        ],
                        [
                            -72.4495309997171,
                            43.1890020002917
                        ],
                        [
                            -72.4442209996822,
                            43.1826470000182
                        ],
                        [
                            -72.4439310001523,
                            43.1797780000139
                        ],
                        [
                            -72.4527810002105,
                            43.1727670001797
                        ],
                        [
                            -72.4521100000158,
                            43.1532279998082
                        ],
                        [
                            -72.4574049999821,
                            43.1475370002019
                        ],
                        [
                            -72.4520949999472,
                            43.1385799998656
                        ],
                        [
                            -72.4408029998577,
                            43.1366269999657
                        ],
                        [
                            -72.442298000408,
                            43.1293029999771
                        ],
                        [
                            -72.4325019997955,
                            43.1188959996907
                        ],
                        [
                            -72.4334779995887,
                            43.1105879998388
                        ],
                        [
                            -72.441412999953,
                            43.1059489998511
                        ],
                        [
                            -72.4431980000333,
                            43.1030200001074
                        ],
                        [
                            -72.438375999895,
                            43.0908359996985
                        ],
                        [
                            -72.4345160002046,
                            43.0866170001913
                        ],
                        [
                            -72.4352640003922,
                            43.0814899998813
                        ],
                        [
                            -72.4443429998809,
                            43.0716180001249
                        ],
                        [
                            -72.4503700001414,
                            43.0684359997507
                        ],
                        [
                            -72.4678419997538,
                            43.052749999738
                        ],
                        [
                            -72.4662249999031,
                            43.0475699998672
                        ],
                        [
                            -72.4603800001158,
                            43.0413830002444
                        ],
                        [
                            -72.4626229999555,
                            43.0257880000875
                        ],
                        [
                            -72.4580760001768,
                            43.0183039999518
                        ],
                        [
                            -72.4450600001064,
                            43.0110939997024
                        ],
                        [
                            -72.4432590001327,
                            43.0075769998227
                        ],
                        [
                            -72.4450450000378,
                            43.0038850002883
                        ],
                        [
                            -72.4494090004167,
                            43.0003220002809
                        ],
                        [
                            -72.4587020001657,
                            43.0008630002119
                        ],
                        [
                            -72.4625159998254,
                            42.9974450001418
                        ],
                        [
                            -72.4654159996161,
                            42.9896329997664
                        ],
                        [
                            -72.4616999998657,
                            42.982870999991
                        ],
                        [
                            -72.4737169996783,
                            42.9721240002673
                        ],
                        [
                            -72.4763110000455,
                            42.9714980002652
                        ],
                        [
                            -72.4816360001491,
                            42.9739549998464
                        ],
                        [
                            -72.4921800000509,
                            42.9676759998706
                        ],
                        [
                            -72.5183650003526,
                            42.9631370001608
                        ],
                        [
                            -72.5320219995821,
                            42.9549430000509
                        ],
                        [
                            -72.5344329996513,
                            42.9493580002546
                        ],
                        [
                            -72.5285279995896,
                            42.9455050002367
                        ],
                        [
                            -72.5269409998761,
                            42.941378000092
                        ],
                        [
                            -72.5270780001434,
                            42.9283469997651
                        ],
                        [
                            -72.5244989998448,
                            42.9151480000374
                        ],
                        [
                            -72.5303589997006,
                            42.9110739998124
                        ],
                        [
                            -72.5324800002399,
                            42.8963500000743
                        ],
                        [
                            -72.5407200002027,
                            42.8892849998774
                        ],
                        [
                            -72.5524240000208,
                            42.8852870001534
                        ],
                        [
                            -72.5554150000481,
                            42.874667000301
                        ],
                        [
                            -72.5542700002005,
                            42.8606139997829
                        ],
                        [
                            -72.5570779999296,
                            42.8528630002901
                        ],
                        [
                            -72.5473580003834,
                            42.8395490001146
                        ],
                        [
                            -72.5465800000585,
                            42.8251990001113
                        ],
                        [
                            -72.542429000115,
                            42.8156469997706
                        ],
                        [
                            -72.5415129996979,
                            42.8070940000026
                        ],
                        [
                            -72.516258000057,
                            42.787971999856
                        ],
                        [
                            -72.5087369999695,
                            42.7795219998748
                        ],
                        [
                            -72.5092859999655,
                            42.7750129996991
                        ],
                        [
                            -72.5164730001422,
                            42.7691230003009
                        ],
                        [
                            -72.5154660003868,
                            42.7656290000705
                        ],
                        [
                            -72.5130700003863,
                            42.7640729999611
                        ],
                        [
                            -72.5083700004467,
                            42.7644009997816
                        ],
                        [
                            -72.4995809995895,
                            42.7688110000712
                        ],
                        [
                            -72.4977040003461,
                            42.7733649998692
                        ],
                        [
                            -72.4905470003066,
                            42.7727469996719
                        ],
                        [
                            -72.4853140002646,
                            42.7662780002447
                        ],
                        [
                            -72.476952000103,
                            42.7607689999567
                        ],
                        [
                            -72.473366000049,
                            42.7462349997527
                        ],
                        [
                            -72.4642249997376,
                            42.7373089997879
                        ],
                        [
                            -72.4584119997375,
                            42.726933000272
                        ],
                        [
                            -72.8091130002702,
                            42.7365809997488
                        ],
                        [
                            -73.2764210002377,
                            42.7460189999895
                        ],
                        [
                            -73.2909439996557,
                            42.8019200002254
                        ],
                        [
                            -73.2836949995547,
                            42.8138560000015
                        ],
                        [
                            -73.2870630000489,
                            42.8201399996984
                        ],
                        [
                            -73.2853879995735,
                            42.8340930000216
                        ],
                        [
                            -73.2786730002982,
                            42.8334099998131
                        ],
                        [
                            -73.2783059998771,
                            42.8375580000233
                        ],
                        [
                            -73.2697770002092,
                            43.0359759999116
                        ],
                        [
                            -73.2564749999086,
                            43.2600930000494
                        ],
                        [
                            -73.2467160000043,
                            43.515332000096
                        ],
                        [
                            -73.2480680004395,
                            43.515993999988
                        ],
                        [
                            -73.246501999744,
                            43.5214599997233
                        ],
                        [
                            -73.2476979996453,
                            43.5231739998121
                        ],
                        [
                            -73.2468209995863,
                            43.5257810001889
                        ],
                        [
                            -73.2418920002162,
                            43.5294189999847
                        ],
                        [
                            -73.2413909999007,
                            43.5323459996902
                        ],
                        [
                            -73.2427440001609,
                            43.5339910001413
                        ],
                        [
                            -73.2415790002217,
                            43.5349599997734
                        ],
                        [
                            -73.2435309998082,
                            43.5354079999879
                        ],
                        [
                            -73.2465860003079,
                            43.5418550002505
                        ],
                        [
                            -73.2501330000039,
                            43.543429000195
                        ],
                        [
                            -73.2499360003604,
                            43.5448690001749
                        ],
                        [
                            -73.2478149998212,
                            43.545054999856
                        ],
                        [
                            -73.2498559999945,
                            43.546126999708
                        ],
                        [
                            -73.2504080003635,
                            43.5504259997279
                        ],
                        [
                            -73.2486070003897,
                            43.5512059998239
                        ],
                        [
                            -73.2486409998266,
                            43.5538580001629
                        ],
                        [
                            -73.2544899998118,
                            43.555595999743
                        ],
                        [
                            -73.2526019997994,
                            43.5568520001165
                        ],
                        [
                            -73.2586309997096,
                            43.5649500000448
                        ],
                        [
                            -73.2607389998301,
                            43.5665349997769
                        ],
                        [
                            -73.2620779998465,
                            43.5657009998309
                        ],
                        [
                            -73.2641630004007,
                            43.5691359997246
                        ],
                        [
                            -73.2689880000137,
                            43.5699290000331
                        ],
                        [
                            -73.2674580002016,
                            43.5708730001902
                        ],
                        [
                            -73.2679319998545,
                            43.5722499997432
                        ],
                        [
                            -73.2778070001098,
                            43.5746289999373
                        ],
                        [
                            -73.2808580004115,
                            43.5735780001161
                        ],
                        [
                            -73.2812960000795,
                            43.5775790001891
                        ],
                        [
                            -73.2849120002707,
                            43.5792720001454
                        ],
                        [
                            -73.2900399998324,
                            43.5776730003012
                        ],
                        [
                            -73.2941280000268,
                            43.5784740000185
                        ],
                        [
                            -73.2952920001411,
                            43.5767820000085
                        ],
                        [
                            -73.2973240000935,
                            43.5793729997671
                        ],
                        [
                            -73.2921089995948,
                            43.5845439998036
                        ],
                        [
                            -73.2969440001519,
                            43.5873140000647
                        ],
                        [
                            -73.2910189999988,
                            43.5992350000242
                        ],
                        [
                            -73.2980200004026,
                            43.6100280000243
                        ],
                        [
                            -73.3027720002207,
                            43.6097770000237
                        ],
                        [
                            -73.30037799987,
                            43.6178030000616
                        ],
                        [
                            -73.3029899997806,
                            43.6205239998791
                        ],
                        [
                            -73.3025529999376,
                            43.6257089997198
                        ],
                        [
                            -73.3064400002905,
                            43.6283329996915
                        ],
                        [
                            -73.311172999842,
                            43.6240919998058
                        ],
                        [
                            -73.3178500003808,
                            43.6284990001282
                        ],
                        [
                            -73.3277020001715,
                            43.6259139999207
                        ],
                        [
                            -73.3341870001913,
                            43.6279940002978
                        ],
                        [
                            -73.3400679999635,
                            43.6246469996901
                        ],
                        [
                            -73.3445100001603,
                            43.6262770000902
                        ],
                        [
                            -73.3487530001653,
                            43.6214579996864
                        ],
                        [
                            -73.3542699998895,
                            43.6261899997657
                        ],
                        [
                            -73.3655629998039,
                            43.623440999721
                        ],
                        [
                            -73.3718899996399,
                            43.6244900000551
                        ],
                        [
                            -73.3724870002148,
                            43.6227520001373
                        ],
                        [
                            -73.3698400001441,
                            43.619392000312
                        ],
                        [
                            -73.375595000418,
                            43.6133510001536
                        ],
                        [
                            -73.3742260002643,
                            43.6101970000307
                        ],
                        [
                            -73.3763860002633,
                            43.6088240002653
                        ],
                        [
                            -73.3731630004324,
                            43.6072479998217
                        ],
                        [
                            -73.3724689997732,
                            43.6048479998368
                        ],
                        [
                            -73.377635999693,
                            43.6002280000635
                        ],
                        [
                            -73.3773699995542,
                            43.5983979998231
                        ],
                        [
                            -73.383489999701,
                            43.5973660002122
                        ],
                        [
                            -73.3821380001641,
                            43.5918239997619
                        ],
                        [
                            -73.3869009998529,
                            43.5907700000667
                        ],
                        [
                            -73.3862530001227,
                            43.5884109999489
                        ],
                        [
                            -73.3824650004023,
                            43.586577999871
                        ],
                        [
                            -73.3852510003901,
                            43.5832629997594
                        ],
                        [
                            -73.3827109995513,
                            43.5791959999382
                        ],
                        [
                            -73.3832849996616,
                            43.5772000001245
                        ],
                        [
                            -73.3849350000227,
                            43.5751140003045
                        ],
                        [
                            -73.3907940000536,
                            43.5741830002447
                        ],
                        [
                            -73.3916729997624,
                            43.5701199997152
                        ],
                        [
                            -73.3951690003031,
                            43.5696189999687
                        ],
                        [
                            -73.3957679996295,
                            43.5680869998738
                        ],
                        [
                            -73.4039110004063,
                            43.5703310002307
                        ],
                        [
                            -73.4169650001115,
                            43.5777310000341
                        ],
                        [
                            -73.420383999761,
                            43.581494999718
                        ],
                        [
                            -73.4286370001426,
                            43.583994000103
                        ],
                        [
                            -73.4312289999617,
                            43.58828500017
                        ],
                        [
                            -73.4216159996473,
                            43.6030230000217
                        ],
                        [
                            -73.4237089996991,
                            43.6123569998244
                        ],
                        [
                            -73.4176689999182,
                            43.6216870000582
                        ],
                        [
                            -73.4284189996844,
                            43.635452999691
                        ],
                        [
                            -73.4264629998999,
                            43.642598999684
                        ],
                        [
                            -73.4185739995663,
                            43.6480509999249
                        ],
                        [
                            -73.4155130001172,
                            43.6524510001084
                        ],
                        [
                            -73.4145459996464,
                            43.6582089998516
                        ],
                        [
                            -73.4080620003498,
                            43.6694379997329
                        ],
                        [
                            -73.4041270003164,
                            43.6813399999708
                        ],
                        [
                            -73.4047390000616,
                            43.6902139999514
                        ],
                        [
                            -73.3955169995593,
                            43.6968309997114
                        ],
                        [
                            -73.3858830002268,
                            43.7113370000024
                        ],
                        [
                            -73.3706119997229,
                            43.7253290001587
                        ],
                        [
                            -73.3699439999012,
                            43.7438549998807
                        ],
                        [
                            -73.3507619996532,
                            43.7713869999626
                        ],
                        [
                            -73.3553620000337,
                            43.7779270001341
                        ],
                        [
                            -73.3581539998693,
                            43.7864749999809
                        ],
                        [
                            -73.3767929999691,
                            43.799157999853
                        ],
                        [
                            -73.3807220001548,
                            43.8108190000372
                        ],
                        [
                            -73.389564999642,
                            43.8170400001332
                        ],
                        [
                            -73.3925170002095,
                            43.8213400002634
                        ],
                        [
                            -73.3881920001887,
                            43.8326549998341
                        ],
                        [
                            -73.3766630002728,
                            43.839434000084
                        ],
                        [
                            -73.3725890003221,
                            43.845353999756
                        ],
                        [
                            -73.3802490003268,
                            43.8519230002051
                        ],
                        [
                            -73.3823089998683,
                            43.8560579997472
                        ],
                        [
                            -73.3741619997919,
                            43.8762409998069
                        ],
                        [
                            -73.3836939999155,
                            43.8912839998241
                        ],
                        [
                            -73.3961790004316,
                            43.9032640002188
                        ],
                        [
                            -73.4085540004444,
                            43.9318940000691
                        ],
                        [
                            -73.4056460002578,
                            43.9480689997177
                        ],
                        [
                            -73.4057359997712,
                            43.9559319998957
                        ],
                        [
                            -73.4070689999398,
                            43.9681719999391
                        ],
                        [
                            -73.412048000437,
                            43.9776039998826
                        ],
                        [
                            -73.4125100003943,
                            43.9822160000184
                        ],
                        [
                            -73.4060089995827,
                            44.0114170000561
                        ],
                        [
                            -73.4076449997,
                            44.021110999851
                        ],
                        [
                            -73.4125120000442,
                            44.0283460001078
                        ],
                        [
                            -73.4228819998687,
                            44.0325479998618
                        ],
                        [
                            -73.4367289999674,
                            44.0426330002555
                        ],
                        [
                            -73.4379049997772,
                            44.0451249998152
                        ],
                        [
                            -73.4311219998316,
                            44.0677660001159
                        ],
                        [
                            -73.4297180004161,
                            44.0785860002712
                        ],
                        [
                            -73.4152150001913,
                            44.1013439998535
                        ],
                        [
                            -73.4115119999614,
                            44.1119519999121
                        ],
                        [
                            -73.4120269996223,
                            44.1196310000426
                        ],
                        [
                            -73.4161380002812,
                            44.1323550000925
                        ],
                        [
                            -73.411972000269,
                            44.1377799998965
                        ],
                        [
                            -73.4030450002179,
                            44.1449839998595
                        ],
                        [
                            -73.4028769999884,
                            44.1504830000886
                        ],
                        [
                            -73.4002910000171,
                            44.1543630002558
                        ],
                        [
                            -73.3988569995661,
                            44.1619149999182
                        ],
                        [
                            -73.3958129998354,
                            44.1662659999144
                        ],
                        [
                            -73.3971710001183,
                            44.1738380001054
                        ],
                        [
                            -73.3898240001081,
                            44.1817569998518
                        ],
                        [
                            -73.3906249999992,
                            44.1910680000836
                        ],
                        [
                            -73.3845819998452,
                            44.1931929997103
                        ],
                        [
                            -73.3819349997745,
                            44.1977339999172
                        ],
                        [
                            -73.3748020000245,
                            44.2002430000895
                        ],
                        [
                            -73.3708650003412,
                            44.2045270002352
                        ],
                        [
                            -73.3622660003531,
                            44.208518000234
                        ],
                        [
                            -73.3622200003224,
                            44.2124609999997
                        ],
                        [
                            -73.3562240000241,
                            44.218956000262
                        ],
                        [
                            -73.3553770001023,
                            44.2231570000069
                        ],
                        [
                            -73.3513790003196,
                            44.2259799997602
                        ],
                        [
                            -73.350478999796,
                            44.2298929997056
                        ],
                        [
                            -73.3428349996848,
                            44.2344459996885
                        ],
                        [
                            -73.3433760001832,
                            44.2381900000811
                        ],
                        [
                            -73.3364030002668,
                            44.2397810000092
                        ],
                        [
                            -73.3303750001815,
                            44.2443119998811
                        ],
                        [
                            -73.3237150002595,
                            44.244159999845
                        ],
                        [
                            -73.323066999631,
                            44.2478960000465
                        ],
                        [
                            -73.3196029997757,
                            44.2499090000554
                        ],
                        [
                            -73.3132829996123,
                            44.2641300000495
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 44.0604795,
            "name": "Vermont",
            "intptlon": -72.6733274,
            "geo_point_2d": [
                44.0752509757,
                -72.6626510555
            ],
            "geoid": "50",
            "mtfcc": "G4000",
            "region": 1
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -72.6626510555,
                44.0752509757
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "3bb059ba9e6a5235b1e092ac75024392b61018a4",
        "fields": {
            "arealand": "1870899812",
            "objectid": 15,
            "basename": "New Jersey",
            "stusab": "NJ",
            "statens": "01779795",
            "centlon": -74.6580421,
            "state": "34",
            "gid": 49,
            "centlat": 40.1050485,
            "division": 2,
            "areawater": "-753053640",
            "oid": "-1799523090",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -75.1895999995515,
                            40.5917759999937
                        ],
                        [
                            -75.1922910000032,
                            40.6026759999519
                        ],
                        [
                            -75.2018440000432,
                            40.6158920002302
                        ],
                        [
                            -75.1996840000442,
                            40.6188200002875
                        ],
                        [
                            -75.1906880003961,
                            40.6199570001332
                        ],
                        [
                            -75.1885950003443,
                            40.6236359997132
                        ],
                        [
                            -75.1906909998708,
                            40.6358910002811
                        ],
                        [
                            -75.1953509996274,
                            40.6430439996875
                        ],
                        [
                            -75.2004680002168,
                            40.6468990000736
                        ],
                        [
                            -75.2004520003233,
                            40.6492189996703
                        ],
                        [
                            -75.1978599996059,
                            40.6520499999373
                        ],
                        [
                            -75.1979779996066,
                            40.6547390001987
                        ],
                        [
                            -75.1908520004276,
                            40.6619380002834
                        ],
                        [
                            -75.1827520002069,
                            40.665973999702
                        ],
                        [
                            -75.1774909996774,
                            40.6725950001672
                        ],
                        [
                            -75.1768029997643,
                            40.6757150003226
                        ],
                        [
                            -75.1805639997205,
                            40.6793629998847
                        ],
                        [
                            -75.1905800004411,
                            40.6793789998285
                        ],
                        [
                            -75.1969209996225,
                            40.6812969998457
                        ],
                        [
                            -75.2039210002015,
                            40.6914979997743
                        ],
                        [
                            -75.19442000004,
                            40.7140179999002
                        ],
                        [
                            -75.1867399999438,
                            40.7194899999944
                        ],
                        [
                            -75.1860520000307,
                            40.7256820002555
                        ],
                        [
                            -75.1820840003852,
                            40.7315220003123
                        ],
                        [
                            -75.1857800000441,
                            40.7372659998109
                        ],
                        [
                            -75.1953489999776,
                            40.745472999661
                        ],
                        [
                            -75.1965349998331,
                            40.7516310001707
                        ],
                        [
                            -75.1917960004338,
                            40.7558300001092
                        ],
                        [
                            -75.1790399997561,
                            40.761897000093
                        ],
                        [
                            -75.1756200002818,
                            40.7729229998772
                        ],
                        [
                            -75.171587000339,
                            40.7777449997636
                        ],
                        [
                            -75.1636479997767,
                            40.7783859999663
                        ],
                        [
                            -75.1493780000787,
                            40.7747859998865
                        ],
                        [
                            -75.1344000003761,
                            40.7737700003011
                        ],
                        [
                            -75.1230880001951,
                            40.7867459996609
                        ],
                        [
                            -75.1085049996044,
                            40.7910940000364
                        ],
                        [
                            -75.1008000002921,
                            40.7997970002513
                        ],
                        [
                            -75.1002139995879,
                            40.807663999733
                        ],
                        [
                            -75.0905180003311,
                            40.8159130000682
                        ],
                        [
                            -75.0839289996559,
                            40.8244710000487
                        ],
                        [
                            -75.0838220004241,
                            40.8278049999085
                        ],
                        [
                            -75.0855170000927,
                            40.8300850002087
                        ],
                        [
                            -75.0970059998256,
                            40.8393360000824
                        ],
                        [
                            -75.0975859997838,
                            40.8430419999839
                        ],
                        [
                            -75.0957839999851,
                            40.8470820003256
                        ],
                        [
                            -75.0909619998468,
                            40.8491869999158
                        ],
                        [
                            -75.0766839997529,
                            40.8498750002515
                        ],
                        [
                            -75.0708299997448,
                            40.8473919999235
                        ],
                        [
                            -75.0660079996065,
                            40.8475940000735
                        ],
                        [
                            -75.0632519997559,
                            40.8501919999627
                        ],
                        [
                            -75.0638019995768,
                            40.8555929999184
                        ],
                        [
                            -75.051029000079,
                            40.8656619996646
                        ],
                        [
                            -75.0536639995558,
                            40.8736599998746
                        ],
                        [
                            -75.0753449997365,
                            40.8941660002271
                        ],
                        [
                            -75.076089999551,
                            40.9070390002257
                        ],
                        [
                            -75.0792949998386,
                            40.9138799999606
                        ],
                        [
                            -75.095527999892,
                            40.9241479997221
                        ],
                        [
                            -75.1048089999455,
                            40.9352469997636
                        ],
                        [
                            -75.1116889999755,
                            40.9481270001058
                        ],
                        [
                            -75.1177670002895,
                            40.9530130002229
                        ],
                        [
                            -75.1208100001953,
                            40.9628840000639
                        ],
                        [
                            -75.1202319998871,
                            40.9687020001515
                        ],
                        [
                            -75.1226029997732,
                            40.9701519998839
                        ],
                        [
                            -75.1301659996935,
                            40.9689610002216
                        ],
                        [
                            -75.1347009997766,
                            40.972038000141
                        ],
                        [
                            -75.1355209999343,
                            40.9768650001528
                        ],
                        [
                            -75.1325220004094,
                            40.9812349999355
                        ],
                        [
                            -75.1310960003544,
                            40.990464000284
                        ],
                        [
                            -75.1091130000499,
                            41.0041019997295
                        ],
                        [
                            -75.0935229997038,
                            41.0094420002593
                        ],
                        [
                            -75.0844950002686,
                            41.015920000155
                        ],
                        [
                            -75.070531999819,
                            41.0186200003036
                        ],
                        [
                            -75.0417890000334,
                            41.0316999999404
                        ],
                        [
                            -75.033493999819,
                            41.0376659999523
                        ],
                        [
                            -75.0271019996856,
                            41.039529000297
                        ],
                        [
                            -75.0271460000666,
                            41.045253999737
                        ],
                        [
                            -75.0175720001101,
                            41.055852999771
                        ],
                        [
                            -75.0124100002132,
                            41.0672639996911
                        ],
                        [
                            -75.0068559997809,
                            41.0675960003157
                        ],
                        [
                            -74.9948470003642,
                            41.0765559996655
                        ],
                        [
                            -74.9825900003521,
                            41.0791720001225
                        ],
                        [
                            -74.9700939999654,
                            41.0858859998038
                        ],
                        [
                            -74.9667780002481,
                            41.0928920001895
                        ],
                        [
                            -74.9674639996131,
                            41.09532700031
                        ],
                        [
                            -74.9694339996413,
                            41.0960740003338
                        ],
                        [
                            -74.983320999923,
                            41.0889959998078
                        ],
                        [
                            -74.991815000329,
                            41.0891320002385
                        ],
                        [
                            -74.9913869998085,
                            41.093155000067
                        ],
                        [
                            -74.9822120000603,
                            41.1082450001921
                        ],
                        [
                            -74.9729169997632,
                            41.1133270001162
                        ],
                        [
                            -74.964294000384,
                            41.1142370003232
                        ],
                        [
                            -74.9479120003676,
                            41.1235599998574
                        ],
                        [
                            -74.9458190003158,
                            41.1284889998498
                        ],
                        [
                            -74.9311410001888,
                            41.1333870002238
                        ],
                        [
                            -74.9241459996327,
                            41.1374949998959
                        ],
                        [
                            -74.9052560003611,
                            41.1556679997304
                        ],
                        [
                            -74.8997010001038,
                            41.1661810003187
                        ],
                        [
                            -74.8821390000797,
                            41.1808359997404
                        ],
                        [
                            -74.874033999836,
                            41.1985430001635
                        ],
                        [
                            -74.8603979996246,
                            41.2174539997065
                        ],
                        [
                            -74.8593230000971,
                            41.2205070003195
                        ],
                        [
                            -74.8674049998762,
                            41.2277689999888
                        ],
                        [
                            -74.8661810003858,
                            41.232133000116
                        ],
                        [
                            -74.8620479999856,
                            41.2376090001512
                        ],
                        [
                            -74.8616780000897,
                            41.2415749997684
                        ],
                        [
                            -74.8571520002274,
                            41.248975000232
                        ],
                        [
                            -74.8489880004326,
                            41.251192000237
                        ],
                        [
                            -74.8458829997042,
                            41.2549450001826
                        ],
                        [
                            -74.8450309997595,
                            41.2580549997704
                        ],
                        [
                            -74.846557000272,
                            41.2623740001375
                        ],
                        [
                            -74.8383609997917,
                            41.2772939998218
                        ],
                        [
                            -74.8242869999123,
                            41.2921139999227
                        ],
                        [
                            -74.7925580001224,
                            41.3106279998106
                        ],
                        [
                            -74.7923770003724,
                            41.3140880001388
                        ],
                        [
                            -74.7958219999611,
                            41.318515999859
                        ],
                        [
                            -74.7941409996379,
                            41.3211870001742
                        ],
                        [
                            -74.789095000092,
                            41.3232809996659
                        ],
                        [
                            -74.7715880003194,
                            41.3250790002894
                        ],
                        [
                            -74.7634999997942,
                            41.3315699998926
                        ],
                        [
                            -74.760327000192,
                            41.3403280003039
                        ],
                        [
                            -74.7539309998607,
                            41.3461350003304
                        ],
                        [
                            -74.7268879997666,
                            41.34624700029
                        ],
                        [
                            -74.6947079998899,
                            41.3573650001118
                        ],
                        [
                            -74.4578620000374,
                            41.2484270001601
                        ],
                        [
                            -74.3306749998176,
                            41.1854569999981
                        ],
                        [
                            -74.1517380000338,
                            41.1081099997862
                        ],
                        [
                            -73.9027149996369,
                            40.9973270003253
                        ],
                        [
                            -73.8939790002798,
                            40.997204999822
                        ],
                        [
                            -73.8964790000374,
                            40.9817010000933
                        ],
                        [
                            -73.91790499995,
                            40.9175769997719
                        ],
                        [
                            -73.9298210003785,
                            40.8886820002368
                        ],
                        [
                            -73.9483850001352,
                            40.8584710001824
                        ],
                        [
                            -73.9631820000879,
                            40.8269000002793
                        ],
                        [
                            -74.0137840003293,
                            40.7566010002803
                        ],
                        [
                            -74.0262840000157,
                            40.6999019998685
                        ],
                        [
                            -74.0557390000039,
                            40.6517599999186
                        ],
                        [
                            -74.0868059998199,
                            40.651596000196
                        ],
                        [
                            -74.0937460001244,
                            40.6482390002257
                        ],
                        [
                            -74.1099759998048,
                            40.6480110001747
                        ],
                        [
                            -74.1255689996257,
                            40.6440229998283
                        ],
                        [
                            -74.1432550003967,
                            40.6421490001348
                        ],
                        [
                            -74.1813899996655,
                            40.6464750002651
                        ],
                        [
                            -74.1883440002137,
                            40.6442640002811
                        ],
                        [
                            -74.2022470003889,
                            40.6309029998973
                        ],
                        [
                            -74.203737000018,
                            40.6242269999222
                        ],
                        [
                            -74.2018640000742,
                            40.6185570000057
                        ],
                        [
                            -74.203813000186,
                            40.6059610002614
                        ],
                        [
                            -74.1994079997992,
                            40.6002010002228
                        ],
                        [
                            -74.1995199999522,
                            40.5975390000503
                        ],
                        [
                            -74.206297999875,
                            40.5885419997228
                        ],
                        [
                            -74.2089680004102,
                            40.5765630003251
                        ],
                        [
                            -74.2152779996295,
                            40.5602410000571
                        ],
                        [
                            -74.2183980004266,
                            40.5569959999623
                        ],
                        [
                            -74.2316930001562,
                            40.5584569996986
                        ],
                        [
                            -74.2474149998485,
                            40.5492000001608
                        ],
                        [
                            -74.2506090002654,
                            40.5418509997425
                        ],
                        [
                            -74.2460690001594,
                            40.5209519999443
                        ],
                        [
                            -74.2548100004377,
                            40.515344000085
                        ],
                        [
                            -74.2590890004277,
                            40.5028899998926
                        ],
                        [
                            -74.2590900002527,
                            40.4972070001451
                        ],
                        [
                            -74.2533130002375,
                            40.4873860002587
                        ],
                        [
                            -74.2281530001329,
                            40.4773989999546
                        ],
                        [
                            -73.9499119995743,
                            40.5255400000137
                        ],
                        [
                            -73.886652000361,
                            40.4897939997489
                        ],
                        [
                            -73.8896450000382,
                            40.4865109999182
                        ],
                        [
                            -73.8867469998973,
                            40.4758920001404
                        ],
                        [
                            -73.8850599997263,
                            40.4527759998539
                        ],
                        [
                            -73.8867460000724,
                            40.4242269998426
                        ],
                        [
                            -73.887081999633,
                            40.3450090001824
                        ],
                        [
                            -73.8907909997107,
                            40.3106900001599
                        ],
                        [
                            -73.8982080000412,
                            40.2743529997138
                        ],
                        [
                            -73.9068630001057,
                            40.2501109998661
                        ],
                        [
                            -73.9652750001397,
                            40.1129500002562
                        ],
                        [
                            -73.9661449996278,
                            40.0905769999252
                        ],
                        [
                            -73.9732909997966,
                            40.0751420000242
                        ],
                        [
                            -73.9860290000326,
                            40.0211259998065
                        ],
                        [
                            -73.9995770003805,
                            39.976797999949
                        ],
                        [
                            -74.019898999555,
                            39.875118999771
                        ],
                        [
                            -74.027659999842,
                            39.8065039999723
                        ],
                        [
                            -74.0346150002151,
                            39.7624270003247
                        ],
                        [
                            -74.0381579997131,
                            39.7501199998794
                        ],
                        [
                            -74.0494890001607,
                            39.723784999927
                        ],
                        [
                            -74.0621949997114,
                            39.7070350000539
                        ],
                        [
                            -74.0833160000254,
                            39.6695620003076
                        ],
                        [
                            -74.0965310002874,
                            39.6499490003135
                        ],
                        [
                            -74.124580000312,
                            39.6151390002297
                        ],
                        [
                            -74.1831889999895,
                            39.5329940002669
                        ],
                        [
                            -74.2111800002878,
                            39.5001170000949
                        ],
                        [
                            -74.2127419998869,
                            39.5001170000949
                        ],
                        [
                            -74.2495849998932,
                            39.4525999998208
                        ],
                        [
                            -74.2659180004042,
                            39.4224149998572
                        ],
                        [
                            -74.2872709996234,
                            39.398251999653
                        ],
                        [
                            -74.3111350002676,
                            39.3751169999594
                        ],
                        [
                            -74.3254150000113,
                            39.3623389997093
                        ],
                        [
                            -74.3500470000363,
                            39.3457679999306
                        ],
                        [
                            -74.3546590001123,
                            39.3354070000031
                        ],
                        [
                            -74.3625130002858,
                            39.3257810000663
                        ],
                        [
                            -74.3745910001977,
                            39.3175259998371
                        ],
                        [
                            -74.3921959998796,
                            39.3122859999636
                        ],
                        [
                            -74.4306010003834,
                            39.3083559996929
                        ],
                        [
                            -74.4616149995977,
                            39.301022999997
                        ],
                        [
                            -74.4828179999275,
                            39.2841349996872
                        ],
                        [
                            -74.4896989997824,
                            39.2773010003346
                        ],
                        [
                            -74.490819000414,
                            39.2739500000563
                        ],
                        [
                            -74.5084709999516,
                            39.2609710000031
                        ],
                        [
                            -74.5239580003656,
                            39.2402599998708
                        ],
                        [
                            -74.5556090003377,
                            39.2197549997485
                        ],
                        [
                            -74.5652410000204,
                            39.2106420001536
                        ],
                        [
                            -74.5837350003552,
                            39.1872200002964
                        ],
                        [
                            -74.595346000287,
                            39.1777520001764
                        ],
                        [
                            -74.6036020001433,
                            39.1629450003032
                        ],
                        [
                            -74.6132349996509,
                            39.1526930002629
                        ],
                        [
                            -74.6246020000835,
                            39.1435800000292
                        ],
                        [
                            -74.6442789999008,
                            39.1148239998561
                        ],
                        [
                            -74.6403840000503,
                            39.1022740001113
                        ],
                        [
                            -74.6398679996662,
                            39.0946529997623
                        ],
                        [
                            -74.6421690001305,
                            39.0845739998197
                        ],
                        [
                            -74.646874000093,
                            39.0762120000808
                        ],
                        [
                            -74.6616149999691,
                            39.0612839998057
                        ],
                        [
                            -74.6821930001349,
                            39.0498240001464
                        ],
                        [
                            -74.7096449995846,
                            39.0145689997936
                        ],
                        [
                            -74.7155709995627,
                            39.0092160000604
                        ],
                        [
                            -74.7200079997365,
                            39.0001130001853
                        ],
                        [
                            -74.7174119997195,
                            39.0001130001853
                        ],
                        [
                            -74.7238299997921,
                            38.9891940001568
                        ],
                        [
                            -74.7284019996852,
                            38.9742489997979
                        ],
                        [
                            -74.7431069995764,
                            38.958885000108
                        ],
                        [
                            -74.7593060001929,
                            38.9489080001322
                        ],
                        [
                            -74.7739380002892,
                            38.9427940001513
                        ],
                        [
                            -74.8006790002595,
                            38.9205829998012
                        ],
                        [
                            -74.8035709996543,
                            38.9139110003416
                        ],
                        [
                            -74.8093929998753,
                            38.9069569996865
                        ],
                        [
                            -74.8243289997451,
                            38.8962620000992
                        ],
                        [
                            -74.8407979998002,
                            38.8900979997566
                        ],
                        [
                            -74.8721580004175,
                            38.8877940001655
                        ],
                        [
                            -74.9079860002725,
                            38.8793159996543
                        ],
                        [
                            -74.9133659997293,
                            38.8751120003013
                        ],
                        [
                            -74.910968000079,
                            38.8751120003013
                        ],
                        [
                            -75.0151230004062,
                            38.7886570003352
                        ],
                        [
                            -75.1684620004174,
                            39.0561789999978
                        ],
                        [
                            -75.1916709998621,
                            39.0789999996941
                        ],
                        [
                            -75.2490169996911,
                            39.158944999915
                        ],
                        [
                            -75.3162229997068,
                            39.2453070000313
                        ],
                        [
                            -75.3210209995557,
                            39.2513790001993
                        ],
                        [
                            -75.4447509995929,
                            39.3550890003189
                        ],
                        [
                            -75.4694360002197,
                            39.3731470001463
                        ],
                        [
                            -75.4813600001458,
                            39.3875549999698
                        ],
                        [
                            -75.5605379997682,
                            39.4556449996593
                        ],
                        [
                            -75.5635859996969,
                            39.4830480002578
                        ],
                        [
                            -75.5590899999719,
                            39.4958059997853
                        ],
                        [
                            -75.5379340003961,
                            39.4969819999934
                        ],
                        [
                            -75.5278609997741,
                            39.498139000328
                        ],
                        [
                            -75.5268230000566,
                            39.4994110002573
                        ],
                        [
                            -75.52936800002,
                            39.501229000263
                        ],
                        [
                            -75.530141000322,
                            39.5053730002381
                        ],
                        [
                            -75.5297410002889,
                            39.5120400001581
                        ],
                        [
                            -75.5274470003956,
                            39.5159680002734
                        ],
                        [
                            -75.5267870000716,
                            39.5314400000264
                        ],
                        [
                            -75.5276750000013,
                            39.5352769998846
                        ],
                        [
                            -75.5315749998747,
                            39.5368249998479
                        ],
                        [
                            -75.5340140004312,
                            39.5407019997012
                        ],
                        [
                            -75.5190679996173,
                            39.5554340002852
                        ],
                        [
                            -75.5119599999817,
                            39.5672900000394
                        ],
                        [
                            -75.5127700000936,
                            39.5782899999477
                        ],
                        [
                            -75.5147469997944,
                            39.5808840001613
                        ],
                        [
                            -75.5196359998799,
                            39.5833280000708
                        ],
                        [
                            -75.5276930004429,
                            39.5841120002865
                        ],
                        [
                            -75.5375030004009,
                            39.5936270002518
                        ],
                        [
                            -75.542146000439,
                            39.5945190002619
                        ],
                        [
                            -75.5535120001484,
                            39.6018190000079
                        ],
                        [
                            -75.5573710000139,
                            39.609114999756
                        ],
                        [
                            -75.5565600000771,
                            39.6115340001053
                        ],
                        [
                            -75.5594549998449,
                            39.622314000075
                        ],
                        [
                            -75.5587729997795,
                            39.6299869998521
                        ],
                        [
                            -75.554143999985,
                            39.6356900000162
                        ],
                        [
                            -75.5485340003745,
                            39.6377699999629
                        ],
                        [
                            -75.5419780002095,
                            39.6454250000225
                        ],
                        [
                            -75.5335239999865,
                            39.6475570002361
                        ],
                        [
                            -75.5155329996169,
                            39.6663260003267
                        ],
                        [
                            -75.510374000093,
                            39.6779350002803
                        ],
                        [
                            -75.5090860001302,
                            39.6858069997346
                        ],
                        [
                            -75.5098739996025,
                            39.6939150000087
                        ],
                        [
                            -75.5065360001439,
                            39.6972629999625
                        ],
                        [
                            -75.4963119999092,
                            39.7009559999024
                        ],
                        [
                            -75.4918270000548,
                            39.7108390002628
                        ],
                        [
                            -75.4883840001159,
                            39.7147079998692
                        ],
                        [
                            -75.485342000035,
                            39.7158980001032
                        ],
                        [
                            -75.4793609998053,
                            39.7137059999603
                        ],
                        [
                            -75.4764810001061,
                            39.7161420001419
                        ],
                        [
                            -75.4771690000193,
                            39.7249950002452
                        ],
                        [
                            -75.4750719997695,
                            39.7294879998981
                        ],
                        [
                            -75.4756929997355,
                            39.7336579998042
                        ],
                        [
                            -75.4680169998373,
                            39.7458110000175
                        ],
                        [
                            -75.4635500004245,
                            39.7570139997162
                        ],
                        [
                            -75.4639210001453,
                            39.7607630003426
                        ],
                        [
                            -75.4594399995906,
                            39.7658109996915
                        ],
                        [
                            -75.4523389996276,
                            39.7690130000223
                        ],
                        [
                            -75.4492949998969,
                            39.7744359998422
                        ],
                        [
                            -75.45068800034,
                            39.7750639998843
                        ],
                        [
                            -75.4452350001899,
                            39.7794530002762
                        ],
                        [
                            -75.4382999999083,
                            39.7844019997994
                        ],
                        [
                            -75.4054139996768,
                            39.7963960002712
                        ],
                        [
                            -75.4150219999683,
                            39.8020039997799
                        ],
                        [
                            -75.3984170003692,
                            39.8104670003059
                        ],
                        [
                            -75.3544010004299,
                            39.8399170003426
                        ],
                        [
                            -75.341766000126,
                            39.8460819996939
                        ],
                        [
                            -75.3232309997833,
                            39.8498129996647
                        ],
                        [
                            -75.2722019997446,
                            39.8490760002021
                        ],
                        [
                            -75.2461489996875,
                            39.8539819999554
                        ],
                        [
                            -75.2213710000726,
                            39.861543000084
                        ],
                        [
                            -75.1887700002465,
                            39.8808319997277
                        ],
                        [
                            -75.1437459998285,
                            39.8849980002145
                        ],
                        [
                            -75.1372959999688,
                            39.8899659999579
                        ],
                        [
                            -75.1300540004389,
                            39.9023900003118
                        ],
                        [
                            -75.1282529995668,
                            39.9131640001879
                        ],
                        [
                            -75.1355269997821,
                            39.9291600001518
                        ],
                        [
                            -75.136268000297,
                            39.9432820001439
                        ],
                        [
                            -75.1336980002192,
                            39.9546199997005
                        ],
                        [
                            -75.1257009999306,
                            39.9618229999273
                        ],
                        [
                            -75.1022870004466,
                            39.972377000128
                        ],
                        [
                            -75.0756050000275,
                            39.9780410001317
                        ],
                        [
                            -75.0641749998457,
                            39.9860269997741
                        ],
                        [
                            -75.053404999988,
                            40.0045679998639
                        ],
                        [
                            -75.0470899998475,
                            40.0099409999607
                        ],
                        [
                            -75.0342160000674,
                            40.0152189997791
                        ],
                        [
                            -75.0132370000436,
                            40.0203029999248
                        ],
                        [
                            -74.9739810003183,
                            40.0485949998048
                        ],
                        [
                            -74.9388969995546,
                            40.0660990002017
                        ],
                        [
                            -74.9240729998378,
                            40.0712349997286
                        ],
                        [
                            -74.9073179995525,
                            40.0708000002553
                        ],
                        [
                            -74.8617559999075,
                            40.0834620001749
                        ],
                        [
                            -74.8546089999138,
                            40.0932700001549
                        ],
                        [
                            -74.8422149996344,
                            40.0985020001143
                        ],
                        [
                            -74.8358679997068,
                            40.1034660002598
                        ],
                        [
                            -74.8294029997785,
                            40.1191920001192
                        ],
                        [
                            -74.8225310001444,
                            40.1268440001101
                        ],
                        [
                            -74.8154760002122,
                            40.1276939998173
                        ],
                        [
                            -74.799421000434,
                            40.1227029999847
                        ],
                        [
                            -74.7841680003717,
                            40.120477000144
                        ],
                        [
                            -74.7597210002946,
                            40.1338039999375
                        ],
                        [
                            -74.7411840003021,
                            40.135165000253
                        ],
                        [
                            -74.7243689997424,
                            40.1464119997712
                        ],
                        [
                            -74.7220480000849,
                            40.1503109996955
                        ],
                        [
                            -74.7223250000944,
                            40.1607270002966
                        ],
                        [
                            -74.7372049998877,
                            40.1776090000227
                        ],
                        [
                            -74.7543049999545,
                            40.1852089999923
                        ],
                        [
                            -74.7606050000264,
                            40.1989090000628
                        ],
                        [
                            -74.7672749999941,
                            40.2070760002667
                        ],
                        [
                            -74.7714369998084,
                            40.2151760003299
                        ],
                        [
                            -74.7953059995771,
                            40.2294080000467
                        ],
                        [
                            -74.8195069996068,
                            40.2385080000472
                        ],
                        [
                            -74.836307000098,
                            40.2462079997112
                        ],
                        [
                            -74.8423080004191,
                            40.2505080000489
                        ],
                        [
                            -74.8604920002342,
                            40.2845840001513
                        ],
                        [
                            -74.8682080003154,
                            40.2952059997223
                        ],
                        [
                            -74.891609000279,
                            40.3130069999672
                        ],
                        [
                            -74.9083100001376,
                            40.316906999794
                        ],
                        [
                            -74.9397109998645,
                            40.338006000134
                        ],
                        [
                            -74.9445170001093,
                            40.3450629999782
                        ],
                        [
                            -74.9489009996813,
                            40.3650550002366
                        ],
                        [
                            -74.9665520002923,
                            40.3981539999544
                        ],
                        [
                            -74.9831309999521,
                            40.4045989997936
                        ],
                        [
                            -74.988413000398,
                            40.4084940002741
                        ],
                        [
                            -74.9958599999674,
                            40.4103840000228
                        ],
                        [
                            -75.023027999735,
                            40.4036930000981
                        ],
                        [
                            -75.028633000221,
                            40.4039709997024
                        ],
                        [
                            -75.0344269999546,
                            40.4060660001332
                        ],
                        [
                            -75.0455259997002,
                            40.4136790001813
                        ],
                        [
                            -75.0585750002809,
                            40.418192999772
                        ],
                        [
                            -75.0618349999217,
                            40.4241040001562
                        ],
                        [
                            -75.0659459996823,
                            40.4452660002533
                        ],
                        [
                            -75.0706780003072,
                            40.4567940001125
                        ],
                        [
                            -75.0670160000852,
                            40.4653080000355
                        ],
                        [
                            -75.067714999869,
                            40.4736690002021
                        ],
                        [
                            -75.0626640003002,
                            40.4804390002216
                        ],
                        [
                            -75.0651020001335,
                            40.5264130000573
                        ],
                        [
                            -75.0685810000574,
                            40.5421850002464
                        ],
                        [
                            -75.0780470000587,
                            40.5478759997953
                        ],
                        [
                            -75.0998269999736,
                            40.5673979997545
                        ],
                        [
                            -75.1163939999379,
                            40.5730640002113
                        ],
                        [
                            -75.1401720003684,
                            40.5753700000822
                        ],
                        [
                            -75.1612319996847,
                            40.5642869998317
                        ],
                        [
                            -75.171623000324,
                            40.5642219997766
                        ],
                        [
                            -75.182190999617,
                            40.5670860000413
                        ],
                        [
                            -75.1882699997559,
                            40.5705469999916
                        ],
                        [
                            -75.1954960002907,
                            40.5786269997828
                        ],
                        [
                            -75.1941499997033,
                            40.5828430002105
                        ],
                        [
                            -75.189691999613,
                            40.5876709998721
                        ],
                        [
                            -75.1895999995515,
                            40.5917759999937
                        ]
                    ],
                    [
                        [
                            -74.0406440001254,
                            40.6998830000817
                        ],
                        [
                            -74.0399310000978,
                            40.6985900000339
                        ],
                        [
                            -74.0383719999734,
                            40.6986689999997
                        ],
                        [
                            -74.038936000038,
                            40.6996550001751
                        ],
                        [
                            -74.0406440001254,
                            40.6998830000817
                        ]
                    ],
                    [
                        [
                            -74.0450739997282,
                            40.6887350003269
                        ],
                        [
                            -74.0437730002449,
                            40.6900830000002
                        ],
                        [
                            -74.0471690003281,
                            40.6911139997251
                        ],
                        [
                            -74.046469999646,
                            40.6894899999803
                        ],
                        [
                            -74.0450739997282,
                            40.6887350003269
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 40.1072744,
            "name": "New Jersey",
            "intptlon": -74.6652012,
            "geo_point_2d": [
                40.1102763626,
                -74.6555473755
            ],
            "geoid": "34",
            "mtfcc": "G4000",
            "region": 1
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -74.6555473755,
                40.1102763626
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "3d145e279b36346b9683ea7a390b0bebcbfbbb6e",
        "fields": {
            "arealand": "2141806805",
            "objectid": 27,
            "basename": "Colorado",
            "stusab": "CO",
            "statens": "01779779",
            "centlon": -105.5478283,
            "state": "08",
            "gid": 5,
            "centlat": 38.9976175,
            "division": 8,
            "areawater": "1174677436",
            "oid": "307146555",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -109.050948999971,
                            40.2226470000998
                        ],
                        [
                            -109.051245000247,
                            40.4415989999939
                        ],
                        [
                            -109.047767000148,
                            40.6098790001952
                        ],
                        [
                            -109.050025999881,
                            41.000690999837
                        ],
                        [
                            -108.526029000036,
                            40.9995919998175
                        ],
                        [
                            -108.046539000399,
                            41.0020639997069
                        ],
                        [
                            -107.000606000075,
                            41.0034439997708
                        ],
                        [
                            -106.455714999696,
                            41.0021180001111
                        ],
                        [
                            -106.195191999695,
                            40.9975780002449
                        ],
                        [
                            -105.729406000042,
                            40.9968220001816
                        ],
                        [
                            -104.855272999554,
                            40.9980480002953
                        ],
                        [
                            -104.675999000384,
                            41.000957000313
                        ],
                        [
                            -104.505711999744,
                            41.0016940001911
                        ],
                        [
                            -102.051716999827,
                            41.0023589997564
                        ],
                        [
                            -102.051665999773,
                            39.8476890002887
                        ],
                        [
                            -102.045350999633,
                            38.8568969996887
                        ],
                        [
                            -102.044707999926,
                            38.0412569998813
                        ],
                        [
                            -102.041604999745,
                            37.6892419999362
                        ],
                        [
                            -102.042089000342,
                            36.9930159998508
                        ],
                        [
                            -102.698142000402,
                            36.9951490001591
                        ],
                        [
                            -102.752140999653,
                            36.9983410002298
                        ],
                        [
                            -102.815074000425,
                            36.9997739999144
                        ],
                        [
                            -102.986976000368,
                            36.9985240001282
                        ],
                        [
                            -103.002328999989,
                            37.000149999736
                        ],
                        [
                            -103.625506999687,
                            36.9986290003297
                        ],
                        [
                            -104.338832999781,
                            36.993534999779
                        ],
                        [
                            -104.624555000244,
                            36.9943750000666
                        ],
                        [
                            -105.029225999716,
                            36.9927269998293
                        ],
                        [
                            -105.120800000166,
                            36.995428000213
                        ],
                        [
                            -105.707852000083,
                            36.9959189996815
                        ],
                        [
                            -106.86982000028,
                            36.9924969999683
                        ],
                        [
                            -106.878317000161,
                            37.0001019996792
                        ],
                        [
                            -109.045171999956,
                            36.9989769999816
                        ],
                        [
                            -109.045639999761,
                            37.359723000173
                        ],
                        [
                            -109.041571999658,
                            37.5621380003295
                        ],
                        [
                            -109.042571999741,
                            38.1570159997971
                        ],
                        [
                            -109.042835000405,
                            38.1662679997321
                        ],
                        [
                            -109.060204000085,
                            38.2782379996979
                        ],
                        [
                            -109.059824000143,
                            38.7147459997869
                        ],
                        [
                            -109.054022000014,
                            38.877161
                        ],
                        [
                            -109.051165999706,
                            39.1426030000478
                        ],
                        [
                            -109.050948999971,
                            40.2226470000998
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 38.9938482,
            "name": "Colorado",
            "intptlon": -105.5083165,
            "geo_point_2d": [
                38.9985186267,
                -105.54785684
            ],
            "geoid": "08",
            "mtfcc": "G4000",
            "region": 4
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -105.54785684,
                38.9985186267
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "b64a4c92d079b8cc63d4d2f95011707ac12698c8",
        "fields": {
            "arealand": "552436514",
            "objectid": 28,
            "basename": "South Carolina",
            "stusab": "SC",
            "statens": "01779799",
            "centlon": -80.8583239,
            "state": "45",
            "gid": 6,
            "centlat": 33.8759382,
            "division": 5,
            "areawater": "775849516",
            "oid": "-1945417158",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.7410070003352,
                            34.2088620000861
                        ],
                        [
                            -82.7423399996055,
                            34.2134319996609
                        ],
                        [
                            -82.7402569995995,
                            34.2194479999894
                        ],
                        [
                            -82.7443769995809,
                            34.2256770002841
                        ],
                        [
                            -82.7420120004408,
                            34.2293280003488
                        ],
                        [
                            -82.7448729998734,
                            34.2449189998725
                        ],
                        [
                            -82.7429199995637,
                            34.2510719999186
                        ],
                        [
                            -82.7455669996344,
                            34.2540660000195
                        ],
                        [
                            -82.7458250002755,
                            34.2583560000246
                        ],
                        [
                            -82.7485359999203,
                            34.2631259999822
                        ],
                        [
                            -82.7468110001145,
                            34.2665880001131
                        ],
                        [
                            -82.7542419997904,
                            34.2757870000993
                        ],
                        [
                            -82.7616880004332,
                            34.2816850001055
                        ],
                        [
                            -82.7667540000706,
                            34.2823220002287
                        ],
                        [
                            -82.7715150001095,
                            34.2860529999578
                        ],
                        [
                            -82.7821279996084,
                            34.2982780002757
                        ],
                        [
                            -82.7819929998892,
                            34.3031769998995
                        ],
                        [
                            -82.7870610000748,
                            34.310299000091
                        ],
                        [
                            -82.7917450001208,
                            34.3251609997746
                        ],
                        [
                            -82.7911779996831,
                            34.3305410002193
                        ],
                        [
                            -82.7944700000093,
                            34.3403789996455
                        ],
                        [
                            -82.8126069999687,
                            34.3511439999015
                        ],
                        [
                            -82.813770000258,
                            34.3539349999756
                        ],
                        [
                            -82.8344570002038,
                            34.3652210002402
                        ],
                        [
                            -82.8359750003203,
                            34.3809089998556
                        ],
                        [
                            -82.8417740000768,
                            34.3915530001384
                        ],
                        [
                            -82.8414529996864,
                            34.3990540002154
                        ],
                        [
                            -82.847510999909,
                            34.4127610001354
                        ],
                        [
                            -82.8469689995857,
                            34.4198760002086
                        ],
                        [
                            -82.8496259997021,
                            34.4256780000696
                        ],
                        [
                            -82.8540109999974,
                            34.428818000166
                        ],
                        [
                            -82.855485999558,
                            34.441790999946
                        ],
                        [
                            -82.8601239995732,
                            34.4528400000677
                        ],
                        [
                            -82.8588370003337,
                            34.4552200001112
                        ],
                        [
                            -82.8644000000886,
                            34.4597849997624
                        ],
                        [
                            -82.871845999833,
                            34.4611889997544
                        ],
                        [
                            -82.8734789995773,
                            34.4643189998664
                        ],
                        [
                            -82.8806440000127,
                            34.4690000000118
                        ],
                        [
                            -82.8813450003447,
                            34.4728670002558
                        ],
                        [
                            -82.887397999646,
                            34.4809590001384
                        ],
                        [
                            -82.8924480002883,
                            34.4811070003619
                        ],
                        [
                            -82.9015510000665,
                            34.4867640002013
                        ],
                        [
                            -82.925317999728,
                            34.4814590003191
                        ],
                        [
                            -82.9303769996927,
                            34.4855999999077
                        ],
                        [
                            -82.9369649996447,
                            34.4865370001609
                        ],
                        [
                            -82.9439869999649,
                            34.485515000302
                        ],
                        [
                            -82.9482280003202,
                            34.4804920002384
                        ],
                        [
                            -82.9556449997523,
                            34.4788869998014
                        ],
                        [
                            -82.9585479999161,
                            34.4795539997841
                        ],
                        [
                            -82.9642410002656,
                            34.4845710000866
                        ],
                        [
                            -82.9664799999073,
                            34.4823330002529
                        ],
                        [
                            -82.974917000412,
                            34.4793500003132
                        ],
                        [
                            -82.9794790002593,
                            34.4829609999384
                        ],
                        [
                            -82.9911429998944,
                            34.4798050000675
                        ],
                        [
                            -82.9938889996994,
                            34.4778289997948
                        ],
                        [
                            -82.9959009995603,
                            34.4722039999672
                        ],
                        [
                            -83.0172520000281,
                            34.478246999711
                        ],
                        [
                            -83.0283950001547,
                            34.4838760001665
                        ],
                        [
                            -83.0345649996319,
                            34.4835709999716
                        ],
                        [
                            -83.0484240003245,
                            34.4934389999968
                        ],
                        [
                            -83.0516379999345,
                            34.4937020000581
                        ],
                        [
                            -83.0543219998151,
                            34.5027939999151
                        ],
                        [
                            -83.0586970000647,
                            34.5035830001384
                        ],
                        [
                            -83.0657960003778,
                            34.5010409998815
                        ],
                        [
                            -83.0725859999962,
                            34.50410500006
                        ],
                        [
                            -83.0764540000825,
                            34.5077929997314
                        ],
                        [
                            -83.0792459999182,
                            34.5141220002311
                        ],
                        [
                            -83.0872420003818,
                            34.5161820001774
                        ],
                        [
                            -83.0779110000997,
                            34.5237730001003
                        ],
                        [
                            -83.0783989999963,
                            34.52537200025
                        ],
                        [
                            -83.08782200034,
                            34.5320849999817
                        ],
                        [
                            -83.1026920000876,
                            34.5324019999981
                        ],
                        [
                            -83.1040789997846,
                            34.5400400000146
                        ],
                        [
                            -83.1092390000317,
                            34.5421780001772
                        ],
                        [
                            -83.1106740003076,
                            34.5478580000198
                        ],
                        [
                            -83.1162670001997,
                            34.5521720003027
                        ],
                        [
                            -83.121894000427,
                            34.5523379999101
                        ],
                        [
                            -83.124054000426,
                            34.5540679996579
                        ],
                        [
                            -83.1220519997125,
                            34.5580280002325
                        ],
                        [
                            -83.1229039996572,
                            34.560116999861
                        ],
                        [
                            -83.1271759999746,
                            34.5619989999069
                        ],
                        [
                            -83.133375999589,
                            34.5594989999383
                        ],
                        [
                            -83.1398759996775,
                            34.5617990002481
                        ],
                        [
                            -83.1398759996775,
                            34.567998999786
                        ],
                        [
                            -83.1431760003995,
                            34.5733989998299
                        ],
                        [
                            -83.1536769997454,
                            34.5786990001983
                        ],
                        [
                            -83.1586770001589,
                            34.5789989999248
                        ],
                        [
                            -83.159077000192,
                            34.5814990001598
                        ],
                        [
                            -83.154577000269,
                            34.5881980003551
                        ],
                        [
                            -83.1696739997973,
                            34.5916930000115
                        ],
                        [
                            -83.1709779996536,
                            34.5980979998637
                        ],
                        [
                            -83.1650779996148,
                            34.6008979998249
                        ],
                        [
                            -83.1605769998669,
                            34.5994980001822
                        ],
                        [
                            -83.1596770002416,
                            34.6032979997635
                        ],
                        [
                            -83.1725779997859,
                            34.6077979996719
                        ],
                        [
                            -83.1852780003871,
                            34.6069979997337
                        ],
                        [
                            -83.1968790002731,
                            34.6030979996449
                        ],
                        [
                            -83.1977789998984,
                            34.6070979999626
                        ],
                        [
                            -83.2037850002424,
                            34.6095990001569
                        ],
                        [
                            -83.2164029999295,
                            34.6110109997552
                        ],
                        [
                            -83.2222829998769,
                            34.6094760002361
                        ],
                        [
                            -83.2325799999065,
                            34.6115969998929
                        ],
                        [
                            -83.2433809997263,
                            34.6179969997764
                        ],
                        [
                            -83.2406690002566,
                            34.6245070001033
                        ],
                        [
                            -83.2464810004319,
                            34.6268970000059
                        ],
                        [
                            -83.2482809996824,
                            34.6316960001932
                        ],
                        [
                            -83.2552810002614,
                            34.6376959999243
                        ],
                        [
                            -83.27198200012,
                            34.6418959997945
                        ],
                        [
                            -83.2779599999766,
                            34.6448530000906
                        ],
                        [
                            -83.2807819999495,
                            34.6489959998361
                        ],
                        [
                            -83.2931829999016,
                            34.654295999856
                        ],
                        [
                            -83.3008479999292,
                            34.6624699996395
                        ],
                        [
                            -83.3014770002911,
                            34.6665819999709
                        ],
                        [
                            -83.3046409996725,
                            34.6695610000901
                        ],
                        [
                            -83.3164009995671,
                            34.66931600017
                        ],
                        [
                            -83.3194400001733,
                            34.6759740002375
                        ],
                        [
                            -83.3290020004341,
                            34.6811369996435
                        ],
                        [
                            -83.3405270001521,
                            34.6807360002054
                        ],
                        [
                            -83.3426169998309,
                            34.6829909997325
                        ],
                        [
                            -83.3382459997793,
                            34.687033000155
                        ],
                        [
                            -83.3401339997917,
                            34.6914829999458
                        ],
                        [
                            -83.3536219998652,
                            34.6977920002366
                        ],
                        [
                            -83.3529890002036,
                            34.7008400002983
                        ],
                        [
                            -83.3466489999488,
                            34.7042199997313
                        ],
                        [
                            -83.3504869998979,
                            34.709694000207
                        ],
                        [
                            -83.3526920001027,
                            34.7169039996787
                        ],
                        [
                            -83.3533249997643,
                            34.728690999733
                        ],
                        [
                            -83.3486249998247,
                            34.7370409997615
                        ],
                        [
                            -83.3391649996712,
                            34.7382850002319
                        ],
                        [
                            -83.3389129997761,
                            34.7417829999833
                        ],
                        [
                            -83.3330539997451,
                            34.7456020002999
                        ],
                        [
                            -83.3323290000221,
                            34.7491150000417
                        ],
                        [
                            -83.3263849996023,
                            34.7507479996787
                        ],
                        [
                            -83.3262330001647,
                            34.7562520001442
                        ],
                        [
                            -83.3187480000622,
                            34.7576260002616
                        ],
                        [
                            -83.3232959996657,
                            34.7607380001763
                        ],
                        [
                            -83.3191759996843,
                            34.7646139998881
                        ],
                        [
                            -83.3229979997399,
                            34.7662470000113
                        ],
                        [
                            -83.318977000391,
                            34.7713090001131
                        ],
                        [
                            -83.3226620001793,
                            34.7773900003064
                        ],
                        [
                            -83.3212129996597,
                            34.7790410002869
                        ],
                        [
                            -83.3241419997627,
                            34.7892459996721
                        ],
                        [
                            -83.3204419999058,
                            34.7905809997858
                        ],
                        [
                            -83.320404000271,
                            34.7931790001844
                        ],
                        [
                            -83.3140640000161,
                            34.7964709997036
                        ],
                        [
                            -83.3141629997503,
                            34.7995609999412
                        ],
                        [
                            -83.3032230000133,
                            34.8022080000219
                        ],
                        [
                            -83.3008420000814,
                            34.8043440001394
                        ],
                        [
                            -83.2994160000264,
                            34.8094519997697
                        ],
                        [
                            -83.3044049996709,
                            34.8111500000335
                        ],
                        [
                            -83.306679000371,
                            34.814391999957
                        ],
                        [
                            -83.305618000189,
                            34.8165170000928
                        ],
                        [
                            -83.3030089997531,
                            34.8188360001042
                        ],
                        [
                            -83.3001859999553,
                            34.8185539996642
                        ],
                        [
                            -83.2989270003048,
                            34.8172569999657
                        ],
                        [
                            -83.2998500003946,
                            34.8150370003198
                        ],
                        [
                            -83.2951429998841,
                            34.8132250001758
                        ],
                        [
                            -83.2945169998952,
                            34.8160319996898
                        ],
                        [
                            -83.2899550000479,
                            34.8184589997641
                        ],
                        [
                            -83.2910770003293,
                            34.8231929996369
                        ],
                        [
                            -83.2886500003666,
                            34.8246729996347
                        ],
                        [
                            -83.2770839997425,
                            34.81699799966
                        ],
                        [
                            -83.2708819995799,
                            34.8185879999201
                        ],
                        [
                            -83.2683180002481,
                            34.8211249997932
                        ],
                        [
                            -83.269637999998,
                            34.8236470001879
                        ],
                        [
                            -83.2671800000732,
                            34.8313249996486
                        ],
                        [
                            -83.2725880000174,
                            34.835512999666
                        ],
                        [
                            -83.2671959999667,
                            34.8484689996508
                        ],
                        [
                            -83.2617250002733,
                            34.8486370001576
                        ],
                        [
                            -83.2606320003042,
                            34.8452849999039
                        ],
                        [
                            -83.2572859995513,
                            34.8440999998107
                        ],
                        [
                            -83.2489269997628,
                            34.8451410002602
                        ],
                        [
                            -83.253905000435,
                            34.8487689999467
                        ],
                        [
                            -83.2524500000677,
                            34.8521849999219
                        ],
                        [
                            -83.2533189997308,
                            34.8559229999527
                        ],
                        [
                            -83.2464139995864,
                            34.8555179998248
                        ],
                        [
                            -83.2482380000247,
                            34.8581699997003
                        ],
                        [
                            -83.2463430003396,
                            34.8600250003415
                        ],
                        [
                            -83.2480920004349,
                            34.8655770000616
                        ],
                        [
                            -83.2460250003223,
                            34.8672290002788
                        ],
                        [
                            -83.2391129996069,
                            34.8650859999792
                        ],
                        [
                            -83.237877000421,
                            34.8688769998544
                        ],
                        [
                            -83.2347030000956,
                            34.8707429999973
                        ],
                        [
                            -83.2405849996927,
                            34.8727909996846
                        ],
                        [
                            -83.2429959997619,
                            34.8775369996364
                        ],
                        [
                            -83.2395100001653,
                            34.8788869998395
                        ],
                        [
                            -83.2324900003932,
                            34.8777770001957
                        ],
                        [
                            -83.2292480002957,
                            34.8797610000878
                        ],
                        [
                            -83.2201310002738,
                            34.8780360001514
                        ],
                        [
                            -83.2198869998763,
                            34.8811109998732
                        ],
                        [
                            -83.2174220002788,
                            34.8823810003576
                        ],
                        [
                            -83.2172769996155,
                            34.8860890001847
                        ],
                        [
                            -83.2140270000205,
                            34.8885690000255
                        ],
                        [
                            -83.2127690001949,
                            34.88761499983
                        ],
                        [
                            -83.2145769998414,
                            34.8836290001767
                        ],
                        [
                            -83.2095720003033,
                            34.8798559999873
                        ],
                        [
                            -83.2052609996279,
                            34.8801650001967
                        ],
                        [
                            -83.2010340004147,
                            34.8844069997184
                        ],
                        [
                            -83.2047579996626,
                            34.8902169999105
                        ],
                        [
                            -83.203536999647,
                            34.893451999938
                        ],
                        [
                            -83.2003330000827,
                            34.8932530003164
                        ],
                        [
                            -83.1986009997059,
                            34.8903849998772
                        ],
                        [
                            -83.1971820002218,
                            34.8905940001983
                        ],
                        [
                            -83.1976619997225,
                            34.894767999819
                        ],
                        [
                            -83.1945109998617,
                            34.8994330000223
                        ],
                        [
                            -83.1914980000931,
                            34.8967820002704
                        ],
                        [
                            -83.1881260002993,
                            34.8980869998449
                        ],
                        [
                            -83.1806949997251,
                            34.9045790001927
                        ],
                        [
                            -83.1809159996581,
                            34.9094699996902
                        ],
                        [
                            -83.174629000005,
                            34.910281999765
                        ],
                        [
                            -83.1707090000401,
                            34.9142410001147
                        ],
                        [
                            -83.1686069997674,
                            34.9193550003297
                        ],
                        [
                            -83.1648409997884,
                            34.9191700003618
                        ],
                        [
                            -83.1604229998811,
                            34.915015999718
                        ],
                        [
                            -83.1569899999879,
                            34.9151230003678
                        ],
                        [
                            -83.1592410002235,
                            34.9286080000066
                        ],
                        [
                            -83.1568910002538,
                            34.9307670000086
                        ],
                        [
                            -83.1415179996426,
                            34.9245149996596
                        ],
                        [
                            -83.1299589995894,
                            34.9313470001057
                        ],
                        [
                            -83.1317369999969,
                            34.9357189999817
                        ],
                        [
                            -83.127312000417,
                            34.9386330000206
                        ],
                        [
                            -83.1205439996417,
                            34.9329449998168
                        ],
                        [
                            -83.1179049999669,
                            34.9360430002873
                        ],
                        [
                            -83.1117549996828,
                            34.9349630001828
                        ],
                        [
                            -83.1165469996839,
                            34.9425049998612
                        ],
                        [
                            -83.121077999569,
                            34.9421540000659
                        ],
                        [
                            -83.1276239996882,
                            34.9498289997359
                        ],
                        [
                            -83.1247520003849,
                            34.9510739997178
                        ],
                        [
                            -83.1270749996922,
                            34.9540410000979
                        ],
                        [
                            -83.1254269998793,
                            34.9550899997686
                        ],
                        [
                            -83.1175839995765,
                            34.9527929999196
                        ],
                        [
                            -83.1141199997212,
                            34.9501840001527
                        ],
                        [
                            -83.1148219998781,
                            34.9528499998357
                        ],
                        [
                            -83.1202880004469,
                            34.9579629996448
                        ],
                        [
                            -83.1202309996472,
                            34.9591310003307
                        ],
                        [
                            -83.1157909999986,
                            34.9601670002978
                        ],
                        [
                            -83.1216199998923,
                            34.9627659999117
                        ],
                        [
                            -83.1219789999175,
                            34.964756999706
                        ],
                        [
                            -83.1203260000818,
                            34.9687830003259
                        ],
                        [
                            -83.1156210001193,
                            34.9730500003039
                        ],
                        [
                            -83.1165309997904,
                            34.9757229996407
                        ],
                        [
                            -83.1111830001206,
                            34.9757189998088
                        ],
                        [
                            -83.1112750001821,
                            34.9810029997967
                        ],
                        [
                            -83.1077350001588,
                            34.9798050001293
                        ],
                        [
                            -83.1057659999555,
                            34.9815030002183
                        ],
                        [
                            -83.106964000405,
                            34.9835360003326
                        ],
                        [
                            -83.101799000135,
                            34.986126000105
                        ],
                        [
                            -83.1028589995939,
                            34.9887079997677
                        ],
                        [
                            -83.0982440000431,
                            34.9908220002749
                        ],
                        [
                            -83.1035160004432,
                            34.9936749996642
                        ],
                        [
                            -83.1024700003297,
                            34.9968950002038
                        ],
                        [
                            -83.1086130000428,
                            35.0006590000544
                        ],
                        [
                            -82.7848380003266,
                            35.0856990000857
                        ],
                        [
                            -82.7789469996103,
                            35.083973999807
                        ],
                        [
                            -82.7762700003007,
                            35.0808340002194
                        ],
                        [
                            -82.7775840002028,
                            35.0754309999894
                        ],
                        [
                            -82.7804730001228,
                            35.0724029999886
                        ],
                        [
                            -82.7793940003974,
                            35.0703119998896
                        ],
                        [
                            -82.7820360004452,
                            35.0650189996331
                        ],
                        [
                            -82.7750710000264,
                            35.0646620002163
                        ],
                        [
                            -82.7708549997855,
                            35.067414999983
                        ],
                        [
                            -82.7645520002389,
                            35.0681699997577
                        ],
                        [
                            -82.7566489996616,
                            35.0672870002033
                        ],
                        [
                            -82.7512650000068,
                            35.0734630000607
                        ],
                        [
                            -82.7521830000738,
                            35.0797650000294
                        ],
                        [
                            -82.7510659998152,
                            35.0814810002486
                        ],
                        [
                            -82.7387569999245,
                            35.078200000279
                        ],
                        [
                            -82.7388849999711,
                            35.0810639997496
                        ],
                        [
                            -82.7361390001662,
                            35.0813979996387
                        ],
                        [
                            -82.7343349998193,
                            35.0870479998309
                        ],
                        [
                            -82.7295830000012,
                            35.0876259997613
                        ],
                        [
                            -82.729516999879,
                            35.0905900000234
                        ],
                        [
                            -82.7270100004487,
                            35.0941419998029
                        ],
                        [
                            -82.7152970004097,
                            35.0929429998748
                        ],
                        [
                            -82.700272999778,
                            35.1008510002381
                        ],
                        [
                            -82.6993980002671,
                            35.0959700001216
                        ],
                        [
                            -82.6979450004479,
                            35.0953589998496
                        ],
                        [
                            -82.686484000304,
                            35.1081639999463
                        ],
                        [
                            -82.687281999822,
                            35.1106670002096
                        ],
                        [
                            -82.6932570002039,
                            35.1130479999772
                        ],
                        [
                            -82.6945660000831,
                            35.1153249997561
                        ],
                        [
                            -82.6924450004421,
                            35.1186689998943
                        ],
                        [
                            -82.6867379998489,
                            35.1197900002629
                        ],
                        [
                            -82.6870840003536,
                            35.1251159999772
                        ],
                        [
                            -82.6831369997262,
                            35.1259529999763
                        ],
                        [
                            -82.6814429998826,
                            35.1279180000064
                        ],
                        [
                            -82.6746149997311,
                            35.1264349999212
                        ],
                        [
                            -82.6730820004442,
                            35.1215020001781
                        ],
                        [
                            -82.6744570004456,
                            35.1184740001384
                        ],
                        [
                            -82.673272000415,
                            35.1174799998113
                        ],
                        [
                            -82.6694300002679,
                            35.1206030000752
                        ],
                        [
                            -82.6666269996633,
                            35.118174999655
                        ],
                        [
                            -82.6566610000698,
                            35.1187979999172
                        ],
                        [
                            -82.65196299978,
                            35.1229729997863
                        ],
                        [
                            -82.652191000284,
                            35.1260470002993
                        ],
                        [
                            -82.6452950003604,
                            35.1268070000197
                        ],
                        [
                            -82.6415129995895,
                            35.1323419999561
                        ],
                        [
                            -82.6397180003618,
                            35.1297999998206
                        ],
                        [
                            -82.6364770000893,
                            35.130356999882
                        ],
                        [
                            -82.6362360000648,
                            35.1254949999618
                        ],
                        [
                            -82.628837000176,
                            35.1257849996605
                        ],
                        [
                            -82.6245749999044,
                            35.1305679997215
                        ],
                        [
                            -82.6241480001071,
                            35.1333860003003
                        ],
                        [
                            -82.6149049996885,
                            35.1363910001404
                        ],
                        [
                            -82.6130819999735,
                            35.1397259997506
                        ],
                        [
                            -82.5981400002558,
                            35.1377289997616
                        ],
                        [
                            -82.5924300001878,
                            35.1390020001712
                        ],
                        [
                            -82.5918500002297,
                            35.1412160000473
                        ],
                        [
                            -82.5881579998704,
                            35.142927999847
                        ],
                        [
                            -82.5786860000213,
                            35.1412600001858
                        ],
                        [
                            -82.576909000337,
                            35.1447329999568
                        ],
                        [
                            -82.568270000166,
                            35.1456820000335
                        ],
                        [
                            -82.5683000003032,
                            35.1483030000312
                        ],
                        [
                            -82.5644459995622,
                            35.1522380002212
                        ],
                        [
                            -82.5626100003267,
                            35.153058000285
                        ],
                        [
                            -82.5574450000567,
                            35.1507689997643
                        ],
                        [
                            -82.5538449997589,
                            35.1528749998066
                        ],
                        [
                            -82.5561649995915,
                            35.1578400003048
                        ],
                        [
                            -82.5532699998237,
                            35.161652999796
                        ],
                        [
                            -82.5499070002507,
                            35.1615839999347
                        ],
                        [
                            -82.546198000173,
                            35.1637300001171
                        ],
                        [
                            -82.5367470002403,
                            35.1600910001286
                        ],
                        [
                            -82.5364740004288,
                            35.1547829999441
                        ],
                        [
                            -82.5274600003391,
                            35.1558870000098
                        ],
                        [
                            -82.5199259998328,
                            35.1591049996835
                        ],
                        [
                            -82.5190909996065,
                            35.1620830002305
                        ],
                        [
                            -82.5164269998174,
                            35.1633639997607
                        ],
                        [
                            -82.507914000043,
                            35.1624060002881
                        ],
                        [
                            -82.5045799998841,
                            35.1658510003325
                        ],
                        [
                            -82.4957099997344,
                            35.1620609999185
                        ],
                        [
                            -82.4945640000618,
                            35.1631599999186
                        ],
                        [
                            -82.4955060004182,
                            35.164730999867
                        ],
                        [
                            -82.4907660002957,
                            35.1697150000369
                        ],
                        [
                            -82.4872480000137,
                            35.169727999833
                        ],
                        [
                            -82.486652999987,
                            35.1744630000427
                        ],
                        [
                            -82.482328999791,
                            35.1738800003125
                        ],
                        [
                            -82.4777540004233,
                            35.1760880001311
                        ],
                        [
                            -82.4749490001688,
                            35.1740429999881
                        ],
                        [
                            -82.467991000321,
                            35.1746329999375
                        ],
                        [
                            -82.4600919999417,
                            35.1781429999978
                        ],
                        [
                            -82.4546520002104,
                            35.177560000225
                        ],
                        [
                            -82.4516240003732,
                            35.172494999849
                        ],
                        [
                            -82.4574299998024,
                            35.167385000303
                        ],
                        [
                            -82.4571279996786,
                            35.1639499999241
                        ],
                        [
                            -82.4504650002819,
                            35.1661180000544
                        ],
                        [
                            -82.4395949999667,
                            35.1658629998108
                        ],
                        [
                            -82.4346759997441,
                            35.1682789999368
                        ],
                        [
                            -82.4351069997393,
                            35.170183000199
                        ],
                        [
                            -82.4314810004006,
                            35.1731869996701
                        ],
                        [
                            -82.430765,
                            35.1778330000044
                        ],
                        [
                            -82.4262659999019,
                            35.1790789997251
                        ],
                        [
                            -82.4259620001283,
                            35.18103999976
                        ],
                        [
                            -82.4243150001403,
                            35.1814530003097
                        ],
                        [
                            -82.4279410003773,
                            35.1830880000604
                        ],
                        [
                            -82.4277840000185,
                            35.1844719999541
                        ],
                        [
                            -82.4260979996724,
                            35.1892989997273
                        ],
                        [
                            -82.4232940001412,
                            35.1907779998905
                        ],
                        [
                            -82.4244609997302,
                            35.1930920000723
                        ],
                        [
                            -82.4197440000722,
                            35.1986129996621
                        ],
                        [
                            -82.4172340002688,
                            35.2002770000447
                        ],
                        [
                            -82.4151169999276,
                            35.1992299998522
                        ],
                        [
                            -82.4113009997197,
                            35.2024830003545
                        ],
                        [
                            -82.4071530001492,
                            35.2023900000023
                        ],
                        [
                            -82.4002110001949,
                            35.2050890001799
                        ],
                        [
                            -82.3982589997101,
                            35.2066389998418
                        ],
                        [
                            -82.3994250003725,
                            35.2091789997873
                        ],
                        [
                            -82.3929300003069,
                            35.2154019997593
                        ],
                        [
                            -82.390253000099,
                            35.2155399997923
                        ],
                        [
                            -82.3873719996766,
                            35.2118309999036
                        ],
                        [
                            -82.3840290001951,
                            35.2105419999868
                        ],
                        [
                            -82.3851690000199,
                            35.2062660002454
                        ],
                        [
                            -82.3805240003319,
                            35.2022760003251
                        ],
                        [
                            -82.3783929997469,
                            35.1960830000715
                        ],
                        [
                            -82.3828829996242,
                            35.1943470001593
                        ],
                        [
                            -82.3839439998063,
                            35.1910069996836
                        ],
                        [
                            -82.3720580004133,
                            35.1803389997619
                        ],
                        [
                            -82.3625589999016,
                            35.1860299998812
                        ],
                        [
                            -82.3645680002879,
                            35.1891339999802
                        ],
                        [
                            -82.3593040002837,
                            35.19231800008
                        ],
                        [
                            -82.3515959997001,
                            35.190101000075
                        ],
                        [
                            -82.3500859999795,
                            35.1928580002035
                        ],
                        [
                            -82.3445540001867,
                            35.1931149999289
                        ],
                        [
                            -82.3395079997424,
                            35.18893000019
                        ],
                        [
                            -82.332975000042,
                            35.1906449998363
                        ],
                        [
                            -82.330779000058,
                            35.189031999782
                        ],
                        [
                            -82.3305489999043,
                            35.1867670001807
                        ],
                        [
                            -82.323350000032,
                            35.1847890001029
                        ],
                        [
                            -82.314863000197,
                            35.1910890003212
                        ],
                        [
                            -82.2953539997108,
                            35.1949650002957
                        ],
                        [
                            -82.2884529997644,
                            35.1986040000089
                        ],
                        [
                            -82.2749200003834,
                            35.2000710000455
                        ],
                        [
                            -82.1811209996371,
                            35.194067000346
                        ],
                        [
                            -81.7241220002932,
                            35.1791389998201
                        ],
                        [
                            -81.2642729995906,
                            35.1610620001418
                        ],
                        [
                            -81.1092809997436,
                            35.1541179997473
                        ],
                        [
                            -81.0331960002557,
                            35.1491970000611
                        ],
                        [
                            -81.0443910002608,
                            35.1479180001108
                        ],
                        [
                            -81.0478259998038,
                            35.1437430002749
                        ],
                        [
                            -81.0512040003437,
                            35.1332370003172
                        ],
                        [
                            -81.0504200001711,
                            35.1310479997465
                        ],
                        [
                            -81.038968000248,
                            35.1262989998324
                        ],
                        [
                            -81.0330049995617,
                            35.1137469998437
                        ],
                        [
                            -81.0328050004435,
                            35.1080490002623
                        ],
                        [
                            -81.0373659995675,
                            35.1025419997598
                        ],
                        [
                            -81.0500779998643,
                            35.0988169998614
                        ],
                        [
                            -81.0572359997287,
                            35.08612900033
                        ],
                        [
                            -81.0576480003556,
                            35.0624330002923
                        ],
                        [
                            -81.041488999922,
                            35.0447029997382
                        ],
                        [
                            -80.9578740001258,
                            35.0926359998138
                        ],
                        [
                            -80.9349500001881,
                            35.1074089999194
                        ],
                        [
                            -80.8872729998517,
                            35.0561960001661
                        ],
                        [
                            -80.7820420001721,
                            34.9357849999432
                        ],
                        [
                            -80.797491000053,
                            34.8197519996535
                        ],
                        [
                            -80.4997850002874,
                            34.8172650003266
                        ],
                        [
                            -79.8748550001207,
                            34.8054099997161
                        ],
                        [
                            -79.7628020001374,
                            34.8060580001192
                        ],
                        [
                            -79.6752999999882,
                            34.8047440002625
                        ],
                        [
                            -79.3582909996009,
                            34.5456250002769
                        ],
                        [
                            -79.2356700003246,
                            34.4377029999455
                        ],
                        [
                            -78.7027709996441,
                            33.9892680003252
                        ],
                        [
                            -78.5509449997266,
                            33.8597049999372
                        ],
                        [
                            -78.4993009995696,
                            33.8128519998785
                        ],
                        [
                            -78.5036940002608,
                            33.8120640000234
                        ],
                        [
                            -78.5116310002749,
                            33.8068309999399
                        ],
                        [
                            -78.5229519997784,
                            33.8021899996381
                        ],
                        [
                            -78.5331979997545,
                            33.7994829996919
                        ],
                        [
                            -78.564842000054,
                            33.796621000352
                        ],
                        [
                            -78.6105490003622,
                            33.7848990002015
                        ],
                        [
                            -78.6617380002345,
                            33.7673390000788
                        ],
                        [
                            -78.6958529999793,
                            33.7528600003298
                        ],
                        [
                            -78.7609779996387,
                            33.7153859997611
                        ],
                        [
                            -78.8085539996927,
                            33.6808380002715
                        ],
                        [
                            -78.8747489997551,
                            33.6234620001386
                        ],
                        [
                            -78.9105409996251,
                            33.5893509999566
                        ],
                        [
                            -78.9569960001209,
                            33.5387219999319
                        ],
                        [
                            -78.9762589996614,
                            33.5086789997732
                        ],
                        [
                            -78.9930330002133,
                            33.488992000112
                        ],
                        [
                            -78.9997569997094,
                            33.4859789997685
                        ],
                        [
                            -79.0344959997932,
                            33.4568339998724
                        ],
                        [
                            -79.0497799998176,
                            33.4360030002919
                        ],
                        [
                            -79.0712350000425,
                            33.4009690000194
                        ],
                        [
                            -79.0875810000739,
                            33.3692869999011
                        ],
                        [
                            -79.0911339996176,
                            33.3563639997273
                        ],
                        [
                            -79.0888599998158,
                            33.3425589999117
                        ],
                        [
                            -79.0891800003813,
                            33.3336379999322
                        ],
                        [
                            -79.090941999997,
                            33.3287870003068
                        ],
                        [
                            -79.0902330001674,
                            33.3231439997642
                        ],
                        [
                            -79.0933120000583,
                            33.3049520001021
                        ],
                        [
                            -79.0994039997177,
                            33.2937249998851
                        ],
                        [
                            -79.1089219995976,
                            33.2831609999775
                        ],
                        [
                            -79.1202170000601,
                            33.2469319997757
                        ],
                        [
                            -79.1080129997515,
                            33.2389310002685
                        ],
                        [
                            -79.086582999641,
                            33.2153099996964
                        ],
                        [
                            -79.0816340001794,
                            33.2073290003706
                        ],
                        [
                            -79.0776050004346,
                            33.1886530000559
                        ],
                        [
                            -79.0822290002061,
                            33.1713250000248
                        ],
                        [
                            -79.0916140000166,
                            33.1590560003304
                        ],
                        [
                            -79.1043880002377,
                            33.1485049996862
                        ],
                        [
                            -79.1158660001,
                            33.1432730002695
                        ],
                        [
                            -79.1405190000414,
                            33.1396199997556
                        ],
                        [
                            -79.1462719997671,
                            33.132116999684
                        ],
                        [
                            -79.1544640000494,
                            33.1251679996365
                        ],
                        [
                            -79.1531379995534,
                            33.1251679996365
                        ],
                        [
                            -79.1615879995786,
                            33.1190559997401
                        ],
                        [
                            -79.1701420002591,
                            33.1161340003512
                        ],
                        [
                            -79.1808710001089,
                            33.1075280001981
                        ],
                        [
                            -79.1851579995965,
                            33.0928580003216
                        ],
                        [
                            -79.1922510000619,
                            33.0820159999936
                        ],
                        [
                            -79.2029630001933,
                            33.0717069997577
                        ],
                        [
                            -79.2155380002227,
                            33.064247999753
                        ],
                        [
                            -79.2292320001605,
                            33.0613009998237
                        ],
                        [
                            -79.2419610001757,
                            33.0611799997574
                        ],
                        [
                            -79.2560450001008,
                            33.0515909999899
                        ],
                        [
                            -79.2733729997732,
                            33.0465590000232
                        ],
                        [
                            -79.278980999734,
                            33.0284590001831
                        ],
                        [
                            -79.2934770002861,
                            33.0112769999272
                        ],
                        [
                            -79.2995050003714,
                            33.0001689997078
                        ],
                        [
                            -79.3003830002554,
                            32.9867840000545
                        ],
                        [
                            -79.3054709996342,
                            32.9739780002662
                        ],
                        [
                            -79.3133830004323,
                            32.9632839999282
                        ],
                        [
                            -79.3222569998816,
                            32.9560780000525
                        ],
                        [
                            -79.3375629996473,
                            32.9495079997401
                        ],
                        [
                            -79.3452200001773,
                            32.9478649997019
                        ],
                        [
                            -79.3712969996256,
                            32.9457170001059
                        ],
                        [
                            -79.3926399996974,
                            32.9500319998342
                        ],
                        [
                            -79.4040630002065,
                            32.9494069996684
                        ],
                        [
                            -79.4152480001658,
                            32.9508579999849
                        ],
                        [
                            -79.4401809995914,
                            32.9589159996483
                        ],
                        [
                            -79.44835899963,
                            32.9489400002326
                        ],
                        [
                            -79.4661590002038,
                            32.9368220000423
                        ],
                        [
                            -79.4779359998169,
                            32.9332089997681
                        ],
                        [
                            -79.4959449997298,
                            32.9315720000901
                        ],
                        [
                            -79.498727000418,
                            32.9237670002
                        ],
                        [
                            -79.5044509998314,
                            32.9151660001665
                        ],
                        [
                            -79.5166579996148,
                            32.9030339996408
                        ],
                        [
                            -79.5172469997937,
                            32.8971379998184
                        ],
                        [
                            -79.5226430000424,
                            32.8831370003339
                        ],
                        [
                            -79.5346890001673,
                            32.8700119999055
                        ],
                        [
                            -79.5608579996771,
                            32.856972000257
                        ],
                        [
                            -79.585390000143,
                            32.850856000277
                        ],
                        [
                            -79.5956489996395,
                            32.835307999727
                        ],
                        [
                            -79.6079180002454,
                            32.8257430001428
                        ],
                        [
                            -79.6204149995587,
                            32.8200929999608
                        ],
                        [
                            -79.6378209999473,
                            32.8163770003757
                        ],
                        [
                            -79.6468340002121,
                            32.8031130002783
                        ],
                        [
                            -79.6532010002311,
                            32.7986360001401
                        ],
                        [
                            -79.6622510003059,
                            32.7831190001966
                        ],
                        [
                            -79.6774359996978,
                            32.7728899996265
                        ],
                        [
                            -79.6918929998918,
                            32.759240000021
                        ],
                        [
                            -79.7172610004089,
                            32.7506619998697
                        ],
                        [
                            -79.7281609999629,
                            32.7501759999633
                        ],
                        [
                            -79.7600739998759,
                            32.7382569999246
                        ],
                        [
                            -79.766592000406,
                            32.7115370002619
                        ],
                        [
                            -79.7796269998446,
                            32.6914979999491
                        ],
                        [
                            -79.7982550000737,
                            32.6756320002876
                        ],
                        [
                            -79.8280580002164,
                            32.6647779999739
                        ],
                        [
                            -79.8364379999213,
                            32.6514179996885
                        ],
                        [
                            -79.8550629997774,
                            32.6363889997885
                        ],
                        [
                            -79.9183410003306,
                            32.6072639996885
                        ],
                        [
                            -79.9298280004138,
                            32.5953019998748
                        ],
                        [
                            -79.947233999904,
                            32.5857680002855
                        ],
                        [
                            -79.9582959998397,
                            32.5709880000798
                        ],
                        [
                            -79.9679230003979,
                            32.5637089998179
                        ],
                        [
                            -79.979002000052,
                            32.5580739996317
                        ],
                        [
                            -79.9925989999055,
                            32.5546220002812
                        ],
                        [
                            -80.0137789997707,
                            32.5550130002322
                        ],
                        [
                            -80.0307700000575,
                            32.5599609997982
                        ],
                        [
                            -80.0888769995947,
                            32.549029000275
                        ],
                        [
                            -80.090119000425,
                            32.5356470001349
                        ],
                        [
                            -80.0949979995664,
                            32.5238660001636
                        ],
                        [
                            -80.1041969996041,
                            32.5129150000945
                        ],
                        [
                            -80.1214590000526,
                            32.4991919996644
                        ],
                        [
                            -80.1349689998674,
                            32.4956280003216
                        ],
                        [
                            -80.1669510002757,
                            32.491086000122
                        ],
                        [
                            -80.1785560003596,
                            32.4911719997696
                        ],
                        [
                            -80.1988390000743,
                            32.4962410003412
                        ],
                        [
                            -80.2110190000935,
                            32.4873910002795
                        ],
                        [
                            -80.2266899997322,
                            32.4816249997545
                        ],
                        [
                            -80.2430740002967,
                            32.4724490000985
                        ],
                        [
                            -80.2457630002002,
                            32.4543050002905
                        ],
                        [
                            -80.2498200004324,
                            32.4449030002921
                        ],
                        [
                            -80.2572810002455,
                            32.4351679998429
                        ],
                        [
                            -80.2719839995885,
                            32.4250429999103
                        ],
                        [
                            -80.2940780002211,
                            32.4185210000543
                        ],
                        [
                            -80.2982049998752,
                            32.4063859996779
                        ],
                        [
                            -80.3061629998057,
                            32.3970970001357
                        ],
                        [
                            -80.3218469998632,
                            32.3861959998328
                        ],
                        [
                            -80.3367169996108,
                            32.3807529996442
                        ],
                        [
                            -80.3420500001103,
                            32.3661199997213
                        ],
                        [
                            -80.3506740002127,
                            32.3559140001185
                        ],
                        [
                            -80.3621179997398,
                            32.3476539997843
                        ],
                        [
                            -80.3748250000137,
                            32.3424529997164
                        ],
                        [
                            -80.3713250001734,
                            32.3285810002564
                        ],
                        [
                            -80.3728299998712,
                            32.3134079998053
                        ],
                        [
                            -80.3795419996717,
                            32.2965579996621
                        ],
                        [
                            -80.3923269997634,
                            32.2803169999642
                        ],
                        [
                            -80.4082719999368,
                            32.2696560001391
                        ],
                        [
                            -80.4201029999765,
                            32.2646429997456
                        ],
                        [
                            -80.4432670001137,
                            32.2599770001736
                        ],
                        [
                            -80.4593319999376,
                            32.2622210000697
                        ],
                        [
                            -80.4779509999459,
                            32.2533580000776
                        ],
                        [
                            -80.4968119998035,
                            32.2501990003376
                        ],
                        [
                            -80.4939010001422,
                            32.2501990003376
                        ],
                        [
                            -80.5238239999355,
                            32.2345919998689
                        ],
                        [
                            -80.527938999894,
                            32.2260050000698
                        ],
                        [
                            -80.5445479996911,
                            32.211221000319
                        ],
                        [
                            -80.5489329999864,
                            32.1920530000154
                        ],
                        [
                            -80.5664489999798,
                            32.1723690001508
                        ],
                        [
                            -80.5849850001474,
                            32.1625890003656
                        ],
                        [
                            -80.5981269997162,
                            32.1612679999157
                        ],
                        [
                            -80.6084510004084,
                            32.1622939997108
                        ],
                        [
                            -80.6168529998546,
                            32.1531930002961
                        ],
                        [
                            -80.6314699998822,
                            32.1458639999455
                        ],
                        [
                            -80.6492210000522,
                            32.1424230002169
                        ],
                        [
                            -80.6647200001618,
                            32.142606999968
                        ],
                        [
                            -80.6690109998474,
                            32.1358539997393
                        ],
                        [
                            -80.6798710001168,
                            32.1252079996333
                        ],
                        [
                            -80.705222999842,
                            32.1051869998603
                        ],
                        [
                            -80.7155030001532,
                            32.0995459997431
                        ],
                        [
                            -80.7319429998959,
                            32.0938939997986
                        ],
                        [
                            -80.7482019998887,
                            32.0844900002658
                        ],
                        [
                            -80.7432940004349,
                            32.0720200002871
                        ],
                        [
                            -80.7416680003633,
                            32.0567760003627
                        ],
                        [
                            -80.7453640000222,
                            32.0436349998279
                        ],
                        [
                            -80.7514289999175,
                            32.0334679997907
                        ],
                        [
                            -80.8929600003535,
                            32.0349509999051
                        ],
                        [
                            -80.9228029997809,
                            32.0391420002744
                        ],
                        [
                            -80.9335719998137,
                            32.0475580000039
                        ],
                        [
                            -80.9544830000654,
                            32.0686160000137
                        ],
                        [
                            -80.9831920004141,
                            32.0796590003254
                        ],
                        [
                            -80.9944700002599,
                            32.0947239996433
                        ],
                        [
                            -81.0023179996872,
                            32.1000559999576
                        ],
                        [
                            -81.0067599998839,
                            32.1011569997307
                        ],
                        [
                            -81.0119700003597,
                            32.1001779997659
                        ],
                        [
                            -81.0216840000582,
                            32.0908639999452
                        ],
                        [
                            -81.032633000016,
                            32.0854760000152
                        ],
                        [
                            -81.0425500001041,
                            32.0845040001546
                        ],
                        [
                            -81.0532279999003,
                            32.085755999689
                        ],
                        [
                            -81.0657710001426,
                            32.0897600000367
                        ],
                        [
                            -81.0754430000083,
                            32.0970679997562
                        ],
                        [
                            -81.0873689995843,
                            32.1032849999716
                        ],
                        [
                            -81.0905239996431,
                            32.1063779999071
                        ],
                        [
                            -81.0915749997794,
                            32.1109090000262
                        ],
                        [
                            -81.1111310001212,
                            32.1120109996242
                        ],
                        [
                            -81.1172250003288,
                            32.1176040000866
                        ],
                        [
                            -81.1152469999047,
                            32.1255039997465
                        ],
                        [
                            -81.1199379996234,
                            32.133975000363
                        ],
                        [
                            -81.1191309998845,
                            32.1421370002553
                        ],
                        [
                            -81.1173349999336,
                            32.1462030003487
                        ],
                        [
                            -81.1122340001361,
                            32.1493060000229
                        ],
                        [
                            -81.1122359997859,
                            32.1508040001981
                        ],
                        [
                            -81.1200339998829,
                            32.1533030000362
                        ],
                        [
                            -81.1221369999804,
                            32.1573039999544
                        ],
                        [
                            -81.1220299998503,
                            32.1617959999546
                        ],
                        [
                            -81.1297559999773,
                            32.1658389998925
                        ],
                        [
                            -81.1281419996012,
                            32.169097000063
                        ],
                        [
                            -81.1211489995933,
                            32.1749020003349
                        ],
                        [
                            -81.1194370002063,
                            32.1753619997162
                        ],
                        [
                            -81.1169459997712,
                            32.1709140002415
                        ],
                        [
                            -81.1111319999461,
                            32.1727029998229
                        ],
                        [
                            -81.1102339999707,
                            32.176800000315
                        ],
                        [
                            -81.1119329998372,
                            32.1780020003571
                        ],
                        [
                            -81.1169330002507,
                            32.1761979998104
                        ],
                        [
                            -81.1204389999388,
                            32.1786999999325
                        ],
                        [
                            -81.1179349999832,
                            32.1852970000016
                        ],
                        [
                            -81.118237000107,
                            32.1892029996458
                        ],
                        [
                            -81.1211579998141,
                            32.1900799996512
                        ],
                        [
                            -81.1217949996737,
                            32.1917939998398
                        ],
                        [
                            -81.1148209999323,
                            32.1934229998783
                        ],
                        [
                            -81.1140319997368,
                            32.1957010000727
                        ],
                        [
                            -81.1218850000854,
                            32.1955379997168
                        ],
                        [
                            -81.1231489997587,
                            32.2013200000096
                        ],
                        [
                            -81.1274200002512,
                            32.2075540001776
                        ],
                        [
                            -81.1360119996682,
                            32.212853999975
                        ],
                        [
                            -81.1389249998777,
                            32.2197679996758
                        ],
                        [
                            -81.1431749995555,
                            32.2217389996773
                        ],
                        [
                            -81.1520639999717,
                            32.2220420001261
                        ],
                        [
                            -81.1463489998808,
                            32.224555000049
                        ],
                        [
                            -81.1436340000381,
                            32.2292659996513
                        ],
                        [
                            -81.1457709995725,
                            32.2323430003147
                        ],
                        [
                            -81.1528830003045,
                            32.2367869996395
                        ],
                        [
                            -81.1566069995524,
                            32.2432839999422
                        ],
                        [
                            -81.1544649999951,
                            32.2469210003612
                        ],
                        [
                            -81.1459050003651,
                            32.2507240001271
                        ],
                        [
                            -81.1481239999154,
                            32.2550910000908
                        ],
                        [
                            -81.1447389997028,
                            32.2580599999731
                        ],
                        [
                            -81.1463170000938,
                            32.2633230001037
                        ],
                        [
                            -81.1393669997436,
                            32.2636999996224
                        ],
                        [
                            -81.1369299997352,
                            32.2661149996582
                        ],
                        [
                            -81.137924999795,
                            32.2688910002838
                        ],
                        [
                            -81.1421080004239,
                            32.2715760002175
                        ],
                        [
                            -81.1360329995846,
                            32.2726629996604
                        ],
                        [
                            -81.1331789998247,
                            32.2696780001984
                        ],
                        [
                            -81.1294789999678,
                            32.2762179998717
                        ],
                        [
                            -81.1235050003092,
                            32.2751430001428
                        ],
                        [
                            -81.1232759999803,
                            32.2769750003691
                        ],
                        [
                            -81.1264629998262,
                            32.2792489996219
                        ],
                        [
                            -81.1271409996936,
                            32.2822549999507
                        ],
                        [
                            -81.119628000002,
                            32.2867780000315
                        ],
                        [
                            -81.1204559996573,
                            32.2908750003026
                        ],
                        [
                            -81.1239170000378,
                            32.2951939998012
                        ],
                        [
                            -81.1226309997249,
                            32.3069899998573
                        ],
                        [
                            -81.1252340003129,
                            32.309075000168
                        ],
                        [
                            -81.128563000449,
                            32.306872999645
                        ],
                        [
                            -81.1330159996181,
                            32.3085459997113
                        ],
                        [
                            -81.1297769998937,
                            32.3132899996631
                        ],
                        [
                            -81.1316270002712,
                            32.3186440002987
                        ],
                        [
                            -81.1307350001436,
                            32.3257560003563
                        ],
                        [
                            -81.1355460004112,
                            32.3243229999105
                        ],
                        [
                            -81.1380129996585,
                            32.3282400001449
                        ],
                        [
                            -81.132803999906,
                            32.3347460003389
                        ],
                        [
                            -81.1289559999111,
                            32.3364520002278
                        ],
                        [
                            -81.1285349999616,
                            32.3383809996944
                        ],
                        [
                            -81.1296150004103,
                            32.3417039998843
                        ],
                        [
                            -81.1334819997734,
                            32.340977999697
                        ],
                        [
                            -81.1392959995985,
                            32.3450249996846
                        ],
                        [
                            -81.1413660000841,
                            32.3501470001017
                        ],
                        [
                            -81.1438569996208,
                            32.3515430000618
                        ],
                        [
                            -81.1479389999674,
                            32.348912999672
                        ],
                        [
                            -81.1453270000568,
                            32.3451600003129
                        ],
                        [
                            -81.1495469995974,
                            32.3450790001119
                        ],
                        [
                            -81.152819999657,
                            32.3413210002688
                        ],
                        [
                            -81.1599950001381,
                            32.3404760000919
                        ],
                        [
                            -81.1599840002674,
                            32.3428520000892
                        ],
                        [
                            -81.1552969998484,
                            32.3439020000924
                        ],
                        [
                            -81.1549629999375,
                            32.3502469998997
                        ],
                        [
                            -81.160801000052,
                            32.3499610002288
                        ],
                        [
                            -81.1609790003273,
                            32.3552440001325
                        ],
                        [
                            -81.167569999754,
                            32.3587469998051
                        ],
                        [
                            -81.1667570001673,
                            32.3616030001181
                        ],
                        [
                            -81.1739629997123,
                            32.3629169998089
                        ],
                        [
                            -81.168995999809,
                            32.3651189999848
                        ],
                        [
                            -81.1679139997105,
                            32.3684819996713
                        ],
                        [
                            -81.1767269999588,
                            32.3767189997468
                        ],
                        [
                            -81.1818450003731,
                            32.3793480002144
                        ],
                        [
                            -81.177783999943,
                            32.3862310003466
                        ],
                        [
                            -81.1782829997102,
                            32.3929860003586
                        ],
                        [
                            -81.2054050003454,
                            32.4233390002681
                        ],
                        [
                            -81.2051140000923,
                            32.4272249996893
                        ],
                        [
                            -81.2083889998017,
                            32.4372499998485
                        ],
                        [
                            -81.2005849998569,
                            32.4406889999511
                        ],
                        [
                            -81.1992919998712,
                            32.4429140003115
                        ],
                        [
                            -81.2007549997362,
                            32.4460860001152
                        ],
                        [
                            -81.2063180003895,
                            32.4470070000225
                        ],
                        [
                            -81.2057740004163,
                            32.4504740001074
                        ],
                        [
                            -81.2032860003543,
                            32.4529929997544
                        ],
                        [
                            -81.1978680003643,
                            32.4485549999741
                        ],
                        [
                            -81.1976690001727,
                            32.4546080000501
                        ],
                        [
                            -81.1920129996331,
                            32.453786000316
                        ],
                        [
                            -81.1944120000066,
                            32.4589089998118
                        ],
                        [
                            -81.1886020003795,
                            32.4612260000043
                        ],
                        [
                            -81.1871410001644,
                            32.4641489997358
                        ],
                        [
                            -81.1947700002069,
                            32.4651619999239
                        ],
                        [
                            -81.2004080003049,
                            32.4683139999976
                        ],
                        [
                            -81.2331110002383,
                            32.4980690000509
                        ],
                        [
                            -81.2342540004362,
                            32.5027469998274
                        ],
                        [
                            -81.2377950002844,
                            32.5052559998195
                        ],
                        [
                            -81.2388210003063,
                            32.5080519998938
                        ],
                        [
                            -81.2371590002497,
                            32.5112959998605
                        ],
                        [
                            -81.2323220000428,
                            32.5120820001323
                        ],
                        [
                            -81.2337940001286,
                            32.5183099998132
                        ],
                        [
                            -81.2408649999544,
                            32.5164810003762
                        ],
                        [
                            -81.2439370001725,
                            32.5209039996621
                        ],
                        [
                            -81.2474549995562,
                            32.5182809997962
                        ],
                        [
                            -81.2520630003325,
                            32.5181360003279
                        ],
                        [
                            -81.2719230004479,
                            32.529984000339
                        ],
                        [
                            -81.2746459997883,
                            32.5341879999847
                        ],
                        [
                            -81.2796550004227,
                            32.5363379999006
                        ],
                        [
                            -81.2799220003863,
                            32.5376359996473
                        ],
                        [
                            -81.2752390001652,
                            32.5398130001969
                        ],
                        [
                            -81.274800999599,
                            32.5444159998075
                        ],
                        [
                            -81.2808739998901,
                            32.5478999998822
                        ],
                        [
                            -81.2787359996325,
                            32.5524460001497
                        ],
                        [
                            -81.2805800001623,
                            32.55603000009
                        ],
                        [
                            -81.2838580002447,
                            32.5565380001393
                        ],
                        [
                            -81.2851479998573,
                            32.5523450001266
                        ],
                        [
                            -81.2886269997813,
                            32.5511130001351
                        ],
                        [
                            -81.2899619995997,
                            32.5521799999118
                        ],
                        [
                            -81.2884669999477,
                            32.5584560003367
                        ],
                        [
                            -81.2909609998575,
                            32.5626629998506
                        ],
                        [
                            -81.2934559995923,
                            32.5615840003157
                        ],
                        [
                            -81.2913120003851,
                            32.5583649998029
                        ],
                        [
                            -81.2914799997163,
                            32.5557139999561
                        ],
                        [
                            -81.2961649995872,
                            32.5533449996879
                        ],
                        [
                            -81.2980719998662,
                            32.5553429997852
                        ],
                        [
                            -81.2953019997719,
                            32.5608059998027
                        ],
                        [
                            -81.2976519997417,
                            32.562931000318
                        ],
                        [
                            -81.3196020004342,
                            32.5595620003786
                        ],
                        [
                            -81.3286049997549,
                            32.5610920002033
                        ],
                        [
                            -81.3559259996834,
                            32.5716060001924
                        ],
                        [
                            -81.3583140001863,
                            32.5744510003706
                        ],
                        [
                            -81.353492000048,
                            32.5787700001126
                        ],
                        [
                            -81.3580310003291,
                            32.5822109999926
                        ],
                        [
                            -81.3601679998635,
                            32.5817780000796
                        ],
                        [
                            -81.3634939996266,
                            32.5769079998081
                        ],
                        [
                            -81.3667819997548,
                            32.5769149996284
                        ],
                        [
                            -81.3672479999101,
                            32.5783609999906
                        ],
                        [
                            -81.3635859996881,
                            32.5829769999051
                        ],
                        [
                            -81.3698189998128,
                            32.5850479999547
                        ],
                        [
                            -81.3692699998168,
                            32.5907049999979
                        ],
                        [
                            -81.3731449995759,
                            32.5920940002368
                        ],
                        [
                            -81.3760059999068,
                            32.5892560001633
                        ],
                        [
                            -81.3788830001313,
                            32.5889119996899
                        ],
                        [
                            -81.3892050002753,
                            32.5954159999788
                        ],
                        [
                            -81.3937649995744,
                            32.6023309999096
                        ],
                        [
                            -81.4064640003507,
                            32.6130419997642
                        ],
                        [
                            -81.4075170001369,
                            32.6165829996735
                        ],
                        [
                            -81.4119059997318,
                            32.6184089998332
                        ],
                        [
                            -81.4130869995645,
                            32.6248030001088
                        ],
                        [
                            -81.4186600002635,
                            32.6293920003122
                        ],
                        [
                            -81.4184309999346,
                            32.6347039996377
                        ],
                        [
                            -81.414761000215,
                            32.6374389996699
                        ],
                        [
                            -81.4128840000732,
                            32.6366799998985
                        ],
                        [
                            -81.4102599995687,
                            32.6313919997925
                        ],
                        [
                            -81.4072710000896,
                            32.6317369999012
                        ],
                        [
                            -81.4027350001816,
                            32.6370309996454
                        ],
                        [
                            -81.4118270000891,
                            32.6393829997294
                        ],
                        [
                            -81.4125529996371,
                            32.6415229997317
                        ],
                        [
                            -81.4035820001034,
                            32.6433970003622
                        ],
                        [
                            -81.3930330001787,
                            32.6515419999318
                        ],
                        [
                            -81.3938180001762,
                            32.6534909999192
                        ],
                        [
                            -81.3994319999848,
                            32.6568129997625
                        ],
                        [
                            -81.405272999574,
                            32.6565170001479
                        ],
                        [
                            -81.4071930002718,
                            32.6605190002726
                        ],
                        [
                            -81.4053689998335,
                            32.662919000076
                        ],
                        [
                            -81.4032540000403,
                            32.6594640000508
                        ],
                        [
                            -81.4003419996558,
                            32.6584879996538
                        ],
                        [
                            -81.397153999985,
                            32.6613779996431
                        ],
                        [
                            -81.404286999735,
                            32.6677969997263
                        ],
                        [
                            -81.4012110002172,
                            32.6798820002499
                        ],
                        [
                            -81.4070099999737,
                            32.685550000014
                        ],
                        [
                            -81.4056840003761,
                            32.6898879999371
                        ],
                        [
                            -81.4090119997889,
                            32.6965510000977
                        ],
                        [
                            -81.410874999687,
                            32.6968599997708
                        ],
                        [
                            -81.4105449999741,
                            32.6925120002392
                        ],
                        [
                            -81.4124029998493,
                            32.6920950002862
                        ],
                        [
                            -81.4135770000093,
                            32.6954719996486
                        ],
                        [
                            -81.4178099999686,
                            32.6955690001903
                        ],
                        [
                            -81.4214189995888,
                            32.7004910003031
                        ],
                        [
                            -81.4275169999944,
                            32.7018950001655
                        ],
                        [
                            -81.4240790000783,
                            32.7062939997541
                        ],
                        [
                            -81.4259269999078,
                            32.7100370003537
                        ],
                        [
                            -81.4224379999381,
                            32.7096069998456
                        ],
                        [
                            -81.4211940003563,
                            32.7119780002853
                        ],
                        [
                            -81.4151200002402,
                            32.7136689998091
                        ],
                        [
                            -81.4203380002136,
                            32.7210509999422
                        ],
                        [
                            -81.4099879995822,
                            32.7218630000367
                        ],
                        [
                            -81.4105769997611,
                            32.7233169999461
                        ],
                        [
                            -81.4177689999607,
                            32.7250740000249
                        ],
                        [
                            -81.4167520001596,
                            32.7287620003
                        ],
                        [
                            -81.4185420002627,
                            32.7325849997122
                        ],
                        [
                            -81.4120990000757,
                            32.7326220001283
                        ],
                        [
                            -81.4136780002916,
                            32.7379919998398
                        ],
                        [
                            -81.4070429995856,
                            32.7406619998938
                        ],
                        [
                            -81.4049319999905,
                            32.7455590000717
                        ],
                        [
                            -81.4058659999509,
                            32.7491439997168
                        ],
                        [
                            -81.4178710000679,
                            32.7478259999781
                        ],
                        [
                            -81.419697999981,
                            32.7491670001753
                        ],
                        [
                            -81.416732999893,
                            32.7532760003026
                        ],
                        [
                            -81.411567999623,
                            32.7531169996485
                        ],
                        [
                            -81.4081590000193,
                            32.7558029998006
                        ],
                        [
                            -81.4140380001417,
                            32.755423000361
                        ],
                        [
                            -81.4120930002279,
                            32.763865999908
                        ],
                        [
                            -81.4146280001456,
                            32.7641840003182
                        ],
                        [
                            -81.4177659995877,
                            32.7602219998922
                        ],
                        [
                            -81.4189010002879,
                            32.7611320000993
                        ],
                        [
                            -81.4168340001753,
                            32.7653180001588
                        ],
                        [
                            -81.4189430001207,
                            32.7676289996866
                        ],
                        [
                            -81.4319399999245,
                            32.7689109998449
                        ],
                        [
                            -81.4315130001272,
                            32.771483999737
                        ],
                        [
                            -81.4298540004437,
                            32.7721289998963
                        ],
                        [
                            -81.4173530000341,
                            32.7733359999547
                        ],
                        [
                            -81.4144559997181,
                            32.7781489997835
                        ],
                        [
                            -81.4161680000035,
                            32.7797919996909
                        ],
                        [
                            -81.4248309995656,
                            32.7787029999992
                        ],
                        [
                            -81.4300779998513,
                            32.7817709996378
                        ],
                        [
                            -81.4312350002929,
                            32.7841410001432
                        ],
                        [
                            -81.429976999569,
                            32.7852350000396
                        ],
                        [
                            -81.4256359996546,
                            32.7826040002153
                        ],
                        [
                            -81.4222399995714,
                            32.7839859999334
                        ],
                        [
                            -81.4220680000423,
                            32.785563000198
                        ],
                        [
                            -81.4276669997822,
                            32.7876130002185
                        ],
                        [
                            -81.4200109999754,
                            32.79288500006
                        ],
                        [
                            -81.419878999731,
                            32.7948570000874
                        ],
                        [
                            -81.4252339999717,
                            32.7941899997306
                        ],
                        [
                            -81.4282919999462,
                            32.8008320000272
                        ],
                        [
                            -81.4224439997859,
                            32.8024770002129
                        ],
                        [
                            -81.4264449999416,
                            32.8107099998127
                        ],
                        [
                            -81.4213430003192,
                            32.8121079996621
                        ],
                        [
                            -81.4179840000459,
                            32.8181959998152
                        ],
                        [
                            -81.4205850000858,
                            32.8186509997793
                        ],
                        [
                            -81.4243540004379,
                            32.816178999695
                        ],
                        [
                            -81.4282350000448,
                            32.8185839999939
                        ],
                        [
                            -81.4230410003608,
                            32.8197469998999
                        ],
                        [
                            -81.4219519996913,
                            32.8214659997481
                        ],
                        [
                            -81.4299430001321,
                            32.8282460001454
                        ],
                        [
                            -81.4236740000224,
                            32.828306999863
                        ],
                        [
                            -81.4206200002459,
                            32.8312230001757
                        ],
                        [
                            -81.4216139995825,
                            32.8351779999851
                        ],
                        [
                            -81.4264750000789,
                            32.8407729998518
                        ],
                        [
                            -81.4335950003084,
                            32.8436739997131
                        ],
                        [
                            -81.438651999725,
                            32.8400760000204
                        ],
                        [
                            -81.4428820002096,
                            32.845847000074
                        ],
                        [
                            -81.4426710003224,
                            32.8501070000063
                        ],
                        [
                            -81.4448659995832,
                            32.8509659996835
                        ],
                        [
                            -81.4511990001653,
                            32.8479240002102
                        ],
                        [
                            -81.4527440000461,
                            32.8441499997642
                        ],
                        [
                            -81.4607220000681,
                            32.8460379996521
                        ],
                        [
                            -81.4602109997069,
                            32.8474020001113
                        ],
                        [
                            -81.4555590003463,
                            32.8465080000009
                        ],
                        [
                            -81.456916999731,
                            32.8526039996845
                        ],
                        [
                            -81.4530169998576,
                            32.8596359999565
                        ],
                        [
                            -81.4533609998142,
                            32.8638469998135
                        ],
                        [
                            -81.4513670003948,
                            32.8671649999653
                        ],
                        [
                            -81.4528829999633,
                            32.8729639998681
                        ],
                        [
                            -81.4605560003868,
                            32.8751610002813
                        ],
                        [
                            -81.4650510002869,
                            32.8808959999847
                        ],
                        [
                            -81.4671379995926,
                            32.8806019999314
                        ],
                        [
                            -81.4680420003142,
                            32.876675000125
                        ],
                        [
                            -81.4703760003904,
                            32.8762670000055
                        ],
                        [
                            -81.475918000229,
                            32.8776400000424
                        ],
                        [
                            -81.4794449998334,
                            32.8810820000997
                        ],
                        [
                            -81.4763960000798,
                            32.8879620002219
                        ],
                        [
                            -81.471702999813,
                            32.8904389997783
                        ],
                        [
                            -81.4661900002868,
                            32.8891280000302
                        ],
                        [
                            -81.468232000285,
                            32.8932510001986
                        ],
                        [
                            -81.4646549995535,
                            32.8959989999505
                        ],
                        [
                            -81.4644329997956,
                            32.8986029996512
                        ],
                        [
                            -81.4677000000074,
                            32.9008999999345
                        ],
                        [
                            -81.4707610003549,
                            32.9009020001676
                        ],
                        [
                            -81.4717890000267,
                            32.8938069996283
                        ],
                        [
                            -81.4808120003373,
                            32.8986139997091
                        ],
                        [
                            -81.4791800004179,
                            32.9042029996632
                        ],
                        [
                            -81.481444000174,
                            32.9088949996996
                        ],
                        [
                            -81.4800080000731,
                            32.9134439998975
                        ],
                        [
                            -81.4870489997617,
                            32.9147720002735
                        ],
                        [
                            -81.487626000245,
                            32.9164070002115
                        ],
                        [
                            -81.4831980002921,
                            32.9218019998572
                        ],
                        [
                            -81.495092000081,
                            32.9315959998451
                        ],
                        [
                            -81.4998289998305,
                            32.932614000103
                        ],
                        [
                            -81.5024270003957,
                            32.9353529997095
                        ],
                        [
                            -81.5027160001007,
                            32.9386879998894
                        ],
                        [
                            -81.4994460004142,
                            32.9449880001389
                        ],
                        [
                            -81.5086089995685,
                            32.9525510001989
                        ],
                        [
                            -81.5113469998758,
                            32.9667210002434
                        ],
                        [
                            -81.5088389997223,
                            32.9693680000638
                        ],
                        [
                            -81.5061420003212,
                            32.9641289997748
                        ],
                        [
                            -81.5024559998097,
                            32.9627979997273
                        ],
                        [
                            -81.4998299996554,
                            32.9638160002656
                        ],
                        [
                            -81.497837000061,
                            32.973010999828
                        ],
                        [
                            -81.4947360004289,
                            32.9789969997289
                        ],
                        [
                            -81.4956729998641,
                            32.9827259999999
                        ],
                        [
                            -81.4941649997932,
                            32.985704999917
                        ],
                        [
                            -81.4963270003403,
                            32.9902120003254
                        ],
                        [
                            -81.4922519996665,
                            32.993509999835
                        ],
                        [
                            -81.4911970002305,
                            32.9978239996235
                        ],
                        [
                            -81.4998299996554,
                            33.0006549999135
                        ],
                        [
                            -81.5006299997216,
                            33.0024540001514
                        ],
                        [
                            -81.4987529995798,
                            33.0050649997818
                        ],
                        [
                            -81.4918990003874,
                            33.006693999665
                        ],
                        [
                            -81.4919539997406,
                            33.0090930002662
                        ],
                        [
                            -81.4986799997849,
                            33.0113360001753
                        ],
                        [
                            -81.504052999569,
                            33.0162530002592
                        ],
                        [
                            -81.5066679998526,
                            33.0150820000836
                        ],
                        [
                            -81.5082179997563,
                            33.0105269996283
                        ],
                        [
                            -81.5106899999248,
                            33.0103150002216
                        ],
                        [
                            -81.5115619999609,
                            33.0123659998573
                        ],
                        [
                            -81.5082200003044,
                            33.0175099997787
                        ],
                        [
                            -81.5102820003941,
                            33.021233000277
                        ],
                        [
                            -81.5160729997546,
                            33.020833000078
                        ],
                        [
                            -81.5190749996526,
                            33.0231959998376
                        ],
                        [
                            -81.5116900000075,
                            33.0245059996612
                        ],
                        [
                            -81.5112449997685,
                            33.0277860000876
                        ],
                        [
                            -81.5227419998974,
                            33.0308360002385
                        ],
                        [
                            -81.5284510001405,
                            33.0356460002921
                        ],
                        [
                            -81.5291239999851,
                            33.0408100002752
                        ],
                        [
                            -81.5354979996768,
                            33.0384889996327
                        ],
                        [
                            -81.5387890001781,
                            33.0391849999032
                        ],
                        [
                            -81.5422420001627,
                            33.0473639998268
                        ],
                        [
                            -81.5454399998792,
                            33.048992999692
                        ],
                        [
                            -81.5517859999819,
                            33.043390000063
                        ],
                        [
                            -81.5619480002923,
                            33.0458440003695
                        ],
                        [
                            -81.5661079995584,
                            33.0487920003759
                        ],
                        [
                            -81.560343999962,
                            33.0500359997262
                        ],
                        [
                            -81.5591730001751,
                            33.0517649997265
                        ],
                        [
                            -81.5593990001309,
                            33.0549040000973
                        ],
                        [
                            -81.5633719997992,
                            33.0601560003265
                        ],
                        [
                            -81.5669899996404,
                            33.060401000301
                        ],
                        [
                            -81.5627690002749,
                            33.056123000032
                        ],
                        [
                            -81.566310999948,
                            33.0534300002911
                        ],
                        [
                            -81.5728799996334,
                            33.0541799999395
                        ],
                        [
                            -81.5717980004332,
                            33.058078999632
                        ],
                        [
                            -81.5781219998963,
                            33.0577740001343
                        ],
                        [
                            -81.5783319999586,
                            33.0589359999352
                        ],
                        [
                            -81.5745049998801,
                            33.0600489999486
                        ],
                        [
                            -81.5749109997609,
                            33.0617990001622
                        ],
                        [
                            -81.5809940000979,
                            33.0626970001126
                        ],
                        [
                            -81.5771600003467,
                            33.0643350003314
                        ],
                        [
                            -81.5771870001109,
                            33.065939000258
                        ],
                        [
                            -81.5831030000432,
                            33.0649599997257
                        ],
                        [
                            -81.5829749999967,
                            33.0685749996717
                        ],
                        [
                            -81.5843609998688,
                            33.0699020002753
                        ],
                        [
                            -81.5873100000633,
                            33.0698510000319
                        ],
                        [
                            -81.592517000166,
                            33.0730619996912
                        ],
                        [
                            -81.5935859998457,
                            33.0694959999394
                        ],
                        [
                            -81.5960250004023,
                            33.0726929997553
                        ],
                        [
                            -81.6022119995979,
                            33.0734729999828
                        ],
                        [
                            -81.6032979998944,
                            33.0750899996752
                        ],
                        [
                            -81.5989910003152,
                            33.0766819999215
                        ],
                        [
                            -81.596500999705,
                            33.0798930001718
                        ],
                        [
                            -81.6013200003686,
                            33.0870490001429
                        ],
                        [
                            -81.6068360002678,
                            33.0817160000397
                        ],
                        [
                            -81.6120719997846,
                            33.0800190003294
                        ],
                        [
                            -81.6129429999959,
                            33.0839810003568
                        ],
                        [
                            -81.608285999714,
                            33.0890219998906
                        ],
                        [
                            -81.6116569996829,
                            33.0942589997916
                        ],
                        [
                            -81.6172560003211,
                            33.0956979999089
                        ],
                        [
                            -81.6423329996867,
                            33.0931520002146
                        ],
                        [
                            -81.6584329996708,
                            33.1031520003521
                        ],
                        [
                            -81.679432999611,
                            33.1117510003441
                        ],
                        [
                            -81.6969340004341,
                            33.1165509997958
                        ],
                        [
                            -81.7046339997234,
                            33.1164499999009
                        ],
                        [
                            -81.7116340003024,
                            33.1219510002319
                        ],
                        [
                            -81.7217339997902,
                            33.1264509997383
                        ],
                        [
                            -81.7295340004353,
                            33.1337499996893
                        ],
                        [
                            -81.7438350000955,
                            33.1414500001122
                        ],
                        [
                            -81.7463349998531,
                            33.1460499999314
                        ],
                        [
                            -81.7537349995668,
                            33.1500499999143
                        ],
                        [
                            -81.7631350003442,
                            33.1594490002819
                        ],
                        [
                            -81.764234999986,
                            33.1652490001248
                        ],
                        [
                            -81.7682350003168,
                            33.1679489996425
                        ],
                        [
                            -81.7670350002176,
                            33.1721479998134
                        ],
                        [
                            -81.7724349997659,
                            33.1804490003196
                        ],
                        [
                            -81.7717350001572,
                            33.1815479997683
                        ],
                        [
                            -81.7686350003499,
                            33.1784490002911
                        ],
                        [
                            -81.7654350000853,
                            33.1783480002025
                        ],
                        [
                            -81.7649349995947,
                            33.179949000269
                        ],
                        [
                            -81.7676350002672,
                            33.1835479997331
                        ],
                        [
                            -81.7670350002176,
                            33.1865480001575
                        ],
                        [
                            -81.7606349996883,
                            33.1892479998323
                        ],
                        [
                            -81.7595350000465,
                            33.1947470003395
                        ],
                        [
                            -81.7568350002723,
                            33.197947000001
                        ],
                        [
                            -81.7635350003773,
                            33.2036480000503
                        ],
                        [
                            -81.7689349999256,
                            33.2174469996741
                        ],
                        [
                            -81.7740349998982,
                            33.2211469998985
                        ],
                        [
                            -81.7801349999536,
                            33.2211469998985
                        ],
                        [
                            -81.7810349995788,
                            33.219847000365
                        ],
                        [
                            -81.777334999722,
                            33.2149469996285
                        ],
                        [
                            -81.7775349997385,
                            33.211346999659
                        ],
                        [
                            -81.7845350003174,
                            33.2081460000757
                        ],
                        [
                            -81.7902360001646,
                            33.2084469998644
                        ],
                        [
                            -81.798235999928,
                            33.2116470001143
                        ],
                        [
                            -81.8052380001567,
                            33.211440999812
                        ],
                        [
                            -81.807936000281,
                            33.2137459997335
                        ],
                        [
                            -81.8096359999725,
                            33.2226469997511
                        ],
                        [
                            -81.8279360001385,
                            33.2287460000822
                        ],
                        [
                            -81.8350360002766,
                            33.2361450002085
                        ],
                        [
                            -81.8465359998802,
                            33.2417459996453
                        ],
                        [
                            -81.8520760000689,
                            33.2472879997288
                        ],
                        [
                            -81.853137000251,
                            33.2507450001305
                        ],
                        [
                            -81.8511370000856,
                            33.2525450000476
                        ],
                        [
                            -81.8451359997644,
                            33.2525450000476
                        ],
                        [
                            -81.8396359997587,
                            33.2552450002642
                        ],
                        [
                            -81.8410359998745,
                            33.2570449998798
                        ],
                        [
                            -81.8461359998471,
                            33.2581449997094
                        ],
                        [
                            -81.8481360000126,
                            33.2639449997476
                        ],
                        [
                            -81.8473359999464,
                            33.2663450003511
                        ],
                        [
                            -81.8438620000453,
                            33.2666209998427
                        ],
                        [
                            -81.8345359997861,
                            33.2583450000342
                        ],
                        [
                            -81.8283360001716,
                            33.2630450001707
                        ],
                        [
                            -81.8301360003205,
                            33.2645450000722
                        ],
                        [
                            -81.8334360001442,
                            33.2622450001062
                        ],
                        [
                            -81.8358360003427,
                            33.2628450000903
                        ],
                        [
                            -81.8357380004335,
                            33.2710449996366
                        ],
                        [
                            -81.8326360000781,
                            33.2758449997785
                        ],
                        [
                            -81.8388359996925,
                            33.2737450003605
                        ],
                        [
                            -81.8440360001226,
                            33.2786440002007
                        ],
                        [
                            -81.8456360002549,
                            33.2842439996936
                        ],
                        [
                            -81.8576360003491,
                            33.2846439997482
                        ],
                        [
                            -81.8632359999139,
                            33.2888439996449
                        ],
                        [
                            -81.8620359998147,
                            33.2969440001455
                        ],
                        [
                            -81.8600359996493,
                            33.2991440003199
                        ],
                        [
                            -81.8529360004095,
                            33.2996440001296
                        ],
                        [
                            -81.8503360001945,
                            33.2964439998763
                        ],
                        [
                            -81.8487360000622,
                            33.2967439996309
                        ],
                        [
                            -81.8495360001283,
                            33.3000440001662
                        ],
                        [
                            -81.846036000288,
                            33.3043430000996
                        ],
                        [
                            -81.8473359999464,
                            33.306843000147
                        ],
                        [
                            -81.8551859999219,
                            33.3103949997852
                        ],
                        [
                            -81.86392899985,
                            33.3020360000786
                        ],
                        [
                            -81.8649359996054,
                            33.3118429999316
                        ],
                        [
                            -81.8679359998535,
                            33.3140429996265
                        ],
                        [
                            -81.8807369998387,
                            33.3022430001737
                        ],
                        [
                            -81.8846369997121,
                            33.3089429996639
                        ],
                        [
                            -81.8816359996391,
                            33.315843000053
                        ],
                        [
                            -81.8837370000868,
                            33.317643000095
                        ],
                        [
                            -81.8925369999163,
                            33.3153430000343
                        ],
                        [
                            -81.8891369996351,
                            33.3210430001795
                        ],
                        [
                            -81.8905369997509,
                            33.3224419998039
                        ],
                        [
                            -81.8955370001644,
                            33.3216419999634
                        ],
                        [
                            -81.8990370000048,
                            33.3233420001356
                        ],
                        [
                            -81.8994370000378,
                            33.3251419999369
                        ],
                        [
                            -81.8965370002471,
                            33.3295419997925
                        ],
                        [
                            -81.9013839996015,
                            33.335773999854
                        ],
                        [
                            -81.9036370003852,
                            33.334841999743
                        ],
                        [
                            -81.9027369998616,
                            33.3302420001115
                        ],
                        [
                            -81.9060369996854,
                            33.3260420001562
                        ],
                        [
                            -81.9061299995718,
                            33.321619999682
                        ],
                        [
                            -81.9115369996911,
                            33.3191420001405
                        ],
                        [
                            -81.9137369998731,
                            33.3200420000205
                        ],
                        [
                            -81.9091370003909,
                            33.3239419996968
                        ],
                        [
                            -81.9081370003082,
                            33.3271419996434
                        ],
                        [
                            -81.9191370003197,
                            33.334442000248
                        ],
                        [
                            -81.9150370004297,
                            33.3415420002152
                        ],
                        [
                            -81.9084369998839,
                            33.3438419999326
                        ],
                        [
                            -81.9091370003909,
                            33.3466409997712
                        ],
                        [
                            -81.9132149996412,
                            33.3468209998112
                        ],
                        [
                            -81.9188000000358,
                            33.3402089998818
                        ],
                        [
                            -81.9247369998845,
                            33.3453410002734
                        ],
                        [
                            -81.9327369996478,
                            33.3435409998104
                        ],
                        [
                            -81.9397370002268,
                            33.3449410002176
                        ],
                        [
                            -81.9398369997859,
                            33.3477410002911
                        ],
                        [
                            -81.9356370003368,
                            33.3520409996773
                        ],
                        [
                            -81.9348320002478,
                            33.3560440001271
                        ],
                        [
                            -81.944736999742,
                            33.3640409999814
                        ],
                        [
                            -81.9467369999074,
                            33.367340000111
                        ],
                        [
                            -81.9463369998743,
                            33.3706399997156
                        ],
                        [
                            -81.9396369997693,
                            33.3725400003085
                        ],
                        [
                            -81.9346370002541,
                            33.3689399999794
                        ],
                        [
                            -81.9306340004486,
                            33.3681650003066
                        ],
                        [
                            -81.9257369999672,
                            33.3711399996703
                        ],
                        [
                            -81.9248370003419,
                            33.3741399998105
                        ],
                        [
                            -81.9308610002293,
                            33.3800760000393
                        ],
                        [
                            -81.9354530002138,
                            33.3978509998057
                        ],
                        [
                            -81.9444759996261,
                            33.4036740001875
                        ],
                        [
                            -81.945181000156,
                            33.4075310002278
                        ],
                        [
                            -81.9426689998045,
                            33.4091949997294
                        ],
                        [
                            -81.9313900001338,
                            33.4069560002002
                        ],
                        [
                            -81.9244980004082,
                            33.4124790002135
                        ],
                        [
                            -81.9217740003446,
                            33.4088669998119
                        ],
                        [
                            -81.916405999685,
                            33.4073489998482
                        ],
                        [
                            -81.912098000281,
                            33.4082599997796
                        ],
                        [
                            -81.9096930000596,
                            33.4119790002946
                        ],
                        [
                            -81.9107650001123,
                            33.4150350002047
                        ],
                        [
                            -81.9245500002867,
                            33.4191090001123
                        ],
                        [
                            -81.9324300003995,
                            33.4257060003182
                        ],
                        [
                            -81.9297409995977,
                            33.4295939996947
                        ],
                        [
                            -81.917323999752,
                            33.4323460000122
                        ],
                        [
                            -81.9131259999528,
                            33.4383329998282
                        ],
                        [
                            -81.9138520003991,
                            33.4420259996546
                        ],
                        [
                            -81.9194360000704,
                            33.4490980002991
                        ],
                        [
                            -81.9263360001919,
                            33.4629369998408
                        ],
                        [
                            -81.9341359999387,
                            33.4683370001796
                        ],
                        [
                            -81.9859380002794,
                            33.486536000145
                        ],
                        [
                            -81.9909379997946,
                            33.494235000256
                        ],
                        [
                            -81.9903820001259,
                            33.5007589999502
                        ],
                        [
                            -81.9919379998773,
                            33.5044349998335
                        ],
                        [
                            -82.0013379997564,
                            33.520134999712
                        ],
                        [
                            -82.0076379998283,
                            33.5233350001264
                        ],
                        [
                            -82.0109650003146,
                            33.5313399997429
                        ],
                        [
                            -82.019837999939,
                            33.5350350000402
                        ],
                        [
                            -82.0234380002367,
                            33.5379350001985
                        ],
                        [
                            -82.0234380002367,
                            33.5407339999699
                        ],
                        [
                            -82.0256130003043,
                            33.5427560002757
                        ],
                        [
                            -82.0330230000638,
                            33.5464540000039
                        ],
                        [
                            -82.0373749998488,
                            33.5546620002532
                        ],
                        [
                            -82.0463350004102,
                            33.5638299997541
                        ],
                        [
                            -82.0577270000588,
                            33.5667739998088
                        ],
                        [
                            -82.0731039999696,
                            33.577510000373
                        ],
                        [
                            -82.0941280001992,
                            33.5827420002901
                        ],
                        [
                            -82.1078399996804,
                            33.5962770001023
                        ],
                        [
                            -82.1175049998734,
                            33.5962290000749
                        ],
                        [
                            -82.1290799998201,
                            33.5899250000605
                        ],
                        [
                            -82.1380279997876,
                            33.592235999987
                        ],
                        [
                            -82.1488160000869,
                            33.5980920001972
                        ],
                        [
                            -82.1533570000178,
                            33.6063190002226
                        ],
                        [
                            -82.1562879997707,
                            33.6086300000434
                        ],
                        [
                            -82.1761720001755,
                            33.613774999853
                        ],
                        [
                            -82.1861550003858,
                            33.620880999713
                        ],
                        [
                            -82.1968950001062,
                            33.6314400001754
                        ],
                        [
                            -82.2011859997919,
                            33.6468979998474
                        ],
                        [
                            -82.1998979998291,
                            33.661699999782
                        ],
                        [
                            -82.2103610004384,
                            33.6698370001152
                        ],
                        [
                            -82.2178830003509,
                            33.6855390000442
                        ],
                        [
                            -82.2345719996156,
                            33.7002169999486
                        ],
                        [
                            -82.2371919999221,
                            33.7078799996671
                        ],
                        [
                            -82.2357530003466,
                            33.7143899999643
                        ],
                        [
                            -82.2390980003762,
                            33.7308719999014
                        ],
                        [
                            -82.2456639996885,
                            33.7434430001606
                        ],
                        [
                            -82.2474720002333,
                            33.7525909997437
                        ],
                        [
                            -82.2552669999572,
                            33.7596900000197
                        ],
                        [
                            -82.2632059996212,
                            33.7619620002777
                        ],
                        [
                            -82.2661270002266,
                            33.766745000101
                        ],
                        [
                            -82.2776810002569,
                            33.7720319996684
                        ],
                        [
                            -82.2858040000439,
                            33.7800580000657
                        ],
                        [
                            -82.2982860001869,
                            33.7835180001099
                        ],
                        [
                            -82.3002129996591,
                            33.8006270000251
                        ],
                        [
                            -82.3128999998415,
                            33.8074860000915
                        ],
                        [
                            -82.314350000186,
                            33.8111110000208
                        ],
                        [
                            -82.324479999811,
                            33.820033000212
                        ],
                        [
                            -82.3378289999674,
                            33.827155999905
                        ],
                        [
                            -82.3469329995705,
                            33.8342980003495
                        ],
                        [
                            -82.3717749996577,
                            33.8438130003418
                        ],
                        [
                            -82.3797710001214,
                            33.8510959997791
                        ],
                        [
                            -82.395734999663,
                            33.8590879996938
                        ],
                        [
                            -82.4038790002647,
                            33.8654750001573
                        ],
                        [
                            -82.4083530002485,
                            33.8663199999899
                        ],
                        [
                            -82.4248880004258,
                            33.8641329999241
                        ],
                        [
                            -82.4311499999645,
                            33.8670510000027
                        ],
                        [
                            -82.4405029999879,
                            33.8751229999019
                        ],
                        [
                            -82.4551049999469,
                            33.8816500003091
                        ],
                        [
                            -82.4801110000657,
                            33.9018969997905
                        ],
                        [
                            -82.4929289997694,
                            33.9097539996672
                        ],
                        [
                            -82.5114220002793,
                            33.9356129999049
                        ],
                        [
                            -82.5245150003426,
                            33.9433600000378
                        ],
                        [
                            -82.5341110000403,
                            33.9436509997457
                        ],
                        [
                            -82.5431069996883,
                            33.9409499997927
                        ],
                        [
                            -82.5567649996411,
                            33.9453240003525
                        ],
                        [
                            -82.5652020001458,
                            33.9567649998207
                        ],
                        [
                            -82.5675899997504,
                            33.9678800000908
                        ],
                        [
                            -82.5799799998319,
                            33.9805190003326
                        ],
                        [
                            -82.5801239997719,
                            33.9864469999251
                        ],
                        [
                            -82.5755689995973,
                            33.9902350002329
                        ],
                        [
                            -82.575554000427,
                            33.9922110001394
                        ],
                        [
                            -82.5850289997509,
                            33.9961710002058
                        ],
                        [
                            -82.5889589997615,
                            34.0005119997593
                        ],
                        [
                            -82.5954970003831,
                            34.0158649999619
                        ],
                        [
                            -82.5944589997672,
                            34.0293039998995
                        ],
                        [
                            -82.6094209995764,
                            34.0399059997713
                        ],
                        [
                            -82.6130140002014,
                            34.0468979998481
                        ],
                        [
                            -82.6191560000895,
                            34.051350000167
                        ],
                        [
                            -82.6214059996019,
                            34.0580179997331
                        ],
                        [
                            -82.6268389996605,
                            34.0635190003535
                        ],
                        [
                            -82.6313939998351,
                            34.0655100001773
                        ],
                        [
                            -82.6354369998236,
                            34.0650899998947
                        ],
                        [
                            -82.6414880003735,
                            34.0688940001857
                        ],
                        [
                            -82.6455299996388,
                            34.0763129999684
                        ],
                        [
                            -82.6402699998325,
                            34.0863629997052
                        ],
                        [
                            -82.6413109999231,
                            34.0915509998537
                        ],
                        [
                            -82.6477370003917,
                            34.0984789996381
                        ],
                        [
                            -82.6587290000072,
                            34.1037720003535
                        ],
                        [
                            -82.666785999672,
                            34.1134409996348
                        ],
                        [
                            -82.6685590000567,
                            34.1204349996344
                        ],
                        [
                            -82.6752189999787,
                            34.1297790002082
                        ],
                        [
                            -82.6787299996896,
                            34.1323929998464
                        ],
                        [
                            -82.6862900001352,
                            34.1344540001146
                        ],
                        [
                            -82.6921519996409,
                            34.1389859997938
                        ],
                        [
                            -82.7041290001688,
                            34.1410209997378
                        ],
                        [
                            -82.7174590000585,
                            34.15054600037
                        ],
                        [
                            -82.7247690002589,
                            34.1686940003144
                        ],
                        [
                            -82.731452999572,
                            34.1770859996921
                        ],
                        [
                            -82.7321239997667,
                            34.1933210002598
                        ],
                        [
                            -82.7410070003352,
                            34.2088620000861
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 33.8741769,
            "name": "South Carolina",
            "intptlon": -80.8542699,
            "geo_point_2d": [
                33.8779486788,
                -80.864660852
            ],
            "geoid": "45",
            "mtfcc": "G4000",
            "region": 3
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -80.864660852,
                33.8779486788
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "5827df469dfb43b47b18492f8f3029b6fea9d3dc",
        "fields": {
            "arealand": "1570063024",
            "objectid": 29,
            "basename": "Oklahoma",
            "stusab": "OK",
            "statens": "01102857",
            "centlon": -97.4947073,
            "state": "40",
            "gid": 7,
            "centlat": 35.5885671,
            "division": 7,
            "areawater": "-921131230",
            "oid": "313259141",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -100.003762000434,
                            36.4998489997935
                        ],
                        [
                            -103.002365999799,
                            36.5003979998481
                        ],
                        [
                            -103.002328999989,
                            37.000149999736
                        ],
                        [
                            -102.986976000368,
                            36.9985240001282
                        ],
                        [
                            -102.815074000425,
                            36.9997739999144
                        ],
                        [
                            -102.752140999653,
                            36.9983410002298
                        ],
                        [
                            -102.698142000402,
                            36.9951490001591
                        ],
                        [
                            -102.042089000342,
                            36.9930159998508
                        ],
                        [
                            -100.107890999923,
                            37.0023119997524
                        ],
                        [
                            -99.5580040002273,
                            36.9995289997991
                        ],
                        [
                            -98.7937250002632,
                            36.9994610002303
                        ],
                        [
                            -98.3004649996226,
                            36.9976839997532
                        ],
                        [
                            -97.6368439996201,
                            36.9990849998713
                        ],
                        [
                            -94.617964000213,
                            36.998905000209
                        ],
                        [
                            -94.6179190000071,
                            36.4994140002771
                        ],
                        [
                            -94.5718059998181,
                            36.2137479999444
                        ],
                        [
                            -94.5236270002396,
                            35.9401579998437
                        ],
                        [
                            -94.4499749996639,
                            35.4980769998421
                        ],
                        [
                            -94.4310450002093,
                            35.3937350002055
                        ],
                        [
                            -94.4355900003382,
                            35.3861220002189
                        ],
                        [
                            -94.4315959998551,
                            35.3695379997342
                        ],
                        [
                            -94.433215000254,
                            35.3657910002557
                        ],
                        [
                            -94.4322489996082,
                            35.3630299999631
                        ],
                        [
                            -94.4344160001782,
                            35.2979819998999
                        ],
                        [
                            -94.4377689997054,
                            35.2402890001223
                        ],
                        [
                            -94.4576120001023,
                            34.6430019998755
                        ],
                        [
                            -94.4852800003604,
                            33.6550019998292
                        ],
                        [
                            -94.4858779998619,
                            33.6378649997599
                        ],
                        [
                            -94.491695999885,
                            33.636311999686
                        ],
                        [
                            -94.488433999696,
                            33.6323669998865
                        ],
                        [
                            -94.4876110000636,
                            33.6285440002078
                        ],
                        [
                            -94.4915030004394,
                            33.6251149997613
                        ],
                        [
                            -94.4989109996508,
                            33.623923999884
                        ],
                        [
                            -94.5056550001366,
                            33.6206679997173
                        ],
                        [
                            -94.5115140001676,
                            33.6231049997946
                        ],
                        [
                            -94.5174679997347,
                            33.6330560002634
                        ],
                        [
                            -94.5202450004001,
                            33.6298049998269
                        ],
                        [
                            -94.5179199996464,
                            33.623734000049
                        ],
                        [
                            -94.519837999796,
                            33.6180769999473
                        ],
                        [
                            -94.5234980003682,
                            33.6158990002008
                        ],
                        [
                            -94.5279499997123,
                            33.6159640000383
                        ],
                        [
                            -94.5348139998488,
                            33.6213359996683
                        ],
                        [
                            -94.5353129996161,
                            33.6245850001244
                        ],
                        [
                            -94.5185289999168,
                            33.6375700001843
                        ],
                        [
                            -94.5218370001365,
                            33.6407279998212
                        ],
                        [
                            -94.527491000128,
                            33.6413439998554
                        ],
                        [
                            -94.5418579999104,
                            33.6362990002496
                        ],
                        [
                            -94.5464370003744,
                            33.6315040002445
                        ],
                        [
                            -94.5533719997577,
                            33.6275840000844
                        ],
                        [
                            -94.5640760003915,
                            33.6262649996305
                        ],
                        [
                            -94.5689649995786,
                            33.6274529998793
                        ],
                        [
                            -94.5704469997102,
                            33.6299469997831
                        ],
                        [
                            -94.5683860003437,
                            33.6372029997603
                        ],
                        [
                            -94.5590419996428,
                            33.6419710002655
                        ],
                        [
                            -94.5454359995685,
                            33.6462280002703
                        ],
                        [
                            -94.5429009996508,
                            33.6496609998698
                        ],
                        [
                            -94.5426270000144,
                            33.6539919997809
                        ],
                        [
                            -94.5467319999272,
                            33.6595659999341
                        ],
                        [
                            -94.5496359999159,
                            33.6606759999258
                        ],
                        [
                            -94.5705660004342,
                            33.6522259999952
                        ],
                        [
                            -94.5800469996058,
                            33.6444999997066
                        ],
                        [
                            -94.5904780004281,
                            33.6450749996558
                        ],
                        [
                            -94.5869860000854,
                            33.6501799999975
                        ],
                        [
                            -94.5767650002237,
                            33.6587629997063
                        ],
                        [
                            -94.5648019999395,
                            33.6618039996756
                        ],
                        [
                            -94.5603389998264,
                            33.6665909997486
                        ],
                        [
                            -94.5636679999625,
                            33.6692719998558
                        ],
                        [
                            -94.5778759997362,
                            33.665681999752
                        ],
                        [
                            -94.5807839999228,
                            33.6663919996502
                        ],
                        [
                            -94.5792690001793,
                            33.6708170001988
                        ],
                        [
                            -94.581250999903,
                            33.6736179998395
                        ],
                        [
                            -94.5796199998085,
                            33.6776229997137
                        ],
                        [
                            -94.5879300000916,
                            33.6862549996991
                        ],
                        [
                            -94.5915849997426,
                            33.6858670001378
                        ],
                        [
                            -94.5930300000642,
                            33.6840190003544
                        ],
                        [
                            -94.5900270003414,
                            33.6758500003409
                        ],
                        [
                            -94.5919529999886,
                            33.6728629999221
                        ],
                        [
                            -94.592050000073,
                            33.6670390002427
                        ],
                        [
                            -94.5960190004417,
                            33.6638629997173
                        ],
                        [
                            -94.6011629998969,
                            33.6627609999059
                        ],
                        [
                            -94.6058229996535,
                            33.6641890002424
                        ],
                        [
                            -94.6118909999218,
                            33.6700400002218
                        ],
                        [
                            -94.616417999609,
                            33.6801029996301
                        ],
                        [
                            -94.6220430001865,
                            33.6825349999913
                        ],
                        [
                            -94.6287109996061,
                            33.6805320001302
                        ],
                        [
                            -94.630919000184,
                            33.6734419998492
                        ],
                        [
                            -94.6391979996066,
                            33.6637369998118
                        ],
                        [
                            -94.6459229998259,
                            33.6619450002868
                        ],
                        [
                            -94.6492340004186,
                            33.6626040002332
                        ],
                        [
                            -94.6549190003723,
                            33.6599250002823
                        ],
                        [
                            -94.6618289996412,
                            33.6604570001015
                        ],
                        [
                            -94.6686459999221,
                            33.6638459999129
                        ],
                        [
                            -94.6705640000717,
                            33.6659339996622
                        ],
                        [
                            -94.6707599998903,
                            33.6692999998766
                        ],
                        [
                            -94.6675400004325,
                            33.6728000002294
                        ],
                        [
                            -94.662615000362,
                            33.6749460000469
                        ],
                        [
                            -94.6595249997022,
                            33.675129000227
                        ],
                        [
                            -94.6518499996289,
                            33.6704529996556
                        ],
                        [
                            -94.64533700002,
                            33.6736440001474
                        ],
                        [
                            -94.6503730004185,
                            33.6844380000472
                        ],
                        [
                            -94.6454919998307,
                            33.6859320002288
                        ],
                        [
                            -94.6386460001359,
                            33.695803999945
                        ],
                        [
                            -94.6394500004,
                            33.6982589996433
                        ],
                        [
                            -94.6437150001464,
                            33.7015220000414
                        ],
                        [
                            -94.6479020000749,
                            33.7005159998083
                        ],
                        [
                            -94.6470929997879,
                            33.6903459997732
                        ],
                        [
                            -94.6488040002483,
                            33.6865730003454
                        ],
                        [
                            -94.6514019999152,
                            33.6858430000458
                        ],
                        [
                            -94.6591670004002,
                            33.6921380002168
                        ],
                        [
                            -94.6668850001313,
                            33.6945829997516
                        ],
                        [
                            -94.6717730003918,
                            33.6933070001126
                        ],
                        [
                            -94.6847960001349,
                            33.6843529996485
                        ],
                        [
                            -94.704113999927,
                            33.6879450001386
                        ],
                        [
                            -94.716187999641,
                            33.6879020001365
                        ],
                        [
                            -94.7183379995942,
                            33.6886640002202
                        ],
                        [
                            -94.714431999873,
                            33.6971890003114
                        ],
                        [
                            -94.703496000334,
                            33.6975379998836
                        ],
                        [
                            -94.7010550001276,
                            33.7000530001635
                        ],
                        [
                            -94.7015599997427,
                            33.7028779999384
                        ],
                        [
                            -94.7060239996807,
                            33.7062929997038
                        ],
                        [
                            -94.7252949996171,
                            33.7146720003366
                        ],
                        [
                            -94.7339649997503,
                            33.7156870001789
                        ],
                        [
                            -94.7349179999773,
                            33.7147649997156
                        ],
                        [
                            -94.7336430004333,
                            33.7113059997257
                        ],
                        [
                            -94.7306909998658,
                            33.7073550002086
                        ],
                        [
                            -94.7188720004199,
                            33.703349000198
                        ],
                        [
                            -94.7159430003169,
                            33.6949129997575
                        ],
                        [
                            -94.7195310000208,
                            33.688856999793
                        ],
                        [
                            -94.7261230001707,
                            33.6908989997475
                        ],
                        [
                            -94.7351170001689,
                            33.6912590001758
                        ],
                        [
                            -94.7409219997732,
                            33.7005549996483
                        ],
                        [
                            -94.7519419998761,
                            33.704862000175
                        ],
                        [
                            -94.7538009995762,
                            33.7086629998066
                        ],
                        [
                            -94.7530690001804,
                            33.7107530003262
                        ],
                        [
                            -94.7412370003158,
                            33.7133580000064
                        ],
                        [
                            -94.7361289999472,
                            33.7160799999603
                        ],
                        [
                            -94.7327859995674,
                            33.7200349996971
                        ],
                        [
                            -94.7334130002795,
                            33.7251909999719
                        ],
                        [
                            -94.7367390000426,
                            33.7271869997187
                        ],
                        [
                            -94.7420919997352,
                            33.7266920003178
                        ],
                        [
                            -94.7450889996103,
                            33.7229080001282
                        ],
                        [
                            -94.7458550002396,
                            33.7161579996859
                        ],
                        [
                            -94.7513660001159,
                            33.7135400000439
                        ],
                        [
                            -94.755905000397,
                            33.7150730000818
                        ],
                        [
                            -94.7577380001578,
                            33.7178170000867
                        ],
                        [
                            -94.7476679999089,
                            33.7251479999341
                        ],
                        [
                            -94.7518249997002,
                            33.7343259996279
                        ],
                        [
                            -94.7606039996133,
                            33.72705400021
                        ],
                        [
                            -94.7686949996133,
                            33.7269889998527
                        ],
                        [
                            -94.7824980002293,
                            33.7301250001073
                        ],
                        [
                            -94.7886369997444,
                            33.7356689998403
                        ],
                        [
                            -94.7768149999255,
                            33.7464370002188
                        ],
                        [
                            -94.7652559998723,
                            33.7460959998582
                        ],
                        [
                            -94.7589480003028,
                            33.7485319997254
                        ],
                        [
                            -94.7571890001618,
                            33.7530279996957
                        ],
                        [
                            -94.7578469999378,
                            33.7558890000928
                        ],
                        [
                            -94.763034999774,
                            33.7597639996292
                        ],
                        [
                            -94.769850000405,
                            33.761033999643
                        ],
                        [
                            -94.7729150000521,
                            33.7597329999217
                        ],
                        [
                            -94.7763680000368,
                            33.756398999885
                        ],
                        [
                            -94.7800679998936,
                            33.7444690002668
                        ],
                        [
                            -94.7972600000219,
                            33.7369250002101
                        ],
                        [
                            -94.8028329998226,
                            33.7326990001601
                        ],
                        [
                            -94.811605000063,
                            33.7350430000892
                        ],
                        [
                            -94.8170230000529,
                            33.7400069998785
                        ],
                        [
                            -94.8129120002923,
                            33.7454470003173
                        ],
                        [
                            -94.8121549998839,
                            33.7509359998405
                        ],
                        [
                            -94.8127640001543,
                            33.7521130000436
                        ],
                        [
                            -94.8152530000413,
                            33.7513820002753
                        ],
                        [
                            -94.8168310004323,
                            33.7542159996882
                        ],
                        [
                            -94.8163870000183,
                            33.7639630001285
                        ],
                        [
                            -94.8207070000163,
                            33.7689639997394
                        ],
                        [
                            -94.8233850000491,
                            33.7691789996655
                        ],
                        [
                            -94.8272749998767,
                            33.7668850003182
                        ],
                        [
                            -94.8308750001745,
                            33.7599850003029
                        ],
                        [
                            -94.8230419999174,
                            33.7475249996757
                        ],
                        [
                            -94.8201549996472,
                            33.7369840003249
                        ],
                        [
                            -94.8202159997466,
                            33.7341309999915
                        ],
                        [
                            -94.8219890001313,
                            33.7328780000453
                        ],
                        [
                            -94.8284559997094,
                            33.735117000052
                        ],
                        [
                            -94.8279479997213,
                            33.7408819998019
                        ],
                        [
                            -94.8493140002577,
                            33.7395869998227
                        ],
                        [
                            -94.8675440001035,
                            33.7449040001517
                        ],
                        [
                            -94.8746690003559,
                            33.7491639997162
                        ],
                        [
                            -94.8771249997326,
                            33.7531230002673
                        ],
                        [
                            -94.876123999825,
                            33.7555009999585
                        ],
                        [
                            -94.8667359996414,
                            33.7618300001565
                        ],
                        [
                            -94.8659289999026,
                            33.7656369999362
                        ],
                        [
                            -94.8681440001532,
                            33.768873999836
                        ],
                        [
                            -94.8717369998799,
                            33.770394999767
                        ],
                        [
                            -94.8745639998756,
                            33.7696179999824
                        ],
                        [
                            -94.8738330003048,
                            33.7580589998143
                        ],
                        [
                            -94.8763720004204,
                            33.7573300000418
                        ],
                        [
                            -94.8856779996899,
                            33.7652339998593
                        ],
                        [
                            -94.8869339998656,
                            33.76906699982
                        ],
                        [
                            -94.8953029996999,
                            33.7684039998828
                        ],
                        [
                            -94.9012809995565,
                            33.7708500002314
                        ],
                        [
                            -94.904197999964,
                            33.773249000207
                        ],
                        [
                            -94.9041720000247,
                            33.7771979998564
                        ],
                        [
                            -94.9128680000971,
                            33.7794759998141
                        ],
                        [
                            -94.9215259996364,
                            33.7904719999262
                        ],
                        [
                            -94.9213139999243,
                            33.7984080002539
                        ],
                        [
                            -94.916747999879,
                            33.8089689998729
                        ],
                        [
                            -94.9144869995977,
                            33.8085160000396
                        ],
                        [
                            -94.9108820001754,
                            33.8159150002346
                        ],
                        [
                            -94.9180099999026,
                            33.8267829999045
                        ],
                        [
                            -94.9224389996805,
                            33.8291130001363
                        ],
                        [
                            -94.9266159995633,
                            33.827556999657
                        ],
                        [
                            -94.9230990000045,
                            33.8154559999461
                        ],
                        [
                            -94.9242659995935,
                            33.8124940001242
                        ],
                        [
                            -94.9232889999753,
                            33.8087419997883
                        ],
                        [
                            -94.9404359998978,
                            33.8056370003718
                        ],
                        [
                            -94.9478740001446,
                            33.8083559998103
                        ],
                        [
                            -94.9535180000904,
                            33.8164749998803
                        ],
                        [
                            -94.9448379999115,
                            33.8258400003607
                        ],
                        [
                            -94.9438880000575,
                            33.830226000206
                        ],
                        [
                            -94.9522979998997,
                            33.8349490003113
                        ],
                        [
                            -94.964400999926,
                            33.8370209999445
                        ],
                        [
                            -94.9657990003919,
                            33.8486959998331
                        ],
                        [
                            -94.9702049997053,
                            33.8618169996309
                        ],
                        [
                            -94.9758960004051,
                            33.8601879996562
                        ],
                        [
                            -94.9816340000622,
                            33.8523140000327
                        ],
                        [
                            -94.988487000328,
                            33.8510000000314
                        ],
                        [
                            -94.9926709998835,
                            33.8524550002303
                        ],
                        [
                            -94.9980189995533,
                            33.8605039999523
                        ],
                        [
                            -95.0021870001136,
                            33.8637129999116
                        ],
                        [
                            -95.0101850002271,
                            33.8587309999448
                        ],
                        [
                            -95.015744999609,
                            33.8577330002995
                        ],
                        [
                            -95.0465679999259,
                            33.8625650001276
                        ],
                        [
                            -95.0490250000258,
                            33.8640900002503
                        ],
                        [
                            -95.0507039998008,
                            33.8681790003161
                        ],
                        [
                            -95.0505719995564,
                            33.8782989997549
                        ],
                        [
                            -95.0590879997037,
                            33.8874909999299
                        ],
                        [
                            -95.0601050004032,
                            33.9018729997932
                        ],
                        [
                            -95.0622839997704,
                            33.9036180002202
                        ],
                        [
                            -95.0724129995706,
                            33.9026990002266
                        ],
                        [
                            -95.0735280001793,
                            33.9019660002111
                        ],
                        [
                            -95.069952000171,
                            33.8955780002171
                        ],
                        [
                            -95.0718009998254,
                            33.8955610001094
                        ],
                        [
                            -95.075875999601,
                            33.9018460002542
                        ],
                        [
                            -95.0764170000994,
                            33.9042980002606
                        ],
                        [
                            -95.0746289996461,
                            33.9072670002287
                        ],
                        [
                            -95.0669789996871,
                            33.9082529997034
                        ],
                        [
                            -95.0642350004303,
                            33.9101559997757
                        ],
                        [
                            -95.0634589997553,
                            33.9140399998362
                        ],
                        [
                            -95.0667600003023,
                            33.9171010002936
                        ],
                        [
                            -95.06947599997,
                            33.9175860002182
                        ],
                        [
                            -95.0739659998472,
                            33.9156359997975
                        ],
                        [
                            -95.0749879996713,
                            33.9088049998628
                        ],
                        [
                            -95.078695999924,
                            33.9050400001162
                        ],
                        [
                            -95.079834000099,
                            33.8874789997692
                        ],
                        [
                            -95.0824080003748,
                            33.8798819999645
                        ],
                        [
                            -95.089607000247,
                            33.8808569996293
                        ],
                        [
                            -95.0987090002003,
                            33.8906829998871
                        ],
                        [
                            -95.1003779999296,
                            33.8955950003214
                        ],
                        [
                            -95.0974079998187,
                            33.8988609998183
                        ],
                        [
                            -95.091502999757,
                            33.9003289996876
                        ],
                        [
                            -95.0920640003469,
                            33.9019449997649
                        ],
                        [
                            -95.0875829997921,
                            33.9030179999343
                        ],
                        [
                            -95.0797420000375,
                            33.9085950000856
                        ],
                        [
                            -95.0767819999723,
                            33.9135840003211
                        ],
                        [
                            -95.0779500002845,
                            33.9186959999317
                        ],
                        [
                            -95.0800600000548,
                            33.9209889998643
                        ],
                        [
                            -95.0831980003952,
                            33.9214170003422
                        ],
                        [
                            -95.0898010004158,
                            33.9140690001349
                        ],
                        [
                            -95.1051759997785,
                            33.9094640001921
                        ],
                        [
                            -95.1103879999041,
                            33.9054070002732
                        ],
                        [
                            -95.1208930003462,
                            33.9045390003723
                        ],
                        [
                            -95.1273640001223,
                            33.907825999979
                        ],
                        [
                            -95.1316439999373,
                            33.9139260001862
                        ],
                        [
                            -95.1316420002875,
                            33.9160270002974
                        ],
                        [
                            -95.1217269998492,
                            33.9257620002
                        ],
                        [
                            -95.121183999701,
                            33.9313070000701
                        ],
                        [
                            -95.1247000003332,
                            33.9346749996967
                        ],
                        [
                            -95.1301569997829,
                            33.9367989998072
                        ],
                        [
                            -95.1394839998671,
                            33.9347329996517
                        ],
                        [
                            -95.1569610004007,
                            33.9366450002353
                        ],
                        [
                            -95.1715030000853,
                            33.9467200000761
                        ],
                        [
                            -95.1981040003135,
                            33.9533519999625
                        ],
                        [
                            -95.2057609999452,
                            33.9586159997497
                        ],
                        [
                            -95.2169469997294,
                            33.9626740001171
                        ],
                        [
                            -95.2260410001851,
                            33.9622370002064
                        ],
                        [
                            -95.2304440000238,
                            33.9600429998538
                        ],
                        [
                            -95.2302580002509,
                            33.9559879997747
                        ],
                        [
                            -95.2328790003824,
                            33.9497360000484
                        ],
                        [
                            -95.240604999611,
                            33.9472289996618
                        ],
                        [
                            -95.2500010001905,
                            33.9388609998893
                        ],
                        [
                            -95.2508930003181,
                            33.9342549996917
                        ],
                        [
                            -95.247596999794,
                            33.91561300037
                        ],
                        [
                            -95.2485009996172,
                            33.9069739996475
                        ],
                        [
                            -95.2531870002114,
                            33.8948779999535
                        ],
                        [
                            -95.2577239999443,
                            33.8901219996296
                        ],
                        [
                            -95.2621200001103,
                            33.8878260000069
                        ],
                        [
                            -95.2666560000183,
                            33.8938999996508
                        ],
                        [
                            -95.2649970003348,
                            33.9045880001631
                        ],
                        [
                            -95.2686970001916,
                            33.9110899996733
                        ],
                        [
                            -95.275959999638,
                            33.9112789996294
                        ],
                        [
                            -95.2846289999462,
                            33.9054830001976
                        ],
                        [
                            -95.2845579998011,
                            33.8999170000427
                        ],
                        [
                            -95.280691000438,
                            33.8960750000257
                        ],
                        [
                            -95.278163000193,
                            33.8903429999456
                        ],
                        [
                            -95.2781740000636,
                            33.878385999802
                        ],
                        [
                            -95.2807249998748,
                            33.8754909999292
                        ],
                        [
                            -95.288926000378,
                            33.8730700000406
                        ],
                        [
                            -95.3063970001655,
                            33.8749060003317
                        ],
                        [
                            -95.314427999891,
                            33.8816320003266
                        ],
                        [
                            -95.3299360002214,
                            33.8863339997859
                        ],
                        [
                            -95.3304579995549,
                            33.8825320000247
                        ],
                        [
                            -95.3356959996198,
                            33.8742520000428
                        ],
                        [
                            -95.3625710003827,
                            33.8669020002355
                        ],
                        [
                            -95.3838259996927,
                            33.8686459998205
                        ],
                        [
                            -95.4052089999475,
                            33.8645750003451
                        ],
                        [
                            -95.4276840003466,
                            33.8704590001281
                        ],
                        [
                            -95.4383000002185,
                            33.8663419997714
                        ],
                        [
                            -95.4427459997148,
                            33.8665059999307
                        ],
                        [
                            -95.453655000388,
                            33.8708619999628
                        ],
                        [
                            -95.4633359995762,
                            33.872395999781
                        ],
                        [
                            -95.4643390000319,
                            33.8743889998956
                        ],
                        [
                            -95.459730000329,
                            33.8866110000721
                        ],
                        [
                            -95.4614400000661,
                            33.8871059998973
                        ],
                        [
                            -95.4649380002566,
                            33.8868910002658
                        ],
                        [
                            -95.4704700000494,
                            33.8815989998514
                        ],
                        [
                            -95.4835310003256,
                            33.8768250002446
                        ],
                        [
                            -95.492039999902,
                            33.8749420001473
                        ],
                        [
                            -95.504999000071,
                            33.8759220003171
                        ],
                        [
                            -95.5062100000409,
                            33.8778090001814
                        ],
                        [
                            -95.5050919999574,
                            33.8934059996752
                        ],
                        [
                            -95.5066190002948,
                            33.8967159998076
                        ],
                        [
                            -95.5095259997582,
                            33.8975860001059
                        ],
                        [
                            -95.5159789999909,
                            33.8939860002568
                        ],
                        [
                            -95.5185289999772,
                            33.8898769998416
                        ],
                        [
                            -95.5326220001232,
                            33.8815210001014
                        ],
                        [
                            -95.5441409999934,
                            33.8806029999804
                        ],
                        [
                            -95.5489609995836,
                            33.8850529997248
                        ],
                        [
                            -95.5512160000171,
                            33.8897129998492
                        ],
                        [
                            -95.5478319996294,
                            33.9018560001949
                        ],
                        [
                            -95.5491139997444,
                            33.9115340001013
                        ],
                        [
                            -95.5542100004174,
                            33.9269580001217
                        ],
                        [
                            -95.5577110000827,
                            33.9303510000003
                        ],
                        [
                            -95.5637840003738,
                            33.9329449998385
                        ],
                        [
                            -95.5818540003861,
                            33.9341520003484
                        ],
                        [
                            -95.5878699998776,
                            33.9408110000382
                        ],
                        [
                            -95.5940849995606,
                            33.9430570002911
                        ],
                        [
                            -95.5975219996517,
                            33.9423419999908
                        ],
                        [
                            -95.5984160003276,
                            33.9343799997766
                        ],
                        [
                            -95.6031380000084,
                            33.9292000002309
                        ],
                        [
                            -95.6163669996158,
                            33.9276259998089
                        ],
                        [
                            -95.6247980002727,
                            33.9156639998701
                        ],
                        [
                            -95.6273180001218,
                            33.9078060000058
                        ],
                        [
                            -95.6327739997466,
                            33.9048149998767
                        ],
                        [
                            -95.6387719996947,
                            33.9049380002999
                        ],
                        [
                            -95.6552320004272,
                            33.9099140001378
                        ],
                        [
                            -95.6654780004032,
                            33.9090040002605
                        ],
                        [
                            -95.684578999562,
                            33.890646000025
                        ],
                        [
                            -95.7006350000635,
                            33.8858450001515
                        ],
                        [
                            -95.712183000246,
                            33.8855190000809
                        ],
                        [
                            -95.7199799996197,
                            33.8909439999097
                        ],
                        [
                            -95.7331830001862,
                            33.8964470002869
                        ],
                        [
                            -95.7451310004018,
                            33.8966900000324
                        ],
                        [
                            -95.7521529998237,
                            33.8945769998557
                        ],
                        [
                            -95.7579409997095,
                            33.8907519996879
                        ],
                        [
                            -95.7624669995718,
                            33.8811439999317
                        ],
                        [
                            -95.7615200000909,
                            33.8731179998295
                        ],
                        [
                            -95.760056000401,
                            33.873817000223
                        ],
                        [
                            -95.7513419998869,
                            33.8602869997709
                        ],
                        [
                            -95.7521090003411,
                            33.8549529996874
                        ],
                        [
                            -95.7579590001512,
                            33.8495660003304
                        ],
                        [
                            -95.7702389997295,
                            33.8452019998613
                        ],
                        [
                            -95.7976140000847,
                            33.860589999906
                        ],
                        [
                            -95.8021840003279,
                            33.8615619996931
                        ],
                        [
                            -95.815571000119,
                            33.8598529999916
                        ],
                        [
                            -95.8209110002912,
                            33.8561719997802
                        ],
                        [
                            -95.8188809999886,
                            33.8466630002951
                        ],
                        [
                            -95.819486000061,
                            33.8412960003521
                        ],
                        [
                            -95.8236989999289,
                            33.8374059997851
                        ],
                        [
                            -95.8304059997065,
                            33.8347849999972
                        ],
                        [
                            -95.8439349997879,
                            33.8381979998022
                        ],
                        [
                            -95.8568320000326,
                            33.8501409997021
                        ],
                        [
                            -95.8671890003367,
                            33.8512899998259
                        ],
                        [
                            -95.8868329996437,
                            33.8649350003642
                        ],
                        [
                            -95.8952149998968,
                            33.867580000109
                        ],
                        [
                            -95.9250890001846,
                            33.8849359999622
                        ],
                        [
                            -95.9322090004142,
                            33.8838820000427
                        ],
                        [
                            -95.9364039998403,
                            33.8714240002682
                        ],
                        [
                            -95.941795999891,
                            33.8616510002757
                        ],
                        [
                            -95.944140000013,
                            33.8599980002749
                        ],
                        [
                            -95.9486759999211,
                            33.8580410000994
                        ],
                        [
                            -95.9513820004413,
                            33.8573319996488
                        ],
                        [
                            -95.9523830003489,
                            33.8573099997838
                        ],
                        [
                            -95.9473269998589,
                            33.8589150000562
                        ],
                        [
                            -95.9746290004191,
                            33.8554639998682
                        ],
                        [
                            -95.9848560001286,
                            33.8524920002383
                        ],
                        [
                            -95.9860400003343,
                            33.8569310000992
                        ],
                        [
                            -95.9840590004355,
                            33.8601180001689
                        ],
                        [
                            -95.9849270002737,
                            33.8658050002972
                        ],
                        [
                            -96.0029250003159,
                            33.8735900000453
                        ],
                        [
                            -96.0045730001288,
                            33.8707119999246
                        ],
                        [
                            -96.0028750000872,
                            33.866669999775
                        ],
                        [
                            -95.9996940000891,
                            33.8625519998857
                        ],
                        [
                            -95.9948689995778,
                            33.8618240003381
                        ],
                        [
                            -95.9968329997582,
                            33.8538979998574
                        ],
                        [
                            -95.9983509998747,
                            33.8510499996803
                        ],
                        [
                            -96.0044729996714,
                            33.8468360001286
                        ],
                        [
                            -96.0040500000721,
                            33.8452369997557
                        ],
                        [
                            -96.0109069996376,
                            33.8442959996511
                        ],
                        [
                            -96.0170550002718,
                            33.8414539997791
                        ],
                        [
                            -96.0227360000275,
                            33.8410919996492
                        ],
                        [
                            -96.0230950000527,
                            33.8441280002659
                        ],
                        [
                            -96.0195419996106,
                            33.8534969998509
                        ],
                        [
                            -96.0224079999644,
                            33.8570420002262
                        ],
                        [
                            -96.0269680001619,
                            33.8574740001336
                        ],
                        [
                            -96.0305009996142,
                            33.855260999815
                        ],
                        [
                            -96.0308649996623,
                            33.8483630000534
                        ],
                        [
                            -96.0349880000167,
                            33.8426459996406
                        ],
                        [
                            -96.0488340002905,
                            33.8364680003408
                        ],
                        [
                            -96.0846260001605,
                            33.8466559998373
                        ],
                        [
                            -96.1000950001328,
                            33.8479709999869
                        ],
                        [
                            -96.1013489997604,
                            33.8457209997251
                        ],
                        [
                            -96.0976380000329,
                            33.83793599981
                        ],
                        [
                            -96.097448999887,
                            33.8327289999181
                        ],
                        [
                            -96.099360000364,
                            33.8304699996311
                        ],
                        [
                            -96.1099939997792,
                            33.8323959998485
                        ],
                        [
                            -96.1229489997502,
                            33.8399639997
                        ],
                        [
                            -96.1278179997442,
                            33.8398959997034
                        ],
                        [
                            -96.1310580001918,
                            33.8374380003275
                        ],
                        [
                            -96.1389049997943,
                            33.8391590003078
                        ],
                        [
                            -96.1492269999383,
                            33.8370909997054
                        ],
                        [
                            -96.1516299996115,
                            33.8319459999725
                        ],
                        [
                            -96.1487919997451,
                            33.8191969998756
                        ],
                        [
                            -96.1507650001463,
                            33.8169869996318
                        ],
                        [
                            -96.1592949996391,
                            33.8185990003261
                        ],
                        [
                            -96.1665029997322,
                            33.8178669998338
                        ],
                        [
                            -96.1713530003579,
                            33.8147250002896
                        ],
                        [
                            -96.1763069998423,
                            33.8143749996576
                        ],
                        [
                            -96.1791220001425,
                            33.8102240000198
                        ],
                        [
                            -96.1757260000593,
                            33.8023210000084
                        ],
                        [
                            -96.1695919996687,
                            33.8009439998815
                        ],
                        [
                            -96.169949999869,
                            33.8051309997646
                        ],
                        [
                            -96.1637660001481,
                            33.8130510001051
                        ],
                        [
                            -96.1555379998808,
                            33.8140400002491
                        ],
                        [
                            -96.1598219998938,
                            33.8115500003477
                        ],
                        [
                            -96.1619540003037,
                            33.7997129998425
                        ],
                        [
                            -96.1607559998542,
                            33.7959100003415
                        ],
                        [
                            -96.1637130004446,
                            33.7877280000553
                        ],
                        [
                            -96.1627750002862,
                            33.7864209997765
                        ],
                        [
                            -96.1690900004267,
                            33.7704170003408
                        ],
                        [
                            -96.1786030002837,
                            33.7604259999343
                        ],
                        [
                            -96.1877320000012,
                            33.7560970003041
                        ],
                        [
                            -96.1866270003365,
                            33.7550209999111
                        ],
                        [
                            -96.1901659996366,
                            33.7555699998798
                        ],
                        [
                            -96.2205219999733,
                            33.7473900000037
                        ],
                        [
                            -96.229023000052,
                            33.7480210000431
                        ],
                        [
                            -96.2635930000814,
                            33.7674670002312
                        ],
                        [
                            -96.2870829997335,
                            33.7712429997348
                        ],
                        [
                            -96.2937080003937,
                            33.7694089998228
                        ],
                        [
                            -96.2964120003658,
                            33.7668330001362
                        ],
                        [
                            -96.2987609996124,
                            33.7609050003367
                        ],
                        [
                            -96.2988269997346,
                            33.7585480002878
                        ],
                        [
                            -96.2977940000399,
                            33.7612130001419
                        ],
                        [
                            -96.2981100004074,
                            33.7591249999468
                        ],
                        [
                            -96.3061629998742,
                            33.7440320000206
                        ],
                        [
                            -96.3078740003346,
                            33.7350219997045
                        ],
                        [
                            -96.3063639997156,
                            33.7230619996653
                        ],
                        [
                            -96.3122300003176,
                            33.7063039999938
                        ],
                        [
                            -96.3210979999191,
                            33.6951020001616
                        ],
                        [
                            -96.3329170002633,
                            33.6931579997494
                        ],
                        [
                            -96.3387560002028,
                            33.6883159998371
                        ],
                        [
                            -96.3468589998982,
                            33.6862160000959
                        ],
                        [
                            -96.3556339996134,
                            33.6874939998454
                        ],
                        [
                            -96.3621970003493,
                            33.6918179998617
                        ],
                        [
                            -96.3632519997853,
                            33.7010500002757
                        ],
                        [
                            -96.3695889996671,
                            33.7168089998908
                        ],
                        [
                            -96.4082200001267,
                            33.7505419997137
                        ],
                        [
                            -96.4134079999629,
                            33.7577139999601
                        ],
                        [
                            -96.4189780002888,
                            33.7716370002985
                        ],
                        [
                            -96.4235319997402,
                            33.7764320000533
                        ],
                        [
                            -96.4319869997882,
                            33.7791049998337
                        ],
                        [
                            -96.4480449999395,
                            33.7810309999557
                        ],
                        [
                            -96.4591539997309,
                            33.7752320000945
                        ],
                        [
                            -96.4693650004451,
                            33.7757920001262
                        ],
                        [
                            -96.4863409997651,
                            33.7729649999894
                        ],
                        [
                            -96.5009839997319,
                            33.7728010003184
                        ],
                        [
                            -96.5108549997893,
                            33.7806079999559
                        ],
                        [
                            -96.5165809997508,
                            33.788278999645
                        ],
                        [
                            -96.5156889996231,
                            33.7951649997766
                        ],
                        [
                            -96.5178479997972,
                            33.8068579996738
                        ],
                        [
                            -96.5248650000946,
                            33.819526000352
                        ],
                        [
                            -96.5325130004037,
                            33.8228579999624
                        ],
                        [
                            -96.5319660000575,
                            33.8239890003107
                        ],
                        [
                            -96.5517039999742,
                            33.8184770001815
                        ],
                        [
                            -96.5510649995665,
                            33.8192519998051
                        ],
                        [
                            -96.5556540000763,
                            33.8193889998151
                        ],
                        [
                            -96.5571219999641,
                            33.817564999779
                        ],
                        [
                            -96.558108999628,
                            33.8171310001758
                        ],
                        [
                            -96.556213000118,
                            33.8191419999106
                        ],
                        [
                            -96.5729370004412,
                            33.8190979999132
                        ],
                        [
                            -96.592926000428,
                            33.8309150001656
                        ],
                        [
                            -96.6231470001472,
                            33.8414959998515
                        ],
                        [
                            -96.6292739999667,
                            33.8455000002519
                        ],
                        [
                            -96.6290220000716,
                            33.852407999693
                        ],
                        [
                            -96.6119699996854,
                            33.8690159998901
                        ],
                        [
                            -96.5973479996349,
                            33.8751010001459
                        ],
                        [
                            -96.5901119999527,
                            33.8806649998235
                        ],
                        [
                            -96.5853600001346,
                            33.8889480001878
                        ],
                        [
                            -96.5879340004104,
                            33.8947840000792
                        ],
                        [
                            -96.6282939999755,
                            33.8944770000613
                        ],
                        [
                            -96.658206999723,
                            33.9158410002223
                        ],
                        [
                            -96.6671870003759,
                            33.9169400002294
                        ],
                        [
                            -96.6734489999147,
                            33.9122779997838
                        ],
                        [
                            -96.6809470004359,
                            33.8962039998491
                        ],
                        [
                            -96.683463999912,
                            33.8842170001226
                        ],
                        [
                            -96.6822089995612,
                            33.8738760000027
                        ],
                        [
                            -96.6847269997604,
                            33.8629050003572
                        ],
                        [
                            -96.6907079999901,
                            33.8499590002457
                        ],
                        [
                            -96.6995739999418,
                            33.8390489999086
                        ],
                        [
                            -96.7044570001795,
                            33.8350210000404
                        ],
                        [
                            -96.7124219997827,
                            33.8316329997612
                        ],
                        [
                            -96.7615870000985,
                            33.8244070000933
                        ],
                        [
                            -96.7662340003346,
                            33.8254589998941
                        ],
                        [
                            -96.770674999808,
                            33.8296209998141
                        ],
                        [
                            -96.7767659996425,
                            33.8419760001725
                        ],
                        [
                            -96.7805690003299,
                            33.8600980001983
                        ],
                        [
                            -96.7834850000141,
                            33.8635339996745
                        ],
                        [
                            -96.7942759997882,
                            33.868885999746
                        ],
                        [
                            -96.8321570004103,
                            33.8748350000707
                        ],
                        [
                            -96.839774999684,
                            33.8683980003076
                        ],
                        [
                            -96.8415919995513,
                            33.8528940001595
                        ],
                        [
                            -96.8458959996557,
                            33.848975000101
                        ],
                        [
                            -96.8505930001205,
                            33.8472110001393
                        ],
                        [
                            -96.8560899997532,
                            33.8474900000343
                        ],
                        [
                            -96.8602470004428,
                            33.8496889998376
                        ],
                        [
                            -96.875281000222,
                            33.8605050002819
                        ],
                        [
                            -96.8830099998237,
                            33.8680189998615
                        ],
                        [
                            -96.8957279999682,
                            33.8964140003233
                        ],
                        [
                            -96.8971940002062,
                            33.902954000271
                        ],
                        [
                            -96.8964689995848,
                            33.9133180002227
                        ],
                        [
                            -96.9009960001703,
                            33.9387839997671
                        ],
                        [
                            -96.9073869995805,
                            33.9500250002639
                        ],
                        [
                            -96.9163000002862,
                            33.9577979999719
                        ],
                        [
                            -96.9242680002625,
                            33.9591589998953
                        ],
                        [
                            -96.9517819996365,
                            33.9447439996306
                        ],
                        [
                            -96.9728700003385,
                            33.9356979999686
                        ],
                        [
                            -96.9769550001598,
                            33.9374529996385
                        ],
                        [
                            -96.9798180001406,
                            33.9415880000384
                        ],
                        [
                            -96.9810309997603,
                            33.9491599997239
                        ],
                        [
                            -96.979290000061,
                            33.955968999887
                        ],
                        [
                            -96.9813370000821,
                            33.9563779998648
                        ],
                        [
                            -96.9908349998705,
                            33.9527010000514
                        ],
                        [
                            -96.9946739996445,
                            33.9486810002421
                        ],
                        [
                            -96.9962510002106,
                            33.9426639999282
                        ],
                        [
                            -96.995022999624,
                            33.9320349999645
                        ],
                        [
                            -96.9878749998054,
                            33.9170460000049
                        ],
                        [
                            -96.9853259996439,
                            33.9066070002723
                        ],
                        [
                            -96.9838350001899,
                            33.8909489997738
                        ],
                        [
                            -96.9970880000867,
                            33.8710170000238
                        ],
                        [
                            -97.0098559995617,
                            33.8597619998424
                        ],
                        [
                            -97.0214380000794,
                            33.8463789997009
                        ],
                        [
                            -97.0418320001222,
                            33.8377560001701
                        ],
                        [
                            -97.0461260001809,
                            33.8395599997164
                        ],
                        [
                            -97.0565460002342,
                            33.8564759999727
                        ],
                        [
                            -97.0670399999073,
                            33.8605140002637
                        ],
                        [
                            -97.0827080000713,
                            33.8560699997185
                        ],
                        [
                            -97.0884979996069,
                            33.8518490000964
                        ],
                        [
                            -97.0905379999553,
                            33.8452149997598
                        ],
                        [
                            -97.0872039997963,
                            33.8413679999572
                        ],
                        [
                            -97.0622820002414,
                            33.8379899997773
                        ],
                        [
                            -97.0519490002268,
                            33.8329930000127
                        ],
                        [
                            -97.0483149995938,
                            33.8278699998667
                        ],
                        [
                            -97.0481460004378,
                            33.817456000041
                        ],
                        [
                            -97.0791050002988,
                            33.8123170003531
                        ],
                        [
                            -97.0926460000757,
                            33.8041979997897
                        ],
                        [
                            -97.0952379998948,
                            33.7984039997607
                        ],
                        [
                            -97.0950980001528,
                            33.7921720001205
                        ],
                        [
                            -97.0865679997617,
                            33.7706949999753
                        ],
                        [
                            -97.084801999948,
                            33.7615999998218
                        ],
                        [
                            -97.0849440002382,
                            33.7523279996681
                        ],
                        [
                            -97.0873650003531,
                            33.7411389999763
                        ],
                        [
                            -97.0967479996155,
                            33.7281060001007
                        ],
                        [
                            -97.1071910001333,
                            33.7211620002004
                        ],
                        [
                            -97.1206850000546,
                            33.7172109998799
                        ],
                        [
                            -97.1339150003852,
                            33.7181279999709
                        ],
                        [
                            -97.1543670001543,
                            33.7240940002821
                        ],
                        [
                            -97.1631450002425,
                            33.7293289996312
                        ],
                        [
                            -97.1732869995631,
                            33.7389879997409
                        ],
                        [
                            -97.1746299997775,
                            33.7450350000043
                        ],
                        [
                            -97.180143000202,
                            33.7544949996703
                        ],
                        [
                            -97.1914539996598,
                            33.757966000302
                        ],
                        [
                            -97.1928510003008,
                            33.7603620002251
                        ],
                        [
                            -97.1877239996657,
                            33.769830999967
                        ],
                        [
                            -97.1908090003026,
                            33.7764360000798
                        ],
                        [
                            -97.1893830002476,
                            33.7761380002068
                        ],
                        [
                            -97.1903969996757,
                            33.7811529997299
                        ],
                        [
                            -97.1947860001689,
                            33.7853439996399
                        ],
                        [
                            -97.2054310003531,
                            33.8014879998328
                        ],
                        [
                            -97.204995000335,
                            33.8188699997676
                        ],
                        [
                            -97.1998009997527,
                            33.8274049997378
                        ],
                        [
                            -97.1958320002824,
                            33.8308050003497
                        ],
                        [
                            -97.1813700000655,
                            33.8313750002369
                        ],
                        [
                            -97.1716270000547,
                            33.835334999663
                        ],
                        [
                            -97.166824000183,
                            33.8403950003508
                        ],
                        [
                            -97.1666290001893,
                            33.8473110001128
                        ],
                        [
                            -97.1769749997244,
                            33.8766989998743
                        ],
                        [
                            -97.1764259997284,
                            33.8790809998577
                        ],
                        [
                            -97.1808450003589,
                            33.8952040000476
                        ],
                        [
                            -97.1865839998409,
                            33.9015479996928
                        ],
                        [
                            -97.2109210003131,
                            33.9160640003281
                        ],
                        [
                            -97.2284620004209,
                            33.9132540000919
                        ],
                        [
                            -97.2315870003425,
                            33.9102229999822
                        ],
                        [
                            -97.2415360002176,
                            33.9069109997574
                        ],
                        [
                            -97.2450489995784,
                            33.9032159998468
                        ],
                        [
                            -97.2492089997427,
                            33.8751010001459
                        ],
                        [
                            -97.2556390004093,
                            33.8637019996828
                        ],
                        [
                            -97.265086999969,
                            33.8624129998152
                        ],
                        [
                            -97.2709329995811,
                            33.8580009999265
                        ],
                        [
                            -97.2728690001724,
                            33.8616740000843
                        ],
                        [
                            -97.2853930001482,
                            33.8682440002053
                        ],
                        [
                            -97.3091720004036,
                            33.888698000015
                        ],
                        [
                            -97.3111919997622,
                            33.8870890003381
                        ],
                        [
                            -97.309441999842,
                            33.8801469998706
                        ],
                        [
                            -97.3099459996322,
                            33.8744490001919
                        ],
                        [
                            -97.3142570003076,
                            33.8650889997223
                        ],
                        [
                            -97.3163889998192,
                            33.8657749997269
                        ],
                        [
                            -97.3260980003931,
                            33.8570829997259
                        ],
                        [
                            -97.3320629998309,
                            33.8550559999844
                        ],
                        [
                            -97.3346160001903,
                            33.8572309998485
                        ],
                        [
                            -97.334177999624,
                            33.8628879997066
                        ],
                        [
                            -97.3280569996523,
                            33.8702629999353
                        ],
                        [
                            -97.3233399999943,
                            33.8791700000751
                        ],
                        [
                            -97.326608000031,
                            33.8844139996399
                        ],
                        [
                            -97.3318800004311,
                            33.8844830000371
                        ],
                        [
                            -97.3359150000237,
                            33.8791679998534
                        ],
                        [
                            -97.3396409998198,
                            33.8607340001029
                        ],
                        [
                            -97.347212999961,
                            33.8451130000438
                        ],
                        [
                            -97.3543180001219,
                            33.8379649998658
                        ],
                        [
                            -97.3585130004464,
                            33.830018000183
                        ],
                        [
                            -97.368730999935,
                            33.82144299971
                        ],
                        [
                            -97.3746900004234,
                            33.8185519998261
                        ],
                        [
                            -97.3951289997737,
                            33.819962999917
                        ],
                        [
                            -97.4136919997055,
                            33.8193040002488
                        ],
                        [
                            -97.4141550003861,
                            33.8179769998754
                        ],
                        [
                            -97.4267990000125,
                            33.8186409996871
                        ],
                        [
                            -97.4441929998071,
                            33.823772999933
                        ],
                        [
                            -97.453057000109,
                            33.8285359996422
                        ],
                        [
                            -97.4590679995776,
                            33.8345810001684
                        ],
                        [
                            -97.4632419999857,
                            33.8420039998033
                        ],
                        [
                            -97.4614860002177,
                            33.8495599998222
                        ],
                        [
                            -97.4559150000669,
                            33.8576460000895
                        ],
                        [
                            -97.4539679996049,
                            33.8666929997234
                        ],
                        [
                            -97.4514689996722,
                            33.8709299999232
                        ],
                        [
                            -97.4508069996983,
                            33.8866080000012
                        ],
                        [
                            -97.4510579997685,
                            33.8916760000063
                        ],
                        [
                            -97.4614200000955,
                            33.9052299998726
                        ],
                        [
                            -97.463564000201,
                            33.9049550000292
                        ],
                        [
                            -97.4877159998269,
                            33.9175079998356
                        ],
                        [
                            -97.5002709997648,
                            33.9196349998326
                        ],
                        [
                            -97.5252769998836,
                            33.9117509996855
                        ],
                        [
                            -97.5342070003078,
                            33.9057300002295
                        ],
                        [
                            -97.551540999828,
                            33.8979470001978
                        ],
                        [
                            -97.5582700002453,
                            33.897098999909
                        ],
                        [
                            -97.5627879997116,
                            33.9009250000883
                        ],
                        [
                            -97.5669560002719,
                            33.9015830003482
                        ],
                        [
                            -97.5723490001475,
                            33.8992629998483
                        ],
                        [
                            -97.5810779998319,
                            33.8996789998982
                        ],
                        [
                            -97.5892540002207,
                            33.9039219999262
                        ],
                        [
                            -97.5899139996464,
                            33.9080439998296
                        ],
                        [
                            -97.593816000068,
                            33.9102649997733
                        ],
                        [
                            -97.5969500002104,
                            33.9163110002621
                        ],
                        [
                            -97.5969789996244,
                            33.9202279997932
                        ],
                        [
                            -97.591211999655,
                            33.929642999943
                        ],
                        [
                            -97.5950339997106,
                            33.9373599999381
                        ],
                        [
                            -97.5954200003982,
                            33.9414359996919
                        ],
                        [
                            -97.5888270004234,
                            33.951882000036
                        ],
                        [
                            -97.5895970003523,
                            33.9535539998222
                        ],
                        [
                            -97.5979800004303,
                            33.9552470002671
                        ],
                        [
                            -97.6009789999552,
                            33.9607890002982
                        ],
                        [
                            -97.6090909998715,
                            33.9680929999003
                        ],
                        [
                            -97.6160749996586,
                            33.9697159996708
                        ],
                        [
                            -97.6337780001481,
                            33.981256999709
                        ],
                        [
                            -97.6572550002796,
                            33.9888240000727
                        ],
                        [
                            -97.6562099999911,
                            33.9894880000872
                        ],
                        [
                            -97.6712770002805,
                            33.9915530000018
                        ],
                        [
                            -97.6860159996086,
                            33.9878900000384
                        ],
                        [
                            -97.6931099998988,
                            33.9836990002463
                        ],
                        [
                            -97.6991849998398,
                            33.9757049996816
                        ],
                        [
                            -97.7041590003141,
                            33.9633359997615
                        ],
                        [
                            -97.7121940002375,
                            33.9516409997143
                        ],
                        [
                            -97.7206040000796,
                            33.9439549997098
                        ],
                        [
                            -97.7322610000421,
                            33.9365190003107
                        ],
                        [
                            -97.7529499996377,
                            33.9371020002134
                        ],
                        [
                            -97.7627679999916,
                            33.934396000132
                        ],
                        [
                            -97.7593989996725,
                            33.9188200000965
                        ],
                        [
                            -97.7637699997241,
                            33.9142410000836
                        ],
                        [
                            -97.7726719996608,
                            33.9143820001078
                        ],
                        [
                            -97.7783010004363,
                            33.9196289997779
                        ],
                        [
                            -97.7835219998844,
                            33.9183020000502
                        ],
                        [
                            -97.7861329999701,
                            33.9134240000308
                        ],
                        [
                            -97.7803400000614,
                            33.9048330001861
                        ],
                        [
                            -97.7808389998287,
                            33.8944469998765
                        ],
                        [
                            -97.7853170000104,
                            33.8907010001972
                        ],
                        [
                            -97.7881449998311,
                            33.8920759996865
                        ],
                        [
                            -97.79240000043,
                            33.8990850002357
                        ],
                        [
                            -97.7947899996845,
                            33.8999560000908
                        ],
                        [
                            -97.7989099996659,
                            33.8985370000507
                        ],
                        [
                            -97.8016300004299,
                            33.8958799996794
                        ],
                        [
                            -97.801577999653,
                            33.8851380002613
                        ],
                        [
                            -97.8054230001731,
                            33.8771669998855
                        ],
                        [
                            -97.8089380000821,
                            33.8767089997803
                        ],
                        [
                            -97.8138530001068,
                            33.8819309997107
                        ],
                        [
                            -97.8176430003753,
                            33.8809919998865
                        ],
                        [
                            -97.8192359999366,
                            33.8790910002303
                        ],
                        [
                            -97.8189310003381,
                            33.8740030002372
                        ],
                        [
                            -97.8207430001825,
                            33.8688950003361
                        ],
                        [
                            -97.8311320002737,
                            33.8617410003346
                        ],
                        [
                            -97.8343199999445,
                            33.8576340002079
                        ],
                        [
                            -97.843776999725,
                            33.8568130002025
                        ],
                        [
                            -97.8641749999658,
                            33.8497450000035
                        ],
                        [
                            -97.8753110004196,
                            33.8497210002567
                        ],
                        [
                            -97.888720999777,
                            33.8567230001717
                        ],
                        [
                            -97.8967380001571,
                            33.8579850001505
                        ],
                        [
                            -97.9098579999845,
                            33.8672210003054
                        ],
                        [
                            -97.9285840001229,
                            33.8741870002534
                        ],
                        [
                            -97.9341689996191,
                            33.8781089996607
                        ],
                        [
                            -97.9389480000997,
                            33.8794660003065
                        ],
                        [
                            -97.9570640001427,
                            33.8787669999412
                        ],
                        [
                            -97.9677519999846,
                            33.8822139998288
                        ],
                        [
                            -97.9778599998684,
                            33.8898249998418
                        ],
                        [
                            -97.9843730003756,
                            33.8980239999515
                        ],
                        [
                            -97.9845399998819,
                            33.9007029997479
                        ],
                        [
                            -97.9788039998746,
                            33.9125479997698
                        ],
                        [
                            -97.9769629997179,
                            33.9125490002348
                        ],
                        [
                            -97.9731430002104,
                            33.9080139999448
                        ],
                        [
                            -97.9687470000445,
                            33.9060229998678
                        ],
                        [
                            -97.9616319998378,
                            33.9094139997201
                        ],
                        [
                            -97.9571550003793,
                            33.9144539999508
                        ],
                        [
                            -97.9526789998474,
                            33.9294819997286
                        ],
                        [
                            -97.953395000248,
                            33.9364450001143
                        ],
                        [
                            -97.9555109998661,
                            33.9381859999804
                        ],
                        [
                            -97.963425000314,
                            33.9362370002651
                        ],
                        [
                            -97.9711749998321,
                            33.9371289997619
                        ],
                        [
                            -97.9740620001023,
                            33.9402889997646
                        ],
                        [
                            -97.9741730004304,
                            33.9428319998332
                        ],
                        [
                            -97.960351000446,
                            33.9519280001622
                        ],
                        [
                            -97.9457300002205,
                            33.9898390001569
                        ],
                        [
                            -97.9475720002021,
                            33.9910530000876
                        ],
                        [
                            -97.9583250003414,
                            33.9908459999517
                        ],
                        [
                            -97.9741730004304,
                            34.0067159999906
                        ],
                        [
                            -97.9908609998702,
                            33.9988730001986
                        ],
                        [
                            -98.015101000258,
                            33.994136000147
                        ],
                        [
                            -98.0276720000895,
                            33.9933569999083
                        ],
                        [
                            -98.0411169996069,
                            33.9934560002841
                        ],
                        [
                            -98.0551970002324,
                            33.9958409998642
                        ],
                        [
                            -98.0828389996529,
                            34.0024119999825
                        ],
                        [
                            -98.0882030001145,
                            34.0054810002413
                        ],
                        [
                            -98.0881489996879,
                            34.0079320002058
                        ],
                        [
                            -98.082400999985,
                            34.0098339998834
                        ],
                        [
                            -98.0840250004068,
                            34.0143690000469
                        ],
                        [
                            -98.0895549996514,
                            34.0192699999206
                        ],
                        [
                            -98.0984420004179,
                            34.0239180000557
                        ],
                        [
                            -98.1036169998353,
                            34.0292070000419
                        ],
                        [
                            -98.1061790004155,
                            34.0348870001019
                        ],
                        [
                            -98.105025000347,
                            34.0375449996446
                        ],
                        [
                            -98.101695000386,
                            34.0390569997344
                        ],
                        [
                            -98.0965500002075,
                            34.0380509996553
                        ],
                        [
                            -98.0917880003437,
                            34.0336540003023
                        ],
                        [
                            -98.0890369996176,
                            34.0338380000944
                        ],
                        [
                            -98.0838100003216,
                            34.0366560001231
                        ],
                        [
                            -98.0824629999093,
                            34.0394279999428
                        ],
                        [
                            -98.0874719996453,
                            34.0440999998769
                        ],
                        [
                            -98.1018929998544,
                            34.0509689997341
                        ],
                        [
                            -98.1145869997095,
                            34.0622799996953
                        ],
                        [
                            -98.120208000089,
                            34.0721270002845
                        ],
                        [
                            -98.1210390001173,
                            34.0812660002128
                        ],
                        [
                            -98.1194170002437,
                            34.0844740000674
                        ],
                        [
                            -98.0993279997995,
                            34.1042949999741
                        ],
                        [
                            -98.0924210000052,
                            34.1169170000148
                        ],
                        [
                            -98.089754999668,
                            34.1282109997592
                        ],
                        [
                            -98.0923730003247,
                            34.1321500001263
                        ],
                        [
                            -98.1004979997615,
                            34.1313690002336
                        ],
                        [
                            -98.1028949995869,
                            34.132605000078
                        ],
                        [
                            -98.1005589998608,
                            34.1434860003068
                        ],
                        [
                            -98.1031780003425,
                            34.148318000352
                        ],
                        [
                            -98.1094619996225,
                            34.1541109998965
                        ],
                        [
                            -98.1233770003916,
                            34.154540000098
                        ],
                        [
                            -98.130815999565,
                            34.1505319996405
                        ],
                        [
                            -98.1543539997959,
                            34.1227340002687
                        ],
                        [
                            -98.1708270000489,
                            34.1141709999307
                        ],
                        [
                            -98.2017280001837,
                            34.1170579999751
                        ],
                        [
                            -98.209219000134,
                            34.1209970001465
                        ],
                        [
                            -98.2164630002121,
                            34.1218210002763
                        ],
                        [
                            -98.22360000016,
                            34.1250929998763
                        ],
                        [
                            -98.2255579995943,
                            34.129282999956
                        ],
                        [
                            -98.2324739996094,
                            34.1346409998482
                        ],
                        [
                            -98.2391410001024,
                            34.1345470003639
                        ],
                        [
                            -98.2479540003506,
                            34.1307169999909
                        ],
                        [
                            -98.256467000125,
                            34.1294810000519
                        ],
                        [
                            -98.2647289998291,
                            34.1319129996439
                        ],
                        [
                            -98.2803209998251,
                            34.1307499997272
                        ],
                        [
                            -98.2939009999601,
                            34.1330200002758
                        ],
                        [
                            -98.3002090004279,
                            34.1345790000102
                        ],
                        [
                            -98.3254449998021,
                            34.1510249996661
                        ],
                        [
                            -98.3656210001425,
                            34.1569930001459
                        ],
                        [
                            -98.3709360002003,
                            34.1518600001028
                        ],
                        [
                            -98.3812380002528,
                            34.1494539996867
                        ],
                        [
                            -98.3843809997178,
                            34.1463170002563
                        ],
                        [
                            -98.3868649995818,
                            34.1388730000965
                        ],
                        [
                            -98.3804209995699,
                            34.1342899996977
                        ],
                        [
                            -98.3800930004052,
                            34.1308310003095
                        ],
                        [
                            -98.3829589998607,
                            34.1295490000154
                        ],
                        [
                            -98.3894029998726,
                            34.1332169996994
                        ],
                        [
                            -98.394031999667,
                            34.1319129996439
                        ],
                        [
                            -98.3984410002518,
                            34.1284559999572
                        ],
                        [
                            -98.4009669999487,
                            34.1222359998724
                        ],
                        [
                            -98.3981600000444,
                            34.1213959999856
                        ],
                        [
                            -98.3983890003732,
                            34.1045659997461
                        ],
                        [
                            -98.3997769998952,
                            34.0999730003136
                        ],
                        [
                            -98.4021579998271,
                            34.0986530001924
                        ],
                        [
                            -98.4077270003281,
                            34.1013690003218
                        ],
                        [
                            -98.4113739995832,
                            34.1003240001011
                        ],
                        [
                            -98.4097230002955,
                            34.0925090000224
                        ],
                        [
                            -98.4144259997098,
                            34.0850739999708
                        ],
                        [
                            -98.4204079997644,
                            34.0823139997346
                        ],
                        [
                            -98.428480000396,
                            34.0855229999802
                        ],
                        [
                            -98.4400920001527,
                            34.0843110001243
                        ],
                        [
                            -98.4437240002375,
                            34.0821520000554
                        ],
                        [
                            -98.4463789998058,
                            34.075430000165
                        ],
                        [
                            -98.4490340002724,
                            34.0734619997077
                        ],
                        [
                            -98.4651330004316,
                            34.0737289997464
                        ],
                        [
                            -98.4714650002905,
                            34.0656650001101
                        ],
                        [
                            -98.4807129998337,
                            34.0624989998377
                        ],
                        [
                            -98.4863280003654,
                            34.0625979999507
                        ],
                        [
                            -98.4946129996358,
                            34.0700900003009
                        ],
                        [
                            -98.5041489999573,
                            34.0722869996445
                        ],
                        [
                            -98.5168749995995,
                            34.0859840002783
                        ],
                        [
                            -98.5282000001993,
                            34.0949609996453
                        ],
                        [
                            -98.536256999864,
                            34.107346999999
                        ],
                        [
                            -98.5509170004477,
                            34.1193340001285
                        ],
                        [
                            -98.5557470000836,
                            34.120133999993
                        ],
                        [
                            -98.5609769997525,
                            34.1239219996554
                        ],
                        [
                            -98.5585930003459,
                            34.1282539998089
                        ],
                        [
                            -98.551844999662,
                            34.1271310002259
                        ],
                        [
                            -98.5503569996827,
                            34.1292390002849
                        ],
                        [
                            -98.5508799997394,
                            34.1325829999945
                        ],
                        [
                            -98.5601909999301,
                            34.133201999718
                        ],
                        [
                            -98.5666090000027,
                            34.1374400001899
                        ],
                        [
                            -98.5771360001862,
                            34.1489620000677
                        ],
                        [
                            -98.5839140001089,
                            34.1512980003574
                        ],
                        [
                            -98.5997889999621,
                            34.1605710003673
                        ],
                        [
                            -98.6039780004388,
                            34.1602489996683
                        ],
                        [
                            -98.6118290002392,
                            34.1565580000959
                        ],
                        [
                            -98.6167330003933,
                            34.1564179996886
                        ],
                        [
                            -98.6432230002935,
                            34.1645309998096
                        ],
                        [
                            -98.6480730000208,
                            34.1644409997903
                        ],
                        [
                            -98.6709219996154,
                            34.1493729998558
                        ],
                        [
                            -98.6766259998356,
                            34.1429130000521
                        ],
                        [
                            -98.684120000159,
                            34.1387899997226
                        ],
                        [
                            -98.6900720000763,
                            34.133155000304
                        ],
                        [
                            -98.7030470001388,
                            34.1359029996647
                        ],
                        [
                            -98.7124670001094,
                            34.1311600002428
                        ],
                        [
                            -98.7175369999448,
                            34.136449999813
                        ],
                        [
                            -98.7236260001295,
                            34.13518900006
                        ],
                        [
                            -98.7342870002072,
                            34.1357579996312
                        ],
                        [
                            -98.7419659995802,
                            34.1255300002104
                        ],
                        [
                            -98.7570370000676,
                            34.1246330001373
                        ],
                        [
                            -98.7596530001761,
                            34.1269119999311
                        ],
                        [
                            -98.7605579998243,
                            34.1323880000519
                        ],
                        [
                            -98.7655699999334,
                            34.1363759998342
                        ],
                        [
                            -98.7780760003658,
                            34.1394949999193
                        ],
                        [
                            -98.7884050001825,
                            34.1466680002613
                        ],
                        [
                            -98.7920149996277,
                            34.1437359999581
                        ],
                        [
                            -98.7962030002795,
                            34.1427750000445
                        ],
                        [
                            -98.8044130001051,
                            34.1539309998491
                        ],
                        [
                            -98.8129540003668,
                            34.1584440000341
                        ],
                        [
                            -98.8311149997174,
                            34.1621540002298
                        ],
                        [
                            -98.8370659998098,
                            34.1564950001644
                        ],
                        [
                            -98.855585000259,
                            34.1616209996418
                        ],
                        [
                            -98.8579000000686,
                            34.1596270002706
                        ],
                        [
                            -98.8584189999274,
                            34.1527320000037
                        ],
                        [
                            -98.860125000365,
                            34.1499129997393
                        ],
                        [
                            -98.8681159999074,
                            34.1496350002579
                        ],
                        [
                            -98.8748720000889,
                            34.1556569999671
                        ],
                        [
                            -98.8712109996918,
                            34.1630119999824
                        ],
                        [
                            -98.8729220001522,
                            34.1665839996371
                        ],
                        [
                            -98.8871120003826,
                            34.1682600000276
                        ],
                        [
                            -98.8966160000188,
                            34.1744949998366
                        ],
                        [
                            -98.9093490002319,
                            34.177498999967
                        ],
                        [
                            -98.9183330001844,
                            34.1818309996299
                        ],
                        [
                            -98.9281449997922,
                            34.1926890002539
                        ],
                        [
                            -98.951283999815,
                            34.2121890002643
                        ],
                        [
                            -98.9599840000853,
                            34.2132719999366
                        ],
                        [
                            -98.9621890002902,
                            34.2111770001464
                        ],
                        [
                            -98.9629309997317,
                            34.2038330000541
                        ],
                        [
                            -98.9666259995657,
                            34.2011010001503
                        ],
                        [
                            -98.9726090003435,
                            34.2025910002636
                        ],
                        [
                            -98.9764699998588,
                            34.206197999638
                        ],
                        [
                            -98.9809170000784,
                            34.2170590000229
                        ],
                        [
                            -98.9881720000271,
                            34.2213099999604
                        ],
                        [
                            -98.9917029998296,
                            34.2214269999036
                        ],
                        [
                            -99.0005920002458,
                            34.2176720001363
                        ],
                        [
                            -99.0046949996105,
                            34.2141450003151
                        ],
                        [
                            -99.0022419997086,
                            34.2093739999669
                        ],
                        [
                            -99.012957000213,
                            34.2031940000031
                        ],
                        [
                            -99.0172710003632,
                            34.206313999789
                        ],
                        [
                            -99.0244650002125,
                            34.2039839998446
                        ],
                        [
                            -99.0361499997641,
                            34.2069010001544
                        ],
                        [
                            -99.0444630004202,
                            34.1981060000797
                        ],
                        [
                            -99.0579759997097,
                            34.2006099999508
                        ],
                        [
                            -99.0631049999947,
                            34.2068510002522
                        ],
                        [
                            -99.0793919995765,
                            34.2115499996741
                        ],
                        [
                            -99.0889229998752,
                            34.2084749997722
                        ],
                        [
                            -99.0962110003342,
                            34.2117050001415
                        ],
                        [
                            -99.1021859998178,
                            34.2054830001273
                        ],
                        [
                            -99.1082010003826,
                            34.2035469997883
                        ],
                        [
                            -99.111003000264,
                            34.2045999999611
                        ],
                        [
                            -99.1084170002927,
                            34.2052710000999
                        ],
                        [
                            -99.1118069996298,
                            34.2059800000496
                        ],
                        [
                            -99.1192540000975,
                            34.2018090002258
                        ],
                        [
                            -99.1266399995675,
                            34.2030639998277
                        ],
                        [
                            -99.1319859995874,
                            34.2074359999261
                        ],
                        [
                            -99.1300120002596,
                            34.2122629999091
                        ],
                        [
                            -99.1264949998026,
                            34.2153960002539
                        ],
                        [
                            -99.1273939996029,
                            34.218054999727
                        ],
                        [
                            -99.1304430002548,
                            34.2194779997582
                        ],
                        [
                            -99.138075999597,
                            34.2192329999717
                        ],
                        [
                            -99.1487209997813,
                            34.2087699999172
                        ],
                        [
                            -99.1536530004227,
                            34.2073280002805
                        ],
                        [
                            -99.1897759998305,
                            34.2143570003065
                        ],
                        [
                            -99.1928110002387,
                            34.2188879998677
                        ],
                        [
                            -99.1899859998928,
                            34.2297560001574
                        ],
                        [
                            -99.1969119999536,
                            34.2443819999788
                        ],
                        [
                            -99.1961989999261,
                            34.2493850000217
                        ],
                        [
                            -99.1914219999936,
                            34.2578300001729
                        ],
                        [
                            -99.1938380000856,
                            34.2603070002757
                        ],
                        [
                            -99.1992540004257,
                            34.260791000242
                        ],
                        [
                            -99.1944590000516,
                            34.2718810002213
                        ],
                        [
                            -99.1954030000578,
                            34.2808489998439
                        ],
                        [
                            -99.2073520000984,
                            34.2835250001993
                        ],
                        [
                            -99.2114479997903,
                            34.2922469999891
                        ],
                        [
                            -99.2096950003954,
                            34.2989039997894
                        ],
                        [
                            -99.2133020003659,
                            34.3106729998199
                        ],
                        [
                            -99.2056280001175,
                            34.3192009999126
                        ],
                        [
                            -99.2095740000216,
                            34.3249250003101
                        ],
                        [
                            -99.2108200001516,
                            34.3368259997411
                        ],
                        [
                            -99.2069050002096,
                            34.3382769999618
                        ],
                        [
                            -99.2172030000642,
                            34.3415080000994
                        ],
                        [
                            -99.2271169997792,
                            34.3377670002183
                        ],
                        [
                            -99.2326319998535,
                            34.3388720000701
                        ],
                        [
                            -99.234046000213,
                            34.3421729997606
                        ],
                        [
                            -99.2279770001198,
                            34.349464000176
                        ],
                        [
                            -99.2341349999015,
                            34.353464000326
                        ],
                        [
                            -99.2371409999974,
                            34.3610949998221
                        ],
                        [
                            -99.2364940000921,
                            34.3658050000758
                        ],
                        [
                            -99.2375619999469,
                            34.3667010002799
                        ],
                        [
                            -99.2444539996725,
                            34.3637010002237
                        ],
                        [
                            -99.2463929997385,
                            34.3643969998874
                        ],
                        [
                            -99.2479419998172,
                            34.3684869999729
                        ],
                        [
                            -99.2435430001765,
                            34.3707110003179
                        ],
                        [
                            -99.2428550002633,
                            34.3726760003272
                        ],
                        [
                            -99.2488840001736,
                            34.3759950000813
                        ],
                        [
                            -99.2546310000515,
                            34.3724190001082
                        ],
                        [
                            -99.258606000268,
                            34.3726499997553
                        ],
                        [
                            -99.2597670000092,
                            34.3775999999916
                        ],
                        [
                            -99.2697960002502,
                            34.3808429998572
                        ],
                        [
                            -99.2744149999989,
                            34.3843489999174
                        ],
                        [
                            -99.2752750003395,
                            34.3866160003101
                        ],
                        [
                            -99.273893999592,
                            34.3875770001847
                        ],
                        [
                            -99.2644419998344,
                            34.388098999766
                        ],
                        [
                            -99.2589169997143,
                            34.3912550000579
                        ],
                        [
                            -99.2618220004262,
                            34.3950359997354
                        ],
                        [
                            -99.26127500008,
                            34.4035079998585
                        ],
                        [
                            -99.2663080001055,
                            34.4054809996538
                        ],
                        [
                            -99.2723029996805,
                            34.4008120002492
                        ],
                        [
                            -99.280044999701,
                            34.4006550003091
                        ],
                        [
                            -99.2831699996226,
                            34.4029459997076
                        ],
                        [
                            -99.2828430002827,
                            34.4074799997526
                        ],
                        [
                            -99.2855859997146,
                            34.4123579998966
                        ],
                        [
                            -99.2946319995914,
                            34.4153809997875
                        ],
                        [
                            -99.3092570000149,
                            34.4097039997744
                        ],
                        [
                            -99.3196019997252,
                            34.4088790000719
                        ],
                        [
                            -99.3286979998307,
                            34.4223829999248
                        ],
                        [
                            -99.3340899998813,
                            34.4275249999654
                        ],
                        [
                            -99.3401299996622,
                            34.4301020001089
                        ],
                        [
                            -99.3569030003892,
                            34.4420819998327
                        ],
                        [
                            -99.3569560000926,
                            34.4464809999834
                        ],
                        [
                            -99.354849999622,
                            34.4505389999127
                        ],
                        [
                            -99.3557049999398,
                            34.4530819999436
                        ],
                        [
                            -99.3589699996034,
                            34.4558069999611
                        ],
                        [
                            -99.3689319998973,
                            34.4584610000205
                        ],
                        [
                            -99.3755499999865,
                            34.4587890000892
                        ],
                        [
                            -99.3812039999781,
                            34.4568790000176
                        ],
                        [
                            -99.3892270002059,
                            34.4468630000896
                        ],
                        [
                            -99.3951869996209,
                            34.4420300003194
                        ],
                        [
                            -99.3961149997336,
                            34.4349700002708
                        ],
                        [
                            -99.3930490002615,
                            34.4319210003467
                        ],
                        [
                            -99.3933060001795,
                            34.4276130002627
                        ],
                        [
                            -99.3972670001522,
                            34.4239240001291
                        ],
                        [
                            -99.3971640002201,
                            34.4186069998393
                        ],
                        [
                            -99.3871810000098,
                            34.4108420000522
                        ],
                        [
                            -99.3956760002407,
                            34.3976899996815
                        ],
                        [
                            -99.3973550000158,
                            34.3778680001553
                        ],
                        [
                            -99.4003340003475,
                            34.3746230003515
                        ],
                        [
                            -99.4052860001821,
                            34.3728579996563
                        ],
                        [
                            -99.4101950003591,
                            34.3734020000356
                        ],
                        [
                            -99.4141589998065,
                            34.3777729997804
                        ],
                        [
                            -99.4207140001466,
                            34.3803859998
                        ],
                        [
                            -99.421908000398,
                            34.3842010002449
                        ],
                        [
                            -99.4248969998772,
                            34.3858229996595
                        ],
                        [
                            -99.4274970000922,
                            34.385136000008
                        ],
                        [
                            -99.4300100002686,
                            34.3810189999845
                        ],
                        [
                            -99.4293240000053,
                            34.3758340003085
                        ],
                        [
                            -99.4311180003063,
                            34.3740029997241
                        ],
                        [
                            -99.4310429999633,
                            34.3711170001596
                        ],
                        [
                            -99.4382689995997,
                            34.3711640003082
                        ],
                        [
                            -99.4537869999758,
                            34.3886630003503
                        ],
                        [
                            -99.4554719995987,
                            34.3860969998202
                        ],
                        [
                            -99.4550849999844,
                            34.3812639998197
                        ],
                        [
                            -99.4610529997952,
                            34.3806659999752
                        ],
                        [
                            -99.4624620001319,
                            34.3911370000263
                        ],
                        [
                            -99.4663829999216,
                            34.3959000003443
                        ],
                        [
                            -99.4703060002596,
                            34.3967479997632
                        ],
                        [
                            -99.4737890003814,
                            34.3953499996501
                        ],
                        [
                            -99.4872189998303,
                            34.3979550002679
                        ],
                        [
                            -99.4989989998164,
                            34.4091729997672
                        ],
                        [
                            -99.5069030002186,
                            34.4095200001954
                        ],
                        [
                            -99.5142800003661,
                            34.4140349996645
                        ],
                        [
                            -99.5188949999168,
                            34.4142889996855
                        ],
                        [
                            -99.5297860001483,
                            34.4114520000453
                        ],
                        [
                            -99.5415309999744,
                            34.4132409996955
                        ],
                        [
                            -99.5492420000327,
                            34.412715000107
                        ],
                        [
                            -99.5696960003499,
                            34.4184179999813
                        ],
                        [
                            -99.5800600003267,
                            34.4166530000317
                        ],
                        [
                            -99.5844799998838,
                            34.4076729998264
                        ],
                        [
                            -99.583483999999,
                            34.4037350003118
                        ],
                        [
                            -99.5853059998892,
                            34.3981220000021
                        ],
                        [
                            -99.5854420003317,
                            34.3889140002233
                        ],
                        [
                            -99.600025999849,
                            34.3746879998645
                        ],
                        [
                            -99.6072630002544,
                            34.3769279999377
                        ],
                        [
                            -99.6241969997415,
                            34.3735770000198
                        ],
                        [
                            -99.6309050002424,
                            34.3760070000382
                        ],
                        [
                            -99.6338589995615,
                            34.3787570000401
                        ],
                        [
                            -99.6465109995838,
                            34.3825410003219
                        ],
                        [
                            -99.654194000053,
                            34.3765190003026
                        ],
                        [
                            -99.6627050001775,
                            34.3736800001574
                        ],
                        [
                            -99.6782829999298,
                            34.379798999635
                        ],
                        [
                            -99.694970000443,
                            34.3783330003573
                        ],
                        [
                            -99.7004140003722,
                            34.384698999735
                        ],
                        [
                            -99.7066580003676,
                            34.3859810000565
                        ],
                        [
                            -99.712682000255,
                            34.3909280003035
                        ],
                        [
                            -99.714838000056,
                            34.3945240000251
                        ],
                        [
                            -99.7150890001262,
                            34.4007540002065
                        ],
                        [
                            -99.720259000419,
                            34.4062950000159
                        ],
                        [
                            -99.7270309995957,
                            34.4105539996491
                        ],
                        [
                            -99.7409070000067,
                            34.4147630003165
                        ],
                        [
                            -99.7431199997091,
                            34.417281999896
                        ],
                        [
                            -99.7466859996717,
                            34.4173950001939
                        ],
                        [
                            -99.7487889997692,
                            34.4197530000049
                        ],
                        [
                            -99.7542479997671,
                            34.42128900009
                        ],
                        [
                            -99.7596070002058,
                            34.4266740003612
                        ],
                        [
                            -99.7672339997002,
                            34.4305019999673
                        ],
                        [
                            -99.7648820000806,
                            34.4352659997948
                        ],
                        [
                            -99.7655990003061,
                            34.4374879998549
                        ],
                        [
                            -99.7757430001749,
                            34.4442249998632
                        ],
                        [
                            -99.7829860004281,
                            34.4443639998241
                        ],
                        [
                            -99.7860810002124,
                            34.4510990001468
                        ],
                        [
                            -99.7936840003157,
                            34.4538939998518
                        ],
                        [
                            -99.8028640000868,
                            34.4656679999942
                        ],
                        [
                            -99.8143129996368,
                            34.4762040003017
                        ],
                        [
                            -99.81873899994,
                            34.4849760000545
                        ],
                        [
                            -99.816803000247,
                            34.4901989999983
                        ],
                        [
                            -99.8218389997472,
                            34.4978479999081
                        ],
                        [
                            -99.832904000056,
                            34.5000679999683
                        ],
                        [
                            -99.8530529998764,
                            34.5115850003511
                        ],
                        [
                            -99.868952999844,
                            34.5276150000228
                        ],
                        [
                            -99.8769569998052,
                            34.5400980002758
                        ],
                        [
                            -99.8937599997711,
                            34.5542190003392
                        ],
                        [
                            -99.9034890004365,
                            34.5583619998583
                        ],
                        [
                            -99.9218010002981,
                            34.5702529997624
                        ],
                        [
                            -99.9232109995613,
                            34.5745520003415
                        ],
                        [
                            -99.944679000205,
                            34.5791350001936
                        ],
                        [
                            -99.9538170001433,
                            34.5785270000936
                        ],
                        [
                            -99.956716999934,
                            34.5765239999411
                        ],
                        [
                            -99.9588989996742,
                            34.571270999986
                        ],
                        [
                            -99.9716059999482,
                            34.5621689998305
                        ],
                        [
                            -99.9924059998718,
                            34.5593670001857
                        ],
                        [
                            -100.000276999764,
                            34.5604499997831
                        ],
                        [
                            -100.000405999635,
                            36.4997020001954
                        ],
                        [
                            -100.003762000434,
                            36.4998489997935
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 35.5900512,
            "name": "Oklahoma",
            "intptlon": -97.4868149,
            "geo_point_2d": [
                35.5833682774,
                -97.508093637
            ],
            "geoid": "40",
            "mtfcc": "G4000",
            "region": 3
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -97.508093637,
                35.5833682774
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "529fd9ce337f24bac471cecf4493ae243513b931",
        "fields": {
            "arealand": "-1698712947",
            "objectid": 49,
            "basename": "Indiana",
            "stusab": "IN",
            "statens": "00448508",
            "centlon": -86.2872672,
            "state": "18",
            "gid": 15,
            "centlat": 39.9060002,
            "division": 3,
            "areawater": "1536739330",
            "oid": "272635782",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -87.5265890004299,
                            40.4768760000238
                        ],
                        [
                            -87.5256610003173,
                            40.83613199976
                        ],
                        [
                            -87.5267569997611,
                            41.3083250002567
                        ],
                        [
                            -87.5236610001518,
                            41.7599069999997
                        ],
                        [
                            -87.1250360003088,
                            41.7613680001885
                        ],
                        [
                            -86.5916160001196,
                            41.7594870001385
                        ],
                        [
                            -86.1084650000861,
                            41.7606969999488
                        ],
                        [
                            -85.7281529999295,
                            41.7589460001244
                        ],
                        [
                            -84.8058850002433,
                            41.760171000018
                        ],
                        [
                            -84.8021440003785,
                            40.7491619998223
                        ],
                        [
                            -84.8036449998784,
                            40.3121390002727
                        ],
                        [
                            -84.8141870001304,
                            39.8136869997689
                        ],
                        [
                            -84.8148850000893,
                            39.5675470001408
                        ],
                        [
                            -84.8201020002378,
                            39.251409999778
                        ],
                        [
                            -84.8201569995911,
                            39.1054889997366
                        ],
                        [
                            -84.833556999801,
                            39.0997660000707
                        ],
                        [
                            -84.8623880003485,
                            39.0770840001683
                        ],
                        [
                            -84.8890589999986,
                            39.0661259996711
                        ],
                        [
                            -84.8973359997713,
                            39.0574199999533
                        ],
                        [
                            -84.8962989998787,
                            39.0524409998325
                        ],
                        [
                            -84.8856589997174,
                            39.0374940002267
                        ],
                        [
                            -84.8718240002126,
                            39.0277559999184
                        ],
                        [
                            -84.8506069996391,
                            39.004693000292
                        ],
                        [
                            -84.8466559997121,
                            38.9967409996927
                        ],
                        [
                            -84.8385789999559,
                            38.9899019998233
                        ],
                        [
                            -84.8300489995648,
                            38.9725600003456
                        ],
                        [
                            -84.8298550002944,
                            38.9693910002333
                        ],
                        [
                            -84.8338279999627,
                            38.9593330000604
                        ],
                        [
                            -84.8666470002471,
                            38.9332260001096
                        ],
                        [
                            -84.8775350001055,
                            38.9206889998506
                        ],
                        [
                            -84.8792580002615,
                            38.9160750003097
                        ],
                        [
                            -84.8772759996394,
                            38.9094799998831
                        ],
                        [
                            -84.8707489997868,
                            38.9009049998808
                        ],
                        [
                            -84.866751999829,
                            38.8988099998611
                        ],
                        [
                            -84.8121600002008,
                            38.8949800000884
                        ],
                        [
                            -84.7931589997028,
                            38.8874179998696
                        ],
                        [
                            -84.7852750002904,
                            38.880425000143
                        ],
                        [
                            -84.7857349996997,
                            38.869959999853
                        ],
                        [
                            -84.7915240003087,
                            38.8599739997209
                        ],
                        [
                            -84.8037469999856,
                            38.8504329998104
                        ],
                        [
                            -84.8231939996491,
                            38.839223000099
                        ],
                        [
                            -84.8299680002723,
                            38.8305730001069
                        ],
                        [
                            -84.8299659997241,
                            38.8254729999698
                        ],
                        [
                            -84.8272090000486,
                            38.8187319996527
                        ],
                        [
                            -84.8139910003118,
                            38.8002169999691
                        ],
                        [
                            -84.811598999611,
                            38.7919600001749
                        ],
                        [
                            -84.8125340002946,
                            38.7867289999387
                        ],
                        [
                            -84.8158240000727,
                            38.7840689997573
                        ],
                        [
                            -84.8288569998615,
                            38.7832710000286
                        ],
                        [
                            -84.8555979998318,
                            38.7899959999501
                        ],
                        [
                            -84.887284999789,
                            38.794776000328
                        ],
                        [
                            -84.8939580001298,
                            38.7937159997688
                        ],
                        [
                            -84.9176560001943,
                            38.7832519999929
                        ],
                        [
                            -84.9417600001397,
                            38.7756819999242
                        ],
                        [
                            -84.9782590001894,
                            38.7795089999646
                        ],
                        [
                            -84.9919270001879,
                            38.7785369999986
                        ],
                        [
                            -85.0133389998568,
                            38.7658830000908
                        ],
                        [
                            -85.0406210003255,
                            38.7554490001847
                        ],
                        [
                            -85.0523999995884,
                            38.7481399998396
                        ],
                        [
                            -85.0730360003788,
                            38.7410399998498
                        ],
                        [
                            -85.1015809997977,
                            38.7265309996945
                        ],
                        [
                            -85.1074149997142,
                            38.7206780001521
                        ],
                        [
                            -85.1374070000028,
                            38.6998669999261
                        ],
                        [
                            -85.1514010004146,
                            38.6938579997341
                        ],
                        [
                            -85.1730480002601,
                            38.6880220001819
                        ],
                        [
                            -85.188817999633,
                            38.6876020001827
                        ],
                        [
                            -85.2130529999979,
                            38.6954530001046
                        ],
                        [
                            -85.2261589995816,
                            38.7057840001441
                        ],
                        [
                            -85.2465559999975,
                            38.7317679998922
                        ],
                        [
                            -85.258946000079,
                            38.7377700000575
                        ],
                        [
                            -85.2684410003927,
                            38.7400909996925
                        ],
                        [
                            -85.2862600003347,
                            38.7422090001412
                        ],
                        [
                            -85.3016990001698,
                            38.741957000123
                        ],
                        [
                            -85.3347479997097,
                            38.7359540001281
                        ],
                        [
                            -85.3375680000327,
                            38.7341609998858
                        ],
                        [
                            -85.3633349998596,
                            38.7305010000885
                        ],
                        [
                            -85.3722650002838,
                            38.7304499998798
                        ],
                        [
                            -85.4102380000691,
                            38.7371349999205
                        ],
                        [
                            -85.422500999929,
                            38.7346880002382
                        ],
                        [
                            -85.4354090000444,
                            38.7290939998988
                        ],
                        [
                            -85.4501840002557,
                            38.7119310000431
                        ],
                        [
                            -85.4515340001428,
                            38.7078220001575
                        ],
                        [
                            -85.4518710004267,
                            38.7102370002262
                        ],
                        [
                            -85.4569129997747,
                            38.6887170001741
                        ],
                        [
                            -85.455042000379,
                            38.68126299992
                        ],
                        [
                            -85.4445550003786,
                            38.6696710003012
                        ],
                        [
                            -85.4387049996702,
                            38.6588569996672
                        ],
                        [
                            -85.4376130004243,
                            38.650235999682
                        ],
                        [
                            -85.4394629999035,
                            38.6358070001472
                        ],
                        [
                            -85.4397290000423,
                            38.611493000147
                        ],
                        [
                            -85.4384159999652,
                            38.603907000276
                        ],
                        [
                            -85.4363000003471,
                            38.598389999868
                        ],
                        [
                            -85.4189709999515,
                            38.5713219999502
                        ],
                        [
                            -85.4159769995511,
                            38.5636539996868
                        ],
                        [
                            -85.4157449997475,
                            38.5467230000209
                        ],
                        [
                            -85.4170469999541,
                            38.5417260000207
                        ],
                        [
                            -85.4233899996836,
                            38.5318449998076
                        ],
                        [
                            -85.4360459999039,
                            38.522953000264
                        ],
                        [
                            -85.4600800004274,
                            38.5140490001375
                        ],
                        [
                            -85.4697189997828,
                            38.5088760000203
                        ],
                        [
                            -85.4751240002523,
                            38.5036890000324
                        ],
                        [
                            -85.4812209999346,
                            38.4882499998985
                        ],
                        [
                            -85.4873629998227,
                            38.4795029998683
                        ],
                        [
                            -85.4964499997074,
                            38.4699599996684
                        ],
                        [
                            -85.5057950002332,
                            38.4646639996814
                        ],
                        [
                            -85.5335449996088,
                            38.4558860000615
                        ],
                        [
                            -85.583575000288,
                            38.4508240000792
                        ],
                        [
                            -85.5971040003694,
                            38.4451020001071
                        ],
                        [
                            -85.606953999612,
                            38.4385740003244
                        ],
                        [
                            -85.6203720002636,
                            38.4234620001235
                        ],
                        [
                            -85.6230510001213,
                            38.414387999919
                        ],
                        [
                            -85.6318330004075,
                            38.398427000142
                        ],
                        [
                            -85.6375459999502,
                            38.3821289996856
                        ],
                        [
                            -85.6394840001914,
                            38.3608660002402
                        ],
                        [
                            -85.6443099996293,
                            38.3460169999532
                        ],
                        [
                            -85.6522640002602,
                            38.3287919999555
                        ],
                        [
                            -85.6705729997487,
                            38.3076979998022
                        ],
                        [
                            -85.6735670001491,
                            38.3023249998188
                        ],
                        [
                            -85.6792129997447,
                            38.29800899985
                        ],
                        [
                            -85.7432129996479,
                            38.2672829998163
                        ],
                        [
                            -85.7514750002504,
                            38.2679979997923
                        ],
                        [
                            -85.7614879996996,
                            38.2722669998334
                        ],
                        [
                            -85.7669109997124,
                            38.2765910002841
                        ],
                        [
                            -85.7659909999956,
                            38.2803439997888
                        ],
                        [
                            -85.7725689999019,
                            38.2850450001971
                        ],
                        [
                            -85.7714220004044,
                            38.2851230000353
                        ],
                        [
                            -85.7813090003552,
                            38.2885200003259
                        ],
                        [
                            -85.817912999987,
                            38.2823600000408
                        ],
                        [
                            -85.8292159999471,
                            38.2765079998391
                        ],
                        [
                            -85.8365880000717,
                            38.2640719998605
                        ],
                        [
                            -85.8389229999729,
                            38.2403080002157
                        ],
                        [
                            -85.8443810001458,
                            38.2310479998962
                        ],
                        [
                            -85.8516939998209,
                            38.2224450002172
                        ],
                        [
                            -85.8750859995636,
                            38.2075579997134
                        ],
                        [
                            -85.8842820001266,
                            38.1995700003112
                        ],
                        [
                            -85.8976770003136,
                            38.1835960000419
                        ],
                        [
                            -85.9062439996163,
                            38.1682609998638
                        ],
                        [
                            -85.9092890000703,
                            38.142234999732
                        ],
                        [
                            -85.9043880002892,
                            38.1062159998533
                        ],
                        [
                            -85.9049899999887,
                            38.0898179999941
                        ],
                        [
                            -85.9129830000793,
                            38.071426000264
                        ],
                        [
                            -85.9133479999523,
                            38.0731239997087
                        ],
                        [
                            -85.913839000222,
                            38.0719609997401
                        ],
                        [
                            -85.9206520003049,
                            38.0323059997422
                        ],
                        [
                            -85.9256540003682,
                            38.0217840000669
                        ],
                        [
                            -85.9422160003097,
                            38.00726699989
                        ],
                        [
                            -85.9471139997176,
                            38.0050849997629
                        ],
                        [
                            -85.9649069997205,
                            38.0029899999919
                        ],
                        [
                            -85.9774230001987,
                            38.0033880000146
                        ],
                        [
                            -86.0095260000824,
                            37.9984049998994
                        ],
                        [
                            -86.0283130003202,
                            37.9933290002605
                        ],
                        [
                            -86.0327820002811,
                            37.9897790002144
                        ],
                        [
                            -86.0351029999385,
                            37.9843580001735
                        ],
                        [
                            -86.0342230004047,
                            37.9652659998002
                        ],
                        [
                            -86.0366300002759,
                            37.9610769997869
                        ],
                        [
                            -86.0408210004025,
                            37.9583660000968
                        ],
                        [
                            -86.0490119999615,
                            37.9598609998633
                        ],
                        [
                            -86.0579969997389,
                            37.9676710003301
                        ],
                        [
                            -86.0644929996294,
                            37.975144999758
                        ],
                        [
                            -86.0773439998433,
                            37.9974139997072
                        ],
                        [
                            -86.0740780003547,
                            37.9957840000925
                        ],
                        [
                            -86.0793890002146,
                            38.0006250001078
                        ],
                        [
                            -86.0946020000938,
                            38.008809000143
                        ],
                        [
                            -86.1041669998294,
                            38.0126270001543
                        ],
                        [
                            -86.1242520000757,
                            38.0161980000752
                        ],
                        [
                            -86.1478970004368,
                            38.0147490001412
                        ],
                        [
                            -86.1631859995857,
                            38.0100639999485
                        ],
                        [
                            -86.1780260000944,
                            38.0105110001699
                        ],
                        [
                            -86.219503999743,
                            38.027552000041
                        ],
                        [
                            -86.233447999926,
                            38.038180000278
                        ],
                        [
                            -86.2333390001461,
                            38.0396250000848
                        ],
                        [
                            -86.2503610003951,
                            38.0462060000437
                        ],
                        [
                            -86.2646920001924,
                            38.0548529999643
                        ],
                        [
                            -86.2727980002609,
                            38.0650020002785
                        ],
                        [
                            -86.2784310003361,
                            38.0859960002234
                        ],
                        [
                            -86.2790999999827,
                            38.1007540000777
                        ],
                        [
                            -86.270269000191,
                            38.1297829996486
                        ],
                        [
                            -86.2706049997517,
                            38.1357790002931
                        ],
                        [
                            -86.2728469997664,
                            38.14083500034
                        ],
                        [
                            -86.2863590001294,
                            38.1571990000682
                        ],
                        [
                            -86.2931959996034,
                            38.1627380003478
                        ],
                        [
                            -86.3186469999611,
                            38.1736350002586
                        ],
                        [
                            -86.3477360002514,
                            38.1953629998599
                        ],
                        [
                            -86.3561179996061,
                            38.1984690000242
                        ],
                        [
                            -86.3617060003737,
                            38.1986979996507
                        ],
                        [
                            -86.3724700003836,
                            38.1946549998912
                        ],
                        [
                            -86.3774099996244,
                            38.1885739999631
                        ],
                        [
                            -86.3785789997615,
                            38.1785719999872
                        ],
                        [
                            -86.3765729997483,
                            38.1693550002437
                        ],
                        [
                            -86.3723309995681,
                            38.1646059996628
                        ],
                        [
                            -86.3660549997857,
                            38.1615309997223
                        ],
                        [
                            -86.3414229997607,
                            38.1584080000355
                        ],
                        [
                            -86.3258740003208,
                            38.1540290000911
                        ],
                        [
                            -86.3211910000996,
                            38.1470050001409
                        ],
                        [
                            -86.3255510002806,
                            38.1358089998141
                        ],
                        [
                            -86.3320990000496,
                            38.1299290000573
                        ],
                        [
                            -86.3383709996341,
                            38.1279820000731
                        ],
                        [
                            -86.3482390002166,
                            38.1276540001808
                        ],
                        [
                            -86.3750830001191,
                            38.1306379998255
                        ],
                        [
                            -86.3811620002581,
                            38.1288230002589
                        ],
                        [
                            -86.3893920001751,
                            38.1222280000425
                        ],
                        [
                            -86.3961459998085,
                            38.1071520000507
                        ],
                        [
                            -86.401879000341,
                            38.1043289996699
                        ],
                        [
                            -86.4252259998779,
                            38.1227549996678
                        ],
                        [
                            -86.4323439995593,
                            38.1263359997495
                        ],
                        [
                            -86.4487730003297,
                            38.1273869997577
                        ],
                        [
                            -86.4557420000482,
                            38.1250310002236
                        ],
                        [
                            -86.4616859995696,
                            38.1205019996594
                        ],
                        [
                            -86.4665560002868,
                            38.1123949996514
                        ],
                        [
                            -86.4655029996023,
                            38.1043060001109
                        ],
                        [
                            -86.462816000247,
                            38.0994309997219
                        ],
                        [
                            -86.4541079995807,
                            38.0934469999111
                        ],
                        [
                            -86.4340109996388,
                            38.0864130001643
                        ],
                        [
                            -86.4302589999034,
                            38.0800319998817
                        ],
                        [
                            -86.4310280000075,
                            38.0737760000549
                        ],
                        [
                            -86.4326409996603,
                            38.0681050002737
                        ],
                        [
                            -86.4373799999579,
                            38.0616480001969
                        ],
                        [
                            -86.4539030004396,
                            38.0500809998103
                        ],
                        [
                            -86.4699370003014,
                            38.046387000115
                        ],
                        [
                            -86.5100859999793,
                            38.0448159998891
                        ],
                        [
                            -86.5216879996902,
                            38.0394829998557
                        ],
                        [
                            -86.5251039998649,
                            38.02848699974
                        ],
                        [
                            -86.5250949996441,
                            37.9680810003422
                        ],
                        [
                            -86.5198480002567,
                            37.9534450002821
                        ],
                        [
                            -86.5098309997112,
                            37.9432959999306
                        ],
                        [
                            -86.5071059998227,
                            37.9361369999609
                        ],
                        [
                            -86.5070890001042,
                            37.9299169997907
                        ],
                        [
                            -86.5167769998634,
                            37.9233459999672
                        ],
                        [
                            -86.533846999793,
                            37.9171910000151
                        ],
                        [
                            -86.5483209997055,
                            37.9179189999937
                        ],
                        [
                            -86.5667940001239,
                            37.9223230000896
                        ],
                        [
                            -86.5795490000784,
                            37.9232390001858
                        ],
                        [
                            -86.5884800003274,
                            37.9213460001594
                        ],
                        [
                            -86.5972259997302,
                            37.9129219997892
                        ],
                        [
                            -86.6001260004192,
                            37.9051709997363
                        ],
                        [
                            -86.5980979997664,
                            37.8847380002538
                        ],
                        [
                            -86.5992259998956,
                            37.8750839996457
                        ],
                        [
                            -86.5972490001948,
                            37.8705179996648
                        ],
                        [
                            -86.5991499997276,
                            37.8655599998036
                        ],
                        [
                            -86.6046990001372,
                            37.8581269998839
                        ],
                        [
                            -86.6092609999845,
                            37.855297000075
                        ],
                        [
                            -86.6380089997929,
                            37.8427720000132
                        ],
                        [
                            -86.6483929998612,
                            37.8414529997891
                        ],
                        [
                            -86.6552720000663,
                            37.8425209999916
                        ],
                        [
                            -86.6602719995815,
                            37.8467640000797
                        ],
                        [
                            -86.6622409997848,
                            37.8528499998181
                        ],
                        [
                            -86.6606530002464,
                            37.8642469999034
                        ],
                        [
                            -86.6442569999863,
                            37.8965170001595
                        ],
                        [
                            -86.6440820000841,
                            37.9025880000598
                        ],
                        [
                            -86.6471269996397,
                            37.9087499997016
                        ],
                        [
                            -86.6600349997551,
                            37.9129469999472
                        ],
                        [
                            -86.6803970000108,
                            37.915076999932
                        ],
                        [
                            -86.6858610000315,
                            37.9131850003177
                        ],
                        [
                            -86.7174919999122,
                            37.893455000196
                        ],
                        [
                            -86.722847000153,
                            37.8926859997003
                        ],
                        [
                            -86.7325630003996,
                            37.8947459999522
                        ],
                        [
                            -86.7511210003085,
                            37.913355999909
                        ],
                        [
                            -86.7736469998628,
                            37.9476030000924
                        ],
                        [
                            -86.7775140001243,
                            37.9512660000792
                        ],
                        [
                            -86.7799150001476,
                            37.9554619996605
                        ],
                        [
                            -86.7785689995602,
                            37.957298000234
                        ],
                        [
                            -86.7871680004466,
                            37.968801000229
                        ],
                        [
                            -86.7948929998503,
                            37.9891699997229
                        ],
                        [
                            -86.8131920001914,
                            37.9981320001363
                        ],
                        [
                            -86.8198040004328,
                            37.999391000338
                        ],
                        [
                            -86.8519330002559,
                            37.9890289998022
                        ],
                        [
                            -86.8611139998519,
                            37.984204999733
                        ],
                        [
                            -86.8864799998208,
                            37.9630689998272
                        ],
                        [
                            -86.8902900001808,
                            37.9574260001984
                        ],
                        [
                            -86.9019830001283,
                            37.9464479997767
                        ],
                        [
                            -86.9198339998575,
                            37.9363899999508
                        ],
                        [
                            -86.9451920003287,
                            37.9334520002577
                        ],
                        [
                            -86.9675250004376,
                            37.9326400003529
                        ],
                        [
                            -86.9667739998769,
                            37.9334550001482
                        ],
                        [
                            -86.9765999997284,
                            37.9309650000046
                        ],
                        [
                            -87.0099749996814,
                            37.9198930003372
                        ],
                        [
                            -87.0341659996654,
                            37.9062649998464
                        ],
                        [
                            -87.0401629997886,
                            37.9012150002627
                        ],
                        [
                            -87.0458779998795,
                            37.8926459997719
                        ],
                        [
                            -87.045829000374,
                            37.8868929998151
                        ],
                        [
                            -87.0429889999595,
                            37.8777689998993
                        ],
                        [
                            -87.0432850002355,
                            37.86855200017
                        ],
                        [
                            -87.0499350001117,
                            37.8523430002503
                        ],
                        [
                            -87.0566639996307,
                            37.8275310001318
                        ],
                        [
                            -87.0658040001171,
                            37.8120719997902
                        ],
                        [
                            -87.0661060002409,
                            37.8070540002437
                        ],
                        [
                            -87.0715329995534,
                            37.7998180000103
                        ],
                        [
                            -87.0892789997006,
                            37.7878619996497
                        ],
                        [
                            -87.108332999902,
                            37.7827290001214
                        ],
                        [
                            -87.1185939999467,
                            37.7828160003158
                        ],
                        [
                            -87.130224000145,
                            37.7873099997038
                        ],
                        [
                            -87.1342040003843,
                            37.7929429998987
                        ],
                        [
                            -87.1356990000363,
                            37.8030030003288
                        ],
                        [
                            -87.1409670002384,
                            37.8151119999903
                        ],
                        [
                            -87.155176999662,
                            37.8348740003263
                        ],
                        [
                            -87.1617040004129,
                            37.8397639998726
                        ],
                        [
                            -87.1689910001487,
                            37.8421289996705
                        ],
                        [
                            -87.1758360000186,
                            37.8410170002056
                        ],
                        [
                            -87.2023340003147,
                            37.8441990002102
                        ],
                        [
                            -87.2199449998443,
                            37.8488899997693
                        ],
                        [
                            -87.2554999998879,
                            37.8678020003007
                        ],
                        [
                            -87.2797870001313,
                            37.8852390001278
                        ],
                        [
                            -87.2989249999984,
                            37.8962389996463
                        ],
                        [
                            -87.3197540002344,
                            37.905524000096
                        ],
                        [
                            -87.332540000151,
                            37.9082329999396
                        ],
                        [
                            -87.3358559998682,
                            37.9074030000492
                        ],
                        [
                            -87.3447929999651,
                            37.9110990000951
                        ],
                        [
                            -87.3568149998005,
                            37.9200770001634
                        ],
                        [
                            -87.3636739999142,
                            37.9222410000707
                        ],
                        [
                            -87.3740959996173,
                            37.9319069999839
                        ],
                        [
                            -87.3729969998004,
                            37.9321440001903
                        ],
                        [
                            -87.383658999703,
                            37.9370130002294
                        ],
                        [
                            -87.4140679997432,
                            37.9448000000696
                        ],
                        [
                            -87.429160000147,
                            37.9450480000187
                        ],
                        [
                            -87.4479900000424,
                            37.9422870001018
                        ],
                        [
                            -87.4647939998332,
                            37.9341009997024
                        ],
                        [
                            -87.4976309996609,
                            37.910698999965
                        ],
                        [
                            -87.5108640003646,
                            37.9060999999624
                        ],
                        [
                            -87.5207449995693,
                            37.9128389999123
                        ],
                        [
                            -87.5509860002783,
                            37.9252700002032
                        ],
                        [
                            -87.561355000278,
                            37.9325750003236
                        ],
                        [
                            -87.5695669997534,
                            37.9425130002424
                        ],
                        [
                            -87.573604999719,
                            37.9516420002895
                        ],
                        [
                            -87.5766629996935,
                            37.9523529996675
                        ],
                        [
                            -87.5740959999887,
                            37.9593100003409
                        ],
                        [
                            -87.575728999733,
                            37.9677779998752
                        ],
                        [
                            -87.5807290001465,
                            37.9725349999426
                        ],
                        [
                            -87.588290000417,
                            37.9755120000385
                        ],
                        [
                            -87.5949639996844,
                            37.975280000252
                        ],
                        [
                            -87.6015729995528,
                            37.9713039997551
                        ],
                        [
                            -87.6064519995925,
                            37.9488590002053
                        ],
                        [
                            -87.622359999956,
                            37.9362519997422
                        ],
                        [
                            -87.6174039999234,
                            37.9382699996464
                        ],
                        [
                            -87.6226960004149,
                            37.9272100002401
                        ],
                        [
                            -87.6256620003279,
                            37.9258289999837
                        ],
                        [
                            -87.6274309996163,
                            37.929262999915
                        ],
                        [
                            -87.6283889998662,
                            37.9275629997195
                        ],
                        [
                            -87.6275150001802,
                            37.920883999722
                        ],
                        [
                            -87.6207949999837,
                            37.9076759998228
                        ],
                        [
                            -87.5928260003251,
                            37.8896879999909
                        ],
                        [
                            -87.5871410003714,
                            37.884858000101
                        ],
                        [
                            -87.5837369998923,
                            37.8780800002027
                        ],
                        [
                            -87.5839250002132,
                            37.872484999731
                        ],
                        [
                            -87.586303999597,
                            37.8724929999232
                        ],
                        [
                            -87.5892019997379,
                            37.863744999828
                        ],
                        [
                            -87.5911149998647,
                            37.8621340003539
                        ],
                        [
                            -87.5902670001179,
                            37.8585899999764
                        ],
                        [
                            -87.5983499997219,
                            37.8472149997463
                        ],
                        [
                            -87.6155829998581,
                            37.8318480001465
                        ],
                        [
                            -87.6395210001221,
                            37.8267230000006
                        ],
                        [
                            -87.6534729998028,
                            37.8260800002538
                        ],
                        [
                            -87.6704289999295,
                            37.8283469999473
                        ],
                        [
                            -87.6775109996259,
                            37.8339330003253
                        ],
                        [
                            -87.6820680003487,
                            37.8468220000623
                        ],
                        [
                            -87.6816840002091,
                            37.8562730000869
                        ],
                        [
                            -87.6669039999749,
                            37.876794999801
                        ],
                        [
                            -87.6634419997694,
                            37.8859129997351
                        ],
                        [
                            -87.6663309996894,
                            37.8956760001644
                        ],
                        [
                            -87.6748510000347,
                            37.9017809996726
                        ],
                        [
                            -87.6821310000979,
                            37.9036860001143
                        ],
                        [
                            -87.6871630002984,
                            37.9031649996558
                        ],
                        [
                            -87.6982279997089,
                            37.8982469996553
                        ],
                        [
                            -87.7168359998465,
                            37.8929679998552
                        ],
                        [
                            -87.7247989997999,
                            37.8925359998566
                        ],
                        [
                            -87.7365210000597,
                            37.8948879996607
                        ],
                        [
                            -87.7594319995785,
                            37.8918610003224
                        ],
                        [
                            -87.7706090000403,
                            37.8865969996522
                        ],
                        [
                            -87.7828960001896,
                            37.8781379996535
                        ],
                        [
                            -87.7921010000751,
                            37.8755760002959
                        ],
                        [
                            -87.8297009995915,
                            37.876455000083
                        ],
                        [
                            -87.8401330002387,
                            37.881264000236
                        ],
                        [
                            -87.842026000274,
                            37.8883140002659
                        ],
                        [
                            -87.8452239999905,
                            37.8928090001948
                        ],
                        [
                            -87.8569269999838,
                            37.9004710001703
                        ],
                        [
                            -87.8624329998373,
                            37.9119770003419
                        ],
                        [
                            -87.8740000002864,
                            37.9227199997521
                        ],
                        [
                            -87.8825580002665,
                            37.9260629997506
                        ],
                        [
                            -87.8954599996358,
                            37.9279899998681
                        ],
                        [
                            -87.9038639996301,
                            37.925536999854
                        ],
                        [
                            -87.9088549998229,
                            37.9215010002588
                        ],
                        [
                            -87.9363189998664,
                            37.8933300002353
                        ],
                        [
                            -87.940213999717,
                            37.8875380003287
                        ],
                        [
                            -87.9411769999898,
                            37.8791570001092
                        ],
                        [
                            -87.9380169999081,
                            37.8702350001724
                        ],
                        [
                            -87.925839000437,
                            37.8572640000357
                        ],
                        [
                            -87.9157089999136,
                            37.850372000332
                        ],
                        [
                            -87.9100170002872,
                            37.8430180000761
                        ],
                        [
                            -87.9036469998952,
                            37.8167849996824
                        ],
                        [
                            -87.9073750002394,
                            37.8071289999073
                        ],
                        [
                            -87.934475000235,
                            37.7961950000907
                        ],
                        [
                            -87.9352379995929,
                            37.7907859998444
                        ],
                        [
                            -87.9386019998891,
                            37.7878799997946
                        ],
                        [
                            -87.9459909997321,
                            37.7735780000667
                        ],
                        [
                            -87.9523450002307,
                            37.7717280001653
                        ],
                        [
                            -87.959471000308,
                            37.7729840001584
                        ],
                        [
                            -87.975932999792,
                            37.7878440002104
                        ],
                        [
                            -87.9991479999828,
                            37.7984440000646
                        ],
                        [
                            -88.0154870003415,
                            37.8019480000182
                        ],
                        [
                            -88.0280160003401,
                            37.7991879997146
                        ],
                        [
                            -88.0286859998116,
                            37.8047899996987
                        ],
                        [
                            -88.0354560002368,
                            37.8095200001045
                        ],
                        [
                            -88.0457530002664,
                            37.8081290002086
                        ],
                        [
                            -88.0622230001464,
                            37.8005810000196
                        ],
                        [
                            -88.0689220004264,
                            37.800982999967
                        ],
                        [
                            -88.0770290003199,
                            37.8049929999525
                        ],
                        [
                            -88.0912350004438,
                            37.8191510000807
                        ],
                        [
                            -88.0908839999162,
                            37.8244679996787
                        ],
                        [
                            -88.0857270000422,
                            37.8293880001696
                        ],
                        [
                            -88.0779950000674,
                            37.8307730000331
                        ],
                        [
                            -88.0610120001765,
                            37.8230150002732
                        ],
                        [
                            -88.0438309999188,
                            37.8215249999077
                        ],
                        [
                            -88.0300120003076,
                            37.8260759997678
                        ],
                        [
                            -88.0270460003946,
                            37.829593000344
                        ],
                        [
                            -88.0260529999846,
                            37.8336880001336
                        ],
                        [
                            -88.0282170001816,
                            37.8385850002778
                        ],
                        [
                            -88.0314190000961,
                            37.8412029998062
                        ],
                        [
                            -88.0534810000432,
                            37.846315999679
                        ],
                        [
                            -88.0650359998984,
                            37.8531720001088
                        ],
                        [
                            -88.0785289999948,
                            37.8791179996783
                        ],
                        [
                            -88.094956000217,
                            37.8936890001586
                        ],
                        [
                            -88.0977599997482,
                            37.904026000272
                        ],
                        [
                            -88.0956619996736,
                            37.9058080001801
                        ],
                        [
                            -88.0862369996801,
                            37.9057130002391
                        ],
                        [
                            -88.0714489999482,
                            37.8958129997196
                        ],
                        [
                            -88.0553730002536,
                            37.8912380003541
                        ],
                        [
                            -88.0312769998058,
                            37.8929660000087
                        ],
                        [
                            -88.0278039997297,
                            37.8920439997836
                        ],
                        [
                            -88.0248179997253,
                            37.8888600000472
                        ],
                        [
                            -88.0202840003654,
                            37.8880510001756
                        ],
                        [
                            -88.0151550000804,
                            37.8907920000827
                        ],
                        [
                            -88.013451000191,
                            37.8939509996945
                        ],
                        [
                            -88.0178160003948,
                            37.902696000257
                        ],
                        [
                            -88.0200600000594,
                            37.9129000003518
                        ],
                        [
                            -88.0225129999614,
                            37.914756000173
                        ],
                        [
                            -88.0329439998853,
                            37.9136600001696
                        ],
                        [
                            -88.0592089996546,
                            37.9171890001181
                        ],
                        [
                            -88.0651899998842,
                            37.9196840000647
                        ],
                        [
                            -88.0694680000494,
                            37.9257039997425
                        ],
                        [
                            -88.0675609997704,
                            37.9302139998058
                        ],
                        [
                            -88.0571270003717,
                            37.9341509997822
                        ],
                        [
                            -88.0449890001853,
                            37.9250989999654
                        ],
                        [
                            -88.0396439999903,
                            37.9243090002062
                        ],
                        [
                            -88.0340650003418,
                            37.9261590001662
                        ],
                        [
                            -88.0303400003706,
                            37.9310449999859
                        ],
                        [
                            -88.0361249998834,
                            37.9427450001277
                        ],
                        [
                            -88.0367929997051,
                            37.9567929996814
                        ],
                        [
                            -88.0319890000084,
                            37.9585900001723
                        ],
                        [
                            -88.0208679996232,
                            37.9593510002266
                        ],
                        [
                            -88.0163109997987,
                            37.9615740002061
                        ],
                        [
                            -88.0129289999592,
                            37.9665439999925
                        ],
                        [
                            -88.012574000132,
                            37.977061999648
                        ],
                        [
                            -88.0228639995906,
                            37.9979829997487
                        ],
                        [
                            -88.0258310002268,
                            38.0072449996756
                        ],
                        [
                            -88.0210309998298,
                            38.0193449998304
                        ],
                        [
                            -88.0169309999398,
                            38.0240449998702
                        ],
                        [
                            -88.0086309997026,
                            38.0275450003025
                        ],
                        [
                            -88.0079310000938,
                            38.0308449999835
                        ],
                        [
                            -88.0121310004412,
                            38.0338450003074
                        ],
                        [
                            -88.0176310004469,
                            38.0347450001482
                        ],
                        [
                            -88.0277309999348,
                            38.0317449998915
                        ],
                        [
                            -88.034132000289,
                            38.0320449999981
                        ],
                        [
                            -88.0415320000027,
                            38.0373450002221
                        ],
                        [
                            -88.0421320000523,
                            38.0463449997397
                        ],
                        [
                            -88.0378320001458,
                            38.0502450000461
                        ],
                        [
                            -88.030032000399,
                            38.0521450001293
                        ],
                        [
                            -88.0013299997231,
                            38.051544999958
                        ],
                        [
                            -87.9787349996734,
                            38.0598150000969
                        ],
                        [
                            -87.9672259998489,
                            38.067105000333
                        ],
                        [
                            -87.9566640004037,
                            38.0862020000116
                        ],
                        [
                            -87.9602300003663,
                            38.0998930002901
                        ],
                        [
                            -88.0067169997509,
                            38.0839239997012
                        ],
                        [
                            -88.0113899999262,
                            38.0846909997046
                        ],
                        [
                            -88.0141499999748,
                            38.08882500022
                        ],
                        [
                            -88.0168060002664,
                            38.0957169997649
                        ],
                        [
                            -88.0151140000725,
                            38.1014109999707
                        ],
                        [
                            -88.0098800002056,
                            38.1050660003276
                        ],
                        [
                            -87.998224000068,
                            38.108557000295
                        ],
                        [
                            -87.9830680000902,
                            38.1071389997276
                        ],
                        [
                            -87.9760580003638,
                            38.1095620003024
                        ],
                        [
                            -87.9738320002426,
                            38.1133960001598
                        ],
                        [
                            -87.9744449998127,
                            38.1208359998857
                        ],
                        [
                            -87.9729979998412,
                            38.1250460000745
                        ],
                        [
                            -87.9694639996657,
                            38.1307439997033
                        ],
                        [
                            -87.9663930001708,
                            38.1325060002442
                        ],
                        [
                            -87.9548580004071,
                            38.1326220000402
                        ],
                        [
                            -87.9454719998733,
                            38.1266160001687
                        ],
                        [
                            -87.9351349996607,
                            38.139427999887
                        ],
                        [
                            -87.9294229999429,
                            38.1407270001187
                        ],
                        [
                            -87.9258109999496,
                            38.1436329997313
                        ],
                        [
                            -87.9278989999785,
                            38.1516460003018
                        ],
                        [
                            -87.9177190001247,
                            38.1558890003253
                        ],
                        [
                            -87.9109239995852,
                            38.162557000122
                        ],
                        [
                            -87.9107920002391,
                            38.1676660002963
                        ],
                        [
                            -87.9130879997821,
                            38.1715300003185
                        ],
                        [
                            -87.9172899997794,
                            38.1741689999144
                        ],
                        [
                            -87.9217200002805,
                            38.1738099999301
                        ],
                        [
                            -87.9252300001665,
                            38.1706340002448
                        ],
                        [
                            -87.9258960003384,
                            38.1664729997661
                        ],
                        [
                            -87.9245129999411,
                            38.1620249997289
                        ],
                        [
                            -87.9259630002855,
                            38.1593419996929
                        ],
                        [
                            -87.9312110003962,
                            38.156347999773
                        ],
                        [
                            -87.9342989996095,
                            38.156459000129
                        ],
                        [
                            -87.9352340002932,
                            38.1593149997921
                        ],
                        [
                            -87.9337589998344,
                            38.1643510001687
                        ],
                        [
                            -87.9371619995903,
                            38.172189000346
                        ],
                        [
                            -87.959499999722,
                            38.1843759996974
                        ],
                        [
                            -87.969865000422,
                            38.1917860003384
                        ],
                        [
                            -87.9774939995663,
                            38.1997829996643
                        ],
                        [
                            -87.9839729997383,
                            38.2091069996624
                        ],
                        [
                            -87.9856270002973,
                            38.2179929997073
                        ],
                        [
                            -87.9848380001018,
                            38.2286739996574
                        ],
                        [
                            -87.9801580002537,
                            38.2361149997196
                        ],
                        [
                            -87.9797890001828,
                            38.2416609997186
                        ],
                        [
                            -87.9830580000444,
                            38.2500460000057
                        ],
                        [
                            -87.9880779996511,
                            38.2567170001368
                        ],
                        [
                            -87.9861090003462,
                            38.2580579996557
                        ],
                        [
                            -87.9818679999909,
                            38.2564439996992
                        ],
                        [
                            -87.9722589998744,
                            38.2470390001923
                        ],
                        [
                            -87.9689680002715,
                            38.2373890003106
                        ],
                        [
                            -87.9602250003434,
                            38.2371180002862
                        ],
                        [
                            -87.9579919996512,
                            38.2400960000039
                        ],
                        [
                            -87.9588829999539,
                            38.2466659998248
                        ],
                        [
                            -87.9576100000598,
                            38.2500460000057
                        ],
                        [
                            -87.9430299998421,
                            38.2577899998044
                        ],
                        [
                            -87.9420669995693,
                            38.2603010000324
                        ],
                        [
                            -87.9489030001167,
                            38.2641160001735
                        ],
                        [
                            -87.950651000387,
                            38.2668189999516
                        ],
                        [
                            -87.9521250001227,
                            38.2737630002257
                        ],
                        [
                            -87.9502980002096,
                            38.2767920000766
                        ],
                        [
                            -87.9402849998621,
                            38.2810639998079
                        ],
                        [
                            -87.9382439996888,
                            38.2902789997826
                        ],
                        [
                            -87.9348440003059,
                            38.2951450001888
                        ],
                        [
                            -87.9214230001796,
                            38.2993429999668
                        ],
                        [
                            -87.914812999588,
                            38.2990050000187
                        ],
                        [
                            -87.9095360000633,
                            38.2960610000821
                        ],
                        [
                            -87.9078659996108,
                            38.2931759999743
                        ],
                        [
                            -87.9085290003079,
                            38.290956999997
                        ],
                        [
                            -87.9177109997288,
                            38.2793510000032
                        ],
                        [
                            -87.9194699998698,
                            38.2745469997204
                        ],
                        [
                            -87.9170920003109,
                            38.2697010000991
                        ],
                        [
                            -87.9101200002194,
                            38.2681079998692
                        ],
                        [
                            -87.906841000312,
                            38.2696340000348
                        ],
                        [
                            -87.8970670003391,
                            38.2801339998779
                        ],
                        [
                            -87.8878490000349,
                            38.2852990001758
                        ],
                        [
                            -87.879388999964,
                            38.3065069999281
                        ],
                        [
                            -87.870537000256,
                            38.3122199998245
                        ],
                        [
                            -87.864270999621,
                            38.310703000176
                        ],
                        [
                            -87.8604529997633,
                            38.3053720001562
                        ],
                        [
                            -87.8662480002202,
                            38.2899799996825
                        ],
                        [
                            -87.8617740002364,
                            38.2786370003323
                        ],
                        [
                            -87.8566049997685,
                            38.2755219997332
                        ],
                        [
                            -87.8511299998771,
                            38.275120000318
                        ],
                        [
                            -87.8409840003585,
                            38.280104999772
                        ],
                        [
                            -87.8364950003061,
                            38.2854859997253
                        ],
                        [
                            -87.83152300038,
                            38.2967269999099
                        ],
                        [
                            -87.830714000093,
                            38.3030730001093
                        ],
                        [
                            -87.8333440004453,
                            38.315649000018
                        ],
                        [
                            -87.832722999581,
                            38.3248529996498
                        ],
                        [
                            -87.8227210000024,
                            38.3469120001666
                        ],
                        [
                            -87.8137909995782,
                            38.3537920002918
                        ],
                        [
                            -87.8060750003954,
                            38.363143000165
                        ],
                        [
                            -87.7860930000812,
                            38.3676489999438
                        ],
                        [
                            -87.7799960003989,
                            38.3708420001368
                        ],
                        [
                            -87.7452539999421,
                            38.4089960003301
                        ],
                        [
                            -87.7410400002493,
                            38.435576000146
                        ],
                        [
                            -87.73881199958,
                            38.438929000178
                        ],
                        [
                            -87.7306989998387,
                            38.4429079998095
                        ],
                        [
                            -87.7301339999492,
                            38.4465180002458
                        ],
                        [
                            -87.731974000281,
                            38.4507799998255
                        ],
                        [
                            -87.7357290003895,
                            38.4529860000879
                        ],
                        [
                            -87.7405249996902,
                            38.4547160000155
                        ],
                        [
                            -87.751477000021,
                            38.4542129998805
                        ],
                        [
                            -87.7559309999133,
                            38.4558759996845
                        ],
                        [
                            -87.7561789996104,
                            38.4625379997792
                        ],
                        [
                            -87.7500280003998,
                            38.4714269997262
                        ],
                        [
                            -87.7446439998467,
                            38.4772659997261
                        ],
                        [
                            -87.7400240002731,
                            38.4792230003083
                        ],
                        [
                            -87.7140470003839,
                            38.4798799998835
                        ],
                        [
                            -87.6931880000107,
                            38.4880379997012
                        ],
                        [
                            -87.6823240004417,
                            38.4946180002023
                        ],
                        [
                            -87.6718979996423,
                            38.5076110000819
                        ],
                        [
                            -87.6637010002355,
                            38.5029310003169
                        ],
                        [
                            -87.6581779997652,
                            38.5016689997483
                        ],
                        [
                            -87.652364999765,
                            38.503218999967
                        ],
                        [
                            -87.6467190001695,
                            38.5077670002958
                        ],
                        [
                            -87.6453709999322,
                            38.5138350002157
                        ],
                        [
                            -87.6561440001629,
                            38.5216680001969
                        ],
                        [
                            -87.6609779999968,
                            38.5321450000153
                        ],
                        [
                            -87.6687879997893,
                            38.5410780002711
                        ],
                        [
                            -87.6700709997292,
                            38.5452370000328
                        ],
                        [
                            -87.6654350002621,
                            38.549946000024
                        ],
                        [
                            -87.6507040004317,
                            38.5562399997902
                        ],
                        [
                            -87.6502139999869,
                            38.5584210001393
                        ],
                        [
                            -87.6527449997066,
                            38.5644590001465
                        ],
                        [
                            -87.6511069999395,
                            38.5690259999332
                        ],
                        [
                            -87.641389000043,
                            38.572934000043
                        ],
                        [
                            -87.638247000403,
                            38.5762730003434
                        ],
                        [
                            -87.6402600000889,
                            38.5858700000814
                        ],
                        [
                            -87.6402099998602,
                            38.5897949999578
                        ],
                        [
                            -87.6383929999929,
                            38.5922000002137
                        ],
                        [
                            -87.6348229998324,
                            38.5926920000315
                        ],
                        [
                            -87.6228970002564,
                            38.5861979999947
                        ],
                        [
                            -87.6119599999941,
                            38.5892480000152
                        ],
                        [
                            -87.6108860002916,
                            38.592632000063
                        ],
                        [
                            -87.6124389996699,
                            38.5946420001455
                        ],
                        [
                            -87.6194430004468,
                            38.5948240000655
                        ],
                        [
                            -87.6241430003864,
                            38.5969550002059
                        ],
                        [
                            -87.6273479997756,
                            38.6054399997108
                        ],
                        [
                            -87.6260809997292,
                            38.6089049999477
                        ],
                        [
                            -87.620532000218,
                            38.6121650000422
                        ],
                        [
                            -87.6223750000246,
                            38.6188729996922
                        ],
                        [
                            -87.6170430002483,
                            38.6221950003054
                        ],
                        [
                            -87.6155850004062,
                            38.6250470002148
                        ],
                        [
                            -87.6159429997082,
                            38.6279520001068
                        ],
                        [
                            -87.6203820004302,
                            38.6343070001724
                        ],
                        [
                            -87.620119999591,
                            38.6394890001742
                        ],
                        [
                            -87.6173150002349,
                            38.6421570000497
                        ],
                        [
                            -87.6058170002811,
                            38.6465180000366
                        ],
                        [
                            -87.6029500001024,
                            38.6512360000205
                        ],
                        [
                            -87.6028630000637,
                            38.6563629996788
                        ],
                        [
                            -87.600180000008,
                            38.6624620002111
                        ],
                        [
                            -87.5936780002698,
                            38.6674020001541
                        ],
                        [
                            -87.5793969998028,
                            38.6724750000159
                        ],
                        [
                            -87.5455380001511,
                            38.6776130001471
                        ],
                        [
                            -87.5312309997449,
                            38.6840360000323
                        ],
                        [
                            -87.5196099997674,
                            38.6971979997719
                        ],
                        [
                            -87.5195190004291,
                            38.710455000145
                        ],
                        [
                            -87.5167069996037,
                            38.7163319999828
                        ],
                        [
                            -87.5085150002197,
                            38.7257680001286
                        ],
                        [
                            -87.5061719999226,
                            38.7337250003452
                        ],
                        [
                            -87.495798999725,
                            38.7406989997277
                        ],
                        [
                            -87.5040460002588,
                            38.7485070000107
                        ],
                        [
                            -87.498947999936,
                            38.7577740001707
                        ],
                        [
                            -87.4969059999378,
                            38.7668179999764
                        ],
                        [
                            -87.5004939996417,
                            38.7691759998457
                        ],
                        [
                            -87.5103300004372,
                            38.7671810000084
                        ],
                        [
                            -87.5148610003223,
                            38.770503000063
                        ],
                        [
                            -87.5131370003415,
                            38.7731959999307
                        ],
                        [
                            -87.5043009996286,
                            38.7738460001435
                        ],
                        [
                            -87.498587000261,
                            38.7764799998398
                        ],
                        [
                            -87.4957390003488,
                            38.7803029997513
                        ],
                        [
                            -87.4955090001951,
                            38.7846259998019
                        ],
                        [
                            -87.5011230000036,
                            38.7913799999393
                        ],
                        [
                            -87.5082110004461,
                            38.7957159998814
                        ],
                        [
                            -87.5192199997801,
                            38.8104780001121
                        ],
                        [
                            -87.5273419997421,
                            38.8181209997953
                        ],
                        [
                            -87.5216810000779,
                            38.8265760001441
                        ],
                        [
                            -87.5222119996322,
                            38.8304829999618
                        ],
                        [
                            -87.5266949998368,
                            38.8385780000606
                        ],
                        [
                            -87.526729000172,
                            38.8503619999297
                        ],
                        [
                            -87.540309000307,
                            38.8547150003346
                        ],
                        [
                            -87.5520280001938,
                            38.8603179997969
                        ],
                        [
                            -87.5533939999744,
                            38.8624099996755
                        ],
                        [
                            -87.5520659998286,
                            38.8645110001701
                        ],
                        [
                            -87.5457189999011,
                            38.8630299999798
                        ],
                        [
                            -87.54264199966,
                            38.8652810002223
                        ],
                        [
                            -87.5476110001114,
                            38.8725159997825
                        ],
                        [
                            -87.5470639997652,
                            38.8759570000489
                        ],
                        [
                            -87.5349189999061,
                            38.8788739999617
                        ],
                        [
                            -87.5382799998293,
                            38.8857990002112
                        ],
                        [
                            -87.5442920000211,
                            38.8889530000688
                        ],
                        [
                            -87.5440889996315,
                            38.8950929998725
                        ],
                        [
                            -87.5391219997282,
                            38.8961189997325
                        ],
                        [
                            -87.5359549999739,
                            38.8869930000531
                        ],
                        [
                            -87.534342000321,
                            38.8912960001444
                        ],
                        [
                            -87.5365910000085,
                            38.895565999721
                        ],
                        [
                            -87.5336950004158,
                            38.900957000229
                        ],
                        [
                            -87.5168310003505,
                            38.9036970001997
                        ],
                        [
                            -87.5177319998007,
                            38.905672000192
                        ],
                        [
                            -87.5243139999049,
                            38.9051940001977
                        ],
                        [
                            -87.5276449996908,
                            38.9076880001033
                        ],
                        [
                            -87.5260329998629,
                            38.9136060003121
                        ],
                        [
                            -87.527715000011,
                            38.9192320000438
                        ],
                        [
                            -87.5260579999773,
                            38.9213549997952
                        ],
                        [
                            -87.5206340001396,
                            38.9216649999585
                        ],
                        [
                            -87.5188259995948,
                            38.9232049997659
                        ],
                        [
                            -87.5166580000982,
                            38.9334800000662
                        ],
                        [
                            -87.5217649997435,
                            38.941117000015
                        ],
                        [
                            -87.5135979995756,
                            38.9487950000919
                        ],
                        [
                            -87.5121869995892,
                            38.9544169999464
                        ],
                        [
                            -87.5243349998213,
                            38.9629640002207
                        ],
                        [
                            -87.5294959998933,
                            38.971924999944
                        ],
                        [
                            -87.5756049998844,
                            38.9861660002863
                        ],
                        [
                            -87.5783189999023,
                            38.9887860002096
                        ],
                        [
                            -87.5780190003267,
                            38.9906469998737
                        ],
                        [
                            -87.5745350003799,
                            38.9915180001843
                        ],
                        [
                            -87.5639660003636,
                            38.9888020002107
                        ],
                        [
                            -87.5604270001652,
                            38.9924249999849
                        ],
                        [
                            -87.561976000244,
                            38.9941979997528
                        ],
                        [
                            -87.5682050001707,
                            38.993705000339
                        ],
                        [
                            -87.5765649997842,
                            38.9970270000977
                        ],
                        [
                            -87.5791170003186,
                            39.0016070003098
                        ],
                        [
                            -87.573761000253,
                            39.0086839998985
                        ],
                        [
                            -87.5696959996248,
                            39.0194130002677
                        ],
                        [
                            -87.569874999725,
                            39.0231050002383
                        ],
                        [
                            -87.5750269995762,
                            39.0340619999557
                        ],
                        [
                            -87.5803729995961,
                            39.040706999769
                        ],
                        [
                            -87.5722899999921,
                            39.0505869997842
                        ],
                        [
                            -87.5725879999179,
                            39.0572860001716
                        ],
                        [
                            -87.5787020002169,
                            39.0616980000518
                        ],
                        [
                            -87.5835460000965,
                            39.0679469998479
                        ],
                        [
                            -87.5915149998977,
                            39.0731809997423
                        ],
                        [
                            -87.596373000021,
                            39.0796389998218
                        ],
                        [
                            -87.601101000448,
                            39.0814049997427
                        ],
                        [
                            -87.6141710000468,
                            39.0817840002211
                        ],
                        [
                            -87.6202579996833,
                            39.0865720002938
                        ],
                        [
                            -87.6203869995547,
                            39.091521999809
                        ],
                        [
                            -87.614309000139,
                            39.0977779997083
                        ],
                        [
                            -87.6139960001446,
                            39.1009950001635
                        ],
                        [
                            -87.6156840001404,
                            39.1033159996699
                        ],
                        [
                            -87.6385849996135,
                            39.1058940000024
                        ],
                        [
                            -87.6470699997987,
                            39.1042679997444
                        ],
                        [
                            -87.6527959997603,
                            39.1068390001702
                        ],
                        [
                            -87.655041999973,
                            39.1109210001671
                        ],
                        [
                            -87.6520829997327,
                            39.1155429996841
                        ],
                        [
                            -87.6504340000949,
                            39.1164949997958
                        ],
                        [
                            -87.6407610004043,
                            39.1143719997633
                        ],
                        [
                            -87.635903000281,
                            39.1183289999234
                        ],
                        [
                            -87.635018999651,
                            39.1221240002568
                        ],
                        [
                            -87.6421699998427,
                            39.1232119996585
                        ],
                        [
                            -87.643144999811,
                            39.1285619999048
                        ],
                        [
                            -87.6552389996165,
                            39.1322829998313
                        ],
                        [
                            -87.6587450002029,
                            39.1359969998112
                        ],
                        [
                            -87.6532009998162,
                            39.1439930003088
                        ],
                        [
                            -87.6527820004149,
                            39.1494350001951
                        ],
                        [
                            -87.6421749998655,
                            39.157096999734
                        ],
                        [
                            -87.6374430001389,
                            39.1567730001698
                        ],
                        [
                            -87.6333480002718,
                            39.1530679999851
                        ],
                        [
                            -87.6277129996486,
                            39.1549059998432
                        ],
                        [
                            -87.6293770002533,
                            39.1580229997958
                        ],
                        [
                            -87.6415489998766,
                            39.1604720003365
                        ],
                        [
                            -87.6404340001662,
                            39.1667270000757
                        ],
                        [
                            -87.6364899999119,
                            39.1688330002796
                        ],
                        [
                            -87.619618000349,
                            39.1709750000733
                        ],
                        [
                            -87.6213969996832,
                            39.1788680003127
                        ],
                        [
                            -87.6203999999735,
                            39.1851950003087
                        ],
                        [
                            -87.6185510003192,
                            39.1864039999451
                        ],
                        [
                            -87.6130639998339,
                            39.1841800003008
                        ],
                        [
                            -87.6078790003708,
                            39.18446300022
                        ],
                        [
                            -87.6051729998505,
                            39.1858970003384
                        ],
                        [
                            -87.6016310001774,
                            39.1924929998325
                        ],
                        [
                            -87.5886140002821,
                            39.1978240002393
                        ],
                        [
                            -87.5857250003621,
                            39.2003959998588
                        ],
                        [
                            -87.5849770001745,
                            39.2052759997928
                        ],
                        [
                            -87.5770290002897,
                            39.2111229997456
                        ],
                        [
                            -87.5745579999461,
                            39.2184040000387
                        ],
                        [
                            -87.5753730000809,
                            39.2283549997214
                        ],
                        [
                            -87.579318999985,
                            39.2435519997193
                        ],
                        [
                            -87.5730740001647,
                            39.2466929999075
                        ],
                        [
                            -87.5715860001854,
                            39.2522869998189
                        ],
                        [
                            -87.5747909995746,
                            39.2551289997084
                        ],
                        [
                            -87.5806520001536,
                            39.2552370002368
                        ],
                        [
                            -87.5841770001083,
                            39.2540040002543
                        ],
                        [
                            -87.5903149997985,
                            39.2475659996883
                        ],
                        [
                            -87.5946840002003,
                            39.248741999809
                        ],
                        [
                            -87.6055429997464,
                            39.2611220001625
                        ],
                        [
                            -87.6013929996277,
                            39.2743899998848
                        ],
                        [
                            -87.6085429999945,
                            39.2771869999978
                        ],
                        [
                            -87.6100500002404,
                            39.2822320000375
                        ],
                        [
                            -87.6087530000568,
                            39.2866889999141
                        ],
                        [
                            -87.5975449996329,
                            39.2963879996758
                        ],
                        [
                            -87.5979460003892,
                            39.2994790002571
                        ],
                        [
                            -87.6053479997527,
                            39.303217000246
                        ],
                        [
                            -87.6177860004131,
                            39.3012569998069
                        ],
                        [
                            -87.6209389999238,
                            39.3034070000659
                        ],
                        [
                            -87.6205890001194,
                            39.307045999674
                        ],
                        [
                            -87.6059200002132,
                            39.3110759998433
                        ],
                        [
                            -87.5890839997371,
                            39.3338309997535
                        ],
                        [
                            -87.5783309995979,
                            39.3403430001247
                        ],
                        [
                            -87.5721319998083,
                            39.3414439998883
                        ],
                        [
                            -87.5606919995808,
                            39.3388510000859
                        ],
                        [
                            -87.5543999999049,
                            39.3404880002099
                        ],
                        [
                            -87.5508320002925,
                            39.3423780003286
                        ],
                        [
                            -87.5481569997344,
                            39.3498799999719
                        ],
                        [
                            -87.5440130003618,
                            39.3529069997558
                        ],
                        [
                            -87.5372710004241,
                            39.3520889996709
                        ],
                        [
                            -87.5316459998465,
                            39.3478879998757
                        ],
                        [
                            -87.5332690004434,
                            39.8750360000227
                        ],
                        [
                            -87.5317589998244,
                            40.1442729997032
                        ],
                        [
                            -87.5265890004299,
                            40.4768760000238
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 39.9030256,
            "name": "Indiana",
            "intptlon": -86.2839503,
            "geo_point_2d": [
                39.9198818827,
                -86.281840449
            ],
            "geoid": "18",
            "mtfcc": "G4000",
            "region": 2
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -86.281840449,
                39.9198818827
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "05d5e7b984abf5737e804dd7489f03a90fc1efaa",
        "fields": {
            "arealand": "-992712557",
            "objectid": 47,
            "basename": "Montana",
            "stusab": "MT",
            "statens": "00767982",
            "centlon": -109.6329861,
            "state": "30",
            "gid": 34,
            "centlat": 47.0526268,
            "division": 8,
            "areawater": "-427433562",
            "oid": "268270520",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -115.629487999578,
                            47.479609999883
                        ],
                        [
                            -115.63460299962,
                            47.4816159997033
                        ],
                        [
                            -115.648247000227,
                            47.4777270002905
                        ],
                        [
                            -115.661024999748,
                            47.477847999767
                        ],
                        [
                            -115.662027000379,
                            47.4831369997373
                        ],
                        [
                            -115.667918000197,
                            47.488819999813
                        ],
                        [
                            -115.680516999617,
                            47.4857239998819
                        ],
                        [
                            -115.682578999707,
                            47.4839660002703
                        ],
                        [
                            -115.686264000393,
                            47.4849399998543
                        ],
                        [
                            -115.690026000174,
                            47.5007619997815
                        ],
                        [
                            -115.69863299966,
                            47.5022730001413
                        ],
                        [
                            -115.708403000333,
                            47.5151439999745
                        ],
                        [
                            -115.71139799966,
                            47.5167709999142
                        ],
                        [
                            -115.711124000024,
                            47.523419000018
                        ],
                        [
                            -115.695399999783,
                            47.5277130000298
                        ],
                        [
                            -115.698313999818,
                            47.5313750002208
                        ],
                        [
                            -115.702064999728,
                            47.5342099999942
                        ],
                        [
                            -115.706436999605,
                            47.5350060000251
                        ],
                        [
                            -115.711368000421,
                            47.5323710001519
                        ],
                        [
                            -115.717105000254,
                            47.5342220001514
                        ],
                        [
                            -115.725118000436,
                            47.5341189998244
                        ],
                        [
                            -115.736435999566,
                            47.5397930000434
                        ],
                        [
                            -115.742327000282,
                            47.5383250001595
                        ],
                        [
                            -115.751793000284,
                            47.5419869997851
                        ],
                        [
                            -115.755620000362,
                            47.5479329998964
                        ],
                        [
                            -115.754513999974,
                            47.5524539998671
                        ],
                        [
                            -115.744682000275,
                            47.5585439997621
                        ],
                        [
                            -115.740953000106,
                            47.5634470001796
                        ],
                        [
                            -115.730696000259,
                            47.5695849998308
                        ],
                        [
                            -115.723745999909,
                            47.5705959997347
                        ],
                        [
                            -115.721195000098,
                            47.5762540000176
                        ],
                        [
                            -115.706035999747,
                            47.5776260000774
                        ],
                        [
                            -115.704782000119,
                            47.5850430000103
                        ],
                        [
                            -115.68775800022,
                            47.5952999998225
                        ],
                        [
                            -115.691735000087,
                            47.5972419998765
                        ],
                        [
                            -115.692831000429,
                            47.6017949999326
                        ],
                        [
                            -115.696999999916,
                            47.6035549998656
                        ],
                        [
                            -115.698442999689,
                            47.6065789997029
                        ],
                        [
                            -115.698745999638,
                            47.6119750002253
                        ],
                        [
                            -115.691899000118,
                            47.6172300003009
                        ],
                        [
                            -115.693501999725,
                            47.6181909998942
                        ],
                        [
                            -115.694444000082,
                            47.6237610000345
                        ],
                        [
                            -115.707671999864,
                            47.6347150002304
                        ],
                        [
                            -115.729864000406,
                            47.6419199998673
                        ],
                        [
                            -115.736508000434,
                            47.6538109998884
                        ],
                        [
                            -115.731403000439,
                            47.6616889997318
                        ],
                        [
                            -115.732173000368,
                            47.6682100000115
                        ],
                        [
                            -115.726326999857,
                            47.6737069998377
                        ],
                        [
                            -115.728281999817,
                            47.6817080000574
                        ],
                        [
                            -115.722647999917,
                            47.695118000139
                        ],
                        [
                            -115.728044000166,
                            47.70169000026
                        ],
                        [
                            -115.741986999625,
                            47.7114920001218
                        ],
                        [
                            -115.753025999995,
                            47.7169500002964
                        ],
                        [
                            -115.75947500003,
                            47.7192089999945
                        ],
                        [
                            -115.76456800033,
                            47.7174060000205
                        ],
                        [
                            -115.773441999779,
                            47.7175680002183
                        ],
                        [
                            -115.776310999608,
                            47.7196780000489
                        ],
                        [
                            -115.784257999668,
                            47.7299630001739
                        ],
                        [
                            -115.782530000387,
                            47.7348260001074
                        ],
                        [
                            -115.783659000341,
                            47.7376820001709
                        ],
                        [
                            -115.781002000225,
                            47.7431840001938
                        ],
                        [
                            -115.786072999885,
                            47.748371000234
                        ],
                        [
                            -115.793730000415,
                            47.7519439999421
                        ],
                        [
                            -115.797498000044,
                            47.7573339997438
                        ],
                        [
                            -115.804609000053,
                            47.7581420002932
                        ],
                        [
                            -115.82499300005,
                            47.7523730002792
                        ],
                        [
                            -115.830909999807,
                            47.7550360000441
                        ],
                        [
                            -115.835423999974,
                            47.7614820002014
                        ],
                        [
                            -115.835069000146,
                            47.7700600002334
                        ],
                        [
                            -115.837438000383,
                            47.7748460001819
                        ],
                        [
                            -115.840426000037,
                            47.777901000209
                        ],
                        [
                            -115.840544000038,
                            47.7803550000375
                        ],
                        [
                            -115.847352999923,
                            47.7850939997806
                        ],
                        [
                            -115.845796000346,
                            47.794057999841
                        ],
                        [
                            -115.848944999659,
                            47.8001870000569
                        ],
                        [
                            -115.847327999808,
                            47.8034410001803
                        ],
                        [
                            -115.848770999582,
                            47.8100910001466
                        ],
                        [
                            -115.845065999702,
                            47.8149990000612
                        ],
                        [
                            -115.850083999659,
                            47.8201499999602
                        ],
                        [
                            -115.852312000328,
                            47.8278570001505
                        ],
                        [
                            -115.870741000366,
                            47.8349619998184
                        ],
                        [
                            -115.875262000205,
                            47.843271999817
                        ],
                        [
                            -115.881496000155,
                            47.8496119999719
                        ],
                        [
                            -115.901400999578,
                            47.8430970000849
                        ],
                        [
                            -115.906821000116,
                            47.8463829999886
                        ],
                        [
                            -115.906492000228,
                            47.8502819997154
                        ],
                        [
                            -115.909316999675,
                            47.8526310001929
                        ],
                        [
                            -115.918884999784,
                            47.8536200001913
                        ],
                        [
                            -115.919422000084,
                            47.857447999932
                        ],
                        [
                            -115.92523400026,
                            47.8668529999591
                        ],
                        [
                            -115.935481000061,
                            47.8741529997252
                        ],
                        [
                            -115.938009000306,
                            47.8810930001487
                        ],
                        [
                            -115.949749000109,
                            47.8890039999914
                        ],
                        [
                            -115.954085999825,
                            47.8945200000224
                        ],
                        [
                            -115.960201999774,
                            47.8980330000745
                        ],
                        [
                            -115.968389000034,
                            47.8992499998039
                        ],
                        [
                            -115.965383999763,
                            47.9101970001539
                        ],
                        [
                            -115.966869000267,
                            47.9125980002813
                        ],
                        [
                            -115.969603000376,
                            47.9143929997429
                        ],
                        [
                            -115.982443999646,
                            47.9156370001401
                        ],
                        [
                            -115.984651000399,
                            47.9225589998708
                        ],
                        [
                            -115.993375000061,
                            47.9259769999306
                        ],
                        [
                            -115.995120999783,
                            47.933827000042
                        ],
                        [
                            -115.998302999606,
                            47.9366280002686
                        ],
                        [
                            -115.998127999704,
                            47.9384770002731
                        ],
                        [
                            -116.00665899992,
                            47.9433410001697
                        ],
                        [
                            -116.0074840001,
                            47.950783000086
                        ],
                        [
                            -116.018771000167,
                            47.9628949999733
                        ],
                        [
                            -116.024030999973,
                            47.9654520002041
                        ],
                        [
                            -116.030387000122,
                            47.972618999702
                        ],
                        [
                            -116.038504999886,
                            47.9713450002131
                        ],
                        [
                            -116.049108000237,
                            47.9768420000676
                        ],
                        [
                            -116.049085999597,
                            49.0008459998511
                        ],
                        [
                            -115.182999999626,
                            48.999560999883
                        ],
                        [
                            -114.361928000027,
                            49.0011000002775
                        ],
                        [
                            -113.694770000374,
                            48.9974930002077
                        ],
                        [
                            -112.000879000188,
                            48.9989210002056
                        ],
                        [
                            -111.572297000217,
                            48.9966709998002
                        ],
                        [
                            -109.425313000408,
                            49.0004280002136
                        ],
                        [
                            -108.750703000265,
                            48.9990550000028
                        ],
                        [
                            -104.048689000111,
                            48.9995929999751
                        ],
                        [
                            -104.045530999679,
                            48.1283339997655
                        ],
                        [
                            -104.04141599972,
                            47.8626850001936
                        ],
                        [
                            -104.043661999933,
                            47.7325560002486
                        ],
                        [
                            -104.04529800005,
                            47.4141839997906
                        ],
                        [
                            -104.044919999758,
                            47.0931259997713
                        ],
                        [
                            -104.046627999846,
                            46.9085920000797
                        ],
                        [
                            -104.045481000348,
                            46.1690799997587
                        ],
                        [
                            -104.046827999862,
                            46.017509000215
                        ],
                        [
                            -104.042595999728,
                            45.7499419997496
                        ],
                        [
                            -104.039694000287,
                            44.9980699999405
                        ],
                        [
                            -105.913149000071,
                            45.000238000007
                        ],
                        [
                            -105.928173999629,
                            44.9934869999385
                        ],
                        [
                            -106.126207000345,
                            44.993267999834
                        ],
                        [
                            -107.084886000218,
                            44.9966000002652
                        ],
                        [
                            -107.138121000286,
                            45.0001280000325
                        ],
                        [
                            -107.476010000065,
                            45.0014239997849
                        ],
                        [
                            -109.080700000235,
                            44.9994400001012
                        ],
                        [
                            -109.103228000338,
                            45.0058150000639
                        ],
                        [
                            -109.761105999762,
                            45.0018020001719
                        ],
                        [
                            -109.825663000057,
                            45.0032240000688
                        ],
                        [
                            -110.105775999836,
                            45.0035330001032
                        ],
                        [
                            -110.183221000155,
                            44.996953000271
                        ],
                        [
                            -110.284773999719,
                            44.997359000155
                        ],
                        [
                            -110.325197999757,
                            44.9996770000252
                        ],
                        [
                            -110.407116000235,
                            44.9994960002789
                        ],
                        [
                            -110.445742999598,
                            44.9936819997972
                        ],
                        [
                            -110.704476000394,
                            44.9921740000481
                        ],
                        [
                            -110.790118999944,
                            45.0008829997368
                        ],
                        [
                            -111.054556000062,
                            45.0009549996844
                        ],
                        [
                            -111.052433999698,
                            44.4784110002825
                        ],
                        [
                            -111.055782000101,
                            44.4767980003198
                        ],
                        [
                            -111.064672000342,
                            44.4786380000183
                        ],
                        [
                            -111.074786999898,
                            44.4834479997
                        ],
                        [
                            -111.100917999773,
                            44.4877830000497
                        ],
                        [
                            -111.107212999822,
                            44.4869289998574
                        ],
                        [
                            -111.112836999677,
                            44.4904660000329
                        ],
                        [
                            -111.115061000148,
                            44.4938480002075
                        ],
                        [
                            -111.127382999559,
                            44.4976199998493
                        ],
                        [
                            -111.128376999794,
                            44.499728000192
                        ],
                        [
                            -111.130658999992,
                            44.5001309999187
                        ],
                        [
                            -111.138617999747,
                            44.5168540002678
                        ],
                        [
                            -111.131382000065,
                            44.5324290000659
                        ],
                        [
                            -111.140806000234,
                            44.5358439999157
                        ],
                        [
                            -111.150570999986,
                            44.5349709997991
                        ],
                        [
                            -111.158893999789,
                            44.546264000102
                        ],
                        [
                            -111.173755000214,
                            44.5493329999366
                        ],
                        [
                            -111.178640000102,
                            44.5524770002054
                        ],
                        [
                            -111.180747000397,
                            44.5548829999374
                        ],
                        [
                            -111.179959000027,
                            44.5674819999263
                        ],
                        [
                            -111.181030000254,
                            44.56923100026
                        ],
                        [
                            -111.201491000244,
                            44.5758960000957
                        ],
                        [
                            -111.218864999948,
                            44.5753060000838
                        ],
                        [
                            -111.220444999988,
                            44.5768730002808
                        ],
                        [
                            -111.22763100034,
                            44.5766870001221
                        ],
                        [
                            -111.230182000151,
                            44.581217000164
                        ],
                        [
                            -111.229483000368,
                            44.5823819997675
                        ],
                        [
                            -111.232144999609,
                            44.5852310002386
                        ],
                        [
                            -111.230812000338,
                            44.587705999805
                        ],
                        [
                            -111.232542999992,
                            44.5894889999197
                        ],
                        [
                            -111.231860000102,
                            44.5971369998503
                        ],
                        [
                            -111.235597999593,
                            44.6010359998027
                        ],
                        [
                            -111.235091000328,
                            44.6054149997778
                        ],
                        [
                            -111.24088900026,
                            44.6145249997955
                        ],
                        [
                            -111.226643999778,
                            44.6183600000497
                        ],
                        [
                            -111.219319000407,
                            44.622520999729
                        ],
                        [
                            -111.224404000311,
                            44.6251530000192
                        ],
                        [
                            -111.242644000203,
                            44.6434000000257
                        ],
                        [
                            -111.249236000353,
                            44.6441040002809
                        ],
                        [
                            -111.253070999929,
                            44.6498230001662
                        ],
                        [
                            -111.252597000276,
                            44.651249999714
                        ],
                        [
                            -111.270129000163,
                            44.6468199996912
                        ],
                        [
                            -111.278477000081,
                            44.6512649997149
                        ],
                        [
                            -111.280575000156,
                            44.6552010002047
                        ],
                        [
                            -111.275613000275,
                            44.656671999958
                        ],
                        [
                            -111.271854999794,
                            44.6625159997045
                        ],
                        [
                            -111.267051999922,
                            44.6648139998882
                        ],
                        [
                            -111.266804000225,
                            44.6675120001502
                        ],
                        [
                            -111.275666999803,
                            44.6743239999889
                        ],
                        [
                            -111.276924999629,
                            44.6759730002208
                        ],
                        [
                            -111.276183000188,
                            44.6783110001658
                        ],
                        [
                            -111.279250000383,
                            44.6822320000633
                        ],
                        [
                            -111.291040000415,
                            44.686188000299
                        ],
                        [
                            -111.296879000354,
                            44.6914439998656
                        ],
                        [
                            -111.297255000098,
                            44.6939720002365
                        ],
                        [
                            -111.294844999853,
                            44.6982510000891
                        ],
                        [
                            -111.296260000038,
                            44.7022709998247
                        ],
                        [
                            -111.317875000096,
                            44.714477000074
                        ],
                        [
                            -111.322483999799,
                            44.7187760001853
                        ],
                        [
                            -111.323626000172,
                            44.7290720000026
                        ],
                        [
                            -111.337113000421,
                            44.7332039999179
                        ],
                        [
                            -111.348067000401,
                            44.7253139997557
                        ],
                        [
                            -111.352977000403,
                            44.7261779998153
                        ],
                        [
                            -111.366491000416,
                            44.7381370001543
                        ],
                        [
                            -111.366261000262,
                            44.7422450002013
                        ],
                        [
                            -111.374931000395,
                            44.7481069998147
                        ],
                        [
                            -111.374488999631,
                            44.7501890002596
                        ],
                        [
                            -111.384824000194,
                            44.7549540001584
                        ],
                        [
                            -111.392968999722,
                            44.7523099998458
                        ],
                        [
                            -111.397843999564,
                            44.7463829998323
                        ],
                        [
                            -111.394429999938,
                            44.7441149997101
                        ],
                        [
                            -111.395257999593,
                            44.7392199997977
                        ],
                        [
                            -111.399382999597,
                            44.734266999992
                        ],
                        [
                            -111.397039000373,
                            44.7279339998783
                        ],
                        [
                            -111.397958000265,
                            44.7236439999596
                        ],
                        [
                            -111.404361000269,
                            44.7210539998385
                        ],
                        [
                            -111.414273000334,
                            44.7107390002917
                        ],
                        [
                            -111.424212999989,
                            44.7140230000928
                        ],
                        [
                            -111.429601999666,
                            44.7201479997345
                        ],
                        [
                            -111.438798000229,
                            44.7205449997813
                        ],
                        [
                            -111.442733000263,
                            44.7202319999266
                        ],
                        [
                            -111.446874000161,
                            44.7174009998677
                        ],
                        [
                            -111.465560999941,
                            44.7137280002067
                        ],
                        [
                            -111.479476999637,
                            44.7068059998781
                        ],
                        [
                            -111.485529000011,
                            44.7077799999946
                        ],
                        [
                            -111.488783999629,
                            44.7056430002198
                        ],
                        [
                            -111.48971599994,
                            44.6996569999203
                        ],
                        [
                            -111.482706000214,
                            44.6925890002588
                        ],
                        [
                            -111.484603000447,
                            44.6875740002697
                        ],
                        [
                            -111.476663999885,
                            44.6814370002605
                        ],
                        [
                            -111.468509000311,
                            44.6791780001932
                        ],
                        [
                            -111.471383999987,
                            44.6751330001526
                        ],
                        [
                            -111.476510999724,
                            44.6737239998055
                        ],
                        [
                            -111.475641000236,
                            44.6689979998673
                        ],
                        [
                            -111.472988000317,
                            44.6681110002823
                        ],
                        [
                            -111.472482999804,
                            44.6661900000824
                        ],
                        [
                            -111.47835199988,
                            44.6621340001292
                        ],
                        [
                            -111.47756000021,
                            44.6603079998873
                        ],
                        [
                            -111.479034999771,
                            44.6586599999547
                        ],
                        [
                            -111.487396999932,
                            44.6537900001709
                        ],
                        [
                            -111.488028999769,
                            44.6510910002329
                        ],
                        [
                            -111.490612000266,
                            44.6496520000202
                        ],
                        [
                            -111.4916709999,
                            44.6447449999052
                        ],
                        [
                            -111.490114000323,
                            44.64247099975
                        ],
                        [
                            -111.495275000395,
                            44.6443229999191
                        ],
                        [
                            -111.500956000151,
                            44.6496389999137
                        ],
                        [
                            -111.504883999613,
                            44.6443409998964
                        ],
                        [
                            -111.507944999961,
                            44.6455910000669
                        ],
                        [
                            -111.516309999597,
                            44.6439970001454
                        ],
                        [
                            -111.516461000108,
                            44.6408959998231
                        ],
                        [
                            -111.511394999572,
                            44.638609000265
                        ],
                        [
                            -111.509056000372,
                            44.6330989999409
                        ],
                        [
                            -111.503850999919,
                            44.6299230001321
                        ],
                        [
                            -111.502499000382,
                            44.6265080000731
                        ],
                        [
                            -111.516297000077,
                            44.6203000000131
                        ],
                        [
                            -111.518432999786,
                            44.6164769997791
                        ],
                        [
                            -111.522613000042,
                            44.614084000116
                        ],
                        [
                            -111.519647000129,
                            44.611972000213
                        ],
                        [
                            -111.525633000382,
                            44.6043399999823
                        ],
                        [
                            -111.519336999609,
                            44.6017679996879
                        ],
                        [
                            -111.524476999765,
                            44.5976279998173
                        ],
                        [
                            -111.523610999577,
                            44.5922150000554
                        ],
                        [
                            -111.519423999648,
                            44.5887120002964
                        ],
                        [
                            -111.517814000368,
                            44.5821660000755
                        ],
                        [
                            -111.506463999654,
                            44.5767740001259
                        ],
                        [
                            -111.50134000029,
                            44.5724899999783
                        ],
                        [
                            -111.5025770002,
                            44.568716999935
                        ],
                        [
                            -111.506308000019,
                            44.5687790000184
                        ],
                        [
                            -111.509586000101,
                            44.5662440002772
                        ],
                        [
                            -111.507769000234,
                            44.5622979998904
                        ],
                        [
                            -111.492122999811,
                            44.560804000237
                        ],
                        [
                            -111.480655999819,
                            44.5557350003048
                        ],
                        [
                            -111.478653000179,
                            44.5533950000056
                        ],
                        [
                            -111.475987999667,
                            44.5544220000689
                        ],
                        [
                            -111.47546499961,
                            44.5563609998825
                        ],
                        [
                            -111.469355000407,
                            44.5576739998945
                        ],
                        [
                            -111.467983999706,
                            44.5549500000169
                        ],
                        [
                            -111.46914600017,
                            44.5527740000497
                        ],
                        [
                            -111.466947999638,
                            44.5469780001667
                        ],
                        [
                            -111.46764899997,
                            44.54462299987
                        ],
                        [
                            -111.463534999836,
                            44.5413559997925
                        ],
                        [
                            -111.467904000238,
                            44.5402119998295
                        ],
                        [
                            -111.468002000147,
                            44.5389509999832
                        ],
                        [
                            -111.479664999958,
                            44.5421349998013
                        ],
                        [
                            -111.491623000219,
                            44.5406179997736
                        ],
                        [
                            -111.499371000087,
                            44.5420290000848
                        ],
                        [
                            -111.50768100037,
                            44.5362980002671
                        ],
                        [
                            -111.512890999948,
                            44.5360410002335
                        ],
                        [
                            -111.51637400007,
                            44.5378369998582
                        ],
                        [
                            -111.51831599961,
                            44.5434520002666
                        ],
                        [
                            -111.523593999858,
                            44.5478640000101
                        ],
                        [
                            -111.539166999588,
                            44.5513129999848
                        ],
                        [
                            -111.547051999723,
                            44.5571759996856
                        ],
                        [
                            -111.556561000281,
                            44.554686999873
                        ],
                        [
                            -111.562813999599,
                            44.5552090001629
                        ],
                        [
                            -111.564405000408,
                            44.5574970001118
                        ],
                        [
                            -111.577664000153,
                            44.5588860002462
                        ],
                        [
                            -111.585687000381,
                            44.562627999756
                        ],
                        [
                            -111.592261999914,
                            44.561119000005
                        ],
                        [
                            -111.592221999731,
                            44.558674999982
                        ],
                        [
                            -111.613897000064,
                            44.5486799997122
                        ],
                        [
                            -111.617637999929,
                            44.5504649999493
                        ],
                        [
                            -111.633222000427,
                            44.5533489997619
                        ],
                        [
                            -111.64058899963,
                            44.5527409999996
                        ],
                        [
                            -111.646826999778,
                            44.554796999703
                        ],
                        [
                            -111.65134199977,
                            44.5551409998778
                        ],
                        [
                            -111.659265000438,
                            44.5526629996904
                        ],
                        [
                            -111.663551000101,
                            44.5557970001421
                        ],
                        [
                            -111.676614999852,
                            44.5576840000144
                        ],
                        [
                            -111.68299699994,
                            44.561657000068
                        ],
                        [
                            -111.689994999971,
                            44.5562000000351
                        ],
                        [
                            -111.703164000202,
                            44.5601480000943
                        ],
                        [
                            -111.707252000396,
                            44.5524910002149
                        ],
                        [
                            -111.71265100012,
                            44.5485540002179
                        ],
                        [
                            -111.715647999995,
                            44.5433689999847
                        ],
                        [
                            -111.727032000146,
                            44.5441309996948
                        ],
                        [
                            -111.732370999595,
                            44.5421170001529
                        ],
                        [
                            -111.737535999865,
                            44.5430679998827
                        ],
                        [
                            -111.741367000141,
                            44.5408760002034
                        ],
                        [
                            -111.749914000251,
                            44.5395799999224
                        ],
                        [
                            -111.75229900038,
                            44.5365360001711
                        ],
                        [
                            -111.758943000409,
                            44.5332340002439
                        ],
                        [
                            -111.762723999558,
                            44.5258000002591
                        ],
                        [
                            -111.771510000042,
                            44.5308679999475
                        ],
                        [
                            -111.779796000036,
                            44.5238429999091
                        ],
                        [
                            -111.785450999852,
                            44.523597999734
                        ],
                        [
                            -111.795361000268,
                            44.5265939997895
                        ],
                        [
                            -111.801119000016,
                            44.5259850000811
                        ],
                        [
                            -111.808159999705,
                            44.5197480000194
                        ],
                        [
                            -111.806081999722,
                            44.5161209998637
                        ],
                        [
                            -111.809388000291,
                            44.5128889997162
                        ],
                        [
                            -111.807970999559,
                            44.5101610002436
                        ],
                        [
                            -111.810658999638,
                            44.5114779999377
                        ],
                        [
                            -111.821529999778,
                            44.5089410000113
                        ],
                        [
                            -111.831249000397,
                            44.5173270001892
                        ],
                        [
                            -111.82993600032,
                            44.5199860003036
                        ],
                        [
                            -111.842295000439,
                            44.5261729997587
                        ],
                        [
                            -111.849234999846,
                            44.5399699997273
                        ],
                        [
                            -111.856656000374,
                            44.545972999815
                        ],
                        [
                            -111.856907000444,
                            44.549091999945
                        ],
                        [
                            -111.863913999797,
                            44.5514600002956
                        ],
                        [
                            -111.864894999614,
                            44.5571159996966
                        ],
                        [
                            -111.867549000255,
                            44.5593890001347
                        ],
                        [
                            -111.866447000065,
                            44.5631000000924
                        ],
                        [
                            -111.869319000267,
                            44.5666209998541
                        ],
                        [
                            -111.870704000314,
                            44.5632580002364
                        ],
                        [
                            -111.879458999938,
                            44.5604089996925
                        ],
                        [
                            -111.889417999859,
                            44.5646489998443
                        ],
                        [
                            -111.892302999581,
                            44.5639519998294
                        ],
                        [
                            -111.895178000155,
                            44.5594010001808
                        ],
                        [
                            -111.90293400042,
                            44.5566729998691
                        ],
                        [
                            -111.934161000069,
                            44.5580400000916
                        ],
                        [
                            -111.941106000397,
                            44.5561519997559
                        ],
                        [
                            -111.947782000212,
                            44.55701299987
                        ],
                        [
                            -111.951680000436,
                            44.5494539998589
                        ],
                        [
                            -111.961154999759,
                            44.5491699999187
                        ],
                        [
                            -111.962421999806,
                            44.5444780003117
                        ],
                        [
                            -111.967303000394,
                            44.5449429997144
                        ],
                        [
                            -111.971542000201,
                            44.5376460001984
                        ],
                        [
                            -111.977724000272,
                            44.5357440001721
                        ],
                        [
                            -111.992039000176,
                            44.5375290000873
                        ],
                        [
                            -111.995515999552,
                            44.5350130002826
                        ],
                        [
                            -111.999932999634,
                            44.5378929998743
                        ],
                        [
                            -112.004426999709,
                            44.5377029999211
                        ],
                        [
                            -112.011473000319,
                            44.5434510002022
                        ],
                        [
                            -112.016699999615,
                            44.5420179997468
                        ],
                        [
                            -112.023437000428,
                            44.5450989997931
                        ],
                        [
                            -112.032706999712,
                            44.5466419998338
                        ],
                        [
                            -112.035069000276,
                            44.5419800001929
                        ],
                        [
                            -112.03281999969,
                            44.5351029998445
                        ],
                        [
                            -112.032798999774,
                            44.5284029997328
                        ],
                        [
                            -112.036943000045,
                            44.5303230001148
                        ],
                        [
                            -112.037856999914,
                            44.5326849997819
                        ],
                        [
                            -112.043915000136,
                            44.5323390002135
                        ],
                        [
                            -112.055475000014,
                            44.5366740003156
                        ],
                        [
                            -112.064488000279,
                            44.5358889997122
                        ],
                        [
                            -112.06906799967,
                            44.5371510000919
                        ],
                        [
                            -112.075274999855,
                            44.5333939999877
                        ],
                        [
                            -112.080842999633,
                            44.5338280001214
                        ],
                        [
                            -112.093092999973,
                            44.5300170002552
                        ],
                        [
                            -112.096484000033,
                            44.5229920000536
                        ],
                        [
                            -112.106400000296,
                            44.5205690002401
                        ],
                        [
                            -112.10952100002,
                            44.5242300001724
                        ],
                        [
                            -112.127046000234,
                            44.5288899999588
                        ],
                        [
                            -112.129161999852,
                            44.5361599998718
                        ],
                        [
                            -112.131345000315,
                            44.5377350000106
                        ],
                        [
                            -112.135369000037,
                            44.5371820003127
                        ],
                        [
                            -112.136672000069,
                            44.5398600000468
                        ],
                        [
                            -112.14575599958,
                            44.5392130000252
                        ],
                        [
                            -112.148886000423,
                            44.5367559997177
                        ],
                        [
                            -112.164648000298,
                            44.5415330000069
                        ],
                        [
                            -112.18007299989,
                            44.5327770002354
                        ],
                        [
                            -112.183657000294,
                            44.53295300003
                        ],
                        [
                            -112.195520000121,
                            44.5373639999744
                        ],
                        [
                            -112.222333000061,
                            44.5439429997219
                        ],
                        [
                            -112.229629999843,
                            44.5495189998667
                        ],
                        [
                            -112.227073000184,
                            44.5546779997497
                        ],
                        [
                            -112.227323000429,
                            44.5575290002028
                        ],
                        [
                            -112.231065999944,
                            44.5589929997874
                        ],
                        [
                            -112.229224999787,
                            44.5640889999169
                        ],
                        [
                            -112.243655000217,
                            44.5682900000845
                        ],
                        [
                            -112.251138999596,
                            44.5677199996959
                        ],
                        [
                            -112.256701000424,
                            44.5716779996941
                        ],
                        [
                            -112.259183999565,
                            44.5682600000686
                        ],
                        [
                            -112.267805000193,
                            44.5654609996997
                        ],
                        [
                            -112.286568000141,
                            44.5685589998986
                        ],
                        [
                            -112.29093400017,
                            44.5658110001914
                        ],
                        [
                            -112.292690999763,
                            44.5616560003164
                        ],
                        [
                            -112.296332000068,
                            44.5612060002561
                        ],
                        [
                            -112.298204000187,
                            44.5589359997871
                        ],
                        [
                            -112.30463499978,
                            44.559068999701
                        ],
                        [
                            -112.313740999932,
                            44.5530410001161
                        ],
                        [
                            -112.315599999632,
                            44.5464249999085
                        ],
                        [
                            -112.315007999978,
                            44.5419000000064
                        ],
                        [
                            -112.319493999657,
                            44.5389039997068
                        ],
                        [
                            -112.341362000334,
                            44.5372830003183
                        ],
                        [
                            -112.347619999675,
                            44.5387420000844
                        ],
                        [
                            -112.35250899976,
                            44.538020999709
                        ],
                        [
                            -112.356851000398,
                            44.5303030000768
                        ],
                        [
                            -112.358823000076,
                            44.5294110001082
                        ],
                        [
                            -112.356649999658,
                            44.52488400027
                        ],
                        [
                            -112.358306999692,
                            44.5199139999111
                        ],
                        [
                            -112.358356999921,
                            44.5091559999446
                        ],
                        [
                            -112.356396000113,
                            44.5052259999564
                        ],
                        [
                            -112.35681200004,
                            44.4979559998167
                        ],
                        [
                            -112.358402000126,
                            44.496434000028
                        ],
                        [
                            -112.356657000229,
                            44.4928379997944
                        ],
                        [
                            -112.358366000141,
                            44.4852610000311
                        ],
                        [
                            -112.361651999721,
                            44.4848159999686
                        ],
                        [
                            -112.364489999588,
                            44.480305000268
                        ],
                        [
                            -112.366064999606,
                            44.4669799998531
                        ],
                        [
                            -112.369741999896,
                            44.465976999807
                        ],
                        [
                            -112.386523999946,
                            44.4473079998419
                        ],
                        [
                            -112.39018399962,
                            44.4503789996831
                        ],
                        [
                            -112.407236000006,
                            44.4574650001995
                        ],
                        [
                            -112.416061999775,
                            44.4530009999639
                        ],
                        [
                            -112.4230149996,
                            44.4546890002375
                        ],
                        [
                            -112.429522000259,
                            44.4534320001768
                        ],
                        [
                            -112.434079999909,
                            44.454348000206
                        ],
                        [
                            -112.433539000308,
                            44.4598339997556
                        ],
                        [
                            -112.442350000009,
                            44.465311000148
                        ],
                        [
                            -112.441508999935,
                            44.4665749997204
                        ],
                        [
                            -112.447773999846,
                            44.4679719997579
                        ],
                        [
                            -112.455232000185,
                            44.4662540000933
                        ],
                        [
                            -112.455535999958,
                            44.4711410000116
                        ],
                        [
                            -112.460349999701,
                            44.4751149999411
                        ],
                        [
                            -112.464369000298,
                            44.4749399997547
                        ],
                        [
                            -112.472687000079,
                            44.4792969996887
                        ],
                        [
                            -112.480075000097,
                            44.4746449996811
                        ],
                        [
                            -112.48365000028,
                            44.4743500000392
                        ],
                        [
                            -112.485030000305,
                            44.4708669999244
                        ],
                        [
                            -112.497131999608,
                            44.4680690001919
                        ],
                        [
                            -112.497186000034,
                            44.4660579998624
                        ],
                        [
                            -112.500642000392,
                            44.4627660000418
                        ],
                        [
                            -112.511290000051,
                            44.4663820002421
                        ],
                        [
                            -112.510700000047,
                            44.4687739997513
                        ],
                        [
                            -112.513891000091,
                            44.4751169998523
                        ],
                        [
                            -112.53584000006,
                            44.4783829998954
                        ],
                        [
                            -112.541711999612,
                            44.4837709998703
                        ],
                        [
                            -112.547867999743,
                            44.483144000055
                        ],
                        [
                            -112.550143000268,
                            44.4847450001401
                        ],
                        [
                            -112.559708000004,
                            44.4826350000944
                        ],
                        [
                            -112.569668999575,
                            44.4827109996957
                        ],
                        [
                            -112.577952999918,
                            44.4800920000764
                        ],
                        [
                            -112.582139999847,
                            44.4820899996823
                        ],
                        [
                            -112.585335999914,
                            44.4812029998951
                        ],
                        [
                            -112.601954999757,
                            44.4907049997523
                        ],
                        [
                            -112.617889000059,
                            44.4873839998404
                        ],
                        [
                            -112.625014000312,
                            44.4830270000795
                        ],
                        [
                            -112.629123000422,
                            44.4828100002759
                        ],
                        [
                            -112.62987300026,
                            44.488805000066
                        ],
                        [
                            -112.63333999959,
                            44.491096000297
                        ],
                        [
                            -112.661734000294,
                            44.4859899997885
                        ],
                        [
                            -112.671971999874,
                            44.4913669999266
                        ],
                        [
                            -112.684958999632,
                            44.4928469998646
                        ],
                        [
                            -112.688754999749,
                            44.4983669999188
                        ],
                        [
                            -112.692734999988,
                            44.4999920001671
                        ],
                        [
                            -112.698363999865,
                            44.4999100002688
                        ],
                        [
                            -112.708525000351,
                            44.5032310000062
                        ],
                        [
                            -112.717930000253,
                            44.5042729996935
                        ],
                        [
                            -112.734542000423,
                            44.4994890002879
                        ],
                        [
                            -112.737462000305,
                            44.493267999742
                        ],
                        [
                            -112.74306199987,
                            44.4883379996812
                        ],
                        [
                            -112.749758999602,
                            44.4901339999183
                        ],
                        [
                            -112.762434999914,
                            44.4876770001141
                        ],
                        [
                            -112.772151999985,
                            44.4836050002224
                        ],
                        [
                            -112.780810000423,
                            44.4846599999338
                        ],
                        [
                            -112.780185000259,
                            44.4822279999717
                        ],
                        [
                            -112.785604000074,
                            44.4789849998106
                        ],
                        [
                            -112.78783100002,
                            44.473733000052
                        ],
                        [
                            -112.798041999836,
                            44.4663409997592
                        ],
                        [
                            -112.796866000026,
                            44.4643800001084
                        ],
                        [
                            -112.798039000361,
                            44.4609140001789
                        ],
                        [
                            -112.805809999795,
                            44.4559939996794
                        ],
                        [
                            -112.807706000204,
                            44.4531219999118
                        ],
                        [
                            -112.811334999916,
                            44.454819999722
                        ],
                        [
                            -112.813894999948,
                            44.453983000183
                        ],
                        [
                            -112.818189000006,
                            44.4470520002795
                        ],
                        [
                            -112.826962000072,
                            44.4431270000642
                        ],
                        [
                            -112.828424000112,
                            44.4363440000891
                        ],
                        [
                            -112.826879000231,
                            44.4320559998521
                        ],
                        [
                            -112.839890000278,
                            44.4247140001001
                        ],
                        [
                            -112.821792999604,
                            44.407164999974
                        ],
                        [
                            -112.819943999949,
                            44.4001980001543
                        ],
                        [
                            -112.810056000174,
                            44.3987599998877
                        ],
                        [
                            -112.815096999697,
                            44.3950979997239
                        ],
                        [
                            -112.812571,
                            44.3924049998118
                        ],
                        [
                            -112.811943000361,
                            44.3879049999711
                        ],
                        [
                            -112.814192000049,
                            44.3834640003008
                        ],
                        [
                            -112.812207999777,
                            44.3771420002231
                        ],
                        [
                            -112.823507000437,
                            44.3687260000998
                        ],
                        [
                            -112.83713000023,
                            44.3620940001054
                        ],
                        [
                            -112.840601999583,
                            44.3618939999712
                        ],
                        [
                            -112.844565000104,
                            44.3579689997411
                        ],
                        [
                            -112.852625999966,
                            44.3592099999458
                        ],
                        [
                            -112.855550999871,
                            44.360215999755
                        ],
                        [
                            -112.863898999789,
                            44.3698020001023
                        ],
                        [
                            -112.872924999575,
                            44.3721340000091
                        ],
                        [
                            -112.875709999738,
                            44.3772320000875
                        ],
                        [
                            -112.881581000362,
                            44.379256000281
                        ],
                        [
                            -112.881736000173,
                            44.3857080001495
                        ],
                        [
                            -112.885564000076,
                            44.396262999861
                        ],
                        [
                            -112.883531000299,
                            44.4006210000728
                        ],
                        [
                            -112.889556999836,
                            44.4059379999195
                        ],
                        [
                            -112.896421999798,
                            44.4008120000292
                        ],
                        [
                            -112.901873000298,
                            44.3988610002918
                        ],
                        [
                            -112.914615999658,
                            44.4026629999989
                        ],
                        [
                            -112.921146999709,
                            44.4109200001912
                        ],
                        [
                            -112.926402000391,
                            44.4106010003094
                        ],
                        [
                            -112.931350000027,
                            44.4134810002527
                        ],
                        [
                            -112.938780999703,
                            44.4123470001697
                        ],
                        [
                            -112.945416000409,
                            44.414631999696
                        ],
                        [
                            -112.950996999707,
                            44.4172600001132
                        ],
                        [
                            -112.953278999905,
                            44.4222800002607
                        ],
                        [
                            -112.952047999844,
                            44.4250200003128
                        ],
                        [
                            -112.963047999855,
                            44.4232549996808
                        ],
                        [
                            -112.971516000322,
                            44.4275670001391
                        ],
                        [
                            -112.98119499986,
                            44.4343059997431
                        ],
                        [
                            -112.989039999813,
                            44.4433290001073
                        ],
                        [
                            -113.001978000066,
                            44.4498570000282
                        ],
                        [
                            -113.004506000311,
                            44.4528269998589
                        ],
                        [
                            -113.008787999775,
                            44.463996999901
                        ],
                        [
                            -113.006117000314,
                            44.4709799998191
                        ],
                        [
                            -113.019986999978,
                            44.4836960001766
                        ],
                        [
                            -113.026824000351,
                            44.4955120001883
                        ],
                        [
                            -113.015877999868,
                            44.5059339998814
                        ],
                        [
                            -113.015944999815,
                            44.5159869999238
                        ],
                        [
                            -113.005518000089,
                            44.5198450001987
                        ],
                        [
                            -113.006529999867,
                            44.525775999758
                        ],
                        [
                            -113.015788000354,
                            44.5294240002367
                        ],
                        [
                            -113.026340999579,
                            44.5385519997
                        ],
                        [
                            -113.028966999733,
                            44.5370550000885
                        ],
                        [
                            -113.033292999579,
                            44.5374999997443
                        ],
                        [
                            -113.04535799997,
                            44.5443229998988
                        ],
                        [
                            -113.047702000092,
                            44.5476969997537
                        ],
                        [
                            -113.046767000307,
                            44.5535989997017
                        ],
                        [
                            -113.044803999951,
                            44.5562320001993
                        ],
                        [
                            -113.036826999754,
                            44.560362000099
                        ],
                        [
                            -113.037285000412,
                            44.5647690002245
                        ],
                        [
                            -113.039549999993,
                            44.5673020002981
                        ],
                        [
                            -113.044742000027,
                            44.5682159999746
                        ],
                        [
                            -113.067168000022,
                            44.5790619998123
                        ],
                        [
                            -113.062266000416,
                            44.5862360000909
                        ],
                        [
                            -113.072525999738,
                            44.5932969998794
                        ],
                        [
                            -113.088291999811,
                            44.5996629997566
                        ],
                        [
                            -113.071888000053,
                            44.6158710001927
                        ],
                        [
                            -113.063489000082,
                            44.6183280001806
                        ],
                        [
                            -113.05814899991,
                            44.6179879999227
                        ],
                        [
                            -113.05365400001,
                            44.6216570001675
                        ],
                        [
                            -113.049837999802,
                            44.6287989997739
                        ],
                        [
                            -113.050722000432,
                            44.6345420001312
                        ],
                        [
                            -113.053874999943,
                            44.6392660001229
                        ],
                        [
                            -113.065588999806,
                            44.6492529998491
                        ],
                        [
                            -113.064038000078,
                            44.652735000155
                        ],
                        [
                            -113.068003000249,
                            44.6576710002559
                        ],
                        [
                            -113.069733999902,
                            44.6686830001702
                        ],
                        [
                            -113.067106000098,
                            44.6742160002994
                        ],
                        [
                            -113.067761000399,
                            44.6787860001926
                        ],
                        [
                            -113.080943000151,
                            44.6830509999464
                        ],
                        [
                            -113.082057000036,
                            44.6839690000627
                        ],
                        [
                            -113.079552000256,
                            44.6878309999344
                        ],
                        [
                            -113.082112000288,
                            44.6916569997501
                        ],
                        [
                            -113.093950999825,
                            44.6953070000985
                        ],
                        [
                            -113.097833000155,
                            44.6988680001224
                        ],
                        [
                            -113.097023000044,
                            44.7014639997755
                        ],
                        [
                            -113.10181400022,
                            44.7071239998631
                        ],
                        [
                            -113.101728000006,
                            44.7155189997793
                        ],
                        [
                            -113.0994049998,
                            44.7177730002192
                        ],
                        [
                            -113.10192100035,
                            44.7227380002032
                        ],
                        [
                            -113.101618000401,
                            44.7294649998598
                        ],
                        [
                            -113.11244699981,
                            44.7334369996827
                        ],
                        [
                            -113.116836000303,
                            44.7325170001207
                        ],
                        [
                            -113.116466000407,
                            44.7400519997979
                        ],
                        [
                            -113.120260999801,
                            44.7459510001677
                        ],
                        [
                            -113.124901000364,
                            44.7494369998137
                        ],
                        [
                            -113.129412000158,
                            44.7495560001889
                        ],
                        [
                            -113.1348240003,
                            44.7527630001774
                        ],
                        [
                            -113.137724999915,
                            44.7601160002662
                        ],
                        [
                            -113.131347999851,
                            44.7649099997145
                        ],
                        [
                            -113.132499000444,
                            44.7690359997237
                        ],
                        [
                            -113.131459000179,
                            44.7727580002488
                        ],
                        [
                            -113.13843100027,
                            44.7761139997552
                        ],
                        [
                            -113.146143000153,
                            44.7766229998012
                        ],
                        [
                            -113.150128000416,
                            44.7792619998131
                        ],
                        [
                            -113.157944999881,
                            44.7809659997255
                        ],
                        [
                            -113.163687999561,
                            44.7788190003022
                        ],
                        [
                            -113.179538000198,
                            44.7871650002668
                        ],
                        [
                            -113.183413999782,
                            44.7935040000453
                        ],
                        [
                            -113.19311699961,
                            44.8016529998741
                        ],
                        [
                            -113.20360399961,
                            44.8039820002641
                        ],
                        [
                            -113.209354999686,
                            44.8091249999973
                        ],
                        [
                            -113.222590999865,
                            44.8107939999163
                        ],
                        [
                            -113.231871000093,
                            44.8143289998433
                        ],
                        [
                            -113.238729000382,
                            44.8141439998066
                        ],
                        [
                            -113.240163999759,
                            44.817232000006
                        ],
                        [
                            -113.246894000002,
                            44.8229729999816
                        ],
                        [
                            -113.258272000305,
                            44.8199969997449
                        ],
                        [
                            -113.263828000387,
                            44.8162099998483
                        ],
                        [
                            -113.279314999903,
                            44.8124389999828
                        ],
                        [
                            -113.285307999828,
                            44.8082549997113
                        ],
                        [
                            -113.298978000375,
                            44.8030310000963
                        ],
                        [
                            -113.301505999721,
                            44.7990309999575
                        ],
                        [
                            -113.318681000131,
                            44.7949139997751
                        ],
                        [
                            -113.328053000421,
                            44.7890969998479
                        ],
                        [
                            -113.341276000181,
                            44.7846640002424
                        ],
                        [
                            -113.354083000014,
                            44.7917979999556
                        ],
                        [
                            -113.354688999911,
                            44.7954369999091
                        ],
                        [
                            -113.346491999606,
                            44.7989299998906
                        ],
                        [
                            -113.346099999969,
                            44.8006110002167
                        ],
                        [
                            -113.356062000263,
                            44.8197980001424
                        ],
                        [
                            -113.376563000436,
                            44.8304829999831
                        ],
                        [
                            -113.377566999818,
                            44.8352389997791
                        ],
                        [
                            -113.385116000393,
                            44.8375999999526
                        ],
                        [
                            -113.394310000408,
                            44.8369400002141
                        ],
                        [
                            -113.409412999784,
                            44.8407570001731
                        ],
                        [
                            -113.421951999828,
                            44.8375989998584
                        ],
                        [
                            -113.422376000151,
                            44.8425950000645
                        ],
                        [
                            -113.429079999555,
                            44.8548580000444
                        ],
                        [
                            -113.439574999952,
                            44.8556889998189
                        ],
                        [
                            -113.443665999621,
                            44.8617560000749
                        ],
                        [
                            -113.455070999688,
                            44.8654239999066
                        ],
                        [
                            -113.457266999672,
                            44.8754080001574
                        ],
                        [
                            -113.462555999791,
                            44.8818580002088
                        ],
                        [
                            -113.461200000056,
                            44.8867540000867
                        ],
                        [
                            -113.466151999891,
                            44.8904640002621
                        ],
                        [
                            -113.478594999676,
                            44.8955949999217
                        ],
                        [
                            -113.475879000008,
                            44.8980159999726
                        ],
                        [
                            -113.474943000398,
                            44.905237999757
                        ],
                        [
                            -113.471786999616,
                            44.909837000103
                        ],
                        [
                            -113.477983999755,
                            44.9123820000193
                        ],
                        [
                            -113.483698000021,
                            44.9197240002638
                        ],
                        [
                            -113.487878000277,
                            44.9203809997376
                        ],
                        [
                            -113.490744999558,
                            44.9228519998331
                        ],
                        [
                            -113.49198300019,
                            44.9303830001241
                        ],
                        [
                            -113.495240000356,
                            44.931714999865
                        ],
                        [
                            -113.495695999567,
                            44.936941999764
                        ],
                        [
                            -113.498818000014,
                            44.9422030000807
                        ],
                        [
                            -113.494650000352,
                            44.9485149999589
                        ],
                        [
                            -113.486846000407,
                            44.9467779997995
                        ],
                        [
                            -113.480835999865,
                            44.9503099996882
                        ],
                        [
                            -113.465837000246,
                            44.94760900021
                        ],
                        [
                            -113.461053999568,
                            44.9520949997405
                        ],
                        [
                            -113.448759999746,
                            44.953730000308
                        ],
                        [
                            -113.443939000331,
                            44.9593799998037
                        ],
                        [
                            -113.449790999791,
                            44.9706359997552
                        ],
                        [
                            -113.444922999622,
                            44.9732640002762
                        ],
                        [
                            -113.447089000367,
                            44.9850579997025
                        ],
                        [
                            -113.445037000323,
                            44.9918450000152
                        ],
                        [
                            -113.446860999863,
                            44.998276000259
                        ],
                        [
                            -113.437881999933,
                            45.0073429997232
                        ],
                        [
                            -113.442094999801,
                            45.014209999723
                        ],
                        [
                            -113.448688999601,
                            45.0200159997332
                        ],
                        [
                            -113.44629099995,
                            45.0338920002776
                        ],
                        [
                            -113.455802999982,
                            45.0397119998331
                        ],
                        [
                            -113.449312000115,
                            45.0461770001764
                        ],
                        [
                            -113.451010999981,
                            45.0485079999313
                        ],
                        [
                            -113.452043999676,
                            45.0591739997068
                        ],
                        [
                            -113.460324999647,
                            45.0648689998204
                        ],
                        [
                            -113.47435199967,
                            45.0617559996887
                        ],
                        [
                            -113.485530999782,
                            45.0636239999259
                        ],
                        [
                            -113.486302000434,
                            45.0691289997078
                        ],
                        [
                            -113.488860999743,
                            45.0713190002058
                        ],
                        [
                            -113.48681799992,
                            45.0740479999255
                        ],
                        [
                            -113.489406000439,
                            45.0765259999013
                        ],
                        [
                            -113.495949000186,
                            45.0808180002205
                        ],
                        [
                            -113.501515000314,
                            45.0809989997832
                        ],
                        [
                            -113.514122999955,
                            45.0848100000223
                        ],
                        [
                            -113.522187000191,
                            45.0913880002355
                        ],
                        [
                            -113.521204999651,
                            45.0939250003062
                        ],
                        [
                            -113.512041999599,
                            45.0994179997707
                        ],
                        [
                            -113.506048999673,
                            45.1077840002341
                        ],
                        [
                            -113.506478000019,
                            45.1107859997201
                        ],
                        [
                            -113.511249000103,
                            45.1161289997089
                        ],
                        [
                            -113.520239999729,
                            45.1149990001609
                        ],
                        [
                            -113.529608999646,
                            45.1164020000831
                        ],
                        [
                            -113.537097000121,
                            45.1123609999013
                        ],
                        [
                            -113.54966299993,
                            45.1128790001355
                        ],
                        [
                            -113.554038000179,
                            45.1185600002086
                        ],
                        [
                            -113.566112999718,
                            45.1241280002215
                        ],
                        [
                            -113.56988399972,
                            45.1238509999471
                        ],
                        [
                            -113.57672500029,
                            45.1312369998618
                        ],
                        [
                            -113.567043000379,
                            45.1431200002683
                        ],
                        [
                            -113.565694000317,
                            45.1496020002695
                        ],
                        [
                            -113.570776999673,
                            45.1479739998864
                        ],
                        [
                            -113.579955999619,
                            45.1483539997305
                        ],
                        [
                            -113.582421000115,
                            45.1501170002526
                        ],
                        [
                            -113.583221000181,
                            45.1535750000347
                        ],
                        [
                            -113.590707000108,
                            45.1558860002811
                        ],
                        [
                            -113.590593000306,
                            45.1613600002045
                        ],
                        [
                            -113.594241000284,
                            45.1660589997745
                        ],
                        [
                            -113.594189000405,
                            45.1696329998044
                        ],
                        [
                            -113.589449000283,
                            45.1773009996966
                        ],
                        [
                            -113.592185999867,
                            45.1811360001157
                        ],
                        [
                            -113.592133000163,
                            45.1847789997715
                        ],
                        [
                            -113.597230999588,
                            45.1864750001616
                        ],
                        [
                            -113.599685000213,
                            45.1921610001336
                        ],
                        [
                            -113.604329999901,
                            45.1920849999232
                        ],
                        [
                            -113.609612000347,
                            45.1973030000326
                        ],
                        [
                            -113.614484999641,
                            45.198070999968
                        ],
                        [
                            -113.617920000082,
                            45.2024310001734
                        ],
                        [
                            -113.623883999695,
                            45.2061610002099
                        ],
                        [
                            -113.636351999594,
                            45.2118470002676
                        ],
                        [
                            -113.636914999834,
                            45.2167609997965
                        ],
                        [
                            -113.647288000031,
                            45.2256420000581
                        ],
                        [
                            -113.649131999663,
                            45.2339989999696
                        ],
                        [
                            -113.655425000062,
                            45.2400060000828
                        ],
                        [
                            -113.666872999787,
                            45.2469770001229
                        ],
                        [
                            -113.679611000023,
                            45.2497459999038
                        ],
                        [
                            -113.68502399999,
                            45.2535050003087
                        ],
                        [
                            -113.686634999993,
                            45.2574070003106
                        ],
                        [
                            -113.691887000302,
                            45.2625030001296
                        ],
                        [
                            -113.692936999715,
                            45.2641970001134
                        ],
                        [
                            -113.690754999975,
                            45.2689549999034
                        ],
                        [
                            -113.691908000218,
                            45.2723559999519
                        ],
                        [
                            -113.680320999678,
                            45.27443999975
                        ],
                        [
                            -113.67896199957,
                            45.2758889999835
                        ],
                        [
                            -113.680278999845,
                            45.2788859998862
                        ],
                        [
                            -113.687264000355,
                            45.2859620002164
                        ],
                        [
                            -113.700569999955,
                            45.2925180002118
                        ],
                        [
                            -113.702904000032,
                            45.2949380003112
                        ],
                        [
                            -113.703752999603,
                            45.2991779999157
                        ],
                        [
                            -113.710035000132,
                            45.3010150001999
                        ],
                        [
                            -113.71332699956,
                            45.3044809998497
                        ],
                        [
                            -113.715828000041,
                            45.3113840000869
                        ],
                        [
                            -113.718480999959,
                            45.3133580000032
                        ],
                        [
                            -113.718582000241,
                            45.3168050001022
                        ],
                        [
                            -113.722687999979,
                            45.3175260002781
                        ],
                        [
                            -113.724068000003,
                            45.3196630000694
                        ],
                        [
                            -113.728574999599,
                            45.3199079999253
                        ],
                        [
                            -113.738660999742,
                            45.3294089997141
                        ],
                        [
                            -113.740342000065,
                            45.3454390000671
                        ],
                        [
                            -113.734681000401,
                            45.3483490001859
                        ],
                        [
                            -113.734432999805,
                            45.3531270000668
                        ],
                        [
                            -113.729029999884,
                            45.3560859998787
                        ],
                        [
                            -113.731321000302,
                            45.3577979997044
                        ],
                        [
                            -113.733514999738,
                            45.3640620000018
                        ],
                        [
                            -113.7388790002,
                            45.3681299999498
                        ],
                        [
                            -113.732919999711,
                            45.3731409998492
                        ],
                        [
                            -113.734386999774,
                            45.3765690003121
                        ],
                        [
                            -113.732475000371,
                            45.384847999945
                        ],
                        [
                            -113.733474999555,
                            45.3908110000167
                        ],
                        [
                            -113.743892000134,
                            45.396585999985
                        ],
                        [
                            -113.749192000123,
                            45.4028999998639
                        ],
                        [
                            -113.752731000321,
                            45.4027680002244
                        ],
                        [
                            -113.761793999917,
                            45.4065790001378
                        ],
                        [
                            -113.773753000003,
                            45.4063589997577
                        ],
                        [
                            -113.776042999698,
                            45.40847700005
                        ],
                        [
                            -113.777159999957,
                            45.4165630001676
                        ],
                        [
                            -113.77274200005,
                            45.4197910001072
                        ],
                        [
                            -113.762794999824,
                            45.4230739998023
                        ],
                        [
                            -113.759732999652,
                            45.427110000251
                        ],
                        [
                            -113.765032999641,
                            45.431061999706
                        ],
                        [
                            -113.77354499959,
                            45.4317050001895
                        ],
                        [
                            -113.776034000376,
                            45.43374599992
                        ],
                        [
                            -113.776888999795,
                            45.4363329998959
                        ],
                        [
                            -113.774426999672,
                            45.4465099997034
                        ],
                        [
                            -113.783541000219,
                            45.4519779997976
                        ],
                        [
                            -113.784264000293,
                            45.4548860002542
                        ],
                        [
                            -113.774640999933,
                            45.4630100003141
                        ],
                        [
                            -113.776221999798,
                            45.4709049998764
                        ],
                        [
                            -113.768064999676,
                            45.4739369997606
                        ],
                        [
                            -113.7599140003,
                            45.4804000000663
                        ],
                        [
                            -113.760064999913,
                            45.4862260000653
                        ],
                        [
                            -113.76325900033,
                            45.4907859998311
                        ],
                        [
                            -113.764785999769,
                            45.4992100002177
                        ],
                        [
                            -113.770906999741,
                            45.506979000282
                        ],
                        [
                            -113.770069999864,
                            45.5120440000164
                        ],
                        [
                            -113.772843000332,
                            45.5133070001306
                        ],
                        [
                            -113.766021999853,
                            45.5206210000719
                        ],
                        [
                            -113.777960000023,
                            45.5232929997553
                        ],
                        [
                            -113.782138999556,
                            45.5218590001725
                        ],
                        [
                            -113.796856000041,
                            45.5234080000277
                        ],
                        [
                            -113.803820999561,
                            45.5227539999921
                        ],
                        [
                            -113.809658999676,
                            45.519795000092
                        ],
                        [
                            -113.820168000316,
                            45.5230999997166
                        ],
                        [
                            -113.82387400002,
                            45.5214280002549
                        ],
                        [
                            -113.834777999772,
                            45.5206570002449
                        ],
                        [
                            -113.829974000076,
                            45.5309150000595
                        ],
                        [
                            -113.827429999937,
                            45.533283999945
                        ],
                        [
                            -113.82906500023,
                            45.5381559998715
                        ],
                        [
                            -113.826039000042,
                            45.5436939998641
                        ],
                        [
                            -113.821457999928,
                            45.5473939997703
                        ],
                        [
                            -113.817408000267,
                            45.5570329999983
                        ],
                        [
                            -113.818031999708,
                            45.5626139999931
                        ],
                        [
                            -113.821468999799,
                            45.5659480001473
                        ],
                        [
                            -113.814983999779,
                            45.5696500000999
                        ],
                        [
                            -113.806928999764,
                            45.5781899998707
                        ],
                        [
                            -113.796746000436,
                            45.5815730000744
                        ],
                        [
                            -113.798752000449,
                            45.5870430002498
                        ],
                        [
                            -113.803710999956,
                            45.5926310003128
                        ],
                        [
                            -113.806430999822,
                            45.6016299999543
                        ],
                        [
                            -113.811028999654,
                            45.6057430002701
                        ],
                        [
                            -113.81819400009,
                            45.6078720002938
                        ],
                        [
                            -113.824095999778,
                            45.6128030002618
                        ],
                        [
                            -113.827871999803,
                            45.612906000229
                        ],
                        [
                            -113.834272000332,
                            45.6100760001842
                        ],
                        [
                            -113.83888000021,
                            45.610691000203
                        ],
                        [
                            -113.843495999586,
                            45.6127429998582
                        ],
                        [
                            -113.848639000115,
                            45.6201089998879
                        ],
                        [
                            -113.854608999575,
                            45.6222820001337
                        ],
                        [
                            -113.858907999657,
                            45.621667000311
                        ],
                        [
                            -113.861589000063,
                            45.6238200001058
                        ],
                        [
                            -113.885963000345,
                            45.6173820000703
                        ],
                        [
                            -113.89795199967,
                            45.6219039997851
                        ],
                        [
                            -113.904691000133,
                            45.6220069999997
                        ],
                        [
                            -113.904498999614,
                            45.6246820002465
                        ],
                        [
                            -113.899428999779,
                            45.6285040002781
                        ],
                        [
                            -113.901452000409,
                            45.630240999848
                        ],
                        [
                            -113.902392000217,
                            45.6375469997859
                        ],
                        [
                            -113.895277999835,
                            45.64384700031
                        ],
                        [
                            -113.903374999683,
                            45.6511279999492
                        ],
                        [
                            -113.907338000204,
                            45.6532490001116
                        ],
                        [
                            -113.911614999646,
                            45.6533069999357
                        ],
                        [
                            -113.914306999922,
                            45.6564260000681
                        ],
                        [
                            -113.919856000332,
                            45.6581169999516
                        ],
                        [
                            -113.920617999865,
                            45.6640649998493
                        ],
                        [
                            -113.931425000431,
                            45.6730360001388
                        ],
                        [
                            -113.930849999597,
                            45.6781720000734
                        ],
                        [
                            -113.934274000168,
                            45.6820049996885
                        ],
                        [
                            -113.932816000326,
                            45.6871729999723
                        ],
                        [
                            -113.93566199969,
                            45.6890940000164
                        ],
                        [
                            -113.933958999625,
                            45.692113999735
                        ],
                        [
                            -113.935260000007,
                            45.6943409997077
                        ],
                        [
                            -113.939104999629,
                            45.6957800000121
                        ],
                        [
                            -113.954877000448,
                            45.6906249998614
                        ],
                        [
                            -113.964816000277,
                            45.6900190000235
                        ],
                        [
                            -113.969187000329,
                            45.6927279998175
                        ],
                        [
                            -113.971738999965,
                            45.7008250001237
                        ],
                        [
                            -113.97076700037,
                            45.7029289998582
                        ],
                        [
                            -113.98000800024,
                            45.7023699998253
                        ],
                        [
                            -113.988526000038,
                            45.7059769998933
                        ],
                        [
                            -113.993903999844,
                            45.7033810001771
                        ],
                        [
                            -113.993721000445,
                            45.700652000161
                        ],
                        [
                            -113.996841000343,
                            45.6968580000944
                        ],
                        [
                            -114.008279000023,
                            45.6938309998983
                        ],
                        [
                            -114.015482000093,
                            45.6959010000388
                        ],
                        [
                            -114.019167999706,
                            45.692631000308
                        ],
                        [
                            -114.014365999659,
                            45.6837690002091
                        ],
                        [
                            -114.028309999842,
                            45.6752370000112
                        ],
                        [
                            -114.0238579996,
                            45.6706330001652
                        ],
                        [
                            -114.019120000026,
                            45.6696539999142
                        ],
                        [
                            -114.015658999645,
                            45.6642499997153
                        ],
                        [
                            -114.015532000322,
                            45.6605789997821
                        ],
                        [
                            -114.013703999685,
                            45.6582920000042
                        ],
                        [
                            -114.015270000381,
                            45.6527660001746
                        ],
                        [
                            -114.019068999972,
                            45.6484030002343
                        ],
                        [
                            -114.034556000386,
                            45.6476160003082
                        ],
                        [
                            -114.043249000085,
                            45.6404109998683
                        ],
                        [
                            -114.045133999725,
                            45.6367619998665
                        ],
                        [
                            -114.051838000028,
                            45.6327050002223
                        ],
                        [
                            -114.066737999912,
                            45.6281659999842
                        ],
                        [
                            -114.072453000003,
                            45.6212539999947
                        ],
                        [
                            -114.073561000041,
                            45.6150819997461
                        ],
                        [
                            -114.081857999905,
                            45.610567999859
                        ],
                        [
                            -114.083077000271,
                            45.6031399999384
                        ],
                        [
                            -114.082262999961,
                            45.5966990001884
                        ],
                        [
                            -114.086101999735,
                            45.5914640001975
                        ],
                        [
                            -114.090000999784,
                            45.5913899997221
                        ],
                        [
                            -114.100503999678,
                            45.5862039999241
                        ],
                        [
                            -114.121261999769,
                            45.5845920000071
                        ],
                        [
                            -114.130694000333,
                            45.5758330000676
                        ],
                        [
                            -114.132125000411,
                            45.5720950002398
                        ],
                        [
                            -114.129012000185,
                            45.5696859997026
                        ],
                        [
                            -114.128987000071,
                            45.5653850001074
                        ],
                        [
                            -114.133163999953,
                            45.5622639999022
                        ],
                        [
                            -114.13582100007,
                            45.5570980001305
                        ],
                        [
                            -114.150243999929,
                            45.5578310002197
                        ],
                        [
                            -114.154211999574,
                            45.5526179998649
                        ],
                        [
                            -114.164558000008,
                            45.5524979998665
                        ],
                        [
                            -114.174179999645,
                            45.5501600001038
                        ],
                        [
                            -114.180273000027,
                            45.5512380001408
                        ],
                        [
                            -114.19360799994,
                            45.536372000113
                        ],
                        [
                            -114.205262000428,
                            45.5359230001007
                        ],
                        [
                            -114.227873000371,
                            45.5461289998652
                        ],
                        [
                            -114.248449999813,
                            45.5458130000792
                        ],
                        [
                            -114.251589999804,
                            45.5375039998085
                        ],
                        [
                            -114.248734000394,
                            45.5345709999215
                        ],
                        [
                            -114.249471999637,
                            45.5292910000259
                        ],
                        [
                            -114.247210000429,
                            45.5247190001121
                        ],
                        [
                            -114.253473999618,
                            45.5150509998202
                        ],
                        [
                            -114.258501999621,
                            45.5112330000427
                        ],
                        [
                            -114.261475000105,
                            45.4949309997522
                        ],
                        [
                            -114.264548999973,
                            45.4921530000637
                        ],
                        [
                            -114.270022000214,
                            45.4908680001756
                        ],
                        [
                            -114.269891999619,
                            45.4845520000452
                        ],
                        [
                            -114.279996000204,
                            45.4798750001356
                        ],
                        [
                            -114.29506000012,
                            45.4793729999913
                        ],
                        [
                            -114.30378600033,
                            45.4715629997244
                        ],
                        [
                            -114.309219000388,
                            45.4693119999185
                        ],
                        [
                            -114.314403999851,
                            45.4696790002165
                        ],
                        [
                            -114.318736000443,
                            45.4661489997533
                        ],
                        [
                            -114.324357999749,
                            45.4658900002346
                        ],
                        [
                            -114.329707000142,
                            45.4620269999991
                        ],
                        [
                            -114.330046999901,
                            45.459722000186
                        ],
                        [
                            -114.345662000361,
                            45.4606569998346
                        ],
                        [
                            -114.350651000006,
                            45.4664709999816
                        ],
                        [
                            -114.351624000324,
                            45.4710359998554
                        ],
                        [
                            -114.361097999823,
                            45.4744089997258
                        ],
                        [
                            -114.360289000435,
                            45.478228999834
                        ],
                        [
                            -114.364560999854,
                            45.4836129999558
                        ],
                        [
                            -114.365119000071,
                            45.4893139998811
                        ],
                        [
                            -114.367201000252,
                            45.4918540000538
                        ],
                        [
                            -114.375339000107,
                            45.4939589998235
                        ],
                        [
                            -114.388869999839,
                            45.503002999893
                        ],
                        [
                            -114.392332000044,
                            45.5024589999582
                        ],
                        [
                            -114.413742000063,
                            45.5089880000861
                        ],
                        [
                            -114.423414999754,
                            45.5092989999414
                        ],
                        [
                            -114.425706999997,
                            45.510718999989
                        ],
                        [
                            -114.429533000251,
                            45.5175389997742
                        ],
                        [
                            -114.428785999888,
                            45.5209159997785
                        ],
                        [
                            -114.430903000229,
                            45.5243979999015
                        ],
                        [
                            -114.430574000341,
                            45.5295100000632
                        ],
                        [
                            -114.437122999935,
                            45.5308110000708
                        ],
                        [
                            -114.439942000433,
                            45.5369829998798
                        ],
                        [
                            -114.448801999639,
                            45.5388800002507
                        ],
                        [
                            -114.450524999795,
                            45.542345999891
                        ],
                        [
                            -114.463911999586,
                            45.5450289998123
                        ],
                        [
                            -114.461589000279,
                            45.5508020002934
                        ],
                        [
                            -114.462763000439,
                            45.5539500000015
                        ],
                        [
                            -114.456076999679,
                            45.5622400001432
                        ],
                        [
                            -114.465731999826,
                            45.5605069997463
                        ],
                        [
                            -114.471945999685,
                            45.565223
                        ],
                        [
                            -114.478044999915,
                            45.5619809997063
                        ],
                        [
                            -114.485249999635,
                            45.5618010000422
                        ],
                        [
                            -114.491917999953,
                            45.5571030000408
                        ],
                        [
                            -114.498079999933,
                            45.5554610003021
                        ],
                        [
                            -114.503078999623,
                            45.5590670000838
                        ],
                        [
                            -114.506863000044,
                            45.5593049999373
                        ],
                        [
                            -114.516341999565,
                            45.56799500026
                        ],
                        [
                            -114.527259999561,
                            45.570855000153
                        ],
                        [
                            -114.53193600011,
                            45.5662099997421
                        ],
                        [
                            -114.54858300044,
                            45.5610579997949
                        ],
                        [
                            -114.552638000124,
                            45.557019999837
                        ],
                        [
                            -114.560765999934,
                            45.559295999948
                        ],
                        [
                            -114.564936000144,
                            45.5577099999337
                        ],
                        [
                            -114.565490999988,
                            45.5626009998652
                        ],
                        [
                            -114.558522000269,
                            45.5660490000758
                        ],
                        [
                            -114.556775999649,
                            45.5729260002653
                        ],
                        [
                            -114.552956000141,
                            45.576603000284
                        ],
                        [
                            -114.554340000364,
                            45.5814800003035
                        ],
                        [
                            -114.55849799998,
                            45.584463000041
                        ],
                        [
                            -114.553875000033,
                            45.5884219998554
                        ],
                        [
                            -114.554035999692,
                            45.5914940001585
                        ],
                        [
                            -114.549272000178,
                            45.5953380000313
                        ],
                        [
                            -114.547643999558,
                            45.5996790001755
                        ],
                        [
                            -114.542017000229,
                            45.6020230002646
                        ],
                        [
                            -114.537944000103,
                            45.6065919999206
                        ],
                        [
                            -114.544687999691,
                            45.6169759998104
                        ],
                        [
                            -114.554598999931,
                            45.6198819996898
                        ],
                        [
                            -114.55829299994,
                            45.6273919999341
                        ],
                        [
                            -114.563229999706,
                            45.6316689999645
                        ],
                        [
                            -114.563987000115,
                            45.6376210003049
                        ],
                        [
                            -114.560664999651,
                            45.6402419997659
                        ],
                        [
                            -114.551713000384,
                            45.6424110002407
                        ],
                        [
                            -114.545435000054,
                            45.6429889996922
                        ],
                        [
                            -114.541674000098,
                            45.6413990002666
                        ],
                        [
                            -114.539674999757,
                            45.6460849998699
                        ],
                        [
                            -114.5413080004,
                            45.6485940000171
                        ],
                        [
                            -114.539494999832,
                            45.6505670002354
                        ],
                        [
                            -114.528879999785,
                            45.6524299998638
                        ],
                        [
                            -114.521927999785,
                            45.6494630002393
                        ],
                        [
                            -114.515051999953,
                            45.6531219997024
                        ],
                        [
                            -114.512726000273,
                            45.6560430002956
                        ],
                        [
                            -114.507646000391,
                            45.6575350000729
                        ],
                        [
                            -114.506361999728,
                            45.6618269998531
                        ],
                        [
                            -114.499532999752,
                            45.6687849997555
                        ],
                        [
                            -114.502281000105,
                            45.6739390002716
                        ],
                        [
                            -114.501455000099,
                            45.6773689997905
                        ],
                        [
                            -114.499029999787,
                            45.6788740000805
                        ],
                        [
                            -114.501188999961,
                            45.6824359997705
                        ],
                        [
                            -114.515086000288,
                            45.6849110001241
                        ],
                        [
                            -114.501385999604,
                            45.6937060003057
                        ],
                        [
                            -114.500737999874,
                            45.6965580000259
                        ],
                        [
                            -114.496570000212,
                            45.6994280000639
                        ],
                        [
                            -114.495148000355,
                            45.7039700001472
                        ],
                        [
                            -114.504181999638,
                            45.7218139997893
                        ],
                        [
                            -114.515366999597,
                            45.7274539999296
                        ],
                        [
                            -114.520521999821,
                            45.7265019998673
                        ],
                        [
                            -114.526653999664,
                            45.7276339998907
                        ],
                        [
                            -114.535634000317,
                            45.7390949998353
                        ],
                        [
                            -114.547850000321,
                            45.7432640002218
                        ],
                        [
                            -114.545747000223,
                            45.7469070000049
                        ],
                        [
                            -114.54731499967,
                            45.7518920000868
                        ],
                        [
                            -114.544511000139,
                            45.7591780001819
                        ],
                        [
                            -114.551243000031,
                            45.7639099996877
                        ],
                        [
                            -114.558282999895,
                            45.7647809998231
                        ],
                        [
                            -114.566465000131,
                            45.7733100000983
                        ],
                        [
                            -114.562450999557,
                            45.7797879998412
                        ],
                        [
                            -114.557004000153,
                            45.7834690002316
                        ],
                        [
                            -114.555619000106,
                            45.7865399997521
                        ],
                        [
                            -114.544091000014,
                            45.7903610001057
                        ],
                        [
                            -114.544967000249,
                            45.797897999812
                        ],
                        [
                            -114.541419999654,
                            45.7992359998774
                        ],
                        [
                            -114.537605999995,
                            45.8027599997189
                        ],
                        [
                            -114.537130999618,
                            45.8053050002065
                        ],
                        [
                            -114.531028999913,
                            45.8098679997089
                        ],
                        [
                            -114.530183999641,
                            45.8133750002417
                        ],
                        [
                            -114.532609999779,
                            45.8156430001803
                        ],
                        [
                            -114.531018000043,
                            45.8188980003077
                        ],
                        [
                            -114.511482999617,
                            45.8294360002369
                        ],
                        [
                            -114.516447000046,
                            45.8321380002794
                        ],
                        [
                            -114.517379000356,
                            45.8349470000755
                        ],
                        [
                            -114.515128000121,
                            45.8405130000173
                        ],
                        [
                            -114.509644000009,
                            45.8453570001253
                        ],
                        [
                            -114.50360000003,
                            45.8460030001398
                        ],
                        [
                            -114.498994999627,
                            45.8506919998361
                        ],
                        [
                            -114.492240999993,
                            45.8497540002548
                        ],
                        [
                            -114.48709699964,
                            45.8516710002942
                        ],
                        [
                            -114.474890999681,
                            45.8465849997154
                        ],
                        [
                            -114.471975999822,
                            45.8477669999489
                        ],
                        [
                            -114.470324999636,
                            45.8514649998699
                        ],
                        [
                            -114.464980000339,
                            45.852117000002
                        ],
                        [
                            -114.461550999746,
                            45.8551340001264
                        ],
                        [
                            -114.453674000006,
                            45.8557949997171
                        ],
                        [
                            -114.446371000377,
                            45.861431999691
                        ],
                        [
                            -114.437714999589,
                            45.8626079998493
                        ],
                        [
                            -114.424060000009,
                            45.8558089998099
                        ],
                        [
                            -114.409653999869,
                            45.8515729997866
                        ],
                        [
                            -114.40482099986,
                            45.8664829999021
                        ],
                        [
                            -114.405917000202,
                            45.8703999999618
                        ],
                        [
                            -114.403921000234,
                            45.8718759997259
                        ],
                        [
                            -114.396057000015,
                            45.871227999744
                        ],
                        [
                            -114.392928999721,
                            45.8787019998636
                        ],
                        [
                            -114.38984800018,
                            45.8803480002043
                        ],
                        [
                            -114.387868000106,
                            45.8839260001206
                        ],
                        [
                            -114.387602999792,
                            45.8901619998367
                        ],
                        [
                            -114.388913000395,
                            45.892251999766
                        ],
                        [
                            -114.393710000419,
                            45.8929689999072
                        ],
                        [
                            -114.395143000146,
                            45.9014330001412
                        ],
                        [
                            -114.404095000312,
                            45.9034699998038
                        ],
                        [
                            -114.413562999963,
                            45.9116310002256
                        ],
                        [
                            -114.416164000003,
                            45.9184330000886
                        ],
                        [
                            -114.414864000345,
                            45.9221329999008
                        ],
                        [
                            -114.431396000149,
                            45.9360400000043
                        ],
                        [
                            -114.431515999799,
                            45.9378910001124
                        ],
                        [
                            -114.426060000175,
                            45.9408069998896
                        ],
                        [
                            -114.424901999908,
                            45.9439810000586
                        ],
                        [
                            -114.414965999553,
                            45.9483899998982
                        ],
                        [
                            -114.411168999612,
                            45.9531099997835
                        ],
                        [
                            -114.40691800011,
                            45.9542450000485
                        ],
                        [
                            -114.402523999593,
                            45.9579439999494
                        ],
                        [
                            -114.40142800015,
                            45.9635440000166
                        ],
                        [
                            -114.406129999739,
                            45.9699809996963
                        ],
                        [
                            -114.410773999602,
                            45.9729999998743
                        ],
                        [
                            -114.411755000316,
                            45.9778700001633
                        ],
                        [
                            -114.421344000341,
                            45.9814109998904
                        ],
                        [
                            -114.428848999637,
                            45.986558999725
                        ],
                        [
                            -114.448500000414,
                            45.9876029998384
                        ],
                        [
                            -114.453644999694,
                            45.990994999968
                        ],
                        [
                            -114.457825999774,
                            45.9974780000063
                        ],
                        [
                            -114.464265999588,
                            45.997516000311
                        ],
                        [
                            -114.474099999836,
                            45.9922469999621
                        ],
                        [
                            -114.479992000377,
                            45.9929019998044
                        ],
                        [
                            -114.484820000363,
                            45.9963070002091
                        ],
                        [
                            -114.487782999903,
                            46.0036460002255
                        ],
                        [
                            -114.484931999618,
                            46.0082480001973
                        ],
                        [
                            -114.476204999583,
                            46.01127800005
                        ],
                        [
                            -114.473069999616,
                            46.0141500000165
                        ],
                        [
                            -114.480347000204,
                            46.0303489998726
                        ],
                        [
                            -114.485792999783,
                            46.0300219998119
                        ],
                        [
                            -114.489312999715,
                            46.0278689997668
                        ],
                        [
                            -114.497233999836,
                            46.0278860001689
                        ],
                        [
                            -114.507504000101,
                            46.03146399974
                        ],
                        [
                            -114.505728000242,
                            46.0343429999298
                        ],
                        [
                            -114.506647999959,
                            46.0385190000482
                        ],
                        [
                            -114.503303999754,
                            46.0418229998353
                        ],
                        [
                            -114.497510999845,
                            46.0423509998176
                        ],
                        [
                            -114.492880000401,
                            46.0501890000502
                        ],
                        [
                            -114.493349000031,
                            46.0525799997461
                        ],
                        [
                            -114.479138999709,
                            46.0557219998068
                        ],
                        [
                            -114.474704000083,
                            46.0583789997815
                        ],
                        [
                            -114.473900999644,
                            46.0605279998767
                        ],
                        [
                            -114.468286000011,
                            46.0627899998888
                        ],
                        [
                            -114.466988000002,
                            46.0657809999699
                        ],
                        [
                            -114.47212200031,
                            46.0692080001447
                        ],
                        [
                            -114.470798000362,
                            46.0744899997998
                        ],
                        [
                            -114.472351999565,
                            46.0768059999717
                        ],
                        [
                            -114.462011000053,
                            46.0785800002637
                        ],
                        [
                            -114.459480000333,
                            46.0939310002592
                        ],
                        [
                            -114.460475000393,
                            46.0977899996977
                        ],
                        [
                            -114.467008000094,
                            46.1045319998489
                        ],
                        [
                            -114.470882000028,
                            46.1056140001464
                        ],
                        [
                            -114.474453999838,
                            46.1131080001513
                        ],
                        [
                            -114.488455999748,
                            46.112985999841
                        ],
                        [
                            -114.495355999869,
                            46.1176759998932
                        ],
                        [
                            -114.504994000298,
                            46.1183900001962
                        ],
                        [
                            -114.520996000373,
                            46.1251120000407
                        ],
                        [
                            -114.517695999651,
                            46.1355849999237
                        ],
                        [
                            -114.521382000162,
                            46.1364500002337
                        ],
                        [
                            -114.527359000194,
                            46.145410999711
                        ],
                        [
                            -114.521824999853,
                            46.1507660001113
                        ],
                        [
                            -114.514368000238,
                            46.1539720001335
                        ],
                        [
                            -114.514857999784,
                            46.1676800002837
                        ],
                        [
                            -114.50748400001,
                            46.1646349998535
                        ],
                        [
                            -114.495581999825,
                            46.1679580001592
                        ],
                        [
                            -114.489205999585,
                            46.1676589997124
                        ],
                        [
                            -114.481163000164,
                            46.1621449997943
                        ],
                        [
                            -114.475952999688,
                            46.1606059999579
                        ],
                        [
                            -114.457701999926,
                            46.1701910001398
                        ],
                        [
                            -114.44663800034,
                            46.1679040002799
                        ],
                        [
                            -114.444090999829,
                            46.1692729998996
                        ],
                        [
                            -114.445947999879,
                            46.1739009999648
                        ],
                        [
                            -114.443304000181,
                            46.2030490000753
                        ],
                        [
                            -114.449189999976,
                            46.2092219998197
                        ],
                        [
                            -114.451828999651,
                            46.2148489996919
                        ],
                        [
                            -114.445634999885,
                            46.2197480000464
                        ],
                        [
                            -114.447984999854,
                            46.2315649999137
                        ],
                        [
                            -114.452089999767,
                            46.2412910001959
                        ],
                        [
                            -114.468266999744,
                            46.2486630001862
                        ],
                        [
                            -114.464832000201,
                            46.2522420000646
                        ],
                        [
                            -114.467800999589,
                            46.2564510000001
                        ],
                        [
                            -114.470719999646,
                            46.2660409997603
                        ],
                        [
                            -114.46492099989,
                            46.2732189998106
                        ],
                        [
                            -114.453657000288,
                            46.270947000299
                        ],
                        [
                            -114.441117000418,
                            46.2738649999289
                        ],
                        [
                            -114.432732999617,
                            46.278598000179
                        ],
                        [
                            -114.427288999688,
                            46.2837660001775
                        ],
                        [
                            -114.427406999689,
                            46.2862309999399
                        ],
                        [
                            -114.425440999858,
                            46.2878560000229
                        ],
                        [
                            -114.428231999869,
                            46.2910790002566
                        ],
                        [
                            -114.429927000436,
                            46.2996339999764
                        ],
                        [
                            -114.432176999948,
                            46.3013559999377
                        ],
                        [
                            -114.433433999949,
                            46.3055490000782
                        ],
                        [
                            -114.431638999823,
                            46.3071810001817
                        ],
                        [
                            -114.430051000285,
                            46.3134369997942
                        ],
                        [
                            -114.422514000303,
                            46.3240520002193
                        ],
                        [
                            -114.421930000147,
                            46.3300869999148
                        ],
                        [
                            -114.414022000446,
                            46.3355640000406
                        ],
                        [
                            -114.412183999764,
                            46.3578619997454
                        ],
                        [
                            -114.41024999972,
                            46.3610539997189
                        ],
                        [
                            -114.410247000246,
                            46.365730999794
                        ],
                        [
                            -114.414534999558,
                            46.3708139999053
                        ],
                        [
                            -114.422447000356,
                            46.3870459999551
                        ],
                        [
                            -114.415616999657,
                            46.393511000203
                        ],
                        [
                            -114.410038999833,
                            46.3963940000197
                        ],
                        [
                            -114.408550000029,
                            46.4006959999573
                        ],
                        [
                            -114.401336999913,
                            46.4015800001335
                        ],
                        [
                            -114.399689999925,
                            46.4047850000702
                        ],
                        [
                            -114.393495000333,
                            46.4092160000595
                        ],
                        [
                            -114.384842999744,
                            46.4113589998606
                        ],
                        [
                            -114.383661000086,
                            46.4184649997405
                        ],
                        [
                            -114.384909999691,
                            46.4275939999558
                        ],
                        [
                            -114.382960999579,
                            46.4321720002812
                        ],
                        [
                            -114.378830999552,
                            46.4350500002393
                        ],
                        [
                            -114.368413999872,
                            46.4371149998881
                        ],
                        [
                            -114.372339999684,
                            46.4428100001992
                        ],
                        [
                            -114.376196000075,
                            46.4428089998798
                        ],
                        [
                            -114.384400000053,
                            46.4470119998773
                        ],
                        [
                            -114.379430999601,
                            46.460289999776
                        ],
                        [
                            -114.383136000379,
                            46.4662579998578
                        ],
                        [
                            -114.39465500025,
                            46.4694260002712
                        ],
                        [
                            -114.395548000202,
                            46.4728970001604
                        ],
                        [
                            -114.400671999566,
                            46.4778129998169
                        ],
                        [
                            -114.400884000176,
                            46.4883829999657
                        ],
                        [
                            -114.403096000054,
                            46.4923469997399
                        ],
                        [
                            -114.403183999918,
                            46.4988110002333
                        ],
                        [
                            -114.399919000254,
                            46.5021929999907
                        ],
                        [
                            -114.383996999647,
                            46.5050730002625
                        ],
                        [
                            -114.375594000376,
                            46.5017539998951
                        ],
                        [
                            -114.367999999595,
                            46.5051130000422
                        ],
                        [
                            -114.358340000323,
                            46.5052119998806
                        ],
                        [
                            -114.348337000021,
                            46.5103340000646
                        ],
                        [
                            -114.347050999708,
                            46.5152809997603
                        ],
                        [
                            -114.342641000197,
                            46.5184310000453
                        ],
                        [
                            -114.34293599975,
                            46.5223729997823
                        ],
                        [
                            -114.347747999842,
                            46.5263450003081
                        ],
                        [
                            -114.349226999601,
                            46.5299440000108
                        ],
                        [
                            -114.348808000199,
                            46.5336560000147
                        ],
                        [
                            -114.343895999649,
                            46.5415100000612
                        ],
                        [
                            -114.345200000404,
                            46.5481349999325
                        ],
                        [
                            -114.347887999584,
                            46.5514710001304
                        ],
                        [
                            -114.342640000372,
                            46.5552250002284
                        ],
                        [
                            -114.339347000221,
                            46.5643410001128
                        ],
                        [
                            -114.331734000072,
                            46.5718560002182
                        ],
                        [
                            -114.32963900037,
                            46.5761989998762
                        ],
                        [
                            -114.334099999935,
                            46.5793580002741
                        ],
                        [
                            -114.339558000108,
                            46.5871310001284
                        ],
                        [
                            -114.344196999948,
                            46.5899690001629
                        ],
                        [
                            -114.332551999681,
                            46.596732000054
                        ],
                        [
                            -114.330256000138,
                            46.5994769997866
                        ],
                        [
                            -114.329945999619,
                            46.6042130003017
                        ],
                        [
                            -114.322531999661,
                            46.6108890002842
                        ],
                        [
                            -114.328060999979,
                            46.6197549998405
                        ],
                        [
                            -114.324510000085,
                            46.6219340000049
                        ],
                        [
                            -114.322149000245,
                            46.6258479997329
                        ],
                        [
                            -114.322360000132,
                            46.6338839999342
                        ],
                        [
                            -114.320303000065,
                            46.6382659998665
                        ],
                        [
                            -114.322816000242,
                            46.6420190000155
                        ],
                        [
                            -114.320704999748,
                            46.6469500000504
                        ],
                        [
                            -114.321622999815,
                            46.6489559997888
                        ],
                        [
                            -114.333124999967,
                            46.6609389997892
                        ],
                        [
                            -114.353000000151,
                            46.663557000252
                        ],
                        [
                            -114.357572000044,
                            46.6681399998841
                        ],
                        [
                            -114.358223999972,
                            46.6711379997373
                        ],
                        [
                            -114.362276000181,
                            46.6685089998662
                        ],
                        [
                            -114.378054999775,
                            46.6671310001926
                        ],
                        [
                            -114.383723999835,
                            46.6624580002833
                        ],
                        [
                            -114.389284000115,
                            46.6647630000618
                        ],
                        [
                            -114.394519999632,
                            46.6649169997771
                        ],
                        [
                            -114.403855999937,
                            46.6594870001859
                        ],
                        [
                            -114.409576999876,
                            46.6622440002179
                        ],
                        [
                            -114.412115999991,
                            46.6568250000368
                        ],
                        [
                            -114.424595999586,
                            46.66065999979
                        ],
                        [
                            -114.449000999831,
                            46.6495329999258
                        ],
                        [
                            -114.453477000362,
                            46.649209000115
                        ],
                        [
                            -114.454536999821,
                            46.6442590002084
                        ],
                        [
                            -114.452638999763,
                            46.6415610002509
                        ],
                        [
                            -114.466834000016,
                            46.6318010002066
                        ],
                        [
                            -114.487520999962,
                            46.6331630000109
                        ],
                        [
                            -114.497613999777,
                            46.637466999983
                        ],
                        [
                            -114.501055000066,
                            46.6413540002405
                        ],
                        [
                            -114.510032000346,
                            46.6392009998533
                        ],
                        [
                            -114.52384699976,
                            46.6405880000001
                        ],
                        [
                            -114.541822000236,
                            46.6499920002409
                        ],
                        [
                            -114.547032999638,
                            46.6446799997081
                        ],
                        [
                            -114.557462999737,
                            46.6440520000365
                        ],
                        [
                            -114.563182000026,
                            46.640822999971
                        ],
                        [
                            -114.570565000021,
                            46.6432989997646
                        ],
                        [
                            -114.582867000239,
                            46.6333670002014
                        ],
                        [
                            -114.588963000096,
                            46.6351700001187
                        ],
                        [
                            -114.593766999793,
                            46.6329420000163
                        ],
                        [
                            -114.599742000175,
                            46.6362960003074
                        ],
                        [
                            -114.605731999727,
                            46.6358999997327
                        ],
                        [
                            -114.614975000146,
                            46.639975000062
                        ],
                        [
                            -114.616270999606,
                            46.6437329998536
                        ],
                        [
                            -114.611656000055,
                            46.6474530002511
                        ],
                        [
                            -114.612054000439,
                            46.6506759999276
                        ],
                        [
                            -114.615304999859,
                            46.6524269997831
                        ],
                        [
                            -114.614564000242,
                            46.6550750002573
                        ],
                        [
                            -114.621023000322,
                            46.6579819996944
                        ],
                        [
                            -114.635779000267,
                            46.6594759998205
                        ],
                        [
                            -114.639891999678,
                            46.6656140001439
                        ],
                        [
                            -114.63336599965,
                            46.6696680000767
                        ],
                        [
                            -114.633139999694,
                            46.6718180002579
                        ],
                        [
                            -114.642563000038,
                            46.6729120002496
                        ],
                        [
                            -114.64024400003,
                            46.6753090001443
                        ],
                        [
                            -114.641443000305,
                            46.6794920000125
                        ],
                        [
                            -114.631840999861,
                            46.6839470002309
                        ],
                        [
                            -114.623543000172,
                            46.6909330001531
                        ],
                        [
                            -114.622908999787,
                            46.694444999924
                        ],
                        [
                            -114.624682999996,
                            46.6961129999123
                        ],
                        [
                            -114.620787000321,
                            46.7072619999694
                        ],
                        [
                            -114.623730999594,
                            46.7090779999954
                        ],
                        [
                            -114.624932000417,
                            46.7122579998899
                        ],
                        [
                            -114.633825000133,
                            46.7157819999439
                        ],
                        [
                            -114.639272000435,
                            46.7217859998128
                        ],
                        [
                            -114.644626999777,
                            46.7228320002249
                        ],
                        [
                            -114.645959999946,
                            46.7289989998608
                        ],
                        [
                            -114.648412999848,
                            46.7323149999764
                        ],
                        [
                            -114.659575000241,
                            46.7347510001382
                        ],
                        [
                            -114.665375999647,
                            46.7392340003042
                        ],
                        [
                            -114.671927999614,
                            46.7365699999949
                        ],
                        [
                            -114.681724000227,
                            46.7388939997427
                        ],
                        [
                            -114.688619000326,
                            46.736454000209
                        ],
                        [
                            -114.69859400014,
                            46.7404510000024
                        ],
                        [
                            -114.701020000278,
                            46.7366019997221
                        ],
                        [
                            -114.700036999913,
                            46.7336680002783
                        ],
                        [
                            -114.708942000223,
                            46.726871999929
                        ],
                        [
                            -114.70779999985,
                            46.7238919998537
                        ],
                        [
                            -114.71042500018,
                            46.7177039999769
                        ],
                        [
                            -114.713043999763,
                            46.7154259999815
                        ],
                        [
                            -114.716782999978,
                            46.7140530001606
                        ],
                        [
                            -114.727444999881,
                            46.714604000041
                        ],
                        [
                            -114.739773000038,
                            46.7119409998797
                        ],
                        [
                            -114.747464999829,
                            46.7029460002312
                        ],
                        [
                            -114.748479999981,
                            46.6985549998489
                        ],
                        [
                            -114.759356999969,
                            46.6965630000536
                        ],
                        [
                            -114.767237999906,
                            46.6971720001387
                        ],
                        [
                            -114.769515000081,
                            46.6999090002748
                        ],
                        [
                            -114.76854799961,
                            46.704762999885
                        ],
                        [
                            -114.78186499998,
                            46.7068329998416
                        ],
                        [
                            -114.788430000365,
                            46.7127699998368
                        ],
                        [
                            -114.786548000201,
                            46.7162919999048
                        ],
                        [
                            -114.78208099989,
                            46.7194990001684
                        ],
                        [
                            -114.782754999559,
                            46.7263499998857
                        ],
                        [
                            -114.77963499966,
                            46.7285889998412
                        ],
                        [
                            -114.779151999787,
                            46.7310170000626
                        ],
                        [
                            -114.773741000368,
                            46.7317489997963
                        ],
                        [
                            -114.769958999597,
                            46.7350420001557
                        ],
                        [
                            -114.765238999566,
                            46.7453909998624
                        ],
                        [
                            -114.76828400002,
                            46.7481519999863
                        ],
                        [
                            -114.765846000186,
                            46.7512390000377
                        ],
                        [
                            -114.767334999991,
                            46.7538289999905
                        ],
                        [
                            -114.765089999603,
                            46.7581500000726
                        ],
                        [
                            -114.773534999605,
                            46.7620869999425
                        ],
                        [
                            -114.774306000257,
                            46.7658060002671
                        ],
                        [
                            -114.778888000196,
                            46.7668470002883
                        ],
                        [
                            -114.779663999973,
                            46.7687270000585
                        ],
                        [
                            -114.783634000166,
                            46.7686660001481
                        ],
                        [
                            -114.786431000025,
                            46.7778859999673
                        ],
                        [
                            -114.784837999565,
                            46.7800190000362
                        ],
                        [
                            -114.787499999705,
                            46.7827759998732
                        ],
                        [
                            -114.789550000099,
                            46.7791139998992
                        ],
                        [
                            -114.798748000311,
                            46.775832000154
                        ],
                        [
                            -114.808386999667,
                            46.7823689997806
                        ],
                        [
                            -114.818876000215,
                            46.7812469999192
                        ],
                        [
                            -114.82910399975,
                            46.7826259999915
                        ],
                        [
                            -114.836649000127,
                            46.7924889999266
                        ],
                        [
                            -114.844787999807,
                            46.7943020002589
                        ],
                        [
                            -114.850139999675,
                            46.7990579998514
                        ],
                        [
                            -114.856905999902,
                            46.8016470002201
                        ],
                        [
                            -114.860604000109,
                            46.8057000000321
                        ],
                        [
                            -114.861142000235,
                            46.8116379997156
                        ],
                        [
                            -114.865779000425,
                            46.8145289997529
                        ],
                        [
                            -114.886945999871,
                            46.8095449997838
                        ],
                        [
                            -114.890731000117,
                            46.8029579998689
                        ],
                        [
                            -114.895189000207,
                            46.8016300001307
                        ],
                        [
                            -114.896519000003,
                            46.8072609997636
                        ],
                        [
                            -114.898571000047,
                            46.8089539997802
                        ],
                        [
                            -114.894804000243,
                            46.8173170001241
                        ],
                        [
                            -114.898448000023,
                            46.8223540001413
                        ],
                        [
                            -114.906272999884,
                            46.8223600000675
                        ],
                        [
                            -114.920730999903,
                            46.8270829998976
                        ],
                        [
                            -114.92471699999,
                            46.8334520000095
                        ],
                        [
                            -114.927286000243,
                            46.8334049999221
                        ],
                        [
                            -114.928739000063,
                            46.8352099997894
                        ],
                        [
                            -114.926762000362,
                            46.8387819997487
                        ],
                        [
                            -114.928666000268,
                            46.8436349997205
                        ],
                        [
                            -114.923989999719,
                            46.8481539997689
                        ],
                        [
                            -114.928682999986,
                            46.8547670000153
                        ],
                        [
                            -114.942089999869,
                            46.8565500001075
                        ],
                        [
                            -114.948261000069,
                            46.8600089999093
                        ],
                        [
                            -114.944571000258,
                            46.8678580000298
                        ],
                        [
                            -114.939620000248,
                            46.8688840001035
                        ],
                        [
                            -114.931421000293,
                            46.8769980002265
                        ],
                        [
                            -114.932335000162,
                            46.8874989997454
                        ],
                        [
                            -114.935703999583,
                            46.892106999874
                        ],
                        [
                            -114.935111000104,
                            46.8948849997982
                        ],
                        [
                            -114.936741000374,
                            46.897279000227
                        ],
                        [
                            -114.933531000064,
                            46.9035860002244
                        ],
                        [
                            -114.931042000177,
                            46.90408699993
                        ],
                        [
                            -114.929333000264,
                            46.9098890002741
                        ],
                        [
                            -114.92182800007,
                            46.9127029998376
                        ],
                        [
                            -114.925142999963,
                            46.9186810001945
                        ],
                        [
                            -114.935656999727,
                            46.9198410000579
                        ],
                        [
                            -114.94193099986,
                            46.9240749999439
                        ],
                        [
                            -114.960596999724,
                            46.9300099997573
                        ],
                        [
                            -114.958793000275,
                            46.9336260002626
                        ],
                        [
                            -114.964542999628,
                            46.9380759997867
                        ],
                        [
                            -114.971061999983,
                            46.9389860000497
                        ],
                        [
                            -114.980394999915,
                            46.9473860002322
                        ],
                        [
                            -114.986120000052,
                            46.9497360000442
                        ],
                        [
                            -114.985685999683,
                            46.9539409998388
                        ],
                        [
                            -114.990031999621,
                            46.9564250002386
                        ],
                        [
                            -114.993386999696,
                            46.9596050001183
                        ],
                        [
                            -114.993917000324,
                            46.9622280000887
                        ],
                        [
                            -115.000910000332,
                            46.9677030000003
                        ],
                        [
                            -115.00127400038,
                            46.9719010001306
                        ],
                        [
                            -115.028598999608,
                            46.9756749997741
                        ],
                        [
                            -115.031356000182,
                            46.9717239999755
                        ],
                        [
                            -115.048789000334,
                            46.9700360000633
                        ],
                        [
                            -115.051956000089,
                            46.9749029998243
                        ],
                        [
                            -115.050667000301,
                            46.975550000232
                        ],
                        [
                            -115.049944000228,
                            46.981056999854
                        ],
                        [
                            -115.0567239998,
                            46.9848449998049
                        ],
                        [
                            -115.061139000233,
                            46.9919340000139
                        ],
                        [
                            -115.067774999865,
                            46.9952120001215
                        ],
                        [
                            -115.065137000016,
                            47.0001650001695
                        ],
                        [
                            -115.072770000256,
                            47.012905999975
                        ],
                        [
                            -115.069416999831,
                            47.0150070000416
                        ],
                        [
                            -115.072268000116,
                            47.0234839998934
                        ],
                        [
                            -115.071678999937,
                            47.0263609997592
                        ],
                        [
                            -115.075665000024,
                            47.025854000188
                        ],
                        [
                            -115.079799000249,
                            47.0282400000438
                        ],
                        [
                            -115.081856000316,
                            47.0381859998608
                        ],
                        [
                            -115.088363000077,
                            47.0411210002877
                        ],
                        [
                            -115.087425999744,
                            47.045715999922
                        ],
                        [
                            -115.098008000179,
                            47.0485809998417
                        ],
                        [
                            -115.101945999687,
                            47.0474189997831
                        ],
                        [
                            -115.105791000207,
                            47.0484129998352
                        ],
                        [
                            -115.107871999665,
                            47.0516939998887
                        ],
                        [
                            -115.120901000154,
                            47.0618100002744
                        ],
                        [
                            -115.122482999845,
                            47.0658869997865
                        ],
                        [
                            -115.126682000367,
                            47.06608799974
                        ],
                        [
                            -115.131133999711,
                            47.0689559998658
                        ],
                        [
                            -115.134917000307,
                            47.0756730000473
                        ],
                        [
                            -115.139618999896,
                            47.0800189998024
                        ],
                        [
                            -115.142671999848,
                            47.0914660002123
                        ],
                        [
                            -115.140162000045,
                            47.09661300006
                        ],
                        [
                            -115.14412999969,
                            47.1019109998959
                        ],
                        [
                            -115.147253999787,
                            47.1032830002779
                        ],
                        [
                            -115.150012000186,
                            47.1017099999216
                        ],
                        [
                            -115.160125999917,
                            47.1020989997052
                        ],
                        [
                            -115.171652000358,
                            47.106781999871
                        ],
                        [
                            -115.173509000409,
                            47.10971700025
                        ],
                        [
                            -115.172901999788,
                            47.1122390002798
                        ],
                        [
                            -115.178763000367,
                            47.1174299999735
                        ],
                        [
                            -115.179295999571,
                            47.1203749997263
                        ],
                        [
                            -115.183599999676,
                            47.1216759997169
                        ],
                        [
                            -115.18626299964,
                            47.1243680002947
                        ],
                        [
                            -115.185987000354,
                            47.1294020000779
                        ],
                        [
                            -115.198230999947,
                            47.1371229998349
                        ],
                        [
                            -115.199040000234,
                            47.1398870001529
                        ],
                        [
                            -115.212529999957,
                            47.1419979999681
                        ],
                        [
                            -115.221893000026,
                            47.1489950000914
                        ],
                        [
                            -115.244618999597,
                            47.1513119999658
                        ],
                        [
                            -115.249340000352,
                            47.1596649997446
                        ],
                        [
                            -115.255462000148,
                            47.163691999725
                        ],
                        [
                            -115.256196000092,
                            47.1749769998221
                        ],
                        [
                            -115.261821999596,
                            47.1814309999361
                        ],
                        [
                            -115.286848999632,
                            47.1834849997795
                        ],
                        [
                            -115.29096399959,
                            47.1868149997095
                        ],
                        [
                            -115.300576999904,
                            47.1883340002623
                        ],
                        [
                            -115.301226000358,
                            47.1934520001088
                        ],
                        [
                            -115.299099999796,
                            47.1988349997724
                        ],
                        [
                            -115.295985999745,
                            47.2056580002259
                        ],
                        [
                            -115.291529000378,
                            47.2108540000921
                        ],
                        [
                            -115.294095000258,
                            47.2144529999514
                        ],
                        [
                            -115.294927999936,
                            47.2202769997531
                        ],
                        [
                            -115.298784000327,
                            47.2255039999978
                        ],
                        [
                            -115.315818000271,
                            47.2328040001671
                        ],
                        [
                            -115.320481000401,
                            47.2421890000949
                        ],
                        [
                            -115.32436899968,
                            47.2447239998584
                        ],
                        [
                            -115.318381999603,
                            47.2518919999115
                        ],
                        [
                            -115.319785999917,
                            47.2566840000091
                        ],
                        [
                            -115.323999999609,
                            47.256455999717
                        ],
                        [
                            -115.340441000075,
                            47.262869999988
                        ],
                        [
                            -115.347979999706,
                            47.2603959998469
                        ],
                        [
                            -115.353848999783,
                            47.262147999981
                        ],
                        [
                            -115.359219000092,
                            47.2596429999508
                        ],
                        [
                            -115.365323000346,
                            47.26140299978
                        ],
                        [
                            -115.370917999888,
                            47.2651799999013
                        ],
                        [
                            -115.37769599981,
                            47.264832999727
                        ],
                        [
                            -115.384666000252,
                            47.2678029999385
                        ],
                        [
                            -115.392113999646,
                            47.2653690000495
                        ],
                        [
                            -115.411584999599,
                            47.264369000133
                        ],
                        [
                            -115.415102999881,
                            47.2686489999901
                        ],
                        [
                            -115.421387999884,
                            47.2720099999218
                        ],
                        [
                            -115.420474000016,
                            47.2774739997015
                        ],
                        [
                            -115.425967000349,
                            47.2802779998545
                        ],
                        [
                            -115.436461999847,
                            47.2804339998888
                        ],
                        [
                            -115.444685000091,
                            47.2770210002489
                        ],
                        [
                            -115.449638999575,
                            47.2783979998812
                        ],
                        [
                            -115.455907999685,
                            47.2776179998332
                        ],
                        [
                            -115.457255000098,
                            47.2783210000762
                        ],
                        [
                            -115.457726000276,
                            47.2823999998036
                        ],
                        [
                            -115.465818000101,
                            47.2874769997945
                        ],
                        [
                            -115.482263999691,
                            47.2814440002792
                        ],
                        [
                            -115.490837999565,
                            47.2894820002883
                        ],
                        [
                            -115.503466000196,
                            47.290716000198
                        ],
                        [
                            -115.514590999881,
                            47.296566999896
                        ],
                        [
                            -115.528509000125,
                            47.2992439999629
                        ],
                        [
                            -115.527903000227,
                            47.3090210001413
                        ],
                        [
                            -115.537062000082,
                            47.3178379997588
                        ],
                        [
                            -115.540875999742,
                            47.3245889999497
                        ],
                        [
                            -115.538834999568,
                            47.3290590001109
                        ],
                        [
                            -115.550473000163,
                            47.3342629997019
                        ],
                        [
                            -115.548841000243,
                            47.3469580002441
                        ],
                        [
                            -115.550762999692,
                            47.3496860001264
                        ],
                        [
                            -115.556845000204,
                            47.3539269999737
                        ],
                        [
                            -115.564152000032,
                            47.3522429999958
                        ],
                        [
                            -115.571170999979,
                            47.3556729998383
                        ],
                        [
                            -115.575126999929,
                            47.3559030001108
                        ],
                        [
                            -115.578329999668,
                            47.360737000295
                        ],
                        [
                            -115.576039000148,
                            47.3653250000848
                        ],
                        [
                            -115.57763900028,
                            47.367404000108
                        ],
                        [
                            -115.595780000438,
                            47.3717189998676
                        ],
                        [
                            -115.601914999755,
                            47.3774889998659
                        ],
                        [
                            -115.617247000358,
                            47.3825199998735
                        ],
                        [
                            -115.630902999763,
                            47.3791800002481
                        ],
                        [
                            -115.636612000006,
                            47.3797290000613
                        ],
                        [
                            -115.643410999845,
                            47.3773400001394
                        ],
                        [
                            -115.648412999908,
                            47.382432000053
                        ],
                        [
                            -115.646293999917,
                            47.3877690000603
                        ],
                        [
                            -115.649953999591,
                            47.3923289997353
                        ],
                        [
                            -115.655595999887,
                            47.39420500011
                        ],
                        [
                            -115.657490999572,
                            47.4004549998421
                        ],
                        [
                            -115.663361000372,
                            47.4028820001942
                        ],
                        [
                            -115.667940999763,
                            47.4011229999156
                        ],
                        [
                            -115.677563000298,
                            47.4017860002226
                        ],
                        [
                            -115.675245000115,
                            47.4093929999998
                        ],
                        [
                            -115.685605999719,
                            47.4111540001899
                        ],
                        [
                            -115.692043999883,
                            47.4153419999889
                        ],
                        [
                            -115.697384000055,
                            47.4154400002437
                        ],
                        [
                            -115.699565999796,
                            47.417275000196
                        ],
                        [
                            -115.703221000345,
                            47.4155610002386
                        ],
                        [
                            -115.712261000374,
                            47.4154420001354
                        ],
                        [
                            -115.716909000435,
                            47.4199469997253
                        ],
                        [
                            -115.720834000423,
                            47.4210760000384
                        ],
                        [
                            -115.721112000257,
                            47.425697999707
                        ],
                        [
                            -115.733174000276,
                            47.4248870002825
                        ],
                        [
                            -115.738704000418,
                            47.4264260000056
                        ],
                        [
                            -115.742706000399,
                            47.4235569999631
                        ],
                        [
                            -115.750807000445,
                            47.4221130001559
                        ],
                        [
                            -115.759304000326,
                            47.4238619999714
                        ],
                        [
                            -115.757001000211,
                            47.4287699997375
                        ],
                        [
                            -115.75241400025,
                            47.4321529999545
                        ],
                        [
                            -115.751444000304,
                            47.4367650001756
                        ],
                        [
                            -115.747438999951,
                            47.4420419999868
                        ],
                        [
                            -115.734160999939,
                            47.4442809999765
                        ],
                        [
                            -115.7160940003,
                            47.4528799999376
                        ],
                        [
                            -115.702113000307,
                            47.4525010001018
                        ],
                        [
                            -115.693743999575,
                            47.4546209999337
                        ],
                        [
                            -115.691208999657,
                            47.4572060002662
                        ],
                        [
                            -115.682505000087,
                            47.4569250001849
                        ],
                        [
                            -115.675618000384,
                            47.4532169997076
                        ],
                        [
                            -115.646461000322,
                            47.4568299999324
                        ],
                        [
                            -115.6401629999,
                            47.4596870000742
                        ],
                        [
                            -115.637590000347,
                            47.462822000086
                        ],
                        [
                            -115.629487999578,
                            47.479609999883
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 47.0511771,
            "name": "Montana",
            "intptlon": -109.6348174,
            "geo_point_2d": [
                47.0333414226,
                -109.645096189
            ],
            "geoid": "30",
            "mtfcc": "G4000",
            "region": 4
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -109.645096189,
                47.0333414226
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "ddaf25c2b2e1a723c66e260963a49e9fc187d51a",
        "fields": {
            "arealand": "-621920778",
            "objectid": 5,
            "basename": "Maryland",
            "stusab": "MD",
            "statens": "01714934",
            "centlon": -76.6789663,
            "state": "24",
            "gid": 39,
            "centlat": 38.9463607,
            "division": 5,
            "areawater": "-1606750401",
            "oid": "-1945053491",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -77.4588079998747,
                            39.2202699998817
                        ],
                        [
                            -77.4576389997376,
                            39.2245950000595
                        ],
                        [
                            -77.4594969996128,
                            39.2277239997698
                        ],
                        [
                            -77.4711050000698,
                            39.2343029999416
                        ],
                        [
                            -77.4866460000121,
                            39.2472810000127
                        ],
                        [
                            -77.4972669999069,
                            39.2513279996957
                        ],
                        [
                            -77.5106679999417,
                            39.253256000226
                        ],
                        [
                            -77.5430860003681,
                            39.2669159997163
                        ],
                        [
                            -77.5541559998015,
                            39.2814310002066
                        ],
                        [
                            -77.5606900002251,
                            39.2859599998943
                        ],
                        [
                            -77.562774999881,
                            39.2944689997719
                        ],
                        [
                            -77.5618280004,
                            39.3014689998089
                        ],
                        [
                            -77.5665979997615,
                            39.3060469997998
                        ],
                        [
                            -77.5792249996694,
                            39.3050799999875
                        ],
                        [
                            -77.5926199998565,
                            39.3012010000008
                        ],
                        [
                            -77.6062660001137,
                            39.3037599998912
                        ],
                        [
                            -77.6159100003903,
                            39.3025479998335
                        ],
                        [
                            -77.6309249999029,
                            39.3077189996543
                        ],
                        [
                            -77.6440840000884,
                            39.3087500001206
                        ],
                        [
                            -77.6508989998211,
                            39.3108460001765
                        ],
                        [
                            -77.6650799998306,
                            39.3168049998015
                        ],
                        [
                            -77.675467000272,
                            39.3243600003225
                        ],
                        [
                            -77.681722000138,
                            39.323888000218
                        ],
                        [
                            -77.6864999998954,
                            39.3201989997619
                        ],
                        [
                            -77.6930080003797,
                            39.3184370001039
                        ],
                        [
                            -77.7076739999128,
                            39.3217280001463
                        ],
                        [
                            -77.7248400001018,
                            39.3214320002804
                        ],
                        [
                            -77.7346919998926,
                            39.3270659997163
                        ],
                        [
                            -77.7581940001385,
                            39.3355299997761
                        ],
                        [
                            -77.7609330002707,
                            39.3403120002974
                        ],
                        [
                            -77.7598269998828,
                            39.3444560001698
                        ],
                        [
                            -77.74759599981,
                            39.3513269997519
                        ],
                        [
                            -77.743383999767,
                            39.361139000254
                        ],
                        [
                            -77.7438399998766,
                            39.3651020002109
                        ],
                        [
                            -77.7523090001683,
                            39.3773980001064
                        ],
                        [
                            -77.75297699999,
                            39.3816789998977
                        ],
                        [
                            -77.7491759998508,
                            39.3843529997062
                        ],
                        [
                            -77.7366090002173,
                            39.3871480001363
                        ],
                        [
                            -77.7358690004256,
                            39.3911719999104
                        ],
                        [
                            -77.7384069998181,
                            39.3989759997095
                        ],
                        [
                            -77.7464030002817,
                            39.4093690002323
                        ],
                        [
                            -77.7541879999599,
                            39.4242429998248
                        ],
                        [
                            -77.7636830002736,
                            39.4283479999541
                        ],
                        [
                            -77.7743989997046,
                            39.4277969997176
                        ],
                        [
                            -77.7943159997214,
                            39.4309250001255
                        ],
                        [
                            -77.8015699998452,
                            39.4349429997069
                        ],
                        [
                            -77.8032689997118,
                            39.4379899996595
                        ],
                        [
                            -77.8009110002444,
                            39.4406740002436
                        ],
                        [
                            -77.7882390001306,
                            39.4429500001747
                        ],
                        [
                            -77.7852649998217,
                            39.4454030000447
                        ],
                        [
                            -77.7861209999644,
                            39.4474400000326
                        ],
                        [
                            -77.7982690001965,
                            39.456131000248
                        ],
                        [
                            -77.7980060004308,
                            39.4607690000498
                        ],
                        [
                            -77.7926739997563,
                            39.462064000316
                        ],
                        [
                            -77.7806009998672,
                            39.4598439998707
                        ],
                        [
                            -77.777604999817,
                            39.4622530002031
                        ],
                        [
                            -77.7953839995762,
                            39.4712470002775
                        ],
                        [
                            -77.7980039998827,
                            39.4752319998923
                        ],
                        [
                            -77.7971960003189,
                            39.4801389997948
                        ],
                        [
                            -77.7878099997852,
                            39.4854630000172
                        ],
                        [
                            -77.7672779996501,
                            39.4912710001586
                        ],
                        [
                            -77.7655410001488,
                            39.4934270001683
                        ],
                        [
                            -77.76634000039,
                            39.4962379996979
                        ],
                        [
                            -77.77090899991,
                            39.4989350001407
                        ],
                        [
                            -77.7821530003189,
                            39.4990360001932
                        ],
                        [
                            -77.7915699999165,
                            39.4909580002127
                        ],
                        [
                            -77.8028910003183,
                            39.4894600002983
                        ],
                        [
                            -77.8193610001982,
                            39.4935480001858
                        ],
                        [
                            -77.8275650001761,
                            39.4930859999315
                        ],
                        [
                            -77.8453780002704,
                            39.4981090001533
                        ],
                        [
                            -77.8478460002409,
                            39.5018200002291
                        ],
                        [
                            -77.8466000001109,
                            39.5045589997557
                        ],
                        [
                            -77.8257929996163,
                            39.5167100000429
                        ],
                        [
                            -77.8234979998981,
                            39.5237850002203
                        ],
                        [
                            -77.8247390000052,
                            39.5284840000977
                        ],
                        [
                            -77.8322700001385,
                            39.5323400002477
                        ],
                        [
                            -77.8369609998572,
                            39.5321120002419
                        ],
                        [
                            -77.84056000033,
                            39.5291419996646
                        ],
                        [
                            -77.8419450003772,
                            39.5184279998358
                        ],
                        [
                            -77.8494370001524,
                            39.5155950001715
                        ],
                        [
                            -77.8611760001307,
                            39.5142929997311
                        ],
                        [
                            -77.8647539997888,
                            39.5158699998944
                        ],
                        [
                            -77.866138000011,
                            39.5183990000721
                        ],
                        [
                            -77.8652750001957,
                            39.5384119999298
                        ],
                        [
                            -77.8716460004127,
                            39.5442809997827
                        ],
                        [
                            -77.8860230002408,
                            39.5516880002451
                        ],
                        [
                            -77.8887360004337,
                            39.5554099999939
                        ],
                        [
                            -77.8869179998432,
                            39.5599699997334
                        ],
                        [
                            -77.878548000184,
                            39.5634080002288
                        ],
                        [
                            -77.8432209997462,
                            39.5644639997656
                        ],
                        [
                            -77.8360870001712,
                            39.5666620003136
                        ],
                        [
                            -77.833352000237,
                            39.5711519998667
                        ],
                        [
                            -77.8296849999921,
                            39.5910830001388
                        ],
                        [
                            -77.8322070003893,
                            39.6014450001761
                        ],
                        [
                            -77.8374019998982,
                            39.6056869998217
                        ],
                        [
                            -77.8639289996083,
                            39.6087990000152
                        ],
                        [
                            -77.874264999996,
                            39.6140789998895
                        ],
                        [
                            -77.884916000028,
                            39.6158330001716
                        ],
                        [
                            -77.8869970003842,
                            39.6135999999127
                        ],
                        [
                            -77.8811759999881,
                            39.6063690000694
                        ],
                        [
                            -77.8811509998738,
                            39.6009790000395
                        ],
                        [
                            -77.8853610002669,
                            39.5976769997947
                        ],
                        [
                            -77.89197299961,
                            39.5977559997485
                        ],
                        [
                            -77.922719999759,
                            39.6047110002853
                        ],
                        [
                            -77.928353999659,
                            39.6136300000071
                        ],
                        [
                            -77.9327599998707,
                            39.6176089999763
                        ],
                        [
                            -77.9410850002223,
                            39.6190349998528
                        ],
                        [
                            -77.9447890002771,
                            39.6155659996903
                        ],
                        [
                            -77.9387350002525,
                            39.6128879999003
                        ],
                        [
                            -77.9358610004011,
                            39.6093650001935
                        ],
                        [
                            -77.9360370001282,
                            39.5967470001192
                        ],
                        [
                            -77.9391150001941,
                            39.5869990000712
                        ],
                        [
                            -77.9423559995684,
                            39.5848530002581
                        ],
                        [
                            -77.9461020003543,
                            39.5847920003411
                        ],
                        [
                            -77.9510330002725,
                            39.5895279998534
                        ],
                        [
                            -77.9522760000295,
                            39.5956749998247
                        ],
                        [
                            -77.950568999767,
                            39.6038360000122
                        ],
                        [
                            -77.955040000276,
                            39.6080779999163
                        ],
                        [
                            -77.9609579998582,
                            39.6088779997396
                        ],
                        [
                            -77.9671580003709,
                            39.6071089999954
                        ],
                        [
                            -77.9731789998853,
                            39.6013170001806
                        ],
                        [
                            -77.9776119998611,
                            39.5996080002814
                        ],
                        [
                            -78.0026120001321,
                            39.6005540002363
                        ],
                        [
                            -78.0101089999302,
                            39.603043999752
                        ],
                        [
                            -78.0234990000944,
                            39.6216989996972
                        ],
                        [
                            -78.0373210000787,
                            39.636983000131
                        ],
                        [
                            -78.0481489996627,
                            39.6435049997816
                        ],
                        [
                            -78.0542110000833,
                            39.6509860000918
                        ],
                        [
                            -78.0805560002185,
                            39.6705019998733
                        ],
                        [
                            -78.0895200000795,
                            39.6714449999852
                        ],
                        [
                            -78.0970439996417,
                            39.6782159997246
                        ],
                        [
                            -78.1053139997418,
                            39.6816819997027
                        ],
                        [
                            -78.1315199999598,
                            39.6867880002065
                        ],
                        [
                            -78.1398150001743,
                            39.6898159996788
                        ],
                        [
                            -78.1543009997823,
                            39.6906230002654
                        ],
                        [
                            -78.1626539997231,
                            39.6939179999222
                        ],
                        [
                            -78.1765419998296,
                            39.695979000213
                        ],
                        [
                            -78.1827580002359,
                            39.6951099996919
                        ],
                        [
                            -78.1910080002445,
                            39.6902999998677
                        ],
                        [
                            -78.1960250003765,
                            39.6826690001285
                        ],
                        [
                            -78.2022040000745,
                            39.6768560002153
                        ],
                        [
                            -78.2270789997738,
                            39.6762470001244
                        ],
                        [
                            -78.2325679999088,
                            39.6735829999576
                        ],
                        [
                            -78.232999999729,
                            39.6706619997761
                        ],
                        [
                            -78.2242529996029,
                            39.6635139998732
                        ],
                        [
                            -78.2238249999807,
                            39.6605389996579
                        ],
                        [
                            -78.2272110000183,
                            39.6570850002201
                        ],
                        [
                            -78.2381070002726,
                            39.6521519999385
                        ],
                        [
                            -78.2540669996163,
                            39.6401839997497
                        ],
                        [
                            -78.2623380004396,
                            39.6304949998511
                        ],
                        [
                            -78.2628630001461,
                            39.6224790000735
                        ],
                        [
                            -78.2660879996268,
                            39.6190549999614
                        ],
                        [
                            -78.286029999758,
                            39.6211920000317
                        ],
                        [
                            -78.3108569997766,
                            39.6306090001256
                        ],
                        [
                            -78.3352369999066,
                            39.6373590002572
                        ],
                        [
                            -78.3553229999777,
                            39.640662999735
                        ],
                        [
                            -78.3580129997061,
                            39.6397830000327
                        ],
                        [
                            -78.3592539998133,
                            39.6372080002534
                        ],
                        [
                            -78.3534569997066,
                            39.6305689996622
                        ],
                        [
                            -78.3555060002758,
                            39.6261109997408
                        ],
                        [
                            -78.360509999989,
                            39.6254339999826
                        ],
                        [
                            -78.3737810003275,
                            39.6303730001018
                        ],
                        [
                            -78.3823629996988,
                            39.6277929998477
                        ],
                        [
                            -78.3833550002839,
                            39.622913000225
                        ],
                        [
                            -78.372042000278,
                            39.6118689999231
                        ],
                        [
                            -78.3756109997153,
                            39.6083070001412
                        ],
                        [
                            -78.3839410000898,
                            39.6089809998143
                        ],
                        [
                            -78.3964550000199,
                            39.6164360001205
                        ],
                        [
                            -78.4205070000867,
                            39.6240729997826
                        ],
                        [
                            -78.4301389997694,
                            39.6234159996914
                        ],
                        [
                            -78.4332989998511,
                            39.6206989997854
                        ],
                        [
                            -78.4335470004466,
                            39.6182070001085
                        ],
                        [
                            -78.4254859996855,
                            39.6076719999495
                        ],
                        [
                            -78.4116169998455,
                            39.5974419998202
                        ],
                        [
                            -78.3988839996324,
                            39.5915920000799
                        ],
                        [
                            -78.3962030001248,
                            39.5892799997693
                        ],
                        [
                            -78.3948440000169,
                            39.5851520001528
                        ],
                        [
                            -78.3997130000109,
                            39.5807019999165
                        ],
                        [
                            -78.4073830000613,
                            39.5786070002057
                        ],
                        [
                            -78.4182990004072,
                            39.5809319997202
                        ],
                        [
                            -78.4260880002833,
                            39.5861369997031
                        ],
                        [
                            -78.4434979999715,
                            39.5913309999993
                        ],
                        [
                            -78.4504080001388,
                            39.5905510003352
                        ],
                        [
                            -78.4567719997847,
                            39.5876079998864
                        ],
                        [
                            -78.458039999656,
                            39.5797079998929
                        ],
                        [
                            -78.4542789996998,
                            39.5740820002798
                        ],
                        [
                            -78.4373970000912,
                            39.5631369997957
                        ],
                        [
                            -78.4263200000869,
                            39.5592219998842
                        ],
                        [
                            -78.417018999942,
                            39.5485600002308
                        ],
                        [
                            -78.4194800002398,
                            39.5456020000578
                        ],
                        [
                            -78.4245290001589,
                            39.5451800001885
                        ],
                        [
                            -78.431512000121,
                            39.5522839998266
                        ],
                        [
                            -78.4377169997584,
                            39.5533659996695
                        ],
                        [
                            -78.4391140003994,
                            39.5507910000406
                        ],
                        [
                            -78.4365540003674,
                            39.5482489997465
                        ],
                        [
                            -78.4346250003471,
                            39.542188999915
                        ],
                        [
                            -78.4378580002237,
                            39.5385159998243
                        ],
                        [
                            -78.4503599995598,
                            39.5498299998802
                        ],
                        [
                            -78.461116999897,
                            39.5508329998781
                        ],
                        [
                            -78.4619460002755,
                            39.5489500001911
                        ],
                        [
                            -78.4605339995659,
                            39.546188999699
                        ],
                        [
                            -78.4499190004172,
                            39.5433849997176
                        ],
                        [
                            -78.4486420003251,
                            39.5399599999495
                        ],
                        [
                            -78.4504569996442,
                            39.5379340002226
                        ],
                        [
                            -78.458003999671,
                            39.5383489999712
                        ],
                        [
                            -78.462367000225,
                            39.5363620000979
                        ],
                        [
                            -78.4629689999245,
                            39.5333979997131
                        ],
                        [
                            -78.4607269999097,
                            39.5285780000216
                        ],
                        [
                            -78.4617880000918,
                            39.522253999744
                        ],
                        [
                            -78.4662300002885,
                            39.5177960000194
                        ],
                        [
                            -78.4719420000063,
                            39.5159380001721
                        ],
                        [
                            -78.4836390001518,
                            39.5195410000658
                        ],
                        [
                            -78.4900029997978,
                            39.5176499997814
                        ],
                        [
                            -78.5032790001591,
                            39.5185820002421
                        ],
                        [
                            -78.5230859996727,
                            39.5248539997945
                        ],
                        [
                            -78.5661149999482,
                            39.5193709997489
                        ],
                        [
                            -78.578598999741,
                            39.5267310000782
                        ],
                        [
                            -78.587606000158,
                            39.5277210000308
                        ],
                        [
                            -78.5921980001425,
                            39.5316969999263
                        ],
                        [
                            -78.590058000235,
                            39.5354310003397
                        ],
                        [
                            -78.5909379997688,
                            39.5367479998237
                        ],
                        [
                            -78.5959930004339,
                            39.5360610002813
                        ],
                        [
                            -78.6025699996169,
                            39.532028999823
                        ],
                        [
                            -78.6068750004446,
                            39.5352059999645
                        ],
                        [
                            -78.6231009999271,
                            39.5396950000951
                        ],
                        [
                            -78.6286510001615,
                            39.5393900001344
                        ],
                        [
                            -78.6302980001495,
                            39.537129999995
                        ],
                        [
                            -78.6546969996476,
                            39.5346049998211
                        ],
                        [
                            -78.6641500001285,
                            39.5369870000157
                        ],
                        [
                            -78.6679090004349,
                            39.5402010001673
                        ],
                        [
                            -78.6754510004389,
                            39.5401650001362
                        ],
                        [
                            -78.6811899999209,
                            39.5438129998033
                        ],
                        [
                            -78.6895579999302,
                            39.5458239998781
                        ],
                        [
                            -78.6917070000586,
                            39.5510489998281
                        ],
                        [
                            -78.6950239996008,
                            39.5538349997832
                        ],
                        [
                            -78.707459999713,
                            39.5558390001383
                        ],
                        [
                            -78.7145780002927,
                            39.5628170000648
                        ],
                        [
                            -78.7245890000921,
                            39.5639189998459
                        ],
                        [
                            -78.7315939997956,
                            39.5746099997154
                        ],
                        [
                            -78.7333899997465,
                            39.5864580001737
                        ],
                        [
                            -78.7402410003625,
                            39.5864060001812
                        ],
                        [
                            -78.7430979995972,
                            39.5809969999929
                        ],
                        [
                            -78.7463320001971,
                            39.5797780001954
                        ],
                        [
                            -78.751053000053,
                            39.581804999743
                        ],
                        [
                            -78.7569859997038,
                            39.5808490002313
                        ],
                        [
                            -78.7670609999756,
                            39.5875109999343
                        ],
                        [
                            -78.7703099997457,
                            39.5950010000844
                        ],
                        [
                            -78.7781460003758,
                            39.6014509997267
                        ],
                        [
                            -78.7768049998113,
                            39.6041319999934
                        ],
                        [
                            -78.7677630001325,
                            39.6087919996674
                        ],
                        [
                            -78.7607640002767,
                            39.6100850000085
                        ],
                        [
                            -78.7512170000845,
                            39.6099610001973
                        ],
                        [
                            -78.7489440001077,
                            39.6059740000747
                        ],
                        [
                            -78.7468329996142,
                            39.6056610001259
                        ],
                        [
                            -78.7425799995633,
                            39.6092449999577
                        ],
                        [
                            -78.7385790003059,
                            39.6098600002651
                        ],
                        [
                            -78.7331870002552,
                            39.61529499974
                        ],
                        [
                            -78.7367959998755,
                            39.6221869997663
                        ],
                        [
                            -78.7467670003902,
                            39.6267219998004
                        ],
                        [
                            -78.7634370002867,
                            39.6188269999026
                        ],
                        [
                            -78.7776219995959,
                            39.6220000001025
                        ],
                        [
                            -78.7781580000714,
                            39.6253439997867
                        ],
                        [
                            -78.7726349996011,
                            39.6368849998212
                        ],
                        [
                            -78.7684920000535,
                            39.639315000026
                        ],
                        [
                            -78.7646580003023,
                            39.6458919999786
                        ],
                        [
                            -78.7661079997485,
                            39.6484540000202
                        ],
                        [
                            -78.7689930003689,
                            39.646307000267
                        ],
                        [
                            -78.7752790001971,
                            39.6456569997358
                        ],
                        [
                            -78.7811950001294,
                            39.636845999864
                        ],
                        [
                            -78.7907709997356,
                            39.6382749999721
                        ],
                        [
                            -78.7948830002195,
                            39.6371149998488
                        ],
                        [
                            -78.794097000397,
                            39.6345589997708
                        ],
                        [
                            -78.7974830004345,
                            39.6293120001636
                        ],
                        [
                            -78.8014019996762,
                            39.6283849998683
                        ],
                        [
                            -78.7957850003929,
                            39.6134020000709
                        ],
                        [
                            -78.7964620004354,
                            39.6059019997541
                        ],
                        [
                            -78.7998230003585,
                            39.6050350001364
                        ],
                        [
                            -78.8015749999285,
                            39.6067629998079
                        ],
                        [
                            -78.809028000244,
                            39.6081819998554
                        ],
                        [
                            -78.8118710001333,
                            39.597993999967
                        ],
                        [
                            -78.8183989998108,
                            39.5940590000955
                        ],
                        [
                            -78.8178649998834,
                            39.5918599998531
                        ],
                        [
                            -78.8193319999463,
                            39.589889000304
                        ],
                        [
                            -78.8257220004299,
                            39.5891770001602
                        ],
                        [
                            -78.8262050003037,
                            39.5773350002299
                        ],
                        [
                            -78.8199050002318,
                            39.5763329997016
                        ],
                        [
                            -78.8148529999397,
                            39.5712870001847
                        ],
                        [
                            -78.8132219998453,
                            39.5672080001003
                        ],
                        [
                            -78.8158460003497,
                            39.5628809999906
                        ],
                        [
                            -78.8219329999863,
                            39.5606210003316
                        ],
                        [
                            -78.8294590000967,
                            39.5649939997156
                        ],
                        [
                            -78.8392420002905,
                            39.5670079999773
                        ],
                        [
                            -78.8432600001646,
                            39.5627840000583
                        ],
                        [
                            -78.8472269999852,
                            39.5625079998301
                        ],
                        [
                            -78.8509760002458,
                            39.5601099999608
                        ],
                        [
                            -78.8520549999712,
                            39.5509719996537
                        ],
                        [
                            -78.8614309995609,
                            39.5409649998881
                        ],
                        [
                            -78.8706870003983,
                            39.5387669997272
                        ],
                        [
                            -78.8713789996111,
                            39.5356839997518
                        ],
                        [
                            -78.8693569997044,
                            39.531792999901
                        ],
                        [
                            -78.8751489997882,
                            39.5226650000522
                        ],
                        [
                            -78.878744000063,
                            39.5212740001155
                        ],
                        [
                            -78.8870519997979,
                            39.5229639999366
                        ],
                        [
                            -78.8889160004192,
                            39.5249120001557
                        ],
                        [
                            -78.8931019996246,
                            39.5239959996876
                        ],
                        [
                            -78.8911369996193,
                            39.5182170000837
                        ],
                        [
                            -78.8923910001452,
                            39.5132790000186
                        ],
                        [
                            -78.9055129996224,
                            39.5115450000273
                        ],
                        [
                            -78.9040809997196,
                            39.5040719997084
                        ],
                        [
                            -78.9077670002311,
                            39.4978390000781
                        ],
                        [
                            -78.9132439997723,
                            39.4945939996982
                        ],
                        [
                            -78.9167200002214,
                            39.4863199999736
                        ],
                        [
                            -78.9342980001391,
                            39.486283999743
                        ],
                        [
                            -78.9425389999269,
                            39.4806299996899
                        ],
                        [
                            -78.9385200002278,
                            39.4745720002742
                        ],
                        [
                            -78.9424920000712,
                            39.4689499998365
                        ],
                        [
                            -78.9524169996569,
                            39.46358300005
                        ],
                        [
                            -78.9589100000726,
                            39.4628380002007
                        ],
                        [
                            -78.9589619999511,
                            39.4597580001539
                        ],
                        [
                            -78.9542929999737,
                            39.45470300028
                        ],
                        [
                            -78.960545000365,
                            39.4510359998619
                        ],
                        [
                            -78.9559530003805,
                            39.4478390001599
                        ],
                        [
                            -78.9565729996233,
                            39.4403310000715
                        ],
                        [
                            -78.9656810003227,
                            39.4384700002185
                        ],
                        [
                            -78.969433999883,
                            39.4432589996573
                        ],
                        [
                            -78.9777349999452,
                            39.4483329999248
                        ],
                        [
                            -79.0109240001254,
                            39.4611210003147
                        ],
                        [
                            -79.0180309999362,
                            39.4676560001062
                        ],
                        [
                            -79.0308760003023,
                            39.4652539998205
                        ],
                        [
                            -79.035419999708,
                            39.4694929999363
                        ],
                        [
                            -79.0370230002133,
                            39.4766529997384
                        ],
                        [
                            -79.0444320001479,
                            39.4797950000756
                        ],
                        [
                            -79.0470249997919,
                            39.4833460002961
                        ],
                        [
                            -79.0527480002787,
                            39.4816779997022
                        ],
                        [
                            -79.0549650001791,
                            39.4722879999344
                        ],
                        [
                            -79.0569040002451,
                            39.4706719998506
                        ],
                        [
                            -79.0609770003709,
                            39.4718769998364
                        ],
                        [
                            -79.0663419997591,
                            39.4799709998059
                        ],
                        [
                            -79.068033000128,
                            39.4790080001869
                        ],
                        [
                            -79.069315000243,
                            39.4739670000932
                        ],
                        [
                            -79.0830169996784,
                            39.4714729997341
                        ],
                        [
                            -79.091130000318,
                            39.4725199997799
                        ],
                        [
                            -79.0999600002847,
                            39.4769489996984
                        ],
                        [
                            -79.1023830000494,
                            39.4763940003002
                        ],
                        [
                            -79.1054920000775,
                            39.4739439999848
                        ],
                        [
                            -79.1044200000248,
                            39.4707649996714
                        ],
                        [
                            -79.0972780000539,
                            39.4684560000428
                        ],
                        [
                            -79.0945420002948,
                            39.4648490000549
                        ],
                        [
                            -79.0963120003064,
                            39.4624209999801
                        ],
                        [
                            -79.1051740000602,
                            39.4630119996886
                        ],
                        [
                            -79.1078789998572,
                            39.4612209997106
                        ],
                        [
                            -79.1083299999439,
                            39.4571240000773
                        ],
                        [
                            -79.101805999566,
                            39.4571650002247
                        ],
                        [
                            -79.1028159996945,
                            39.4504820000436
                        ],
                        [
                            -79.1077779995748,
                            39.4458960001244
                        ],
                        [
                            -79.1138879996759,
                            39.4435209999922
                        ],
                        [
                            -79.1168600003349,
                            39.4388579999585
                        ],
                        [
                            -79.1121200002124,
                            39.4355109999352
                        ],
                        [
                            -79.110821000379,
                            39.4318540001119
                        ],
                        [
                            -79.1127319999576,
                            39.430853999753
                        ],
                        [
                            -79.1240359997427,
                            39.4332040002407
                        ],
                        [
                            -79.1290470000269,
                            39.4295420000678
                        ],
                        [
                            -79.1274419998716,
                            39.4181770000586
                        ],
                        [
                            -79.1298260001766,
                            39.4173030000688
                        ],
                        [
                            -79.1364989996191,
                            39.4188869999175
                        ],
                        [
                            -79.1418429999892,
                            39.4170979997273
                        ],
                        [
                            -79.1411380003576,
                            39.4086820000741
                        ],
                        [
                            -79.1437510000931,
                            39.4052020001799
                        ],
                        [
                            -79.1467339997245,
                            39.4045399998014
                        ],
                        [
                            -79.149979000195,
                            39.4060770000573
                        ],
                        [
                            -79.1495590000704,
                            39.4137270003435
                        ],
                        [
                            -79.1512410002185,
                            39.4169679996554
                        ],
                        [
                            -79.1553500003293,
                            39.4182989998002
                        ],
                        [
                            -79.1594660001127,
                            39.4170360001824
                        ],
                        [
                            -79.159415999884,
                            39.4095430000619
                        ],
                        [
                            -79.1645119996587,
                            39.4056789997155
                        ],
                        [
                            -79.1667050001679,
                            39.4009429999672
                        ],
                        [
                            -79.1654480001673,
                            39.3954720000548
                        ],
                        [
                            -79.1614779999737,
                            39.3909020000888
                        ],
                        [
                            -79.1622710003672,
                            39.3881290002875
                        ],
                        [
                            -79.1660649999353,
                            39.3874429996621
                        ],
                        [
                            -79.1745690003872,
                            39.3953790003349
                        ],
                        [
                            -79.1791839999379,
                            39.3964949998978
                        ],
                        [
                            -79.1811280000268,
                            39.3943939999993
                        ],
                        [
                            -79.1769589996416,
                            39.3890279999463
                        ],
                        [
                            -79.1855690003986,
                            39.3852079998898
                        ],
                        [
                            -79.1933030000231,
                            39.3886319997168
                        ],
                        [
                            -79.1976909997932,
                            39.3883729998658
                        ],
                        [
                            -79.2024440003345,
                            39.378801999761
                        ],
                        [
                            -79.2125849998302,
                            39.3702480001521
                        ],
                        [
                            -79.2142800003971,
                            39.3634820000151
                        ],
                        [
                            -79.2309550003165,
                            39.364904999963
                        ],
                        [
                            -79.2329189995986,
                            39.3596219996765
                        ],
                        [
                            -79.2349630001449,
                            39.3586769999045
                        ],
                        [
                            -79.2426099997308,
                            39.359415000337
                        ],
                        [
                            -79.2506010001716,
                            39.3554719996619
                        ],
                        [
                            -79.2563079998666,
                            39.3572270003217
                        ],
                        [
                            -79.2568679997332,
                            39.3553929998333
                        ],
                        [
                            -79.2534800000459,
                            39.347895999772
                        ],
                        [
                            -79.25735399998,
                            39.3419069999884
                        ],
                        [
                            -79.2538499999418,
                            39.3371439997439
                        ],
                        [
                            -79.2601740003031,
                            39.3342969997535
                        ],
                        [
                            -79.2686800004047,
                            39.3361630002696
                        ],
                        [
                            -79.270425000302,
                            39.3288820003071
                        ],
                        [
                            -79.2821319995949,
                            39.323273000335
                        ],
                        [
                            -79.2842260003699,
                            39.3154820002402
                        ],
                        [
                            -79.2838840000632,
                            39.3098439997822
                        ],
                        [
                            -79.2901609996705,
                            39.3035160001468
                        ],
                        [
                            -79.2903950000222,
                            39.2994520003283
                        ],
                        [
                            -79.3034310001841,
                            39.2996229999331
                        ],
                        [
                            -79.3139440001238,
                            39.3047060000317
                        ],
                        [
                            -79.3170470003041,
                            39.3040380002157
                        ],
                        [
                            -79.3212650001948,
                            39.3000049997529
                        ],
                        [
                            -79.3262460003418,
                            39.3010770000226
                        ],
                        [
                            -79.3326469997977,
                            39.3001299998673
                        ],
                        [
                            -79.3403749995745,
                            39.2936710000082
                        ],
                        [
                            -79.3460110000226,
                            39.2939599997308
                        ],
                        [
                            -79.3473890003971,
                            39.2911020001317
                        ],
                        [
                            -79.34365600003,
                            39.287117999661
                        ],
                        [
                            -79.3449149996804,
                            39.2849690003036
                        ],
                        [
                            -79.3513079996387,
                            39.2850710002978
                        ],
                        [
                            -79.3522960000259,
                            39.2802020003157
                        ],
                        [
                            -79.35752499987,
                            39.2763050000895
                        ],
                        [
                            -79.3760140001819,
                            39.2732170002327
                        ],
                        [
                            -79.3788090003906,
                            39.2718179998977
                        ],
                        [
                            -79.3818570003193,
                            39.2668650000824
                        ],
                        [
                            -79.3873380000584,
                            39.2678200001451
                        ],
                        [
                            -79.3873759996933,
                            39.2635489997676
                        ],
                        [
                            -79.3902579999406,
                            39.2613639998134
                        ],
                        [
                            -79.3932849999529,
                            39.2616270002628
                        ],
                        [
                            -79.3950039999109,
                            39.257074000005
                        ],
                        [
                            -79.3995599999104,
                            39.2550419998382
                        ],
                        [
                            -79.3983880002986,
                            39.2534079999069
                        ],
                        [
                            -79.3996650003907,
                            39.2515049997704
                        ],
                        [
                            -79.4094519998841,
                            39.2461990002631
                        ],
                        [
                            -79.4083349996256,
                            39.2450699998959
                        ],
                        [
                            -79.4122169999556,
                            39.2401930002565
                        ],
                        [
                            -79.4202610000999,
                            39.238882999844
                        ],
                        [
                            -79.4206800003995,
                            39.2354240001199
                        ],
                        [
                            -79.4252210003304,
                            39.2344770002367
                        ],
                        [
                            -79.4240539998431,
                            39.2273980000766
                        ],
                        [
                            -79.425713000425,
                            39.225314000279
                        ],
                        [
                            -79.4329840002673,
                            39.2238870003438
                        ],
                        [
                            -79.4406469997467,
                            39.2165060002105
                        ],
                        [
                            -79.4474239998446,
                            39.2156819999402
                        ],
                        [
                            -79.4533039997919,
                            39.2104699996547
                        ],
                        [
                            -79.460555000441,
                            39.2115559999218
                        ],
                        [
                            -79.4673869998921,
                            39.2087650000769
                        ],
                        [
                            -79.4696039997925,
                            39.2072160000437
                        ],
                        [
                            -79.4697929999384,
                            39.2037340001347
                        ],
                        [
                            -79.4733420001825,
                            39.2021290002049
                        ],
                        [
                            -79.4868749995635,
                            39.2058960001089
                        ],
                        [
                            -79.4858739996559,
                            39.2649050003253
                        ],
                        [
                            -79.4872739997717,
                            39.2652049998098
                        ],
                        [
                            -79.4876510002386,
                            39.2799329998469
                        ],
                        [
                            -79.4860720000226,
                            39.3442999999049
                        ],
                        [
                            -79.4843530000646,
                            39.3450440002298
                        ],
                        [
                            -79.482366000318,
                            39.5316890003266
                        ],
                        [
                            -79.4788659995794,
                            39.5316890003266
                        ],
                        [
                            -79.4777640002877,
                            39.6422819999287
                        ],
                        [
                            -79.4765609998154,
                            39.6427379996742
                        ],
                        [
                            -79.4766620000978,
                            39.7210780003069
                        ],
                        [
                            -79.0455479996832,
                            39.7228830002726
                        ],
                        [
                            -78.3151390001398,
                            39.7228770002001
                        ],
                        [
                            -77.5068099999011,
                            39.7198700000023
                        ],
                        [
                            -76.9909030002307,
                            39.7197989999248
                        ],
                        [
                            -75.7885960003851,
                            39.7221989997067
                        ],
                        [
                            -75.7886349998449,
                            39.6495680000928
                        ],
                        [
                            -75.6936700002328,
                            38.4600799997912
                        ],
                        [
                            -75.4108800002456,
                            38.4522969997386
                        ],
                        [
                            -74.986281999813,
                            38.4516319997754
                        ],
                        [
                            -74.993346999791,
                            38.4064470002282
                        ],
                        [
                            -74.9996250001215,
                            38.3829709999176
                        ],
                        [
                            -74.9996250001215,
                            38.3716680002131
                        ],
                        [
                            -75.0190950002496,
                            38.3214249999759
                        ],
                        [
                            -75.0208049999868,
                            38.3097559997798
                        ],
                        [
                            -75.0312950003603,
                            38.2947369996635
                        ],
                        [
                            -75.0414569997724,
                            38.2870089997338
                        ],
                        [
                            -75.0878129996357,
                            38.189356000219
                        ],
                        [
                            -75.1246249996799,
                            38.0939010000078
                        ],
                        [
                            -75.1352000004423,
                            38.0744690001356
                        ],
                        [
                            -75.1664349995895,
                            38.0278340000703
                        ],
                        [
                            -75.2496090002431,
                            38.0267150000812
                        ],
                        [
                            -75.6244489999831,
                            37.9941949998984
                        ],
                        [
                            -75.6256110004475,
                            37.9897980002914
                        ],
                        [
                            -75.6308679998806,
                            37.987816000322
                        ],
                        [
                            -75.6338320001437,
                            37.9845170001545
                        ],
                        [
                            -75.6288529996466,
                            37.9777949999065
                        ],
                        [
                            -75.6295320002372,
                            37.9759629999791
                        ],
                        [
                            -75.6320079997054,
                            37.976017999886
                        ],
                        [
                            -75.6357350002248,
                            37.9795340003059
                        ],
                        [
                            -75.6382219995636,
                            37.9793949998982
                        ],
                        [
                            -75.6482280002384,
                            37.9667709998575
                        ],
                        [
                            -75.6477000001588,
                            37.9469630002959
                        ],
                        [
                            -75.6556809996556,
                            37.9454329997954
                        ],
                        [
                            -75.6652999998178,
                            37.9495110002623
                        ],
                        [
                            -75.6913210000879,
                            37.9554960000357
                        ],
                        [
                            -75.7046340002592,
                            37.9546979999537
                        ],
                        [
                            -75.7616419999794,
                            37.9413659997644
                        ],
                        [
                            -75.8012400000115,
                            37.912174000344
                        ],
                        [
                            -75.8829390002063,
                            37.9113350000094
                        ],
                        [
                            -75.9526719997437,
                            37.9068269997509
                        ],
                        [
                            -75.9436929998141,
                            37.9461329996952
                        ],
                        [
                            -75.9933379996306,
                            37.9534870000633
                        ],
                        [
                            -76.0520209998262,
                            37.953577999979
                        ],
                        [
                            -76.1246609997254,
                            37.9287079999005
                        ],
                        [
                            -76.2364579996156,
                            37.8866049997297
                        ],
                        [
                            -76.2365720003168,
                            37.8901460001092
                        ],
                        [
                            -76.2407769997887,
                            37.890625999937
                        ],
                        [
                            -76.2479060002391,
                            37.8948689998404
                        ],
                        [
                            -76.261811999889,
                            37.9087859998417
                        ],
                        [
                            -76.3333720002378,
                            37.9429559999309
                        ],
                        [
                            -76.3593249998376,
                            37.9522730002115
                        ],
                        [
                            -76.3890119996293,
                            37.9583499999641
                        ],
                        [
                            -76.4129339999998,
                            37.966331999959
                        ],
                        [
                            -76.4254599996254,
                            37.9768709998206
                        ],
                        [
                            -76.4338999996048,
                            37.9818470000782
                        ],
                        [
                            -76.4650080003271,
                            38.0132200003033
                        ],
                        [
                            -76.4733970002528,
                            38.0162319999187
                        ],
                        [
                            -76.4808940000509,
                            38.0152769996672
                        ],
                        [
                            -76.4919760000781,
                            38.0177140001256
                        ],
                        [
                            -76.516608999928,
                            38.0267829999005
                        ],
                        [
                            -76.5161600003894,
                            38.0449199998134
                        ],
                        [
                            -76.5263579997866,
                            38.0531079997467
                        ],
                        [
                            -76.5341910000436,
                            38.0635389999172
                        ],
                        [
                            -76.5364570003478,
                            38.0749030001425
                        ],
                        [
                            -76.5566289997345,
                            38.0858730000765
                        ],
                        [
                            -76.5815679999061,
                            38.0959089998953
                        ],
                        [
                            -76.5880799996901,
                            38.1022080001807
                        ],
                        [
                            -76.5961559996214,
                            38.1066760002497
                        ],
                        [
                            -76.6004670002968,
                            38.1130879997792
                        ],
                        [
                            -76.6017209999245,
                            38.1251290002303
                        ],
                        [
                            -76.6103840003849,
                            38.1485160002561
                        ],
                        [
                            -76.6049720002427,
                            38.1479899997502
                        ],
                        [
                            -76.6054300000021,
                            38.1489330000971
                        ],
                        [
                            -76.6213299999697,
                            38.1506380001042
                        ],
                        [
                            -76.6291630002267,
                            38.1534830002444
                        ],
                        [
                            -76.6359720001117,
                            38.1525599998867
                        ],
                        [
                            -76.690265999814,
                            38.1605489998267
                        ],
                        [
                            -76.7514169999802,
                            38.1646890000258
                        ],
                        [
                            -76.7639729997431,
                            38.1677909998487
                        ],
                        [
                            -76.7998869998118,
                            38.1689329997003
                        ],
                        [
                            -76.8242839996601,
                            38.1636460000416
                        ],
                        [
                            -76.839367999668,
                            38.1636089998216
                        ],
                        [
                            -76.8542609998801,
                            38.1673019997119
                        ],
                        [
                            -76.8588360001462,
                            38.1709989997466
                        ],
                        [
                            -76.8767439997768,
                            38.173019000247
                        ],
                        [
                            -76.9111299996832,
                            38.1973570002696
                        ],
                        [
                            -76.9161769999524,
                            38.1997350000335
                        ],
                        [
                            -76.9368379999589,
                            38.2023849999221
                        ],
                        [
                            -76.9490199996279,
                            38.2084179997156
                        ],
                        [
                            -76.9622509997834,
                            38.2298880001568
                        ],
                        [
                            -76.958099000015,
                            38.2340580002682
                        ],
                        [
                            -76.9569230002052,
                            38.2374720002958
                        ],
                        [
                            -76.962085999927,
                            38.2569640001192
                        ],
                        [
                            -76.9813459999927,
                            38.274541999683
                        ],
                        [
                            -76.9908689998955,
                            38.273638999973
                        ],
                        [
                            -77.0011419996357,
                            38.2801810001223
                        ],
                        [
                            -77.0164490001247,
                            38.2988370001844
                        ],
                        [
                            -77.0235080002548,
                            38.3014550002776
                        ],
                        [
                            -77.0281310002015,
                            38.3053169998185
                        ],
                        [
                            -77.028764999688,
                            38.3113820000606
                        ],
                        [
                            -77.0306829998376,
                            38.3116220001901
                        ],
                        [
                            -77.0145269997771,
                            38.3326339998758
                        ],
                        [
                            -77.0143479996769,
                            38.3377230001715
                        ],
                        [
                            -77.0168070003249,
                            38.3416879999871
                        ],
                        [
                            -77.0146480001509,
                            38.3540430001923
                        ],
                        [
                            -77.0159109999993,
                            38.3710239998175
                        ],
                        [
                            -77.0114639997797,
                            38.3744249996547
                        ],
                        [
                            -77.0180190001198,
                            38.3816010003022
                        ],
                        [
                            -77.0415049995738,
                            38.4006919997718
                        ],
                        [
                            -77.0534200001775,
                            38.3988130001278
                        ],
                        [
                            -77.0705500003815,
                            38.3781509996813
                        ],
                        [
                            -77.0842379995732,
                            38.3687389996671
                        ],
                        [
                            -77.0900890001065,
                            38.3672750000838
                        ],
                        [
                            -77.1048740003637,
                            38.3697459997729
                        ],
                        [
                            -77.124699000319,
                            38.3667599998293
                        ],
                        [
                            -77.1382240002024,
                            38.3679159996483
                        ],
                        [
                            -77.158367000175,
                            38.3485020002727
                        ],
                        [
                            -77.1632189995522,
                            38.3458229998221
                        ],
                        [
                            -77.1793389996277,
                            38.3419140000479
                        ],
                        [
                            -77.204693999726,
                            38.3410959998276
                        ],
                        [
                            -77.2352080002464,
                            38.3325360000108
                        ],
                        [
                            -77.247148999891,
                            38.3315230000107
                        ],
                        [
                            -77.2652950000713,
                            38.3331639997401
                        ],
                        [
                            -77.276581000313,
                            38.3378810003122
                        ],
                        [
                            -77.2837960000788,
                            38.3428550002625
                        ],
                        [
                            -77.2884070003299,
                            38.3514010002553
                        ],
                        [
                            -77.287264000132,
                            38.3530919996939
                        ],
                        [
                            -77.2881949997194,
                            38.3593849999304
                        ],
                        [
                            -77.2967920000576,
                            38.3705000000481
                        ],
                        [
                            -77.3049380003092,
                            38.3752710002261
                        ],
                        [
                            -77.3108359997998,
                            38.3968820002771
                        ],
                        [
                            -77.3104449999876,
                            38.4001010002623
                        ],
                        [
                            -77.3187860002327,
                            38.4176710002749
                        ],
                        [
                            -77.322106000148,
                            38.428944000082
                        ],
                        [
                            -77.3230090001463,
                            38.4334539998709
                        ],
                        [
                            -77.3203999997105,
                            38.4352209999884
                        ],
                        [
                            -77.3185419998353,
                            38.4744370001315
                        ],
                        [
                            -77.3153159996314,
                            38.4785490002929
                        ],
                        [
                            -77.3096380002487,
                            38.4956360000291
                        ],
                        [
                            -77.3030839997336,
                            38.499188000321
                        ],
                        [
                            -77.2957760000814,
                            38.5073840002258
                        ],
                        [
                            -77.2897379999503,
                            38.5180559998533
                        ],
                        [
                            -77.2871210000169,
                            38.5204009998522
                        ],
                        [
                            -77.2853090001724,
                            38.5196949997314
                        ],
                        [
                            -77.2872940002692,
                            38.521463000036
                        ],
                        [
                            -77.2829139999968,
                            38.5255580002689
                        ],
                        [
                            -77.2789739999405,
                            38.5365099997758
                        ],
                        [
                            -77.2763389995653,
                            38.5394450003375
                        ],
                        [
                            -77.2761219998304,
                            38.5474199999623
                        ],
                        [
                            -77.2729480004033,
                            38.5512439997722
                        ],
                        [
                            -77.2619729996079,
                            38.5563339999317
                        ],
                        [
                            -77.2612489997097,
                            38.5593579996551
                        ],
                        [
                            -77.2564540002339,
                            38.5605869997997
                        ],
                        [
                            -77.2463620002436,
                            38.5934410001575
                        ],
                        [
                            -77.2036960001914,
                            38.6181720001435
                        ],
                        [
                            -77.1914869998599,
                            38.6191749998532
                        ],
                        [
                            -77.1802719997633,
                            38.6232459997903
                        ],
                        [
                            -77.1663650002885,
                            38.6251059996779
                        ],
                        [
                            -77.1296820001157,
                            38.6347039998598
                        ],
                        [
                            -77.133506999646,
                            38.6425249997624
                        ],
                        [
                            -77.1329319997108,
                            38.6447149998401
                        ],
                        [
                            -77.1362929996339,
                            38.6472310002838
                        ],
                        [
                            -77.1364230002286,
                            38.6502260003447
                        ],
                        [
                            -77.1324389997913,
                            38.6741429998242
                        ],
                        [
                            -77.1226320002064,
                            38.6852559998184
                        ],
                        [
                            -77.1037649996027,
                            38.6978599998951
                        ],
                        [
                            -77.098272000168,
                            38.6990530001602
                        ],
                        [
                            -77.0800370002993,
                            38.708923999946
                        ],
                        [
                            -77.0807999996572,
                            38.711125000313
                        ],
                        [
                            -77.0792320002102,
                            38.7130520000877
                        ],
                        [
                            -77.0743039997667,
                            38.7129019998893
                        ],
                        [
                            -77.0714330002884,
                            38.7099809998137
                        ],
                        [
                            -77.0535220002847,
                            38.7098039999756
                        ],
                        [
                            -77.0461960001909,
                            38.7144309999531
                        ],
                        [
                            -77.0433440000808,
                            38.7184400001887
                        ],
                        [
                            -77.0414689995888,
                            38.7258470000072
                        ],
                        [
                            -77.0415899999627,
                            38.7368170002298
                        ],
                        [
                            -77.0434779999751,
                            38.7392409997736
                        ],
                        [
                            -77.0422679998301,
                            38.7411550000355
                        ],
                        [
                            -77.0404650002065,
                            38.7904049997555
                        ],
                        [
                            -76.9093930001818,
                            38.8928520002621
                        ],
                        [
                            -77.040881999958,
                            38.9958450003095
                        ],
                        [
                            -77.1197590001799,
                            38.9343430002786
                        ],
                        [
                            -77.1286540004439,
                            38.9413980002227
                        ],
                        [
                            -77.135999999731,
                            38.954364999652
                        ],
                        [
                            -77.1487730001271,
                            38.9650359998975
                        ],
                        [
                            -77.1646949998359,
                            38.9677480001573
                        ],
                        [
                            -77.1698020003796,
                            38.9663150000751
                        ],
                        [
                            -77.1814469997482,
                            38.9687880000279
                        ],
                        [
                            -77.1940939997476,
                            38.9682280001683
                        ],
                        [
                            -77.1977740004113,
                            38.966739999884
                        ],
                        [
                            -77.2081380003881,
                            38.9702470002891
                        ],
                        [
                            -77.2115200002277,
                            38.969343999774
                        ],
                        [
                            -77.2237489997523,
                            38.9721199996667
                        ],
                        [
                            -77.22995000009,
                            38.9797090002832
                        ],
                        [
                            -77.2323279996489,
                            38.9792880000944
                        ],
                        [
                            -77.2351959996525,
                            38.9760800002506
                        ],
                        [
                            -77.2399500000188,
                            38.98075399971
                        ],
                        [
                            -77.2450299998999,
                            38.9826840001023
                        ],
                        [
                            -77.2486140003041,
                            38.9926239997658
                        ],
                        [
                            -77.2529400001499,
                            38.9954509999489
                        ],
                        [
                            -77.2557510002521,
                            39.001748999863
                        ],
                        [
                            -77.2532589999921,
                            39.0091579999301
                        ],
                        [
                            -77.2453799997043,
                            39.0175310000172
                        ],
                        [
                            -77.2449389996633,
                            39.0215159997263
                        ],
                        [
                            -77.246568000108,
                            39.0249599999607
                        ],
                        [
                            -77.2547460001465,
                            39.0297329998036
                        ],
                        [
                            -77.2750370002571,
                            39.0342840001872
                        ],
                        [
                            -77.29190899982,
                            39.0458489998607
                        ],
                        [
                            -77.3046620001246,
                            39.0513700001742
                        ],
                        [
                            -77.319730000239,
                            39.0533839997848
                        ],
                        [
                            -77.3335510003984,
                            39.059390000168
                        ],
                        [
                            -77.3342440003344,
                            39.0613300003404
                        ],
                        [
                            -77.3401440003732,
                            39.0629089997505
                        ],
                        [
                            -77.3857939998817,
                            39.0621320003284
                        ],
                        [
                            -77.3983539998425,
                            39.0646569999165
                        ],
                        [
                            -77.420733999807,
                            39.0663289998753
                        ],
                        [
                            -77.4609710002467,
                            39.0750560002337
                        ],
                        [
                            -77.468823999697,
                            39.0853460002255
                        ],
                        [
                            -77.4686599996655,
                            39.0893890000714
                        ],
                        [
                            -77.4734900001997,
                            39.0940439999152
                        ],
                        [
                            -77.4789500000224,
                            39.1034100002669
                        ],
                        [
                            -77.485400999707,
                            39.1093099998211
                        ],
                        [
                            -77.515887999565,
                            39.1186519999944
                        ],
                        [
                            -77.5206679998705,
                            39.1214349997091
                        ],
                        [
                            -77.5251750003645,
                            39.1292660000152
                        ],
                        [
                            -77.527680000145,
                            39.1446579999769
                        ],
                        [
                            -77.5246410004371,
                            39.1493820000995
                        ],
                        [
                            -77.518604000131,
                            39.1677399996948
                        ],
                        [
                            -77.5107890003156,
                            39.1787770000383
                        ],
                        [
                            -77.5057289996276,
                            39.1820310001603
                        ],
                        [
                            -77.4857949998924,
                            39.1860250002368
                        ],
                        [
                            -77.4786720001881,
                            39.1896710003352
                        ],
                        [
                            -77.4750879997839,
                            39.1954330002621
                        ],
                        [
                            -77.4759949999802,
                            39.2018269996531
                        ],
                        [
                            -77.4738049998439,
                            39.2083500002517
                        ],
                        [
                            -77.4588079998747,
                            39.2202699998817
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 38.9466584,
            "name": "Maryland",
            "intptlon": -76.6744939,
            "geo_point_2d": [
                38.9463162888,
                -76.6870675073
            ],
            "geoid": "24",
            "mtfcc": "G4000",
            "region": 3
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -76.6870675073,
                38.9463162888
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "329749f848445da01c3fa0d66682585cfcf236cc",
        "fields": {
            "arealand": "637075652",
            "objectid": 13,
            "basename": "New Mexico",
            "stusab": "NM",
            "statens": "00897535",
            "centlon": -106.1126594,
            "state": "35",
            "gid": 47,
            "centlat": 34.4075783,
            "division": 8,
            "areawater": "755396976",
            "oid": "-1984724855",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -106.006316000082,
                            36.9952669998594
                        ],
                        [
                            -105.120800000166,
                            36.995428000213
                        ],
                        [
                            -105.029225999716,
                            36.9927269998293
                        ],
                        [
                            -104.624555000244,
                            36.9943750000666
                        ],
                        [
                            -104.368881000146,
                            36.9934450002485
                        ],
                        [
                            -103.625506999687,
                            36.9986290003297
                        ],
                        [
                            -103.002328999989,
                            37.000149999736
                        ],
                        [
                            -103.002289999631,
                            36.5008870003598
                        ],
                        [
                            -103.041716999959,
                            36.5003600000762
                        ],
                        [
                            -103.040895000151,
                            35.8961010001164
                        ],
                        [
                            -103.043651000002,
                            34.0036390000545
                        ],
                        [
                            -103.064625000003,
                            32.9998989996924
                        ],
                        [
                            -103.064510000375,
                            32.0004240002891
                        ],
                        [
                            -104.750503000007,
                            32.0005369996516
                        ],
                        [
                            -104.754556000041,
                            32.0005270001316
                        ],
                        [
                            -104.754787000019,
                            31.9977729997087
                        ],
                        [
                            -104.772106000369,
                            31.9974859999823
                        ],
                        [
                            -104.847739999771,
                            32.000415000253
                        ],
                        [
                            -106.082896000038,
                            32.0026500003734
                        ],
                        [
                            -106.618486000272,
                            32.0004950002891
                        ],
                        [
                            -106.619448999646,
                            31.9947349998489
                        ],
                        [
                            -106.623567999803,
                            31.9909980003416
                        ],
                        [
                            -106.631185999974,
                            31.9898099999194
                        ],
                        [
                            -106.636496999834,
                            31.9857110002691
                        ],
                        [
                            -106.639536000441,
                            31.9803429997953
                        ],
                        [
                            -106.638193000226,
                            31.9768239997939
                        ],
                        [
                            -106.630102000226,
                            31.9712579996397
                        ],
                        [
                            -106.626522999845,
                            31.9706750003651
                        ],
                        [
                            -106.623186000211,
                            31.9729180003216
                        ],
                        [
                            -106.619569000195,
                            31.9715769999184
                        ],
                        [
                            -106.619370999828,
                            31.9647770001019
                        ],
                        [
                            -106.624298000447,
                            31.9610560002026
                        ],
                        [
                            -106.625122999729,
                            31.9545300002382
                        ],
                        [
                            -106.622817999965,
                            31.9528909997187
                        ],
                        [
                            -106.617707000122,
                            31.9560080000331
                        ],
                        [
                            -106.614374999613,
                            31.9559910003723
                        ],
                        [
                            -106.614321000084,
                            31.9516149998117
                        ],
                        [
                            -106.615253000395,
                            31.9489650000219
                        ],
                        [
                            -106.623659000039,
                            31.9455100001051
                        ],
                        [
                            -106.62252900026,
                            31.9348629999065
                        ],
                        [
                            -106.625338999639,
                            31.9300309997429
                        ],
                        [
                            -106.629742000376,
                            31.9265669997117
                        ],
                        [
                            -106.62865400043,
                            31.9236079996188
                        ],
                        [
                            -106.623931999851,
                            31.9253340000354
                        ],
                        [
                            -106.611847000266,
                            31.9200029996364
                        ],
                        [
                            -106.616146000348,
                            31.9173030001157
                        ],
                        [
                            -106.618750999687,
                            31.9177950001858
                        ],
                        [
                            -106.625949999559,
                            31.9122270001937
                        ],
                        [
                            -106.633667000364,
                            31.909793000381
                        ],
                        [
                            -106.640838999574,
                            31.904598999955
                        ],
                        [
                            -106.645478000312,
                            31.8986690001884
                        ],
                        [
                            -106.645295000014,
                            31.8948580000356
                        ],
                        [
                            -106.633921999734,
                            31.8891799997199
                        ],
                        [
                            -106.629190999832,
                            31.8836990003276
                        ],
                        [
                            -106.634874000136,
                            31.8744769996291
                        ],
                        [
                            -106.635920000249,
                            31.8662330001136
                        ],
                        [
                            -106.628173000206,
                            31.861120000366
                        ],
                        [
                            -106.625773000007,
                            31.8561649999517
                        ],
                        [
                            -106.614788000065,
                            31.8464129997768
                        ],
                        [
                            -106.606199999947,
                            31.8463260000859
                        ],
                        [
                            -106.602043000156,
                            31.8444060003357
                        ],
                        [
                            -106.601944000422,
                            31.8396010000041
                        ],
                        [
                            -106.605296999949,
                            31.8277200003175
                        ],
                        [
                            -106.602806000412,
                            31.8250179999413
                        ],
                        [
                            -106.593824999934,
                            31.8248980001305
                        ],
                        [
                            -106.589061000421,
                            31.8227079996558
                        ],
                        [
                            -106.577243999726,
                            31.8104060003369
                        ],
                        [
                            -106.570943999654,
                            31.8102059999611
                        ],
                        [
                            -106.566472000219,
                            31.8134329998794
                        ],
                        [
                            -106.563410000046,
                            31.8127360001732
                        ],
                        [
                            -106.562950999564,
                            31.8111050001232
                        ],
                        [
                            -106.547140000183,
                            31.8072989999523
                        ],
                        [
                            -106.544156999653,
                            31.8034700000011
                        ],
                        [
                            -106.536004999554,
                            31.7985059996985
                        ],
                        [
                            -106.532795000142,
                            31.792444000296
                        ],
                        [
                            -106.527402000266,
                            31.7900109999668
                        ],
                        [
                            -106.528241999617,
                            31.7831480002905
                        ],
                        [
                            -108.208484000071,
                            31.7836980000187
                        ],
                        [
                            -108.208662000346,
                            31.3333730002288
                        ],
                        [
                            -109.050040000125,
                            31.3323480003587
                        ],
                        [
                            -109.045362999751,
                            34.7854060000773
                        ],
                        [
                            -109.046953999663,
                            35.3934269999297
                        ],
                        [
                            -109.045171999956,
                            36.9989769999816
                        ],
                        [
                            -106.87723199969,
                            37.0001000002143
                        ],
                        [
                            -106.86982000028,
                            36.9924969999683
                        ],
                        [
                            -106.831972000168,
                            36.9924490001031
                        ],
                        [
                            -106.006316000082,
                            36.9952669998594
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 34.4346843,
            "name": "New Mexico",
            "intptlon": -106.1316181,
            "geo_point_2d": [
                34.4212892847,
                -106.108430625
            ],
            "geoid": "35",
            "mtfcc": "G4000",
            "region": 4
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -106.108430625,
                34.4212892847
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "426b88798c9440bf6d5a77795169831c11a6fd5d",
        "fields": {
            "arealand": "158364994",
            "objectid": 54,
            "basename": "District of Columbia",
            "stusab": "DC",
            "statens": "01702382",
            "centlon": -77.0162745,
            "state": "11",
            "gid": 20,
            "centlat": 38.9047579,
            "division": 5,
            "areawater": "18633403",
            "oid": "-1754367606",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -77.1197509997839,
                            38.934349999852
                        ],
                        [
                            -77.040881999958,
                            38.9958450003095
                        ],
                        [
                            -76.9093930001818,
                            38.8928520002621
                        ],
                        [
                            -77.0390059996411,
                            38.7916449999142
                        ],
                        [
                            -77.0373429997596,
                            38.8146349998601
                        ],
                        [
                            -77.0411070000889,
                            38.822939999809
                        ],
                        [
                            -77.0387839998833,
                            38.8244730001069
                        ],
                        [
                            -77.0377550003866,
                            38.8301379997509
                        ],
                        [
                            -77.0418889997134,
                            38.8337099998371
                        ],
                        [
                            -77.041984000148,
                            38.8314840000691
                        ],
                        [
                            -77.0432069998134,
                            38.8314299999871
                        ],
                        [
                            -77.0458699997777,
                            38.8401480001312
                        ],
                        [
                            -77.0480199997309,
                            38.8412610002234
                        ],
                        [
                            -77.0368039998094,
                            38.8394050003172
                        ],
                        [
                            -77.0345929997568,
                            38.840101000095
                        ],
                        [
                            -77.0330660003177,
                            38.8445489997831
                        ],
                        [
                            -77.0322109999999,
                            38.851671999878
                        ],
                        [
                            -77.0341700001575,
                            38.8576800003103
                        ],
                        [
                            -77.0380819997264,
                            38.8632930001003
                        ],
                        [
                            -77.0427630002977,
                            38.8633720001806
                        ],
                        [
                            -77.037924000441,
                            38.8642840001346
                        ],
                        [
                            -77.0394640002989,
                            38.8697320002803
                        ],
                        [
                            -77.0458689999527,
                            38.8756130001128
                        ],
                        [
                            -77.0472189998398,
                            38.8749579998757
                        ],
                        [
                            -77.0465169996829,
                            38.8720569998651
                        ],
                        [
                            -77.0501549996155,
                            38.8716470002346
                        ],
                        [
                            -77.0541409997026,
                            38.8797119996672
                        ],
                        [
                            -77.0591410001161,
                            38.8809139996505
                        ],
                        [
                            -77.0642510001345,
                            38.8889559997074
                        ],
                        [
                            -77.0686409995544,
                            38.9002879998599
                        ],
                        [
                            -77.0826580004307,
                            38.9022739997139
                        ],
                        [
                            -77.0932159996779,
                            38.9057669998791
                        ],
                        [
                            -77.1020320002993,
                            38.911916000093
                        ],
                        [
                            -77.1068009998358,
                            38.920025999669
                        ],
                        [
                            -77.1159570002158,
                            38.928164999833
                        ],
                        [
                            -77.1197509997839,
                            38.934349999852
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 38.9041031,
            "name": "District of Columbia",
            "intptlon": -77.017229,
            "geo_point_2d": [
                38.9047679414,
                -77.0163213502
            ],
            "geoid": "11",
            "mtfcc": "G4000",
            "region": 3
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -77.0163213502,
                38.9047679414
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "a18341a2f2a84731b9dd57b99b3ca0cdc8cb4c35",
        "fields": {
            "arealand": "2140782230",
            "objectid": 56,
            "basename": "Arizona",
            "stusab": "AZ",
            "statens": "01779777",
            "centlon": -111.660548,
            "state": "04",
            "gid": 22,
            "centlat": 34.275053,
            "division": 8,
            "areawater": "1027348231",
            "oid": "1671972589",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -114.516453999718,
                            33.0276169998718
                        ],
                        [
                            -114.520130000184,
                            33.0299839999246
                        ],
                        [
                            -114.53108300034,
                            33.0323930000822
                        ],
                        [
                            -114.571652999967,
                            33.0366239998621
                        ],
                        [
                            -114.57828699995,
                            33.0353750003515
                        ],
                        [
                            -114.584765000297,
                            33.028230000122
                        ],
                        [
                            -114.589778000231,
                            33.0262279999847
                        ],
                        [
                            -114.601014000244,
                            33.0254100000797
                        ],
                        [
                            -114.61878799998,
                            33.0272020001241
                        ],
                        [
                            -114.628294000165,
                            33.03105000031
                        ],
                        [
                            -114.639551999919,
                            33.0452900000327
                        ],
                        [
                            -114.645979000213,
                            33.0489019999723
                        ],
                        [
                            -114.649001000202,
                            33.0467619998621
                        ],
                        [
                            -114.657826999971,
                            33.0338239996895
                        ],
                        [
                            -114.662316999848,
                            33.0326700001964
                        ],
                        [
                            -114.670802999858,
                            33.0379830001715
                        ],
                        [
                            -114.673659000166,
                            33.0418959997568
                        ],
                        [
                            -114.675102999765,
                            33.0475299999263
                        ],
                        [
                            -114.674295000201,
                            33.0571690002503
                        ],
                        [
                            -114.686990999706,
                            33.0709680000729
                        ],
                        [
                            -114.689119999743,
                            33.0761209997059
                        ],
                        [
                            -114.689020000184,
                            33.084035000087
                        ],
                        [
                            -114.706487999598,
                            33.0881600000586
                        ],
                        [
                            -114.70789600011,
                            33.097430999681
                        ],
                        [
                            -114.703682000417,
                            33.1137680000752
                        ],
                        [
                            -114.696913999642,
                            33.1311190002023
                        ],
                        [
                            -114.687404999983,
                            33.1419830003167
                        ],
                        [
                            -114.679944999994,
                            33.1590590001759
                        ],
                        [
                            -114.680889999826,
                            33.169074000152
                        ],
                        [
                            -114.675358999858,
                            33.185488000051
                        ],
                        [
                            -114.678749000093,
                            33.203448000124
                        ],
                        [
                            -114.673625999656,
                            33.2231209998096
                        ],
                        [
                            -114.689540999692,
                            33.2464280002242
                        ],
                        [
                            -114.674491000019,
                            33.2555970000143
                        ],
                        [
                            -114.672088000346,
                            33.2584990001751
                        ],
                        [
                            -114.677031999785,
                            33.2701690002306
                        ],
                        [
                            -114.680507000409,
                            33.2735760001751
                        ],
                        [
                            -114.694449000044,
                            33.279785000309
                        ],
                        [
                            -114.721670000414,
                            33.2869820000651
                        ],
                        [
                            -114.731222999555,
                            33.3024330002204
                        ],
                        [
                            -114.72990399963,
                            33.3057449997852
                        ],
                        [
                            -114.723622999825,
                            33.3121090001766
                        ],
                        [
                            -114.70787000017,
                            33.3233160002414
                        ],
                        [
                            -114.700938000262,
                            33.3370140001415
                        ],
                        [
                            -114.698035000098,
                            33.3524420003696
                        ],
                        [
                            -114.699056000097,
                            33.3611479996339
                        ],
                        [
                            -114.707347999939,
                            33.3766269997882
                        ],
                        [
                            -114.707308999581,
                            33.382540000008
                        ],
                        [
                            -114.724249999639,
                            33.4004200001815
                        ],
                        [
                            -114.725282000407,
                            33.4050480001963
                        ],
                        [
                            -114.720065000258,
                            33.4078909999378
                        ],
                        [
                            -114.701787999658,
                            33.4083769998045
                        ],
                        [
                            -114.69770799986,
                            33.41094199963
                        ],
                        [
                            -114.695658000364,
                            33.4151279996741
                        ],
                        [
                            -114.687949999781,
                            33.417933999699
                        ],
                        [
                            -114.673690999953,
                            33.4191570002595
                        ],
                        [
                            -114.656734999827,
                            33.4128130002564
                        ],
                        [
                            -114.649540000152,
                            33.4136330002168
                        ],
                        [
                            -114.643302000005,
                            33.4167459996419
                        ],
                        [
                            -114.629639999854,
                            33.42813699965
                        ],
                        [
                            -114.622282999798,
                            33.4475580000116
                        ],
                        [
                            -114.622918000008,
                            33.4565609998138
                        ],
                        [
                            -114.612472000015,
                            33.4707680001652
                        ],
                        [
                            -114.601693999761,
                            33.4813959998256
                        ],
                        [
                            -114.592369000225,
                            33.4986750001603
                        ],
                        [
                            -114.580467999865,
                            33.506465000001
                        ],
                        [
                            -114.569533000151,
                            33.5092189998394
                        ],
                        [
                            -114.560962999577,
                            33.5167389999055
                        ],
                        [
                            -114.558898000013,
                            33.5318189997814
                        ],
                        [
                            -114.524215000006,
                            33.5530679997355
                        ],
                        [
                            -114.528220000359,
                            33.5593179998278
                        ],
                        [
                            -114.531613000069,
                            33.5617019998336
                        ],
                        [
                            -114.536784000187,
                            33.5709589997949
                        ],
                        [
                            -114.540299999921,
                            33.5806150001141
                        ],
                        [
                            -114.540617000113,
                            33.591412000232
                        ],
                        [
                            -114.529186000107,
                            33.6066500001862
                        ],
                        [
                            -114.521844999944,
                            33.612544000031
                        ],
                        [
                            -114.531214999686,
                            33.6239130002751
                        ],
                        [
                            -114.531033999936,
                            33.6282129998021
                        ],
                        [
                            -114.526369999982,
                            33.6302589997495
                        ],
                        [
                            -114.523801999554,
                            33.6347000000093
                        ],
                        [
                            -114.525394000188,
                            33.6406690001536
                        ],
                        [
                            -114.533214999852,
                            33.6484429996604
                        ],
                        [
                            -114.533193999935,
                            33.651660000203
                        ],
                        [
                            -114.530582999849,
                            33.6544609999774
                        ],
                        [
                            -114.518336999708,
                            33.6559269999056
                        ],
                        [
                            -114.514559000033,
                            33.6580139999723
                        ],
                        [
                            -114.514056999893,
                            33.6601789999654
                        ],
                        [
                            -114.517112000393,
                            33.6628770002753
                        ],
                        [
                            -114.526438999579,
                            33.663880000266
                        ],
                        [
                            -114.53026700038,
                            33.6668209997697
                        ],
                        [
                            -114.532122999707,
                            33.6697020001327
                        ],
                        [
                            -114.531522999658,
                            33.6751080001403
                        ],
                        [
                            -114.530347999673,
                            33.6792449996835
                        ],
                        [
                            -114.523958999912,
                            33.6858790001813
                        ],
                        [
                            -114.496489000021,
                            33.696901000083
                        ],
                        [
                            -114.494196999778,
                            33.7079220002624
                        ],
                        [
                            -114.496565000189,
                            33.719154999932
                        ],
                        [
                            -114.500788000103,
                            33.7222039997931
                        ],
                        [
                            -114.50417599979,
                            33.728054999944
                        ],
                        [
                            -114.512347999981,
                            33.7342139998181
                        ],
                        [
                            -114.504482999937,
                            33.7509979996312
                        ],
                        [
                            -114.504862999878,
                            33.7604650001888
                        ],
                        [
                            -114.516734000101,
                            33.7883450002618
                        ],
                        [
                            -114.518941999781,
                            33.7973019997072
                        ],
                        [
                            -114.528049999582,
                            33.8149629999611
                        ],
                        [
                            -114.522713999607,
                            33.8189789999425
                        ],
                        [
                            -114.519970000351,
                            33.8253810003531
                        ],
                        [
                            -114.52959700001,
                            33.8480629997453
                        ],
                        [
                            -114.529883000241,
                            33.857562999755
                        ],
                        [
                            -114.524291999998,
                            33.8601329999573
                        ],
                        [
                            -114.514672999836,
                            33.858638000135
                        ],
                        [
                            -114.50563799983,
                            33.864275999828
                        ],
                        [
                            -114.503016999699,
                            33.867998000008
                        ],
                        [
                            -114.504339999822,
                            33.876881999907
                        ],
                        [
                            -114.522768000034,
                            33.8925830000528
                        ],
                        [
                            -114.525872000039,
                            33.9010080000101
                        ],
                        [
                            -114.513714999586,
                            33.8979589996314
                        ],
                        [
                            -114.508708000398,
                            33.9006399996971
                        ],
                        [
                            -114.507920000028,
                            33.9038070003415
                        ],
                        [
                            -114.511511000105,
                            33.9110919998919
                        ],
                        [
                            -114.518433999792,
                            33.9175180001753
                        ],
                        [
                            -114.525360999678,
                            33.9222719997147
                        ],
                        [
                            -114.534951000426,
                            33.925700000347
                        ],
                        [
                            -114.535676000149,
                            33.9335519996424
                        ],
                        [
                            -114.528679999768,
                            33.9478170002516
                        ],
                        [
                            -114.522733999699,
                            33.9550390001604
                        ],
                        [
                            -114.515860000415,
                            33.9581059997912
                        ],
                        [
                            -114.50866900004,
                            33.9575249998096
                        ],
                        [
                            -114.499882999556,
                            33.9617890000441
                        ],
                        [
                            -114.484784000378,
                            33.9755190003477
                        ],
                        [
                            -114.481455000242,
                            33.9812609998295
                        ],
                        [
                            -114.471138000121,
                            33.9880400001696
                        ],
                        [
                            -114.467932000008,
                            33.9928770002875
                        ],
                        [
                            -114.459257999677,
                            33.9947110002695
                        ],
                        [
                            -114.458028000339,
                            33.9971580003412
                        ],
                        [
                            -114.459184000057,
                            34.0000159996746
                        ],
                        [
                            -114.467310000217,
                            34.0051899998307
                        ],
                        [
                            -114.465867000444,
                            34.0109869998183
                        ],
                        [
                            -114.463335999826,
                            34.0122589998225
                        ],
                        [
                            -114.454807000158,
                            34.0109680003066
                        ],
                        [
                            -114.450205999953,
                            34.0125739999092
                        ],
                        [
                            -114.44382100039,
                            34.0161760001066
                        ],
                        [
                            -114.438266000133,
                            34.0226089999267
                        ],
                        [
                            -114.434948999693,
                            34.0377840003627
                        ],
                        [
                            -114.439339999836,
                            34.0578929998373
                        ],
                        [
                            -114.434181000312,
                            34.0873789997436
                        ],
                        [
                            -114.428026000005,
                            34.0927869999101
                        ],
                        [
                            -114.420499000069,
                            34.1034660001573
                        ],
                        [
                            -114.411679999973,
                            34.1100309999868
                        ],
                        [
                            -114.401336000088,
                            34.1116380002988
                        ],
                        [
                            -114.390565000405,
                            34.110084000353
                        ],
                        [
                            -114.379223000087,
                            34.1159899999295
                        ],
                        [
                            -114.366516999638,
                            34.1185769998982
                        ],
                        [
                            -114.360402000413,
                            34.1235770001411
                        ],
                        [
                            -114.353030000288,
                            34.1331199998217
                        ],
                        [
                            -114.348050999791,
                            34.1344570003641
                        ],
                        [
                            -114.336111999796,
                            34.1340339998253
                        ],
                        [
                            -114.320776999718,
                            34.138634999845
                        ],
                        [
                            -114.312206000217,
                            34.1447760000247
                        ],
                        [
                            -114.29280600041,
                            34.1667249999847
                        ],
                        [
                            -114.28729399981,
                            34.1705290001584
                        ],
                        [
                            -114.275266999952,
                            34.1721489997966
                        ],
                        [
                            -114.268459999717,
                            34.1701770000703
                        ],
                        [
                            -114.257033999733,
                            34.1728369998884
                        ],
                        [
                            -114.244421000069,
                            34.1794030003606
                        ],
                        [
                            -114.240711999991,
                            34.1832320000931
                        ],
                        [
                            -114.229715000352,
                            34.1869280002699
                        ],
                        [
                            -114.227033999947,
                            34.1888659998951
                        ],
                        [
                            -114.224940999895,
                            34.1938959997231
                        ],
                        [
                            -114.225193999615,
                            34.2036419997349
                        ],
                        [
                            -114.211760999793,
                            34.211538999889
                        ],
                        [
                            -114.190876000379,
                            34.230857999729
                        ],
                        [
                            -114.178050000279,
                            34.2399690000097
                        ],
                        [
                            -114.174597000294,
                            34.2473029999875
                        ],
                        [
                            -114.166123999805,
                            34.2500149998975
                        ],
                        [
                            -114.163866999721,
                            34.2533490003366
                        ],
                        [
                            -114.164648000419,
                            34.2596989998628
                        ],
                        [
                            -114.153346000284,
                            34.2582890000047
                        ],
                        [
                            -114.144779000083,
                            34.2596230001518
                        ],
                        [
                            -114.135450000349,
                            34.2578860001442
                        ],
                        [
                            -114.131489000376,
                            34.2603869996707
                        ],
                        [
                            -114.131210999644,
                            34.2627299998709
                        ],
                        [
                            -114.136671000365,
                            34.2743769997511
                        ],
                        [
                            -114.136050000399,
                            34.2808330003012
                        ],
                        [
                            -114.139534000346,
                            34.2958439996355
                        ],
                        [
                            -114.138282000368,
                            34.3032299999102
                        ],
                        [
                            -114.157205999975,
                            34.3178620001229
                        ],
                        [
                            -114.168806999861,
                            34.3395129998878
                        ],
                        [
                            -114.176908999731,
                            34.3493059998711
                        ],
                        [
                            -114.199482000039,
                            34.3613729999983
                        ],
                        [
                            -114.213774000377,
                            34.362459999954
                        ],
                        [
                            -114.226106999659,
                            34.3659160003232
                        ],
                        [
                            -114.22968600004,
                            34.3689079997616
                        ],
                        [
                            -114.234274999652,
                            34.3766620000794
                        ],
                        [
                            -114.252738999849,
                            34.3901000000076
                        ],
                        [
                            -114.264317000169,
                            34.4013290001697
                        ],
                        [
                            -114.280108000357,
                            34.403147000207
                        ],
                        [
                            -114.288662999964,
                            34.4066230003178
                        ],
                        [
                            -114.291751000075,
                            34.4111039997171
                        ],
                        [
                            -114.292225999553,
                            34.4176059998756
                        ],
                        [
                            -114.294835999814,
                            34.4213889996615
                        ],
                        [
                            -114.301016000235,
                            34.4268070002088
                        ],
                        [
                            -114.312251000423,
                            34.4327259997448
                        ],
                        [
                            -114.326130000309,
                            34.4372510002063
                        ],
                        [
                            -114.330668999692,
                            34.4452950002357
                        ],
                        [
                            -114.335372000004,
                            34.4500379996415
                        ],
                        [
                            -114.342615000258,
                            34.4514419998156
                        ],
                        [
                            -114.373718999884,
                            34.4469379997967
                        ],
                        [
                            -114.386698999969,
                            34.4579109997009
                        ],
                        [
                            -114.387186999866,
                            34.4620210001845
                        ],
                        [
                            -114.381701000104,
                            34.4760400002075
                        ],
                        [
                            -114.382358000055,
                            34.4957579996309
                        ],
                        [
                            -114.37812400027,
                            34.5072880000412
                        ],
                        [
                            -114.378223000005,
                            34.5165210003569
                        ],
                        [
                            -114.380838000288,
                            34.5297240002774
                        ],
                        [
                            -114.405227999566,
                            34.5696369998726
                        ],
                        [
                            -114.422382000059,
                            34.5807109999993
                        ],
                        [
                            -114.436809999941,
                            34.5960740001477
                        ],
                        [
                            -114.425337999926,
                            34.600841999967
                        ],
                        [
                            -114.424202000299,
                            34.6104530003166
                        ],
                        [
                            -114.438738999961,
                            34.6214550000576
                        ],
                        [
                            -114.441397999727,
                            34.6301709997853
                        ],
                        [
                            -114.440293999888,
                            34.6382400002883
                        ],
                        [
                            -114.441464999674,
                            34.6425300001632
                        ],
                        [
                            -114.449549000002,
                            34.6514229997706
                        ],
                        [
                            -114.457984999783,
                            34.6571129998036
                        ],
                        [
                            -114.457701999926,
                            34.6593280003603
                        ],
                        [
                            -114.451785000169,
                            34.6638910002644
                        ],
                        [
                            -114.450506000427,
                            34.6668359996652
                        ],
                        [
                            -114.454305000018,
                            34.6712339998288
                        ],
                        [
                            -114.455536000079,
                            34.6773350002106
                        ],
                        [
                            -114.46524599958,
                            34.6912020001372
                        ],
                        [
                            -114.470476999972,
                            34.7113679996688
                        ],
                        [
                            -114.473682000259,
                            34.7139639998759
                        ],
                        [
                            -114.48277900019,
                            34.7145110003303
                        ],
                        [
                            -114.487508000442,
                            34.7166259999096
                        ],
                        [
                            -114.492016999687,
                            34.7257019999347
                        ],
                        [
                            -114.516618999575,
                            34.7367449998607
                        ],
                        [
                            -114.529614999554,
                            34.750821999943
                        ],
                        [
                            -114.552681999607,
                            34.7668710001902
                        ],
                        [
                            -114.55865299979,
                            34.7738519998061
                        ],
                        [
                            -114.57101000026,
                            34.7942939999411
                        ],
                        [
                            -114.576451999641,
                            34.8152999996909
                        ],
                        [
                            -114.586841999557,
                            34.8356720002071
                        ],
                        [
                            -114.600652999671,
                            34.8473610001277
                        ],
                        [
                            -114.623939000007,
                            34.8597380003241
                        ],
                        [
                            -114.630681999769,
                            34.8663519996439
                        ],
                        [
                            -114.635175999845,
                            34.8750030000746
                        ],
                        [
                            -114.636724999923,
                            34.8891070000245
                        ],
                        [
                            -114.630876999763,
                            34.907263000249
                        ],
                        [
                            -114.633252999672,
                            34.9246079996703
                        ],
                        [
                            -114.629752999832,
                            34.9386839999577
                        ],
                        [
                            -114.629810999558,
                            34.9448099997976
                        ],
                        [
                            -114.634953000262,
                            34.9589180001021
                        ],
                        [
                            -114.635236999944,
                            34.9651490001662
                        ],
                        [
                            -114.629129000391,
                            34.9861319998263
                        ],
                        [
                            -114.630244000101,
                            34.9946399997873
                        ],
                        [
                            -114.637071000428,
                            35.0103710002348
                        ],
                        [
                            -114.637524000165,
                            35.0270529999579
                        ],
                        [
                            -114.629027000284,
                            35.0425309999523
                        ],
                        [
                            -114.606694000175,
                            35.0589409997721
                        ],
                        [
                            -114.602908000104,
                            35.0685879999082
                        ],
                        [
                            -114.604735999842,
                            35.0748300001974
                        ],
                        [
                            -114.613132000339,
                            35.0830969999361
                        ],
                        [
                            -114.62251700015,
                            35.0887030002202
                        ],
                        [
                            -114.642830999826,
                            35.0965029996691
                        ],
                        [
                            -114.64676400021,
                            35.1018679997695
                        ],
                        [
                            -114.644353999966,
                            35.1059030002737
                        ],
                        [
                            -114.632281999902,
                            35.1170879998873
                        ],
                        [
                            -114.623760999731,
                            35.1206020000524
                        ],
                        [
                            -114.628992999949,
                            35.1194110001849
                        ],
                        [
                            -114.624954000158,
                            35.1207420001862
                        ],
                        [
                            -114.618696999744,
                            35.1217489997693
                        ],
                        [
                            -114.597793999888,
                            35.1217349996459
                        ],
                        [
                            -114.584876999552,
                            35.1251940003353
                        ],
                        [
                            -114.578262999661,
                            35.1288100000014
                        ],
                        [
                            -114.572596999974,
                            35.1395570001448
                        ],
                        [
                            -114.573879000089,
                            35.1453509997301
                        ],
                        [
                            -114.569528999953,
                            35.1623169998432
                        ],
                        [
                            -114.569257999792,
                            35.1834240001893
                        ],
                        [
                            -114.572083999963,
                            35.200794000236
                        ],
                        [
                            -114.574957999814,
                            35.2067140000221
                        ],
                        [
                            -114.57953499973,
                            35.2089110001882
                        ],
                        [
                            -114.58031200023,
                            35.2200950001645
                        ],
                        [
                            -114.583523000365,
                            35.2303480001377
                        ],
                        [
                            -114.582842000125,
                            35.2387029996728
                        ],
                        [
                            -114.58605300026,
                            35.2488909998814
                        ],
                        [
                            -114.586603999906,
                            35.2623859997324
                        ],
                        [
                            -114.596681999652,
                            35.2945570001219
                        ],
                        [
                            -114.595552999698,
                            35.3265469999637
                        ],
                        [
                            -114.604606999971,
                            35.3552389999286
                        ],
                        [
                            -114.611205999794,
                            35.3701189996753
                        ],
                        [
                            -114.617698000384,
                            35.3801309998016
                        ],
                        [
                            -114.62088699988,
                            35.3968669997625
                        ],
                        [
                            -114.626765000178,
                            35.4096439998946
                        ],
                        [
                            -114.652080000093,
                            35.4301339997231
                        ],
                        [
                            -114.654295000343,
                            35.4368539999687
                        ],
                        [
                            -114.662895999981,
                            35.4464489997055
                        ],
                        [
                            -114.664216999556,
                            35.4558450001446
                        ],
                        [
                            -114.667216999804,
                            35.4603699999232
                        ],
                        [
                            -114.665651000007,
                            35.4669110003465
                        ],
                        [
                            -114.673163999699,
                            35.4748140000432
                        ],
                        [
                            -114.671906999698,
                            35.4820870003636
                        ],
                        [
                            -114.67681500005,
                            35.4897870001694
                        ],
                        [
                            -114.676256999833,
                            35.4931030001417
                        ],
                        [
                            -114.678641999963,
                            35.4976280001991
                        ],
                        [
                            -114.677480000397,
                            35.510947999905
                        ],
                        [
                            -114.675685000271,
                            35.5156299998921
                        ],
                        [
                            -114.66954000001,
                            35.5207899996567
                        ],
                        [
                            -114.664600999696,
                            35.5215190001021
                        ],
                        [
                            -114.657753000351,
                            35.5307409996881
                        ],
                        [
                            -114.656769999987,
                            35.5349640000214
                        ],
                        [
                            -114.661457000406,
                            35.5440620001212
                        ],
                        [
                            -114.662804999745,
                            35.5642680002283
                        ],
                        [
                            -114.664433000364,
                            35.5684260003237
                        ],
                        [
                            -114.668392999614,
                            35.5743310001164
                        ],
                        [
                            -114.675666999829,
                            35.5800330001046
                        ],
                        [
                            -114.659237999957,
                            35.587477000015
                        ],
                        [
                            -114.653900000333,
                            35.5984910002893
                        ],
                        [
                            -114.653533999737,
                            35.609671999991
                        ],
                        [
                            -114.659461000438,
                            35.6195519998037
                        ],
                        [
                            -114.666682000052,
                            35.6230729997423
                        ],
                        [
                            -114.677615000116,
                            35.6417740000257
                        ],
                        [
                            -114.689507000255,
                            35.6514289999958
                        ],
                        [
                            -114.690214000435,
                            35.6651589996572
                        ],
                        [
                            -114.682316999706,
                            35.6778250001871
                        ],
                        [
                            -114.680631000258,
                            35.684045999879
                        ],
                        [
                            -114.682657000362,
                            35.6885709998376
                        ],
                        [
                            -114.701416000113,
                            35.7010840003561
                        ],
                        [
                            -114.705597000194,
                            35.7082740003047
                        ],
                        [
                            -114.697859000371,
                            35.7316570001785
                        ],
                        [
                            -114.696399999806,
                            35.7426529997241
                        ],
                        [
                            -114.697726000302,
                            35.7509659998452
                        ],
                        [
                            -114.694266999571,
                            35.7566329999385
                        ],
                        [
                            -114.70102699995,
                            35.76967999984
                        ],
                        [
                            -114.699318000038,
                            35.790479999703
                        ],
                        [
                            -114.712026000137,
                            35.8055289997328
                        ],
                        [
                            -114.709323999815,
                            35.8100499999052
                        ],
                        [
                            -114.7036649998,
                            35.814613999955
                        ],
                        [
                            -114.695276999699,
                            35.8310909998345
                        ],
                        [
                            -114.706287999582,
                            35.8462180001621
                        ],
                        [
                            -114.705855999761,
                            35.850508000345
                        ],
                        [
                            -114.701904000009,
                            35.8532230001221
                        ],
                        [
                            -114.694370000401,
                            35.8544630000983
                        ],
                        [
                            -114.691456000367,
                            35.8586610001922
                        ],
                        [
                            -114.687798000343,
                            35.8607279998869
                        ],
                        [
                            -114.669679999752,
                            35.8650360001616
                        ],
                        [
                            -114.661635999608,
                            35.8712329997507
                        ],
                        [
                            -114.663213999999,
                            35.8736920003004
                        ],
                        [
                            -114.672008999805,
                            35.878018000048
                        ],
                        [
                            -114.678971999676,
                            35.8855099997378
                        ],
                        [
                            -114.696064000245,
                            35.8964639999969
                        ],
                        [
                            -114.69492799972,
                            35.8975940003635
                        ],
                        [
                            -114.700257999846,
                            35.9017569999668
                        ],
                        [
                            -114.705714000369,
                            35.9093160003022
                        ],
                        [
                            -114.70811200002,
                            35.9099329997865
                        ],
                        [
                            -114.709187000446,
                            35.9168270000931
                        ],
                        [
                            -114.707398000167,
                            35.9180570002154
                        ],
                        [
                            -114.707603000207,
                            35.9279500002815
                        ],
                        [
                            -114.713412999834,
                            35.9318999996833
                        ],
                        [
                            -114.713311999552,
                            35.9338439997933
                        ],
                        [
                            -114.729762000238,
                            35.9598950002555
                        ],
                        [
                            -114.728665999896,
                            35.96175699967
                        ],
                        [
                            -114.740536000294,
                            35.9755450000609
                        ],
                        [
                            -114.743637999751,
                            35.9857849999276
                        ],
                        [
                            -114.739317999753,
                            35.9918040001474
                        ],
                        [
                            -114.743005000089,
                            36.0084499998972
                        ],
                        [
                            -114.738555000395,
                            36.0152230000629
                        ],
                        [
                            -114.728874000309,
                            36.0213869996549
                        ],
                        [
                            -114.722096000386,
                            36.0289519998792
                        ],
                        [
                            -114.733416999889,
                            36.0379130002794
                        ],
                        [
                            -114.741261999842,
                            36.0380439996478
                        ],
                        [
                            -114.74247899966,
                            36.0456969997864
                        ],
                        [
                            -114.735700999737,
                            36.0533930001731
                        ],
                        [
                            -114.73528499981,
                            36.0566480002522
                        ],
                        [
                            -114.740060000093,
                            36.0624370003626
                        ],
                        [
                            -114.74354200039,
                            36.0710370002629
                        ],
                        [
                            -114.748890999885,
                            36.0749809999515
                        ],
                        [
                            -114.75056999966,
                            36.0803299998981
                        ],
                        [
                            -114.754681000318,
                            36.085051999841
                        ],
                        [
                            -114.748912999626,
                            36.0951829996846
                        ],
                        [
                            -114.734857000188,
                            36.1044260003296
                        ],
                        [
                            -114.70373699977,
                            36.10834800036
                        ],
                        [
                            -114.693654999826,
                            36.1124820001009
                        ],
                        [
                            -114.681847000251,
                            36.1091920003044
                        ],
                        [
                            -114.671867000413,
                            36.1159639997299
                        ],
                        [
                            -114.664342999953,
                            36.1163000000118
                        ],
                        [
                            -114.655512000161,
                            36.126186999918
                        ],
                        [
                            -114.640125000205,
                            36.1351260002464
                        ],
                        [
                            -114.635809000405,
                            36.1361700001332
                        ],
                        [
                            -114.630474000255,
                            36.1422180000812
                        ],
                        [
                            -114.627078999997,
                            36.1407610001453
                        ],
                        [
                            -114.620604999848,
                            36.1317589998704
                        ],
                        [
                            -114.615454999646,
                            36.129652999858
                        ],
                        [
                            -114.609287999644,
                            36.1322290000677
                        ],
                        [
                            -114.596474000138,
                            36.1415370002577
                        ],
                        [
                            -114.58070700024,
                            36.1459869999253
                        ],
                        [
                            -114.571089999728,
                            36.1510989997437
                        ],
                        [
                            -114.56117299964,
                            36.1509209997533
                        ],
                        [
                            -114.556162000254,
                            36.1524700001633
                        ],
                        [
                            -114.548742000449,
                            36.1506969998762
                        ],
                        [
                            -114.539232999891,
                            36.1517640001756
                        ],
                        [
                            -114.531090999838,
                            36.14764400024
                        ],
                        [
                            -114.511218000202,
                            36.1505760001296
                        ],
                        [
                            -114.508104000151,
                            36.1497130000908
                        ],
                        [
                            -114.500235999734,
                            36.1432259997228
                        ],
                        [
                            -114.500338999666,
                            36.1406999996858
                        ],
                        [
                            -114.505149999933,
                            36.1380780000608
                        ],
                        [
                            -114.50920999964,
                            36.1332470002267
                        ],
                        [
                            -114.508467000374,
                            36.1299130001136
                        ],
                        [
                            -114.50471499974,
                            36.1271879997835
                        ],
                        [
                            -114.498849000037,
                            36.1266120001401
                        ],
                        [
                            -114.483826999953,
                            36.1297199998137
                        ],
                        [
                            -114.468674000348,
                            36.1388890001878
                        ],
                        [
                            -114.462600000232,
                            36.1396439999716
                        ],
                        [
                            -114.456486999758,
                            36.1380319996798
                        ],
                        [
                            -114.451330999709,
                            36.1298310002209
                        ],
                        [
                            -114.445042000406,
                            36.1253460001131
                        ],
                        [
                            -114.423114000353,
                            36.137350000078
                        ],
                        [
                            -114.415252999609,
                            36.1451229998277
                        ],
                        [
                            -114.405624000299,
                            36.1469830002485
                        ],
                        [
                            -114.381479000346,
                            36.1413490000376
                        ],
                        [
                            -114.375278000008,
                            36.1435920001399
                        ],
                        [
                            -114.370181000408,
                            36.1426239999329
                        ],
                        [
                            -114.358967999962,
                            36.127794999658
                        ],
                        [
                            -114.345099999947,
                            36.118556000021
                        ],
                        [
                            -114.334632000213,
                            36.106783999683
                        ],
                        [
                            -114.325813999942,
                            36.1039330002261
                        ],
                        [
                            -114.320866000305,
                            36.0964630001052
                        ],
                        [
                            -114.30693899984,
                            36.0824870000549
                        ],
                        [
                            -114.304383999831,
                            36.0740189999273
                        ],
                        [
                            -114.312419999579,
                            36.0661170003578
                        ],
                        [
                            -114.314327999683,
                            36.0620159998606
                        ],
                        [
                            -114.313591000265,
                            36.0590480003364
                        ],
                        [
                            -114.308624000361,
                            36.0569759998637
                        ],
                        [
                            -114.298592999572,
                            36.0572629998548
                        ],
                        [
                            -114.287991999769,
                            36.0490699998525
                        ],
                        [
                            -114.279637000178,
                            36.0461030003218
                        ],
                        [
                            -114.270861999565,
                            36.0455229997461
                        ],
                        [
                            -114.268586000113,
                            36.0350339998767
                        ],
                        [
                            -114.264379999918,
                            36.0279109996818
                        ],
                        [
                            -114.2438650004,
                            36.0152660002237
                        ],
                        [
                            -114.233442999798,
                            36.0128350000009
                        ],
                        [
                            -114.228014999763,
                            36.0147310000853
                        ],
                        [
                            -114.22479799978,
                            36.013699000186
                        ],
                        [
                            -114.211932000395,
                            36.0148340000941
                        ],
                        [
                            -114.19122100016,
                            36.0200189998876
                        ],
                        [
                            -114.17468299961,
                            36.0266699998192
                        ],
                        [
                            -114.164402000372,
                            36.0268519996513
                        ],
                        [
                            -114.151389999601,
                            36.0231329999527
                        ],
                        [
                            -114.145907000212,
                            36.0272290002491
                        ],
                        [
                            -114.144666000105,
                            36.0342720001488
                        ],
                        [
                            -114.137112000406,
                            36.0384909997559
                        ],
                        [
                            -114.134841000079,
                            36.0438730003374
                        ],
                        [
                            -114.136206000034,
                            36.0532319997942
                        ],
                        [
                            -114.133389000084,
                            36.0616649997865
                        ],
                        [
                            -114.124019000342,
                            36.0755630000782
                        ],
                        [
                            -114.119648000291,
                            36.0858220002695
                        ],
                        [
                            -114.111998000332,
                            36.0949100001173
                        ],
                        [
                            -114.115207999744,
                            36.0998780000282
                        ],
                        [
                            -114.121033000338,
                            36.1038849998696
                        ],
                        [
                            -114.120865000109,
                            36.1108499996413
                        ],
                        [
                            -114.118496999697,
                            36.1138999997355
                        ],
                        [
                            -114.108381000316,
                            36.1191540003371
                        ],
                        [
                            -114.096993999792,
                            36.1208229997303
                        ],
                        [
                            -114.091701000374,
                            36.1373030002775
                        ],
                        [
                            -114.081233999566,
                            36.1502079998253
                        ],
                        [
                            -114.078831999718,
                            36.1574340002992
                        ],
                        [
                            -114.066798000187,
                            36.1790870000775
                        ],
                        [
                            -114.058661999981,
                            36.1878349998181
                        ],
                        [
                            -114.043943999671,
                            36.1933500000278
                        ],
                        [
                            -114.045558999872,
                            36.2888369997179
                        ],
                        [
                            -114.044050999801,
                            36.3176279997808
                        ],
                        [
                            -114.042843000204,
                            36.4481750003019
                        ],
                        [
                            -114.049934999947,
                            36.7095210001029
                        ],
                        [
                            -114.050616000187,
                            37.0001669998386
                        ],
                        [
                            -111.150576000008,
                            37.0009499998098
                        ],
                        [
                            -110.625691000233,
                            37.0037249997718
                        ],
                        [
                            -110.490299000211,
                            37.0036279997926
                        ],
                        [
                            -110.46973299974,
                            36.9976570000152
                        ],
                        [
                            -109.045171999956,
                            36.9989769999816
                        ],
                        [
                            -109.046953999663,
                            35.3934269999297
                        ],
                        [
                            -109.045362999751,
                            34.7854060000773
                        ],
                        [
                            -109.050044000323,
                            31.3325020000809
                        ],
                        [
                            -109.494490000277,
                            31.3341250002308
                        ],
                        [
                            -110.795466999614,
                            31.3336299998299
                        ],
                        [
                            -111.074825000431,
                            31.3322390000991
                        ],
                        [
                            -111.56019400019,
                            31.4881380003152
                        ],
                        [
                            -112.200717000178,
                            31.6900330000522
                        ],
                        [
                            -112.800206999858,
                            31.8750680002981
                        ],
                        [
                            -113.125960999823,
                            31.9727800002008
                        ],
                        [
                            -113.781679999971,
                            32.1790339999565
                        ],
                        [
                            -114.250774999669,
                            32.3239089997992
                        ],
                        [
                            -114.813613000036,
                            32.4942760001096
                        ],
                        [
                            -114.813991000328,
                            32.4972309999166
                        ],
                        [
                            -114.812315999853,
                            32.5000539999482
                        ],
                        [
                            -114.816590999645,
                            32.5076960003623
                        ],
                        [
                            -114.814321000041,
                            32.5090230002642
                        ],
                        [
                            -114.807726000416,
                            32.5087260001766
                        ],
                        [
                            -114.80407599989,
                            32.5103749999895
                        ],
                        [
                            -114.802211000342,
                            32.5131910000036
                        ],
                        [
                            -114.803670000009,
                            32.5163739999437
                        ],
                        [
                            -114.809672000155,
                            32.51756700029
                        ],
                        [
                            -114.810482000267,
                            32.52175799992
                        ],
                        [
                            -114.813347999722,
                            32.5241860002666
                        ],
                        [
                            -114.802181000205,
                            32.5364139997202
                        ],
                        [
                            -114.802017999998,
                            32.5394599996714
                        ],
                        [
                            -114.804776000397,
                            32.5416589996774
                        ],
                        [
                            -114.805830000008,
                            32.5463540003151
                        ],
                        [
                            -114.793768999814,
                            32.5523289999401
                        ],
                        [
                            -114.791551000089,
                            32.5570230003781
                        ],
                        [
                            -114.793224000016,
                            32.5694590002581
                        ],
                        [
                            -114.797660000365,
                            32.5644439998216
                        ],
                        [
                            -114.806830000091,
                            32.558879999976
                        ],
                        [
                            -114.810318000235,
                            32.5586280001033
                        ],
                        [
                            -114.813994999628,
                            32.5622009999676
                        ],
                        [
                            -114.812995000443,
                            32.5687060000368
                        ],
                        [
                            -114.804420999671,
                            32.5729410001367
                        ],
                        [
                            -114.801877000431,
                            32.5760090001809
                        ],
                        [
                            -114.801470999652,
                            32.5782549998211
                        ],
                        [
                            -114.803987000201,
                            32.5826520001249
                        ],
                        [
                            -114.80044100033,
                            32.5880790000313
                        ],
                        [
                            -114.799736999625,
                            32.5921769999057
                        ],
                        [
                            -114.801547999645,
                            32.5985910000175
                        ],
                        [
                            -114.80804100006,
                            32.6031720000008
                        ],
                        [
                            -114.809554999979,
                            32.6162029997722
                        ],
                        [
                            -114.808662000026,
                            32.6191569996385
                        ],
                        [
                            -114.80682099987,
                            32.6217209997069
                        ],
                        [
                            -114.79930200033,
                            32.6251150003419
                        ],
                        [
                            -114.791178999645,
                            32.6218329997757
                        ],
                        [
                            -114.782572999984,
                            32.6243039997838
                        ],
                        [
                            -114.782234999875,
                            32.6302150002774
                        ],
                        [
                            -114.779215000434,
                            32.6335780003053
                        ],
                        [
                            -114.764381999598,
                            32.6426660003771
                        ],
                        [
                            -114.763310000444,
                            32.6446160001554
                        ],
                        [
                            -114.764949999861,
                            32.649390999992
                        ],
                        [
                            -114.749480000063,
                            32.6617800003118
                        ],
                        [
                            -114.744349000129,
                            32.6789350000436
                        ],
                        [
                            -114.730452999626,
                            32.6988439999038
                        ],
                        [
                            -114.730086000103,
                            32.7042979996984
                        ],
                        [
                            -114.722410000205,
                            32.7135969997765
                        ],
                        [
                            -114.714521999697,
                            32.7303899998469
                        ],
                        [
                            -114.709073999569,
                            32.7354560001678
                        ],
                        [
                            -114.702940000077,
                            32.7447929998874
                        ],
                        [
                            -114.699246999893,
                            32.7450980002458
                        ],
                        [
                            -114.688230000163,
                            32.7375299997095
                        ],
                        [
                            -114.617395000436,
                            32.728256999714
                        ],
                        [
                            -114.615669999732,
                            32.728453999819
                        ],
                        [
                            -114.615112000413,
                            32.7345150000883
                        ],
                        [
                            -114.581784000316,
                            32.7349460003693
                        ],
                        [
                            -114.581735999737,
                            32.7423200001372
                        ],
                        [
                            -114.564507999623,
                            32.7422740002121
                        ],
                        [
                            -114.564447000422,
                            32.7495539997746
                        ],
                        [
                            -114.53922399967,
                            32.7498120003363
                        ],
                        [
                            -114.539092000324,
                            32.7569490003357
                        ],
                        [
                            -114.526856000228,
                            32.7570940000453
                        ],
                        [
                            -114.528442999942,
                            32.7672760001137
                        ],
                        [
                            -114.532432000402,
                            32.776921999978
                        ],
                        [
                            -114.531669000146,
                            32.7911850001968
                        ],
                        [
                            -114.529632999996,
                            32.7954769997985
                        ],
                        [
                            -114.522030999717,
                            32.801674999808
                        ],
                        [
                            -114.510326999899,
                            32.8164879997865
                        ],
                        [
                            -114.494115999587,
                            32.8232869996422
                        ],
                        [
                            -114.468970999551,
                            32.8451550000409
                        ],
                        [
                            -114.46292900012,
                            32.9079439998112
                        ],
                        [
                            -114.464448000062,
                            32.9131279997402
                        ],
                        [
                            -114.476639999776,
                            32.9236280001936
                        ],
                        [
                            -114.480924999614,
                            32.9362760002656
                        ],
                        [
                            -114.470768000225,
                            32.9494239998677
                        ],
                        [
                            -114.46806099988,
                            32.9552600002288
                        ],
                        [
                            -114.46731500024,
                            32.9640600003178
                        ],
                        [
                            -114.468604999853,
                            32.9716490002934
                        ],
                        [
                            -114.470511000307,
                            32.9738580001708
                        ],
                        [
                            -114.476531999821,
                            32.9751739996663
                        ],
                        [
                            -114.488624999802,
                            32.9699460003102
                        ],
                        [
                            -114.492938000127,
                            32.9717809996967
                        ],
                        [
                            -114.501225999771,
                            33.0075560002359
                        ],
                        [
                            -114.507956000013,
                            33.0197079997681
                        ],
                        [
                            -114.516453999718,
                            33.0276169998718
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 34.2039355,
            "name": "Arizona",
            "intptlon": -111.6063565,
            "geo_point_2d": [
                34.2930127751,
                -111.664551938
            ],
            "geoid": "04",
            "mtfcc": "G4000",
            "region": 4
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -111.664551938,
                34.2930127751
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "8b8344ad833b8a9fda356fdbf3a5c58b474e8889",
        "fields": {
            "arealand": "861485430",
            "objectid": 40,
            "basename": "Nevada",
            "stusab": "NV",
            "statens": "01779793",
            "centlon": -116.6313445,
            "state": "32",
            "gid": 27,
            "centlat": 39.3292329,
            "division": 8,
            "areawater": "2047350889",
            "oid": "-1799193962",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -119.324183999974,
                            41.9939230001045
                        ],
                        [
                            -118.676498999924,
                            41.9921539996791
                        ],
                        [
                            -118.569725000014,
                            41.9944570000522
                        ],
                        [
                            -118.223188999668,
                            41.9972490000088
                        ],
                        [
                            -117.1336670001,
                            42.0004389996824
                        ],
                        [
                            -117.026200000253,
                            42.000194999674
                        ],
                        [
                            -117.008064999944,
                            41.9987419999104
                        ],
                        [
                            -116.368476999742,
                            41.996280000148
                        ],
                        [
                            -116.189435000376,
                            41.9976989997287
                        ],
                        [
                            -116.04537300022,
                            41.9963310000868
                        ],
                        [
                            -115.069421000029,
                            41.9961369996826
                        ],
                        [
                            -114.823490999766,
                            42.0018419999316
                        ],
                        [
                            -114.59828099996,
                            41.9945940002506
                        ],
                        [
                            -114.041477000424,
                            41.9938729997
                        ],
                        [
                            -114.039461000365,
                            41.7188740000175
                        ],
                        [
                            -114.042874000167,
                            40.7950709999927
                        ],
                        [
                            -114.046098999647,
                            40.401848000238
                        ],
                        [
                            -114.050520999752,
                            38.4961169998412
                        ],
                        [
                            -114.049324999851,
                            38.2660599998404
                        ],
                        [
                            -114.050499999836,
                            37.9533410001809
                        ],
                        [
                            -114.049612999731,
                            37.8235409997085
                        ],
                        [
                            -114.048302000202,
                            37.8094240001948
                        ],
                        [
                            -114.051653999905,
                            37.7514980000692
                        ],
                        [
                            -114.052884999966,
                            37.5608549997976
                        ],
                        [
                            -114.051626000315,
                            37.3039050000868
                        ],
                        [
                            -114.052541000009,
                            37.1128809997017
                        ],
                        [
                            -114.050072000214,
                            37.0140949998286
                        ],
                        [
                            -114.050326999584,
                            36.7528990002127
                        ],
                        [
                            -114.045646999736,
                            36.5210950002336
                        ],
                        [
                            -114.042843000204,
                            36.4481750003019
                        ],
                        [
                            -114.044050999801,
                            36.3176279997808
                        ],
                        [
                            -114.045558999872,
                            36.2888369997179
                        ],
                        [
                            -114.043943999671,
                            36.1933500000278
                        ],
                        [
                            -114.058661999981,
                            36.1878349998181
                        ],
                        [
                            -114.066798000187,
                            36.1790870000775
                        ],
                        [
                            -114.078831999718,
                            36.1574340002992
                        ],
                        [
                            -114.081233999566,
                            36.1502079998253
                        ],
                        [
                            -114.091701000374,
                            36.1373030002775
                        ],
                        [
                            -114.096993999792,
                            36.1208229997303
                        ],
                        [
                            -114.108381000316,
                            36.1191540003371
                        ],
                        [
                            -114.118496999697,
                            36.1138999997355
                        ],
                        [
                            -114.120865000109,
                            36.1108499996413
                        ],
                        [
                            -114.121033000338,
                            36.1038849998696
                        ],
                        [
                            -114.115207999744,
                            36.0998780000282
                        ],
                        [
                            -114.111900000423,
                            36.0958449999352
                        ],
                        [
                            -114.112297000083,
                            36.09404999995
                        ],
                        [
                            -114.119648000291,
                            36.0858220002695
                        ],
                        [
                            -114.124019000342,
                            36.0755630000782
                        ],
                        [
                            -114.133389000084,
                            36.0616649997865
                        ],
                        [
                            -114.136206000034,
                            36.0532319997942
                        ],
                        [
                            -114.134841000079,
                            36.0438730003374
                        ],
                        [
                            -114.137112000406,
                            36.0384909997559
                        ],
                        [
                            -114.144666000105,
                            36.0342720001488
                        ],
                        [
                            -114.145907000212,
                            36.0272290002491
                        ],
                        [
                            -114.151389999601,
                            36.0231329999527
                        ],
                        [
                            -114.164402000372,
                            36.0268519996513
                        ],
                        [
                            -114.17468299961,
                            36.0266699998192
                        ],
                        [
                            -114.19122100016,
                            36.0200189998876
                        ],
                        [
                            -114.211932000395,
                            36.0148340000941
                        ],
                        [
                            -114.22479799978,
                            36.013699000186
                        ],
                        [
                            -114.228014999763,
                            36.0147310000853
                        ],
                        [
                            -114.233442999798,
                            36.0128350000009
                        ],
                        [
                            -114.2438650004,
                            36.0152660002237
                        ],
                        [
                            -114.264379999918,
                            36.0279109996818
                        ],
                        [
                            -114.268586000113,
                            36.0350339998767
                        ],
                        [
                            -114.270861999565,
                            36.0455229997461
                        ],
                        [
                            -114.279637000178,
                            36.0461030003218
                        ],
                        [
                            -114.287991999769,
                            36.0490699998525
                        ],
                        [
                            -114.298592999572,
                            36.0572629998548
                        ],
                        [
                            -114.308624000361,
                            36.0569759998637
                        ],
                        [
                            -114.313591000265,
                            36.0590480003364
                        ],
                        [
                            -114.314327999683,
                            36.0620159998606
                        ],
                        [
                            -114.312419999579,
                            36.0661170003578
                        ],
                        [
                            -114.304383999831,
                            36.0740189999273
                        ],
                        [
                            -114.30693899984,
                            36.0824870000549
                        ],
                        [
                            -114.320866000305,
                            36.0964630001052
                        ],
                        [
                            -114.325813999942,
                            36.1039330002261
                        ],
                        [
                            -114.334632000213,
                            36.106783999683
                        ],
                        [
                            -114.345099999947,
                            36.118556000021
                        ],
                        [
                            -114.36062599982,
                            36.1294119996529
                        ],
                        [
                            -114.370181000408,
                            36.1426239999329
                        ],
                        [
                            -114.373744999823,
                            36.1437220001347
                        ],
                        [
                            -114.381479000346,
                            36.1413490000376
                        ],
                        [
                            -114.402280999919,
                            36.1465070001453
                        ],
                        [
                            -114.41249099991,
                            36.1465109999997
                        ],
                        [
                            -114.423114000353,
                            36.137350000078
                        ],
                        [
                            -114.445042000406,
                            36.1253460001131
                        ],
                        [
                            -114.451330999709,
                            36.1298310002209
                        ],
                        [
                            -114.456486999758,
                            36.1380319996798
                        ],
                        [
                            -114.462600000232,
                            36.1396439999716
                        ],
                        [
                            -114.468674000348,
                            36.1388890001878
                        ],
                        [
                            -114.483826999953,
                            36.1297199998137
                        ],
                        [
                            -114.501798000231,
                            36.126556000292
                        ],
                        [
                            -114.507201000152,
                            36.1284839998521
                        ],
                        [
                            -114.50920999964,
                            36.1332470002267
                        ],
                        [
                            -114.505149999933,
                            36.1380780000608
                        ],
                        [
                            -114.500338999666,
                            36.1406999996858
                        ],
                        [
                            -114.500235999734,
                            36.1432259997228
                        ],
                        [
                            -114.508104000151,
                            36.1497130000908
                        ],
                        [
                            -114.514280000374,
                            36.1507949997198
                        ],
                        [
                            -114.531090999838,
                            36.14764400024
                        ],
                        [
                            -114.539232999891,
                            36.1517640001756
                        ],
                        [
                            -114.548742000449,
                            36.1506969998762
                        ],
                        [
                            -114.556162000254,
                            36.1524700001633
                        ],
                        [
                            -114.56117299964,
                            36.1509209997533
                        ],
                        [
                            -114.571089999728,
                            36.1510989997437
                        ],
                        [
                            -114.58070700024,
                            36.1459869999253
                        ],
                        [
                            -114.596474000138,
                            36.1415370002577
                        ],
                        [
                            -114.609287999644,
                            36.1322290000677
                        ],
                        [
                            -114.615454999646,
                            36.129652999858
                        ],
                        [
                            -114.620604999848,
                            36.1317589998704
                        ],
                        [
                            -114.627078999997,
                            36.1407610001453
                        ],
                        [
                            -114.630474000255,
                            36.1422180000812
                        ],
                        [
                            -114.635809000405,
                            36.1361700001332
                        ],
                        [
                            -114.640125000205,
                            36.1351260002464
                        ],
                        [
                            -114.655512000161,
                            36.126186999918
                        ],
                        [
                            -114.664342999953,
                            36.1163000000118
                        ],
                        [
                            -114.671867000413,
                            36.1159639997299
                        ],
                        [
                            -114.681847000251,
                            36.1091920003044
                        ],
                        [
                            -114.693654999826,
                            36.1124820001009
                        ],
                        [
                            -114.70373699977,
                            36.10834800036
                        ],
                        [
                            -114.734084999711,
                            36.104672000243
                        ],
                        [
                            -114.748912999626,
                            36.0951829996846
                        ],
                        [
                            -114.753784000168,
                            36.0878069998014
                        ],
                        [
                            -114.754031999865,
                            36.0830930001723
                        ],
                        [
                            -114.75056999966,
                            36.0803299998981
                        ],
                        [
                            -114.748890999885,
                            36.0749809999515
                        ],
                        [
                            -114.74354200039,
                            36.0710370002629
                        ],
                        [
                            -114.740060000093,
                            36.0624370003626
                        ],
                        [
                            -114.73528499981,
                            36.0566480002522
                        ],
                        [
                            -114.735700999737,
                            36.0533930001731
                        ],
                        [
                            -114.74247899966,
                            36.0456969997864
                        ],
                        [
                            -114.741261999842,
                            36.0380439996478
                        ],
                        [
                            -114.733416999889,
                            36.0379130002794
                        ],
                        [
                            -114.722096000386,
                            36.0289519998792
                        ],
                        [
                            -114.728874000309,
                            36.0213869996549
                        ],
                        [
                            -114.738555000395,
                            36.0152230000629
                        ],
                        [
                            -114.743005000089,
                            36.0084499998972
                        ],
                        [
                            -114.739317999753,
                            35.9918040001474
                        ],
                        [
                            -114.743637999751,
                            35.9857849999276
                        ],
                        [
                            -114.740536000294,
                            35.9755450000609
                        ],
                        [
                            -114.728665999896,
                            35.96175699967
                        ],
                        [
                            -114.729762000238,
                            35.9598950002555
                        ],
                        [
                            -114.713311999552,
                            35.9338439997933
                        ],
                        [
                            -114.713412999834,
                            35.9318999996833
                        ],
                        [
                            -114.707603000207,
                            35.9279500002815
                        ],
                        [
                            -114.707398000167,
                            35.9180570002154
                        ],
                        [
                            -114.709187000446,
                            35.9168270000931
                        ],
                        [
                            -114.70811200002,
                            35.9099329997865
                        ],
                        [
                            -114.705714000369,
                            35.9093160003022
                        ],
                        [
                            -114.700257999846,
                            35.9017569999668
                        ],
                        [
                            -114.69492799972,
                            35.8975940003635
                        ],
                        [
                            -114.696064000245,
                            35.8964639999969
                        ],
                        [
                            -114.678971999676,
                            35.8855099997378
                        ],
                        [
                            -114.672008999805,
                            35.878018000048
                        ],
                        [
                            -114.663213999999,
                            35.8736920003004
                        ],
                        [
                            -114.661635999608,
                            35.8712329997507
                        ],
                        [
                            -114.669679999752,
                            35.8650360001616
                        ],
                        [
                            -114.687798000343,
                            35.8607279998869
                        ],
                        [
                            -114.691456000367,
                            35.8586610001922
                        ],
                        [
                            -114.694370000401,
                            35.8544630000983
                        ],
                        [
                            -114.701904000009,
                            35.8532230001221
                        ],
                        [
                            -114.705855999761,
                            35.850508000345
                        ],
                        [
                            -114.706287999582,
                            35.8462180001621
                        ],
                        [
                            -114.695276999699,
                            35.8310909998345
                        ],
                        [
                            -114.7036649998,
                            35.814613999955
                        ],
                        [
                            -114.709323999815,
                            35.8100499999052
                        ],
                        [
                            -114.712026000137,
                            35.8055289997328
                        ],
                        [
                            -114.699318000038,
                            35.790479999703
                        ],
                        [
                            -114.70102699995,
                            35.76967999984
                        ],
                        [
                            -114.694266999571,
                            35.7566329999385
                        ],
                        [
                            -114.697726000302,
                            35.7509659998452
                        ],
                        [
                            -114.696399999806,
                            35.7426529997241
                        ],
                        [
                            -114.697859000371,
                            35.7316570001785
                        ],
                        [
                            -114.705597000194,
                            35.7082740003047
                        ],
                        [
                            -114.701416000113,
                            35.7010840003561
                        ],
                        [
                            -114.682657000362,
                            35.6885709998376
                        ],
                        [
                            -114.680631000258,
                            35.684045999879
                        ],
                        [
                            -114.682316999706,
                            35.6778250001871
                        ],
                        [
                            -114.690214000435,
                            35.6651589996572
                        ],
                        [
                            -114.689507000255,
                            35.6514289999958
                        ],
                        [
                            -114.677615000116,
                            35.6417740000257
                        ],
                        [
                            -114.666682000052,
                            35.6230729997423
                        ],
                        [
                            -114.659461000438,
                            35.6195519998037
                        ],
                        [
                            -114.653533999737,
                            35.609671999991
                        ],
                        [
                            -114.653900000333,
                            35.5984910002893
                        ],
                        [
                            -114.659237999957,
                            35.587477000015
                        ],
                        [
                            -114.675666999829,
                            35.5800330001046
                        ],
                        [
                            -114.668392999614,
                            35.5743310001164
                        ],
                        [
                            -114.664433000364,
                            35.5684260003237
                        ],
                        [
                            -114.662804999745,
                            35.5642680002283
                        ],
                        [
                            -114.661457000406,
                            35.5440620001212
                        ],
                        [
                            -114.656769999987,
                            35.5349640000214
                        ],
                        [
                            -114.657753000351,
                            35.5307409996881
                        ],
                        [
                            -114.664600999696,
                            35.5215190001021
                        ],
                        [
                            -114.66954000001,
                            35.5207899996567
                        ],
                        [
                            -114.675685000271,
                            35.5156299998921
                        ],
                        [
                            -114.677480000397,
                            35.510947999905
                        ],
                        [
                            -114.678641999963,
                            35.4976280001991
                        ],
                        [
                            -114.676256999833,
                            35.4931030001417
                        ],
                        [
                            -114.67681500005,
                            35.4897870001694
                        ],
                        [
                            -114.671906999698,
                            35.4820870003636
                        ],
                        [
                            -114.673163999699,
                            35.4748140000432
                        ],
                        [
                            -114.665651000007,
                            35.4669110003465
                        ],
                        [
                            -114.667216999804,
                            35.4603699999232
                        ],
                        [
                            -114.664216999556,
                            35.4558450001446
                        ],
                        [
                            -114.662895999981,
                            35.4464489997055
                        ],
                        [
                            -114.654295000343,
                            35.4368539999687
                        ],
                        [
                            -114.652080000093,
                            35.4301339997231
                        ],
                        [
                            -114.626765000178,
                            35.4096439998946
                        ],
                        [
                            -114.62088699988,
                            35.3968669997625
                        ],
                        [
                            -114.617698000384,
                            35.3801309998016
                        ],
                        [
                            -114.611205999794,
                            35.3701189996753
                        ],
                        [
                            -114.604606999971,
                            35.3552389999286
                        ],
                        [
                            -114.595552999698,
                            35.3265469999637
                        ],
                        [
                            -114.596681999652,
                            35.2945570001219
                        ],
                        [
                            -114.586603999906,
                            35.2623859997324
                        ],
                        [
                            -114.58605300026,
                            35.2488909998814
                        ],
                        [
                            -114.582842000125,
                            35.2387029996728
                        ],
                        [
                            -114.583523000365,
                            35.2303480001377
                        ],
                        [
                            -114.58031200023,
                            35.2200950001645
                        ],
                        [
                            -114.57953499973,
                            35.2089110001882
                        ],
                        [
                            -114.574957999814,
                            35.2067140000221
                        ],
                        [
                            -114.572083999963,
                            35.200794000236
                        ],
                        [
                            -114.569257999792,
                            35.1834240001893
                        ],
                        [
                            -114.569528999953,
                            35.1623169998432
                        ],
                        [
                            -114.573879000089,
                            35.1453509997301
                        ],
                        [
                            -114.572596999974,
                            35.1395570001448
                        ],
                        [
                            -114.578262999661,
                            35.1288100000014
                        ],
                        [
                            -114.584876999552,
                            35.1251940003353
                        ],
                        [
                            -114.597793999888,
                            35.1217349996459
                        ],
                        [
                            -114.618696999744,
                            35.1217489997693
                        ],
                        [
                            -114.624954000158,
                            35.1207420001862
                        ],
                        [
                            -114.628992999949,
                            35.1194110001849
                        ],
                        [
                            -114.623760999731,
                            35.1206020000524
                        ],
                        [
                            -114.632281999902,
                            35.1170879998873
                        ],
                        [
                            -114.644353999966,
                            35.1059030002737
                        ],
                        [
                            -114.64676400021,
                            35.1018679997695
                        ],
                        [
                            -114.642830999826,
                            35.0965029996691
                        ],
                        [
                            -114.62251700015,
                            35.0887030002202
                        ],
                        [
                            -114.613132000339,
                            35.0830969999361
                        ],
                        [
                            -114.604735999842,
                            35.0748300001974
                        ],
                        [
                            -114.602908000104,
                            35.0685879999082
                        ],
                        [
                            -114.606694000175,
                            35.0589409997721
                        ],
                        [
                            -114.627124000203,
                            35.0447209999868
                        ],
                        [
                            -114.634732000329,
                            35.0337339999371
                        ],
                        [
                            -114.638190000336,
                            35.0220690000642
                        ],
                        [
                            -114.637071000428,
                            35.0103710002348
                        ],
                        [
                            -114.633487000024,
                            35.0018569999241
                        ],
                        [
                            -114.797340999625,
                            35.1349270001186
                        ],
                        [
                            -115.100957000373,
                            35.3756810000582
                        ],
                        [
                            -115.259720000222,
                            35.503540999858
                        ],
                        [
                            -115.695436999593,
                            35.8462579998167
                        ],
                        [
                            -116.094166000092,
                            36.156262999646
                        ],
                        [
                            -116.488233000286,
                            36.4590969997845
                        ],
                        [
                            -116.874253999787,
                            36.7520809998818
                        ],
                        [
                            -117.500117000089,
                            37.2203799998619
                        ],
                        [
                            -117.581417999901,
                            37.2789359998489
                        ],
                        [
                            -117.680609999624,
                            37.3533989997183
                        ],
                        [
                            -117.712357999681,
                            37.3749309998151
                        ],
                        [
                            -117.875926999775,
                            37.497267000023
                        ],
                        [
                            -117.904625000253,
                            37.5158359997378
                        ],
                        [
                            -118.052188999899,
                            37.6249300001613
                        ],
                        [
                            -118.714201000446,
                            38.1021049999393
                        ],
                        [
                            -119.125982000102,
                            38.3931700002632
                        ],
                        [
                            -119.453823000271,
                            38.6224329997542
                        ],
                        [
                            -120.001014000391,
                            38.9995739998671
                        ],
                        [
                            -120.006473000389,
                            39.2728780003376
                        ],
                        [
                            -120.006126999884,
                            39.3741060000853
                        ],
                        [
                            -120.002909000076,
                            39.4644019999406
                        ],
                        [
                            -120.000820000222,
                            39.606828000071
                        ],
                        [
                            -120.001133000217,
                            39.7522290002301
                        ],
                        [
                            -119.997706000171,
                            39.9999050001949
                        ],
                        [
                            -119.995705000181,
                            40.3758299997791
                        ],
                        [
                            -119.995976000342,
                            40.57054999967
                        ],
                        [
                            -119.999875000391,
                            40.9998940001065
                        ],
                        [
                            -119.998286999954,
                            41.6188180003087
                        ],
                        [
                            -119.999234000333,
                            41.9949440002954
                        ],
                        [
                            -119.94789399995,
                            41.9967529999631
                        ],
                        [
                            -119.826835000273,
                            41.9974770001489
                        ],
                        [
                            -119.324183999974,
                            41.9939230001045
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 39.3310928,
            "name": "Nevada",
            "intptlon": -116.6151469,
            "geo_point_2d": [
                39.35603619,
                -116.655208718
            ],
            "geoid": "32",
            "mtfcc": "G4000",
            "region": 4
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -116.655208718,
                39.35603619
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "9efc4352ba67d294a250b4f8f6bb7b54f8f8f3c4",
        "fields": {
            "arealand": "-1152137026",
            "objectid": 22,
            "basename": "Georgia",
            "stusab": "GA",
            "statens": "01705317",
            "centlon": -83.416413,
            "state": "13",
            "gid": 56,
            "centlat": 32.6279164,
            "division": 5,
            "areawater": "444263739",
            "oid": "269008297",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -85.3865810000125,
                            33.901718999712
                        ],
                        [
                            -85.4294699996475,
                            34.1250959997144
                        ],
                        [
                            -85.470450000252,
                            34.3282390000213
                        ],
                        [
                            -85.5614160002565,
                            34.7500789998558
                        ],
                        [
                            -85.5905010003488,
                            34.8989449996583
                        ],
                        [
                            -85.6051650002321,
                            34.9846780002776
                        ],
                        [
                            -85.3849669996365,
                            34.9829869999777
                        ],
                        [
                            -85.2356430000247,
                            34.9858339996416
                        ],
                        [
                            -84.8915150002736,
                            34.9882430001005
                        ],
                        [
                            -83.7498930004389,
                            34.9878360000426
                        ],
                        [
                            -83.6248820001115,
                            34.9866279998032
                        ],
                        [
                            -83.6199500003683,
                            34.9866690003375
                        ],
                        [
                            -83.6200509997524,
                            34.992031999961
                        ],
                        [
                            -83.2968500001465,
                            34.9962750002584
                        ],
                        [
                            -83.1106099998352,
                            35.0013030002248
                        ],
                        [
                            -83.1028669999898,
                            34.9972570002315
                        ],
                        [
                            -83.1035160004432,
                            34.9936749996642
                        ],
                        [
                            -83.0983119998152,
                            34.9906649999796
                        ],
                        [
                            -83.1028589995939,
                            34.9887079997677
                        ],
                        [
                            -83.101799000135,
                            34.986126000105
                        ],
                        [
                            -83.106964000405,
                            34.9835360003326
                        ],
                        [
                            -83.1057659999555,
                            34.9815030002183
                        ],
                        [
                            -83.1077350001588,
                            34.9798050001293
                        ],
                        [
                            -83.1112750001821,
                            34.9810029997967
                        ],
                        [
                            -83.1111830001206,
                            34.9757189998088
                        ],
                        [
                            -83.1165309997904,
                            34.9757229996407
                        ],
                        [
                            -83.1156210001193,
                            34.9730500003039
                        ],
                        [
                            -83.1203260000818,
                            34.9687830003259
                        ],
                        [
                            -83.1221100003371,
                            34.9643289997736
                        ],
                        [
                            -83.1216199998923,
                            34.9627659999117
                        ],
                        [
                            -83.1157909999986,
                            34.9601670002978
                        ],
                        [
                            -83.1202309996472,
                            34.9591310003307
                        ],
                        [
                            -83.1202880004469,
                            34.9579629996448
                        ],
                        [
                            -83.1148219998781,
                            34.9528499998357
                        ],
                        [
                            -83.1140589996218,
                            34.9502790003146
                        ],
                        [
                            -83.1244600003069,
                            34.955027000147
                        ],
                        [
                            -83.127052000126,
                            34.9542389997602
                        ],
                        [
                            -83.1247520003849,
                            34.9510739997178
                        ],
                        [
                            -83.1274339997174,
                            34.9493029997082
                        ],
                        [
                            -83.121077999569,
                            34.9421540000659
                        ],
                        [
                            -83.1165469996839,
                            34.9425049998612
                        ],
                        [
                            -83.1117549996828,
                            34.9349630001828
                        ],
                        [
                            -83.1179049999669,
                            34.9360430002873
                        ],
                        [
                            -83.1205439996417,
                            34.9329449998168
                        ],
                        [
                            -83.127312000417,
                            34.9386330000206
                        ],
                        [
                            -83.1317369999969,
                            34.9357189999817
                        ],
                        [
                            -83.1299589995894,
                            34.9313470001057
                        ],
                        [
                            -83.1415179996426,
                            34.9245149996596
                        ],
                        [
                            -83.1568910002538,
                            34.9307670000086
                        ],
                        [
                            -83.1592410002235,
                            34.9286080000066
                        ],
                        [
                            -83.1569899999879,
                            34.9151230003678
                        ],
                        [
                            -83.1604229998811,
                            34.915015999718
                        ],
                        [
                            -83.1648409997884,
                            34.9191700003618
                        ],
                        [
                            -83.1686069997674,
                            34.9193550003297
                        ],
                        [
                            -83.1707090000401,
                            34.9142410001147
                        ],
                        [
                            -83.174629000005,
                            34.910281999765
                        ],
                        [
                            -83.1809159996581,
                            34.9094699996902
                        ],
                        [
                            -83.1806949997251,
                            34.9045790001927
                        ],
                        [
                            -83.1881260002993,
                            34.8980869998449
                        ],
                        [
                            -83.1914980000931,
                            34.8967820002704
                        ],
                        [
                            -83.1945109998617,
                            34.8994330000223
                        ],
                        [
                            -83.1976619997225,
                            34.894767999819
                        ],
                        [
                            -83.1971820002218,
                            34.8905940001983
                        ],
                        [
                            -83.1986009997059,
                            34.8903849998772
                        ],
                        [
                            -83.2003330000827,
                            34.8932530003164
                        ],
                        [
                            -83.203536999647,
                            34.893451999938
                        ],
                        [
                            -83.2047579996626,
                            34.8902169999105
                        ],
                        [
                            -83.2010340004147,
                            34.8844069997184
                        ],
                        [
                            -83.2052609996279,
                            34.8801650001967
                        ],
                        [
                            -83.2095720003033,
                            34.8798559999873
                        ],
                        [
                            -83.2145769998414,
                            34.8836290001767
                        ],
                        [
                            -83.2127690001949,
                            34.88761499983
                        ],
                        [
                            -83.2140270000205,
                            34.8885690000255
                        ],
                        [
                            -83.2172769996155,
                            34.8860890001847
                        ],
                        [
                            -83.2174220002788,
                            34.8823810003576
                        ],
                        [
                            -83.2198869998763,
                            34.8811109998732
                        ],
                        [
                            -83.2201310002738,
                            34.8780360001514
                        ],
                        [
                            -83.2292480002957,
                            34.8797610000878
                        ],
                        [
                            -83.2324900003932,
                            34.8777770001957
                        ],
                        [
                            -83.2395100001653,
                            34.8788869998395
                        ],
                        [
                            -83.2429959997619,
                            34.8775369996364
                        ],
                        [
                            -83.2405849996927,
                            34.8727909996846
                        ],
                        [
                            -83.2347030000956,
                            34.8707429999973
                        ],
                        [
                            -83.237877000421,
                            34.8688769998544
                        ],
                        [
                            -83.2391129996069,
                            34.8650859999792
                        ],
                        [
                            -83.2460250003223,
                            34.8672290002788
                        ],
                        [
                            -83.2480920004349,
                            34.8655770000616
                        ],
                        [
                            -83.2463430003396,
                            34.8600250003415
                        ],
                        [
                            -83.2482380000247,
                            34.8581699997003
                        ],
                        [
                            -83.2464139995864,
                            34.8555179998248
                        ],
                        [
                            -83.2533189997308,
                            34.8559229999527
                        ],
                        [
                            -83.2524500000677,
                            34.8521849999219
                        ],
                        [
                            -83.253905000435,
                            34.8487689999467
                        ],
                        [
                            -83.2489269997628,
                            34.8451410002602
                        ],
                        [
                            -83.2572859995513,
                            34.8440999998107
                        ],
                        [
                            -83.2606320003042,
                            34.8452849999039
                        ],
                        [
                            -83.2617250002733,
                            34.8486370001576
                        ],
                        [
                            -83.2671959999667,
                            34.8484689996508
                        ],
                        [
                            -83.2725880000174,
                            34.835512999666
                        ],
                        [
                            -83.2671800000732,
                            34.8313249996486
                        ],
                        [
                            -83.269637999998,
                            34.8236470001879
                        ],
                        [
                            -83.2683180002481,
                            34.8211249997932
                        ],
                        [
                            -83.2708819995799,
                            34.8185879999201
                        ],
                        [
                            -83.2770839997425,
                            34.81699799966
                        ],
                        [
                            -83.2890319999581,
                            34.8246460001041
                        ],
                        [
                            -83.2910770003293,
                            34.8231929996369
                        ],
                        [
                            -83.2899550000479,
                            34.8184589997641
                        ],
                        [
                            -83.2945169998952,
                            34.8160319996898
                        ],
                        [
                            -83.2951429998841,
                            34.8132250001758
                        ],
                        [
                            -83.2998500003946,
                            34.8150370003198
                        ],
                        [
                            -83.298949999871,
                            34.8174480002589
                        ],
                        [
                            -83.300568000445,
                            34.8186609997931
                        ],
                        [
                            -83.3030089997531,
                            34.8188360001042
                        ],
                        [
                            -83.3063660003766,
                            34.8156700000236
                        ],
                        [
                            -83.3044049996709,
                            34.8111500000335
                        ],
                        [
                            -83.2994160000264,
                            34.8094519997697
                        ],
                        [
                            -83.3008420000814,
                            34.8043440001394
                        ],
                        [
                            -83.3032230000133,
                            34.8022080000219
                        ],
                        [
                            -83.3141629997503,
                            34.7995609999412
                        ],
                        [
                            -83.3140640000161,
                            34.7964709997036
                        ],
                        [
                            -83.320404000271,
                            34.7931790001844
                        ],
                        [
                            -83.3204419999058,
                            34.7905809997858
                        ],
                        [
                            -83.3241419997627,
                            34.7892459996721
                        ],
                        [
                            -83.3212129996597,
                            34.7790410002869
                        ],
                        [
                            -83.3226620001793,
                            34.7773900003064
                        ],
                        [
                            -83.318977000391,
                            34.7713090001131
                        ],
                        [
                            -83.3229979997399,
                            34.7662470000113
                        ],
                        [
                            -83.3191759996843,
                            34.7646139998881
                        ],
                        [
                            -83.3232959996657,
                            34.7607380001763
                        ],
                        [
                            -83.3187480000622,
                            34.7576260002616
                        ],
                        [
                            -83.3262330001647,
                            34.7562520001442
                        ],
                        [
                            -83.3263849996023,
                            34.7507479996787
                        ],
                        [
                            -83.3323290000221,
                            34.7491150000417
                        ],
                        [
                            -83.3330539997451,
                            34.7456020002999
                        ],
                        [
                            -83.3389129997761,
                            34.7417829999833
                        ],
                        [
                            -83.3391649996712,
                            34.7382850002319
                        ],
                        [
                            -83.3486249998247,
                            34.7370409997615
                        ],
                        [
                            -83.3534160000009,
                            34.7284240002608
                        ],
                        [
                            -83.3526920001027,
                            34.7169039996787
                        ],
                        [
                            -83.3504869998979,
                            34.709694000207
                        ],
                        [
                            -83.3466489999488,
                            34.7042199997313
                        ],
                        [
                            -83.3529890002036,
                            34.7008400002983
                        ],
                        [
                            -83.3537900000947,
                            34.6981660002109
                        ],
                        [
                            -83.3401339997917,
                            34.6914829999458
                        ],
                        [
                            -83.3382459997793,
                            34.687033000155
                        ],
                        [
                            -83.3426169998309,
                            34.6829909997325
                        ],
                        [
                            -83.3420040002608,
                            34.6817700002901
                        ],
                        [
                            -83.3389000002556,
                            34.6800389998274
                        ],
                        [
                            -83.3302839996508,
                            34.6813420003395
                        ],
                        [
                            -83.3253360000141,
                            34.6795169997496
                        ],
                        [
                            -83.3194400001733,
                            34.6759740002375
                        ],
                        [
                            -83.3164009995671,
                            34.66931600017
                        ],
                        [
                            -83.3046409996725,
                            34.6695610000901
                        ],
                        [
                            -83.3014770002911,
                            34.6665819999709
                        ],
                        [
                            -83.3008479999292,
                            34.6624699996395
                        ],
                        [
                            -83.2931829999016,
                            34.654295999856
                        ],
                        [
                            -83.2807819999495,
                            34.6489959998361
                        ],
                        [
                            -83.2779599999766,
                            34.6448530000906
                        ],
                        [
                            -83.27198200012,
                            34.6418959997945
                        ],
                        [
                            -83.2625820002409,
                            34.6403959999219
                        ],
                        [
                            -83.2538810001456,
                            34.636696000175
                        ],
                        [
                            -83.2482809996824,
                            34.6316960001932
                        ],
                        [
                            -83.2464810004319,
                            34.6268970000059
                        ],
                        [
                            -83.2406690002566,
                            34.6245070001033
                        ],
                        [
                            -83.2433809997263,
                            34.6179969997764
                        ],
                        [
                            -83.2325799999065,
                            34.6115969998929
                        ],
                        [
                            -83.2222829998769,
                            34.6094760002361
                        ],
                        [
                            -83.2164029999295,
                            34.6110109997552
                        ],
                        [
                            -83.2037850002424,
                            34.6095990001569
                        ],
                        [
                            -83.1977789998984,
                            34.6070979999626
                        ],
                        [
                            -83.1968790002731,
                            34.6030979996449
                        ],
                        [
                            -83.1852780003871,
                            34.6069979997337
                        ],
                        [
                            -83.1725779997859,
                            34.6077979996719
                        ],
                        [
                            -83.1596770002416,
                            34.6032979997635
                        ],
                        [
                            -83.1605769998669,
                            34.5994980001822
                        ],
                        [
                            -83.1650779996148,
                            34.6008979998249
                        ],
                        [
                            -83.1709779996536,
                            34.5980979998637
                        ],
                        [
                            -83.1696739997973,
                            34.5916930000115
                        ],
                        [
                            -83.154577000269,
                            34.5881980003551
                        ],
                        [
                            -83.159077000192,
                            34.5814990001598
                        ],
                        [
                            -83.1586770001589,
                            34.5789989999248
                        ],
                        [
                            -83.1536769997454,
                            34.5786990001983
                        ],
                        [
                            -83.1431760003995,
                            34.5733989998299
                        ],
                        [
                            -83.1398759996775,
                            34.567998999786
                        ],
                        [
                            -83.1398759996775,
                            34.5617990002481
                        ],
                        [
                            -83.133375999589,
                            34.5594989999383
                        ],
                        [
                            -83.1271759999746,
                            34.5619989999069
                        ],
                        [
                            -83.1229039996572,
                            34.560116999861
                        ],
                        [
                            -83.1220519997125,
                            34.5580280002325
                        ],
                        [
                            -83.124054000426,
                            34.5540679996579
                        ],
                        [
                            -83.121894000427,
                            34.5523379999101
                        ],
                        [
                            -83.1162670001997,
                            34.5521720003027
                        ],
                        [
                            -83.1106740003076,
                            34.5478580000198
                        ],
                        [
                            -83.1092390000317,
                            34.5421780001772
                        ],
                        [
                            -83.1040789997846,
                            34.5400400000146
                        ],
                        [
                            -83.1026920000876,
                            34.5324019999981
                        ],
                        [
                            -83.08782200034,
                            34.5320849999817
                        ],
                        [
                            -83.0783989999963,
                            34.52537200025
                        ],
                        [
                            -83.0779110000997,
                            34.5237730001003
                        ],
                        [
                            -83.0872420003818,
                            34.5161820001774
                        ],
                        [
                            -83.0792459999182,
                            34.5141220002311
                        ],
                        [
                            -83.0764540000825,
                            34.5077929997314
                        ],
                        [
                            -83.0725859999962,
                            34.50410500006
                        ],
                        [
                            -83.0657960003778,
                            34.5010409998815
                        ],
                        [
                            -83.0586970000647,
                            34.5035830001384
                        ],
                        [
                            -83.0543219998151,
                            34.5027939999151
                        ],
                        [
                            -83.0516379999345,
                            34.4937020000581
                        ],
                        [
                            -83.0484240003245,
                            34.4934389999968
                        ],
                        [
                            -83.0345649996319,
                            34.4835709999716
                        ],
                        [
                            -83.0283950001547,
                            34.4838760001665
                        ],
                        [
                            -83.0172520000281,
                            34.478246999711
                        ],
                        [
                            -82.9959009995603,
                            34.4722039999672
                        ],
                        [
                            -82.992737000179,
                            34.4789469998127
                        ],
                        [
                            -82.979746000223,
                            34.4829379999968
                        ],
                        [
                            -82.974917000412,
                            34.4793500003132
                        ],
                        [
                            -82.9664799999073,
                            34.4823330002529
                        ],
                        [
                            -82.9642410002656,
                            34.4845710000866
                        ],
                        [
                            -82.9585479999161,
                            34.4795539997841
                        ],
                        [
                            -82.9556449997523,
                            34.4788869998014
                        ],
                        [
                            -82.9482280003202,
                            34.4804920002384
                        ],
                        [
                            -82.9428580000108,
                            34.4859109999568
                        ],
                        [
                            -82.9304150002259,
                            34.4856130000401
                        ],
                        [
                            -82.9252020002753,
                            34.4814609996414
                        ],
                        [
                            -82.9177360004394,
                            34.4841679996312
                        ],
                        [
                            -82.9015510000665,
                            34.4867640002013
                        ],
                        [
                            -82.8924480002883,
                            34.4811070003619
                        ],
                        [
                            -82.887397999646,
                            34.4809590001384
                        ],
                        [
                            -82.8813450003447,
                            34.4728670002558
                        ],
                        [
                            -82.8806440000127,
                            34.4690000000118
                        ],
                        [
                            -82.8734789995773,
                            34.4643189998664
                        ],
                        [
                            -82.871845999833,
                            34.4611889997544
                        ],
                        [
                            -82.8644000000886,
                            34.4597849997624
                        ],
                        [
                            -82.8590680003124,
                            34.4554349997515
                        ],
                        [
                            -82.8601239995732,
                            34.4528400000677
                        ],
                        [
                            -82.855485999558,
                            34.441790999946
                        ],
                        [
                            -82.8540109999974,
                            34.428818000166
                        ],
                        [
                            -82.8496259997021,
                            34.4256780000696
                        ],
                        [
                            -82.8469689995857,
                            34.4198760002086
                        ],
                        [
                            -82.847510999909,
                            34.4127610001354
                        ],
                        [
                            -82.8414529996864,
                            34.3990540002154
                        ],
                        [
                            -82.8417740000768,
                            34.3915530001384
                        ],
                        [
                            -82.8359750003203,
                            34.3809089998556
                        ],
                        [
                            -82.8344570002038,
                            34.3652210002402
                        ],
                        [
                            -82.813770000258,
                            34.3539349999756
                        ],
                        [
                            -82.8126069999687,
                            34.3511439999015
                        ],
                        [
                            -82.7944700000093,
                            34.3403789996455
                        ],
                        [
                            -82.7911779996831,
                            34.3305410002193
                        ],
                        [
                            -82.7917450001208,
                            34.3251609997746
                        ],
                        [
                            -82.7870610000748,
                            34.310299000091
                        ],
                        [
                            -82.7819929998892,
                            34.3031769998995
                        ],
                        [
                            -82.7821279996084,
                            34.2982780002757
                        ],
                        [
                            -82.7715150001095,
                            34.2860529999578
                        ],
                        [
                            -82.7667540000706,
                            34.2823220002287
                        ],
                        [
                            -82.7616880004332,
                            34.2816850001055
                        ],
                        [
                            -82.7542419997904,
                            34.2757870000993
                        ],
                        [
                            -82.7468110001145,
                            34.2665880001131
                        ],
                        [
                            -82.7485359999203,
                            34.2631259999822
                        ],
                        [
                            -82.7458250002755,
                            34.2583560000246
                        ],
                        [
                            -82.7455669996344,
                            34.2540660000195
                        ],
                        [
                            -82.7429199995637,
                            34.2510719999186
                        ],
                        [
                            -82.7448729998734,
                            34.2449189998725
                        ],
                        [
                            -82.7420120004408,
                            34.2293280003488
                        ],
                        [
                            -82.7443630002355,
                            34.2254100002966
                        ],
                        [
                            -82.7402569995995,
                            34.2194479999894
                        ],
                        [
                            -82.7423399996055,
                            34.2134319996609
                        ],
                        [
                            -82.7418750001734,
                            34.2102090003543
                        ],
                        [
                            -82.7321239997667,
                            34.1933210002598
                        ],
                        [
                            -82.731452999572,
                            34.1770859996921
                        ],
                        [
                            -82.7247690002589,
                            34.1686940003144
                        ],
                        [
                            -82.7174590000585,
                            34.15054600037
                        ],
                        [
                            -82.7041290001688,
                            34.1410209997378
                        ],
                        [
                            -82.6921519996409,
                            34.1389859997938
                        ],
                        [
                            -82.6862900001352,
                            34.1344540001146
                        ],
                        [
                            -82.6769849997923,
                            34.1314250002252
                        ],
                        [
                            -82.6685590000567,
                            34.1204349996344
                        ],
                        [
                            -82.6663439998061,
                            34.1127509999512
                        ],
                        [
                            -82.6587290000072,
                            34.1037720003535
                        ],
                        [
                            -82.6478360001259,
                            34.0985479997468
                        ],
                        [
                            -82.6424159995878,
                            34.0932640002823
                        ],
                        [
                            -82.6401690004485,
                            34.0873289997253
                        ],
                        [
                            -82.6453670003305,
                            34.0782570003232
                        ],
                        [
                            -82.6436609998929,
                            34.0721050000977
                        ],
                        [
                            -82.6389850002428,
                            34.0667790002054
                        ],
                        [
                            -82.6266319999712,
                            34.0633390001915
                        ],
                        [
                            -82.6212869997762,
                            34.0578289998252
                        ],
                        [
                            -82.6191560000895,
                            34.051350000167
                        ],
                        [
                            -82.6130140002014,
                            34.0468979998481
                        ],
                        [
                            -82.6094209995764,
                            34.0399059997713
                        ],
                        [
                            -82.5944589997672,
                            34.0293039998995
                        ],
                        [
                            -82.5954970003831,
                            34.0158649999619
                        ],
                        [
                            -82.5889589997615,
                            34.0005119997593
                        ],
                        [
                            -82.5850289997509,
                            33.9961710002058
                        ],
                        [
                            -82.575554000427,
                            33.9922110001394
                        ],
                        [
                            -82.5755689995973,
                            33.9902350002329
                        ],
                        [
                            -82.5801239997719,
                            33.9864469999251
                        ],
                        [
                            -82.5799799998319,
                            33.9805190003326
                        ],
                        [
                            -82.5675899997504,
                            33.9678800000908
                        ],
                        [
                            -82.5652020001458,
                            33.9567649998207
                        ],
                        [
                            -82.5567649996411,
                            33.9453240003525
                        ],
                        [
                            -82.5431069996883,
                            33.9409499997927
                        ],
                        [
                            -82.5341110000403,
                            33.9436509997457
                        ],
                        [
                            -82.5245150003426,
                            33.9433600000378
                        ],
                        [
                            -82.5114220002793,
                            33.9356129999049
                        ],
                        [
                            -82.4929289997694,
                            33.9097539996672
                        ],
                        [
                            -82.4801110000657,
                            33.9018969997905
                        ],
                        [
                            -82.4551049999469,
                            33.8816500003091
                        ],
                        [
                            -82.4405029999879,
                            33.8751229999019
                        ],
                        [
                            -82.4311499999645,
                            33.8670510000027
                        ],
                        [
                            -82.4248880004258,
                            33.8641329999241
                        ],
                        [
                            -82.4083530002485,
                            33.8663199999899
                        ],
                        [
                            -82.4038790002647,
                            33.8654750001573
                        ],
                        [
                            -82.395734999663,
                            33.8590879996938
                        ],
                        [
                            -82.3797710001214,
                            33.8510959997791
                        ],
                        [
                            -82.3717749996577,
                            33.8438130003418
                        ],
                        [
                            -82.3469329995705,
                            33.8342980003495
                        ],
                        [
                            -82.3378289999674,
                            33.827155999905
                        ],
                        [
                            -82.324479999811,
                            33.820033000212
                        ],
                        [
                            -82.314350000186,
                            33.8111110000208
                        ],
                        [
                            -82.3128999998415,
                            33.8074860000915
                        ],
                        [
                            -82.3002129996591,
                            33.8006270000251
                        ],
                        [
                            -82.2982860001869,
                            33.7835180001099
                        ],
                        [
                            -82.2858040000439,
                            33.7800580000657
                        ],
                        [
                            -82.2776810002569,
                            33.7720319996684
                        ],
                        [
                            -82.2661270002266,
                            33.766745000101
                        ],
                        [
                            -82.2632059996212,
                            33.7619620002777
                        ],
                        [
                            -82.2552669999572,
                            33.7596900000197
                        ],
                        [
                            -82.2474720002333,
                            33.7525909997437
                        ],
                        [
                            -82.2456639996885,
                            33.7434430001606
                        ],
                        [
                            -82.2390980003762,
                            33.7308719999014
                        ],
                        [
                            -82.2357530003466,
                            33.7143899999643
                        ],
                        [
                            -82.2371919999221,
                            33.7078799996671
                        ],
                        [
                            -82.2345719996156,
                            33.7002169999486
                        ],
                        [
                            -82.2178830003509,
                            33.6855390000442
                        ],
                        [
                            -82.2103610004384,
                            33.6698370001152
                        ],
                        [
                            -82.1998979998291,
                            33.661699999782
                        ],
                        [
                            -82.2011859997919,
                            33.6468979998474
                        ],
                        [
                            -82.1968950001062,
                            33.6314400001754
                        ],
                        [
                            -82.1861550003858,
                            33.620880999713
                        ],
                        [
                            -82.1761720001755,
                            33.613774999853
                        ],
                        [
                            -82.1562879997707,
                            33.6086300000434
                        ],
                        [
                            -82.1533570000178,
                            33.6063190002226
                        ],
                        [
                            -82.1488160000869,
                            33.5980920001972
                        ],
                        [
                            -82.1380279997876,
                            33.592235999987
                        ],
                        [
                            -82.1290799998201,
                            33.5899250000605
                        ],
                        [
                            -82.1175049998734,
                            33.5962290000749
                        ],
                        [
                            -82.1078399996804,
                            33.5962770001023
                        ],
                        [
                            -82.0941280001992,
                            33.5827420002901
                        ],
                        [
                            -82.0731039999696,
                            33.577510000373
                        ],
                        [
                            -82.0577270000588,
                            33.5667739998088
                        ],
                        [
                            -82.0463350004102,
                            33.5638299997541
                        ],
                        [
                            -82.0373749998488,
                            33.5546620002532
                        ],
                        [
                            -82.0330230000638,
                            33.5464540000039
                        ],
                        [
                            -82.0256130003043,
                            33.5427560002757
                        ],
                        [
                            -82.0234380002367,
                            33.5407339999699
                        ],
                        [
                            -82.0234380002367,
                            33.5379350001985
                        ],
                        [
                            -82.019837999939,
                            33.5350350000402
                        ],
                        [
                            -82.0109650003146,
                            33.5313399997429
                        ],
                        [
                            -82.0076379998283,
                            33.5233350001264
                        ],
                        [
                            -82.0013379997564,
                            33.520134999712
                        ],
                        [
                            -81.9919379998773,
                            33.5044349998335
                        ],
                        [
                            -81.9903820001259,
                            33.5007589999502
                        ],
                        [
                            -81.9909379997946,
                            33.494235000256
                        ],
                        [
                            -81.9859380002794,
                            33.486536000145
                        ],
                        [
                            -81.9341359999387,
                            33.4683370001796
                        ],
                        [
                            -81.9263360001919,
                            33.4629369998408
                        ],
                        [
                            -81.9194360000704,
                            33.4490980002991
                        ],
                        [
                            -81.9138520003991,
                            33.4420259996546
                        ],
                        [
                            -81.9131259999528,
                            33.4383329998282
                        ],
                        [
                            -81.917323999752,
                            33.4323460000122
                        ],
                        [
                            -81.9297409995977,
                            33.4295939996947
                        ],
                        [
                            -81.9324300003995,
                            33.4257060003182
                        ],
                        [
                            -81.9245500002867,
                            33.4191090001123
                        ],
                        [
                            -81.9107650001123,
                            33.4150350002047
                        ],
                        [
                            -81.9096930000596,
                            33.4119790002946
                        ],
                        [
                            -81.912098000281,
                            33.4082599997796
                        ],
                        [
                            -81.916405999685,
                            33.4073489998482
                        ],
                        [
                            -81.9217740003446,
                            33.4088669998119
                        ],
                        [
                            -81.9244980004082,
                            33.4124790002135
                        ],
                        [
                            -81.9313900001338,
                            33.4069560002002
                        ],
                        [
                            -81.9426689998045,
                            33.4091949997294
                        ],
                        [
                            -81.945181000156,
                            33.4075310002278
                        ],
                        [
                            -81.9444759996261,
                            33.4036740001875
                        ],
                        [
                            -81.9354530002138,
                            33.3978509998057
                        ],
                        [
                            -81.9308610002293,
                            33.3800760000393
                        ],
                        [
                            -81.9248370003419,
                            33.3741399998105
                        ],
                        [
                            -81.9257369999672,
                            33.3711399996703
                        ],
                        [
                            -81.9306340004486,
                            33.3681650003066
                        ],
                        [
                            -81.9346370002541,
                            33.3689399999794
                        ],
                        [
                            -81.9396369997693,
                            33.3725400003085
                        ],
                        [
                            -81.9463369998743,
                            33.3706399997156
                        ],
                        [
                            -81.9467369999074,
                            33.367340000111
                        ],
                        [
                            -81.944736999742,
                            33.3640409999814
                        ],
                        [
                            -81.9348320002478,
                            33.3560440001271
                        ],
                        [
                            -81.9356370003368,
                            33.3520409996773
                        ],
                        [
                            -81.9398369997859,
                            33.3477410002911
                        ],
                        [
                            -81.9397370002268,
                            33.3449410002176
                        ],
                        [
                            -81.9327369996478,
                            33.3435409998104
                        ],
                        [
                            -81.9247369998845,
                            33.3453410002734
                        ],
                        [
                            -81.9188000000358,
                            33.3402089998818
                        ],
                        [
                            -81.9132149996412,
                            33.3468209998112
                        ],
                        [
                            -81.9091370003909,
                            33.3466409997712
                        ],
                        [
                            -81.9084369998839,
                            33.3438419999326
                        ],
                        [
                            -81.9150370004297,
                            33.3415420002152
                        ],
                        [
                            -81.9191370003197,
                            33.334442000248
                        ],
                        [
                            -81.9081370003082,
                            33.3271419996434
                        ],
                        [
                            -81.9091370003909,
                            33.3239419996968
                        ],
                        [
                            -81.9137369998731,
                            33.3200420000205
                        ],
                        [
                            -81.9115369996911,
                            33.3191420001405
                        ],
                        [
                            -81.9061299995718,
                            33.321619999682
                        ],
                        [
                            -81.9060369996854,
                            33.3260420001562
                        ],
                        [
                            -81.9027369998616,
                            33.3302420001115
                        ],
                        [
                            -81.9036370003852,
                            33.334841999743
                        ],
                        [
                            -81.9013839996015,
                            33.335773999854
                        ],
                        [
                            -81.8965370002471,
                            33.3295419997925
                        ],
                        [
                            -81.8994370000378,
                            33.3251419999369
                        ],
                        [
                            -81.8990370000048,
                            33.3233420001356
                        ],
                        [
                            -81.8955370001644,
                            33.3216419999634
                        ],
                        [
                            -81.8905369997509,
                            33.3224419998039
                        ],
                        [
                            -81.8891369996351,
                            33.3210430001795
                        ],
                        [
                            -81.8925369999163,
                            33.3153430000343
                        ],
                        [
                            -81.8837370000868,
                            33.317643000095
                        ],
                        [
                            -81.8816359996391,
                            33.315843000053
                        ],
                        [
                            -81.8846369997121,
                            33.3089429996639
                        ],
                        [
                            -81.8807369998387,
                            33.3022430001737
                        ],
                        [
                            -81.8679359998535,
                            33.3140429996265
                        ],
                        [
                            -81.8649359996054,
                            33.3118429999316
                        ],
                        [
                            -81.86392899985,
                            33.3020360000786
                        ],
                        [
                            -81.8551859999219,
                            33.3103949997852
                        ],
                        [
                            -81.8473359999464,
                            33.306843000147
                        ],
                        [
                            -81.846036000288,
                            33.3043430000996
                        ],
                        [
                            -81.8495360001283,
                            33.3000440001662
                        ],
                        [
                            -81.8487360000622,
                            33.2967439996309
                        ],
                        [
                            -81.8503360001945,
                            33.2964439998763
                        ],
                        [
                            -81.8529360004095,
                            33.2996440001296
                        ],
                        [
                            -81.8600359996493,
                            33.2991440003199
                        ],
                        [
                            -81.8620359998147,
                            33.2969440001455
                        ],
                        [
                            -81.8632359999139,
                            33.2888439996449
                        ],
                        [
                            -81.8576360003491,
                            33.2846439997482
                        ],
                        [
                            -81.8456360002549,
                            33.2842439996936
                        ],
                        [
                            -81.8440360001226,
                            33.2786440002007
                        ],
                        [
                            -81.8388359996925,
                            33.2737450003605
                        ],
                        [
                            -81.8326360000781,
                            33.2758449997785
                        ],
                        [
                            -81.8357380004335,
                            33.2710449996366
                        ],
                        [
                            -81.8358360003427,
                            33.2628450000903
                        ],
                        [
                            -81.8334360001442,
                            33.2622450001062
                        ],
                        [
                            -81.8301360003205,
                            33.2645450000722
                        ],
                        [
                            -81.8283360001716,
                            33.2630450001707
                        ],
                        [
                            -81.8345359997861,
                            33.2583450000342
                        ],
                        [
                            -81.8438620000453,
                            33.2666209998427
                        ],
                        [
                            -81.8473359999464,
                            33.2663450003511
                        ],
                        [
                            -81.8481360000126,
                            33.2639449997476
                        ],
                        [
                            -81.8461359998471,
                            33.2581449997094
                        ],
                        [
                            -81.8410359998745,
                            33.2570449998798
                        ],
                        [
                            -81.8396359997587,
                            33.2552450002642
                        ],
                        [
                            -81.8451359997644,
                            33.2525450000476
                        ],
                        [
                            -81.8511370000856,
                            33.2525450000476
                        ],
                        [
                            -81.853137000251,
                            33.2507450001305
                        ],
                        [
                            -81.8520760000689,
                            33.2472879997288
                        ],
                        [
                            -81.8465359998802,
                            33.2417459996453
                        ],
                        [
                            -81.8350360002766,
                            33.2361450002085
                        ],
                        [
                            -81.8279360001385,
                            33.2287460000822
                        ],
                        [
                            -81.8096359999725,
                            33.2226469997511
                        ],
                        [
                            -81.807936000281,
                            33.2137459997335
                        ],
                        [
                            -81.8052380001567,
                            33.211440999812
                        ],
                        [
                            -81.798235999928,
                            33.2116470001143
                        ],
                        [
                            -81.7902360001646,
                            33.2084469998644
                        ],
                        [
                            -81.7845350003174,
                            33.2081460000757
                        ],
                        [
                            -81.7775349997385,
                            33.211346999659
                        ],
                        [
                            -81.777334999722,
                            33.2149469996285
                        ],
                        [
                            -81.7810349995788,
                            33.219847000365
                        ],
                        [
                            -81.7801349999536,
                            33.2211469998985
                        ],
                        [
                            -81.7740349998982,
                            33.2211469998985
                        ],
                        [
                            -81.7689349999256,
                            33.2174469996741
                        ],
                        [
                            -81.7635350003773,
                            33.2036480000503
                        ],
                        [
                            -81.7568350002723,
                            33.197947000001
                        ],
                        [
                            -81.7595350000465,
                            33.1947470003395
                        ],
                        [
                            -81.7606349996883,
                            33.1892479998323
                        ],
                        [
                            -81.7670350002176,
                            33.1865480001575
                        ],
                        [
                            -81.7676350002672,
                            33.1835479997331
                        ],
                        [
                            -81.7649349995947,
                            33.179949000269
                        ],
                        [
                            -81.7654350000853,
                            33.1783480002025
                        ],
                        [
                            -81.7686350003499,
                            33.1784490002911
                        ],
                        [
                            -81.7717350001572,
                            33.1815479997683
                        ],
                        [
                            -81.7724349997659,
                            33.1804490003196
                        ],
                        [
                            -81.7670350002176,
                            33.1721479998134
                        ],
                        [
                            -81.7682350003168,
                            33.1679489996425
                        ],
                        [
                            -81.764234999986,
                            33.1652490001248
                        ],
                        [
                            -81.7631350003442,
                            33.1594490002819
                        ],
                        [
                            -81.7521350003328,
                            33.1485499997635
                        ],
                        [
                            -81.7463349998531,
                            33.1460499999314
                        ],
                        [
                            -81.7438350000955,
                            33.1414500001122
                        ],
                        [
                            -81.7295340004353,
                            33.1337499996893
                        ],
                        [
                            -81.7217339997902,
                            33.1264509997383
                        ],
                        [
                            -81.7116340003024,
                            33.1219510002319
                        ],
                        [
                            -81.7046339997234,
                            33.1164499999009
                        ],
                        [
                            -81.6969340004341,
                            33.1165509997958
                        ],
                        [
                            -81.679432999611,
                            33.1117510003441
                        ],
                        [
                            -81.6584329996708,
                            33.1031520003521
                        ],
                        [
                            -81.6423329996867,
                            33.0931520002146
                        ],
                        [
                            -81.6172560003211,
                            33.0956979999089
                        ],
                        [
                            -81.6116569996829,
                            33.0942589997916
                        ],
                        [
                            -81.608285999714,
                            33.0890219998906
                        ],
                        [
                            -81.6129429999959,
                            33.0839810003568
                        ],
                        [
                            -81.6120719997846,
                            33.0800190003294
                        ],
                        [
                            -81.6068360002678,
                            33.0817160000397
                        ],
                        [
                            -81.6013200003686,
                            33.0870490001429
                        ],
                        [
                            -81.596500999705,
                            33.0798930001718
                        ],
                        [
                            -81.5989910003152,
                            33.0766819999215
                        ],
                        [
                            -81.6032979998944,
                            33.0750899996752
                        ],
                        [
                            -81.6022119995979,
                            33.0734729999828
                        ],
                        [
                            -81.5960250004023,
                            33.0726929997553
                        ],
                        [
                            -81.5935859998457,
                            33.0694959999394
                        ],
                        [
                            -81.592517000166,
                            33.0730619996912
                        ],
                        [
                            -81.5873100000633,
                            33.0698510000319
                        ],
                        [
                            -81.5843609998688,
                            33.0699020002753
                        ],
                        [
                            -81.5829749999967,
                            33.0685749996717
                        ],
                        [
                            -81.5831030000432,
                            33.0649599997257
                        ],
                        [
                            -81.5771870001109,
                            33.065939000258
                        ],
                        [
                            -81.5771600003467,
                            33.0643350003314
                        ],
                        [
                            -81.5809940000979,
                            33.0626970001126
                        ],
                        [
                            -81.5749109997609,
                            33.0617990001622
                        ],
                        [
                            -81.5745049998801,
                            33.0600489999486
                        ],
                        [
                            -81.5783319999586,
                            33.0589359999352
                        ],
                        [
                            -81.5781219998963,
                            33.0577740001343
                        ],
                        [
                            -81.5717980004332,
                            33.058078999632
                        ],
                        [
                            -81.5728799996334,
                            33.0541799999395
                        ],
                        [
                            -81.566310999948,
                            33.0534300002911
                        ],
                        [
                            -81.5627690002749,
                            33.056123000032
                        ],
                        [
                            -81.5669899996404,
                            33.060401000301
                        ],
                        [
                            -81.5633719997992,
                            33.0601560003265
                        ],
                        [
                            -81.5593990001309,
                            33.0549040000973
                        ],
                        [
                            -81.5591730001751,
                            33.0517649997265
                        ],
                        [
                            -81.560343999962,
                            33.0500359997262
                        ],
                        [
                            -81.5661079995584,
                            33.0487920003759
                        ],
                        [
                            -81.5619480002923,
                            33.0458440003695
                        ],
                        [
                            -81.5517859999819,
                            33.043390000063
                        ],
                        [
                            -81.5454399998792,
                            33.048992999692
                        ],
                        [
                            -81.5422420001627,
                            33.0473639998268
                        ],
                        [
                            -81.5387890001781,
                            33.0391849999032
                        ],
                        [
                            -81.5354979996768,
                            33.0384889996327
                        ],
                        [
                            -81.5291239999851,
                            33.0408100002752
                        ],
                        [
                            -81.5284510001405,
                            33.0356460002921
                        ],
                        [
                            -81.5227419998974,
                            33.0308360002385
                        ],
                        [
                            -81.5112449997685,
                            33.0277860000876
                        ],
                        [
                            -81.5116900000075,
                            33.0245059996612
                        ],
                        [
                            -81.5190749996526,
                            33.0231959998376
                        ],
                        [
                            -81.5160729997546,
                            33.020833000078
                        ],
                        [
                            -81.5102820003941,
                            33.021233000277
                        ],
                        [
                            -81.5082200003044,
                            33.0175099997787
                        ],
                        [
                            -81.5115619999609,
                            33.0123659998573
                        ],
                        [
                            -81.5106899999248,
                            33.0103150002216
                        ],
                        [
                            -81.5082179997563,
                            33.0105269996283
                        ],
                        [
                            -81.5066679998526,
                            33.0150820000836
                        ],
                        [
                            -81.504052999569,
                            33.0162530002592
                        ],
                        [
                            -81.4986799997849,
                            33.0113360001753
                        ],
                        [
                            -81.4919539997406,
                            33.0090930002662
                        ],
                        [
                            -81.4918990003874,
                            33.006693999665
                        ],
                        [
                            -81.4987529995798,
                            33.0050649997818
                        ],
                        [
                            -81.5006299997216,
                            33.0024540001514
                        ],
                        [
                            -81.4998299996554,
                            33.0006549999135
                        ],
                        [
                            -81.4911970002305,
                            32.9978239996235
                        ],
                        [
                            -81.4922519996665,
                            32.993509999835
                        ],
                        [
                            -81.4963270003403,
                            32.9902120003254
                        ],
                        [
                            -81.4941649997932,
                            32.985704999917
                        ],
                        [
                            -81.4956729998641,
                            32.9827259999999
                        ],
                        [
                            -81.4947360004289,
                            32.9789969997289
                        ],
                        [
                            -81.497837000061,
                            32.973010999828
                        ],
                        [
                            -81.4998299996554,
                            32.9638160002656
                        ],
                        [
                            -81.5024559998097,
                            32.9627979997273
                        ],
                        [
                            -81.5061420003212,
                            32.9641289997748
                        ],
                        [
                            -81.5088389997223,
                            32.9693680000638
                        ],
                        [
                            -81.5113469998758,
                            32.9667210002434
                        ],
                        [
                            -81.5086089995685,
                            32.9525510001989
                        ],
                        [
                            -81.4994460004142,
                            32.9449880001389
                        ],
                        [
                            -81.5027160001007,
                            32.9386879998894
                        ],
                        [
                            -81.5024270003957,
                            32.9353529997095
                        ],
                        [
                            -81.4998289998305,
                            32.932614000103
                        ],
                        [
                            -81.495092000081,
                            32.9315959998451
                        ],
                        [
                            -81.4831980002921,
                            32.9218019998572
                        ],
                        [
                            -81.487626000245,
                            32.9164070002115
                        ],
                        [
                            -81.4870489997617,
                            32.9147720002735
                        ],
                        [
                            -81.4800080000731,
                            32.9134439998975
                        ],
                        [
                            -81.481444000174,
                            32.9088949996996
                        ],
                        [
                            -81.4791800004179,
                            32.9042029996632
                        ],
                        [
                            -81.4808120003373,
                            32.8986139997091
                        ],
                        [
                            -81.4717890000267,
                            32.8938069996283
                        ],
                        [
                            -81.4707610003549,
                            32.9009020001676
                        ],
                        [
                            -81.4677000000074,
                            32.9008999999345
                        ],
                        [
                            -81.4644329997956,
                            32.8986029996512
                        ],
                        [
                            -81.4646549995535,
                            32.8959989999505
                        ],
                        [
                            -81.468232000285,
                            32.8932510001986
                        ],
                        [
                            -81.4661900002868,
                            32.8891280000302
                        ],
                        [
                            -81.471702999813,
                            32.8904389997783
                        ],
                        [
                            -81.4763960000798,
                            32.8879620002219
                        ],
                        [
                            -81.4794449998334,
                            32.8810820000997
                        ],
                        [
                            -81.475918000229,
                            32.8776400000424
                        ],
                        [
                            -81.4703760003904,
                            32.8762670000055
                        ],
                        [
                            -81.4680420003142,
                            32.876675000125
                        ],
                        [
                            -81.4671379995926,
                            32.8806019999314
                        ],
                        [
                            -81.4650510002869,
                            32.8808959999847
                        ],
                        [
                            -81.4605560003868,
                            32.8751610002813
                        ],
                        [
                            -81.4528829999633,
                            32.8729639998681
                        ],
                        [
                            -81.4513670003948,
                            32.8671649999653
                        ],
                        [
                            -81.4533609998142,
                            32.8638469998135
                        ],
                        [
                            -81.4530169998576,
                            32.8596359999565
                        ],
                        [
                            -81.456916999731,
                            32.8526039996845
                        ],
                        [
                            -81.4555590003463,
                            32.8465080000009
                        ],
                        [
                            -81.4602109997069,
                            32.8474020001113
                        ],
                        [
                            -81.4607220000681,
                            32.8460379996521
                        ],
                        [
                            -81.4527440000461,
                            32.8441499997642
                        ],
                        [
                            -81.4511990001653,
                            32.8479240002102
                        ],
                        [
                            -81.4448659995832,
                            32.8509659996835
                        ],
                        [
                            -81.4426710003224,
                            32.8501070000063
                        ],
                        [
                            -81.4428820002096,
                            32.845847000074
                        ],
                        [
                            -81.438651999725,
                            32.8400760000204
                        ],
                        [
                            -81.4335950003084,
                            32.8436739997131
                        ],
                        [
                            -81.4264750000789,
                            32.8407729998518
                        ],
                        [
                            -81.4216139995825,
                            32.8351779999851
                        ],
                        [
                            -81.4206200002459,
                            32.8312230001757
                        ],
                        [
                            -81.4236740000224,
                            32.828306999863
                        ],
                        [
                            -81.4299430001321,
                            32.8282460001454
                        ],
                        [
                            -81.4219519996913,
                            32.8214659997481
                        ],
                        [
                            -81.4230410003608,
                            32.8197469998999
                        ],
                        [
                            -81.4282350000448,
                            32.8185839999939
                        ],
                        [
                            -81.4243540004379,
                            32.816178999695
                        ],
                        [
                            -81.4205850000858,
                            32.8186509997793
                        ],
                        [
                            -81.4179840000459,
                            32.8181959998152
                        ],
                        [
                            -81.4213430003192,
                            32.8121079996621
                        ],
                        [
                            -81.4264449999416,
                            32.8107099998127
                        ],
                        [
                            -81.4224439997859,
                            32.8024770002129
                        ],
                        [
                            -81.4282919999462,
                            32.8008320000272
                        ],
                        [
                            -81.4252339999717,
                            32.7941899997306
                        ],
                        [
                            -81.419878999731,
                            32.7948570000874
                        ],
                        [
                            -81.4200109999754,
                            32.79288500006
                        ],
                        [
                            -81.4276669997822,
                            32.7876130002185
                        ],
                        [
                            -81.4220680000423,
                            32.785563000198
                        ],
                        [
                            -81.4222399995714,
                            32.7839859999334
                        ],
                        [
                            -81.4256359996546,
                            32.7826040002153
                        ],
                        [
                            -81.429976999569,
                            32.7852350000396
                        ],
                        [
                            -81.4312350002929,
                            32.7841410001432
                        ],
                        [
                            -81.4300779998513,
                            32.7817709996378
                        ],
                        [
                            -81.4248309995656,
                            32.7787029999992
                        ],
                        [
                            -81.4161680000035,
                            32.7797919996909
                        ],
                        [
                            -81.4144559997181,
                            32.7781489997835
                        ],
                        [
                            -81.4173530000341,
                            32.7733359999547
                        ],
                        [
                            -81.4298540004437,
                            32.7721289998963
                        ],
                        [
                            -81.4315130001272,
                            32.771483999737
                        ],
                        [
                            -81.4319399999245,
                            32.7689109998449
                        ],
                        [
                            -81.4189430001207,
                            32.7676289996866
                        ],
                        [
                            -81.4168340001753,
                            32.7653180001588
                        ],
                        [
                            -81.4189010002879,
                            32.7611320000993
                        ],
                        [
                            -81.4177659995877,
                            32.7602219998922
                        ],
                        [
                            -81.4146280001456,
                            32.7641840003182
                        ],
                        [
                            -81.4120930002279,
                            32.763865999908
                        ],
                        [
                            -81.4140380001417,
                            32.755423000361
                        ],
                        [
                            -81.4081590000193,
                            32.7558029998006
                        ],
                        [
                            -81.411567999623,
                            32.7531169996485
                        ],
                        [
                            -81.416732999893,
                            32.7532760003026
                        ],
                        [
                            -81.419697999981,
                            32.7491670001753
                        ],
                        [
                            -81.4178710000679,
                            32.7478259999781
                        ],
                        [
                            -81.4058659999509,
                            32.7491439997168
                        ],
                        [
                            -81.4049319999905,
                            32.7455590000717
                        ],
                        [
                            -81.4070429995856,
                            32.7406619998938
                        ],
                        [
                            -81.4136780002916,
                            32.7379919998398
                        ],
                        [
                            -81.4120990000757,
                            32.7326220001283
                        ],
                        [
                            -81.4185420002627,
                            32.7325849997122
                        ],
                        [
                            -81.4167520001596,
                            32.7287620003
                        ],
                        [
                            -81.4177689999607,
                            32.7250740000249
                        ],
                        [
                            -81.4105769997611,
                            32.7233169999461
                        ],
                        [
                            -81.4099879995822,
                            32.7218630000367
                        ],
                        [
                            -81.4203380002136,
                            32.7210509999422
                        ],
                        [
                            -81.4151200002402,
                            32.7136689998091
                        ],
                        [
                            -81.4211940003563,
                            32.7119780002853
                        ],
                        [
                            -81.4224379999381,
                            32.7096069998456
                        ],
                        [
                            -81.4259269999078,
                            32.7100370003537
                        ],
                        [
                            -81.4240790000783,
                            32.7062939997541
                        ],
                        [
                            -81.4275169999944,
                            32.7018950001655
                        ],
                        [
                            -81.4214189995888,
                            32.7004910003031
                        ],
                        [
                            -81.4178099999686,
                            32.6955690001903
                        ],
                        [
                            -81.4135770000093,
                            32.6954719996486
                        ],
                        [
                            -81.4124029998493,
                            32.6920950002862
                        ],
                        [
                            -81.4105449999741,
                            32.6925120002392
                        ],
                        [
                            -81.410874999687,
                            32.6968599997708
                        ],
                        [
                            -81.4090119997889,
                            32.6965510000977
                        ],
                        [
                            -81.4056840003761,
                            32.6898879999371
                        ],
                        [
                            -81.4070099999737,
                            32.685550000014
                        ],
                        [
                            -81.4012110002172,
                            32.6798820002499
                        ],
                        [
                            -81.404286999735,
                            32.6677969997263
                        ],
                        [
                            -81.397153999985,
                            32.6613779996431
                        ],
                        [
                            -81.4003419996558,
                            32.6584879996538
                        ],
                        [
                            -81.4032540000403,
                            32.6594640000508
                        ],
                        [
                            -81.4053689998335,
                            32.662919000076
                        ],
                        [
                            -81.4071930002718,
                            32.6605190002726
                        ],
                        [
                            -81.405272999574,
                            32.6565170001479
                        ],
                        [
                            -81.3994319999848,
                            32.6568129997625
                        ],
                        [
                            -81.3938180001762,
                            32.6534909999192
                        ],
                        [
                            -81.3930330001787,
                            32.6515419999318
                        ],
                        [
                            -81.4035820001034,
                            32.6433970003622
                        ],
                        [
                            -81.4125529996371,
                            32.6415229997317
                        ],
                        [
                            -81.4118270000891,
                            32.6393829997294
                        ],
                        [
                            -81.4027350001816,
                            32.6370309996454
                        ],
                        [
                            -81.4072710000896,
                            32.6317369999012
                        ],
                        [
                            -81.4102599995687,
                            32.6313919997925
                        ],
                        [
                            -81.4128840000732,
                            32.6366799998985
                        ],
                        [
                            -81.414761000215,
                            32.6374389996699
                        ],
                        [
                            -81.4184309999346,
                            32.6347039996377
                        ],
                        [
                            -81.4186600002635,
                            32.6293920003122
                        ],
                        [
                            -81.4130869995645,
                            32.6248030001088
                        ],
                        [
                            -81.4119059997318,
                            32.6184089998332
                        ],
                        [
                            -81.4075170001369,
                            32.6165829996735
                        ],
                        [
                            -81.4064640003507,
                            32.6130419997642
                        ],
                        [
                            -81.3937649995744,
                            32.6023309999096
                        ],
                        [
                            -81.3892050002753,
                            32.5954159999788
                        ],
                        [
                            -81.3788830001313,
                            32.5889119996899
                        ],
                        [
                            -81.3760059999068,
                            32.5892560001633
                        ],
                        [
                            -81.3731449995759,
                            32.5920940002368
                        ],
                        [
                            -81.3692699998168,
                            32.5907049999979
                        ],
                        [
                            -81.3698189998128,
                            32.5850479999547
                        ],
                        [
                            -81.3635859996881,
                            32.5829769999051
                        ],
                        [
                            -81.3672479999101,
                            32.5783609999906
                        ],
                        [
                            -81.3667819997548,
                            32.5769149996284
                        ],
                        [
                            -81.3634939996266,
                            32.5769079998081
                        ],
                        [
                            -81.3601679998635,
                            32.5817780000796
                        ],
                        [
                            -81.3580310003291,
                            32.5822109999926
                        ],
                        [
                            -81.353492000048,
                            32.5787700001126
                        ],
                        [
                            -81.3583140001863,
                            32.5744510003706
                        ],
                        [
                            -81.3559259996834,
                            32.5716060001924
                        ],
                        [
                            -81.3286049997549,
                            32.5610920002033
                        ],
                        [
                            -81.3196020004342,
                            32.5595620003786
                        ],
                        [
                            -81.2976519997417,
                            32.562931000318
                        ],
                        [
                            -81.2953019997719,
                            32.5608059998027
                        ],
                        [
                            -81.2980719998662,
                            32.5553429997852
                        ],
                        [
                            -81.2961649995872,
                            32.5533449996879
                        ],
                        [
                            -81.2914799997163,
                            32.5557139999561
                        ],
                        [
                            -81.2913120003851,
                            32.5583649998029
                        ],
                        [
                            -81.2934559995923,
                            32.5615840003157
                        ],
                        [
                            -81.2909609998575,
                            32.5626629998506
                        ],
                        [
                            -81.2884669999477,
                            32.5584560003367
                        ],
                        [
                            -81.2899619995997,
                            32.5521799999118
                        ],
                        [
                            -81.2886269997813,
                            32.5511130001351
                        ],
                        [
                            -81.2851479998573,
                            32.5523450001266
                        ],
                        [
                            -81.2838580002447,
                            32.5565380001393
                        ],
                        [
                            -81.2805800001623,
                            32.55603000009
                        ],
                        [
                            -81.2787359996325,
                            32.5524460001497
                        ],
                        [
                            -81.2808739998901,
                            32.5478999998822
                        ],
                        [
                            -81.274800999599,
                            32.5444159998075
                        ],
                        [
                            -81.2752390001652,
                            32.5398130001969
                        ],
                        [
                            -81.2799220003863,
                            32.5376359996473
                        ],
                        [
                            -81.2796550004227,
                            32.5363379999006
                        ],
                        [
                            -81.2746459997883,
                            32.5341879999847
                        ],
                        [
                            -81.2719230004479,
                            32.529984000339
                        ],
                        [
                            -81.2520630003325,
                            32.5181360003279
                        ],
                        [
                            -81.2474549995562,
                            32.5182809997962
                        ],
                        [
                            -81.2439370001725,
                            32.5209039996621
                        ],
                        [
                            -81.2408649999544,
                            32.5164810003762
                        ],
                        [
                            -81.2337940001286,
                            32.5183099998132
                        ],
                        [
                            -81.2323220000428,
                            32.5120820001323
                        ],
                        [
                            -81.2371590002497,
                            32.5112959998605
                        ],
                        [
                            -81.2388210003063,
                            32.5080519998938
                        ],
                        [
                            -81.2377950002844,
                            32.5052559998195
                        ],
                        [
                            -81.2342540004362,
                            32.5027469998274
                        ],
                        [
                            -81.2331110002383,
                            32.4980690000509
                        ],
                        [
                            -81.2004080003049,
                            32.4683139999976
                        ],
                        [
                            -81.1947700002069,
                            32.4651619999239
                        ],
                        [
                            -81.1871410001644,
                            32.4641489997358
                        ],
                        [
                            -81.1886020003795,
                            32.4612260000043
                        ],
                        [
                            -81.1944120000066,
                            32.4589089998118
                        ],
                        [
                            -81.1920129996331,
                            32.453786000316
                        ],
                        [
                            -81.1976690001727,
                            32.4546080000501
                        ],
                        [
                            -81.1978680003643,
                            32.4485549999741
                        ],
                        [
                            -81.2032860003543,
                            32.4529929997544
                        ],
                        [
                            -81.2057740004163,
                            32.4504740001074
                        ],
                        [
                            -81.2063180003895,
                            32.4470070000225
                        ],
                        [
                            -81.2007549997362,
                            32.4460860001152
                        ],
                        [
                            -81.1992919998712,
                            32.4429140003115
                        ],
                        [
                            -81.2005849998569,
                            32.4406889999511
                        ],
                        [
                            -81.2083889998017,
                            32.4372499998485
                        ],
                        [
                            -81.2051140000923,
                            32.4272249996893
                        ],
                        [
                            -81.2054050003454,
                            32.4233390002681
                        ],
                        [
                            -81.1782829997102,
                            32.3929860003586
                        ],
                        [
                            -81.177783999943,
                            32.3862310003466
                        ],
                        [
                            -81.1818450003731,
                            32.3793480002144
                        ],
                        [
                            -81.1767269999588,
                            32.3767189997468
                        ],
                        [
                            -81.1679139997105,
                            32.3684819996713
                        ],
                        [
                            -81.168995999809,
                            32.3651189999848
                        ],
                        [
                            -81.1739629997123,
                            32.3629169998089
                        ],
                        [
                            -81.1667570001673,
                            32.3616030001181
                        ],
                        [
                            -81.167569999754,
                            32.3587469998051
                        ],
                        [
                            -81.1609790003273,
                            32.3552440001325
                        ],
                        [
                            -81.160801000052,
                            32.3499610002288
                        ],
                        [
                            -81.1549629999375,
                            32.3502469998997
                        ],
                        [
                            -81.1552969998484,
                            32.3439020000924
                        ],
                        [
                            -81.1599840002674,
                            32.3428520000892
                        ],
                        [
                            -81.1599950001381,
                            32.3404760000919
                        ],
                        [
                            -81.152819999657,
                            32.3413210002688
                        ],
                        [
                            -81.1495469995974,
                            32.3450790001119
                        ],
                        [
                            -81.1453270000568,
                            32.3451600003129
                        ],
                        [
                            -81.1479389999674,
                            32.348912999672
                        ],
                        [
                            -81.1438569996208,
                            32.3515430000618
                        ],
                        [
                            -81.1413660000841,
                            32.3501470001017
                        ],
                        [
                            -81.1392959995985,
                            32.3450249996846
                        ],
                        [
                            -81.1334819997734,
                            32.340977999697
                        ],
                        [
                            -81.1296150004103,
                            32.3417039998843
                        ],
                        [
                            -81.1285349999616,
                            32.3383809996944
                        ],
                        [
                            -81.1289559999111,
                            32.3364520002278
                        ],
                        [
                            -81.132803999906,
                            32.3347460003389
                        ],
                        [
                            -81.1380129996585,
                            32.3282400001449
                        ],
                        [
                            -81.1355460004112,
                            32.3243229999105
                        ],
                        [
                            -81.1307350001436,
                            32.3257560003563
                        ],
                        [
                            -81.1316270002712,
                            32.3186440002987
                        ],
                        [
                            -81.1297769998937,
                            32.3132899996631
                        ],
                        [
                            -81.1330159996181,
                            32.3085459997113
                        ],
                        [
                            -81.128563000449,
                            32.306872999645
                        ],
                        [
                            -81.1252340003129,
                            32.309075000168
                        ],
                        [
                            -81.1226309997249,
                            32.3069899998573
                        ],
                        [
                            -81.1239170000378,
                            32.2951939998012
                        ],
                        [
                            -81.1204559996573,
                            32.2908750003026
                        ],
                        [
                            -81.119628000002,
                            32.2867780000315
                        ],
                        [
                            -81.1271409996936,
                            32.2822549999507
                        ],
                        [
                            -81.1264629998262,
                            32.2792489996219
                        ],
                        [
                            -81.1232759999803,
                            32.2769750003691
                        ],
                        [
                            -81.1235050003092,
                            32.2751430001428
                        ],
                        [
                            -81.1294789999678,
                            32.2762179998717
                        ],
                        [
                            -81.1331789998247,
                            32.2696780001984
                        ],
                        [
                            -81.1360329995846,
                            32.2726629996604
                        ],
                        [
                            -81.1421080004239,
                            32.2715760002175
                        ],
                        [
                            -81.137924999795,
                            32.2688910002838
                        ],
                        [
                            -81.1369299997352,
                            32.2661149996582
                        ],
                        [
                            -81.1393669997436,
                            32.2636999996224
                        ],
                        [
                            -81.1463170000938,
                            32.2633230001037
                        ],
                        [
                            -81.1447389997028,
                            32.2580599999731
                        ],
                        [
                            -81.1481239999154,
                            32.2550910000908
                        ],
                        [
                            -81.1459050003651,
                            32.2507240001271
                        ],
                        [
                            -81.1544649999951,
                            32.2469210003612
                        ],
                        [
                            -81.1566069995524,
                            32.2432839999422
                        ],
                        [
                            -81.1528830003045,
                            32.2367869996395
                        ],
                        [
                            -81.1457709995725,
                            32.2323430003147
                        ],
                        [
                            -81.1436340000381,
                            32.2292659996513
                        ],
                        [
                            -81.1463489998808,
                            32.224555000049
                        ],
                        [
                            -81.1520639999717,
                            32.2220420001261
                        ],
                        [
                            -81.1431749995555,
                            32.2217389996773
                        ],
                        [
                            -81.1389249998777,
                            32.2197679996758
                        ],
                        [
                            -81.1360119996682,
                            32.212853999975
                        ],
                        [
                            -81.1274200002512,
                            32.2075540001776
                        ],
                        [
                            -81.1231489997587,
                            32.2013200000096
                        ],
                        [
                            -81.1218850000854,
                            32.1955379997168
                        ],
                        [
                            -81.1140319997368,
                            32.1957010000727
                        ],
                        [
                            -81.1148209999323,
                            32.1934229998783
                        ],
                        [
                            -81.1217949996737,
                            32.1917939998398
                        ],
                        [
                            -81.1211579998141,
                            32.1900799996512
                        ],
                        [
                            -81.118237000107,
                            32.1892029996458
                        ],
                        [
                            -81.1179349999832,
                            32.1852970000016
                        ],
                        [
                            -81.1204389999388,
                            32.1786999999325
                        ],
                        [
                            -81.1169330002507,
                            32.1761979998104
                        ],
                        [
                            -81.1119329998372,
                            32.1780020003571
                        ],
                        [
                            -81.1102339999707,
                            32.176800000315
                        ],
                        [
                            -81.1111319999461,
                            32.1727029998229
                        ],
                        [
                            -81.1169459997712,
                            32.1709140002415
                        ],
                        [
                            -81.1194370002063,
                            32.1753619997162
                        ],
                        [
                            -81.1211489995933,
                            32.1749020003349
                        ],
                        [
                            -81.1281419996012,
                            32.169097000063
                        ],
                        [
                            -81.1297559999773,
                            32.1658389998925
                        ],
                        [
                            -81.1220299998503,
                            32.1617959999546
                        ],
                        [
                            -81.1221369999804,
                            32.1573039999544
                        ],
                        [
                            -81.1200339998829,
                            32.1533030000362
                        ],
                        [
                            -81.1122359997859,
                            32.1508040001981
                        ],
                        [
                            -81.1122340001361,
                            32.1493060000229
                        ],
                        [
                            -81.1183309998184,
                            32.144408000247
                        ],
                        [
                            -81.1199950004231,
                            32.1342649998018
                        ],
                        [
                            -81.1152469999047,
                            32.1255039997465
                        ],
                        [
                            -81.1172250003288,
                            32.1176040000866
                        ],
                        [
                            -81.1133429999987,
                            32.1132000001102
                        ],
                        [
                            -81.0915749997794,
                            32.1109090000262
                        ],
                        [
                            -81.0905239996431,
                            32.1063779999071
                        ],
                        [
                            -81.0882060003587,
                            32.1039430001275
                        ],
                        [
                            -81.0754430000083,
                            32.0970679997562
                        ],
                        [
                            -81.0669650003941,
                            32.0903839997379
                        ],
                        [
                            -81.0503170002389,
                            32.0853239998983
                        ],
                        [
                            -81.032633000016,
                            32.0854760000152
                        ],
                        [
                            -81.0216840000582,
                            32.0908639999452
                        ],
                        [
                            -81.0119700003597,
                            32.1001779997659
                        ],
                        [
                            -81.0067599998839,
                            32.1011569997307
                        ],
                        [
                            -81.0023179996872,
                            32.1000559999576
                        ],
                        [
                            -80.9944700002599,
                            32.0947239996433
                        ],
                        [
                            -80.9831920004141,
                            32.0796590003254
                        ],
                        [
                            -80.9544830000654,
                            32.0686160000137
                        ],
                        [
                            -80.9267689997765,
                            32.0416629996252
                        ],
                        [
                            -80.9180030002822,
                            32.0375789999264
                        ],
                        [
                            -80.8855299996043,
                            32.0345960003173
                        ],
                        [
                            -80.7514289999175,
                            32.0334679997907
                        ],
                        [
                            -80.7580339995879,
                            32.0259819999681
                        ],
                        [
                            -80.7697680004416,
                            32.0197930001568
                        ],
                        [
                            -80.78069300011,
                            32.0113700001467
                        ],
                        [
                            -80.782997000049,
                            31.9956669999975
                        ],
                        [
                            -80.7862399999714,
                            31.9857519998888
                        ],
                        [
                            -80.7866819998372,
                            31.9756130002802
                        ],
                        [
                            -80.789841000094,
                            31.9643899997929
                        ],
                        [
                            -80.7962719996871,
                            31.9534589997538
                        ],
                        [
                            -80.8040539998906,
                            31.9461310001168
                        ],
                        [
                            -80.8146060001884,
                            31.9399990000606
                        ],
                        [
                            -80.8192750001658,
                            31.933854999874
                        ],
                        [
                            -80.8292240000409,
                            31.9265840002415
                        ],
                        [
                            -80.8553390000223,
                            31.9108090002869
                        ],
                        [
                            -80.8748130003484,
                            31.8807040002901
                        ],
                        [
                            -80.8887790002727,
                            31.8657820003761
                        ],
                        [
                            -80.8969880002734,
                            31.8528060002491
                        ],
                        [
                            -80.9117049998601,
                            31.8406490001867
                        ],
                        [
                            -80.9168170004267,
                            31.8383770001118
                        ],
                        [
                            -80.9221340001343,
                            31.8252790002696
                        ],
                        [
                            -80.9285890000169,
                            31.8169819996656
                        ],
                        [
                            -80.9383160001341,
                            31.8090080001638
                        ],
                        [
                            -80.9496309997899,
                            31.8030489999674
                        ],
                        [
                            -80.9707260001646,
                            31.7983529996809
                        ],
                        [
                            -80.9730989997006,
                            31.7882230000801
                        ],
                        [
                            -80.9769519997183,
                            31.7808759996612
                        ],
                        [
                            -80.9830020004433,
                            31.7726820000139
                        ],
                        [
                            -80.9961699999513,
                            31.762132999736
                        ],
                        [
                            -80.9972000001712,
                            31.7485029997223
                        ],
                        [
                            -81.0138120003414,
                            31.7286430002474
                        ],
                        [
                            -81.0258240001311,
                            31.7202270000481
                        ],
                        [
                            -81.0566230001586,
                            31.7148750002405
                        ],
                        [
                            -81.0515209996378,
                            31.7051980000601
                        ],
                        [
                            -81.0497890001593,
                            31.6886330000446
                        ],
                        [
                            -81.0590059997403,
                            31.6660079998228
                        ],
                        [
                            -81.0666349997829,
                            31.6573279999348
                        ],
                        [
                            -81.0696330003812,
                            31.6424049996219
                        ],
                        [
                            -81.0658260003942,
                            31.6252269996461
                        ],
                        [
                            -81.0766830002905,
                            31.5979459997584
                        ],
                        [
                            -81.0831830003789,
                            31.5649089999982
                        ],
                        [
                            -81.0870670003588,
                            31.5291580003819
                        ],
                        [
                            -81.0923609996019,
                            31.5231699996346
                        ],
                        [
                            -81.0996859998708,
                            31.5185259997854
                        ],
                        [
                            -81.1071400000112,
                            31.49663200036
                        ],
                        [
                            -81.1149980003826,
                            31.4870110001142
                        ],
                        [
                            -81.1254350001544,
                            31.4775410001215
                        ],
                        [
                            -81.1335219999563,
                            31.4737399997977
                        ],
                        [
                            -81.1438449999253,
                            31.4715210001452
                        ],
                        [
                            -81.1501459998221,
                            31.4580399997009
                        ],
                        [
                            -81.1635510000549,
                            31.4420340002614
                        ],
                        [
                            -81.162444999667,
                            31.4370270000748
                        ],
                        [
                            -81.1669389997422,
                            31.4234449997158
                        ],
                        [
                            -81.1821900001546,
                            31.4053229999992
                        ],
                        [
                            -81.1907580001805,
                            31.3998759996896
                        ],
                        [
                            -81.1756130000733,
                            31.3824789999309
                        ],
                        [
                            -81.1710889998609,
                            31.3703229997094
                        ],
                        [
                            -81.1719819998134,
                            31.3552990002009
                        ],
                        [
                            -81.1771160001213,
                            31.3392770002796
                        ],
                        [
                            -81.1861190003404,
                            31.3267960001731
                        ],
                        [
                            -81.1833500000709,
                            31.311235000132
                        ],
                        [
                            -81.1846849998894,
                            31.3011209998032
                        ],
                        [
                            -81.1825640002484,
                            31.2689909996624
                        ],
                        [
                            -81.1876750000917,
                            31.2462579996217
                        ],
                        [
                            -81.2024519999529,
                            31.2276780002779
                        ],
                        [
                            -81.2091369999893,
                            31.2131269997855
                        ],
                        [
                            -81.2162630000666,
                            31.2046510001817
                        ],
                        [
                            -81.2221599997324,
                            31.187968999842
                        ],
                        [
                            -81.2343370002768,
                            31.1702580002573
                        ],
                        [
                            -81.2479099998408,
                            31.1608119997539
                        ],
                        [
                            -81.2669680002402,
                            31.1570320002452
                        ],
                        [
                            -81.2921450000632,
                            31.1554149996409
                        ],
                        [
                            -81.2964170003806,
                            31.151879999777
                        ],
                        [
                            -81.2927250000214,
                            31.1428260002967
                        ],
                        [
                            -81.2940099996111,
                            31.1356660002
                        ],
                        [
                            -81.2898710002614,
                            31.1311570001793
                        ],
                        [
                            -81.2865300004298,
                            31.1166860003658
                        ],
                        [
                            -81.2863160001695,
                            31.1030830001343
                        ],
                        [
                            -81.2901990003245,
                            31.093493999788
                        ],
                        [
                            -81.2978740003978,
                            31.0809519997656
                        ],
                        [
                            -81.313637000098,
                            31.0678520003828
                        ],
                        [
                            -81.3261719999444,
                            31.0626599997987
                        ],
                        [
                            -81.3453059996135,
                            31.0593620001021
                        ],
                        [
                            -81.35341599988,
                            31.0371210000536
                        ],
                        [
                            -81.3444980000498,
                            31.0181099997899
                        ],
                        [
                            -81.337944000433,
                            31.0163149999148
                        ],
                        [
                            -81.3239440001734,
                            31.0088100003775
                        ],
                        [
                            -81.3107089998199,
                            30.9973289996304
                        ],
                        [
                            -81.3047789996439,
                            30.9857120001041
                        ],
                        [
                            -81.3048320002457,
                            30.9707739998603
                        ],
                        [
                            -81.3073040004142,
                            30.9622270000666
                        ],
                        [
                            -81.3175350003216,
                            30.9485000000594
                        ],
                        [
                            -81.3332290004248,
                            30.9365860003029
                        ],
                        [
                            -81.3452069998793,
                            30.9315030000708
                        ],
                        [
                            -81.3502039999198,
                            30.8891089998648
                        ],
                        [
                            -81.3588559996113,
                            30.8675120000572
                        ],
                        [
                            -81.3633420001889,
                            30.8505569996303
                        ],
                        [
                            -81.3626819998648,
                            30.8459260001677
                        ],
                        [
                            -81.3748090001806,
                            30.8173769997666
                        ],
                        [
                            -81.3863299997006,
                            30.8013710003063
                        ],
                        [
                            -81.4004820002962,
                            30.7664260003031
                        ],
                        [
                            -81.3913040001749,
                            30.7646660002961
                        ],
                        [
                            -81.3748090001806,
                            30.7574829998818
                        ],
                        [
                            -81.3591460000395,
                            30.7443140002903
                        ],
                        [
                            -81.3513949997982,
                            30.7308789997918
                        ],
                        [
                            -81.3502499999506,
                            30.7214030002022
                        ],
                        [
                            -81.3470150004241,
                            30.7124439999292
                        ],
                        [
                            -81.404174999582,
                            30.7124389999371
                        ],
                        [
                            -81.403701999754,
                            30.7098139999572
                        ],
                        [
                            -81.4512409999981,
                            30.7094840001921
                        ],
                        [
                            -81.4741130000572,
                            30.7139130001327
                        ],
                        [
                            -81.4848250001886,
                            30.7251129998539
                        ],
                        [
                            -81.4884639999461,
                            30.7263090003412
                        ],
                        [
                            -81.5098870003839,
                            30.7226140003214
                        ],
                        [
                            -81.5322949999374,
                            30.7240910003031
                        ],
                        [
                            -81.5344919997463,
                            30.7200740001842
                        ],
                        [
                            -81.5340110004207,
                            30.7114469999284
                        ],
                        [
                            -81.5383909997948,
                            30.7086559998977
                        ],
                        [
                            -81.5428310003417,
                            30.7111340000854
                        ],
                        [
                            -81.5460199998374,
                            30.7169420002713
                        ],
                        [
                            -81.5483770003782,
                            30.7182790001773
                        ],
                        [
                            -81.5614240004107,
                            30.7116720000861
                        ],
                        [
                            -81.563461000386,
                            30.7134230001864
                        ],
                        [
                            -81.5635070004168,
                            30.7175100002342
                        ],
                        [
                            -81.5742179998249,
                            30.7225039996799
                        ],
                        [
                            -81.5876840001571,
                            30.7236350000486
                        ],
                        [
                            -81.592657999733,
                            30.7189079998362
                        ],
                        [
                            -81.596176000015,
                            30.7186659996145
                        ],
                        [
                            -81.5973729997412,
                            30.7202259996875
                        ],
                        [
                            -81.5965340002153,
                            30.7274719999943
                        ],
                        [
                            -81.601881999885,
                            30.7295859997904
                        ],
                        [
                            -81.605833999637,
                            30.727263999624
                        ],
                        [
                            -81.6060479998972,
                            30.7186759997251
                        ],
                        [
                            -81.6098699999528,
                            30.7159329998042
                        ],
                        [
                            -81.6119300003927,
                            30.7172009996306
                        ],
                        [
                            -81.6141659996613,
                            30.7223529999567
                        ],
                        [
                            -81.6229780000847,
                            30.7234669998988
                        ],
                        [
                            -81.6234340001943,
                            30.7255549998321
                        ],
                        [
                            -81.6206809998184,
                            30.7309589997786
                        ],
                        [
                            -81.6243659996066,
                            30.7363400000544
                        ],
                        [
                            -81.6283309997773,
                            30.7353940003677
                        ],
                        [
                            -81.6288289997197,
                            30.7293070001682
                        ],
                        [
                            -81.6304320002251,
                            30.7280880000322
                        ],
                        [
                            -81.6326819997374,
                            30.7285960000951
                        ],
                        [
                            -81.6366190003191,
                            30.7339379999237
                        ],
                        [
                            -81.6435849996645,
                            30.7282029996546
                        ],
                        [
                            -81.6485359996742,
                            30.7274230001294
                        ],
                        [
                            -81.6521529996904,
                            30.7288700003187
                        ],
                        [
                            -81.6540900001066,
                            30.7330929998569
                        ],
                        [
                            -81.6518280000004,
                            30.7422080003812
                        ],
                        [
                            -81.6543040003669,
                            30.7443599998222
                        ],
                        [
                            -81.6608350004174,
                            30.7423399997834
                        ],
                        [
                            -81.6630089997618,
                            30.743492000085
                        ],
                        [
                            -81.6634819995898,
                            30.7458530000589
                        ],
                        [
                            -81.6597290000295,
                            30.7497160002197
                        ],
                        [
                            -81.6623230003968,
                            30.7541770001288
                        ],
                        [
                            -81.669554999881,
                            30.7517009996224
                        ],
                        [
                            -81.6790689995629,
                            30.7509060002309
                        ],
                        [
                            -81.6830959996579,
                            30.7484919999775
                        ],
                        [
                            -81.6827769998157,
                            30.7459680000982
                        ],
                        [
                            -81.6799079999871,
                            30.7446920002901
                        ],
                        [
                            -81.6734840000667,
                            30.7485500002581
                        ],
                        [
                            -81.670836999996,
                            30.7473959998763
                        ],
                        [
                            -81.6688379996555,
                            30.7425140000527
                        ],
                        [
                            -81.6724940000297,
                            30.7387900001693
                        ],
                        [
                            -81.6794200000905,
                            30.7415200001943
                        ],
                        [
                            -81.6891549997054,
                            30.7416319996797
                        ],
                        [
                            -81.6938930001781,
                            30.7484719997662
                        ],
                        [
                            -81.7020870001102,
                            30.7454700001597
                        ],
                        [
                            -81.709113999555,
                            30.7479759999902
                        ],
                        [
                            -81.7201300003582,
                            30.7447190000127
                        ],
                        [
                            -81.7320550001093,
                            30.7498150001781
                        ],
                        [
                            -81.7332150000256,
                            30.7512760002932
                        ],
                        [
                            -81.7318569997426,
                            30.7549949998464
                        ],
                        [
                            -81.7421949997802,
                            30.7592540001935
                        ],
                        [
                            -81.7407529998316,
                            30.7650069997816
                        ],
                        [
                            -81.7559430001446,
                            30.769344000084
                        ],
                        [
                            -81.7617200001598,
                            30.7756980002997
                        ],
                        [
                            -81.7707059997621,
                            30.7768199997733
                        ],
                        [
                            -81.7702639998963,
                            30.7736700001127
                        ],
                        [
                            -81.7735899996593,
                            30.7677079998116
                        ],
                        [
                            -81.7715679997526,
                            30.7641430001279
                        ],
                        [
                            -81.7826299996883,
                            30.7614539999942
                        ],
                        [
                            -81.7827600002829,
                            30.7666130003614
                        ],
                        [
                            -81.7866969999663,
                            30.7698019999971
                        ],
                        [
                            -81.7837899996046,
                            30.7733330000774
                        ],
                        [
                            -81.7913509998751,
                            30.7828040000251
                        ],
                        [
                            -81.793074999856,
                            30.7872510000775
                        ],
                        [
                            -81.7971260002404,
                            30.7850299999438
                        ],
                        [
                            -81.795967000149,
                            30.7821290000533
                        ],
                        [
                            -81.7971720002711,
                            30.7814690000112
                        ],
                        [
                            -81.8018870002793,
                            30.7868750000304
                        ],
                        [
                            -81.8074490002093,
                            30.7901189997673
                        ],
                        [
                            -81.8194879997632,
                            30.7898859997327
                        ],
                        [
                            -81.8237990004387,
                            30.7868119997001
                        ],
                        [
                            -81.8300090000989,
                            30.789359999685
                        ],
                        [
                            -81.8394769997501,
                            30.7865890001471
                        ],
                        [
                            -81.8418800003216,
                            30.7875690003122
                        ],
                        [
                            -81.8402709999684,
                            30.7925910000413
                        ],
                        [
                            -81.8458710004316,
                            30.7907499997219
                        ],
                        [
                            -81.8526910001872,
                            30.7948969996143
                        ],
                        [
                            -81.8685149999867,
                            30.7929060000968
                        ],
                        [
                            -81.8711389995929,
                            30.7980399998351
                        ],
                        [
                            -81.8826669996839,
                            30.8002819998691
                        ],
                        [
                            -81.8821410001525,
                            30.8048339996385
                        ],
                        [
                            -81.8845289997571,
                            30.8075979999349
                        ],
                        [
                            -81.8847189997279,
                            30.8114219997342
                        ],
                        [
                            -81.8914339999015,
                            30.8165860000991
                        ],
                        [
                            -81.8919750003999,
                            30.8248819999382
                        ],
                        [
                            -81.8955379999893,
                            30.825289000215
                        ],
                        [
                            -81.9012449996843,
                            30.8298800000074
                        ],
                        [
                            -81.906968999996,
                            30.8264899997602
                        ],
                        [
                            -81.9042130001454,
                            30.818589999696
                        ],
                        [
                            -81.9091109995533,
                            30.8156800001825
                        ],
                        [
                            -81.9186469998749,
                            30.8178179998456
                        ],
                        [
                            -81.9287410004133,
                            30.8175109998735
                        ],
                        [
                            -81.9298319998342,
                            30.8200719997421
                        ],
                        [
                            -81.9352570003952,
                            30.8210659997308
                        ],
                        [
                            -81.9335169996225,
                            30.8233760001365
                        ],
                        [
                            -81.936064999959,
                            30.8244820001175
                        ],
                        [
                            -81.9362940002878,
                            30.8277660000455
                        ],
                        [
                            -81.9388340002284,
                            30.8298489999107
                        ],
                        [
                            -81.9390789995525,
                            30.8272109998834
                        ],
                        [
                            -81.9428150002927,
                            30.8285039996906
                        ],
                        [
                            -81.9420240004473,
                            30.8255539997427
                        ],
                        [
                            -81.9498059997525,
                            30.8277639998213
                        ],
                        [
                            -81.9644849997044,
                            30.8172969998554
                        ],
                        [
                            -81.9612190002158,
                            30.8140369998352
                        ],
                        [
                            -81.96388200018,
                            30.8129459996762
                        ],
                        [
                            -81.9619439999389,
                            30.80870799999
                        ],
                        [
                            -81.9650420000963,
                            30.806806999988
                        ],
                        [
                            -81.9617230000059,
                            30.8009929999138
                        ],
                        [
                            -81.9621730002677,
                            30.7969779999639
                        ],
                        [
                            -81.9690239999854,
                            30.7912769999886
                        ],
                        [
                            -81.9701229998024,
                            30.7843569997443
                        ],
                        [
                            -81.9736400002594,
                            30.7787190003545
                        ],
                        [
                            -81.9780420002731,
                            30.7765520001283
                        ],
                        [
                            -81.9810199998816,
                            30.7769760002942
                        ],
                        [
                            -81.9887119996734,
                            30.7803629996215
                        ],
                        [
                            -81.9955730003352,
                            30.786838999957
                        ],
                        [
                            -81.997406000096,
                            30.7863910001453
                        ],
                        [
                            -82.0035870003422,
                            30.7916749997311
                        ],
                        [
                            -82.0058519999231,
                            30.7903860000798
                        ],
                        [
                            -82.0076739998133,
                            30.7930370001149
                        ],
                        [
                            -82.0104459995575,
                            30.789979000099
                        ],
                        [
                            -82.0141839999475,
                            30.789209999951
                        ],
                        [
                            -82.0129739998025,
                            30.7909309996882
                        ],
                        [
                            -82.016327000228,
                            30.7915800000276
                        ],
                        [
                            -82.0177149997499,
                            30.7905770003225
                        ],
                        [
                            -82.0169749999582,
                            30.7877979999901
                        ],
                        [
                            -82.0228800000199,
                            30.7877059999728
                        ],
                        [
                            -82.0236430002762,
                            30.7830820003729
                        ],
                        [
                            -82.0173800000142,
                            30.7757999998103
                        ],
                        [
                            -82.0183650000283,
                            30.772610999819
                        ],
                        [
                            -82.0151599997408,
                            30.7719710000463
                        ],
                        [
                            -82.011602999999,
                            30.7618780003019
                        ],
                        [
                            -82.0201640003523,
                            30.7578389999705
                        ],
                        [
                            -82.0175890002516,
                            30.754991000229
                        ],
                        [
                            -82.0213679997511,
                            30.7551929996149
                        ],
                        [
                            -82.0218439999522,
                            30.7536260000704
                        ],
                        [
                            -82.0276810002418,
                            30.7509350000346
                        ],
                        [
                            -82.0316790000245,
                            30.7503879997627
                        ],
                        [
                            -82.0345860003862,
                            30.7542890003365
                        ],
                        [
                            -82.0367730001494,
                            30.7544340003499
                        ],
                        [
                            -82.0397950001388,
                            30.7472969997476
                        ],
                        [
                            -82.0388560001554,
                            30.7436979997431
                        ],
                        [
                            -82.0404510002649,
                            30.7379720002531
                        ],
                        [
                            -82.0388939997903,
                            30.7361529998831
                        ],
                        [
                            -82.0440709997558,
                            30.7312629997408
                        ],
                        [
                            -82.0419309998483,
                            30.7300089998941
                        ],
                        [
                            -82.0455269999481,
                            30.7278810002117
                        ],
                        [
                            -82.0385130000238,
                            30.722636999864
                        ],
                        [
                            -82.0395659998099,
                            30.7192990001652
                        ],
                        [
                            -82.037117000106,
                            30.718537999807
                        ],
                        [
                            -82.0388939997903,
                            30.7176510003756
                        ],
                        [
                            -82.0390389995552,
                            30.7149520000588
                        ],
                        [
                            -82.0368040001115,
                            30.7130330001207
                        ],
                        [
                            -82.0359270000524,
                            30.7062049998579
                        ],
                        [
                            -82.0397790002453,
                            30.7021920003346
                        ],
                        [
                            -82.0395659998099,
                            30.7006780000635
                        ],
                        [
                            -82.0429459999996,
                            30.7008859998492
                        ],
                        [
                            -82.0412439997601,
                            30.699384999828
                        ],
                        [
                            -82.0440370003189,
                            30.6973690001449
                        ],
                        [
                            -82.0416789999532,
                            30.6961520001404
                        ],
                        [
                            -82.0412370000874,
                            30.6910319997311
                        ],
                        [
                            -82.0429839996345,
                            30.6900650001361
                        ],
                        [
                            -82.0436859997914,
                            30.6839470002647
                        ],
                        [
                            -82.0484309999367,
                            30.6824319999505
                        ],
                        [
                            -82.0462950002272,
                            30.6811239999902
                        ],
                        [
                            -82.0502550003751,
                            30.6761150000151
                        ],
                        [
                            -82.0482789996008,
                            30.6689060002808
                        ],
                        [
                            -82.0459899997304,
                            30.6663699997859
                        ],
                        [
                            -82.0480590003911,
                            30.6650310003423
                        ],
                        [
                            -82.0464709999543,
                            30.6608059997324
                        ],
                        [
                            -82.0483439998981,
                            30.6629259999176
                        ],
                        [
                            -82.0493959998593,
                            30.6620070001447
                        ],
                        [
                            -82.0490229995904,
                            30.6552950001305
                        ],
                        [
                            -82.0443450002905,
                            30.6529409998064
                        ],
                        [
                            -82.0460759999441,
                            30.6520719996677
                        ],
                        [
                            -82.0416410003184,
                            30.648981000359
                        ],
                        [
                            -82.038611999758,
                            30.6409469997528
                        ],
                        [
                            -82.0402450004006,
                            30.6397090002048
                        ],
                        [
                            -82.0387680002919,
                            30.6386630003241
                        ],
                        [
                            -82.0404899997246,
                            30.636050000175
                        ],
                        [
                            -82.0358199999223,
                            30.6318529997692
                        ],
                        [
                            -82.0367299995933,
                            30.6299749997672
                        ],
                        [
                            -82.0331710002018,
                            30.6294930001675
                        ],
                        [
                            -82.0349589997568,
                            30.6268369998642
                        ],
                        [
                            -82.0316840000474,
                            30.6250159999612
                        ],
                        [
                            -82.0316460004125,
                            30.6195300001847
                        ],
                        [
                            -82.0295180002006,
                            30.6191579998107
                        ],
                        [
                            -82.0299829996327,
                            30.6219120000302
                        ],
                        [
                            -82.0282589996518,
                            30.6220109997033
                        ],
                        [
                            -82.0262829997758,
                            30.6133900002133
                        ],
                        [
                            -82.0288849996407,
                            30.6116849998191
                        ],
                        [
                            -82.0268090002056,
                            30.6108719996154
                        ],
                        [
                            -82.0272290003302,
                            30.6066610001761
                        ],
                        [
                            -82.0236549999717,
                            30.6067750001295
                        ],
                        [
                            -82.0150869999458,
                            30.6006020002239
                        ],
                        [
                            -82.0144490002614,
                            30.5962859997129
                        ],
                        [
                            -82.0122189999422,
                            30.5939100002794
                        ],
                        [
                            -82.0127930000525,
                            30.5903029999331
                        ],
                        [
                            -82.011202999966,
                            30.588991000028
                        ],
                        [
                            -82.0121579998428,
                            30.5862620002887
                        ],
                        [
                            -82.010208999731,
                            30.5864190003047
                        ],
                        [
                            -82.0107540004274,
                            30.5837169999653
                        ],
                        [
                            -82.0088780001105,
                            30.5829359999954
                        ],
                        [
                            -82.0089950002864,
                            30.5801489998395
                        ],
                        [
                            -82.0061259995595,
                            30.5771240002967
                        ],
                        [
                            -82.0080260001658,
                            30.5770299996402
                        ],
                        [
                            -82.0061830003593,
                            30.5749569998982
                        ],
                        [
                            -82.0078799996777,
                            30.5749690002436
                        ],
                        [
                            -82.0065320003387,
                            30.572940999681
                        ],
                        [
                            -82.0082300003803,
                            30.5697119999067
                        ],
                        [
                            -82.005820999961,
                            30.5651330001663
                        ],
                        [
                            -82.0084069999323,
                            30.5636579996619
                        ],
                        [
                            -82.0054009998364,
                            30.5635739997931
                        ],
                        [
                            -82.0080329998385,
                            30.5602529996401
                        ],
                        [
                            -82.0107109998714,
                            30.5607359997271
                        ],
                        [
                            -82.010024999608,
                            30.557872999718
                        ],
                        [
                            -82.0084680000317,
                            30.5580939998983
                        ],
                        [
                            -82.0095969999858,
                            30.5562060003266
                        ],
                        [
                            -82.0084229998259,
                            30.5545290000773
                        ],
                        [
                            -82.0111390003919,
                            30.5534729998681
                        ],
                        [
                            -82.0099500001633,
                            30.5504339999885
                        ],
                        [
                            -82.0131660003214,
                            30.5499459998026
                        ],
                        [
                            -82.0134120003687,
                            30.542444000201
                        ],
                        [
                            -82.0157539999426,
                            30.539538999633
                        ],
                        [
                            -82.0166090002603,
                            30.5347330001333
                        ],
                        [
                            -82.0190499995684,
                            30.5330120002064
                        ],
                        [
                            -82.0176009999471,
                            30.532319999762
                        ],
                        [
                            -82.018303000104,
                            30.5285209998077
                        ],
                        [
                            -82.0154340002755,
                            30.5285009997177
                        ],
                        [
                            -82.0164410000309,
                            30.5268989997986
                        ],
                        [
                            -82.0181809999053,
                            30.5275100000465
                        ],
                        [
                            -82.0186310001671,
                            30.5238340003734
                        ],
                        [
                            -82.0162960002659,
                            30.521903999955
                        ],
                        [
                            -82.0185850001363,
                            30.5194890001358
                        ],
                        [
                            -82.0155410004056,
                            30.5200980001296
                        ],
                        [
                            -82.0145639998891,
                            30.51231199995
                        ],
                        [
                            -82.017020999989,
                            30.5071319998792
                        ],
                        [
                            -82.0170870001112,
                            30.5020760001716
                        ],
                        [
                            -82.015149999695,
                            30.4998919998515
                        ],
                        [
                            -82.017754000108,
                            30.4957919997669
                        ],
                        [
                            -82.0157170001327,
                            30.494821999617
                        ],
                        [
                            -82.0180169998737,
                            30.4920749999703
                        ],
                        [
                            -82.0164050000458,
                            30.488670000173
                        ],
                        [
                            -82.0171430001877,
                            30.4855830001394
                        ],
                        [
                            -82.0155480000783,
                            30.4839580000591
                        ],
                        [
                            -82.0192179997979,
                            30.4829790001155
                        ],
                        [
                            -82.0174329997176,
                            30.4798749998542
                        ],
                        [
                            -82.0193480003926,
                            30.4789759999952
                        ],
                        [
                            -82.0169060003613,
                            30.4751109998016
                        ],
                        [
                            -82.0235949996973,
                            30.4672119996521
                        ],
                        [
                            -82.0241639997847,
                            30.4644789997863
                        ],
                        [
                            -82.0222719995744,
                            30.4613819996539
                        ],
                        [
                            -82.0283199997512,
                            30.455391000201
                        ],
                        [
                            -82.026870000305,
                            30.4537219999641
                        ],
                        [
                            -82.0296250003307,
                            30.4507429996371
                        ],
                        [
                            -82.028144999849,
                            30.4472520001645
                        ],
                        [
                            -82.0324699998698,
                            30.44159899968
                        ],
                        [
                            -82.0338740001836,
                            30.4423160003766
                        ],
                        [
                            -82.0360640003198,
                            30.4384630002697
                        ],
                        [
                            -82.0400010000031,
                            30.4366609996653
                        ],
                        [
                            -82.0394589996798,
                            30.4343719996962
                        ],
                        [
                            -82.0377270002013,
                            30.4354739999431
                        ],
                        [
                            -82.0353239996298,
                            30.4279920000188
                        ],
                        [
                            -82.033782999947,
                            30.427649999879
                        ],
                        [
                            -82.0360030002204,
                            30.4249670002016
                        ],
                        [
                            -82.0340649999793,
                            30.420032000341
                        ],
                        [
                            -82.0375520002991,
                            30.4175379998628
                        ],
                        [
                            -82.0405419996032,
                            30.4176389997399
                        ],
                        [
                            -82.0391390000127,
                            30.4170510000086
                        ],
                        [
                            -82.0395049997105,
                            30.4155030002653
                        ],
                        [
                            -82.0409850001923,
                            30.416596999766
                        ],
                        [
                            -82.0437619999593,
                            30.4148689996808
                        ],
                        [
                            -82.0408470001,
                            30.4149379998537
                        ],
                        [
                            -82.0433579997283,
                            30.4130500000067
                        ],
                        [
                            -82.0418680000991,
                            30.4113140001226
                        ],
                        [
                            -82.043632000263,
                            30.4105570002363
                        ],
                        [
                            -82.0438990002267,
                            30.4079899997329
                        ],
                        [
                            -82.041288000141,
                            30.4066690003764
                        ],
                        [
                            -82.0446850000491,
                            30.4039630002306
                        ],
                        [
                            -82.0400649995772,
                            30.3913849996308
                        ],
                        [
                            -82.0358349999909,
                            30.3850130000687
                        ],
                        [
                            -82.0381559996484,
                            30.3822090003341
                        ],
                        [
                            -82.0366330004073,
                            30.378095999775
                        ],
                        [
                            -82.0388119997745,
                            30.3746929997549
                        ],
                        [
                            -82.0367649997534,
                            30.375109000169
                        ],
                        [
                            -82.0381409995798,
                            30.3729240003594
                        ],
                        [
                            -82.0371570002889,
                            30.3720330000984
                        ],
                        [
                            -82.0407509998406,
                            30.3705559999527
                        ],
                        [
                            -82.041757999596,
                            30.3676200002895
                        ],
                        [
                            -82.0444869996824,
                            30.3676720002279
                        ],
                        [
                            -82.0421390002608,
                            30.3654090003575
                        ],
                        [
                            -82.0451169998693,
                            30.3663070002399
                        ],
                        [
                            -82.0477709996127,
                            30.363485000157
                        ],
                        [
                            -82.0580910001069,
                            30.3592140003484
                        ],
                        [
                            -82.0624210001506,
                            30.3606489997934
                        ],
                        [
                            -82.0628560003437,
                            30.3587549998477
                        ],
                        [
                            -82.0654210003987,
                            30.3585500001256
                        ],
                        [
                            -82.0649109998624,
                            30.3564329997366
                        ],
                        [
                            -82.0661839997566,
                            30.3557569997647
                        ],
                        [
                            -82.0726679999515,
                            30.36141000001
                        ],
                        [
                            -82.0811139997787,
                            30.3587679996568
                        ],
                        [
                            -82.0833180001586,
                            30.3603340000928
                        ],
                        [
                            -82.0947180002031,
                            30.3607030001495
                        ],
                        [
                            -82.0986990002674,
                            30.3640609999277
                        ],
                        [
                            -82.1010750001764,
                            30.3627909998432
                        ],
                        [
                            -82.1006280002877,
                            30.3644170000436
                        ],
                        [
                            -82.1023749998348,
                            30.3643789996314
                        ],
                        [
                            -82.1015589998751,
                            30.3666399996195
                        ],
                        [
                            -82.1052410001886,
                            30.3688960001501
                        ],
                        [
                            -82.1103999997125,
                            30.3667200003745
                        ],
                        [
                            -82.1103319999404,
                            30.3652089999289
                        ],
                        [
                            -82.111521000169,
                            30.3675569998056
                        ],
                        [
                            -82.1157750000447,
                            30.3680530000204
                        ],
                        [
                            -82.1181490003039,
                            30.3643580000705
                        ],
                        [
                            -82.1268539996989,
                            30.3682620000654
                        ],
                        [
                            -82.1286720002894,
                            30.3670829997713
                        ],
                        [
                            -82.1275640002517,
                            30.3654380001027
                        ],
                        [
                            -82.1338640003235,
                            30.3622370002553
                        ],
                        [
                            -82.1370639996899,
                            30.3625160002162
                        ],
                        [
                            -82.1369420003895,
                            30.3642239997759
                        ],
                        [
                            -82.1399619998307,
                            30.3649189998599
                        ],
                        [
                            -82.1485490001232,
                            30.3608980001972
                        ],
                        [
                            -82.1500990000268,
                            30.3618299999324
                        ],
                        [
                            -82.1578970001238,
                            30.3601219996469
                        ],
                        [
                            -82.1617769999057,
                            30.3571110003485
                        ],
                        [
                            -82.1674130003539,
                            30.360002000083
                        ],
                        [
                            -82.1701969997936,
                            30.3589719997736
                        ],
                        [
                            -82.1737450002128,
                            30.3650659997244
                        ],
                        [
                            -82.1809909999407,
                            30.3686110000644
                        ],
                        [
                            -82.1802400002784,
                            30.3725799999518
                        ],
                        [
                            -82.1834930002465,
                            30.3733380002226
                        ],
                        [
                            -82.1847949995547,
                            30.378936000173
                        ],
                        [
                            -82.1871090004377,
                            30.378983999867
                        ],
                        [
                            -82.1899149996188,
                            30.3761700001752
                        ],
                        [
                            -82.1927859999955,
                            30.3790650003143
                        ],
                        [
                            -82.1934220000301,
                            30.3833449996141
                        ],
                        [
                            -82.1917890002858,
                            30.3847159999025
                        ],
                        [
                            -82.1942079998526,
                            30.3892040002748
                        ],
                        [
                            -82.1965839997616,
                            30.3882779996378
                        ],
                        [
                            -82.1992249999845,
                            30.389832000166
                        ],
                        [
                            -82.1981079997259,
                            30.3918430000086
                        ],
                        [
                            -82.2014000000521,
                            30.3953500001479
                        ],
                        [
                            -82.2028570000693,
                            30.4012559999805
                        ],
                        [
                            -82.2042449995912,
                            30.4015530002248
                        ],
                        [
                            -82.2037600000676,
                            30.4053109996469
                        ],
                        [
                            -82.2067550002929,
                            30.4068429996387
                        ],
                        [
                            -82.2053269996897,
                            30.4083399998233
                        ],
                        [
                            -82.2057550002102,
                            30.411946000245
                        ],
                        [
                            -82.2083839998392,
                            30.4143040002472
                        ],
                        [
                            -82.2064330000776,
                            30.4170840003364
                        ],
                        [
                            -82.2080340000348,
                            30.4174669997793
                        ],
                        [
                            -82.2074619995743,
                            30.4211199998234
                        ],
                        [
                            -82.209198999974,
                            30.421329000226
                        ],
                        [
                            -82.2108600002057,
                            30.4253790002332
                        ],
                        [
                            -82.2090049998052,
                            30.4283369996991
                        ],
                        [
                            -82.2097200003809,
                            30.4330550001432
                        ],
                        [
                            -82.2064160003592,
                            30.4357250000103
                        ],
                        [
                            -82.2073510001445,
                            30.4391730001038
                        ],
                        [
                            -82.2037860000069,
                            30.4449840002767
                        ],
                        [
                            -82.2057379995934,
                            30.4510479998631
                        ],
                        [
                            -82.2040579999935,
                            30.4528640003476
                        ],
                        [
                            -82.2044949998365,
                            30.4553419996428
                        ],
                        [
                            -82.2073789997336,
                            30.456980999885
                        ],
                        [
                            -82.207700000124,
                            30.4604069997753
                        ],
                        [
                            -82.2061510000453,
                            30.4624149998151
                        ],
                        [
                            -82.2063719999782,
                            30.4685149996949
                        ],
                        [
                            -82.2044660004225,
                            30.4687560002389
                        ],
                        [
                            -82.204374000361,
                            30.4710950002278
                        ],
                        [
                            -82.2010350001791,
                            30.4746649996619
                        ],
                        [
                            -82.2014729998471,
                            30.4800630002666
                        ],
                        [
                            -82.2032060000488,
                            30.4821839997281
                        ],
                        [
                            -82.2012400002186,
                            30.4851139997144
                        ],
                        [
                            -82.206670999729,
                            30.493205999872
                        ],
                        [
                            -82.2115780002561,
                            30.4972889996486
                        ],
                        [
                            -82.2126319998672,
                            30.5005489997943
                        ],
                        [
                            -82.2250720001774,
                            30.5076900002428
                        ],
                        [
                            -82.2303040003945,
                            30.5173250001789
                        ],
                        [
                            -82.2293009999387,
                            30.5205869999853
                        ],
                        [
                            -82.2307589997808,
                            30.5265760002264
                        ],
                        [
                            -82.2373879997407,
                            30.5322159997566
                        ],
                        [
                            -82.2404030000574,
                            30.5377700001006
                        ],
                        [
                            -82.2388790000931,
                            30.5455049998881
                        ],
                        [
                            -82.2344350002465,
                            30.5487549999861
                        ],
                        [
                            -82.2341859998262,
                            30.5576500001671
                        ],
                        [
                            -82.2238620000324,
                            30.5652760001063
                        ],
                        [
                            -82.216920999903,
                            30.56531599983
                        ],
                        [
                            -82.2143969998559,
                            30.566827000074
                        ],
                        [
                            -82.2146770002384,
                            30.5685560000252
                        ],
                        [
                            -82.5957350000345,
                            30.5925300000496
                        ],
                        [
                            -83.4999250002108,
                            30.6456739998242
                        ],
                        [
                            -84.2503570000762,
                            30.6841330000235
                        ],
                        [
                            -84.4744089996162,
                            30.6927930002523
                        ],
                        [
                            -84.6443320002085,
                            30.7019639996806
                        ],
                        [
                            -84.8646930001124,
                            30.7115419999388
                        ],
                        [
                            -84.8697520000772,
                            30.7218969999763
                        ],
                        [
                            -84.8754210001373,
                            30.7274909999352
                        ],
                        [
                            -84.8838209999337,
                            30.7325909999952
                        ],
                        [
                            -84.8875219996155,
                            30.7417909996424
                        ],
                        [
                            -84.8961220003267,
                            30.7505910000695
                        ],
                        [
                            -84.9002220002167,
                            30.751890999724
                        ],
                        [
                            -84.9073220003547,
                            30.7505910000695
                        ],
                        [
                            -84.9135219999692,
                            30.7522909997689
                        ],
                        [
                            -84.9150219996441,
                            30.7611909997878
                        ],
                        [
                            -84.9201230003399,
                            30.7659900001636
                        ],
                        [
                            -84.9180229997171,
                            30.7720900002152
                        ],
                        [
                            -84.9180229997171,
                            30.7780899998965
                        ],
                        [
                            -84.9283230001198,
                            30.7930900001245
                        ],
                        [
                            -84.9283230001198,
                            30.8050899996167
                        ],
                        [
                            -84.9374240002482,
                            30.820889000166
                        ],
                        [
                            -84.9348149998123,
                            30.8358509998322
                        ],
                        [
                            -84.9321379996044,
                            30.8382960001139
                        ],
                        [
                            -84.9270839996625,
                            30.8390239998433
                        ],
                        [
                            -84.9256650001784,
                            30.8426849999672
                        ],
                        [
                            -84.9278240003525,
                            30.8478890002372
                        ],
                        [
                            -84.9332239999008,
                            30.85148799981
                        ],
                        [
                            -84.9361239996915,
                            30.8569879996784
                        ],
                        [
                            -84.9364240001655,
                            30.8597879998124
                        ],
                        [
                            -84.9323240002755,
                            30.8642880002499
                        ],
                        [
                            -84.9398240004467,
                            30.8725880002787
                        ],
                        [
                            -84.934424,
                            30.8835879998483
                        ],
                        [
                            -84.9425250000457,
                            30.8884879997283
                        ],
                        [
                            -84.9561250002722,
                            30.9075869999065
                        ],
                        [
                            -84.9667260000755,
                            30.9172869998782
                        ],
                        [
                            -84.9710259999819,
                            30.9281869996492
                        ],
                        [
                            -84.9831270003584,
                            30.9347859999398
                        ],
                        [
                            -84.983026999901,
                            30.9425859996851
                        ],
                        [
                            -84.9795270000607,
                            30.955585999865
                        ],
                        [
                            -84.9801270001103,
                            30.9612860000458
                        ],
                        [
                            -84.9825270003088,
                            30.9655859999004
                        ],
                        [
                            -84.9872170002026,
                            30.9685429998344
                        ],
                        [
                            -84.9999279997762,
                            30.9711860000845
                        ],
                        [
                            -85.0057340001036,
                            30.9759080002493
                        ],
                        [
                            -85.0059340001202,
                            30.9798040000179
                        ],
                        [
                            -85.0023809996781,
                            30.9878360002195
                        ],
                        [
                            -85.0024989996789,
                            31.0006850000506
                        ],
                        [
                            -84.999428000184,
                            31.0138440003317
                        ],
                        [
                            -85.0045470004232,
                            31.0191820002359
                        ],
                        [
                            -85.0050510002134,
                            31.0247020000381
                        ],
                        [
                            -85.0094070001963,
                            31.0323770001701
                        ],
                        [
                            -85.0085769999929,
                            31.0440610003764
                        ],
                        [
                            -85.0113909995699,
                            31.053546999913
                        ],
                        [
                            -85.0181500001244,
                            31.0592540000434
                        ],
                        [
                            -85.0285729996525,
                            31.0745830003147
                        ],
                        [
                            -85.026067999872,
                            31.0841799998792
                        ],
                        [
                            -85.0297359999418,
                            31.0961629999622
                        ],
                        [
                            -85.0354569998804,
                            31.1043439998691
                        ],
                        [
                            -85.0356150000642,
                            31.1081920002228
                        ],
                        [
                            -85.0546769997632,
                            31.1208180001866
                        ],
                        [
                            -85.060432999862,
                            31.1314990003195
                        ],
                        [
                            -85.0640280001369,
                            31.142495000237
                        ],
                        [
                            -85.0693289999509,
                            31.1470080003182
                        ],
                        [
                            -85.0766280002806,
                            31.1569270001351
                        ],
                        [
                            -85.0835819999305,
                            31.1596300002253
                        ],
                        [
                            -85.0921059995755,
                            31.1602930003075
                        ],
                        [
                            -85.1002069996211,
                            31.165490000074
                        ],
                        [
                            -85.0985069999297,
                            31.1801530001337
                        ],
                        [
                            -85.1020519999758,
                            31.1847339998954
                        ],
                        [
                            -85.1075159999966,
                            31.186451000264
                        ],
                        [
                            -85.1069629998026,
                            31.2026929999745
                        ],
                        [
                            -85.0987039995732,
                            31.2112859997698
                        ],
                        [
                            -85.0987069999462,
                            31.2195110002483
                        ],
                        [
                            -85.0967629998573,
                            31.2256509997755
                        ],
                        [
                            -85.1123520003786,
                            31.2595800001957
                        ],
                        [
                            -85.1132610002247,
                            31.2643430001024
                        ],
                        [
                            -85.1119049995915,
                            31.2724769999475
                        ],
                        [
                            -85.114601000066,
                            31.2773330000273
                        ],
                        [
                            -85.1103580000609,
                            31.2817470000301
                        ],
                        [
                            -85.098712999794,
                            31.2844550001566
                        ],
                        [
                            -85.0897740000474,
                            31.2950259998644
                        ],
                        [
                            -85.0874039999861,
                            31.3112229997843
                        ],
                        [
                            -85.0837760000993,
                            31.3182100003289
                        ],
                        [
                            -85.0836969995582,
                            31.3238460000712
                        ],
                        [
                            -85.0841519998429,
                            31.3283129998341
                        ],
                        [
                            -85.0894230004181,
                            31.3360280000164
                        ],
                        [
                            -85.0870890003418,
                            31.3403209999204
                        ],
                        [
                            -85.0859179996566,
                            31.3531459998319
                        ],
                        [
                            -85.0917909999312,
                            31.3552069999046
                        ],
                        [
                            -85.0923270004067,
                            31.3640489999049
                        ],
                        [
                            -85.0824310002351,
                            31.3845400002512
                        ],
                        [
                            -85.0804029995822,
                            31.3939319997357
                        ],
                        [
                            -85.0776259998152,
                            31.3988800001054
                        ],
                        [
                            -85.0773870003389,
                            31.4028439999369
                        ],
                        [
                            -85.0799780003331,
                            31.4104719996407
                        ],
                        [
                            -85.0767460002814,
                            31.4159710001035
                        ],
                        [
                            -85.0758929996135,
                            31.4249279998914
                        ],
                        [
                            -85.0737019996524,
                            31.4289590000991
                        ],
                        [
                            -85.0685459996032,
                            31.4273109998043
                        ],
                        [
                            -85.0660120004087,
                            31.4305289999427
                        ],
                        [
                            -85.065954999609,
                            31.4429790002485
                        ],
                        [
                            -85.0716210001944,
                            31.4683839997574
                        ],
                        [
                            -85.0652590001982,
                            31.4849239999679
                        ],
                        [
                            -85.0621049999643,
                            31.4880170001204
                        ],
                        [
                            -85.0569279999988,
                            31.5001710001866
                        ],
                        [
                            -85.044985999631,
                            31.5182300000908
                        ],
                        [
                            -85.044556000359,
                            31.5209080001181
                        ],
                        [
                            -85.0482629998885,
                            31.5260120000999
                        ],
                        [
                            -85.0418130000288,
                            31.5377539997072
                        ],
                        [
                            -85.0413610001172,
                            31.5424950001958
                        ],
                        [
                            -85.0425469999727,
                            31.5459529997738
                        ],
                        [
                            -85.0508379999892,
                            31.5555510002876
                        ],
                        [
                            -85.052931000041,
                            31.5628900001132
                        ],
                        [
                            -85.0579599998686,
                            31.570839999749
                        ],
                        [
                            -85.0552840003839,
                            31.5775960001783
                        ],
                        [
                            -85.0584400002676,
                            31.583690000077
                        ],
                        [
                            -85.0559759995967,
                            31.6051749998938
                        ],
                        [
                            -85.0605519996877,
                            31.608223999853
                        ],
                        [
                            -85.0575270002235,
                            31.6168829998728
                        ],
                        [
                            -85.0581690001059,
                            31.6202269996609
                        ],
                        [
                            -85.073828999874,
                            31.6295669999271
                        ],
                        [
                            -85.0800290003867,
                            31.6368669997585
                        ],
                        [
                            -85.0862290000012,
                            31.6385670002775
                        ],
                        [
                            -85.0870290000674,
                            31.640965999683
                        ],
                        [
                            -85.0826289997035,
                            31.6502659996268
                        ],
                        [
                            -85.0780290002213,
                            31.6515660000413
                        ],
                        [
                            -85.0791289998631,
                            31.6547660001227
                        ],
                        [
                            -85.0924289996156,
                            31.6599660002813
                        ],
                        [
                            -85.1094299999482,
                            31.6774650001225
                        ],
                        [
                            -85.1139299998713,
                            31.6868649997549
                        ],
                        [
                            -85.1223299996677,
                            31.6912649999252
                        ],
                        [
                            -85.1255299999323,
                            31.6949649997914
                        ],
                        [
                            -85.126530000015,
                            31.7167640001389
                        ],
                        [
                            -85.1222300001086,
                            31.7227639997315
                        ],
                        [
                            -85.1222300001086,
                            31.7264640000144
                        ],
                        [
                            -85.1189300002848,
                            31.73266400016
                        ],
                        [
                            -85.122030000092,
                            31.7440640000716
                        ],
                        [
                            -85.1292309996141,
                            31.7586630000582
                        ],
                        [
                            -85.1252300003567,
                            31.7670629997206
                        ],
                        [
                            -85.1419310002153,
                            31.7819630000887
                        ],
                        [
                            -85.1329310003693,
                            31.7923629999909
                        ],
                        [
                            -85.1322309998622,
                            31.7951620001782
                        ],
                        [
                            -85.1329310003693,
                            31.8080619996624
                        ],
                        [
                            -85.1312309997795,
                            31.8167619997015
                        ],
                        [
                            -85.1345309996033,
                            31.8249619999472
                        ],
                        [
                            -85.1319310002866,
                            31.8270619997556
                        ],
                        [
                            -85.1377309998679,
                            31.8321619998137
                        ],
                        [
                            -85.1418309997579,
                            31.839261000306
                        ],
                        [
                            -85.1377309998679,
                            31.845861000189
                        ],
                        [
                            -85.1380310003419,
                            31.8512619997493
                        ],
                        [
                            -85.140731000116,
                            31.8574609997739
                        ],
                        [
                            -85.13583100016,
                            31.862460999832
                        ],
                        [
                            -85.1337310004354,
                            31.870061000258
                        ],
                        [
                            -85.1284310004462,
                            31.8775600000847
                        ],
                        [
                            -85.1341309995702,
                            31.892160000038
                        ],
                        [
                            -85.1150310002363,
                            31.8930600001122
                        ],
                        [
                            -85.1120300001633,
                            31.894760000063
                        ],
                        [
                            -85.1106300000475,
                            31.8968599999583
                        ],
                        [
                            -85.1122310000047,
                            31.9027600003515
                        ],
                        [
                            -85.1080299998325,
                            31.9051600002985
                        ],
                        [
                            -85.11313099963,
                            31.9118590000558
                        ],
                        [
                            -85.1024300002676,
                            31.9173589996861
                        ],
                        [
                            -85.0995299995786,
                            31.9252590001079
                        ],
                        [
                            -85.0918300002893,
                            31.9288590000573
                        ],
                        [
                            -85.0864299998427,
                            31.9359590002682
                        ],
                        [
                            -85.0789299996715,
                            31.9401590002007
                        ],
                        [
                            -85.0824300004101,
                            31.9453580001392
                        ],
                        [
                            -85.0867300003166,
                            31.9591580002489
                        ],
                        [
                            -85.083229999578,
                            31.9624580001846
                        ],
                        [
                            -85.0739300001563,
                            31.9641580001746
                        ],
                        [
                            -85.067829000276,
                            31.9673580002155
                        ],
                        [
                            -85.0659289996698,
                            31.972458000128
                        ],
                        [
                            -85.0709299999082,
                            31.9816580002981
                        ],
                        [
                            -85.0683299996932,
                            31.9867569999068
                        ],
                        [
                            -85.0673299996105,
                            31.9955570001378
                        ],
                        [
                            -85.0634409996077,
                            32.0041399996413
                        ],
                        [
                            -85.0550750001465,
                            32.0107139998913
                        ],
                        [
                            -85.0538149997728,
                            32.0135020001602
                        ],
                        [
                            -85.055307999775,
                            32.0177529997864
                        ],
                        [
                            -85.0536690001829,
                            32.0206619999254
                        ],
                        [
                            -85.0488250003033,
                            32.0217009997898
                        ],
                        [
                            -85.0571239998174,
                            32.0299190001836
                        ],
                        [
                            -85.0580340003867,
                            32.0336059999773
                        ],
                        [
                            -85.0535319999156,
                            32.0359520002677
                        ],
                        [
                            -85.0534680003415,
                            32.0378230001305
                        ],
                        [
                            -85.0588300002549,
                            32.0469560002278
                        ],
                        [
                            -85.0541789998209,
                            32.0679849996956
                        ],
                        [
                            -85.0558130002884,
                            32.0744390003141
                        ],
                        [
                            -85.0532320003399,
                            32.0806039998928
                        ],
                        [
                            -85.0447610003984,
                            32.0858359997643
                        ],
                        [
                            -85.0534020002192,
                            32.1048439997163
                        ],
                        [
                            -85.0619069995976,
                            32.1328630002021
                        ],
                        [
                            -85.0455930002516,
                            32.1437579999106
                        ],
                        [
                            -85.0265830004311,
                            32.1661029999775
                        ],
                        [
                            -85.0170799997215,
                            32.1735160003415
                        ],
                        [
                            -85.0112669997213,
                            32.1804930001518
                        ],
                        [
                            -84.9750279999626,
                            32.1910520001257
                        ],
                        [
                            -84.9637280003755,
                            32.19585199989
                        ],
                        [
                            -84.9617280002101,
                            32.1987510001709
                        ],
                        [
                            -84.9646280000008,
                            32.2035500001811
                        ],
                        [
                            -84.974427999913,
                            32.2035500001811
                        ],
                        [
                            -84.9802290002176,
                            32.20775100035
                        ],
                        [
                            -84.9798290001845,
                            32.2110500000586
                        ],
                        [
                            -84.9733280002712,
                            32.2176500002751
                        ],
                        [
                            -84.9681279998411,
                            32.2193509998183
                        ],
                        [
                            -84.9593280000116,
                            32.2178509997332
                        ],
                        [
                            -84.9393280001541,
                            32.2179510003568
                        ],
                        [
                            -84.9282269998603,
                            32.2198509996671
                        ],
                        [
                            -84.9229269998711,
                            32.2247499997026
                        ],
                        [
                            -84.9233260000793,
                            32.2308509996928
                        ],
                        [
                            -84.9163270002236,
                            32.2365509996339
                        ],
                        [
                            -84.9127269999259,
                            32.2433500002845
                        ],
                        [
                            -84.9154269997,
                            32.2460489999163
                        ],
                        [
                            -84.9203269996561,
                            32.2440499996961
                        ],
                        [
                            -84.9232270003451,
                            32.2446499997822
                        ],
                        [
                            -84.9244270004444,
                            32.2501490003079
                        ],
                        [
                            -84.9072269999201,
                            32.2490490002863
                        ],
                        [
                            -84.9040869999299,
                            32.2508379997063
                        ],
                        [
                            -84.9034790003827,
                            32.2556059999926
                        ],
                        [
                            -84.9011910003372,
                            32.2583739999664
                        ],
                        [
                            -84.8911910004085,
                            32.2574940000532
                        ],
                        [
                            -84.888246000412,
                            32.2596699998166
                        ],
                        [
                            -84.9040230003558,
                            32.2737490002086
                        ],
                        [
                            -84.9228719996196,
                            32.2853329998711
                        ],
                        [
                            -84.9346480003061,
                            32.2989159997945
                        ],
                        [
                            -84.9888259996575,
                            32.3191719996817
                        ],
                        [
                            -85.0003429998779,
                            32.3214179996589
                        ],
                        [
                            -85.0064019999253,
                            32.3267569999875
                        ],
                        [
                            -85.008095999769,
                            32.3366769997105
                        ],
                        [
                            -85.004581999685,
                            32.3451960002947
                        ],
                        [
                            -84.9838660003252,
                            32.3623859996501
                        ],
                        [
                            -84.9832419999862,
                            32.3651220001439
                        ],
                        [
                            -84.9863300000978,
                            32.3686899996885
                        ],
                        [
                            -84.9853219996192,
                            32.3725140001025
                        ],
                        [
                            -84.9820419998869,
                            32.3743379997548
                        ],
                        [
                            -84.9752260003293,
                            32.3741619998556
                        ],
                        [
                            -84.9721540001112,
                            32.3769140000242
                        ],
                        [
                            -84.9748100004027,
                            32.3802570001224
                        ],
                        [
                            -84.9862820004173,
                            32.380336000149
                        ],
                        [
                            -84.9884099997309,
                            32.3852159996636
                        ],
                        [
                            -84.9830019997867,
                            32.3919530003288
                        ],
                        [
                            -84.9783620001216,
                            32.3904970001164
                        ],
                        [
                            -84.9687300004388,
                            32.3914570001001
                        ],
                        [
                            -84.969274000412,
                            32.3934409998409
                        ],
                        [
                            -84.9747459999303,
                            32.3956010001845
                        ],
                        [
                            -84.9798979997815,
                            32.4000970003727
                        ],
                        [
                            -84.9809539999407,
                            32.4060640001709
                        ],
                        [
                            -84.9794309998012,
                            32.4122439996728
                        ],
                        [
                            -84.9718300002461,
                            32.416244000027
                        ],
                        [
                            -84.9630300004166,
                            32.424244000303
                        ],
                        [
                            -84.967030999674,
                            32.4353429998348
                        ],
                        [
                            -84.971831000071,
                            32.4428430000672
                        ],
                        [
                            -84.9838310001651,
                            32.4456419998519
                        ],
                        [
                            -84.993529999795,
                            32.4507429999868
                        ],
                        [
                            -84.9953299999439,
                            32.4532429997977
                        ],
                        [
                            -84.9985310000334,
                            32.4687420000301
                        ],
                        [
                            -84.9952279998366,
                            32.4752989996983
                        ],
                        [
                            -84.9949309997357,
                            32.4801419999826
                        ],
                        [
                            -84.9954320000511,
                            32.4889420000609
                        ],
                        [
                            -84.9988320003323,
                            32.4970410003608
                        ],
                        [
                            -84.9965319996929,
                            32.5016410000874
                        ],
                        [
                            -84.999832000415,
                            32.5043400003235
                        ],
                        [
                            -84.9989319998914,
                            32.5063409997353
                        ],
                        [
                            -85.0009320000568,
                            32.5087409998219
                        ],
                        [
                            -85.0015320001065,
                            32.514740999647
                        ],
                        [
                            -85.006380000184,
                            32.5184930000448
                        ],
                        [
                            -85.0070999998842,
                            32.5238679997796
                        ],
                        [
                            -85.0152610002044,
                            32.5276759998694
                        ],
                        [
                            -85.0152610002044,
                            32.5311149997593
                        ],
                        [
                            -85.0208769996627,
                            32.535852000311
                        ],
                        [
                            -85.0220449999749,
                            32.5408269999908
                        ],
                        [
                            -85.0207970001951,
                            32.5427640003702
                        ],
                        [
                            -85.0261890002458,
                            32.5448430001315
                        ],
                        [
                            -85.0313889997775,
                            32.5441710003186
                        ],
                        [
                            -85.0357260003922,
                            32.55396300019
                        ],
                        [
                            -85.0436619996832,
                            32.5565230001448
                        ],
                        [
                            -85.045470000228,
                            32.5617229999511
                        ],
                        [
                            -85.056926000349,
                            32.5712410000968
                        ],
                        [
                            -85.0579020001422,
                            32.5746499997435
                        ],
                        [
                            -85.0619829997656,
                            32.5782490000648
                        ],
                        [
                            -85.0675349996499,
                            32.5795460001098
                        ],
                        [
                            -85.0789749998774,
                            32.6001689999086
                        ],
                        [
                            -85.0822400004394,
                            32.6162640001955
                        ],
                        [
                            -85.0879360002637,
                            32.6205200003076
                        ],
                        [
                            -85.090886000283,
                            32.6251349996602
                        ],
                        [
                            -85.0900359999882,
                            32.6276359997094
                        ],
                        [
                            -85.0838360003737,
                            32.6319359999072
                        ],
                        [
                            -85.0831180003233,
                            32.6361610001094
                        ],
                        [
                            -85.0900359999882,
                            32.6340360003299
                        ],
                        [
                            -85.0966840002146,
                            32.6361970003363
                        ],
                        [
                            -85.0962180000593,
                            32.6348289996882
                        ],
                        [
                            -85.1000889996204,
                            32.6345759999075
                        ],
                        [
                            -85.0988989995669,
                            32.6369349999032
                        ],
                        [
                            -85.1003880002694,
                            32.6371180002683
                        ],
                        [
                            -85.1015540000335,
                            32.6403929998993
                        ],
                        [
                            -85.104531999642,
                            32.6410110001585
                        ],
                        [
                            -85.1054320001656,
                            32.6449339999672
                        ],
                        [
                            -85.1043369996483,
                            32.6464339998036
                        ],
                        [
                            -85.0983360002255,
                            32.6520340003737
                        ],
                        [
                            -85.0897360004125,
                            32.6556339999769
                        ],
                        [
                            -85.0884829997115,
                            32.657758000338
                        ],
                        [
                            -85.0930360002363,
                            32.6644340000174
                        ],
                        [
                            -85.0935359998285,
                            32.6697339998539
                        ],
                        [
                            -85.1040370000727,
                            32.6796340003463
                        ],
                        [
                            -85.1126369998856,
                            32.6834340002812
                        ],
                        [
                            -85.1170370002495,
                            32.692032999971
                        ],
                        [
                            -85.1200380003225,
                            32.7065330000967
                        ],
                        [
                            -85.1181379997162,
                            32.7094319998013
                        ],
                        [
                            -85.1218379995731,
                            32.7126320002396
                        ],
                        [
                            -85.1227380000967,
                            32.7160320001583
                        ],
                        [
                            -85.1208380003887,
                            32.7229320002274
                        ],
                        [
                            -85.1141380002837,
                            32.7304320000248
                        ],
                        [
                            -85.1133289999967,
                            32.7357509998673
                        ],
                        [
                            -85.1185500003432,
                            32.7408169999856
                        ],
                        [
                            -85.1282380001024,
                            32.7422320003254
                        ],
                        [
                            -85.1331380000585,
                            32.7446310003699
                        ],
                        [
                            -85.1394549998488,
                            32.7518290000278
                        ],
                        [
                            -85.1429870003745,
                            32.7599340002369
                        ],
                        [
                            -85.1416220004188,
                            32.7641620001079
                        ],
                        [
                            -85.1374170000486,
                            32.7671929998397
                        ],
                        [
                            -85.1366920003255,
                            32.7711720001326
                        ],
                        [
                            -85.1308840003482,
                            32.7695620002847
                        ],
                        [
                            -85.1237380001794,
                            32.7720310002482
                        ],
                        [
                            -85.122326000368,
                            32.7743829997856
                        ],
                        [
                            -85.1262189996704,
                            32.7791889997857
                        ],
                        [
                            -85.1310990004333,
                            32.7803129998829
                        ],
                        [
                            -85.1348930000015,
                            32.7844609996977
                        ],
                        [
                            -85.1396649999111,
                            32.7851500000775
                        ],
                        [
                            -85.1421830001104,
                            32.792136000183
                        ],
                        [
                            -85.1463320004041,
                            32.7915660001068
                        ],
                        [
                            -85.1520249998554,
                            32.7941790003146
                        ],
                        [
                            -85.1680479998466,
                            32.8118409997228
                        ],
                        [
                            -85.1686439996982,
                            32.8142450000796
                        ],
                        [
                            -85.1648450001072,
                            32.8197890002016
                        ],
                        [
                            -85.1683419995744,
                            32.8285159996295
                        ],
                        [
                            -85.1641079997902,
                            32.8352949999123
                        ],
                        [
                            -85.1540130003252,
                            32.8407440001899
                        ],
                        [
                            -85.1533880001612,
                            32.8448649999539
                        ],
                        [
                            -85.1562889997768,
                            32.8505239998691
                        ],
                        [
                            -85.1623100001894,
                            32.8564790003753
                        ],
                        [
                            -85.1686880000791,
                            32.8525869997559
                        ],
                        [
                            -85.1771249996855,
                            32.8538899997092
                        ],
                        [
                            -85.1843999997257,
                            32.8613169999417
                        ],
                        [
                            -85.1841310001122,
                            32.870525000153
                        ],
                        [
                            -85.233108000107,
                            33.1117140001027
                        ],
                        [
                            -85.3865810000125,
                            33.901718999712
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 32.6295789,
            "name": "Georgia",
            "intptlon": -83.4235109,
            "geo_point_2d": [
                32.6386626049,
                -83.427200622
            ],
            "geoid": "13",
            "mtfcc": "G4000",
            "region": 3
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -83.427200622,
                32.6386626049
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "8593bd53f7f501c531d115aabd7a2714bacc881f",
        "fields": {
            "arealand": "-1718748249",
            "objectid": 38,
            "basename": "Maine",
            "stusab": "ME",
            "statens": "01779787",
            "centlon": -69.2136634,
            "state": "23",
            "gid": 25,
            "centlat": 45.2610943,
            "division": 1,
            "areawater": "-1136555256",
            "oid": "-1984725153",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -70.9891219999859,
                            43.7921129999779
                        ],
                        [
                            -70.993328000181,
                            43.9297930000125
                        ],
                        [
                            -71.0057459998516,
                            44.2026750000274
                        ],
                        [
                            -71.0182239997966,
                            44.4316719999052
                        ],
                        [
                            -71.0261619996357,
                            44.549267999721
                        ],
                        [
                            -71.0326149998684,
                            44.6831820002753
                        ],
                        [
                            -71.0431179997624,
                            44.8268910002919
                        ],
                        [
                            -71.0582469999761,
                            44.9905149997322
                        ],
                        [
                            -71.077173000131,
                            45.250048000244
                        ],
                        [
                            -71.0839279995893,
                            45.3054839999733
                        ],
                        [
                            -71.0750309996754,
                            45.3070489998227
                        ],
                        [
                            -71.0655179998184,
                            45.3125009997363
                        ],
                        [
                            -71.0591499999745,
                            45.3138129998863
                        ],
                        [
                            -71.0531179996912,
                            45.3114240001654
                        ],
                        [
                            -71.0414409996372,
                            45.3130549997431
                        ],
                        [
                            -71.0324550000349,
                            45.3100740000773
                        ],
                        [
                            -71.0268629999676,
                            45.3147509997988
                        ],
                        [
                            -71.0162889999285,
                            45.3155280001885
                        ],
                        [
                            -71.0084869996336,
                            45.319242999837
                        ],
                        [
                            -71.0039320003573,
                            45.3273709997695
                        ],
                        [
                            -71.0018540003741,
                            45.3284470002892
                        ],
                        [
                            -71.006648000025,
                            45.3327189997851
                        ],
                        [
                            -71.0119909996719,
                            45.3338270000413
                        ],
                        [
                            -71.0106099998227,
                            45.3381580001032
                        ],
                        [
                            -71.0130019996252,
                            45.344228000109
                        ],
                        [
                            -71.0106190000435,
                            45.3476769997224
                        ],
                        [
                            -71.0014350000745,
                            45.3441749997621
                        ],
                        [
                            -71.0004259997709,
                            45.3419960000611
                        ],
                        [
                            -70.9938000001841,
                            45.3383720001396
                        ],
                        [
                            -70.9910839996181,
                            45.3382490000699
                        ],
                        [
                            -70.9891629999937,
                            45.3341680002494
                        ],
                        [
                            -70.9849969999816,
                            45.3319100001247
                        ],
                        [
                            -70.9732959996382,
                            45.333638000149
                        ],
                        [
                            -70.9673200003297,
                            45.3322600001666
                        ],
                        [
                            -70.9611099997712,
                            45.3367599999899
                        ],
                        [
                            -70.9515609999291,
                            45.3388229997925
                        ],
                        [
                            -70.9494710002504,
                            45.33132000021
                        ],
                        [
                            -70.9451710003439,
                            45.3290709997468
                        ],
                        [
                            -70.94523099972,
                            45.3256489999573
                        ],
                        [
                            -70.9406160001693,
                            45.3232210001588
                        ],
                        [
                            -70.9389409996939,
                            45.3199769998817
                        ],
                        [
                            -70.9300739999172,
                            45.3187950000316
                        ],
                        [
                            -70.9272260000051,
                            45.3148470000482
                        ],
                        [
                            -70.9179490001496,
                            45.311695000159
                        ],
                        [
                            -70.9198689999491,
                            45.3053889998147
                        ],
                        [
                            -70.9138280003433,
                            45.3003320002126
                        ],
                        [
                            -70.9120530003088,
                            45.2962889997337
                        ],
                        [
                            -70.9139179998567,
                            45.2918510002425
                        ],
                        [
                            -70.9188169999878,
                            45.2872319999172
                        ],
                        [
                            -70.9214799999521,
                            45.2793740002776
                        ],
                        [
                            -70.9164729998659,
                            45.2773299997358
                        ],
                        [
                            -70.9113529998017,
                            45.2699850001804
                        ],
                        [
                            -70.9084940000189,
                            45.2694919998174
                        ],
                        [
                            -70.9054989997937,
                            45.2660049997885
                        ],
                        [
                            -70.9059719996217,
                            45.2608429999431
                        ],
                        [
                            -70.898347999602,
                            45.2580130002007
                        ],
                        [
                            -70.8983429995791,
                            45.2548679998735
                        ],
                        [
                            -70.895137000365,
                            45.2522519997809
                        ],
                        [
                            -70.8979950003229,
                            45.2463579997302
                        ],
                        [
                            -70.8970029997378,
                            45.2418740002913
                        ],
                        [
                            -70.8892309995801,
                            45.2385769999351
                        ],
                        [
                            -70.8847089999158,
                            45.2348080002706
                        ],
                        [
                            -70.8795770001561,
                            45.2333540001402
                        ],
                        [
                            -70.8747280002536,
                            45.2340820002585
                        ],
                        [
                            -70.8574030000559,
                            45.2292199997063
                        ],
                        [
                            -70.846086999677,
                            45.2343730000701
                        ],
                        [
                            -70.839177000408,
                            45.2342640002543
                        ],
                        [
                            -70.836713999562,
                            45.2362609997804
                        ],
                        [
                            -70.8481760004292,
                            45.2443349998358
                        ],
                        [
                            -70.8465050001517,
                            45.2529720001984
                        ],
                        [
                            -70.8495510004306,
                            45.2557720002087
                        ],
                        [
                            -70.8480950002383,
                            45.2590229999785
                        ],
                        [
                            -70.8489260002666,
                            45.2630420002904
                        ],
                        [
                            -70.8409420003968,
                            45.267293000212
                        ],
                        [
                            -70.8363910004202,
                            45.2759950000887
                        ],
                        [
                            -70.8319540002464,
                            45.2789509998866
                        ],
                        [
                            -70.8297530002395,
                            45.2900130003061
                        ],
                        [
                            -70.8355360001024,
                            45.293803999809
                        ],
                        [
                            -70.8247350002826,
                            45.2979160001794
                        ],
                        [
                            -70.8162740003868,
                            45.2981899998856
                        ],
                        [
                            -70.8115579996554,
                            45.3037070002001
                        ],
                        [
                            -70.814429999857,
                            45.3082380002864
                        ],
                        [
                            -70.8084419999547,
                            45.3118209999036
                        ],
                        [
                            -70.8061620003051,
                            45.3210790000005
                        ],
                        [
                            -70.8080860003025,
                            45.3249150001295
                        ],
                        [
                            -70.8076629998049,
                            45.327784000162
                        ],
                        [
                            -70.8128020001356,
                            45.3280539998445
                        ],
                        [
                            -70.8180999995767,
                            45.3324839996898
                        ],
                        [
                            -70.8181690000719,
                            45.3378470000427
                        ],
                        [
                            -70.8199309996877,
                            45.340664000146
                        ],
                        [
                            -70.8162740003868,
                            45.345700000138
                        ],
                        [
                            -70.809926999561,
                            45.3487490001012
                        ],
                        [
                            -70.8096610003205,
                            45.3516110000302
                        ],
                        [
                            -70.811985000351,
                            45.3524469998659
                        ],
                        [
                            -70.8143670001078,
                            45.3568289998811
                        ],
                        [
                            -70.8102599996469,
                            45.3578440002638
                        ],
                        [
                            -70.8084129996424,
                            45.3631340000977
                        ],
                        [
                            -70.8032480002707,
                            45.3649489999709
                        ],
                        [
                            -70.8027159999931,
                            45.3671269999514
                        ],
                        [
                            -70.8068030003626,
                            45.3775799999742
                        ],
                        [
                            -70.8123950004298,
                            45.3833739997993
                        ],
                        [
                            -70.813768999708,
                            45.3877180001247
                        ],
                        [
                            -70.8167410003671,
                            45.390182000153
                        ],
                        [
                            -70.823806000345,
                            45.3908840002636
                        ],
                        [
                            -70.8256630003953,
                            45.400179999983
                        ],
                        [
                            -70.8189280001302,
                            45.4047520001866
                        ],
                        [
                            -70.8127319998154,
                            45.4115649999614
                        ],
                        [
                            -70.8113559999891,
                            45.4161730001712
                        ],
                        [
                            -70.8025260000224,
                            45.4180909997852
                        ],
                        [
                            -70.7982069998493,
                            45.426480999725
                        ],
                        [
                            -70.7941329998986,
                            45.42838500021
                        ],
                        [
                            -70.787493999893,
                            45.4265679998656
                        ],
                        [
                            -70.7815770001358,
                            45.4312190000696
                        ],
                        [
                            -70.7679009997413,
                            45.4285379997072
                        ],
                        [
                            -70.7642119997551,
                            45.4260520002884
                        ],
                        [
                            -70.7545010004297,
                            45.4283889997077
                        ],
                        [
                            -70.7495179997346,
                            45.4229570002108
                        ],
                        [
                            -70.7440210001019,
                            45.4211239997544
                        ],
                        [
                            -70.7452460003155,
                            45.41615900016
                        ],
                        [
                            -70.7436450003583,
                            45.4119660001773
                        ],
                        [
                            -70.7304879998226,
                            45.4043759997694
                        ],
                        [
                            -70.7313359995694,
                            45.4017610001489
                        ],
                        [
                            -70.729635000053,
                            45.3991409998943
                        ],
                        [
                            -70.7121120003869,
                            45.3905740002761
                        ],
                        [
                            -70.6992519999522,
                            45.3945289997872
                        ],
                        [
                            -70.6961489997719,
                            45.3924869998674
                        ],
                        [
                            -70.6875990001876,
                            45.3929110000976
                        ],
                        [
                            -70.6829209999894,
                            45.3951809997623
                        ],
                        [
                            -70.6781850000648,
                            45.3942829999783
                        ],
                        [
                            -70.6617650004136,
                            45.3862459997139
                        ],
                        [
                            -70.6609259999894,
                            45.3782329999906
                        ],
                        [
                            -70.6518840003105,
                            45.3770409998262
                        ],
                        [
                            -70.6348920001988,
                            45.3834920000422
                        ],
                        [
                            -70.6389479997078,
                            45.3888399999777
                        ],
                        [
                            -70.6364429999273,
                            45.3951720000151
                        ],
                        [
                            -70.6333160003559,
                            45.398394000221
                        ],
                        [
                            -70.6235209995682,
                            45.4001409998926
                        ],
                        [
                            -70.6214619998516,
                            45.4025010002832
                        ],
                        [
                            -70.6245159996281,
                            45.4061480003054
                        ],
                        [
                            -70.6329539999576,
                            45.4086469999213
                        ],
                        [
                            -70.6357440001435,
                            45.4130000001506
                        ],
                        [
                            -70.634946999552,
                            45.415621999815
                        ],
                        [
                            -70.631476999849,
                            45.4157749998522
                        ],
                        [
                            -70.6279130004345,
                            45.4207449998421
                        ],
                        [
                            -70.6299160000747,
                            45.4263419997076
                        ],
                        [
                            -70.6358019998698,
                            45.427356000235
                        ],
                        [
                            -70.6406759998866,
                            45.4309809997893
                        ],
                        [
                            -70.6430579996434,
                            45.433567000025
                        ],
                        [
                            -70.6425510003786,
                            45.4362470000436
                        ],
                        [
                            -70.646230000319,
                            45.4377289997325
                        ],
                        [
                            -70.6506840002113,
                            45.4449150001049
                        ],
                        [
                            -70.6628719997281,
                            45.4459489998094
                        ],
                        [
                            -70.6747029997678,
                            45.451779999945
                        ],
                        [
                            -70.6811560000006,
                            45.4521760002155
                        ],
                        [
                            -70.6819540004169,
                            45.46184900027
                        ],
                        [
                            -70.6904169999626,
                            45.4636099997511
                        ],
                        [
                            -70.6927719999552,
                            45.4663480000176
                        ],
                        [
                            -70.6920949999127,
                            45.4746570001091
                        ],
                        [
                            -70.6995080000452,
                            45.47897099971
                        ],
                        [
                            -70.7049469999515,
                            45.4790379998511
                        ],
                        [
                            -70.7164010004227,
                            45.4864949997549
                        ],
                        [
                            -70.7177909995944,
                            45.4903539999461
                        ],
                        [
                            -70.7119550000281,
                            45.4969990002827
                        ],
                        [
                            -70.711659999577,
                            45.5018729999877
                        ],
                        [
                            -70.715325000172,
                            45.5045359997223
                        ],
                        [
                            -70.7225719997249,
                            45.5065469999621
                        ],
                        [
                            -70.7235940004472,
                            45.5099750002948
                        ],
                        [
                            -70.7224269999599,
                            45.5138669999158
                        ],
                        [
                            -70.7156850000221,
                            45.5189150002599
                        ],
                        [
                            -70.7110389996109,
                            45.5195430002018
                        ],
                        [
                            -70.7064559998473,
                            45.5279150003133
                        ],
                        [
                            -70.6991530002179,
                            45.5301210001278
                        ],
                        [
                            -70.7008470000616,
                            45.532414000107
                        ],
                        [
                            -70.6964590002916,
                            45.5400169999645
                        ],
                        [
                            -70.6868419997792,
                            45.5494309996987
                        ],
                        [
                            -70.6772820000665,
                            45.5519790002571
                        ],
                        [
                            -70.6890450003342,
                            45.5620990001724
                        ],
                        [
                            -70.6850299999347,
                            45.5668120003123
                        ],
                        [
                            -70.6877199996631,
                            45.5693550000748
                        ],
                        [
                            -70.6779669996066,
                            45.5705479998612
                        ],
                        [
                            -70.6721119997736,
                            45.5760430000877
                        ],
                        [
                            -70.6719269998257,
                            45.5786290001603
                        ],
                        [
                            -70.6640350000174,
                            45.5828219998975
                        ],
                        [
                            -70.6635400004481,
                            45.5847059997743
                        ],
                        [
                            -70.6592499996891,
                            45.5871179997432
                        ],
                        [
                            -70.6591600001757,
                            45.5896390000689
                        ],
                        [
                            -70.6538769999049,
                            45.5929610003122
                        ],
                        [
                            -70.6448750004091,
                            45.6067859997508
                        ],
                        [
                            -70.6361490001994,
                            45.6099179999938
                        ],
                        [
                            -70.6322869999609,
                            45.6091449998141
                        ],
                        [
                            -70.619254999997,
                            45.6120709997499
                        ],
                        [
                            -70.6170699998836,
                            45.6167919999131
                        ],
                        [
                            -70.6087169999429,
                            45.6218239998599
                        ],
                        [
                            -70.606884000182,
                            45.6257559999778
                        ],
                        [
                            -70.596746999986,
                            45.6297909998236
                        ],
                        [
                            -70.5911330001774,
                            45.6302880002131
                        ],
                        [
                            -70.5901580002091,
                            45.6335199999151
                        ],
                        [
                            -70.5838110002815,
                            45.6391080001637
                        ],
                        [
                            -70.5834029998525,
                            45.6424299997948
                        ],
                        [
                            -70.579836000065,
                            45.6434069997828
                        ],
                        [
                            -70.5754630003637,
                            45.6484259998448
                        ],
                        [
                            -70.5728179999428,
                            45.6488269997548
                        ],
                        [
                            -70.5647999997378,
                            45.6549280000159
                        ],
                        [
                            -70.5650280002417,
                            45.6571969997034
                        ],
                        [
                            -70.5620770003974,
                            45.6602669998127
                        ],
                        [
                            -70.5635040002774,
                            45.6627399998562
                        ],
                        [
                            -70.5582000000902,
                            45.6666469997799
                        ],
                        [
                            -70.5528549998952,
                            45.6679039997108
                        ],
                        [
                            -70.5468500002744,
                            45.6660359998473
                        ],
                        [
                            -70.5422959999247,
                            45.6664850000293
                        ],
                        [
                            -70.5338130002876,
                            45.6710280001104
                        ],
                        [
                            -70.5260310000842,
                            45.6664560000438
                        ],
                        [
                            -70.5199400002497,
                            45.669044000158
                        ],
                        [
                            -70.5197129995706,
                            45.6714820001907
                        ],
                        [
                            -70.5106869997853,
                            45.6792160000155
                        ],
                        [
                            -70.5023330000197,
                            45.6817840002997
                        ],
                        [
                            -70.5023999999668,
                            45.6832050000717
                        ],
                        [
                            -70.4964809996615,
                            45.6856589996952
                        ],
                        [
                            -70.4942980000963,
                            45.6886620001253
                        ],
                        [
                            -70.479562000243,
                            45.6947950001354
                        ],
                        [
                            -70.4690929997859,
                            45.701721000065
                        ],
                        [
                            -70.4705249996887,
                            45.7030749998777
                        ],
                        [
                            -70.4655519999377,
                            45.7064739999192
                        ],
                        [
                            -70.4613580003364,
                            45.7055560002454
                        ],
                        [
                            -70.4559720001335,
                            45.707046000233
                        ],
                        [
                            -70.4509340000851,
                            45.7039690001472
                        ],
                        [
                            -70.4452890003144,
                            45.7037030001351
                        ],
                        [
                            -70.4410169999971,
                            45.7053639997153
                        ],
                        [
                            -70.4391759998403,
                            45.703889000088
                        ],
                        [
                            -70.4289209996435,
                            45.7079780001559
                        ],
                        [
                            -70.425466000009,
                            45.7075569998952
                        ],
                        [
                            -70.4294119999131,
                            45.7103210001308
                        ],
                        [
                            -70.4004940002254,
                            45.7197429997626
                        ],
                        [
                            -70.4007140003334,
                            45.7216390001905
                        ],
                        [
                            -70.3979140001018,
                            45.7232940000453
                        ],
                        [
                            -70.3968139995617,
                            45.7288520002094
                        ],
                        [
                            -70.3902830004095,
                            45.7284900002823
                        ],
                        [
                            -70.3840150001246,
                            45.7342459998175
                        ],
                        [
                            -70.3858689998018,
                            45.73462899983
                        ],
                        [
                            -70.3855869997695,
                            45.7362209998409
                        ],
                        [
                            -70.390223000135,
                            45.7361550002673
                        ],
                        [
                            -70.3895319998488,
                            45.7373830000205
                        ],
                        [
                            -70.3940019996346,
                            45.7400830002485
                        ],
                        [
                            -70.3930030002751,
                            45.7414679997143
                        ],
                        [
                            -70.3945969996613,
                            45.7435549998611
                        ],
                        [
                            -70.3890899999829,
                            45.7471009998107
                        ],
                        [
                            -70.3885200000705,
                            45.7502759997964
                        ],
                        [
                            -70.3959809998836,
                            45.7566739999954
                        ],
                        [
                            -70.4008609997482,
                            45.7573840002293
                        ],
                        [
                            -70.407096000421,
                            45.7625549997902
                        ],
                        [
                            -70.4055380001215,
                            45.7700269997108
                        ],
                        [
                            -70.4077359997553,
                            45.7731999999118
                        ],
                        [
                            -70.4056770000387,
                            45.7765280000791
                        ],
                        [
                            -70.4102899999396,
                            45.7842259997802
                        ],
                        [
                            -70.4158460000218,
                            45.7853359997479
                        ],
                        [
                            -70.4144609999747,
                            45.7901680000786
                        ],
                        [
                            -70.4178970002409,
                            45.7939740001329
                        ],
                        [
                            -70.416970999778,
                            45.795533000056
                        ],
                        [
                            -70.4067330001979,
                            45.797828999926
                        ],
                        [
                            -70.4014509997521,
                            45.7959450002781
                        ],
                        [
                            -70.3962329997786,
                            45.7986690001327
                        ],
                        [
                            -70.3958190004002,
                            45.8025580002752
                        ],
                        [
                            -70.3979669998053,
                            45.8037830002972
                        ],
                        [
                            -70.3968699996382,
                            45.8079500001833
                        ],
                        [
                            -70.387946999785,
                            45.8142560000544
                        ],
                        [
                            -70.3880699998087,
                            45.818798999844
                        ],
                        [
                            -70.3768310003209,
                            45.8267979999702
                        ],
                        [
                            -70.3722290002907,
                            45.8280499996934
                        ],
                        [
                            -70.3693069998604,
                            45.8312489999348
                        ],
                        [
                            -70.3719320001898,
                            45.8340529999181
                        ],
                        [
                            -70.3708049998855,
                            45.8352269998178
                        ],
                        [
                            -70.3594280003055,
                            45.8354759998133
                        ],
                        [
                            -70.3561319997814,
                            45.8378860002233
                        ],
                        [
                            -70.3564809997608,
                            45.8392409999083
                        ],
                        [
                            -70.3523509997336,
                            45.8406859997738
                        ],
                        [
                            -70.3529009995545,
                            45.8424970002884
                        ],
                        [
                            -70.349194000025,
                            45.8471399998639
                        ],
                        [
                            -70.3419199998097,
                            45.8523109998614
                        ],
                        [
                            -70.3186949995732,
                            45.8557730001004
                        ],
                        [
                            -70.3077959998441,
                            45.8589089997924
                        ],
                        [
                            -70.3029700004061,
                            45.863338000182
                        ],
                        [
                            -70.2977370003641,
                            45.8644870001271
                        ],
                        [
                            -70.2842140001305,
                            45.8718309999539
                        ],
                        [
                            -70.2738529996284,
                            45.8797499997406
                        ],
                        [
                            -70.2726960000852,
                            45.8827560002663
                        ],
                        [
                            -70.2588260004203,
                            45.8911900000678
                        ],
                        [
                            -70.2657340000394,
                            45.8937329998274
                        ],
                        [
                            -70.2539330001369,
                            45.9023959997482
                        ],
                        [
                            -70.2533790001181,
                            45.9073389997682
                        ],
                        [
                            -70.2595429997475,
                            45.9096469997066
                        ],
                        [
                            -70.2571619998156,
                            45.9178319999398
                        ],
                        [
                            -70.2605500004012,
                            45.9204860000194
                        ],
                        [
                            -70.2625649997369,
                            45.920152999999
                        ],
                        [
                            -70.2630390002882,
                            45.9240410000655
                        ],
                        [
                            -70.2520839995843,
                            45.9333779996882
                        ],
                        [
                            -70.2395360002173,
                            45.9396799998709
                        ],
                        [
                            -70.240316000192,
                            45.942875999715
                        ],
                        [
                            -70.2391360001842,
                            45.9439149997311
                        ],
                        [
                            -70.2444400003714,
                            45.9446260000027
                        ],
                        [
                            -70.2422319997935,
                            45.9466890002172
                        ],
                        [
                            -70.2479989997629,
                            45.949605000102
                        ],
                        [
                            -70.2522440003161,
                            45.9553779998215
                        ],
                        [
                            -70.2561170004253,
                            45.95236499991
                        ],
                        [
                            -70.2605550004241,
                            45.9522530000842
                        ],
                        [
                            -70.2585340003423,
                            45.9548720002448
                        ],
                        [
                            -70.2601820001552,
                            45.9574730002801
                        ],
                        [
                            -70.2581429996317,
                            45.9590479998803
                        ],
                        [
                            -70.2618140000746,
                            45.9601369998641
                        ],
                        [
                            -70.2660570000796,
                            45.9642979999246
                        ],
                        [
                            -70.2714569996279,
                            45.9613350002043
                        ],
                        [
                            -70.2745559996102,
                            45.9613820000279
                        ],
                        [
                            -70.2751449997892,
                            45.9632650000342
                        ],
                        [
                            -70.2726890004125,
                            45.9643230000133
                        ],
                        [
                            -70.275627999663,
                            45.96680899977
                        ],
                        [
                            -70.2810980004298,
                            45.9640899998984
                        ],
                        [
                            -70.2867339999796,
                            45.964517000243
                        ],
                        [
                            -70.2897300000298,
                            45.9631330002646
                        ],
                        [
                            -70.2984310001251,
                            45.9628950002448
                        ],
                        [
                            -70.3042020002924,
                            45.9649719999158
                        ],
                        [
                            -70.3126129999595,
                            45.9621300003037
                        ],
                        [
                            -70.3161100003251,
                            45.9631430000121
                        ],
                        [
                            -70.3117459999462,
                            45.966275999898
                        ],
                        [
                            -70.3125660001038,
                            45.9696009999797
                        ],
                        [
                            -70.3103530004014,
                            45.9725880001751
                        ],
                        [
                            -70.3121660000707,
                            45.9742450000276
                        ],
                        [
                            -70.3071680002054,
                            45.9782989999357
                        ],
                        [
                            -70.309046000172,
                            45.9804329997237
                        ],
                        [
                            -70.3073840001154,
                            45.9825129997148
                        ],
                        [
                            -70.2997480004002,
                            45.985885000264
                        ],
                        [
                            -70.2949730001176,
                            45.9860570000488
                        ],
                        [
                            -70.2839849998017,
                            45.9954469997996
                        ],
                        [
                            -70.2884959995953,
                            45.9939869997187
                        ],
                        [
                            -70.2886749996955,
                            45.9952770000233
                        ],
                        [
                            -70.291114000252,
                            45.9947860002076
                        ],
                        [
                            -70.2903610000415,
                            45.9958160001712
                        ],
                        [
                            -70.2921510001447,
                            45.9971099998442
                        ],
                        [
                            -70.3027729998643,
                            45.9989870001105
                        ],
                        [
                            -70.3041000001852,
                            46.0012310002263
                        ],
                        [
                            -70.3027970001538,
                            46.0025420000241
                        ],
                        [
                            -70.3056740003783,
                            46.0044349999931
                        ],
                        [
                            -70.3064269996904,
                            46.0102720001047
                        ],
                        [
                            -70.3113619998066,
                            46.0120159999258
                        ],
                        [
                            -70.3105690003115,
                            46.0148739997078
                        ],
                        [
                            -70.3121280004359,
                            46.0165609999538
                        ],
                        [
                            -70.3146650000034,
                            46.0164149998131
                        ],
                        [
                            -70.3154309997344,
                            46.0183420002056
                        ],
                        [
                            -70.3180379996222,
                            46.0190270000956
                        ],
                        [
                            -70.3016000004276,
                            46.0275229998754
                        ],
                        [
                            -70.2985180001637,
                            46.0308650002447
                        ],
                        [
                            -70.3018749998889,
                            46.0316920001775
                        ],
                        [
                            -70.301978999646,
                            46.0332560000341
                        ],
                        [
                            -70.2972970001481,
                            46.0408379997027
                        ],
                        [
                            -70.2940459998298,
                            46.0413030000227
                        ],
                        [
                            -70.2895190001426,
                            46.0462770002566
                        ],
                        [
                            -70.2811390004377,
                            46.0506639997442
                        ],
                        [
                            -70.2797030003369,
                            46.0522919997527
                        ],
                        [
                            -70.28082499972,
                            46.0541869997952
                        ],
                        [
                            -70.2783559999245,
                            46.0564199997374
                        ],
                        [
                            -70.2788220000798,
                            46.0608110001512
                        ],
                        [
                            -70.2823250002932,
                            46.0602720000925
                        ],
                        [
                            -70.2843150004129,
                            46.0626760000589
                        ],
                        [
                            -70.2931500004025,
                            46.0605560002681
                        ],
                        [
                            -70.3008199995546,
                            46.0606079997996
                        ],
                        [
                            -70.3034170002949,
                            46.0622880000769
                        ],
                        [
                            -70.307291000229,
                            46.0611330002742
                        ],
                        [
                            -70.3108549996434,
                            46.0638860001302
                        ],
                        [
                            -70.3025569999543,
                            46.0704660001089
                        ],
                        [
                            -70.3052180002687,
                            46.0716150000382
                        ],
                        [
                            -70.3022189998455,
                            46.0732010003073
                        ],
                        [
                            -70.3029979999952,
                            46.0765270001142
                        ],
                        [
                            -70.3000300004325,
                            46.0785009997239
                        ],
                        [
                            -70.3024099996412,
                            46.0803910001814
                        ],
                        [
                            -70.3019109998739,
                            46.0825739997218
                        ],
                        [
                            -70.2916359995855,
                            46.087289999752
                        ],
                        [
                            -70.2905080003546,
                            46.0938009997688
                        ],
                        [
                            -70.2842850002756,
                            46.0978450002528
                        ],
                        [
                            -70.2859520003551,
                            46.0999229999453
                        ],
                        [
                            -70.2783130002668,
                            46.0999409997199
                        ],
                        [
                            -70.2732020004235,
                            46.1021789998154
                        ],
                        [
                            -70.2568999998747,
                            46.0994989997505
                        ],
                        [
                            -70.2528970000692,
                            46.100344000158
                        ],
                        [
                            -70.2521969995622,
                            46.1057479999971
                        ],
                        [
                            -70.2552880000469,
                            46.1088910001988
                        ],
                        [
                            -70.253528000081,
                            46.112605000201
                        ],
                        [
                            -70.2546119998293,
                            46.1137400002431
                        ],
                        [
                            -70.2522660000574,
                            46.1150130000848
                        ],
                        [
                            -70.2514419997018,
                            46.1195260000373
                        ],
                        [
                            -70.2481839997109,
                            46.1223790000245
                        ],
                        [
                            -70.247557999722,
                            46.1255220000806
                        ],
                        [
                            -70.2436169998407,
                            46.1286610001157
                        ],
                        [
                            -70.2439869997365,
                            46.1309160000106
                        ],
                        [
                            -70.2396790003325,
                            46.137856999718
                        ],
                        [
                            -70.2395250003467,
                            46.1431710001601
                        ],
                        [
                            -70.2362399996932,
                            46.1451490000314
                        ],
                        [
                            -70.2404190001242,
                            46.1490370001737
                        ],
                        [
                            -70.2404319996446,
                            46.1509530000186
                        ],
                        [
                            -70.2422979999157,
                            46.1497359997143
                        ],
                        [
                            -70.2437380002145,
                            46.1514849999506
                        ],
                        [
                            -70.2482609997037,
                            46.1518309997412
                        ],
                        [
                            -70.2475929998821,
                            46.1532160001997
                        ],
                        [
                            -70.2503610003266,
                            46.1542560002336
                        ],
                        [
                            -70.2495219999024,
                            46.1554810000127
                        ],
                        [
                            -70.2525620003334,
                            46.1582819998895
                        ],
                        [
                            -70.2552770001762,
                            46.1584329998695
                        ],
                        [
                            -70.2594719996024,
                            46.1631559999082
                        ],
                        [
                            -70.2648600003534,
                            46.1647980000622
                        ],
                        [
                            -70.2645409996129,
                            46.16751100008
                        ],
                        [
                            -70.2662939999061,
                            46.1692280001464
                        ],
                        [
                            -70.2777099998441,
                            46.174753000174
                        ],
                        [
                            -70.285957000378,
                            46.182904999929
                        ],
                        [
                            -70.285620999919,
                            46.1846009998512
                        ],
                        [
                            -70.2904050004225,
                            46.1850869998198
                        ],
                        [
                            -70.2898809996425,
                            46.1888280002577
                        ],
                        [
                            -70.292240999658,
                            46.191429000262
                        ],
                        [
                            -70.2857930003465,
                            46.1954259997833
                        ],
                        [
                            -70.2851699998323,
                            46.1975479998323
                        ],
                        [
                            -70.2771099997945,
                            46.2038120002489
                        ],
                        [
                            -70.2761259996053,
                            46.207485999935
                        ],
                        [
                            -70.2708049996998,
                            46.2108759997323
                        ],
                        [
                            -70.2713739997873,
                            46.2133889996998
                        ],
                        [
                            -70.2742429996158,
                            46.2146279999406
                        ],
                        [
                            -70.273431999679,
                            46.2167599997877
                        ],
                        [
                            -70.2699650003489,
                            46.2170409998387
                        ],
                        [
                            -70.2674429999517,
                            46.2193100001879
                        ],
                        [
                            -70.265605000168,
                            46.2235249997821
                        ],
                        [
                            -70.2671679995921,
                            46.2250999997902
                        ],
                        [
                            -70.2601600004139,
                            46.2302540000955
                        ],
                        [
                            -70.2575259998636,
                            46.2367719998881
                        ],
                        [
                            -70.2591960003162,
                            46.2387510001725
                        ],
                        [
                            -70.2540750004272,
                            46.2455490002325
                        ],
                        [
                            -70.2553089999633,
                            46.2461560000751
                        ],
                        [
                            -70.2508940004291,
                            46.2491159996919
                        ],
                        [
                            -70.2515660004487,
                            46.2520880002885
                        ],
                        [
                            -70.254589000263,
                            46.2539899999569
                        ],
                        [
                            -70.2529840001078,
                            46.255213000192
                        ],
                        [
                            -70.2529049995668,
                            46.2590889997533
                        ],
                        [
                            -70.2500580003779,
                            46.2616349997749
                        ],
                        [
                            -70.2487269998591,
                            46.2670449998781
                        ],
                        [
                            -70.2430550003242,
                            46.2717779998374
                        ],
                        [
                            -70.2433420003794,
                            46.2731070000171
                        ],
                        [
                            -70.2406669998213,
                            46.27307300009
                        ],
                        [
                            -70.2399250003797,
                            46.2791290002925
                        ],
                        [
                            -70.2326100001566,
                            46.2846500002097
                        ],
                        [
                            -70.2316879998916,
                            46.2910259997061
                        ],
                        [
                            -70.2169410001677,
                            46.2942080001875
                        ],
                        [
                            -70.2170049997418,
                            46.2954489998805
                        ],
                        [
                            -70.2153100000732,
                            46.2947299998305
                        ],
                        [
                            -70.2123640002518,
                            46.298495999782
                        ],
                        [
                            -70.2059269999125,
                            46.2996889999468
                        ],
                        [
                            -70.2061699995868,
                            46.309846999804
                        ],
                        [
                            -70.2030239997488,
                            46.314361999814
                        ],
                        [
                            -70.207337999899,
                            46.3195460002558
                        ],
                        [
                            -70.2060490001113,
                            46.3247340003017
                        ],
                        [
                            -70.2089890000849,
                            46.3295270000438
                        ],
                        [
                            -70.1957219999444,
                            46.3408179999384
                        ],
                        [
                            -70.1958340000974,
                            46.3432819997157
                        ],
                        [
                            -70.191349000243,
                            46.3478019999789
                        ],
                        [
                            -70.1912239996712,
                            46.3502339999018
                        ],
                        [
                            -70.1824549998039,
                            46.3518279998852
                        ],
                        [
                            -70.1805020003924,
                            46.3546470000144
                        ],
                        [
                            -70.1729079996116,
                            46.3592429998922
                        ],
                        [
                            -70.1660100000383,
                            46.3574409997903
                        ],
                        [
                            -70.1617300002233,
                            46.3610669998136
                        ],
                        [
                            -70.1586870003174,
                            46.3592800002353
                        ],
                        [
                            -70.1565840002199,
                            46.3614520002128
                        ],
                        [
                            -70.1480889999889,
                            46.3589999998479
                        ],
                        [
                            -70.1448639996099,
                            46.3621369999075
                        ],
                        [
                            -70.1405930000157,
                            46.3629900002479
                        ],
                        [
                            -70.1413389996552,
                            46.364872000139
                        ],
                        [
                            -70.1364410002473,
                            46.369879999916
                        ],
                        [
                            -70.133706000313,
                            46.3689080002595
                        ],
                        [
                            -70.1269400000858,
                            46.3708649998729
                        ],
                        [
                            -70.1281359999871,
                            46.3791510002428
                        ],
                        [
                            -70.1162189997336,
                            46.3859189998938
                        ],
                        [
                            -70.1166780002163,
                            46.3882000000478
                        ],
                        [
                            -70.1150110001368,
                            46.3879740001659
                        ],
                        [
                            -70.1142570001014,
                            46.3852000000528
                        ],
                        [
                            -70.1107840000252,
                            46.386178999962
                        ],
                        [
                            -70.1120829998587,
                            46.388155000039
                        ],
                        [
                            -70.1076950000886,
                            46.3878110000679
                        ],
                        [
                            -70.1098919998976,
                            46.3903000000905
                        ],
                        [
                            -70.106870999733,
                            46.3905550001622
                        ],
                        [
                            -70.1075789997377,
                            46.3926540000568
                        ],
                        [
                            -70.101685000445,
                            46.3970570000252
                        ],
                        [
                            -70.1009690000444,
                            46.4012489997317
                        ],
                        [
                            -70.098968999879,
                            46.4007920000502
                        ],
                        [
                            -70.1004669999041,
                            46.4031440000482
                        ],
                        [
                            -70.0977939998941,
                            46.4042049997145
                        ],
                        [
                            -70.1010119997021,
                            46.4049930000583
                        ],
                        [
                            -70.0952220001665,
                            46.4063979998889
                        ],
                        [
                            -70.0968060004053,
                            46.4070670000213
                        ],
                        [
                            -70.0945069995908,
                            46.4082409997154
                        ],
                        [
                            -70.0967529998035,
                            46.4095429998132
                        ],
                        [
                            -70.0891370001797,
                            46.4109020000148
                        ],
                        [
                            -70.0866859999276,
                            46.4091799997964
                        ],
                        [
                            -70.081301000448,
                            46.4106709997892
                        ],
                        [
                            -70.0765470000817,
                            46.4095549998237
                        ],
                        [
                            -70.0565380000034,
                            46.415555000163
                        ],
                        [
                            -69.9968880002354,
                            46.6957689999533
                        ],
                        [
                            -69.6358770001524,
                            47.0563380001419
                        ],
                        [
                            -69.2244959996317,
                            47.4598539998071
                        ],
                        [
                            -69.2178769997175,
                            47.4566140000109
                        ],
                        [
                            -69.2061419999373,
                            47.4540849999619
                        ],
                        [
                            -69.2052370002891,
                            47.4519400002515
                        ],
                        [
                            -69.1958220003414,
                            47.4554880003026
                        ],
                        [
                            -69.189975000006,
                            47.4535590001088
                        ],
                        [
                            -69.1790560001854,
                            47.4573559998655
                        ],
                        [
                            -69.1728859998099,
                            47.4556600001708
                        ],
                        [
                            -69.1726809997705,
                            47.4535279999279
                        ],
                        [
                            -69.1650340001846,
                            47.4515009997541
                        ],
                        [
                            -69.1632609997999,
                            47.4502890002054
                        ],
                        [
                            -69.1651680000789,
                            47.4492199997773
                        ],
                        [
                            -69.1624800000003,
                            47.4474600000183
                        ],
                        [
                            -69.1591359997956,
                            47.4484100000756
                        ],
                        [
                            -69.156595999855,
                            47.4516300002251
                        ],
                        [
                            -69.1505960002571,
                            47.4487450000963
                        ],
                        [
                            -69.1466689997212,
                            47.4490250002355
                        ],
                        [
                            -69.1411539996469,
                            47.4440020001106
                        ],
                        [
                            -69.1393750003127,
                            47.4466310000812
                        ],
                        [
                            -69.1268820002991,
                            47.4435690002998
                        ],
                        [
                            -69.1236879998822,
                            47.4406880000099
                        ],
                        [
                            -69.1223060002081,
                            47.442764000019
                        ],
                        [
                            -69.1179379996312,
                            47.4391239997457
                        ],
                        [
                            -69.1121430000727,
                            47.4374969998438
                        ],
                        [
                            -69.1126800003731,
                            47.4357959999213
                        ],
                        [
                            -69.1077710001962,
                            47.4352330001856
                        ],
                        [
                            -69.1067209998848,
                            47.4306089998462
                        ],
                        [
                            -69.1078380001434,
                            47.427710999731
                        ],
                        [
                            -69.105104000034,
                            47.427961000265
                        ],
                        [
                            -69.1042489997163,
                            47.4308369997138
                        ],
                        [
                            -69.1005870003926,
                            47.4301970000753
                        ],
                        [
                            -69.098913999567,
                            47.4314489997835
                        ],
                        [
                            -69.0972590000814,
                            47.4297770002991
                        ],
                        [
                            -69.0978959999409,
                            47.4270360002583
                        ],
                        [
                            -69.0912949995702,
                            47.4268389998537
                        ],
                        [
                            -69.0904920000293,
                            47.424199000283
                        ],
                        [
                            -69.0881830000674,
                            47.4255279998218
                        ],
                        [
                            -69.0813840002282,
                            47.4237419999997
                        ],
                        [
                            -69.0748299997131,
                            47.4272650001322
                        ],
                        [
                            -69.0684590003944,
                            47.4274219998946
                        ],
                        [
                            -69.066892999699,
                            47.4303160002534
                        ],
                        [
                            -69.0620210002303,
                            47.4305839998886
                        ],
                        [
                            -69.0641710001835,
                            47.432971999789
                        ],
                        [
                            -69.0630799998642,
                            47.4340839999376
                        ],
                        [
                            -69.0621600001475,
                            47.4325949997604
                        ],
                        [
                            -69.0557620001663,
                            47.4321210002891
                        ],
                        [
                            -69.0569549996945,
                            47.4299790002105
                        ],
                        [
                            -69.0546299998391,
                            47.4290980000132
                        ],
                        [
                            -69.0521719999143,
                            47.4299720000911
                        ],
                        [
                            -69.0531090002478,
                            47.428144999705
                        ],
                        [
                            -69.0486929999904,
                            47.424169999984
                        ],
                        [
                            -69.0528170001697,
                            47.422346000041
                        ],
                        [
                            -69.0466400001215,
                            47.4243370000622
                        ],
                        [
                            -69.0475709997089,
                            47.427720000184
                        ],
                        [
                            -69.0433870001534,
                            47.4267860001927
                        ],
                        [
                            -69.0424020001393,
                            47.4236189997751
                        ],
                        [
                            -69.039587999664,
                            47.42228900024
                        ],
                        [
                            -69.0393419996167,
                            47.4196520000072
                        ],
                        [
                            -69.0423579997584,
                            47.4156919999748
                        ],
                        [
                            -69.0347570002032,
                            47.4146330000749
                        ],
                        [
                            -69.0367310004294,
                            47.4076720000015
                        ],
                        [
                            -69.030988999676,
                            47.4090550002947
                        ],
                        [
                            -69.0306830002526,
                            47.4074080002306
                        ],
                        [
                            -69.0335100002483,
                            47.4044470002704
                        ],
                        [
                            -69.0317059999015,
                            47.4031379999338
                        ],
                        [
                            -69.0324990002949,
                            47.4022159997129
                        ],
                        [
                            -69.039563000448,
                            47.40374299983
                        ],
                        [
                            -69.0404580000504,
                            47.405668999889
                        ],
                        [
                            -69.0424149996598,
                            47.4051639998246
                        ],
                        [
                            -69.0398570001759,
                            47.4021690001643
                        ],
                        [
                            -69.0352779997118,
                            47.4012879997016
                        ],
                        [
                            -69.0400710004361,
                            47.4002379998725
                        ],
                        [
                            -69.0428389999823,
                            47.4019650001498
                        ],
                        [
                            -69.0419569999004,
                            47.3982360001071
                        ],
                        [
                            -69.0376569999939,
                            47.3968380001119
                        ],
                        [
                            -69.0400229998572,
                            47.3954599999374
                        ],
                        [
                            -69.0438630003544,
                            47.3974339998385
                        ],
                        [
                            -69.0455470001524,
                            47.3928850002422
                        ],
                        [
                            -69.0449820002628,
                            47.3886649998446
                        ],
                        [
                            -69.0402520001861,
                            47.3860119998373
                        ],
                        [
                            -69.0397519996956,
                            47.3842080002902
                        ],
                        [
                            -69.0422540000013,
                            47.3826329997802
                        ],
                        [
                            -69.0474820000205,
                            47.3834319998148
                        ],
                        [
                            -69.0509750001881,
                            47.3809649998712
                        ],
                        [
                            -69.0501109996495,
                            47.3785579999235
                        ],
                        [
                            -69.0549239995669,
                            47.3775790001418
                        ],
                        [
                            -69.0536330001294,
                            47.3404670002773
                        ],
                        [
                            -69.0549319999629,
                            47.3152980000262
                        ],
                        [
                            -69.0493440000936,
                            47.3058169997506
                        ],
                        [
                            -69.0507929997149,
                            47.3039440002211
                        ],
                        [
                            -69.0507450000343,
                            47.2995039997556
                        ],
                        [
                            -69.0535390004181,
                            47.2971449998877
                        ],
                        [
                            -69.0531989997612,
                            47.2927759999007
                        ],
                        [
                            -69.0512820003347,
                            47.2923450000875
                        ],
                        [
                            -69.0497740002639,
                            47.2892099999069
                        ],
                        [
                            -69.0492860003673,
                            47.2832670002477
                        ],
                        [
                            -69.0508830001266,
                            47.2784390000086
                        ],
                        [
                            -69.0469420002453,
                            47.2715659997097
                        ],
                        [
                            -69.0508170000043,
                            47.2564920002135
                        ],
                        [
                            -69.0409180003579,
                            47.2449729997162
                        ],
                        [
                            -69.0338039999762,
                            47.2403679998336
                        ],
                        [
                            -69.027113000092,
                            47.239339999901
                        ],
                        [
                            -68.9804280003407,
                            47.2201249999271
                        ],
                        [
                            -68.9786280001918,
                            47.2177039999538
                        ],
                        [
                            -68.965299999952,
                            47.2125090000977
                        ],
                        [
                            -68.9611869996432,
                            47.2056729998679
                        ],
                        [
                            -68.9528689998626,
                            47.2068899999796
                        ],
                        [
                            -68.950765999765,
                            47.2019979998617
                        ],
                        [
                            -68.941028999602,
                            47.206100999909
                        ],
                        [
                            -68.9342089998464,
                            47.2000910001485
                        ],
                        [
                            -68.9281969996546,
                            47.1986839999143
                        ],
                        [
                            -68.9300749996213,
                            47.1975600002595
                        ],
                        [
                            -68.928558000228,
                            47.1965690002949
                        ],
                        [
                            -68.9257060001179,
                            47.1978409998113
                        ],
                        [
                            -68.9208410003219,
                            47.1954890001953
                        ],
                        [
                            -68.9199170004071,
                            47.1898960002922
                        ],
                        [
                            -68.9085109996165,
                            47.186345999787
                        ],
                        [
                            -68.904980999639,
                            47.1804359998995
                        ],
                        [
                            -68.9000849998808,
                            47.1777190001802
                        ],
                        [
                            -68.8955799999349,
                            47.1824610002861
                        ],
                        [
                            -68.8821520001359,
                            47.1829069997412
                        ],
                        [
                            -68.8714340001568,
                            47.1889289998524
                        ],
                        [
                            -68.8574230000266,
                            47.1903249999666
                        ],
                        [
                            -68.8499489997947,
                            47.1962730002569
                        ],
                        [
                            -68.8314810002975,
                            47.2038779999799
                        ],
                        [
                            -68.8115290001206,
                            47.2151309997687
                        ],
                        [
                            -68.8042669996008,
                            47.2153659997045
                        ],
                        [
                            -68.7813769999984,
                            47.2210479999585
                        ],
                        [
                            -68.7643460004269,
                            47.2220439999947
                        ],
                        [
                            -68.7518929996979,
                            47.2263599998884
                        ],
                        [
                            -68.7343659998338,
                            47.2356789998661
                        ],
                        [
                            -68.7301460002933,
                            47.2356729998669
                        ],
                        [
                            -68.7170239999177,
                            47.2405409997629
                        ],
                        [
                            -68.7051069996643,
                            47.2378659997779
                        ],
                        [
                            -68.6872359998436,
                            47.2444320001651
                        ],
                        [
                            -68.6844869996657,
                            47.242885999706
                        ],
                        [
                            -68.6750039999459,
                            47.2423110001901
                        ],
                        [
                            -68.6632559997468,
                            47.2362210000457
                        ],
                        [
                            -68.6598770002803,
                            47.2360980002684
                        ],
                        [
                            -68.6554419997563,
                            47.2388479999835
                        ],
                        [
                            -68.6350929999194,
                            47.2423119997598
                        ],
                        [
                            -68.6172780001752,
                            47.2434459997584
                        ],
                        [
                            -68.6074289998592,
                            47.2470570001717
                        ],
                        [
                            -68.5941919998559,
                            47.2579530000137
                        ],
                        [
                            -68.5933180001699,
                            47.2613919999399
                        ],
                        [
                            -68.597917999652,
                            47.2674820001329
                        ],
                        [
                            -68.5977820001079,
                            47.2698749997527
                        ],
                        [
                            -68.5923130000643,
                            47.274224000064
                        ],
                        [
                            -68.5872859998866,
                            47.2826150001316
                        ],
                        [
                            -68.5778159996873,
                            47.2876290001959
                        ],
                        [
                            -68.5499309996942,
                            47.2821009997799
                        ],
                        [
                            -68.5400400004438,
                            47.2846669997839
                        ],
                        [
                            -68.5302100003943,
                            47.2920460002203
                        ],
                        [
                            -68.5186079997851,
                            47.2957650002547
                        ],
                        [
                            -68.508181999884,
                            47.2964349998023
                        ],
                        [
                            -68.4903860004065,
                            47.2938149999946
                        ],
                        [
                            -68.4748119999538,
                            47.2971599998382
                        ],
                        [
                            -68.4648349995913,
                            47.2939100001081
                        ],
                        [
                            -68.4584840003641,
                            47.2842880001395
                        ],
                        [
                            -68.448900000362,
                            47.2820020002353
                        ],
                        [
                            -68.4413249998478,
                            47.2836610000173
                        ],
                        [
                            -68.4298820001456,
                            47.2812929998308
                        ],
                        [
                            -68.4151370000714,
                            47.2874089998422
                        ],
                        [
                            -68.4100570001903,
                            47.2880080002768
                        ],
                        [
                            -68.3900420002642,
                            47.2859689999701
                        ],
                        [
                            -68.3779690003751,
                            47.2873799998271
                        ],
                        [
                            -68.3748190003391,
                            47.2909669998348
                        ],
                        [
                            -68.3763340000827,
                            47.2952129998217
                        ],
                        [
                            -68.3839720003461,
                            47.3011709999578
                        ],
                        [
                            -68.3848560000778,
                            47.3035649998199
                        ],
                        [
                            -68.3810459997178,
                            47.3093480002951
                        ],
                        [
                            -68.3846419998176,
                            47.3260319997701
                        ],
                        [
                            -68.3777409998711,
                            47.3435909998088
                        ],
                        [
                            -68.371959999658,
                            47.3492780001972
                        ],
                        [
                            -68.3630639995691,
                            47.3547090000458
                        ],
                        [
                            -68.3283320000562,
                            47.3600130002074
                        ],
                        [
                            -68.3033269997624,
                            47.3552210001285
                        ],
                        [
                            -68.2910200004199,
                            47.3597299999964
                        ],
                        [
                            -68.2850319996192,
                            47.3602910000226
                        ],
                        [
                            -68.2650269997389,
                            47.3521740000415
                        ],
                        [
                            -68.2479979998172,
                            47.3520860000924
                        ],
                        [
                            -68.2345130001167,
                            47.3552949997106
                        ],
                        [
                            -68.2276609996758,
                            47.3529769999496
                        ],
                        [
                            -68.2246549995799,
                            47.3501709999237
                        ],
                        [
                            -68.2237180001447,
                            47.3447299998344
                        ],
                        [
                            -68.2145750001835,
                            47.3396019999575
                        ],
                        [
                            -68.202369000225,
                            47.3393910000863
                        ],
                        [
                            -68.1763010000993,
                            47.3288860002135
                        ],
                        [
                            -68.1599779996341,
                            47.3270939998293
                        ],
                        [
                            -68.1548800002096,
                            47.3251849998656
                        ],
                        [
                            -68.1524049996681,
                            47.3220210001859
                        ],
                        [
                            -68.1538690002563,
                            47.3132989999669
                        ],
                        [
                            -68.1522799999946,
                            47.309593999787
                        ],
                        [
                            -68.1359280001154,
                            47.2956320002049
                        ],
                        [
                            -68.1275170004484,
                            47.2939980001444
                        ],
                        [
                            -68.1209560002606,
                            47.2874020002813
                        ],
                        [
                            -68.0920610001391,
                            47.2764460001585
                        ],
                        [
                            -68.0819049996764,
                            47.2709840001737
                        ],
                        [
                            -68.0740070000203,
                            47.2591910001436
                        ],
                        [
                            -68.0587690000266,
                            47.2549950000726
                        ],
                        [
                            -68.0166340004271,
                            47.2349159996959
                        ],
                        [
                            -67.9897779999307,
                            47.2099950001034
                        ],
                        [
                            -67.9731170002551,
                            47.2067339998731
                        ],
                        [
                            -67.9572759998387,
                            47.1995940002223
                        ],
                        [
                            -67.9519859998953,
                            47.1946280001022
                        ],
                        [
                            -67.9515049996714,
                            47.1834249999056
                        ],
                        [
                            -67.9388219996869,
                            47.1708089999785
                        ],
                        [
                            -67.9350969997157,
                            47.1636210001004
                        ],
                        [
                            -67.9274919999626,
                            47.1550329999655
                        ],
                        [
                            -67.90991300022,
                            47.1471640001988
                        ],
                        [
                            -67.9033860003674,
                            47.1364249998014
                        ],
                        [
                            -67.8942469997059,
                            47.1305419997617
                        ],
                        [
                            -67.8907419998427,
                            47.1264540002957
                        ],
                        [
                            -67.8881419996276,
                            47.1096780002567
                        ],
                        [
                            -67.8828950002402,
                            47.1043170001364
                        ],
                        [
                            -67.8487050001524,
                            47.0932969999644
                        ],
                        [
                            -67.8307179999808,
                            47.0849890000398
                        ],
                        [
                            -67.8210240003738,
                            47.0833369998327
                        ],
                        [
                            -67.8113950001659,
                            47.0760660001798
                        ],
                        [
                            -67.7903280002785,
                            47.0672019999213
                        ],
                        [
                            -67.7882340004018,
                            46.5999079997815
                        ],
                        [
                            -67.781853999964,
                            46.2592930001171
                        ],
                        [
                            -67.7812100004317,
                            45.9435889997248
                        ],
                        [
                            -67.7776550003398,
                            45.9341989999334
                        ],
                        [
                            -67.7735410002062,
                            45.9348749997649
                        ],
                        [
                            -67.771864000081,
                            45.9319860000553
                        ],
                        [
                            -67.7679630003827,
                            45.932151000302
                        ],
                        [
                            -67.7658279995997,
                            45.9304899997844
                        ],
                        [
                            -67.7661749999293,
                            45.9280370001461
                        ],
                        [
                            -67.7586699997353,
                            45.9264340001334
                        ],
                        [
                            -67.7583169995579,
                            45.923324999817
                        ],
                        [
                            -67.7537909996956,
                            45.9212960002213
                        ],
                        [
                            -67.7507250002236,
                            45.9175090000967
                        ],
                        [
                            -67.7554440004297,
                            45.9116470000624
                        ],
                        [
                            -67.7578570001487,
                            45.912277000208
                        ],
                        [
                            -67.7603460000357,
                            45.9096569997282
                        ],
                        [
                            -67.7652059998088,
                            45.9097730002229
                        ],
                        [
                            -67.7647139997142,
                            45.9080129998453
                        ],
                        [
                            -67.7694220000497,
                            45.9009440002271
                        ],
                        [
                            -67.7680110000632,
                            45.8981210000382
                        ],
                        [
                            -67.7763699998518,
                            45.8959509998022
                        ],
                        [
                            -67.7780820001371,
                            45.8975270000935
                        ],
                        [
                            -67.7792679999926,
                            45.8946320002753
                        ],
                        [
                            -67.7854740003532,
                            45.8955080000836
                        ],
                        [
                            -67.7967499996508,
                            45.8878870002684
                        ],
                        [
                            -67.7986889997169,
                            45.8843340002208
                        ],
                        [
                            -67.8037779998189,
                            45.8827410001563
                        ],
                        [
                            -67.8046759997943,
                            45.875153999952
                        ],
                        [
                            -67.8030299996312,
                            45.8703299996927
                        ],
                        [
                            -67.8042769995861,
                            45.8693440000642
                        ],
                        [
                            -67.7962669997771,
                            45.8656330000249
                        ],
                        [
                            -67.7983239998439,
                            45.8640609997424
                        ],
                        [
                            -67.795802000345,
                            45.8618839998606
                        ],
                        [
                            -67.7969449996445,
                            45.8601309997428
                        ],
                        [
                            -67.7871620003491,
                            45.8495960002135
                        ],
                        [
                            -67.7687619997256,
                            45.839040000184
                        ],
                        [
                            -67.7651060002497,
                            45.8342929998835
                        ],
                        [
                            -67.7636840003926,
                            45.8289310001813
                        ],
                        [
                            -67.7552560001088,
                            45.8233490002892
                        ],
                        [
                            -67.7655859997504,
                            45.8185739999568
                        ],
                        [
                            -67.7780400003043,
                            45.8192299997937
                        ],
                        [
                            -67.7805159997724,
                            45.8178070002454
                        ],
                        [
                            -67.7807400000784,
                            45.8154060002143
                        ],
                        [
                            -67.801989000439,
                            45.8030660000171
                        ],
                        [
                            -67.806121000116,
                            45.7948919998218
                        ],
                        [
                            -67.8037309999632,
                            45.7811230003075
                        ],
                        [
                            -67.8053190004,
                            45.7697260000953
                        ],
                        [
                            -67.8091570003491,
                            45.7673720000016
                        ],
                        [
                            -67.8061439996822,
                            45.7553160001068
                        ],
                        [
                            -67.7929920000676,
                            45.7505420000217
                        ],
                        [
                            -67.7817960002376,
                            45.731117000096
                        ],
                        [
                            -67.8096219997812,
                            45.7295740002121
                        ],
                        [
                            -67.8029340002701,
                            45.696087999736
                        ],
                        [
                            -67.8180080002322,
                            45.6938190002306
                        ],
                        [
                            -67.8035030003576,
                            45.6776959999952
                        ],
                        [
                            -67.7686570001436,
                            45.6775239998782
                        ],
                        [
                            -67.7543029998817,
                            45.6675749999959
                        ],
                        [
                            -67.7443809997708,
                            45.6729930001875
                        ],
                        [
                            -67.7324259998825,
                            45.6634009998594
                        ],
                        [
                            -67.7272229999777,
                            45.6626690002028
                        ],
                        [
                            -67.7247020003037,
                            45.6641529997939
                        ],
                        [
                            -67.7202380003656,
                            45.662284000205
                        ],
                        [
                            -67.7175679998304,
                            45.665089999901
                        ],
                        [
                            -67.7179569999929,
                            45.6676410002235
                        ],
                        [
                            -67.7235289999686,
                            45.6699899998241
                        ],
                        [
                            -67.7237569995742,
                            45.6722340001636
                        ],
                        [
                            -67.7300180001864,
                            45.6766780000804
                        ],
                        [
                            -67.7346319999122,
                            45.6889790000488
                        ],
                        [
                            -67.7273219997119,
                            45.6885739998082
                        ],
                        [
                            -67.7178370003423,
                            45.6794489997485
                        ],
                        [
                            -67.7094659999598,
                            45.6813799998336
                        ],
                        [
                            -67.7105790000205,
                            45.679314000008
                        ],
                        [
                            -67.7013859998306,
                            45.6691009999475
                        ],
                        [
                            -67.6979269999981,
                            45.6596200002815
                        ],
                        [
                            -67.6911549999232,
                            45.6535209999795
                        ],
                        [
                            -67.6922999997708,
                            45.6500870001533
                        ],
                        [
                            -67.6795420003416,
                            45.6380950002758
                        ],
                        [
                            -67.6751560002214,
                            45.6309329999188
                        ],
                        [
                            -67.6662269996222,
                            45.6242140002973
                        ],
                        [
                            -67.6631479997313,
                            45.6254110002981
                        ],
                        [
                            -67.645563000141,
                            45.613282000187
                        ],
                        [
                            -67.6397209998285,
                            45.6160250002302
                        ],
                        [
                            -67.6440130002374,
                            45.6230969999759
                        ],
                        [
                            -67.6388909996251,
                            45.6250749998802
                        ],
                        [
                            -67.6085050000495,
                            45.6061979997036
                        ],
                        [
                            -67.5897730000633,
                            45.6067649997206
                        ],
                        [
                            -67.5831030000956,
                            45.602281000262
                        ],
                        [
                            -67.561821999948,
                            45.5947189998809
                        ],
                        [
                            -67.55775499967,
                            45.5947810002098
                        ],
                        [
                            -67.5570419996425,
                            45.5969590002077
                        ],
                        [
                            -67.5482359999652,
                            45.5978419999251
                        ],
                        [
                            -67.5355549996306,
                            45.5951240002844
                        ],
                        [
                            -67.5192160001702,
                            45.5875680002575
                        ],
                        [
                            -67.501502999635,
                            45.5866800000279
                        ],
                        [
                            -67.4990060002505,
                            45.5866429997921
                        ],
                        [
                            -67.4914929996606,
                            45.5908820000434
                        ],
                        [
                            -67.4911150002671,
                            45.5931729999472
                        ],
                        [
                            -67.4880129999117,
                            45.5957189998284
                        ],
                        [
                            -67.4876870003968,
                            45.5969390002973
                        ],
                        [
                            -67.494045000195,
                            45.5989270001468
                        ],
                        [
                            -67.4933339998173,
                            45.601298000125
                        ],
                        [
                            -67.4859969998528,
                            45.6014080001119
                        ],
                        [
                            -67.4770420002126,
                            45.603877000257
                        ],
                        [
                            -67.4723289998543,
                            45.6005020001581
                        ],
                        [
                            -67.4684840002325,
                            45.6006259999101
                        ],
                        [
                            -67.4649540002549,
                            45.6034450000712
                        ],
                        [
                            -67.4566670004364,
                            45.6043100001543
                        ],
                        [
                            -67.4499770003772,
                            45.6024640000744
                        ],
                        [
                            -67.4473019998191,
                            45.5966089998044
                        ],
                        [
                            -67.445294000156,
                            45.5963749997645
                        ],
                        [
                            -67.4310550004202,
                            45.584113999919
                        ],
                        [
                            -67.4255810003537,
                            45.5783949999776
                        ],
                        [
                            -67.4232859997372,
                            45.5715669999876
                        ],
                        [
                            -67.4290519998817,
                            45.5685509999686
                        ],
                        [
                            -67.4266030001778,
                            45.5606000002876
                        ],
                        [
                            -67.4221419997145,
                            45.5565689996864
                        ],
                        [
                            -67.4227509999849,
                            45.5537109999436
                        ],
                        [
                            -67.420731999553,
                            45.5496950000495
                        ],
                        [
                            -67.4240010003129,
                            45.5469279998213
                        ],
                        [
                            -67.4251429997875,
                            45.5402149999994
                        ],
                        [
                            -67.4325359998285,
                            45.5404290001493
                        ],
                        [
                            -67.4348289998969,
                            45.5359109999461
                        ],
                        [
                            -67.4349550002936,
                            45.5282170001103
                        ],
                        [
                            -67.4318970003192,
                            45.5237869999784
                        ],
                        [
                            -67.432776000028,
                            45.5198339999592
                        ],
                        [
                            -67.4257849996699,
                            45.5171950002175
                        ],
                        [
                            -67.4265260001849,
                            45.5151480003065
                        ],
                        [
                            -67.4210089995624,
                            45.5117999997934
                        ],
                        [
                            -67.4162619997672,
                            45.501355999939
                        ],
                        [
                            -67.4182269997725,
                            45.5012800000638
                        ],
                        [
                            -67.422966999895,
                            45.50553500027
                        ],
                        [
                            -67.4253310001085,
                            45.5049419998838
                        ],
                        [
                            -67.427362000236,
                            45.5009600000935
                        ],
                        [
                            -67.4302589996537,
                            45.5010590000012
                        ],
                        [
                            -67.4307139999383,
                            45.5030989999284
                        ],
                        [
                            -67.4419809999135,
                            45.5052520002873
                        ],
                        [
                            -67.450155999579,
                            45.5103449998067
                        ],
                        [
                            -67.4535399999667,
                            45.5087710001571
                        ],
                        [
                            -67.4499649997833,
                            45.5051400000014
                        ],
                        [
                            -67.4497739999876,
                            45.4990659999157
                        ],
                        [
                            -67.4539099998625,
                            45.5003189998751
                        ],
                        [
                            -67.463599000345,
                            45.5083800000532
                        ],
                        [
                            -67.471128999755,
                            45.504137999997
                        ],
                        [
                            -67.4705429999491,
                            45.4996070002796
                        ],
                        [
                            -67.4636550004215,
                            45.4970439997073
                        ],
                        [
                            -67.4655960001373,
                            45.4959670002521
                        ],
                        [
                            -67.4776089997519,
                            45.4970140003048
                        ],
                        [
                            -67.4815570002042,
                            45.4939569998802
                        ],
                        [
                            -67.4854770001691,
                            45.4955349998076
                        ],
                        [
                            -67.4890489999794,
                            45.4930390001484
                        ],
                        [
                            -67.4880899999046,
                            45.4911500000192
                        ],
                        [
                            -67.4894769996016,
                            45.4891380001599
                        ],
                        [
                            -67.4950849995624,
                            45.4886770002298
                        ],
                        [
                            -67.5000719995571,
                            45.4908490002984
                        ],
                        [
                            -67.5034690003636,
                            45.48954800009
                        ],
                        [
                            -67.5009660002329,
                            45.4788890003126
                        ],
                        [
                            -67.48727300012,
                            45.4677669998546
                        ],
                        [
                            -67.482508999708,
                            45.4601569997612
                        ],
                        [
                            -67.482368999966,
                            45.4573099997166
                        ],
                        [
                            -67.4850160000367,
                            45.4556849999128
                        ],
                        [
                            -67.4847680003395,
                            45.4526480002858
                        ],
                        [
                            -67.4837270002489,
                            45.4494429999038
                        ],
                        [
                            -67.4768279999524,
                            45.4445179996868
                        ],
                        [
                            -67.4771879998025,
                            45.4421099997832
                        ],
                        [
                            -67.4803219999449,
                            45.4405359997295
                        ],
                        [
                            -67.478052000341,
                            45.4367619999865
                        ],
                        [
                            -67.477463999987,
                            45.4305430000939
                        ],
                        [
                            -67.4736720000686,
                            45.4244520001443
                        ],
                        [
                            -67.470539999576,
                            45.4227359999705
                        ],
                        [
                            -67.4610469998105,
                            45.4220999999864
                        ],
                        [
                            -67.4587720001838,
                            45.4152639999008
                        ],
                        [
                            -67.4273250004261,
                            45.3907209999367
                        ],
                        [
                            -67.4230239997964,
                            45.3794870001356
                        ],
                        [
                            -67.4192860003047,
                            45.3771220001355
                        ],
                        [
                            -67.4212179997998,
                            45.3735210000848
                        ],
                        [
                            -67.426773999882,
                            45.3737050001849
                        ],
                        [
                            -67.4302429997602,
                            45.3715929998576
                        ],
                        [
                            -67.434535000169,
                            45.3648960000923
                        ],
                        [
                            -67.4334539998955,
                            45.3606640001625
                        ],
                        [
                            -67.4253240004358,
                            45.3573970001186
                        ],
                        [
                            -67.4315180002025,
                            45.3496879998818
                        ],
                        [
                            -67.4288719999567,
                            45.344228000109
                        ],
                        [
                            -67.4312579999115,
                            45.3410699997447
                        ],
                        [
                            -67.4406159999578,
                            45.3360490001435
                        ],
                        [
                            -67.4437330003818,
                            45.3307630003009
                        ],
                        [
                            -67.450708999773,
                            45.32914699996
                        ],
                        [
                            -67.4569700003851,
                            45.3251590002051
                        ],
                        [
                            -67.4518849995827,
                            45.3176320002751
                        ],
                        [
                            -67.4534229997908,
                            45.3137059996855
                        ],
                        [
                            -67.4577889998195,
                            45.3096600000096
                        ],
                        [
                            -67.4603120000417,
                            45.3000180002304
                        ],
                        [
                            -67.4680389999935,
                            45.2989470001434
                        ],
                        [
                            -67.4686740002032,
                            45.2973529999211
                        ],
                        [
                            -67.4659119996065,
                            45.2968840001207
                        ],
                        [
                            -67.4660959997295,
                            45.2938260001381
                        ],
                        [
                            -67.4747159996339,
                            45.2916329998137
                        ],
                        [
                            -67.4761769998491,
                            45.2885240001144
                        ],
                        [
                            -67.4779169997235,
                            45.2882590001675
                        ],
                        [
                            -67.481238000362,
                            45.2910579998982
                        ],
                        [
                            -67.4850689997401,
                            45.2911309999534
                        ],
                        [
                            -67.4863640002739,
                            45.2898520002623
                        ],
                        [
                            -67.4858319999963,
                            45.2869420002462
                        ],
                        [
                            -67.4896320003106,
                            45.2821229999384
                        ],
                        [
                            -67.4890089997965,
                            45.2805300002237
                        ],
                        [
                            -67.4834779998286,
                            45.2794579999889
                        ],
                        [
                            -67.4821060002002,
                            45.2752939999579
                        ],
                        [
                            -67.4763719998428,
                            45.275371000283
                        ],
                        [
                            -67.4747919998019,
                            45.263320000074
                        ],
                        [
                            -67.4716789995759,
                            45.2616080002233
                        ],
                        [
                            -67.4729680002619,
                            45.258433999768
                        ],
                        [
                            -67.4684819996843,
                            45.2506689999793
                        ],
                        [
                            -67.4618879998845,
                            45.2426679998559
                        ],
                        [
                            -67.4537409998081,
                            45.2411629998173
                        ],
                        [
                            -67.4472950001464,
                            45.2330100002323
                        ],
                        [
                            -67.439287999812,
                            45.2262980000653
                        ],
                        [
                            -67.4278479995845,
                            45.2017380002162
                        ],
                        [
                            -67.4291029999354,
                            45.1933380003137
                        ],
                        [
                            -67.426769999684,
                            45.1915010002851
                        ],
                        [
                            -67.4181590000004,
                            45.1899469999352
                        ],
                        [
                            -67.4065089997106,
                            45.178830000061
                        ],
                        [
                            -67.4004489998383,
                            45.1776020001101
                        ],
                        [
                            -67.3982650004481,
                            45.1733219998051
                        ],
                        [
                            -67.3991280002635,
                            45.1688609998207
                        ],
                        [
                            -67.4047200003307,
                            45.1666299998705
                        ],
                        [
                            -67.4049360002408,
                            45.1599670000438
                        ],
                        [
                            -67.3928010004274,
                            45.1543509998853
                        ],
                        [
                            -67.3805579997607,
                            45.1521079998831
                        ],
                        [
                            -67.3704669995954,
                            45.1419049998198
                        ],
                        [
                            -67.3564529999904,
                            45.1329629996858
                        ],
                        [
                            -67.3574320001568,
                            45.1321809999433
                        ],
                        [
                            -67.3534000000389,
                            45.1291830001187
                        ],
                        [
                            -67.3489749995606,
                            45.1294800002987
                        ],
                        [
                            -67.3459559999442,
                            45.1265279998295
                        ],
                        [
                            -67.3409239997437,
                            45.1250920001548
                        ],
                        [
                            -67.3304049999562,
                            45.1317979998961
                        ],
                        [
                            -67.3210600003286,
                            45.1313270002762
                        ],
                        [
                            -67.317563999788,
                            45.1381129999997
                        ],
                        [
                            -67.3199249996284,
                            45.1424189997876
                        ],
                        [
                            -67.3094180004348,
                            45.1437399998149
                        ],
                        [
                            -67.2958809999575,
                            45.1479680000519
                        ],
                        [
                            -67.2941989998094,
                            45.1536180000947
                        ],
                        [
                            -67.3015449999947,
                            45.1569909999005
                        ],
                        [
                            -67.3026890000175,
                            45.1607840002763
                        ],
                        [
                            -67.299338999965,
                            45.1691780002418
                        ],
                        [
                            -67.2914699997229,
                            45.1710409996948
                        ],
                        [
                            -67.2911530004288,
                            45.1745870000965
                        ],
                        [
                            -67.2941279996643,
                            45.1772060002624
                        ],
                        [
                            -67.2907850001828,
                            45.1817020001154
                        ],
                        [
                            -67.2910549996212,
                            45.1875749999349
                        ],
                        [
                            -67.2835360000818,
                            45.1918230000527
                        ],
                        [
                            -67.2693729996157,
                            45.1905519998835
                        ],
                        [
                            -67.26219300001,
                            45.1879129999183
                        ],
                        [
                            -67.2553320002465,
                            45.1829209997234
                        ],
                        [
                            -67.2471969998657,
                            45.1810729998595
                        ],
                        [
                            -67.2439179999583,
                            45.1782360000358
                        ],
                        [
                            -67.2419989999837,
                            45.1717750001522
                        ],
                        [
                            -67.2331830002607,
                            45.1686549999959
                        ],
                        [
                            -67.2271939996351,
                            45.1634620001968
                        ],
                        [
                            -67.2230250001482,
                            45.1634970000528
                        ],
                        [
                            -67.2051130003197,
                            45.1715159998404
                        ],
                        [
                            -67.1908929999521,
                            45.1657150000197
                        ],
                        [
                            -67.1679649998165,
                            45.1647330003084
                        ],
                        [
                            -67.1588710002592,
                            45.162035000172
                        ],
                        [
                            -67.1449959996731,
                            45.1460159998988
                        ],
                        [
                            -67.1289080002829,
                            45.1323820002826
                        ],
                        [
                            -67.1125870003659,
                            45.1125700002324
                        ],
                        [
                            -67.0945969998212,
                            45.0747930003106
                        ],
                        [
                            -67.0216409995546,
                            44.9539249999904
                        ],
                        [
                            -67.0098560004439,
                            44.9447369999186
                        ],
                        [
                            -66.9970229997733,
                            44.9286059999062
                        ],
                        [
                            -66.9688430002274,
                            44.9104190000512
                        ],
                        [
                            -66.9723609996111,
                            44.8948540001978
                        ],
                        [
                            -66.9801210000732,
                            44.8775360001219
                        ],
                        [
                            -66.9821649997212,
                            44.8672200000989
                        ],
                        [
                            -66.9807389996662,
                            44.8605689999613
                        ],
                        [
                            -66.974717000327,
                            44.8509290002764
                        ],
                        [
                            -66.9747690002055,
                            44.8415140002278
                        ],
                        [
                            -66.9653170004478,
                            44.8289049997885
                        ],
                        [
                            -66.9325629995625,
                            44.8255080002527
                        ],
                        [
                            -66.8854440003412,
                            44.7942080001821
                        ],
                        [
                            -66.9022280000405,
                            44.7764289998079
                        ],
                        [
                            -66.9418410001412,
                            44.7656249998059
                        ],
                        [
                            -66.9522689996921,
                            44.7604220000516
                        ],
                        [
                            -66.9637430002548,
                            44.7500800000844
                        ],
                        [
                            -66.9775209998582,
                            44.7312410001757
                        ],
                        [
                            -67.01076500029,
                            44.7173220000806
                        ],
                        [
                            -67.0239649995851,
                            44.7050909999329
                        ],
                        [
                            -67.0392789997467,
                            44.6969679999218
                        ],
                        [
                            -67.0437850004158,
                            44.6879909997014
                        ],
                        [
                            -67.0502129996359,
                            44.6810479996947
                        ],
                        [
                            -67.0875179995996,
                            44.6534610002924
                        ],
                        [
                            -67.0932119997741,
                            44.6430810001434
                        ],
                        [
                            -67.1037580002241,
                            44.633426000017
                        ],
                        [
                            -67.1174980001926,
                            44.6252769999423
                        ],
                        [
                            -67.1274479998927,
                            44.6214169999611
                        ],
                        [
                            -67.1334450000158,
                            44.613760000188
                        ],
                        [
                            -67.1408539999504,
                            44.6082650002675
                        ],
                        [
                            -67.1570719999352,
                            44.6002060001068
                        ],
                        [
                            -67.1743190003151,
                            44.5947039997846
                        ],
                        [
                            -67.1878450000234,
                            44.58178199985
                        ],
                        [
                            -67.2232850004392,
                            44.5632260000961
                        ],
                        [
                            -67.2480520001834,
                            44.5548979998759
                        ],
                        [
                            -67.2636759999664,
                            44.554144999851
                        ],
                        [
                            -67.2792300003276,
                            44.5503169997396
                        ],
                        [
                            -67.3008200002716,
                            44.5498570001458
                        ],
                        [
                            -67.3041760001719,
                            44.5443820002243
                        ],
                        [
                            -67.3178409997974,
                            44.5315439999602
                        ],
                        [
                            -67.3404480004409,
                            44.5212889997526
                        ],
                        [
                            -67.3659979996345,
                            44.5178270002907
                        ],
                        [
                            -67.3882000002221,
                            44.5203989997328
                        ],
                        [
                            -67.4138780003605,
                            44.5096400002592
                        ],
                        [
                            -67.4433960000979,
                            44.5052789996965
                        ],
                        [
                            -67.4412560001904,
                            44.5000790001518
                        ],
                        [
                            -67.4417180001478,
                            44.4902330000769
                        ],
                        [
                            -67.4442270001262,
                            44.4811259997587
                        ],
                        [
                            -67.4552410003813,
                            44.4648730001465
                        ],
                        [
                            -67.466881999552,
                            44.4573170002982
                        ],
                        [
                            -67.4730269998132,
                            44.4483390000334
                        ],
                        [
                            -67.4994330000477,
                            44.4275489997476
                        ],
                        [
                            -67.5127849996788,
                            44.4206520002817
                        ],
                        [
                            -67.5495830003776,
                            44.4066240003167
                        ],
                        [
                            -67.553655999605,
                            44.4031699997695
                        ],
                        [
                            -67.5675849997195,
                            44.3972090003012
                        ],
                        [
                            -67.6100330002118,
                            44.3857829998802
                        ],
                        [
                            -67.6274129997628,
                            44.3834729998996
                        ],
                        [
                            -67.6549789999136,
                            44.3867070000283
                        ],
                        [
                            -67.6746420003855,
                            44.394634000207
                        ],
                        [
                            -67.6793859998077,
                            44.3918789999193
                        ],
                        [
                            -67.6958189998777,
                            44.3876959997903
                        ],
                        [
                            -67.7153290001887,
                            44.3865009999886
                        ],
                        [
                            -67.7295310001146,
                            44.3877020001591
                        ],
                        [
                            -67.7417439997458,
                            44.3908309998921
                        ],
                        [
                            -67.7585669998032,
                            44.3982620003029
                        ],
                        [
                            -67.7747499996279,
                            44.4113139999972
                        ],
                        [
                            -67.7883370003339,
                            44.4021890002913
                        ],
                        [
                            -67.7989940002137,
                            44.3983640002691
                        ],
                        [
                            -67.8055689997469,
                            44.3910150002114
                        ],
                        [
                            -67.7960419996462,
                            44.3795229997017
                        ],
                        [
                            -67.7952619996715,
                            44.361165000186
                        ],
                        [
                            -67.8007960000124,
                            44.3474849999041
                        ],
                        [
                            -67.8096899995532,
                            44.3365100002968
                        ],
                        [
                            -67.8237280003459,
                            44.3270380000068
                        ],
                        [
                            -67.8422860002548,
                            44.3204760001324
                        ],
                        [
                            -67.8583360000102,
                            44.3170239996977
                        ],
                        [
                            -67.874442999667,
                            44.3169249997155
                        ],
                        [
                            -67.8981509997772,
                            44.3221259998544
                        ],
                        [
                            -67.9166910001428,
                            44.3335119998533
                        ],
                        [
                            -67.9251779999778,
                            44.3414339998506
                        ],
                        [
                            -67.9333889996283,
                            44.3323079998603
                        ],
                        [
                            -67.9424119999389,
                            44.3260710001451
                        ],
                        [
                            -67.9607790000521,
                            44.3192650001854
                        ],
                        [
                            -67.9655750002511,
                            44.3059980002574
                        ],
                        [
                            -67.9736469999845,
                            44.2951589997223
                        ],
                        [
                            -67.9827099995797,
                            44.2883830000075
                        ],
                        [
                            -67.9994459995984,
                            44.280197000319
                        ],
                        [
                            -68.0181779995846,
                            44.2751069996918
                        ],
                        [
                            -68.0278439996025,
                            44.2742139997944
                        ],
                        [
                            -68.0609590001629,
                            44.2782559998272
                        ],
                        [
                            -68.1282799998063,
                            44.2642530002216
                        ],
                        [
                            -68.1244529997278,
                            44.2546270000084
                        ],
                        [
                            -68.1244529997278,
                            44.2500800002474
                        ],
                        [
                            -68.1217370000602,
                            44.2500820001338
                        ],
                        [
                            -68.1206599999846,
                            44.2443150000049
                        ],
                        [
                            -68.1226259998148,
                            44.2260410000106
                        ],
                        [
                            -68.1281469997369,
                            44.2172750000856
                        ],
                        [
                            -68.1375790003014,
                            44.2068599997239
                        ],
                        [
                            -68.1564900003877,
                            44.1948439999836
                        ],
                        [
                            -68.1707500000399,
                            44.1901140000275
                        ],
                        [
                            -68.1683880003746,
                            44.1729080000084
                        ],
                        [
                            -68.1712999998608,
                            44.1604969998291
                        ],
                        [
                            -68.1718949998876,
                            44.1396269998344
                        ],
                        [
                            -68.1758580004085,
                            44.1250829998383
                        ],
                        [
                            -68.1858130001314,
                            44.111962000224
                        ],
                        [
                            -68.2033600000869,
                            44.0995869998664
                        ],
                        [
                            -68.2302369996014,
                            44.0905009998812
                        ],
                        [
                            -68.2457819997417,
                            44.0891250002448
                        ],
                        [
                            -68.2631360002517,
                            44.0906169996816
                        ],
                        [
                            -68.2792119999463,
                            44.0699450001091
                        ],
                        [
                            -68.293712999623,
                            44.0608639997447
                        ],
                        [
                            -68.3113929996479,
                            44.0534289999245
                        ],
                        [
                            -68.3256520003736,
                            44.0507740002597
                        ],
                        [
                            -68.3356089997463,
                            44.0506589999193
                        ],
                        [
                            -68.3565819999223,
                            44.0458360002711
                        ],
                        [
                            -68.3687970001016,
                            44.0452199999768
                        ],
                        [
                            -68.4007879998324,
                            44.0505380000388
                        ],
                        [
                            -68.4368430003664,
                            44.0532330002664
                        ],
                        [
                            -68.4465589997147,
                            44.042583000113
                        ],
                        [
                            -68.4630119998762,
                            44.0321119998665
                        ],
                        [
                            -68.484944999952,
                            44.0283089999204
                        ],
                        [
                            -68.4874149995723,
                            44.0177830002112
                        ],
                        [
                            -68.4931060002721,
                            44.0105579997513
                        ],
                        [
                            -68.5307150000094,
                            43.9866209997241
                        ],
                        [
                            -68.5381499998832,
                            43.9769600001778
                        ],
                        [
                            -68.5472769999508,
                            43.9686199997283
                        ],
                        [
                            -68.5683880002191,
                            43.9579699997961
                        ],
                        [
                            -68.5941640002668,
                            43.9537300002251
                        ],
                        [
                            -68.6187259999716,
                            43.9558940000434
                        ],
                        [
                            -68.6410089998517,
                            43.9493619997111
                        ],
                        [
                            -68.6595860000272,
                            43.9479909998524
                        ],
                        [
                            -68.6896800004231,
                            43.9541989996879
                        ],
                        [
                            -68.7071910003936,
                            43.9638269997559
                        ],
                        [
                            -68.72147799981,
                            43.9619379997415
                        ],
                        [
                            -68.7426490003527,
                            43.943146999981
                        ],
                        [
                            -68.7261950003663,
                            43.9433759997376
                        ],
                        [
                            -68.7069980000497,
                            43.9391820002695
                        ],
                        [
                            -68.6869460003137,
                            43.929808999931
                        ],
                        [
                            -68.6792579998216,
                            43.9234550000591
                        ],
                        [
                            -68.6687209995924,
                            43.9088260002262
                        ],
                        [
                            -68.6637889998493,
                            43.8939259999841
                        ],
                        [
                            -68.6649870002987,
                            43.8805020001104
                        ],
                        [
                            -68.669432999795,
                            43.8695759996976
                        ],
                        [
                            -68.684659999918,
                            43.8546470000497
                        ],
                        [
                            -68.7127540001485,
                            43.8383990000039
                        ],
                        [
                            -68.7243149998515,
                            43.8345920001536
                        ],
                        [
                            -68.7494730003062,
                            43.8325309996777
                        ],
                        [
                            -68.7644929998417,
                            43.8190129999095
                        ],
                        [
                            -68.7853239997274,
                            43.804338999708
                        ],
                        [
                            -68.7818099996434,
                            43.7927239998787
                        ],
                        [
                            -68.7812189998146,
                            43.7851440002205
                        ],
                        [
                            -68.7822440000117,
                            43.7775370001158
                        ],
                        [
                            -68.7861389998622,
                            43.768363999897
                        ],
                        [
                            -68.7977780002814,
                            43.7528819997026
                        ],
                        [
                            -68.8053610002932,
                            43.7459829998563
                        ],
                        [
                            -68.8269160000771,
                            43.7357110001772
                        ],
                        [
                            -68.849101000048,
                            43.7323839999275
                        ],
                        [
                            -68.8706459997862,
                            43.7335649999898
                        ],
                        [
                            -68.8903069997099,
                            43.7394369998094
                        ],
                        [
                            -68.9049029998212,
                            43.7419390002999
                        ],
                        [
                            -68.9255179997969,
                            43.7500880002022
                        ],
                        [
                            -68.9381689999943,
                            43.7603379997548
                        ],
                        [
                            -68.9444359995559,
                            43.7678700001931
                        ],
                        [
                            -68.9498360000025,
                            43.7801699999009
                        ],
                        [
                            -68.9811599997365,
                            43.7837859998239
                        ],
                        [
                            -68.9966789999376,
                            43.7900570002561
                        ],
                        [
                            -69.0099219997887,
                            43.7984360000446
                        ],
                        [
                            -69.0179090000315,
                            43.8078540002431
                        ],
                        [
                            -69.0242950003171,
                            43.8198419996847
                        ],
                        [
                            -69.0247439998557,
                            43.8342109999417
                        ],
                        [
                            -69.0227839998732,
                            43.8425070000471
                        ],
                        [
                            -69.0342719997813,
                            43.8463820001577
                        ],
                        [
                            -69.0558940004107,
                            43.858910999679
                        ],
                        [
                            -69.0580109998537,
                            43.8524580001242
                        ],
                        [
                            -69.0649350002647,
                            43.8419080000874
                        ],
                        [
                            -69.0830050002769,
                            43.8256469998739
                        ],
                        [
                            -69.0942119999776,
                            43.8188989997923
                        ],
                        [
                            -69.105621000243,
                            43.8140930002797
                        ],
                        [
                            -69.1189000000791,
                            43.8109680003039
                        ],
                        [
                            -69.131014999801,
                            43.8031339997006
                        ],
                        [
                            -69.1498249996049,
                            43.7957350002545
                        ],
                        [
                            -69.1710420001784,
                            43.7931939996918
                        ],
                        [
                            -69.1971989999927,
                            43.7959920000959
                        ],
                        [
                            -69.2068240000027,
                            43.799958999901
                        ],
                        [
                            -69.2265629997442,
                            43.8122030001653
                        ],
                        [
                            -69.2365540003504,
                            43.8256259998144
                        ],
                        [
                            -69.2494900000549,
                            43.8191459999872
                        ],
                        [
                            -69.2623400004439,
                            43.8153739999681
                        ],
                        [
                            -69.2425349996818,
                            43.7997610000941
                        ],
                        [
                            -69.233276000268,
                            43.7809619999472
                        ],
                        [
                            -69.2333800000251,
                            43.7623159998983
                        ],
                        [
                            -69.2380969996831,
                            43.750084000323
                        ],
                        [
                            -69.2449359997052,
                            43.7407760002031
                        ],
                        [
                            -69.2620269995512,
                            43.7254260002583
                        ],
                        [
                            -69.2829479998486,
                            43.7137660000382
                        ],
                        [
                            -69.3089530002252,
                            43.7050120003232
                        ],
                        [
                            -69.3234899998869,
                            43.7036690000447
                        ],
                        [
                            -69.335093000321,
                            43.7044849997233
                        ],
                        [
                            -69.3563779997682,
                            43.7108800000724
                        ],
                        [
                            -69.3688609997361,
                            43.7185279999447
                        ],
                        [
                            -69.3875879996994,
                            43.736104999806
                        ],
                        [
                            -69.3972100002347,
                            43.7633710000343
                        ],
                        [
                            -69.3953130000015,
                            43.7802570001659
                        ],
                        [
                            -69.3872789999029,
                            43.7990159998507
                        ],
                        [
                            -69.4005789996555,
                            43.8045290002939
                        ],
                        [
                            -69.409269999705,
                            43.8108170001833
                        ],
                        [
                            -69.4698679996772,
                            43.7939009997096
                        ],
                        [
                            -69.48038399999,
                            43.7881589997957
                        ],
                        [
                            -69.505785000119,
                            43.7784560003113
                        ],
                        [
                            -69.5074909996582,
                            43.7723570002268
                        ],
                        [
                            -69.5158260000556,
                            43.7609749997006
                        ],
                        [
                            -69.5141620003492,
                            43.7500850002928
                        ],
                        [
                            -69.5190899998944,
                            43.7321219999
                        ],
                        [
                            -69.5244929998157,
                            43.7239739997204
                        ],
                        [
                            -69.5353079998793,
                            43.7145340001558
                        ],
                        [
                            -69.5509380004084,
                            43.7071440001584
                        ],
                        [
                            -69.5666180002679,
                            43.7028299997321
                        ],
                        [
                            -69.584573999579,
                            43.7019959999849
                        ],
                        [
                            -69.5967030004429,
                            43.7032900003002
                        ],
                        [
                            -69.6077560001578,
                            43.700399999914
                        ],
                        [
                            -69.6172880002814,
                            43.6998140000258
                        ],
                        [
                            -69.64404799962,
                            43.7035750000813
                        ],
                        [
                            -69.6647449996115,
                            43.7117480002183
                        ],
                        [
                            -69.6732680003298,
                            43.7041820001077
                        ],
                        [
                            -69.6863289997078,
                            43.6982000002101
                        ],
                        [
                            -69.6933929998608,
                            43.6833809998274
                        ],
                        [
                            -69.6992200001047,
                            43.6758600001791
                        ],
                        [
                            -69.7083370001266,
                            43.6682220003168
                        ],
                        [
                            -69.7303959997007,
                            43.6572730001028
                        ],
                        [
                            -69.7494880004353,
                            43.6539850001097
                        ],
                        [
                            -69.7757969996872,
                            43.6555860002887
                        ],
                        [
                            -69.7865759997657,
                            43.658131000159
                        ],
                        [
                            -69.79358999969,
                            43.6531399998062
                        ],
                        [
                            -69.8088260000338,
                            43.6473399996868
                        ],
                        [
                            -69.8275229998599,
                            43.6449589997481
                        ],
                        [
                            -69.8416739997322,
                            43.6449359997332
                        ],
                        [
                            -69.8552120000344,
                            43.6469469999507
                        ],
                        [
                            -70.1366060001037,
                            43.5334680002025
                        ],
                        [
                            -70.1559859998201,
                            43.5244439998032
                        ],
                        [
                            -70.1641460003153,
                            43.5185630002986
                        ],
                        [
                            -70.1997470003896,
                            43.4842280000854
                        ],
                        [
                            -70.2435959999243,
                            43.4483739997133
                        ],
                        [
                            -70.2596640001213,
                            43.4320130000021
                        ],
                        [
                            -70.2684329999887,
                            43.4263510001997
                        ],
                        [
                            -70.2739920004439,
                            43.4151530002962
                        ],
                        [
                            -70.2803970000978,
                            43.4087380002192
                        ],
                        [
                            -70.3028709997736,
                            43.3958970001823
                        ],
                        [
                            -70.3189480001915,
                            43.3901379997577
                        ],
                        [
                            -70.3217740003623,
                            43.3849570000601
                        ],
                        [
                            -70.3461849995561,
                            43.3581829996964
                        ],
                        [
                            -70.3467870001538,
                            43.3496230002765
                        ],
                        [
                            -70.3516369998812,
                            43.3391009999207
                        ],
                        [
                            -70.3673970001067,
                            43.3233170002447
                        ],
                        [
                            -70.3853780004305,
                            43.3152809999566
                        ],
                        [
                            -70.3967070003299,
                            43.3124650002021
                        ],
                        [
                            -70.4009319998933,
                            43.3082909997531
                        ],
                        [
                            -70.4218079999849,
                            43.2974949997369
                        ],
                        [
                            -70.4553140003575,
                            43.290346999694
                        ],
                        [
                            -70.4654740001199,
                            43.2894779999563
                        ],
                        [
                            -70.4732720002168,
                            43.29100100013
                        ],
                        [
                            -70.4932300002415,
                            43.2868290001052
                        ],
                        [
                            -70.4972880002986,
                            43.2743619997133
                        ],
                        [
                            -70.5021880002547,
                            43.268125000005
                        ],
                        [
                            -70.5148150001627,
                            43.2555650000373
                        ],
                        [
                            -70.5208309996541,
                            43.2521039998903
                        ],
                        [
                            -70.5123830001772,
                            43.2427110002116
                        ],
                        [
                            -70.5062780000989,
                            43.2249080002608
                        ],
                        [
                            -70.5063240001297,
                            43.2188299998524
                        ],
                        [
                            -70.5111849997277,
                            43.2012070000962
                        ],
                        [
                            -70.5134769999712,
                            43.1960759998118
                        ],
                        [
                            -70.519665999715,
                            43.1910149996977
                        ],
                        [
                            -70.5199400002497,
                            43.1829440003213
                        ],
                        [
                            -70.5229179998582,
                            43.1788939997406
                        ],
                        [
                            -70.5208540001187,
                            43.1745699998516
                        ],
                        [
                            -70.5216750001012,
                            43.1548829998588
                        ],
                        [
                            -70.4994940003284,
                            43.1668509998952
                        ],
                        [
                            -70.486361999907,
                            43.1702639996793
                        ],
                        [
                            -70.4649410000174,
                            43.1708019999008
                        ],
                        [
                            -70.4409380003543,
                            43.1666399997941
                        ],
                        [
                            -70.419643999788,
                            43.1525509999795
                        ],
                        [
                            -70.4072269999423,
                            43.1384389998072
                        ],
                        [
                            -70.4033959996659,
                            43.1250900001507
                        ],
                        [
                            -70.40412799996,
                            43.1093560001511
                        ],
                        [
                            -70.4062429997531,
                            43.1020919997945
                        ],
                        [
                            -70.421077000414,
                            43.0866530001218
                        ],
                        [
                            -70.4305710000045,
                            43.0802179997455
                        ],
                        [
                            -70.439771999692,
                            43.0765419996724
                        ],
                        [
                            -70.4590719999407,
                            43.0714780000425
                        ],
                        [
                            -70.4772420004104,
                            43.0710190003277
                        ],
                        [
                            -70.4930140003314,
                            43.0730429998546
                        ],
                        [
                            -70.5104340000654,
                            43.08011799988
                        ],
                        [
                            -70.5250800004053,
                            43.0892780001976
                        ],
                        [
                            -70.5343449996669,
                            43.0990549997305
                        ],
                        [
                            -70.5374229997328,
                            43.1058519998899
                        ],
                        [
                            -70.5404050004376,
                            43.1196379998976
                        ],
                        [
                            -70.5401269997049,
                            43.1305469998586
                        ],
                        [
                            -70.5557620002569,
                            43.1215049997631
                        ],
                        [
                            -70.5574200001155,
                            43.1146569996993
                        ],
                        [
                            -70.5619619998714,
                            43.1099010002424
                        ],
                        [
                            -70.5731600002495,
                            43.1005309998821
                        ],
                        [
                            -70.5821629995703,
                            43.0954869997693
                        ],
                        [
                            -70.5906260000142,
                            43.0673600002339
                        ],
                        [
                            -70.5996579996473,
                            43.0569390000191
                        ],
                        [
                            -70.5730699998378,
                            43.0528910003101
                        ],
                        [
                            -70.5583530002511,
                            43.0472639999225
                        ],
                        [
                            -70.5521329996468,
                            43.0430440000858
                        ],
                        [
                            -70.5421799995738,
                            43.0340389998187
                        ],
                        [
                            -70.5325420000433,
                            43.0199939996795
                        ],
                        [
                            -70.5288820003694,
                            43.0057690000118
                        ],
                        [
                            -70.5314830004094,
                            42.9941629998398
                        ],
                        [
                            -70.5347489998979,
                            42.9884789996952
                        ],
                        [
                            -70.5359880003552,
                            42.9561710002163
                        ],
                        [
                            -70.5402310003603,
                            42.944031999989
                        ],
                        [
                            -70.5529829999417,
                            42.9292530002493
                        ],
                        [
                            -70.5659410002858,
                            42.9208939999823
                        ],
                        [
                            -70.5750940002927,
                            42.9171259999352
                        ],
                        [
                            -70.5818549995987,
                            42.9383189998545
                        ],
                        [
                            -70.5875930001541,
                            42.9491880002418
                        ],
                        [
                            -70.6111520003015,
                            42.9789900001369
                        ],
                        [
                            -70.6307139995927,
                            42.9852859999178
                        ],
                        [
                            -70.701196999866,
                            43.0452400000711
                        ],
                        [
                            -70.7063100002574,
                            43.0753389998319
                        ],
                        [
                            -70.7379689997272,
                            43.0737280001796
                        ],
                        [
                            -70.7430329997148,
                            43.0779730002444
                        ],
                        [
                            -70.7564120000083,
                            43.0801770002731
                        ],
                        [
                            -70.7658920002534,
                            43.0924950001417
                        ],
                        [
                            -70.7796880002985,
                            43.09624800019
                        ],
                        [
                            -70.7838640003564,
                            43.1009590001538
                        ],
                        [
                            -70.8270800002295,
                            43.1273950001081
                        ],
                        [
                            -70.8336099995569,
                            43.1461059998015
                        ],
                        [
                            -70.8286540004226,
                            43.1598300000168
                        ],
                        [
                            -70.8277820003864,
                            43.1704320002037
                        ],
                        [
                            -70.8235689996202,
                            43.1742390000186
                        ],
                        [
                            -70.8282550002144,
                            43.1877889998515
                        ],
                        [
                            -70.8192820001325,
                            43.1929919999448
                        ],
                        [
                            -70.8206710003777,
                            43.1994919999146
                        ],
                        [
                            -70.8165659995666,
                            43.2113180002589
                        ],
                        [
                            -70.8169170000942,
                            43.2145299996835
                        ],
                        [
                            -70.8128730002807,
                            43.2173530000437
                        ],
                        [
                            -70.8135450003004,
                            43.2199690000072
                        ],
                        [
                            -70.8095309997258,
                            43.2247069998496
                        ],
                        [
                            -70.8122630001854,
                            43.228674000263
                        ],
                        [
                            -70.8172069996241,
                            43.2293459999841
                        ],
                        [
                            -70.8154819998183,
                            43.2335189999898
                        ],
                        [
                            -70.8178169997194,
                            43.2381350001759
                        ],
                        [
                            -70.8274309998588,
                            43.2433840001313
                        ],
                        [
                            -70.8379289997299,
                            43.2424379999633
                        ],
                        [
                            -70.8384099999539,
                            43.2472089999258
                        ],
                        [
                            -70.8410870001618,
                            43.2481290002733
                        ],
                        [
                            -70.8395759997179,
                            43.2513410003155
                        ],
                        [
                            -70.8428270000362,
                            43.2542249997925
                        ],
                        [
                            -70.852088999823,
                            43.2567270002983
                        ],
                        [
                            -70.8551099999875,
                            43.2550569999931
                        ],
                        [
                            -70.8574599999573,
                            43.255812000019
                        ],
                        [
                            -70.8628930000159,
                            43.2647989999878
                        ],
                        [
                            -70.870065000124,
                            43.2661340002131
                        ],
                        [
                            -70.8742910004106,
                            43.2718560001985
                        ],
                        [
                            -70.883096000263,
                            43.273031000308
                        ],
                        [
                            -70.8838129995901,
                            43.2771590000907
                        ],
                        [
                            -70.8826540003971,
                            43.2789129997163
                        ],
                        [
                            -70.8858429998928,
                            43.2808130000335
                        ],
                        [
                            -70.8836150001217,
                            43.2834379997644
                        ],
                        [
                            -70.8872000003509,
                            43.2827199998107
                        ],
                        [
                            -70.8967380003222,
                            43.2853450003097
                        ],
                        [
                            -70.8993169997226,
                            43.2878869999345
                        ],
                        [
                            -70.8963259996953,
                            43.2921040002
                        ],
                        [
                            -70.8973180002804,
                            43.2927219998638
                        ],
                        [
                            -70.9043520002962,
                            43.291211999754
                        ],
                        [
                            -70.9074789998676,
                            43.293329000218
                        ],
                        [
                            -70.9039399996692,
                            43.2967810002681
                        ],
                        [
                            -70.8977600001462,
                            43.296720000324
                        ],
                        [
                            -70.8963109996267,
                            43.2984369998396
                        ],
                        [
                            -70.9020480003572,
                            43.3013590002317
                        ],
                        [
                            -70.9014370004369,
                            43.3028469998112
                        ],
                        [
                            -70.9037260003073,
                            43.3062870001414
                        ],
                        [
                            -70.9070370000017,
                            43.3046619999858
                        ],
                        [
                            -70.9137520001753,
                            43.3088970001452
                        ],
                        [
                            -70.9112950000754,
                            43.3104380000572
                        ],
                        [
                            -70.9153999999882,
                            43.3154119999162
                        ],
                        [
                            -70.9117980000406,
                            43.3157330001601
                        ],
                        [
                            -70.9135539998086,
                            43.3181890003215
                        ],
                        [
                            -70.9114170002741,
                            43.3197230003129
                        ],
                        [
                            -70.9163609997129,
                            43.3196920001226
                        ],
                        [
                            -70.916804000302,
                            43.322071999884
                        ],
                        [
                            -70.9203590003939,
                            43.321210000078
                        ],
                        [
                            -70.9182539997481,
                            43.3230569999203
                        ],
                        [
                            -70.9191540002717,
                            43.3244450001577
                        ],
                        [
                            -70.9243870003138,
                            43.3238499998492
                        ],
                        [
                            -70.9248300000046,
                            43.3250169999269
                        ],
                        [
                            -70.9228159995955,
                            43.3260320003183
                        ],
                        [
                            -70.9262190002497,
                            43.3259710003186
                        ],
                        [
                            -70.9242040000157,
                            43.3272989998219
                        ],
                        [
                            -70.9308880002271,
                            43.3295490000764
                        ],
                        [
                            -70.9312389998564,
                            43.333326000018
                        ],
                        [
                            -70.9339550004224,
                            43.3353629999378
                        ],
                        [
                            -70.9307960001656,
                            43.3361639999375
                        ],
                        [
                            -70.9328259995699,
                            43.3372239997686
                        ],
                        [
                            -70.9374950004457,
                            43.3375369999535
                        ],
                        [
                            -70.9419970000185,
                            43.3352100002117
                        ],
                        [
                            -70.9461889999699,
                            43.3360259999592
                        ],
                        [
                            -70.9467570002325,
                            43.3347140001828
                        ],
                        [
                            -70.9501449999198,
                            43.3357060001095
                        ],
                        [
                            -70.9525099999582,
                            43.3333560000669
                        ],
                        [
                            -70.9562330002796,
                            43.3341110002321
                        ],
                        [
                            -70.9594989997682,
                            43.3409780001962
                        ],
                        [
                            -70.9633900003191,
                            43.3409090001632
                        ],
                        [
                            -70.9643059998379,
                            43.3432209998401
                        ],
                        [
                            -70.9679529999913,
                            43.3445790001509
                        ],
                        [
                            -70.9687920004155,
                            43.3464100002673
                        ],
                        [
                            -70.9665029996468,
                            43.3478060003074
                        ],
                        [
                            -70.9674490002011,
                            43.349239999869
                        ],
                        [
                            -70.9709430001936,
                            43.3504989998357
                        ],
                        [
                            -70.9714140003719,
                            43.3487689998135
                        ],
                        [
                            -70.973048999766,
                            43.3488129997515
                        ],
                        [
                            -70.9720269999419,
                            43.3546339999185
                        ],
                        [
                            -70.9760239998997,
                            43.3583649998006
                        ],
                        [
                            -70.9781000002331,
                            43.3583799998458
                        ],
                        [
                            -70.974590000347,
                            43.363575999818
                        ],
                        [
                            -70.9764819996591,
                            43.3672070001411
                        ],
                        [
                            -70.9810450002296,
                            43.370725000188
                        ],
                        [
                            -70.9810140002675,
                            43.373363999871
                        ],
                        [
                            -70.9833030001379,
                            43.3761949997502
                        ],
                        [
                            -70.9824029996143,
                            43.3778659999038
                        ],
                        [
                            -70.9856680001763,
                            43.3791780000226
                        ],
                        [
                            -70.9854699998096,
                            43.3863489998932
                        ],
                        [
                            -70.9878809998787,
                            43.389934999706
                        ],
                        [
                            -70.9871789997219,
                            43.3935360000029
                        ],
                        [
                            -70.9826629999053,
                            43.3945740000959
                        ],
                        [
                            -70.9822970002074,
                            43.3982819999671
                        ],
                        [
                            -70.9865080004254,
                            43.4039809997892
                        ],
                        [
                            -70.9859130003987,
                            43.4104809998347
                        ],
                        [
                            -70.9870869996603,
                            43.41307500023
                        ],
                        [
                            -70.9812729998353,
                            43.4208719999023
                        ],
                        [
                            -70.9756120001711,
                            43.4218869997034
                        ],
                        [
                            -70.9697070001094,
                            43.4269069996827
                        ],
                        [
                            -70.9687920004155,
                            43.4345970003084
                        ],
                        [
                            -70.9609790002499,
                            43.4383890001799
                        ],
                        [
                            -70.9615359997435,
                            43.443180999816
                        ],
                        [
                            -70.9658959999245,
                            43.4477939996915
                        ],
                        [
                            -70.9666259996704,
                            43.4512739998726
                        ],
                        [
                            -70.9603380001924,
                            43.4661829999976
                        ],
                        [
                            -70.961115999619,
                            43.4691880003171
                        ],
                        [
                            -70.9645800003726,
                            43.4733160002399
                        ],
                        [
                            -70.9703330000983,
                            43.4731329997651
                        ],
                        [
                            -70.9760399997932,
                            43.4784279998877
                        ],
                        [
                            -70.9721480003158,
                            43.4783279998567
                        ],
                        [
                            -70.967526000194,
                            43.4809449998056
                        ],
                        [
                            -70.9694180004044,
                            43.4856300002409
                        ],
                        [
                            -70.9682889995519,
                            43.4894519997477
                        ],
                        [
                            -70.9644280000366,
                            43.4934799998592
                        ],
                        [
                            -70.9643359999751,
                            43.4971420001785
                        ],
                        [
                            -70.9588429996421,
                            43.4993699996785
                        ],
                        [
                            -70.957973000154,
                            43.5080370002483
                        ],
                        [
                            -70.9546609997364,
                            43.509302999966
                        ],
                        [
                            -70.9567980001691,
                            43.5127820000159
                        ],
                        [
                            -70.9539440004092,
                            43.5222729999895
                        ],
                        [
                            -70.9567980001691,
                            43.5241040001286
                        ],
                        [
                            -70.9577589998938,
                            43.5314969998544
                        ],
                        [
                            -70.9624739999019,
                            43.5342049998297
                        ],
                        [
                            -70.9638020000477,
                            43.5393709999322
                        ],
                        [
                            -70.9614210001158,
                            43.5401939999212
                        ],
                        [
                            -70.9621540002348,
                            43.5410870000492
                        ],
                        [
                            -70.9587969996113,
                            43.5405989998916
                        ],
                        [
                            -70.9559129997142,
                            43.5420940000375
                        ],
                        [
                            -70.9535040001931,
                            43.540603000069
                        ],
                        [
                            -70.9536330000646,
                            43.5441669999888
                        ],
                        [
                            -70.9506319999915,
                            43.5513129998135
                        ],
                        [
                            -70.9549029995857,
                            43.5540450000849
                        ],
                        [
                            -70.9572529995555,
                            43.5613380000977
                        ],
                        [
                            -70.9628299995541,
                            43.5633939998711
                        ],
                        [
                            -70.9667119998842,
                            43.5694370001617
                        ],
                        [
                            -70.9698960002553,
                            43.5685540002723
                        ],
                        [
                            -70.9727590002361,
                            43.5703830002966
                        ],
                        [
                            -70.9834610003217,
                            43.7341769999253
                        ],
                        [
                            -70.9891219999859,
                            43.7921129999779
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 45.4092789,
            "name": "Maine",
            "intptlon": -68.6665979,
            "geo_point_2d": [
                45.2743814371,
                -69.2027978457
            ],
            "geoid": "23",
            "mtfcc": "G4000",
            "region": 1
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -69.2027978457,
                45.2743814371
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "c43437d10ddc8d3e51921ca4f4a1aec797e30509",
        "fields": {
            "arealand": "426389945",
            "objectid": 43,
            "basename": "Michigan",
            "stusab": "MI",
            "statens": "01779789",
            "centlon": -85.6650494,
            "state": "26",
            "gid": 30,
            "centlat": 44.8382251,
            "division": 3,
            "areawater": "952330638",
            "oid": "269148552",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -86.4641200001033,
                            45.3248100003068
                        ],
                        [
                            -86.7542360001843,
                            45.4436099997207
                        ],
                        [
                            -87.1011330002048,
                            45.4442300001973
                        ],
                        [
                            -87.1711700004143,
                            45.3525400001324
                        ],
                        [
                            -87.3154219996425,
                            45.2406569998735
                        ],
                        [
                            -87.405693999886,
                            45.2019690000849
                        ],
                        [
                            -87.44250799958,
                            45.0764339998307
                        ],
                        [
                            -87.5853880000782,
                            45.0969249999411
                        ],
                        [
                            -87.5918799997707,
                            45.0946890001531
                        ],
                        [
                            -87.6277169998465,
                            45.1033499999353
                        ],
                        [
                            -87.6315349997042,
                            45.1062239997108
                        ],
                        [
                            -87.6481910002553,
                            45.1063680001699
                        ],
                        [
                            -87.6525120000781,
                            45.1086330001328
                        ],
                        [
                            -87.6599519999748,
                            45.1075120003146
                        ],
                        [
                            -87.6612109996253,
                            45.108278999799
                        ],
                        [
                            -87.6612960000141,
                            45.1125659999724
                        ],
                        [
                            -87.6724469996383,
                            45.1212940002972
                        ],
                        [
                            -87.6747420002548,
                            45.1270179996965
                        ],
                        [
                            -87.6782089995848,
                            45.130083999938
                        ],
                        [
                            -87.6758159999574,
                            45.1350590003118
                        ],
                        [
                            -87.6839019999344,
                            45.1441349999698
                        ],
                        [
                            -87.6923750004241,
                            45.149505000148
                        ],
                        [
                            -87.7034919997131,
                            45.1522059996961
                        ],
                        [
                            -87.7073909997615,
                            45.1546789998452
                        ],
                        [
                            -87.709539000065,
                            45.158690000049
                        ],
                        [
                            -87.7179100004474,
                            45.1611910001891
                        ],
                        [
                            -87.7277680000859,
                            45.1695959998881
                        ],
                        [
                            -87.7361040003082,
                            45.172244000064
                        ],
                        [
                            -87.7352099996324,
                            45.1776420001462
                        ],
                        [
                            -87.739396999561,
                            45.1900460001596
                        ],
                        [
                            -87.7390069995736,
                            45.1927380001697
                        ],
                        [
                            -87.7418100001782,
                            45.197469000033
                        ],
                        [
                            -87.7363389995865,
                            45.2046529997849
                        ],
                        [
                            -87.7261980000908,
                            45.2093909997575
                        ],
                        [
                            -87.7269520001262,
                            45.2189490001487
                        ],
                        [
                            -87.7224730001196,
                            45.2233089997689
                        ],
                        [
                            -87.7213540002112,
                            45.226846999886
                        ],
                        [
                            -87.7251650003961,
                            45.2303320000636
                        ],
                        [
                            -87.7249920001438,
                            45.2321399999745
                        ],
                        [
                            -87.7182639995514,
                            45.2383329997877
                        ],
                        [
                            -87.7113390002138,
                            45.2399650001902
                        ],
                        [
                            -87.7099330002502,
                            45.2490330001536
                        ],
                        [
                            -87.7113149999244,
                            45.2523960000887
                        ],
                        [
                            -87.7077580001827,
                            45.2586369998842
                        ],
                        [
                            -87.7104499995609,
                            45.259323999974
                        ],
                        [
                            -87.7056430003895,
                            45.2661039997721
                        ],
                        [
                            -87.6987800000779,
                            45.2694199998907
                        ],
                        [
                            -87.6992289996165,
                            45.2767459999638
                        ],
                        [
                            -87.6973730002895,
                            45.2784440003108
                        ],
                        [
                            -87.6982479998004,
                            45.281511999802
                        ],
                        [
                            -87.6903409999235,
                            45.2902169997758
                        ],
                        [
                            -87.6874989998591,
                            45.2980550001359
                        ],
                        [
                            -87.6803300001241,
                            45.3028000000329
                        ],
                        [
                            -87.6790909996668,
                            45.3058410000504
                        ],
                        [
                            -87.6718890003198,
                            45.3081060001446
                        ],
                        [
                            -87.6726690002944,
                            45.3102129999194
                        ],
                        [
                            -87.6617110001158,
                            45.3207360001729
                        ],
                        [
                            -87.6616030001608,
                            45.327607999765
                        ],
                        [
                            -87.655775000092,
                            45.3308459999771
                        ],
                        [
                            -87.6481259999579,
                            45.339395999824
                        ],
                        [
                            -87.6477290002979,
                            45.3507209999549
                        ],
                        [
                            -87.650721999975,
                            45.3540049997784
                        ],
                        [
                            -87.6539189998666,
                            45.3545899998313
                        ],
                        [
                            -87.6564470001116,
                            45.3584130002631
                        ],
                        [
                            -87.6558040004043,
                            45.3627129999168
                        ],
                        [
                            -87.65735000011,
                            45.3687540000763
                        ],
                        [
                            -87.6735129998432,
                            45.3769459997674
                        ],
                        [
                            -87.6750169997161,
                            45.3824539998224
                        ],
                        [
                            -87.6828659998667,
                            45.3849500000657
                        ],
                        [
                            -87.6859339998869,
                            45.3887110001358
                        ],
                        [
                            -87.6939560002898,
                            45.3898929997954
                        ],
                        [
                            -87.70433799981,
                            45.3854639998156
                        ],
                        [
                            -87.70832899992,
                            45.3812180000181
                        ],
                        [
                            -87.7188910002635,
                            45.3774620000123
                        ],
                        [
                            -87.7334089996586,
                            45.3644320000473
                        ],
                        [
                            -87.7388230003489,
                            45.3568509997342
                        ],
                        [
                            -87.750928000025,
                            45.3550370000562
                        ],
                        [
                            -87.7514519999067,
                            45.3517550000818
                        ],
                        [
                            -87.7541040000003,
                            45.3494420000313
                        ],
                        [
                            -87.762128000053,
                            45.3484009997539
                        ],
                        [
                            -87.7692599999781,
                            45.3511959998355
                        ],
                        [
                            -87.7830760001147,
                            45.3497250000517
                        ],
                        [
                            -87.7903219998426,
                            45.3534509999846
                        ],
                        [
                            -87.8004640000616,
                            45.3536080002011
                        ],
                        [
                            -87.8100759996528,
                            45.3512690000964
                        ],
                        [
                            -87.8230280001491,
                            45.3526500000739
                        ],
                        [
                            -87.8269179999767,
                            45.350538000205
                        ],
                        [
                            -87.8326120001512,
                            45.3522489999908
                        ],
                        [
                            -87.8353029997045,
                            45.3509799996969
                        ],
                        [
                            -87.8381409995709,
                            45.3451009998029
                        ],
                        [
                            -87.8501330001674,
                            45.3404349999342
                        ],
                        [
                            -87.8514749996586,
                            45.3423350000568
                        ],
                        [
                            -87.8498989998157,
                            45.3446509997413
                        ],
                        [
                            -87.8504179996745,
                            45.3474920001447
                        ],
                        [
                            -87.8634889999965,
                            45.3530200002594
                        ],
                        [
                            -87.8657629997983,
                            45.356280000032
                        ],
                        [
                            -87.8653440003969,
                            45.3620959998174
                        ],
                        [
                            -87.8691670002774,
                            45.3660489997448
                        ],
                        [
                            -87.8729499999749,
                            45.3665259997648
                        ],
                        [
                            -87.8758449997428,
                            45.3650170002306
                        ],
                        [
                            -87.8697709996267,
                            45.3586159999008
                        ],
                        [
                            -87.8703670003767,
                            45.3562670002182
                        ],
                        [
                            -87.8811139997698,
                            45.3512779998885
                        ],
                        [
                            -87.8851700001771,
                            45.3517360000477
                        ],
                        [
                            -87.8880520004245,
                            45.3546969998267
                        ],
                        [
                            -87.8878280001185,
                            45.3581220000221
                        ],
                        [
                            -87.8848549996345,
                            45.3627919997412
                        ],
                        [
                            -87.8714849995618,
                            45.3715460001298
                        ],
                        [
                            -87.8756919995819,
                            45.3770520002411
                        ],
                        [
                            -87.8754239997933,
                            45.3793730001818
                        ],
                        [
                            -87.8709049996037,
                            45.3831160001107
                        ],
                        [
                            -87.8594180004188,
                            45.3882270001685
                        ],
                        [
                            -87.8568299998994,
                            45.3931059999985
                        ],
                        [
                            -87.8596030003668,
                            45.3964089998422
                        ],
                        [
                            -87.8591310003637,
                            45.3989670001105
                        ],
                        [
                            -87.8509690002186,
                            45.4019249998827
                        ],
                        [
                            -87.8493220002306,
                            45.4038719997532
                        ],
                        [
                            -87.8505330002005,
                            45.4116849997064
                        ],
                        [
                            -87.8562159996061,
                            45.4161009998967
                        ],
                        [
                            -87.8571239996272,
                            45.4201919999225
                        ],
                        [
                            -87.860431999847,
                            45.4235039997639
                        ],
                        [
                            -87.8616970002435,
                            45.4344730000813
                        ],
                        [
                            -87.8578409998527,
                            45.4370319997553
                        ],
                        [
                            -87.8552980004374,
                            45.4413789998479
                        ],
                        [
                            -87.8474290001953,
                            45.4441770002755
                        ],
                        [
                            -87.8448039998659,
                            45.4484060001437
                        ],
                        [
                            -87.8355620001706,
                            45.451102000302
                        ],
                        [
                            -87.8312170000583,
                            45.4571849999401
                        ],
                        [
                            -87.8255089996401,
                            45.4580260001996
                        ],
                        [
                            -87.8200399995965,
                            45.4604529999621
                        ],
                        [
                            -87.8202639999025,
                            45.4623140000744
                        ],
                        [
                            -87.8129760003418,
                            45.4641599999258
                        ],
                        [
                            -87.8114690000959,
                            45.4679910001881
                        ],
                        [
                            -87.8057730002716,
                            45.473139000186
                        ],
                        [
                            -87.8068910003551,
                            45.4790920000008
                        ],
                        [
                            -87.7989600001887,
                            45.4851470000437
                        ],
                        [
                            -87.798172999643,
                            45.4936659998174
                        ],
                        [
                            -87.7927689998968,
                            45.4999670000331
                        ],
                        [
                            -87.7932149999606,
                            45.5050280001222
                        ],
                        [
                            -87.798793999609,
                            45.5062870000136
                        ],
                        [
                            -87.8022409997459,
                            45.5130589999292
                        ],
                        [
                            -87.8047199995871,
                            45.5312439997197
                        ],
                        [
                            -87.8033899997915,
                            45.5382719997737
                        ],
                        [
                            -87.8083659999156,
                            45.544661999725
                        ],
                        [
                            -87.8170889997522,
                            45.5495119999311
                        ],
                        [
                            -87.8187909999918,
                            45.5521000003023
                        ],
                        [
                            -87.8322959997837,
                            45.558767000069
                        ],
                        [
                            -87.8336900000517,
                            45.5615559998698
                        ],
                        [
                            -87.8316909997112,
                            45.5680359998609
                        ],
                        [
                            -87.8293480003124,
                            45.5687769998378
                        ],
                        [
                            -87.8104199996094,
                            45.5653079999593
                        ],
                        [
                            -87.8061039998094,
                            45.5628629999757
                        ],
                        [
                            -87.7923720002367,
                            45.5630550000346
                        ],
                        [
                            -87.7887979998783,
                            45.5659470001969
                        ],
                        [
                            -87.7867659999258,
                            45.5828280003118
                        ],
                        [
                            -87.7771989996421,
                            45.5884980001985
                        ],
                        [
                            -87.7775040001389,
                            45.5939080002816
                        ],
                        [
                            -87.7746820001661,
                            45.6020240002011
                        ],
                        [
                            -87.777936999784,
                            45.6044400002782
                        ],
                        [
                            -87.7776709996452,
                            45.6092039998595
                        ],
                        [
                            -87.7808449999706,
                            45.6145990000084
                        ],
                        [
                            -87.7958799995747,
                            45.6188459999214
                        ],
                        [
                            -87.7969829995895,
                            45.6236129996865
                        ],
                        [
                            -87.8045199995706,
                            45.6289870002256
                        ],
                        [
                            -87.8101950003768,
                            45.638732000041
                        ],
                        [
                            -87.8243920002798,
                            45.6477100001141
                        ],
                        [
                            -87.8246779996118,
                            45.6532119998244
                        ],
                        [
                            -87.8224590000616,
                            45.6571510001418
                        ],
                        [
                            -87.8237009999936,
                            45.6596969997617
                        ],
                        [
                            -87.8223779998707,
                            45.6609969998294
                        ],
                        [
                            -87.8241910004384,
                            45.6620439997229
                        ],
                        [
                            -87.8032900002324,
                            45.6664939997836
                        ],
                        [
                            -87.7972780000406,
                            45.6708380002225
                        ],
                        [
                            -87.7816230002954,
                            45.6732800002585
                        ],
                        [
                            -87.7808080001606,
                            45.680348999946
                        ],
                        [
                            -87.7822259998198,
                            45.6830529998987
                        ],
                        [
                            -87.8018800000709,
                            45.6938619998644
                        ],
                        [
                            -87.8090749997452,
                            45.6997170002114
                        ],
                        [
                            -87.8050810001604,
                            45.7049739999217
                        ],
                        [
                            -87.8101440003231,
                            45.7102299997308
                        ],
                        [
                            -87.8373449997027,
                            45.7169179998407
                        ],
                        [
                            -87.8554800000123,
                            45.7269429997046
                        ],
                        [
                            -87.8643200000248,
                            45.7371389999014
                        ],
                        [
                            -87.8622110000794,
                            45.7450180001478
                        ],
                        [
                            -87.8656809997825,
                            45.7462880001237
                        ],
                        [
                            -87.8681110001182,
                            45.7494769998507
                        ],
                        [
                            -87.8733390001374,
                            45.7504389998892
                        ],
                        [
                            -87.8768980004272,
                            45.7543790001584
                        ],
                        [
                            -87.8983629997995,
                            45.752502999931
                        ],
                        [
                            -87.9027070000869,
                            45.757932000193
                        ],
                        [
                            -87.9066599996637,
                            45.7595240000867
                        ],
                        [
                            -87.9113360002122,
                            45.7565389999263
                        ],
                        [
                            -87.9165559998354,
                            45.759461000012
                        ],
                        [
                            -87.9219939999169,
                            45.7569959999745
                        ],
                        [
                            -87.9291269996669,
                            45.7603680000667
                        ],
                        [
                            -87.93458300019,
                            45.7580960001468
                        ],
                        [
                            -87.9454779997211,
                            45.756687000268
                        ],
                        [
                            -87.952182999849,
                            45.7584160001364
                        ],
                        [
                            -87.9634520003722,
                            45.7582200002352
                        ],
                        [
                            -87.9669699997559,
                            45.7640210002013
                        ],
                        [
                            -87.9864290000133,
                            45.7695960000422
                        ],
                        [
                            -87.9896560000421,
                            45.7720249998198
                        ],
                        [
                            -87.9855970001601,
                            45.7749260000002
                        ],
                        [
                            -87.9817890003482,
                            45.7750810003019
                        ],
                        [
                            -87.980869999558,
                            45.7769769997249
                        ],
                        [
                            -87.987942000107,
                            45.7930750001163
                        ],
                        [
                            -87.9914469999702,
                            45.7953930001202
                        ],
                        [
                            -88.0070430001641,
                            45.7921919997928
                        ],
                        [
                            -88.0175879998909,
                            45.7924549997333
                        ],
                        [
                            -88.0272320001675,
                            45.7891969996986
                        ],
                        [
                            -88.0397279996559,
                            45.789626000028
                        ],
                        [
                            -88.040936999976,
                            45.7842650001216
                        ],
                        [
                            -88.0499600002865,
                            45.7811930000741
                        ],
                        [
                            -88.0721669999986,
                            45.7800850000952
                        ],
                        [
                            -88.0799759999663,
                            45.7851239999016
                        ],
                        [
                            -88.0947539996524,
                            45.7857549999565
                        ],
                        [
                            -88.099420000155,
                            45.7899900000908
                        ],
                        [
                            -88.1032129998983,
                            45.7912549997249
                        ],
                        [
                            -88.1076080002394,
                            45.8030399997952
                        ],
                        [
                            -88.1162659997786,
                            45.8040859997274
                        ],
                        [
                            -88.1301289997708,
                            45.8097079997687
                        ],
                        [
                            -88.1360330000076,
                            45.8180909997827
                        ],
                        [
                            -88.1345170004392,
                            45.8224569998998
                        ],
                        [
                            -88.1209010003192,
                            45.8329620001566
                        ],
                        [
                            -88.1051960003453,
                            45.8424130002237
                        ],
                        [
                            -88.0977580000984,
                            45.8504379997075
                        ],
                        [
                            -88.0877189998116,
                            45.8569500002183
                        ],
                        [
                            -88.0824649998532,
                            45.8649610002249
                        ],
                        [
                            -88.0776529997607,
                            45.8637440001659
                        ],
                        [
                            -88.074828000313,
                            45.8649050002919
                        ],
                        [
                            -88.0758380004415,
                            45.8696300002843
                        ],
                        [
                            -88.0697629996022,
                            45.8729380002808
                        ],
                        [
                            -88.0818199995977,
                            45.8802009998346
                        ],
                        [
                            -88.0964960000749,
                            45.8798610003058
                        ],
                        [
                            -88.1012639997865,
                            45.8822819999599
                        ],
                        [
                            -88.102526999635,
                            45.8922220002934
                        ],
                        [
                            -88.1067579999445,
                            45.8985290002783
                        ],
                        [
                            -88.1066240000502,
                            45.9022129999457
                        ],
                        [
                            -88.1022520001737,
                            45.9099419999008
                        ],
                        [
                            -88.0953140004173,
                            45.9136570001505
                        ],
                        [
                            -88.0949269999047,
                            45.9153230001605
                        ],
                        [
                            -88.102461000411,
                            45.9215460002408
                        ],
                        [
                            -88.1266540000449,
                            45.9217909999045
                        ],
                        [
                            -88.1268080000306,
                            45.9259939998955
                        ],
                        [
                            -88.1412500001561,
                            45.9308439998302
                        ],
                        [
                            -88.1463340002352,
                            45.9340690001505
                        ],
                        [
                            -88.145710999721,
                            45.9362429999387
                        ],
                        [
                            -88.147650999612,
                            45.9374270000193
                        ],
                        [
                            -88.1556389996798,
                            45.9373520001743
                        ],
                        [
                            -88.1617610003747,
                            45.9407410001564
                        ],
                        [
                            -88.1638579997262,
                            45.9383810001666
                        ],
                        [
                            -88.1700890002011,
                            45.9394919998019
                        ],
                        [
                            -88.1780410002839,
                            45.9470930000428
                        ],
                        [
                            -88.1920580002619,
                            45.9526509998436
                        ],
                        [
                            -88.197759999934,
                            45.9530190001506
                        ],
                        [
                            -88.2037970002401,
                            45.9479410002115
                        ],
                        [
                            -88.224222000245,
                            45.948607000036
                        ],
                        [
                            -88.2320439997331,
                            45.9472139997886
                        ],
                        [
                            -88.2371060000709,
                            45.9482780001597
                        ],
                        [
                            -88.2448330000228,
                            45.9528639998965
                        ],
                        [
                            -88.2461789997119,
                            45.9560830000881
                        ],
                        [
                            -88.245708000432,
                            45.9627350002287
                        ],
                        [
                            -88.2468940002876,
                            45.9635320002813
                        ],
                        [
                            -88.2549800002646,
                            45.9635120003003
                        ],
                        [
                            -88.2590259997279,
                            45.9596499997501
                        ],
                        [
                            -88.2676399997845,
                            45.9578940002487
                        ],
                        [
                            -88.269306999864,
                            45.9562719999505
                        ],
                        [
                            -88.2853139999617,
                            45.9552619997466
                        ],
                        [
                            -88.2938490003756,
                            45.9509410002592
                        ],
                        [
                            -88.3013779999607,
                            45.9564590001018
                        ],
                        [
                            -88.3130209996795,
                            45.9594310001757
                        ],
                        [
                            -88.3203080003136,
                            45.9636569998425
                        ],
                        [
                            -88.3202439998412,
                            45.9600840003042
                        ],
                        [
                            -88.3272760002072,
                            45.9549570000979
                        ],
                        [
                            -88.3301719997999,
                            45.956631999957
                        ],
                        [
                            -88.3278760002568,
                            45.9589150002542
                        ],
                        [
                            -88.3280410001133,
                            45.9635390001792
                        ],
                        [
                            -88.3302780001052,
                            45.9658350001616
                        ],
                        [
                            -88.3347400003933,
                            45.9686270001383
                        ],
                        [
                            -88.34203600035,
                            45.9689840003089
                        ],
                        [
                            -88.3425230004217,
                            45.971986999979
                        ],
                        [
                            -88.3454170003646,
                            45.9739850000487
                        ],
                        [
                            -88.3598209999569,
                            45.9818920002767
                        ],
                        [
                            -88.3717580003018,
                            45.9854469998287
                        ],
                        [
                            -88.3742980002424,
                            45.989050000108
                        ],
                        [
                            -88.3805029998797,
                            45.9917649998408
                        ],
                        [
                            -88.3848420001443,
                            45.9904490000579
                        ],
                        [
                            -88.384259999638,
                            45.9879000003033
                        ],
                        [
                            -88.3886440001084,
                            45.9826230001767
                        ],
                        [
                            -88.3954210002062,
                            45.9803120000979
                        ],
                        [
                            -88.4031010003024,
                            45.981193000221
                        ],
                        [
                            -88.4099100001873,
                            45.9796699998782
                        ],
                        [
                            -88.4163809999635,
                            45.9750919997559
                        ],
                        [
                            -88.4230380004107,
                            45.9783969997695
                        ],
                        [
                            -88.4231930002214,
                            45.9817250001048
                        ],
                        [
                            -88.4261309996469,
                            45.9843860002119
                        ],
                        [
                            -88.4335570001982,
                            45.9854089999752
                        ],
                        [
                            -88.4347620003203,
                            45.9883399998429
                        ],
                        [
                            -88.4385580004367,
                            45.9886040000099
                        ],
                        [
                            -88.4407490003978,
                            45.9908500002199
                        ],
                        [
                            -88.4500370001239,
                            45.9901120000703
                        ],
                        [
                            -88.4542349999231,
                            45.9934759999207
                        ],
                        [
                            -88.4541090004248,
                            45.9975289999001
                        ],
                        [
                            -88.458672000097,
                            45.9995140002638
                        ],
                        [
                            -88.4707719997502,
                            46.0009980000107
                        ],
                        [
                            -88.4745829999352,
                            45.9989530002504
                        ],
                        [
                            -88.4737710001734,
                            45.9949070000266
                        ],
                        [
                            -88.475205999551,
                            45.9931289996922
                        ],
                        [
                            -88.4893549997735,
                            45.9913670002098
                        ],
                        [
                            -88.497978000051,
                            45.9957939998476
                        ],
                        [
                            -88.4968519995715,
                            45.9992340001928
                        ],
                        [
                            -88.5008850004127,
                            46.0006039999498
                        ],
                        [
                            -88.503102000313,
                            46.005964999878
                        ],
                        [
                            -88.5022860003534,
                            46.0069710001655
                        ],
                        [
                            -88.5065510000997,
                            46.0113030001418
                        ],
                        [
                            -88.5063639996037,
                            46.0176099998421
                        ],
                        [
                            -88.5146920003284,
                            46.0200189999955
                        ],
                        [
                            -88.522749999818,
                            46.019364999857
                        ],
                        [
                            -88.5336860002554,
                            46.0211499999454
                        ],
                        [
                            -88.5325220001411,
                            46.0183710001253
                        ],
                        [
                            -88.5362680000287,
                            46.0176880001939
                        ],
                        [
                            -88.5394320003084,
                            46.0144700000666
                        ],
                        [
                            -88.5491510000297,
                            46.0128839998155
                        ],
                        [
                            -88.5550900004266,
                            46.015044999822
                        ],
                        [
                            -88.5592970004467,
                            46.0140969997417
                        ],
                        [
                            -88.5656629997425,
                            46.0156790000452
                        ],
                        [
                            -88.5717589995998,
                            46.0137739998531
                        ],
                        [
                            -88.5729570000493,
                            46.0115979998731
                        ],
                        [
                            -88.5787540001559,
                            46.0096930001071
                        ],
                        [
                            -88.5809190001778,
                            46.006784999768
                        ],
                        [
                            -88.588270000386,
                            46.0050980001885
                        ],
                        [
                            -88.5928909997845,
                            46.0111250001901
                        ],
                        [
                            -88.5935479997355,
                            46.0147699999525
                        ],
                        [
                            -88.5976819999607,
                            46.0174719999101
                        ],
                        [
                            -88.601177999603,
                            46.0177699997572
                        ],
                        [
                            -88.6041059998811,
                            46.0163039997473
                        ],
                        [
                            -88.6072709999857,
                            46.0113530002916
                        ],
                        [
                            -88.6073110001686,
                            46.0076210002055
                        ],
                        [
                            -88.6127120004402,
                            46.004461999888
                        ],
                        [
                            -88.6115120003409,
                            46.0034779999204
                        ],
                        [
                            -88.6118489997265,
                            45.9983900001159
                        ],
                        [
                            -88.615967999883,
                            45.9935389998601
                        ],
                        [
                            -88.6130039996199,
                            45.9909770002351
                        ],
                        [
                            -88.6159570000123,
                            45.9878960000702
                        ],
                        [
                            -88.6339380003362,
                            45.9881690001834
                        ],
                        [
                            -88.6352010001846,
                            45.9852169997913
                        ],
                        [
                            -88.6385460002142,
                            45.9850609997758
                        ],
                        [
                            -88.6464699998095,
                            45.9871100001406
                        ],
                        [
                            -88.6476020001367,
                            45.9886410002779
                        ],
                        [
                            -88.651767000324,
                            45.9875660001249
                        ],
                        [
                            -88.6579379996261,
                            45.989419000067
                        ],
                        [
                            -88.6629780002226,
                            45.9874989999904
                        ],
                        [
                            -88.6641620004283,
                            45.9891399999747
                        ],
                        [
                            -88.6708270003732,
                            45.9885470000379
                        ],
                        [
                            -88.6706010004174,
                            45.9898300001244
                        ],
                        [
                            -88.6634829998377,
                            45.9927020002507
                        ],
                        [
                            -88.6670019999446,
                            45.9937159998114
                        ],
                        [
                            -88.6667280003082,
                            45.9969319997763
                        ],
                        [
                            -88.671005999575,
                            45.9988319999178
                        ],
                        [
                            -88.6703300002558,
                            46.0009980000107
                        ],
                        [
                            -88.6722569997279,
                            46.0026450000494
                        ],
                        [
                            -88.6712149998124,
                            46.0048589999649
                        ],
                        [
                            -88.6759159995769,
                            46.0085459998363
                        ],
                        [
                            -88.6752839997402,
                            46.0113960003094
                        ],
                        [
                            -88.6788680001444,
                            46.0135950003057
                        ],
                        [
                            -88.6872689997657,
                            46.0150439997864
                        ],
                        [
                            -88.6896959997284,
                            46.0140029996945
                        ],
                        [
                            -88.6974210000305,
                            46.017489999758
                        ],
                        [
                            -88.7037110000566,
                            46.0182930001132
                        ],
                        [
                            -88.7077149996871,
                            46.0153959999758
                        ],
                        [
                            -88.712078000241,
                            46.0163460002705
                        ],
                        [
                            -88.7129770000414,
                            46.0127180002706
                        ],
                        [
                            -88.7183139998405,
                            46.0131820000562
                        ],
                        [
                            -88.7180240003106,
                            46.0156110002335
                        ],
                        [
                            -88.722217000087,
                            46.017530000023
                        ],
                        [
                            -88.7212610003852,
                            46.0222209999084
                        ],
                        [
                            -88.7304749995932,
                            46.0267279999362
                        ],
                        [
                            -88.7342339998995,
                            46.0255449998906
                        ],
                        [
                            -88.7389799998699,
                            46.027459000152
                        ],
                        [
                            -88.7527199998384,
                            46.0234149999528
                        ],
                        [
                            -88.7589680000318,
                            46.0196330002884
                        ],
                        [
                            -88.7663989997076,
                            46.0248820002827
                        ],
                        [
                            -88.7706209997963,
                            46.0255609999146
                        ],
                        [
                            -88.7688480003099,
                            46.0218869997878
                        ],
                        [
                            -88.7698009996386,
                            46.0189439999361
                        ],
                        [
                            -88.7801560002929,
                            46.0153949999465
                        ],
                        [
                            -88.7824729997524,
                            46.0167509999357
                        ],
                        [
                            -88.7839399998154,
                            46.023843999773
                        ],
                        [
                            -88.7787340004358,
                            46.0288750001811
                        ],
                        [
                            -88.7787389995604,
                            46.0316900000847
                        ],
                        [
                            -88.7845959999415,
                            46.0326540002285
                        ],
                        [
                            -88.7887660001516,
                            46.0286230000279
                        ],
                        [
                            -88.7921620002349,
                            46.0323160000117
                        ],
                        [
                            -88.7961819997589,
                            46.0337120001507
                        ],
                        [
                            -88.8006699999863,
                            46.0300360002584
                        ],
                        [
                            -88.7962420000333,
                            46.02685400011
                        ],
                        [
                            -88.7964599995932,
                            46.0236059999669
                        ],
                        [
                            -88.8160259999807,
                            46.0205859999584
                        ],
                        [
                            -88.8154400001748,
                            46.0229739999697
                        ],
                        [
                            -88.8190839999552,
                            46.026314000016
                        ],
                        [
                            -88.8243789999215,
                            46.026552999842
                        ],
                        [
                            -88.8354080002452,
                            46.0304439999548
                        ],
                        [
                            -88.8390910003836,
                            46.0299760000124
                        ],
                        [
                            -88.850821999966,
                            46.0403120000075
                        ],
                        [
                            -89.091638000012,
                            46.1384469999974
                        ],
                        [
                            -90.1205719996537,
                            46.3370390002516
                        ],
                        [
                            -90.1212550004423,
                            46.3385920001933
                        ],
                        [
                            -90.1185430000743,
                            46.3393190000634
                        ],
                        [
                            -90.1179840000326,
                            46.3415049996993
                        ],
                        [
                            -90.1200319998785,
                            46.3447640000419
                        ],
                        [
                            -90.1217800001489,
                            46.344399999865
                        ],
                        [
                            -90.1236510004429,
                            46.3462649999189
                        ],
                        [
                            -90.1203959999266,
                            46.3495940000026
                        ],
                        [
                            -90.1168519997054,
                            46.3506510002151
                        ],
                        [
                            -90.1187029999079,
                            46.3593450003083
                        ],
                        [
                            -90.1233679996873,
                            46.3607349998608
                        ],
                        [
                            -90.1228110001937,
                            46.3636450000365
                        ],
                        [
                            -90.1313519995571,
                            46.3690530000344
                        ],
                        [
                            -90.1297320002316,
                            46.3698730000887
                        ],
                        [
                            -90.1304600003278,
                            46.3711510000849
                        ],
                        [
                            -90.1339860001074,
                            46.3717890002625
                        ],
                        [
                            -90.1344460004149,
                            46.377439000303
                        ],
                        [
                            -90.1328079997494,
                            46.3781199998798
                        ],
                        [
                            -90.134249999698,
                            46.3791310002334
                        ],
                        [
                            -90.1321769997377,
                            46.3811199997641
                        ],
                        [
                            -90.1396819999317,
                            46.3852590000697
                        ],
                        [
                            -90.1411740001089,
                            46.3888729999251
                        ],
                        [
                            -90.144351999734,
                            46.3900480002124
                        ],
                        [
                            -90.1447350000486,
                            46.3932439998136
                        ],
                        [
                            -90.1477820001524,
                            46.3960560001845
                        ],
                        [
                            -90.146667000442,
                            46.3990130001429
                        ],
                        [
                            -90.1531680003553,
                            46.401216999781
                        ],
                        [
                            -90.1540970002929,
                            46.4032980000842
                        ],
                        [
                            -90.1528300002465,
                            46.4037769997101
                        ],
                        [
                            -90.1549719998038,
                            46.4054570000805
                        ],
                        [
                            -90.1532679999144,
                            46.4058749998506
                        ],
                        [
                            -90.1536420000083,
                            46.4070010000175
                        ],
                        [
                            -90.158368999712,
                            46.4094539999627
                        ],
                        [
                            -90.1590869997624,
                            46.4140300002077
                        ],
                        [
                            -90.1577200001569,
                            46.41570599987
                        ],
                        [
                            -90.158921000081,
                            46.4190889999845
                        ],
                        [
                            -90.1572980003825,
                            46.4211219999437
                        ],
                        [
                            -90.1605589998482,
                            46.4228890001064
                        ],
                        [
                            -90.1596590002229,
                            46.4253939998644
                        ],
                        [
                            -90.1624200000964,
                            46.4275790002601
                        ],
                        [
                            -90.1633189998968,
                            46.4347639998753
                        ],
                        [
                            -90.1671300000817,
                            46.4384529997672
                        ],
                        [
                            -90.1674199996117,
                            46.4417050000156
                        ],
                        [
                            -90.1704039999662,
                            46.4394379998501
                        ],
                        [
                            -90.1783500002012,
                            46.4404649998429
                        ],
                        [
                            -90.1766970003654,
                            46.447982999918
                        ],
                        [
                            -90.1791710001838,
                            46.4505960000462
                        ],
                        [
                            -90.1783250000868,
                            46.4574079998446
                        ],
                        [
                            -90.1810879996102,
                            46.4587520002884
                        ],
                        [
                            -90.1804180001387,
                            46.4565830001205
                        ],
                        [
                            -90.1907920001612,
                            46.4598159996999
                        ],
                        [
                            -90.1936140001341,
                            46.4633790000822
                        ],
                        [
                            -90.1922270004371,
                            46.4633739997548
                        ],
                        [
                            -90.1921830000562,
                            46.4657579999483
                        ],
                        [
                            -90.1888339998286,
                            46.4689190002649
                        ],
                        [
                            -90.1926569997091,
                            46.4685760002624
                        ],
                        [
                            -90.1931479999788,
                            46.4723160001249
                        ],
                        [
                            -90.1971879995943,
                            46.4731589996923
                        ],
                        [
                            -90.2042119995643,
                            46.4783089998138
                        ],
                        [
                            -90.2057880003055,
                            46.4847609997172
                        ],
                        [
                            -90.2120809998064,
                            46.490611000223
                        ],
                        [
                            -90.2111920000518,
                            46.4931110002564
                        ],
                        [
                            -90.2166369998059,
                            46.5016370000446
                        ],
                        [
                            -90.220771999856,
                            46.5035680002264
                        ],
                        [
                            -90.2309180002729,
                            46.5015289999116
                        ],
                        [
                            -90.2291320003677,
                            46.5026510002771
                        ],
                        [
                            -90.2312530000086,
                            46.5030700002283
                        ],
                        [
                            -90.2294339995932,
                            46.5078910000106
                        ],
                        [
                            -90.2312360002902,
                            46.5098930001463
                        ],
                        [
                            -90.2408970002852,
                            46.5059760001942
                        ],
                        [
                            -90.2566979996203,
                            46.5048049998045
                        ],
                        [
                            -90.261548000246,
                            46.5025619997418
                        ],
                        [
                            -90.2654969996248,
                            46.5043000001151
                        ],
                        [
                            -90.2640060001708,
                            46.5063100002196
                        ],
                        [
                            -90.2665670000277,
                            46.5078550000096
                        ],
                        [
                            -90.2709339998814,
                            46.5068520002365
                        ],
                        [
                            -90.2725509997321,
                            46.5045119998336
                        ],
                        [
                            -90.2728699995744,
                            46.5086330001826
                        ],
                        [
                            -90.2704409999619,
                            46.5116830001309
                        ],
                        [
                            -90.2746520001799,
                            46.5153580002891
                        ],
                        [
                            -90.2776230001157,
                            46.5148100002361
                        ],
                        [
                            -90.2753240001995,
                            46.5182300002328
                        ],
                        [
                            -90.2717959998718,
                            46.5192650001075
                        ],
                        [
                            -90.2769400002254,
                            46.5244470002103
                        ],
                        [
                            -90.2844639997876,
                            46.5187150001889
                        ],
                        [
                            -90.2945890002882,
                            46.5216599999239
                        ],
                        [
                            -90.2953220004072,
                            46.5185100001494
                        ],
                        [
                            -90.3033260003685,
                            46.5173599998515
                        ],
                        [
                            -90.3071969999296,
                            46.5184369998068
                        ],
                        [
                            -90.3087669999246,
                            46.5167300001309
                        ],
                        [
                            -90.3140860001804,
                            46.5160119999646
                        ],
                        [
                            -90.316554000151,
                            46.5168959999281
                        ],
                        [
                            -90.3178350004411,
                            46.5215229999371
                        ],
                        [
                            -90.3144340003349,
                            46.5236909998741
                        ],
                        [
                            -90.3120130002201,
                            46.529192999999
                        ],
                        [
                            -90.3130219996253,
                            46.5308890000167
                        ],
                        [
                            -90.3106039998834,
                            46.5324110002672
                        ],
                        [
                            -90.3113799996601,
                            46.5340390001464
                        ],
                        [
                            -90.3094409995941,
                            46.5372849999032
                        ],
                        [
                            -90.311613000187,
                            46.5379800002298
                        ],
                        [
                            -90.3106189999521,
                            46.5391659999774
                        ],
                        [
                            -90.3121989999929,
                            46.5402170001142
                        ],
                        [
                            -90.3161599999657,
                            46.54050399996
                        ],
                        [
                            -90.3170810004057,
                            46.5423480000033
                        ],
                        [
                            -90.3154770000754,
                            46.5446970001452
                        ],
                        [
                            -90.3184480000112,
                            46.5442430001573
                        ],
                        [
                            -90.321306999794,
                            46.5467419999687
                        ],
                        [
                            -90.3267309996317,
                            46.5460709997862
                        ],
                        [
                            -90.3280579999526,
                            46.5481700001946
                        ],
                        [
                            -90.3273790002602,
                            46.551408999697
                        ],
                        [
                            -90.3324750000349,
                            46.5516719998825
                        ],
                        [
                            -90.3322299998125,
                            46.5534319999576
                        ],
                        [
                            -90.3366209999556,
                            46.5541209997714
                        ],
                        [
                            -90.3435329997727,
                            46.5524549998777
                        ],
                        [
                            -90.3437770001701,
                            46.5508539999666
                        ],
                        [
                            -90.3394039995704,
                            46.5492879999399
                        ],
                        [
                            -90.3423360000465,
                            46.5478309998489
                        ],
                        [
                            -90.3468059998323,
                            46.5493499997071
                        ],
                        [
                            -90.3477320002951,
                            46.5474760001935
                        ],
                        [
                            -90.3455069999988,
                            46.5454379997567
                        ],
                        [
                            -90.3506839999643,
                            46.5403249999909
                        ],
                        [
                            -90.3578500002246,
                            46.5388130000875
                        ],
                        [
                            -90.360684999718,
                            46.5413800001485
                        ],
                        [
                            -90.3653910004036,
                            46.5395359999771
                        ],
                        [
                            -90.36822900027,
                            46.5411320000792
                        ],
                        [
                            -90.3755169998308,
                            46.5388310000324
                        ],
                        [
                            -90.3778890004402,
                            46.5355460002502
                        ],
                        [
                            -90.3804510001221,
                            46.5365649998173
                        ],
                        [
                            -90.393262000153,
                            46.5324330001149
                        ],
                        [
                            -90.3951529996402,
                            46.5342269999439
                        ],
                        [
                            -90.3923280001926,
                            46.5345859998169
                        ],
                        [
                            -90.3922160000396,
                            46.5360479999015
                        ],
                        [
                            -90.3955379996046,
                            46.5359459998507
                        ],
                        [
                            -90.3993360002691,
                            46.5439509999689
                        ],
                        [
                            -90.4016479997057,
                            46.544153999769
                        ],
                        [
                            -90.4059800002976,
                            46.5485769998376
                        ],
                        [
                            -90.4080299997934,
                            46.5548389997035
                        ],
                        [
                            -90.4150439997177,
                            46.5576729997099
                        ],
                        [
                            -90.4151990004267,
                            46.5631129998376
                        ],
                        [
                            -90.4183920001204,
                            46.5660990002009
                        ],
                        [
                            -90.0001449998133,
                            47.2149690002009
                        ],
                        [
                            -89.9571020001925,
                            47.2911030002522
                        ],
                        [
                            -89.6251669999811,
                            47.7995489997429
                        ],
                        [
                            -89.4833850003732,
                            48.0137160001423
                        ],
                        [
                            -89.3375499998328,
                            47.9742609999592
                        ],
                        [
                            -88.6774420000894,
                            48.2453970002119
                        ],
                        [
                            -88.3698619998935,
                            48.3060629999582
                        ],
                        [
                            -88.0000769999204,
                            48.1640500000156
                        ],
                        [
                            -88.0000779997453,
                            48.1558830002119
                        ],
                        [
                            -87.9108130001555,
                            48.1248780000218
                        ],
                        [
                            -87.8750820003848,
                            48.1134320000248
                        ],
                        [
                            -87.8750820003848,
                            48.114725999949
                        ],
                        [
                            -87.750086999951,
                            48.0662449999046
                        ],
                        [
                            -87.750086999951,
                            48.0638780002652
                        ],
                        [
                            -87.6250909996922,
                            48.0211090001148
                        ],
                        [
                            -87.6250909996922,
                            48.0190189997029
                        ],
                        [
                            -87.5742409997537,
                            47.9998849999759
                        ],
                        [
                            -87.5768819999766,
                            47.9998849999759
                        ],
                        [
                            -87.5000950003318,
                            47.9681139998428
                        ],
                        [
                            -87.2501029998143,
                            47.8704770000428
                        ],
                        [
                            -87.0631009999699,
                            47.799990999731
                        ],
                        [
                            -86.9406220000855,
                            47.7499050002019
                        ],
                        [
                            -86.9492260000965,
                            47.7499050002019
                        ],
                        [
                            -86.7501200004008,
                            47.6719599998386
                        ],
                        [
                            -86.7501200004008,
                            47.6749109998649
                        ],
                        [
                            -86.6313210002818,
                            47.6249199999602
                        ],
                        [
                            -86.6343440000962,
                            47.6249199999602
                        ],
                        [
                            -86.625124999967,
                            47.6177670001395
                        ],
                        [
                            -86.625124999967,
                            47.6200080001656
                        ],
                        [
                            -86.5001300004315,
                            47.5695230000019
                        ],
                        [
                            -86.5001300004315,
                            47.5683239999725
                        ],
                        [
                            -86.3127739996865,
                            47.4999400002544
                        ],
                        [
                            -86.3052049999184,
                            47.4999409998082
                        ],
                        [
                            -86.2501389997389,
                            47.4779030001544
                        ],
                        [
                            -86.2501389997389,
                            47.4744479997081
                        ],
                        [
                            -86.1251409998303,
                            47.4234680001935
                        ],
                        [
                            -86.1251419996552,
                            47.4167439997973
                        ],
                        [
                            -86.0200320003495,
                            47.3749620002054
                        ],
                        [
                            -86.0273450000245,
                            47.3749610000994
                        ],
                        [
                            -86.0001449995716,
                            47.361882999722
                        ],
                        [
                            -86.0001439997467,
                            47.367278999833
                        ],
                        [
                            -85.8751440001883,
                            47.316260999702
                        ],
                        [
                            -85.8751440001883,
                            47.3140230001414
                        ],
                        [
                            -85.7501429999066,
                            47.2613870002335
                        ],
                        [
                            -85.7501429999066,
                            47.2642829997116
                        ],
                        [
                            -85.7209669995777,
                            47.2499849997761
                        ],
                        [
                            -85.7222119998828,
                            47.2499849997761
                        ],
                        [
                            -85.4159640000307,
                            47.1250049998599
                        ],
                        [
                            -85.420120999822,
                            47.1250049998599
                        ],
                        [
                            -85.2501110000894,
                            47.0565400000003
                        ],
                        [
                            -85.2501110000894,
                            47.0545129996992
                        ],
                        [
                            -85.1255240000846,
                            47.0000180000628
                        ],
                        [
                            -84.8593350003969,
                            46.8888430002098
                        ],
                        [
                            -84.7629570002502,
                            46.6343240001999
                        ],
                        [
                            -84.5569620003038,
                            46.4606389999619
                        ],
                        [
                            -84.4755939996468,
                            46.4528640002227
                        ],
                        [
                            -84.4451489996217,
                            46.4890160001096
                        ],
                        [
                            -84.4202739999225,
                            46.5010769998899
                        ],
                        [
                            -84.3947249996555,
                            46.4992420001485
                        ],
                        [
                            -84.3739900000291,
                            46.5090990002353
                        ],
                        [
                            -84.3414269998378,
                            46.5076889997996
                        ],
                        [
                            -84.3253710002347,
                            46.5000209998052
                        ],
                        [
                            -84.2930159995575,
                            46.492803000054
                        ],
                        [
                            -84.2758140002817,
                            46.4928209997845
                        ],
                        [
                            -84.2653909998554,
                            46.4943929998593
                        ],
                        [
                            -84.2544340004,
                            46.5008209998415
                        ],
                        [
                            -84.2261309999322,
                            46.5339200001979
                        ],
                        [
                            -84.1940199996524,
                            46.5401489999672
                        ],
                        [
                            -84.1774279995738,
                            46.5269199998859
                        ],
                        [
                            -84.1660269997043,
                            46.5262200000513
                        ],
                        [
                            -84.1394260003744,
                            46.532219000095
                        ],
                        [
                            -84.1289250001302,
                            46.5301189997874
                        ],
                        [
                            -84.1233249996671,
                            46.5209189997023
                        ],
                        [
                            -84.1179250001188,
                            46.5176189998296
                        ],
                        [
                            -84.1112250000138,
                            46.504118999917
                        ],
                        [
                            -84.1461719996118,
                            46.4185199997493
                        ],
                        [
                            -84.1389059997924,
                            46.3722209997748
                        ],
                        [
                            -84.119121999845,
                            46.3370140001905
                        ],
                        [
                            -84.1062470002399,
                            46.3219629999833
                        ],
                        [
                            -84.1196290000082,
                            46.3150130002816
                        ],
                        [
                            -84.1155629995551,
                            46.2682249997292
                        ],
                        [
                            -84.0977660002527,
                            46.2565119999018
                        ],
                        [
                            -84.1080890002216,
                            46.241237999947
                        ],
                        [
                            -84.0857850004251,
                            46.2099910002079
                        ],
                        [
                            -84.0770270004284,
                            46.1875580001449
                        ],
                        [
                            -84.0056849996394,
                            46.1495510002082
                        ],
                        [
                            -84.0058729999604,
                            46.1184690002708
                        ],
                        [
                            -83.9761340002901,
                            46.1025539998415
                        ],
                        [
                            -83.9554239998798,
                            46.0571879998606
                        ],
                        [
                            -83.9034740002994,
                            46.0609100001625
                        ],
                        [
                            -83.8262430002406,
                            46.1192880002669
                        ],
                        [
                            -83.7595290003195,
                            46.1031729998355
                        ],
                        [
                            -83.6546469997211,
                            46.122054999945
                        ],
                        [
                            -83.571467000118,
                            46.1059980003072
                        ],
                        [
                            -83.433557999721,
                            45.9988950000002
                        ],
                        [
                            -83.5476259999383,
                            45.8750199997962
                        ],
                        [
                            -83.636867000137,
                            45.7739439997809
                        ],
                        [
                            -83.4839740001896,
                            45.7719369997907
                        ],
                        [
                            -82.9999629998154,
                            45.5563780002275
                        ],
                        [
                            -82.8787650002211,
                            45.5000220001338
                        ],
                        [
                            -82.874955999686,
                            45.4967230002968
                        ],
                        [
                            -82.7499500002798,
                            45.4422209997168
                        ],
                        [
                            -82.5206399996852,
                            45.3359019998049
                        ],
                        [
                            -82.3828369995935,
                            44.7500160002252
                        ],
                        [
                            -82.3855039997556,
                            44.7500160002252
                        ],
                        [
                            -82.3749270002418,
                            44.7164369997641
                        ],
                        [
                            -82.3749270002418,
                            44.7081389998709
                        ],
                        [
                            -82.3547869997439,
                            44.6250150000203
                        ],
                        [
                            -82.3564549996483,
                            44.6250150000203
                        ],
                        [
                            -82.3264659997328,
                            44.5000150002273
                        ],
                        [
                            -82.2499140002646,
                            44.1651429998362
                        ],
                        [
                            -82.2499140002646,
                            44.1587510002675
                        ],
                        [
                            -82.1229710004422,
                            43.5908270001711
                        ],
                        [
                            -82.2951009999909,
                            43.2500280000007
                        ],
                        [
                            -82.3562420001113,
                            43.1250290000267
                        ],
                        [
                            -82.3899710000667,
                            43.0622380002982
                        ],
                        [
                            -82.4109969999461,
                            43.0113149997714
                        ],
                        [
                            -82.4237240003115,
                            42.9985169996859
                        ],
                        [
                            -82.4249689997183,
                            42.993382999991
                        ],
                        [
                            -82.4230499997438,
                            42.9884400002407
                        ],
                        [
                            -82.4134740001375,
                            42.976886999966
                        ],
                        [
                            -82.4166979997933,
                            42.966424000238
                        ],
                        [
                            -82.4287280000247,
                            42.9520209998462
                        ],
                        [
                            -82.4472299998571,
                            42.9377100001342
                        ],
                        [
                            -82.4560270002118,
                            42.9258120002104
                        ],
                        [
                            -82.4696819997916,
                            42.8875489998758
                        ],
                        [
                            -82.468676999686,
                            42.8520499999762
                        ],
                        [
                            -82.4726190002905,
                            42.8366730000358
                        ],
                        [
                            -82.478526000002,
                            42.8256249999911
                        ],
                        [
                            -82.4825740000134,
                            42.8086440001375
                        ],
                        [
                            -82.4809050002841,
                            42.8026460002171
                        ],
                        [
                            -82.4705909996377,
                            42.7837050003074
                        ],
                        [
                            -82.4670449997666,
                            42.7687470001342
                        ],
                        [
                            -82.4676669995576,
                            42.7619060002817
                        ],
                        [
                            -82.4833849999502,
                            42.733461999979
                        ],
                        [
                            -82.4839570004108,
                            42.7179379999442
                        ],
                        [
                            -82.4976300004322,
                            42.6950329998463
                        ],
                        [
                            -82.5108509996437,
                            42.6649920003126
                        ],
                        [
                            -82.5100389998819,
                            42.6367929997876
                        ],
                        [
                            -82.5215679997979,
                            42.6098490001747
                        ],
                        [
                            -82.5248330003599,
                            42.6059760001895
                        ],
                        [
                            -82.548142000262,
                            42.5917520002097
                        ],
                        [
                            -82.5581770003508,
                            42.5811679998326
                        ],
                        [
                            -82.5788130002429,
                            42.5660270001089
                        ],
                        [
                            -82.584073999874,
                            42.5540499999317
                        ],
                        [
                            -82.5899809995855,
                            42.5509779996848
                        ],
                        [
                            -82.6069669998495,
                            42.5482820002278
                        ],
                        [
                            -82.6170859996039,
                            42.554718000324
                        ],
                        [
                            -82.624329999682,
                            42.5571339998059
                        ],
                        [
                            -82.6337239997134,
                            42.5568019998739
                        ],
                        [
                            -82.6430700000641,
                            42.5543210002138
                        ],
                        [
                            -82.6618859997159,
                            42.541745999866
                        ],
                        [
                            -82.668063999589,
                            42.5331119996823
                        ],
                        [
                            -82.7115799999361,
                            42.4891409998502
                        ],
                        [
                            -82.8302500001837,
                            42.373492000034
                        ],
                        [
                            -82.94548499999,
                            42.3473960002898
                        ],
                        [
                            -82.9597499996651,
                            42.3400330002218
                        ],
                        [
                            -82.9891970001557,
                            42.3324809998907
                        ],
                        [
                            -83.0140760000529,
                            42.3313209997718
                        ],
                        [
                            -83.0262340003308,
                            42.3289099999009
                        ],
                        [
                            -83.0636370002038,
                            42.3168570000061
                        ],
                        [
                            -83.0800200000451,
                            42.3071720002379
                        ],
                        [
                            -83.0999770002448,
                            42.286698000054
                        ],
                        [
                            -83.1120300000424,
                            42.2605869999463
                        ],
                        [
                            -83.1263609998398,
                            42.2384120001075
                        ],
                        [
                            -83.1343840000676,
                            42.1746210001367
                        ],
                        [
                            -83.1253039998557,
                            42.1375559997848
                        ],
                        [
                            -83.1192280000898,
                            42.1252630002222
                        ],
                        [
                            -83.1224009996919,
                            42.1142690003242
                        ],
                        [
                            -83.1275259997789,
                            42.1049680001729
                        ],
                        [
                            -83.1321229997863,
                            42.0881390000024
                        ],
                        [
                            -83.1456910002257,
                            42.0538579998504
                        ],
                        [
                            -83.1494499996338,
                            42.0402639996742
                        ],
                        [
                            -83.1124600002127,
                            41.959410000268
                        ],
                        [
                            -83.4158509999319,
                            41.733794000114
                        ],
                        [
                            -84.1923169995879,
                            41.7110100003018
                        ],
                        [
                            -84.805995999673,
                            41.6961180000935
                        ],
                        [
                            -84.8058850002433,
                            41.760171000018
                        ],
                        [
                            -85.7281529999295,
                            41.7589460001244
                        ],
                        [
                            -86.1084650000861,
                            41.7606969999488
                        ],
                        [
                            -86.5916160001196,
                            41.7594870001385
                        ],
                        [
                            -87.207774000046,
                            41.7609559999281
                        ],
                        [
                            -87.1484310004246,
                            42.0000140001194
                        ],
                        [
                            -87.1500089999173,
                            42.0000330000599
                        ],
                        [
                            -87.0198630003555,
                            42.4937630000481
                        ],
                        [
                            -87.0918809995654,
                            43.0000140000758
                        ],
                        [
                            -87.1083970003744,
                            43.1250100000078
                        ],
                        [
                            -87.1250720002938,
                            43.2277370001511
                        ],
                        [
                            -87.147166000028,
                            43.3798590001406
                        ],
                        [
                            -87.1250789999665,
                            43.670445000247
                        ],
                        [
                            -87.1141020004196,
                            43.7341109999213
                        ],
                        [
                            -87.0488470001655,
                            43.999999999853
                        ],
                        [
                            -87.0462489996003,
                            43.999999999853
                        ],
                        [
                            -87.0132340003956,
                            44.1319049997823
                        ],
                        [
                            -87.000072999662,
                            44.1632359998216
                        ],
                        [
                            -87.000080000233,
                            44.1548209998068
                        ],
                        [
                            -86.8476940004488,
                            44.5003389998039
                        ],
                        [
                            -86.8551839996759,
                            44.5003389998039
                        ],
                        [
                            -86.6862879998288,
                            44.8810020000028
                        ],
                        [
                            -86.4999300004149,
                            45.0807999998192
                        ],
                        [
                            -86.433127999732,
                            45.1249910001394
                        ],
                        [
                            -86.2495479999101,
                            45.2361249999463
                        ],
                        [
                            -86.4641200001033,
                            45.3248100003068
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 44.8441757,
            "name": "Michigan",
            "intptlon": -85.660493,
            "geo_point_2d": [
                44.874698084,
                -85.730876413
            ],
            "geoid": "26",
            "mtfcc": "G4000",
            "region": 2
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -85.730876413,
                44.874698084
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "dcb6d932c3761a6f614524b9bdf5f9462a8b030d",
        "fields": {
            "arealand": "-698155119",
            "objectid": 7,
            "basename": "Idaho",
            "stusab": "ID",
            "statens": "01779783",
            "centlon": -114.6136821,
            "state": "16",
            "gid": 41,
            "centlat": 44.3524058,
            "division": 8,
            "areawater": "-1903599535",
            "oid": "-1954881512",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -116.899710999992,
                            44.840608999876
                        ],
                        [
                            -116.896248999787,
                            44.8483299999536
                        ],
                        [
                            -116.898726999803,
                            44.8522900001008
                        ],
                        [
                            -116.897721999698,
                            44.8548469997547
                        ],
                        [
                            -116.889881999768,
                            44.8551709998813
                        ],
                        [
                            -116.883590999917,
                            44.8582770001876
                        ],
                        [
                            -116.877136000034,
                            44.8660489999237
                        ],
                        [
                            -116.865337999606,
                            44.8706030002921
                        ],
                        [
                            -116.857038000268,
                            44.8807699997343
                        ],
                        [
                            -116.857290000163,
                            44.8822910000839
                        ],
                        [
                            -116.852427000016,
                            44.8875779999344
                        ],
                        [
                            -116.85237299959,
                            44.8919190002138
                        ],
                        [
                            -116.85051200024,
                            44.8935240000294
                        ],
                        [
                            -116.848892999841,
                            44.9007189999514
                        ],
                        [
                            -116.842921999657,
                            44.908907000297
                        ],
                        [
                            -116.842108000245,
                            44.9149219997206
                        ],
                        [
                            -116.838955999661,
                            44.9178260000118
                        ],
                        [
                            -116.83846699994,
                            44.9236010002283
                        ],
                        [
                            -116.832176000089,
                            44.9313729997852
                        ],
                        [
                            -116.835701999868,
                            44.940632999712
                        ],
                        [
                            -116.846460999855,
                            44.9515210000053
                        ],
                        [
                            -116.851406000017,
                            44.959840999748
                        ],
                        [
                            -116.847044000187,
                            44.9653600003031
                        ],
                        [
                            -116.836449000231,
                            44.9692390001034
                        ],
                        [
                            -116.82742599992,
                            44.9807210002101
                        ],
                        [
                            -116.826893999643,
                            44.9838779997765
                        ],
                        [
                            -116.839744000032,
                            44.9830630002255
                        ],
                        [
                            -116.843550000194,
                            44.9754080000518
                        ],
                        [
                            -116.848573999999,
                            44.9717160002237
                        ],
                        [
                            -116.85350999994,
                            44.9729880001845
                        ],
                        [
                            -116.858276999827,
                            44.9780080000985
                        ],
                        [
                            -116.856753999687,
                            44.9842980001004
                        ],
                        [
                            -116.844624999721,
                            45.0014349999877
                        ],
                        [
                            -116.845112999618,
                            45.0079530001252
                        ],
                        [
                            -116.847190999601,
                            45.011495999936
                        ],
                        [
                            -116.844796000324,
                            45.0153119999862
                        ],
                        [
                            -116.847943999812,
                            45.0226019999313
                        ],
                        [
                            -116.841314000027,
                            45.0309069997511
                        ],
                        [
                            -116.825132999852,
                            45.0378400000801
                        ],
                        [
                            -116.820914000137,
                            45.0431179998557
                        ],
                        [
                            -116.814320000337,
                            45.0483309999662
                        ],
                        [
                            -116.808575999934,
                            45.0506520000898
                        ],
                        [
                            -116.79732900005,
                            45.0602670002561
                        ],
                        [
                            -116.790878000365,
                            45.0699470001119
                        ],
                        [
                            -116.783709999557,
                            45.0769719998127
                        ],
                        [
                            -116.783537000203,
                            45.093605000285
                        ],
                        [
                            -116.774846999978,
                            45.105535999901
                        ],
                        [
                            -116.754642999906,
                            45.1139719999244
                        ],
                        [
                            -116.752838999559,
                            45.1217679997589
                        ],
                        [
                            -116.746859999878,
                            45.1242230000653
                        ],
                        [
                            -116.745535000105,
                            45.1330380002005
                        ],
                        [
                            -116.733295999636,
                            45.1384860001838
                        ],
                        [
                            -116.72960699965,
                            45.1420910002371
                        ],
                        [
                            -116.728018000287,
                            45.1542369998395
                        ],
                        [
                            -116.724187999835,
                            45.1629239999579
                        ],
                        [
                            -116.724204999554,
                            45.1715010001164
                        ],
                        [
                            -116.709535999648,
                            45.2030150003118
                        ],
                        [
                            -116.708545999611,
                            45.2073559997883
                        ],
                        [
                            -116.709749999908,
                            45.2172430000035
                        ],
                        [
                            -116.704110999985,
                            45.2281539999543
                        ],
                        [
                            -116.702709000219,
                            45.2379099997015
                        ],
                        [
                            -116.703696999708,
                            45.2400990002385
                        ],
                        [
                            -116.698764999965,
                            45.2451819998135
                        ],
                        [
                            -116.691387999817,
                            45.2637390002415
                        ],
                        [
                            -116.67449299979,
                            45.2763489998759
                        ],
                        [
                            -116.672162999912,
                            45.2889379996975
                        ],
                        [
                            -116.674647999601,
                            45.31434199977
                        ],
                        [
                            -116.673793000181,
                            45.3215110002356
                        ],
                        [
                            -116.641250999906,
                            45.3677929998214
                        ],
                        [
                            -116.632343999947,
                            45.377415000114
                        ],
                        [
                            -116.623047,
                            45.3919419999282
                        ],
                        [
                            -116.623385000109,
                            45.3939629999168
                        ],
                        [
                            -116.597446999679,
                            45.4127699999373
                        ],
                        [
                            -116.588194999938,
                            45.4429199997488
                        ],
                        [
                            -116.576811999612,
                            45.4520190001831
                        ],
                        [
                            -116.568251000157,
                            45.4558200000495
                        ],
                        [
                            -116.563972999992,
                            45.460228999919
                        ],
                        [
                            -116.554830000031,
                            45.4629300001588
                        ],
                        [
                            -116.554980999644,
                            45.4727979999563
                        ],
                        [
                            -116.558806999897,
                            45.4811890002206
                        ],
                        [
                            -116.553089000332,
                            45.4962099999116
                        ],
                        [
                            -116.553471999748,
                            45.4991079999369
                        ],
                        [
                            -116.55713200032,
                            45.5020170000364
                        ],
                        [
                            -116.557462999858,
                            45.5041730002217
                        ],
                        [
                            -116.555487999807,
                            45.5074699997444
                        ],
                        [
                            -116.543836999692,
                            45.5141929997312
                        ],
                        [
                            -116.540402999974,
                            45.5209250002487
                        ],
                        [
                            -116.535482000102,
                            45.5250789997574
                        ],
                        [
                            -116.531726999993,
                            45.5366849999558
                        ],
                        [
                            -116.527208999628,
                            45.5407019998167
                        ],
                        [
                            -116.52657900034,
                            45.5444860000178
                        ],
                        [
                            -116.52343900035,
                            45.5465559999593
                        ],
                        [
                            -116.52417199957,
                            45.548564999713
                        ],
                        [
                            -116.528571000109,
                            45.5501490000739
                        ],
                        [
                            -116.528319000214,
                            45.551512999947
                        ],
                        [
                            -116.5207400004,
                            45.5562009999682
                        ],
                        [
                            -116.511749999702,
                            45.5564450000984
                        ],
                        [
                            -116.506966999922,
                            45.5584270001287
                        ],
                        [
                            -116.50344899964,
                            45.5616949998934
                        ],
                        [
                            -116.502755999704,
                            45.5666079999956
                        ],
                        [
                            -116.481943000259,
                            45.5778979999013
                        ],
                        [
                            -116.481207999592,
                            45.5805970003001
                        ],
                        [
                            -116.483617000012,
                            45.5829619997577
                        ],
                        [
                            -116.482163000367,
                            45.5866819998191
                        ],
                        [
                            -116.471683999865,
                            45.5936119999987
                        ],
                        [
                            -116.463634999697,
                            45.6027850001376
                        ],
                        [
                            -116.463295999764,
                            45.6147599999643
                        ],
                        [
                            -116.472877000291,
                            45.6248769998576
                        ],
                        [
                            -116.471241000174,
                            45.6276690002531
                        ],
                        [
                            -116.472099999791,
                            45.6292010002089
                        ],
                        [
                            -116.482806000075,
                            45.6335950001006
                        ],
                        [
                            -116.48249499973,
                            45.6399159998414
                        ],
                        [
                            -116.48773399962,
                            45.643955999928
                        ],
                        [
                            -116.48726300034,
                            45.6478100001351
                        ],
                        [
                            -116.488818000267,
                            45.6507990002343
                        ],
                        [
                            -116.505428999714,
                            45.6619140000524
                        ],
                        [
                            -116.517674999855,
                            45.6750390002308
                        ],
                        [
                            -116.523960999683,
                            45.6776390001719
                        ],
                        [
                            -116.535117000229,
                            45.6912769998308
                        ],
                        [
                            -116.538201000142,
                            45.7161610000811
                        ],
                        [
                            -116.535698000012,
                            45.7342309997897
                        ],
                        [
                            -116.548089999743,
                            45.7523640001305
                        ],
                        [
                            -116.559443999757,
                            45.7551890000756
                        ],
                        [
                            -116.567338999938,
                            45.7630039997543
                        ],
                        [
                            -116.575128999639,
                            45.7658629999393
                        ],
                        [
                            -116.594209999605,
                            45.7790800001074
                        ],
                        [
                            -116.616935000249,
                            45.7824639998469
                        ],
                        [
                            -116.623741999586,
                            45.7818129998088
                        ],
                        [
                            -116.626971000163,
                            45.7841259998887
                        ],
                        [
                            -116.632031999777,
                            45.7849789998617
                        ],
                        [
                            -116.646341999658,
                            45.7798150001575
                        ],
                        [
                            -116.65962899989,
                            45.7800160000823
                        ],
                        [
                            -116.665267999813,
                            45.782058999816
                        ],
                        [
                            -116.680817999976,
                            45.7943879998751
                        ],
                        [
                            -116.68700699972,
                            45.806318999975
                        ],
                        [
                            -116.693424999793,
                            45.8116400000591
                        ],
                        [
                            -116.697191999597,
                            45.8201349998048
                        ],
                        [
                            -116.703417000224,
                            45.8217039997508
                        ],
                        [
                            -116.709749999908,
                            45.825728000006
                        ],
                        [
                            -116.735778999676,
                            45.8263640000783
                        ],
                        [
                            -116.749342999917,
                            45.8192550001575
                        ],
                        [
                            -116.75978899991,
                            45.8161669999722
                        ],
                        [
                            -116.763401999728,
                            45.8165800002418
                        ],
                        [
                            -116.774224000362,
                            45.8233690001032
                        ],
                        [
                            -116.782138999737,
                            45.8251160000804
                        ],
                        [
                            -116.789065999623,
                            45.8334710000535
                        ],
                        [
                            -116.787791999903,
                            45.8442669998282
                        ],
                        [
                            -116.796458999664,
                            45.8612239997679
                        ],
                        [
                            -116.804902999841,
                            45.8658150000324
                        ],
                        [
                            -116.815917999921,
                            45.8789979999987
                        ],
                        [
                            -116.843550000194,
                            45.8922730001999
                        ],
                        [
                            -116.848421999663,
                            45.8981610002923
                        ],
                        [
                            -116.859794999943,
                            45.9072639998676
                        ],
                        [
                            -116.86965500013,
                            45.9237989997691
                        ],
                        [
                            -116.87048399961,
                            45.9324080002196
                        ],
                        [
                            -116.875705999781,
                            45.9450079999796
                        ],
                        [
                            -116.875980000316,
                            45.9547749998784
                        ],
                        [
                            -116.880506000178,
                            45.9585079997436
                        ],
                        [
                            -116.891297999777,
                            45.9603270000378
                        ],
                        [
                            -116.894905999573,
                            45.96892200014
                        ],
                        [
                            -116.892934999719,
                            45.9743959998224
                        ],
                        [
                            -116.893605999914,
                            45.977580000195
                        ],
                        [
                            -116.911469000237,
                            45.9890490002265
                        ],
                        [
                            -116.918924000203,
                            45.9987129999824
                        ],
                        [
                            -116.921719000411,
                            46.0159420001539
                        ],
                        [
                            -116.929944000305,
                            46.0236479999337
                        ],
                        [
                            -116.929815000434,
                            46.035679999999
                        ],
                        [
                            -116.932451999561,
                            46.0408010000455
                        ],
                        [
                            -116.940279999795,
                            46.0466869999348
                        ],
                        [
                            -116.943322999701,
                            46.0543229998151
                        ],
                        [
                            -116.942792999971,
                            46.0611150003014
                        ],
                        [
                            -116.957866000108,
                            46.0754570002735
                        ],
                        [
                            -116.976822000401,
                            46.0790400000164
                        ],
                        [
                            -116.981951999612,
                            46.0848470000068
                        ],
                        [
                            -116.982464999623,
                            46.0919350000586
                        ],
                        [
                            -116.978845000132,
                            46.0958490003068
                        ],
                        [
                            -116.974041000436,
                            46.0976580000105
                        ],
                        [
                            -116.959693999846,
                            46.09901299988
                        ],
                        [
                            -116.955424000077,
                            46.1018610000619
                        ],
                        [
                            -116.951335000058,
                            46.1108870000834
                        ],
                        [
                            -116.949851000277,
                            46.124485999782
                        ],
                        [
                            -116.934728999736,
                            46.1372939996941
                        ],
                        [
                            -116.934987000377,
                            46.1535279997221
                        ],
                        [
                            -116.923157000162,
                            46.1603599999705
                        ],
                        [
                            -116.92135000034,
                            46.1645140002413
                        ],
                        [
                            -116.922341000202,
                            46.1687739997623
                        ],
                        [
                            -116.927474999612,
                            46.173293999817
                        ],
                        [
                            -116.932733999593,
                            46.1732429997998
                        ],
                        [
                            -116.935957000322,
                            46.1752270002795
                        ],
                        [
                            -116.939836000279,
                            46.1797429999177
                        ],
                        [
                            -116.941429999665,
                            46.184682000248
                        ],
                        [
                            -116.949630000344,
                            46.1916759999225
                        ],
                        [
                            -116.962299999909,
                            46.1993359996974
                        ],
                        [
                            -116.965383999823,
                            46.2031220002537
                        ],
                        [
                            -116.966306000088,
                            46.2091949999443
                        ],
                        [
                            -116.957374000014,
                            46.2235570000075
                        ],
                        [
                            -116.955480000154,
                            46.2309840000926
                        ],
                        [
                            -116.959236000087,
                            46.2431650000685
                        ],
                        [
                            -116.967804999938,
                            46.2584749997537
                        ],
                        [
                            -116.974808999816,
                            46.2654989999871
                        ],
                        [
                            -116.98655799984,
                            46.2723739999813
                        ],
                        [
                            -116.991195000031,
                            46.2769600003054
                        ],
                        [
                            -116.990866999968,
                            46.2808550000915
                        ],
                        [
                            -116.98530099984,
                            46.2891860001407
                        ],
                        [
                            -116.984803999722,
                            46.2930880001254
                        ],
                        [
                            -116.986216000432,
                            46.2959500001555
                        ],
                        [
                            -116.990849000424,
                            46.3002330001079
                        ],
                        [
                            -117.007380000404,
                            46.3052439996953
                        ],
                        [
                            -117.020581000422,
                            46.3147890001006
                        ],
                        [
                            -117.023072999784,
                            46.3198579997681
                        ],
                        [
                            -117.022831999759,
                            46.3335670001353
                        ],
                        [
                            -117.02430499967,
                            46.3362759998628
                        ],
                        [
                            -117.033483999616,
                            46.3412370001704
                        ],
                        [
                            -117.049504999958,
                            46.3430100002963
                        ],
                        [
                            -117.056646999928,
                            46.3458110001138
                        ],
                        [
                            -117.060296999556,
                            46.348380999904
                        ],
                        [
                            -117.062787999992,
                            46.3536049997825
                        ],
                        [
                            -117.062777999946,
                            46.365175000012
                        ],
                        [
                            -117.05926999971,
                            46.3699999997377
                        ],
                        [
                            -117.046893999872,
                            46.3796150000163
                        ],
                        [
                            -117.038570000243,
                            46.4054819998042
                        ],
                        [
                            -117.035399000291,
                            46.4103280002018
                        ],
                        [
                            -117.034625999989,
                            46.4178449998014
                        ],
                        [
                            -117.039766000145,
                            46.4258870000347
                        ],
                        [
                            -117.039798999757,
                            47.4543400000087
                        ],
                        [
                            -117.04230899956,
                            47.9622889998434
                        ],
                        [
                            -117.041106999811,
                            48.1249040001375
                        ],
                        [
                            -117.035178000358,
                            48.3708780001528
                        ],
                        [
                            -117.032351000362,
                            48.9991879998893
                        ],
                        [
                            -116.049085999597,
                            49.0008459998511
                        ],
                        [
                            -116.049108000237,
                            47.9768420000676
                        ],
                        [
                            -116.038504999886,
                            47.9713450002131
                        ],
                        [
                            -116.030387000122,
                            47.972618999702
                        ],
                        [
                            -116.024030999973,
                            47.9654520002041
                        ],
                        [
                            -116.018771000167,
                            47.9628949999733
                        ],
                        [
                            -116.0074840001,
                            47.950783000086
                        ],
                        [
                            -116.00665899992,
                            47.9433410001697
                        ],
                        [
                            -115.998127999704,
                            47.9384770002731
                        ],
                        [
                            -115.998302999606,
                            47.9366280002686
                        ],
                        [
                            -115.995120999783,
                            47.933827000042
                        ],
                        [
                            -115.993375000061,
                            47.9259769999306
                        ],
                        [
                            -115.984651000399,
                            47.9225589998708
                        ],
                        [
                            -115.982443999646,
                            47.9156370001401
                        ],
                        [
                            -115.969603000376,
                            47.9143929997429
                        ],
                        [
                            -115.966869000267,
                            47.9125980002813
                        ],
                        [
                            -115.965383999763,
                            47.9101970001539
                        ],
                        [
                            -115.968389000034,
                            47.8992499998039
                        ],
                        [
                            -115.960201999774,
                            47.8980330000745
                        ],
                        [
                            -115.954085999825,
                            47.8945200000224
                        ],
                        [
                            -115.949749000109,
                            47.8890039999914
                        ],
                        [
                            -115.938009000306,
                            47.8810930001487
                        ],
                        [
                            -115.935481000061,
                            47.8741529997252
                        ],
                        [
                            -115.92523400026,
                            47.8668529999591
                        ],
                        [
                            -115.919422000084,
                            47.857447999932
                        ],
                        [
                            -115.918884999784,
                            47.8536200001913
                        ],
                        [
                            -115.909316999675,
                            47.8526310001929
                        ],
                        [
                            -115.906492000228,
                            47.8502819997154
                        ],
                        [
                            -115.906821000116,
                            47.8463829999886
                        ],
                        [
                            -115.901400999578,
                            47.8430970000849
                        ],
                        [
                            -115.881496000155,
                            47.8496119999719
                        ],
                        [
                            -115.875262000205,
                            47.843271999817
                        ],
                        [
                            -115.870741000366,
                            47.8349619998184
                        ],
                        [
                            -115.852312000328,
                            47.8278570001505
                        ],
                        [
                            -115.850083999659,
                            47.8201499999602
                        ],
                        [
                            -115.845065999702,
                            47.8149990000612
                        ],
                        [
                            -115.848770999582,
                            47.8100910001466
                        ],
                        [
                            -115.847327999808,
                            47.8034410001803
                        ],
                        [
                            -115.848944999659,
                            47.8001870000569
                        ],
                        [
                            -115.845796000346,
                            47.794057999841
                        ],
                        [
                            -115.847352999923,
                            47.7850939997806
                        ],
                        [
                            -115.840544000038,
                            47.7803550000375
                        ],
                        [
                            -115.840426000037,
                            47.777901000209
                        ],
                        [
                            -115.837438000383,
                            47.7748460001819
                        ],
                        [
                            -115.835069000146,
                            47.7700600002334
                        ],
                        [
                            -115.835423999974,
                            47.7614820002014
                        ],
                        [
                            -115.830909999807,
                            47.7550360000441
                        ],
                        [
                            -115.82499300005,
                            47.7523730002792
                        ],
                        [
                            -115.804609000053,
                            47.7581420002932
                        ],
                        [
                            -115.797498000044,
                            47.7573339997438
                        ],
                        [
                            -115.793730000415,
                            47.7519439999421
                        ],
                        [
                            -115.786072999885,
                            47.748371000234
                        ],
                        [
                            -115.781002000225,
                            47.7431840001938
                        ],
                        [
                            -115.783659000341,
                            47.7376820001709
                        ],
                        [
                            -115.782530000387,
                            47.7348260001074
                        ],
                        [
                            -115.784257999668,
                            47.7299630001739
                        ],
                        [
                            -115.777150999857,
                            47.7203759999627
                        ],
                        [
                            -115.773441999779,
                            47.7175680002183
                        ],
                        [
                            -115.76456800033,
                            47.7174060000205
                        ],
                        [
                            -115.75947500003,
                            47.7192089999945
                        ],
                        [
                            -115.753025999995,
                            47.7169500002964
                        ],
                        [
                            -115.731354000035,
                            47.7049419997774
                        ],
                        [
                            -115.722647999917,
                            47.695118000139
                        ],
                        [
                            -115.728281999817,
                            47.6817080000574
                        ],
                        [
                            -115.726588999798,
                            47.6728640000677
                        ],
                        [
                            -115.732173000368,
                            47.6682100000115
                        ],
                        [
                            -115.731403000439,
                            47.6616889997318
                        ],
                        [
                            -115.736508000434,
                            47.6538109998884
                        ],
                        [
                            -115.729864000406,
                            47.6419199998673
                        ],
                        [
                            -115.707671999864,
                            47.6347150002304
                        ],
                        [
                            -115.694444000082,
                            47.6237610000345
                        ],
                        [
                            -115.693501999725,
                            47.6181909998942
                        ],
                        [
                            -115.691899000118,
                            47.6172300003009
                        ],
                        [
                            -115.698745999638,
                            47.6119750002253
                        ],
                        [
                            -115.698442999689,
                            47.6065789997029
                        ],
                        [
                            -115.696999999916,
                            47.6035549998656
                        ],
                        [
                            -115.692831000429,
                            47.6017949999326
                        ],
                        [
                            -115.691735000087,
                            47.5972419998765
                        ],
                        [
                            -115.68775800022,
                            47.5952999998225
                        ],
                        [
                            -115.704782000119,
                            47.5850430000103
                        ],
                        [
                            -115.706035999747,
                            47.5776260000774
                        ],
                        [
                            -115.721195000098,
                            47.5762540000176
                        ],
                        [
                            -115.723745999909,
                            47.5705959997347
                        ],
                        [
                            -115.730696000259,
                            47.5695849998308
                        ],
                        [
                            -115.740953000106,
                            47.5634470001796
                        ],
                        [
                            -115.744682000275,
                            47.5585439997621
                        ],
                        [
                            -115.754513999974,
                            47.5524539998671
                        ],
                        [
                            -115.755620000362,
                            47.5479329998964
                        ],
                        [
                            -115.751793000284,
                            47.5419869997851
                        ],
                        [
                            -115.742327000282,
                            47.5383250001595
                        ],
                        [
                            -115.736435999566,
                            47.5397930000434
                        ],
                        [
                            -115.725118000436,
                            47.5341189998244
                        ],
                        [
                            -115.717105000254,
                            47.5342220001514
                        ],
                        [
                            -115.711368000421,
                            47.5323710001519
                        ],
                        [
                            -115.706436999605,
                            47.5350060000251
                        ],
                        [
                            -115.702064999728,
                            47.5342099999942
                        ],
                        [
                            -115.698313999818,
                            47.5313750002208
                        ],
                        [
                            -115.695399999783,
                            47.5277130000298
                        ],
                        [
                            -115.711124000024,
                            47.523419000018
                        ],
                        [
                            -115.71139799966,
                            47.5167709999142
                        ],
                        [
                            -115.708403000333,
                            47.5151439999745
                        ],
                        [
                            -115.69863299966,
                            47.5022730001413
                        ],
                        [
                            -115.690026000174,
                            47.5007619997815
                        ],
                        [
                            -115.686264000393,
                            47.4849399998543
                        ],
                        [
                            -115.682578999707,
                            47.4839660002703
                        ],
                        [
                            -115.680516999617,
                            47.4857239998819
                        ],
                        [
                            -115.667918000197,
                            47.488819999813
                        ],
                        [
                            -115.662027000379,
                            47.4831369997373
                        ],
                        [
                            -115.661024999748,
                            47.477847999767
                        ],
                        [
                            -115.648247000227,
                            47.4777270002905
                        ],
                        [
                            -115.63460299962,
                            47.4816159997033
                        ],
                        [
                            -115.629487999578,
                            47.479609999883
                        ],
                        [
                            -115.637590000347,
                            47.462822000086
                        ],
                        [
                            -115.6401629999,
                            47.4596870000742
                        ],
                        [
                            -115.646461000322,
                            47.4568299999324
                        ],
                        [
                            -115.675618000384,
                            47.4532169997076
                        ],
                        [
                            -115.682505000087,
                            47.4569250001849
                        ],
                        [
                            -115.691208999657,
                            47.4572060002662
                        ],
                        [
                            -115.693743999575,
                            47.4546209999337
                        ],
                        [
                            -115.702113000307,
                            47.4525010001018
                        ],
                        [
                            -115.7160940003,
                            47.4528799999376
                        ],
                        [
                            -115.734160999939,
                            47.4442809999765
                        ],
                        [
                            -115.747438999951,
                            47.4420419999868
                        ],
                        [
                            -115.751444000304,
                            47.4367650001756
                        ],
                        [
                            -115.75241400025,
                            47.4321529999545
                        ],
                        [
                            -115.757001000211,
                            47.4287699997375
                        ],
                        [
                            -115.759304000326,
                            47.4238619999714
                        ],
                        [
                            -115.750807000445,
                            47.4221130001559
                        ],
                        [
                            -115.742706000399,
                            47.4235569999631
                        ],
                        [
                            -115.738704000418,
                            47.4264260000056
                        ],
                        [
                            -115.733174000276,
                            47.4248870002825
                        ],
                        [
                            -115.721112000257,
                            47.425697999707
                        ],
                        [
                            -115.720834000423,
                            47.4210760000384
                        ],
                        [
                            -115.716909000435,
                            47.4199469997253
                        ],
                        [
                            -115.712261000374,
                            47.4154420001354
                        ],
                        [
                            -115.703221000345,
                            47.4155610002386
                        ],
                        [
                            -115.699565999796,
                            47.417275000196
                        ],
                        [
                            -115.697384000055,
                            47.4154400002437
                        ],
                        [
                            -115.692043999883,
                            47.4153419999889
                        ],
                        [
                            -115.685605999719,
                            47.4111540001899
                        ],
                        [
                            -115.675245000115,
                            47.4093929999998
                        ],
                        [
                            -115.677563000298,
                            47.4017860002226
                        ],
                        [
                            -115.667940999763,
                            47.4011229999156
                        ],
                        [
                            -115.663361000372,
                            47.4028820001942
                        ],
                        [
                            -115.657490999572,
                            47.4004549998421
                        ],
                        [
                            -115.655595999887,
                            47.39420500011
                        ],
                        [
                            -115.649953999591,
                            47.3923289997353
                        ],
                        [
                            -115.646293999917,
                            47.3877690000603
                        ],
                        [
                            -115.648412999908,
                            47.382432000053
                        ],
                        [
                            -115.643410999845,
                            47.3773400001394
                        ],
                        [
                            -115.636612000006,
                            47.3797290000613
                        ],
                        [
                            -115.630902999763,
                            47.3791800002481
                        ],
                        [
                            -115.617247000358,
                            47.3825199998735
                        ],
                        [
                            -115.601914999755,
                            47.3774889998659
                        ],
                        [
                            -115.595780000438,
                            47.3717189998676
                        ],
                        [
                            -115.57763900028,
                            47.367404000108
                        ],
                        [
                            -115.576039000148,
                            47.3653250000848
                        ],
                        [
                            -115.578329999668,
                            47.360737000295
                        ],
                        [
                            -115.575126999929,
                            47.3559030001108
                        ],
                        [
                            -115.571170999979,
                            47.3556729998383
                        ],
                        [
                            -115.564152000032,
                            47.3522429999958
                        ],
                        [
                            -115.556845000204,
                            47.3539269999737
                        ],
                        [
                            -115.550762999692,
                            47.3496860001264
                        ],
                        [
                            -115.548841000243,
                            47.3469580002441
                        ],
                        [
                            -115.550473000163,
                            47.3342629997019
                        ],
                        [
                            -115.538834999568,
                            47.3290590001109
                        ],
                        [
                            -115.540875999742,
                            47.3245889999497
                        ],
                        [
                            -115.537062000082,
                            47.3178379997588
                        ],
                        [
                            -115.527903000227,
                            47.3090210001413
                        ],
                        [
                            -115.528509000125,
                            47.2992439999629
                        ],
                        [
                            -115.514590999881,
                            47.296566999896
                        ],
                        [
                            -115.503466000196,
                            47.290716000198
                        ],
                        [
                            -115.490837999565,
                            47.2894820002883
                        ],
                        [
                            -115.482263999691,
                            47.2814440002792
                        ],
                        [
                            -115.465818000101,
                            47.2874769997945
                        ],
                        [
                            -115.457726000276,
                            47.2823999998036
                        ],
                        [
                            -115.457255000098,
                            47.2783210000762
                        ],
                        [
                            -115.455907999685,
                            47.2776179998332
                        ],
                        [
                            -115.449638999575,
                            47.2783979998812
                        ],
                        [
                            -115.444685000091,
                            47.2770210002489
                        ],
                        [
                            -115.436461999847,
                            47.2804339998888
                        ],
                        [
                            -115.425967000349,
                            47.2802779998545
                        ],
                        [
                            -115.420474000016,
                            47.2774739997015
                        ],
                        [
                            -115.421387999884,
                            47.2720099999218
                        ],
                        [
                            -115.415102999881,
                            47.2686489999901
                        ],
                        [
                            -115.411584999599,
                            47.264369000133
                        ],
                        [
                            -115.392113999646,
                            47.2653690000495
                        ],
                        [
                            -115.384666000252,
                            47.2678029999385
                        ],
                        [
                            -115.37769599981,
                            47.264832999727
                        ],
                        [
                            -115.370917999888,
                            47.2651799999013
                        ],
                        [
                            -115.365323000346,
                            47.26140299978
                        ],
                        [
                            -115.359219000092,
                            47.2596429999508
                        ],
                        [
                            -115.353848999783,
                            47.262147999981
                        ],
                        [
                            -115.347979999706,
                            47.2603959998469
                        ],
                        [
                            -115.340441000075,
                            47.262869999988
                        ],
                        [
                            -115.323999999609,
                            47.256455999717
                        ],
                        [
                            -115.319785999917,
                            47.2566840000091
                        ],
                        [
                            -115.318381999603,
                            47.2518919999115
                        ],
                        [
                            -115.32436899968,
                            47.2447239998584
                        ],
                        [
                            -115.320481000401,
                            47.2421890000949
                        ],
                        [
                            -115.315818000271,
                            47.2328040001671
                        ],
                        [
                            -115.298784000327,
                            47.2255039999978
                        ],
                        [
                            -115.294927999936,
                            47.2202769997531
                        ],
                        [
                            -115.294095000258,
                            47.2144529999514
                        ],
                        [
                            -115.291529000378,
                            47.2108540000921
                        ],
                        [
                            -115.295985999745,
                            47.2056580002259
                        ],
                        [
                            -115.299099999796,
                            47.1988349997724
                        ],
                        [
                            -115.301226000358,
                            47.1934520001088
                        ],
                        [
                            -115.300576999904,
                            47.1883340002623
                        ],
                        [
                            -115.29096399959,
                            47.1868149997095
                        ],
                        [
                            -115.286848999632,
                            47.1834849997795
                        ],
                        [
                            -115.261821999596,
                            47.1814309999361
                        ],
                        [
                            -115.256196000092,
                            47.1749769998221
                        ],
                        [
                            -115.255462000148,
                            47.163691999725
                        ],
                        [
                            -115.249340000352,
                            47.1596649997446
                        ],
                        [
                            -115.244618999597,
                            47.1513119999658
                        ],
                        [
                            -115.221893000026,
                            47.1489950000914
                        ],
                        [
                            -115.212529999957,
                            47.1419979999681
                        ],
                        [
                            -115.199040000234,
                            47.1398870001529
                        ],
                        [
                            -115.198230999947,
                            47.1371229998349
                        ],
                        [
                            -115.185987000354,
                            47.1294020000779
                        ],
                        [
                            -115.18626299964,
                            47.1243680002947
                        ],
                        [
                            -115.183599999676,
                            47.1216759997169
                        ],
                        [
                            -115.179295999571,
                            47.1203749997263
                        ],
                        [
                            -115.178763000367,
                            47.1174299999735
                        ],
                        [
                            -115.172901999788,
                            47.1122390002798
                        ],
                        [
                            -115.173509000409,
                            47.10971700025
                        ],
                        [
                            -115.171652000358,
                            47.106781999871
                        ],
                        [
                            -115.160125999917,
                            47.1020989997052
                        ],
                        [
                            -115.150012000186,
                            47.1017099999216
                        ],
                        [
                            -115.147253999787,
                            47.1032830002779
                        ],
                        [
                            -115.14412999969,
                            47.1019109998959
                        ],
                        [
                            -115.140162000045,
                            47.09661300006
                        ],
                        [
                            -115.142671999848,
                            47.0914660002123
                        ],
                        [
                            -115.139618999896,
                            47.0800189998024
                        ],
                        [
                            -115.134917000307,
                            47.0756730000473
                        ],
                        [
                            -115.131133999711,
                            47.0689559998658
                        ],
                        [
                            -115.126682000367,
                            47.06608799974
                        ],
                        [
                            -115.122482999845,
                            47.0658869997865
                        ],
                        [
                            -115.120901000154,
                            47.0618100002744
                        ],
                        [
                            -115.107871999665,
                            47.0516939998887
                        ],
                        [
                            -115.105791000207,
                            47.0484129998352
                        ],
                        [
                            -115.101945999687,
                            47.0474189997831
                        ],
                        [
                            -115.098008000179,
                            47.0485809998417
                        ],
                        [
                            -115.087425999744,
                            47.045715999922
                        ],
                        [
                            -115.088363000077,
                            47.0411210002877
                        ],
                        [
                            -115.081856000316,
                            47.0381859998608
                        ],
                        [
                            -115.079799000249,
                            47.0282400000438
                        ],
                        [
                            -115.075665000024,
                            47.025854000188
                        ],
                        [
                            -115.071678999937,
                            47.0263609997592
                        ],
                        [
                            -115.072268000116,
                            47.0234839998934
                        ],
                        [
                            -115.069416999831,
                            47.0150070000416
                        ],
                        [
                            -115.072770000256,
                            47.012905999975
                        ],
                        [
                            -115.065137000016,
                            47.0001650001695
                        ],
                        [
                            -115.067774999865,
                            46.9952120001215
                        ],
                        [
                            -115.061139000233,
                            46.9919340000139
                        ],
                        [
                            -115.0567239998,
                            46.9848449998049
                        ],
                        [
                            -115.049944000228,
                            46.981056999854
                        ],
                        [
                            -115.050667000301,
                            46.975550000232
                        ],
                        [
                            -115.051956000089,
                            46.9749029998243
                        ],
                        [
                            -115.048789000334,
                            46.9700360000633
                        ],
                        [
                            -115.031356000182,
                            46.9717239999755
                        ],
                        [
                            -115.028598999608,
                            46.9756749997741
                        ],
                        [
                            -115.00127400038,
                            46.9719010001306
                        ],
                        [
                            -115.000910000332,
                            46.9677030000003
                        ],
                        [
                            -114.993917000324,
                            46.9622280000887
                        ],
                        [
                            -114.993386999696,
                            46.9596050001183
                        ],
                        [
                            -114.990031999621,
                            46.9564250002386
                        ],
                        [
                            -114.985685999683,
                            46.9539409998388
                        ],
                        [
                            -114.986120000052,
                            46.9497360000442
                        ],
                        [
                            -114.980394999915,
                            46.9473860002322
                        ],
                        [
                            -114.971061999983,
                            46.9389860000497
                        ],
                        [
                            -114.964542999628,
                            46.9380759997867
                        ],
                        [
                            -114.958793000275,
                            46.9336260002626
                        ],
                        [
                            -114.960596999724,
                            46.9300099997573
                        ],
                        [
                            -114.94193099986,
                            46.9240749999439
                        ],
                        [
                            -114.935656999727,
                            46.9198410000579
                        ],
                        [
                            -114.925142999963,
                            46.9186810001945
                        ],
                        [
                            -114.92182800007,
                            46.9127029998376
                        ],
                        [
                            -114.929333000264,
                            46.9098890002741
                        ],
                        [
                            -114.931042000177,
                            46.90408699993
                        ],
                        [
                            -114.933531000064,
                            46.9035860002244
                        ],
                        [
                            -114.936741000374,
                            46.897279000227
                        ],
                        [
                            -114.935111000104,
                            46.8948849997982
                        ],
                        [
                            -114.935703999583,
                            46.892106999874
                        ],
                        [
                            -114.932335000162,
                            46.8874989997454
                        ],
                        [
                            -114.931421000293,
                            46.8769980002265
                        ],
                        [
                            -114.939620000248,
                            46.8688840001035
                        ],
                        [
                            -114.944571000258,
                            46.8678580000298
                        ],
                        [
                            -114.948261000069,
                            46.8600089999093
                        ],
                        [
                            -114.942089999869,
                            46.8565500001075
                        ],
                        [
                            -114.928682999986,
                            46.8547670000153
                        ],
                        [
                            -114.923989999719,
                            46.8481539997689
                        ],
                        [
                            -114.928666000268,
                            46.8436349997205
                        ],
                        [
                            -114.926762000362,
                            46.8387819997487
                        ],
                        [
                            -114.928739000063,
                            46.8352099997894
                        ],
                        [
                            -114.927286000243,
                            46.8334049999221
                        ],
                        [
                            -114.92471699999,
                            46.8334520000095
                        ],
                        [
                            -114.920730999903,
                            46.8270829998976
                        ],
                        [
                            -114.906272999884,
                            46.8223600000675
                        ],
                        [
                            -114.898448000023,
                            46.8223540001413
                        ],
                        [
                            -114.894804000243,
                            46.8173170001241
                        ],
                        [
                            -114.898571000047,
                            46.8089539997802
                        ],
                        [
                            -114.896519000003,
                            46.8072609997636
                        ],
                        [
                            -114.895189000207,
                            46.8016300001307
                        ],
                        [
                            -114.890731000117,
                            46.8029579998689
                        ],
                        [
                            -114.886945999871,
                            46.8095449997838
                        ],
                        [
                            -114.865779000425,
                            46.8145289997529
                        ],
                        [
                            -114.861142000235,
                            46.8116379997156
                        ],
                        [
                            -114.860604000109,
                            46.8057000000321
                        ],
                        [
                            -114.856905999902,
                            46.8016470002201
                        ],
                        [
                            -114.850139999675,
                            46.7990579998514
                        ],
                        [
                            -114.844787999807,
                            46.7943020002589
                        ],
                        [
                            -114.836649000127,
                            46.7924889999266
                        ],
                        [
                            -114.82910399975,
                            46.7826259999915
                        ],
                        [
                            -114.818876000215,
                            46.7812469999192
                        ],
                        [
                            -114.808386999667,
                            46.7823689997806
                        ],
                        [
                            -114.798748000311,
                            46.775832000154
                        ],
                        [
                            -114.789550000099,
                            46.7791139998992
                        ],
                        [
                            -114.787499999705,
                            46.7827759998732
                        ],
                        [
                            -114.784837999565,
                            46.7800190000362
                        ],
                        [
                            -114.786431000025,
                            46.7778859999673
                        ],
                        [
                            -114.783634000166,
                            46.7686660001481
                        ],
                        [
                            -114.779663999973,
                            46.7687270000585
                        ],
                        [
                            -114.778888000196,
                            46.7668470002883
                        ],
                        [
                            -114.774306000257,
                            46.7658060002671
                        ],
                        [
                            -114.773534999605,
                            46.7620869999425
                        ],
                        [
                            -114.765089999603,
                            46.7581500000726
                        ],
                        [
                            -114.767334999991,
                            46.7538289999905
                        ],
                        [
                            -114.765846000186,
                            46.7512390000377
                        ],
                        [
                            -114.76828400002,
                            46.7481519999863
                        ],
                        [
                            -114.765238999566,
                            46.7453909998624
                        ],
                        [
                            -114.769958999597,
                            46.7350420001557
                        ],
                        [
                            -114.773741000368,
                            46.7317489997963
                        ],
                        [
                            -114.779151999787,
                            46.7310170000626
                        ],
                        [
                            -114.77963499966,
                            46.7285889998412
                        ],
                        [
                            -114.782754999559,
                            46.7263499998857
                        ],
                        [
                            -114.78208099989,
                            46.7194990001684
                        ],
                        [
                            -114.786548000201,
                            46.7162919999048
                        ],
                        [
                            -114.788430000365,
                            46.7127699998368
                        ],
                        [
                            -114.78186499998,
                            46.7068329998416
                        ],
                        [
                            -114.76854799961,
                            46.704762999885
                        ],
                        [
                            -114.769515000081,
                            46.6999090002748
                        ],
                        [
                            -114.766785999995,
                            46.6969020000987
                        ],
                        [
                            -114.752198000279,
                            46.6971419997217
                        ],
                        [
                            -114.748479999981,
                            46.6985549998489
                        ],
                        [
                            -114.747464999829,
                            46.7029460002312
                        ],
                        [
                            -114.739773000038,
                            46.7119409998797
                        ],
                        [
                            -114.727444999881,
                            46.714604000041
                        ],
                        [
                            -114.716782999978,
                            46.7140530001606
                        ],
                        [
                            -114.713043999763,
                            46.7154259999815
                        ],
                        [
                            -114.71042500018,
                            46.7177039999769
                        ],
                        [
                            -114.70779999985,
                            46.7238919998537
                        ],
                        [
                            -114.708942000223,
                            46.726871999929
                        ],
                        [
                            -114.700036999913,
                            46.7336680002783
                        ],
                        [
                            -114.701020000278,
                            46.7366019997221
                        ],
                        [
                            -114.69859400014,
                            46.7404510000024
                        ],
                        [
                            -114.688619000326,
                            46.736454000209
                        ],
                        [
                            -114.681724000227,
                            46.7388939997427
                        ],
                        [
                            -114.671927999614,
                            46.7365699999949
                        ],
                        [
                            -114.665375999647,
                            46.7392340003042
                        ],
                        [
                            -114.659575000241,
                            46.7347510001382
                        ],
                        [
                            -114.648412999848,
                            46.7323149999764
                        ],
                        [
                            -114.645959999946,
                            46.7289989998608
                        ],
                        [
                            -114.644626999777,
                            46.7228320002249
                        ],
                        [
                            -114.639272000435,
                            46.7217859998128
                        ],
                        [
                            -114.633825000133,
                            46.7157819999439
                        ],
                        [
                            -114.624932000417,
                            46.7122579998899
                        ],
                        [
                            -114.623730999594,
                            46.7090779999954
                        ],
                        [
                            -114.620787000321,
                            46.7072619999694
                        ],
                        [
                            -114.624682999996,
                            46.6961129999123
                        ],
                        [
                            -114.622908999787,
                            46.694444999924
                        ],
                        [
                            -114.623543000172,
                            46.6909330001531
                        ],
                        [
                            -114.631840999861,
                            46.6839470002309
                        ],
                        [
                            -114.641443000305,
                            46.6794920000125
                        ],
                        [
                            -114.64024400003,
                            46.6753090001443
                        ],
                        [
                            -114.642563000038,
                            46.6729120002496
                        ],
                        [
                            -114.633139999694,
                            46.6718180002579
                        ],
                        [
                            -114.63336599965,
                            46.6696680000767
                        ],
                        [
                            -114.639891999678,
                            46.6656140001439
                        ],
                        [
                            -114.635779000267,
                            46.6594759998205
                        ],
                        [
                            -114.621023000322,
                            46.6579819996944
                        ],
                        [
                            -114.614564000242,
                            46.6550750002573
                        ],
                        [
                            -114.615304999859,
                            46.6524269997831
                        ],
                        [
                            -114.612054000439,
                            46.6506759999276
                        ],
                        [
                            -114.611656000055,
                            46.6474530002511
                        ],
                        [
                            -114.616270999606,
                            46.6437329998536
                        ],
                        [
                            -114.614975000146,
                            46.639975000062
                        ],
                        [
                            -114.605731999727,
                            46.6358999997327
                        ],
                        [
                            -114.599742000175,
                            46.6362960003074
                        ],
                        [
                            -114.593766999793,
                            46.6329420000163
                        ],
                        [
                            -114.588963000096,
                            46.6351700001187
                        ],
                        [
                            -114.582867000239,
                            46.6333670002014
                        ],
                        [
                            -114.570565000021,
                            46.6432989997646
                        ],
                        [
                            -114.563182000026,
                            46.640822999971
                        ],
                        [
                            -114.557462999737,
                            46.6440520000365
                        ],
                        [
                            -114.547032999638,
                            46.6446799997081
                        ],
                        [
                            -114.541822000236,
                            46.6499920002409
                        ],
                        [
                            -114.52384699976,
                            46.6405880000001
                        ],
                        [
                            -114.510032000346,
                            46.6392009998533
                        ],
                        [
                            -114.501055000066,
                            46.6413540002405
                        ],
                        [
                            -114.497613999777,
                            46.637466999983
                        ],
                        [
                            -114.487520999962,
                            46.6331630000109
                        ],
                        [
                            -114.466834000016,
                            46.6318010002066
                        ],
                        [
                            -114.452638999763,
                            46.6415610002509
                        ],
                        [
                            -114.454536999821,
                            46.6442590002084
                        ],
                        [
                            -114.453477000362,
                            46.649209000115
                        ],
                        [
                            -114.449000999831,
                            46.6495329999258
                        ],
                        [
                            -114.424595999586,
                            46.66065999979
                        ],
                        [
                            -114.412115999991,
                            46.6568250000368
                        ],
                        [
                            -114.409576999876,
                            46.6622440002179
                        ],
                        [
                            -114.403855999937,
                            46.6594870001859
                        ],
                        [
                            -114.394519999632,
                            46.6649169997771
                        ],
                        [
                            -114.389284000115,
                            46.6647630000618
                        ],
                        [
                            -114.383723999835,
                            46.6624580002833
                        ],
                        [
                            -114.378054999775,
                            46.6671310001926
                        ],
                        [
                            -114.362276000181,
                            46.6685089998662
                        ],
                        [
                            -114.358223999972,
                            46.6711379997373
                        ],
                        [
                            -114.357572000044,
                            46.6681399998841
                        ],
                        [
                            -114.353000000151,
                            46.663557000252
                        ],
                        [
                            -114.333124999967,
                            46.6609389997892
                        ],
                        [
                            -114.324614999667,
                            46.6534169998433
                        ],
                        [
                            -114.320744999931,
                            46.6470630002723
                        ],
                        [
                            -114.322911999603,
                            46.6429380000322
                        ],
                        [
                            -114.320303000065,
                            46.6382659998665
                        ],
                        [
                            -114.322360000132,
                            46.6338839999342
                        ],
                        [
                            -114.322149000245,
                            46.6258479997329
                        ],
                        [
                            -114.324510000085,
                            46.6219340000049
                        ],
                        [
                            -114.328060999979,
                            46.6197549998405
                        ],
                        [
                            -114.322531999661,
                            46.6108890002842
                        ],
                        [
                            -114.329945999619,
                            46.6042130003017
                        ],
                        [
                            -114.330256000138,
                            46.5994769997866
                        ],
                        [
                            -114.332551999681,
                            46.596732000054
                        ],
                        [
                            -114.344196999948,
                            46.5899690001629
                        ],
                        [
                            -114.339558000108,
                            46.5871310001284
                        ],
                        [
                            -114.334099999935,
                            46.5793580002741
                        ],
                        [
                            -114.32963900037,
                            46.5761989998762
                        ],
                        [
                            -114.331734000072,
                            46.5718560002182
                        ],
                        [
                            -114.339347000221,
                            46.5643410001128
                        ],
                        [
                            -114.342640000372,
                            46.5552250002284
                        ],
                        [
                            -114.347887999584,
                            46.5514710001304
                        ],
                        [
                            -114.345200000404,
                            46.5481349999325
                        ],
                        [
                            -114.343895999649,
                            46.5415100000612
                        ],
                        [
                            -114.348808000199,
                            46.5336560000147
                        ],
                        [
                            -114.349226999601,
                            46.5299440000108
                        ],
                        [
                            -114.347747999842,
                            46.5263450003081
                        ],
                        [
                            -114.34293599975,
                            46.5223729997823
                        ],
                        [
                            -114.342641000197,
                            46.5184310000453
                        ],
                        [
                            -114.347050999708,
                            46.5152809997603
                        ],
                        [
                            -114.348337000021,
                            46.5103340000646
                        ],
                        [
                            -114.358340000323,
                            46.5052119998806
                        ],
                        [
                            -114.367999999595,
                            46.5051130000422
                        ],
                        [
                            -114.375594000376,
                            46.5017539998951
                        ],
                        [
                            -114.383996999647,
                            46.5050730002625
                        ],
                        [
                            -114.399919000254,
                            46.5021929999907
                        ],
                        [
                            -114.403183999918,
                            46.4988110002333
                        ],
                        [
                            -114.403096000054,
                            46.4923469997399
                        ],
                        [
                            -114.400884000176,
                            46.4883829999657
                        ],
                        [
                            -114.400671999566,
                            46.4778129998169
                        ],
                        [
                            -114.395548000202,
                            46.4728970001604
                        ],
                        [
                            -114.39465500025,
                            46.4694260002712
                        ],
                        [
                            -114.383136000379,
                            46.4662579998578
                        ],
                        [
                            -114.379430999601,
                            46.460289999776
                        ],
                        [
                            -114.384400000053,
                            46.4470119998773
                        ],
                        [
                            -114.376196000075,
                            46.4428089998798
                        ],
                        [
                            -114.372339999684,
                            46.4428100001992
                        ],
                        [
                            -114.368413999872,
                            46.4371149998881
                        ],
                        [
                            -114.378830999552,
                            46.4350500002393
                        ],
                        [
                            -114.382960999579,
                            46.4321720002812
                        ],
                        [
                            -114.384909999691,
                            46.4275939999558
                        ],
                        [
                            -114.383661000086,
                            46.4184649997405
                        ],
                        [
                            -114.384842999744,
                            46.4113589998606
                        ],
                        [
                            -114.393495000333,
                            46.4092160000595
                        ],
                        [
                            -114.399689999925,
                            46.4047850000702
                        ],
                        [
                            -114.401336999913,
                            46.4015800001335
                        ],
                        [
                            -114.408550000029,
                            46.4006959999573
                        ],
                        [
                            -114.410038999833,
                            46.3963940000197
                        ],
                        [
                            -114.415616999657,
                            46.393511000203
                        ],
                        [
                            -114.422447000356,
                            46.3870459999551
                        ],
                        [
                            -114.414534999558,
                            46.3708139999053
                        ],
                        [
                            -114.410247000246,
                            46.365730999794
                        ],
                        [
                            -114.41024999972,
                            46.3610539997189
                        ],
                        [
                            -114.412183999764,
                            46.3578619997454
                        ],
                        [
                            -114.414022000446,
                            46.3355640000406
                        ],
                        [
                            -114.421930000147,
                            46.3300869999148
                        ],
                        [
                            -114.422514000303,
                            46.3240520002193
                        ],
                        [
                            -114.430051000285,
                            46.3134369997942
                        ],
                        [
                            -114.431638999823,
                            46.3071810001817
                        ],
                        [
                            -114.433433999949,
                            46.3055490000782
                        ],
                        [
                            -114.432176999948,
                            46.3013559999377
                        ],
                        [
                            -114.429927000436,
                            46.2996339999764
                        ],
                        [
                            -114.428231999869,
                            46.2910790002566
                        ],
                        [
                            -114.425440999858,
                            46.2878560000229
                        ],
                        [
                            -114.427406999689,
                            46.2862309999399
                        ],
                        [
                            -114.427288999688,
                            46.2837660001775
                        ],
                        [
                            -114.432732999617,
                            46.278598000179
                        ],
                        [
                            -114.441117000418,
                            46.2738649999289
                        ],
                        [
                            -114.453657000288,
                            46.270947000299
                        ],
                        [
                            -114.46492099989,
                            46.2732189998106
                        ],
                        [
                            -114.470719999646,
                            46.2660409997603
                        ],
                        [
                            -114.467800999589,
                            46.2564510000001
                        ],
                        [
                            -114.464832000201,
                            46.2522420000646
                        ],
                        [
                            -114.468266999744,
                            46.2486630001862
                        ],
                        [
                            -114.452089999767,
                            46.2412910001959
                        ],
                        [
                            -114.447984999854,
                            46.2315649999137
                        ],
                        [
                            -114.445634999885,
                            46.2197480000464
                        ],
                        [
                            -114.451828999651,
                            46.2148489996919
                        ],
                        [
                            -114.449189999976,
                            46.2092219998197
                        ],
                        [
                            -114.443304000181,
                            46.2030490000753
                        ],
                        [
                            -114.445947999879,
                            46.1739009999648
                        ],
                        [
                            -114.444090999829,
                            46.1692729998996
                        ],
                        [
                            -114.44663800034,
                            46.1679040002799
                        ],
                        [
                            -114.457701999926,
                            46.1701910001398
                        ],
                        [
                            -114.475952999688,
                            46.1606059999579
                        ],
                        [
                            -114.481163000164,
                            46.1621449997943
                        ],
                        [
                            -114.489205999585,
                            46.1676589997124
                        ],
                        [
                            -114.495581999825,
                            46.1679580001592
                        ],
                        [
                            -114.50748400001,
                            46.1646349998535
                        ],
                        [
                            -114.514857999784,
                            46.1676800002837
                        ],
                        [
                            -114.514368000238,
                            46.1539720001335
                        ],
                        [
                            -114.521824999853,
                            46.1507660001113
                        ],
                        [
                            -114.527359000194,
                            46.145410999711
                        ],
                        [
                            -114.521382000162,
                            46.1364500002337
                        ],
                        [
                            -114.517695999651,
                            46.1355849999237
                        ],
                        [
                            -114.520996000373,
                            46.1251120000407
                        ],
                        [
                            -114.504994000298,
                            46.1183900001962
                        ],
                        [
                            -114.495355999869,
                            46.1176759998932
                        ],
                        [
                            -114.488455999748,
                            46.112985999841
                        ],
                        [
                            -114.474453999838,
                            46.1131080001513
                        ],
                        [
                            -114.470882000028,
                            46.1056140001464
                        ],
                        [
                            -114.467008000094,
                            46.1045319998489
                        ],
                        [
                            -114.460475000393,
                            46.0977899996977
                        ],
                        [
                            -114.459480000333,
                            46.0939310002592
                        ],
                        [
                            -114.462011000053,
                            46.0785800002637
                        ],
                        [
                            -114.472351999565,
                            46.0768059999717
                        ],
                        [
                            -114.470798000362,
                            46.0744899997998
                        ],
                        [
                            -114.47212200031,
                            46.0692080001447
                        ],
                        [
                            -114.466988000002,
                            46.0657809999699
                        ],
                        [
                            -114.468286000011,
                            46.0627899998888
                        ],
                        [
                            -114.473900999644,
                            46.0605279998767
                        ],
                        [
                            -114.474704000083,
                            46.0583789997815
                        ],
                        [
                            -114.479138999709,
                            46.0557219998068
                        ],
                        [
                            -114.493349000031,
                            46.0525799997461
                        ],
                        [
                            -114.492880000401,
                            46.0501890000502
                        ],
                        [
                            -114.497510999845,
                            46.0423509998176
                        ],
                        [
                            -114.503303999754,
                            46.0418229998353
                        ],
                        [
                            -114.506647999959,
                            46.0385190000482
                        ],
                        [
                            -114.505728000242,
                            46.0343429999298
                        ],
                        [
                            -114.507504000101,
                            46.03146399974
                        ],
                        [
                            -114.497233999836,
                            46.0278860001689
                        ],
                        [
                            -114.489312999715,
                            46.0278689997668
                        ],
                        [
                            -114.485792999783,
                            46.0300219998119
                        ],
                        [
                            -114.480347000204,
                            46.0303489998726
                        ],
                        [
                            -114.473069999616,
                            46.0141500000165
                        ],
                        [
                            -114.476204999583,
                            46.01127800005
                        ],
                        [
                            -114.484931999618,
                            46.0082480001973
                        ],
                        [
                            -114.487782999903,
                            46.0036460002255
                        ],
                        [
                            -114.484820000363,
                            45.9963070002091
                        ],
                        [
                            -114.479992000377,
                            45.9929019998044
                        ],
                        [
                            -114.474099999836,
                            45.9922469999621
                        ],
                        [
                            -114.464265999588,
                            45.997516000311
                        ],
                        [
                            -114.457825999774,
                            45.9974780000063
                        ],
                        [
                            -114.453644999694,
                            45.990994999968
                        ],
                        [
                            -114.448500000414,
                            45.9876029998384
                        ],
                        [
                            -114.428848999637,
                            45.986558999725
                        ],
                        [
                            -114.421344000341,
                            45.9814109998904
                        ],
                        [
                            -114.411755000316,
                            45.9778700001633
                        ],
                        [
                            -114.410773999602,
                            45.9729999998743
                        ],
                        [
                            -114.406129999739,
                            45.9699809996963
                        ],
                        [
                            -114.40142800015,
                            45.9635440000166
                        ],
                        [
                            -114.402523999593,
                            45.9579439999494
                        ],
                        [
                            -114.40691800011,
                            45.9542450000485
                        ],
                        [
                            -114.411168999612,
                            45.9531099997835
                        ],
                        [
                            -114.414965999553,
                            45.9483899998982
                        ],
                        [
                            -114.424901999908,
                            45.9439810000586
                        ],
                        [
                            -114.426060000175,
                            45.9408069998896
                        ],
                        [
                            -114.431515999799,
                            45.9378910001124
                        ],
                        [
                            -114.431396000149,
                            45.9360400000043
                        ],
                        [
                            -114.414864000345,
                            45.9221329999008
                        ],
                        [
                            -114.416164000003,
                            45.9184330000886
                        ],
                        [
                            -114.413562999963,
                            45.9116310002256
                        ],
                        [
                            -114.404095000312,
                            45.9034699998038
                        ],
                        [
                            -114.395143000146,
                            45.9014330001412
                        ],
                        [
                            -114.393710000419,
                            45.8929689999072
                        ],
                        [
                            -114.388913000395,
                            45.892251999766
                        ],
                        [
                            -114.387602999792,
                            45.8901619998367
                        ],
                        [
                            -114.387868000106,
                            45.8839260001206
                        ],
                        [
                            -114.38984800018,
                            45.8803480002043
                        ],
                        [
                            -114.392928999721,
                            45.8787019998636
                        ],
                        [
                            -114.396057000015,
                            45.871227999744
                        ],
                        [
                            -114.403921000234,
                            45.8718759997259
                        ],
                        [
                            -114.405917000202,
                            45.8703999999618
                        ],
                        [
                            -114.40482099986,
                            45.8664829999021
                        ],
                        [
                            -114.409653999869,
                            45.8515729997866
                        ],
                        [
                            -114.424060000009,
                            45.8558089998099
                        ],
                        [
                            -114.437714999589,
                            45.8626079998493
                        ],
                        [
                            -114.446371000377,
                            45.861431999691
                        ],
                        [
                            -114.453674000006,
                            45.8557949997171
                        ],
                        [
                            -114.461550999746,
                            45.8551340001264
                        ],
                        [
                            -114.464980000339,
                            45.852117000002
                        ],
                        [
                            -114.470324999636,
                            45.8514649998699
                        ],
                        [
                            -114.471975999822,
                            45.8477669999489
                        ],
                        [
                            -114.474890999681,
                            45.8465849997154
                        ],
                        [
                            -114.48709699964,
                            45.8516710002942
                        ],
                        [
                            -114.492240999993,
                            45.8497540002548
                        ],
                        [
                            -114.498994999627,
                            45.8506919998361
                        ],
                        [
                            -114.50360000003,
                            45.8460030001398
                        ],
                        [
                            -114.509644000009,
                            45.8453570001253
                        ],
                        [
                            -114.515128000121,
                            45.8405130000173
                        ],
                        [
                            -114.517379000356,
                            45.8349470000755
                        ],
                        [
                            -114.516447000046,
                            45.8321380002794
                        ],
                        [
                            -114.511482999617,
                            45.8294360002369
                        ],
                        [
                            -114.531018000043,
                            45.8188980003077
                        ],
                        [
                            -114.532609999779,
                            45.8156430001803
                        ],
                        [
                            -114.530183999641,
                            45.8133750002417
                        ],
                        [
                            -114.531028999913,
                            45.8098679997089
                        ],
                        [
                            -114.537130999618,
                            45.8053050002065
                        ],
                        [
                            -114.537605999995,
                            45.8027599997189
                        ],
                        [
                            -114.541419999654,
                            45.7992359998774
                        ],
                        [
                            -114.544967000249,
                            45.797897999812
                        ],
                        [
                            -114.544091000014,
                            45.7903610001057
                        ],
                        [
                            -114.555619000106,
                            45.7865399997521
                        ],
                        [
                            -114.557004000153,
                            45.7834690002316
                        ],
                        [
                            -114.562450999557,
                            45.7797879998412
                        ],
                        [
                            -114.566465000131,
                            45.7733100000983
                        ],
                        [
                            -114.558282999895,
                            45.7647809998231
                        ],
                        [
                            -114.551243000031,
                            45.7639099996877
                        ],
                        [
                            -114.544511000139,
                            45.7591780001819
                        ],
                        [
                            -114.54731499967,
                            45.7518920000868
                        ],
                        [
                            -114.545747000223,
                            45.7469070000049
                        ],
                        [
                            -114.547850000321,
                            45.7432640002218
                        ],
                        [
                            -114.535634000317,
                            45.7390949998353
                        ],
                        [
                            -114.526653999664,
                            45.7276339998907
                        ],
                        [
                            -114.520521999821,
                            45.7265019998673
                        ],
                        [
                            -114.515366999597,
                            45.7274539999296
                        ],
                        [
                            -114.504181999638,
                            45.7218139997893
                        ],
                        [
                            -114.495148000355,
                            45.7039700001472
                        ],
                        [
                            -114.496570000212,
                            45.6994280000639
                        ],
                        [
                            -114.500737999874,
                            45.6965580000259
                        ],
                        [
                            -114.501385999604,
                            45.6937060003057
                        ],
                        [
                            -114.515086000288,
                            45.6849110001241
                        ],
                        [
                            -114.501188999961,
                            45.6824359997705
                        ],
                        [
                            -114.499029999787,
                            45.6788740000805
                        ],
                        [
                            -114.501455000099,
                            45.6773689997905
                        ],
                        [
                            -114.502281000105,
                            45.6739390002716
                        ],
                        [
                            -114.499532999752,
                            45.6687849997555
                        ],
                        [
                            -114.506361999728,
                            45.6618269998531
                        ],
                        [
                            -114.507646000391,
                            45.6575350000729
                        ],
                        [
                            -114.512726000273,
                            45.6560430002956
                        ],
                        [
                            -114.515051999953,
                            45.6531219997024
                        ],
                        [
                            -114.521927999785,
                            45.6494630002393
                        ],
                        [
                            -114.528879999785,
                            45.6524299998638
                        ],
                        [
                            -114.539494999832,
                            45.6505670002354
                        ],
                        [
                            -114.5413080004,
                            45.6485940000171
                        ],
                        [
                            -114.539674999757,
                            45.6460849998699
                        ],
                        [
                            -114.541674000098,
                            45.6413990002666
                        ],
                        [
                            -114.545435000054,
                            45.6429889996922
                        ],
                        [
                            -114.551713000384,
                            45.6424110002407
                        ],
                        [
                            -114.560664999651,
                            45.6402419997659
                        ],
                        [
                            -114.563987000115,
                            45.6376210003049
                        ],
                        [
                            -114.563229999706,
                            45.6316689999645
                        ],
                        [
                            -114.55829299994,
                            45.6273919999341
                        ],
                        [
                            -114.554598999931,
                            45.6198819996898
                        ],
                        [
                            -114.544687999691,
                            45.6169759998104
                        ],
                        [
                            -114.537944000103,
                            45.6065919999206
                        ],
                        [
                            -114.542017000229,
                            45.6020230002646
                        ],
                        [
                            -114.547643999558,
                            45.5996790001755
                        ],
                        [
                            -114.549272000178,
                            45.5953380000313
                        ],
                        [
                            -114.554035999692,
                            45.5914940001585
                        ],
                        [
                            -114.553875000033,
                            45.5884219998554
                        ],
                        [
                            -114.55849799998,
                            45.584463000041
                        ],
                        [
                            -114.554340000364,
                            45.5814800003035
                        ],
                        [
                            -114.552956000141,
                            45.576603000284
                        ],
                        [
                            -114.556775999649,
                            45.5729260002653
                        ],
                        [
                            -114.558522000269,
                            45.5660490000758
                        ],
                        [
                            -114.565490999988,
                            45.5626009998652
                        ],
                        [
                            -114.564936000144,
                            45.5577099999337
                        ],
                        [
                            -114.560765999934,
                            45.559295999948
                        ],
                        [
                            -114.552638000124,
                            45.557019999837
                        ],
                        [
                            -114.54858300044,
                            45.5610579997949
                        ],
                        [
                            -114.53193600011,
                            45.5662099997421
                        ],
                        [
                            -114.527259999561,
                            45.570855000153
                        ],
                        [
                            -114.516341999565,
                            45.56799500026
                        ],
                        [
                            -114.506863000044,
                            45.5593049999373
                        ],
                        [
                            -114.503078999623,
                            45.5590670000838
                        ],
                        [
                            -114.498079999933,
                            45.5554610003021
                        ],
                        [
                            -114.491917999953,
                            45.5571030000408
                        ],
                        [
                            -114.485249999635,
                            45.5618010000422
                        ],
                        [
                            -114.478044999915,
                            45.5619809997063
                        ],
                        [
                            -114.471945999685,
                            45.565223
                        ],
                        [
                            -114.465731999826,
                            45.5605069997463
                        ],
                        [
                            -114.456076999679,
                            45.5622400001432
                        ],
                        [
                            -114.462763000439,
                            45.5539500000015
                        ],
                        [
                            -114.461589000279,
                            45.5508020002934
                        ],
                        [
                            -114.463911999586,
                            45.5450289998123
                        ],
                        [
                            -114.450524999795,
                            45.542345999891
                        ],
                        [
                            -114.448801999639,
                            45.5388800002507
                        ],
                        [
                            -114.439942000433,
                            45.5369829998798
                        ],
                        [
                            -114.437122999935,
                            45.5308110000708
                        ],
                        [
                            -114.430574000341,
                            45.5295100000632
                        ],
                        [
                            -114.430903000229,
                            45.5243979999015
                        ],
                        [
                            -114.428785999888,
                            45.5209159997785
                        ],
                        [
                            -114.429533000251,
                            45.5175389997742
                        ],
                        [
                            -114.425706999997,
                            45.510718999989
                        ],
                        [
                            -114.423414999754,
                            45.5092989999414
                        ],
                        [
                            -114.413742000063,
                            45.5089880000861
                        ],
                        [
                            -114.392332000044,
                            45.5024589999582
                        ],
                        [
                            -114.388869999839,
                            45.503002999893
                        ],
                        [
                            -114.375339000107,
                            45.4939589998235
                        ],
                        [
                            -114.367201000252,
                            45.4918540000538
                        ],
                        [
                            -114.365119000071,
                            45.4893139998811
                        ],
                        [
                            -114.364560999854,
                            45.4836129999558
                        ],
                        [
                            -114.360289000435,
                            45.478228999834
                        ],
                        [
                            -114.361097999823,
                            45.4744089997258
                        ],
                        [
                            -114.351624000324,
                            45.4710359998554
                        ],
                        [
                            -114.350651000006,
                            45.4664709999816
                        ],
                        [
                            -114.345662000361,
                            45.4606569998346
                        ],
                        [
                            -114.330046999901,
                            45.459722000186
                        ],
                        [
                            -114.329707000142,
                            45.4620269999991
                        ],
                        [
                            -114.324357999749,
                            45.4658900002346
                        ],
                        [
                            -114.318736000443,
                            45.4661489997533
                        ],
                        [
                            -114.314403999851,
                            45.4696790002165
                        ],
                        [
                            -114.309219000388,
                            45.4693119999185
                        ],
                        [
                            -114.30378600033,
                            45.4715629997244
                        ],
                        [
                            -114.29506000012,
                            45.4793729999913
                        ],
                        [
                            -114.279996000204,
                            45.4798750001356
                        ],
                        [
                            -114.269891999619,
                            45.4845520000452
                        ],
                        [
                            -114.270022000214,
                            45.4908680001756
                        ],
                        [
                            -114.264548999973,
                            45.4921530000637
                        ],
                        [
                            -114.261475000105,
                            45.4949309997522
                        ],
                        [
                            -114.258501999621,
                            45.5112330000427
                        ],
                        [
                            -114.253473999618,
                            45.5150509998202
                        ],
                        [
                            -114.247210000429,
                            45.5247190001121
                        ],
                        [
                            -114.249471999637,
                            45.5292910000259
                        ],
                        [
                            -114.248734000394,
                            45.5345709999215
                        ],
                        [
                            -114.251589999804,
                            45.5375039998085
                        ],
                        [
                            -114.248449999813,
                            45.5458130000792
                        ],
                        [
                            -114.227873000371,
                            45.5461289998652
                        ],
                        [
                            -114.205262000428,
                            45.5359230001007
                        ],
                        [
                            -114.19360799994,
                            45.536372000113
                        ],
                        [
                            -114.180273000027,
                            45.5512380001408
                        ],
                        [
                            -114.174179999645,
                            45.5501600001038
                        ],
                        [
                            -114.164558000008,
                            45.5524979998665
                        ],
                        [
                            -114.154211999574,
                            45.5526179998649
                        ],
                        [
                            -114.150243999929,
                            45.5578310002197
                        ],
                        [
                            -114.13582100007,
                            45.5570980001305
                        ],
                        [
                            -114.133163999953,
                            45.5622639999022
                        ],
                        [
                            -114.128987000071,
                            45.5653850001074
                        ],
                        [
                            -114.129012000185,
                            45.5696859997026
                        ],
                        [
                            -114.132125000411,
                            45.5720950002398
                        ],
                        [
                            -114.130694000333,
                            45.5758330000676
                        ],
                        [
                            -114.121261999769,
                            45.5845920000071
                        ],
                        [
                            -114.100503999678,
                            45.5862039999241
                        ],
                        [
                            -114.090000999784,
                            45.5913899997221
                        ],
                        [
                            -114.086101999735,
                            45.5914640001975
                        ],
                        [
                            -114.082262999961,
                            45.5966990001884
                        ],
                        [
                            -114.083077000271,
                            45.6031399999384
                        ],
                        [
                            -114.081857999905,
                            45.610567999859
                        ],
                        [
                            -114.073561000041,
                            45.6150819997461
                        ],
                        [
                            -114.072453000003,
                            45.6212539999947
                        ],
                        [
                            -114.066737999912,
                            45.6281659999842
                        ],
                        [
                            -114.051838000028,
                            45.6327050002223
                        ],
                        [
                            -114.045133999725,
                            45.6367619998665
                        ],
                        [
                            -114.043249000085,
                            45.6404109998683
                        ],
                        [
                            -114.034556000386,
                            45.6476160003082
                        ],
                        [
                            -114.019068999972,
                            45.6484030002343
                        ],
                        [
                            -114.015270000381,
                            45.6527660001746
                        ],
                        [
                            -114.013703999685,
                            45.6582920000042
                        ],
                        [
                            -114.015532000322,
                            45.6605789997821
                        ],
                        [
                            -114.015658999645,
                            45.6642499997153
                        ],
                        [
                            -114.019120000026,
                            45.6696539999142
                        ],
                        [
                            -114.0238579996,
                            45.6706330001652
                        ],
                        [
                            -114.028309999842,
                            45.6752370000112
                        ],
                        [
                            -114.014365999659,
                            45.6837690002091
                        ],
                        [
                            -114.019167999706,
                            45.692631000308
                        ],
                        [
                            -114.015482000093,
                            45.6959010000388
                        ],
                        [
                            -114.008279000023,
                            45.6938309998983
                        ],
                        [
                            -113.996841000343,
                            45.6968580000944
                        ],
                        [
                            -113.993721000445,
                            45.700652000161
                        ],
                        [
                            -113.993903999844,
                            45.7033810001771
                        ],
                        [
                            -113.988526000038,
                            45.7059769998933
                        ],
                        [
                            -113.98000800024,
                            45.7023699998253
                        ],
                        [
                            -113.97076700037,
                            45.7029289998582
                        ],
                        [
                            -113.971738999965,
                            45.7008250001237
                        ],
                        [
                            -113.969187000329,
                            45.6927279998175
                        ],
                        [
                            -113.964816000277,
                            45.6900190000235
                        ],
                        [
                            -113.954877000448,
                            45.6906249998614
                        ],
                        [
                            -113.939104999629,
                            45.6957800000121
                        ],
                        [
                            -113.935260000007,
                            45.6943409997077
                        ],
                        [
                            -113.933958999625,
                            45.692113999735
                        ],
                        [
                            -113.93566199969,
                            45.6890940000164
                        ],
                        [
                            -113.932816000326,
                            45.6871729999723
                        ],
                        [
                            -113.934274000168,
                            45.6820049996885
                        ],
                        [
                            -113.930849999597,
                            45.6781720000734
                        ],
                        [
                            -113.931425000431,
                            45.6730360001388
                        ],
                        [
                            -113.920617999865,
                            45.6640649998493
                        ],
                        [
                            -113.919856000332,
                            45.6581169999516
                        ],
                        [
                            -113.914306999922,
                            45.6564260000681
                        ],
                        [
                            -113.911614999646,
                            45.6533069999357
                        ],
                        [
                            -113.907338000204,
                            45.6532490001116
                        ],
                        [
                            -113.903374999683,
                            45.6511279999492
                        ],
                        [
                            -113.895277999835,
                            45.64384700031
                        ],
                        [
                            -113.902392000217,
                            45.6375469997859
                        ],
                        [
                            -113.901452000409,
                            45.630240999848
                        ],
                        [
                            -113.899428999779,
                            45.6285040002781
                        ],
                        [
                            -113.904498999614,
                            45.6246820002465
                        ],
                        [
                            -113.904691000133,
                            45.6220069999997
                        ],
                        [
                            -113.89795199967,
                            45.6219039997851
                        ],
                        [
                            -113.885963000345,
                            45.6173820000703
                        ],
                        [
                            -113.861589000063,
                            45.6238200001058
                        ],
                        [
                            -113.858907999657,
                            45.621667000311
                        ],
                        [
                            -113.854608999575,
                            45.6222820001337
                        ],
                        [
                            -113.848639000115,
                            45.6201089998879
                        ],
                        [
                            -113.843495999586,
                            45.6127429998582
                        ],
                        [
                            -113.83888000021,
                            45.610691000203
                        ],
                        [
                            -113.834272000332,
                            45.6100760001842
                        ],
                        [
                            -113.827871999803,
                            45.612906000229
                        ],
                        [
                            -113.824095999778,
                            45.6128030002618
                        ],
                        [
                            -113.81819400009,
                            45.6078720002938
                        ],
                        [
                            -113.811028999654,
                            45.6057430002701
                        ],
                        [
                            -113.806430999822,
                            45.6016299999543
                        ],
                        [
                            -113.803710999956,
                            45.5926310003128
                        ],
                        [
                            -113.798752000449,
                            45.5870430002498
                        ],
                        [
                            -113.796746000436,
                            45.5815730000744
                        ],
                        [
                            -113.806928999764,
                            45.5781899998707
                        ],
                        [
                            -113.814983999779,
                            45.5696500000999
                        ],
                        [
                            -113.821468999799,
                            45.5659480001473
                        ],
                        [
                            -113.818031999708,
                            45.5626139999931
                        ],
                        [
                            -113.817408000267,
                            45.5570329999983
                        ],
                        [
                            -113.821457999928,
                            45.5473939997703
                        ],
                        [
                            -113.826039000042,
                            45.5436939998641
                        ],
                        [
                            -113.82906500023,
                            45.5381559998715
                        ],
                        [
                            -113.827429999937,
                            45.533283999945
                        ],
                        [
                            -113.829974000076,
                            45.5309150000595
                        ],
                        [
                            -113.834777999772,
                            45.5206570002449
                        ],
                        [
                            -113.82387400002,
                            45.5214280002549
                        ],
                        [
                            -113.820168000316,
                            45.5230999997166
                        ],
                        [
                            -113.809658999676,
                            45.519795000092
                        ],
                        [
                            -113.803820999561,
                            45.5227539999921
                        ],
                        [
                            -113.796856000041,
                            45.5234080000277
                        ],
                        [
                            -113.782138999556,
                            45.5218590001725
                        ],
                        [
                            -113.777960000023,
                            45.5232929997553
                        ],
                        [
                            -113.766021999853,
                            45.5206210000719
                        ],
                        [
                            -113.772843000332,
                            45.5133070001306
                        ],
                        [
                            -113.770069999864,
                            45.5120440000164
                        ],
                        [
                            -113.770906999741,
                            45.506979000282
                        ],
                        [
                            -113.764785999769,
                            45.4992100002177
                        ],
                        [
                            -113.76325900033,
                            45.4907859998311
                        ],
                        [
                            -113.760064999913,
                            45.4862260000653
                        ],
                        [
                            -113.7599140003,
                            45.4804000000663
                        ],
                        [
                            -113.768064999676,
                            45.4739369997606
                        ],
                        [
                            -113.776221999798,
                            45.4709049998764
                        ],
                        [
                            -113.774640999933,
                            45.4630100003141
                        ],
                        [
                            -113.784264000293,
                            45.4548860002542
                        ],
                        [
                            -113.783541000219,
                            45.4519779997976
                        ],
                        [
                            -113.774426999672,
                            45.4465099997034
                        ],
                        [
                            -113.776888999795,
                            45.4363329998959
                        ],
                        [
                            -113.776034000376,
                            45.43374599992
                        ],
                        [
                            -113.77354499959,
                            45.4317050001895
                        ],
                        [
                            -113.765032999641,
                            45.431061999706
                        ],
                        [
                            -113.759732999652,
                            45.427110000251
                        ],
                        [
                            -113.762794999824,
                            45.4230739998023
                        ],
                        [
                            -113.77274200005,
                            45.4197910001072
                        ],
                        [
                            -113.777159999957,
                            45.4165630001676
                        ],
                        [
                            -113.776042999698,
                            45.40847700005
                        ],
                        [
                            -113.773753000003,
                            45.4063589997577
                        ],
                        [
                            -113.761793999917,
                            45.4065790001378
                        ],
                        [
                            -113.752731000321,
                            45.4027680002244
                        ],
                        [
                            -113.749192000123,
                            45.4028999998639
                        ],
                        [
                            -113.743892000134,
                            45.396585999985
                        ],
                        [
                            -113.733474999555,
                            45.3908110000167
                        ],
                        [
                            -113.732475000371,
                            45.384847999945
                        ],
                        [
                            -113.734386999774,
                            45.3765690003121
                        ],
                        [
                            -113.732919999711,
                            45.3731409998492
                        ],
                        [
                            -113.7388790002,
                            45.3681299999498
                        ],
                        [
                            -113.733514999738,
                            45.3640620000018
                        ],
                        [
                            -113.731321000302,
                            45.3577979997044
                        ],
                        [
                            -113.729029999884,
                            45.3560859998787
                        ],
                        [
                            -113.734432999805,
                            45.3531270000668
                        ],
                        [
                            -113.734681000401,
                            45.3483490001859
                        ],
                        [
                            -113.740342000065,
                            45.3454390000671
                        ],
                        [
                            -113.738660999742,
                            45.3294089997141
                        ],
                        [
                            -113.728574999599,
                            45.3199079999253
                        ],
                        [
                            -113.724068000003,
                            45.3196630000694
                        ],
                        [
                            -113.722687999979,
                            45.3175260002781
                        ],
                        [
                            -113.718582000241,
                            45.3168050001022
                        ],
                        [
                            -113.718480999959,
                            45.3133580000032
                        ],
                        [
                            -113.715828000041,
                            45.3113840000869
                        ],
                        [
                            -113.71332699956,
                            45.3044809998497
                        ],
                        [
                            -113.710035000132,
                            45.3010150001999
                        ],
                        [
                            -113.703752999603,
                            45.2991779999157
                        ],
                        [
                            -113.702904000032,
                            45.2949380003112
                        ],
                        [
                            -113.700569999955,
                            45.2925180002118
                        ],
                        [
                            -113.687264000355,
                            45.2859620002164
                        ],
                        [
                            -113.680278999845,
                            45.2788859998862
                        ],
                        [
                            -113.67896199957,
                            45.2758889999835
                        ],
                        [
                            -113.680320999678,
                            45.27443999975
                        ],
                        [
                            -113.691908000218,
                            45.2723559999519
                        ],
                        [
                            -113.690754999975,
                            45.2689549999034
                        ],
                        [
                            -113.692936999715,
                            45.2641970001134
                        ],
                        [
                            -113.691887000302,
                            45.2625030001296
                        ],
                        [
                            -113.686634999993,
                            45.2574070003106
                        ],
                        [
                            -113.68502399999,
                            45.2535050003087
                        ],
                        [
                            -113.679611000023,
                            45.2497459999038
                        ],
                        [
                            -113.666872999787,
                            45.2469770001229
                        ],
                        [
                            -113.655425000062,
                            45.2400060000828
                        ],
                        [
                            -113.649131999663,
                            45.2339989999696
                        ],
                        [
                            -113.647288000031,
                            45.2256420000581
                        ],
                        [
                            -113.636914999834,
                            45.2167609997965
                        ],
                        [
                            -113.636351999594,
                            45.2118470002676
                        ],
                        [
                            -113.623883999695,
                            45.2061610002099
                        ],
                        [
                            -113.617920000082,
                            45.2024310001734
                        ],
                        [
                            -113.614484999641,
                            45.198070999968
                        ],
                        [
                            -113.609612000347,
                            45.1973030000326
                        ],
                        [
                            -113.604329999901,
                            45.1920849999232
                        ],
                        [
                            -113.599685000213,
                            45.1921610001336
                        ],
                        [
                            -113.597230999588,
                            45.1864750001616
                        ],
                        [
                            -113.592133000163,
                            45.1847789997715
                        ],
                        [
                            -113.592185999867,
                            45.1811360001157
                        ],
                        [
                            -113.589449000283,
                            45.1773009996966
                        ],
                        [
                            -113.594189000405,
                            45.1696329998044
                        ],
                        [
                            -113.594241000284,
                            45.1660589997745
                        ],
                        [
                            -113.590593000306,
                            45.1613600002045
                        ],
                        [
                            -113.590707000108,
                            45.1558860002811
                        ],
                        [
                            -113.583221000181,
                            45.1535750000347
                        ],
                        [
                            -113.582421000115,
                            45.1501170002526
                        ],
                        [
                            -113.579955999619,
                            45.1483539997305
                        ],
                        [
                            -113.570776999673,
                            45.1479739998864
                        ],
                        [
                            -113.565694000317,
                            45.1496020002695
                        ],
                        [
                            -113.567043000379,
                            45.1431200002683
                        ],
                        [
                            -113.57672500029,
                            45.1312369998618
                        ],
                        [
                            -113.56988399972,
                            45.1238509999471
                        ],
                        [
                            -113.566112999718,
                            45.1241280002215
                        ],
                        [
                            -113.554038000179,
                            45.1185600002086
                        ],
                        [
                            -113.54966299993,
                            45.1128790001355
                        ],
                        [
                            -113.537097000121,
                            45.1123609999013
                        ],
                        [
                            -113.529608999646,
                            45.1164020000831
                        ],
                        [
                            -113.520239999729,
                            45.1149990001609
                        ],
                        [
                            -113.511249000103,
                            45.1161289997089
                        ],
                        [
                            -113.506478000019,
                            45.1107859997201
                        ],
                        [
                            -113.506048999673,
                            45.1077840002341
                        ],
                        [
                            -113.512041999599,
                            45.0994179997707
                        ],
                        [
                            -113.521204999651,
                            45.0939250003062
                        ],
                        [
                            -113.522187000191,
                            45.0913880002355
                        ],
                        [
                            -113.514122999955,
                            45.0848100000223
                        ],
                        [
                            -113.501515000314,
                            45.0809989997832
                        ],
                        [
                            -113.495949000186,
                            45.0808180002205
                        ],
                        [
                            -113.489406000439,
                            45.0765259999013
                        ],
                        [
                            -113.48681799992,
                            45.0740479999255
                        ],
                        [
                            -113.488860999743,
                            45.0713190002058
                        ],
                        [
                            -113.486302000434,
                            45.0691289997078
                        ],
                        [
                            -113.485530999782,
                            45.0636239999259
                        ],
                        [
                            -113.47435199967,
                            45.0617559996887
                        ],
                        [
                            -113.460324999647,
                            45.0648689998204
                        ],
                        [
                            -113.452043999676,
                            45.0591739997068
                        ],
                        [
                            -113.451010999981,
                            45.0485079999313
                        ],
                        [
                            -113.449312000115,
                            45.0461770001764
                        ],
                        [
                            -113.455802999982,
                            45.0397119998331
                        ],
                        [
                            -113.44629099995,
                            45.0338920002776
                        ],
                        [
                            -113.448688999601,
                            45.0200159997332
                        ],
                        [
                            -113.442094999801,
                            45.014209999723
                        ],
                        [
                            -113.437881999933,
                            45.0073429997232
                        ],
                        [
                            -113.446860999863,
                            44.998276000259
                        ],
                        [
                            -113.445037000323,
                            44.9918450000152
                        ],
                        [
                            -113.447089000367,
                            44.9850579997025
                        ],
                        [
                            -113.444922999622,
                            44.9732640002762
                        ],
                        [
                            -113.449790999791,
                            44.9706359997552
                        ],
                        [
                            -113.443939000331,
                            44.9593799998037
                        ],
                        [
                            -113.448759999746,
                            44.953730000308
                        ],
                        [
                            -113.461053999568,
                            44.9520949997405
                        ],
                        [
                            -113.465837000246,
                            44.94760900021
                        ],
                        [
                            -113.480835999865,
                            44.9503099996882
                        ],
                        [
                            -113.486846000407,
                            44.9467779997995
                        ],
                        [
                            -113.494650000352,
                            44.9485149999589
                        ],
                        [
                            -113.498818000014,
                            44.9422030000807
                        ],
                        [
                            -113.495695999567,
                            44.936941999764
                        ],
                        [
                            -113.495240000356,
                            44.931714999865
                        ],
                        [
                            -113.49198300019,
                            44.9303830001241
                        ],
                        [
                            -113.490744999558,
                            44.9228519998331
                        ],
                        [
                            -113.487878000277,
                            44.9203809997376
                        ],
                        [
                            -113.483698000021,
                            44.9197240002638
                        ],
                        [
                            -113.477983999755,
                            44.9123820000193
                        ],
                        [
                            -113.471786999616,
                            44.909837000103
                        ],
                        [
                            -113.474943000398,
                            44.905237999757
                        ],
                        [
                            -113.475879000008,
                            44.8980159999726
                        ],
                        [
                            -113.478594999676,
                            44.8955949999217
                        ],
                        [
                            -113.466151999891,
                            44.8904640002621
                        ],
                        [
                            -113.461200000056,
                            44.8867540000867
                        ],
                        [
                            -113.462555999791,
                            44.8818580002088
                        ],
                        [
                            -113.457266999672,
                            44.8754080001574
                        ],
                        [
                            -113.455070999688,
                            44.8654239999066
                        ],
                        [
                            -113.443665999621,
                            44.8617560000749
                        ],
                        [
                            -113.439574999952,
                            44.8556889998189
                        ],
                        [
                            -113.429079999555,
                            44.8548580000444
                        ],
                        [
                            -113.422376000151,
                            44.8425950000645
                        ],
                        [
                            -113.421951999828,
                            44.8375989998584
                        ],
                        [
                            -113.409412999784,
                            44.8407570001731
                        ],
                        [
                            -113.394310000408,
                            44.8369400002141
                        ],
                        [
                            -113.385116000393,
                            44.8375999999526
                        ],
                        [
                            -113.377566999818,
                            44.8352389997791
                        ],
                        [
                            -113.376563000436,
                            44.8304829999831
                        ],
                        [
                            -113.356062000263,
                            44.8197980001424
                        ],
                        [
                            -113.346099999969,
                            44.8006110002167
                        ],
                        [
                            -113.346491999606,
                            44.7989299998906
                        ],
                        [
                            -113.354688999911,
                            44.7954369999091
                        ],
                        [
                            -113.354083000014,
                            44.7917979999556
                        ],
                        [
                            -113.341276000181,
                            44.7846640002424
                        ],
                        [
                            -113.328053000421,
                            44.7890969998479
                        ],
                        [
                            -113.318681000131,
                            44.7949139997751
                        ],
                        [
                            -113.301505999721,
                            44.7990309999575
                        ],
                        [
                            -113.298978000375,
                            44.8030310000963
                        ],
                        [
                            -113.285307999828,
                            44.8082549997113
                        ],
                        [
                            -113.279314999903,
                            44.8124389999828
                        ],
                        [
                            -113.263828000387,
                            44.8162099998483
                        ],
                        [
                            -113.258272000305,
                            44.8199969997449
                        ],
                        [
                            -113.246894000002,
                            44.8229729999816
                        ],
                        [
                            -113.240163999759,
                            44.817232000006
                        ],
                        [
                            -113.238729000382,
                            44.8141439998066
                        ],
                        [
                            -113.231871000093,
                            44.8143289998433
                        ],
                        [
                            -113.222590999865,
                            44.8107939999163
                        ],
                        [
                            -113.209354999686,
                            44.8091249999973
                        ],
                        [
                            -113.20360399961,
                            44.8039820002641
                        ],
                        [
                            -113.19311699961,
                            44.8016529998741
                        ],
                        [
                            -113.183413999782,
                            44.7935040000453
                        ],
                        [
                            -113.179538000198,
                            44.7871650002668
                        ],
                        [
                            -113.163687999561,
                            44.7788190003022
                        ],
                        [
                            -113.157944999881,
                            44.7809659997255
                        ],
                        [
                            -113.150128000416,
                            44.7792619998131
                        ],
                        [
                            -113.146143000153,
                            44.7766229998012
                        ],
                        [
                            -113.13843100027,
                            44.7761139997552
                        ],
                        [
                            -113.131459000179,
                            44.7727580002488
                        ],
                        [
                            -113.132499000444,
                            44.7690359997237
                        ],
                        [
                            -113.131347999851,
                            44.7649099997145
                        ],
                        [
                            -113.137724999915,
                            44.7601160002662
                        ],
                        [
                            -113.1348240003,
                            44.7527630001774
                        ],
                        [
                            -113.129412000158,
                            44.7495560001889
                        ],
                        [
                            -113.124901000364,
                            44.7494369998137
                        ],
                        [
                            -113.120260999801,
                            44.7459510001677
                        ],
                        [
                            -113.116466000407,
                            44.7400519997979
                        ],
                        [
                            -113.116836000303,
                            44.7325170001207
                        ],
                        [
                            -113.11244699981,
                            44.7334369996827
                        ],
                        [
                            -113.101618000401,
                            44.7294649998598
                        ],
                        [
                            -113.10192100035,
                            44.7227380002032
                        ],
                        [
                            -113.0994049998,
                            44.7177730002192
                        ],
                        [
                            -113.101728000006,
                            44.7155189997793
                        ],
                        [
                            -113.10181400022,
                            44.7071239998631
                        ],
                        [
                            -113.097023000044,
                            44.7014639997755
                        ],
                        [
                            -113.097833000155,
                            44.6988680001224
                        ],
                        [
                            -113.093950999825,
                            44.6953070000985
                        ],
                        [
                            -113.082112000288,
                            44.6916569997501
                        ],
                        [
                            -113.079552000256,
                            44.6878309999344
                        ],
                        [
                            -113.082057000036,
                            44.6839690000627
                        ],
                        [
                            -113.080943000151,
                            44.6830509999464
                        ],
                        [
                            -113.067761000399,
                            44.6787860001926
                        ],
                        [
                            -113.067106000098,
                            44.6742160002994
                        ],
                        [
                            -113.069733999902,
                            44.6686830001702
                        ],
                        [
                            -113.068003000249,
                            44.6576710002559
                        ],
                        [
                            -113.064038000078,
                            44.652735000155
                        ],
                        [
                            -113.065588999806,
                            44.6492529998491
                        ],
                        [
                            -113.053874999943,
                            44.6392660001229
                        ],
                        [
                            -113.050722000432,
                            44.6345420001312
                        ],
                        [
                            -113.049837999802,
                            44.6287989997739
                        ],
                        [
                            -113.05365400001,
                            44.6216570001675
                        ],
                        [
                            -113.05814899991,
                            44.6179879999227
                        ],
                        [
                            -113.063489000082,
                            44.6183280001806
                        ],
                        [
                            -113.071888000053,
                            44.6158710001927
                        ],
                        [
                            -113.088291999811,
                            44.5996629997566
                        ],
                        [
                            -113.072525999738,
                            44.5932969998794
                        ],
                        [
                            -113.062266000416,
                            44.5862360000909
                        ],
                        [
                            -113.067168000022,
                            44.5790619998123
                        ],
                        [
                            -113.044742000027,
                            44.5682159999746
                        ],
                        [
                            -113.039549999993,
                            44.5673020002981
                        ],
                        [
                            -113.037285000412,
                            44.5647690002245
                        ],
                        [
                            -113.036826999754,
                            44.560362000099
                        ],
                        [
                            -113.044803999951,
                            44.5562320001993
                        ],
                        [
                            -113.046767000307,
                            44.5535989997017
                        ],
                        [
                            -113.047702000092,
                            44.5476969997537
                        ],
                        [
                            -113.04535799997,
                            44.5443229998988
                        ],
                        [
                            -113.033292999579,
                            44.5374999997443
                        ],
                        [
                            -113.028966999733,
                            44.5370550000885
                        ],
                        [
                            -113.026340999579,
                            44.5385519997
                        ],
                        [
                            -113.015788000354,
                            44.5294240002367
                        ],
                        [
                            -113.006529999867,
                            44.525775999758
                        ],
                        [
                            -113.005518000089,
                            44.5198450001987
                        ],
                        [
                            -113.015944999815,
                            44.5159869999238
                        ],
                        [
                            -113.015877999868,
                            44.5059339998814
                        ],
                        [
                            -113.026824000351,
                            44.4955120001883
                        ],
                        [
                            -113.019986999978,
                            44.4836960001766
                        ],
                        [
                            -113.006117000314,
                            44.4709799998191
                        ],
                        [
                            -113.008787999775,
                            44.463996999901
                        ],
                        [
                            -113.004506000311,
                            44.4528269998589
                        ],
                        [
                            -113.001978000066,
                            44.4498570000282
                        ],
                        [
                            -112.989039999813,
                            44.4433290001073
                        ],
                        [
                            -112.98119499986,
                            44.4343059997431
                        ],
                        [
                            -112.971516000322,
                            44.4275670001391
                        ],
                        [
                            -112.963047999855,
                            44.4232549996808
                        ],
                        [
                            -112.952047999844,
                            44.4250200003128
                        ],
                        [
                            -112.953278999905,
                            44.4222800002607
                        ],
                        [
                            -112.950996999707,
                            44.4172600001132
                        ],
                        [
                            -112.945416000409,
                            44.414631999696
                        ],
                        [
                            -112.938780999703,
                            44.4123470001697
                        ],
                        [
                            -112.931350000027,
                            44.4134810002527
                        ],
                        [
                            -112.926402000391,
                            44.4106010003094
                        ],
                        [
                            -112.921146999709,
                            44.4109200001912
                        ],
                        [
                            -112.914615999658,
                            44.4026629999989
                        ],
                        [
                            -112.901873000298,
                            44.3988610002918
                        ],
                        [
                            -112.896421999798,
                            44.4008120000292
                        ],
                        [
                            -112.889556999836,
                            44.4059379999195
                        ],
                        [
                            -112.883531000299,
                            44.4006210000728
                        ],
                        [
                            -112.885564000076,
                            44.396262999861
                        ],
                        [
                            -112.881736000173,
                            44.3857080001495
                        ],
                        [
                            -112.881581000362,
                            44.379256000281
                        ],
                        [
                            -112.875709999738,
                            44.3772320000875
                        ],
                        [
                            -112.872924999575,
                            44.3721340000091
                        ],
                        [
                            -112.863898999789,
                            44.3698020001023
                        ],
                        [
                            -112.854267000107,
                            44.3595879997356
                        ],
                        [
                            -112.844565000104,
                            44.3579689997411
                        ],
                        [
                            -112.840601999583,
                            44.3618939999712
                        ],
                        [
                            -112.83713000023,
                            44.3620940001054
                        ],
                        [
                            -112.823507000437,
                            44.3687260000998
                        ],
                        [
                            -112.812207999777,
                            44.3771420002231
                        ],
                        [
                            -112.814192000049,
                            44.3834640003008
                        ],
                        [
                            -112.811943000361,
                            44.3879049999711
                        ],
                        [
                            -112.812571,
                            44.3924049998118
                        ],
                        [
                            -112.815096999697,
                            44.3950979997239
                        ],
                        [
                            -112.810056000174,
                            44.3987599998877
                        ],
                        [
                            -112.819943999949,
                            44.4001980001543
                        ],
                        [
                            -112.821792999604,
                            44.407164999974
                        ],
                        [
                            -112.839890000278,
                            44.4247140001001
                        ],
                        [
                            -112.826879000231,
                            44.4320559998521
                        ],
                        [
                            -112.828424000112,
                            44.4363440000891
                        ],
                        [
                            -112.826962000072,
                            44.4431270000642
                        ],
                        [
                            -112.818189000006,
                            44.4470520002795
                        ],
                        [
                            -112.813894999948,
                            44.453983000183
                        ],
                        [
                            -112.811334999916,
                            44.454819999722
                        ],
                        [
                            -112.807706000204,
                            44.4531219999118
                        ],
                        [
                            -112.805809999795,
                            44.4559939996794
                        ],
                        [
                            -112.798039000361,
                            44.4609140001789
                        ],
                        [
                            -112.796866000026,
                            44.4643800001084
                        ],
                        [
                            -112.798041999836,
                            44.4663409997592
                        ],
                        [
                            -112.78783100002,
                            44.473733000052
                        ],
                        [
                            -112.785604000074,
                            44.4789849998106
                        ],
                        [
                            -112.780185000259,
                            44.4822279999717
                        ],
                        [
                            -112.780810000423,
                            44.4846599999338
                        ],
                        [
                            -112.772151999985,
                            44.4836050002224
                        ],
                        [
                            -112.762434999914,
                            44.4876770001141
                        ],
                        [
                            -112.749758999602,
                            44.4901339999183
                        ],
                        [
                            -112.74306199987,
                            44.4883379996812
                        ],
                        [
                            -112.737462000305,
                            44.493267999742
                        ],
                        [
                            -112.734542000423,
                            44.4994890002879
                        ],
                        [
                            -112.717930000253,
                            44.5042729996935
                        ],
                        [
                            -112.708525000351,
                            44.5032310000062
                        ],
                        [
                            -112.698363999865,
                            44.4999100002688
                        ],
                        [
                            -112.692734999988,
                            44.4999920001671
                        ],
                        [
                            -112.688754999749,
                            44.4983669999188
                        ],
                        [
                            -112.684958999632,
                            44.4928469998646
                        ],
                        [
                            -112.671971999874,
                            44.4913669999266
                        ],
                        [
                            -112.661734000294,
                            44.4859899997885
                        ],
                        [
                            -112.63333999959,
                            44.491096000297
                        ],
                        [
                            -112.62987300026,
                            44.488805000066
                        ],
                        [
                            -112.629123000422,
                            44.4828100002759
                        ],
                        [
                            -112.625014000312,
                            44.4830270000795
                        ],
                        [
                            -112.617889000059,
                            44.4873839998404
                        ],
                        [
                            -112.601954999757,
                            44.4907049997523
                        ],
                        [
                            -112.585335999914,
                            44.4812029998951
                        ],
                        [
                            -112.582139999847,
                            44.4820899996823
                        ],
                        [
                            -112.577952999918,
                            44.4800920000764
                        ],
                        [
                            -112.569668999575,
                            44.4827109996957
                        ],
                        [
                            -112.559708000004,
                            44.4826350000944
                        ],
                        [
                            -112.550143000268,
                            44.4847450001401
                        ],
                        [
                            -112.547867999743,
                            44.483144000055
                        ],
                        [
                            -112.541711999612,
                            44.4837709998703
                        ],
                        [
                            -112.53584000006,
                            44.4783829998954
                        ],
                        [
                            -112.513891000091,
                            44.4751169998523
                        ],
                        [
                            -112.510700000047,
                            44.4687739997513
                        ],
                        [
                            -112.511290000051,
                            44.4663820002421
                        ],
                        [
                            -112.500642000392,
                            44.4627660000418
                        ],
                        [
                            -112.497186000034,
                            44.4660579998624
                        ],
                        [
                            -112.497131999608,
                            44.4680690001919
                        ],
                        [
                            -112.485030000305,
                            44.4708669999244
                        ],
                        [
                            -112.48365000028,
                            44.4743500000392
                        ],
                        [
                            -112.480075000097,
                            44.4746449996811
                        ],
                        [
                            -112.472687000079,
                            44.4792969996887
                        ],
                        [
                            -112.464369000298,
                            44.4749399997547
                        ],
                        [
                            -112.460349999701,
                            44.4751149999411
                        ],
                        [
                            -112.455535999958,
                            44.4711410000116
                        ],
                        [
                            -112.455232000185,
                            44.4662540000933
                        ],
                        [
                            -112.447773999846,
                            44.4679719997579
                        ],
                        [
                            -112.441508999935,
                            44.4665749997204
                        ],
                        [
                            -112.442350000009,
                            44.465311000148
                        ],
                        [
                            -112.433539000308,
                            44.4598339997556
                        ],
                        [
                            -112.434079999909,
                            44.454348000206
                        ],
                        [
                            -112.429522000259,
                            44.4534320001768
                        ],
                        [
                            -112.4230149996,
                            44.4546890002375
                        ],
                        [
                            -112.416061999775,
                            44.4530009999639
                        ],
                        [
                            -112.407236000006,
                            44.4574650001995
                        ],
                        [
                            -112.39018399962,
                            44.4503789996831
                        ],
                        [
                            -112.386523999946,
                            44.4473079998419
                        ],
                        [
                            -112.369741999896,
                            44.465976999807
                        ],
                        [
                            -112.366064999606,
                            44.4669799998531
                        ],
                        [
                            -112.364489999588,
                            44.480305000268
                        ],
                        [
                            -112.361651999721,
                            44.4848159999686
                        ],
                        [
                            -112.358366000141,
                            44.4852610000311
                        ],
                        [
                            -112.356657000229,
                            44.4928379997944
                        ],
                        [
                            -112.358402000126,
                            44.496434000028
                        ],
                        [
                            -112.35681200004,
                            44.4979559998167
                        ],
                        [
                            -112.356396000113,
                            44.5052259999564
                        ],
                        [
                            -112.358356999921,
                            44.5091559999446
                        ],
                        [
                            -112.358306999692,
                            44.5199139999111
                        ],
                        [
                            -112.356649999658,
                            44.52488400027
                        ],
                        [
                            -112.358823000076,
                            44.5294110001082
                        ],
                        [
                            -112.356851000398,
                            44.5303030000768
                        ],
                        [
                            -112.35250899976,
                            44.538020999709
                        ],
                        [
                            -112.347619999675,
                            44.5387420000844
                        ],
                        [
                            -112.341362000334,
                            44.5372830003183
                        ],
                        [
                            -112.319493999657,
                            44.5389039997068
                        ],
                        [
                            -112.315007999978,
                            44.5419000000064
                        ],
                        [
                            -112.315599999632,
                            44.5464249999085
                        ],
                        [
                            -112.313740999932,
                            44.5530410001161
                        ],
                        [
                            -112.30463499978,
                            44.559068999701
                        ],
                        [
                            -112.298204000187,
                            44.5589359997871
                        ],
                        [
                            -112.296332000068,
                            44.5612060002561
                        ],
                        [
                            -112.292690999763,
                            44.5616560003164
                        ],
                        [
                            -112.29093400017,
                            44.5658110001914
                        ],
                        [
                            -112.286568000141,
                            44.5685589998986
                        ],
                        [
                            -112.267805000193,
                            44.5654609996997
                        ],
                        [
                            -112.259183999565,
                            44.5682600000686
                        ],
                        [
                            -112.256701000424,
                            44.5716779996941
                        ],
                        [
                            -112.251138999596,
                            44.5677199996959
                        ],
                        [
                            -112.243655000217,
                            44.5682900000845
                        ],
                        [
                            -112.229224999787,
                            44.5640889999169
                        ],
                        [
                            -112.231065999944,
                            44.5589929997874
                        ],
                        [
                            -112.227323000429,
                            44.5575290002028
                        ],
                        [
                            -112.227073000184,
                            44.5546779997497
                        ],
                        [
                            -112.229629999843,
                            44.5495189998667
                        ],
                        [
                            -112.222333000061,
                            44.5439429997219
                        ],
                        [
                            -112.195520000121,
                            44.5373639999744
                        ],
                        [
                            -112.183657000294,
                            44.53295300003
                        ],
                        [
                            -112.18007299989,
                            44.5327770002354
                        ],
                        [
                            -112.164648000298,
                            44.5415330000069
                        ],
                        [
                            -112.148886000423,
                            44.5367559997177
                        ],
                        [
                            -112.14575599958,
                            44.5392130000252
                        ],
                        [
                            -112.136672000069,
                            44.5398600000468
                        ],
                        [
                            -112.135369000037,
                            44.5371820003127
                        ],
                        [
                            -112.131345000315,
                            44.5377350000106
                        ],
                        [
                            -112.129161999852,
                            44.5361599998718
                        ],
                        [
                            -112.127046000234,
                            44.5288899999588
                        ],
                        [
                            -112.10952100002,
                            44.5242300001724
                        ],
                        [
                            -112.106400000296,
                            44.5205690002401
                        ],
                        [
                            -112.096484000033,
                            44.5229920000536
                        ],
                        [
                            -112.093092999973,
                            44.5300170002552
                        ],
                        [
                            -112.080842999633,
                            44.5338280001214
                        ],
                        [
                            -112.075274999855,
                            44.5333939999877
                        ],
                        [
                            -112.06906799967,
                            44.5371510000919
                        ],
                        [
                            -112.064488000279,
                            44.5358889997122
                        ],
                        [
                            -112.055475000014,
                            44.5366740003156
                        ],
                        [
                            -112.043915000136,
                            44.5323390002135
                        ],
                        [
                            -112.037856999914,
                            44.5326849997819
                        ],
                        [
                            -112.036943000045,
                            44.5303230001148
                        ],
                        [
                            -112.032798999774,
                            44.5284029997328
                        ],
                        [
                            -112.03281999969,
                            44.5351029998445
                        ],
                        [
                            -112.035069000276,
                            44.5419800001929
                        ],
                        [
                            -112.032706999712,
                            44.5466419998338
                        ],
                        [
                            -112.023437000428,
                            44.5450989997931
                        ],
                        [
                            -112.016699999615,
                            44.5420179997468
                        ],
                        [
                            -112.011473000319,
                            44.5434510002022
                        ],
                        [
                            -112.004426999709,
                            44.5377029999211
                        ],
                        [
                            -111.999932999634,
                            44.5378929998743
                        ],
                        [
                            -111.995515999552,
                            44.5350130002826
                        ],
                        [
                            -111.992039000176,
                            44.5375290000873
                        ],
                        [
                            -111.977724000272,
                            44.5357440001721
                        ],
                        [
                            -111.971542000201,
                            44.5376460001984
                        ],
                        [
                            -111.967303000394,
                            44.5449429997144
                        ],
                        [
                            -111.962421999806,
                            44.5444780003117
                        ],
                        [
                            -111.961154999759,
                            44.5491699999187
                        ],
                        [
                            -111.951680000436,
                            44.5494539998589
                        ],
                        [
                            -111.947782000212,
                            44.55701299987
                        ],
                        [
                            -111.941106000397,
                            44.5561519997559
                        ],
                        [
                            -111.934161000069,
                            44.5580400000916
                        ],
                        [
                            -111.90293400042,
                            44.5566729998691
                        ],
                        [
                            -111.895178000155,
                            44.5594010001808
                        ],
                        [
                            -111.892302999581,
                            44.5639519998294
                        ],
                        [
                            -111.889417999859,
                            44.5646489998443
                        ],
                        [
                            -111.879458999938,
                            44.5604089996925
                        ],
                        [
                            -111.870704000314,
                            44.5632580002364
                        ],
                        [
                            -111.869319000267,
                            44.5666209998541
                        ],
                        [
                            -111.866447000065,
                            44.5631000000924
                        ],
                        [
                            -111.867549000255,
                            44.5593890001347
                        ],
                        [
                            -111.864894999614,
                            44.5571159996966
                        ],
                        [
                            -111.863913999797,
                            44.5514600002956
                        ],
                        [
                            -111.856907000444,
                            44.549091999945
                        ],
                        [
                            -111.856656000374,
                            44.545972999815
                        ],
                        [
                            -111.849234999846,
                            44.5399699997273
                        ],
                        [
                            -111.842295000439,
                            44.5261729997587
                        ],
                        [
                            -111.82993600032,
                            44.5199860003036
                        ],
                        [
                            -111.831249000397,
                            44.5173270001892
                        ],
                        [
                            -111.821529999778,
                            44.5089410000113
                        ],
                        [
                            -111.810658999638,
                            44.5114779999377
                        ],
                        [
                            -111.807970999559,
                            44.5101610002436
                        ],
                        [
                            -111.809388000291,
                            44.5128889997162
                        ],
                        [
                            -111.806081999722,
                            44.5161209998637
                        ],
                        [
                            -111.808159999705,
                            44.5197480000194
                        ],
                        [
                            -111.801119000016,
                            44.5259850000811
                        ],
                        [
                            -111.795361000268,
                            44.5265939997895
                        ],
                        [
                            -111.785450999852,
                            44.523597999734
                        ],
                        [
                            -111.779796000036,
                            44.5238429999091
                        ],
                        [
                            -111.771510000042,
                            44.5308679999475
                        ],
                        [
                            -111.762723999558,
                            44.5258000002591
                        ],
                        [
                            -111.758943000409,
                            44.5332340002439
                        ],
                        [
                            -111.75229900038,
                            44.5365360001711
                        ],
                        [
                            -111.749914000251,
                            44.5395799999224
                        ],
                        [
                            -111.741367000141,
                            44.5408760002034
                        ],
                        [
                            -111.737535999865,
                            44.5430679998827
                        ],
                        [
                            -111.732370999595,
                            44.5421170001529
                        ],
                        [
                            -111.727032000146,
                            44.5441309996948
                        ],
                        [
                            -111.715647999995,
                            44.5433689999847
                        ],
                        [
                            -111.71265100012,
                            44.5485540002179
                        ],
                        [
                            -111.707252000396,
                            44.5524910002149
                        ],
                        [
                            -111.703164000202,
                            44.5601480000943
                        ],
                        [
                            -111.689994999971,
                            44.5562000000351
                        ],
                        [
                            -111.68299699994,
                            44.561657000068
                        ],
                        [
                            -111.676614999852,
                            44.5576840000144
                        ],
                        [
                            -111.663551000101,
                            44.5557970001421
                        ],
                        [
                            -111.659265000438,
                            44.5526629996904
                        ],
                        [
                            -111.65134199977,
                            44.5551409998778
                        ],
                        [
                            -111.646826999778,
                            44.554796999703
                        ],
                        [
                            -111.64058899963,
                            44.5527409999996
                        ],
                        [
                            -111.633222000427,
                            44.5533489997619
                        ],
                        [
                            -111.617637999929,
                            44.5504649999493
                        ],
                        [
                            -111.613897000064,
                            44.5486799997122
                        ],
                        [
                            -111.592221999731,
                            44.558674999982
                        ],
                        [
                            -111.592261999914,
                            44.561119000005
                        ],
                        [
                            -111.585687000381,
                            44.562627999756
                        ],
                        [
                            -111.577664000153,
                            44.5588860002462
                        ],
                        [
                            -111.564405000408,
                            44.5574970001118
                        ],
                        [
                            -111.562813999599,
                            44.5552090001629
                        ],
                        [
                            -111.556561000281,
                            44.554686999873
                        ],
                        [
                            -111.547051999723,
                            44.5571759996856
                        ],
                        [
                            -111.539166999588,
                            44.5513129999848
                        ],
                        [
                            -111.523593999858,
                            44.5478640000101
                        ],
                        [
                            -111.51831599961,
                            44.5434520002666
                        ],
                        [
                            -111.51637400007,
                            44.5378369998582
                        ],
                        [
                            -111.512890999948,
                            44.5360410002335
                        ],
                        [
                            -111.50768100037,
                            44.5362980002671
                        ],
                        [
                            -111.499371000087,
                            44.5420290000848
                        ],
                        [
                            -111.491623000219,
                            44.5406179997736
                        ],
                        [
                            -111.479664999958,
                            44.5421349998013
                        ],
                        [
                            -111.468002000147,
                            44.5389509999832
                        ],
                        [
                            -111.467904000238,
                            44.5402119998295
                        ],
                        [
                            -111.463534999836,
                            44.5413559997925
                        ],
                        [
                            -111.46764899997,
                            44.54462299987
                        ],
                        [
                            -111.466947999638,
                            44.5469780001667
                        ],
                        [
                            -111.46914600017,
                            44.5527740000497
                        ],
                        [
                            -111.467983999706,
                            44.5549500000169
                        ],
                        [
                            -111.469355000407,
                            44.5576739998945
                        ],
                        [
                            -111.47546499961,
                            44.5563609998825
                        ],
                        [
                            -111.475987999667,
                            44.5544220000689
                        ],
                        [
                            -111.478653000179,
                            44.5533950000056
                        ],
                        [
                            -111.480655999819,
                            44.5557350003048
                        ],
                        [
                            -111.492122999811,
                            44.560804000237
                        ],
                        [
                            -111.507769000234,
                            44.5622979998904
                        ],
                        [
                            -111.509586000101,
                            44.5662440002772
                        ],
                        [
                            -111.506308000019,
                            44.5687790000184
                        ],
                        [
                            -111.5025770002,
                            44.568716999935
                        ],
                        [
                            -111.50134000029,
                            44.5724899999783
                        ],
                        [
                            -111.506463999654,
                            44.5767740001259
                        ],
                        [
                            -111.517814000368,
                            44.5821660000755
                        ],
                        [
                            -111.519423999648,
                            44.5887120002964
                        ],
                        [
                            -111.523610999577,
                            44.5922150000554
                        ],
                        [
                            -111.524476999765,
                            44.5976279998173
                        ],
                        [
                            -111.519336999609,
                            44.6017679996879
                        ],
                        [
                            -111.525633000382,
                            44.6043399999823
                        ],
                        [
                            -111.519647000129,
                            44.611972000213
                        ],
                        [
                            -111.522613000042,
                            44.614084000116
                        ],
                        [
                            -111.518432999786,
                            44.6164769997791
                        ],
                        [
                            -111.516297000077,
                            44.6203000000131
                        ],
                        [
                            -111.502499000382,
                            44.6265080000731
                        ],
                        [
                            -111.503850999919,
                            44.6299230001321
                        ],
                        [
                            -111.509056000372,
                            44.6330989999409
                        ],
                        [
                            -111.511394999572,
                            44.638609000265
                        ],
                        [
                            -111.516461000108,
                            44.6408959998231
                        ],
                        [
                            -111.516309999597,
                            44.6439970001454
                        ],
                        [
                            -111.507944999961,
                            44.6455910000669
                        ],
                        [
                            -111.504883999613,
                            44.6443409998964
                        ],
                        [
                            -111.500956000151,
                            44.6496389999137
                        ],
                        [
                            -111.495275000395,
                            44.6443229999191
                        ],
                        [
                            -111.490114000323,
                            44.64247099975
                        ],
                        [
                            -111.4916709999,
                            44.6447449999052
                        ],
                        [
                            -111.490612000266,
                            44.6496520000202
                        ],
                        [
                            -111.488028999769,
                            44.6510910002329
                        ],
                        [
                            -111.487396999932,
                            44.6537900001709
                        ],
                        [
                            -111.479034999771,
                            44.6586599999547
                        ],
                        [
                            -111.47756000021,
                            44.6603079998873
                        ],
                        [
                            -111.47835199988,
                            44.6621340001292
                        ],
                        [
                            -111.472482999804,
                            44.6661900000824
                        ],
                        [
                            -111.472988000317,
                            44.6681110002823
                        ],
                        [
                            -111.475641000236,
                            44.6689979998673
                        ],
                        [
                            -111.476510999724,
                            44.6737239998055
                        ],
                        [
                            -111.471383999987,
                            44.6751330001526
                        ],
                        [
                            -111.468509000311,
                            44.6791780001932
                        ],
                        [
                            -111.476663999885,
                            44.6814370002605
                        ],
                        [
                            -111.484603000447,
                            44.6875740002697
                        ],
                        [
                            -111.482706000214,
                            44.6925890002588
                        ],
                        [
                            -111.48971599994,
                            44.6996569999203
                        ],
                        [
                            -111.488783999629,
                            44.7056430002198
                        ],
                        [
                            -111.485529000011,
                            44.7077799999946
                        ],
                        [
                            -111.479476999637,
                            44.7068059998781
                        ],
                        [
                            -111.465560999941,
                            44.7137280002067
                        ],
                        [
                            -111.446874000161,
                            44.7174009998677
                        ],
                        [
                            -111.442733000263,
                            44.7202319999266
                        ],
                        [
                            -111.438798000229,
                            44.7205449997813
                        ],
                        [
                            -111.429601999666,
                            44.7201479997345
                        ],
                        [
                            -111.424212999989,
                            44.7140230000928
                        ],
                        [
                            -111.414273000334,
                            44.7107390002917
                        ],
                        [
                            -111.404361000269,
                            44.7210539998385
                        ],
                        [
                            -111.397958000265,
                            44.7236439999596
                        ],
                        [
                            -111.397039000373,
                            44.7279339998783
                        ],
                        [
                            -111.399382999597,
                            44.734266999992
                        ],
                        [
                            -111.395257999593,
                            44.7392199997977
                        ],
                        [
                            -111.394429999938,
                            44.7441149997101
                        ],
                        [
                            -111.397843999564,
                            44.7463829998323
                        ],
                        [
                            -111.392968999722,
                            44.7523099998458
                        ],
                        [
                            -111.384824000194,
                            44.7549540001584
                        ],
                        [
                            -111.374488999631,
                            44.7501890002596
                        ],
                        [
                            -111.374931000395,
                            44.7481069998147
                        ],
                        [
                            -111.366261000262,
                            44.7422450002013
                        ],
                        [
                            -111.366491000416,
                            44.7381370001543
                        ],
                        [
                            -111.352977000403,
                            44.7261779998153
                        ],
                        [
                            -111.348067000401,
                            44.7253139997557
                        ],
                        [
                            -111.337113000421,
                            44.7332039999179
                        ],
                        [
                            -111.323626000172,
                            44.7290720000026
                        ],
                        [
                            -111.322483999799,
                            44.7187760001853
                        ],
                        [
                            -111.317875000096,
                            44.714477000074
                        ],
                        [
                            -111.296260000038,
                            44.7022709998247
                        ],
                        [
                            -111.294844999853,
                            44.6982510000891
                        ],
                        [
                            -111.297255000098,
                            44.6939720002365
                        ],
                        [
                            -111.296879000354,
                            44.6914439998656
                        ],
                        [
                            -111.291040000415,
                            44.686188000299
                        ],
                        [
                            -111.279250000383,
                            44.6822320000633
                        ],
                        [
                            -111.276183000188,
                            44.6783110001658
                        ],
                        [
                            -111.276924999629,
                            44.6759730002208
                        ],
                        [
                            -111.275666999803,
                            44.6743239999889
                        ],
                        [
                            -111.266804000225,
                            44.6675120001502
                        ],
                        [
                            -111.267051999922,
                            44.6648139998882
                        ],
                        [
                            -111.271854999794,
                            44.6625159997045
                        ],
                        [
                            -111.275613000275,
                            44.656671999958
                        ],
                        [
                            -111.280575000156,
                            44.6552010002047
                        ],
                        [
                            -111.278477000081,
                            44.6512649997149
                        ],
                        [
                            -111.270129000163,
                            44.6468199996912
                        ],
                        [
                            -111.252597000276,
                            44.651249999714
                        ],
                        [
                            -111.253070999929,
                            44.6498230001662
                        ],
                        [
                            -111.249236000353,
                            44.6441040002809
                        ],
                        [
                            -111.242644000203,
                            44.6434000000257
                        ],
                        [
                            -111.224404000311,
                            44.6251530000192
                        ],
                        [
                            -111.219319000407,
                            44.622520999729
                        ],
                        [
                            -111.226643999778,
                            44.6183600000497
                        ],
                        [
                            -111.24088900026,
                            44.6145249997955
                        ],
                        [
                            -111.235091000328,
                            44.6054149997778
                        ],
                        [
                            -111.235597999593,
                            44.6010359998027
                        ],
                        [
                            -111.231860000102,
                            44.5971369998503
                        ],
                        [
                            -111.232542999992,
                            44.5894889999197
                        ],
                        [
                            -111.230812000338,
                            44.587705999805
                        ],
                        [
                            -111.232144999609,
                            44.5852310002386
                        ],
                        [
                            -111.229483000368,
                            44.5823819997675
                        ],
                        [
                            -111.230182000151,
                            44.581217000164
                        ],
                        [
                            -111.22763100034,
                            44.5766870001221
                        ],
                        [
                            -111.220444999988,
                            44.5768730002808
                        ],
                        [
                            -111.218864999948,
                            44.5753060000838
                        ],
                        [
                            -111.201491000244,
                            44.5758960000957
                        ],
                        [
                            -111.181030000254,
                            44.56923100026
                        ],
                        [
                            -111.179959000027,
                            44.5674819999263
                        ],
                        [
                            -111.180747000397,
                            44.5548829999374
                        ],
                        [
                            -111.178640000102,
                            44.5524770002054
                        ],
                        [
                            -111.173755000214,
                            44.5493329999366
                        ],
                        [
                            -111.158893999789,
                            44.546264000102
                        ],
                        [
                            -111.150570999986,
                            44.5349709997991
                        ],
                        [
                            -111.140806000234,
                            44.5358439999157
                        ],
                        [
                            -111.131382000065,
                            44.5324290000659
                        ],
                        [
                            -111.138617999747,
                            44.5168540002678
                        ],
                        [
                            -111.130658999992,
                            44.5001309999187
                        ],
                        [
                            -111.128376999794,
                            44.499728000192
                        ],
                        [
                            -111.127382999559,
                            44.4976199998493
                        ],
                        [
                            -111.115061000148,
                            44.4938480002075
                        ],
                        [
                            -111.112836999677,
                            44.4904660000329
                        ],
                        [
                            -111.107212999822,
                            44.4869289998574
                        ],
                        [
                            -111.100917999773,
                            44.4877830000497
                        ],
                        [
                            -111.074786999898,
                            44.4834479997
                        ],
                        [
                            -111.059969999854,
                            44.4773500002324
                        ],
                        [
                            -111.055782000101,
                            44.4767980003198
                        ],
                        [
                            -111.052433999698,
                            44.4784110002825
                        ],
                        [
                            -111.048974000041,
                            44.4740719997397
                        ],
                        [
                            -111.049076999973,
                            44.0201299998467
                        ],
                        [
                            -111.047348999794,
                            43.9999210000068
                        ],
                        [
                            -111.046521999964,
                            43.9105330003212
                        ],
                        [
                            -111.043494999951,
                            42.7475519999091
                        ],
                        [
                            -111.047234000166,
                            42.4690960000418
                        ],
                        [
                            -111.046713999584,
                            42.0017019999653
                        ],
                        [
                            -112.153848000304,
                            41.9972260002685
                        ],
                        [
                            -112.173147999654,
                            42.0015149999733
                        ],
                        [
                            -112.705684000111,
                            42.0001509997042
                        ],
                        [
                            -113.249781000272,
                            41.9959689998458
                        ],
                        [
                            -113.873348999958,
                            41.9881819998709
                        ],
                        [
                            -114.041477000424,
                            41.9938729997
                        ],
                        [
                            -114.59828099996,
                            41.9945940002506
                        ],
                        [
                            -114.823490999766,
                            42.0018419999316
                        ],
                        [
                            -115.014887000127,
                            41.9965619997694
                        ],
                        [
                            -115.092427999807,
                            41.9961150003015
                        ],
                        [
                            -116.04537300022,
                            41.9963310000868
                        ],
                        [
                            -116.189435000376,
                            41.9976989997287
                        ],
                        [
                            -116.368476999742,
                            41.996280000148
                        ],
                        [
                            -117.008064999944,
                            41.9987419999104
                        ],
                        [
                            -117.026200000253,
                            42.000194999674
                        ],
                        [
                            -117.026832999915,
                            43.7336699997392
                        ],
                        [
                            -117.026633999723,
                            43.8081100001716
                        ],
                        [
                            -117.021549999644,
                            43.8194020001263
                        ],
                        [
                            -117.023576999574,
                            43.8238109998111
                        ],
                        [
                            -117.032578000143,
                            43.8301809998057
                        ],
                        [
                            -117.031886999857,
                            43.8341559998854
                        ],
                        [
                            -117.02515599979,
                            43.841333999925
                        ],
                        [
                            -117.014611999888,
                            43.8396420001759
                        ],
                        [
                            -117.011582000401,
                            43.8410449999034
                        ],
                        [
                            -117.010701000144,
                            43.8497539998101
                        ],
                        [
                            -117.020116000092,
                            43.8539579999809
                        ],
                        [
                            -117.021721000247,
                            43.8561820002392
                        ],
                        [
                            -117.020034999901,
                            43.8589559999596
                        ],
                        [
                            -117.00718899971,
                            43.8636409998557
                        ],
                        [
                            -116.997391000346,
                            43.8648740000302
                        ],
                        [
                            -116.991415000139,
                            43.8638639998885
                        ],
                        [
                            -116.982939999999,
                            43.867710000012
                        ],
                        [
                            -116.983857000241,
                            43.8807950001429
                        ],
                        [
                            -116.976025999634,
                            43.8955479998518
                        ],
                        [
                            -116.97735399978,
                            43.9058159999905
                        ],
                        [
                            -116.981144999873,
                            43.9136930000087
                        ],
                        [
                            -116.979579999901,
                            43.9152530002556
                        ],
                        [
                            -116.961535000003,
                            43.9183879997297
                        ],
                        [
                            -116.959333999996,
                            43.9293249996849
                        ],
                        [
                            -116.961022999817,
                            43.932366000206
                        ],
                        [
                            -116.967640999906,
                            43.9347920000749
                        ],
                        [
                            -116.969138999931,
                            43.9388649999007
                        ],
                        [
                            -116.968214000192,
                            43.9418809998225
                        ],
                        [
                            -116.962540000109,
                            43.9469929998733
                        ],
                        [
                            -116.960477000194,
                            43.9551029998153
                        ],
                        [
                            -116.972431000258,
                            43.9579870002622
                        ],
                        [
                            -116.959807999649,
                            43.9638149997321
                        ],
                        [
                            -116.95920599995,
                            43.9663599999235
                        ],
                        [
                            -116.964413999877,
                            43.9688119998042
                        ],
                        [
                            -116.970879999631,
                            43.968301999692
                        ],
                        [
                            -116.976044999901,
                            43.9750579997702
                        ],
                        [
                            -116.972925000002,
                            43.9775219998364
                        ],
                        [
                            -116.966414999868,
                            43.9772959999115
                        ],
                        [
                            -116.960832000021,
                            43.9828230001149
                        ],
                        [
                            -116.940023999702,
                            43.9844449999414
                        ],
                        [
                            -116.935301000196,
                            43.9886160001227
                        ],
                        [
                            -116.944939999551,
                            44.0027789997087
                        ],
                        [
                            -116.93686199997,
                            44.0105039998031
                        ],
                        [
                            -116.934498999582,
                            44.02121799981
                        ],
                        [
                            -116.936133999874,
                            44.0271400001576
                        ],
                        [
                            -116.942002999951,
                            44.0345990002943
                        ],
                        [
                            -116.952925999969,
                            44.0400219996906
                        ],
                        [
                            -116.95667500023,
                            44.0441489998808
                        ],
                        [
                            -116.973185000293,
                            44.0494250000227
                        ],
                        [
                            -116.975956000212,
                            44.0828999998647
                        ],
                        [
                            -116.977351000305,
                            44.0853640001793
                        ],
                        [
                            -116.974253000148,
                            44.088295000233
                        ],
                        [
                            -116.967203000238,
                            44.0909359997339
                        ],
                        [
                            -116.943126999882,
                            44.0940629997847
                        ],
                        [
                            -116.933701000064,
                            44.100043000031
                        ],
                        [
                            -116.928304999815,
                            44.1073269997264
                        ],
                        [
                            -116.927868999797,
                            44.1103099999307
                        ],
                        [
                            -116.929525000006,
                            44.1130589999727
                        ],
                        [
                            -116.928561999733,
                            44.115158999953
                        ],
                        [
                            -116.914235999959,
                            44.1273039998811
                        ],
                        [
                            -116.911218000167,
                            44.1399919999969
                        ],
                        [
                            -116.902406999569,
                            44.1451679997882
                        ],
                        [
                            -116.901497999723,
                            44.1492550002137
                        ],
                        [
                            -116.89593099977,
                            44.1542949998591
                        ],
                        [
                            -116.894201999766,
                            44.1623169997636
                        ],
                        [
                            -116.895756999692,
                            44.1712669999143
                        ],
                        [
                            -116.902752000248,
                            44.1794669999802
                        ],
                        [
                            -116.915738000182,
                            44.1831520000009
                        ],
                        [
                            -116.922952000122,
                            44.1873580000842
                        ],
                        [
                            -116.925391999606,
                            44.191544000146
                        ],
                        [
                            -116.935442999588,
                            44.1939620000706
                        ],
                        [
                            -116.94759099982,
                            44.1912640003097
                        ],
                        [
                            -116.965497999626,
                            44.1941260000721
                        ],
                        [
                            -116.971674999674,
                            44.1972559998663
                        ],
                        [
                            -116.973700999779,
                            44.208016999779
                        ],
                        [
                            -116.970245000319,
                            44.2208429997042
                        ],
                        [
                            -116.973945000176,
                            44.2259320001836
                        ],
                        [
                            -116.97195800043,
                            44.2356770001373
                        ],
                        [
                            -116.975984999626,
                            44.2428750002296
                        ],
                        [
                            -116.986837000398,
                            44.2454690002305
                        ],
                        [
                            -117.005961000021,
                            44.246497999958
                        ],
                        [
                            -117.016916999652,
                            44.2453899997569
                        ],
                        [
                            -117.025277000164,
                            44.2485049997302
                        ],
                        [
                            -117.031861999743,
                            44.2486350001911
                        ],
                        [
                            -117.042282999621,
                            44.2427750000089
                        ],
                        [
                            -117.046148000232,
                            44.2306689998901
                        ],
                        [
                            -117.051430999605,
                            44.2287479998985
                        ],
                        [
                            -117.056509999661,
                            44.2308740001789
                        ],
                        [
                            -117.059351999725,
                            44.2372439997851
                        ],
                        [
                            -117.067283999717,
                            44.2440100001525
                        ],
                        [
                            -117.07834999985,
                            44.2498850000564
                        ],
                        [
                            -117.089503000023,
                            44.2582340002561
                        ],
                        [
                            -117.09456899966,
                            44.2709759999004
                        ],
                        [
                            -117.104207000089,
                            44.2799420001407
                        ],
                        [
                            -117.111616000023,
                            44.2806659998003
                        ],
                        [
                            -117.119861000009,
                            44.2782720002544
                        ],
                        [
                            -117.130866000043,
                            44.2694920003043
                        ],
                        [
                            -117.136766999907,
                            44.2603909998386
                        ],
                        [
                            -117.14621799984,
                            44.2580269998099
                        ],
                        [
                            -117.170341000052,
                            44.2588889998601
                        ],
                        [
                            -117.198147000402,
                            44.2738280000463
                        ],
                        [
                            -117.207505000449,
                            44.282722000266
                        ],
                        [
                            -117.216973999925,
                            44.2883569997667
                        ],
                        [
                            -117.222740000069,
                            44.2983259997373
                        ],
                        [
                            -117.216794999825,
                            44.3082360000387
                        ],
                        [
                            -117.203322999645,
                            44.3130240000862
                        ],
                        [
                            -117.196336000383,
                            44.3266040000616
                        ],
                        [
                            -117.191546000031,
                            44.3296209997729
                        ],
                        [
                            -117.189769000347,
                            44.3365850000667
                        ],
                        [
                            -117.19733899994,
                            44.3474059997294
                        ],
                        [
                            -117.202665999694,
                            44.3498129997627
                        ],
                        [
                            -117.2069620003,
                            44.3552059998205
                        ],
                        [
                            -117.216911000175,
                            44.3601629999592
                        ],
                        [
                            -117.235116999732,
                            44.3738529997404
                        ],
                        [
                            -117.237829999925,
                            44.3823859997663
                        ],
                        [
                            -117.242754999995,
                            44.3901940002362
                        ],
                        [
                            -117.242872999996,
                            44.395556999884
                        ],
                        [
                            -117.227259000259,
                            44.4052359999969
                        ],
                        [
                            -117.222974000421,
                            44.4164570000399
                        ],
                        [
                            -117.219291000282,
                            44.4192359999842
                        ],
                        [
                            -117.215071999669,
                            44.4271619999341
                        ],
                        [
                            -117.216562000196,
                            44.4422760001447
                        ],
                        [
                            -117.214637000374,
                            44.4480300000887
                        ],
                        [
                            -117.215254000142,
                            44.4521520003055
                        ],
                        [
                            -117.217014999933,
                            44.4590419997855
                        ],
                        [
                            -117.220383000427,
                            44.4630369998438
                        ],
                        [
                            -117.221548000366,
                            44.4701459999946
                        ],
                        [
                            -117.225371000246,
                            44.4754760001072
                        ],
                        [
                            -117.225075999795,
                            44.4823460000995
                        ],
                        [
                            -117.216372000225,
                            44.4861600000465
                        ],
                        [
                            -117.208935999628,
                            44.485660999797
                        ],
                        [
                            -117.200237000081,
                            44.4920270003042
                        ],
                        [
                            -117.192494000236,
                            44.503272000197
                        ],
                        [
                            -117.189759000301,
                            44.513384999703
                        ],
                        [
                            -117.181582999913,
                            44.522960000189
                        ],
                        [
                            -117.167186999818,
                            44.523430999683
                        ],
                        [
                            -117.161033000234,
                            44.5251660000855
                        ],
                        [
                            -117.152405999759,
                            44.5318020002532
                        ],
                        [
                            -117.149242000377,
                            44.5361510000852
                        ],
                        [
                            -117.144160999773,
                            44.5456469997107
                        ],
                        [
                            -117.142929999712,
                            44.5572360002528
                        ],
                        [
                            -117.147934000323,
                            44.5621430002093
                        ],
                        [
                            -117.148254999815,
                            44.5643709999644
                        ],
                        [
                            -117.146032000067,
                            44.5686029997331
                        ],
                        [
                            -117.133963000376,
                            44.5752399999271
                        ],
                        [
                            -117.124754000293,
                            44.5838340001495
                        ],
                        [
                            -117.125267000304,
                            44.5938179999486
                        ],
                        [
                            -117.120522000158,
                            44.6146579998333
                        ],
                        [
                            -117.114754000364,
                            44.6248829999924
                        ],
                        [
                            -117.108230999811,
                            44.6271100002659
                        ],
                        [
                            -117.098220999836,
                            44.640688999919
                        ],
                        [
                            -117.098205999768,
                            44.6476509999754
                        ],
                        [
                            -117.094967999868,
                            44.6520110001057
                        ],
                        [
                            -117.096790999583,
                            44.6573849997235
                        ],
                        [
                            -117.095868000392,
                            44.6647369996878
                        ],
                        [
                            -117.091222999806,
                            44.6688069997097
                        ],
                        [
                            -117.08079600008,
                            44.6737679998639
                        ],
                        [
                            -117.078574000156,
                            44.6803279997498
                        ],
                        [
                            -117.08077199979,
                            44.6841610002681
                        ],
                        [
                            -117.079119999779,
                            44.6921750002658
                        ],
                        [
                            -117.074136999982,
                            44.6963739998078
                        ],
                        [
                            -117.072221000381,
                            44.7005169996882
                        ],
                        [
                            -117.063824000059,
                            44.7036230001281
                        ],
                        [
                            -117.06179899978,
                            44.7066540001396
                        ],
                        [
                            -117.062686999709,
                            44.711678999807
                        ],
                        [
                            -117.060453999915,
                            44.721668000092
                        ],
                        [
                            -117.062273000331,
                            44.7271429998291
                        ],
                        [
                            -117.058302000312,
                            44.7304819999599
                        ],
                        [
                            -117.050204999566,
                            44.7325650001933
                        ],
                        [
                            -117.048177999637,
                            44.7359159999587
                        ],
                        [
                            -117.04782100016,
                            44.7415270002916
                        ],
                        [
                            -117.038269999769,
                            44.7481790002749
                        ],
                        [
                            -117.029419999711,
                            44.7511020003155
                        ],
                        [
                            -117.019999999741,
                            44.7510189999784
                        ],
                        [
                            -117.013801999776,
                            44.7568409998808
                        ],
                        [
                            -116.998902999716,
                            44.7563820001717
                        ],
                        [
                            -116.986732999743,
                            44.7622360002385
                        ],
                        [
                            -116.97090200027,
                            44.7738809999563
                        ],
                        [
                            -116.949000999982,
                            44.7779809999967
                        ],
                        [
                            -116.943300000134,
                            44.7816809999982
                        ],
                        [
                            -116.934700000322,
                            44.7838809996907
                        ],
                        [
                            -116.930699999991,
                            44.789881000063
                        ],
                        [
                            -116.933798999973,
                            44.7967810002023
                        ],
                        [
                            -116.931099000199,
                            44.8047810000728
                        ],
                        [
                            -116.922099000353,
                            44.8118800000463
                        ],
                        [
                            -116.90577199969,
                            44.8347930001693
                        ],
                        [
                            -116.900146000186,
                            44.8366719999266
                        ],
                        [
                            -116.899710999992,
                            44.840608999876
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 44.3484222,
            "name": "Idaho",
            "intptlon": -114.5588538,
            "geo_point_2d": [
                44.3889268004,
                -114.659399868
            ],
            "geoid": "16",
            "mtfcc": "G4000",
            "region": 4
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -114.659399868,
                44.3889268004
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "e492fa1880be086344ff5f4202ffd4dfa36f003a",
        "fields": {
            "arealand": "1390732324",
            "objectid": 18,
            "basename": "Nebraska",
            "stusab": "NE",
            "statens": "01779792",
            "centlon": -99.7949708,
            "state": "31",
            "gid": 52,
            "centlat": 41.5381096,
            "division": 4,
            "areawater": "1369769201",
            "oid": "293419492",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -104.052645000061,
                            42.0017180000533
                        ],
                        [
                            -104.053028000375,
                            43.0005849997737
                        ],
                        [
                            -103.966199999895,
                            43.0017070002588
                        ],
                        [
                            -101.875423999741,
                            42.9994149999718
                        ],
                        [
                            -101.633006999738,
                            42.9962549998049
                        ],
                        [
                            -100.30911399996,
                            42.9992930002922
                        ],
                        [
                            -99.9629850002188,
                            42.9976610001865
                        ],
                        [
                            -98.5003589996888,
                            42.9985990000589
                        ],
                        [
                            -98.4985500002174,
                            42.9985589998426
                        ],
                        [
                            -98.4957469996128,
                            42.988032000067
                        ],
                        [
                            -98.4904829996086,
                            42.977947999985
                        ],
                        [
                            -98.4673560001797,
                            42.9475560001641
                        ],
                        [
                            -98.448308999651,
                            42.9364279996902
                        ],
                        [
                            -98.4441450001869,
                            42.929242000168
                        ],
                        [
                            -98.4372850002484,
                            42.9283930002821
                        ],
                        [
                            -98.4309340001229,
                            42.931504000192
                        ],
                        [
                            -98.4207400000254,
                            42.9319239998117
                        ],
                        [
                            -98.4078240004124,
                            42.9257499999105
                        ],
                        [
                            -98.3462299996572,
                            42.9027469997944
                        ],
                        [
                            -98.3379899996943,
                            42.8977599998145
                        ],
                        [
                            -98.3324219999165,
                            42.890499999819
                        ],
                        [
                            -98.3258640001018,
                            42.8865000002555
                        ],
                        [
                            -98.2800070000057,
                            42.8749959998711
                        ],
                        [
                            -98.2582759995963,
                            42.8743900003074
                        ],
                        [
                            -98.2518099998431,
                            42.8728239997738
                        ],
                        [
                            -98.2468300004194,
                            42.8683969999399
                        ],
                        [
                            -98.2319220001386,
                            42.8611400002883
                        ],
                        [
                            -98.219825999785,
                            42.853157000002
                        ],
                        [
                            -98.1898109999302,
                            42.841642999982
                        ],
                        [
                            -98.1675230000272,
                            42.8369249999586
                        ],
                        [
                            -98.1471089998929,
                            42.8396310002177
                        ],
                        [
                            -98.1381030001991,
                            42.8325430000526
                        ],
                        [
                            -98.127673999925,
                            42.8199559998776
                        ],
                        [
                            -98.1124379995812,
                            42.8132019999794
                        ],
                        [
                            -98.0947209997463,
                            42.7991579998689
                        ],
                        [
                            -98.082910999623,
                            42.7942000001766
                        ],
                        [
                            -98.0674860000316,
                            42.7846349998096
                        ],
                        [
                            -98.062998999629,
                            42.7810009999207
                        ],
                        [
                            -98.0599030000198,
                            42.7726629998861
                        ],
                        [
                            -98.0516759995774,
                            42.7686669997903
                        ],
                        [
                            -98.0420559995903,
                            42.767220000059
                        ],
                        [
                            -98.0350709999782,
                            42.7641139999512
                        ],
                        [
                            -98.0172560002341,
                            42.7623289999498
                        ],
                        [
                            -98.0057659997779,
                            42.7640860000896
                        ],
                        [
                            -98.0003710002525,
                            42.7631789998008
                        ],
                        [
                            -97.977614999646,
                            42.7698469998264
                        ],
                        [
                            -97.962067000031,
                            42.768636999882
                        ],
                        [
                            -97.950169999869,
                            42.7695499996849
                        ],
                        [
                            -97.9367460002679,
                            42.7756829997297
                        ],
                        [
                            -97.9214799997869,
                            42.7882749997825
                        ],
                        [
                            -97.9159949998498,
                            42.789823999993
                        ],
                        [
                            -97.9090360001771,
                            42.7948330000387
                        ],
                        [
                            -97.8886470001571,
                            42.8171770003093
                        ],
                        [
                            -97.8800029999633,
                            42.8353269998317
                        ],
                        [
                            -97.8791200000564,
                            42.8436020000363
                        ],
                        [
                            -97.8760040003557,
                            42.8476589999165
                        ],
                        [
                            -97.8771759999675,
                            42.8543219997194
                        ],
                        [
                            -97.8755309996293,
                            42.8586560001952
                        ],
                        [
                            -97.8581130004436,
                            42.8650599996908
                        ],
                        [
                            -97.8419659997055,
                            42.8681660001559
                        ],
                        [
                            -97.8285730000666,
                            42.8688019996893
                        ],
                        [
                            -97.8171119999227,
                            42.8617769999833
                        ],
                        [
                            -97.7743809995731,
                            42.8497400002815
                        ],
                        [
                            -97.7206829997224,
                            42.847316999769
                        ],
                        [
                            -97.686476999741,
                            42.8422120000721
                        ],
                        [
                            -97.6579030000099,
                            42.844400000199
                        ],
                        [
                            -97.6118109997372,
                            42.8583670003028
                        ],
                        [
                            -97.60376199957,
                            42.8583290003098
                        ],
                        [
                            -97.5784740003172,
                            42.8505439998676
                        ],
                        [
                            -97.5578939996033,
                            42.8475320001328
                        ],
                        [
                            -97.5223710002452,
                            42.851978999934
                        ],
                        [
                            -97.5048469998558,
                            42.8584769997696
                        ],
                        [
                            -97.500341000085,
                            42.8572200002459
                        ],
                        [
                            -97.4962300003244,
                            42.8532309997179
                        ],
                        [
                            -97.4870100003704,
                            42.8505820000412
                        ],
                        [
                            -97.4705289997215,
                            42.8504549996815
                        ],
                        [
                            -97.4521769996769,
                            42.8460480000757
                        ],
                        [
                            -97.4437559999641,
                            42.8460180001302
                        ],
                        [
                            -97.4334549997365,
                            42.8503929999866
                        ],
                        [
                            -97.4170660000474,
                            42.8659180001734
                        ],
                        [
                            -97.4074090002503,
                            42.8683910000163
                        ],
                        [
                            -97.3993030001818,
                            42.864835000199
                        ],
                        [
                            -97.3939639998346,
                            42.8642499997894
                        ],
                        [
                            -97.3766949997134,
                            42.8651950001775
                        ],
                        [
                            -97.3686430000715,
                            42.8584189999435
                        ],
                        [
                            -97.3595689997073,
                            42.854816000227
                        ],
                        [
                            -97.3361560000481,
                            42.8568019998323
                        ],
                        [
                            -97.3066769997705,
                            42.8676040002637
                        ],
                        [
                            -97.3020749997403,
                            42.8656599998134
                        ],
                        [
                            -97.289858999736,
                            42.8554990002296
                        ],
                        [
                            -97.2692250003921,
                            42.8526020002937
                        ],
                        [
                            -97.2503300001993,
                            42.8556119997909
                        ],
                        [
                            -97.2378680001478,
                            42.8531389997373
                        ],
                        [
                            -97.2180459996672,
                            42.8451129998685
                        ],
                        [
                            -97.2182690001483,
                            42.8295610002308
                        ],
                        [
                            -97.2139569996479,
                            42.8201429996981
                        ],
                        [
                            -97.2130839997868,
                            42.8130070002122
                        ],
                        [
                            -97.2101260002698,
                            42.8092960001182
                        ],
                        [
                            -97.2004309999396,
                            42.8054850000229
                        ],
                        [
                            -97.1669780001687,
                            42.8020870001972
                        ],
                        [
                            -97.1507629996586,
                            42.7955659998367
                        ],
                        [
                            -97.1382160001166,
                            42.7834279998204
                        ],
                        [
                            -97.1344610000082,
                            42.7744940000558
                        ],
                        [
                            -97.1302570003612,
                            42.7715409997354
                        ],
                        [
                            -97.1126459999332,
                            42.7691289999729
                        ],
                        [
                            -97.0672299998781,
                            42.7723230002484
                        ],
                        [
                            -97.0416789999613,
                            42.7679679998661
                        ],
                        [
                            -97.0248500000561,
                            42.7624299997117
                        ],
                        [
                            -96.9928199999673,
                            42.7594810001916
                        ],
                        [
                            -96.9791200001817,
                            42.7600900000194
                        ],
                        [
                            -96.9688800000535,
                            42.7542779996914
                        ],
                        [
                            -96.9612300000945,
                            42.7406230002193
                        ],
                        [
                            -96.9612910001939,
                            42.7365690000391
                        ],
                        [
                            -96.9658329999497,
                            42.7270960002847
                        ],
                        [
                            -96.9647759999656,
                            42.7224549998794
                        ],
                        [
                            -96.9615759997009,
                            42.7198410001747
                        ],
                        [
                            -96.9489019999373,
                            42.7194649999608
                        ],
                        [
                            -96.9367729999717,
                            42.7234279997806
                        ],
                        [
                            -96.9241560001095,
                            42.7303270000455
                        ],
                        [
                            -96.9067969995766,
                            42.7337999998206
                        ],
                        [
                            -96.886845000298,
                            42.7252220002333
                        ],
                        [
                            -96.872788999962,
                            42.7240959997585
                        ],
                        [
                            -96.8604359996904,
                            42.7207970001463
                        ],
                        [
                            -96.8434190003626,
                            42.7120240000142
                        ],
                        [
                            -96.8062130001331,
                            42.7041540000687
                        ],
                        [
                            -96.8016160001257,
                            42.6987759997382
                        ],
                        [
                            -96.8004829999736,
                            42.6924640002973
                        ],
                        [
                            -96.8021769998173,
                            42.6722349999382
                        ],
                        [
                            -96.800836999976,
                            42.6696610000182
                        ],
                        [
                            -96.7932349996976,
                            42.6660209997434
                        ],
                        [
                            -96.7767820004344,
                            42.6627159998634
                        ],
                        [
                            -96.7640609999168,
                            42.6619830000012
                        ],
                        [
                            -96.7469480003295,
                            42.6662220003187
                        ],
                        [
                            -96.7280270001975,
                            42.6668799997687
                        ],
                        [
                            -96.6976390000737,
                            42.6591410002084
                        ],
                        [
                            -96.6912679998567,
                            42.6561979998261
                        ],
                        [
                            -96.6870800001033,
                            42.6520930000332
                        ],
                        [
                            -96.6877899997577,
                            42.6459930000274
                        ],
                        [
                            -96.6968530002513,
                            42.63759399983
                        ],
                        [
                            -96.7094869998319,
                            42.6219310003214
                        ],
                        [
                            -96.7115480000967,
                            42.6147589999908
                        ],
                        [
                            -96.7098240001158,
                            42.6051000001556
                        ],
                        [
                            -96.706232000214,
                            42.5992220001953
                        ],
                        [
                            -96.6840589999388,
                            42.5764619999799
                        ],
                        [
                            -96.6759489996722,
                            42.5716010002383
                        ],
                        [
                            -96.6587520004194,
                            42.5664239998445
                        ],
                        [
                            -96.6435849996726,
                            42.5576069998011
                        ],
                        [
                            -96.6353299996411,
                            42.5476400000242
                        ],
                        [
                            -96.633322999803,
                            42.5402099998104
                        ],
                        [
                            -96.6327939998985,
                            42.5286980001245
                        ],
                        [
                            -96.6281809999976,
                            42.5169640000799
                        ],
                        [
                            -96.6259609997241,
                            42.5135780000696
                        ],
                        [
                            -96.6114879996365,
                            42.5060860002752
                        ],
                        [
                            -96.6034700003299,
                            42.504460000303
                        ],
                        [
                            -96.5911179998832,
                            42.5054070001316
                        ],
                        [
                            -96.5843500000062,
                            42.5078360000111
                        ],
                        [
                            -96.5678929996467,
                            42.5178749998282
                        ],
                        [
                            -96.5577760004404,
                            42.52038000007
                        ],
                        [
                            -96.5487890001149,
                            42.5205469998701
                        ],
                        [
                            -96.5379899999449,
                            42.5181289997451
                        ],
                        [
                            -96.5287780003867,
                            42.5132670002276
                        ],
                        [
                            -96.5206279999373,
                            42.5046930002459
                        ],
                        [
                            -96.5158369997611,
                            42.4942230002348
                        ],
                        [
                            -96.5085869998352,
                            42.4866980001962
                        ],
                        [
                            -96.501319000366,
                            42.4827490002257
                        ],
                        [
                            -96.489494000174,
                            42.4801089999279
                        ],
                        [
                            -96.4787760001949,
                            42.4797019997909
                        ],
                        [
                            -96.4454830002576,
                            42.4906299999549
                        ],
                        [
                            -96.4019619998876,
                            42.4864399998679
                        ],
                        [
                            -96.3961239997731,
                            42.4843610002942
                        ],
                        [
                            -96.3860030003689,
                            42.474495999753
                        ],
                        [
                            -96.3813050000791,
                            42.4616949999361
                        ],
                        [
                            -96.3807050000295,
                            42.4463929998841
                        ],
                        [
                            -96.3876079996257,
                            42.4324940002994
                        ],
                        [
                            -96.4103070003308,
                            42.4129650002233
                        ],
                        [
                            -96.4151860003705,
                            42.4042030000519
                        ],
                        [
                            -96.4149799996078,
                            42.3934419998707
                        ],
                        [
                            -96.4091530002623,
                            42.3814909998653
                        ],
                        [
                            -96.4084360000368,
                            42.3760919999947
                        ],
                        [
                            -96.4170929997511,
                            42.3614429996897
                        ],
                        [
                            -96.4177859996872,
                            42.351448999957
                        ],
                        [
                            -96.4138950000346,
                            42.3433930000489
                        ],
                        [
                            -96.4079980003689,
                            42.3374080000314
                        ],
                        [
                            -96.3841689998848,
                            42.3258739998023
                        ],
                        [
                            -96.375307000131,
                            42.3183389999628
                        ],
                        [
                            -96.3692120000985,
                            42.3083439997227
                        ],
                        [
                            -96.368467000284,
                            42.292619000086
                        ],
                        [
                            -96.365750999718,
                            42.2858139997787
                        ],
                        [
                            -96.3565500000305,
                            42.2765940000533
                        ],
                        [
                            -96.3413870003799,
                            42.2690869997993
                        ],
                        [
                            -96.3359640003671,
                            42.264776999953
                        ],
                        [
                            -96.328896999841,
                            42.2547230002543
                        ],
                        [
                            -96.3277059999626,
                            42.2499919999501
                        ],
                        [
                            -96.3300059997037,
                            42.2402249999376
                        ],
                        [
                            -96.3228679999308,
                            42.2336369998548
                        ],
                        [
                            -96.3237230002485,
                            42.2298870000135
                        ],
                        [
                            -96.3369979998867,
                            42.2174659998784
                        ],
                        [
                            -96.3565910000384,
                            42.2151820000994
                        ],
                        [
                            -96.3598699999458,
                            42.2105530002826
                        ],
                        [
                            -96.3495670000683,
                            42.1981410002969
                        ],
                        [
                            -96.3473739995591,
                            42.1930750002922
                        ],
                        [
                            -96.3472780001979,
                            42.1857739997667
                        ],
                        [
                            -96.3503610002867,
                            42.1769029998679
                        ],
                        [
                            -96.3486020001457,
                            42.1680270002233
                        ],
                        [
                            -96.3402280002886,
                            42.1580570002474
                        ],
                        [
                            -96.3268549998428,
                            42.1523240001193
                        ],
                        [
                            -96.3207399997189,
                            42.1479279999691
                        ],
                        [
                            -96.31404600036,
                            42.1364489997464
                        ],
                        [
                            -96.3097580001491,
                            42.132101000176
                        ],
                        [
                            -96.3001540000555,
                            42.1276960000342
                        ],
                        [
                            -96.2830020001101,
                            42.1250309998221
                        ],
                        [
                            -96.274583999872,
                            42.1203369998828
                        ],
                        [
                            -96.2694589997851,
                            42.1142159999094
                        ],
                        [
                            -96.2669880003398,
                            42.1061720003227
                        ],
                        [
                            -96.2677390000021,
                            42.0970549998598
                        ],
                        [
                            -96.2767530000919,
                            42.0816959998992
                        ],
                        [
                            -96.2794789998053,
                            42.0708799999502
                        ],
                        [
                            -96.2780429997045,
                            42.0584120001431
                        ],
                        [
                            -96.2743930000764,
                            42.0492439997137
                        ],
                        [
                            -96.2680629998672,
                            42.0417049997912
                        ],
                        [
                            -96.2583559998414,
                            42.0383379998278
                        ],
                        [
                            -96.2468319999484,
                            42.0416159998304
                        ],
                        [
                            -96.2343559996531,
                            42.0407119998731
                        ],
                        [
                            -96.2302530002885,
                            42.0394329996779
                        ],
                        [
                            -96.2269769998558,
                            42.0373530002325
                        ],
                        [
                            -96.2301560002041,
                            42.0388709998299
                        ],
                        [
                            -96.2350340004189,
                            42.040136999773
                        ],
                        [
                            -96.2256560002811,
                            42.0352169999529
                        ],
                        [
                            -96.2219010001727,
                            42.0295579996903
                        ],
                        [
                            -96.2236109999098,
                            42.0226520002152
                        ],
                        [
                            -96.2388599997741,
                            42.0123150002148
                        ],
                        [
                            -96.2408320003505,
                            42.0093149998709
                        ],
                        [
                            -96.2423799997059,
                            42.0028980001284
                        ],
                        [
                            -96.2407129996264,
                            41.9993510000466
                        ],
                        [
                            -96.2349520004031,
                            41.995735999815
                        ],
                        [
                            -96.2271719998495,
                            41.9943319996822
                        ],
                        [
                            -96.2226409999644,
                            41.9964510000959
                        ],
                        [
                            -96.2152250003571,
                            42.0067009999354
                        ],
                        [
                            -96.2060830002209,
                            42.0092669999863
                        ],
                        [
                            -96.1945559999547,
                            42.0086620002264
                        ],
                        [
                            -96.1859190003319,
                            42.0059139997388
                        ],
                        [
                            -96.1840020000071,
                            42.0030629997442
                        ],
                        [
                            -96.1842549997271,
                            41.9966339999412
                        ],
                        [
                            -96.1921409996876,
                            41.9844609997448
                        ],
                        [
                            -96.1906080004008,
                            41.9807290001542
                        ],
                        [
                            -96.1872189999902,
                            41.9779460002116
                        ],
                        [
                            -96.1772039999929,
                            41.9763080002866
                        ],
                        [
                            -96.1580490004074,
                            41.9802749999566
                        ],
                        [
                            -96.1427150001543,
                            41.9784910000259
                        ],
                        [
                            -96.13035500021,
                            41.9728629997743
                        ],
                        [
                            -96.1288999998426,
                            41.9697270000307
                        ],
                        [
                            -96.1299779997432,
                            41.9626420002958
                        ],
                        [
                            -96.1353930002584,
                            41.9522230000638
                        ],
                        [
                            -96.1425970001535,
                            41.9459079999037
                        ],
                        [
                            -96.1445830000752,
                            41.9415440002974
                        ],
                        [
                            -96.1434929995808,
                            41.9373870001408
                        ],
                        [
                            -96.1366130004491,
                            41.9271669997793
                        ],
                        [
                            -96.1367430001455,
                            41.9208260000335
                        ],
                        [
                            -96.1422649998925,
                            41.9153790003129
                        ],
                        [
                            -96.1590979999956,
                            41.910056999831
                        ],
                        [
                            -96.1619879997405,
                            41.9055530000935
                        ],
                        [
                            -96.1617559999369,
                            41.9018200000821
                        ],
                        [
                            -96.1487830004226,
                            41.8882049999081
                        ],
                        [
                            -96.1459739999702,
                            41.8750930002329
                        ],
                        [
                            -96.1419080004154,
                            41.8689989998168
                        ],
                        [
                            -96.134959999715,
                            41.8632520000525
                        ],
                        [
                            -96.1165969997998,
                            41.8551030001891
                        ],
                        [
                            -96.1105479997981,
                            41.8491989997063
                        ],
                        [
                            -96.1076170000453,
                            41.8404000002327
                        ],
                        [
                            -96.1109620000749,
                            41.8288270001732
                        ],
                        [
                            -96.1093839996838,
                            41.823134999921
                        ],
                        [
                            -96.106565000084,
                            41.8196130002283
                        ],
                        [
                            -96.0974799998492,
                            41.8139169999197
                        ],
                        [
                            -96.0767739996369,
                            41.8085340000529
                        ],
                        [
                            -96.0695610004192,
                            41.8032179999176
                        ],
                        [
                            -96.0653269997366,
                            41.7971730000334
                        ],
                        [
                            -96.0648630001295,
                            41.7918889999893
                        ],
                        [
                            -96.0663770000481,
                            41.7889810001289
                        ],
                        [
                            -96.0775460001139,
                            41.7778220002328
                        ],
                        [
                            -96.0790209996745,
                            41.7723500000172
                        ],
                        [
                            -96.0784969997928,
                            41.762631999762
                        ],
                        [
                            -96.0808900003186,
                            41.7575260001435
                        ],
                        [
                            -96.0873610000947,
                            41.7521759999211
                        ],
                        [
                            -96.1009810004127,
                            41.7475720003062
                        ],
                        [
                            -96.1048050001181,
                            41.7430160001662
                        ],
                        [
                            -96.1063800001361,
                            41.7377620001597
                        ],
                        [
                            -96.1053289999998,
                            41.731504999755
                        ],
                        [
                            -96.1022119995758,
                            41.7276410002397
                        ],
                        [
                            -96.0852720002409,
                            41.7213130002973
                        ],
                        [
                            -96.0766420002907,
                            41.715656999796
                        ],
                        [
                            -96.0733609998352,
                            41.7101989997327
                        ],
                        [
                            -96.073225000291,
                            41.7040620000148
                        ],
                        [
                            -96.0773310000288,
                            41.7002320000316
                        ],
                        [
                            -96.0846400004042,
                            41.6977089998682
                        ],
                        [
                            -96.1038140002562,
                            41.6994030001449
                        ],
                        [
                            -96.1134610000076,
                            41.6975690000997
                        ],
                        [
                            -96.1198609996386,
                            41.6926490003064
                        ],
                        [
                            -96.1226039999687,
                            41.6830399998578
                        ],
                        [
                            -96.1210480002173,
                            41.6770019997977
                        ],
                        [
                            -96.1179590002808,
                            41.6733140002648
                        ],
                        [
                            -96.1000549999498,
                            41.6610769997928
                        ],
                        [
                            -96.095221000116,
                            41.650114000129
                        ],
                        [
                            -96.0977769999501,
                            41.6396250002428
                        ],
                        [
                            -96.1159950001004,
                            41.6220239997737
                        ],
                        [
                            -96.1182770002981,
                            41.6149670000403
                        ],
                        [
                            -96.1158109999773,
                            41.6056589999898
                        ],
                        [
                            -96.109454999829,
                            41.5967750001057
                        ],
                        [
                            -96.1013310002171,
                            41.5914110002651
                        ],
                        [
                            -96.0871860001926,
                            41.5865500000576
                        ],
                        [
                            -96.0839630003617,
                            41.5836830001768
                        ],
                        [
                            -96.0813899999108,
                            41.5779000001618
                        ],
                        [
                            -96.082486000253,
                            41.5711449997857
                        ],
                        [
                            -96.0912590003183,
                            41.5622439996808
                        ],
                        [
                            -96.0950420000158,
                            41.5547679997091
                        ],
                        [
                            -96.0966170000338,
                            41.5456259997303
                        ],
                        [
                            -96.0944369999434,
                            41.5394629999834
                        ],
                        [
                            -96.0901179997703,
                            41.5317020001555
                        ],
                        [
                            -96.0826539995842,
                            41.525973999967
                        ],
                        [
                            -96.0733890003226,
                            41.5235290000945
                        ],
                        [
                            -96.052765000126,
                            41.5084359996753
                        ],
                        [
                            -96.0406129996959,
                            41.5068930001759
                        ],
                        [
                            -96.0366030002177,
                            41.5090469997603
                        ],
                        [
                            -96.034352999807,
                            41.5127609996862
                        ],
                        [
                            -96.0344409996705,
                            41.5210509998656
                        ],
                        [
                            -96.0311269996031,
                            41.5230039997211
                        ],
                        [
                            -96.0266569998173,
                            41.5403660003182
                        ],
                        [
                            -96.0191499999735,
                            41.5451400003222
                        ],
                        [
                            -96.0051120000791,
                            41.5432500001707
                        ],
                        [
                            -95.9999660000757,
                            41.5394799999729
                        ],
                        [
                            -95.9943079998862,
                            41.5237430003302
                        ],
                        [
                            -95.9927770002492,
                            41.5145959998739
                        ],
                        [
                            -95.9961940002488,
                            41.5069599996897
                        ],
                        [
                            -96.000648999966,
                            41.5025400002753
                        ],
                        [
                            -96.0195430003339,
                            41.4915919997261
                        ],
                        [
                            -96.0185630003426,
                            41.4849909997844
                        ],
                        [
                            -96.0135849996704,
                            41.4784689996936
                        ],
                        [
                            -96.004936000352,
                            41.4724130000326
                        ],
                        [
                            -95.9842789996451,
                            41.4709390000534
                        ],
                        [
                            -95.966688000207,
                            41.4634250002271
                        ],
                        [
                            -95.9583720000762,
                            41.4632180002901
                        ],
                        [
                            -95.9450070000263,
                            41.4664850002663
                        ],
                        [
                            -95.9363400002662,
                            41.465303999854
                        ],
                        [
                            -95.9227860000705,
                            41.4573060002589
                        ],
                        [
                            -95.919989000212,
                            41.4526039998113
                        ],
                        [
                            -95.9199049996481,
                            41.4458689997084
                        ],
                        [
                            -95.9221059996549,
                            41.4416650002672
                        ],
                        [
                            -95.9304229996107,
                            41.4347470001392
                        ],
                        [
                            -95.9327779996033,
                            41.4308149997733
                        ],
                        [
                            -95.9296769999712,
                            41.4110710000678
                        ],
                        [
                            -95.9376940003513,
                            41.3947700001339
                        ],
                        [
                            -95.9364110004113,
                            41.3858760000125
                        ],
                        [
                            -95.9294860001755,
                            41.3753260000066
                        ],
                        [
                            -95.9296810001692,
                            41.3670579997154
                        ],
                        [
                            -95.9369229996991,
                            41.3592469997555
                        ],
                        [
                            -95.953953000344,
                            41.353775999963
                        ],
                        [
                            -95.9571300001441,
                            41.3495930001743
                        ],
                        [
                            -95.9571369998168,
                            41.345902000008
                        ],
                        [
                            -95.9534179996934,
                            41.3394700000763
                        ],
                        [
                            -95.9465319998156,
                            41.3331390002747
                        ],
                        [
                            -95.9258349998241,
                            41.321579999743
                        ],
                        [
                            -95.9150909999057,
                            41.3199179996973
                        ],
                        [
                            -95.8999430003238,
                            41.3214809996938
                        ],
                        [
                            -95.8855539999018,
                            41.3180969997088
                        ],
                        [
                            -95.8767599999201,
                            41.3115369998636
                        ],
                        [
                            -95.8718709998346,
                            41.3018129999223
                        ],
                        [
                            -95.8715889998023,
                            41.2927350002223
                        ],
                        [
                            -95.8760510000905,
                            41.2857390002985
                        ],
                        [
                            -95.8844950002678,
                            41.2806890001175
                        ],
                        [
                            -95.9028399997414,
                            41.2743739996944
                        ],
                        [
                            -95.9125810001024,
                            41.2795270000859
                        ],
                        [
                            -95.9061439997631,
                            41.2874879999021
                        ],
                        [
                            -95.9042220003138,
                            41.2975909999629
                        ],
                        [
                            -95.9058990004391,
                            41.3010379998699
                        ],
                        [
                            -95.9202469999548,
                            41.301191000198
                        ],
                        [
                            -95.9276419996457,
                            41.2984399999916
                        ],
                        [
                            -95.9296510000319,
                            41.2922620002166
                        ],
                        [
                            -95.9286459999263,
                            41.2813320001801
                        ],
                        [
                            -95.914090999823,
                            41.2724279998961
                        ],
                        [
                            -95.9214970002828,
                            41.2676039997512
                        ],
                        [
                            -95.9223040000217,
                            41.2630199998044
                        ],
                        [
                            -95.9190929998864,
                            41.2526979996774
                        ],
                        [
                            -95.9118599996789,
                            41.24102300025
                        ],
                        [
                            -95.9106289996175,
                            41.2345789998983
                        ],
                        [
                            -95.914271999573,
                            41.2238579998771
                        ],
                        [
                            -95.9261169998564,
                            41.2116190002126
                        ],
                        [
                            -95.9279749997316,
                            41.2000690001109
                        ],
                        [
                            -95.9232050003702,
                            41.190282000065
                        ],
                        [
                            -95.9161189995775,
                            41.1852790003008
                        ],
                        [
                            -95.9005099998631,
                            41.184608999913
                        ],
                        [
                            -95.8781330002717,
                            41.1879410002455
                        ],
                        [
                            -95.8637969995531,
                            41.1882450002408
                        ],
                        [
                            -95.850373999777,
                            41.1850899999522
                        ],
                        [
                            -95.845432999813,
                            41.1819300000643
                        ],
                        [
                            -95.8421880002408,
                            41.1774210002322
                        ],
                        [
                            -95.8420390002779,
                            41.171366999733
                        ],
                        [
                            -95.8469569997773,
                            41.1666039998083
                        ],
                        [
                            -95.853451000018,
                            41.1655179999891
                        ],
                        [
                            -95.865928999963,
                            41.1681290001738
                        ],
                        [
                            -95.8711100001265,
                            41.1676349999629
                        ],
                        [
                            -95.8773680003656,
                            41.1639140003131
                        ],
                        [
                            -95.8825400003083,
                            41.1558809997589
                        ],
                        [
                            -95.8831289995889,
                            41.1481960000547
                        ],
                        [
                            -95.8812220002082,
                            41.1416650000035
                        ],
                        [
                            -95.8672279997965,
                            41.1214930001758
                        ],
                        [
                            -95.8666610002571,
                            41.1049750002166
                        ],
                        [
                            -95.8624269995746,
                            41.0896869997548
                        ],
                        [
                            -95.8658350002517,
                            41.0800789998758
                        ],
                        [
                            -95.881011000321,
                            41.0663030000017
                        ],
                        [
                            -95.8823419999415,
                            41.0594189997622
                        ],
                        [
                            -95.8815879999061,
                            41.0543780000658
                        ],
                        [
                            -95.8617820002174,
                            41.0394270002765
                        ],
                        [
                            -95.8596540000055,
                            41.0356949998882
                        ],
                        [
                            -95.8599179995961,
                            41.0254029999032
                        ],
                        [
                            -95.8658860003053,
                            41.0174179997407
                        ],
                        [
                            -95.8695019995983,
                            41.0094290000668
                        ],
                        [
                            -95.866950999787,
                            41.0010849998842
                        ],
                        [
                            -95.8601159999628,
                            40.9952420002537
                        ],
                        [
                            -95.8389079996102,
                            40.9864840000358
                        ],
                        [
                            -95.8335370003742,
                            40.9826599997149
                        ],
                        [
                            -95.8297920003115,
                            40.9773439997297
                        ],
                        [
                            -95.8283290004465,
                            40.9723780002015
                        ],
                        [
                            -95.8298290001214,
                            40.9638570001097
                        ],
                        [
                            -95.8379510000835,
                            40.9506180003191
                        ],
                        [
                            -95.840275000114,
                            40.9399419998885
                        ],
                        [
                            -95.8397429998365,
                            40.9327800000676
                        ],
                        [
                            -95.8349059996296,
                            40.9195739997427
                        ],
                        [
                            -95.8143020004228,
                            40.9029359999053
                        ],
                        [
                            -95.8093790000022,
                            40.8932790002491
                        ],
                        [
                            -95.8107089997978,
                            40.8866810002924
                        ],
                        [
                            -95.815932999619,
                            40.8798459997087
                        ],
                        [
                            -95.8249890004398,
                            40.8750000003129
                        ],
                        [
                            -95.840788000125,
                            40.8712360002344
                        ],
                        [
                            -95.8477850003309,
                            40.8643279998929
                        ],
                        [
                            -95.8485650003056,
                            40.8596650002382
                        ],
                        [
                            -95.8470839999989,
                            40.8541740001745
                        ],
                        [
                            -95.8413089996336,
                            40.8456040003096
                        ],
                        [
                            -95.8371860001774,
                            40.8353470001205
                        ],
                        [
                            -95.8386010003618,
                            40.8261749997894
                        ],
                        [
                            -95.8439210004425,
                            40.8176859997058
                        ],
                        [
                            -95.8453419995764,
                            40.8113239998466
                        ],
                        [
                            -95.8437449998171,
                            40.8037829999689
                        ],
                        [
                            -95.8345230002132,
                            40.7877779998127
                        ],
                        [
                            -95.8348810004135,
                            40.7802099999428
                        ],
                        [
                            -95.8369030003202,
                            40.7764770003063
                        ],
                        [
                            -95.8466200003918,
                            40.7686190002231
                        ],
                        [
                            -95.8699819999973,
                            40.7596450000538
                        ],
                        [
                            -95.8815290003549,
                            40.7506110003115
                        ],
                        [
                            -95.888697000265,
                            40.7362920000214
                        ],
                        [
                            -95.8889070003273,
                            40.73185500016
                        ],
                        [
                            -95.8853489998623,
                            40.7210930001364
                        ],
                        [
                            -95.8831779999927,
                            40.7175789997292
                        ],
                        [
                            -95.8617980001109,
                            40.7092419998852
                        ],
                        [
                            -95.8544560001235,
                            40.7041639997016
                        ],
                        [
                            -95.8486799999333,
                            40.6959729999965
                        ],
                        [
                            -95.845765000074,
                            40.6818060002282
                        ],
                        [
                            -95.8423160002873,
                            40.6771709998906
                        ],
                        [
                            -95.8229130001064,
                            40.6672400000016
                        ],
                        [
                            -95.7954890002458,
                            40.6623839998727
                        ],
                        [
                            -95.7838220002376,
                            40.6549740001588
                        ],
                        [
                            -95.7762509999213,
                            40.6474630001064
                        ],
                        [
                            -95.7714699997909,
                            40.6397879997573
                        ],
                        [
                            -95.7689259996524,
                            40.6212640003269
                        ],
                        [
                            -95.7496849998532,
                            40.6068419999719
                        ],
                        [
                            -95.7485719997926,
                            40.6012649997561
                        ],
                        [
                            -95.7502740000321,
                            40.5963169998598
                        ],
                        [
                            -95.7531479998836,
                            40.5928399999168
                        ],
                        [
                            -95.7685270003425,
                            40.5832959999269
                        ],
                        [
                            -95.773548999599,
                            40.5782050002475
                        ],
                        [
                            -95.7747040003908,
                            40.5735740001705
                        ],
                        [
                            -95.7717760001127,
                            40.5661330001567
                        ],
                        [
                            -95.7638330002507,
                            40.5538730001521
                        ],
                        [
                            -95.7636240000134,
                            40.548298000143
                        ],
                        [
                            -95.7692810003779,
                            40.5366559998156
                        ],
                        [
                            -95.7669199996392,
                            40.5315629999313
                        ],
                        [
                            -95.7628569995592,
                            40.5283710002632
                        ],
                        [
                            -95.7527429998276,
                            40.5248929998181
                        ],
                        [
                            -95.741828000205,
                            40.5239000001607
                        ],
                        [
                            -95.7347950000141,
                            40.5243079997601
                        ],
                        [
                            -95.725214000385,
                            40.527772999981
                        ],
                        [
                            -95.7175369997636,
                            40.5277830002899
                        ],
                        [
                            -95.7128619999383,
                            40.5263840002032
                        ],
                        [
                            -95.7085910003442,
                            40.5215510000246
                        ],
                        [
                            -95.6972020001703,
                            40.5284750001535
                        ],
                        [
                            -95.6954949999079,
                            40.5320209997556
                        ],
                        [
                            -95.6973770000725,
                            40.5413889998076
                        ],
                        [
                            -95.6941469996706,
                            40.5569420002552
                        ],
                        [
                            -95.6870209995933,
                            40.5606689998416
                        ],
                        [
                            -95.6787150004065,
                            40.5625609999768
                        ],
                        [
                            -95.671775999927,
                            40.5626279998474
                        ],
                        [
                            -95.6654950001216,
                            40.5566969999247
                        ],
                        [
                            -95.6621070004343,
                            40.5499670000353
                        ],
                        [
                            -95.6558480003703,
                            40.54660899984
                        ],
                        [
                            -95.652268999989,
                            40.5381080002805
                        ],
                        [
                            -95.655674000293,
                            40.523557000114
                        ],
                        [
                            -95.6616809995637,
                            40.5173119997571
                        ],
                        [
                            -95.6999689998916,
                            40.5052749999994
                        ],
                        [
                            -95.6948229998882,
                            40.4947049998023
                        ],
                        [
                            -95.6967560001065,
                            40.4788489999611
                        ],
                        [
                            -95.6946510003591,
                            40.4714519998188
                        ],
                        [
                            -95.6875319999545,
                            40.4652060003397
                        ],
                        [
                            -95.6677139996719,
                            40.4534630002802
                        ],
                        [
                            -95.6565749997432,
                            40.438701000296
                        ],
                        [
                            -95.655963999823,
                            40.4298730001032
                        ],
                        [
                            -95.6607099997933,
                            40.4188269997805
                        ],
                        [
                            -95.6606019998382,
                            40.412408999822
                        ],
                        [
                            -95.6439350003148,
                            40.3868510001891
                        ],
                        [
                            -95.6410270001282,
                            40.3663989999363
                        ],
                        [
                            -95.627123999953,
                            40.3527999999482
                        ],
                        [
                            -95.6226840003045,
                            40.3423230002657
                        ],
                        [
                            -95.624014999925,
                            40.3362259997818
                        ],
                        [
                            -95.6276479998347,
                            40.3324120003006
                        ],
                        [
                            -95.6537290003792,
                            40.3225820002551
                        ],
                        [
                            -95.6577639999718,
                            40.3157879997593
                        ],
                        [
                            -95.6573279999537,
                            40.3108560000483
                        ],
                        [
                            -95.6515069995576,
                            40.3066839999772
                        ],
                        [
                            -95.6453289996845,
                            40.3056929996971
                        ],
                        [
                            -95.6179309997631,
                            40.3137280000528
                        ],
                        [
                            -95.6104389999879,
                            40.3139700000746
                        ],
                        [
                            -95.598657000352,
                            40.3098089998864
                        ],
                        [
                            -95.5901649995957,
                            40.3031990002197
                        ],
                        [
                            -95.581787000439,
                            40.2995800002479
                        ],
                        [
                            -95.5706929998179,
                            40.299425999972
                        ],
                        [
                            -95.562156999579,
                            40.2973589998619
                        ],
                        [
                            -95.5555529997335,
                            40.2932729996884
                        ],
                        [
                            -95.5516200002482,
                            40.288665999721
                        ],
                        [
                            -95.5514880000037,
                            40.281060999752
                        ],
                        [
                            -95.5562749999819,
                            40.2707610003062
                        ],
                        [
                            -95.5563250002106,
                            40.2677140000013
                        ],
                        [
                            -95.5524730000178,
                            40.261903999863
                        ],
                        [
                            -95.5437369997624,
                            40.2606870000523
                        ],
                        [
                            -95.5210349995826,
                            40.2500829997325
                        ],
                        [
                            -95.4934049998577,
                            40.2495600000297
                        ],
                        [
                            -95.4848169997403,
                            40.247469000248
                        ],
                        [
                            -95.4786940001187,
                            40.2436609999939
                        ],
                        [
                            -95.4725480000326,
                            40.2360779997589
                        ],
                        [
                            -95.4697179996638,
                            40.2279079996897
                        ],
                        [
                            -95.4713930001392,
                            40.2173329998326
                        ],
                        [
                            -95.4823189996325,
                            40.2006670000785
                        ],
                        [
                            -95.4825399995654,
                            40.1922829999616
                        ],
                        [
                            -95.479192999886,
                            40.1856520000733
                        ],
                        [
                            -95.4638310000438,
                            40.1712010003398
                        ],
                        [
                            -95.4428179996848,
                            40.1632610000806
                        ],
                        [
                            -95.4363479997336,
                            40.1587199997988
                        ],
                        [
                            -95.4338220000367,
                            40.1529349999149
                        ],
                        [
                            -95.432165000003,
                            40.1410250002315
                        ],
                        [
                            -95.427408999987,
                            40.1346079999606
                        ],
                        [
                            -95.4195499997906,
                            40.1310489999903
                        ],
                        [
                            -95.4076910001618,
                            40.129839000048
                        ],
                        [
                            -95.4002660003337,
                            40.1272929996685
                        ],
                        [
                            -95.3965489998601,
                            40.1247009996978
                        ],
                        [
                            -95.3933469999456,
                            40.1192119998833
                        ],
                        [
                            -95.3928099996452,
                            40.1129289997378
                        ],
                        [
                            -95.3942159996088,
                            40.1082630002878
                        ],
                        [
                            -95.4065980001927,
                            40.0991059997471
                        ],
                        [
                            -95.4100999996828,
                            40.0939750003154
                        ],
                        [
                            -95.410426000096,
                            40.0879480003088
                        ],
                        [
                            -95.4083890001207,
                            40.0797219998952
                        ],
                        [
                            -95.4093669995638,
                            40.0749980002762
                        ],
                        [
                            -95.4188960002126,
                            40.0659580000774
                        ],
                        [
                            -95.4213399998938,
                            40.0607459997853
                        ],
                        [
                            -95.4199990002275,
                            40.0504400001953
                        ],
                        [
                            -95.4151059999441,
                            40.040340999689
                        ],
                        [
                            -95.4072609999915,
                            40.0331119998504
                        ],
                        [
                            -95.3915269997053,
                            40.0270579997775
                        ],
                        [
                            -95.3829570000296,
                            40.0271120001379
                        ],
                        [
                            -95.3639830001941,
                            40.0314980000917
                        ],
                        [
                            -95.3568760003833,
                            40.0315219999178
                        ],
                        [
                            -95.3487769999875,
                            40.0292970002002
                        ],
                        [
                            -95.336242000141,
                            40.0191040000237
                        ],
                        [
                            -95.3152709996148,
                            40.0120700002605
                        ],
                        [
                            -95.3111630002273,
                            40.0078060000208
                        ],
                        [
                            -95.3082900002008,
                            39.9999980002159
                        ],
                        [
                            -98.2740169995552,
                            40.0026619997989
                        ],
                        [
                            -99.7568349995633,
                            40.0013419999843
                        ],
                        [
                            -102.051743999591,
                            40.0030780002988
                        ],
                        [
                            -102.051716999827,
                            41.0023589997564
                        ],
                        [
                            -104.053249000308,
                            41.0014059997388
                        ],
                        [
                            -104.052645000061,
                            42.0017180000533
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 41.5433014,
            "name": "Nebraska",
            "intptlon": -99.8118616,
            "geo_point_2d": [
                41.5271431364,
                -99.8110205566
            ],
            "geoid": "31",
            "mtfcc": "G4000",
            "region": 2
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -99.8110205566,
                41.5271431364
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "5f457effb9a1b807e56b5a58c6b76d5a4f0ffff0",
        "fields": {
            "arealand": "-1616974352",
            "objectid": 6,
            "basename": "Rhode Island",
            "stusab": "RI",
            "statens": "01219835",
            "centlon": -71.5247213,
            "state": "44",
            "gid": 40,
            "centlat": 41.5940253,
            "division": 1,
            "areawater": "1323457457",
            "oid": "-1861167639",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                -71.7897030003562,
                                41.7245629997204
                            ],
                            [
                                -71.7978830000446,
                                41.9310110001433
                            ],
                            [
                                -71.7991879997258,
                                42.0080529997619
                            ],
                            [
                                -71.5277020004221,
                                42.0150949999646
                            ],
                            [
                                -71.4982590001295,
                                42.0172719998823
                            ],
                            [
                                -71.3814009997263,
                                42.0187979997801
                            ],
                            [
                                -71.3814629996506,
                                41.8935460000175
                            ],
                            [
                                -71.3387869995526,
                                41.8985930000188
                            ],
                            [
                                -71.3408899996502,
                                41.8818040001622
                            ],
                            [
                                -71.3340399997574,
                                41.8626039999997
                            ],
                            [
                                -71.3425500000569,
                                41.844655999714
                            ],
                            [
                                -71.3354170003069,
                                41.8356450001951
                            ],
                            [
                                -71.3451979999526,
                                41.8276760001097
                            ],
                            [
                                -71.3475450004476,
                                41.8232460001313
                            ],
                            [
                                -71.339332000249,
                                41.8085270002287
                            ],
                            [
                                -71.3404810002946,
                                41.7970330000113
                            ],
                            [
                                -71.3346449998299,
                                41.7948350001564
                            ],
                            [
                                -71.3327429995738,
                                41.7901599996962
                            ],
                            [
                                -71.3294569999937,
                                41.7883270000824
                            ],
                            [
                                -71.3303909999541,
                                41.7853810002203
                            ],
                            [
                                -71.3284719999796,
                                41.7806940001221
                            ],
                            [
                                -71.2610669997723,
                                41.7520820001837
                            ],
                            [
                                -71.1954790003306,
                                41.6750900002277
                            ],
                            [
                                -71.1757840000716,
                                41.671493000104
                            ],
                            [
                                -71.1761199996323,
                                41.6682479999186
                            ],
                            [
                                -71.1325570003278,
                                41.6603630002315
                            ],
                            [
                                -71.1353070003307,
                                41.6284880000023
                            ],
                            [
                                -71.1401849996472,
                                41.6238689997119
                            ],
                            [
                                -71.1419580000318,
                                41.612532999684
                            ],
                            [
                                -71.1403079996708,
                                41.6048499999872
                            ],
                            [
                                -71.1316199999943,
                                41.5937159998945
                            ],
                            [
                                -71.1224369998502,
                                41.5220750003012
                            ],
                            [
                                -71.1198740003434,
                                41.4946040003201
                            ],
                            [
                                -71.1211160002754,
                                41.4927870000876
                            ],
                            [
                                -71.0885709996274,
                                41.4313149997622
                            ],
                            [
                                -71.1245940003744,
                                41.4114740001174
                            ],
                            [
                                -71.2499599996307,
                                41.3777130002631
                            ],
                            [
                                -71.374494999757,
                                41.3390109997684
                            ],
                            [
                                -71.4798760001227,
                                41.3125989999385
                            ],
                            [
                                -71.4948050003199,
                                41.3073549997809
                            ],
                            [
                                -71.5265940003843,
                                41.308803999992
                            ],
                            [
                                -71.5752530003618,
                                41.3209550001678
                            ],
                            [
                                -71.5981510003601,
                                41.3125940001026
                            ],
                            [
                                -71.6321880002871,
                                41.3048419998052
                            ],
                            [
                                -71.6595650002921,
                                41.2966529997974
                            ],
                            [
                                -71.6725949997078,
                                41.2890790002382
                            ],
                            [
                                -71.6950430003427,
                                41.2826469997487
                            ],
                            [
                                -71.7057439997051,
                                41.2807389999695
                            ],
                            [
                                -71.7199940002099,
                                41.281148999876
                            ],
                            [
                                -71.7395599996991,
                                41.2754490002662
                            ],
                            [
                                -71.778429999635,
                                41.2751110002386
                            ],
                            [
                                -71.7914800000406,
                                41.272164999947
                            ],
                            [
                                -71.7909720000524,
                                41.1841010001959
                            ],
                            [
                                -71.9072579999951,
                                41.3044830002895
                            ],
                            [
                                -71.8920669998571,
                                41.3300750002019
                            ],
                            [
                                -71.8665379996816,
                                41.3191850000815
                            ],
                            [
                                -71.8574580003679,
                                41.3207889997028
                            ],
                            [
                                -71.847708999611,
                                41.3296039999481
                            ],
                            [
                                -71.8372800002352,
                                41.3350679999292
                            ],
                            [
                                -71.8293649999624,
                                41.342432000211
                            ],
                            [
                                -71.8313030002035,
                                41.3512949997362
                            ],
                            [
                                -71.836044000151,
                                41.3539880002988
                            ],
                            [
                                -71.8378729997138,
                                41.3601450001731
                            ],
                            [
                                -71.8376330004127,
                                41.3654989997534
                            ],
                            [
                                -71.8318439998036,
                                41.3703439996667
                            ],
                            [
                                -71.8326559995653,
                                41.3758499999467
                            ],
                            [
                                -71.8306370000317,
                                41.3787719998533
                            ],
                            [
                                -71.8335269997766,
                                41.3822640003247
                            ],
                            [
                                -71.8331300001166,
                                41.3878390001489
                            ],
                            [
                                -71.838748999948,
                                41.3910539999132
                            ],
                            [
                                -71.8422089996053,
                                41.3953800002228
                            ],
                            [
                                -71.8410679999556,
                                41.399658000083
                            ],
                            [
                                -71.8435910001778,
                                41.4053310003039
                            ],
                            [
                                -71.8424260002386,
                                41.4102600001307
                            ],
                            [
                                -71.839257999761,
                                41.4123899998547
                            ],
                            [
                                -71.8341069997347,
                                41.4115820002727
                            ],
                            [
                                -71.8253839998982,
                                41.4146870002032
                            ],
                            [
                                -71.8237310000624,
                                41.4172819997694
                            ],
                            [
                                -71.8180879999415,
                                41.4198520003067
                            ],
                            [
                                -71.8068119997456,
                                41.4166730001888
                            ],
                            [
                                -71.8036840003492,
                                41.4174279999441
                            ],
                            [
                                -71.8014389999614,
                                41.4155449997442
                            ],
                            [
                                -71.7977449999524,
                                41.4167269996722
                            ],
                            [
                                -71.7876560003352,
                                41.6399890002335
                            ],
                            [
                                -71.7897030003562,
                                41.7245629997204
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -71.6726440001116,
                                41.134291999751
                            ],
                            [
                                -71.6783020003011,
                                41.1489040001556
                            ],
                            [
                                -71.6788069999162,
                                41.158911999977
                            ],
                            [
                                -71.6704909997853,
                                41.1945029999276
                            ],
                            [
                                -71.6637680001141,
                                41.2066049997921
                            ],
                            [
                                -71.6545609996806,
                                41.2154649997529
                            ],
                            [
                                -71.6430140002213,
                                41.2313230002814
                            ],
                            [
                                -71.6408440001765,
                                41.2501010001676
                            ],
                            [
                                -71.6349680004272,
                                41.2636819998305
                            ],
                            [
                                -71.6245039999929,
                                41.2741290000607
                            ],
                            [
                                -71.5915909999972,
                                41.2893170002079
                            ],
                            [
                                -71.5706549996312,
                                41.2940180001858
                            ],
                            [
                                -71.5575690001389,
                                41.2940180001858
                            ],
                            [
                                -71.5359790001948,
                                41.2861799999156
                            ],
                            [
                                -71.5163500000581,
                                41.2715489999549
                            ],
                            [
                                -71.492195999884,
                                41.2408759996691
                            ],
                            [
                                -71.4864969996867,
                                41.226926999716
                            ],
                            [
                                -71.484582999735,
                                41.2174860000326
                            ],
                            [
                                -71.4858840001166,
                                41.2047269999353
                            ],
                            [
                                -71.4898440002644,
                                41.19469799997
                            ],
                            [
                                -71.482715999639,
                                41.1862529996971
                            ],
                            [
                                -71.4786030002286,
                                41.1757919996716
                            ],
                            [
                                -71.4769250002784,
                                41.1681569996717
                            ],
                            [
                                -71.4770240000126,
                                41.1556570000309
                            ],
                            [
                                -71.4914319998029,
                                41.1251039998907
                            ],
                            [
                                -71.5080930003768,
                                41.1116149996644
                            ],
                            [
                                -71.5281679996791,
                                41.1031139996929
                            ],
                            [
                                -71.5358410001026,
                                41.1016640001612
                            ],
                            [
                                -71.5840859998032,
                                41.096341999797
                            ],
                            [
                                -71.6116729998705,
                                41.0974129997022
                            ],
                            [
                                -71.6303010000996,
                                41.1034550000648
                            ],
                            [
                                -71.6498490000454,
                                41.114143000111
                            ],
                            [
                                -71.6683450000301,
                                41.1282500000273
                            ],
                            [
                                -71.6726440001116,
                                41.134291999751
                            ]
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 41.5974187,
            "name": "Rhode Island",
            "intptlon": -71.5272723,
            "geo_point_2d": [
                41.5946933039,
                -71.524812176
            ],
            "geoid": "44",
            "mtfcc": "G4000",
            "region": 1
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -71.524812176,
                41.5946933039
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "b264e53f0d27bb6f0e2c16dcafd8a27fcff6a854",
        "fields": {
            "arealand": "312831514",
            "objectid": 20,
            "basename": "Washington",
            "stusab": "WA",
            "statens": "01779804",
            "centlon": -120.5955739,
            "state": "53",
            "gid": 54,
            "centlat": 47.4148989,
            "division": 9,
            "areawater": "-324557627",
            "oid": "-1859906639",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -123.247918999905,
                            48.2845579997562
                        ],
                        [
                            -123.115137999968,
                            48.4228299999698
                        ],
                        [
                            -123.159873999607,
                            48.453507000155
                        ],
                        [
                            -123.218929000247,
                            48.5487079997306
                        ],
                        [
                            -123.267876999929,
                            48.6939929997782
                        ],
                        [
                            -123.008486000256,
                            48.7670989998963
                        ],
                        [
                            -123.008485000431,
                            48.831218000076
                        ],
                        [
                            -123.322237999579,
                            49.0020700000235
                        ],
                        [
                            -122.098061000305,
                            49.0024110002099
                        ],
                        [
                            -121.759571999578,
                            48.997298999737
                        ],
                        [
                            -121.334821999708,
                            49.0009100001317
                        ],
                        [
                            -120.036186999945,
                            48.9994430000654
                        ],
                        [
                            -117.427707000344,
                            49.0012009997901
                        ],
                        [
                            -117.032351000362,
                            48.9991879998893
                        ],
                        [
                            -117.035178000358,
                            48.3708780001528
                        ],
                        [
                            -117.041106999811,
                            48.1249040001375
                        ],
                        [
                            -117.04230899956,
                            47.9622889998434
                        ],
                        [
                            -117.039798999757,
                            47.4543400000087
                        ],
                        [
                            -117.039766000145,
                            46.4258870000347
                        ],
                        [
                            -117.034625999989,
                            46.4178449998014
                        ],
                        [
                            -117.035399000291,
                            46.4103280002018
                        ],
                        [
                            -117.038570000243,
                            46.4054819998042
                        ],
                        [
                            -117.046893999872,
                            46.3796150000163
                        ],
                        [
                            -117.05926999971,
                            46.3699999997377
                        ],
                        [
                            -117.062777999946,
                            46.365175000012
                        ],
                        [
                            -117.062787999992,
                            46.3536049997825
                        ],
                        [
                            -117.060296999556,
                            46.348380999904
                        ],
                        [
                            -117.056646999928,
                            46.3458110001138
                        ],
                        [
                            -117.049504999958,
                            46.3430100002963
                        ],
                        [
                            -117.033483999616,
                            46.3412370001704
                        ],
                        [
                            -117.02430499967,
                            46.3362759998628
                        ],
                        [
                            -117.022831999759,
                            46.3335670001353
                        ],
                        [
                            -117.023072999784,
                            46.3198579997681
                        ],
                        [
                            -117.020581000422,
                            46.3147890001006
                        ],
                        [
                            -117.007380000404,
                            46.3052439996953
                        ],
                        [
                            -116.990849000424,
                            46.3002330001079
                        ],
                        [
                            -116.986216000432,
                            46.2959500001555
                        ],
                        [
                            -116.984803999722,
                            46.2930880001254
                        ],
                        [
                            -116.98530099984,
                            46.2891860001407
                        ],
                        [
                            -116.990866999968,
                            46.2808550000915
                        ],
                        [
                            -116.991195000031,
                            46.2769600003054
                        ],
                        [
                            -116.98655799984,
                            46.2723739999813
                        ],
                        [
                            -116.974808999816,
                            46.2654989999871
                        ],
                        [
                            -116.967804999938,
                            46.2584749997537
                        ],
                        [
                            -116.959236000087,
                            46.2431650000685
                        ],
                        [
                            -116.955480000154,
                            46.2309840000926
                        ],
                        [
                            -116.957374000014,
                            46.2235570000075
                        ],
                        [
                            -116.966306000088,
                            46.2091949999443
                        ],
                        [
                            -116.965383999823,
                            46.2031220002537
                        ],
                        [
                            -116.962299999909,
                            46.1993359996974
                        ],
                        [
                            -116.949630000344,
                            46.1916759999225
                        ],
                        [
                            -116.941429999665,
                            46.184682000248
                        ],
                        [
                            -116.939836000279,
                            46.1797429999177
                        ],
                        [
                            -116.935957000322,
                            46.1752270002795
                        ],
                        [
                            -116.932733999593,
                            46.1732429997998
                        ],
                        [
                            -116.927474999612,
                            46.173293999817
                        ],
                        [
                            -116.922341000202,
                            46.1687739997623
                        ],
                        [
                            -116.92135000034,
                            46.1645140002413
                        ],
                        [
                            -116.923157000162,
                            46.1603599999705
                        ],
                        [
                            -116.934987000377,
                            46.1535279997221
                        ],
                        [
                            -116.934728999736,
                            46.1372939996941
                        ],
                        [
                            -116.949851000277,
                            46.124485999782
                        ],
                        [
                            -116.951335000058,
                            46.1108870000834
                        ],
                        [
                            -116.955424000077,
                            46.1018610000619
                        ],
                        [
                            -116.960315999637,
                            46.0988590002038
                        ],
                        [
                            -116.978065000158,
                            46.0963129998375
                        ],
                        [
                            -116.981457000043,
                            46.0936119997983
                        ],
                        [
                            -116.98258900037,
                            46.0900750003037
                        ],
                        [
                            -116.981951999612,
                            46.0848470000068
                        ],
                        [
                            -116.978429000206,
                            46.0797250003096
                        ],
                        [
                            -116.967111000177,
                            46.0762050000464
                        ],
                        [
                            -116.957866000108,
                            46.0754570002735
                        ],
                        [
                            -116.942792999971,
                            46.0611150003014
                        ],
                        [
                            -116.942290000006,
                            46.0509739997039
                        ],
                        [
                            -116.938442000011,
                            46.0443490001596
                        ],
                        [
                            -116.931633000126,
                            46.0396280002951
                        ],
                        [
                            -116.929656000425,
                            46.0349649997925
                        ],
                        [
                            -116.929944000305,
                            46.0236479999337
                        ],
                        [
                            -116.922261999661,
                            46.0172420000897
                        ],
                        [
                            -116.918656000414,
                            46.0026210000485
                        ],
                        [
                            -116.919253000091,
                            45.9993860001753
                        ],
                        [
                            -116.916071000268,
                            45.9953570002034
                        ],
                        [
                            -117.126147999662,
                            45.9978989998391
                        ],
                        [
                            -117.337908999753,
                            45.9985749997002
                        ],
                        [
                            -117.355951000176,
                            45.9965679999581
                        ],
                        [
                            -117.405800000207,
                            45.9984830001529
                        ],
                        [
                            -117.919176000158,
                            46.0004210002613
                        ],
                        [
                            -118.725241999567,
                            46.0011570002748
                        ],
                        [
                            -118.98725799957,
                            45.9998549997806
                        ],
                        [
                            -119.008650999871,
                            45.9792230003059
                        ],
                        [
                            -119.027214999627,
                            45.9691120001559
                        ],
                        [
                            -119.061496999777,
                            45.9585420000924
                        ],
                        [
                            -119.093294000237,
                            45.942723000017
                        ],
                        [
                            -119.12632199986,
                            45.9327840002727
                        ],
                        [
                            -119.169191000302,
                            45.9277030000952
                        ],
                        [
                            -119.195519999646,
                            45.9279000002656
                        ],
                        [
                            -119.25707199967,
                            45.9398669997824
                        ],
                        [
                            -119.322573999796,
                            45.9330719999318
                        ],
                        [
                            -119.364498000407,
                            45.9215470000912
                        ],
                        [
                            -119.450397999875,
                            45.9173809997935
                        ],
                        [
                            -119.488082999781,
                            45.9063610002849
                        ],
                        [
                            -119.524519000081,
                            45.9085669998883
                        ],
                        [
                            -119.571602000216,
                            45.9254779998565
                        ],
                        [
                            -119.600543000368,
                            45.9195490000733
                        ],
                        [
                            -119.623627999964,
                            45.905601999769
                        ],
                        [
                            -119.669973999782,
                            45.8569080001653
                        ],
                        [
                            -119.772853999667,
                            45.8457270000383
                        ],
                        [
                            -119.802705000388,
                            45.8476300003082
                        ],
                        [
                            -119.849343000284,
                            45.8401799997242
                        ],
                        [
                            -119.907244999782,
                            45.8281760000308
                        ],
                        [
                            -119.965772000342,
                            45.8243759997313
                        ],
                        [
                            -120.0148370002,
                            45.8071199999526
                        ],
                        [
                            -120.070235999567,
                            45.7851559997646
                        ],
                        [
                            -120.141480000447,
                            45.7732080000238
                        ],
                        [
                            -120.170603000174,
                            45.7619799999427
                        ],
                        [
                            -120.210773999593,
                            45.725915000129
                        ],
                        [
                            -120.283431000109,
                            45.7211650000006
                        ],
                        [
                            -120.326332000338,
                            45.7114199999691
                        ],
                        [
                            -120.40365100026,
                            45.6994440001068
                        ],
                        [
                            -120.482614999622,
                            45.6944069997418
                        ],
                        [
                            -120.505742999775,
                            45.6999489998135
                        ],
                        [
                            -120.522734999886,
                            45.7102870002192
                        ],
                        [
                            -120.55928699964,
                            45.7383149999633
                        ],
                        [
                            -120.591144000374,
                            45.7465089997147
                        ],
                        [
                            -120.635050999635,
                            45.7457110002643
                        ],
                        [
                            -120.669280000081,
                            45.7298830001529
                        ],
                        [
                            -120.689535000207,
                            45.7157270002157
                        ],
                        [
                            -120.728626000076,
                            45.7053190001917
                        ],
                        [
                            -120.78857399977,
                            45.6864240002501
                        ],
                        [
                            -120.855414999912,
                            45.6717290000274
                        ],
                        [
                            -120.895822000231,
                            45.6429229999217
                        ],
                        [
                            -120.913607999663,
                            45.6399920001475
                        ],
                        [
                            -120.944018000427,
                            45.6563920000368
                        ],
                        [
                            -120.953115000357,
                            45.6567419997031
                        ],
                        [
                            -120.98349400026,
                            45.6483580000953
                        ],
                        [
                            -121.007369999701,
                            45.6531970000234
                        ],
                        [
                            -121.033822999792,
                            45.6509630001734
                        ],
                        [
                            -121.064460000336,
                            45.6524910001578
                        ],
                        [
                            -121.084862999701,
                            45.6479320000447
                        ],
                        [
                            -121.120109999773,
                            45.6231650002289
                        ],
                        [
                            -121.11717700037,
                            45.6182009999902
                        ],
                        [
                            -121.131832999858,
                            45.6097530001392
                        ],
                        [
                            -121.139385999732,
                            45.6120449997672
                        ],
                        [
                            -121.145582999872,
                            45.6079449999419
                        ],
                        [
                            -121.183683999704,
                            45.6064500002092
                        ],
                        [
                            -121.19645899975,
                            45.6166100000218
                        ],
                        [
                            -121.195184000206,
                            45.6295610002865
                        ],
                        [
                            -121.200351000126,
                            45.649838999933
                        ],
                        [
                            -121.215768000219,
                            45.6712280001876
                        ],
                        [
                            -121.287295999883,
                            45.6868670000826
                        ],
                        [
                            -121.312296000154,
                            45.6998389999491
                        ],
                        [
                            -121.337712000351,
                            45.7048390003001
                        ],
                        [
                            -121.372572999736,
                            45.7030329998166
                        ],
                        [
                            -121.401739999844,
                            45.6928930001233
                        ],
                        [
                            -121.423961999625,
                            45.694074000144
                        ],
                        [
                            -121.46279499975,
                            45.7012820001269
                        ],
                        [
                            -121.499097999982,
                            45.7207469997063
                        ],
                        [
                            -121.532934000067,
                            45.7265039996882
                        ],
                        [
                            -121.63132300025,
                            45.7046969998168
                        ],
                        [
                            -121.668405999557,
                            45.7051139997424
                        ],
                        [
                            -121.70729499976,
                            45.694835999947
                        ],
                        [
                            -121.735072999623,
                            45.6940019999024
                        ],
                        [
                            -121.811183999948,
                            45.7067149998986
                        ],
                        [
                            -121.867020999882,
                            45.6933909999427
                        ],
                        [
                            -121.901744999897,
                            45.6706690002725
                        ],
                        [
                            -121.900766999555,
                            45.6620160000705
                        ],
                        [
                            -121.908133999657,
                            45.6545580002291
                        ],
                        [
                            -121.934986999781,
                            45.6442309997731
                        ],
                        [
                            -121.951691000012,
                            45.6448360002074
                        ],
                        [
                            -121.955775000009,
                            45.643585999879
                        ],
                        [
                            -121.963080000186,
                            45.6328910000622
                        ],
                        [
                            -121.983007000249,
                            45.622888000271
                        ],
                        [
                            -122.003553999554,
                            45.6159470000901
                        ],
                        [
                            -122.021053999654,
                            45.6147799998055
                        ],
                        [
                            -122.044298999982,
                            45.6095350002307
                        ],
                        [
                            -122.101750000291,
                            45.583168999962
                        ],
                        [
                            -122.112316999759,
                            45.5813370002335
                        ],
                        [
                            -122.141612999739,
                            45.5840029997227
                        ],
                        [
                            -122.182863999607,
                            45.5767810001626
                        ],
                        [
                            -122.200791000402,
                            45.5651110002272
                        ],
                        [
                            -122.248993000445,
                            45.547745000282
                        ],
                        [
                            -122.266701000059,
                            45.5438410002664
                        ],
                        [
                            -122.296601000286,
                            45.5436409997232
                        ],
                        [
                            -122.331501999853,
                            45.5482410001735
                        ],
                        [
                            -122.352802000267,
                            45.5694409999586
                        ],
                        [
                            -122.380302000296,
                            45.5759410001222
                        ],
                        [
                            -122.3918019999,
                            45.5745410000088
                        ],
                        [
                            -122.410706000313,
                            45.5676330000615
                        ],
                        [
                            -122.438674000147,
                            45.5635849997856
                        ],
                        [
                            -122.453891000224,
                            45.5673120001749
                        ],
                        [
                            -122.47931499992,
                            45.5797610002042
                        ],
                        [
                            -122.523668000143,
                            45.5896320002253
                        ],
                        [
                            -122.550325000448,
                            45.5972690001067
                        ],
                        [
                            -122.602605999566,
                            45.6076389999293
                        ],
                        [
                            -122.643906999662,
                            45.6097389998131
                        ],
                        [
                            -122.675001000141,
                            45.6180250000668
                        ],
                        [
                            -122.691009999888,
                            45.6247389999614
                        ],
                        [
                            -122.713308999662,
                            45.6374380002609
                        ],
                        [
                            -122.738108999916,
                            45.6441380002232
                        ],
                        [
                            -122.763809999621,
                            45.6571379999313
                        ],
                        [
                            -122.774510999882,
                            45.6804370000223
                        ],
                        [
                            -122.772510999716,
                            45.6996369997144
                        ],
                        [
                            -122.760108000114,
                            45.7344130000214
                        ],
                        [
                            -122.761451000329,
                            45.7591629999581
                        ],
                        [
                            -122.769532000283,
                            45.7805829997803
                        ],
                        [
                            -122.795605000432,
                            45.8099999997518
                        ],
                        [
                            -122.795962999734,
                            45.8250239998821
                        ],
                        [
                            -122.785695999841,
                            45.8442160002868
                        ],
                        [
                            -122.78502600037,
                            45.8676989998541
                        ],
                        [
                            -122.798090999945,
                            45.8843329997
                        ],
                        [
                            -122.811500000376,
                            45.9127239999235
                        ],
                        [
                            -122.806186999968,
                            45.9324159999548
                        ],
                        [
                            -122.813999000309,
                            45.9609830001385
                        ],
                        [
                            -122.837637999924,
                            45.9808210002691
                        ],
                        [
                            -122.856158000198,
                            46.0144690000206
                        ],
                        [
                            -122.878091000274,
                            46.0312800001235
                        ],
                        [
                            -122.884478000384,
                            46.0602800000648
                        ],
                        [
                            -122.904119000217,
                            46.0837339997166
                        ],
                        [
                            -122.962681000038,
                            46.1048169998962
                        ],
                        [
                            -123.004233000205,
                            46.1338230000682
                        ],
                        [
                            -123.041297000144,
                            46.1463509999579
                        ],
                        [
                            -123.051064000445,
                            46.1535990003018
                        ],
                        [
                            -123.106594000231,
                            46.178308999846
                        ],
                        [
                            -123.115903999699,
                            46.1852680002546
                        ],
                        [
                            -123.166416000427,
                            46.1889789997242
                        ],
                        [
                            -123.213054000322,
                            46.1725409999894
                        ],
                        [
                            -123.280165999728,
                            46.1448430001193
                        ],
                        [
                            -123.371433000032,
                            46.1463719999496
                        ],
                        [
                            -123.379524000032,
                            46.1509930000893
                        ],
                        [
                            -123.390404000393,
                            46.1544280000901
                        ],
                        [
                            -123.430987000439,
                            46.1815710002401
                        ],
                        [
                            -123.42762899999,
                            46.2293480002815
                        ],
                        [
                            -123.447592000038,
                            46.2498319997927
                        ],
                        [
                            -123.474844000369,
                            46.2678309998355
                        ],
                        [
                            -123.501246000406,
                            46.2710139999217
                        ],
                        [
                            -123.526391000442,
                            46.263404000248
                        ],
                        [
                            -123.550480000319,
                            46.2587650002461
                        ],
                        [
                            -123.586954000254,
                            46.2558900000846
                        ],
                        [
                            -123.648774999892,
                            46.2588030002184
                        ],
                        [
                            -123.688115000181,
                            46.2501049997688
                        ],
                        [
                            -123.741448999984,
                            46.269154999963
                        ],
                        [
                            -123.779364999867,
                            46.2538839997202
                        ],
                        [
                            -123.815135999821,
                            46.2498269997645
                        ],
                        [
                            -123.872629999788,
                            46.2349870000523
                        ],
                        [
                            -123.930476999933,
                            46.238805000048
                        ],
                        [
                            -123.946544000305,
                            46.2498239999959
                        ],
                        [
                            -123.957618999761,
                            46.2601550000092
                        ],
                        [
                            -123.973346000374,
                            46.2685010000389
                        ],
                        [
                            -123.985991999651,
                            46.2727550001518
                        ],
                        [
                            -124.003615999599,
                            46.2747090000673
                        ],
                        [
                            -124.014875000077,
                            46.2931650002111
                        ],
                        [
                            -124.024876999655,
                            46.299098999978
                        ],
                        [
                            -124.035598999832,
                            46.2968429999735
                        ],
                        [
                            -124.038866999869,
                            46.2845600002884
                        ],
                        [
                            -124.033550999986,
                            46.2759109999348
                        ],
                        [
                            -124.035680000023,
                            46.271473999856
                        ],
                        [
                            -124.03492000014,
                            46.264835000111
                        ],
                        [
                            -124.035951000185,
                            46.2641829999265
                        ],
                        [
                            -124.159545999605,
                            46.2611320000612
                        ],
                        [
                            -124.1625519997,
                            46.271726999944
                        ],
                        [
                            -124.160713999917,
                            46.2820100002612
                        ],
                        [
                            -124.154331999829,
                            46.2952249997007
                        ],
                        [
                            -124.153494000128,
                            46.3020520001832
                        ],
                        [
                            -124.150185999908,
                            46.306379000105
                        ],
                        [
                            -124.144050999693,
                            46.3219469997286
                        ],
                        [
                            -124.14176600002,
                            46.3343320001442
                        ],
                        [
                            -124.135207000381,
                            46.3493680003077
                        ],
                        [
                            -124.130531999657,
                            46.4083159999795
                        ],
                        [
                            -124.129981999836,
                            46.4315939999838
                        ],
                        [
                            -124.133827000356,
                            46.4907130000481
                        ],
                        [
                            -124.135297999719,
                            46.5422989998119
                        ],
                        [
                            -124.141955000166,
                            46.5869589999529
                        ],
                        [
                            -124.143286999612,
                            46.631164000249
                        ],
                        [
                            -124.147739999679,
                            46.6406010000383
                        ],
                        [
                            -124.149667999875,
                            46.6500780002388
                        ],
                        [
                            -124.148820000128,
                            46.6609860000627
                        ],
                        [
                            -124.145260000013,
                            46.67047400027
                        ],
                        [
                            -124.154115000094,
                            46.6891979999088
                        ],
                        [
                            -124.157005999664,
                            46.7022450001667
                        ],
                        [
                            -124.168515000387,
                            46.7304210000278
                        ],
                        [
                            -124.171405000132,
                            46.745422000221
                        ],
                        [
                            -124.170923000083,
                            46.7584909998593
                        ],
                        [
                            -124.175130999928,
                            46.7937320002328
                        ],
                        [
                            -124.181203000394,
                            46.7937360002627
                        ],
                        [
                            -124.194580999964,
                            46.8440919999921
                        ],
                        [
                            -124.209469000154,
                            46.8748129997174
                        ],
                        [
                            -124.234393000257,
                            46.8911880003063
                        ],
                        [
                            -124.243920000357,
                            46.9089369997322
                        ],
                        [
                            -124.246641999873,
                            46.9178130000772
                        ],
                        [
                            -124.24970499987,
                            46.9562879998836
                        ],
                        [
                            -124.241539000425,
                            46.9998079999965
                        ],
                        [
                            -124.244013000244,
                            47.0188559997453
                        ],
                        [
                            -124.24413000042,
                            47.0586479998274
                        ],
                        [
                            -124.248315999625,
                            47.0708460000684
                        ],
                        [
                            -124.251290999759,
                            47.0966540001896
                        ],
                        [
                            -124.273659000028,
                            47.1481310000537
                        ],
                        [
                            -124.272492000439,
                            47.1608359999942
                        ],
                        [
                            -124.273659999853,
                            47.1650709998599
                        ],
                        [
                            -124.27950100034,
                            47.1743890000203
                        ],
                        [
                            -124.295854999869,
                            47.212505999702
                        ],
                        [
                            -124.306366999984,
                            47.2480830002356
                        ],
                        [
                            -124.336049999578,
                            47.2616050000398
                        ],
                        [
                            -124.35129900034,
                            47.2763899999632
                        ],
                        [
                            -124.359072000323,
                            47.2927850002495
                        ],
                        [
                            -124.359643999885,
                            47.3093359999341
                        ],
                        [
                            -124.391354000307,
                            47.3297659999538
                        ],
                        [
                            -124.399532000345,
                            47.3407790002732
                        ],
                        [
                            -124.413550000148,
                            47.3687300000015
                        ],
                        [
                            -124.421728000187,
                            47.3932929998619
                        ],
                        [
                            -124.421728000187,
                            47.4068450002097
                        ],
                        [
                            -124.419391999563,
                            47.4144680001446
                        ],
                        [
                            -124.406542000072,
                            47.4280209997303
                        ],
                        [
                            -124.420559999875,
                            47.4559719996968
                        ],
                        [
                            -124.421729999837,
                            47.4686769997065
                        ],
                        [
                            -124.4205609997,
                            47.4754529996974
                        ],
                        [
                            -124.417056999661,
                            47.4788410002274
                        ],
                        [
                            -124.417056999661,
                            47.4815379999466
                        ],
                        [
                            -124.440015999759,
                            47.5690390000074
                        ],
                        [
                            -124.448217000262,
                            47.5778720001962
                        ],
                        [
                            -124.453163000249,
                            47.5867329997654
                        ],
                        [
                            -124.455556999701,
                            47.5961739998756
                        ],
                        [
                            -124.455119999858,
                            47.606613000231
                        ],
                        [
                            -124.45959600039,
                            47.6157490002347
                        ],
                        [
                            -124.461402000387,
                            47.6249600000489
                        ],
                        [
                            -124.486959000152,
                            47.621198999932
                        ],
                        [
                            -124.514921999962,
                            47.6237079997693
                        ],
                        [
                            -124.537583000134,
                            47.6318549998143
                        ],
                        [
                            -124.5546260003,
                            47.6444269998021
                        ],
                        [
                            -124.561271000153,
                            47.652718999998
                        ],
                        [
                            -124.565539000273,
                            47.6618530002483
                        ],
                        [
                            -124.567238999964,
                            47.6715420002424
                        ],
                        [
                            -124.566551999876,
                            47.6802390000699
                        ],
                        [
                            -124.559405999707,
                            47.6957830002272
                        ],
                        [
                            -124.548193999983,
                            47.7095540000905
                        ],
                        [
                            -124.540302000175,
                            47.7164450001834
                        ],
                        [
                            -124.556191000272,
                            47.7290349999416
                        ],
                        [
                            -124.575904999899,
                            47.7564190000065
                        ],
                        [
                            -124.58927900017,
                            47.7635390002891
                        ],
                        [
                            -124.599053999968,
                            47.7716430002625
                        ],
                        [
                            -124.608093000172,
                            47.7848520000754
                        ],
                        [
                            -124.633211000444,
                            47.8067889999541
                        ],
                        [
                            -124.641838999846,
                            47.8194239998466
                        ],
                        [
                            -124.654062000421,
                            47.8252770002088
                        ],
                        [
                            -124.665387000122,
                            47.8336319998945
                        ],
                        [
                            -124.690585999687,
                            47.8442650000956
                        ],
                        [
                            -124.704180000065,
                            47.8547370000804
                        ],
                        [
                            -124.712156000437,
                            47.865666999948
                        ],
                        [
                            -124.71715299958,
                            47.8789999997843
                        ],
                        [
                            -124.714400999927,
                            47.8790180000333
                        ],
                        [
                            -124.720858000358,
                            47.8883029998537
                        ],
                        [
                            -124.739658999941,
                            47.8975500002876
                        ],
                        [
                            -124.753794999745,
                            47.9105230002914
                        ],
                        [
                            -124.758053999643,
                            47.9180370001282
                        ],
                        [
                            -124.7606110002,
                            47.9272850002912
                        ],
                        [
                            -124.758185000063,
                            47.943977999891
                        ],
                        [
                            -124.77686500017,
                            47.952080999937
                        ],
                        [
                            -124.791161999632,
                            47.9617049998148
                        ],
                        [
                            -124.795203999796,
                            47.9665060001025
                        ],
                        [
                            -124.800112000148,
                            47.9751919999506
                        ],
                        [
                            -124.803394000429,
                            47.9886580000365
                        ],
                        [
                            -124.800909999666,
                            48.008464999878
                        ],
                        [
                            -124.796998999922,
                            48.0179379998872
                        ],
                        [
                            -124.801154999889,
                            48.0378089998557
                        ],
                        [
                            -124.796639000072,
                            48.0494659998749
                        ],
                        [
                            -124.786102999668,
                            48.0613289999836
                        ],
                        [
                            -124.788002000449,
                            48.0759660000237
                        ],
                        [
                            -124.785187000149,
                            48.0871149999531
                        ],
                        [
                            -124.794920999939,
                            48.0969749997776
                        ],
                        [
                            -124.800340999579,
                            48.1048589998584
                        ],
                        [
                            -124.803904999891,
                            48.1142880001597
                        ],
                        [
                            -124.816258000163,
                            48.1217589999813
                        ],
                        [
                            -124.824046000214,
                            48.1293080001388
                        ],
                        [
                            -124.831594000066,
                            48.1426529998888
                        ],
                        [
                            -124.832671999966,
                            48.1477219998978
                        ],
                        [
                            -124.839182999925,
                            48.152889000254
                        ],
                        [
                            -124.843131000378,
                            48.1584290000724
                        ],
                        [
                            -124.848536999774,
                            48.1740049999085
                        ],
                        [
                            -124.848252000267,
                            48.1881999998509
                        ],
                        [
                            -124.845666000295,
                            48.1948930002976
                        ],
                        [
                            -124.827680999774,
                            48.2138299997798
                        ],
                        [
                            -124.805014999579,
                            48.2243159999163
                        ],
                        [
                            -124.780503999928,
                            48.2285800002584
                        ],
                        [
                            -124.790240000266,
                            48.246354999754
                        ],
                        [
                            -124.790860000407,
                            48.2530209997908
                        ],
                        [
                            -124.788742000241,
                            48.2663719999919
                        ],
                        [
                            -124.786405999616,
                            48.2722909999143
                        ],
                        [
                            -124.776913999676,
                            48.2835880000574
                        ],
                        [
                            -124.761386999977,
                            48.2956880001961
                        ],
                        [
                            -124.767266999925,
                            48.3051409999378
                        ],
                        [
                            -124.77123299992,
                            48.3160379999537
                        ],
                        [
                            -124.770442000075,
                            48.3235370000922
                        ],
                        [
                            -124.786135000353,
                            48.3318829997441
                        ],
                        [
                            -124.796635999699,
                            48.3441259998987
                        ],
                        [
                            -124.803770999997,
                            48.3569399997452
                        ],
                        [
                            -124.803394000429,
                            48.3629929997122
                        ],
                        [
                            -124.810103999681,
                            48.3699879999876
                        ],
                        [
                            -124.812504999704,
                            48.3747819999042
                        ],
                        [
                            -124.817911999824,
                            48.3910029997775
                        ],
                        [
                            -124.817264999918,
                            48.3983889997712
                        ],
                        [
                            -124.812506000428,
                            48.4106380002306
                        ],
                        [
                            -124.80773199997,
                            48.417553999786
                        ],
                        [
                            -124.80117499998,
                            48.4232419999059
                        ],
                        [
                            -124.788558000118,
                            48.4321650002666
                        ],
                        [
                            -124.773575000392,
                            48.438521000117
                        ],
                        [
                            -124.745837999639,
                            48.4456630000889
                        ],
                        [
                            -124.760329000168,
                            48.4554280000701
                        ],
                        [
                            -124.765279000353,
                            48.4605620002952
                        ],
                        [
                            -124.772262000315,
                            48.4738119999444
                        ],
                        [
                            -124.772084999865,
                            48.4861169998311
                        ],
                        [
                            -124.770180000134,
                            48.4919499998124
                        ],
                        [
                            -124.763030999592,
                            48.4997839998003
                        ],
                        [
                            -124.749707000448,
                            48.4997749997064
                        ],
                        [
                            -124.126323999812,
                            48.3289729998171
                        ],
                        [
                            -124.126323999812,
                            48.3302099999515
                        ],
                        [
                            -124.013809999697,
                            48.29752100027
                        ],
                        [
                            -123.67936700023,
                            48.2400220001286
                        ],
                        [
                            -123.542427999778,
                            48.2248030000379
                        ],
                        [
                            -123.247918999905,
                            48.2845579997562
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 47.4073238,
            "name": "Washington",
            "intptlon": -120.5757999,
            "geo_point_2d": [
                47.4116571169,
                -120.592673096
            ],
            "geoid": "53",
            "mtfcc": "G4000",
            "region": 4
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -120.592673096,
                47.4116571169
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "d9f5ffa19e29948c20d8a343f3137043788e0719",
        "fields": {
            "arealand": "235758874",
            "objectid": 21,
            "basename": "Louisiana",
            "stusab": "LA",
            "statens": "01629543",
            "centlon": -91.7982269,
            "state": "22",
            "gid": 55,
            "centlat": 30.902586,
            "division": 7,
            "areawater": "-2023496089",
            "oid": "273332861",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -92.0691050000679,
                            33.0081629996608
                        ],
                        [
                            -91.5001180001397,
                            33.0067840003632
                        ],
                        [
                            -91.1660730001581,
                            33.0041059999842
                        ],
                        [
                            -91.1689729999487,
                            32.9921309997866
                        ],
                        [
                            -91.1733080000153,
                            32.9860879997619
                        ],
                        [
                            -91.1869829996865,
                            32.9761940000507
                        ],
                        [
                            -91.2018419995635,
                            32.9612119997365
                        ],
                        [
                            -91.201757000073,
                            32.9564520003284
                        ],
                        [
                            -91.1983430004464,
                            32.950206000223
                        ],
                        [
                            -91.2022979996731,
                            32.9497030003207
                        ],
                        [
                            -91.2074400003768,
                            32.9443920001848
                        ],
                        [
                            -91.2128199998336,
                            32.9360760001254
                        ],
                        [
                            -91.2140269996056,
                            32.9303200002024
                        ],
                        [
                            -91.2115970001682,
                            32.919623999741
                        ],
                        [
                            -91.1997750003492,
                            32.9085120001365
                        ],
                        [
                            -91.1816309998188,
                            32.9014460000286
                        ],
                        [
                            -91.1702349999723,
                            32.8993909997969
                        ],
                        [
                            -91.1569340003948,
                            32.9004770001667
                        ],
                        [
                            -91.1473830000047,
                            32.9040909997695
                        ],
                        [
                            -91.1332689999423,
                            32.9193510003624
                        ],
                        [
                            -91.1312889998684,
                            32.9300479996808
                        ],
                        [
                            -91.1377780000862,
                            32.9544560003151
                        ],
                        [
                            -91.1366280002156,
                            32.9573489999711
                        ],
                        [
                            -91.1307209996058,
                            32.9622569999605
                        ],
                        [
                            -91.1316710003581,
                            32.9652370001578
                        ],
                        [
                            -91.138584999825,
                            32.9713519999727
                        ],
                        [
                            -91.1358149997307,
                            32.9754159999414
                        ],
                        [
                            -91.1355169998048,
                            32.9796570001441
                        ],
                        [
                            -91.1251069997973,
                            32.9846679999445
                        ],
                        [
                            -91.111756999816,
                            32.9883600001817
                        ],
                        [
                            -91.1065809996754,
                            32.9889369998169
                        ],
                        [
                            -91.0969299997261,
                            32.9864119999053
                        ],
                        [
                            -91.0868019997509,
                            32.976265999881
                        ],
                        [
                            -91.0803550002642,
                            32.9627939997983
                        ],
                        [
                            -91.0789040000948,
                            32.9518179996258
                        ],
                        [
                            -91.0830840003507,
                            32.9479090003132
                        ],
                        [
                            -91.0819129996654,
                            32.9447680001481
                        ],
                        [
                            -91.0720750001184,
                            32.9378319998577
                        ],
                        [
                            -91.0648040002761,
                            32.9264639997256
                        ],
                        [
                            -91.0644490004489,
                            32.9010629999228
                        ],
                        [
                            -91.0706020002077,
                            32.8886579999751
                        ],
                        [
                            -91.0866829999252,
                            32.8733920003441
                        ],
                        [
                            -91.1056309998214,
                            32.8583959997363
                        ],
                        [
                            -91.1160910000577,
                            32.8556410000866
                        ],
                        [
                            -91.1278860001124,
                            32.855057999947
                        ],
                        [
                            -91.1450020000728,
                            32.8428689998001
                        ],
                        [
                            -91.1521740001808,
                            32.8266729999001
                        ],
                        [
                            -91.1583360001604,
                            32.8223040003589
                        ],
                        [
                            -91.1616689995962,
                            32.8124650002023
                        ],
                        [
                            -91.1609890000789,
                            32.8015289997617
                        ],
                        [
                            -91.1643969998577,
                            32.785820999878
                        ],
                        [
                            -91.1554759996544,
                            32.7799090000446
                        ],
                        [
                            -91.1643969998577,
                            32.7634910001454
                        ],
                        [
                            -91.1656959996912,
                            32.7533990001008
                        ],
                        [
                            -91.1634200002396,
                            32.7470449998424
                        ],
                        [
                            -91.1586099997968,
                            32.743448999956
                        ],
                        [
                            -91.1502440003356,
                            32.7417850001967
                        ],
                        [
                            -91.1246449998399,
                            32.7431640001008
                        ],
                        [
                            -91.0905729997528,
                            32.7361000001387
                        ],
                        [
                            -91.0607660003105,
                            32.7274939998447
                        ],
                        [
                            -91.0544810003072,
                            32.7222589998279
                        ],
                        [
                            -91.0547490000958,
                            32.7192289999494
                        ],
                        [
                            -91.0570429999891,
                            32.7125759996523
                        ],
                        [
                            -91.0639459995853,
                            32.7029250000169
                        ],
                        [
                            -91.0688430000667,
                            32.6989200002405
                        ],
                        [
                            -91.0811450002846,
                            32.6911269996708
                        ],
                        [
                            -91.0987619996621,
                            32.6852909999605
                        ],
                        [
                            -91.1182579997294,
                            32.6740750001796
                        ],
                        [
                            -91.1277229999058,
                            32.6653430003597
                        ],
                        [
                            -91.1376380003441,
                            32.6506210002718
                        ],
                        [
                            -91.1497530000659,
                            32.6440409998112
                        ],
                        [
                            -91.1526989998874,
                            32.6407569996874
                        ],
                        [
                            -91.1537440001759,
                            32.6352269998776
                        ],
                        [
                            -91.1524860003504,
                            32.620319000149
                        ],
                        [
                            -91.1492519997505,
                            32.6104839999653
                        ],
                        [
                            -91.1427819997993,
                            32.5988990003433
                        ],
                        [
                            -91.1279120000517,
                            32.5864920003584
                        ],
                        [
                            -91.1198539996637,
                            32.5847950002894
                        ],
                        [
                            -91.1174600002114,
                            32.5860390002104
                        ],
                        [
                            -91.1156550000396,
                            32.5910029997417
                        ],
                        [
                            -91.1072350001517,
                            32.5902550001831
                        ],
                        [
                            -91.0795069996192,
                            32.6006759999795
                        ],
                        [
                            -91.049802000284,
                            32.6071849996774
                        ],
                        [
                            -91.0489029995854,
                            32.6135539997106
                        ],
                        [
                            -91.0432629998376,
                            32.6207870000802
                        ],
                        [
                            -91.0400419996565,
                            32.6335550003083
                        ],
                        [
                            -91.0371820000488,
                            32.6378610000553
                        ],
                        [
                            -91.0317609996858,
                            32.6403149996439
                        ],
                        [
                            -91.0301809996449,
                            32.6440499996438
                        ],
                        [
                            -91.0257689995855,
                            32.6465720002524
                        ],
                        [
                            -91.0157999996188,
                            32.6416440000551
                        ],
                        [
                            -91.0068199998643,
                            32.631261000247
                        ],
                        [
                            -91.0029619998237,
                            32.6225550000175
                        ],
                        [
                            -91.0035450001549,
                            32.6122710001563
                        ],
                        [
                            -91.0082450000945,
                            32.6042390002666
                        ],
                        [
                            -91.0137230003589,
                            32.5984189996991
                        ],
                        [
                            -91.0249019995721,
                            32.5909039996244
                        ],
                        [
                            -91.0309909997568,
                            32.5833470001886
                        ],
                        [
                            -91.0361700002705,
                            32.5795550003708
                        ],
                        [
                            -91.0804110003407,
                            32.5564679996386
                        ],
                        [
                            -91.0754000000565,
                            32.5467430000718
                        ],
                        [
                            -91.0577059997879,
                            32.5337770002017
                        ],
                        [
                            -91.0184690003292,
                            32.5210190002362
                        ],
                        [
                            -91.0011290000629,
                            32.5129450002574
                        ],
                        [
                            -90.9948669996258,
                            32.5086109997822
                        ],
                        [
                            -90.9887840001872,
                            32.5008410000713
                        ],
                        [
                            -90.9872019995983,
                            32.4956630000421
                        ],
                        [
                            -90.9900889998685,
                            32.4885610001881
                        ],
                        [
                            -91.000506000447,
                            32.4822389999746
                        ],
                        [
                            -91.0209060003376,
                            32.4845399998754
                        ],
                        [
                            -91.0381059999635,
                            32.490439999964
                        ],
                        [
                            -91.0509069999487,
                            32.5001379999701
                        ],
                        [
                            -91.0748440003878,
                            32.5334889997029
                        ],
                        [
                            -91.0937420000553,
                            32.5491270001484
                        ],
                        [
                            -91.0978779999303,
                            32.5447509998503
                        ],
                        [
                            -91.0979490000754,
                            32.537213999699
                        ],
                        [
                            -91.1013040001508,
                            32.5255989999095
                        ],
                        [
                            -91.1167079998258,
                            32.5001379999701
                        ],
                        [
                            -91.116008000217,
                            32.4831400001164
                        ],
                        [
                            -91.1088079996215,
                            32.4720399999805
                        ],
                        [
                            -91.0935079997036,
                            32.4574410001622
                        ],
                        [
                            -91.0755070001866,
                            32.4474399999742
                        ],
                        [
                            -91.0514070004392,
                            32.4378419999918
                        ],
                        [
                            -91.0282059995939,
                            32.4337419999948
                        ],
                        [
                            -90.9954869997669,
                            32.450885000101
                        ],
                        [
                            -90.980006999924,
                            32.4478420003482
                        ],
                        [
                            -90.9695869998706,
                            32.4394919999725
                        ],
                        [
                            -90.9664410000327,
                            32.4338739998592
                        ],
                        [
                            -90.9659869995729,
                            32.4206540001164
                        ],
                        [
                            -90.9753420001445,
                            32.4109510002335
                        ],
                        [
                            -90.9940799999785,
                            32.403861000092
                        ],
                        [
                            -90.9946859998759,
                            32.3922770003653
                        ],
                        [
                            -91.0014060000723,
                            32.3794440000031
                        ],
                        [
                            -91.0045059998795,
                            32.3647439998084
                        ],
                        [
                            -91.0001060004139,
                            32.3576950003433
                        ],
                        [
                            -90.9936249996938,
                            32.3540469998532
                        ],
                        [
                            -90.9545830002287,
                            32.3458579997243
                        ],
                        [
                            -90.9307599995924,
                            32.3442539997241
                        ],
                        [
                            -90.9123630002403,
                            32.3394540001278
                        ],
                        [
                            -90.8971789997751,
                            32.3551780000238
                        ],
                        [
                            -90.8915979995785,
                            32.3714510001697
                        ],
                        [
                            -90.8846060002938,
                            32.374082999742
                        ],
                        [
                            -90.8756209996181,
                            32.3735260002081
                        ],
                        [
                            -90.8772890004208,
                            32.3669450002188
                        ],
                        [
                            -90.8857210000042,
                            32.3508669998229
                        ],
                        [
                            -90.9007220001714,
                            32.330379000236
                        ],
                        [
                            -90.9025580003053,
                            32.3195869999908
                        ],
                        [
                            -90.9118490004045,
                            32.3079010002429
                        ],
                        [
                            -90.9222309999246,
                            32.2986389999373
                        ],
                        [
                            -90.9339909998192,
                            32.2903430003221
                        ],
                        [
                            -90.9497810001819,
                            32.2830559999017
                        ],
                        [
                            -90.9554050000362,
                            32.2862400000823
                        ],
                        [
                            -90.9641489997892,
                            32.2968720000663
                        ],
                        [
                            -90.9746020003528,
                            32.2971220003115
                        ],
                        [
                            -90.9794749996464,
                            32.29370200003
                        ],
                        [
                            -90.9840769996767,
                            32.2799529996246
                        ],
                        [
                            -90.9829850004308,
                            32.2702939996932
                        ],
                        [
                            -90.9713440003618,
                            32.257742000015
                        ],
                        [
                            -90.9694029997476,
                            32.2525199999354
                        ],
                        [
                            -90.9802899997812,
                            32.243600999755
                        ],
                        [
                            -90.9831350002186,
                            32.2313710003192
                        ],
                        [
                            -90.9833810002659,
                            32.2117669997851
                        ],
                        [
                            -90.9949439996187,
                            32.1931409997843
                        ],
                        [
                            -90.99969700016,
                            32.1911029996717
                        ],
                        [
                            -91.0032060002212,
                            32.1980499996516
                        ],
                        [
                            -91.00500600037,
                            32.21284999988
                        ],
                        [
                            -91.0029059997472,
                            32.223050000205
                        ],
                        [
                            -91.0096059998522,
                            32.226149000024
                        ],
                        [
                            -91.0215370003494,
                            32.236155999853
                        ],
                        [
                            -91.039007000312,
                            32.2423479998036
                        ],
                        [
                            -91.0465069995848,
                            32.2411490001086
                        ],
                        [
                            -91.0503069998991,
                            32.2379489999545
                        ],
                        [
                            -91.0531070001307,
                            32.2279499996284
                        ],
                        [
                            -91.0614080001929,
                            32.2186499999723
                        ],
                        [
                            -91.0711079996477,
                            32.2260500001275
                        ],
                        [
                            -91.0837079997914,
                            32.2264500000834
                        ],
                        [
                            -91.0921079995878,
                            32.2238499996821
                        ],
                        [
                            -91.1004089996501,
                            32.2178500003344
                        ],
                        [
                            -91.1085089998708,
                            32.2081499999823
                        ],
                        [
                            -91.1172700002406,
                            32.2066680003432
                        ],
                        [
                            -91.1266120003934,
                            32.2124559999162
                        ],
                        [
                            -91.1335869999596,
                            32.2134309998718
                        ],
                        [
                            -91.1580259996407,
                            32.2019560000428
                        ],
                        [
                            -91.1641709999019,
                            32.1968880001465
                        ],
                        [
                            -91.1710459999091,
                            32.1765260000104
                        ],
                        [
                            -91.1745519995972,
                            32.1549779999356
                        ],
                        [
                            -91.1717020000352,
                            32.1442499996273
                        ],
                        [
                            -91.165452000192,
                            32.1342900001037
                        ],
                        [
                            -91.1628219998398,
                            32.1326940002524
                        ],
                        [
                            -91.1444000003733,
                            32.1303900002505
                        ],
                        [
                            -91.1314029996712,
                            32.126212999642
                        ],
                        [
                            -91.1109900002601,
                            32.1246439999776
                        ],
                        [
                            -91.1011810001271,
                            32.1311349999187
                        ],
                        [
                            -91.0916559996762,
                            32.1336039996791
                        ],
                        [
                            -91.0816299998082,
                            32.1339919997103
                        ],
                        [
                            -91.0690809997181,
                            32.1314780001703
                        ],
                        [
                            -91.0615549996077,
                            32.1269070002442
                        ],
                        [
                            -91.0535549998443,
                            32.124411999922
                        ],
                        [
                            -91.0512070004227,
                            32.1441519998274
                        ],
                        [
                            -91.0485069997502,
                            32.150152000303
                        ],
                        [
                            -91.0497069998495,
                            32.15735199996
                        ],
                        [
                            -91.0557070003457,
                            32.163751999638
                        ],
                        [
                            -91.0589069997121,
                            32.1712499997478
                        ],
                        [
                            -91.0583069996624,
                            32.1809509998141
                        ],
                        [
                            -91.0418069996453,
                            32.1745510002178
                        ],
                        [
                            -91.0250070000524,
                            32.1625510001389
                        ],
                        [
                            -91.0166060004311,
                            32.160851999666
                        ],
                        [
                            -91.0029059997472,
                            32.1620520002547
                        ],
                        [
                            -91.0041059998465,
                            32.1461519999983
                        ],
                        [
                            -91.0072059996537,
                            32.1384520003273
                        ],
                        [
                            -91.0158060003649,
                            32.1267529998099
                        ],
                        [
                            -91.020005999814,
                            32.1235530001856
                        ],
                        [
                            -91.0308910001977,
                            32.1205580002406
                        ],
                        [
                            -91.0305070000582,
                            32.1081529996836
                        ],
                        [
                            -91.0352069999977,
                            32.100452999653
                        ],
                        [
                            -91.0811079995764,
                            32.0783530003156
                        ],
                        [
                            -91.0791080003093,
                            32.0506550002981
                        ],
                        [
                            -91.0809079995598,
                            32.0478550002081
                        ],
                        [
                            -91.0987080001337,
                            32.0483549998719
                        ],
                        [
                            -91.1037079996489,
                            32.0502549998741
                        ],
                        [
                            -91.1143090003505,
                            32.058254999711
                        ],
                        [
                            -91.1286090001857,
                            32.076554000054
                        ],
                        [
                            -91.1357090003238,
                            32.0806540001978
                        ],
                        [
                            -91.1426090004453,
                            32.0817529998579
                        ],
                        [
                            -91.1488099998846,
                            32.0801539997224
                        ],
                        [
                            -91.1585100002377,
                            32.0728540002759
                        ],
                        [
                            -91.161610000045,
                            32.0677539998609
                        ],
                        [
                            -91.162010000078,
                            32.0618549996296
                        ],
                        [
                            -91.1593100003039,
                            32.0563549998449
                        ],
                        [
                            -91.1538100002982,
                            32.0506550002981
                        ],
                        [
                            -91.1451100000278,
                            32.0465540001176
                        ],
                        [
                            -91.0914079999791,
                            32.0360539999101
                        ],
                        [
                            -91.0844080002985,
                            32.0314560001165
                        ],
                        [
                            -91.0799279995686,
                            32.018316000238
                        ],
                        [
                            -91.0759080000446,
                            32.0168269999373
                        ],
                        [
                            -91.0901049999477,
                            32.0001570003658
                        ],
                        [
                            -91.1028080000236,
                            31.9909570000191
                        ],
                        [
                            -91.1174090001577,
                            31.9870570003599
                        ],
                        [
                            -91.1644100002765,
                            31.9825570002782
                        ],
                        [
                            -91.1757099998636,
                            31.9747570003661
                        ],
                        [
                            -91.1848100001671,
                            31.9655569996552
                        ],
                        [
                            -91.1888099995996,
                            31.959357999833
                        ],
                        [
                            -91.1895100001067,
                            31.9463579998739
                        ],
                        [
                            -91.1932099999635,
                            31.9356580003513
                        ],
                        [
                            -91.191110000239,
                            31.9341580000315
                        ],
                        [
                            -91.1847099997097,
                            31.9350580002636
                        ],
                        [
                            -91.1838100000844,
                            31.9335580000639
                        ],
                        [
                            -91.1849099997262,
                            31.9237590001492
                        ],
                        [
                            -91.1804099998032,
                            31.9185589997159
                        ],
                        [
                            -91.1822769998992,
                            31.9158140000423
                        ],
                        [
                            -91.1910459997666,
                            31.9140390001343
                        ],
                        [
                            -91.1943099996053,
                            31.909359000298
                        ],
                        [
                            -91.2028099998592,
                            31.9079590003215
                        ],
                        [
                            -91.2088100003554,
                            31.9004590002918
                        ],
                        [
                            -91.2348990003975,
                            31.8768630003458
                        ],
                        [
                            -91.2481439998985,
                            31.8698479997501
                        ],
                        [
                            -91.2647119996877,
                            31.8653599997363
                        ],
                        [
                            -91.2677119999358,
                            31.8626599996688
                        ],
                        [
                            -91.2690119995942,
                            31.8586610000112
                        ],
                        [
                            -91.2676120003767,
                            31.8523610002194
                        ],
                        [
                            -91.2449949996874,
                            31.8321879999405
                        ],
                        [
                            -91.2466180002843,
                            31.8234280002846
                        ],
                        [
                            -91.254310999901,
                            31.8136620001397
                        ],
                        [
                            -91.2620110000886,
                            31.8093619997278
                        ],
                        [
                            -91.2692119996107,
                            31.8091620001452
                        ],
                        [
                            -91.2767119997819,
                            31.8113619999836
                        ],
                        [
                            -91.2832119998703,
                            31.8157619997128
                        ],
                        [
                            -91.2894120003831,
                            31.8286610002358
                        ],
                        [
                            -91.2893119999256,
                            31.8468609999556
                        ],
                        [
                            -91.2940129996901,
                            31.8604600002012
                        ],
                        [
                            -91.3083130004236,
                            31.8562609999415
                        ],
                        [
                            -91.3338140001117,
                            31.8532609999454
                        ],
                        [
                            -91.3384139995939,
                            31.8512610001567
                        ],
                        [
                            -91.3452140001563,
                            31.8438610001836
                        ],
                        [
                            -91.3605140000742,
                            31.7957619999759
                        ],
                        [
                            -91.3637140003388,
                            31.7825630000299
                        ],
                        [
                            -91.3656140000468,
                            31.7607629996568
                        ],
                        [
                            -91.3558140001346,
                            31.7580630001506
                        ],
                        [
                            -91.3456140001894,
                            31.758463000012
                        ],
                        [
                            -91.3044200002229,
                            31.7659639997695
                        ],
                        [
                            -91.2874199997153,
                            31.7719639996604
                        ],
                        [
                            -91.2812119997049,
                            31.7722629996466
                        ],
                        [
                            -91.2704109998851,
                            31.7707629999509
                        ],
                        [
                            -91.2625109996809,
                            31.7665629998796
                        ],
                        [
                            -91.2595110003311,
                            31.760163000378
                        ],
                        [
                            -91.2631109997305,
                            31.7547639997565
                        ],
                        [
                            -91.267410999637,
                            31.7504639996949
                        ],
                        [
                            -91.27711099999,
                            31.7448830002491
                        ],
                        [
                            -91.2952990000031,
                            31.7452670001524
                        ],
                        [
                            -91.3069119995846,
                            31.7486639999894
                        ],
                        [
                            -91.3209129996691,
                            31.750063999644
                        ],
                        [
                            -91.3519290003299,
                            31.7499560001513
                        ],
                        [
                            -91.369375000003,
                            31.7469030003345
                        ],
                        [
                            -91.3979150002973,
                            31.7093639999076
                        ],
                        [
                            -91.400114999581,
                            31.6872639998665
                        ],
                        [
                            -91.3957150001154,
                            31.6441650003465
                        ],
                        [
                            -91.3983150003304,
                            31.6262650000927
                        ],
                        [
                            -91.4010150001046,
                            31.6203650001583
                        ],
                        [
                            -91.4211160002444,
                            31.6115649997049
                        ],
                        [
                            -91.4362160001458,
                            31.612464999773
                        ],
                        [
                            -91.4638169995584,
                            31.6203650001583
                        ],
                        [
                            -91.4743179998026,
                            31.6253650003774
                        ],
                        [
                            -91.4822180000068,
                            31.6310649996639
                        ],
                        [
                            -91.4927479996649,
                            31.6442790001987
                        ],
                        [
                            -91.4961300004028,
                            31.6453100001411
                        ],
                        [
                            -91.5073100003393,
                            31.6390680002704
                        ],
                        [
                            -91.5154620004386,
                            31.6303719997033
                        ],
                        [
                            -91.517899000447,
                            31.6203750003748
                        ],
                        [
                            -91.5165670001033,
                            31.6118179997813
                        ],
                        [
                            -91.5027829997538,
                            31.5957270003121
                        ],
                        [
                            -91.4929180004426,
                            31.5895650002824
                        ],
                        [
                            -91.4832180000895,
                            31.5857659996818
                        ],
                        [
                            -91.4663170002143,
                            31.5860660001173
                        ],
                        [
                            -91.4553170002029,
                            31.588165999844
                        ],
                        [
                            -91.432615999848,
                            31.5960649997797
                        ],
                        [
                            -91.418815999605,
                            31.59656499979
                        ],
                        [
                            -91.4130150001987,
                            31.5953649996942
                        ],
                        [
                            -91.4039149998952,
                            31.5897660002987
                        ],
                        [
                            -91.4054149995702,
                            31.5764660000168
                        ],
                        [
                            -91.4079150002261,
                            31.5693659996902
                        ],
                        [
                            -91.4149149999067,
                            31.5621660003144
                        ],
                        [
                            -91.4476160001903,
                            31.5406659998282
                        ],
                        [
                            -91.4797180002492,
                            31.5303660003246
                        ],
                        [
                            -91.4896179997205,
                            31.5342660003225
                        ],
                        [
                            -91.5134460003796,
                            31.5320310000883
                        ],
                        [
                            -91.522535999739,
                            31.5220780003163
                        ],
                        [
                            -91.5228620001523,
                            31.5174929998219
                        ],
                        [
                            -91.5206970001304,
                            31.5133879999757
                        ],
                        [
                            -91.5149169997422,
                            31.5101130000398
                        ],
                        [
                            -91.518147999969,
                            31.4834830003036
                        ],
                        [
                            -91.5151300001775,
                            31.4492059996182
                        ],
                        [
                            -91.499811999818,
                            31.420100000313
                        ],
                        [
                            -91.480020000373,
                            31.4042039999604
                        ],
                        [
                            -91.4776519999615,
                            31.3979039997117
                        ],
                        [
                            -91.472066999567,
                            31.3970760003746
                        ],
                        [
                            -91.4726520004463,
                            31.3848039996926
                        ],
                        [
                            -91.4709829998187,
                            31.3762439998267
                        ],
                        [
                            -91.4748090000722,
                            31.368645999739
                        ],
                        [
                            -91.4788479998628,
                            31.3649459999286
                        ],
                        [
                            -91.504756000155,
                            31.3649799999117
                        ],
                        [
                            -91.5163670000867,
                            31.3705029998748
                        ],
                        [
                            -91.5218360001303,
                            31.375169999755
                        ],
                        [
                            -91.5323359996513,
                            31.3902750000138
                        ],
                        [
                            -91.5394580004289,
                            31.4140209998337
                        ],
                        [
                            -91.5371369998731,
                            31.4241609996609
                        ],
                        [
                            -91.5406469997592,
                            31.4307580001308
                        ],
                        [
                            -91.5433659997999,
                            31.4326080001758
                        ],
                        [
                            -91.5484649999477,
                            31.4326680003243
                        ],
                        [
                            -91.5651790002251,
                            31.423446999687
                        ],
                        [
                            -91.5700919997016,
                            31.4194870002586
                        ],
                        [
                            -91.5762649995519,
                            31.4104979997905
                        ],
                        [
                            -91.5783340002126,
                            31.3990669997916
                        ],
                        [
                            -91.5689529997018,
                            31.3776290001121
                        ],
                        [
                            -91.5625549997206,
                            31.3832190003182
                        ],
                        [
                            -91.5556799997134,
                            31.3864129998523
                        ],
                        [
                            -91.5460589999014,
                            31.3822210003383
                        ],
                        [
                            -91.5500519996612,
                            31.3753800002033
                        ],
                        [
                            -91.5510009996903,
                            31.3636490002371
                        ],
                        [
                            -91.54721100032,
                            31.3514270001288
                        ],
                        [
                            -91.5489899996542,
                            31.3486349998338
                        ],
                        [
                            -91.5482130000526,
                            31.346083999671
                        ],
                        [
                            -91.5349199999728,
                            31.3380049999928
                        ],
                        [
                            -91.5316569999589,
                            31.331800999712
                        ],
                        [
                            -91.5086600002264,
                            31.3151309999726
                        ],
                        [
                            -91.5088579996948,
                            31.2916440002852
                        ],
                        [
                            -91.5156139998762,
                            31.2782099997673
                        ],
                        [
                            -91.5185780001393,
                            31.2752830001755
                        ],
                        [
                            -91.537734000448,
                            31.2673690003605
                        ],
                        [
                            -91.5641919996628,
                            31.2616329996742
                        ],
                        [
                            -91.5877490001603,
                            31.2625629999722
                        ],
                        [
                            -91.6213579995668,
                            31.2678109997558
                        ],
                        [
                            -91.6376719998111,
                            31.2676800000189
                        ],
                        [
                            -91.6486690003479,
                            31.2622380003401
                        ],
                        [
                            -91.6540270000634,
                            31.2557530000294
                        ],
                        [
                            -91.6549069995971,
                            31.2501750001991
                        ],
                        [
                            -91.6520190004003,
                            31.242690999636
                        ],
                        [
                            -91.6443560000226,
                            31.2344140000355
                        ],
                        [
                            -91.6251190004214,
                            31.2260710003694
                        ],
                        [
                            -91.6016160003505,
                            31.2085730001881
                        ],
                        [
                            -91.5950290002234,
                            31.2019689999841
                        ],
                        [
                            -91.5889390002138,
                            31.1889590001833
                        ],
                        [
                            -91.589046000344,
                            31.1785860001559
                        ],
                        [
                            -91.5915019997206,
                            31.1731179997243
                        ],
                        [
                            -91.5997319996377,
                            31.1595920003548
                        ],
                        [
                            -91.6242170002479,
                            31.1337289999164
                        ],
                        [
                            -91.6282979998713,
                            31.1246089998433
                        ],
                        [
                            -91.6268619997705,
                            31.1187710001219
                        ],
                        [
                            -91.6132080000157,
                            31.1035209999833
                        ],
                        [
                            -91.5946929997645,
                            31.0914439997242
                        ],
                        [
                            -91.56414999983,
                            31.0668299999549
                        ],
                        [
                            -91.559906999825,
                            31.0541190000491
                        ],
                        [
                            -91.5643969997022,
                            31.0389649997887
                        ],
                        [
                            -91.5784129998553,
                            31.0240300000833
                        ],
                        [
                            -91.5904630001782,
                            31.0172700000731
                        ],
                        [
                            -91.6251179996981,
                            31.0053740003817
                        ],
                        [
                            -91.6369420000652,
                            30.9994160000014
                        ],
                        [
                            -90.4518209996018,
                            30.9995389999717
                        ],
                        [
                            -89.8158310000475,
                            31.0020769999279
                        ],
                        [
                            -89.752642000081,
                            31.0018529996468
                        ],
                        [
                            -89.7504810002572,
                            30.9994200002096
                        ],
                        [
                            -89.7409950001643,
                            31.0035929998356
                        ],
                        [
                            -89.7360669997208,
                            31.001226999873
                        ],
                        [
                            -89.7355010000064,
                            31.0033730000785
                        ],
                        [
                            -89.731673000103,
                            31.0048169997567
                        ],
                        [
                            -89.728126000407,
                            31.0009559999619
                        ],
                        [
                            -89.7355400003644,
                            30.9997150002729
                        ],
                        [
                            -89.7348180001161,
                            30.9965040003245
                        ],
                        [
                            -89.7276979998865,
                            30.9933289996275
                        ],
                        [
                            -89.7306210001418,
                            30.9865089997177
                        ],
                        [
                            -89.7305009995928,
                            30.9797070001979
                        ],
                        [
                            -89.7371489998193,
                            30.9760810003223
                        ],
                        [
                            -89.7283819996017,
                            30.9711409996268
                        ],
                        [
                            -89.7270719998976,
                            30.9673949999311
                        ],
                        [
                            -89.7356859999543,
                            30.966573000055
                        ],
                        [
                            -89.7431340002469,
                            30.959993000186
                        ],
                        [
                            -89.7433009997531,
                            30.9556689997137
                        ],
                        [
                            -89.7511959999345,
                            30.9514390001529
                        ],
                        [
                            -89.7565129996421,
                            30.9428609999653
                        ],
                        [
                            -89.7558349997747,
                            30.9395430003391
                        ],
                        [
                            -89.7488970000183,
                            30.9338880000845
                        ],
                        [
                            -89.7528119999603,
                            30.9254130001157
                        ],
                        [
                            -89.7507409996498,
                            30.923246000078
                        ],
                        [
                            -89.7451610001765,
                            30.9224160003512
                        ],
                        [
                            -89.7447889997325,
                            30.9189329996339
                        ],
                        [
                            -89.7500729998281,
                            30.9129299999916
                        ],
                        [
                            -89.7540859996794,
                            30.9127999998438
                        ],
                        [
                            -89.7594030002854,
                            30.9151340003142
                        ],
                        [
                            -89.7642019999591,
                            30.9119060001146
                        ],
                        [
                            -89.763622000001,
                            30.9077319997772
                        ],
                        [
                            -89.7598030003185,
                            30.9062160000401
                        ],
                        [
                            -89.7566709998259,
                            30.9010690000231
                        ],
                        [
                            -89.7587189996719,
                            30.8973190003141
                        ],
                        [
                            -89.763693999971,
                            30.896667000146
                        ],
                        [
                            -89.7709310003764,
                            30.8994319998308
                        ],
                        [
                            -89.7734100002176,
                            30.8976929999577
                        ],
                        [
                            -89.7717980003897,
                            30.8922580002991
                        ],
                        [
                            -89.7735549999826,
                            30.8863689999212
                        ],
                        [
                            -89.7695839999641,
                            30.8811479997679
                        ],
                        [
                            -89.7711399997155,
                            30.8792219999035
                        ],
                        [
                            -89.7771100000745,
                            30.8810880002027
                        ],
                        [
                            -89.7792469996089,
                            30.8760069999587
                        ],
                        [
                            -89.7780049996769,
                            30.8734110001535
                        ],
                        [
                            -89.7677889998381,
                            30.8655769997049
                        ],
                        [
                            -89.7725819996641,
                            30.8596400001157
                        ],
                        [
                            -89.7717220002218,
                            30.8546769998061
                        ],
                        [
                            -89.7747390001883,
                            30.8532540001869
                        ],
                        [
                            -89.7799100003061,
                            30.8562839999496
                        ],
                        [
                            -89.7833840002071,
                            30.856022000282
                        ],
                        [
                            -89.7844160000769,
                            30.8537440000502
                        ],
                        [
                            -89.7803220000347,
                            30.8469930002205
                        ],
                        [
                            -89.7816340002869,
                            30.845273000123
                        ],
                        [
                            -89.7842419999996,
                            30.845811000102
                        ],
                        [
                            -89.7858089996216,
                            30.8490210002885
                        ],
                        [
                            -89.7895090003768,
                            30.8513820001932
                        ],
                        [
                            -89.7924700002669,
                            30.8503859997167
                        ],
                        [
                            -89.7874999999906,
                            30.8441120003451
                        ],
                        [
                            -89.7907489997607,
                            30.8317680002594
                        ],
                        [
                            -89.7880570003825,
                            30.8303749997214
                        ],
                        [
                            -89.7823499997892,
                            30.8325519997813
                        ],
                        [
                            -89.7829530002119,
                            30.8291450000478
                        ],
                        [
                            -89.7876409995576,
                            30.8234740001219
                        ],
                        [
                            -89.7863819999071,
                            30.821240000358
                        ],
                        [
                            -89.7815310003548,
                            30.8208489997528
                        ],
                        [
                            -89.7815020000425,
                            30.8189449998487
                        ],
                        [
                            -89.7862110002028,
                            30.8159859999861
                        ],
                        [
                            -89.7917449996455,
                            30.8203869999828
                        ],
                        [
                            -89.7953270003998,
                            30.817226999782
                        ],
                        [
                            -89.7957070003414,
                            30.8210889996678
                        ],
                        [
                            -89.7986539999878,
                            30.8208550000858
                        ],
                        [
                            -89.7999899996312,
                            30.8161959996805
                        ],
                        [
                            -89.7971559999627,
                            30.8117529996303
                        ],
                        [
                            -89.8040500002364,
                            30.8074440003602
                        ],
                        [
                            -89.8046319998444,
                            30.8025110002152
                        ],
                        [
                            -89.8103079995772,
                            30.8001800000889
                        ],
                        [
                            -89.8145849999175,
                            30.8041049999548
                        ],
                        [
                            -89.815738000161,
                            30.8033529998219
                        ],
                        [
                            -89.8081440002786,
                            30.7964299997528
                        ],
                        [
                            -89.8084470002273,
                            30.7918369999107
                        ],
                        [
                            -89.8057290000115,
                            30.7919369998567
                        ],
                        [
                            -89.8035439998982,
                            30.7945550002831
                        ],
                        [
                            -89.8034660000803,
                            30.7911410001216
                        ],
                        [
                            -89.8115699996007,
                            30.7874909997987
                        ],
                        [
                            -89.813070000174,
                            30.7892319996694
                        ],
                        [
                            -89.8128900002489,
                            30.7936490002085
                        ],
                        [
                            -89.8164179996783,
                            30.7960540001045
                        ],
                        [
                            -89.8191540003358,
                            30.7952329996541
                        ],
                        [
                            -89.821485000039,
                            30.791159999848
                        ],
                        [
                            -89.8190239997411,
                            30.7843709999214
                        ],
                        [
                            -89.820086999573,
                            30.7823909999486
                        ],
                        [
                            -89.8243840000048,
                            30.7796200002697
                        ],
                        [
                            -89.8273879995525,
                            30.7833400000045
                        ],
                        [
                            -89.8301130003394,
                            30.7813630002722
                        ],
                        [
                            -89.8274589996976,
                            30.7734669998457
                        ],
                        [
                            -89.8287559998813,
                            30.7687360002899
                        ],
                        [
                            -89.8315369998463,
                            30.7676100000788
                        ],
                        [
                            -89.8331620000929,
                            30.7716760001408
                        ],
                        [
                            -89.8351239997252,
                            30.7710920002848
                        ],
                        [
                            -89.8298730001398,
                            30.7609460002758
                        ],
                        [
                            -89.8256359999825,
                            30.7583849999256
                        ],
                        [
                            -89.8272520000084,
                            30.7559049999716
                        ],
                        [
                            -89.832649000082,
                            30.755993999646
                        ],
                        [
                            -89.8331670001158,
                            30.7533140002729
                        ],
                        [
                            -89.8293490002582,
                            30.7513179998181
                        ],
                        [
                            -89.8242269996459,
                            30.7551579997478
                        ],
                        [
                            -89.8224590001824,
                            30.7546280000955
                        ],
                        [
                            -89.8257740000748,
                            30.7473049996214
                        ],
                        [
                            -89.8251410004132,
                            30.7418540001086
                        ],
                        [
                            -89.8286920003072,
                            30.7380890002089
                        ],
                        [
                            -89.8264589996149,
                            30.7373620002147
                        ],
                        [
                            -89.8202899999626,
                            30.7406939998715
                        ],
                        [
                            -89.8160750004449,
                            30.7389079997822
                        ],
                        [
                            -89.8192029998413,
                            30.7368929999691
                        ],
                        [
                            -89.8329319999392,
                            30.7371020003029
                        ],
                        [
                            -89.8366979999183,
                            30.7350630002054
                        ],
                        [
                            -89.834508999607,
                            30.7322649999796
                        ],
                        [
                            -89.8285840003521,
                            30.733215000019
                        ],
                        [
                            -89.8277189999886,
                            30.7321280001579
                        ],
                        [
                            -89.8291890004246,
                            30.7305050002851
                        ],
                        [
                            -89.8358769999357,
                            30.7296340001256
                        ],
                        [
                            -89.8363310003955,
                            30.7271969996579
                        ],
                        [
                            -89.8270439995959,
                            30.7287790003251
                        ],
                        [
                            -89.8256340003327,
                            30.7273740002396
                        ],
                        [
                            -89.8312720004307,
                            30.7225879998582
                        ],
                        [
                            -89.830007999859,
                            30.7168180001499
                        ],
                        [
                            -89.8309810001776,
                            30.7155289997022
                        ],
                        [
                            -89.8370720000121,
                            30.7166019998566
                        ],
                        [
                            -89.8394339996774,
                            30.7206899996153
                        ],
                        [
                            -89.8425290003601,
                            30.7204180002214
                        ],
                        [
                            -89.8401109997199,
                            30.7118180002922
                        ],
                        [
                            -89.8466550001893,
                            30.7057260002767
                        ],
                        [
                            -89.8436049997125,
                            30.7025109998304
                        ],
                        [
                            -89.8380650004221,
                            30.7040360002426
                        ],
                        [
                            -89.8358529996463,
                            30.6990720002435
                        ],
                        [
                            -89.8383239999899,
                            30.6951209996842
                        ],
                        [
                            -89.8352939996045,
                            30.6917930000151
                        ],
                        [
                            -89.8367969996525,
                            30.6905729996324
                        ],
                        [
                            -89.845019000072,
                            30.6906250000444
                        ],
                        [
                            -89.8448869998275,
                            30.6870309999736
                        ],
                        [
                            -89.8373559996942,
                            30.6877980002574
                        ],
                        [
                            -89.8422649998711,
                            30.680284000072
                        ],
                        [
                            -89.8406499996702,
                            30.6789090000913
                        ],
                        [
                            -89.8376839997573,
                            30.6793680000152
                        ],
                        [
                            -89.8371220002408,
                            30.677905000024
                        ],
                        [
                            -89.8421269997789,
                            30.6764559999137
                        ],
                        [
                            -89.8472009998123,
                            30.6700379998019
                        ],
                        [
                            -89.8432180000982,
                            30.6689819997985
                        ],
                        [
                            -89.8395919998612,
                            30.673956000368
                        ],
                        [
                            -89.8378350002683,
                            30.6719169996491
                        ],
                        [
                            -89.8432459996873,
                            30.6637359996974
                        ],
                        [
                            -89.8464200000127,
                            30.6631829999611
                        ],
                        [
                            -89.8493189999785,
                            30.6654009999882
                        ],
                        [
                            -89.8522029998756,
                            30.6616840001023
                        ],
                        [
                            -89.8460839995537,
                            30.6599860001135
                        ],
                        [
                            -89.840843000014,
                            30.6608159996566
                        ],
                        [
                            -89.8407209998153,
                            30.6521700001612
                        ],
                        [
                            -89.8383480002793,
                            30.6529359998235
                        ],
                        [
                            -89.8359980003095,
                            30.6572810002604
                        ],
                        [
                            -89.8332059995756,
                            30.6575049999914
                        ],
                        [
                            -89.8303140001808,
                            30.6507659998365
                        ],
                        [
                            -89.8247070000449,
                            30.6490259997181
                        ],
                        [
                            -89.8257439999376,
                            30.645273000377
                        ],
                        [
                            -89.8206549998356,
                            30.6460930003688
                        ],
                        [
                            -89.8235470001287,
                            30.642588999882
                        ],
                        [
                            -89.8229520001019,
                            30.6390779997668
                        ],
                        [
                            -89.8140019995863,
                            30.6382460003498
                        ],
                        [
                            -89.8140939996478,
                            30.6364250002773
                        ],
                        [
                            -89.8200680002048,
                            30.6328079998137
                        ],
                        [
                            -89.8202049995738,
                            30.629124999633
                        ],
                        [
                            -89.8236690003274,
                            30.6222199998834
                        ],
                        [
                            -89.8301839995861,
                            30.6220810001764
                        ],
                        [
                            -89.8307109998408,
                            30.6172210001565
                        ],
                        [
                            -89.8214259995894,
                            30.6197939996577
                        ],
                        [
                            -89.8232569997004,
                            30.6077109998819
                        ],
                        [
                            -89.8139189997456,
                            30.6077170003467
                        ],
                        [
                            -89.8169469995828,
                            30.6051290003864
                        ],
                        [
                            -89.8225629999395,
                            30.6052429998234
                        ],
                        [
                            -89.8140409999444,
                            30.5985870003338
                        ],
                        [
                            -89.8144300001068,
                            30.5965040003222
                        ],
                        [
                            -89.8198010002411,
                            30.5967839998886
                        ],
                        [
                            -89.8187710000211,
                            30.5928800002028
                        ],
                        [
                            -89.8123470001007,
                            30.5921659999413
                        ],
                        [
                            -89.8114309996836,
                            30.586641000317
                        ],
                        [
                            -89.8070219999971,
                            30.587098999788
                        ],
                        [
                            -89.8101419998959,
                            30.5845199998442
                        ],
                        [
                            -89.8156960003283,
                            30.5855399998623
                        ],
                        [
                            -89.8130790003948,
                            30.5817730002654
                        ],
                        [
                            -89.8093410000048,
                            30.5800430000794
                        ],
                        [
                            -89.8068610003387,
                            30.5724519997008
                        ],
                        [
                            -89.8079519997597,
                            30.5676529998994
                        ],
                        [
                            -89.7953259996766,
                            30.5700949999732
                        ],
                        [
                            -89.7935709997336,
                            30.5676870003408
                        ],
                        [
                            -89.7955929996403,
                            30.5653200003429
                        ],
                        [
                            -89.79301400024,
                            30.5641469998849
                        ],
                        [
                            -89.791435000024,
                            30.5654640001338
                        ],
                        [
                            -89.7912299999846,
                            30.5699469997734
                        ],
                        [
                            -89.7868649997808,
                            30.5690530002554
                        ],
                        [
                            -89.791655999957,
                            30.562611999794
                        ],
                        [
                            -89.7967140000968,
                            30.5615190001317
                        ],
                        [
                            -89.8016659999315,
                            30.563738999798
                        ],
                        [
                            -89.8039770004415,
                            30.5605219998458
                        ],
                        [
                            -89.8030309998872,
                            30.5578929999475
                        ],
                        [
                            -89.7944560001886,
                            30.5565519999903
                        ],
                        [
                            -89.7917780001557,
                            30.5519879997174
                        ],
                        [
                            -89.792204999953,
                            30.5483450003385
                        ],
                        [
                            -89.7967219995945,
                            30.5494840001021
                        ],
                        [
                            -89.7996289999561,
                            30.5480339999415
                        ],
                        [
                            -89.8029859996813,
                            30.5509469999914
                        ],
                        [
                            -89.8039189998169,
                            30.5493490001212
                        ],
                        [
                            -89.789680000081,
                            30.5446560002048
                        ],
                        [
                            -89.7791589997454,
                            30.5440290001209
                        ],
                        [
                            -89.7771600003032,
                            30.5395159998554
                        ],
                        [
                            -89.774084999712,
                            30.5377919998797
                        ],
                        [
                            -89.7773660001675,
                            30.5329950001036
                        ],
                        [
                            -89.7690500000367,
                            30.5291779997394
                        ],
                        [
                            -89.7716820000388,
                            30.5263759997805
                        ],
                        [
                            -89.7694390001991,
                            30.5250510003573
                        ],
                        [
                            -89.7709270001785,
                            30.5237170002967
                        ],
                        [
                            -89.7701949998844,
                            30.5214819999411
                        ],
                        [
                            -89.7730939998501,
                            30.5207900001774
                        ],
                        [
                            -89.7687980001416,
                            30.5183730000865
                        ],
                        [
                            -89.7678139999524,
                            30.513185000329
                        ],
                        [
                            -89.7638160001698,
                            30.5156590002957
                        ],
                        [
                            -89.7603070001086,
                            30.5155560001242
                        ],
                        [
                            -89.7598800003113,
                            30.5176309998489
                        ],
                        [
                            -89.7574590001965,
                            30.5185990003518
                        ],
                        [
                            -89.7587730000985,
                            30.5129069999571
                        ],
                        [
                            -89.7606729998065,
                            30.5120199999841
                        ],
                        [
                            -89.7571329997832,
                            30.5093510000487
                        ],
                        [
                            -89.7544509995524,
                            30.509773000101
                        ],
                        [
                            -89.7585599996632,
                            30.5059960003045
                        ],
                        [
                            -89.7574990003794,
                            30.5050159999917
                        ],
                        [
                            -89.7528069999375,
                            30.5076959999297
                        ],
                        [
                            -89.7540129998845,
                            30.5041140002447
                        ],
                        [
                            -89.752638999708,
                            30.5024329999591
                        ],
                        [
                            -89.7572320004157,
                            30.5016760002147
                        ],
                        [
                            -89.7574840003108,
                            30.4995529998206
                        ],
                        [
                            -89.74956499984,
                            30.5023769998362
                        ],
                        [
                            -89.7473749997038,
                            30.5014550001444
                        ],
                        [
                            -89.7466960000115,
                            30.502950000344
                        ],
                        [
                            -89.7455250002245,
                            30.4982359997099
                        ],
                        [
                            -89.7431009997366,
                            30.498923999636
                        ],
                        [
                            -89.7421480004079,
                            30.4973140002412
                        ],
                        [
                            -89.7429459999259,
                            30.4951430000462
                        ],
                        [
                            -89.7473300003963,
                            30.4927219998273
                        ],
                        [
                            -89.7448990002357,
                            30.4923380002541
                        ],
                        [
                            -89.7394369998648,
                            30.495792999837
                        ],
                        [
                            -89.7346189999244,
                            30.4945969999001
                        ],
                        [
                            -89.7331019996328,
                            30.4960039997071
                        ],
                        [
                            -89.7334940001683,
                            30.4928110003265
                        ],
                        [
                            -89.7298269999234,
                            30.4919189999665
                        ],
                        [
                            -89.7296520000212,
                            30.4900210002709
                        ],
                        [
                            -89.7272030003172,
                            30.4905130000393
                        ],
                        [
                            -89.7264929997644,
                            30.4926490001139
                        ],
                        [
                            -89.7248220003853,
                            30.4921289996157
                        ],
                        [
                            -89.7208320001002,
                            30.4870159997685
                        ],
                        [
                            -89.72357899973,
                            30.482302999877
                        ],
                        [
                            -89.7220299996513,
                            30.4814349997775
                        ],
                        [
                            -89.7192000001808,
                            30.4830680002358
                        ],
                        [
                            -89.7155449996315,
                            30.4776849997797
                        ],
                        [
                            -89.7099070004318,
                            30.4782159996993
                        ],
                        [
                            -89.7081900001237,
                            30.4759360003758
                        ],
                        [
                            -89.7087630004091,
                            30.4732719998332
                        ],
                        [
                            -89.7050130003235,
                            30.4708669996497
                        ],
                        [
                            -89.7042919999001,
                            30.4668589998768
                        ],
                        [
                            -89.7058479996515,
                            30.4649519997339
                        ],
                        [
                            -89.703567000177,
                            30.464093999898
                        ],
                        [
                            -89.7013850004367,
                            30.4653640003767
                        ],
                        [
                            -89.6952839996581,
                            30.4628700001117
                        ],
                        [
                            -89.6900349997226,
                            30.4593330003607
                        ],
                        [
                            -89.6900780002786,
                            30.4532350003417
                        ],
                        [
                            -89.6863399998886,
                            30.4547509996137
                        ],
                        [
                            -89.683403000288,
                            30.4515360000978
                        ],
                        [
                            -89.6850119997428,
                            30.4466109997626
                        ],
                        [
                            -89.6826400000317,
                            30.4455280002279
                        ],
                        [
                            -89.6826009996737,
                            30.4426930000697
                        ],
                        [
                            -89.6850350002073,
                            30.4394509996536
                        ],
                        [
                            -89.6839820004212,
                            30.433940000305
                        ],
                        [
                            -89.6803129996282,
                            30.433373999831
                        ],
                        [
                            -89.6808919997614,
                            30.4272990000991
                        ],
                        [
                            -89.678863000182,
                            30.4261560000081
                        ],
                        [
                            -89.6810449999223,
                            30.4233910002307
                        ],
                        [
                            -89.6797939997694,
                            30.4186740003713
                        ],
                        [
                            -89.6774820003328,
                            30.4179609997698
                        ],
                        [
                            -89.6794730002773,
                            30.4167970003755
                        ],
                        [
                            -89.6785500001875,
                            30.4137949998286
                        ],
                        [
                            -89.6799920001361,
                            30.4116489998513
                        ],
                        [
                            -89.6823119999687,
                            30.4131430002773
                        ],
                        [
                            -89.6844550002492,
                            30.4122879996493
                        ],
                        [
                            -89.682661999773,
                            30.410387999933
                        ],
                        [
                            -89.6841730002169,
                            30.4086850000346
                        ],
                        [
                            -89.6838369997579,
                            30.4058200001423
                        ],
                        [
                            -89.6791450002143,
                            30.402364000006
                        ],
                        [
                            -89.6766810004417,
                            30.3938689999962
                        ],
                        [
                            -89.6737890001486,
                            30.3928579997595
                        ],
                        [
                            -89.6700590001545,
                            30.3822020002075
                        ],
                        [
                            -89.6641759998342,
                            30.3791689996134
                        ],
                        [
                            -89.6661979997409,
                            30.3772409999832
                        ],
                        [
                            -89.6634140003011,
                            30.3764990003113
                        ],
                        [
                            -89.6645809998901,
                            30.374883000227
                        ],
                        [
                            -89.6601789998764,
                            30.3734180001475
                        ],
                        [
                            -89.6626119996868,
                            30.3714400003762
                        ],
                        [
                            -89.6587980000272,
                            30.369583000287
                        ],
                        [
                            -89.6585379997362,
                            30.3672310000974
                        ],
                        [
                            -89.6611250004307,
                            30.3642249996457
                        ],
                        [
                            -89.6587749995626,
                            30.3633510002165
                        ],
                        [
                            -89.6572489999485,
                            30.3566320003348
                        ],
                        [
                            -89.6548460002752,
                            30.3565750002126
                        ],
                        [
                            -89.6532589996634,
                            30.3542900000173
                        ],
                        [
                            -89.651518999789,
                            30.3561299998441
                        ],
                        [
                            -89.6460260003543,
                            30.3520429997353
                        ],
                        [
                            -89.6452019999987,
                            30.3481249999879
                        ],
                        [
                            -89.6417540000369,
                            30.348851999814
                        ],
                        [
                            -89.6412960002775,
                            30.3461549999941
                        ],
                        [
                            -89.6369319998986,
                            30.3476179998397
                        ],
                        [
                            -89.636299000237,
                            30.3439730001063
                        ],
                        [
                            -89.6331399999802,
                            30.3402800002408
                        ],
                        [
                            -89.629591999561,
                            30.3389599998347
                        ],
                        [
                            -89.6326980001143,
                            30.3279600001028
                        ],
                        [
                            -89.6288140001345,
                            30.3264230000333
                        ],
                        [
                            -89.6305919996437,
                            30.324797999962
                        ],
                        [
                            -89.6295999999569,
                            30.3215630002991
                        ],
                        [
                            -89.6339640003358,
                            30.321300000185
                        ],
                        [
                            -89.62618999963,
                            30.3142910002009
                        ],
                        [
                            -89.6315990002974,
                            30.3093789998127
                        ],
                        [
                            -89.6340709995676,
                            30.3099949999222
                        ],
                        [
                            -89.6341619998042,
                            30.3083049996312
                        ],
                        [
                            -89.6403420002255,
                            30.3067810003847
                        ],
                        [
                            -89.6416469999068,
                            30.3038249996803
                        ],
                        [
                            -89.640434000287,
                            30.29550299985
                        ],
                        [
                            -89.6469650003376,
                            30.291810000062
                        ],
                        [
                            -89.6471779998746,
                            30.2893669996446
                        ],
                        [
                            -89.6433709998876,
                            30.2877190000359
                        ],
                        [
                            -89.6385040004418,
                            30.2881220002015
                        ],
                        [
                            -89.6375269999253,
                            30.2850829997798
                        ],
                        [
                            -89.6312939998006,
                            30.2797689996372
                        ],
                        [
                            -89.630400999848,
                            30.2766790002273
                        ],
                        [
                            -89.6323159996246,
                            30.2686039997796
                        ],
                        [
                            -89.6258919997042,
                            30.2659129997547
                        ],
                        [
                            -89.6253050000733,
                            30.263531999933
                        ],
                        [
                            -89.6310500003015,
                            30.2617960003625
                        ],
                        [
                            -89.6316140003661,
                            30.2570130000945
                        ],
                        [
                            -89.6267539996946,
                            30.2518710000985
                        ],
                        [
                            -89.6160340000657,
                            30.2475709996268
                        ],
                        [
                            -89.614043999946,
                            30.2447729998233
                        ],
                        [
                            -89.6169279998432,
                            30.2276720001668
                        ],
                        [
                            -89.6157369999647,
                            30.2233709999322
                        ],
                        [
                            -89.6110450004211,
                            30.2185699997337
                        ],
                        [
                            -89.601165000143,
                            30.216268000308
                        ],
                        [
                            -89.5965720003335,
                            30.2119650000404
                        ],
                        [
                            -89.5887829995591,
                            30.2004519998741
                        ],
                        [
                            -89.5856929997976,
                            30.1922409996474
                        ],
                        [
                            -89.5806959997571,
                            30.1863319998273
                        ],
                        [
                            -89.5752019995992,
                            30.1822239997618
                        ],
                        [
                            -89.5692129998719,
                            30.1805190000087
                        ],
                        [
                            -89.5546259999815,
                            30.1859109997859
                        ],
                        [
                            -89.54483699994,
                            30.1942990002995
                        ],
                        [
                            -89.5348350003614,
                            30.1953830002354
                        ],
                        [
                            -89.5279390004379,
                            30.1887700003633
                        ],
                        [
                            -89.5147700002066,
                            30.1617620003276
                        ],
                        [
                            -89.4949729998404,
                            30.1607500003257
                        ],
                        [
                            -89.4539539997762,
                            30.1662280000271
                        ],
                        [
                            -89.4167109997368,
                            30.1776389999761
                        ],
                        [
                            -89.3061349998621,
                            30.1779409996475
                        ],
                        [
                            -89.2883939997378,
                            30.1784699998626
                        ],
                        [
                            -89.2609770004482,
                            30.1819980001278
                        ],
                        [
                            -89.1836690003965,
                            30.2121550000719
                        ],
                        [
                            -89.0964760000438,
                            30.1658289999843
                        ],
                        [
                            -88.8549129996351,
                            30.1355320000774
                        ],
                        [
                            -88.8343470000632,
                            30.0907079997663
                        ],
                        [
                            -88.820920999914,
                            30.0792390000229
                        ],
                        [
                            -88.8085209997867,
                            30.0654780002288
                        ],
                        [
                            -88.783666000179,
                            30.0266849998475
                        ],
                        [
                            -88.7672769995916,
                            29.9820730001733
                        ],
                        [
                            -88.7601510004126,
                            29.9445529996285
                        ],
                        [
                            -88.7583880000736,
                            29.9182710000077
                        ],
                        [
                            -88.7627230001402,
                            29.8669880003181
                        ],
                        [
                            -88.7672549998503,
                            29.8407709998355
                        ],
                        [
                            -88.7897450003179,
                            29.7774140000638
                        ],
                        [
                            -88.8047349997161,
                            29.7447539998983
                        ],
                        [
                            -88.8234299998924,
                            29.7149919997771
                        ],
                        [
                            -88.8699370002668,
                            29.6570989996679
                        ],
                        [
                            -88.9126569998474,
                            29.6117800001653
                        ],
                        [
                            -88.9443549996752,
                            29.5862949997669
                        ],
                        [
                            -88.9602039995891,
                            29.5771040002507
                        ],
                        [
                            -88.9869069999246,
                            29.5488070002127
                        ],
                        [
                            -89.0000379996227,
                            29.5324800000032
                        ],
                        [
                            -89.0173560001477,
                            29.5190330002154
                        ],
                        [
                            -89.0388740001218,
                            29.4922290003404
                        ],
                        [
                            -89.0528590003127,
                            29.4800010000017
                        ],
                        [
                            -89.0722950001056,
                            29.4708380001383
                        ],
                        [
                            -89.1194510000351,
                            29.4580650000622
                        ],
                        [
                            -89.1255050000597,
                            29.4475140003317
                        ],
                        [
                            -89.1420729998489,
                            29.4294649997147
                        ],
                        [
                            -89.137612000284,
                            29.3814290003749
                        ],
                        [
                            -89.1291959996957,
                            29.375214999695
                        ],
                        [
                            -89.1216200002549,
                            29.3664980000353
                        ],
                        [
                            -89.1137230004237,
                            29.3494789999501
                        ],
                        [
                            -89.1121590002764,
                            29.3396720000264
                        ],
                        [
                            -89.094527999757,
                            29.3289320002495
                        ],
                        [
                            -89.0853189996736,
                            29.3186089996943
                        ],
                        [
                            -89.0772279996736,
                            29.3011049999597
                        ],
                        [
                            -89.069426000277,
                            29.2942970003384
                        ],
                        [
                            -89.022615000129,
                            29.274463000229
                        ],
                        [
                            -89.0000450001937,
                            29.2714839997644
                        ],
                        [
                            -88.9824200004204,
                            29.2632140001179
                        ],
                        [
                            -88.9674950004212,
                            29.262621999935
                        ],
                        [
                            -88.9420540001092,
                            29.2537419997541
                        ],
                        [
                            -88.9250939997845,
                            29.2433809997057
                        ],
                        [
                            -88.918310000014,
                            29.2362759999069
                        ],
                        [
                            -88.9101689997853,
                            29.2202910000206
                        ],
                        [
                            -88.9088120002256,
                            29.2102260002188
                        ],
                        [
                            -88.9091510001593,
                            29.2001609997915
                        ],
                        [
                            -88.9128819999783,
                            29.1874319999551
                        ],
                        [
                            -88.9176480000401,
                            29.1794400002923
                        ],
                        [
                            -88.9139230000689,
                            29.1655270001787
                        ],
                        [
                            -88.9132450002014,
                            29.1545739999629
                        ],
                        [
                            -88.9146000001114,
                            29.1436209998471
                        ],
                        [
                            -88.9173099999313,
                            29.1335560001246
                        ],
                        [
                            -88.9217129997699,
                            29.1249710000761
                        ],
                        [
                            -88.9278089996273,
                            29.1172750001668
                        ],
                        [
                            -88.9457599998139,
                            29.1039540002824
                        ],
                        [
                            -88.9674359999717,
                            29.095073000279
                        ],
                        [
                            -88.9853860003333,
                            29.09418499967
                        ],
                        [
                            -88.9969019998305,
                            29.0853040000989
                        ],
                        [
                            -89.0011890002165,
                            29.0662269997035
                        ],
                        [
                            -89.0009980004208,
                            29.0570090000741
                        ],
                        [
                            -89.0029140000223,
                            29.0487969997499
                        ],
                        [
                            -89.0126879999952,
                            29.0276799998131
                        ],
                        [
                            -89.0259119995797,
                            29.0156130000534
                        ],
                        [
                            -89.0456379998008,
                            29.0069419996775
                        ],
                        [
                            -89.0888260000849,
                            28.9611470000291
                        ],
                        [
                            -89.0902720002314,
                            28.9538750000522
                        ],
                        [
                            -89.0960550000944,
                            28.9424930001025
                        ],
                        [
                            -89.1050909999254,
                            28.9330079999774
                        ],
                        [
                            -89.1148499998297,
                            28.9263679996638
                        ],
                        [
                            -89.1332839998901,
                            28.9197280002403
                        ],
                        [
                            -89.1509940000522,
                            28.919095999865
                        ],
                        [
                            -89.1690670004375,
                            28.9225739997541
                        ],
                        [
                            -89.1853320002781,
                            28.9304780002603
                        ],
                        [
                            -89.1965359996057,
                            28.93996399999
                        ],
                        [
                            -89.2048500000867,
                            28.9529270002589
                        ],
                        [
                            -89.2095790003386,
                            28.9701990001125
                        ],
                        [
                            -89.2243660002455,
                            28.9774860003689
                        ],
                        [
                            -89.2349029995763,
                            28.9871519998652
                        ],
                        [
                            -89.2796039999541,
                            28.977782999816
                        ],
                        [
                            -89.2940720000188,
                            28.9631869996705
                        ],
                        [
                            -89.3051480001982,
                            28.9560909996652
                        ],
                        [
                            -89.3206680002242,
                            28.9343950001217
                        ],
                        [
                            -89.3445350003431,
                            28.9099699996955
                        ],
                        [
                            -89.3621609999413,
                            28.8956430002016
                        ],
                        [
                            -89.3847269996786,
                            28.8715030000576
                        ],
                        [
                            -89.4009930002424,
                            28.8606309999885
                        ],
                        [
                            -89.4182049995639,
                            28.8560309997348
                        ],
                        [
                            -89.433799000108,
                            28.8552660003622
                        ],
                        [
                            -89.4494549996781,
                            28.858147999893
                        ],
                        [
                            -89.4624780003194,
                            28.8636490003863
                        ],
                        [
                            -89.4760579995561,
                            28.8728609998196
                        ],
                        [
                            -89.4834969996279,
                            28.8809730003486
                        ],
                        [
                            -89.4876400000739,
                            28.887371000289
                        ],
                        [
                            -89.4911340000664,
                            28.8973379998195
                        ],
                        [
                            -89.4928580000473,
                            28.9079600002396
                        ],
                        [
                            -89.4902260000452,
                            28.9244340000444
                        ],
                        [
                            -89.4809869998246,
                            28.9416479999404
                        ],
                        [
                            -89.46940600003,
                            28.9561760003789
                        ],
                        [
                            -89.469703000131,
                            28.9632979997218
                        ],
                        [
                            -89.4657360003104,
                            28.9744370002539
                        ],
                        [
                            -89.4634239999755,
                            29.0828200001635
                        ],
                        [
                            -89.4676889997219,
                            29.0961720003522
                        ],
                        [
                            -89.468672999911,
                            29.1074610000712
                        ],
                        [
                            -89.4812079997575,
                            29.1222009999963
                        ],
                        [
                            -89.4861370000259,
                            29.1372179998396
                        ],
                        [
                            -89.4941400001623,
                            29.1454540001727
                        ],
                        [
                            -89.5023190000257,
                            29.1583760001279
                        ],
                        [
                            -89.5204690004039,
                            29.1721870002957
                        ],
                        [
                            -89.5536490003633,
                            29.1859759999451
                        ],
                        [
                            -89.5991590001298,
                            29.1978769996518
                        ],
                        [
                            -89.6104269999298,
                            29.1986349996909
                        ],
                        [
                            -90.0323249996899,
                            29.1350440003579
                        ],
                        [
                            -90.0555700000179,
                            29.1219610000922
                        ],
                        [
                            -90.0925939997742,
                            29.0919899997478
                        ],
                        [
                            -90.1251360000491,
                            29.0698590001008
                        ],
                        [
                            -90.1626619999458,
                            29.0512489998896
                        ],
                        [
                            -90.1824400000454,
                            29.0448240001613
                        ],
                        [
                            -90.2100780001663,
                            29.0330819998232
                        ],
                        [
                            -90.2258219995999,
                            29.0315129997639
                        ],
                        [
                            -90.2390619999763,
                            29.0331039997896
                        ],
                        [
                            -90.3111620001003,
                            29.0108430003552
                        ],
                        [
                            -90.3443189995951,
                            29.0031779997867
                        ],
                        [
                            -90.3684090001951,
                            29.0034250000256
                        ],
                        [
                            -90.3750010003451,
                            29.00023299975
                        ],
                        [
                            -90.3849350001515,
                            28.9980719999276
                        ],
                        [
                            -90.4093799996805,
                            28.9953399999502
                        ],
                        [
                            -90.4918170000171,
                            29.0118049998688
                        ],
                        [
                            -90.5525470002338,
                            29.0368060003355
                        ],
                        [
                            -90.7071909999263,
                            28.9957559999636
                        ],
                        [
                            -90.7468579995553,
                            28.9885949996609
                        ],
                        [
                            -90.7912710000529,
                            28.9881429996634
                        ],
                        [
                            -90.8226480003887,
                            28.9859110000085
                        ],
                        [
                            -90.8645620000554,
                            28.9915929999505
                        ],
                        [
                            -90.9070799999696,
                            28.9911319997006
                        ],
                        [
                            -90.932105000355,
                            28.9943190001274
                        ],
                        [
                            -90.9478680000552,
                            29.00023299975
                        ],
                        [
                            -90.9702870003777,
                            29.0050269997066
                        ],
                        [
                            -90.9934530001646,
                            29.0149700000843
                        ],
                        [
                            -91.0087589999304,
                            29.0269020000465
                        ],
                        [
                            -91.0174469996068,
                            29.0386530003022
                        ],
                        [
                            -91.0207559996514,
                            29.0460650000303
                        ],
                        [
                            -91.0232379998657,
                            29.0592619997915
                        ],
                        [
                            -91.0230310001765,
                            29.0673979999992
                        ],
                        [
                            -91.0174469996068,
                            29.0825840001282
                        ],
                        [
                            -91.0120689997999,
                            29.0905379998747
                        ],
                        [
                            -91.0008989999091,
                            29.1004809998693
                        ],
                        [
                            -90.9893959999324,
                            29.1064460000165
                        ],
                        [
                            -90.9762259998762,
                            29.1102109997379
                        ],
                        [
                            -90.9603719999395,
                            29.1110640003587
                        ],
                        [
                            -90.974925000393,
                            29.121509000374
                        ],
                        [
                            -91.000238999585,
                            29.1198249999727
                        ],
                        [
                            -91.020010000012,
                            29.1217709996401
                        ],
                        [
                            -91.0363300001041,
                            29.1264299996348
                        ],
                        [
                            -91.0594590000811,
                            29.1308219997458
                        ],
                        [
                            -91.0723300003865,
                            29.1315249999363
                        ],
                        [
                            -91.0920399998158,
                            29.1371470001869
                        ],
                        [
                            -91.103302999593,
                            29.1383770000324
                        ],
                        [
                            -91.1183869996008,
                            29.1427700000825
                        ],
                        [
                            -91.1254259996395,
                            29.1464599999162
                        ],
                        [
                            -91.1403089998059,
                            29.1570019999227
                        ],
                        [
                            -91.1459400002312,
                            29.1629750003283
                        ],
                        [
                            -91.1598169995688,
                            29.1684220002874
                        ],
                        [
                            -91.170140000436,
                            29.1701709996274
                        ],
                        [
                            -91.2078450004328,
                            29.1711589996999
                        ],
                        [
                            -91.2372550002151,
                            29.1777429999603
                        ],
                        [
                            -91.2926810002447,
                            29.1961769997612
                        ],
                        [
                            -91.3186980003168,
                            29.2083569998432
                        ],
                        [
                            -91.3416980004224,
                            29.2274490003229
                        ],
                        [
                            -91.3542719997286,
                            29.2396060002579
                        ],
                        [
                            -91.3602579999811,
                            29.2478220000634
                        ],
                        [
                            -91.3769089996111,
                            29.2625850000546
                        ],
                        [
                            -91.3916489996623,
                            29.2818729997248
                        ],
                        [
                            -91.4050239997579,
                            29.2880649996921
                        ],
                        [
                            -91.4211279999399,
                            29.3028280000661
                        ],
                        [
                            -91.5297399996342,
                            29.3444549998523
                        ],
                        [
                            -91.5447030001665,
                            29.3428850001714
                        ],
                        [
                            -91.560747999899,
                            29.3453979997455
                        ],
                        [
                            -91.5746469998762,
                            29.3512239996589
                        ],
                        [
                            -91.5829110001285,
                            29.356968000295
                        ],
                        [
                            -91.5942559999215,
                            29.3603060003445
                        ],
                        [
                            -91.6132349997798,
                            29.3606280001565
                        ],
                        [
                            -91.6246959999237,
                            29.3638489999859
                        ],
                        [
                            -91.6354180001008,
                            29.3691110001455
                        ],
                        [
                            -91.6440439998531,
                            29.3755550001099
                        ],
                        [
                            -91.662528999967,
                            29.3745880002269
                        ],
                        [
                            -91.6748529999263,
                            29.3663190000797
                        ],
                        [
                            -91.6889009998664,
                            29.3615929999589
                        ],
                        [
                            -91.6999929999394,
                            29.3600890001012
                        ],
                        [
                            -91.71219300005,
                            29.3606259997767
                        ],
                        [
                            -91.7234080001466,
                            29.3634180001391
                        ],
                        [
                            -91.7367179999449,
                            29.36964700029
                        ],
                        [
                            -91.7490419999042,
                            29.3807079999998
                        ],
                        [
                            -91.7629190001401,
                            29.3857539998979
                        ],
                        [
                            -91.7774900001369,
                            29.3960839996242
                        ],
                        [
                            -91.7931830004152,
                            29.382543999712
                        ],
                        [
                            -91.8080740000792,
                            29.3765409996659
                        ],
                        [
                            -91.8230269996675,
                            29.3663070000365
                        ],
                        [
                            -91.8327680000285,
                            29.3625619999134
                        ],
                        [
                            -91.8409340003715,
                            29.360189999803
                        ],
                        [
                            -91.8598429999097,
                            29.3589420003063
                        ],
                        [
                            -91.8769120000143,
                            29.3626890000118
                        ],
                        [
                            -91.8896900004333,
                            29.3689529996199
                        ],
                        [
                            -91.8964189999523,
                            29.3740920002301
                        ],
                        [
                            -91.9063479997359,
                            29.3874039998254
                        ],
                        [
                            -91.9106600002363,
                            29.3961170002594
                        ],
                        [
                            -91.913011000031,
                            29.4056849999489
                        ],
                        [
                            -91.9132069998495,
                            29.4202069998496
                        ],
                        [
                            -91.9333930003782,
                            29.4297740002128
                        ],
                        [
                            -91.9454529998485,
                            29.4313359999645
                        ],
                        [
                            -91.964830999915,
                            29.4393920002202
                        ],
                        [
                            -91.9761780002561,
                            29.4491209996763
                        ],
                        [
                            -91.9838669996748,
                            29.4607129996223
                        ],
                        [
                            -92.0073079998214,
                            29.4633749999084
                        ],
                        [
                            -92.0227540002275,
                            29.4706359999146
                        ],
                        [
                            -92.0365290003561,
                            29.483341999803
                        ],
                        [
                            -92.0430679999043,
                            29.4953399999711
                        ],
                        [
                            -92.0456160002408,
                            29.5058060002093
                        ],
                        [
                            -92.0690270002501,
                            29.5218059999962
                        ],
                        [
                            -92.0780499996624,
                            29.5314890000024
                        ],
                        [
                            -92.1000830001956,
                            29.5316719998889
                        ],
                        [
                            -92.1141420000064,
                            29.5351430002627
                        ],
                        [
                            -92.1466659998396,
                            29.5320369997754
                        ],
                        [
                            -92.1833249997229,
                            29.5183340002378
                        ],
                        [
                            -92.2234619997052,
                            29.4947129998186
                        ],
                        [
                            -92.2316570003605,
                            29.4918939997602
                        ],
                        [
                            -92.2639820000022,
                            29.4841490000797
                        ],
                        [
                            -92.2936699996188,
                            29.4814960001493
                        ],
                        [
                            -92.3168329999311,
                            29.4829409999992
                        ],
                        [
                            -92.3536609998688,
                            29.4887160000574
                        ],
                        [
                            -92.4050160003207,
                            29.5002889998816
                        ],
                        [
                            -92.4264909997387,
                            29.5018889996157
                        ],
                        [
                            -92.453308999702,
                            29.506913999654
                        ],
                        [
                            -92.5598199998468,
                            29.5181789998717
                        ],
                        [
                            -92.6249739998185,
                            29.5283280001183
                        ],
                        [
                            -92.6866949998971,
                            29.5441190001683
                        ],
                        [
                            -92.7675970003987,
                            29.5709329997704
                        ],
                        [
                            -92.8946719995673,
                            29.6234949997914
                        ],
                        [
                            -92.9645170001561,
                            29.6545409998625
                        ],
                        [
                            -93.0291079998881,
                            29.677855000163
                        ],
                        [
                            -93.0743080000332,
                            29.6895450000871
                        ],
                        [
                            -93.1251499995758,
                            29.7062090003568
                        ],
                        [
                            -93.1420410003035,
                            29.7085049996942
                        ],
                        [
                            -93.18809199967,
                            29.7207990001388
                        ],
                        [
                            -93.2231680000378,
                            29.7263959996617
                        ],
                        [
                            -93.2684779997876,
                            29.7274390002042
                        ],
                        [
                            -93.2873539997139,
                            29.7243310003195
                        ],
                        [
                            -93.2982569996409,
                            29.7103409997112
                        ],
                        [
                            -93.3107870003628,
                            29.7020040003581
                        ],
                        [
                            -93.3217699997575,
                            29.6977640000909
                        ],
                        [
                            -93.3392940001468,
                            29.6950849999683
                        ],
                        [
                            -93.3494439998634,
                            29.6954059998501
                        ],
                        [
                            -93.3655000003649,
                            29.6994159998989
                        ],
                        [
                            -93.3750960000626,
                            29.7040670003557
                        ],
                        [
                            -93.3872459999445,
                            29.7137709997214
                        ],
                        [
                            -93.4327580002591,
                            29.7185300001231
                        ],
                        [
                            -93.4877540001184,
                            29.7178109996263
                        ],
                        [
                            -93.5145939998229,
                            29.7151180001376
                        ],
                        [
                            -93.5878880001984,
                            29.705692999651
                        ],
                        [
                            -93.6661879999368,
                            29.6977879996817
                        ],
                        [
                            -93.7115470000905,
                            29.6903469997516
                        ],
                        [
                            -93.7500010000998,
                            29.6797299996786
                        ],
                        [
                            -93.7701470004455,
                            29.670825000255
                        ],
                        [
                            -93.7654550000035,
                            29.6441300002498
                        ],
                        [
                            -93.7665210002085,
                            29.6343049999781
                        ],
                        [
                            -93.7703599999825,
                            29.6239240003428
                        ],
                        [
                            -93.7793170001708,
                            29.6116890000273
                        ],
                        [
                            -93.785927999689,
                            29.6059419998632
                        ],
                        [
                            -93.8006440003492,
                            29.5983420003769
                        ],
                        [
                            -93.8143509998075,
                            29.596575999859
                        ],
                        [
                            -93.8287080004424,
                            29.6595350002561
                        ],
                        [
                            -93.8374120000124,
                            29.6898849997183
                        ],
                        [
                            -93.8632039999537,
                            29.7240590000639
                        ],
                        [
                            -93.8700200004096,
                            29.7354820000051
                        ],
                        [
                            -93.8888209999927,
                            29.7422340002389
                        ],
                        [
                            -93.8916370001178,
                            29.7446180002508
                        ],
                        [
                            -93.8938289999038,
                            29.7530329999048
                        ],
                        [
                            -93.8908210001581,
                            29.7616729998417
                        ],
                        [
                            -93.893863000239,
                            29.767294000125
                        ],
                        [
                            -93.8984700002922,
                            29.7715769998118
                        ],
                        [
                            -93.922406999833,
                            29.7850479999584
                        ],
                        [
                            -93.9265040002482,
                            29.7895599997335
                        ],
                        [
                            -93.9288080001872,
                            29.7970799997555
                        ],
                        [
                            -93.9279920002275,
                            29.8096400000304
                        ],
                        [
                            -93.9227440001169,
                            29.8188079997553
                        ],
                        [
                            -93.9136720003008,
                            29.8271920001291
                        ],
                        [
                            -93.895574999626,
                            29.8402469998272
                        ],
                        [
                            -93.8635699996516,
                            29.8571769997172
                        ],
                        [
                            -93.8551399997179,
                            29.8640990001343
                        ],
                        [
                            -93.838373999562,
                            29.8828530001537
                        ],
                        [
                            -93.8303739997986,
                            29.8943579998932
                        ],
                        [
                            -93.8172859997582,
                            29.9184219999709
                        ],
                        [
                            -93.807814999734,
                            29.9545490000631
                        ],
                        [
                            -93.7944389998135,
                            29.974773000384
                        ],
                        [
                            -93.7885990000492,
                            29.9888359999977
                        ],
                        [
                            -93.7808229996935,
                            29.9942760000601
                        ],
                        [
                            -93.7705669996717,
                            29.996531999776
                        ],
                        [
                            -93.7672390002589,
                            30.003380000091
                        ],
                        [
                            -93.7566300000597,
                            30.0141629997094
                        ],
                        [
                            -93.7410780002467,
                            30.0215710001384
                        ],
                        [
                            -93.7374460001619,
                            30.0372829999182
                        ],
                        [
                            -93.7208049996794,
                            30.0530430003866
                        ],
                        [
                            -93.7194530001425,
                            30.0638080001132
                        ],
                        [
                            -93.7178839999723,
                            30.0655539998291
                        ],
                        [
                            -93.7137720003868,
                            30.0656870003497
                        ],
                        [
                            -93.7110740002625,
                            30.0543910000077
                        ],
                        [
                            -93.7041509996765,
                            30.0542080002571
                        ],
                        [
                            -93.6995160000342,
                            30.0585780001891
                        ],
                        [
                            -93.700579999691,
                            30.063683000284
                        ],
                        [
                            -93.703104999563,
                            30.0662609997283
                        ],
                        [
                            -93.7170079997382,
                            30.0695829998461
                        ],
                        [
                            -93.7259099996749,
                            30.0784690001939
                        ],
                        [
                            -93.7324909999542,
                            30.082589000202
                        ],
                        [
                            -93.7340850002387,
                            30.0861299996316
                        ],
                        [
                            -93.7331269999888,
                            30.0883740001083
                        ],
                        [
                            -93.7310790001429,
                            30.0895179998963
                        ],
                        [
                            -93.726858999704,
                            30.082268000204
                        ],
                        [
                            -93.7240049999441,
                            30.0826259996408
                        ],
                        [
                            -93.7237649997446,
                            30.0941299998858
                        ],
                        [
                            -93.7154929999947,
                            30.100529000117
                        ],
                        [
                            -93.712260999943,
                            30.1090410003374
                        ],
                        [
                            -93.7042600003547,
                            30.110657000344
                        ],
                        [
                            -93.7024359999164,
                            30.1127209996592
                        ],
                        [
                            -93.7034119997097,
                            30.1171849998996
                        ],
                        [
                            -93.7079540003638,
                            30.1200160002919
                        ],
                        [
                            -93.7008719997691,
                            30.1231140001615
                        ],
                        [
                            -93.7012099998779,
                            30.1374579998802
                        ],
                        [
                            -93.6982740001022,
                            30.1386189996465
                        ],
                        [
                            -93.6949519996388,
                            30.1351810002757
                        ],
                        [
                            -93.6928490004395,
                            30.1352130000858
                        ],
                        [
                            -93.6882049996782,
                            30.1413569997866
                        ],
                        [
                            -93.693303000001,
                            30.1418990003371
                        ],
                        [
                            -93.6977369998018,
                            30.1529460000816
                        ],
                        [
                            -93.7095079995671,
                            30.1466720002668
                        ],
                        [
                            -93.7061540002149,
                            30.1505960003054
                        ],
                        [
                            -93.7069960001139,
                            30.1574649997968
                        ],
                        [
                            -93.7030500002097,
                            30.1612309997569
                        ],
                        [
                            -93.7008170004158,
                            30.1662260001341
                        ],
                        [
                            -93.7026579996742,
                            30.1678240003582
                        ],
                        [
                            -93.702999999981,
                            30.1716949997978
                        ],
                        [
                            -93.6999529998772,
                            30.1755289998419
                        ],
                        [
                            -93.7042400002632,
                            30.1741849999128
                        ],
                        [
                            -93.7065380003545,
                            30.1769789999873
                        ],
                        [
                            -93.7056210001124,
                            30.1806489997447
                        ],
                        [
                            -93.7106840002752,
                            30.1798619997106
                        ],
                        [
                            -93.7087950004378,
                            30.1863530002415
                        ],
                        [
                            -93.7124230003247,
                            30.1871719997028
                        ],
                        [
                            -93.7125520001961,
                            30.1924559998724
                        ],
                        [
                            -93.7089920000813,
                            30.1909589999807
                        ],
                        [
                            -93.7054949997158,
                            30.1929210003786
                        ],
                        [
                            -93.7091209999528,
                            30.197207999863
                        ],
                        [
                            -93.7132269996905,
                            30.1932450003451
                        ],
                        [
                            -93.7175230002973,
                            30.193084999843
                        ],
                        [
                            -93.7176479999708,
                            30.1948879999502
                        ],
                        [
                            -93.7147549998528,
                            30.1974039997663
                        ],
                        [
                            -93.7179040000638,
                            30.2007229999568
                        ],
                        [
                            -93.7146169997605,
                            30.2023829999987
                        ],
                        [
                            -93.7141919996131,
                            30.2086590003849
                        ],
                        [
                            -93.7174379999085,
                            30.2062080002619
                        ],
                        [
                            -93.7212590001392,
                            30.2096109998559
                        ],
                        [
                            -93.7171190000663,
                            30.2120400003826
                        ],
                        [
                            -93.7195569998996,
                            30.2147510000629
                        ],
                        [
                            -93.7170200003321,
                            30.2161309998964
                        ],
                        [
                            -93.7206890002268,
                            30.2154810000631
                        ],
                        [
                            -93.7184630001056,
                            30.2199980002951
                        ],
                        [
                            -93.7160649995569,
                            30.2209339999308
                        ],
                        [
                            -93.7130579996361,
                            30.2186409997121
                        ],
                        [
                            -93.7111760003697,
                            30.2220700002883
                        ],
                        [
                            -93.7136419997922,
                            30.2246559997009
                        ],
                        [
                            -93.7095090002903,
                            30.2278339997436
                        ],
                        [
                            -93.7134960002023,
                            30.2288999997608
                        ],
                        [
                            -93.7110979996536,
                            30.2352470002024
                        ],
                        [
                            -93.7097489995915,
                            30.2355119997954
                        ],
                        [
                            -93.7095129995899,
                            30.2335480001324
                        ],
                        [
                            -93.7044899996102,
                            30.2337870002519
                        ],
                        [
                            -93.7094499998407,
                            30.2385140003735
                        ],
                        [
                            -93.7078120000736,
                            30.2396240000134
                        ],
                        [
                            -93.7030989997152,
                            30.2384589998242
                        ],
                        [
                            -93.7050019997963,
                            30.2423509998651
                        ],
                        [
                            -93.7095310000316,
                            30.2449319997758
                        ],
                        [
                            -93.7055419995714,
                            30.2465020002003
                        ],
                        [
                            -93.7090499998077,
                            30.2480479998744
                        ],
                        [
                            -93.7073259998268,
                            30.2496680001482
                        ],
                        [
                            -93.7089279996089,
                            30.2516530001691
                        ],
                        [
                            -93.7063009996297,
                            30.2529559998772
                        ],
                        [
                            -93.7029830002626,
                            30.2605169997685
                        ],
                        [
                            -93.7072659995523,
                            30.2614710002499
                        ],
                        [
                            -93.7079789995798,
                            30.2636859999584
                        ],
                        [
                            -93.706217999789,
                            30.2654769996753
                        ],
                        [
                            -93.708818999829,
                            30.2659229999545
                        ],
                        [
                            -93.705452000058,
                            30.2677560002043
                        ],
                        [
                            -93.7086659996681,
                            30.2674280001754
                        ],
                        [
                            -93.707756999822,
                            30.2715690002284
                        ],
                        [
                            -93.7104159995882,
                            30.2731720000523
                        ],
                        [
                            -93.7065259997606,
                            30.2735010003051
                        ],
                        [
                            -93.7040600003382,
                            30.279417000372
                        ],
                        [
                            -93.7094189998786,
                            30.28191699972
                        ],
                        [
                            -93.7090530001807,
                            30.2838740003046
                        ],
                        [
                            -93.7052539996913,
                            30.2861810003854
                        ],
                        [
                            -93.7045840002198,
                            30.2899490001904
                        ],
                        [
                            -93.7083540003969,
                            30.2925629999531
                        ],
                        [
                            -93.7104390000528,
                            30.2877649997247
                        ],
                        [
                            -93.7129079998483,
                            30.2879390003084
                        ],
                        [
                            -93.7136529996628,
                            30.290002999978
                        ],
                        [
                            -93.7122690003389,
                            30.2923309997005
                        ],
                        [
                            -93.7155209995837,
                            30.295628999671
                        ],
                        [
                            -93.7154129996287,
                            30.2927639996881
                        ],
                        [
                            -93.7189819999643,
                            30.296245999874
                        ],
                        [
                            -93.7242080003337,
                            30.2951119996885
                        ],
                        [
                            -93.7255110003651,
                            30.2982260001305
                        ],
                        [
                            -93.7290460003655,
                            30.2967050002285
                        ],
                        [
                            -93.7329060000559,
                            30.2989930001485
                        ],
                        [
                            -93.7375559997668,
                            30.2993690001208
                        ],
                        [
                            -93.7345460003712,
                            30.3016749997789
                        ],
                        [
                            -93.7385199998644,
                            30.3037450002579
                        ],
                        [
                            -93.7424239999358,
                            30.3011390001882
                        ],
                        [
                            -93.7443520001312,
                            30.3054509998304
                        ],
                        [
                            -93.7409180004131,
                            30.3072299996381
                        ],
                        [
                            -93.7456479995916,
                            30.3117439996962
                        ],
                        [
                            -93.74860900038,
                            30.3119449996619
                        ],
                        [
                            -93.7468679997823,
                            30.3153760001068
                        ],
                        [
                            -93.7501810000249,
                            30.3170099998918
                        ],
                        [
                            -93.7478150001616,
                            30.3187559998625
                        ],
                        [
                            -93.7510410003655,
                            30.3198519996343
                        ],
                        [
                            -93.7507109997543,
                            30.3217530000455
                        ],
                        [
                            -93.7561980002396,
                            30.326004000387
                        ],
                        [
                            -93.7516759996769,
                            30.3293470003844
                        ],
                        [
                            -93.7552440001876,
                            30.3283870002485
                        ],
                        [
                            -93.7555839999462,
                            30.3315050003784
                        ],
                        [
                            -93.7572739995919,
                            30.3323540002575
                        ],
                        [
                            -93.7629579997207,
                            30.3294479999211
                        ],
                        [
                            -93.7658290000973,
                            30.3327449999427
                        ],
                        [
                            -93.7586579998142,
                            30.3390189996185
                        ],
                        [
                            -93.7630890001402,
                            30.3394570001867
                        ],
                        [
                            -93.7656550000201,
                            30.3434090001167
                        ],
                        [
                            -93.7643560001866,
                            30.344447999784
                        ],
                        [
                            -93.7595330002234,
                            30.3423229997583
                        ],
                        [
                            -93.7636549998547,
                            30.3460399998575
                        ],
                        [
                            -93.7577180000059,
                            30.3499769996565
                        ],
                        [
                            -93.7624799998698,
                            30.3530389999
                        ],
                        [
                            -93.758118000039,
                            30.3546860002905
                        ],
                        [
                            -93.7609380003621,
                            30.3571120002911
                        ],
                        [
                            -93.7607790003534,
                            30.3598500000106
                        ],
                        [
                            -93.7589299998008,
                            30.360964999933
                        ],
                        [
                            -93.756803000312,
                            30.3561580000628
                        ],
                        [
                            -93.7553670002113,
                            30.3595820000623
                        ],
                        [
                            -93.7509790004412,
                            30.3598989996874
                        ],
                        [
                            -93.7576870000438,
                            30.3645809999397
                        ],
                        [
                            -93.759216000031,
                            30.3680200001764
                        ],
                        [
                            -93.7580510000919,
                            30.3696920002182
                        ],
                        [
                            -93.7564950003405,
                            30.3662529998776
                        ],
                        [
                            -93.754856999675,
                            30.3660910001617
                        ],
                        [
                            -93.7552259997459,
                            30.378848000027
                        ],
                        [
                            -93.7584660001936,
                            30.3840330001685
                        ],
                        [
                            -93.7548220004132,
                            30.3863129999365
                        ],
                        [
                            -93.7586119997834,
                            30.3861860001287
                        ],
                        [
                            -93.7576679997772,
                            30.3904409997156
                        ],
                        [
                            -93.7526149996602,
                            30.3927499996698
                        ],
                        [
                            -93.7510160002512,
                            30.3964840002063
                        ],
                        [
                            -93.7456810001019,
                            30.3965700001136
                        ],
                        [
                            -93.7416790001212,
                            30.402975000172
                        ],
                        [
                            -93.7367640000965,
                            30.4054779997716
                        ],
                        [
                            -93.7339170000092,
                            30.4149350002514
                        ],
                        [
                            -93.7287209997772,
                            30.4135470003082
                        ],
                        [
                            -93.7275480003404,
                            30.4187199997314
                        ],
                        [
                            -93.7227489997683,
                            30.4195569999297
                        ],
                        [
                            -93.7227439997455,
                            30.4213690003002
                        ],
                        [
                            -93.7268339995896,
                            30.4236419996327
                        ],
                        [
                            -93.7261060003918,
                            30.4307200000871
                        ],
                        [
                            -93.72276700021,
                            30.431982999754
                        ],
                        [
                            -93.7180510003769,
                            30.4225790000228
                        ],
                        [
                            -93.7163690002288,
                            30.4247549997706
                        ],
                        [
                            -93.716904999806,
                            30.4276789997403
                        ],
                        [
                            -93.7140390003505,
                            30.4297289998505
                        ],
                        [
                            -93.7127470001898,
                            30.433174999835
                        ],
                        [
                            -93.7082549997643,
                            30.4302520003703
                        ],
                        [
                            -93.7023289997863,
                            30.4298400000057
                        ],
                        [
                            -93.7041540000495,
                            30.4343530003172
                        ],
                        [
                            -93.6989269998552,
                            30.437233999705
                        ],
                        [
                            -93.6975779997931,
                            30.4422330000409
                        ],
                        [
                            -93.6983059998892,
                            30.4449279996311
                        ],
                        [
                            -93.7046329997253,
                            30.4431179997752
                        ],
                        [
                            -93.7074649997439,
                            30.4450449999754
                        ],
                        [
                            -93.7021029998305,
                            30.4497589999268
                        ],
                        [
                            -93.7069359998394,
                            30.4553409999314
                        ],
                        [
                            -93.7063390001629,
                            30.4581410002964
                        ],
                        [
                            -93.7003589997581,
                            30.4575319996149
                        ],
                        [
                            -93.6990359996352,
                            30.4588050002658
                        ],
                        [
                            -93.7063769997977,
                            30.466904000143
                        ],
                        [
                            -93.7118540002372,
                            30.4694370002391
                        ],
                        [
                            -93.7105190004187,
                            30.4754980000518
                        ],
                        [
                            -93.7168110000947,
                            30.4814949996624
                        ],
                        [
                            -93.7174210001901,
                            30.4838220001989
                        ],
                        [
                            -93.7148300001959,
                            30.485234999735
                        ],
                        [
                            -93.7117270000156,
                            30.4822989998158
                        ],
                        [
                            -93.7097629998352,
                            30.4826490002901
                        ],
                        [
                            -93.7102470004321,
                            30.4862529998119
                        ],
                        [
                            -93.7170689998376,
                            30.4944489999678
                        ],
                        [
                            -93.7153769996437,
                            30.4972700001538
                        ],
                        [
                            -93.7103949996719,
                            30.4974110000644
                        ],
                        [
                            -93.7086690000411,
                            30.4991240000607
                        ],
                        [
                            -93.7085359999718,
                            30.5003970003117
                        ],
                        [
                            -93.714123999841,
                            30.4999969996419
                        ],
                        [
                            -93.7151449998401,
                            30.50126800005
                        ],
                        [
                            -93.7106199998027,
                            30.5061980000448
                        ],
                        [
                            -93.7068089996178,
                            30.507068999687
                        ],
                        [
                            -93.7076530000649,
                            30.5110660000836
                        ],
                        [
                            -93.7117620001757,
                            30.5109149999267
                        ],
                        [
                            -93.7098919997066,
                            30.5138509999742
                        ],
                        [
                            -93.711022000384,
                            30.5172749999895
                        ],
                        [
                            -93.7076830002021,
                            30.5213370001057
                        ],
                        [
                            -93.709155000288,
                            30.5218450000321
                        ],
                        [
                            -93.7128700002134,
                            30.5186130003714
                        ],
                        [
                            -93.7164729999859,
                            30.5192309996738
                        ],
                        [
                            -93.7204840001874,
                            30.52432799966
                        ],
                        [
                            -93.7277220004177,
                            30.5256700002698
                        ],
                        [
                            -93.732793000078,
                            30.5299599996167
                        ],
                        [
                            -93.7338209997498,
                            30.5317350003146
                        ],
                        [
                            -93.7324159996111,
                            30.5363660001585
                        ],
                        [
                            -93.7380529998842,
                            30.537309000135
                        ],
                        [
                            -93.7402530000661,
                            30.5395689998393
                        ],
                        [
                            -93.7287600001352,
                            30.5452959998165
                        ],
                        [
                            -93.7314230000994,
                            30.5528610002373
                        ],
                        [
                            -93.7239449996696,
                            30.5592240003503
                        ],
                        [
                            -93.7243000003952,
                            30.562014999895
                        ],
                        [
                            -93.7278179997788,
                            30.5659190002604
                        ],
                        [
                            -93.7261649999431,
                            30.5695200000374
                        ],
                        [
                            -93.7278439997181,
                            30.5740699996459
                        ],
                        [
                            -93.7214929995926,
                            30.5805529998471
                        ],
                        [
                            -93.7179109997365,
                            30.5777670000233
                        ],
                        [
                            -93.7150679998472,
                            30.5790180002954
                        ],
                        [
                            -93.7137820004326,
                            30.5875839997744
                        ],
                        [
                            -93.7113179997617,
                            30.5889059997894
                        ],
                        [
                            -93.7039080000022,
                            30.5884410003488
                        ],
                        [
                            -93.7008919998606,
                            30.5916429997226
                        ],
                        [
                            -93.6928689996327,
                            30.5943820001176
                        ],
                        [
                            -93.6878039998202,
                            30.5901380002707
                        ],
                        [
                            -93.6917510004475,
                            30.5879589996948
                        ],
                        [
                            -93.6922219997274,
                            30.58570600031
                        ],
                        [
                            -93.6865729997588,
                            30.5857199999341
                        ],
                        [
                            -93.6849330003418,
                            30.5871449999834
                        ],
                        [
                            -93.6850660004112,
                            30.5920860002015
                        ],
                        [
                            -93.6836489996787,
                            30.593520000307
                        ],
                        [
                            -93.6789489997391,
                            30.5936369997676
                        ],
                        [
                            -93.6787670001642,
                            30.598628999804
                        ],
                        [
                            -93.6833719996692,
                            30.6082489998815
                        ],
                        [
                            -93.6891339996158,
                            30.6114059996797
                        ],
                        [
                            -93.6857020004458,
                            30.616417999925
                        ],
                        [
                            -93.6819830003224,
                            30.618583999833
                        ],
                        [
                            -93.6851209997645,
                            30.6252009999961
                        ],
                        [
                            -93.6842110000934,
                            30.6318810003635
                        ],
                        [
                            -93.6804149999771,
                            30.6348770001353
                        ],
                        [
                            -93.6829719996361,
                            30.6412510003826
                        ],
                        [
                            -93.6747609999856,
                            30.6476830001444
                        ],
                        [
                            -93.6738379998958,
                            30.6512969999936
                        ],
                        [
                            -93.6705360004222,
                            30.6526260003785
                        ],
                        [
                            -93.670358000147,
                            30.6580070003048
                        ],
                        [
                            -93.6657529997437,
                            30.6615809996727
                        ],
                        [
                            -93.6597449997498,
                            30.6620519996224
                        ],
                        [
                            -93.6549720000153,
                            30.6701850002239
                        ],
                        [
                            -93.6514729999999,
                            30.6699399996538
                        ],
                        [
                            -93.6485840000799,
                            30.6676470003859
                        ],
                        [
                            -93.6453059999974,
                            30.673480999815
                        ],
                        [
                            -93.6382150000802,
                            30.6730600001946
                        ],
                        [
                            -93.6360540002563,
                            30.6763200001551
                        ],
                        [
                            -93.6313450000959,
                            30.6778720001579
                        ],
                        [
                            -93.6298870002538,
                            30.6799349997271
                        ],
                        [
                            -93.6266469998062,
                            30.6883569997809
                        ],
                        [
                            -93.6204420001689,
                            30.6941419998638
                        ],
                        [
                            -93.6246759999531,
                            30.6999369997898
                        ],
                        [
                            -93.6164369998152,
                            30.7123879998483
                        ],
                        [
                            -93.6172290003837,
                            30.7185799997022
                        ],
                        [
                            -93.6144469996955,
                            30.7198700003727
                        ],
                        [
                            -93.6125980000411,
                            30.7165319998723
                        ],
                        [
                            -93.610046000405,
                            30.7182929999246
                        ],
                        [
                            -93.6087199999091,
                            30.7152350000168
                        ],
                        [
                            -93.605390999773,
                            30.7171589999069
                        ],
                        [
                            -93.6136440001546,
                            30.7221430000203
                        ],
                        [
                            -93.6095440002647,
                            30.7231389998291
                        ],
                        [
                            -93.6098139997031,
                            30.727339000303
                        ],
                        [
                            -93.6148929997593,
                            30.726787999627
                        ],
                        [
                            -93.6177189999302,
                            30.728896999842
                        ],
                        [
                            -93.6153070000361,
                            30.7298159999566
                        ],
                        [
                            -93.610952999703,
                            30.7278680003051
                        ],
                        [
                            -93.6091340001858,
                            30.7300389997817
                        ],
                        [
                            -93.612819999799,
                            30.7367070000001
                        ],
                        [
                            -93.6190310001824,
                            30.7403379997134
                        ],
                        [
                            -93.6177530002654,
                            30.7432520002575
                        ],
                        [
                            -93.6099459999476,
                            30.7446490003731
                        ],
                        [
                            -93.6084139995873,
                            30.7466640000981
                        ],
                        [
                            -93.6147770003067,
                            30.7560640003036
                        ],
                        [
                            -93.6143460003115,
                            30.7601949996226
                        ],
                        [
                            -93.6123720000853,
                            30.7605969999855
                        ],
                        [
                            -93.6091779996685,
                            30.7572600002788
                        ],
                        [
                            -93.606496000336,
                            30.7587969999343
                        ],
                        [
                            -93.6059409995939,
                            30.7645930001112
                        ],
                        [
                            -93.6036629995941,
                            30.7601149999837
                        ],
                        [
                            -93.5921010000662,
                            30.7636750002291
                        ],
                        [
                            -93.5927139996363,
                            30.7750950003482
                        ],
                        [
                            -93.5900490000222,
                            30.7776619997906
                        ],
                        [
                            -93.5838150000726,
                            30.7770520003376
                        ],
                        [
                            -93.5831649997943,
                            30.7785959997148
                        ],
                        [
                            -93.5893810002006,
                            30.7866759996917
                        ],
                        [
                            -93.5819210002124,
                            30.7889790002889
                        ],
                        [
                            -93.5813880001099,
                            30.7916270002257
                        ],
                        [
                            -93.5840939997319,
                            30.7966889998885
                        ],
                        [
                            -93.5837749998896,
                            30.8017789998944
                        ],
                        [
                            -93.5815050002858,
                            30.8031049997108
                        ],
                        [
                            -93.5746770001343,
                            30.8013730003085
                        ],
                        [
                            -93.5629799999889,
                            30.8060090000324
                        ],
                        [
                            -93.560707000012,
                            30.8096390002753
                        ],
                        [
                            -93.5637630003366,
                            30.8136059996513
                        ],
                        [
                            -93.5641640001946,
                            30.8175410002634
                        ],
                        [
                            -93.5544329998794,
                            30.8235240002333
                        ],
                        [
                            -93.5540570001357,
                            30.8249410002046
                        ],
                        [
                            -93.5573200001496,
                            30.8284189996515
                        ],
                        [
                            -93.5533109995979,
                            30.83254199993
                        ],
                        [
                            -93.5536260001405,
                            30.8351400001601
                        ],
                        [
                            -93.5580239999563,
                            30.8400749997814
                        ],
                        [
                            -93.5687169998211,
                            30.8441820000985
                        ],
                        [
                            -93.5573140003018,
                            30.85208099998
                        ],
                        [
                            -93.5621470003107,
                            30.8593320003272
                        ],
                        [
                            -93.55861599961,
                            30.8694239999899
                        ],
                        [
                            -93.5645039999532,
                            30.8686400002912
                        ],
                        [
                            -93.5632329997089,
                            30.8748669999953
                        ],
                        [
                            -93.5706040000085,
                            30.8751930000479
                        ],
                        [
                            -93.5740799995594,
                            30.8845289998156
                        ],
                        [
                            -93.5663159997977,
                            30.8948389996653
                        ],
                        [
                            -93.5492020003855,
                            30.8974389998849
                        ],
                        [
                            -93.5486230002522,
                            30.8998939996827
                        ],
                        [
                            -93.5531159996043,
                            30.9010529996679
                        ],
                        [
                            -93.5539950002115,
                            30.9027730001875
                        ],
                        [
                            -93.5530260000909,
                            30.9081000000895
                        ],
                        [
                            -93.5580869997055,
                            30.9107969998337
                        ],
                        [
                            -93.5585620000816,
                            30.913238000123
                        ],
                        [
                            -93.5520849995594,
                            30.9181190000619
                        ],
                        [
                            -93.549282999678,
                            30.9243719999649
                        ],
                        [
                            -93.5457180004387,
                            30.9270999997813
                        ],
                        [
                            -93.5420729999351,
                            30.9258489999538
                        ],
                        [
                            -93.5434229998222,
                            30.9201530003431
                        ],
                        [
                            -93.5358320003128,
                            30.9236909998716
                        ],
                        [
                            -93.532007999709,
                            30.9237539997409
                        ],
                        [
                            -93.5307030000278,
                            30.9267780001478
                        ],
                        [
                            -93.5264740002664,
                            30.9294639997063
                        ],
                        [
                            -93.527836999674,
                            30.9330660000682
                        ],
                        [
                            -93.526013000134,
                            30.9375350003417
                        ],
                        [
                            -93.5298140002732,
                            30.9421719999814
                        ],
                        [
                            -93.5323669997342,
                            30.9489770002828
                        ],
                        [
                            -93.5305339999734,
                            30.9545779998064
                        ],
                        [
                            -93.5317580003621,
                            30.9582170001228
                        ],
                        [
                            -93.5431220004216,
                            30.9567400002869
                        ],
                        [
                            -93.5463269998108,
                            30.9578019996727
                        ],
                        [
                            -93.547314000373,
                            30.9647549997777
                        ],
                        [
                            -93.5498409998948,
                            30.9671179997795
                        ],
                        [
                            -93.5535399999268,
                            30.9673740000673
                        ],
                        [
                            -93.5605370001327,
                            30.9712890002478
                        ],
                        [
                            -93.5677369998298,
                            30.9689710002218
                        ],
                        [
                            -93.5735370003095,
                            30.9712060001099
                        ],
                        [
                            -93.5743320003528,
                            30.973707999821
                        ],
                        [
                            -93.5692950001294,
                            30.9761910001612
                        ],
                        [
                            -93.566858000121,
                            30.9794029996308
                        ],
                        [
                            -93.5642989999138,
                            30.9896999996303
                        ],
                        [
                            -93.5733649998821,
                            30.9918280000427
                        ],
                        [
                            -93.5782070001119,
                            30.9999949996977
                        ],
                        [
                            -93.5734229996084,
                            31.0038580003039
                        ],
                        [
                            -93.5714650001741,
                            31.0095989998976
                        ],
                        [
                            -93.56656100002,
                            31.0130569999683
                        ],
                        [
                            -93.5559269997065,
                            31.0037439999981
                        ],
                        [
                            -93.5493770002877,
                            31.0101430001241
                        ],
                        [
                            -93.5391840000151,
                            31.0082119997347
                        ],
                        [
                            -93.5324570001459,
                            31.0197190002018
                        ],
                        [
                            -93.5223140001021,
                            31.0200979998485
                        ],
                        [
                            -93.5089599999229,
                            31.0290169997139
                        ],
                        [
                            -93.5080390003812,
                            31.0315319997004
                        ],
                        [
                            -93.5233829997817,
                            31.0377159998474
                        ],
                        [
                            -93.5244499998116,
                            31.0411389998621
                        ],
                        [
                            -93.5199409996677,
                            31.0418759999727
                        ],
                        [
                            -93.5188989997522,
                            31.0437360002895
                        ],
                        [
                            -93.5277429999627,
                            31.048316999948
                        ],
                        [
                            -93.5316279997674,
                            31.0536270001497
                        ],
                        [
                            -93.5322150002966,
                            31.0552359999977
                        ],
                        [
                            -93.5301289999175,
                            31.0568139999014
                        ],
                        [
                            -93.5211449999649,
                            31.0572749999618
                        ],
                        [
                            -93.518912000171,
                            31.0618629998708
                        ],
                        [
                            -93.5201259996157,
                            31.0658160003133
                        ],
                        [
                            -93.5251310000521,
                            31.0665510002961
                        ],
                        [
                            -93.5287320001747,
                            31.0692139998401
                        ],
                        [
                            -93.5276450000534,
                            31.074510000045
                        ],
                        [
                            -93.5326270000252,
                            31.0745520002702
                        ],
                        [
                            -93.5401299996711,
                            31.0779399996387
                        ],
                        [
                            -93.5522050001083,
                            31.0789309997539
                        ],
                        [
                            -93.5524670000491,
                            31.082489000357
                        ],
                        [
                            -93.5486990004202,
                            31.0886370003416
                        ],
                        [
                            -93.5492619997616,
                            31.0904120000075
                        ],
                        [
                            -93.5507139997559,
                            31.0909850000199
                        ],
                        [
                            -93.5522789997282,
                            31.0883269998875
                        ],
                        [
                            -93.5558149995535,
                            31.0869650003725
                        ],
                        [
                            -93.5585810003482,
                            31.0915040002306
                        ],
                        [
                            -93.5637459997198,
                            31.0945219998156
                        ],
                        [
                            -93.5631819996552,
                            31.0969879997398
                        ],
                        [
                            -93.560342000139,
                            31.0977439998489
                        ],
                        [
                            -93.5593780000413,
                            31.0976780003172
                        ],
                        [
                            -93.5579410001156,
                            31.0958740002318
                        ],
                        [
                            -93.5537220004,
                            31.0938690002016
                        ],
                        [
                            -93.5583160000343,
                            31.096481000188
                        ],
                        [
                            -93.558178999767,
                            31.0979830003685
                        ],
                        [
                            -93.5490669997679,
                            31.1002190003362
                        ],
                        [
                            -93.5486960000472,
                            31.1060349996174
                        ],
                        [
                            -93.5459350001737,
                            31.1111970000403
                        ],
                        [
                            -93.5393659995899,
                            31.1152069998558
                        ],
                        [
                            -93.537870000113,
                            31.1265700002004
                        ],
                        [
                            -93.5447350000744,
                            31.1360660003336
                        ],
                        [
                            -93.5485150002972,
                            31.1482289997091
                        ],
                        [
                            -93.5487000002451,
                            31.155838999716
                        ],
                        [
                            -93.5401640000063,
                            31.1560539998324
                        ],
                        [
                            -93.5364809998679,
                            31.1584710000107
                        ],
                        [
                            -93.5379889999387,
                            31.1634270000792
                        ],
                        [
                            -93.5430610003222,
                            31.1706579997015
                        ],
                        [
                            -93.5328069999503,
                            31.1742649997534
                        ],
                        [
                            -93.531318000146,
                            31.1798060001038
                        ],
                        [
                            -93.5325450000095,
                            31.1833139996358
                        ],
                        [
                            -93.5350980003688,
                            31.1856120001431
                        ],
                        [
                            -93.5392800002745,
                            31.1835469999333
                        ],
                        [
                            -93.5443650001786,
                            31.1834779998423
                        ],
                        [
                            -93.54998400001,
                            31.186626999703
                        ],
                        [
                            -93.569562999918,
                            31.1775740001878
                        ],
                        [
                            -93.578166000104,
                            31.1678550003703
                        ],
                        [
                            -93.5891190002598,
                            31.1658889996357
                        ],
                        [
                            -93.6003080004171,
                            31.1761579998314
                        ],
                        [
                            -93.6024430003017,
                            31.1825409998651
                        ],
                        [
                            -93.6018569995975,
                            31.1993930002825
                        ],
                        [
                            -93.6072429998004,
                            31.204805999869
                        ],
                        [
                            -93.6035410002937,
                            31.2106729997005
                        ],
                        [
                            -93.6034960000879,
                            31.2167099999921
                        ],
                        [
                            -93.5952550003001,
                            31.2203139997361
                        ],
                        [
                            -93.5993529996419,
                            31.2249489996639
                        ],
                        [
                            -93.5962729999262,
                            31.2295100002583
                        ],
                        [
                            -93.5983910000923,
                            31.2310970001598
                        ],
                        [
                            -93.6081580003926,
                            31.2278350001494
                        ],
                        [
                            -93.6107050000059,
                            31.2321520003509
                        ],
                        [
                            -93.6181070002677,
                            31.2326849996176
                        ],
                        [
                            -93.6162739996086,
                            31.2368379998629
                        ],
                        [
                            -93.6163079999437,
                            31.2445950003675
                        ],
                        [
                            -93.6217569998959,
                            31.249127000288
                        ],
                        [
                            -93.6142879996868,
                            31.2516310003145
                        ],
                        [
                            -93.615636999749,
                            31.2551269999824
                        ],
                        [
                            -93.6139420000804,
                            31.2593749999619
                        ],
                        [
                            -93.619924000135,
                            31.2651779998233
                        ],
                        [
                            -93.6203430004347,
                            31.2710249999701
                        ],
                        [
                            -93.6425159998116,
                            31.2695079999896
                        ],
                        [
                            -93.6446330001529,
                            31.2777639999556
                        ],
                        [
                            -93.648647999654,
                            31.2842300000639
                        ],
                        [
                            -93.658685000291,
                            31.2816240001271
                        ],
                        [
                            -93.668927999894,
                            31.297974999824
                        ],
                        [
                            -93.6754399996779,
                            31.3010400002434
                        ],
                        [
                            -93.6835609998151,
                            31.3015070001889
                        ],
                        [
                            -93.6872780002887,
                            31.3060679998977
                        ],
                        [
                            -93.6878509996758,
                            31.309834999901
                        ],
                        [
                            -93.6839809999397,
                            31.3146210003366
                        ],
                        [
                            -93.682940999674,
                            31.3189989997758
                        ],
                        [
                            -93.6762630002086,
                            31.3213510003015
                        ],
                        [
                            -93.6735530003888,
                            31.3245419996235
                        ],
                        [
                            -93.6772769996367,
                            31.3304830000672
                        ],
                        [
                            -93.6690240001534,
                            31.3388289999977
                        ],
                        [
                            -93.6695470002102,
                            31.3505699997965
                        ],
                        [
                            -93.6684390001724,
                            31.3530120002881
                        ],
                        [
                            -93.6458659998641,
                            31.3590080003068
                        ],
                        [
                            -93.6441240003398,
                            31.3655280003778
                        ],
                        [
                            -93.6392219998356,
                            31.3720200003676
                        ],
                        [
                            -93.6482569998417,
                            31.3708550001735
                        ],
                        [
                            -93.6583429999842,
                            31.3668449997223
                        ],
                        [
                            -93.6709990002045,
                            31.3662210000794
                        ],
                        [
                            -93.6738470001166,
                            31.3706329999077
                        ],
                        [
                            -93.6690169995824,
                            31.3713790002065
                        ],
                        [
                            -93.6701820004199,
                            31.3871840003367
                        ],
                        [
                            -93.674116999555,
                            31.3976809998513
                        ],
                        [
                            -93.6820970001252,
                            31.3983320001877
                        ],
                        [
                            -93.6903260002174,
                            31.4065910003135
                        ],
                        [
                            -93.6958660004061,
                            31.4093919999702
                        ],
                        [
                            -93.7048789997726,
                            31.4108809999527
                        ],
                        [
                            -93.7046779999311,
                            31.4189000001519
                        ],
                        [
                            -93.6925260003994,
                            31.4382010003656
                        ],
                        [
                            -93.6932720000388,
                            31.4400779997132
                        ],
                        [
                            -93.709511999765,
                            31.4420250002596
                        ],
                        [
                            -93.7039339999415,
                            31.4554929997862
                        ],
                        [
                            -93.7091279996254,
                            31.4567459996996
                        ],
                        [
                            -93.7099700004227,
                            31.4527600000774
                        ],
                        [
                            -93.7167499999953,
                            31.4575480001347
                        ],
                        [
                            -93.7219310001588,
                            31.4534469999998
                        ],
                        [
                            -93.7315129996128,
                            31.4574739997458
                        ],
                        [
                            -93.7418640000691,
                            31.4658720001214
                        ],
                        [
                            -93.7494760003933,
                            31.4686899998127
                        ],
                        [
                            -93.7455420001847,
                            31.480395999736
                        ],
                        [
                            -93.7480029995842,
                            31.4816479998753
                        ],
                        [
                            -93.7513679997053,
                            31.4790719998408
                        ],
                        [
                            -93.7523870000546,
                            31.4827399996283
                        ],
                        [
                            -93.7451520001974,
                            31.4856380002905
                        ],
                        [
                            -93.7418940002064,
                            31.4835370001463
                        ],
                        [
                            -93.7371680003276,
                            31.4846219997603
                        ],
                        [
                            -93.7353149995769,
                            31.4890739999711
                        ],
                        [
                            -93.7313460001065,
                            31.487274000216
                        ],
                        [
                            -93.7225959996074,
                            31.4927689997439
                        ],
                        [
                            -93.7219410002046,
                            31.4942829996782
                        ],
                        [
                            -93.7240810001121,
                            31.4964009996621
                        ],
                        [
                            -93.7234469997272,
                            31.5009379999854
                        ],
                        [
                            -93.7174849997642,
                            31.5014959997018
                        ],
                        [
                            -93.7195669999453,
                            31.5069209997645
                        ],
                        [
                            -93.7138860001896,
                            31.5086399999014
                        ],
                        [
                            -93.7110459997751,
                            31.5121609997787
                        ],
                        [
                            -93.7147029999742,
                            31.5141560002411
                        ],
                        [
                            -93.735237999584,
                            31.5134960003226
                        ],
                        [
                            -93.7426930004476,
                            31.5156939997485
                        ],
                        [
                            -93.7444480003906,
                            31.5178529999937
                        ],
                        [
                            -93.7405290002507,
                            31.5181420000214
                        ],
                        [
                            -93.7426520004397,
                            31.5265599998662
                        ],
                        [
                            -93.7469010002926,
                            31.5279399999465
                        ],
                        [
                            -93.7474369998698,
                            31.5306819996278
                        ],
                        [
                            -93.7518890001122,
                            31.5274750002829
                        ],
                        [
                            -93.7547000002145,
                            31.5298649998171
                        ],
                        [
                            -93.7581110003663,
                            31.5298719997999
                        ],
                        [
                            -93.7576130004239,
                            31.527553999783
                        ],
                        [
                            -93.7538599999653,
                            31.5253310002668
                        ],
                        [
                            -93.7600620001279,
                            31.5239330003593
                        ],
                        [
                            -93.7808350002874,
                            31.5253839996337
                        ],
                        [
                            -93.78768699983,
                            31.5273440002183
                        ],
                        [
                            -93.7902979999157,
                            31.5307730000986
                        ],
                        [
                            -93.7980869997918,
                            31.5340440003387
                        ],
                        [
                            -93.7992059997002,
                            31.5380750003538
                        ],
                        [
                            -93.8033590001919,
                            31.5387869999263
                        ],
                        [
                            -93.7995360003114,
                            31.5429769999602
                        ],
                        [
                            -93.8083050001788,
                            31.5463830002074
                        ],
                        [
                            -93.8102500000926,
                            31.5498780000618
                        ],
                        [
                            -93.813973000414,
                            31.5505559999032
                        ],
                        [
                            -93.8185820001169,
                            31.5548259996765
                        ],
                        [
                            -93.8211069999889,
                            31.5604709999365
                        ],
                        [
                            -93.8183629998338,
                            31.5601639997906
                        ],
                        [
                            -93.8168599997859,
                            31.5630839996945
                        ],
                        [
                            -93.8215639999234,
                            31.5636959996195
                        ],
                        [
                            -93.8229580001914,
                            31.5681300001837
                        ],
                        [
                            -93.8191489996563,
                            31.5689299997902
                        ],
                        [
                            -93.818433999979,
                            31.5709430001465
                        ],
                        [
                            -93.8271500001428,
                            31.5784770001879
                        ],
                        [
                            -93.830362999928,
                            31.5793550003564
                        ],
                        [
                            -93.8349180001026,
                            31.5862119997197
                        ],
                        [
                            -93.8394160003758,
                            31.599199999809
                        ],
                        [
                            -93.8376060001812,
                            31.6018260003393
                        ],
                        [
                            -93.8391300001455,
                            31.60354000034
                        ],
                        [
                            -93.8380570002679,
                            31.6067949999777
                        ],
                        [
                            -93.8331029998851,
                            31.6090379998829
                        ],
                        [
                            -93.8278520002997,
                            31.6165509999995
                        ],
                        [
                            -93.8258539997841,
                            31.6159099999099
                        ],
                        [
                            -93.8270830001957,
                            31.6128569997275
                        ],
                        [
                            -93.8187169998362,
                            31.6145560000416
                        ],
                        [
                            -93.8187870001564,
                            31.6166029997261
                        ],
                        [
                            -93.8271700002343,
                            31.620276000249
                        ],
                        [
                            -93.817155000237,
                            31.6218719999412
                        ],
                        [
                            -93.8169870000075,
                            31.6251750001489
                        ],
                        [
                            -93.8203439997327,
                            31.6270890001766
                        ],
                        [
                            -93.8208480004211,
                            31.6290200000797
                        ],
                        [
                            -93.8159550001377,
                            31.6322789999459
                        ],
                        [
                            -93.8180620004333,
                            31.6355679999321
                        ],
                        [
                            -93.8163690004145,
                            31.6392579996549
                        ],
                        [
                            -93.8180370003189,
                            31.6478919997103
                        ],
                        [
                            -93.8167879998159,
                            31.6489489997281
                        ],
                        [
                            -93.8132340004472,
                            31.6464910003715
                        ],
                        [
                            -93.8115160003141,
                            31.6474350000941
                        ],
                        [
                            -93.8127880003833,
                            31.6504410001534
                        ],
                        [
                            -93.8194859999402,
                            31.6526499998918
                        ],
                        [
                            -93.8256610003386,
                            31.6610219997313
                        ],
                        [
                            -93.8264620002297,
                            31.6669189997678
                        ],
                        [
                            -93.8220509999951,
                            31.67396699996
                        ],
                        [
                            -93.8174249996754,
                            31.6721460001858
                        ],
                        [
                            -93.8166510004468,
                            31.6680099997376
                        ],
                        [
                            -93.8124329996578,
                            31.6702449997979
                        ],
                        [
                            -93.8162849998506,
                            31.6753599996918
                        ],
                        [
                            -93.8044620002068,
                            31.6840889999801
                        ],
                        [
                            -93.8051440002722,
                            31.6869569996511
                        ],
                        [
                            -93.794462000278,
                            31.703320000066
                        ],
                        [
                            -93.7974070002746,
                            31.7056149996856
                        ],
                        [
                            -93.809798000181,
                            31.7030820001958
                        ],
                        [
                            -93.811801999646,
                            31.707484999819
                        ],
                        [
                            -93.8166940001045,
                            31.7079170001961
                        ],
                        [
                            -93.8124770000387,
                            31.7152459998851
                        ],
                        [
                            -93.8149299999407,
                            31.7286880003466
                        ],
                        [
                            -93.8245790002401,
                            31.7343969997814
                        ],
                        [
                            -93.8316979997464,
                            31.7455139999549
                        ],
                        [
                            -93.8352570000363,
                            31.7472360003058
                        ],
                        [
                            -93.8368839999328,
                            31.7501709998517
                        ],
                        [
                            -93.8316219995785,
                            31.7545920000139
                        ],
                        [
                            -93.8298209996047,
                            31.7606189996347
                        ],
                        [
                            -93.8275409999551,
                            31.7608849998821
                        ],
                        [
                            -93.8264610004048,
                            31.7591929999238
                        ],
                        [
                            -93.8268100003842,
                            31.7637770002509
                        ],
                        [
                            -93.8295870001513,
                            31.7642039997953
                        ],
                        [
                            -93.8253280002527,
                            31.767170000136
                        ],
                        [
                            -93.8225980003413,
                            31.7735589997646
                        ],
                        [
                            -93.823442999715,
                            31.7750980001868
                        ],
                        [
                            -93.8274510004417,
                            31.7777409997897
                        ],
                        [
                            -93.8331199996035,
                            31.7775219999506
                        ],
                        [
                            -93.8308490001748,
                            31.7790940002375
                        ],
                        [
                            -93.8317320000816,
                            31.7809140001509
                        ],
                        [
                            -93.8376930002198,
                            31.7808170000958
                        ],
                        [
                            -93.8377779997103,
                            31.7837080002057
                        ],
                        [
                            -93.8358550004361,
                            31.7852160001315
                        ],
                        [
                            -93.8375559999524,
                            31.7897920001711
                        ],
                        [
                            -93.8356129996885,
                            31.7944889996961
                        ],
                        [
                            -93.8393429996825,
                            31.8003299997987
                        ],
                        [
                            -93.8461879995525,
                            31.8020209999234
                        ],
                        [
                            -93.8476320000492,
                            31.7996350000212
                        ],
                        [
                            -93.8514809998689,
                            31.8001499996219
                        ],
                        [
                            -93.8535289997149,
                            31.8026070000876
                        ],
                        [
                            -93.8533899997978,
                            31.8054670000589
                        ],
                        [
                            -93.8593159997758,
                            31.8091150001384
                        ],
                        [
                            -93.8613839997133,
                            31.8122970001173
                        ],
                        [
                            -93.8673189999122,
                            31.8153159996628
                        ],
                        [
                            -93.870838999844,
                            31.813783000048
                        ],
                        [
                            -93.8732530002862,
                            31.8148860003
                        ],
                        [
                            -93.8709169996618,
                            31.8168370001992
                        ],
                        [
                            -93.8709369997533,
                            31.8193830002095
                        ],
                        [
                            -93.874761000357,
                            31.8216610002131
                        ],
                        [
                            -93.8746450000061,
                            31.8298880002496
                        ],
                        [
                            -93.877358000199,
                            31.8325279997508
                        ],
                        [
                            -93.8728189999179,
                            31.8363010000123
                        ],
                        [
                            -93.8749639998483,
                            31.8408679999926
                        ],
                        [
                            -93.8819140001985,
                            31.8422160002921
                        ],
                        [
                            -93.882079000055,
                            31.8440890000817
                        ],
                        [
                            -93.8789559997832,
                            31.8447669999435
                        ],
                        [
                            -93.8841169998552,
                            31.8476059998468
                        ],
                        [
                            -93.8846370004372,
                            31.8501629999177
                        ],
                        [
                            -93.8821119996669,
                            31.852319000272
                        ],
                        [
                            -93.8828780002962,
                            31.8546720002331
                        ],
                        [
                            -93.889395999928,
                            31.8559000003036
                        ],
                        [
                            -93.881894000107,
                            31.8648350001738
                        ],
                        [
                            -93.881678000197,
                            31.8685809996537
                        ],
                        [
                            -93.8848790002865,
                            31.8693719999948
                        ],
                        [
                            -93.8854149998637,
                            31.8652570001654
                        ],
                        [
                            -93.888738999977,
                            31.8694579999082
                        ],
                        [
                            -93.8934380000916,
                            31.8717310003731
                        ],
                        [
                            -93.8921149999687,
                            31.8759169996704
                        ],
                        [
                            -93.8969749997418,
                            31.875982000034
                        ],
                        [
                            -93.8993449998031,
                            31.8738760001703
                        ],
                        [
                            -93.9016760004046,
                            31.8745700000748
                        ],
                        [
                            -93.9018880001167,
                            31.8800630000697
                        ],
                        [
                            -93.8964580004312,
                            31.8862860000597
                        ],
                        [
                            -93.8971880001772,
                            31.891018999849
                        ],
                        [
                            -93.9006160000475,
                            31.8967880000445
                        ],
                        [
                            -93.9038189997868,
                            31.8957689997924
                        ],
                        [
                            -93.9030799998201,
                            31.8939220000539
                        ],
                        [
                            -93.9061880000232,
                            31.8899279998772
                        ],
                        [
                            -93.9065389996525,
                            31.8925089998483
                        ],
                        [
                            -93.9125490001945,
                            31.8901450002226
                        ],
                        [
                            -93.9125340001258,
                            31.8924689997077
                        ],
                        [
                            -93.9149989997234,
                            31.892948000345
                        ],
                        [
                            -93.9168890002839,
                            31.8898469996875
                        ],
                        [
                            -93.9196810001196,
                            31.8909630002665
                        ],
                        [
                            -93.9207110003395,
                            31.8897310000734
                        ],
                        [
                            -93.9196929998151,
                            31.8866419998144
                        ],
                        [
                            -93.9209749999301,
                            31.8858779999855
                        ],
                        [
                            -93.926209999622,
                            31.8886830002234
                        ],
                        [
                            -93.9271720000699,
                            31.8908930001685
                        ],
                        [
                            -93.9245419997176,
                            31.8929289998943
                        ],
                        [
                            -93.9255259999068,
                            31.8946150000602
                        ],
                        [
                            -93.931189999944,
                            31.8914949998827
                        ],
                        [
                            -93.9321349997751,
                            31.8936719998665
                        ],
                        [
                            -93.9251569998358,
                            31.9024749999137
                        ],
                        [
                            -93.9215940002463,
                            31.9044209998844
                        ],
                        [
                            -93.9286509998284,
                            31.9126450001105
                        ],
                        [
                            -93.9375369998716,
                            31.9092219997519
                        ],
                        [
                            -93.9535459996191,
                            31.9105629998199
                        ],
                        [
                            -93.9597679998732,
                            31.9232259998866
                        ],
                        [
                            -93.9629480000463,
                            31.92415600028
                        ],
                        [
                            -93.9690320002081,
                            31.920150999638
                        ],
                        [
                            -93.971710000241,
                            31.9203860001609
                        ],
                        [
                            -93.9774610003169,
                            31.9264190001234
                        ],
                        [
                            -93.98232100009,
                            31.939764000112
                        ],
                        [
                            -93.995712999904,
                            31.9541400001902
                        ],
                        [
                            -94.0067949999312,
                            31.9734300002714
                        ],
                        [
                            -94.0165150003758,
                            31.9811739999202
                        ],
                        [
                            -94.018513999818,
                            31.9924319997412
                        ],
                        [
                            -94.0228930002655,
                            31.9904499998859
                        ],
                        [
                            -94.0286219997018,
                            31.9962460000276
                        ],
                        [
                            -94.0390989996565,
                            31.9922860002897
                        ],
                        [
                            -94.0422410001948,
                            31.9936089998811
                        ],
                        [
                            -94.0410450002935,
                            31.9963209999022
                        ],
                        [
                            -94.0427199998706,
                            31.9992709996433
                        ],
                        [
                            -94.0427779995969,
                            32.0248310001663
                        ],
                        [
                            -94.0429640002681,
                            33.0192189997448
                        ],
                        [
                            -93.0001600000632,
                            33.0176280001804
                        ],
                        [
                            -92.0691050000679,
                            33.0081629996608
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 30.8634368,
            "name": "Louisiana",
            "intptlon": -91.7987173,
            "geo_point_2d": [
                30.9091786621,
                -91.8142984793
            ],
            "geoid": "22",
            "mtfcc": "G4000",
            "region": 3
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -91.8142984793,
                30.9091786621
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "a927660441f1cd79d669ea158da4edf4a2c003da",
        "fields": {
            "arealand": "2054785035",
            "objectid": 3,
            "basename": "Illinois",
            "stusab": "IL",
            "statens": "01779784",
            "centlon": -89.1500872,
            "state": "17",
            "gid": 37,
            "centlat": 40.100603,
            "division": 3,
            "areawater": "1911718080",
            "oid": "-1985301493",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -91.1852949996907,
                            40.6378030000053
                        ],
                        [
                            -91.1380550000956,
                            40.6608930001284
                        ],
                        [
                            -91.1239279996144,
                            40.6691519996674
                        ],
                        [
                            -91.1208200003096,
                            40.6727769998939
                        ],
                        [
                            -91.1109269996126,
                            40.7032620001627
                        ],
                        [
                            -91.1157350004056,
                            40.7251679997493
                        ],
                        [
                            -91.1104239996474,
                            40.7455279999119
                        ],
                        [
                            -91.1082000000743,
                            40.750934999969
                        ],
                        [
                            -91.098104999711,
                            40.7632329997648
                        ],
                        [
                            -91.0917030004302,
                            40.7797080003254
                        ],
                        [
                            -91.0922559997258,
                            40.7929090002431
                        ],
                        [
                            -91.0976489996014,
                            40.8055750003052
                        ],
                        [
                            -91.0969459996197,
                            40.8114029999088
                        ],
                        [
                            -91.0929930000428,
                            40.8210790002962
                        ],
                        [
                            -91.0671590002688,
                            40.8419970002691
                        ],
                        [
                            -91.056430000419,
                            40.8483870001353
                        ],
                        [
                            -91.0446529999076,
                            40.8683559997115
                        ],
                        [
                            -91.0390969998254,
                            40.8735649998354
                        ],
                        [
                            -91.0274890002667,
                            40.8791729999023
                        ],
                        [
                            -91.0215619995654,
                            40.8840210002309
                        ],
                        [
                            -91.0095360004303,
                            40.9005650001802
                        ],
                        [
                            -90.9985000004338,
                            40.9081200002466
                        ],
                        [
                            -90.9854619997238,
                            40.9121410000517
                        ],
                        [
                            -90.9653439998656,
                            40.921633000268
                        ],
                        [
                            -90.9625600004259,
                            40.9258310001523
                        ],
                        [
                            -90.959946999792,
                            40.9377360000757
                        ],
                        [
                            -90.952233000259,
                            40.9540469998092
                        ],
                        [
                            -90.9527150003078,
                            40.962086999871
                        ],
                        [
                            -90.9581419996203,
                            40.9797670001989
                        ],
                        [
                            -90.9496339998688,
                            40.9952480000178
                        ],
                        [
                            -90.9459490000806,
                            41.0064950001903
                        ],
                        [
                            -90.9453239999166,
                            41.0192790002452
                        ],
                        [
                            -90.9422530004217,
                            41.0347020002981
                        ],
                        [
                            -90.9459990003093,
                            41.0563359997223
                        ],
                        [
                            -90.9455490000475,
                            41.0617300002808
                        ],
                        [
                            -90.9493900003697,
                            41.0727830003
                        ],
                        [
                            -90.9466250002982,
                            41.0966280002158
                        ],
                        [
                            -90.9572649995612,
                            41.1110669996737
                        ],
                        [
                            -90.9659079999302,
                            41.1195590000739
                        ],
                        [
                            -90.9708559995669,
                            41.1301089997637
                        ],
                        [
                            -90.9896620000712,
                            41.1557070001875
                        ],
                        [
                            -90.9949629998853,
                            41.1606300003113
                        ],
                        [
                            -91.0075939999912,
                            41.1661870000523
                        ],
                        [
                            -91.0272309996255,
                            41.1633829999561
                        ],
                        [
                            -91.0415570002983,
                            41.1661620002567
                        ],
                        [
                            -91.055067999938,
                            41.1857890001077
                        ],
                        [
                            -91.0729839999645,
                            41.2071509999498
                        ],
                        [
                            -91.1095819997484,
                            41.2365659999236
                        ],
                        [
                            -91.1136570004223,
                            41.2414010002252
                        ],
                        [
                            -91.1141860003268,
                            41.2500289996992
                        ],
                        [
                            -91.1011419997691,
                            41.2671690001306
                        ],
                        [
                            -91.092033999968,
                            41.286910999985
                        ],
                        [
                            -91.0868799995687,
                            41.294370999811
                        ],
                        [
                            -91.0748410000148,
                            41.30557800033
                        ],
                        [
                            -91.0715550004347,
                            41.3396480001834
                        ],
                        [
                            -91.066231999981,
                            41.366367000326
                        ],
                        [
                            -91.0515799997933,
                            41.3852829997294
                        ],
                        [
                            -91.0500669996996,
                            41.4014960001338
                        ],
                        [
                            -91.0458899998168,
                            41.4140850001856
                        ],
                        [
                            -91.0371309999951,
                            41.4200169997922
                        ],
                        [
                            -91.0277870001925,
                            41.4236029999651
                        ],
                        [
                            -91.0058459997209,
                            41.426134999741
                        ],
                        [
                            -90.9829129995624,
                            41.4341709999602
                        ],
                        [
                            -90.974184999703,
                            41.4337120002729
                        ],
                        [
                            -90.9497910002277,
                            41.4241629999317
                        ],
                        [
                            -90.9292540000698,
                            41.4215440003197
                        ],
                        [
                            -90.9004710001012,
                            41.4311540000177
                        ],
                        [
                            -90.860626000197,
                            41.451393000073
                        ],
                        [
                            -90.8465580001653,
                            41.4551409998269
                        ],
                        [
                            -90.7989310000576,
                            41.4540250002777
                        ],
                        [
                            -90.7734169999508,
                            41.4508820000565
                        ],
                        [
                            -90.7459460002345,
                            41.4497300002153
                        ],
                        [
                            -90.701158999643,
                            41.4547429998195
                        ],
                        [
                            -90.6764389997545,
                            41.4608319998861
                        ],
                        [
                            -90.6558389998474,
                            41.4621319998132
                        ],
                        [
                            -90.6325380003412,
                            41.478732000008
                        ],
                        [
                            -90.6185370002568,
                            41.4850320001836
                        ],
                        [
                            -90.605936000288,
                            41.4942320002435
                        ],
                        [
                            -90.6021369997987,
                            41.5060319997315
                        ],
                        [
                            -90.5952369996772,
                            41.511032000195
                        ],
                        [
                            -90.5862360000062,
                            41.5142320001179
                        ],
                        [
                            -90.5672360002315,
                            41.5175319998605
                        ],
                        [
                            -90.5562350003951,
                            41.5242320000256
                        ],
                        [
                            -90.5409349995789,
                            41.5261330002952
                        ],
                        [
                            -90.5006329997401,
                            41.5180330001344
                        ],
                        [
                            -90.4743319999858,
                            41.519733000077
                        ],
                        [
                            -90.4614320002664,
                            41.5235329997723
                        ],
                        [
                            -90.445231,
                            41.5361330002853
                        ],
                        [
                            -90.4327310003136,
                            41.5495330000397
                        ],
                        [
                            -90.4222300000695,
                            41.5542329999459
                        ],
                        [
                            -90.4128250001675,
                            41.5653289997992
                        ],
                        [
                            -90.3979300003055,
                            41.5722329999463
                        ],
                        [
                            -90.3641279996569,
                            41.5796330001845
                        ],
                        [
                            -90.3432280001741,
                            41.5878330002294
                        ],
                        [
                            -90.3395280003173,
                            41.5986330002881
                        ],
                        [
                            -90.3434519995818,
                            41.6469589997101
                        ],
                        [
                            -90.3367289999106,
                            41.6645319997175
                        ],
                        [
                            -90.334525000429,
                            41.6795589997015
                        ],
                        [
                            -90.3302220001495,
                            41.6839539996837
                        ],
                        [
                            -90.3173149998591,
                            41.6916699997613
                        ],
                        [
                            -90.3134350000772,
                            41.6980820001977
                        ],
                        [
                            -90.3128929997539,
                            41.7075280000899
                        ],
                        [
                            -90.3174210001643,
                            41.7183329997284
                        ],
                        [
                            -90.3176680000365,
                            41.7226889998146
                        ],
                        [
                            -90.3155490000454,
                            41.7344260000082
                        ],
                        [
                            -90.3099729998717,
                            41.743420000041
                        ],
                        [
                            -90.2786330002441,
                            41.767358000092
                        ],
                        [
                            -90.2632859995722,
                            41.7721119998235
                        ],
                        [
                            -90.216888999701,
                            41.7953349999058
                        ],
                        [
                            -90.1879690003634,
                            41.8031629997764
                        ],
                        [
                            -90.1819730000652,
                            41.8070699997143
                        ],
                        [
                            -90.1809539997159,
                            41.8093539999863
                        ],
                        [
                            -90.1818890003996,
                            41.827754999995
                        ],
                        [
                            -90.1839730002306,
                            41.8330699999234
                        ],
                        [
                            -90.1819010000952,
                            41.8432160002538
                        ],
                        [
                            -90.1730090002041,
                            41.8573930002736
                        ],
                        [
                            -90.1732369998098,
                            41.8646099998412
                        ],
                        [
                            -90.1700409997431,
                            41.8764389997463
                        ],
                        [
                            -90.1570189998249,
                            41.898018999752
                        ],
                        [
                            -90.1535840002819,
                            41.9066139997959
                        ],
                        [
                            -90.15160000001,
                            41.9310020001245
                        ],
                        [
                            -90.1638469999764,
                            41.9449339999667
                        ],
                        [
                            -90.1641349998565,
                            41.9561779999787
                        ],
                        [
                            -90.1538279997811,
                            41.9741210000964
                        ],
                        [
                            -90.1462249996778,
                            41.9813290001004
                        ],
                        [
                            -90.1460330000572,
                            41.9881389998268
                        ],
                        [
                            -90.1406130004174,
                            41.9959990000399
                        ],
                        [
                            -90.1400610000483,
                            42.0032519998222
                        ],
                        [
                            -90.1423060004361,
                            42.0118389997355
                        ],
                        [
                            -90.1480959999718,
                            42.0200139999144
                        ],
                        [
                            -90.1515790000936,
                            42.0306330003306
                        ],
                        [
                            -90.1644849996609,
                            42.0421050000551
                        ],
                        [
                            -90.1668530000723,
                            42.0549100000957
                        ],
                        [
                            -90.1655550000638,
                            42.0626379998513
                        ],
                        [
                            -90.1683579997701,
                            42.0757790000488
                        ],
                        [
                            -90.1634050001105,
                            42.087613000018
                        ],
                        [
                            -90.1617790000389,
                            42.0968360001255
                        ],
                        [
                            -90.1613260003024,
                            42.1097210003081
                        ],
                        [
                            -90.1628949995743,
                            42.1167180002949
                        ],
                        [
                            -90.170409999814,
                            42.125020999849
                        ],
                        [
                            -90.1904520004026,
                            42.1257789998693
                        ],
                        [
                            -90.2014039998352,
                            42.1309369997544
                        ],
                        [
                            -90.2053599997851,
                            42.1390790000769
                        ],
                        [
                            -90.2082599995757,
                            42.1509900002289
                        ],
                        [
                            -90.2161070000765,
                            42.1567300002657
                        ],
                        [
                            -90.2501289999349,
                            42.1714689998495
                        ],
                        [
                            -90.2690800002041,
                            42.1744999998433
                        ],
                        [
                            -90.2821730002674,
                            42.178846000238
                        ],
                        [
                            -90.2984420003059,
                            42.1875759997689
                        ],
                        [
                            -90.3177740003417,
                            42.1937889999078
                        ],
                        [
                            -90.3282730000377,
                            42.2010469999991
                        ],
                        [
                            -90.3569639999448,
                            42.2054450000373
                        ],
                        [
                            -90.3751290003916,
                            42.2148110000805
                        ],
                        [
                            -90.3911080000018,
                            42.2254729999833
                        ],
                        [
                            -90.4006529996459,
                            42.2392930000768
                        ],
                        [
                            -90.4193260000808,
                            42.2544670001421
                        ],
                        [
                            -90.4308840003091,
                            42.2782300000141
                        ],
                        [
                            -90.4307350003462,
                            42.2842110001565
                        ],
                        [
                            -90.424325999596,
                            42.2933259999778
                        ],
                        [
                            -90.4189150001771,
                            42.3083279999038
                        ],
                        [
                            -90.4210469996887,
                            42.3161089996741
                        ],
                        [
                            -90.4159369996703,
                            42.322699000037
                        ],
                        [
                            -90.4190270003301,
                            42.3285050002622
                        ],
                        [
                            -90.4305460002003,
                            42.3368600000231
                        ],
                        [
                            -90.4438740004402,
                            42.3552180002216
                        ],
                        [
                            -90.4527239996001,
                            42.3593030002784
                        ],
                        [
                            -90.4626189999469,
                            42.3672530000237
                        ],
                        [
                            -90.4702730001038,
                            42.3783550002248
                        ],
                        [
                            -90.4772789996322,
                            42.3837939999581
                        ],
                        [
                            -90.4871539998875,
                            42.3851410001702
                        ],
                        [
                            -90.500128000125,
                            42.3955390000893
                        ],
                        [
                            -90.5068290000549,
                            42.3987919999694
                        ],
                        [
                            -90.5550179996791,
                            42.4161380000717
                        ],
                        [
                            -90.558167999715,
                            42.4209840001165
                        ],
                        [
                            -90.5604390000421,
                            42.4328969997384
                        ],
                        [
                            -90.5679679996272,
                            42.4403890000132
                        ],
                        [
                            -90.6063279999252,
                            42.4515050001924
                        ],
                        [
                            -90.6243279996173,
                            42.4589039997558
                        ],
                        [
                            -90.6464189998768,
                            42.4713279996724
                        ],
                        [
                            -90.6548499996353,
                            42.4797569997313
                        ],
                        [
                            -90.6567100000586,
                            42.4888350003099
                        ],
                        [
                            -90.6496119995704,
                            42.500906000154
                        ],
                        [
                            -90.6428429998685,
                            42.5084810000285
                        ],
                        [
                            -90.4017029999573,
                            42.5068889998929
                        ],
                        [
                            -90.0736700001674,
                            42.5082750003305
                        ],
                        [
                            -89.9552369997463,
                            42.5056509997906
                        ],
                        [
                            -89.6698199997799,
                            42.505043999862
                        ],
                        [
                            -88.776493000246,
                            42.4919120002119
                        ],
                        [
                            -88.6289970003722,
                            42.4950449996741
                        ],
                        [
                            -88.138878000445,
                            42.4956039998572
                        ],
                        [
                            -87.961771999874,
                            42.4947560000858
                        ],
                        [
                            -87.7933709995962,
                            42.4917199999595
                        ],
                        [
                            -87.3750580000652,
                            42.4938150003252
                        ],
                        [
                            -87.0199350003255,
                            42.4934979997221
                        ],
                        [
                            -87.1500089999173,
                            42.0000330000599
                        ],
                        [
                            -87.1484310004246,
                            42.0000140001194
                        ],
                        [
                            -87.207774000046,
                            41.7609559999281
                        ],
                        [
                            -87.5236610001518,
                            41.7599069999997
                        ],
                        [
                            -87.5267569997611,
                            41.3083250002567
                        ],
                        [
                            -87.5261679995821,
                            40.5057609997726
                        ],
                        [
                            -87.5317589998244,
                            40.1442729997032
                        ],
                        [
                            -87.5332690004434,
                            39.8750360000227
                        ],
                        [
                            -87.5316459998465,
                            39.3478879998757
                        ],
                        [
                            -87.5372710004241,
                            39.3520889996709
                        ],
                        [
                            -87.5440130003618,
                            39.3529069997558
                        ],
                        [
                            -87.5481569997344,
                            39.3498799999719
                        ],
                        [
                            -87.5508320002925,
                            39.3423780003286
                        ],
                        [
                            -87.5543999999049,
                            39.3404880002099
                        ],
                        [
                            -87.5606919995808,
                            39.3388510000859
                        ],
                        [
                            -87.5721319998083,
                            39.3414439998883
                        ],
                        [
                            -87.5783309995979,
                            39.3403430001247
                        ],
                        [
                            -87.5890839997371,
                            39.3338309997535
                        ],
                        [
                            -87.6059200002132,
                            39.3110759998433
                        ],
                        [
                            -87.6205890001194,
                            39.307045999674
                        ],
                        [
                            -87.6209389999238,
                            39.3034070000659
                        ],
                        [
                            -87.6177860004131,
                            39.3012569998069
                        ],
                        [
                            -87.6053479997527,
                            39.303217000246
                        ],
                        [
                            -87.5979460003892,
                            39.2994790002571
                        ],
                        [
                            -87.5975449996329,
                            39.2963879996758
                        ],
                        [
                            -87.6087530000568,
                            39.2866889999141
                        ],
                        [
                            -87.6100500002404,
                            39.2822320000375
                        ],
                        [
                            -87.6085429999945,
                            39.2771869999978
                        ],
                        [
                            -87.6013929996277,
                            39.2743899998848
                        ],
                        [
                            -87.6055429997464,
                            39.2611220001625
                        ],
                        [
                            -87.5946840002003,
                            39.248741999809
                        ],
                        [
                            -87.5903149997985,
                            39.2475659996883
                        ],
                        [
                            -87.5841770001083,
                            39.2540040002543
                        ],
                        [
                            -87.5806520001536,
                            39.2552370002368
                        ],
                        [
                            -87.5747909995746,
                            39.2551289997084
                        ],
                        [
                            -87.5715860001854,
                            39.2522869998189
                        ],
                        [
                            -87.5730740001647,
                            39.2466929999075
                        ],
                        [
                            -87.579318999985,
                            39.2435519997193
                        ],
                        [
                            -87.5753730000809,
                            39.2283549997214
                        ],
                        [
                            -87.5745579999461,
                            39.2184040000387
                        ],
                        [
                            -87.5770290002897,
                            39.2111229997456
                        ],
                        [
                            -87.5849770001745,
                            39.2052759997928
                        ],
                        [
                            -87.5857250003621,
                            39.2003959998588
                        ],
                        [
                            -87.5886140002821,
                            39.1978240002393
                        ],
                        [
                            -87.6016310001774,
                            39.1924929998325
                        ],
                        [
                            -87.6051729998505,
                            39.1858970003384
                        ],
                        [
                            -87.6078790003708,
                            39.18446300022
                        ],
                        [
                            -87.6130639998339,
                            39.1841800003008
                        ],
                        [
                            -87.6185510003192,
                            39.1864039999451
                        ],
                        [
                            -87.6203999999735,
                            39.1851950003087
                        ],
                        [
                            -87.6213969996832,
                            39.1788680003127
                        ],
                        [
                            -87.619618000349,
                            39.1709750000733
                        ],
                        [
                            -87.6364899999119,
                            39.1688330002796
                        ],
                        [
                            -87.6404340001662,
                            39.1667270000757
                        ],
                        [
                            -87.6415489998766,
                            39.1604720003365
                        ],
                        [
                            -87.6293770002533,
                            39.1580229997958
                        ],
                        [
                            -87.6277129996486,
                            39.1549059998432
                        ],
                        [
                            -87.6333480002718,
                            39.1530679999851
                        ],
                        [
                            -87.6374430001389,
                            39.1567730001698
                        ],
                        [
                            -87.6421749998655,
                            39.157096999734
                        ],
                        [
                            -87.6527820004149,
                            39.1494350001951
                        ],
                        [
                            -87.6532009998162,
                            39.1439930003088
                        ],
                        [
                            -87.6587450002029,
                            39.1359969998112
                        ],
                        [
                            -87.6552389996165,
                            39.1322829998313
                        ],
                        [
                            -87.643144999811,
                            39.1285619999048
                        ],
                        [
                            -87.6421699998427,
                            39.1232119996585
                        ],
                        [
                            -87.635018999651,
                            39.1221240002568
                        ],
                        [
                            -87.635903000281,
                            39.1183289999234
                        ],
                        [
                            -87.6407610004043,
                            39.1143719997633
                        ],
                        [
                            -87.6504340000949,
                            39.1164949997958
                        ],
                        [
                            -87.6520829997327,
                            39.1155429996841
                        ],
                        [
                            -87.655041999973,
                            39.1109210001671
                        ],
                        [
                            -87.6527959997603,
                            39.1068390001702
                        ],
                        [
                            -87.6470699997987,
                            39.1042679997444
                        ],
                        [
                            -87.6385849996135,
                            39.1058940000024
                        ],
                        [
                            -87.6156840001404,
                            39.1033159996699
                        ],
                        [
                            -87.6139960001446,
                            39.1009950001635
                        ],
                        [
                            -87.614309000139,
                            39.0977779997083
                        ],
                        [
                            -87.6203869995547,
                            39.091521999809
                        ],
                        [
                            -87.6202579996833,
                            39.0865720002938
                        ],
                        [
                            -87.6141710000468,
                            39.0817840002211
                        ],
                        [
                            -87.601101000448,
                            39.0814049997427
                        ],
                        [
                            -87.596373000021,
                            39.0796389998218
                        ],
                        [
                            -87.5915149998977,
                            39.0731809997423
                        ],
                        [
                            -87.5835460000965,
                            39.0679469998479
                        ],
                        [
                            -87.5787020002169,
                            39.0616980000518
                        ],
                        [
                            -87.5725879999179,
                            39.0572860001716
                        ],
                        [
                            -87.5722899999921,
                            39.0505869997842
                        ],
                        [
                            -87.5803729995961,
                            39.040706999769
                        ],
                        [
                            -87.5750269995762,
                            39.0340619999557
                        ],
                        [
                            -87.569874999725,
                            39.0231050002383
                        ],
                        [
                            -87.5696959996248,
                            39.0194130002677
                        ],
                        [
                            -87.573761000253,
                            39.0086839998985
                        ],
                        [
                            -87.5791170003186,
                            39.0016070003098
                        ],
                        [
                            -87.5765649997842,
                            38.9970270000977
                        ],
                        [
                            -87.5682050001707,
                            38.993705000339
                        ],
                        [
                            -87.561976000244,
                            38.9941979997528
                        ],
                        [
                            -87.5604270001652,
                            38.9924249999849
                        ],
                        [
                            -87.5639660003636,
                            38.9888020002107
                        ],
                        [
                            -87.5745350003799,
                            38.9915180001843
                        ],
                        [
                            -87.5780190003267,
                            38.9906469998737
                        ],
                        [
                            -87.5783189999023,
                            38.9887860002096
                        ],
                        [
                            -87.5756049998844,
                            38.9861660002863
                        ],
                        [
                            -87.5294959998933,
                            38.971924999944
                        ],
                        [
                            -87.5243349998213,
                            38.9629640002207
                        ],
                        [
                            -87.5121869995892,
                            38.9544169999464
                        ],
                        [
                            -87.5135979995756,
                            38.9487950000919
                        ],
                        [
                            -87.5217649997435,
                            38.941117000015
                        ],
                        [
                            -87.5166580000982,
                            38.9334800000662
                        ],
                        [
                            -87.5188259995948,
                            38.9232049997659
                        ],
                        [
                            -87.5206340001396,
                            38.9216649999585
                        ],
                        [
                            -87.5260579999773,
                            38.9213549997952
                        ],
                        [
                            -87.527715000011,
                            38.9192320000438
                        ],
                        [
                            -87.5260329998629,
                            38.9136060003121
                        ],
                        [
                            -87.5276449996908,
                            38.9076880001033
                        ],
                        [
                            -87.5243139999049,
                            38.9051940001977
                        ],
                        [
                            -87.5177319998007,
                            38.905672000192
                        ],
                        [
                            -87.5168310003505,
                            38.9036970001997
                        ],
                        [
                            -87.5336950004158,
                            38.900957000229
                        ],
                        [
                            -87.5365910000085,
                            38.895565999721
                        ],
                        [
                            -87.534342000321,
                            38.8912960001444
                        ],
                        [
                            -87.5359549999739,
                            38.8869930000531
                        ],
                        [
                            -87.5391219997282,
                            38.8961189997325
                        ],
                        [
                            -87.5440889996315,
                            38.8950929998725
                        ],
                        [
                            -87.5442920000211,
                            38.8889530000688
                        ],
                        [
                            -87.5382799998293,
                            38.8857990002112
                        ],
                        [
                            -87.5349189999061,
                            38.8788739999617
                        ],
                        [
                            -87.5470639997652,
                            38.8759570000489
                        ],
                        [
                            -87.5476110001114,
                            38.8725159997825
                        ],
                        [
                            -87.54264199966,
                            38.8652810002223
                        ],
                        [
                            -87.5457189999011,
                            38.8630299999798
                        ],
                        [
                            -87.5520659998286,
                            38.8645110001701
                        ],
                        [
                            -87.5533939999744,
                            38.8624099996755
                        ],
                        [
                            -87.5520280001938,
                            38.8603179997969
                        ],
                        [
                            -87.540309000307,
                            38.8547150003346
                        ],
                        [
                            -87.526729000172,
                            38.8503619999297
                        ],
                        [
                            -87.5266949998368,
                            38.8385780000606
                        ],
                        [
                            -87.5222119996322,
                            38.8304829999618
                        ],
                        [
                            -87.5216810000779,
                            38.8265760001441
                        ],
                        [
                            -87.5273419997421,
                            38.8181209997953
                        ],
                        [
                            -87.5192199997801,
                            38.8104780001121
                        ],
                        [
                            -87.5082110004461,
                            38.7957159998814
                        ],
                        [
                            -87.5011230000036,
                            38.7913799999393
                        ],
                        [
                            -87.4955090001951,
                            38.7846259998019
                        ],
                        [
                            -87.4957390003488,
                            38.7803029997513
                        ],
                        [
                            -87.498587000261,
                            38.7764799998398
                        ],
                        [
                            -87.5043009996286,
                            38.7738460001435
                        ],
                        [
                            -87.5131370003415,
                            38.7731959999307
                        ],
                        [
                            -87.5148610003223,
                            38.770503000063
                        ],
                        [
                            -87.5103300004372,
                            38.7671810000084
                        ],
                        [
                            -87.5004939996417,
                            38.7691759998457
                        ],
                        [
                            -87.4969059999378,
                            38.7668179999764
                        ],
                        [
                            -87.498947999936,
                            38.7577740001707
                        ],
                        [
                            -87.5040460002588,
                            38.7485070000107
                        ],
                        [
                            -87.495798999725,
                            38.7406989997277
                        ],
                        [
                            -87.5061719999226,
                            38.7337250003452
                        ],
                        [
                            -87.5085150002197,
                            38.7257680001286
                        ],
                        [
                            -87.5167069996037,
                            38.7163319999828
                        ],
                        [
                            -87.5195190004291,
                            38.710455000145
                        ],
                        [
                            -87.5196099997674,
                            38.6971979997719
                        ],
                        [
                            -87.5312309997449,
                            38.6840360000323
                        ],
                        [
                            -87.5455380001511,
                            38.6776130001471
                        ],
                        [
                            -87.5793969998028,
                            38.6724750000159
                        ],
                        [
                            -87.5936780002698,
                            38.6674020001541
                        ],
                        [
                            -87.600180000008,
                            38.6624620002111
                        ],
                        [
                            -87.6028630000637,
                            38.6563629996788
                        ],
                        [
                            -87.6029500001024,
                            38.6512360000205
                        ],
                        [
                            -87.6058170002811,
                            38.6465180000366
                        ],
                        [
                            -87.6173150002349,
                            38.6421570000497
                        ],
                        [
                            -87.620119999591,
                            38.6394890001742
                        ],
                        [
                            -87.6203820004302,
                            38.6343070001724
                        ],
                        [
                            -87.6159429997082,
                            38.6279520001068
                        ],
                        [
                            -87.6155850004062,
                            38.6250470002148
                        ],
                        [
                            -87.6170430002483,
                            38.6221950003054
                        ],
                        [
                            -87.6223750000246,
                            38.6188729996922
                        ],
                        [
                            -87.620532000218,
                            38.6121650000422
                        ],
                        [
                            -87.6260809997292,
                            38.6089049999477
                        ],
                        [
                            -87.6273479997756,
                            38.6054399997108
                        ],
                        [
                            -87.6241430003864,
                            38.5969550002059
                        ],
                        [
                            -87.6194430004468,
                            38.5948240000655
                        ],
                        [
                            -87.6124389996699,
                            38.5946420001455
                        ],
                        [
                            -87.6108860002916,
                            38.592632000063
                        ],
                        [
                            -87.6119599999941,
                            38.5892480000152
                        ],
                        [
                            -87.6228970002564,
                            38.5861979999947
                        ],
                        [
                            -87.6348229998324,
                            38.5926920000315
                        ],
                        [
                            -87.6383929999929,
                            38.5922000002137
                        ],
                        [
                            -87.6402099998602,
                            38.5897949999578
                        ],
                        [
                            -87.6402600000889,
                            38.5858700000814
                        ],
                        [
                            -87.638247000403,
                            38.5762730003434
                        ],
                        [
                            -87.641389000043,
                            38.572934000043
                        ],
                        [
                            -87.6511069999395,
                            38.5690259999332
                        ],
                        [
                            -87.6527449997066,
                            38.5644590001465
                        ],
                        [
                            -87.6502139999869,
                            38.5584210001393
                        ],
                        [
                            -87.6507040004317,
                            38.5562399997902
                        ],
                        [
                            -87.6654350002621,
                            38.549946000024
                        ],
                        [
                            -87.6700709997292,
                            38.5452370000328
                        ],
                        [
                            -87.6687879997893,
                            38.5410780002711
                        ],
                        [
                            -87.6609779999968,
                            38.5321450000153
                        ],
                        [
                            -87.6561440001629,
                            38.5216680001969
                        ],
                        [
                            -87.6453709999322,
                            38.5138350002157
                        ],
                        [
                            -87.6467190001695,
                            38.5077670002958
                        ],
                        [
                            -87.652364999765,
                            38.503218999967
                        ],
                        [
                            -87.6581779997652,
                            38.5016689997483
                        ],
                        [
                            -87.6637010002355,
                            38.5029310003169
                        ],
                        [
                            -87.6718979996423,
                            38.5076110000819
                        ],
                        [
                            -87.6823240004417,
                            38.4946180002023
                        ],
                        [
                            -87.6931880000107,
                            38.4880379997012
                        ],
                        [
                            -87.7140470003839,
                            38.4798799998835
                        ],
                        [
                            -87.7400240002731,
                            38.4792230003083
                        ],
                        [
                            -87.7446439998467,
                            38.4772659997261
                        ],
                        [
                            -87.7500280003998,
                            38.4714269997262
                        ],
                        [
                            -87.7561789996104,
                            38.4625379997792
                        ],
                        [
                            -87.7559309999133,
                            38.4558759996845
                        ],
                        [
                            -87.751477000021,
                            38.4542129998805
                        ],
                        [
                            -87.7405249996902,
                            38.4547160000155
                        ],
                        [
                            -87.7357290003895,
                            38.4529860000879
                        ],
                        [
                            -87.731974000281,
                            38.4507799998255
                        ],
                        [
                            -87.7301339999492,
                            38.4465180002458
                        ],
                        [
                            -87.7306989998387,
                            38.4429079998095
                        ],
                        [
                            -87.73881199958,
                            38.438929000178
                        ],
                        [
                            -87.7410400002493,
                            38.435576000146
                        ],
                        [
                            -87.7452539999421,
                            38.4089960003301
                        ],
                        [
                            -87.7799960003989,
                            38.3708420001368
                        ],
                        [
                            -87.7860930000812,
                            38.3676489999438
                        ],
                        [
                            -87.8060750003954,
                            38.363143000165
                        ],
                        [
                            -87.8137909995782,
                            38.3537920002918
                        ],
                        [
                            -87.8227210000024,
                            38.3469120001666
                        ],
                        [
                            -87.832722999581,
                            38.3248529996498
                        ],
                        [
                            -87.8333440004453,
                            38.315649000018
                        ],
                        [
                            -87.830714000093,
                            38.3030730001093
                        ],
                        [
                            -87.83152300038,
                            38.2967269999099
                        ],
                        [
                            -87.8364950003061,
                            38.2854859997253
                        ],
                        [
                            -87.8409840003585,
                            38.280104999772
                        ],
                        [
                            -87.8511299998771,
                            38.275120000318
                        ],
                        [
                            -87.8566049997685,
                            38.2755219997332
                        ],
                        [
                            -87.8617740002364,
                            38.2786370003323
                        ],
                        [
                            -87.8662480002202,
                            38.2899799996825
                        ],
                        [
                            -87.8604529997633,
                            38.3053720001562
                        ],
                        [
                            -87.864270999621,
                            38.310703000176
                        ],
                        [
                            -87.870537000256,
                            38.3122199998245
                        ],
                        [
                            -87.879388999964,
                            38.3065069999281
                        ],
                        [
                            -87.8878490000349,
                            38.2852990001758
                        ],
                        [
                            -87.8970670003391,
                            38.2801339998779
                        ],
                        [
                            -87.906841000312,
                            38.2696340000348
                        ],
                        [
                            -87.9101200002194,
                            38.2681079998692
                        ],
                        [
                            -87.9170920003109,
                            38.2697010000991
                        ],
                        [
                            -87.9194699998698,
                            38.2745469997204
                        ],
                        [
                            -87.9177109997288,
                            38.2793510000032
                        ],
                        [
                            -87.9085290003079,
                            38.290956999997
                        ],
                        [
                            -87.9078659996108,
                            38.2931759999743
                        ],
                        [
                            -87.9095360000633,
                            38.2960610000821
                        ],
                        [
                            -87.914812999588,
                            38.2990050000187
                        ],
                        [
                            -87.9214230001796,
                            38.2993429999668
                        ],
                        [
                            -87.9348440003059,
                            38.2951450001888
                        ],
                        [
                            -87.9382439996888,
                            38.2902789997826
                        ],
                        [
                            -87.9402849998621,
                            38.2810639998079
                        ],
                        [
                            -87.9502980002096,
                            38.2767920000766
                        ],
                        [
                            -87.9521250001227,
                            38.2737630002257
                        ],
                        [
                            -87.950651000387,
                            38.2668189999516
                        ],
                        [
                            -87.9489030001167,
                            38.2641160001735
                        ],
                        [
                            -87.9420669995693,
                            38.2603010000324
                        ],
                        [
                            -87.9430299998421,
                            38.2577899998044
                        ],
                        [
                            -87.9576100000598,
                            38.2500460000057
                        ],
                        [
                            -87.9588829999539,
                            38.2466659998248
                        ],
                        [
                            -87.9579919996512,
                            38.2400960000039
                        ],
                        [
                            -87.9602250003434,
                            38.2371180002862
                        ],
                        [
                            -87.9689680002715,
                            38.2373890003106
                        ],
                        [
                            -87.9722589998744,
                            38.2470390001923
                        ],
                        [
                            -87.9818679999909,
                            38.2564439996992
                        ],
                        [
                            -87.9861090003462,
                            38.2580579996557
                        ],
                        [
                            -87.9880779996511,
                            38.2567170001368
                        ],
                        [
                            -87.9830580000444,
                            38.2500460000057
                        ],
                        [
                            -87.9797890001828,
                            38.2416609997186
                        ],
                        [
                            -87.9801580002537,
                            38.2361149997196
                        ],
                        [
                            -87.9848380001018,
                            38.2286739996574
                        ],
                        [
                            -87.9856270002973,
                            38.2179929997073
                        ],
                        [
                            -87.9839729997383,
                            38.2091069996624
                        ],
                        [
                            -87.9774939995663,
                            38.1997829996643
                        ],
                        [
                            -87.969865000422,
                            38.1917860003384
                        ],
                        [
                            -87.959499999722,
                            38.1843759996974
                        ],
                        [
                            -87.9371619995903,
                            38.172189000346
                        ],
                        [
                            -87.9337589998344,
                            38.1643510001687
                        ],
                        [
                            -87.9352340002932,
                            38.1593149997921
                        ],
                        [
                            -87.9342989996095,
                            38.156459000129
                        ],
                        [
                            -87.9312110003962,
                            38.156347999773
                        ],
                        [
                            -87.9259630002855,
                            38.1593419996929
                        ],
                        [
                            -87.9245129999411,
                            38.1620249997289
                        ],
                        [
                            -87.9258960003384,
                            38.1664729997661
                        ],
                        [
                            -87.9252300001665,
                            38.1706340002448
                        ],
                        [
                            -87.9217200002805,
                            38.1738099999301
                        ],
                        [
                            -87.9172899997794,
                            38.1741689999144
                        ],
                        [
                            -87.9130879997821,
                            38.1715300003185
                        ],
                        [
                            -87.9107920002391,
                            38.1676660002963
                        ],
                        [
                            -87.9109239995852,
                            38.162557000122
                        ],
                        [
                            -87.9177190001247,
                            38.1558890003253
                        ],
                        [
                            -87.9278989999785,
                            38.1516460003018
                        ],
                        [
                            -87.9258109999496,
                            38.1436329997313
                        ],
                        [
                            -87.9294229999429,
                            38.1407270001187
                        ],
                        [
                            -87.9351349996607,
                            38.139427999887
                        ],
                        [
                            -87.9454719998733,
                            38.1266160001687
                        ],
                        [
                            -87.9548580004071,
                            38.1326220000402
                        ],
                        [
                            -87.9663930001708,
                            38.1325060002442
                        ],
                        [
                            -87.9698689997217,
                            38.1302630002618
                        ],
                        [
                            -87.9742719995603,
                            38.1219809999683
                        ],
                        [
                            -87.9738320002426,
                            38.1133960001598
                        ],
                        [
                            -87.9752990003055,
                            38.1100529997793
                        ],
                        [
                            -87.9830680000902,
                            38.1071389997276
                        ],
                        [
                            -87.998224000068,
                            38.108557000295
                        ],
                        [
                            -88.0131180001051,
                            38.103527000202
                        ],
                        [
                            -88.0159159997885,
                            38.0998179999145
                        ],
                        [
                            -88.016152999615,
                            38.0939309997684
                        ],
                        [
                            -88.0131249997778,
                            38.0868080001969
                        ],
                        [
                            -88.0101429999714,
                            38.084381000343
                        ],
                        [
                            -88.0026350003026,
                            38.0844900002706
                        ],
                        [
                            -87.9688129995625,
                            38.0959319999764
                        ],
                        [
                            -87.960677000255,
                            38.1001909997496
                        ],
                        [
                            -87.9566640004037,
                            38.0862020000116
                        ],
                        [
                            -87.9672259998489,
                            38.067105000333
                        ],
                        [
                            -87.9787349996734,
                            38.0598150000969
                        ],
                        [
                            -88.0013299997231,
                            38.051544999958
                        ],
                        [
                            -88.030032000399,
                            38.0521450001293
                        ],
                        [
                            -88.0378320001458,
                            38.0502450000461
                        ],
                        [
                            -88.0421320000523,
                            38.0463449997397
                        ],
                        [
                            -88.0415320000027,
                            38.0373450002221
                        ],
                        [
                            -88.034132000289,
                            38.0320449999981
                        ],
                        [
                            -88.0277309999348,
                            38.0317449998915
                        ],
                        [
                            -88.0176310004469,
                            38.0347450001482
                        ],
                        [
                            -88.0121310004412,
                            38.0338450003074
                        ],
                        [
                            -88.0079310000938,
                            38.0308449999835
                        ],
                        [
                            -88.0086309997026,
                            38.0275450003025
                        ],
                        [
                            -88.0169309999398,
                            38.0240449998702
                        ],
                        [
                            -88.0210309998298,
                            38.0193449998304
                        ],
                        [
                            -88.0258310002268,
                            38.0072449996756
                        ],
                        [
                            -88.0228639995906,
                            37.9979829997487
                        ],
                        [
                            -88.012574000132,
                            37.977061999648
                        ],
                        [
                            -88.0129289999592,
                            37.9665439999925
                        ],
                        [
                            -88.0163109997987,
                            37.9615740002061
                        ],
                        [
                            -88.0208679996232,
                            37.9593510002266
                        ],
                        [
                            -88.0319890000084,
                            37.9585900001723
                        ],
                        [
                            -88.0367929997051,
                            37.9567929996814
                        ],
                        [
                            -88.0361249998834,
                            37.9427450001277
                        ],
                        [
                            -88.0303400003706,
                            37.9310449999859
                        ],
                        [
                            -88.0340650003418,
                            37.9261590001662
                        ],
                        [
                            -88.0396439999903,
                            37.9243090002062
                        ],
                        [
                            -88.0449890001853,
                            37.9250989999654
                        ],
                        [
                            -88.0571270003717,
                            37.9341509997822
                        ],
                        [
                            -88.0675609997704,
                            37.9302139998058
                        ],
                        [
                            -88.0694680000494,
                            37.9257039997425
                        ],
                        [
                            -88.0651899998842,
                            37.9196840000647
                        ],
                        [
                            -88.0592089996546,
                            37.9171890001181
                        ],
                        [
                            -88.0329439998853,
                            37.9136600001696
                        ],
                        [
                            -88.0225129999614,
                            37.914756000173
                        ],
                        [
                            -88.0200600000594,
                            37.9129000003518
                        ],
                        [
                            -88.0178160003948,
                            37.902696000257
                        ],
                        [
                            -88.013451000191,
                            37.8939509996945
                        ],
                        [
                            -88.0170730002301,
                            37.8892220000671
                        ],
                        [
                            -88.0211170000435,
                            37.8880570001153
                        ],
                        [
                            -88.0312769998058,
                            37.8929660000087
                        ],
                        [
                            -88.0553730002536,
                            37.8912380003541
                        ],
                        [
                            -88.0714489999482,
                            37.8958129997196
                        ],
                        [
                            -88.0862369996801,
                            37.9057130002391
                        ],
                        [
                            -88.0956619996736,
                            37.9058080001801
                        ],
                        [
                            -88.0977599997482,
                            37.904026000272
                        ],
                        [
                            -88.0978919999927,
                            37.9015170000386
                        ],
                        [
                            -88.0935209999411,
                            37.8919519999641
                        ],
                        [
                            -88.0843849996527,
                            37.885582000135
                        ],
                        [
                            -88.0785289999948,
                            37.8791179996783
                        ],
                        [
                            -88.0650359998984,
                            37.8531720001088
                        ],
                        [
                            -88.0534810000432,
                            37.846315999679
                        ],
                        [
                            -88.0314190000961,
                            37.8412029998062
                        ],
                        [
                            -88.0282170001816,
                            37.8385850002778
                        ],
                        [
                            -88.0259209997401,
                            37.8326940000094
                        ],
                        [
                            -88.0277889996611,
                            37.8282980000956
                        ],
                        [
                            -88.0315329998989,
                            37.8250919999124
                        ],
                        [
                            -88.0438309999188,
                            37.8215249999077
                        ],
                        [
                            -88.0610120001765,
                            37.8230150002732
                        ],
                        [
                            -88.0764269997221,
                            37.8302389998297
                        ],
                        [
                            -88.0819319997507,
                            37.8306820000311
                        ],
                        [
                            -88.0876250001003,
                            37.8281950001855
                        ],
                        [
                            -88.0908839999162,
                            37.8244679996787
                        ],
                        [
                            -88.091766999823,
                            37.820913000198
                        ],
                        [
                            -88.0900389996442,
                            37.8167709997651
                        ],
                        [
                            -88.0770290003199,
                            37.8049929999525
                        ],
                        [
                            -88.0689220004264,
                            37.800982999967
                        ],
                        [
                            -88.0596620002894,
                            37.8011959996562
                        ],
                        [
                            -88.0457530002664,
                            37.8081290002086
                        ],
                        [
                            -88.0376229999085,
                            37.8098639997615
                        ],
                        [
                            -88.0298180001388,
                            37.8062739997948
                        ],
                        [
                            -88.0280160003401,
                            37.7991879997146
                        ],
                        [
                            -88.0362620001507,
                            37.7914260003249
                        ],
                        [
                            -88.0424650001383,
                            37.767317999703
                        ],
                        [
                            -88.0491829997866,
                            37.7550729997958
                        ],
                        [
                            -88.0593330004015,
                            37.7429670000428
                        ],
                        [
                            -88.0723109999387,
                            37.7335199996497
                        ],
                        [
                            -88.0864629996359,
                            37.7284080001487
                        ],
                        [
                            -88.1022460003259,
                            37.7179149997905
                        ],
                        [
                            -88.1221509997488,
                            37.7099579998699
                        ],
                        [
                            -88.1319299997446,
                            37.6978389997605
                        ],
                        [
                            -88.1343690003012,
                            37.6914169997123
                        ],
                        [
                            -88.1443240000241,
                            37.6838040003177
                        ],
                        [
                            -88.1510709999847,
                            37.676113999916
                        ],
                        [
                            -88.160100000143,
                            37.659467999939
                        ],
                        [
                            -88.1571459999257,
                            37.6333410001049
                        ],
                        [
                            -88.1422550002617,
                            37.6040020003177
                        ],
                        [
                            -88.1401939999969,
                            37.5872329997786
                        ],
                        [
                            -88.1335099997854,
                            37.5744170002441
                        ],
                        [
                            -88.1234229998181,
                            37.569329000117
                        ],
                        [
                            -88.1059050001748,
                            37.5563709999329
                        ],
                        [
                            -88.0896949996876,
                            37.5361490002739
                        ],
                        [
                            -88.0744399999755,
                            37.5303870002156
                        ],
                        [
                            -88.0690179997876,
                            37.5253240001853
                        ],
                        [
                            -88.062971000334,
                            37.5150220000199
                        ],
                        [
                            -88.0611679998121,
                            37.5042690002507
                        ],
                        [
                            -88.0654300000838,
                            37.4887599999783
                        ],
                        [
                            -88.0684379998295,
                            37.4854490002933
                        ],
                        [
                            -88.073065999799,
                            37.4857179996532
                        ],
                        [
                            -88.0740059996072,
                            37.4818749999609
                        ],
                        [
                            -88.0809529995844,
                            37.4737989997421
                        ],
                        [
                            -88.0858829996777,
                            37.4714120001756
                        ],
                        [
                            -88.0934620003899,
                            37.4736699996786
                        ],
                        [
                            -88.1287100002868,
                            37.4703220000408
                        ],
                        [
                            -88.1343860000196,
                            37.4716619999998
                        ],
                        [
                            -88.1977139999032,
                            37.4601549996625
                        ],
                        [
                            -88.2286200000608,
                            37.4568190000885
                        ],
                        [
                            -88.2529919997948,
                            37.4569770000195
                        ],
                        [
                            -88.2816309998233,
                            37.4527289997061
                        ],
                        [
                            -88.3119739997412,
                            37.4410010001753
                        ],
                        [
                            -88.3185399999518,
                            37.43561899994
                        ],
                        [
                            -88.3236330002518,
                            37.4339920002361
                        ],
                        [
                            -88.3337329997396,
                            37.4268100001812
                        ],
                        [
                            -88.3483980003461,
                            37.4109599999604
                        ],
                        [
                            -88.3643900003752,
                            37.4017540001263
                        ],
                        [
                            -88.3716899996315,
                            37.4028660001128
                        ],
                        [
                            -88.3771660002461,
                            37.4095849999652
                        ],
                        [
                            -88.3936279997301,
                            37.4198609998556
                        ],
                        [
                            -88.4101129996786,
                            37.4254000003438
                        ],
                        [
                            -88.4354390003629,
                            37.4183559997336
                        ],
                        [
                            -88.4509390002973,
                            37.4120279996742
                        ],
                        [
                            -88.4696210000548,
                            37.397489000177
                        ],
                        [
                            -88.4769510003466,
                            37.3856830000648
                        ],
                        [
                            -88.4827259998136,
                            37.3638959997467
                        ],
                        [
                            -88.4830949998846,
                            37.3521340000115
                        ],
                        [
                            -88.4866070003188,
                            37.3404579996443
                        ],
                        [
                            -88.5122629998176,
                            37.2970959999351
                        ],
                        [
                            -88.515757999635,
                            37.2840919997241
                        ],
                        [
                            -88.5141369995863,
                            37.2794979997257
                        ],
                        [
                            -88.5100090001072,
                            37.2755899997492
                        ],
                        [
                            -88.506797000147,
                            37.2650180001713
                        ],
                        [
                            -88.503940000014,
                            37.2646210000342
                        ],
                        [
                            -88.5092950002548,
                            37.2620670001648
                        ],
                        [
                            -88.5121540000376,
                            37.263788999972
                        ],
                        [
                            -88.5113129999636,
                            37.2613359998427
                        ],
                        [
                            -88.5070619995626,
                            37.2596889999818
                        ],
                        [
                            -88.4877939999992,
                            37.2445070002502
                        ],
                        [
                            -88.4794419998834,
                            37.2287949997999
                        ],
                        [
                            -88.4725359999141,
                            37.2205029999172
                        ],
                        [
                            -88.4577520003802,
                            37.2127699998677
                        ],
                        [
                            -88.4480160000421,
                            37.2034029996756
                        ],
                        [
                            -88.4379829996031,
                            37.1800859996654
                        ],
                        [
                            -88.4333739999001,
                            37.1631400000916
                        ],
                        [
                            -88.4246030003829,
                            37.1517640003453
                        ],
                        [
                            -88.4298770004329,
                            37.1375030002781
                        ],
                        [
                            -88.4432499999803,
                            37.1113629997496
                        ],
                        [
                            -88.4434709999132,
                            37.1087290001773
                        ],
                        [
                            -88.4406740000547,
                            37.1089930000127
                        ],
                        [
                            -88.4457759996772,
                            37.0996830000739
                        ],
                        [
                            -88.4445830001489,
                            37.0964190003102
                        ],
                        [
                            -88.4456140001938,
                            37.0933320000676
                        ],
                        [
                            -88.4585170002863,
                            37.0760979996635
                        ],
                        [
                            -88.4622820004404,
                            37.0734899998217
                        ],
                        [
                            -88.4826239997064,
                            37.0672570001101
                        ],
                        [
                            -88.4904109999327,
                            37.068578999906
                        ],
                        [
                            -88.5029719997184,
                            37.0656399997184
                        ],
                        [
                            -88.5166859997477,
                            37.0653839996719
                        ],
                        [
                            -88.5438229995531,
                            37.070103999752
                        ],
                        [
                            -88.5659599998434,
                            37.0777089998266
                        ],
                        [
                            -88.5660610001257,
                            37.07520400029
                        ],
                        [
                            -88.5732490001273,
                            37.0800009997031
                        ],
                        [
                            -88.575451999784,
                            37.0880569999285
                        ],
                        [
                            -88.5844349999116,
                            37.0962749998637
                        ],
                        [
                            -88.6037999995594,
                            37.1090520002788
                        ],
                        [
                            -88.6218450003556,
                            37.1180699997358
                        ],
                        [
                            -88.630786999577,
                            37.1211280003004
                        ],
                        [
                            -88.6440550004408,
                            37.1224430002
                        ],
                        [
                            -88.6918249997653,
                            37.1409470000501
                        ],
                        [
                            -88.7039410002104,
                            37.1430259997506
                        ],
                        [
                            -88.7062030003166,
                            37.1414930000069
                        ],
                        [
                            -88.7081099996973,
                            37.1427450000529
                        ],
                        [
                            -88.7194190004035,
                            37.140880999749
                        ],
                        [
                            -88.7329550001576,
                            37.1442910003215
                        ],
                        [
                            -88.7622450002893,
                            37.1600259997914
                        ],
                        [
                            -88.7842639996805,
                            37.175034999966
                        ],
                        [
                            -88.786919999972,
                            37.1783570003477
                        ],
                        [
                            -88.8025539998008,
                            37.1878610001703
                        ],
                        [
                            -88.8352509998864,
                            37.1965220002771
                        ],
                        [
                            -88.8748869995533,
                            37.2116559998635
                        ],
                        [
                            -88.9314239999936,
                            37.2273780003404
                        ],
                        [
                            -88.9420180001242,
                            37.2288739997262
                        ],
                        [
                            -88.9327539997892,
                            37.2252990001427
                        ],
                        [
                            -88.9418599999405,
                            37.224305000188
                        ],
                        [
                            -88.964499000371,
                            37.2260949999499
                        ],
                        [
                            -88.9729999995515,
                            37.2285309997267
                        ],
                        [
                            -88.9666340002557,
                            37.2301529996539
                        ],
                        [
                            -88.9762619997404,
                            37.2295529998531
                        ],
                        [
                            -88.9797919997179,
                            37.2261130001593
                        ],
                        [
                            -88.9881549997044,
                            37.2237319997313
                        ],
                        [
                            -88.9797280001439,
                            37.2266160000218
                        ],
                        [
                            -88.9769689999202,
                            37.2295029999522
                        ],
                        [
                            -89.0001849999358,
                            37.2247639997018
                        ],
                        [
                            -89.0131540001505,
                            37.2164780000289
                        ],
                        [
                            -89.0294650000218,
                            37.211303000007
                        ],
                        [
                            -89.0757339998465,
                            37.1754949999087
                        ],
                        [
                            -89.0867120001166,
                            37.1654510002148
                        ],
                        [
                            -89.0927550002706,
                            37.1567629999724
                        ],
                        [
                            -89.0990109999615,
                            37.140406000256
                        ],
                        [
                            -89.111409000439,
                            37.1189880003146
                        ],
                        [
                            -89.1156780003833,
                            37.1156739999903
                        ],
                        [
                            -89.1096129995897,
                            37.1229199998202
                        ],
                        [
                            -89.1256280000833,
                            37.1086300001809
                        ],
                        [
                            -89.1354039997061,
                            37.103091999756
                        ],
                        [
                            -89.1375820001467,
                            37.0981500000667
                        ],
                        [
                            -89.1420600003284,
                            37.0938060001959
                        ],
                        [
                            -89.1464949999541,
                            37.0908190003566
                        ],
                        [
                            -89.1543459997545,
                            37.0889599999807
                        ],
                        [
                            -89.1738250001034,
                            37.0663079996576
                        ],
                        [
                            -89.1798110003559,
                            37.0523640002728
                        ],
                        [
                            -89.1825680000315,
                            37.0390009998546
                        ],
                        [
                            -89.1788730001975,
                            37.0205460003325
                        ],
                        [
                            -89.1701729999271,
                            37.0070670000235
                        ],
                        [
                            -89.147161000126,
                            36.9900329997967
                        ],
                        [
                            -89.1329149998191,
                            36.9820570002717
                        ],
                        [
                            -89.1360930003425,
                            36.9803810003344
                        ],
                        [
                            -89.1498819998165,
                            36.9776359997738
                        ],
                        [
                            -89.1700089998956,
                            36.9702979997375
                        ],
                        [
                            -89.1854910002867,
                            36.9735179999985
                        ],
                        [
                            -89.1920969997821,
                            36.9799949996853
                        ],
                        [
                            -89.1950390003038,
                            36.9897680003519
                        ],
                        [
                            -89.1950290002581,
                            37.0000510000339
                        ],
                        [
                            -89.2007929998545,
                            37.0161639996525
                        ],
                        [
                            -89.205052999578,
                            37.0200570003475
                        ],
                        [
                            -89.2340530001798,
                            37.0372769999839
                        ],
                        [
                            -89.2549300000963,
                            37.0720140003566
                        ],
                        [
                            -89.2599349996344,
                            37.0640699998297
                        ],
                        [
                            -89.2803620001875,
                            37.0652219999298
                        ],
                        [
                            -89.3077259997736,
                            37.0696540000608
                        ],
                        [
                            -89.3082899998383,
                            37.0683709997056
                        ],
                        [
                            -89.3108189999082,
                            37.057897000206
                        ],
                        [
                            -89.3047520003631,
                            37.0475649997291
                        ],
                        [
                            -89.2911849997486,
                            37.0404080000901
                        ],
                        [
                            -89.2777150001168,
                            37.0361400001324
                        ],
                        [
                            -89.2600030003048,
                            37.023287999841
                        ],
                        [
                            -89.2576080001292,
                            37.0154960002138
                        ],
                        [
                            -89.2635270004345,
                            37.0000499999421
                        ],
                        [
                            -89.2695639998424,
                            36.9934010002639
                        ],
                        [
                            -89.2786280001608,
                            36.9886699999575
                        ],
                        [
                            -89.2921299995797,
                            36.9921890000073
                        ],
                        [
                            -89.322982000209,
                            37.0160899998987
                        ],
                        [
                            -89.3679520002002,
                            37.0332440000674
                        ],
                        [
                            -89.3796099999876,
                            37.0406689997313
                        ],
                        [
                            -89.3846809996479,
                            37.0482509999209
                        ],
                        [
                            -89.3851860001613,
                            37.0577480001722
                        ],
                        [
                            -89.3788889995641,
                            37.0704989997415
                        ],
                        [
                            -89.3755379996867,
                            37.0818329998805
                        ],
                        [
                            -89.3787100003623,
                            37.0945859998576
                        ],
                        [
                            -89.388049999967,
                            37.1074810001558
                        ],
                        [
                            -89.4117299995898,
                            37.1225070002876
                        ],
                        [
                            -89.4255800000615,
                            37.1382350002705
                        ],
                        [
                            -89.4352019996985,
                            37.152090000102
                        ],
                        [
                            -89.4382749997416,
                            37.1612869997507
                        ],
                        [
                            -89.4561049995543,
                            37.1881199999209
                        ],
                        [
                            -89.4618620003763,
                            37.1995169999657
                        ],
                        [
                            -89.4676309999955,
                            37.2182000000914
                        ],
                        [
                            -89.467499999576,
                            37.2218439998232
                        ],
                        [
                            -89.4583020002615,
                            37.2403679998296
                        ],
                        [
                            -89.4588269999681,
                            37.2486610001411
                        ],
                        [
                            -89.4626599998943,
                            37.2515199998515
                        ],
                        [
                            -89.4705249999384,
                            37.2533570001477
                        ],
                        [
                            -89.4899149997005,
                            37.2513150000772
                        ],
                        [
                            -89.5092619998049,
                            37.2713710001826
                        ],
                        [
                            -89.5153619998603,
                            37.2789090002299
                        ],
                        [
                            -89.5183400003671,
                            37.2854969996471
                        ],
                        [
                            -89.5179350003112,
                            37.2912500000529
                        ],
                        [
                            -89.51075999983,
                            37.3127439997175
                        ],
                        [
                            -89.4951600003365,
                            37.324795000293
                        ],
                        [
                            -89.4890050000295,
                            37.3333679996826
                        ],
                        [
                            -89.4745689997519,
                            37.3381650002847
                        ],
                        [
                            -89.447555999795,
                            37.3404749997787
                        ],
                        [
                            -89.4360400002978,
                            37.3444410003111
                        ],
                        [
                            -89.4328359998352,
                            37.3470560002541
                        ],
                        [
                            -89.4281850002994,
                            37.3561579998683
                        ],
                        [
                            -89.4205420000132,
                            37.3880069999287
                        ],
                        [
                            -89.4209389996732,
                            37.3939519999058
                        ],
                        [
                            -89.4265220004179,
                            37.40899799967
                        ],
                        [
                            -89.4397689995687,
                            37.4371999997878
                        ],
                        [
                            -89.4509689995967,
                            37.450069000059
                        ],
                        [
                            -89.475525000352,
                            37.4713879999944
                        ],
                        [
                            -89.4920510003084,
                            37.4940079996763
                        ],
                        [
                            -89.5029170004255,
                            37.5178700000796
                        ],
                        [
                            -89.5170509996811,
                            37.5372779998306
                        ],
                        [
                            -89.5219249996979,
                            37.560735000136
                        ],
                        [
                            -89.5212739995947,
                            37.5789700003346
                        ],
                        [
                            -89.519808000255,
                            37.5827469999016
                        ],
                        [
                            -89.5139430003763,
                            37.5848150000318
                        ],
                        [
                            -89.4940509995755,
                            37.5801159996697
                        ],
                        [
                            -89.4860619996828,
                            37.5808530000116
                        ],
                        [
                            -89.4775480000836,
                            37.5858850001479
                        ],
                        [
                            -89.4759320000578,
                            37.592998000082
                        ],
                        [
                            -89.4783990002034,
                            37.5988689998193
                        ],
                        [
                            -89.4857920002444,
                            37.6071569997342
                        ],
                        [
                            -89.5065629998557,
                            37.6250500002313
                        ],
                        [
                            -89.5105260003766,
                            37.6317540002814
                        ],
                        [
                            -89.5156489999155,
                            37.6364450003282
                        ],
                        [
                            -89.5177179996779,
                            37.6412170002108
                        ],
                        [
                            -89.5158599998027,
                            37.6455550000684
                        ],
                        [
                            -89.5161460000329,
                            37.6679750003036
                        ],
                        [
                            -89.5120400002952,
                            37.6809850002003
                        ],
                        [
                            -89.5120090003331,
                            37.6855250001897
                        ],
                        [
                            -89.5142549996475,
                            37.6899230001663
                        ],
                        [
                            -89.5257300000351,
                            37.6984409999851
                        ],
                        [
                            -89.5667039998935,
                            37.7071890002682
                        ],
                        [
                            -89.5833160000636,
                            37.7132610000982
                        ],
                        [
                            -89.5974810001796,
                            37.7334769998516
                        ],
                        [
                            -89.6157439996374,
                            37.7427249998166
                        ],
                        [
                            -89.6172779996475,
                            37.7497199998579
                        ],
                        [
                            -89.6263319999202,
                            37.7490460000324
                        ],
                        [
                            -89.633370000134,
                            37.7457820000889
                        ],
                        [
                            -89.6495300003925,
                            37.7454959997653
                        ],
                        [
                            -89.6633520003769,
                            37.7500519998822
                        ],
                        [
                            -89.6655459998127,
                            37.7520950001305
                        ],
                        [
                            -89.6679929998669,
                            37.7594840002749
                        ],
                        [
                            -89.6611899998297,
                            37.7757319997394
                        ],
                        [
                            -89.6603799997178,
                            37.7862960000788
                        ],
                        [
                            -89.6696440000528,
                            37.7999219998894
                        ],
                        [
                            -89.7174419998647,
                            37.8257229996574
                        ],
                        [
                            -89.7294310000882,
                            37.835113999777
                        ],
                        [
                            -89.739875000431,
                            37.8469210001476
                        ],
                        [
                            -89.7540990000982,
                            37.8463530000448
                        ],
                        [
                            -89.765200000392,
                            37.8517629999484
                        ],
                        [
                            -89.77430499982,
                            37.8521270002357
                        ],
                        [
                            -89.781990999764,
                            37.8550530000035
                        ],
                        [
                            -89.7863259998306,
                            37.8516889996624
                        ],
                        [
                            -89.7937370003133,
                            37.8570629996782
                        ],
                        [
                            -89.7975419997521,
                            37.8621279997208
                        ],
                        [
                            -89.8003529998543,
                            37.8686259998379
                        ],
                        [
                            -89.7976959997379,
                            37.8743919998865
                        ],
                        [
                            -89.7993290003805,
                            37.8815159999491
                        ],
                        [
                            -89.8136359998884,
                            37.8876959996798
                        ],
                        [
                            -89.8426380001401,
                            37.9051969998045
                        ],
                        [
                            -89.8505240001005,
                            37.9041720002996
                        ],
                        [
                            -89.8570710000447,
                            37.8992039998366
                        ],
                        [
                            -89.8618770002894,
                            37.8976839999366
                        ],
                        [
                            -89.8796879998356,
                            37.8807360003372
                        ],
                        [
                            -89.9023769995966,
                            37.8697399996572
                        ],
                        [
                            -89.9233749998869,
                            37.8706970002057
                        ],
                        [
                            -89.9386079998577,
                            37.8743039999922
                        ],
                        [
                            -89.9376899997908,
                            37.8750979998422
                        ],
                        [
                            -89.9477580003899,
                            37.8798470002814
                        ],
                        [
                            -89.9530080001503,
                            37.8840369996846
                        ],
                        [
                            -89.9605419997583,
                            37.8973690000322
                        ],
                        [
                            -89.9652850002539,
                            37.9023030001564
                        ],
                        [
                            -89.9663289998193,
                            37.9067310003259
                        ],
                        [
                            -89.9737620000432,
                            37.9180160003483
                        ],
                        [
                            -89.9746540001709,
                            37.9270740001409
                        ],
                        [
                            -89.9620570004002,
                            37.9339179999707
                        ],
                        [
                            -89.9597719998294,
                            37.9400829996829
                        ],
                        [
                            -89.9473220003718,
                            37.9402829998158
                        ],
                        [
                            -89.9373199998949,
                            37.946662999889
                        ],
                        [
                            -89.9312669996952,
                            37.9482770001559
                        ],
                        [
                            -89.9248529998205,
                            37.954905999708
                        ],
                        [
                            -89.9245969997275,
                            37.9598489996542
                        ],
                        [
                            -89.935884999619,
                            37.9595809999041
                        ],
                        [
                            -89.9406869996658,
                            37.9708740000968
                        ],
                        [
                            -89.9650459998793,
                            37.9643929999938
                        ],
                        [
                            -89.9881079999092,
                            37.9617490000717
                        ],
                        [
                            -89.9968480003626,
                            37.9632190003263
                        ],
                        [
                            -90.0079360002375,
                            37.9701099999651
                        ],
                        [
                            -90.0317949999605,
                            37.9951259998906
                        ],
                        [
                            -90.0519410003062,
                            38.0054319999219
                        ],
                        [
                            -90.0553990003137,
                            38.0119519997716
                        ],
                        [
                            -90.0593669999592,
                            38.0155429998241
                        ],
                        [
                            -90.0722829995721,
                            38.0170009996808
                        ],
                        [
                            -90.0882600004309,
                            38.0157720003245
                        ],
                        [
                            -90.1105209996714,
                            38.0265489997577
                        ],
                        [
                            -90.1261960004063,
                            38.0407060003213
                        ],
                        [
                            -90.1263019998132,
                            38.0544320003477
                        ],
                        [
                            -90.1295999999872,
                            38.0614359999185
                        ],
                        [
                            -90.1582730003508,
                            38.0749500000018
                        ],
                        [
                            -90.1634129996081,
                            38.0743459996914
                        ],
                        [
                            -90.1722200000086,
                            38.0696360001918
                        ],
                        [
                            -90.1760400004144,
                            38.0706599997142
                        ],
                        [
                            -90.2170190002957,
                            38.0934659999732
                        ],
                        [
                            -90.2425740004105,
                            38.1121250003184
                        ],
                        [
                            -90.2501180000642,
                            38.1250540003235
                        ],
                        [
                            -90.2748429999756,
                            38.1575590001297
                        ],
                        [
                            -90.2906290001403,
                            38.1703630002023
                        ],
                        [
                            -90.3003289995951,
                            38.1751389996983
                        ],
                        [
                            -90.3168760003662,
                            38.1794769997513
                        ],
                        [
                            -90.3272179997034,
                            38.1843109997508
                        ],
                        [
                            -90.3341720002516,
                            38.1898569998907
                        ],
                        [
                            -90.3523619999145,
                            38.2118279997596
                        ],
                        [
                            -90.3586279996512,
                            38.2219809998858
                        ],
                        [
                            -90.3639259999905,
                            38.236354999661
                        ],
                        [
                            -90.3724220000464,
                            38.2837249999881
                        ],
                        [
                            -90.371515999675,
                            38.3061909999677
                        ],
                        [
                            -90.3726260002609,
                            38.316161999794
                        ],
                        [
                            -90.3708190004393,
                            38.3335540001427
                        ],
                        [
                            -90.3643610001837,
                            38.3475139998785
                        ],
                        [
                            -90.3559420001207,
                            38.3604600001781
                        ],
                        [
                            -90.3506200003902,
                            38.3747620000363
                        ],
                        [
                            -90.3414459995686,
                            38.3882979997272
                        ],
                        [
                            -90.3258390004024,
                            38.4009630001206
                        ],
                        [
                            -90.3111699995979,
                            38.4156790003439
                        ],
                        [
                            -90.2968699997627,
                            38.4248079999974
                        ],
                        [
                            -90.291084000425,
                            38.4308899998028
                        ],
                        [
                            -90.2840429998382,
                            38.4516740001617
                        ],
                        [
                            -90.2785310001369,
                            38.4776820001483
                        ],
                        [
                            -90.2640560003995,
                            38.5210250002864
                        ],
                        [
                            -90.2471870003114,
                            38.5485570002833
                        ],
                        [
                            -90.2412720002039,
                            38.5531910000678
                        ],
                        [
                            -90.2323200000385,
                            38.5667199998508
                        ],
                        [
                            -90.2230599999015,
                            38.5757320002926
                        ],
                        [
                            -90.2031849997175,
                            38.5881259999229
                        ],
                        [
                            -90.1958069997451,
                            38.5945270003016
                        ],
                        [
                            -90.1852560001705,
                            38.6108150000408
                        ],
                        [
                            -90.1778969995664,
                            38.6337759998207
                        ],
                        [
                            -90.179309000276,
                            38.6524399997133
                        ],
                        [
                            -90.1878060001568,
                            38.6775899998708
                        ],
                        [
                            -90.1941000003809,
                            38.6859369996671
                        ],
                        [
                            -90.2060209999341,
                            38.6974040001915
                        ],
                        [
                            -90.2102399996497,
                            38.7044010002491
                        ],
                        [
                            -90.2129799996068,
                            38.711988000327
                        ],
                        [
                            -90.2099099999368,
                            38.7260499996848
                        ],
                        [
                            -90.2034049998255,
                            38.7348809998971
                        ],
                        [
                            -90.1977590002299,
                            38.7394820002086
                        ],
                        [
                            -90.1791129995591,
                            38.7502690002427
                        ],
                        [
                            -90.175903000147,
                            38.7550289997848
                        ],
                        [
                            -90.1749769996842,
                            38.7601289996501
                        ],
                        [
                            -90.1663640003508,
                            38.7726750001447
                        ],
                        [
                            -90.1231069995714,
                            38.7980479999995
                        ],
                        [
                            -90.1177070000231,
                            38.805748000048
                        ],
                        [
                            -90.114706999775,
                            38.8150479998291
                        ],
                        [
                            -90.1091070002102,
                            38.8374480002152
                        ],
                        [
                            -90.1094069997858,
                            38.8435480001166
                        ],
                        [
                            -90.1133269997507,
                            38.8493059999505
                        ],
                        [
                            -90.1515079999485,
                            38.8671479999424
                        ],
                        [
                            -90.1664089996583,
                            38.8763480003126
                        ],
                        [
                            -90.1869090000063,
                            38.8850480002202
                        ],
                        [
                            -90.197610000267,
                            38.8876479998369
                        ],
                        [
                            -90.2230409996349,
                            38.9073889997132
                        ],
                        [
                            -90.2502479997605,
                            38.9193440002194
                        ],
                        [
                            -90.2627919998278,
                            38.9203439997417
                        ],
                        [
                            -90.2774709997797,
                            38.9237160002197
                        ],
                        [
                            -90.3094540000129,
                            38.9241200000494
                        ],
                        [
                            -90.3335329998439,
                            38.9334890003259
                        ],
                        [
                            -90.3464419997842,
                            38.9407900001018
                        ],
                        [
                            -90.3927209996546,
                            38.959071000298
                        ],
                        [
                            -90.4075369998739,
                            38.9627060001669
                        ],
                        [
                            -90.429779999571,
                            38.9637780001614
                        ],
                        [
                            -90.4399379996835,
                            38.9673829999508
                        ],
                        [
                            -90.4512790001768,
                            38.9677310001205
                        ],
                        [
                            -90.4624110004327,
                            38.9643220003345
                        ],
                        [
                            -90.4717290002961,
                            38.9591859998971
                        ],
                        [
                            -90.483070999716,
                            38.9430700002221
                        ],
                        [
                            -90.4827250001096,
                            38.9347119999068
                        ],
                        [
                            -90.487057999628,
                            38.9258619999408
                        ],
                        [
                            -90.4996720000155,
                            38.9106050000728
                        ],
                        [
                            -90.5074509998459,
                            38.9027669999649
                        ],
                        [
                            -90.516962999878,
                            38.8988179998843
                        ],
                        [
                            -90.5385870001572,
                            38.8797419999491
                        ],
                        [
                            -90.5489169997988,
                            38.8729369997518
                        ],
                        [
                            -90.5610209996501,
                            38.8694759998762
                        ],
                        [
                            -90.5822590001399,
                            38.8687940002287
                        ],
                        [
                            -90.6247369998712,
                            38.8881879999954
                        ],
                        [
                            -90.6296679997894,
                            38.8932359997147
                        ],
                        [
                            -90.6369939998833,
                            38.9051229997821
                        ],
                        [
                            -90.647290000088,
                            38.9120950001198
                        ],
                        [
                            -90.6630179996281,
                            38.926283000329
                        ],
                        [
                            -90.6634240004073,
                            38.9355510002783
                        ],
                        [
                            -90.6680890001868,
                            38.9430029999392
                        ],
                        [
                            -90.675949000208,
                            38.9621400003472
                        ],
                        [
                            -90.6757149998563,
                            38.9810659999149
                        ],
                        [
                            -90.6781929998726,
                            38.9918510002683
                        ],
                        [
                            -90.6877190001484,
                            39.0053739999624
                        ],
                        [
                            -90.6924030001944,
                            39.0166560002613
                        ],
                        [
                            -90.7127260000921,
                            39.0497360003172
                        ],
                        [
                            -90.7136290000904,
                            39.0539769998358
                        ],
                        [
                            -90.7125410001442,
                            39.0570639996715
                        ],
                        [
                            -90.7029429998983,
                            39.064544999989
                        ],
                        [
                            -90.6982820003168,
                            39.0746489998775
                        ],
                        [
                            -90.6827439998492,
                            39.0883480002303
                        ],
                        [
                            -90.6810859999906,
                            39.1005900003334
                        ],
                        [
                            -90.686051000244,
                            39.1177850001616
                        ],
                        [
                            -90.7029229998068,
                            39.1387490000118
                        ],
                        [
                            -90.707902000304,
                            39.1508600001438
                        ],
                        [
                            -90.7104799998794,
                            39.1763660003361
                        ],
                        [
                            -90.7174040002903,
                            39.1974139997111
                        ],
                        [
                            -90.7171130000372,
                            39.2139120002099
                        ],
                        [
                            -90.7218349997181,
                            39.2241080001047
                        ],
                        [
                            -90.7215929998687,
                            39.2327300001859
                        ],
                        [
                            -90.7269809997215,
                            39.2511730000926
                        ],
                        [
                            -90.7299600000532,
                            39.2558940001562
                        ],
                        [
                            -90.7390870001208,
                            39.2618930002452
                        ],
                        [
                            -90.7515990004011,
                            39.2654320002169
                        ],
                        [
                            -90.7676480003315,
                            39.2800249996866
                        ],
                        [
                            -90.7756730002092,
                            39.2928110001498
                        ],
                        [
                            -90.7906750002013,
                            39.3029079997154
                        ],
                        [
                            -90.7934610001892,
                            39.3094979999913
                        ],
                        [
                            -90.8168510002821,
                            39.3204960000123
                        ],
                        [
                            -90.8474999996234,
                            39.3452719999429
                        ],
                        [
                            -90.893776999844,
                            39.3673430003002
                        ],
                        [
                            -90.9000950003576,
                            39.3723539998751
                        ],
                        [
                            -90.9048620002443,
                            39.379403000178
                        ],
                        [
                            -90.9209759995738,
                            39.3836870000604
                        ],
                        [
                            -90.9287450002567,
                            39.3875439997616
                        ],
                        [
                            -90.9340069997128,
                            39.3921269998699
                        ],
                        [
                            -90.9374189996895,
                            39.4008029996924
                        ],
                        [
                            -90.9407660002673,
                            39.4039839997263
                        ],
                        [
                            -90.9482990000504,
                            39.40750199972
                        ],
                        [
                            -90.9674799995751,
                            39.4119480000152
                        ],
                        [
                            -90.9776179995961,
                            39.4182900003158
                        ],
                        [
                            -90.9937889997253,
                            39.422958999954
                        ],
                        [
                            -91.0236100003097,
                            39.43869399981
                        ],
                        [
                            -91.038269999995,
                            39.4484350002176
                        ],
                        [
                            -91.0594389999896,
                            39.4688600003262
                        ],
                        [
                            -91.0624140001234,
                            39.4741219997812
                        ],
                        [
                            -91.0643049996105,
                            39.4946429996587
                        ],
                        [
                            -91.07976899956,
                            39.50772799978
                        ],
                        [
                            -91.1003070004411,
                            39.5386950002371
                        ],
                        [
                            -91.1465960003573,
                            39.5454859999129
                        ],
                        [
                            -91.153627999825,
                            39.5482480002082
                        ],
                        [
                            -91.1684189999299,
                            39.5649279996813
                        ],
                        [
                            -91.1746510002297,
                            39.5933130002963
                        ],
                        [
                            -91.1819360003157,
                            39.602676999889
                        ],
                        [
                            -91.2293170003761,
                            39.6208530001609
                        ],
                        [
                            -91.2412250004087,
                            39.6300670000449
                        ],
                        [
                            -91.2487790001082,
                            39.6408800001904
                        ],
                        [
                            -91.2604750004287,
                            39.6490240002323
                        ],
                        [
                            -91.2761400002196,
                            39.6657590000874
                        ],
                        [
                            -91.3024850003548,
                            39.679630999785
                        ],
                        [
                            -91.3053480003356,
                            39.6839569999529
                        ],
                        [
                            -91.3544729995701,
                            39.7162289999639
                        ],
                        [
                            -91.3700090003879,
                            39.7325239999974
                        ],
                        [
                            -91.3698389996102,
                            39.7457019998756
                        ],
                        [
                            -91.3651250003253,
                            39.7587229997672
                        ],
                        [
                            -91.3653959995886,
                            39.7772660002427
                        ],
                        [
                            -91.3617440003107,
                            39.7850789996658
                        ],
                        [
                            -91.3634440000021,
                            39.7928039998145
                        ],
                        [
                            -91.3764879996616,
                            39.8100330000881
                        ],
                        [
                            -91.4321580000886,
                            39.8403080000644
                        ],
                        [
                            -91.4365290001402,
                            39.8470780000221
                        ],
                        [
                            -91.4413429998826,
                            39.8619830000638
                        ],
                        [
                            -91.4466849997046,
                            39.871641999668
                        ],
                        [
                            -91.4478439997959,
                            39.877950999813
                        ],
                        [
                            -91.4435129999273,
                            39.8935830002523
                        ],
                        [
                            -91.4208779996947,
                            39.9148650003031
                        ],
                        [
                            -91.4188070002825,
                            39.9221260000598
                        ],
                        [
                            -91.4193599995781,
                            39.9277169998634
                        ],
                        [
                            -91.4257819998488,
                            39.9377650002898
                        ],
                        [
                            -91.4415599996176,
                            39.9512989996562
                        ],
                        [
                            -91.4588520002033,
                            39.979014999847
                        ],
                        [
                            -91.4653149995835,
                            39.9839949998242
                        ],
                        [
                            -91.4692470001422,
                            39.9953269999104
                        ],
                        [
                            -91.494878000425,
                            40.0364530002442
                        ],
                        [
                            -91.4896060000249,
                            40.0574349997096
                        ],
                        [
                            -91.4976629996897,
                            40.0782569996716
                        ],
                        [
                            -91.5014339996916,
                            40.0958519998463
                        ],
                        [
                            -91.5108389995936,
                            40.1261369996846
                        ],
                        [
                            -91.5115900001543,
                            40.1492689998231
                        ],
                        [
                            -91.5082240002083,
                            40.1576649998988
                        ],
                        [
                            -91.5119559998522,
                            40.1704409996755
                        ],
                        [
                            -91.5129740003765,
                            40.1810620003379
                        ],
                        [
                            -91.5110729999453,
                            40.1887940002148
                        ],
                        [
                            -91.5044769995974,
                            40.1982619999288
                        ],
                        [
                            -91.5072690003314,
                            40.209338000139
                        ],
                        [
                            -91.5042819996038,
                            40.2242989998993
                        ],
                        [
                            -91.5058280002077,
                            40.2388389996663
                        ],
                        [
                            -91.5025509999502,
                            40.2443790002477
                        ],
                        [
                            -91.4974960001834,
                            40.2482359999345
                        ],
                        [
                            -91.4905240000919,
                            40.2594979999636
                        ],
                        [
                            -91.4905249999168,
                            40.2648140001165
                        ],
                        [
                            -91.4928909997801,
                            40.2699230002301
                        ],
                        [
                            -91.4927269997485,
                            40.2782170002454
                        ],
                        [
                            -91.4824499998104,
                            40.300583000174
                        ],
                        [
                            -91.4718260004409,
                            40.3173400001736
                        ],
                        [
                            -91.4639699997193,
                            40.339596999865
                        ],
                        [
                            -91.446308000136,
                            40.3618230000682
                        ],
                        [
                            -91.4415859995568,
                            40.3653720000298
                        ],
                        [
                            -91.4266320001436,
                            40.3719880001532
                        ],
                        [
                            -91.4150510003492,
                            40.3817470000615
                        ],
                        [
                            -91.3969960004055,
                            40.3831269997272
                        ],
                        [
                            -91.384201000268,
                            40.3864299999654
                        ],
                        [
                            -91.3757119998849,
                            40.3919250000592
                        ],
                        [
                            -91.3729209998741,
                            40.3991080002648
                        ],
                        [
                            -91.3728260003379,
                            40.4142790001732
                        ],
                        [
                            -91.3809650000184,
                            40.4353949996867
                        ],
                        [
                            -91.3817690002825,
                            40.442555000138
                        ],
                        [
                            -91.3781439998704,
                            40.4563939999653
                        ],
                        [
                            -91.3664629996185,
                            40.4788690001381
                        ],
                        [
                            -91.3639100001574,
                            40.4901220003406
                        ],
                        [
                            -91.364210999558,
                            40.5000430002366
                        ],
                        [
                            -91.3690589996356,
                            40.5125319999497
                        ],
                        [
                            -91.3845309999809,
                            40.5309479998736
                        ],
                        [
                            -91.4041249999575,
                            40.5391269997769
                        ],
                        [
                            -91.4062020001158,
                            40.5426980002217
                        ],
                        [
                            -91.4063729998201,
                            40.5518309997493
                        ],
                        [
                            -91.4014820000848,
                            40.5594579997744
                        ],
                        [
                            -91.3797520003987,
                            40.5744500001306
                        ],
                        [
                            -91.3570579997165,
                            40.6042629996674
                        ],
                        [
                            -91.3487330002632,
                            40.6096949998596
                        ],
                        [
                            -91.3090769996065,
                            40.6254329998898
                        ],
                        [
                            -91.2649529997121,
                            40.6338930001657
                        ],
                        [
                            -91.2518680000448,
                            40.6381129997004
                        ],
                        [
                            -91.2184370000152,
                            40.638437000196
                        ],
                        [
                            -91.1979059997051,
                            40.6361070002497
                        ],
                        [
                            -91.1852949996907,
                            40.6378030000053
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 40.1028754,
            "name": "Illinois",
            "intptlon": -89.1526108,
            "geo_point_2d": [
                40.1242236189,
                -89.1486499157
            ],
            "geoid": "17",
            "mtfcc": "G4000",
            "region": 2
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -89.1486499157,
                40.1242236189
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "bad4e98235fe9a99675beae86439284638d931b4",
        "fields": {
            "arealand": "-812563424",
            "objectid": 34,
            "basename": "Kentucky",
            "stusab": "KY",
            "statens": "01779786",
            "centlon": -85.3018362,
            "state": "21",
            "gid": 12,
            "centlat": 37.5351927,
            "division": 6,
            "areawater": "-1906562745",
            "oid": "-1985301492",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                -88.0613319998436,
                                37.5053320003084
                            ],
                            [
                                -88.062971000334,
                                37.5150220000199
                            ],
                            [
                                -88.0721000000515,
                                37.5288110001818
                            ],
                            [
                                -88.0896949996876,
                                37.5361490002739
                            ],
                            [
                                -88.1059050001748,
                                37.5563709999329
                            ],
                            [
                                -88.1234229998181,
                                37.569329000117
                            ],
                            [
                                -88.1335099997854,
                                37.5744170002441
                            ],
                            [
                                -88.1401939999969,
                                37.5872329997786
                            ],
                            [
                                -88.1422550002617,
                                37.6040020003177
                            ],
                            [
                                -88.1571459999257,
                                37.6333410001049
                            ],
                            [
                                -88.1603460001903,
                                37.6551279999324
                            ],
                            [
                                -88.1595899996068,
                                37.6617039998765
                            ],
                            [
                                -88.1510709999847,
                                37.676113999916
                            ],
                            [
                                -88.1443240000241,
                                37.6838040003177
                            ],
                            [
                                -88.1343690003012,
                                37.6914169997123
                            ],
                            [
                                -88.1319299997446,
                                37.6978389997605
                            ],
                            [
                                -88.1221509997488,
                                37.7099579998699
                            ],
                            [
                                -88.1022460003259,
                                37.7179149997905
                            ],
                            [
                                -88.0864629996359,
                                37.7284080001487
                            ],
                            [
                                -88.0723109999387,
                                37.7335199996497
                            ],
                            [
                                -88.0593330004015,
                                37.7429670000428
                            ],
                            [
                                -88.0506719995908,
                                37.7529530001167
                            ],
                            [
                                -88.0445849999543,
                                37.7631219996987
                            ],
                            [
                                -88.041269000237,
                                37.7703890002816
                            ],
                            [
                                -88.036536999612,
                                37.7908200003466
                            ],
                            [
                                -88.0296399998636,
                                37.7984129999461
                            ],
                            [
                                -88.0154870003415,
                                37.8019480000182
                            ],
                            [
                                -87.9991479999828,
                                37.7984440000646
                            ],
                            [
                                -87.975932999792,
                                37.7878440002104
                            ],
                            [
                                -87.9599730004483,
                                37.7732440002776
                            ],
                            [
                                -87.9513850003309,
                                37.7717949997256
                            ],
                            [
                                -87.9448040000517,
                                37.7750500003474
                            ],
                            [
                                -87.9386019998891,
                                37.7878799997946
                            ],
                            [
                                -87.9352379995929,
                                37.7907859998444
                            ],
                            [
                                -87.935023000406,
                                37.7951179999611
                            ],
                            [
                                -87.932928999631,
                                37.7974559996665
                            ],
                            [
                                -87.9073750002394,
                                37.8071289999073
                            ],
                            [
                                -87.9036469998952,
                                37.8167849996824
                            ],
                            [
                                -87.9100170002872,
                                37.8430180000761
                            ],
                            [
                                -87.9157089999136,
                                37.850372000332
                            ],
                            [
                                -87.925839000437,
                                37.8572640000357
                            ],
                            [
                                -87.9363010003231,
                                37.8678730002845
                            ],
                            [
                                -87.9408599997974,
                                37.8777020000473
                            ],
                            [
                                -87.9408790000639,
                                37.8847380002538
                            ],
                            [
                                -87.9381440001297,
                                37.8912859999076
                            ],
                            [
                                -87.9088549998229,
                                37.9215010002588
                            ],
                            [
                                -87.9038639996301,
                                37.925536999854
                            ],
                            [
                                -87.897144000332,
                                37.9277559998565
                            ],
                            [
                                -87.8825580002665,
                                37.9260629997506
                            ],
                            [
                                -87.8740000002864,
                                37.9227199997521
                            ],
                            [
                                -87.8624329998373,
                                37.9119770003419
                            ],
                            [
                                -87.8569269999838,
                                37.9004710001703
                            ],
                            [
                                -87.8452239999905,
                                37.8928090001948
                            ],
                            [
                                -87.842026000274,
                                37.8883140002659
                            ],
                            [
                                -87.8401330002387,
                                37.881264000236
                            ],
                            [
                                -87.8297009995915,
                                37.876455000083
                            ],
                            [
                                -87.7921010000751,
                                37.8755760002959
                            ],
                            [
                                -87.7828960001896,
                                37.8781379996535
                            ],
                            [
                                -87.7706090000403,
                                37.8865969996522
                            ],
                            [
                                -87.7594319995785,
                                37.8918610003224
                            ],
                            [
                                -87.7365210000597,
                                37.8948879996607
                            ],
                            [
                                -87.7247989997999,
                                37.8925359998566
                            ],
                            [
                                -87.7168359998465,
                                37.8929679998552
                            ],
                            [
                                -87.6982279997089,
                                37.8982469996553
                            ],
                            [
                                -87.6871630002984,
                                37.9031649996558
                            ],
                            [
                                -87.6821310000979,
                                37.9036860001143
                            ],
                            [
                                -87.6748510000347,
                                37.9017809996726
                            ],
                            [
                                -87.6663309996894,
                                37.8956760001644
                            ],
                            [
                                -87.6634419997694,
                                37.8859129997351
                            ],
                            [
                                -87.6669039999749,
                                37.876794999801
                            ],
                            [
                                -87.6816840002091,
                                37.8562730000869
                            ],
                            [
                                -87.6820680003487,
                                37.8468220000623
                            ],
                            [
                                -87.6794060002094,
                                37.8366550002951
                            ],
                            [
                                -87.6704289999295,
                                37.8283469999473
                            ],
                            [
                                -87.6453600000616,
                                37.8262539999007
                            ],
                            [
                                -87.6271870001171,
                                37.8286939997611
                            ],
                            [
                                -87.6120639997512,
                                37.8339549999436
                            ],
                            [
                                -87.5971010001172,
                                37.8487129998001
                            ],
                            [
                                -87.5902670001179,
                                37.8585899999764
                            ],
                            [
                                -87.5911149998647,
                                37.8621340003539
                            ],
                            [
                                -87.5892019997379,
                                37.863744999828
                            ],
                            [
                                -87.586303999597,
                                37.8724929999232
                            ],
                            [
                                -87.5839250002132,
                                37.872484999731
                            ],
                            [
                                -87.5837369998923,
                                37.8780800002027
                            ],
                            [
                                -87.5871410003714,
                                37.884858000101
                            ],
                            [
                                -87.6207949999837,
                                37.9076759998228
                            ],
                            [
                                -87.6280669996509,
                                37.9228919997241
                            ],
                            [
                                -87.6276800000366,
                                37.9292299999394
                            ],
                            [
                                -87.6256620003279,
                                37.9258289999837
                            ],
                            [
                                -87.6226960004149,
                                37.9272100002401
                            ],
                            [
                                -87.6174039999234,
                                37.9382699996464
                            ],
                            [
                                -87.622359999956,
                                37.9362519997422
                            ],
                            [
                                -87.6064519995925,
                                37.9488590002053
                            ],
                            [
                                -87.6036399996653,
                                37.9582650001745
                            ],
                            [
                                -87.6029669998208,
                                37.968479999933
                            ],
                            [
                                -87.5975580000517,
                                37.9742539999005
                            ],
                            [
                                -87.5858280002943,
                                37.9752010001452
                            ],
                            [
                                -87.5768159998544,
                                37.9692649996565
                            ],
                            [
                                -87.57404599976,
                                37.9608779996566
                            ],
                            [
                                -87.5766629996935,
                                37.9523529996675
                            ],
                            [
                                -87.573604999719,
                                37.9516420002895
                            ],
                            [
                                -87.5660309999281,
                                37.9376369998573
                            ],
                            [
                                -87.5565669995765,
                                37.9286059998021
                            ],
                            [
                                -87.5321999998654,
                                37.9166319997727
                            ],
                            [
                                -87.5207449995693,
                                37.9128389999123
                            ],
                            [
                                -87.5108640003646,
                                37.9060999999624
                            ],
                            [
                                -87.4976309996609,
                                37.910698999965
                            ],
                            [
                                -87.4647939998332,
                                37.9341009997024
                            ],
                            [
                                -87.4487840002608,
                                37.9420590000456
                            ],
                            [
                                -87.429160000147,
                                37.9450480000187
                            ],
                            [
                                -87.4122319996093,
                                37.9446489999249
                            ],
                            [
                                -87.3854339997375,
                                37.9376700003101
                            ],
                            [
                                -87.3729969998004,
                                37.9321440001903
                            ],
                            [
                                -87.3740959996173,
                                37.9319069999839
                            ],
                            [
                                -87.3625039999522,
                                37.9213749998554
                            ],
                            [
                                -87.3568149998005,
                                37.9200770001634
                            ],
                            [
                                -87.3527380003752,
                                37.9160910002952
                            ],
                            [
                                -87.3385399997489,
                                37.9082919998068
                            ],
                            [
                                -87.3197540002344,
                                37.905524000096
                            ],
                            [
                                -87.2989249999984,
                                37.8962389996463
                            ],
                            [
                                -87.2797870001313,
                                37.8852390001278
                            ],
                            [
                                -87.2554999998879,
                                37.8678020003007
                            ],
                            [
                                -87.2199449998443,
                                37.8488899997693
                            ],
                            [
                                -87.2023340003147,
                                37.8441990002102
                            ],
                            [
                                -87.1758360000186,
                                37.8410170002056
                            ],
                            [
                                -87.1689910001487,
                                37.8421289996705
                            ],
                            [
                                -87.1617040004129,
                                37.8397639998726
                            ],
                            [
                                -87.155176999662,
                                37.8348740003263
                            ],
                            [
                                -87.1409670002384,
                                37.8151119999903
                            ],
                            [
                                -87.1356990000363,
                                37.8030030003288
                            ],
                            [
                                -87.1342040003843,
                                37.7929429998987
                            ],
                            [
                                -87.130224000145,
                                37.7873099997038
                            ],
                            [
                                -87.122722000324,
                                37.7838209999106
                            ],
                            [
                                -87.1110079995618,
                                37.7823539999968
                            ],
                            [
                                -87.0892789997006,
                                37.7878619996497
                            ],
                            [
                                -87.0715329995534,
                                37.7998180000103
                            ],
                            [
                                -87.0661060002409,
                                37.8070540002437
                            ],
                            [
                                -87.0658040001171,
                                37.8120719997902
                            ],
                            [
                                -87.0566639996307,
                                37.8275310001318
                            ],
                            [
                                -87.0499350001117,
                                37.8523430002503
                            ],
                            [
                                -87.0432850002355,
                                37.86855200017
                            ],
                            [
                                -87.0429889999595,
                                37.8777689998993
                            ],
                            [
                                -87.045829000374,
                                37.8868929998151
                            ],
                            [
                                -87.0458779998795,
                                37.8926459997719
                            ],
                            [
                                -87.0401629997886,
                                37.9012150002627
                            ],
                            [
                                -87.0341659996654,
                                37.9062649998464
                            ],
                            [
                                -87.0099749996814,
                                37.9198930003372
                            ],
                            [
                                -86.9765999997284,
                                37.9309650000046
                            ],
                            [
                                -86.9667739998769,
                                37.9334550001482
                            ],
                            [
                                -86.9675250004376,
                                37.9326400003529
                            ],
                            [
                                -86.9451920003287,
                                37.9334520002577
                            ],
                            [
                                -86.9198339998575,
                                37.9363899999508
                            ],
                            [
                                -86.9019830001283,
                                37.9464479997767
                            ],
                            [
                                -86.8902900001808,
                                37.9574260001984
                            ],
                            [
                                -86.8864799998208,
                                37.9630689998272
                            ],
                            [
                                -86.8611139998519,
                                37.984204999733
                            ],
                            [
                                -86.8519330002559,
                                37.9890289998022
                            ],
                            [
                                -86.8331109998581,
                                37.9944820002883
                            ],
                            [
                                -86.8245620000987,
                                37.9986370001958
                            ],
                            [
                                -86.8186720001057,
                                37.9993399998227
                            ],
                            [
                                -86.8131920001914,
                                37.9981320001363
                            ],
                            [
                                -86.7948929998503,
                                37.9891699997229
                            ],
                            [
                                -86.7871680004466,
                                37.968801000229
                            ],
                            [
                                -86.7785689995602,
                                37.957298000234
                            ],
                            [
                                -86.7799150001476,
                                37.9554619996605
                            ],
                            [
                                -86.7775140001243,
                                37.9512660000792
                            ],
                            [
                                -86.7736469998628,
                                37.9476030000924
                            ],
                            [
                                -86.7511210003085,
                                37.913355999909
                            ],
                            [
                                -86.7325630003996,
                                37.8947459999522
                            ],
                            [
                                -86.722847000153,
                                37.8926859997003
                            ],
                            [
                                -86.7174919999122,
                                37.893455000196
                            ],
                            [
                                -86.6858610000315,
                                37.9131850003177
                            ],
                            [
                                -86.6803970000108,
                                37.915076999932
                            ],
                            [
                                -86.6600349997551,
                                37.9129469999472
                            ],
                            [
                                -86.6471269996397,
                                37.9087499997016
                            ],
                            [
                                -86.6440820000841,
                                37.9025880000598
                            ],
                            [
                                -86.6442569999863,
                                37.8965170001595
                            ],
                            [
                                -86.6606530002464,
                                37.8642469999034
                            ],
                            [
                                -86.6622409997848,
                                37.8528499998181
                            ],
                            [
                                -86.6602719995815,
                                37.8467640000797
                            ],
                            [
                                -86.6552720000663,
                                37.8425209999916
                            ],
                            [
                                -86.6483929998612,
                                37.8414529997891
                            ],
                            [
                                -86.6380089997929,
                                37.8427720000132
                            ],
                            [
                                -86.6092609999845,
                                37.855297000075
                            ],
                            [
                                -86.6046990001372,
                                37.8581269998839
                            ],
                            [
                                -86.5991499997276,
                                37.8655599998036
                            ],
                            [
                                -86.5972490001948,
                                37.8705179996648
                            ],
                            [
                                -86.5992259998956,
                                37.8750839996457
                            ],
                            [
                                -86.5980979997664,
                                37.8847380002538
                            ],
                            [
                                -86.6001260004192,
                                37.9051709997363
                            ],
                            [
                                -86.5972259997302,
                                37.9129219997892
                            ],
                            [
                                -86.5884800003274,
                                37.9213460001594
                            ],
                            [
                                -86.5795490000784,
                                37.9232390001858
                            ],
                            [
                                -86.5667940001239,
                                37.9223230000896
                            ],
                            [
                                -86.5483209997055,
                                37.9179189999937
                            ],
                            [
                                -86.533846999793,
                                37.9171910000151
                            ],
                            [
                                -86.5167769998634,
                                37.9233459999672
                            ],
                            [
                                -86.5070890001042,
                                37.9299169997907
                            ],
                            [
                                -86.5071059998227,
                                37.9361369999609
                            ],
                            [
                                -86.5098309997112,
                                37.9432959999306
                            ],
                            [
                                -86.5198480002567,
                                37.9534450002821
                            ],
                            [
                                -86.5250949996441,
                                37.9680810003422
                            ],
                            [
                                -86.5251039998649,
                                38.02848699974
                            ],
                            [
                                -86.5216879996902,
                                38.0394829998557
                            ],
                            [
                                -86.5100859999793,
                                38.0448159998891
                            ],
                            [
                                -86.4699370003014,
                                38.046387000115
                            ],
                            [
                                -86.4539030004396,
                                38.0500809998103
                            ],
                            [
                                -86.4373799999579,
                                38.0616480001969
                            ],
                            [
                                -86.4326409996603,
                                38.0681050002737
                            ],
                            [
                                -86.4310280000075,
                                38.0737760000549
                            ],
                            [
                                -86.4302589999034,
                                38.0800319998817
                            ],
                            [
                                -86.4340109996388,
                                38.0864130001643
                            ],
                            [
                                -86.4541079995807,
                                38.0934469999111
                            ],
                            [
                                -86.462816000247,
                                38.0994309997219
                            ],
                            [
                                -86.4655029996023,
                                38.1043060001109
                            ],
                            [
                                -86.4665560002868,
                                38.1123949996514
                            ],
                            [
                                -86.4616859995696,
                                38.1205019996594
                            ],
                            [
                                -86.4557420000482,
                                38.1250310002236
                            ],
                            [
                                -86.4487730003297,
                                38.1273869997577
                            ],
                            [
                                -86.4323439995593,
                                38.1263359997495
                            ],
                            [
                                -86.4252259998779,
                                38.1227549996678
                            ],
                            [
                                -86.401879000341,
                                38.1043289996699
                            ],
                            [
                                -86.3961459998085,
                                38.1071520000507
                            ],
                            [
                                -86.3893920001751,
                                38.1222280000425
                            ],
                            [
                                -86.3811620002581,
                                38.1288230002589
                            ],
                            [
                                -86.3750830001191,
                                38.1306379998255
                            ],
                            [
                                -86.3482390002166,
                                38.1276540001808
                            ],
                            [
                                -86.3383709996341,
                                38.1279820000731
                            ],
                            [
                                -86.3320990000496,
                                38.1299290000573
                            ],
                            [
                                -86.3255510002806,
                                38.1358089998141
                            ],
                            [
                                -86.3211910000996,
                                38.1470050001409
                            ],
                            [
                                -86.3258740003208,
                                38.1540290000911
                            ],
                            [
                                -86.3414229997607,
                                38.1584080000355
                            ],
                            [
                                -86.3660549997857,
                                38.1615309997223
                            ],
                            [
                                -86.3723309995681,
                                38.1646059996628
                            ],
                            [
                                -86.3765729997483,
                                38.1693550002437
                            ],
                            [
                                -86.3785789997615,
                                38.1785719999872
                            ],
                            [
                                -86.3774099996244,
                                38.1885739999631
                            ],
                            [
                                -86.370952000267,
                                38.1955749998357
                            ],
                            [
                                -86.3597720003306,
                                38.1988399998127
                            ],
                            [
                                -86.3477360002514,
                                38.1953629998599
                            ],
                            [
                                -86.3186469999611,
                                38.1736350002586
                            ],
                            [
                                -86.2931959996034,
                                38.1627380003478
                            ],
                            [
                                -86.2863590001294,
                                38.1571990000682
                            ],
                            [
                                -86.2728469997664,
                                38.14083500034
                            ],
                            [
                                -86.2706049997517,
                                38.1357790002931
                            ],
                            [
                                -86.270269000191,
                                38.1297829996486
                            ],
                            [
                                -86.2790999999827,
                                38.1007540000777
                            ],
                            [
                                -86.2784310003361,
                                38.0859960002234
                            ],
                            [
                                -86.2727980002609,
                                38.0650020002785
                            ],
                            [
                                -86.2646920001924,
                                38.0548529999643
                            ],
                            [
                                -86.2503610003951,
                                38.0462060000437
                            ],
                            [
                                -86.2333390001461,
                                38.0396250000848
                            ],
                            [
                                -86.233447999926,
                                38.038180000278
                            ],
                            [
                                -86.219503999743,
                                38.027552000041
                            ],
                            [
                                -86.1780260000944,
                                38.0105110001699
                            ],
                            [
                                -86.1631859995857,
                                38.0100639999485
                            ],
                            [
                                -86.1478970004368,
                                38.0147490001412
                            ],
                            [
                                -86.1242520000757,
                                38.0161980000752
                            ],
                            [
                                -86.1041669998294,
                                38.0126270001543
                            ],
                            [
                                -86.0946020000938,
                                38.008809000143
                            ],
                            [
                                -86.0793890002146,
                                38.0006250001078
                            ],
                            [
                                -86.0740780003547,
                                37.9957840000925
                            ],
                            [
                                -86.0773439998433,
                                37.9974139997072
                            ],
                            [
                                -86.0644929996294,
                                37.975144999758
                            ],
                            [
                                -86.0511469998461,
                                37.9613250000346
                            ],
                            [
                                -86.043690000231,
                                37.9582549999011
                            ],
                            [
                                -86.039109999942,
                                37.9589609999897
                            ],
                            [
                                -86.0342230004047,
                                37.9652659998002
                            ],
                            [
                                -86.0351029999385,
                                37.9843580001735
                            ],
                            [
                                -86.0327820002811,
                                37.9897790002144
                            ],
                            [
                                -86.0283130003202,
                                37.9933290002605
                            ],
                            [
                                -86.0095260000824,
                                37.9984049998994
                            ],
                            [
                                -85.9774230001987,
                                38.0033880000146
                            ],
                            [
                                -85.9649069997205,
                                38.0029899999919
                            ],
                            [
                                -85.9471139997176,
                                38.0050849997629
                            ],
                            [
                                -85.9422160003097,
                                38.00726699989
                            ],
                            [
                                -85.9256540003682,
                                38.0217840000669
                            ],
                            [
                                -85.9206520003049,
                                38.0323059997422
                            ],
                            [
                                -85.913839000222,
                                38.0719609997401
                            ],
                            [
                                -85.9133479999523,
                                38.0731239997087
                            ],
                            [
                                -85.9129830000793,
                                38.071426000264
                            ],
                            [
                                -85.9049899999887,
                                38.0898179999941
                            ],
                            [
                                -85.9043880002892,
                                38.1062159998533
                            ],
                            [
                                -85.9092890000703,
                                38.142234999732
                            ],
                            [
                                -85.9062439996163,
                                38.1682609998638
                            ],
                            [
                                -85.8976770003136,
                                38.1835960000419
                            ],
                            [
                                -85.8842820001266,
                                38.1995700003112
                            ],
                            [
                                -85.8750859995636,
                                38.2075579997134
                            ],
                            [
                                -85.8516939998209,
                                38.2224450002172
                            ],
                            [
                                -85.8443810001458,
                                38.2310479998962
                            ],
                            [
                                -85.8389229999729,
                                38.2403080002157
                            ],
                            [
                                -85.8365880000717,
                                38.2640719998605
                            ],
                            [
                                -85.8292159999471,
                                38.2765079998391
                            ],
                            [
                                -85.817912999987,
                                38.2823600000408
                            ],
                            [
                                -85.7813090003552,
                                38.2885200003259
                            ],
                            [
                                -85.7714220004044,
                                38.2851230000353
                            ],
                            [
                                -85.7725689999019,
                                38.2850450001971
                            ],
                            [
                                -85.7659909999956,
                                38.2803439997888
                            ],
                            [
                                -85.7669109997124,
                                38.2765910002841
                            ],
                            [
                                -85.7614879996996,
                                38.2722669998334
                            ],
                            [
                                -85.7514750002504,
                                38.2679979997923
                            ],
                            [
                                -85.7432129996479,
                                38.2672829998163
                            ],
                            [
                                -85.6792129997447,
                                38.29800899985
                            ],
                            [
                                -85.6735670001491,
                                38.3023249998188
                            ],
                            [
                                -85.6705729997487,
                                38.3076979998022
                            ],
                            [
                                -85.6522640002602,
                                38.3287919999555
                            ],
                            [
                                -85.6443099996293,
                                38.3460169999532
                            ],
                            [
                                -85.6394840001914,
                                38.3608660002402
                            ],
                            [
                                -85.6375459999502,
                                38.3821289996856
                            ],
                            [
                                -85.6318330004075,
                                38.398427000142
                            ],
                            [
                                -85.6230510001213,
                                38.414387999919
                            ],
                            [
                                -85.6203720002636,
                                38.4234620001235
                            ],
                            [
                                -85.606953999612,
                                38.4385740003244
                            ],
                            [
                                -85.5971040003694,
                                38.4451020001071
                            ],
                            [
                                -85.583575000288,
                                38.4508240000792
                            ],
                            [
                                -85.5335449996088,
                                38.4558860000615
                            ],
                            [
                                -85.5057950002332,
                                38.4646639996814
                            ],
                            [
                                -85.4964499997074,
                                38.4699599996684
                            ],
                            [
                                -85.4873629998227,
                                38.4795029998683
                            ],
                            [
                                -85.4812209999346,
                                38.4882499998985
                            ],
                            [
                                -85.4751240002523,
                                38.5036890000324
                            ],
                            [
                                -85.4697189997828,
                                38.5088760000203
                            ],
                            [
                                -85.4600800004274,
                                38.5140490001375
                            ],
                            [
                                -85.4360459999039,
                                38.522953000264
                            ],
                            [
                                -85.4233899996836,
                                38.5318449998076
                            ],
                            [
                                -85.4170469999541,
                                38.5417260000207
                            ],
                            [
                                -85.4157449997475,
                                38.5467230000209
                            ],
                            [
                                -85.4159769995511,
                                38.5636539996868
                            ],
                            [
                                -85.4189709999515,
                                38.5713219999502
                            ],
                            [
                                -85.4363000003471,
                                38.598389999868
                            ],
                            [
                                -85.4384159999652,
                                38.603907000276
                            ],
                            [
                                -85.4397290000423,
                                38.611493000147
                            ],
                            [
                                -85.4394629999035,
                                38.6358070001472
                            ],
                            [
                                -85.4376130004243,
                                38.650235999682
                            ],
                            [
                                -85.4387049996702,
                                38.6588569996672
                            ],
                            [
                                -85.4445550003786,
                                38.6696710003012
                            ],
                            [
                                -85.455042000379,
                                38.68126299992
                            ],
                            [
                                -85.4569129997747,
                                38.6887170001741
                            ],
                            [
                                -85.4518710004267,
                                38.7102370002262
                            ],
                            [
                                -85.4515340001428,
                                38.7078220001575
                            ],
                            [
                                -85.4501840002557,
                                38.7119310000431
                            ],
                            [
                                -85.4354090000444,
                                38.7290939998988
                            ],
                            [
                                -85.422500999929,
                                38.7346880002382
                            ],
                            [
                                -85.4102380000691,
                                38.7371349999205
                            ],
                            [
                                -85.3722650002838,
                                38.7304499998798
                            ],
                            [
                                -85.3633349998596,
                                38.7305010000885
                            ],
                            [
                                -85.3375680000327,
                                38.7341609998858
                            ],
                            [
                                -85.3347479997097,
                                38.7359540001281
                            ],
                            [
                                -85.3016990001698,
                                38.741957000123
                            ],
                            [
                                -85.2862600003347,
                                38.7422090001412
                            ],
                            [
                                -85.2684410003927,
                                38.7400909996925
                            ],
                            [
                                -85.258946000079,
                                38.7377700000575
                            ],
                            [
                                -85.2465559999975,
                                38.7317679998922
                            ],
                            [
                                -85.2261589995816,
                                38.7057840001441
                            ],
                            [
                                -85.2130529999979,
                                38.6954530001046
                            ],
                            [
                                -85.188817999633,
                                38.6876020001827
                            ],
                            [
                                -85.1730480002601,
                                38.6880220001819
                            ],
                            [
                                -85.1514010004146,
                                38.6938579997341
                            ],
                            [
                                -85.1374070000028,
                                38.6998669999261
                            ],
                            [
                                -85.1074149997142,
                                38.7206780001521
                            ],
                            [
                                -85.1015809997977,
                                38.7265309996945
                            ],
                            [
                                -85.0730360003788,
                                38.7410399998498
                            ],
                            [
                                -85.0523999995884,
                                38.7481399998396
                            ],
                            [
                                -85.0406210003255,
                                38.7554490001847
                            ],
                            [
                                -85.0133389998568,
                                38.7658830000908
                            ],
                            [
                                -84.9919270001879,
                                38.7785369999986
                            ],
                            [
                                -84.9782590001894,
                                38.7795089999646
                            ],
                            [
                                -84.9417600001397,
                                38.7756819999242
                            ],
                            [
                                -84.9176560001943,
                                38.7832519999929
                            ],
                            [
                                -84.8939580001298,
                                38.7937159997688
                            ],
                            [
                                -84.887284999789,
                                38.794776000328
                            ],
                            [
                                -84.8555979998318,
                                38.7899959999501
                            ],
                            [
                                -84.8288569998615,
                                38.7832710000286
                            ],
                            [
                                -84.8212150002985,
                                38.783128999971
                            ],
                            [
                                -84.8139379997101,
                                38.7850429998258
                            ],
                            [
                                -84.811598999611,
                                38.7919600001749
                            ],
                            [
                                -84.8139910003118,
                                38.8002169999691
                            ],
                            [
                                -84.8272090000486,
                                38.8187319996527
                            ],
                            [
                                -84.8299659997241,
                                38.8254729999698
                            ],
                            [
                                -84.8299680002723,
                                38.8305730001069
                            ],
                            [
                                -84.8231939996491,
                                38.839223000099
                            ],
                            [
                                -84.8037469999856,
                                38.8504329998104
                            ],
                            [
                                -84.7915240003087,
                                38.8599739997209
                            ],
                            [
                                -84.7857349996997,
                                38.869959999853
                            ],
                            [
                                -84.7852750002904,
                                38.880425000143
                            ],
                            [
                                -84.7931589997028,
                                38.8874179998696
                            ],
                            [
                                -84.8121600002008,
                                38.8949800000884
                            ],
                            [
                                -84.866751999829,
                                38.8988099998611
                            ],
                            [
                                -84.8707489997868,
                                38.9009049998808
                            ],
                            [
                                -84.8772759996394,
                                38.9094799998831
                            ],
                            [
                                -84.8792580002615,
                                38.9160750003097
                            ],
                            [
                                -84.8775350001055,
                                38.9206889998506
                            ],
                            [
                                -84.8666470002471,
                                38.9332260001096
                            ],
                            [
                                -84.8338279999627,
                                38.9593330000604
                            ],
                            [
                                -84.8298550002944,
                                38.9693910002333
                            ],
                            [
                                -84.8300489995648,
                                38.9725600003456
                            ],
                            [
                                -84.8385789999559,
                                38.9899019998233
                            ],
                            [
                                -84.8466559997121,
                                38.9967409996927
                            ],
                            [
                                -84.8506069996391,
                                39.004693000292
                            ],
                            [
                                -84.8718240002126,
                                39.0277559999184
                            ],
                            [
                                -84.8856589997174,
                                39.0374940002267
                            ],
                            [
                                -84.8962989998787,
                                39.0524409998325
                            ],
                            [
                                -84.8973359997713,
                                39.0574199999533
                            ],
                            [
                                -84.8890589999986,
                                39.0661259996711
                            ],
                            [
                                -84.8610190001948,
                                39.077986000028
                            ],
                            [
                                -84.8324350004179,
                                39.1004890002819
                            ],
                            [
                                -84.8128589999846,
                                39.1068309999351
                            ],
                            [
                                -84.8106789998942,
                                39.1092039998245
                            ],
                            [
                                -84.7877680003754,
                                39.1153129998819
                            ],
                            [
                                -84.7689359999318,
                                39.1366010001604
                            ],
                            [
                                -84.7610619996668,
                                39.1429050000545
                            ],
                            [
                                -84.7527339998404,
                                39.1472229996609
                            ],
                            [
                                -84.7443709998539,
                                39.1477320002691
                            ],
                            [
                                -84.7284290000536,
                                39.1431790001992
                            ],
                            [
                                -84.7180960000389,
                                39.1366160002727
                            ],
                            [
                                -84.6860729996228,
                                39.1008020002816
                            ],
                            [
                                -84.6582479999041,
                                39.0956150000101
                            ],
                            [
                                -84.6501820000186,
                                39.0914249999613
                            ],
                            [
                                -84.6328219996608,
                                39.0767080003068
                            ],
                            [
                                -84.618734000436,
                                39.0733309997459
                            ],
                            [
                                -84.6040589997837,
                                39.073699999723
                            ],
                            [
                                -84.5948990001041,
                                39.0773129998625
                            ],
                            [
                                -84.5731529996261,
                                39.0818920001981
                            ],
                            [
                                -84.5510630000899,
                                39.0994179997441
                            ],
                            [
                                -84.5403419997377,
                                39.0989980001635
                            ],
                            [
                                -84.5256259999759,
                                39.0924780000621
                            ],
                            [
                                -84.5110409997353,
                                39.0936540000943
                            ],
                            [
                                -84.4972389998425,
                                39.1002520002485
                            ],
                            [
                                -84.4852439997712,
                                39.1136999998788
                            ],
                            [
                                -84.4759090001894,
                                39.1199229999946
                            ],
                            [
                                -84.464211000219,
                                39.1220560000847
                            ],
                            [
                                -84.4524699996927,
                                39.1192330001082
                            ],
                            [
                                -84.4432199996014,
                                39.112173999837
                            ],
                            [
                                -84.4351599995636,
                                39.1003619999012
                            ],
                            [
                                -84.4337670000188,
                                39.0948629999085
                            ],
                            [
                                -84.4354919998246,
                                39.0870350001703
                            ],
                            [
                                -84.433717999615,
                                39.0842249999115
                            ],
                            [
                                -84.432731999776,
                                39.0668339998708
                            ],
                            [
                                -84.4305609999064,
                                39.0587399999023
                            ],
                            [
                                -84.4237889998314,
                                39.0528740001461
                            ],
                            [
                                -84.428564000114,
                                39.0555790001525
                            ],
                            [
                                -84.426255999977,
                                39.0530420001876
                            ],
                            [
                                -84.4095679996389,
                                39.0462480000995
                            ],
                            [
                                -84.3769130002844,
                                39.0441630000302
                            ],
                            [
                                -84.3482039999357,
                                39.0377830001404
                            ],
                            [
                                -84.3266199998394,
                                39.0272619998614
                            ],
                            [
                                -84.3138349997477,
                                39.0165880003015
                            ],
                            [
                                -84.3060740003589,
                                39.007753000283
                            ],
                            [
                                -84.2979490000238,
                                38.9909470003083
                            ],
                            [
                                -84.2957959996976,
                                38.9692089997821
                            ],
                            [
                                -84.2887769997504,
                                38.9556439996627
                            ],
                            [
                                -84.2784529999565,
                                38.9436149998796
                            ],
                            [
                                -84.2695769999591,
                                38.9361689997354
                            ],
                            [
                                -84.2604449998685,
                                38.9247150002491
                            ],
                            [
                                -84.2570389997396,
                                38.9226490000531
                            ],
                            [
                                -84.2340269999384,
                                38.8914069998071
                            ],
                            [
                                -84.2315330000286,
                                38.8725330002665
                            ],
                            [
                                -84.2336610002405,
                                38.8540000000482
                            ],
                            [
                                -84.231742000266,
                                38.8323009997211
                            ],
                            [
                                -84.224969000366,
                                38.8170369996619
                            ],
                            [
                                -84.2131149998618,
                                38.8058350003114
                            ],
                            [
                                -84.1980040000897,
                                38.8009009998133
                            ],
                            [
                                -84.1472119998775,
                                38.7928610000816
                            ],
                            [
                                -84.0918140003353,
                                38.7742330002348
                            ],
                            [
                                -84.070779000235,
                                38.7704139998052
                            ],
                            [
                                -84.0437529998594,
                                38.7706700000897
                            ],
                            [
                                -83.9779319998908,
                                38.7873240002266
                            ],
                            [
                                -83.9618419999525,
                                38.7874919999856
                            ],
                            [
                                -83.9436229999773,
                                38.7833319999616
                            ],
                            [
                                -83.9263179998711,
                                38.7740489999465
                            ],
                            [
                                -83.9286410000767,
                                38.7724260001707
                            ],
                            [
                                -83.9256609999201,
                                38.7712079999917
                            ],
                            [
                                -83.8876760004391,
                                38.7657600002314
                            ],
                            [
                                -83.8701989999055,
                                38.761532000247
                            ],
                            [
                                -83.8583159999872,
                                38.7563250003111
                            ],
                            [
                                -83.8488909999937,
                                38.7474769996827
                            ],
                            [
                                -83.8413249997003,
                                38.7233630001865
                            ],
                            [
                                -83.8361920001158,
                                38.7173009999472
                            ],
                            [
                                -83.822353000413,
                                38.7097769997598
                            ],
                            [
                                -83.7919340003271,
                                38.702254999926
                            ],
                            [
                                -83.7843859995771,
                                38.6965810003391
                            ],
                            [
                                -83.7757320002358,
                                38.6667129998339
                            ],
                            [
                                -83.7723190004341,
                                38.6584840002144
                            ],
                            [
                                -83.7691199999943,
                                38.6550750001899
                            ],
                            [
                                -83.7631959996661,
                                38.652241000311
                            ],
                            [
                                -83.749183999711,
                                38.6494320002458
                            ],
                            [
                                -83.7210230004317,
                                38.646695999768
                            ],
                            [
                                -83.7134749996817,
                                38.6416689998806
                            ],
                            [
                                -83.7038909996795,
                                38.6395249998079
                            ],
                            [
                                -83.684560000367,
                                38.6313209999137
                            ],
                            [
                                -83.6687180001257,
                                38.6265419999742
                            ],
                            [
                                -83.6566600003053,
                                38.6280620002603
                            ],
                            [
                                -83.6482860004481,
                                38.6318180001862
                            ],
                            [
                                -83.6415939998408,
                                38.6440670000764
                            ],
                            [
                                -83.6362119998358,
                                38.667928000142
                            ],
                            [
                                -83.6320609998922,
                                38.6739030000659
                            ],
                            [
                                -83.6255299998417,
                                38.6788759997066
                            ],
                            [
                                -83.6114099999315,
                                38.6846790003215
                            ],
                            [
                                -83.6017420002638,
                                38.6839829997201
                            ],
                            [
                                -83.5924240004005,
                                38.6886490001006
                            ],
                            [
                                -83.5693819995638,
                                38.692932999809
                            ],
                            [
                                -83.54522400009,
                                38.6999280003372
                            ],
                            [
                                -83.5207550002717,
                                38.7030940002733
                            ],
                            [
                                -83.5062190004349,
                                38.6999839999448
                            ],
                            [
                                -83.4906070003475,
                                38.692953999705
                            ],
                            [
                                -83.4681329997733,
                                38.6754759997789
                            ],
                            [
                                -83.4571150002185,
                                38.6739940001625
                            ],
                            [
                                -83.4453989998065,
                                38.6699900000994
                            ],
                            [
                                -83.4207009996593,
                                38.6685159997838
                            ],
                            [
                                -83.4175249996841,
                                38.6678819996705
                            ],
                            [
                                -83.419585000124,
                                38.667003999766
                            ],
                            [
                                -83.4145110000906,
                                38.666158999989
                            ],
                            [
                                -83.4098210001968,
                                38.6667940001218
                            ],
                            [
                                -83.3848579997357,
                                38.6631110000847
                            ],
                            [
                                -83.3625530001143,
                                38.6570590001729
                            ],
                            [
                                -83.3276259997095,
                                38.6378509998179
                            ],
                            [
                                -83.3218140004325,
                                38.6300300000694
                            ],
                            [
                                -83.3177270000631,
                                38.6094130001551
                            ],
                            [
                                -83.3061920002993,
                                38.5999279999267
                            ],
                            [
                                -83.2930189998701,
                                38.5967640000651
                            ],
                            [
                                -83.2880410000962,
                                38.5983489998145
                            ],
                            [
                                -83.2818620003981,
                                38.6032460002114
                            ],
                            [
                                -83.2684520001424,
                                38.615235999711
                            ],
                            [
                                -83.2648689995631,
                                38.62105800024
                            ],
                            [
                                -83.2545409995713,
                                38.6234969998101
                            ],
                            [
                                -83.2452219998831,
                                38.6287110001865
                            ],
                            [
                                -83.2301279998295,
                                38.626792999686
                            ],
                            [
                                -83.2125189999497,
                                38.6190399996595
                            ],
                            [
                                -83.2014450003184,
                                38.6167300002553
                            ],
                            [
                                -83.1568490004209,
                                38.620257999698
                            ],
                            [
                                -83.1426530003428,
                                38.6250960003433
                            ],
                            [
                                -83.1302170002306,
                                38.6382269997169
                            ],
                            [
                                -83.1258269999124,
                                38.6465219998384
                            ],
                            [
                                -83.1221710004365,
                                38.6598619996619
                            ],
                            [
                                -83.1120579996315,
                                38.6718430001396
                            ],
                            [
                                -83.1016710000885,
                                38.6774909997861
                            ],
                            [
                                -83.0845690003719,
                                38.6808010003237
                            ],
                            [
                                -83.0625790003947,
                                38.6894569998071
                            ],
                            [
                                -83.0540180000415,
                                38.6950680002252
                            ],
                            [
                                -83.0425440003772,
                                38.7078200002162
                            ],
                            [
                                -83.0378209999731,
                                38.7172940000293
                            ],
                            [
                                -83.0302029998012,
                                38.7259469998168
                            ],
                            [
                                -83.0215119997516,
                                38.7287639998142
                            ],
                            [
                                -83.0150469998233,
                                38.7289590001122
                            ],
                            [
                                -83.0136559999283,
                                38.7306619998076
                            ],
                            [
                                -83.0124530003543,
                                38.7303820002457
                            ],
                            [
                                -83.0130220004418,
                                38.7291090002487
                            ],
                            [
                                -83.0035630001132,
                                38.7298709998629
                            ],
                            [
                                -82.9814910001203,
                                38.7259960003032
                            ],
                            [
                                -82.9708119996009,
                                38.7285919996882
                            ],
                            [
                                -82.9716180004131,
                                38.7271650001411
                            ],
                            [
                                -82.9502839996638,
                                38.7397260001548
                            ],
                            [
                                -82.9340859997704,
                                38.7468610003101
                            ],
                            [
                                -82.8962929999101,
                                38.7563760002559
                            ],
                            [
                                -82.8858040002598,
                                38.7550790002955
                            ],
                            [
                                -82.8854040002267,
                                38.7527589999503
                            ],
                            [
                                -82.8797860002202,
                                38.7515839996932
                            ],
                            [
                                -82.8751740001442,
                                38.7468370001087
                            ],
                            [
                                -82.8709589997282,
                                38.7365419998646
                            ],
                            [
                                -82.8698840002008,
                                38.7272719998507
                            ],
                            [
                                -82.8774250003798,
                                38.6894080002159
                            ],
                            [
                                -82.8757010003989,
                                38.6839610000421
                            ],
                            [
                                -82.8658389996641,
                                38.6731160001691
                            ],
                            [
                                -82.8602809999321,
                                38.6626530000713
                            ],
                            [
                                -82.8568949998946,
                                38.6474049998949
                            ],
                            [
                                -82.8573469998062,
                                38.6315639998688
                            ],
                            [
                                -82.8543690001977,
                                38.6132910001797
                            ],
                            [
                                -82.8474609996803,
                                38.5953820001073
                            ],
                            [
                                -82.8422129995696,
                                38.5885770002568
                            ],
                            [
                                -82.8212050001318,
                                38.5733439999817
                            ],
                            [
                                -82.7964380003877,
                                38.5619360001037
                            ],
                            [
                                -82.781048999883,
                                38.5591619999188
                            ],
                            [
                                -82.7662639996258,
                                38.5605589999474
                            ],
                            [
                                -82.7287279996835,
                                38.5589970002962
                            ],
                            [
                                -82.6982850002065,
                                38.5437700000037
                            ],
                            [
                                -82.6892390003297,
                                38.5359250001523
                            ],
                            [
                                -82.676081999794,
                                38.5161250002844
                            ],
                            [
                                -82.6576939997644,
                                38.497425999837
                            ],
                            [
                                -82.6392220000693,
                                38.4853440002497
                            ],
                            [
                                -82.6126040001226,
                                38.4735560001359
                            ],
                            [
                                -82.6036090002994,
                                38.4595329999339
                            ],
                            [
                                -82.6003799997224,
                                38.4368729997243
                            ],
                            [
                                -82.5934799996009,
                                38.4218209999638
                            ],
                            [
                                -82.5959719998609,
                                38.4178799999761
                            ],
                            [
                                -82.5970670003782,
                                38.4108149996503
                            ],
                            [
                                -82.5945540002018,
                                38.3982689997722
                            ],
                            [
                                -82.5987329997345,
                                38.3937619998797
                            ],
                            [
                                -82.5994580003559,
                                38.3907810002104
                            ],
                            [
                                -82.5927380001594,
                                38.376806999789
                            ],
                            [
                                -82.5975939997346,
                                38.3649120000209
                            ],
                            [
                                -82.5981209999892,
                                38.3459330002943
                            ],
                            [
                                -82.5955070004288,
                                38.3424890001566
                            ],
                            [
                                -82.5891610003262,
                                38.3402600001984
                            ],
                            [
                                -82.5845879997099,
                                38.3337330001576
                            ],
                            [
                                -82.5767200001911,
                                38.3285150000502
                            ],
                            [
                                -82.5753950004184,
                                38.3229799997279
                            ],
                            [
                                -82.5717559997626,
                                38.3171949999026
                            ],
                            [
                                -82.5719230001671,
                                38.3133470002619
                            ],
                            [
                                -82.5790049998635,
                                38.3047789998151
                            ],
                            [
                                -82.5830350003316,
                                38.2968069999903
                            ],
                            [
                                -82.5795010001561,
                                38.2909869997791
                            ],
                            [
                                -82.5787859995804,
                                38.2819440003197
                            ],
                            [
                                -82.5740980002347,
                                38.2746350003227
                            ],
                            [
                                -82.5745180003593,
                                38.2645050000662
                            ],
                            [
                                -82.5833170003639,
                                38.246793000023
                            ],
                            [
                                -82.594508999996,
                                38.2455029996672
                            ],
                            [
                                -82.6046250002756,
                                38.2476620003469
                            ],
                            [
                                -82.6081279995907,
                                38.244829999706
                            ],
                            [
                                -82.6122159997851,
                                38.2362969997595
                            ],
                            [
                                -82.6086149996624,
                                38.2234989997142
                            ],
                            [
                                -82.5982950000665,
                                38.2173610000338
                            ],
                            [
                                -82.5991369999655,
                                38.1972049999134
                            ],
                            [
                                -82.6116009996668,
                                38.1713389998827
                            ],
                            [
                                -82.6193650003269,
                                38.1688769998328
                            ],
                            [
                                -82.6388549996481,
                                38.1711489996698
                            ],
                            [
                                -82.6429520000633,
                                38.1694459997628
                            ],
                            [
                                -82.6445389997769,
                                38.1652489999802
                            ],
                            [
                                -82.6386279998674,
                                38.1559149998556
                            ],
                            [
                                -82.6365730003487,
                                38.1380200000935
                            ],
                            [
                                -82.6218349999473,
                                38.1332910002491
                            ],
                            [
                                -82.6209019998117,
                                38.1227870002494
                            ],
                            [
                                -82.6192280000596,
                                38.1205399998425
                            ],
                            [
                                -82.6061609999356,
                                38.1207849998421
                            ],
                            [
                                -82.5986909999016,
                                38.1166949998082
                            ],
                            [
                                -82.5924119997461,
                                38.1104159996502
                            ],
                            [
                                -82.5870060003501,
                                38.1086639998845
                            ],
                            [
                                -82.5853419997453,
                                38.1066339998481
                            ],
                            [
                                -82.5855459999598,
                                38.0949729998862
                            ],
                            [
                                -82.5833569996485,
                                38.090032000168
                            ],
                            [
                                -82.5738530000123,
                                38.0819190002587
                            ],
                            [
                                -82.5659889997931,
                                38.0809969998657
                            ],
                            [
                                -82.5596319998199,
                                38.0727810003108
                            ],
                            [
                                -82.5498489996261,
                                38.0694520003385
                            ],
                            [
                                -82.5494999996466,
                                38.0632339997087
                            ],
                            [
                                -82.5448430002631,
                                38.0590759998368
                            ],
                            [
                                -82.5441550003499,
                                38.0525919997093
                            ],
                            [
                                -82.5370989996945,
                                38.0446509998897
                            ],
                            [
                                -82.5387180000934,
                                38.0374000000908
                            ],
                            [
                                -82.531920999904,
                                38.0292920002212
                            ],
                            [
                                -82.5263179999662,
                                38.0269709996552
                            ],
                            [
                                -82.5250690003614,
                                38.0175030000089
                            ],
                            [
                                -82.5197550001286,
                                38.0083750003475
                            ],
                            [
                                -82.5155470002836,
                                38.0066069997879
                            ],
                            [
                                -82.5191929997138,
                                38.0011310002115
                            ],
                            [
                                -82.5136359998066,
                                37.9990949999202
                            ],
                            [
                                -82.508423999681,
                                38.0015069998937
                            ],
                            [
                                -82.499859999853,
                                37.998896000209
                            ],
                            [
                                -82.4886549998022,
                                37.9989119999994
                            ],
                            [
                                -82.4860579999602,
                                37.995646999913
                            ],
                            [
                                -82.4860720002039,
                                37.9898659996697
                            ],
                            [
                                -82.4835940001876,
                                37.9838440003537
                            ],
                            [
                                -82.4721899999451,
                                37.9867100001722
                            ],
                            [
                                -82.4645659999254,
                                37.9838760001252
                            ],
                            [
                                -82.4645569997046,
                                37.9774409997171
                            ],
                            [
                                -82.4688509997633,
                                37.9732299998672
                            ],
                            [
                                -82.4812240001263,
                                37.9730720001128
                            ],
                            [
                                -82.4844310000637,
                                37.9709210001623
                            ],
                            [
                                -82.4844589996528,
                                37.9635779998231
                            ],
                            [
                                -82.4722169997093,
                                37.9605170002823
                            ],
                            [
                                -82.4717749998434,
                                37.9587389998125
                            ],
                            [
                                -82.4855590001929,
                                37.9458060000102
                            ],
                            [
                                -82.4975709999826,
                                37.9459519999909
                            ],
                            [
                                -82.4969690002832,
                                37.9420199996482
                            ],
                            [
                                -82.4892769995931,
                                37.9382169998385
                            ],
                            [
                                -82.491340000406,
                                37.9357430002699
                            ],
                            [
                                -82.4999539995644,
                                37.9368209999866
                            ],
                            [
                                -82.5021980001273,
                                37.9330519998234
                            ],
                            [
                                -82.4959409997131,
                                37.9269539996778
                            ],
                            [
                                -82.4806569996887,
                                37.926168999848
                            ],
                            [
                                -82.480762000169,
                                37.9246459997629
                            ],
                            [
                                -82.487714000169,
                                37.9199920000351
                            ],
                            [
                                -82.4883460000057,
                                37.9177239996683
                            ],
                            [
                                -82.4792890002583,
                                37.9147009997492
                            ],
                            [
                                -82.4755740003328,
                                37.9118429997549
                            ],
                            [
                                -82.4742400003393,
                                37.9073649997772
                            ],
                            [
                                -82.4764329999502,
                                37.9027250002174
                            ],
                            [
                                -82.4744069998455,
                                37.8999549998394
                            ],
                            [
                                -82.471389999879,
                                37.8990859996587
                            ],
                            [
                                -82.4683820001332,
                                37.9032069998159
                            ],
                            [
                                -82.4679650003817,
                                37.9139270001572
                            ],
                            [
                                -82.4629909999075,
                                37.9148199996738
                            ],
                            [
                                -82.4599940000324,
                                37.9095359998183
                            ],
                            [
                                -82.4518960003598,
                                37.908551000322
                            ],
                            [
                                -82.4473310001394,
                                37.9038569998104
                            ],
                            [
                                -82.4375649996641,
                                37.8997859999529
                            ],
                            [
                                -82.4320320000464,
                                37.8898760000715
                            ],
                            [
                                -82.4199290000201,
                                37.8848699997914
                            ],
                            [
                                -82.4193919997197,
                                37.8746140002161
                            ],
                            [
                                -82.4175599997837,
                                37.8702789997264
                            ],
                            [
                                -82.4075129999994,
                                37.8672969998703
                            ],
                            [
                                -82.4097080001584,
                                37.8655420001581
                            ],
                            [
                                -82.4226809996728,
                                37.863859000052
                            ],
                            [
                                -82.4246230001119,
                                37.8614490002163
                            ],
                            [
                                -82.4147090003969,
                                37.8559699997956
                            ],
                            [
                                -82.4204930000847,
                                37.8464329997945
                            ],
                            [
                                -82.4129369998371,
                                37.8448009996453
                            ],
                            [
                                -82.4086570000221,
                                37.8360690001289
                            ],
                            [
                                -82.3993459998314,
                                37.8295230001062
                            ],
                            [
                                -82.3982269999231,
                                37.8213780001483
                            ],
                            [
                                -82.4022029999644,
                                37.8129370003477
                            ],
                            [
                                -82.4013219997074,
                                37.8095120001759
                            ],
                            [
                                -82.3966219997679,
                                37.8089320002068
                            ],
                            [
                                -82.3867249997713,
                                37.8182249999073
                            ],
                            [
                                -82.3799829998335,
                                37.8111880003465
                            ],
                            [
                                -82.3766410001769,
                                37.8022069996615
                            ],
                            [
                                -82.3690370002487,
                                37.8012589997765
                            ],
                            [
                                -82.3638889996972,
                                37.7950020002965
                            ],
                            [
                                -82.3550529998827,
                                37.7933539999997
                            ],
                            [
                                -82.3490259996223,
                                37.7869330002211
                            ],
                            [
                                -82.3400860000507,
                                37.7856250000882
                            ],
                            [
                                -82.3395479999254,
                                37.7770960002402
                            ],
                            [
                                -82.3374649999193,
                                37.7749139997558
                            ],
                            [
                                -82.3238859996092,
                                37.7749950000077
                            ],
                            [
                                -82.3230579999539,
                                37.7726780000432
                            ],
                            [
                                -82.3336050002289,
                                37.7671500002066
                            ],
                            [
                                -82.333380000098,
                                37.7644139999361
                            ],
                            [
                                -82.3285200003248,
                                37.7621179998601
                            ],
                            [
                                -82.3168249998292,
                                37.7654550002961
                            ],
                            [
                                -82.3119859999725,
                                37.7643200001415
                            ],
                            [
                                -82.3112679999221,
                                37.7613309998895
                            ],
                            [
                                -82.3199509995757,
                                37.7585579999367
                            ],
                            [
                                -82.3212939997901,
                                37.7515870002077
                            ],
                            [
                                -82.327745000373,
                                37.7492160003544
                            ],
                            [
                                -82.3341740003164,
                                37.7427709997678
                            ],
                            [
                                -82.3273439996167,
                                37.7364099998696
                            ],
                            [
                                -82.3188540003069,
                                37.7336950002229
                            ],
                            [
                                -82.316105000129,
                                37.7210810000443
                            ],
                            [
                                -82.3114229997328,
                                37.7184739997024
                            ],
                            [
                                -82.3107969997439,
                                37.7132550001557
                            ],
                            [
                                -82.3076779996701,
                                37.708005999999
                            ],
                            [
                                -82.3003449999035,
                                37.7058159997319
                            ],
                            [
                                -82.2963749997099,
                                37.7021849996989
                            ],
                            [
                                -82.2966890004276,
                                37.6998590002097
                            ],
                            [
                                -82.3019579995563,
                                37.6968040000075
                            ],
                            [
                                -82.3029400000957,
                                37.6936110001448
                            ],
                            [
                                -82.2959239996232,
                                37.6861819997145
                            ],
                            [
                                -82.3040680002249,
                                37.678306999811
                            ],
                            [
                                -82.3043140002722,
                                37.6760929999013
                            ],
                            [
                                -82.2950320003939,
                                37.6780530003094
                            ],
                            [
                                -82.2945610002157,
                                37.6704329998526
                            ],
                            [
                                -82.2881189998536,
                                37.6681079996773
                            ],
                            [
                                -82.2845010000125,
                                37.6752359999866
                            ],
                            [
                                -82.2824859997785,
                                37.6759250002808
                            ],
                            [
                                -82.2713119996897,
                                37.6636170000577
                            ],
                            [
                                -82.2580680000137,
                                37.6568830001482
                            ],
                            [
                                -82.2519010000112,
                                37.6568470001375
                            ],
                            [
                                -82.2408940003271,
                                37.6615309996787
                            ],
                            [
                                -82.236066000341,
                                37.6596340000675
                            ],
                            [
                                -82.2334419998365,
                                37.6556859996773
                            ],
                            [
                                -82.2261559999257,
                                37.6529129998384
                            ],
                            [
                                -82.2241549999353,
                                37.6447130002067
                            ],
                            [
                                -82.216848000108,
                                37.6415979997247
                            ],
                            [
                                -82.220502999759,
                                37.6347689997144
                            ],
                            [
                                -82.2145950002226,
                                37.6274350002983
                            ],
                            [
                                -82.2159529996073,
                                37.6262090000724
                            ],
                            [
                                -82.2144989999632,
                                37.6254000000996
                            ],
                            [
                                -82.2048539998617,
                                37.62523499993
                            ],
                            [
                                -82.2008900004142,
                                37.6279900001259
                            ],
                            [
                                -82.1922550004411,
                                37.6256539997527
                            ],
                            [
                                -82.1872280002634,
                                37.626984000168
                            ],
                            [
                                -82.18592900043,
                                37.62891000005
                            ],
                            [
                                -82.1866990003589,
                                37.6351419999958
                            ],
                            [
                                -82.1911160004413,
                                37.6445859999838
                            ],
                            [
                                -82.1858060004063,
                                37.6485950000625
                            ],
                            [
                                -82.1750750000084,
                                37.6478319996866
                            ],
                            [
                                -82.1776359998654,
                                37.6408489998128
                            ],
                            [
                                -82.1724120000442,
                                37.6326339999236
                            ],
                            [
                                -82.1816910004478,
                                37.6271470000813
                            ],
                            [
                                -82.1827159997465,
                                37.6243699998822
                            ],
                            [
                                -82.18119799963,
                                37.6215100003354
                            ],
                            [
                                -82.1767260001944,
                                37.6181520001288
                            ],
                            [
                                -82.1689749999531,
                                37.6219069997291
                            ],
                            [
                                -82.1642459997012,
                                37.6202900002131
                            ],
                            [
                                -82.1696140003607,
                                37.6130270003224
                            ],
                            [
                                -82.1687749999365,
                                37.6090799999134
                            ],
                            [
                                -82.1658149998714,
                                37.6076929996725
                            ],
                            [
                                -82.1566829997809,
                                37.6089689997985
                            ],
                            [
                                -82.1568510000103,
                                37.592722999821
                            ],
                            [
                                -82.1483819997186,
                                37.5907200002498
                            ],
                            [
                                -82.1398849998379,
                                37.5949670002522
                            ],
                            [
                                -82.1312880003979,
                                37.5927070000799
                            ],
                            [
                                -82.1273399999457,
                                37.5865449997684
                            ],
                            [
                                -82.1244219997133,
                                37.5773579999822
                            ],
                            [
                                -82.1262400003038,
                                37.5737380000839
                            ],
                            [
                                -82.129120999828,
                                37.5721380003053
                            ],
                            [
                                -82.1417639996294,
                                37.5710859999775
                            ],
                            [
                                -82.1441780000716,
                                37.569967999718
                            ],
                            [
                                -82.1449139996654,
                                37.5676549999606
                            ],
                            [
                                -82.144383999936,
                                37.5663110000321
                            ],
                            [
                                -82.1344240001902,
                                37.5629280000269
                            ],
                            [
                                -82.1335260002147,
                                37.5603449997229
                            ],
                            [
                                -82.1353239998155,
                                37.5573509998936
                            ],
                            [
                                -82.1341110001957,
                                37.5536270002723
                            ],
                            [
                                -82.1320850000911,
                                37.5523659998943
                            ],
                            [
                                -82.123414000133,
                                37.5515299997314
                            ],
                            [
                                -82.117996999968,
                                37.5589870001244
                            ],
                            [
                                -82.1048079996452,
                                37.5601909997206
                            ],
                            [
                                -82.1019599997331,
                                37.5584200003165
                            ],
                            [
                                -82.1053549999914,
                                37.5542619997413
                            ],
                            [
                                -82.1040260000207,
                                37.5528720001602
                            ],
                            [
                                -82.0890320004246,
                                37.5561080000542
                            ],
                            [
                                -82.0751789995798,
                                37.5558530002306
                            ],
                            [
                                -82.0639099999548,
                                37.5431249997056
                            ],
                            [
                                -82.0648349996945,
                                37.5371959997255
                            ],
                            [
                                -82.0502989998577,
                                37.5354360002644
                            ],
                            [
                                -82.0485210003484,
                                37.53369499993
                            ],
                            [
                                -82.048717000167,
                                37.5292749996835
                            ],
                            [
                                -82.0461549995868,
                                37.5279399999635
                            ],
                            [
                                -82.0434590000106,
                                37.530031000335
                            ],
                            [
                                -82.0419979997955,
                                37.5341929996771
                            ],
                            [
                                -82.045050999747,
                                37.5456750002925
                            ],
                            [
                                -82.0423209998357,
                                37.5482249998333
                            ],
                            [
                                -82.0392410001199,
                                37.5477980001047
                            ],
                            [
                                -82.0370169996485,
                                37.5424589997041
                            ],
                            [
                                -82.0296379998512,
                                37.5378489998866
                            ],
                            [
                                -82.0185459997783,
                                37.5403580002545
                            ],
                            [
                                -82.0160059998377,
                                37.5339539999346
                            ],
                            [
                                -82.0111689996308,
                                37.5328809998606
                            ],
                            [
                                -82.0059520003806,
                                37.5345730001825
                            ],
                            [
                                -82.000583999721,
                                37.5422089996735
                            ],
                            [
                                -81.9982129998348,
                                37.5433170002824
                            ],
                            [
                                -81.9964589997167,
                                37.5417539998458
                            ],
                            [
                                -81.9967139999849,
                                37.5386160000639
                            ],
                            [
                                -81.9941489999299,
                                37.5377449996656
                            ],
                            [
                                -81.9891059998587,
                                37.5427799997192
                            ],
                            [
                                -81.9812440001876,
                                37.5422450003507
                            ],
                            [
                                -81.9732390004014,
                                37.5465770000384
                            ],
                            [
                                -81.9688409996873,
                                37.546170999831
                            ],
                            [
                                -81.9647879996531,
                                37.542243000274
                            ],
                            [
                                -81.9679839997198,
                                37.538054000322
                            ],
                            [
                                -82.0620200002926,
                                37.4716379998989
                            ],
                            [
                                -82.3160080000446,
                                37.2948299997601
                            ],
                            [
                                -82.3191849998447,
                                37.2874779998516
                            ],
                            [
                                -82.3245710000476,
                                37.2830290001783
                            ],
                            [
                                -82.3360310003666,
                                37.2815389998361
                            ],
                            [
                                -82.3369269997939,
                                37.279579000064
                            ],
                            [
                                -82.3436100001805,
                                37.2808520002579
                            ],
                            [
                                -82.3419849999338,
                                37.2741320002723
                            ],
                            [
                                -82.3488619995908,
                                37.2719199997563
                            ],
                            [
                                -82.3487570000088,
                                37.2679439999261
                            ],
                            [
                                -82.376596999796,
                                37.2598570003044
                            ],
                            [
                                -82.3856290003274,
                                37.2596450000738
                            ],
                            [
                                -82.3986229997582,
                                37.2553869999226
                            ],
                            [
                                -82.4119679997165,
                                37.2533819996991
                            ],
                            [
                                -82.4303309996317,
                                37.2468549996638
                            ],
                            [
                                -82.4397670003942,
                                37.2470620002339
                            ],
                            [
                                -82.4433380003796,
                                37.2433019996512
                            ],
                            [
                                -82.4490729996637,
                                37.2437470001815
                            ],
                            [
                                -82.4573610002054,
                                37.2382140001047
                            ],
                            [
                                -82.4626579998215,
                                37.238481000025
                            ],
                            [
                                -82.4650529999972,
                                37.2356859999353
                            ],
                            [
                                -82.4724729998023,
                                37.2356779997459
                            ],
                            [
                                -82.4864389997267,
                                37.2311050001735
                            ],
                            [
                                -82.4873240001817,
                                37.2276759999326
                            ],
                            [
                                -82.4916149998673,
                                37.2249140001481
                            ],
                            [
                                -82.4951649999364,
                                37.2253460000241
                            ],
                            [
                                -82.4963089999591,
                                37.2274940000384
                            ],
                            [
                                -82.4987469997924,
                                37.2269580001879
                            ],
                            [
                                -82.508467000237,
                                37.2225739998404
                            ],
                            [
                                -82.5100720003922,
                                37.218700999652
                            ],
                            [
                                -82.5207529996631,
                                37.2121099997278
                            ],
                            [
                                -82.5246559999096,
                                37.2149990000139
                            ],
                            [
                                -82.530017999823,
                                37.2133209996503
                            ],
                            [
                                -82.5320170001635,
                                37.2121099997278
                            ],
                            [
                                -82.5307919999499,
                                37.2082859998003
                            ],
                            [
                                -82.5329400002533,
                                37.2065559998421
                            ],
                            [
                                -82.5414279999133,
                                37.2065050002047
                            ],
                            [
                                -82.5458740003079,
                                37.2030269999024
                            ],
                            [
                                -82.548971999567,
                                37.2026980000848
                            ],
                            [
                                -82.5505890003161,
                                37.2044829996895
                            ],
                            [
                                -82.5746940000864,
                                37.1905400000696
                            ],
                            [
                                -82.5922560001106,
                                37.1830390001431
                            ],
                            [
                                -82.5930190003668,
                                37.1804640003325
                            ],
                            [
                                -82.5974620003884,
                                37.1770450001188
                            ],
                            [
                                -82.6065130002881,
                                37.1739889999255
                            ],
                            [
                                -82.6236090001569,
                                37.1644380002129
                            ],
                            [
                                -82.6333809995817,
                                37.1544180000244
                            ],
                            [
                                -82.6427830000089,
                                37.1530030000077
                            ],
                            [
                                -82.6471890002206,
                                37.1501600001849
                            ],
                            [
                                -82.6502379999742,
                                37.1519430002781
                            ],
                            [
                                -82.6533040003446,
                                37.151187999782
                            ],
                            [
                                -82.656722999994,
                                37.1447200001694
                            ],
                            [
                                -82.6676600002563,
                                37.1418940003189
                            ],
                            [
                                -82.6766950002624,
                                37.1349170000098
                            ],
                            [
                                -82.6828920004021,
                                37.1369509996969
                            ],
                            [
                                -82.6878569997573,
                                37.1336590000187
                            ],
                            [
                                -82.704672000317,
                                37.1279140000262
                            ],
                            [
                                -82.7151490002717,
                                37.1218479999294
                            ],
                            [
                                -82.7214130003586,
                                37.1208299999126
                            ],
                            [
                                -82.7266880002334,
                                37.1147939998582
                            ],
                            [
                                -82.7220259999287,
                                37.1055699996499
                            ],
                            [
                                -82.7234329997172,
                                37.1033310000821
                            ],
                            [
                                -82.7216789995991,
                                37.1010150002991
                            ],
                            [
                                -82.7220560000659,
                                37.0948049999808
                            ],
                            [
                                -82.725016999956,
                                37.0918219999428
                            ],
                            [
                                -82.7227620004208,
                                37.0898170000204
                            ],
                            [
                                -82.7228440004365,
                                37.0848009999723
                            ],
                            [
                                -82.7166549997944,
                                37.077406000226
                            ],
                            [
                                -82.7180780003747,
                                37.0759179997136
                            ],
                            [
                                -82.7235959999238,
                                37.0755760002083
                            ],
                            [
                                -82.7267369997389,
                                37.0730390003055
                            ],
                            [
                                -82.7256239996783,
                                37.0652549997328
                            ],
                            [
                                -82.7228290003679,
                                37.0619290001397
                            ],
                            [
                                -82.722200000006,
                                37.0579359998923
                            ],
                            [
                                -82.7224329996345,
                                37.0449490001058
                            ],
                            [
                                -82.7258239996948,
                                37.042252000257
                            ],
                            [
                                -82.7335060003392,
                                37.0445210002434
                            ],
                            [
                                -82.7427589999052,
                                37.0427959999909
                            ],
                            [
                                -82.7478620002509,
                                37.0244249998967
                            ],
                            [
                                -82.7503519999627,
                                37.0232009997509
                            ],
                            [
                                -82.7525809995587,
                                37.0261769997632
                            ],
                            [
                                -82.7594790000304,
                                37.0272550002702
                            ],
                            [
                                -82.7664090002891,
                                37.0230840001651
                            ],
                            [
                                -82.7718530002184,
                                37.0157070000986
                            ],
                            [
                                -82.7771649999031,
                                37.0160229999625
                            ],
                            [
                                -82.7828630002755,
                                37.0078210002554
                            ],
                            [
                                -82.7884980000005,
                                37.0086720003047
                            ],
                            [
                                -82.7936649999203,
                                37.0054709997551
                            ],
                            [
                                -82.7978850003591,
                                37.0095620002991
                            ],
                            [
                                -82.8037610001085,
                                37.0072339997663
                            ],
                            [
                                -82.8108269999113,
                                37.0070340000308
                            ],
                            [
                                -82.8133419997375,
                                37.0052670003098
                            ],
                            [
                                -82.8168619996693,
                                37.0074269997295
                            ],
                            [
                                -82.817173000014,
                                37.0060130003549
                            ],
                            [
                                -82.8199659996746,
                                37.006172000171
                            ],
                            [
                                -82.8225249998817,
                                37.0041060003339
                            ],
                            [
                                -82.8251379996172,
                                37.0064189996994
                            ],
                            [
                                -82.8287200003716,
                                37.005798000034
                            ],
                            [
                                -82.8305859997444,
                                37.0012190000554
                            ],
                            [
                                -82.8289889999851,
                                36.997869000039
                            ],
                            [
                                -82.8301790000386,
                                36.9943569999373
                            ],
                            [
                                -82.8350599997281,
                                36.9914769997212
                            ],
                            [
                                -82.8372749999787,
                                36.9872800002567
                            ],
                            [
                                -82.8410040001479,
                                36.9870980001127
                            ],
                            [
                                -82.8452420001301,
                                36.9837650003576
                            ],
                            [
                                -82.8498020003275,
                                36.9835829996974
                            ],
                            [
                                -82.8535769996292,
                                36.9852979998168
                            ],
                            [
                                -82.8565020004325,
                                36.9820419996485
                            ],
                            [
                                -82.8569810001083,
                                36.9788480001137
                            ],
                            [
                                -82.8604140000015,
                                36.9783769998893
                            ],
                            [
                                -82.862585999696,
                                36.980268000111
                            ],
                            [
                                -82.8674670002839,
                                36.978048999722
                            ],
                            [
                                -82.8696070001914,
                                36.9733430001774
                            ],
                            [
                                -82.868780000361,
                                36.9695870003293
                            ],
                            [
                                -82.8713069998828,
                                36.9682599999395
                            ],
                            [
                                -82.8701079996085,
                                36.9649779999379
                            ],
                            [
                                -82.8674379999715,
                                36.9641620001045
                            ],
                            [
                                -82.8659399999464,
                                36.9583360000374
                            ],
                            [
                                -82.8613570001828,
                                36.9573289996881
                            ],
                            [
                                -82.858165000314,
                                36.9538529996714
                            ],
                            [
                                -82.8556389997188,
                                36.9542149999438
                            ],
                            [
                                -82.8598819997239,
                                36.9491720000048
                            ],
                            [
                                -82.8623000003641,
                                36.9413380001492
                            ],
                            [
                                -82.8576649998235,
                                36.9286690001736
                            ],
                            [
                                -82.8671280003501,
                                36.9190900000134
                            ],
                            [
                                -82.8673450000851,
                                36.9172679997488
                            ],
                            [
                                -82.8697529997812,
                                36.9164960001317
                            ],
                            [
                                -82.877379000349,
                                36.9084710000373
                            ],
                            [
                                -82.8679779997467,
                                36.9000480002122
                            ],
                            [
                                -82.877411999961,
                                36.8955099997254
                            ],
                            [
                                -82.8786830002054,
                                36.8895320002454
                            ],
                            [
                                -82.8844610000454,
                                36.888497999989
                            ],
                            [
                                -82.8951510004355,
                                36.8822309998818
                            ],
                            [
                                -82.9073849999831,
                                36.8792980002648
                            ],
                            [
                                -82.907822999651,
                                36.8747639998024
                            ],
                            [
                                -82.9224630001431,
                                36.8738980001089
                            ],
                            [
                                -82.9283070001054,
                                36.8704120002197
                            ],
                            [
                                -82.9342959998327,
                                36.8702319996776
                            ],
                            [
                                -82.9376779996722,
                                36.8673189999801
                            ],
                            [
                                -82.9515759998245,
                                36.8661899997518
                            ],
                            [
                                -82.9707920004077,
                                36.8576410000394
                            ],
                            [
                                -82.9785770000859,
                                36.8598129997786
                            ],
                            [
                                -82.9889960003143,
                                36.8591560003077
                            ],
                            [
                                -82.9985869999891,
                                36.8565670000161
                            ],
                            [
                                -83.0065769997067,
                                36.8476430002694
                            ],
                            [
                                -83.0127710003717,
                                36.8469299998916
                            ],
                            [
                                -83.0220790001893,
                                36.8499349998164
                            ],
                            [
                                -83.0266069997014,
                                36.8555560002748
                            ],
                            [
                                -83.0357039996318,
                                36.8541170000755
                            ],
                            [
                                -83.0413769998899,
                                36.8549570002764
                            ],
                            [
                                -83.0477999999854,
                                36.851679999882
                            ],
                            [
                                -83.0607490001086,
                                36.8539479997925
                            ],
                            [
                                -83.0642430001012,
                                36.8515830003307
                            ],
                            [
                                -83.0728360002414,
                                36.8544569999339
                            ],
                            [
                                -83.07584900001,
                                36.8506939997696
                            ],
                            [
                                -83.0740670003028,
                                36.8457929999726
                            ],
                            [
                                -83.0751719999675,
                                36.8407280002749
                            ],
                            [
                                -83.0874590001168,
                                36.8382239999824
                            ],
                            [
                                -83.0909000004059,
                                36.8345940003362
                            ],
                            [
                                -83.0990240000178,
                                36.8318109998691
                            ],
                            [
                                -83.1019360004024,
                                36.8282380001134
                            ],
                            [
                                -83.0985079996338,
                                36.8237990003017
                            ],
                            [
                                -83.0984800000447,
                                36.8135169999043
                            ],
                            [
                                -83.1025640000411,
                                36.8112699999032
                            ],
                            [
                                -83.1043309996797,
                                36.8039970002431
                            ],
                            [
                                -83.1106490001932,
                                36.8004869998404
                            ],
                            [
                                -83.1182570003194,
                                36.790415999977
                            ],
                            [
                                -83.1231609995751,
                                36.7866929998055
                            ],
                            [
                                -83.1332309998241,
                                36.7845849998664
                            ],
                            [
                                -83.1289869999941,
                                36.7757839996747
                            ],
                            [
                                -83.1330800002113,
                                36.7716789996795
                            ],
                            [
                                -83.1295660001273,
                                36.7701859998756
                            ],
                            [
                                -83.1325449995607,
                                36.7643949998562
                            ],
                            [
                                -83.1255730003675,
                                36.7616230003531
                            ],
                            [
                                -83.1286380000146,
                                36.7596550000009
                            ],
                            [
                                -83.1279850002616,
                                36.7506450002523
                            ],
                            [
                                -83.1344250000755,
                                36.749356999747
                            ],
                            [
                                -83.1329920003478,
                                36.7460940000409
                            ],
                            [
                                -83.1351199996614,
                                36.7426290000017
                            ],
                            [
                                -83.1444109997606,
                                36.7416359996864
                            ],
                            [
                                -83.1524900000649,
                                36.7427040001757
                            ],
                            [
                                -83.1631240003785,
                                36.7392980002747
                            ],
                            [
                                -83.1671280000089,
                                36.7393300003261
                            ],
                            [
                                -83.1680240003346,
                                36.7406409996926
                            ],
                            [
                                -83.1748280001966,
                                36.7391750002227
                            ],
                            [
                                -83.1948389999248,
                                36.7394479998606
                            ],
                            [
                                -83.2237189999777,
                                36.7302580001568
                            ],
                            [
                                -83.2454799996259,
                                36.7259400001313
                            ],
                            [
                                -83.2882960003643,
                                36.7137100001817
                            ],
                            [
                                -83.2966309998634,
                                36.7136040000565
                            ],
                            [
                                -83.3097110004062,
                                36.7106389999637
                            ],
                            [
                                -83.3476349997878,
                                36.7001990000262
                            ],
                            [
                                -83.3587670000437,
                                36.6935059999575
                            ],
                            [
                                -83.3649730004042,
                                36.694095999946
                            ],
                            [
                                -83.3710919998279,
                                36.6906860001464
                            ],
                            [
                                -83.3752660002359,
                                36.6907279999629
                            ],
                            [
                                -83.3812380002448,
                                36.6873260002988
                            ],
                            [
                                -83.3868090003956,
                                36.6866140001635
                            ],
                            [
                                -83.395870000341,
                                36.6794259999455
                            ],
                            [
                                -83.3952390003292,
                                36.6777089999703
                            ],
                            [
                                -83.3964829999111,
                                36.6769049998739
                            ],
                            [
                                -83.4247129996857,
                                36.6673400003423
                            ],
                            [
                                -83.4363539997546,
                                36.6662209997177
                            ],
                            [
                                -83.4435450001293,
                                36.6671789997625
                            ],
                            [
                                -83.4478340001651,
                                36.6653160002607
                            ],
                            [
                                -83.4666110003571,
                                36.6647090001214
                            ],
                            [
                                -83.482680000379,
                                36.6661869999121
                            ],
                            [
                                -83.4928049999813,
                                36.6701869997227
                            ],
                            [
                                -83.499218999856,
                                36.6704389998729
                            ],
                            [
                                -83.5057499999065,
                                36.6689460001571
                            ],
                            [
                                -83.5124369995927,
                                36.6696009997225
                            ],
                            [
                                -83.5155840001539,
                                36.6677749999738
                            ],
                            [
                                -83.53014599993,
                                36.6660490002506
                            ],
                            [
                                -83.5324039998382,
                                36.66468899976
                            ],
                            [
                                -83.5323059999289,
                                36.6619650001159
                            ],
                            [
                                -83.5370540004474,
                                36.658933999801
                            ],
                            [
                                -83.5468169996514,
                                36.6541330002899
                            ],
                            [
                                -83.5624260002641,
                                36.6512490000208
                            ],
                            [
                                -83.5660509997778,
                                36.6471659999873
                            ],
                            [
                                -83.574616000329,
                                36.6443359997081
                            ],
                            [
                                -83.5773160001032,
                                36.6414959996505
                            ],
                            [
                                -83.5831240000805,
                                36.6411039999951
                            ],
                            [
                                -83.5864490000186,
                                36.6438629996663
                            ],
                            [
                                -83.5872959999404,
                                36.6423010003337
                            ],
                            [
                                -83.6004319996614,
                                36.637848999975
                            ],
                            [
                                -83.605900999705,
                                36.6379009998581
                            ],
                            [
                                -83.6138960003437,
                                36.634850000031
                            ],
                            [
                                -83.6182609996492,
                                36.6285870000176
                            ],
                            [
                                -83.624016999748,
                                36.6255089997027
                            ],
                            [
                                -83.6341480000963,
                                36.6229370003436
                            ],
                            [
                                -83.6407269998275,
                                36.6247630000268
                            ],
                            [
                                -83.6477030001169,
                                36.6230829999794
                            ],
                            [
                                -83.649313000295,
                                36.6167739997535
                            ],
                            [
                                -83.6571089998438,
                                36.6130280000897
                            ],
                            [
                                -83.6635479998329,
                                36.6069279997378
                            ],
                            [
                                -83.6744359996913,
                                36.603621000105
                            ],
                            [
                                -83.6762180002969,
                                36.5979330003337
                            ],
                            [
                                -83.6826599997607,
                                36.5931089998717
                            ],
                            [
                                -83.6907890002937,
                                36.5825960002864
                            ],
                            [
                                -83.9999429997844,
                                36.5898569996559
                            ],
                            [
                                -84.2715450003374,
                                36.5917029999358
                            ],
                            [
                                -84.4488960002325,
                                36.5962109997332
                            ],
                            [
                                -84.5404739999822,
                                36.5963040000978
                            ],
                            [
                                -84.8300350002194,
                                36.6045359998007
                            ],
                            [
                                -85.0268610002654,
                                36.6192989999816
                            ],
                            [
                                -85.1862700001948,
                                36.6252079996645
                            ],
                            [
                                -85.2939040004459,
                                36.6263549998507
                            ],
                            [
                                -85.300415000405,
                                36.624448999707
                            ],
                            [
                                -85.3247910003369,
                                36.6245079997247
                            ],
                            [
                                -85.5015070000223,
                                36.6148550002275
                            ],
                            [
                                -85.676529999689,
                                36.618222999676
                            ],
                            [
                                -85.7888580000319,
                                36.6220550003567
                            ],
                            [
                                -85.8324320001053,
                                36.6221000001221
                            ],
                            [
                                -86.0248190003277,
                                36.6302929997492
                            ],
                            [
                                -86.2361550002712,
                                36.6418779999329
                            ],
                            [
                                -86.3321259998138,
                                36.6487010002252
                            ],
                            [
                                -86.4934279997784,
                                36.6522400003515
                            ],
                            [
                                -86.5077810002153,
                                36.6523789998674
                            ],
                            [
                                -86.5438219996074,
                                36.6404269997361
                            ],
                            [
                                -86.5433520001524,
                                36.6438549998067
                            ],
                            [
                                -86.5499429995791,
                                36.6447590003053
                            ],
                            [
                                -86.5512220002194,
                                36.6379980002808
                            ],
                            [
                                -86.5639090004018,
                                36.6335619996496
                            ],
                            [
                                -86.5899060003825,
                                36.6524430002861
                            ],
                            [
                                -87.0704909996379,
                                36.6427299996558
                            ],
                            [
                                -87.4746869996318,
                                36.6404149997715
                            ],
                            [
                                -87.8533190001884,
                                36.6331600000416
                            ],
                            [
                                -87.8495439999885,
                                36.6636949998913
                            ],
                            [
                                -87.9268749996064,
                                36.6689359998071
                            ],
                            [
                                -88.0122030004112,
                                36.6771900003587
                            ],
                            [
                                -88.070540999927,
                                36.6782550000303
                            ],
                            [
                                -88.0668360000473,
                                36.6558559997368
                            ],
                            [
                                -88.0570160000436,
                                36.6403429999449
                            ],
                            [
                                -88.0556250001487,
                                36.6303400002476
                            ],
                            [
                                -88.0448619999637,
                                36.6030699996432
                            ],
                            [
                                -88.0404969997599,
                                36.576823999771
                            ],
                            [
                                -88.0350930000136,
                                36.5601889999794
                            ],
                            [
                                -88.0326490003325,
                                36.5420949996722
                            ],
                            [
                                -88.0326750002718,
                                36.5369850001342
                            ],
                            [
                                -88.0346829999348,
                                36.5278449996655
                            ],
                            [
                                -88.0374320001127,
                                36.5228580000517
                            ],
                            [
                                -88.039069000055,
                                36.510784000243
                            ],
                            [
                                -88.0532929997222,
                                36.497057999768
                            ],
                            [
                                -88.216645000081,
                                36.4998269997338
                            ],
                            [
                                -88.5418740003397,
                                36.502175999753
                            ],
                            [
                                -88.9597459998297,
                                36.5020760001394
                            ],
                            [
                                -89.301022000369,
                                36.5072329999293
                            ],
                            [
                                -89.3910569998878,
                                36.4992159998474
                            ],
                            [
                                -89.4172749998014,
                                36.4990110000652
                            ],
                            [
                                -89.3986259996559,
                                36.5422759999338
                            ],
                            [
                                -89.3958329999953,
                                36.5593249999186
                            ],
                            [
                                -89.3827099997948,
                                36.5837219999881
                            ],
                            [
                                -89.3761439995841,
                                36.6141319999296
                            ],
                            [
                                -89.3719149998228,
                                36.6201960000666
                            ],
                            [
                                -89.3657970002241,
                                36.6251259997737
                            ],
                            [
                                -89.3439019997832,
                                36.6318880002906
                            ],
                            [
                                -89.3320339999335,
                                36.6328810000169
                            ],
                            [
                                -89.326263999591,
                                36.6318400001473
                            ],
                            [
                                -89.3164500003335,
                                36.6240440002366
                            ],
                            [
                                -89.2946750004415,
                                36.5937789999395
                            ],
                            [
                                -89.269546000299,
                                36.569716000343
                            ],
                            [
                                -89.2583770002332,
                                36.5649459997545
                            ],
                            [
                                -89.236307999715,
                                36.5668810002663
                            ],
                            [
                                -89.2270459999282,
                                36.5695370000424
                            ],
                            [
                                -89.2138740002222,
                                36.5799109996842
                            ],
                            [
                                -89.2027710002786,
                                36.6016649997396
                            ],
                            [
                                -89.2012649998576,
                                36.6165449999948
                            ],
                            [
                                -89.1977530003217,
                                36.6289709999555
                            ],
                            [
                                -89.1868560002424,
                                36.6420220002535
                            ],
                            [
                                -89.1747270002768,
                                36.6504749998381
                            ],
                            [
                                -89.1591179996641,
                                36.6663539998087
                            ],
                            [
                                -89.1718840003875,
                                36.6725980001854
                            ],
                            [
                                -89.1679710000954,
                                36.6790889996876
                            ],
                            [
                                -89.1681100000125,
                                36.6857289998941
                            ],
                            [
                                -89.1704619996321,
                                36.6900480000458
                            ],
                            [
                                -89.182952999996,
                                36.6995520000944
                            ],
                            [
                                -89.1993109997229,
                                36.7156240003514
                            ],
                            [
                                -89.2013119997132,
                                36.7222999996661
                            ],
                            [
                                -89.20055199983,
                                36.7325669999006
                            ],
                            [
                                -89.1978560002539,
                                36.7397720002117
                            ],
                            [
                                -89.1881669997714,
                                36.7507439998568
                            ],
                            [
                                -89.1820270004314,
                                36.7554080001563
                            ],
                            [
                                -89.172886999945,
                                36.7587710000217
                            ],
                            [
                                -89.1666110001625,
                                36.7596699997359
                            ],
                            [
                                -89.1585539995995,
                                36.7562290000734
                            ],
                            [
                                -89.1425020001943,
                                36.7555029998502
                            ],
                            [
                                -89.1357889996705,
                                36.7517769996987
                            ],
                            [
                                -89.125967000017,
                                36.751809000305
                            ],
                            [
                                -89.1217750000656,
                                36.7556700000649
                            ],
                            [
                                -89.1182020004303,
                                36.762325999803
                            ],
                            [
                                -89.1162430002728,
                                36.7732920003055
                            ],
                            [
                                -89.1203969996911,
                                36.7817330002924
                            ],
                            [
                                -89.1243800003035,
                                36.7858259997262
                            ],
                            [
                                -89.132186999723,
                                36.7882569999521
                            ],
                            [
                                -89.1571369997653,
                                36.7895689998205
                            ],
                            [
                                -89.1705629999145,
                                36.7975379997026
                            ],
                            [
                                -89.1791329995902,
                                36.8108929997608
                            ],
                            [
                                -89.1792010002606,
                                36.8298959996729
                            ],
                            [
                                -89.1765440001441,
                                36.8371209996723
                            ],
                            [
                                -89.1704430002639,
                                36.8418399997194
                            ],
                            [
                                -89.1590049996862,
                                36.8427289997103
                            ],
                            [
                                -89.1526810002232,
                                36.8471050002542
                            ],
                            [
                                -89.1381090004014,
                                36.8473229998293
                            ],
                            [
                                -89.131934000003,
                                36.8575960001486
                            ],
                            [
                                -89.1267739997559,
                                36.871899999763
                            ],
                            [
                                -89.117115000309,
                                36.8886849999798
                            ],
                            [
                                -89.1088779998209,
                                36.9216100002603
                            ],
                            [
                                -89.1016439997885,
                                36.9407280000687
                            ],
                            [
                                -89.0989830003725,
                                36.9582220001787
                            ],
                            [
                                -89.1010379998911,
                                36.9670849997113
                            ],
                            [
                                -89.1109459997584,
                                36.9777970001297
                            ],
                            [
                                -89.1234420001451,
                                36.983192999687
                            ],
                            [
                                -89.1329149998191,
                                36.9820570002717
                            ],
                            [
                                -89.1365589995995,
                                36.9837579996503
                            ],
                            [
                                -89.1701729999271,
                                37.0070670000235
                            ],
                            [
                                -89.1807410001184,
                                37.0251799998024
                            ],
                            [
                                -89.1825680000315,
                                37.0390009998546
                            ],
                            [
                                -89.1808470004237,
                                37.0490199997123
                            ],
                            [
                                -89.1738250001034,
                                37.0663079996576
                            ],
                            [
                                -89.1565989996399,
                                37.0868409997952
                            ],
                            [
                                -89.1527339999266,
                                37.0899459997382
                            ],
                            [
                                -89.1509809996334,
                                37.0905530002903
                            ],
                            [
                                -89.1512979998258,
                                37.0889950001938
                            ],
                            [
                                -89.1464949999541,
                                37.0908190003566
                            ],
                            [
                                -89.1415129999822,
                                37.0942240000558
                            ],
                            [
                                -89.1375820001467,
                                37.0981500000667
                            ],
                            [
                                -89.1354039997061,
                                37.103091999756
                            ],
                            [
                                -89.1256280000833,
                                37.1086300001809
                            ],
                            [
                                -89.1130329999624,
                                37.1206850000952
                            ],
                            [
                                -89.1096129995897,
                                37.1229199998202
                            ],
                            [
                                -89.1156780003833,
                                37.1156739999903
                            ],
                            [
                                -89.111409000439,
                                37.1189880003146
                            ],
                            [
                                -89.0990109999615,
                                37.140406000256
                            ],
                            [
                                -89.0936800000102,
                                37.1550739996625
                            ],
                            [
                                -89.0867120001166,
                                37.1654510002148
                            ],
                            [
                                -89.0408080001649,
                                37.2031139996948
                            ],
                            [
                                -89.0380389998955,
                                37.2036660001372
                            ],
                            [
                                -89.0294650000218,
                                37.211303000007
                            ],
                            [
                                -89.0131540001505,
                                37.2164780000289
                            ],
                            [
                                -89.0001849999358,
                                37.2247639997018
                            ],
                            [
                                -88.9838670003918,
                                37.228517999794
                            ],
                            [
                                -88.9769689999202,
                                37.2295029999522
                            ],
                            [
                                -88.9797280001439,
                                37.2266160000218
                            ],
                            [
                                -88.9881549997044,
                                37.2237319997313
                            ],
                            [
                                -88.9797919997179,
                                37.2261130001593
                            ],
                            [
                                -88.9762619997404,
                                37.2295529998531
                            ],
                            [
                                -88.9666340002557,
                                37.2301529996539
                            ],
                            [
                                -88.9729999995515,
                                37.2285309997267
                            ],
                            [
                                -88.9545000002672,
                                37.224917000111
                            ],
                            [
                                -88.9327539997892,
                                37.2252990001427
                            ],
                            [
                                -88.9420180001242,
                                37.2288739997262
                            ],
                            [
                                -88.9161800001522,
                                37.2239910001536
                            ],
                            [
                                -88.8709809998321,
                                37.2103770003298
                            ],
                            [
                                -88.8352509998864,
                                37.1965220002771
                            ],
                            [
                                -88.8025539998008,
                                37.1878610001703
                            ],
                            [
                                -88.786919999972,
                                37.1783570003477
                            ],
                            [
                                -88.7842639996805,
                                37.175034999966
                            ],
                            [
                                -88.7622450002893,
                                37.1600259997914
                            ],
                            [
                                -88.7329550001576,
                                37.1442910003215
                            ],
                            [
                                -88.7194190004035,
                                37.140880999749
                            ],
                            [
                                -88.7081099996973,
                                37.1427450000529
                            ],
                            [
                                -88.7062030003166,
                                37.1414930000069
                            ],
                            [
                                -88.7039410002104,
                                37.1430259997506
                            ],
                            [
                                -88.6918249997653,
                                37.1409470000501
                            ],
                            [
                                -88.6440550004408,
                                37.1224430002
                            ],
                            [
                                -88.6297010001789,
                                37.1209110002182
                            ],
                            [
                                -88.6169279997827,
                                37.1160409997119
                            ],
                            [
                                -88.5844349999116,
                                37.0962749998637
                            ],
                            [
                                -88.575451999784,
                                37.0880569999285
                            ],
                            [
                                -88.5732490001273,
                                37.0800009997031
                            ],
                            [
                                -88.5666479997566,
                                37.0753930002858
                            ],
                            [
                                -88.5651649998001,
                                37.0754569999956
                            ],
                            [
                                -88.5659599998434,
                                37.0777089998266
                            ],
                            [
                                -88.5438229995531,
                                37.070103999752
                            ],
                            [
                                -88.5204369996582,
                                37.0655609996983
                            ],
                            [
                                -88.5029719997184,
                                37.0656399997184
                            ],
                            [
                                -88.4904109999327,
                                37.068578999906
                            ],
                            [
                                -88.4826239997064,
                                37.0672570001101
                            ],
                            [
                                -88.4728399996877,
                                37.0697739999528
                            ],
                            [
                                -88.4592679999486,
                                37.075248000206
                            ],
                            [
                                -88.4456140001938,
                                37.0933320000676
                            ],
                            [
                                -88.4445830001489,
                                37.0964190003102
                            ],
                            [
                                -88.4457759996772,
                                37.0996830000739
                            ],
                            [
                                -88.4406740000547,
                                37.1089930000127
                            ],
                            [
                                -88.4434709999132,
                                37.1087290001773
                            ],
                            [
                                -88.4432499999803,
                                37.1113629997496
                            ],
                            [
                                -88.4298770004329,
                                37.1375030002781
                            ],
                            [
                                -88.4246030003829,
                                37.1517640003453
                            ],
                            [
                                -88.4333739999001,
                                37.1631400000916
                            ],
                            [
                                -88.4379829996031,
                                37.1800859996654
                            ],
                            [
                                -88.4493180002486,
                                37.2052169998442
                            ],
                            [
                                -88.4588679999155,
                                37.2135269998756
                            ],
                            [
                                -88.4725359999141,
                                37.2205029999172
                            ],
                            [
                                -88.4794419998834,
                                37.2287949997999
                            ],
                            [
                                -88.4877939999992,
                                37.2445070002502
                            ],
                            [
                                -88.5123669995746,
                                37.2631929998899
                            ],
                            [
                                -88.5072830003938,
                                37.262405999737
                            ],
                            [
                                -88.503940000014,
                                37.2646210000342
                            ],
                            [
                                -88.506797000147,
                                37.2650180001713
                            ],
                            [
                                -88.5100090001072,
                                37.2755899997492
                            ],
                            [
                                -88.5141369995863,
                                37.2794979997257
                            ],
                            [
                                -88.515757999635,
                                37.2840919997241
                            ],
                            [
                                -88.5145380003426,
                                37.2913730002238
                            ],
                            [
                                -88.4866070003188,
                                37.3404579996443
                            ],
                            [
                                -88.4830949998846,
                                37.3521340000115
                            ],
                            [
                                -88.4827259998136,
                                37.3638959997467
                            ],
                            [
                                -88.4769510003466,
                                37.3856830000648
                            ],
                            [
                                -88.4696210000548,
                                37.397489000177
                            ],
                            [
                                -88.4509390002973,
                                37.4120279996742
                            ],
                            [
                                -88.4354390003629,
                                37.4183559997336
                            ],
                            [
                                -88.4101129996786,
                                37.4254000003438
                            ],
                            [
                                -88.3936279997301,
                                37.4198609998556
                            ],
                            [
                                -88.3771660002461,
                                37.4095849999652
                            ],
                            [
                                -88.3716899996315,
                                37.4028660001128
                            ],
                            [
                                -88.3643900003752,
                                37.4017540001263
                            ],
                            [
                                -88.3483980003461,
                                37.4109599999604
                            ],
                            [
                                -88.3337329997396,
                                37.4268100001812
                            ],
                            [
                                -88.3236330002518,
                                37.4339920002361
                            ],
                            [
                                -88.3185399999518,
                                37.43561899994
                            ],
                            [
                                -88.3063530002599,
                                37.4434610002167
                            ],
                            [
                                -88.2792070002336,
                                37.453291999902
                            ],
                            [
                                -88.2529919997948,
                                37.4569770000195
                            ],
                            [
                                -88.2286200000608,
                                37.4568190000885
                            ],
                            [
                                -88.1977139999032,
                                37.4601549996625
                            ],
                            [
                                -88.1343860000196,
                                37.4716619999998
                            ],
                            [
                                -88.1287100002868,
                                37.4703220000408
                            ],
                            [
                                -88.0934620003899,
                                37.4736699996786
                            ],
                            [
                                -88.0852290000998,
                                37.4715400002984
                            ],
                            [
                                -88.081336999724,
                                37.4733959999049
                            ],
                            [
                                -88.0745200003415,
                                37.4812750001306
                            ],
                            [
                                -88.073065999799,
                                37.4857179996532
                            ],
                            [
                                -88.0684379998295,
                                37.4854490002933
                            ],
                            [
                                -88.0654300000838,
                                37.4887599999783
                            ],
                            [
                                -88.0613319998436,
                                37.5053320003084
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -89.5710249997163,
                                36.5502010003345
                            ],
                            [
                                -89.5690499996653,
                                36.5617210001397
                            ],
                            [
                                -89.5596339998926,
                                36.5727899998702
                            ],
                            [
                                -89.5446749995583,
                                36.580187999863
                            ],
                            [
                                -89.5285410001373,
                                36.5815819999062
                            ],
                            [
                                -89.5168520003878,
                                36.5773969997326
                            ],
                            [
                                -89.4881060002292,
                                36.5729250001844
                            ],
                            [
                                -89.4799299998404,
                                36.5690869997394
                            ],
                            [
                                -89.4728609996645,
                                36.558839999655
                            ],
                            [
                                -89.4678200001414,
                                36.5469739996394
                            ],
                            [
                                -89.4655349995707,
                                36.5359889998362
                            ],
                            [
                                -89.4659129998624,
                                36.5300150002719
                            ],
                            [
                                -89.4732559996747,
                                36.5124159998464
                            ],
                            [
                                -89.4854270003714,
                                36.4974909999145
                            ],
                            [
                                -89.5392320003523,
                                36.4979339999487
                            ],
                            [
                                -89.5598740000922,
                                36.5234669999254
                            ],
                            [
                                -89.5710249997163,
                                36.5502010003345
                            ]
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 37.5336844,
            "name": "Kentucky",
            "intptlon": -85.2929801,
            "geo_point_2d": [
                37.5266027266,
                -85.2905397627
            ],
            "geoid": "21",
            "mtfcc": "G4000",
            "region": 3
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -85.2905397627,
                37.5266027266
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "cbdabd63fac5ce0d6f10ca1129dcf48f37baee1d",
        "fields": {
            "arealand": "-1220695848",
            "objectid": 51,
            "basename": "South Dakota",
            "stusab": "SD",
            "statens": "01785534",
            "centlon": -100.2270346,
            "state": "46",
            "gid": 17,
            "centlat": 44.4445721,
            "division": 4,
            "areawater": "-913576672",
            "oid": "313806704",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -100.499371000321,
                            45.9436530002873
                        ],
                        [
                            -98.8813549995606,
                            45.9393659999239
                        ],
                        [
                            -98.3114880000986,
                            45.9361200001872
                        ],
                        [
                            -96.5636720002813,
                            45.9352449998413
                        ],
                        [
                            -96.564961000069,
                            45.9300760000945
                        ],
                        [
                            -96.5640019999942,
                            45.9195609999579
                        ],
                        [
                            -96.5665620000262,
                            45.9169310002898
                        ],
                        [
                            -96.5690989995937,
                            45.9170370000154
                        ],
                        [
                            -96.5701859997151,
                            45.9120389999379
                        ],
                        [
                            -96.5675389996444,
                            45.912052000218
                        ],
                        [
                            -96.564451000431,
                            45.9095130002425
                        ],
                        [
                            -96.5661879999324,
                            45.9086629997647
                        ],
                        [
                            -96.5681839998999,
                            45.9034510002063
                        ],
                        [
                            -96.5688659999652,
                            45.8880229996917
                        ],
                        [
                            -96.5713540000273,
                            45.8866740001178
                        ],
                        [
                            -96.5718710002362,
                            45.8718459998819
                        ],
                        [
                            -96.5746670002699,
                            45.8668159997436
                        ],
                        [
                            -96.5730789998331,
                            45.8615239997577
                        ],
                        [
                            -96.5744120000017,
                            45.8446489999129
                        ],
                        [
                            -96.5775340004486,
                            45.8379299998298
                        ],
                        [
                            -96.5772759998074,
                            45.8337209999495
                        ],
                        [
                            -96.57973999958,
                            45.8258200002928
                        ],
                        [
                            -96.5836839998343,
                            45.8193069998518
                        ],
                        [
                            -96.5967040001027,
                            45.8118010000325
                        ],
                        [
                            -96.6125120000087,
                            45.7944420001322
                        ],
                        [
                            -96.6277779995914,
                            45.7862390003097
                        ],
                        [
                            -96.6303450001945,
                            45.7823800000266
                        ],
                        [
                            -96.630144000353,
                            45.7794310001381
                        ],
                        [
                            -96.6340619997698,
                            45.7779159997052
                        ],
                        [
                            -96.6341470001586,
                            45.7745619999426
                        ],
                        [
                            -96.6395330003615,
                            45.77159199984
                        ],
                        [
                            -96.6391800001841,
                            45.7671119998992
                        ],
                        [
                            -96.6419410000576,
                            45.7598709999791
                        ],
                        [
                            -96.6522260003917,
                            45.7468089999889
                        ],
                        [
                            -96.6625950003914,
                            45.7386820001904
                        ],
                        [
                            -96.672664999742,
                            45.7323359998283
                        ],
                        [
                            -96.7111570002844,
                            45.717561000244
                        ],
                        [
                            -96.7450860002564,
                            45.7015759996893
                        ],
                        [
                            -96.7503500002606,
                            45.6987820003115
                        ],
                        [
                            -96.760865999675,
                            45.6875180002644
                        ],
                        [
                            -96.8024680000704,
                            45.6672759998352
                        ],
                        [
                            -96.8386480002779,
                            45.6475089997828
                        ],
                        [
                            -96.8442110000329,
                            45.6395830000122
                        ],
                        [
                            -96.8568339997428,
                            45.6053779999491
                        ],
                        [
                            -96.8019869998465,
                            45.5554140001319
                        ],
                        [
                            -96.7938399997701,
                            45.5507240000679
                        ],
                        [
                            -96.7810360003101,
                            45.535971999919
                        ],
                        [
                            -96.7634319995549,
                            45.5200070002545
                        ],
                        [
                            -96.7454870001144,
                            45.4887119999893
                        ],
                        [
                            -96.7434860001241,
                            45.4806490001156
                        ],
                        [
                            -96.7384460004259,
                            45.4734990003084
                        ],
                        [
                            -96.7327389998326,
                            45.4587369999486
                        ],
                        [
                            -96.7242500003477,
                            45.4514820000276
                        ],
                        [
                            -96.6925409997509,
                            45.417337999927
                        ],
                        [
                            -96.6804539996181,
                            45.4104989997674
                        ],
                        [
                            -96.6622580001075,
                            45.4090110002031
                        ],
                        [
                            -96.6489519996089,
                            45.4103099998842
                        ],
                        [
                            -96.6444619997316,
                            45.4088920000416
                        ],
                        [
                            -96.6358889996828,
                            45.4099429998029
                        ],
                        [
                            -96.6197519998888,
                            45.4088840001755
                        ],
                        [
                            -96.6011799997362,
                            45.4031809998899
                        ],
                        [
                            -96.5788790003128,
                            45.3922950000022
                        ],
                        [
                            -96.5621419995708,
                            45.3860899998396
                        ],
                        [
                            -96.5217870000286,
                            45.3756449999542
                        ],
                        [
                            -96.4890649998287,
                            45.3570709996881
                        ],
                        [
                            -96.4802580003265,
                            45.3407389997934
                        ],
                        [
                            -96.4692459997211,
                            45.3249410002601
                        ],
                        [
                            -96.4680270002536,
                            45.3186190001316
                        ],
                        [
                            -96.4619099995815,
                            45.3138839996954
                        ],
                        [
                            -96.457582000086,
                            45.3075859999653
                        ],
                        [
                            -96.4569949995568,
                            45.3038799997433
                        ],
                        [
                            -96.4530970002316,
                            45.3007510000905
                        ],
                        [
                            -96.4512340003335,
                            44.7182730000883
                        ],
                        [
                            -96.4534040003782,
                            44.0254309997314
                        ],
                        [
                            -96.4532600004382,
                            43.5003899998969
                        ],
                        [
                            -96.5991910003398,
                            43.5004560002029
                        ],
                        [
                            -96.5990130000646,
                            43.4953480000079
                        ],
                        [
                            -96.5947969998237,
                            43.4931139999337
                        ],
                        [
                            -96.5902109996869,
                            43.4941229999317
                        ],
                        [
                            -96.5901560003336,
                            43.4919750000524
                        ],
                        [
                            -96.5869150000611,
                            43.4915140003019
                        ],
                        [
                            -96.5845509998476,
                            43.4890990002873
                        ],
                        [
                            -96.5870020000997,
                            43.4875790001502
                        ],
                        [
                            -96.5867870000146,
                            43.4838330000907
                        ],
                        [
                            -96.5824219998108,
                            43.4834179998306
                        ],
                        [
                            -96.5810839996193,
                            43.4814620001021
                        ],
                        [
                            -96.5868919995966,
                            43.4778729999074
                        ],
                        [
                            -96.5837409997357,
                            43.4751059997243
                        ],
                        [
                            -96.5852920003626,
                            43.4709739999264
                        ],
                        [
                            -96.5824190003361,
                            43.4667060002331
                        ],
                        [
                            -96.5870079999475,
                            43.4645020000871
                        ],
                        [
                            -96.5894359997351,
                            43.4664560002488
                        ],
                        [
                            -96.5905510003439,
                            43.4629029998089
                        ],
                        [
                            -96.5946529998836,
                            43.4622299997551
                        ],
                        [
                            -96.5938529998175,
                            43.4588519998197
                        ],
                        [
                            -96.6009489997575,
                            43.4559429996764
                        ],
                        [
                            -96.6000170003452,
                            43.4505769998079
                        ],
                        [
                            -96.6030560000531,
                            43.4507929999814
                        ],
                        [
                            -96.5990719996158,
                            43.4452729998937
                        ],
                        [
                            -96.5994430002349,
                            43.442527000314
                        ],
                        [
                            -96.5963919999331,
                            43.4421560001793
                        ],
                        [
                            -96.5975450001767,
                            43.4401790000305
                        ],
                        [
                            -96.5936610001968,
                            43.4369669999451
                        ],
                        [
                            -96.5934439995635,
                            43.4332269999398
                        ],
                        [
                            -96.5880470003883,
                            43.4314650002702
                        ],
                        [
                            -96.5761030003706,
                            43.4318740001041
                        ],
                        [
                            -96.5702889996472,
                            43.4285469997287
                        ],
                        [
                            -96.5699999999423,
                            43.4256330000494
                        ],
                        [
                            -96.5735550000341,
                            43.4189690000368
                        ],
                        [
                            -96.568258000418,
                            43.4169030000954
                        ],
                        [
                            -96.5646370002039,
                            43.4130939999387
                        ],
                        [
                            -96.5604390004046,
                            43.4124619999716
                        ],
                        [
                            -96.5572340001171,
                            43.4061660001183
                        ],
                        [
                            -96.5484080003484,
                            43.4036619998259
                        ],
                        [
                            -96.5448809998455,
                            43.4000070002412
                        ],
                        [
                            -96.5455999997209,
                            43.3972220000481
                        ],
                        [
                            -96.5444289999339,
                            43.3961890002179
                        ],
                        [
                            -96.5409099998271,
                            43.3974259998109
                        ],
                        [
                            -96.5355189996013,
                            43.3948360003063
                        ],
                        [
                            -96.5306399995616,
                            43.3955440001115
                        ],
                        [
                            -96.5295829995775,
                            43.3976239997493
                        ],
                        [
                            -96.5252419996631,
                            43.3960720001133
                        ],
                        [
                            -96.524112999709,
                            43.3902959998451
                        ],
                        [
                            -96.5217340003252,
                            43.3868620001804
                        ],
                        [
                            -96.5217950004245,
                            43.3847109998192
                        ],
                        [
                            -96.5246059996285,
                            43.3822709997609
                        ],
                        [
                            -96.5219829998472,
                            43.3813799996884
                        ],
                        [
                            -96.52451699994,
                            43.379776000198
                        ],
                        [
                            -96.5213579996832,
                            43.3758739997772
                        ],
                        [
                            -96.5220590000152,
                            43.3721899996856
                        ],
                        [
                            -96.5249820002704,
                            43.3725800002446
                        ],
                        [
                            -96.5247620001624,
                            43.3698589997156
                        ],
                        [
                            -96.5267179999469,
                            43.3681470000939
                        ],
                        [
                            -96.5289109995578,
                            43.3693169997555
                        ],
                        [
                            -96.5309609999519,
                            43.368432999875
                        ],
                        [
                            -96.5294599995538,
                            43.3626360002185
                        ],
                        [
                            -96.5272610000951,
                            43.3615590002619
                        ],
                        [
                            -96.5277480001668,
                            43.3563250002508
                        ],
                        [
                            -96.5251139996166,
                            43.3535789998589
                        ],
                        [
                            -96.5277160003798,
                            43.3482950002992
                        ],
                        [
                            -96.5242790002886,
                            43.3474220000533
                        ],
                        [
                            -96.5284439995776,
                            43.3422459997731
                        ],
                        [
                            -96.5327589995527,
                            43.3427440001234
                        ],
                        [
                            -96.5316649997586,
                            43.3393350002627
                        ],
                        [
                            -96.5350869997811,
                            43.3365019998664
                        ],
                        [
                            -96.5314580000694,
                            43.3318829999597
                        ],
                        [
                            -96.533188999723,
                            43.3287140001921
                        ],
                        [
                            -96.5288479998086,
                            43.3243370002733
                        ],
                        [
                            -96.5309619997768,
                            43.3206690001164
                        ],
                        [
                            -96.5256390002215,
                            43.3186330001245
                        ],
                        [
                            -96.5265189997552,
                            43.3158629997927
                        ],
                        [
                            -96.5290009999695,
                            43.3164279999928
                        ],
                        [
                            -96.5284410001028,
                            43.3140280000001
                        ],
                        [
                            -96.5240590001806,
                            43.3113809997188
                        ],
                        [
                            -96.5291540001304,
                            43.309078000167
                        ],
                        [
                            -96.5270550002308,
                            43.3051040001974
                        ],
                        [
                            -96.5319049999581,
                            43.3042329997833
                        ],
                        [
                            -96.5301449999922,
                            43.3000000001425
                        ],
                        [
                            -96.5387640000718,
                            43.2985439997021
                        ],
                        [
                            -96.5408150002908,
                            43.295532999842
                        ],
                        [
                            -96.5522029997415,
                            43.292833999939
                        ],
                        [
                            -96.5549379996757,
                            43.2940250002978
                        ],
                        [
                            -96.5547119997199,
                            43.2965090001954
                        ],
                        [
                            -96.5643800002859,
                            43.294638999942
                        ],
                        [
                            -96.5664749999876,
                            43.296047999837
                        ],
                        [
                            -96.5693189997018,
                            43.2955389999242
                        ],
                        [
                            -96.5737059996469,
                            43.2990260001639
                        ],
                        [
                            -96.5829470004157,
                            43.2981249998436
                        ],
                        [
                            -96.5892740002517,
                            43.2952650001441
                        ],
                        [
                            -96.5880330001446,
                            43.2923790002302
                        ],
                        [
                            -96.5804049999269,
                            43.2958200001678
                        ],
                        [
                            -96.5791449995532,
                            43.2937569998386
                        ],
                        [
                            -96.5814159998803,
                            43.2879580003141
                        ],
                        [
                            -96.5775970001978,
                            43.2789640002719
                        ],
                        [
                            -96.5811959997723,
                            43.2746689998145
                        ],
                        [
                            -96.5831110004472,
                            43.2745490000488
                        ],
                        [
                            -96.5837650000252,
                            43.2766599996872
                        ],
                        [
                            -96.5861490003301,
                            43.2744799997821
                        ],
                        [
                            -96.5853409998681,
                            43.2688690001429
                        ],
                        [
                            -96.5829449998675,
                            43.2675680001307
                        ],
                        [
                            -96.5765150000993,
                            43.2680709997623
                        ],
                        [
                            -96.5648229999767,
                            43.2602270000019
                        ],
                        [
                            -96.5536880002461,
                            43.2596009996964
                        ],
                        [
                            -96.5526889999883,
                            43.2520120002692
                        ],
                        [
                            -96.5491949999957,
                            43.2476260001159
                        ],
                        [
                            -96.5532410003573,
                            43.2474690000812
                        ],
                        [
                            -96.560713000041,
                            43.2438499997886
                        ],
                        [
                            -96.5656699998985,
                            43.2442009997874
                        ],
                        [
                            -96.5714379996929,
                            43.239022000122
                        ],
                        [
                            -96.5690230003241,
                            43.2371890003105
                        ],
                        [
                            -96.5691780001348,
                            43.2327000002958
                        ],
                        [
                            -96.5652899999569,
                            43.2303700000859
                        ],
                        [
                            -96.5593260003441,
                            43.2229339999626
                        ],
                        [
                            -96.5552809998074,
                            43.2266659998485
                        ],
                        [
                            -96.5402460002033,
                            43.2256319998606
                        ],
                        [
                            -96.5359150003347,
                            43.2276340000683
                        ],
                        [
                            -96.5266350001062,
                            43.2241220003037
                        ],
                        [
                            -96.5197480004035,
                            43.2177849997205
                        ],
                        [
                            -96.5138009996107,
                            43.2188060000347
                        ],
                        [
                            -96.5089059996775,
                            43.2171620001792
                        ],
                        [
                            -96.5085509998502,
                            43.221117000162
                        ],
                        [
                            -96.5011979999922,
                            43.2205740001661
                        ],
                        [
                            -96.4966389996196,
                            43.2237159999876
                        ],
                        [
                            -96.4856529998519,
                            43.2242219998182
                        ],
                        [
                            -96.4766539998308,
                            43.221941000254
                        ],
                        [
                            -96.4749599999871,
                            43.2173959998896
                        ],
                        [
                            -96.4764689998828,
                            43.2129689999843
                        ],
                        [
                            -96.4706600000806,
                            43.2073259997194
                        ],
                        [
                            -96.4707720002336,
                            43.205111999938
                        ],
                        [
                            -96.4750069998428,
                            43.2025690000184
                        ],
                        [
                            -96.4721060002272,
                            43.1963420000806
                        ],
                        [
                            -96.4740090003082,
                            43.1896390002592
                        ],
                        [
                            -96.4725249996285,
                            43.1856009999619
                        ],
                        [
                            -96.4673069996551,
                            43.1847800000732
                        ],
                        [
                            -96.4648560003013,
                            43.1819600001656
                        ],
                        [
                            -96.466870999637,
                            43.1742189998544
                        ],
                        [
                            -96.4655700001537,
                            43.1713150000503
                        ],
                        [
                            -96.4691300002685,
                            43.1694209999217
                        ],
                        [
                            -96.466986999988,
                            43.1683540001463
                        ],
                        [
                            -96.4675440003799,
                            43.1637340002295
                        ],
                        [
                            -96.4659550001182,
                            43.1614219997916
                        ],
                        [
                            -96.4695560002408,
                            43.1583529996979
                        ],
                        [
                            -96.463980999892,
                            43.1554889999896
                        ],
                        [
                            -96.4649809999748,
                            43.1527259998689
                        ],
                        [
                            -96.4684990002567,
                            43.1518870002343
                        ],
                        [
                            -96.4651440001814,
                            43.1474750000307
                        ],
                        [
                            -96.4621720004207,
                            43.149180000178
                        ],
                        [
                            -96.4592480003405,
                            43.1474880000295
                        ],
                        [
                            -96.4604299999981,
                            43.1436300000891
                        ],
                        [
                            -96.455754000348,
                            43.1442350001701
                        ],
                        [
                            -96.4504300000694,
                            43.1423899998463
                        ],
                        [
                            -96.4470800000169,
                            43.1383380003233
                        ],
                        [
                            -96.4485870002628,
                            43.136227999911
                        ],
                        [
                            -96.4455989997103,
                            43.1365129997475
                        ],
                        [
                            -96.4431340001127,
                            43.1336360002512
                        ],
                        [
                            -96.4427600000189,
                            43.1286150002291
                        ],
                        [
                            -96.440190999766,
                            43.1283170001605
                        ],
                        [
                            -96.4415809998361,
                            43.1244960000196
                        ],
                        [
                            -96.4364719996426,
                            43.1201659998975
                        ],
                        [
                            -96.4398319997408,
                            43.1134229998426
                        ],
                        [
                            -96.4462680002552,
                            43.1097029997777
                        ],
                        [
                            -96.4469920001533,
                            43.1055139997434
                        ],
                        [
                            -96.4505360003746,
                            43.1037750000933
                        ],
                        [
                            -96.4504030003052,
                            43.1008499996866
                        ],
                        [
                            -96.452672999909,
                            43.1005750002932
                        ],
                        [
                            -96.4523800000061,
                            43.0981929999088
                        ],
                        [
                            -96.4570230000442,
                            43.0976470002183
                        ],
                        [
                            -96.4568459995939,
                            43.0959149998775
                        ],
                        [
                            -96.4602419996771,
                            43.0952840001358
                        ],
                        [
                            -96.4623199996604,
                            43.092884999868
                        ],
                        [
                            -96.4630829999166,
                            43.089966999869
                        ],
                        [
                            -96.4554670002928,
                            43.088283000067
                        ],
                        [
                            -96.4540799996975,
                            43.0838449997443
                        ],
                        [
                            -96.4557979998306,
                            43.0808450001494
                        ],
                        [
                            -96.4555550001564,
                            43.0743190001242
                        ],
                        [
                            -96.4593870002577,
                            43.0698429999899
                        ],
                        [
                            -96.4582330001892,
                            43.0675610002426
                        ],
                        [
                            -96.4605499996487,
                            43.0663240002755
                        ],
                        [
                            -96.4607420001677,
                            43.0643370001541
                        ],
                        [
                            -96.4674420002726,
                            43.0619470003018
                        ],
                        [
                            -96.4727530001324,
                            43.063710999719
                        ],
                        [
                            -96.4767400000445,
                            43.0626970001375
                        ],
                        [
                            -96.4869669997539,
                            43.0557790001431
                        ],
                        [
                            -96.4909300002749,
                            43.0508119996831
                        ],
                        [
                            -96.5018039998896,
                            43.0486580002911
                        ],
                        [
                            -96.5105230004265,
                            43.0499130001645
                        ],
                        [
                            -96.5148119995641,
                            43.0451739998935
                        ],
                        [
                            -96.5125210000438,
                            43.0410800000573
                        ],
                        [
                            -96.5213019996067,
                            43.0396600003075
                        ],
                        [
                            -96.520085999614,
                            43.0377619999748
                        ],
                        [
                            -96.5132330002464,
                            43.0403499997318
                        ],
                        [
                            -96.5110480001331,
                            43.0397269997944
                        ],
                        [
                            -96.5091380003794,
                            43.036762999925
                        ],
                        [
                            -96.5106659996434,
                            43.0320359997892
                        ],
                        [
                            -96.5139710003883,
                            43.030323999794
                        ],
                        [
                            -96.5120320003223,
                            43.0262119997507
                        ],
                        [
                            -96.5039370001244,
                            43.0203659996813
                        ],
                        [
                            -96.499039999643,
                            43.0190730002105
                        ],
                        [
                            -96.4995319997376,
                            43.0165480002446
                        ],
                        [
                            -96.4956720000472,
                            43.0158690002374
                        ],
                        [
                            -96.4919810004112,
                            43.0101969997588
                        ],
                        [
                            -96.4928109997163,
                            43.0056499999908
                        ],
                        [
                            -96.4969950001701,
                            42.9984780000309
                        ],
                        [
                            -96.5098599997294,
                            42.9952280000601
                        ],
                        [
                            -96.5128340000383,
                            42.9919120000648
                        ],
                        [
                            -96.5111629997608,
                            42.9903099998423
                        ],
                        [
                            -96.5123820001266,
                            42.985657999853
                        ],
                        [
                            -96.5153420001918,
                            42.9841410001288
                        ],
                        [
                            -96.5183640001812,
                            42.9851869998525
                        ],
                        [
                            -96.516830999996,
                            42.9810200001499
                        ],
                        [
                            -96.5208379999996,
                            42.9803140001686
                        ],
                        [
                            -96.515773000187,
                            42.9728440002848
                        ],
                        [
                            -96.5051599997899,
                            42.971095000096
                        ],
                        [
                            -96.5026819997736,
                            42.9676050001141
                        ],
                        [
                            -96.5018390000497,
                            42.9607769999387
                        ],
                        [
                            -96.4989629996501,
                            42.9578020000326
                        ],
                        [
                            -96.5089190000963,
                            42.9537149997073
                        ],
                        [
                            -96.507907000318,
                            42.9483909999086
                        ],
                        [
                            -96.5092880001672,
                            42.9451530001217
                        ],
                        [
                            -96.514198000169,
                            42.9448510000629
                        ],
                        [
                            -96.5198699997039,
                            42.939969000108
                        ],
                        [
                            -96.5198580000083,
                            42.9379889997591
                        ],
                        [
                            -96.5164500002295,
                            42.9352000002252
                        ],
                        [
                            -96.516835000194,
                            42.932347000325
                        ],
                        [
                            -96.5197989995588,
                            42.9319759999474
                        ],
                        [
                            -96.521704000188,
                            42.9351449998482
                        ],
                        [
                            -96.5253119999833,
                            42.9356269999151
                        ],
                        [
                            -96.5340609997592,
                            42.9281659999995
                        ],
                        [
                            -96.5329519998965,
                            42.9247539997588
                        ],
                        [
                            -96.534105999965,
                            42.9237100002012
                        ],
                        [
                            -96.5364090000791,
                            42.9231060000704
                        ],
                        [
                            -96.5399259996379,
                            42.9250660000564
                        ],
                        [
                            -96.5412830000959,
                            42.9239269999414
                        ],
                        [
                            -96.5411640002703,
                            42.9194060001828
                        ],
                        [
                            -96.5377589999662,
                            42.9173180001413
                        ],
                        [
                            -96.5400999997152,
                            42.9123180000178
                        ],
                        [
                            -96.5378090001949,
                            42.9109540000133
                        ],
                        [
                            -96.5385859997966,
                            42.907840000249
                        ],
                        [
                            -96.5364669998055,
                            42.9056429998798
                        ],
                        [
                            -96.542416000248,
                            42.9050540000905
                        ],
                        [
                            -96.5429300000839,
                            42.9037560000851
                        ],
                        [
                            -96.5395309996277,
                            42.8999389996863
                        ],
                        [
                            -96.5361239996738,
                            42.9008789999308
                        ],
                        [
                            -96.528872999923,
                            42.8979259998646
                        ],
                        [
                            -96.5296920002557,
                            42.8942179997751
                        ],
                        [
                            -96.5252869998689,
                            42.8919099997131
                        ],
                        [
                            -96.5272100000415,
                            42.890617000076
                        ],
                        [
                            -96.5398379997743,
                            42.8898030002648
                        ],
                        [
                            -96.5376950003921,
                            42.8867479997543
                        ],
                        [
                            -96.5434849999277,
                            42.8837650002705
                        ],
                        [
                            -96.5376700002777,
                            42.8784620002809
                        ],
                        [
                            -96.5471460003248,
                            42.8739550001586
                        ],
                        [
                            -96.549701000334,
                            42.8698800001486
                        ],
                        [
                            -96.5461739998312,
                            42.8675519997994
                        ],
                        [
                            -96.5465730000394,
                            42.8639139996917
                        ],
                        [
                            -96.5504510001715,
                            42.8635479997501
                        ],
                        [
                            -96.5461000002114,
                            42.8611610001804
                        ],
                        [
                            -96.5468190000867,
                            42.8574359999967
                        ],
                        [
                            -96.5452120002817,
                            42.8570810002964
                        ],
                        [
                            -96.5426199995642,
                            42.8596880003057
                        ],
                        [
                            -96.5414549996251,
                            42.856868000155
                        ],
                        [
                            -96.5452619996121,
                            42.8498540002402
                        ],
                        [
                            -96.5494539995635,
                            42.8512079998132
                        ],
                        [
                            -96.5501950000784,
                            42.8475839996765
                        ],
                        [
                            -96.5560830004217,
                            42.8492309998221
                        ],
                        [
                            -96.5538050004219,
                            42.8431819999704
                        ],
                        [
                            -96.5485980003192,
                            42.8408909997184
                        ],
                        [
                            -96.5503220003001,
                            42.8375309997222
                        ],
                        [
                            -96.5539860001719,
                            42.8361060002397
                        ],
                        [
                            -96.5585429999963,
                            42.8395120003056
                        ],
                        [
                            -96.5609440000197,
                            42.839133999723
                        ],
                        [
                            -96.5628620001694,
                            42.8358869997762
                        ],
                        [
                            -96.5630119999572,
                            42.8313559998227
                        ],
                        [
                            -96.5615180001301,
                            42.829466999777
                        ],
                        [
                            -96.5636039996109,
                            42.8281740002424
                        ],
                        [
                            -96.5696829997498,
                            42.8345000001166
                        ],
                        [
                            -96.5683820002666,
                            42.83778100023
                        ],
                        [
                            -96.5744239996973,
                            42.8379780001154
                        ],
                        [
                            -96.5781639997371,
                            42.8347270000945
                        ],
                        [
                            -96.5797170000138,
                            42.8356259998524
                        ],
                        [
                            -96.5783300003168,
                            42.8377989997006
                        ],
                        [
                            -96.581470000307,
                            42.8377259998757
                        ],
                        [
                            -96.5823049996349,
                            42.8337499998737
                        ],
                        [
                            -96.5776540000992,
                            42.8277910003208
                        ],
                        [
                            -96.5805539998899,
                            42.8248800001582
                        ],
                        [
                            -96.5858079998483,
                            42.8237759999117
                        ],
                        [
                            -96.5841089999818,
                            42.8190049996889
                        ],
                        [
                            -96.5889429998156,
                            42.8184870001294
                        ],
                        [
                            -96.5907239996979,
                            42.8153829998122
                        ],
                        [
                            -96.5959450000444,
                            42.8149540002767
                        ],
                        [
                            -96.5956260002021,
                            42.8103430001455
                        ],
                        [
                            -96.5904650001301,
                            42.8082970001434
                        ],
                        [
                            -96.5940760002985,
                            42.7982349997945
                        ],
                        [
                            -96.5964499996594,
                            42.7975900000369
                        ],
                        [
                            -96.5981719999905,
                            42.7999659999671
                        ],
                        [
                            -96.5996830004344,
                            42.7984350002085
                        ],
                        [
                            -96.5952139995752,
                            42.7928960001198
                        ],
                        [
                            -96.6050069998147,
                            42.7916959998424
                        ],
                        [
                            -96.6023449996754,
                            42.7881129998431
                        ],
                        [
                            -96.6044400002753,
                            42.7831100001563
                        ],
                        [
                            -96.6079929998191,
                            42.7844340001499
                        ],
                        [
                            -96.6104890002771,
                            42.7825780002673
                        ],
                        [
                            -96.6125630000623,
                            42.7846199997689
                        ],
                        [
                            -96.6147099996425,
                            42.7822469999231
                        ],
                        [
                            -96.6155690001582,
                            42.7850159997548
                        ],
                        [
                            -96.6195030003668,
                            42.7840429997987
                        ],
                        [
                            -96.6219849996828,
                            42.7768299999343
                        ],
                        [
                            -96.6269850000963,
                            42.7753019997166
                        ],
                        [
                            -96.6263609997572,
                            42.7714029999775
                        ],
                        [
                            -96.6292449996544,
                            42.7719939998326
                        ],
                        [
                            -96.6313090002922,
                            42.7696499998272
                        ],
                        [
                            -96.6330540001895,
                            42.7713320002738
                        ],
                        [
                            -96.6363819996023,
                            42.769740000015
                        ],
                        [
                            -96.6355079999163,
                            42.7680340000942
                        ],
                        [
                            -96.6327150002558,
                            42.7686199997956
                        ],
                        [
                            -96.6315219998292,
                            42.7660950002468
                        ],
                        [
                            -96.6360000000109,
                            42.7654210002302
                        ],
                        [
                            -96.63609999957,
                            42.7639860001979
                        ],
                        [
                            -96.628129000119,
                            42.7571850003072
                        ],
                        [
                            -96.6272429998391,
                            42.7544130001132
                        ],
                        [
                            -96.6255659997139,
                            42.7544569997865
                        ],
                        [
                            -96.6228330003278,
                            42.7583980000426
                        ],
                        [
                            -96.620267000448,
                            42.757584999942
                        ],
                        [
                            -96.6192620003424,
                            42.7546089997966
                        ],
                        [
                            -96.6300189997812,
                            42.750695999991
                        ],
                        [
                            -96.6300029998877,
                            42.7471420000229
                        ],
                        [
                            -96.6321550003891,
                            42.7452100002232
                        ],
                        [
                            -96.6294289997774,
                            42.7436410001475
                        ],
                        [
                            -96.6356119996734,
                            42.741893999874
                        ],
                        [
                            -96.6305860002189,
                            42.7352860001389
                        ],
                        [
                            -96.6394849997826,
                            42.7369279999725
                        ],
                        [
                            -96.6367199997111,
                            42.7332309999743
                        ],
                        [
                            -96.6305219997465,
                            42.7302340002794
                        ],
                        [
                            -96.6347039996522,
                            42.7261220000807
                        ],
                        [
                            -96.6341889999914,
                            42.724305999951
                        ],
                        [
                            -96.6244140001936,
                            42.7252119998537
                        ],
                        [
                            -96.6245449997148,
                            42.7209440003189
                        ],
                        [
                            -96.6280110001182,
                            42.7177979998251
                        ],
                        [
                            -96.624799000158,
                            42.7159549996837
                        ],
                        [
                            -96.6244059997976,
                            42.7140110002987
                        ],
                        [
                            -96.6268550003999,
                            42.7099259999577
                        ],
                        [
                            -96.6293330004162,
                            42.7090149997811
                        ],
                        [
                            -96.6301540003988,
                            42.7054379996801
                        ],
                        [
                            -96.625528999904,
                            42.7041809998234
                        ],
                        [
                            -96.625304999598,
                            42.7071290000476
                        ],
                        [
                            -96.6239570002591,
                            42.7076000000434
                        ],
                        [
                            -96.619193999672,
                            42.6998120002481
                        ],
                        [
                            -96.6160779999712,
                            42.7004370001081
                        ],
                        [
                            -96.6151480002087,
                            42.6984330001679
                        ],
                        [
                            -96.6098010003639,
                            42.7023239999652
                        ],
                        [
                            -96.6055959999937,
                            42.7020200000226
                        ],
                        [
                            -96.6046029995836,
                            42.6984729997939
                        ],
                        [
                            -96.6096239999136,
                            42.6984249998435
                        ],
                        [
                            -96.6120860000363,
                            42.6955799998518
                        ],
                        [
                            -96.6056429998493,
                            42.6946830001878
                        ],
                        [
                            -96.6013809995777,
                            42.6990029998325
                        ],
                        [
                            -96.5996370004036,
                            42.6988540001056
                        ],
                        [
                            -96.5976239998194,
                            42.6956370002958
                        ],
                        [
                            -96.5939280001605,
                            42.6943270002282
                        ],
                        [
                            -96.5965390002462,
                            42.6919319999508
                        ],
                        [
                            -96.5936129996179,
                            42.6897939997313
                        ],
                        [
                            -96.5965590003377,
                            42.6882879998638
                        ],
                        [
                            -96.5993810003106,
                            42.6889040002284
                        ],
                        [
                            -96.5996390000534,
                            42.6868929997637
                        ],
                        [
                            -96.5922950004162,
                            42.6871600000231
                        ],
                        [
                            -96.5918519998271,
                            42.6905460001662
                        ],
                        [
                            -96.589841999616,
                            42.6910409997253
                        ],
                        [
                            -96.5863419997757,
                            42.6897939997313
                        ],
                        [
                            -96.58618600014,
                            42.6831769998074
                        ],
                        [
                            -96.5751800002808,
                            42.6827569997109
                        ],
                        [
                            -96.5749719998684,
                            42.6807299997637
                        ],
                        [
                            -96.578757000114,
                            42.6781229999457
                        ],
                        [
                            -96.5726229997235,
                            42.6789259997874
                        ],
                        [
                            -96.5720529998111,
                            42.676703999978
                        ],
                        [
                            -96.5743540002754,
                            42.6736770002722
                        ],
                        [
                            -96.5783989999138,
                            42.6718399998508
                        ],
                        [
                            -96.5715669995643,
                            42.6708960000529
                        ],
                        [
                            -96.5697070000393,
                            42.6749699999436
                        ],
                        [
                            -96.5669639997091,
                            42.6764169997194
                        ],
                        [
                            -96.5643889996084,
                            42.6713280001837
                        ],
                        [
                            -96.5557700004272,
                            42.66420699985
                        ],
                        [
                            -96.5598080003929,
                            42.6628680000949
                        ],
                        [
                            -96.5589990001059,
                            42.6576790001809
                        ],
                        [
                            -96.546588000108,
                            42.6614849997123
                        ],
                        [
                            -96.5423650001944,
                            42.6609340001247
                        ],
                        [
                            -96.5378180004157,
                            42.6555999998582
                        ],
                        [
                            -96.537471999911,
                            42.6459510001919
                        ],
                        [
                            -96.5266300000833,
                            42.64145000006
                        ],
                        [
                            -96.5251429999289,
                            42.6347460002725
                        ],
                        [
                            -96.5216010002558,
                            42.6348830002145
                        ],
                        [
                            -96.5160839996333,
                            42.6302749999299
                        ],
                        [
                            -96.5155510004292,
                            42.6249829998823
                        ],
                        [
                            -96.5190389996756,
                            42.6189860001417
                        ],
                        [
                            -96.522581000247,
                            42.6175760000565
                        ],
                        [
                            -96.5281309995831,
                            42.6184399997475
                        ],
                        [
                            -96.5313119995812,
                            42.6166469998856
                        ],
                        [
                            -96.5299369995798,
                            42.6104710001516
                        ],
                        [
                            -96.5257179998642,
                            42.6092759996782
                        ],
                        [
                            -96.5176629998493,
                            42.6166469998856
                        ],
                        [
                            -96.5094530000236,
                            42.6127040002927
                        ],
                        [
                            -96.5001829998409,
                            42.5941040002796
                        ],
                        [
                            -96.5010819996412,
                            42.5893110000963
                        ],
                        [
                            -96.4912709998584,
                            42.5868059998588
                        ],
                        [
                            -96.4954989997948,
                            42.5827919999368
                        ],
                        [
                            -96.4956539996055,
                            42.5795410000207
                        ],
                        [
                            -96.4857969997919,
                            42.575019999881
                        ],
                        [
                            -96.4861459997714,
                            42.5731490000398
                        ],
                        [
                            -96.4989969999853,
                            42.5608319997302
                        ],
                        [
                            -96.4985440002488,
                            42.5581160001837
                        ],
                        [
                            -96.4955399998027,
                            42.5564400002511
                        ],
                        [
                            -96.4769600001525,
                            42.555971999926
                        ],
                        [
                            -96.4765810000358,
                            42.5464670000504
                        ],
                        [
                            -96.4798130000875,
                            42.5296010000517
                        ],
                        [
                            -96.4790070001736,
                            42.5263940003028
                        ],
                        [
                            -96.4799489996316,
                            42.5242460000892
                        ],
                        [
                            -96.4928690003409,
                            42.5190599999505
                        ],
                        [
                            -96.4935699997745,
                            42.5156260003067
                        ],
                        [
                            -96.4881629996552,
                            42.5112839999038
                        ],
                        [
                            -96.4790230000671,
                            42.5108429997052
                        ],
                        [
                            -96.4760479999334,
                            42.5077830001758
                        ],
                        [
                            -96.4733339999155,
                            42.5035360000175
                        ],
                        [
                            -96.4779080003567,
                            42.4946679997947
                        ],
                        [
                            -96.4762620001936,
                            42.4899689999851
                        ],
                        [
                            -96.4715050003526,
                            42.4892680000273
                        ],
                        [
                            -96.4695729999593,
                            42.4914060000015
                        ],
                        [
                            -96.463512000262,
                            42.4904639999429
                        ],
                        [
                            -96.4561850003432,
                            42.4925760001291
                        ],
                        [
                            -96.4454830002576,
                            42.4906299999549
                        ],
                        [
                            -96.4787760001949,
                            42.4797019997909
                        ],
                        [
                            -96.489494000174,
                            42.4801089999279
                        ],
                        [
                            -96.501319000366,
                            42.4827490002257
                        ],
                        [
                            -96.5085869998352,
                            42.4866980001962
                        ],
                        [
                            -96.5158369997611,
                            42.4942230002348
                        ],
                        [
                            -96.5206279999373,
                            42.5046930002459
                        ],
                        [
                            -96.5287780003867,
                            42.5132670002276
                        ],
                        [
                            -96.5379899999449,
                            42.5181289997451
                        ],
                        [
                            -96.5487890001149,
                            42.5205469998701
                        ],
                        [
                            -96.5577760004404,
                            42.52038000007
                        ],
                        [
                            -96.5678929996467,
                            42.5178749998282
                        ],
                        [
                            -96.5843500000062,
                            42.5078360000111
                        ],
                        [
                            -96.5911179998832,
                            42.5054070001316
                        ],
                        [
                            -96.6034700003299,
                            42.504460000303
                        ],
                        [
                            -96.6114879996365,
                            42.5060860002752
                        ],
                        [
                            -96.6259609997241,
                            42.5135780000696
                        ],
                        [
                            -96.6281809999976,
                            42.5169640000799
                        ],
                        [
                            -96.6327939998985,
                            42.5286980001245
                        ],
                        [
                            -96.633322999803,
                            42.5402099998104
                        ],
                        [
                            -96.6353299996411,
                            42.5476400000242
                        ],
                        [
                            -96.6435849996726,
                            42.5576069998011
                        ],
                        [
                            -96.6587520004194,
                            42.5664239998445
                        ],
                        [
                            -96.6759489996722,
                            42.5716010002383
                        ],
                        [
                            -96.6840589999388,
                            42.5764619999799
                        ],
                        [
                            -96.706232000214,
                            42.5992220001953
                        ],
                        [
                            -96.7098240001158,
                            42.6051000001556
                        ],
                        [
                            -96.7115480000967,
                            42.6147589999908
                        ],
                        [
                            -96.7094869998319,
                            42.6219310003214
                        ],
                        [
                            -96.6968530002513,
                            42.63759399983
                        ],
                        [
                            -96.6877899997577,
                            42.6459930000274
                        ],
                        [
                            -96.6870800001033,
                            42.6520930000332
                        ],
                        [
                            -96.6912679998567,
                            42.6561979998261
                        ],
                        [
                            -96.6976390000737,
                            42.6591410002084
                        ],
                        [
                            -96.7280270001975,
                            42.6668799997687
                        ],
                        [
                            -96.7469480003295,
                            42.6662220003187
                        ],
                        [
                            -96.7640609999168,
                            42.6619830000012
                        ],
                        [
                            -96.7767820004344,
                            42.6627159998634
                        ],
                        [
                            -96.7932349996976,
                            42.6660209997434
                        ],
                        [
                            -96.800836999976,
                            42.6696610000182
                        ],
                        [
                            -96.8021769998173,
                            42.6722349999382
                        ],
                        [
                            -96.8004829999736,
                            42.6924640002973
                        ],
                        [
                            -96.8016160001257,
                            42.6987759997382
                        ],
                        [
                            -96.8062130001331,
                            42.7041540000687
                        ],
                        [
                            -96.8434190003626,
                            42.7120240000142
                        ],
                        [
                            -96.8604359996904,
                            42.7207970001463
                        ],
                        [
                            -96.872788999962,
                            42.7240959997585
                        ],
                        [
                            -96.886845000298,
                            42.7252220002333
                        ],
                        [
                            -96.9067969995766,
                            42.7337999998206
                        ],
                        [
                            -96.9241560001095,
                            42.7303270000455
                        ],
                        [
                            -96.9367729999717,
                            42.7234279997806
                        ],
                        [
                            -96.9489019999373,
                            42.7194649999608
                        ],
                        [
                            -96.9615759997009,
                            42.7198410001747
                        ],
                        [
                            -96.9647759999656,
                            42.7224549998794
                        ],
                        [
                            -96.9658329999497,
                            42.7270960002847
                        ],
                        [
                            -96.9612910001939,
                            42.7365690000391
                        ],
                        [
                            -96.9612300000945,
                            42.7406230002193
                        ],
                        [
                            -96.9688800000535,
                            42.7542779996914
                        ],
                        [
                            -96.9791200001817,
                            42.7600900000194
                        ],
                        [
                            -96.9928199999673,
                            42.7594810001916
                        ],
                        [
                            -97.0248500000561,
                            42.7624299997117
                        ],
                        [
                            -97.0416789999613,
                            42.7679679998661
                        ],
                        [
                            -97.0672299998781,
                            42.7723230002484
                        ],
                        [
                            -97.1126459999332,
                            42.7691289999729
                        ],
                        [
                            -97.1302570003612,
                            42.7715409997354
                        ],
                        [
                            -97.1344610000082,
                            42.7744940000558
                        ],
                        [
                            -97.1382160001166,
                            42.7834279998204
                        ],
                        [
                            -97.1507629996586,
                            42.7955659998367
                        ],
                        [
                            -97.1669780001687,
                            42.8020870001972
                        ],
                        [
                            -97.2004309999396,
                            42.8054850000229
                        ],
                        [
                            -97.2101260002698,
                            42.8092960001182
                        ],
                        [
                            -97.2130839997868,
                            42.8130070002122
                        ],
                        [
                            -97.2139569996479,
                            42.8201429996981
                        ],
                        [
                            -97.2182690001483,
                            42.8295610002308
                        ],
                        [
                            -97.2180459996672,
                            42.8451129998685
                        ],
                        [
                            -97.2378680001478,
                            42.8531389997373
                        ],
                        [
                            -97.2503300001993,
                            42.8556119997909
                        ],
                        [
                            -97.2692250003921,
                            42.8526020002937
                        ],
                        [
                            -97.289858999736,
                            42.8554990002296
                        ],
                        [
                            -97.3020749997403,
                            42.8656599998134
                        ],
                        [
                            -97.3066769997705,
                            42.8676040002637
                        ],
                        [
                            -97.3361560000481,
                            42.8568019998323
                        ],
                        [
                            -97.3595689997073,
                            42.854816000227
                        ],
                        [
                            -97.3686430000715,
                            42.8584189999435
                        ],
                        [
                            -97.3766949997134,
                            42.8651950001775
                        ],
                        [
                            -97.3939639998346,
                            42.8642499997894
                        ],
                        [
                            -97.3993030001818,
                            42.864835000199
                        ],
                        [
                            -97.4074090002503,
                            42.8683910000163
                        ],
                        [
                            -97.4170660000474,
                            42.8659180001734
                        ],
                        [
                            -97.4334549997365,
                            42.8503929999866
                        ],
                        [
                            -97.4437559999641,
                            42.8460180001302
                        ],
                        [
                            -97.4521769996769,
                            42.8460480000757
                        ],
                        [
                            -97.4705289997215,
                            42.8504549996815
                        ],
                        [
                            -97.4870100003704,
                            42.8505820000412
                        ],
                        [
                            -97.4962300003244,
                            42.8532309997179
                        ],
                        [
                            -97.500341000085,
                            42.8572200002459
                        ],
                        [
                            -97.5048469998558,
                            42.8584769997696
                        ],
                        [
                            -97.5223710002452,
                            42.851978999934
                        ],
                        [
                            -97.5578939996033,
                            42.8475320001328
                        ],
                        [
                            -97.5784740003172,
                            42.8505439998676
                        ],
                        [
                            -97.60376199957,
                            42.8583290003098
                        ],
                        [
                            -97.6118109997372,
                            42.8583670003028
                        ],
                        [
                            -97.6579030000099,
                            42.844400000199
                        ],
                        [
                            -97.686476999741,
                            42.8422120000721
                        ],
                        [
                            -97.7206829997224,
                            42.847316999769
                        ],
                        [
                            -97.7743809995731,
                            42.8497400002815
                        ],
                        [
                            -97.8171119999227,
                            42.8617769999833
                        ],
                        [
                            -97.8285730000666,
                            42.8688019996893
                        ],
                        [
                            -97.8419659997055,
                            42.8681660001559
                        ],
                        [
                            -97.8581130004436,
                            42.8650599996908
                        ],
                        [
                            -97.8755309996293,
                            42.8586560001952
                        ],
                        [
                            -97.8771759999675,
                            42.8543219997194
                        ],
                        [
                            -97.8760040003557,
                            42.8476589999165
                        ],
                        [
                            -97.8791200000564,
                            42.8436020000363
                        ],
                        [
                            -97.8800029999633,
                            42.8353269998317
                        ],
                        [
                            -97.8886470001571,
                            42.8171770003093
                        ],
                        [
                            -97.9090360001771,
                            42.7948330000387
                        ],
                        [
                            -97.9159949998498,
                            42.789823999993
                        ],
                        [
                            -97.9214799997869,
                            42.7882749997825
                        ],
                        [
                            -97.9367460002679,
                            42.7756829997297
                        ],
                        [
                            -97.950169999869,
                            42.7695499996849
                        ],
                        [
                            -97.962067000031,
                            42.768636999882
                        ],
                        [
                            -97.977614999646,
                            42.7698469998264
                        ],
                        [
                            -98.0003710002525,
                            42.7631789998008
                        ],
                        [
                            -98.0057659997779,
                            42.7640860000896
                        ],
                        [
                            -98.0172560002341,
                            42.7623289999498
                        ],
                        [
                            -98.0350709999782,
                            42.7641139999512
                        ],
                        [
                            -98.0420559995903,
                            42.767220000059
                        ],
                        [
                            -98.0516759995774,
                            42.7686669997903
                        ],
                        [
                            -98.0599030000198,
                            42.7726629998861
                        ],
                        [
                            -98.062998999629,
                            42.7810009999207
                        ],
                        [
                            -98.0674860000316,
                            42.7846349998096
                        ],
                        [
                            -98.082910999623,
                            42.7942000001766
                        ],
                        [
                            -98.0947209997463,
                            42.7991579998689
                        ],
                        [
                            -98.1124379995812,
                            42.8132019999794
                        ],
                        [
                            -98.127673999925,
                            42.8199559998776
                        ],
                        [
                            -98.1381030001991,
                            42.8325430000526
                        ],
                        [
                            -98.1471089998929,
                            42.8396310002177
                        ],
                        [
                            -98.1675230000272,
                            42.8369249999586
                        ],
                        [
                            -98.1898109999302,
                            42.841642999982
                        ],
                        [
                            -98.219825999785,
                            42.853157000002
                        ],
                        [
                            -98.2319220001386,
                            42.8611400002883
                        ],
                        [
                            -98.2468300004194,
                            42.8683969999399
                        ],
                        [
                            -98.2518099998431,
                            42.8728239997738
                        ],
                        [
                            -98.2582759995963,
                            42.8743900003074
                        ],
                        [
                            -98.2800070000057,
                            42.8749959998711
                        ],
                        [
                            -98.3258640001018,
                            42.8865000002555
                        ],
                        [
                            -98.3324219999165,
                            42.890499999819
                        ],
                        [
                            -98.3379899996943,
                            42.8977599998145
                        ],
                        [
                            -98.3462299996572,
                            42.9027469997944
                        ],
                        [
                            -98.4078240004124,
                            42.9257499999105
                        ],
                        [
                            -98.4207400000254,
                            42.9319239998117
                        ],
                        [
                            -98.4309340001229,
                            42.931504000192
                        ],
                        [
                            -98.4372850002484,
                            42.9283930002821
                        ],
                        [
                            -98.4441450001869,
                            42.929242000168
                        ],
                        [
                            -98.448308999651,
                            42.9364279996902
                        ],
                        [
                            -98.4673560001797,
                            42.9475560001641
                        ],
                        [
                            -98.4904829996086,
                            42.977947999985
                        ],
                        [
                            -98.4957469996128,
                            42.988032000067
                        ],
                        [
                            -98.4985500002174,
                            42.9985589998426
                        ],
                        [
                            -99.9629850002188,
                            42.9976610001865
                        ],
                        [
                            -100.30911399996,
                            42.9992930002922
                        ],
                        [
                            -101.633006999738,
                            42.9962549998049
                        ],
                        [
                            -101.875423999741,
                            42.9994149999718
                        ],
                        [
                            -103.966199999895,
                            43.0017070002588
                        ],
                        [
                            -104.053028000375,
                            43.0005849997737
                        ],
                        [
                            -104.055099999612,
                            43.5650150002317
                        ],
                        [
                            -104.055913000097,
                            44.8749860003098
                        ],
                        [
                            -104.057878999927,
                            44.9976049997522
                        ],
                        [
                            -104.039694000287,
                            44.9980699999405
                        ],
                        [
                            -104.040604999783,
                            45.4373490000155
                        ],
                        [
                            -104.042595999728,
                            45.7499419997496
                        ],
                        [
                            -104.045546999572,
                            45.9453079998037
                        ],
                        [
                            -100.499371000321,
                            45.9436530002873
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 44.4467957,
            "name": "South Dakota",
            "intptlon": -100.2381762,
            "geo_point_2d": [
                44.4361504276,
                -100.230377123
            ],
            "geoid": "46",
            "mtfcc": "G4000",
            "region": 2
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -100.230377123,
                44.4361504276
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "a89c50921f9414cfca5ea7a2edb9ca0b27da05b0",
        "fields": {
            "arealand": "-1970221771",
            "objectid": 23,
            "basename": "Alabama",
            "stusab": "AL",
            "statens": "01779775",
            "centlon": -86.8467951,
            "state": "01",
            "gid": 1,
            "centlat": 32.7436859,
            "division": 6,
            "areawater": "298643766",
            "oid": "285969313",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -88.1399880001326,
                            34.5817030002605
                        ],
                        [
                            -88.1184070004093,
                            34.7242920002829
                        ],
                        [
                            -88.0978879997948,
                            34.8922019999876
                        ],
                        [
                            -88.0999990002883,
                            34.8940950000004
                        ],
                        [
                            -88.1319630002549,
                            34.9049580003041
                        ],
                        [
                            -88.1524130003741,
                            34.9197409996967
                        ],
                        [
                            -88.1614369996113,
                            34.9320440002347
                        ],
                        [
                            -88.17253899973,
                            34.9569000002504
                        ],
                        [
                            -88.1980460001642,
                            34.9902849997762
                        ],
                        [
                            -88.2029589996408,
                            35.00802799984
                        ],
                        [
                            -87.4188599997442,
                            35.0026679998649
                        ],
                        [
                            -86.8621470004449,
                            34.9919569999865
                        ],
                        [
                            -86.6695640004039,
                            34.9923390000331
                        ],
                        [
                            -86.4681150004112,
                            34.9904799997232
                        ],
                        [
                            -86.3749659999432,
                            34.9917379996971
                        ],
                        [
                            -85.824023000088,
                            34.9881309997765
                        ],
                        [
                            -85.6051650002321,
                            34.9846780002776
                        ],
                        [
                            -85.5905010003488,
                            34.8989449996583
                        ],
                        [
                            -85.5614160002565,
                            34.7500789998558
                        ],
                        [
                            -85.470450000252,
                            34.3282390000213
                        ],
                        [
                            -85.4294699996475,
                            34.1250959997144
                        ],
                        [
                            -85.4067480002745,
                            34.002313999772
                        ],
                        [
                            -85.304439000127,
                            33.4828839996648
                        ],
                        [
                            -85.233108000107,
                            33.1117140001027
                        ],
                        [
                            -85.1841310001122,
                            32.870525000153
                        ],
                        [
                            -85.1843999997257,
                            32.8613169999417
                        ],
                        [
                            -85.1771249996855,
                            32.8538899997092
                        ],
                        [
                            -85.1686880000791,
                            32.8525869997559
                        ],
                        [
                            -85.1623100001894,
                            32.8564790003753
                        ],
                        [
                            -85.1562889997768,
                            32.8505239998691
                        ],
                        [
                            -85.1533880001612,
                            32.8448649999539
                        ],
                        [
                            -85.1540130003252,
                            32.8407440001899
                        ],
                        [
                            -85.1641079997902,
                            32.8352949999123
                        ],
                        [
                            -85.1683419995744,
                            32.8285159996295
                        ],
                        [
                            -85.1648450001072,
                            32.8197890002016
                        ],
                        [
                            -85.1686439996982,
                            32.8142450000796
                        ],
                        [
                            -85.1680479998466,
                            32.8118409997228
                        ],
                        [
                            -85.1520249998554,
                            32.7941790003146
                        ],
                        [
                            -85.1463320004041,
                            32.7915660001068
                        ],
                        [
                            -85.1421830001104,
                            32.792136000183
                        ],
                        [
                            -85.1396649999111,
                            32.7851500000775
                        ],
                        [
                            -85.1348930000015,
                            32.7844609996977
                        ],
                        [
                            -85.1310990004333,
                            32.7803129998829
                        ],
                        [
                            -85.1262189996704,
                            32.7791889997857
                        ],
                        [
                            -85.122326000368,
                            32.7743829997856
                        ],
                        [
                            -85.1237380001794,
                            32.7720310002482
                        ],
                        [
                            -85.1308840003482,
                            32.7695620002847
                        ],
                        [
                            -85.1366920003255,
                            32.7711720001326
                        ],
                        [
                            -85.1374170000486,
                            32.7671929998397
                        ],
                        [
                            -85.1416220004188,
                            32.7641620001079
                        ],
                        [
                            -85.1429870003745,
                            32.7599340002369
                        ],
                        [
                            -85.1394549998488,
                            32.7518290000278
                        ],
                        [
                            -85.1331380000585,
                            32.7446310003699
                        ],
                        [
                            -85.1282380001024,
                            32.7422320003254
                        ],
                        [
                            -85.1185500003432,
                            32.7408169999856
                        ],
                        [
                            -85.1133289999967,
                            32.7357509998673
                        ],
                        [
                            -85.1141380002837,
                            32.7304320000248
                        ],
                        [
                            -85.1208380003887,
                            32.7229320002274
                        ],
                        [
                            -85.1227380000967,
                            32.7160320001583
                        ],
                        [
                            -85.1218379995731,
                            32.7126320002396
                        ],
                        [
                            -85.1181379997162,
                            32.7094319998013
                        ],
                        [
                            -85.1200380003225,
                            32.7065330000967
                        ],
                        [
                            -85.1170370002495,
                            32.692032999971
                        ],
                        [
                            -85.1126369998856,
                            32.6834340002812
                        ],
                        [
                            -85.1040370000727,
                            32.6796340003463
                        ],
                        [
                            -85.0935359998285,
                            32.6697339998539
                        ],
                        [
                            -85.0930360002363,
                            32.6644340000174
                        ],
                        [
                            -85.0884829997115,
                            32.657758000338
                        ],
                        [
                            -85.0897360004125,
                            32.6556339999769
                        ],
                        [
                            -85.0983360002255,
                            32.6520340003737
                        ],
                        [
                            -85.1043369996483,
                            32.6464339998036
                        ],
                        [
                            -85.1054320001656,
                            32.6449339999672
                        ],
                        [
                            -85.104531999642,
                            32.6410110001585
                        ],
                        [
                            -85.1015540000335,
                            32.6403929998993
                        ],
                        [
                            -85.1003880002694,
                            32.6371180002683
                        ],
                        [
                            -85.0988989995669,
                            32.6369349999032
                        ],
                        [
                            -85.1000889996204,
                            32.6345759999075
                        ],
                        [
                            -85.0962180000593,
                            32.6348289996882
                        ],
                        [
                            -85.0966840002146,
                            32.6361970003363
                        ],
                        [
                            -85.0900359999882,
                            32.6340360003299
                        ],
                        [
                            -85.0831180003233,
                            32.6361610001094
                        ],
                        [
                            -85.0838360003737,
                            32.6319359999072
                        ],
                        [
                            -85.0900359999882,
                            32.6276359997094
                        ],
                        [
                            -85.090886000283,
                            32.6251349996602
                        ],
                        [
                            -85.0879360002637,
                            32.6205200003076
                        ],
                        [
                            -85.0822400004394,
                            32.6162640001955
                        ],
                        [
                            -85.0789749998774,
                            32.6001689999086
                        ],
                        [
                            -85.0675349996499,
                            32.5795460001098
                        ],
                        [
                            -85.0619829997656,
                            32.5782490000648
                        ],
                        [
                            -85.0579020001422,
                            32.5746499997435
                        ],
                        [
                            -85.056926000349,
                            32.5712410000968
                        ],
                        [
                            -85.045470000228,
                            32.5617229999511
                        ],
                        [
                            -85.0436619996832,
                            32.5565230001448
                        ],
                        [
                            -85.0357260003922,
                            32.55396300019
                        ],
                        [
                            -85.0313889997775,
                            32.5441710003186
                        ],
                        [
                            -85.0261890002458,
                            32.5448430001315
                        ],
                        [
                            -85.0207970001951,
                            32.5427640003702
                        ],
                        [
                            -85.0220449999749,
                            32.5408269999908
                        ],
                        [
                            -85.0208769996627,
                            32.535852000311
                        ],
                        [
                            -85.0152610002044,
                            32.5311149997593
                        ],
                        [
                            -85.0152610002044,
                            32.5276759998694
                        ],
                        [
                            -85.0070999998842,
                            32.5238679997796
                        ],
                        [
                            -85.006380000184,
                            32.5184930000448
                        ],
                        [
                            -85.0015320001065,
                            32.514740999647
                        ],
                        [
                            -85.0009320000568,
                            32.5087409998219
                        ],
                        [
                            -84.9989319998914,
                            32.5063409997353
                        ],
                        [
                            -84.999832000415,
                            32.5043400003235
                        ],
                        [
                            -84.9965319996929,
                            32.5016410000874
                        ],
                        [
                            -84.9988320003323,
                            32.4970410003608
                        ],
                        [
                            -84.9954320000511,
                            32.4889420000609
                        ],
                        [
                            -84.9949309997357,
                            32.4801419999826
                        ],
                        [
                            -84.9952279998366,
                            32.4752989996983
                        ],
                        [
                            -84.9985310000334,
                            32.4687420000301
                        ],
                        [
                            -84.9953299999439,
                            32.4532429997977
                        ],
                        [
                            -84.993529999795,
                            32.4507429999868
                        ],
                        [
                            -84.9838310001651,
                            32.4456419998519
                        ],
                        [
                            -84.971831000071,
                            32.4428430000672
                        ],
                        [
                            -84.967030999674,
                            32.4353429998348
                        ],
                        [
                            -84.9630300004166,
                            32.424244000303
                        ],
                        [
                            -84.9718300002461,
                            32.416244000027
                        ],
                        [
                            -84.9794309998012,
                            32.4122439996728
                        ],
                        [
                            -84.9809539999407,
                            32.4060640001709
                        ],
                        [
                            -84.9798979997815,
                            32.4000970003727
                        ],
                        [
                            -84.9747459999303,
                            32.3956010001845
                        ],
                        [
                            -84.969274000412,
                            32.3934409998409
                        ],
                        [
                            -84.9687300004388,
                            32.3914570001001
                        ],
                        [
                            -84.9783620001216,
                            32.3904970001164
                        ],
                        [
                            -84.9830019997867,
                            32.3919530003288
                        ],
                        [
                            -84.9884099997309,
                            32.3852159996636
                        ],
                        [
                            -84.9862820004173,
                            32.380336000149
                        ],
                        [
                            -84.9748100004027,
                            32.3802570001224
                        ],
                        [
                            -84.9721540001112,
                            32.3769140000242
                        ],
                        [
                            -84.9752260003293,
                            32.3741619998556
                        ],
                        [
                            -84.9820419998869,
                            32.3743379997548
                        ],
                        [
                            -84.9853219996192,
                            32.3725140001025
                        ],
                        [
                            -84.9863300000978,
                            32.3686899996885
                        ],
                        [
                            -84.9832419999862,
                            32.3651220001439
                        ],
                        [
                            -84.9838660003252,
                            32.3623859996501
                        ],
                        [
                            -85.004581999685,
                            32.3451960002947
                        ],
                        [
                            -85.008095999769,
                            32.3366769997105
                        ],
                        [
                            -85.0064019999253,
                            32.3267569999875
                        ],
                        [
                            -85.0003429998779,
                            32.3214179996589
                        ],
                        [
                            -84.9888259996575,
                            32.3191719996817
                        ],
                        [
                            -84.9346480003061,
                            32.2989159997945
                        ],
                        [
                            -84.9228719996196,
                            32.2853329998711
                        ],
                        [
                            -84.9040230003558,
                            32.2737490002086
                        ],
                        [
                            -84.888246000412,
                            32.2596699998166
                        ],
                        [
                            -84.8911910004085,
                            32.2574940000532
                        ],
                        [
                            -84.9011910003372,
                            32.2583739999664
                        ],
                        [
                            -84.9034790003827,
                            32.2556059999926
                        ],
                        [
                            -84.9040869999299,
                            32.2508379997063
                        ],
                        [
                            -84.9072269999201,
                            32.2490490002863
                        ],
                        [
                            -84.9244270004444,
                            32.2501490003079
                        ],
                        [
                            -84.9232270003451,
                            32.2446499997822
                        ],
                        [
                            -84.9203269996561,
                            32.2440499996961
                        ],
                        [
                            -84.9154269997,
                            32.2460489999163
                        ],
                        [
                            -84.9127269999259,
                            32.2433500002845
                        ],
                        [
                            -84.9163270002236,
                            32.2365509996339
                        ],
                        [
                            -84.9233260000793,
                            32.2308509996928
                        ],
                        [
                            -84.9229269998711,
                            32.2247499997026
                        ],
                        [
                            -84.9282269998603,
                            32.2198509996671
                        ],
                        [
                            -84.9393280001541,
                            32.2179510003568
                        ],
                        [
                            -84.9593280000116,
                            32.2178509997332
                        ],
                        [
                            -84.9681279998411,
                            32.2193509998183
                        ],
                        [
                            -84.9733280002712,
                            32.2176500002751
                        ],
                        [
                            -84.9798290001845,
                            32.2110500000586
                        ],
                        [
                            -84.9802290002176,
                            32.20775100035
                        ],
                        [
                            -84.974427999913,
                            32.2035500001811
                        ],
                        [
                            -84.9646280000008,
                            32.2035500001811
                        ],
                        [
                            -84.9617280002101,
                            32.1987510001709
                        ],
                        [
                            -84.9637280003755,
                            32.19585199989
                        ],
                        [
                            -84.9750279999626,
                            32.1910520001257
                        ],
                        [
                            -85.0112669997213,
                            32.1804930001518
                        ],
                        [
                            -85.0170799997215,
                            32.1735160003415
                        ],
                        [
                            -85.0265830004311,
                            32.1661029999775
                        ],
                        [
                            -85.0455930002516,
                            32.1437579999106
                        ],
                        [
                            -85.0616010001742,
                            32.1334119996964
                        ],
                        [
                            -85.0534020002192,
                            32.1048439997163
                        ],
                        [
                            -85.0447610003984,
                            32.0858359997643
                        ],
                        [
                            -85.0532320003399,
                            32.0806039998928
                        ],
                        [
                            -85.0558130002884,
                            32.0744390003141
                        ],
                        [
                            -85.0541789998209,
                            32.0679849996956
                        ],
                        [
                            -85.0588300002549,
                            32.0469560002278
                        ],
                        [
                            -85.0534680003415,
                            32.0378230001305
                        ],
                        [
                            -85.0535319999156,
                            32.0359520002677
                        ],
                        [
                            -85.0580340003867,
                            32.0336059999773
                        ],
                        [
                            -85.0571239998174,
                            32.0299190001836
                        ],
                        [
                            -85.0488250003033,
                            32.0217009997898
                        ],
                        [
                            -85.0536690001829,
                            32.0206619999254
                        ],
                        [
                            -85.055307999775,
                            32.0177529997864
                        ],
                        [
                            -85.0538149997728,
                            32.0135020001602
                        ],
                        [
                            -85.0550750001465,
                            32.0107139998913
                        ],
                        [
                            -85.0634409996077,
                            32.0041399996413
                        ],
                        [
                            -85.0673299996105,
                            31.9955570001378
                        ],
                        [
                            -85.0683299996932,
                            31.9867569999068
                        ],
                        [
                            -85.0709299999082,
                            31.9816580002981
                        ],
                        [
                            -85.0659289996698,
                            31.972458000128
                        ],
                        [
                            -85.067829000276,
                            31.9673580002155
                        ],
                        [
                            -85.0739300001563,
                            31.9641580001746
                        ],
                        [
                            -85.083229999578,
                            31.9624580001846
                        ],
                        [
                            -85.0867300003166,
                            31.9591580002489
                        ],
                        [
                            -85.0824300004101,
                            31.9453580001392
                        ],
                        [
                            -85.0789299996715,
                            31.9401590002007
                        ],
                        [
                            -85.0864299998427,
                            31.9359590002682
                        ],
                        [
                            -85.0918300002893,
                            31.9288590000573
                        ],
                        [
                            -85.0995299995786,
                            31.9252590001079
                        ],
                        [
                            -85.1024300002676,
                            31.9173589996861
                        ],
                        [
                            -85.11313099963,
                            31.9118590000558
                        ],
                        [
                            -85.1080299998325,
                            31.9051600002985
                        ],
                        [
                            -85.1122310000047,
                            31.9027600003515
                        ],
                        [
                            -85.1106300000475,
                            31.8968599999583
                        ],
                        [
                            -85.1120300001633,
                            31.894760000063
                        ],
                        [
                            -85.1150310002363,
                            31.8930600001122
                        ],
                        [
                            -85.1341309995702,
                            31.892160000038
                        ],
                        [
                            -85.1284310004462,
                            31.8775600000847
                        ],
                        [
                            -85.1337310004354,
                            31.870061000258
                        ],
                        [
                            -85.13583100016,
                            31.862460999832
                        ],
                        [
                            -85.140731000116,
                            31.8574609997739
                        ],
                        [
                            -85.1380310003419,
                            31.8512619997493
                        ],
                        [
                            -85.1377309998679,
                            31.845861000189
                        ],
                        [
                            -85.1418309997579,
                            31.839261000306
                        ],
                        [
                            -85.1372310002757,
                            31.8315619998421
                        ],
                        [
                            -85.1321310003031,
                            31.8279619997691
                        ],
                        [
                            -85.1345309996033,
                            31.8249619999472
                        ],
                        [
                            -85.1312309997795,
                            31.8152619998563
                        ],
                        [
                            -85.1329310003693,
                            31.8080619996624
                        ],
                        [
                            -85.1324309998787,
                            31.7934620000871
                        ],
                        [
                            -85.1418309997579,
                            31.7812630002005
                        ],
                        [
                            -85.1255299999323,
                            31.7678630001089
                        ],
                        [
                            -85.1256300003897,
                            31.7644629997609
                        ],
                        [
                            -85.1292309996141,
                            31.7586630000582
                        ],
                        [
                            -85.122030000092,
                            31.7440640000716
                        ],
                        [
                            -85.1189300002848,
                            31.73266400016
                        ],
                        [
                            -85.1222300001086,
                            31.7264640000144
                        ],
                        [
                            -85.1222300001086,
                            31.7227639997315
                        ],
                        [
                            -85.126530000015,
                            31.7167640001389
                        ],
                        [
                            -85.1255299999323,
                            31.6949649997914
                        ],
                        [
                            -85.1223299996677,
                            31.6912649999252
                        ],
                        [
                            -85.1139299998713,
                            31.6868649997549
                        ],
                        [
                            -85.1094299999482,
                            31.6774650001225
                        ],
                        [
                            -85.0924289996156,
                            31.6599660002813
                        ],
                        [
                            -85.0791289998631,
                            31.6547660001227
                        ],
                        [
                            -85.0780290002213,
                            31.6515660000413
                        ],
                        [
                            -85.0826289997035,
                            31.6502659996268
                        ],
                        [
                            -85.0870290000674,
                            31.640965999683
                        ],
                        [
                            -85.0862290000012,
                            31.6385670002775
                        ],
                        [
                            -85.0800290003867,
                            31.6368669997585
                        ],
                        [
                            -85.073828999874,
                            31.6295669999271
                        ],
                        [
                            -85.0581690001059,
                            31.6202269996609
                        ],
                        [
                            -85.0575270002235,
                            31.6168829998728
                        ],
                        [
                            -85.0605519996877,
                            31.608223999853
                        ],
                        [
                            -85.0559759995967,
                            31.6051749998938
                        ],
                        [
                            -85.0584400002676,
                            31.583690000077
                        ],
                        [
                            -85.0552840003839,
                            31.5775960001783
                        ],
                        [
                            -85.0579599998686,
                            31.570839999749
                        ],
                        [
                            -85.052931000041,
                            31.5628900001132
                        ],
                        [
                            -85.0508379999892,
                            31.5555510002876
                        ],
                        [
                            -85.0425469999727,
                            31.5459529997738
                        ],
                        [
                            -85.0413610001172,
                            31.5424950001958
                        ],
                        [
                            -85.0418130000288,
                            31.5377539997072
                        ],
                        [
                            -85.0482629998885,
                            31.5260120000999
                        ],
                        [
                            -85.044556000359,
                            31.5209080001181
                        ],
                        [
                            -85.044985999631,
                            31.5182300000908
                        ],
                        [
                            -85.0569279999988,
                            31.5001710001866
                        ],
                        [
                            -85.0621049999643,
                            31.4880170001204
                        ],
                        [
                            -85.0652590001982,
                            31.4849239999679
                        ],
                        [
                            -85.0716210001944,
                            31.4683839997574
                        ],
                        [
                            -85.065954999609,
                            31.4429790002485
                        ],
                        [
                            -85.0660120004087,
                            31.4305289999427
                        ],
                        [
                            -85.0685459996032,
                            31.4273109998043
                        ],
                        [
                            -85.0737019996524,
                            31.4289590000991
                        ],
                        [
                            -85.0758929996135,
                            31.4249279998914
                        ],
                        [
                            -85.0767460002814,
                            31.4159710001035
                        ],
                        [
                            -85.0799780003331,
                            31.4104719996407
                        ],
                        [
                            -85.0773870003389,
                            31.4028439999369
                        ],
                        [
                            -85.0776259998152,
                            31.3988800001054
                        ],
                        [
                            -85.0804029995822,
                            31.3939319997357
                        ],
                        [
                            -85.0824310002351,
                            31.3845400002512
                        ],
                        [
                            -85.0923270004067,
                            31.3640489999049
                        ],
                        [
                            -85.0917909999312,
                            31.3552069999046
                        ],
                        [
                            -85.0859179996566,
                            31.3531459998319
                        ],
                        [
                            -85.0870890003418,
                            31.3403209999204
                        ],
                        [
                            -85.0894230004181,
                            31.3360280000164
                        ],
                        [
                            -85.0841519998429,
                            31.3283129998341
                        ],
                        [
                            -85.0836969995582,
                            31.3238460000712
                        ],
                        [
                            -85.0837760000993,
                            31.3182100003289
                        ],
                        [
                            -85.0874039999861,
                            31.3112229997843
                        ],
                        [
                            -85.0897740000474,
                            31.2950259998644
                        ],
                        [
                            -85.098712999794,
                            31.2844550001566
                        ],
                        [
                            -85.1103580000609,
                            31.2817470000301
                        ],
                        [
                            -85.114601000066,
                            31.2773330000273
                        ],
                        [
                            -85.1119049995915,
                            31.2724769999475
                        ],
                        [
                            -85.1132610002247,
                            31.2643430001024
                        ],
                        [
                            -85.1123520003786,
                            31.2595800001957
                        ],
                        [
                            -85.0967629998573,
                            31.2256509997755
                        ],
                        [
                            -85.0987069999462,
                            31.2195110002483
                        ],
                        [
                            -85.0987039995732,
                            31.2112859997698
                        ],
                        [
                            -85.1069629998026,
                            31.2026929999745
                        ],
                        [
                            -85.1076759998301,
                            31.1874359997877
                        ],
                        [
                            -85.1070089998334,
                            31.1855110002692
                        ],
                        [
                            -85.1020519999758,
                            31.1847339998954
                        ],
                        [
                            -85.0985069999297,
                            31.1801530001337
                        ],
                        [
                            -85.1002069996211,
                            31.165490000074
                        ],
                        [
                            -85.0921059995755,
                            31.1602930003075
                        ],
                        [
                            -85.0835819999305,
                            31.1596300002253
                        ],
                        [
                            -85.0766280002806,
                            31.1569270001351
                        ],
                        [
                            -85.0693289999509,
                            31.1470080003182
                        ],
                        [
                            -85.0640280001369,
                            31.142495000237
                        ],
                        [
                            -85.060432999862,
                            31.1314990003195
                        ],
                        [
                            -85.0546769997632,
                            31.1208180001866
                        ],
                        [
                            -85.0356150000642,
                            31.1081920002228
                        ],
                        [
                            -85.0354569998804,
                            31.1043439998691
                        ],
                        [
                            -85.0297359999418,
                            31.0961629999622
                        ],
                        [
                            -85.026067999872,
                            31.0841799998792
                        ],
                        [
                            -85.0285729996525,
                            31.0745830003147
                        ],
                        [
                            -85.0181500001244,
                            31.0592540000434
                        ],
                        [
                            -85.0126419997228,
                            31.0554009996957
                        ],
                        [
                            -85.0088199996672,
                            31.0455740003302
                        ],
                        [
                            -85.0094070001963,
                            31.0323770001701
                        ],
                        [
                            -85.0050510002134,
                            31.0247020000381
                        ],
                        [
                            -85.0045470004232,
                            31.0191820002359
                        ],
                        [
                            -84.999428000184,
                            31.0138440003317
                        ],
                        [
                            -85.0024989996789,
                            31.0006850000506
                        ],
                        [
                            -85.2436319999173,
                            31.00088399966
                        ],
                        [
                            -85.5013029998078,
                            30.9968730002466
                        ],
                        [
                            -85.9986109995615,
                            30.9928700002008
                        ],
                        [
                            -86.3654740000026,
                            30.994552999621
                        ],
                        [
                            -86.5201960004112,
                            30.9932580000368
                        ],
                        [
                            -86.5634359996755,
                            30.9952229996992
                        ],
                        [
                            -86.7056109996437,
                            30.9944870001126
                        ],
                        [
                            -86.7235850002948,
                            30.9965689997027
                        ],
                        [
                            -87.0402289999109,
                            30.9995470003774
                        ],
                        [
                            -87.140754999628,
                            30.9995319998086
                        ],
                        [
                            -87.2376850001437,
                            30.9963929997555
                        ],
                        [
                            -87.2524019997305,
                            30.997936000034
                        ],
                        [
                            -87.3015279996881,
                            30.9984379999073
                        ],
                        [
                            -87.598829000296,
                            30.9974550000079
                        ],
                        [
                            -87.5965040004406,
                            30.9872290002278
                        ],
                        [
                            -87.5922370001461,
                            30.9799419996635
                        ],
                        [
                            -87.5935510000482,
                            30.9771030003838
                        ],
                        [
                            -87.5926499996997,
                            30.9726590000691
                        ],
                        [
                            -87.5888619999793,
                            30.9657900002209
                        ],
                        [
                            -87.5909330002898,
                            30.953513000148
                        ],
                        [
                            -87.5941860002579,
                            30.9498710001603
                        ],
                        [
                            -87.5980049999404,
                            30.93839000036
                        ],
                        [
                            -87.601678999858,
                            30.9357489997921
                        ],
                        [
                            -87.6070530003653,
                            30.9251210000671
                        ],
                        [
                            -87.6156859997903,
                            30.9016409997872
                        ],
                        [
                            -87.621806999762,
                            30.8977229999401
                        ],
                        [
                            -87.6202700002772,
                            30.8874040002521
                        ],
                        [
                            -87.6291029997186,
                            30.8804250003469
                        ],
                        [
                            -87.6347440001897,
                            30.8656100000312
                        ],
                        [
                            -87.6260060002845,
                            30.857437999926
                        ],
                        [
                            -87.6250899998673,
                            30.8542339997237
                        ],
                        [
                            -87.6269760002299,
                            30.8478259998107
                        ],
                        [
                            -87.6170719996623,
                            30.8403280003687
                        ],
                        [
                            -87.6152449997493,
                            30.8342099998998
                        ],
                        [
                            -87.6043140002331,
                            30.8314190001325
                        ],
                        [
                            -87.6001020001902,
                            30.8205080000088
                        ],
                        [
                            -87.5795669996821,
                            30.8108919998821
                        ],
                        [
                            -87.5717519998667,
                            30.8001990003437
                        ],
                        [
                            -87.5682520000264,
                            30.8001129999323
                        ],
                        [
                            -87.5590570001867,
                            30.7903750003533
                        ],
                        [
                            -87.5514709998019,
                            30.7859300000035
                        ],
                        [
                            -87.5502849999463,
                            30.781250999926
                        ],
                        [
                            -87.5454319998459,
                            30.7793889999541
                        ],
                        [
                            -87.5460469999641,
                            30.7720559998512
                        ],
                        [
                            -87.5359850001111,
                            30.7611920002068
                        ],
                        [
                            -87.5343020001381,
                            30.7538679997418
                        ],
                        [
                            -87.5353399998556,
                            30.7499990000175
                        ],
                        [
                            -87.5316619997401,
                            30.7460630000607
                        ],
                        [
                            -87.5325570002408,
                            30.7431989998161
                        ],
                        [
                            -87.5295910003279,
                            30.7425810001792
                        ],
                        [
                            -87.5299770001173,
                            30.7409519996411
                        ],
                        [
                            -87.5261459998408,
                            30.7404559997468
                        ],
                        [
                            -87.5149030001551,
                            30.7331520000812
                        ],
                        [
                            -87.5106330003859,
                            30.7329070002746
                        ],
                        [
                            -87.5087920002291,
                            30.7280850000604
                        ],
                        [
                            -87.5051369996798,
                            30.7262880001488
                        ],
                        [
                            -87.5059509999897,
                            30.7250640003343
                        ],
                        [
                            -87.5019439999862,
                            30.7212740001054
                        ],
                        [
                            -87.4975989998739,
                            30.7198550000062
                        ],
                        [
                            -87.4920930000203,
                            30.7203919999382
                        ],
                        [
                            -87.4887749997549,
                            30.7176420002577
                        ],
                        [
                            -87.4846010002451,
                            30.717077999947
                        ],
                        [
                            -87.4836229999037,
                            30.7153100000138
                        ],
                        [
                            -87.4812779999568,
                            30.7170019996748
                        ],
                        [
                            -87.4779920003768,
                            30.7132770000931
                        ],
                        [
                            -87.4802689996533,
                            30.7117869999812
                        ],
                        [
                            -87.4793120001266,
                            30.7095999997888
                        ],
                        [
                            -87.4751710002288,
                            30.7090600002128
                        ],
                        [
                            -87.4744930003614,
                            30.7066539999471
                        ],
                        [
                            -87.4758049997153,
                            30.7052480000894
                        ],
                        [
                            -87.4698059999423,
                            30.7050209999354
                        ],
                        [
                            -87.4680850003344,
                            30.7002080002778
                        ],
                        [
                            -87.4621900003185,
                            30.7001890003681
                        ],
                        [
                            -87.4580130004357,
                            30.6963980001906
                        ],
                        [
                            -87.4513059997597,
                            30.6995910002838
                        ],
                        [
                            -87.4495659998853,
                            30.6989599999728
                        ],
                        [
                            -87.4486610002371,
                            30.696687999633
                        ],
                        [
                            -87.4436759998922,
                            30.6945369999716
                        ],
                        [
                            -87.4403750002435,
                            30.6903619996492
                        ],
                        [
                            -87.436400999852,
                            30.6884199996278
                        ],
                        [
                            -87.4301359999402,
                            30.6882589996796
                        ],
                        [
                            -87.4266119998105,
                            30.6830530002126
                        ],
                        [
                            -87.4202299997228,
                            30.6809320000571
                        ],
                        [
                            -87.4190410003925,
                            30.678528000275
                        ],
                        [
                            -87.4167199998367,
                            30.6792980000584
                        ],
                        [
                            -87.4064739998607,
                            30.6751459998336
                        ],
                        [
                            -87.40738400043,
                            30.6718159996853
                        ],
                        [
                            -87.405878999834,
                            30.6709109999113
                        ],
                        [
                            -87.4057580003584,
                            30.6664389999822
                        ],
                        [
                            -87.402688999615,
                            30.6648469998615
                        ],
                        [
                            -87.4031029998918,
                            30.6574789997595
                        ],
                        [
                            -87.3968179998886,
                            30.6548499998834
                        ],
                        [
                            -87.3959080002175,
                            30.6501590002776
                        ],
                        [
                            -87.3969889995928,
                            30.6482729998024
                        ],
                        [
                            -87.3943359996743,
                            30.6456119999952
                        ],
                        [
                            -87.3957390001631,
                            30.6445620002451
                        ],
                        [
                            -87.3940240004031,
                            30.6418549998768
                        ],
                        [
                            -87.3952119999084,
                            30.6360690003347
                        ],
                        [
                            -87.3940720000837,
                            30.6335020002826
                        ],
                        [
                            -87.3956549995992,
                            30.632984000132
                        ],
                        [
                            -87.3934100001098,
                            30.6315340003486
                        ],
                        [
                            -87.3932939997588,
                            30.627218000145
                        ],
                        [
                            -87.3945750000489,
                            30.6242550001809
                        ],
                        [
                            -87.3960270000432,
                            30.6241349997152
                        ],
                        [
                            -87.3950660003186,
                            30.6218989997689
                        ],
                        [
                            -87.3964749997569,
                            30.6171019998417
                        ],
                        [
                            -87.3949130001577,
                            30.6142659996296
                        ],
                        [
                            -87.3987700003734,
                            30.6053960003829
                        ],
                        [
                            -87.4032860001899,
                            30.6053610003065
                        ],
                        [
                            -87.4035010002751,
                            30.6034170002196
                        ],
                        [
                            -87.4060860004215,
                            30.6032579996594
                        ],
                        [
                            -87.4057109996045,
                            30.6014759998183
                        ],
                        [
                            -87.4075869999213,
                            30.6008649997342
                        ],
                        [
                            -87.4052960004011,
                            30.598692000135
                        ],
                        [
                            -87.407304999889,
                            30.5959839997539
                        ],
                        [
                            -87.4073149999348,
                            30.5878960001189
                        ],
                        [
                            -87.4093550002831,
                            30.5858529997424
                        ],
                        [
                            -87.4080179999165,
                            30.5837010001746
                        ],
                        [
                            -87.4126299999926,
                            30.576704999762
                        ],
                        [
                            -87.4121909996014,
                            30.5737160002459
                        ],
                        [
                            -87.4185209998105,
                            30.5701339999516
                        ],
                        [
                            -87.4161480002745,
                            30.5662930003168
                        ],
                        [
                            -87.4184670002822,
                            30.5648090000041
                        ],
                        [
                            -87.4179169995629,
                            30.5622539997086
                        ],
                        [
                            -87.4219129996958,
                            30.5601270000291
                        ],
                        [
                            -87.4230080002131,
                            30.5614910000819
                        ],
                        [
                            -87.4243410003817,
                            30.5596309998974
                        ],
                        [
                            -87.4253590000078,
                            30.5603110000322
                        ],
                        [
                            -87.4275540001668,
                            30.5536809998802
                        ],
                        [
                            -87.4311820000537,
                            30.5500150002172
                        ],
                        [
                            -87.4348829997354,
                            30.549434000217
                        ],
                        [
                            -87.4367389999608,
                            30.5436889997039
                        ],
                        [
                            -87.4353210003017,
                            30.542867000044
                        ],
                        [
                            -87.4415689995967,
                            30.5374509996477
                        ],
                        [
                            -87.4405769999099,
                            30.5315950003609
                        ],
                        [
                            -87.4389580004093,
                            30.530291000364
                        ],
                        [
                            -87.4417030003893,
                            30.5297469997059
                        ],
                        [
                            -87.4439010000231,
                            30.5314810002274
                        ],
                        [
                            -87.4426189999081,
                            30.5297620001617
                        ],
                        [
                            -87.4436469995799,
                            30.5281379997291
                        ],
                        [
                            -87.4451029997722,
                            30.5289090001196
                        ],
                        [
                            -87.4479799999967,
                            30.5266070003206
                        ],
                        [
                            -87.4479709997758,
                            30.5249449999944
                        ],
                        [
                            -87.4459980002729,
                            30.5263770003099
                        ],
                        [
                            -87.4438059995886,
                            30.5239480001935
                        ],
                        [
                            -87.4472829998627,
                            30.5219730001735
                        ],
                        [
                            -87.4454529995766,
                            30.5176719997012
                        ],
                        [
                            -87.4472679997941,
                            30.5150440003422
                        ],
                        [
                            -87.4449859995963,
                            30.5154910002379
                        ],
                        [
                            -87.444523999639,
                            30.5142479997396
                        ],
                        [
                            -87.4466779997902,
                            30.5136540000872
                        ],
                        [
                            -87.4491220003696,
                            30.515300999666
                        ],
                        [
                            -87.4503709999743,
                            30.5144620002233
                        ],
                        [
                            -87.4483549999154,
                            30.5137400003502
                        ],
                        [
                            -87.4477020001623,
                            30.5104579999195
                        ],
                        [
                            -87.4447139996098,
                            30.5074940002053
                        ],
                        [
                            -87.4396899998051,
                            30.5066490001999
                        ],
                        [
                            -87.4338779996299,
                            30.4994950002975
                        ],
                        [
                            -87.4309779998392,
                            30.4982950000282
                        ],
                        [
                            -87.4316780003462,
                            30.4928960003445
                        ],
                        [
                            -87.4283779996241,
                            30.4884959998398
                        ],
                        [
                            -87.4338779996299,
                            30.4876959996254
                        ],
                        [
                            -87.4328780004455,
                            30.4842960000501
                        ],
                        [
                            -87.4355780002196,
                            30.4804959998189
                        ],
                        [
                            -87.4305779998061,
                            30.4765960002924
                        ],
                        [
                            -87.4295779997234,
                            30.4705959998813
                        ],
                        [
                            -87.4250779998004,
                            30.4655959998741
                        ],
                        [
                            -87.4177769998209,
                            30.4610959996168
                        ],
                        [
                            -87.4146770000136,
                            30.4572959999575
                        ],
                        [
                            -87.4078770003495,
                            30.4563960002568
                        ],
                        [
                            -87.4046770000849,
                            30.4528970001747
                        ],
                        [
                            -87.3998769996879,
                            30.4509969999324
                        ],
                        [
                            -87.3811759996639,
                            30.4500970001353
                        ],
                        [
                            -87.3707680002044,
                            30.4468649999839
                        ],
                        [
                            -87.3669389995778,
                            30.440479999716
                        ],
                        [
                            -87.3665910003216,
                            30.4366480001512
                        ],
                        [
                            -87.3693830001573,
                            30.4319479999154
                        ],
                        [
                            -87.3833759998458,
                            30.422097000025
                        ],
                        [
                            -87.395676000414,
                            30.4175970002639
                        ],
                        [
                            -87.4029770003934,
                            30.4103980001057
                        ],
                        [
                            -87.4088770004323,
                            30.4087979997152
                        ],
                        [
                            -87.422676999777,
                            30.4100979998996
                        ],
                        [
                            -87.4295779997234,
                            30.4064979998909
                        ],
                        [
                            -87.4342779996629,
                            30.3974980002432
                        ],
                        [
                            -87.4406780001923,
                            30.3914979999591
                        ],
                        [
                            -87.4410780002253,
                            30.3876979998951
                        ],
                        [
                            -87.4386780000268,
                            30.3807980001169
                        ],
                        [
                            -87.4513779997297,
                            30.3671989998155
                        ],
                        [
                            -87.4510780001541,
                            30.3455989998811
                        ],
                        [
                            -87.4610780000828,
                            30.3353000000652
                        ],
                        [
                            -87.4718789999026,
                            30.3317999996941
                        ],
                        [
                            -87.4935800001748,
                            30.3305999998106
                        ],
                        [
                            -87.5025719996249,
                            30.3274050000238
                        ],
                        [
                            -87.5059429995938,
                            30.3193960000809
                        ],
                        [
                            -87.5057800002855,
                            30.3124999996952
                        ],
                        [
                            -87.5038799996792,
                            30.3080010000164
                        ],
                        [
                            -87.4999799998058,
                            30.3062009998368
                        ],
                        [
                            -87.4836789999802,
                            30.3048009999401
                        ],
                        [
                            -87.4758790002334,
                            30.3079000000837
                        ],
                        [
                            -87.4629779997908,
                            30.3077999997176
                        ],
                        [
                            -87.4500780000714,
                            30.3110999998076
                        ],
                        [
                            -87.4523779998124,
                            30.3002009996705
                        ],
                        [
                            -87.5183800004292,
                            30.2839010003228
                        ],
                        [
                            -87.518346000094,
                            30.2295059997632
                        ],
                        [
                            -87.6250200004454,
                            30.2059400003245
                        ],
                        [
                            -87.6478239998342,
                            30.1992109999782
                        ],
                        [
                            -87.7180019996106,
                            30.1917600001563
                        ],
                        [
                            -87.8071530002959,
                            30.1773779999336
                        ],
                        [
                            -87.8749910001482,
                            30.1771929999103
                        ],
                        [
                            -87.925755999698,
                            30.1795609999144
                        ],
                        [
                            -87.9810410001606,
                            30.1764849998491
                        ],
                        [
                            -87.9874090000045,
                            30.1778900002694
                        ],
                        [
                            -88.0036179997686,
                            30.1734169998222
                        ],
                        [
                            -88.0097970003649,
                            30.164172000042
                        ],
                        [
                            -88.0207699997139,
                            30.1552179999471
                        ],
                        [
                            -88.036729000131,
                            30.14669299981
                        ],
                        [
                            -88.0461039998957,
                            30.1446889996239
                        ],
                        [
                            -88.0606890001363,
                            30.1444250000119
                        ],
                        [
                            -88.0748690003209,
                            30.1471490002189
                        ],
                        [
                            -88.0902200002925,
                            30.1545160001143
                        ],
                        [
                            -88.1034170001128,
                            30.1663770000981
                        ],
                        [
                            -88.115597000132,
                            30.1677430001449
                        ],
                        [
                            -88.1284979996764,
                            30.1720779996477
                        ],
                        [
                            -88.1383739997565,
                            30.1779730003402
                        ],
                        [
                            -88.153480000404,
                            30.1903710002125
                        ],
                        [
                            -88.1620219995923,
                            30.2000929998765
                        ],
                        [
                            -88.2100029997024,
                            30.1936120001457
                        ],
                        [
                            -88.2665929998462,
                            30.1817040003366
                        ],
                        [
                            -88.3030230002989,
                            30.1779620002974
                        ],
                        [
                            -88.324933999735,
                            30.1802419998558
                        ],
                        [
                            -88.3477189997555,
                            30.1900880000314
                        ],
                        [
                            -88.3597559996596,
                            30.1745359999362
                        ],
                        [
                            -88.3711630002751,
                            30.1656080001804
                        ],
                        [
                            -88.3844310002405,
                            30.1585430001974
                        ],
                        [
                            -88.4034290003655,
                            30.5305130003691
                        ],
                        [
                            -88.4026119996826,
                            30.5397069998949
                        ],
                        [
                            -88.4053659998834,
                            30.5539409998488
                        ],
                        [
                            -88.4041769996548,
                            30.559512000022
                        ],
                        [
                            -88.4059860000245,
                            30.5649500001575
                        ],
                        [
                            -88.4049340000633,
                            30.5666329996794
                        ],
                        [
                            -88.406919999985,
                            30.6302049999842
                        ],
                        [
                            -88.418632000199,
                            30.8665700002027
                        ],
                        [
                            -88.4387810000194,
                            31.2526510003041
                        ],
                        [
                            -88.4649079996964,
                            31.7054760000187
                        ],
                        [
                            -88.4732270002003,
                            31.8938560002534
                        ],
                        [
                            -88.4038419999191,
                            32.4487730001084
                        ],
                        [
                            -88.3245689998621,
                            33.1332459999074
                        ],
                        [
                            -88.312535000331,
                            33.2209230002765
                        ],
                        [
                            -88.2430250003763,
                            33.7956790000812
                        ],
                        [
                            -88.2242809997962,
                            33.9270420003302
                        ],
                        [
                            -88.1906999999789,
                            34.1900189996828
                        ],
                        [
                            -88.187621000088,
                            34.2047659997653
                        ],
                        [
                            -88.1866670000361,
                            34.2209519998043
                        ],
                        [
                            -88.1562920003311,
                            34.4632140000614
                        ],
                        [
                            -88.1399880001326,
                            34.5817030002605
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 32.7396323,
            "name": "Alabama",
            "intptlon": -86.8434593,
            "geo_point_2d": [
                32.7570463396,
                -86.844525962
            ],
            "geoid": "01",
            "mtfcc": "G4000",
            "region": 3
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -86.844525962,
                32.7570463396
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "c74a1c8e4de4f2d655ab5cefaf158180e55d3849",
        "fields": {
            "arealand": "-1540815024",
            "objectid": 25,
            "basename": "Ohio",
            "stusab": "OH",
            "statens": "01085497",
            "centlon": -82.7168502,
            "state": "39",
            "gid": 3,
            "centlat": 40.4097376,
            "division": 3,
            "areawater": "1674416037",
            "oid": "267564895",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.8032509996931,
                            40.9893939999234
                        ],
                        [
                            -84.8059720002819,
                            41.6961180000935
                        ],
                        [
                            -84.1923169995879,
                            41.7110100003018
                        ],
                        [
                            -83.4158509999319,
                            41.733794000114
                        ],
                        [
                            -83.1124600002127,
                            41.959410000268
                        ],
                        [
                            -83.0693120001117,
                            41.8642169997689
                        ],
                        [
                            -82.6811190000175,
                            41.676327999863
                        ],
                        [
                            -82.3983480002969,
                            41.6765240000303
                        ],
                        [
                            -81.7092020003168,
                            42.0000370001873
                        ],
                        [
                            -81.6864470004336,
                            42.0000370001873
                        ],
                        [
                            -81.6248469998306,
                            42.0307319998236
                        ],
                        [
                            -81.6248469998306,
                            42.0296189999829
                        ],
                        [
                            -81.4951809997695,
                            42.0964019997844
                        ],
                        [
                            -81.2498330000133,
                            42.2164149999052
                        ],
                        [
                            -81.0261410003235,
                            42.2500459997981
                        ],
                        [
                            -80.9998249996023,
                            42.2524039997194
                        ],
                        [
                            -80.5198510002672,
                            42.3271320000067
                        ],
                        [
                            -80.5189909999265,
                            40.638801000148
                        ],
                        [
                            -80.5256600000694,
                            40.6360679997011
                        ],
                        [
                            -80.5327369997429,
                            40.6355900003209
                        ],
                        [
                            -80.5511259995974,
                            40.6288470002631
                        ],
                        [
                            -80.5606900004063,
                            40.6237080002676
                        ],
                        [
                            -80.567613999919,
                            40.6176779999226
                        ],
                        [
                            -80.5767040001767,
                            40.614222999669
                        ],
                        [
                            -80.5835919997043,
                            40.6155019997618
                        ],
                        [
                            -80.5920490003005,
                            40.6224959996834
                        ],
                        [
                            -80.5987639995757,
                            40.6252629996649
                        ],
                        [
                            -80.6271709998006,
                            40.619936000164
                        ],
                        [
                            -80.6343549996043,
                            40.6160949996711
                        ],
                        [
                            -80.651715999787,
                            40.5977439998782
                        ],
                        [
                            -80.663540000154,
                            40.590768000031
                        ],
                        [
                            -80.6678130002963,
                            40.5835359996855
                        ],
                        [
                            -80.6669169999707,
                            40.5736640000407
                        ],
                        [
                            -80.6626599997219,
                            40.569951999926
                        ],
                        [
                            -80.6643720000072,
                            40.5700160002018
                        ],
                        [
                            -80.6524360003855,
                            40.5625440002231
                        ],
                        [
                            -80.6331069998244,
                            40.5387049996743
                        ],
                        [
                            -80.6275070002596,
                            40.5357929998714
                        ],
                        [
                            -80.6180029997251,
                            40.5020489997014
                        ],
                        [
                            -80.615442999693,
                            40.5018870000828
                        ],
                        [
                            -80.6136030002595,
                            40.4985939997741
                        ],
                        [
                            -80.6157309995731,
                            40.4990100000775
                        ],
                        [
                            -80.6136500001152,
                            40.4957480002893
                        ],
                        [
                            -80.6087379995652,
                            40.4896110000218
                        ],
                        [
                            -80.599193999746,
                            40.4825659998563
                        ],
                        [
                            -80.5954050002007,
                            40.4753159999171
                        ],
                        [
                            -80.5959530003718,
                            40.4633540000985
                        ],
                        [
                            -80.6121949997478,
                            40.4348380001938
                        ],
                        [
                            -80.6125470001003,
                            40.411672999975
                        ],
                        [
                            -80.6142509999897,
                            40.4087100002934
                        ],
                        [
                            -80.6123250003425,
                            40.4027619996944
                        ],
                        [
                            -80.6151949999959,
                            40.3998670002397
                        ],
                        [
                            -80.6161010003673,
                            40.4009959999157
                        ],
                        [
                            -80.6321960003285,
                            40.3936669998399
                        ],
                        [
                            -80.6327319999057,
                            40.390504999762
                        ],
                        [
                            -80.6313920000644,
                            40.3856960000726
                        ],
                        [
                            -80.6191020004403,
                            40.3818619998589
                        ],
                        [
                            -80.6096859997694,
                            40.3749499996944
                        ],
                        [
                            -80.6075869998698,
                            40.3695599996642
                        ],
                        [
                            -80.6127949997974,
                            40.3476690003322
                        ],
                        [
                            -80.6107960003553,
                            40.3408680002171
                        ],
                        [
                            -80.6028950003261,
                            40.3278680000215
                        ],
                        [
                            -80.599895000078,
                            40.3176659996742
                        ],
                        [
                            -80.6028950003261,
                            40.3070689997488
                        ],
                        [
                            -80.6148860001994,
                            40.2919710003037
                        ],
                        [
                            -80.6167929995801,
                            40.2852779998878
                        ],
                        [
                            -80.6162090003223,
                            40.2722709997664
                        ],
                        [
                            -80.619297000434,
                            40.265169999738
                        ],
                        [
                            -80.622507999671,
                            40.2617650003201
                        ],
                        [
                            -80.6446030001284,
                            40.2512759998834
                        ],
                        [
                            -80.6521009997514,
                            40.2449749998824
                        ],
                        [
                            -80.6615399999886,
                            40.2298119998121
                        ],
                        [
                            -80.6680829997348,
                            40.1996890003093
                        ],
                        [
                            -80.6725819998328,
                            40.1924010001411
                        ],
                        [
                            -80.686080999777,
                            40.1816369999107
                        ],
                        [
                            -80.7056110001795,
                            40.1534759997999
                        ],
                        [
                            -80.7101660003541,
                            40.1383150000091
                        ],
                        [
                            -80.7106700001443,
                            40.125549999999
                        ],
                        [
                            -80.7070949999609,
                            40.1111030002034
                        ],
                        [
                            -80.7095450003881,
                            40.1003019999377
                        ],
                        [
                            -80.7313000001886,
                            40.0864299996888
                        ],
                        [
                            -80.7381810000435,
                            40.0786069997943
                        ],
                        [
                            -80.7392360003778,
                            40.0746230002338
                        ],
                        [
                            -80.73816500015,
                            40.0665340001355
                        ],
                        [
                            -80.7333270001182,
                            40.0586350000123
                        ],
                        [
                            -80.7310240000041,
                            40.0442910001428
                        ],
                        [
                            -80.7319339996751,
                            40.0370009999791
                        ],
                        [
                            -80.7371509998236,
                            40.0285379998938
                        ],
                        [
                            -80.7377319996066,
                            40.0220569999035
                        ],
                        [
                            -80.7421699996054,
                            40.0085750000804
                        ],
                        [
                            -80.7388830002004,
                            39.9855830001989
                        ],
                        [
                            -80.7410339999785,
                            39.9731520001419
                        ],
                        [
                            -80.7401390003761,
                            39.9707949996668
                        ],
                        [
                            -80.7579220003332,
                            39.9597070002217
                        ],
                        [
                            -80.7629870001458,
                            39.9537849999358
                        ],
                        [
                            -80.7642750001086,
                            39.9499630002359
                        ],
                        [
                            -80.7613140002185,
                            39.9301439998775
                        ],
                        [
                            -80.756357000361,
                            39.9201729997488
                        ],
                        [
                            -80.7561129999635,
                            39.9134020002989
                        ],
                        [
                            -80.7585979996525,
                            39.909478999862
                        ],
                        [
                            -80.7633989998744,
                            39.9086189996773
                        ],
                        [
                            -80.7708010001363,
                            39.9103500003031
                        ],
                        [
                            -80.7870380003877,
                            39.9183550002048
                        ],
                        [
                            -80.7996850003871,
                            39.9195799999544
                        ],
                        [
                            -80.8067760003044,
                            39.9163080000002
                        ],
                        [
                            -80.8090450000833,
                            39.9112520000568
                        ],
                        [
                            -80.8091739999547,
                            39.9039359998405
                        ],
                        [
                            -80.8029209997385,
                            39.8925240003108
                        ],
                        [
                            -80.7939900003877,
                            39.8827829996718
                        ],
                        [
                            -80.7901559997383,
                            39.8722520001339
                        ],
                        [
                            -80.7907609998108,
                            39.867280000331
                        ],
                        [
                            -80.793130999872,
                            39.8637509998574
                        ],
                        [
                            -80.7998979999241,
                            39.8589119998274
                        ],
                        [
                            -80.8164299997284,
                            39.85303200009
                        ],
                        [
                            -80.8242760004042,
                            39.8471589997064
                        ],
                        [
                            -80.8269639995845,
                            39.8416560000003
                        ],
                        [
                            -80.822479999555,
                            39.8249709997686
                        ],
                        [
                            -80.8221809998043,
                            39.8117079998981
                        ],
                        [
                            -80.8260790000278,
                            39.7985839996985
                        ],
                        [
                            -80.8353109996775,
                            39.7906900002461
                        ],
                        [
                            -80.8630480004309,
                            39.775196999845
                        ],
                        [
                            -80.866328999988,
                            39.7717379997408
                        ],
                        [
                            -80.8703219997479,
                            39.7631840002612
                        ],
                        [
                            -80.8685700001779,
                            39.7623989997031
                        ],
                        [
                            -80.8653389999511,
                            39.7532509996806
                        ],
                        [
                            -80.8527379999824,
                            39.7410400002857
                        ],
                        [
                            -80.8459600000596,
                            39.7386669997862
                        ],
                        [
                            -80.847448000039,
                            39.7380910000502
                        ],
                        [
                            -80.8315510004445,
                            39.719475000123
                        ],
                        [
                            -80.8297229998082,
                            39.7140410003054
                        ],
                        [
                            -80.8308290001961,
                            39.7078269997424
                        ],
                        [
                            -80.8338820001477,
                            39.7034970001295
                        ],
                        [
                            -80.8415470001752,
                            39.7002289997224
                        ],
                        [
                            -80.8519999998405,
                            39.6985599996902
                        ],
                        [
                            -80.8636979998109,
                            39.6917239998151
                        ],
                        [
                            -80.8663299998129,
                            39.683167000116
                        ],
                        [
                            -80.8652240003233,
                            39.6655090000978
                        ],
                        [
                            -80.8666460001805,
                            39.6526159997826
                        ],
                        [
                            -80.8698010002393,
                            39.6468950001743
                        ],
                        [
                            -80.8710199997068,
                            39.6389629998854
                        ],
                        [
                            -80.8754309999413,
                            39.6283609999286
                        ],
                        [
                            -80.8803600002097,
                            39.6207060003132
                        ],
                        [
                            -80.8922079999679,
                            39.6167560000336
                        ],
                        [
                            -80.9176199999676,
                            39.6187029998345
                        ],
                        [
                            -80.9283460003426,
                            39.6165539997806
                        ],
                        [
                            -80.9359350002022,
                            39.6132270000293
                        ],
                        [
                            -80.9734829998402,
                            39.5884460000202
                        ],
                        [
                            -80.9936950003081,
                            39.571253000155
                        ],
                        [
                            -81.0200549996136,
                            39.5554099999939
                        ],
                        [
                            -81.0268560000009,
                            39.5474969999935
                        ],
                        [
                            -81.0449019997237,
                            39.5363000000675
                        ],
                        [
                            -81.055986000299,
                            39.5258959998573
                        ],
                        [
                            -81.0710250001011,
                            39.5156080002812
                        ],
                        [
                            -81.0816209998815,
                            39.5040919997277
                        ],
                        [
                            -81.0919360003528,
                            39.4965730001102
                        ],
                        [
                            -81.1008340000916,
                            39.4871760000436
                        ],
                        [
                            -81.1147330000688,
                            39.4655749999123
                        ],
                        [
                            -81.115232999661,
                            39.4664749996695
                        ],
                        [
                            -81.1247329999975,
                            39.453374999722
                        ],
                        [
                            -81.1325339995692,
                            39.4462750000866
                        ],
                        [
                            -81.1381340000324,
                            39.4437750000273
                        ],
                        [
                            -81.152534000325,
                            39.4431750002838
                        ],
                        [
                            -81.1675930002185,
                            39.4400640002995
                        ],
                        [
                            -81.1851450001969,
                            39.4314790000981
                        ],
                        [
                            -81.1907139997996,
                            39.423562000174
                        ],
                        [
                            -81.2082309996179,
                            39.4071469999825
                        ],
                        [
                            -81.2114330004307,
                            39.4020309999223
                        ],
                        [
                            -81.2116540003636,
                            39.3929770000851
                        ],
                        [
                            -81.2173150000279,
                            39.3875900001159
                        ],
                        [
                            -81.2235809997646,
                            39.3860620002255
                        ],
                        [
                            -81.2443589999469,
                            39.3904100001187
                        ],
                        [
                            -81.2503800003595,
                            39.390106000294
                        ],
                        [
                            -81.2597860000864,
                            39.3866990002955
                        ],
                        [
                            -81.2702920003534,
                            39.3860469999358
                        ],
                        [
                            -81.2814039996196,
                            39.379259000189
                        ],
                        [
                            -81.3017700000733,
                            39.3722279996779
                        ],
                        [
                            -81.3449270003952,
                            39.3467939998677
                        ],
                        [
                            -81.3569109996975,
                            39.3431779998719
                        ],
                        [
                            -81.375960999701,
                            39.3416969996944
                        ],
                        [
                            -81.3845560003894,
                            39.3434489998727
                        ],
                        [
                            -81.3937939998868,
                            39.3517059998433
                        ],
                        [
                            -81.4066889995833,
                            39.3880899999885
                        ],
                        [
                            -81.412705999798,
                            39.394618000336
                        ],
                        [
                            -81.4356420003295,
                            39.4084740002756
                        ],
                        [
                            -81.4465429997084,
                            39.4103739999968
                        ],
                        [
                            -81.4561429996041,
                            39.4092740000025
                        ],
                        [
                            -81.4731869995943,
                            39.4001690001071
                        ],
                        [
                            -81.4890439999042,
                            39.3840740001496
                        ],
                        [
                            -81.5031889999287,
                            39.3732420002726
                        ],
                        [
                            -81.5243090004178,
                            39.3616100001574
                        ],
                        [
                            -81.534470000005,
                            39.3582340000327
                        ],
                        [
                            -81.5368459999141,
                            39.3549740000138
                        ],
                        [
                            -81.5423459999198,
                            39.3528740001127
                        ],
                        [
                            -81.5575470001035,
                            39.338773999765
                        ],
                        [
                            -81.5650470002747,
                            39.2938739998105
                        ],
                        [
                            -81.5652470002912,
                            39.276175000014
                        ],
                        [
                            -81.5702469998064,
                            39.267674999659
                        ],
                        [
                            -81.5855590003182,
                            39.2687469996928
                        ],
                        [
                            -81.5951600000388,
                            39.2733869999175
                        ],
                        [
                            -81.6058160000936,
                            39.2758510001348
                        ],
                        [
                            -81.6221849996912,
                            39.2736749998904
                        ],
                        [
                            -81.6453379999577,
                            39.2773389999421
                        ],
                        [
                            -81.6701869997176,
                            39.2759629999191
                        ],
                        [
                            -81.6775949998273,
                            39.2741709999663
                        ],
                        [
                            -81.6847629997374,
                            39.2700269996986
                        ],
                        [
                            -81.6904910002471,
                            39.265083000332
                        ],
                        [
                            -81.692908000164,
                            39.2607149998141
                        ],
                        [
                            -81.6934839999242,
                            39.2616749997015
                        ],
                        [
                            -81.6966199997164,
                            39.2561389998397
                        ],
                        [
                            -81.6967639996564,
                            39.2468109999217
                        ],
                        [
                            -81.6919790002264,
                            39.2353549996542
                        ],
                        [
                            -81.691914999754,
                            39.2270349998211
                        ],
                        [
                            -81.7009079999273,
                            39.2208439999632
                        ],
                        [
                            -81.7256219999681,
                            39.2159050000472
                        ],
                        [
                            -81.7326769999003,
                            39.205481000037
                        ],
                        [
                            -81.7345099996611,
                            39.1961450003365
                        ],
                        [
                            -81.7368739998746,
                            39.1926280000212
                        ],
                        [
                            -81.7414850001257,
                            39.1892000003152
                        ],
                        [
                            -81.7512060003952,
                            39.1860429998448
                        ],
                        [
                            -81.7561799999711,
                            39.1804599996549
                        ],
                        [
                            -81.7542409999051,
                            39.1710790002773
                        ],
                        [
                            -81.7427649996926,
                            39.142669000326
                        ],
                        [
                            -81.743117000045,
                            39.1413250003224
                        ],
                        [
                            -81.7443949999621,
                            39.1437399998329
                        ],
                        [
                            -81.7451940002034,
                            39.138239000096
                        ],
                        [
                            -81.7423529999639,
                            39.1112769998991
                        ],
                        [
                            -81.7460539996457,
                            39.0969779999275
                        ],
                        [
                            -81.7519539996845,
                            39.0901770001408
                        ],
                        [
                            -81.7607540004123,
                            39.0840769998886
                        ],
                        [
                            -81.7755549996646,
                            39.0783770000121
                        ],
                        [
                            -81.7857549996099,
                            39.078577999858
                        ],
                        [
                            -81.7981539999122,
                            39.0828779999876
                        ],
                        [
                            -81.806654000166,
                            39.0840779998051
                        ],
                        [
                            -81.8106539995985,
                            39.083278000103
                        ],
                        [
                            -81.8138539998632,
                            39.0796779997011
                        ],
                        [
                            -81.8123540001883,
                            39.0612790000035
                        ],
                        [
                            -81.8080550001067,
                            39.0537770000304
                        ],
                        [
                            -81.8003549999191,
                            39.0449780001825
                        ],
                        [
                            -81.7850540001762,
                            39.0358789998828
                        ],
                        [
                            -81.7728540000655,
                            39.026178999734
                        ],
                        [
                            -81.7642530004277,
                            39.0152790000205
                        ],
                        [
                            -81.7651530000529,
                            39.0025789997602
                        ],
                        [
                            -81.7740619996624,
                            38.9936820000531
                        ],
                        [
                            -81.7767229999768,
                            38.9851420001746
                        ],
                        [
                            -81.7757339997647,
                            38.9807370000018
                        ],
                        [
                            -81.7798830000585,
                            38.97277300004
                        ],
                        [
                            -81.7818199995764,
                            38.9649350001077
                        ],
                        [
                            -81.7788450003409,
                            38.9558919997408
                        ],
                        [
                            -81.7676670000543,
                            38.9468649998317
                        ],
                        [
                            -81.7610160003531,
                            38.9380030001878
                        ],
                        [
                            -81.7569750000144,
                            38.9371539997858
                        ],
                        [
                            -81.7570579998551,
                            38.9312279999831
                        ],
                        [
                            -81.759995000354,
                            38.9258290000935
                        ],
                        [
                            -81.7626580003182,
                            38.9241230002991
                        ],
                        [
                            -81.7741050002184,
                            38.9229639999527
                        ],
                        [
                            -81.7856469996548,
                            38.9261919996649
                        ],
                        [
                            -81.7933719999569,
                            38.9302030001975
                        ],
                        [
                            -81.8061369999571,
                            38.9421120001988
                        ],
                        [
                            -81.8111569995638,
                            38.9447210000801
                        ],
                        [
                            -81.8196919999777,
                            38.9470149996827
                        ],
                        [
                            -81.8273539996323,
                            38.9458980000064
                        ],
                        [
                            -81.8331399998683,
                            38.941050999827
                        ],
                        [
                            -81.8344389997017,
                            38.941426999758
                        ],
                        [
                            -81.8444860003844,
                            38.9287460000986
                        ],
                        [
                            -81.8453120003899,
                            38.9100879999779
                        ],
                        [
                            -81.8486530002215,
                            38.9014069999849
                        ],
                        [
                            -81.8559709999194,
                            38.8927339998786
                        ],
                        [
                            -81.8892329998946,
                            38.8742789999329
                        ],
                        [
                            -81.895112000017,
                            38.8739679997625
                        ],
                        [
                            -81.9029700003884,
                            38.8760899998381
                        ],
                        [
                            -81.9103120003758,
                            38.8792940000162
                        ],
                        [
                            -81.9279990000734,
                            38.8934919998279
                        ],
                        [
                            -81.9266700001027,
                            38.9013109996518
                        ],
                        [
                            -81.9009099999485,
                            38.924337999687
                        ],
                        [
                            -81.8984699995671,
                            38.9296029998758
                        ],
                        [
                            -81.9005950003043,
                            38.937670999729
                        ],
                        [
                            -81.9124430000625,
                            38.9543430002145
                        ],
                        [
                            -81.9182150000548,
                            38.9665950000879
                        ],
                        [
                            -81.9331860000847,
                            38.9876600002086
                        ],
                        [
                            -81.9417170003007,
                            38.9932340002176
                        ],
                        [
                            -81.9514469998927,
                            38.9960320002535
                        ],
                        [
                            -81.9677689996346,
                            38.9929549999126
                        ],
                        [
                            -81.9793710002438,
                            38.9931929997637
                        ],
                        [
                            -81.9820319996599,
                            38.9956979998237
                        ],
                        [
                            -81.9842610001542,
                            39.0066779998098
                        ],
                        [
                            -81.9913610002922,
                            39.0183780001321
                        ],
                        [
                            -81.9958610002152,
                            39.0232780002885
                        ],
                        [
                            -82.004498999663,
                            39.0288920003457
                        ],
                        [
                            -82.0218840001352,
                            39.0294910000964
                        ],
                        [
                            -82.0377489999426,
                            39.0238649999985
                        ],
                        [
                            -82.0420620002679,
                            39.0167779996764
                        ],
                        [
                            -82.0456630003905,
                            39.0037779999128
                        ],
                        [
                            -82.0515630004293,
                            38.994377999932
                        ],
                        [
                            -82.0915649997941,
                            38.9737780001169
                        ],
                        [
                            -82.0948649996179,
                            38.9645779996691
                        ],
                        [
                            -82.109064999894,
                            38.9455790001285
                        ],
                        [
                            -82.1108659998678,
                            38.9403789998156
                        ],
                        [
                            -82.1105649995689,
                            38.935278999991
                        ],
                        [
                            -82.1116669997588,
                            38.932578999816
                        ],
                        [
                            -82.112366000441,
                            38.931778999668
                        ],
                        [
                            -82.1111660003417,
                            38.9365790000601
                        ],
                        [
                            -82.1132660000663,
                            38.9305790003466
                        ],
                        [
                            -82.1286660004416,
                            38.9106790000176
                        ],
                        [
                            -82.1293660000503,
                            38.9078789998593
                        ],
                        [
                            -82.1359659996979,
                            38.904878999713
                        ],
                        [
                            -82.1375659998302,
                            38.9018790000263
                        ],
                        [
                            -82.1361659997144,
                            38.9016790001075
                        ],
                        [
                            -82.140067000311,
                            38.901178999943
                        ],
                        [
                            -82.1431670001183,
                            38.898079000317
                        ],
                        [
                            -82.1453670003002,
                            38.8841790000716
                        ],
                        [
                            -82.1413669999694,
                            38.8752800000035
                        ],
                        [
                            -82.1392239996889,
                            38.8650200001942
                        ],
                        [
                            -82.1454669998593,
                            38.8396800002175
                        ],
                        [
                            -82.163597999971,
                            38.8234240001431
                        ],
                        [
                            -82.1807750000307,
                            38.8169619999877
                        ],
                        [
                            -82.1911719996194,
                            38.8151369996697
                        ],
                        [
                            -82.1975400003617,
                            38.8095390002783
                        ],
                        [
                            -82.2114379996157,
                            38.8012200000246
                        ],
                        [
                            -82.2172690000575,
                            38.7956799997187
                        ],
                        [
                            -82.221565999591,
                            38.7871870000884
                        ],
                        [
                            -82.2196440001417,
                            38.7722830001105
                        ],
                        [
                            -82.2166139997563,
                            38.7683500001949
                        ],
                        [
                            -82.2071410000823,
                            38.7639430003166
                        ],
                        [
                            -82.1988819998528,
                            38.7577249997694
                        ],
                        [
                            -82.1956060003185,
                            38.7524409997798
                        ],
                        [
                            -82.1932680000443,
                            38.7411820003081
                        ],
                        [
                            -82.1879680000551,
                            38.7330820002436
                        ],
                        [
                            -82.1868680004133,
                            38.7222819998136
                        ],
                        [
                            -82.1913680003363,
                            38.7297820000918
                        ],
                        [
                            -82.191667999912,
                            38.7373819998999
                        ],
                        [
                            -82.193098000165,
                            38.7377180002309
                        ],
                        [
                            -82.1938329999338,
                            38.7356530003251
                        ],
                        [
                            -82.1912849995973,
                            38.7272039999949
                        ],
                        [
                            -82.184566999949,
                            38.7158820001674
                        ],
                        [
                            -82.1824670002245,
                            38.7087819998567
                        ],
                        [
                            -82.1901670004122,
                            38.6873820002009
                        ],
                        [
                            -82.1908670000209,
                            38.6803830000223
                        ],
                        [
                            -82.1860669996239,
                            38.6667830000967
                        ],
                        [
                            -82.1855670000317,
                            38.6595830001917
                        ],
                        [
                            -82.1790669999433,
                            38.6488830001328
                        ],
                        [
                            -82.1724670002958,
                            38.6287839997578
                        ],
                        [
                            -82.1720660004378,
                            38.6185839997
                        ],
                        [
                            -82.175266999629,
                            38.6070839997546
                        ],
                        [
                            -82.1811669996678,
                            38.59978399969
                        ],
                        [
                            -82.1887670002964,
                            38.5949839998994
                        ],
                        [
                            -82.201004000217,
                            38.5920519998885
                        ],
                        [
                            -82.2214679996816,
                            38.5912840001904
                        ],
                        [
                            -82.2271679997039,
                            38.5937830000162
                        ],
                        [
                            -82.2479690003508,
                            38.5972830000301
                        ],
                        [
                            -82.2456689997114,
                            38.5981829998719
                        ],
                        [
                            -82.2466589997484,
                            38.5985690001872
                        ],
                        [
                            -82.2620699999944,
                            38.5981829998719
                        ],
                        [
                            -82.2742700001051,
                            38.5936830001562
                        ],
                        [
                            -82.2912710004377,
                            38.5789829999478
                        ],
                        [
                            -82.2934709997213,
                            38.5742830003337
                        ],
                        [
                            -82.291870999589,
                            38.575482999749
                        ],
                        [
                            -82.2917710000298,
                            38.5747829998925
                        ],
                        [
                            -82.2938709997544,
                            38.5726830001317
                        ],
                        [
                            -82.2933710001622,
                            38.5569830002151
                        ],
                        [
                            -82.2956709999032,
                            38.5384830001802
                        ],
                        [
                            -82.3028709996004,
                            38.5231829997915
                        ],
                        [
                            -82.3039710001405,
                            38.5176829999295
                        ],
                        [
                            -82.3030709996169,
                            38.5043840000274
                        ],
                        [
                            -82.3049269998423,
                            38.4939400001554
                        ],
                        [
                            -82.3106389995601,
                            38.4831720003419
                        ],
                        [
                            -82.314782999831,
                            38.4652359998313
                        ],
                        [
                            -82.3192950003479,
                            38.455924000215
                        ],
                        [
                            -82.3273910003707,
                            38.4464839997502
                        ],
                        [
                            -82.3369920000913,
                            38.4420200001853
                        ],
                        [
                            -82.3897459999358,
                            38.4343550003106
                        ],
                        [
                            -82.4048819998222,
                            38.4393469997853
                        ],
                        [
                            -82.4312749996379,
                            38.4308830000699
                        ],
                        [
                            -82.4861969998774,
                            38.4181780001308
                        ],
                        [
                            -82.5185099998235,
                            38.4080779997946
                        ],
                        [
                            -82.5382340003947,
                            38.4040810002436
                        ],
                        [
                            -82.5705709997319,
                            38.4049600003329
                        ],
                        [
                            -82.5833779995649,
                            38.4099699997864
                        ],
                        [
                            -82.5934820001491,
                            38.418251999733
                        ],
                        [
                            -82.5934799996009,
                            38.4218209999638
                        ],
                        [
                            -82.6003799997224,
                            38.4368729997243
                        ],
                        [
                            -82.6036090002994,
                            38.4595329999339
                        ],
                        [
                            -82.6108080001717,
                            38.471953000198
                        ],
                        [
                            -82.6186690000178,
                            38.4772240001755
                        ],
                        [
                            -82.6373109995923,
                            38.4843459997583
                        ],
                        [
                            -82.6534569996071,
                            38.493702000252
                        ],
                        [
                            -82.6756359997302,
                            38.5155810003203
                        ],
                        [
                            -82.6892390003297,
                            38.5359250001523
                        ],
                        [
                            -82.6982850002065,
                            38.5437700000037
                        ],
                        [
                            -82.7287279996835,
                            38.5589970002962
                        ],
                        [
                            -82.7662639996258,
                            38.5605589999474
                        ],
                        [
                            -82.781048999883,
                            38.5591619999188
                        ],
                        [
                            -82.7964380003877,
                            38.5619360001037
                        ],
                        [
                            -82.8212050001318,
                            38.5733439999817
                        ],
                        [
                            -82.8422129995696,
                            38.5885770002568
                        ],
                        [
                            -82.8474609996803,
                            38.5953820001073
                        ],
                        [
                            -82.8543690001977,
                            38.6132910001797
                        ],
                        [
                            -82.8573469998062,
                            38.6315639998688
                        ],
                        [
                            -82.8568949998946,
                            38.6474049998949
                        ],
                        [
                            -82.8602809999321,
                            38.6626530000713
                        ],
                        [
                            -82.8658389996641,
                            38.6731160001691
                        ],
                        [
                            -82.8757010003989,
                            38.6839610000421
                        ],
                        [
                            -82.8774250003798,
                            38.6894080002159
                        ],
                        [
                            -82.8698840002008,
                            38.7272719998507
                        ],
                        [
                            -82.8709589997282,
                            38.7365419998646
                        ],
                        [
                            -82.8730259998408,
                            38.7431319997789
                        ],
                        [
                            -82.8769180002166,
                            38.7487129996943
                        ],
                        [
                            -82.8798570003653,
                            38.7516180002842
                        ],
                        [
                            -82.8854040002267,
                            38.7527589999503
                        ],
                        [
                            -82.8858040002598,
                            38.7550790002955
                        ],
                        [
                            -82.8904320002294,
                            38.7562599997242
                        ],
                        [
                            -82.8962929999101,
                            38.7563760002559
                        ],
                        [
                            -82.919831999966,
                            38.7511779998741
                        ],
                        [
                            -82.9372340001565,
                            38.7458340001247
                        ],
                        [
                            -82.9716180004131,
                            38.7271650001411
                        ],
                        [
                            -82.9708119996009,
                            38.7285919996882
                        ],
                        [
                            -82.9814910001203,
                            38.7259960003032
                        ],
                        [
                            -83.0035630001132,
                            38.7298709998629
                        ],
                        [
                            -83.0130220004418,
                            38.7291090002487
                        ],
                        [
                            -83.0124530003543,
                            38.7303820002457
                        ],
                        [
                            -83.0136559999283,
                            38.7306619998076
                        ],
                        [
                            -83.0150469998233,
                            38.7289590001122
                        ],
                        [
                            -83.0215119997516,
                            38.7287639998142
                        ],
                        [
                            -83.0302029998012,
                            38.7259469998168
                        ],
                        [
                            -83.0378209999731,
                            38.7172940000293
                        ],
                        [
                            -83.0425440003772,
                            38.7078200002162
                        ],
                        [
                            -83.0540180000415,
                            38.6950680002252
                        ],
                        [
                            -83.0625790003947,
                            38.6894569998071
                        ],
                        [
                            -83.0845690003719,
                            38.6808010003237
                        ],
                        [
                            -83.1016710000885,
                            38.6774909997861
                        ],
                        [
                            -83.1120579996315,
                            38.6718430001396
                        ],
                        [
                            -83.1221710004365,
                            38.6598619996619
                        ],
                        [
                            -83.1258269999124,
                            38.6465219998384
                        ],
                        [
                            -83.1302170002306,
                            38.6382269997169
                        ],
                        [
                            -83.1426530003428,
                            38.6250960003433
                        ],
                        [
                            -83.1568490004209,
                            38.620257999698
                        ],
                        [
                            -83.2014450003184,
                            38.6167300002553
                        ],
                        [
                            -83.2125189999497,
                            38.6190399996595
                        ],
                        [
                            -83.2301279998295,
                            38.626792999686
                        ],
                        [
                            -83.2452219998831,
                            38.6287110001865
                        ],
                        [
                            -83.2545409995713,
                            38.6234969998101
                        ],
                        [
                            -83.2648689995631,
                            38.62105800024
                        ],
                        [
                            -83.2684520001424,
                            38.615235999711
                        ],
                        [
                            -83.2818620003981,
                            38.6032460002114
                        ],
                        [
                            -83.2880410000962,
                            38.5983489998145
                        ],
                        [
                            -83.2930189998701,
                            38.5967640000651
                        ],
                        [
                            -83.3061920002993,
                            38.5999279999267
                        ],
                        [
                            -83.3177270000631,
                            38.6094130001551
                        ],
                        [
                            -83.3218140004325,
                            38.6300300000694
                        ],
                        [
                            -83.3276259997095,
                            38.6378509998179
                        ],
                        [
                            -83.3625530001143,
                            38.6570590001729
                        ],
                        [
                            -83.3848579997357,
                            38.6631110000847
                        ],
                        [
                            -83.4098210001968,
                            38.6667940001218
                        ],
                        [
                            -83.4145110000906,
                            38.666158999989
                        ],
                        [
                            -83.419585000124,
                            38.667003999766
                        ],
                        [
                            -83.4175249996841,
                            38.6678819996705
                        ],
                        [
                            -83.4207009996593,
                            38.6685159997838
                        ],
                        [
                            -83.4453989998065,
                            38.6699900000994
                        ],
                        [
                            -83.4571150002185,
                            38.6739940001625
                        ],
                        [
                            -83.4681329997733,
                            38.6754759997789
                        ],
                        [
                            -83.4906070003475,
                            38.692953999705
                        ],
                        [
                            -83.5062190004349,
                            38.6999839999448
                        ],
                        [
                            -83.5207550002717,
                            38.7030940002733
                        ],
                        [
                            -83.54522400009,
                            38.6999280003372
                        ],
                        [
                            -83.5693819995638,
                            38.692932999809
                        ],
                        [
                            -83.5924240004005,
                            38.6886490001006
                        ],
                        [
                            -83.6017420002638,
                            38.6839829997201
                        ],
                        [
                            -83.6114099999315,
                            38.6846790003215
                        ],
                        [
                            -83.6255299998417,
                            38.6788759997066
                        ],
                        [
                            -83.6320609998922,
                            38.6739030000659
                        ],
                        [
                            -83.6362119998358,
                            38.667928000142
                        ],
                        [
                            -83.6415939998408,
                            38.6440670000764
                        ],
                        [
                            -83.6482860004481,
                            38.6318180001862
                        ],
                        [
                            -83.6566600003053,
                            38.6280620002603
                        ],
                        [
                            -83.6687180001257,
                            38.6265419999742
                        ],
                        [
                            -83.684560000367,
                            38.6313209999137
                        ],
                        [
                            -83.7038909996795,
                            38.6395249998079
                        ],
                        [
                            -83.7134749996817,
                            38.6416689998806
                        ],
                        [
                            -83.7210230004317,
                            38.646695999768
                        ],
                        [
                            -83.749183999711,
                            38.6494320002458
                        ],
                        [
                            -83.7631959996661,
                            38.652241000311
                        ],
                        [
                            -83.7691199999943,
                            38.6550750001899
                        ],
                        [
                            -83.7723190004341,
                            38.6584840002144
                        ],
                        [
                            -83.7757320002358,
                            38.6667129998339
                        ],
                        [
                            -83.7843859995771,
                            38.6965810003391
                        ],
                        [
                            -83.7919340003271,
                            38.702254999926
                        ],
                        [
                            -83.822353000413,
                            38.7097769997598
                        ],
                        [
                            -83.8361920001158,
                            38.7173009999472
                        ],
                        [
                            -83.8413249997003,
                            38.7233630001865
                        ],
                        [
                            -83.8488909999937,
                            38.7474769996827
                        ],
                        [
                            -83.8583159999872,
                            38.7563250003111
                        ],
                        [
                            -83.8701989999055,
                            38.761532000247
                        ],
                        [
                            -83.8876760004391,
                            38.7657600002314
                        ],
                        [
                            -83.9256609999201,
                            38.7712079999917
                        ],
                        [
                            -83.9286410000767,
                            38.7724260001707
                        ],
                        [
                            -83.9263179998711,
                            38.7740489999465
                        ],
                        [
                            -83.9436229999773,
                            38.7833319999616
                        ],
                        [
                            -83.9618419999525,
                            38.7874919999856
                        ],
                        [
                            -83.9779319998908,
                            38.7873240002266
                        ],
                        [
                            -84.0437529998594,
                            38.7706700000897
                        ],
                        [
                            -84.070779000235,
                            38.7704139998052
                        ],
                        [
                            -84.0918140003353,
                            38.7742330002348
                        ],
                        [
                            -84.1472119998775,
                            38.7928610000816
                        ],
                        [
                            -84.1980040000897,
                            38.8009009998133
                        ],
                        [
                            -84.2131149998618,
                            38.8058350003114
                        ],
                        [
                            -84.224969000366,
                            38.8170369996619
                        ],
                        [
                            -84.231742000266,
                            38.8323009997211
                        ],
                        [
                            -84.2336610002405,
                            38.8540000000482
                        ],
                        [
                            -84.2315330000286,
                            38.8725330002665
                        ],
                        [
                            -84.2340269999384,
                            38.8914069998071
                        ],
                        [
                            -84.2570389997396,
                            38.9226490000531
                        ],
                        [
                            -84.2604449998685,
                            38.9247150002491
                        ],
                        [
                            -84.2695769999591,
                            38.9361689997354
                        ],
                        [
                            -84.2784529999565,
                            38.9436149998796
                        ],
                        [
                            -84.2887769997504,
                            38.9556439996627
                        ],
                        [
                            -84.2957959996976,
                            38.9692089997821
                        ],
                        [
                            -84.2979490000238,
                            38.9909470003083
                        ],
                        [
                            -84.3060740003589,
                            39.007753000283
                        ],
                        [
                            -84.3138349997477,
                            39.0165880003015
                        ],
                        [
                            -84.3266199998394,
                            39.0272619998614
                        ],
                        [
                            -84.3482039999357,
                            39.0377830001404
                        ],
                        [
                            -84.3769130002844,
                            39.0441630000302
                        ],
                        [
                            -84.4095679996389,
                            39.0462480000995
                        ],
                        [
                            -84.426255999977,
                            39.0530420001876
                        ],
                        [
                            -84.428564000114,
                            39.0555790001525
                        ],
                        [
                            -84.4237889998314,
                            39.0528740001461
                        ],
                        [
                            -84.4305609999064,
                            39.0587399999023
                        ],
                        [
                            -84.432731999776,
                            39.0668339998708
                        ],
                        [
                            -84.433717999615,
                            39.0842249999115
                        ],
                        [
                            -84.4354919998246,
                            39.0870350001703
                        ],
                        [
                            -84.4337670000188,
                            39.0948629999085
                        ],
                        [
                            -84.4351599995636,
                            39.1003619999012
                        ],
                        [
                            -84.4432199996014,
                            39.112173999837
                        ],
                        [
                            -84.4524699996927,
                            39.1192330001082
                        ],
                        [
                            -84.464211000219,
                            39.1220560000847
                        ],
                        [
                            -84.4759090001894,
                            39.1199229999946
                        ],
                        [
                            -84.4852439997712,
                            39.1136999998788
                        ],
                        [
                            -84.4972389998425,
                            39.1002520002485
                        ],
                        [
                            -84.5110409997353,
                            39.0936540000943
                        ],
                        [
                            -84.5256259999759,
                            39.0924780000621
                        ],
                        [
                            -84.5403419997377,
                            39.0989980001635
                        ],
                        [
                            -84.5513590003659,
                            39.0993599997551
                        ],
                        [
                            -84.5711009995822,
                            39.082744999656
                        ],
                        [
                            -84.6078890002352,
                            39.0732939999839
                        ],
                        [
                            -84.618734000436,
                            39.0733309997459
                        ],
                        [
                            -84.6325060001916,
                            39.0765440001849
                        ],
                        [
                            -84.6543389998099,
                            39.0938970000537
                        ],
                        [
                            -84.6666019996698,
                            39.0979529996705
                        ],
                        [
                            -84.6742139999939,
                            39.097714000032
                        ],
                        [
                            -84.6860729996228,
                            39.1008020002816
                        ],
                        [
                            -84.7180960000389,
                            39.1366160002727
                        ],
                        [
                            -84.7284290000536,
                            39.1431790001992
                        ],
                        [
                            -84.7427849999653,
                            39.1475569999181
                        ],
                        [
                            -84.7527339998404,
                            39.1472229996609
                        ],
                        [
                            -84.7610619996668,
                            39.1429050000545
                        ],
                        [
                            -84.7689359999318,
                            39.1366010001604
                        ],
                        [
                            -84.7877680003754,
                            39.1153129998819
                        ],
                        [
                            -84.8201569995911,
                            39.1054889997366
                        ],
                        [
                            -84.8201020002378,
                            39.251409999778
                        ],
                        [
                            -84.8148850000893,
                            39.5675470001408
                        ],
                        [
                            -84.8141870001304,
                            39.8136869997689
                        ],
                        [
                            -84.8037240004194,
                            40.3027969998501
                        ],
                        [
                            -84.8020930003249,
                            40.6915010001545
                        ],
                        [
                            -84.8032509996931,
                            40.9893939999234
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 40.4149297,
            "name": "Ohio",
            "intptlon": -82.7119975,
            "geo_point_2d": [
                40.4154068727,
                -82.7094377437
            ],
            "geoid": "39",
            "mtfcc": "G4000",
            "region": 2
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -82.7094377437,
                40.4154068727
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "47e13e91e99977d28dbc2c77b7898fac3ee60ad0",
        "fields": {
            "arealand": "-821915984",
            "objectid": 53,
            "basename": "Virginia",
            "stusab": "VA",
            "statens": "01779803",
            "centlon": -78.6759106,
            "state": "51",
            "gid": 19,
            "centlat": 37.518265,
            "division": 5,
            "areawater": "-61307834",
            "oid": "320541347",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -78.8692760004119,
                            38.7629909998472
                        ],
                        [
                            -78.8498139997814,
                            38.793012000216
                        ],
                        [
                            -78.8290559996906,
                            38.8186249998684
                        ],
                        [
                            -78.7963300001909,
                            38.8751110002269
                        ],
                        [
                            -78.7916100001599,
                            38.8775930001632
                        ],
                        [
                            -78.7860249997653,
                            38.8871869999525
                        ],
                        [
                            -78.7791980003371,
                            38.8922980001503
                        ],
                        [
                            -78.7727929997849,
                            38.8937420001457
                        ],
                        [
                            -78.7693580002419,
                            38.8970640003256
                        ],
                        [
                            -78.7590849996034,
                            38.9005289997787
                        ],
                        [
                            -78.7545160000834,
                            38.9057279998169
                        ],
                        [
                            -78.753690000078,
                            38.9095639996912
                        ],
                        [
                            -78.7503569997439,
                            38.9116749996835
                        ],
                        [
                            -78.7505169995775,
                            38.9160280001393
                        ],
                        [
                            -78.7445039995608,
                            38.9200590001186
                        ],
                        [
                            -78.7391469996702,
                            38.9271340002669
                        ],
                        [
                            -78.7224419996136,
                            38.9314080001924
                        ],
                        [
                            -78.7170760004005,
                            38.9360279999332
                        ],
                        [
                            -78.7200950000169,
                            38.9238629998601
                        ],
                        [
                            -78.7193479996542,
                            38.915748999766
                        ],
                        [
                            -78.7208320003339,
                            38.9136330002644
                        ],
                        [
                            -78.7192909997528,
                            38.905230000204
                        ],
                        [
                            -78.7166310001616,
                            38.9044750000927
                        ],
                        [
                            -78.7043230000959,
                            38.9152309998871
                        ],
                        [
                            -78.6974370002181,
                            38.9143590000251
                        ],
                        [
                            -78.6882659997695,
                            38.9247800001917
                        ],
                        [
                            -78.6804559999769,
                            38.925312999851
                        ],
                        [
                            -78.670678999631,
                            38.9337999998227
                        ],
                        [
                            -78.665885999805,
                            38.9415790003088
                        ],
                        [
                            -78.6590500001559,
                            38.9473749998466
                        ],
                        [
                            -78.6523519997008,
                            38.9606769996868
                        ],
                        [
                            -78.6465889999293,
                            38.9681379997155
                        ],
                        [
                            -78.6384229995863,
                            38.9668190001394
                        ],
                        [
                            -78.632470999669,
                            38.9747389997297
                        ],
                        [
                            -78.6308460003206,
                            38.9795859997885
                        ],
                        [
                            -78.6277980003919,
                            38.9817900000623
                        ],
                        [
                            -78.6204530000315,
                            38.9826010002592
                        ],
                        [
                            -78.6186760003471,
                            38.9740819997164
                        ],
                        [
                            -78.6111839996736,
                            38.9761340000678
                        ],
                        [
                            -78.6101970000097,
                            38.9704759999078
                        ],
                        [
                            -78.6016549999231,
                            38.9646030000538
                        ],
                        [
                            -78.5988940000496,
                            38.9695459999072
                        ],
                        [
                            -78.5932609999744,
                            38.9719179998902
                        ],
                        [
                            -78.5889290002809,
                            38.9769579998779
                        ],
                        [
                            -78.5804650000121,
                            38.9905669998225
                        ],
                        [
                            -78.5704619997103,
                            39.0015519999884
                        ],
                        [
                            -78.5504669998757,
                            39.0180650002431
                        ],
                        [
                            -78.557380000416,
                            39.0213930000048
                        ],
                        [
                            -78.5596399999741,
                            39.0244559999721
                        ],
                        [
                            -78.5658370001138,
                            39.0263030002379
                        ],
                        [
                            -78.5719010001841,
                            39.0319950002337
                        ],
                        [
                            -78.5402159998768,
                            39.0606310001878
                        ],
                        [
                            -78.5227140001271,
                            39.0710619998688
                        ],
                        [
                            -78.5167889999739,
                            39.0775689997494
                        ],
                        [
                            -78.5164790003525,
                            39.0818019999963
                        ],
                        [
                            -78.5107030001623,
                            39.0852049997659
                        ],
                        [
                            -78.4951599996718,
                            39.1009919997395
                        ],
                        [
                            -78.4795969999881,
                            39.1095049999176
                        ],
                        [
                            -78.4753759997244,
                            39.1074689999818
                        ],
                        [
                            -78.4666620001086,
                            39.1128580000103
                        ],
                        [
                            -78.4598690001172,
                            39.1133509998354
                        ],
                        [
                            -78.4394290000437,
                            39.1321460002904
                        ],
                        [
                            -78.4366580001244,
                            39.1416909997491
                        ],
                        [
                            -78.4272940002303,
                            39.1527260002072
                        ],
                        [
                            -78.4139430004242,
                            39.1584149998286
                        ],
                        [
                            -78.4036970004482,
                            39.1674509999537
                        ],
                        [
                            -78.4069660003098,
                            39.1709030001099
                        ],
                        [
                            -78.4131309997641,
                            39.1736379997874
                        ],
                        [
                            -78.4148439998744,
                            39.1778349998584
                        ],
                        [
                            -78.4189439997643,
                            39.1773540000543
                        ],
                        [
                            -78.4239890003837,
                            39.1824909997467
                        ],
                        [
                            -78.426315000064,
                            39.1827619999029
                        ],
                        [
                            -78.4286969998209,
                            39.1872170000642
                        ],
                        [
                            -78.4242920003324,
                            39.1921559998542
                        ],
                        [
                            -78.4249049999025,
                            39.1933010003417
                        ],
                        [
                            -78.4264370002628,
                            39.1940849997095
                        ],
                        [
                            -78.4286759999045,
                            39.1926330002553
                        ],
                        [
                            -78.4308459999492,
                            39.1962270001653
                        ],
                        [
                            -78.4386950000998,
                            39.1980929996747
                        ],
                        [
                            -78.4205480000946,
                            39.2147979998853
                        ],
                        [
                            -78.4055849995623,
                            39.2311759997485
                        ],
                        [
                            -78.4042139997588,
                            39.2412140001144
                        ],
                        [
                            -78.3996689996299,
                            39.2438739998668
                        ],
                        [
                            -78.409116000263,
                            39.252563999656
                        ],
                        [
                            -78.4194219996152,
                            39.2574760001397
                        ],
                        [
                            -78.4027829996809,
                            39.2754709999298
                        ],
                        [
                            -78.4018129997354,
                            39.2767540000782
                        ],
                        [
                            -78.402885999613,
                            39.2786239998241
                        ],
                        [
                            -78.3998910002861,
                            39.2813450001968
                        ],
                        [
                            -78.3978960001435,
                            39.2805619996731
                        ],
                        [
                            -78.3933710001062,
                            39.2829879999524
                        ],
                        [
                            -78.3858840003538,
                            39.2948880000244
                        ],
                        [
                            -78.3672419998811,
                            39.3101480000709
                        ],
                        [
                            -78.3639620001488,
                            39.3156269997576
                        ],
                        [
                            -78.3640740003018,
                            39.3188200001464
                        ],
                        [
                            -78.3600349996129,
                            39.3177710001482
                        ],
                        [
                            -78.35571399979,
                            39.3271749997727
                        ],
                        [
                            -78.3463010003904,
                            39.3391080000015
                        ],
                        [
                            -78.3454600003164,
                            39.3410240000556
                        ],
                        [
                            -78.3474090004282,
                            39.3434019999095
                        ],
                        [
                            -78.3436850002818,
                            39.346712999945
                        ],
                        [
                            -78.3413079996496,
                            39.345554999713
                        ],
                        [
                            -78.339284000093,
                            39.3486049997997
                        ],
                        [
                            -78.340414999697,
                            39.3536279998977
                        ],
                        [
                            -78.3668669999623,
                            39.3592899998852
                        ],
                        [
                            -78.3548369997309,
                            39.3716160000494
                        ],
                        [
                            -78.3432140001037,
                            39.3888069996535
                        ],
                        [
                            -78.3509240003371,
                            39.3909850002737
                        ],
                        [
                            -78.3494360003577,
                            39.3972519996556
                        ],
                        [
                            -78.3579490001321,
                            39.404191999729
                        ],
                        [
                            -78.3599180003353,
                            39.4090280003401
                        ],
                        [
                            -78.3569380001787,
                            39.4169290002111
                        ],
                        [
                            -78.3512359996083,
                            39.4205949997219
                        ],
                        [
                            -78.3467180001419,
                            39.4276180002206
                        ],
                        [
                            -78.3532269995529,
                            39.436792000298
                        ],
                        [
                            -78.3465459997145,
                            39.4426159999241
                        ],
                        [
                            -78.3473330002602,
                            39.4476589998379
                        ],
                        [
                            -78.345143000124,
                            39.4594839997995
                        ],
                        [
                            -78.3497979998577,
                            39.4618070001488
                        ],
                        [
                            -78.3470870002129,
                            39.4660119998373
                        ],
                        [
                            -78.2627850003283,
                            39.414323000081
                        ],
                        [
                            -78.1581930001581,
                            39.3433909996918
                        ],
                        [
                            -78.140919999839,
                            39.3337450001893
                        ],
                        [
                            -77.82829900012,
                            39.1324260000647
                        ],
                        [
                            -77.8221010001554,
                            39.1401050003352
                        ],
                        [
                            -77.8233459995621,
                            39.1456249998137
                        ],
                        [
                            -77.8213699996862,
                            39.1523510000557
                        ],
                        [
                            -77.8136260000159,
                            39.1644180001842
                        ],
                        [
                            -77.8060489998518,
                            39.171736999811
                        ],
                        [
                            -77.8049049998291,
                            39.1790509997732
                        ],
                        [
                            -77.7981639997162,
                            39.1925179996549
                        ],
                        [
                            -77.7981570000435,
                            39.200702999957
                        ],
                        [
                            -77.7926319999235,
                            39.2089980001047
                        ],
                        [
                            -77.7926629998856,
                            39.2113879996673
                        ],
                        [
                            -77.78884099983,
                            39.2148650000833
                        ],
                        [
                            -77.7859269997956,
                            39.2196659996872
                        ],
                        [
                            -77.7863219998058,
                            39.221807000187
                        ],
                        [
                            -77.7807990002339,
                            39.2287209999597
                        ],
                        [
                            -77.7774159996711,
                            39.2297020002245
                        ],
                        [
                            -77.7713650000196,
                            39.2364929997849
                        ],
                        [
                            -77.7672269995965,
                            39.2490090003407
                        ],
                        [
                            -77.7709370003974,
                            39.249445999872
                        ],
                        [
                            -77.7701020001711,
                            39.2563689997572
                        ],
                        [
                            -77.7629280004133,
                            39.2582730002669
                        ],
                        [
                            -77.7617679995987,
                            39.2630310003461
                        ],
                        [
                            -77.7586519998979,
                            39.2646579997117
                        ],
                        [
                            -77.7579670003578,
                            39.2722519997491
                        ],
                        [
                            -77.7531050000365,
                            39.2773399998949
                        ],
                        [
                            -77.7527259999198,
                            39.2833729997413
                        ],
                        [
                            -77.7482949995938,
                            39.2936959999471
                        ],
                        [
                            -77.735517999898,
                            39.311871000212
                        ],
                        [
                            -77.7306969995846,
                            39.3156030001075
                        ],
                        [
                            -77.7216380001874,
                            39.3184940000779
                        ],
                        [
                            -77.7195190001963,
                            39.3213140001302
                        ],
                        [
                            -77.7076739999128,
                            39.3217280001463
                        ],
                        [
                            -77.6930080003797,
                            39.3184370001039
                        ],
                        [
                            -77.6864999998954,
                            39.3201989997619
                        ],
                        [
                            -77.681722000138,
                            39.323888000218
                        ],
                        [
                            -77.676809999588,
                            39.3245580002013
                        ],
                        [
                            -77.6650799998306,
                            39.3168049998015
                        ],
                        [
                            -77.6508989998211,
                            39.3108460001765
                        ],
                        [
                            -77.6440840000884,
                            39.3087500001206
                        ],
                        [
                            -77.6309249999029,
                            39.3077189996543
                        ],
                        [
                            -77.6159100003903,
                            39.3025479998335
                        ],
                        [
                            -77.6062660001137,
                            39.3037599998912
                        ],
                        [
                            -77.5926199998565,
                            39.3012010000008
                        ],
                        [
                            -77.5792249996694,
                            39.3050799999875
                        ],
                        [
                            -77.5665979997615,
                            39.3060469997998
                        ],
                        [
                            -77.5618280004,
                            39.3014689998089
                        ],
                        [
                            -77.562774999881,
                            39.2944689997719
                        ],
                        [
                            -77.5606900002251,
                            39.2859599998943
                        ],
                        [
                            -77.5541559998015,
                            39.2814310002066
                        ],
                        [
                            -77.5430860003681,
                            39.2669159997163
                        ],
                        [
                            -77.5106679999417,
                            39.253256000226
                        ],
                        [
                            -77.4972669999069,
                            39.2513279996957
                        ],
                        [
                            -77.4866460000121,
                            39.2472810000127
                        ],
                        [
                            -77.4711050000698,
                            39.2343029999416
                        ],
                        [
                            -77.4607260000244,
                            39.2287990002315
                        ],
                        [
                            -77.4577099998827,
                            39.2249789997961
                        ],
                        [
                            -77.4598590000111,
                            39.2188870002308
                        ],
                        [
                            -77.4741560003715,
                            39.2077629998943
                        ],
                        [
                            -77.4759949999802,
                            39.2018269996531
                        ],
                        [
                            -77.4750879997839,
                            39.1954330002621
                        ],
                        [
                            -77.4786720001881,
                            39.1896710003352
                        ],
                        [
                            -77.4857949998924,
                            39.1860250002368
                        ],
                        [
                            -77.5075310003246,
                            39.1814020001756
                        ],
                        [
                            -77.5176219995916,
                            39.1694170003442
                        ],
                        [
                            -77.5247400001713,
                            39.1491310002614
                        ],
                        [
                            -77.527680000145,
                            39.1446579999769
                        ],
                        [
                            -77.5251750003645,
                            39.1292660000152
                        ],
                        [
                            -77.5200920001103,
                            39.120869000313
                        ],
                        [
                            -77.485400999707,
                            39.1093099998211
                        ],
                        [
                            -77.4789500000224,
                            39.1034100002669
                        ],
                        [
                            -77.4734900001997,
                            39.0940439999152
                        ],
                        [
                            -77.4686599996655,
                            39.0893890000714
                        ],
                        [
                            -77.4689569997664,
                            39.0856220002351
                        ],
                        [
                            -77.462500000234,
                            39.0764040002895
                        ],
                        [
                            -77.4569709999159,
                            39.0733990002005
                        ],
                        [
                            -77.4348379998237,
                            39.070201999953
                        ],
                        [
                            -77.420733999807,
                            39.0663289998753
                        ],
                        [
                            -77.3983539998425,
                            39.0646569999165
                        ],
                        [
                            -77.3857939998817,
                            39.0621320003284
                        ],
                        [
                            -77.3371369995541,
                            39.0622649997553
                        ],
                        [
                            -77.3198289999732,
                            39.0534190002131
                        ],
                        [
                            -77.3061030002483,
                            39.0518789998374
                        ],
                        [
                            -77.29407600039,
                            39.0469459998673
                        ],
                        [
                            -77.2750370002571,
                            39.0342840001872
                        ],
                        [
                            -77.2547460001465,
                            39.0297329998036
                        ],
                        [
                            -77.2484190003105,
                            39.0265539999955
                        ],
                        [
                            -77.2450929996491,
                            39.0221460000517
                        ],
                        [
                            -77.2453799997043,
                            39.0175310000172
                        ],
                        [
                            -77.2532589999921,
                            39.0091579999301
                        ],
                        [
                            -77.2557649995975,
                            39.0018830002616
                        ],
                        [
                            -77.2529400001499,
                            38.9954509999489
                        ],
                        [
                            -77.2486140003041,
                            38.9926239997658
                        ],
                        [
                            -77.2450299998999,
                            38.9826840001023
                        ],
                        [
                            -77.2399500000188,
                            38.98075399971
                        ],
                        [
                            -77.2351959996525,
                            38.9760800002506
                        ],
                        [
                            -77.2323279996489,
                            38.9792880000944
                        ],
                        [
                            -77.22995000009,
                            38.9797090002832
                        ],
                        [
                            -77.2237489997523,
                            38.9721199996667
                        ],
                        [
                            -77.2115200002277,
                            38.969343999774
                        ],
                        [
                            -77.2081380003881,
                            38.9702470002891
                        ],
                        [
                            -77.2006919997453,
                            38.9672609998719
                        ],
                        [
                            -77.1812540003026,
                            38.9687790000589
                        ],
                        [
                            -77.1698020003796,
                            38.9663150000751
                        ],
                        [
                            -77.1643500000544,
                            38.967715999715
                        ],
                        [
                            -77.1471970002842,
                            38.9644390002188
                        ],
                        [
                            -77.1353240004117,
                            38.9536010000099
                        ],
                        [
                            -77.1286540004439,
                            38.9413980002227
                        ],
                        [
                            -77.1197590001799,
                            38.9343430002786
                        ],
                        [
                            -77.1159570002158,
                            38.928164999833
                        ],
                        [
                            -77.1068009998358,
                            38.920025999669
                        ],
                        [
                            -77.1030049997195,
                            38.9127080000054
                        ],
                        [
                            -77.0932159996779,
                            38.9057669998791
                        ],
                        [
                            -77.0826580004307,
                            38.9022739997139
                        ],
                        [
                            -77.068691999608,
                            38.9003220001184
                        ],
                        [
                            -77.0642510001345,
                            38.8889559997074
                        ],
                        [
                            -77.0591999996674,
                            38.8809849997954
                        ],
                        [
                            -77.0541409997026,
                            38.8797119996672
                        ],
                        [
                            -77.0515310003402,
                            38.8765639996969
                        ],
                        [
                            -77.0505520001739,
                            38.8719820001179
                        ],
                        [
                            -77.046551999843,
                            38.8720269998358
                        ],
                        [
                            -77.0472189998398,
                            38.8749579998757
                        ],
                        [
                            -77.0460420002051,
                            38.8756169996825
                        ],
                        [
                            -77.0416719999784,
                            38.8725330002665
                        ],
                        [
                            -77.0377389995948,
                            38.8647809998479
                        ],
                        [
                            -77.0382740002454,
                            38.8640210001824
                        ],
                        [
                            -77.0424519999531,
                            38.8639450001188
                        ],
                        [
                            -77.0427630002977,
                            38.8633720001806
                        ],
                        [
                            -77.0380209996271,
                            38.8632389996762
                        ],
                        [
                            -77.0328629999281,
                            38.855077000085
                        ],
                        [
                            -77.0321430002279,
                            38.8502869996832
                        ],
                        [
                            -77.0346439998104,
                            38.8400439999499
                        ],
                        [
                            -77.0399749997617,
                            38.8392769998663
                        ],
                        [
                            -77.0480199997309,
                            38.8412610002234
                        ],
                        [
                            -77.0458699997777,
                            38.8401480001312
                        ],
                        [
                            -77.0432069998134,
                            38.8314299999871
                        ],
                        [
                            -77.041984000148,
                            38.8314840000691
                        ],
                        [
                            -77.0417869996062,
                            38.8336759998945
                        ],
                        [
                            -77.0376990003101,
                            38.8295000000653
                        ],
                        [
                            -77.0387839998833,
                            38.8244730001069
                        ],
                        [
                            -77.0411070000889,
                            38.822939999809
                        ],
                        [
                            -77.039459000276,
                            38.8215569998046
                        ],
                        [
                            -77.0372880004063,
                            38.8105269999731
                        ],
                        [
                            -77.0390059996411,
                            38.7916449999142
                        ],
                        [
                            -77.0404650002065,
                            38.7904049997555
                        ],
                        [
                            -77.0402500001213,
                            38.7852159997464
                        ],
                        [
                            -77.041362000357,
                            38.7854149996579
                        ],
                        [
                            -77.0422679998301,
                            38.7411550000355
                        ],
                        [
                            -77.0434779999751,
                            38.7392409997736
                        ],
                        [
                            -77.0415899999627,
                            38.7368170002298
                        ],
                        [
                            -77.0414689995888,
                            38.7258470000072
                        ],
                        [
                            -77.0438770001833,
                            38.7176739998886
                        ],
                        [
                            -77.0461960001909,
                            38.7144309999531
                        ],
                        [
                            -77.0535220002847,
                            38.7098039999756
                        ],
                        [
                            -77.0714330002884,
                            38.7099809998137
                        ],
                        [
                            -77.0743039997667,
                            38.7129019998893
                        ],
                        [
                            -77.0792320002102,
                            38.7130520000877
                        ],
                        [
                            -77.0807999996572,
                            38.711125000313
                        ],
                        [
                            -77.0800370002993,
                            38.708923999946
                        ],
                        [
                            -77.098272000168,
                            38.6990530001602
                        ],
                        [
                            -77.1037649996027,
                            38.6978599998951
                        ],
                        [
                            -77.1226320002064,
                            38.6852559998184
                        ],
                        [
                            -77.1324389997913,
                            38.6741429998242
                        ],
                        [
                            -77.1364230002286,
                            38.6502260003447
                        ],
                        [
                            -77.1362929996339,
                            38.6472310002838
                        ],
                        [
                            -77.1329319997108,
                            38.6447149998401
                        ],
                        [
                            -77.133506999646,
                            38.6425249997624
                        ],
                        [
                            -77.1296820001157,
                            38.6347039998598
                        ],
                        [
                            -77.1663650002885,
                            38.6251059996779
                        ],
                        [
                            -77.1802719997633,
                            38.6232459997903
                        ],
                        [
                            -77.1914869998599,
                            38.6191749998532
                        ],
                        [
                            -77.2036960001914,
                            38.6181720001435
                        ],
                        [
                            -77.2463620002436,
                            38.5934410001575
                        ],
                        [
                            -77.2564540002339,
                            38.5605869997997
                        ],
                        [
                            -77.2607480002926,
                            38.5596639999023
                        ],
                        [
                            -77.2621979997388,
                            38.5561699997694
                        ],
                        [
                            -77.2740329999765,
                            38.5501520002418
                        ],
                        [
                            -77.2765539996505,
                            38.5462629996703
                        ],
                        [
                            -77.2763389995653,
                            38.5394450003375
                        ],
                        [
                            -77.2789739999405,
                            38.5365099997758
                        ],
                        [
                            -77.2829139999968,
                            38.5255580002689
                        ],
                        [
                            -77.2872940002692,
                            38.521463000036
                        ],
                        [
                            -77.2853090001724,
                            38.5196949997314
                        ],
                        [
                            -77.2871210000169,
                            38.5204009998522
                        ],
                        [
                            -77.2897379999503,
                            38.5180559998533
                        ],
                        [
                            -77.2957760000814,
                            38.5073840002258
                        ],
                        [
                            -77.3030839997336,
                            38.499188000321
                        ],
                        [
                            -77.3105579999655,
                            38.4939930003164
                        ],
                        [
                            -77.3153159996314,
                            38.4785490002929
                        ],
                        [
                            -77.3185419998353,
                            38.4744370001315
                        ],
                        [
                            -77.3203999997105,
                            38.4352209999884
                        ],
                        [
                            -77.3227780001677,
                            38.4344440003249
                        ],
                        [
                            -77.3229100004121,
                            38.4326249999275
                        ],
                        [
                            -77.3187860002327,
                            38.4176710002749
                        ],
                        [
                            -77.3104449999876,
                            38.4001010002623
                        ],
                        [
                            -77.3108359997998,
                            38.3968820002771
                        ],
                        [
                            -77.3049380003092,
                            38.3752710002261
                        ],
                        [
                            -77.2967920000576,
                            38.3705000000481
                        ],
                        [
                            -77.2881949997194,
                            38.3593849999304
                        ],
                        [
                            -77.287264000132,
                            38.3530919996939
                        ],
                        [
                            -77.2884070003299,
                            38.3514010002553
                        ],
                        [
                            -77.286202000125,
                            38.3470239998201
                        ],
                        [
                            -77.2837960000788,
                            38.3428550002625
                        ],
                        [
                            -77.276581000313,
                            38.3378810003122
                        ],
                        [
                            -77.2652950000713,
                            38.3331639997401
                        ],
                        [
                            -77.2400710003926,
                            38.3315970002077
                        ],
                        [
                            -77.204693999726,
                            38.3410959998276
                        ],
                        [
                            -77.1793389996277,
                            38.3419140000479
                        ],
                        [
                            -77.1632189995522,
                            38.3458229998221
                        ],
                        [
                            -77.1551910001997,
                            38.351046000116
                        ],
                        [
                            -77.1382240002024,
                            38.3679159996483
                        ],
                        [
                            -77.124699000319,
                            38.3667599998293
                        ],
                        [
                            -77.1048740003637,
                            38.3697459997729
                        ],
                        [
                            -77.0900890001065,
                            38.3672750000838
                        ],
                        [
                            -77.0842379995732,
                            38.3687389996671
                        ],
                        [
                            -77.0705500003815,
                            38.3781509996813
                        ],
                        [
                            -77.0534200001775,
                            38.3988130001278
                        ],
                        [
                            -77.0415049995738,
                            38.4006919997718
                        ],
                        [
                            -77.0180190001198,
                            38.3816010003022
                        ],
                        [
                            -77.0114639997797,
                            38.3744249996547
                        ],
                        [
                            -77.0159109999993,
                            38.3710239998175
                        ],
                        [
                            -77.0146480001509,
                            38.3540430001923
                        ],
                        [
                            -77.0168070003249,
                            38.3416879999871
                        ],
                        [
                            -77.0143479996769,
                            38.3377230001715
                        ],
                        [
                            -77.0145269997771,
                            38.3326339998758
                        ],
                        [
                            -77.0306829998376,
                            38.3116220001901
                        ],
                        [
                            -77.028764999688,
                            38.3113820000606
                        ],
                        [
                            -77.0281310002015,
                            38.3053169998185
                        ],
                        [
                            -77.0235080002548,
                            38.3014550002776
                        ],
                        [
                            -77.0164490001247,
                            38.2988370001844
                        ],
                        [
                            -77.0011419996357,
                            38.2801810001223
                        ],
                        [
                            -76.9908689998955,
                            38.273638999973
                        ],
                        [
                            -76.9813459999927,
                            38.274541999683
                        ],
                        [
                            -76.962085999927,
                            38.2569640001192
                        ],
                        [
                            -76.9569230002052,
                            38.2374720002958
                        ],
                        [
                            -76.958099000015,
                            38.2340580002682
                        ],
                        [
                            -76.9622509997834,
                            38.2298880001568
                        ],
                        [
                            -76.9490199996279,
                            38.2084179997156
                        ],
                        [
                            -76.9368379999589,
                            38.2023849999221
                        ],
                        [
                            -76.9161769999524,
                            38.1997350000335
                        ],
                        [
                            -76.9111299996832,
                            38.1973570002696
                        ],
                        [
                            -76.8767439997768,
                            38.173019000247
                        ],
                        [
                            -76.8588360001462,
                            38.1709989997466
                        ],
                        [
                            -76.8542609998801,
                            38.1673019997119
                        ],
                        [
                            -76.839367999668,
                            38.1636089998216
                        ],
                        [
                            -76.8242839996601,
                            38.1636460000416
                        ],
                        [
                            -76.7998869998118,
                            38.1689329997003
                        ],
                        [
                            -76.7639729997431,
                            38.1677909998487
                        ],
                        [
                            -76.7514169999802,
                            38.1646890000258
                        ],
                        [
                            -76.690265999814,
                            38.1605489998267
                        ],
                        [
                            -76.6359720001117,
                            38.1525599998867
                        ],
                        [
                            -76.6291630002267,
                            38.1534830002444
                        ],
                        [
                            -76.6213299999697,
                            38.1506380001042
                        ],
                        [
                            -76.6054300000021,
                            38.1489330000971
                        ],
                        [
                            -76.6049720002427,
                            38.1479899997502
                        ],
                        [
                            -76.6103840003849,
                            38.1485160002561
                        ],
                        [
                            -76.6017209999245,
                            38.1251290002303
                        ],
                        [
                            -76.6004670002968,
                            38.1130879997792
                        ],
                        [
                            -76.5961559996214,
                            38.1066760002497
                        ],
                        [
                            -76.5880799996901,
                            38.1022080001807
                        ],
                        [
                            -76.5815679999061,
                            38.0959089998953
                        ],
                        [
                            -76.5566289997345,
                            38.0858730000765
                        ],
                        [
                            -76.5364570003478,
                            38.0749030001425
                        ],
                        [
                            -76.5341910000436,
                            38.0635389999172
                        ],
                        [
                            -76.5263579997866,
                            38.0531079997467
                        ],
                        [
                            -76.5161600003894,
                            38.0449199998134
                        ],
                        [
                            -76.516608999928,
                            38.0267829999005
                        ],
                        [
                            -76.4919760000781,
                            38.0177140001256
                        ],
                        [
                            -76.4808940000509,
                            38.0152769996672
                        ],
                        [
                            -76.4733970002528,
                            38.0162319999187
                        ],
                        [
                            -76.4650080003271,
                            38.0132200003033
                        ],
                        [
                            -76.4338999996048,
                            37.9818470000782
                        ],
                        [
                            -76.4254599996254,
                            37.9768709998206
                        ],
                        [
                            -76.4129339999998,
                            37.966331999959
                        ],
                        [
                            -76.3890119996293,
                            37.9583499999641
                        ],
                        [
                            -76.3593249998376,
                            37.9522730002115
                        ],
                        [
                            -76.3333720002378,
                            37.9429559999309
                        ],
                        [
                            -76.261811999889,
                            37.9087859998417
                        ],
                        [
                            -76.2479060002391,
                            37.8948689998404
                        ],
                        [
                            -76.2407769997887,
                            37.890625999937
                        ],
                        [
                            -76.2365720003168,
                            37.8901460001092
                        ],
                        [
                            -76.2364579996156,
                            37.8866049997297
                        ],
                        [
                            -76.1246609997254,
                            37.9287079999005
                        ],
                        [
                            -76.0520209998262,
                            37.953577999979
                        ],
                        [
                            -75.9933379996306,
                            37.9534870000633
                        ],
                        [
                            -75.9436929998141,
                            37.9461329996952
                        ],
                        [
                            -75.9526719997437,
                            37.9068269997509
                        ],
                        [
                            -75.8829390002063,
                            37.9113350000094
                        ],
                        [
                            -75.8012400000115,
                            37.912174000344
                        ],
                        [
                            -75.7616419999794,
                            37.9413659997644
                        ],
                        [
                            -75.7046340002592,
                            37.9546979999537
                        ],
                        [
                            -75.6913210000879,
                            37.9554960000357
                        ],
                        [
                            -75.6652999998178,
                            37.9495110002623
                        ],
                        [
                            -75.6556809996556,
                            37.9454329997954
                        ],
                        [
                            -75.6477000001588,
                            37.9469630002959
                        ],
                        [
                            -75.6482280002384,
                            37.9667709998575
                        ],
                        [
                            -75.6382219995636,
                            37.9793949998982
                        ],
                        [
                            -75.6357350002248,
                            37.9795340003059
                        ],
                        [
                            -75.6320079997054,
                            37.976017999886
                        ],
                        [
                            -75.6295320002372,
                            37.9759629999791
                        ],
                        [
                            -75.6288529996466,
                            37.9777949999065
                        ],
                        [
                            -75.6338320001437,
                            37.9845170001545
                        ],
                        [
                            -75.6308679998806,
                            37.987816000322
                        ],
                        [
                            -75.6256110004475,
                            37.9897980002914
                        ],
                        [
                            -75.6244489999831,
                            37.9941949998984
                        ],
                        [
                            -75.2496090002431,
                            38.0267150000812
                        ],
                        [
                            -75.1664349995895,
                            38.0278340000703
                        ],
                        [
                            -75.2417580004427,
                            37.9315319998632
                        ],
                        [
                            -75.2591909996971,
                            37.9043320003467
                        ],
                        [
                            -75.2749810000598,
                            37.8751239998963
                        ],
                        [
                            -75.2960039995662,
                            37.8457520000105
                        ],
                        [
                            -75.3031320001917,
                            37.8381689999228
                        ],
                        [
                            -75.3122090000306,
                            37.830612000117
                        ],
                        [
                            -75.3379790002305,
                            37.8152319996618
                        ],
                        [
                            -75.3601249998433,
                            37.8101860003443
                        ],
                        [
                            -75.3801740001046,
                            37.8104039996464
                        ],
                        [
                            -75.3936880001173,
                            37.8135160001463
                        ],
                        [
                            -75.4092850001362,
                            37.8209189999276
                        ],
                        [
                            -75.4170730001874,
                            37.8210499996973
                        ],
                        [
                            -75.4609329995928,
                            37.7713559997219
                        ],
                        [
                            -75.4744070003209,
                            37.7391890001005
                        ],
                        [
                            -75.4783440000042,
                            37.7332410003164
                        ],
                        [
                            -75.4872250000245,
                            37.7248770001422
                        ],
                        [
                            -75.5022060001002,
                            37.695979000353
                        ],
                        [
                            -75.5077460002889,
                            37.6886539996766
                        ],
                        [
                            -75.5159619999623,
                            37.6715379999155
                        ],
                        [
                            -75.5157169997399,
                            37.660964000266
                        ],
                        [
                            -75.5184600000701,
                            37.6494259997801
                        ],
                        [
                            -75.5253750002602,
                            37.6374590003039
                        ],
                        [
                            -75.5331830004029,
                            37.6298839997018
                        ],
                        [
                            -75.5417790000179,
                            37.6061450003346
                        ],
                        [
                            -75.5332479998019,
                            37.595440999682
                        ],
                        [
                            -75.5284449999302,
                            37.5848760002442
                        ],
                        [
                            -75.52691299957,
                            37.5710839999627
                        ],
                        [
                            -75.5299010001225,
                            37.5567170002414
                        ],
                        [
                            -75.5408059996994,
                            37.5389499998912
                        ],
                        [
                            -75.5550610002271,
                            37.5266769998788
                        ],
                        [
                            -75.5796389998254,
                            37.4946370002618
                        ],
                        [
                            -75.5891099998496,
                            37.4778570000838
                        ],
                        [
                            -75.5956419997251,
                            37.4655839998004
                        ],
                        [
                            -75.5960459999561,
                            37.4531330002241
                        ],
                        [
                            -75.6039589996808,
                            37.43259000007
                        ],
                        [
                            -75.604025999628,
                            37.4220240001841
                        ],
                        [
                            -75.6182529996682,
                            37.4022630000606
                        ],
                        [
                            -75.6246449998016,
                            37.3969510002071
                        ],
                        [
                            -75.6434560003288,
                            37.3728640000077
                        ],
                        [
                            -75.6403179999885,
                            37.3638650002233
                        ],
                        [
                            -75.6443860000913,
                            37.3422700003354
                        ],
                        [
                            -75.6565900004,
                            37.3239139997111
                        ],
                        [
                            -75.6789639996184,
                            37.3125769997948
                        ],
                        [
                            -75.715515000445,
                            37.2756869997529
                        ],
                        [
                            -75.7153770003527,
                            37.2699720002637
                        ],
                        [
                            -75.7278209999625,
                            37.2501410003444
                        ],
                        [
                            -75.7322840000757,
                            37.245694000354
                        ],
                        [
                            -75.7283669995855,
                            37.2356169998828
                        ],
                        [
                            -75.7292710003071,
                            37.2169010001642
                        ],
                        [
                            -75.7428310003505,
                            37.1929070003189
                        ],
                        [
                            -75.744638999997,
                            37.1823489997329
                        ],
                        [
                            -75.7660490000161,
                            37.1549320001908
                        ],
                        [
                            -75.7815869995853,
                            37.1371159999618
                        ],
                        [
                            -75.7970349996413,
                            37.1251449999694
                        ],
                        [
                            -75.8059070003391,
                            37.1160609996457
                        ],
                        [
                            -75.8241470002307,
                            37.110121999959
                        ],
                        [
                            -75.8356309999408,
                            37.0950060002631
                        ],
                        [
                            -75.85454700005,
                            37.0793489997883
                        ],
                        [
                            -75.8694090003,
                            37.0739510000306
                        ],
                        [
                            -75.8896760001211,
                            37.0545149998085
                        ],
                        [
                            -75.905749000341,
                            37.00014399991
                        ],
                        [
                            -75.9283069996824,
                            36.9381790002468
                        ],
                        [
                            -75.8746470003649,
                            36.7443050001913
                        ],
                        [
                            -75.8621190001911,
                            36.7140510001768
                        ],
                        [
                            -75.8439909995542,
                            36.6797729998312
                        ],
                        [
                            -75.8297579996662,
                            36.6446320000395
                        ],
                        [
                            -75.8198249996846,
                            36.6251530000023
                        ],
                        [
                            -75.7974969995986,
                            36.5509159998209
                        ],
                        [
                            -76.7489540000326,
                            36.5507479999891
                        ],
                        [
                            -76.915899000118,
                            36.5521490001173
                        ],
                        [
                            -76.9170980003924,
                            36.5508569998099
                        ],
                        [
                            -76.9165100000383,
                            36.5438810001629
                        ],
                        [
                            -77.1529199998727,
                            36.5441299996612
                        ],
                        [
                            -77.1696600000894,
                            36.5473149999565
                        ],
                        [
                            -77.2049699999105,
                            36.5446010003184
                        ],
                        [
                            -78.2497170003794,
                            36.5444849998284
                        ],
                        [
                            -78.510897000331,
                            36.5410489999758
                        ],
                        [
                            -78.9369800003699,
                            36.5421900001614
                        ],
                        [
                            -79.5433869998895,
                            36.5408560001517
                        ],
                        [
                            -80.1382760002622,
                            36.5426320002568
                        ],
                        [
                            -80.294876999564,
                            36.5440530001802
                        ],
                        [
                            -80.704601000051,
                            36.5623019999865
                        ],
                        [
                            -80.7735979999948,
                            36.5603740000134
                        ],
                        [
                            -80.7998259999541,
                            36.5610989998164
                        ],
                        [
                            -80.8377710001504,
                            36.5591380000809
                        ],
                        [
                            -80.9329380003272,
                            36.5629640001542
                        ],
                        [
                            -81.0038080002147,
                            36.5636559999024
                        ],
                        [
                            -81.1769329998232,
                            36.5718850003008
                        ],
                        [
                            -81.3074369995852,
                            36.5749609998328
                        ],
                        [
                            -81.3860970000721,
                            36.5749380003275
                        ],
                        [
                            -81.5203469997218,
                            36.5805210000958
                        ],
                        [
                            -81.6004550000051,
                            36.5869480003139
                        ],
                        [
                            -81.6774900002453,
                            36.5881370000227
                        ],
                        [
                            -81.6468999995569,
                            36.6119180002695
                        ],
                        [
                            -81.9226439998327,
                            36.6162130002575
                        ],
                        [
                            -81.9341440003346,
                            36.5942129999361
                        ],
                        [
                            -82.1737870000456,
                            36.5946070000493
                        ],
                        [
                            -82.2226150000775,
                            36.5960300003309
                        ],
                        [
                            -82.4665760001366,
                            36.5944800000806
                        ],
                        [
                            -82.487237999968,
                            36.5958209997755
                        ],
                        [
                            -82.6634909998711,
                            36.5936440000278
                        ],
                        [
                            -83.2489329996106,
                            36.5938269999794
                        ],
                        [
                            -83.2610990002844,
                            36.5938869999873
                        ],
                        [
                            -83.2762999995698,
                            36.5981870002347
                        ],
                        [
                            -83.5569220000604,
                            36.5973849998572
                        ],
                        [
                            -83.622000999689,
                            36.5979739999654
                        ],
                        [
                            -83.6753949997662,
                            36.6007839997287
                        ],
                        [
                            -83.6732039998051,
                            36.6046409999937
                        ],
                        [
                            -83.6635479998329,
                            36.6069279997378
                        ],
                        [
                            -83.6571089998438,
                            36.6130280000897
                        ],
                        [
                            -83.649313000295,
                            36.6167739997535
                        ],
                        [
                            -83.6483170004103,
                            36.622447000315
                        ],
                        [
                            -83.6451239998183,
                            36.6243059996724
                        ],
                        [
                            -83.6407269998275,
                            36.6247630000268
                        ],
                        [
                            -83.6347139998107,
                            36.6228899996564
                        ],
                        [
                            -83.624016999748,
                            36.6255089997027
                        ],
                        [
                            -83.6182609996492,
                            36.6285870000176
                        ],
                        [
                            -83.6138960003437,
                            36.634850000031
                        ],
                        [
                            -83.605900999705,
                            36.6379009998581
                        ],
                        [
                            -83.6004319996614,
                            36.637848999975
                        ],
                        [
                            -83.5872959999404,
                            36.6423010003337
                        ],
                        [
                            -83.5864490000186,
                            36.6438629996663
                        ],
                        [
                            -83.583319999899,
                            36.6411570003115
                        ],
                        [
                            -83.5776070003563,
                            36.6413900001684
                        ],
                        [
                            -83.574616000329,
                            36.6443359997081
                        ],
                        [
                            -83.5660509997778,
                            36.6471659999873
                        ],
                        [
                            -83.5624260002641,
                            36.6512490000208
                        ],
                        [
                            -83.5468169996514,
                            36.6541330002899
                        ],
                        [
                            -83.5370540004474,
                            36.658933999801
                        ],
                        [
                            -83.5323059999289,
                            36.6619650001159
                        ],
                        [
                            -83.5324039998382,
                            36.66468899976
                        ],
                        [
                            -83.53014599993,
                            36.6660490002506
                        ],
                        [
                            -83.5155840001539,
                            36.6677749999738
                        ],
                        [
                            -83.5124369995927,
                            36.6696009997225
                        ],
                        [
                            -83.5057499999065,
                            36.6689460001571
                        ],
                        [
                            -83.499218999856,
                            36.6704389998729
                        ],
                        [
                            -83.4928049999813,
                            36.6701869997227
                        ],
                        [
                            -83.482680000379,
                            36.6661869999121
                        ],
                        [
                            -83.4666110003571,
                            36.6647090001214
                        ],
                        [
                            -83.4478340001651,
                            36.6653160002607
                        ],
                        [
                            -83.4435450001293,
                            36.6671789997625
                        ],
                        [
                            -83.4363539997546,
                            36.6662209997177
                        ],
                        [
                            -83.4247129996857,
                            36.6673400003423
                        ],
                        [
                            -83.3964829999111,
                            36.6769049998739
                        ],
                        [
                            -83.3952390003292,
                            36.6777089999703
                        ],
                        [
                            -83.395870000341,
                            36.6794259999455
                        ],
                        [
                            -83.3868090003956,
                            36.6866140001635
                        ],
                        [
                            -83.3812380002448,
                            36.6873260002988
                        ],
                        [
                            -83.3752660002359,
                            36.6907279999629
                        ],
                        [
                            -83.3710919998279,
                            36.6906860001464
                        ],
                        [
                            -83.3649730004042,
                            36.694095999946
                        ],
                        [
                            -83.3587670000437,
                            36.6935059999575
                        ],
                        [
                            -83.3476349997878,
                            36.7001990000262
                        ],
                        [
                            -83.3097110004062,
                            36.7106389999637
                        ],
                        [
                            -83.2966309998634,
                            36.7136040000565
                        ],
                        [
                            -83.2882960003643,
                            36.7137100001817
                        ],
                        [
                            -83.2454799996259,
                            36.7259400001313
                        ],
                        [
                            -83.2237189999777,
                            36.7302580001568
                        ],
                        [
                            -83.1948389999248,
                            36.7394479998606
                        ],
                        [
                            -83.1748280001966,
                            36.7391750002227
                        ],
                        [
                            -83.1680240003346,
                            36.7406409996926
                        ],
                        [
                            -83.1671280000089,
                            36.7393300003261
                        ],
                        [
                            -83.1631240003785,
                            36.7392980002747
                        ],
                        [
                            -83.1524900000649,
                            36.7427040001757
                        ],
                        [
                            -83.1444109997606,
                            36.7416359996864
                        ],
                        [
                            -83.1351199996614,
                            36.7426290000017
                        ],
                        [
                            -83.1329920003478,
                            36.7460940000409
                        ],
                        [
                            -83.1344250000755,
                            36.749356999747
                        ],
                        [
                            -83.1279850002616,
                            36.7506450002523
                        ],
                        [
                            -83.1286380000146,
                            36.7596550000009
                        ],
                        [
                            -83.1255730003675,
                            36.7616230003531
                        ],
                        [
                            -83.1325449995607,
                            36.7643949998562
                        ],
                        [
                            -83.1295660001273,
                            36.7701859998756
                        ],
                        [
                            -83.1330800002113,
                            36.7716789996795
                        ],
                        [
                            -83.1289869999941,
                            36.7757839996747
                        ],
                        [
                            -83.1332309998241,
                            36.7845849998664
                        ],
                        [
                            -83.1231609995751,
                            36.7866929998055
                        ],
                        [
                            -83.1182570003194,
                            36.790415999977
                        ],
                        [
                            -83.1106490001932,
                            36.8004869998404
                        ],
                        [
                            -83.1043309996797,
                            36.8039970002431
                        ],
                        [
                            -83.1025640000411,
                            36.8112699999032
                        ],
                        [
                            -83.0984800000447,
                            36.8135169999043
                        ],
                        [
                            -83.0985079996338,
                            36.8237990003017
                        ],
                        [
                            -83.1019360004024,
                            36.8282380001134
                        ],
                        [
                            -83.0990240000178,
                            36.8318109998691
                        ],
                        [
                            -83.0909000004059,
                            36.8345940003362
                        ],
                        [
                            -83.0874590001168,
                            36.8382239999824
                        ],
                        [
                            -83.0751719999675,
                            36.8407280002749
                        ],
                        [
                            -83.0740670003028,
                            36.8457929999726
                        ],
                        [
                            -83.07584900001,
                            36.8506939997696
                        ],
                        [
                            -83.0728360002414,
                            36.8544569999339
                        ],
                        [
                            -83.0642430001012,
                            36.8515830003307
                        ],
                        [
                            -83.0607490001086,
                            36.8539479997925
                        ],
                        [
                            -83.0477999999854,
                            36.851679999882
                        ],
                        [
                            -83.0413769998899,
                            36.8549570002764
                        ],
                        [
                            -83.0357039996318,
                            36.8541170000755
                        ],
                        [
                            -83.0266069997014,
                            36.8555560002748
                        ],
                        [
                            -83.0220790001893,
                            36.8499349998164
                        ],
                        [
                            -83.0127710003717,
                            36.8469299998916
                        ],
                        [
                            -83.0065769997067,
                            36.8476430002694
                        ],
                        [
                            -82.9985869999891,
                            36.8565670000161
                        ],
                        [
                            -82.9889960003143,
                            36.8591560003077
                        ],
                        [
                            -82.9785770000859,
                            36.8598129997786
                        ],
                        [
                            -82.9707920004077,
                            36.8576410000394
                        ],
                        [
                            -82.9515759998245,
                            36.8661899997518
                        ],
                        [
                            -82.9376779996722,
                            36.8673189999801
                        ],
                        [
                            -82.9342959998327,
                            36.8702319996776
                        ],
                        [
                            -82.9283070001054,
                            36.8704120002197
                        ],
                        [
                            -82.9224630001431,
                            36.8738980001089
                        ],
                        [
                            -82.907822999651,
                            36.8747639998024
                        ],
                        [
                            -82.9073849999831,
                            36.8792980002648
                        ],
                        [
                            -82.8951510004355,
                            36.8822309998818
                        ],
                        [
                            -82.8844610000454,
                            36.888497999989
                        ],
                        [
                            -82.8786830002054,
                            36.8895320002454
                        ],
                        [
                            -82.877411999961,
                            36.8955099997254
                        ],
                        [
                            -82.8679779997467,
                            36.9000480002122
                        ],
                        [
                            -82.877379000349,
                            36.9084710000373
                        ],
                        [
                            -82.8697529997812,
                            36.9164960001317
                        ],
                        [
                            -82.8673450000851,
                            36.9172679997488
                        ],
                        [
                            -82.8671280003501,
                            36.9190900000134
                        ],
                        [
                            -82.8576649998235,
                            36.9286690001736
                        ],
                        [
                            -82.8623000003641,
                            36.9413380001492
                        ],
                        [
                            -82.8598819997239,
                            36.9491720000048
                        ],
                        [
                            -82.8556389997188,
                            36.9542149999438
                        ],
                        [
                            -82.858165000314,
                            36.9538529996714
                        ],
                        [
                            -82.8613570001828,
                            36.9573289996881
                        ],
                        [
                            -82.8659399999464,
                            36.9583360000374
                        ],
                        [
                            -82.8674379999715,
                            36.9641620001045
                        ],
                        [
                            -82.8701079996085,
                            36.9649779999379
                        ],
                        [
                            -82.8713069998828,
                            36.9682599999395
                        ],
                        [
                            -82.868780000361,
                            36.9695870003293
                        ],
                        [
                            -82.8696070001914,
                            36.9733430001774
                        ],
                        [
                            -82.8674670002839,
                            36.978048999722
                        ],
                        [
                            -82.862585999696,
                            36.980268000111
                        ],
                        [
                            -82.8604140000015,
                            36.9783769998893
                        ],
                        [
                            -82.8569810001083,
                            36.9788480001137
                        ],
                        [
                            -82.8565020004325,
                            36.9820419996485
                        ],
                        [
                            -82.8535769996292,
                            36.9852979998168
                        ],
                        [
                            -82.8498020003275,
                            36.9835829996974
                        ],
                        [
                            -82.8452420001301,
                            36.9837650003576
                        ],
                        [
                            -82.8410040001479,
                            36.9870980001127
                        ],
                        [
                            -82.8372749999787,
                            36.9872800002567
                        ],
                        [
                            -82.8350599997281,
                            36.9914769997212
                        ],
                        [
                            -82.8301790000386,
                            36.9943569999373
                        ],
                        [
                            -82.8289889999851,
                            36.997869000039
                        ],
                        [
                            -82.8305859997444,
                            37.0012190000554
                        ],
                        [
                            -82.8287200003716,
                            37.005798000034
                        ],
                        [
                            -82.8251379996172,
                            37.0064189996994
                        ],
                        [
                            -82.8225249998817,
                            37.0041060003339
                        ],
                        [
                            -82.8199659996746,
                            37.006172000171
                        ],
                        [
                            -82.817173000014,
                            37.0060130003549
                        ],
                        [
                            -82.8168619996693,
                            37.0074269997295
                        ],
                        [
                            -82.8133419997375,
                            37.0052670003098
                        ],
                        [
                            -82.8108269999113,
                            37.0070340000308
                        ],
                        [
                            -82.8037610001085,
                            37.0072339997663
                        ],
                        [
                            -82.7978850003591,
                            37.0095620002991
                        ],
                        [
                            -82.7936649999203,
                            37.0054709997551
                        ],
                        [
                            -82.7884980000005,
                            37.0086720003047
                        ],
                        [
                            -82.7828630002755,
                            37.0078210002554
                        ],
                        [
                            -82.7771649999031,
                            37.0160229999625
                        ],
                        [
                            -82.7718530002184,
                            37.0157070000986
                        ],
                        [
                            -82.7664090002891,
                            37.0230840001651
                        ],
                        [
                            -82.7594790000304,
                            37.0272550002702
                        ],
                        [
                            -82.7525809995587,
                            37.0261769997632
                        ],
                        [
                            -82.7503519999627,
                            37.0232009997509
                        ],
                        [
                            -82.7478620002509,
                            37.0244249998967
                        ],
                        [
                            -82.7427589999052,
                            37.0427959999909
                        ],
                        [
                            -82.7335060003392,
                            37.0445210002434
                        ],
                        [
                            -82.7258239996948,
                            37.042252000257
                        ],
                        [
                            -82.7224329996345,
                            37.0449490001058
                        ],
                        [
                            -82.722200000006,
                            37.0579359998923
                        ],
                        [
                            -82.7228290003679,
                            37.0619290001397
                        ],
                        [
                            -82.7256239996783,
                            37.0652549997328
                        ],
                        [
                            -82.7267369997389,
                            37.0730390003055
                        ],
                        [
                            -82.7235959999238,
                            37.0755760002083
                        ],
                        [
                            -82.7180780003747,
                            37.0759179997136
                        ],
                        [
                            -82.7166549997944,
                            37.077406000226
                        ],
                        [
                            -82.7228440004365,
                            37.0848009999723
                        ],
                        [
                            -82.7227620004208,
                            37.0898170000204
                        ],
                        [
                            -82.725016999956,
                            37.0918219999428
                        ],
                        [
                            -82.7220560000659,
                            37.0948049999808
                        ],
                        [
                            -82.7216789995991,
                            37.1010150002991
                        ],
                        [
                            -82.7234329997172,
                            37.1033310000821
                        ],
                        [
                            -82.7220259999287,
                            37.1055699996499
                        ],
                        [
                            -82.7266880002334,
                            37.1147939998582
                        ],
                        [
                            -82.7214130003586,
                            37.1208299999126
                        ],
                        [
                            -82.7151490002717,
                            37.1218479999294
                        ],
                        [
                            -82.704672000317,
                            37.1279140000262
                        ],
                        [
                            -82.6878569997573,
                            37.1336590000187
                        ],
                        [
                            -82.6828920004021,
                            37.1369509996969
                        ],
                        [
                            -82.6766950002624,
                            37.1349170000098
                        ],
                        [
                            -82.6676600002563,
                            37.1418940003189
                        ],
                        [
                            -82.656722999994,
                            37.1447200001694
                        ],
                        [
                            -82.6533040003446,
                            37.151187999782
                        ],
                        [
                            -82.6502379999742,
                            37.1519430002781
                        ],
                        [
                            -82.6471890002206,
                            37.1501600001849
                        ],
                        [
                            -82.6427830000089,
                            37.1530030000077
                        ],
                        [
                            -82.6333809995817,
                            37.1544180000244
                        ],
                        [
                            -82.6236090001569,
                            37.1644380002129
                        ],
                        [
                            -82.6065130002881,
                            37.1739889999255
                        ],
                        [
                            -82.5974620003884,
                            37.1770450001188
                        ],
                        [
                            -82.5930190003668,
                            37.1804640003325
                        ],
                        [
                            -82.5922560001106,
                            37.1830390001431
                        ],
                        [
                            -82.5746940000864,
                            37.1905400000696
                        ],
                        [
                            -82.5505890003161,
                            37.2044829996895
                        ],
                        [
                            -82.548971999567,
                            37.2026980000848
                        ],
                        [
                            -82.5458740003079,
                            37.2030269999024
                        ],
                        [
                            -82.5414279999133,
                            37.2065050002047
                        ],
                        [
                            -82.5329400002533,
                            37.2065559998421
                        ],
                        [
                            -82.5307919999499,
                            37.2082859998003
                        ],
                        [
                            -82.5320170001635,
                            37.2121099997278
                        ],
                        [
                            -82.530017999823,
                            37.2133209996503
                        ],
                        [
                            -82.5246559999096,
                            37.2149990000139
                        ],
                        [
                            -82.5207529996631,
                            37.2121099997278
                        ],
                        [
                            -82.5100720003922,
                            37.218700999652
                        ],
                        [
                            -82.508467000237,
                            37.2225739998404
                        ],
                        [
                            -82.4987469997924,
                            37.2269580001879
                        ],
                        [
                            -82.4963089999591,
                            37.2274940000384
                        ],
                        [
                            -82.4951649999364,
                            37.2253460000241
                        ],
                        [
                            -82.4916149998673,
                            37.2249140001481
                        ],
                        [
                            -82.4873240001817,
                            37.2276759999326
                        ],
                        [
                            -82.4864389997267,
                            37.2311050001735
                        ],
                        [
                            -82.4724729998023,
                            37.2356779997459
                        ],
                        [
                            -82.4650529999972,
                            37.2356859999353
                        ],
                        [
                            -82.4626579998215,
                            37.238481000025
                        ],
                        [
                            -82.4573610002054,
                            37.2382140001047
                        ],
                        [
                            -82.4490729996637,
                            37.2437470001815
                        ],
                        [
                            -82.4433380003796,
                            37.2433019996512
                        ],
                        [
                            -82.4397670003942,
                            37.2470620002339
                        ],
                        [
                            -82.4303309996317,
                            37.2468549996638
                        ],
                        [
                            -82.4119679997165,
                            37.2533819996991
                        ],
                        [
                            -82.3986229997582,
                            37.2553869999226
                        ],
                        [
                            -82.3856290003274,
                            37.2596450000738
                        ],
                        [
                            -82.376596999796,
                            37.2598570003044
                        ],
                        [
                            -82.3487570000088,
                            37.2679439999261
                        ],
                        [
                            -82.3488619995908,
                            37.2719199997563
                        ],
                        [
                            -82.3419849999338,
                            37.2741320002723
                        ],
                        [
                            -82.3436100001805,
                            37.2808520002579
                        ],
                        [
                            -82.3369269997939,
                            37.279579000064
                        ],
                        [
                            -82.3360310003666,
                            37.2815389998361
                        ],
                        [
                            -82.3245710000476,
                            37.2830290001783
                        ],
                        [
                            -82.3191849998447,
                            37.2874779998516
                        ],
                        [
                            -82.3160080000446,
                            37.2948299997601
                        ],
                        [
                            -81.9680120002072,
                            37.5380349999475
                        ],
                        [
                            -81.9689880000004,
                            37.5345409999296
                        ],
                        [
                            -81.9671729997829,
                            37.5329369997597
                        ],
                        [
                            -81.957286999657,
                            37.5353729999038
                        ],
                        [
                            -81.9575479997729,
                            37.5300279997284
                        ],
                        [
                            -81.9559669999072,
                            37.5283909998331
                        ],
                        [
                            -81.9529109995826,
                            37.5283140003438
                        ],
                        [
                            -81.9462540000336,
                            37.5319629997193
                        ],
                        [
                            -81.9439640003383,
                            37.5312819998222
                        ],
                        [
                            -81.9476130001415,
                            37.5259079996472
                        ],
                        [
                            -81.9435080002287,
                            37.52075999993
                        ],
                        [
                            -81.9454520003176,
                            37.5164029999488
                        ],
                        [
                            -81.9442589998911,
                            37.5132770003496
                        ],
                        [
                            -81.9377610003508,
                            37.5135800000778
                        ],
                        [
                            -81.9337400001036,
                            37.5194910002012
                        ],
                        [
                            -81.9262430003055,
                            37.5135739996789
                        ],
                        [
                            -81.9276810000561,
                            37.5121129996969
                        ],
                        [
                            -81.9426400003905,
                            37.5088439997131
                        ],
                        [
                            -81.944187999746,
                            37.5069760001888
                        ],
                        [
                            -81.9439119995614,
                            37.5029289999707
                        ],
                        [
                            -81.9531469995841,
                            37.5013139999822
                        ],
                        [
                            -81.9543640003001,
                            37.4960840002322
                        ],
                        [
                            -81.9526810003271,
                            37.4922739998152
                        ],
                        [
                            -81.9538379998703,
                            37.4915020000143
                        ],
                        [
                            -81.9649860000198,
                            37.4934880001634
                        ],
                        [
                            -81.9671290003003,
                            37.4907430002837
                        ],
                        [
                            -81.970730000423,
                            37.4899039999712
                        ],
                        [
                            -81.9706590002779,
                            37.4884679998153
                        ],
                        [
                            -81.9757519996795,
                            37.4876890001964
                        ],
                        [
                            -81.9775929998362,
                            37.4846029997109
                        ],
                        [
                            -81.9857030001028,
                            37.4856810001273
                        ],
                        [
                            -81.9908959999618,
                            37.4844309997317
                        ],
                        [
                            -81.9929160002187,
                            37.4829690003166
                        ],
                        [
                            -81.992973999945,
                            37.4797969998072
                        ],
                        [
                            -81.9964989998997,
                            37.4769240001871
                        ],
                        [
                            -81.9960749995772,
                            37.4715260000394
                        ],
                        [
                            -81.9929720002952,
                            37.4684500003555
                        ],
                        [
                            -81.9922700001383,
                            37.4609160000437
                        ],
                        [
                            -81.98762899975,
                            37.4586610002635
                        ],
                        [
                            -81.9870060001342,
                            37.4548780003523
                        ],
                        [
                            -81.976176000002,
                            37.4571859998218
                        ],
                        [
                            -81.9687949996566,
                            37.4514960000834
                        ],
                        [
                            -81.9703830000934,
                            37.4476880002293
                        ],
                        [
                            -81.9663180003635,
                            37.4467209997716
                        ],
                        [
                            -81.9575789997351,
                            37.4491230003365
                        ],
                        [
                            -81.9540560003285,
                            37.4463209998714
                        ],
                        [
                            -81.9491239996871,
                            37.4455750000745
                        ],
                        [
                            -81.9457650003121,
                            37.4402140002805
                        ],
                        [
                            -81.9388429995509,
                            37.440463000304
                        ],
                        [
                            -81.9356210004433,
                            37.4383970002599
                        ],
                        [
                            -81.9371739998217,
                            37.4339949997086
                        ],
                        [
                            -81.9356470003826,
                            37.4319989999673
                        ],
                        [
                            -81.9378380003437,
                            37.4321109998212
                        ],
                        [
                            -81.9405530001864,
                            37.4290579997345
                        ],
                        [
                            -81.9382900002553,
                            37.4240470000617
                        ],
                        [
                            -81.9389120000462,
                            37.4220020002937
                        ],
                        [
                            -81.9378800001765,
                            37.4200860002899
                        ],
                        [
                            -81.9349729998148,
                            37.4200170002857
                        ],
                        [
                            -81.9324680000343,
                            37.4152169997977
                        ],
                        [
                            -81.9229560000022,
                            37.4113890003303
                        ],
                        [
                            -81.9245519999366,
                            37.4099860003547
                        ],
                        [
                            -81.9242819995998,
                            37.4073159999938
                        ],
                        [
                            -81.9300419998965,
                            37.4052909996883
                        ],
                        [
                            -81.9307859998862,
                            37.4016560003169
                        ],
                        [
                            -81.9277499996531,
                            37.3987949999312
                        ],
                        [
                            -81.9300500002925,
                            37.39712399997
                        ],
                        [
                            -81.9287780002232,
                            37.3938450002123
                        ],
                        [
                            -81.9338830002187,
                            37.3906519999512
                        ],
                        [
                            -81.9318110000833,
                            37.3854789997212
                        ],
                        [
                            -81.9357100001318,
                            37.3831469997992
                        ],
                        [
                            -81.9367439996513,
                            37.3807300001581
                        ],
                        [
                            -81.9358719996151,
                            37.3785539999854
                        ],
                        [
                            -81.9311430002616,
                            37.3757569996797
                        ],
                        [
                            -81.933973999557,
                            37.3731629999834
                        ],
                        [
                            -81.9333819999033,
                            37.369341000343
                        ],
                        [
                            -81.9260449999388,
                            37.3655130000355
                        ],
                        [
                            -81.9284619998557,
                            37.3604610000109
                        ],
                        [
                            -81.925301999774,
                            37.3569690001349
                        ],
                        [
                            -81.9238160003428,
                            37.3582719998383
                        ],
                        [
                            -81.9217619997507,
                            37.3570729999112
                        ],
                        [
                            -81.9176500001652,
                            37.3491529998811
                        ],
                        [
                            -81.9102200003143,
                            37.3489550002074
                        ],
                        [
                            -81.9096479998538,
                            37.3454549999896
                        ],
                        [
                            -81.9079110003524,
                            37.3452240001305
                        ],
                        [
                            -81.9086629998397,
                            37.3439249996719
                        ],
                        [
                            -81.9071609996166,
                            37.3426360002686
                        ],
                        [
                            -81.903895000128,
                            37.3432259997695
                        ],
                        [
                            -81.8996010000694,
                            37.3416070002343
                        ],
                        [
                            -81.8966319997834,
                            37.3353830000377
                        ],
                        [
                            -81.8977430001942,
                            37.333214000307
                        ],
                        [
                            -81.8938840003287,
                            37.3300159998285
                        ],
                        [
                            -81.890347999605,
                            37.3318780001177
                        ],
                        [
                            -81.8821760003126,
                            37.3301529997216
                        ],
                        [
                            -81.8793540003397,
                            37.3324930002647
                        ],
                        [
                            -81.877320999664,
                            37.3314189997443
                        ],
                        [
                            -81.8783450000361,
                            37.3288299998632
                        ],
                        [
                            -81.8740130003426,
                            37.327626000229
                        ],
                        [
                            -81.87245199967,
                            37.3223620000841
                        ],
                        [
                            -81.867194000412,
                            37.320717000007
                        ],
                        [
                            -81.8604890002841,
                            37.3159420001305
                        ],
                        [
                            -81.8599279996942,
                            37.3139650002915
                        ],
                        [
                            -81.8656829999681,
                            37.3094840000863
                        ],
                        [
                            -81.8596239999206,
                            37.3047649997662
                        ],
                        [
                            -81.8544600003739,
                            37.3065700001148
                        ],
                        [
                            -81.8548410001404,
                            37.2980310002436
                        ],
                        [
                            -81.852844000348,
                            37.29810900002
                        ],
                        [
                            -81.8522819999332,
                            37.2965749998132
                        ],
                        [
                            -81.8540650003637,
                            37.2900370002583
                        ],
                        [
                            -81.8534410000246,
                            37.2876260000905
                        ],
                        [
                            -81.8499649995754,
                            37.2874289996649
                        ],
                        [
                            -81.8499489996819,
                            37.2852270001134
                        ],
                        [
                            -81.834432000029,
                            37.2854169998597
                        ],
                        [
                            -81.8334050001821,
                            37.2845370001599
                        ],
                        [
                            -81.8344699996638,
                            37.2817620000428
                        ],
                        [
                            -81.8285060000509,
                            37.2814700003178
                        ],
                        [
                            -81.8257510000252,
                            37.2790930003004
                        ],
                        [
                            -81.8167019997754,
                            37.2798650001377
                        ],
                        [
                            -81.807231999576,
                            37.2831750000169
                        ],
                        [
                            -81.8047250001457,
                            37.2859259999235
                        ],
                        [
                            -81.8006760003094,
                            37.2856759998548
                        ],
                        [
                            -81.793595000438,
                            37.2848380000164
                        ],
                        [
                            -81.7931219997116,
                            37.2811679999001
                        ],
                        [
                            -81.7892939998083,
                            37.2844160000754
                        ],
                        [
                            -81.7824480001134,
                            37.2823199997832
                        ],
                        [
                            -81.7793619996516,
                            37.2799819997897
                        ],
                        [
                            -81.7793519996058,
                            37.2773900003491
                        ],
                        [
                            -81.7732440000529,
                            37.2739119997771
                        ],
                        [
                            -81.7692169999579,
                            37.2732500000303
                        ],
                        [
                            -81.7571370003961,
                            37.2761470001911
                        ],
                        [
                            -81.7575759998889,
                            37.2685520003515
                        ],
                        [
                            -81.7501069996799,
                            37.2641860002056
                        ],
                        [
                            -81.7460899996307,
                            37.2637020001041
                        ],
                        [
                            -81.7456600003587,
                            37.2580939999408
                        ],
                        [
                            -81.7408969997716,
                            37.2543169997049
                        ],
                        [
                            -81.7443759996955,
                            37.243330000339
                        ],
                        [
                            -81.7410859999175,
                            37.2416479997707
                        ],
                        [
                            -81.740124000368,
                            37.2377520000495
                        ],
                        [
                            -81.7230610001111,
                            37.2404929997424
                        ],
                        [
                            -81.7162480000282,
                            37.2343209998515
                        ],
                        [
                            -81.7157299999944,
                            37.2287709998965
                        ],
                        [
                            -81.6993339997343,
                            37.2199210001357
                        ],
                        [
                            -81.6987560003243,
                            37.2180059999862
                        ],
                        [
                            -81.6960430001313,
                            37.2169889997928
                        ],
                        [
                            -81.6951130003688,
                            37.2135700001017
                        ],
                        [
                            -81.6870259996685,
                            37.2133490000265
                        ],
                        [
                            -81.686023999936,
                            37.2115589996864
                        ],
                        [
                            -81.6836179998897,
                            37.2116249997983
                        ],
                        [
                            -81.6832680000854,
                            37.2056490001931
                        ],
                        [
                            -81.6782209998162,
                            37.2015400000175
                        ],
                        [
                            -81.5606310000172,
                            37.2066630001617
                        ],
                        [
                            -81.5580540002667,
                            37.2085650001116
                        ],
                        [
                            -81.5572199998654,
                            37.2073249997361
                        ],
                        [
                            -81.5547050000391,
                            37.207989999863
                        ],
                        [
                            -81.5471470001417,
                            37.2159850001456
                        ],
                        [
                            -81.5455660002759,
                            37.2201609998079
                        ],
                        [
                            -81.5412370000571,
                            37.2200170002392
                        ],
                        [
                            -81.5242619996638,
                            37.2278879999865
                        ],
                        [
                            -81.5206759996098,
                            37.2269800000325
                        ],
                        [
                            -81.5076360001483,
                            37.2337120000495
                        ],
                        [
                            -81.5065199997146,
                            37.2452460000094
                        ],
                        [
                            -81.5031310002024,
                            37.2530910003449
                        ],
                        [
                            -81.4990959997115,
                            37.2579519997057
                        ],
                        [
                            -81.4977750001367,
                            37.2578989999722
                        ],
                        [
                            -81.4980449995751,
                            37.2546590000464
                        ],
                        [
                            -81.4922869998266,
                            37.2509599999337
                        ],
                        [
                            -81.4801439996173,
                            37.2511210000772
                        ],
                        [
                            -81.4761399999868,
                            37.2556349998925
                        ],
                        [
                            -81.4621070001153,
                            37.2598990003265
                        ],
                        [
                            -81.4611189997281,
                            37.2625690001332
                        ],
                        [
                            -81.4588730004138,
                            37.2628720002964
                        ],
                        [
                            -81.4605849998008,
                            37.2655270002043
                        ],
                        [
                            -81.4490679995804,
                            37.2695829999591
                        ],
                        [
                            -81.4476549999441,
                            37.2722449996813
                        ],
                        [
                            -81.4395070000428,
                            37.2709089996857
                        ],
                        [
                            -81.4355960002987,
                            37.274164999755
                        ],
                        [
                            -81.4279460003398,
                            37.271015000106
                        ],
                        [
                            -81.4160290000863,
                            37.2736259999593
                        ],
                        [
                            -81.4159539997432,
                            37.2789359998489
                        ],
                        [
                            -81.4115929997374,
                            37.2803299998971
                        ],
                        [
                            -81.4060929997317,
                            37.2932719997769
                        ],
                        [
                            -81.4022999999884,
                            37.2958099996499
                        ],
                        [
                            -81.405060000037,
                            37.2987939998134
                        ],
                        [
                            -81.3968169997011,
                            37.3044979997229
                        ],
                        [
                            -81.3986779999493,
                            37.3102480002062
                        ],
                        [
                            -81.3952960001098,
                            37.3126070002361
                        ],
                        [
                            -81.3942869998063,
                            37.3164109999068
                        ],
                        [
                            -81.3908489998902,
                            37.3169010001514
                        ],
                        [
                            -81.3863120001573,
                            37.3206379999605
                        ],
                        [
                            -81.384851999767,
                            37.3185960002578
                        ],
                        [
                            -81.3794129998607,
                            37.317566000069
                        ],
                        [
                            -81.3727489997407,
                            37.3202479999521
                        ],
                        [
                            -81.3714159995721,
                            37.3242170000646
                        ],
                        [
                            -81.3675359997902,
                            37.3268999998205
                        ],
                        [
                            -81.3692380000298,
                            37.3324019998716
                        ],
                        [
                            -81.3674959996072,
                            37.3328749997156
                        ],
                        [
                            -81.3644920000595,
                            37.3379959998819
                        ],
                        [
                            -81.3621560003334,
                            37.3376869998736
                        ],
                        [
                            -81.3201050003994,
                            37.2993230001489
                        ],
                        [
                            -81.225103999904,
                            37.2348739999807
                        ],
                        [
                            -81.2047740003336,
                            37.2430130001944
                        ],
                        [
                            -81.1670290001539,
                            37.2628809999429
                        ],
                        [
                            -81.1041470003341,
                            37.2806049996635
                        ],
                        [
                            -81.0361329998563,
                            37.2902520001849
                        ],
                        [
                            -80.9795889997433,
                            37.3022790000874
                        ],
                        [
                            -80.9791059998695,
                            37.300581000282
                        ],
                        [
                            -80.9821730000648,
                            37.2960229999977
                        ],
                        [
                            -80.981321999945,
                            37.2934650000263
                        ],
                        [
                            -80.973888999721,
                            37.2914440003064
                        ],
                        [
                            -80.9665559999545,
                            37.2921580002612
                        ],
                        [
                            -80.9441729996169,
                            37.2965749998132
                        ],
                        [
                            -80.9381350003842,
                            37.3002779996896
                        ],
                        [
                            -80.9192589995596,
                            37.3061629999631
                        ],
                        [
                            -80.899159999968,
                            37.3156779996898
                        ],
                        [
                            -80.8689860001045,
                            37.3385729999057
                        ],
                        [
                            -80.8494509996791,
                            37.346908999665
                        ],
                        [
                            -80.8832469995816,
                            37.3839329998871
                        ],
                        [
                            -80.863003000225,
                            37.411514000049
                        ],
                        [
                            -80.8655060003557,
                            37.4168989997271
                        ],
                        [
                            -80.8652739996538,
                            37.42011299984
                        ],
                        [
                            -80.8594570003539,
                            37.4294909996492
                        ],
                        [
                            -80.8573590002792,
                            37.4272060003256
                        ],
                        [
                            -80.8522259997963,
                            37.4266570001156
                        ],
                        [
                            -80.8463249999326,
                            37.4233950000236
                        ],
                        [
                            -80.8410249999434,
                            37.4261229998148
                        ],
                        [
                            -80.8372349996749,
                            37.4254059999774
                        ],
                        [
                            -80.8085870003239,
                            37.4065369997555
                        ],
                        [
                            -80.8061489995922,
                            37.4040260000909
                        ],
                        [
                            -80.8070440000929,
                            37.4016459996592
                        ],
                        [
                            -80.8057429997114,
                            37.3979540001925
                        ],
                        [
                            -80.8024989999641,
                            37.398261000075
                        ],
                        [
                            -80.7994479996624,
                            37.3957440001137
                        ],
                        [
                            -80.7841879999274,
                            37.3945870000201
                        ],
                        [
                            -80.7831309999433,
                            37.3902469998527
                        ],
                        [
                            -80.7770069995985,
                            37.384840000238
                        ],
                        [
                            -80.7700820002609,
                            37.3723630002753
                        ],
                        [
                            -80.7598859996153,
                            37.3748820002392
                        ],
                        [
                            -80.7487219995723,
                            37.380050000239
                        ],
                        [
                            -80.743390999621,
                            37.3798509999163
                        ],
                        [
                            -80.7278039996479,
                            37.3863790000411
                        ],
                        [
                            -80.7257020002736,
                            37.3855220002527
                        ],
                        [
                            -80.7206840003167,
                            37.3893189996945
                        ],
                        [
                            -80.7162980001965,
                            37.3886940001656
                        ],
                        [
                            -80.7154789998638,
                            37.3907070002012
                        ],
                        [
                            -80.7052029997505,
                            37.3946179996935
                        ],
                        [
                            -80.6974740001488,
                            37.3996009998267
                        ],
                        [
                            -80.6851069996336,
                            37.4040949998412
                        ],
                        [
                            -80.6524260003398,
                            37.4206339999272
                        ],
                        [
                            -80.6458929997411,
                            37.4221470002627
                        ],
                        [
                            -80.6369470003217,
                            37.4274709996451
                        ],
                        [
                            -80.6373790001418,
                            37.4293720000029
                        ],
                        [
                            -80.6343899997644,
                            37.4312270003384
                        ],
                        [
                            -80.6226640002049,
                            37.4333070002956
                        ],
                        [
                            -80.6221169998587,
                            37.4359689996541
                        ],
                        [
                            -80.6168019998009,
                            37.4394429997826
                        ],
                        [
                            -80.6002039998745,
                            37.4461729999582
                        ],
                        [
                            -80.5858560003587,
                            37.4566540000839
                        ],
                        [
                            -80.5828839996997,
                            37.4566579998334
                        ],
                        [
                            -80.5814629996675,
                            37.4591380001064
                        ],
                        [
                            -80.5614419998936,
                            37.4697750001928
                        ],
                        [
                            -80.5520360001667,
                            37.4735629998162
                        ],
                        [
                            -80.5368890004097,
                            37.4740080002897
                        ],
                        [
                            -80.5323719998699,
                            37.4771240001922
                        ],
                        [
                            -80.5234809998038,
                            37.4769049999306
                        ],
                        [
                            -80.5186419999471,
                            37.4793040000376
                        ],
                        [
                            -80.5151389997337,
                            37.4785659998997
                        ],
                        [
                            -80.5113910001963,
                            37.4816720001406
                        ],
                        [
                            -80.4929810004254,
                            37.4577489999826
                        ],
                        [
                            -80.4972789997837,
                            37.4447799997346
                        ],
                        [
                            -80.494860000217,
                            37.4350720001802
                        ],
                        [
                            -80.4756009999761,
                            37.42294899994
                        ],
                        [
                            -80.4648160000499,
                            37.4261449999211
                        ],
                        [
                            -80.4573040001831,
                            37.4322699999503
                        ],
                        [
                            -80.4430090003708,
                            37.4381309996698
                        ],
                        [
                            -80.4256289999215,
                            37.449885000147
                        ],
                        [
                            -80.3719510001623,
                            37.4740689999335
                        ],
                        [
                            -80.3631710004243,
                            37.4800009998912
                        ],
                        [
                            -80.3668369999459,
                            37.4848789999275
                        ],
                        [
                            -80.3437799999389,
                            37.4921510002677
                        ],
                        [
                            -80.3270999999967,
                            37.4953769997863
                        ],
                        [
                            -80.299789000114,
                            37.5082709996823
                        ],
                        [
                            -80.2823910001214,
                            37.5335189998592
                        ],
                        [
                            -80.2916670001519,
                            37.536516000001
                        ],
                        [
                            -80.3093470001769,
                            37.5273800000331
                        ],
                        [
                            -80.3303680000334,
                            37.5362670001076
                        ],
                        [
                            -80.327490999809,
                            37.5401400003028
                        ],
                        [
                            -80.3144699997157,
                            37.5440550000778
                        ],
                        [
                            -80.3122009999368,
                            37.546219999826
                        ],
                        [
                            -80.3285040003105,
                            37.5643150000761
                        ],
                        [
                            -80.2589190000127,
                            37.595499000333
                        ],
                        [
                            -80.2402720004153,
                            37.6069609997695
                        ],
                        [
                            -80.2260169998876,
                            37.6200589996475
                        ],
                        [
                            -80.2209839998621,
                            37.6277669996677
                        ],
                        [
                            -80.2407910002741,
                            37.6383240002475
                        ],
                        [
                            -80.267503999757,
                            37.6461559998897
                        ],
                        [
                            -80.2793719996067,
                            37.6570770002218
                        ],
                        [
                            -80.2959160000048,
                            37.6928379996595
                        ],
                        [
                            -80.2871069999545,
                            37.6964039997276
                        ],
                        [
                            -80.2765770002963,
                            37.7055490002282
                        ],
                        [
                            -80.272340000139,
                            37.7112790000245
                        ],
                        [
                            -80.2681490000125,
                            37.7128829999323
                        ],
                        [
                            -80.2644059995996,
                            37.71878599967
                        ],
                        [
                            -80.2581430002359,
                            37.7206120001147
                        ],
                        [
                            -80.2530769997002,
                            37.7258989996957
                        ],
                        [
                            -80.252023999914,
                            37.7298249999932
                        ],
                        [
                            -80.2603130002807,
                            37.7335170001437
                        ],
                        [
                            -80.2627650003577,
                            37.7383360001712
                        ],
                        [
                            -80.2593149998478,
                            37.7449610001017
                        ],
                        [
                            -80.2602899998162,
                            37.7498910001399
                        ],
                        [
                            -80.2574109999419,
                            37.7560840001127
                        ],
                        [
                            -80.2508790000664,
                            37.7555159999112
                        ],
                        [
                            -80.2493459998812,
                            37.7575010000488
                        ],
                        [
                            -80.251655999668,
                            37.7632470002758
                        ],
                        [
                            -80.2483890003545,
                            37.76825000019
                        ],
                        [
                            -80.244563000101,
                            37.7670049998694
                        ],
                        [
                            -80.2414370003545,
                            37.7680580002122
                        ],
                        [
                            -80.2408630002441,
                            37.7724419998272
                        ],
                        [
                            -80.2358920001429,
                            37.7731299999064
                        ],
                        [
                            -80.2304580002594,
                            37.7783049996706
                        ],
                        [
                            -80.2270529999553,
                            37.7788599999314
                        ],
                        [
                            -80.2251630002931,
                            37.7769169997429
                        ],
                        [
                            -80.221826999586,
                            37.7782929996765
                        ],
                        [
                            -80.216605000313,
                            37.7759609998276
                        ],
                        [
                            -80.2158920002854,
                            37.7819890001877
                        ],
                        [
                            -80.219081999606,
                            37.7833679997503
                        ],
                        [
                            -80.223485000343,
                            37.7824440002854
                        ],
                        [
                            -80.2245370003042,
                            37.7845949997778
                        ],
                        [
                            -80.223040000104,
                            37.7867230000631
                        ],
                        [
                            -80.2294890001388,
                            37.7923309996619
                        ],
                        [
                            -80.2276899998149,
                            37.7978919996722
                        ],
                        [
                            -80.2186110003261,
                            37.8097830001124
                        ],
                        [
                            -80.216228999671,
                            37.8098200003487
                        ],
                        [
                            -80.2064820003606,
                            37.8159699996688
                        ],
                        [
                            -80.2028529997505,
                            37.8242399999964
                        ],
                        [
                            -80.1929659997997,
                            37.8347499999377
                        ],
                        [
                            -80.1793909996876,
                            37.8397510003487
                        ],
                        [
                            -80.1835550000499,
                            37.8468099998495
                        ],
                        [
                            -80.1827549999837,
                            37.8513649997211
                        ],
                        [
                            -80.1814109999444,
                            37.8529030001257
                        ],
                        [
                            -80.1767410001421,
                            37.8527569998689
                        ],
                        [
                            -80.1761960003441,
                            37.8552650003442
                        ],
                        [
                            -80.1716410001695,
                            37.8589660002538
                        ],
                        [
                            -80.1720329998066,
                            37.8621440002259
                        ],
                        [
                            -80.1689570002889,
                            37.8671159997399
                        ],
                        [
                            -80.165073000309,
                            37.8690249996606
                        ],
                        [
                            -80.1653729998846,
                            37.8708670000752
                        ],
                        [
                            -80.1622019999323,
                            37.8751220002742
                        ],
                        [
                            -80.1498479998358,
                            37.8833640003311
                        ],
                        [
                            -80.1489509996853,
                            37.8868920001638
                        ],
                        [
                            -80.1415219996592,
                            37.8824359999996
                        ],
                        [
                            -80.1379869996588,
                            37.886283999836
                        ],
                        [
                            -80.1319309999844,
                            37.8895000001392
                        ],
                        [
                            -80.1297679996124,
                            37.8941589997931
                        ],
                        [
                            -80.1236199998765,
                            37.8979430002187
                        ],
                        [
                            -80.1206129999557,
                            37.8967350002354
                        ],
                        [
                            -80.1177469996019,
                            37.8977199998196
                        ],
                        [
                            -80.1189669997926,
                            37.9036139997876
                        ],
                        [
                            -80.1114659997966,
                            37.9105269998286
                        ],
                        [
                            -80.1072050002481,
                            37.9124420000682
                        ],
                        [
                            -80.1029310002809,
                            37.9189109996812
                        ],
                        [
                            -80.096563000437,
                            37.9181119999304
                        ],
                        [
                            -80.0910580004084,
                            37.9269060000046
                        ],
                        [
                            -80.0761049999218,
                            37.9389460003137
                        ],
                        [
                            -80.0745140000103,
                            37.9422209997854
                        ],
                        [
                            -80.0560559996605,
                            37.9519589997872
                        ],
                        [
                            -80.0468719996914,
                            37.9600550002562
                        ],
                        [
                            -80.0444759996909,
                            37.9600799999703
                        ],
                        [
                            -80.0404229996567,
                            37.965619999923
                        ],
                        [
                            -80.0369079997477,
                            37.9659070001862
                        ],
                        [
                            -80.035979999635,
                            37.9682489998378
                        ],
                        [
                            -80.0221409999323,
                            37.9780209998505
                        ],
                        [
                            -80.0206310002116,
                            37.9807479998381
                        ],
                        [
                            -80.0131450002842,
                            37.9842530002109
                        ],
                        [
                            -80.0121929998821,
                            37.9886329998106
                        ],
                        [
                            -79.999382999676,
                            37.9949079998646
                        ],
                        [
                            -79.9988529999466,
                            37.9997109996945
                        ],
                        [
                            -79.9963959998467,
                            38.0006040002532
                        ],
                        [
                            -79.9949039996694,
                            38.0040769996868
                        ],
                        [
                            -79.9954879998255,
                            38.0071249999343
                        ],
                        [
                            -79.9901140002165,
                            38.0132459996594
                        ],
                        [
                            -79.986142000373,
                            38.0141820000621
                        ],
                        [
                            -79.9848419998164,
                            38.0166100000306
                        ],
                        [
                            -79.9856190003163,
                            38.0191600002523
                        ],
                        [
                            -79.9802900000148,
                            38.0275960003027
                        ],
                        [
                            -79.9752689996849,
                            38.0300749999098
                        ],
                        [
                            -79.9740850003775,
                            38.0343920000578
                        ],
                        [
                            -79.9716009996151,
                            38.0352320000177
                        ],
                        [
                            -79.9738950004067,
                            38.0400040001904
                        ],
                        [
                            -79.966079999693,
                            38.0508200003516
                        ],
                        [
                            -79.9601620001108,
                            38.0625469998273
                        ],
                        [
                            -79.959495999939,
                            38.0642970002326
                        ],
                        [
                            -79.9618049999008,
                            38.0658749999558
                        ],
                        [
                            -79.9616469997171,
                            38.0674150000862
                        ],
                        [
                            -79.9577579997144,
                            38.0720499998682
                        ],
                        [
                            -79.9543700000271,
                            38.0804010001894
                        ],
                        [
                            -79.9472890001556,
                            38.0850460000391
                        ],
                        [
                            -79.9426560001632,
                            38.091292999954
                        ],
                        [
                            -79.9351059997633,
                            38.0965470002623
                        ],
                        [
                            -79.9263970001704,
                            38.1065429997197
                        ],
                        [
                            -79.9288850002325,
                            38.1088769998425
                        ],
                        [
                            -79.9389460002606,
                            38.1116279998739
                        ],
                        [
                            -79.9414639995615,
                            38.117368999924
                        ],
                        [
                            -79.9406669998684,
                            38.120872999895
                        ],
                        [
                            -79.9432460001671,
                            38.1231350001913
                        ],
                        [
                            -79.9411740000316,
                            38.1252699996485
                        ],
                        [
                            -79.945258000028,
                            38.1322389999034
                        ],
                        [
                            -79.9427470003997,
                            38.1343329999943
                        ],
                        [
                            -79.9327490001209,
                            38.1361590001454
                        ],
                        [
                            -79.9290309998223,
                            38.1397710002169
                        ],
                        [
                            -79.9286829996678,
                            38.1449279998924
                        ],
                        [
                            -79.9261429997272,
                            38.1495569998639
                        ],
                        [
                            -79.9186619998227,
                            38.1547900000989
                        ],
                        [
                            -79.9195149995923,
                            38.1626489997856
                        ],
                        [
                            -79.916206000446,
                            38.1630940000975
                        ],
                        [
                            -79.9148640000565,
                            38.1677239998102
                        ],
                        [
                            -79.917925000404,
                            38.1683989998186
                        ],
                        [
                            -79.9188230003794,
                            38.1701169999429
                        ],
                        [
                            -79.9164540001431,
                            38.1768170001535
                        ],
                        [
                            -79.9210260000361,
                            38.1799539999473
                        ],
                        [
                            -79.9200750003572,
                            38.1825289999738
                        ],
                        [
                            -79.9161829999814,
                            38.184223999786
                        ],
                        [
                            -79.9144099995968,
                            38.1884179998051
                        ],
                        [
                            -79.9073870003499,
                            38.1879700001189
                        ],
                        [
                            -79.8973349996443,
                            38.1933809999956
                        ],
                        [
                            -79.8973949999188,
                            38.1958469996865
                        ],
                        [
                            -79.8929159999122,
                            38.1998680001536
                        ],
                        [
                            -79.8903220004432,
                            38.2058589998235
                        ],
                        [
                            -79.886413000349,
                            38.2079530000692
                        ],
                        [
                            -79.8829550003415,
                            38.2069389999606
                        ],
                        [
                            -79.8792499995634,
                            38.2109199997947
                        ],
                        [
                            -79.8745940000048,
                            38.2129759998397
                        ],
                        [
                            -79.8739339996807,
                            38.2153849997893
                        ],
                        [
                            -79.8608900000212,
                            38.2252429997708
                        ],
                        [
                            -79.8569719997062,
                            38.2310670001212
                        ],
                        [
                            -79.8503260000279,
                            38.233323999736
                        ],
                        [
                            -79.8452119998116,
                            38.2410740002753
                        ],
                        [
                            -79.8351130001486,
                            38.2418969999871
                        ],
                        [
                            -79.8308860000371,
                            38.2496890002076
                        ],
                        [
                            -79.8252830000992,
                            38.2504880000808
                        ],
                        [
                            -79.8196249999097,
                            38.2482320002767
                        ],
                        [
                            -79.8148639998708,
                            38.251568999788
                        ],
                        [
                            -79.8157090001428,
                            38.2550669999813
                        ],
                        [
                            -79.8119890001945,
                            38.2603979999672
                        ],
                        [
                            -79.8063360000279,
                            38.2591930001696
                        ],
                        [
                            -79.8012729998651,
                            38.2614729999614
                        ],
                        [
                            -79.7982979997314,
                            38.2659569996583
                        ],
                        [
                            -79.7959209999974,
                            38.2655519997996
                        ],
                        [
                            -79.788944999708,
                            38.2687030003147
                        ],
                        [
                            -79.7864669996917,
                            38.2746489997035
                        ],
                        [
                            -79.7900050000651,
                            38.277351000321
                        ],
                        [
                            -79.7897909998049,
                            38.2811669997195
                        ],
                        [
                            -79.7916550004262,
                            38.2846530000125
                        ],
                        [
                            -79.797695000207,
                            38.2920039997899
                        ],
                        [
                            -79.8027779995629,
                            38.2920729998962
                        ],
                        [
                            -79.8040260002411,
                            38.2986219997031
                        ],
                        [
                            -79.8099339997775,
                            38.3046809996522
                        ],
                        [
                            -79.8101539998855,
                            38.3067069997894
                        ],
                        [
                            -79.8023249998264,
                            38.316263999795
                        ],
                        [
                            -79.7996169996563,
                            38.317148999792
                        ],
                        [
                            -79.7965500003594,
                            38.323479999996
                        ],
                        [
                            -79.7900450002481,
                            38.3282389997633
                        ],
                        [
                            -79.7869839999006,
                            38.3284370003515
                        ],
                        [
                            -79.785806000441,
                            38.3310949996932
                        ],
                        [
                            -79.7792720000174,
                            38.3316090000231
                        ],
                        [
                            -79.7730899999462,
                            38.33552899988
                        ],
                        [
                            -79.7675940001385,
                            38.3443019998209
                        ],
                        [
                            -79.7703490001642,
                            38.3477999998584
                        ],
                        [
                            -79.7659799997625,
                            38.3512910003387
                        ],
                        [
                            -79.7672629997024,
                            38.3533950001574
                        ],
                        [
                            -79.764432000407,
                            38.3565140000571
                        ],
                        [
                            -79.7555599997091,
                            38.357371999867
                        ],
                        [
                            -79.742177000116,
                            38.3532480001676
                        ],
                        [
                            -79.7353089997815,
                            38.3552400000252
                        ],
                        [
                            -79.7320590001865,
                            38.3601680001796
                        ],
                        [
                            -79.7255969997328,
                            38.363828000122
                        ],
                        [
                            -79.7267900001594,
                            38.3708319999553
                        ],
                        [
                            -79.7316979996131,
                            38.3733759997465
                        ],
                        [
                            -79.7295119996749,
                            38.3811939997169
                        ],
                        [
                            -79.7263499999434,
                            38.3870700001856
                        ],
                        [
                            -79.7226529995612,
                            38.3895169996597
                        ],
                        [
                            -79.7173650001659,
                            38.4015619997494
                        ],
                        [
                            -79.7089650003695,
                            38.4095530000276
                        ],
                        [
                            -79.7091400002717,
                            38.4120639996825
                        ],
                        [
                            -79.706633999768,
                            38.4157299999682
                        ],
                        [
                            -79.6997339996465,
                            38.4218700000055
                        ],
                        [
                            -79.6952730000815,
                            38.4236850001202
                        ],
                        [
                            -79.6968310003811,
                            38.4267570000364
                        ],
                        [
                            -79.6896669997706,
                            38.4314620003121
                        ],
                        [
                            -79.691743000104,
                            38.4370640002719
                        ],
                        [
                            -79.689246999646,
                            38.4421339999782
                        ],
                        [
                            -79.691478999615,
                            38.4462829999072
                        ],
                        [
                            -79.6881050001714,
                            38.4504440003293
                        ],
                        [
                            -79.6883660002873,
                            38.4568699998443
                        ],
                        [
                            -79.6910860001529,
                            38.4637429999009
                        ],
                        [
                            -79.6955879997258,
                            38.4690579997813
                        ],
                        [
                            -79.6991200002515,
                            38.4702880001548
                        ],
                        [
                            -79.6994730004289,
                            38.4745270000055
                        ],
                        [
                            -79.6933969997647,
                            38.480182999998
                        ],
                        [
                            -79.6954620002274,
                            38.4814539997068
                        ],
                        [
                            -79.6975719999977,
                            38.4872230001172
                        ],
                        [
                            -79.6922729998334,
                            38.4964740000898
                        ],
                        [
                            -79.6883450003709,
                            38.4961830002036
                        ],
                        [
                            -79.6829740002366,
                            38.5013169997869
                        ],
                        [
                            -79.6803740000216,
                            38.5106170002777
                        ],
                        [
                            -79.6740739999497,
                            38.5104170001979
                        ],
                        [
                            -79.6704860002459,
                            38.5076589999134
                        ],
                        [
                            -79.6684740003849,
                            38.5123169999651
                        ],
                        [
                            -79.662174000313,
                            38.5152169999043
                        ],
                        [
                            -79.6667739997951,
                            38.5243170000076
                        ],
                        [
                            -79.6729740003079,
                            38.5287170000063
                        ],
                        [
                            -79.6668730004277,
                            38.5383170002991
                        ],
                        [
                            -79.6708750004083,
                            38.5410159996681
                        ],
                        [
                            -79.6691749998185,
                            38.5453160002915
                        ],
                        [
                            -79.6697749998682,
                            38.5503160000818
                        ],
                        [
                            -79.6672750001105,
                            38.5526160001351
                        ],
                        [
                            -79.6650749999286,
                            38.5609160002317
                        ],
                        [
                            -79.662575000171,
                            38.5605160002284
                        ],
                        [
                            -79.6592750003472,
                            38.5624159999102
                        ],
                        [
                            -79.6628749997466,
                            38.5704159997309
                        ],
                        [
                            -79.6579749997906,
                            38.5733160001837
                        ],
                        [
                            -79.655875000066,
                            38.5769160000959
                        ],
                        [
                            -79.655174999559,
                            38.5831160003157
                        ],
                        [
                            -79.649075000402,
                            38.5915150000941
                        ],
                        [
                            -79.5717709996499,
                            38.5631169996775
                        ],
                        [
                            -79.5554709996493,
                            38.5602169999186
                        ],
                        [
                            -79.5368700000827,
                            38.5509170001413
                        ],
                        [
                            -79.5319700001266,
                            38.542417000287
                        ],
                        [
                            -79.5214689998824,
                            38.5341179999742
                        ],
                        [
                            -79.4766379998083,
                            38.4572279999456
                        ],
                        [
                            -79.3122760002194,
                            38.4118759998518
                        ],
                        [
                            -79.3055510000001,
                            38.4152890003285
                        ],
                        [
                            -79.3000850003296,
                            38.4148910002612
                        ],
                        [
                            -79.2906949995979,
                            38.4210379998011
                        ],
                        [
                            -79.2832189997162,
                            38.4180289998552
                        ],
                        [
                            -79.2803130000777,
                            38.4203609998171
                        ],
                        [
                            -79.2796829998909,
                            38.4237330002441
                        ],
                        [
                            -79.2827540002841,
                            38.431693999793
                        ],
                        [
                            -79.2745179996209,
                            38.4363579997231
                        ],
                        [
                            -79.2673189997487,
                            38.4382740001675
                        ],
                        [
                            -79.2611030002407,
                            38.4480699998975
                        ],
                        [
                            -79.2569780002364,
                            38.4505670002008
                        ],
                        [
                            -79.2544819997784,
                            38.4559300000153
                        ],
                        [
                            -79.2474739997019,
                            38.4533059996595
                        ],
                        [
                            -79.2426680003554,
                            38.4541810001785
                        ],
                        [
                            -79.2421879999564,
                            38.4646230000215
                        ],
                        [
                            -79.2398809996443,
                            38.4700790002403
                        ],
                        [
                            -79.2256689996726,
                            38.4764709999623
                        ],
                        [
                            -79.2209610002355,
                            38.4805899999737
                        ],
                        [
                            -79.2214800000943,
                            38.4846930000703
                        ],
                        [
                            -79.2190670003753,
                            38.4874409998909
                        ],
                        [
                            -79.2105910004109,
                            38.4929129999117
                        ],
                        [
                            -79.2069590003261,
                            38.5035219996665
                        ],
                        [
                            -79.2109589997586,
                            38.5080220002829
                        ],
                        [
                            -79.205858999786,
                            38.5245209997008
                        ],
                        [
                            -79.2014590003204,
                            38.5278209999305
                        ],
                        [
                            -79.1988590001054,
                            38.5321210001853
                        ],
                        [
                            -79.1990590001219,
                            38.5350209998022
                        ],
                        [
                            -79.1934579998338,
                            38.5424210002723
                        ],
                        [
                            -79.1840579999547,
                            38.5515200001855
                        ],
                        [
                            -79.1816579997562,
                            38.5585200003302
                        ],
                        [
                            -79.176658000241,
                            38.5655200001653
                        ],
                        [
                            -79.1709540000208,
                            38.568116000301
                        ],
                        [
                            -79.1708579997613,
                            38.5741189996923
                        ],
                        [
                            -79.1678580004115,
                            38.5795190000583
                        ],
                        [
                            -79.1634420001541,
                            38.5828169999479
                        ],
                        [
                            -79.1582569997926,
                            38.5931189999389
                        ],
                        [
                            -79.1597580001908,
                            38.5951189997233
                        ],
                        [
                            -79.1592579997003,
                            38.6009189998484
                        ],
                        [
                            -79.1543569999192,
                            38.6065180002744
                        ],
                        [
                            -79.1555589996683,
                            38.6104840001576
                        ],
                        [
                            -79.1514570001286,
                            38.6202180003491
                        ],
                        [
                            -79.1459570001228,
                            38.6268180002356
                        ],
                        [
                            -79.1426570002991,
                            38.634416999698
                        ],
                        [
                            -79.1375570003264,
                            38.6380169999008
                        ],
                        [
                            -79.1297559998564,
                            38.6550180001523
                        ],
                        [
                            -79.1243560003081,
                            38.6558169997039
                        ],
                        [
                            -79.1232569995929,
                            38.6589169996649
                        ],
                        [
                            -79.1204530000617,
                            38.6602079996674
                        ],
                        [
                            -79.1159549997885,
                            38.6588089997731
                        ],
                        [
                            -79.1115560001478,
                            38.6597170002087
                        ],
                        [
                            -79.1063559997177,
                            38.6562169998156
                        ],
                        [
                            -79.0927549996663,
                            38.6598169999114
                        ],
                        [
                            -79.0922459998533,
                            38.6681139999783
                        ],
                        [
                            -79.0882549997433,
                            38.6729159998949
                        ],
                        [
                            -79.0844550003274,
                            38.6851160002902
                        ],
                        [
                            -79.0853549999526,
                            38.6886160002788
                        ],
                        [
                            -79.0907550003993,
                            38.6925150002623
                        ],
                        [
                            -79.0929549996829,
                            38.7017149997516
                        ],
                        [
                            -79.0865550000519,
                            38.7160149997162
                        ],
                        [
                            -79.0872549996606,
                            38.7201140002785
                        ],
                        [
                            -79.0854550004101,
                            38.7246139997526
                        ],
                        [
                            -79.0790549998808,
                            38.7372139998423
                        ],
                        [
                            -79.073855000349,
                            38.7421139998801
                        ],
                        [
                            -79.0723549997758,
                            38.7478130001576
                        ],
                        [
                            -79.0572540000495,
                            38.7607130002267
                        ],
                        [
                            -79.0556539999172,
                            38.7709129997616
                        ],
                        [
                            -79.051267999797,
                            38.7736019998573
                        ],
                        [
                            -79.0516539995863,
                            38.7780129998927
                        ],
                        [
                            -79.0556539999172,
                            38.7830130002171
                        ],
                        [
                            -79.0549540003084,
                            38.7857129999189
                        ],
                        [
                            -79.0465539996137,
                            38.7921129997993
                        ],
                        [
                            -79.0302539996131,
                            38.7910060000105
                        ],
                        [
                            -79.0272530004384,
                            38.7921129997993
                        ],
                        [
                            -79.0230410003954,
                            38.7986109999406
                        ],
                        [
                            -79.0240530001737,
                            38.809211999904
                        ],
                        [
                            -79.0198419999557,
                            38.8117109998529
                        ],
                        [
                            -79.0201530003003,
                            38.8161120002597
                        ],
                        [
                            -79.0185520003431,
                            38.8188119996938
                        ],
                        [
                            -79.0126520003043,
                            38.8201120001889
                        ],
                        [
                            -79.0065520002489,
                            38.8237120000703
                        ],
                        [
                            -79.0023519999016,
                            38.8365120002819
                        ],
                        [
                            -78.999014000443,
                            38.8400740001793
                        ],
                        [
                            -78.9997519996865,
                            38.8461620000749
                        ],
                        [
                            -78.9937610003094,
                            38.8500209998502
                        ],
                        [
                            -78.8692760004119,
                            38.7629909998472
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 37.5222512,
            "name": "Virginia",
            "intptlon": -78.6681938,
            "geo_point_2d": [
                37.5108555353,
                -78.666442414
            ],
            "geoid": "51",
            "mtfcc": "G4000",
            "region": 3
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -78.666442414,
                37.5108555353
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "254c039adedd2ad972f556537b2ac1e57a320fb5",
        "fields": {
            "arealand": "-545768328",
            "objectid": 32,
            "basename": "Hawaii",
            "stusab": "HI",
            "statens": "01779782",
            "centlon": -157.9936887,
            "state": "15",
            "gid": 10,
            "centlat": 21.0508739,
            "division": 9,
            "areawater": "-1107203494",
            "oid": "268578688",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                -155.432862000169,
                                20.1452070001443
                            ],
                            [
                                -155.418127000141,
                                20.1411219996913
                            ],
                            [
                                -155.397547000325,
                                20.1328329999583
                            ],
                            [
                                -155.384829000181,
                                20.1250029999264
                            ],
                            [
                                -155.378513000215,
                                20.1228649999356
                            ],
                            [
                                -155.369701999617,
                                20.115953000358
                            ],
                            [
                                -155.348434999713,
                                20.1074810001116
                            ],
                            [
                                -155.33524399974,
                                20.0991910000493
                            ],
                            [
                                -155.314417999877,
                                20.0912339996946
                            ],
                            [
                                -155.298301000175,
                                20.0815139996053
                            ],
                            [
                                -155.252398999873,
                                20.0634480003606
                            ],
                            [
                                -155.222370999599,
                                20.0434539997647
                            ],
                            [
                                -155.211768000146,
                                20.0384659998936
                            ],
                            [
                                -155.204006000034,
                                20.0325480002543
                            ],
                            [
                                -155.200578000164,
                                20.0279260004029
                            ],
                            [
                                -155.184503000294,
                                20.0195010000852
                            ],
                            [
                                -155.181250000326,
                                20.0154770003309
                            ],
                            [
                                -155.166803000178,
                                20.0064239999617
                            ],
                            [
                                -155.157486000139,
                                19.9976079999687
                            ],
                            [
                                -155.140329999996,
                                19.9894810003324
                            ],
                            [
                                -155.121683000399,
                                19.9710519998231
                            ],
                            [
                                -155.086048999814,
                                19.941742999928
                            ],
                            [
                                -155.07610900016,
                                19.9285979996298
                            ],
                            [
                                -155.070842999608,
                                19.918424999617
                            ],
                            [
                                -155.055551999911,
                                19.9066720002331
                            ],
                            [
                                -155.046810999632,
                                19.897703999879
                            ],
                            [
                                -155.031620000393,
                                19.8750000001716
                            ],
                            [
                                -155.034618000093,
                                19.8750000001716
                            ],
                            [
                                -155.031521999585,
                                19.8660889999331
                            ],
                            [
                                -155.03029899992,
                                19.8489950000537
                            ],
                            [
                                -155.030278000003,
                                19.8394629996277
                            ],
                            [
                                -155.034706999781,
                                19.8220670003768
                            ],
                            [
                                -155.033315000061,
                                19.7934209995914
                            ],
                            [
                                -155.025522999812,
                                19.790146000266
                            ],
                            [
                                -155.013696999795,
                                19.790929999712
                            ],
                            [
                                -154.994719999587,
                                19.7889239999635
                            ],
                            [
                                -154.977848999849,
                                19.7814039996486
                            ],
                            [
                                -154.963842999741,
                                19.7691890000865
                            ],
                            [
                                -154.952430000176,
                                19.7567840004006
                            ],
                            [
                                -154.948321999891,
                                19.7499989996605
                            ],
                            [
                                -154.931852999835,
                                19.7154680000903
                            ],
                            [
                                -154.928108999598,
                                19.7045339996223
                            ],
                            [
                                -154.926134000445,
                                19.6916639997298
                            ],
                            [
                                -154.928934999603,
                                19.6736049999997
                            ],
                            [
                                -154.920256999972,
                                19.6692490001395
                            ],
                            [
                                -154.911451000295,
                                19.6620360001542
                            ],
                            [
                                -154.903059999821,
                                19.6523050004087
                            ],
                            [
                                -154.894851999645,
                                19.636248999717
                            ],
                            [
                                -154.884291000025,
                                19.6249999997599
                            ],
                            [
                                -154.88678400011,
                                19.6249999997599
                            ],
                            [
                                -154.881428000044,
                                19.6199509997313
                            ],
                            [
                                -154.844960999782,
                                19.60213500005
                            ],
                            [
                                -154.839911999863,
                                19.6017050003622
                            ],
                            [
                                -154.822220999967,
                                19.5933099998953
                            ],
                            [
                                -154.811504999638,
                                19.5825940001617
                            ],
                            [
                                -154.797547999934,
                                19.5756679998447
                            ],
                            [
                                -154.778293999716,
                                19.5598949999769
                            ],
                            [
                                -154.764891999856,
                                19.5449649999818
                            ],
                            [
                                -154.7593330003,
                                19.5364620002962
                            ],
                            [
                                -154.755837999584,
                                19.520783999729
                            ],
                            [
                                -154.757873999734,
                                19.5000000003411
                            ],
                            [
                                -154.759905999687,
                                19.5000000003411
                            ],
                            [
                                -154.775751000301,
                                19.4571129997379
                            ],
                            [
                                -154.786131999996,
                                19.4403759997045
                            ],
                            [
                                -154.793570000243,
                                19.4325719997728
                            ],
                            [
                                -154.810311000285,
                                19.4160569996414
                            ],
                            [
                                -154.847913000349,
                                19.3851520001799
                            ],
                            [
                                -154.889027999775,
                                19.3603919999158
                            ],
                            [
                                -154.89590500033,
                                19.3523200003811
                            ],
                            [
                                -154.927296000011,
                                19.3269590002392
                            ],
                            [
                                -154.931718999941,
                                19.3198749996388
                            ],
                            [
                                -154.939662999628,
                                19.3128340000069
                            ],
                            [
                                -154.980079000168,
                                19.2941179999957
                            ],
                            [
                                -155.000000000383,
                                19.282037000101
                            ],
                            [
                                -155.000000000383,
                                19.2843799996261
                            ],
                            [
                                -155.032324000199,
                                19.2715039996904
                            ],
                            [
                                -155.049153000104,
                                19.2672509996693
                            ],
                            [
                                -155.067531000088,
                                19.2553230002224
                            ],
                            [
                                -155.079338999664,
                                19.2540880000837
                            ],
                            [
                                -155.083189000207,
                                19.2499999996458
                            ],
                            [
                                -155.079030999692,
                                19.2489659996148
                            ],
                            [
                                -155.105509999722,
                                19.2342700003333
                            ],
                            [
                                -155.140875999619,
                                19.2221170000199
                            ],
                            [
                                -155.161300999624,
                                19.2183750002257
                            ],
                            [
                                -155.175617000251,
                                19.2113859998334
                            ],
                            [
                                -155.196551999894,
                                19.2096800003634
                            ],
                            [
                                -155.230050999696,
                                19.2128510002903
                            ],
                            [
                                -155.25801500023,
                                19.2221710002601
                            ],
                            [
                                -155.268405000146,
                                19.2194949999019
                            ],
                            [
                                -155.273180000428,
                                19.2137519999575
                            ],
                            [
                                -155.297855999936,
                                19.1994530002449
                            ],
                            [
                                -155.301561999641,
                                19.192492999588
                            ],
                            [
                                -155.325376999881,
                                19.1712800000887
                            ],
                            [
                                -155.348155000229,
                                19.1587730001292
                            ],
                            [
                                -155.37295200011,
                                19.1522330001072
                            ],
                            [
                                -155.391433999851,
                                19.1443600001024
                            ],
                            [
                                -155.406515000384,
                                19.1245820002434
                            ],
                            [
                                -155.428933999808,
                                19.1072149999122
                            ],
                            [
                                -155.46609700038,
                                19.0953520003371
                            ],
                            [
                                -155.477606000205,
                                19.0842739998265
                            ],
                            [
                                -155.494877999801,
                                19.0715859999238
                            ],
                            [
                                -155.49858299968,
                                19.0639050002477
                            ],
                            [
                                -155.500002000063,
                                19.0643380000983
                            ],
                            [
                                -155.503306999909,
                                19.0464829997252
                            ],
                            [
                                -155.516553999958,
                                19.0114829998242
                            ],
                            [
                                -155.537365999578,
                                18.9890569998838
                            ],
                            [
                                -155.541786999858,
                                18.9812939996218
                            ],
                            [
                                -155.545351000171,
                                18.961850000389
                            ],
                            [
                                -155.55747000009,
                                18.9434420002308
                            ],
                            [
                                -155.565600000448,
                                18.9345169996634
                            ],
                            [
                                -155.583779000241,
                                18.9220940003918
                            ],
                            [
                                -155.591551000398,
                                18.9127360001254
                            ],
                            [
                                -155.6160600004,
                                18.8902490003311
                            ],
                            [
                                -155.624999999971,
                                18.8839080000582
                            ],
                            [
                                -155.624999999971,
                                18.8860960004124
                            ],
                            [
                                -155.630752999697,
                                18.8796869995841
                            ],
                            [
                                -155.639097000315,
                                18.8749999996289
                            ],
                            [
                                -155.662995000396,
                                18.8661589996226
                            ],
                            [
                                -155.678359999713,
                                18.8654599999244
                            ],
                            [
                                -155.694257000206,
                                18.8667859996125
                            ],
                            [
                                -155.70597299972,
                                18.8703010004156
                            ],
                            [
                                -155.72289500041,
                                18.8825229999192
                            ],
                            [
                                -155.730547999843,
                                18.8941139997228
                            ],
                            [
                                -155.739971000187,
                                18.9168909996479
                            ],
                            [
                                -155.750000000428,
                                18.9226320003835
                            ],
                            [
                                -155.78172699967,
                                18.9334170004161
                            ],
                            [
                                -155.828233000219,
                                18.9665420000341
                            ],
                            [
                                -155.850759999599,
                                18.9692379998284
                            ],
                            [
                                -155.874999999986,
                                18.9764300003424
                            ],
                            [
                                -155.874999999986,
                                18.9781139999099
                            ],
                            [
                                -155.882228000171,
                                18.9806310003134
                            ],
                            [
                                -155.916333999695,
                                18.9999990004069
                            ],
                            [
                                -155.925931000116,
                                19.0081540001595
                            ],
                            [
                                -155.934029999613,
                                19.0215719997653
                            ],
                            [
                                -155.940425999945,
                                19.0360989997065
                            ],
                            [
                                -155.954063999806,
                                19.0536219998422
                            ],
                            [
                                -155.963465000408,
                                19.0820720001652
                            ],
                            [
                                -155.972538000049,
                                19.1013819998042
                            ],
                            [
                                -155.974473999742,
                                19.1127430002024
                            ],
                            [
                                -155.973451999918,
                                19.1249999995791
                            ],
                            [
                                -155.974703999896,
                                19.1249999995791
                            ],
                            [
                                -155.974738000231,
                                19.1301609999222
                            ],
                            [
                                -155.966067000273,
                                19.1912579996811
                            ],
                            [
                                -155.956730000143,
                                19.2309569996071
                            ],
                            [
                                -155.956200000414,
                                19.2599499998129
                            ],
                            [
                                -155.945104000143,
                                19.2966799997259
                            ],
                            [
                                -155.944228999734,
                                19.3296290000062
                            ],
                            [
                                -155.942325999653,
                                19.3395349999698
                            ],
                            [
                                -155.943212999757,
                                19.3442269996181
                            ],
                            [
                                -155.963615000196,
                                19.3865190000801
                            ],
                            [
                                -155.965787999716,
                                19.4006600003405
                            ],
                            [
                                -155.978105000002,
                                19.4458090001559
                            ],
                            [
                                -155.990327999679,
                                19.4558889996622
                            ],
                            [
                                -155.999134000255,
                                19.4661810003544
                            ],
                            [
                                -156.012864000178,
                                19.5000000003411
                            ],
                            [
                                -156.011808000018,
                                19.5003759997388
                            ],
                            [
                                -156.016907000166,
                                19.5326409997793
                            ],
                            [
                                -156.019396000053,
                                19.5392849998439
                            ],
                            [
                                -156.022997000176,
                                19.5636319996586
                            ],
                            [
                                -156.030277000239,
                                19.587478000103
                            ],
                            [
                                -156.038934999778,
                                19.6009480000618
                            ],
                            [
                                -156.057121000141,
                                19.6082329998595
                            ],
                            [
                                -156.071087000066,
                                19.6174610000494
                            ],
                            [
                                -156.07859200026,
                                19.6250009998891
                            ],
                            [
                                -156.08288200012,
                                19.6326589999634
                            ],
                            [
                                -156.087628000091,
                                19.6460830000275
                            ],
                            [
                                -156.088819999794,
                                19.6614080000499
                            ],
                            [
                                -156.097823000013,
                                19.675374000285
                            ],
                            [
                                -156.104742000401,
                                19.6948120002387
                            ],
                            [
                                -156.109582999908,
                                19.7004320001786
                            ],
                            [
                                -156.119074999848,
                                19.7193009997667
                            ],
                            [
                                -156.120001000311,
                                19.7271760003382
                            ],
                            [
                                -156.117919999955,
                                19.7445709998505
                            ],
                            [
                                -156.114727000261,
                                19.7499999998548
                            ],
                            [
                                -156.11585700004,
                                19.7499999998548
                            ],
                            [
                                -156.114857999783,
                                19.7545899997488
                            ],
                            [
                                -156.109162999783,
                                19.7695150002058
                            ],
                            [
                                -156.105518000178,
                                19.7972619997804
                            ],
                            [
                                -156.099464000153,
                                19.8089780002631
                            ],
                            [
                                -156.090615999745,
                                19.8211429996017
                            ],
                            [
                                -156.079516999999,
                                19.8311309997855
                            ],
                            [
                                -156.065627000243,
                                19.8391149995816
                            ],
                            [
                                -156.053544000308,
                                19.843533999764
                            ],
                            [
                                -156.027348000136,
                                19.8750000001716
                            ],
                            [
                                -156.024849000203,
                                19.8750000001716
                            ],
                            [
                                -156.015708999717,
                                19.8865109997273
                            ],
                            [
                                -156.008081000397,
                                19.8927409998393
                            ],
                            [
                                -155.986097000268,
                                19.9026630002698
                            ],
                            [
                                -155.962426999792,
                                19.9091519997176
                            ],
                            [
                                -155.950380999668,
                                19.9420750001851
                            ],
                            [
                                -155.938882999714,
                                19.9615180001119
                            ],
                            [
                                -155.918985999788,
                                19.9839660001656
                            ],
                            [
                                -155.908017999564,
                                19.9919610000592
                            ],
                            [
                                -155.903500000098,
                                20.0000000001039
                            ],
                            [
                                -155.901787999812,
                                20.0000000001039
                            ],
                            [
                                -155.896100000384,
                                20.0080059998878
                            ],
                            [
                                -155.884311000177,
                                20.0183270003899
                            ],
                            [
                                -155.895306000166,
                                20.0357520001761
                            ],
                            [
                                -155.93227499967,
                                20.0834450003346
                            ],
                            [
                                -155.937887999654,
                                20.1041569999521
                            ],
                            [
                                -155.942689999701,
                                20.1103959998613
                            ],
                            [
                                -155.94798800004,
                                20.1237860000306
                            ],
                            [
                                -155.957719000355,
                                20.1676140000349
                            ],
                            [
                                -155.959619999888,
                                20.1840270000081
                            ],
                            [
                                -155.960818000338,
                                20.1977289998876
                            ],
                            [
                                -155.958377000131,
                                20.2091169996574
                            ],
                            [
                                -155.959029000059,
                                20.2268710000515
                            ],
                            [
                                -155.957666999578,
                                20.2349719999602
                            ],
                            [
                                -155.953621000115,
                                20.2470230002449
                            ],
                            [
                                -155.950949999755,
                                20.2499999996898
                            ],
                            [
                                -155.947962000101,
                                20.2616520002209
                            ],
                            [
                                -155.941012999576,
                                20.2736549998622
                            ],
                            [
                                -155.928173000131,
                                20.289941000304
                            ],
                            [
                                -155.912479000027,
                                20.3032279998916
                            ],
                            [
                                -155.885912000134,
                                20.3145490001999
                            ],
                            [
                                -155.851635000008,
                                20.3214090002686
                            ],
                            [
                                -155.829460999908,
                                20.3199070002577
                            ],
                            [
                                -155.814744000321,
                                20.3157440003872
                            ],
                            [
                                -155.812088000029,
                                20.3132699996093
                            ],
                            [
                                -155.792889999888,
                                20.3092700000626
                            ],
                            [
                                -155.780182999614,
                                20.303459999605
                            ],
                            [
                                -155.774818000226,
                                20.298645000397
                            ],
                            [
                                -155.755475000319,
                                20.2957689999015
                            ],
                            [
                                -155.740399999634,
                                20.2889250002181
                            ],
                            [
                                -155.726864999705,
                                20.2801219998776
                            ],
                            [
                                -155.717967999791,
                                20.2700799997388
                            ],
                            [
                                -155.702216999786,
                                20.261602000296
                            ],
                            [
                                -155.688845000064,
                                20.2447760003237
                            ],
                            [
                                -155.677726000227,
                                20.2412390003527
                            ],
                            [
                                -155.658723000079,
                                20.2253829998754
                            ],
                            [
                                -155.651790000345,
                                20.223867000077
                            ],
                            [
                                -155.630515000046,
                                20.2134940003574
                            ],
                            [
                                -155.615733999986,
                                20.2035089999433
                            ],
                            [
                                -155.606399000405,
                                20.1939479998815
                            ],
                            [
                                -155.597786999998,
                                20.1908600000595
                            ],
                            [
                                -155.582087000047,
                                20.1813160000771
                            ],
                            [
                                -155.578767999956,
                                20.1801469997684
                            ],
                            [
                                -155.570610999834,
                                20.1829459998983
                            ],
                            [
                                -155.561734000012,
                                20.1836429999393
                            ],
                            [
                                -155.540749999965,
                                20.1802489997031
                            ],
                            [
                                -155.50837599992,
                                20.1684680002937
                            ],
                            [
                                -155.478823999847,
                                20.1625070000462
                            ],
                            [
                                -155.462877000024,
                                20.1551760002405
                            ],
                            [
                                -155.441384000164,
                                20.1500959998508
                            ],
                            [
                                -155.432862000169,
                                20.1452070001443
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -156.562697000345,
                                20.7380410003092
                            ],
                            [
                                -156.577588000009,
                                20.7432579999253
                            ],
                            [
                                -156.586931999812,
                                20.7500000001839
                            ],
                            [
                                -156.625000000032,
                                20.7500000001839
                            ],
                            [
                                -156.625000000032,
                                20.7630820003582
                            ],
                            [
                                -156.640084999864,
                                20.7651059996991
                            ],
                            [
                                -156.659858999766,
                                20.7784189996922
                            ],
                            [
                                -156.669371999623,
                                20.7888440000483
                            ],
                            [
                                -156.672680999668,
                                20.7948430001971
                            ],
                            [
                                -156.684729000341,
                                20.8040149999063
                            ],
                            [
                                -156.721737000204,
                                20.8424359995897
                            ],
                            [
                                -156.728614999685,
                                20.8516989997613
                            ],
                            [
                                -156.737530999866,
                                20.867917999666
                            ],
                            [
                                -156.739462000434,
                                20.8750000000937
                            ],
                            [
                                -156.737577999722,
                                20.8750000000937
                            ],
                            [
                                -156.739418999878,
                                20.8888080000481
                            ],
                            [
                                -156.74999999959,
                                20.9051249997826
                            ],
                            [
                                -156.751941000204,
                                20.9136809998455
                            ],
                            [
                                -156.751796000439,
                                20.9485480004059
                            ],
                            [
                                -156.74999999959,
                                20.9607730001446
                            ],
                            [
                                -156.743126000306,
                                20.9760950003203
                            ],
                            [
                                -156.735016999865,
                                21.0057290000597
                            ],
                            [
                                -156.728424999715,
                                21.017326999829
                            ],
                            [
                                -156.723001999702,
                                21.0225650002825
                            ],
                            [
                                -156.70848900033,
                                21.0432309997962
                            ],
                            [
                                -156.690896000343,
                                21.0534790002946
                            ],
                            [
                                -156.687061999694,
                                21.0605609997254
                            ],
                            [
                                -156.670423999584,
                                21.0766930001653
                            ],
                            [
                                -156.655947000197,
                                21.0818260003799
                            ],
                            [
                                -156.633583000126,
                                21.0833409999174
                            ],
                            [
                                -156.618134000245,
                                21.0904979998021
                            ],
                            [
                                -156.605888999929,
                                21.0925799996653
                            ],
                            [
                                -156.591030999877,
                                21.0924110002183
                            ],
                            [
                                -156.574371999851,
                                21.0885310003429
                            ],
                            [
                                -156.559512999974,
                                21.0823379996779
                            ],
                            [
                                -156.543033000048,
                                21.0688239996065
                            ],
                            [
                                -156.512963999767,
                                21.0510129997471
                            ],
                            [
                                -156.490727999742,
                                21.035435000223
                            ],
                            [
                                -156.475382999619,
                                21.0192079996884
                            ],
                            [
                                -156.468145000287,
                                20.9999999996284
                            ],
                            [
                                -156.470300000263,
                                20.9999999996284
                            ],
                            [
                                -156.467546000062,
                                20.9879500001638
                            ],
                            [
                                -156.463433999578,
                                20.9795539998637
                            ],
                            [
                                -156.451090000426,
                                20.968758000084
                            ],
                            [
                                -156.427446999714,
                                20.9733800002304
                            ],
                            [
                                -156.404907999741,
                                20.9741810000055
                            ],
                            [
                                -156.396203000346,
                                20.9809679997999
                            ],
                            [
                                -156.377601999881,
                                20.989823000046
                            ],
                            [
                                -156.375000000017,
                                20.9908350002916
                            ],
                            [
                                -156.375000000017,
                                20.9892459997303
                            ],
                            [
                                -156.364739999797,
                                20.9937890003009
                            ],
                            [
                                -156.354785999899,
                                20.9938010001977
                            ],
                            [
                                -156.339218000192,
                                21.0041949999863
                            ],
                            [
                                -156.320478999635,
                                21.006230000139
                            ],
                            [
                                -156.294488000401,
                                21.0010259999811
                            ],
                            [
                                -156.274301999872,
                                21.0042360004055
                            ],
                            [
                                -156.26857799956,
                                21.0037509996957
                            ],
                            [
                                -156.256595000083,
                                20.9999999996284
                            ],
                            [
                                -156.237582999714,
                                20.9897149997408
                            ],
                            [
                                -156.214340999759,
                                20.9830880004111
                            ],
                            [
                                -156.195295999779,
                                20.9715020004118
                            ],
                            [
                                -156.171826000218,
                                20.9492240002772
                            ],
                            [
                                -156.161874999795,
                                20.9428740003104
                            ],
                            [
                                -156.136358000213,
                                20.917436999606
                            ],
                            [
                                -156.125000000001,
                                20.9150090001088
                            ],
                            [
                                -156.111060999841,
                                20.90833799996
                            ],
                            [
                                -156.095924999955,
                                20.8984809999404
                            ],
                            [
                                -156.086013999715,
                                20.8884309999384
                            ],
                            [
                                -156.081693999717,
                                20.8807279999115
                            ],
                            [
                                -156.076475999743,
                                20.8807509997382
                            ],
                            [
                                -156.04962299962,
                                20.8705439999506
                            ],
                            [
                                -156.0322890001,
                                20.8598580003499
                            ],
                            [
                                -156.025587000345,
                                20.8594999995892
                            ],
                            [
                                -156.000000000443,
                                20.8521230003711
                            ],
                            [
                                -156.000000000443,
                                20.8750000000937
                            ],
                            [
                                -155.958991000425,
                                20.8750000000937
                            ],
                            [
                                -155.950955999603,
                                20.8619279996088
                            ],
                            [
                                -155.939126000286,
                                20.8365910000968
                            ],
                            [
                                -155.929693999722,
                                20.8014669997285
                            ],
                            [
                                -155.92678499971,
                                20.7817350001372
                            ],
                            [
                                -155.926952000115,
                                20.7670449999372
                            ],
                            [
                                -155.929180999711,
                                20.7580749996528
                            ],
                            [
                                -155.926836999589,
                                20.7386750000834
                            ],
                            [
                                -155.928101000161,
                                20.7247920000326
                            ],
                            [
                                -155.949645999899,
                                20.6768320002972
                            ],
                            [
                                -155.961457999672,
                                20.6604520000966
                            ],
                            [
                                -155.969593999878,
                                20.6521239998404
                            ],
                            [
                                -156.000000000443,
                                20.6259760003604
                            ],
                            [
                                -156.000000000443,
                                20.6318620001433
                            ],
                            [
                                -156.005121000332,
                                20.625000000139
                            ],
                            [
                                -156.002650999813,
                                20.625000000139
                            ],
                            [
                                -156.006016999759,
                                20.6210000000317
                            ],
                            [
                                -156.016304999568,
                                20.6123740000463
                            ],
                            [
                                -156.029948000351,
                                20.6040710002765
                            ],
                            [
                                -156.043067000353,
                                20.5992929996704
                            ],
                            [
                                -156.054265999658,
                                20.5972539996872
                            ],
                            [
                                -156.073271000354,
                                20.5984089996316
                            ],
                            [
                                -156.082994000273,
                                20.5950730001941
                            ],
                            [
                                -156.087428000074,
                                20.590700999805
                            ],
                            [
                                -156.102203000285,
                                20.5830050004002
                            ],
                            [
                                -156.135270000267,
                                20.5698430003522
                            ],
                            [
                                -156.143850999813,
                                20.5697400003254
                            ],
                            [
                                -156.158737000353,
                                20.5727339996705
                            ],
                            [
                                -156.177164999667,
                                20.5724770001356
                            ],
                            [
                                -156.195607999948,
                                20.5771509998
                            ],
                            [
                                -156.24999999956,
                                20.5503370001947
                            ],
                            [
                                -156.24999999956,
                                20.5541320000141
                            ],
                            [
                                -156.2791480003,
                                20.540072000017
                            ],
                            [
                                -156.290472000176,
                                20.536895000131
                            ],
                            [
                                -156.324126999613,
                                20.536326000079
                            ],
                            [
                                -156.341488999621,
                                20.5330380000074
                            ],
                            [
                                -156.351098999562,
                                20.5344079998636
                            ],
                            [
                                -156.375000000017,
                                20.5289850001183
                            ],
                            [
                                -156.375000000017,
                                20.531428000104
                            ],
                            [
                                -156.420494999714,
                                20.5366089996229
                            ],
                            [
                                -156.437383999894,
                                20.5439290002363
                            ],
                            [
                                -156.453491000449,
                                20.5555249997455
                            ],
                            [
                                -156.459867999616,
                                20.5576660002486
                            ],
                            [
                                -156.484509999686,
                                20.5790830000552
                            ],
                            [
                                -156.488376999948,
                                20.5585459996826
                            ],
                            [
                                -156.482318999725,
                                20.5481269999327
                            ],
                            [
                                -156.480588000072,
                                20.5411100001525
                            ],
                            [
                                -156.479551000179,
                                20.5295729996732
                            ],
                            [
                                -156.483355999618,
                                20.5131669999969
                            ],
                            [
                                -156.489366999985,
                                20.4999999998374
                            ],
                            [
                                -156.512199999686,
                                20.4747799999696
                            ],
                            [
                                -156.533455999719,
                                20.4629799999986
                            ],
                            [
                                -156.546069000281,
                                20.4608450003624
                            ],
                            [
                                -156.563893000246,
                                20.4628320001272
                            ],
                            [
                                -156.573544000196,
                                20.4591319999051
                            ],
                            [
                                -156.583924000066,
                                20.458167000092
                            ],
                            [
                                -156.601306000165,
                                20.4609820002184
                            ],
                            [
                                -156.656859000416,
                                20.4572660000498
                            ],
                            [
                                -156.672786000148,
                                20.4577749998003
                            ],
                            [
                                -156.705114999988,
                                20.4541419999354
                            ],
                            [
                                -156.732893999675,
                                20.4529300000428
                            ],
                            [
                                -156.74999999959,
                                20.4543350000538
                            ],
                            [
                                -156.749998999765,
                                20.4999880002472
                            ],
                            [
                                -156.744263999583,
                                20.4999990002221
                            ],
                            [
                                -156.749990000443,
                                20.5068250000302
                            ],
                            [
                                -156.755172000431,
                                20.5201580000912
                            ],
                            [
                                -156.755742000343,
                                20.5352760000912
                            ],
                            [
                                -156.750007999986,
                                20.5564560000051
                            ],
                            [
                                -156.74999999959,
                                20.5538309998999
                            ],
                            [
                                -156.742659000326,
                                20.5700910001317
                            ],
                            [
                                -156.733645000236,
                                20.58184400032
                            ],
                            [
                                -156.704442000143,
                                20.6045910003995
                            ],
                            [
                                -156.691114999728,
                                20.6115670001838
                            ],
                            [
                                -156.674228999922,
                                20.6153750003156
                            ],
                            [
                                -156.655055999894,
                                20.625000000139
                            ],
                            [
                                -156.645375999633,
                                20.6333999996472
                            ],
                            [
                                -156.625000000032,
                                20.6450959997086
                            ],
                            [
                                -156.610080000055,
                                20.6507919996652
                            ],
                            [
                                -156.597734000355,
                                20.6578789999487
                            ],
                            [
                                -156.575070999635,
                                20.6593100001065
                            ],
                            [
                                -156.549625000198,
                                20.6580300000327
                            ],
                            [
                                -156.540245000411,
                                20.6713849995861
                            ],
                            [
                                -156.531658999943,
                                20.6792199997999
                            ],
                            [
                                -156.50883000044,
                                20.689131000099
                            ],
                            [
                                -156.499999999575,
                                20.6912319999032
                            ],
                            [
                                -156.499999999575,
                                20.6894259997695
                            ],
                            [
                                -156.495728000156,
                                20.6915800001749
                            ],
                            [
                                -156.499999999575,
                                20.7025470002808
                            ],
                            [
                                -156.499999999575,
                                20.6990869996575
                            ],
                            [
                                -156.511869999973,
                                20.7353860000553
                            ],
                            [
                                -156.521903000412,
                                20.7325570000149
                            ],
                            [
                                -156.546819999944,
                                20.7324860003446
                            ],
                            [
                                -156.562697000345,
                                20.7380410003092
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -158.31763900024,
                                21.5391479999783
                            ],
                            [
                                -158.328651999772,
                                21.5459999996252
                            ],
                            [
                                -158.342150999716,
                                21.564285999926
                            ],
                            [
                                -158.344051000322,
                                21.5697830001383
                            ],
                            [
                                -158.344649999649,
                                21.5831769999506
                            ],
                            [
                                -158.342149000066,
                                21.5911749996352
                            ],
                            [
                                -158.335048000103,
                                21.6045730002651
                            ],
                            [
                                -158.324247000283,
                                21.6156749997314
                            ],
                            [
                                -158.311945000065,
                                21.6217799996725
                            ],
                            [
                                -158.301909999976,
                                21.6217870003715
                            ],
                            [
                                -158.278155999835,
                                21.6278420003213
                            ],
                            [
                                -158.247237000157,
                                21.63108600009
                            ],
                            [
                                -158.230221999581,
                                21.6308159996826
                            ],
                            [
                                -158.199604000202,
                                21.634474000403
                            ],
                            [
                                -158.175004999789,
                                21.6320090003043
                            ],
                            [
                                -158.148846000325,
                                21.6419990001032
                            ],
                            [
                                -158.134153000129,
                                21.6579890000097
                            ],
                            [
                                -158.122243000447,
                                21.6656980003338
                            ],
                            [
                                -158.120593999911,
                                21.6637750003458
                            ],
                            [
                                -158.120229000038,
                                21.6573049995854
                            ],
                            [
                                -158.103343000231,
                                21.6920989996827
                            ],
                            [
                                -158.095542999586,
                                21.702994999687
                            ],
                            [
                                -158.088542999905,
                                21.7094920000828
                            ],
                            [
                                -158.071944000154,
                                21.7191909999596
                            ],
                            [
                                -158.057143999828,
                                21.7354830000039
                            ],
                            [
                                -158.050144000148,
                                21.7398810000614
                            ],
                            [
                                -158.030944000356,
                                21.7430839997884
                            ],
                            [
                                -157.997243999815,
                                21.7615910001115
                            ],
                            [
                                -157.991748000007,
                                21.76296499971
                            ],
                            [
                                -157.97831799966,
                                21.762996999675
                            ],
                            [
                                -157.941785000173,
                                21.7545790001146
                            ],
                            [
                                -157.924213000104,
                                21.7434510002339
                            ],
                            [
                                -157.909245000447,
                                21.7279810003766
                            ],
                            [
                                -157.900444999719,
                                21.7230810003658
                            ],
                            [
                                -157.891445999698,
                                21.7120830000546
                            ],
                            [
                                -157.881445999769,
                                21.7051829996677
                            ],
                            [
                                -157.872245999906,
                                21.685888999921
                            ],
                            [
                                -157.860210999652,
                                21.6655439999846
                            ],
                            [
                                -157.844149000201,
                                21.6218039998009
                            ],
                            [
                                -157.83724900008,
                                21.6137029998959
                            ],
                            [
                                -157.818649000338,
                                21.6033999995863
                            ],
                            [
                                -157.802048999863,
                                21.590099000327
                            ],
                            [
                                -157.798648999582,
                                21.5852000001431
                            ],
                            [
                                -157.790249999611,
                                21.561505000178
                            ],
                            [
                                -157.780951000014,
                                21.5446070003398
                            ],
                            [
                                -157.761931000148,
                                21.5263890001239
                            ],
                            [
                                -157.750923999565,
                                21.5164580001657
                            ],
                            [
                                -157.747251000371,
                                21.5153069996993
                            ],
                            [
                                -157.727724000341,
                                21.5197839999026
                            ],
                            [
                                -157.712174000178,
                                21.5208139998613
                            ],
                            [
                                -157.702612999742,
                                21.5187090001915
                            ],
                            [
                                -157.683493000317,
                                21.5078070001159
                            ],
                            [
                                -157.677777000401,
                                21.5018529996697
                            ],
                            [
                                -157.660949000321,
                                21.4967889997955
                            ],
                            [
                                -157.650549000359,
                                21.4850899996111
                            ],
                            [
                                -157.642849000171,
                                21.4722929997665
                            ],
                            [
                                -157.640749000447,
                                21.4626950000071
                            ],
                            [
                                -157.645450000211,
                                21.4487020002726
                            ],
                            [
                                -157.652151000141,
                                21.4391070000548
                            ],
                            [
                                -157.642450999788,
                                21.4264079998726
                            ],
                            [
                                -157.638550999915,
                                21.4178089999492
                            ],
                            [
                                -157.632352000125,
                                21.3900140001667
                            ],
                            [
                                -157.629252000318,
                                21.3852139998897
                            ],
                            [
                                -157.628452000252,
                                21.3766160003546
                            ],
                            [
                                -157.622251999739,
                                21.3727150003019
                            ],
                            [
                                -157.620051999557,
                                21.3676759996886
                            ],
                            [
                                -157.608219999692,
                                21.3530539998576
                            ],
                            [
                                -157.602298999737,
                                21.3415210000445
                            ],
                            [
                                -157.598721999904,
                                21.3261239999823
                            ],
                            [
                                -157.597288000351,
                                21.3106619998976
                            ],
                            [
                                -157.597909000317,
                                21.2951339997794
                            ],
                            [
                                -157.600922000086,
                                21.2829190003545
                            ],
                            [
                                -157.607693000336,
                                21.2691339998435
                            ],
                            [
                                -157.616130999767,
                                21.2604290003087
                            ],
                            [
                                -157.634071000083,
                                21.2468329997959
                            ],
                            [
                                -157.645862999765,
                                21.2405729999068
                            ],
                            [
                                -157.653475999914,
                                21.2291140000687
                            ],
                            [
                                -157.675777000236,
                                21.2138010003991
                            ],
                            [
                                -157.689378000287,
                                21.2091839999554
                            ],
                            [
                                -157.708323999635,
                                21.208202000226
                            ],
                            [
                                -157.719701000114,
                                21.2094850001143
                            ],
                            [
                                -157.730908999639,
                                21.2124549998143
                            ],
                            [
                                -157.739957999889,
                                21.2168549999826
                            ],
                            [
                                -157.745310999582,
                                21.2216710001628
                            ],
                            [
                                -157.753337000183,
                                21.219400000072
                            ],
                            [
                                -157.771407000195,
                                21.2078330002539
                            ],
                            [
                                -157.790852000208,
                                21.20167599989
                            ],
                            [
                                -157.810228999552,
                                21.1992379996912
                            ],
                            [
                                -157.829092999782,
                                21.2009409998918
                            ],
                            [
                                -157.859066999629,
                                21.2131350001791
                            ],
                            [
                                -157.881328999593,
                                21.2393499998079
                            ],
                            [
                                -157.886051000172,
                                21.2432279997995
                            ],
                            [
                                -157.895955999666,
                                21.2468390004018
                            ],
                            [
                                -157.898656000339,
                                21.2499390002516
                            ],
                            [
                                -157.913455999766,
                                21.2475389999107
                            ],
                            [
                                -157.931454999634,
                                21.2483390001817
                            ],
                            [
                                -157.941955000053,
                                21.2530390001811
                            ],
                            [
                                -157.963555000043,
                                21.2577379996442
                            ],
                            [
                                -157.970748000067,
                                21.2533030004148
                            ],
                            [
                                -157.983354999883,
                                21.2501390001899
                            ],
                            [
                                -158.025655000238,
                                21.2483390001817
                            ],
                            [
                                -158.032586000321,
                                21.2445029997602
                            ],
                            [
                                -158.039047999877,
                                21.2432359999656
                            ],
                            [
                                -158.059810000166,
                                21.2412739995923
                            ],
                            [
                                -158.072478999906,
                                21.2419229998539
                            ],
                            [
                                -158.091585999811,
                                21.2378629998198
                            ],
                            [
                                -158.110076999773,
                                21.2370710001237
                            ],
                            [
                                -158.122254000317,
                                21.2386499999212
                            ],
                            [
                                -158.134759999852,
                                21.2423910000084
                            ],
                            [
                                -158.149253999856,
                                21.2517369998337
                            ],
                            [
                                -158.159554000258,
                                21.2634360003333
                            ],
                            [
                                -158.164353999757,
                                21.2723349998861
                            ],
                            [
                                -158.183253000148,
                                21.3459350000787
                            ],
                            [
                                -158.188753000153,
                                21.3535340002104
                            ],
                            [
                                -158.206552000004,
                                21.3590330001305
                            ],
                            [
                                -158.217752000032,
                                21.364532000299
                            ],
                            [
                                -158.228851999603,
                                21.3756310001878
                            ],
                            [
                                -158.235551999708,
                                21.3894310003358
                            ],
                            [
                                -158.240051999631,
                                21.4115309999513
                            ],
                            [
                                -158.245784000338,
                                21.4144759999292
                            ],
                            [
                                -158.247252000226,
                                21.4190109999568
                            ],
                            [
                                -158.257643999792,
                                21.4255300003562
                            ],
                            [
                                -158.26374899987,
                                21.4329889998472
                            ],
                            [
                                -158.268331999634,
                                21.4420380003776
                            ],
                            [
                                -158.276241000059,
                                21.4493190001602
                            ],
                            [
                                -158.285934999666,
                                21.4651640001815
                            ],
                            [
                                -158.29026499971,
                                21.4759620004129
                            ],
                            [
                                -158.291547999649,
                                21.489357999943
                            ],
                            [
                                -158.290164000326,
                                21.4968219999044
                            ],
                            [
                                -158.301352999585,
                                21.4968179996383
                            ],
                            [
                                -158.29865299981,
                                21.5247179998833
                            ],
                            [
                                -158.31763900024,
                                21.5391479999783
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -159.625000000213,
                                22.2618109997679
                            ],
                            [
                                -159.612952000438,
                                22.2722350000136
                            ],
                            [
                                -159.596388999774,
                                22.277999000126
                            ],
                            [
                                -159.582990000287,
                                22.2799880003693
                            ],
                            [
                                -159.574883000393,
                                22.2829500002586
                            ],
                            [
                                -159.547441999916,
                                22.2830759999245
                            ],
                            [
                                -159.533562000205,
                                22.2772009998918
                            ],
                            [
                                -159.524654000421,
                                22.275493999689
                            ],
                            [
                                -159.515406999804,
                                22.2777990002824
                            ],
                            [
                                -159.506826000258,
                                22.2824779996564
                            ],
                            [
                                -159.484042999887,
                                22.2859160003742
                            ],
                            [
                                -159.43977000003,
                                22.2818649996377
                            ],
                            [
                                -159.432755000281,
                                22.2780350003667
                            ],
                            [
                                -159.412413000117,
                                22.2888459999623
                            ],
                            [
                                -159.402920000351,
                                22.2904730000427
                            ],
                            [
                                -159.389673000302,
                                22.2875070001925
                            ],
                            [
                                -159.355687000429,
                                22.2696540002233
                            ],
                            [
                                -159.334252000295,
                                22.2664700000716
                            ],
                            [
                                -159.308797999565,
                                22.2499999997776
                            ],
                            [
                                -159.291325000127,
                                22.2336199998875
                            ],
                            [
                                -159.265517000292,
                                22.2039149998457
                            ],
                            [
                                -159.245779000376,
                                22.1668079999891
                            ],
                            [
                                -159.241826999725,
                                22.1249999997999
                            ],
                            [
                                -159.242613000446,
                                22.1118659998429
                            ],
                            [
                                -159.245020000317,
                                22.101652000349
                            ],
                            [
                                -159.249999999741,
                                22.0841459997956
                            ],
                            [
                                -159.259012000181,
                                22.0729329997166
                            ],
                            [
                                -159.270367999845,
                                22.041118000154
                            ],
                            [
                                -159.283711000153,
                                22.0185009998897
                            ],
                            [
                                -159.279887000448,
                                22.001181000122
                            ],
                            [
                                -159.281586000314,
                                21.9817679995959
                            ],
                            [
                                -159.279222000101,
                                21.9652959997971
                            ],
                            [
                                -159.28435399986,
                                21.9406370004068
                            ],
                            [
                                -159.307978000305,
                                21.90867299962
                            ],
                            [
                                -159.320113999943,
                                21.8982999999729
                            ],
                            [
                                -159.339754999776,
                                21.8864440003381
                            ],
                            [
                                -159.342330999701,
                                21.8801909999295
                            ],
                            [
                                -159.352662999891,
                                21.8696339997167
                            ],
                            [
                                -159.37118200034,
                                21.8589909999287
                            ],
                            [
                                -159.375000000198,
                                21.8544339999669
                            ],
                            [
                                -159.403900000342,
                                21.834953000152
                            ],
                            [
                                -159.43285300019,
                                21.8207709997523
                            ],
                            [
                                -159.443998999791,
                                21.8177889997914
                            ],
                            [
                                -159.453739000327,
                                21.8177999998472
                            ],
                            [
                                -159.493488999797,
                                21.8291800003551
                            ],
                            [
                                -159.499999999756,
                                21.8323219997724
                            ],
                            [
                                -159.499999999756,
                                21.8335650003883
                            ],
                            [
                                -159.518239999648,
                                21.835059999724
                            ],
                            [
                                -159.522956000379,
                                21.833676000272
                            ],
                            [
                                -159.537653999699,
                                21.8342779996733
                            ],
                            [
                                -159.587906999961,
                                21.8454699996767
                            ],
                            [
                                -159.612405000092,
                                21.8428730001966
                            ],
                            [
                                -159.624769000234,
                                21.8438739997122
                            ],
                            [
                                -159.631259000277,
                                21.8466880003635
                            ],
                            [
                                -159.67294100014,
                                21.8750000004125
                            ],
                            [
                                -159.679505999627,
                                21.8804599999315
                            ],
                            [
                                -159.687887999881,
                                21.8909529999507
                            ],
                            [
                                -159.697676999922,
                                21.8956550000222
                            ],
                            [
                                -159.711713999992,
                                21.907556999914
                            ],
                            [
                                -159.735843999876,
                                21.9121660003169
                            ],
                            [
                                -159.762075000209,
                                21.9275959996049
                            ],
                            [
                                -159.775270000379,
                                21.9304550001334
                            ],
                            [
                                -159.793600999609,
                                21.9397800001831
                            ],
                            [
                                -159.808605000149,
                                21.9529019997331
                            ],
                            [
                                -159.833736999766,
                                21.989295000032
                            ],
                            [
                                -159.838584000019,
                                21.9999999996193
                            ],
                            [
                                -159.843608999649,
                                21.9999999996193
                            ],
                            [
                                -159.847758999767,
                                22.0085249996685
                            ],
                            [
                                -159.850959000032,
                                22.0271469998903
                            ],
                            [
                                -159.850677999824,
                                22.0405900000487
                            ],
                            [
                                -159.847122999733,
                                22.0528700001328
                            ],
                            [
                                -159.84812399964,
                                22.0760729999737
                            ],
                            [
                                -159.837722999853,
                                22.0942259997871
                            ],
                            [
                                -159.804894000422,
                                22.1249999997999
                            ],
                            [
                                -159.801111999651,
                                22.1249999997999
                            ],
                            [
                                -159.794005999665,
                                22.134621999936
                            ],
                            [
                                -159.792023000116,
                                22.1516600000855
                            ],
                            [
                                -159.788826000225,
                                22.1601050002503
                            ],
                            [
                                -159.77279999986,
                                22.1810599996565
                            ],
                            [
                                -159.763414000225,
                                22.1897520003817
                            ],
                            [
                                -159.749999999771,
                                22.1977179998543
                            ],
                            [
                                -159.749999999771,
                                22.1994489998452
                            ],
                            [
                                -159.707992000393,
                                22.2131810002865
                            ],
                            [
                                -159.677251999917,
                                22.2271079998669
                            ],
                            [
                                -159.663171000365,
                                22.2385610003432
                            ],
                            [
                                -159.653454000294,
                                22.2445659997615
                            ],
                            [
                                -159.640002000205,
                                22.2499999997776
                            ],
                            [
                                -159.625000000213,
                                22.2632980003105
                            ],
                            [
                                -159.625000000213,
                                22.2618109997679
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -157.015057999674,
                                21.2611940003631
                            ],
                            [
                                -157.009085999665,
                                21.2659479996568
                            ],
                            [
                                -156.990342999808,
                                21.2735340001132
                            ],
                            [
                                -156.971288999607,
                                21.2743650000705
                            ],
                            [
                                -156.952166999633,
                                21.2693279999761
                            ],
                            [
                                -156.927399999889,
                                21.2565779998007
                            ],
                            [
                                -156.917151000438,
                                21.2437839998462
                            ],
                            [
                                -156.910278999906,
                                21.2295710003835
                            ],
                            [
                                -156.893947999943,
                                21.234006000386
                            ],
                            [
                                -156.874362000362,
                                21.2330929996447
                            ],
                            [
                                -156.859043000178,
                                21.2270880002314
                            ],
                            [
                                -156.85351399986,
                                21.2227050002592
                            ],
                            [
                                -156.840869000409,
                                21.2231950001666
                            ],
                            [
                                -156.812115999679,
                                21.2314070000128
                            ],
                            [
                                -156.794843000258,
                                21.2341609997222
                            ],
                            [
                                -156.783885000079,
                                21.2314509998439
                            ],
                            [
                                -156.771063000178,
                                21.2328609996213
                            ],
                            [
                                -156.74999999959,
                                21.2281960001433
                            ],
                            [
                                -156.722076000137,
                                21.2260620001315
                            ],
                            [
                                -156.706250999614,
                                21.2172280003839
                            ],
                            [
                                -156.68650699985,
                                21.2105139996841
                            ],
                            [
                                -156.675828000229,
                                21.2024019997297
                            ],
                            [
                                -156.659565000038,
                                21.1819069998403
                            ],
                            [
                                -156.650885999684,
                                21.1450189998315
                            ],
                            [
                                -156.650525000009,
                                21.134277000154
                            ],
                            [
                                -156.652725000191,
                                21.1249999998359
                            ],
                            [
                                -156.654835999786,
                                21.1249999998359
                            ],
                            [
                                -156.657707999988,
                                21.1149030001365
                            ],
                            [
                                -156.672586000132,
                                21.0957170002788
                            ],
                            [
                                -156.693992999777,
                                21.0877919998097
                            ],
                            [
                                -156.708055999786,
                                21.0722089998879
                            ],
                            [
                                -156.735601000021,
                                21.055777999644
                            ],
                            [
                                -156.74999999959,
                                21.0438299997392
                            ],
                            [
                                -156.74999999959,
                                21.0469909997366
                            ],
                            [
                                -156.761740000292,
                                21.0408869998786
                            ],
                            [
                                -156.785290000218,
                                21.0245400000004
                            ],
                            [
                                -156.801612999785,
                                21.0219689997854
                            ],
                            [
                                -156.829989000048,
                                21.0022590000343
                            ],
                            [
                                -156.836953999568,
                                20.9999999996284
                            ],
                            [
                                -156.875000000047,
                                20.9999999996284
                            ],
                            [
                                -156.875000000047,
                                20.9936960002297
                            ],
                            [
                                -156.877923000302,
                                20.9933639997013
                            ],
                            [
                                -156.893619000055,
                                20.9938369998821
                            ],
                            [
                                -156.899463000017,
                                20.9945669997188
                            ],
                            [
                                -156.903674000235,
                                20.9970240001718
                            ],
                            [
                                -156.91453500033,
                                20.9975840000834
                            ],
                            [
                                -156.925726999962,
                                21.0014279999913
                            ],
                            [
                                -156.935358999644,
                                21.0067760001125
                            ],
                            [
                                -156.96313099966,
                                21.0128699999222
                            ],
                            [
                                -156.973893999845,
                                21.021820999861
                            ],
                            [
                                -156.999999999605,
                                21.0293170003792
                            ],
                            [
                                -157.010555000276,
                                21.035482000386
                            ],
                            [
                                -157.02451000033,
                                21.0388039996771
                            ],
                            [
                                -157.038720999578,
                                21.0385200000718
                            ],
                            [
                                -157.050236999974,
                                21.04270400006
                            ],
                            [
                                -157.072149000133,
                                21.0575970002579
                            ],
                            [
                                -157.077556000252,
                                21.0583580000417
                            ],
                            [
                                -157.102757000365,
                                21.0579580003949
                            ],
                            [
                                -157.125000000062,
                                21.0524959996421
                            ],
                            [
                                -157.125000000062,
                                21.0500880003893
                            ],
                            [
                                -157.129544000366,
                                21.0485550000558
                            ],
                            [
                                -157.156004000129,
                                21.0488460002369
                            ],
                            [
                                -157.177198000238,
                                21.04647299999
                            ],
                            [
                                -157.216036000387,
                                21.0456550000091
                            ],
                            [
                                -157.231438000412,
                                21.0430990001917
                            ],
                            [
                                -157.24999999962,
                                21.0449099998074
                            ],
                            [
                                -157.24999999962,
                                21.0461180000244
                            ],
                            [
                                -157.267907000324,
                                21.0447209998579
                            ],
                            [
                                -157.289427999773,
                                21.0461140000649
                            ],
                            [
                                -157.321243999602,
                                21.0506760002333
                            ],
                            [
                                -157.334618999697,
                                21.058604000175
                            ],
                            [
                                -157.349123999572,
                                21.0722399997939
                            ],
                            [
                                -157.36378499998,
                                21.0923580002678
                            ],
                            [
                                -157.366549000227,
                                21.1009560000328
                            ],
                            [
                                -157.366258999799,
                                21.1166580003239
                            ],
                            [
                                -157.363483999682,
                                21.1249999998359
                            ],
                            [
                                -157.365663999772,
                                21.1249999998359
                            ],
                            [
                                -157.357149000348,
                                21.1390659996405
                            ],
                            [
                                -157.349891999851,
                                21.1570850000519
                            ],
                            [
                                -157.338593000089,
                                21.1750380003739
                            ],
                            [
                                -157.328814999918,
                                21.1878189999986
                            ],
                            [
                                -157.311674999668,
                                21.2041279996536
                            ],
                            [
                                -157.314554000441,
                                21.2202719999351
                            ],
                            [
                                -157.314468000227,
                                21.2304329999718
                            ],
                            [
                                -157.3105209996,
                                21.2445219998851
                            ],
                            [
                                -157.301350999874,
                                21.257219999837
                            ],
                            [
                                -157.292295999777,
                                21.2656350003844
                            ],
                            [
                                -157.28128400007,
                                21.2725900000925
                            ],
                            [
                                -157.272186000314,
                                21.2765640000758
                            ],
                            [
                                -157.24999999962,
                                21.2787730003772
                            ],
                            [
                                -157.24999999962,
                                21.2803470003256
                            ],
                            [
                                -157.231259000312,
                                21.2756599998027
                            ],
                            [
                                -157.214242000085,
                                21.2786129995874
                            ],
                            [
                                -157.199538000019,
                                21.2765090001752
                            ],
                            [
                                -157.18394299965,
                                21.2714660001974
                            ],
                            [
                                -157.158991999783,
                                21.2578959996333
                            ],
                            [
                                -157.135204000205,
                                21.2551610001312
                            ],
                            [
                                -157.125000000062,
                                21.2556319998063
                            ],
                            [
                                -157.125000000062,
                                21.2571440000126
                            ],
                            [
                                -157.109492999556,
                                21.2525799996569
                            ],
                            [
                                -157.090732000156,
                                21.2522700002103
                            ],
                            [
                                -157.072441000211,
                                21.2467769999907
                            ],
                            [
                                -157.065326999829,
                                21.2469489997434
                            ],
                            [
                                -157.057395999663,
                                21.2441870001893
                            ],
                            [
                                -157.034393999908,
                                21.2457090000507
                            ],
                            [
                                -157.030162999598,
                                21.2439499998572
                            ],
                            [
                                -157.025296000152,
                                21.2499999996713
                            ],
                            [
                                -157.026444000373,
                                21.2499999996713
                            ],
                            [
                                -157.015057999674,
                                21.2611940003631
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -176.039627000199,
                                27.7582419997593
                            ],
                            [
                                -176.04292000035,
                                27.7784060003178
                            ],
                            [
                                -176.042592000287,
                                27.7980679997249
                            ],
                            [
                                -176.025016000019,
                                27.8215790001022
                            ],
                            [
                                -176.006191000147,
                                27.8326070001248
                            ],
                            [
                                -175.999999999855,
                                27.8353440002616
                            ],
                            [
                                -175.999999999855,
                                27.8305299996688
                            ],
                            [
                                -175.990685000365,
                                27.8325819997468
                            ],
                            [
                                -175.974561999916,
                                27.8302450002084
                            ],
                            [
                                -175.938832999795,
                                27.8186229997233
                            ],
                            [
                                -175.932504000309,
                                27.8144509999615
                            ],
                            [
                                -175.924303999631,
                                27.8137100001803
                            ],
                            [
                                -175.921028000097,
                                27.8214390000528
                            ],
                            [
                                -175.955758999785,
                                27.8330550001079
                            ],
                            [
                                -175.978157000191,
                                27.8479770001518
                            ],
                            [
                                -175.988874000345,
                                27.8619040003805
                            ],
                            [
                                -175.990656000052,
                                27.8749999999311
                            ],
                            [
                                -175.99375400021,
                                27.8749999999311
                            ],
                            [
                                -175.992144000032,
                                27.8894989998193
                            ],
                            [
                                -175.981438999573,
                                27.9132180000878
                            ],
                            [
                                -175.966335000372,
                                27.9299110003619
                            ],
                            [
                                -175.935650000147,
                                27.9405810000054
                            ],
                            [
                                -175.90884399988,
                                27.9582679998951
                            ],
                            [
                                -175.875000000297,
                                27.9759130000114
                            ],
                            [
                                -175.875000000297,
                                27.9733009999254
                            ],
                            [
                                -175.806733000099,
                                28.0071920002339
                            ],
                            [
                                -175.786313999942,
                                28.008607000175
                            ],
                            [
                                -175.769684000228,
                                28.0074749998056
                            ],
                            [
                                -175.740038000444,
                                28.0000000003889
                            ],
                            [
                                -175.734928000426,
                                28.0000000003889
                            ],
                            [
                                -175.704353999631,
                                27.982737000069
                            ],
                            [
                                -175.681532999626,
                                27.9585589999078
                            ],
                            [
                                -175.670127999558,
                                27.9416010002615
                            ],
                            [
                                -175.664789999934,
                                27.9144179999839
                            ],
                            [
                                -175.664061999838,
                                27.8940620001878
                            ],
                            [
                                -175.665594000198,
                                27.8749999999311
                            ],
                            [
                                -175.676624000347,
                                27.8467650000166
                            ],
                            [
                                -175.72615899966,
                                27.7499999998234
                            ],
                            [
                                -175.734134000207,
                                27.7388550001201
                            ],
                            [
                                -175.746115000035,
                                27.7327169997648
                            ],
                            [
                                -175.74999999984,
                                27.7332539997836
                            ],
                            [
                                -175.779137999636,
                                27.7256850003253
                            ],
                            [
                                -175.802938999632,
                                27.7234849998756
                            ],
                            [
                                -175.817524999698,
                                27.7229720000217
                            ],
                            [
                                -175.845049999841,
                                27.7284569996374
                            ],
                            [
                                -175.875000000297,
                                27.7084119998007
                            ],
                            [
                                -175.875000000297,
                                27.7101570001919
                            ],
                            [
                                -175.895191999775,
                                27.6992130003871
                            ],
                            [
                                -175.939488000096,
                                27.6882309997935
                            ],
                            [
                                -175.969598000386,
                                27.6934019997727
                            ],
                            [
                                -175.999999999855,
                                27.7093059999082
                            ],
                            [
                                -175.999999999855,
                                27.7160929999446
                            ],
                            [
                                -176.024927000331,
                                27.738040000275
                            ],
                            [
                                -176.039627000199,
                                27.7582419997593
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -157.111501999943,
                                20.8750000000937
                            ],
                            [
                                -157.1172399996,
                                20.8916930000669
                            ],
                            [
                                -157.115058999684,
                                20.915581000288
                            ],
                            [
                                -157.111226999583,
                                20.9312299997371
                            ],
                            [
                                -157.099930000369,
                                20.9486030003531
                            ],
                            [
                                -157.088834999923,
                                20.95839700008
                            ],
                            [
                                -157.049489999611,
                                20.9790350000743
                            ],
                            [
                                -157.035267999594,
                                20.9833020000593
                            ],
                            [
                                -157.008341999675,
                                20.9832689996985
                            ],
                            [
                                -156.9898220003,
                                20.9850579995939
                            ],
                            [
                                -156.976221000248,
                                20.9840159995995
                            ],
                            [
                                -156.964616000164,
                                20.9811640002797
                            ],
                            [
                                -156.913322999637,
                                20.9779629996086
                            ],
                            [
                                -156.868474000019,
                                20.9643790002972
                            ],
                            [
                                -156.847561000118,
                                20.9512380000761
                            ],
                            [
                                -156.823329000126,
                                20.9308079995964
                            ],
                            [
                                -156.80424999981,
                                20.9069159995966
                            ],
                            [
                                -156.790950000057,
                                20.8949540002735
                            ],
                            [
                                -156.757458999753,
                                20.850770999594
                            ],
                            [
                                -156.74999999959,
                                20.8267129997689
                            ],
                            [
                                -156.74999999959,
                                20.8076049999461
                            ],
                            [
                                -156.769049999594,
                                20.7708499996649
                            ],
                            [
                                -156.782301999665,
                                20.7500000001839
                            ],
                            [
                                -156.795362999942,
                                20.7352509999229
                            ],
                            [
                                -156.816993000069,
                                20.7197930003899
                            ],
                            [
                                -156.845415000362,
                                20.7116770003827
                            ],
                            [
                                -156.861852999557,
                                20.6985999998052
                            ],
                            [
                                -156.875000000047,
                                20.6909649999576
                            ],
                            [
                                -156.887813999552,
                                20.6888390000313
                            ],
                            [
                                -156.89960100011,
                                20.6899349999602
                            ],
                            [
                                -156.91059000025,
                                20.6933120002491
                            ],
                            [
                                -156.928261999879,
                                20.691158000066
                            ],
                            [
                                -156.935899000318,
                                20.6922219997259
                            ],
                            [
                                -156.962838999582,
                                20.6854589999402
                            ],
                            [
                                -156.973806999806,
                                20.6867839998347
                            ],
                            [
                                -156.994434000376,
                                20.6935750001553
                            ],
                            [
                                -156.999999999605,
                                20.6968949997677
                            ],
                            [
                                -157.027277999876,
                                20.7287309996175
                            ],
                            [
                                -157.038144999818,
                                20.7500000001839
                            ],
                            [
                                -157.040267000182,
                                20.7620329999344
                            ],
                            [
                                -157.045183000032,
                                20.7704670001659
                            ],
                            [
                                -157.047608000345,
                                20.7900559996224
                            ],
                            [
                                -157.046874000401,
                                20.8031060000095
                            ],
                            [
                                -157.043948999597,
                                20.8100259999677
                            ],
                            [
                                -157.048068999579,
                                20.820545000174
                            ],
                            [
                                -157.075207000107,
                                20.827299000057
                            ],
                            [
                                -157.095181999851,
                                20.8471539997419
                            ],
                            [
                                -157.110131000139,
                                20.8694610002691
                            ],
                            [
                                -157.112677999752,
                                20.8750000000937
                            ],
                            [
                                -157.111501999943,
                                20.8750000000937
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -160.304503999566,
                                21.832623000138
                            ],
                            [
                                -160.30500700043,
                                21.8459219996067
                            ],
                            [
                                -160.30237100023,
                                21.8614909997687
                            ],
                            [
                                -160.296889999592,
                                21.8710109999564
                            ],
                            [
                                -160.297282000128,
                                21.8750000004125
                            ],
                            [
                                -160.29036100009,
                                21.8891789998371
                            ],
                            [
                                -160.285897000152,
                                21.9086710002004
                            ],
                            [
                                -160.279946999884,
                                21.9206379998114
                            ],
                            [
                                -160.264724999784,
                                21.9358919998183
                            ],
                            [
                                -160.248707999641,
                                21.9430079998434
                            ],
                            [
                                -160.242661000187,
                                21.9438690001316
                            ],
                            [
                                -160.222722000429,
                                21.9645529996165
                            ],
                            [
                                -160.199499999667,
                                21.981278000335
                            ],
                            [
                                -160.189159000155,
                                21.9869929998936
                            ],
                            [
                                -160.174010999675,
                                21.9914790000026
                            ],
                            [
                                -160.165830999986,
                                22.0200640003904
                            ],
                            [
                                -160.161977000144,
                                22.0244460001323
                            ],
                            [
                                -160.159544000333,
                                22.041324999705
                            ],
                            [
                                -160.151433000242,
                                22.0605149996641
                            ],
                            [
                                -160.140415999614,
                                22.0733220003144
                            ],
                            [
                                -160.123821999885,
                                22.0815560000017
                            ],
                            [
                                -160.112624000405,
                                22.0829189996355
                            ],
                            [
                                -160.100307000119,
                                22.0824970001159
                            ],
                            [
                                -160.075148999664,
                                22.0769940003674
                            ],
                            [
                                -160.063460999739,
                                22.0726759996522
                            ],
                            [
                                -160.056722000175,
                                22.0683449998333
                            ],
                            [
                                -160.044793000226,
                                22.0548890001808
                            ],
                            [
                                -160.0422170003,
                                22.0495379995937
                            ],
                            [
                                -160.027169000277,
                                22.0388579999921
                            ],
                            [
                                -160.018288000257,
                                22.0300100002437
                            ],
                            [
                                -160.004520999626,
                                22.0068919997103
                            ],
                            [
                                -160.001225999825,
                                21.9964099996473
                            ],
                            [
                                -159.997204999578,
                                21.9925100002336
                            ],
                            [
                                -159.995625000435,
                                21.9821410003679
                            ],
                            [
                                -159.999998999962,
                                21.9681020001015
                            ],
                            [
                                -160.006191000078,
                                21.9565750002818
                            ],
                            [
                                -160.014138999963,
                                21.9463859997029
                            ],
                            [
                                -160.021516999936,
                                21.9401079996224
                            ],
                            [
                                -160.027502000363,
                                21.9312139996821
                            ],
                            [
                                -160.022423000307,
                                21.9115749996553
                            ],
                            [
                                -160.022907999831,
                                21.8964590003879
                            ],
                            [
                                -160.024889999554,
                                21.8849770001714
                            ],
                            [
                                -160.031032000341,
                                21.8693639997467
                            ],
                            [
                                -160.046404000229,
                                21.8543050003945
                            ],
                            [
                                -160.059407999705,
                                21.845594000213
                            ],
                            [
                                -160.09298199985,
                                21.8298919999749
                            ],
                            [
                                -160.122015000064,
                                21.8249600003294
                            ],
                            [
                                -160.141938999753,
                                21.7990260001018
                            ],
                            [
                                -160.14396099966,
                                21.7874210001478
                            ],
                            [
                                -160.147362999591,
                                21.7822949998361
                            ],
                            [
                                -160.152055999858,
                                21.762954999928
                            ],
                            [
                                -160.16024999979,
                                21.7500000002196
                            ],
                            [
                                -160.170309999993,
                                21.7395509997318
                            ],
                            [
                                -160.186194999892,
                                21.7321459998497
                            ],
                            [
                                -160.208281000129,
                                21.7282410002113
                            ],
                            [
                                -160.218301000149,
                                21.7287969999106
                            ],
                            [
                                -160.26577799957,
                                21.7461799999096
                            ],
                            [
                                -160.269505999915,
                                21.7500000002196
                            ],
                            [
                                -160.267227999915,
                                21.7500000002196
                            ],
                            [
                                -160.278211000208,
                                21.7577809996113
                            ],
                            [
                                -160.293453000399,
                                21.7799699996808
                            ],
                            [
                                -160.29852599971,
                                21.7905000001753
                            ],
                            [
                                -160.303820999676,
                                21.8195779997353
                            ],
                            [
                                -160.304503999566,
                                21.832623000138
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -166.382900999752,
                                23.8296569996352
                            ],
                            [
                                -166.38447599977,
                                23.8319919996641
                            ],
                            [
                                -166.386532000011,
                                23.8463629997697
                            ],
                            [
                                -166.381928000331,
                                23.8750000001526
                            ],
                            [
                                -166.383287000439,
                                23.8750000001526
                            ],
                            [
                                -166.380462999918,
                                23.8839779995918
                            ],
                            [
                                -166.374999999722,
                                23.8882669997753
                            ],
                            [
                                -166.374999999722,
                                23.8853819997225
                            ],
                            [
                                -166.365619999935,
                                23.8942070003202
                            ],
                            [
                                -166.351371000153,
                                23.9028580000203
                            ],
                            [
                                -166.330052000371,
                                23.9082920004071
                            ],
                            [
                                -166.324743000161,
                                23.91795400023
                            ],
                            [
                                -166.307072000357,
                                23.9248829998541
                            ],
                            [
                                -166.28836600031,
                                23.9289769996424
                            ],
                            [
                                -166.288009999759,
                                23.9241720001718
                            ],
                            [
                                -166.26473000017,
                                23.9285210002988
                            ],
                            [
                                -166.250000000164,
                                23.9288599996402
                            ],
                            [
                                -166.250000000164,
                                23.9324300001582
                            ],
                            [
                                -166.233064000129,
                                23.9284910003288
                            ],
                            [
                                -166.204276000137,
                                23.9172140002498
                            ],
                            [
                                -166.18234700026,
                                23.9023250003454
                            ],
                            [
                                -166.170446999725,
                                23.8884520001175
                            ],
                            [
                                -166.165935999931,
                                23.8750000001526
                            ],
                            [
                                -166.168302999619,
                                23.8750000001526
                            ],
                            [
                                -166.165521999654,
                                23.8648160001133
                            ],
                            [
                                -166.164838999764,
                                23.851018000342
                            ],
                            [
                                -166.16973899972,
                                23.8374219996718
                            ],
                            [
                                -166.164856000381,
                                23.835406999644
                            ],
                            [
                                -166.153478000077,
                                23.8189410003193
                            ],
                            [
                                -166.148171000416,
                                23.7854789996886
                            ],
                            [
                                -166.142591999869,
                                23.7843709996925
                            ],
                            [
                                -166.124999999707,
                                23.7661139996535
                            ],
                            [
                                -166.124999999707,
                                23.7729069997933
                            ],
                            [
                                -166.118498999794,
                                23.7666689998061
                            ],
                            [
                                -166.116661999835,
                                23.754221999899
                            ],
                            [
                                -166.114577000179,
                                23.7499999997039
                            ],
                            [
                                -166.106779999907,
                                23.7425219997133
                            ],
                            [
                                -166.104626999581,
                                23.7289290000828
                            ],
                            [
                                -166.105881000107,
                                23.7135359999277
                            ],
                            [
                                -166.113494000256,
                                23.6971479998068
                            ],
                            [
                                -166.124999999707,
                                23.6860960002826
                            ],
                            [
                                -166.124999999707,
                                23.6901390000517
                            ],
                            [
                                -166.132954000338,
                                23.6847030003545
                            ],
                            [
                                -166.124999999707,
                                23.6740830002829
                            ],
                            [
                                -166.124999999707,
                                23.670731999936
                            ],
                            [
                                -166.117690000405,
                                23.6645959998458
                            ],
                            [
                                -166.113842000411,
                                23.6523360002575
                            ],
                            [
                                -166.113135000231,
                                23.6369819996141
                            ],
                            [
                                -166.117136000386,
                                23.6249999996932
                            ],
                            [
                                -166.110682000329,
                                23.6249999996932
                            ],
                            [
                                -166.112626000418,
                                23.6166249999819
                            ],
                            [
                                -166.116780999661,
                                23.6112539997513
                            ],
                            [
                                -166.124999999707,
                                23.6071130003219
                            ],
                            [
                                -166.124999999707,
                                23.6092810002793
                            ],
                            [
                                -166.131313000198,
                                23.6030110000064
                            ],
                            [
                                -166.151154999872,
                                23.5904639998219
                            ],
                            [
                                -166.172217999561,
                                23.587089999999
                            ],
                            [
                                -166.194409000278,
                                23.5895310001482
                            ],
                            [
                                -166.211058000258,
                                23.5998099998142
                            ],
                            [
                                -166.22423800036,
                                23.6147910000591
                            ],
                            [
                                -166.228861000307,
                                23.6249999996932
                            ],
                            [
                                -166.22484000006,
                                23.6249999996932
                            ],
                            [
                                -166.232284999979,
                                23.6415800000064
                            ],
                            [
                                -166.223963999825,
                                23.6725329997684
                            ],
                            [
                                -166.205415000137,
                                23.691600999843
                            ],
                            [
                                -166.214810999818,
                                23.6966859999629
                            ],
                            [
                                -166.222827000374,
                                23.7048780000923
                            ],
                            [
                                -166.2267819996,
                                23.7274439996041
                            ],
                            [
                                -166.231495999784,
                                23.7236089996218
                            ],
                            [
                                -166.241222000076,
                                23.7196720001699
                            ],
                            [
                                -166.250000000164,
                                23.7181709997917
                            ],
                            [
                                -166.250000000164,
                                23.7138979998341
                            ],
                            [
                                -166.265588999787,
                                23.7131399997202
                            ],
                            [
                                -166.285990000401,
                                23.7181200003301
                            ],
                            [
                                -166.302606999696,
                                23.7286659997229
                            ],
                            [
                                -166.315822999782,
                                23.740120000204
                            ],
                            [
                                -166.319700999915,
                                23.7499999997039
                            ],
                            [
                                -166.316695999644,
                                23.7499999997039
                            ],
                            [
                                -166.323742000253,
                                23.7709740000851
                            ],
                            [
                                -166.319151999919,
                                23.7910470001
                            ],
                            [
                                -166.313574000095,
                                23.8037360001834
                            ],
                            [
                                -166.322572000291,
                                23.7990520001825
                            ],
                            [
                                -166.336821999898,
                                23.7996399997478
                            ],
                            [
                                -166.356342000255,
                                23.8059449999479
                            ],
                            [
                                -166.369228999555,
                                23.8155359999811
                            ],
                            [
                                -166.374999999722,
                                23.824624000043
                            ],
                            [
                                -166.374999999722,
                                23.8216409998093
                            ],
                            [
                                -166.378839000395,
                                23.8236349996117
                            ],
                            [
                                -166.382900999752,
                                23.8296569996352
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -178.433226999635,
                                28.3977709999647
                            ],
                            [
                                -178.440485999782,
                                28.4051720003298
                            ],
                            [
                                -178.44359300016,
                                28.4189859997567
                            ],
                            [
                                -178.438335999829,
                                28.444087999846
                            ],
                            [
                                -178.427577999667,
                                28.4661019996708
                            ],
                            [
                                -178.395769000409,
                                28.4999999998544
                            ],
                            [
                                -178.386433000104,
                                28.4999999998544
                            ],
                            [
                                -178.375000000448,
                                28.5078220003464
                            ],
                            [
                                -178.375000000448,
                                28.5100839999301
                            ],
                            [
                                -178.340540000023,
                                28.517268999802
                            ],
                            [
                                -178.315121000351,
                                28.5163339999982
                            ],
                            [
                                -178.30434800012,
                                28.513262999914
                            ],
                            [
                                -178.291516000173,
                                28.5072069996163
                            ],
                            [
                                -178.281065000157,
                                28.4999999998544
                            ],
                            [
                                -178.288827000269,
                                28.4999999998544
                            ],
                            [
                                -178.271165999612,
                                28.4885650001322
                            ],
                            [
                                -178.257040999679,
                                28.4765350001008
                            ],
                            [
                                -178.249999999991,
                                28.4666739996404
                            ],
                            [
                                -178.249999999991,
                                28.4681780000008
                            ],
                            [
                                -178.238469000425,
                                28.4561499998458
                            ],
                            [
                                -178.232222999882,
                                28.4273349999652
                            ],
                            [
                                -178.230503999924,
                                28.3977010000269
                            ],
                            [
                                -178.232493000218,
                                28.3836329997623
                            ],
                            [
                                -178.237070999959,
                                28.3750000002727
                            ],
                            [
                                -178.233017999925,
                                28.3750000002727
                            ],
                            [
                                -178.238859000412,
                                28.3619810003467
                            ],
                            [
                                -178.249999999991,
                                28.3471510000859
                            ],
                            [
                                -178.249999999991,
                                28.3494210000971
                            ],
                            [
                                -178.257526000101,
                                28.3428319996164
                            ],
                            [
                                -178.270262999614,
                                28.3358879997484
                            ],
                            [
                                -178.288895000041,
                                28.3288100001691
                            ],
                            [
                                -178.301402000299,
                                28.3283360003386
                            ],
                            [
                                -178.375000000448,
                                28.3375399999268
                            ],
                            [
                                -178.375000000448,
                                28.3353570003074
                            ],
                            [
                                -178.3956100004,
                                28.3419009998276
                            ],
                            [
                                -178.411477999683,
                                28.3606609998117
                            ],
                            [
                                -178.415882000244,
                                28.3800859997396
                            ],
                            [
                                -178.433226999635,
                                28.3977709999647
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -174.065943999726,
                                26.0394280003976
                            ],
                            [
                                -174.067373999979,
                                26.059614000001
                            ],
                            [
                                -174.059123000146,
                                26.0941509998786
                            ],
                            [
                                -174.052469000072,
                                26.1129870000641
                            ],
                            [
                                -174.049620000334,
                                26.1165779999403
                            ],
                            [
                                -174.031114000304,
                                26.1290699999729
                            ],
                            [
                                -174.010978000004,
                                26.1365859996703
                            ],
                            [
                                -173.999999999734,
                                26.1354960002189
                            ],
                            [
                                -173.999999999734,
                                26.136879999711
                            ],
                            [
                                -173.99188000032,
                                26.13660799988
                            ],
                            [
                                -173.981427999581,
                                26.1332240003603
                            ],
                            [
                                -173.957916000188,
                                26.135689999704
                            ],
                            [
                                -173.950251999985,
                                26.1331519999915
                            ],
                            [
                                -173.936105000311,
                                26.1249999997463
                            ],
                            [
                                -173.938901000345,
                                26.1249999997463
                            ],
                            [
                                -173.929088000014,
                                26.1201390003114
                            ],
                            [
                                -173.918570000051,
                                26.1097410003991
                            ],
                            [
                                -173.913424000048,
                                26.0993989998821
                            ],
                            [
                                -173.903829000175,
                                26.0667600004023
                            ],
                            [
                                -173.903357999997,
                                26.0600559998394
                            ],
                            [
                                -173.913145000389,
                                26.0359979996137
                            ],
                            [
                                -173.925968000115,
                                26.0196270000463
                            ],
                            [
                                -173.94276000021,
                                26.0107140002746
                            ],
                            [
                                -173.964921999717,
                                26.0066390001358
                            ],
                            [
                                -173.977044999834,
                                26.0000000002371
                            ],
                            [
                                -173.999999999734,
                                25.9928779998902
                            ],
                            [
                                -173.999999999734,
                                25.9902120003295
                            ],
                            [
                                -174.02720500021,
                                25.9932779999392
                            ],
                            [
                                -174.037538000225,
                                26.0000000002371
                            ],
                            [
                                -174.043109000375,
                                26.0000000002371
                            ],
                            [
                                -174.05918300042,
                                26.0207189999402
                            ],
                            [
                                -174.065943999726,
                                26.0394280003976
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -171.807046999676,
                                25.7671330003608
                            ],
                            [
                                -171.809629000348,
                                25.7813500003166
                            ],
                            [
                                -171.806464000244,
                                25.7933600001149
                            ],
                            [
                                -171.800148000278,
                                25.8058220000133
                            ],
                            [
                                -171.786978000222,
                                25.8223009997336
                            ],
                            [
                                -171.768119999839,
                                25.8336809997408
                            ],
                            [
                                -171.749999999598,
                                25.837944000168
                            ],
                            [
                                -171.749999999598,
                                25.833466999994
                            ],
                            [
                                -171.73152299988,
                                25.8346499995995
                            ],
                            [
                                -171.710623000397,
                                25.8332110000834
                            ],
                            [
                                -171.688276000045,
                                25.823767999748
                            ],
                            [
                                -171.676628000303,
                                25.8109069999012
                            ],
                            [
                                -171.669361999585,
                                25.7939079996023
                            ],
                            [
                                -171.667922000185,
                                25.7812169997246
                            ],
                            [
                                -171.677680000264,
                                25.7500000002385
                            ],
                            [
                                -171.674070999746,
                                25.7500000002385
                            ],
                            [
                                -171.680474999575,
                                25.7367519996504
                            ],
                            [
                                -171.686860000035,
                                25.7278789997501
                            ],
                            [
                                -171.699845000144,
                                25.7168580000774
                            ],
                            [
                                -171.718432000365,
                                25.7094950000116
                            ],
                            [
                                -171.737490999691,
                                25.7065329996035
                            ],
                            [
                                -171.749999999598,
                                25.7091909996478
                            ],
                            [
                                -171.770150000142,
                                25.7181990001458
                            ],
                            [
                                -171.797026999656,
                                25.7405390001767
                            ],
                            [
                                -171.807046999676,
                                25.7671330003608
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -161.985444999804,
                                23.0411310000284
                            ],
                            [
                                -161.987554999574,
                                23.0699240001833
                            ],
                            [
                                -161.982524000097,
                                23.0912749996445
                            ],
                            [
                                -161.965449000144,
                                23.1049869996008
                            ],
                            [
                                -161.944661999741,
                                23.1118969995892
                            ],
                            [
                                -161.919439999712,
                                23.1139880001397
                            ],
                            [
                                -161.887575000378,
                                23.1103840002564
                            ],
                            [
                                -161.875000000349,
                                23.1016369996491
                            ],
                            [
                                -161.875000000349,
                                23.0975470002443
                            ],
                            [
                                -161.870439000327,
                                23.095352000295
                            ],
                            [
                                -161.863001999905,
                                23.0851830004093
                            ],
                            [
                                -161.859955999626,
                                23.0756590000685
                            ],
                            [
                                -161.858059000291,
                                23.0608060002792
                            ],
                            [
                                -161.863341999663,
                                23.0366020001665
                            ],
                            [
                                -161.875000000349,
                                23.0192419999167
                            ],
                            [
                                -161.894219000407,
                                23.0094299999081
                            ],
                            [
                                -161.931896999741,
                                23.0053129999315
                            ],
                            [
                                -161.955171000381,
                                23.0091689999914
                            ],
                            [
                                -161.976060999818,
                                23.0255639996724
                            ],
                            [
                                -161.985444999804,
                                23.0411310000284
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -164.764730000079,
                                23.5663799997115
                            ],
                            [
                                -164.764810000445,
                                23.5817210000939
                            ],
                            [
                                -164.763449999614,
                                23.5867020002022
                            ],
                            [
                                -164.758091999898,
                                23.5989519999015
                            ],
                            [
                                -164.753791999992,
                                23.6035170002536
                            ],
                            [
                                -164.750000000073,
                                23.6051349996228
                            ],
                            [
                                -164.740060000419,
                                23.6143940002119
                            ],
                            [
                                -164.724629999907,
                                23.6210699998718
                            ],
                            [
                                -164.709792999771,
                                23.6232340002638
                            ],
                            [
                                -164.682630000026,
                                23.6228059998835
                            ],
                            [
                                -164.668762999836,
                                23.6166239999418
                            ],
                            [
                                -164.654083000059,
                                23.6070690002962
                            ],
                            [
                                -164.644608999662,
                                23.5945400002457
                            ],
                            [
                                -164.640019000226,
                                23.5761109996615
                            ],
                            [
                                -164.645347999629,
                                23.5515139996856
                            ],
                            [
                                -164.65402399961,
                                23.5352210002152
                            ],
                            [
                                -164.673322000209,
                                23.5210849998761
                            ],
                            [
                                -164.685217999647,
                                23.5171490003317
                            ],
                            [
                                -164.701300000088,
                                23.5170019999496
                            ],
                            [
                                -164.723040999645,
                                23.5217570001515
                            ],
                            [
                                -164.750000000073,
                                23.5361459999392
                            ],
                            [
                                -164.750000000073,
                                23.5410690000149
                            ],
                            [
                                -164.762610000263,
                                23.5585090003069
                            ],
                            [
                                -164.764730000079,
                                23.5663799997115
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -160.597602000352,
                                21.6403980001562
                            ],
                            [
                                -160.600364999875,
                                21.6492689997795
                            ],
                            [
                                -160.599767000374,
                                21.6676499996166
                            ],
                            [
                                -160.591868999819,
                                21.6858580003355
                            ],
                            [
                                -160.578265000293,
                                21.7002499997138
                            ],
                            [
                                -160.562727999651,
                                21.7088919998028
                            ],
                            [
                                -160.550309000155,
                                21.7120639999157
                            ],
                            [
                                -160.531740000376,
                                21.7119980001604
                            ],
                            [
                                -160.508791000324,
                                21.7032379997625
                            ],
                            [
                                -160.499999999817,
                                21.6970249997239
                            ],
                            [
                                -160.494144999984,
                                21.6905369999772
                            ],
                            [
                                -160.490173999965,
                                21.6844920002365
                            ],
                            [
                                -160.483196000026,
                                21.665186000131
                            ],
                            [
                                -160.482907000321,
                                21.6403109999083
                            ],
                            [
                                -160.484924000205,
                                21.6324299997702
                            ],
                            [
                                -160.496243000058,
                                21.6188529996936
                            ],
                            [
                                -160.52036600027,
                                21.6049300002908
                            ],
                            [
                                -160.530828999981,
                                21.6010639999326
                            ],
                            [
                                -160.546215999938,
                                21.6003839996886
                            ],
                            [
                                -160.570728000312,
                                21.6066640002933
                            ],
                            [
                                -160.590748000261,
                                21.6249999998037
                            ],
                            [
                                -160.589481000215,
                                21.6249999998037
                            ],
                            [
                                -160.597602000352,
                                21.6403980001562
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -168.050344999695,
                                24.9744870003988
                            ],
                            [
                                -168.058387000189,
                                24.9878849998298
                            ],
                            [
                                -168.057977000111,
                                25.0026129996809
                            ],
                            [
                                -168.054663000043,
                                25.0152770002766
                            ],
                            [
                                -168.050606999636,
                                25.0258679999235
                            ],
                            [
                                -168.046577000066,
                                25.0312719999748
                            ],
                            [
                                -168.026989999762,
                                25.0448019997144
                            ],
                            [
                                -168.013203999763,
                                25.0491890000495
                            ],
                            [
                                -168.00000000027,
                                25.050253000204
                            ],
                            [
                                -168.00000000027,
                                25.052240000124
                            ],
                            [
                                -167.988939999984,
                                25.0490490002841
                            ],
                            [
                                -167.972527000005,
                                25.0412239997508
                            ],
                            [
                                -167.95244900033,
                                25.0235379997311
                            ],
                            [
                                -167.946338000404,
                                25.0000000003888
                            ],
                            [
                                -167.944592999609,
                                25.0000000003888
                            ],
                            [
                                -167.944204000344,
                                24.9898070000761
                            ],
                            [
                                -167.948970000406,
                                24.9736399997503
                            ],
                            [
                                -167.953685000414,
                                24.9643860003878
                            ],
                            [
                                -167.966766999709,
                                24.9548870000628
                            ],
                            [
                                -167.983537000063,
                                24.9492650003852
                            ],
                            [
                                -168.00000000027,
                                24.9481070002987
                            ],
                            [
                                -168.00000000027,
                                24.9507049999394
                            ],
                            [
                                -168.023590000379,
                                24.9544770001504
                            ],
                            [
                                -168.037751000297,
                                24.9597150002668
                            ],
                            [
                                -168.050344999695,
                                24.9744870003988
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -170.684417999944,
                                25.50226999965
                            ],
                            [
                                -170.684096999553,
                                25.5137219997877
                            ],
                            [
                                -170.681702000276,
                                25.5228770002388
                            ],
                            [
                                -170.671325999705,
                                25.5394380002291
                            ],
                            [
                                -170.663452000339,
                                25.5463599996793
                            ],
                            [
                                -170.653640999658,
                                25.5519239996073
                            ],
                            [
                                -170.633758999801,
                                25.5569360002799
                            ],
                            [
                                -170.621779999623,
                                25.5567090002064
                            ],
                            [
                                -170.61157200018,
                                25.5546069998291
                            ],
                            [
                                -170.594115999563,
                                25.5459399998654
                            ],
                            [
                                -170.58027699986,
                                25.5309960003407
                            ],
                            [
                                -170.575150000123,
                                25.5195770002288
                            ],
                            [
                                -170.573319000012,
                                25.5103570002616
                            ],
                            [
                                -170.573502000311,
                                25.5005529999537
                            ],
                            [
                                -170.576171999947,
                                25.4896490003634
                            ],
                            [
                                -170.586120999823,
                                25.4738260003467
                            ],
                            [
                                -170.602950999553,
                                25.4614940001627
                            ],
                            [
                                -170.623733999758,
                                25.4559269998794
                            ],
                            [
                                -170.644699000436,
                                25.4578380002845
                            ],
                            [
                                -170.654465000013,
                                25.4614620003652
                            ],
                            [
                                -170.664413999888,
                                25.4674490002071
                            ],
                            [
                                -170.672180000198,
                                25.4746989998238
                            ],
                            [
                                -170.678466999851,
                                25.4834689997221
                            ],
                            [
                                -170.684417999944,
                                25.50226999965
                            ]
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 19.8281671,
            "name": "Hawaii",
            "intptlon": -155.4950421,
            "geo_point_2d": [
                20.9944055917,
                -158.108287988
            ],
            "geoid": "15",
            "mtfcc": "G4000",
            "region": 4
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -158.108287988,
                20.9944055917
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "afdb98dcec2877da5fa07b207186a0d5a7773369",
        "fields": {
            "arealand": "1299859523",
            "objectid": 48,
            "basename": "Kansas",
            "stusab": "KS",
            "statens": "00481813",
            "centlon": -98.3786217,
            "state": "20",
            "gid": 14,
            "centlat": 38.4938959,
            "division": 4,
            "areawater": "1346231756",
            "oid": "272356056",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -102.045211999716,
                            38.6975669996845
                        ],
                        [
                            -102.051743999591,
                            40.0030780002988
                        ],
                        [
                            -99.7568349995633,
                            40.0013419999843
                        ],
                        [
                            -98.2740169995552,
                            40.0026619997989
                        ],
                        [
                            -95.3082900002008,
                            39.9999980002159
                        ],
                        [
                            -95.3076609998389,
                            39.9906900000532
                        ],
                        [
                            -95.3023399999333,
                            39.9845550002339
                        ],
                        [
                            -95.2698860004202,
                            39.9693959996576
                        ],
                        [
                            -95.2576519999743,
                            39.9548860003206
                        ],
                        [
                            -95.2502539999104,
                            39.9486439999423
                        ],
                        [
                            -95.2367609998141,
                            39.9439309999293
                        ],
                        [
                            -95.2164399995662,
                            39.9439530002111
                        ],
                        [
                            -95.2052660003759,
                            39.9397499997955
                        ],
                        [
                            -95.2019239998209,
                            39.9361219999244
                        ],
                        [
                            -95.2003820003133,
                            39.9310309997358
                        ],
                        [
                            -95.2005820003298,
                            39.9251239998765
                        ],
                        [
                            -95.206063000069,
                            39.9120609999844
                        ],
                        [
                            -95.2053879996763,
                            39.9083100002371
                        ],
                        [
                            -95.2017499997437,
                            39.9043769999646
                        ],
                        [
                            -95.191240000177,
                            39.900424000076
                        ],
                        [
                            -95.1812219998066,
                            39.8999960002521
                        ],
                        [
                            -95.1743030003168,
                            39.9009910002266
                        ],
                        [
                            -95.1598340004272,
                            39.9069840001758
                        ],
                        [
                            -95.1496570000482,
                            39.9059479998707
                        ],
                        [
                            -95.1428380001175,
                            39.9004020002758
                        ],
                        [
                            -95.1432540000441,
                            39.8877750000207
                        ],
                        [
                            -95.1406010001256,
                            39.881688000294
                        ],
                        [
                            -95.1347470001176,
                            39.8768519999617
                        ],
                        [
                            -95.1245289997307,
                            39.8728570002614
                        ],
                        [
                            -95.1108490000365,
                            39.8709889998226
                        ],
                        [
                            -95.0901579998928,
                            39.8631399998327
                        ],
                        [
                            -95.0815599997297,
                            39.8615390000462
                        ],
                        [
                            -95.0394030003888,
                            39.8648319996734
                        ],
                        [
                            -95.0328609995693,
                            39.8673209998627
                        ],
                        [
                            -95.0267470001685,
                            39.8722730003257
                        ],
                        [
                            -95.0248290000189,
                            39.8781659998908
                        ],
                        [
                            -95.0259470001024,
                            39.8867470000663
                        ],
                        [
                            -95.023722999631,
                            39.8920349999431
                        ],
                        [
                            -95.0176059998572,
                            39.898253999997
                        ],
                        [
                            -95.0099359998068,
                            39.9006360001066
                        ],
                        [
                            -94.9870589997248,
                            39.8963200002626
                        ],
                        [
                            -94.9621270001242,
                            39.9012630001845
                        ],
                        [
                            -94.9551779995989,
                            39.901288000238
                        ],
                        [
                            -94.9400020004278,
                            39.8964720002737
                        ],
                        [
                            -94.9301599997845,
                            39.8899339998118
                        ],
                        [
                            -94.927358999728,
                            39.8839659998699
                        ],
                        [
                            -94.9278259997083,
                            39.8778169997891
                        ],
                        [
                            -94.9304710001292,
                            39.8735619999225
                        ],
                        [
                            -94.9417969996555,
                            39.8634170000926
                        ],
                        [
                            -94.9429599999448,
                            39.8594570000804
                        ],
                        [
                            -94.9405990001044,
                            39.8530179999443
                        ],
                        [
                            -94.9261500003063,
                            39.8413220003055
                        ],
                        [
                            -94.9167660003207,
                            39.8358069999963
                        ],
                        [
                            -94.9063400004196,
                            39.8337360002895
                        ],
                        [
                            -94.8914809996443,
                            39.8346440002694
                        ],
                        [
                            -94.8848259997452,
                            39.8324179996716
                        ],
                        [
                            -94.8773330001451,
                            39.824473999802
                        ],
                        [
                            -94.8759439998999,
                            39.8132939999299
                        ],
                        [
                            -94.8776439995914,
                            39.8035940000506
                        ],
                        [
                            -94.8810220001313,
                            39.7972620001795
                        ],
                        [
                            -94.8846519996679,
                            39.7939969999984
                        ],
                        [
                            -94.8931800004092,
                            39.7909149999878
                        ],
                        [
                            -94.9282299999393,
                            39.7889380001188
                        ],
                        [
                            -94.9349969999914,
                            39.783691999912
                        ],
                        [
                            -94.9345729996689,
                            39.7729609999432
                        ],
                        [
                            -94.9206929999583,
                            39.7629379998281
                        ],
                        [
                            -94.9109490001226,
                            39.7591780001164
                        ],
                        [
                            -94.8982629997651,
                            39.7616219998377
                        ],
                        [
                            -94.8821610001311,
                            39.7710429997473
                        ],
                        [
                            -94.8696549996987,
                            39.7728989998014
                        ],
                        [
                            -94.865220000073,
                            39.7700699998443
                        ],
                        [
                            -94.8606389999591,
                            39.7629829997541
                        ],
                        [
                            -94.8590349996289,
                            39.7541159997548
                        ],
                        [
                            -94.862735000384,
                            39.7421950001042
                        ],
                        [
                            -94.8677399999221,
                            39.736041999714
                        ],
                        [
                            -94.8756340002785,
                            39.730491999915
                        ],
                        [
                            -94.8841429998549,
                            39.7267940002018
                        ],
                        [
                            -94.8993159995512,
                            39.7240420000992
                        ],
                        [
                            -94.9183420001636,
                            39.7287969999665
                        ],
                        [
                            -94.9418040002265,
                            39.7433670001252
                        ],
                        [
                            -94.9475410000587,
                            39.7453369997547
                        ],
                        [
                            -94.9552859995539,
                            39.745689000107
                        ],
                        [
                            -94.9639580002352,
                            39.7403609999727
                        ],
                        [
                            -94.9712059996129,
                            39.7293049997846
                        ],
                        [
                            -94.9684530001353,
                            39.7074019998261
                        ],
                        [
                            -94.968981000215,
                            39.6929540000495
                        ],
                        [
                            -94.9706769997084,
                            39.6872390000346
                        ],
                        [
                            -94.9758129996661,
                            39.6817539999776
                        ],
                        [
                            -94.9815649995669,
                            39.6786360002614
                        ],
                        [
                            -95.0153100003141,
                            39.6742620002247
                        ],
                        [
                            -95.0276440003191,
                            39.6654540002416
                        ],
                        [
                            -95.0495179999453,
                            39.6378760002062
                        ],
                        [
                            -95.0542670002886,
                            39.6277450002411
                        ],
                        [
                            -95.0542360003265,
                            39.6172909997088
                        ],
                        [
                            -95.0464449999022,
                            39.6016060001644
                        ],
                        [
                            -95.0471649996025,
                            39.595116999788
                        ],
                        [
                            -95.050589999998,
                            39.5875629997899
                        ],
                        [
                            -95.0568969997426,
                            39.5805669999322
                        ],
                        [
                            -95.0670950000381,
                            39.5765019998657
                        ],
                        [
                            -95.0752100003274,
                            39.5764290002428
                        ],
                        [
                            -95.0921499996623,
                            39.5811160002597
                        ],
                        [
                            -95.1049480001728,
                            39.5766039997981
                        ],
                        [
                            -95.1074739998697,
                            39.5737430003398
                        ],
                        [
                            -95.1132840003951,
                            39.5581520003265
                        ],
                        [
                            -95.1135570002066,
                            39.5539409997196
                        ],
                        [
                            -95.1103679998126,
                            39.5445619998362
                        ],
                        [
                            -95.1045869995995,
                            39.5351440002873
                        ],
                        [
                            -95.0875900003632,
                            39.5203549996568
                        ],
                        [
                            -95.0774409995732,
                            39.5135519998245
                        ],
                        [
                            -95.0593000003141,
                            39.5064729998846
                        ],
                        [
                            -95.0544589999093,
                            39.5026529997612
                        ],
                        [
                            -95.0505520003632,
                            39.4975140001371
                        ],
                        [
                            -95.0476709999407,
                            39.477535999993
                        ],
                        [
                            -95.0446680002179,
                            39.4712559998557
                        ],
                        [
                            -95.0375000003078,
                            39.4636889997473
                        ],
                        [
                            -95.0157630000506,
                            39.4530990001726
                        ],
                        [
                            -94.9901719999508,
                            39.4461919998082
                        ],
                        [
                            -94.9821439997001,
                            39.4405520001272
                        ],
                        [
                            -94.9787979998456,
                            39.4362410002315
                        ],
                        [
                            -94.9766060000595,
                            39.4267010001412
                        ],
                        [
                            -94.9729520002334,
                            39.4217049998493
                        ],
                        [
                            -94.9644889997895,
                            39.4167179999034
                        ],
                        [
                            -94.9548169999238,
                            39.4138439998205
                        ],
                        [
                            -94.9478640000989,
                            39.4086040002224
                        ],
                        [
                            -94.9462930002789,
                            39.405646000023
                        ],
                        [
                            -94.9462270001567,
                            39.3956479998422
                        ],
                        [
                            -94.9420390004032,
                            39.3894989998811
                        ],
                        [
                            -94.9336520001272,
                            39.3855460002104
                        ],
                        [
                            -94.9229940004225,
                            39.3848830002542
                        ],
                        [
                            -94.9158590001244,
                            39.3863480000409
                        ],
                        [
                            -94.9001320004092,
                            39.3933330001254
                        ],
                        [
                            -94.8918449996924,
                            39.3933130002025
                        ],
                        [
                            -94.8850259997617,
                            39.3898010001049
                        ],
                        [
                            -94.8792810004319,
                            39.3797800002468
                        ],
                        [
                            -94.8790880000881,
                            39.3757029997299
                        ],
                        [
                            -94.8813600002401,
                            39.3703829998673
                        ],
                        [
                            -94.8871449997528,
                            39.3654309999077
                        ],
                        [
                            -94.9024970004476,
                            39.3603829999577
                        ],
                        [
                            -94.9100169998119,
                            39.352542999778
                        ],
                        [
                            -94.9103530002709,
                            39.3447350003366
                        ],
                        [
                            -94.9061260001593,
                            39.3147210003047
                        ],
                        [
                            -94.8985160003834,
                            39.2984500001641
                        ],
                        [
                            -94.8840929996262,
                            39.284511999931
                        ],
                        [
                            -94.8463199998574,
                            39.2684809999892
                        ],
                        [
                            -94.8305529999592,
                            39.2551349999354
                        ],
                        [
                            -94.8274790000912,
                            39.2498959997671
                        ],
                        [
                            -94.8256630000489,
                            39.2417290000234
                        ],
                        [
                            -94.8277910002608,
                            39.2340009999887
                        ],
                        [
                            -94.8348960004217,
                            39.2238420001529
                        ],
                        [
                            -94.834729999842,
                            39.2207549998907
                        ],
                        [
                            -94.8316790004386,
                            39.2159380002556
                        ],
                        [
                            -94.8219349997046,
                            39.2096689997826
                        ],
                        [
                            -94.8115430001387,
                            39.2069059997831
                        ],
                        [
                            -94.7829509999659,
                            39.2071620000742
                        ],
                        [
                            -94.776398000174,
                            39.201810000245
                        ],
                        [
                            -94.7702500004381,
                            39.1898269998321
                        ],
                        [
                            -94.762858000222,
                            39.17999099978
                        ],
                        [
                            -94.7522859998327,
                            39.1732499997999
                        ],
                        [
                            -94.7407770000082,
                            39.1699869998665
                        ],
                        [
                            -94.7236369997585,
                            39.1690030001262
                        ],
                        [
                            -94.7141370003202,
                            39.1704030000326
                        ],
                        [
                            -94.6887880000698,
                            39.183227999689
                        ],
                        [
                            -94.6782399999699,
                            39.1841690001333
                        ],
                        [
                            -94.6691100004276,
                            39.1822039998811
                        ],
                        [
                            -94.6615769997462,
                            39.1773589999823
                        ],
                        [
                            -94.6591620003774,
                            39.1741260001636
                        ],
                        [
                            -94.6613969998211,
                            39.162742000334
                        ],
                        [
                            -94.6592100000579,
                            39.1578410002073
                        ],
                        [
                            -94.6557870002105,
                            39.155680000263
                        ],
                        [
                            -94.6506470000549,
                            39.1540919998588
                        ],
                        [
                            -94.6355520001764,
                            39.154082000177
                        ],
                        [
                            -94.6086570002203,
                            39.1610440003462
                        ],
                        [
                            -94.5938460000239,
                            39.15682800013
                        ],
                        [
                            -94.5886580001877,
                            39.1511629999137
                        ],
                        [
                            -94.590287999559,
                            39.138121000174
                        ],
                        [
                            -94.6051160003721,
                            39.1232590002943
                        ],
                        [
                            -94.608519000128,
                            39.1162439998017
                        ],
                        [
                            -94.607132000431,
                            39.1006749997677
                        ],
                        [
                            -94.6084570002037,
                            38.9792980001279
                        ],
                        [
                            -94.6076250003505,
                            38.8275599998704
                        ],
                        [
                            -94.6133560003349,
                            38.4579670000888
                        ],
                        [
                            -94.612474000253,
                            38.2210399997308
                        ],
                        [
                            -94.6141110001953,
                            37.9737970000539
                        ],
                        [
                            -94.6176689997619,
                            37.776192999823
                        ],
                        [
                            -94.6167239999308,
                            37.500123000328
                        ],
                        [
                            -94.617964000213,
                            36.998905000209
                        ],
                        [
                            -97.6368439996201,
                            36.9990849998713
                        ],
                        [
                            -98.3004649996226,
                            36.9976839997532
                        ],
                        [
                            -98.7937250002632,
                            36.9994610002303
                        ],
                        [
                            -99.5580040002273,
                            36.9995289997991
                        ],
                        [
                            -100.107890999923,
                            37.0023119997524
                        ],
                        [
                            -102.042089000342,
                            36.9930159998508
                        ],
                        [
                            -102.041604999745,
                            37.6892419999362
                        ],
                        [
                            -102.044514999582,
                            37.9845749999505
                        ],
                        [
                            -102.045211999716,
                            38.6975669996845
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 38.4985464,
            "name": "Kansas",
            "intptlon": -98.3834298,
            "geo_point_2d": [
                38.4846312307,
                -98.380027191
            ],
            "geoid": "20",
            "mtfcc": "G4000",
            "region": 2
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -98.380027191,
                38.4846312307
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "12be7d88f8c299726d3a61eb123138313ff9f719",
        "fields": {
            "arealand": "752226110",
            "objectid": 12,
            "basename": "Delaware",
            "stusab": "DE",
            "statens": "01779781",
            "centlon": -75.4514105,
            "state": "10",
            "gid": 46,
            "centlat": 38.9932575,
            "division": 5,
            "areawater": "1398722170",
            "oid": "-1754043328",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -75.7600739996342,
                            39.2968189998354
                        ],
                        [
                            -75.788701999792,
                            39.6504860002236
                        ],
                        [
                            -75.7885960003851,
                            39.7221989997067
                        ],
                        [
                            -75.7737860000136,
                            39.7222000001892
                        ],
                        [
                            -75.7626499995598,
                            39.7437640002308
                        ],
                        [
                            -75.7474939995819,
                            39.7642380001896
                        ],
                        [
                            -75.7294050002014,
                            39.7823090003235
                        ],
                        [
                            -75.7078119998842,
                            39.7986330000386
                        ],
                        [
                            -75.6877479995543,
                            39.8103410001465
                        ],
                        [
                            -75.6657900002626,
                            39.8202840001132
                        ],
                        [
                            -75.6428040004007,
                            39.8280850001319
                        ],
                        [
                            -75.6181289998197,
                            39.8339650001629
                        ],
                        [
                            -75.5900870003661,
                            39.8380350001053
                        ],
                        [
                            -75.5618740003099,
                            39.8395100000068
                        ],
                        [
                            -75.533976999723,
                            39.8384490000509
                        ],
                        [
                            -75.505902999584,
                            39.8348140003386
                        ],
                        [
                            -75.4810389997554,
                            39.8293190002514
                        ],
                        [
                            -75.4528819997758,
                            39.8202249997763
                        ],
                        [
                            -75.4282749998652,
                            39.8093790000797
                        ],
                        [
                            -75.4054139996768,
                            39.7963960002712
                        ],
                        [
                            -75.4382999999083,
                            39.7844019997994
                        ],
                        [
                            -75.45068800034,
                            39.7750639998843
                        ],
                        [
                            -75.4492949998969,
                            39.7744359998422
                        ],
                        [
                            -75.4523389996276,
                            39.7690130000223
                        ],
                        [
                            -75.4621979999893,
                            39.763197000181
                        ],
                        [
                            -75.4680169998373,
                            39.7458110000175
                        ],
                        [
                            -75.4756929997355,
                            39.7336579998042
                        ],
                        [
                            -75.4750719997695,
                            39.7294879998981
                        ],
                        [
                            -75.4771690000193,
                            39.7249950002452
                        ],
                        [
                            -75.4764380004484,
                            39.7161990001568
                        ],
                        [
                            -75.4793609998053,
                            39.7137059999603
                        ],
                        [
                            -75.485342000035,
                            39.7158980001032
                        ],
                        [
                            -75.4883840001159,
                            39.7147079998692
                        ],
                        [
                            -75.4918270000548,
                            39.7108390002628
                        ],
                        [
                            -75.4963119999092,
                            39.7009559999024
                        ],
                        [
                            -75.5065360001439,
                            39.6972629999625
                        ],
                        [
                            -75.5098739996025,
                            39.6939150000087
                        ],
                        [
                            -75.5090860001302,
                            39.6858069997346
                        ],
                        [
                            -75.510374000093,
                            39.6779350002803
                        ],
                        [
                            -75.5155329996169,
                            39.6663260003267
                        ],
                        [
                            -75.5335239999865,
                            39.6475570002361
                        ],
                        [
                            -75.5419780002095,
                            39.6454250000225
                        ],
                        [
                            -75.5485340003745,
                            39.6377699999629
                        ],
                        [
                            -75.554143999985,
                            39.6356900000162
                        ],
                        [
                            -75.5587729997795,
                            39.6299869998521
                        ],
                        [
                            -75.5594549998449,
                            39.622314000075
                        ],
                        [
                            -75.5565600000771,
                            39.6115340001053
                        ],
                        [
                            -75.5573710000139,
                            39.609114999756
                        ],
                        [
                            -75.5535120001484,
                            39.6018190000079
                        ],
                        [
                            -75.542146000439,
                            39.5945190002619
                        ],
                        [
                            -75.5375030004009,
                            39.5936270002518
                        ],
                        [
                            -75.5276930004429,
                            39.5841120002865
                        ],
                        [
                            -75.5196359998799,
                            39.5833280000708
                        ],
                        [
                            -75.5147469997944,
                            39.5808840001613
                        ],
                        [
                            -75.5127700000936,
                            39.5782899999477
                        ],
                        [
                            -75.5119599999817,
                            39.5672900000394
                        ],
                        [
                            -75.5190679996173,
                            39.5554340002852
                        ],
                        [
                            -75.5340140004312,
                            39.5407019997012
                        ],
                        [
                            -75.5315749998747,
                            39.5368249998479
                        ],
                        [
                            -75.5276750000013,
                            39.5352769998846
                        ],
                        [
                            -75.5267870000716,
                            39.5314400000264
                        ],
                        [
                            -75.5274470003956,
                            39.5159680002734
                        ],
                        [
                            -75.5297410002889,
                            39.5120400001581
                        ],
                        [
                            -75.530141000322,
                            39.5053730002381
                        ],
                        [
                            -75.52936800002,
                            39.501229000263
                        ],
                        [
                            -75.5269739996693,
                            39.499168000075
                        ],
                        [
                            -75.5379340003961,
                            39.4969819999934
                        ],
                        [
                            -75.5590899999719,
                            39.4958059997853
                        ],
                        [
                            -75.5635859996969,
                            39.4830480002578
                        ],
                        [
                            -75.5605379997682,
                            39.4556449996593
                        ],
                        [
                            -75.4813600001458,
                            39.3875549999698
                        ],
                        [
                            -75.4694360002197,
                            39.3731470001463
                        ],
                        [
                            -75.4447509995929,
                            39.3550890003189
                        ],
                        [
                            -75.3210209995557,
                            39.2513790001993
                        ],
                        [
                            -75.2490169996911,
                            39.158944999915
                        ],
                        [
                            -75.1916709998621,
                            39.0789999996941
                        ],
                        [
                            -75.1684620004174,
                            39.0561789999978
                        ],
                        [
                            -75.0161090002452,
                            38.790530000255
                        ],
                        [
                            -75.0151230004062,
                            38.7886570003352
                        ],
                        [
                            -75.0173660002458,
                            38.786638999704
                        ],
                        [
                            -75.0131540002029,
                            38.7675730000624
                        ],
                        [
                            -75.0142120000119,
                            38.7450360000437
                        ],
                        [
                            -75.002750999868,
                            38.6832739998405
                        ],
                        [
                            -75.0019680004186,
                            38.6316829999334
                        ],
                        [
                            -74.9972679995807,
                            38.6251110000465
                        ],
                        [
                            -74.9935190002184,
                            38.6125009997352
                        ],
                        [
                            -74.9851710003005,
                            38.5281710002997
                        ],
                        [
                            -74.9841999996318,
                            38.4970940001043
                        ],
                        [
                            -74.986281999813,
                            38.4516319997754
                        ],
                        [
                            -75.4108800002456,
                            38.4522969997386
                        ],
                        [
                            -75.6936700002328,
                            38.4600799997912
                        ],
                        [
                            -75.7600739996342,
                            39.2968189998354
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 38.998538,
            "name": "Delaware",
            "intptlon": -75.4416616,
            "geo_point_2d": [
                38.9951239354,
                -75.4525519527
            ],
            "geoid": "10",
            "mtfcc": "G4000",
            "region": 3
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -75.4525519527,
                38.9951239354
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "2e3c50b9762eb882d377735159ed063ed7493100",
        "fields": {
            "arealand": "73043361",
            "objectid": 4,
            "basename": "Minnesota",
            "stusab": "MN",
            "statens": "00662849",
            "centlon": -94.1982235,
            "state": "27",
            "gid": 38,
            "centlat": 46.3164845,
            "division": 4,
            "areawater": "1750136443",
            "oid": "-1899485909",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -96.7843810003397,
                            46.6305040000681
                        ],
                        [
                            -96.7820899999212,
                            46.6312839996977
                        ],
                        [
                            -96.7890620000127,
                            46.629215000118
                        ],
                        [
                            -96.7954249998338,
                            46.6315620001843
                        ],
                        [
                            -96.7959889998984,
                            46.6288779997625
                        ],
                        [
                            -96.7981249996079,
                            46.629325999805
                        ],
                        [
                            -96.796039999952,
                            46.6331800002104
                        ],
                        [
                            -96.7923009997371,
                            46.6348229998759
                        ],
                        [
                            -96.7907819997956,
                            46.6386690002397
                        ],
                        [
                            -96.7858149998924,
                            46.6405229997623
                        ],
                        [
                            -96.7901909999669,
                            46.6419079998414
                        ],
                        [
                            -96.7941579997874,
                            46.6464470001059
                        ],
                        [
                            -96.7906529999242,
                            46.6490660000761
                        ],
                        [
                            -96.7989499997885,
                            46.6541120002199
                        ],
                        [
                            -96.7990369998271,
                            46.6575960000703
                        ],
                        [
                            -96.7970350000119,
                            46.6590039999156
                        ],
                        [
                            -96.8000059999477,
                            46.6608469997519
                        ],
                        [
                            -96.7975780001601,
                            46.661992999808
                        ],
                        [
                            -96.7985670003721,
                            46.665090000183
                        ],
                        [
                            -96.7958099997983,
                            46.6667019999395
                        ],
                        [
                            -96.8020739998851,
                            46.6675079999768
                        ],
                        [
                            -96.7991199996678,
                            46.668902000114
                        ],
                        [
                            -96.7951360001288,
                            46.667507000092
                        ],
                        [
                            -96.7936749999136,
                            46.6692890002432
                        ],
                        [
                            -96.7946370003615,
                            46.6714150002814
                        ],
                        [
                            -96.7914689998839,
                            46.6725250001729
                        ],
                        [
                            -96.7931769999713,
                            46.6739880002061
                        ],
                        [
                            -96.798271999921,
                            46.673659000129
                        ],
                        [
                            -96.7989840001237,
                            46.6784529999078
                        ],
                        [
                            -96.7947670000578,
                            46.6795489998821
                        ],
                        [
                            -96.7921450001015,
                            46.6765550001335
                        ],
                        [
                            -96.7875299996524,
                            46.6771030002685
                        ],
                        [
                            -96.7947989998449,
                            46.6832970002242
                        ],
                        [
                            -96.788434000374,
                            46.6816910001121
                        ],
                        [
                            -96.7876069996453,
                            46.6852029999728
                        ],
                        [
                            -96.7839279997049,
                            46.6857290000031
                        ],
                        [
                            -96.7850690002529,
                            46.6875910000872
                        ],
                        [
                            -96.79062600016,
                            46.6891330001697
                        ],
                        [
                            -96.7860950002749,
                            46.6914630000746
                        ],
                        [
                            -96.7872920000011,
                            46.6932289998198
                        ],
                        [
                            -96.7849250003129,
                            46.6943720003077
                        ],
                        [
                            -96.7880119997013,
                            46.7006290002058
                        ],
                        [
                            -96.7842149997601,
                            46.701448000214
                        ],
                        [
                            -96.79120399957,
                            46.7037470001844
                        ],
                        [
                            -96.7838270003209,
                            46.7059770000238
                        ],
                        [
                            -96.7895530002824,
                            46.7095569998505
                        ],
                        [
                            -96.7835739997026,
                            46.7114230000996
                        ],
                        [
                            -96.7874679997282,
                            46.7139980002583
                        ],
                        [
                            -96.7821200000584,
                            46.7168880002316
                        ],
                        [
                            -96.7901690002255,
                            46.7174529997262
                        ],
                        [
                            -96.7911969998973,
                            46.718873999858
                        ],
                        [
                            -96.7799039999829,
                            46.7229170000192
                        ],
                        [
                            -96.7820299996467,
                            46.7251930001877
                        ],
                        [
                            -96.7791359997038,
                            46.7266420003035
                        ],
                        [
                            -96.7793209996517,
                            46.7295669998957
                        ],
                        [
                            -96.783016000384,
                            46.7289939999694
                        ],
                        [
                            -96.784551000219,
                            46.7318780001076
                        ],
                        [
                            -96.7807679996232,
                            46.7338069998169
                        ],
                        [
                            -96.7831560001261,
                            46.7361379999725
                        ],
                        [
                            -96.7809430004237,
                            46.737937999705
                        ],
                        [
                            -96.782828999888,
                            46.7405879997678
                        ],
                        [
                            -96.7804510003291,
                            46.7417689997701
                        ],
                        [
                            -96.7873530001004,
                            46.7431399996944
                        ],
                        [
                            -96.7864750002165,
                            46.7445129998385
                        ],
                        [
                            -96.7802980001682,
                            46.7450040001806
                        ],
                        [
                            -96.7876679997447,
                            46.7482389997476
                        ],
                        [
                            -96.7829970001174,
                            46.7488349998268
                        ],
                        [
                            -96.7832890001955,
                            46.7509090001225
                        ],
                        [
                            -96.789376999657,
                            46.7519990001586
                        ],
                        [
                            -96.7831390004077,
                            46.7527689997095
                        ],
                        [
                            -96.7829879998966,
                            46.7545159997685
                        ],
                        [
                            -96.7897890002839,
                            46.7567009996942
                        ],
                        [
                            -96.7876600002471,
                            46.757933999869
                        ],
                        [
                            -96.7829400002161,
                            46.7567450001175
                        ],
                        [
                            -96.7836039998398,
                            46.7590760000272
                        ],
                        [
                            -96.7872939996509,
                            46.7603559999861
                        ],
                        [
                            -96.7863949998505,
                            46.7618640002487
                        ],
                        [
                            -96.7803450000239,
                            46.7601950002544
                        ],
                        [
                            -96.7778500002892,
                            46.7615880002271
                        ],
                        [
                            -96.7796450004152,
                            46.7630509997297
                        ],
                        [
                            -96.7864939995847,
                            46.7636010000803
                        ],
                        [
                            -96.7895189999472,
                            46.766024000108
                        ],
                        [
                            -96.7783959999121,
                            46.7645060000213
                        ],
                        [
                            -96.7754850002508,
                            46.7671850001388
                        ],
                        [
                            -96.7860590002899,
                            46.7691299998892
                        ],
                        [
                            -96.7892169998234,
                            46.771209999997
                        ],
                        [
                            -96.7900789998139,
                            46.7745690000233
                        ],
                        [
                            -96.7842940003011,
                            46.7743120001132
                        ],
                        [
                            -96.7837929999857,
                            46.7782470000883
                        ],
                        [
                            -96.7872090001604,
                            46.7783560001655
                        ],
                        [
                            -96.7921550001472,
                            46.7759300001698
                        ],
                        [
                            -96.7967560003526,
                            46.7769989998576
                        ],
                        [
                            -96.7871360003655,
                            46.7856510002834
                        ],
                        [
                            -96.7902329997997,
                            46.7872970002558
                        ],
                        [
                            -96.789568000351,
                            46.7895619999114
                        ],
                        [
                            -96.7943300002148,
                            46.788555000247
                        ],
                        [
                            -96.7961949997627,
                            46.7898810002434
                        ],
                        [
                            -96.7969589998439,
                            46.7924400002502
                        ],
                        [
                            -96.794397000162,
                            46.7941079998941
                        ],
                        [
                            -96.7949279997163,
                            46.7957760001142
                        ],
                        [
                            -96.8019140000516,
                            46.7969649999336
                        ],
                        [
                            -96.7962349999457,
                            46.7999150001128
                        ],
                        [
                            -96.7958170003693,
                            46.8016969999817
                        ],
                        [
                            -96.7978049999408,
                            46.8045400000953
                        ],
                        [
                            -96.803144000288,
                            46.805098999787
                        ],
                        [
                            -96.7957560002699,
                            46.8077950002817
                        ],
                        [
                            -96.8025440002384,
                            46.8115210001426
                        ],
                        [
                            -96.7993359995778,
                            46.8154359999879
                        ],
                        [
                            -96.8001599999334,
                            46.8196639999928
                        ],
                        [
                            -96.7979599997515,
                            46.8223639998131
                        ],
                        [
                            -96.7925590003783,
                            46.8232640001507
                        ],
                        [
                            -96.7915590002956,
                            46.8278640002707
                        ],
                        [
                            -96.7813769998937,
                            46.8247420002404
                        ],
                        [
                            -96.7802710004041,
                            46.8257629997957
                        ],
                        [
                            -96.7939589995958,
                            46.8330640000836
                        ],
                        [
                            -96.7926649997852,
                            46.8344609998313
                        ],
                        [
                            -96.7872340002748,
                            46.832618000158
                        ],
                        [
                            -96.7845260001047,
                            46.8333770000728
                        ],
                        [
                            -96.7827589995678,
                            46.8370630003017
                        ],
                        [
                            -96.7867589998986,
                            46.8387630001516
                        ],
                        [
                            -96.7871589999317,
                            46.8410630000767
                        ],
                        [
                            -96.7753589998541,
                            46.8388630000582
                        ],
                        [
                            -96.7750590002784,
                            46.8403630002897
                        ],
                        [
                            -96.781658999926,
                            46.845663000093
                        ],
                        [
                            -96.7805590002841,
                            46.8477630002437
                        ],
                        [
                            -96.7738580003543,
                            46.8499630001428
                        ],
                        [
                            -96.7731579998472,
                            46.8514630000994
                        ],
                        [
                            -96.7769589999864,
                            46.8524630001418
                        ],
                        [
                            -96.7813590003503,
                            46.851163000142
                        ],
                        [
                            -96.7841589996836,
                            46.8519629999907
                        ],
                        [
                            -96.7854590002402,
                            46.8548629998358
                        ],
                        [
                            -96.7760579996379,
                            46.8544630002705
                        ],
                        [
                            -96.7772579997372,
                            46.8572629997741
                        ],
                        [
                            -96.783958999667,
                            46.8616629997293
                        ],
                        [
                            -96.7803669997652,
                            46.8683590000413
                        ],
                        [
                            -96.7822589999756,
                            46.8708089997969
                        ],
                        [
                            -96.7758529995985,
                            46.8742809999183
                        ],
                        [
                            -96.7792410001841,
                            46.8759570000049
                        ],
                        [
                            -96.7811840004481,
                            46.8792759997855
                        ],
                        [
                            -96.7755179998627,
                            46.8791290002084
                        ],
                        [
                            -96.7716580001723,
                            46.8810629998059
                        ],
                        [
                            -96.771296999599,
                            46.8774349999682
                        ],
                        [
                            -96.7684289995953,
                            46.87950799979
                        ],
                        [
                            -96.7677790002153,
                            46.8847350000978
                        ],
                        [
                            -96.7735579998803,
                            46.8847630001925
                        ],
                        [
                            -96.7746580004204,
                            46.8886630001438
                        ],
                        [
                            -96.7695580004478,
                            46.8919629999153
                        ],
                        [
                            -96.7701579995991,
                            46.8936629999516
                        ],
                        [
                            -96.7767129999391,
                            46.8956190000538
                        ],
                        [
                            -96.7747579999795,
                            46.8993629998747
                        ],
                        [
                            -96.7706580000896,
                            46.9002629999758
                        ],
                        [
                            -96.7735579998803,
                            46.90356299978
                        ],
                        [
                            -96.7704580000731,
                            46.9067629998958
                        ],
                        [
                            -96.7656180003914,
                            46.9050099997382
                        ],
                        [
                            -96.7635400004082,
                            46.9094559998079
                        ],
                        [
                            -96.7638220004405,
                            46.9130050000035
                        ],
                        [
                            -96.7595960001539,
                            46.9164349998271
                        ],
                        [
                            -96.7556870000597,
                            46.9137639997204
                        ],
                        [
                            -96.7534299999763,
                            46.9146650000448
                        ],
                        [
                            -96.7612690000811,
                            46.91954700012
                        ],
                        [
                            -96.7632569996527,
                            46.9233630001812
                        ],
                        [
                            -96.7613569999447,
                            46.9243630000001
                        ],
                        [
                            -96.7537570002144,
                            46.9230630002153
                        ],
                        [
                            -96.7532569997239,
                            46.9245629998098
                        ],
                        [
                            -96.7652569998181,
                            46.9275629999904
                        ],
                        [
                            -96.7650569998015,
                            46.9290629999563
                        ],
                        [
                            -96.7625570000439,
                            46.9294629999279
                        ],
                        [
                            -96.7590570002036,
                            46.9277630001226
                        ],
                        [
                            -96.7568570000217,
                            46.9284630002256
                        ],
                        [
                            -96.7574570000713,
                            46.930763000216
                        ],
                        [
                            -96.7612570003856,
                            46.932162999968
                        ],
                        [
                            -96.7617569999778,
                            46.9346630000735
                        ],
                        [
                            -96.7632569996527,
                            46.9350629999332
                        ],
                        [
                            -96.7751570001877,
                            46.9308629999221
                        ],
                        [
                            -96.7766569998626,
                            46.9263630000798
                        ],
                        [
                            -96.7802589998102,
                            46.9282620002945
                        ],
                        [
                            -96.7837570000006,
                            46.9246630000482
                        ],
                        [
                            -96.7857589998159,
                            46.9249650002976
                        ],
                        [
                            -96.7859560003577,
                            46.9301629998985
                        ],
                        [
                            -96.7904579999305,
                            46.9273639997006
                        ],
                        [
                            -96.7917579995889,
                            46.9284650001357
                        ],
                        [
                            -96.7900500003998,
                            46.9376820002769
                        ],
                        [
                            -96.792157999622,
                            46.9405640000385
                        ],
                        [
                            -96.7926559995644,
                            46.9467640002685
                        ],
                        [
                            -96.7953579998866,
                            46.9469639998345
                        ],
                        [
                            -96.7979739999952,
                            46.9441859997688
                        ],
                        [
                            -96.8041400001728,
                            46.9469110002614
                        ],
                        [
                            -96.7995160004012,
                            46.9493340002859
                        ],
                        [
                            -96.7989649998571,
                            46.9511479997536
                        ],
                        [
                            -96.7996059999146,
                            46.9543160000965
                        ],
                        [
                            -96.8037500001854,
                            46.9581720000809
                        ],
                        [
                            -96.7992059998815,
                            46.9597240002348
                        ],
                        [
                            -96.7950449998922,
                            46.9658380000656
                        ],
                        [
                            -96.8015900001865,
                            46.9682869999227
                        ],
                        [
                            -96.8019809999987,
                            46.9641279998545
                        ],
                        [
                            -96.8065239995794,
                            46.9676359999605
                        ],
                        [
                            -96.807600999655,
                            46.9646359998188
                        ],
                        [
                            -96.8097859997684,
                            46.9638350001495
                        ],
                        [
                            -96.8140499996898,
                            46.966602000224
                        ],
                        [
                            -96.8195549997184,
                            46.967415000076
                        ],
                        [
                            -96.8226639997465,
                            46.9700199997163
                        ],
                        [
                            -96.8201129999353,
                            46.9793779999612
                        ],
                        [
                            -96.8226040003703,
                            46.985913999928
                        ],
                        [
                            -96.8263800003952,
                            46.987917999733
                        ],
                        [
                            -96.8223449999043,
                            46.9905910002727
                        ],
                        [
                            -96.8245979997897,
                            46.9933089999594
                        ],
                        [
                            -96.82392599977,
                            46.9974369998135
                        ],
                        [
                            -96.8194779997255,
                            46.9973729999459
                        ],
                        [
                            -96.8233130001999,
                            47.0003300000201
                        ],
                        [
                            -96.8209759997506,
                            47.0033260000978
                        ],
                        [
                            -96.8274890002578,
                            47.0016110000048
                        ],
                        [
                            -96.8287920002892,
                            47.0050610001619
                        ],
                        [
                            -96.8329669996239,
                            47.0041699998123
                        ],
                        [
                            -96.8348389997428,
                            47.0072540003058
                        ],
                        [
                            -96.8395479999032,
                            47.0067279998404
                        ],
                        [
                            -96.8335039999244,
                            47.0101099999356
                        ],
                        [
                            -96.8339390001176,
                            47.0127620000545
                        ],
                        [
                            -96.8323030000002,
                            47.0151840002192
                        ],
                        [
                            -96.8347759999936,
                            47.0174459998182
                        ],
                        [
                            -96.8309680001817,
                            47.0185200000171
                        ],
                        [
                            -96.8295160001874,
                            47.0215500001598
                        ],
                        [
                            -96.8209470003366,
                            47.0244020001626
                        ],
                        [
                            -96.8147929998546,
                            47.0244010001629
                        ],
                        [
                            -96.8204619999147,
                            47.030346999832
                        ],
                        [
                            -96.8260120001491,
                            47.0300709999362
                        ],
                        [
                            -96.8272680003249,
                            47.0320950001115
                        ],
                        [
                            -96.8240360002732,
                            47.0337469998591
                        ],
                        [
                            -96.8171419999994,
                            47.0333249999485
                        ],
                        [
                            -96.8126589997949,
                            47.0386339997008
                        ],
                        [
                            -96.8160769996194,
                            47.039632999791
                        ],
                        [
                            -96.8203940001427,
                            47.0355969996996
                        ],
                        [
                            -96.822715999625,
                            47.0369370002114
                        ],
                        [
                            -96.8178650000727,
                            47.0470769999813
                        ],
                        [
                            -96.820455000242,
                            47.0475769999212
                        ],
                        [
                            -96.8263979999385,
                            47.0452430001519
                        ],
                        [
                            -96.8275589996797,
                            47.04633199994
                        ],
                        [
                            -96.8195589999164,
                            47.0494929997379
                        ],
                        [
                            -96.8177079997139,
                            47.0523089999433
                        ],
                        [
                            -96.8250630001201,
                            47.0555640001335
                        ],
                        [
                            -96.8216949996259,
                            47.0582390001291
                        ],
                        [
                            -96.8222379997741,
                            47.059539999868
                        ],
                        [
                            -96.8274280001585,
                            47.0591220000192
                        ],
                        [
                            -96.8303859996755,
                            47.0608229997372
                        ],
                        [
                            -96.8197389998414,
                            47.0617290001038
                        ],
                        [
                            -96.8234999997977,
                            47.0659669999258
                        ],
                        [
                            -96.8245459999111,
                            47.0715339999028
                        ],
                        [
                            -96.8202240002633,
                            47.0734779997521
                        ],
                        [
                            -96.8196300000615,
                            47.0753420002494
                        ],
                        [
                            -96.8236909995934,
                            47.0759470002837
                        ],
                        [
                            -96.8267779998801,
                            47.0725979999865
                        ],
                        [
                            -96.8292759999879,
                            47.0746200002236
                        ],
                        [
                            -96.8157510001045,
                            47.0789870001432
                        ],
                        [
                            -96.8203859997467,
                            47.0830410000045
                        ],
                        [
                            -96.8159729998623,
                            47.0884660001395
                        ],
                        [
                            -96.8194919999692,
                            47.0897389998269
                        ],
                        [
                            -96.8263629997784,
                            47.0889199997545
                        ],
                        [
                            -96.8172509997794,
                            47.0920879998644
                        ],
                        [
                            -96.8172900001375,
                            47.0980599998206
                        ],
                        [
                            -96.8210200001316,
                            47.1008939998623
                        ],
                        [
                            -96.8159340004026,
                            47.1041389999373
                        ],
                        [
                            -96.8161910003206,
                            47.1084809997784
                        ],
                        [
                            -96.822094999659,
                            47.1088920002253
                        ],
                        [
                            -96.8211919996607,
                            47.1157539999834
                        ],
                        [
                            -96.8283870002333,
                            47.1207690001684
                        ],
                        [
                            -96.8375639996313,
                            47.1219800000085
                        ],
                        [
                            -96.8349190001088,
                            47.1237640000877
                        ],
                        [
                            -96.8258089997596,
                            47.1231010001674
                        ],
                        [
                            -96.824470999568,
                            47.1271829999595
                        ],
                        [
                            -96.8207230000306,
                            47.1303689997183
                        ],
                        [
                            -96.8272759998225,
                            47.1285100002935
                        ],
                        [
                            -96.8304169996376,
                            47.1292240000426
                        ],
                        [
                            -96.8305809996692,
                            47.1311619997639
                        ],
                        [
                            -96.8263300001665,
                            47.1349410001192
                        ],
                        [
                            -96.8290569997048,
                            47.14055499993
                        ],
                        [
                            -96.8346360002515,
                            47.1438860000164
                        ],
                        [
                            -96.8286480003492,
                            47.1464190000503
                        ],
                        [
                            -96.8320899995649,
                            47.1496870001042
                        ],
                        [
                            -96.8369560000841,
                            47.1486000002775
                        ],
                        [
                            -96.8412469997697,
                            47.1518559999996
                        ],
                        [
                            -96.8363309999201,
                            47.1532600001395
                        ],
                        [
                            -96.8362050004217,
                            47.1566479998366
                        ],
                        [
                            -96.8281289995921,
                            47.1546429998256
                        ],
                        [
                            -96.822359000148,
                            47.1563409998737
                        ],
                        [
                            -96.8236089995776,
                            47.158301999909
                        ],
                        [
                            -96.8306369997456,
                            47.1582389998774
                        ],
                        [
                            -96.8319900000058,
                            47.1594309997561
                        ],
                        [
                            -96.8304390002772,
                            47.1608279996972
                        ],
                        [
                            -96.8226019998222,
                            47.1614599999036
                        ],
                        [
                            -96.8192969999756,
                            47.1637919997924
                        ],
                        [
                            -96.8193549997019,
                            47.1653879998942
                        ],
                        [
                            -96.8251519998085,
                            47.1674510001276
                        ],
                        [
                            -96.8224839998214,
                            47.1730789999492
                        ],
                        [
                            -96.8304729997141,
                            47.1742530001942
                        ],
                        [
                            -96.8334839998329,
                            47.1766439997203
                        ],
                        [
                            -96.8250139997163,
                            47.1778589997659
                        ],
                        [
                            -96.8244649997203,
                            47.179873999993
                        ],
                        [
                            -96.8274990003036,
                            47.1820049997841
                        ],
                        [
                            -96.8228599995651,
                            47.1823240002845
                        ],
                        [
                            -96.8215549998838,
                            47.1843140000814
                        ],
                        [
                            -96.8241669997944,
                            47.1858800000648
                        ],
                        [
                            -96.8345609999085,
                            47.18367799984
                        ],
                        [
                            -96.8354799998003,
                            47.1862600002876
                        ],
                        [
                            -96.826638999963,
                            47.188802999713
                        ],
                        [
                            -96.826422000228,
                            47.1914810000863
                        ],
                        [
                            -96.8380390000075,
                            47.1964810000106
                        ],
                        [
                            -96.8403360002738,
                            47.195533999799
                        ],
                        [
                            -96.8402490002352,
                            47.1910139997345
                        ],
                        [
                            -96.8432059999273,
                            47.1912239998025
                        ],
                        [
                            -96.8448470000675,
                            47.193579999956
                        ],
                        [
                            -96.8339719997295,
                            47.2035479999819
                        ],
                        [
                            -96.8315730002542,
                            47.2032499998141
                        ],
                        [
                            -96.8292910000565,
                            47.2001340000019
                        ],
                        [
                            -96.8271670000426,
                            47.2013159998416
                        ],
                        [
                            -96.830044000267,
                            47.2043010002543
                        ],
                        [
                            -96.8371639995982,
                            47.2073240001232
                        ],
                        [
                            -96.8303100004058,
                            47.2102829996978
                        ],
                        [
                            -96.8371979999334,
                            47.2159489998731
                        ],
                        [
                            -96.8331559997698,
                            47.2192509999449
                        ],
                        [
                            -96.8336400003668,
                            47.2215770001962
                        ],
                        [
                            -96.838972999968,
                            47.2227130003026
                        ],
                        [
                            -96.8404149999165,
                            47.2187100001163
                        ],
                        [
                            -96.8423930003406,
                            47.2177490002403
                        ],
                        [
                            -96.8457879997006,
                            47.2201100001012
                        ],
                        [
                            -96.8433030000117,
                            47.2228150001277
                        ],
                        [
                            -96.8314540004286,
                            47.2267110002959
                        ],
                        [
                            -96.8348570001845,
                            47.2292820001247
                        ],
                        [
                            -96.8414409999385,
                            47.2295080000426
                        ],
                        [
                            -96.8427869996276,
                            47.2310039997534
                        ],
                        [
                            -96.8414690004259,
                            47.2332079997334
                        ],
                        [
                            -96.8366389998917,
                            47.2349520001955
                        ],
                        [
                            -96.8309609996108,
                            47.2312759998222
                        ],
                        [
                            -96.8286719997403,
                            47.2332860002492
                        ],
                        [
                            -96.8365350001346,
                            47.2423869997575
                        ],
                        [
                            -96.839080999923,
                            47.2418649999378
                        ],
                        [
                            -96.840835999866,
                            47.2389160001301
                        ],
                        [
                            -96.8437209995881,
                            47.2397879999079
                        ],
                        [
                            -96.8416400001301,
                            47.243063000003
                        ],
                        [
                            -96.8321930003953,
                            47.2468299999921
                        ],
                        [
                            -96.8373059998885,
                            47.2530219997952
                        ],
                        [
                            -96.839408999986,
                            47.2525820000035
                        ],
                        [
                            -96.8412180003558,
                            47.2494960000623
                        ],
                        [
                            -96.8444129996992,
                            47.251155000014
                        ],
                        [
                            -96.8385419999727,
                            47.2562249999121
                        ],
                        [
                            -96.8452499995753,
                            47.2584709997064
                        ],
                        [
                            -96.8372680002536,
                            47.2584820001534
                        ],
                        [
                            -96.8357629996575,
                            47.2622310001931
                        ],
                        [
                            -96.8418159998572,
                            47.2626450001157
                        ],
                        [
                            -96.8457890004239,
                            47.2607980003033
                        ],
                        [
                            -96.8474480001074,
                            47.261933000009
                        ],
                        [
                            -96.8447709998995,
                            47.2653620000109
                        ],
                        [
                            -96.8376559996928,
                            47.2646080002728
                        ],
                        [
                            -96.8347049998485,
                            47.266154999804
                        ],
                        [
                            -96.8361920000029,
                            47.2688759999107
                        ],
                        [
                            -96.844826999976,
                            47.2687529997577
                        ],
                        [
                            -96.8476780002612,
                            47.2712470000486
                        ],
                        [
                            -96.8400900002265,
                            47.2755760002051
                        ],
                        [
                            -96.8437880004335,
                            47.2931130001377
                        ],
                        [
                            -96.8306600002102,
                            47.3060659998109
                        ],
                        [
                            -96.8365259999138,
                            47.3114639998394
                        ],
                        [
                            -96.8430450002688,
                            47.3088269998762
                        ],
                        [
                            -96.8439280001756,
                            47.3102449997741
                        ],
                        [
                            -96.8423630002034,
                            47.3134010001728
                        ],
                        [
                            -96.8469760001043,
                            47.3158340001268
                        ],
                        [
                            -96.8426660001521,
                            47.3182709999678
                        ],
                        [
                            -96.8354510003863,
                            47.3190599999136
                        ],
                        [
                            -96.8346620001908,
                            47.3206720000194
                        ],
                        [
                            -96.8369000000076,
                            47.3248220000114
                        ],
                        [
                            -96.8309499997401,
                            47.3258290002505
                        ],
                        [
                            -96.8287639998018,
                            47.3279949998166
                        ],
                        [
                            -96.834584000373,
                            47.3309530000027
                        ],
                        [
                            -96.8407580000482,
                            47.3266589998635
                        ],
                        [
                            -96.8457860000508,
                            47.3280370002426
                        ],
                        [
                            -96.8460060001589,
                            47.3300750000349
                        ],
                        [
                            -96.8439780004043,
                            47.3316930001986
                        ],
                        [
                            -96.8344710003951,
                            47.3327019997243
                        ],
                        [
                            -96.8306429995934,
                            47.3387859999378
                        ],
                        [
                            -96.8341809999669,
                            47.3409130000956
                        ],
                        [
                            -96.8403749997336,
                            47.3409350001885
                        ],
                        [
                            -96.8471140001966,
                            47.3477109997141
                        ],
                        [
                            -96.8470389998535,
                            47.3497130000955
                        ],
                        [
                            -96.8429149996741,
                            47.3507779997602
                        ],
                        [
                            -96.8445879996014,
                            47.3542710002735
                        ],
                        [
                            -96.8381069997796,
                            47.3534889997965
                        ],
                        [
                            -96.837361999965,
                            47.3555360001548
                        ],
                        [
                            -96.8505400004171,
                            47.3570909997008
                        ],
                        [
                            -96.8523139997283,
                            47.3595250002114
                        ],
                        [
                            -96.8481960002951,
                            47.3627520001577
                        ],
                        [
                            -96.8483619999764,
                            47.3646199999463
                        ],
                        [
                            -96.850613000212,
                            47.3663489997792
                        ],
                        [
                            -96.8565120004259,
                            47.3661750000994
                        ],
                        [
                            -96.8582060002695,
                            47.3675299997252
                        ],
                        [
                            -96.8528290002875,
                            47.3697280000575
                        ],
                        [
                            -96.8440160000392,
                            47.368021000007
                        ],
                        [
                            -96.837141000032,
                            47.3712910000876
                        ],
                        [
                            -96.8368570003499,
                            47.3728670001027
                        ],
                        [
                            -96.8396609998811,
                            47.3735320002965
                        ],
                        [
                            -96.84727599968,
                            47.3702880000403
                        ],
                        [
                            -96.8546219998653,
                            47.3737799998558
                        ],
                        [
                            -96.8436879999761,
                            47.3757510000047
                        ],
                        [
                            -96.8439039998862,
                            47.3777130002139
                        ],
                        [
                            -96.8500459997743,
                            47.3816079999696
                        ],
                        [
                            -96.8477290003148,
                            47.3827509998441
                        ],
                        [
                            -96.8428200001379,
                            47.3807469999346
                        ],
                        [
                            -96.8392440001296,
                            47.3811649997289
                        ],
                        [
                            -96.8381489996123,
                            47.3832900000967
                        ],
                        [
                            -96.8421699998596,
                            47.3861689997487
                        ],
                        [
                            -96.8374649998971,
                            47.389526999931
                        ],
                        [
                            -96.8404509999015,
                            47.3932490001443
                        ],
                        [
                            -96.8440029996204,
                            47.3921449997938
                        ],
                        [
                            -96.8489810002926,
                            47.3936220001656
                        ],
                        [
                            -96.8480110003471,
                            47.395658999817
                        ],
                        [
                            -96.8391329998015,
                            47.3993790000032
                        ],
                        [
                            -96.8527139997614,
                            47.4060939998996
                        ],
                        [
                            -96.8428979999557,
                            47.409872000133
                        ],
                        [
                            -96.8420729997752,
                            47.411738000089
                        ],
                        [
                            -96.8568179998493,
                            47.409669000215
                        ],
                        [
                            -96.8616779996224,
                            47.4143940003027
                        ],
                        [
                            -96.8610109996257,
                            47.4176899999196
                        ],
                        [
                            -96.87176299994,
                            47.4190060000358
                        ],
                        [
                            -96.8552799996413,
                            47.4243280002461
                        ],
                        [
                            -96.8543249997644,
                            47.4255919998574
                        ],
                        [
                            -96.8555709998944,
                            47.4267189999831
                        ],
                        [
                            -96.8629520002398,
                            47.4264929999804
                        ],
                        [
                            -96.8647149996805,
                            47.4280940001625
                        ],
                        [
                            -96.8577509999849,
                            47.4322440000929
                        ],
                        [
                            -96.8528159998687,
                            47.4320299999381
                        ],
                        [
                            -96.8628590003534,
                            47.4364029998785
                        ],
                        [
                            -96.8558670001704,
                            47.4392209999847
                        ],
                        [
                            -96.8595610001795,
                            47.4457560002824
                        ],
                        [
                            -96.8570120000181,
                            47.4490359999111
                        ],
                        [
                            -96.8592580002308,
                            47.4517279997452
                        ],
                        [
                            -96.8550820001729,
                            47.4529639999706
                        ],
                        [
                            -96.8542980000002,
                            47.4545750001957
                        ],
                        [
                            -96.8622280003417,
                            47.4554839999175
                        ],
                        [
                            -96.8636190002366,
                            47.4570100002015
                        ],
                        [
                            -96.8539080000129,
                            47.4604030002278
                        ],
                        [
                            -96.8592149996748,
                            47.4662300002423
                        ],
                        [
                            -96.8610020003032,
                            47.4725880002185
                        ],
                        [
                            -96.8537900000121,
                            47.4759050001282
                        ],
                        [
                            -96.8519460003806,
                            47.4790549999938
                        ],
                        [
                            -96.8569149999337,
                            47.4812430000532
                        ],
                        [
                            -96.8605429998205,
                            47.4806559999171
                        ],
                        [
                            -96.8651580002696,
                            47.4768220001778
                        ],
                        [
                            -96.8685190001927,
                            47.4784730001171
                        ],
                        [
                            -96.8629749998061,
                            47.4839880001773
                        ],
                        [
                            -96.8551809999071,
                            47.4832060000897
                        ],
                        [
                            -96.8521580000927,
                            47.486351000258
                        ],
                        [
                            -96.8635139997563,
                            47.4897110001268
                        ],
                        [
                            -96.8650400002688,
                            47.4916509999337
                        ],
                        [
                            -96.8565579995583,
                            47.4935509999478
                        ],
                        [
                            -96.8487929999716,
                            47.4980509999123
                        ],
                        [
                            -96.853494999561,
                            47.5015709999255
                        ],
                        [
                            -96.8525360003845,
                            47.5074909997146
                        ],
                        [
                            -96.846990000348,
                            47.5064950000648
                        ],
                        [
                            -96.8422800003627,
                            47.5086359999785
                        ],
                        [
                            -96.8450560003049,
                            47.5104590001363
                        ],
                        [
                            -96.8518729996874,
                            47.5096999998124
                        ],
                        [
                            -96.8536250001557,
                            47.511505999827
                        ],
                        [
                            -96.8510850002151,
                            47.5135450002325
                        ],
                        [
                            -96.8438999996882,
                            47.5126010002767
                        ],
                        [
                            -96.8423680002263,
                            47.5161159998161
                        ],
                        [
                            -96.8464320001312,
                            47.5169149997527
                        ],
                        [
                            -96.8542920001525,
                            47.5144000001624
                        ],
                        [
                            -96.8608919998,
                            47.5154650001026
                        ],
                        [
                            -96.8633100004401,
                            47.5171599999938
                        ],
                        [
                            -96.8667199998688,
                            47.5253509998839
                        ],
                        [
                            -96.8711720001112,
                            47.5272680001116
                        ],
                        [
                            -96.8692010002581,
                            47.5288810001739
                        ],
                        [
                            -96.8609250003103,
                            47.5293649999419
                        ],
                        [
                            -96.8535339999191,
                            47.5362130002397
                        ],
                        [
                            -96.8544660002297,
                            47.5382610002541
                        ],
                        [
                            -96.8620099998835,
                            47.5378190002146
                        ],
                        [
                            -96.8635880002745,
                            47.5395489999943
                        ],
                        [
                            -96.8623060001595,
                            47.5411950001387
                        ],
                        [
                            -96.855397999642,
                            47.5420849999967
                        ],
                        [
                            -96.8488239999338,
                            47.5372020000672
                        ],
                        [
                            -96.8453230002685,
                            47.5387519997657
                        ],
                        [
                            -96.8485920001302,
                            47.5425770000357
                        ],
                        [
                            -96.8604280001928,
                            47.5459469998472
                        ],
                        [
                            -96.8538129995783,
                            47.5492019999322
                        ],
                        [
                            -96.8498349998871,
                            47.553162999744
                        ],
                        [
                            -96.8579250000621,
                            47.5554470000699
                        ],
                        [
                            -96.8617489997676,
                            47.5592789999305
                        ],
                        [
                            -96.8601439996124,
                            47.560636000168
                        ],
                        [
                            -96.8539869996556,
                            47.5604589997582
                        ],
                        [
                            -96.8476149996137,
                            47.564650999806
                        ],
                        [
                            -96.8474990001611,
                            47.5662259998175
                        ],
                        [
                            -96.863087999784,
                            47.562485999865
                        ],
                        [
                            -96.8689340002944,
                            47.5670019997647
                        ],
                        [
                            -96.8658179996954,
                            47.5696610002052
                        ],
                        [
                            -96.8584630001875,
                            47.5671459998901
                        ],
                        [
                            -96.8515680000888,
                            47.5686550001458
                        ],
                        [
                            -96.8523620003072,
                            47.5710310002435
                        ],
                        [
                            -96.8604759998734,
                            47.5750220002732
                        ],
                        [
                            -96.8537389999585,
                            47.5788500002039
                        ],
                        [
                            -96.8498969998114,
                            47.5908259998888
                        ],
                        [
                            -96.8589369998404,
                            47.5957419997068
                        ],
                        [
                            -96.8527499997464,
                            47.5961689997636
                        ],
                        [
                            -96.8510120004202,
                            47.5985079998502
                        ],
                        [
                            -96.8568120000015,
                            47.6018320000422
                        ],
                        [
                            -96.854821000057,
                            47.6068620002185
                        ],
                        [
                            -96.8570820003383,
                            47.6107449997124
                        ],
                        [
                            -96.8659230001757,
                            47.612560999712
                        ],
                        [
                            -96.8690609996177,
                            47.6148479999068
                        ],
                        [
                            -96.8736409999067,
                            47.6135460000536
                        ],
                        [
                            -96.8710099997295,
                            47.6180740001268
                        ],
                        [
                            -96.8797259998934,
                            47.6193359997389
                        ],
                        [
                            -96.8758600003552,
                            47.6234490002137
                        ],
                        [
                            -96.8804869996015,
                            47.626015999919
                        ],
                        [
                            -96.8743730002008,
                            47.627823999984
                        ],
                        [
                            -96.8809019997032,
                            47.6294590000585
                        ],
                        [
                            -96.8824680003987,
                            47.6336990000173
                        ],
                        [
                            -96.8882100002537,
                            47.6389109998783
                        ],
                        [
                            -96.8823510002228,
                            47.6426560000304
                        ],
                        [
                            -96.8822120003056,
                            47.6493890001084
                        ],
                        [
                            -96.8866159999692,
                            47.6527519999021
                        ],
                        [
                            -96.8875180001426,
                            47.6579350000301
                        ],
                        [
                            -96.885419000243,
                            47.6638840002401
                        ],
                        [
                            -96.8899369997093,
                            47.6697349999721
                        ],
                        [
                            -96.8851160002943,
                            47.6738079999564
                        ],
                        [
                            -96.8928890002769,
                            47.6730289998374
                        ],
                        [
                            -96.8962460000021,
                            47.6741939997707
                        ],
                        [
                            -96.8959880002592,
                            47.6781809999686
                        ],
                        [
                            -96.898955999822,
                            47.6849149998917
                        ],
                        [
                            -96.8992370000293,
                            47.6895790000073
                        ],
                        [
                            -96.9020420002838,
                            47.69171400014
                        ],
                        [
                            -96.9061310003031,
                            47.6887480001027
                        ],
                        [
                            -96.9095270003863,
                            47.689217999942
                        ],
                        [
                            -96.9097630003879,
                            47.6917090000945
                        ],
                        [
                            -96.9070190002328,
                            47.6944259997505
                        ],
                        [
                            -96.9120170000982,
                            47.701465000278
                        ],
                        [
                            -96.9140170002636,
                            47.701802999855
                        ],
                        [
                            -96.9178829998018,
                            47.6992260000984
                        ],
                        [
                            -96.920874999654,
                            47.6999759998557
                        ],
                        [
                            -96.9203790002597,
                            47.7018150003006
                        ],
                        [
                            -96.9144359996649,
                            47.7036530000549
                        ],
                        [
                            -96.9118680001352,
                            47.7068170000522
                        ],
                        [
                            -96.9127540004151,
                            47.7083190002964
                        ],
                        [
                            -96.9199270003481,
                            47.7103770002931
                        ],
                        [
                            -96.9197990003016,
                            47.7169280002247
                        ],
                        [
                            -96.9231319997373,
                            47.7178030001172
                        ],
                        [
                            -96.9278090001107,
                            47.7143150000062
                        ],
                        [
                            -96.9309730003903,
                            47.7155470001254
                        ],
                        [
                            -96.9300860002855,
                            47.7181179997207
                        ],
                        [
                            -96.9211009996098,
                            47.7213750000688
                        ],
                        [
                            -96.9184130004295,
                            47.7240409997866
                        ],
                        [
                            -96.9241050000559,
                            47.7262420002992
                        ],
                        [
                            -96.9251729999106,
                            47.7300610001264
                        ],
                        [
                            -96.9328300004406,
                            47.7375229998614
                        ],
                        [
                            -96.9327760000139,
                            47.7400650000964
                        ],
                        [
                            -96.928672999751,
                            47.7438119997497
                        ],
                        [
                            -96.9291520003251,
                            47.750375999711
                        ],
                        [
                            -96.9396850003563,
                            47.754787999782
                        ],
                        [
                            -96.9380869998738,
                            47.756397999792
                        ],
                        [
                            -96.9288540003992,
                            47.7552959999361
                        ],
                        [
                            -96.9382529995551,
                            47.7608130002118
                        ],
                        [
                            -96.9382719998217,
                            47.7630940001359
                        ],
                        [
                            -96.9348830003095,
                            47.767209000296
                        ],
                        [
                            -96.9451240002626,
                            47.7721650001929
                        ],
                        [
                            -96.9467729999004,
                            47.775090999798
                        ],
                        [
                            -96.956345000207,
                            47.7750920001391
                        ],
                        [
                            -96.9523769996632,
                            47.779440999816
                        ],
                        [
                            -96.959094000385,
                            47.7805849997462
                        ],
                        [
                            -96.9579760003015,
                            47.7856580002529
                        ],
                        [
                            -96.9617160003413,
                            47.783263999842
                        ],
                        [
                            -96.9651030002037,
                            47.7835120001679
                        ],
                        [
                            -96.9634560002157,
                            47.7873870002161
                        ],
                        [
                            -96.9570509996635,
                            47.7910070000657
                        ],
                        [
                            -96.9648489997605,
                            47.7955280000526
                        ],
                        [
                            -96.9664589999386,
                            47.7995690001649
                        ],
                        [
                            -96.9762539998279,
                            47.7977680002364
                        ],
                        [
                            -96.9764699997379,
                            47.8013659998678
                        ],
                        [
                            -96.9722610000681,
                            47.8027870000602
                        ],
                        [
                            -96.9915780000353,
                            47.8081869997884
                        ],
                        [
                            -96.9895770000449,
                            47.8098940001125
                        ],
                        [
                            -96.9787000000571,
                            47.8098920001665
                        ],
                        [
                            -96.9779190002575,
                            47.8116539999511
                        ],
                        [
                            -96.9800740002337,
                            47.8151090000339
                        ],
                        [
                            -96.980500000206,
                            47.8218560002804
                        ],
                        [
                            -96.9752860004306,
                            47.8221460002294
                        ],
                        [
                            -96.9741280001641,
                            47.8234740001607
                        ],
                        [
                            -96.9777259999137,
                            47.8254840001314
                        ],
                        [
                            -96.9871049998764,
                            47.8255500002041
                        ],
                        [
                            -96.9815499996191,
                            47.8295579997992
                        ],
                        [
                            -96.9862760003962,
                            47.8371129998728
                        ],
                        [
                            -96.9939649998149,
                            47.8384079997915
                        ],
                        [
                            -96.9927210002331,
                            47.8427909999477
                        ],
                        [
                            -96.9983369996915,
                            47.841931999879
                        ],
                        [
                            -96.9939740000358,
                            47.8459739999477
                        ],
                        [
                            -96.9974329998682,
                            47.84881699989
                        ],
                        [
                            -96.9967719997192,
                            47.8540779997114
                        ],
                        [
                            -96.9983280003689,
                            47.8591909999253
                        ],
                        [
                            -97.0059070001828,
                            47.86449599971
                        ],
                        [
                            -97.0018580003465,
                            47.8665080000774
                        ],
                        [
                            -97.0018280002092,
                            47.8681340002671
                        ],
                        [
                            -97.0054469998752,
                            47.8702400001796
                        ],
                        [
                            -97.0176720001003,
                            47.8715530002356
                        ],
                        [
                            -97.0231419999688,
                            47.8740630002098
                        ],
                        [
                            -97.0180140004071,
                            47.8786510000124
                        ],
                        [
                            -97.0254219996184,
                            47.8849259998474
                        ],
                        [
                            -97.024634999971,
                            47.8872219999558
                        ],
                        [
                            -97.0187819997879,
                            47.8905419997389
                        ],
                        [
                            -97.0250140000877,
                            47.8952030002927
                        ],
                        [
                            -97.0246290001232,
                            47.8975349998256
                        ],
                        [
                            -97.019846000343,
                            47.9013359999723
                        ],
                        [
                            -97.0252290001728,
                            47.9077280000375
                        ],
                        [
                            -97.0232299998324,
                            47.9084069999684
                        ],
                        [
                            -97.0186560002895,
                            47.9056520002555
                        ],
                        [
                            -97.0153320001763,
                            47.9067089998909
                        ],
                        [
                            -97.0168350002242,
                            47.9126160002941
                        ],
                        [
                            -97.0237629999348,
                            47.9156139997595
                        ],
                        [
                            -97.0184420000292,
                            47.9181530001498
                        ],
                        [
                            -97.0178539996752,
                            47.9199109998518
                        ],
                        [
                            -97.0364280003759,
                            47.9307810002511
                        ],
                        [
                            -97.0364729996835,
                            47.9396309999539
                        ],
                        [
                            -97.0482219997074,
                            47.9420360001141
                        ],
                        [
                            -97.0553410001121,
                            47.9475150000892
                        ],
                        [
                            -97.0524510003671,
                            47.9574219998588
                        ],
                        [
                            -97.0618310001547,
                            47.9647309997363
                        ],
                        [
                            -97.0573579999959,
                            47.9696639998585
                        ],
                        [
                            -97.0592550002291,
                            47.9753240001203
                        ],
                        [
                            -97.0530890000516,
                            47.9904440000438
                        ],
                        [
                            -97.0549450002769,
                            47.9929239997896
                        ],
                        [
                            -97.063936999727,
                            47.9976600000533
                        ],
                        [
                            -97.066762000073,
                            48.0095579997258
                        ],
                        [
                            -97.0630119999874,
                            48.0131789999113
                        ],
                        [
                            -97.0632889999968,
                            48.0149890002425
                        ],
                        [
                            -97.0692839995719,
                            48.0161759997755
                        ],
                        [
                            -97.0722389996142,
                            48.0191069998413
                        ],
                        [
                            -97.0687129998346,
                            48.0276940002117
                        ],
                        [
                            -97.0722570000558,
                            48.0480680000741
                        ],
                        [
                            -97.0756410004435,
                            48.0527249997731
                        ],
                        [
                            -97.0869860002364,
                            48.0582219998888
                        ],
                        [
                            -97.0977719999876,
                            48.0710799998958
                        ],
                        [
                            -97.1044829999633,
                            48.0724279999288
                        ],
                        [
                            -97.1001549995694,
                            48.0787569999972
                        ],
                        [
                            -97.0997980000923,
                            48.0858840001089
                        ],
                        [
                            -97.1021649997805,
                            48.0891220001066
                        ],
                        [
                            -97.1098569995723,
                            48.0913949999378
                        ],
                        [
                            -97.1018760000755,
                            48.0958959997068
                        ],
                        [
                            -97.108448000134,
                            48.0998669997694
                        ],
                        [
                            -97.1095350002553,
                            48.1047230001215
                        ],
                        [
                            -97.1114700001234,
                            48.1059130000156
                        ],
                        [
                            -97.1197729998354,
                            48.1053810000948
                        ],
                        [
                            -97.123202000429,
                            48.1066500002068
                        ],
                        [
                            -97.120701999773,
                            48.1149870001019
                        ],
                        [
                            -97.1282789999371,
                            48.1271849997401
                        ],
                        [
                            -97.129453000097,
                            48.1331330001958
                        ],
                        [
                            -97.1334679995982,
                            48.1371009999171
                        ],
                        [
                            -97.1252669999934,
                            48.1360990001033
                        ],
                        [
                            -97.1230440002452,
                            48.1369960001412
                        ],
                        [
                            -97.1228760000157,
                            48.1397500000489
                        ],
                        [
                            -97.1367680003202,
                            48.1432919999074
                        ],
                        [
                            -97.1435689998092,
                            48.1409969999445
                        ],
                        [
                            -97.1465049995849,
                            48.1422200000941
                        ],
                        [
                            -97.145886999992,
                            48.1451040002107
                        ],
                        [
                            -97.1402949999247,
                            48.1508939999991
                        ],
                        [
                            -97.1244810001709,
                            48.1565499997354
                        ],
                        [
                            -97.1209679999118,
                            48.159551999703
                        ],
                        [
                            -97.1214669996791,
                            48.1614779997235
                        ],
                        [
                            -97.125350999659,
                            48.1628200002087
                        ],
                        [
                            -97.1363759997848,
                            48.1597710000252
                        ],
                        [
                            -97.1419500003087,
                            48.1602020001589
                        ],
                        [
                            -97.1475099996905,
                            48.1705719999979
                        ],
                        [
                            -97.1475110004137,
                            48.1724070002352
                        ],
                        [
                            -97.1452429995614,
                            48.1740460001625
                        ],
                        [
                            -97.1389500000605,
                            48.1746559997487
                        ],
                        [
                            -97.1360100000869,
                            48.1767399998257
                        ],
                        [
                            -97.1366799995584,
                            48.1808659999754
                        ],
                        [
                            -97.1383859999959,
                            48.1823039999921
                        ],
                        [
                            -97.146768000249,
                            48.182057999863
                        ],
                        [
                            -97.1498100003299,
                            48.1834979998739
                        ],
                        [
                            -97.1497550000783,
                            48.1858240002832
                        ],
                        [
                            -97.1429219999039,
                            48.1909960001443
                        ],
                        [
                            -97.1409040001952,
                            48.1944430000786
                        ],
                        [
                            -97.1356719999781,
                            48.1971259999516
                        ],
                        [
                            -97.1412399997559,
                            48.2029530002041
                        ],
                        [
                            -97.140910000043,
                            48.2045199999987
                        ],
                        [
                            -97.1292770003699,
                            48.2083770001018
                        ],
                        [
                            -97.1301550002539,
                            48.2107649999214
                        ],
                        [
                            -97.1382570001245,
                            48.2130609998196
                        ],
                        [
                            -97.1392959996669,
                            48.2167549998338
                        ],
                        [
                            -97.1313629998508,
                            48.2171819997038
                        ],
                        [
                            -97.1278620001855,
                            48.2144169998715
                        ],
                        [
                            -97.1236889996024,
                            48.2076019998236
                        ],
                        [
                            -97.1197739996603,
                            48.2075040002632
                        ],
                        [
                            -97.1178379999673,
                            48.2097639997698
                        ],
                        [
                            -97.1228840004116,
                            48.2164939998782
                        ],
                        [
                            -97.1390549996425,
                            48.2219519998953
                        ],
                        [
                            -97.1436039999693,
                            48.2215230001057
                        ],
                        [
                            -97.1482880000154,
                            48.2183170002991
                        ],
                        [
                            -97.1521900004369,
                            48.219636000299
                        ],
                        [
                            -97.149657000169,
                            48.2231490002355
                        ],
                        [
                            -97.1371909999195,
                            48.2269509997199
                        ],
                        [
                            -97.1332879996731,
                            48.2269429997568
                        ],
                        [
                            -97.1245079999351,
                            48.2231740001737
                        ],
                        [
                            -97.1206900000775,
                            48.2247539999834
                        ],
                        [
                            -97.1244469998357,
                            48.2283869997625
                        ],
                        [
                            -97.1393109997355,
                            48.2301870001128
                        ],
                        [
                            -97.1446450000599,
                            48.2335620000621
                        ],
                        [
                            -97.1430080001177,
                            48.236060000189
                        ],
                        [
                            -97.1358350001847,
                            48.2369189999941
                        ],
                        [
                            -97.1337349995618,
                            48.2390750001695
                        ],
                        [
                            -97.1375490001198,
                            48.2413030002856
                        ],
                        [
                            -97.1459299996497,
                            48.2416199998493
                        ],
                        [
                            -97.1467439999595,
                            48.2444840000194
                        ],
                        [
                            -97.1355749998937,
                            48.2494879998055
                        ],
                        [
                            -97.1251079999847,
                            48.2499830001963
                        ],
                        [
                            -97.1248729998081,
                            48.2531640002327
                        ],
                        [
                            -97.1306020001427,
                            48.258982000257
                        ],
                        [
                            -97.1229539998335,
                            48.2616660000999
                        ],
                        [
                            -97.1262729999239,
                            48.264369999787
                        ],
                        [
                            -97.1438640002603,
                            48.2657490001448
                        ],
                        [
                            -97.1450319996742,
                            48.2687659999021
                        ],
                        [
                            -97.1391500000771,
                            48.2712269998266
                        ],
                        [
                            -97.1253449998112,
                            48.2704209999796
                        ],
                        [
                            -97.1237380000062,
                            48.272047999979
                        ],
                        [
                            -97.1251230000533,
                            48.2766739998137
                        ],
                        [
                            -97.1198010003228,
                            48.2768499998123
                        ],
                        [
                            -97.116570000096,
                            48.2796610002277
                        ],
                        [
                            -97.1231429999794,
                            48.2908089999004
                        ],
                        [
                            -97.1280420001106,
                            48.2915190000637
                        ],
                        [
                            -97.1355459995814,
                            48.2870590000637
                        ],
                        [
                            -97.1383829996229,
                            48.28865499973
                        ],
                        [
                            -97.1373340000347,
                            48.2917569998151
                        ],
                        [
                            -97.127621999986,
                            48.2989930001319
                        ],
                        [
                            -97.1241539999328,
                            48.2983570002982
                        ],
                        [
                            -97.1191900004026,
                            48.2942419998161
                        ],
                        [
                            -97.1138009998266,
                            48.2942269998939
                        ],
                        [
                            -97.1122309998315,
                            48.2964720001841
                        ],
                        [
                            -97.1266870002006,
                            48.3040040002757
                        ],
                        [
                            -97.1222020003462,
                            48.3087019998663
                        ],
                        [
                            -97.1241659996283,
                            48.311141999824
                        ],
                        [
                            -97.1276129997652,
                            48.3118059999081
                        ],
                        [
                            -97.1343349996115,
                            48.3088259999021
                        ],
                        [
                            -97.1377490001364,
                            48.3097290000439
                        ],
                        [
                            -97.1379259996884,
                            48.3119839997829
                        ],
                        [
                            -97.132598999935,
                            48.3191449999436
                        ],
                        [
                            -97.1197350002006,
                            48.320787999778
                        ],
                        [
                            -97.115580999884,
                            48.3239280001763
                        ],
                        [
                            -97.120300999915,
                            48.3270979998527
                        ],
                        [
                            -97.1292060002248,
                            48.3281310001154
                        ],
                        [
                            -97.1343420001825,
                            48.3276770001892
                        ],
                        [
                            -97.1390110001599,
                            48.3250990000301
                        ],
                        [
                            -97.141715000132,
                            48.3257290001959
                        ],
                        [
                            -97.1420249997534,
                            48.3280389997254
                        ],
                        [
                            -97.1400299996109,
                            48.3298469999255
                        ],
                        [
                            -97.1290410003684,
                            48.3328900000724
                        ],
                        [
                            -97.1259199997464,
                            48.3405790002425
                        ],
                        [
                            -97.1297540003959,
                            48.3434699997881
                        ],
                        [
                            -97.1400840000375,
                            48.3445909999715
                        ],
                        [
                            -97.1408039997378,
                            48.3467309999182
                        ],
                        [
                            -97.1361359995852,
                            48.3502279997378
                        ],
                        [
                            -97.1362030004307,
                            48.3521839999464
                        ],
                        [
                            -97.1435339996491,
                            48.3542700002722
                        ],
                        [
                            -97.1468610001354,
                            48.3595530002667
                        ],
                        [
                            -97.1554810000399,
                            48.3637680002245
                        ],
                        [
                            -97.1562610000145,
                            48.3655779997754
                        ],
                        [
                            -97.1548059996471,
                            48.3699699997058
                        ],
                        [
                            -97.1524559996774,
                            48.3709740002684
                        ],
                        [
                            -97.1378529998934,
                            48.3712240001429
                        ],
                        [
                            -97.1373039998974,
                            48.3729969999356
                        ],
                        [
                            -97.1413510000839,
                            48.3784579999577
                        ],
                        [
                            -97.1333410002749,
                            48.382196000077
                        ],
                        [
                            -97.1362469999133,
                            48.3839329999942
                        ],
                        [
                            -97.1454829997609,
                            48.3814090000984
                        ],
                        [
                            -97.1519929998951,
                            48.3828800001797
                        ],
                        [
                            -97.1576920000925,
                            48.3864860000563
                        ],
                        [
                            -97.1567599997818,
                            48.3908870002608
                        ],
                        [
                            -97.1633760002212,
                            48.3928570001623
                        ],
                        [
                            -97.1586479997942,
                            48.3963440001712
                        ],
                        [
                            -97.1456929998232,
                            48.39743099973
                        ],
                        [
                            -97.1311750004281,
                            48.4072810002681
                        ],
                        [
                            -97.1322819997426,
                            48.4103619999432
                        ],
                        [
                            -97.138453999768,
                            48.414547999883
                        ],
                        [
                            -97.139879999823,
                            48.4184870002352
                        ],
                        [
                            -97.1434790002959,
                            48.4180350000356
                        ],
                        [
                            -97.1467110003476,
                            48.4139409997044
                        ],
                        [
                            -97.1505300000301,
                            48.4127419999264
                        ],
                        [
                            -97.1550840003798,
                            48.4142900000688
                        ],
                        [
                            -97.1539939998854,
                            48.4181209999453
                        ],
                        [
                            -97.1383960000417,
                            48.42164699975
                        ],
                        [
                            -97.1335580000098,
                            48.4209160000822
                        ],
                        [
                            -97.126043999595,
                            48.4161199997869
                        ],
                        [
                            -97.1225830001127,
                            48.4179209998203
                        ],
                        [
                            -97.1246639995707,
                            48.4222160002607
                        ],
                        [
                            -97.1425970002139,
                            48.4276500000919
                        ],
                        [
                            -97.1458729997483,
                            48.4311479999028
                        ],
                        [
                            -97.141242000304,
                            48.4337710000785
                        ],
                        [
                            -97.1278179998046,
                            48.4312559998367
                        ],
                        [
                            -97.1236669998611,
                            48.4360339999117
                        ],
                        [
                            -97.1248240003026,
                            48.4418959997712
                        ],
                        [
                            -97.1280410002857,
                            48.4437079997949
                        ],
                        [
                            -97.132823000241,
                            48.4437999998976
                        ],
                        [
                            -97.141086999595,
                            48.4385839999537
                        ],
                        [
                            -97.1451709995914,
                            48.4380780001941
                        ],
                        [
                            -97.1498110001548,
                            48.439152999764
                        ],
                        [
                            -97.1506329999623,
                            48.4408159999405
                        ],
                        [
                            -97.148110999565,
                            48.4440519999993
                        ],
                        [
                            -97.1377829995732,
                            48.4476789997967
                        ],
                        [
                            -97.130691999656,
                            48.4522070002829
                        ],
                        [
                            -97.1285440002509,
                            48.4603009999592
                        ],
                        [
                            -97.1306320002799,
                            48.4634200000803
                        ],
                        [
                            -97.1430170003385,
                            48.4638229997888
                        ],
                        [
                            -97.1474839997512,
                            48.4684000001108
                        ],
                        [
                            -97.147417999629,
                            48.4727530000368
                        ],
                        [
                            -97.1407300001179,
                            48.4746910002721
                        ],
                        [
                            -97.1306039997925,
                            48.471215000242
                        ],
                        [
                            -97.1281629995861,
                            48.4743619997226
                        ],
                        [
                            -97.1308819996268,
                            48.4768549999712
                        ],
                        [
                            -97.1346009997502,
                            48.4775360001653
                        ],
                        [
                            -97.1549160001503,
                            48.4749110001841
                        ],
                        [
                            -97.1609819998705,
                            48.4758050000387
                        ],
                        [
                            -97.1626680002165,
                            48.4777829997439
                        ],
                        [
                            -97.1592050001861,
                            48.4811069998045
                        ],
                        [
                            -97.1402909997268,
                            48.4847219997799
                        ],
                        [
                            -97.1393390002229,
                            48.4872769998887
                        ],
                        [
                            -97.1430170003385,
                            48.4923339997576
                        ],
                        [
                            -97.1399550001661,
                            48.4919630001682
                        ],
                        [
                            -97.1351050004387,
                            48.487728999747
                        ],
                        [
                            -97.1315000001181,
                            48.4890059998701
                        ],
                        [
                            -97.1316110004462,
                            48.4915519999009
                        ],
                        [
                            -97.1345880002298,
                            48.4942379997872
                        ],
                        [
                            -97.145337999996,
                            48.4978780002032
                        ],
                        [
                            -97.1491900001888,
                            48.5008399997256
                        ],
                        [
                            -97.1502670002644,
                            48.5115999999795
                        ],
                        [
                            -97.1366190003573,
                            48.5121830000514
                        ],
                        [
                            -97.1281140000806,
                            48.5177549997789
                        ],
                        [
                            -97.1267580003458,
                            48.5201570002221
                        ],
                        [
                            -97.1307770000448,
                            48.5226680000386
                        ],
                        [
                            -97.145405999768,
                            48.5187349998636
                        ],
                        [
                            -97.1524230000654,
                            48.5203640002486
                        ],
                        [
                            -97.1547730000352,
                            48.524163000258
                        ],
                        [
                            -97.1535970002254,
                            48.5295079999048
                        ],
                        [
                            -97.1421760002645,
                            48.532239999976
                        ],
                        [
                            -97.1413579997566,
                            48.5354520002899
                        ],
                        [
                            -97.1456080003327,
                            48.5380999999127
                        ],
                        [
                            -97.1610250004265,
                            48.5420089999931
                        ],
                        [
                            -97.1659839999338,
                            48.5489319999541
                        ],
                        [
                            -97.1659649996673,
                            48.5526480000836
                        ],
                        [
                            -97.1639369999127,
                            48.5539830002531
                        ],
                        [
                            -97.1555050003293,
                            48.55112299979
                        ],
                        [
                            -97.1415850004357,
                            48.5512709998133
                        ],
                        [
                            -97.138529999936,
                            48.5524329999166
                        ],
                        [
                            -97.1389509998854,
                            48.5551200002058
                        ],
                        [
                            -97.1448319996577,
                            48.5572380002755
                        ],
                        [
                            -97.166199000019,
                            48.557244000074
                        ],
                        [
                            -97.1732189997911,
                            48.5596090002728
                        ],
                        [
                            -97.175423000171,
                            48.5619569997705
                        ],
                        [
                            -97.1719710000113,
                            48.5649500000387
                        ],
                        [
                            -97.154053000335,
                            48.5667550000172
                        ],
                        [
                            -97.1491700000973,
                            48.5700140001849
                        ],
                        [
                            -97.1478469999744,
                            48.5730290002815
                        ],
                        [
                            -97.1487089999648,
                            48.5763669999925
                        ],
                        [
                            -97.1587249997871,
                            48.5782209997191
                        ],
                        [
                            -97.1606310002412,
                            48.5809360000856
                        ],
                        [
                            -97.1563639999467,
                            48.5827080001587
                        ],
                        [
                            -97.1473309995904,
                            48.5809640000855
                        ],
                        [
                            -97.1438910000245,
                            48.582111999802
                        ],
                        [
                            -97.1422790001966,
                            48.5926129998931
                        ],
                        [
                            -97.1452240001931,
                            48.5940680000824
                        ],
                        [
                            -97.1523379996766,
                            48.5938379998843
                        ],
                        [
                            -97.1540270003957,
                            48.5954059999287
                        ],
                        [
                            -97.1513679997311,
                            48.5980100001666
                        ],
                        [
                            -97.1438220004275,
                            48.5983590000782
                        ],
                        [
                            -97.1342910001289,
                            48.6073999999801
                        ],
                        [
                            -97.1374910003935,
                            48.6088750000647
                        ],
                        [
                            -97.1476760002702,
                            48.6081820000414
                        ],
                        [
                            -97.1502909996555,
                            48.609667999863
                        ],
                        [
                            -97.1506489998558,
                            48.6116759998751
                        ],
                        [
                            -97.1437939999401,
                            48.6147659997048
                        ],
                        [
                            -97.1295760001207,
                            48.6136930002574
                        ],
                        [
                            -97.1295540003794,
                            48.6168009997485
                        ],
                        [
                            -97.1353149996027,
                            48.6209099999639
                        ],
                        [
                            -97.1338590003087,
                            48.6227779997127
                        ],
                        [
                            -97.129633999847,
                            48.6223900001053
                        ],
                        [
                            -97.1237370001812,
                            48.6181020001762
                        ],
                        [
                            -97.1194950000011,
                            48.6185599999945
                        ],
                        [
                            -97.1201419999064,
                            48.6216800000767
                        ],
                        [
                            -97.1296470002658,
                            48.6279270001792
                        ],
                        [
                            -97.131140000268,
                            48.6314729998118
                        ],
                        [
                            -97.1259919997165,
                            48.6341200002403
                        ],
                        [
                            -97.1146839997334,
                            48.6303419997964
                        ],
                        [
                            -97.1094499998665,
                            48.6324689998686
                        ],
                        [
                            -97.1098580002955,
                            48.6384769998506
                        ],
                        [
                            -97.1156920002121,
                            48.6445609997624
                        ],
                        [
                            -97.1079240002523,
                            48.6479219997493
                        ],
                        [
                            -97.1059020003456,
                            48.6535619997592
                        ],
                        [
                            -97.0986909998795,
                            48.6609950001489
                        ],
                        [
                            -97.1007239996569,
                            48.6627280000256
                        ],
                        [
                            -97.1079600002373,
                            48.6626699997603
                        ],
                        [
                            -97.1092289999336,
                            48.6642499998686
                        ],
                        [
                            -97.1005620001735,
                            48.6684059998269
                        ],
                        [
                            -97.1012760000259,
                            48.6822990001578
                        ],
                        [
                            -97.0984139998701,
                            48.6834060001705
                        ],
                        [
                            -97.0924140002721,
                            48.6821169999811
                        ],
                        [
                            -97.0903129998244,
                            48.6844039999439
                        ],
                        [
                            -97.093364999951,
                            48.6865839999362
                        ],
                        [
                            -97.1086769995645,
                            48.6909909999515
                        ],
                        [
                            -97.119904000255,
                            48.7012480001495
                        ],
                        [
                            -97.1202919996942,
                            48.7039990002057
                        ],
                        [
                            -97.1113019998939,
                            48.7057299999337
                        ],
                        [
                            -97.1101119998404,
                            48.7079779998588
                        ],
                        [
                            -97.1193999995665,
                            48.7116249999987
                        ],
                        [
                            -97.1255180000636,
                            48.7204510002564
                        ],
                        [
                            -97.1374240004464,
                            48.7265659999517
                        ],
                        [
                            -97.1363740001349,
                            48.7285000000733
                        ],
                        [
                            -97.1298880002902,
                            48.7297319997705
                        ],
                        [
                            -97.1283400000364,
                            48.7321359998618
                        ],
                        [
                            -97.1302730002547,
                            48.7342309997579
                        ],
                        [
                            -97.1388339997096,
                            48.7342839999979
                        ],
                        [
                            -97.1421329997084,
                            48.7361720000791
                        ],
                        [
                            -97.1366650003881,
                            48.7425150001113
                        ],
                        [
                            -97.1375380002492,
                            48.7496440002557
                        ],
                        [
                            -97.1404699998269,
                            48.7509230000578
                        ],
                        [
                            -97.1491500000058,
                            48.75035500006
                        ],
                        [
                            -97.1537949996938,
                            48.7539930000191
                        ],
                        [
                            -97.1544390001243,
                            48.7582120002093
                        ],
                        [
                            -97.1467930003633,
                            48.7618659999013
                        ],
                        [
                            -97.1454559999968,
                            48.7647899999621
                        ],
                        [
                            -97.1517329996041,
                            48.7719320002509
                        ],
                        [
                            -97.1577409995979,
                            48.7733649999308
                        ],
                        [
                            -97.1589830004283,
                            48.775711000287
                        ],
                        [
                            -97.1485700000477,
                            48.7792249998367
                        ],
                        [
                            -97.1474689996826,
                            48.7814409997944
                        ],
                        [
                            -97.1589239999787,
                            48.7832570002028
                        ],
                        [
                            -97.1595990003714,
                            48.7854660001151
                        ],
                        [
                            -97.1568779997826,
                            48.7895779998253
                        ],
                        [
                            -97.1582429997383,
                            48.7912050001685
                        ],
                        [
                            -97.1674619998674,
                            48.791252000283
                        ],
                        [
                            -97.1740409995985,
                            48.7938789997759
                        ],
                        [
                            -97.171360000091,
                            48.7965330000521
                        ],
                        [
                            -97.1624670003751,
                            48.7966570002857
                        ],
                        [
                            -97.1616659995857,
                            48.7983259999448
                        ],
                        [
                            -97.1632609996951,
                            48.8005790001295
                        ],
                        [
                            -97.1693919997126,
                            48.8013100000994
                        ],
                        [
                            -97.1775730001242,
                            48.797072000117
                        ],
                        [
                            -97.1816400004022,
                            48.7985170001456
                        ],
                        [
                            -97.179049000408,
                            48.801100999847
                        ],
                        [
                            -97.1592379997981,
                            48.8061650000385
                        ],
                        [
                            -97.1575070001445,
                            48.8079810002393
                        ],
                        [
                            -97.1581180000648,
                            48.810318999957
                        ],
                        [
                            -97.1636459996596,
                            48.8119700002536
                        ],
                        [
                            -97.1720610004229,
                            48.8115200001213
                        ],
                        [
                            -97.1806820001523,
                            48.8151710000169
                        ],
                        [
                            -97.190273999652,
                            48.815134999969
                        ],
                        [
                            -97.1887680001293,
                            48.8168980000634
                        ],
                        [
                            -97.1808210000695,
                            48.8191690000149
                        ],
                        [
                            -97.1779930002488,
                            48.8228350001498
                        ],
                        [
                            -97.1787770004214,
                            48.8263809999065
                        ],
                        [
                            -97.1846459995998,
                            48.8314420000952
                        ],
                        [
                            -97.1841639995509,
                            48.833902000221
                        ],
                        [
                            -97.176593000133,
                            48.8355250001885
                        ],
                        [
                            -97.173884000138,
                            48.8379930000544
                        ],
                        [
                            -97.1757860003942,
                            48.8448319997599
                        ],
                        [
                            -97.1829230003421,
                            48.8469120000092
                        ],
                        [
                            -97.17527199966,
                            48.8486660000958
                        ],
                        [
                            -97.1730990001405,
                            48.8506249999371
                        ],
                        [
                            -97.174136999858,
                            48.8533299999502
                        ],
                        [
                            -97.1788340003229,
                            48.8566610000556
                        ],
                        [
                            -97.1791659996856,
                            48.8628440001679
                        ],
                        [
                            -97.1905730003011,
                            48.8642789997131
                        ],
                        [
                            -97.1893709996537,
                            48.8667499998119
                        ],
                        [
                            -97.1793399997628,
                            48.8731350002853
                        ],
                        [
                            -97.1778140001487,
                            48.8756389998434
                        ],
                        [
                            -97.1808550004047,
                            48.8772259998425
                        ],
                        [
                            -97.1902589995834,
                            48.876725000157
                        ],
                        [
                            -97.199394000047,
                            48.8811130000968
                        ],
                        [
                            -97.1997930002551,
                            48.8832769997507
                        ],
                        [
                            -97.1973279997593,
                            48.8873479999757
                        ],
                        [
                            -97.2016269998409,
                            48.8919320000419
                        ],
                        [
                            -97.1919659998459,
                            48.8917510001531
                        ],
                        [
                            -97.1901880003366,
                            48.8929570000895
                        ],
                        [
                            -97.190017999559,
                            48.8955160000156
                        ],
                        [
                            -97.1932660004025,
                            48.8983360001668
                        ],
                        [
                            -97.2073999996581,
                            48.9025259998301
                        ],
                        [
                            -97.211399000164,
                            48.9056020000067
                        ],
                        [
                            -97.2126539996165,
                            48.9089560001274
                        ],
                        [
                            -97.2107759996499,
                            48.916026999909
                        ],
                        [
                            -97.2198059996331,
                            48.9221459997858
                        ],
                        [
                            -97.2144060000848,
                            48.9316410002227
                        ],
                        [
                            -97.224614000426,
                            48.9337979997217
                        ],
                        [
                            -97.2270530000843,
                            48.9392259998617
                        ],
                        [
                            -97.2266239997388,
                            48.9458259999721
                        ],
                        [
                            -97.2339959998635,
                            48.9474339998402
                        ],
                        [
                            -97.2316860000766,
                            48.9561599998941
                        ],
                        [
                            -97.2274980003232,
                            48.9623419997464
                        ],
                        [
                            -97.2296499999262,
                            48.9642259999533
                        ],
                        [
                            -97.2386120001374,
                            48.9665000002683
                        ],
                        [
                            -97.238191000188,
                            48.9833399999126
                        ],
                        [
                            -97.2339890001908,
                            48.9890889997674
                        ],
                        [
                            -97.2266460003785,
                            48.9929900001928
                        ],
                        [
                            -97.2275130003918,
                            48.994561000059
                        ],
                        [
                            -97.2342469999336,
                            48.9964659998862
                        ],
                        [
                            -97.2284389999563,
                            49.0006740001262
                        ],
                        [
                            -95.1537109999073,
                            48.9989029999503
                        ],
                        [
                            -95.1533140002473,
                            49.3843579999647
                        ],
                        [
                            -95.1502350003565,
                            49.3829629997722
                        ],
                        [
                            -95.1497469995615,
                            49.3805630000064
                        ],
                        [
                            -95.1418079998976,
                            49.3782970000895
                        ],
                        [
                            -95.1264669999718,
                            49.3694290000052
                        ],
                        [
                            -95.103147000199,
                            49.365060000245
                        ],
                        [
                            -95.1031270001075,
                            49.3634740002857
                        ],
                        [
                            -95.0583749996762,
                            49.3531299999141
                        ],
                        [
                            -95.0143699996075,
                            49.3563500001063
                        ],
                        [
                            -94.9888799997901,
                            49.3688319999819
                        ],
                        [
                            -94.9742739996331,
                            49.3675429998358
                        ],
                        [
                            -94.9574389998802,
                            49.3700659997379
                        ],
                        [
                            -94.909326000424,
                            49.3501060002349
                        ],
                        [
                            -94.8784629999241,
                            49.3331840000237
                        ],
                        [
                            -94.8542829998108,
                            49.3241080000457
                        ],
                        [
                            -94.8368730001226,
                            49.3240350000192
                        ],
                        [
                            -94.8165009998211,
                            49.3209970000932
                        ],
                        [
                            -94.8243180001846,
                            49.3088330001721
                        ],
                        [
                            -94.8255550000938,
                            49.2943889997429
                        ],
                        [
                            -94.7974600000385,
                            49.2143529998214
                        ],
                        [
                            -94.7980829996543,
                            49.1978710002075
                        ],
                        [
                            -94.7732330000694,
                            49.1207320000931
                        ],
                        [
                            -94.7502780001697,
                            49.0992729998738
                        ],
                        [
                            -94.7502179998952,
                            48.9999920001529
                        ],
                        [
                            -94.7189410000169,
                            48.9999910000293
                        ],
                        [
                            -94.684346999698,
                            48.8839580002813
                        ],
                        [
                            -94.6842789999259,
                            48.8723899997641
                        ],
                        [
                            -94.6925420003533,
                            48.8689530002082
                        ],
                        [
                            -94.6902979997904,
                            48.8633220002843
                        ],
                        [
                            -94.6931199997633,
                            48.8533299999502
                        ],
                        [
                            -94.6856980003083,
                            48.8401180002399
                        ],
                        [
                            -94.6972420002928,
                            48.8356840000254
                        ],
                        [
                            -94.7020939996701,
                            48.831705000149
                        ],
                        [
                            -94.7043790002408,
                            48.8242630002389
                        ],
                        [
                            -94.695258000021,
                            48.808808000141
                        ],
                        [
                            -94.6960870003995,
                            48.7992770001623
                        ],
                        [
                            -94.6943469996267,
                            48.7821839998632
                        ],
                        [
                            -94.6870420003476,
                            48.7750090002583
                        ],
                        [
                            -94.6729869998364,
                            48.7693769999519
                        ],
                        [
                            -94.6519729996525,
                            48.7558580002316
                        ],
                        [
                            -94.6453439996927,
                            48.7490290000902
                        ],
                        [
                            -94.6453870002487,
                            48.744079000289
                        ],
                        [
                            -94.6412420001529,
                            48.7412529997711
                        ],
                        [
                            -94.61902600022,
                            48.7372569997857
                        ],
                        [
                            -94.6109979999692,
                            48.7319350001858
                        ],
                        [
                            -94.6011529998512,
                            48.7281519997147
                        ],
                        [
                            -94.5902850000843,
                            48.7180220001119
                        ],
                        [
                            -94.5490750002243,
                            48.7146469999227
                        ],
                        [
                            -94.5377429999518,
                            48.7024130000466
                        ],
                        [
                            -94.508866000272,
                            48.7003519999245
                        ],
                        [
                            -94.4996150003558,
                            48.6979559997559
                        ],
                        [
                            -94.470209999698,
                            48.6964619999902
                        ],
                        [
                            -94.4523089997401,
                            48.6923610000179
                        ],
                        [
                            -94.438701000016,
                            48.6948780001274
                        ],
                        [
                            -94.4213459996811,
                            48.7086749999596
                        ],
                        [
                            -94.4161829999593,
                            48.710916999995
                        ],
                        [
                            -94.3842309996882,
                            48.7117609997965
                        ],
                        [
                            -94.3686179997759,
                            48.7063219998551
                        ],
                        [
                            -94.3427670002834,
                            48.7032469999853
                        ],
                        [
                            -94.3284309995648,
                            48.7043909997631
                        ],
                        [
                            -94.3085849996932,
                            48.710176000034
                        ],
                        [
                            -94.2910260000421,
                            48.707692000062
                        ],
                        [
                            -94.2828949998592,
                            48.7055059998298
                        ],
                        [
                            -94.2745119997812,
                            48.6998020002198
                        ],
                        [
                            -94.2645029996316,
                            48.6988610002104
                        ],
                        [
                            -94.2605900002377,
                            48.6963089997759
                        ],
                        [
                            -94.2512430000621,
                            48.683450999883
                        ],
                        [
                            -94.250690999693,
                            48.6781789999953
                        ],
                        [
                            -94.2546199998787,
                            48.6613269997067
                        ],
                        [
                            -94.2502820003374,
                            48.656364000261
                        ],
                        [
                            -94.2443980001922,
                            48.6534210002668
                        ],
                        [
                            -94.2243189997936,
                            48.649473999812
                        ],
                        [
                            -94.199499000346,
                            48.6509020000073
                        ],
                        [
                            -94.1677450004417,
                            48.648047000177
                        ],
                        [
                            -94.1573780000918,
                            48.6457119999008
                        ],
                        [
                            -94.1100399996891,
                            48.6441379999712
                        ],
                        [
                            -94.0999150000869,
                            48.6457320000356
                        ],
                        [
                            -94.0912209996643,
                            48.6435129997077
                        ],
                        [
                            -94.0657719998547,
                            48.6459950001502
                        ],
                        [
                            -94.0642379998446,
                            48.6436089999573
                        ],
                        [
                            -94.0431790003532,
                            48.6433219999909
                        ],
                        [
                            -94.0356060003872,
                            48.6409280000267
                        ],
                        [
                            -94.0069210003279,
                            48.6431239997075
                        ],
                        [
                            -93.9765159995874,
                            48.6375229998555
                        ],
                        [
                            -93.9633539999272,
                            48.6371100000998
                        ],
                        [
                            -93.9543880004163,
                            48.6337080002682
                        ],
                        [
                            -93.9441950001438,
                            48.6322649999273
                        ],
                        [
                            -93.9269750004264,
                            48.6312030002503
                        ],
                        [
                            -93.9166189999472,
                            48.6321450002052
                        ],
                        [
                            -93.9115340000431,
                            48.6346539998348
                        ],
                        [
                            -93.8869339998052,
                            48.6307440001736
                        ],
                        [
                            -93.8516220003342,
                            48.6300970001727
                        ],
                        [
                            -93.8407600004151,
                            48.6285369999917
                        ],
                        [
                            -93.8343289999236,
                            48.6249449998966
                        ],
                        [
                            -93.8279739996001,
                            48.6129919998202
                        ],
                        [
                            -93.8226629997403,
                            48.609056999844
                        ],
                        [
                            -93.8185460001319,
                            48.5953049999228
                        ],
                        [
                            -93.8068060003288,
                            48.5776050001533
                        ],
                        [
                            -93.8054150004338,
                            48.5683950000592
                        ],
                        [
                            -93.8090140000083,
                            48.5559200001503
                        ],
                        [
                            -93.8121339999071,
                            48.5506959998439
                        ],
                        [
                            -93.8112429996043,
                            48.5423900002448
                        ],
                        [
                            -93.8184020001919,
                            48.5344270000327
                        ],
                        [
                            -93.8182699999474,
                            48.5300279998349
                        ],
                        [
                            -93.8151860000337,
                            48.5264969997494
                        ],
                        [
                            -93.794454999707,
                            48.5159860000505
                        ],
                        [
                            -93.7529340004008,
                            48.5151070001118
                        ],
                        [
                            -93.7379680003937,
                            48.5178169998202
                        ],
                        [
                            -93.7091529997398,
                            48.5180119999161
                        ],
                        [
                            -93.6908960001298,
                            48.5145739999985
                        ],
                        [
                            -93.6745629996189,
                            48.5162850001055
                        ],
                        [
                            -93.6566499999655,
                            48.5157209998043
                        ],
                        [
                            -93.6430790000513,
                            48.5182759998387
                        ],
                        [
                            -93.6381670003996,
                            48.5225289998889
                        ],
                        [
                            -93.6354449999859,
                            48.5276779997891
                        ],
                        [
                            -93.6288639997066,
                            48.5312100002024
                        ],
                        [
                            -93.6264579996603,
                            48.530987000056
                        ],
                        [
                            -93.6183199998047,
                            48.523956999821
                        ],
                        [
                            -93.6106170001423,
                            48.5216550001086
                        ],
                        [
                            -93.6037329999143,
                            48.5233180000461
                        ],
                        [
                            -93.5943740000431,
                            48.5287749999084
                        ],
                        [
                            -93.5879569997953,
                            48.5288809997476
                        ],
                        [
                            -93.5783300001355,
                            48.5265069999354
                        ],
                        [
                            -93.5620569998991,
                            48.5288839998408
                        ],
                        [
                            -93.5471839997784,
                            48.5286740000235
                        ],
                        [
                            -93.5154440001178,
                            48.5347830000118
                        ],
                        [
                            -93.5001459998497,
                            48.54118400011
                        ],
                        [
                            -93.4694260003632,
                            48.5452070001431
                        ],
                        [
                            -93.4607860003673,
                            48.5505479999227
                        ],
                        [
                            -93.4566589998149,
                            48.5618300001599
                        ],
                        [
                            -93.4570060001445,
                            48.5672040000503
                        ],
                        [
                            -93.4617129997568,
                            48.5740300002613
                        ],
                        [
                            -93.4659819997011,
                            48.587287000012
                        ],
                        [
                            -93.4642989997281,
                            48.5917800000222
                        ],
                        [
                            -93.4384919997182,
                            48.5933740001314
                        ],
                        [
                            -93.4254809996707,
                            48.6012919999665
                        ],
                        [
                            -93.4052649999032,
                            48.6093299997678
                        ],
                        [
                            -93.3970940004356,
                            48.6033869998303
                        ],
                        [
                            -93.3711519998082,
                            48.6050690000668
                        ],
                        [
                            -93.3676279996785,
                            48.6070079998909
                        ],
                        [
                            -93.3621319998707,
                            48.6138309999509
                        ],
                        [
                            -93.3550660000679,
                            48.6114149999717
                        ],
                        [
                            -93.352636999557,
                            48.613598000232
                        ],
                        [
                            -93.3531339996745,
                            48.6156509998316
                        ],
                        [
                            -93.3481819998399,
                            48.6264130000758
                        ],
                        [
                            -93.2548700000636,
                            48.6427460001915
                        ],
                        [
                            -93.207426000254,
                            48.6424190001573
                        ],
                        [
                            -93.1841719997052,
                            48.6282489999187
                        ],
                        [
                            -93.1784700000331,
                            48.623278999971
                        ],
                        [
                            -93.0895960002555,
                            48.6277049998482
                        ],
                        [
                            -92.9849630000774,
                            48.6237310000992
                        ],
                        [
                            -92.9548760002525,
                            48.6314930002496
                        ],
                        [
                            -92.9501190004116,
                            48.6304189997208
                        ],
                        [
                            -92.9498270003335,
                            48.6082740000211
                        ],
                        [
                            -92.9296090000178,
                            48.6068840002051
                        ],
                        [
                            -92.9099480000941,
                            48.5963130000334
                        ],
                        [
                            -92.8946880003591,
                            48.5949139997432
                        ],
                        [
                            -92.7280460002223,
                            48.5392889997665
                        ],
                        [
                            -92.6578809999664,
                            48.5462630002398
                        ],
                        [
                            -92.6349310000896,
                            48.5428730000754
                        ],
                        [
                            -92.6255410002562,
                            48.5175490000565
                        ],
                        [
                            -92.6266390002482,
                            48.5143739999584
                        ],
                        [
                            -92.6251510002688,
                            48.5130480000871
                        ],
                        [
                            -92.6308760004055,
                            48.5091909997267
                        ],
                        [
                            -92.6314630000363,
                            48.5067899999822
                        ],
                        [
                            -92.6272369997496,
                            48.5033829997411
                        ],
                        [
                            -92.6366960000783,
                            48.4994300002592
                        ],
                        [
                            -92.6540389998193,
                            48.501635000035
                        ],
                        [
                            -92.6614179996167,
                            48.4965559999435
                        ],
                        [
                            -92.6848660003342,
                            48.4976109997945
                        ],
                        [
                            -92.6988139998169,
                            48.4948849999135
                        ],
                        [
                            -92.701297999681,
                            48.4845869997746
                        ],
                        [
                            -92.7092599998095,
                            48.4730909997718
                        ],
                        [
                            -92.7086380000186,
                            48.4703489997901
                        ],
                        [
                            -92.7125620001814,
                            48.4630130001869
                        ],
                        [
                            -92.6879979999285,
                            48.4438890000716
                        ],
                        [
                            -92.6632710003673,
                            48.4401840001251
                        ],
                        [
                            -92.6560270002892,
                            48.4367089998108
                        ],
                        [
                            -92.5756360001487,
                            48.4408269997328
                        ],
                        [
                            -92.5372020002309,
                            48.4477030002689
                        ],
                        [
                            -92.5072850002853,
                            48.4478760000842
                        ],
                        [
                            -92.4891900001587,
                            48.4303279998491
                        ],
                        [
                            -92.4820819996248,
                            48.4286620002168
                        ],
                        [
                            -92.4811510000373,
                            48.4253469999263
                        ],
                        [
                            -92.4755840000845,
                            48.4187920000538
                        ],
                        [
                            -92.4563249998436,
                            48.4142040002373
                        ],
                        [
                            -92.4563900001409,
                            48.4011339998525
                        ],
                        [
                            -92.4767499998485,
                            48.3717599997042
                        ],
                        [
                            -92.4699490003595,
                            48.351837000206
                        ],
                        [
                            -92.4378260003843,
                            48.3098400001932
                        ],
                        [
                            -92.4320030003383,
                            48.3050649998995
                        ],
                        [
                            -92.4260699997893,
                            48.3044960001607
                        ],
                        [
                            -92.4162759997248,
                            48.2954670001031
                        ],
                        [
                            -92.393257000251,
                            48.2597170002935
                        ],
                        [
                            -92.3934420001989,
                            48.2574769997058
                        ],
                        [
                            -92.3893049996008,
                            48.2533159997828
                        ],
                        [
                            -92.3921459998402,
                            48.2527230002202
                        ],
                        [
                            -92.3921480003883,
                            48.251078000116
                        ],
                        [
                            -92.3867069999339,
                            48.2516700001026
                        ],
                        [
                            -92.3851009999537,
                            48.2499660001488
                        ],
                        [
                            -92.3881129998974,
                            48.2467329998116
                        ],
                        [
                            -92.3871909996325,
                            48.2446059999761
                        ],
                        [
                            -92.3839059998774,
                            48.2446960001304
                        ],
                        [
                            -92.3838830003112,
                            48.2393330000061
                        ],
                        [
                            -92.3788140003007,
                            48.235359000193
                        ],
                        [
                            -92.3785459996137,
                            48.230408000183
                        ],
                        [
                            -92.3731300001719,
                            48.2235909997602
                        ],
                        [
                            -92.3712780001445,
                            48.2237160000451
                        ],
                        [
                            -92.3699449999759,
                            48.2201549999774
                        ],
                        [
                            -92.3664819999455,
                            48.222025000047
                        ],
                        [
                            -92.3680229996283,
                            48.2238300000053
                        ],
                        [
                            -92.3620520003427,
                            48.2227470000076
                        ],
                        [
                            -92.3532730004296,
                            48.230026000024
                        ],
                        [
                            -92.3414969997431,
                            48.2317310000039
                        ],
                        [
                            -92.338792999771,
                            48.2347430000598
                        ],
                        [
                            -92.3321160001305,
                            48.2337600000986
                        ],
                        [
                            -92.3146650004345,
                            48.2405270002956
                        ],
                        [
                            -92.2807299997165,
                            48.2442749997947
                        ],
                        [
                            -92.2697420002989,
                            48.2482410001089
                        ],
                        [
                            -92.2737059997464,
                            48.2567449997945
                        ],
                        [
                            -92.2903680001453,
                            48.2655269998163
                        ],
                        [
                            -92.2938570001149,
                            48.2702590000027
                        ],
                        [
                            -92.2929990003224,
                            48.2764069997994
                        ],
                        [
                            -92.2950530000162,
                            48.2765869998078
                        ],
                        [
                            -92.3014509999974,
                            48.2886099998082
                        ],
                        [
                            -92.2945280003096,
                            48.3064570002353
                        ],
                        [
                            -92.3062399996254,
                            48.3163840002333
                        ],
                        [
                            -92.3045609998504,
                            48.3229770000217
                        ],
                        [
                            -92.2954120000414,
                            48.3239569999928
                        ],
                        [
                            -92.2889939999687,
                            48.3429899997173
                        ],
                        [
                            -92.2624770003044,
                            48.354848000202
                        ],
                        [
                            -92.2228130001501,
                            48.3492029999156
                        ],
                        [
                            -92.2169830004315,
                            48.3451140000485
                        ],
                        [
                            -92.2068029996794,
                            48.3455959999463
                        ],
                        [
                            -92.2077130002487,
                            48.3478280001604
                        ],
                        [
                            -92.2036839996056,
                            48.3520630000385
                        ],
                        [
                            -92.1948739997303,
                            48.3503960001796
                        ],
                        [
                            -92.1935720004222,
                            48.3486119997802
                        ],
                        [
                            -92.178418999919,
                            48.3518829998464
                        ],
                        [
                            -92.1788859998993,
                            48.3552079998953
                        ],
                        [
                            -92.1773369998206,
                            48.3572070000552
                        ],
                        [
                            -92.1619830003743,
                            48.3633060000017
                        ],
                        [
                            -92.1450489999888,
                            48.3656510001789
                        ],
                        [
                            -92.1435829997508,
                            48.3561210001661
                        ],
                        [
                            -92.0834889995687,
                            48.353867999923
                        ],
                        [
                            -92.0779109997452,
                            48.3582600002316
                        ],
                        [
                            -92.055227999832,
                            48.3592119999454
                        ],
                        [
                            -92.048648000276,
                            48.3488610001697
                        ],
                        [
                            -92.0457340002416,
                            48.3479009997326
                        ],
                        [
                            -92.0451529995602,
                            48.3457769997179
                        ],
                        [
                            -92.0476559996909,
                            48.3437650000269
                        ],
                        [
                            -92.0465629997217,
                            48.3347390002679
                        ],
                        [
                            -92.0377210000595,
                            48.333183000012
                        ],
                        [
                            -92.0308650003189,
                            48.3258299998533
                        ],
                        [
                            -92.0001259996675,
                            48.3213540002335
                        ],
                        [
                            -92.0129729996835,
                            48.2973919999957
                        ],
                        [
                            -92.0120599996395,
                            48.2872699999711
                        ],
                        [
                            -92.0072409998742,
                            48.2803899997412
                        ],
                        [
                            -92.0065739998774,
                            48.2654230002736
                        ],
                        [
                            -91.9895449999558,
                            48.2602140002303
                        ],
                        [
                            -91.9807719998905,
                            48.2478010002972
                        ],
                        [
                            -91.9775549999073,
                            48.2471399998632
                        ],
                        [
                            -91.9767670004351,
                            48.244513000109
                        ],
                        [
                            -91.97060999958,
                            48.2481240001505
                        ],
                        [
                            -91.9725650004379,
                            48.2503959997211
                        ],
                        [
                            -91.9710270002298,
                            48.2533430000925
                        ],
                        [
                            -91.959565000261,
                            48.2535529998209
                        ],
                        [
                            -91.9544660001133,
                            48.2518379998285
                        ],
                        [
                            -91.9542230004391,
                            48.2492489997529
                        ],
                        [
                            -91.9484749998379,
                            48.2458410000676
                        ],
                        [
                            -91.9498089998315,
                            48.2444259997907
                        ],
                        [
                            -91.9583609999638,
                            48.2437849999188
                        ],
                        [
                            -91.9574370000491,
                            48.2388289997814
                        ],
                        [
                            -91.9598990001719,
                            48.2367950000471
                        ],
                        [
                            -91.9582899998187,
                            48.2330239998468
                        ],
                        [
                            -91.9517560002934,
                            48.233367000066
                        ],
                        [
                            -91.9451549999226,
                            48.230442000109
                        ],
                        [
                            -91.9418380003804,
                            48.2306020001619
                        ],
                        [
                            -91.9373569998257,
                            48.2342140001729
                        ],
                        [
                            -91.9208019995569,
                            48.2367490001158
                        ],
                        [
                            -91.9157660000567,
                            48.2388709999898
                        ],
                        [
                            -91.9059910002589,
                            48.2371309997476
                        ],
                        [
                            -91.8934699997578,
                            48.2376990000071
                        ],
                        [
                            -91.8846919996696,
                            48.2273220002242
                        ],
                        [
                            -91.867882000031,
                            48.21909600002
                        ],
                        [
                            -91.8643820001907,
                            48.2070310002006
                        ],
                        [
                            -91.8157719997188,
                            48.2117499997772
                        ],
                        [
                            -91.8144729998853,
                            48.2086639997212
                        ],
                        [
                            -91.8090380001769,
                            48.2060120000104
                        ],
                        [
                            -91.7918030003909,
                            48.2024930001874
                        ],
                        [
                            -91.7890039999843,
                            48.1965479997744
                        ],
                        [
                            -91.7861379996305,
                            48.1964069997353
                        ],
                        [
                            -91.7811740001003,
                            48.2004330002302
                        ],
                        [
                            -91.764674999908,
                            48.2005889998394
                        ],
                        [
                            -91.7608729999439,
                            48.204787999927
                        ],
                        [
                            -91.7563939999373,
                            48.2058229998689
                        ],
                        [
                            -91.7539300001647,
                            48.2012020000203
                        ],
                        [
                            -91.7490720000414,
                            48.1988510001716
                        ],
                        [
                            -91.7419440003142,
                            48.1991269999423
                        ],
                        [
                            -91.7423140002101,
                            48.2044989998713
                        ],
                        [
                            -91.7152329995828,
                            48.1992970002499
                        ],
                        [
                            -91.7105080004272,
                            48.1938989998202
                        ],
                        [
                            -91.7115989998482,
                            48.1890999999873
                        ],
                        [
                            -91.7214049996082,
                            48.1802510002526
                        ],
                        [
                            -91.7245730000858,
                            48.1706580001576
                        ],
                        [
                            -91.7232840002981,
                            48.1692709997098
                        ],
                        [
                            -91.7175480002908,
                            48.1718009997504
                        ],
                        [
                            -91.7093839995976,
                            48.1727159999261
                        ],
                        [
                            -91.7053189998678,
                            48.1707759997937
                        ],
                        [
                            -91.7051099996304,
                            48.1597160000048
                        ],
                        [
                            -91.7085119995615,
                            48.1527050001708
                        ],
                        [
                            -91.703558000077,
                            48.1453949997542
                        ],
                        [
                            -91.6993269997675,
                            48.1447329998935
                        ],
                        [
                            -91.6981619998283,
                            48.1416450001602
                        ],
                        [
                            -91.699971000198,
                            48.1318379997868
                        ],
                        [
                            -91.7041310003624,
                            48.1246070002006
                        ],
                        [
                            -91.708090999612,
                            48.1229799998047
                        ],
                        [
                            -91.7122159996163,
                            48.1168789997085
                        ],
                        [
                            -91.7118440000706,
                            48.1146049997752
                        ],
                        [
                            -91.7035260002899,
                            48.1135559999915
                        ],
                        [
                            -91.6928500001436,
                            48.1163660001027
                        ],
                        [
                            -91.6915219999978,
                            48.117621999953
                        ],
                        [
                            -91.6923660004449,
                            48.1193379998689
                        ],
                        [
                            -91.6828369997961,
                            48.1221250002043
                        ],
                        [
                            -91.6876110002538,
                            48.1116989997981
                        ],
                        [
                            -91.6768759996579,
                            48.1072720001602
                        ],
                        [
                            -91.6675200001597,
                            48.1083660000703
                        ],
                        [
                            -91.6652110001978,
                            48.1070179999699
                        ],
                        [
                            -91.6626539996405,
                            48.1114929999159
                        ],
                        [
                            -91.6532610003324,
                            48.1141449997953
                        ],
                        [
                            -91.6516140003444,
                            48.1127420001524
                        ],
                        [
                            -91.6535629995578,
                            48.1095680000439
                        ],
                        [
                            -91.640173000292,
                            48.0969309999296
                        ],
                        [
                            -91.6152580004098,
                            48.1019139998405
                        ],
                        [
                            -91.5889559999323,
                            48.1021740002466
                        ],
                        [
                            -91.5758579998461,
                            48.1065170002505
                        ],
                        [
                            -91.5592669995924,
                            48.1082749997031
                        ],
                        [
                            -91.5529549998249,
                            48.1030159999382
                        ],
                        [
                            -91.5697350002246,
                            48.0933449997229
                        ],
                        [
                            -91.5754600003612,
                            48.0662940002377
                        ],
                        [
                            -91.5729999998882,
                            48.0572929999017
                        ],
                        [
                            -91.5756579998296,
                            48.0487919999318
                        ],
                        [
                            -91.5672479999875,
                            48.0437259997312
                        ],
                        [
                            -91.5425160004034,
                            48.0532750001277
                        ],
                        [
                            -91.4886500003231,
                            48.0680729999843
                        ],
                        [
                            -91.4654989997065,
                            48.0667789998055
                        ],
                        [
                            -91.450331000033,
                            48.0688050001498
                        ],
                        [
                            -91.4465809999475,
                            48.0673919997176
                        ],
                        [
                            -91.4471249999206,
                            48.0631879998242
                        ],
                        [
                            -91.4380930002875,
                            48.0521069997114
                        ],
                        [
                            -91.4388769995618,
                            48.0499789999399
                        ],
                        [
                            -91.4296420004375,
                            48.0486079998045
                        ],
                        [
                            -91.4138660003185,
                            48.0535259998702
                        ],
                        [
                            -91.3911359996513,
                            48.0570820002289
                        ],
                        [
                            -91.3788910002331,
                            48.0657589999682
                        ],
                        [
                            -91.370874999678,
                            48.0694169998745
                        ],
                        [
                            -91.3651429998686,
                            48.0669750000249
                        ],
                        [
                            -91.3507600001927,
                            48.0716799998896
                        ],
                        [
                            -91.3401600002144,
                            48.0732360001709
                        ],
                        [
                            -91.3365790001832,
                            48.069630000143
                        ],
                        [
                            -91.3325889998981,
                            48.0693309999446
                        ],
                        [
                            -91.3247890001514,
                            48.072607000142
                        ],
                        [
                            -91.2902149999239,
                            48.0739469999495
                        ],
                        [
                            -91.2759619999444,
                            48.0784890002722
                        ],
                        [
                            -91.2663810003153,
                            48.0787140000525
                        ],
                        [
                            -91.2501120002768,
                            48.0840870000961
                        ],
                        [
                            -91.2349320000095,
                            48.0959230002434
                        ],
                        [
                            -91.2144280003619,
                            48.1029399999452
                        ],
                        [
                            -91.1901989998447,
                            48.1253439997299
                        ],
                        [
                            -91.1832190002556,
                            48.1222399998147
                        ],
                        [
                            -91.1561300001307,
                            48.1404730002787
                        ],
                        [
                            -91.1377329998803,
                            48.1491500002711
                        ],
                        [
                            -91.1380109997146,
                            48.1517520002982
                        ],
                        [
                            -91.1403590000346,
                            48.151617999959
                        ],
                        [
                            -91.1429059996479,
                            48.1543749999416
                        ],
                        [
                            -91.1200520000305,
                            48.1604169997186
                        ],
                        [
                            -91.114870999867,
                            48.1660620001987
                        ],
                        [
                            -91.0922669995965,
                            48.1731080002453
                        ],
                        [
                            -91.0885819998083,
                            48.1775619999876
                        ],
                        [
                            -91.082733999648,
                            48.1807640001841
                        ],
                        [
                            -91.0756619999974,
                            48.1792110001552
                        ],
                        [
                            -91.0655569995883,
                            48.1812209997634
                        ],
                        [
                            -91.0629260003094,
                            48.185221000294
                        ],
                        [
                            -91.0592709997601,
                            48.1853110000812
                        ],
                        [
                            -91.0555550000097,
                            48.1880250002681
                        ],
                        [
                            -91.0366399997255,
                            48.1895580002342
                        ],
                        [
                            -91.0315880003317,
                            48.1884560001353
                        ],
                        [
                            -91.0242050003365,
                            48.1900709998483
                        ],
                        [
                            -91.0226670001284,
                            48.1924699999629
                        ],
                        [
                            -91.01242899965,
                            48.1980789999371
                        ],
                        [
                            -91.0033490003363,
                            48.2001820000998
                        ],
                        [
                            -91.0042389999158,
                            48.2026279998136
                        ],
                        [
                            -90.9769399997287,
                            48.2194300001397
                        ],
                        [
                            -90.9250899997074,
                            48.2298940000268
                        ],
                        [
                            -90.9149659999301,
                            48.2306010002379
                        ],
                        [
                            -90.9068249997015,
                            48.2373380002596
                        ],
                        [
                            -90.8854799999798,
                            48.2457839998087
                        ],
                        [
                            -90.8814589997326,
                            48.2404700000975
                        ],
                        [
                            -90.8751100001552,
                            48.2377799999022
                        ],
                        [
                            -90.8670769998816,
                            48.2381760000693
                        ],
                        [
                            -90.8473520003837,
                            48.2444379998228
                        ],
                        [
                            -90.8436240000395,
                            48.2435759998292
                        ],
                        [
                            -90.8428700000041,
                            48.2412000002367
                        ],
                        [
                            -90.839175999995,
                            48.2395110000671
                        ],
                        [
                            -90.8377769997041,
                            48.2347160001018
                        ],
                        [
                            -90.839796999961,
                            48.2282939997319
                        ],
                        [
                            -90.8373230001427,
                            48.2256210000531
                        ],
                        [
                            -90.8339940000066,
                            48.1877460000944
                        ],
                        [
                            -90.8362939997477,
                            48.1769710001226
                        ],
                        [
                            -90.8325889998679,
                            48.1737649997069
                        ],
                        [
                            -90.8246119996708,
                            48.1768810000685
                        ],
                        [
                            -90.8236419997253,
                            48.1781299999741
                        ],
                        [
                            -90.8258290003868,
                            48.1834519999592
                        ],
                        [
                            -90.8211150002036,
                            48.1847090002674
                        ],
                        [
                            -90.8177009996786,
                            48.1795660000231
                        ],
                        [
                            -90.8042069997574,
                            48.1778339997961
                        ],
                        [
                            -90.800711000115,
                            48.1632189997698
                        ],
                        [
                            -90.7966110002251,
                            48.1593620000423
                        ],
                        [
                            -90.7932489995787,
                            48.1613830002522
                        ],
                        [
                            -90.7904309998038,
                            48.159992999726
                        ],
                        [
                            -90.7858720003296,
                            48.1609000001619
                        ],
                        [
                            -90.7812650002764,
                            48.1646959997492
                        ],
                        [
                            -90.7774390000229,
                            48.1634120002384
                        ],
                        [
                            -90.7762570003653,
                            48.1618860001456
                        ],
                        [
                            -90.7790309997593,
                            48.1507780001389
                        ],
                        [
                            -90.7779339995922,
                            48.1482470002202
                        ],
                        [
                            -90.7967829997542,
                            48.1395009998735
                        ],
                        [
                            -90.7979719999828,
                            48.1368940000164
                        ],
                        [
                            -90.7952420000715,
                            48.1354899999581
                        ],
                        [
                            -90.7821080000003,
                            48.1354269998202
                        ],
                        [
                            -90.7834019998109,
                            48.1329639999064
                        ],
                        [
                            -90.7875660001732,
                            48.1328739999688
                        ],
                        [
                            -90.7899229998157,
                            48.1299010001972
                        ],
                        [
                            -90.7768180000569,
                            48.1240999998353
                        ],
                        [
                            -90.7741929997275,
                            48.1185739999031
                        ],
                        [
                            -90.7688769998448,
                            48.1166069999171
                        ],
                        [
                            -90.7670690001983,
                            48.1144819997328
                        ],
                        [
                            -90.767620999669,
                            48.110300000041
                        ],
                        [
                            -90.7616289995687,
                            48.0982800001032
                        ],
                        [
                            -90.7516079997236,
                            48.0909680002566
                        ],
                        [
                            -90.7415199999313,
                            48.0945829998364
                        ],
                        [
                            -90.7037019999566,
                            48.0960090001246
                        ],
                        [
                            -90.6866169999584,
                            48.1005099997599
                        ],
                        [
                            -90.6415950000886,
                            48.1035150001794
                        ],
                        [
                            -90.6268859999995,
                            48.1118459997449
                        ],
                        [
                            -90.61983799974,
                            48.1117130000397
                        ],
                        [
                            -90.6064020004434,
                            48.1159659999039
                        ],
                        [
                            -90.5914599998274,
                            48.1175459999677
                        ],
                        [
                            -90.5905740004458,
                            48.1197619999429
                        ],
                        [
                            -90.5798969995763,
                            48.12392199973
                        ],
                        [
                            -90.5759049996414,
                            48.1209070000473
                        ],
                        [
                            -90.5661120003002,
                            48.1226190002434
                        ],
                        [
                            -90.5592950000194,
                            48.121681000216
                        ],
                        [
                            -90.5558930000883,
                            48.1170719999527
                        ],
                        [
                            -90.5697679997761,
                            48.1069519998802
                        ],
                        [
                            -90.567472000233,
                            48.1011719998763
                        ],
                        [
                            -90.5568379999195,
                            48.0960079999734
                        ],
                        [
                            -90.517075000031,
                            48.0994020000217
                        ],
                        [
                            -90.5108710002185,
                            48.0973890001582
                        ],
                        [
                            -90.5047739996379,
                            48.099729999944
                        ],
                        [
                            -90.4961479998857,
                            48.0987809997176
                        ],
                        [
                            -90.493797000091,
                            48.1013179998909
                        ],
                        [
                            -90.4870769998946,
                            48.099081999939
                        ],
                        [
                            -90.4802939999489,
                            48.1020990002413
                        ],
                        [
                            -90.4776350001827,
                            48.1054580000453
                        ],
                        [
                            -90.4710189997433,
                            48.1060760002062
                        ],
                        [
                            -90.4677120002468,
                            48.1088179997835
                        ],
                        [
                            -90.4473840003263,
                            48.103430000263
                        ],
                        [
                            -90.4384489998793,
                            48.0987469999964
                        ],
                        [
                            -90.4103470001512,
                            48.1050479997599
                        ],
                        [
                            -90.403219000424,
                            48.105113999895
                        ],
                        [
                            -90.3864130000851,
                            48.0982090002503
                        ],
                        [
                            -90.3822579999436,
                            48.093181999962
                        ],
                        [
                            -90.3745419998624,
                            48.0909419997681
                        ],
                        [
                            -90.3730420001875,
                            48.0912169999312
                        ],
                        [
                            -90.3735219996882,
                            48.0938660002897
                        ],
                        [
                            -90.3676579996344,
                            48.094576999962
                        ],
                        [
                            -90.3457339997795,
                            48.094058999967
                        ],
                        [
                            -90.3384379998229,
                            48.0962070002951
                        ],
                        [
                            -90.3371769996243,
                            48.0997710001621
                        ],
                        [
                            -90.3300520002702,
                            48.1023990002057
                        ],
                        [
                            -90.3123859995906,
                            48.1053000001471
                        ],
                        [
                            -90.3056339996071,
                            48.1051169998173
                        ],
                        [
                            -90.2933260004397,
                            48.099131000199
                        ],
                        [
                            -90.2893369999796,
                            48.0989930001188
                        ],
                        [
                            -90.2746360002863,
                            48.1032600000086
                        ],
                        [
                            -90.2538699997996,
                            48.1022450000228
                        ],
                        [
                            -90.2246919998208,
                            48.1081479998439
                        ],
                        [
                            -90.2104709996283,
                            48.1063009998814
                        ],
                        [
                            -90.1886790000179,
                            48.1079469997184
                        ],
                        [
                            -90.1766050003039,
                            48.1124450000722
                        ],
                        [
                            -90.164226999918,
                            48.1097250001333
                        ],
                        [
                            -90.1507210003012,
                            48.1102689997626
                        ],
                        [
                            -90.1408629997643,
                            48.1125029998632
                        ],
                        [
                            -90.1326450004411,
                            48.1117679999234
                        ],
                        [
                            -90.1226029997813,
                            48.1056019999548
                        ],
                        [
                            -90.1162590002268,
                            48.1043030000736
                        ],
                        [
                            -90.0916389998974,
                            48.1046299999353
                        ],
                        [
                            -90.0738729996587,
                            48.1011379999366
                        ],
                        [
                            -90.0576439998032,
                            48.0963639998004
                        ],
                        [
                            -90.0417260002923,
                            48.089236000093
                        ],
                        [
                            -90.0296259997407,
                            48.0875879999343
                        ],
                        [
                            -90.0235950001807,
                            48.0847080002419
                        ],
                        [
                            -90.0188349999667,
                            48.0720319999913
                        ],
                        [
                            -90.015057000292,
                            48.0671880001951
                        ],
                        [
                            -90.0084459998755,
                            48.0683960000041
                        ],
                        [
                            -89.9978519997449,
                            48.0575670001356
                        ],
                        [
                            -89.9938220001752,
                            48.0490270002872
                        ],
                        [
                            -89.9967370000345,
                            48.0365790001693
                        ],
                        [
                            -89.9937650002738,
                            48.0281980002316
                        ],
                        [
                            -89.989475000413,
                            48.0281780002723
                        ],
                        [
                            -89.9888939997317,
                            48.0256659999095
                        ],
                        [
                            -89.9843319998844,
                            48.0260790001114
                        ],
                        [
                            -89.9745250002995,
                            48.0231130002818
                        ],
                        [
                            -89.9682550003648,
                            48.0144819998612
                        ],
                        [
                            -89.9634900001279,
                            48.0146430002996
                        ],
                        [
                            -89.9531410002197,
                            48.0106709999965
                        ],
                        [
                            -89.9498399996727,
                            48.0133209998515
                        ],
                        [
                            -89.9505900004085,
                            48.0159009999602
                        ],
                        [
                            -89.9428960000686,
                            48.0143719998622
                        ],
                        [
                            -89.9344889997012,
                            48.0156279997024
                        ],
                        [
                            -89.9307450003617,
                            48.0081599999386
                        ],
                        [
                            -89.9259130001777,
                            48.0065829999648
                        ],
                        [
                            -89.9258789998425,
                            48.0037049999681
                        ],
                        [
                            -89.9279560000008,
                            48.0020380002082
                        ],
                        [
                            -89.9216330003627,
                            47.9998860001923
                        ],
                        [
                            -89.9222479995826,
                            47.9973110002629
                        ],
                        [
                            -89.9168020000036,
                            47.997014000263
                        ],
                        [
                            -89.9146600004462,
                            47.9943870001267
                        ],
                        [
                            -89.9048279998487,
                            47.9922610000426
                        ],
                        [
                            -89.8990800001458,
                            47.9880570002072
                        ],
                        [
                            -89.8949300000272,
                            47.9872099999294
                        ],
                        [
                            -89.8719590002339,
                            47.9855559997029
                        ],
                        [
                            -89.8681530000719,
                            47.989897999705
                        ],
                        [
                            -89.8471179999716,
                            47.9922230000157
                        ],
                        [
                            -89.8427090002852,
                            47.997422000249
                        ],
                        [
                            -89.8425679998198,
                            48.0013680002478
                        ],
                        [
                            -89.8448500000176,
                            48.003103999874
                        ],
                        [
                            -89.8318490000158,
                            47.9993999997331
                        ],
                        [
                            -89.8289230002858,
                            48.0045689997141
                        ],
                        [
                            -89.8224230001974,
                            48.0098460000196
                        ],
                        [
                            -89.8240230003297,
                            48.011079000147
                        ],
                        [
                            -89.8206189998506,
                            48.0127920000443
                        ],
                        [
                            -89.819802000066,
                            48.015099000127
                        ],
                        [
                            -89.8074449995965,
                            48.0172240002397
                        ],
                        [
                            -89.8060160000667,
                            48.0140259999855
                        ],
                        [
                            -89.7966200003855,
                            48.0146870002163
                        ],
                        [
                            -89.7952239995694,
                            48.0171540001662
                        ],
                        [
                            -89.7918529996005,
                            48.018203999702
                        ],
                        [
                            -89.7794270004323,
                            48.0183609999019
                        ],
                        [
                            -89.774998999581,
                            48.0226310002744
                        ],
                        [
                            -89.7736729999833,
                            48.0200719997871
                        ],
                        [
                            -89.7676790002332,
                            48.022605000101
                        ],
                        [
                            -89.7557279996446,
                            48.0233530000357
                        ],
                        [
                            -89.7442060002996,
                            48.0221859997964
                        ],
                        [
                            -89.7425690003574,
                            48.019834000039
                        ],
                        [
                            -89.7340249997226,
                            48.0218710001942
                        ],
                        [
                            -89.7311299999548,
                            48.0200670000612
                        ],
                        [
                            -89.731673000103,
                            48.0187649998946
                        ],
                        [
                            -89.7240480002584,
                            48.0189960002496
                        ],
                        [
                            -89.7231639996283,
                            48.0204809998778
                        ],
                        [
                            -89.7196889999024,
                            48.0176050000549
                        ],
                        [
                            -89.7243179996968,
                            48.0164849999572
                        ],
                        [
                            -89.7240440000604,
                            48.0136749998451
                        ],
                        [
                            -89.7187700000105,
                            48.0169890002178
                        ],
                        [
                            -89.7162500001614,
                            48.0167840000016
                        ],
                        [
                            -89.7159060002048,
                            48.0092459999966
                        ],
                        [
                            -89.7081449999178,
                            48.0101619997935
                        ],
                        [
                            -89.7014380001401,
                            48.0062110000873
                        ],
                        [
                            -89.6888790000042,
                            48.0107800000728
                        ],
                        [
                            -89.684930999552,
                            48.0098210000328
                        ],
                        [
                            -89.6737980003694,
                            48.0115099998725
                        ],
                        [
                            -89.6661409998395,
                            48.0073979997468
                        ],
                        [
                            -89.6632119997365,
                            48.0106180001849
                        ],
                        [
                            -89.6591610002504,
                            48.0104340001326
                        ],
                        [
                            -89.6567109998231,
                            48.0097030000998
                        ],
                        [
                            -89.6510650002275,
                            48.0036249999386
                        ],
                        [
                            -89.645447000221,
                            48.0062040000316
                        ],
                        [
                            -89.6379949997305,
                            48.0037799997704
                        ],
                        [
                            -89.637177000121,
                            48.0049449998868
                        ],
                        [
                            -89.6387739998802,
                            48.008166000274
                        ],
                        [
                            -89.637172999923,
                            48.0093079997194
                        ],
                        [
                            -89.6250869996151,
                            48.011516999809
                        ],
                        [
                            -89.6178669998265,
                            48.0109470000627
                        ],
                        [
                            -89.6103509997618,
                            48.0177799997315
                        ],
                        [
                            -89.6085070001303,
                            48.0124819999938
                        ],
                        [
                            -89.6104110000363,
                            48.0097629998245
                        ],
                        [
                            -89.607820999867,
                            48.0065660002938
                        ],
                        [
                            -89.5947489997201,
                            48.0043320000576
                        ],
                        [
                            -89.5909700002205,
                            48.0013629999104
                        ],
                        [
                            -89.5861010002266,
                            48.0014100002982
                        ],
                        [
                            -89.5810070001017,
                            47.9958989998485
                        ],
                        [
                            -89.570670999714,
                            47.9980200002623
                        ],
                        [
                            -89.5642879998014,
                            48.0029299998328
                        ],
                        [
                            -89.5000860002318,
                            48.0131479999824
                        ],
                        [
                            -89.4891959998252,
                            48.0145330000432
                        ],
                        [
                            -89.4833850003732,
                            48.0137160001423
                        ],
                        [
                            -89.6251669999811,
                            47.7995489997429
                        ],
                        [
                            -89.9571020001925,
                            47.2911030002522
                        ],
                        [
                            -90.6546599996645,
                            47.3098220001952
                        ],
                        [
                            -91.1166090000916,
                            47.0952159998095
                        ],
                        [
                            -91.5001809998889,
                            46.9133199997186
                        ],
                        [
                            -91.5921169998389,
                            46.8749350001723
                        ],
                        [
                            -91.8751860003836,
                            46.7633030000525
                        ],
                        [
                            -91.9813409999779,
                            46.7230910001729
                        ],
                        [
                            -92.0202939997545,
                            46.7040409999913
                        ],
                        [
                            -92.0339890004156,
                            46.7089390001779
                        ],
                        [
                            -92.0894930002629,
                            46.7492369999054
                        ],
                        [
                            -92.1165899998854,
                            46.7486399997331
                        ],
                        [
                            -92.1378900002996,
                            46.7395399999614
                        ],
                        [
                            -92.1432899998479,
                            46.7346400001177
                        ],
                        [
                            -92.1434020000008,
                            46.7281519999546
                        ],
                        [
                            -92.1415220003844,
                            46.7252850002868
                        ],
                        [
                            -92.146730000312,
                            46.7157940001577
                        ],
                        [
                            -92.1551910002078,
                            46.7159399998771
                        ],
                        [
                            -92.1672909998611,
                            46.7199410000615
                        ],
                        [
                            -92.17429100044,
                            46.7172409998146
                        ],
                        [
                            -92.1890909998674,
                            46.7175409999446
                        ],
                        [
                            -92.1914910000659,
                            46.716241000064
                        ],
                        [
                            -92.1932910002148,
                            46.7112409998956
                        ],
                        [
                            -92.1973910001047,
                            46.7076410001807
                        ],
                        [
                            -92.2046910002593,
                            46.7040409999913
                        ],
                        [
                            -92.2051919996764,
                            46.698340999773
                        ],
                        [
                            -92.1984909997465,
                            46.6961409997266
                        ],
                        [
                            -92.182999000208,
                            46.6956519999371
                        ],
                        [
                            -92.1762589999201,
                            46.6904729997838
                        ],
                        [
                            -92.1758749997805,
                            46.686465999912
                        ],
                        [
                            -92.1813589998927,
                            46.6802410000654
                        ],
                        [
                            -92.1924919999735,
                            46.6767410002535
                        ],
                        [
                            -92.2040920000346,
                            46.6669410000881
                        ],
                        [
                            -92.2054920001504,
                            46.6647410002428
                        ],
                        [
                            -92.201592000277,
                            46.6566409999867
                        ],
                        [
                            -92.2070920002827,
                            46.6519410000614
                        ],
                        [
                            -92.2163919997044,
                            46.6498409999639
                        ],
                        [
                            -92.228492000256,
                            46.6529410000782
                        ],
                        [
                            -92.2424930003404,
                            46.6492409998477
                        ],
                        [
                            -92.2523090001462,
                            46.6570689998481
                        ],
                        [
                            -92.2565920003342,
                            46.6587409999327
                        ],
                        [
                            -92.2659930000382,
                            46.6510409999614
                        ],
                        [
                            -92.2705919996955,
                            46.6507410002997
                        ],
                        [
                            -92.2743920000097,
                            46.6574409997764
                        ],
                        [
                            -92.283899000019,
                            46.6587099997028
                        ],
                        [
                            -92.2861920000873,
                            46.6603420001568
                        ],
                        [
                            -92.2873920001866,
                            46.6673430002477
                        ],
                        [
                            -92.2912889996869,
                            46.6681370002649
                        ],
                        [
                            -92.2923189999069,
                            46.6633069997353
                        ],
                        [
                            -92.294032999842,
                            46.0743770000359
                        ],
                        [
                            -92.319329000389,
                            46.0692890002554
                        ],
                        [
                            -92.3329119999988,
                            46.0626970001601
                        ],
                        [
                            -92.3363530002878,
                            46.0569979998521
                        ],
                        [
                            -92.3387330003948,
                            46.0481260000266
                        ],
                        [
                            -92.3434590002737,
                            46.0429900000764
                        ],
                        [
                            -92.3424290000537,
                            46.0345409998796
                        ],
                        [
                            -92.3437449996056,
                            46.0285249999352
                        ],
                        [
                            -92.3492809995964,
                            46.0236239996893
                        ],
                        [
                            -92.3499769999054,
                            46.0169819999274
                        ],
                        [
                            -92.3517600003359,
                            46.0156850001898
                        ],
                        [
                            -92.3621410000311,
                            46.0131030001404
                        ],
                        [
                            -92.37271699972,
                            46.0141979999861
                        ],
                        [
                            -92.3926809995925,
                            46.0195400002486
                        ],
                        [
                            -92.4118960003508,
                            46.0274780003018
                        ],
                        [
                            -92.4285550003766,
                            46.0242409998322
                        ],
                        [
                            -92.4422590003601,
                            46.0161769998427
                        ],
                        [
                            -92.4442939997873,
                            46.0091610001187
                        ],
                        [
                            -92.4529520002249,
                            45.9977819998409
                        ],
                        [
                            -92.4533730001744,
                            45.9929130002301
                        ],
                        [
                            -92.4624769997775,
                            45.9878500001769
                        ],
                        [
                            -92.464512000103,
                            45.9850380002164
                        ],
                        [
                            -92.4615839998249,
                            45.9788939999129
                        ],
                        [
                            -92.4697650002365,
                            45.9735690001656
                        ],
                        [
                            -92.4909960001554,
                            45.9755599997225
                        ],
                        [
                            -92.5025350001171,
                            45.9799949998502
                        ],
                        [
                            -92.5194879998708,
                            45.9839169999617
                        ],
                        [
                            -92.5251860002432,
                            45.9838620001073
                        ],
                        [
                            -92.5377090003941,
                            45.9778180001731
                        ],
                        [
                            -92.5498059996743,
                            45.9679860001963
                        ],
                        [
                            -92.5498579995529,
                            45.9570389998835
                        ],
                        [
                            -92.5519330000613,
                            45.951650999797
                        ],
                        [
                            -92.5748920001591,
                            45.9481029998418
                        ],
                        [
                            -92.5895279995549,
                            45.9417819998003
                        ],
                        [
                            -92.6024599999597,
                            45.9408150002873
                        ],
                        [
                            -92.6143139995657,
                            45.934528999888
                        ],
                        [
                            -92.6227200001082,
                            45.9351860000908
                        ],
                        [
                            -92.6292600003796,
                            45.9324039997268
                        ],
                        [
                            -92.6388240002902,
                            45.9341659998924
                        ],
                        [
                            -92.6401149997278,
                            45.932477999738
                        ],
                        [
                            -92.6391199996679,
                            45.9245580001445
                        ],
                        [
                            -92.6561250001985,
                            45.9244419999344
                        ],
                        [
                            -92.6761669998887,
                            45.9120720000179
                        ],
                        [
                            -92.6757369997184,
                            45.9074779999995
                        ],
                        [
                            -92.6773519999194,
                            45.9058179996999
                        ],
                        [
                            -92.6998149997246,
                            45.8961469998667
                        ],
                        [
                            -92.7077020004083,
                            45.8949009997789
                        ],
                        [
                            -92.7222089999327,
                            45.8830009998221
                        ],
                        [
                            -92.7382200002284,
                            45.8620309998893
                        ],
                        [
                            -92.7361170001308,
                            45.8591290000527
                        ],
                        [
                            -92.7399910000649,
                            45.8462829999409
                        ],
                        [
                            -92.7455570001929,
                            45.8414549997674
                        ],
                        [
                            -92.7510839999628,
                            45.8406169996967
                        ],
                        [
                            -92.7594579998199,
                            45.835341000248
                        ],
                        [
                            -92.7651460001466,
                            45.8301830001258
                        ],
                        [
                            -92.7656809998989,
                            45.8272519999053
                        ],
                        [
                            -92.7579470002744,
                            45.8112159999288
                        ],
                        [
                            -92.7578150000299,
                            45.8065739999136
                        ],
                        [
                            -92.761832999904,
                            45.8012580000347
                        ],
                        [
                            -92.7720649996364,
                            45.7952299998324
                        ],
                        [
                            -92.7764819997187,
                            45.7900039998289
                        ],
                        [
                            -92.7846170000996,
                            45.7641989997565
                        ],
                        [
                            -92.8026300002104,
                            45.751887999756
                        ],
                        [
                            -92.8049419996471,
                            45.7479159998752
                        ],
                        [
                            -92.8098369995803,
                            45.7441720001411
                        ],
                        [
                            -92.8165590003249,
                            45.7420369999782
                        ],
                        [
                            -92.8289810001934,
                            45.7337140001747
                        ],
                        [
                            -92.8488510003546,
                            45.7287509999304
                        ],
                        [
                            -92.8509329996374,
                            45.7238309999597
                        ],
                        [
                            -92.8625979999958,
                            45.7222409998213
                        ],
                        [
                            -92.8691929996205,
                            45.7175679997961
                        ],
                        [
                            -92.8688620000827,
                            45.7119929997078
                        ],
                        [
                            -92.8717750002922,
                            45.6997740001271
                        ],
                        [
                            -92.8701450000226,
                            45.6967569997031
                        ],
                        [
                            -92.8754879996695,
                            45.6890140000094
                        ],
                        [
                            -92.8789320003316,
                            45.6656060003126
                        ],
                        [
                            -92.882504000142,
                            45.6594709999592
                        ],
                        [
                            -92.8859029997,
                            45.6455410000636
                        ],
                        [
                            -92.8894329996775,
                            45.6412019999931
                        ],
                        [
                            -92.8868569997519,
                            45.6357229999685
                        ],
                        [
                            -92.8880350001098,
                            45.6249590001359
                        ],
                        [
                            -92.8825290002563,
                            45.6102159999332
                        ],
                        [
                            -92.8864419996502,
                            45.5986789997729
                        ],
                        [
                            -92.8864209997338,
                            45.5948809997645
                        ],
                        [
                            -92.883277000444,
                            45.5898309997506
                        ],
                        [
                            -92.8849539996709,
                            45.5788179999829
                        ],
                        [
                            -92.8837490004471,
                            45.5754829999659
                        ],
                        [
                            -92.8710820003562,
                            45.5675809997753
                        ],
                        [
                            -92.8464469999581,
                            45.566514999966
                        ],
                        [
                            -92.8233089997603,
                            45.5609340000497
                        ],
                        [
                            -92.8015029999061,
                            45.5628539999273
                        ],
                        [
                            -92.7857410000308,
                            45.5678879999334
                        ],
                        [
                            -92.7734120000487,
                            45.5682349998456
                        ],
                        [
                            -92.7569060001838,
                            45.5574989998254
                        ],
                        [
                            -92.7455909996298,
                            45.5530160001641
                        ],
                        [
                            -92.7273630003321,
                            45.5421440002021
                        ],
                        [
                            -92.7247620002921,
                            45.5386169998806
                        ],
                        [
                            -92.7280229997578,
                            45.525651999948
                        ],
                        [
                            -92.7266770000687,
                            45.514461999916
                        ],
                        [
                            -92.712862999582,
                            45.5048489998076
                        ],
                        [
                            -92.7008990003712,
                            45.4916089998334
                        ],
                        [
                            -92.6909620001916,
                            45.475442000112
                        ],
                        [
                            -92.6825959998321,
                            45.4699089997533
                        ],
                        [
                            -92.6802340001667,
                            45.4643439997533
                        ],
                        [
                            -92.6535490002729,
                            45.4553460000073
                        ],
                        [
                            -92.6465050002113,
                            45.4403380000509
                        ],
                        [
                            -92.6502689996423,
                            45.4191680002277
                        ],
                        [
                            -92.6466759999156,
                            45.4132270002599
                        ],
                        [
                            -92.6505680002914,
                            45.4033109998196
                        ],
                        [
                            -92.6502839997109,
                            45.3987690003136
                        ],
                        [
                            -92.6641040000454,
                            45.3933080001731
                        ],
                        [
                            -92.676962999757,
                            45.3801439999731
                        ],
                        [
                            -92.6791870002284,
                            45.3727099999109
                        ],
                        [
                            -92.6885279996579,
                            45.3684289997519
                        ],
                        [
                            -92.7027229999112,
                            45.3584680002338
                        ],
                        [
                            -92.70405400043,
                            45.3536599999878
                        ],
                        [
                            -92.6995229996465,
                            45.3424210002209
                        ],
                        [
                            -92.6989669999778,
                            45.3363740001818
                        ],
                        [
                            -92.7047940002217,
                            45.3265259997709
                        ],
                        [
                            -92.7371210004115,
                            45.3004590001845
                        ],
                        [
                            -92.7511550001079,
                            45.2928270000287
                        ],
                        [
                            -92.7587099996323,
                            45.2909649997698
                        ],
                        [
                            -92.7620039996083,
                            45.2882129999528
                        ],
                        [
                            -92.7606119998884,
                            45.2788279997549
                        ],
                        [
                            -92.7538440000114,
                            45.2709589997707
                        ],
                        [
                            -92.7519200000139,
                            45.2658770002116
                        ],
                        [
                            -92.7536210004286,
                            45.259419000156
                        ],
                        [
                            -92.7602639997339,
                            45.2517170002524
                        ],
                        [
                            -92.7574560000047,
                            45.2305260000408
                        ],
                        [
                            -92.7517080003018,
                            45.218666000019
                        ],
                        [
                            -92.7540049996699,
                            45.2127689997442
                        ],
                        [
                            -92.7639080004125,
                            45.2048659999631
                        ],
                        [
                            -92.7671300004185,
                            45.1941650001125
                        ],
                        [
                            -92.7668080002032,
                            45.18546600018
                        ],
                        [
                            -92.7524039997126,
                            45.1739159997959
                        ],
                        [
                            -92.7569070000087,
                            45.1651659997683
                        ],
                        [
                            -92.7581320002223,
                            45.1596769997219
                        ],
                        [
                            -92.7573520002476,
                            45.152350999731
                        ],
                        [
                            -92.7525059998199,
                            45.145965999964
                        ],
                        [
                            -92.7456919999121,
                            45.1231119997578
                        ],
                        [
                            -92.7406100003811,
                            45.118453999913
                        ],
                        [
                            -92.7395829996359,
                            45.1155979999773
                        ],
                        [
                            -92.7467489998962,
                            45.1070510002393
                        ],
                        [
                            -92.7926570000459,
                            45.0787620003079
                        ],
                        [
                            -92.8015220001727,
                            45.068623000193
                        ],
                        [
                            -92.8031239999548,
                            45.0615660000308
                        ],
                        [
                            -92.8015680002035,
                            45.0565070001207
                        ],
                        [
                            -92.7955860001489,
                            45.0492439999037
                        ],
                        [
                            -92.7703619995719,
                            45.0338029997251
                        ],
                        [
                            -92.7646039998233,
                            45.0287670002637
                        ],
                        [
                            -92.7620599996848,
                            45.024320000222
                        ],
                        [
                            -92.7712310001334,
                            45.0013780000113
                        ],
                        [
                            -92.7690490003931,
                            44.988195000238
                        ],
                        [
                            -92.7703459996784,
                            44.9833280002514
                        ],
                        [
                            -92.7694450002282,
                            44.9721499997619
                        ],
                        [
                            -92.7672180002821,
                            44.9680839997128
                        ],
                        [
                            -92.7606999997519,
                            44.964978999813
                        ],
                        [
                            -92.7546030000697,
                            44.9557669997512
                        ],
                        [
                            -92.7506449995716,
                            44.937298999984
                        ],
                        [
                            -92.7587010003098,
                            44.908978999945
                        ],
                        [
                            -92.7731030002522,
                            44.9013669999404
                        ],
                        [
                            -92.7745710001401,
                            44.8980839999054
                        ],
                        [
                            -92.7739459999761,
                            44.8899969999078
                        ],
                        [
                            -92.7634110002951,
                            44.8741400000499
                        ],
                        [
                            -92.7695010003047,
                            44.8620010001343
                        ],
                        [
                            -92.7659319999691,
                            44.8356309998568
                        ],
                        [
                            -92.7724379999053,
                            44.8281899997304
                        ],
                        [
                            -92.7729109997333,
                            44.8215030003056
                        ],
                        [
                            -92.7807540000361,
                            44.8122999999507
                        ],
                        [
                            -92.7856220002052,
                            44.7918869999466
                        ],
                        [
                            -92.7991649996319,
                            44.7789690001343
                        ],
                        [
                            -92.8044450004279,
                            44.7707599999577
                        ],
                        [
                            -92.8076869996271,
                            44.759285000258
                        ],
                        [
                            -92.8082420003692,
                            44.7510830000029
                        ],
                        [
                            -92.7923359996555,
                            44.7393620000284
                        ],
                        [
                            -92.7719480003588,
                            44.7320770001509
                        ],
                        [
                            -92.7556709999244,
                            44.7232430001043
                        ],
                        [
                            -92.7320430001801,
                            44.7143449999939
                        ],
                        [
                            -92.6943199997417,
                            44.6886150000621
                        ],
                        [
                            -92.6609880003447,
                            44.6608999999722
                        ],
                        [
                            -92.6320210002531,
                            44.6491869999551
                        ],
                        [
                            -92.6218470002471,
                            44.6390179996983
                        ],
                        [
                            -92.6202150003277,
                            44.6341940000406
                        ],
                        [
                            -92.6202620001834,
                            44.6286539997056
                        ],
                        [
                            -92.6235660002051,
                            44.6206400001601
                        ],
                        [
                            -92.6216420002077,
                            44.614929000208
                        ],
                        [
                            -92.6147129997738,
                            44.6116579998054
                        ],
                        [
                            -92.601608000015,
                            44.6120380002335
                        ],
                        [
                            -92.590488000353,
                            44.6059440001593
                        ],
                        [
                            -92.5887969999841,
                            44.6016980000593
                        ],
                        [
                            -92.5862160000356,
                            44.6000880000335
                        ],
                        [
                            -92.581588000066,
                            44.6008509999148
                        ],
                        [
                            -92.5771559999151,
                            44.6050370000785
                        ],
                        [
                            -92.5689460000895,
                            44.6033719998859
                        ],
                        [
                            -92.5498269995907,
                            44.5810979998642
                        ],
                        [
                            -92.5493389996941,
                            44.5778469997078
                        ],
                        [
                            -92.5515670003634,
                            44.5722149998064
                        ],
                        [
                            -92.5488890003306,
                            44.5682519997631
                        ],
                        [
                            -92.5410159998906,
                            44.5671269996812
                        ],
                        [
                            -92.5279999998202,
                            44.5732500000667
                        ],
                        [
                            -92.5192180004324,
                            44.5752749996899
                        ],
                        [
                            -92.5086669999595,
                            44.5703129997796
                        ],
                        [
                            -92.4937169998459,
                            44.5660520000664
                        ],
                        [
                            -92.4809860001809,
                            44.5684489998466
                        ],
                        [
                            -92.4550899995843,
                            44.5620230001726
                        ],
                        [
                            -92.4407460002664,
                            44.5628549999443
                        ],
                        [
                            -92.4311010001649,
                            44.5657859998604
                        ],
                        [
                            -92.4150890000444,
                            44.5603590001373
                        ],
                        [
                            -92.4021049997611,
                            44.5586060001027
                        ],
                        [
                            -92.3915449999657,
                            44.5576429997038
                        ],
                        [
                            -92.3615180004153,
                            44.5589349999888
                        ],
                        [
                            -92.3361139999132,
                            44.5540039998599
                        ],
                        [
                            -92.3223410003327,
                            44.5467789997064
                        ],
                        [
                            -92.3140710002326,
                            44.5380139998854
                        ],
                        [
                            -92.3108269995871,
                            44.5287559999266
                        ],
                        [
                            -92.3030460001068,
                            44.5186459996972
                        ],
                        [
                            -92.3022150000785,
                            44.5002980001132
                        ],
                        [
                            -92.2910050000048,
                            44.4854640002326
                        ],
                        [
                            -92.2739789995579,
                            44.471882000053
                        ],
                        [
                            -92.2448840003181,
                            44.4568420002818
                        ],
                        [
                            -92.2308559995711,
                            44.4444960000234
                        ],
                        [
                            -92.2151630001912,
                            44.4385030000003
                        ],
                        [
                            -92.1953780004188,
                            44.4337920003189
                        ],
                        [
                            -92.1624539996542,
                            44.4272079997312
                        ],
                        [
                            -92.1245129996558,
                            44.422114999826
                        ],
                        [
                            -92.1123080004206,
                            44.4143319998581
                        ],
                        [
                            -92.0872389996543,
                            44.4088499999859
                        ],
                        [
                            -92.0786050004045,
                            44.4048690001617
                        ],
                        [
                            -92.0564859996576,
                            44.4027289999786
                        ],
                        [
                            -92.0381470000318,
                            44.3887310000697
                        ],
                        [
                            -92.0193129999384,
                            44.3812170002698
                        ],
                        [
                            -92.0061789998672,
                            44.3788250000273
                        ],
                        [
                            -91.9872889996973,
                            44.3691189997244
                        ],
                        [
                            -91.9771029999957,
                            44.3681359999626
                        ],
                        [
                            -91.9668090003391,
                            44.3639860003087
                        ],
                        [
                            -91.9595230004282,
                            44.3594040001935
                        ],
                        [
                            -91.9413110001258,
                            44.3409779997263
                        ],
                        [
                            -91.9255900002584,
                            44.3335480000276
                        ],
                        [
                            -91.9186249998395,
                            44.3226710001931
                        ],
                        [
                            -91.9135740002707,
                            44.3103920001173
                        ],
                        [
                            -91.924102000279,
                            44.2970950002521
                        ],
                        [
                            -91.9246129997419,
                            44.2918150002165
                        ],
                        [
                            -91.9202819998733,
                            44.2864960000474
                        ],
                        [
                            -91.9057889996942,
                            44.2816140002522
                        ],
                        [
                            -91.8963879999902,
                            44.2746899999686
                        ],
                        [
                            -91.8960080000486,
                            44.2628709998545
                        ],
                        [
                            -91.8891320002165,
                            44.2560600001597
                        ],
                        [
                            -91.8870399999896,
                            44.2517720001731
                        ],
                        [
                            -91.8929629995946,
                            44.2351490001634
                        ],
                        [
                            -91.892698000179,
                            44.2311050002326
                        ],
                        [
                            -91.8774290002233,
                            44.2129210002704
                        ],
                        [
                            -91.8751579998962,
                            44.2005750000134
                        ],
                        [
                            -91.8643870002136,
                            44.1965740001464
                        ],
                        [
                            -91.8447539998789,
                            44.184877999693
                        ],
                        [
                            -91.8291669999059,
                            44.1783499998752
                        ],
                        [
                            -91.8173020004292,
                            44.1642349997954
                        ],
                        [
                            -91.8080640000335,
                            44.1592620000754
                        ],
                        [
                            -91.7744859996909,
                            44.1475390002434
                        ],
                        [
                            -91.7517469997012,
                            44.134785999994
                        ],
                        [
                            -91.7306480000268,
                            44.1329000001897
                        ],
                        [
                            -91.7215519999213,
                            44.1303419999304
                        ],
                        [
                            -91.7105970001157,
                            44.1204800001286
                        ],
                        [
                            -91.7074909995623,
                            44.1039059998194
                        ],
                        [
                            -91.6953099997182,
                            44.0985699997629
                        ],
                        [
                            -91.6815299995667,
                            44.0974000002313
                        ],
                        [
                            -91.6670060003238,
                            44.0869639997264
                        ],
                        [
                            -91.6595110001756,
                            44.0742029997472
                        ],
                        [
                            -91.6478729995813,
                            44.0641090000362
                        ],
                        [
                            -91.6447169996976,
                            44.0627819997966
                        ],
                        [
                            -91.6381399996163,
                            44.0632949996846
                        ],
                        [
                            -91.6278960001884,
                            44.0558049997577
                        ],
                        [
                            -91.6104870003251,
                            44.0493100002913
                        ],
                        [
                            -91.6035500003937,
                            44.043681000168
                        ],
                        [
                            -91.5976169998446,
                            44.0349649997996
                        ],
                        [
                            -91.5920699999832,
                            44.0313719999982
                        ],
                        [
                            -91.5800189998354,
                            44.0269250002544
                        ],
                        [
                            -91.5590039998266,
                            44.0253150000481
                        ],
                        [
                            -91.5470280000219,
                            44.0222260000451
                        ],
                        [
                            -91.5243149999715,
                            44.0214329997414
                        ],
                        [
                            -91.5071210001934,
                            44.0189800000635
                        ],
                        [
                            -91.4909169995539,
                            44.0110889999609
                        ],
                        [
                            -91.479755999884,
                            44.0079779999087
                        ],
                        [
                            -91.4635150003329,
                            44.0090409999301
                        ],
                        [
                            -91.4405410001666,
                            44.0015179998447
                        ],
                        [
                            -91.4323470002345,
                            43.9966900000842
                        ],
                        [
                            -91.4241340000358,
                            43.9826310000888
                        ],
                        [
                            -91.4124910003171,
                            43.9734109999851
                        ],
                        [
                            -91.4073949996441,
                            43.965148000106
                        ],
                        [
                            -91.3857939998294,
                            43.9542390001961
                        ],
                        [
                            -91.3666419997186,
                            43.9374629997026
                        ],
                        [
                            -91.3647200002693,
                            43.9348800002628
                        ],
                        [
                            -91.3632270002671,
                            43.9265569996945
                        ],
                        [
                            -91.3574250001376,
                            43.9172309997956
                        ],
                        [
                            -91.3477409996781,
                            43.9119640000169
                        ],
                        [
                            -91.3381409997824,
                            43.8976640001297
                        ],
                        [
                            -91.325465000369,
                            43.8922239997409
                        ],
                        [
                            -91.3206049996975,
                            43.888490999873
                        ],
                        [
                            -91.3153099997312,
                            43.8818079999966
                        ],
                        [
                            -91.3109909995581,
                            43.8673810002367
                        ],
                        [
                            -91.2988149997369,
                            43.8565550003004
                        ],
                        [
                            -91.2910019995713,
                            43.8527329997589
                        ],
                        [
                            -91.2841380003332,
                            43.8470649998367
                        ],
                        [
                            -91.2776950001461,
                            43.837740999785
                        ],
                        [
                            -91.2757369998135,
                            43.8248659998174
                        ],
                        [
                            -91.2720369999567,
                            43.8137660000509
                        ],
                        [
                            -91.2678490002032,
                            43.8087610002789
                        ],
                        [
                            -91.2674359997513,
                            43.8041660001435
                        ],
                        [
                            -91.2644360004015,
                            43.8003649998626
                        ],
                        [
                            -91.262432999863,
                            43.7921699996905
                        ],
                        [
                            -91.2441350002451,
                            43.7746669997475
                        ],
                        [
                            -91.2554309996343,
                            43.7448759997578
                        ],
                        [
                            -91.2559319999497,
                            43.7298490002645
                        ],
                        [
                            -91.2587559995724,
                            43.7234260000289
                        ],
                        [
                            -91.2684550001006,
                            43.7098240000312
                        ],
                        [
                            -91.2677920003018,
                            43.6956520001303
                        ],
                        [
                            -91.2727409997634,
                            43.6766089999418
                        ],
                        [
                            -91.2712979999899,
                            43.6727529997405
                        ],
                        [
                            -91.2732520001245,
                            43.666622999937
                        ],
                        [
                            -91.2717490000766,
                            43.6549289998829
                        ],
                        [
                            -91.2638560004434,
                            43.6476619998582
                        ],
                        [
                            -91.262396999878,
                            43.6417600000237
                        ],
                        [
                            -91.2684569997504,
                            43.6273520001603
                        ],
                        [
                            -91.2689789999822,
                            43.6165779998962
                        ],
                        [
                            -91.261631000147,
                            43.6061750001754
                        ],
                        [
                            -91.2391089998924,
                            43.5897600000397
                        ],
                        [
                            -91.2318649998142,
                            43.5818220001739
                        ],
                        [
                            -91.2328120001935,
                            43.5648419997293
                        ],
                        [
                            -91.2432139998051,
                            43.5507219997008
                        ],
                        [
                            -91.243182999843,
                            43.5403090003179
                        ],
                        [
                            -91.2367249995874,
                            43.5329299998314
                        ],
                        [
                            -91.2329410000649,
                            43.5239669998905
                        ],
                        [
                            -91.2182920002503,
                            43.5144339997432
                        ],
                        [
                            -91.2177060004444,
                            43.5005500000797
                        ],
                        [
                            -93.2669870003336,
                            43.4993610001307
                        ],
                        [
                            -94.765747000142,
                            43.501052000133
                        ],
                        [
                            -96.4532600004382,
                            43.5003899998969
                        ],
                        [
                            -96.4534040003782,
                            44.0254309997314
                        ],
                        [
                            -96.4512340003335,
                            44.7182730000883
                        ],
                        [
                            -96.4530970002316,
                            45.3007510000905
                        ],
                        [
                            -96.4569949995568,
                            45.3038799997433
                        ],
                        [
                            -96.457582000086,
                            45.3075859999653
                        ],
                        [
                            -96.4619099995815,
                            45.3138839996954
                        ],
                        [
                            -96.4680270002536,
                            45.3186190001316
                        ],
                        [
                            -96.4692459997211,
                            45.3249410002601
                        ],
                        [
                            -96.4802580003265,
                            45.3407389997934
                        ],
                        [
                            -96.4890649998287,
                            45.3570709996881
                        ],
                        [
                            -96.5217870000286,
                            45.3756449999542
                        ],
                        [
                            -96.5621419995708,
                            45.3860899998396
                        ],
                        [
                            -96.5788790003128,
                            45.3922950000022
                        ],
                        [
                            -96.6011799997362,
                            45.4031809998899
                        ],
                        [
                            -96.6197519998888,
                            45.4088840001755
                        ],
                        [
                            -96.6358889996828,
                            45.4099429998029
                        ],
                        [
                            -96.6444619997316,
                            45.4088920000416
                        ],
                        [
                            -96.6489519996089,
                            45.4103099998842
                        ],
                        [
                            -96.6622580001075,
                            45.4090110002031
                        ],
                        [
                            -96.6804539996181,
                            45.4104989997674
                        ],
                        [
                            -96.6925409997509,
                            45.417337999927
                        ],
                        [
                            -96.7242500003477,
                            45.4514820000276
                        ],
                        [
                            -96.7327389998326,
                            45.4587369999486
                        ],
                        [
                            -96.7384460004259,
                            45.4734990003084
                        ],
                        [
                            -96.7434860001241,
                            45.4806490001156
                        ],
                        [
                            -96.7454870001144,
                            45.4887119999893
                        ],
                        [
                            -96.7634319995549,
                            45.5200070002545
                        ],
                        [
                            -96.7810360003101,
                            45.535971999919
                        ],
                        [
                            -96.7938399997701,
                            45.5507240000679
                        ],
                        [
                            -96.8019869998465,
                            45.5554140001319
                        ],
                        [
                            -96.8568339997428,
                            45.6053779999491
                        ],
                        [
                            -96.8442110000329,
                            45.6395830000122
                        ],
                        [
                            -96.8386480002779,
                            45.6475089997828
                        ],
                        [
                            -96.8024680000704,
                            45.6672759998352
                        ],
                        [
                            -96.760865999675,
                            45.6875180002644
                        ],
                        [
                            -96.7503500002606,
                            45.6987820003115
                        ],
                        [
                            -96.7450860002564,
                            45.7015759996893
                        ],
                        [
                            -96.7111570002844,
                            45.717561000244
                        ],
                        [
                            -96.672664999742,
                            45.7323359998283
                        ],
                        [
                            -96.6625950003914,
                            45.7386820001904
                        ],
                        [
                            -96.6522260003917,
                            45.7468089999889
                        ],
                        [
                            -96.6419410000576,
                            45.7598709999791
                        ],
                        [
                            -96.6391800001841,
                            45.7671119998992
                        ],
                        [
                            -96.6395330003615,
                            45.77159199984
                        ],
                        [
                            -96.6341470001586,
                            45.7745619999426
                        ],
                        [
                            -96.6340619997698,
                            45.7779159997052
                        ],
                        [
                            -96.630144000353,
                            45.7794310001381
                        ],
                        [
                            -96.6303450001945,
                            45.7823800000266
                        ],
                        [
                            -96.6277779995914,
                            45.7862390003097
                        ],
                        [
                            -96.6125120000087,
                            45.7944420001322
                        ],
                        [
                            -96.5967040001027,
                            45.8118010000325
                        ],
                        [
                            -96.5836839998343,
                            45.8193069998518
                        ],
                        [
                            -96.57973999958,
                            45.8258200002928
                        ],
                        [
                            -96.5772759998074,
                            45.8337209999495
                        ],
                        [
                            -96.5775340004486,
                            45.8379299998298
                        ],
                        [
                            -96.5745169995837,
                            45.8440440002371
                        ],
                        [
                            -96.5730789998331,
                            45.8615239997577
                        ],
                        [
                            -96.5746670002699,
                            45.8668159997436
                        ],
                        [
                            -96.5718710002362,
                            45.8718459998819
                        ],
                        [
                            -96.5713540000273,
                            45.8866740001178
                        ],
                        [
                            -96.5688659999652,
                            45.8880229996917
                        ],
                        [
                            -96.5681839998999,
                            45.9034510002063
                        ],
                        [
                            -96.5661879999324,
                            45.9086629997647
                        ],
                        [
                            -96.564451000431,
                            45.9095130002425
                        ],
                        [
                            -96.5675389996444,
                            45.912052000218
                        ],
                        [
                            -96.5698769999186,
                            45.9114579999369
                        ],
                        [
                            -96.5699959997443,
                            45.9135239997377
                        ],
                        [
                            -96.5690989995937,
                            45.9170370000154
                        ],
                        [
                            -96.5665620000262,
                            45.9169310002898
                        ],
                        [
                            -96.5640019999942,
                            45.9195609999579
                        ],
                        [
                            -96.564961000069,
                            45.9300760000945
                        ],
                        [
                            -96.5613340000071,
                            45.9456549999239
                        ],
                        [
                            -96.5648029998852,
                            45.9503479996892
                        ],
                        [
                            -96.5653340003379,
                            45.9573259998818
                        ],
                        [
                            -96.5681539997626,
                            45.9587960000551
                        ],
                        [
                            -96.5703499997466,
                            45.9635949999555
                        ],
                        [
                            -96.5724910003773,
                            45.9770500000099
                        ],
                        [
                            -96.5736050002629,
                            46.0023089997135
                        ],
                        [
                            -96.5758690000189,
                            46.007999000099
                        ],
                        [
                            -96.5742429999473,
                            46.0165359997434
                        ],
                        [
                            -96.5778819997048,
                            46.0271769996894
                        ],
                        [
                            -96.5663210000018,
                            46.0515030001304
                        ],
                        [
                            -96.5591079998858,
                            46.0585230003017
                        ],
                        [
                            -96.5584860000949,
                            46.0631999997806
                        ],
                        [
                            -96.556798000099,
                            46.0647490001919
                        ],
                        [
                            -96.5572919998434,
                            46.0776269999056
                        ],
                        [
                            -96.5544110003193,
                            46.0840790002195
                        ],
                        [
                            -96.557351000293,
                            46.0877410000862
                        ],
                        [
                            -96.5562719996692,
                            46.0940090000326
                        ],
                        [
                            -96.5587070000279,
                            46.1004980000468
                        ],
                        [
                            -96.5579339997259,
                            46.1025209998419
                        ],
                        [
                            -96.5669200002265,
                            46.1147500001413
                        ],
                        [
                            -96.5602340003652,
                            46.1165679998904
                        ],
                        [
                            -96.5625700000913,
                            46.1195149998979
                        ],
                        [
                            -96.5668990003101,
                            46.1195159999106
                        ],
                        [
                            -96.568398999985,
                            46.1232940001357
                        ],
                        [
                            -96.5719990002827,
                            46.1245670002977
                        ],
                        [
                            -96.5696789995519,
                            46.1260609997287
                        ],
                        [
                            -96.5700690004375,
                            46.1285769998493
                        ],
                        [
                            -96.568579999735,
                            46.1303520001779
                        ],
                        [
                            -96.5713790001416,
                            46.1311009998866
                        ],
                        [
                            -96.5687050003068,
                            46.133156999894
                        ],
                        [
                            -96.5717780003498,
                            46.1349230000706
                        ],
                        [
                            -96.5727850001052,
                            46.1404900000606
                        ],
                        [
                            -96.5792809999957,
                            46.1474409998501
                        ],
                        [
                            -96.5802729996825,
                            46.1510759997078
                        ],
                        [
                            -96.576264999854,
                            46.1595820002324
                        ],
                        [
                            -96.5799129998324,
                            46.1611510002033
                        ],
                        [
                            -96.5772890002262,
                            46.1628989999375
                        ],
                        [
                            -96.5770199997144,
                            46.1660360000933
                        ],
                        [
                            -96.5806080003165,
                            46.1676479998543
                        ],
                        [
                            -96.5752809996649,
                            46.1690109999631
                        ],
                        [
                            -96.5838620001095,
                            46.1705989998215
                        ],
                        [
                            -96.5770110003918,
                            46.171546000056
                        ],
                        [
                            -96.5766359995748,
                            46.1739439997119
                        ],
                        [
                            -96.5835220003509,
                            46.1726630002772
                        ],
                        [
                            -96.5791410002536,
                            46.1768319997536
                        ],
                        [
                            -96.5806319997077,
                            46.1773169997705
                        ],
                        [
                            -96.5856189997024,
                            46.1773439997611
                        ],
                        [
                            -96.5856270000983,
                            46.1754169998964
                        ],
                        [
                            -96.588086999673,
                            46.1764169999598
                        ],
                        [
                            -96.591059000332,
                            46.1745440002129
                        ],
                        [
                            -96.5926820000305,
                            46.1752110000853
                        ],
                        [
                            -96.5916650002294,
                            46.1784960000751
                        ],
                        [
                            -96.5883870001469,
                            46.179083999815
                        ],
                        [
                            -96.5892330002438,
                            46.1813950001162
                        ],
                        [
                            -96.5861509999799,
                            46.1818119999093
                        ],
                        [
                            -96.5915720003429,
                            46.184682000248
                        ],
                        [
                            -96.5874280000721,
                            46.1858009999429
                        ],
                        [
                            -96.5896409997745,
                            46.1869179998056
                        ],
                        [
                            -96.5874130000035,
                            46.1886549997372
                        ],
                        [
                            -96.5905379999251,
                            46.190474000042
                        ],
                        [
                            -96.5874590000342,
                            46.1920669998145
                        ],
                        [
                            -96.587801000341,
                            46.1938309999308
                        ],
                        [
                            -96.5850770002774,
                            46.1946239999099
                        ],
                        [
                            -96.5912259998382,
                            46.1957470000364
                        ],
                        [
                            -96.5846289996654,
                            46.1975110001738
                        ],
                        [
                            -96.5836739997886,
                            46.2011629997067
                        ],
                        [
                            -96.5873490004294,
                            46.2016250000191
                        ],
                        [
                            -96.5842579999447,
                            46.2040939997473
                        ],
                        [
                            -96.5890270003795,
                            46.2049460002187
                        ],
                        [
                            -96.5875949995784,
                            46.2069670001118
                        ],
                        [
                            -96.5837609998272,
                            46.2080590001137
                        ],
                        [
                            -96.5870559996281,
                            46.2099670001718
                        ],
                        [
                            -96.5911139996853,
                            46.2086899999903
                        ],
                        [
                            -96.5884399998504,
                            46.2128790001008
                        ],
                        [
                            -96.5927919996354,
                            46.2139140001172
                        ],
                        [
                            -96.5917420002223,
                            46.2181150000101
                        ],
                        [
                            -96.5957769998149,
                            46.2197879998139
                        ],
                        [
                            -96.5947130001581,
                            46.2235069999968
                        ],
                        [
                            -96.5977920000489,
                            46.2288800001416
                        ],
                        [
                            -96.5969390002793,
                            46.232363999779
                        ],
                        [
                            -96.5990499998745,
                            46.2357229996914
                        ],
                        [
                            -96.5987290003824,
                            46.2423170002697
                        ],
                        [
                            -96.5939060004192,
                            46.2455239999987
                        ],
                        [
                            -96.5907370001167,
                            46.2450920002238
                        ],
                        [
                            -96.5860530000707,
                            46.2470250002498
                        ],
                        [
                            -96.5837149997964,
                            46.2498070002685
                        ],
                        [
                            -96.5923000004391,
                            46.2503379997885
                        ],
                        [
                            -96.595375000132,
                            46.2533980001958
                        ],
                        [
                            -96.5929280000778,
                            46.2573129998505
                        ],
                        [
                            -96.5963049998945,
                            46.2605250001247
                        ],
                        [
                            -96.6004020003097,
                            46.2617499997688
                        ],
                        [
                            -96.5968990000963,
                            46.2678050000203
                        ],
                        [
                            -96.5976120001238,
                            46.2700060001791
                        ],
                        [
                            -96.5960399995806,
                            46.2715109999786
                        ],
                        [
                            -96.5973459999851,
                            46.2728130001688
                        ],
                        [
                            -96.5950439996959,
                            46.2742840000877
                        ],
                        [
                            -96.5963340002068,
                            46.2779350002747
                        ],
                        [
                            -96.6003200002939,
                            46.2811310001256
                        ],
                        [
                            -96.5947549999909,
                            46.2859649998207
                        ],
                        [
                            -96.6017649997172,
                            46.286018999772
                        ],
                        [
                            -96.601554999655,
                            46.2873380003086
                        ],
                        [
                            -96.5963790004127,
                            46.2880010000313
                        ],
                        [
                            -96.5963790004127,
                            46.291193999928
                        ],
                        [
                            -96.6056939999029,
                            46.2937229999354
                        ],
                        [
                            -96.5972980003045,
                            46.2975870003033
                        ],
                        [
                            -96.6035859997825,
                            46.3025300001348
                        ],
                        [
                            -96.6001810003768,
                            46.3052929999426
                        ],
                        [
                            -96.6002750000881,
                            46.3088379999515
                        ],
                        [
                            -96.5967669998519,
                            46.3170039998372
                        ],
                        [
                            -96.6012040000257,
                            46.3194979998389
                        ],
                        [
                            -96.600027000391,
                            46.3268169997705
                        ],
                        [
                            -96.606138000317,
                            46.3266039998134
                        ],
                        [
                            -96.5993789997625,
                            46.3299840001915
                        ],
                        [
                            -96.6061590002334,
                            46.3311909997917
                        ],
                        [
                            -96.6096139998678,
                            46.3361930002442
                        ],
                        [
                            -96.6202449998083,
                            46.3402430000308
                        ],
                        [
                            -96.6162019998198,
                            46.3458950002527
                        ],
                        [
                            -96.6166739998229,
                            46.3473880002624
                        ],
                        [
                            -96.6278439997137,
                            46.3494170001096
                        ],
                        [
                            -96.6294029998381,
                            46.3543189998254
                        ],
                        [
                            -96.6425660002216,
                            46.3500399999624
                        ],
                        [
                            -96.6451940000257,
                            46.3506689998136
                        ],
                        [
                            -96.6484500003669,
                            46.3580810001773
                        ],
                        [
                            -96.6431849996395,
                            46.3619349998976
                        ],
                        [
                            -96.6549770002195,
                            46.3659370001271
                        ],
                        [
                            -96.6567139997208,
                            46.3696210001306
                        ],
                        [
                            -96.6603790003159,
                            46.3715630000028
                        ],
                        [
                            -96.6578040002152,
                            46.3744189997245
                        ],
                        [
                            -96.6671349995991,
                            46.3755790002552
                        ],
                        [
                            -96.666818000305,
                            46.3798889999301
                        ],
                        [
                            -96.6696369999048,
                            46.3843790000786
                        ],
                        [
                            -96.6681780002378,
                            46.3876179997976
                        ],
                        [
                            -96.6710269999748,
                            46.3894940002501
                        ],
                        [
                            -96.6677089997094,
                            46.3912439999327
                        ],
                        [
                            -96.6750780003593,
                            46.3909619997498
                        ],
                        [
                            -96.6724859996419,
                            46.3952800002967
                        ],
                        [
                            -96.6805049996718,
                            46.4071079997395
                        ],
                        [
                            -96.6874840003343,
                            46.4055639997339
                        ],
                        [
                            -96.6891129998806,
                            46.4068340001768
                        ],
                        [
                            -96.6898810001598,
                            46.4096720000973
                        ],
                        [
                            -96.684970000333,
                            46.4123639999363
                        ],
                        [
                            -96.6853550002975,
                            46.4142119999746
                        ],
                        [
                            -96.6971970002079,
                            46.4125759999165
                        ],
                        [
                            -96.7015499998178,
                            46.4150630002883
                        ],
                        [
                            -96.7015170002058,
                            46.4164829998882
                        ],
                        [
                            -96.6989379999072,
                            46.4172140002334
                        ],
                        [
                            -96.692247999848,
                            46.4161109997592
                        ],
                        [
                            -96.693313000228,
                            46.422085000029
                        ],
                        [
                            -96.704581000028,
                            46.4179949998239
                        ],
                        [
                            -96.7019869996608,
                            46.4253150000896
                        ],
                        [
                            -96.6988550000665,
                            46.4263830000864
                        ],
                        [
                            -96.7047049998766,
                            46.4264060001229
                        ],
                        [
                            -96.7012309999756,
                            46.4302360002423
                        ],
                        [
                            -96.7057090001573,
                            46.4288139997809
                        ],
                        [
                            -96.707654999896,
                            46.4297870002234
                        ],
                        [
                            -96.7032689997758,
                            46.4372949997607
                        ],
                        [
                            -96.7049419997031,
                            46.4382460002685
                        ],
                        [
                            -96.7104500001047,
                            46.4356049999316
                        ],
                        [
                            -96.7122400002078,
                            46.4360620001421
                        ],
                        [
                            -96.7124800004074,
                            46.4378839998204
                        ],
                        [
                            -96.7157300000024,
                            46.4373180001452
                        ],
                        [
                            -96.7137499999285,
                            46.4349550002329
                        ],
                        [
                            -96.7154179998329,
                            46.4339769998715
                        ],
                        [
                            -96.7179269998113,
                            46.4393740001694
                        ],
                        [
                            -96.721852999624,
                            46.4398290002131
                        ],
                        [
                            -96.718273000316,
                            46.4418249997927
                        ],
                        [
                            -96.712302999957,
                            46.4412879997568
                        ],
                        [
                            -96.7163619998391,
                            46.4446269999789
                        ],
                        [
                            -96.7113880002631,
                            46.4464639999146
                        ],
                        [
                            -96.7133470004207,
                            46.4487609999953
                        ],
                        [
                            -96.7178160003815,
                            46.4460330002535
                        ],
                        [
                            -96.7218290002328,
                            46.44880300014
                        ],
                        [
                            -96.7131020001983,
                            46.4501989997254
                        ],
                        [
                            -96.7137039998977,
                            46.4522579999517
                        ],
                        [
                            -96.7175929999005,
                            46.4543649999159
                        ],
                        [
                            -96.7166820004045,
                            46.4559570000692
                        ],
                        [
                            -96.7122200001164,
                            46.4554459998975
                        ],
                        [
                            -96.7116109998459,
                            46.4597830000737
                        ],
                        [
                            -96.718714000357,
                            46.461860000028
                        ],
                        [
                            -96.713184000214,
                            46.4664860000144
                        ],
                        [
                            -96.7185200001882,
                            46.4678140001182
                        ],
                        [
                            -96.7214159997809,
                            46.4652220000139
                        ],
                        [
                            -96.7230829998604,
                            46.4667170000948
                        ],
                        [
                            -96.722517000146,
                            46.469168999888
                        ],
                        [
                            -96.7192249998198,
                            46.4709010001918
                        ],
                        [
                            -96.7146489997289,
                            46.4705530000186
                        ],
                        [
                            -96.7146060000711,
                            46.4722609999599
                        ],
                        [
                            -96.7197420000288,
                            46.4733840001278
                        ],
                        [
                            -96.721599999904,
                            46.4708030001796
                        ],
                        [
                            -96.7235549998636,
                            46.4708679998602
                        ],
                        [
                            -96.7271469997654,
                            46.4722820002544
                        ],
                        [
                            -96.7267900002883,
                            46.4762780002063
                        ],
                        [
                            -96.7305699996128,
                            46.4787560002566
                        ],
                        [
                            -96.7324939996102,
                            46.4782399998293
                        ],
                        [
                            -96.7335539999674,
                            46.4804720002025
                        ],
                        [
                            -96.7356040003615,
                            46.4802489998816
                        ],
                        [
                            -96.7360010000215,
                            46.4774970002798
                        ],
                        [
                            -96.7388749998729,
                            46.4787059999521
                        ],
                        [
                            -96.736822999829,
                            46.4814120002658
                        ],
                        [
                            -96.7324629996481,
                            46.4829360000536
                        ],
                        [
                            -96.7396790001371,
                            46.4867470002942
                        ],
                        [
                            -96.734149999819,
                            46.4885910001289
                        ],
                        [
                            -96.7369759999899,
                            46.4915609999319
                        ],
                        [
                            -96.7332049999879,
                            46.4926860001143
                        ],
                        [
                            -96.7349480002354,
                            46.4949600000703
                        ],
                        [
                            -96.7333930003089,
                            46.496983000296
                        ],
                        [
                            -96.7385769999471,
                            46.4980390000189
                        ],
                        [
                            -96.7370060001271,
                            46.5012119997537
                        ],
                        [
                            -96.7424149998962,
                            46.4999759999327
                        ],
                        [
                            -96.7359590001887,
                            46.5050959998458
                        ],
                        [
                            -96.7334819999974,
                            46.5034670002206
                        ],
                        [
                            -96.7343790001479,
                            46.5069829997092
                        ],
                        [
                            -96.7404449998681,
                            46.5111040000582
                        ],
                        [
                            -96.7352589996817,
                            46.5107230001851
                        ],
                        [
                            -96.7342159999413,
                            46.5121250000271
                        ],
                        [
                            -96.7376019999788,
                            46.5144269998986
                        ],
                        [
                            -96.737443999795,
                            46.5174920001449
                        ],
                        [
                            -96.7399810002609,
                            46.5200470000695
                        ],
                        [
                            -96.7452169997776,
                            46.5201750000981
                        ],
                        [
                            -96.7376010001539,
                            46.5218610000283
                        ],
                        [
                            -96.7367409998132,
                            46.5241300001957
                        ],
                        [
                            -96.7385200000457,
                            46.5254709999238
                        ],
                        [
                            -96.7453349997784,
                            46.524982000138
                        ],
                        [
                            -96.7420500000233,
                            46.5292909998893
                        ],
                        [
                            -96.7432440002747,
                            46.533623000056
                        ],
                        [
                            -96.7461649999818,
                            46.5348809999088
                        ],
                        [
                            -96.7380110002327,
                            46.5346020001898
                        ],
                        [
                            -96.7426550000957,
                            46.5381040001895
                        ],
                        [
                            -96.7382599997547,
                            46.5392940000655
                        ],
                        [
                            -96.7438579996697,
                            46.5403680002997
                        ],
                        [
                            -96.7468019998414,
                            46.5388589997288
                        ],
                        [
                            -96.7501159999088,
                            46.5407589999496
                        ],
                        [
                            -96.7451359995868,
                            46.5420459997217
                        ],
                        [
                            -96.7411960004287,
                            46.5406709997856
                        ],
                        [
                            -96.7387150000394,
                            46.5432280001726
                        ],
                        [
                            -96.7505440004293,
                            46.5441310000337
                        ],
                        [
                            -96.744949999814,
                            46.5482250002078
                        ],
                        [
                            -96.7418659999002,
                            46.5453470001325
                        ],
                        [
                            -96.7405689997166,
                            46.5468379997963
                        ],
                        [
                            -96.7452140003029,
                            46.5505980002499
                        ],
                        [
                            -96.7492120000856,
                            46.5495850002746
                        ],
                        [
                            -96.7460429997831,
                            46.5560839997748
                        ],
                        [
                            -96.7519069998369,
                            46.5555410002625
                        ],
                        [
                            -96.754397000447,
                            46.5568180003
                        ],
                        [
                            -96.7467850001229,
                            46.5588490000027
                        ],
                        [
                            -96.7519679999362,
                            46.5626099997054
                        ],
                        [
                            -96.7470720001781,
                            46.5624180000853
                        ],
                        [
                            -96.7455609997342,
                            46.565258000302
                        ],
                        [
                            -96.7417500004476,
                            46.5639969997583
                        ],
                        [
                            -96.7436700002471,
                            46.567079000101
                        ],
                        [
                            -96.7474470000968,
                            46.5674519997098
                        ],
                        [
                            -96.74539000003,
                            46.5708329999409
                        ],
                        [
                            -96.7527589997816,
                            46.5713839998933
                        ],
                        [
                            -96.7516099997359,
                            46.5733940000896
                        ],
                        [
                            -96.7466060000228,
                            46.5736530002495
                        ],
                        [
                            -96.7466150002436,
                            46.5755459996985
                        ],
                        [
                            -96.7524120003502,
                            46.5763280000374
                        ],
                        [
                            -96.7558400002205,
                            46.5743129998929
                        ],
                        [
                            -96.757499999729,
                            46.5778299997271
                        ],
                        [
                            -96.7532430003785,
                            46.5786609999875
                        ],
                        [
                            -96.7521029996554,
                            46.5820840001574
                        ],
                        [
                            -96.7484060001716,
                            46.5805919998528
                        ],
                        [
                            -96.747225000339,
                            46.5819169999466
                        ],
                        [
                            -96.7504039997889,
                            46.58380800008
                        ],
                        [
                            -96.7609009998351,
                            46.5818430000142
                        ],
                        [
                            -96.7604280000071,
                            46.58352400015
                        ],
                        [
                            -96.7513250002289,
                            46.5846740002729
                        ],
                        [
                            -96.7508010003473,
                            46.5858999998337
                        ],
                        [
                            -96.7527300003675,
                            46.5883510002386
                        ],
                        [
                            -96.7574930000563,
                            46.5868820003076
                        ],
                        [
                            -96.7626080000976,
                            46.589323999902
                        ],
                        [
                            -96.7622139999122,
                            46.5919669998421
                        ],
                        [
                            -96.7561909998498,
                            46.592288000269
                        ],
                        [
                            -96.7559840001606,
                            46.595379000193
                        ],
                        [
                            -96.7630270003972,
                            46.5941230000145
                        ],
                        [
                            -96.7670539995939,
                            46.5988870002324
                        ],
                        [
                            -96.7694870003027,
                            46.5978450000303
                        ],
                        [
                            -96.7701180003144,
                            46.5955639999368
                        ],
                        [
                            -96.7715789996313,
                            46.5962129998829
                        ],
                        [
                            -96.7725290003836,
                            46.6007179998054
                        ],
                        [
                            -96.7701040000707,
                            46.6023800000145
                        ],
                        [
                            -96.7722359995823,
                            46.6038299997641
                        ],
                        [
                            -96.7682460001955,
                            46.606324000162
                        ],
                        [
                            -96.7764450001505,
                            46.6054269997458
                        ],
                        [
                            -96.7788319999302,
                            46.6074600000268
                        ],
                        [
                            -96.7725109999419,
                            46.6092929999892
                        ],
                        [
                            -96.7746230002603,
                            46.6120279997061
                        ],
                        [
                            -96.7708430000375,
                            46.6152700000065
                        ],
                        [
                            -96.7735250002683,
                            46.6163930001471
                        ],
                        [
                            -96.7778829999011,
                            46.6138219996998
                        ],
                        [
                            -96.7812379999765,
                            46.6153820001353
                        ],
                        [
                            -96.7808310002707,
                            46.6172469999352
                        ],
                        [
                            -96.7749980001791,
                            46.617522999939
                        ],
                        [
                            -96.7755320001064,
                            46.6212009998186
                        ],
                        [
                            -96.7798780000437,
                            46.6194849998082
                        ],
                        [
                            -96.7835610001821,
                            46.6221529996956
                        ],
                        [
                            -96.7851770002079,
                            46.6190310000137
                        ],
                        [
                            -96.7872590003891,
                            46.6194390000295
                        ],
                        [
                            -96.7862979997661,
                            46.6224209997937
                        ],
                        [
                            -96.778332000338,
                            46.6254279999596
                        ],
                        [
                            -96.7874030003291,
                            46.6238869997773
                        ],
                        [
                            -96.7913059996773,
                            46.6249709998638
                        ],
                        [
                            -96.786009999886,
                            46.627127000139
                        ],
                        [
                            -96.7843810003397,
                            46.6305040000681
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 46.3158148,
            "name": "Minnesota",
            "intptlon": -94.1996628,
            "geo_point_2d": [
                46.3491388483,
                -94.1981561215
            ],
            "geoid": "27",
            "mtfcc": "G4000",
            "region": 2
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -94.1981561215,
                46.3491388483
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "38c2cfad5e16982e16ff8b863c27441df26e55ab",
        "fields": {
            "arealand": "1712577727",
            "objectid": 8,
            "basename": "New Hampshire",
            "stusab": "NH",
            "statens": "01779794",
            "centlon": -71.5738495,
            "state": "33",
            "gid": 42,
            "centlat": 43.6740331,
            "division": 1,
            "areawater": "1028674404",
            "oid": "-1858446377",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -72.32989899992,
                            43.6002139999586
                        ],
                        [
                            -72.3281289999083,
                            43.6016709997597
                        ],
                        [
                            -72.3284190003366,
                            43.6070579999416
                        ],
                        [
                            -72.3331950004441,
                            43.6102849997844
                        ],
                        [
                            -72.3348270003635,
                            43.6179519997623
                        ],
                        [
                            -72.3327829998171,
                            43.624361000044
                        ],
                        [
                            -72.329165999801,
                            43.6268250000772
                        ],
                        [
                            -72.3273809997207,
                            43.6306629999203
                        ],
                        [
                            -72.3297619996526,
                            43.633447000098
                        ],
                        [
                            -72.3291359996637,
                            43.6355300002991
                        ],
                        [
                            -72.3146940004366,
                            43.6418579999499
                        ],
                        [
                            -72.3136330002545,
                            43.6569379997919
                        ],
                        [
                            -72.3031489997288,
                            43.6686190000084
                        ],
                        [
                            -72.3014249997479,
                            43.675760000036
                        ],
                        [
                            -72.3060179995574,
                            43.6833509997944
                        ],
                        [
                            -72.3051330000007,
                            43.6953209999658
                        ],
                        [
                            -72.3017760002755,
                            43.6996549997266
                        ],
                        [
                            -72.2997009997671,
                            43.7061089997384
                        ],
                        [
                            -72.2921780000297,
                            43.7110680003177
                        ],
                        [
                            -72.281741000258,
                            43.7237630000382
                        ],
                        [
                            -72.2756830000354,
                            43.7271050002209
                        ],
                        [
                            -72.2711050002946,
                            43.7339180000234
                        ],
                        [
                            -72.2644670001139,
                            43.7338949996947
                        ],
                        [
                            -72.2466599998673,
                            43.7424859999059
                        ],
                        [
                            -72.2341779997244,
                            43.7459649998126
                        ],
                        [
                            -72.2309430001979,
                            43.7481920002804
                        ],
                        [
                            -72.2185370002229,
                            43.7654649996915
                        ],
                        [
                            -72.2052299998994,
                            43.7709300002041
                        ],
                        [
                            -72.203934000439,
                            43.779403999842
                        ],
                        [
                            -72.2055360002211,
                            43.7845840002111
                        ],
                        [
                            -72.1961820003728,
                            43.7909169998755
                        ],
                        [
                            -72.1909939996383,
                            43.8004839998764
                        ],
                        [
                            -72.1840509998591,
                            43.8064039998053
                        ],
                        [
                            -72.1834409997637,
                            43.8104479997505
                        ],
                        [
                            -72.1889499999902,
                            43.8211899998722
                        ],
                        [
                            -72.1824490000769,
                            43.8341210002354
                        ],
                        [
                            -72.1830900001344,
                            43.8446800003165
                        ],
                        [
                            -72.1880339995731,
                            43.8551549998288
                        ],
                        [
                            -72.1846159997486,
                            43.864143000053
                        ],
                        [
                            -72.1679070003924,
                            43.8691089997872
                        ],
                        [
                            -72.1735839999501,
                            43.8796149998776
                        ],
                        [
                            -72.1721029996434,
                            43.8849859999944
                        ],
                        [
                            -72.1597279996306,
                            43.8876640000792
                        ],
                        [
                            -72.1571640002988,
                            43.8949500000773
                        ],
                        [
                            -72.1505269999431,
                            43.9020989999725
                        ],
                        [
                            -72.1360610004265,
                            43.9091709999071
                        ],
                        [
                            -72.1324140002731,
                            43.9133210001536
                        ],
                        [
                            -72.1204509999889,
                            43.9195550002729
                        ],
                        [
                            -72.1181470000499,
                            43.9236059998007
                        ],
                        [
                            -72.1169109999656,
                            43.9338439997613
                        ],
                        [
                            -72.119276000004,
                            43.9437469999283
                        ],
                        [
                            -72.1183450004166,
                            43.9465319997863
                        ],
                        [
                            -72.1057400002499,
                            43.9498290002939
                        ],
                        [
                            -72.098995999764,
                            43.9575640001908
                        ],
                        [
                            -72.1011480002654,
                            43.9608520001216
                        ],
                        [
                            -72.1006750004374,
                            43.963004000321
                        ],
                        [
                            -72.0909399999242,
                            43.9657800002582
                        ],
                        [
                            -72.1059999996426,
                            43.9700220001718
                        ],
                        [
                            -72.110852999743,
                            43.9669939999015
                        ],
                        [
                            -72.1143779996977,
                            43.9680539997182
                        ],
                        [
                            -72.1120429997965,
                            43.9859520001776
                        ],
                        [
                            -72.116635999606,
                            43.9919720001823
                        ],
                        [
                            -72.1168649999348,
                            43.9944670003175
                        ],
                        [
                            -72.1041230003992,
                            44.0023710002262
                        ],
                        [
                            -72.1057710002121,
                            44.0122810000544
                        ],
                        [
                            -72.1027650001162,
                            44.0145779998812
                        ],
                        [
                            -72.0969370000474,
                            44.0150350001507
                        ],
                        [
                            -72.0935490003601,
                            44.0128610003173
                        ],
                        [
                            -72.0934880002607,
                            44.0094429998099
                        ],
                        [
                            -72.0905270003706,
                            44.0099239997485
                        ],
                        [
                            -72.0953489996106,
                            44.0164620003043
                        ],
                        [
                            -72.0954259996035,
                            44.0217569997036
                        ],
                        [
                            -72.0915039999888,
                            44.0244120002256
                        ],
                        [
                            -72.0854769997284,
                            44.0212610002463
                        ],
                        [
                            -72.0826689999992,
                            44.0218030002166
                        ],
                        [
                            -72.0813419996784,
                            44.0283640002779
                        ],
                        [
                            -72.0748109996278,
                            44.0324070000483
                        ],
                        [
                            -72.0791440000446,
                            44.0395020002219
                        ],
                        [
                            -72.0783199996889,
                            44.0433780000917
                        ],
                        [
                            -72.0619009998626,
                            44.0495960003165
                        ],
                        [
                            -72.0631219998783,
                            44.0521979998563
                        ],
                        [
                            -72.0689359997034,
                            44.0542809998722
                        ],
                        [
                            -72.0680960003525,
                            44.0573779997771
                        ],
                        [
                            -72.0572929999846,
                            44.0583389997413
                        ],
                        [
                            -72.0483049998341,
                            44.0697069999302
                        ],
                        [
                            -72.0528680004046,
                            44.0763059998537
                        ],
                        [
                            -72.043513999658,
                            44.0792440000805
                        ],
                        [
                            -72.0380360002919,
                            44.076543000293
                        ],
                        [
                            -72.031840999802,
                            44.0785419998582
                        ],
                        [
                            -72.0332450001157,
                            44.0815100003159
                        ],
                        [
                            -72.0452839996696,
                            44.0873080000416
                        ],
                        [
                            -72.0434829996958,
                            44.0889019999687
                        ],
                        [
                            -72.0371049998061,
                            44.0886360002412
                        ],
                        [
                            -72.0332600001843,
                            44.0904739998783
                        ],
                        [
                            -72.0306809998857,
                            44.0977140000759
                        ],
                        [
                            -72.0313060000497,
                            44.1004300001408
                        ],
                        [
                            -72.0394549997759,
                            44.1035199997369
                        ],
                        [
                            -72.0422630004034,
                            44.1013530001369
                        ],
                        [
                            -72.0439100003914,
                            44.0965999999291
                        ],
                        [
                            -72.0478799996867,
                            44.0965859997242
                        ],
                        [
                            -72.0521350002856,
                            44.1001560000982
                        ],
                        [
                            -72.0553399996748,
                            44.1106000002198
                        ],
                        [
                            -72.0532640002398,
                            44.1146900001713
                        ],
                        [
                            -72.0531429998659,
                            44.1195110001539
                        ],
                        [
                            -72.045131000407,
                            44.1246909998782
                        ],
                        [
                            -72.0373030001729,
                            44.1249129998131
                        ],
                        [
                            -72.0332600001843,
                            44.1316800002757
                        ],
                        [
                            -72.0419570000817,
                            44.1369980000766
                        ],
                        [
                            -72.0428269995697,
                            44.1509669998642
                        ],
                        [
                            -72.0389970000165,
                            44.1567960000205
                        ],
                        [
                            -72.0430869998607,
                            44.1565519998766
                        ],
                        [
                            -72.0439110002163,
                            44.1580539996853
                        ],
                        [
                            -72.0422929996423,
                            44.162045000216
                        ],
                        [
                            -72.0471909999486,
                            44.1616330001713
                        ],
                        [
                            -72.0489459998916,
                            44.1652410002767
                        ],
                        [
                            -72.0518299997888,
                            44.1664999997399
                        ],
                        [
                            -72.0583309997021,
                            44.1813240001259
                        ],
                        [
                            -72.0661580001114,
                            44.1901279999698
                        ],
                        [
                            -72.0643270000004,
                            44.1975739997741
                        ],
                        [
                            -72.0587430003291,
                            44.2020299997079
                        ],
                        [
                            -72.057750999744,
                            44.2058370000503
                        ],
                        [
                            -72.0594749997249,
                            44.210560000203
                        ],
                        [
                            -72.0553699998121,
                            44.2128329998471
                        ],
                        [
                            -72.0525779999764,
                            44.2178299998519
                        ],
                        [
                            -72.0536309997626,
                            44.2253450001435
                        ],
                        [
                            -72.0476639997766,
                            44.2389560002393
                        ],
                        [
                            -72.0501209998765,
                            44.2443119998811
                        ],
                        [
                            -72.0547299995795,
                            44.2478749998118
                        ],
                        [
                            -72.0615200000961,
                            44.2610729997207
                        ],
                        [
                            -72.0594599996563,
                            44.2619970001813
                        ],
                        [
                            -72.0607719999085,
                            44.261850999916
                        ],
                        [
                            -72.0616570003635,
                            44.2635529999177
                        ],
                        [
                            -72.0594899997935,
                            44.2657650001706
                        ],
                        [
                            -72.0596429999544,
                            44.2639649997125
                        ],
                        [
                            -72.0586050002368,
                            44.2650560000888
                        ],
                        [
                            -72.060054999683,
                            44.2675890000503
                        ],
                        [
                            -72.0583309997021,
                            44.2700830002679
                        ],
                        [
                            -72.0647539997977,
                            44.2676880002516
                        ],
                        [
                            -72.0671349997296,
                            44.2682370002715
                        ],
                        [
                            -72.068477999944,
                            44.2708009997153
                        ],
                        [
                            -72.0643889999247,
                            44.2793229997193
                        ],
                        [
                            -72.058636000199,
                            44.2865019998489
                        ],
                        [
                            -72.0534630004314,
                            44.2903549998205
                        ],
                        [
                            -72.0463830003848,
                            44.2919950002009
                        ],
                        [
                            -72.0440019995546,
                            44.295337000112
                        ],
                        [
                            -72.0385240001885,
                            44.2964430001587
                        ],
                        [
                            -72.0328779996946,
                            44.3021649996977
                        ],
                        [
                            -72.0314130001798,
                            44.3152489997279
                        ],
                        [
                            -72.0336099999887,
                            44.3171409998119
                        ],
                        [
                            -72.0328020004249,
                            44.3204899999225
                        ],
                        [
                            -72.0290020001106,
                            44.3223830000388
                        ],
                        [
                            -72.0192520004272,
                            44.3201930003083
                        ],
                        [
                            -72.0090890002918,
                            44.322222000055
                        ],
                        [
                            -71.9881379998571,
                            44.3300649998478
                        ],
                        [
                            -71.9846589999332,
                            44.3360159996896
                        ],
                        [
                            -71.9814240004067,
                            44.3373659999546
                        ],
                        [
                            -71.9630370002021,
                            44.3365119998122
                        ],
                        [
                            -71.9455949998285,
                            44.337709999882
                        ],
                        [
                            -71.9340440001713,
                            44.3360009999833
                        ],
                        [
                            -71.9290999998342,
                            44.3376260000486
                        ],
                        [
                            -71.9252250000752,
                            44.3418529997813
                        ],
                        [
                            -71.917212999718,
                            44.3466359998913
                        ],
                        [
                            -71.9124220004401,
                            44.3481240001679
                        ],
                        [
                            -71.9005649995628,
                            44.3470410000284
                        ],
                        [
                            -71.8715880003238,
                            44.3366799999803
                        ],
                        [
                            -71.8631340001007,
                            44.3398989999983
                        ],
                        [
                            -71.8528499995916,
                            44.3409220001879
                        ],
                        [
                            -71.8330890001087,
                            44.3502369997343
                        ],
                        [
                            -71.8158459999268,
                            44.3540059999298
                        ],
                        [
                            -71.8124130000336,
                            44.3573249999686
                        ],
                        [
                            -71.8162889996176,
                            44.3674030000136
                        ],
                        [
                            -71.8123209999721,
                            44.3716369996912
                        ],
                        [
                            -71.8156779996973,
                            44.375024999738
                        ],
                        [
                            -71.8144270004427,
                            44.3819220002637
                        ],
                        [
                            -71.8091319995781,
                            44.3837600002163
                        ],
                        [
                            -71.8005719999481,
                            44.3840279998724
                        ],
                        [
                            -71.7998849998599,
                            44.3860720001781
                        ],
                        [
                            -71.8034879996323,
                            44.3918899997019
                        ],
                        [
                            -71.7944829997634,
                            44.39900400014
                        ],
                        [
                            -71.7908210004397,
                            44.4002469998478
                        ],
                        [
                            -71.7784460004269,
                            44.3998350000073
                        ],
                        [
                            -71.7619659996028,
                            44.4070270000113
                        ],
                        [
                            -71.7560909996784,
                            44.4064009997941
                        ],
                        [
                            -71.7484609998109,
                            44.4014379997257
                        ],
                        [
                            -71.7437259997113,
                            44.4010269998691
                        ],
                        [
                            -71.7354129999535,
                            44.4100049998813
                        ],
                        [
                            -71.7257429997376,
                            44.4109639997507
                        ],
                        [
                            -71.714279000119,
                            44.4095449996835
                        ],
                        [
                            -71.7025800003237,
                            44.4135420003017
                        ],
                        [
                            -71.6941300002986,
                            44.4193910000864
                        ],
                        [
                            -71.6842850001806,
                            44.423645000285
                        ],
                        [
                            -71.6827009999418,
                            44.4273400000356
                        ],
                        [
                            -71.6769669995843,
                            44.4268480001852
                        ],
                        [
                            -71.6759509996081,
                            44.4289059997096
                        ],
                        [
                            -71.6792820002923,
                            44.434337000144
                        ],
                        [
                            -71.6779619996442,
                            44.4352550000752
                        ],
                        [
                            -71.6655459996234,
                            44.4367119999342
                        ],
                        [
                            -71.6608830003921,
                            44.4399369996809
                        ],
                        [
                            -71.6577559999223,
                            44.4455519998372
                        ],
                        [
                            -71.6563709998752,
                            44.4551590002496
                        ],
                        [
                            -71.654216999724,
                            44.4588230000812
                        ],
                        [
                            -71.6516929996769,
                            44.460872000144
                        ],
                        [
                            -71.644266000199,
                            44.460250000242
                        ],
                        [
                            -71.6389860003014,
                            44.4644109999383
                        ],
                        [
                            -71.6396270003589,
                            44.4663510001767
                        ],
                        [
                            -71.6469769998438,
                            44.4699449997905
                        ],
                        [
                            -71.6468370001018,
                            44.4732020002986
                        ],
                        [
                            -71.6346150002498,
                            44.4804620002755
                        ],
                        [
                            -71.6327819995906,
                            44.483796000174
                        ],
                        [
                            -71.6268609996354,
                            44.4840249997144
                        ],
                        [
                            -71.6246630000016,
                            44.4817440002524
                        ],
                        [
                            -71.6276380001353,
                            44.4796759999815
                        ],
                        [
                            -71.6342300002853,
                            44.4788439998668
                        ],
                        [
                            -71.6345049997466,
                            44.4756250000733
                        ],
                        [
                            -71.6317890000789,
                            44.4740229997364
                        ],
                        [
                            -71.6262050004076,
                            44.4744660001414
                        ],
                        [
                            -71.617843000246,
                            44.4858410000144
                        ],
                        [
                            -71.6095260002903,
                            44.4842000002125
                        ],
                        [
                            -71.5986009997236,
                            44.4868249998025
                        ],
                        [
                            -71.5952130000363,
                            44.4934090000689
                        ],
                        [
                            -71.5938849998905,
                            44.5010310002664
                        ],
                        [
                            -71.5910169998869,
                            44.5011070002462
                        ],
                        [
                            -71.5894300001734,
                            44.4982230000865
                        ],
                        [
                            -71.5859200002873,
                            44.4993600001738
                        ],
                        [
                            -71.5881329999897,
                            44.506585000167
                        ],
                        [
                            -71.5857679999513,
                            44.5061879999485
                        ],
                        [
                            -71.58292900026,
                            44.5021980001828
                        ],
                        [
                            -71.5770240001983,
                            44.5020609998616
                        ],
                        [
                            -71.5769480000303,
                            44.5043120002412
                        ],
                        [
                            -71.582853000092,
                            44.5081799998353
                        ],
                        [
                            -71.5863019998787,
                            44.5146720001108
                        ],
                        [
                            -71.5919319995808,
                            44.5179070002418
                        ],
                        [
                            -71.5941140002194,
                            44.5215539998772
                        ],
                        [
                            -71.592586999882,
                            44.5232609998411
                        ],
                        [
                            -71.5862859999852,
                            44.522346999851
                        ],
                        [
                            -71.5807469996214,
                            44.5245980000985
                        ],
                        [
                            -71.5734990002437,
                            44.5331430001133
                        ],
                        [
                            -71.5718670003243,
                            44.5379190001837
                        ],
                        [
                            -71.5766419997086,
                            44.5423590002947
                        ],
                        [
                            -71.598585999655,
                            44.554794999964
                        ],
                        [
                            -71.5969679999793,
                            44.5594710001866
                        ],
                        [
                            -71.5949379996767,
                            44.5629199997053
                        ],
                        [
                            -71.5895780003114,
                            44.5657370001277
                        ],
                        [
                            -71.5761239996747,
                            44.5650789999011
                        ],
                        [
                            -71.5682350002395,
                            44.5627599998182
                        ],
                        [
                            -71.5597170004423,
                            44.5639750002451
                        ],
                        [
                            -71.558225000265,
                            44.5653769997547
                        ],
                        [
                            -71.5591559998524,
                            44.5681379999297
                        ],
                        [
                            -71.5577110004291,
                            44.5704040000454
                        ],
                        [
                            -71.5513119997247,
                            44.5691149998988
                        ],
                        [
                            -71.5477259996707,
                            44.5713809997083
                        ],
                        [
                            -71.5548059997172,
                            44.5793689998336
                        ],
                        [
                            -71.5523650004092,
                            44.5843929999912
                        ],
                        [
                            -71.5502589999386,
                            44.5837939997258
                        ],
                        [
                            -71.5516790001458,
                            44.5807799999648
                        ],
                        [
                            -71.5496639999118,
                            44.5783229998271
                        ],
                        [
                            -71.5457880003279,
                            44.5779339998956
                        ],
                        [
                            -71.5384639998838,
                            44.5821309999817
                        ],
                        [
                            -71.5349089997919,
                            44.5878980001824
                        ],
                        [
                            -71.5400509995974,
                            44.5885239997672
                        ],
                        [
                            -71.5378689998571,
                            44.5922620001387
                        ],
                        [
                            -71.538494000021,
                            44.594170000243
                        ],
                        [
                            -71.5468929999926,
                            44.5908370000326
                        ],
                        [
                            -71.5459719995526,
                            44.597130000101
                        ],
                        [
                            -71.555188000207,
                            44.5933460000603
                        ],
                        [
                            -71.5541510003143,
                            44.5979610000093
                        ],
                        [
                            -71.5506099995679,
                            44.5996930002017
                        ],
                        [
                            -71.5509460000268,
                            44.6010199998944
                        ],
                        [
                            -71.5572789997107,
                            44.5993810000605
                        ],
                        [
                            -71.5621309999862,
                            44.6011809997697
                        ],
                        [
                            -71.5617639995651,
                            44.6031340002118
                        ],
                        [
                            -71.5558590004017,
                            44.6037060000846
                        ],
                        [
                            -71.5536309997324,
                            44.6061319999773
                        ],
                        [
                            -71.5565459995916,
                            44.6177440002708
                        ],
                        [
                            -71.5551270001076,
                            44.6252440001124
                        ],
                        [
                            -71.547862999938,
                            44.6222450003166
                        ],
                        [
                            -71.5463370003238,
                            44.6229550000756
                        ],
                        [
                            -71.5480010000303,
                            44.6251369997211
                        ],
                        [
                            -71.5569270002565,
                            44.6274180001954
                        ],
                        [
                            -71.557019000318,
                            44.6294859998649
                        ],
                        [
                            -71.5544860000501,
                            44.6295319999806
                        ],
                        [
                            -71.5521040002933,
                            44.6273140003189
                        ],
                        [
                            -71.5469309996274,
                            44.6285500001258
                        ],
                        [
                            -71.5468190003727,
                            44.629756000218
                        ],
                        [
                            -71.5537560003041,
                            44.6304259998833
                        ],
                        [
                            -71.5637210000728,
                            44.637247000149
                        ],
                        [
                            -71.5637749996011,
                            44.6389429997992
                        ],
                        [
                            -71.5597040000235,
                            44.6392979999561
                        ],
                        [
                            -71.5577900000718,
                            44.6419599998262
                        ],
                        [
                            -71.5619619999318,
                            44.647188999801
                        ],
                        [
                            -71.5628060003789,
                            44.6523559997304
                        ],
                        [
                            -71.5657940000331,
                            44.6538520002459
                        ],
                        [
                            -71.5690250002599,
                            44.6502899998951
                        ],
                        [
                            -71.5770810000997,
                            44.6506860001881
                        ],
                        [
                            -71.5800710003021,
                            44.6520989999233
                        ],
                        [
                            -71.5752590002096,
                            44.6531020001466
                        ],
                        [
                            -71.5752149998286,
                            44.6558299999425
                        ],
                        [
                            -71.5827219996725,
                            44.6558909997055
                        ],
                        [
                            -71.5873649997106,
                            44.6597150001654
                        ],
                        [
                            -71.5849329997251,
                            44.6640540000208
                        ],
                        [
                            -71.5878769998967,
                            44.6699319997685
                        ],
                        [
                            -71.581463000022,
                            44.6732040002432
                        ],
                        [
                            -71.5966149998019,
                            44.6780879999061
                        ],
                        [
                            -71.5939590004087,
                            44.6834249998573
                        ],
                        [
                            -71.5979370000999,
                            44.6922219999034
                        ],
                        [
                            -71.5938609996011,
                            44.6964149999448
                        ],
                        [
                            -71.6007699999434,
                            44.6990170000442
                        ],
                        [
                            -71.598589000028,
                            44.7032410001503
                        ],
                        [
                            -71.599391999569,
                            44.7053150001472
                        ],
                        [
                            -71.6050139997734,
                            44.708409999688
                        ],
                        [
                            -71.6085200003598,
                            44.7139560002074
                        ],
                        [
                            -71.6125120002947,
                            44.7166730002357
                        ],
                        [
                            -71.6125480002797,
                            44.7191859999027
                        ],
                        [
                            -71.6186440001371,
                            44.7221930001796
                        ],
                        [
                            -71.617106999754,
                            44.7287119997194
                        ],
                        [
                            -71.623582999553,
                            44.7273160000234
                        ],
                        [
                            -71.6260930002546,
                            44.7285430001539
                        ],
                        [
                            -71.6249359998131,
                            44.7368110002252
                        ],
                        [
                            -71.6264110002719,
                            44.7405099998087
                        ],
                        [
                            -71.625092000347,
                            44.7433609997292
                        ],
                        [
                            -71.6269860002072,
                            44.7470140000596
                        ],
                        [
                            -71.6313820003732,
                            44.7486930001214
                        ],
                        [
                            -71.6321599997997,
                            44.7509849999366
                        ],
                        [
                            -71.6303520001532,
                            44.7533070002109
                        ],
                        [
                            -71.616669000086,
                            44.7562989997627
                        ],
                        [
                            -71.6128560002513,
                            44.7589979998219
                        ],
                        [
                            -71.6113549998532,
                            44.7653290002376
                        ],
                        [
                            -71.6062130000477,
                            44.7668189999592
                        ],
                        [
                            -71.6005470003607,
                            44.7729340002559
                        ],
                        [
                            -71.5950990002335,
                            44.7754050000753
                        ],
                        [
                            -71.5956409996585,
                            44.779312000267
                        ],
                        [
                            -71.5924859995996,
                            44.7826689999468
                        ],
                        [
                            -71.5791819996492,
                            44.7849360002278
                        ],
                        [
                            -71.5728170001783,
                            44.7908709998258
                        ],
                        [
                            -71.5711600001445,
                            44.7943139999912
                        ],
                        [
                            -71.5727999995615,
                            44.7992060000716
                        ],
                        [
                            -71.5683840002024,
                            44.8070400000435
                        ],
                        [
                            -71.5764169995777,
                            44.8127300001436
                        ],
                        [
                            -71.5768650001897,
                            44.8151969999444
                        ],
                        [
                            -71.5731510000891,
                            44.8208230003074
                        ],
                        [
                            -71.5653139996341,
                            44.8241000002395
                        ],
                        [
                            -71.5618360004334,
                            44.8236250002422
                        ],
                        [
                            -71.5572539995963,
                            44.8338730001658
                        ],
                        [
                            -71.5511140002563,
                            44.8369650003034
                        ],
                        [
                            -71.5515770000386,
                            44.8427569997867
                        ],
                        [
                            -71.5557940001044,
                            44.8485669999536
                        ],
                        [
                            -71.5474520000343,
                            44.8553979999886
                        ],
                        [
                            -71.5494510003748,
                            44.8620840001181
                        ],
                        [
                            -71.5459230000471,
                            44.8659320000843
                        ],
                        [
                            -71.5329110001746,
                            44.8702219999823
                        ],
                        [
                            -71.5287510000103,
                            44.8734939996869
                        ],
                        [
                            -71.5283719998936,
                            44.8777120000751
                        ],
                        [
                            -71.5225770003351,
                            44.8796430003088
                        ],
                        [
                            -71.5189560001209,
                            44.8846639997999
                        ],
                        [
                            -71.5160849997443,
                            44.8845229997245
                        ],
                        [
                            -71.5106690003024,
                            44.8904300002553
                        ],
                        [
                            -71.5138829999125,
                            44.8943110001273
                        ],
                        [
                            -71.5082730003019,
                            44.8965200001966
                        ],
                        [
                            -71.5073719999534,
                            44.8994889999279
                        ],
                        [
                            -71.5005229998855,
                            44.904713000205
                        ],
                        [
                            -71.4949099999019,
                            44.9042990000167
                        ],
                        [
                            -71.4960170001147,
                            44.9088979997779
                        ],
                        [
                            -71.4931690002026,
                            44.9113700000929
                        ],
                        [
                            -71.4999449995772,
                            44.9111740001923
                        ],
                        [
                            -71.5007000003358,
                            44.9146280002953
                        ],
                        [
                            -71.5043800001012,
                            44.9191830002543
                        ],
                        [
                            -71.5124960002155,
                            44.9262030002708
                        ],
                        [
                            -71.5157230002443,
                            44.9272319996854
                        ],
                        [
                            -71.5132219997635,
                            44.929210999846
                        ],
                        [
                            -71.5158680000093,
                            44.931714999865
                        ],
                        [
                            -71.5162279998594,
                            44.9351580000183
                        ],
                        [
                            -71.5228379995526,
                            44.9399150000958
                        ],
                        [
                            -71.5212969998699,
                            44.9406249999234
                        ],
                        [
                            -71.5198959999292,
                            44.9384879996993
                        ],
                        [
                            -71.5170680001085,
                            44.93911699983
                        ],
                        [
                            -71.5147900001087,
                            44.943694999879
                        ],
                        [
                            -71.5177950003797,
                            44.9449939998355
                        ],
                        [
                            -71.5167579995888,
                            44.9484170001902
                        ],
                        [
                            -71.519240999628,
                            44.9496199999525
                        ],
                        [
                            -71.5158570001386,
                            44.952758000224
                        ],
                        [
                            -71.515000999996,
                            44.958596999718
                        ],
                        [
                            -71.5163209997458,
                            44.9644779997312
                        ],
                        [
                            -71.5237089997639,
                            44.964277000246
                        ],
                        [
                            -71.5179550002133,
                            44.9676549998909
                        ],
                        [
                            -71.5229199995684,
                            44.9661559997705
                        ],
                        [
                            -71.5221970003935,
                            44.9694559998039
                        ],
                        [
                            -71.5255249998063,
                            44.9696239997258
                        ],
                        [
                            -71.5274470001539,
                            44.9731869997257
                        ],
                        [
                            -71.5311789997978,
                            44.9750149997765
                        ],
                        [
                            -71.5329710004491,
                            44.9798930000454
                        ],
                        [
                            -71.5371969998374,
                            44.9807319999392
                        ],
                        [
                            -71.5367549999715,
                            44.983898999834
                        ],
                        [
                            -71.5415160000105,
                            44.9847680000065
                        ],
                        [
                            -71.5386470001819,
                            44.9872560000687
                        ],
                        [
                            -71.5384329999217,
                            44.9897039998461
                        ],
                        [
                            -71.5331379999554,
                            44.9908570000481
                        ],
                        [
                            -71.5371519996316,
                            44.9932519996833
                        ],
                        [
                            -71.5304030000211,
                            44.9993640002263
                        ],
                        [
                            -71.5140360000733,
                            45.0043840001661
                        ],
                        [
                            -71.5087839997647,
                            45.0078440000313
                        ],
                        [
                            -71.5043700000555,
                            45.0082730002163
                        ],
                        [
                            -71.4984170003132,
                            45.0039439997183
                        ],
                        [
                            -71.4953430004452,
                            45.0046019999761
                        ],
                        [
                            -71.488280999942,
                            45.0007390002051
                        ],
                        [
                            -71.4864950000368,
                            45.0021310002079
                        ],
                        [
                            -71.4805520003403,
                            45.0023339999498
                        ],
                        [
                            -71.476347999795,
                            45.0094260002512
                        ],
                        [
                            -71.4650649999264,
                            45.0129179998914
                        ],
                        [
                            -71.5012009997529,
                            45.0133560002011
                        ],
                        [
                            -71.4995360002216,
                            45.0148710001019
                        ],
                        [
                            -71.5013969995715,
                            45.0175129999695
                        ],
                        [
                            -71.4995680000087,
                            45.0191009998648
                        ],
                        [
                            -71.500871999865,
                            45.0193480001917
                        ],
                        [
                            -71.4985589997051,
                            45.0202349998314
                        ],
                        [
                            -71.5000740003469,
                            45.0200879997622
                        ],
                        [
                            -71.5001570001876,
                            45.0234869998805
                        ],
                        [
                            -71.4971169997565,
                            45.0236020002578
                        ],
                        [
                            -71.5001399995709,
                            45.0260810000614
                        ],
                        [
                            -71.497977999922,
                            45.0265450002291
                        ],
                        [
                            -71.4986859999267,
                            45.0283040002838
                        ],
                        [
                            -71.4971589995893,
                            45.028636000312
                        ],
                        [
                            -71.4962740000327,
                            45.0317510001393
                        ],
                        [
                            -71.4941480003689,
                            45.0314929998536
                        ],
                        [
                            -71.4948129998175,
                            45.0330659999245
                        ],
                        [
                            -71.4925490000614,
                            45.0335480000221
                        ],
                        [
                            -71.490957000325,
                            45.0431690001172
                        ],
                        [
                            -71.4932560002412,
                            45.0457590002017
                        ],
                        [
                            -71.5031189999026,
                            45.0452039997637
                        ],
                        [
                            -71.5045149998204,
                            45.0460780000909
                        ],
                        [
                            -71.5031630002835,
                            45.0481100002521
                        ],
                        [
                            -71.5046139995546,
                            45.0470900002223
                        ],
                        [
                            -71.5064499996885,
                            45.0484820001643
                        ],
                        [
                            -71.504389000322,
                            45.0523750001391
                        ],
                        [
                            -71.4995909995749,
                            45.0524810000038
                        ],
                        [
                            -71.5001980001955,
                            45.0540719999199
                        ],
                        [
                            -71.4972039997952,
                            45.0558019998566
                        ],
                        [
                            -71.4990819997618,
                            45.0564330000215
                        ],
                        [
                            -71.4975629998204,
                            45.0574889999675
                        ],
                        [
                            -71.5006020004265,
                            45.0573240002105
                        ],
                        [
                            -71.5012679997001,
                            45.0587269998131
                        ],
                        [
                            -71.4992030001357,
                            45.058360000221
                        ],
                        [
                            -71.4971030004111,
                            45.0601160000801
                        ],
                        [
                            -71.4956670003103,
                            45.0647539998863
                        ],
                        [
                            -71.4980420003945,
                            45.0701870002522
                        ],
                        [
                            -71.4956699997851,
                            45.0688470002148
                        ],
                        [
                            -71.4944329998759,
                            45.0713089999424
                        ],
                        [
                            -71.4877579998853,
                            45.0713679997586
                        ],
                        [
                            -71.4893510003449,
                            45.0729940002967
                        ],
                        [
                            -71.4863689996401,
                            45.0756339997852
                        ],
                        [
                            -71.4872909999051,
                            45.0786729997876
                        ],
                        [
                            -71.4826360001714,
                            45.0790000001845
                        ],
                        [
                            -71.479970999659,
                            45.0816270000713
                        ],
                        [
                            -71.4811959998726,
                            45.0829060000503
                        ],
                        [
                            -71.4781260002026,
                            45.0843420001481
                        ],
                        [
                            -71.4769920002256,
                            45.082921999888
                        ],
                        [
                            -71.4751719999852,
                            45.0842380002191
                        ],
                        [
                            -71.4678869998992,
                            45.0836509997537
                        ],
                        [
                            -71.4643889997088,
                            45.093350000203
                        ],
                        [
                            -71.4583530001258,
                            45.0951589997022
                        ],
                        [
                            -71.459437999699,
                            45.0965499999656
                        ],
                        [
                            -71.45845199986,
                            45.0988260000035
                        ],
                        [
                            -71.4497650000084,
                            45.1042179997377
                        ],
                        [
                            -71.4510799997354,
                            45.105104999893
                        ],
                        [
                            -71.448610000115,
                            45.1082349999552
                        ],
                        [
                            -71.4505910000139,
                            45.1094119999469
                        ],
                        [
                            -71.448786999667,
                            45.1125669997204
                        ],
                        [
                            -71.4403340001672,
                            45.1136890001511
                        ],
                        [
                            -71.4386210000569,
                            45.1172690001623
                        ],
                        [
                            -71.4284000001953,
                            45.1236850001885
                        ],
                        [
                            -71.4290909995832,
                            45.1247280000823
                        ],
                        [
                            -71.4261099996016,
                            45.1294809997504
                        ],
                        [
                            -71.4324380001609,
                            45.1380189997791
                        ],
                        [
                            -71.4376219997992,
                            45.1418870002991
                        ],
                        [
                            -71.4354109997466,
                            45.1418830001936
                        ],
                        [
                            -71.4332700000141,
                            45.148314000033
                        ],
                        [
                            -71.4264009998548,
                            45.1526780001201
                        ],
                        [
                            -71.4257320002082,
                            45.157741000012
                        ],
                        [
                            -71.4234990004143,
                            45.160675000024
                        ],
                        [
                            -71.4234419996146,
                            45.1646419999522
                        ],
                        [
                            -71.42493600034,
                            45.1658390000824
                        ],
                        [
                            -71.4184549996198,
                            45.170530000076
                        ],
                        [
                            -71.4173750000695,
                            45.1748640002518
                        ],
                        [
                            -71.4141079998577,
                            45.178991000236
                        ],
                        [
                            -71.4157520003709,
                            45.1806870001948
                        ],
                        [
                            -71.4144230004003,
                            45.1847880000406
                        ],
                        [
                            -71.4069459997953,
                            45.1899509998325
                        ],
                        [
                            -71.4074270000193,
                            45.1915660002886
                        ],
                        [
                            -71.4042040001884,
                            45.1949699998989
                        ],
                        [
                            -71.4058639996969,
                            45.1977050002828
                        ],
                        [
                            -71.3999820000997,
                            45.2003289997068
                        ],
                        [
                            -71.3973480004478,
                            45.2035219999742
                        ],
                        [
                            -71.4031279999377,
                            45.2152970002306
                        ],
                        [
                            -71.4158179995949,
                            45.2183589996941
                        ],
                        [
                            -71.4174179997272,
                            45.2217470002688
                        ],
                        [
                            -71.4207450002135,
                            45.2238479998719
                        ],
                        [
                            -71.426399000205,
                            45.2251799999484
                        ],
                        [
                            -71.4405059996963,
                            45.2350070001757
                        ],
                        [
                            -71.4435119997922,
                            45.2347999998243
                        ],
                        [
                            -71.4428180000313,
                            45.238291999953
                        ],
                        [
                            -71.4342220004163,
                            45.2384150000008
                        ],
                        [
                            -71.4296329999065,
                            45.2359690000336
                        ],
                        [
                            -71.4291269995682,
                            45.2339609999874
                        ],
                        [
                            -71.4210509996369,
                            45.232203000002
                        ],
                        [
                            -71.4168210000506,
                            45.235780999732
                        ],
                        [
                            -71.4130049998428,
                            45.2358409998016
                        ],
                        [
                            -71.4119219999194,
                            45.2387249997525
                        ],
                        [
                            -71.4085159997904,
                            45.2392920001015
                        ],
                        [
                            -71.4074460002858,
                            45.2412169997652
                        ],
                        [
                            -71.4019899997627,
                            45.2425690001214
                        ],
                        [
                            -71.3957619996609,
                            45.2415519998179
                        ],
                        [
                            -71.3892630002957,
                            45.2349540001514
                        ],
                        [
                            -71.3841370003838,
                            45.2335090001152
                        ],
                        [
                            -71.378341000102,
                            45.2434919998075
                        ],
                        [
                            -71.3627719996723,
                            45.2483020002619
                        ],
                        [
                            -71.3565390004459,
                            45.2538969999335
                        ],
                        [
                            -71.3593339997562,
                            45.2620940000311
                        ],
                        [
                            -71.3632129997133,
                            45.2667379998331
                        ],
                        [
                            -71.3602779997625,
                            45.2703319997994
                        ],
                        [
                            -71.3534549996339,
                            45.268605000201
                        ],
                        [
                            -71.3476819998166,
                            45.2720160002152
                        ],
                        [
                            -71.3423469996674,
                            45.271259000211
                        ],
                        [
                            -71.3364120003668,
                            45.2727419997016
                        ],
                        [
                            -71.3308019998579,
                            45.2802789997553
                        ],
                        [
                            -71.3208329998913,
                            45.2819199997396
                        ],
                        [
                            -71.3151949997933,
                            45.2866480003085
                        ],
                        [
                            -71.3075880003904,
                            45.2874850002717
                        ],
                        [
                            -71.3005300000851,
                            45.2966489998748
                        ],
                        [
                            -71.2962340003766,
                            45.2991439997402
                        ],
                        [
                            -71.2909669999994,
                            45.2987109999594
                        ],
                        [
                            -71.2879130002229,
                            45.3009079998489
                        ],
                        [
                            -71.2857930004069,
                            45.3003630002071
                        ],
                        [
                            -71.2842240002367,
                            45.302358999964
                        ],
                        [
                            -71.2805169998089,
                            45.2945879997201
                        ],
                        [
                            -71.2726879997497,
                            45.2965719999911
                        ],
                        [
                            -71.2649480002774,
                            45.2937919999131
                        ],
                        [
                            -71.2661769997906,
                            45.2914410001451
                        ],
                        [
                            -71.2629749998762,
                            45.277432000244
                        ],
                        [
                            -71.2596270003718,
                            45.2745740000153
                        ],
                        [
                            -71.2593920001952,
                            45.2720330002189
                        ],
                        [
                            -71.2564050003659,
                            45.2731959999026
                        ],
                        [
                            -71.2494700000842,
                            45.2680200002335
                        ],
                        [
                            -71.2456150004167,
                            45.2687989997355
                        ],
                        [
                            -71.2391870002983,
                            45.2633350002556
                        ],
                        [
                            -71.2394199999268,
                            45.2616669999119
                        ],
                        [
                            -71.2355910001985,
                            45.2609070000682
                        ],
                        [
                            -71.2313420003457,
                            45.2539129998736
                        ],
                        [
                            -71.2331099998092,
                            45.2515220000517
                        ],
                        [
                            -71.2306140002495,
                            45.2494170000029
                        ],
                        [
                            -71.2282609999067,
                            45.2493790002126
                        ],
                        [
                            -71.222103000125,
                            45.2533629998997
                        ],
                        [
                            -71.2206279996662,
                            45.2507349998972
                        ],
                        [
                            -71.2126570002151,
                            45.2501190002103
                        ],
                        [
                            -71.2025159998211,
                            45.2543240003078
                        ],
                        [
                            -71.1964290001846,
                            45.2540520001518
                        ],
                        [
                            -71.1951370000238,
                            45.2512659998096
                        ],
                        [
                            -71.1911850002719,
                            45.2494039996947
                        ],
                        [
                            -71.1910310002861,
                            45.2478810002079
                        ],
                        [
                            -71.1877330001121,
                            45.2476180000013
                        ],
                        [
                            -71.1868870000152,
                            45.2443869999989
                        ],
                        [
                            -71.1839649995849,
                            45.244941000071
                        ],
                        [
                            -71.182396000313,
                            45.2406349998012
                        ],
                        [
                            -71.1801009996965,
                            45.2399370002157
                        ],
                        [
                            -71.1732030001231,
                            45.2463150001298
                        ],
                        [
                            -71.1688220000258,
                            45.2462719998644
                        ],
                        [
                            -71.1623310001582,
                            45.2503460001659
                        ],
                        [
                            -71.1485700002733,
                            45.242870000044
                        ],
                        [
                            -71.1475600001448,
                            45.2394349996876
                        ],
                        [
                            -71.1435420002706,
                            45.2425350001728
                        ],
                        [
                            -71.1329099996069,
                            45.2445120000625
                        ],
                        [
                            -71.1279440004269,
                            45.2536900001042
                        ],
                        [
                            -71.1241170003484,
                            45.2551379997292
                        ],
                        [
                            -71.1238980000653,
                            45.2583909998246
                        ],
                        [
                            -71.1195449995571,
                            45.2616199999721
                        ],
                        [
                            -71.1202009996832,
                            45.2651419999637
                        ],
                        [
                            -71.1164339998792,
                            45.2694399998795
                        ],
                        [
                            -71.1163100000306,
                            45.2740849999896
                        ],
                        [
                            -71.1102250000439,
                            45.2756330002368
                        ],
                        [
                            -71.1063650003535,
                            45.2800089999937
                        ],
                        [
                            -71.1054439999135,
                            45.282798999832
                        ],
                        [
                            -71.1091739999076,
                            45.282346999838
                        ],
                        [
                            -71.1109909997749,
                            45.2843789997899
                        ],
                        [
                            -71.1065550003243,
                            45.2910869997236
                        ],
                        [
                            -71.1041889995627,
                            45.2915170002759
                        ],
                        [
                            -71.105279999882,
                            45.2948749997196
                        ],
                        [
                            -71.0982519997139,
                            45.3002240002217
                        ],
                        [
                            -71.0982009996603,
                            45.3016729997081
                        ],
                        [
                            -71.0839279995893,
                            45.3054839999733
                        ],
                        [
                            -71.077173000131,
                            45.250048000244
                        ],
                        [
                            -71.0582469999761,
                            44.9905149997322
                        ],
                        [
                            -71.0431179997624,
                            44.8268910002919
                        ],
                        [
                            -71.0335239997145,
                            44.6976369997656
                        ],
                        [
                            -71.0057459998516,
                            44.2026750000274
                        ],
                        [
                            -70.993328000181,
                            43.9297930000125
                        ],
                        [
                            -70.9890399999701,
                            43.7901380000582
                        ],
                        [
                            -70.9834610003217,
                            43.7341769999253
                        ],
                        [
                            -70.9727740003047,
                            43.5705120002288
                        ],
                        [
                            -70.9698960002553,
                            43.5685540002723
                        ],
                        [
                            -70.9667119998842,
                            43.5694370001617
                        ],
                        [
                            -70.9628299995541,
                            43.5633939998711
                        ],
                        [
                            -70.9572529995555,
                            43.5613380000977
                        ],
                        [
                            -70.9549029995857,
                            43.5540450000849
                        ],
                        [
                            -70.9506319999915,
                            43.5513129998135
                        ],
                        [
                            -70.9536330000646,
                            43.5441669999888
                        ],
                        [
                            -70.9535040001931,
                            43.540603000069
                        ],
                        [
                            -70.9559129997142,
                            43.5420940000375
                        ],
                        [
                            -70.9587969996113,
                            43.5405989998916
                        ],
                        [
                            -70.9621540002348,
                            43.5410870000492
                        ],
                        [
                            -70.9614210001158,
                            43.5401939999212
                        ],
                        [
                            -70.9638020000477,
                            43.5393709999322
                        ],
                        [
                            -70.9624739999019,
                            43.5342049998297
                        ],
                        [
                            -70.9577589998938,
                            43.5314969998544
                        ],
                        [
                            -70.9567980001691,
                            43.5241040001286
                        ],
                        [
                            -70.9539440004092,
                            43.5222729999895
                        ],
                        [
                            -70.9567980001691,
                            43.5127820000159
                        ],
                        [
                            -70.9546609997364,
                            43.509302999966
                        ],
                        [
                            -70.957973000154,
                            43.5080370002483
                        ],
                        [
                            -70.9588429996421,
                            43.4993699996785
                        ],
                        [
                            -70.9643359999751,
                            43.4971420001785
                        ],
                        [
                            -70.9644280000366,
                            43.4934799998592
                        ],
                        [
                            -70.9682889995519,
                            43.4894519997477
                        ],
                        [
                            -70.9694180004044,
                            43.4856300002409
                        ],
                        [
                            -70.967526000194,
                            43.4809449998056
                        ],
                        [
                            -70.9721480003158,
                            43.4783279998567
                        ],
                        [
                            -70.9760399997932,
                            43.4784279998877
                        ],
                        [
                            -70.9703330000983,
                            43.4731329997651
                        ],
                        [
                            -70.9645800003726,
                            43.4733160002399
                        ],
                        [
                            -70.961115999619,
                            43.4691880003171
                        ],
                        [
                            -70.9603380001924,
                            43.4661829999976
                        ],
                        [
                            -70.9666259996704,
                            43.4512739998726
                        ],
                        [
                            -70.9658959999245,
                            43.4477939996915
                        ],
                        [
                            -70.9615359997435,
                            43.443180999816
                        ],
                        [
                            -70.9608729999447,
                            43.4388920001166
                        ],
                        [
                            -70.9630239997229,
                            43.4364889997129
                        ],
                        [
                            -70.9690200000211,
                            43.4343680002192
                        ],
                        [
                            -70.9685170000559,
                            43.4292800000114
                        ],
                        [
                            -70.9709890002244,
                            43.4256019997984
                        ],
                        [
                            -70.9756120001711,
                            43.4218869997034
                        ],
                        [
                            -70.9812729998353,
                            43.4208719999023
                        ],
                        [
                            -70.9868430001612,
                            43.4137839999875
                        ],
                        [
                            -70.9865080004254,
                            43.4039809997892
                        ],
                        [
                            -70.9822970002074,
                            43.3982819999671
                        ],
                        [
                            -70.9826629999053,
                            43.3945740000959
                        ],
                        [
                            -70.9873620000199,
                            43.393261000219
                        ],
                        [
                            -70.9878809998787,
                            43.389934999706
                        ],
                        [
                            -70.9854089997102,
                            43.3859219997895
                        ],
                        [
                            -70.9859429996376,
                            43.3800550001597
                        ],
                        [
                            -70.9824029996143,
                            43.3778659999038
                        ],
                        [
                            -70.9833030001379,
                            43.3761949997502
                        ],
                        [
                            -70.9810140002675,
                            43.373363999871
                        ],
                        [
                            -70.9810450002296,
                            43.370725000188
                        ],
                        [
                            -70.9749459999992,
                            43.364488999777
                        ],
                        [
                            -70.9748649998083,
                            43.3623019997303
                        ],
                        [
                            -70.9781610003325,
                            43.3586630000917
                        ],
                        [
                            -70.9720269999419,
                            43.3546339999185
                        ],
                        [
                            -70.9734610003929,
                            43.3498889998298
                        ],
                        [
                            -70.9725910000066,
                            43.34837800021
                        ],
                        [
                            -70.9709430001936,
                            43.3504989998357
                        ],
                        [
                            -70.9674490002011,
                            43.349239999869
                        ],
                        [
                            -70.9665029996468,
                            43.3478060003074
                        ],
                        [
                            -70.9687920004155,
                            43.3464100002673
                        ],
                        [
                            -70.9679529999913,
                            43.3445790001509
                        ],
                        [
                            -70.9643059998379,
                            43.3432209998401
                        ],
                        [
                            -70.9633900003191,
                            43.3409090001632
                        ],
                        [
                            -70.9594989997682,
                            43.3409780001962
                        ],
                        [
                            -70.9558519996148,
                            43.3339969996855
                        ],
                        [
                            -70.9525099999582,
                            43.3333560000669
                        ],
                        [
                            -70.9501449999198,
                            43.3357060001095
                        ],
                        [
                            -70.9400579999525,
                            43.3354470002257
                        ],
                        [
                            -70.9374950004457,
                            43.3375369999535
                        ],
                        [
                            -70.931177999757,
                            43.3365449998828
                        ],
                        [
                            -70.9339550004224,
                            43.3353629999378
                        ],
                        [
                            -70.9312389998564,
                            43.333326000018
                        ],
                        [
                            -70.931650999585,
                            43.3308609997001
                        ],
                        [
                            -70.9304000003305,
                            43.3291449996829
                        ],
                        [
                            -70.9242040000157,
                            43.3272989998219
                        ],
                        [
                            -70.9262190002497,
                            43.3259710003186
                        ],
                        [
                            -70.9229229997256,
                            43.3261620001347
                        ],
                        [
                            -70.9243870003138,
                            43.3238499998492
                        ],
                        [
                            -70.9191540002717,
                            43.3244450001577
                        ],
                        [
                            -70.9182539997481,
                            43.3230569999203
                        ],
                        [
                            -70.9203590003939,
                            43.321210000078
                        ],
                        [
                            -70.916804000302,
                            43.322071999884
                        ],
                        [
                            -70.9163609997129,
                            43.3196920001226
                        ],
                        [
                            -70.9114170002741,
                            43.3197230003129
                        ],
                        [
                            -70.9135539998086,
                            43.3181890003215
                        ],
                        [
                            -70.9117980000406,
                            43.3157330001601
                        ],
                        [
                            -70.9153999999882,
                            43.3154119999162
                        ],
                        [
                            -70.9112950000754,
                            43.3104380000572
                        ],
                        [
                            -70.9138130002747,
                            43.3094080002353
                        ],
                        [
                            -70.9126070003277,
                            43.3075839999396
                        ],
                        [
                            -70.9070370000017,
                            43.3046619999858
                        ],
                        [
                            -70.9037260003073,
                            43.3062870001414
                        ],
                        [
                            -70.9014370004369,
                            43.3028469998112
                        ],
                        [
                            -70.9020480003572,
                            43.3013590002317
                        ],
                        [
                            -70.8963409997639,
                            43.2985509997782
                        ],
                        [
                            -70.8977600001462,
                            43.296720000324
                        ],
                        [
                            -70.9039399996692,
                            43.2967810002681
                        ],
                        [
                            -70.907435000385,
                            43.2936459998734
                        ],
                        [
                            -70.9065640001737,
                            43.2920359999698
                        ],
                        [
                            -70.896371999726,
                            43.2923179997935
                        ],
                        [
                            -70.8993169997226,
                            43.2878869999345
                        ],
                        [
                            -70.8969509998592,
                            43.285489999937
                        ],
                        [
                            -70.8872000003509,
                            43.2827199998107
                        ],
                        [
                            -70.8835540000224,
                            43.2833770001696
                        ],
                        [
                            -70.8858429998928,
                            43.2808130000335
                        ],
                        [
                            -70.8826540003971,
                            43.2789129997163
                        ],
                        [
                            -70.8838129995901,
                            43.2771590000907
                        ],
                        [
                            -70.883096000263,
                            43.273031000308
                        ],
                        [
                            -70.8742910004106,
                            43.2718560001985
                        ],
                        [
                            -70.870065000124,
                            43.2661340002131
                        ],
                        [
                            -70.8628930000159,
                            43.2647989999878
                        ],
                        [
                            -70.8574599999573,
                            43.255812000019
                        ],
                        [
                            -70.8551099999875,
                            43.2550569999931
                        ],
                        [
                            -70.852088999823,
                            43.2567270002983
                        ],
                        [
                            -70.8428270000362,
                            43.2542249997925
                        ],
                        [
                            -70.8395759997179,
                            43.2513410003155
                        ],
                        [
                            -70.8410870001618,
                            43.2481290002733
                        ],
                        [
                            -70.8384099999539,
                            43.2472089999258
                        ],
                        [
                            -70.8379289997299,
                            43.2424379999633
                        ],
                        [
                            -70.8274309998588,
                            43.2433840001313
                        ],
                        [
                            -70.8178169997194,
                            43.2381350001759
                        ],
                        [
                            -70.8154819998183,
                            43.2335189999898
                        ],
                        [
                            -70.8172069996241,
                            43.2293459999841
                        ],
                        [
                            -70.8122630001854,
                            43.228674000263
                        ],
                        [
                            -70.8098210001541,
                            43.2258820000018
                        ],
                        [
                            -70.8135450003004,
                            43.2199690000072
                        ],
                        [
                            -70.8126139998146,
                            43.2177190002134
                        ],
                        [
                            -70.8169170000942,
                            43.2145299996835
                        ],
                        [
                            -70.8165659995666,
                            43.2113180002589
                        ],
                        [
                            -70.8206710003777,
                            43.1994919999146
                        ],
                        [
                            -70.8192820001325,
                            43.1929919999448
                        ],
                        [
                            -70.8282550002144,
                            43.1877889998515
                        ],
                        [
                            -70.8235689996202,
                            43.1742390000186
                        ],
                        [
                            -70.8277820003864,
                            43.1704320002037
                        ],
                        [
                            -70.8286540004226,
                            43.1598300000168
                        ],
                        [
                            -70.8336099995569,
                            43.1461059998015
                        ],
                        [
                            -70.8270800002295,
                            43.1273950001081
                        ],
                        [
                            -70.7838640003564,
                            43.1009590001538
                        ],
                        [
                            -70.7796880002985,
                            43.09624800019
                        ],
                        [
                            -70.7658920002534,
                            43.0924950001417
                        ],
                        [
                            -70.7564120000083,
                            43.0801770002731
                        ],
                        [
                            -70.7430329997148,
                            43.0779730002444
                        ],
                        [
                            -70.7379689997272,
                            43.0737280001796
                        ],
                        [
                            -70.7063100002574,
                            43.0753389998319
                        ],
                        [
                            -70.701196999866,
                            43.0452400000711
                        ],
                        [
                            -70.6307139995927,
                            42.9852859999178
                        ],
                        [
                            -70.6111520003015,
                            42.9789900001369
                        ],
                        [
                            -70.5875930001541,
                            42.9491880002418
                        ],
                        [
                            -70.5818549995987,
                            42.9383189998545
                        ],
                        [
                            -70.5750940002927,
                            42.9171259999352
                        ],
                        [
                            -70.5928789998996,
                            42.9130009999164
                        ],
                        [
                            -70.610659000382,
                            42.9125290001683
                        ],
                        [
                            -70.6551089997912,
                            42.9220989998882
                        ],
                        [
                            -70.6677769997071,
                            42.927463999711
                        ],
                        [
                            -70.6809330004178,
                            42.9368739998958
                        ],
                        [
                            -70.6905469996589,
                            42.9482389999991
                        ],
                        [
                            -70.6943949996538,
                            42.9567539999842
                        ],
                        [
                            -70.7003010004386,
                            42.9470890001756
                        ],
                        [
                            -70.7089930003131,
                            42.9381460000789
                        ],
                        [
                            -70.7260239998846,
                            42.9285239998791
                        ],
                        [
                            -70.7247540003635,
                            42.9176889999927
                        ],
                        [
                            -70.7273029996266,
                            42.8936169998783
                        ],
                        [
                            -70.7350050003624,
                            42.8746850002148
                        ],
                        [
                            -70.8202379998343,
                            42.8717869998011
                        ],
                        [
                            -70.8477179997714,
                            42.8608669997241
                        ],
                        [
                            -70.8856019998684,
                            42.8828339998974
                        ],
                        [
                            -70.9035329999634,
                            42.8867780003161
                        ],
                        [
                            -70.9146309998842,
                            42.8866479999702
                        ],
                        [
                            -70.9295940004165,
                            42.8850319999391
                        ],
                        [
                            -70.9670180002059,
                            42.8688000001669
                        ],
                        [
                            -71.0312010004073,
                            42.8590869998415
                        ],
                        [
                            -71.0449199995611,
                            42.8486669999432
                        ],
                        [
                            -71.054003999971,
                            42.8332849997371
                        ],
                        [
                            -71.0644159996284,
                            42.8062749997989
                        ],
                        [
                            -71.1328050000249,
                            42.8214759998798
                        ],
                        [
                            -71.1666469999582,
                            42.8088810002302
                        ],
                        [
                            -71.1861399996525,
                            42.790935000103
                        ],
                        [
                            -71.1817860002177,
                            42.7373410001663
                        ],
                        [
                            -71.2238299995806,
                            42.746483999792
                        ],
                        [
                            -71.2454260002708,
                            42.7425459997377
                        ],
                        [
                            -71.2678730001824,
                            42.7260260000064
                        ],
                        [
                            -71.2789490003619,
                            42.7113500001148
                        ],
                        [
                            -71.294636999719,
                            42.6970419997386
                        ],
                        [
                            -71.6965050003828,
                            42.7057220002706
                        ],
                        [
                            -72.4584119997375,
                            42.726933000272
                        ],
                        [
                            -72.4642249997376,
                            42.7373089997879
                        ],
                        [
                            -72.473366000049,
                            42.7462349997527
                        ],
                        [
                            -72.476952000103,
                            42.7607689999567
                        ],
                        [
                            -72.4853140002646,
                            42.7662780002447
                        ],
                        [
                            -72.4905470003066,
                            42.7727469996719
                        ],
                        [
                            -72.4977040003461,
                            42.7733649998692
                        ],
                        [
                            -72.4995809995895,
                            42.7688110000712
                        ],
                        [
                            -72.5083700004467,
                            42.7644009997816
                        ],
                        [
                            -72.5130700003863,
                            42.7640729999611
                        ],
                        [
                            -72.5154660003868,
                            42.7656290000705
                        ],
                        [
                            -72.5164730001422,
                            42.7691230003009
                        ],
                        [
                            -72.5092859999655,
                            42.7750129996991
                        ],
                        [
                            -72.5087369999695,
                            42.7795219998748
                        ],
                        [
                            -72.516258000057,
                            42.787971999856
                        ],
                        [
                            -72.5415129996979,
                            42.8070940000026
                        ],
                        [
                            -72.542429000115,
                            42.8156469997706
                        ],
                        [
                            -72.5465800000585,
                            42.8251990001113
                        ],
                        [
                            -72.5473580003834,
                            42.8395490001146
                        ],
                        [
                            -72.5570779999296,
                            42.8528630002901
                        ],
                        [
                            -72.5542700002005,
                            42.8606139997829
                        ],
                        [
                            -72.5554150000481,
                            42.874667000301
                        ],
                        [
                            -72.5524240000208,
                            42.8852870001534
                        ],
                        [
                            -72.5407200002027,
                            42.8892849998774
                        ],
                        [
                            -72.5324800002399,
                            42.8963500000743
                        ],
                        [
                            -72.5303589997006,
                            42.9110739998124
                        ],
                        [
                            -72.5244989998448,
                            42.9151480000374
                        ],
                        [
                            -72.5270780001434,
                            42.9283469997651
                        ],
                        [
                            -72.5269409998761,
                            42.941378000092
                        ],
                        [
                            -72.5285279995896,
                            42.9455050002367
                        ],
                        [
                            -72.5344329996513,
                            42.9493580002546
                        ],
                        [
                            -72.5320219995821,
                            42.9549430000509
                        ],
                        [
                            -72.5183650003526,
                            42.9631370001608
                        ],
                        [
                            -72.4921800000509,
                            42.9676759998706
                        ],
                        [
                            -72.4816360001491,
                            42.9739549998464
                        ],
                        [
                            -72.4763110000455,
                            42.9714980002652
                        ],
                        [
                            -72.4737169996783,
                            42.9721240002673
                        ],
                        [
                            -72.4616999998657,
                            42.982870999991
                        ],
                        [
                            -72.4654159996161,
                            42.9896329997664
                        ],
                        [
                            -72.4625159998254,
                            42.9974450001418
                        ],
                        [
                            -72.4587020001657,
                            43.0008630002119
                        ],
                        [
                            -72.4494090004167,
                            43.0003220002809
                        ],
                        [
                            -72.4450450000378,
                            43.0038850002883
                        ],
                        [
                            -72.4432590001327,
                            43.0075769998227
                        ],
                        [
                            -72.4450600001064,
                            43.0110939997024
                        ],
                        [
                            -72.4580760001768,
                            43.0183039999518
                        ],
                        [
                            -72.4626229999555,
                            43.0257880000875
                        ],
                        [
                            -72.4603800001158,
                            43.0413830002444
                        ],
                        [
                            -72.4662249999031,
                            43.0475699998672
                        ],
                        [
                            -72.4678419997538,
                            43.052749999738
                        ],
                        [
                            -72.4503700001414,
                            43.0684359997507
                        ],
                        [
                            -72.4443429998809,
                            43.0716180001249
                        ],
                        [
                            -72.4339820002772,
                            43.0844500000167
                        ],
                        [
                            -72.438375999895,
                            43.0908359996985
                        ],
                        [
                            -72.4432139999268,
                            43.1027600000897
                        ],
                        [
                            -72.441412999953,
                            43.1059489998511
                        ],
                        [
                            -72.4334779995887,
                            43.1105879998388
                        ],
                        [
                            -72.4328220003609,
                            43.1202319999869
                        ],
                        [
                            -72.442298000408,
                            43.1293029999771
                        ],
                        [
                            -72.4408029998577,
                            43.1366269999657
                        ],
                        [
                            -72.4520949999472,
                            43.1385799998656
                        ],
                        [
                            -72.4574049999821,
                            43.1475370002019
                        ],
                        [
                            -72.4521100000158,
                            43.1532279998082
                        ],
                        [
                            -72.4527810002105,
                            43.1727670001797
                        ],
                        [
                            -72.4439310001523,
                            43.1797780000139
                        ],
                        [
                            -72.4442209996822,
                            43.1826470000182
                        ],
                        [
                            -72.4495309997171,
                            43.1890020002917
                        ],
                        [
                            -72.4505080002336,
                            43.192610999703
                        ],
                        [
                            -72.438223999559,
                            43.2029180002788
                        ],
                        [
                            -72.4410470002551,
                            43.2181919999027
                        ],
                        [
                            -72.434164999677,
                            43.2319090002851
                        ],
                        [
                            -72.4394749997119,
                            43.2441390002497
                        ],
                        [
                            -72.438772999555,
                            43.2519589998767
                        ],
                        [
                            -72.4364540004457,
                            43.2569489999794
                        ],
                        [
                            -72.4215610002336,
                            43.2633730000168
                        ],
                        [
                            -72.4160369999384,
                            43.2702310002274
                        ],
                        [
                            -72.4088909997696,
                            43.281966999781
                        ],
                        [
                            -72.4027920004375,
                            43.3024420000981
                        ],
                        [
                            -72.3953149998326,
                            43.3126959997228
                        ],
                        [
                            -72.3972229999364,
                            43.3170069999926
                        ],
                        [
                            -72.4026400001015,
                            43.3204699999261
                        ],
                        [
                            -72.410558999674,
                            43.3311209999484
                        ],
                        [
                            -72.4007010000355,
                            43.3460439999912
                        ],
                        [
                            -72.3898680004286,
                            43.3565720000319
                        ],
                        [
                            -72.3922329995687,
                            43.3579300001411
                        ],
                        [
                            -72.4030819999674,
                            43.3577470000686
                        ],
                        [
                            -72.4126490002511,
                            43.3622790001882
                        ],
                        [
                            -72.4152279996514,
                            43.365605000278
                        ],
                        [
                            -72.4160369999384,
                            43.3764540001109
                        ],
                        [
                            -72.4133970004387,
                            43.3834809999625
                        ],
                        [
                            -72.4035089997646,
                            43.3915830002666
                        ],
                        [
                            -72.3999389996041,
                            43.4151350002571
                        ],
                        [
                            -72.3962759995572,
                            43.4269369997841
                        ],
                        [
                            -72.395816000148,
                            43.437489999802
                        ],
                        [
                            -72.3903710003938,
                            43.4509090002876
                        ],
                        [
                            -72.3925690000276,
                            43.4668839999829
                        ],
                        [
                            -72.3825430001596,
                            43.4759100000918
                        ],
                        [
                            -72.3799949998232,
                            43.4900320003228
                        ],
                        [
                            -72.3845270004315,
                            43.5000180000809
                        ],
                        [
                            -72.3972990001044,
                            43.5089979998611
                        ],
                        [
                            -72.3988709997494,
                            43.5126530002818
                        ],
                        [
                            -72.3942620000464,
                            43.5273999999349
                        ],
                        [
                            -72.3904170004246,
                            43.527560000167
                        ],
                        [
                            -72.3876549998279,
                            43.5296349997411
                        ],
                        [
                            -72.38069699998,
                            43.540355000285
                        ],
                        [
                            -72.3807120000486,
                            43.5507079998008
                        ],
                        [
                            -72.3829859998504,
                            43.5634180003178
                        ],
                        [
                            -72.3813380000375,
                            43.5656609997291
                        ],
                        [
                            -72.3801470001591,
                            43.5649739998716
                        ],
                        [
                            -72.3798269995937,
                            43.5741680002881
                        ],
                        [
                            -72.3708240002729,
                            43.5807419999563
                        ],
                        [
                            -72.3496440004077,
                            43.5878169997977
                        ],
                        [
                            -72.32989899992,
                            43.6002139999586
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 43.6726907,
            "name": "New Hampshire",
            "intptlon": -71.5843145,
            "geo_point_2d": [
                43.6802448752,
                -71.5712354907
            ],
            "geoid": "33",
            "mtfcc": "G4000",
            "region": 1
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -71.5712354907,
                43.6802448752
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "c620ba889bfc1e9eee7602be5ca095179ff7967a",
        "fields": {
            "arealand": "278176477",
            "objectid": 50,
            "basename": "Puerto Rico",
            "stusab": "PR",
            "statens": "01779808",
            "centlon": -66.4147614,
            "state": "72",
            "gid": 16,
            "centlat": 18.2164715,
            "division": 0,
            "areawater": "628200285",
            "oid": "303146031",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                -67.2079369999424,
                                18.4422799998226
                            ],
                            [
                                -67.2171309999572,
                                18.4614119996632
                            ],
                            [
                                -67.2213420001752,
                                18.4761569997703
                            ],
                            [
                                -67.2227199996514,
                                18.4898189997092
                            ],
                            [
                                -67.2216259998574,
                                18.4979959999405
                            ],
                            [
                                -67.2164940000977,
                                18.4979959999405
                            ],
                            [
                                -67.2098169995589,
                                18.5110400001029
                            ],
                            [
                                -67.2000250000426,
                                18.5232759996637
                            ],
                            [
                                -67.1686000000263,
                                18.5484669996766
                            ],
                            [
                                -67.1604670001935,
                                18.5525430002406
                            ],
                            [
                                -67.1391619997565,
                                18.5589489998181
                            ],
                            [
                                -67.1048360001246,
                                18.5665880002917
                            ],
                            [
                                -67.0882649999623,
                                18.5680020000119
                            ],
                            [
                                -67.0697609995818,
                                18.5672770001002
                            ],
                            [
                                -67.0170939997759,
                                18.5626449996697
                            ],
                            [
                                -66.9996229999883,
                                18.5584360003656
                            ],
                            [
                                -66.9996229999883,
                                18.5561960003373
                            ],
                            [
                                -66.9686559997314,
                                18.5429920000961
                            ],
                            [
                                -66.9514860002427,
                                18.5400089997689
                            ],
                            [
                                -66.9376740003041,
                                18.5400479997021
                            ],
                            [
                                -66.9081020001401,
                                18.533297000049
                            ],
                            [
                                -66.9020859997503,
                                18.5352380001215
                            ],
                            [
                                -66.9021069996667,
                                18.5384160001964
                            ],
                            [
                                -66.8838740003461,
                                18.541664000025
                            ],
                            [
                                -66.8511749997124,
                                18.5425169998855
                            ],
                            [
                                -66.8366930003023,
                                18.5402059999736
                            ],
                            [
                                -66.8229590001815,
                                18.5431040001739
                            ],
                            [
                                -66.8042189997994,
                                18.5437839997387
                            ],
                            [
                                -66.7704439998132,
                                18.5400280002179
                            ],
                            [
                                -66.7346059999125,
                                18.5260120004014
                            ],
                            [
                                -66.7054260002839,
                                18.5326139998317
                            ],
                            [
                                -66.6995010001308,
                                18.5357369997746
                            ],
                            [
                                -66.6879909995831,
                                18.5383300003839
                            ],
                            [
                                -66.6603580003834,
                                18.5397989999101
                            ],
                            [
                                -66.6347610004358,
                                18.5439920000769
                            ],
                            [
                                -66.6022159997878,
                                18.5435719996739
                            ],
                            [
                                -66.5870849999243,
                                18.5399530000021
                            ],
                            [
                                -66.5775519999758,
                                18.5415169997865
                            ],
                            [
                                -66.5566010004394,
                                18.5402539997037
                            ],
                            [
                                -66.5331250001328,
                                18.5354739999905
                            ],
                            [
                                -66.5151520002049,
                                18.5337520001217
                            ],
                            [
                                -66.495011999707,
                                18.5274669998592
                            ],
                            [
                                -66.4785610000936,
                                18.5244199998184
                            ],
                            [
                                -66.4682359995766,
                                18.5323820000074
                            ],
                            [
                                -66.4529509997273,
                                18.5389600003442
                            ],
                            [
                                -66.432985000205,
                                18.5402190002212
                            ],
                            [
                                -66.4196079995613,
                                18.5432779997993
                            ],
                            [
                                -66.3996489997117,
                                18.5437610002385
                            ],
                            [
                                -66.3663049997208,
                                18.5399050001874
                            ],
                            [
                                -66.3429940001689,
                                18.5408610003439
                            ],
                            [
                                -66.3024599996282,
                                18.5282799998785
                            ],
                            [
                                -66.2862040000085,
                                18.5273949999823
                            ],
                            [
                                -66.2711230003737,
                                18.5297090000884
                            ],
                            [
                                -66.2600709995854,
                                18.5294429996929
                            ],
                            [
                                -66.2355330001701,
                                18.523704000421
                            ],
                            [
                                -66.2254789998147,
                                18.5239459999294
                            ],
                            [
                                -66.2121099995669,
                                18.5218440002163
                            ],
                            [
                                -66.1940699996918,
                                18.5265119997002
                            ],
                            [
                                -66.1293469997153,
                                18.5245129996747
                            ],
                            [
                                -66.1076449996182,
                                18.519209000364
                            ],
                            [
                                -66.0896850001091,
                                18.519916999787
                            ],
                            [
                                -66.0748599996691,
                                18.5176170003377
                            ],
                            [
                                -66.045984999639,
                                18.5176649995963
                            ],
                            [
                                -66.021545000133,
                                18.5117170001223
                            ],
                            [
                                -65.9934009996738,
                                18.5165979997314
                            ],
                            [
                                -65.9654489997336,
                                18.5101520002547
                            ],
                            [
                                -65.9481369999547,
                                18.5026469999123
                            ],
                            [
                                -65.9325869997915,
                                18.5010430003028
                            ],
                            [
                                -65.916105000216,
                                18.5051720001383
                            ],
                            [
                                -65.9079559995914,
                                18.505372999955
                            ],
                            [
                                -65.8901309998016,
                                18.5032860002193
                            ],
                            [
                                -65.8769940002557,
                                18.4995499996623
                            ],
                            [
                                -65.8577190001213,
                                18.4872490002407
                            ],
                            [
                                -65.8506309996788,
                                18.4880690001212
                            ],
                            [
                                -65.832697000109,
                                18.4862029999679
                            ],
                            [
                                -65.8053130004313,
                                18.4782529999618
                            ],
                            [
                                -65.77731899976,
                                18.4766039997078
                            ],
                            [
                                -65.7535789998627,
                                18.4720040001662
                            ],
                            [
                                -65.7496070000193,
                                18.4695100002083
                            ],
                            [
                                -65.7496070000193,
                                18.464699000133
                            ],
                            [
                                -65.7433060001225,
                                18.4638039997637
                            ],
                            [
                                -65.723663999567,
                                18.4544489998926
                            ],
                            [
                                -65.7158110001167,
                                18.4472209999783
                            ],
                            [
                                -65.7098130001686,
                                18.4384309997898
                            ],
                            [
                                -65.6913759997353,
                                18.430739999622
                            ],
                            [
                                -65.6786119995599,
                                18.4185330000351
                            ],
                            [
                                -65.6663489997001,
                                18.4261649996389
                            ],
                            [
                                -65.6579149995685,
                                18.4289049996164
                            ],
                            [
                                -65.6460829997038,
                                18.4422200003501
                            ],
                            [
                                -65.6351420001419,
                                18.448151999868
                            ],
                            [
                                -65.6246059997377,
                                18.4512599998916
                            ],
                            [
                                -65.5866799998079,
                                18.4433330000645
                            ],
                            [
                                -65.5485990000676,
                                18.4286530003192
                            ],
                            [
                                -65.4925040003914,
                                18.4036850000438
                            ],
                            [
                                -65.4721039996025,
                                18.3981590000376
                            ],
                            [
                                -65.4639780003408,
                                18.3934800002609
                            ],
                            [
                                -65.4449390002079,
                                18.3938499996368
                            ],
                            [
                                -65.4243329995548,
                                18.3908669998849
                            ],
                            [
                                -65.4186080003164,
                                18.4012100003232
                            ],
                            [
                                -65.4082429996164,
                                18.4112619999038
                            ],
                            [
                                -65.3929040002388,
                                18.4189460001677
                            ],
                            [
                                -65.3766579997665,
                                18.4214829999298
                            ],
                            [
                                -65.3563850000975,
                                18.4174880001751
                            ],
                            [
                                -65.3396080000709,
                                18.4089649996534
                            ],
                            [
                                -65.32262500018,
                                18.4032950003927
                            ],
                            [
                                -65.3112290003334,
                                18.3954889999347
                            ],
                            [
                                -65.3029209997002,
                                18.3875020002837
                            ],
                            [
                                -65.2863749996523,
                                18.387340000295
                            ],
                            [
                                -65.2755989999469,
                                18.3905549998639
                            ],
                            [
                                -65.2607660000092,
                                18.3923420002326
                            ],
                            [
                                -65.2442030002428,
                                18.3974949995931
                            ],
                            [
                                -65.2294659996663,
                                18.3970429999367
                            ],
                            [
                                -65.2209429998462,
                                18.3952720003856
                            ],
                            [
                                -65.2084840001678,
                                18.3893709997814
                            ],
                            [
                                -65.1951390002094,
                                18.3776770001755
                            ],
                            [
                                -65.1856189997814,
                                18.3643860001496
                            ],
                            [
                                -65.1716310001157,
                                18.3334400002125
                            ],
                            [
                                -65.168502999821,
                                18.3133469996941
                            ],
                            [
                                -65.1688250000363,
                                18.3035160003013
                            ],
                            [
                                -65.1754039997675,
                                18.2866830001176
                            ],
                            [
                                -65.1848560004234,
                                18.2720449996083
                            ],
                            [
                                -65.1911339998557,
                                18.258883999815
                            ],
                            [
                                -65.1994640002302,
                                18.2490359997988
                            ],
                            [
                                -65.2054320000411,
                                18.2447019995758
                            ],
                            [
                                -65.2275750001791,
                                18.2357699999292
                            ],
                            [
                                -65.2430290000828,
                                18.2355129999648
                            ],
                            [
                                -65.2529260000794,
                                18.2380220000598
                            ],
                            [
                                -65.2629860002826,
                                18.2310160001045
                            ],
                            [
                                -65.2866800001491,
                                18.2259899996004
                            ],
                            [
                                -65.3066530002424,
                                18.2300799998105
                            ],
                            [
                                -65.3155779997454,
                                18.2344910003153
                            ],
                            [
                                -65.3259359998744,
                                18.2446009996154
                            ],
                            [
                                -65.341214999876,
                                18.2451320003808
                            ],
                            [
                                -65.3603989997737,
                                18.2534359996168
                            ],
                            [
                                -65.3780510002096,
                                18.267273999869
                            ],
                            [
                                -65.4039090002731,
                                18.2764329998361
                            ],
                            [
                                -65.4237729996881,
                                18.2913770003997
                            ],
                            [
                                -65.4377510002063,
                                18.290344999685
                            ],
                            [
                                -65.4615399996091,
                                18.2932990001668
                            ],
                            [
                                -65.4577240002996,
                                18.2831849998515
                            ],
                            [
                                -65.4570360003865,
                                18.26813999984
                            ],
                            [
                                -65.4600490001551,
                                18.2541799998793
                            ],
                            [
                                -65.4653680004108,
                                18.2436769996266
                            ],
                            [
                                -65.4744300001811,
                                18.2333949999749
                            ],
                            [
                                -65.4893800002947,
                                18.2252990003682
                            ],
                            [
                                -65.4996070000041,
                                18.2221550000634
                            ],
                            [
                                -65.4996060001792,
                                18.2244559996884
                            ],
                            [
                                -65.5338399997497,
                                18.2183599999149
                            ],
                            [
                                -65.541734999931,
                                18.2103680003008
                            ],
                            [
                                -65.5546430000464,
                                18.1847669996625
                            ],
                            [
                                -65.5352690001778,
                                18.2012950002205
                            ],
                            [
                                -65.5242370003793,
                                18.2085139997867
                            ],
                            [
                                -65.510627999932,
                                18.2139089996517
                            ],
                            [
                                -65.4644250002295,
                                18.2222139999744
                            ],
                            [
                                -65.4487579998905,
                                18.2191509996397
                            ],
                            [
                                -65.4381010000107,
                                18.2141120002432
                            ],
                            [
                                -65.431503000013,
                                18.2086720002398
                            ],
                            [
                                -65.3947429998474,
                                18.2117420003728
                            ],
                            [
                                -65.3478630001024,
                                18.2059229995788
                            ],
                            [
                                -65.3287849996115,
                                18.1999300002158
                            ],
                            [
                                -65.2783160003377,
                                18.1979179997395
                            ],
                            [
                                -65.2719860001286,
                                18.1962440001335
                            ],
                            [
                                -65.25279300001,
                                18.1871649998504
                            ],
                            [
                                -65.2272449995679,
                                18.1658869998304
                            ],
                            [
                                -65.2186909997857,
                                18.1524430002192
                            ],
                            [
                                -65.2150889998382,
                                18.1362579996215
                            ],
                            [
                                -65.2178999999404,
                                18.1164160000728
                            ],
                            [
                                -65.2232469997852,
                                18.1052239995773
                            ],
                            [
                                -65.2362670000536,
                                18.0923750002841
                            ],
                            [
                                -65.2550450000705,
                                18.0824269999004
                            ],
                            [
                                -65.2723860001617,
                                18.0803960000496
                            ],
                            [
                                -65.2960690001576,
                                18.0717970002939
                            ],
                            [
                                -65.3122250002182,
                                18.0711419997976
                            ],
                            [
                                -65.3184330002286,
                                18.0671700001997
                            ],
                            [
                                -65.3339120002466,
                                18.062189000003
                            ],
                            [
                                -65.3505000001273,
                                18.0620370001041
                            ],
                            [
                                -65.3746009996997,
                                18.0555889996708
                            ],
                            [
                                -65.3909960001348,
                                18.0563889998691
                            ],
                            [
                                -65.4016000003112,
                                18.0489580000752
                            ],
                            [
                                -65.4192080003661,
                                18.0432210002826
                            ],
                            [
                                -65.4250449997574,
                                18.0428750002083
                            ],
                            [
                                -65.4322310001092,
                                18.0390170002345
                            ],
                            [
                                -65.4403010001927,
                                18.0371739999471
                            ],
                            [
                                -65.4479610001974,
                                18.0359209997493
                            ],
                            [
                                -65.4782260002975,
                                18.0368749997812
                            ],
                            [
                                -65.4958639995913,
                                18.042021000138
                            ],
                            [
                                -65.5077089998748,
                                18.0354219997523
                            ],
                            [
                                -65.5243279997176,
                                18.0297029999496
                            ],
                            [
                                -65.5401950000732,
                                18.0276410003724
                            ],
                            [
                                -65.5496989997094,
                                18.0284490000952
                            ],
                            [
                                -65.5886230000719,
                                18.0410810001859
                            ],
                            [
                                -65.5948029995949,
                                18.0450810001713
                            ],
                            [
                                -65.6105300002084,
                                18.0580790003155
                            ],
                            [
                                -65.6246040000878,
                                18.0774729995903
                            ],
                            [
                                -65.6293290001417,
                                18.0892539998178
                            ],
                            [
                                -65.630677000379,
                                18.0986680001503
                            ],
                            [
                                -65.6309729997567,
                                18.1230190002592
                            ],
                            [
                                -65.6285900001749,
                                18.1340760003818
                            ],
                            [
                                -65.6180499995727,
                                18.1541799998936
                            ],
                            [
                                -65.6665289996251,
                                18.1277379996371
                            ],
                            [
                                -65.6775360002076,
                                18.1260660002973
                            ],
                            [
                                -65.6894199999508,
                                18.1264039999324
                            ],
                            [
                                -65.7039220003507,
                                18.1123150001272
                            ],
                            [
                                -65.7160980001719,
                                18.1062659999553
                            ],
                            [
                                -65.7236650002902,
                                18.0850410000673
                            ],
                            [
                                -65.7318800001387,
                                18.0753389999334
                            ],
                            [
                                -65.7391119996229,
                                18.0707780001697
                            ],
                            [
                                -65.7400549998042,
                                18.0625440004235
                            ],
                            [
                                -65.7462190003319,
                                18.0506430003039
                            ],
                            [
                                -65.7496050003694,
                                18.038787999958
                            ],
                            [
                                -65.7610709996379,
                                18.0275259999877
                            ],
                            [
                                -65.7823819999227,
                                18.0189979996809
                            ],
                            [
                                -65.7822129998683,
                                18.0029769999931
                            ],
                            [
                                -65.7886130003976,
                                17.9894070001401
                            ],
                            [
                                -65.7976390001829,
                                17.976981999736
                            ],
                            [
                                -65.8109819995931,
                                17.9674529997967
                            ],
                            [
                                -65.8278480002065,
                                17.9624000002265
                            ],
                            [
                                -65.8345900001443,
                                17.954385000073
                            ],
                            [
                                -65.8525310002851,
                                17.9414860002699
                            ],
                            [
                                -65.8779340000639,
                                17.9376340001513
                            ],
                            [
                                -65.8993189999686,
                                17.9279859998621
                            ],
                            [
                                -65.9217469996136,
                                17.9226069999004
                            ],
                            [
                                -65.9446100003502,
                                17.9205710000535
                            ],
                            [
                                -65.9728819999576,
                                17.9232449997401
                            ],
                            [
                                -65.980916999881,
                                17.9140290003297
                            ],
                            [
                                -65.9996070000344,
                                17.9000979998204
                            ],
                            [
                                -66.008409000412,
                                17.8959509996106
                            ],
                            [
                                -66.0188910003896,
                                17.8941309998764
                            ],
                            [
                                -66.0417759999691,
                                17.8957729998176
                            ],
                            [
                                -66.0498000000219,
                                17.8977450003003
                            ],
                            [
                                -66.0767229995671,
                                17.9106710001388
                            ],
                            [
                                -66.1036879998434,
                                17.8892290003738
                            ],
                            [
                                -66.1223050002019,
                                17.8794650001516
                            ],
                            [
                                -66.1484970001762,
                                17.8779269996686
                            ],
                            [
                                -66.1671749997357,
                                17.8795060000584
                            ],
                            [
                                -66.1797110003054,
                                17.8825649996855
                            ],
                            [
                                -66.1936529999403,
                                17.8730409996966
                            ],
                            [
                                -66.2203010000242,
                                17.8628889998127
                            ],
                            [
                                -66.2433120000004,
                                17.8625650001466
                            ],
                            [
                                -66.2573680003365,
                                17.86559300025
                            ],
                            [
                                -66.2717769999517,
                                17.8713350002439
                            ],
                            [
                                -66.2828139997731,
                                17.8783780003181
                            ],
                            [
                                -66.3030930001881,
                                17.8821119997563
                            ],
                            [
                                -66.3164799999793,
                                17.8782410002021
                            ],
                            [
                                -66.3585090001719,
                                17.8763179997291
                            ],
                            [
                                -66.3663990003304,
                                17.8730249999443
                            ],
                            [
                                -66.3626469996967,
                                17.8730170000675
                            ],
                            [
                                -66.3702060003174,
                                17.870371999808
                            ],
                            [
                                -66.3881189999708,
                                17.8692169999452
                            ],
                            [
                                -66.4057699996834,
                                17.8719149997116
                            ],
                            [
                                -66.417393000209,
                                17.8766769996764
                            ],
                            [
                                -66.4244849999511,
                                17.8819920000117
                            ],
                            [
                                -66.4416660002088,
                                17.8715319997168
                            ],
                            [
                                -66.4617420002343,
                                17.8696700002687
                            ],
                            [
                                -66.4654060001061,
                                17.8641399996797
                            ],
                            [
                                -66.4763440001933,
                                17.8540480001661
                            ],
                            [
                                -66.4941520002647,
                                17.8461660003864
                            ],
                            [
                                -66.5042640003464,
                                17.8390420002515
                            ],
                            [
                                -66.5226499998279,
                                17.8328220000726
                            ],
                            [
                                -66.5381740000518,
                                17.8316759998376
                            ],
                            [
                                -66.5485609995948,
                                17.8332519996746
                            ],
                            [
                                -66.5642039996444,
                                17.8400949998521
                            ],
                            [
                                -66.5712970001098,
                                17.8454799998717
                            ],
                            [
                                -66.5830849995935,
                                17.8621550000394
                            ],
                            [
                                -66.5867979998691,
                                17.8747929997752
                            ],
                            [
                                -66.5857359998622,
                                17.8885030000317
                            ],
                            [
                                -66.5810469997933,
                                17.9021920002258
                            ],
                            [
                                -66.5740670002041,
                                17.9131399997734
                            ],
                            [
                                -66.5826229996361,
                                17.9098290001649
                            ],
                            [
                                -66.5916690004112,
                                17.9087190001411
                            ],
                            [
                                -66.6019880001822,
                                17.9102110003461
                            ],
                            [
                                -66.6142729997834,
                                17.9153089999194
                            ],
                            [
                                -66.6416730002529,
                                17.9119730002208
                            ],
                            [
                                -66.6593499999048,
                                17.901362000204
                            ],
                            [
                                -66.6692360000307,
                                17.8975889999941
                            ],
                            [
                                -66.6770650000898,
                                17.8967620003211
                            ],
                            [
                                -66.6933269995573,
                                17.9009890002404
                            ],
                            [
                                -66.7100659999491,
                                17.9095190003571
                            ],
                            [
                                -66.7133979995599,
                                17.9128519998398
                            ],
                            [
                                -66.7237769996053,
                                17.9120969996891
                            ],
                            [
                                -66.7385429995958,
                                17.9167969999814
                            ],
                            [
                                -66.7463289999972,
                                17.9154349997505
                            ],
                            [
                                -66.7618950000539,
                                17.9178810002
                            ],
                            [
                                -66.7730039998453,
                                17.9148419997098
                            ],
                            [
                                -66.7881539999754,
                                17.9160640000641
                            ],
                            [
                                -66.8050149996676,
                                17.9064789999932
                            ],
                            [
                                -66.8253099999762,
                                17.9004490000539
                            ],
                            [
                                -66.8432660001856,
                                17.8989509996821
                            ],
                            [
                                -66.853897999951,
                                17.8948790000107
                            ],
                            [
                                -66.8719980001005,
                                17.8914119999793
                            ],
                            [
                                -66.8814540000561,
                                17.8912990002428
                            ],
                            [
                                -66.8905610000323,
                                17.8937050003634
                            ],
                            [
                                -66.8975020001617,
                                17.8852050001555
                            ],
                            [
                                -66.9027319998307,
                                17.8819150000474
                            ],
                            [
                                -66.9128959997909,
                                17.876987999861
                            ],
                            [
                                -66.9209969998366,
                                17.8751759995802
                            ],
                            [
                                -66.9383279998821,
                                17.8784699999498
                            ],
                            [
                                -66.9540179997874,
                                17.87650399994
                            ],
                            [
                                -66.9769240001816,
                                17.8787330001395
                            ],
                            [
                                -67.0060209999695,
                                17.8857950002663
                            ],
                            [
                                -67.0199080002512,
                                17.8843540002487
                            ],
                            [
                                -67.0311879997468,
                                17.8871470001593
                            ],
                            [
                                -67.0440020001508,
                                17.8871599996726
                            ],
                            [
                                -67.0580540002889,
                                17.8919770001474
                            ],
                            [
                                -67.0781350003372,
                                17.8929049997746
                            ],
                            [
                                -67.086704000188,
                                17.8949399999935
                            ],
                            [
                                -67.0954210001768,
                                17.8939820002674
                            ],
                            [
                                -67.1210590001323,
                                17.8961790002756
                            ],
                            [
                                -67.1427899996434,
                                17.9005700002924
                            ],
                            [
                                -67.1561659995639,
                                17.8897159995857
                            ],
                            [
                                -67.1669210002512,
                                17.8845560001628
                            ],
                            [
                                -67.1819449999846,
                                17.8817850001763
                            ],
                            [
                                -67.1945040001205,
                                17.8817450001968
                            ],
                            [
                                -67.2105970004319,
                                17.8854160003653
                            ],
                            [
                                -67.2185160000044,
                                17.8890939996553
                            ],
                            [
                                -67.2334810001865,
                                17.8995120000116
                            ],
                            [
                                -67.2410370004342,
                                17.9103749997104
                            ],
                            [
                                -67.2496239998283,
                                17.9140380001414
                            ],
                            [
                                -67.2606799999163,
                                17.9285399998078
                            ],
                            [
                                -67.2641339997258,
                                17.9367880003506
                            ],
                            [
                                -67.2666390004046,
                                17.9498229997238
                            ],
                            [
                                -67.2658989997146,
                                17.9604829999934
                            ],
                            [
                                -67.2674939998241,
                                17.9830710001157
                            ],
                            [
                                -67.2648879997612,
                                17.9980179996256
                            ],
                            [
                                -67.2599150000102,
                                18.0131300002894
                            ],
                            [
                                -67.2613259999967,
                                18.0387010002746
                            ],
                            [
                                -67.259343000448,
                                18.0492370003675
                            ],
                            [
                                -67.2553649998585,
                                18.0576720003004
                            ],
                            [
                                -67.2606039997483,
                                18.0752129998421
                            ],
                            [
                                -67.2601710001033,
                                18.0848040003161
                            ],
                            [
                                -67.2573979996359,
                                18.0957469999538
                            ],
                            [
                                -67.2496249996532,
                                18.1101959998749
                            ],
                            [
                                -67.2463130001339,
                                18.1132619997777
                            ],
                            [
                                -67.2385569998697,
                                18.1163500000876
                            ],
                            [
                                -67.2353740002218,
                                18.120145000239
                            ],
                            [
                                -67.2354389996209,
                                18.1274920004178
                            ],
                            [
                                -67.2390160003524,
                                18.1418719998212
                            ],
                            [
                                -67.2362040004252,
                                18.1522189999401
                            ],
                            [
                                -67.2372520001885,
                                18.1676500001418
                            ],
                            [
                                -67.2358959995553,
                                18.1757030003481
                            ],
                            [
                                -67.2300330002248,
                                18.1902880000271
                            ],
                            [
                                -67.2142360001894,
                                18.2113489997134
                            ],
                            [
                                -67.2250610002987,
                                18.228646000033
                            ],
                            [
                                -67.2304650000449,
                                18.2465599996788
                            ],
                            [
                                -67.2474529999587,
                                18.2498939996364
                            ],
                            [
                                -67.2613329996693,
                                18.2560399998173
                            ],
                            [
                                -67.2697309998159,
                                18.2613909998515
                            ],
                            [
                                -67.281847000261,
                                18.2757789997255
                            ],
                            [
                                -67.2993859998207,
                                18.3090110002437
                            ],
                            [
                                -67.3066080001575,
                                18.3180870004002
                            ],
                            [
                                -67.3160420003718,
                                18.3342159997287
                            ],
                            [
                                -67.3220479998174,
                                18.3514149997025
                            ],
                            [
                                -67.3232539997645,
                                18.3638750003738
                            ],
                            [
                                -67.318693999567,
                                18.3810340000402
                            ],
                            [
                                -67.3141500001614,
                                18.388806000025
                            ],
                            [
                                -67.3027169996066,
                                18.4015590000073
                            ],
                            [
                                -67.2804920003511,
                                18.4120630002933
                            ],
                            [
                                -67.2496260003765,
                                18.4185720003376
                            ],
                            [
                                -67.2079369999424,
                                18.4422799998226
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -67.996679999702,
                                18.1350720003359
                            ],
                            [
                                -67.9986909997381,
                                18.1707759997996
                            ],
                            [
                                -67.9972420001168,
                                18.1798969998967
                            ],
                            [
                                -67.988597000098,
                                18.1914470003766
                            ],
                            [
                                -67.973662999878,
                                18.2036000004053
                            ],
                            [
                                -67.9559169997308,
                                18.2102279997035
                            ],
                            [
                                -67.9369410002455,
                                18.2093660000684
                            ],
                            [
                                -67.9272140001282,
                                18.2062679999573
                            ],
                            [
                                -67.9161699997359,
                                18.2001039998282
                            ],
                            [
                                -67.9087630003495,
                                18.1931140003112
                            ],
                            [
                                -67.901490999784,
                                18.1840640003739
                            ],
                            [
                                -67.8949329999692,
                                18.1666739998489
                            ],
                            [
                                -67.8855640000523,
                                18.1638429996844
                            ],
                            [
                                -67.874630000163,
                                18.1687820001823
                            ],
                            [
                                -67.8608209996992,
                                18.1708330002436
                            ],
                            [
                                -67.8524429996441,
                                18.170111000073
                            ],
                            [
                                -67.8344129998148,
                                18.1636529999146
                            ],
                            [
                                -67.8257519999025,
                                18.1577839999303
                            ],
                            [
                                -67.8112600004466,
                                18.1438849997833
                            ],
                            [
                                -67.7994560001711,
                                18.1280590000661
                            ],
                            [
                                -67.7919860001372,
                                18.1074580004132
                            ],
                            [
                                -67.7898100002447,
                                18.0903560003128
                            ],
                            [
                                -67.793558999607,
                                18.0671000002247
                            ],
                            [
                                -67.8028370001857,
                                18.0507389997364
                            ],
                            [
                                -67.822441000208,
                                18.0264310003987
                            ],
                            [
                                -67.8384790002679,
                                18.0138509999502
                            ],
                            [
                                -67.8625820003883,
                                18.0032250002162
                            ],
                            [
                                -67.874630000163,
                                17.9997700002298
                            ],
                            [
                                -67.874630000163,
                                18.0008259998054
                            ],
                            [
                                -67.8795719999519,
                                17.999457000389
                            ],
                            [
                                -67.8917810002835,
                                18.0005939998129
                            ],
                            [
                                -67.9008870004347,
                                17.9994800003771
                            ],
                            [
                                -67.9146869997794,
                                18.0029050001111
                            ],
                            [
                                -67.9229290002904,
                                18.0060799998605
                            ],
                            [
                                -67.9294190003331,
                                18.0112880002076
                            ],
                            [
                                -67.9458339999614,
                                18.0175360002228
                            ],
                            [
                                -67.9535569997153,
                                18.0226370000126
                            ],
                            [
                                -67.9761359998714,
                                18.0395929999554
                            ],
                            [
                                -67.9880050004443,
                                18.0522049998248
                            ],
                            [
                                -67.9934219997111,
                                18.0624069995836
                            ],
                            [
                                -67.9982959997279,
                                18.0837609996642
                            ],
                            [
                                -67.995910999598,
                                18.0949119997841
                            ],
                            [
                                -67.9869199999728,
                                18.1092979996121
                            ],
                            [
                                -67.9861480003941,
                                18.1191919999511
                            ],
                            [
                                -67.9951390000193,
                                18.1292060002831
                            ],
                            [
                                -67.996679999702,
                                18.1350720003359
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -67.5415810000661,
                                18.3730000002772
                            ],
                            [
                                -67.5430249996645,
                                18.3861229999212
                            ],
                            [
                                -67.5411209997586,
                                18.3964470002667
                            ],
                            [
                                -67.5374920000468,
                                18.4063369999871
                            ],
                            [
                                -67.5317839996286,
                                18.4144829998885
                            ],
                            [
                                -67.5239279998054,
                                18.4217580000437
                            ],
                            [
                                -67.5068660002717,
                                18.4324770001594
                            ],
                            [
                                -67.4918550000588,
                                18.4373050003203
                            ],
                            [
                                -67.481414999914,
                                18.4386149997769
                            ],
                            [
                                -67.4620319998246,
                                18.4357410001261
                            ],
                            [
                                -67.4516410000836,
                                18.4305849996961
                            ],
                            [
                                -67.434094999953,
                                18.4171640002035
                            ],
                            [
                                -67.4250299998096,
                                18.4068499996841
                            ],
                            [
                                -67.4212999998155,
                                18.3985089997989
                            ],
                            [
                                -67.4175730001945,
                                18.3801340000248
                            ],
                            [
                                -67.4180429996495,
                                18.3730000002772
                            ],
                            [
                                -67.4219729996601,
                                18.3617519998389
                            ],
                            [
                                -67.4315709999059,
                                18.347330000406
                            ],
                            [
                                -67.453998999551,
                                18.3312640003125
                            ],
                            [
                                -67.4721360004087,
                                18.3260879996359
                            ],
                            [
                                -67.4942610001051,
                                18.3278960002719
                            ],
                            [
                                -67.5135139995998,
                                18.337127999733
                            ],
                            [
                                -67.5235029996579,
                                18.344570000218
                            ],
                            [
                                -67.535988000174,
                                18.3582779999236
                            ],
                            [
                                -67.5415810000661,
                                18.3730000002772
                            ]
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 18.217648,
            "name": "Puerto Rico",
            "intptlon": -66.4107992,
            "geo_point_2d": [
                18.2157325628,
                -66.4146425123
            ],
            "geoid": "72",
            "mtfcc": "G4000",
            "region": 9
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -66.4146425123,
                18.2157325628
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "e02b02f616a6cbf8379ac749bb51092f7e769929",
        "fields": {
            "arealand": "472276664",
            "objectid": 36,
            "basename": "Commonwealth of the Northern Mariana Islands",
            "stusab": "MP",
            "statens": "01779809",
            "centlon": 145.596871,
            "state": "69",
            "gid": 23,
            "centlat": 16.7974379,
            "division": 0,
            "areawater": "349301029",
            "oid": "-1625647860",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                145.572602999898,
                                15.1250179997765
                            ],
                            [
                                145.64831400019,
                                15.1386679996744
                            ],
                            [
                                145.669295999689,
                                15.1619619998559
                            ],
                            [
                                145.686969999866,
                                15.1973650000025
                            ],
                            [
                                145.688443999602,
                                15.2362769996011
                            ],
                            [
                                145.691701999593,
                                15.2470150001869
                            ],
                            [
                                145.708511000305,
                                15.2752660002512
                            ],
                            [
                                145.73543399985,
                                15.3126549999157
                            ],
                            [
                                145.752061999913,
                                15.3270159999622
                            ],
                            [
                                145.781814999827,
                                15.3483500001218
                            ],
                            [
                                145.793020999703,
                                15.3546989995859
                            ],
                            [
                                145.802854000126,
                                15.3578339996743
                            ],
                            [
                                145.819069999562,
                                15.355422000018
                            ],
                            [
                                145.833546999848,
                                15.3552839998989
                            ],
                            [
                                145.85281500031,
                                15.3518899996698
                            ],
                            [
                                145.875977999724,
                                15.3344990001862
                            ],
                            [
                                145.896346999652,
                                15.3087310002767
                            ],
                            [
                                145.901244000133,
                                15.2948049999754
                            ],
                            [
                                145.907345000014,
                                15.2839029997785
                            ],
                            [
                                145.90936500027,
                                15.2740839997411
                            ],
                            [
                                145.905515999552,
                                15.2577749999213
                            ],
                            [
                                145.891886000087,
                                15.2199070003829
                            ],
                            [
                                145.882943000142,
                                15.1973530003738
                            ],
                            [
                                145.879850000008,
                                15.1932580000854
                            ],
                            [
                                145.880036999606,
                                15.1779449999623
                            ],
                            [
                                145.87500000028,
                                15.1502379998629
                            ],
                            [
                                145.87916299992,
                                15.1450959995667
                            ],
                            [
                                145.875983999571,
                                15.1354379999751
                            ],
                            [
                                145.864929000207,
                                15.1301900001309
                            ],
                            [
                                145.859758000089,
                                15.1237570003105
                            ],
                            [
                                145.84772000036,
                                15.1205499999419
                            ],
                            [
                                145.837413000284,
                                15.1200710002262
                            ],
                            [
                                145.838718999791,
                                15.115994999646
                            ],
                            [
                                145.833890999805,
                                15.1019369996071
                            ],
                            [
                                145.825800999629,
                                15.0923989999603
                            ],
                            [
                                145.802680999873,
                                15.0756190001801
                            ],
                            [
                                145.750070000144,
                                15.0666040000059
                            ],
                            [
                                145.742687000149,
                                15.0142149999914
                            ],
                            [
                                145.743067999915,
                                14.9999999999315
                            ],
                            [
                                145.746205000431,
                                14.9917359998619
                            ],
                            [
                                145.747746999938,
                                14.9712149998842
                            ],
                            [
                                145.730764000047,
                                14.9172960002881
                            ],
                            [
                                145.709323000066,
                                14.892748999681
                            ],
                            [
                                145.694674000252,
                                14.8731300003869
                            ],
                            [
                                145.683742000012,
                                14.8642290000101
                            ],
                            [
                                145.671216000387,
                                14.8599970004211
                            ],
                            [
                                145.653126000283,
                                14.8573660003075
                            ],
                            [
                                145.641369999688,
                                14.832333000396
                            ],
                            [
                                145.632693999707,
                                14.8190660002614
                            ],
                            [
                                145.616469000049,
                                14.8008420000486
                            ],
                            [
                                145.591717000374,
                                14.7814310001096
                            ],
                            [
                                145.562063000194,
                                14.7737970003273
                            ],
                            [
                                145.532624999924,
                                14.7726889999785
                            ],
                            [
                                145.507725999936,
                                14.779374999976
                            ],
                            [
                                145.499999999809,
                                14.787624999615
                            ],
                            [
                                145.493934000088,
                                14.7902299996269
                            ],
                            [
                                145.482681000357,
                                14.7992069999107
                            ],
                            [
                                145.472555999856,
                                14.8191450001166
                            ],
                            [
                                145.467848000419,
                                14.8370850000131
                            ],
                            [
                                145.468221999615,
                                14.8533449999837
                            ],
                            [
                                145.470195999841,
                                14.8648769999029
                            ],
                            [
                                145.474402000036,
                                14.8749999997743
                            ],
                            [
                                145.48935200015,
                                14.8986999998673
                            ],
                            [
                                145.502436999817,
                                14.9074209998054
                            ],
                            [
                                145.552239999817,
                                14.9327210003526
                            ],
                            [
                                145.562890999849,
                                14.9342839997312
                            ],
                            [
                                145.54101399985,
                                14.9495850003744
                            ],
                            [
                                145.524764999903,
                                14.9714549995695
                            ],
                            [
                                145.513219000269,
                                14.9999999999315
                            ],
                            [
                                145.513604000233,
                                15.0268289996891
                            ],
                            [
                                145.518316999693,
                                15.0562789995761
                            ],
                            [
                                145.53502199975,
                                15.0892140003424
                            ],
                            [
                                145.572602999898,
                                15.1250179997765
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                145.0644359997,
                                14.0830490004285
                            ],
                            [
                                145.059596000018,
                                14.0990670000212
                            ],
                            [
                                145.058052999787,
                                14.114402999847
                            ],
                            [
                                145.058965999831,
                                14.1250000001643
                            ],
                            [
                                145.063022000239,
                                14.1379010000061
                            ],
                            [
                                145.075239000068,
                                14.1591120001751
                            ],
                            [
                                145.091456000228,
                                14.1687380003972
                            ],
                            [
                                145.089365999651,
                                14.1790410002722
                            ],
                            [
                                145.091941999576,
                                14.193625999754
                            ],
                            [
                                145.103001000037,
                                14.2068449998467
                            ],
                            [
                                145.11430900002,
                                14.2169070000723
                            ],
                            [
                                145.128449000022,
                                14.2243370004251
                            ],
                            [
                                145.143090000339,
                                14.2313240004305
                            ],
                            [
                                145.156986999768,
                                14.2351539998856
                            ],
                            [
                                145.17832800019,
                                14.2350209998555
                            ],
                            [
                                145.201392999695,
                                14.2537510001663
                            ],
                            [
                                145.216789999697,
                                14.2619149998306
                            ],
                            [
                                145.235624999615,
                                14.2665970003441
                            ],
                            [
                                145.248946000182,
                                14.2669120001887
                            ],
                            [
                                145.263808000432,
                                14.2662040001693
                            ],
                            [
                                145.271060000008,
                                14.2623179996753
                            ],
                            [
                                145.276585000128,
                                14.2543060002535
                            ],
                            [
                                145.304527000023,
                                14.2562849996715
                            ],
                            [
                                145.319196999754,
                                14.2539229999456
                            ],
                            [
                                145.329760999747,
                                14.2493730000081
                            ],
                            [
                                145.351703000044,
                                14.244410000021
                            ],
                            [
                                145.364996000124,
                                14.2266090000606
                            ],
                            [
                                145.374479999668,
                                14.2008249998035
                            ],
                            [
                                145.372477000028,
                                14.1774940003858
                            ],
                            [
                                145.371413000371,
                                14.1701260003791
                            ],
                            [
                                145.34918200037,
                                14.1364089997714
                            ],
                            [
                                145.351487999959,
                                14.1250000001643
                            ],
                            [
                                145.349901000245,
                                14.1212519996837
                            ],
                            [
                                145.339641000025,
                                14.1082760003091
                            ],
                            [
                                145.325554999552,
                                14.0988990003261
                            ],
                            [
                                145.312047000285,
                                14.0941750003848
                            ],
                            [
                                145.299143000368,
                                14.0919430003804
                            ],
                            [
                                145.29576099963,
                                14.0844240002084
                            ],
                            [
                                145.284563999975,
                                14.0711089998701
                            ],
                            [
                                145.273924999639,
                                14.063994999627
                            ],
                            [
                                145.260742999887,
                                14.0586590004289
                            ],
                            [
                                145.233025000298,
                                14.0525310001757
                            ],
                            [
                                145.219560999616,
                                14.05598299985
                            ],
                            [
                                145.197250000147,
                                14.0427429998377
                            ],
                            [
                                145.177988000431,
                                14.0365649996548
                            ],
                            [
                                145.159732999573,
                                14.0430259996718
                            ],
                            [
                                145.151374999609,
                                14.0428459996207
                            ],
                            [
                                145.139447000383,
                                14.0391650003245
                            ],
                            [
                                145.13334300013,
                                14.0393449997817
                            ],
                            [
                                145.127325000091,
                                14.0441099996417
                            ],
                            [
                                145.096909000378,
                                14.0470520002184
                            ],
                            [
                                145.077746000396,
                                14.0604089999194
                            ],
                            [
                                145.065414000041,
                                14.0786269997818
                            ],
                            [
                                145.0644359997,
                                14.0830490004285
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                145.657528000297,
                                18.0188649995799
                            ],
                            [
                                145.656268999748,
                                18.024694000306
                            ],
                            [
                                145.64560400037,
                                18.0456959999744
                            ],
                            [
                                145.645677000165,
                                18.0527149998988
                            ],
                            [
                                145.643752000343,
                                18.0579480000883
                            ],
                            [
                                145.654748999981,
                                18.090939000264
                            ],
                            [
                                145.654487999865,
                                18.0976600002775
                            ],
                            [
                                145.660320999957,
                                18.1191990000052
                            ],
                            [
                                145.668456000338,
                                18.137208999856
                            ],
                            [
                                145.685486000085,
                                18.1536179999679
                            ],
                            [
                                145.684774999707,
                                18.1711159998363
                            ],
                            [
                                145.686876000155,
                                18.1791759999443
                            ],
                            [
                                145.691308000306,
                                18.1890479999657
                            ],
                            [
                                145.700030000317,
                                18.1997440002614
                            ],
                            [
                                145.739667999634,
                                18.2302229997429
                            ],
                            [
                                145.759522999726,
                                18.2410869998621
                            ],
                            [
                                145.794046999725,
                                18.243074000251
                            ],
                            [
                                145.806036999774,
                                18.2396029997823
                            ],
                            [
                                145.818070000378,
                                18.2396300003763
                            ],
                            [
                                145.84002400037,
                                18.2338310003522
                            ],
                            [
                                145.855991000285,
                                18.223203000219
                            ],
                            [
                                145.880286999851,
                                18.1863699999093
                            ],
                            [
                                145.890651999653,
                                18.1550659996199
                            ],
                            [
                                145.887684999915,
                                18.1479000003103
                            ],
                            [
                                145.887809999588,
                                18.1382329996325
                            ],
                            [
                                145.885186999807,
                                18.1320289996396
                            ],
                            [
                                145.883472999872,
                                18.1093320003296
                            ],
                            [
                                145.87500000028,
                                18.0865949997889
                            ],
                            [
                                145.864222999852,
                                18.0686260001856
                            ],
                            [
                                145.844237000238,
                                18.0455350003893
                            ],
                            [
                                145.809249999559,
                                18.0295159999461
                            ],
                            [
                                145.777863000076,
                                18.0034319998493
                            ],
                            [
                                145.744099999785,
                                17.9860400001067
                            ],
                            [
                                145.721616000063,
                                17.9860400001067
                            ],
                            [
                                145.695385999556,
                                17.9898359997954
                            ],
                            [
                                145.664021999639,
                                18.0092520002018
                            ],
                            [
                                145.657528000297,
                                18.0188649995799
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                145.576892999759,
                                18.72965099978
                            ],
                            [
                                145.563134000422,
                                18.7786870003317
                            ],
                            [
                                145.564075000055,
                                18.7924010000266
                            ],
                            [
                                145.575397000282,
                                18.8204290003661
                            ],
                            [
                                145.598097999738,
                                18.8568440001446
                            ],
                            [
                                145.607844999947,
                                18.8658580001001
                            ],
                            [
                                145.625000000265,
                                18.8749999996289
                            ],
                            [
                                145.646753000416,
                                18.8796109997646
                            ],
                            [
                                145.662183999855,
                                18.8809989997569
                            ],
                            [
                                145.676085000381,
                                18.8805469996577
                            ],
                            [
                                145.704192000131,
                                18.8749999996289
                            ],
                            [
                                145.709974000169,
                                18.8724029997523
                            ],
                            [
                                145.725720000151,
                                18.8598269997758
                            ],
                            [
                                145.749999999824,
                                18.8270949999442
                            ],
                            [
                                145.764922000348,
                                18.7955660000908
                            ],
                            [
                                145.768930000177,
                                18.7781309996748
                            ],
                            [
                                145.770660000005,
                                18.7607200003466
                            ],
                            [
                                145.769804999688,
                                18.7499999998604
                            ],
                            [
                                145.764329999796,
                                18.7316319998853
                            ],
                            [
                                145.736617000231,
                                18.6879049997643
                            ],
                            [
                                145.700284999687,
                                18.6642780002422
                            ],
                            [
                                145.684624000094,
                                18.6578400003359
                            ],
                            [
                                145.654435000162,
                                18.6604100001641
                            ],
                            [
                                145.625000000265,
                                18.6726640002821
                            ],
                            [
                                145.608609999853,
                                18.6822780002722
                            ],
                            [
                                145.592257000149,
                                18.7024829996087
                            ],
                            [
                                145.576892999759,
                                18.72965099978
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                145.575071999693,
                                16.3058150000228
                            ],
                            [
                                145.560083999945,
                                16.3412490002448
                            ],
                            [
                                145.558836000165,
                                16.374152000182
                            ],
                            [
                                145.571325999806,
                                16.4019929997344
                            ],
                            [
                                145.597557999963,
                                16.4235069998125
                            ],
                            [
                                145.625000000265,
                                16.4424990003316
                            ],
                            [
                                145.654610000064,
                                16.4510960000578
                            ],
                            [
                                145.675709999563,
                                16.4523480004123
                            ],
                            [
                                145.720754000073,
                                16.4482839996821
                            ],
                            [
                                145.749999999824,
                                16.4376519998975
                            ],
                            [
                                145.764785000081,
                                16.4223740002326
                            ],
                            [
                                145.770875999915,
                                16.4122800003485
                            ],
                            [
                                145.780121999809,
                                16.3835050004193
                            ],
                            [
                                145.782821999583,
                                16.3599169997862
                            ],
                            [
                                145.781869000254,
                                16.3558530001435
                            ],
                            [
                                145.767637000191,
                                16.3239649996877
                            ],
                            [
                                145.732905999605,
                                16.2933099995994
                            ],
                            [
                                145.724116999646,
                                16.288264000147
                            ],
                            [
                                145.702308000317,
                                16.2765760000789
                            ],
                            [
                                145.693194999595,
                                16.2739949998855
                            ],
                            [
                                145.6499090003,
                                16.2737650003357
                            ],
                            [
                                145.625000000265,
                                16.2805449996268
                            ],
                            [
                                145.603801999958,
                                16.289364000334
                            ],
                            [
                                145.575071999693,
                                16.3058150000228
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                145.314130000291,
                                19.6511210003669
                            ],
                            [
                                145.310417999841,
                                19.6691059998716
                            ],
                            [
                                145.304866999781,
                                19.6845260002037
                            ],
                            [
                                145.306333000019,
                                19.6984049996568
                            ],
                            [
                                145.315902999778,
                                19.7140369998074
                            ],
                            [
                                145.341186999731,
                                19.7407250002899
                            ],
                            [
                                145.359686999913,
                                19.7564130002973
                            ],
                            [
                                145.384969000217,
                                19.7641169997504
                            ],
                            [
                                145.396920999732,
                                19.7647080003214
                            ],
                            [
                                145.410149000413,
                                19.7631849998578
                            ],
                            [
                                145.424333999722,
                                19.7601530002409
                            ],
                            [
                                145.435277999657,
                                19.7556149995919
                            ],
                            [
                                145.472709999842,
                                19.7267299997983
                            ],
                            [
                                145.478813000271,
                                19.7156250002033
                            ],
                            [
                                145.489836999673,
                                19.7035810003943
                            ],
                            [
                                145.493200999969,
                                19.695472999584
                            ],
                            [
                                145.495622999909,
                                19.6799180001009
                            ],
                            [
                                145.490564999769,
                                19.6707019998394
                            ],
                            [
                                145.488405999595,
                                19.6569789996043
                            ],
                            [
                                145.467614999893,
                                19.6269869997744
                            ],
                            [
                                145.454977999939,
                                19.6048789997737
                            ],
                            [
                                145.427498000002,
                                19.5909589999997
                            ],
                            [
                                145.400016000415,
                                19.5884279998411
                            ],
                            [
                                145.369141000219,
                                19.5967079998536
                            ],
                            [
                                145.343059999675,
                                19.6076990003307
                            ],
                            [
                                145.33032700036,
                                19.6150009996994
                            ],
                            [
                                145.327738000016,
                                19.6249999997599
                            ],
                            [
                                145.314130000291,
                                19.6511210003669
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                145.748505999997,
                                17.5717170002996
                            ],
                            [
                                145.746782999841,
                                17.5768119999174
                            ],
                            [
                                145.746658999992,
                                17.599368999871
                            ],
                            [
                                145.749999999824,
                                17.6085579998479
                            ],
                            [
                                145.785435000217,
                                17.6685220000712
                            ],
                            [
                                145.800108000321,
                                17.6840879999322
                            ],
                            [
                                145.828117999987,
                                17.6908170002806
                            ],
                            [
                                145.856873000367,
                                17.6905460001984
                            ],
                            [
                                145.893934999758,
                                17.6803029997084
                            ],
                            [
                                145.910843000204,
                                17.66766099997
                            ],
                            [
                                145.917700999594,
                                17.6546320001692
                            ],
                            [
                                145.923241999608,
                                17.6250000002428
                            ],
                            [
                                145.924447999555,
                                17.5991220004223
                            ],
                            [
                                145.918333000329,
                                17.5672399997478
                            ],
                            [
                                145.909551999868,
                                17.5479590003045
                            ],
                            [
                                145.896662000195,
                                17.5335380003785
                            ],
                            [
                                145.87500000028,
                                17.5204729996499
                            ],
                            [
                                145.867655999745,
                                17.5177159999956
                            ],
                            [
                                145.83235599997,
                                17.5129160003495
                            ],
                            [
                                145.81779099982,
                                17.514456000302
                            ],
                            [
                                145.80113299962,
                                17.5205080003227
                            ],
                            [
                                145.776725999725,
                                17.5359669998537
                            ],
                            [
                                145.758838000186,
                                17.5519479996359
                            ],
                            [
                                145.749999999824,
                                17.5642590000999
                            ],
                            [
                                145.748505999997,
                                17.5717170002996
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                145.155196999665,
                                19.9775709996771
                            ],
                            [
                                145.142600999719,
                                19.9862549998587
                            ],
                            [
                                145.131885000288,
                                20.0395610001618
                            ],
                            [
                                145.136235000423,
                                20.0559880001104
                            ],
                            [
                                145.146165999857,
                                20.0763889998714
                            ],
                            [
                                145.16093500022,
                                20.0861590003208
                            ],
                            [
                                145.178652000055,
                                20.0965129996244
                            ],
                            [
                                145.203202999889,
                                20.1018050002123
                            ],
                            [
                                145.233608999556,
                                20.1026660000672
                            ],
                            [
                                145.265148000274,
                                20.0953630000909
                            ],
                            [
                                145.285568000256,
                                20.0819349997281
                            ],
                            [
                                145.299715999755,
                                20.0658359997242
                            ],
                            [
                                145.314964999619,
                                20.0329620002688
                            ],
                            [
                                145.31008599958,
                                20.0164929996968
                            ],
                            [
                                145.304151000279,
                                20.003582000096
                            ],
                            [
                                145.305682999741,
                                20.0000000001039
                            ],
                            [
                                145.302162999809,
                                19.9928120004165
                            ],
                            [
                                145.292542999822,
                                19.979302999764
                            ],
                            [
                                145.282238000295,
                                19.9687799998209
                            ],
                            [
                                145.2722190001,
                                19.9615230003312
                            ],
                            [
                                145.248099000261,
                                19.9480569998807
                            ],
                            [
                                145.200071000295,
                                19.9483300001247
                            ],
                            [
                                145.183121000016,
                                19.9519110001658
                            ],
                            [
                                145.172733999575,
                                19.9591479998851
                            ],
                            [
                                145.155196999665,
                                19.9775709996771
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                145.763378000292,
                                17.305854999877
                            ],
                            [
                                145.762389999905,
                                17.3246739995898
                            ],
                            [
                                145.764750999746,
                                17.3297490000666
                            ],
                            [
                                145.761307999807,
                                17.33630699969
                            ],
                            [
                                145.769059999873,
                                17.3527209996291
                            ],
                            [
                                145.780684000223,
                                17.3671899996516
                            ],
                            [
                                145.801572000011,
                                17.3869869997149
                            ],
                            [
                                145.844313000406,
                                17.400411000279
                            ],
                            [
                                145.85054099961,
                                17.4011890003224
                            ],
                            [
                                145.87500000028,
                                17.3915660003043
                            ],
                            [
                                145.896408999576,
                                17.3883880001547
                            ],
                            [
                                145.904533000086,
                                17.3749999995893
                            ],
                            [
                                145.916096000338,
                                17.3720770002361
                            ],
                            [
                                145.921547999764,
                                17.3667490002148
                            ],
                            [
                                145.936554999779,
                                17.340370999978
                            ],
                            [
                                145.926011999702,
                                17.3332939997343
                            ],
                            [
                                145.927236000091,
                                17.3213129999462
                            ],
                            [
                                145.925815999884,
                                17.2971289999109
                            ],
                            [
                                145.923192000278,
                                17.2886029997928
                            ],
                            [
                                145.912142000037,
                                17.2707580000661
                            ],
                            [
                                145.903712999929,
                                17.2608250003186
                            ],
                            [
                                145.895636000173,
                                17.2564010001277
                            ],
                            [
                                145.892151000401,
                                17.2499999997981
                            ],
                            [
                                145.890717999775,
                                17.2433120000036
                            ],
                            [
                                145.865607000074,
                                17.2307170003213
                            ],
                            [
                                145.846815999638,
                                17.2252650001859
                            ],
                            [
                                145.827383000218,
                                17.2286759999542
                            ],
                            [
                                145.805477999732,
                                17.2411030003627
                            ],
                            [
                                145.79361400008,
                                17.2499999997981
                            ],
                            [
                                145.791459000104,
                                17.2549389999527
                            ],
                            [
                                145.774210000074,
                                17.2730780001251
                            ],
                            [
                                145.766051000302,
                                17.2906159997767
                            ],
                            [
                                145.763378000292,
                                17.305854999877
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                145.704423999935,
                                16.6881549995847
                            ],
                            [
                                145.700026999944,
                                16.7108970004277
                            ],
                            [
                                145.702166999852,
                                16.7357080003057
                            ],
                            [
                                145.708296000219,
                                16.7500000001387
                            ],
                            [
                                145.720089999551,
                                16.7587679997664
                            ],
                            [
                                145.722185999976,
                                16.7639959998654
                            ],
                            [
                                145.730738999933,
                                16.7698819998017
                            ],
                            [
                                145.769772000076,
                                16.7877449995982
                            ],
                            [
                                145.792386000392,
                                16.7919710001872
                            ],
                            [
                                145.823188999719,
                                16.7821200002526
                            ],
                            [
                                145.84355000015,
                                16.7658739998144
                            ],
                            [
                                145.867897999594,
                                16.7417549998759
                            ],
                            [
                                145.867632000354,
                                16.6905210001492
                            ],
                            [
                                145.861516000405,
                                16.673005000415
                            ],
                            [
                                145.854936999775,
                                16.6601249997711
                            ],
                            [
                                145.842076000414,
                                16.6479229998523
                            ],
                            [
                                145.821905999779,
                                16.6370650002582
                            ],
                            [
                                145.80267599985,
                                16.6323649999382
                            ],
                            [
                                145.781234000044,
                                16.6304699998217
                            ],
                            [
                                145.762957000343,
                                16.632236999771
                            ],
                            [
                                145.749999999824,
                                16.6392219995799
                            ],
                            [
                                145.738306999876,
                                16.6409500001092
                            ],
                            [
                                145.721845000392,
                                16.6518459999171
                            ],
                            [
                                145.711745999831,
                                16.665675000317
                            ],
                            [
                                145.704423999935,
                                16.6881549995847
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                144.815480000148,
                                20.5277230000031
                            ],
                            [
                                144.813337999693,
                                20.544762000408
                            ],
                            [
                                144.815443000338,
                                20.5628230001661
                            ],
                            [
                                144.821067000193,
                                20.5781020000139
                            ],
                            [
                                144.833432999985,
                                20.5951409998618
                            ],
                            [
                                144.846104000274,
                                20.6051579996434
                            ],
                            [
                                144.863289999656,
                                20.6129949996178
                            ],
                            [
                                144.899134000303,
                                20.6165560004064
                            ],
                            [
                                144.923035999683,
                                20.6143670003459
                            ],
                            [
                                144.941413999667,
                                20.6083220001132
                            ],
                            [
                                144.959280000363,
                                20.5944030002391
                            ],
                            [
                                144.971092000136,
                                20.5811850003034
                            ],
                            [
                                144.97748599992,
                                20.5622549999561
                            ],
                            [
                                144.979354999665,
                                20.547375999891
                            ],
                            [
                                144.979146000326,
                                20.5258630003282
                            ],
                            [
                                144.971427999697,
                                20.5105309998094
                            ],
                            [
                                144.963848999883,
                                20.4999999998374
                            ],
                            [
                                144.934042000441,
                                20.4754250003783
                            ],
                            [
                                144.926430999942,
                                20.4700930002373
                            ],
                            [
                                144.911896999755,
                                20.466816999775
                            ],
                            [
                                144.887114000117,
                                20.4629140001987
                            ],
                            [
                                144.878519000327,
                                20.4634739998448
                            ],
                            [
                                144.87500000022,
                                20.4656299999487
                            ],
                            [
                                144.862473999696,
                                20.4670949998089
                            ],
                            [
                                144.853505999637,
                                20.4722720003123
                            ],
                            [
                                144.845054999787,
                                20.4740690001627
                            ],
                            [
                                144.831399000382,
                                20.4848949999765
                            ],
                            [
                                144.826761000367,
                                20.4917660003525
                            ],
                            [
                                144.826127999807,
                                20.4999999998374
                            ],
                            [
                                144.816038000365,
                                20.5232949996812
                            ],
                            [
                                144.815480000148,
                                20.5277230000031
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                146.002974000148,
                                16.0057559998864
                            ],
                            [
                                146.001994000156,
                                16.0287839999842
                            ],
                            [
                                146.014253999643,
                                16.0574420000498
                            ],
                            [
                                146.030366000221,
                                16.0772340002135
                            ],
                            [
                                146.050775000333,
                                16.0933679997572
                            ],
                            [
                                146.094033999864,
                                16.0972830000783
                            ],
                            [
                                146.125000000296,
                                16.0854770004137
                            ],
                            [
                                146.138180000397,
                                16.0746520003728
                            ],
                            [
                                146.151920000366,
                                16.0518729997113
                            ],
                            [
                                146.154417999575,
                                16.0227679996859
                            ],
                            [
                                146.150670000038,
                                16.006316999829
                            ],
                            [
                                146.14692400015,
                                16.0000000003688
                            ],
                            [
                                146.133159000068,
                                15.98102099963
                            ],
                            [
                                146.088159999764,
                                15.9529550001122
                            ],
                            [
                                146.04444199975,
                                15.9478929999052
                            ],
                            [
                                146.01071399962,
                                15.9706699998228
                            ],
                            [
                                146.002974000148,
                                16.0057559998864
                            ]
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 14.9367835,
            "name": "Commonwealth of the Northern Mariana Islands",
            "intptlon": 145.601021,
            "geo_point_2d": [
                16.8164771029,
                145.595809651
            ],
            "geoid": "69",
            "mtfcc": "G4000",
            "region": 9
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                145.595809651,
                16.8164771029
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "b1ac50494cf2d71089c859ca0a3fa53004a6ab8e",
        "fields": {
            "arealand": "1627312771",
            "objectid": 44,
            "basename": "Arkansas",
            "stusab": "AR",
            "statens": "00068085",
            "centlon": -92.442699,
            "state": "05",
            "gid": 31,
            "centlat": 34.8940208,
            "division": 7,
            "areawater": "-1334552525",
            "oid": "266078934",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -94.5521800000544,
                            36.1022360001623
                        ],
                        [
                            -94.5862000002629,
                            36.2999690003058
                        ],
                        [
                            -94.6177559998005,
                            36.4994610003194
                        ],
                        [
                            -92.7900600002039,
                            36.4976449997916
                        ],
                        [
                            -92.5168359997772,
                            36.4987379998015
                        ],
                        [
                            -92.3751589997513,
                            36.4971989997664
                        ],
                        [
                            -91.6077230000786,
                            36.4995700002154
                        ],
                        [
                            -91.4071379997261,
                            36.4970810000618
                        ],
                        [
                            -90.818314999972,
                            36.4986889997449
                        ],
                        [
                            -90.1522800004256,
                            36.4979500003182
                        ],
                        [
                            -90.1547340001525,
                            36.4967389997923
                        ],
                        [
                            -90.1534500003876,
                            36.4930480003001
                        ],
                        [
                            -90.1600070003775,
                            36.4942570003581
                        ],
                        [
                            -90.1561509999867,
                            36.4890969999826
                        ],
                        [
                            -90.1564859997224,
                            36.4877039997202
                        ],
                        [
                            -90.1601360002489,
                            36.4870339999385
                        ],
                        [
                            -90.1571579997421,
                            36.4844009997162
                        ],
                        [
                            -90.1596270004359,
                            36.4803019997107
                        ],
                        [
                            -90.1499220000599,
                            36.4765650002349
                        ],
                        [
                            -90.1438119999588,
                            36.4759069997354
                        ],
                        [
                            -90.142412999668,
                            36.4715010000063
                        ],
                        [
                            -90.1459159998813,
                            36.4689539996658
                        ],
                        [
                            -90.1528899996227,
                            36.4709309997565
                        ],
                        [
                            -90.1558039996571,
                            36.4635539998117
                        ],
                        [
                            -90.1507000003848,
                            36.4655109996477
                        ],
                        [
                            -90.1446200004209,
                            36.462788999693
                        ],
                        [
                            -90.1445889995605,
                            36.4675890001614
                        ],
                        [
                            -90.1424939998588,
                            36.4679059998831
                        ],
                        [
                            -90.1404319997691,
                            36.4645689999717
                        ],
                        [
                            -90.1409620003968,
                            36.4585380002054
                        ],
                        [
                            -90.1339390002517,
                            36.4535969997771
                        ],
                        [
                            -90.1372650000147,
                            36.4503100000734
                        ],
                        [
                            -90.1344029998589,
                            36.4359840000702
                        ],
                        [
                            -90.138861999774,
                            36.4351159997236
                        ],
                        [
                            -90.1377000002079,
                            36.4318380001887
                        ],
                        [
                            -90.1429240000291,
                            36.430957000168
                        ],
                        [
                            -90.1442770002892,
                            36.4259179999205
                        ],
                        [
                            -90.1386040000312,
                            36.4211540000215
                        ],
                        [
                            -90.1343099999725,
                            36.4228000000954
                        ],
                        [
                            -90.1350330000457,
                            36.4168159998151
                        ],
                        [
                            -90.1385739998939,
                            36.4138170002336
                        ],
                        [
                            -90.1339219996349,
                            36.4138570001002
                        ],
                        [
                            -90.1274900002169,
                            36.4166670000825
                        ],
                        [
                            -90.1265960004394,
                            36.4155919997024
                        ],
                        [
                            -90.1285909996836,
                            36.4114250002594
                        ],
                        [
                            -90.12155099982,
                            36.4108349999201
                        ],
                        [
                            -90.118917999993,
                            36.4125379999467
                        ],
                        [
                            -90.1183870004387,
                            36.4094130003469
                        ],
                        [
                            -90.1154709998562,
                            36.4079900001758
                        ],
                        [
                            -90.1135569999045,
                            36.409509000085
                        ],
                        [
                            -90.1156900001393,
                            36.4059060002365
                        ],
                        [
                            -90.1108069999016,
                            36.4077789999114
                        ],
                        [
                            -90.1108139995743,
                            36.4047850002088
                        ],
                        [
                            -90.1093520004326,
                            36.4037839999526
                        ],
                        [
                            -90.1045920002185,
                            36.4055050002851
                        ],
                        [
                            -90.1015829997496,
                            36.4020250002903
                        ],
                        [
                            -90.0983470003982,
                            36.4040220003478
                        ],
                        [
                            -90.081407000165,
                            36.4010740001991
                        ],
                        [
                            -90.0742450001027,
                            36.3930660001869
                        ],
                        [
                            -90.0703529997269,
                            36.3934669999698
                        ],
                        [
                            -90.068961999832,
                            36.3882970002032
                        ],
                        [
                            -90.0657690001383,
                            36.387463000146
                        ],
                        [
                            -90.0681259997808,
                            36.3851490003395
                        ],
                        [
                            -90.0633970004272,
                            36.3839569996969
                        ],
                        [
                            -90.0667449999316,
                            36.3780060002861
                        ],
                        [
                            -90.062738999753,
                            36.3772600003172
                        ],
                        [
                            -90.0647260003979,
                            36.3689879999274
                        ],
                        [
                            -90.0634080002979,
                            36.3670160003314
                        ],
                        [
                            -90.0663599999671,
                            36.3651339998523
                        ],
                        [
                            -90.0660519999955,
                            36.3599439997777
                        ],
                        [
                            -90.0680580000087,
                            36.3565650002911
                        ],
                        [
                            -90.0705119997356,
                            36.3562670003094
                        ],
                        [
                            -90.0758100000749,
                            36.3498349997282
                        ],
                        [
                            -90.0780429998688,
                            36.3491409997384
                        ],
                        [
                            -90.0742099999426,
                            36.3425169998262
                        ],
                        [
                            -90.0771700000077,
                            36.3411490001545
                        ],
                        [
                            -90.0782860004414,
                            36.3367199997528
                        ],
                        [
                            -90.0755740000734,
                            36.3342139997158
                        ],
                        [
                            -90.0790699997157,
                            36.327737000318
                        ],
                        [
                            -90.081912999605,
                            36.3264570002429
                        ],
                        [
                            -90.0798580000863,
                            36.3234950003538
                        ],
                        [
                            -90.0824070002477,
                            36.3219489998708
                        ],
                        [
                            -90.0798499996904,
                            36.3183889996693
                        ],
                        [
                            -90.0766129996158,
                            36.319213000332
                        ],
                        [
                            -90.0695490003611,
                            36.313261000245
                        ],
                        [
                            -90.064169000006,
                            36.302526000116
                        ],
                        [
                            -90.0693180003824,
                            36.2985880000806
                        ],
                        [
                            -90.0714209995817,
                            36.293174000144
                        ],
                        [
                            -90.0790510003475,
                            36.289851000194
                        ],
                        [
                            -90.0757270002343,
                            36.281065999727
                        ],
                        [
                            -90.079836000345,
                            36.2785240003179
                        ],
                        [
                            -90.0835289996309,
                            36.2721220002691
                        ],
                        [
                            -90.0960049999261,
                            36.2713420003214
                        ],
                        [
                            -90.0995039999415,
                            36.2689470000768
                        ],
                        [
                            -90.1028129999861,
                            36.268981000004
                        ],
                        [
                            -90.1065379999573,
                            36.2658589998357
                        ],
                        [
                            -90.1104110000665,
                            36.2668339998384
                        ],
                        [
                            -90.1168009996518,
                            36.263770999759
                        ],
                        [
                            -90.1160230002252,
                            36.2616010000033
                        ],
                        [
                            -90.1174280003639,
                            36.2582760002845
                        ],
                        [
                            -90.1192860002391,
                            36.257715000203
                        ],
                        [
                            -90.118076999919,
                            36.255596000206
                        ],
                        [
                            -90.1198939997863,
                            36.2546559997641
                        ],
                        [
                            -90.1183740000199,
                            36.2532730002479
                        ],
                        [
                            -90.1223049998554,
                            36.2492560001148
                        ],
                        [
                            -90.1219070003705,
                            36.2467850000915
                        ],
                        [
                            -90.1246339999088,
                            36.2444750000209
                        ],
                        [
                            -90.1236279999783,
                            36.2434219999764
                        ],
                        [
                            -90.1297160003381,
                            36.2432349998633
                        ],
                        [
                            -90.1309480002244,
                            36.2413769997331
                        ],
                        [
                            -90.1277699997011,
                            36.2396680001452
                        ],
                        [
                            -90.127705000302,
                            36.2370259998324
                        ],
                        [
                            -90.1247350001912,
                            36.2352929998542
                        ],
                        [
                            -90.1265289995939,
                            36.2331979998869
                        ],
                        [
                            -90.1263360001484,
                            36.2291230000513
                        ],
                        [
                            -90.1311819996778,
                            36.228693999729
                        ],
                        [
                            -90.1331030002005,
                            36.2261110002368
                        ],
                        [
                            -90.1422379997658,
                            36.227521999701
                        ],
                        [
                            -90.144134999999,
                            36.2234510000325
                        ],
                        [
                            -90.1459130004066,
                            36.223152999965
                        ],
                        [
                            -90.1456029998869,
                            36.2211409996585
                        ],
                        [
                            -90.1490490001988,
                            36.2216209997511
                        ],
                        [
                            -90.152153000204,
                            36.2157469997821
                        ],
                        [
                            -90.1555020004316,
                            36.2138810001186
                        ],
                        [
                            -90.1610430004452,
                            36.2156580000584
                        ],
                        [
                            -90.1677950004287,
                            36.2131810001369
                        ],
                        [
                            -90.1695699995649,
                            36.2141370000846
                        ],
                        [
                            -90.1729799998918,
                            36.2104199996904
                        ],
                        [
                            -90.1758460002456,
                            36.2105740002795
                        ],
                        [
                            -90.17783899984,
                            36.207130999925
                        ],
                        [
                            -90.1810779995644,
                            36.2087290000735
                        ],
                        [
                            -90.1830429995698,
                            36.2049840002904
                        ],
                        [
                            -90.1881840004486,
                            36.2054950000349
                        ],
                        [
                            -90.1901519999286,
                            36.2013930001682
                        ],
                        [
                            -90.1952140002664,
                            36.2003479999283
                        ],
                        [
                            -90.1964560001985,
                            36.1963720002103
                        ],
                        [
                            -90.2015379997295,
                            36.1960459998188
                        ],
                        [
                            -90.2007469998841,
                            36.1921280002539
                        ],
                        [
                            -90.2041930001961,
                            36.1897060001778
                        ],
                        [
                            -90.2044259998246,
                            36.1867119999727
                        ],
                        [
                            -90.2101030002806,
                            36.1857649999021
                        ],
                        [
                            -90.2114699998861,
                            36.1830439998607
                        ],
                        [
                            -90.2153050003605,
                            36.1846740000451
                        ],
                        [
                            -90.2165990001711,
                            36.1835590001103
                        ],
                        [
                            -90.2204870003489,
                            36.1844370000555
                        ],
                        [
                            -90.2251579999762,
                            36.1768729997928
                        ],
                        [
                            -90.2295599999899,
                            36.1731930003445
                        ],
                        [
                            -90.2312749997499,
                            36.1636530002759
                        ],
                        [
                            -90.2352680004081,
                            36.1596170001569
                        ],
                        [
                            -90.2328349996993,
                            36.1563230001318
                        ],
                        [
                            -90.2333689996267,
                            36.1547869996398
                        ],
                        [
                            -90.2312630000544,
                            36.1538210002445
                        ],
                        [
                            -90.2315059997286,
                            36.1467829997768
                        ],
                        [
                            -90.2350599999956,
                            36.1450739996419
                        ],
                        [
                            -90.2353720001651,
                            36.1394800001231
                        ],
                        [
                            -90.2455560002169,
                            36.1361879996718
                        ],
                        [
                            -90.2453700004441,
                            36.1335540000819
                        ],
                        [
                            -90.2531919999322,
                            36.1272919997724
                        ],
                        [
                            -90.2608619999826,
                            36.1272570000845
                        ],
                        [
                            -90.2665200001721,
                            36.1201079999628
                        ],
                        [
                            -90.2723080000579,
                            36.1184460003188
                        ],
                        [
                            -90.2729590001611,
                            36.1169310000901
                        ],
                        [
                            -90.2788750000935,
                            36.1174769998068
                        ],
                        [
                            -90.2933010003254,
                            36.1143050002141
                        ],
                        [
                            -90.2950670001391,
                            36.1126249998041
                        ],
                        [
                            -90.2938500003214,
                            36.1099390000066
                        ],
                        [
                            -90.2981839996647,
                            36.1073119999243
                        ],
                        [
                            -90.297916999701,
                            36.1029930000666
                        ],
                        [
                            -90.3002509997773,
                            36.0977310002389
                        ],
                        [
                            -90.3069059996764,
                            36.0948019997554
                        ],
                        [
                            -90.3117269999898,
                            36.094748999779
                        ],
                        [
                            -90.3164360001502,
                            36.0906409997939
                        ],
                        [
                            -90.3188770003566,
                            36.0903089999087
                        ],
                        [
                            -90.3206480001931,
                            36.0870520002321
                        ],
                        [
                            -90.3188820003794,
                            36.0845240002908
                        ],
                        [
                            -90.3202370002894,
                            36.0810700001581
                        ],
                        [
                            -90.3182379999489,
                            36.0761339998703
                        ],
                        [
                            -90.3207679998437,
                            36.0732250003372
                        ],
                        [
                            -90.3204199996892,
                            36.0714819999692
                        ],
                        [
                            -90.3236750002054,
                            36.0716380001725
                        ],
                        [
                            -90.3279800001348,
                            36.0685520003448
                        ],
                        [
                            -90.3332190000246,
                            36.0677009997303
                        ],
                        [
                            -90.3362960002656,
                            36.0606679999901
                        ],
                        [
                            -90.3334459998053,
                            36.0567300002832
                        ],
                        [
                            -90.3377159995745,
                            36.0532549997647
                        ],
                        [
                            -90.3368810002466,
                            36.047368000154
                        ],
                        [
                            -90.3407550001807,
                            36.0471379996495
                        ],
                        [
                            -90.3435570000621,
                            36.044984999735
                        ],
                        [
                            -90.3436599999943,
                            36.0430699998636
                        ],
                        [
                            -90.3482079995979,
                            36.0415730002335
                        ],
                        [
                            -90.3493089999629,
                            36.0392949999507
                        ],
                        [
                            -90.3482750004433,
                            36.0350509997741
                        ],
                        [
                            -90.3513059997553,
                            36.031074999978
                        ],
                        [
                            -90.3541970002234,
                            36.0304670002012
                        ],
                        [
                            -90.3517130003594,
                            36.0280059997468
                        ],
                        [
                            -90.3518089997205,
                            36.0250819997732
                        ],
                        [
                            -90.3569770003635,
                            36.0184860003039
                        ],
                        [
                            -90.3643250001987,
                            36.014078999997
                        ],
                        [
                            -90.3778900002651,
                            35.9956830002956
                        ],
                        [
                            -89.7330949999601,
                            36.0006079997882
                        ],
                        [
                            -89.7284419998762,
                            35.9935680003498
                        ],
                        [
                            -89.7191680003938,
                            35.985975999916
                        ],
                        [
                            -89.7196789998566,
                            35.9709390000179
                        ],
                        [
                            -89.7145649996403,
                            35.9630340002498
                        ],
                        [
                            -89.6869240000447,
                            35.9477160002529
                        ],
                        [
                            -89.6531399998377,
                            35.9226179998816
                        ],
                        [
                            -89.6448379999506,
                            35.9043510002339
                        ],
                        [
                            -89.6465560000837,
                            35.899801999665
                        ],
                        [
                            -89.6443950002598,
                            35.894781999771
                        ],
                        [
                            -89.6453459999387,
                            35.8914189997462
                        ],
                        [
                            -89.6483649995551,
                            35.8937669996475
                        ],
                        [
                            -89.6661009996565,
                            35.882866000246
                        ],
                        [
                            -89.675084999609,
                            35.8843639998003
                        ],
                        [
                            -89.6881369996644,
                            35.896945000091
                        ],
                        [
                            -89.7149339997112,
                            35.9062470000979
                        ],
                        [
                            -89.7426060001673,
                            35.9066529996905
                        ],
                        [
                            -89.7656890001136,
                            35.8912989999551
                        ],
                        [
                            -89.7728550003739,
                            35.8762440000246
                        ],
                        [
                            -89.7724670000363,
                            35.8650979997542
                        ],
                        [
                            -89.7643430004244,
                            35.8583129997283
                        ],
                        [
                            -89.729517000302,
                            35.847632000314
                        ],
                        [
                            -89.7063520003399,
                            35.8372020000929
                        ],
                        [
                            -89.7028829995634,
                            35.8341529996855
                        ],
                        [
                            -89.7011910002679,
                            35.8299120002226
                        ],
                        [
                            -89.7015229996306,
                            35.8249149996771
                        ],
                        [
                            -89.7043870003346,
                            35.8196070002743
                        ],
                        [
                            -89.7238330001732,
                            35.809246999983
                        ],
                        [
                            -89.734706999788,
                            35.8060469996775
                        ],
                        [
                            -89.7433190001948,
                            35.8057809999703
                        ],
                        [
                            -89.7654380000434,
                            35.8112129997233
                        ],
                        [
                            -89.76545700031,
                            35.809512999738
                        ],
                        [
                            -89.7811759996292,
                            35.8052959998136
                        ],
                        [
                            -89.7963240001095,
                            35.7928069998658
                        ],
                        [
                            -89.7993310000303,
                            35.7885030001334
                        ],
                        [
                            -89.7970530000306,
                            35.7826479999668
                        ],
                        [
                            -89.7992490000145,
                            35.775438999979
                        ],
                        [
                            -89.8048629998231,
                            35.7715159996495
                        ],
                        [
                            -89.8128910000738,
                            35.7611540001451
                        ],
                        [
                            -89.8208759997685,
                            35.7568679999846
                        ],
                        [
                            -89.8368000000255,
                            35.7549410002589
                        ],
                        [
                            -89.8471270001924,
                            35.7554989998716
                        ],
                        [
                            -89.8656309996746,
                            35.7465769998356
                        ],
                        [
                            -89.8764619996317,
                            35.7380259999282
                        ],
                        [
                            -89.87725599985,
                            35.7413689997149
                        ],
                        [
                            -89.8835350000055,
                            35.7449840002374
                        ],
                        [
                            -89.8881629999751,
                            35.7500770000229
                        ],
                        [
                            -89.9063619998588,
                            35.7595620001419
                        ],
                        [
                            -89.9098249998892,
                            35.759387000298
                        ],
                        [
                            -89.9191069997675,
                            35.7530250000032
                        ],
                        [
                            -89.9502770004141,
                            35.7384939997654
                        ],
                        [
                            -89.9562519998976,
                            35.7333880003109
                        ],
                        [
                            -89.9588789998768,
                            35.7238349999952
                        ],
                        [
                            -89.9557530001303,
                            35.6906209996729
                        ],
                        [
                            -89.94270000025,
                            35.675120999858
                        ],
                        [
                            -89.9373829996441,
                            35.6657109999367
                        ],
                        [
                            -89.9310359997165,
                            35.6600440000173
                        ],
                        [
                            -89.9154270000021,
                            35.6527819997329
                        ],
                        [
                            -89.9042300003472,
                            35.6509969998607
                        ],
                        [
                            -89.894566999804,
                            35.6515139998436
                        ],
                        [
                            -89.8849319997483,
                            35.6551069997997
                        ],
                        [
                            -89.8771579999408,
                            35.6661360003596
                        ],
                        [
                            -89.8647390004453,
                            35.6703880001562
                        ],
                        [
                            -89.8579420002559,
                            35.6683120003279
                        ],
                        [
                            -89.8511579995871,
                            35.6574239996727
                        ],
                        [
                            -89.8518239997589,
                            35.6442610003553
                        ],
                        [
                            -89.8570650001969,
                            35.6341519998545
                        ],
                        [
                            -89.8778629995724,
                            35.6260040002644
                        ],
                        [
                            -89.8943450000462,
                            35.6155350000581
                        ],
                        [
                            -89.8997520001655,
                            35.6150250000394
                        ],
                        [
                            -89.9110819998898,
                            35.617555999828
                        ],
                        [
                            -89.9195529998313,
                            35.6121800000893
                        ],
                        [
                            -89.9467109995532,
                            35.6007890000516
                        ],
                        [
                            -89.9536940004137,
                            35.59462399991
                        ],
                        [
                            -89.9574949996546,
                            35.5885300002917
                        ],
                        [
                            -89.9578770001443,
                            35.58536900003
                        ],
                        [
                            -89.9544719998402,
                            35.5766080001325
                        ],
                        [
                            -89.9418750000695,
                            35.5568590001347
                        ],
                        [
                            -89.9286849999219,
                            35.551453999818
                        ],
                        [
                            -89.9124789996326,
                            35.5494319998331
                        ],
                        [
                            -89.9088709998372,
                            35.5473779997255
                        ],
                        [
                            -89.9075789996765,
                            35.5421210002444
                        ],
                        [
                            -89.9097400003987,
                            35.5419599998556
                        ],
                        [
                            -89.9124019996397,
                            35.5379509996993
                        ],
                        [
                            -89.9064580001182,
                            35.5371940001641
                        ],
                        [
                            -89.9038820001927,
                            35.5341750003641
                        ],
                        [
                            -89.9090220003483,
                            35.5205479997059
                        ],
                        [
                            -89.9144839998208,
                            35.5156919996687
                        ],
                        [
                            -89.9193310000735,
                            35.5138700002248
                        ],
                        [
                            -89.9336140001903,
                            35.5185379998524
                        ],
                        [
                            -89.948010000285,
                            35.5200900002189
                        ],
                        [
                            -89.9577149997626,
                            35.5271920001611
                        ],
                        [
                            -89.9556900003812,
                            35.5337879997665
                        ],
                        [
                            -89.9588489997396,
                            35.5419160002969
                        ],
                        [
                            -89.9789170002674,
                            35.5553280002864
                        ],
                        [
                            -89.9894989998042,
                            35.5601359998114
                        ],
                        [
                            -89.9992440003631,
                            35.5611930002688
                        ],
                        [
                            -90.007993000139,
                            35.5600910002819
                        ],
                        [
                            -90.017582000164,
                            35.5560099998088
                        ],
                        [
                            -90.0261130003799,
                            35.556176000034
                        ],
                        [
                            -90.0351180002488,
                            35.5525119998285
                        ],
                        [
                            -90.0395500003998,
                            35.5485569998391
                        ],
                        [
                            -90.0419609995706,
                            35.537467000233
                        ],
                        [
                            -90.0495400002828,
                            35.5210490000949
                        ],
                        [
                            -90.0498099997212,
                            35.510454000226
                        ],
                        [
                            -90.0434619999688,
                            35.4923390000974
                        ],
                        [
                            -90.0337900001031,
                            35.4794109998313
                        ],
                        [
                            -90.0203329999917,
                            35.4702910002407
                        ],
                        [
                            -90.0189160001575,
                            35.4662309996885
                        ],
                        [
                            -90.0266039997513,
                            35.4477879997185
                        ],
                        [
                            -90.0265839996598,
                            35.4401030000938
                        ],
                        [
                            -90.0310209998336,
                            35.4330539997828
                        ],
                        [
                            -90.0315840000733,
                            35.4276619998141
                        ],
                        [
                            -90.0426400001613,
                            35.4212369998146
                        ],
                        [
                            -90.046597999761,
                            35.4129659996465
                        ],
                        [
                            -90.0567819998127,
                            35.4038629998753
                        ],
                        [
                            -90.0415630000857,
                            35.3966200002484
                        ],
                        [
                            -90.044581999702,
                            35.3930730002377
                        ],
                        [
                            -90.0543220002381,
                            35.3892770001504
                        ],
                        [
                            -90.0694339998351,
                            35.4081630002331
                        ],
                        [
                            -90.0620180002278,
                            35.4151799999946
                        ],
                        [
                            -90.0705490004438,
                            35.4232910001488
                        ],
                        [
                            -90.0740459999111,
                            35.4340430002881
                        ],
                        [
                            -90.0740629996295,
                            35.4396109998964
                        ],
                        [
                            -90.0672289996302,
                            35.4609519996426
                        ],
                        [
                            -90.068155999918,
                            35.4667799997889
                        ],
                        [
                            -90.0741320001248,
                            35.472545999647
                        ],
                        [
                            -90.0836500000047,
                            35.4782970000804
                        ],
                        [
                            -90.0987340000125,
                            35.4785540000427
                        ],
                        [
                            -90.1017719998955,
                            35.4768309999472
                        ],
                        [
                            -90.1078369997907,
                            35.4768360000455
                        ],
                        [
                            -90.1189280000388,
                            35.4683280003639
                        ],
                        [
                            -90.1237560000248,
                            35.459112999973
                        ],
                        [
                            -90.1294469998263,
                            35.4419299999227
                        ],
                        [
                            -90.1688819996517,
                            35.4222059996978
                        ],
                        [
                            -90.1705119999213,
                            35.4187970002032
                        ],
                        [
                            -90.1706759999528,
                            35.4105299996636
                        ],
                        [
                            -90.1792649998951,
                            35.385193999949
                        ],
                        [
                            -90.1783409999804,
                            35.3820919997073
                        ],
                        [
                            -90.16624600035,
                            35.3747449997637
                        ],
                        [
                            -90.1449240001945,
                            35.3746329997337
                        ],
                        [
                            -90.1355100000717,
                            35.3766679997786
                        ],
                        [
                            -90.1434749996749,
                            35.3876020001256
                        ],
                        [
                            -90.1461910002409,
                            35.3994679999066
                        ],
                        [
                            -90.1441650001363,
                            35.4055540002652
                        ],
                        [
                            -90.140921000389,
                            35.4092250001599
                        ],
                        [
                            -90.1351250001072,
                            35.4129769998085
                        ],
                        [
                            -90.129492999857,
                            35.4137590002237
                        ],
                        [
                            -90.1125040001183,
                            35.4101529999112
                        ],
                        [
                            -90.1048419995655,
                            35.4014870003429
                        ],
                        [
                            -90.0966500001816,
                            35.3952569997578
                        ],
                        [
                            -90.0799300000564,
                            35.3852720001743
                        ],
                        [
                            -90.0749919995671,
                            35.3841519998463
                        ],
                        [
                            -90.0879030000555,
                            35.3632700000136
                        ],
                        [
                            -90.0968250000837,
                            35.3572160001604
                        ],
                        [
                            -90.1073120000842,
                            35.3431429999746
                        ],
                        [
                            -90.1102930000657,
                            35.3427880000287
                        ],
                        [
                            -90.1038619995743,
                            35.3324049997845
                        ],
                        [
                            -90.1068240001875,
                            35.3246179997427
                        ],
                        [
                            -90.1090929999665,
                            35.3049870003379
                        ],
                        [
                            -90.1161139995635,
                            35.3034119997107
                        ],
                        [
                            -90.123706999621,
                            35.3045299998037
                        ],
                        [
                            -90.1388640003221,
                            35.2991139998684
                        ],
                        [
                            -90.1494490002319,
                            35.3033019996851
                        ],
                        [
                            -90.1589129996851,
                            35.3006370002211
                        ],
                        [
                            -90.1638119998163,
                            35.296114999734
                        ],
                        [
                            -90.1690790001935,
                            35.2845639999834
                        ],
                        [
                            -90.1689240003828,
                            35.2802800001742
                        ],
                        [
                            -90.1588650000045,
                            35.2625770001718
                        ],
                        [
                            -90.1520939997545,
                            35.2559890000478
                        ],
                        [
                            -90.1403940001343,
                            35.2522890001125
                        ],
                        [
                            -90.1164929996802,
                            35.2557879997438
                        ],
                        [
                            -90.1050929996356,
                            35.2542880002279
                        ],
                        [
                            -90.0979470003652,
                            35.249982999842
                        ],
                        [
                            -90.0793450000753,
                            35.2288380002134
                        ],
                        [
                            -90.0742619998211,
                            35.2183159998541
                        ],
                        [
                            -90.0743920004158,
                            35.2105889997374
                        ],
                        [
                            -90.0800849998671,
                            35.2076880000456
                        ],
                        [
                            -90.0885969998164,
                            35.2123759998207
                        ],
                        [
                            -90.0964660000585,
                            35.1948480002065
                        ],
                        [
                            -90.109076000248,
                            35.199105000228
                        ],
                        [
                            -90.1161820002339,
                            35.1984980000222
                        ],
                        [
                            -90.1173930002038,
                            35.1878900000935
                        ],
                        [
                            -90.1142139998555,
                            35.1816910000093
                        ],
                        [
                            -90.1094860003269,
                            35.17886499998
                        ],
                        [
                            -90.1087570004058,
                            35.1752819999294
                        ],
                        [
                            -90.1041700004442,
                            35.169011999721
                        ],
                        [
                            -90.0919989997475,
                            35.1563719999572
                        ],
                        [
                            -90.0705259999793,
                            35.1536439997781
                        ],
                        [
                            -90.0669580003669,
                            35.1518389997326
                        ],
                        [
                            -90.0646119996968,
                            35.1406209997463
                        ],
                        [
                            -90.0653919996714,
                            35.1376910002657
                        ],
                        [
                            -90.0834209996758,
                            35.1216689997666
                        ],
                        [
                            -90.096514999564,
                            35.1171309997724
                        ],
                        [
                            -90.1093930004404,
                            35.1188909996361
                        ],
                        [
                            -90.1427940003328,
                            35.1350909998266
                        ],
                        [
                            -90.1559939996279,
                            35.1309909998595
                        ],
                        [
                            -90.165328000283,
                            35.1252279999216
                        ],
                        [
                            -90.173603000406,
                            35.1180730001042
                        ],
                        [
                            -90.1768429999553,
                            35.1120880002499
                        ],
                        [
                            -90.1813870002592,
                            35.0914010000451
                        ],
                        [
                            -90.1958729998673,
                            35.0602079999161
                        ],
                        [
                            -90.1972210001045,
                            35.0483810002563
                        ],
                        [
                            -90.1958169997908,
                            35.0377880000063
                        ],
                        [
                            -90.2002220001776,
                            35.0327429998732
                        ],
                        [
                            -90.2093969999258,
                            35.0265460003647
                        ],
                        [
                            -90.2162579996892,
                            35.0260489998863
                        ],
                        [
                            -90.2306100003013,
                            35.031319999692
                        ],
                        [
                            -90.2564900001061,
                            35.0344940002398
                        ],
                        [
                            -90.2623919997947,
                            35.0363939998636
                        ],
                        [
                            -90.2652930003086,
                            35.0402939997739
                        ],
                        [
                            -90.2919959997458,
                            35.041792999819
                        ],
                        [
                            -90.2955960000436,
                            35.0400929999774
                        ],
                        [
                            -90.3098719995893,
                            35.0097490003089
                        ],
                        [
                            -90.3100830003748,
                            35.0010409999558
                        ],
                        [
                            -90.3077900003065,
                            34.9918529998885
                        ],
                        [
                            -90.3024710000507,
                            34.9820769998084
                        ],
                        [
                            -90.296422000049,
                            34.9763460001564
                        ],
                        [
                            -90.2872389999049,
                            34.9725310002275
                        ],
                        [
                            -90.2775019997419,
                            34.9647760003443
                        ],
                        [
                            -90.2539690004321,
                            34.9549879996557
                        ],
                        [
                            -90.2500560001399,
                            34.9511960002784
                        ],
                        [
                            -90.246689000369,
                            34.9457469996687
                        ],
                        [
                            -90.2444759997683,
                            34.9375960003134
                        ],
                        [
                            -90.2447310000364,
                            34.9210259997599
                        ],
                        [
                            -90.2500949995997,
                            34.9073200001296
                        ],
                        [
                            -90.279363999815,
                            34.8900769996759
                        ],
                        [
                            -90.3065550000471,
                            34.8775139999605
                        ],
                        [
                            -90.313476000085,
                            34.8716980001168
                        ],
                        [
                            -90.3025229999293,
                            34.856470999962
                        ],
                        [
                            -90.3039439999614,
                            34.8505169996356
                        ],
                        [
                            -90.3091829998513,
                            34.8449939997649
                        ],
                        [
                            -90.3136159998271,
                            34.8441049998812
                        ],
                        [
                            -90.3230700001329,
                            34.8464330002786
                        ],
                        [
                            -90.3322599999497,
                            34.8525060000097
                        ],
                        [
                            -90.340380000262,
                            34.8603569999599
                        ],
                        [
                            -90.3521739995935,
                            34.853195999776
                        ],
                        [
                            -90.3798369998287,
                            34.8459309998437
                        ],
                        [
                            -90.4105510003657,
                            34.8320590000233
                        ],
                        [
                            -90.4194850000895,
                            34.832883000066
                        ],
                        [
                            -90.4238849995551,
                            34.8346140000541
                        ],
                        [
                            -90.4294380001626,
                            34.84310199972
                        ],
                        [
                            -90.4317250003832,
                            34.8589140000748
                        ],
                        [
                            -90.4289770000301,
                            34.8671599999509
                        ],
                        [
                            -90.438310999787,
                            34.8845830002242
                        ],
                        [
                            -90.4496530001052,
                            34.8898269999268
                        ],
                        [
                            -90.4618029999872,
                            34.8919100001779
                        ],
                        [
                            -90.4661539999473,
                            34.8909889996565
                        ],
                        [
                            -90.4798639997786,
                            34.8832729999402
                        ],
                        [
                            -90.4845560002206,
                            34.8751060002132
                        ],
                        [
                            -90.483875999805,
                            34.8613329998432
                        ],
                        [
                            -90.4637110000911,
                            34.8348040000824
                        ],
                        [
                            -90.4567830003805,
                            34.8226829999742
                        ],
                        [
                            -90.459023999672,
                            34.8144400000927
                        ],
                        [
                            -90.4653670002999,
                            34.8105920001708
                        ],
                        [
                            -90.4722799999419,
                            34.8024649998953
                        ],
                        [
                            -90.4745899997287,
                            34.7931999998891
                        ],
                        [
                            -90.4704110001961,
                            34.7808769999021
                        ],
                        [
                            -90.4567120002354,
                            34.7607540000587
                        ],
                        [
                            -90.4517510001799,
                            34.7500990000758
                        ],
                        [
                            -90.4512570004355,
                            34.7444849998293
                        ],
                        [
                            -90.4539699997301,
                            34.7368770002368
                        ],
                        [
                            -90.4579499999694,
                            34.7329459998047
                        ],
                        [
                            -90.4698970003601,
                            34.727030000295
                        ],
                        [
                            -90.4888650003479,
                            34.7237310000314
                        ],
                        [
                            -90.501667000158,
                            34.7242360003248
                        ],
                        [
                            -90.520180999686,
                            34.7319020001316
                        ],
                        [
                            -90.5243180002842,
                            34.7391350000749
                        ],
                        [
                            -90.5245169995775,
                            34.7513679999632
                        ],
                        [
                            -90.5208410000101,
                            34.7555360002326
                        ],
                        [
                            -90.505502999559,
                            34.7645639996313
                        ],
                        [
                            -90.5009890002906,
                            34.7711920003657
                        ],
                        [
                            -90.5036799998439,
                            34.7801410000346
                        ],
                        [
                            -90.5147080003428,
                            34.8017659999385
                        ],
                        [
                            -90.5228920002291,
                            34.8022649997099
                        ],
                        [
                            -90.5385029995933,
                            34.7972349998728
                        ],
                        [
                            -90.5476120001176,
                            34.784588999727
                        ],
                        [
                            -90.5472439998716,
                            34.7766829996788
                        ],
                        [
                            -90.5419060002476,
                            34.761730000305
                        ],
                        [
                            -90.5387360001201,
                            34.7589290001213
                        ],
                        [
                            -90.5357960001465,
                            34.7528070000515
                        ],
                        [
                            -90.5364170001125,
                            34.7482989999969
                        ],
                        [
                            -90.5399949997706,
                            34.7448109997201
                        ],
                        [
                            -90.559895000069,
                            34.740788000175
                        ],
                        [
                            -90.5635439998722,
                            34.7386710003447
                        ],
                        [
                            -90.5672400004294,
                            34.7334630000942
                        ],
                        [
                            -90.5680809996051,
                            34.7248020000269
                        ],
                        [
                            -90.5460529999932,
                            34.7020760000439
                        ],
                        [
                            -90.5356820003437,
                            34.697879000107
                        ],
                        [
                            -90.5220360000864,
                            34.6966110002233
                        ],
                        [
                            -90.4853239996014,
                            34.7015359998664
                        ],
                        [
                            -90.4724950000271,
                            34.6997880000046
                        ],
                        [
                            -90.4670639996183,
                            34.6956429999583
                        ],
                        [
                            -90.4628519995754,
                            34.6891440000435
                        ],
                        [
                            -90.4628159995904,
                            34.684073999784
                        ],
                        [
                            -90.4660409999694,
                            34.6743119997985
                        ],
                        [
                            -90.4797180001887,
                            34.6599339997393
                        ],
                        [
                            -90.4944780003315,
                            34.6499339999445
                        ],
                        [
                            -90.5081000002993,
                            34.6366819999688
                        ],
                        [
                            -90.5194779997042,
                            34.6299010000226
                        ],
                        [
                            -90.5321130000081,
                            34.6274890003612
                        ],
                        [
                            -90.54097700031,
                            34.6285330000398
                        ],
                        [
                            -90.5468200004474,
                            34.6310869996327
                        ],
                        [
                            -90.5545449998511,
                            34.6422130002152
                        ],
                        [
                            -90.5548670000664,
                            34.6486879997911
                        ],
                        [
                            -90.5526419997701,
                            34.6597069998716
                        ],
                        [
                            -90.550157999906,
                            34.6634449999126
                        ],
                        [
                            -90.5394009995688,
                            34.6709109997406
                        ],
                        [
                            -90.5378850000003,
                            34.6739789998113
                        ],
                        [
                            -90.5391500003969,
                            34.6834510001775
                        ],
                        [
                            -90.5498569996071,
                            34.6954780002524
                        ],
                        [
                            -90.5571480004392,
                            34.6978229999887
                        ],
                        [
                            -90.5673140000493,
                            34.6935219996913
                        ],
                        [
                            -90.573106000133,
                            34.6864250000016
                        ],
                        [
                            -90.5787450000559,
                            34.6838439997649
                        ],
                        [
                            -90.5884189995714,
                            34.6709629997118
                        ],
                        [
                            -90.5863359995653,
                            34.6516890003384
                        ],
                        [
                            -90.583019999848,
                            34.6426789998599
                        ],
                        [
                            -90.5882550004382,
                            34.6266159999184
                        ],
                        [
                            -90.5872240003934,
                            34.6157320003251
                        ],
                        [
                            -90.5816930004255,
                            34.6042269998296
                        ],
                        [
                            -90.5701329996491,
                            34.5874569998568
                        ],
                        [
                            -90.5535640000349,
                            34.5728620001324
                        ],
                        [
                            -90.5458909996115,
                            34.5632569999908
                        ],
                        [
                            -90.5407360002855,
                            34.5480850002575
                        ],
                        [
                            -90.5457280003032,
                            34.5377500000199
                        ],
                        [
                            -90.5552760003203,
                            34.5310120000374
                        ],
                        [
                            -90.5693469998266,
                            34.5248669997859
                        ],
                        [
                            -90.5801150000345,
                            34.5145370003125
                        ],
                        [
                            -90.5889419996282,
                            34.4910969996305
                        ],
                        [
                            -90.5896599996785,
                            34.4801009999035
                        ],
                        [
                            -90.5860109998753,
                            34.462345999929
                        ],
                        [
                            -90.58372100018,
                            34.4588249998827
                        ],
                        [
                            -90.5732319996314,
                            34.4509290001949
                        ],
                        [
                            -90.5658090003515,
                            34.4354000002608
                        ],
                        [
                            -90.5665049997623,
                            34.42952799965
                        ],
                        [
                            -90.5711450003257,
                            34.4203219996649
                        ],
                        [
                            -90.5806809997489,
                            34.4105510003422
                        ],
                        [
                            -90.613947999747,
                            34.3907210000708
                        ],
                        [
                            -90.6349460000373,
                            34.3863620000452
                        ],
                        [
                            -90.6585400003447,
                            34.3757050002304
                        ],
                        [
                            -90.6553500001258,
                            34.3718400000808
                        ],
                        [
                            -90.6667869999803,
                            34.3558170001859
                        ],
                        [
                            -90.6668639999732,
                            34.3485509998511
                        ],
                        [
                            -90.6573710002076,
                            34.327286999997
                        ],
                        [
                            -90.6574880003835,
                            34.3222309997173
                        ],
                        [
                            -90.6629609997267,
                            34.3147789997809
                        ],
                        [
                            -90.6693429998144,
                            34.3130200000389
                        ],
                        [
                            -90.6780969996131,
                            34.3130309999165
                        ],
                        [
                            -90.6860029996651,
                            34.3157709996579
                        ],
                        [
                            -90.6931289997424,
                            34.3225699999658
                        ],
                        [
                            -90.6936860001344,
                            34.3296799997412
                        ],
                        [
                            -90.6915510002497,
                            34.338617999685
                        ],
                        [
                            -90.68161599972,
                            34.3529039996321
                        ],
                        [
                            -90.6766599996874,
                            34.3714109997882
                        ],
                        [
                            -90.7120880004076,
                            34.3638050002255
                        ],
                        [
                            -90.724908999586,
                            34.3636589997586
                        ],
                        [
                            -90.7501070002237,
                            34.3679190002992
                        ],
                        [
                            -90.7561970002334,
                            34.3672560000429
                        ],
                        [
                            -90.76414299957,
                            34.363396000287
                        ],
                        [
                            -90.7670609998024,
                            34.3602709996809
                        ],
                        [
                            -90.768097999695,
                            34.3558840003014
                        ],
                        [
                            -90.767107999658,
                            34.3452640000601
                        ],
                        [
                            -90.7501060003989,
                            34.3320649997659
                        ],
                        [
                            -90.7458270004088,
                            34.3267460000918
                        ],
                        [
                            -90.7406100002603,
                            34.3134690001419
                        ],
                        [
                            -90.7412529999676,
                            34.3055559997178
                        ],
                        [
                            -90.7526809996012,
                            34.2892659996931
                        ],
                        [
                            -90.7651650002923,
                            34.2805240003237
                        ],
                        [
                            -90.8029239998175,
                            34.2824650003199
                        ],
                        [
                            -90.8218709998888,
                            34.2774909998346
                        ],
                        [
                            -90.829663000138,
                            34.2724240001344
                        ],
                        [
                            -90.8329360001976,
                            34.2657370002509
                        ],
                        [
                            -90.838807999749,
                            34.2420960000966
                        ],
                        [
                            -90.8400089996731,
                            34.2230770001569
                        ],
                        [
                            -90.847807999595,
                            34.2065300000545
                        ],
                        [
                            -90.8567079998819,
                            34.2115979999356
                        ],
                        [
                            -90.8670609999881,
                            34.212144000057
                        ],
                        [
                            -90.8790850003717,
                            34.2154329997906
                        ],
                        [
                            -90.8944439998408,
                            34.2243260001609
                        ],
                        [
                            -90.9000879997866,
                            34.2296260001089
                        ],
                        [
                            -90.9042699996923,
                            34.2409699999051
                        ],
                        [
                            -90.9070809997945,
                            34.2444909999122
                        ],
                        [
                            -90.9231520003646,
                            34.2465310000287
                        ],
                        [
                            -90.9295229996833,
                            34.2441059997002
                        ],
                        [
                            -90.937042000121,
                            34.2353409999102
                        ],
                        [
                            -90.9352190004059,
                            34.2190430002076
                        ],
                        [
                            -90.9312750001516,
                            34.2130900000017
                        ],
                        [
                            -90.9141530003435,
                            34.1954839999056
                        ],
                        [
                            -90.887886999851,
                            34.1819820001784
                        ],
                        [
                            -90.8774829996912,
                            34.1856370000291
                        ],
                        [
                            -90.8738399997358,
                            34.1832249998378
                        ],
                        [
                            -90.8696629998529,
                            34.1829710001599
                        ],
                        [
                            -90.8555949998213,
                            34.1868819997478
                        ],
                        [
                            -90.8381950001789,
                            34.183799999765
                        ],
                        [
                            -90.8283890004189,
                            34.1847830000881
                        ],
                        [
                            -90.8165719997245,
                            34.1830229998636
                        ],
                        [
                            -90.8123739999253,
                            34.180766999654
                        ],
                        [
                            -90.8086849999391,
                            34.1758780001313
                        ],
                        [
                            -90.8071159997689,
                            34.1655900000399
                        ],
                        [
                            -90.8087359999927,
                            34.1594159999839
                        ],
                        [
                            -90.8158779999636,
                            34.1498790001514
                        ],
                        [
                            -90.825708000013,
                            34.1420110000946
                        ],
                        [
                            -90.836098999754,
                            34.1378759998513
                        ],
                        [
                            -90.8471680002607,
                            34.136884000012
                        ],
                        [
                            -90.8645799995987,
                            34.1405550003658
                        ],
                        [
                            -90.8830730001086,
                            34.1515020002
                        ],
                        [
                            -90.8943850002896,
                            34.1609530001533
                        ],
                        [
                            -90.9020250002028,
                            34.1644180000921
                        ],
                        [
                            -90.9100070004228,
                            34.1655019998377
                        ],
                        [
                            -90.9542989996483,
                            34.1384960002503
                        ],
                        [
                            -90.9593169996052,
                            34.1303489998964
                        ],
                        [
                            -90.9584670002086,
                            34.1251049999718
                        ],
                        [
                            -90.9551699998596,
                            34.1188329997275
                        ],
                        [
                            -90.9431670002907,
                            34.1071649999421
                        ],
                        [
                            -90.9183970001735,
                            34.0930549998528
                        ],
                        [
                            -90.9011299997021,
                            34.0946670002708
                        ],
                        [
                            -90.8935259997739,
                            34.0977950000451
                        ],
                        [
                            -90.8826229998468,
                            34.0966129998812
                        ],
                        [
                            -90.8766059996321,
                            34.0929109998225
                        ],
                        [
                            -90.8710539997479,
                            34.0850250000029
                        ],
                        [
                            -90.8705280002164,
                            34.0805159999809
                        ],
                        [
                            -90.8745410000677,
                            34.0720409996572
                        ],
                        [
                            -90.8878369996223,
                            34.0554030001791
                        ],
                        [
                            -90.8890579996379,
                            34.0463620002839
                        ],
                        [
                            -90.8870829995869,
                            34.0334000000551
                        ],
                        [
                            -90.8903890001567,
                            34.028222999709
                        ],
                        [
                            -90.8941509999379,
                            34.0259490002312
                        ],
                        [
                            -90.9120639995913,
                            34.0219980001152
                        ],
                        [
                            -90.9237449998432,
                            34.023143000119
                        ],
                        [
                            -90.9426619997773,
                            34.0180499998857
                        ],
                        [
                            -90.9503109999114,
                            34.0178219998607
                        ],
                        [
                            -90.9707259998705,
                            34.0216200001376
                        ],
                        [
                            -90.9879480001361,
                            34.0190380003634
                        ],
                        [
                            -90.9798360002197,
                            33.9976650000545
                        ],
                        [
                            -90.9710179999486,
                            33.9919559999856
                        ],
                        [
                            -90.9615510001223,
                            33.979946000365
                        ],
                        [
                            -90.9626299998477,
                            33.9703839998395
                        ],
                        [
                            -90.9676319999111,
                            33.9633239998498
                        ],
                        [
                            -90.9748020003693,
                            33.9607620000586
                        ],
                        [
                            -90.9833589996263,
                            33.9601859998605
                        ],
                        [
                            -90.9922410003698,
                            33.9619760002358
                        ],
                        [
                            -90.9983680001894,
                            33.9650209997343
                        ],
                        [
                            -91.0029860001132,
                            33.970901999666
                        ],
                        [
                            -91.0037590004151,
                            33.974935999903
                        ],
                        [
                            -91.0188900002787,
                            34.0031510000264
                        ],
                        [
                            -91.0337650000491,
                            33.9953230000649
                        ],
                        [
                            -91.0427509996515,
                            33.9868109998084
                        ],
                        [
                            -91.0483670000081,
                            33.9850780003174
                        ],
                        [
                            -91.0753780003152,
                            33.9835859996392
                        ],
                        [
                            -91.0831870002828,
                            33.9798649999878
                        ],
                        [
                            -91.0879209996593,
                            33.9753350000615
                        ],
                        [
                            -91.0897559999682,
                            33.9697210001276
                        ],
                        [
                            -91.0893490002625,
                            33.9632279997503
                        ],
                        [
                            -91.084095000304,
                            33.9561789998607
                        ],
                        [
                            -91.0359610000331,
                            33.9437579999157
                        ],
                        [
                            -91.0200970000506,
                            33.9371270001545
                        ],
                        [
                            -91.0103180000548,
                            33.9293520000603
                        ],
                        [
                            -91.0108310000658,
                            33.9255519997221
                        ],
                        [
                            -91.0263820000539,
                            33.9079800002118
                        ],
                        [
                            -91.0553089999624,
                            33.8830350001776
                        ],
                        [
                            -91.0708830004151,
                            33.8667139998979
                        ],
                        [
                            -91.0727980001917,
                            33.8623959998125
                        ],
                        [
                            -91.0726600000994,
                            33.8549430002169
                        ],
                        [
                            -91.067510999723,
                            33.8404429998542
                        ],
                        [
                            -91.0566920003598,
                            33.8289349997319
                        ],
                        [
                            -91.0468489998916,
                            33.8153650002792
                        ],
                        [
                            -91.0251729997338,
                            33.8059530001095
                        ],
                        [
                            -91.0077799997641,
                            33.8025940000332
                        ],
                        [
                            -90.9997840001987,
                            33.7991400000297
                        ],
                        [
                            -90.9917469997271,
                            33.7925969997473
                        ],
                        [
                            -90.9884660001699,
                            33.7845300000174
                        ],
                        [
                            -90.9912200003707,
                            33.7767909996354
                        ],
                        [
                            -91.0001060004139,
                            33.7691650002529
                        ],
                        [
                            -91.0127700001318,
                            33.7646750002286
                        ],
                        [
                            -91.0232849997214,
                            33.762990999786
                        ],
                        [
                            -91.026782000087,
                            33.7636420001512
                        ],
                        [
                            -91.0538860002805,
                            33.7787009998406
                        ],
                        [
                            -91.0855099995901,
                            33.7764100002775
                        ],
                        [
                            -91.1073179999924,
                            33.7706190003206
                        ],
                        [
                            -91.1234659996571,
                            33.7821060003208
                        ],
                        [
                            -91.132185000194,
                            33.7834199996697
                        ],
                        [
                            -91.1398689995898,
                            33.7771170001904
                        ],
                        [
                            -91.1451119996776,
                            33.7673400000049
                        ],
                        [
                            -91.144812000102,
                            33.7639959997301
                        ],
                        [
                            -91.1407559996946,
                            33.7597349999513
                        ],
                        [
                            -91.1448730002013,
                            33.7509650002542
                        ],
                        [
                            -91.1432870003127,
                            33.747140999686
                        ],
                        [
                            -91.146522999664,
                            33.7365030001814
                        ],
                        [
                            -91.1463510001349,
                            33.7306089997154
                        ],
                        [
                            -91.1423659998727,
                            33.7240350002699
                        ],
                        [
                            -91.1319540002153,
                            33.7139020001051
                        ],
                        [
                            -91.1255269999218,
                            33.7087800001334
                        ],
                        [
                            -91.1173930002642,
                            33.7052649999483
                        ],
                        [
                            -91.0968209999462,
                            33.7056540000073
                        ],
                        [
                            -91.0682899998727,
                            33.7164770000197
                        ],
                        [
                            -91.0598909999012,
                            33.7148160002966
                        ],
                        [
                            -91.0467779997465,
                            33.7063129997544
                        ],
                        [
                            -91.0390249998554,
                            33.6965950003241
                        ],
                        [
                            -91.0361200000418,
                            33.6891130001727
                        ],
                        [
                            -91.0304019995779,
                            33.6877660000055
                        ],
                        [
                            -91.030332000156,
                            33.6816220003586
                        ],
                        [
                            -91.0321890002063,
                            33.6765930003556
                        ],
                        [
                            -91.036839999742,
                            33.671315999853
                        ],
                        [
                            -91.0591820000717,
                            33.6664000002454
                        ],
                        [
                            -91.0785070004348,
                            33.658283000071
                        ],
                        [
                            -91.0866150001531,
                            33.6572769999967
                        ],
                        [
                            -91.0940399999812,
                            33.6583509999267
                        ],
                        [
                            -91.1321600000796,
                            33.6757490002256
                        ],
                        [
                            -91.1613310003857,
                            33.7072010001472
                        ],
                        [
                            -91.1725940001629,
                            33.7036059999234
                        ],
                        [
                            -91.2007119997845,
                            33.7015350000869
                        ],
                        [
                            -91.2103729997795,
                            33.6990219997943
                        ],
                        [
                            -91.22057000025,
                            33.6929229996847
                        ],
                        [
                            -91.2252790004104,
                            33.6877489996937
                        ],
                        [
                            -91.2290150002523,
                            33.6775429996829
                        ],
                        [
                            -91.2282279997066,
                            33.6713259998374
                        ],
                        [
                            -91.2190479999355,
                            33.6615029996605
                        ],
                        [
                            -91.2112840001737,
                            33.658525999693
                        ],
                        [
                            -91.1854550004226,
                            33.6536039997145
                        ],
                        [
                            -91.1745890003054,
                            33.6495259996349
                        ],
                        [
                            -91.1407249997325,
                            33.6268929999369
                        ],
                        [
                            -91.1343889996756,
                            33.619511999787
                        ],
                        [
                            -91.1304450003196,
                            33.6060339997015
                        ],
                        [
                            -91.1340430000692,
                            33.5944889999383
                        ],
                        [
                            -91.1521480002416,
                            33.5827210001203
                        ],
                        [
                            -91.1607549997272,
                            33.5813519996914
                        ],
                        [
                            -91.178219999667,
                            33.5826069999645
                        ],
                        [
                            -91.1982849998218,
                            33.572060999764
                        ],
                        [
                            -91.224121000144,
                            33.5673689998001
                        ],
                        [
                            -91.2314179999255,
                            33.5605930002907
                        ],
                        [
                            -91.2325979999333,
                            33.5552350003315
                        ],
                        [
                            -91.2310220000904,
                            33.5494619997023
                        ],
                        [
                            -91.221560000287,
                            33.5350049998012
                        ],
                        [
                            -91.2104210003584,
                            33.5255870002744
                        ],
                        [
                            -91.1873669998261,
                            33.5105509998759
                        ],
                        [
                            -91.183420000097,
                            33.5050619999532
                        ],
                        [
                            -91.1825649997793,
                            33.5010610001099
                        ],
                        [
                            -91.1833720004165,
                            33.4981570000437
                        ],
                        [
                            -91.1956339995531,
                            33.4884670000489
                        ],
                        [
                            -91.2085349999958,
                            33.4686059997695
                        ],
                        [
                            -91.2155079999122,
                            33.4647789998301
                        ],
                        [
                            -91.2233379997962,
                            33.4627639999369
                        ],
                        [
                            -91.2316609995998,
                            33.4570990000489
                        ],
                        [
                            -91.2334220002889,
                            33.4440379996931
                        ],
                        [
                            -91.2357999998477,
                            33.4397379996931
                        ],
                        [
                            -91.2173740001833,
                            33.434698999765
                        ],
                        [
                            -91.2068069998169,
                            33.4338459998777
                        ],
                        [
                            -91.1869790003869,
                            33.4385920003288
                        ],
                        [
                            -91.1772930002775,
                            33.4436380001248
                        ],
                        [
                            -91.169359999563,
                            33.4526290003307
                        ],
                        [
                            -91.1756350004189,
                            33.4717609998156
                        ],
                        [
                            -91.1770670003217,
                            33.4866309997777
                        ],
                        [
                            -91.1725389999113,
                            33.4963579999513
                        ],
                        [
                            -91.1675789996807,
                            33.4983089999828
                        ],
                        [
                            -91.1646000002473,
                            33.4978410001048
                        ],
                        [
                            -91.1366559998047,
                            33.4813230000428
                        ],
                        [
                            -91.1251090003454,
                            33.4728420003711
                        ],
                        [
                            -91.1179749998722,
                            33.4538059998721
                        ],
                        [
                            -91.1210999997938,
                            33.4435620002007
                        ],
                        [
                            -91.1285890000942,
                            33.4362840000352
                        ],
                        [
                            -91.13188499972,
                            33.43006200008
                        ],
                        [
                            -91.1385530000379,
                            33.427068000348
                        ],
                        [
                            -91.1448770003993,
                            33.4277060001913
                        ],
                        [
                            -91.1535809999693,
                            33.4256009999158
                        ],
                        [
                            -91.176279999776,
                            33.4169780001727
                        ],
                        [
                            -91.1844269998524,
                            33.4195759998243
                        ],
                        [
                            -91.1919730000543,
                            33.4177270001767
                        ],
                        [
                            -91.2000489999856,
                            33.4181370000445
                        ],
                        [
                            -91.2052719999819,
                            33.4142309999387
                        ],
                        [
                            -91.2087020004004,
                            33.4087180000164
                        ],
                        [
                            -91.2081130002214,
                            33.4020059997482
                        ],
                        [
                            -91.1911269999574,
                            33.3896339999599
                        ],
                        [
                            -91.171968000174,
                            33.3810299999927
                        ],
                        [
                            -91.1511609996793,
                            33.3786909997391
                        ],
                        [
                            -91.1426920002859,
                            33.3798300002977
                        ],
                        [
                            -91.1206780000194,
                            33.3885449997793
                        ],
                        [
                            -91.1134620004287,
                            33.3933239996912
                        ],
                        [
                            -91.09694200032,
                            33.4109899997923
                        ],
                        [
                            -91.0949110001925,
                            33.4186149999919
                        ],
                        [
                            -91.0974739996992,
                            33.4317330002085
                        ],
                        [
                            -91.0967230000369,
                            33.4376030001181
                        ],
                        [
                            -91.0942790003558,
                            33.4426700001785
                        ],
                        [
                            -91.0864979999773,
                            33.4515759997678
                        ],
                        [
                            -91.0778139996004,
                            33.4556469999526
                        ],
                        [
                            -91.067622999876,
                            33.4551039999166
                        ],
                        [
                            -91.059828000152,
                            33.4502570000115
                        ],
                        [
                            -91.0576210002974,
                            33.4453410001154
                        ],
                        [
                            -91.0581519998517,
                            33.4287050003544
                        ],
                        [
                            -91.068167999674,
                            33.4140079998455
                        ],
                        [
                            -91.0752929999264,
                            33.4059659998753
                        ],
                        [
                            -91.1017900003976,
                            33.3868929997147
                        ],
                        [
                            -91.1204090004058,
                            33.3638089999213
                        ],
                        [
                            -91.1422189995596,
                            33.3489889997597
                        ],
                        [
                            -91.1409680003051,
                            33.336491999728
                        ],
                        [
                            -91.1436670002543,
                            33.3283979996931
                        ],
                        [
                            -91.1414169998436,
                            33.315044000366
                        ],
                        [
                            -91.1414400003082,
                            33.2988680000382
                        ],
                        [
                            -91.1255389996174,
                            33.2802539998664
                        ],
                        [
                            -91.1255279997467,
                            33.2747320001219
                        ],
                        [
                            -91.1280779997331,
                            33.2685010002353
                        ],
                        [
                            -91.118208000399,
                            33.2620709998281
                        ],
                        [
                            -91.1105609999148,
                            33.2459299997714
                        ],
                        [
                            -91.1061420001826,
                            33.2417989999665
                        ],
                        [
                            -91.1000999998536,
                            33.2381239999834
                        ],
                        [
                            -91.0983919997662,
                            33.239079999877
                        ],
                        [
                            -91.0947479999858,
                            33.2504980003506
                        ],
                        [
                            -91.0904530001022,
                            33.2567989997137
                        ],
                        [
                            -91.0857079999568,
                            33.2747909999848
                        ],
                        [
                            -91.078530000001,
                            33.2833059996335
                        ],
                        [
                            -91.0670350004202,
                            33.2871799999554
                        ],
                        [
                            -91.0554510002527,
                            33.2862599998677
                        ],
                        [
                            -91.0481500002732,
                            33.282794999904
                        ],
                        [
                            -91.0438200002295,
                            33.2756060003252
                        ],
                        [
                            -91.0451910000329,
                            33.2654029998122
                        ],
                        [
                            -91.0504070003565,
                            33.2512019998683
                        ],
                        [
                            -91.0541259995816,
                            33.2461049998466
                        ],
                        [
                            -91.0637599998125,
                            33.2376980003736
                        ],
                        [
                            -91.0656289995583,
                            33.2329819997334
                        ],
                        [
                            -91.0691440003656,
                            33.232639000064
                        ],
                        [
                            -91.0710790002337,
                            33.2300959998524
                        ],
                        [
                            -91.0706969997439,
                            33.2273020002181
                        ],
                        [
                            -91.0776730000334,
                            33.2274849998621
                        ],
                        [
                            -91.0810249997357,
                            33.2224679999107
                        ],
                        [
                            -91.0917109999278,
                            33.2208130003662
                        ],
                        [
                            -91.0843659995674,
                            33.1808559999145
                        ],
                        [
                            -91.0850369997621,
                            33.1593389998492
                        ],
                        [
                            -91.0886329998619,
                            33.1422130003506
                        ],
                        [
                            -91.093200999557,
                            33.1367260000885
                        ],
                        [
                            -91.1043169999194,
                            33.1315969996331
                        ],
                        [
                            -91.1266700001197,
                            33.1290499999357
                        ],
                        [
                            -91.1503620003364,
                            33.1306950000743
                        ],
                        [
                            -91.1530150002549,
                            33.1350920000631
                        ],
                        [
                            -91.1625570004242,
                            33.1419889996712
                        ],
                        [
                            -91.1836619999464,
                            33.1416909998958
                        ],
                        [
                            -91.1931739999785,
                            33.1367330002561
                        ],
                        [
                            -91.2017799996392,
                            33.1251209999389
                        ],
                        [
                            -91.2014619996219,
                            33.1096370000352
                        ],
                        [
                            -91.2001669999864,
                            33.1069299996256
                        ],
                        [
                            -91.1808359997755,
                            33.0983630003559
                        ],
                        [
                            -91.1715139997142,
                            33.0878179996872
                        ],
                        [
                            -91.1498230003861,
                            33.0816029998169
                        ],
                        [
                            -91.1246389999921,
                            33.0641269999738
                        ],
                        [
                            -91.1211950002283,
                            33.0591649999838
                        ],
                        [
                            -91.1203790002686,
                            33.0545299998982
                        ],
                        [
                            -91.1235080003882,
                            33.0494869998642
                        ],
                        [
                            -91.1271129998104,
                            33.0359640000959
                        ],
                        [
                            -91.1374380003275,
                            33.0287359998597
                        ],
                        [
                            -91.1497580000888,
                            33.0263109996521
                        ],
                        [
                            -91.1623630002555,
                            33.0196830003179
                        ],
                        [
                            -91.1667100000176,
                            33.0096639998284
                        ],
                        [
                            -91.1660730001581,
                            33.0041059999842
                        ],
                        [
                            -91.5001180001397,
                            33.0067840003632
                        ],
                        [
                            -92.2221999996817,
                            33.0090899998208
                        ],
                        [
                            -93.0001600000632,
                            33.0176280001804
                        ],
                        [
                            -94.0429640002681,
                            33.0192189997448
                        ],
                        [
                            -94.0434499996166,
                            33.5522529997555
                        ],
                        [
                            -94.0501859997065,
                            33.5510760003125
                        ],
                        [
                            -94.0519180000833,
                            33.552640000046
                        ],
                        [
                            -94.0614769999711,
                            33.5500010002205
                        ],
                        [
                            -94.0729470003359,
                            33.5546699999956
                        ],
                        [
                            -94.0738140003492,
                            33.5576989998895
                        ],
                        [
                            -94.0709599996909,
                            33.5601399996681
                        ],
                        [
                            -94.0666840000739,
                            33.5609529999288
                        ],
                        [
                            -94.0611780002204,
                            33.5591579999729
                        ],
                        [
                            -94.0565800003881,
                            33.5609319996979
                        ],
                        [
                            -94.057085999828,
                            33.5680030002113
                        ],
                        [
                            -94.06627499982,
                            33.5687309997656
                        ],
                        [
                            -94.0685310000784,
                            33.5711030000587
                        ],
                        [
                            -94.0705970003661,
                            33.5767249997676
                        ],
                        [
                            -94.0700619997155,
                            33.5812939996299
                        ],
                        [
                            -94.0729389999399,
                            33.5847970002066
                        ],
                        [
                            -94.0752560002978,
                            33.5825099998053
                        ],
                        [
                            -94.0720080003525,
                            33.5762950000038
                        ],
                        [
                            -94.072825999962,
                            33.5722979996493
                        ],
                        [
                            -94.0823520002378,
                            33.5757199998789
                        ],
                        [
                            -94.0889369998168,
                            33.575333999639
                        ],
                        [
                            -94.0974410002686,
                            33.5737440001831
                        ],
                        [
                            -94.1128430002937,
                            33.5669909997435
                        ],
                        [
                            -94.1208599997755,
                            33.5670509997085
                        ],
                        [
                            -94.1207369997519,
                            33.5605609997678
                        ],
                        [
                            -94.1227360000924,
                            33.5538600002293
                        ],
                        [
                            -94.1254679996536,
                            33.5511379997476
                        ],
                        [
                            -94.1290019998291,
                            33.5510359999289
                        ],
                        [
                            -94.1312079998588,
                            33.553092000152
                        ],
                        [
                            -94.1351439997172,
                            33.571033000262
                        ],
                        [
                            -94.1452180001641,
                            33.5649790003294
                        ],
                        [
                            -94.1515740003125,
                            33.5684060002285
                        ],
                        [
                            -94.1513440001587,
                            33.5713889999432
                        ],
                        [
                            -94.1430239998299,
                            33.5777249997415
                        ],
                        [
                            -94.1421600001897,
                            33.5813899998138
                        ],
                        [
                            -94.1460480003675,
                            33.5819750002162
                        ],
                        [
                            -94.1526260002737,
                            33.5759230003109
                        ],
                        [
                            -94.1567749996692,
                            33.5757619998784
                        ],
                        [
                            -94.1620060000614,
                            33.5808840001998
                        ],
                        [
                            -94.1610810003217,
                            33.5879729997831
                        ],
                        [
                            -94.1622660003523,
                            33.5889060001292
                        ],
                        [
                            -94.176326999813,
                            33.5910769997167
                        ],
                        [
                            -94.1839120003729,
                            33.594685000282
                        ],
                        [
                            -94.1964349996255,
                            33.5812929997834
                        ],
                        [
                            -94.2038429997351,
                            33.5797730003355
                        ],
                        [
                            -94.2130069996127,
                            33.5834819998752
                        ],
                        [
                            -94.2171140000736,
                            33.5809940002381
                        ],
                        [
                            -94.2162079997022,
                            33.5765690003376
                        ],
                        [
                            -94.211244000172,
                            33.5737379998303
                        ],
                        [
                            -94.201587000375,
                            33.5773480000147
                        ],
                        [
                            -94.1941270003868,
                            33.573468999926
                        ],
                        [
                            -94.1896600000757,
                            33.5618070000007
                        ],
                        [
                            -94.1914719999202,
                            33.5571830001398
                        ],
                        [
                            -94.195432000068,
                            33.5550940002572
                        ],
                        [
                            -94.2004480003751,
                            33.5570780003398
                        ],
                        [
                            -94.2035940002131,
                            33.5665339996771
                        ],
                        [
                            -94.2056660003485,
                            33.5672189999876
                        ],
                        [
                            -94.2136250001039,
                            33.5631320002646
                        ],
                        [
                            -94.2192099996002,
                            33.5560960002674
                        ],
                        [
                            -94.2261880004378,
                            33.552976000198
                        ],
                        [
                            -94.2375750000635,
                            33.5526689999748
                        ],
                        [
                            -94.2501300000015,
                            33.5567530003642
                        ],
                        [
                            -94.252157999756,
                            33.5604960000962
                        ],
                        [
                            -94.251099000122,
                            33.5658509999715
                        ],
                        [
                            -94.2443499996133,
                            33.573570000261
                        ],
                        [
                            -94.2388660003994,
                            33.5767479996981
                        ],
                        [
                            -94.2368329997237,
                            33.5809179996278
                        ],
                        [
                            -94.236970999816,
                            33.5874069998648
                        ],
                        [
                            -94.2427770001434,
                            33.589708999909
                        ],
                        [
                            -94.2567940001214,
                            33.583605000247
                        ],
                        [
                            -94.265669000294,
                            33.5735889997921
                        ],
                        [
                            -94.2713480003999,
                            33.5620940000556
                        ],
                        [
                            -94.2782600002169,
                            33.5575559998512
                        ],
                        [
                            -94.2859400003131,
                            33.5562289998575
                        ],
                        [
                            -94.2908970001706,
                            33.5599059996304
                        ],
                        [
                            -94.2901270002417,
                            33.5681110001999
                        ],
                        [
                            -94.2806050001638,
                            33.5749080001123
                        ],
                        [
                            -94.2835819999474,
                            33.5818910002564
                        ],
                        [
                            -94.2892150000225,
                            33.5821310003522
                        ],
                        [
                            -94.2949689995731,
                            33.5791249998486
                        ],
                        [
                            -94.3010229995977,
                            33.57302099995
                        ],
                        [
                            -94.3071820001026,
                            33.5597979998598
                        ],
                        [
                            -94.3064110003488,
                            33.5555980002761
                        ],
                        [
                            -94.3121499998308,
                            33.5506660003684
                        ],
                        [
                            -94.3212660000278,
                            33.5490299999847
                        ],
                        [
                            -94.3312020003824,
                            33.552951000323
                        ],
                        [
                            -94.3332030003727,
                            33.5553660002049
                        ],
                        [
                            -94.3355240000302,
                            33.5644840000309
                        ],
                        [
                            -94.3395619999959,
                            33.5676680001361
                        ],
                        [
                            -94.3440229995608,
                            33.5678239999329
                        ],
                        [
                            -94.3524180002327,
                            33.5621930000881
                        ],
                        [
                            -94.3472930001457,
                            33.5521519999399
                        ],
                        [
                            -94.3489450001565,
                            33.5483579998742
                        ],
                        [
                            -94.3535930002176,
                            33.5440049998548
                        ],
                        [
                            -94.3577910000168,
                            33.5430499997647
                        ],
                        [
                            -94.3698749997765,
                            33.5456610002405
                        ],
                        [
                            -94.3751999998801,
                            33.544307000289
                        ],
                        [
                            -94.3861360003174,
                            33.5448100000752
                        ],
                        [
                            -94.3895299998525,
                            33.546738999666
                        ],
                        [
                            -94.3923879998104,
                            33.5511949996599
                        ],
                        [
                            -94.3970790004274,
                            33.553617999809
                        ],
                        [
                            -94.4016160001604,
                            33.5593779998841
                        ],
                        [
                            -94.4004379998024,
                            33.5629350000527
                        ],
                        [
                            -94.3948509997581,
                            33.5665210001967
                        ],
                        [
                            -94.3880329996523,
                            33.5655179998393
                        ],
                        [
                            -94.3800799997447,
                            33.5689470000317
                        ],
                        [
                            -94.3777559997142,
                            33.5746100000757
                        ],
                        [
                            -94.3796739998638,
                            33.5806310000286
                        ],
                        [
                            -94.3828980004179,
                            33.5832770003627
                        ],
                        [
                            -94.4033419997911,
                            33.568424000152
                        ],
                        [
                            -94.4121950002224,
                            33.5687079999445
                        ],
                        [
                            -94.4300409999286,
                            33.5911239998854
                        ],
                        [
                            -94.4389789998504,
                            33.5936199998798
                        ],
                        [
                            -94.4422140002751,
                            33.5912760002713
                        ],
                        [
                            -94.4531969996698,
                            33.5917800001072
                        ],
                        [
                            -94.4582320002434,
                            33.5982700001546
                        ],
                        [
                            -94.45743899985,
                            33.6004120001635
                        ],
                        [
                            -94.4623019999962,
                            33.5983790000775
                        ],
                        [
                            -94.4675869999167,
                            33.5988419999856
                        ],
                        [
                            -94.4724430003902,
                            33.6034689997538
                        ],
                        [
                            -94.4694509996397,
                            33.6073159997179
                        ],
                        [
                            -94.4527010002756,
                            33.6173729998082
                        ],
                        [
                            -94.4527110003213,
                            33.6226209998513
                        ],
                        [
                            -94.4544899996555,
                            33.6257420001434
                        ],
                        [
                            -94.4602740002417,
                            33.6244149999031
                        ],
                        [
                            -94.4627360003644,
                            33.630909999809
                        ],
                        [
                            -94.448438000179,
                            33.6344980002767
                        ],
                        [
                            -94.4468709996587,
                            33.6401780000464
                        ],
                        [
                            -94.4482239999188,
                            33.6424020000094
                        ],
                        [
                            -94.4581390003571,
                            33.6453299996461
                        ],
                        [
                            -94.4614530004245,
                            33.6436160002029
                        ],
                        [
                            -94.4660749996479,
                            33.6362620003471
                        ],
                        [
                            -94.4764229997312,
                            33.6389490003544
                        ],
                        [
                            -94.4858779998619,
                            33.6378649997599
                        ],
                        [
                            -94.4576120001023,
                            34.6430019998755
                        ],
                        [
                            -94.4377689997054,
                            35.2402890001223
                        ],
                        [
                            -94.4344160001782,
                            35.2979819998999
                        ],
                        [
                            -94.4322489996082,
                            35.3630299999631
                        ],
                        [
                            -94.433215000254,
                            35.3657910002557
                        ],
                        [
                            -94.4315959998551,
                            35.3695379997342
                        ],
                        [
                            -94.4355900003382,
                            35.3861220002189
                        ],
                        [
                            -94.4310100000492,
                            35.3925140001249
                        ],
                        [
                            -94.5521800000544,
                            36.1022360001623
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 34.8955256,
            "name": "Arkansas",
            "intptlon": -92.4446262,
            "geo_point_2d": [
                34.899695632,
                -92.4393557454
            ],
            "geoid": "05",
            "mtfcc": "G4000",
            "region": 3
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -92.4393557454,
                34.899695632
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "a36e82b7f224f07dd22b0e25961ad401ceb80575",
        "fields": {
            "arealand": "2136109036",
            "objectid": 1,
            "basename": "West Virginia",
            "stusab": "WV",
            "statens": "01779805",
            "centlon": -80.6234555,
            "state": "54",
            "gid": 35,
            "centlat": 38.641186,
            "division": 5,
            "areawater": "489848791",
            "oid": "-1929409300",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -81.7472539997449,
                            39.0953790000815
                        ],
                        [
                            -81.743854000362,
                            39.102378000062
                        ],
                        [
                            -81.7423529999639,
                            39.1112769998991
                        ],
                        [
                            -81.7451940002034,
                            39.138239000096
                        ],
                        [
                            -81.7443949999621,
                            39.1437399998329
                        ],
                        [
                            -81.743117000045,
                            39.1413250003224
                        ],
                        [
                            -81.7427649996926,
                            39.142669000326
                        ],
                        [
                            -81.7542409999051,
                            39.1710790002773
                        ],
                        [
                            -81.7561799999711,
                            39.1804599996549
                        ],
                        [
                            -81.7512060003952,
                            39.1860429998448
                        ],
                        [
                            -81.7414850001257,
                            39.1892000003152
                        ],
                        [
                            -81.7368739998746,
                            39.1926280000212
                        ],
                        [
                            -81.7345099996611,
                            39.1961450003365
                        ],
                        [
                            -81.7326769999003,
                            39.205481000037
                        ],
                        [
                            -81.7256219999681,
                            39.2159050000472
                        ],
                        [
                            -81.7009079999273,
                            39.2208439999632
                        ],
                        [
                            -81.691914999754,
                            39.2270349998211
                        ],
                        [
                            -81.6918670000734,
                            39.2348429998568
                        ],
                        [
                            -81.6962520003687,
                            39.2442989998682
                        ],
                        [
                            -81.6968440000224,
                            39.2534829996784
                        ],
                        [
                            -81.6961879998963,
                            39.2574829997063
                        ],
                        [
                            -81.6904910002471,
                            39.265083000332
                        ],
                        [
                            -81.6775949998273,
                            39.2741709999663
                        ],
                        [
                            -81.6561379999526,
                            39.2773549998804
                        ],
                        [
                            -81.6431779999587,
                            39.2771950003325
                        ],
                        [
                            -81.6213050001574,
                            39.27364299972
                        ],
                        [
                            -81.6058160000936,
                            39.2758510001348
                        ],
                        [
                            -81.5951600000388,
                            39.2733869999175
                        ],
                        [
                            -81.5855590003182,
                            39.2687469996928
                        ],
                        [
                            -81.5702469998064,
                            39.267674999659
                        ],
                        [
                            -81.5652470002912,
                            39.276175000014
                        ],
                        [
                            -81.5650470002747,
                            39.2938739998105
                        ],
                        [
                            -81.5575470001035,
                            39.338773999765
                        ],
                        [
                            -81.534470000005,
                            39.3582340000327
                        ],
                        [
                            -81.5168369998358,
                            39.3652259998986
                        ],
                        [
                            -81.4987889995648,
                            39.3761220001949
                        ],
                        [
                            -81.4677350001675,
                            39.4037789997873
                        ],
                        [
                            -81.4561429996041,
                            39.4092740000025
                        ],
                        [
                            -81.4465429997084,
                            39.4103739999968
                        ],
                        [
                            -81.4356420003295,
                            39.4084740002756
                        ],
                        [
                            -81.4205780004131,
                            39.4003779999633
                        ],
                        [
                            -81.4066889995833,
                            39.3880899999885
                        ],
                        [
                            -81.3937939998868,
                            39.3517059998433
                        ],
                        [
                            -81.3845560003894,
                            39.3434489998727
                        ],
                        [
                            -81.375960999701,
                            39.3416969996944
                        ],
                        [
                            -81.3569109996975,
                            39.3431779998719
                        ],
                        [
                            -81.3449270003952,
                            39.3467939998677
                        ],
                        [
                            -81.3017700000733,
                            39.3722279996779
                        ],
                        [
                            -81.2814039996196,
                            39.379259000189
                        ],
                        [
                            -81.2702920003534,
                            39.3860469999358
                        ],
                        [
                            -81.2597860000864,
                            39.3866990002955
                        ],
                        [
                            -81.2503800003595,
                            39.390106000294
                        ],
                        [
                            -81.2443589999469,
                            39.3904100001187
                        ],
                        [
                            -81.2235809997646,
                            39.3860620002255
                        ],
                        [
                            -81.2193860003384,
                            39.3868090001603
                        ],
                        [
                            -81.2116540003636,
                            39.3929770000851
                        ],
                        [
                            -81.2114330004307,
                            39.4020309999223
                        ],
                        [
                            -81.2082309996179,
                            39.4071469999825
                        ],
                        [
                            -81.1907139997996,
                            39.423562000174
                        ],
                        [
                            -81.1851450001969,
                            39.4314790000981
                        ],
                        [
                            -81.1675930002185,
                            39.4400640002995
                        ],
                        [
                            -81.152534000325,
                            39.4431750002838
                        ],
                        [
                            -81.1381340000324,
                            39.4437750000273
                        ],
                        [
                            -81.1325339995692,
                            39.4462750000866
                        ],
                        [
                            -81.1247329999975,
                            39.453374999722
                        ],
                        [
                            -81.115232999661,
                            39.4664749996695
                        ],
                        [
                            -81.1147330000688,
                            39.4655749999123
                        ],
                        [
                            -81.1008340000916,
                            39.4871760000436
                        ],
                        [
                            -81.0919360003528,
                            39.4965730001102
                        ],
                        [
                            -81.0816209998815,
                            39.5040919997277
                        ],
                        [
                            -81.0710250001011,
                            39.5156080002812
                        ],
                        [
                            -81.055986000299,
                            39.5258959998573
                        ],
                        [
                            -81.0449019997237,
                            39.5363000000675
                        ],
                        [
                            -81.0268560000009,
                            39.5474969999935
                        ],
                        [
                            -81.0200549996136,
                            39.5554099999939
                        ],
                        [
                            -80.9936950003081,
                            39.571253000155
                        ],
                        [
                            -80.9734829998402,
                            39.5884460000202
                        ],
                        [
                            -80.9359350002022,
                            39.6132270000293
                        ],
                        [
                            -80.9283460003426,
                            39.6165539997806
                        ],
                        [
                            -80.9176199999676,
                            39.6187029998345
                        ],
                        [
                            -80.8922079999679,
                            39.6167560000336
                        ],
                        [
                            -80.8803600002097,
                            39.6207060003132
                        ],
                        [
                            -80.8754309999413,
                            39.6283609999286
                        ],
                        [
                            -80.8710199997068,
                            39.6389629998854
                        ],
                        [
                            -80.8698010002393,
                            39.6468950001743
                        ],
                        [
                            -80.8666460001805,
                            39.6526159997826
                        ],
                        [
                            -80.8652240003233,
                            39.6655090000978
                        ],
                        [
                            -80.8663299998129,
                            39.683167000116
                        ],
                        [
                            -80.8636979998109,
                            39.6917239998151
                        ],
                        [
                            -80.8519999998405,
                            39.6985599996902
                        ],
                        [
                            -80.8415470001752,
                            39.7002289997224
                        ],
                        [
                            -80.8338820001477,
                            39.7034970001295
                        ],
                        [
                            -80.8297639998161,
                            39.7118389997798
                        ],
                        [
                            -80.8315510004445,
                            39.719475000123
                        ],
                        [
                            -80.847448000039,
                            39.7380910000502
                        ],
                        [
                            -80.8459600000596,
                            39.7386669997862
                        ],
                        [
                            -80.8527379999824,
                            39.7410400002857
                        ],
                        [
                            -80.8653389999511,
                            39.7532509996806
                        ],
                        [
                            -80.8685700001779,
                            39.7623989997031
                        ],
                        [
                            -80.8703219997479,
                            39.7631840002612
                        ],
                        [
                            -80.8690920004097,
                            39.7663639998918
                        ],
                        [
                            -80.8630480004309,
                            39.775196999845
                        ],
                        [
                            -80.8353109996775,
                            39.7906900002461
                        ],
                        [
                            -80.8260790000278,
                            39.7985839996985
                        ],
                        [
                            -80.8221809998043,
                            39.8117079998981
                        ],
                        [
                            -80.822479999555,
                            39.8249709997686
                        ],
                        [
                            -80.8262279999907,
                            39.835802000316
                        ],
                        [
                            -80.8261240002337,
                            39.8442380000806
                        ],
                        [
                            -80.8212789996308,
                            39.8499820001088
                        ],
                        [
                            -80.7998979999241,
                            39.8589119998274
                        ],
                        [
                            -80.793130999872,
                            39.8637509998574
                        ],
                        [
                            -80.7907609998108,
                            39.867280000331
                        ],
                        [
                            -80.7901559997383,
                            39.8722520001339
                        ],
                        [
                            -80.7939900003877,
                            39.8827829996718
                        ],
                        [
                            -80.8029209997385,
                            39.8925240003108
                        ],
                        [
                            -80.8091739999547,
                            39.9039359998405
                        ],
                        [
                            -80.8090450000833,
                            39.9112520000568
                        ],
                        [
                            -80.8067760003044,
                            39.9163080000002
                        ],
                        [
                            -80.7996850003871,
                            39.9195799999544
                        ],
                        [
                            -80.7870380003877,
                            39.9183550002048
                        ],
                        [
                            -80.7708010001363,
                            39.9103500003031
                        ],
                        [
                            -80.7633989998744,
                            39.9086189996773
                        ],
                        [
                            -80.7585979996525,
                            39.909478999862
                        ],
                        [
                            -80.7561129999635,
                            39.9134020002989
                        ],
                        [
                            -80.756357000361,
                            39.9201729997488
                        ],
                        [
                            -80.7613140002185,
                            39.9301439998775
                        ],
                        [
                            -80.7642750001086,
                            39.9499630002359
                        ],
                        [
                            -80.7629870001458,
                            39.9537849999358
                        ],
                        [
                            -80.7579220003332,
                            39.9597070002217
                        ],
                        [
                            -80.7401390003761,
                            39.9707949996668
                        ],
                        [
                            -80.7410339999785,
                            39.9731520001419
                        ],
                        [
                            -80.7388830002004,
                            39.9855830001989
                        ],
                        [
                            -80.7421699996054,
                            40.0085750000804
                        ],
                        [
                            -80.7377319996066,
                            40.0220569999035
                        ],
                        [
                            -80.7371509998236,
                            40.0285379998938
                        ],
                        [
                            -80.7319339996751,
                            40.0370009999791
                        ],
                        [
                            -80.7310240000041,
                            40.0442910001428
                        ],
                        [
                            -80.7333270001182,
                            40.0586350000123
                        ],
                        [
                            -80.73816500015,
                            40.0665340001355
                        ],
                        [
                            -80.7392360003778,
                            40.0746230002338
                        ],
                        [
                            -80.7381810000435,
                            40.0786069997943
                        ],
                        [
                            -80.7313000001886,
                            40.0864299996888
                        ],
                        [
                            -80.7095450003881,
                            40.1003019999377
                        ],
                        [
                            -80.7070949999609,
                            40.1111030002034
                        ],
                        [
                            -80.7106700001443,
                            40.125549999999
                        ],
                        [
                            -80.7101660003541,
                            40.1383150000091
                        ],
                        [
                            -80.7056110001795,
                            40.1534759997999
                        ],
                        [
                            -80.686080999777,
                            40.1816369999107
                        ],
                        [
                            -80.6725819998328,
                            40.1924010001411
                        ],
                        [
                            -80.6680829997348,
                            40.1996890003093
                        ],
                        [
                            -80.6615399999886,
                            40.2298119998121
                        ],
                        [
                            -80.6521009997514,
                            40.2449749998824
                        ],
                        [
                            -80.6446030001284,
                            40.2512759998834
                        ],
                        [
                            -80.622507999671,
                            40.2617650003201
                        ],
                        [
                            -80.619297000434,
                            40.265169999738
                        ],
                        [
                            -80.6162090003223,
                            40.2722709997664
                        ],
                        [
                            -80.6167929995801,
                            40.2852779998878
                        ],
                        [
                            -80.6148860001994,
                            40.2919710003037
                        ],
                        [
                            -80.6028950003261,
                            40.3070689997488
                        ],
                        [
                            -80.599895000078,
                            40.3176659996742
                        ],
                        [
                            -80.6028950003261,
                            40.3278680000215
                        ],
                        [
                            -80.6107960003553,
                            40.3408680002171
                        ],
                        [
                            -80.6127949997974,
                            40.3476690003322
                        ],
                        [
                            -80.6075869998698,
                            40.3695599996642
                        ],
                        [
                            -80.6096859997694,
                            40.3749499996944
                        ],
                        [
                            -80.6191020004403,
                            40.3818619998589
                        ],
                        [
                            -80.6313920000644,
                            40.3856960000726
                        ],
                        [
                            -80.6327319999057,
                            40.390504999762
                        ],
                        [
                            -80.6321960003285,
                            40.3936669998399
                        ],
                        [
                            -80.6161010003673,
                            40.4009959999157
                        ],
                        [
                            -80.6151949999959,
                            40.3998670002397
                        ],
                        [
                            -80.6123250003425,
                            40.4027619996944
                        ],
                        [
                            -80.6142509999897,
                            40.4087100002934
                        ],
                        [
                            -80.6125470001003,
                            40.411672999975
                        ],
                        [
                            -80.6121949997478,
                            40.4348380001938
                        ],
                        [
                            -80.5959530003718,
                            40.4633540000985
                        ],
                        [
                            -80.5954050002007,
                            40.4753159999171
                        ],
                        [
                            -80.599193999746,
                            40.4825659998563
                        ],
                        [
                            -80.6087379995652,
                            40.4896110000218
                        ],
                        [
                            -80.6136500001152,
                            40.4957480002893
                        ],
                        [
                            -80.6157309995731,
                            40.4990100000775
                        ],
                        [
                            -80.6136030002595,
                            40.4985939997741
                        ],
                        [
                            -80.615442999693,
                            40.5018870000828
                        ],
                        [
                            -80.6180029997251,
                            40.5020489997014
                        ],
                        [
                            -80.6275070002596,
                            40.5357929998714
                        ],
                        [
                            -80.6331069998244,
                            40.5387049996743
                        ],
                        [
                            -80.6524360003855,
                            40.5625440002231
                        ],
                        [
                            -80.6643720000072,
                            40.5700160002018
                        ],
                        [
                            -80.6626599997219,
                            40.569951999926
                        ],
                        [
                            -80.6669169999707,
                            40.5736640000407
                        ],
                        [
                            -80.6678130002963,
                            40.5835359996855
                        ],
                        [
                            -80.663540000154,
                            40.590768000031
                        ],
                        [
                            -80.651715999787,
                            40.5977439998782
                        ],
                        [
                            -80.6343549996043,
                            40.6160949996711
                        ],
                        [
                            -80.6271709998006,
                            40.619936000164
                        ],
                        [
                            -80.5987639995757,
                            40.6252629996649
                        ],
                        [
                            -80.5920490003005,
                            40.6224959996834
                        ],
                        [
                            -80.5835919997043,
                            40.6155019997618
                        ],
                        [
                            -80.5767040001767,
                            40.614222999669
                        ],
                        [
                            -80.567613999919,
                            40.6176779999226
                        ],
                        [
                            -80.5606900004063,
                            40.6237080002676
                        ],
                        [
                            -80.5511259995974,
                            40.6288470002631
                        ],
                        [
                            -80.5327369997429,
                            40.6355900003209
                        ],
                        [
                            -80.5256600000694,
                            40.6360679997011
                        ],
                        [
                            -80.5189909999265,
                            40.638801000148
                        ],
                        [
                            -80.5190379997822,
                            40.4773630002102
                        ],
                        [
                            -80.5176889997201,
                            40.4622169997867
                        ],
                        [
                            -80.5192150002325,
                            40.2683460003262
                        ],
                        [
                            -80.5193419995558,
                            39.7214029999813
                        ],
                        [
                            -79.4766620000978,
                            39.7210780003069
                        ],
                        [
                            -79.4765609998154,
                            39.6427379996742
                        ],
                        [
                            -79.4777640002877,
                            39.6422819999287
                        ],
                        [
                            -79.4788659995794,
                            39.5316890003266
                        ],
                        [
                            -79.482366000318,
                            39.5316890003266
                        ],
                        [
                            -79.4843530000646,
                            39.3450440002298
                        ],
                        [
                            -79.4860720000226,
                            39.3442999999049
                        ],
                        [
                            -79.4876510002386,
                            39.2799329998469
                        ],
                        [
                            -79.4872739997717,
                            39.2652049998098
                        ],
                        [
                            -79.4858739996559,
                            39.2649050003253
                        ],
                        [
                            -79.4868749995635,
                            39.2058960001089
                        ],
                        [
                            -79.4729489998221,
                            39.2021379996873
                        ],
                        [
                            -79.4697929999384,
                            39.2037340001347
                        ],
                        [
                            -79.4696039997925,
                            39.2072160000437
                        ],
                        [
                            -79.4673869998921,
                            39.2087650000769
                        ],
                        [
                            -79.460555000441,
                            39.2115559999218
                        ],
                        [
                            -79.4533039997919,
                            39.2104699996547
                        ],
                        [
                            -79.4474239998446,
                            39.2156819999402
                        ],
                        [
                            -79.4406469997467,
                            39.2165060002105
                        ],
                        [
                            -79.4329840002673,
                            39.2238870003438
                        ],
                        [
                            -79.425713000425,
                            39.225314000279
                        ],
                        [
                            -79.4240539998431,
                            39.2273980000766
                        ],
                        [
                            -79.4252210003304,
                            39.2344770002367
                        ],
                        [
                            -79.4206800003995,
                            39.2354240001199
                        ],
                        [
                            -79.4202610000999,
                            39.238882999844
                        ],
                        [
                            -79.4122169999556,
                            39.2401930002565
                        ],
                        [
                            -79.4083349996256,
                            39.2450699998959
                        ],
                        [
                            -79.4094519998841,
                            39.2461990002631
                        ],
                        [
                            -79.3996650003907,
                            39.2515049997704
                        ],
                        [
                            -79.3983880002986,
                            39.2534079999069
                        ],
                        [
                            -79.3995599999104,
                            39.2550419998382
                        ],
                        [
                            -79.3950039999109,
                            39.257074000005
                        ],
                        [
                            -79.3932849999529,
                            39.2616270002628
                        ],
                        [
                            -79.3902579999406,
                            39.2613639998134
                        ],
                        [
                            -79.3873759996933,
                            39.2635489997676
                        ],
                        [
                            -79.3873380000584,
                            39.2678200001451
                        ],
                        [
                            -79.3818570003193,
                            39.2668650000824
                        ],
                        [
                            -79.3788090003906,
                            39.2718179998977
                        ],
                        [
                            -79.3760140001819,
                            39.2732170002327
                        ],
                        [
                            -79.35752499987,
                            39.2763050000895
                        ],
                        [
                            -79.3522960000259,
                            39.2802020003157
                        ],
                        [
                            -79.3513079996387,
                            39.2850710002978
                        ],
                        [
                            -79.3449149996804,
                            39.2849690003036
                        ],
                        [
                            -79.34365600003,
                            39.287117999661
                        ],
                        [
                            -79.3473890003971,
                            39.2911020001317
                        ],
                        [
                            -79.3460110000226,
                            39.2939599997308
                        ],
                        [
                            -79.3403749995745,
                            39.2936710000082
                        ],
                        [
                            -79.3326469997977,
                            39.3001299998673
                        ],
                        [
                            -79.3262460003418,
                            39.3010770000226
                        ],
                        [
                            -79.3212650001948,
                            39.3000049997529
                        ],
                        [
                            -79.3170470003041,
                            39.3040380002157
                        ],
                        [
                            -79.3139440001238,
                            39.3047060000317
                        ],
                        [
                            -79.3034310001841,
                            39.2996229999331
                        ],
                        [
                            -79.2903950000222,
                            39.2994520003283
                        ],
                        [
                            -79.2901609996705,
                            39.3035160001468
                        ],
                        [
                            -79.2838840000632,
                            39.3098439997822
                        ],
                        [
                            -79.2842260003699,
                            39.3154820002402
                        ],
                        [
                            -79.2821319995949,
                            39.323273000335
                        ],
                        [
                            -79.270425000302,
                            39.3288820003071
                        ],
                        [
                            -79.2686800004047,
                            39.3361630002696
                        ],
                        [
                            -79.2601740003031,
                            39.3342969997535
                        ],
                        [
                            -79.2538499999418,
                            39.3371439997439
                        ],
                        [
                            -79.25735399998,
                            39.3419069999884
                        ],
                        [
                            -79.2534800000459,
                            39.347895999772
                        ],
                        [
                            -79.2568679997332,
                            39.3553929998333
                        ],
                        [
                            -79.2563079998666,
                            39.3572270003217
                        ],
                        [
                            -79.2506010001716,
                            39.3554719996619
                        ],
                        [
                            -79.2426099997308,
                            39.359415000337
                        ],
                        [
                            -79.2349630001449,
                            39.3586769999045
                        ],
                        [
                            -79.2329189995986,
                            39.3596219996765
                        ],
                        [
                            -79.2309550003165,
                            39.364904999963
                        ],
                        [
                            -79.2142800003971,
                            39.3634820000151
                        ],
                        [
                            -79.2125849998302,
                            39.3702480001521
                        ],
                        [
                            -79.2024440003345,
                            39.378801999761
                        ],
                        [
                            -79.1976909997932,
                            39.3883729998658
                        ],
                        [
                            -79.1933030000231,
                            39.3886319997168
                        ],
                        [
                            -79.1855690003986,
                            39.3852079998898
                        ],
                        [
                            -79.1769589996416,
                            39.3890279999463
                        ],
                        [
                            -79.1811280000268,
                            39.3943939999993
                        ],
                        [
                            -79.1791839999379,
                            39.3964949998978
                        ],
                        [
                            -79.1745690003872,
                            39.3953790003349
                        ],
                        [
                            -79.1660649999353,
                            39.3874429996621
                        ],
                        [
                            -79.1622710003672,
                            39.3881290002875
                        ],
                        [
                            -79.1614779999737,
                            39.3909020000888
                        ],
                        [
                            -79.1654480001673,
                            39.3954720000548
                        ],
                        [
                            -79.1667050001679,
                            39.4009429999672
                        ],
                        [
                            -79.1645119996587,
                            39.4056789997155
                        ],
                        [
                            -79.159415999884,
                            39.4095430000619
                        ],
                        [
                            -79.1594660001127,
                            39.4170360001824
                        ],
                        [
                            -79.1553500003293,
                            39.4182989998002
                        ],
                        [
                            -79.1512410002185,
                            39.4169679996554
                        ],
                        [
                            -79.1495590000704,
                            39.4137270003435
                        ],
                        [
                            -79.149979000195,
                            39.4060770000573
                        ],
                        [
                            -79.1467339997245,
                            39.4045399998014
                        ],
                        [
                            -79.1437510000931,
                            39.4052020001799
                        ],
                        [
                            -79.1411380003576,
                            39.4086820000741
                        ],
                        [
                            -79.1418429999892,
                            39.4170979997273
                        ],
                        [
                            -79.1364989996191,
                            39.4188869999175
                        ],
                        [
                            -79.1298260001766,
                            39.4173030000688
                        ],
                        [
                            -79.1274419998716,
                            39.4181770000586
                        ],
                        [
                            -79.1290470000269,
                            39.4295420000678
                        ],
                        [
                            -79.1240359997427,
                            39.4332040002407
                        ],
                        [
                            -79.1127319999576,
                            39.430853999753
                        ],
                        [
                            -79.110821000379,
                            39.4318540001119
                        ],
                        [
                            -79.1121200002124,
                            39.4355109999352
                        ],
                        [
                            -79.1168600003349,
                            39.4388579999585
                        ],
                        [
                            -79.1138879996759,
                            39.4435209999922
                        ],
                        [
                            -79.1077779995748,
                            39.4458960001244
                        ],
                        [
                            -79.1028159996945,
                            39.4504820000436
                        ],
                        [
                            -79.101805999566,
                            39.4571650002247
                        ],
                        [
                            -79.1083299999439,
                            39.4571240000773
                        ],
                        [
                            -79.1078789998572,
                            39.4612209997106
                        ],
                        [
                            -79.1051740000602,
                            39.4630119996886
                        ],
                        [
                            -79.0963120003064,
                            39.4624209999801
                        ],
                        [
                            -79.0945420002948,
                            39.4648490000549
                        ],
                        [
                            -79.0972780000539,
                            39.4684560000428
                        ],
                        [
                            -79.1044200000248,
                            39.4707649996714
                        ],
                        [
                            -79.1054920000775,
                            39.4739439999848
                        ],
                        [
                            -79.1023830000494,
                            39.4763940003002
                        ],
                        [
                            -79.0999600002847,
                            39.4769489996984
                        ],
                        [
                            -79.091130000318,
                            39.4725199997799
                        ],
                        [
                            -79.0830169996784,
                            39.4714729997341
                        ],
                        [
                            -79.069315000243,
                            39.4739670000932
                        ],
                        [
                            -79.068033000128,
                            39.4790080001869
                        ],
                        [
                            -79.0663419997591,
                            39.4799709998059
                        ],
                        [
                            -79.0609770003709,
                            39.4718769998364
                        ],
                        [
                            -79.0569040002451,
                            39.4706719998506
                        ],
                        [
                            -79.0549650001791,
                            39.4722879999344
                        ],
                        [
                            -79.0527480002787,
                            39.4816779997022
                        ],
                        [
                            -79.0470249997919,
                            39.4833460002961
                        ],
                        [
                            -79.0444320001479,
                            39.4797950000756
                        ],
                        [
                            -79.0370230002133,
                            39.4766529997384
                        ],
                        [
                            -79.035419999708,
                            39.4694929999363
                        ],
                        [
                            -79.0308760003023,
                            39.4652539998205
                        ],
                        [
                            -79.0180309999362,
                            39.4676560001062
                        ],
                        [
                            -79.0109240001254,
                            39.4611210003147
                        ],
                        [
                            -78.9777349999452,
                            39.4483329999248
                        ],
                        [
                            -78.969433999883,
                            39.4432589996573
                        ],
                        [
                            -78.9656810003227,
                            39.4384700002185
                        ],
                        [
                            -78.9565729996233,
                            39.4403310000715
                        ],
                        [
                            -78.9559530003805,
                            39.4478390001599
                        ],
                        [
                            -78.960545000365,
                            39.4510359998619
                        ],
                        [
                            -78.9542929999737,
                            39.45470300028
                        ],
                        [
                            -78.9589619999511,
                            39.4597580001539
                        ],
                        [
                            -78.9589100000726,
                            39.4628380002007
                        ],
                        [
                            -78.9524169996569,
                            39.46358300005
                        ],
                        [
                            -78.9424920000712,
                            39.4689499998365
                        ],
                        [
                            -78.9385200002278,
                            39.4745720002742
                        ],
                        [
                            -78.9425389999269,
                            39.4806299996899
                        ],
                        [
                            -78.9342980001391,
                            39.486283999743
                        ],
                        [
                            -78.9167200002214,
                            39.4863199999736
                        ],
                        [
                            -78.9132439997723,
                            39.4945939996982
                        ],
                        [
                            -78.9077670002311,
                            39.4978390000781
                        ],
                        [
                            -78.9040809997196,
                            39.5040719997084
                        ],
                        [
                            -78.9055129996224,
                            39.5115450000273
                        ],
                        [
                            -78.8923910001452,
                            39.5132790000186
                        ],
                        [
                            -78.8911369996193,
                            39.5182170000837
                        ],
                        [
                            -78.8931019996246,
                            39.5239959996876
                        ],
                        [
                            -78.8889160004192,
                            39.5249120001557
                        ],
                        [
                            -78.8870519997979,
                            39.5229639999366
                        ],
                        [
                            -78.878744000063,
                            39.5212740001155
                        ],
                        [
                            -78.8751489997882,
                            39.5226650000522
                        ],
                        [
                            -78.8693569997044,
                            39.531792999901
                        ],
                        [
                            -78.8713789996111,
                            39.5356839997518
                        ],
                        [
                            -78.8706870003983,
                            39.5387669997272
                        ],
                        [
                            -78.8614309995609,
                            39.5409649998881
                        ],
                        [
                            -78.8520549999712,
                            39.5509719996537
                        ],
                        [
                            -78.8509760002458,
                            39.5601099999608
                        ],
                        [
                            -78.8472269999852,
                            39.5625079998301
                        ],
                        [
                            -78.8432600001646,
                            39.5627840000583
                        ],
                        [
                            -78.8392420002905,
                            39.5670079999773
                        ],
                        [
                            -78.8294590000967,
                            39.5649939997156
                        ],
                        [
                            -78.8219329999863,
                            39.5606210003316
                        ],
                        [
                            -78.8158460003497,
                            39.5628809999906
                        ],
                        [
                            -78.8132219998453,
                            39.5672080001003
                        ],
                        [
                            -78.8148529999397,
                            39.5712870001847
                        ],
                        [
                            -78.8199050002318,
                            39.5763329997016
                        ],
                        [
                            -78.8262050003037,
                            39.5773350002299
                        ],
                        [
                            -78.8257220004299,
                            39.5891770001602
                        ],
                        [
                            -78.8193319999463,
                            39.589889000304
                        ],
                        [
                            -78.8178649998834,
                            39.5918599998531
                        ],
                        [
                            -78.8183989998108,
                            39.5940590000955
                        ],
                        [
                            -78.8118710001333,
                            39.597993999967
                        ],
                        [
                            -78.809028000244,
                            39.6081819998554
                        ],
                        [
                            -78.8015749999285,
                            39.6067629998079
                        ],
                        [
                            -78.7998230003585,
                            39.6050350001364
                        ],
                        [
                            -78.7964620004354,
                            39.6059019997541
                        ],
                        [
                            -78.7957850003929,
                            39.6134020000709
                        ],
                        [
                            -78.8014019996762,
                            39.6283849998683
                        ],
                        [
                            -78.7974830004345,
                            39.6293120001636
                        ],
                        [
                            -78.794097000397,
                            39.6345589997708
                        ],
                        [
                            -78.7948830002195,
                            39.6371149998488
                        ],
                        [
                            -78.7907709997356,
                            39.6382749999721
                        ],
                        [
                            -78.7811950001294,
                            39.636845999864
                        ],
                        [
                            -78.7752790001971,
                            39.6456569997358
                        ],
                        [
                            -78.7689930003689,
                            39.646307000267
                        ],
                        [
                            -78.7661079997485,
                            39.6484540000202
                        ],
                        [
                            -78.7646580003023,
                            39.6458919999786
                        ],
                        [
                            -78.7684920000535,
                            39.639315000026
                        ],
                        [
                            -78.7726349996011,
                            39.6368849998212
                        ],
                        [
                            -78.7781580000714,
                            39.6253439997867
                        ],
                        [
                            -78.7776219995959,
                            39.6220000001025
                        ],
                        [
                            -78.7634370002867,
                            39.6188269999026
                        ],
                        [
                            -78.7467670003902,
                            39.6267219998004
                        ],
                        [
                            -78.7367959998755,
                            39.6221869997663
                        ],
                        [
                            -78.7331870002552,
                            39.61529499974
                        ],
                        [
                            -78.7385790003059,
                            39.6098600002651
                        ],
                        [
                            -78.7425799995633,
                            39.6092449999577
                        ],
                        [
                            -78.7468329996142,
                            39.6056610001259
                        ],
                        [
                            -78.7489440001077,
                            39.6059740000747
                        ],
                        [
                            -78.7512170000845,
                            39.6099610001973
                        ],
                        [
                            -78.7607640002767,
                            39.6100850000085
                        ],
                        [
                            -78.7677630001325,
                            39.6087919996674
                        ],
                        [
                            -78.7768049998113,
                            39.6041319999934
                        ],
                        [
                            -78.7781460003758,
                            39.6014509997267
                        ],
                        [
                            -78.7703099997457,
                            39.5950010000844
                        ],
                        [
                            -78.7670609999756,
                            39.5875109999343
                        ],
                        [
                            -78.7569859997038,
                            39.5808490002313
                        ],
                        [
                            -78.751053000053,
                            39.581804999743
                        ],
                        [
                            -78.7463320001971,
                            39.5797780001954
                        ],
                        [
                            -78.7430979995972,
                            39.5809969999929
                        ],
                        [
                            -78.7402410003625,
                            39.5864060001812
                        ],
                        [
                            -78.7333899997465,
                            39.5864580001737
                        ],
                        [
                            -78.7315939997956,
                            39.5746099997154
                        ],
                        [
                            -78.7245890000921,
                            39.5639189998459
                        ],
                        [
                            -78.7145780002927,
                            39.5628170000648
                        ],
                        [
                            -78.707459999713,
                            39.5558390001383
                        ],
                        [
                            -78.6950239996008,
                            39.5538349997832
                        ],
                        [
                            -78.6917070000586,
                            39.5510489998281
                        ],
                        [
                            -78.6895579999302,
                            39.5458239998781
                        ],
                        [
                            -78.6811899999209,
                            39.5438129998033
                        ],
                        [
                            -78.6754510004389,
                            39.5401650001362
                        ],
                        [
                            -78.6679090004349,
                            39.5402010001673
                        ],
                        [
                            -78.6641500001285,
                            39.5369870000157
                        ],
                        [
                            -78.6546969996476,
                            39.5346049998211
                        ],
                        [
                            -78.6302980001495,
                            39.537129999995
                        ],
                        [
                            -78.6286510001615,
                            39.5393900001344
                        ],
                        [
                            -78.6231009999271,
                            39.5396950000951
                        ],
                        [
                            -78.6068750004446,
                            39.5352059999645
                        ],
                        [
                            -78.6025699996169,
                            39.532028999823
                        ],
                        [
                            -78.5959930004339,
                            39.5360610002813
                        ],
                        [
                            -78.5909379997688,
                            39.5367479998237
                        ],
                        [
                            -78.590058000235,
                            39.5354310003397
                        ],
                        [
                            -78.5921980001425,
                            39.5316969999263
                        ],
                        [
                            -78.587606000158,
                            39.5277210000308
                        ],
                        [
                            -78.578598999741,
                            39.5267310000782
                        ],
                        [
                            -78.5661149999482,
                            39.5193709997489
                        ],
                        [
                            -78.5230859996727,
                            39.5248539997945
                        ],
                        [
                            -78.5032790001591,
                            39.5185820002421
                        ],
                        [
                            -78.4900029997978,
                            39.5176499997814
                        ],
                        [
                            -78.4836390001518,
                            39.5195410000658
                        ],
                        [
                            -78.4719420000063,
                            39.5159380001721
                        ],
                        [
                            -78.4662300002885,
                            39.5177960000194
                        ],
                        [
                            -78.4617880000918,
                            39.522253999744
                        ],
                        [
                            -78.4607269999097,
                            39.5285780000216
                        ],
                        [
                            -78.4629689999245,
                            39.5333979997131
                        ],
                        [
                            -78.462367000225,
                            39.5363620000979
                        ],
                        [
                            -78.458003999671,
                            39.5383489999712
                        ],
                        [
                            -78.4504569996442,
                            39.5379340002226
                        ],
                        [
                            -78.4486420003251,
                            39.5399599999495
                        ],
                        [
                            -78.4499190004172,
                            39.5433849997176
                        ],
                        [
                            -78.4605339995659,
                            39.546188999699
                        ],
                        [
                            -78.4619460002755,
                            39.5489500001911
                        ],
                        [
                            -78.461116999897,
                            39.5508329998781
                        ],
                        [
                            -78.4503599995598,
                            39.5498299998802
                        ],
                        [
                            -78.4378580002237,
                            39.5385159998243
                        ],
                        [
                            -78.4346250003471,
                            39.542188999915
                        ],
                        [
                            -78.4365540003674,
                            39.5482489997465
                        ],
                        [
                            -78.4391140003994,
                            39.5507910000406
                        ],
                        [
                            -78.4377169997584,
                            39.5533659996695
                        ],
                        [
                            -78.431512000121,
                            39.5522839998266
                        ],
                        [
                            -78.4245290001589,
                            39.5451800001885
                        ],
                        [
                            -78.4194800002398,
                            39.5456020000578
                        ],
                        [
                            -78.417018999942,
                            39.5485600002308
                        ],
                        [
                            -78.4263200000869,
                            39.5592219998842
                        ],
                        [
                            -78.4373970000912,
                            39.5631369997957
                        ],
                        [
                            -78.4542789996998,
                            39.5740820002798
                        ],
                        [
                            -78.458039999656,
                            39.5797079998929
                        ],
                        [
                            -78.4567719997847,
                            39.5876079998864
                        ],
                        [
                            -78.4504080001388,
                            39.5905510003352
                        ],
                        [
                            -78.4434979999715,
                            39.5913309999993
                        ],
                        [
                            -78.4260880002833,
                            39.5861369997031
                        ],
                        [
                            -78.4182990004072,
                            39.5809319997202
                        ],
                        [
                            -78.4073830000613,
                            39.5786070002057
                        ],
                        [
                            -78.3997130000109,
                            39.5807019999165
                        ],
                        [
                            -78.3948440000169,
                            39.5851520001528
                        ],
                        [
                            -78.3962030001248,
                            39.5892799997693
                        ],
                        [
                            -78.3988839996324,
                            39.5915920000799
                        ],
                        [
                            -78.4116169998455,
                            39.5974419998202
                        ],
                        [
                            -78.4254859996855,
                            39.6076719999495
                        ],
                        [
                            -78.4335470004466,
                            39.6182070001085
                        ],
                        [
                            -78.4332989998511,
                            39.6206989997854
                        ],
                        [
                            -78.4301389997694,
                            39.6234159996914
                        ],
                        [
                            -78.4205070000867,
                            39.6240729997826
                        ],
                        [
                            -78.3964550000199,
                            39.6164360001205
                        ],
                        [
                            -78.3839410000898,
                            39.6089809998143
                        ],
                        [
                            -78.3756109997153,
                            39.6083070001412
                        ],
                        [
                            -78.372042000278,
                            39.6118689999231
                        ],
                        [
                            -78.3833550002839,
                            39.622913000225
                        ],
                        [
                            -78.3823629996988,
                            39.6277929998477
                        ],
                        [
                            -78.3737810003275,
                            39.6303730001018
                        ],
                        [
                            -78.360509999989,
                            39.6254339999826
                        ],
                        [
                            -78.3555060002758,
                            39.6261109997408
                        ],
                        [
                            -78.3534569997066,
                            39.6305689996622
                        ],
                        [
                            -78.3592539998133,
                            39.6372080002534
                        ],
                        [
                            -78.3580129997061,
                            39.6397830000327
                        ],
                        [
                            -78.3553229999777,
                            39.640662999735
                        ],
                        [
                            -78.3352369999066,
                            39.6373590002572
                        ],
                        [
                            -78.3108569997766,
                            39.6306090001256
                        ],
                        [
                            -78.286029999758,
                            39.6211920000317
                        ],
                        [
                            -78.2660879996268,
                            39.6190549999614
                        ],
                        [
                            -78.2628630001461,
                            39.6224790000735
                        ],
                        [
                            -78.2623380004396,
                            39.6304949998511
                        ],
                        [
                            -78.2540669996163,
                            39.6401839997497
                        ],
                        [
                            -78.2381070002726,
                            39.6521519999385
                        ],
                        [
                            -78.2272110000183,
                            39.6570850002201
                        ],
                        [
                            -78.2238249999807,
                            39.6605389996579
                        ],
                        [
                            -78.2242529996029,
                            39.6635139998732
                        ],
                        [
                            -78.232999999729,
                            39.6706619997761
                        ],
                        [
                            -78.2325679999088,
                            39.6735829999576
                        ],
                        [
                            -78.2270789997738,
                            39.6762470001244
                        ],
                        [
                            -78.2022040000745,
                            39.6768560002153
                        ],
                        [
                            -78.1960250003765,
                            39.6826690001285
                        ],
                        [
                            -78.1910080002445,
                            39.6902999998677
                        ],
                        [
                            -78.1827580002359,
                            39.6951099996919
                        ],
                        [
                            -78.1765419998296,
                            39.695979000213
                        ],
                        [
                            -78.1626539997231,
                            39.6939179999222
                        ],
                        [
                            -78.1543009997823,
                            39.6906230002654
                        ],
                        [
                            -78.1398150001743,
                            39.6898159996788
                        ],
                        [
                            -78.1315199999598,
                            39.6867880002065
                        ],
                        [
                            -78.1053139997418,
                            39.6816819997027
                        ],
                        [
                            -78.0970439996417,
                            39.6782159997246
                        ],
                        [
                            -78.0895200000795,
                            39.6714449999852
                        ],
                        [
                            -78.0805560002185,
                            39.6705019998733
                        ],
                        [
                            -78.0542110000833,
                            39.6509860000918
                        ],
                        [
                            -78.0481489996627,
                            39.6435049997816
                        ],
                        [
                            -78.0373210000787,
                            39.636983000131
                        ],
                        [
                            -78.0234990000944,
                            39.6216989996972
                        ],
                        [
                            -78.0101089999302,
                            39.603043999752
                        ],
                        [
                            -78.0026120001321,
                            39.6005540002363
                        ],
                        [
                            -77.9776119998611,
                            39.5996080002814
                        ],
                        [
                            -77.9731789998853,
                            39.6013170001806
                        ],
                        [
                            -77.9671580003709,
                            39.6071089999954
                        ],
                        [
                            -77.9609579998582,
                            39.6088779997396
                        ],
                        [
                            -77.955040000276,
                            39.6080779999163
                        ],
                        [
                            -77.950568999767,
                            39.6038360000122
                        ],
                        [
                            -77.9522760000295,
                            39.5956749998247
                        ],
                        [
                            -77.9510330002725,
                            39.5895279998534
                        ],
                        [
                            -77.9461020003543,
                            39.5847920003411
                        ],
                        [
                            -77.9423559995684,
                            39.5848530002581
                        ],
                        [
                            -77.9391150001941,
                            39.5869990000712
                        ],
                        [
                            -77.9360370001282,
                            39.5967470001192
                        ],
                        [
                            -77.9358610004011,
                            39.6093650001935
                        ],
                        [
                            -77.9387350002525,
                            39.6128879999003
                        ],
                        [
                            -77.9447890002771,
                            39.6155659996903
                        ],
                        [
                            -77.9410850002223,
                            39.6190349998528
                        ],
                        [
                            -77.9327599998707,
                            39.6176089999763
                        ],
                        [
                            -77.928353999659,
                            39.6136300000071
                        ],
                        [
                            -77.922719999759,
                            39.6047110002853
                        ],
                        [
                            -77.89197299961,
                            39.5977559997485
                        ],
                        [
                            -77.8853610002669,
                            39.5976769997947
                        ],
                        [
                            -77.8811509998738,
                            39.6009790000395
                        ],
                        [
                            -77.8811759999881,
                            39.6063690000694
                        ],
                        [
                            -77.8869970003842,
                            39.6135999999127
                        ],
                        [
                            -77.884916000028,
                            39.6158330001716
                        ],
                        [
                            -77.874264999996,
                            39.6140789998895
                        ],
                        [
                            -77.8644819998022,
                            39.6089310002137
                        ],
                        [
                            -77.8415110000089,
                            39.6069699999438
                        ],
                        [
                            -77.8341449997321,
                            39.6033710001734
                        ],
                        [
                            -77.8318869998239,
                            39.6007759999605
                        ],
                        [
                            -77.8297689996577,
                            39.5877570001049
                        ],
                        [
                            -77.833352000237,
                            39.5711519998667
                        ],
                        [
                            -77.8360870001712,
                            39.5666620003136
                        ],
                        [
                            -77.8432209997462,
                            39.5644639997656
                        ],
                        [
                            -77.878548000184,
                            39.5634080002288
                        ],
                        [
                            -77.8869179998432,
                            39.5599699997334
                        ],
                        [
                            -77.8887360004337,
                            39.5554099999939
                        ],
                        [
                            -77.8860230002408,
                            39.5516880002451
                        ],
                        [
                            -77.8716460004127,
                            39.5442809997827
                        ],
                        [
                            -77.8652750001957,
                            39.5384119999298
                        ],
                        [
                            -77.866138000011,
                            39.5183990000721
                        ],
                        [
                            -77.8647539997888,
                            39.5158699998944
                        ],
                        [
                            -77.8611760001307,
                            39.5142929997311
                        ],
                        [
                            -77.8494370001524,
                            39.5155950001715
                        ],
                        [
                            -77.8419450003772,
                            39.5184279998358
                        ],
                        [
                            -77.84056000033,
                            39.5291419996646
                        ],
                        [
                            -77.8369609998572,
                            39.5321120002419
                        ],
                        [
                            -77.8322700001385,
                            39.5323400002477
                        ],
                        [
                            -77.8247390000052,
                            39.5284840000977
                        ],
                        [
                            -77.8234979998981,
                            39.5237850002203
                        ],
                        [
                            -77.8257929996163,
                            39.5167100000429
                        ],
                        [
                            -77.8466000001109,
                            39.5045589997557
                        ],
                        [
                            -77.8478460002409,
                            39.5018200002291
                        ],
                        [
                            -77.8453780002704,
                            39.4981090001533
                        ],
                        [
                            -77.8275650001761,
                            39.4930859999315
                        ],
                        [
                            -77.8193610001982,
                            39.4935480001858
                        ],
                        [
                            -77.8028910003183,
                            39.4894600002983
                        ],
                        [
                            -77.7915699999165,
                            39.4909580002127
                        ],
                        [
                            -77.7821530003189,
                            39.4990360001932
                        ],
                        [
                            -77.77090899991,
                            39.4989350001407
                        ],
                        [
                            -77.76634000039,
                            39.4962379996979
                        ],
                        [
                            -77.7655410001488,
                            39.4934270001683
                        ],
                        [
                            -77.7672779996501,
                            39.4912710001586
                        ],
                        [
                            -77.7878099997852,
                            39.4854630000172
                        ],
                        [
                            -77.7971960003189,
                            39.4801389997948
                        ],
                        [
                            -77.7980039998827,
                            39.4752319998923
                        ],
                        [
                            -77.7953839995762,
                            39.4712470002775
                        ],
                        [
                            -77.777604999817,
                            39.4622530002031
                        ],
                        [
                            -77.7806009998672,
                            39.4598439998707
                        ],
                        [
                            -77.7926739997563,
                            39.462064000316
                        ],
                        [
                            -77.7980060004308,
                            39.4607690000498
                        ],
                        [
                            -77.7982690001965,
                            39.456131000248
                        ],
                        [
                            -77.7861209999644,
                            39.4474400000326
                        ],
                        [
                            -77.7852649998217,
                            39.4454030000447
                        ],
                        [
                            -77.7882390001306,
                            39.4429500001747
                        ],
                        [
                            -77.8009110002444,
                            39.4406740002436
                        ],
                        [
                            -77.8032050001377,
                            39.4371110003164
                        ],
                        [
                            -77.7943159997214,
                            39.4309250001255
                        ],
                        [
                            -77.7863319998516,
                            39.4288760002613
                        ],
                        [
                            -77.7614470001066,
                            39.4279410001382
                        ],
                        [
                            -77.7529429996548,
                            39.4230410002076
                        ],
                        [
                            -77.7464030002817,
                            39.4093690002323
                        ],
                        [
                            -77.7388040003764,
                            39.3997609999848
                        ],
                        [
                            -77.7357399996559,
                            39.3894589999144
                        ],
                        [
                            -77.7387370004293,
                            39.3857359999143
                        ],
                        [
                            -77.7488789997499,
                            39.3844409997929
                        ],
                        [
                            -77.75297699999,
                            39.3816789998977
                        ],
                        [
                            -77.7523090001683,
                            39.3773980001064
                        ],
                        [
                            -77.743640999685,
                            39.3646629997933
                        ],
                        [
                            -77.7436580003017,
                            39.3591900000572
                        ],
                        [
                            -77.74759599981,
                            39.3513269997519
                        ],
                        [
                            -77.7595160004365,
                            39.3448729997833
                        ],
                        [
                            -77.7608620001256,
                            39.3400269999415
                        ],
                        [
                            -77.7557279998178,
                            39.3339380000088
                        ],
                        [
                            -77.7346919998926,
                            39.3270659997163
                        ],
                        [
                            -77.727684999641,
                            39.3221140002635
                        ],
                        [
                            -77.7195190001963,
                            39.3213140001302
                        ],
                        [
                            -77.7216380001874,
                            39.3184940000779
                        ],
                        [
                            -77.7306969995846,
                            39.3156030001075
                        ],
                        [
                            -77.7384100001911,
                            39.3081909997297
                        ],
                        [
                            -77.7482949995938,
                            39.2936959999471
                        ],
                        [
                            -77.7527259999198,
                            39.2833729997413
                        ],
                        [
                            -77.7531050000365,
                            39.2773399998949
                        ],
                        [
                            -77.7579670003578,
                            39.2722519997491
                        ],
                        [
                            -77.7583799999114,
                            39.2650860000195
                        ],
                        [
                            -77.7617679995987,
                            39.2630310003461
                        ],
                        [
                            -77.7629280004133,
                            39.2582730002669
                        ],
                        [
                            -77.7701020001711,
                            39.2563689997572
                        ],
                        [
                            -77.7709370003974,
                            39.249445999872
                        ],
                        [
                            -77.7672269995965,
                            39.2490090003407
                        ],
                        [
                            -77.7713650000196,
                            39.2364929997849
                        ],
                        [
                            -77.7774159996711,
                            39.2297020002245
                        ],
                        [
                            -77.7807990002339,
                            39.2287209999597
                        ],
                        [
                            -77.7863219998058,
                            39.221807000187
                        ],
                        [
                            -77.7859269997956,
                            39.2196659996872
                        ],
                        [
                            -77.78884099983,
                            39.2148650000833
                        ],
                        [
                            -77.7926629998856,
                            39.2113879996673
                        ],
                        [
                            -77.7926319999235,
                            39.2089980001047
                        ],
                        [
                            -77.7981570000435,
                            39.200702999957
                        ],
                        [
                            -77.7981639997162,
                            39.1925179996549
                        ],
                        [
                            -77.8049049998291,
                            39.1790509997732
                        ],
                        [
                            -77.8060489998518,
                            39.171736999811
                        ],
                        [
                            -77.8136260000159,
                            39.1644180001842
                        ],
                        [
                            -77.8213699996862,
                            39.1523510000557
                        ],
                        [
                            -77.8233459995621,
                            39.1456249998137
                        ],
                        [
                            -77.8221010001554,
                            39.1401050003352
                        ],
                        [
                            -77.82829900012,
                            39.1324260000647
                        ],
                        [
                            -78.140919999839,
                            39.3337450001893
                        ],
                        [
                            -78.1581930001581,
                            39.3433909996918
                        ],
                        [
                            -78.2627850003283,
                            39.414323000081
                        ],
                        [
                            -78.3470870002129,
                            39.4660119998373
                        ],
                        [
                            -78.3497979998577,
                            39.4618070001488
                        ],
                        [
                            -78.345143000124,
                            39.4594839997995
                        ],
                        [
                            -78.3473330002602,
                            39.4476589998379
                        ],
                        [
                            -78.3465459997145,
                            39.4426159999241
                        ],
                        [
                            -78.3532269995529,
                            39.436792000298
                        ],
                        [
                            -78.3467180001419,
                            39.4276180002206
                        ],
                        [
                            -78.3512359996083,
                            39.4205949997219
                        ],
                        [
                            -78.3569380001787,
                            39.4169290002111
                        ],
                        [
                            -78.3599180003353,
                            39.4090280003401
                        ],
                        [
                            -78.3579490001321,
                            39.404191999729
                        ],
                        [
                            -78.3494360003577,
                            39.3972519996556
                        ],
                        [
                            -78.3509240003371,
                            39.3909850002737
                        ],
                        [
                            -78.3432140001037,
                            39.3888069996535
                        ],
                        [
                            -78.3548369997309,
                            39.3716160000494
                        ],
                        [
                            -78.3668669999623,
                            39.3592899998852
                        ],
                        [
                            -78.340414999697,
                            39.3536279998977
                        ],
                        [
                            -78.339284000093,
                            39.3486049997997
                        ],
                        [
                            -78.3413079996496,
                            39.345554999713
                        ],
                        [
                            -78.3436850002818,
                            39.346712999945
                        ],
                        [
                            -78.3474090004282,
                            39.3434019999095
                        ],
                        [
                            -78.3454600003164,
                            39.3410240000556
                        ],
                        [
                            -78.3463010003904,
                            39.3391080000015
                        ],
                        [
                            -78.35571399979,
                            39.3271749997727
                        ],
                        [
                            -78.3600349996129,
                            39.3177710001482
                        ],
                        [
                            -78.3640740003018,
                            39.3188200001464
                        ],
                        [
                            -78.3639620001488,
                            39.3156269997576
                        ],
                        [
                            -78.3672419998811,
                            39.3101480000709
                        ],
                        [
                            -78.3858840003538,
                            39.2948880000244
                        ],
                        [
                            -78.3933710001062,
                            39.2829879999524
                        ],
                        [
                            -78.3978960001435,
                            39.2805619996731
                        ],
                        [
                            -78.3998910002861,
                            39.2813450001968
                        ],
                        [
                            -78.402885999613,
                            39.2786239998241
                        ],
                        [
                            -78.4018129997354,
                            39.2767540000782
                        ],
                        [
                            -78.4027829996809,
                            39.2754709999298
                        ],
                        [
                            -78.4194219996152,
                            39.2574760001397
                        ],
                        [
                            -78.409116000263,
                            39.252563999656
                        ],
                        [
                            -78.3996689996299,
                            39.2438739998668
                        ],
                        [
                            -78.4042139997588,
                            39.2412140001144
                        ],
                        [
                            -78.4055849995623,
                            39.2311759997485
                        ],
                        [
                            -78.4205480000946,
                            39.2147979998853
                        ],
                        [
                            -78.4386950000998,
                            39.1980929996747
                        ],
                        [
                            -78.4308459999492,
                            39.1962270001653
                        ],
                        [
                            -78.4286759999045,
                            39.1926330002553
                        ],
                        [
                            -78.4264370002628,
                            39.1940849997095
                        ],
                        [
                            -78.4249049999025,
                            39.1933010003417
                        ],
                        [
                            -78.4242920003324,
                            39.1921559998542
                        ],
                        [
                            -78.4286969998209,
                            39.1872170000642
                        ],
                        [
                            -78.426315000064,
                            39.1827619999029
                        ],
                        [
                            -78.4239890003837,
                            39.1824909997467
                        ],
                        [
                            -78.4189439997643,
                            39.1773540000543
                        ],
                        [
                            -78.4148439998744,
                            39.1778349998584
                        ],
                        [
                            -78.4131309997641,
                            39.1736379997874
                        ],
                        [
                            -78.4069660003098,
                            39.1709030001099
                        ],
                        [
                            -78.4036970004482,
                            39.1674509999537
                        ],
                        [
                            -78.4139430004242,
                            39.1584149998286
                        ],
                        [
                            -78.4272940002303,
                            39.1527260002072
                        ],
                        [
                            -78.4366580001244,
                            39.1416909997491
                        ],
                        [
                            -78.4394290000437,
                            39.1321460002904
                        ],
                        [
                            -78.4598690001172,
                            39.1133509998354
                        ],
                        [
                            -78.4666620001086,
                            39.1128580000103
                        ],
                        [
                            -78.4753759997244,
                            39.1074689999818
                        ],
                        [
                            -78.4795969999881,
                            39.1095049999176
                        ],
                        [
                            -78.4951599996718,
                            39.1009919997395
                        ],
                        [
                            -78.5107030001623,
                            39.0852049997659
                        ],
                        [
                            -78.5164790003525,
                            39.0818019999963
                        ],
                        [
                            -78.5167889999739,
                            39.0775689997494
                        ],
                        [
                            -78.5227140001271,
                            39.0710619998688
                        ],
                        [
                            -78.5402159998768,
                            39.0606310001878
                        ],
                        [
                            -78.5719010001841,
                            39.0319950002337
                        ],
                        [
                            -78.5658370001138,
                            39.0263030002379
                        ],
                        [
                            -78.5596399999741,
                            39.0244559999721
                        ],
                        [
                            -78.557380000416,
                            39.0213930000048
                        ],
                        [
                            -78.5504669998757,
                            39.0180650002431
                        ],
                        [
                            -78.5704619997103,
                            39.0015519999884
                        ],
                        [
                            -78.5804650000121,
                            38.9905669998225
                        ],
                        [
                            -78.5889290002809,
                            38.9769579998779
                        ],
                        [
                            -78.5932609999744,
                            38.9719179998902
                        ],
                        [
                            -78.5988940000496,
                            38.9695459999072
                        ],
                        [
                            -78.6016549999231,
                            38.9646030000538
                        ],
                        [
                            -78.6101970000097,
                            38.9704759999078
                        ],
                        [
                            -78.6111839996736,
                            38.9761340000678
                        ],
                        [
                            -78.6186760003471,
                            38.9740819997164
                        ],
                        [
                            -78.6204530000315,
                            38.9826010002592
                        ],
                        [
                            -78.6277980003919,
                            38.9817900000623
                        ],
                        [
                            -78.6308460003206,
                            38.9795859997885
                        ],
                        [
                            -78.632470999669,
                            38.9747389997297
                        ],
                        [
                            -78.6384229995863,
                            38.9668190001394
                        ],
                        [
                            -78.6465889999293,
                            38.9681379997155
                        ],
                        [
                            -78.6523519997008,
                            38.9606769996868
                        ],
                        [
                            -78.6590500001559,
                            38.9473749998466
                        ],
                        [
                            -78.665885999805,
                            38.9415790003088
                        ],
                        [
                            -78.670678999631,
                            38.9337999998227
                        ],
                        [
                            -78.6804559999769,
                            38.925312999851
                        ],
                        [
                            -78.6882659997695,
                            38.9247800001917
                        ],
                        [
                            -78.6974370002181,
                            38.9143590000251
                        ],
                        [
                            -78.7043230000959,
                            38.9152309998871
                        ],
                        [
                            -78.7166310001616,
                            38.9044750000927
                        ],
                        [
                            -78.7192909997528,
                            38.905230000204
                        ],
                        [
                            -78.7208320003339,
                            38.9136330002644
                        ],
                        [
                            -78.7193479996542,
                            38.915748999766
                        ],
                        [
                            -78.7200950000169,
                            38.9238629998601
                        ],
                        [
                            -78.7170760004005,
                            38.9360279999332
                        ],
                        [
                            -78.7224419996136,
                            38.9314080001924
                        ],
                        [
                            -78.7391469996702,
                            38.9271340002669
                        ],
                        [
                            -78.7445039995608,
                            38.9200590001186
                        ],
                        [
                            -78.7505169995775,
                            38.9160280001393
                        ],
                        [
                            -78.7503569997439,
                            38.9116749996835
                        ],
                        [
                            -78.753690000078,
                            38.9095639996912
                        ],
                        [
                            -78.7545160000834,
                            38.9057279998169
                        ],
                        [
                            -78.7590849996034,
                            38.9005289997787
                        ],
                        [
                            -78.7693580002419,
                            38.8970640003256
                        ],
                        [
                            -78.7727929997849,
                            38.8937420001457
                        ],
                        [
                            -78.7791980003371,
                            38.8922980001503
                        ],
                        [
                            -78.7860249997653,
                            38.8871869999525
                        ],
                        [
                            -78.7916100001599,
                            38.8775930001632
                        ],
                        [
                            -78.7963300001909,
                            38.8751110002269
                        ],
                        [
                            -78.8290559996906,
                            38.8186249998684
                        ],
                        [
                            -78.8498139997814,
                            38.793012000216
                        ],
                        [
                            -78.868970999915,
                            38.7631390001566
                        ],
                        [
                            -78.9937610003094,
                            38.8500209998502
                        ],
                        [
                            -78.9997519996865,
                            38.8461620000749
                        ],
                        [
                            -78.999014000443,
                            38.8400740001793
                        ],
                        [
                            -79.0023519999016,
                            38.8365120002819
                        ],
                        [
                            -79.0065520002489,
                            38.8237120000703
                        ],
                        [
                            -79.0126520003043,
                            38.8201120001889
                        ],
                        [
                            -79.0185520003431,
                            38.8188119996938
                        ],
                        [
                            -79.0201530003003,
                            38.8161120002597
                        ],
                        [
                            -79.0198419999557,
                            38.8117109998529
                        ],
                        [
                            -79.0240530001737,
                            38.809211999904
                        ],
                        [
                            -79.0230410003954,
                            38.7986109999406
                        ],
                        [
                            -79.0272530004384,
                            38.7921129997993
                        ],
                        [
                            -79.0302539996131,
                            38.7910060000105
                        ],
                        [
                            -79.0465539996137,
                            38.7921129997993
                        ],
                        [
                            -79.0549540003084,
                            38.7857129999189
                        ],
                        [
                            -79.0556539999172,
                            38.7830130002171
                        ],
                        [
                            -79.0516539995863,
                            38.7780129998927
                        ],
                        [
                            -79.051267999797,
                            38.7736019998573
                        ],
                        [
                            -79.0556539999172,
                            38.7709129997616
                        ],
                        [
                            -79.0572540000495,
                            38.7607130002267
                        ],
                        [
                            -79.0723549997758,
                            38.7478130001576
                        ],
                        [
                            -79.073855000349,
                            38.7421139998801
                        ],
                        [
                            -79.0790549998808,
                            38.7372139998423
                        ],
                        [
                            -79.0854550004101,
                            38.7246139997526
                        ],
                        [
                            -79.0872549996606,
                            38.7201140002785
                        ],
                        [
                            -79.0865550000519,
                            38.7160149997162
                        ],
                        [
                            -79.0929549996829,
                            38.7017149997516
                        ],
                        [
                            -79.0907550003993,
                            38.6925150002623
                        ],
                        [
                            -79.0853549999526,
                            38.6886160002788
                        ],
                        [
                            -79.0844550003274,
                            38.6851160002902
                        ],
                        [
                            -79.0882549997433,
                            38.6729159998949
                        ],
                        [
                            -79.0922459998533,
                            38.6681139999783
                        ],
                        [
                            -79.0927549996663,
                            38.6598169999114
                        ],
                        [
                            -79.1063559997177,
                            38.6562169998156
                        ],
                        [
                            -79.1115560001478,
                            38.6597170002087
                        ],
                        [
                            -79.1159549997885,
                            38.6588089997731
                        ],
                        [
                            -79.1204530000617,
                            38.6602079996674
                        ],
                        [
                            -79.1232569995929,
                            38.6589169996649
                        ],
                        [
                            -79.1243560003081,
                            38.6558169997039
                        ],
                        [
                            -79.1297559998564,
                            38.6550180001523
                        ],
                        [
                            -79.1375570003264,
                            38.6380169999008
                        ],
                        [
                            -79.1426570002991,
                            38.634416999698
                        ],
                        [
                            -79.1459570001228,
                            38.6268180002356
                        ],
                        [
                            -79.1514570001286,
                            38.6202180003491
                        ],
                        [
                            -79.1555589996683,
                            38.6104840001576
                        ],
                        [
                            -79.1543569999192,
                            38.6065180002744
                        ],
                        [
                            -79.1592579997003,
                            38.6009189998484
                        ],
                        [
                            -79.1597580001908,
                            38.5951189997233
                        ],
                        [
                            -79.1582569997926,
                            38.5931189999389
                        ],
                        [
                            -79.1634420001541,
                            38.5828169999479
                        ],
                        [
                            -79.1678580004115,
                            38.5795190000583
                        ],
                        [
                            -79.1708579997613,
                            38.5741189996923
                        ],
                        [
                            -79.1709540000208,
                            38.568116000301
                        ],
                        [
                            -79.176658000241,
                            38.5655200001653
                        ],
                        [
                            -79.1816579997562,
                            38.5585200003302
                        ],
                        [
                            -79.1840579999547,
                            38.5515200001855
                        ],
                        [
                            -79.1934579998338,
                            38.5424210002723
                        ],
                        [
                            -79.1990590001219,
                            38.5350209998022
                        ],
                        [
                            -79.1988590001054,
                            38.5321210001853
                        ],
                        [
                            -79.2014590003204,
                            38.5278209999305
                        ],
                        [
                            -79.205858999786,
                            38.5245209997008
                        ],
                        [
                            -79.2109589997586,
                            38.5080220002829
                        ],
                        [
                            -79.2069590003261,
                            38.5035219996665
                        ],
                        [
                            -79.2105910004109,
                            38.4929129999117
                        ],
                        [
                            -79.2190670003753,
                            38.4874409998909
                        ],
                        [
                            -79.2214800000943,
                            38.4846930000703
                        ],
                        [
                            -79.2209610002355,
                            38.4805899999737
                        ],
                        [
                            -79.2256689996726,
                            38.4764709999623
                        ],
                        [
                            -79.2398809996443,
                            38.4700790002403
                        ],
                        [
                            -79.2421879999564,
                            38.4646230000215
                        ],
                        [
                            -79.2426680003554,
                            38.4541810001785
                        ],
                        [
                            -79.2474739997019,
                            38.4533059996595
                        ],
                        [
                            -79.2544819997784,
                            38.4559300000153
                        ],
                        [
                            -79.2569780002364,
                            38.4505670002008
                        ],
                        [
                            -79.2611030002407,
                            38.4480699998975
                        ],
                        [
                            -79.2673189997487,
                            38.4382740001675
                        ],
                        [
                            -79.2745179996209,
                            38.4363579997231
                        ],
                        [
                            -79.2827540002841,
                            38.431693999793
                        ],
                        [
                            -79.2796829998909,
                            38.4237330002441
                        ],
                        [
                            -79.2803130000777,
                            38.4203609998171
                        ],
                        [
                            -79.282940000057,
                            38.4180999998973
                        ],
                        [
                            -79.2906949995979,
                            38.4210379998011
                        ],
                        [
                            -79.3000850003296,
                            38.4148910002612
                        ],
                        [
                            -79.3055510000001,
                            38.4152890003285
                        ],
                        [
                            -79.3122760002194,
                            38.4118759998518
                        ],
                        [
                            -79.4766379998083,
                            38.4572279999456
                        ],
                        [
                            -79.5214689998824,
                            38.5341179999742
                        ],
                        [
                            -79.5319700001266,
                            38.542417000287
                        ],
                        [
                            -79.5368700000827,
                            38.5509170001413
                        ],
                        [
                            -79.5554709996493,
                            38.5602169999186
                        ],
                        [
                            -79.5717709996499,
                            38.5631169996775
                        ],
                        [
                            -79.6491749999611,
                            38.5914149998835
                        ],
                        [
                            -79.655174999559,
                            38.5831160003157
                        ],
                        [
                            -79.655875000066,
                            38.5769160000959
                        ],
                        [
                            -79.6579749997906,
                            38.5733160001837
                        ],
                        [
                            -79.6628749997466,
                            38.5704159997309
                        ],
                        [
                            -79.6592750003472,
                            38.5624159999102
                        ],
                        [
                            -79.662575000171,
                            38.5605160002284
                        ],
                        [
                            -79.6650749999286,
                            38.5609160002317
                        ],
                        [
                            -79.6672750001105,
                            38.5526160001351
                        ],
                        [
                            -79.6697749998682,
                            38.5503160000818
                        ],
                        [
                            -79.6691749998185,
                            38.5453160002915
                        ],
                        [
                            -79.6708750004083,
                            38.5410159996681
                        ],
                        [
                            -79.6668730004277,
                            38.5383170002991
                        ],
                        [
                            -79.6729740003079,
                            38.5287170000063
                        ],
                        [
                            -79.6667739997951,
                            38.5243170000076
                        ],
                        [
                            -79.662174000313,
                            38.5152169999043
                        ],
                        [
                            -79.6684740003849,
                            38.5123169999651
                        ],
                        [
                            -79.6704860002459,
                            38.5076589999134
                        ],
                        [
                            -79.6740739999497,
                            38.5104170001979
                        ],
                        [
                            -79.6803740000216,
                            38.5106170002777
                        ],
                        [
                            -79.6829740002366,
                            38.5013169997869
                        ],
                        [
                            -79.6883450003709,
                            38.4961830002036
                        ],
                        [
                            -79.6922729998334,
                            38.4964740000898
                        ],
                        [
                            -79.6975719999977,
                            38.4872230001172
                        ],
                        [
                            -79.6954620002274,
                            38.4814539997068
                        ],
                        [
                            -79.6933969997647,
                            38.480182999998
                        ],
                        [
                            -79.6994730004289,
                            38.4745270000055
                        ],
                        [
                            -79.6991200002515,
                            38.4702880001548
                        ],
                        [
                            -79.6955879997258,
                            38.4690579997813
                        ],
                        [
                            -79.6910860001529,
                            38.4637429999009
                        ],
                        [
                            -79.6883660002873,
                            38.4568699998443
                        ],
                        [
                            -79.6881050001714,
                            38.4504440003293
                        ],
                        [
                            -79.691478999615,
                            38.4462829999072
                        ],
                        [
                            -79.689246999646,
                            38.4421339999782
                        ],
                        [
                            -79.691743000104,
                            38.4370640002719
                        ],
                        [
                            -79.6896669997706,
                            38.4314620003121
                        ],
                        [
                            -79.6968310003811,
                            38.4267570000364
                        ],
                        [
                            -79.6952730000815,
                            38.4236850001202
                        ],
                        [
                            -79.6997339996465,
                            38.4218700000055
                        ],
                        [
                            -79.706633999768,
                            38.4157299999682
                        ],
                        [
                            -79.7091400002717,
                            38.4120639996825
                        ],
                        [
                            -79.7089650003695,
                            38.4095530000276
                        ],
                        [
                            -79.7173650001659,
                            38.4015619997494
                        ],
                        [
                            -79.7226529995612,
                            38.3895169996597
                        ],
                        [
                            -79.7263499999434,
                            38.3870700001856
                        ],
                        [
                            -79.7295119996749,
                            38.3811939997169
                        ],
                        [
                            -79.7316979996131,
                            38.3733759997465
                        ],
                        [
                            -79.7267900001594,
                            38.3708319999553
                        ],
                        [
                            -79.7255969997328,
                            38.363828000122
                        ],
                        [
                            -79.7320590001865,
                            38.3601680001796
                        ],
                        [
                            -79.7353089997815,
                            38.3552400000252
                        ],
                        [
                            -79.742177000116,
                            38.3532480001676
                        ],
                        [
                            -79.7555599997091,
                            38.357371999867
                        ],
                        [
                            -79.764432000407,
                            38.3565140000571
                        ],
                        [
                            -79.7672629997024,
                            38.3533950001574
                        ],
                        [
                            -79.7659799997625,
                            38.3512910003387
                        ],
                        [
                            -79.7703490001642,
                            38.3477999998584
                        ],
                        [
                            -79.7675940001385,
                            38.3443019998209
                        ],
                        [
                            -79.7730899999462,
                            38.33552899988
                        ],
                        [
                            -79.7792720000174,
                            38.3316090000231
                        ],
                        [
                            -79.785806000441,
                            38.3310949996932
                        ],
                        [
                            -79.7869839999006,
                            38.3284370003515
                        ],
                        [
                            -79.7900450002481,
                            38.3282389997633
                        ],
                        [
                            -79.7965500003594,
                            38.323479999996
                        ],
                        [
                            -79.7996169996563,
                            38.317148999792
                        ],
                        [
                            -79.8023249998264,
                            38.316263999795
                        ],
                        [
                            -79.8101539998855,
                            38.3067069997894
                        ],
                        [
                            -79.8099339997775,
                            38.3046809996522
                        ],
                        [
                            -79.8040260002411,
                            38.2986219997031
                        ],
                        [
                            -79.8027779995629,
                            38.2920729998962
                        ],
                        [
                            -79.797695000207,
                            38.2920039997899
                        ],
                        [
                            -79.7916550004262,
                            38.2846530000125
                        ],
                        [
                            -79.7897909998049,
                            38.2811669997195
                        ],
                        [
                            -79.7900050000651,
                            38.277351000321
                        ],
                        [
                            -79.7864669996917,
                            38.2746489997035
                        ],
                        [
                            -79.788944999708,
                            38.2687030003147
                        ],
                        [
                            -79.7959209999974,
                            38.2655519997996
                        ],
                        [
                            -79.7982979997314,
                            38.2659569996583
                        ],
                        [
                            -79.8012729998651,
                            38.2614729999614
                        ],
                        [
                            -79.8063360000279,
                            38.2591930001696
                        ],
                        [
                            -79.8119890001945,
                            38.2603979999672
                        ],
                        [
                            -79.8157090001428,
                            38.2550669999813
                        ],
                        [
                            -79.8148639998708,
                            38.251568999788
                        ],
                        [
                            -79.8196249999097,
                            38.2482320002767
                        ],
                        [
                            -79.8252830000992,
                            38.2504880000808
                        ],
                        [
                            -79.8308860000371,
                            38.2496890002076
                        ],
                        [
                            -79.8351130001486,
                            38.2418969999871
                        ],
                        [
                            -79.8452119998116,
                            38.2410740002753
                        ],
                        [
                            -79.8503260000279,
                            38.233323999736
                        ],
                        [
                            -79.8569719997062,
                            38.2310670001212
                        ],
                        [
                            -79.8608900000212,
                            38.2252429997708
                        ],
                        [
                            -79.8739339996807,
                            38.2153849997893
                        ],
                        [
                            -79.8745940000048,
                            38.2129759998397
                        ],
                        [
                            -79.8792499995634,
                            38.2109199997947
                        ],
                        [
                            -79.8829550003415,
                            38.2069389999606
                        ],
                        [
                            -79.886413000349,
                            38.2079530000692
                        ],
                        [
                            -79.8903220004432,
                            38.2058589998235
                        ],
                        [
                            -79.8929159999122,
                            38.1998680001536
                        ],
                        [
                            -79.8973949999188,
                            38.1958469996865
                        ],
                        [
                            -79.8973349996443,
                            38.1933809999956
                        ],
                        [
                            -79.9073870003499,
                            38.1879700001189
                        ],
                        [
                            -79.9144099995968,
                            38.1884179998051
                        ],
                        [
                            -79.9161829999814,
                            38.184223999786
                        ],
                        [
                            -79.9200750003572,
                            38.1825289999738
                        ],
                        [
                            -79.9210260000361,
                            38.1799539999473
                        ],
                        [
                            -79.9164540001431,
                            38.1768170001535
                        ],
                        [
                            -79.9188230003794,
                            38.1701169999429
                        ],
                        [
                            -79.917925000404,
                            38.1683989998186
                        ],
                        [
                            -79.9148640000565,
                            38.1677239998102
                        ],
                        [
                            -79.916206000446,
                            38.1630940000975
                        ],
                        [
                            -79.9195149995923,
                            38.1626489997856
                        ],
                        [
                            -79.9186619998227,
                            38.1547900000989
                        ],
                        [
                            -79.9261429997272,
                            38.1495569998639
                        ],
                        [
                            -79.9286829996678,
                            38.1449279998924
                        ],
                        [
                            -79.9290309998223,
                            38.1397710002169
                        ],
                        [
                            -79.9327490001209,
                            38.1361590001454
                        ],
                        [
                            -79.9427470003997,
                            38.1343329999943
                        ],
                        [
                            -79.945258000028,
                            38.1322389999034
                        ],
                        [
                            -79.9411740000316,
                            38.1252699996485
                        ],
                        [
                            -79.9432460001671,
                            38.1231350001913
                        ],
                        [
                            -79.9406669998684,
                            38.120872999895
                        ],
                        [
                            -79.9414639995615,
                            38.117368999924
                        ],
                        [
                            -79.9389460002606,
                            38.1116279998739
                        ],
                        [
                            -79.9288850002325,
                            38.1088769998425
                        ],
                        [
                            -79.9263970001704,
                            38.1065429997197
                        ],
                        [
                            -79.9351059997633,
                            38.0965470002623
                        ],
                        [
                            -79.9426560001632,
                            38.091292999954
                        ],
                        [
                            -79.9472890001556,
                            38.0850460000391
                        ],
                        [
                            -79.9543700000271,
                            38.0804010001894
                        ],
                        [
                            -79.9577579997144,
                            38.0720499998682
                        ],
                        [
                            -79.9616469997171,
                            38.0674150000862
                        ],
                        [
                            -79.9618049999008,
                            38.0658749999558
                        ],
                        [
                            -79.959495999939,
                            38.0642970002326
                        ],
                        [
                            -79.9601620001108,
                            38.0625469998273
                        ],
                        [
                            -79.966079999693,
                            38.0508200003516
                        ],
                        [
                            -79.9738950004067,
                            38.0400040001904
                        ],
                        [
                            -79.9716009996151,
                            38.0352320000177
                        ],
                        [
                            -79.9740850003775,
                            38.0343920000578
                        ],
                        [
                            -79.9752689996849,
                            38.0300749999098
                        ],
                        [
                            -79.9802900000148,
                            38.0275960003027
                        ],
                        [
                            -79.9856190003163,
                            38.0191600002523
                        ],
                        [
                            -79.9848419998164,
                            38.0166100000306
                        ],
                        [
                            -79.986142000373,
                            38.0141820000621
                        ],
                        [
                            -79.9901140002165,
                            38.0132459996594
                        ],
                        [
                            -79.9954879998255,
                            38.0071249999343
                        ],
                        [
                            -79.9949039996694,
                            38.0040769996868
                        ],
                        [
                            -79.9963959998467,
                            38.0006040002532
                        ],
                        [
                            -79.9988529999466,
                            37.9997109996945
                        ],
                        [
                            -79.999382999676,
                            37.9949079998646
                        ],
                        [
                            -80.0121929998821,
                            37.9886329998106
                        ],
                        [
                            -80.0131450002842,
                            37.9842530002109
                        ],
                        [
                            -80.0206310002116,
                            37.9807479998381
                        ],
                        [
                            -80.0221409999323,
                            37.9780209998505
                        ],
                        [
                            -80.035979999635,
                            37.9682489998378
                        ],
                        [
                            -80.0369079997477,
                            37.9659070001862
                        ],
                        [
                            -80.0404229996567,
                            37.965619999923
                        ],
                        [
                            -80.0444759996909,
                            37.9600799999703
                        ],
                        [
                            -80.0468719996914,
                            37.9600550002562
                        ],
                        [
                            -80.0560559996605,
                            37.9519589997872
                        ],
                        [
                            -80.0745140000103,
                            37.9422209997854
                        ],
                        [
                            -80.0761049999218,
                            37.9389460003137
                        ],
                        [
                            -80.0910580004084,
                            37.9269060000046
                        ],
                        [
                            -80.096563000437,
                            37.9181119999304
                        ],
                        [
                            -80.1029310002809,
                            37.9189109996812
                        ],
                        [
                            -80.1072050002481,
                            37.9124420000682
                        ],
                        [
                            -80.1114659997966,
                            37.9105269998286
                        ],
                        [
                            -80.1189669997926,
                            37.9036139997876
                        ],
                        [
                            -80.1177469996019,
                            37.8977199998196
                        ],
                        [
                            -80.1206129999557,
                            37.8967350002354
                        ],
                        [
                            -80.1236199998765,
                            37.8979430002187
                        ],
                        [
                            -80.1297679996124,
                            37.8941589997931
                        ],
                        [
                            -80.1319309999844,
                            37.8895000001392
                        ],
                        [
                            -80.1379869996588,
                            37.886283999836
                        ],
                        [
                            -80.1415219996592,
                            37.8824359999996
                        ],
                        [
                            -80.1489509996853,
                            37.8868920001638
                        ],
                        [
                            -80.1498479998358,
                            37.8833640003311
                        ],
                        [
                            -80.1622019999323,
                            37.8751220002742
                        ],
                        [
                            -80.1653729998846,
                            37.8708670000752
                        ],
                        [
                            -80.165073000309,
                            37.8690249996606
                        ],
                        [
                            -80.1689570002889,
                            37.8671159997399
                        ],
                        [
                            -80.1720329998066,
                            37.8621440002259
                        ],
                        [
                            -80.1716410001695,
                            37.8589660002538
                        ],
                        [
                            -80.1761960003441,
                            37.8552650003442
                        ],
                        [
                            -80.1767410001421,
                            37.8527569998689
                        ],
                        [
                            -80.1814109999444,
                            37.8529030001257
                        ],
                        [
                            -80.1827549999837,
                            37.8513649997211
                        ],
                        [
                            -80.1835550000499,
                            37.8468099998495
                        ],
                        [
                            -80.1793909996876,
                            37.8397510003487
                        ],
                        [
                            -80.1929659997997,
                            37.8347499999377
                        ],
                        [
                            -80.2028529997505,
                            37.8242399999964
                        ],
                        [
                            -80.2064820003606,
                            37.8159699996688
                        ],
                        [
                            -80.216228999671,
                            37.8098200003487
                        ],
                        [
                            -80.2186110003261,
                            37.8097830001124
                        ],
                        [
                            -80.2276899998149,
                            37.7978919996722
                        ],
                        [
                            -80.2294890001388,
                            37.7923309996619
                        ],
                        [
                            -80.223040000104,
                            37.7867230000631
                        ],
                        [
                            -80.2245370003042,
                            37.7845949997778
                        ],
                        [
                            -80.223485000343,
                            37.7824440002854
                        ],
                        [
                            -80.219081999606,
                            37.7833679997503
                        ],
                        [
                            -80.2158920002854,
                            37.7819890001877
                        ],
                        [
                            -80.216605000313,
                            37.7759609998276
                        ],
                        [
                            -80.221826999586,
                            37.7782929996765
                        ],
                        [
                            -80.2251630002931,
                            37.7769169997429
                        ],
                        [
                            -80.2270529999553,
                            37.7788599999314
                        ],
                        [
                            -80.2304580002594,
                            37.7783049996706
                        ],
                        [
                            -80.2358920001429,
                            37.7731299999064
                        ],
                        [
                            -80.2408630002441,
                            37.7724419998272
                        ],
                        [
                            -80.2414370003545,
                            37.7680580002122
                        ],
                        [
                            -80.244563000101,
                            37.7670049998694
                        ],
                        [
                            -80.2483890003545,
                            37.76825000019
                        ],
                        [
                            -80.251655999668,
                            37.7632470002758
                        ],
                        [
                            -80.2493459998812,
                            37.7575010000488
                        ],
                        [
                            -80.2508790000664,
                            37.7555159999112
                        ],
                        [
                            -80.2574109999419,
                            37.7560840001127
                        ],
                        [
                            -80.2602899998162,
                            37.7498910001399
                        ],
                        [
                            -80.2593149998478,
                            37.7449610001017
                        ],
                        [
                            -80.2627650003577,
                            37.7383360001712
                        ],
                        [
                            -80.2603130002807,
                            37.7335170001437
                        ],
                        [
                            -80.252023999914,
                            37.7298249999932
                        ],
                        [
                            -80.2530769997002,
                            37.7258989996957
                        ],
                        [
                            -80.2581430002359,
                            37.7206120001147
                        ],
                        [
                            -80.2644059995996,
                            37.71878599967
                        ],
                        [
                            -80.2681490000125,
                            37.7128829999323
                        ],
                        [
                            -80.272340000139,
                            37.7112790000245
                        ],
                        [
                            -80.2765770002963,
                            37.7055490002282
                        ],
                        [
                            -80.2871069999545,
                            37.6964039997276
                        ],
                        [
                            -80.2959160000048,
                            37.6928379996595
                        ],
                        [
                            -80.2793719996067,
                            37.6570770002218
                        ],
                        [
                            -80.267503999757,
                            37.6461559998897
                        ],
                        [
                            -80.2407910002741,
                            37.6383240002475
                        ],
                        [
                            -80.2209839998621,
                            37.6277669996677
                        ],
                        [
                            -80.2260169998876,
                            37.6200589996475
                        ],
                        [
                            -80.2402720004153,
                            37.6069609997695
                        ],
                        [
                            -80.2589190000127,
                            37.595499000333
                        ],
                        [
                            -80.3285040003105,
                            37.5643150000761
                        ],
                        [
                            -80.3122009999368,
                            37.546219999826
                        ],
                        [
                            -80.3144699997157,
                            37.5440550000778
                        ],
                        [
                            -80.327490999809,
                            37.5401400003028
                        ],
                        [
                            -80.3303680000334,
                            37.5362670001076
                        ],
                        [
                            -80.3093470001769,
                            37.5273800000331
                        ],
                        [
                            -80.2916670001519,
                            37.536516000001
                        ],
                        [
                            -80.2823910001214,
                            37.5335189998592
                        ],
                        [
                            -80.299789000114,
                            37.5082709996823
                        ],
                        [
                            -80.3270999999967,
                            37.4953769997863
                        ],
                        [
                            -80.3437799999389,
                            37.4921510002677
                        ],
                        [
                            -80.3668369999459,
                            37.4848789999275
                        ],
                        [
                            -80.3631710004243,
                            37.4800009998912
                        ],
                        [
                            -80.3719510001623,
                            37.4740689999335
                        ],
                        [
                            -80.4256289999215,
                            37.449885000147
                        ],
                        [
                            -80.4430090003708,
                            37.4381309996698
                        ],
                        [
                            -80.4573040001831,
                            37.4322699999503
                        ],
                        [
                            -80.4648160000499,
                            37.4261449999211
                        ],
                        [
                            -80.4756009999761,
                            37.42294899994
                        ],
                        [
                            -80.494860000217,
                            37.4350720001802
                        ],
                        [
                            -80.4972789997837,
                            37.4447799997346
                        ],
                        [
                            -80.4929810004254,
                            37.4577489999826
                        ],
                        [
                            -80.5113910001963,
                            37.4816720001406
                        ],
                        [
                            -80.5151389997337,
                            37.4785659998997
                        ],
                        [
                            -80.5186419999471,
                            37.4793040000376
                        ],
                        [
                            -80.5234809998038,
                            37.4769049999306
                        ],
                        [
                            -80.5323719998699,
                            37.4771240001922
                        ],
                        [
                            -80.5368890004097,
                            37.4740080002897
                        ],
                        [
                            -80.5520360001667,
                            37.4735629998162
                        ],
                        [
                            -80.5762549997398,
                            37.4620480001203
                        ],
                        [
                            -80.5814629996675,
                            37.4591380001064
                        ],
                        [
                            -80.5828839996997,
                            37.4566579998334
                        ],
                        [
                            -80.5858560003587,
                            37.4566540000839
                        ],
                        [
                            -80.6002039998745,
                            37.4461729999582
                        ],
                        [
                            -80.6168019998009,
                            37.4394429997826
                        ],
                        [
                            -80.6221169998587,
                            37.4359689996541
                        ],
                        [
                            -80.6226640002049,
                            37.4333070002956
                        ],
                        [
                            -80.6343899997644,
                            37.4312270003384
                        ],
                        [
                            -80.6373790001418,
                            37.4293720000029
                        ],
                        [
                            -80.6369470003217,
                            37.4274709996451
                        ],
                        [
                            -80.6458929997411,
                            37.4221470002627
                        ],
                        [
                            -80.6524260003398,
                            37.4206339999272
                        ],
                        [
                            -80.6851069996336,
                            37.4040949998412
                        ],
                        [
                            -80.6974740001488,
                            37.3996009998267
                        ],
                        [
                            -80.7052029997505,
                            37.3946179996935
                        ],
                        [
                            -80.7154789998638,
                            37.3907070002012
                        ],
                        [
                            -80.7162980001965,
                            37.3886940001656
                        ],
                        [
                            -80.7206840003167,
                            37.3893189996945
                        ],
                        [
                            -80.7257020002736,
                            37.3855220002527
                        ],
                        [
                            -80.7278039996479,
                            37.3863790000411
                        ],
                        [
                            -80.743390999621,
                            37.3798509999163
                        ],
                        [
                            -80.7487219995723,
                            37.380050000239
                        ],
                        [
                            -80.7598859996153,
                            37.3748820002392
                        ],
                        [
                            -80.7700820002609,
                            37.3723630002753
                        ],
                        [
                            -80.7770069995985,
                            37.384840000238
                        ],
                        [
                            -80.7831309999433,
                            37.3902469998527
                        ],
                        [
                            -80.7841879999274,
                            37.3945870000201
                        ],
                        [
                            -80.7994479996624,
                            37.3957440001137
                        ],
                        [
                            -80.8024989999641,
                            37.398261000075
                        ],
                        [
                            -80.8057429997114,
                            37.3979540001925
                        ],
                        [
                            -80.8070440000929,
                            37.4016459996592
                        ],
                        [
                            -80.8061489995922,
                            37.4040260000909
                        ],
                        [
                            -80.8085870003239,
                            37.4065369997555
                        ],
                        [
                            -80.8372349996749,
                            37.4254059999774
                        ],
                        [
                            -80.8410249999434,
                            37.4261229998148
                        ],
                        [
                            -80.8463249999326,
                            37.4233950000236
                        ],
                        [
                            -80.8522259997963,
                            37.4266570001156
                        ],
                        [
                            -80.8573590002792,
                            37.4272060003256
                        ],
                        [
                            -80.8594570003539,
                            37.4294909996492
                        ],
                        [
                            -80.8652739996538,
                            37.42011299984
                        ],
                        [
                            -80.8655060003557,
                            37.4168989997271
                        ],
                        [
                            -80.863003000225,
                            37.411514000049
                        ],
                        [
                            -80.8832469995816,
                            37.3839329998871
                        ],
                        [
                            -80.8494509996791,
                            37.346908999665
                        ],
                        [
                            -80.8689860001045,
                            37.3385729999057
                        ],
                        [
                            -80.899159999968,
                            37.3156779996898
                        ],
                        [
                            -80.9192589995596,
                            37.3061629999631
                        ],
                        [
                            -80.9381350003842,
                            37.3002779996896
                        ],
                        [
                            -80.9441729996169,
                            37.2965749998132
                        ],
                        [
                            -80.9665559999545,
                            37.2921580002612
                        ],
                        [
                            -80.973888999721,
                            37.2914440003064
                        ],
                        [
                            -80.981321999945,
                            37.2934650000263
                        ],
                        [
                            -80.9821730000648,
                            37.2960229999977
                        ],
                        [
                            -80.9791059998695,
                            37.300581000282
                        ],
                        [
                            -80.9795889997433,
                            37.3022790000874
                        ],
                        [
                            -81.0361329998563,
                            37.2902520001849
                        ],
                        [
                            -81.1041470003341,
                            37.2806049996635
                        ],
                        [
                            -81.1670290001539,
                            37.2628809999429
                        ],
                        [
                            -81.2047740003336,
                            37.2430130001944
                        ],
                        [
                            -81.225103999904,
                            37.2348739999807
                        ],
                        [
                            -81.3201050003994,
                            37.2993230001489
                        ],
                        [
                            -81.3621560003334,
                            37.3376869998736
                        ],
                        [
                            -81.3652839997297,
                            37.3378419999951
                        ],
                        [
                            -81.3693789995968,
                            37.3318280002909
                        ],
                        [
                            -81.3675359997902,
                            37.3268999998205
                        ],
                        [
                            -81.3714159995721,
                            37.3242170000646
                        ],
                        [
                            -81.3727489997407,
                            37.3202479999521
                        ],
                        [
                            -81.3794129998607,
                            37.317566000069
                        ],
                        [
                            -81.384851999767,
                            37.3185960002578
                        ],
                        [
                            -81.3863120001573,
                            37.3206379999605
                        ],
                        [
                            -81.3908489998902,
                            37.3169010001514
                        ],
                        [
                            -81.3942869998063,
                            37.3164109999068
                        ],
                        [
                            -81.3952960001098,
                            37.3126070002361
                        ],
                        [
                            -81.3986779999493,
                            37.3102480002062
                        ],
                        [
                            -81.3968169997011,
                            37.3044979997229
                        ],
                        [
                            -81.405060000037,
                            37.2987939998134
                        ],
                        [
                            -81.4022999999884,
                            37.2958099996499
                        ],
                        [
                            -81.4060929997317,
                            37.2932719997769
                        ],
                        [
                            -81.4115929997374,
                            37.2803299998971
                        ],
                        [
                            -81.4159539997432,
                            37.2789359998489
                        ],
                        [
                            -81.4160290000863,
                            37.2736259999593
                        ],
                        [
                            -81.4279460003398,
                            37.271015000106
                        ],
                        [
                            -81.4355960002987,
                            37.274164999755
                        ],
                        [
                            -81.4395070000428,
                            37.2709089996857
                        ],
                        [
                            -81.4476549999441,
                            37.2722449996813
                        ],
                        [
                            -81.4490679995804,
                            37.2695829999591
                        ],
                        [
                            -81.4605849998008,
                            37.2655270002043
                        ],
                        [
                            -81.4588730004138,
                            37.2628720002964
                        ],
                        [
                            -81.4611189997281,
                            37.2625690001332
                        ],
                        [
                            -81.4621070001153,
                            37.2598990003265
                        ],
                        [
                            -81.4761399999868,
                            37.2556349998925
                        ],
                        [
                            -81.4801439996173,
                            37.2511210000772
                        ],
                        [
                            -81.4922869998266,
                            37.2509599999337
                        ],
                        [
                            -81.4980449995751,
                            37.2546590000464
                        ],
                        [
                            -81.4977750001367,
                            37.2578989999722
                        ],
                        [
                            -81.4990959997115,
                            37.2579519997057
                        ],
                        [
                            -81.5031310002024,
                            37.2530910003449
                        ],
                        [
                            -81.5065199997146,
                            37.2452460000094
                        ],
                        [
                            -81.5076360001483,
                            37.2337120000495
                        ],
                        [
                            -81.5206759996098,
                            37.2269800000325
                        ],
                        [
                            -81.5242619996638,
                            37.2278879999865
                        ],
                        [
                            -81.5412370000571,
                            37.2200170002392
                        ],
                        [
                            -81.5444590000631,
                            37.2207429998836
                        ],
                        [
                            -81.5540110002782,
                            37.2084120001609
                        ],
                        [
                            -81.5572199998654,
                            37.2073249997361
                        ],
                        [
                            -81.5580540002667,
                            37.2085650001116
                        ],
                        [
                            -81.5606310000172,
                            37.2066630001617
                        ],
                        [
                            -81.6782209998162,
                            37.2015400000175
                        ],
                        [
                            -81.6832680000854,
                            37.2056490001931
                        ],
                        [
                            -81.6836179998897,
                            37.2116249997983
                        ],
                        [
                            -81.686023999936,
                            37.2115589996864
                        ],
                        [
                            -81.6870259996685,
                            37.2133490000265
                        ],
                        [
                            -81.6951130003688,
                            37.2135700001017
                        ],
                        [
                            -81.6960430001313,
                            37.2169889997928
                        ],
                        [
                            -81.6987560003243,
                            37.2180059999862
                        ],
                        [
                            -81.6993339997343,
                            37.2199210001357
                        ],
                        [
                            -81.7157299999944,
                            37.2287709998965
                        ],
                        [
                            -81.7162480000282,
                            37.2343209998515
                        ],
                        [
                            -81.7230610001111,
                            37.2404929997424
                        ],
                        [
                            -81.740124000368,
                            37.2377520000495
                        ],
                        [
                            -81.7410859999175,
                            37.2416479997707
                        ],
                        [
                            -81.7443759996955,
                            37.243330000339
                        ],
                        [
                            -81.7408969997716,
                            37.2543169997049
                        ],
                        [
                            -81.7456600003587,
                            37.2580939999408
                        ],
                        [
                            -81.7460899996307,
                            37.2637020001041
                        ],
                        [
                            -81.7501069996799,
                            37.2641860002056
                        ],
                        [
                            -81.7575759998889,
                            37.2685520003515
                        ],
                        [
                            -81.7571370003961,
                            37.2761470001911
                        ],
                        [
                            -81.7692169999579,
                            37.2732500000303
                        ],
                        [
                            -81.7732440000529,
                            37.2739119997771
                        ],
                        [
                            -81.7793519996058,
                            37.2773900003491
                        ],
                        [
                            -81.7793619996516,
                            37.2799819997897
                        ],
                        [
                            -81.7824480001134,
                            37.2823199997832
                        ],
                        [
                            -81.7892939998083,
                            37.2844160000754
                        ],
                        [
                            -81.7931219997116,
                            37.2811679999001
                        ],
                        [
                            -81.793595000438,
                            37.2848380000164
                        ],
                        [
                            -81.8006760003094,
                            37.2856759998548
                        ],
                        [
                            -81.8047250001457,
                            37.2859259999235
                        ],
                        [
                            -81.807231999576,
                            37.2831750000169
                        ],
                        [
                            -81.8167019997754,
                            37.2798650001377
                        ],
                        [
                            -81.8257510000252,
                            37.2790930003004
                        ],
                        [
                            -81.8285060000509,
                            37.2814700003178
                        ],
                        [
                            -81.8344699996638,
                            37.2817620000428
                        ],
                        [
                            -81.8334050001821,
                            37.2845370001599
                        ],
                        [
                            -81.834432000029,
                            37.2854169998597
                        ],
                        [
                            -81.8499489996819,
                            37.2852270001134
                        ],
                        [
                            -81.8499649995754,
                            37.2874289996649
                        ],
                        [
                            -81.8534410000246,
                            37.2876260000905
                        ],
                        [
                            -81.8540650003637,
                            37.2900370002583
                        ],
                        [
                            -81.8522819999332,
                            37.2965749998132
                        ],
                        [
                            -81.852844000348,
                            37.29810900002
                        ],
                        [
                            -81.8548410001404,
                            37.2980310002436
                        ],
                        [
                            -81.8544600003739,
                            37.3065700001148
                        ],
                        [
                            -81.8596239999206,
                            37.3047649997662
                        ],
                        [
                            -81.8656829999681,
                            37.3094840000863
                        ],
                        [
                            -81.8599279996942,
                            37.3139650002915
                        ],
                        [
                            -81.8604890002841,
                            37.3159420001305
                        ],
                        [
                            -81.867194000412,
                            37.320717000007
                        ],
                        [
                            -81.87245199967,
                            37.3223620000841
                        ],
                        [
                            -81.8740130003426,
                            37.327626000229
                        ],
                        [
                            -81.8783450000361,
                            37.3288299998632
                        ],
                        [
                            -81.877320999664,
                            37.3314189997443
                        ],
                        [
                            -81.8793540003397,
                            37.3324930002647
                        ],
                        [
                            -81.8821760003126,
                            37.3301529997216
                        ],
                        [
                            -81.890347999605,
                            37.3318780001177
                        ],
                        [
                            -81.8938840003287,
                            37.3300159998285
                        ],
                        [
                            -81.8977430001942,
                            37.333214000307
                        ],
                        [
                            -81.8966319997834,
                            37.3353830000377
                        ],
                        [
                            -81.8996010000694,
                            37.3416070002343
                        ],
                        [
                            -81.903895000128,
                            37.3432259997695
                        ],
                        [
                            -81.9071609996166,
                            37.3426360002686
                        ],
                        [
                            -81.9086629998397,
                            37.3439249996719
                        ],
                        [
                            -81.9079110003524,
                            37.3452240001305
                        ],
                        [
                            -81.9096479998538,
                            37.3454549999896
                        ],
                        [
                            -81.9102200003143,
                            37.3489550002074
                        ],
                        [
                            -81.9176500001652,
                            37.3491529998811
                        ],
                        [
                            -81.9217619997507,
                            37.3570729999112
                        ],
                        [
                            -81.9238160003428,
                            37.3582719998383
                        ],
                        [
                            -81.925301999774,
                            37.3569690001349
                        ],
                        [
                            -81.9284619998557,
                            37.3604610000109
                        ],
                        [
                            -81.9260449999388,
                            37.3655130000355
                        ],
                        [
                            -81.9333819999033,
                            37.369341000343
                        ],
                        [
                            -81.933973999557,
                            37.3731629999834
                        ],
                        [
                            -81.9311430002616,
                            37.3757569996797
                        ],
                        [
                            -81.9358719996151,
                            37.3785539999854
                        ],
                        [
                            -81.9367439996513,
                            37.3807300001581
                        ],
                        [
                            -81.9357100001318,
                            37.3831469997992
                        ],
                        [
                            -81.9318110000833,
                            37.3854789997212
                        ],
                        [
                            -81.9338830002187,
                            37.3906519999512
                        ],
                        [
                            -81.9287780002232,
                            37.3938450002123
                        ],
                        [
                            -81.9300500002925,
                            37.39712399997
                        ],
                        [
                            -81.9277499996531,
                            37.3987949999312
                        ],
                        [
                            -81.9307859998862,
                            37.4016560003169
                        ],
                        [
                            -81.9300419998965,
                            37.4052909996883
                        ],
                        [
                            -81.9242819995998,
                            37.4073159999938
                        ],
                        [
                            -81.9245519999366,
                            37.4099860003547
                        ],
                        [
                            -81.9229560000022,
                            37.4113890003303
                        ],
                        [
                            -81.9324680000343,
                            37.4152169997977
                        ],
                        [
                            -81.9349729998148,
                            37.4200170002857
                        ],
                        [
                            -81.9378800001765,
                            37.4200860002899
                        ],
                        [
                            -81.9389120000462,
                            37.4220020002937
                        ],
                        [
                            -81.9382900002553,
                            37.4240470000617
                        ],
                        [
                            -81.9405530001864,
                            37.4290579997345
                        ],
                        [
                            -81.9378380003437,
                            37.4321109998212
                        ],
                        [
                            -81.9356470003826,
                            37.4319989999673
                        ],
                        [
                            -81.9371739998217,
                            37.4339949997086
                        ],
                        [
                            -81.9356210004433,
                            37.4383970002599
                        ],
                        [
                            -81.9388429995509,
                            37.440463000304
                        ],
                        [
                            -81.9457650003121,
                            37.4402140002805
                        ],
                        [
                            -81.9491239996871,
                            37.4455750000745
                        ],
                        [
                            -81.9540560003285,
                            37.4463209998714
                        ],
                        [
                            -81.9575789997351,
                            37.4491230003365
                        ],
                        [
                            -81.9663180003635,
                            37.4467209997716
                        ],
                        [
                            -81.9703830000934,
                            37.4476880002293
                        ],
                        [
                            -81.9687949996566,
                            37.4514960000834
                        ],
                        [
                            -81.976176000002,
                            37.4571859998218
                        ],
                        [
                            -81.9800130001262,
                            37.4572100002837
                        ],
                        [
                            -81.9862050002431,
                            37.4545189997176
                        ],
                        [
                            -81.9879259998509,
                            37.4564240002265
                        ],
                        [
                            -81.98762899975,
                            37.4586610002635
                        ],
                        [
                            -81.9922700001383,
                            37.4609160000437
                        ],
                        [
                            -81.9934230003819,
                            37.4638720002087
                        ],
                        [
                            -81.9929720002952,
                            37.4684500003555
                        ],
                        [
                            -81.9956489996048,
                            37.4698330002647
                        ],
                        [
                            -81.9965780004407,
                            37.4767050000523
                        ],
                        [
                            -81.992973999945,
                            37.4797969998072
                        ],
                        [
                            -81.9929160002187,
                            37.4829690003166
                        ],
                        [
                            -81.9857030001028,
                            37.4856810001273
                        ],
                        [
                            -81.9775929998362,
                            37.4846029997109
                        ],
                        [
                            -81.9757519996795,
                            37.4876890001964
                        ],
                        [
                            -81.9706590002779,
                            37.4884679998153
                        ],
                        [
                            -81.970730000423,
                            37.4899039999712
                        ],
                        [
                            -81.9671290003003,
                            37.4907430002837
                        ],
                        [
                            -81.9649860000198,
                            37.4934880001634
                        ],
                        [
                            -81.95326399976,
                            37.4917629996553
                        ],
                        [
                            -81.9527349998555,
                            37.494162000238
                        ],
                        [
                            -81.9543640003001,
                            37.4960840002322
                        ],
                        [
                            -81.9531469995841,
                            37.5013139999822
                        ],
                        [
                            -81.9439119995614,
                            37.5029289999707
                        ],
                        [
                            -81.944187999746,
                            37.5069760001888
                        ],
                        [
                            -81.9426400003905,
                            37.5088439997131
                        ],
                        [
                            -81.9276810000561,
                            37.5121129996969
                        ],
                        [
                            -81.9262430003055,
                            37.5135739996789
                        ],
                        [
                            -81.9337400001036,
                            37.5194910002012
                        ],
                        [
                            -81.9377610003508,
                            37.5135800000778
                        ],
                        [
                            -81.9414579998346,
                            37.5124700000109
                        ],
                        [
                            -81.9447479996126,
                            37.5136399997652
                        ],
                        [
                            -81.9454380000739,
                            37.5165130001657
                        ],
                        [
                            -81.9435080002287,
                            37.52075999993
                        ],
                        [
                            -81.9475920002251,
                            37.5266500003254
                        ],
                        [
                            -81.9439640003383,
                            37.5312819998222
                        ],
                        [
                            -81.9464749999666,
                            37.5319029998055
                        ],
                        [
                            -81.9541800001771,
                            37.5281440003386
                        ],
                        [
                            -81.9573889997643,
                            37.5296049999095
                        ],
                        [
                            -81.9575279996815,
                            37.5355049999199
                        ],
                        [
                            -81.967717999581,
                            37.5330970000537
                        ],
                        [
                            -81.9689539996653,
                            37.535672999991
                        ],
                        [
                            -81.964872000217,
                            37.5429370001752
                        ],
                        [
                            -81.9700919998402,
                            37.5467239998384
                        ],
                        [
                            -81.9760449995825,
                            37.5458269996862
                        ],
                        [
                            -81.9817300004344,
                            37.5421439999699
                        ],
                        [
                            -81.9891059998587,
                            37.5427799997192
                        ],
                        [
                            -81.9941489999299,
                            37.5377449996656
                        ],
                        [
                            -81.9967139999849,
                            37.5386160000639
                        ],
                        [
                            -81.9964589997167,
                            37.5417539998458
                        ],
                        [
                            -81.9982890000028,
                            37.5432979998244
                        ],
                        [
                            -82.000583999721,
                            37.5422089996735
                        ],
                        [
                            -82.0059520003806,
                            37.5345730001825
                        ],
                        [
                            -82.0108079999557,
                            37.5328650000853
                        ],
                        [
                            -82.0160059998377,
                            37.5339539999346
                        ],
                        [
                            -82.0174350002658,
                            37.5392190002424
                        ],
                        [
                            -82.0196170000061,
                            37.5406450001665
                        ],
                        [
                            -82.0261120000716,
                            37.537720000043
                        ],
                        [
                            -82.0296379998512,
                            37.5378489998866
                        ],
                        [
                            -82.0370169996485,
                            37.5424589997041
                        ],
                        [
                            -82.0387060003676,
                            37.5472399996615
                        ],
                        [
                            -82.0413769998294,
                            37.5483000002169
                        ],
                        [
                            -82.045050999747,
                            37.5456750002925
                        ],
                        [
                            -82.0420460003743,
                            37.5339980000907
                        ],
                        [
                            -82.0442909998638,
                            37.5288119996879
                        ],
                        [
                            -82.0466459998565,
                            37.5279930000343
                        ],
                        [
                            -82.048717000167,
                            37.5292749996835
                        ],
                        [
                            -82.0485210003484,
                            37.53369499993
                        ],
                        [
                            -82.0502989998577,
                            37.5354360002644
                        ],
                        [
                            -82.0648349996945,
                            37.5371959997255
                        ],
                        [
                            -82.0639099999548,
                            37.5431249997056
                        ],
                        [
                            -82.0751789995798,
                            37.5558530002306
                        ],
                        [
                            -82.0890320004246,
                            37.5561080000542
                        ],
                        [
                            -82.1040260000207,
                            37.5528720001602
                        ],
                        [
                            -82.1053549999914,
                            37.5542619997413
                        ],
                        [
                            -82.1019599997331,
                            37.5584200003165
                        ],
                        [
                            -82.1048079996452,
                            37.5601909997206
                        ],
                        [
                            -82.117996999968,
                            37.5589870001244
                        ],
                        [
                            -82.123414000133,
                            37.5515299997314
                        ],
                        [
                            -82.1332469996572,
                            37.5528320000447
                        ],
                        [
                            -82.1353239998155,
                            37.5573509998936
                        ],
                        [
                            -82.1335260002147,
                            37.5603449997229
                        ],
                        [
                            -82.1344240001902,
                            37.5629280000269
                        ],
                        [
                            -82.144383999936,
                            37.5663110000321
                        ],
                        [
                            -82.1449139996654,
                            37.5676549999606
                        ],
                        [
                            -82.1441780000716,
                            37.569967999718
                        ],
                        [
                            -82.1417639996294,
                            37.5710859999775
                        ],
                        [
                            -82.129120999828,
                            37.5721380003053
                        ],
                        [
                            -82.1262400003038,
                            37.5737380000839
                        ],
                        [
                            -82.1244219997133,
                            37.5773579999822
                        ],
                        [
                            -82.1273399999457,
                            37.5865449997684
                        ],
                        [
                            -82.1312880003979,
                            37.5927070000799
                        ],
                        [
                            -82.1398849998379,
                            37.5949670002522
                        ],
                        [
                            -82.1483819997186,
                            37.5907200002498
                        ],
                        [
                            -82.1568510000103,
                            37.592722999821
                        ],
                        [
                            -82.1566829997809,
                            37.6089689997985
                        ],
                        [
                            -82.1658149998714,
                            37.6076929996725
                        ],
                        [
                            -82.1687749999365,
                            37.6090799999134
                        ],
                        [
                            -82.1696140003607,
                            37.6130270003224
                        ],
                        [
                            -82.1642459997012,
                            37.6202900002131
                        ],
                        [
                            -82.1689749999531,
                            37.6219069997291
                        ],
                        [
                            -82.1767260001944,
                            37.6181520001288
                        ],
                        [
                            -82.18119799963,
                            37.6215100003354
                        ],
                        [
                            -82.1827159997465,
                            37.6243699998822
                        ],
                        [
                            -82.1816910004478,
                            37.6271470000813
                        ],
                        [
                            -82.1724120000442,
                            37.6326339999236
                        ],
                        [
                            -82.1776359998654,
                            37.6408489998128
                        ],
                        [
                            -82.1750750000084,
                            37.6478319996866
                        ],
                        [
                            -82.1858060004063,
                            37.6485950000625
                        ],
                        [
                            -82.1911160004413,
                            37.6445859999838
                        ],
                        [
                            -82.1866990003589,
                            37.6351419999958
                        ],
                        [
                            -82.18592900043,
                            37.62891000005
                        ],
                        [
                            -82.1872280002634,
                            37.626984000168
                        ],
                        [
                            -82.1922550004411,
                            37.6256539997527
                        ],
                        [
                            -82.2008900004142,
                            37.6279900001259
                        ],
                        [
                            -82.2048539998617,
                            37.62523499993
                        ],
                        [
                            -82.2144989999632,
                            37.6254000000996
                        ],
                        [
                            -82.2159529996073,
                            37.6262090000724
                        ],
                        [
                            -82.2145950002226,
                            37.6274350002983
                        ],
                        [
                            -82.220502999759,
                            37.6347689997144
                        ],
                        [
                            -82.216848000108,
                            37.6415979997247
                        ],
                        [
                            -82.2241549999353,
                            37.6447130002067
                        ],
                        [
                            -82.2261559999257,
                            37.6529129998384
                        ],
                        [
                            -82.2334419998365,
                            37.6556859996773
                        ],
                        [
                            -82.236066000341,
                            37.6596340000675
                        ],
                        [
                            -82.2408940003271,
                            37.6615309996787
                        ],
                        [
                            -82.2519010000112,
                            37.6568470001375
                        ],
                        [
                            -82.2580680000137,
                            37.6568830001482
                        ],
                        [
                            -82.2713119996897,
                            37.6636170000577
                        ],
                        [
                            -82.2824859997785,
                            37.6759250002808
                        ],
                        [
                            -82.2845010000125,
                            37.6752359999866
                        ],
                        [
                            -82.2881189998536,
                            37.6681079996773
                        ],
                        [
                            -82.2945610002157,
                            37.6704329998526
                        ],
                        [
                            -82.2950320003939,
                            37.6780530003094
                        ],
                        [
                            -82.3043140002722,
                            37.6760929999013
                        ],
                        [
                            -82.3040680002249,
                            37.678306999811
                        ],
                        [
                            -82.2959239996232,
                            37.6861819997145
                        ],
                        [
                            -82.3029400000957,
                            37.6936110001448
                        ],
                        [
                            -82.3019579995563,
                            37.6968040000075
                        ],
                        [
                            -82.2966890004276,
                            37.6998590002097
                        ],
                        [
                            -82.2963749997099,
                            37.7021849996989
                        ],
                        [
                            -82.3003449999035,
                            37.7058159997319
                        ],
                        [
                            -82.3076779996701,
                            37.708005999999
                        ],
                        [
                            -82.3107969997439,
                            37.7132550001557
                        ],
                        [
                            -82.3114229997328,
                            37.7184739997024
                        ],
                        [
                            -82.316105000129,
                            37.7210810000443
                        ],
                        [
                            -82.3188540003069,
                            37.7336950002229
                        ],
                        [
                            -82.3273439996167,
                            37.7364099998696
                        ],
                        [
                            -82.3341740003164,
                            37.7427709997678
                        ],
                        [
                            -82.327745000373,
                            37.7492160003544
                        ],
                        [
                            -82.3212939997901,
                            37.7515870002077
                        ],
                        [
                            -82.3199509995757,
                            37.7585579999367
                        ],
                        [
                            -82.3112679999221,
                            37.7613309998895
                        ],
                        [
                            -82.3119859999725,
                            37.7643200001415
                        ],
                        [
                            -82.3168249998292,
                            37.7654550002961
                        ],
                        [
                            -82.3285200003248,
                            37.7621179998601
                        ],
                        [
                            -82.333380000098,
                            37.7644139999361
                        ],
                        [
                            -82.3336050002289,
                            37.7671500002066
                        ],
                        [
                            -82.3230579999539,
                            37.7726780000432
                        ],
                        [
                            -82.3238859996092,
                            37.7749950000077
                        ],
                        [
                            -82.3374649999193,
                            37.7749139997558
                        ],
                        [
                            -82.3395479999254,
                            37.7770960002402
                        ],
                        [
                            -82.3400860000507,
                            37.7856250000882
                        ],
                        [
                            -82.3490259996223,
                            37.7869330002211
                        ],
                        [
                            -82.3550529998827,
                            37.7933539999997
                        ],
                        [
                            -82.3638889996972,
                            37.7950020002965
                        ],
                        [
                            -82.3690370002487,
                            37.8012589997765
                        ],
                        [
                            -82.3766410001769,
                            37.8022069996615
                        ],
                        [
                            -82.3799829998335,
                            37.8111880003465
                        ],
                        [
                            -82.3867249997713,
                            37.8182249999073
                        ],
                        [
                            -82.3966219997679,
                            37.8089320002068
                        ],
                        [
                            -82.4013219997074,
                            37.8095120001759
                        ],
                        [
                            -82.4022029999644,
                            37.8129370003477
                        ],
                        [
                            -82.3982269999231,
                            37.8213780001483
                        ],
                        [
                            -82.3993459998314,
                            37.8295230001062
                        ],
                        [
                            -82.4086570000221,
                            37.8360690001289
                        ],
                        [
                            -82.4129369998371,
                            37.8448009996453
                        ],
                        [
                            -82.4204930000847,
                            37.8464329997945
                        ],
                        [
                            -82.4147090003969,
                            37.8559699997956
                        ],
                        [
                            -82.4246230001119,
                            37.8614490002163
                        ],
                        [
                            -82.4226809996728,
                            37.863859000052
                        ],
                        [
                            -82.4097080001584,
                            37.8655420001581
                        ],
                        [
                            -82.4075129999994,
                            37.8672969998703
                        ],
                        [
                            -82.4175599997837,
                            37.8702789997264
                        ],
                        [
                            -82.4193919997197,
                            37.8746140002161
                        ],
                        [
                            -82.4199290000201,
                            37.8848699997914
                        ],
                        [
                            -82.4320320000464,
                            37.8898760000715
                        ],
                        [
                            -82.4375649996641,
                            37.8997859999529
                        ],
                        [
                            -82.4473310001394,
                            37.9038569998104
                        ],
                        [
                            -82.4518960003598,
                            37.908551000322
                        ],
                        [
                            -82.4599940000324,
                            37.9095359998183
                        ],
                        [
                            -82.4629909999075,
                            37.9148199996738
                        ],
                        [
                            -82.4679650003817,
                            37.9139270001572
                        ],
                        [
                            -82.4683820001332,
                            37.9032069998159
                        ],
                        [
                            -82.471389999879,
                            37.8990859996587
                        ],
                        [
                            -82.4744069998455,
                            37.8999549998394
                        ],
                        [
                            -82.4764329999502,
                            37.9027250002174
                        ],
                        [
                            -82.4742400003393,
                            37.9073649997772
                        ],
                        [
                            -82.4755740003328,
                            37.9118429997549
                        ],
                        [
                            -82.4792890002583,
                            37.9147009997492
                        ],
                        [
                            -82.4883460000057,
                            37.9177239996683
                        ],
                        [
                            -82.487714000169,
                            37.9199920000351
                        ],
                        [
                            -82.480762000169,
                            37.9246459997629
                        ],
                        [
                            -82.4806569996887,
                            37.926168999848
                        ],
                        [
                            -82.4959409997131,
                            37.9269539996778
                        ],
                        [
                            -82.5024340001288,
                            37.933452999985
                        ],
                        [
                            -82.4999539995644,
                            37.9368209999866
                        ],
                        [
                            -82.491340000406,
                            37.9357430002699
                        ],
                        [
                            -82.4892769995931,
                            37.9382169998385
                        ],
                        [
                            -82.4969690002832,
                            37.9420199996482
                        ],
                        [
                            -82.4972200003534,
                            37.9462450000521
                        ],
                        [
                            -82.4855590001929,
                            37.9458060000102
                        ],
                        [
                            -82.4717749998434,
                            37.9587389998125
                        ],
                        [
                            -82.4722169997093,
                            37.9605170002823
                        ],
                        [
                            -82.4844589996528,
                            37.9635779998231
                        ],
                        [
                            -82.4844310000637,
                            37.9709210001623
                        ],
                        [
                            -82.4812240001263,
                            37.9730720001128
                        ],
                        [
                            -82.4688509997633,
                            37.9732299998672
                        ],
                        [
                            -82.4645569997046,
                            37.9774409997171
                        ],
                        [
                            -82.4645659999254,
                            37.9838760001252
                        ],
                        [
                            -82.4721899999451,
                            37.9867100001722
                        ],
                        [
                            -82.4835940001876,
                            37.9838440003537
                        ],
                        [
                            -82.4860720002039,
                            37.9898659996697
                        ],
                        [
                            -82.4860579999602,
                            37.995646999913
                        ],
                        [
                            -82.4886549998022,
                            37.9989119999994
                        ],
                        [
                            -82.499859999853,
                            37.998896000209
                        ],
                        [
                            -82.508423999681,
                            38.0015069998937
                        ],
                        [
                            -82.5136359998066,
                            37.9990949999202
                        ],
                        [
                            -82.5191929997138,
                            38.0011310002115
                        ],
                        [
                            -82.5155470002836,
                            38.0066069997879
                        ],
                        [
                            -82.5197550001286,
                            38.0083750003475
                        ],
                        [
                            -82.5250690003614,
                            38.0175030000089
                        ],
                        [
                            -82.5263179999662,
                            38.0269709996552
                        ],
                        [
                            -82.531920999904,
                            38.0292920002212
                        ],
                        [
                            -82.5387180000934,
                            38.0374000000908
                        ],
                        [
                            -82.5370989996945,
                            38.0446509998897
                        ],
                        [
                            -82.5441550003499,
                            38.0525919997093
                        ],
                        [
                            -82.5448430002631,
                            38.0590759998368
                        ],
                        [
                            -82.5494999996466,
                            38.0632339997087
                        ],
                        [
                            -82.5498489996261,
                            38.0694520003385
                        ],
                        [
                            -82.5596319998199,
                            38.0727810003108
                        ],
                        [
                            -82.5659889997931,
                            38.0809969998657
                        ],
                        [
                            -82.5738530000123,
                            38.0819190002587
                        ],
                        [
                            -82.5833569996485,
                            38.090032000168
                        ],
                        [
                            -82.5855459999598,
                            38.0949729998862
                        ],
                        [
                            -82.5853419997453,
                            38.1066339998481
                        ],
                        [
                            -82.5870060003501,
                            38.1086639998845
                        ],
                        [
                            -82.5924119997461,
                            38.1104159996502
                        ],
                        [
                            -82.5986909999016,
                            38.1166949998082
                        ],
                        [
                            -82.6061609999356,
                            38.1207849998421
                        ],
                        [
                            -82.6192280000596,
                            38.1205399998425
                        ],
                        [
                            -82.6209019998117,
                            38.1227870002494
                        ],
                        [
                            -82.6218349999473,
                            38.1332910002491
                        ],
                        [
                            -82.6365730003487,
                            38.1380200000935
                        ],
                        [
                            -82.6386279998674,
                            38.1559149998556
                        ],
                        [
                            -82.6445389997769,
                            38.1652489999802
                        ],
                        [
                            -82.6429520000633,
                            38.1694459997628
                        ],
                        [
                            -82.6388549996481,
                            38.1711489996698
                        ],
                        [
                            -82.6193650003269,
                            38.1688769998328
                        ],
                        [
                            -82.6116009996668,
                            38.1713389998827
                        ],
                        [
                            -82.5991369999655,
                            38.1972049999134
                        ],
                        [
                            -82.5982950000665,
                            38.2173610000338
                        ],
                        [
                            -82.6086149996624,
                            38.2234989997142
                        ],
                        [
                            -82.6122159997851,
                            38.2362969997595
                        ],
                        [
                            -82.6081279995907,
                            38.244829999706
                        ],
                        [
                            -82.6046250002756,
                            38.2476620003469
                        ],
                        [
                            -82.594508999996,
                            38.2455029996672
                        ],
                        [
                            -82.5833170003639,
                            38.246793000023
                        ],
                        [
                            -82.5746239997662,
                            38.2639369997117
                        ],
                        [
                            -82.5740980002347,
                            38.2746350003227
                        ],
                        [
                            -82.5787859995804,
                            38.2819440003197
                        ],
                        [
                            -82.5795010001561,
                            38.2909869997791
                        ],
                        [
                            -82.5830350003316,
                            38.2968069999903
                        ],
                        [
                            -82.5790049998635,
                            38.3047789998151
                        ],
                        [
                            -82.5719230001671,
                            38.3133470002619
                        ],
                        [
                            -82.5717559997626,
                            38.3171949999026
                        ],
                        [
                            -82.5753950004184,
                            38.3229799997279
                        ],
                        [
                            -82.5767200001911,
                            38.3285150000502
                        ],
                        [
                            -82.5845879997099,
                            38.3337330001576
                        ],
                        [
                            -82.5891610003262,
                            38.3402600001984
                        ],
                        [
                            -82.5955070004288,
                            38.3424890001566
                        ],
                        [
                            -82.5981209999892,
                            38.3459330002943
                        ],
                        [
                            -82.5975939997346,
                            38.3649120000209
                        ],
                        [
                            -82.5927380001594,
                            38.376806999789
                        ],
                        [
                            -82.5994580003559,
                            38.3907810002104
                        ],
                        [
                            -82.5987329997345,
                            38.3937619998797
                        ],
                        [
                            -82.5945540002018,
                            38.3982689997722
                        ],
                        [
                            -82.5970670003782,
                            38.4108149996503
                        ],
                        [
                            -82.5959719998609,
                            38.4178799999761
                        ],
                        [
                            -82.5934799996009,
                            38.4218209999638
                        ],
                        [
                            -82.5934820001491,
                            38.418251999733
                        ],
                        [
                            -82.5888030001259,
                            38.4136340003307
                        ],
                        [
                            -82.5734250003902,
                            38.4056890003053
                        ],
                        [
                            -82.5410060001389,
                            38.4037999997694
                        ],
                        [
                            -82.5185099998235,
                            38.4080779997946
                        ],
                        [
                            -82.4861969998774,
                            38.4181780001308
                        ],
                        [
                            -82.4404760002238,
                            38.4284820002875
                        ],
                        [
                            -82.4048819998222,
                            38.4393469997853
                        ],
                        [
                            -82.3897459999358,
                            38.4343550003106
                        ],
                        [
                            -82.3817729999366,
                            38.4347830001495
                        ],
                        [
                            -82.3406400000696,
                            38.4409480000168
                        ],
                        [
                            -82.330334999644,
                            38.4444999997893
                        ],
                        [
                            -82.3239989995871,
                            38.4492680003248
                        ],
                        [
                            -82.3181110001422,
                            38.4578759996546
                        ],
                        [
                            -82.3139350000843,
                            38.4680839996612
                        ],
                        [
                            -82.3106389995601,
                            38.4831720003419
                        ],
                        [
                            -82.3042230000356,
                            38.4963080003301
                        ],
                        [
                            -82.3030709996169,
                            38.5043840000274
                        ],
                        [
                            -82.3039710001405,
                            38.5176829999295
                        ],
                        [
                            -82.3028709996004,
                            38.5231829997915
                        ],
                        [
                            -82.2956709999032,
                            38.5384830001802
                        ],
                        [
                            -82.2933710001622,
                            38.5569830002151
                        ],
                        [
                            -82.2938709997544,
                            38.5726830001317
                        ],
                        [
                            -82.2917710000298,
                            38.5747829998925
                        ],
                        [
                            -82.2934709997213,
                            38.5742830003337
                        ],
                        [
                            -82.2912710004377,
                            38.5789829999478
                        ],
                        [
                            -82.2742700001051,
                            38.5936830001562
                        ],
                        [
                            -82.2620699999944,
                            38.5981829998719
                        ],
                        [
                            -82.2466589997484,
                            38.5985690001872
                        ],
                        [
                            -82.2456689997114,
                            38.5981829998719
                        ],
                        [
                            -82.2479690003508,
                            38.5972830000301
                        ],
                        [
                            -82.2271679997039,
                            38.5937830000162
                        ],
                        [
                            -82.2214679996816,
                            38.5912840001904
                        ],
                        [
                            -82.2051710000541,
                            38.5917190001478
                        ],
                        [
                            -82.1887670002964,
                            38.5949839998994
                        ],
                        [
                            -82.1772669997944,
                            38.6037839996715
                        ],
                        [
                            -82.1731660000796,
                            38.6123839999266
                        ],
                        [
                            -82.1721669998218,
                            38.6271840000116
                        ],
                        [
                            -82.1790669999433,
                            38.6488830001328
                        ],
                        [
                            -82.1855670000317,
                            38.6595830001917
                        ],
                        [
                            -82.1860669996239,
                            38.6667830000967
                        ],
                        [
                            -82.1908670000209,
                            38.6803830000223
                        ],
                        [
                            -82.1901670004122,
                            38.6873820002009
                        ],
                        [
                            -82.1824670002245,
                            38.7087819998567
                        ],
                        [
                            -82.184566999949,
                            38.7158820001674
                        ],
                        [
                            -82.1912849995973,
                            38.7272039999949
                        ],
                        [
                            -82.1938329999338,
                            38.7356530003251
                        ],
                        [
                            -82.193098000165,
                            38.7377180002309
                        ],
                        [
                            -82.191667999912,
                            38.7373819998999
                        ],
                        [
                            -82.1913680003363,
                            38.7297820000918
                        ],
                        [
                            -82.1868680004133,
                            38.7222819998136
                        ],
                        [
                            -82.1879680000551,
                            38.7330820002436
                        ],
                        [
                            -82.1932680000443,
                            38.7411820003081
                        ],
                        [
                            -82.1956060003185,
                            38.7524409997798
                        ],
                        [
                            -82.1988819998528,
                            38.7577249997694
                        ],
                        [
                            -82.2071410000823,
                            38.7639430003166
                        ],
                        [
                            -82.2166139997563,
                            38.7683500001949
                        ],
                        [
                            -82.2196440001417,
                            38.7722830001105
                        ],
                        [
                            -82.221565999591,
                            38.7871870000884
                        ],
                        [
                            -82.2172690000575,
                            38.7956799997187
                        ],
                        [
                            -82.2114379996157,
                            38.8012200000246
                        ],
                        [
                            -82.1975400003617,
                            38.8095390002783
                        ],
                        [
                            -82.1911719996194,
                            38.8151369996697
                        ],
                        [
                            -82.1807750000307,
                            38.8169619999877
                        ],
                        [
                            -82.163597999971,
                            38.8234240001431
                        ],
                        [
                            -82.1448669998097,
                            38.8404799998005
                        ],
                        [
                            -82.1392239996889,
                            38.8650200001942
                        ],
                        [
                            -82.1413669999694,
                            38.8752800000035
                        ],
                        [
                            -82.1453670003002,
                            38.8841790000716
                        ],
                        [
                            -82.1432669996774,
                            38.8978790001817
                        ],
                        [
                            -82.140067000311,
                            38.901178999943
                        ],
                        [
                            -82.1361659997144,
                            38.9016790001075
                        ],
                        [
                            -82.1375659998302,
                            38.9018790000263
                        ],
                        [
                            -82.1359659996979,
                            38.904878999713
                        ],
                        [
                            -82.1293660000503,
                            38.9078789998593
                        ],
                        [
                            -82.1286660004416,
                            38.9106790000176
                        ],
                        [
                            -82.1132660000663,
                            38.9305790003466
                        ],
                        [
                            -82.1111660003417,
                            38.9365790000601
                        ],
                        [
                            -82.112366000441,
                            38.931778999668
                        ],
                        [
                            -82.1116669997588,
                            38.932578999816
                        ],
                        [
                            -82.109064999894,
                            38.9455790001285
                        ],
                        [
                            -82.0953650001084,
                            38.9637780001614
                        ],
                        [
                            -82.0906650001688,
                            38.9747780002627
                        ],
                        [
                            -82.0515630004293,
                            38.994377999932
                        ],
                        [
                            -82.0456630003905,
                            39.0037779999128
                        ],
                        [
                            -82.0420620002679,
                            39.0167779996764
                        ],
                        [
                            -82.0377489999426,
                            39.0238649999985
                        ],
                        [
                            -82.0218840001352,
                            39.0294910000964
                        ],
                        [
                            -82.0034609999455,
                            39.0284779996961
                        ],
                        [
                            -81.9949609996917,
                            39.0224779996593
                        ],
                        [
                            -81.9897610001599,
                            39.0160780002633
                        ],
                        [
                            -81.9837609996637,
                            39.0054780003098
                        ],
                        [
                            -81.9820319996599,
                            38.9956979998237
                        ],
                        [
                            -81.9793710002438,
                            38.9931929997637
                        ],
                        [
                            -81.9677689996346,
                            38.9929549999126
                        ],
                        [
                            -81.9514469998927,
                            38.9960320002535
                        ],
                        [
                            -81.9417170003007,
                            38.9932340002176
                        ],
                        [
                            -81.9331860000847,
                            38.9876600002086
                        ],
                        [
                            -81.9182150000548,
                            38.9665950000879
                        ],
                        [
                            -81.9124430000625,
                            38.9543430002145
                        ],
                        [
                            -81.9005950003043,
                            38.937670999729
                        ],
                        [
                            -81.8984699995671,
                            38.9296029998758
                        ],
                        [
                            -81.9009099999485,
                            38.924337999687
                        ],
                        [
                            -81.9266700001027,
                            38.9013109996518
                        ],
                        [
                            -81.9279990000734,
                            38.8934919998279
                        ],
                        [
                            -81.9068560000181,
                            38.8775659997929
                        ],
                        [
                            -81.8985409997122,
                            38.8745820001867
                        ],
                        [
                            -81.8892329998946,
                            38.8742789999329
                        ],
                        [
                            -81.8559709999194,
                            38.8927339998786
                        ],
                        [
                            -81.8486530002215,
                            38.9014069999849
                        ],
                        [
                            -81.8453120003899,
                            38.9100879999779
                        ],
                        [
                            -81.8444860003844,
                            38.9287460000986
                        ],
                        [
                            -81.8344389997017,
                            38.941426999758
                        ],
                        [
                            -81.8331399998683,
                            38.941050999827
                        ],
                        [
                            -81.8273539996323,
                            38.9458980000064
                        ],
                        [
                            -81.8142349996297,
                            38.9461679998392
                        ],
                        [
                            -81.8061369999571,
                            38.9421120001988
                        ],
                        [
                            -81.7933719999569,
                            38.9302030001975
                        ],
                        [
                            -81.7777130000138,
                            38.9238559999506
                        ],
                        [
                            -81.7662250001057,
                            38.9229469996567
                        ],
                        [
                            -81.7585049998265,
                            38.9279419999287
                        ],
                        [
                            -81.7561309995673,
                            38.9335460003133
                        ],
                        [
                            -81.7569750000144,
                            38.9371539997858
                        ],
                        [
                            -81.7610160003531,
                            38.9380030001878
                        ],
                        [
                            -81.7676670000543,
                            38.9468649998317
                        ],
                        [
                            -81.7788450003409,
                            38.9558919997408
                        ],
                        [
                            -81.7818199995764,
                            38.9649350001077
                        ],
                        [
                            -81.7798830000585,
                            38.97277300004
                        ],
                        [
                            -81.7757339997647,
                            38.9807370000018
                        ],
                        [
                            -81.7767229999768,
                            38.9851420001746
                        ],
                        [
                            -81.7740619996624,
                            38.9936820000531
                        ],
                        [
                            -81.7651530000529,
                            39.0025789997602
                        ],
                        [
                            -81.7642530004277,
                            39.0152790000205
                        ],
                        [
                            -81.7728540000655,
                            39.026178999734
                        ],
                        [
                            -81.7850540001762,
                            39.0358789998828
                        ],
                        [
                            -81.8003549999191,
                            39.0449780001825
                        ],
                        [
                            -81.8080550001067,
                            39.0537770000304
                        ],
                        [
                            -81.8123540001883,
                            39.0612790000035
                        ],
                        [
                            -81.8138539998632,
                            39.0796779997011
                        ],
                        [
                            -81.8106539995985,
                            39.083278000103
                        ],
                        [
                            -81.806654000166,
                            39.0840779998051
                        ],
                        [
                            -81.7981539999122,
                            39.0828779999876
                        ],
                        [
                            -81.7857549996099,
                            39.078577999858
                        ],
                        [
                            -81.7755549996646,
                            39.0783770000121
                        ],
                        [
                            -81.7607540004123,
                            39.0840769998886
                        ],
                        [
                            -81.7472539997449,
                            39.0953790000815
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 38.6472854,
            "name": "West Virginia",
            "intptlon": -80.6183274,
            "geo_point_2d": [
                38.6425782646,
                -80.6137013299
            ],
            "geoid": "54",
            "mtfcc": "G4000",
            "region": 3
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -80.6137013299,
                38.6425782646
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "53061e88c95185976a48a9f6c1d8176eea5dcf66",
        "fields": {
            "arealand": "-342263542",
            "objectid": 11,
            "basename": "Connecticut",
            "stusab": "CT",
            "statens": "01779780",
            "centlon": -72.7393081,
            "state": "09",
            "gid": 45,
            "centlat": 41.5751437,
            "division": 1,
            "areawater": "1815476291",
            "oid": "-1753705891",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -73.5180800000076,
                            41.666723000114
                        ],
                        [
                            -73.4922260001421,
                            41.9671779999716
                        ],
                        [
                            -73.487318999615,
                            42.0495250000787
                        ],
                        [
                            -73.4285149999438,
                            42.050476000308
                        ],
                        [
                            -73.0368019999179,
                            42.0392939998041
                        ],
                        [
                            -72.8137839998975,
                            42.0367440002959
                        ],
                        [
                            -72.8170529997591,
                            41.9976850001029
                        ],
                        [
                            -72.7667330004483,
                            42.0032660002259
                        ],
                        [
                            -72.7662530000493,
                            42.007870999957
                        ],
                        [
                            -72.7615010002312,
                            42.009302000017
                        ],
                        [
                            -72.7629769996166,
                            42.0134659998403
                        ],
                        [
                            -72.7589500004199,
                            42.0154690003262
                        ],
                        [
                            -72.7629470003777,
                            42.0196829997021
                        ],
                        [
                            -72.7658060001605,
                            42.0195680000881
                        ],
                        [
                            -72.7644770001898,
                            42.0208729999502
                        ],
                        [
                            -72.7652520001417,
                            42.0223660000544
                        ],
                        [
                            -72.7634870001529,
                            42.0211839999834
                        ],
                        [
                            -72.7605670002707,
                            42.0226500001952
                        ],
                        [
                            -72.7573410000668,
                            42.0209789998813
                        ],
                        [
                            -72.752061999994,
                            42.0290250003208
                        ],
                        [
                            -72.7525530002637,
                            42.0312490002311
                        ],
                        [
                            -72.7573009998838,
                            42.0333809997234
                        ],
                        [
                            -72.7558940000953,
                            42.0363490002855
                        ],
                        [
                            -72.6993299998908,
                            42.0369569998856
                        ],
                        [
                            -72.6401909995856,
                            42.0320480002329
                        ],
                        [
                            -72.6079780000969,
                            42.0310830001198
                        ],
                        [
                            -72.6071689998099,
                            42.0251480001561
                        ],
                        [
                            -72.5821629996911,
                            42.0247409997585
                        ],
                        [
                            -72.5727779998806,
                            42.0302190001131
                        ],
                        [
                            -72.5315489997541,
                            42.0345780002288
                        ],
                        [
                            -72.1431509996205,
                            42.0305220000485
                        ],
                        [
                            -72.0718260003466,
                            42.0276369996794
                        ],
                        [
                            -71.8006709996823,
                            42.0235140003054
                        ],
                        [
                            -71.7872389996853,
                            41.6561109999574
                        ],
                        [
                            -71.7977449999524,
                            41.4167269996722
                        ],
                        [
                            -71.8014389999614,
                            41.4155449997442
                        ],
                        [
                            -71.8036840003492,
                            41.4174279999441
                        ],
                        [
                            -71.8068119997456,
                            41.4166730001888
                        ],
                        [
                            -71.8180879999415,
                            41.4198520003067
                        ],
                        [
                            -71.8237310000624,
                            41.4172819997694
                        ],
                        [
                            -71.8253839998982,
                            41.4146870002032
                        ],
                        [
                            -71.8341069997347,
                            41.4115820002727
                        ],
                        [
                            -71.839257999761,
                            41.4123899998547
                        ],
                        [
                            -71.8424260002386,
                            41.4102600001307
                        ],
                        [
                            -71.8435910001778,
                            41.4053310003039
                        ],
                        [
                            -71.8410679999556,
                            41.399658000083
                        ],
                        [
                            -71.8422089996053,
                            41.3953800002228
                        ],
                        [
                            -71.838748999948,
                            41.3910539999132
                        ],
                        [
                            -71.8331300001166,
                            41.3878390001489
                        ],
                        [
                            -71.8335269997766,
                            41.3822640003247
                        ],
                        [
                            -71.8306370000317,
                            41.3787719998533
                        ],
                        [
                            -71.8326559995653,
                            41.3758499999467
                        ],
                        [
                            -71.8318439998036,
                            41.3703439996667
                        ],
                        [
                            -71.8376330004127,
                            41.3654989997534
                        ],
                        [
                            -71.8378729997138,
                            41.3601450001731
                        ],
                        [
                            -71.836044000151,
                            41.3539880002988
                        ],
                        [
                            -71.8313030002035,
                            41.3512949997362
                        ],
                        [
                            -71.8293649999624,
                            41.342432000211
                        ],
                        [
                            -71.8372800002352,
                            41.3350679999292
                        ],
                        [
                            -71.847708999611,
                            41.3296039999481
                        ],
                        [
                            -71.8574580003679,
                            41.3207889997028
                        ],
                        [
                            -71.8665379996816,
                            41.3191850000815
                        ],
                        [
                            -71.8920669998571,
                            41.3300750002019
                        ],
                        [
                            -71.9072579999951,
                            41.3044830002895
                        ],
                        [
                            -71.9294510003618,
                            41.3103869999966
                        ],
                        [
                            -71.9994860000231,
                            41.2957939999066
                        ],
                        [
                            -72.019213999894,
                            41.2905940000935
                        ],
                        [
                            -72.0817919999402,
                            41.2622360002248
                        ],
                        [
                            -72.9995470001829,
                            41.0871079999568
                        ],
                        [
                            -73.2495570002437,
                            41.0258660000926
                        ],
                        [
                            -73.6128849997861,
                            40.9509429998257
                        ],
                        [
                            -73.6596709998198,
                            40.9879090003188
                        ],
                        [
                            -73.6572279999636,
                            40.9909140001282
                        ],
                        [
                            -73.6595849996061,
                            40.9941399999662
                        ],
                        [
                            -73.6602680003947,
                            41.0004840000058
                        ],
                        [
                            -73.6552549995624,
                            41.0122460000961
                        ],
                        [
                            -73.7277749998109,
                            41.1006960002942
                        ],
                        [
                            -73.4826949998434,
                            41.2127720002509
                        ],
                        [
                            -73.5509620000412,
                            41.2954209999985
                        ],
                        [
                            -73.5414690002757,
                            41.4043940000537
                        ],
                        [
                            -73.5363340001429,
                            41.4466320001305
                        ],
                        [
                            -73.5180800000076,
                            41.666723000114
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 41.5797777,
            "name": "Connecticut",
            "intptlon": -72.7466665,
            "geo_point_2d": [
                41.5750199702,
                -72.738273382
            ],
            "geoid": "09",
            "mtfcc": "G4000",
            "region": 1
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -72.738273382,
                41.5750199702
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "8755aaf2bbaef43dcec57e6eaecbaf9df09a69be",
        "fields": {
            "arealand": "-82198335",
            "objectid": 19,
            "basename": "Pennsylvania",
            "stusab": "PA",
            "statens": "01779798",
            "centlon": -77.8369374,
            "state": "42",
            "gid": 53,
            "centlat": 40.9011252,
            "division": 2,
            "areawater": "-897854106",
            "oid": "-1921873091",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -80.5193540001497,
                            41.8495629999304
                        ],
                        [
                            -80.5198510002672,
                            42.3271320000067
                        ],
                        [
                            -80.0802220004285,
                            42.3941619999033
                        ],
                        [
                            -79.7624179999979,
                            42.5160720003242
                        ],
                        [
                            -79.7613130003332,
                            41.9988079999798
                        ],
                        [
                            -79.1247680000368,
                            41.9995069998212
                        ],
                        [
                            -78.8747589998008,
                            41.9975589999937
                        ],
                        [
                            -78.596650000385,
                            41.9998770002396
                        ],
                        [
                            -78.2712040003913,
                            41.998967999945
                        ],
                        [
                            -78.1247310001664,
                            42.000450999987
                        ],
                        [
                            -77.8283960002044,
                            41.9982590002622
                        ],
                        [
                            -77.5053079996781,
                            42.0000699998928
                        ],
                        [
                            -77.1268459998991,
                            41.9994140000273
                        ],
                        [
                            -76.8352660001282,
                            42.0021340002624
                        ],
                        [
                            -76.3469499998247,
                            41.9983199998714
                        ],
                        [
                            -75.9802499995901,
                            41.9990349998275
                        ],
                        [
                            -75.7611359996412,
                            41.9977899998921
                        ],
                        [
                            -75.3595790002203,
                            41.9994449999737
                        ],
                        [
                            -75.3411250000685,
                            41.9927720000785
                        ],
                        [
                            -75.3376019997637,
                            41.9866999999535
                        ],
                        [
                            -75.3422039997939,
                            41.9728719998952
                        ],
                        [
                            -75.3394880001263,
                            41.9707860002182
                        ],
                        [
                            -75.3289810000343,
                            41.9684199999232
                        ],
                        [
                            -75.3200399997395,
                            41.9608669999055
                        ],
                        [
                            -75.3181679996206,
                            41.9542360002594
                        ],
                        [
                            -75.3128169995778,
                            41.9501819996666
                        ],
                        [
                            -75.3030099999929,
                            41.9482859997714
                        ],
                        [
                            -75.3012330003086,
                            41.9489000003276
                        ],
                        [
                            -75.300408999953,
                            41.9538710002436
                        ],
                        [
                            -75.293713000046,
                            41.9545930002368
                        ],
                        [
                            -75.2914300000234,
                            41.9524769999868
                        ],
                        [
                            -75.2917539998884,
                            41.9470820003118
                        ],
                        [
                            -75.2893830000023,
                            41.9428910002001
                        ],
                        [
                            -75.2790940003685,
                            41.9389170003186
                        ],
                        [
                            -75.277243000166,
                            41.9335980001152
                        ],
                        [
                            -75.2765519998798,
                            41.9222080001717
                        ],
                        [
                            -75.2675620000795,
                            41.9070539998283
                        ],
                        [
                            -75.2677729999667,
                            41.9019710002003
                        ],
                        [
                            -75.2727780004031,
                            41.8971120001574
                        ],
                        [
                            -75.2712929998985,
                            41.8873579997753
                        ],
                        [
                            -75.2606229996,
                            41.8837830001439
                        ],
                        [
                            -75.2575639998006,
                            41.8771079997557
                        ],
                        [
                            -75.263815000367,
                            41.8707570001226
                        ],
                        [
                            -75.2628019998655,
                            41.8662129999334
                        ],
                        [
                            -75.2605270002388,
                            41.8638000000638
                        ],
                        [
                            -75.2578249999165,
                            41.8621540002807
                        ],
                        [
                            -75.2511969997816,
                            41.8620400000721
                        ],
                        [
                            -75.2411340001036,
                            41.8671179998335
                        ],
                        [
                            -75.2316120000258,
                            41.8594590001755
                        ],
                        [
                            -75.2237339995629,
                            41.8574560000975
                        ],
                        [
                            -75.2149699997184,
                            41.8674490002897
                        ],
                        [
                            -75.2040020003923,
                            41.8698669999136
                        ],
                        [
                            -75.1943820004052,
                            41.867287000321
                        ],
                        [
                            -75.1888880002472,
                            41.8612640000596
                        ],
                        [
                            -75.1852539996143,
                            41.8599300001413
                        ],
                        [
                            -75.1822709999829,
                            41.8621979998726
                        ],
                        [
                            -75.1776699997776,
                            41.871384000231
                        ],
                        [
                            -75.1725320001701,
                            41.8722390003188
                        ],
                        [
                            -75.1685019997021,
                            41.868929000083
                        ],
                        [
                            -75.1684060003409,
                            41.858146000001
                        ],
                        [
                            -75.1628539995583,
                            41.8505820000376
                        ],
                        [
                            -75.1548289996806,
                            41.8482919996658
                        ],
                        [
                            -75.1409380000994,
                            41.8521729997103
                        ],
                        [
                            -75.1309830003765,
                            41.8451449997715
                        ],
                        [
                            -75.1171029997675,
                            41.8454629997124
                        ],
                        [
                            -75.1133689995755,
                            41.8406979998892
                        ],
                        [
                            -75.115146999983,
                            41.8272850001486
                        ],
                        [
                            -75.1133340003137,
                            41.8227820001263
                        ],
                        [
                            -75.1013020004325,
                            41.8189639997628
                        ],
                        [
                            -75.0894839999132,
                            41.8115760000747
                        ],
                        [
                            -75.0857890000792,
                            41.8116260001525
                        ],
                        [
                            -75.0780629999523,
                            41.8151120001141
                        ],
                        [
                            -75.0721720001343,
                            41.8137319999612
                        ],
                        [
                            -75.0721760003322,
                            41.808318000153
                        ],
                        [
                            -75.0768889997923,
                            41.7985090001293
                        ],
                        [
                            -75.0814149996546,
                            41.796483000312
                        ],
                        [
                            -75.0883280001949,
                            41.797533999885
                        ],
                        [
                            -75.0928759997985,
                            41.7963860002225
                        ],
                        [
                            -75.101463000091,
                            41.787940999724
                        ],
                        [
                            -75.1046399998911,
                            41.7742029999383
                        ],
                        [
                            -75.1034919996704,
                            41.7712379999085
                        ],
                        [
                            -75.0999750001116,
                            41.7689009998311
                        ],
                        [
                            -75.0928099996762,
                            41.7683609997711
                        ],
                        [
                            -75.0769969997473,
                            41.7716490002105
                        ],
                        [
                            -75.064901000292,
                            41.7666859998392
                        ],
                        [
                            -75.0603720000567,
                            41.7642000000334
                        ],
                        [
                            -75.0532269997128,
                            41.7516620000464
                        ],
                        [
                            -75.0528080003115,
                            41.7447249997393
                        ],
                        [
                            -75.0548179996243,
                            41.735168000153
                        ],
                        [
                            -75.0498619995917,
                            41.7133090002474
                        ],
                        [
                            -75.0522259998052,
                            41.7113960001326
                        ],
                        [
                            -75.0666300002958,
                            41.7125879998192
                        ],
                        [
                            -75.0688299995794,
                            41.7081610000812
                        ],
                        [
                            -75.0598289999085,
                            41.6997160001879
                        ],
                        [
                            -75.0527360003414,
                            41.6883930002699
                        ],
                        [
                            -75.051285000172,
                            41.6799610000321
                        ],
                        [
                            -75.0587650002517,
                            41.6744119999973
                        ],
                        [
                            -75.059331999791,
                            41.6723199997565
                        ],
                        [
                            -75.0584299996176,
                            41.6696529998032
                        ],
                        [
                            -75.053990999794,
                            41.6681940000281
                        ],
                        [
                            -75.0499200002163,
                            41.6625560001132
                        ],
                        [
                            -75.0491150001273,
                            41.6484999997046
                        ],
                        [
                            -75.0476830002245,
                            41.6453559998215
                        ],
                        [
                            -75.0492809998087,
                            41.6418619998193
                        ],
                        [
                            -75.0486580001928,
                            41.6337810002243
                        ],
                        [
                            -75.0435620004181,
                            41.6236400003161
                        ],
                        [
                            -75.044224000392,
                            41.6179779997202
                        ],
                        [
                            -75.04729800026,
                            41.6157910001665
                        ],
                        [
                            -75.053850000227,
                            41.6186549997877
                        ],
                        [
                            -75.0600980004203,
                            41.6174820001131
                        ],
                        [
                            -75.0616750000881,
                            41.6154680002272
                        ],
                        [
                            -75.0597250001514,
                            41.6108009996674
                        ],
                        [
                            -75.0716669996209,
                            41.6095010002682
                        ],
                        [
                            -75.0746250000362,
                            41.607904999907
                        ],
                        [
                            -75.0746130003407,
                            41.6057110002607
                        ],
                        [
                            -75.0669549999858,
                            41.5994280000714
                        ],
                        [
                            -75.0600120002066,
                            41.5908129998302
                        ],
                        [
                            -75.0467600001346,
                            41.5832580001113
                        ],
                        [
                            -75.0435809997864,
                            41.5738729999515
                        ],
                        [
                            -75.0404900002,
                            41.5696879996749
                        ],
                        [
                            -75.033164999931,
                            41.5650930001573
                        ],
                        [
                            -75.02734299971,
                            41.5635410001093
                        ],
                        [
                            -75.018523999614,
                            41.5518019998077
                        ],
                        [
                            -75.0161440004053,
                            41.5442460000134
                        ],
                        [
                            -75.0247979997466,
                            41.539800999924
                        ],
                        [
                            -75.0247569997387,
                            41.5350990000007
                        ],
                        [
                            -75.0230179996892,
                            41.5331470000222
                        ],
                        [
                            -75.0149190001917,
                            41.5313989997456
                        ],
                        [
                            -75.0038499996849,
                            41.5240520000848
                        ],
                        [
                            -75.0009110004345,
                            41.5192919999806
                        ],
                        [
                            -75.0037059997449,
                            41.5111180000643
                        ],
                        [
                            -75.0031509999011,
                            41.5081010003047
                        ],
                        [
                            -74.9876450001189,
                            41.5087379996684
                        ],
                        [
                            -74.9843720000593,
                            41.506610999906
                        ],
                        [
                            -74.9821679996794,
                            41.498485999688
                        ],
                        [
                            -74.9855949997247,
                            41.4858629999914
                        ],
                        [
                            -74.9833410000144,
                            41.4808940000089
                        ],
                        [
                            -74.9698870002761,
                            41.477437999848
                        ],
                        [
                            -74.9582599995526,
                            41.4763960002052
                        ],
                        [
                            -74.9480799996988,
                            41.4806249999278
                        ],
                        [
                            -74.9456340003678,
                            41.483212999912
                        ],
                        [
                            -74.9417980000686,
                            41.4835419999716
                        ],
                        [
                            -74.9325849997872,
                            41.4823230001365
                        ],
                        [
                            -74.9240920001044,
                            41.4771380002057
                        ],
                        [
                            -74.9129909998106,
                            41.4759009996981
                        ],
                        [
                            -74.9091810003489,
                            41.4724360000124
                        ],
                        [
                            -74.9068869995573,
                            41.4611309998881
                        ],
                        [
                            -74.8950689999363,
                            41.4581899996729
                        ],
                        [
                            -74.8903580001261,
                            41.4553240001848
                        ],
                        [
                            -74.8890750001862,
                            41.4512449998024
                        ],
                        [
                            -74.8949309998441,
                            41.4460989996975
                        ],
                        [
                            -74.8963989997319,
                            41.4421790001435
                        ],
                        [
                            -74.8960249996381,
                            41.4399869997634
                        ],
                        [
                            -74.8926250002552,
                            41.438617000229
                        ],
                        [
                            -74.8801740000743,
                            41.4396479998555
                        ],
                        [
                            -74.8585780002825,
                            41.4444269998235
                        ],
                        [
                            -74.8486019997449,
                            41.4401790000222
                        ],
                        [
                            -74.8369149996452,
                            41.4316250001787
                        ],
                        [
                            -74.8306709996498,
                            41.4305029998495
                        ],
                        [
                            -74.8260309999847,
                            41.4317360003024
                        ],
                        [
                            -74.8228800001239,
                            41.4367920002204
                        ],
                        [
                            -74.8179950002364,
                            41.440505000227
                        ],
                        [
                            -74.8121229997867,
                            41.4429820002611
                        ],
                        [
                            -74.8075819998558,
                            41.4428470001613
                        ],
                        [
                            -74.8012249998825,
                            41.438100000245
                        ],
                        [
                            -74.7995460001074,
                            41.4312899996859
                        ],
                        [
                            -74.7953959999888,
                            41.4239799999254
                        ],
                        [
                            -74.79041700039,
                            41.4216599997107
                        ],
                        [
                            -74.773238999607,
                            41.4263519998549
                        ],
                        [
                            -74.7585870003176,
                            41.423286999931
                        ],
                        [
                            -74.743822000152,
                            41.430635999683
                        ],
                        [
                            -74.7384560000406,
                            41.430642000059
                        ],
                        [
                            -74.7355200002649,
                            41.4274660000841
                        ],
                        [
                            -74.7347319998943,
                            41.4226999998832
                        ],
                        [
                            -74.7415690002666,
                            41.4099249999009
                        ],
                        [
                            -74.7385539999499,
                            41.4011909998565
                        ],
                        [
                            -74.7303850001321,
                            41.3956599998271
                        ],
                        [
                            -74.7208920003666,
                            41.3946899998523
                        ],
                        [
                            -74.7159799998166,
                            41.3925840001903
                        ],
                        [
                            -74.7084589997291,
                            41.3789020002422
                        ],
                        [
                            -74.694959999785,
                            41.3704309998669
                        ],
                        [
                            -74.689501999612,
                            41.3638509996945
                        ],
                        [
                            -74.6897560000553,
                            41.3615590002348
                        ],
                        [
                            -74.6966079995979,
                            41.3563230001401
                        ],
                        [
                            -74.7209230003288,
                            41.347383999891
                        ],
                        [
                            -74.7303730004366,
                            41.34598299974
                        ],
                        [
                            -74.7539309998607,
                            41.3461350003304
                        ],
                        [
                            -74.760327000192,
                            41.3403280003039
                        ],
                        [
                            -74.7634999997942,
                            41.3315699998926
                        ],
                        [
                            -74.7715880003194,
                            41.3250790002894
                        ],
                        [
                            -74.789095000092,
                            41.3232809996659
                        ],
                        [
                            -74.7941409996379,
                            41.3211870001742
                        ],
                        [
                            -74.7958219999611,
                            41.318515999859
                        ],
                        [
                            -74.7923770003724,
                            41.3140880001388
                        ],
                        [
                            -74.7925580001224,
                            41.3106279998106
                        ],
                        [
                            -74.8068589997825,
                            41.3031550000463
                        ],
                        [
                            -74.8120330002733,
                            41.2981580000488
                        ],
                        [
                            -74.821885000064,
                            41.2938370001974
                        ],
                        [
                            -74.8300570002548,
                            41.2871999997472
                        ],
                        [
                            -74.8383609997917,
                            41.2772939998218
                        ],
                        [
                            -74.8463189997223,
                            41.2630770000442
                        ],
                        [
                            -74.8450309997595,
                            41.2580549997704
                        ],
                        [
                            -74.8469389998634,
                            41.2533209999454
                        ],
                        [
                            -74.8489880004326,
                            41.251192000237
                        ],
                        [
                            -74.8571520002274,
                            41.248975000232
                        ],
                        [
                            -74.8674049998762,
                            41.2277689999888
                        ],
                        [
                            -74.8608370000157,
                            41.2223180000004
                        ],
                        [
                            -74.8596319998936,
                            41.2190769999869
                        ],
                        [
                            -74.874033999836,
                            41.1985430001635
                        ],
                        [
                            -74.8821390000797,
                            41.1808359997404
                        ],
                        [
                            -74.8997010001038,
                            41.1661810003187
                        ],
                        [
                            -74.9052560003611,
                            41.1556679997304
                        ],
                        [
                            -74.9231690000146,
                            41.1381459996679
                        ],
                        [
                            -74.9311410001888,
                            41.1333870002238
                        ],
                        [
                            -74.9458190003158,
                            41.1284889998498
                        ],
                        [
                            -74.9479120003676,
                            41.1235599998574
                        ],
                        [
                            -74.964294000384,
                            41.1142370003232
                        ],
                        [
                            -74.9729169997632,
                            41.1133270001162
                        ],
                        [
                            -74.9822120000603,
                            41.1082450001921
                        ],
                        [
                            -74.9913869998085,
                            41.093155000067
                        ],
                        [
                            -74.991815000329,
                            41.0891320002385
                        ],
                        [
                            -74.983320999923,
                            41.0889959998078
                        ],
                        [
                            -74.9694339996413,
                            41.0960740003338
                        ],
                        [
                            -74.9667589999815,
                            41.0934249998036
                        ],
                        [
                            -74.969123000195,
                            41.0868030000366
                        ],
                        [
                            -74.9825900003521,
                            41.0791720001225
                        ],
                        [
                            -74.9948470003642,
                            41.0765559996655
                        ],
                        [
                            -75.0068559997809,
                            41.0675960003157
                        ],
                        [
                            -75.0124100002132,
                            41.0672639996911
                        ],
                        [
                            -75.0175720001101,
                            41.055852999771
                        ],
                        [
                            -75.0271460000666,
                            41.045253999737
                        ],
                        [
                            -75.0271019996856,
                            41.039529000297
                        ],
                        [
                            -75.033493999819,
                            41.0376659999523
                        ],
                        [
                            -75.0417890000334,
                            41.0316999999404
                        ],
                        [
                            -75.070531999819,
                            41.0186200003036
                        ],
                        [
                            -75.0844950002686,
                            41.015920000155
                        ],
                        [
                            -75.0935229997038,
                            41.0094420002593
                        ],
                        [
                            -75.1091130000499,
                            41.0041019997295
                        ],
                        [
                            -75.1310960003544,
                            40.990464000284
                        ],
                        [
                            -75.1325220004094,
                            40.9812349999355
                        ],
                        [
                            -75.1355209999343,
                            40.9768650001528
                        ],
                        [
                            -75.1347009997766,
                            40.972038000141
                        ],
                        [
                            -75.1301659996935,
                            40.9689610002216
                        ],
                        [
                            -75.1226029997732,
                            40.9701519998839
                        ],
                        [
                            -75.1202319998871,
                            40.9687020001515
                        ],
                        [
                            -75.1208100001953,
                            40.9628840000639
                        ],
                        [
                            -75.1177670002895,
                            40.9530130002229
                        ],
                        [
                            -75.1116889999755,
                            40.9481270001058
                        ],
                        [
                            -75.1048089999455,
                            40.9352469997636
                        ],
                        [
                            -75.095527999892,
                            40.9241479997221
                        ],
                        [
                            -75.0792949998386,
                            40.9138799999606
                        ],
                        [
                            -75.076089999551,
                            40.9070390002257
                        ],
                        [
                            -75.0753449997365,
                            40.8941660002271
                        ],
                        [
                            -75.0536639995558,
                            40.8736599998746
                        ],
                        [
                            -75.051029000079,
                            40.8656619996646
                        ],
                        [
                            -75.0638019995768,
                            40.8555929999184
                        ],
                        [
                            -75.0632519997559,
                            40.8501919999627
                        ],
                        [
                            -75.0660079996065,
                            40.8475940000735
                        ],
                        [
                            -75.0708299997448,
                            40.8473919999235
                        ],
                        [
                            -75.0766839997529,
                            40.8498750002515
                        ],
                        [
                            -75.0909619998468,
                            40.8491869999158
                        ],
                        [
                            -75.0957839999851,
                            40.8470820003256
                        ],
                        [
                            -75.0975859997838,
                            40.8430419999839
                        ],
                        [
                            -75.0970059998256,
                            40.8393360000824
                        ],
                        [
                            -75.0855170000927,
                            40.8300850002087
                        ],
                        [
                            -75.0838220004241,
                            40.8278049999085
                        ],
                        [
                            -75.0839289996559,
                            40.8244710000487
                        ],
                        [
                            -75.0905180003311,
                            40.8159130000682
                        ],
                        [
                            -75.1002139995879,
                            40.807663999733
                        ],
                        [
                            -75.1008000002921,
                            40.7997970002513
                        ],
                        [
                            -75.1085049996044,
                            40.7910940000364
                        ],
                        [
                            -75.1230880001951,
                            40.7867459996609
                        ],
                        [
                            -75.1344000003761,
                            40.7737700003011
                        ],
                        [
                            -75.1493780000787,
                            40.7747859998865
                        ],
                        [
                            -75.1636479997767,
                            40.7783859999663
                        ],
                        [
                            -75.171587000339,
                            40.7777449997636
                        ],
                        [
                            -75.1756200002818,
                            40.7729229998772
                        ],
                        [
                            -75.1790399997561,
                            40.761897000093
                        ],
                        [
                            -75.1917960004338,
                            40.7558300001092
                        ],
                        [
                            -75.1965349998331,
                            40.7516310001707
                        ],
                        [
                            -75.1953489999776,
                            40.745472999661
                        ],
                        [
                            -75.1857800000441,
                            40.7372659998109
                        ],
                        [
                            -75.1820840003852,
                            40.7315220003123
                        ],
                        [
                            -75.1860520000307,
                            40.7256820002555
                        ],
                        [
                            -75.1867399999438,
                            40.7194899999944
                        ],
                        [
                            -75.19442000004,
                            40.7140179999002
                        ],
                        [
                            -75.2039210002015,
                            40.6914979997743
                        ],
                        [
                            -75.1969209996225,
                            40.6812969998457
                        ],
                        [
                            -75.1905800004411,
                            40.6793789998285
                        ],
                        [
                            -75.1805639997205,
                            40.6793629998847
                        ],
                        [
                            -75.1768029997643,
                            40.6757150003226
                        ],
                        [
                            -75.1774909996774,
                            40.6725950001672
                        ],
                        [
                            -75.1827520002069,
                            40.665973999702
                        ],
                        [
                            -75.1908520004276,
                            40.6619380002834
                        ],
                        [
                            -75.1979779996066,
                            40.6547390001987
                        ],
                        [
                            -75.1978599996059,
                            40.6520499999373
                        ],
                        [
                            -75.2004520003233,
                            40.6492189996703
                        ],
                        [
                            -75.2004680002168,
                            40.6468990000736
                        ],
                        [
                            -75.1953509996274,
                            40.6430439996875
                        ],
                        [
                            -75.1906909998708,
                            40.6358910002811
                        ],
                        [
                            -75.1885789995524,
                            40.6246280002922
                        ],
                        [
                            -75.1906880003961,
                            40.6199570001332
                        ],
                        [
                            -75.197890999568,
                            40.6193319996836
                        ],
                        [
                            -75.2018120002561,
                            40.6171879999322
                        ],
                        [
                            -75.2013479997506,
                            40.6146280002046
                        ],
                        [
                            -75.1922910000032,
                            40.6026759999519
                        ],
                        [
                            -75.1893979998852,
                            40.5909989998866
                        ],
                        [
                            -75.189691999613,
                            40.5876709998721
                        ],
                        [
                            -75.195221999756,
                            40.580303000267
                        ],
                        [
                            -75.1941050003957,
                            40.5760790000235
                        ],
                        [
                            -75.184907000183,
                            40.5683080000497
                        ],
                        [
                            -75.1704519996388,
                            40.5641649997917
                        ],
                        [
                            -75.1590409997236,
                            40.5648459998837
                        ],
                        [
                            -75.1484199998288,
                            40.5720210002498
                        ],
                        [
                            -75.1401720003684,
                            40.5753700000822
                        ],
                        [
                            -75.1155360001454,
                            40.5728600000945
                        ],
                        [
                            -75.0998269999736,
                            40.5673979997545
                        ],
                        [
                            -75.0780470000587,
                            40.5478759997953
                        ],
                        [
                            -75.0680999998335,
                            40.5414880001594
                        ],
                        [
                            -75.0651020001335,
                            40.5264130000573
                        ],
                        [
                            -75.0626640003002,
                            40.4804390002216
                        ],
                        [
                            -75.067714999869,
                            40.4736690002021
                        ],
                        [
                            -75.0670160000852,
                            40.4653080000355
                        ],
                        [
                            -75.0706780003072,
                            40.4567940001125
                        ],
                        [
                            -75.0659459996823,
                            40.4452660002533
                        ],
                        [
                            -75.0618349999217,
                            40.4241040001562
                        ],
                        [
                            -75.0585750002809,
                            40.418192999772
                        ],
                        [
                            -75.0455259997002,
                            40.4136790001813
                        ],
                        [
                            -75.0344269999546,
                            40.4060660001332
                        ],
                        [
                            -75.028633000221,
                            40.4039709997024
                        ],
                        [
                            -75.023027999735,
                            40.4036930000981
                        ],
                        [
                            -74.9958599999674,
                            40.4103840000228
                        ],
                        [
                            -74.988413000398,
                            40.4084940002741
                        ],
                        [
                            -74.9831309999521,
                            40.4045989997936
                        ],
                        [
                            -74.9665520002923,
                            40.3981539999544
                        ],
                        [
                            -74.9489009996813,
                            40.3650550002366
                        ],
                        [
                            -74.9445170001093,
                            40.3450629999782
                        ],
                        [
                            -74.9397109998645,
                            40.338006000134
                        ],
                        [
                            -74.9083100001376,
                            40.316906999794
                        ],
                        [
                            -74.891609000279,
                            40.3130069999672
                        ],
                        [
                            -74.8682080003154,
                            40.2952059997223
                        ],
                        [
                            -74.8604920002342,
                            40.2845840001513
                        ],
                        [
                            -74.8423080004191,
                            40.2505080000489
                        ],
                        [
                            -74.836307000098,
                            40.2462079997112
                        ],
                        [
                            -74.8195069996068,
                            40.2385080000472
                        ],
                        [
                            -74.7953059995771,
                            40.2294080000467
                        ],
                        [
                            -74.7714369998084,
                            40.2151760003299
                        ],
                        [
                            -74.7672749999941,
                            40.2070760002667
                        ],
                        [
                            -74.7606050000264,
                            40.1989090000628
                        ],
                        [
                            -74.7543049999545,
                            40.1852089999923
                        ],
                        [
                            -74.7372049998877,
                            40.1776090000227
                        ],
                        [
                            -74.7223250000944,
                            40.1607270002966
                        ],
                        [
                            -74.7220480000849,
                            40.1503109996955
                        ],
                        [
                            -74.7243689997424,
                            40.1464119997712
                        ],
                        [
                            -74.7411840003021,
                            40.135165000253
                        ],
                        [
                            -74.7597210002946,
                            40.1338039999375
                        ],
                        [
                            -74.7841680003717,
                            40.120477000144
                        ],
                        [
                            -74.799421000434,
                            40.1227029999847
                        ],
                        [
                            -74.8154760002122,
                            40.1276939998173
                        ],
                        [
                            -74.8225310001444,
                            40.1268440001101
                        ],
                        [
                            -74.8294029997785,
                            40.1191920001192
                        ],
                        [
                            -74.8358679997068,
                            40.1034660002598
                        ],
                        [
                            -74.8422149996344,
                            40.0985020001143
                        ],
                        [
                            -74.8546089999138,
                            40.0932700001549
                        ],
                        [
                            -74.8617559999075,
                            40.0834620001749
                        ],
                        [
                            -74.9073179995525,
                            40.0708000002553
                        ],
                        [
                            -74.9240729998378,
                            40.0712349997286
                        ],
                        [
                            -74.9388969995546,
                            40.0660990002017
                        ],
                        [
                            -74.9739810003183,
                            40.0485949998048
                        ],
                        [
                            -75.0132370000436,
                            40.0203029999248
                        ],
                        [
                            -75.0342160000674,
                            40.0152189997791
                        ],
                        [
                            -75.0470899998475,
                            40.0099409999607
                        ],
                        [
                            -75.053404999988,
                            40.0045679998639
                        ],
                        [
                            -75.0641749998457,
                            39.9860269997741
                        ],
                        [
                            -75.0756050000275,
                            39.9780410001317
                        ],
                        [
                            -75.1022870004466,
                            39.972377000128
                        ],
                        [
                            -75.1257009999306,
                            39.9618229999273
                        ],
                        [
                            -75.1336980002192,
                            39.9546199997005
                        ],
                        [
                            -75.136268000297,
                            39.9432820001439
                        ],
                        [
                            -75.1355269997821,
                            39.9291600001518
                        ],
                        [
                            -75.1282529995668,
                            39.9131640001879
                        ],
                        [
                            -75.1300540004389,
                            39.9023900003118
                        ],
                        [
                            -75.1372959999688,
                            39.8899659999579
                        ],
                        [
                            -75.1437459998285,
                            39.8849980002145
                        ],
                        [
                            -75.1887700002465,
                            39.8808319997277
                        ],
                        [
                            -75.2213710000726,
                            39.861543000084
                        ],
                        [
                            -75.2461489996875,
                            39.8539819999554
                        ],
                        [
                            -75.2722019997446,
                            39.8490760002021
                        ],
                        [
                            -75.3232309997833,
                            39.8498129996647
                        ],
                        [
                            -75.341766000126,
                            39.8460819996939
                        ],
                        [
                            -75.3544010004299,
                            39.8399170003426
                        ],
                        [
                            -75.3984170003692,
                            39.8104670003059
                        ],
                        [
                            -75.4150620001513,
                            39.8019189999259
                        ],
                        [
                            -75.4372549996197,
                            39.8136840002369
                        ],
                        [
                            -75.4642230002691,
                            39.8242809999079
                        ],
                        [
                            -75.4919490002535,
                            39.8320070000447
                        ],
                        [
                            -75.5203060002497,
                            39.8370049999522
                        ],
                        [
                            -75.5487779998736,
                            39.8393219997033
                        ],
                        [
                            -75.5780620001576,
                            39.8389759997733
                        ],
                        [
                            -75.6068640003927,
                            39.8359209999198
                        ],
                        [
                            -75.6345610000648,
                            39.8303229997777
                        ],
                        [
                            -75.6586049997357,
                            39.8229890000419
                        ],
                        [
                            -75.6813250003571,
                            39.8135320003167
                        ],
                        [
                            -75.701963999724,
                            39.8023430002064
                        ],
                        [
                            -75.7208940000769,
                            39.789295999702
                        ],
                        [
                            -75.7378019996247,
                            39.7745520002111
                        ],
                        [
                            -75.7524720002541,
                            39.7582179998212
                        ],
                        [
                            -75.7643879997843,
                            39.7409099999104
                        ],
                        [
                            -75.7737860000136,
                            39.7222000001892
                        ],
                        [
                            -76.9909030002307,
                            39.7197989999248
                        ],
                        [
                            -77.5068099999011,
                            39.7198700000023
                        ],
                        [
                            -78.3151390001398,
                            39.7228770002001
                        ],
                        [
                            -79.0455479996832,
                            39.7228830002726
                        ],
                        [
                            -79.5484650001209,
                            39.7207779999389
                        ],
                        [
                            -80.5193419995558,
                            39.7214029999813
                        ],
                        [
                            -80.5192150002325,
                            40.2683460003262
                        ],
                        [
                            -80.5176889997201,
                            40.4622169997867
                        ],
                        [
                            -80.5189900001016,
                            40.4736670000297
                        ],
                        [
                            -80.519227999753,
                            40.5334600001879
                        ],
                        [
                            -80.5193540001497,
                            41.8495629999304
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 40.9024957,
            "name": "Pennsylvania",
            "intptlon": -77.8334514,
            "geo_point_2d": [
                40.8966438104,
                -77.8390102704
            ],
            "geoid": "42",
            "mtfcc": "G4000",
            "region": 1
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -77.8390102704,
                40.8966438104
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "06a3101fb3fcc2e34c12471f2e98835248e20bfc",
        "fields": {
            "arealand": "595517795",
            "objectid": 41,
            "basename": "Alaska",
            "stusab": "AK",
            "statens": "01785533",
            "centlon": -152.8699814,
            "state": "02",
            "gid": 28,
            "centlat": 63.4205306,
            "division": 9,
            "areawater": "459359166",
            "oid": "265990302",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                -165.254161999918,
                                60.9171080000168
                            ],
                            [
                                -165.270719999661,
                                60.9300130001458
                            ],
                            [
                                -165.276044999765,
                                60.9377589999708
                            ],
                            [
                                -165.274346999723,
                                60.9463629999742
                            ],
                            [
                                -165.293229000396,
                                60.9543940001713
                            ],
                            [
                                -165.297980000389,
                                60.9589800001383
                            ],
                            [
                                -165.302149999701,
                                60.9664399999462
                            ],
                            [
                                -165.303415999922,
                                60.9790599999927
                            ],
                            [
                                -165.301137000097,
                                60.9902429999407
                            ],
                            [
                                -165.293535999644,
                                60.999712999835
                            ],
                            [
                                -165.274731999688,
                                61.0146550002162
                            ],
                            [
                                -165.261804000379,
                                61.0215130001621
                            ],
                            [
                                -165.254786000257,
                                61.03465199984
                            ],
                            [
                                -165.236558000061,
                                61.0500899999352
                            ],
                            [
                                -165.246009999819,
                                61.053511999827
                            ],
                            [
                                -165.258377000334,
                                61.0506430001402
                            ],
                            [
                                -165.268377000263,
                                61.0440700000251
                            ],
                            [
                                -165.29015100033,
                                61.0340599999395
                            ],
                            [
                                -165.346601999658,
                                61.0154419999484
                            ],
                            [
                                -165.41666999983,
                                61.0124800000492
                            ],
                            [
                                -165.538782000191,
                                61.0267800000124
                            ],
                            [
                                -165.606760999611,
                                61.0400129999574
                            ],
                            [
                                -165.663650000403,
                                61.0578089997913
                            ],
                            [
                                -165.68632699957,
                                61.0725800001457
                            ],
                            [
                                -165.716850000312,
                                61.0964549999388
                            ],
                            [
                                -165.743394999565,
                                61.1251929997847
                            ],
                            [
                                -165.786880999775,
                                61.1981149997974
                            ],
                            [
                                -165.801800999751,
                                61.2031899998112
                            ],
                            [
                                -165.822856999768,
                                61.2170850000719
                            ],
                            [
                                -165.833749999649,
                                61.2270289999062
                            ],
                            [
                                -165.837560999834,
                                61.2387210001121
                            ],
                            [
                                -165.90326999965,
                                61.2569450000376
                            ],
                            [
                                -165.923056000145,
                                61.2648379999738
                            ],
                            [
                                -165.939962000043,
                                61.2758809998105
                            ],
                            [
                                -165.953513999691,
                                61.2940929999712
                            ],
                            [
                                -165.979456000318,
                                61.3085060001693
                            ],
                            [
                                -165.990386999835,
                                61.3164349998743
                            ],
                            [
                                -165.996005999666,
                                61.3263959999946
                            ],
                            [
                                -165.99863199982,
                                61.335515999839
                            ],
                            [
                                -166.017553999777,
                                61.351965999826
                            ],
                            [
                                -166.023759000313,
                                61.361069000048
                            ],
                            [
                                -166.032597999602,
                                61.3784369998354
                            ],
                            [
                                -166.031299999594,
                                61.3952989997912
                            ],
                            [
                                -166.050547999964,
                                61.4008889998691
                            ],
                            [
                                -166.117661000093,
                                61.4301450000257
                            ],
                            [
                                -166.183554999857,
                                61.4545310001504
                            ],
                            [
                                -166.194489999571,
                                61.4598669999966
                            ],
                            [
                                -166.231642000272,
                                61.4970030001566
                            ],
                            [
                                -166.254094000206,
                                61.5222519997984
                            ],
                            [
                                -166.280753999986,
                                61.5628819999856
                            ],
                            [
                                -166.288613000182,
                                61.5808090000024
                            ],
                            [
                                -166.286428999894,
                                61.6031039998665
                            ],
                            [
                                -166.279933999828,
                                61.6220100001889
                            ],
                            [
                                -166.265242000356,
                                61.646972000011
                            ],
                            [
                                -166.260245000315,
                                61.6587250001166
                            ],
                            [
                                -166.262126999582,
                                61.6932639997988
                            ],
                            [
                                -166.257290000273,
                                61.7095770000449
                            ],
                            [
                                -166.237945999643,
                                61.7408619997981
                            ],
                            [
                                -166.235154999633,
                                61.7643009998514
                            ],
                            [
                                -166.237550999633,
                                61.7805650001143
                            ],
                            [
                                -166.22972999997,
                                61.7983310000157
                            ],
                            [
                                -166.19776299963,
                                61.8342730000576
                            ],
                            [
                                -166.171763000175,
                                61.8521660001556
                            ],
                            [
                                -166.143689999861,
                                61.8632189999749
                            ],
                            [
                                -166.112514000265,
                                61.8731439999134
                            ],
                            [
                                -166.106902000106,
                                61.905742999977
                            ],
                            [
                                -166.094850000133,
                                61.9466589999946
                            ],
                            [
                                -166.082029999882,
                                61.9635939999661
                            ],
                            [
                                -166.077493000149,
                                61.9738990001799
                            ],
                            [
                                -166.07594599972,
                                61.9830500001421
                            ],
                            [
                                -166.096493999748,
                                61.9983380001642
                            ],
                            [
                                -166.118313999846,
                                62.0096989998203
                            ],
                            [
                                -166.129817999648,
                                62.0252970001881
                            ],
                            [
                                -166.126051999669,
                                62.0387229998926
                            ],
                            [
                                -166.096215999914,
                                62.0734530001469
                            ],
                            [
                                -166.049213000145,
                                62.1080219998695
                            ],
                            [
                                -166.005766000294,
                                62.1234439999159
                            ],
                            [
                                -165.985685000245,
                                62.126137999905
                            ],
                            [
                                -165.948386000129,
                                62.1246749998952
                            ],
                            [
                                -165.901810000158,
                                62.119275999939
                            ],
                            [
                                -165.892590000204,
                                62.1381519998774
                            ],
                            [
                                -165.874582000116,
                                62.1502329998047
                            ],
                            [
                                -165.848594000356,
                                62.1609329999743
                            ],
                            [
                                -165.820598000035,
                                62.1662279998187
                            ],
                            [
                                -165.813533000057,
                                62.1762420001618
                            ],
                            [
                                -165.792468999644,
                                62.1903229999443
                            ],
                            [
                                -165.770639000399,
                                62.1984249998184
                            ],
                            [
                                -165.739565999837,
                                62.204852000027
                            ],
                            [
                                -165.721613999825,
                                62.222621000054
                            ],
                            [
                                -165.668902999639,
                                62.2508109999528
                            ],
                            [
                                -165.659378999911,
                                62.2642490000525
                            ],
                            [
                                -165.621272000231,
                                62.2917840001702
                            ],
                            [
                                -165.525884999946,
                                62.3534399998947
                            ],
                            [
                                -165.448869999798,
                                62.3910219998671
                            ],
                            [
                                -165.438691999594,
                                62.4118699999362
                            ],
                            [
                                -165.418730000269,
                                62.4404310001662
                            ],
                            [
                                -165.379579999951,
                                62.4696060000119
                            ],
                            [
                                -165.388413000291,
                                62.4852629997985
                            ],
                            [
                                -165.383615000442,
                                62.4969619998474
                            ],
                            [
                                -165.369538000189,
                                62.5106880001882
                            ],
                            [
                                -165.346209000196,
                                62.5266989998226
                            ],
                            [
                                -165.350770000218,
                                62.5477890001887
                            ],
                            [
                                -165.338687000284,
                                62.5760379999372
                            ],
                            [
                                -165.311605999656,
                                62.5954699998681
                            ],
                            [
                                -165.276973999703,
                                62.6092140001746
                            ],
                            [
                                -165.213090999975,
                                62.6144310000913
                            ],
                            [
                                -165.239301000391,
                                62.6340819998138
                            ],
                            [
                                -165.248095000373,
                                62.6480330000054
                            ],
                            [
                                -165.245716000091,
                                62.6622899998007
                            ],
                            [
                                -165.255131000038,
                                62.6722539998875
                            ],
                            [
                                -165.259015000018,
                                62.6922000001298
                            ],
                            [
                                -165.247323999721,
                                62.7098940001322
                            ],
                            [
                                -165.22188800033,
                                62.7224660000493
                            ],
                            [
                                -165.165247999957,
                                62.7359359999069
                            ],
                            [
                                -165.12599999973,
                                62.7405169998377
                            ],
                            [
                                -165.090459999755,
                                62.7393949999686
                            ],
                            [
                                -165.063014999978,
                                62.733981000009
                            ],
                            [
                                -165.053051999859,
                                62.7422510001788
                            ],
                            [
                                -165.038716999864,
                                62.7499380000457
                            ],
                            [
                                -165.021887999958,
                                62.7562150000192
                            ],
                            [
                                -165.002642000136,
                                62.7570610000693
                            ],
                            [
                                -164.986702999811,
                                62.7607400001838
                            ],
                            [
                                -165.002545999877,
                                62.7747429999056
                            ],
                            [
                                -165.009404000166,
                                62.7778749998659
                            ],
                            [
                                -165.016892999568,
                                62.7844350001723
                            ],
                            [
                                -165.018758999839,
                                62.7924140001332
                            ],
                            [
                                -165.015018999799,
                                62.8004030000118
                            ],
                            [
                                -165.023139999936,
                                62.8041090001834
                            ],
                            [
                                -165.038143999578,
                                62.8206470000815
                            ],
                            [
                                -165.033766999679,
                                62.8366060001517
                            ],
                            [
                                -165.019387000376,
                                62.8519919998903
                            ],
                            [
                                -164.99653299986,
                                62.8665829999931
                            ],
                            [
                                -164.981150000102,
                                62.8713610001309
                            ],
                            [
                                -164.93927200042,
                                62.87751600012
                            ],
                            [
                                -164.936893999963,
                                62.8861670000535
                            ],
                            [
                                -164.922267999714,
                                62.8992270000445
                            ],
                            [
                                -164.915883999977,
                                62.9026069997986
                            ],
                            [
                                -164.92213399982,
                                62.912500999923
                            ],
                            [
                                -164.920988999972,
                                62.9210360001105
                            ],
                            [
                                -164.91799700012,
                                62.9282510000897
                            ],
                            [
                                -164.901932000296,
                                62.9444590000647
                            ],
                            [
                                -164.895532999592,
                                62.9486360001845
                            ],
                            [
                                -164.902934999854,
                                62.9569980001539
                            ],
                            [
                                -164.90053200018,
                                62.9735459998747
                            ],
                            [
                                -164.896625999561,
                                62.9795040001981
                            ],
                            [
                                -164.907047000337,
                                62.9841959998784
                            ],
                            [
                                -164.912524999704,
                                63.0000050001187
                            ],
                            [
                                -164.910614000125,
                                63.005155000167
                            ],
                            [
                                -164.89296900026,
                                63.0191440001692
                            ],
                            [
                                -164.902997999603,
                                63.0331699998174
                            ],
                            [
                                -164.906735999993,
                                63.0448950000795
                            ],
                            [
                                -164.899789999841,
                                63.052321999907
                            ],
                            [
                                -164.880215999955,
                                63.0654450001344
                            ],
                            [
                                -164.830316999696,
                                63.0822500001886
                            ],
                            [
                                -164.772227999702,
                                63.0901480000031
                            ],
                            [
                                -164.75077599985,
                                63.0906769999379
                            ],
                            [
                                -164.743802000109,
                                63.0932309998119
                            ],
                            [
                                -164.746820999725,
                                63.1103980000086
                            ],
                            [
                                -164.727743999958,
                                63.1246500001514
                            ],
                            [
                                -164.692178000043,
                                63.1448610000614
                            ],
                            [
                                -164.609282999947,
                                63.1995229997991
                            ],
                            [
                                -164.495673000388,
                                63.2511589998658
                            ],
                            [
                                -164.395460999592,
                                63.2798929998529
                            ],
                            [
                                -164.250046999899,
                                63.3034520001948
                            ],
                            [
                                -164.128906000206,
                                63.3153020001045
                            ],
                            [
                                -164.051255999848,
                                63.3173550001791
                            ],
                            [
                                -164.015761999903,
                                63.3139550000315
                            ],
                            [
                                -163.932942999975,
                                63.3001729998892
                            ],
                            [
                                -163.87062500022,
                                63.2842270001045
                            ],
                            [
                                -163.859742000385,
                                63.2861420001539
                            ],
                            [
                                -163.823624000101,
                                63.2838380000844
                            ],
                            [
                                -163.7718479997,
                                63.2762279999246
                            ],
                            [
                                -163.737342000143,
                                63.2661959998631
                            ],
                            [
                                -163.705829000263,
                                63.2610390001993
                            ],
                            [
                                -163.658100999873,
                                63.249716999928
                            ],
                            [
                                -163.613402999868,
                                63.2286799999498
                            ],
                            [
                                -163.584710000311,
                                63.2058030001856
                            ],
                            [
                                -163.477584000048,
                                63.1823429999872
                            ],
                            [
                                -163.455166000449,
                                63.1749549998829
                            ],
                            [
                                -163.349816000046,
                                63.1236869998919
                            ],
                            [
                                -163.252478999824,
                                63.0924539999527
                            ],
                            [
                                -163.118702000002,
                                63.1015580000141
                            ],
                            [
                                -163.102791999989,
                                63.113061999839
                            ],
                            [
                                -163.064337000155,
                                63.1311269998668
                            ],
                            [
                                -163.026177999698,
                                63.1534680000951
                            ],
                            [
                                -162.977860000203,
                                63.1659230001035
                            ],
                            [
                                -162.958127000309,
                                63.1767909998043
                            ],
                            [
                                -162.954679000347,
                                63.1898939999381
                            ],
                            [
                                -162.937697000281,
                                63.2151019998541
                            ],
                            [
                                -162.916909999878,
                                63.2307989999896
                            ],
                            [
                                -162.870861000161,
                                63.2535589998963
                            ],
                            [
                                -162.806441000134,
                                63.2659939999203
                            ],
                            [
                                -162.73951700015,
                                63.2689249998317
                            ],
                            [
                                -162.700287000364,
                                63.2911749999426
                            ],
                            [
                                -162.437773000419,
                                63.4919939999298
                            ],
                            [
                                -162.448493999873,
                                63.4939010001636
                            ],
                            [
                                -162.468317000178,
                                63.4942609999345
                            ],
                            [
                                -162.552552000116,
                                63.4825980000519
                            ],
                            [
                                -162.579718000233,
                                63.486787000101
                            ],
                            [
                                -162.610749000064,
                                63.483026999855
                            ],
                            [
                                -162.641963000194,
                                63.484990000163
                            ],
                            [
                                -162.679831000397,
                                63.4916260000337
                            ],
                            [
                                -162.697154000047,
                                63.4990619999739
                            ],
                            [
                                -162.71111099975,
                                63.5087310000598
                            ],
                            [
                                -162.747558999746,
                                63.5179020001183
                            ],
                            [
                                -162.772897999951,
                                63.5297469999528
                            ],
                            [
                                -162.784652999823,
                                63.5436659999908
                            ],
                            [
                                -162.826440999991,
                                63.5763599999095
                            ],
                            [
                                -162.825788000238,
                                63.5849240001099
                            ],
                            [
                                -162.816030999983,
                                63.603370000019
                            ],
                            [
                                -162.786165999916,
                                63.6240670001741
                            ],
                            [
                                -162.762994000282,
                                63.632559999886
                            ],
                            [
                                -162.730633999582,
                                63.6512049998948
                            ],
                            [
                                -162.680080999744,
                                63.671264999939
                            ],
                            [
                                -162.661000999603,
                                63.6760920000747
                            ],
                            [
                                -162.633024000447,
                                63.6793429998905
                            ],
                            [
                                -162.614453999944,
                                63.6856089999347
                            ],
                            [
                                -162.584319000439,
                                63.6913920000087
                            ],
                            [
                                -162.544042999641,
                                63.6946969998483
                            ],
                            [
                                -162.514518000231,
                                63.6930409999272
                            ],
                            [
                                -162.481046000193,
                                63.6850070001601
                            ],
                            [
                                -162.452956999986,
                                63.6890869998141
                            ],
                            [
                                -162.42582000018,
                                63.6897370001514
                            ],
                            [
                                -162.394359000179,
                                63.6883040000482
                            ],
                            [
                                -162.356942999887,
                                63.6816050000113
                            ],
                            [
                                -162.338829000392,
                                63.6752539998392
                            ],
                            [
                                -162.325916000254,
                                63.6755890000608
                            ],
                            [
                                -162.304354999724,
                                63.6720249998056
                            ],
                            [
                                -162.274573000396,
                                63.6600120000685
                            ],
                            [
                                -162.254620000394,
                                63.6464669998325
                            ],
                            [
                                -162.249931000325,
                                63.6283519999025
                            ],
                            [
                                -162.242573000445,
                                63.6244840001694
                            ],
                            [
                                -162.225381000316,
                                63.6095570001883
                            ],
                            [
                                -162.222744000291,
                                63.5969139998047
                            ],
                            [
                                -162.172317999777,
                                63.5870369999275
                            ],
                            [
                                -162.00360299968,
                                63.5638570000829
                            ],
                            [
                                -161.979896000293,
                                63.5617820001222
                            ],
                            [
                                -161.956959999761,
                                63.5572089999845
                            ],
                            [
                                -161.938961999719,
                                63.5469260000742
                            ],
                            [
                                -161.924934999695,
                                63.5363889998384
                            ],
                            [
                                -161.910533999578,
                                63.5194989999266
                            ],
                            [
                                -161.896213999651,
                                63.5082199999659
                            ],
                            [
                                -161.860175999734,
                                63.496280999868
                            ],
                            [
                                -161.840405000205,
                                63.4932880001108
                            ],
                            [
                                -161.821316999668,
                                63.4945279998353
                            ],
                            [
                                -161.804168000096,
                                63.4985300000654
                            ],
                            [
                                -161.774005999928,
                                63.4994040001807
                            ],
                            [
                                -161.745941999835,
                                63.5033219998814
                            ],
                            [
                                -161.718899999566,
                                63.5115700000425
                            ],
                            [
                                -161.687774000199,
                                63.5146169998885
                            ],
                            [
                                -161.66017799991,
                                63.5143869999234
                            ],
                            [
                                -161.641914999554,
                                63.5122009999506
                            ],
                            [
                                -161.603114000114,
                                63.5041549999299
                            ],
                            [
                                -161.575840000041,
                                63.5073330000275
                            ],
                            [
                                -161.529459999888,
                                63.5177860001412
                            ],
                            [
                                -161.511953999941,
                                63.5197460000784
                            ],
                            [
                                -161.424337999988,
                                63.5161000001822
                            ],
                            [
                                -161.314357999966,
                                63.5192290000965
                            ],
                            [
                                -161.285188000383,
                                63.5233699998992
                            ],
                            [
                                -161.270081999735,
                                63.5301909998866
                            ],
                            [
                                -161.260290000219,
                                63.5427099999584
                            ],
                            [
                                -161.248694000356,
                                63.552112999897
                            ],
                            [
                                -161.224994999568,
                                63.5644519998303
                            ],
                            [
                                -161.202070999631,
                                63.5873680000937
                            ],
                            [
                                -161.191961999922,
                                63.591410999975
                            ],
                            [
                                -161.15180099965,
                                63.6007179999473
                            ],
                            [
                                -161.142543000061,
                                63.6041780001529
                            ],
                            [
                                -161.138937999741,
                                63.614709000196
                            ],
                            [
                                -161.139971000334,
                                63.6252259999024
                            ],
                            [
                                -161.098069000362,
                                63.6561360000534
                            ],
                            [
                                -161.070436000264,
                                63.6642669998296
                            ],
                            [
                                -161.024205000075,
                                63.6717929998761
                            ],
                            [
                                -161.00848900023,
                                63.6776770000732
                            ],
                            [
                                -160.996809999628,
                                63.6895829998795
                            ],
                            [
                                -160.92857799959,
                                63.7416420001955
                            ],
                            [
                                -160.901622000433,
                                63.7581949998545
                            ],
                            [
                                -160.886200000316,
                                63.771397000197
                            ],
                            [
                                -160.876980000362,
                                63.7968780000352
                            ],
                            [
                                -160.880050999857,
                                63.8121809999492
                            ],
                            [
                                -160.887726999756,
                                63.8245590000255
                            ],
                            [
                                -160.898773999623,
                                63.8331760001975
                            ],
                            [
                                -160.915030000141,
                                63.8515710000373
                            ],
                            [
                                -160.920124000266,
                                63.8597450001703
                            ],
                            [
                                -160.936481999992,
                                63.908480000059
                            ],
                            [
                                -160.944644000138,
                                63.9215319999969
                            ],
                            [
                                -160.961444999555,
                                63.937221999917
                            ],
                            [
                                -160.978303999598,
                                63.9497050000053
                            ],
                            [
                                -160.988754999613,
                                63.9614750001343
                            ],
                            [
                                -161.009271999679,
                                63.9792810001947
                            ],
                            [
                                -161.016889000026,
                                63.9894190000378
                            ],
                            [
                                -161.018956999964,
                                63.9948299999444
                            ],
                            [
                                -161.018489000159,
                                63.9985540000623
                            ],
                            [
                                -161.045660000299,
                                64.022466999916
                            ],
                            [
                                -161.064424000073,
                                64.0639209998635
                            ],
                            [
                                -161.070600000296,
                                64.0849750001805
                            ],
                            [
                                -161.06234400044,
                                64.113389000157
                            ],
                            [
                                -161.069227999769,
                                64.1181479999816
                            ],
                            [
                                -161.076476000045,
                                64.127471000102
                            ],
                            [
                                -161.07732799999,
                                64.1383229998622
                            ],
                            [
                                -161.074136000121,
                                64.1649419999833
                            ],
                            [
                                -161.075402000343,
                                64.2066520001808
                            ],
                            [
                                -161.091796000055,
                                64.2152690001448
                            ],
                            [
                                -161.179114999906,
                                64.2579929998751
                            ],
                            [
                                -161.253426999907,
                                64.2988690001685
                            ],
                            [
                                -161.326795999728,
                                64.3340120001724
                            ],
                            [
                                -161.413898999669,
                                64.3585869999365
                            ],
                            [
                                -161.443174999557,
                                64.3400900001898
                            ],
                            [
                                -161.463623000026,
                                64.3334620000255
                            ],
                            [
                                -161.493432999842,
                                64.3283779999784
                            ],
                            [
                                -161.554655999978,
                                64.3287410001541
                            ],
                            [
                                -161.590840000383,
                                64.3355409998132
                            ],
                            [
                                -161.666369000202,
                                64.3604700001166
                            ],
                            [
                                -161.767465999657,
                                64.3888779999679
                            ],
                            [
                                -162.002127000294,
                                64.4587889999483
                            ],
                            [
                                -162.027939000327,
                                64.4613170001441
                            ],
                            [
                                -162.237403000152,
                                64.5243560001334
                            ],
                            [
                                -162.313491000013,
                                64.5427889998441
                            ],
                            [
                                -162.345106999825,
                                64.5343549998321
                            ],
                            [
                                -162.44188400018,
                                64.5041190001684
                            ],
                            [
                                -162.4719829997,
                                64.486843000147
                            ],
                            [
                                -162.486690999964,
                                64.4708969998532
                            ],
                            [
                                -162.48776800004,
                                64.4575540000431
                            ],
                            [
                                -162.491981999733,
                                64.4403860000374
                            ],
                            [
                                -162.504468000073,
                                64.4178369998501
                            ],
                            [
                                -162.512552000401,
                                64.3925730001486
                            ],
                            [
                                -162.517871999583,
                                64.3849059999077
                            ],
                            [
                                -162.557920999702,
                                64.3557939999862
                            ],
                            [
                                -162.594767999906,
                                64.3384369998756
                            ],
                            [
                                -162.632747000438,
                                64.3319259999857
                            ],
                            [
                                -162.654473999751,
                                64.3225790000363
                            ],
                            [
                                -162.6849770004,
                                64.3043529998331
                            ],
                            [
                                -162.728061999854,
                                64.2874669999262
                            ],
                            [
                                -162.795801999797,
                                64.2756740001123
                            ],
                            [
                                -162.842151999813,
                                64.2831710000604
                            ],
                            [
                                -163.165796999833,
                                64.3498640000754
                            ],
                            [
                                -163.215381000448,
                                64.3532770001571
                            ],
                            [
                                -163.241932000447,
                                64.3607430001015
                            ],
                            [
                                -163.323337999841,
                                64.4078659998702
                            ],
                            [
                                -163.349687000174,
                                64.4376670001219
                            ],
                            [
                                -163.363754000381,
                                64.4456419998445
                            ],
                            [
                                -163.431854999999,
                                64.4666500000574
                            ],
                            [
                                -163.457728999956,
                                64.4821100000135
                            ],
                            [
                                -163.53617199981,
                                64.503580999998
                            ],
                            [
                                -163.612329999991,
                                64.5179180000133
                            ],
                            [
                                -163.656951000002,
                                64.5219399998274
                            ],
                            [
                                -163.70425699972,
                                64.5196890000311
                            ],
                            [
                                -163.75039,
                                64.519640000065
                            ],
                            [
                                -163.787863000194,
                                64.5241260001332
                            ],
                            [
                                -163.814064000389,
                                64.5311859998333
                            ],
                            [
                                -163.831614999644,
                                64.5243809999411
                            ],
                            [
                                -163.850766999755,
                                64.5193980001652
                            ],
                            [
                                -163.879706000257,
                                64.5153489998663
                            ],
                            [
                                -163.976218000298,
                                64.5083159999393
                            ],
                            [
                                -164.180901999817,
                                64.5195690001394
                            ],
                            [
                                -164.266311999739,
                                64.5183329998972
                            ],
                            [
                                -164.355354999571,
                                64.5084470001604
                            ],
                            [
                                -164.465134999578,
                                64.4838669999685
                            ],
                            [
                                -164.619788000389,
                                64.4444089998566
                            ],
                            [
                                -164.754801000295,
                                64.4078240001563
                            ],
                            [
                                -164.804227999654,
                                64.4010609998369
                            ],
                            [
                                -164.908916999908,
                                64.3936069999572
                            ],
                            [
                                -164.955097000044,
                                64.3927760000703
                            ],
                            [
                                -165.03220500008,
                                64.3879869999801
                            ],
                            [
                                -165.176616000215,
                                64.4131080001016
                            ],
                            [
                                -165.335918000014,
                                64.444416999843
                            ],
                            [
                                -165.460762999762,
                                64.4577409998306
                            ],
                            [
                                -165.476867999769,
                                64.4576900000856
                            ],
                            [
                                -165.680679000325,
                                64.4836409998817
                            ],
                            [
                                -165.850211000207,
                                64.4938789999768
                            ],
                            [
                                -166.07779000025,
                                64.5204590001516
                            ],
                            [
                                -166.07313999964,
                                64.5102619998332
                            ],
                            [
                                -166.07087300041,
                                64.4901590000059
                            ],
                            [
                                -166.09569600023,
                                64.459804000064
                            ],
                            [
                                -166.133535000121,
                                64.4382119998636
                            ],
                            [
                                -166.153861000392,
                                64.4329739998843
                            ],
                            [
                                -166.212337999825,
                                64.4298560000326
                            ],
                            [
                                -166.271959000179,
                                64.4377800001937
                            ],
                            [
                                -166.314529999797,
                                64.454359999861
                            ],
                            [
                                -166.351131999779,
                                64.4778829999093
                            ],
                            [
                                -166.353875000109,
                                64.5064440000204
                            ],
                            [
                                -166.330910000163,
                                64.5258509998458
                            ],
                            [
                                -166.281388000371,
                                64.5449559998566
                            ],
                            [
                                -166.352028999929,
                                64.5681370000483
                            ],
                            [
                                -166.440112999686,
                                64.603256999857
                            ],
                            [
                                -166.491259999726,
                                64.6189580001917
                            ],
                            [
                                -166.507840999934,
                                64.6313729999744
                            ],
                            [
                                -166.529373000152,
                                64.6578619998969
                            ],
                            [
                                -166.557674999896,
                                64.6989150001844
                            ],
                            [
                                -166.590162999745,
                                64.7389480001592
                            ],
                            [
                                -166.58588100028,
                                64.7662609998627
                            ],
                            [
                                -166.577565999974,
                                64.7779990000297
                            ],
                            [
                                -166.586748000293,
                                64.8014689999697
                            ],
                            [
                                -166.582187000271,
                                64.8220890001311
                            ],
                            [
                                -166.57020300007,
                                64.8334169999112
                            ],
                            [
                                -166.517208000201,
                                64.8573830000047
                            ],
                            [
                                -166.522688000115,
                                64.8711029999457
                            ],
                            [
                                -166.589185000301,
                                64.9082910000705
                            ],
                            [
                                -166.761900999656,
                                64.9634930001015
                            ],
                            [
                                -166.783276000413,
                                64.9797179998696
                            ],
                            [
                                -166.828998999717,
                                64.9993150001397
                            ],
                            [
                                -166.824467000007,
                                65.0174569999132
                            ],
                            [
                                -166.878158000185,
                                65.0299500001313
                            ],
                            [
                                -166.978964000284,
                                65.0694099998192
                            ],
                            [
                                -167.040929999687,
                                65.1092480001575
                            ],
                            [
                                -167.072980999692,
                                65.1383850000316
                            ],
                            [
                                -167.084338999904,
                                65.1556949998856
                            ],
                            [
                                -167.072439000267,
                                65.199781999997
                            ],
                            [
                                -167.039248000437,
                                65.2433940000289
                            ],
                            [
                                -167.008528999877,
                                65.2766520001848
                            ],
                            [
                                -166.977539999879,
                                65.2976359998658
                            ],
                            [
                                -166.948930000163,
                                65.3079089999686
                            ],
                            [
                                -167.070331000147,
                                65.3274910001289
                            ],
                            [
                                -167.191298999587,
                                65.3376289999841
                            ],
                            [
                                -167.272176999799,
                                65.3381530001487
                            ],
                            [
                                -167.400891000357,
                                65.3472699998217
                            ],
                            [
                                -167.488585000127,
                                65.3604140000826
                            ],
                            [
                                -167.606390000011,
                                65.3856259998349
                            ],
                            [
                                -167.664684000044,
                                65.4053779999406
                            ],
                            [
                                -167.756708999682,
                                65.4472260001692
                            ],
                            [
                                -167.830880999942,
                                65.4658369999254
                            ],
                            [
                                -167.896865999942,
                                65.476684999892
                            ],
                            [
                                -167.938028999946,
                                65.4998959998799
                            ],
                            [
                                -167.971063000316,
                                65.5001309998796
                            ],
                            [
                                -168.004429000048,
                                65.5041919998141
                            ],
                            [
                                -168.031050000368,
                                65.5094299999626
                            ],
                            [
                                -168.055167999658,
                                65.516985999889
                            ],
                            [
                                -168.065367999604,
                                65.5225179999564
                            ],
                            [
                                -168.090937999787,
                                65.5240439998545
                            ],
                            [
                                -168.126639000319,
                                65.5305259998683
                            ],
                            [
                                -168.152966999837,
                                65.5388890000099
                            ],
                            [
                                -168.167583999865,
                                65.5469020001328
                            ],
                            [
                                -168.175414999574,
                                65.5556140000203
                            ],
                            [
                                -168.176203999769,
                                65.5627419998319
                            ],
                            [
                                -168.218653000086,
                                65.5961230001215
                            ],
                            [
                                -168.231886999717,
                                65.6225670001807
                            ],
                            [
                                -168.229243000019,
                                65.6502660000374
                            ],
                            [
                                -168.22130699983,
                                65.6754490000195
                            ],
                            [
                                -168.200141000208,
                                65.699371999851
                            ],
                            [
                                -168.155129000384,
                                65.7283510001193
                            ],
                            [
                                -168.137857999715,
                                65.7349600001321
                            ],
                            [
                                -168.077125999848,
                                65.75608900013
                            ],
                            [
                                -168.004249000123,
                                65.7745499998166
                            ],
                            [
                                -167.968001000143,
                                65.7806609999079
                            ],
                            [
                                -167.88339799996,
                                65.8032750001192
                            ],
                            [
                                -167.718856000446,
                                65.8349219998372
                            ],
                            [
                                -167.520822999729,
                                65.8781520001527
                            ],
                            [
                                -167.308341999938,
                                65.9443249998867
                            ],
                            [
                                -167.238601000005,
                                65.9708589998324
                            ],
                            [
                                -167.178403000066,
                                65.9995669999691
                            ],
                            [
                                -167.007856999858,
                                66.0514099998279
                            ],
                            [
                                -166.719473999978,
                                66.148046000144
                            ],
                            [
                                -166.480248000019,
                                66.2119330001722
                            ],
                            [
                                -166.317088000179,
                                66.2519349998558
                            ],
                            [
                                -166.093966000402,
                                66.3159979998708
                            ],
                            [
                                -165.777738999639,
                                66.3973619999757
                            ],
                            [
                                -165.434440000266,
                                66.4752300000656
                            ],
                            [
                                -165.29233499972,
                                66.5016390000574
                            ],
                            [
                                -164.880443999561,
                                66.572628000151
                            ],
                            [
                                -164.831582000092,
                                66.5786480001741
                            ],
                            [
                                -164.75190200033,
                                66.5925459998326
                            ],
                            [
                                -164.69159899991,
                                66.6003780000127
                            ],
                            [
                                -164.424297000162,
                                66.6302169998976
                            ],
                            [
                                -164.220991000119,
                                66.6385000000953
                            ],
                            [
                                -163.982715999838,
                                66.6425819998281
                            ],
                            [
                                -163.805092999957,
                                66.6412860000301
                            ],
                            [
                                -163.712089000152,
                                66.6357500001082
                            ],
                            [
                                -163.626214999724,
                                66.6241740001036
                            ],
                            [
                                -163.403542000384,
                                66.7103240001005
                            ],
                            [
                                -163.203244000086,
                                66.7821969998544
                            ],
                            [
                                -162.97841800042,
                                66.8709520001621
                            ],
                            [
                                -162.893091000338,
                                66.8997429998553
                            ],
                            [
                                -162.832032000233,
                                66.9268130000227
                            ],
                            [
                                -162.847943000071,
                                66.9316070001342
                            ],
                            [
                                -162.876849000064,
                                66.9478280000022
                            ],
                            [
                                -163.049470999707,
                                66.9866109999899
                            ],
                            [
                                -163.129897999832,
                                66.9948379998953
                            ],
                            [
                                -163.149899000413,
                                66.9999999999215
                            ],
                            [
                                -163.340525999771,
                                67.0259149998871
                            ],
                            [
                                -163.404354000145,
                                67.0297550000195
                            ],
                            [
                                -163.487708999651,
                                67.0386650001364
                            ],
                            [
                                -163.617681999858,
                                67.0487320001034
                            ],
                            [
                                -163.781523999764,
                                67.0697599999062
                            ],
                            [
                                -163.911384999818,
                                67.1046950000352
                            ],
                            [
                                -163.991230000336,
                                67.1478830000254
                            ],
                            [
                                -164.002718000244,
                                67.1725539999863
                            ],
                            [
                                -163.962463000261,
                                67.1966959998932
                            ],
                            [
                                -163.924407999561,
                                67.2075119999402
                            ],
                            [
                                -163.895737999571,
                                67.2137230001089
                            ],
                            [
                                -163.882534000078,
                                67.2149759998641
                            ],
                            [
                                -163.905647000161,
                                67.2534369998972
                            ],
                            [
                                -164.004509999997,
                                67.397807999925
                            ],
                            [
                                -164.038741000093,
                                67.4273780000869
                            ],
                            [
                                -164.116655999867,
                                67.4999670000577
                            ],
                            [
                                -164.175428999576,
                                67.5475989999826
                            ],
                            [
                                -164.23031300018,
                                67.5750540000387
                            ],
                            [
                                -164.281270000249,
                                67.5916660000882
                            ],
                            [
                                -164.549011000388,
                                67.6619939998932
                            ],
                            [
                                -164.655222000059,
                                67.692447999988
                            ],
                            [
                                -164.664920999689,
                                67.6982159998315
                            ],
                            [
                                -164.767555000425,
                                67.7416959999348
                            ],
                            [
                                -165.206175999785,
                                67.9050200000714
                            ],
                            [
                                -165.499452999773,
                                68.0187819999978
                            ],
                            [
                                -165.516403000052,
                                68.0236210000716
                            ],
                            [
                                -165.567492000365,
                                68.0319079999036
                            ],
                            [
                                -165.656222000202,
                                68.0387269998916
                            ],
                            [
                                -165.684734000009,
                                68.0251629999148
                            ],
                            [
                                -165.820034999795,
                                68.0305800000353
                            ],
                            [
                                -165.855553999854,
                                68.0348250000213
                            ],
                            [
                                -165.951111999843,
                                68.0566239999504
                            ],
                            [
                                -165.980463999899,
                                68.0678470001035
                            ],
                            [
                                -166.043857999905,
                                68.0848910001669
                            ],
                            [
                                -166.085624000332,
                                68.1126560001395
                            ],
                            [
                                -166.112186000202,
                                68.1465230000088
                            ],
                            [
                                -166.126214999875,
                                68.1569630000959
                            ],
                            [
                                -166.148441999679,
                                68.1688790001419
                            ],
                            [
                                -166.274529000257,
                                68.2129180001473
                            ],
                            [
                                -166.410025999862,
                                68.2507610001424
                            ],
                            [
                                -166.493914000368,
                                68.2662440000558
                            ],
                            [
                                -166.539349999616,
                                68.2762210000479
                            ],
                            [
                                -166.672761999565,
                                68.2901920000893
                            ],
                            [
                                -166.759606999763,
                                68.2930079999427
                            ],
                            [
                                -166.796648000136,
                                68.2848660001603
                            ],
                            [
                                -166.827526999631,
                                68.2807040000762
                            ],
                            [
                                -166.843651999729,
                                68.2808110001434
                            ],
                            [
                                -166.900579999982,
                                68.2862719999688
                            ],
                            [
                                -166.922104999629,
                                68.2912160001441
                            ],
                            [
                                -166.973264000262,
                                68.3096970000355
                            ],
                            [
                                -166.979909000115,
                                68.31363400002
                            ],
                            [
                                -166.985511000228,
                                68.3205130001596
                            ],
                            [
                                -166.98542400019,
                                68.3331210001299
                            ],
                            [
                                -166.971634999817,
                                68.3452930001594
                            ],
                            [
                                -166.949968999705,
                                68.3591820000974
                            ],
                            [
                                -166.92219500004,
                                68.3719969999521
                            ],
                            [
                                -166.903731999668,
                                68.3782650000459
                            ],
                            [
                                -166.866033000417,
                                68.3820509999621
                            ],
                            [
                                -166.81400699977,
                                68.4017699998651
                            ],
                            [
                                -166.753659999868,
                                68.4191840000285
                            ],
                            [
                                -166.700278000385,
                                68.4228769998433
                            ],
                            [
                                -166.541611999722,
                                68.4489189999012
                            ],
                            [
                                -166.456826000139,
                                68.4668279999732
                            ],
                            [
                                -166.445417999698,
                                68.4726840001401
                            ],
                            [
                                -166.444328999927,
                                68.4760689999665
                            ],
                            [
                                -166.445570999859,
                                68.4872899999352
                            ],
                            [
                                -166.44102599973,
                                68.4986989998752
                            ],
                            [
                                -166.434684999651,
                                68.5063819999212
                            ],
                            [
                                -166.433324999718,
                                68.5275039998981
                            ],
                            [
                                -166.425061000364,
                                68.5382370000645
                            ],
                            [
                                -166.411913999874,
                                68.5474370000765
                            ],
                            [
                                -166.397720000344,
                                68.554013000097
                            ],
                            [
                                -166.369922000389,
                                68.5711889999774
                            ],
                            [
                                -166.365922999884,
                                68.5798759999091
                            ],
                            [
                                -166.369180999875,
                                68.591838999979
                            ],
                            [
                                -166.369609000395,
                                68.6066859999343
                            ],
                            [
                                -166.360352999558,
                                68.6249239998856
                            ],
                            [
                                -166.361860999629,
                                68.6349510001145
                            ],
                            [
                                -166.370253999752,
                                68.648944999941
                            ],
                            [
                                -166.359201000037,
                                68.6666530000823
                            ],
                            [
                                -166.328027000091,
                                68.6930649999378
                            ],
                            [
                                -166.338391999893,
                                68.7068849999251
                            ],
                            [
                                -166.33856799962,
                                68.7188779998948
                            ],
                            [
                                -166.331784999674,
                                68.7253139999109
                            ],
                            [
                                -166.330542999742,
                                68.7300610000266
                            ],
                            [
                                -166.332484000356,
                                68.7369560000941
                            ],
                            [
                                -166.331500000167,
                                68.7457929999186
                            ],
                            [
                                -166.320642000446,
                                68.7748619998585
                            ],
                            [
                                -166.323502000054,
                                68.7877840001298
                            ],
                            [
                                -166.338940999889,
                                68.8097819998413
                            ],
                            [
                                -166.346544999817,
                                68.8224759998931
                            ],
                            [
                                -166.351947999738,
                                68.8369929999798
                            ],
                            [
                                -166.366944999708,
                                68.858327000079
                            ],
                            [
                                -166.363633000188,
                                68.8780589998627
                            ],
                            [
                                -166.342117999689,
                                68.9021639999229
                            ],
                            [
                                -166.330481999643,
                                68.9107969999868
                            ],
                            [
                                -166.32058600037,
                                68.9157379998511
                            ],
                            [
                                -166.294394000395,
                                68.9232090000231
                            ],
                            [
                                -166.238630000257,
                                68.9319430000618
                            ],
                            [
                                -166.205882000118,
                                68.9355610001354
                            ],
                            [
                                -166.185328000241,
                                68.935706000114
                            ],
                            [
                                -166.109959000256,
                                68.9290780000241
                            ],
                            [
                                -166.072448000428,
                                68.9294490000179
                            ],
                            [
                                -165.973004999735,
                                68.9243560000688
                            ],
                            [
                                -165.817382999702,
                                68.9219929998592
                            ],
                            [
                                -165.666756000058,
                                68.9146300000932
                            ],
                            [
                                -165.591797000152,
                                68.9137079999529
                            ],
                            [
                                -165.555922000441,
                                68.9156620001461
                            ],
                            [
                                -165.498776999555,
                                68.9131289998626
                            ],
                            [
                                -165.291464000407,
                                68.9211240000804
                            ],
                            [
                                -165.249798000437,
                                68.9267250000532
                            ],
                            [
                                -165.152099999643,
                                68.9345789999025
                            ],
                            [
                                -165.12809500033,
                                68.934375000035
                            ],
                            [
                                -165.064971999587,
                                68.9379509999459
                            ],
                            [
                                -165.007541000268,
                                68.9463549999124
                            ],
                            [
                                -164.966851000091,
                                68.9413859999325
                            ],
                            [
                                -164.849027999766,
                                68.9438920001558
                            ],
                            [
                                -164.779353999779,
                                68.9477639998724
                            ],
                            [
                                -164.619555999688,
                                68.9652170000847
                            ],
                            [
                                -164.537111999678,
                                68.9663069999276
                            ],
                            [
                                -164.288556999985,
                                68.9764319999432
                            ],
                            [
                                -164.230150999799,
                                68.9868840000965
                            ],
                            [
                                -164.044062999823,
                                69.0332699999852
                            ],
                            [
                                -164.001903999934,
                                69.0444170000723
                            ],
                            [
                                -163.935267999831,
                                69.0703120000691
                            ],
                            [
                                -163.855722999787,
                                69.0909109998943
                            ],
                            [
                                -163.75983799956,
                                69.1218309999029
                            ],
                            [
                                -163.711499999973,
                                69.1469560000698
                            ],
                            [
                                -163.654535999735,
                                69.1729840000573
                            ],
                            [
                                -163.633893000171,
                                69.1883050000341
                            ],
                            [
                                -163.611740999812,
                                69.2107369998499
                            ],
                            [
                                -163.564197000443,
                                69.2318100000271
                            ],
                            [
                                -163.535253999743,
                                69.248536000123
                            ],
                            [
                                -163.386714000303,
                                69.3161800000916
                            ],
                            [
                                -163.362880999621,
                                69.3301920000715
                            ],
                            [
                                -163.317165999816,
                                69.3746819999871
                            ],
                            [
                                -163.301348999689,
                                69.3972120001303
                            ],
                            [
                                -163.289040999623,
                                69.4274739999345
                            ],
                            [
                                -163.282255000203,
                                69.4563189998767
                            ],
                            [
                                -163.289204999655,
                                69.4927259999642
                            ],
                            [
                                -163.287858999965,
                                69.5236530000237
                            ],
                            [
                                -163.284669999571,
                                69.5505760000925
                            ],
                            [
                                -163.28638500023,
                                69.5660369999135
                            ],
                            [
                                -163.291151000292,
                                69.578904000026
                            ],
                            [
                                -163.294301000327,
                                69.5959189999194
                            ],
                            [
                                -163.284397999585,
                                69.646752000109
                            ],
                            [
                                -163.273549000084,
                                69.6639349998466
                            ],
                            [
                                -163.26424799994,
                                69.6713809998729
                            ],
                            [
                                -163.257537999789,
                                69.6817489998877
                            ],
                            [
                                -163.255168999552,
                                69.6965720001002
                            ],
                            [
                                -163.245412000196,
                                69.7159359999763
                            ],
                            [
                                -163.228031999747,
                                69.7339930000114
                            ],
                            [
                                -163.202166000186,
                                69.7722699998503
                            ],
                            [
                                -163.195390999738,
                                69.7879949998818
                            ],
                            [
                                -163.183492999751,
                                69.8064460001048
                            ],
                            [
                                -163.165315999609,
                                69.8242769998495
                            ],
                            [
                                -163.107598000234,
                                69.8571290001218
                            ],
                            [
                                -163.049352999706,
                                69.893206000051
                            ],
                            [
                                -163.013802999685,
                                69.9116899998642
                            ],
                            [
                                -162.969738000241,
                                69.9307820001373
                            ],
                            [
                                -162.896116999627,
                                69.966964000065
                            ],
                            [
                                -162.847065000188,
                                69.9995040000109
                            ],
                            [
                                -162.762626000036,
                                70.0491329998792
                            ],
                            [
                                -162.661127999825,
                                70.1163569998978
                            ],
                            [
                                -162.572568999692,
                                70.1694260000355
                            ],
                            [
                                -162.400766000381,
                                70.2384750000926
                            ],
                            [
                                -162.306873999748,
                                70.2682020000905
                            ],
                            [
                                -162.215670000093,
                                70.2995060000633
                            ],
                            [
                                -162.00022099984,
                                70.3589739999755
                            ],
                            [
                                -161.93288099993,
                                70.3743490000761
                            ],
                            [
                                -161.874858000059,
                                70.3806520001426
                            ],
                            [
                                -161.818580999909,
                                70.3774909999079
                            ],
                            [
                                -161.56505400029,
                                70.354288999973
                            ],
                            [
                                -161.444295000188,
                                70.3478759999417
                            ],
                            [
                                -161.283230999875,
                                70.349866000019
                            ],
                            [
                                -161.182081999644,
                                70.3598419999036
                            ],
                            [
                                -160.975017000193,
                                70.4012900001336
                            ],
                            [
                                -160.729804999981,
                                70.4571970000193
                            ],
                            [
                                -160.491010000016,
                                70.535492000131
                            ],
                            [
                                -160.229573000147,
                                70.6375410000355
                            ],
                            [
                                -160.087482999669,
                                70.7004869999266
                            ],
                            [
                                -159.900387000113,
                                70.7686900001395
                            ],
                            [
                                -159.835356000165,
                                70.7998930000372
                            ],
                            [
                                -159.764534999783,
                                70.8285630001074
                            ],
                            [
                                -159.712427000019,
                                70.8468300000136
                            ],
                            [
                                -159.552084999954,
                                70.8754309999522
                            ],
                            [
                                -159.431782999787,
                                70.8914069999444
                            ],
                            [
                                -159.26260099971,
                                70.9173570000792
                            ],
                            [
                                -159.1758409999,
                                70.9290849999853
                            ],
                            [
                                -159.093854999651,
                                70.9365340001172
                            ],
                            [
                                -158.970769999869,
                                70.9421639999241
                            ],
                            [
                                -158.882932000159,
                                70.9545669999116
                            ],
                            [
                                -158.722821999898,
                                70.9619299999153
                            ],
                            [
                                -158.685866999738,
                                70.9611379999461
                            ],
                            [
                                -158.660056000429,
                                70.9585089999948
                            ],
                            [
                                -158.599091000035,
                                70.9463599998662
                            ],
                            [
                                -158.579813000426,
                                70.9345419998994
                            ],
                            [
                                -158.566444000178,
                                70.9218350000685
                            ],
                            [
                                -158.540044999617,
                                70.9039720001181
                            ],
                            [
                                -158.521788000007,
                                70.8977499999696
                            ],
                            [
                                -158.438820999941,
                                70.882438000011
                            ],
                            [
                                -158.316435999767,
                                70.8713830000192
                            ],
                            [
                                -158.248002999888,
                                70.8676390000995
                            ],
                            [
                                -158.211498999816,
                                70.8677819999641
                            ],
                            [
                                -158.151772000055,
                                70.8708940000716
                            ],
                            [
                                -157.999427000278,
                                70.8902779998757
                            ],
                            [
                                -157.880470999801,
                                70.9100279999295
                            ],
                            [
                                -157.817106999932,
                                70.9248589999371
                            ],
                            [
                                -157.61249200001,
                                70.9804810000306
                            ],
                            [
                                -157.553299000176,
                                70.9993050001092
                            ],
                            [
                                -157.498911999689,
                                71.0240820000351
                            ],
                            [
                                -157.478613000081,
                                71.0308980001321
                            ],
                            [
                                -157.457249999918,
                                71.0412050000122
                            ],
                            [
                                -157.423809999667,
                                71.0536629998721
                            ],
                            [
                                -157.384213000358,
                                71.0724300001145
                            ],
                            [
                                -157.359988000039,
                                71.0918719998788
                            ],
                            [
                                -157.341057999686,
                                71.0986749999162
                            ],
                            [
                                -157.312728000353,
                                71.1140490001154
                            ],
                            [
                                -157.294239000041,
                                71.1284659999353
                            ],
                            [
                                -157.212660000395,
                                71.1699499998996
                            ],
                            [
                                -157.129028999807,
                                71.217510000105
                            ],
                            [
                                -157.031191999993,
                                71.2646650000681
                            ],
                            [
                                -156.945337999657,
                                71.3119890001192
                            ],
                            [
                                -156.860594999731,
                                71.3427560000518
                            ],
                            [
                                -156.832581999692,
                                71.3588289999851
                            ],
                            [
                                -156.789621999912,
                                71.3735859999263
                            ],
                            [
                                -156.668302000119,
                                71.3907770001096
                            ],
                            [
                                -156.634751000438,
                                71.39738299995
                            ],
                            [
                                -156.631305000126,
                                71.4034370001253
                            ],
                            [
                                -156.618524000233,
                                71.4142389998859
                            ],
                            [
                                -156.593307000227,
                                71.4231520000534
                            ],
                            [
                                -156.539092999992,
                                71.4338030001354
                            ],
                            [
                                -156.512787000215,
                                71.4377690000867
                            ],
                            [
                                -156.477530000097,
                                71.4406869999215
                            ],
                            [
                                -156.427354999653,
                                71.4391370001251
                            ],
                            [
                                -156.388046000224,
                                71.4351179999507
                            ],
                            [
                                -156.321273999678,
                                71.4218569999872
                            ],
                            [
                                -156.293621000387,
                                71.4091689999812
                            ],
                            [
                                -156.285111999913,
                                71.4106819998925
                            ],
                            [
                                -156.263836999613,
                                71.410538000137
                            ],
                            [
                                -156.223081000213,
                                71.403283000031
                            ],
                            [
                                -156.198819999908,
                                71.3953840000782
                            ],
                            [
                                -156.116558000372,
                                71.3860500001137
                            ],
                            [
                                -155.978640999579,
                                71.3627910001195
                            ],
                            [
                                -155.941378000347,
                                71.3608000001064
                            ],
                            [
                                -155.892558999637,
                                71.3540030000032
                            ],
                            [
                                -155.843831999888,
                                71.3366060000765
                            ],
                            [
                                -155.818593000141,
                                71.3239330000072
                            ],
                            [
                                -155.797831999677,
                                71.3113629999356
                            ],
                            [
                                -155.781931999709,
                                71.2978530000508
                            ],
                            [
                                -155.767015000106,
                                71.2891989999599
                            ],
                            [
                                -155.761007000112,
                                71.2823359999043
                            ],
                            [
                                -155.747385000144,
                                71.2862730001373
                            ],
                            [
                                -155.723153000152,
                                71.2889820000818
                            ],
                            [
                                -155.658560999697,
                                71.2892269998789
                            ],
                            [
                                -155.560545999608,
                                71.2801380000304
                            ],
                            [
                                -155.541839999561,
                                71.2762309999855
                            ],
                            [
                                -155.487507000399,
                                71.2754410000988
                            ],
                            [
                                -155.436059000061,
                                71.2706319999491
                            ],
                            [
                                -155.300076000209,
                                71.240326000094
                            ],
                            [
                                -155.225676000344,
                                71.2300479998959
                            ],
                            [
                                -155.096302000361,
                                71.2235560000235
                            ],
                            [
                                -155.064468000091,
                                71.2170829999248
                            ],
                            [
                                -155.04156500007,
                                71.2101560000811
                            ],
                            [
                                -155.033868000255,
                                71.2059589998629
                            ],
                            [
                                -154.999462000257,
                                71.2041639999369
                            ],
                            [
                                -154.910955000003,
                                71.1853989998731
                            ],
                            [
                                -154.769903000141,
                                71.1493810000461
                            ],
                            [
                                -154.688107999686,
                                71.1228319999378
                            ],
                            [
                                -154.570158000037,
                                71.0773860001398
                            ],
                            [
                                -154.536530000364,
                                71.0663889998769
                            ],
                            [
                                -154.496445000261,
                                71.056645000047
                            ],
                            [
                                -154.372454000107,
                                71.0104750000073
                            ],
                            [
                                -154.326160000168,
                                71.0057000000189
                            ],
                            [
                                -153.900423999561,
                                70.9307359999609
                            ],
                            [
                                -153.874958000033,
                                70.9350630001187
                            ],
                            [
                                -153.815807999857,
                                70.9365600000525
                            ],
                            [
                                -153.80152300009,
                                70.9396890001215
                            ],
                            [
                                -153.749519000083,
                                70.9415220001419
                            ],
                            [
                                -153.615917999989,
                                70.9353440001018
                            ],
                            [
                                -153.494346000301,
                                70.9325830001301
                            ],
                            [
                                -153.457376999898,
                                70.9373760001234
                            ],
                            [
                                -153.242773999742,
                                70.9764589998781
                            ],
                            [
                                -153.162151999623,
                                70.9763469999237
                            ],
                            [
                                -153.122957999822,
                                70.9789010001114
                            ],
                            [
                                -153.07013700003,
                                70.9768560000897
                            ],
                            [
                                -153.045401000248,
                                70.9715950000186
                            ],
                            [
                                -153.000080999554,
                                70.9746960000146
                            ],
                            [
                                -152.984130000431,
                                70.982633000145
                            ],
                            [
                                -152.939792000277,
                                70.9783759999167
                            ],
                            [
                                -152.91897800011,
                                70.9741129999854
                            ],
                            [
                                -152.914057000237,
                                70.9716770000181
                            ],
                            [
                                -152.85221599961,
                                70.9760100000821
                            ],
                            [
                                -152.82270000042,
                                70.974841999978
                            ],
                            [
                                -152.778450999954,
                                70.9691829998944
                            ],
                            [
                                -152.745823000364,
                                70.9631989999901
                            ],
                            [
                                -152.71190199989,
                                70.9534069998706
                            ],
                            [
                                -152.691888999613,
                                70.9530259998879
                            ],
                            [
                                -152.655498000417,
                                70.9459099998863
                            ],
                            [
                                -152.595738999971,
                                70.9492669999787
                            ],
                            [
                                -152.477149999914,
                                70.9424399998777
                            ],
                            [
                                -152.440486999833,
                                70.9370390000969
                            ],
                            [
                                -152.402160000045,
                                70.9292630001002
                            ],
                            [
                                -152.376611999603,
                                70.9222490000481
                            ],
                            [
                                -152.345573000274,
                                70.9213380001072
                            ],
                            [
                                -152.299414999879,
                                70.9134540000547
                            ],
                            [
                                -152.288471999769,
                                70.9101910000722
                            ],
                            [
                                -152.199517999626,
                                70.8987790000848
                            ],
                            [
                                -152.124527999757,
                                70.880387999887
                            ],
                            [
                                -152.082785999619,
                                70.8583599998591
                            ],
                            [
                                -152.056976000135,
                                70.8465899999954
                            ],
                            [
                                -152.043151999602,
                                70.8347999999065
                            ],
                            [
                                -152.039897999809,
                                70.823696999899
                            ],
                            [
                                -152.044360999922,
                                70.818152000127
                            ],
                            [
                                -151.982510999972,
                                70.7847399998652
                            ],
                            [
                                -151.615572000261,
                                70.5970890000623
                            ],
                            [
                                -151.559228000165,
                                70.5893079998902
                            ],
                            [
                                -151.542113999854,
                                70.5836189998993
                            ],
                            [
                                -151.51442499968,
                                70.5700069999769
                            ],
                            [
                                -151.499482000137,
                                70.5585930000974
                            ],
                            [
                                -151.502392999799,
                                70.5431490000343
                            ],
                            [
                                -151.50808199995,
                                70.5311839999627
                            ],
                            [
                                -151.549576000391,
                                70.5064419999477
                            ],
                            [
                                -151.545011999995,
                                70.490318999916
                            ],
                            [
                                -151.520825000209,
                                70.4891809999335
                            ],
                            [
                                -151.444056999933,
                                70.4800829999154
                            ],
                            [
                                -151.42651300035,
                                70.4784800000277
                            ],
                            [
                                -151.246640999883,
                                70.4800149998745
                            ],
                            [
                                -151.201583999853,
                                70.4922999998665
                            ],
                            [
                                -151.169821999553,
                                70.4969590000744
                            ],
                            [
                                -151.136517999745,
                                70.4967519999021
                            ],
                            [
                                -151.101164000441,
                                70.5009820000479
                            ],
                            [
                                -151.076831999992,
                                70.5013769999666
                            ],
                            [
                                -151.05339199967,
                                70.4996800000599
                            ],
                            [
                                -151.001560999915,
                                70.5082680001017
                            ],
                            [
                                -150.924933000279,
                                70.5170339998662
                            ],
                            [
                                -150.876122999791,
                                70.538941999983
                            ],
                            [
                                -150.838981000034,
                                70.5459769999103
                            ],
                            [
                                -150.769038000434,
                                70.553322000112
                            ],
                            [
                                -150.708361999745,
                                70.5544099998621
                            ],
                            [
                                -150.621690999625,
                                70.5514179999415
                            ],
                            [
                                -150.584597000446,
                                70.558623000008
                            ],
                            [
                                -150.489908999945,
                                70.5537540000552
                            ],
                            [
                                -150.454805999813,
                                70.5612180000995
                            ],
                            [
                                -150.424456000223,
                                70.5639559999814
                            ],
                            [
                                -150.346102999883,
                                70.5605109999247
                            ],
                            [
                                -150.342558999661,
                                70.5709000000196
                            ],
                            [
                                -150.303943999994,
                                70.5953989999015
                            ],
                            [
                                -150.283873999816,
                                70.6046430000089
                            ],
                            [
                                -150.20086999994,
                                70.6153819999691
                            ],
                            [
                                -150.111646000358,
                                70.6154379999149
                            ],
                            [
                                -150.086697999966,
                                70.6130089999015
                            ],
                            [
                                -150.057684999843,
                                70.6072240000396
                            ],
                            [
                                -150.031913999818,
                                70.5965420001218
                            ],
                            [
                                -150.022045000309,
                                70.5989420000033
                            ],
                            [
                                -150.009004999949,
                                70.5976629999617
                            ],
                            [
                                -149.954163000076,
                                70.6111000000031
                            ],
                            [
                                -149.90523999961,
                                70.6169680000499
                            ],
                            [
                                -149.841439999723,
                                70.6179319999061
                            ],
                            [
                                -149.796647000182,
                                70.6123070001331
                            ],
                            [
                                -149.751826999978,
                                70.620589999885
                            ],
                            [
                                -149.694986999589,
                                70.6255580000793
                            ],
                            [
                                -149.635525000142,
                                70.6243999999259
                            ],
                            [
                                -149.590423999731,
                                70.6216670000309
                            ],
                            [
                                -149.533010999955,
                                70.61701099994
                            ],
                            [
                                -149.495813000122,
                                70.6117960000408
                            ],
                            [
                                -149.422242999562,
                                70.6079979999286
                            ],
                            [
                                -149.333844999985,
                                70.5957020001166
                            ],
                            [
                                -149.309926999813,
                                70.5897770000644
                            ],
                            [
                                -149.269137000077,
                                70.5893159999596
                            ],
                            [
                                -149.215443000425,
                                70.5823880000664
                            ],
                            [
                                -149.186370000028,
                                70.5764939999914
                            ],
                            [
                                -149.121263999737,
                                70.5718130000855
                            ],
                            [
                                -149.042076999893,
                                70.5557579998645
                            ],
                            [
                                -149.01365299995,
                                70.5474569998612
                            ],
                            [
                                -148.973161999965,
                                70.53254399998
                            ],
                            [
                                -148.939195000359,
                                70.530322999871
                            ],
                            [
                                -148.885674999885,
                                70.5309730000713
                            ],
                            [
                                -148.786709000117,
                                70.5265489999173
                            ],
                            [
                                -148.735843000285,
                                70.515135999967
                            ],
                            [
                                -148.695783000295,
                                70.5027450000892
                            ],
                            [
                                -148.679452000333,
                                70.4958650000484
                            ],
                            [
                                -148.659483000437,
                                70.4788169999683
                            ],
                            [
                                -148.601502000399,
                                70.4727529999053
                            ],
                            [
                                -148.491072000114,
                                70.453944999995
                            ],
                            [
                                -148.510030000056,
                                70.4663289998573
                            ],
                            [
                                -148.516011000286,
                                70.4723840000028
                            ],
                            [
                                -148.517173999677,
                                70.4801900000088
                            ],
                            [
                                -148.514746999714,
                                70.488169999917
                            ],
                            [
                                -148.509260999952,
                                70.4953350000094
                            ],
                            [
                                -148.492560000093,
                                70.5081329999298
                            ],
                            [
                                -148.472704000176,
                                70.5165860000121
                            ],
                            [
                                -148.448914999875,
                                70.5230399999447
                            ],
                            [
                                -148.422239000202,
                                70.5278860000542
                            ],
                            [
                                -148.396710999851,
                                70.5305390001346
                            ],
                            [
                                -148.340602999756,
                                70.53202500011
                            ],
                            [
                                -148.283355999661,
                                70.5291750001062
                            ],
                            [
                                -148.183620999789,
                                70.513407999884
                            ],
                            [
                                -148.129946000403,
                                70.5000159999689
                            ],
                            [
                                -148.121509999724,
                                70.5107980001349
                            ],
                            [
                                -148.113449999686,
                                70.5159200000051
                            ],
                            [
                                -148.088488999773,
                                70.5244239999665
                            ],
                            [
                                -148.058345000047,
                                70.5316060000374
                            ],
                            [
                                -148.037320999817,
                                70.5359429999032
                            ],
                            [
                                -147.986463000381,
                                70.5416100000259
                            ],
                            [
                                -147.959591999816,
                                70.5423870000783
                            ],
                            [
                                -147.935287000029,
                                70.541066999884
                            ],
                            [
                                -147.906874999781,
                                70.5389269999521
                            ],
                            [
                                -147.881780999799,
                                70.5351239999502
                            ],
                            [
                                -147.850164000162,
                                70.527238999913
                            ],
                            [
                                -147.81157399971,
                                70.5127899999151
                            ],
                            [
                                -147.789351000104,
                                70.5001170000641
                            ],
                            [
                                -147.778474000117,
                                70.4916610000209
                            ],
                            [
                                -147.775626000204,
                                70.4849159998992
                            ],
                            [
                                -147.779435999666,
                                70.4718690001219
                            ],
                            [
                                -147.744713000374,
                                70.4719870000229
                            ],
                            [
                                -147.707475000358,
                                70.4696389999354
                            ],
                            [
                                -147.652481999973,
                                70.4597040001456
                            ],
                            [
                                -147.638136000107,
                                70.4548990001107
                            ],
                            [
                                -147.622645000394,
                                70.4471439998737
                            ],
                            [
                                -147.610652999797,
                                70.4356730000502
                            ],
                            [
                                -147.589312000273,
                                70.4419260001093
                            ],
                            [
                                -147.578234000444,
                                70.4433629999958
                            ],
                            [
                                -147.531311000143,
                                70.4457289999731
                            ],
                            [
                                -147.451038999828,
                                70.4466849999063
                            ],
                            [
                                -147.425564000079,
                                70.442857000111
                            ],
                            [
                                -147.349332000278,
                                70.4239569999059
                            ],
                            [
                                -147.328627999716,
                                70.4164159998712
                            ],
                            [
                                -147.312619999793,
                                70.4076249999648
                            ],
                            [
                                -147.281354999611,
                                70.4041970000821
                            ],
                            [
                                -147.248494000392,
                                70.3969629999173
                            ],
                            [
                                -147.22944000019,
                                70.3921829998537
                            ],
                            [
                                -147.207184999899,
                                70.3841299998853
                            ],
                            [
                                -147.188417999753,
                                70.3750479999934
                            ],
                            [
                                -147.175486000246,
                                70.3645269999847
                            ],
                            [
                                -147.170117999587,
                                70.3590039999129
                            ],
                            [
                                -147.168427999941,
                                70.3460970001486
                            ],
                            [
                                -147.126571000176,
                                70.3537179999248
                            ],
                            [
                                -147.094880999846,
                                70.3573949999806
                            ],
                            [
                                -147.033676000151,
                                70.3594570001093
                            ],
                            [
                                -147.007151000091,
                                70.3569439999982
                            ],
                            [
                                -146.999177000267,
                                70.3634090000419
                            ],
                            [
                                -146.93120700017,
                                70.3554129998832
                            ],
                            [
                                -146.888613000088,
                                70.3470179999366
                            ],
                            [
                                -146.869702000001,
                                70.3470890000898
                            ],
                            [
                                -146.814472999615,
                                70.3290180000102
                            ],
                            [
                                -146.748982000258,
                                70.2952519999968
                            ],
                            [
                                -146.716891999895,
                                70.286802999988
                            ],
                            [
                                -146.697230000146,
                                70.286818000016
                            ],
                            [
                                -146.676657000003,
                                70.2901169999135
                            ],
                            [
                                -146.634488999893,
                                70.2905250001034
                            ],
                            [
                                -146.593431000369,
                                70.2879649998731
                            ],
                            [
                                -146.50018999984,
                                70.2864509999427
                            ],
                            [
                                -146.447378000269,
                                70.2838929999941
                            ],
                            [
                                -146.405493999841,
                                70.2888599998744
                            ],
                            [
                                -146.267660999612,
                                70.2712529999595
                            ],
                            [
                                -146.203649000013,
                                70.2571529999478
                            ],
                            [
                                -146.182895000121,
                                70.2553789999981
                            ],
                            [
                                -146.126935999989,
                                70.2556749999253
                            ],
                            [
                                -146.061011000263,
                                70.251861999869
                            ],
                            [
                                -145.979266999862,
                                70.2441110000995
                            ],
                            [
                                -145.87043300041,
                                70.2238910000282
                            ],
                            [
                                -145.847284000342,
                                70.2167690001457
                            ],
                            [
                                -145.826029999958,
                                70.2147509998977
                            ],
                            [
                                -145.701714000115,
                                70.1830489999214
                            ],
                            [
                                -145.628273000325,
                                70.1604280000174
                            ],
                            [
                                -145.574553999659,
                                70.1461430000913
                            ],
                            [
                                -145.507129000259,
                                70.1418699998585
                            ],
                            [
                                -145.296354000007,
                                70.0922569999976
                            ],
                            [
                                -145.150362999831,
                                70.0915519999791
                            ],
                            [
                                -145.112435000251,
                                70.0841530001336
                            ],
                            [
                                -145.083882000437,
                                70.074325999929
                            ],
                            [
                                -145.057342000308,
                                70.0576769999078
                            ],
                            [
                                -145.040345000173,
                                70.0404179998634
                            ],
                            [
                                -145.03293399969,
                                70.0294890000529
                            ],
                            [
                                -145.006346999706,
                                70.0282640001128
                            ],
                            [
                                -144.939323999988,
                                70.0411690000595
                            ],
                            [
                                -144.867034999718,
                                70.0428090000788
                            ],
                            [
                                -144.802644999828,
                                70.0347270001147
                            ],
                            [
                                -144.703198999661,
                                70.0172140000357
                            ],
                            [
                                -144.678778000422,
                                70.0144339999685
                            ],
                            [
                                -144.667905999558,
                                70.0178919999729
                            ],
                            [
                                -144.657103999914,
                                70.023941000014
                            ],
                            [
                                -144.639743999556,
                                70.0372279999337
                            ],
                            [
                                -144.61095100044,
                                70.0504269998618
                            ],
                            [
                                -144.538683000086,
                                70.0765270000209
                            ],
                            [
                                -144.481218000432,
                                70.0906689999111
                            ],
                            [
                                -144.445018000133,
                                70.0949060000987
                            ],
                            [
                                -144.406898999859,
                                70.0957099999321
                            ],
                            [
                                -144.378073000233,
                                70.0923970000186
                            ],
                            [
                                -144.331894999746,
                                70.099392999874
                            ],
                            [
                                -144.284666000022,
                                70.1107420001375
                            ],
                            [
                                -144.22791500022,
                                70.114968000113
                            ],
                            [
                                -144.204315999889,
                                70.1222800000395
                            ],
                            [
                                -144.114433999633,
                                70.1393340000675
                            ],
                            [
                                -144.083867000308,
                                70.1410139999611
                            ],
                            [
                                -144.063171000141,
                                70.140364999937
                            ],
                            [
                                -144.050924000175,
                                70.1441909999166
                            ],
                            [
                                -144.039888000178,
                                70.1516109999068
                            ],
                            [
                                -144.017103000158,
                                70.1597839998521
                            ],
                            [
                                -144.00505000036,
                                70.168414999996
                            ],
                            [
                                -143.951320999649,
                                70.1776990001461
                            ],
                            [
                                -143.910150000147,
                                70.1802570000544
                            ],
                            [
                                -143.882397000399,
                                70.1798790000077
                            ],
                            [
                                -143.846023000022,
                                70.176848999938
                            ],
                            [
                                -143.83248499972,
                                70.1801889998908
                            ],
                            [
                                -143.78492100026,
                                70.1845780001462
                            ],
                            [
                                -143.679801999835,
                                70.1911530000938
                            ],
                            [
                                -143.641491999766,
                                70.2037070001061
                            ],
                            [
                                -143.604935999815,
                                70.2058430000601
                            ],
                            [
                                -143.56027499962,
                                70.204870000011
                            ],
                            [
                                -143.525822999591,
                                70.2013609999081
                            ],
                            [
                                -143.461790000076,
                                70.1905399998694
                            ],
                            [
                                -143.444714000299,
                                70.1855569998792
                            ],
                            [
                                -143.411447000301,
                                70.1835710000559
                            ],
                            [
                                -143.360787000333,
                                70.1963240000705
                            ],
                            [
                                -143.29718400009,
                                70.201662000147
                            ],
                            [
                                -143.226433000028,
                                70.2019980000724
                            ],
                            [
                                -143.167884999551,
                                70.1934929999395
                            ],
                            [
                                -143.141449999903,
                                70.187597000055
                            ],
                            [
                                -143.122959999766,
                                70.1805829999412
                            ],
                            [
                                -143.104387999613,
                                70.1691829999734
                            ],
                            [
                                -143.050273999836,
                                70.1598309999018
                            ],
                            [
                                -143.027932000405,
                                70.1511909998676
                            ],
                            [
                                -142.923823000108,
                                70.1311759998659
                            ],
                            [
                                -142.890096999627,
                                70.1236259999154
                            ],
                            [
                                -142.858235999593,
                                70.1142659999198
                            ],
                            [
                                -142.802910000021,
                                70.1070519998889
                            ],
                            [
                                -142.760391000282,
                                70.0954320000828
                            ],
                            [
                                -142.706295000048,
                                70.0881960001367
                            ],
                            [
                                -142.480272999581,
                                70.0339049999267
                            ],
                            [
                                -142.425342999844,
                                70.0114970001466
                            ],
                            [
                                -142.349506000054,
                                69.9944649998696
                            ],
                            [
                                -142.312375000167,
                                69.9803200001173
                            ],
                            [
                                -142.311558000383,
                                69.9777439999386
                            ],
                            [
                                -142.307590999664,
                                69.975715999973
                            ],
                            [
                                -142.284598999954,
                                69.9716149999448
                            ],
                            [
                                -142.258140999841,
                                69.964310000131
                            ],
                            [
                                -142.239793999819,
                                69.9547419999594
                            ],
                            [
                                -142.206100000024,
                                69.9466959998573
                            ],
                            [
                                -142.171076000433,
                                69.9352419999209
                            ],
                            [
                                -142.056978000078,
                                69.9031489999071
                            ],
                            [
                                -141.993348000071,
                                69.8912590001498
                            ],
                            [
                                -141.914015999564,
                                69.8720400001057
                            ],
                            [
                                -141.80286399993,
                                69.8549230000802
                            ],
                            [
                                -141.746109999754,
                                69.8482420000024
                            ],
                            [
                                -141.676876999809,
                                69.8352430001198
                            ],
                            [
                                -141.591452999643,
                                69.8145820001405
                            ],
                            [
                                -141.452862999904,
                                69.7727759999739
                            ],
                            [
                                -141.383844000219,
                                69.7499750001458
                            ],
                            [
                                -141.377895999601,
                                69.7441470000319
                            ],
                            [
                                -141.133588999936,
                                69.725351000106
                            ],
                            [
                                -141.024217000358,
                                69.7084679999098
                            ],
                            [
                                -141.00271299973,
                                69.7036319998586
                            ],
                            [
                                -141.001839999869,
                                60.3061049999053
                            ],
                            [
                                -140.535090000118,
                                60.2242240000634
                            ],
                            [
                                -140.472291999964,
                                60.3105899999398
                            ],
                            [
                                -139.989141999755,
                                60.1852400001329
                            ],
                            [
                                -139.698361000225,
                                60.3404209999252
                            ],
                            [
                                -139.086668999887,
                                60.3576539999352
                            ],
                            [
                                -139.082245999957,
                                60.3238250000925
                            ],
                            [
                                -139.200346000292,
                                60.0907009997876
                            ],
                            [
                                -139.060938000045,
                                60.0109670000809
                            ],
                            [
                                -139.053596999883,
                                60.0018640001218
                            ],
                            [
                                -139.046425999599,
                                59.9982350000057
                            ],
                            [
                                -138.796083000351,
                                59.9287009998632
                            ],
                            [
                                -138.702052999626,
                                59.9102450002047
                            ],
                            [
                                -138.662769000312,
                                59.8137189998499
                            ],
                            [
                                -138.662971999803,
                                59.8102249998059
                            ],
                            [
                                -138.630952999585,
                                59.7822089998041
                            ],
                            [
                                -138.620930999915,
                                59.7705590000464
                            ],
                            [
                                -138.560225999812,
                                59.7412010001671
                            ],
                            [
                                -138.001128000383,
                                59.4521640002138
                            ],
                            [
                                -137.604276999851,
                                59.2430569999309
                            ],
                            [
                                -137.498558000275,
                                58.986694000132
                            ],
                            [
                                -137.526424000001,
                                58.9068339999172
                            ],
                            [
                                -137.447382999748,
                                58.9095129999723
                            ],
                            [
                                -137.264751999955,
                                59.0023520002254
                            ],
                            [
                                -136.863895999968,
                                59.1384720000166
                            ],
                            [
                                -136.826632999837,
                                59.1583889999944
                            ],
                            [
                                -136.581521000083,
                                59.1649090000874
                            ],
                            [
                                -136.486609000174,
                                59.2611080000183
                            ],
                            [
                                -136.494084000231,
                                59.2729900001001
                            ],
                            [
                                -136.466815000181,
                                59.2842520000355
                            ],
                            [
                                -136.474323999674,
                                59.4641930001314
                            ],
                            [
                                -136.365821999585,
                                59.4480069998083
                            ],
                            [
                                -136.358139999839,
                                59.4497990000827
                            ],
                            [
                                -136.301845999971,
                                59.4641290000003
                            ],
                            [
                                -136.234229000052,
                                59.5247309998471
                            ],
                            [
                                -136.236527000143,
                                59.5382720001413
                            ],
                            [
                                -136.23733999973,
                                59.5587339998896
                            ],
                            [
                                -136.350622000125,
                                59.5993260001784
                            ],
                            [
                                -136.19035000038,
                                59.6398540000087
                            ],
                            [
                                -135.945904999724,
                                59.6638019998951
                            ],
                            [
                                -135.85416899979,
                                59.6918460000838
                            ],
                            [
                                -135.477436000015,
                                59.799625999935
                            ],
                            [
                                -135.254125000092,
                                59.7013390000953
                            ],
                            [
                                -135.243775000359,
                                59.6979490001586
                            ],
                            [
                                -135.231147999552,
                                59.697176000134
                            ],
                            [
                                -135.214343999762,
                                59.6643430000556
                            ],
                            [
                                -135.166735999921,
                                59.6322399998708
                            ],
                            [
                                -135.153113000128,
                                59.6251589998155
                            ],
                            [
                                -135.114587999973,
                                59.6234150001278
                            ],
                            [
                                -135.02745599972,
                                59.5636920001238
                            ],
                            [
                                -135.026327999591,
                                59.4746579999754
                            ],
                            [
                                -135.071239000031,
                                59.453309000121
                            ],
                            [
                                -135.097985000024,
                                59.4277829999546
                            ],
                            [
                                -134.993254999762,
                                59.3877959999072
                            ],
                            [
                                -135.029244999998,
                                59.3453639997955
                            ],
                            [
                                -134.961972000036,
                                59.2803759998594
                            ],
                            [
                                -134.702382999995,
                                59.2478359998556
                            ],
                            [
                                -134.681923999655,
                                59.1908430001072
                            ],
                            [
                                -134.566688999849,
                                59.1282779999648
                            ],
                            [
                                -134.481241000292,
                                59.1280710002052
                            ],
                            [
                                -134.442195999556,
                                59.0830100001166
                            ],
                            [
                                -134.37977099967,
                                59.0349609997806
                            ],
                            [
                                -134.40029299976,
                                58.9964839998881
                            ],
                            [
                                -134.401041999772,
                                58.9762209999499
                            ],
                            [
                                -134.306390000154,
                                58.959237999882
                            ],
                            [
                                -134.328964000288,
                                58.9195930002045
                            ],
                            [
                                -134.250525999559,
                                58.8580459998775
                            ],
                            [
                                -133.99208100044,
                                58.7745810000063
                            ],
                            [
                                -133.840391999891,
                                58.727991000059
                            ],
                            [
                                -133.72363499977,
                                58.6260040001835
                            ],
                            [
                                -133.699834999598,
                                58.6072899998328
                            ],
                            [
                                -133.559949000399,
                                58.5223310000022
                            ],
                            [
                                -133.379907000052,
                                58.427908999802
                            ],
                            [
                                -133.461474000003,
                                58.3855220001769
                            ],
                            [
                                -133.3596550003,
                                58.284768999814
                            ],
                            [
                                -133.343694000233,
                                58.2708979999934
                            ],
                            [
                                -133.222898000321,
                                58.1863679998883
                            ],
                            [
                                -133.17644399965,
                                58.1501509999949
                            ],
                            [
                                -133.076420999899,
                                57.9997619999353
                            ],
                            [
                                -132.869317999914,
                                57.8429410000127
                            ],
                            [
                                -132.756813000019,
                                57.7050930000776
                            ],
                            [
                                -132.658124000261,
                                57.6194860000235
                            ],
                            [
                                -132.559177999686,
                                57.5039269998587
                            ],
                            [
                                -132.367983999891,
                                57.3486849997643
                            ],
                            [
                                -132.252186999669,
                                57.2156549997949
                            ],
                            [
                                -132.371312000202,
                                57.095228999797
                            ],
                            [
                                -132.051043999999,
                                57.0511550001779
                            ],
                            [
                                -132.083722999643,
                                56.9760460000067
                            ],
                            [
                                -132.116100000061,
                                56.8903880002382
                            ],
                            [
                                -132.119034000187,
                                56.8912640001916
                            ],
                            [
                                -132.125934000308,
                                56.8746980000509
                            ],
                            [
                                -132.005107000435,
                                56.8429200002159
                            ],
                            [
                                -131.871724999725,
                                56.8049650000562
                            ],
                            [
                                -131.886499999936,
                                56.7760829998878
                            ],
                            [
                                -131.901759999671,
                                56.7531579999295
                            ],
                            [
                                -131.862035000316,
                                56.7041359999281
                            ],
                            [
                                -131.835132999789,
                                56.6018490002043
                            ],
                            [
                                -131.761209000125,
                                56.6039600000357
                            ],
                            [
                                -131.581221000205,
                                56.613275000106
                            ],
                            [
                                -131.461806000143,
                                56.5479040001661
                            ],
                            [
                                -131.363464999641,
                                56.5156899999571
                            ],
                            [
                                -131.167924999907,
                                56.4483609997653
                            ],
                            [
                                -131.085704000379,
                                56.4065399999151
                            ],
                            [
                                -130.782229999921,
                                56.3675089997556
                            ],
                            [
                                -130.740616999655,
                                56.3429519999944
                            ],
                            [
                                -130.622487999906,
                                56.267936999854
                            ],
                            [
                                -130.541172999851,
                                56.2480169998278
                            ],
                            [
                                -130.466874000268,
                                56.2397899998947
                            ],
                            [
                                -130.425579000019,
                                56.1406780001327
                            ],
                            [
                                -130.343714000143,
                                56.1271619999493
                            ],
                            [
                                -130.245540000045,
                                56.0968759998974
                            ],
                            [
                                -130.10277000005,
                                56.1166919999418
                            ],
                            [
                                -130.061273000135,
                                56.0685080000637
                            ],
                            [
                                -130.031572999925,
                                56.0367909999937
                            ],
                            [
                                -130.016871000407,
                                56.01733300002
                            ],
                            [
                                -130.004260000392,
                                55.9933789997934
                            ],
                            [
                                -130.015364000161,
                                55.9846560002054
                            ],
                            [
                                -130.020249999873,
                                55.9643619998857
                            ],
                            [
                                -130.024731000428,
                                55.9159010000376
                            ],
                            [
                                -130.013198000314,
                                55.9163820001727
                            ],
                            [
                                -130.084510000067,
                                55.8239969997774
                            ],
                            [
                                -130.123719999762,
                                55.8070399999884
                            ],
                            [
                                -130.128537999702,
                                55.8021479997776
                            ],
                            [
                                -130.133595000017,
                                55.7906299998064
                            ],
                            [
                                -130.150594999627,
                                55.7670310000218
                            ],
                            [
                                -130.151509000394,
                                55.7460289998657
                            ],
                            [
                                -130.150060999699,
                                55.7270990002291
                            ],
                            [
                                -130.148039999617,
                                55.7150409999999
                            ],
                            [
                                -130.129517999693,
                                55.6998060000126
                            ],
                            [
                                -130.11167700001,
                                55.682051000237
                            ],
                            [
                                -130.126742999576,
                                55.5812820001881
                            ],
                            [
                                -130.120132000058,
                                55.5639190000446
                            ],
                            [
                                -130.085413000066,
                                55.4915169998688
                            ],
                            [
                                -130.044302999765,
                                55.4519699998163
                            ],
                            [
                                -130.03018200003,
                                55.3676959997453
                            ],
                            [
                                -130.023558000093,
                                55.3382589998484
                            ],
                            [
                                -129.982348000233,
                                55.3020790000454
                            ],
                            [
                                -129.979511000191,
                                55.2867229999754
                            ],
                            [
                                -129.985379000443,
                                55.277760000229
                            ],
                            [
                                -130.001734999622,
                                55.2645569998795
                            ],
                            [
                                -130.030161999938,
                                55.2465919998038
                            ],
                            [
                                -130.07985399961,
                                55.2085269997871
                            ],
                            [
                                -130.096546000147,
                                55.1979530001284
                            ],
                            [
                                -130.118919000438,
                                55.1760740000579
                            ],
                            [
                                -130.144723000075,
                                55.146038000148
                            ],
                            [
                                -130.152911999984,
                                55.124536999785
                            ],
                            [
                                -130.158117000437,
                                55.1171040000104
                            ],
                            [
                                -130.182706999731,
                                55.0932119997987
                            ],
                            [
                                -130.182375000368,
                                55.0793880001545
                            ],
                            [
                                -130.187540999565,
                                55.0646649998611
                            ],
                            [
                                -130.221512000268,
                                55.0259899998206
                            ],
                            [
                                -130.247951000115,
                                55.0023410001369
                            ],
                            [
                                -130.259079000173,
                                54.9876419997816
                            ],
                            [
                                -130.33950399975,
                                54.9213759997736
                            ],
                            [
                                -130.40976400044,
                                54.8811919999309
                            ],
                            [
                                -130.474605000418,
                                54.8381019998251
                            ],
                            [
                                -130.569365999815,
                                54.7908689999163
                            ],
                            [
                                -130.636745000083,
                                54.7784559997614
                            ],
                            [
                                -130.657754000244,
                                54.7618280000065
                            ],
                            [
                                -130.626367999688,
                                54.7380680001169
                            ],
                            [
                                -130.629351000217,
                                54.7240080000452
                            ],
                            [
                                -130.615396999989,
                                54.7054909998564
                            ],
                            [
                                -132.004807999786,
                                54.6824479999268
                            ],
                            [
                                -132.925702000194,
                                54.6589700002076
                            ],
                            [
                                -133.648902999644,
                                54.6320449999771
                            ],
                            [
                                -133.659634000042,
                                54.6491519998853
                            ],
                            [
                                -133.696647999753,
                                54.7360409998965
                            ],
                            [
                                -133.724505000157,
                                54.7949710001775
                            ],
                            [
                                -133.741565999865,
                                54.8229220000736
                            ],
                            [
                                -133.756058000219,
                                54.8772790001065
                            ],
                            [
                                -133.766432000242,
                                54.9049769998417
                            ],
                            [
                                -133.776053000054,
                                54.9373529999365
                            ],
                            [
                                -133.784520999623,
                                54.953490000057
                            ],
                            [
                                -133.790527999791,
                                55.0005809998927
                            ],
                            [
                                -133.766988999736,
                                55.0258559997459
                            ],
                            [
                                -133.751021999821,
                                55.0365160000669
                            ],
                            [
                                -133.700940000161,
                                55.0786169999802
                            ],
                            [
                                -133.66021599965,
                                55.1171880001945
                            ],
                            [
                                -133.61365999977,
                                55.170264999987
                            ],
                            [
                                -133.611481000403,
                                55.176458999816
                            ],
                            [
                                -133.637459000117,
                                55.1788049998989
                            ],
                            [
                                -133.648167999875,
                                55.1814920001173
                            ],
                            [
                                -133.670012000263,
                                55.1878330000541
                            ],
                            [
                                -133.69122100044,
                                55.1984730001422
                            ],
                            [
                                -133.697484999629,
                                55.207897000017
                            ],
                            [
                                -133.703937000037,
                                55.2203040000293
                            ],
                            [
                                -133.704737000103,
                                55.226322000108
                            ],
                            [
                                -133.707609000304,
                                55.229370999837
                            ],
                            [
                                -133.741487000223,
                                55.2503649998507
                            ],
                            [
                                -133.761697000142,
                                55.2657990000972
                            ],
                            [
                                -133.775622999884,
                                55.282244999798
                            ],
                            [
                                -133.775713000295,
                                55.3028700002349
                            ],
                            [
                                -133.778834000019,
                                55.3100470000738
                            ],
                            [
                                -133.778449000055,
                                55.3196159997858
                            ],
                            [
                                -133.770296999955,
                                55.33512000008
                            ],
                            [
                                -133.761375999752,
                                55.346260999769
                            ],
                            [
                                -133.767367999852,
                                55.3576329997682
                            ],
                            [
                                -133.772155999655,
                                55.3791409999959
                            ],
                            [
                                -133.769151000283,
                                55.3889240001676
                            ],
                            [
                                -133.771598000337,
                                55.3943229999637
                            ],
                            [
                                -133.807329999932,
                                55.3880309999913
                            ],
                            [
                                -133.833038000208,
                                55.3885680001765
                            ],
                            [
                                -133.873916999632,
                                55.3988400001945
                            ],
                            [
                                -133.885698000341,
                                55.403940999889
                            ],
                            [
                                -133.897150000264,
                                55.4149409999733
                            ],
                            [
                                -133.907152999668,
                                55.4340030001272
                            ],
                            [
                                -133.904453999719,
                                55.4461969998985
                            ],
                            [
                                -133.897855999721,
                                55.4590750002515
                            ],
                            [
                                -133.883841000291,
                                55.4695849998186
                            ],
                            [
                                -133.861380999961,
                                55.4806389997503
                            ],
                            [
                                -133.861197999662,
                                55.5016230001617
                            ],
                            [
                                -133.845737000086,
                                55.5252329998388
                            ],
                            [
                                -133.834840999832,
                                55.5332600001489
                            ],
                            [
                                -133.836091999985,
                                55.5460530002098
                            ],
                            [
                                -133.834271999744,
                                55.5568229998475
                            ],
                            [
                                -133.829339000176,
                                55.5709169999379
                            ],
                            [
                                -133.810351000097,
                                55.5864060000993
                            ],
                            [
                                -133.813039000176,
                                55.5937599997511
                            ],
                            [
                                -133.813934999603,
                                55.6030499999543
                            ],
                            [
                                -133.808612999872,
                                55.6160570000117
                            ],
                            [
                                -133.798246000421,
                                55.6332310000442
                            ],
                            [
                                -133.807749000232,
                                55.6383639999129
                            ],
                            [
                                -133.812675000127,
                                55.6451349997995
                            ],
                            [
                                -133.842233999873,
                                55.6472280001496
                            ],
                            [
                                -133.867501000107,
                                55.6573249998318
                            ],
                            [
                                -133.882491000404,
                                55.6735340001821
                            ],
                            [
                                -133.88768099989,
                                55.6836389998748
                            ],
                            [
                                -133.886146000055,
                                55.7006059999065
                            ],
                            [
                                -133.879562000301,
                                55.7154509999384
                            ],
                            [
                                -133.852227999954,
                                55.7333190001306
                            ],
                            [
                                -133.831783999682,
                                55.7415099997966
                            ],
                            [
                                -133.843259999895,
                                55.7518040000094
                            ],
                            [
                                -133.848518000051,
                                55.763395000099
                            ],
                            [
                                -133.848961999567,
                                55.7780639997912
                            ],
                            [
                                -133.837978000347,
                                55.7932970001161
                            ],
                            [
                                -133.855726000144,
                                55.7948079999455
                            ],
                            [
                                -133.856716000181,
                                55.7928719998936
                            ],
                            [
                                -133.885900000008,
                                55.7802350001321
                            ],
                            [
                                -133.896248000091,
                                55.7781330001385
                            ],
                            [
                                -133.908296999691,
                                55.7763310000148
                            ],
                            [
                                -133.936564000173,
                                55.775996999869
                            ],
                            [
                                -133.96525500008,
                                55.7806990001114
                            ],
                            [
                                -133.995884000229,
                                55.7952010000388
                            ],
                            [
                                -134.002391999815,
                                55.7993730000007
                            ],
                            [
                                -134.007384999657,
                                55.8059379998787
                            ],
                            [
                                -134.012768000385,
                                55.8197050001315
                            ],
                            [
                                -134.011629000385,
                                55.8301940000601
                            ],
                            [
                                -134.006748999623,
                                55.8422189997761
                            ],
                            [
                                -134.0021840003,
                                55.8472619998101
                            ],
                            [
                                -134.008419000075,
                                55.8512220000891
                            ],
                            [
                                -134.019226999568,
                                55.864936999922
                            ],
                            [
                                -134.026417000117,
                                55.8824330001096
                            ],
                            [
                                -134.026977999809,
                                55.8919999999427
                            ],
                            [
                                -134.036726999667,
                                55.8808980002405
                            ],
                            [
                                -134.046608999595,
                                55.8504769998538
                            ],
                            [
                                -134.163622999634,
                                55.7218720002074
                            ],
                            [
                                -134.192027000384,
                                55.6930530001388
                            ],
                            [
                                -134.297586000127,
                                55.720639999897
                            ],
                            [
                                -134.421029999934,
                                55.7486309998067
                            ],
                            [
                                -134.503056000367,
                                55.7772730002465
                            ],
                            [
                                -134.569370000255,
                                55.804010000054
                            ],
                            [
                                -134.602305999817,
                                55.8225100001894
                            ],
                            [
                                -134.629052999635,
                                55.8435920000736
                            ],
                            [
                                -134.641273999662,
                                55.8579490001212
                            ],
                            [
                                -134.645128000403,
                                55.8652169998674
                            ],
                            [
                                -134.645684999897,
                                55.8714719998473
                            ],
                            [
                                -134.638480000177,
                                55.889152000063
                            ],
                            [
                                -134.631995999982,
                                55.8987549999515
                            ],
                            [
                                -134.622125999749,
                                55.9086990001985
                            ],
                            [
                                -134.601555999979,
                                55.9229249998297
                            ],
                            [
                                -134.524971000001,
                                55.948565999833
                            ],
                            [
                                -134.512504999751,
                                55.9559370002376
                            ],
                            [
                                -134.49654100021,
                                55.9698960002404
                            ],
                            [
                                -134.493042000194,
                                55.9774729999133
                            ],
                            [
                                -134.490287999994,
                                55.992674999828
                            ],
                            [
                                -134.494172999798,
                                55.9996380002102
                            ],
                            [
                                -134.532786999641,
                                56.0282669998557
                            ],
                            [
                                -134.661229000212,
                                56.1421690001339
                            ],
                            [
                                -134.729189000263,
                                56.1765739999242
                            ],
                            [
                                -134.823461999764,
                                56.218320999952
                            ],
                            [
                                -134.854327999739,
                                56.2364259997767
                            ],
                            [
                                -135.206949000071,
                                56.5088899998061
                            ],
                            [
                                -135.792889999578,
                                56.9450809997753
                            ],
                            [
                                -135.865793000141,
                                56.9962460000309
                            ],
                            [
                                -135.870005000184,
                                57.0005919998731
                            ],
                            [
                                -136.053883999756,
                                57.2772609999095
                            ],
                            [
                                -136.24447099983,
                                57.5387020001905
                            ],
                            [
                                -136.261524999866,
                                57.5580240000144
                            ],
                            [
                                -136.347511000447,
                                57.673156000098
                            ],
                            [
                                -136.410810000018,
                                57.7546570001204
                            ],
                            [
                                -136.439172999862,
                                57.8016120000691
                            ],
                            [
                                -136.446705999645,
                                57.8113790000271
                            ],
                            [
                                -136.454478999628,
                                57.8307800000672
                            ],
                            [
                                -136.464662999679,
                                57.8372900001145
                            ],
                            [
                                -136.537828000184,
                                57.8477099997791
                            ],
                            [
                                -136.57450499961,
                                57.857978000024
                            ],
                            [
                                -136.587960999897,
                                57.8632499999985
                            ],
                            [
                                -136.616659000375,
                                57.8692179999692
                            ],
                            [
                                -136.644621000361,
                                57.8837680001287
                            ],
                            [
                                -136.658568000018,
                                57.9014550000716
                            ],
                            [
                                -136.662116000438,
                                57.9203080002238
                            ],
                            [
                                -136.660498999689,
                                57.938870000147
                            ],
                            [
                                -136.65416699983,
                                57.9527789999743
                            ],
                            [
                                -136.648455000112,
                                57.9583240001127
                            ],
                            [
                                -136.656214999676,
                                57.9784699998246
                            ],
                            [
                                -136.651129999772,
                                57.9937769998915
                            ],
                            [
                                -136.669379999709,
                                57.9985500001913
                            ],
                            [
                                -136.679147999834,
                                58.0142659999002
                            ],
                            [
                                -136.678890999916,
                                58.0362929997633
                            ],
                            [
                                -136.668593000061,
                                58.0485069999719
                            ],
                            [
                                -136.661492999923,
                                58.0638170001848
                            ],
                            [
                                -136.657192000192,
                                58.0844760002237
                            ],
                            [
                                -136.650241000017,
                                58.1026290000404
                            ],
                            [
                                -136.615164999649,
                                58.1207119997876
                            ],
                            [
                                -136.543771999705,
                                58.1443399999796
                            ],
                            [
                                -136.51026799988,
                                58.1534639997788
                            ],
                            [
                                -136.515428000128,
                                58.1805829997966
                            ],
                            [
                                -136.544755000069,
                                58.1644479998061
                            ],
                            [
                                -136.559366000249,
                                58.1619220001131
                            ],
                            [
                                -136.590588999701,
                                58.1519669998558
                            ],
                            [
                                -136.616338999809,
                                58.1472299999125
                            ],
                            [
                                -136.658393999941,
                                58.1456460000327
                            ],
                            [
                                -136.711548999644,
                                58.1556689999764
                            ],
                            [
                                -136.738938000243,
                                58.1660640000194
                            ],
                            [
                                -136.758993000352,
                                58.1764690000567
                            ],
                            [
                                -136.761991000052,
                                58.1794049999299
                            ],
                            [
                                -136.791426999773,
                                58.1827110001805
                            ],
                            [
                                -136.81441199981,
                                58.1887900001526
                            ],
                            [
                                -136.843978000126,
                                58.2067599999344
                            ],
                            [
                                -136.85322599967,
                                58.2191600002226
                            ],
                            [
                                -136.855208000292,
                                58.2308499999266
                            ],
                            [
                                -136.880738000292,
                                58.2507810000344
                            ],
                            [
                                -136.897768999864,
                                58.25951900015
                            ],
                            [
                                -136.937828999853,
                                58.2686000000454
                            ],
                            [
                                -136.974838000439,
                                58.2960440002262
                            ],
                            [
                                -137.008662999755,
                                58.3331539998019
                            ],
                            [
                                -137.010802999663,
                                58.3394719998431
                            ],
                            [
                                -137.014500000045,
                                58.3443109999495
                            ],
                            [
                                -137.045135000041,
                                58.3301429999405
                            ],
                            [
                                -137.072353000037,
                                58.3236200000086
                            ],
                            [
                                -137.120297000337,
                                58.3296629998565
                            ],
                            [
                                -137.149585999746,
                                58.3413069998871
                            ],
                            [
                                -137.196782999683,
                                58.3681609998562
                            ],
                            [
                                -137.247534999713,
                                58.392846999898
                            ],
                            [
                                -137.280651999923,
                                58.4031380001967
                            ],
                            [
                                -137.315832999873,
                                58.4101120000254
                            ],
                            [
                                -137.359150000028,
                                58.4303779999698
                            ],
                            [
                                -137.374173999761,
                                58.4409109999484
                            ],
                            [
                                -137.414808999686,
                                58.451135999841
                            ],
                            [
                                -137.450459000164,
                                58.4643239998223
                            ],
                            [
                                -137.493061999569,
                                58.486073999877
                            ],
                            [
                                -137.533516999568,
                                58.5093890001875
                            ],
                            [
                                -137.579735000238,
                                58.5280080001976
                            ],
                            [
                                -137.602564999566,
                                58.5417480001595
                            ],
                            [
                                -137.630992999707,
                                58.548182999942
                            ],
                            [
                                -137.675051000378,
                                58.5548379998202
                            ],
                            [
                                -137.720609999649,
                                58.5708570002071
                            ],
                            [
                                -137.762217999893,
                                58.5965019999718
                            ],
                            [
                                -137.768430000101,
                                58.6271590001716
                            ],
                            [
                                -137.77593799977,
                                58.6407540001739
                            ],
                            [
                                -137.800507000046,
                                58.6562070000845
                            ],
                            [
                                -137.86955199967,
                                58.691560999831
                            ],
                            [
                                -137.996040000106,
                                58.7495050001897
                            ],
                            [
                                -138.037571000356,
                                58.7901089998838
                            ],
                            [
                                -138.031493999867,
                                58.8283340000212
                            ],
                            [
                                -138.025124000373,
                                58.8367999999887
                            ],
                            [
                                -138.025971000295,
                                58.8524400002258
                            ],
                            [
                                -138.04105399958,
                                58.8672859999196
                            ],
                            [
                                -138.133478000325,
                                58.9235199997913
                            ],
                            [
                                -138.204400000091,
                                58.9546329998596
                            ],
                            [
                                -138.24452100018,
                                58.9746579998086
                            ],
                            [
                                -138.291084999557,
                                59.0003839998482
                            ],
                            [
                                -138.32841399981,
                                59.0119379999598
                            ],
                            [
                                -138.489983999563,
                                59.042349000126
                            ],
                            [
                                -138.667200999564,
                                59.0831190001725
                            ],
                            [
                                -138.77279600019,
                                59.1256940001417
                            ],
                            [
                                -138.817792999946,
                                59.1463949997974
                            ],
                            [
                                -138.85467899961,
                                59.1672279998876
                            ],
                            [
                                -138.890564000264,
                                59.1807209998788
                            ],
                            [
                                -139.012262000349,
                                59.2164860000258
                            ],
                            [
                                -139.116488999748,
                                59.2493989999353
                            ],
                            [
                                -139.146387000325,
                                59.253234999967
                            ],
                            [
                                -139.318925000303,
                                59.2937189998071
                            ],
                            [
                                -139.437614999744,
                                59.3277529999899
                            ],
                            [
                                -139.531413999592,
                                59.3571919998312
                            ],
                            [
                                -139.641623999769,
                                59.4012399997957
                            ],
                            [
                                -139.68160400029,
                                59.4150059998831
                            ],
                            [
                                -139.731918999578,
                                59.4384970000431
                            ],
                            [
                                -139.771597999801,
                                59.4530359998377
                            ],
                            [
                                -139.922458999796,
                                59.501883000066
                            ],
                            [
                                -139.954683999879,
                                59.5163099999808
                            ],
                            [
                                -139.981141000167,
                                59.524054999851
                            ],
                            [
                                -140.035167000081,
                                59.5468470001387
                            ],
                            [
                                -140.071939999767,
                                59.5553089999218
                            ],
                            [
                                -140.179511000269,
                                59.5934749998201
                            ],
                            [
                                -140.363093999565,
                                59.6535660001096
                            ],
                            [
                                -140.379699999888,
                                59.6531740001901
                            ],
                            [
                                -140.390203999607,
                                59.6511539999236
                            ],
                            [
                                -140.425931000077,
                                59.6560899998023
                            ],
                            [
                                -140.447881999697,
                                59.6550250000645
                            ],
                            [
                                -140.525589999781,
                                59.6581570001635
                            ],
                            [
                                -140.607288999976,
                                59.6563310000464
                            ],
                            [
                                -140.656438000398,
                                59.6573689997746
                            ],
                            [
                                -140.710962000255,
                                59.6634079998232
                            ],
                            [
                                -140.765645000119,
                                59.6753380001182
                            ],
                            [
                                -140.819287999718,
                                59.6817379999364
                            ],
                            [
                                -140.89629500037,
                                59.6819100000875
                            ],
                            [
                                -140.936485000055,
                                59.686563999931
                            ],
                            [
                                -141.001678000385,
                                59.7009989999459
                            ],
                            [
                                -141.001683000408,
                                59.723721999897
                            ],
                            [
                                -141.38329199985,
                                59.8003609999914
                            ],
                            [
                                -141.646570999701,
                                59.8610379999881
                            ],
                            [
                                -142.082369999987,
                                59.9410659998736
                            ],
                            [
                                -142.39779799961,
                                59.9848500001868
                            ],
                            [
                                -142.668964000145,
                                60.0114849998764
                            ],
                            [
                                -142.859039000032,
                                60.0113669997768
                            ],
                            [
                                -143.083249999581,
                                59.9769139998292
                            ],
                            [
                                -143.714083999984,
                                59.9360840000393
                            ],
                            [
                                -143.943217999954,
                                59.9198509998916
                            ],
                            [
                                -144.144077999767,
                                59.9194519998648
                            ],
                            [
                                -144.410509000203,
                                59.7462739999369
                            ],
                            [
                                -144.542182000102,
                                59.7193209998759
                            ],
                            [
                                -144.67676999986,
                                59.7273949999896
                            ],
                            [
                                -144.778103000215,
                                59.7785630001283
                            ],
                            [
                                -144.777797999718,
                                59.8299350001648
                            ],
                            [
                                -144.641727000003,
                                59.9243890000183
                            ],
                            [
                                -144.539683999994,
                                60.0101489997895
                            ],
                            [
                                -144.607276999624,
                                60.0783469999928
                            ],
                            [
                                -144.776015000186,
                                60.1295919999117
                            ],
                            [
                                -145.097974999684,
                                60.1461119998769
                            ],
                            [
                                -145.317687000033,
                                60.1796909998773
                            ],
                            [
                                -145.621695000419,
                                60.2558030000067
                            ],
                            [
                                -145.824322999696,
                                60.3151169999617
                            ],
                            [
                                -145.976567999913,
                                60.3146520002104
                            ],
                            [
                                -146.179309000067,
                                60.2800560000932
                            ],
                            [
                                -146.465706999849,
                                60.2125010000795
                            ],
                            [
                                -146.734305999781,
                                60.1541419998515
                            ],
                            [
                                -146.939278999903,
                                60.1038480001472
                            ],
                            [
                                -147.002565999779,
                                60.0975449998273
                            ],
                            [
                                -147.101939999976,
                                60.0813369999133
                            ],
                            [
                                -147.161568000003,
                                60.0529930001185
                            ],
                            [
                                -147.24173999986,
                                59.9983169998031
                            ],
                            [
                                -147.271128999726,
                                59.963436999861
                            ],
                            [
                                -147.280941000232,
                                59.9235730001546
                            ],
                            [
                                -147.280963999798,
                                59.8787260001886
                            ],
                            [
                                -147.334827000404,
                                59.8338739999278
                            ],
                            [
                                -147.520845999884,
                                59.7740789998945
                            ],
                            [
                                -147.687240000324,
                                59.7416799999317
                            ],
                            [
                                -147.819381999853,
                                59.7366699999596
                            ],
                            [
                                -147.956439000305,
                                59.7441309999045
                            ],
                            [
                                -148.039657000441,
                                59.7690469998438
                            ],
                            [
                                -148.100745999785,
                                59.8156969998756
                            ],
                            [
                                -148.218506000361,
                                59.8756400001946
                            ],
                            [
                                -148.437223999577,
                                59.8928100001323
                            ],
                            [
                                -148.605475999892,
                                59.8842959998128
                            ],
                            [
                                -148.980201999829,
                                59.8937209999598
                            ],
                            [
                                -149.269624999974,
                                59.8454200001307
                            ],
                            [
                                -149.467503999806,
                                59.7337669998746
                            ],
                            [
                                -149.621299999752,
                                59.5275160001365
                            ],
                            [
                                -150.456456000175,
                                59.3086879998302
                            ],
                            [
                                -150.531963000252,
                                59.2928539997929
                            ],
                            [
                                -150.550701000086,
                                59.2872570001906
                            ],
                            [
                                -150.591243000125,
                                59.2790789998038
                            ],
                            [
                                -150.710968999633,
                                59.2502239997829
                            ],
                            [
                                -150.801061999777,
                                59.2318249997707
                            ],
                            [
                                -150.992193999648,
                                59.1808370002091
                            ],
                            [
                                -151.255286999726,
                                59.1377640000991
                            ],
                            [
                                -151.316151999662,
                                59.1253390000371
                            ],
                            [
                                -151.374719000405,
                                59.1106690001786
                            ],
                            [
                                -151.444173000284,
                                59.0996870001631
                            ],
                            [
                                -151.491140000067,
                                59.0958560001267
                            ],
                            [
                                -151.855379999829,
                                59.0776169998036
                            ],
                            [
                                -153.021180000127,
                                59.0024520000159
                            ],
                            [
                                -153.184086000422,
                                58.9057290000074
                            ],
                            [
                                -153.173575000132,
                                58.9001930000746
                            ],
                            [
                                -153.165141,
                                58.892714000075
                            ],
                            [
                                -153.157768000051,
                                58.8814430002071
                            ],
                            [
                                -153.152839999607,
                                58.8690190002052
                            ],
                            [
                                -153.151647000079,
                                58.8575610000332
                            ],
                            [
                                -153.152349000236,
                                58.8475819999197
                            ],
                            [
                                -153.167029000013,
                                58.8230189999494
                            ],
                            [
                                -153.186372999744,
                                58.8076170000055
                            ],
                            [
                                -153.202500000391,
                                58.8013330001417
                            ],
                            [
                                -153.189121000097,
                                58.7941510001789
                            ],
                            [
                                -153.176635999581,
                                58.7846649999549
                            ],
                            [
                                -153.170190999744,
                                58.7751720001129
                            ],
                            [
                                -153.165925000173,
                                58.7649840000941
                            ],
                            [
                                -153.166845999715,
                                58.7551750002226
                            ],
                            [
                                -153.17607499989,
                                58.7415119999315
                            ],
                            [
                                -153.186963999573,
                                58.7293449999596
                            ],
                            [
                                -153.214065000292,
                                58.7163799998379
                            ],
                            [
                                -153.244798000197,
                                58.7074700001213
                            ],
                            [
                                -153.278031999685,
                                58.7069010002049
                            ],
                            [
                                -153.307233999953,
                                58.7091619998842
                            ],
                            [
                                -153.318021000427,
                                58.7129620001702
                            ],
                            [
                                -153.357442999834,
                                58.6818109998828
                            ],
                            [
                                -153.371581000186,
                                58.6732750001653
                            ],
                            [
                                -153.478776000046,
                                58.6416940001688
                            ],
                            [
                                -153.457904000152,
                                58.6234559999965
                            ],
                            [
                                -153.447019999593,
                                58.5984090000876
                            ],
                            [
                                -153.451840000082,
                                58.5849729997801
                            ],
                            [
                                -153.46148199981,
                                58.5726079997847
                            ],
                            [
                                -153.46870900017,
                                58.5665020000691
                            ],
                            [
                                -153.491797999964,
                                58.5555029999059
                            ],
                            [
                                -153.515150000422,
                                58.5483969999921
                            ],
                            [
                                -153.557117999617,
                                58.5437590000498
                            ],
                            [
                                -153.592667999638,
                                58.5422529998367
                            ],
                            [
                                -153.597408999585,
                                58.5329979999532
                            ],
                            [
                                -153.60980399969,
                                58.5197459999355
                            ],
                            [
                                -153.628002999573,
                                58.5082970000087
                            ],
                            [
                                -153.654100000011,
                                58.4995979998434
                            ],
                            [
                                -153.692360999677,
                                58.4935059999343
                            ],
                            [
                                -153.778411999657,
                                58.5040439998386
                            ],
                            [
                                -153.799480000267,
                                58.509222999948
                            ],
                            [
                                -153.810656000006,
                                58.5153859998747
                            ],
                            [
                                -153.821070000211,
                                58.4877990001622
                            ],
                            [
                                -153.844420000121,
                                58.4722629999291
                            ],
                            [
                                -153.868057999911,
                                58.4602749999957
                            ],
                            [
                                -153.881793999682,
                                58.4564990001942
                            ],
                            [
                                -153.88408699975,
                                58.448804000191
                            ],
                            [
                                -153.883900000152,
                                58.4385479999978
                            ],
                            [
                                -153.876718999824,
                                58.4307560001457
                            ],
                            [
                                -153.872602000215,
                                58.4151369997714
                            ],
                            [
                                -153.864164999711,
                                58.4087810000952
                            ],
                            [
                                -153.85852199959,
                                58.3976010001453
                            ],
                            [
                                -153.856868999754,
                                58.3843730000761
                            ],
                            [
                                -153.85855699975,
                                58.3742739999209
                            ],
                            [
                                -153.909327000221,
                                58.3291039998308
                            ],
                            [
                                -153.925987999896,
                                58.3197949998865
                            ],
                            [
                                -153.970038000171,
                                58.3127240000551
                            ],
                            [
                                -154.002084999978,
                                58.2724340000067
                            ],
                            [
                                -154.026379999719,
                                58.2469199998392
                            ],
                            [
                                -154.039040000137,
                                58.2303619998337
                            ],
                            [
                                -154.052462999914,
                                58.2011830000135
                            ],
                            [
                                -154.05291599965,
                                58.1891960001691
                            ],
                            [
                                -154.058971000398,
                                58.1752060001252
                            ],
                            [
                                -154.070086999862,
                                58.1623570000939
                            ],
                            [
                                -154.082824000273,
                                58.1540119999602
                            ],
                            [
                                -154.108918000338,
                                58.1460099999857
                            ],
                            [
                                -154.110408999792,
                                58.1313910000741
                            ],
                            [
                                -154.119565000172,
                                58.1132279999766
                            ],
                            [
                                -154.13365400012,
                                58.1001409999412
                            ],
                            [
                                -154.155186000338,
                                58.0909830000743
                            ],
                            [
                                -154.180164999794,
                                58.0848429999431
                            ],
                            [
                                -154.211800999698,
                                58.0802880001179
                            ],
                            [
                                -154.220469000181,
                                58.0661729998963
                            ],
                            [
                                -154.23796000006,
                                58.054552000038
                            ],
                            [
                                -154.256949999789,
                                58.0449869998983
                            ],
                            [
                                -154.265522999838,
                                58.0382280000254
                            ],
                            [
                                -154.288179999812,
                                58.0308230000196
                            ],
                            [
                                -154.31566899997,
                                58.027164999952
                            ],
                            [
                                -154.335125999679,
                                58.0267110001738
                            ],
                            [
                                -154.390744000228,
                                57.9993010001169
                            ],
                            [
                                -154.539595000011,
                                57.9881970002157
                            ],
                            [
                                -154.589135000246,
                                57.9897790001053
                            ],
                            [
                                -154.708988999801,
                                57.9822949999271
                            ],
                            [
                                -154.747745999759,
                                57.9691879998795
                            ],
                            [
                                -154.776308999619,
                                57.9627109998452
                            ],
                            [
                                -154.800928999949,
                                57.9591479999086
                            ],
                            [
                                -154.840367999972,
                                57.9622189999285
                            ],
                            [
                                -154.865303999771,
                                57.967639000144
                            ],
                            [
                                -154.893803000057,
                                57.9835410000165
                            ],
                            [
                                -154.940742000252,
                                57.975071000061
                            ],
                            [
                                -154.953937000422,
                                57.9497859999358
                            ],
                            [
                                -154.969686999704,
                                57.9278540001793
                            ],
                            [
                                -154.980700000134,
                                57.8948099998102
                            ],
                            [
                                -154.994016999605,
                                57.8631880001507
                            ],
                            [
                                -155.034366000198,
                                57.8244960000158
                            ],
                            [
                                -155.043525000052,
                                57.8123900000328
                            ],
                            [
                                -155.06085000025,
                                57.8029089997817
                            ],
                            [
                                -155.078875999881,
                                57.7953400002076
                            ],
                            [
                                -155.09942200026,
                                57.7912399999483
                            ],
                            [
                                -155.129022999838,
                                57.7879529998256
                            ],
                            [
                                -155.160861000306,
                                57.7905880000136
                            ],
                            [
                                -155.150714999889,
                                57.7719609999243
                            ],
                            [
                                -155.150059999588,
                                57.7500219998821
                            ],
                            [
                                -155.179218000374,
                                57.7322579998232
                            ],
                            [
                                -155.209954999579,
                                57.7162569998821
                            ],
                            [
                                -155.225302000251,
                                57.6988820002286
                            ],
                            [
                                -155.245788000355,
                                57.681594999782
                            ],
                            [
                                -155.277003000309,
                                57.6761120002186
                            ],
                            [
                                -155.27619800022,
                                57.6699540001437
                            ],
                            [
                                -155.280948000388,
                                57.6568310002293
                            ],
                            [
                                -155.300396999701,
                                57.6428940000821
                            ],
                            [
                                -155.327356999955,
                                57.6329590000214
                            ],
                            [
                                -155.360914000381,
                                57.6250289998863
                            ],
                            [
                                -155.379555000131,
                                57.6259720001013
                            ],
                            [
                                -155.431970000042,
                                57.63248200019
                            ],
                            [
                                -155.459864000255,
                                57.637648000132
                            ],
                            [
                                -155.589109000367,
                                57.6082760002308
                            ],
                            [
                                -155.60103199957,
                                57.6108379997933
                            ],
                            [
                                -155.639485999579,
                                57.6022669997665
                            ],
                            [
                                -155.63256700009,
                                57.5868130000219
                            ],
                            [
                                -155.645502999794,
                                57.5223579999672
                            ],
                            [
                                -155.667959999751,
                                57.5083519997978
                            ],
                            [
                                -155.719259000127,
                                57.4974189998494
                            ],
                            [
                                -155.764409000043,
                                57.4990949999297
                            ],
                            [
                                -155.796127999787,
                                57.495527999947
                            ],
                            [
                                -155.816844000045,
                                57.4977099998028
                            ],
                            [
                                -155.844484999641,
                                57.4975129999669
                            ],
                            [
                                -155.850790999561,
                                57.4883830000558
                            ],
                            [
                                -155.919809000319,
                                57.4330280001179
                            ],
                            [
                                -155.939758000123,
                                57.4128700000042
                            ],
                            [
                                -155.978919000312,
                                57.3958949997893
                            ],
                            [
                                -156.098355000291,
                                57.3798479998287
                            ],
                            [
                                -156.114545999613,
                                57.3685210001841
                            ],
                            [
                                -156.141824999709,
                                57.3536349999762
                            ],
                            [
                                -156.1671959997,
                                57.3458520000627
                            ],
                            [
                                -156.169571999609,
                                57.3429190001643
                            ],
                            [
                                -156.171046000243,
                                57.3340749999045
                            ],
                            [
                                -156.184162999698,
                                57.3172269997887
                            ],
                            [
                                -156.196698000443,
                                57.3101959999845
                            ],
                            [
                                -156.200964999839,
                                57.3017030000184
                            ],
                            [
                                -156.215612999829,
                                57.2819789999794
                            ],
                            [
                                -156.247041000218,
                                57.2437760001456
                            ],
                            [
                                -156.24176499962,
                                57.2321760001449
                            ],
                            [
                                -156.225387999626,
                                57.217306999841
                            ],
                            [
                                -156.214505999616,
                                57.1951799998572
                            ],
                            [
                                -156.227811000289,
                                57.1624549999482
                            ],
                            [
                                -156.262757000062,
                                57.1335979999129
                            ],
                            [
                                -156.316483000401,
                                57.1055020000727
                            ],
                            [
                                -156.312371999742,
                                57.0870449998331
                            ],
                            [
                                -156.311669999585,
                                57.0679160001982
                            ],
                            [
                                -156.332621999844,
                                57.0491740000976
                            ],
                            [
                                -156.35896699998,
                                57.037181000243
                            ],
                            [
                                -156.357314000144,
                                56.8222589999351
                            ],
                            [
                                -156.745451000162,
                                56.5763150001391
                            ],
                            [
                                -157.000101999712,
                                56.4200970002276
                            ],
                            [
                                -157.362341000382,
                                56.1924919999472
                            ],
                            [
                                -157.711870000405,
                                55.9694160000873
                            ],
                            [
                                -157.964441000322,
                                55.81203600015
                            ],
                            [
                                -158.237442000268,
                                55.6349899999025
                            ],
                            [
                                -159.457990999655,
                                55.6288749999566
                            ],
                            [
                                -159.469101999995,
                                55.6192530000219
                            ],
                            [
                                -159.488813999972,
                                55.6089359999841
                            ],
                            [
                                -159.512615999794,
                                55.601213999837
                            ],
                            [
                                -159.521516999905,
                                55.6008939997533
                            ],
                            [
                                -159.510844999957,
                                55.586214999947
                            ],
                            [
                                -159.503979000171,
                                55.5673550000621
                            ],
                            [
                                -159.505046000201,
                                55.558107999852
                            ],
                            [
                                -159.511453000403,
                                55.5496230002001
                            ],
                            [
                                -159.538153000365,
                                55.5320060001561
                            ],
                            [
                                -159.552891999693,
                                55.5281519998375
                            ],
                            [
                                -159.584658000191,
                                55.5242589999685
                            ],
                            [
                                -159.633390999789,
                                55.5236090000639
                            ],
                            [
                                -159.655302000123,
                                55.5288860000943
                            ],
                            [
                                -159.951138000318,
                                55.507583000191
                            ],
                            [
                                -159.951898000201,
                                55.5022269999571
                            ],
                            [
                                -159.96325100039,
                                55.489683999925
                            ],
                            [
                                -159.995145000036,
                                55.4684659998428
                            ],
                            [
                                -160.002045000158,
                                55.4590809997675
                            ],
                            [
                                -160.01825800012,
                                55.4523050002373
                            ],
                            [
                                -160.041161999966,
                                55.4488790001001
                            ],
                            [
                                -160.059297000275,
                                55.4162080001435
                            ],
                            [
                                -160.052890999898,
                                55.4071059999181
                            ],
                            [
                                -160.055281999876,
                                55.399477999745
                            ],
                            [
                                -160.050544000302,
                                55.3957769997601
                            ],
                            [
                                -160.001875000278,
                                55.388380000069
                            ],
                            [
                                -159.996366999877,
                                55.3901940000692
                            ],
                            [
                                -159.961920999696,
                                55.3686639998249
                            ],
                            [
                                -159.932582999884,
                                55.336981000081
                            ],
                            [
                                -159.899361999916,
                                55.3441529998328
                            ],
                            [
                                -159.883554999835,
                                55.3451980000777
                            ],
                            [
                                -159.84330400005,
                                55.3400490000815
                            ],
                            [
                                -159.826825999774,
                                55.3348700000712
                            ],
                            [
                                -159.804214000006,
                                55.3239750000258
                            ],
                            [
                                -159.792495999944,
                                55.3171539999586
                            ],
                            [
                                -159.766801000087,
                                55.293085000222
                            ],
                            [
                                -159.75788999993,
                                55.2756829998864
                            ],
                            [
                                -159.755876000419,
                                55.2623389999146
                            ],
                            [
                                -159.748429999776,
                                55.2426319997506
                            ],
                            [
                                -159.737891999722,
                                55.2222869999299
                            ],
                            [
                                -159.735978999595,
                                55.2261080000043
                            ],
                            [
                                -159.722216000061,
                                55.2332870001795
                            ],
                            [
                                -159.704272000445,
                                55.2398650002314
                            ],
                            [
                                -159.687030000088,
                                55.2432979999593
                            ],
                            [
                                -159.660206000277,
                                55.2429099997501
                            ],
                            [
                                -159.643420999854,
                                55.2546670000083
                            ],
                            [
                                -159.609058000413,
                                55.2677899999987
                            ],
                            [
                                -159.602924999847,
                                55.2726810001013
                            ],
                            [
                                -159.599874000444,
                                55.2804630000911
                            ],
                            [
                                -159.57302100032,
                                55.3121410002547
                            ],
                            [
                                -159.562034999654,
                                55.3203730000412
                            ],
                            [
                                -159.544335000436,
                                55.3284069999603
                            ],
                            [
                                -159.521967000167,
                                55.3341910001543
                            ],
                            [
                                -159.499751000234,
                                55.3367099997574
                            ],
                            [
                                -159.482600999939,
                                55.336294000009
                            ],
                            [
                                -159.447809999976,
                                55.329098000077
                            ],
                            [
                                -159.415307000059,
                                55.3153809997766
                            ],
                            [
                                -159.407005999997,
                                55.3089109998911
                            ],
                            [
                                -159.401787000199,
                                55.3022049998419
                            ],
                            [
                                -159.398063000053,
                                55.2933699998305
                            ],
                            [
                                -159.397390000208,
                                55.2804150001521
                            ],
                            [
                                -159.399493000306,
                                55.2597930002398
                            ],
                            [
                                -159.406847999813,
                                55.2549099997812
                            ],
                            [
                                -159.419792999739,
                                55.249895999744
                            ],
                            [
                                -159.420489000048,
                                55.244450999804
                            ],
                            [
                                -159.418473999814,
                                55.2357299997835
                            ],
                            [
                                -159.420273999963,
                                55.2230419998323
                            ],
                            [
                                -159.426041999757,
                                55.212338000097
                            ],
                            [
                                -159.42707899965,
                                55.2011459999849
                            ],
                            [
                                -159.408094999768,
                                55.1743970000698
                            ],
                            [
                                -159.404675000294,
                                55.1597410000281
                            ],
                            [
                                -159.403850999938,
                                55.1494650001658
                            ],
                            [
                                -159.405863999624,
                                55.1374100001569
                            ],
                            [
                                -159.410885999779,
                                55.1234959999209
                            ],
                            [
                                -159.386329999922,
                                55.1247079997597
                            ],
                            [
                                -159.376135999825,
                                55.1232130002077
                            ],
                            [
                                -159.365637000129,
                                55.120916000197
                            ],
                            [
                                -159.348479000336,
                                55.1128969998413
                            ],
                            [
                                -159.342807999727,
                                55.115705999967
                            ],
                            [
                                -159.321992999735,
                                55.1182469997485
                            ],
                            [
                                -159.30362800017,
                                55.116493000151
                            ],
                            [
                                -159.279631000355,
                                55.1120069998735
                            ],
                            [
                                -159.274931000415,
                                55.1091160000473
                            ],
                            [
                                -159.268949000361,
                                55.1098250000907
                            ],
                            [
                                -159.252834000308,
                                55.1061709998386
                            ],
                            [
                                -159.233422999731,
                                55.098642000139
                            ],
                            [
                                -159.216574999559,
                                55.0874419999631
                            ],
                            [
                                -159.209189000089,
                                55.0772490000038
                            ],
                            [
                                -159.204305000027,
                                55.0660110000577
                            ],
                            [
                                -159.203203999662,
                                55.0433439998152
                            ],
                            [
                                -159.212298000117,
                                55.0258420000889
                            ],
                            [
                                -159.24003900017,
                                55.0118789998179
                            ],
                            [
                                -159.255063999729,
                                55.0061989999419
                            ],
                            [
                                -159.249463000339,
                                54.9998989998181
                            ],
                            [
                                -159.250140000382,
                                54.998099999873
                            ],
                            [
                                -159.245073000021,
                                54.9978869998186
                            ],
                            [
                                -159.233323999997,
                                54.9937360001383
                            ],
                            [
                                -159.213607999822,
                                54.9842539998244
                            ],
                            [
                                -159.179425000305,
                                54.9767619998843
                            ],
                            [
                                -159.14161099963,
                                54.9605349997914
                            ],
                            [
                                -159.126013999611,
                                54.9497089997439
                            ],
                            [
                                -159.118018000045,
                                54.9408359999521
                            ],
                            [
                                -159.113256000182,
                                54.9303299999683
                            ],
                            [
                                -159.111790999769,
                                54.9156899999413
                            ],
                            [
                                -159.119023000151,
                                54.9024370000111
                            ],
                            [
                                -159.132695000348,
                                54.8855310000603
                            ],
                            [
                                -159.172430999574,
                                54.8541650000959
                            ],
                            [
                                -159.201514999841,
                                54.8336720001535
                            ],
                            [
                                -159.226601000326,
                                54.8206790001079
                            ],
                            [
                                -159.260569999582,
                                54.8127370000667
                            ],
                            [
                                -159.277356999655,
                                54.8112099999123
                            ],
                            [
                                -159.300766000014,
                                54.8128809999773
                            ],
                            [
                                -159.333699000101,
                                54.8186179997449
                            ],
                            [
                                -159.362297000122,
                                54.8283149997495
                            ],
                            [
                                -159.380641999595,
                                54.8403919997728
                            ],
                            [
                                -159.395385999845,
                                54.8582140002079
                            ],
                            [
                                -159.404236999728,
                                54.8616479997865
                            ],
                            [
                                -159.416903999819,
                                54.8722530000906
                            ],
                            [
                                -159.43128200037,
                                54.8912640000204
                            ],
                            [
                                -159.431782999787,
                                54.8941969998534
                            ],
                            [
                                -159.439279999585,
                                54.8960019999042
                            ],
                            [
                                -159.457409999872,
                                54.8925149999576
                            ],
                            [
                                -159.480059000348,
                                54.8951240001271
                            ],
                            [
                                -159.503257999747,
                                54.9007550002331
                            ],
                            [
                                -159.529508000346,
                                54.9144570000636
                            ],
                            [
                                -159.541443000143,
                                54.9239549998546
                            ],
                            [
                                -159.54660300039,
                                54.9335830002532
                            ],
                            [
                                -159.540776999971,
                                54.9601180001162
                            ],
                            [
                                -159.540534000297,
                                54.9680600001626
                            ],
                            [
                                -159.543251999614,
                                54.9763690002232
                            ],
                            [
                                -159.564820999642,
                                54.9913950000918
                            ],
                            [
                                -159.580340999668,
                                54.9875980001356
                            ],
                            [
                                -159.592243999678,
                                54.9873849999962
                            ],
                            [
                                -159.604149000236,
                                54.9893910002447
                            ],
                            [
                                -159.626186999893,
                                54.9864770000092
                            ],
                            [
                                -159.676555999608,
                                54.9872389998163
                            ],
                            [
                                -159.698126000359,
                                54.9931670000151
                            ],
                            [
                                -159.707035999793,
                                54.9974930001461
                            ],
                            [
                                -159.703511999664,
                                54.9997940002356
                            ],
                            [
                                -159.724503000281,
                                55.0177930000196
                            ],
                            [
                                -159.728403000155,
                                54.9999930000307
                            ],
                            [
                                -159.734735000014,
                                54.9902450000655
                            ],
                            [
                                -159.760880000132,
                                54.9718350000573
                            ],
                            [
                                -159.768598999688,
                                54.9686679999356
                            ],
                            [
                                -159.773988000264,
                                54.9684930002369
                            ],
                            [
                                -159.769544000418,
                                54.9634120002252
                            ],
                            [
                                -159.767559000321,
                                54.9566070002004
                            ],
                            [
                                -159.770336000088,
                                54.9474740001655
                            ],
                            [
                                -159.775734999811,
                                54.9378380000509
                            ],
                            [
                                -159.788884999776,
                                54.9256620002492
                            ],
                            [
                                -159.816041999673,
                                54.9095319997624
                            ],
                            [
                                -159.846099000259,
                                54.9024350001047
                            ],
                            [
                                -159.875026999992,
                                54.904242000049
                            ],
                            [
                                -159.906918999989,
                                54.9094739997456
                            ],
                            [
                                -159.936980999699,
                                54.9190460000885
                            ],
                            [
                                -159.952976000101,
                                54.9293840000062
                            ],
                            [
                                -159.953858000183,
                                54.920005999949
                            ],
                            [
                                -159.958801999622,
                                54.9132160000403
                            ],
                            [
                                -159.97128499959,
                                54.9005040001997
                            ],
                            [
                                -159.98681899986,
                                54.8912180002341
                            ],
                            [
                                -160.010685000154,
                                54.8836190000699
                            ],
                            [
                                -160.0344879998,
                                54.8796040000465
                            ],
                            [
                                -160.065920000388,
                                54.8810840000619
                            ],
                            [
                                -160.086396000446,
                                54.8862030001844
                            ],
                            [
                                -160.093976000085,
                                54.8903790000698
                            ],
                            [
                                -160.098023000271,
                                54.8854159998894
                            ],
                            [
                                -160.101166999561,
                                54.8694780000477
                            ],
                            [
                                -160.113036999959,
                                54.8549210000606
                            ],
                            [
                                -160.130094000368,
                                54.8431099997821
                            ],
                            [
                                -160.155421999804,
                                54.8290190001192
                            ],
                            [
                                -160.185541000314,
                                54.8152679999638
                            ],
                            [
                                -160.207724999562,
                                54.8073420001276
                            ],
                            [
                                -160.241964000053,
                                54.8034649997881
                            ],
                            [
                                -160.25810599987,
                                54.8068890000546
                            ],
                            [
                                -160.2773929997,
                                54.8152349999737
                            ],
                            [
                                -160.302812000271,
                                54.8297460001867
                            ],
                            [
                                -160.315780999587,
                                54.8432879997599
                            ],
                            [
                                -160.323715000126,
                                54.8555709999988
                            ],
                            [
                                -160.323360000299,
                                54.8597720002291
                            ],
                            [
                                -160.329878999756,
                                54.8639089999948
                            ],
                            [
                                -160.337202000375,
                                54.8772759999232
                            ],
                            [
                                -160.343181000057,
                                54.8943580001424
                            ],
                            [
                                -160.343392999769,
                                54.9308810000201
                            ],
                            [
                                -160.336069000223,
                                54.9499099998767
                            ],
                            [
                                -160.326852999569,
                                54.9596909999751
                            ],
                            [
                                -160.297657000046,
                                54.9764739997535
                            ],
                            [
                                -160.301801000317,
                                54.9814740002521
                            ],
                            [
                                -160.301402999934,
                                54.9863259999671
                            ],
                            [
                                -160.29285699965,
                                55.0006739999648
                            ],
                            [
                                -160.244364000252,
                                55.0225749999582
                            ],
                            [
                                -160.256519000157,
                                55.0287919999944
                            ],
                            [
                                -160.261918999705,
                                55.0352009998238
                            ],
                            [
                                -160.265000000144,
                                55.0507040000365
                            ],
                            [
                                -160.262712000098,
                                55.0728519998298
                            ],
                            [
                                -160.27781399965,
                                55.0888589998304
                            ],
                            [
                                -160.288523000306,
                                55.1116250001936
                            ],
                            [
                                -160.285318000019,
                                55.1245559997613
                            ],
                            [
                                -160.270951000236,
                                55.1388689999275
                            ],
                            [
                                -160.245629999575,
                                55.1523800000347
                            ],
                            [
                                -160.214593999721,
                                55.1601170001058
                            ],
                            [
                                -160.206174999658,
                                55.1721870002452
                            ],
                            [
                                -160.190247000101,
                                55.185346999841
                            ],
                            [
                                -160.161289000231,
                                55.1948379997987
                            ],
                            [
                                -160.149679000124,
                                55.1963759998678
                            ],
                            [
                                -160.149573999644,
                                55.2101659997717
                            ],
                            [
                                -160.141218000228,
                                55.2253179999142
                            ],
                            [
                                -160.13009199982,
                                55.2386749999829
                            ],
                            [
                                -160.139269999941,
                                55.2423090000382
                            ],
                            [
                                -160.155994000264,
                                55.2538129999058
                            ],
                            [
                                -160.167283999806,
                                55.2725289998996
                            ],
                            [
                                -160.167648999679,
                                55.2798450000858
                            ],
                            [
                                -160.18199000042,
                                55.2886039999171
                            ],
                            [
                                -160.18641300035,
                                55.2968430000211
                            ],
                            [
                                -160.190774000356,
                                55.3153749998896
                            ],
                            [
                                -160.200504999773,
                                55.3231570000268
                            ],
                            [
                                -160.203576999991,
                                55.3357749999491
                            ],
                            [
                                -160.225701999687,
                                55.3467170000786
                            ],
                            [
                                -160.238966000353,
                                55.3402749998014
                            ],
                            [
                                -160.219300000407,
                                55.3224629999063
                            ],
                            [
                                -160.21579399982,
                                55.3082800000537
                            ],
                            [
                                -160.221350999727,
                                55.2892279999072
                            ],
                            [
                                -160.238229000036,
                                55.2725189998592
                            ],
                            [
                                -160.235910999854,
                                55.2683459998333
                            ],
                            [
                                -160.234113000253,
                                55.2568480002396
                            ],
                            [
                                -160.242535999615,
                                55.2410550002055
                            ],
                            [
                                -160.278369000392,
                                55.2050740000024
                            ],
                            [
                                -160.292742000022,
                                55.1980040000938
                            ],
                            [
                                -160.321171999813,
                                55.1934570000403
                            ],
                            [
                                -160.353600000285,
                                55.1936550001461
                            ],
                            [
                                -160.366832000265,
                                55.196475000223
                            ],
                            [
                                -160.367844999869,
                                55.1844240002289
                            ],
                            [
                                -160.376905999814,
                                55.1654200001202
                            ],
                            [
                                -160.393596999627,
                                55.1547000002461
                            ],
                            [
                                -160.411524000422,
                                55.1462750001429
                            ],
                            [
                                -160.417917000381,
                                55.132284000187
                            ],
                            [
                                -160.430885999697,
                                55.1228239999756
                            ],
                            [
                                -160.439368000408,
                                55.1196570001107
                            ],
                            [
                                -160.446922999932,
                                55.108173999866
                            ],
                            [
                                -160.43177500035,
                                55.0946699998239
                            ],
                            [
                                -160.427200999909,
                                55.0761380001315
                            ],
                            [
                                -160.43092599988,
                                55.0648620002339
                            ],
                            [
                                -160.448323000048,
                                55.0460609999431
                            ],
                            [
                                -160.460836999978,
                                55.0358450000314
                            ],
                            [
                                -160.478336000253,
                                55.0294699999512
                            ],
                            [
                                -160.51946900012,
                                55.0251229997985
                            ],
                            [
                                -160.554332000052,
                                55.0308579998126
                            ],
                            [
                                -160.573259999857,
                                55.0369819998775
                            ],
                            [
                                -160.591729999902,
                                55.0527739998755
                            ],
                            [
                                -160.598415999764,
                                55.0640189998856
                            ],
                            [
                                -160.602477000194,
                                55.0831770001993
                            ],
                            [
                                -160.598691000123,
                                55.0940559997662
                            ],
                            [
                                -160.607675999901,
                                55.0983610000386
                            ],
                            [
                                -160.612492000191,
                                55.0954970000128
                            ],
                            [
                                -160.648968999601,
                                55.0962879999961
                            ],
                            [
                                -160.678267999954,
                                55.1073880002265
                            ],
                            [
                                -160.699174000183,
                                55.1075379998059
                            ],
                            [
                                -160.709692000145,
                                55.1120550002528
                            ],
                            [
                                -160.723375000212,
                                55.0998239997781
                            ],
                            [
                                -160.786355999766,
                                55.0708149998707
                            ],
                            [
                                -160.810277000312,
                                55.0684719999213
                            ],
                            [
                                -160.83677499971,
                                55.0681510002247
                            ],
                            [
                                -160.867700000134,
                                55.075558000084
                            ],
                            [
                                -160.886006999973,
                                55.084607000187
                            ],
                            [
                                -160.899675999796,
                                55.0953499998197
                            ],
                            [
                                -160.907729999986,
                                55.1130440002438
                            ],
                            [
                                -160.907211999952,
                                55.1239150000843
                            ],
                            [
                                -160.901463000424,
                                55.1355519999477
                            ],
                            [
                                -160.906145999747,
                                55.1525879999279
                            ],
                            [
                                -160.905063999649,
                                55.1594540002053
                            ],
                            [
                                -160.935588000215,
                                55.1782259998758
                            ],
                            [
                                -160.941477000383,
                                55.1884879997672
                            ],
                            [
                                -160.942696999676,
                                55.2023279999611
                            ],
                            [
                                -160.93305800032,
                                55.2241260000833
                            ],
                            [
                                -160.922362999907,
                                55.2380579999585
                            ],
                            [
                                -160.938640000342,
                                55.2526469999726
                            ],
                            [
                                -160.943945000354,
                                55.2638250000675
                            ],
                            [
                                -160.942503000405,
                                55.2811679997683
                            ],
                            [
                                -160.938640000342,
                                55.29176400021
                            ],
                            [
                                -160.949447999834,
                                55.3112159999698
                            ],
                            [
                                -160.953423999876,
                                55.3253670001883
                            ],
                            [
                                -160.944340000364,
                                55.3415690001089
                            ],
                            [
                                -160.931089999942,
                                55.3565239998994
                            ],
                            [
                                -160.916529999816,
                                55.3646199999994
                            ],
                            [
                                -160.899649000032,
                                55.3786139997527
                            ],
                            [
                                -160.892404999954,
                                55.3806739998213
                            ],
                            [
                                -160.89195899989,
                                55.3884940000454
                            ],
                            [
                                -160.88765599961,
                                55.3966430000009
                            ],
                            [
                                -160.875264999704,
                                55.4080939997539
                            ],
                            [
                                -160.859005999711,
                                55.4198740001252
                            ],
                            [
                                -160.907904999888,
                                55.4203740000684
                            ],
                            [
                                -160.932822000319,
                                55.405652000137
                            ],
                            [
                                -160.995900999782,
                                55.3812430000433
                            ],
                            [
                                -161.112538000252,
                                55.3434350001414
                            ],
                            [
                                -161.138803000021,
                                55.337464999833
                            ],
                            [
                                -161.148275999695,
                                55.3269150000292
                            ],
                            [
                                -161.153738000066,
                                55.3237870001017
                            ],
                            [
                                -161.192988999769,
                                55.308143999853
                            ],
                            [
                                -161.21978699964,
                                55.3027780001145
                            ],
                            [
                                -161.226135000291,
                                55.2994670002334
                            ],
                            [
                                -161.239516000235,
                                55.2984580000315
                            ],
                            [
                                -161.238708999597,
                                55.2960469999772
                            ],
                            [
                                -161.21966500034,
                                55.2941419997551
                            ],
                            [
                                -161.185609999971,
                                55.2857610000849
                            ],
                            [
                                -161.172814999834,
                                55.2953809998878
                            ],
                            [
                                -161.128589999657,
                                55.3133890001953
                            ],
                            [
                                -161.104206000228,
                                55.3167650002249
                            ],
                            [
                                -161.089989000233,
                                55.3166479999592
                            ],
                            [
                                -161.068996999791,
                                55.3123909999993
                            ],
                            [
                                -161.049469999761,
                                55.3050270001727
                            ],
                            [
                                -161.031101999823,
                                55.2947429997737
                            ],
                            [
                                -161.019724000418,
                                55.2846640001846
                            ],
                            [
                                -161.015972999609,
                                55.2767669997504
                            ],
                            [
                                -161.012924999681,
                                55.2629950002437
                            ],
                            [
                                -161.016039999557,
                                55.2514199998328
                            ],
                            [
                                -161.026534999953,
                                55.2391059998888
                            ],
                            [
                                -161.039624999643,
                                55.2294620000292
                            ],
                            [
                                -161.058630000339,
                                55.2201249997845
                            ],
                            [
                                -161.080382999591,
                                55.2143640002511
                            ],
                            [
                                -161.106591000358,
                                55.2122899998329
                            ],
                            [
                                -161.131671000096,
                                55.2139380002072
                            ],
                            [
                                -161.155504999705,
                                55.2224619999443
                            ],
                            [
                                -161.167868000022,
                                55.2109160002109
                            ],
                            [
                                -161.181356999921,
                                55.204231000189
                            ],
                            [
                                -161.204977999992,
                                55.198317999749
                            ],
                            [
                                -161.234306999584,
                                55.1937919997701
                            ],
                            [
                                -161.248507999685,
                                55.1932580000791
                            ],
                            [
                                -161.253166999616,
                                55.186711000234
                            ],
                            [
                                -161.244434999559,
                                55.1730479997462
                            ],
                            [
                                -161.243211999893,
                                55.163504000239
                            ],
                            [
                                -161.251198000311,
                                55.1464600000509
                            ],
                            [
                                -161.246500999847,
                                55.1269749999166
                            ],
                            [
                                -161.25467800006,
                                55.113403000201
                            ],
                            [
                                -161.272255000153,
                                55.1000890001072
                            ],
                            [
                                -161.291053000261,
                                55.0920320002317
                            ],
                            [
                                -161.313572999968,
                                55.0866210002519
                            ],
                            [
                                -161.345737999776,
                                55.0852400000172
                            ],
                            [
                                -161.375387999758,
                                55.0891569998638
                            ],
                            [
                                -161.387699000197,
                                55.0785019998079
                            ],
                            [
                                -161.398715000102,
                                55.0720400001479
                            ],
                            [
                                -161.417725999747,
                                55.0668210002137
                            ],
                            [
                                -161.41758500018,
                                55.0620560002192
                            ],
                            [
                                -161.38992299977,
                                55.0545530002395
                            ],
                            [
                                -161.373718000204,
                                55.0459559997494
                            ],
                            [
                                -161.361235000236,
                                55.0338249999598
                            ],
                            [
                                -161.353146999711,
                                55.0195960000818
                            ],
                            [
                                -161.352596000065,
                                55.0099829999929
                            ],
                            [
                                -161.359522000126,
                                54.9914509999104
                            ],
                            [
                                -161.384881000422,
                                54.9739640000152
                            ],
                            [
                                -161.394097000178,
                                54.9684849998169
                            ],
                            [
                                -161.416985999955,
                                54.9631510000681
                            ],
                            [
                                -161.433250999796,
                                54.962274000095
                            ],
                            [
                                -161.440776999906,
                                54.9637539999985
                            ],
                            [
                                -161.451318000333,
                                54.9549709997908
                            ],
                            [
                                -161.461175000147,
                                54.9510949997476
                            ],
                            [
                                -161.470086000305,
                                54.9497290000793
                            ],
                            [
                                -161.493005000219,
                                54.9545429999394
                            ],
                            [
                                -161.525872000184,
                                54.9515890001567
                            ],
                            [
                                -161.553460999901,
                                54.9544570001641
                            ],
                            [
                                -161.56756099972,
                                54.9574930002503
                            ],
                            [
                                -161.577235000134,
                                54.9618410000273
                            ],
                            [
                                -161.584579999596,
                                54.9679490000231
                            ],
                            [
                                -161.591365999915,
                                54.9636720002549
                            ],
                            [
                                -161.627772999903,
                                54.9574909999846
                            ],
                            [
                                -161.659480999776,
                                54.9563829999583
                            ],
                            [
                                -161.672280999937,
                                54.9592459998838
                            ],
                            [
                                -161.666928000244,
                                54.9429249998466
                            ],
                            [
                                -161.666681000372,
                                54.9281159997786
                            ],
                            [
                                -161.675073999597,
                                54.9183880000819
                            ],
                            [
                                -161.688072999949,
                                54.9075689998007
                            ],
                            [
                                -161.717276999867,
                                54.895743999888
                            ],
                            [
                                -161.710014000421,
                                54.8915919999393
                            ],
                            [
                                -161.667594000416,
                                54.8828649999799
                            ],
                            [
                                -161.652403000278,
                                54.8750390000805
                            ],
                            [
                                -161.641041999693,
                                54.8636939997596
                            ],
                            [
                                -161.632800999905,
                                54.8503350000425
                            ],
                            [
                                -161.630071999819,
                                54.8403459998482
                            ],
                            [
                                -161.61571000006,
                                54.8389209999539
                            ],
                            [
                                -161.597674000382,
                                54.8291550002518
                            ],
                            [
                                -161.576738000016,
                                54.8142100002229
                            ],
                            [
                                -161.56474399977,
                                54.7971969998821
                            ],
                            [
                                -161.566329999659,
                                54.7847529999149
                            ],
                            [
                                -161.576552000244,
                                54.7607049999846
                            ],
                            [
                                -161.590313999953,
                                54.7498470001448
                            ],
                            [
                                -161.604931999806,
                                54.7424240001586
                            ],
                            [
                                -161.639844000142,
                                54.7361590000281
                            ],
                            [
                                -161.672803000169,
                                54.737812999788
                            ],
                            [
                                -161.691876999563,
                                54.7433510000459
                            ],
                            [
                                -161.698366999606,
                                54.7474419997973
                            ],
                            [
                                -161.702741000031,
                                54.7429619998272
                            ],
                            [
                                -161.710186999775,
                                54.7391539997474
                            ],
                            [
                                -161.753674999635,
                                54.727586999888
                            ],
                            [
                                -161.76576699979,
                                54.7277399998873
                            ],
                            [
                                -161.765057999961,
                                54.7244589997484
                            ],
                            [
                                -161.78272799994,
                                54.7067580002532
                            ],
                            [
                                -161.809370000176,
                                54.6929249999007
                            ],
                            [
                                -161.841016000125,
                                54.6845160001131
                            ],
                            [
                                -161.861951999593,
                                54.6898179997709
                            ],
                            [
                                -161.874617000034,
                                54.686461000169
                            ],
                            [
                                -161.889112999688,
                                54.6869489998242
                            ],
                            [
                                -161.935836999797,
                                54.695241000017
                            ],
                            [
                                -161.957047999625,
                                54.7082489999727
                            ],
                            [
                                -161.964669999995,
                                54.7169349998119
                            ],
                            [
                                -161.966844000237,
                                54.7148019998781
                            ],
                            [
                                -161.983293000201,
                                54.7120929998816
                            ],
                            [
                                -162.011859000434,
                                54.7184169997998
                            ],
                            [
                                -162.013597999586,
                                54.7159759997703
                            ],
                            [
                                -162.045581999644,
                                54.7056839999857
                            ],
                            [
                                -162.056571999609,
                                54.7045320000744
                            ],
                            [
                                -162.051136000076,
                                54.7016770002001
                            ],
                            [
                                -162.033251999837,
                                54.6810560000852
                            ],
                            [
                                -162.02852000011,
                                54.6591200000844
                            ],
                            [
                                -162.034653999602,
                                54.6447770000154
                            ],
                            [
                                -162.0480210002,
                                54.6319140001907
                            ],
                            [
                                -162.062882999552,
                                54.6251840001175
                            ],
                            [
                                -162.07982000031,
                                54.6212780002242
                            ],
                            [
                                -162.104845999622,
                                54.6182859998379
                            ],
                            [
                                -162.161090999985,
                                54.6265090002121
                            ],
                            [
                                -162.187276000286,
                                54.6395789999672
                            ],
                            [
                                -162.193743999689,
                                54.6363669997421
                            ],
                            [
                                -162.205921000234,
                                54.6349700001121
                            ],
                            [
                                -162.231402999655,
                                54.6368160002453
                            ],
                            [
                                -162.240472999822,
                                54.6405280002251
                            ],
                            [
                                -162.239430000081,
                                54.6387080000125
                            ],
                            [
                                -162.24229700026,
                                54.6166660001892
                            ],
                            [
                                -162.252734000032,
                                54.6033679998356
                            ],
                            [
                                -162.279404999682,
                                54.5823480002561
                            ],
                            [
                                -162.309282000343,
                                54.575122000105
                            ],
                            [
                                -162.321869999893,
                                54.5758260002517
                            ],
                            [
                                -162.317308000045,
                                54.5724289998807
                            ],
                            [
                                -162.315110000412,
                                54.5457190001824
                            ],
                            [
                                -162.321792000075,
                                54.5319320000845
                            ],
                            [
                                -162.337814000241,
                                54.5178029997881
                            ],
                            [
                                -162.365034999712,
                                54.5076930000169
                            ],
                            [
                                -162.391921000346,
                                54.5048850001668
                            ],
                            [
                                -162.428511999559,
                                54.5076690000825
                            ],
                            [
                                -162.442765000437,
                                54.5122080002513
                            ],
                            [
                                -162.465135000355,
                                54.5248500000905
                            ],
                            [
                                -162.479815000132,
                                54.5409320001002
                            ],
                            [
                                -162.48375299964,
                                54.5585029998318
                            ],
                            [
                                -162.478351000442,
                                54.5756159997455
                            ],
                            [
                                -162.464172999907,
                                54.5875240001452
                            ],
                            [
                                -162.477527999912,
                                54.5952159999818
                            ],
                            [
                                -162.487905000307,
                                54.6102460002099
                            ],
                            [
                                -162.490348000163,
                                54.6223769998338
                            ],
                            [
                                -162.48363499964,
                                54.6400620000595
                            ],
                            [
                                -162.468924999726,
                                54.6586160001526
                            ],
                            [
                                -162.459877000199,
                                54.6665240000459
                            ],
                            [
                                -162.477226999613,
                                54.6702590001383
                            ],
                            [
                                -162.507013000037,
                                54.6827009997551
                            ],
                            [
                                -162.526239999592,
                                54.6946869998634
                            ],
                            [
                                -162.539363999618,
                                54.7072070001162
                            ],
                            [
                                -162.549648999952,
                                54.7253729999361
                            ],
                            [
                                -162.554931000398,
                                54.7493439997949
                            ],
                            [
                                -162.546966999721,
                                54.764565000115
                            ],
                            [
                                -162.533142000262,
                                54.7766119997547
                            ],
                            [
                                -162.51272599958,
                                54.7853710000592
                            ],
                            [
                                -162.497376000331,
                                54.787178999868
                            ],
                            [
                                -162.483084999819,
                                54.7927239998625
                            ],
                            [
                                -162.506746000073,
                                54.8058560001346
                            ],
                            [
                                -162.512605999929,
                                54.8114869997676
                            ],
                            [
                                -162.538454999772,
                                54.8109819999632
                            ],
                            [
                                -162.56937000015,
                                54.8151320001606
                            ],
                            [
                                -162.599980000032,
                                54.8268120000511
                            ],
                            [
                                -162.618932999951,
                                54.8452280000447
                            ],
                            [
                                -162.618537999941,
                                54.8584879998003
                            ],
                            [
                                -162.614602000082,
                                54.8778980000673
                            ],
                            [
                                -162.606424000044,
                                54.8902050001707
                            ],
                            [
                                -162.578893999878,
                                54.9085229999
                            ],
                            [
                                -162.591380000219,
                                54.9069449999036
                            ],
                            [
                                -162.61332300034,
                                54.9134359999535
                            ],
                            [
                                -162.627818999994,
                                54.9211499998694
                            ],
                            [
                                -162.640790999684,
                                54.9300609998323
                            ],
                            [
                                -162.642996999713,
                                54.9333219998786
                            ],
                            [
                                -162.645581000035,
                                54.9271690000265
                            ],
                            [
                                -162.65110500033,
                                54.9221570002296
                            ],
                            [
                                -162.674054000382,
                                54.9133889999909
                            ],
                            [
                                -162.693860999896,
                                54.9081469997615
                            ],
                            [
                                -162.706830999935,
                                54.9091620000195
                            ],
                            [
                                -162.717328999806,
                                54.9019970001269
                            ],
                            [
                                -162.732587999716,
                                54.8957630000857
                            ],
                            [
                                -162.761885000419,
                                54.8897419999615
                            ],
                            [
                                -162.783338999921,
                                54.8878039998971
                            ],
                            [
                                -162.790797000259,
                                54.8888160001122
                            ],
                            [
                                -162.791455000035,
                                54.878342999855
                            ],
                            [
                                -162.804241999776,
                                54.8663719998688
                            ],
                            [
                                -162.822063000267,
                                54.8565899998741
                            ],
                            [
                                -162.846813000292,
                                54.8484270001984
                            ],
                            [
                                -162.8939030001,
                                54.8442379998991
                            ],
                            [
                                -162.908917999612,
                                54.8489529999165
                            ],
                            [
                                -162.930740000258,
                                54.8591079998151
                            ],
                            [
                                -162.967800999824,
                                54.8825160002172
                            ],
                            [
                                -163.063199999805,
                                54.8795180001826
                            ],
                            [
                                -163.062882999612,
                                54.8696010001624
                            ],
                            [
                                -163.068955999904,
                                54.8617190000561
                            ],
                            [
                                -163.07939199985,
                                54.8538380001989
                            ],
                            [
                                -163.097030999867,
                                54.8468710000837
                            ],
                            [
                                -163.110092000144,
                                54.8461919997854
                            ],
                            [
                                -163.120528999915,
                                54.8355030002314
                            ],
                            [
                                -163.13200299958,
                                54.830131999981
                            ],
                            [
                                -163.137298000444,
                                54.8298149998069
                            ],
                            [
                                -163.141738000093,
                                54.820251000146
                            ],
                            [
                                -163.134811000207,
                                54.8188159997786
                            ],
                            [
                                -163.127198999883,
                                54.8127130000318
                            ],
                            [
                                -163.122237999828,
                                54.8140160001781
                            ],
                            [
                                -163.115310999942,
                                54.8132150001665
                            ],
                            [
                                -163.10493500027,
                                54.8093849998209
                            ],
                            [
                                -163.091904000131,
                                54.7994979999352
                            ],
                            [
                                -163.070847000289,
                                54.7887390000921
                            ],
                            [
                                -163.057799000432,
                                54.7734119998963
                            ],
                            [
                                -163.047775000213,
                                54.7695820000377
                            ],
                            [
                                -163.028243000161,
                                54.7572220002004
                            ],
                            [
                                -163.012219000345,
                                54.7390959997944
                            ],
                            [
                                -162.984324000306,
                                54.7230599999298
                            ],
                            [
                                -162.968605999914,
                                54.7087019997696
                            ],
                            [
                                -162.964423000183,
                                54.6961760002081
                            ],
                            [
                                -162.962041000426,
                                54.6820610002116
                            ],
                            [
                                -162.951886999613,
                                54.6660170002226
                            ],
                            [
                                -162.954776000432,
                                54.6598150000979
                            ],
                            [
                                -162.969912000318,
                                54.6505830000362
                            ],
                            [
                                -162.986023000173,
                                54.6376899999739
                            ],
                            [
                                -162.999785999707,
                                54.6235310001142
                            ],
                            [
                                -163.0256329999,
                                54.6111550000794
                            ],
                            [
                                -163.059140999923,
                                54.6062030001938
                            ],
                            [
                                -163.109769000103,
                                54.6113140000978
                            ],
                            [
                                -163.126182000082,
                                54.6158159999712
                            ],
                            [
                                -163.14077499982,
                                54.6133200000624
                            ],
                            [
                                -163.193387000273,
                                54.6142200000458
                            ],
                            [
                                -163.225095999971,
                                54.6172870001438
                            ],
                            [
                                -163.306118999948,
                                54.6079399998906
                            ],
                            [
                                -163.347196999564,
                                54.6066810002283
                            ],
                            [
                                -163.413938000148,
                                54.6003779999123
                            ],
                            [
                                -163.427823999706,
                                54.6004009998411
                            ],
                            [
                                -163.435882000094,
                                54.6028129999106
                            ],
                            [
                                -163.453855000022,
                                54.5911380001324
                            ],
                            [
                                -163.466214000141,
                                54.5868729999464
                            ],
                            [
                                -163.501081000272,
                                54.5820110002407
                            ],
                            [
                                -163.504026000268,
                                54.5749320001309
                            ],
                            [
                                -163.516017999966,
                                54.5606650001566
                            ],
                            [
                                -163.526180000277,
                                54.5516619997593
                            ],
                            [
                                -163.552059000257,
                                54.5413460001712
                            ],
                            [
                                -163.564052999605,
                                54.5395919998606
                            ],
                            [
                                -163.593562999844,
                                54.5411399999257
                            ],
                            [
                                -163.629909999558,
                                54.5494630000119
                            ],
                            [
                                -163.673337000217,
                                54.5622559998402
                            ],
                            [
                                -163.712797000157,
                                54.5701910000965
                            ],
                            [
                                -163.75787199973,
                                54.5720290001279
                            ],
                            [
                                -163.952936999985,
                                54.5684400000923
                            ],
                            [
                                -163.98558999969,
                                54.566791999943
                            ],
                            [
                                -164.001776999712,
                                54.5636030001786
                            ],
                            [
                                -164.03185400039,
                                54.5649459998313
                            ],
                            [
                                -164.05617500007,
                                54.5636489998924
                            ],
                            [
                                -164.122671000431,
                                54.556118999852
                            ],
                            [
                                -164.151752000326,
                                54.5490930002499
                            ],
                            [
                                -164.205126000311,
                                54.5325140001649
                            ],
                            [
                                -164.227860000278,
                                54.5236639998698
                            ],
                            [
                                -164.239568000294,
                                54.5137039997879
                            ],
                            [
                                -164.239882999939,
                                54.5102660000493
                            ],
                            [
                                -164.236618000275,
                                54.4999130002506
                            ],
                            [
                                -164.249158999969,
                                54.4780159998669
                            ],
                            [
                                -164.250012999564,
                                54.4679300001866
                            ],
                            [
                                -164.258070000127,
                                54.4562570001167
                            ],
                            [
                                -164.276684000112,
                                54.437206999786
                            ],
                            [
                                -164.31629399984,
                                54.4114269997701
                            ],
                            [
                                -164.346872999759,
                                54.3965649999696
                            ],
                            [
                                -164.386086999652,
                                54.3847309999128
                            ],
                            [
                                -164.395120000008,
                                54.3785360001395
                            ],
                            [
                                -164.414986999796,
                                54.3688229999424
                            ],
                            [
                                -164.457224000401,
                                54.3640010001685
                            ],
                            [
                                -164.487062999631,
                                54.3696019998332
                            ],
                            [
                                -164.492776999897,
                                54.3660679999248
                            ],
                            [
                                -164.502206999913,
                                54.3632219997599
                            ],
                            [
                                -164.564496000254,
                                54.3520049998677
                            ],
                            [
                                -164.581524000351,
                                54.3454440000756
                            ],
                            [
                                -164.617108999633,
                                54.3388739997674
                            ],
                            [
                                -164.663344000021,
                                54.3375379997655
                            ],
                            [
                                -164.696487999996,
                                54.3411989999681
                            ],
                            [
                                -164.705969000066,
                                54.3441970001688
                            ],
                            [
                                -164.723680000053,
                                54.3390010000301
                            ],
                            [
                                -164.740616999913,
                                54.3372610001872
                            ],
                            [
                                -164.776445999593,
                                54.3390840002207
                            ],
                            [
                                -164.805378000422,
                                54.3455369999981
                            ],
                            [
                                -164.858327000261,
                                54.3621159999566
                            ],
                            [
                                -164.8712970003,
                                54.3640449998697
                            ],
                            [
                                -164.901297000086,
                                54.3746510001206
                            ],
                            [
                                -164.924888000021,
                                54.3916569999331
                            ],
                            [
                                -164.961906999754,
                                54.4266620002103
                            ],
                            [
                                -164.972577000053,
                                54.4408949998779
                            ],
                            [
                                -164.982812999983,
                                54.4446209998289
                            ],
                            [
                                -164.995629999862,
                                54.4573089999311
                            ],
                            [
                                -165.008266999816,
                                54.4913759997938
                            ],
                            [
                                -165.016049000019,
                                54.5013710001852
                            ],
                            [
                                -165.026975000411,
                                54.5207729998558
                            ],
                            [
                                -165.031126000354,
                                54.5531000000353
                            ],
                            [
                                -165.03677199995,
                                54.5618890001434
                            ],
                            [
                                -165.036162999679,
                                54.5765980000564
                            ],
                            [
                                -165.019621999654,
                                54.6067040002387
                            ],
                            [
                                -164.999482000055,
                                54.6262489999979
                            ],
                            [
                                -164.903045000357,
                                54.6664649999645
                            ],
                            [
                                -164.87686099988,
                                54.675430000036
                            ],
                            [
                                -164.863675999755,
                                54.676116000231
                            ],
                            [
                                -164.835172000344,
                                54.6873410001498
                            ],
                            [
                                -164.808895999806,
                                54.69116399975
                            ],
                            [
                                -164.78278,
                                54.691701999915
                            ],
                            [
                                -164.756311999841,
                                54.7173119997988
                            ],
                            [
                                -164.744685000016,
                                54.7305959998371
                            ],
                            [
                                -164.734920000264,
                                54.7450239998611
                            ],
                            [
                                -164.706080000394,
                                54.773484000034
                            ],
                            [
                                -164.703455999889,
                                54.7880039998395
                            ],
                            [
                                -164.698511999552,
                                54.8015389998437
                            ],
                            [
                                -164.683252999642,
                                54.8166769998013
                            ],
                            [
                                -164.670680000161,
                                54.8242459999887
                            ],
                            [
                                -164.651179000071,
                                54.844199000186
                            ],
                            [
                                -164.64403899975,
                                54.8854150000303
                            ],
                            [
                                -164.637538999661,
                                54.9007590002128
                            ],
                            [
                                -164.627743999772,
                                54.9115330000328
                            ],
                            [
                                -164.614254999873,
                                54.9238770002065
                            ],
                            [
                                -164.601254999697,
                                54.9306380001482
                            ],
                            [
                                -164.582480999878,
                                54.9345199998864
                            ],
                            [
                                -164.572171000328,
                                54.943907000136
                            ],
                            [
                                -164.552823000398,
                                54.9504539998453
                            ],
                            [
                                -164.521181999573,
                                54.9691240001712
                            ],
                            [
                                -164.475249000208,
                                54.9762669999304
                            ],
                            [
                                -164.43878100012,
                                54.9795950000175
                            ],
                            [
                                -164.418151000076,
                                54.9795579999775
                            ],
                            [
                                -164.38305600034,
                                54.9743390000186
                            ],
                            [
                                -164.360960999882,
                                54.9690980001115
                            ],
                            [
                                -164.307524999972,
                                54.9514579999203
                            ],
                            [
                                -164.297882000419,
                                54.9526399998185
                            ],
                            [
                                -164.267762999909,
                                54.9641830002117
                            ],
                            [
                                -164.254976999992,
                                54.9707370001975
                            ],
                            [
                                -164.226565999569,
                                54.990328999852
                            ],
                            [
                                -164.213474000229,
                                54.9949590000048
                            ],
                            [
                                -164.204086999871,
                                55.0006250002474
                            ],
                            [
                                -164.157870999749,
                                55.0156540001405
                            ],
                            [
                                -164.109683999775,
                                55.0181560001226
                            ],
                            [
                                -164.085594000073,
                                55.0156540001405
                            ],
                            [
                                -164.047447000211,
                                55.020656999909
                            ],
                            [
                                -164.015325999885,
                                55.0369210002526
                            ],
                            [
                                -164.003150999889,
                                55.0468549998632
                            ],
                            [
                                -163.950176000112,
                                55.0788120001877
                            ],
                            [
                                -163.912702000093,
                                55.0905840001855
                            ],
                            [
                                -163.887799999732,
                                55.0913240000196
                            ],
                            [
                                -163.858656000088,
                                55.0889830000382
                            ],
                            [
                                -163.823012000356,
                                55.1010080002378
                            ],
                            [
                                -163.803908999751,
                                55.1057919999678
                            ],
                            [
                                -163.770298999621,
                                55.1088259999519
                            ],
                            [
                                -163.717424000301,
                                55.09965199998
                            ],
                            [
                                -163.666090999591,
                                55.094549000212
                            ],
                            [
                                -163.630692000081,
                                55.0932290000969
                            ],
                            [
                                -163.56794699963,
                                55.1001580000152
                            ],
                            [
                                -163.532211999662,
                                55.111306000222
                            ],
                            [
                                -163.458941999576,
                                55.1281360000013
                            ],
                            [
                                -163.356378000058,
                                55.1656839999177
                            ],
                            [
                                -163.290680000113,
                                55.1933969998295
                            ],
                            [
                                -163.207522000252,
                                55.2196709998637
                            ],
                            [
                                -163.141331000387,
                                55.2319610002408
                            ],
                            [
                                -163.097875000315,
                                55.2616549998251
                            ],
                            [
                                -162.982796999969,
                                55.3356169997633
                            ],
                            [
                                -162.958139999829,
                                55.3541499999679
                            ],
                            [
                                -162.953837000448,
                                55.3589719997978
                            ],
                            [
                                -162.949868999904,
                                55.3604219998255
                            ],
                            [
                                -162.93516099964,
                                55.3820510002319
                            ],
                            [
                                -162.922772000282,
                                55.3885749997887
                            ],
                            [
                                -162.905163000402,
                                55.3947699998536
                            ],
                            [
                                -162.889568999858,
                                55.4055440001655
                            ],
                            [
                                -162.869975999706,
                                55.4152560001321
                            ],
                            [
                                -162.830182999681,
                                55.4294250002224
                            ],
                            [
                                -162.758405999597,
                                55.4514530000739
                            ],
                            [
                                -162.726973000083,
                                55.4588700000276
                            ],
                            [
                                -162.681562999876,
                                55.4631809998676
                            ],
                            [
                                -162.673810999809,
                                55.4675529997593
                            ],
                            [
                                -162.65791300039,
                                55.4811500000231
                            ],
                            [
                                -162.645035000412,
                                55.4884969999174
                            ],
                            [
                                -162.608353000064,
                                55.4977750002311
                            ],
                            [
                                -162.570878000221,
                                55.5158189999289
                            ],
                            [
                                -162.547227999837,
                                55.5287449997944
                            ],
                            [
                                -162.500231999741,
                                55.572851999955
                            ],
                            [
                                -162.470417999728,
                                55.589981000008
                            ],
                            [
                                -162.415091000331,
                                55.6266110002353
                            ],
                            [
                                -162.373955000091,
                                55.6590450000039
                            ],
                            [
                                -162.360528000116,
                                55.6723439999426
                            ],
                            [
                                -162.320490999693,
                                55.7047159997531
                            ],
                            [
                                -162.289791000298,
                                55.7248660002258
                            ],
                            [
                                -162.231381999739,
                                55.7487630000676
                            ],
                            [
                                -162.209560999816,
                                55.7598870001363
                            ],
                            [
                                -162.172270000096,
                                55.7735299999566
                            ],
                            [
                                -162.161223000229,
                                55.7854160002506
                            ],
                            [
                                -162.134185000158,
                                55.8090610000422
                            ],
                            [
                                -162.12024000015,
                                55.8150340001933
                            ],
                            [
                                -162.103638000026,
                                55.8194759997523
                            ],
                            [
                                -162.080016999954,
                                55.823709999895
                            ],
                            [
                                -162.060822000186,
                                55.8251449998325
                            ],
                            [
                                -162.043579000004,
                                55.8320350000145
                            ],
                            [
                                -162.037996000157,
                                55.8410829998082
                            ],
                            [
                                -162.03286900042,
                                55.8442580002266
                            ],
                            [
                                -162.011353999921,
                                55.8511469998726
                            ],
                            [
                                -162.00224600012,
                                55.8487499997859
                            ],
                            [
                                -161.981019000399,
                                55.8594189998916
                            ],
                            [
                                -161.906192999663,
                                55.9122010000575
                            ],
                            [
                                -161.858250999911,
                                55.9325039997549
                            ],
                            [
                                -161.825749999644,
                                55.942654999902
                            ],
                            [
                                -161.786840000424,
                                55.9465060000957
                            ],
                            [
                                -161.729497999894,
                                55.9548679999091
                            ],
                            [
                                -161.649907999645,
                                55.9733559997925
                            ],
                            [
                                -161.600409000317,
                                55.9867160001334
                            ],
                            [
                                -161.527258999881,
                                55.9954990001923
                            ],
                            [
                                -161.512230000125,
                                56.0011040000237
                            ],
                            [
                                -161.479846999859,
                                56.0035960001028
                            ],
                            [
                                -161.428658999811,
                                56.0115739999018
                            ],
                            [
                                -161.377470999764,
                                56.0235390000806
                            ],
                            [
                                -161.262296000232,
                                56.0554450000623
                            ],
                            [
                                -161.211108000185,
                                56.0674099997866
                            ],
                            [
                                -161.159915000115,
                                56.0713990001407
                            ],
                            [
                                -161.044741000408,
                                56.0714020000353
                            ],
                            [
                                -160.929571999825,
                                56.079379999951
                            ],
                            [
                                -160.865585000341,
                                56.079381000043
                            ],
                            [
                                -160.750409000086,
                                56.0714070000262
                            ],
                            [
                                -160.654425999949,
                                56.0714090001226
                            ],
                            [
                                -160.616034999689,
                                56.067421000203
                            ],
                            [
                                -160.584043000133,
                                56.0793860000014
                            ],
                            [
                                -160.558450000384,
                                56.0993269998471
                            ],
                            [
                                -160.545655000246,
                                56.1152790002175
                            ],
                            [
                                -160.532860999934,
                                56.1551600002326
                            ],
                            [
                                -160.520065999796,
                                56.179088000156
                            ],
                            [
                                -160.51366899964,
                                56.2030170001029
                            ],
                            [
                                -160.502394000167,
                                56.2158400001333
                            ],
                            [
                                -160.483832999885,
                                56.2295369998109
                            ],
                            [
                                -160.478737000111,
                                56.2358400002034
                            ],
                            [
                                -160.465522999674,
                                56.2550279999752
                            ],
                            [
                                -160.439432999807,
                                56.2988139999434
                            ],
                            [
                                -160.413157000167,
                                56.3217340000016
                            ],
                            [
                                -160.386791000115,
                                56.3347730000213
                            ],
                            [
                                -160.330149000093,
                                56.3557400000999
                            ],
                            [
                                -160.305584000015,
                                56.3692140001843
                            ],
                            [
                                -160.261364999686,
                                56.408070999948
                            ],
                            [
                                -160.225416000355,
                                56.433600000073
                            ],
                            [
                                -160.164198000242,
                                56.4549560002088
                            ],
                            [
                                -160.111526000413,
                                56.4655990000604
                            ],
                            [
                                -160.069137000371,
                                56.4802789997572
                            ],
                            [
                                -160.032089000325,
                                56.496950000099
                            ],
                            [
                                -159.994063999763,
                                56.5164059999601
                            ],
                            [
                                -159.960648999627,
                                56.5434519997595
                            ],
                            [
                                -159.952660999559,
                                56.5462940002411
                            ],
                            [
                                -159.928941999578,
                                56.5644420000024
                            ],
                            [
                                -159.905138999931,
                                56.578175000004
                            ],
                            [
                                -159.870166000394,
                                56.5908559999573
                            ],
                            [
                                -159.840045000234,
                                56.5991949999353
                            ],
                            [
                                -159.807911000388,
                                56.6103269999997
                            ],
                            [
                                -159.729603000254,
                                56.6320120001101
                            ],
                            [
                                -159.67583199971,
                                56.6449899999147
                            ],
                            [
                                -159.637135000026,
                                56.6585100000267
                            ],
                            [
                                -159.580984000274,
                                56.6729230001763
                            ],
                            [
                                -159.502281000129,
                                56.7028020002262
                            ],
                            [
                                -159.425743999831,
                                56.7401869997859
                            ],
                            [
                                -159.376365000154,
                                56.7539739999825
                            ],
                            [
                                -159.351220000118,
                                56.7577210002283
                            ],
                            [
                                -159.288842999913,
                                56.7782000000532
                            ],
                            [
                                -159.234950000068,
                                56.793849000024
                            ],
                            [
                                -159.173914000428,
                                56.8144500001481
                            ],
                            [
                                -159.081570000049,
                                56.8528889999236
                            ],
                            [
                                -159.04400399997,
                                56.8762889998469
                            ],
                            [
                                -158.981567000389,
                                56.9111260001099
                            ],
                            [
                                -158.935120000289,
                                56.9300779998864
                            ],
                            [
                                -158.919404000444,
                                56.9382190000492
                            ],
                            [
                                -158.893400999718,
                                56.9451080000849
                            ],
                            [
                                -158.866283000179,
                                56.9975630001525
                            ],
                            [
                                -158.667663999657,
                                57.2956529999795
                            ],
                            [
                                -158.557743000083,
                                57.4935970000019
                            ],
                            [
                                -158.529603999647,
                                57.5351390000779
                            ],
                            [
                                -158.510844999897,
                                57.5844699998778
                            ],
                            [
                                -158.487396000252,
                                57.615626999988
                            ],
                            [
                                -158.473325999673,
                                57.6597649999855
                            ],
                            [
                                -158.454566999922,
                                57.6935180001279
                            ],
                            [
                                -158.435807000347,
                                57.7480419997843
                            ],
                            [
                                -158.281146999863,
                                58.0442490001279
                            ],
                            [
                                -158.207957999968,
                                58.1922390002204
                            ],
                            [
                                -158.192335999834,
                                58.2363210001149
                            ],
                            [
                                -158.168903000084,
                                58.2730559999264
                            ],
                            [
                                -158.164998000187,
                                58.2867000000545
                            ],
                            [
                                -158.159139999981,
                                58.299295000169
                            ],
                            [
                                -158.141565000437,
                                58.3213359999604
                            ],
                            [
                                -158.137659999642,
                                58.3328809998029
                            ],
                            [
                                -158.089924999579,
                                58.4241930000241
                            ],
                            [
                                -158.062812000064,
                                58.4855180001038
                            ],
                            [
                                -158.147874999656,
                                58.5437800001305
                            ],
                            [
                                -158.189415999952,
                                58.5404899998813
                            ],
                            [
                                -158.213032000001,
                                58.5426040001474
                            ],
                            [
                                -158.605234999573,
                                58.4555919998766
                            ],
                            [
                                -158.604667000209,
                                58.4463319999718
                            ],
                            [
                                -158.614354999968,
                                58.4337069999606
                            ],
                            [
                                -158.624733000189,
                                58.4220330000847
                            ],
                            [
                                -158.638213999691,
                                58.4132469997683
                            ],
                            [
                                -158.654488999578,
                                58.4072880001957
                            ],
                            [
                                -158.671838000065,
                                58.4034250001652
                            ],
                            [
                                -158.689543000204,
                                58.4016820001821
                            ],
                            [
                                -158.702241000257,
                                58.3883799999339
                            ],
                            [
                                -158.720112000078,
                                58.3760620002251
                            ],
                            [
                                -158.744724000011,
                                58.3637250001222
                            ],
                            [
                                -158.772682999624,
                                58.3549280001661
                            ],
                            [
                                -158.81032900007,
                                58.3471510001947
                            ],
                            [
                                -158.839794000104,
                                58.3436640000208
                            ],
                            [
                                -158.87667399992,
                                58.3419259998469
                            ],
                            [
                                -158.911680999792,
                                58.3429089999026
                            ],
                            [
                                -158.941252000131,
                                58.3470119999435
                            ],
                            [
                                -158.965513000436,
                                58.3528049998618
                            ],
                            [
                                -158.970640000172,
                                58.348524999988
                            ],
                            [
                                -158.991905000426,
                                58.3406029998784
                            ],
                            [
                                -159.018721999666,
                                58.3341399998584
                            ],
                            [
                                -159.04767200004,
                                58.3343359998843
                            ],
                            [
                                -159.078850000184,
                                58.3392569998316
                            ],
                            [
                                -159.107875999827,
                                58.3475409998479
                            ],
                            [
                                -159.133035999931,
                                58.3600290000284
                            ],
                            [
                                -159.148387999728,
                                58.3703020000893
                            ],
                            [
                                -159.159841000374,
                                58.3851939999202
                            ],
                            [
                                -159.162376000292,
                                58.3952309999765
                            ],
                            [
                                -159.162179999575,
                                58.4035620002067
                            ],
                            [
                                -159.151387999976,
                                58.4157729998762
                            ],
                            [
                                -159.404727999998,
                                58.6659569999081
                            ],
                            [
                                -159.54468500024,
                                58.7784189997693
                            ],
                            [
                                -159.552965000386,
                                58.7805159999101
                            ],
                            [
                                -159.580843999633,
                                58.7825499998769
                            ],
                            [
                                -159.611123999802,
                                58.7822239999805
                            ],
                            [
                                -159.646931999565,
                                58.7860790001889
                            ],
                            [
                                -159.675651999785,
                                58.7894829998884
                            ],
                            [
                                -159.693154000433,
                                58.7927990001776
                            ],
                            [
                                -159.701693999971,
                                58.7963970001494
                            ],
                            [
                                -159.71886199981,
                                58.7766670000651
                            ],
                            [
                                -159.748103000436,
                                58.760304999977
                            ],
                            [
                                -159.8117190002,
                                58.735449999983
                            ],
                            [
                                -159.857813999948,
                                58.7220359998834
                            ],
                            [
                                -159.893861000086,
                                58.7150359999126
                            ],
                            [
                                -159.939081000322,
                                58.7179059999283
                            ],
                            [
                                -159.974992000018,
                                58.730744999817
                            ],
                            [
                                -160.00436499999,
                                58.7446119998752
                            ],
                            [
                                -160.014427999668,
                                58.7597860001361
                            ],
                            [
                                -160.028215000391,
                                58.7640719998722
                            ],
                            [
                                -160.038252000129,
                                58.7703739998152
                            ],
                            [
                                -160.052784999593,
                                58.7839689998342
                            ],
                            [
                                -160.065634000157,
                                58.7897069997978
                            ],
                            [
                                -160.089153000122,
                                58.806303000013
                            ],
                            [
                                -160.11301299967,
                                58.7866140000829
                            ],
                            [
                                -160.127145000174,
                                58.7800749998138
                            ],
                            [
                                -160.15076500042,
                                58.7719959997777
                            ],
                            [
                                -160.172238000188,
                                58.7693290002187
                            ],
                            [
                                -160.208762000353,
                                58.7701219999085
                            ],
                            [
                                -160.234000000275,
                                58.7739560002215
                            ],
                            [
                                -160.273449000344,
                                58.788731000062
                            ],
                            [
                                -160.29760099997,
                                58.7991499998849
                            ],
                            [
                                -160.310533000375,
                                58.8126259998213
                            ],
                            [
                                -160.326676000017,
                                58.8347509998357
                            ],
                            [
                                -160.33368099972,
                                58.8483140002146
                            ],
                            [
                                -160.326929999561,
                                58.858421000157
                            ],
                            [
                                -160.341252999861,
                                58.8747170001668
                            ],
                            [
                                -160.343087000345,
                                58.8805419998552
                            ],
                            [
                                -160.608715000341,
                                58.8436210001141
                            ],
                            [
                                -160.598090000249,
                                58.8342400000215
                            ],
                            [
                                -160.593499999914,
                                58.8217279997889
                            ],
                            [
                                -160.596404999728,
                                58.8044260000821
                            ],
                            [
                                -160.601627999724,
                                58.7933230000747
                            ],
                            [
                                -160.626581000139,
                                58.7608070001366
                            ],
                            [
                                -160.64979600033,
                                58.7367950001113
                            ],
                            [
                                -160.663183999946,
                                58.719133000025
                            ],
                            [
                                -160.689141999569,
                                58.690263000059
                            ],
                            [
                                -160.700027999777,
                                58.6720739998116
                            ],
                            [
                                -160.746823000032,
                                58.6256860001072
                            ],
                            [
                                -160.787267999986,
                                58.5815699999623
                            ],
                            [
                                -160.789745000177,
                                58.5744239997965
                            ],
                            [
                                -160.789772999766,
                                58.5644180000481
                            ],
                            [
                                -160.793302999744,
                                58.5600969998633
                            ],
                            [
                                -160.80177299986,
                                58.551428000028
                            ],
                            [
                                -160.81841099997,
                                58.5395529999264
                            ],
                            [
                                -160.843097000421,
                                58.5243500002199
                            ],
                            [
                                -160.874149999994,
                                58.5100659999906
                            ],
                            [
                                -160.901812000404,
                                58.5022090002193
                            ],
                            [
                                -160.933435999714,
                                58.4961089999999
                            ],
                            [
                                -160.964827000293,
                                58.4956880000828
                            ],
                            [
                                -161.015596999866,
                                58.503062000195
                            ],
                            [
                                -161.067529999728,
                                58.4898060000027
                            ],
                            [
                                -161.095793999837,
                                58.4900269998715
                            ],
                            [
                                -161.140957999997,
                                58.5033400000837
                            ],
                            [
                                -161.158918000405,
                                58.5123829999133
                            ],
                            [
                                -161.168509999905,
                                58.5249080001596
                            ],
                            [
                                -161.177342000419,
                                58.5425670001842
                            ],
                            [
                                -161.186773000261,
                                58.5732970000096
                            ],
                            [
                                -161.184927999906,
                                58.5875339997695
                            ],
                            [
                                -161.177036999923,
                                58.6070679998468
                            ],
                            [
                                -161.233977999696,
                                58.6434529999993
                            ],
                            [
                                -161.272666000057,
                                58.6277409999316
                            ],
                            [
                                -161.32204399991,
                                58.6116029997852
                            ],
                            [
                                -161.35345700023,
                                58.6035850001764
                            ],
                            [
                                -161.418357999584,
                                58.5959850000895
                            ],
                            [
                                -161.457831999767,
                                58.585965999937
                            ],
                            [
                                -161.461449999608,
                                58.5800859998193
                            ],
                            [
                                -161.468857999718,
                                58.5743480000835
                            ],
                            [
                                -161.489866000054,
                                58.5618949999205
                            ],
                            [
                                -161.521349999622,
                                58.5516430001052
                            ],
                            [
                                -161.571499000127,
                                58.5437549999214
                            ],
                            [
                                -161.572346999873,
                                58.5352399998004
                            ],
                            [
                                -161.586982000343,
                                58.5184189998467
                            ],
                            [
                                -161.59800699957,
                                58.5127549998286
                            ],
                            [
                                -161.617019999764,
                                58.5067740001137
                            ],
                            [
                                -161.640249000198,
                                58.5023260001147
                            ],
                            [
                                -161.658711999672,
                                58.5004370002171
                            ],
                            [
                                -161.676466000216,
                                58.5006410001015
                            ],
                            [
                                -161.704228000185,
                                58.4969109999241
                            ],
                            [
                                -161.716420999725,
                                58.4965030002205
                            ],
                            [
                                -161.727926000249,
                                58.498854000191
                            ],
                            [
                                -161.749240000009,
                                58.4960189997772
                            ],
                            [
                                -161.767157999685,
                                58.4969390002039
                            ],
                            [
                                -161.802172000129,
                                58.5017369997949
                            ],
                            [
                                -161.817568000306,
                                58.5057260001305
                            ],
                            [
                                -161.844634999791,
                                58.5233170001287
                            ],
                            [
                                -161.860918999899,
                                58.5390910000123
                            ],
                            [
                                -161.864294000065,
                                58.5458440000384
                            ],
                            [
                                -161.865052000299,
                                58.5660870000659
                            ],
                            [
                                -161.863644999612,
                                58.5687259998715
                            ],
                            [
                                -161.870716000336,
                                58.5704129999961
                            ],
                            [
                                -161.909045999598,
                                58.5727389999972
                            ],
                            [
                                -161.93017299976,
                                58.5662680000568
                            ],
                            [
                                -161.963592999919,
                                58.5664240000825
                            ],
                            [
                                -161.971539000154,
                                58.5677360001156
                            ],
                            [
                                -161.99861300021,
                                58.5610780001031
                            ],
                            [
                                -162.01736299974,
                                58.5616659998803
                            ],
                            [
                                -162.043076000038,
                                58.5683319998815
                            ],
                            [
                                -162.081765000224,
                                58.5648990002316
                            ],
                            [
                                -162.13221699978,
                                58.571376000115
                            ],
                            [
                                -162.149209999716,
                                58.576108999992
                            ],
                            [
                                -162.171742000017,
                                58.5899440001679
                            ],
                            [
                                -162.198977999556,
                                58.5899820002031
                            ],
                            [
                                -162.230286000295,
                                58.5985509998181
                            ],
                            [
                                -162.246464999922,
                                58.6066239998573
                            ],
                            [
                                -162.270410999684,
                                58.6221259999441
                            ],
                            [
                                -162.277650999564,
                                58.6328839997959
                            ],
                            [
                                -162.280735000376,
                                58.646298000057
                            ],
                            [
                                -162.275340999777,
                                58.6538190000003
                            ],
                            [
                                -162.274001999761,
                                58.6602849998728
                            ],
                            [
                                -162.268984999629,
                                58.6675570000289
                            ],
                            [
                                -162.259754999629,
                                58.6726749997729
                            ],
                            [
                                -162.226275999919,
                                58.6855440001411
                            ],
                            [
                                -162.22376899959,
                                58.6899120001342
                            ],
                            [
                                -162.215422000395,
                                58.6950320001982
                            ],
                            [
                                -162.185119999587,
                                58.706140999772
                            ],
                            [
                                -162.168521999661,
                                58.7102760000421
                            ],
                            [
                                -162.128480999938,
                                58.713071000134
                            ],
                            [
                                -162.091042999905,
                                58.7227349998103
                            ],
                            [
                                -162.073143999597,
                                58.7244090000396
                            ],
                            [
                                -162.03800299983,
                                58.7220959999035
                            ],
                            [
                                -162.020817000448,
                                58.7263289998862
                            ],
                            [
                                -162.001980999806,
                                58.7270960000152
                            ],
                            [
                                -161.96302800003,
                                58.7262330001012
                            ],
                            [
                                -161.949165999862,
                                58.7243290001762
                            ],
                            [
                                -161.950403999596,
                                58.7289359999087
                            ],
                            [
                                -161.948251000168,
                                58.7324700000536
                            ],
                            [
                                -161.933158999765,
                                58.7482719999845
                            ],
                            [
                                -161.922982000284,
                                58.7545610002318
                            ],
                            [
                                -161.897843999921,
                                58.7620429999089
                            ],
                            [
                                -161.89822999971,
                                58.7681390000338
                            ],
                            [
                                -161.894242999798,
                                58.7776590002053
                            ],
                            [
                                -161.889158999719,
                                58.7842999997798
                            ],
                            [
                                -161.863534000182,
                                58.8015629998689
                            ],
                            [
                                -161.851201000002,
                                58.8079830000273
                            ],
                            [
                                -161.85658999968,
                                58.8152109998213
                            ],
                            [
                                -161.858500000332,
                                58.8222070000685
                            ],
                            [
                                -161.857527000013,
                                58.8304339998881
                            ],
                            [
                                -161.88179300034,
                                58.8575039998103
                            ],
                            [
                                -161.887201000285,
                                58.8712800002011
                            ],
                            [
                                -161.888789999648,
                                58.881505000069
                            ],
                            [
                                -161.888106999758,
                                58.8977290001819
                            ],
                            [
                                -161.882509999667,
                                58.930521000098
                            ],
                            [
                                -161.881764999853,
                                58.9441459999735
                            ],
                            [
                                -161.885853999872,
                                58.9537589998478
                            ],
                            [
                                -161.902474000438,
                                58.9776020001
                            ],
                            [
                                -161.927072000128,
                                59.0008010000696
                            ],
                            [
                                -161.953346000118,
                                59.0327029998218
                            ],
                            [
                                -162.00113100041,
                                59.0702260000252
                            ],
                            [
                                -162.007675999806,
                                59.0552860000686
                            ],
                            [
                                -162.016814999569,
                                59.0602359998444
                            ],
                            [
                                -162.046820000276,
                                59.0790830001887
                            ],
                            [
                                -162.07308400022,
                                59.0999840002016
                            ],
                            [
                                -162.102197999727,
                                59.1287519998529
                            ],
                            [
                                -162.16381099985,
                                59.2244610000745
                            ],
                            [
                                -162.171006000423,
                                59.2475679999709
                            ],
                            [
                                -162.166604000409,
                                59.2750750000475
                            ],
                            [
                                -162.132114999672,
                                59.3061989998774
                            ],
                            [
                                -162.111558000321,
                                59.3329290001264
                            ],
                            [
                                -162.082818999835,
                                59.3507270001555
                            ],
                            [
                                -162.064754999671,
                                59.3600319998044
                            ],
                            [
                                -162.061322999603,
                                59.3979580001906
                            ],
                            [
                                -162.053612000443,
                                59.4089900001589
                            ],
                            [
                                -162.014023999558,
                                59.4321340000283
                            ],
                            [
                                -162.001948000194,
                                59.437447000176
                            ],
                            [
                                -161.979281000174,
                                59.4443259999153
                            ],
                            [
                                -161.958537000327,
                                59.4478110001464
                            ],
                            [
                                -161.941382000009,
                                59.4578929999011
                            ],
                            [
                                -161.914449000418,
                                59.4692889998882
                            ],
                            [
                                -161.910131999895,
                                59.4799750001277
                            ],
                            [
                                -161.903405000026,
                                59.4876120001214
                            ],
                            [
                                -161.900096999806,
                                59.4980499999582
                            ],
                            [
                                -161.894780000098,
                                59.5075649997753
                            ],
                            [
                                -161.871266000157,
                                59.5247909998767
                            ],
                            [
                                -162.003056000232,
                                59.6446540002036
                            ],
                            [
                                -162.012698999785,
                                59.6456450000902
                            ],
                            [
                                -162.042605999685,
                                59.6707290000208
                            ],
                            [
                                -162.064876000045,
                                59.7000309999238
                            ],
                            [
                                -162.068324999831,
                                59.7164260001828
                            ],
                            [
                                -162.074890000217,
                                59.7176369998207
                            ],
                            [
                                -162.098244000325,
                                59.7337080001164
                            ],
                            [
                                -162.106856999658,
                                59.7542060000322
                            ],
                            [
                                -162.114991000214,
                                59.7561490000748
                            ],
                            [
                                -162.138505999981,
                                59.7723919998223
                            ],
                            [
                                -162.160654999967,
                                59.7997960000633
                            ],
                            [
                                -162.178329000144,
                                59.8148419998162
                            ],
                            [
                                -162.18665500032,
                                59.8189080001448
                            ],
                            [
                                -162.194433000326,
                                59.81219099986
                            ],
                            [
                                -162.227838000416,
                                59.8064999998033
                            ],
                            [
                                -162.25731799962,
                                59.8061889999155
                            ],
                            [
                                -162.29766200019,
                                59.8117380000554
                            ],
                            [
                                -162.31761900039,
                                59.8124889998075
                            ],
                            [
                                -162.338791999684,
                                59.8098850002171
                            ],
                            [
                                -162.364340999951,
                                59.8108689998104
                            ],
                            [
                                -162.411383000078,
                                59.82325599996
                            ],
                            [
                                -162.441660999698,
                                59.84407800013
                            ],
                            [
                                -162.451976999995,
                                59.8763389998428
                            ],
                            [
                                -162.449847999958,
                                59.8846160001061
                            ],
                            [
                                -162.514534999949,
                                59.8903729999735
                            ],
                            [
                                -162.562638000258,
                                59.8968259997827
                            ],
                            [
                                -162.606410999625,
                                59.888301000147
                            ],
                            [
                                -162.698375000062,
                                59.8918159999023
                            ],
                            [
                                -162.707559000031,
                                59.8801750000692
                            ],
                            [
                                -162.762539999822,
                                59.8574549999092
                            ],
                            [
                                -162.818047999867,
                                59.8402759999361
                            ],
                            [
                                -162.815467999744,
                                59.8277070000957
                            ],
                            [
                                -162.815887000043,
                                59.8128299999936
                            ],
                            [
                                -162.826090000362,
                                59.8006599999355
                            ],
                            [
                                -162.868660999979,
                                59.7803809998463
                            ],
                            [
                                -162.947224000382,
                                59.7682879999652
                            ],
                            [
                                -162.974781000312,
                                59.7728320001253
                            ],
                            [
                                -162.985993000035,
                                59.7694129998476
                            ],
                            [
                                -163.025586000045,
                                59.7675419998723
                            ],
                            [
                                -163.105666999665,
                                59.7782409998351
                            ],
                            [
                                -163.145863000097,
                                59.7956419997995
                            ],
                            [
                                -163.165151999577,
                                59.7912890000603
                            ],
                            [
                                -163.227801999593,
                                59.7858510000439
                            ],
                            [
                                -163.330028999725,
                                59.7696619998554
                            ],
                            [
                                -163.428523000388,
                                59.7608690001547
                            ],
                            [
                                -163.582016000385,
                                59.7516349998683
                            ],
                            [
                                -163.647419999704,
                                59.7436090001696
                            ],
                            [
                                -163.707703000032,
                                59.7411259999463
                            ],
                            [
                                -163.784373000399,
                                59.7405689999512
                            ],
                            [
                                -163.982390000323,
                                59.7614650001188
                            ],
                            [
                                -163.993705999804,
                                59.7637600001837
                            ],
                            [
                                -163.997990999642,
                                59.760451999968
                            ],
                            [
                                -163.999739999737,
                                59.7549650000938
                            ],
                            [
                                -163.983426000391,
                                59.7524329999857
                            ],
                            [
                                -163.966000999736,
                                59.7471180000689
                            ],
                            [
                                -163.952860999817,
                                59.7359159999424
                            ],
                            [
                                -163.920348999679,
                                59.7263160000036
                            ],
                            [
                                -163.896891999639,
                                59.7146419997804
                            ],
                            [
                                -163.891153999982,
                                59.7137950000066
                            ],
                            [
                                -163.880303999759,
                                59.7159649999172
                            ],
                            [
                                -163.847326000364,
                                59.7184510000616
                            ],
                            [
                                -163.813675000226,
                                59.7169420000285
                            ],
                            [
                                -163.787797000071,
                                59.709192000062
                            ],
                            [
                                -163.748901000196,
                                59.6864219998924
                            ],
                            [
                                -163.739949000031,
                                59.6686489999528
                            ],
                            [
                                -163.744628999879,
                                59.654776999987
                            ],
                            [
                                -163.751154000082,
                                59.6464769998887
                            ],
                            [
                                -163.760960999666,
                                59.6397160000771
                            ],
                            [
                                -163.811948999697,
                                59.6208340001266
                            ],
                            [
                                -163.847071000096,
                                59.6179269998816
                            ],
                            [
                                -163.919716999843,
                                59.6404640001469
                            ],
                            [
                                -163.947917000378,
                                59.6358290002027
                            ],
                            [
                                -163.976245000062,
                                59.6343270000092
                            ],
                            [
                                -164.013299999781,
                                59.6377590000085
                            ],
                            [
                                -164.080776000133,
                                59.6705129999493
                            ],
                            [
                                -164.111067999997,
                                59.6940789997753
                            ],
                            [
                                -164.124795000445,
                                59.7162759998462
                            ],
                            [
                                -164.134438999824,
                                59.7174220001465
                            ],
                            [
                                -164.150837999559,
                                59.7223770000869
                            ],
                            [
                                -164.19322900015,
                                59.737295000145
                            ],
                            [
                                -164.23096299956,
                                59.7530620001989
                            ],
                            [
                                -164.274627000045,
                                59.7783109999272
                            ],
                            [
                                -164.3131520002,
                                59.806286000173
                            ],
                            [
                                -164.336675000362,
                                59.8296029998683
                            ],
                            [
                                -164.346544999696,
                                59.8483549999899
                            ],
                            [
                                -164.415695999626,
                                59.884866999818
                            ],
                            [
                                -164.420800999621,
                                59.9069739997908
                            ],
                            [
                                -164.435214000333,
                                59.9267479999869
                            ],
                            [
                                -164.449658000108,
                                59.9533380000274
                            ],
                            [
                                -164.434858999607,
                                59.9689059998433
                            ],
                            [
                                -164.420269000242,
                                59.9781000001422
                            ],
                            [
                                -164.364331999851,
                                59.9992230001739
                            ],
                            [
                                -164.342832000319,
                                60.0029310000678
                            ],
                            [
                                -164.390075000287,
                                60.0108530001615
                            ],
                            [
                                -164.428626000381,
                                60.0278709998166
                            ],
                            [
                                -164.43208199984,
                                60.0261709999428
                            ],
                            [
                                -164.44573499977,
                                60.0305110001872
                            ],
                            [
                                -164.48822599992,
                                60.0549619999631
                            ],
                            [
                                -164.52145000026,
                                60.0919649999194
                            ],
                            [
                                -164.548680999777,
                                60.110627999912
                            ],
                            [
                                -164.57474399988,
                                60.1330090001222
                            ],
                            [
                                -164.597385999785,
                                60.1582379998637
                            ],
                            [
                                -164.624279000092,
                                60.1645939999156
                            ],
                            [
                                -164.658596000401,
                                60.1755330001436
                            ],
                            [
                                -164.688862000326,
                                60.1921739999474
                            ],
                            [
                                -164.711119000267,
                                60.2079390001428
                            ],
                            [
                                -164.74881000002,
                                60.2403130001563
                            ],
                            [
                                -164.803999000223,
                                60.2432349999776
                            ],
                            [
                                -164.851129000213,
                                60.2489899999031
                            ],
                            [
                                -164.895392000025,
                                60.2581539999445
                            ],
                            [
                                -164.943143999806,
                                60.2710219999007
                            ],
                            [
                                -164.971915999904,
                                60.2770190000233
                            ],
                            [
                                -165.001713000199,
                                60.2938209998874
                            ],
                            [
                                -165.098660000433,
                                60.334709000043
                            ],
                            [
                                -165.141446999961,
                                60.3579790001922
                            ],
                            [
                                -165.176786000094,
                                60.3812449999914
                            ],
                            [
                                -165.206371999604,
                                60.4050680001885
                            ],
                            [
                                -165.235410999665,
                                60.425150000118
                            ],
                            [
                                -165.239555999761,
                                60.4432260002204
                            ],
                            [
                                -165.270254000404,
                                60.4417939999398
                            ],
                            [
                                -165.288232000355,
                                60.4426449999537
                            ],
                            [
                                -165.308561999926,
                                60.4480949999145
                            ],
                            [
                                -165.333574999717,
                                60.4592660000608
                            ],
                            [
                                -165.369522000296,
                                60.4580950001164
                            ],
                            [
                                -165.387518999615,
                                60.4595070001152
                            ],
                            [
                                -165.426530000016,
                                60.4743830001865
                            ],
                            [
                                -165.443457999656,
                                60.4846889999378
                            ],
                            [
                                -165.448222000068,
                                60.4949980001871
                            ],
                            [
                                -165.467462000042,
                                60.5027169999091
                            ],
                            [
                                -165.50144899974,
                                60.5261800002055
                            ],
                            [
                                -165.513831000324,
                                60.539637000053
                            ],
                            [
                                -165.520389999964,
                                60.5519659998651
                            ],
                            [
                                -165.518262999577,
                                60.5660159999767
                            ],
                            [
                                -165.504449999813,
                                60.5786750000645
                            ],
                            [
                                -165.479074999623,
                                60.5976490000883
                            ],
                            [
                                -165.479115999631,
                                60.6016620001206
                            ],
                            [
                                -165.475096999932,
                                60.6065519998594
                            ],
                            [
                                -165.464115000362,
                                60.6137369998289
                            ],
                            [
                                -165.445579000195,
                                60.6235229999854
                            ],
                            [
                                -165.410696000171,
                                60.632173000196
                            ],
                            [
                                -165.375742000002,
                                60.6362249999849
                            ],
                            [
                                -165.340191000156,
                                60.6382680002035
                            ],
                            [
                                -165.333246999654,
                                60.6457280000988
                            ],
                            [
                                -165.322793000164,
                                60.6517510001937
                            ],
                            [
                                -165.291369999797,
                                60.6655419998736
                            ],
                            [
                                -165.245901999864,
                                60.675005000025
                            ],
                            [
                                -165.24243200016,
                                60.6833339999114
                            ],
                            [
                                -165.222048000163,
                                60.6953729999785
                            ],
                            [
                                -165.184687000123,
                                60.704541999963
                            ],
                            [
                                -165.164241000202,
                                60.7076750000245
                            ],
                            [
                                -165.160173000099,
                                60.7148530001009
                            ],
                            [
                                -165.146752999798,
                                60.725452999893
                            ],
                            [
                                -165.123368999552,
                                60.736627000069
                            ],
                            [
                                -165.129801999694,
                                60.7403559999069
                            ],
                            [
                                -165.139178000182,
                                60.7501150000633
                            ],
                            [
                                -165.143877000296,
                                60.7587290000021
                            ],
                            [
                                -165.140973000308,
                                60.7661839999858
                            ],
                            [
                                -165.159140000404,
                                60.7765239998635
                            ],
                            [
                                -165.176175000174,
                                60.7963260002191
                            ],
                            [
                                -165.175628999653,
                                60.8072259998154
                            ],
                            [
                                -165.1680239999,
                                60.8184129997828
                            ],
                            [
                                -165.157506999762,
                                60.8292979998592
                            ],
                            [
                                -165.170435999794,
                                60.8399220000515
                            ],
                            [
                                -165.177489999901,
                                60.8479659999396
                            ],
                            [
                                -165.177530999909,
                                60.8588650001693
                            ],
                            [
                                -165.170522999832,
                                60.876927999912
                            ],
                            [
                                -165.179355000347,
                                60.8820969998856
                            ],
                            [
                                -165.193466000036,
                                60.8852559998435
                            ],
                            [
                                -165.223480000066,
                                60.8964500001899
                            ],
                            [
                                -165.232342999645,
                                60.9039130000166
                            ],
                            [
                                -165.237074000445,
                                60.9105129998083
                            ],
                            [
                                -165.246495000241,
                                60.9128029998537
                            ],
                            [
                                -165.254161999918,
                                60.9171080000168
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -154.798609999941,
                                57.2275329998254
                            ],
                            [
                                -154.807024999806,
                                57.2256509997894
                            ],
                            [
                                -154.831098999614,
                                57.2306440001727
                            ],
                            [
                                -154.871238000145,
                                57.246485000243
                            ],
                            [
                                -154.914026000396,
                                57.2667339997748
                            ],
                            [
                                -154.931221999824,
                                57.2823700000742
                            ],
                            [
                                -154.933693999992,
                                57.2946780000476
                            ],
                            [
                                -154.926459000135,
                                57.3164230000039
                            ],
                            [
                                -154.910334000037,
                                57.3350579998034
                            ],
                            [
                                -154.914464000064,
                                57.3424369999171
                            ],
                            [
                                -154.915558999683,
                                57.3498730001441
                            ],
                            [
                                -154.888627999742,
                                57.3819179998497
                            ],
                            [
                                -154.815397000014,
                                57.4127869998194
                            ],
                            [
                                -154.81762200031,
                                57.4180840000622
                            ],
                            [
                                -154.816929000374,
                                57.4242529998577
                            ],
                            [
                                -154.790158000266,
                                57.4614169999871
                            ],
                            [
                                -154.765884000442,
                                57.4809520001651
                            ],
                            [
                                -154.75277600031,
                                57.4871780000534
                            ],
                            [
                                -154.745671999974,
                                57.5071050000695
                            ],
                            [
                                -154.722965999596,
                                57.5279549998299
                            ],
                            [
                                -154.703144999839,
                                57.5420159999889
                            ],
                            [
                                -154.671568000385,
                                57.5571470001492
                            ],
                            [
                                -154.625182000384,
                                57.5729039998498
                            ],
                            [
                                -154.616344000021,
                                57.5937709998928
                            ],
                            [
                                -154.606883999868,
                                57.6032529999452
                            ],
                            [
                                -154.57272899994,
                                57.6205899999402
                            ],
                            [
                                -154.537399999852,
                                57.6250819999371
                            ],
                            [
                                -154.510507000444,
                                57.6320329998406
                            ],
                            [
                                -154.48093699993,
                                57.6318560002102
                            ],
                            [
                                -154.439095000234,
                                57.6684929998712
                            ],
                            [
                                -154.407010999718,
                                57.6905249997827
                            ],
                            [
                                -154.364714999562,
                                57.6930070000883
                            ],
                            [
                                -154.329740000375,
                                57.6931779998244
                            ],
                            [
                                -154.298137000083,
                                57.7061409999921
                            ],
                            [
                                -154.266240999888,
                                57.7159519998714
                            ],
                            [
                                -154.240064999808,
                                57.7154670001468
                            ],
                            [
                                -154.128419000428,
                                57.7706650000655
                            ],
                            [
                                -154.105894999626,
                                57.7885740001824
                            ],
                            [
                                -154.003794999715,
                                57.8502529999411
                            ],
                            [
                                -153.978253000019,
                                57.8614729999832
                            ],
                            [
                                -153.972198999995,
                                57.8676939999298
                            ],
                            [
                                -153.968592999849,
                                57.8748980002263
                            ],
                            [
                                -153.956567999641,
                                57.8819620001725
                            ],
                            [
                                -153.943451000186,
                                57.8856670000394
                            ],
                            [
                                -153.944524999889,
                                57.892337000187
                            ],
                            [
                                -153.941497000052,
                                57.9004290000693
                            ],
                            [
                                -153.932275000448,
                                57.9091989998387
                            ],
                            [
                                -153.917132999815,
                                57.9165590001639
                            ],
                            [
                                -153.87056699989,
                                57.9287790002201
                            ],
                            [
                                -153.824589000318,
                                57.9270099999347
                            ],
                            [
                                -153.786622000381,
                                57.9177119999162
                            ],
                            [
                                -153.744876000045,
                                57.9400080001154
                            ],
                            [
                                -153.696094000044,
                                57.955429999838
                            ],
                            [
                                -153.634353999699,
                                57.9713680002061
                            ],
                            [
                                -153.554462000224,
                                58.0028160002268
                            ],
                            [
                                -153.511735999897,
                                58.013405999879
                            ],
                            [
                                -153.480193999705,
                                58.0188439999596
                            ],
                            [
                                -153.496698999745,
                                58.0277549998305
                            ],
                            [
                                -153.504762999981,
                                58.040327999867
                            ],
                            [
                                -153.506579000023,
                                58.0662260000052
                            ],
                            [
                                -153.500135000011,
                                58.0795549999348
                            ],
                            [
                                -153.486790999878,
                                58.0946339999453
                            ],
                            [
                                -153.470197999974,
                                58.1086989997664
                            ],
                            [
                                -153.431014000219,
                                58.1281939998222
                            ],
                            [
                                -153.424755000155,
                                58.136011000049
                            ],
                            [
                                -153.406417000354,
                                58.1514570001815
                            ],
                            [
                                -153.367468999702,
                                58.1792919999279
                            ],
                            [
                                -153.341851999663,
                                58.1872400001974
                            ],
                            [
                                -153.31467399985,
                                58.1930119998602
                            ],
                            [
                                -153.31028400043,
                                58.1968040000114
                            ],
                            [
                                -153.300825999926,
                                58.2113949998459
                            ],
                            [
                                -153.288207000414,
                                58.2255340001314
                            ],
                            [
                                -153.271345999823,
                                58.2384870001078
                            ],
                            [
                                -153.248836000163,
                                58.2491030001482
                            ],
                            [
                                -153.22128699973,
                                58.2572639997866
                            ],
                            [
                                -153.200034999895,
                                58.2662520001436
                            ],
                            [
                                -153.185324000156,
                                58.2829720002066
                            ],
                            [
                                -153.12306900015,
                                58.330215999849
                            ],
                            [
                                -152.970698000435,
                                58.435586999837
                            ],
                            [
                                -152.922283999781,
                                58.4721850001207
                            ],
                            [
                                -152.900702000233,
                                58.4839890000816
                            ],
                            [
                                -152.890898000123,
                                58.488003999852
                            ],
                            [
                                -152.880858999836,
                                58.4897909997934
                            ],
                            [
                                -152.87660699961,
                                58.4940800000635
                            ],
                            [
                                -152.871360000223,
                                58.5069190001115
                            ],
                            [
                                -152.861746999908,
                                58.5200490002285
                            ],
                            [
                                -152.846898000077,
                                58.5315009998293
                            ],
                            [
                                -152.833349999729,
                                58.5383199999958
                            ],
                            [
                                -152.819689000302,
                                58.5426750001195
                            ],
                            [
                                -152.775120999993,
                                58.5494119998618
                            ],
                            [
                                -152.773065999576,
                                58.5742250001305
                            ],
                            [
                                -152.767548999852,
                                58.5865999997853
                            ],
                            [
                                -152.757237999579,
                                58.5952390000192
                            ],
                            [
                                -152.742207999998,
                                58.6034810001733
                            ],
                            [
                                -152.734629000184,
                                58.6110920000674
                            ],
                            [
                                -152.732941000188,
                                58.6224410001251
                            ],
                            [
                                -152.719078000196,
                                58.6353799999158
                            ],
                            [
                                -152.698412999991,
                                58.648644000042
                            ],
                            [
                                -152.610010000392,
                                58.6922790001549
                            ],
                            [
                                -152.57459699974,
                                58.7253280000815
                            ],
                            [
                                -152.560234999981,
                                58.7357240002041
                            ],
                            [
                                -152.545566999899,
                                58.7424459998329
                            ],
                            [
                                -152.497312999978,
                                58.7553239998896
                            ],
                            [
                                -152.473568999883,
                                58.7572579997872
                            ],
                            [
                                -152.434539999938,
                                58.7507770000707
                            ],
                            [
                                -152.40918300019,
                                58.7418610000485
                            ],
                            [
                                -152.392986000122,
                                58.7304590002018
                            ],
                            [
                                -152.380770000117,
                                58.7112690000275
                            ],
                            [
                                -152.380918000255,
                                58.7010129999211
                            ],
                            [
                                -152.384798999862,
                                58.6904240001958
                            ],
                            [
                                -152.368589000273,
                                58.6966050001526
                            ],
                            [
                                -152.342634000125,
                                58.7013789998575
                            ],
                            [
                                -152.312588000308,
                                58.7004479999489
                            ],
                            [
                                -152.268054000335,
                                58.6885610001378
                            ],
                            [
                                -152.247712000171,
                                58.6807280001625
                            ],
                            [
                                -152.227209000348,
                                58.6633779998958
                            ],
                            [
                                -152.212939999577,
                                58.64863299979
                            ],
                            [
                                -152.211629999873,
                                58.6372269999372
                            ],
                            [
                                -152.189320000228,
                                58.6202549999756
                            ],
                            [
                                -152.180860000158,
                                58.6058299999738
                            ],
                            [
                                -152.179034000069,
                                58.5934410000065
                            ],
                            [
                                -152.182826999813,
                                58.5739729999283
                            ],
                            [
                                -152.153662000253,
                                58.5733529999915
                            ],
                            [
                                -152.138635999971,
                                58.5704140000873
                            ],
                            [
                                -152.11348099989,
                                58.5616640001352
                            ],
                            [
                                -152.092474000277,
                                58.5491030001201
                            ],
                            [
                                -152.089949000405,
                                58.545133000035
                            ],
                            [
                                -152.065493000107,
                                58.5267600000348
                            ],
                            [
                                -152.060881000031,
                                58.5008300000202
                            ],
                            [
                                -152.067812000114,
                                58.4886099999536
                            ],
                            [
                                -152.079823999904,
                                58.4785920002057
                            ],
                            [
                                -152.091096999727,
                                58.4717280000358
                            ],
                            [
                                -152.116251999809,
                                58.4627060001052
                            ],
                            [
                                -152.144363999583,
                                58.4577060000615
                            ],
                            [
                                -152.181928999837,
                                58.4578619998505
                            ],
                            [
                                -152.182050000211,
                                58.4519519998698
                            ],
                            [
                                -152.173277000146,
                                58.4497300001974
                            ],
                            [
                                -152.126347999997,
                                58.4495890000508
                            ],
                            [
                                -152.103311999906,
                                58.447210999861
                            ],
                            [
                                -152.071908999631,
                                58.4400269999208
                            ],
                            [
                                -152.051984000117,
                                58.4312579998932
                            ],
                            [
                                -152.024262000331,
                                58.4126179998661
                            ],
                            [
                                -151.962482999627,
                                58.3999969999353
                            ],
                            [
                                -151.93229100022,
                                58.3906670001516
                            ],
                            [
                                -151.912944000116,
                                58.3823299997873
                            ],
                            [
                                -151.895216000411,
                                58.3717050002159
                            ],
                            [
                                -151.876846999749,
                                58.3794130000087
                            ],
                            [
                                -151.854532000082,
                                58.3914510001669
                            ],
                            [
                                -151.823970999706,
                                58.398881000058
                            ],
                            [
                                -151.794995000292,
                                58.4005959998539
                            ],
                            [
                                -151.772102000316,
                                58.3985649999403
                            ],
                            [
                                -151.750493000106,
                                58.3946549999838
                            ],
                            [
                                -151.728693000099,
                                58.3862120000761
                            ],
                            [
                                -151.712804000003,
                                58.3739800001844
                            ],
                            [
                                -151.704026999739,
                                58.35733600003
                            ],
                            [
                                -151.702266999773,
                                58.3414990001552
                            ],
                            [
                                -151.70728499973,
                                58.3313449998384
                            ],
                            [
                                -151.736041999759,
                                58.3118239999821
                            ],
                            [
                                -151.755183,
                                58.3033969999148
                            ],
                            [
                                -151.740089999771,
                                58.2973660002149
                            ],
                            [
                                -151.725241999765,
                                58.2875430000969
                            ],
                            [
                                -151.705003000431,
                                58.270515000117
                            ],
                            [
                                -151.696582999645,
                                58.2597750000463
                            ],
                            [
                                -151.691759999681,
                                58.2459180001522
                            ],
                            [
                                -151.690505000229,
                                58.2351340001847
                            ],
                            [
                                -151.701424999874,
                                58.2225079998578
                            ],
                            [
                                -151.698313000372,
                                58.2154330000819
                            ],
                            [
                                -151.706604000388,
                                58.1878209998482
                            ],
                            [
                                -151.715655000288,
                                58.1755240001186
                            ],
                            [
                                -151.734634000146,
                                58.1567760001688
                            ],
                            [
                                -151.764019999639,
                                58.1366259998217
                            ],
                            [
                                -151.782174000215,
                                58.1287600001377
                            ],
                            [
                                -151.802568000258,
                                58.1221609997755
                            ],
                            [
                                -151.841674000195,
                                58.113143000021
                            ],
                            [
                                -151.861053999912,
                                58.1106040000121
                            ],
                            [
                                -151.884181000239,
                                58.1129559998796
                            ],
                            [
                                -151.90822300026,
                                58.1172059998882
                            ],
                            [
                                -151.933992999562,
                                58.1257170000042
                            ],
                            [
                                -151.945374000238,
                                58.132442000223
                            ],
                            [
                                -151.966391999722,
                                58.153741999967
                            ],
                            [
                                -151.988397999592,
                                58.1382529998776
                            ],
                            [
                                -152.033519000094,
                                58.1117809999962
                            ],
                            [
                                -152.055267000222,
                                58.1045339999672
                            ],
                            [
                                -152.127475000301,
                                58.0920189997873
                            ],
                            [
                                -152.260736999564,
                                58.0733570000373
                            ],
                            [
                                -152.270399000282,
                                58.0692809997784
                            ],
                            [
                                -152.275104000245,
                                58.0646540001516
                            ],
                            [
                                -152.292600999971,
                                58.0558509997922
                            ],
                            [
                                -152.312309999576,
                                58.049636000221
                            ],
                            [
                                -152.325252999851,
                                58.0479179999539
                            ],
                            [
                                -152.344953999958,
                                58.048002999936
                            ],
                            [
                                -152.382573999566,
                                58.0523879998538
                            ],
                            [
                                -152.404233000005,
                                58.0592370002123
                            ],
                            [
                                -152.413622999839,
                                58.0581760000111
                            ],
                            [
                                -152.453348000092,
                                58.0592869999316
                            ],
                            [
                                -152.474517000087,
                                58.0454270001481
                            ],
                            [
                                -152.458900999801,
                                58.0462959998458
                            ],
                            [
                                -152.414453000042,
                                58.0365770001446
                            ],
                            [
                                -152.380166999695,
                                58.0184490000027
                            ],
                            [
                                -152.358714000018,
                                58.0140120001413
                            ],
                            [
                                -152.346210999959,
                                58.009142000008
                            ],
                            [
                                -152.333416999646,
                                58.0018710001682
                            ],
                            [
                                -152.324149000011,
                                57.9921349997985
                            ],
                            [
                                -152.316625000449,
                                57.9796039997899
                            ],
                            [
                                -152.311707999876,
                                57.9765709999145
                            ],
                            [
                                -152.297088000374,
                                57.9723750001364
                            ],
                            [
                                -152.281642999793,
                                57.9749239998843
                            ],
                            [
                                -152.26563300022,
                                57.9711390000222
                            ],
                            [
                                -152.23558400003,
                                57.9499350000012
                            ],
                            [
                                -152.220211000317,
                                57.9302230002003
                            ],
                            [
                                -152.228864999659,
                                57.9044830000544
                            ],
                            [
                                -152.255903999555,
                                57.8692920001232
                            ],
                            [
                                -152.25523099971,
                                57.8662700001779
                            ],
                            [
                                -152.226553000222,
                                57.8573240001461
                            ],
                            [
                                -152.182379999924,
                                57.8499470001765
                            ],
                            [
                                -152.161118000043,
                                57.8429560001018
                            ],
                            [
                                -152.137953999906,
                                57.82980899991
                            ],
                            [
                                -152.124092999564,
                                57.8117249999626
                            ],
                            [
                                -152.121012000023,
                                57.8045720001276
                            ],
                            [
                                -152.079789999569,
                                57.6862970000098
                            ],
                            [
                                -152.07474099965,
                                57.6817220001978
                            ],
                            [
                                -152.046099000147,
                                57.6653560001933
                            ],
                            [
                                -152.034855999563,
                                57.6533189998626
                            ],
                            [
                                -152.031689999633,
                                57.6433980000349
                            ],
                            [
                                -152.031005999918,
                                57.6286310000145
                            ],
                            [
                                -152.045135000049,
                                57.6105150000292
                            ],
                            [
                                -152.053478999769,
                                57.6036529999789
                            ],
                            [
                                -152.051256999846,
                                57.59544500017
                            ],
                            [
                                -152.055473999912,
                                57.5864010001191
                            ],
                            [
                                -152.056417999918,
                                57.579510000149
                            ],
                            [
                                -152.063497999964,
                                57.5712049998284
                            ],
                            [
                                -152.096262999822,
                                57.5525520000296
                            ],
                            [
                                -152.125116000111,
                                57.5313569998081
                            ],
                            [
                                -152.152643999728,
                                57.5186060000899
                            ],
                            [
                                -152.222437000439,
                                57.4752989999989
                            ],
                            [
                                -152.216486000346,
                                57.4382290000745
                            ],
                            [
                                -152.19650999988,
                                57.4321310000756
                            ],
                            [
                                -152.18236599968,
                                57.424804000187
                            ],
                            [
                                -152.164554000309,
                                57.4140419999319
                            ],
                            [
                                -152.156516000013,
                                57.4058549998196
                            ],
                            [
                                -152.159697999836,
                                57.3804120001284
                            ],
                            [
                                -152.170051999767,
                                57.3677559997817
                            ],
                            [
                                -152.18634400027,
                                57.3531809998147
                            ],
                            [
                                -152.2044920001,
                                57.3422239999223
                            ],
                            [
                                -152.227390999923,
                                57.332037999795
                            ],
                            [
                                -152.258481000204,
                                57.323429000003
                            ],
                            [
                                -152.314917000362,
                                57.3185849999338
                            ],
                            [
                                -152.337676000443,
                                57.3215799999822
                            ],
                            [
                                -152.361191000209,
                                57.3267589999041
                            ],
                            [
                                -152.383146999851,
                                57.3354070001347
                            ],
                            [
                                -152.395494000275,
                                57.3455239999599
                            ],
                            [
                                -152.4020889999,
                                57.3563020002157
                            ],
                            [
                                -152.403607999841,
                                57.3664720001157
                            ],
                            [
                                -152.400763000302,
                                57.3782839999802
                            ],
                            [
                                -152.4020889999,
                                57.3803439999992
                            ],
                            [
                                -152.415583999646,
                                57.3844700001495
                            ],
                            [
                                -152.424951999738,
                                57.3810439999113
                            ],
                            [
                                -152.526010999558,
                                57.3293669998007
                            ],
                            [
                                -152.528758000086,
                                57.3273270001503
                            ],
                            [
                                -152.534474000002,
                                57.3063079998561
                            ],
                            [
                                -152.537666999695,
                                57.3007520001285
                            ],
                            [
                                -152.622929000378,
                                57.2460730002347
                            ],
                            [
                                -152.642719000173,
                                57.2353719998004
                            ],
                            [
                                -152.676554000434,
                                57.2272699997873
                            ],
                            [
                                -152.701814999922,
                                57.2248879998546
                            ],
                            [
                                -152.772909000116,
                                57.2197990000853
                            ],
                            [
                                -152.835628999554,
                                57.2173650001647
                            ],
                            [
                                -152.798841999624,
                                57.2018080001763
                            ],
                            [
                                -152.785206000311,
                                57.1924570001941
                            ],
                            [
                                -152.779173000203,
                                57.1821659998818
                            ],
                            [
                                -152.775459999927,
                                57.1709300002321
                            ],
                            [
                                -152.774366999958,
                                57.1605590000765
                            ],
                            [
                                -152.777379999726,
                                57.1414499997673
                            ],
                            [
                                -152.792946999608,
                                57.1228769998012
                            ],
                            [
                                -152.809971000405,
                                57.1091800001987
                            ],
                            [
                                -152.834162000389,
                                57.0944249998963
                            ],
                            [
                                -152.876864000426,
                                57.078842000226
                            ],
                            [
                                -152.904335999968,
                                57.0723800001133
                            ],
                            [
                                -152.938003999824,
                                57.0661719999605
                            ],
                            [
                                -152.983990999616,
                                57.066455000181
                            ],
                            [
                                -153.001222000102,
                                57.0644010000135
                            ],
                            [
                                -153.098288999987,
                                57.0403129998546
                            ],
                            [
                                -153.104626999694,
                                57.0281089998415
                            ],
                            [
                                -153.11141899986,
                                57.0225059999613
                            ],
                            [
                                -153.110448999915,
                                57.0115789998936
                            ],
                            [
                                -153.121518000422,
                                57.0015300002429
                            ],
                            [
                                -153.146830999789,
                                56.9874840000557
                            ],
                            [
                                -153.159867999775,
                                56.9739089997702
                            ],
                            [
                                -153.169127999912,
                                56.9670339999458
                            ],
                            [
                                -153.180958999952,
                                56.9606799999864
                            ],
                            [
                                -153.20554800032,
                                56.953218000176
                            ],
                            [
                                -153.229741999778,
                                56.9508770001477
                            ],
                            [
                                -153.238782999632,
                                56.9523190002155
                            ],
                            [
                                -153.243213000133,
                                56.9498739997592
                            ],
                            [
                                -153.274315000109,
                                56.9427300001837
                            ],
                            [
                                -153.319999999778,
                                56.9409379999603
                            ],
                            [
                                -153.357708000148,
                                56.9499619999262
                            ],
                            [
                                -153.387965999677,
                                56.9482789998632
                            ],
                            [
                                -153.404529000342,
                                56.9507729998976
                            ],
                            [
                                -153.439188999884,
                                56.9639580001364
                            ],
                            [
                                -153.449095000102,
                                56.9708100002029
                            ],
                            [
                                -153.459049999825,
                                56.9638029998278
                            ],
                            [
                                -153.457371999875,
                                56.9602060000882
                            ],
                            [
                                -153.457680999671,
                                56.9560009998872
                            ],
                            [
                                -153.462845000116,
                                56.9477410000693
                            ],
                            [
                                -153.476856000246,
                                56.9413189998802
                            ],
                            [
                                -153.470372000051,
                                56.9393320002318
                            ],
                            [
                                -153.454616000024,
                                56.9264249998906
                            ],
                            [
                                -153.449427000363,
                                56.9133990001034
                            ],
                            [
                                -153.450947000129,
                                56.9003400002213
                            ],
                            [
                                -153.462564999734,
                                56.8855029999535
                            ],
                            [
                                -153.476334999839,
                                56.8725439999404
                            ],
                            [
                                -153.48272199995,
                                56.8693709998382
                            ],
                            [
                                -153.488700999632,
                                56.863668000212
                            ],
                            [
                                -153.501320999867,
                                56.8566729998504
                            ],
                            [
                                -153.523099999957,
                                56.8525240000058
                            ],
                            [
                                -153.544542999588,
                                56.8423660000231
                            ],
                            [
                                -153.599585000376,
                                56.8339259997929
                            ],
                            [
                                -153.615179999847,
                                56.8343849997578
                            ],
                            [
                                -153.627738000158,
                                56.8203860000097
                            ],
                            [
                                -153.648649000409,
                                56.8041809999674
                            ],
                            [
                                -153.666038000181,
                                56.7948440000269
                            ],
                            [
                                -153.694549999988,
                                56.7856420002184
                            ],
                            [
                                -153.719090999776,
                                56.7787130000033
                            ],
                            [
                                -153.742500999961,
                                56.7758740001615
                            ],
                            [
                                -153.760418999637,
                                56.7764740000558
                            ],
                            [
                                -153.749653999802,
                                56.7590819999967
                            ],
                            [
                                -153.747553000253,
                                56.7450440001624
                            ],
                            [
                                -153.769789000277,
                                56.711806999765
                            ],
                            [
                                -153.783540000117,
                                56.6975640000337
                            ],
                            [
                                -153.800431999771,
                                56.6899280001164
                            ],
                            [
                                -153.812368000291,
                                56.6878429999841
                            ],
                            [
                                -153.834947000447,
                                56.6880460001719
                            ],
                            [
                                -153.840126999887,
                                56.6833070000719
                            ],
                            [
                                -153.857415000275,
                                56.6764130001066
                            ],
                            [
                                -153.871878000317,
                                56.6727119998979
                            ],
                            [
                                -153.890074999653,
                                56.6717079997733
                            ],
                            [
                                -153.893587000087,
                                56.663299000154
                            ],
                            [
                                -153.902636000337,
                                56.6533419999885
                            ],
                            [
                                -153.919918999803,
                                56.6425389997939
                            ],
                            [
                                -153.957047000215,
                                56.6333179999375
                            ],
                            [
                                -153.971152999881,
                                56.6320409999928
                            ],
                            [
                                -153.999760000123,
                                56.6349510002346
                            ],
                            [
                                -153.986318999905,
                                56.6258720001654
                            ],
                            [
                                -153.981362999872,
                                56.6138309998048
                            ],
                            [
                                -153.982120000281,
                                56.6090110000013
                            ],
                            [
                                -153.915075999748,
                                56.612835000004
                            ],
                            [
                                -153.905132999721,
                                56.6115759999194
                            ],
                            [
                                -153.8833650004,
                                56.618683000201
                            ],
                            [
                                -153.842106000136,
                                56.6205650001351
                            ],
                            [
                                -153.819054999977,
                                56.6169869999631
                            ],
                            [
                                -153.790896000348,
                                56.61010499999
                            ],
                            [
                                -153.764940000375,
                                56.5880830001283
                            ],
                            [
                                -153.758231999874,
                                56.5684899998961
                            ],
                            [
                                -153.762432000221,
                                56.5567450000422
                            ],
                            [
                                -153.7701559998,
                                56.5470429999051
                            ],
                            [
                                -153.777979999836,
                                56.541184000065
                            ],
                            [
                                -153.783946999822,
                                56.5316620002274
                            ],
                            [
                                -153.806604999621,
                                56.5093600001191
                            ],
                            [
                                -153.824714999816,
                                56.4971259998461
                            ],
                            [
                                -153.848848999899,
                                56.486045999814
                            ],
                            [
                                -153.871504000223,
                                56.4785819998481
                            ],
                            [
                                -153.881207999876,
                                56.4774779998265
                            ],
                            [
                                -153.888923999957,
                                56.4706350001294
                            ],
                            [
                                -153.904670999764,
                                56.4619800001477
                            ],
                            [
                                -153.919080000277,
                                56.4569869997876
                            ],
                            [
                                -153.936275999705,
                                56.4534610000075
                            ],
                            [
                                -153.959640999684,
                                56.4551469999019
                            ],
                            [
                                -153.981001000372,
                                56.4510899999886
                            ],
                            [
                                -153.997159000083,
                                56.4500859999931
                            ],
                            [
                                -154.013305999923,
                                56.4516809999965
                            ],
                            [
                                -154.035876999683,
                                56.4487530001304
                            ],
                            [
                                -154.057878000429,
                                56.4484339998687
                            ],
                            [
                                -154.081451999746,
                                56.4519600000592
                            ],
                            [
                                -154.105404000254,
                                56.4500770000596
                            ],
                            [
                                -154.131052000255,
                                56.4534969999973
                            ],
                            [
                                -154.153344000356,
                                56.4600499999811
                            ],
                            [
                                -154.188188000022,
                                56.4489560001664
                            ],
                            [
                                -154.233608000275,
                                56.4434739998089
                            ],
                            [
                                -154.253763999768,
                                56.443939000239
                            ],
                            [
                                -154.275042000441,
                                56.4466269999139
                            ],
                            [
                                -154.340562999936,
                                56.4644020000479
                            ],
                            [
                                -154.356011999817,
                                56.4539459999421
                            ],
                            [
                                -154.378344999925,
                                56.4460940000367
                            ],
                            [
                                -154.403176000142,
                                56.4416679999637
                            ],
                            [
                                -154.429876999929,
                                56.4411430000015
                            ],
                            [
                                -154.452603000399,
                                56.4432389997872
                            ],
                            [
                                -154.473236999742,
                                56.4478029998441
                            ],
                            [
                                -154.498209000424,
                                56.4557839999618
                            ],
                            [
                                -154.581989999902,
                                56.4268109999726
                            ],
                            [
                                -154.628878000043,
                                56.3860889999234
                            ],
                            [
                                -154.657101000145,
                                56.3732309999085
                            ],
                            [
                                -154.689035999799,
                                56.3627010000237
                            ],
                            [
                                -154.725732000391,
                                56.3552189998775
                            ],
                            [
                                -154.763299000295,
                                56.3546920000891
                            ],
                            [
                                -154.794932999651,
                                56.3592880002094
                            ],
                            [
                                -154.817960000419,
                                56.3661699999232
                            ],
                            [
                                -154.862938999732,
                                56.3838509997781
                            ],
                            [
                                -154.881701999681,
                                56.4013949998147
                            ],
                            [
                                -154.889023999577,
                                56.4126140001072
                            ],
                            [
                                -154.893089000205,
                                56.4228610001661
                            ],
                            [
                                -154.894805999615,
                                56.4345659999899
                            ],
                            [
                                -154.893847000438,
                                56.4447239998015
                            ],
                            [
                                -154.886687999851,
                                56.4557410000352
                            ],
                            [
                                -154.861382999981,
                                56.4753480002439
                            ],
                            [
                                -154.854569000073,
                                56.4869910000137
                            ],
                            [
                                -154.820897000019,
                                56.5115440000508
                            ],
                            [
                                -154.812366999628,
                                56.5238269998816
                            ],
                            [
                                -154.758106000436,
                                56.5598150002376
                            ],
                            [
                                -154.668697000008,
                                56.5978250001756
                            ],
                            [
                                -154.648744000006,
                                56.6098540000573
                            ],
                            [
                                -154.597483000164,
                                56.6360330000463
                            ],
                            [
                                -154.563837999874,
                                56.6497550001568
                            ],
                            [
                                -154.54049800001,
                                56.6558440001951
                            ],
                            [
                                -154.507519999717,
                                56.6588439997544
                            ],
                            [
                                -154.488546999706,
                                56.6580879997964
                            ],
                            [
                                -154.458938999557,
                                56.6530760001178
                            ],
                            [
                                -154.429980999686,
                                56.6546309999804
                            ],
                            [
                                -154.415039999794,
                                56.6516720001083
                            ],
                            [
                                -154.360108000407,
                                56.6335239999927
                            ],
                            [
                                -154.352106000095,
                                56.6325400001229
                            ],
                            [
                                -154.337817000131,
                                56.6369090002352
                            ],
                            [
                                -154.320450999925,
                                56.6466589997575
                            ],
                            [
                                -154.305381000161,
                                56.6520060001747
                            ],
                            [
                                -154.276849000263,
                                56.6600149999927
                            ],
                            [
                                -154.236672000097,
                                56.6675729999019
                            ],
                            [
                                -154.235497000113,
                                56.6739140002313
                            ],
                            [
                                -154.238647000148,
                                56.6796069999204
                            ],
                            [
                                -154.239295999704,
                                56.6928550000692
                            ],
                            [
                                -154.233595999681,
                                56.7036320001365
                            ],
                            [
                                -154.217826000308,
                                56.7204670000834
                            ],
                            [
                                -154.31606599963,
                                56.7861289999952
                            ],
                            [
                                -154.385523999706,
                                56.8302690002024
                            ],
                            [
                                -154.392761000112,
                                56.8397240002112
                            ],
                            [
                                -154.393197999955,
                                56.8490320001541
                            ],
                            [
                                -154.387718999865,
                                56.8956180001027
                            ],
                            [
                                -154.440057999608,
                                56.923600000088
                            ],
                            [
                                -154.502439999836,
                                56.9407879997844
                            ],
                            [
                                -154.537266999783,
                                56.9450090001607
                            ],
                            [
                                -154.577483000306,
                                56.9527730002349
                            ],
                            [
                                -154.59553800025,
                                56.9609310002352
                            ],
                            [
                                -154.608606000199,
                                56.9702750000447
                            ],
                            [
                                -154.616889999644,
                                56.9796250000503
                            ],
                            [
                                -154.623967000216,
                                56.9908859999024
                            ],
                            [
                                -154.628535999736,
                                57.0020059997976
                            ],
                            [
                                -154.601127999769,
                                57.0363820002076
                            ],
                            [
                                -154.619781999937,
                                57.1623599998404
                            ],
                            [
                                -154.650276000366,
                                57.1778880002398
                            ],
                            [
                                -154.679763000142,
                                57.1980839999363
                            ],
                            [
                                -154.685766000113,
                                57.2142219998119
                            ],
                            [
                                -154.709572999957,
                                57.2161030001249
                            ],
                            [
                                -154.767085000366,
                                57.2145069999049
                            ],
                            [
                                -154.798609999941,
                                57.2275329998254
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -171.944229999627,
                                63.454713000081
                            ],
                            [
                                -171.954225000431,
                                63.4663680001488
                            ],
                            [
                                -171.965319000154,
                                63.4728739998842
                            ],
                            [
                                -171.944726999745,
                                63.5706180001002
                            ],
                            [
                                -171.924838000215,
                                63.6108499998509
                            ],
                            [
                                -171.909922000437,
                                63.6179470000288
                            ],
                            [
                                -171.904949999612,
                                63.6392429998342
                            ],
                            [
                                -171.890031000359,
                                63.6558049999203
                            ],
                            [
                                -171.860196000429,
                                63.674736000171
                            ],
                            [
                                -171.865168000356,
                                63.6889329999907
                            ],
                            [
                                -171.865168000356,
                                63.7054970001478
                            ],
                            [
                                -171.845277000278,
                                63.7267939999473
                            ],
                            [
                                -171.835333000426,
                                63.7433610000263
                            ],
                            [
                                -171.840305000352,
                                63.7528259998506
                            ],
                            [
                                -171.840306000177,
                                63.7693889998034
                            ],
                            [
                                -171.855925999762,
                                63.7856699998163
                            ],
                            [
                                -171.84598199991,
                                63.7998670000081
                            ],
                            [
                                -171.81614899963,
                                63.8211620000497
                            ],
                            [
                                -171.7962600001,
                                63.83062600006
                            ],
                            [
                                -171.776372000396,
                                63.8377239999964
                            ],
                            [
                                -171.714674999708,
                                63.8360560001755
                            ],
                            [
                                -171.705719000243,
                                63.8403910001234
                            ],
                            [
                                -171.674734000443,
                                63.8436800001221
                            ],
                            [
                                -171.647467000043,
                                63.8409499999698
                            ],
                            [
                                -171.617503000242,
                                63.833641000092
                            ],
                            [
                                -171.548830000163,
                                63.8004609999433
                            ],
                            [
                                -171.527025999959,
                                63.776517999958
                            ],
                            [
                                -171.520679000031,
                                63.7502360000418
                            ],
                            [
                                -171.527477000045,
                                63.7344879999593
                            ],
                            [
                                -171.535603000205,
                                63.7218749999616
                            ],
                            [
                                -171.251321000041,
                                63.6674419998696
                            ],
                            [
                                -171.165035999709,
                                63.6589879999509
                            ],
                            [
                                -170.959154999566,
                                63.6258880001709
                            ],
                            [
                                -170.910939000178,
                                63.6378739999838
                            ],
                            [
                                -170.875521000401,
                                63.6552939999126
                            ],
                            [
                                -170.847167999705,
                                63.6661259998691
                            ],
                            [
                                -170.705151999745,
                                63.7130829998247
                            ],
                            [
                                -170.656696999982,
                                63.7235469998871
                            ],
                            [
                                -170.619932999619,
                                63.7234550001076
                            ],
                            [
                                -170.504029999991,
                                63.7533999999115
                            ],
                            [
                                -170.468483000343,
                                63.7555620000638
                            ],
                            [
                                -170.296845999816,
                                63.7555720000931
                            ],
                            [
                                -170.243194999821,
                                63.7453860000426
                            ],
                            [
                                -170.168075000255,
                                63.719890999959
                            ],
                            [
                                -170.103681000167,
                                63.6841790000511
                            ],
                            [
                                -170.028558000228,
                                63.6484690001048
                            ],
                            [
                                -169.985627999687,
                                63.6229590001119
                            ],
                            [
                                -169.964147000421,
                                63.5821179999224
                            ],
                            [
                                -169.926598999885,
                                63.5267389999703
                            ],
                            [
                                -169.84860299992,
                                63.5075219998019
                            ],
                            [
                                -169.792992999768,
                                63.4907399999156
                            ],
                            [
                                -169.736920999658,
                                63.486235000161
                            ],
                            [
                                -169.645610999697,
                                63.4836430001398
                            ],
                            [
                                -169.602285000219,
                                63.4806349999349
                            ],
                            [
                                -169.569419999904,
                                63.4728589999586
                            ],
                            [
                                -169.543882000405,
                                63.4594380000424
                            ],
                            [
                                -169.483799999919,
                                63.4356310000353
                            ],
                            [
                                -169.455539000182,
                                63.4150200001667
                            ],
                            [
                                -169.372174999558,
                                63.4071010001713
                            ],
                            [
                                -169.234552000289,
                                63.390726999876
                            ],
                            [
                                -169.034999999632,
                                63.3874530000645
                            ],
                            [
                                -168.883610999557,
                                63.3743550000999
                            ],
                            [
                                -168.704698999888,
                                63.3547060000414
                            ],
                            [
                                -168.642767999747,
                                63.3448799999837
                            ],
                            [
                                -168.608362999574,
                                63.3350550000782
                            ],
                            [
                                -168.587718000359,
                                63.3252290000508
                            ],
                            [
                                -168.578617000231,
                                63.308674000147
                            ],
                            [
                                -168.586694999812,
                                63.2724699999015
                            ],
                            [
                                -168.604276000103,
                                63.2515929998741
                            ],
                            [
                                -168.609261000448,
                                63.2293620001773
                            ],
                            [
                                -168.635967000258,
                                63.2046380000868
                            ],
                            [
                                -168.666591000383,
                                63.1859720001537
                            ],
                            [
                                -168.692774000137,
                                63.1589409998236
                            ],
                            [
                                -168.733460000115,
                                63.1315529998758
                            ],
                            [
                                -168.696657000292,
                                63.1092199998333
                            ],
                            [
                                -168.682789000277,
                                63.0846680001514
                            ],
                            [
                                -168.700727000044,
                                63.0569250001592
                            ],
                            [
                                -168.76408599989,
                                63.0276169999095
                            ],
                            [
                                -168.803228999638,
                                63.0176520000583
                            ],
                            [
                                -168.837330999862,
                                63.0161749999696
                            ],
                            [
                                -168.868548000364,
                                63.0197920001945
                            ],
                            [
                                -168.917486000001,
                                63.0311159999556
                            ],
                            [
                                -168.940357000235,
                                63.0463289998223
                            ],
                            [
                                -168.948697999582,
                                63.0624679999159
                            ],
                            [
                                -168.946607000078,
                                63.0744510001944
                            ],
                            [
                                -168.936242000276,
                                63.0874549998976
                            ],
                            [
                                -168.922780000142,
                                63.099567000109
                            ],
                            [
                                -168.984370999626,
                                63.1127569998385
                            ],
                            [
                                -169.027785999691,
                                63.1245270001996
                            ],
                            [
                                -169.066713000426,
                                63.1282140001163
                            ],
                            [
                                -169.142551999867,
                                63.1277999999482
                            ],
                            [
                                -169.23064400002,
                                63.123501999832
                            ],
                            [
                                -169.263784999621,
                                63.1184710001653
                            ],
                            [
                                -169.282325999812,
                                63.1144050002001
                            ],
                            [
                                -169.324360000027,
                                63.0857400000896
                            ],
                            [
                                -169.367965999888,
                                63.0610759998115
                            ],
                            [
                                -169.406522999829,
                                63.0475090001789
                            ],
                            [
                                -169.451082999742,
                                63.038550999832
                            ],
                            [
                                -169.427263000377,
                                63.0002599998869
                            ],
                            [
                                -169.419861000115,
                                62.9738559999133
                            ],
                            [
                                -169.436324000322,
                                62.9474380001024
                            ],
                            [
                                -169.461551000374,
                                62.9328390000238
                            ],
                            [
                                -169.493234999958,
                                62.9266089998852
                            ],
                            [
                                -169.525341000215,
                                62.9246589998228
                            ],
                            [
                                -169.552096000429,
                                62.9029139998234
                            ],
                            [
                                -169.591035999787,
                                62.8878670000168
                            ],
                            [
                                -169.638795999964,
                                62.8849079999708
                            ],
                            [
                                -169.727890999674,
                                62.8963330001161
                            ],
                            [
                                -169.81481399969,
                                62.9173950000522
                            ],
                            [
                                -169.838951999971,
                                62.9258889998282
                            ],
                            [
                                -169.864596999599,
                                62.9446970001325
                            ],
                            [
                                -169.865270000342,
                                62.9604300001315
                            ],
                            [
                                -169.863264000329,
                                62.9801439999224
                            ],
                            [
                                -169.877727000371,
                                62.9971360001315
                            ],
                            [
                                -169.883781000395,
                                63.0200730001464
                            ],
                            [
                                -169.901407999818,
                                63.0373649999811
                            ],
                            [
                                -169.952759999897,
                                63.0640719999713
                            ],
                            [
                                -170.031796999953,
                                63.0955190001359
                            ],
                            [
                                -170.081157000262,
                                63.1113039999845
                            ],
                            [
                                -170.115415999947,
                                63.127246999919
                            ],
                            [
                                -170.140133000361,
                                63.1242570000531
                            ],
                            [
                                -170.176725000297,
                                63.1253360000323
                            ],
                            [
                                -170.190582000441,
                                63.1268419999483
                            ],
                            [
                                -170.201845000219,
                                63.1311960000438
                            ],
                            [
                                -170.228407000089,
                                63.1302029998568
                            ],
                            [
                                -170.248567999605,
                                63.1320260001998
                            ],
                            [
                                -170.259833999755,
                                63.1366620001594
                            ],
                            [
                                -170.290816999905,
                                63.1351060000127
                            ],
                            [
                                -170.32802200031,
                                63.138724000072
                            ],
                            [
                                -170.359422000212,
                                63.1477370001493
                            ],
                            [
                                -170.375066000086,
                                63.1558130000648
                            ],
                            [
                                -170.394357999939,
                                63.171055999812
                            ],
                            [
                                -170.400463000017,
                                63.1822290001309
                            ],
                            [
                                -170.400883000142,
                                63.19367500006
                            ],
                            [
                                -170.38344800034,
                                63.2107520001295
                            ],
                            [
                                -170.439075000211,
                                63.2515959998393
                            ],
                            [
                                -170.515276000049,
                                63.2879099998298
                            ],
                            [
                                -170.596894999878,
                                63.3116159999474
                            ],
                            [
                                -170.816603999854,
                                63.354976999928
                            ],
                            [
                                -170.908249000449,
                                63.3699449999171
                            ],
                            [
                                -170.993588000226,
                                63.3748400001817
                            ],
                            [
                                -171.048383000244,
                                63.3736909999015
                            ],
                            [
                                -171.066835999673,
                                63.3711079998396
                            ],
                            [
                                -171.100518999597,
                                63.3565129999919
                            ],
                            [
                                -171.127873000036,
                                63.3501990000895
                            ],
                            [
                                -171.166046999663,
                                63.3481580000479
                            ],
                            [
                                -171.173588999667,
                                63.3364290001733
                            ],
                            [
                                -171.191304999677,
                                63.324685000038
                            ],
                            [
                                -171.214119000009,
                                63.3157840000659
                            ],
                            [
                                -171.227377999754,
                                63.3040430000872
                            ],
                            [
                                -171.251398000034,
                                63.2925679998235
                            ],
                            [
                                -171.280545999875,
                                63.2856480001793
                            ],
                            [
                                -171.326228999894,
                                63.2826970001502
                            ],
                            [
                                -171.362228000352,
                                63.2680259998771
                            ],
                            [
                                -171.398887000235,
                                63.2576419998302
                            ],
                            [
                                -171.43560100037,
                                63.2526700001185
                            ],
                            [
                                -171.481934999594,
                                63.2545309999452
                            ],
                            [
                                -171.533561000207,
                                63.2666429999758
                            ],
                            [
                                -171.560191999674,
                                63.2651140000334
                            ],
                            [
                                -171.594550999817,
                                63.2695510001357
                            ],
                            [
                                -171.622014000035,
                                63.277154000083
                            ],
                            [
                                -171.651547999666,
                                63.2910400001259
                            ],
                            [
                                -171.691118999934,
                                63.2980080000408
                            ],
                            [
                                -171.744267999789,
                                63.3131779998356
                            ],
                            [
                                -171.772284000201,
                                63.3153270001728
                            ],
                            [
                                -171.810816999854,
                                63.3285479998138
                            ],
                            [
                                -171.83924400017,
                                63.3426820000929
                            ],
                            [
                                -171.851020999783,
                                63.3531939999789
                            ],
                            [
                                -171.8557159997,
                                63.3611809999095
                            ],
                            [
                                -171.884887000006,
                                63.3775899998627
                            ],
                            [
                                -171.906613000392,
                                63.3986169998658
                            ],
                            [
                                -171.931108999975,
                                63.4256289999635
                            ],
                            [
                                -171.944229999627,
                                63.454713000081
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -166.236052999608,
                                54.035402000252
                            ],
                            [
                                -166.225646999798,
                                54.0419929999396
                            ],
                            [
                                -166.208071000429,
                                54.0479739998208
                            ],
                            [
                                -166.180793000158,
                                54.0508819997636
                            ],
                            [
                                -166.156350000279,
                                54.0498980000316
                            ],
                            [
                                -166.140832999728,
                                54.0469329998576
                            ],
                            [
                                -166.140533999977,
                                54.0571319998495
                            ],
                            [
                                -166.152324000009,
                                54.0618450001234
                            ],
                            [
                                -166.164560000105,
                                54.0727010002558
                            ],
                            [
                                -166.172250000247,
                                54.0751870000227
                            ],
                            [
                                -166.178750000335,
                                54.0831219998247
                            ],
                            [
                                -166.18210700006,
                                54.0904990001587
                            ],
                            [
                                -166.188576000186,
                                54.0936500000079
                            ],
                            [
                                -166.199012000133,
                                54.1035130000173
                            ],
                            [
                                -166.202429999958,
                                54.119824000248
                            ],
                            [
                                -166.199074999882,
                                54.1355410002081
                            ],
                            [
                                -166.19266799968,
                                54.145474000004
                            ],
                            [
                                -166.18413399999,
                                54.1509269999843
                            ],
                            [
                                -166.184979000262,
                                54.1570480000739
                            ],
                            [
                                -166.183759000071,
                                54.1611299997688
                            ],
                            [
                                -166.178695000083,
                                54.1692010001239
                            ],
                            [
                                -166.173508000072,
                                54.1733439998271
                            ],
                            [
                                -166.171830000122,
                                54.1848719999082
                            ],
                            [
                                -166.165116999598,
                                54.1950880001147
                            ],
                            [
                                -166.137536000277,
                                54.21425299976
                            ],
                            [
                                -166.120662000166,
                                54.2231410001858
                            ],
                            [
                                -166.068455999594,
                                54.2399190002086
                            ],
                            [
                                -166.061060000079,
                                54.2494279999775
                            ],
                            [
                                -166.03394400019,
                                54.2609539999256
                            ],
                            [
                                -166.002769000418,
                                54.26482899977
                            ],
                            [
                                -165.987657999748,
                                54.2708040001375
                            ],
                            [
                                -165.937220000436,
                                54.2769850002358
                            ],
                            [
                                -165.90804900013,
                                54.2744450002198
                            ],
                            [
                                -165.88815300003,
                                54.2674110001086
                            ],
                            [
                                -165.865603000186,
                                54.2647190000989
                            ],
                            [
                                -165.836641999942,
                                54.2582199997822
                            ],
                            [
                                -165.812870000258,
                                54.2499950000538
                            ],
                            [
                                -165.789147000079,
                                54.2391380000769
                            ],
                            [
                                -165.769001999558,
                                54.2391789998357
                            ],
                            [
                                -165.76730699989,
                                54.2552299997639
                            ],
                            [
                                -165.743351999907,
                                54.2888909997616
                            ],
                            [
                                -165.725162000245,
                                54.3062709999249
                            ],
                            [
                                -165.696170000039,
                                54.3322339998654
                            ],
                            [
                                -165.682559999766,
                                54.3393449999175
                            ],
                            [
                                -165.655550000183,
                                54.3470510001102
                            ],
                            [
                                -165.624026000432,
                                54.3493560001168
                            ],
                            [
                                -165.594209000046,
                                54.3465630002089
                            ],
                            [
                                -165.578684999822,
                                54.3414359997763
                            ],
                            [
                                -165.542022999565,
                                54.3487150001061
                            ],
                            [
                                -165.498962000401,
                                54.3491189998965
                            ],
                            [
                                -165.46249299959,
                                54.3468219998899
                            ],
                            [
                                -165.431333999713,
                                54.3406870002455
                            ],
                            [
                                -165.40676600016,
                                54.326677999985
                            ],
                            [
                                -165.395626000407,
                                54.3128320001899
                            ],
                            [
                                -165.389095000356,
                                54.2993280002431
                            ],
                            [
                                -165.386347999828,
                                54.2914620001026
                            ],
                            [
                                -165.388479000413,
                                54.2881340000381
                            ],
                            [
                                -165.358636999912,
                                54.2660470000421
                            ],
                            [
                                -165.345177000326,
                                54.2510330002002
                            ],
                            [
                                -165.318658000114,
                                54.2480849999636
                            ],
                            [
                                -165.263022999847,
                                54.236543999777
                            ],
                            [
                                -165.241416000184,
                                54.2258600000563
                            ],
                            [
                                -165.232074000031,
                                54.2165200000802
                            ],
                            [
                                -165.232044999719,
                                54.1956999997996
                            ],
                            [
                                -165.242817000125,
                                54.1778739999509
                            ],
                            [
                                -165.258898999667,
                                54.1668309997981
                            ],
                            [
                                -165.279132999877,
                                54.158410000263
                            ],
                            [
                                -165.319018999789,
                                54.1491890002543
                            ],
                            [
                                -165.320971000274,
                                54.1445340000584
                            ],
                            [
                                -165.271170999748,
                                54.1441309998985
                            ],
                            [
                                -165.263415000382,
                                54.1502450000843
                            ],
                            [
                                -165.243823000055,
                                54.159290000158
                            ],
                            [
                                -165.203937000143,
                                54.1659599997426
                            ],
                            [
                                -165.186572999588,
                                54.1743160001075
                            ],
                            [
                                -165.150256999836,
                                54.1805230001702
                            ],
                            [
                                -165.105974999758,
                                54.1787299998792
                            ],
                            [
                                -165.081926999889,
                                54.1807270000562
                            ],
                            [
                                -165.066871000369,
                                54.1800860002465
                            ],
                            [
                                -165.063493999654,
                                54.186730999927
                            ],
                            [
                                -165.048053000169,
                                54.1987169999735
                            ],
                            [
                                -165.031512000144,
                                54.2088780001818
                            ],
                            [
                                -165.009661000084,
                                54.2128669999383
                            ],
                            [
                                -164.97496999968,
                                54.2148839999703
                            ],
                            [
                                -164.966203000361,
                                54.2264260001763
                            ],
                            [
                                -164.950914000314,
                                54.2357270001257
                            ],
                            [
                                -164.911240999939,
                                54.2546879997951
                            ],
                            [
                                -164.885331999822,
                                54.2625229997749
                            ],
                            [
                                -164.805369000202,
                                54.282392000044
                            ],
                            [
                                -164.758528999741,
                                54.2781769999394
                            ],
                            [
                                -164.729262000074,
                                54.2719369997804
                            ],
                            [
                                -164.708905999666,
                                54.2640179998883
                            ],
                            [
                                -164.687755000113,
                                54.2501179999079
                            ],
                            [
                                -164.68064300028,
                                54.2321740002006
                            ],
                            [
                                -164.679606000387,
                                54.2188300000394
                            ],
                            [
                                -164.682382000329,
                                54.2018620000284
                            ],
                            [
                                -164.692055999845,
                                54.1821849997677
                            ],
                            [
                                -164.714485000213,
                                54.1661170001672
                            ],
                            [
                                -164.7277010003,
                                54.1608820002145
                            ],
                            [
                                -164.753961999871,
                                54.1548919998254
                            ],
                            [
                                -164.762948000372,
                                54.1435459999011
                            ],
                            [
                                -164.776192999873,
                                54.1358550002305
                            ],
                            [
                                -164.796242000134,
                                54.1330990000705
                            ],
                            [
                                -164.817363000448,
                                54.1333879998426
                            ],
                            [
                                -164.821940000364,
                                54.1308089999441
                            ],
                            [
                                -164.831033000096,
                                54.1102170001947
                            ],
                            [
                                -164.836739999791,
                                54.1046470000365
                            ],
                            [
                                -164.841193999684,
                                54.0902430000478
                            ],
                            [
                                -164.846046999784,
                                54.0832539998025
                            ],
                            [
                                -164.865561000293,
                                54.0752860001309
                            ],
                            [
                                -164.868691000237,
                                54.0713130002018
                            ],
                            [
                                -164.867621999659,
                                54.0642419999513
                            ],
                            [
                                -164.881047999809,
                                54.0434289998832
                            ],
                            [
                                -164.898198999929,
                                54.0355100000023
                            ],
                            [
                                -164.922795999794,
                                54.0284750002333
                            ],
                            [
                                -164.964454000266,
                                54.0250719997768
                            ],
                            [
                                -165.027259000093,
                                54.0129429997826
                            ],
                            [
                                -165.040168000033,
                                54.0129209999491
                            ],
                            [
                                -165.051559999682,
                                54.0147899999912
                            ],
                            [
                                -165.082130000279,
                                54.0118260002525
                            ],
                            [
                                -165.105598000189,
                                54.0131239999394
                            ],
                            [
                                -165.137153999727,
                                54.0185449997977
                            ],
                            [
                                -165.159949999618,
                                54.0191619999677
                            ],
                            [
                                -165.184461000167,
                                54.0170100000244
                            ],
                            [
                                -165.197211000099,
                                54.0091300001893
                            ],
                            [
                                -165.221058999951,
                                54.0030190000217
                            ],
                            [
                                -165.233833000172,
                                53.9950689998371
                            ],
                            [
                                -165.269631999715,
                                53.9807309997934
                            ],
                            [
                                -165.302877999797,
                                53.9807349997844
                            ],
                            [
                                -165.357363000193,
                                54.0028339997516
                            ],
                            [
                                -165.374950000332,
                                54.0112889998201
                            ],
                            [
                                -165.380964999998,
                                54.0164360001426
                            ],
                            [
                                -165.403066000303,
                                54.0171349999306
                            ],
                            [
                                -165.405591000175,
                                54.012302999823
                            ],
                            [
                                -165.41325100018,
                                54.0083059998061
                            ],
                            [
                                -165.468621999958,
                                53.9863999998753
                            ],
                            [
                                -165.519767000348,
                                53.9720599998002
                            ],
                            [
                                -165.563239000314,
                                53.967281999952
                            ],
                            [
                                -165.606712000105,
                                53.9768430001728
                            ],
                            [
                                -165.632287000311,
                                53.9975600002639
                            ],
                            [
                                -165.647174999602,
                                54.0169659998963
                            ],
                            [
                                -165.657172999881,
                                54.036336000234
                            ],
                            [
                                -165.667775000408,
                                54.0374360000444
                            ],
                            [
                                -165.691516999955,
                                54.033833999961
                            ],
                            [
                                -165.702671999777,
                                54.0341359998165
                            ],
                            [
                                -165.717517000309,
                                54.0250910000813
                            ],
                            [
                                -165.750107000264,
                                54.016607000075
                            ],
                            [
                                -165.775313000399,
                                54.0145239997875
                            ],
                            [
                                -165.788966000329,
                                54.0162360000244
                            ],
                            [
                                -165.828073000092,
                                53.9782269997673
                            ],
                            [
                                -165.886882999611,
                                53.9702600002421
                            ],
                            [
                                -165.961029999756,
                                53.9160800002458
                            ],
                            [
                                -165.955916000438,
                                53.9017380002026
                            ],
                            [
                                -165.938013999757,
                                53.8842050000432
                            ],
                            [
                                -165.922670000356,
                                53.8459569999332
                            ],
                            [
                                -165.930337000033,
                                53.8188690001615
                            ],
                            [
                                -165.976359999811,
                                53.77106100007
                            ],
                            [
                                -166.002126999638,
                                53.7570570002426
                            ],
                            [
                                -166.027954999564,
                                53.7525900001738
                            ],
                            [
                                -166.035797999867,
                                53.7467779998305
                            ],
                            [
                                -166.046233999814,
                                53.7458390002146
                            ],
                            [
                                -166.05331200021,
                                53.7391629998786
                            ],
                            [
                                -166.064082999893,
                                53.7324269997941
                            ],
                            [
                                -166.081516000046,
                                53.7254580002445
                            ],
                            [
                                -166.089193999594,
                                53.7098280001677
                            ],
                            [
                                -166.098622999785,
                                53.6996509999726
                            ],
                            [
                                -166.107379999957,
                                53.6946989998396
                            ],
                            [
                                -166.124741999965,
                                53.6910079997792
                            ],
                            [
                                -166.131088999892,
                                53.6830650002302
                            ],
                            [
                                -166.1484509999,
                                53.6719019999276
                            ],
                            [
                                -166.174938000325,
                                53.6597780001582
                            ],
                            [
                                -166.2101200001,
                                53.6546420001964
                            ],
                            [
                                -166.221105999867,
                                53.6453260001977
                            ],
                            [
                                -166.263308000313,
                                53.6339419999189
                            ],
                            [
                                -166.271606999827,
                                53.6296610002226
                            ],
                            [
                                -166.283050000427,
                                53.6269450001904
                            ],
                            [
                                -166.295591000121,
                                53.6262529998416
                            ],
                            [
                                -166.319973999726,
                                53.6169659998068
                            ],
                            [
                                -166.330135000212,
                                53.6146929997525
                            ],
                            [
                                -166.344446999742,
                                53.6146230000909
                            ],
                            [
                                -166.360468000084,
                                53.605581999933
                            ],
                            [
                                -166.378655000272,
                                53.6021410001847
                            ],
                            [
                                -166.498492000108,
                                53.5128579998975
                            ],
                            [
                                -166.52229399993,
                                53.4932880000904
                            ],
                            [
                                -166.542130999581,
                                53.4823840002472
                            ],
                            [
                                -166.581176000317,
                                53.4725439999272
                            ],
                            [
                                -166.579940000233,
                                53.4687940002319
                            ],
                            [
                                -166.580793999828,
                                53.4650479998351
                            ],
                            [
                                -166.589217000088,
                                53.4571589997374
                            ],
                            [
                                -166.701789999755,
                                53.4020330002301
                            ],
                            [
                                -166.724799000082,
                                53.3942039998335
                            ],
                            [
                                -166.741216000258,
                                53.391717000168
                            ],
                            [
                                -166.760267000087,
                                53.3947389998946
                            ],
                            [
                                -166.767705000334,
                                53.3905570000594
                            ],
                            [
                                -166.788669000289,
                                53.3838880001716
                            ],
                            [
                                -166.80353099964,
                                53.3815540001444
                            ],
                            [
                                -166.815248999702,
                                53.3823839997996
                            ],
                            [
                                -166.820100999978,
                                53.3848110000999
                            ],
                            [
                                -166.858124999817,
                                53.3787060002305
                            ],
                            [
                                -166.882904000155,
                                53.3775679998181
                            ],
                            [
                                -166.912934999903,
                                53.3814730002431
                            ],
                            [
                                -166.92736399961,
                                53.3851339998593
                            ],
                            [
                                -166.934512000327,
                                53.3814949998544
                            ],
                            [
                                -166.955934999866,
                                53.3752609997416
                            ],
                            [
                                -166.989565000088,
                                53.3732460002544
                            ],
                            [
                                -167.01608599995,
                                53.3757850000164
                            ],
                            [
                                -167.03217000004,
                                53.3791190002653
                            ],
                            [
                                -167.03625900006,
                                53.3817579998084
                            ],
                            [
                                -167.043127000394,
                                53.3763329998377
                            ],
                            [
                                -167.055028999681,
                                53.3724180000266
                            ],
                            [
                                -167.072486000123,
                                53.3727369997459
                            ],
                            [
                                -167.090735000235,
                                53.3671360001986
                            ],
                            [
                                -167.106758000226,
                                53.3669910002678
                            ],
                            [
                                -167.219879000064,
                                53.3255120000314
                            ],
                            [
                                -167.219103000288,
                                53.3212779999642
                            ],
                            [
                                -167.225724999677,
                                53.310833000014
                            ],
                            [
                                -167.23814599972,
                                53.2991580002511
                            ],
                            [
                                -167.246569999806,
                                53.2955479999725
                            ],
                            [
                                -167.255145000403,
                                53.2873299999204
                            ],
                            [
                                -167.287372000135,
                                53.2759539998927
                            ],
                            [
                                -167.328173999566,
                                53.2715890000816
                            ],
                            [
                                -167.353900000284,
                                53.2732060001564
                            ],
                            [
                                -167.361987000086,
                                53.2743049998248
                            ],
                            [
                                -167.364381000436,
                                53.2765050001578
                            ],
                            [
                                -167.372119000259,
                                53.2688180000741
                            ],
                            [
                                -167.386372000238,
                                53.260943999764
                            ],
                            [
                                -167.39680900001,
                                53.2567619998739
                            ],
                            [
                                -167.40418199996,
                                53.2580019998811
                            ],
                            [
                                -167.406396000385,
                                53.2497990001175
                            ],
                            [
                                -167.448576000191,
                                53.2139189998783
                            ],
                            [
                                -167.52214099983,
                                53.1860090001457
                            ],
                            [
                                -167.592511000125,
                                53.1740459998469
                            ],
                            [
                                -167.669277999678,
                                53.1720499999064
                            ],
                            [
                                -167.768440000163,
                                53.1820149999941
                            ],
                            [
                                -167.829213000037,
                                53.1919810002602
                            ],
                            [
                                -167.870793999618,
                                53.2059329998775
                            ],
                            [
                                -167.947561999895,
                                53.211911000199
                            ],
                            [
                                -168.010519999882,
                                53.2202690000125
                            ],
                            [
                                -168.026270000062,
                                53.2261369999078
                            ],
                            [
                                -168.044048999821,
                                53.2441869999569
                            ],
                            [
                                -168.059475999961,
                                53.2329120001271
                            ],
                            [
                                -168.068936999939,
                                53.2296989997845
                            ],
                            [
                                -168.082030000002,
                                53.2263709997842
                            ],
                            [
                                -168.132157999693,
                                53.2192820001227
                            ],
                            [
                                -168.136815999799,
                                53.2138329997567
                            ],
                            [
                                -168.156959999597,
                                53.2043099998086
                            ],
                            [
                                -168.151069999604,
                                53.1905139999641
                            ],
                            [
                                -168.155219999723,
                                53.1772440000056
                            ],
                            [
                                -168.169901000223,
                                53.1585949998135
                            ],
                            [
                                -168.196150000098,
                                53.1468580000325
                            ],
                            [
                                -168.216324999858,
                                53.1413700001255
                            ],
                            [
                                -168.232624999859,
                                53.1387749997843
                            ],
                            [
                                -168.251243000042,
                                53.1386910001055
                            ],
                            [
                                -168.257712999993,
                                53.1418950001314
                            ],
                            [
                                -168.27242600028,
                                53.1255030001783
                            ],
                            [
                                -168.277096000083,
                                53.1219770001868
                            ],
                            [
                                -168.281673999823,
                                53.1214129999378
                            ],
                            [
                                -168.281581999762,
                                53.1182839997327
                            ],
                            [
                                -168.291501000398,
                                53.1052500001347
                            ],
                            [
                                -168.302488999816,
                                53.096145999963
                            ],
                            [
                                -168.310850999977,
                                53.0900020000838
                            ],
                            [
                                -168.320008000182,
                                53.0859040000798
                            ],
                            [
                                -168.333986999627,
                                53.0831869999184
                            ],
                            [
                                -168.322174000029,
                                53.068643000051
                            ],
                            [
                                -168.320494000429,
                                53.0500709999397
                            ],
                            [
                                -168.323514999695,
                                53.0434169999495
                            ],
                            [
                                -168.329497999575,
                                53.0372659998732
                            ],
                            [
                                -168.340881999726,
                                53.030046999763
                            ],
                            [
                                -168.338073999996,
                                53.0293069999796
                            ],
                            [
                                -168.325161999683,
                                53.0172669998147
                            ],
                            [
                                -168.319270999865,
                                53.009240999797
                            ],
                            [
                                -168.312585999829,
                                52.9889510000557
                            ],
                            [
                                -168.314843999737,
                                52.9821459999624
                            ],
                            [
                                -168.31844499986,
                                52.9748879998448
                            ],
                            [
                                -168.325341999608,
                                52.9665400002307
                            ],
                            [
                                -168.337155000105,
                                52.9569249998157
                            ],
                            [
                                -168.353573999931,
                                52.9481710000464
                            ],
                            [
                                -168.36889599959,
                                52.9453550002047
                            ],
                            [
                                -168.373084000242,
                                52.9470279998601
                            ],
                            [
                                -168.376435000119,
                                52.9414629998768
                            ],
                            [
                                -168.402683999995,
                                52.9292529998647
                            ],
                            [
                                -168.430917000143,
                                52.9218420001601
                            ],
                            [
                                -168.455212999709,
                                52.9234049998585
                            ],
                            [
                                -168.476272999923,
                                52.9194830002619
                            ],
                            [
                                -168.522027000087,
                                52.9337330001522
                            ],
                            [
                                -168.544797000039,
                                52.9467120000374
                            ],
                            [
                                -168.557114000326,
                                52.958220000051
                            ],
                            [
                                -168.564697000337,
                                52.9514649999208
                            ],
                            [
                                -168.576021000214,
                                52.9470910002477
                            ],
                            [
                                -168.585176999696,
                                52.9464960001458
                            ],
                            [
                                -168.592013000243,
                                52.9374459997795
                            ],
                            [
                                -168.604419000218,
                                52.9354139999803
                            ],
                            [
                                -168.602481999802,
                                52.9234130000513
                            ],
                            [
                                -168.609684999872,
                                52.9157290002528
                            ],
                            [
                                -168.629248999711,
                                52.9051510001394
                            ],
                            [
                                -168.648293999692,
                                52.8986870000291
                            ],
                            [
                                -168.675826000406,
                                52.8947640000656
                            ],
                            [
                                -168.673261000351,
                                52.8919180000929
                            ],
                            [
                                -168.676495999877,
                                52.886415999794
                            ],
                            [
                                -168.689985999601,
                                52.8757400000602
                            ],
                            [
                                -168.743002000284,
                                52.8547309998319
                            ],
                            [
                                -168.769220000198,
                                52.8526760001512
                            ],
                            [
                                -168.819828000287,
                                52.8557559997688
                            ],
                            [
                                -168.827049999726,
                                52.858389999976
                            ],
                            [
                                -168.836279999725,
                                52.8490480000179
                            ],
                            [
                                -168.849464000025,
                                52.8409210001366
                            ],
                            [
                                -168.875163999905,
                                52.8306190000107
                            ],
                            [
                                -168.903580000351,
                                52.8272059997408
                            ],
                            [
                                -168.915208000001,
                                52.8209710000342
                            ],
                            [
                                -168.951375999615,
                                52.8075009997314
                            ],
                            [
                                -168.977656000351,
                                52.8056310000753
                            ],
                            [
                                -168.981074000175,
                                52.8016089998374
                            ],
                            [
                                -169.006221999686,
                                52.7855610002007
                            ],
                            [
                                -169.030061000216,
                                52.7794020000467
                            ],
                            [
                                -169.066077000392,
                                52.7760200002505
                            ],
                            [
                                -169.079384999642,
                                52.7779659999233
                            ],
                            [
                                -169.081705000373,
                                52.7712269997751
                            ],
                            [
                                -169.094492999939,
                                52.7639930000269
                            ],
                            [
                                -169.137589999987,
                                52.7447539998215
                            ],
                            [
                                -169.14073300035,
                                52.7410149999824
                            ],
                            [
                                -169.162007999751,
                                52.7326819998338
                            ],
                            [
                                -169.188836999585,
                                52.7246990000756
                            ],
                            [
                                -169.228058000049,
                                52.7146100002047
                            ],
                            [
                                -169.267616999723,
                                52.7162789999691
                            ],
                            [
                                -169.291456000253,
                                52.722648000268
                            ],
                            [
                                -169.310723999816,
                                52.7328469997397
                            ],
                            [
                                -169.319660999913,
                                52.7395409997613
                            ],
                            [
                                -169.326591999997,
                                52.753542000119
                            ],
                            [
                                -169.32631800036,
                                52.7653760000056
                            ],
                            [
                                -169.320551000391,
                                52.783720000184
                            ],
                            [
                                -169.312005000106,
                                52.7964860001063
                            ],
                            [
                                -169.294273000203,
                                52.8116259999955
                            ],
                            [
                                -169.263505999962,
                                52.8260109998032
                            ],
                            [
                                -169.18821200032,
                                52.850669999962
                            ],
                            [
                                -169.203561999568,
                                52.8535370001135
                            ],
                            [
                                -169.22676000004,
                                52.8611819998301
                            ],
                            [
                                -169.243152000102,
                                52.8713980001585
                            ],
                            [
                                -169.255424000183,
                                52.8844989997444
                            ],
                            [
                                -169.259484999715,
                                52.899720999839
                            ],
                            [
                                -169.258846000206,
                                52.9132810000759
                            ],
                            [
                                -169.254085000167,
                                52.9242920000552
                            ],
                            [
                                -169.246026999779,
                                52.9344790000318
                            ],
                            [
                                -169.23388000027,
                                52.9458950002455
                            ],
                            [
                                -169.218894999996,
                                52.9558540001936
                            ],
                            [
                                -169.197132999625,
                                52.9648819997484
                            ],
                            [
                                -169.164016000313,
                                52.9653730001229
                            ],
                            [
                                -169.129524000101,
                                52.9628870000512
                            ],
                            [
                                -169.109408999718,
                                52.9583900002171
                            ],
                            [
                                -169.094850000315,
                                52.9693059997858
                            ],
                            [
                                -169.082947000305,
                                52.9753349999835
                            ],
                            [
                                -169.065733000435,
                                52.9812869999698
                            ],
                            [
                                -169.044704000183,
                                52.985881999768
                            ],
                            [
                                -169.023336999821,
                                52.9870510002629
                            ],
                            [
                                -169.00987600041,
                                52.9856549998848
                            ],
                            [
                                -169.000092000392,
                                52.9827989998709
                            ],
                            [
                                -169.003346000185,
                                52.9965520001236
                            ],
                            [
                                -168.999471000426,
                                53.010608000113
                            ],
                            [
                                -168.992146999982,
                                53.0240910001387
                            ],
                            [
                                -168.980611000393,
                                53.0369959998224
                            ],
                            [
                                -168.965351999585,
                                53.0507469998655
                            ],
                            [
                                -168.952806999693,
                                53.058209999943
                            ],
                            [
                                -168.928176000391,
                                53.0660109998831
                            ],
                            [
                                -168.916152000007,
                                53.0675999999692
                            ],
                            [
                                -168.905589999664,
                                53.0655319997333
                            ],
                            [
                                -168.872782999973,
                                53.0817239998539
                            ],
                            [
                                -168.882457000387,
                                53.093706999963
                            ],
                            [
                                -168.890699999825,
                                53.1085469999962
                            ],
                            [
                                -168.890579000349,
                                53.1238769997998
                            ],
                            [
                                -168.887343999924,
                                53.1327750002491
                            ],
                            [
                                -168.880600000337,
                                53.1409469998006
                            ],
                            [
                                -168.883101999744,
                                53.1436709997861
                            ],
                            [
                                -168.883071999607,
                                53.1501719997457
                            ],
                            [
                                -168.868666000365,
                                53.1769499999362
                            ],
                            [
                                -168.859448999885,
                                53.1881520001405
                            ],
                            [
                                -168.832071000056,
                                53.207392000025
                            ],
                            [
                                -168.829750000398,
                                53.2125269999618
                            ],
                            [
                                -168.819434000102,
                                53.2216769997448
                            ],
                            [
                                -168.806462000412,
                                53.2306060001476
                            ],
                            [
                                -168.78137199973,
                                53.2406500002478
                            ],
                            [
                                -168.749413999611,
                                53.2664980000103
                            ],
                            [
                                -168.739797999822,
                                53.2714809997415
                            ],
                            [
                                -168.717605000353,
                                53.2798540001927
                            ],
                            [
                                -168.699054000117,
                                53.2803709999462
                            ],
                            [
                                -168.694069999597,
                                53.2970540001901
                            ],
                            [
                                -168.689337000046,
                                53.303272999894
                            ],
                            [
                                -168.666870000043,
                                53.3152239999163
                            ],
                            [
                                -168.655819999802,
                                53.3197499998988
                            ],
                            [
                                -168.61943399973,
                                53.327161000048
                            ],
                            [
                                -168.504296000009,
                                53.3628890000474
                            ],
                            [
                                -168.496573000255,
                                53.3664820000561
                            ],
                            [
                                -168.488453999767,
                                53.3754249999848
                            ],
                            [
                                -168.477601999894,
                                53.3786909997335
                            ],
                            [
                                -168.486836999917,
                                53.3901440000693
                            ],
                            [
                                -168.496359999819,
                                53.4210760001464
                            ],
                            [
                                -168.488637000065,
                                53.4357789998173
                            ],
                            [
                                -168.476672000131,
                                53.4505959998999
                            ],
                            [
                                -168.456374000348,
                                53.4680850000716
                            ],
                            [
                                -168.425239999687,
                                53.4863130002425
                            ],
                            [
                                -168.415259000024,
                                53.5094469998378
                            ],
                            [
                                -168.39636600038,
                                53.5194050002497
                            ],
                            [
                                -168.364010999703,
                                53.5303310002011
                            ],
                            [
                                -168.351710000208,
                                53.5302620002077
                            ],
                            [
                                -168.334434000414,
                                53.5386710001306
                            ],
                            [
                                -168.318173999698,
                                53.5395049999445
                            ],
                            [
                                -168.315389000433,
                                53.5465530002649
                            ],
                            [
                                -168.309131000194,
                                53.5556090000235
                            ],
                            [
                                -168.284012000098,
                                53.5711369998
                            ],
                            [
                                -168.250284999792,
                                53.5776990001137
                            ],
                            [
                                -168.223609000119,
                                53.5875040002372
                            ],
                            [
                                -168.206791000085,
                                53.5903799999217
                            ],
                            [
                                -168.185396000134,
                                53.5986520001849
                            ],
                            [
                                -168.162902000367,
                                53.5978669998555
                            ],
                            [
                                -168.151517000391,
                                53.6039319998143
                            ],
                            [
                                -168.090351999981,
                                53.6196669997925
                            ],
                            [
                                -168.069873000448,
                                53.6200040000241
                            ],
                            [
                                -168.053453999723,
                                53.6159069999199
                            ],
                            [
                                -168.040329999698,
                                53.6102759998592
                            ],
                            [
                                -167.992351999961,
                                53.6149549999982
                            ],
                            [
                                -167.959881999656,
                                53.6128120000072
                            ],
                            [
                                -167.948346000067,
                                53.6103860000941
                            ],
                            [
                                -167.925641000413,
                                53.6019479998437
                            ],
                            [
                                -167.901349000146,
                                53.5874829998404
                            ],
                            [
                                -167.889234000424,
                                53.5782280000418
                            ],
                            [
                                -167.885136000184,
                                53.5714190000028
                            ],
                            [
                                -167.882063000141,
                                53.570575999735
                            ],
                            [
                                -167.875044000194,
                                53.569050000081
                            ],
                            [
                                -167.804238999706,
                                53.5718070001249
                            ],
                            [
                                -167.762305999772,
                                53.5680240000456
                            ],
                            [
                                -167.730900000023,
                                53.5564269999171
                            ],
                            [
                                -167.718226000259,
                                53.548623000229
                            ],
                            [
                                -167.704439000435,
                                53.5334390000231
                            ],
                            [
                                -167.698212999983,
                                53.5242230000993
                            ],
                            [
                                -167.695985000212,
                                53.514609000153
                            ],
                            [
                                -167.697328000426,
                                53.5020879999527
                            ],
                            [
                                -167.701142999911,
                                53.4876290001517
                            ],
                            [
                                -167.719149000349,
                                53.4623119998447
                            ],
                            [
                                -167.730624999663,
                                53.4508670000409
                            ],
                            [
                                -167.761227999872,
                                53.4316119998586
                            ],
                            [
                                -167.752427000217,
                                53.4215120000805
                            ],
                            [
                                -167.746769000028,
                                53.4263350001992
                            ],
                            [
                                -167.727724000047,
                                53.4340270000813
                            ],
                            [
                                -167.710142999757,
                                53.4369269997721
                            ],
                            [
                                -167.673579000308,
                                53.4382320000324
                            ],
                            [
                                -167.656365000438,
                                53.4421549998525
                            ],
                            [
                                -167.648124999577,
                                53.4420940000114
                            ],
                            [
                                -167.60802000028,
                                53.4580569999095
                            ],
                            [
                                -167.59144699957,
                                53.4603239999322
                            ],
                            [
                                -167.581771000404,
                                53.4592330002434
                            ],
                            [
                                -167.574323999937,
                                53.4627510001246
                            ],
                            [
                                -167.555202999788,
                                53.4654210000007
                            ],
                            [
                                -167.554180000139,
                                53.4693280001889
                            ],
                            [
                                -167.542093999831,
                                53.4785300002338
                            ],
                            [
                                -167.502659999831,
                                53.4953179999842
                            ],
                            [
                                -167.482730000294,
                                53.4976150001349
                            ],
                            [
                                -167.453614000239,
                                53.4955710000629
                            ],
                            [
                                -167.444153000261,
                                53.4933280000292
                            ],
                            [
                                -167.422390000064,
                                53.4981270000452
                            ],
                            [
                                -167.411831999919,
                                53.5153330000076
                            ],
                            [
                                -167.392665999564,
                                53.5282589997905
                            ],
                            [
                                -167.360468000144,
                                53.54007000002
                            ],
                            [
                                -167.341148999629,
                                53.5435949998017
                            ],
                            [
                                -167.320548999722,
                                53.5433060002186
                            ],
                            [
                                -167.29628199957,
                                53.5397310001322
                            ],
                            [
                                -167.230380000309,
                                53.5667340000418
                            ],
                            [
                                -167.234882999706,
                                53.5717349999478
                            ],
                            [
                                -167.239370000109,
                                53.5832489999741
                            ],
                            [
                                -167.247030999939,
                                53.6146920000474
                            ],
                            [
                                -167.244377000195,
                                53.6294180001814
                            ],
                            [
                                -167.233848000362,
                                53.6441119999762
                            ],
                            [
                                -167.215231999828,
                                53.6582039998603
                            ],
                            [
                                -167.171866000167,
                                53.6742960000124
                            ],
                            [
                                -167.155683000343,
                                53.6783400001408
                            ],
                            [
                                -167.156789999657,
                                53.6872589998974
                            ],
                            [
                                -167.153921999654,
                                53.6936220000236
                            ],
                            [
                                -167.143270999622,
                                53.7070050000569
                            ],
                            [
                                -167.138204999984,
                                53.7094230001507
                            ],
                            [
                                -167.164880999657,
                                53.7738420002589
                            ],
                            [
                                -167.182277999825,
                                53.7847840000548
                            ],
                            [
                                -167.218078999916,
                                53.8017760000893
                            ],
                            [
                                -167.234070999945,
                                53.8142430002512
                            ],
                            [
                                -167.233552999911,
                                53.8257950001894
                            ],
                            [
                                -167.23831399995,
                                53.8311050002044
                            ],
                            [
                                -167.243502999611,
                                53.8426189997704
                            ],
                            [
                                -167.242893000414,
                                53.85467300023
                            ],
                            [
                                -167.237003000421,
                                53.8652329997615
                            ],
                            [
                                -167.23007700036,
                                53.88825200002
                            ],
                            [
                                -167.212072999572,
                                53.9005809998189
                            ],
                            [
                                -167.181218000366,
                                53.9091579998478
                            ],
                            [
                                -167.176296999595,
                                53.9118539998151
                            ],
                            [
                                -167.173986999808,
                                53.9216470000327
                            ],
                            [
                                -167.174170000106,
                                53.9321149997675
                            ],
                            [
                                -167.168891000034,
                                53.9432379999474
                            ],
                            [
                                -167.160743000132,
                                53.9538970001626
                            ],
                            [
                                -167.144629999729,
                                53.9632130001033
                            ],
                            [
                                -167.106024000283,
                                53.9752590000466
                            ],
                            [
                                -167.095697999941,
                                53.9758539997374
                            ],
                            [
                                -167.091528999555,
                                53.9859400002051
                            ],
                            [
                                -167.076637000066,
                                53.9983220000474
                            ],
                            [
                                -167.053992999613,
                                54.0086130001037
                            ],
                            [
                                -167.024695999809,
                                54.0148839998707
                            ],
                            [
                                -167.009097999965,
                                54.0150509999865
                            ],
                            [
                                -167.001806000206,
                                54.0210700000452
                            ],
                            [
                                -166.98868399983,
                                54.0239460001439
                            ],
                            [
                                -166.94592900009,
                                54.0276669997379
                            ],
                            [
                                -166.936528000386,
                                54.0271560000708
                            ],
                            [
                                -166.925054999648,
                                54.034647000234
                            ],
                            [
                                -166.910405999833,
                                54.0400170001391
                            ],
                            [
                                -166.880041000174,
                                54.044989999853
                            ],
                            [
                                -166.820198000062,
                                54.0480410000686
                            ],
                            [
                                -166.81485600024,
                                54.0534540001739
                            ],
                            [
                                -166.785193999665,
                                54.0659790001238
                            ],
                            [
                                -166.755804999799,
                                54.068823999895
                            ],
                            [
                                -166.692420000014,
                                54.0672859997416
                            ],
                            [
                                -166.511145999781,
                                54.0577900001672
                            ],
                            [
                                -166.462530000359,
                                54.0607200000025
                            ],
                            [
                                -166.354438000349,
                                54.061109999924
                            ],
                            [
                                -166.336681000331,
                                54.0585769997774
                            ],
                            [
                                -166.305071000366,
                                54.0510560001439
                            ],
                            [
                                -166.294787999682,
                                54.0450740002349
                            ],
                            [
                                -166.287281999663,
                                54.038215999787
                            ],
                            [
                                -166.286412000175,
                                54.0337310000345
                            ],
                            [
                                -166.27510800039,
                                54.03597299986
                            ],
                            [
                                -166.261561999692,
                                54.0350730000508
                            ],
                            [
                                -166.250943000345,
                                54.0334630000236
                            ],
                            [
                                -166.243221000416,
                                54.0302320001456
                            ],
                            [
                                -166.236052999608,
                                54.035402000252
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -178.256047000343,
                                51.8120170000862
                            ],
                            [
                                -178.284951999612,
                                51.833342000174
                            ],
                            [
                                -178.297649999665,
                                51.8452290000915
                            ],
                            [
                                -178.307052000092,
                                51.8584369998706
                            ],
                            [
                                -178.30947999988,
                                51.8710590001285
                            ],
                            [
                                -178.307786999861,
                                51.8816079998067
                            ],
                            [
                                -178.298847000289,
                                51.9037050002487
                            ],
                            [
                                -178.274980999995,
                                51.923886999993
                            ],
                            [
                                -178.258409999833,
                                51.9421299998264
                            ],
                            [
                                -178.243699000094,
                                51.9519970002194
                            ],
                            [
                                -178.228439000359,
                                51.9568719997905
                            ],
                            [
                                -178.196209000254,
                                51.9604749998033
                            ],
                            [
                                -178.157782000009,
                                51.9689830000287
                            ],
                            [
                                -178.107240000042,
                                51.9765069997417
                            ],
                            [
                                -178.086881999984,
                                51.9754389998548
                            ],
                            [
                                -178.072199999659,
                                51.9700139999486
                            ],
                            [
                                -178.064958000129,
                                51.9647710002372
                            ],
                            [
                                -178.050347999774,
                                51.9704870000715
                            ],
                            [
                                -178.032430999923,
                                51.9726539997617
                            ],
                            [
                                -178.007434000025,
                                51.969778000254
                            ],
                            [
                                -178.002250000386,
                                51.9656710000209
                            ],
                            [
                                -177.99592699985,
                                51.9683740002156
                            ],
                            [
                                -177.98173499997,
                                51.9707480000833
                            ],
                            [
                                -177.964947999898,
                                51.9718230000348
                            ],
                            [
                                -177.947002999559,
                                51.9713120001266
                            ],
                            [
                                -177.927956999753,
                                51.9685129997641
                            ],
                            [
                                -177.913246999839,
                                51.9643630002342
                            ],
                            [
                                -177.889927000066,
                                51.952574999823
                            ],
                            [
                                -177.878634000152,
                                51.9439840000698
                            ],
                            [
                                -177.870667999826,
                                51.9357669997316
                            ],
                            [
                                -177.865783999763,
                                51.9285489999878
                            ],
                            [
                                -177.865532999693,
                                51.9257720000713
                            ],
                            [
                                -177.84670799982,
                                51.9224839997602
                            ],
                            [
                                -177.834499000387,
                                51.9140690000701
                            ],
                            [
                                -177.826806999697,
                                51.8964139998588
                            ],
                            [
                                -177.816887999959,
                                51.8946670000024
                            ],
                            [
                                -177.811926000079,
                                51.8907720000269
                            ],
                            [
                                -177.799797000113,
                                51.8941189999259
                            ],
                            [
                                -177.773427999688,
                                51.8957070000924
                            ],
                            [
                                -177.759358000007,
                                51.8947909998613
                            ],
                            [
                                -177.735429999789,
                                51.8884069999997
                            ],
                            [
                                -177.727830999883,
                                51.8919539998782
                            ],
                            [
                                -177.70393500035,
                                51.8971669999414
                            ],
                            [
                                -177.67359999993,
                                51.8990450002468
                            ],
                            [
                                -177.661666999783,
                                51.9022199998882
                            ],
                            [
                                -177.637709000326,
                                51.9009540000447
                            ],
                            [
                                -177.614911999712,
                                51.9049999997525
                            ],
                            [
                                -177.577067999798,
                                51.9011869997675
                            ],
                            [
                                -177.557534999921,
                                51.8918800001244
                            ],
                            [
                                -177.538367999741,
                                51.8731270002657
                            ],
                            [
                                -177.534857999855,
                                51.8659479998833
                            ],
                            [
                                -177.532261999838,
                                51.8550990002305
                            ],
                            [
                                -177.533085000369,
                                51.8423490000272
                            ],
                            [
                                -177.53592200041,
                                51.8365580001569
                            ],
                            [
                                -177.542004999849,
                                51.8331830000239
                            ],
                            [
                                -177.538576000154,
                                51.8282490001319
                            ],
                            [
                                -177.538270999657,
                                51.8209319997287
                            ],
                            [
                                -177.553862999653,
                                51.8005440000074
                            ],
                            [
                                -177.564301999973,
                                51.7959799998098
                            ],
                            [
                                -177.548459999731,
                                51.7926320002299
                            ],
                            [
                                -177.54205099988,
                                51.7882679999705
                            ],
                            [
                                -177.525692000328,
                                51.7915650000348
                            ],
                            [
                                -177.515315999757,
                                51.7919699999917
                            ],
                            [
                                -177.506924000357,
                                51.7978540000547
                            ],
                            [
                                -177.488705000381,
                                51.8048739999829
                            ],
                            [
                                -177.467952000313,
                                51.8088189999715
                            ],
                            [
                                -177.445697000022,
                                51.8094859998112
                            ],
                            [
                                -177.444941000337,
                                51.811970999799
                            ],
                            [
                                -177.435024000249,
                                51.820715000006
                            ],
                            [
                                -177.423640999923,
                                51.8286579998239
                            ],
                            [
                                -177.406215000341,
                                51.8362350002046
                            ],
                            [
                                -177.386987999887,
                                51.8389599999974
                            ],
                            [
                                -177.363733000412,
                                51.8380379999459
                            ],
                            [
                                -177.346947000165,
                                51.8354820001815
                            ],
                            [
                                -177.34163700013,
                                51.8322470001411
                            ],
                            [
                                -177.324485000184,
                                51.8320880000108
                            ],
                            [
                                -177.308894000013,
                                51.8367930000513
                            ],
                            [
                                -177.298303000256,
                                51.8562070002205
                            ],
                            [
                                -177.289330999999,
                                51.8621879999837
                            ],
                            [
                                -177.28289200001,
                                51.8630730002331
                            ],
                            [
                                -177.281551000344,
                                51.875021999939
                            ],
                            [
                                -177.285304999729,
                                51.8915329998105
                            ],
                            [
                                -177.284360999723,
                                51.9131480002559
                            ],
                            [
                                -177.275605000274,
                                51.9433079997781
                            ],
                            [
                                -177.267458000198,
                                51.9596280000786
                            ],
                            [
                                -177.248932999901,
                                51.9734690000836
                            ],
                            [
                                -177.229523999872,
                                51.9837170002003
                            ],
                            [
                                -177.206209000122,
                                51.9931100000388
                            ],
                            [
                                -177.186249000448,
                                51.9975130000457
                            ],
                            [
                                -177.168059999711,
                                51.9995119998921
                            ],
                            [
                                -177.148900999928,
                                51.9984050001178
                            ],
                            [
                                -177.119717000101,
                                51.9926629999943
                            ],
                            [
                                -177.096461999728,
                                51.9901460000801
                            ],
                            [
                                -177.03987800033,
                                51.9748809999137
                            ],
                            [
                                -177.016895999768,
                                51.9650319997768
                            ],
                            [
                                -177.004191000042,
                                51.9506090000672
                            ],
                            [
                                -176.983689999869,
                                51.9402670001955
                            ],
                            [
                                -176.97004799981,
                                51.924504999888
                            ],
                            [
                                -176.967604999954,
                                51.915815000021
                            ],
                            [
                                -176.967238000431,
                                51.8894620001779
                            ],
                            [
                                -176.970534000057,
                                51.8816260000655
                            ],
                            [
                                -176.975233999996,
                                51.8761329998618
                            ],
                            [
                                -176.970985000143,
                                51.8611069999369
                            ],
                            [
                                -176.954817000387,
                                51.8747299999619
                            ],
                            [
                                -176.938703000159,
                                51.8827489998924
                            ],
                            [
                                -176.91886599961,
                                51.882132000077
                            ],
                            [
                                -176.91019899985,
                                51.8896329999119
                            ],
                            [
                                -176.897411000284,
                                51.9070130000199
                            ],
                            [
                                -176.884806999942,
                                51.9409199998911
                            ],
                            [
                                -176.875530000086,
                                51.9596740000688
                            ],
                            [
                                -176.847819000171,
                                51.9955170000747
                            ],
                            [
                                -176.827005999828,
                                52.0091289998851
                            ],
                            [
                                -176.802469000238,
                                52.0166679997615
                            ],
                            [
                                -176.75879600043,
                                52.0180199997414
                            ],
                            [
                                -176.738625999795,
                                52.0208040001795
                            ],
                            [
                                -176.691486999584,
                                52.0177180001945
                            ],
                            [
                                -176.690566999867,
                                52.0204610001883
                            ],
                            [
                                -176.681870999795,
                                52.0285720000948
                            ],
                            [
                                -176.661916000143,
                                52.0400920001456
                            ],
                            [
                                -176.601279999638,
                                52.0565600000142
                            ],
                            [
                                -176.584434000014,
                                52.0562940002259
                            ],
                            [
                                -176.541188000004,
                                52.0508799998277
                            ],
                            [
                                -176.510058000439,
                                52.039361999805
                            ],
                            [
                                -176.488479000365,
                                52.0260420002413
                            ],
                            [
                                -176.48149100038,
                                52.0181919997875
                            ],
                            [
                                -176.475018999881,
                                52.0028500002509
                            ],
                            [
                                -176.469554000035,
                                51.9812209999628
                            ],
                            [
                                -176.472908000286,
                                51.9635650002452
                            ],
                            [
                                -176.452823999864,
                                51.9440119998719
                            ],
                            [
                                -176.448946999557,
                                51.9333080001479
                            ],
                            [
                                -176.447846999915,
                                51.9211850001116
                            ],
                            [
                                -176.452771000161,
                                51.9079310000008
                            ],
                            [
                                -176.42913699967,
                                51.9153500000064
                            ],
                            [
                                -176.365658999999,
                                51.9184270000222
                            ],
                            [
                                -176.33989999967,
                                51.9162619999747
                            ],
                            [
                                -176.325983999974,
                                51.9223739998862
                            ],
                            [
                                -176.301966000242,
                                51.9224369998471
                            ],
                            [
                                -176.269310000165,
                                51.9189659998958
                            ],
                            [
                                -176.242863999747,
                                51.9267419998893
                            ],
                            [
                                -176.251979000119,
                                51.9483249999775
                            ],
                            [
                                -176.252955999737,
                                51.9579839998888
                            ],
                            [
                                -176.247127999668,
                                51.9653319997812
                            ],
                            [
                                -176.254453999762,
                                51.9724040002499
                            ],
                            [
                                -176.265534999965,
                                51.9873189997355
                            ],
                            [
                                -176.268465999717,
                                51.9989919998817
                            ],
                            [
                                -176.268863000276,
                                52.0031120001668
                            ],
                            [
                                -176.267474999856,
                                52.005441000135
                            ],
                            [
                                -176.272769999822,
                                52.0103360002014
                            ],
                            [
                                -176.279911999793,
                                52.0234049999677
                            ],
                            [
                                -176.281074000257,
                                52.0433629999341
                            ],
                            [
                                -176.291419999792,
                                52.0533569997484
                            ],
                            [
                                -176.293130000428,
                                52.0654269997651
                            ],
                            [
                                -176.285380999836,
                                52.0940830001892
                            ],
                            [
                                -176.278241000414,
                                52.1035429997411
                            ],
                            [
                                -176.224433999885,
                                52.1531259998512
                            ],
                            [
                                -176.207677999774,
                                52.1569490000003
                            ],
                            [
                                -176.169129000229,
                                52.1695770001468
                            ],
                            [
                                -176.148375000336,
                                52.1714650001063
                            ],
                            [
                                -176.116145000231,
                                52.165212999747
                            ],
                            [
                                -176.093137999554,
                                52.1645500002285
                            ],
                            [
                                -176.08059500021,
                                52.1590430000797
                            ],
                            [
                                -176.03988900014,
                                52.1622029998151
                            ],
                            [
                                -176.027744000281,
                                52.1601749999524
                            ],
                            [
                                -175.991918000076,
                                52.1496709999297
                            ],
                            [
                                -175.977085000138,
                                52.1412859999699
                            ],
                            [
                                -175.967197000362,
                                52.1267369999345
                            ],
                            [
                                -175.966860999903,
                                52.1214180001919
                            ],
                            [
                                -175.9539820001,
                                52.1162379998064
                            ],
                            [
                                -175.93533200013,
                                52.1049160002338
                            ],
                            [
                                -175.93008200037,
                                52.0995829999686
                            ],
                            [
                                -175.927610000201,
                                52.0949439999346
                            ],
                            [
                                -175.92776900021,
                                52.0910679997727
                            ],
                            [
                                -175.895199000346,
                                52.0969289998318
                            ],
                            [
                                -175.853662000248,
                                52.096526000202
                            ],
                            [
                                -175.830283000025,
                                52.0853939998187
                            ],
                            [
                                -175.821552999618,
                                52.071333000031
                            ],
                            [
                                -175.811997999928,
                                52.0479480001424
                            ],
                            [
                                -175.806456999914,
                                52.0436690000522
                            ],
                            [
                                -175.795333999879,
                                52.0450029999733
                            ],
                            [
                                -175.778242000208,
                                52.040120999729
                            ],
                            [
                                -175.739327999891,
                                52.0212999998264
                            ],
                            [
                                -175.713202999864,
                                52.0262979998835
                            ],
                            [
                                -175.686435999955,
                                52.0232930000039
                            ],
                            [
                                -175.677585000072,
                                52.0193030001989
                            ],
                            [
                                -175.670597000087,
                                52.0212040002527
                            ],
                            [
                                -175.647675999624,
                                52.0221510001566
                            ],
                            [
                                -175.636077000286,
                                52.017512000231
                            ],
                            [
                                -175.611478999698,
                                52.0182149998593
                            ],
                            [
                                -175.598874000429,
                                52.0125320000954
                            ],
                            [
                                -175.572321999707,
                                52.0145320000338
                            ],
                            [
                                -175.5599919999,
                                52.0137609998238
                            ],
                            [
                                -175.549135000003,
                                52.0098789997829
                            ],
                            [
                                -175.517694000093,
                                52.0283820001482
                            ],
                            [
                                -175.4937669997,
                                52.0368379999801
                            ],
                            [
                                -175.470359999889,
                                52.0428349999608
                            ],
                            [
                                -175.441213999697,
                                52.0431259998763
                            ],
                            [
                                -175.412429999903,
                                52.0359859998085
                            ],
                            [
                                -175.401814000032,
                                52.049719999847
                            ],
                            [
                                -175.384328000175,
                                52.0632949999686
                            ],
                            [
                                -175.364459999664,
                                52.0720469997875
                            ],
                            [
                                -175.341021999891,
                                52.0775029999878
                            ],
                            [
                                -175.32667699985,
                                52.0758330002065
                            ],
                            [
                                -175.321275999578,
                                52.0800669999733
                            ],
                            [
                                -175.312883000353,
                                52.0813119999889
                            ],
                            [
                                -175.296616999789,
                                52.0888200002327
                            ],
                            [
                                -175.273147000228,
                                52.0921469999855
                            ],
                            [
                                -175.231624000374,
                                52.0901960000636
                            ],
                            [
                                -175.221082000122,
                                52.0965750002473
                            ],
                            [
                                -175.193096999671,
                                52.1043579997777
                            ],
                            [
                                -175.155130999559,
                                52.1094559998605
                            ],
                            [
                                -175.116249999752,
                                52.1099690001598
                            ],
                            [
                                -175.102363000369,
                                52.1086190001165
                            ],
                            [
                                -175.083930000133,
                                52.1256799998594
                            ],
                            [
                                -175.05942500033,
                                52.1366140001699
                            ],
                            [
                                -175.026890999553,
                                52.1384239998521
                            ],
                            [
                                -174.993214000374,
                                52.136408000079
                            ],
                            [
                                -174.964541999835,
                                52.1543269998458
                            ],
                            [
                                -174.925233000407,
                                52.1639100002296
                            ],
                            [
                                -174.896698000135,
                                52.1662460002321
                            ],
                            [
                                -174.866087000429,
                                52.1656370001794
                            ],
                            [
                                -174.853725999761,
                                52.1621579997919
                            ],
                            [
                                -174.849851000002,
                                52.1554279997872
                            ],
                            [
                                -174.827113999662,
                                52.1598159999357
                            ],
                            [
                                -174.796625000155,
                                52.1534699999962
                            ],
                            [
                                -174.794703999632,
                                52.1502149999432
                            ],
                            [
                                -174.77410100025,
                                52.1585980001157
                            ],
                            [
                                -174.759818999958,
                                52.1671499999314
                            ],
                            [
                                -174.731558000221,
                                52.1714469998821
                            ],
                            [
                                -174.718558000045,
                                52.1901250001887
                            ],
                            [
                                -174.696127999851,
                                52.2093599998487
                            ],
                            [
                                -174.668508999997,
                                52.2252009997874
                            ],
                            [
                                -174.642445999894,
                                52.2313970000203
                            ],
                            [
                                -174.617756000143,
                                52.2333739998799
                            ],
                            [
                                -174.603168000428,
                                52.2325809999883
                            ],
                            [
                                -174.588998000289,
                                52.228725000141
                            ],
                            [
                                -174.575427000375,
                                52.2425690002234
                            ],
                            [
                                -174.553484000253,
                                52.2594930001337
                            ],
                            [
                                -174.524583000284,
                                52.2676270002483
                            ],
                            [
                                -174.504441000136,
                                52.267369000042
                            ],
                            [
                                -174.494395000177,
                                52.2653299997527
                            ],
                            [
                                -174.505782999627,
                                52.2731590000724
                            ],
                            [
                                -174.517808999661,
                                52.2844970002487
                            ],
                            [
                                -174.537648999685,
                                52.3170359998439
                            ],
                            [
                                -174.536886000327,
                                52.3288630000231
                            ],
                            [
                                -174.524862999768,
                                52.3481660002464
                            ],
                            [
                                -174.513143999881,
                                52.3574669998642
                            ],
                            [
                                -174.494712000369,
                                52.3669510002394
                            ],
                            [
                                -174.468831999666,
                                52.3729420000031
                            ],
                            [
                                -174.410698000365,
                                52.3764370000728
                            ],
                            [
                                -174.406390999887,
                                52.3907900001688
                            ],
                            [
                                -174.390767000104,
                                52.4099100000953
                            ],
                            [
                                -174.375812999793,
                                52.4217740002259
                            ],
                            [
                                -174.355731999744,
                                52.4264669999486
                            ],
                            [
                                -174.352130999622,
                                52.4317160002657
                            ],
                            [
                                -174.328846999834,
                                52.4506990000001
                            ],
                            [
                                -174.312732999606,
                                52.4567119998512
                            ],
                            [
                                -174.26619299962,
                                52.4582920002027
                            ],
                            [
                                -174.255052999866,
                                52.4576969997567
                            ],
                            [
                                -174.244997999686,
                                52.4548430000533
                            ],
                            [
                                -174.238054999907,
                                52.4583540000617
                            ],
                            [
                                -174.222918000195,
                                52.4615740000153
                            ],
                            [
                                -174.207933999747,
                                52.4671279998075
                            ],
                            [
                                -174.186419000146,
                                52.4716609997782
                            ],
                            [
                                -174.157854999562,
                                52.4730199998137
                            ],
                            [
                                -174.132768999975,
                                52.4722720000127
                            ],
                            [
                                -174.110582000355,
                                52.4683209999406
                            ],
                            [
                                -174.078994999957,
                                52.4535960001101
                            ],
                            [
                                -174.065748999733,
                                52.445654000064
                            ],
                            [
                                -174.06539499973,
                                52.4426469997994
                            ],
                            [
                                -174.055526000221,
                                52.4438149998416
                            ],
                            [
                                -174.030867000432,
                                52.4393829999466
                            ],
                            [
                                -173.995403999552,
                                52.4243159998798
                            ],
                            [
                                -173.987224999688,
                                52.4186239998588
                            ],
                            [
                                -173.985942999573,
                                52.4121160001717
                            ],
                            [
                                -173.964243999849,
                                52.4072330000784
                            ],
                            [
                                -173.940226000117,
                                52.3962559998794
                            ],
                            [
                                -173.922920000186,
                                52.3780670001226
                            ],
                            [
                                -173.918156999599,
                                52.3629070000054
                            ],
                            [
                                -173.918523000196,
                                52.3543309998563
                            ],
                            [
                                -173.921785000384,
                                52.3474479998336
                            ],
                            [
                                -173.908328000273,
                                52.3322899999929
                            ],
                            [
                                -173.902834000115,
                                52.319227999934
                            ],
                            [
                                -173.902373999808,
                                52.3003609998368
                            ],
                            [
                                -173.904631999716,
                                52.2936609998758
                            ],
                            [
                                -173.921781000186,
                                52.2678349998541
                            ],
                            [
                                -173.948206999614,
                                52.2445260000229
                            ],
                            [
                                -173.955592999983,
                                52.2399639997993
                            ],
                            [
                                -173.964654999753,
                                52.2239560001768
                            ],
                            [
                                -173.973291000449,
                                52.2156099999643
                            ],
                            [
                                -173.980492999796,
                                52.2116179999573
                            ],
                            [
                                -173.982673999711,
                                52.2080429998543
                            ],
                            [
                                -173.984093000094,
                                52.1957190000154
                            ],
                            [
                                -173.986869000036,
                                52.1875169998812
                            ],
                            [
                                -173.990471999808,
                                52.1831429998727
                            ],
                            [
                                -173.981314999604,
                                52.1808260002208
                            ],
                            [
                                -173.956931000174,
                                52.1844280002314
                            ],
                            [
                                -173.936392000366,
                                52.1845590000657
                            ],
                            [
                                -173.898581999889,
                                52.1990100002375
                            ],
                            [
                                -173.871816999629,
                                52.2028950001527
                            ],
                            [
                                -173.840596999653,
                                52.2013250002738
                            ],
                            [
                                -173.785069000414,
                                52.1855500002132
                            ],
                            [
                                -173.732103999784,
                                52.1832539998618
                            ],
                            [
                                -173.709491000191,
                                52.1866730001329
                            ],
                            [
                                -173.638567999702,
                                52.2060689997417
                            ],
                            [
                                -173.601671999992,
                                52.2125319997509
                            ],
                            [
                                -173.587327999776,
                                52.2122199998143
                            ],
                            [
                                -173.570878999812,
                                52.2070009999008
                            ],
                            [
                                -173.565141000155,
                                52.2036610000351
                            ],
                            [
                                -173.540848999889,
                                52.2091159999179
                            ],
                            [
                                -173.506303000149,
                                52.2131379997913
                            ],
                            [
                                -173.478989999718,
                                52.20710500023
                            ],
                            [
                                -173.45570400028,
                                52.1916800001628
                            ],
                            [
                                -173.445084000211,
                                52.1727070001925
                            ],
                            [
                                -173.440764000213,
                                52.1687640002287
                            ],
                            [
                                -173.41456600039,
                                52.1697100001818
                            ],
                            [
                                -173.405136000374,
                                52.1667809999661
                            ],
                            [
                                -173.381638000326,
                                52.1692849999923
                            ],
                            [
                                -173.34297300043,
                                52.1665250002409
                            ],
                            [
                                -173.309768000356,
                                52.1549979999157
                            ],
                            [
                                -173.283156000257,
                                52.161263000043
                            ],
                            [
                                -173.257155000078,
                                52.1613470002325
                            ],
                            [
                                -173.224066000356,
                                52.1556740002195
                            ],
                            [
                                -173.177816999724,
                                52.1618089998184
                            ],
                            [
                                -173.151511999772,
                                52.161588999774
                            ],
                            [
                                -173.123712999992,
                                52.1604529998027
                            ],
                            [
                                -173.045286000032,
                                52.1527580002655
                            ],
                            [
                                -172.974303999992,
                                52.1496400000338
                            ],
                            [
                                -172.943680999691,
                                52.1465849999394
                            ],
                            [
                                -172.941314999828,
                                52.1489160002548
                            ],
                            [
                                -172.925173000011,
                                52.1552110000136
                            ],
                            [
                                -172.894870000276,
                                52.1555020001767
                            ],
                            [
                                -172.866641000327,
                                52.1489329997656
                            ],
                            [
                                -172.842501000396,
                                52.1363539998494
                            ],
                            [
                                -172.828798000238,
                                52.1234370002641
                            ],
                            [
                                -172.822021999965,
                                52.1122909998197
                            ],
                            [
                                -172.819916000392,
                                52.1049740002049
                            ],
                            [
                                -172.820251000128,
                                52.1002740000398
                            ],
                            [
                                -172.8257739997,
                                52.0900890002521
                            ],
                            [
                                -172.839230999811,
                                52.071564000211
                            ],
                            [
                                -172.851985999766,
                                52.062676000021
                            ],
                            [
                                -172.866878000153,
                                52.0564029999632
                            ],
                            [
                                -172.87652200043,
                                52.0559449997648
                            ],
                            [
                                -172.884698999745,
                                52.0433490001241
                            ],
                            [
                                -172.90258200016,
                                52.0319650000153
                            ],
                            [
                                -172.923058000218,
                                52.024160000185
                            ],
                            [
                                -172.94975999983,
                                52.0191469997652
                            ],
                            [
                                -172.976034999645,
                                52.0202750001715
                            ],
                            [
                                -173.003271999908,
                                52.0279830000093
                            ],
                            [
                                -173.008750000173,
                                52.0256690000634
                            ],
                            [
                                -173.021628999976,
                                52.0244549998089
                            ],
                            [
                                -173.034506999954,
                                52.0255760002013
                            ],
                            [
                                -173.048759000109,
                                52.0224169997712
                            ],
                            [
                                -173.064505999915,
                                52.0239349999415
                            ],
                            [
                                -173.069999000248,
                                52.0259870001809
                            ],
                            [
                                -173.080271000164,
                                52.0185799997517
                            ],
                            [
                                -173.077687999667,
                                52.0079739999871
                            ],
                            [
                                -173.078115000363,
                                51.9934550002294
                            ],
                            [
                                -173.085162999724,
                                51.9749310000411
                            ],
                            [
                                -173.103229000436,
                                51.9594719997605
                            ],
                            [
                                -173.129259000029,
                                51.9505069998868
                            ],
                            [
                                -173.151260999702,
                                51.9471409999909
                            ],
                            [
                                -173.16670299991,
                                51.9467059997953
                            ],
                            [
                                -173.176065999979,
                                51.9488770002568
                            ],
                            [
                                -173.203567999657,
                                51.9603239998659
                            ],
                            [
                                -173.224871000445,
                                51.9746049999327
                            ],
                            [
                                -173.237168999566,
                                51.9883379997941
                            ],
                            [
                                -173.238866999608,
                                52.0086750001696
                            ],
                            [
                                -173.243180999758,
                                52.0094030000399
                            ],
                            [
                                -173.255815000237,
                                52.0053050001144
                            ],
                            [
                                -173.304521999895,
                                52.0054260002546
                            ],
                            [
                                -173.309923000167,
                                51.9990009999981
                            ],
                            [
                                -173.344223999684,
                                51.9833900000973
                            ],
                            [
                                -173.356674999865,
                                51.9808259999957
                            ],
                            [
                                -173.393142999953,
                                51.9796960002697
                            ],
                            [
                                -173.427865000318,
                                51.9848670001041
                            ],
                            [
                                -173.447982000351,
                                51.9781820002744
                            ],
                            [
                                -173.487318000443,
                                51.9711840000644
                            ],
                            [
                                -173.518597999796,
                                51.9716479999339
                            ],
                            [
                                -173.530683000279,
                                51.9764920001263
                            ],
                            [
                                -173.551434999623,
                                51.9767200001574
                            ],
                            [
                                -173.584913000407,
                                51.9824630000515
                            ],
                            [
                                -173.607100000028,
                                51.992379999899
                            ],
                            [
                                -173.617414999601,
                                52.0009480001617
                            ],
                            [
                                -173.637863999895,
                                52.0036569999396
                            ],
                            [
                                -173.645003000391,
                                52.001052999769
                            ],
                            [
                                -173.680189000364,
                                52.0008760000989
                            ],
                            [
                                -173.710034000339,
                                52.0068399998698
                            ],
                            [
                                -173.734874999703,
                                51.9988820000946
                            ],
                            [
                                -173.756389000378,
                                52.0002690000362
                            ],
                            [
                                -173.766522000376,
                                52.0028790002094
                            ],
                            [
                                -173.768351999764,
                                52.0011470001934
                            ],
                            [
                                -173.773662999623,
                                52.0028790002094
                            ],
                            [
                                -173.776530999627,
                                51.9985749998773
                            ],
                            [
                                -173.815227000384,
                                51.9898070002277
                            ],
                            [
                                -173.853954000205,
                                51.9912630000922
                            ],
                            [
                                -173.879361000182,
                                51.9987460000545
                            ],
                            [
                                -173.88297599965,
                                51.9974720001043
                            ],
                            [
                                -173.900890000026,
                                51.9992640002662
                            ],
                            [
                                -173.935161000305,
                                52.0044419998872
                            ],
                            [
                                -173.970685000386,
                                52.0142289999978
                            ],
                            [
                                -174.002163000106,
                                52.0287400000035
                            ],
                            [
                                -174.01133500038,
                                52.0373140001639
                            ],
                            [
                                -174.016705999616,
                                52.0376349998377
                            ],
                            [
                                -174.026380999854,
                                52.0472090001202
                            ],
                            [
                                -174.042280999822,
                                52.0499400000333
                            ],
                            [
                                -174.049864999659,
                                52.055238000202
                            ],
                            [
                                -174.056502999839,
                                52.0516329998895
                            ],
                            [
                                -174.076614000025,
                                52.0489609999496
                            ],
                            [
                                -174.112107000144,
                                52.046442000144
                            ],
                            [
                                -174.124252999828,
                                52.0485549998399
                            ],
                            [
                                -174.133041999787,
                                52.0456849998446
                            ],
                            [
                                -174.150987999951,
                                52.0432119997791
                            ],
                            [
                                -174.181140999898,
                                52.0473770001676
                            ],
                            [
                                -174.194537999735,
                                52.0468279998131
                            ],
                            [
                                -174.220967000434,
                                52.0416450002146
                            ],
                            [
                                -174.250326000162,
                                52.0393170000032
                            ],
                            [
                                -174.277153000346,
                                52.0453970000748
                            ],
                            [
                                -174.313973999713,
                                52.0362260002698
                            ],
                            [
                                -174.315758999793,
                                52.0238650002737
                            ],
                            [
                                -174.323325999912,
                                52.010161000151
                            ],
                            [
                                -174.335930000253,
                                52.0003190000326
                            ],
                            [
                                -174.340995999891,
                                51.9910939998003
                            ],
                            [
                                -174.35039400012,
                                51.9803359998397
                            ],
                            [
                                -174.378562999795,
                                51.9685240000762
                            ],
                            [
                                -174.423059000134,
                                51.9618390001858
                            ],
                            [
                                -174.442499999949,
                                51.9638750002693
                            ],
                            [
                                -174.48089100021,
                                51.9524970001945
                            ],
                            [
                                -174.508480999752,
                                51.9576229997586
                            ],
                            [
                                -174.533659000298,
                                51.9580189997852
                            ],
                            [
                                -174.550658000082,
                                51.9613669998051
                            ],
                            [
                                -174.575562000094,
                                51.9687820002338
                            ],
                            [
                                -174.594282000384,
                                51.9784120002739
                            ],
                            [
                                -174.631443000408,
                                51.9687109999889
                            ],
                            [
                                -174.646061000261,
                                51.9692069998311
                            ],
                            [
                                -174.687933000094,
                                51.9575250002214
                            ],
                            [
                                -174.701391999856,
                                51.9572640001492
                            ],
                            [
                                -174.708594000101,
                                51.9554789997709
                            ],
                            [
                                -174.745064999663,
                                51.9556459997412
                            ],
                            [
                                -174.787214000405,
                                51.9662800000355
                            ],
                            [
                                -174.805801999553,
                                51.9729850001259
                            ],
                            [
                                -174.832475999576,
                                51.972710000072
                            ],
                            [
                                -174.861256999894,
                                51.9800480001964
                            ],
                            [
                                -174.869302999689,
                                51.989101999792
                            ],
                            [
                                -174.876731999715,
                                51.9859079999138
                            ],
                            [
                                -174.910819999695,
                                51.9809019999757
                            ],
                            [
                                -174.933310999988,
                                51.9752930001627
                            ],
                            [
                                -174.951499999826,
                                51.9667169998824
                            ],
                            [
                                -175.000327999858,
                                51.9544929997994
                            ],
                            [
                                -175.029320999888,
                                51.9514560002131
                            ],
                            [
                                -175.040704000214,
                                51.9540959997847
                            ],
                            [
                                -175.056359999785,
                                51.9481589998506
                            ],
                            [
                                -175.100154999791,
                                51.9490339997503
                            ],
                            [
                                -175.146421000141,
                                51.9569530000157
                            ],
                            [
                                -175.164457999643,
                                51.9578290002565
                            ],
                            [
                                -175.182250999646,
                                51.9578889998741
                            ],
                            [
                                -175.203888000344,
                                51.9551950001832
                            ],
                            [
                                -175.237093000418,
                                51.9615189997853
                            ],
                            [
                                -175.256258999874,
                                51.9614119999877
                            ],
                            [
                                -175.275882999987,
                                51.9546819997915
                            ],
                            [
                                -175.306858999567,
                                51.9523369998789
                            ],
                            [
                                -175.337593000195,
                                51.9581500001829
                            ],
                            [
                                -175.348718999705,
                                51.9625850000296
                            ],
                            [
                                -175.372841000092,
                                51.9465069998013
                            ],
                            [
                                -175.392434000244,
                                51.9365560000205
                            ],
                            [
                                -175.409982000024,
                                51.9301840002275
                            ],
                            [
                                -175.430949000353,
                                51.9277270002251
                            ],
                            [
                                -175.446239000225,
                                51.930588000158
                            ],
                            [
                                -175.469950999635,
                                51.9217970000101
                            ],
                            [
                                -175.498731000129,
                                51.9215510000326
                            ],
                            [
                                -175.51307600017,
                                51.9267989998508
                            ],
                            [
                                -175.525678999788,
                                51.9153470000908
                            ],
                            [
                                -175.552536000109,
                                51.9089679997414
                            ],
                            [
                                -175.585374999587,
                                51.904898999895
                            ],
                            [
                                -175.615252000248,
                                51.9042950001118
                            ],
                            [
                                -175.628254000074,
                                51.907514000209
                            ],
                            [
                                -175.635930999798,
                                51.9111699999828
                            ],
                            [
                                -175.638874999969,
                                51.9089560000347
                            ],
                            [
                                -175.645102000246,
                                51.9079110002108
                            ],
                            [
                                -175.648029999626,
                                51.9035230001154
                            ],
                            [
                                -175.655599000292,
                                51.8976860001209
                            ],
                            [
                                -175.672475999878,
                                51.8902380001736
                            ],
                            [
                                -175.692161000091,
                                51.8858430001421
                            ],
                            [
                                -175.694967999996,
                                51.8817989998126
                            ],
                            [
                                -175.711935999818,
                                51.8732069997518
                            ],
                            [
                                -175.752557000397,
                                51.8638289998001
                            ],
                            [
                                -175.790643999985,
                                51.8625299999402
                            ],
                            [
                                -175.805938000055,
                                51.8664600002059
                            ],
                            [
                                -175.802424999796,
                                51.8604150002566
                            ],
                            [
                                -175.798638999726,
                                51.8390839997788
                            ],
                            [
                                -175.81462800028,
                                51.8152699999753
                            ],
                            [
                                -175.827110000423,
                                51.8054429998592
                            ],
                            [
                                -175.849753999978,
                                51.7954929999229
                            ],
                            [
                                -175.881980999711,
                                51.7939129997496
                            ],
                            [
                                -175.913035000006,
                                51.800353999884
                            ],
                            [
                                -175.9147270002,
                                51.7926139999188
                            ],
                            [
                                -175.926598000423,
                                51.7760340002543
                            ],
                            [
                                -175.942038999908,
                                51.7647189999163
                            ],
                            [
                                -175.9647129996,
                                51.7554250000484
                            ],
                            [
                                -176.002634000405,
                                51.7484480000675
                            ],
                            [
                                -176.018700000054,
                                51.7492890000443
                            ],
                            [
                                -176.041835999704,
                                51.7558469998743
                            ],
                            [
                                -176.058344000117,
                                51.7447019997766
                            ],
                            [
                                -176.078759999901,
                                51.7390620001672
                            ],
                            [
                                -176.090601999811,
                                51.7409089997354
                            ],
                            [
                                -176.115566000097,
                                51.7267250002019
                            ],
                            [
                                -176.133205000114,
                                51.7216270001862
                            ],
                            [
                                -176.163020999778,
                                51.7199779998467
                            ],
                            [
                                -176.189724999938,
                                51.7242420002076
                            ],
                            [
                                -176.207213000342,
                                51.7303449997599
                            ],
                            [
                                -176.211340999821,
                                51.7331400000269
                            ],
                            [
                                -176.218168999973,
                                51.7223400001165
                            ],
                            [
                                -176.229979000096,
                                51.7113160000478
                            ],
                            [
                                -176.241391999661,
                                51.702854000137
                            ],
                            [
                                -176.255186000056,
                                51.6953759997263
                            ],
                            [
                                -176.317809000308,
                                51.6813879999772
                            ],
                            [
                                -176.338958000211,
                                51.6787309998872
                            ],
                            [
                                -176.368743999737,
                                51.6788589998037
                            ],
                            [
                                -176.40313999969,
                                51.6839090002733
                            ],
                            [
                                -176.426514999714,
                                51.6777040001712
                            ],
                            [
                                -176.447938999977,
                                51.6741779998098
                            ],
                            [
                                -176.456543999812,
                                51.676425999782
                            ],
                            [
                                -176.465517999719,
                                51.6714919998386
                            ],
                            [
                                -176.477112999757,
                                51.6676990001766
                            ],
                            [
                                -176.493072999999,
                                51.655742999847
                            ],
                            [
                                -176.515258999795,
                                51.6477999999829
                            ],
                            [
                                -176.533265000233,
                                51.6464250000412
                            ],
                            [
                                -176.539766000147,
                                51.6484379999894
                            ],
                            [
                                -176.560944000362,
                                51.6363749998845
                            ],
                            [
                                -176.578277000057,
                                51.6223510001691
                            ],
                            [
                                -176.601530999708,
                                51.6117139997643
                            ],
                            [
                                -176.624754000295,
                                51.6048990001858
                            ],
                            [
                                -176.646514000118,
                                51.6026470001704
                            ],
                            [
                                -176.651241999647,
                                51.6039129997211
                            ],
                            [
                                -176.662963000081,
                                51.594101999765
                            ],
                            [
                                -176.698730999662,
                                51.5784280002393
                            ],
                            [
                                -176.734499000141,
                                51.5741760000082
                            ],
                            [
                                -176.747316999845,
                                51.5777300001086
                            ],
                            [
                                -176.756776999998,
                                51.5726490000885
                            ],
                            [
                                -176.801394999637,
                                51.559409000239
                            ],
                            [
                                -176.829718000196,
                                51.5587829999746
                            ],
                            [
                                -176.848760999629,
                                51.5630159999827
                            ],
                            [
                                -176.862149999968,
                                51.5704010000214
                            ],
                            [
                                -176.867012000289,
                                51.563579999972
                            ],
                            [
                                -176.893990000086,
                                51.5468240002057
                            ],
                            [
                                -176.917885999619,
                                51.5384679999871
                            ],
                            [
                                -176.942788999806,
                                51.5370709999294
                            ],
                            [
                                -176.969615000165,
                                51.5389850001629
                            ],
                            [
                                -176.998029000062,
                                51.5454690000259
                            ],
                            [
                                -177.042558000013,
                                51.5660890000894
                            ],
                            [
                                -177.05885499964,
                                51.5784640001936
                            ],
                            [
                                -177.068653999727,
                                51.5927919998477
                            ],
                            [
                                -177.068763000406,
                                51.6096929998122
                            ],
                            [
                                -177.0720740001,
                                51.6132480002739
                            ],
                            [
                                -177.074332999833,
                                51.62090699981
                            ],
                            [
                                -177.074670000117,
                                51.6331920000872
                            ],
                            [
                                -177.072716999807,
                                51.6424000002258
                            ],
                            [
                                -177.065332000162,
                                51.6489319997222
                            ],
                            [
                                -177.065729999647,
                                51.6553940002323
                            ],
                            [
                                -177.063747000099,
                                51.6646560001906
                            ],
                            [
                                -177.045439000435,
                                51.690705000167
                            ],
                            [
                                -177.033934999735,
                                51.7039809999164
                            ],
                            [
                                -177.012663999633,
                                51.7145350001013
                            ],
                            [
                                -176.990296000263,
                                51.7340059997692
                            ],
                            [
                                -176.970216000039,
                                51.7436890001461
                            ],
                            [
                                -176.968373000233,
                                51.7481030000534
                            ],
                            [
                                -176.980532000335,
                                51.7524780001726
                            ],
                            [
                                -176.994297000418,
                                51.762236000109
                            ],
                            [
                                -177.001439000389,
                                51.7737179999956
                            ],
                            [
                                -177.004156999707,
                                51.7877799999352
                            ],
                            [
                                -177.001472999826,
                                51.8027040000543
                            ],
                            [
                                -176.994515999803,
                                51.8138579999225
                            ],
                            [
                                -176.983042000139,
                                51.8257610002193
                            ],
                            [
                                -176.983683000196,
                                51.8338059999338
                            ],
                            [
                                -176.988505000335,
                                51.8279889997243
                            ],
                            [
                                -176.997049999896,
                                51.8225409998457
                            ],
                            [
                                -177.024424000426,
                                51.8095850002152
                            ],
                            [
                                -177.040281999663,
                                51.8077020002378
                            ],
                            [
                                -177.037393999567,
                                51.8044190002129
                            ],
                            [
                                -177.034493999777,
                                51.7967050002284
                            ],
                            [
                                -177.034431000028,
                                51.7844749998371
                            ],
                            [
                                -177.03607899984,
                                51.7818009999925
                            ],
                            [
                                -177.031654000261,
                                51.7795609999047
                            ],
                            [
                                -177.028325999949,
                                51.7743039999475
                            ],
                            [
                                -177.027622999968,
                                51.7670490000179
                            ],
                            [
                                -177.033604000197,
                                51.7498889999348
                            ],
                            [
                                -177.036350999827,
                                51.7462809998656
                            ],
                            [
                                -177.033481000174,
                                51.74119899989
                            ],
                            [
                                -177.034090000444,
                                51.732737000066
                            ],
                            [
                                -177.046845000399,
                                51.7129380001221
                            ],
                            [
                                -177.059721999653,
                                51.6973659998345
                            ],
                            [
                                -177.065271000063,
                                51.6954970002297
                            ],
                            [
                                -177.064390999631,
                                51.6930090000245
                            ],
                            [
                                -177.067564000131,
                                51.6875760001576
                            ],
                            [
                                -177.079985000175,
                                51.6742309998599
                            ],
                            [
                                -177.097989000065,
                                51.6633280000013
                            ],
                            [
                                -177.126859000072,
                                51.6533699998223
                            ],
                            [
                                -177.150450000007,
                                51.6515370001603
                            ],
                            [
                                -177.163023000386,
                                51.6527570000821
                            ],
                            [
                                -177.183654000255,
                                51.6485830000562
                            ],
                            [
                                -177.198271000283,
                                51.6419060001145
                            ],
                            [
                                -177.228636999767,
                                51.6320330001408
                            ],
                            [
                                -177.256591000255,
                                51.6282770000504
                            ],
                            [
                                -177.281096999884,
                                51.6276120000356
                            ],
                            [
                                -177.304353000082,
                                51.6308920002445
                            ],
                            [
                                -177.317629000444,
                                51.6360419998409
                            ],
                            [
                                -177.334932000002,
                                51.6342559998236
                            ],
                            [
                                -177.350283999798,
                                51.6350410001411
                            ],
                            [
                                -177.372288000019,
                                51.6415709997652
                            ],
                            [
                                -177.388434000034,
                                51.6541290001879
                            ],
                            [
                                -177.402979999917,
                                51.6703809998595
                            ],
                            [
                                -177.41318599971,
                                51.6644129998592
                            ],
                            [
                                -177.419930000195,
                                51.6624979997585
                            ],
                            [
                                -177.452033000079,
                                51.6402010000464
                            ],
                            [
                                -177.480995999973,
                                51.6321580000212
                            ],
                            [
                                -177.512857000007,
                                51.6319199999034
                            ],
                            [
                                -177.538645999575,
                                51.6373659998824
                            ],
                            [
                                -177.541383000058,
                                51.6411719998956
                            ],
                            [
                                -177.55378400001,
                                51.6397719997717
                            ],
                            [
                                -177.556407999616,
                                51.6305670001361
                            ],
                            [
                                -177.562723999582,
                                51.6229219998371
                            ],
                            [
                                -177.581155999992,
                                51.6132619999743
                            ],
                            [
                                -177.602427999919,
                                51.6070040002455
                            ],
                            [
                                -177.646771999921,
                                51.59904299978
                            ],
                            [
                                -177.657685999719,
                                51.5986640001708
                            ],
                            [
                                -177.699663999858,
                                51.6023129998543
                            ],
                            [
                                -177.727712999883,
                                51.6109639998522
                            ],
                            [
                                -177.750268999574,
                                51.6277099997344
                            ],
                            [
                                -177.751949000072,
                                51.6373549998674
                            ],
                            [
                                -177.783401999678,
                                51.6478590000737
                            ],
                            [
                                -177.810393999718,
                                51.6330469998639
                            ],
                            [
                                -177.824903999616,
                                51.6310550001548
                            ],
                            [
                                -177.821715999945,
                                51.6216859997384
                            ],
                            [
                                -177.818694999781,
                                51.6187410001215
                            ],
                            [
                                -177.817868999775,
                                51.5981099997348
                            ],
                            [
                                -177.820491999556,
                                51.5844220002434
                            ],
                            [
                                -177.830531999668,
                                51.5700460001628
                            ],
                            [
                                -177.843808999854,
                                51.5585549997271
                            ],
                            [
                                -177.860043999558,
                                51.5510919999539
                            ],
                            [
                                -177.878264000256,
                                51.5433240002427
                            ],
                            [
                                -177.89935399971,
                                51.540218000236
                            ],
                            [
                                -177.928257000227,
                                51.5418949999688
                            ],
                            [
                                -177.97129200035,
                                51.5523919999957
                            ],
                            [
                                -177.990641999929,
                                51.5602040002575
                            ],
                            [
                                -178.002114999769,
                                51.5669439999184
                            ],
                            [
                                -178.010816999689,
                                51.5758219999679
                            ],
                            [
                                -178.018995999553,
                                51.5739750001491
                            ],
                            [
                                -178.026901999605,
                                51.5741880001884
                            ],
                            [
                                -178.050707999624,
                                51.579909000202
                            ],
                            [
                                -178.083428000174,
                                51.5951230002218
                            ],
                            [
                                -178.089411000054,
                                51.6006690000465
                            ],
                            [
                                -178.090227000013,
                                51.6037430000165
                            ],
                            [
                                -178.131774000157,
                                51.609907000046
                            ],
                            [
                                -178.162906000271,
                                51.6212369999754
                            ],
                            [
                                -178.189367999683,
                                51.6336729999775
                            ],
                            [
                                -178.195564999823,
                                51.6413939997994
                            ],
                            [
                                -178.203197000239,
                                51.6613239997258
                            ],
                            [
                                -178.205975999656,
                                51.6793390000282
                            ],
                            [
                                -178.203718999572,
                                51.692508000044
                            ],
                            [
                                -178.192248000281,
                                51.7038479999545
                            ],
                            [
                                -178.192338999619,
                                51.7208469997654
                            ],
                            [
                                -178.184526000352,
                                51.7322009997989
                            ],
                            [
                                -178.164476000266,
                                51.7468820002344
                            ],
                            [
                                -178.139084000358,
                                51.7570469998394
                            ],
                            [
                                -178.132046999969,
                                51.7578550002679
                            ],
                            [
                                -178.143509999763,
                                51.7643950000965
                            ],
                            [
                                -178.151660000212,
                                51.7737560000733
                            ],
                            [
                                -178.16029799966,
                                51.7788910001411
                            ],
                            [
                                -178.189079999803,
                                51.7890839999125
                            ],
                            [
                                -178.233490999753,
                                51.8018929999691
                            ],
                            [
                                -178.256047000343,
                                51.8120170000862
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -167.505440999796,
                                60.1569680001196
                            ],
                            [
                                -167.515172000111,
                                60.1642579999147
                            ],
                            [
                                -167.516964999689,
                                60.1728390001754
                            ],
                            [
                                -167.536351000151,
                                60.1782450001671
                            ],
                            [
                                -167.560296000088,
                                60.205956000092
                            ],
                            [
                                -167.558228000151,
                                60.2243569998138
                            ],
                            [
                                -167.550799000125,
                                60.2405540000847
                            ],
                            [
                                -167.540495000422,
                                60.2493490000215
                            ],
                            [
                                -167.529403000349,
                                60.2552830002187
                            ],
                            [
                                -167.508073999623,
                                60.2631090001769
                            ],
                            [
                                -167.473595999655,
                                60.264834000141
                            ],
                            [
                                -167.437700000028,
                                60.2628479999616
                            ],
                            [
                                -167.411783000413,
                                60.2715940000937
                            ],
                            [
                                -167.391836000259,
                                60.2750730002079
                            ],
                            [
                                -167.371115999803,
                                60.2756879997826
                            ],
                            [
                                -167.352589999681,
                                60.2831669999913
                            ],
                            [
                                -167.336719999851,
                                60.2874259998903
                            ],
                            [
                                -167.309829999918,
                                60.2907180001177
                            ],
                            [
                                -167.276808999967,
                                60.2889340000992
                            ],
                            [
                                -167.255106000045,
                                60.2838170001688
                            ],
                            [
                                -167.22595499983,
                                60.287973000143
                            ],
                            [
                                -167.205189000242,
                                60.2882830001127
                            ],
                            [
                                -167.179117999743,
                                60.2866569998432
                            ],
                            [
                                -167.153458000046,
                                60.2824309997908
                            ],
                            [
                                -167.137656999813,
                                60.2881109999007
                            ],
                            [
                                -167.120545999876,
                                60.2912249999674
                            ],
                            [
                                -167.102574999598,
                                60.2900370002096
                            ],
                            [
                                -167.069946000182,
                                60.2847469999267
                            ],
                            [
                                -167.044719000131,
                                60.2779159999231
                            ],
                            [
                                -167.018065000199,
                                60.2662349998674
                            ],
                            [
                                -166.989091000435,
                                60.2737850001773
                            ],
                            [
                                -166.967975999968,
                                60.2775030002074
                            ],
                            [
                                -166.943778000312,
                                60.2781030001488
                            ],
                            [
                                -166.929756000311,
                                60.2954969998884
                            ],
                            [
                                -166.918722999789,
                                60.3045299997783
                            ],
                            [
                                -166.904705999811,
                                60.3121590001691
                            ],
                            [
                                -166.885952999909,
                                60.3175549999265
                            ],
                            [
                                -166.873408000016,
                                60.331203000123
                            ],
                            [
                                -166.844681000124,
                                60.3450309999928
                            ],
                            [
                                -166.812605000005,
                                60.3505829998759
                            ],
                            [
                                -166.800517000047,
                                60.3627630001483
                            ],
                            [
                                -166.763295999749,
                                60.3812759998204
                            ],
                            [
                                -166.739336999568,
                                60.3878610000969
                            ],
                            [
                                -166.701192000254,
                                60.389142999962
                            ],
                            [
                                -166.666308000405,
                                60.3857850001335
                            ],
                            [
                                -166.640544999878,
                                60.4038420000932
                            ],
                            [
                                -166.620120999698,
                                60.4129470001482
                            ],
                            [
                                -166.593773000088,
                                60.4192450001899
                            ],
                            [
                                -166.570193999849,
                                60.4229190002008
                            ],
                            [
                                -166.56064800038,
                                60.4299020000064
                            ],
                            [
                                -166.528839000224,
                                60.444270000123
                            ],
                            [
                                -166.505784999692,
                                60.447639000093
                            ],
                            [
                                -166.478006000004,
                                60.4487580001199
                            ],
                            [
                                -166.443624000296,
                                60.4444839998616
                            ],
                            [
                                -166.417197000145,
                                60.4361099998397
                            ],
                            [
                                -166.402868999822,
                                60.4261950001994
                            ],
                            [
                                -166.38782800037,
                                60.4266139999873
                            ],
                            [
                                -166.371492000385,
                                60.4238820001693
                            ],
                            [
                                -166.3498229999,
                                60.4177449999642
                            ],
                            [
                                -166.334033000435,
                                60.4290659997931
                            ],
                            [
                                -166.316448999771,
                                60.4389660000415
                            ],
                            [
                                -166.29763300012,
                                60.4474289998938
                            ],
                            [
                                -166.276331999881,
                                60.4516129999919
                            ],
                            [
                                -166.275818000045,
                                60.4691189997959
                            ],
                            [
                                -166.270933000157,
                                60.4786210001932
                            ],
                            [
                                -166.24932700032,
                                60.4911270001189
                            ],
                            [
                                -166.229288000104,
                                60.4993050002003
                            ],
                            [
                                -166.208588999564,
                                60.5051859998081
                            ],
                            [
                                -166.152431999964,
                                60.5107469998905
                            ],
                            [
                                -166.121334000186,
                                60.5009100000156
                            ],
                            [
                                -166.084877999794,
                                60.4859379998617
                            ],
                            [
                                -166.054191999744,
                                60.4694909999961
                            ],
                            [
                                -166.030688999673,
                                60.4389400001854
                            ],
                            [
                                -166.005798000081,
                                60.4195729999866
                            ],
                            [
                                -165.999768000345,
                                60.4112850001405
                            ],
                            [
                                -165.998309999605,
                                60.4003959999853
                            ],
                            [
                                -166.009532000273,
                                60.3877039998957
                            ],
                            [
                                -165.997255999994,
                                60.3837569999501
                            ],
                            [
                                -165.986707999894,
                                60.3786540001232
                            ],
                            [
                                -165.981366000072,
                                60.3740869999653
                            ],
                            [
                                -165.960970000379,
                                60.3896979999678
                            ],
                            [
                                -165.949495999817,
                                60.393776000135
                            ],
                            [
                                -165.899384999845,
                                60.4032019999436
                            ],
                            [
                                -165.876212000385,
                                60.402745000061
                            ],
                            [
                                -165.847735999665,
                                60.398574000211
                            ],
                            [
                                -165.828446000361,
                                60.3900499998115
                            ],
                            [
                                -165.816423999627,
                                60.3961220001554
                            ],
                            [
                                -165.782465000416,
                                60.4048730001365
                            ],
                            [
                                -165.759338999914,
                                60.4063980001837
                            ],
                            [
                                -165.73846500037,
                                60.4056089998958
                            ],
                            [
                                -165.693076000079,
                                60.3931469997833
                            ],
                            [
                                -165.671483999587,
                                60.3834619998526
                            ],
                            [
                                -165.653928000309,
                                60.3717599997863
                            ],
                            [
                                -165.64739499971,
                                60.3623100000858
                            ],
                            [
                                -165.649482999739,
                                60.3488229998247
                            ],
                            [
                                -165.63271199956,
                                60.3483020000612
                            ],
                            [
                                -165.61704699977,
                                60.344901000036
                            ],
                            [
                                -165.600196999948,
                                60.3389269999402
                            ],
                            [
                                -165.586194000214,
                                60.3297759998321
                            ],
                            [
                                -165.574507999939,
                                60.321198999941
                            ],
                            [
                                -165.561518999633,
                                60.3017259998014
                            ],
                            [
                                -165.561376000416,
                                60.2922629999684
                            ],
                            [
                                -165.56528700016,
                                60.2830699998894
                            ],
                            [
                                -165.57437499987,
                                60.27329700015
                            ],
                            [
                                -165.57020100036,
                                60.2641210001409
                            ],
                            [
                                -165.570707999625,
                                60.2589559998673
                            ],
                            [
                                -165.588300999611,
                                60.2414139999992
                            ],
                            [
                                -165.575488999755,
                                60.2316930001744
                            ],
                            [
                                -165.566600000237,
                                60.2156499998175
                            ],
                            [
                                -165.56707399989,
                                60.2076210000559
                            ],
                            [
                                -165.570370000414,
                                60.1978549999268
                            ],
                            [
                                -165.588498000153,
                                60.1788760000605
                            ],
                            [
                                -165.579197999833,
                                60.1717239998569
                            ],
                            [
                                -165.576226999897,
                                60.1657120000813
                            ],
                            [
                                -165.575543000182,
                                60.1585410001788
                            ],
                            [
                                -165.57715500001,
                                60.1507849999345
                            ],
                            [
                                -165.56846200031,
                                60.1459289999292
                            ],
                            [
                                -165.562060999956,
                                60.1402090000869
                            ],
                            [
                                -165.559675999826,
                                60.1333319998647
                            ],
                            [
                                -165.56396700041,
                                60.1126619999016
                            ],
                            [
                                -165.558715000101,
                                60.1063720002168
                            ],
                            [
                                -165.557460999576,
                                60.09919400017
                            ],
                            [
                                -165.560191000385,
                                60.0894399998767
                            ],
                            [
                                -165.566360999862,
                                60.0802369998589
                            ],
                            [
                                -165.57317300012,
                                60.0750629999494
                            ],
                            [
                                -165.567356999747,
                                60.0702030001784
                            ],
                            [
                                -165.564990000059,
                                60.0641790000709
                            ],
                            [
                                -165.566621999978,
                                60.0587309998204
                            ],
                            [
                                -165.553379999952,
                                60.0541630000776
                            ],
                            [
                                -165.546981000146,
                                60.0473029999854
                            ],
                            [
                                -165.539364999624,
                                60.0349790002096
                            ],
                            [
                                -165.497496000163,
                                60.0278870001359
                            ],
                            [
                                -165.479677000221,
                                60.0216140001647
                            ],
                            [
                                -165.462358999696,
                                60.0095899999487
                            ],
                            [
                                -165.45321499991,
                                59.9992090000475
                            ],
                            [
                                -165.451880000092,
                                59.9904179999959
                            ],
                            [
                                -165.402457999858,
                                59.9704480000833
                            ],
                            [
                                -165.402539000049,
                                59.9678590001258
                            ],
                            [
                                -165.412004000225,
                                59.9541419999108
                            ],
                            [
                                -165.43913000016,
                                59.936035999865
                            ],
                            [
                                -165.438706999662,
                                59.9317989998692
                            ],
                            [
                                -165.445190999857,
                                59.9164379997784
                            ],
                            [
                                -165.460905999877,
                                59.895005000195
                            ],
                            [
                                -165.495470000059,
                                59.8701839998702
                            ],
                            [
                                -165.560383000006,
                                59.8528680001611
                            ],
                            [
                                -165.60214799971,
                                59.8476870000032
                            ],
                            [
                                -165.625260999793,
                                59.8464030001852
                            ],
                            [
                                -165.657822000335,
                                59.8362630001165
                            ],
                            [
                                -165.699029999646,
                                59.8350790001331
                            ],
                            [
                                -165.739021999864,
                                59.841350999941
                            ],
                            [
                                -165.773273000051,
                                59.8204650001471
                            ],
                            [
                                -165.803036999836,
                                59.8091040000056
                            ],
                            [
                                -165.824858999583,
                                59.8067459998473
                            ],
                            [
                                -165.837189000289,
                                59.7975090000597
                            ],
                            [
                                -165.878050999994,
                                59.7760090001061
                            ],
                            [
                                -165.921604000151,
                                59.7471299999557
                            ],
                            [
                                -165.964816999651,
                                59.7229710001101
                            ],
                            [
                                -166.012453999805,
                                59.7053839998487
                            ],
                            [
                                -166.057893000324,
                                59.6955350001812
                            ],
                            [
                                -166.099716999579,
                                59.6940510000188
                            ],
                            [
                                -166.157565000447,
                                59.697910999889
                            ],
                            [
                                -166.19661299976,
                                59.7033840000097
                            ],
                            [
                                -166.242114000204,
                                59.71709499982
                            ],
                            [
                                -166.273867000283,
                                59.7338470002094
                            ],
                            [
                                -166.291254000405,
                                59.7471960001612
                            ],
                            [
                                -166.300577000291,
                                59.7600740001528
                            ],
                            [
                                -166.321854000241,
                                59.7731410002141
                            ],
                            [
                                -166.366394999887,
                                59.7796609997907
                            ],
                            [
                                -166.429670999892,
                                59.8016359998498
                            ],
                            [
                                -166.461706999828,
                                59.79593300004
                            ],
                            [
                                -166.507965999607,
                                59.7987490000376
                            ],
                            [
                                -166.564222999665,
                                59.7961109999591
                            ],
                            [
                                -166.626126000217,
                                59.7970609999545
                            ],
                            [
                                -166.654342999573,
                                59.8023919999696
                            ],
                            [
                                -166.689016000433,
                                59.8153380000092
                            ],
                            [
                                -166.736783000282,
                                59.8383009998334
                            ],
                            [
                                -166.748371999575,
                                59.8382040002131
                            ],
                            [
                                -166.786514000313,
                                59.8426570001928
                            ],
                            [
                                -166.828905000005,
                                59.8523300001742
                            ],
                            [
                                -166.841506999799,
                                59.861541999904
                            ],
                            [
                                -166.850852000325,
                                59.8710560002064
                            ],
                            [
                                -166.868128000119,
                                59.881258000053
                            ],
                            [
                                -166.90461399975,
                                59.898051000183
                            ],
                            [
                                -166.940622000428,
                                59.9119079999684
                            ],
                            [
                                -167.042040000273,
                                59.9357570002049
                            ],
                            [
                                -167.102911999881,
                                59.936948999777
                            ],
                            [
                                -167.137841999761,
                                59.944033000104
                            ],
                            [
                                -167.192588000273,
                                59.9745560000863
                            ],
                            [
                                -167.22273000035,
                                59.9938529998391
                            ],
                            [
                                -167.293405000244,
                                59.9991859999234
                            ],
                            [
                                -167.310802000411,
                                60.0074719999874
                            ],
                            [
                                -167.324149000019,
                                60.0184570000597
                            ],
                            [
                                -167.358482000222,
                                60.0184859998887
                            ],
                            [
                                -167.38557999967,
                                60.0212069999797
                            ],
                            [
                                -167.400816000014,
                                60.0261220001262
                            ],
                            [
                                -167.4179899997,
                                60.0341629997796
                            ],
                            [
                                -167.434833999674,
                                60.045657999882
                            ],
                            [
                                -167.440768999873,
                                60.0564619998576
                            ],
                            [
                                -167.442969000055,
                                60.0954529997781
                            ],
                            [
                                -167.441713999704,
                                60.1020800000737
                            ],
                            [
                                -167.4364499997,
                                60.1087609999518
                            ],
                            [
                                -167.453076999939,
                                60.1162329999051
                            ],
                            [
                                -167.464578000266,
                                60.1246520001763
                            ],
                            [
                                -167.471732999757,
                                60.136016999921
                            ],
                            [
                                -167.473240999828,
                                60.1489050002015
                            ],
                            [
                                -167.492433000121,
                                60.1520219999763
                            ],
                            [
                                -167.505440999796,
                                60.1569680001196
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                172.353919999779,
                                52.8949939999853
                            ],
                            [
                                172.347845999663,
                                52.9066909998839
                            ],
                            [
                                172.349340000388,
                                52.9221939999173
                            ],
                            [
                                172.353886000342,
                                52.9358040001818
                            ],
                            [
                                172.365816000116,
                                52.951742000181
                            ],
                            [
                                172.376405000224,
                                52.9596169999714
                            ],
                            [
                                172.399596000125,
                                52.9687720002212
                            ],
                            [
                                172.416594000085,
                                52.9720450000015
                            ],
                            [
                                172.427775999671,
                                52.9715359997835
                            ],
                            [
                                172.445337999695,
                                52.9840399998636
                            ],
                            [
                                172.465843999891,
                                52.9938200002301
                            ],
                            [
                                172.483817999644,
                                52.998916999923
                            ],
                            [
                                172.495749000141,
                                53.0044260001955
                            ],
                            [
                                172.506642999847,
                                53.0125209999219
                            ],
                            [
                                172.527729999826,
                                53.0233179997918
                            ],
                            [
                                172.562274999741,
                                53.0306279999953
                            ],
                            [
                                172.586167999799,
                                53.0438659999175
                            ],
                            [
                                172.623153999921,
                                53.0531519998717
                            ],
                            [
                                172.68278500032,
                                53.054930999889
                            ],
                            [
                                172.734417999708,
                                53.0636450001946
                            ],
                            [
                                172.76362300035,
                                53.0652559997614
                            ],
                            [
                                172.787944999855,
                                53.0629979998686
                            ],
                            [
                                172.808270000301,
                                53.0565289997825
                            ],
                            [
                                172.836589999589,
                                53.0510290001845
                            ],
                            [
                                172.859844999962,
                                53.0537460000997
                            ],
                            [
                                172.888346999723,
                                53.0541290002098
                            ],
                            [
                                172.913004999688,
                                53.0507559997778
                            ],
                            [
                                172.954157999646,
                                53.0389509998298
                            ],
                            [
                                172.963021999948,
                                53.042564000058
                            ],
                            [
                                172.981820000057,
                                53.0468900002352
                            ],
                            [
                                172.9943020002,
                                53.0475399998375
                            ],
                            [
                                173.018715999766,
                                53.0474629998813
                            ],
                            [
                                173.061836000278,
                                53.0417959999053
                            ],
                            [
                                173.102210000087,
                                53.0458719997459
                            ],
                            [
                                173.132208000223,
                                53.0439580002393
                            ],
                            [
                                173.178686000286,
                                53.0328350001074
                            ],
                            [
                                173.215888000317,
                                53.0203550002255
                            ],
                            [
                                173.229162999955,
                                53.0121609999062
                            ],
                            [
                                173.238356000145,
                                52.9997599999518
                            ],
                            [
                                173.249885999886,
                                53.0022890002123
                            ],
                            [
                                173.278908000229,
                                53.0017179999256
                            ],
                            [
                                173.321938000329,
                                52.9921210000106
                            ],
                            [
                                173.371409000069,
                                52.9603449998141
                            ],
                            [
                                173.383830999937,
                                52.9411489997756
                            ],
                            [
                                173.388379000439,
                                52.9255319999024
                            ],
                            [
                                173.390639999822,
                                52.9091290000476
                            ],
                            [
                                173.392593000132,
                                52.9074499998316
                            ],
                            [
                                173.400362999741,
                                52.9067619999652
                            ],
                            [
                                173.408858999797,
                                52.9123639999623
                            ],
                            [
                                173.418043999591,
                                52.9160420001668
                            ],
                            [
                                173.431653999863,
                                52.9174919997373
                            ],
                            [
                                173.474076000416,
                                52.9076209997338
                            ],
                            [
                                173.489548999688,
                                52.9001149998327
                            ],
                            [
                                173.50254999969,
                                52.8915470002341
                            ],
                            [
                                173.512530000426,
                                52.8810649999588
                            ],
                            [
                                173.516161999612,
                                52.8739769999886
                            ],
                            [
                                173.537523999951,
                                52.8667910001697
                            ],
                            [
                                173.567614999974,
                                52.8418670001034
                            ],
                            [
                                173.577075999952,
                                52.8303540002381
                            ],
                            [
                                173.580646999937,
                                52.8154849999185
                            ],
                            [
                                173.577870999995,
                                52.8089920001743
                            ],
                            [
                                173.569388000358,
                                52.7989669999905
                            ],
                            [
                                173.551354000331,
                                52.7848820002206
                            ],
                            [
                                173.526056999959,
                                52.7744819997805
                            ],
                            [
                                173.504390999847,
                                52.7727950001419
                            ],
                            [
                                173.46874600029,
                                52.7749909999413
                            ],
                            [
                                173.448269000407,
                                52.781238999995
                            ],
                            [
                                173.403376000408,
                                52.7775620001491
                            ],
                            [
                                173.405728000028,
                                52.7714709998214
                            ],
                            [
                                173.42168999992,
                                52.755610999945
                            ],
                            [
                                173.420593999578,
                                52.7441129999134
                            ],
                            [
                                173.414337999887,
                                52.7281519998828
                            ],
                            [
                                173.39853199963,
                                52.7104739999553
                            ],
                            [
                                173.361973000204,
                                52.6957560002704
                            ],
                            [
                                173.346074999887,
                                52.6932380002094
                            ],
                            [
                                173.330236000019,
                                52.692832999909
                            ],
                            [
                                173.306341000311,
                                52.6988589998407
                            ],
                            [
                                173.287420000179,
                                52.7067239997933
                            ],
                            [
                                173.267704000003,
                                52.7199689999917
                            ],
                            [
                                173.209628000428,
                                52.739651000046
                            ],
                            [
                                173.182498000295,
                                52.7384139998426
                            ],
                            [
                                173.132756000395,
                                52.7302179998025
                            ],
                            [
                                173.103306000429,
                                52.729790000271
                            ],
                            [
                                173.062716999637,
                                52.740317000202
                            ],
                            [
                                173.049227999739,
                                52.7456350000524
                            ],
                            [
                                173.045780999602,
                                52.7488499997301
                            ],
                            [
                                173.044162000101,
                                52.7381800000355
                            ],
                            [
                                173.036015000025,
                                52.7284600002687
                            ],
                            [
                                172.99292499965,
                                52.711451999923
                            ],
                            [
                                172.96921499989,
                                52.6994969999279
                            ],
                            [
                                172.943087000388,
                                52.6888469997561
                            ],
                            [
                                172.920908000265,
                                52.6830690000809
                            ],
                            [
                                172.901468000274,
                                52.6816029999301
                            ],
                            [
                                172.882973999939,
                                52.6847610000967
                            ],
                            [
                                172.867623999793,
                                52.6902460001138
                            ],
                            [
                                172.857339000357,
                                52.6957469998921
                            ],
                            [
                                172.835608999772,
                                52.7099670001252
                            ],
                            [
                                172.814383999701,
                                52.7308429998885
                            ],
                            [
                                172.78769499961,
                                52.727086999785
                            ],
                            [
                                172.760168999642,
                                52.7274140002131
                            ],
                            [
                                172.736945000128,
                                52.7278870000086
                            ],
                            [
                                172.716070999687,
                                52.7324100000169
                            ],
                            [
                                172.692815000386,
                                52.7417479998896
                            ],
                            [
                                172.680149999945,
                                52.748620999838
                            ],
                            [
                                172.639590000364,
                                52.7797789999277
                            ],
                            [
                                172.610138999675,
                                52.7982259998672
                            ],
                            [
                                172.587311999822,
                                52.8091430002096
                            ],
                            [
                                172.479079000244,
                                52.855234000257
                            ],
                            [
                                172.450441999866,
                                52.8524449999945
                            ],
                            [
                                172.437869000384,
                                52.8534589998149
                            ],
                            [
                                172.427952000296,
                                52.856121999947
                            ],
                            [
                                172.404484000386,
                                52.8684279999122
                            ],
                            [
                                172.362585999714,
                                52.8870209998399
                            ],
                            [
                                172.353919999779,
                                52.8949939999853
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -173.181626000259,
                                60.6061779998888
                            ],
                            [
                                -173.23223000015,
                                60.6368269999271
                            ],
                            [
                                -173.236829999633,
                                60.6455830001509
                            ],
                            [
                                -173.218430999732,
                                60.6762310000766
                            ],
                            [
                                -173.190831000145,
                                60.6893659999366
                            ],
                            [
                                -173.19083199997,
                                60.7068779999483
                            ],
                            [
                                -173.177031999727,
                                60.7200129998512
                            ],
                            [
                                -173.158632000001,
                                60.728770000112
                            ],
                            [
                                -173.100409000113,
                                60.7451660000933
                            ],
                            [
                                -173.059067999834,
                                60.7477480000686
                            ],
                            [
                                -173.012472999596,
                                60.7403719999744
                            ],
                            [
                                -172.989762000094,
                                60.731810999831
                            ],
                            [
                                -172.962252999844,
                                60.7098050000955
                            ],
                            [
                                -172.953052000157,
                                60.6944810000703
                            ],
                            [
                                -172.943848999921,
                                60.6506999998693
                            ],
                            [
                                -172.888646000372,
                                60.6441349997977
                            ],
                            [
                                -172.861045000062,
                                60.6375680000335
                            ],
                            [
                                -172.838043000306,
                                60.6288130001578
                            ],
                            [
                                -172.82884199972,
                                60.6222459998106
                            ],
                            [
                                -172.819641000033,
                                60.6134899999805
                            ],
                            [
                                -172.815040999652,
                                60.6025450001005
                            ],
                            [
                                -172.819641000033,
                                60.5959780000971
                            ],
                            [
                                -172.815326999883,
                                60.5578520001723
                            ],
                            [
                                -172.79574700015,
                                60.5386469999715
                            ],
                            [
                                -172.760254999855,
                                60.5141810001198
                            ],
                            [
                                -172.687840000087,
                                60.4801200001698
                            ],
                            [
                                -172.530021000244,
                                60.435740999827
                            ],
                            [
                                -172.499479000135,
                                60.4321100000975
                            ],
                            [
                                -172.462602999618,
                                60.4386939999037
                            ],
                            [
                                -172.439591999642,
                                60.4365050001474
                            ],
                            [
                                -172.39356999969,
                                60.4365060000467
                            ],
                            [
                                -172.347542999714,
                                60.4277469999076
                            ],
                            [
                                -172.319928999883,
                                60.4167980001927
                            ],
                            [
                                -172.306121000142,
                                60.4058479998026
                            ],
                            [
                                -172.292314000226,
                                60.3992779998674
                            ],
                            [
                                -172.278507000311,
                                60.3883279999559
                            ],
                            [
                                -172.260098000365,
                                60.3795689998543
                            ],
                            [
                                -172.241687999695,
                                60.3729990000657
                            ],
                            [
                                -172.22327899975,
                                60.3642399999373
                            ],
                            [
                                -172.168051000087,
                                60.3489120000763
                            ],
                            [
                                -172.145039000285,
                                60.3401520001153
                            ],
                            [
                                -172.122024999936,
                                60.3270130000313
                            ],
                            [
                                -172.109090000057,
                                60.314012000131
                            ],
                            [
                                -172.108767000016,
                                60.303427999991
                            ],
                            [
                                -172.120377999948,
                                60.2873370002168
                            ],
                            [
                                -172.143520000344,
                                60.271451999831
                            ],
                            [
                                -172.162869000098,
                                60.2644499999926
                            ],
                            [
                                -172.16678400004,
                                60.2607070001119
                            ],
                            [
                                -172.18948799987,
                                60.2508159999088
                            ],
                            [
                                -172.21690099986,
                                60.2446039999655
                            ],
                            [
                                -172.246707000376,
                                60.2412289998367
                            ],
                            [
                                -172.267533000239,
                                60.2444980000395
                            ],
                            [
                                -172.29550899957,
                                60.238830000036
                            ],
                            [
                                -172.33310999981,
                                60.2445280001795
                            ],
                            [
                                -172.361583999982,
                                60.2520060000009
                            ],
                            [
                                -172.379187999839,
                                60.260713999802
                            ],
                            [
                                -172.388398999572,
                                60.275498000156
                            ],
                            [
                                -172.407525999568,
                                60.2787640000044
                            ],
                            [
                                -172.453839999599,
                                60.2706129999622
                            ],
                            [
                                -172.496040000394,
                                60.2742209998061
                            ],
                            [
                                -172.522963999764,
                                60.2710949998738
                            ],
                            [
                                -172.53422700044,
                                60.2652619997773
                            ],
                            [
                                -172.565013000048,
                                60.2580859998021
                            ],
                            [
                                -172.59612500007,
                                60.2586219999509
                            ],
                            [
                                -172.652480000037,
                                60.2697510002137
                            ],
                            [
                                -172.679355999727,
                                60.2780259998448
                            ],
                            [
                                -172.715375000276,
                                60.2962190000394
                            ],
                            [
                                -172.74442100001,
                                60.3010310000618
                            ],
                            [
                                -172.802381999957,
                                60.3186499999052
                            ],
                            [
                                -172.820133000127,
                                60.3161540001765
                            ],
                            [
                                -172.847945000326,
                                60.3183829998329
                            ],
                            [
                                -172.87777000021,
                                60.3263089998062
                            ],
                            [
                                -172.910227999921,
                                60.3396230001909
                            ],
                            [
                                -172.925359999609,
                                60.3525810002149
                            ],
                            [
                                -172.930970999943,
                                60.3705250000802
                            ],
                            [
                                -172.931452000167,
                                60.38968399986
                            ],
                            [
                                -172.985903000228,
                                60.4118480001224
                            ],
                            [
                                -173.067390999637,
                                60.4399049998917
                            ],
                            [
                                -173.095288000224,
                                60.4417950001196
                            ],
                            [
                                -173.144868999568,
                                60.4571009998533
                            ],
                            [
                                -173.158271000326,
                                60.4674920000131
                            ],
                            [
                                -173.16781799962,
                                60.4810799999921
                            ],
                            [
                                -173.170521999592,
                                60.4964840000436
                            ],
                            [
                                -173.165262999611,
                                60.5142920001793
                            ],
                            [
                                -173.156456999934,
                                60.5221400001843
                            ],
                            [
                                -173.163222000336,
                                60.538315999984
                            ],
                            [
                                -173.154023000298,
                                60.5645859999245
                            ],
                            [
                                -173.140223000055,
                                60.5755329997815
                            ],
                            [
                                -173.144823000436,
                                60.5820999998229
                            ],
                            [
                                -173.167825000191,
                                60.5930450001512
                            ],
                            [
                                -173.177025999879,
                                60.5996109998388
                            ],
                            [
                                -173.181626000259,
                                60.6061779998888
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -170.269724000079,
                                52.7293169999028
                            ],
                            [
                                -170.274913999565,
                                52.7376339999515
                            ],
                            [
                                -170.272992000115,
                                52.7485149998224
                            ],
                            [
                                -170.267285000421,
                                52.7642490001781
                            ],
                            [
                                -170.257792999582,
                                52.7736870001304
                            ],
                            [
                                -170.247019000424,
                                52.7788769998945
                            ],
                            [
                                -170.233194000067,
                                52.8001290001797
                            ],
                            [
                                -170.222603000309,
                                52.8106369997343
                            ],
                            [
                                -170.206764000441,
                                52.8227629998919
                            ],
                            [
                                -170.186407000208,
                                52.830524000104
                            ],
                            [
                                -170.152788999683,
                                52.835823999799
                            ],
                            [
                                -170.190133000004,
                                52.8628320000253
                            ],
                            [
                                -170.199687999694,
                                52.8800909998243
                            ],
                            [
                                -170.203380999878,
                                52.8976470000113
                            ],
                            [
                                -170.198591000425,
                                52.9164329998786
                            ],
                            [
                                -170.176890000153,
                                52.9351370001974
                            ],
                            [
                                -170.154397000211,
                                52.9445369998783
                            ],
                            [
                                -170.131382999862,
                                52.9568039997762
                            ],
                            [
                                -170.116518999962,
                                52.9631310002135
                            ],
                            [
                                -170.086578999552,
                                52.9712059999316
                            ],
                            [
                                -170.046106000009,
                                52.9731159999809
                            ],
                            [
                                -170.024953999733,
                                52.9711479997924
                            ],
                            [
                                -170.008746999618,
                                52.9677089998382
                            ],
                            [
                                -170.002275000017,
                                52.9647509999049
                            ],
                            [
                                -169.99553100043,
                                52.9665340001132
                            ],
                            [
                                -169.97743100028,
                                52.9655349997789
                            ],
                            [
                                -169.94174999984,
                                52.9587840001207
                            ],
                            [
                                -169.929753999944,
                                52.9515809999018
                            ],
                            [
                                -169.910493000053,
                                52.935214999827
                            ],
                            [
                                -169.90795899996,
                                52.9286149998385
                            ],
                            [
                                -169.908158000152,
                                52.9225489999237
                            ],
                            [
                                -169.88549500033,
                                52.9325830001942
                            ],
                            [
                                -169.831897999864,
                                52.9355379998091
                            ],
                            [
                                -169.827746000095,
                                52.9327920002372
                            ],
                            [
                                -169.817745000342,
                                52.9373550001971
                            ],
                            [
                                -169.827535000208,
                                52.9453660000342
                            ],
                            [
                                -169.842890000378,
                                52.9695160002144
                            ],
                            [
                                -169.843318,
                                52.9783760001575
                            ],
                            [
                                -169.832954999848,
                                53.0099649998007
                            ],
                            [
                                -169.852111999981,
                                53.0158560002172
                            ],
                            [
                                -169.858735000093,
                                53.0198320001929
                            ],
                            [
                                -169.865082999846,
                                53.0243720002088
                            ],
                            [
                                -169.883214999782,
                                53.0451870000483
                            ],
                            [
                                -169.880622000138,
                                53.0674990002619
                            ],
                            [
                                -169.872064000158,
                                53.0819750001934
                            ],
                            [
                                -169.872962000134,
                                53.089185999891
                            ],
                            [
                                -169.860753999627,
                                53.1050120000837
                            ],
                            [
                                -169.838534000395,
                                53.1162769999165
                            ],
                            [
                                -169.825715999793,
                                53.1202390001002
                            ],
                            [
                                -169.814087000317,
                                53.1266409998152
                            ],
                            [
                                -169.786158999768,
                                53.1326099997388
                            ],
                            [
                                -169.770196000052,
                                53.1343290000938
                            ],
                            [
                                -169.75914800036,
                                53.1340540000346
                            ],
                            [
                                -169.730731000089,
                                53.1278140001054
                            ],
                            [
                                -169.689860000163,
                                53.1120639999126
                            ],
                            [
                                -169.679298999644,
                                53.1036249999231
                            ],
                            [
                                -169.664233000078,
                                53.0856849999425
                            ],
                            [
                                -169.631042000248,
                                53.0774979998822
                            ],
                            [
                                -169.614070000228,
                                53.06980699994
                            ],
                            [
                                -169.604454000438,
                                53.0620309998528
                            ],
                            [
                                -169.580644000221,
                                53.0321649998106
                            ],
                            [
                                -169.578293000426,
                                53.0202080002359
                            ],
                            [
                                -169.580002000339,
                                53.0151099997626
                            ],
                            [
                                -169.575110999705,
                                53.0017720002381
                            ],
                            [
                                -169.575238999751,
                                52.9937069998136
                            ],
                            [
                                -169.580366000387,
                                52.9823900001265
                            ],
                            [
                                -169.587475999672,
                                52.9699599998329
                            ],
                            [
                                -169.595777999559,
                                52.9632379999656
                            ],
                            [
                                -169.607102000334,
                                52.9595669999783
                            ],
                            [
                                -169.606674999638,
                                52.9551650001787
                            ],
                            [
                                -169.612594999769,
                                52.9442979999477
                            ],
                            [
                                -169.619980000312,
                                52.936148000116
                            ],
                            [
                                -169.625413000371,
                                52.9317979999877
                            ],
                            [
                                -169.6336149998,
                                52.9290599999856
                            ],
                            [
                                -169.62782400044,
                                52.9267400000531
                            ],
                            [
                                -169.61488000034,
                                52.9120819999408
                            ],
                            [
                                -169.613657999601,
                                52.9068399997984
                            ],
                            [
                                -169.615641999873,
                                52.9033299999374
                            ],
                            [
                                -169.607553000421,
                                52.9019339997387
                            ],
                            [
                                -169.59338900013,
                                52.8895200002635
                            ],
                            [
                                -169.591677999669,
                                52.8805610000369
                            ],
                            [
                                -169.587860999637,
                                52.8728850001515
                            ],
                            [
                                -169.58822200021,
                                52.8397849998429
                            ],
                            [
                                -169.589412000263,
                                52.835366000253
                            ],
                            [
                                -169.592892999837,
                                52.831547999878
                            ],
                            [
                                -169.588860999719,
                                52.826796999996
                            ],
                            [
                                -169.586601000161,
                                52.8141379999546
                            ],
                            [
                                -169.588613000022,
                                52.8005630000737
                            ],
                            [
                                -169.610160000309,
                                52.7841950001547
                            ],
                            [
                                -169.618790000259,
                                52.7806390000228
                            ],
                            [
                                -169.617057000057,
                                52.7759469998567
                            ],
                            [
                                -169.624167000241,
                                52.7623720000661
                            ],
                            [
                                -169.627461000217,
                                52.7506750000621
                            ],
                            [
                                -169.639088000042,
                                52.7367560002615
                            ],
                            [
                                -169.659719999736,
                                52.7262260000508
                            ],
                            [
                                -169.682274999603,
                                52.7190980000744
                            ],
                            [
                                -169.703061000181,
                                52.7160989999596
                            ],
                            [
                                -169.738038999741,
                                52.7209869998925
                            ],
                            [
                                -169.784986000332,
                                52.7367340001797
                            ],
                            [
                                -169.804339000284,
                                52.7462020001393
                            ],
                            [
                                -169.820320000442,
                                52.7594269999185
                            ],
                            [
                                -169.826257000291,
                                52.7581510001614
                            ],
                            [
                                -169.848754000431,
                                52.763109999895
                            ],
                            [
                                -169.857056000318,
                                52.7559060001611
                            ],
                            [
                                -169.876314999661,
                                52.7467260000845
                            ],
                            [
                                -169.903845999652,
                                52.7405739999075
                            ],
                            [
                                -169.933148000377,
                                52.7382160002549
                            ],
                            [
                                -169.975546999568,
                                52.7381180000713
                            ],
                            [
                                -169.97407899968,
                                52.7350020000807
                            ],
                            [
                                -169.977374000379,
                                52.7190010000124
                            ],
                            [
                                -169.981798999959,
                                52.7120199997984
                            ],
                            [
                                -170.019308999962,
                                52.6823670000623
                            ],
                            [
                                -170.032586000148,
                                52.6757440002527
                            ],
                            [
                                -170.064971000064,
                                52.6665250001931
                            ],
                            [
                                -170.0865810001,
                                52.6626179997563
                            ],
                            [
                                -170.119699000135,
                                52.6617389997535
                            ],
                            [
                                -170.156474000369,
                                52.6644029999475
                            ],
                            [
                                -170.173754000361,
                                52.6628879999551
                            ],
                            [
                                -170.196463000214,
                                52.6658169997751
                            ],
                            [
                                -170.231870999944,
                                52.6739180001236
                            ],
                            [
                                -170.250063000155,
                                52.6810889998116
                            ],
                            [
                                -170.263587000214,
                                52.6973249999413
                            ],
                            [
                                -170.267434000383,
                                52.713843999954
                            ],
                            [
                                -170.267404000246,
                                52.7247630000472
                            ],
                            [
                                -170.269724000079,
                                52.7293169999028
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                178.576921000443,
                                51.5872360000873
                            ],
                            [
                                178.563209000064,
                                51.5998299999059
                            ],
                            [
                                178.551773000034,
                                51.5998550000217
                            ],
                            [
                                178.530624000131,
                                51.6247280001561
                            ],
                            [
                                178.53181300036,
                                51.6496920002042
                            ],
                            [
                                178.538801000345,
                                51.6709560000304
                            ],
                            [
                                178.546735999811,
                                51.6880699998725
                            ],
                            [
                                178.55949199959,
                                51.7016519999624
                            ],
                            [
                                178.584609000037,
                                51.7118839998293
                            ],
                            [
                                178.607285000278,
                                51.7165230000127
                            ],
                            [
                                178.643115999607,
                                51.7153559999182
                            ],
                            [
                                178.698784000385,
                                51.7095660001212
                            ],
                            [
                                178.737019000111,
                                51.6949380000215
                            ],
                            [
                                178.769927000084,
                                51.6978089999026
                            ],
                            [
                                178.79275700031,
                                51.6927280000303
                            ],
                            [
                                178.810064000066,
                                51.6868980002435
                            ],
                            [
                                178.827032999713,
                                51.6869970001371
                            ],
                            [
                                178.841196000179,
                                51.6808860002369
                            ],
                            [
                                178.865674000219,
                                51.6756140001482
                            ],
                            [
                                178.893473999823,
                                51.6744330000077
                            ],
                            [
                                178.922066999821,
                                51.6686279998018
                            ],
                            [
                                178.953039999925,
                                51.6567199998968
                            ],
                            [
                                178.972427000213,
                                51.646442000109
                            ],
                            [
                                178.984163999643,
                                51.647308000147
                            ],
                            [
                                178.999512999964,
                                51.6424780001622
                            ],
                            [
                                179.015171000083,
                                51.6455750002286
                            ],
                            [
                                179.040168999806,
                                51.640035000233
                            ],
                            [
                                179.06061999975,
                                51.6325099999257
                            ],
                            [
                                179.077925999681,
                                51.6211100001096
                            ],
                            [
                                179.088855000446,
                                51.6061999999537
                            ],
                            [
                                179.087442999736,
                                51.5835329999713
                            ],
                            [
                                179.094748999738,
                                51.5760299997616
                            ],
                            [
                                179.116482999622,
                                51.5691679998627
                            ],
                            [
                                179.150548000037,
                                51.5372960000611
                            ],
                            [
                                179.170325000311,
                                51.5230279997686
                            ],
                            [
                                179.187160999889,
                                51.5169219998901
                            ],
                            [
                                179.197426000132,
                                51.5181300002578
                            ],
                            [
                                179.226814999998,
                                51.5129340002733
                            ],
                            [
                                179.247903999626,
                                51.503091000258
                            ],
                            [
                                179.26456699985,
                                51.4923030000551
                            ],
                            [
                                179.274671000434,
                                51.4787469997528
                            ],
                            [
                                179.287067000364,
                                51.469823000043
                            ],
                            [
                                179.304640000258,
                                51.4705000000691
                            ],
                            [
                                179.331893000415,
                                51.4667720002027
                            ],
                            [
                                179.35556899984,
                                51.4581269998255
                            ],
                            [
                                179.371079999645,
                                51.4651869999642
                            ],
                            [
                                179.396746000088,
                                51.4655550002433
                            ],
                            [
                                179.425221000085,
                                51.4628170000607
                            ],
                            [
                                179.45214000033,
                                51.4506799998863
                            ],
                            [
                                179.477655000262,
                                51.4310809999652
                            ],
                            [
                                179.4924880002,
                                51.4296159998735
                            ],
                            [
                                179.522518999948,
                                51.4191489999074
                            ],
                            [
                                179.535369000337,
                                51.4065230000023
                            ],
                            [
                                179.553193000302,
                                51.400007999938
                            ],
                            [
                                179.576908000085,
                                51.376814000028
                            ],
                            [
                                179.57840600011,
                                51.3543440000104
                            ],
                            [
                                179.568000000301,
                                51.3393739998562
                            ],
                            [
                                179.543677999897,
                                51.3191469997499
                            ],
                            [
                                179.510870000382,
                                51.3082649999963
                            ],
                            [
                                179.479616999894,
                                51.3114459998425
                            ],
                            [
                                179.459386000058,
                                51.3160260001449
                            ],
                            [
                                179.452333999601,
                                51.3131599997756
                            ],
                            [
                                179.403074999574,
                                51.3068489998357
                            ],
                            [
                                179.371671000372,
                                51.3070389997801
                            ],
                            [
                                179.356980999651,
                                51.310721000242
                            ],
                            [
                                179.358242999675,
                                51.3059160000813
                            ],
                            [
                                179.338222999726,
                                51.3050529998532
                            ],
                            [
                                179.309291999619,
                                51.3062889999752
                            ],
                            [
                                179.298079999896,
                                51.3110190002306
                            ],
                            [
                                179.296780000237,
                                51.3066999997397
                            ],
                            [
                                179.282070000323,
                                51.2998169997699
                            ],
                            [
                                179.255520000149,
                                51.2936749999082
                            ],
                            [
                                179.218195999918,
                                51.2949849999415
                            ],
                            [
                                179.181053000336,
                                51.3054499999336
                            ],
                            [
                                179.150655000167,
                                51.3163439998782
                            ],
                            [
                                179.129993000335,
                                51.3334009999553
                            ],
                            [
                                179.122027000009,
                                51.3531999999284
                            ],
                            [
                                179.101940000113,
                                51.3660949997442
                            ],
                            [
                                179.032733999932,
                                51.3994300000088
                            ],
                            [
                                178.99570199978,
                                51.4220429998051
                            ],
                            [
                                178.969361999667,
                                51.4287730002372
                            ],
                            [
                                178.9502239998,
                                51.445055999807
                            ],
                            [
                                178.945419000279,
                                51.4606320001367
                            ],
                            [
                                178.916740000067,
                                51.4773770002054
                            ],
                            [
                                178.896012000114,
                                51.4925350002169
                            ],
                            [
                                178.892933000223,
                                51.4903010001136
                            ],
                            [
                                178.872668999876,
                                51.489698000028
                            ],
                            [
                                178.862011000172,
                                51.4941340000754
                            ],
                            [
                                178.857563000127,
                                51.4917739998779
                            ],
                            [
                                178.823535000421,
                                51.4875920002353
                            ],
                            [
                                178.790240999761,
                                51.4924579999349
                            ],
                            [
                                178.762011999811,
                                51.5075800000543
                            ],
                            [
                                178.740742000432,
                                51.5235400002266
                            ],
                            [
                                178.707996999768,
                                51.5373939997285
                            ],
                            [
                                178.667224999576,
                                51.5427430000881
                            ],
                            [
                                178.641222999572,
                                51.5558340001752
                            ],
                            [
                                178.624560000247,
                                51.5690400000206
                            ],
                            [
                                178.593493000431,
                                51.5782489998722
                            ],
                            [
                                178.576921000443,
                                51.5872360000873
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                177.169023999809,
                                51.829289000078
                            ],
                            [
                                177.15834099999,
                                51.8335860002274
                            ],
                            [
                                177.12900999985,
                                51.8499810002469
                            ],
                            [
                                177.119609000146,
                                51.8587329997921
                            ],
                            [
                                177.112006999868,
                                51.8796609997859
                            ],
                            [
                                177.111975999906,
                                51.8905419999772
                            ],
                            [
                                177.121343000173,
                                51.9178709997312
                            ],
                            [
                                177.139623000247,
                                51.9355880001548
                            ],
                            [
                                177.165961999637,
                                51.9490319998307
                            ],
                            [
                                177.209636000167,
                                51.9628350000757
                            ],
                            [
                                177.224591000304,
                                51.9698009997513
                            ],
                            [
                                177.253950999857,
                                51.9784539998192
                            ],
                            [
                                177.260015999753,
                                51.9787200002668
                            ],
                            [
                                177.264267000154,
                                51.9862360001267
                            ],
                            [
                                177.272018000395,
                                51.9939810002202
                            ],
                            [
                                177.287431000291,
                                52.0054560000103
                            ],
                            [
                                177.305529999717,
                                52.0151920000216
                            ],
                            [
                                177.320850999551,
                                52.021227999889
                            ],
                            [
                                177.348290000379,
                                52.0283860000914
                            ],
                            [
                                177.382076000236,
                                52.0292490000586
                            ],
                            [
                                177.41643100018,
                                52.0381280001641
                            ],
                            [
                                177.414672000039,
                                52.0423050002027
                            ],
                            [
                                177.414244000417,
                                52.0531850001531
                            ],
                            [
                                177.418027999939,
                                52.0665600001641
                            ],
                            [
                                177.425994000266,
                                52.0777829999716
                            ],
                            [
                                177.451509000198,
                                52.093974999729
                            ],
                            [
                                177.460633999717,
                                52.0946329998874
                            ],
                            [
                                177.459047999828,
                                52.0985609997956
                            ],
                            [
                                177.464572000124,
                                52.1089979997771
                            ],
                            [
                                177.469180000002,
                                52.1240210002051
                            ],
                            [
                                177.487675000161,
                                52.1400830002655
                            ],
                            [
                                177.498080999971,
                                52.151627999954
                            ],
                            [
                                177.510775999651,
                                52.1610970001076
                            ],
                            [
                                177.530643000337,
                                52.1706729998798
                            ],
                            [
                                177.57324800029,
                                52.187155000256
                            ],
                            [
                                177.586706000227,
                                52.1895060000179
                            ],
                            [
                                177.607764999718,
                                52.1894380000325
                            ],
                            [
                                177.643992999606,
                                52.184427000021
                            ],
                            [
                                177.703781000365,
                                52.163025999793
                            ],
                            [
                                177.730456000213,
                                52.1508030000335
                            ],
                            [
                                177.745654999848,
                                52.1416019997605
                            ],
                            [
                                177.764608999592,
                                52.1209709997986
                            ],
                            [
                                177.769614999854,
                                52.1063750000875
                            ],
                            [
                                177.767907999591,
                                52.0765040001758
                            ],
                            [
                                177.76043199971,
                                52.0611680000091
                            ],
                            [
                                177.734613999829,
                                52.0381179999974
                            ],
                            [
                                177.726373999866,
                                52.0360269998357
                            ],
                            [
                                177.72610000023,
                                52.0325329997808
                            ],
                            [
                                177.708308000052,
                                52.009855999849
                            ],
                            [
                                177.703157000026,
                                52.0070350000559
                            ],
                            [
                                177.741759999998,
                                51.9955350000437
                            ],
                            [
                                177.748441999661,
                                51.9980690002104
                            ],
                            [
                                177.770537000119,
                                52.0014560002625
                            ],
                            [
                                177.793395999759,
                                51.9995649999975
                            ],
                            [
                                177.811556000183,
                                51.9953080001205
                            ],
                            [
                                177.845706999913,
                                51.9837790000504
                            ],
                            [
                                177.857426999624,
                                51.9751729998472
                            ],
                            [
                                177.874000000335,
                                51.9560980001009
                            ],
                            [
                                177.874093000221,
                                51.9439509998849
                            ],
                            [
                                177.860817999685,
                                51.9222819997846
                            ],
                            [
                                177.851449999593,
                                51.9119589999512
                            ],
                            [
                                177.837076000137,
                                51.9022690002455
                            ],
                            [
                                177.819191000073,
                                51.8953559997597
                            ],
                            [
                                177.790596000426,
                                51.8923949999374
                            ],
                            [
                                177.762609000325,
                                51.8950270001138
                            ],
                            [
                                177.735965000439,
                                51.9021280000571
                            ],
                            [
                                177.721743000422,
                                51.8971469997932
                            ],
                            [
                                177.695587000433,
                                51.8945900000877
                            ],
                            [
                                177.687262000081,
                                51.8954260002581
                            ],
                            [
                                177.680511999747,
                                51.8883180002167
                            ],
                            [
                                177.669218999833,
                                51.8814420001615
                            ],
                            [
                                177.654904999754,
                                51.875155000244
                            ],
                            [
                                177.635464999763,
                                51.8706979998908
                            ],
                            [
                                177.604639999796,
                                51.8710859999162
                            ],
                            [
                                177.598595999817,
                                51.873199999851
                            ],
                            [
                                177.570611000265,
                                51.863929000256
                            ],
                            [
                                177.546407999687,
                                51.8616160002639
                            ],
                            [
                                177.522358999994,
                                51.8647289997835
                            ],
                            [
                                177.519250999791,
                                51.8669189997249
                            ],
                            [
                                177.504351999731,
                                51.8628670001018
                            ],
                            [
                                177.47563200041,
                                51.8604009998005
                            ],
                            [
                                177.455489000437,
                                51.8613549997551
                            ],
                            [
                                177.433543999767,
                                51.8650090000013
                            ],
                            [
                                177.416240000384,
                                51.8712170001552
                            ],
                            [
                                177.41004200042,
                                51.8337139999451
                            ],
                            [
                                177.419354000435,
                                51.8267440001412
                            ],
                            [
                                177.420300999916,
                                51.8190080002432
                            ],
                            [
                                177.420911000012,
                                51.8131019999933
                            ],
                            [
                                177.415205000142,
                                51.7910439997849
                            ],
                            [
                                177.403790999853,
                                51.7790409997994
                            ],
                            [
                                177.396222000085,
                                51.773884000246
                            ],
                            [
                                177.371622999672,
                                51.7618579997823
                            ],
                            [
                                177.345315000245,
                                51.7577379999421
                            ],
                            [
                                177.308719999936,
                                51.7596300000641
                            ],
                            [
                                177.2986179999,
                                51.7628950001778
                            ],
                            [
                                177.26174800013,
                                51.7824120000963
                            ],
                            [
                                177.254929000199,
                                51.7897079997786
                            ],
                            [
                                177.249416999599,
                                51.7890120001846
                            ],
                            [
                                177.242336999553,
                                51.7905379997503
                            ],
                            [
                                177.230799000314,
                                51.7983890002718
                            ],
                            [
                                177.215720000329,
                                51.8134719999213
                            ],
                            [
                                177.213462000421,
                                51.817723000223
                            ],
                            [
                                177.201710999849,
                                51.8221400002195
                            ],
                            [
                                177.200320999779,
                                51.8244100000458
                            ],
                            [
                                177.189105999682,
                                51.8248410001999
                            ],
                            [
                                177.169023999809,
                                51.829289000078
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -162.952627000303,
                                54.3257300001045
                            ],
                            [
                                -162.983265999599,
                                54.3377760000416
                            ],
                            [
                                -163.004750999961,
                                54.3515800001868
                            ],
                            [
                                -163.01396800044,
                                54.3670830001684
                            ],
                            [
                                -163.013693999906,
                                54.3850869998269
                            ],
                            [
                                -163.008445999795,
                                54.400172000015
                            ],
                            [
                                -162.995049999783,
                                54.4147900000132
                            ],
                            [
                                -162.959878000054,
                                54.4280169998795
                            ],
                            [
                                -162.963189999573,
                                54.431323000233
                            ],
                            [
                                -162.965722999841,
                                54.4372749999287
                            ],
                            [
                                -162.964593999887,
                                54.4462469999169
                            ],
                            [
                                -162.949366999764,
                                54.4673720001825
                            ],
                            [
                                -162.940975000364,
                                54.475362000016
                            ],
                            [
                                -162.93557999994,
                                54.4777200001138
                            ],
                            [
                                -162.94024300007,
                                54.4843340000091
                            ],
                            [
                                -162.94482099981,
                                54.505375999919
                            ],
                            [
                                -162.938839999581,
                                54.5211449999396
                            ],
                            [
                                -162.924985999809,
                                54.5335809999573
                            ],
                            [
                                -162.906827999934,
                                54.5440049998494
                            ],
                            [
                                -162.880706000279,
                                54.5532130001679
                            ],
                            [
                                -162.860626000056,
                                54.5543109998955
                            ],
                            [
                                -162.844327999705,
                                54.5530760002113
                            ],
                            [
                                -162.824491999879,
                                54.5492540000406
                            ],
                            [
                                -162.799581000195,
                                54.5395220001283
                            ],
                            [
                                -162.770015999704,
                                54.5378479999612
                            ],
                            [
                                -162.741877999991,
                                54.5311880002035
                            ],
                            [
                                -162.733088000207,
                                54.5265879998593
                            ],
                            [
                                -162.722529000236,
                                54.5298069998291
                            ],
                            [
                                -162.691582999896,
                                54.5263880000317
                            ],
                            [
                                -162.663748999956,
                                54.5127939997451
                            ],
                            [
                                -162.658529000333,
                                54.5146019998772
                            ],
                            [
                                -162.635946999804,
                                54.5118239998026
                            ],
                            [
                                -162.610573000337,
                                54.5004219997791
                            ],
                            [
                                -162.606403999952,
                                54.503813999742
                            ],
                            [
                                -162.600087000162,
                                54.5054470001917
                            ],
                            [
                                -162.582691999644,
                                54.5057670000877
                            ],
                            [
                                -162.545184000189,
                                54.4970250000757
                            ],
                            [
                                -162.507919000408,
                                54.4819799999616
                            ],
                            [
                                -162.505723000424,
                                54.4780199999758
                            ],
                            [
                                -162.476060000024,
                                54.4791269999571
                            ],
                            [
                                -162.456619000208,
                                54.4782180001826
                            ],
                            [
                                -162.441940000256,
                                54.4753360002211
                            ],
                            [
                                -162.422835000001,
                                54.468483999893
                            ],
                            [
                                -162.41166699976,
                                54.4591250000042
                            ],
                            [
                                -162.407362999656,
                                54.4620460002112
                            ],
                            [
                                -162.395612999807,
                                54.4642650001891
                            ],
                            [
                                -162.37544100042,
                                54.4620220000998
                            ],
                            [
                                -162.354351999893,
                                54.456690999773
                            ],
                            [
                                -162.31565500021,
                                54.4390600000023
                            ],
                            [
                                -162.277751000021,
                                54.4193679997967
                            ],
                            [
                                -162.248208999994,
                                54.399265000011
                            ],
                            [
                                -162.241463999684,
                                54.3885829998508
                            ],
                            [
                                -162.238380999595,
                                54.3699209998037
                            ],
                            [
                                -162.243569000329,
                                54.3567609998016
                            ],
                            [
                                -162.250158999931,
                                54.3467429999843
                            ],
                            [
                                -162.262092000078,
                                54.3384259999637
                            ],
                            [
                                -162.282203000264,
                                54.3309649999322
                            ],
                            [
                                -162.337409000185,
                                54.3257300001045
                            ],
                            [
                                -162.358960999596,
                                54.3285230001447
                            ],
                            [
                                -162.404303999856,
                                54.3057920000848
                            ],
                            [
                                -162.429359000379,
                                54.2985140001986
                            ],
                            [
                                -162.462623999829,
                                54.2964680001389
                            ],
                            [
                                -162.477060999931,
                                54.2989210001356
                            ],
                            [
                                -162.485726000042,
                                54.2948739997649
                            ],
                            [
                                -162.492287000229,
                                54.2938280001175
                            ],
                            [
                                -162.526587999747,
                                54.2579840002279
                            ],
                            [
                                -162.54694300033,
                                54.2480200001342
                            ],
                            [
                                -162.563147000071,
                                54.243578999762
                            ],
                            [
                                -162.591834999605,
                                54.2439979999063
                            ],
                            [
                                -162.623361999729,
                                54.2525170002548
                            ],
                            [
                                -162.63044099995,
                                54.2492540000073
                            ],
                            [
                                -162.652108999712,
                                54.2482550001635
                            ],
                            [
                                -162.659890999916,
                                54.2422810002584
                            ],
                            [
                                -162.700083999975,
                                54.237969000006
                            ],
                            [
                                -162.726482999638,
                                54.24206500005
                            ],
                            [
                                -162.746593999824,
                                54.2526480000897
                            ],
                            [
                                -162.768203000034,
                                54.2704400002055
                            ],
                            [
                                -162.779969999602,
                                54.2932150001802
                            ],
                            [
                                -162.788469000031,
                                54.2944500000666
                            ],
                            [
                                -162.798417999906,
                                54.2982489999473
                            ],
                            [
                                -162.807664999624,
                                54.3037029997968
                            ],
                            [
                                -162.817919999821,
                                54.3148200002578
                            ],
                            [
                                -162.831592999842,
                                54.3160019998293
                            ],
                            [
                                -162.850729000059,
                                54.322503000144
                            ],
                            [
                                -162.86659899989,
                                54.3315120002428
                            ],
                            [
                                -162.875173999588,
                                54.3390160000517
                            ],
                            [
                                -162.878897999735,
                                54.3357160001523
                            ],
                            [
                                -162.8900669998,
                                54.3307659999716
                            ],
                            [
                                -162.921987000284,
                                54.3260200002567
                            ],
                            [
                                -162.952627000303,
                                54.3257300001045
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                173.280576999958,
                                52.3775869998211
                            ],
                            [
                                173.274502999842,
                                52.3891589999657
                            ],
                            [
                                173.272581000393,
                                52.397475999859
                            ],
                            [
                                173.274409999956,
                                52.4166329997356
                            ],
                            [
                                173.287744999869,
                                52.4289249999448
                            ],
                            [
                                173.298119999716,
                                52.4416960001147
                            ],
                            [
                                173.300001999881,
                                52.450159999944
                            ],
                            [
                                173.315940000381,
                                52.4646380000474
                            ],
                            [
                                173.336690000076,
                                52.4761519999247
                            ],
                            [
                                173.370013999976,
                                52.4829509999681
                            ],
                            [
                                173.384417999568,
                                52.4949909999776
                            ],
                            [
                                173.393662999636,
                                52.499752000115
                            ],
                            [
                                173.407183000395,
                                52.5030939998149
                            ],
                            [
                                173.416519999627,
                                52.5036739999503
                            ],
                            [
                                173.421586000163,
                                52.5072830002345
                            ],
                            [
                                173.431289999815,
                                52.5086639997324
                            ],
                            [
                                173.453323000348,
                                52.5076040000936
                            ],
                            [
                                173.480492999766,
                                52.5025659997541
                            ],
                            [
                                173.483930999682,
                                52.5077040000374
                            ],
                            [
                                173.49870099987,
                                52.5169220001488
                            ],
                            [
                                173.534739999613,
                                52.5283359997925
                            ],
                            [
                                173.548533000183,
                                52.5416729998794
                            ],
                            [
                                173.559031000054,
                                52.5470979998888
                            ],
                            [
                                173.584360000213,
                                52.5541650000483
                            ],
                            [
                                173.608254000096,
                                52.5598730002379
                            ],
                            [
                                173.626442000109,
                                52.5591260002257
                            ],
                            [
                                173.639085999736,
                                52.5568739997402
                            ],
                            [
                                173.646826000106,
                                52.5613169997469
                            ],
                            [
                                173.656713000057,
                                52.5641099998592
                            ],
                            [
                                173.670535999866,
                                52.5646680000507
                            ],
                            [
                                173.692232000116,
                                52.5613970001075
                            ],
                            [
                                173.778133000308,
                                52.5661019999502
                            ],
                            [
                                173.804163999725,
                                52.5609759998157
                            ],
                            [
                                173.825649000087,
                                52.5460610001422
                            ],
                            [
                                173.847773999783,
                                52.5371849998776
                            ],
                            [
                                173.855586999949,
                                52.5283300002364
                            ],
                            [
                                173.856350000205,
                                52.5219609999685
                            ],
                            [
                                173.854916999579,
                                52.513850999742
                            ],
                            [
                                173.844605000379,
                                52.4888719998196
                            ],
                            [
                                173.812443000046,
                                52.4627859999183
                            ],
                            [
                                173.792639000007,
                                52.4526530001561
                            ],
                            [
                                173.780586000209,
                                52.4507839999868
                            ],
                            [
                                173.781196000305,
                                52.447954000065
                            ],
                            [
                                173.790992999844,
                                52.4394470000238
                            ],
                            [
                                173.794593999967,
                                52.4332750002188
                            ],
                            [
                                173.79816900015,
                                52.3924560000223
                            ],
                            [
                                173.80174099996,
                                52.3780889998309
                            ],
                            [
                                173.807020999858,
                                52.3671950001634
                            ],
                            [
                                173.811293000175,
                                52.3630820001538
                            ],
                            [
                                173.814071999592,
                                52.3533470000031
                            ],
                            [
                                173.794269000277,
                                52.3250099998808
                            ],
                            [
                                173.781452000398,
                                52.3152900000949
                            ],
                            [
                                173.766560999835,
                                52.3067440000395
                            ],
                            [
                                173.747703000351,
                                52.2999679999877
                            ],
                            [
                                173.73079599973,
                                52.2975719999686
                            ],
                            [
                                173.713705999709,
                                52.2980980001231
                            ],
                            [
                                173.692400000345,
                                52.3018759998242
                            ],
                            [
                                173.681632000137,
                                52.294877000162
                            ],
                            [
                                173.672598999781,
                                52.2924730001615
                            ],
                            [
                                173.638632999999,
                                52.2942500001915
                            ],
                            [
                                173.624380999844,
                                52.2978120000328
                            ],
                            [
                                173.597433000185,
                                52.3080039997982
                            ],
                            [
                                173.58107499956,
                                52.3191499999741
                            ],
                            [
                                173.548268999694,
                                52.3248859998789
                            ],
                            [
                                173.53369900042,
                                52.3317699998382
                            ],
                            [
                                173.481589999933,
                                52.3266679997668
                            ],
                            [
                                173.462272999966,
                                52.3276969999544
                            ],
                            [
                                173.447502999777,
                                52.3304439997436
                            ],
                            [
                                173.435753999753,
                                52.3346469998406
                            ],
                            [
                                173.379545000274,
                                52.338139000208
                            ],
                            [
                                173.349118999617,
                                52.3491699999186
                            ],
                            [
                                173.321532000449,
                                52.349977000162
                            ],
                            [
                                173.304044999869,
                                52.3564529997895
                            ],
                            [
                                173.289488000116,
                                52.3680580000337
                            ],
                            [
                                173.280576999958,
                                52.3775869998211
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -170.510802999891,
                                57.1742619998718
                            ],
                            [
                                -170.516296000224,
                                57.1759779999277
                            ],
                            [
                                -170.520936999714,
                                57.1822119998251
                            ],
                            [
                                -170.522709000273,
                                57.1933740000207
                            ],
                            [
                                -170.509924000182,
                                57.2083070001454
                            ],
                            [
                                -170.490090000006,
                                57.2255369999167
                            ],
                            [
                                -170.47040899999,
                                57.2370910001758
                            ],
                            [
                                -170.460229999961,
                                57.2387420001317
                            ],
                            [
                                -170.449598000196,
                                57.2493320000084
                            ],
                            [
                                -170.438184999733,
                                57.2548790002411
                            ],
                            [
                                -170.423048000021,
                                57.2575059999855
                            ],
                            [
                                -170.395332999908,
                                57.2579500002371
                            ],
                            [
                                -170.387220999991,
                                57.2625150000585
                            ],
                            [
                                -170.364059000402,
                                57.2687349999344
                            ],
                            [
                                -170.334457000101,
                                57.2723679999614
                            ],
                            [
                                -170.290815000256,
                                57.2705940000197
                            ],
                            [
                                -170.261696999653,
                                57.2641339999046
                            ],
                            [
                                -170.232980999632,
                                57.2738110002015
                            ],
                            [
                                -170.222928999824,
                                57.2751639997714
                            ],
                            [
                                -170.221843000426,
                                57.2786559998879
                            ],
                            [
                                -170.206921999727,
                                57.2906960001578
                            ],
                            [
                                -170.191024000307,
                                57.2973510001036
                            ],
                            [
                                -170.158889999563,
                                57.30437200023
                            ],
                            [
                                -170.109480999748,
                                57.3052690001707
                            ],
                            [
                                -170.087293000302,
                                57.3029130000004
                            ],
                            [
                                -170.065378999595,
                                57.2979260000646
                            ],
                            [
                                -170.039924999763,
                                57.2838790001727
                            ],
                            [
                                -170.016511000279,
                                57.2665809999965
                            ],
                            [
                                -170.012356999962,
                                57.2540919998552
                            ],
                            [
                                -170.018487000154,
                                57.2367269997898
                            ],
                            [
                                -170.029775000046,
                                57.2208559998934
                            ],
                            [
                                -170.046618000195,
                                57.2080359999213
                            ],
                            [
                                -170.063858000004,
                                57.1976200000775
                            ],
                            [
                                -170.08080300026,
                                57.1922729999911
                            ],
                            [
                                -170.077248999993,
                                57.1793540001866
                            ],
                            [
                                -170.078314000373,
                                57.1694580000404
                            ],
                            [
                                -170.111782000212,
                                57.1367090000926
                            ],
                            [
                                -170.137076000211,
                                57.1221260001237
                            ],
                            [
                                -170.147680000388,
                                57.1214640002186
                            ],
                            [
                                -170.153553999589,
                                57.1172489998579
                            ],
                            [
                                -170.165270999826,
                                57.1134400000108
                            ],
                            [
                                -170.223246000017,
                                57.0765960002405
                            ],
                            [
                                -170.258919000061,
                                57.0621250000897
                            ],
                            [
                                -170.295725000257,
                                57.0561689998931
                            ],
                            [
                                -170.305368999636,
                                57.0567410001491
                            ],
                            [
                                -170.300942000406,
                                57.0486830001722
                            ],
                            [
                                -170.30097099982,
                                57.0417550000852
                            ],
                            [
                                -170.306095999907,
                                57.0295150001851
                            ],
                            [
                                -170.324222999821,
                                57.017969000045
                            ],
                            [
                                -170.354132000268,
                                57.0023230002011
                            ],
                            [
                                -170.39946200011,
                                56.9957720000064
                            ],
                            [
                                -170.444143000396,
                                56.9961339999525
                            ],
                            [
                                -170.478294999951,
                                57.0071950002418
                            ],
                            [
                                -170.487283999926,
                                57.0125179997951
                            ],
                            [
                                -170.501984999619,
                                57.0331810001603
                            ],
                            [
                                -170.508488000081,
                                57.0464039998386
                            ],
                            [
                                -170.50837000008,
                                57.0623739998163
                            ],
                            [
                                -170.492044999965,
                                57.0801769997571
                            ],
                            [
                                -170.469524000434,
                                57.0927680001369
                            ],
                            [
                                -170.442668999762,
                                57.1022629999029
                            ],
                            [
                                -170.414797000188,
                                57.1047340001768
                            ],
                            [
                                -170.448254999982,
                                57.108495999979
                            ],
                            [
                                -170.470656999687,
                                57.1136520001085
                            ],
                            [
                                -170.495258999575,
                                57.1283079999222
                            ],
                            [
                                -170.509880999626,
                                57.1438180000175
                            ],
                            [
                                -170.519468000001,
                                57.1621830002089
                            ],
                            [
                                -170.510802999891,
                                57.1742619998718
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -152.460704000323,
                                58.8954490000064
                            ],
                            [
                                -152.465430000202,
                                58.9063420002053
                            ],
                            [
                                -152.466527000369,
                                58.9149950001936
                            ],
                            [
                                -152.464855000267,
                                58.9242199999789
                            ],
                            [
                                -152.452071999825,
                                58.9394799999828
                            ],
                            [
                                -152.43748599976,
                                58.948423999874
                            ],
                            [
                                -152.43983200043,
                                58.9547390000534
                            ],
                            [
                                -152.440900000284,
                                58.9660879998814
                            ],
                            [
                                -152.437370000307,
                                58.9735260001501
                            ],
                            [
                                -152.429410999653,
                                58.981402999852
                            ],
                            [
                                -152.414022000047,
                                58.9922390001013
                            ],
                            [
                                -152.405968999682,
                                58.9942279998926
                            ],
                            [
                                -152.39385599961,
                                58.9939560001248
                            ],
                            [
                                -152.369086000391,
                                59.0016999998185
                            ],
                            [
                                -152.352748999682,
                                59.004867000204
                            ],
                            [
                                -152.323450000228,
                                59.0074589998722
                            ],
                            [
                                -152.294710999742,
                                59.0066259998856
                            ],
                            [
                                -152.272724999962,
                                59.003202000056
                            ],
                            [
                                -152.245650999906,
                                58.9934569998251
                            ],
                            [
                                -152.236625999946,
                                58.9957450000354
                            ],
                            [
                                -152.228754000229,
                                59.0017769998412
                            ],
                            [
                                -152.20849599973,
                                59.0101129999741
                            ],
                            [
                                -152.188222000236,
                                59.0161439997788
                            ],
                            [
                                -152.170189000034,
                                59.0170240000437
                            ],
                            [
                                -152.145403000023,
                                59.0178869998274
                            ],
                            [
                                -152.129050000319,
                                59.0167510002127
                            ],
                            [
                                -152.083367000301,
                                59.0050209997872
                            ],
                            [
                                -152.058933999569,
                                59.0119950000928
                            ],
                            [
                                -152.040389000079,
                                59.0128900001542
                            ],
                            [
                                -152.01955499982,
                                59.0109210000943
                            ],
                            [
                                -151.995346000293,
                                59.0052090000085
                            ],
                            [
                                -151.964916000336,
                                58.9923429998305
                            ],
                            [
                                -151.937183999606,
                                58.9883000000692
                            ],
                            [
                                -151.917654999926,
                                58.9752039998519
                            ],
                            [
                                -151.867775999758,
                                58.9464109998704
                            ],
                            [
                                -151.857590000056,
                                58.9359569998455
                            ],
                            [
                                -151.851640999614,
                                58.9173479998579
                            ],
                            [
                                -151.853889000375,
                                58.9087639999851
                            ],
                            [
                                -151.860673000145,
                                58.8999400001546
                            ],
                            [
                                -151.883201000248,
                                58.8806830000578
                            ],
                            [
                                -151.891805000259,
                                58.8763910001618
                            ],
                            [
                                -151.912046999965,
                                58.8704099999462
                            ],
                            [
                                -151.936386999912,
                                58.8673800000683
                            ],
                            [
                                -151.951573999852,
                                58.8521880001895
                            ],
                            [
                                -151.964883999651,
                                58.8447769999912
                            ],
                            [
                                -151.981582000035,
                                58.8372770000132
                            ],
                            [
                                -152.017164999667,
                                58.8285179999303
                            ],
                            [
                                -152.036534000411,
                                58.8278419999142
                            ],
                            [
                                -152.06678100007,
                                58.8330130001163
                            ],
                            [
                                -152.108585000131,
                                58.8446139998346
                            ],
                            [
                                -152.134071999576,
                                58.8581179997742
                            ],
                            [
                                -152.195356999636,
                                58.840633000223
                            ],
                            [
                                -152.219058999899,
                                58.8389730001157
                            ],
                            [
                                -152.249643999666,
                                58.8406739998539
                            ],
                            [
                                -152.262412999864,
                                58.8328439997876
                            ],
                            [
                                -152.275531999867,
                                58.8272689998269
                            ],
                            [
                                -152.29290599957,
                                58.8226490001275
                            ],
                            [
                                -152.314621999911,
                                58.8199969998903
                            ],
                            [
                                -152.340715000151,
                                58.8203900000906
                            ],
                            [
                                -152.360884999888,
                                58.823045999861
                            ],
                            [
                                -152.383294000164,
                                58.8282900002015
                            ],
                            [
                                -152.401447999842,
                                58.8366740000085
                            ],
                            [
                                -152.413750999885,
                                58.8476070000923
                            ],
                            [
                                -152.420959999803,
                                58.8584739998763
                            ],
                            [
                                -152.425946999798,
                                58.8733740001658
                            ],
                            [
                                -152.434602999687,
                                58.875864000206
                            ],
                            [
                                -152.452584000011,
                                58.8848720000398
                            ],
                            [
                                -152.460704000323,
                                58.8954490000064
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                178.038556000092,
                                51.9603659997602
                            ],
                            [
                                178.016306999649,
                                51.9786999999219
                            ],
                            [
                                178.010567000342,
                                52.0006659998076
                            ],
                            [
                                178.015068999915,
                                52.0150390000646
                            ],
                            [
                                178.006140000214,
                                52.0214429999035
                            ],
                            [
                                178.000401999659,
                                52.0333220000132
                            ],
                            [
                                178.00345299996,
                                52.0495879998782
                            ],
                            [
                                178.020569999746,
                                52.0718610001062
                            ],
                            [
                                178.04623399964,
                                52.0886019997512
                            ],
                            [
                                178.076902999971,
                                52.1000009997456
                            ],
                            [
                                178.109060000282,
                                52.1027469999739
                            ],
                            [
                                178.12197699972,
                                52.1074190002166
                            ],
                            [
                                178.1627160003,
                                52.1066500000447
                            ],
                            [
                                178.188837999954,
                                52.0986770001887
                            ],
                            [
                                178.211556999852,
                                52.0840510001126
                            ],
                            [
                                178.225579999678,
                                52.0803759998435
                            ],
                            [
                                178.247368999814,
                                52.067528999868
                            ],
                            [
                                178.257167000076,
                                52.0548560000483
                            ],
                            [
                                178.267158999609,
                                52.0374509997988
                            ],
                            [
                                178.335486999906,
                                52.0305700002096
                            ],
                            [
                                178.350137000444,
                                52.0314700001453
                            ],
                            [
                                178.378461999755,
                                52.028876000099
                            ],
                            [
                                178.402663999609,
                                52.0217039998744
                            ],
                            [
                                178.414414000357,
                                52.0271519998156
                            ],
                            [
                                178.44041600036,
                                52.0354759999382
                            ],
                            [
                                178.468800000121,
                                52.0411839997875
                            ],
                            [
                                178.496176000301,
                                52.0440309998197
                            ],
                            [
                                178.512411999829,
                                52.043436000214
                            ],
                            [
                                178.541436999647,
                                52.0327709999369
                            ],
                            [
                                178.616579999677,
                                52.0093750001576
                            ],
                            [
                                178.643315999624,
                                51.9976030002304
                            ],
                            [
                                178.663949000041,
                                51.9828460000966
                            ],
                            [
                                178.671306000097,
                                51.9598799999993
                            ],
                            [
                                178.67020700028,
                                51.9440940001413
                            ],
                            [
                                178.666904000084,
                                51.9302519999823
                            ],
                            [
                                178.644603999587,
                                51.9061189999497
                            ],
                            [
                                178.62568199963,
                                51.8884400000053
                            ],
                            [
                                178.6047150002,
                                51.8736609998664
                            ],
                            [
                                178.58072800043,
                                51.8595520001368
                            ],
                            [
                                178.531590999704,
                                51.8467160002428
                            ],
                            [
                                178.502096000431,
                                51.8469410002474
                            ],
                            [
                                178.477297000001,
                                51.8522080002384
                            ],
                            [
                                178.412990999776,
                                51.8753649998662
                            ],
                            [
                                178.383813999622,
                                51.8910199998318
                            ],
                            [
                                178.371084999607,
                                51.9068430002633
                            ],
                            [
                                178.363364000402,
                                51.903556000106
                            ],
                            [
                                178.330921999686,
                                51.8985500002397
                            ],
                            [
                                178.289261999564,
                                51.9008539998834
                            ],
                            [
                                178.260541000418,
                                51.9101560002748
                            ],
                            [
                                178.250467999796,
                                51.9196310000763
                            ],
                            [
                                178.228983000332,
                                51.9264980002375
                            ],
                            [
                                178.19587000032,
                                51.9488429998322
                            ],
                            [
                                178.142805000131,
                                51.9393259998756
                            ],
                            [
                                178.100570000074,
                                51.9399039999747
                            ],
                            [
                                178.059674999858,
                                51.9481140002073
                            ],
                            [
                                178.038556000092,
                                51.9603659997602
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -160.491466000126,
                                58.6334610001697
                            ],
                            [
                                -160.528739000303,
                                58.6541640000698
                            ],
                            [
                                -160.539474999825,
                                58.6643360000049
                            ],
                            [
                                -160.544320000428,
                                58.6743259998718
                            ],
                            [
                                -160.544057999589,
                                58.6878380002243
                            ],
                            [
                                -160.537493000101,
                                58.7002680001104
                            ],
                            [
                                -160.530924000416,
                                58.7062660000833
                            ],
                            [
                                -160.525425000235,
                                58.7085710000464
                            ],
                            [
                                -160.52578599991,
                                58.7302369998908
                            ],
                            [
                                -160.513537000294,
                                58.750650000046
                            ],
                            [
                                -160.504297000248,
                                58.7614299998638
                            ],
                            [
                                -160.490372000332,
                                58.7736310000803
                            ],
                            [
                                -160.472047000052,
                                58.7836869999074
                            ],
                            [
                                -160.452227000119,
                                58.79185400004
                            ],
                            [
                                -160.416039000414,
                                58.7984449999198
                            ],
                            [
                                -160.367639999829,
                                58.7940970000154
                            ],
                            [
                                -160.349390999717,
                                58.7888080001744
                            ],
                            [
                                -160.333188999625,
                                58.7817709998875
                            ],
                            [
                                -160.323240999575,
                                58.7750579998941
                            ],
                            [
                                -160.296788000383,
                                58.7762790002182
                            ],
                            [
                                -160.263913000022,
                                58.7717529997679
                            ],
                            [
                                -160.23770400033,
                                58.7660480002224
                            ],
                            [
                                -160.214084000083,
                                58.7554770000183
                            ],
                            [
                                -160.18767399965,
                                58.7562839999955
                            ],
                            [
                                -160.13761799993,
                                58.7488980001201
                            ],
                            [
                                -160.116555000241,
                                58.7390219998114
                            ],
                            [
                                -160.101913999924,
                                58.7248119999535
                            ],
                            [
                                -160.096264999955,
                                58.7053390000061
                            ],
                            [
                                -160.09697299996,
                                58.6972830001639
                            ],
                            [
                                -160.110312999895,
                                58.6815999999151
                            ],
                            [
                                -160.122547000341,
                                58.6728270001886
                            ],
                            [
                                -160.15273100025,
                                58.6581990000831
                            ],
                            [
                                -160.15197600039,
                                58.6533640001863
                            ],
                            [
                                -160.15408600016,
                                58.6371090001129
                            ],
                            [
                                -160.16114699994,
                                58.6260400000538
                            ],
                            [
                                -160.168401000064,
                                58.6185620000432
                            ],
                            [
                                -160.179822000025,
                                58.610018000069
                            ],
                            [
                                -160.212158000436,
                                58.5950459998589
                            ],
                            [
                                -160.213467000315,
                                58.5931680000805
                            ],
                            [
                                -160.212859999694,
                                58.5848509998476
                            ],
                            [
                                -160.21543599962,
                                58.5783140000799
                            ],
                            [
                                -160.225597000105,
                                58.5655389998874
                            ],
                            [
                                -160.258761000171,
                                58.5445690000035
                            ],
                            [
                                -160.30102899984,
                                58.5357579999102
                            ],
                            [
                                -160.333606000275,
                                58.5356780000942
                            ],
                            [
                                -160.367618999913,
                                58.541989000136
                            ],
                            [
                                -160.396861000364,
                                58.5539189998658
                            ],
                            [
                                -160.407770000139,
                                58.567313999893
                            ],
                            [
                                -160.414290000319,
                                58.5869340001709
                            ],
                            [
                                -160.401123999562,
                                58.6125739999406
                            ],
                            [
                                -160.394860000374,
                                58.6212709997726
                            ],
                            [
                                -160.387627000166,
                                58.6276459998953
                            ],
                            [
                                -160.401316999906,
                                58.6249839998218
                            ],
                            [
                                -160.433100999948,
                                58.6222459999024
                            ],
                            [
                                -160.453608999793,
                                58.6230659999694
                            ],
                            [
                                -160.475339000378,
                                58.6271920001714
                            ],
                            [
                                -160.491466000126,
                                58.6334610001697
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -172.667157000339,
                                52.2097610000771
                            ],
                            [
                                -172.676008000222,
                                52.210576000179
                            ],
                            [
                                -172.688702999902,
                                52.2167099998377
                            ],
                            [
                                -172.707502999661,
                                52.2342269998117
                            ],
                            [
                                -172.718337999816,
                                52.2531030002361
                            ],
                            [
                                -172.72068899961,
                                52.264363999893
                            ],
                            [
                                -172.717699000306,
                                52.2719010002439
                            ],
                            [
                                -172.71259799961,
                                52.2762919998485
                            ],
                            [
                                -172.717271999611,
                                52.2814989997968
                            ],
                            [
                                -172.714344000231,
                                52.2947509999342
                            ],
                            [
                                -172.701284999605,
                                52.3146180002565
                            ],
                            [
                                -172.681541999665,
                                52.3306410002693
                            ],
                            [
                                -172.667261999921,
                                52.3497530000722
                            ],
                            [
                                -172.660905999773,
                                52.35299299989
                            ],
                            [
                                -172.646482000089,
                                52.3748620002027
                            ],
                            [
                                -172.633239000238,
                                52.3878629998499
                            ],
                            [
                                -172.606078999968,
                                52.3993989997673
                            ],
                            [
                                -172.583345000001,
                                52.4030549997904
                            ],
                            [
                                -172.572206000073,
                                52.4079460001659
                            ],
                            [
                                -172.547712999965,
                                52.412996999894
                            ],
                            [
                                -172.507541999647,
                                52.4293560001006
                            ],
                            [
                                -172.489444999871,
                                52.4327829999652
                            ],
                            [
                                -172.483981999675,
                                52.4366510002273
                            ],
                            [
                                -172.448306999981,
                                52.4430990002221
                            ],
                            [
                                -172.427097999803,
                                52.4402160001933
                            ],
                            [
                                -172.417606999688,
                                52.4441219999254
                            ],
                            [
                                -172.399295999651,
                                52.4445050000269
                            ],
                            [
                                -172.379489000137,
                                52.4412779999482
                            ],
                            [
                                -172.356020000402,
                                52.4332899997829
                            ],
                            [
                                -172.332366999645,
                                52.4199249998044
                            ],
                            [
                                -172.296537999965,
                                52.4138679998221
                            ],
                            [
                                -172.274533999744,
                                52.4057590000219
                            ],
                            [
                                -172.24965999987,
                                52.3934540000796
                            ],
                            [
                                -172.231286999909,
                                52.3799270000595
                            ],
                            [
                                -172.224328000236,
                                52.3694290002376
                            ],
                            [
                                -172.223564000155,
                                52.3591980001999
                            ],
                            [
                                -172.226303000287,
                                52.3549079998002
                            ],
                            [
                                -172.210529999643,
                                52.327124000098
                            ],
                            [
                                -172.210712000116,
                                52.3162669997732
                            ],
                            [
                                -172.215013999672,
                                52.3094389999369
                            ],
                            [
                                -172.22844200037,
                                52.2961929997982
                            ],
                            [
                                -172.250170000406,
                                52.2840839997489
                            ],
                            [
                                -172.266039000411,
                                52.2818259998092
                            ],
                            [
                                -172.271623999908,
                                52.275288000207
                            ],
                            [
                                -172.287706000348,
                                52.2656510001285
                            ],
                            [
                                -172.312027999854,
                                52.2570280002424
                            ],
                            [
                                -172.334997999822,
                                52.2523030002
                            ],
                            [
                                -172.354813999556,
                                52.238320000024
                            ],
                            [
                                -172.377914999944,
                                52.2283629999141
                            ],
                            [
                                -172.407668999683,
                                52.2244859998339
                            ],
                            [
                                -172.427200999736,
                                52.2254240000619
                            ],
                            [
                                -172.433945000222,
                                52.2215019998173
                            ],
                            [
                                -172.450056999901,
                                52.2173349999628
                            ],
                            [
                                -172.474929000126,
                                52.2150849998529
                            ],
                            [
                                -172.494215000131,
                                52.2041200002625
                            ],
                            [
                                -172.510602999995,
                                52.1999459997927
                            ],
                            [
                                -172.544384999654,
                                52.1980449997276
                            ],
                            [
                                -172.570020000134,
                                52.2001120001416
                            ],
                            [
                                -172.575992999968,
                                52.2021949999505
                            ],
                            [
                                -172.589460000125,
                                52.1995400002735
                            ],
                            [
                                -172.616162999562,
                                52.1990879998062
                            ],
                            [
                                -172.636426000084,
                                52.2019869997381
                            ],
                            [
                                -172.652295000089,
                                52.2062510002598
                            ],
                            [
                                -172.659528000297,
                                52.2111189997771
                            ],
                            [
                                -172.667157000339,
                                52.2097610000771
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                179.405499000062,
                                51.9095079998493
                            ],
                            [
                                179.389690000331,
                                51.9237889998574
                            ],
                            [
                                179.388742000126,
                                51.9419170000245
                            ],
                            [
                                179.404561999728,
                                51.968358000009
                            ],
                            [
                                179.401375999707,
                                51.9730830001759
                            ],
                            [
                                179.408728999565,
                                52.0053550000155
                            ],
                            [
                                179.429602000182,
                                52.023583000044
                            ],
                            [
                                179.450322999565,
                                52.0313190001583
                            ],
                            [
                                179.485860000065,
                                52.0338589999118
                            ],
                            [
                                179.508306000152,
                                52.0397120000934
                            ],
                            [
                                179.520907999945,
                                52.0541630001169
                            ],
                            [
                                179.540865000145,
                                52.0641659999579
                            ],
                            [
                                179.569366000081,
                                52.0689200002747
                            ],
                            [
                                179.58581400022,
                                52.0692559999264
                            ],
                            [
                                179.658381000324,
                                52.0782700000738
                            ],
                            [
                                179.679039999782,
                                52.0753110001394
                            ],
                            [
                                179.704034000205,
                                52.0637000001825
                            ],
                            [
                                179.718447000019,
                                52.0533699998965
                            ],
                            [
                                179.731376999875,
                                52.0522950001614
                            ],
                            [
                                179.753441000371,
                                52.0471480002459
                            ],
                            [
                                179.771020000113,
                                52.0408300000389
                            ],
                            [
                                179.782190000004,
                                52.0334309999741
                            ],
                            [
                                179.788782000154,
                                52.0255650000801
                            ],
                            [
                                179.790277999631,
                                52.0183700001547
                            ],
                            [
                                179.819209999562,
                                52.0100020002384
                            ],
                            [
                                179.847075000362,
                                51.9924709999899
                            ],
                            [
                                179.857055000199,
                                51.977608999841
                            ],
                            [
                                179.859681000354,
                                51.9603659997602
                            ],
                            [
                                179.851016000243,
                                51.9358829998712
                            ],
                            [
                                179.835856000068,
                                51.9174740002308
                            ],
                            [
                                179.835727000196,
                                51.9101560002748
                            ],
                            [
                                179.825016999715,
                                51.8920209997675
                            ],
                            [
                                179.785069999703,
                                51.8626389997238
                            ],
                            [
                                179.757236999589,
                                51.8547949998742
                            ],
                            [
                                179.73525800038,
                                51.8549720001088
                            ],
                            [
                                179.72684400034,
                                51.8464030001763
                            ],
                            [
                                179.717476000248,
                                51.8409549998832
                            ],
                            [
                                179.671671999856,
                                51.8256640002361
                            ],
                            [
                                179.62321200007,
                                51.8189879997501
                            ],
                            [
                                179.574873999584,
                                51.8268210002277
                            ],
                            [
                                179.548368999616,
                                51.8360659999353
                            ],
                            [
                                179.546006000125,
                                51.8350139999192
                            ],
                            [
                                179.523271000334,
                                51.839101999853
                            ],
                            [
                                179.508563000069,
                                51.8437639998742
                            ],
                            [
                                179.484606000437,
                                51.848026999874
                            ],
                            [
                                179.456802999562,
                                51.8598889998605
                            ],
                            [
                                179.442886999866,
                                51.8758809997321
                            ],
                            [
                                179.423537000287,
                                51.8858050002566
                            ],
                            [
                                179.405499000062,
                                51.9095079998493
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -179.12725099983,
                                51.523423000196
                            ],
                            [
                                -179.141107999975,
                                51.5380339998823
                            ],
                            [
                                -179.148892999653,
                                51.5542310002441
                            ],
                            [
                                -179.150511000227,
                                51.5664849999871
                            ],
                            [
                                -179.149872999644,
                                51.5953580002359
                            ],
                            [
                                -179.141236999846,
                                51.6095649997376
                            ],
                            [
                                -179.123995000387,
                                51.6262599999284
                            ],
                            [
                                -179.113344000355,
                                51.6323869999702
                            ],
                            [
                                -179.092073000254,
                                51.6402859997256
                            ],
                            [
                                -179.065795000065,
                                51.6452160000533
                            ],
                            [
                                -179.05602899959,
                                51.6459719997878
                            ],
                            [
                                -179.038784999583,
                                51.6435010000077
                            ],
                            [
                                -179.020045999924,
                                51.63928999973
                            ],
                            [
                                -179.000815999996,
                                51.6316990002291
                            ],
                            [
                                -178.988333000028,
                                51.6245809997892
                            ],
                            [
                                -178.954605999722,
                                51.5985180001356
                            ],
                            [
                                -178.948607999774,
                                51.5873039997588
                            ],
                            [
                                -178.944168000126,
                                51.5960540002492
                            ],
                            [
                                -178.934097000052,
                                51.6047990001468
                            ],
                            [
                                -178.920210999595,
                                51.6137260002746
                            ],
                            [
                                -178.890759999805,
                                51.6285300001737
                            ],
                            [
                                -178.844583999867,
                                51.6376259997648
                            ],
                            [
                                -178.827523999983,
                                51.6354070000921
                            ],
                            [
                                -178.79101999991,
                                51.6233909998673
                            ],
                            [
                                -178.784975999931,
                                51.6193619999977
                            ],
                            [
                                -178.774599000434,
                                51.6188290000271
                            ],
                            [
                                -178.768295000164,
                                51.616612999737
                            ],
                            [
                                -178.766603999795,
                                51.6212930000492
                            ],
                            [
                                -178.752320999679,
                                51.6359729998676
                            ],
                            [
                                -178.741822999807,
                                51.6441219999356
                            ],
                            [
                                -178.725891999878,
                                51.6526610001694
                            ],
                            [
                                -178.660121999963,
                                51.6658790000718
                            ],
                            [
                                -178.634300999709,
                                51.6664840002323
                            ],
                            [
                                -178.603137999634,
                                51.6603129999982
                            ],
                            [
                                -178.592981000245,
                                51.656224000194
                            ],
                            [
                                -178.578110999599,
                                51.6594819999784
                            ],
                            [
                                -178.55931099984,
                                51.6580030000093
                            ],
                            [
                                -178.539807000275,
                                51.6523359999946
                            ],
                            [
                                -178.515237000175,
                                51.6404029997384
                            ],
                            [
                                -178.504469999792,
                                51.633526999888
                            ],
                            [
                                -178.499152000259,
                                51.6348409999437
                            ],
                            [
                                -178.489902999993,
                                51.6336660002092
                            ],
                            [
                                -178.470397999705,
                                51.6286690001942
                            ],
                            [
                                -178.452206000392,
                                51.6210020000276
                            ],
                            [
                                -178.436118000104,
                                51.6097090000054
                            ],
                            [
                                -178.421675999978,
                                51.5872830001994
                            ],
                            [
                                -178.422375999587,
                                51.5743109998979
                            ],
                            [
                                -178.426677000217,
                                51.5617980001472
                            ],
                            [
                                -178.440623999874,
                                51.5508629998178
                            ],
                            [
                                -178.456158000144,
                                51.5433929997336
                            ],
                            [
                                -178.496478000424,
                                51.5324739998203
                            ],
                            [
                                -178.501251999984,
                                51.532715999974
                            ],
                            [
                                -178.506884000234,
                                51.5264610000076
                            ],
                            [
                                -178.517689000252,
                                51.5206240002242
                            ],
                            [
                                -178.550192999994,
                                51.5084839998121
                            ],
                            [
                                -178.574457999597,
                                51.5075289997673
                            ],
                            [
                                -178.596558000078,
                                51.5108320001964
                            ],
                            [
                                -178.618993000294,
                                51.5170499999585
                            ],
                            [
                                -178.636421000424,
                                51.5247940000066
                            ],
                            [
                                -178.64899499973,
                                51.5199410002701
                            ],
                            [
                                -178.665273999814,
                                51.5180100001849
                            ],
                            [
                                -178.666820000418,
                                51.5142479998061
                            ],
                            [
                                -178.673045000147,
                                51.5097609999196
                            ],
                            [
                                -178.690807000188,
                                51.5007419997899
                            ],
                            [
                                -178.714795999607,
                                51.4938129998793
                            ],
                            [
                                -178.73307800023,
                                51.4907450001709
                            ],
                            [
                                -178.755786000257,
                                51.4887070001091
                            ],
                            [
                                -178.774831000238,
                                51.4904450002734
                            ],
                            [
                                -178.782613000441,
                                51.4875770002567
                            ],
                            [
                                -178.800193000009,
                                51.485859000203
                            ],
                            [
                                -178.836421999722,
                                51.4920369998198
                            ],
                            [
                                -178.855039999905,
                                51.5010240000031
                            ],
                            [
                                -178.865264999965,
                                51.5081280000296
                            ],
                            [
                                -178.8688950004,
                                51.5130999998196
                            ],
                            [
                                -178.876069000157,
                                51.511980999926
                            ],
                            [
                                -178.88815600029,
                                51.5162450002282
                            ],
                            [
                                -178.900272999662,
                                51.5222809997223
                            ],
                            [
                                -178.910009999825,
                                51.5312610000688
                            ],
                            [
                                -178.918188999688,
                                51.533702000112
                            ],
                            [
                                -178.925606000019,
                                51.5380810000303
                            ],
                            [
                                -178.948620000368,
                                51.5608789998801
                            ],
                            [
                                -178.950305999816,
                                51.5648020000558
                            ],
                            [
                                -178.955182000381,
                                51.5535230001363
                            ],
                            [
                                -178.979778000421,
                                51.5311049999618
                            ],
                            [
                                -179.002361999702,
                                51.521635000222
                            ],
                            [
                                -179.017804999735,
                                51.5178040000305
                            ],
                            [
                                -179.028516999866,
                                51.5190089998722
                            ],
                            [
                                -179.054123000034,
                                51.5114010001545
                            ],
                            [
                                -179.071062000443,
                                51.5092939997545
                            ],
                            [
                                -179.082012000225,
                                51.5097909998097
                            ],
                            [
                                -179.102529000292,
                                51.513450999748
                            ],
                            [
                                -179.119925999561,
                                51.5190660002611
                            ],
                            [
                                -179.12725099983,
                                51.523423000196
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -156.871424999863,
                                56.1159899999486
                            ],
                            [
                                -156.883602000408,
                                56.1228400001851
                            ],
                            [
                                -156.893100000196,
                                56.1309760000286
                            ],
                            [
                                -156.897385000034,
                                56.1377050000354
                            ],
                            [
                                -156.903152999828,
                                56.1550530001639
                            ],
                            [
                                -156.902033000095,
                                56.1654450001213
                            ],
                            [
                                -156.896769999916,
                                56.1735230001212
                            ],
                            [
                                -156.899200000252,
                                56.1810180000592
                            ],
                            [
                                -156.900164000349,
                                56.1916109998448
                            ],
                            [
                                -156.898002999627,
                                56.2016610000917
                            ],
                            [
                                -156.891165000328,
                                56.2125630002201
                            ],
                            [
                                -156.878042000128,
                                56.2233040001374
                            ],
                            [
                                -156.88071299959,
                                56.2322870000254
                            ],
                            [
                                -156.872228000303,
                                56.2470230001778
                            ],
                            [
                                -156.85858299987,
                                56.25929799987
                            ],
                            [
                                -156.841994000165,
                                56.2700799999084
                            ],
                            [
                                -156.819982000446,
                                56.2765180001104
                            ],
                            [
                                -156.79285099959,
                                56.2795760000549
                            ],
                            [
                                -156.770233999799,
                                56.2797930001043
                            ],
                            [
                                -156.728906999763,
                                56.27398400015
                            ],
                            [
                                -156.707393999812,
                                56.267426000243
                            ],
                            [
                                -156.690626000007,
                                56.2572490001109
                            ],
                            [
                                -156.678004999946,
                                56.2398799999222
                            ],
                            [
                                -156.673253000128,
                                56.2240770001581
                            ],
                            [
                                -156.660095999593,
                                56.2149929999913
                            ],
                            [
                                -156.65111199964,
                                56.2009599999097
                            ],
                            [
                                -156.649387999659,
                                56.1841959997956
                            ],
                            [
                                -156.653769999581,
                                56.1675939999446
                            ],
                            [
                                -156.667291000165,
                                56.1567180000645
                            ],
                            [
                                -156.675866999689,
                                56.1524989999076
                            ],
                            [
                                -156.671960999968,
                                56.148350999876
                            ],
                            [
                                -156.657226999764,
                                56.146901999775
                            ],
                            [
                                -156.626815000249,
                                56.1401920001224
                            ],
                            [
                                -156.609848000252,
                                56.1321490000858
                            ],
                            [
                                -156.602293999654,
                                56.1269710000477
                            ],
                            [
                                -156.591466999895,
                                56.1148880001078
                            ],
                            [
                                -156.58933000036,
                                56.1092749998088
                            ],
                            [
                                -156.573970000168,
                                56.1023050000972
                            ],
                            [
                                -156.556048000294,
                                56.0901620001
                            ],
                            [
                                -156.550151999555,
                                56.0766409999588
                            ],
                            [
                                -156.55029300002,
                                56.0643019999428
                            ],
                            [
                                -156.553427000162,
                                56.0584109998661
                            ],
                            [
                                -156.54741699962,
                                56.0486429997549
                            ],
                            [
                                -156.548041999784,
                                56.0386169999014
                            ],
                            [
                                -156.55332400023,
                                56.0270750000445
                            ],
                            [
                                -156.584457999993,
                                56.0056360000346
                            ],
                            [
                                -156.611958000022,
                                55.9829190001282
                            ],
                            [
                                -156.647785000052,
                                55.9623019998555
                            ],
                            [
                                -156.675427000371,
                                55.9567700001494
                            ],
                            [
                                -156.732163000105,
                                55.9534970000231
                            ],
                            [
                                -156.76399999985,
                                55.9530010000335
                            ],
                            [
                                -156.793074000071,
                                55.9557920001359
                            ],
                            [
                                -156.822209000392,
                                55.9675559998702
                            ],
                            [
                                -156.833390000154,
                                55.9842140000938
                            ],
                            [
                                -156.832089999597,
                                55.9961540002199
                            ],
                            [
                                -156.826412000214,
                                56.007578000141
                            ],
                            [
                                -156.838120000231,
                                56.0126839999946
                            ],
                            [
                                -156.844914000047,
                                56.022111000218
                            ],
                            [
                                -156.846528000423,
                                56.0302289999821
                            ],
                            [
                                -156.845372999631,
                                56.0402720000101
                            ],
                            [
                                -156.835351999786,
                                56.060235999769
                            ],
                            [
                                -156.82008299983,
                                56.0705619998777
                            ],
                            [
                                -156.821063999646,
                                56.0881840001286
                            ],
                            [
                                -156.817169999621,
                                56.1040959997921
                            ],
                            [
                                -156.852862999757,
                                56.1093740000842
                            ],
                            [
                                -156.871424999863,
                                56.1159899999486
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -170.912919000251,
                                52.5302929999981
                            ],
                            [
                                -170.927388000141,
                                52.5498859998574
                            ],
                            [
                                -170.923514000207,
                                52.5749869998606
                            ],
                            [
                                -170.912711999664,
                                52.5912010001585
                            ],
                            [
                                -170.911125999775,
                                52.600097000201
                            ],
                            [
                                -170.903192999959,
                                52.6224060001384
                            ],
                            [
                                -170.888913000215,
                                52.6492710001172
                            ],
                            [
                                -170.86544600013,
                                52.6690560001376
                            ],
                            [
                                -170.834134999916,
                                52.684278000014
                            ],
                            [
                                -170.818747999959,
                                52.6884769997405
                            ],
                            [
                                -170.815916999766,
                                52.6925190000374
                            ],
                            [
                                -170.804716999738,
                                52.701461000009
                            ],
                            [
                                -170.786040999828,
                                52.7139750002427
                            ],
                            [
                                -170.770386999908,
                                52.7210940000072
                            ],
                            [
                                -170.745667999844,
                                52.7281519998828
                            ],
                            [
                                -170.732050999899,
                                52.7297809998392
                            ],
                            [
                                -170.700226999675,
                                52.7440230000657
                            ],
                            [
                                -170.680451999948,
                                52.7495859998568
                            ],
                            [
                                -170.630402000076,
                                52.7515940000676
                            ],
                            [
                                -170.593103999785,
                                52.7448430002697
                            ],
                            [
                                -170.564109999929,
                                52.742204999888
                            ],
                            [
                                -170.543238000035,
                                52.7318889998249
                            ],
                            [
                                -170.526322999917,
                                52.7292049998911
                            ],
                            [
                                -170.508314000004,
                                52.721164999735
                            ],
                            [
                                -170.4809939999,
                                52.6904099997351
                            ],
                            [
                                -170.474706000422,
                                52.6738000002061
                            ],
                            [
                                -170.472779999877,
                                52.6434869998573
                            ],
                            [
                                -170.474020000159,
                                52.6383869998604
                            ],
                            [
                                -170.49368500028,
                                52.618941000011
                            ],
                            [
                                -170.50918600004,
                                52.5977369998458
                            ],
                            [
                                -170.536132999874,
                                52.573846999902
                            ],
                            [
                                -170.575684999876,
                                52.5548710000292
                            ],
                            [
                                -170.652013999761,
                                52.5406250000583
                            ],
                            [
                                -170.674426000411,
                                52.5398759999208
                            ],
                            [
                                -170.738226000298,
                                52.5001089999855
                            ],
                            [
                                -170.763829999918,
                                52.4898079998855
                            ],
                            [
                                -170.793768999605,
                                52.4870989999195
                            ],
                            [
                                -170.828317999718,
                                52.4919270000987
                            ],
                            [
                                -170.847149000337,
                                52.5024400000637
                            ],
                            [
                                -170.848430999553,
                                52.5051690000808
                            ],
                            [
                                -170.887588000443,
                                52.5138289999424
                            ],
                            [
                                -170.912919000251,
                                52.5302929999981
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -169.853006999584,
                                56.5788239997808
                            ],
                            [
                                -169.868239999555,
                                56.587030999826
                            ],
                            [
                                -169.880237999999,
                                56.6020289997821
                            ],
                            [
                                -169.877591999753,
                                56.6305370000391
                            ],
                            [
                                -169.871461999561,
                                56.6430489999665
                            ],
                            [
                                -169.862552999951,
                                56.6519980002195
                            ],
                            [
                                -169.852881000085,
                                56.6588810002207
                            ],
                            [
                                -169.829383999862,
                                56.6698160002082
                            ],
                            [
                                -169.802528000264,
                                56.6739689999963
                            ],
                            [
                                -169.768256000161,
                                56.6756809997743
                            ],
                            [
                                -169.747349999932,
                                56.6750429999071
                            ],
                            [
                                -169.688507999728,
                                56.6670319998616
                            ],
                            [
                                -169.621912999632,
                                56.6613409999536
                            ],
                            [
                                -169.589104000292,
                                56.6622590001331
                            ],
                            [
                                -169.538686999998,
                                56.6684370000172
                            ],
                            [
                                -169.509264999622,
                                56.6674710000104
                            ],
                            [
                                -169.48164199957,
                                56.6604459997901
                            ],
                            [
                                -169.474163000213,
                                56.6560980001794
                            ],
                            [
                                -169.462413000365,
                                56.6565799998068
                            ],
                            [
                                -169.438666999721,
                                56.6536979999569
                            ],
                            [
                                -169.422246000245,
                                56.6488089999354
                            ],
                            [
                                -169.401641000315,
                                56.6365529998439
                            ],
                            [
                                -169.390069999668,
                                56.624314999916
                            ],
                            [
                                -169.382254000028,
                                56.6147500001089
                            ],
                            [
                                -169.377544999867,
                                56.5877290000931
                            ],
                            [
                                -169.383952000069,
                                56.5781619998657
                            ],
                            [
                                -169.420473999685,
                                56.5492590000468
                            ],
                            [
                                -169.479412999974,
                                56.5268509999472
                            ],
                            [
                                -169.526636999675,
                                56.4929760002291
                            ],
                            [
                                -169.554772999739,
                                56.4862530001419
                            ],
                            [
                                -169.581262999639,
                                56.4832279998968
                            ],
                            [
                                -169.676091999707,
                                56.4962259997614
                            ],
                            [
                                -169.708904000318,
                                56.5070270000497
                            ],
                            [
                                -169.727524000152,
                                56.5146389999574
                            ],
                            [
                                -169.763735000321,
                                56.5407239998515
                            ],
                            [
                                -169.782344000284,
                                56.5425559999785
                            ],
                            [
                                -169.804776999952,
                                56.5473689998673
                            ],
                            [
                                -169.830387000318,
                                56.5603270000466
                            ],
                            [
                                -169.838171999996,
                                56.5711899998964
                            ],
                            [
                                -169.853006999584,
                                56.5788239997808
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -146.508044000013,
                                59.3863780002079
                            ],
                            [
                                -146.507932999685,
                                59.3942039999665
                            ],
                            [
                                -146.505817999892,
                                59.4010769997944
                            ],
                            [
                                -146.499467999592,
                                59.4130999998546
                            ],
                            [
                                -146.492175999833,
                                59.4222800000952
                            ],
                            [
                                -146.470016999801,
                                59.4400699998894
                            ],
                            [
                                -146.455615999684,
                                59.4539150001128
                            ],
                            [
                                -146.442942999745,
                                59.4609699999224
                            ],
                            [
                                -146.427833999623,
                                59.4666689997929
                            ],
                            [
                                -146.433922999807,
                                59.4768370001856
                            ],
                            [
                                -146.43573899985,
                                59.48722300021
                            ],
                            [
                                -146.431991000312,
                                59.500101999992
                            ],
                            [
                                -146.420643999971,
                                59.5135650000674
                            ],
                            [
                                -146.429061000385,
                                59.5238859997845
                            ],
                            [
                                -146.432654000111,
                                59.5341510001168
                            ],
                            [
                                -146.431967999848,
                                59.5445719999808
                            ],
                            [
                                -146.426430000207,
                                59.5555069999037
                            ],
                            [
                                -146.416181999683,
                                59.5655299999932
                            ],
                            [
                                -146.40048400028,
                                59.5747130000432
                            ],
                            [
                                -146.382372000435,
                                59.5812780001072
                            ],
                            [
                                -146.360153999954,
                                59.5858370000507
                            ],
                            [
                                -146.331268999878,
                                59.5875089999619
                            ],
                            [
                                -146.302684000277,
                                59.5848180001542
                            ],
                            [
                                -146.276909999879,
                                59.5780019997988
                            ],
                            [
                                -146.25518400039,
                                59.5669570002167
                            ],
                            [
                                -146.243597999675,
                                59.5563180001254
                            ],
                            [
                                -146.237434000045,
                                59.5445719999808
                            ],
                            [
                                -146.237050999731,
                                59.5324170000778
                            ],
                            [
                                -146.242924000005,
                                59.5199540000051
                            ],
                            [
                                -146.21000900036,
                                59.5113679998409
                            ],
                            [
                                -146.196378999996,
                                59.5047799999392
                            ],
                            [
                                -146.185477999719,
                                59.4969909999842
                            ],
                            [
                                -146.171126000005,
                                59.482167999843
                            ],
                            [
                                -146.167084999666,
                                59.4728319998206
                            ],
                            [
                                -146.166666000265,
                                59.462965999784
                            ],
                            [
                                -146.151670999946,
                                59.4496310000811
                            ],
                            [
                                -146.147326999658,
                                59.4421239997957
                            ],
                            [
                                -146.145446000217,
                                59.4352330001362
                            ],
                            [
                                -146.145601999852,
                                59.4274070000283
                            ],
                            [
                                -146.148588999682,
                                59.4188479999483
                            ],
                            [
                                -146.154445000238,
                                59.4106829999343
                            ],
                            [
                                -146.162988999974,
                                59.4031589998596
                            ],
                            [
                                -146.187030000171,
                                59.3909240001622
                            ],
                            [
                                -146.218529999632,
                                59.3835170001934
                            ],
                            [
                                -146.238860999925,
                                59.366122000098
                            ],
                            [
                                -146.253535999679,
                                59.3576730001975
                            ],
                            [
                                -146.282316000173,
                                59.3485059999023
                            ],
                            [
                                -146.301941999937,
                                59.3456360002268
                            ],
                            [
                                -146.322327999584,
                                59.3449099999747
                            ],
                            [
                                -146.360261999909,
                                59.3357029997918
                            ],
                            [
                                -146.38382400043,
                                59.3340130001831
                            ],
                            [
                                -146.407487000334,
                                59.3352819998603
                            ],
                            [
                                -146.425235999956,
                                59.3383120001257
                            ],
                            [
                                -146.465126000066,
                                59.3485350002041
                            ],
                            [
                                -146.481954000146,
                                59.3558600000836
                            ],
                            [
                                -146.495130999875,
                                59.364928999859
                            ],
                            [
                                -146.503980000108,
                                59.3752780000645
                            ],
                            [
                                -146.508044000013,
                                59.3863780002079
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -155.634614999936,
                                55.7058489997994
                            ],
                            [
                                -155.719822000367,
                                55.711785999854
                            ],
                            [
                                -155.794348999555,
                                55.72959400006
                            ],
                            [
                                -155.826240999551,
                                55.7592739999594
                            ],
                            [
                                -155.836841000428,
                                55.7830150002119
                            ],
                            [
                                -155.83680000042,
                                55.8008240001013
                            ],
                            [
                                -155.826106999657,
                                55.8186299999759
                            ],
                            [
                                -155.804761000111,
                                55.836437000161
                            ],
                            [
                                -155.751457000445,
                                55.8542420000555
                            ],
                            [
                                -155.676764999604,
                                55.9076629998342
                            ],
                            [
                                -155.634100000275,
                                55.9254710000431
                            ],
                            [
                                -155.606883999928,
                                55.9306800001054
                            ],
                            [
                                -155.581138999843,
                                55.930679000183
                            ],
                            [
                                -155.538240000162,
                                55.9254669998158
                            ],
                            [
                                -155.474353000237,
                                55.9135939999406
                            ],
                            [
                                -155.453118999945,
                                55.8839160000267
                            ],
                            [
                                -155.474504999674,
                                55.848300999998
                            ],
                            [
                                -155.485224000377,
                                55.8186229999738
                            ],
                            [
                                -155.474668999706,
                                55.7770740000715
                            ],
                            [
                                -155.474750999722,
                                55.7414589997837
                            ],
                            [
                                -155.528092000095,
                                55.7058480001565
                            ],
                            [
                                -155.581365999623,
                                55.6999120001702
                            ],
                            [
                                -155.634614999936,
                                55.7058489997994
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -159.855217000106,
                                54.7577039998374
                            ],
                            [
                                -159.899621000382,
                                54.7943080001544
                            ],
                            [
                                -159.911669999982,
                                54.8092880002366
                            ],
                            [
                                -159.905788000385,
                                54.8227809998281
                            ],
                            [
                                -159.88583000036,
                                54.8471890000551
                            ],
                            [
                                -159.872066000102,
                                54.8566269999079
                            ],
                            [
                                -159.837034000115,
                                54.8702860002154
                            ],
                            [
                                -159.761050999836,
                                54.8913239998649
                            ],
                            [
                                -159.729289000434,
                                54.8941630001561
                            ],
                            [
                                -159.681325999868,
                                54.8874339999103
                            ],
                            [
                                -159.636302000348,
                                54.8717970001576
                            ],
                            [
                                -159.601897000175,
                                54.881886999891
                            ],
                            [
                                -159.57262799996,
                                54.8837340002026
                            ],
                            [
                                -159.556116000247,
                                54.8802619999853
                            ],
                            [
                                -159.497604999581,
                                54.8554589999068
                            ],
                            [
                                -159.458747000239,
                                54.8277189998407
                            ],
                            [
                                -159.440646000264,
                                54.8175409999489
                            ],
                            [
                                -159.416771999574,
                                54.7921970000567
                            ],
                            [
                                -159.416439000387,
                                54.7820029997645
                            ],
                            [
                                -159.418178000436,
                                54.7751519999993
                            ],
                            [
                                -159.425226999622,
                                54.7600000002035
                            ],
                            [
                                -159.442865999639,
                                54.7384009999783
                            ],
                            [
                                -159.469293999615,
                                54.7209680000998
                            ],
                            [
                                -159.502896000247,
                                54.709003999764
                            ],
                            [
                                -159.547872000086,
                                54.6992959999569
                            ],
                            [
                                -159.566098999559,
                                54.7034879997918
                            ],
                            [
                                -159.600431999762,
                                54.7039609997734
                            ],
                            [
                                -159.624755999816,
                                54.7079209998818
                            ],
                            [
                                -159.652434999944,
                                54.7177779998472
                            ],
                            [
                                -159.66919000023,
                                54.733517999963
                            ],
                            [
                                -159.684848000348,
                                54.7623409998125
                            ],
                            [
                                -159.688578000342,
                                54.7655960001871
                            ],
                            [
                                -159.746790000359,
                                54.7426720000474
                            ],
                            [
                                -159.76540500017,
                                54.739772000063
                            ],
                            [
                                -159.801751000058,
                                54.7418000000779
                            ],
                            [
                                -159.813500000082,
                                54.7404180000396
                            ],
                            [
                                -159.835075999783,
                                54.7454599998952
                            ],
                            [
                                -159.855217000106,
                                54.7577039998374
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -179.226898999663,
                                51.2524640000924
                            ],
                            [
                                -179.231085999592,
                                51.2675310001671
                            ],
                            [
                                -179.230232999822,
                                51.2839270000274
                            ],
                            [
                                -179.225686000044,
                                51.2992720001072
                            ],
                            [
                                -179.207652000016,
                                51.3209420001206
                            ],
                            [
                                -179.183665000247,
                                51.3384910000151
                            ],
                            [
                                -179.160012000388,
                                51.3479830002052
                            ],
                            [
                                -179.130896000334,
                                51.3537530002713
                            ],
                            [
                                -179.107599000127,
                                51.353675999743
                            ],
                            [
                                -179.106725000441,
                                51.3651689998701
                            ],
                            [
                                -179.093238000193,
                                51.3909360002377
                            ],
                            [
                                -179.077550999762,
                                51.3990010000484
                            ],
                            [
                                -179.071112999598,
                                51.4079670002513
                            ],
                            [
                                -179.049873000357,
                                51.4284399998547
                            ],
                            [
                                -179.03391200029,
                                51.438847000153
                            ],
                            [
                                -179.005040999559,
                                51.4526519999623
                            ],
                            [
                                -178.990148000245,
                                51.4568259997757
                            ],
                            [
                                -178.969425000315,
                                51.4571089998705
                            ],
                            [
                                -178.922789999894,
                                51.4470079997811
                            ],
                            [
                                -178.904416000108,
                                51.4397520001341
                            ],
                            [
                                -178.895390000323,
                                51.4328910001897
                            ],
                            [
                                -178.873162999621,
                                51.4247910002105
                            ],
                            [
                                -178.852193999643,
                                51.4104089998365
                            ],
                            [
                                -178.835223000346,
                                51.3908079997751
                            ],
                            [
                                -178.825636999795,
                                51.3675290000702
                            ],
                            [
                                -178.824019999945,
                                51.358067999997
                            ],
                            [
                                -178.828138000276,
                                51.3350180000679
                            ],
                            [
                                -178.832806000429,
                                51.3235879998692
                            ],
                            [
                                -178.84235899957,
                                51.3155299997517
                            ],
                            [
                                -178.856487999701,
                                51.3049010000096
                            ],
                            [
                                -178.887892999626,
                                51.2938510002132
                            ],
                            [
                                -178.899578999901,
                                51.2933790000186
                            ],
                            [
                                -178.908064999911,
                                51.2818030002629
                            ],
                            [
                                -178.928603999719,
                                51.2704409998682
                            ],
                            [
                                -178.937210000278,
                                51.2693419998259
                            ],
                            [
                                -178.957963000346,
                                51.2591249999473
                            ],
                            [
                                -178.978868999676,
                                51.2552470000222
                            ],
                            [
                                -178.977380999697,
                                51.252971999924
                            ],
                            [
                                -178.995060999722,
                                51.2350960001077
                            ],
                            [
                                -179.024194000393,
                                51.2098310002193
                            ],
                            [
                                -179.07359900001,
                                51.179040999791
                            ],
                            [
                                -179.136941000138,
                                51.1750920001834
                            ],
                            [
                                -179.183815000035,
                                51.1837760000082
                            ],
                            [
                                -179.207884999645,
                                51.2027230002226
                            ],
                            [
                                -179.220561999782,
                                51.2279880001629
                            ],
                            [
                                -179.226898999663,
                                51.2524640000924
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -171.377262999956,
                                52.4313779997353
                            ],
                            [
                                -171.386634000421,
                                52.4485900002375
                            ],
                            [
                                -171.393716000117,
                                52.4681299997915
                            ],
                            [
                                -171.392955000409,
                                52.4912020001408
                            ],
                            [
                                -171.387067000066,
                                52.5148160000911
                            ],
                            [
                                -171.374432999587,
                                52.5371180000738
                            ],
                            [
                                -171.344067000103,
                                52.5559720002465
                            ],
                            [
                                -171.312420000329,
                                52.5707449998788
                            ],
                            [
                                -171.280466000408,
                                52.5798029998484
                            ],
                            [
                                -171.248556999794,
                                52.5808559997561
                            ],
                            [
                                -171.248390000288,
                                52.5834739999399
                            ],
                            [
                                -171.219643000305,
                                52.6135060001873
                            ],
                            [
                                -171.205940999971,
                                52.6249280001522
                            ],
                            [
                                -171.189369999809,
                                52.6350610000713
                            ],
                            [
                                -171.179158000168,
                                52.6386610000001
                            ],
                            [
                                -171.159186999724,
                                52.6400969999334
                            ],
                            [
                                -171.109837000359,
                                52.6363149999126
                            ],
                            [
                                -171.069734999638,
                                52.6270619998678
                            ],
                            [
                                -171.038360999676,
                                52.6143449999973
                            ],
                            [
                                -171.022428999921,
                                52.6037169997623
                            ],
                            [
                                -171.018825000324,
                                52.5857950000121
                            ],
                            [
                                -171.023676999701,
                                52.5707029998544
                            ],
                            [
                                -171.033562999827,
                                52.5485240001187
                            ],
                            [
                                -171.049523000069,
                                52.5310349999001
                            ],
                            [
                                -171.064598999681,
                                52.5212459999884
                            ],
                            [
                                -171.102014000148,
                                52.5083039998436
                            ],
                            [
                                -171.111845000022,
                                52.5075590002086
                            ],
                            [
                                -171.114861000164,
                                52.482775000073
                            ],
                            [
                                -171.124045999958,
                                52.4644099999803
                            ],
                            [
                                -171.136496000314,
                                52.4456709999411
                            ],
                            [
                                -171.158621999835,
                                52.4254290000172
                            ],
                            [
                                -171.173147999626,
                                52.4151589998401
                            ],
                            [
                                -171.187154999558,
                                52.4077489999543
                            ],
                            [
                                -171.206258999988,
                                52.3988910000956
                            ],
                            [
                                -171.226616000221,
                                52.3937020000338
                            ],
                            [
                                -171.264306999974,
                                52.3912279999055
                            ],
                            [
                                -171.294307999585,
                                52.3931339997771
                            ],
                            [
                                -171.320953000194,
                                52.4000909999014
                            ],
                            [
                                -171.358352999694,
                                52.4125449997332
                            ],
                            [
                                -171.366856000321,
                                52.4193619999507
                            ],
                            [
                                -171.377262999956,
                                52.4313779997353
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                173.779978999589,
                                52.7634150000705
                            ],
                            [
                                173.773204000039,
                                52.7733559997384
                            ],
                            [
                                173.776072000043,
                                52.7917500001126
                            ],
                            [
                                173.78159399979,
                                52.8021490002569
                            ],
                            [
                                173.789069999671,
                                52.8103430001803
                            ],
                            [
                                173.802007999924,
                                52.8196579999044
                            ],
                            [
                                173.817449000307,
                                52.8250459997768
                            ],
                            [
                                173.84476000019,
                                52.8315160001657
                            ],
                            [
                                173.870638000345,
                                52.8347899998094
                            ],
                            [
                                173.906128999916,
                                52.8313340000982
                            ],
                            [
                                173.926147000215,
                                52.8259870000832
                            ],
                            [
                                173.964781999974,
                                52.8115300002056
                            ],
                            [
                                173.980590999705,
                                52.8139719999245
                            ],
                            [
                                173.988738999607,
                                52.8123549997457
                            ],
                            [
                                174.007904000136,
                                52.8049470000336
                            ],
                            [
                                174.037872000135,
                                52.7966090000786
                            ],
                            [
                                174.050148000414,
                                52.7890840000186
                            ],
                            [
                                174.068389000131,
                                52.7962210001984
                            ],
                            [
                                174.114438999672,
                                52.803348000123
                            ],
                            [
                                174.128628999903,
                                52.8012120002263
                            ],
                            [
                                174.147733000333,
                                52.7933849997804
                            ],
                            [
                                174.199278999682,
                                52.7802800001022
                            ],
                            [
                                174.221435000239,
                                52.7676909999531
                            ],
                            [
                                174.232727000328,
                                52.7572939997999
                            ],
                            [
                                174.236633999874,
                                52.7481760001035
                            ],
                            [
                                174.238342999787,
                                52.7388150000565
                            ],
                            [
                                174.237827000301,
                                52.7032620001056
                            ],
                            [
                                174.234410000301,
                                52.6950830001914
                            ],
                            [
                                174.224005000317,
                                52.6834169998772
                            ],
                            [
                                174.211754000152,
                                52.6741889997383
                            ],
                            [
                                174.197241999707,
                                52.667860999993
                            ],
                            [
                                174.162514000392,
                                52.6567350001951
                            ],
                            [
                                174.146735999725,
                                52.6559800000962
                            ],
                            [
                                174.108315000226,
                                52.6644859998736
                            ],
                            [
                                174.0969319999,
                                52.662624000021
                            ],
                            [
                                174.07932300002,
                                52.6633170002609
                            ],
                            [
                                174.057155999592,
                                52.6716849999246
                            ],
                            [
                                174.017984000431,
                                52.6718380000031
                            ],
                            [
                                173.971902000204,
                                52.6808249998195
                            ],
                            [
                                173.951576999758,
                                52.6857229997889
                            ],
                            [
                                173.916145999563,
                                52.6996759998317
                            ],
                            [
                                173.901466999611,
                                52.7028179998741
                            ],
                            [
                                173.871498999612,
                                52.7056249998778
                            ],
                            [
                                173.857218000044,
                                52.7092720000276
                            ],
                            [
                                173.837962000176,
                                52.716022999781
                            ],
                            [
                                173.800242999935,
                                52.74033699977
                            ],
                            [
                                173.788707000347,
                                52.7497199997713
                            ],
                            [
                                173.779978999589,
                                52.7634150000705
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                178.184811999684,
                                51.7701899999725
                            ],
                            [
                                178.157680999726,
                                51.7845329999808
                            ],
                            [
                                178.140528999781,
                                51.7965339999271
                            ],
                            [
                                178.125054999786,
                                51.8140129997782
                            ],
                            [
                                178.120933999979,
                                51.8325160000818
                            ],
                            [
                                178.12383200012,
                                51.843983999803
                            ],
                            [
                                178.131583000362,
                                51.8558340000419
                            ],
                            [
                                178.155505999659,
                                51.8753199998713
                            ],
                            [
                                178.175828999556,
                                51.8845909997304
                            ],
                            [
                                178.197953000326,
                                51.888858000196
                            ],
                            [
                                178.220078000022,
                                51.8887279999829
                            ],
                            [
                                178.252061000256,
                                51.8833279997952
                            ],
                            [
                                178.270080000214,
                                51.8776399998292
                            ],
                            [
                                178.275500999679,
                                51.8789710002082
                            ],
                            [
                                178.293446999843,
                                51.8785820001378
                            ],
                            [
                                178.332176000212,
                                51.8708540000003
                            ],
                            [
                                178.368434000237,
                                51.8605389997475
                            ],
                            [
                                178.386350000263,
                                51.8491329998153
                            ],
                            [
                                178.408232000286,
                                51.8415569998983
                            ],
                            [
                                178.43115299985,
                                51.8269680001282
                            ],
                            [
                                178.441347999773,
                                51.8093209997655
                            ],
                            [
                                178.451449999808,
                                51.8021639998842
                            ],
                            [
                                178.479040000249,
                                51.7899720000724
                            ],
                            [
                                178.49277400037,
                                51.7768870000472
                            ],
                            [
                                178.497993000168,
                                51.763733999851
                            ],
                            [
                                178.496347000005,
                                51.7445219997776
                            ],
                            [
                                178.469216000047,
                                51.7187099998391
                            ],
                            [
                                178.43314499962,
                                51.7090499998134
                            ],
                            [
                                178.400459000303,
                                51.7036089999824
                            ],
                            [
                                178.370429000379,
                                51.7040370002521
                            ],
                            [
                                178.328374000247,
                                51.719234000117
                            ],
                            [
                                178.307468999844,
                                51.7188210000735
                            ],
                            [
                                178.297895999712,
                                51.7236310000299
                            ],
                            [
                                178.293704999586,
                                51.7205380001734
                            ],
                            [
                                178.276554000363,
                                51.721040999853
                            ],
                            [
                                178.249881000165,
                                51.724474000022
                            ],
                            [
                                178.227845999982,
                                51.7325909997222
                            ],
                            [
                                178.207123000051,
                                51.746744000099
                            ],
                            [
                                178.197291000352,
                                51.7673309998958
                            ],
                            [
                                178.184811999684,
                                51.7701899999725
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -168.090879000236,
                                64.9111129999893
                            ],
                            [
                                -168.137033000433,
                                64.918432000023
                            ],
                            [
                                -168.187034999726,
                                64.9349019999918
                            ],
                            [
                                -168.210114000373,
                                64.9513719998182
                            ],
                            [
                                -168.213962000368,
                                64.967844999905
                            ],
                            [
                                -168.213963000193,
                                64.9843180000721
                            ],
                            [
                                -168.207905999795,
                                65.0032910001473
                            ],
                            [
                                -168.191847999644,
                                65.0104700001494
                            ],
                            [
                                -168.169090000286,
                                65.024067000037
                            ],
                            [
                                -168.156162000079,
                                65.0291739998334
                            ],
                            [
                                -168.095414000319,
                                65.0409180000758
                            ],
                            [
                                -168.076108000222,
                                65.0432889999384
                            ],
                            [
                                -168.033434999599,
                                65.0412270001422
                            ],
                            [
                                -168.004871999739,
                                65.0388969999641
                            ],
                            [
                                -167.972261999692,
                                65.0305339998154
                            ],
                            [
                                -167.953076000144,
                                65.0234790000738
                            ],
                            [
                                -167.92429599965,
                                65.0027279999899
                            ],
                            [
                                -167.916447000398,
                                64.9886180001181
                            ],
                            [
                                -167.913828999741,
                                64.9699430000087
                            ],
                            [
                                -167.919060000133,
                                64.9608129999302
                            ],
                            [
                                -167.932210999923,
                                64.9520939999651
                            ],
                            [
                                -167.953093999687,
                                64.932994000065
                            ],
                            [
                                -167.976149000044,
                                64.9228719999996
                            ],
                            [
                                -168.037032000422,
                                64.9092839999414
                            ],
                            [
                                -168.090879000236,
                                64.9111129999893
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -178.941742999813,
                                51.7533469998502
                            ],
                            [
                                -178.949954000362,
                                51.7602439998319
                            ],
                            [
                                -178.954226999606,
                                51.7684150000573
                            ],
                            [
                                -178.957281000281,
                                51.7807990001087
                            ],
                            [
                                -178.946846999984,
                                51.8180780001304
                            ],
                            [
                                -178.9395540004,
                                51.833605000035
                            ],
                            [
                                -178.915324000058,
                                51.8580820001216
                            ],
                            [
                                -178.890360999597,
                                51.8731059999679
                            ],
                            [
                                -178.859169999932,
                                51.8821639999126
                            ],
                            [
                                -178.845100000251,
                                51.8879400001965
                            ],
                            [
                                -178.786531999683,
                                51.8878800001561
                            ],
                            [
                                -178.768325000302,
                                51.8841299997673
                            ],
                            [
                                -178.72884699992,
                                51.8696699997882
                            ],
                            [
                                -178.705650000171,
                                51.8587219997362
                            ],
                            [
                                -178.690327999613,
                                51.8495290001809
                            ],
                            [
                                -178.680468000325,
                                51.8355520001085
                            ],
                            [
                                -178.669724000407,
                                51.8255730002051
                            ],
                            [
                                -178.658607000219,
                                51.8071300000734
                            ],
                            [
                                -178.646648000133,
                                51.799394999842
                            ],
                            [
                                -178.646799999571,
                                51.7902999997498
                            ],
                            [
                                -178.659095999941,
                                51.7615350002449
                            ],
                            [
                                -178.663489999559,
                                51.7473579998847
                            ],
                            [
                                -178.670691999804,
                                51.7392249999319
                            ],
                            [
                                -178.700904000201,
                                51.7165389998142
                            ],
                            [
                                -178.709266000362,
                                51.7120220001202
                            ],
                            [
                                -178.749399999972,
                                51.7001540000485
                            ],
                            [
                                -178.771312999956,
                                51.6960419998953
                            ],
                            [
                                -178.796186000005,
                                51.6933950002695
                            ],
                            [
                                -178.805807999642,
                                51.6946169998193
                            ],
                            [
                                -178.81590200018,
                                51.6935929998218
                            ],
                            [
                                -178.828018999552,
                                51.6947759998501
                            ],
                            [
                                -178.841080999653,
                                51.6982550000106
                            ],
                            [
                                -178.868490000344,
                                51.7116910002087
                            ],
                            [
                                -178.880364000041,
                                51.7215029999458
                            ],
                            [
                                -178.893702000327,
                                51.7286439998185
                            ],
                            [
                                -178.915098000102,
                                51.7371349998877
                            ],
                            [
                                -178.941742999813,
                                51.7533469998502
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -163.262370999798,
                                55.3941970000712
                            ],
                            [
                                -163.271923999838,
                                55.4238520001682
                            ],
                            [
                                -163.270886999945,
                                55.4317180000915
                            ],
                            [
                                -163.25907900037,
                                55.4495179998266
                            ],
                            [
                                -163.25212900002,
                                55.4538240001776
                            ],
                            [
                                -163.259932999964,
                                55.4590480001264
                            ],
                            [
                                -163.26713500021,
                                55.4667840001519
                            ],
                            [
                                -163.271500000414,
                                55.4770149998167
                            ],
                            [
                                -163.271072999718,
                                55.4848889999811
                            ],
                            [
                                -163.265733000444,
                                55.4991780001259
                            ],
                            [
                                -163.259997000437,
                                55.506227999924
                            ],
                            [
                                -163.242512000406,
                                55.5177179999375
                            ],
                            [
                                -163.223407999975,
                                55.5242869998858
                            ],
                            [
                                -163.190418999812,
                                55.5305579997779
                            ],
                            [
                                -163.173877999787,
                                55.5321220000823
                            ],
                            [
                                -163.156606000191,
                                55.531053000084
                            ],
                            [
                                -163.146200000381,
                                55.5284820002428
                            ],
                            [
                                -163.136534000363,
                                55.5250240001336
                            ],
                            [
                                -163.101369000307,
                                55.5060740001646
                            ],
                            [
                                -163.095325000328,
                                55.5017639999775
                            ],
                            [
                                -163.094013000076,
                                55.4970719997706
                            ],
                            [
                                -163.076008000361,
                                55.4886869998262
                            ],
                            [
                                -163.06651600042,
                                55.4804089999955
                            ],
                            [
                                -163.056383000422,
                                55.4651730002534
                            ],
                            [
                                -163.041855999908,
                                55.454637000121
                            ],
                            [
                                -163.033158000186,
                                55.4427649998121
                            ],
                            [
                                -163.027937999664,
                                55.4208300001427
                            ],
                            [
                                -163.034498999852,
                                55.4037020000891
                            ],
                            [
                                -163.049207000116,
                                55.3819499998351
                            ],
                            [
                                -163.070963999567,
                                55.361327000108
                            ],
                            [
                                -163.106882999658,
                                55.3488299997834
                            ],
                            [
                                -163.13199900028,
                                55.3437930001361
                            ],
                            [
                                -163.153940999678,
                                55.3431229998074
                            ],
                            [
                                -163.179788999696,
                                55.3473189999963
                            ],
                            [
                                -163.196267999797,
                                55.3507840001502
                            ],
                            [
                                -163.214946000255,
                                55.3596639997842
                            ],
                            [
                                -163.227610999798,
                                55.3625859997936
                            ],
                            [
                                -163.235362000039,
                                55.3661120000109
                            ],
                            [
                                -163.247050999788,
                                55.3744970001642
                            ],
                            [
                                -163.262370999798,
                                55.3941970000712
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -161.316507999919,
                                64.0631809999741
                            ],
                            [
                                -161.373180000078,
                                64.0697419998795
                            ],
                            [
                                -161.414440999992,
                                64.0867699999116
                            ],
                            [
                                -161.432417000293,
                                64.1053360001929
                            ],
                            [
                                -161.436921000414,
                                64.1335529999781
                            ],
                            [
                                -161.420197000091,
                                64.1584160001054
                            ],
                            [
                                -161.393287000066,
                                64.1720139998123
                            ],
                            [
                                -161.35571299959,
                                64.1837770001118
                            ],
                            [
                                -161.310362999658,
                                64.1893679998894
                            ],
                            [
                                -161.264812999708,
                                64.184660000157
                            ],
                            [
                                -161.223547999597,
                                64.1764329999369
                            ],
                            [
                                -161.197016999689,
                                64.1639769998131
                            ],
                            [
                                -161.176662000004,
                                64.1468559998124
                            ],
                            [
                                -161.176784000203,
                                64.1402830000234
                            ],
                            [
                                -161.185436999719,
                                64.1261559999397
                            ],
                            [
                                -161.183338999644,
                                64.1161780001246
                            ],
                            [
                                -161.184482999667,
                                64.1061590000529
                            ],
                            [
                                -161.196380999654,
                                64.0919739999303
                            ],
                            [
                                -161.209918000131,
                                64.0820459999627
                            ],
                            [
                                -161.221749999996,
                                64.0752940001727
                            ],
                            [
                                -161.252272999839,
                                64.0659630000708
                            ],
                            [
                                -161.306031999789,
                                64.0619689998243
                            ],
                            [
                                -161.316507999919,
                                64.0631809999741
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -168.918060000111,
                                65.6843320000973
                            ],
                            [
                                -168.971902999727,
                                65.6887649999152
                            ],
                            [
                                -168.971029999866,
                                65.8146450000339
                            ],
                            [
                                -168.926957000025,
                                65.8222600000274
                            ],
                            [
                                -168.892775000333,
                                65.8213529998597
                            ],
                            [
                                -168.861004999637,
                                65.8178070000573
                            ],
                            [
                                -168.835714000011,
                                65.8106760001685
                            ],
                            [
                                -168.80097999995,
                                65.7951730000632
                            ],
                            [
                                -168.787060000056,
                                65.7846239998161
                            ],
                            [
                                -168.781427999806,
                                65.7736120000971
                            ],
                            [
                                -168.779851999963,
                                65.7670630000705
                            ],
                            [
                                -168.780883000008,
                                65.7644669999269
                            ],
                            [
                                -168.762465999666,
                                65.7511659999321
                            ],
                            [
                                -168.76166399995,
                                65.7400249999238
                            ],
                            [
                                -168.767353999927,
                                65.730747000007
                            ],
                            [
                                -168.785494000259,
                                65.7163190000754
                            ],
                            [
                                -168.856091000335,
                                65.6912359998967
                            ],
                            [
                                -168.880929000225,
                                65.6857859999334
                            ],
                            [
                                -168.918060000111,
                                65.6843320000973
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                175.774501000344,
                                52.3655599999571
                            ],
                            [
                                175.763482000065,
                                52.3807430001234
                            ],
                            [
                                175.77477300033,
                                52.4076680001406
                            ],
                            [
                                175.782522999848,
                                52.4172439999064
                            ],
                            [
                                175.811330999931,
                                52.4326639998325
                            ],
                            [
                                175.841909000026,
                                52.440288000076
                            ],
                            [
                                175.856283000379,
                                52.4403789999572
                            ],
                            [
                                175.874776999815,
                                52.4397090001063
                            ],
                            [
                                175.898570000315,
                                52.4319399999441
                            ],
                            [
                                175.917899999802,
                                52.4343379997384
                            ],
                            [
                                175.955316999919,
                                52.4239919998187
                            ],
                            [
                                175.968574000014,
                                52.4161400000791
                            ],
                            [
                                175.978603000255,
                                52.4168360002481
                            ],
                            [
                                176.004910999682,
                                52.409504000129
                            ],
                            [
                                176.031037000432,
                                52.3978080001328
                            ],
                            [
                                176.043397000376,
                                52.3902700000051
                            ],
                            [
                                176.049181000064,
                                52.3856400002554
                            ],
                            [
                                176.060489000047,
                                52.3722409997372
                            ],
                            [
                                176.064517999792,
                                52.3633760000056
                            ],
                            [
                                176.062871999629,
                                52.3501909997704
                            ],
                            [
                                176.054021999571,
                                52.3367480002062
                            ],
                            [
                                176.037391000032,
                                52.321266999777
                            ],
                            [
                                176.024385999833,
                                52.3157370001763
                            ],
                            [
                                176.024298999794,
                                52.3130730000565
                            ],
                            [
                                176.016914000149,
                                52.3040770000142
                            ],
                            [
                                176.009925000339,
                                52.2995530001476
                            ],
                            [
                                175.970893000021,
                                52.2886880000816
                            ],
                            [
                                175.920079999893,
                                52.281354999747
                            ],
                            [
                                175.902684000448,
                                52.2809339999442
                            ],
                            [
                                175.88742499964,
                                52.2830940001047
                            ],
                            [
                                175.862461000252,
                                52.2895789998644
                            ],
                            [
                                175.831391999888,
                                52.3055770001511
                            ],
                            [
                                175.820740000031,
                                52.3157020001609
                            ],
                            [
                                175.801357999767,
                                52.3398800001625
                            ],
                            [
                                175.789913000414,
                                52.3480049999598
                            ],
                            [
                                175.774501000344,
                                52.3655599999571
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -168.846055000422,
                                65.5925120000276
                            ],
                            [
                                -168.865921000385,
                                65.6069199999565
                            ],
                            [
                                -168.869538000401,
                                65.6131290001405
                            ],
                            [
                                -168.869228999706,
                                65.6208529999447
                            ],
                            [
                                -168.864929999625,
                                65.6298220000859
                            ],
                            [
                                -168.85673999989,
                                65.640596000083
                            ],
                            [
                                -168.841188000078,
                                65.6529779999773
                            ],
                            [
                                -168.820066999763,
                                65.6651900000028
                            ],
                            [
                                -168.794617000129,
                                65.6712160001183
                            ],
                            [
                                -168.734928999828,
                                65.6742989998499
                            ],
                            [
                                -168.710586000406,
                                65.6731360000805
                            ],
                            [
                                -168.677629999854,
                                65.6641459999801
                            ],
                            [
                                -168.666028999968,
                                65.6598310000535
                            ],
                            [
                                -168.653304000151,
                                65.6521029998756
                            ],
                            [
                                -168.637909999624,
                                65.6398630000597
                            ],
                            [
                                -168.628523000163,
                                65.6257760000605
                            ],
                            [
                                -168.626815000076,
                                65.6123729999878
                            ],
                            [
                                -168.633680999862,
                                65.6013579999732
                            ],
                            [
                                -168.652314000114,
                                65.5909359998551
                            ],
                            [
                                -168.67346800004,
                                65.5841690001246
                            ],
                            [
                                -168.733163999839,
                                65.5722440001614
                            ],
                            [
                                -168.766100000299,
                                65.5714840001715
                            ],
                            [
                                -168.789959999847,
                                65.5749470000532
                            ],
                            [
                                -168.82913899958,
                                65.584613999968
                            ],
                            [
                                -168.846055000422,
                                65.5925120000276
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -172.85643399981,
                                60.1537419998029
                            ],
                            [
                                -172.881018000155,
                                60.1687850000125
                            ],
                            [
                                -172.890361000133,
                                60.1821239997993
                            ],
                            [
                                -172.88569299998,
                                60.208802999996
                            ],
                            [
                                -172.867008999674,
                                60.2221419998065
                            ],
                            [
                                -172.815624999809,
                                60.2399279997912
                            ],
                            [
                                -172.796941000401,
                                60.2488209999354
                            ],
                            [
                                -172.77358599957,
                                60.2554900002075
                            ],
                            [
                                -172.731544999682,
                                60.2621609998529
                            ],
                            [
                                -172.69857399996,
                                60.2583739999619
                            ],
                            [
                                -172.673409999657,
                                60.2497840000184
                            ],
                            [
                                -172.649218999673,
                                60.2368949999082
                            ],
                            [
                                -172.637484999718,
                                60.2192939999937
                            ],
                            [
                                -172.634323999811,
                                60.2007429998034
                            ],
                            [
                                -172.638120999753,
                                60.1958380000301
                            ],
                            [
                                -172.655702000043,
                                60.1784839999792
                            ],
                            [
                                -172.680662000131,
                                60.1599100000309
                            ],
                            [
                                -172.694192999862,
                                60.154610000029
                            ],
                            [
                                -172.761788000041,
                                60.1406770000232
                            ],
                            [
                                -172.799540999718,
                                60.1388040000329
                            ],
                            [
                                -172.829635000114,
                                60.142109000112
                            ],
                            [
                                -172.848320000244,
                                60.1487779999565
                            ],
                            [
                                -172.85643399981,
                                60.1537419998029
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -160.07319299988,
                                58.5645739998818
                            ],
                            [
                                -160.089374000055,
                                58.5789639998383
                            ],
                            [
                                -160.094562999716,
                                58.5873369998699
                            ],
                            [
                                -160.097589999728,
                                58.5999139998745
                            ],
                            [
                                -160.088190999674,
                                58.6216939997691
                            ],
                            [
                                -160.082766000011,
                                58.6304519998525
                            ],
                            [
                                -160.074875999853,
                                58.6398859998772
                            ],
                            [
                                -160.063998999865,
                                58.6465610001207
                            ],
                            [
                                -160.017456000404,
                                58.6595590002071
                            ],
                            [
                                -160.004645000373,
                                58.6656019997695
                            ],
                            [
                                -159.985525999874,
                                58.6640519998798
                            ],
                            [
                                -159.940580999997,
                                58.6563219999384
                            ],
                            [
                                -159.914198000227,
                                58.6502299999707
                            ],
                            [
                                -159.905282000047,
                                58.646705999959
                            ],
                            [
                                -159.894615999946,
                                58.6399300002024
                            ],
                            [
                                -159.885292000235,
                                58.631442000054
                            ],
                            [
                                -159.867680999807,
                                58.6105170000686
                            ],
                            [
                                -159.864298999967,
                                58.6026859999475
                            ],
                            [
                                -159.862877999935,
                                58.594237000096
                            ],
                            [
                                -159.863447000023,
                                58.5786860000658
                            ],
                            [
                                -159.868122999673,
                                58.5725439997744
                            ],
                            [
                                -159.880463000424,
                                58.5629709998757
                            ],
                            [
                                -159.896532000446,
                                58.5537180001512
                            ],
                            [
                                -159.921320999931,
                                58.5439639998078
                            ],
                            [
                                -159.952401000166,
                                58.5384769998513
                            ],
                            [
                                -159.972615000284,
                                58.5362469998873
                            ],
                            [
                                -160.004559999984,
                                58.5372599997869
                            ],
                            [
                                -160.0085350002,
                                58.5346839999929
                            ],
                            [
                                -160.059052000053,
                                58.5478259999902
                            ],
                            [
                                -160.07319299988,
                                58.5645739998818
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -161.839220000174,
                                63.5703550001189
                            ],
                            [
                                -161.849814000305,
                                63.5773569998347
                            ],
                            [
                                -161.857025999698,
                                63.5879819999943
                            ],
                            [
                                -161.859288999629,
                                63.6109109998871
                            ],
                            [
                                -161.85584599969,
                                63.6216319999348
                            ],
                            [
                                -161.840317000342,
                                63.635115999812
                            ],
                            [
                                -161.826228000394,
                                63.6424889998446
                            ],
                            [
                                -161.814522000027,
                                63.646670000096
                            ],
                            [
                                -161.79545600013,
                                63.6504180000572
                            ],
                            [
                                -161.758495000123,
                                63.6545170001656
                            ],
                            [
                                -161.71475100017,
                                63.652538000051
                            ],
                            [
                                -161.69638700043,
                                63.6489859999765
                            ],
                            [
                                -161.67713900006,
                                63.6426459999684
                            ],
                            [
                                -161.661145000381,
                                63.6353819998708
                            ],
                            [
                                -161.64947699965,
                                63.6276399998292
                            ],
                            [
                                -161.639572000156,
                                63.6177460001962
                            ],
                            [
                                -161.633969000218,
                                63.6043240000323
                            ],
                            [
                                -161.641536000336,
                                63.5884969998455
                            ],
                            [
                                -161.651661999763,
                                63.5796619999071
                            ],
                            [
                                -161.675224000283,
                                63.5663379998079
                            ],
                            [
                                -161.694076999745,
                                63.5592320001126
                            ],
                            [
                                -161.711815000395,
                                63.5551180001574
                            ],
                            [
                                -161.751964999898,
                                63.5520629999889
                            ],
                            [
                                -161.784470000363,
                                63.5537880001438
                            ],
                            [
                                -161.812403000036,
                                63.5599429999543
                            ],
                            [
                                -161.839220000174,
                                63.5703550001189
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -177.540981000375,
                                51.8834900001021
                            ],
                            [
                                -177.546487000228,
                                51.8893709999051
                            ],
                            [
                                -177.551982000211,
                                51.9081470002686
                            ],
                            [
                                -177.549664999854,
                                51.9242230001792
                            ],
                            [
                                -177.542920000441,
                                51.9351269999866
                            ],
                            [
                                -177.534436000081,
                                51.9414669998473
                            ],
                            [
                                -177.509137999884,
                                51.9657460001096
                            ],
                            [
                                -177.493634999576,
                                51.9746359998108
                            ],
                            [
                                -177.459545999771,
                                51.9828839997722
                            ],
                            [
                                -177.422190999578,
                                51.9764470001432
                            ],
                            [
                                -177.374366000002,
                                51.9626159999207
                            ],
                            [
                                -177.356205999578,
                                51.9503480001526
                            ],
                            [
                                -177.350101000399,
                                51.9428330000364
                            ],
                            [
                                -177.344004999643,
                                51.9321960001565
                            ],
                            [
                                -177.339265000419,
                                51.9271540000412
                            ],
                            [
                                -177.333741000123,
                                51.9118559997574
                            ],
                            [
                                -177.332580000382,
                                51.9007709999242
                            ],
                            [
                                -177.336332999943,
                                51.8889820000063
                            ],
                            [
                                -177.352354000284,
                                51.8717009998712
                            ],
                            [
                                -177.361934999913,
                                51.8642839999127
                            ],
                            [
                                -177.403225000139,
                                51.8472300002631
                            ],
                            [
                                -177.429807000101,
                                51.8410949998177
                            ],
                            [
                                -177.468963000267,
                                51.839857000183
                            ],
                            [
                                -177.486726000132,
                                51.8436489998998
                            ],
                            [
                                -177.523687000139,
                                51.8661849997452
                            ],
                            [
                                -177.532568999985,
                                51.8729910000916
                            ],
                            [
                                -177.540981000375,
                                51.8834900001021
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -178.409813000151,
                                51.4493600000664
                            ],
                            [
                                -178.430875000016,
                                51.4765840001842
                            ],
                            [
                                -178.431181000337,
                                51.4891189997452
                            ],
                            [
                                -178.4229420002,
                                51.5089959999619
                            ],
                            [
                                -178.405150000022,
                                51.5223799997998
                            ],
                            [
                                -178.390072999686,
                                51.5291629999367
                            ],
                            [
                                -178.359981999664,
                                51.5374750002008
                            ],
                            [
                                -178.332762000018,
                                51.5383849998636
                            ],
                            [
                                -178.322131000077,
                                51.5353220001447
                            ],
                            [
                                -178.310607000184,
                                51.5405229998665
                            ],
                            [
                                -178.292174999773,
                                51.5423249998735
                            ],
                            [
                                -178.272583000345,
                                51.5410139999952
                            ],
                            [
                                -178.255432000225,
                                51.5357890000034
                            ],
                            [
                                -178.23861599984,
                                51.5298920000571
                            ],
                            [
                                -178.220882000287,
                                51.5182500000146
                            ],
                            [
                                -178.213428000146,
                                51.5097270002439
                            ],
                            [
                                -178.208918000177,
                                51.5092239997757
                            ],
                            [
                                -178.202264999928,
                                51.5038989997601
                            ],
                            [
                                -178.187622999786,
                                51.4822269999238
                            ],
                            [
                                -178.187825000351,
                                51.4726319997795
                            ],
                            [
                                -178.190966999991,
                                51.4564489999956
                            ],
                            [
                                -178.19841199991,
                                51.4436530000056
                            ],
                            [
                                -178.218127000261,
                                51.43149000018
                            ],
                            [
                                -178.248645000081,
                                51.4231709999798
                            ],
                            [
                                -178.278947999816,
                                51.4198719998592
                            ],
                            [
                                -178.304338999899,
                                51.4213429998605
                            ],
                            [
                                -178.321367999821,
                                51.4254080001272
                            ],
                            [
                                -178.331819999661,
                                51.4308129999928
                            ],
                            [
                                -178.354081999625,
                                51.4296790001605
                            ],
                            [
                                -178.370317000226,
                                51.4317830002272
                            ],
                            [
                                -178.387408999897,
                                51.4368100000446
                            ],
                            [
                                -178.409813000151,
                                51.4493600000664
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -175.59040299959,
                                52.1412240000585
                            ],
                            [
                                -175.598651999773,
                                52.1466840001716
                            ],
                            [
                                -175.603129999955,
                                52.1518749997298
                            ],
                            [
                                -175.609112999834,
                                52.1674000002146
                            ],
                            [
                                -175.60975600044,
                                52.1830039999868
                            ],
                            [
                                -175.604020000433,
                                52.1986830000472
                            ],
                            [
                                -175.579299999646,
                                52.2202380002643
                            ],
                            [
                                -175.553297999642,
                                52.2311499998629
                            ],
                            [
                                -175.532788999972,
                                52.2366060002556
                            ],
                            [
                                -175.492046999917,
                                52.2375770001888
                            ],
                            [
                                -175.456459000262,
                                52.2274769998201
                            ],
                            [
                                -175.419070999559,
                                52.205472999736
                            ],
                            [
                                -175.410707999572,
                                52.1967759997751
                            ],
                            [
                                -175.403742000227,
                                52.1819939997552
                            ],
                            [
                                -175.404266999934,
                                52.1716360002203
                            ],
                            [
                                -175.437010999875,
                                52.1328070000205
                            ],
                            [
                                -175.454741999953,
                                52.1209500001622
                            ],
                            [
                                -175.472473000032,
                                52.1131369999129
                            ],
                            [
                                -175.485741999822,
                                52.1093869997442
                            ],
                            [
                                -175.512360999594,
                                52.1073739999921
                            ],
                            [
                                -175.535496000317,
                                52.1128510000878
                            ],
                            [
                                -175.561254999748,
                                52.1225709998099
                            ],
                            [
                                -175.59040299959,
                                52.1412240000585
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -170.036927999888,
                                57.1569650001735
                            ],
                            [
                                -170.044012000132,
                                57.1650519997939
                            ],
                            [
                                -170.050499999627,
                                57.1868759998052
                            ],
                            [
                                -170.049117000128,
                                57.1956840000993
                            ],
                            [
                                -170.042222000029,
                                57.2050469999556
                            ],
                            [
                                -170.025195999582,
                                57.2168359999838
                            ],
                            [
                                -170.007985000085,
                                57.225298999994
                            ],
                            [
                                -169.960408000206,
                                57.2374189999192
                            ],
                            [
                                -169.923114999938,
                                57.2384149999499
                            ],
                            [
                                -169.909747000414,
                                57.2362950000429
                            ],
                            [
                                -169.883682999588,
                                57.2284010000888
                            ],
                            [
                                -169.871229999757,
                                57.2215360002391
                            ],
                            [
                                -169.849310999925,
                                57.1900129998979
                            ],
                            [
                                -169.847694000074,
                                57.1848970001929
                            ],
                            [
                                -169.849033999915,
                                57.1761650000109
                            ],
                            [
                                -169.869477000362,
                                57.1580050001782
                            ],
                            [
                                -169.886868999609,
                                57.1454380002395
                            ],
                            [
                                -169.930261999932,
                                57.1321200000116
                            ],
                            [
                                -169.955590000266,
                                57.1282269999619
                            ],
                            [
                                -169.979485999799,
                                57.1289480001556
                            ],
                            [
                                -170.003718999616,
                                57.1350410001677
                            ],
                            [
                                -170.022458999998,
                                57.1443249998609
                            ],
                            [
                                -170.036927999888,
                                57.1569650001735
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -168.128853999671,
                                53.9194960001801
                            ],
                            [
                                -168.132486999581,
                                53.9274229999813
                            ],
                            [
                                -168.135845999854,
                                53.9467330001879
                            ],
                            [
                                -168.133342999723,
                                53.9565840001135
                            ],
                            [
                                -168.1298029997,
                                53.9626639999111
                            ],
                            [
                                -168.110972999805,
                                53.9815640002013
                            ],
                            [
                                -168.082955999567,
                                53.9924369998593
                            ],
                            [
                                -168.049168999885,
                                53.9949559997508
                            ],
                            [
                                -168.022585000274,
                                53.9941179999857
                            ],
                            [
                                -168.002248999957,
                                53.9880409999583
                            ],
                            [
                                -167.997648999577,
                                53.990686000038
                            ],
                            [
                                -167.98956099995,
                                53.9893050001989
                            ],
                            [
                                -167.969997000111,
                                53.9773879997856
                            ],
                            [
                                -167.959038999932,
                                53.9637000001391
                            ],
                            [
                                -167.953445000215,
                                53.9365379999799
                            ],
                            [
                                -167.953452999713,
                                53.9211580000566
                            ],
                            [
                                -167.956107000354,
                                53.9127269997398
                            ],
                            [
                                -167.963187000401,
                                53.9013359999053
                            ],
                            [
                                -167.968467000298,
                                53.8964909999939
                            ],
                            [
                                -167.984061999769,
                                53.8864959998956
                            ],
                            [
                                -168.001946000009,
                                53.8792350001482
                            ],
                            [
                                -168.022944000299,
                                53.874920000113
                            ],
                            [
                                -168.047208999903,
                                53.8751709998765
                            ],
                            [
                                -168.072854000429,
                                53.8795319998274
                            ],
                            [
                                -168.083040000131,
                                53.8829589998653
                            ],
                            [
                                -168.104619000204,
                                53.8961350001527
                            ],
                            [
                                -168.123085000052,
                                53.9119649997927
                            ],
                            [
                                -168.128853999671,
                                53.9194960001801
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -175.193120000136,
                                52.1808620002326
                            ],
                            [
                                -175.205311999851,
                                52.1912299997281
                            ],
                            [
                                -175.222985000203,
                                52.2206039999333
                            ],
                            [
                                -175.224817000139,
                                52.2329649997983
                            ],
                            [
                                -175.204644999854,
                                52.2591119999955
                            ],
                            [
                                -175.175102999827,
                                52.271564999921
                            ],
                            [
                                -175.143578000252,
                                52.2782189997295
                            ],
                            [
                                -175.119803000194,
                                52.2805400000315
                            ],
                            [
                                -175.095905000113,
                                52.2742850001956
                            ],
                            [
                                -175.070695999605,
                                52.2629250000628
                            ],
                            [
                                -175.053572999972,
                                52.2513049999621
                            ],
                            [
                                -175.036523000134,
                                52.2290109999506
                            ],
                            [
                                -175.036327000315,
                                52.2176280000387
                            ],
                            [
                                -175.043346000262,
                                52.2069000000944
                            ],
                            [
                                -175.064189999669,
                                52.1861010002529
                            ],
                            [
                                -175.078685000396,
                                52.1771739999421
                            ],
                            [
                                -175.102323000186,
                                52.1680050002667
                            ],
                            [
                                -175.132153999918,
                                52.1631710001217
                            ],
                            [
                                -175.152541000288,
                                52.1629789997675
                            ],
                            [
                                -175.176529999707,
                                52.1706539999168
                            ],
                            [
                                -175.193120000136,
                                52.1808620002326
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -161.177012999633,
                                55.1306470000894
                            ],
                            [
                                -161.188977999567,
                                55.1453950002003
                            ],
                            [
                                -161.19439899993,
                                55.1549610001796
                            ],
                            [
                                -161.192704000262,
                                55.1646879999125
                            ],
                            [
                                -161.175005999795,
                                55.184381000243
                            ],
                            [
                                -161.160296999706,
                                55.1943750002515
                            ],
                            [
                                -161.148914000278,
                                55.201014000071
                            ],
                            [
                                -161.128953999705,
                                55.2065460001804
                            ],
                            [
                                -161.091640000419,
                                55.2081779999844
                            ],
                            [
                                -161.054510000358,
                                55.2051630001876
                            ],
                            [
                                -161.043862999625,
                                55.2010879999912
                            ],
                            [
                                -161.0270210002,
                                55.189521000234
                            ],
                            [
                                -161.007494999995,
                                55.1705989998304
                            ],
                            [
                                -161.005418999662,
                                55.1634659998882
                            ],
                            [
                                -161.008562000025,
                                55.1509069997919
                            ],
                            [
                                -161.017165000211,
                                55.1370439998749
                            ],
                            [
                                -161.01997100029,
                                55.1292389998842
                            ],
                            [
                                -161.058929999915,
                                55.1117699998525
                            ],
                            [
                                -161.084404999664,
                                55.1047670001931
                            ],
                            [
                                -161.102467000178,
                                55.1037920000255
                            ],
                            [
                                -161.132885000439,
                                55.1096600002544
                            ],
                            [
                                -161.151562000174,
                                55.1151300000629
                            ],
                            [
                                -161.166057999828,
                                55.1221259999754
                            ],
                            [
                                -161.177012999633,
                                55.1306470000894
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -162.783454000447,
                                54.7652849997569
                            ],
                            [
                                -162.797004000444,
                                54.7721810002151
                            ],
                            [
                                -162.807411000079,
                                54.7798709999088
                            ],
                            [
                                -162.817819000437,
                                54.7994390000167
                            ],
                            [
                                -162.819010000315,
                                54.8111580000099
                            ],
                            [
                                -162.81287699975,
                                54.8224569997881
                            ],
                            [
                                -162.806955999794,
                                54.829522000142
                            ],
                            [
                                -162.780742000079,
                                54.8477409998411
                            ],
                            [
                                -162.76339500014,
                                54.8552170002526
                            ],
                            [
                                -162.743205000311,
                                54.8578510001571
                            ],
                            [
                                -162.725168999736,
                                54.8573940001609
                            ],
                            [
                                -162.702218999859,
                                54.8551509998599
                            ],
                            [
                                -162.692726999919,
                                54.85277200022
                            ],
                            [
                                -162.672949999644,
                                54.8434410002407
                            ],
                            [
                                -162.661749999616,
                                54.8355690001765
                            ],
                            [
                                -162.650213000202,
                                54.82341499998
                            ],
                            [
                                -162.637181000238,
                                54.8047929998554
                            ],
                            [
                                -162.637688000402,
                                54.8011209998728
                            ],
                            [
                                -162.6440460002,
                                54.7890989998445
                            ],
                            [
                                -162.653750999677,
                                54.7803389999514
                            ],
                            [
                                -162.677799000445,
                                54.7627159997609
                            ],
                            [
                                -162.692356000198,
                                54.7587710001905
                            ],
                            [
                                -162.70675999979,
                                54.7549869999
                            ],
                            [
                                -162.740178000299,
                                54.7531020000373
                            ],
                            [
                                -162.762060000321,
                                54.7579680000904
                            ],
                            [
                                -162.783454000447,
                                54.7652849997569
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                177.948138000259,
                                51.8466270000589
                            ],
                            [
                                177.939042999978,
                                51.8549200002657
                            ],
                            [
                                177.935471000168,
                                51.8610919999957
                            ],
                            [
                                177.93360999992,
                                51.8696459999001
                            ],
                            [
                                177.93428000029,
                                51.8757649998139
                            ],
                            [
                                177.945539999694,
                                51.8956169997568
                            ],
                            [
                                177.95478700031,
                                51.9035220002497
                            ],
                            [
                                177.974409999701,
                                51.9146319998903
                            ],
                            [
                                177.996976000337,
                                51.9220340002336
                            ],
                            [
                                178.034347000422,
                                51.9226059998592
                            ],
                            [
                                178.068986000049,
                                51.9144810001805
                            ],
                            [
                                178.093706999762,
                                51.8944999997321
                            ],
                            [
                                178.100452000073,
                                51.8754939999719
                            ],
                            [
                                178.101277000254,
                                51.8629650000503
                            ],
                            [
                                178.097952000316,
                                51.8506899999871
                            ],
                            [
                                178.084037000445,
                                51.8353369997484
                            ],
                            [
                                178.066398000428,
                                51.827204
                            ],
                            [
                                178.044882999928,
                                51.821497000022
                            ],
                            [
                                177.997183999851,
                                51.8220290000342
                            ],
                            [
                                177.964008000089,
                                51.8348699999274
                            ],
                            [
                                177.948138000259,
                                51.8466270000589
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                177.28821200009,
                                52.1064279999282
                            ],
                            [
                                177.283487999861,
                                52.1141589998345
                            ],
                            [
                                177.280160000449,
                                52.1275790000769
                            ],
                            [
                                177.281807000437,
                                52.1351259999315
                            ],
                            [
                                177.288827000209,
                                52.1441899997617
                            ],
                            [
                                177.31540900017,
                                52.1609760002658
                            ],
                            [
                                177.340528000267,
                                52.170323000043
                            ],
                            [
                                177.352033999718,
                                52.1726500001306
                            ],
                            [
                                177.367356000276,
                                52.1752989997711
                            ],
                            [
                                177.383654999553,
                                52.1742760001007
                            ],
                            [
                                177.408834999749,
                                52.1676320000805
                            ],
                            [
                                177.425774999983,
                                52.1572030002625
                            ],
                            [
                                177.43792400004,
                                52.1468729998535
                            ],
                            [
                                177.445983000253,
                                52.1351300002448
                            ],
                            [
                                177.445931000374,
                                52.1113330001795
                            ],
                            [
                                177.443023000187,
                                52.1052590000137
                            ],
                            [
                                177.435577000443,
                                52.0970110000488
                            ],
                            [
                                177.42880100017,
                                52.0917380002407
                            ],
                            [
                                177.414975999813,
                                52.0847869999776
                            ],
                            [
                                177.386804999589,
                                52.0751960001821
                            ],
                            [
                                177.355214999717,
                                52.0713959999978
                            ],
                            [
                                177.332628999888,
                                52.0752090000481
                            ],
                            [
                                177.305189999958,
                                52.0903239998931
                            ],
                            [
                                177.290599999695,
                                52.1021500002055
                            ],
                            [
                                177.28821200009,
                                52.1064279999282
                            ]
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 63.3461909,
            "name": "Alaska",
            "intptlon": -152.837069,
            "geo_point_2d": [
                63.7431630974,
                -151.594035116
            ],
            "geoid": "02",
            "mtfcc": "G4000",
            "region": 4
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -151.594035116,
                63.7431630974
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "0a8897534bc007f44ca7e74b694ecedfbb23e3d0",
        "fields": {
            "arealand": "-1971382068",
            "objectid": 26,
            "basename": "Texas",
            "stusab": "TX",
            "statens": "01779801",
            "centlon": -99.2890858,
            "state": "48",
            "gid": 4,
            "centlat": 31.4277663,
            "division": 7,
            "areawater": "1846131515",
            "oid": "319382675",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -103.980179000239,
                            32.0001249996323
                        ],
                        [
                            -103.064510000375,
                            32.0004240002891
                        ],
                        [
                            -103.064625000003,
                            32.9998989996924
                        ],
                        [
                            -103.043651000002,
                            34.0036390000545
                        ],
                        [
                            -103.040895000151,
                            35.8961010001164
                        ],
                        [
                            -103.041712999761,
                            36.5004389999801
                        ],
                        [
                            -100.000405999635,
                            36.4997020001954
                        ],
                        [
                            -100.000276999764,
                            34.5604499997831
                        ],
                        [
                            -99.9924059998718,
                            34.5593670001857
                        ],
                        [
                            -99.9716059999482,
                            34.5621689998305
                        ],
                        [
                            -99.9588989996742,
                            34.571270999986
                        ],
                        [
                            -99.956716999934,
                            34.5765239999411
                        ],
                        [
                            -99.9538170001433,
                            34.5785270000936
                        ],
                        [
                            -99.944679000205,
                            34.5791350001936
                        ],
                        [
                            -99.9232109995613,
                            34.5745520003415
                        ],
                        [
                            -99.9218010002981,
                            34.5702529997624
                        ],
                        [
                            -99.9034890004365,
                            34.5583619998583
                        ],
                        [
                            -99.8937599997711,
                            34.5542190003392
                        ],
                        [
                            -99.8769569998052,
                            34.5400980002758
                        ],
                        [
                            -99.868952999844,
                            34.5276150000228
                        ],
                        [
                            -99.8530529998764,
                            34.5115850003511
                        ],
                        [
                            -99.832904000056,
                            34.5000679999683
                        ],
                        [
                            -99.8218389997472,
                            34.4978479999081
                        ],
                        [
                            -99.816803000247,
                            34.4901989999983
                        ],
                        [
                            -99.81873899994,
                            34.4849760000545
                        ],
                        [
                            -99.8143129996368,
                            34.4762040003017
                        ],
                        [
                            -99.8028640000868,
                            34.4656679999942
                        ],
                        [
                            -99.7936840003157,
                            34.4538939998518
                        ],
                        [
                            -99.7860810002124,
                            34.4510990001468
                        ],
                        [
                            -99.7829860004281,
                            34.4443639998241
                        ],
                        [
                            -99.7757430001749,
                            34.4442249998632
                        ],
                        [
                            -99.7655990003061,
                            34.4374879998549
                        ],
                        [
                            -99.7648820000806,
                            34.4352659997948
                        ],
                        [
                            -99.7672339997002,
                            34.4305019999673
                        ],
                        [
                            -99.7596070002058,
                            34.4266740003612
                        ],
                        [
                            -99.7542479997671,
                            34.42128900009
                        ],
                        [
                            -99.7487889997692,
                            34.4197530000049
                        ],
                        [
                            -99.7466859996717,
                            34.4173950001939
                        ],
                        [
                            -99.7431199997091,
                            34.417281999896
                        ],
                        [
                            -99.7409070000067,
                            34.4147630003165
                        ],
                        [
                            -99.7270309995957,
                            34.4105539996491
                        ],
                        [
                            -99.720259000419,
                            34.4062950000159
                        ],
                        [
                            -99.7150890001262,
                            34.4007540002065
                        ],
                        [
                            -99.714838000056,
                            34.3945240000251
                        ],
                        [
                            -99.712682000255,
                            34.3909280003035
                        ],
                        [
                            -99.7066580003676,
                            34.3859810000565
                        ],
                        [
                            -99.7004140003722,
                            34.384698999735
                        ],
                        [
                            -99.694970000443,
                            34.3783330003573
                        ],
                        [
                            -99.6782829999298,
                            34.379798999635
                        ],
                        [
                            -99.6627050001775,
                            34.3736800001574
                        ],
                        [
                            -99.654194000053,
                            34.3765190003026
                        ],
                        [
                            -99.6465109995838,
                            34.3825410003219
                        ],
                        [
                            -99.6338589995615,
                            34.3787570000401
                        ],
                        [
                            -99.6309050002424,
                            34.3760070000382
                        ],
                        [
                            -99.6241969997415,
                            34.3735770000198
                        ],
                        [
                            -99.6072630002544,
                            34.3769279999377
                        ],
                        [
                            -99.600025999849,
                            34.3746879998645
                        ],
                        [
                            -99.5854420003317,
                            34.3889140002233
                        ],
                        [
                            -99.5853059998892,
                            34.3981220000021
                        ],
                        [
                            -99.583483999999,
                            34.4037350003118
                        ],
                        [
                            -99.5844799998838,
                            34.4076729998264
                        ],
                        [
                            -99.5800600003267,
                            34.4166530000317
                        ],
                        [
                            -99.5696960003499,
                            34.4184179999813
                        ],
                        [
                            -99.5492420000327,
                            34.412715000107
                        ],
                        [
                            -99.5415309999744,
                            34.4132409996955
                        ],
                        [
                            -99.5297860001483,
                            34.4114520000453
                        ],
                        [
                            -99.5188949999168,
                            34.4142889996855
                        ],
                        [
                            -99.5142800003661,
                            34.4140349996645
                        ],
                        [
                            -99.5069030002186,
                            34.4095200001954
                        ],
                        [
                            -99.4989989998164,
                            34.4091729997672
                        ],
                        [
                            -99.4872189998303,
                            34.3979550002679
                        ],
                        [
                            -99.4737890003814,
                            34.3953499996501
                        ],
                        [
                            -99.4703060002596,
                            34.3967479997632
                        ],
                        [
                            -99.4663829999216,
                            34.3959000003443
                        ],
                        [
                            -99.4624620001319,
                            34.3911370000263
                        ],
                        [
                            -99.4610529997952,
                            34.3806659999752
                        ],
                        [
                            -99.4550849999844,
                            34.3812639998197
                        ],
                        [
                            -99.4554719995987,
                            34.3860969998202
                        ],
                        [
                            -99.4537869999758,
                            34.3886630003503
                        ],
                        [
                            -99.4382689995997,
                            34.3711640003082
                        ],
                        [
                            -99.4310429999633,
                            34.3711170001596
                        ],
                        [
                            -99.4311180003063,
                            34.3740029997241
                        ],
                        [
                            -99.4293240000053,
                            34.3758340003085
                        ],
                        [
                            -99.4300100002686,
                            34.3810189999845
                        ],
                        [
                            -99.4274970000922,
                            34.385136000008
                        ],
                        [
                            -99.4248969998772,
                            34.3858229996595
                        ],
                        [
                            -99.421908000398,
                            34.3842010002449
                        ],
                        [
                            -99.4207140001466,
                            34.3803859998
                        ],
                        [
                            -99.4141589998065,
                            34.3777729997804
                        ],
                        [
                            -99.4101950003591,
                            34.3734020000356
                        ],
                        [
                            -99.4052860001821,
                            34.3728579996563
                        ],
                        [
                            -99.4003340003475,
                            34.3746230003515
                        ],
                        [
                            -99.3973550000158,
                            34.3778680001553
                        ],
                        [
                            -99.3956760002407,
                            34.3976899996815
                        ],
                        [
                            -99.3871810000098,
                            34.4108420000522
                        ],
                        [
                            -99.3971640002201,
                            34.4186069998393
                        ],
                        [
                            -99.3972670001522,
                            34.4239240001291
                        ],
                        [
                            -99.3933060001795,
                            34.4276130002627
                        ],
                        [
                            -99.3930490002615,
                            34.4319210003467
                        ],
                        [
                            -99.3961149997336,
                            34.4349700002708
                        ],
                        [
                            -99.3951869996209,
                            34.4420300003194
                        ],
                        [
                            -99.3892270002059,
                            34.4468630000896
                        ],
                        [
                            -99.3812039999781,
                            34.4568790000176
                        ],
                        [
                            -99.3755499999865,
                            34.4587890000892
                        ],
                        [
                            -99.3689319998973,
                            34.4584610000205
                        ],
                        [
                            -99.3589699996034,
                            34.4558069999611
                        ],
                        [
                            -99.3557049999398,
                            34.4530819999436
                        ],
                        [
                            -99.354849999622,
                            34.4505389999127
                        ],
                        [
                            -99.3569560000926,
                            34.4464809999834
                        ],
                        [
                            -99.3569030003892,
                            34.4420819998327
                        ],
                        [
                            -99.3401299996622,
                            34.4301020001089
                        ],
                        [
                            -99.3340899998813,
                            34.4275249999654
                        ],
                        [
                            -99.3286979998307,
                            34.4223829999248
                        ],
                        [
                            -99.3196019997252,
                            34.4088790000719
                        ],
                        [
                            -99.3092570000149,
                            34.4097039997744
                        ],
                        [
                            -99.2946319995914,
                            34.4153809997875
                        ],
                        [
                            -99.2855859997146,
                            34.4123579998966
                        ],
                        [
                            -99.2828430002827,
                            34.4074799997526
                        ],
                        [
                            -99.2831699996226,
                            34.4029459997076
                        ],
                        [
                            -99.280044999701,
                            34.4006550003091
                        ],
                        [
                            -99.2723029996805,
                            34.4008120002492
                        ],
                        [
                            -99.2663080001055,
                            34.4054809996538
                        ],
                        [
                            -99.26127500008,
                            34.4035079998585
                        ],
                        [
                            -99.2618220004262,
                            34.3950359997354
                        ],
                        [
                            -99.2589169997143,
                            34.3912550000579
                        ],
                        [
                            -99.2644419998344,
                            34.388098999766
                        ],
                        [
                            -99.273893999592,
                            34.3875770001847
                        ],
                        [
                            -99.2752750003395,
                            34.3866160003101
                        ],
                        [
                            -99.2744149999989,
                            34.3843489999174
                        ],
                        [
                            -99.2697960002502,
                            34.3808429998572
                        ],
                        [
                            -99.2597670000092,
                            34.3775999999916
                        ],
                        [
                            -99.258606000268,
                            34.3726499997553
                        ],
                        [
                            -99.2546310000515,
                            34.3724190001082
                        ],
                        [
                            -99.2488840001736,
                            34.3759950000813
                        ],
                        [
                            -99.2428550002633,
                            34.3726760003272
                        ],
                        [
                            -99.2435430001765,
                            34.3707110003179
                        ],
                        [
                            -99.2479419998172,
                            34.3684869999729
                        ],
                        [
                            -99.2463929997385,
                            34.3643969998874
                        ],
                        [
                            -99.2444539996725,
                            34.3637010002237
                        ],
                        [
                            -99.2375619999469,
                            34.3667010002799
                        ],
                        [
                            -99.2364940000921,
                            34.3658050000758
                        ],
                        [
                            -99.2371409999974,
                            34.3610949998221
                        ],
                        [
                            -99.2341349999015,
                            34.353464000326
                        ],
                        [
                            -99.2279770001198,
                            34.349464000176
                        ],
                        [
                            -99.234046000213,
                            34.3421729997606
                        ],
                        [
                            -99.2326319998535,
                            34.3388720000701
                        ],
                        [
                            -99.2271169997792,
                            34.3377670002183
                        ],
                        [
                            -99.2172030000642,
                            34.3415080000994
                        ],
                        [
                            -99.2069050002096,
                            34.3382769999618
                        ],
                        [
                            -99.2108200001516,
                            34.3368259997411
                        ],
                        [
                            -99.2095740000216,
                            34.3249250003101
                        ],
                        [
                            -99.2056280001175,
                            34.3192009999126
                        ],
                        [
                            -99.2133020003659,
                            34.3106729998199
                        ],
                        [
                            -99.2096950003954,
                            34.2989039997894
                        ],
                        [
                            -99.2114479997903,
                            34.2922469999891
                        ],
                        [
                            -99.2073520000984,
                            34.2835250001993
                        ],
                        [
                            -99.1954030000578,
                            34.2808489998439
                        ],
                        [
                            -99.1944590000516,
                            34.2718810002213
                        ],
                        [
                            -99.1992540004257,
                            34.260791000242
                        ],
                        [
                            -99.1938380000856,
                            34.2603070002757
                        ],
                        [
                            -99.1914219999936,
                            34.2578300001729
                        ],
                        [
                            -99.1961989999261,
                            34.2493850000217
                        ],
                        [
                            -99.1969119999536,
                            34.2443819999788
                        ],
                        [
                            -99.1899859998928,
                            34.2297560001574
                        ],
                        [
                            -99.1928110002387,
                            34.2188879998677
                        ],
                        [
                            -99.1897759998305,
                            34.2143570003065
                        ],
                        [
                            -99.1536530004227,
                            34.2073280002805
                        ],
                        [
                            -99.1487209997813,
                            34.2087699999172
                        ],
                        [
                            -99.138075999597,
                            34.2192329999717
                        ],
                        [
                            -99.1304430002548,
                            34.2194779997582
                        ],
                        [
                            -99.1273939996029,
                            34.218054999727
                        ],
                        [
                            -99.1264949998026,
                            34.2153960002539
                        ],
                        [
                            -99.1300120002596,
                            34.2122629999091
                        ],
                        [
                            -99.1319859995874,
                            34.2074359999261
                        ],
                        [
                            -99.1266399995675,
                            34.2030639998277
                        ],
                        [
                            -99.1192540000975,
                            34.2018090002258
                        ],
                        [
                            -99.1118069996298,
                            34.2059800000496
                        ],
                        [
                            -99.1084170002927,
                            34.2052710000999
                        ],
                        [
                            -99.111003000264,
                            34.2045999999611
                        ],
                        [
                            -99.1082010003826,
                            34.2035469997883
                        ],
                        [
                            -99.1021859998178,
                            34.2054830001273
                        ],
                        [
                            -99.0962110003342,
                            34.2117050001415
                        ],
                        [
                            -99.0889229998752,
                            34.2084749997722
                        ],
                        [
                            -99.0793919995765,
                            34.2115499996741
                        ],
                        [
                            -99.0631049999947,
                            34.2068510002522
                        ],
                        [
                            -99.0579759997097,
                            34.2006099999508
                        ],
                        [
                            -99.0444630004202,
                            34.1981060000797
                        ],
                        [
                            -99.0361499997641,
                            34.2069010001544
                        ],
                        [
                            -99.0244650002125,
                            34.2039839998446
                        ],
                        [
                            -99.0172710003632,
                            34.206313999789
                        ],
                        [
                            -99.012957000213,
                            34.2031940000031
                        ],
                        [
                            -99.0022419997086,
                            34.2093739999669
                        ],
                        [
                            -99.0046949996105,
                            34.2141450003151
                        ],
                        [
                            -99.0005920002458,
                            34.2176720001363
                        ],
                        [
                            -98.9917029998296,
                            34.2214269999036
                        ],
                        [
                            -98.9881720000271,
                            34.2213099999604
                        ],
                        [
                            -98.9809170000784,
                            34.2170590000229
                        ],
                        [
                            -98.9764699998588,
                            34.206197999638
                        ],
                        [
                            -98.9726090003435,
                            34.2025910002636
                        ],
                        [
                            -98.9666259995657,
                            34.2011010001503
                        ],
                        [
                            -98.9629309997317,
                            34.2038330000541
                        ],
                        [
                            -98.9621890002902,
                            34.2111770001464
                        ],
                        [
                            -98.9599840000853,
                            34.2132719999366
                        ],
                        [
                            -98.951283999815,
                            34.2121890002643
                        ],
                        [
                            -98.9281449997922,
                            34.1926890002539
                        ],
                        [
                            -98.9183330001844,
                            34.1818309996299
                        ],
                        [
                            -98.9093490002319,
                            34.177498999967
                        ],
                        [
                            -98.8966160000188,
                            34.1744949998366
                        ],
                        [
                            -98.8871120003826,
                            34.1682600000276
                        ],
                        [
                            -98.8729220001522,
                            34.1665839996371
                        ],
                        [
                            -98.8712109996918,
                            34.1630119999824
                        ],
                        [
                            -98.8748720000889,
                            34.1556569999671
                        ],
                        [
                            -98.8681159999074,
                            34.1496350002579
                        ],
                        [
                            -98.860125000365,
                            34.1499129997393
                        ],
                        [
                            -98.8584189999274,
                            34.1527320000037
                        ],
                        [
                            -98.8579000000686,
                            34.1596270002706
                        ],
                        [
                            -98.855585000259,
                            34.1616209996418
                        ],
                        [
                            -98.8370659998098,
                            34.1564950001644
                        ],
                        [
                            -98.8311149997174,
                            34.1621540002298
                        ],
                        [
                            -98.8129540003668,
                            34.1584440000341
                        ],
                        [
                            -98.8044130001051,
                            34.1539309998491
                        ],
                        [
                            -98.7962030002795,
                            34.1427750000445
                        ],
                        [
                            -98.7920149996277,
                            34.1437359999581
                        ],
                        [
                            -98.7884050001825,
                            34.1466680002613
                        ],
                        [
                            -98.7780760003658,
                            34.1394949999193
                        ],
                        [
                            -98.7655699999334,
                            34.1363759998342
                        ],
                        [
                            -98.7605579998243,
                            34.1323880000519
                        ],
                        [
                            -98.7596530001761,
                            34.1269119999311
                        ],
                        [
                            -98.7570370000676,
                            34.1246330001373
                        ],
                        [
                            -98.7419659995802,
                            34.1255300002104
                        ],
                        [
                            -98.7342870002072,
                            34.1357579996312
                        ],
                        [
                            -98.7236260001295,
                            34.13518900006
                        ],
                        [
                            -98.7175369999448,
                            34.136449999813
                        ],
                        [
                            -98.7124670001094,
                            34.1311600002428
                        ],
                        [
                            -98.7030470001388,
                            34.1359029996647
                        ],
                        [
                            -98.6900720000763,
                            34.133155000304
                        ],
                        [
                            -98.684120000159,
                            34.1387899997226
                        ],
                        [
                            -98.6766259998356,
                            34.1429130000521
                        ],
                        [
                            -98.6709219996154,
                            34.1493729998558
                        ],
                        [
                            -98.6480730000208,
                            34.1644409997903
                        ],
                        [
                            -98.6432230002935,
                            34.1645309998096
                        ],
                        [
                            -98.6167330003933,
                            34.1564179996886
                        ],
                        [
                            -98.6118290002392,
                            34.1565580000959
                        ],
                        [
                            -98.6039780004388,
                            34.1602489996683
                        ],
                        [
                            -98.5997889999621,
                            34.1605710003673
                        ],
                        [
                            -98.5839140001089,
                            34.1512980003574
                        ],
                        [
                            -98.5771360001862,
                            34.1489620000677
                        ],
                        [
                            -98.5666090000027,
                            34.1374400001899
                        ],
                        [
                            -98.5601909999301,
                            34.133201999718
                        ],
                        [
                            -98.5508799997394,
                            34.1325829999945
                        ],
                        [
                            -98.5503569996827,
                            34.1292390002849
                        ],
                        [
                            -98.551844999662,
                            34.1271310002259
                        ],
                        [
                            -98.5585930003459,
                            34.1282539998089
                        ],
                        [
                            -98.5609769997525,
                            34.1239219996554
                        ],
                        [
                            -98.5557470000836,
                            34.120133999993
                        ],
                        [
                            -98.5509170004477,
                            34.1193340001285
                        ],
                        [
                            -98.536256999864,
                            34.107346999999
                        ],
                        [
                            -98.5282000001993,
                            34.0949609996453
                        ],
                        [
                            -98.5168749995995,
                            34.0859840002783
                        ],
                        [
                            -98.5041489999573,
                            34.0722869996445
                        ],
                        [
                            -98.4946129996358,
                            34.0700900003009
                        ],
                        [
                            -98.4863280003654,
                            34.0625979999507
                        ],
                        [
                            -98.4807129998337,
                            34.0624989998377
                        ],
                        [
                            -98.4714650002905,
                            34.0656650001101
                        ],
                        [
                            -98.4651330004316,
                            34.0737289997464
                        ],
                        [
                            -98.4490340002724,
                            34.0734619997077
                        ],
                        [
                            -98.4463789998058,
                            34.075430000165
                        ],
                        [
                            -98.4437240002375,
                            34.0821520000554
                        ],
                        [
                            -98.4400920001527,
                            34.0843110001243
                        ],
                        [
                            -98.428480000396,
                            34.0855229999802
                        ],
                        [
                            -98.4204079997644,
                            34.0823139997346
                        ],
                        [
                            -98.4144259997098,
                            34.0850739999708
                        ],
                        [
                            -98.4097230002955,
                            34.0925090000224
                        ],
                        [
                            -98.4113739995832,
                            34.1003240001011
                        ],
                        [
                            -98.4077270003281,
                            34.1013690003218
                        ],
                        [
                            -98.4021579998271,
                            34.0986530001924
                        ],
                        [
                            -98.3997769998952,
                            34.0999730003136
                        ],
                        [
                            -98.3983890003732,
                            34.1045659997461
                        ],
                        [
                            -98.3981600000444,
                            34.1213959999856
                        ],
                        [
                            -98.4009669999487,
                            34.1222359998724
                        ],
                        [
                            -98.3984410002518,
                            34.1284559999572
                        ],
                        [
                            -98.394031999667,
                            34.1319129996439
                        ],
                        [
                            -98.3894029998726,
                            34.1332169996994
                        ],
                        [
                            -98.3829589998607,
                            34.1295490000154
                        ],
                        [
                            -98.3800930004052,
                            34.1308310003095
                        ],
                        [
                            -98.3804209995699,
                            34.1342899996977
                        ],
                        [
                            -98.3868649995818,
                            34.1388730000965
                        ],
                        [
                            -98.3843809997178,
                            34.1463170002563
                        ],
                        [
                            -98.3812380002528,
                            34.1494539996867
                        ],
                        [
                            -98.3709360002003,
                            34.1518600001028
                        ],
                        [
                            -98.3656210001425,
                            34.1569930001459
                        ],
                        [
                            -98.3254449998021,
                            34.1510249996661
                        ],
                        [
                            -98.3002090004279,
                            34.1345790000102
                        ],
                        [
                            -98.2939009999601,
                            34.1330200002758
                        ],
                        [
                            -98.2803209998251,
                            34.1307499997272
                        ],
                        [
                            -98.2647289998291,
                            34.1319129996439
                        ],
                        [
                            -98.256467000125,
                            34.1294810000519
                        ],
                        [
                            -98.2479540003506,
                            34.1307169999909
                        ],
                        [
                            -98.2391410001024,
                            34.1345470003639
                        ],
                        [
                            -98.2324739996094,
                            34.1346409998482
                        ],
                        [
                            -98.2255579995943,
                            34.129282999956
                        ],
                        [
                            -98.22360000016,
                            34.1250929998763
                        ],
                        [
                            -98.2164630002121,
                            34.1218210002763
                        ],
                        [
                            -98.209219000134,
                            34.1209970001465
                        ],
                        [
                            -98.2017280001837,
                            34.1170579999751
                        ],
                        [
                            -98.1708270000489,
                            34.1141709999307
                        ],
                        [
                            -98.1543539997959,
                            34.1227340002687
                        ],
                        [
                            -98.130815999565,
                            34.1505319996405
                        ],
                        [
                            -98.1233770003916,
                            34.154540000098
                        ],
                        [
                            -98.1094619996225,
                            34.1541109998965
                        ],
                        [
                            -98.1031780003425,
                            34.148318000352
                        ],
                        [
                            -98.1005589998608,
                            34.1434860003068
                        ],
                        [
                            -98.1028949995869,
                            34.132605000078
                        ],
                        [
                            -98.1004979997615,
                            34.1313690002336
                        ],
                        [
                            -98.0923730003247,
                            34.1321500001263
                        ],
                        [
                            -98.089754999668,
                            34.1282109997592
                        ],
                        [
                            -98.0924210000052,
                            34.1169170000148
                        ],
                        [
                            -98.0993279997995,
                            34.1042949999741
                        ],
                        [
                            -98.1194170002437,
                            34.0844740000674
                        ],
                        [
                            -98.1210390001173,
                            34.0812660002128
                        ],
                        [
                            -98.120208000089,
                            34.0721270002845
                        ],
                        [
                            -98.1145869997095,
                            34.0622799996953
                        ],
                        [
                            -98.1018929998544,
                            34.0509689997341
                        ],
                        [
                            -98.0874719996453,
                            34.0440999998769
                        ],
                        [
                            -98.0824629999093,
                            34.0394279999428
                        ],
                        [
                            -98.0838100003216,
                            34.0366560001231
                        ],
                        [
                            -98.0890369996176,
                            34.0338380000944
                        ],
                        [
                            -98.0917880003437,
                            34.0336540003023
                        ],
                        [
                            -98.0965500002075,
                            34.0380509996553
                        ],
                        [
                            -98.101695000386,
                            34.0390569997344
                        ],
                        [
                            -98.105025000347,
                            34.0375449996446
                        ],
                        [
                            -98.1061790004155,
                            34.0348870001019
                        ],
                        [
                            -98.1036169998353,
                            34.0292070000419
                        ],
                        [
                            -98.0984420004179,
                            34.0239180000557
                        ],
                        [
                            -98.0895549996514,
                            34.0192699999206
                        ],
                        [
                            -98.0840250004068,
                            34.0143690000469
                        ],
                        [
                            -98.082400999985,
                            34.0098339998834
                        ],
                        [
                            -98.0881489996879,
                            34.0079320002058
                        ],
                        [
                            -98.0882030001145,
                            34.0054810002413
                        ],
                        [
                            -98.0828389996529,
                            34.0024119999825
                        ],
                        [
                            -98.0551970002324,
                            33.9958409998642
                        ],
                        [
                            -98.0411169996069,
                            33.9934560002841
                        ],
                        [
                            -98.0276720000895,
                            33.9933569999083
                        ],
                        [
                            -98.015101000258,
                            33.994136000147
                        ],
                        [
                            -97.9908609998702,
                            33.9988730001986
                        ],
                        [
                            -97.9741730004304,
                            34.0067159999906
                        ],
                        [
                            -97.9583250003414,
                            33.9908459999517
                        ],
                        [
                            -97.9475720002021,
                            33.9910530000876
                        ],
                        [
                            -97.9457300002205,
                            33.9898390001569
                        ],
                        [
                            -97.960351000446,
                            33.9519280001622
                        ],
                        [
                            -97.9741730004304,
                            33.9428319998332
                        ],
                        [
                            -97.9740620001023,
                            33.9402889997646
                        ],
                        [
                            -97.9711749998321,
                            33.9371289997619
                        ],
                        [
                            -97.963425000314,
                            33.9362370002651
                        ],
                        [
                            -97.9555109998661,
                            33.9381859999804
                        ],
                        [
                            -97.953395000248,
                            33.9364450001143
                        ],
                        [
                            -97.9526789998474,
                            33.9294819997286
                        ],
                        [
                            -97.9571550003793,
                            33.9144539999508
                        ],
                        [
                            -97.9616319998378,
                            33.9094139997201
                        ],
                        [
                            -97.9687470000445,
                            33.9060229998678
                        ],
                        [
                            -97.9731430002104,
                            33.9080139999448
                        ],
                        [
                            -97.9769629997179,
                            33.9125490002348
                        ],
                        [
                            -97.9788039998746,
                            33.9125479997698
                        ],
                        [
                            -97.9845399998819,
                            33.9007029997479
                        ],
                        [
                            -97.9843730003756,
                            33.8980239999515
                        ],
                        [
                            -97.9778599998684,
                            33.8898249998418
                        ],
                        [
                            -97.9677519999846,
                            33.8822139998288
                        ],
                        [
                            -97.9570640001427,
                            33.8787669999412
                        ],
                        [
                            -97.9389480000997,
                            33.8794660003065
                        ],
                        [
                            -97.9341689996191,
                            33.8781089996607
                        ],
                        [
                            -97.9285840001229,
                            33.8741870002534
                        ],
                        [
                            -97.9098579999845,
                            33.8672210003054
                        ],
                        [
                            -97.8967380001571,
                            33.8579850001505
                        ],
                        [
                            -97.888720999777,
                            33.8567230001717
                        ],
                        [
                            -97.8753110004196,
                            33.8497210002567
                        ],
                        [
                            -97.8641749999658,
                            33.8497450000035
                        ],
                        [
                            -97.843776999725,
                            33.8568130002025
                        ],
                        [
                            -97.8343199999445,
                            33.8576340002079
                        ],
                        [
                            -97.8311320002737,
                            33.8617410003346
                        ],
                        [
                            -97.8207430001825,
                            33.8688950003361
                        ],
                        [
                            -97.8189310003381,
                            33.8740030002372
                        ],
                        [
                            -97.8192359999366,
                            33.8790910002303
                        ],
                        [
                            -97.8176430003753,
                            33.8809919998865
                        ],
                        [
                            -97.8138530001068,
                            33.8819309997107
                        ],
                        [
                            -97.8089380000821,
                            33.8767089997803
                        ],
                        [
                            -97.8054230001731,
                            33.8771669998855
                        ],
                        [
                            -97.801577999653,
                            33.8851380002613
                        ],
                        [
                            -97.8016300004299,
                            33.8958799996794
                        ],
                        [
                            -97.7989099996659,
                            33.8985370000507
                        ],
                        [
                            -97.7947899996845,
                            33.8999560000908
                        ],
                        [
                            -97.79240000043,
                            33.8990850002357
                        ],
                        [
                            -97.7881449998311,
                            33.8920759996865
                        ],
                        [
                            -97.7853170000104,
                            33.8907010001972
                        ],
                        [
                            -97.7808389998287,
                            33.8944469998765
                        ],
                        [
                            -97.7803400000614,
                            33.9048330001861
                        ],
                        [
                            -97.7861329999701,
                            33.9134240000308
                        ],
                        [
                            -97.7835219998844,
                            33.9183020000502
                        ],
                        [
                            -97.7783010004363,
                            33.9196289997779
                        ],
                        [
                            -97.7726719996608,
                            33.9143820001078
                        ],
                        [
                            -97.7637699997241,
                            33.9142410000836
                        ],
                        [
                            -97.7593989996725,
                            33.9188200000965
                        ],
                        [
                            -97.7627679999916,
                            33.934396000132
                        ],
                        [
                            -97.7529499996377,
                            33.9371020002134
                        ],
                        [
                            -97.7322610000421,
                            33.9365190003107
                        ],
                        [
                            -97.7206040000796,
                            33.9439549997098
                        ],
                        [
                            -97.7121940002375,
                            33.9516409997143
                        ],
                        [
                            -97.7041590003141,
                            33.9633359997615
                        ],
                        [
                            -97.6991849998398,
                            33.9757049996816
                        ],
                        [
                            -97.6931099998988,
                            33.9836990002463
                        ],
                        [
                            -97.6860159996086,
                            33.9878900000384
                        ],
                        [
                            -97.6712770002805,
                            33.9915530000018
                        ],
                        [
                            -97.6562099999911,
                            33.9894880000872
                        ],
                        [
                            -97.6572550002796,
                            33.9888240000727
                        ],
                        [
                            -97.6337780001481,
                            33.981256999709
                        ],
                        [
                            -97.6160749996586,
                            33.9697159996708
                        ],
                        [
                            -97.6090909998715,
                            33.9680929999003
                        ],
                        [
                            -97.6009789999552,
                            33.9607890002982
                        ],
                        [
                            -97.5979800004303,
                            33.9552470002671
                        ],
                        [
                            -97.5895970003523,
                            33.9535539998222
                        ],
                        [
                            -97.5888270004234,
                            33.951882000036
                        ],
                        [
                            -97.5954200003982,
                            33.9414359996919
                        ],
                        [
                            -97.5950339997106,
                            33.9373599999381
                        ],
                        [
                            -97.591211999655,
                            33.929642999943
                        ],
                        [
                            -97.5969789996244,
                            33.9202279997932
                        ],
                        [
                            -97.5969500002104,
                            33.9163110002621
                        ],
                        [
                            -97.593816000068,
                            33.9102649997733
                        ],
                        [
                            -97.5899139996464,
                            33.9080439998296
                        ],
                        [
                            -97.5892540002207,
                            33.9039219999262
                        ],
                        [
                            -97.5810779998319,
                            33.8996789998982
                        ],
                        [
                            -97.5723490001475,
                            33.8992629998483
                        ],
                        [
                            -97.5669560002719,
                            33.9015830003482
                        ],
                        [
                            -97.5627879997116,
                            33.9009250000883
                        ],
                        [
                            -97.5582700002453,
                            33.897098999909
                        ],
                        [
                            -97.551540999828,
                            33.8979470001978
                        ],
                        [
                            -97.5342070003078,
                            33.9057300002295
                        ],
                        [
                            -97.5252769998836,
                            33.9117509996855
                        ],
                        [
                            -97.5002709997648,
                            33.9196349998326
                        ],
                        [
                            -97.4877159998269,
                            33.9175079998356
                        ],
                        [
                            -97.463564000201,
                            33.9049550000292
                        ],
                        [
                            -97.4614200000955,
                            33.9052299998726
                        ],
                        [
                            -97.4510579997685,
                            33.8916760000063
                        ],
                        [
                            -97.4508069996983,
                            33.8866080000012
                        ],
                        [
                            -97.4514689996722,
                            33.8709299999232
                        ],
                        [
                            -97.4539679996049,
                            33.8666929997234
                        ],
                        [
                            -97.4559150000669,
                            33.8576460000895
                        ],
                        [
                            -97.4614860002177,
                            33.8495599998222
                        ],
                        [
                            -97.4632419999857,
                            33.8420039998033
                        ],
                        [
                            -97.4590679995776,
                            33.8345810001684
                        ],
                        [
                            -97.453057000109,
                            33.8285359996422
                        ],
                        [
                            -97.4441929998071,
                            33.823772999933
                        ],
                        [
                            -97.4267990000125,
                            33.8186409996871
                        ],
                        [
                            -97.4141550003861,
                            33.8179769998754
                        ],
                        [
                            -97.4136919997055,
                            33.8193040002488
                        ],
                        [
                            -97.3951289997737,
                            33.819962999917
                        ],
                        [
                            -97.3746900004234,
                            33.8185519998261
                        ],
                        [
                            -97.368730999935,
                            33.82144299971
                        ],
                        [
                            -97.3585130004464,
                            33.830018000183
                        ],
                        [
                            -97.3543180001219,
                            33.8379649998658
                        ],
                        [
                            -97.347212999961,
                            33.8451130000438
                        ],
                        [
                            -97.3396409998198,
                            33.8607340001029
                        ],
                        [
                            -97.3359150000237,
                            33.8791679998534
                        ],
                        [
                            -97.3318800004311,
                            33.8844830000371
                        ],
                        [
                            -97.326608000031,
                            33.8844139996399
                        ],
                        [
                            -97.3233399999943,
                            33.8791700000751
                        ],
                        [
                            -97.3280569996523,
                            33.8702629999353
                        ],
                        [
                            -97.334177999624,
                            33.8628879997066
                        ],
                        [
                            -97.3346160001903,
                            33.8572309998485
                        ],
                        [
                            -97.3320629998309,
                            33.8550559999844
                        ],
                        [
                            -97.3260980003931,
                            33.8570829997259
                        ],
                        [
                            -97.3163889998192,
                            33.8657749997269
                        ],
                        [
                            -97.3142570003076,
                            33.8650889997223
                        ],
                        [
                            -97.3099459996322,
                            33.8744490001919
                        ],
                        [
                            -97.309441999842,
                            33.8801469998706
                        ],
                        [
                            -97.3111919997622,
                            33.8870890003381
                        ],
                        [
                            -97.3091720004036,
                            33.888698000015
                        ],
                        [
                            -97.2853930001482,
                            33.8682440002053
                        ],
                        [
                            -97.2728690001724,
                            33.8616740000843
                        ],
                        [
                            -97.2709329995811,
                            33.8580009999265
                        ],
                        [
                            -97.265086999969,
                            33.8624129998152
                        ],
                        [
                            -97.2556390004093,
                            33.8637019996828
                        ],
                        [
                            -97.2492089997427,
                            33.8751010001459
                        ],
                        [
                            -97.2450489995784,
                            33.9032159998468
                        ],
                        [
                            -97.2415360002176,
                            33.9069109997574
                        ],
                        [
                            -97.2315870003425,
                            33.9102229999822
                        ],
                        [
                            -97.2284620004209,
                            33.9132540000919
                        ],
                        [
                            -97.2109210003131,
                            33.9160640003281
                        ],
                        [
                            -97.1865839998409,
                            33.9015479996928
                        ],
                        [
                            -97.1808450003589,
                            33.8952040000476
                        ],
                        [
                            -97.1764259997284,
                            33.8790809998577
                        ],
                        [
                            -97.1769749997244,
                            33.8766989998743
                        ],
                        [
                            -97.1666290001893,
                            33.8473110001128
                        ],
                        [
                            -97.166824000183,
                            33.8403950003508
                        ],
                        [
                            -97.1716270000547,
                            33.835334999663
                        ],
                        [
                            -97.1813700000655,
                            33.8313750002369
                        ],
                        [
                            -97.1958320002824,
                            33.8308050003497
                        ],
                        [
                            -97.1998009997527,
                            33.8274049997378
                        ],
                        [
                            -97.204995000335,
                            33.8188699997676
                        ],
                        [
                            -97.2054310003531,
                            33.8014879998328
                        ],
                        [
                            -97.1947860001689,
                            33.7853439996399
                        ],
                        [
                            -97.1903969996757,
                            33.7811529997299
                        ],
                        [
                            -97.1893830002476,
                            33.7761380002068
                        ],
                        [
                            -97.1908090003026,
                            33.7764360000798
                        ],
                        [
                            -97.1877239996657,
                            33.769830999967
                        ],
                        [
                            -97.1928510003008,
                            33.7603620002251
                        ],
                        [
                            -97.1914539996598,
                            33.757966000302
                        ],
                        [
                            -97.180143000202,
                            33.7544949996703
                        ],
                        [
                            -97.1746299997775,
                            33.7450350000043
                        ],
                        [
                            -97.1732869995631,
                            33.7389879997409
                        ],
                        [
                            -97.1631450002425,
                            33.7293289996312
                        ],
                        [
                            -97.1543670001543,
                            33.7240940002821
                        ],
                        [
                            -97.1339150003852,
                            33.7181279999709
                        ],
                        [
                            -97.1206850000546,
                            33.7172109998799
                        ],
                        [
                            -97.1071910001333,
                            33.7211620002004
                        ],
                        [
                            -97.0967479996155,
                            33.7281060001007
                        ],
                        [
                            -97.0873650003531,
                            33.7411389999763
                        ],
                        [
                            -97.0849440002382,
                            33.7523279996681
                        ],
                        [
                            -97.084801999948,
                            33.7615999998218
                        ],
                        [
                            -97.0865679997617,
                            33.7706949999753
                        ],
                        [
                            -97.0950980001528,
                            33.7921720001205
                        ],
                        [
                            -97.0952379998948,
                            33.7984039997607
                        ],
                        [
                            -97.0926460000757,
                            33.8041979997897
                        ],
                        [
                            -97.0791050002988,
                            33.8123170003531
                        ],
                        [
                            -97.0481460004378,
                            33.817456000041
                        ],
                        [
                            -97.0483149995938,
                            33.8278699998667
                        ],
                        [
                            -97.0519490002268,
                            33.8329930000127
                        ],
                        [
                            -97.0622820002414,
                            33.8379899997773
                        ],
                        [
                            -97.0872039997963,
                            33.8413679999572
                        ],
                        [
                            -97.0905379999553,
                            33.8452149997598
                        ],
                        [
                            -97.0884979996069,
                            33.8518490000964
                        ],
                        [
                            -97.0827080000713,
                            33.8560699997185
                        ],
                        [
                            -97.0670399999073,
                            33.8605140002637
                        ],
                        [
                            -97.0565460002342,
                            33.8564759999727
                        ],
                        [
                            -97.0461260001809,
                            33.8395599997164
                        ],
                        [
                            -97.0418320001222,
                            33.8377560001701
                        ],
                        [
                            -97.0214380000794,
                            33.8463789997009
                        ],
                        [
                            -97.0098559995617,
                            33.8597619998424
                        ],
                        [
                            -96.9970880000867,
                            33.8710170000238
                        ],
                        [
                            -96.9838350001899,
                            33.8909489997738
                        ],
                        [
                            -96.9853259996439,
                            33.9066070002723
                        ],
                        [
                            -96.9878749998054,
                            33.9170460000049
                        ],
                        [
                            -96.995022999624,
                            33.9320349999645
                        ],
                        [
                            -96.9962510002106,
                            33.9426639999282
                        ],
                        [
                            -96.9946739996445,
                            33.9486810002421
                        ],
                        [
                            -96.9908349998705,
                            33.9527010000514
                        ],
                        [
                            -96.9813370000821,
                            33.9563779998648
                        ],
                        [
                            -96.979290000061,
                            33.955968999887
                        ],
                        [
                            -96.9810309997603,
                            33.9491599997239
                        ],
                        [
                            -96.9798180001406,
                            33.9415880000384
                        ],
                        [
                            -96.9769550001598,
                            33.9374529996385
                        ],
                        [
                            -96.9728700003385,
                            33.9356979999686
                        ],
                        [
                            -96.9517819996365,
                            33.9447439996306
                        ],
                        [
                            -96.9242680002625,
                            33.9591589998953
                        ],
                        [
                            -96.9163000002862,
                            33.9577979999719
                        ],
                        [
                            -96.9073869995805,
                            33.9500250002639
                        ],
                        [
                            -96.9009960001703,
                            33.9387839997671
                        ],
                        [
                            -96.8964689995848,
                            33.9133180002227
                        ],
                        [
                            -96.8971940002062,
                            33.902954000271
                        ],
                        [
                            -96.8957279999682,
                            33.8964140003233
                        ],
                        [
                            -96.8830099998237,
                            33.8680189998615
                        ],
                        [
                            -96.875281000222,
                            33.8605050002819
                        ],
                        [
                            -96.8602470004428,
                            33.8496889998376
                        ],
                        [
                            -96.8560899997532,
                            33.8474900000343
                        ],
                        [
                            -96.8505930001205,
                            33.8472110001393
                        ],
                        [
                            -96.8458959996557,
                            33.848975000101
                        ],
                        [
                            -96.8415919995513,
                            33.8528940001595
                        ],
                        [
                            -96.839774999684,
                            33.8683980003076
                        ],
                        [
                            -96.8321570004103,
                            33.8748350000707
                        ],
                        [
                            -96.7942759997882,
                            33.868885999746
                        ],
                        [
                            -96.7834850000141,
                            33.8635339996745
                        ],
                        [
                            -96.7805690003299,
                            33.8600980001983
                        ],
                        [
                            -96.7767659996425,
                            33.8419760001725
                        ],
                        [
                            -96.770674999808,
                            33.8296209998141
                        ],
                        [
                            -96.7662340003346,
                            33.8254589998941
                        ],
                        [
                            -96.7615870000985,
                            33.8244070000933
                        ],
                        [
                            -96.7124219997827,
                            33.8316329997612
                        ],
                        [
                            -96.7044570001795,
                            33.8350210000404
                        ],
                        [
                            -96.6995739999418,
                            33.8390489999086
                        ],
                        [
                            -96.6907079999901,
                            33.8499590002457
                        ],
                        [
                            -96.6847269997604,
                            33.8629050003572
                        ],
                        [
                            -96.6822089995612,
                            33.8738760000027
                        ],
                        [
                            -96.683463999912,
                            33.8842170001226
                        ],
                        [
                            -96.6809470004359,
                            33.8962039998491
                        ],
                        [
                            -96.6734489999147,
                            33.9122779997838
                        ],
                        [
                            -96.6671870003759,
                            33.9169400002294
                        ],
                        [
                            -96.658206999723,
                            33.9158410002223
                        ],
                        [
                            -96.6282939999755,
                            33.8944770000613
                        ],
                        [
                            -96.5879340004104,
                            33.8947840000792
                        ],
                        [
                            -96.5853600001346,
                            33.8889480001878
                        ],
                        [
                            -96.5901119999527,
                            33.8806649998235
                        ],
                        [
                            -96.5973479996349,
                            33.8751010001459
                        ],
                        [
                            -96.6119699996854,
                            33.8690159998901
                        ],
                        [
                            -96.6290220000716,
                            33.852407999693
                        ],
                        [
                            -96.6292739999667,
                            33.8455000002519
                        ],
                        [
                            -96.6231470001472,
                            33.8414959998515
                        ],
                        [
                            -96.592926000428,
                            33.8309150001656
                        ],
                        [
                            -96.5729370004412,
                            33.8190979999132
                        ],
                        [
                            -96.556213000118,
                            33.8191419999106
                        ],
                        [
                            -96.558108999628,
                            33.8171310001758
                        ],
                        [
                            -96.5556540000763,
                            33.8193889998151
                        ],
                        [
                            -96.5510649995665,
                            33.8192519998051
                        ],
                        [
                            -96.5517039999742,
                            33.8184770001815
                        ],
                        [
                            -96.5319660000575,
                            33.8239890003107
                        ],
                        [
                            -96.5325130004037,
                            33.8228579999624
                        ],
                        [
                            -96.5248650000946,
                            33.819526000352
                        ],
                        [
                            -96.5178479997972,
                            33.8068579996738
                        ],
                        [
                            -96.5156889996231,
                            33.7951649997766
                        ],
                        [
                            -96.5165809997508,
                            33.788278999645
                        ],
                        [
                            -96.5108549997893,
                            33.7806079999559
                        ],
                        [
                            -96.5009839997319,
                            33.7728010003184
                        ],
                        [
                            -96.4863409997651,
                            33.7729649999894
                        ],
                        [
                            -96.4693650004451,
                            33.7757920001262
                        ],
                        [
                            -96.4591539997309,
                            33.7752320000945
                        ],
                        [
                            -96.4480449999395,
                            33.7810309999557
                        ],
                        [
                            -96.4319869997882,
                            33.7791049998337
                        ],
                        [
                            -96.4235319997402,
                            33.7764320000533
                        ],
                        [
                            -96.4189780002888,
                            33.7716370002985
                        ],
                        [
                            -96.4134079999629,
                            33.7577139999601
                        ],
                        [
                            -96.4082200001267,
                            33.7505419997137
                        ],
                        [
                            -96.3695889996671,
                            33.7168089998908
                        ],
                        [
                            -96.3632519997853,
                            33.7010500002757
                        ],
                        [
                            -96.3621970003493,
                            33.6918179998617
                        ],
                        [
                            -96.3556339996134,
                            33.6874939998454
                        ],
                        [
                            -96.3468589998982,
                            33.6862160000959
                        ],
                        [
                            -96.3387560002028,
                            33.6883159998371
                        ],
                        [
                            -96.3329170002633,
                            33.6931579997494
                        ],
                        [
                            -96.3210979999191,
                            33.6951020001616
                        ],
                        [
                            -96.3122300003176,
                            33.7063039999938
                        ],
                        [
                            -96.3063639997156,
                            33.7230619996653
                        ],
                        [
                            -96.3078740003346,
                            33.7350219997045
                        ],
                        [
                            -96.3061629998742,
                            33.7440320000206
                        ],
                        [
                            -96.2981100004074,
                            33.7591249999468
                        ],
                        [
                            -96.2977940000399,
                            33.7612130001419
                        ],
                        [
                            -96.2982019995706,
                            33.7610149996474
                        ],
                        [
                            -96.2982550001724,
                            33.7594000003087
                        ],
                        [
                            -96.2988269997346,
                            33.7585480002878
                        ],
                        [
                            -96.2987609996124,
                            33.7609050003367
                        ],
                        [
                            -96.2964120003658,
                            33.7668330001362
                        ],
                        [
                            -96.2937080003937,
                            33.7694089998228
                        ],
                        [
                            -96.2870829997335,
                            33.7712429997348
                        ],
                        [
                            -96.2635930000814,
                            33.7674670002312
                        ],
                        [
                            -96.229023000052,
                            33.7480210000431
                        ],
                        [
                            -96.2205219999733,
                            33.7473900000037
                        ],
                        [
                            -96.1901659996366,
                            33.7555699998798
                        ],
                        [
                            -96.1866270003365,
                            33.7550209999111
                        ],
                        [
                            -96.1877320000012,
                            33.7560970003041
                        ],
                        [
                            -96.1786030002837,
                            33.7604259999343
                        ],
                        [
                            -96.1690900004267,
                            33.7704170003408
                        ],
                        [
                            -96.1627750002862,
                            33.7864209997765
                        ],
                        [
                            -96.1637130004446,
                            33.7877280000553
                        ],
                        [
                            -96.1607559998542,
                            33.7959100003415
                        ],
                        [
                            -96.1619540003037,
                            33.7997129998425
                        ],
                        [
                            -96.1598219998938,
                            33.8115500003477
                        ],
                        [
                            -96.1555379998808,
                            33.8140400002491
                        ],
                        [
                            -96.1637660001481,
                            33.8130510001051
                        ],
                        [
                            -96.169949999869,
                            33.8051309997646
                        ],
                        [
                            -96.1695919996687,
                            33.8009439998815
                        ],
                        [
                            -96.1757260000593,
                            33.8023210000084
                        ],
                        [
                            -96.1791220001425,
                            33.8102240000198
                        ],
                        [
                            -96.1763069998423,
                            33.8143749996576
                        ],
                        [
                            -96.1713530003579,
                            33.8147250002896
                        ],
                        [
                            -96.1665029997322,
                            33.8178669998338
                        ],
                        [
                            -96.1592949996391,
                            33.8185990003261
                        ],
                        [
                            -96.1507650001463,
                            33.8169869996318
                        ],
                        [
                            -96.1487919997451,
                            33.8191969998756
                        ],
                        [
                            -96.1516299996115,
                            33.8319459999725
                        ],
                        [
                            -96.1492269999383,
                            33.8370909997054
                        ],
                        [
                            -96.1389049997943,
                            33.8391590003078
                        ],
                        [
                            -96.1310580001918,
                            33.8374380003275
                        ],
                        [
                            -96.1278179997442,
                            33.8398959997034
                        ],
                        [
                            -96.1229489997502,
                            33.8399639997
                        ],
                        [
                            -96.1099939997792,
                            33.8323959998485
                        ],
                        [
                            -96.099360000364,
                            33.8304699996311
                        ],
                        [
                            -96.097448999887,
                            33.8327289999181
                        ],
                        [
                            -96.0976380000329,
                            33.83793599981
                        ],
                        [
                            -96.1013489997604,
                            33.8457209997251
                        ],
                        [
                            -96.1000950001328,
                            33.8479709999869
                        ],
                        [
                            -96.0846260001605,
                            33.8466559998373
                        ],
                        [
                            -96.0488340002905,
                            33.8364680003408
                        ],
                        [
                            -96.0349880000167,
                            33.8426459996406
                        ],
                        [
                            -96.0308649996623,
                            33.8483630000534
                        ],
                        [
                            -96.0305009996142,
                            33.855260999815
                        ],
                        [
                            -96.0269680001619,
                            33.8574740001336
                        ],
                        [
                            -96.0224079999644,
                            33.8570420002262
                        ],
                        [
                            -96.0195419996106,
                            33.8534969998509
                        ],
                        [
                            -96.0230950000527,
                            33.8441280002659
                        ],
                        [
                            -96.0227360000275,
                            33.8410919996492
                        ],
                        [
                            -96.0170550002718,
                            33.8414539997791
                        ],
                        [
                            -96.0109069996376,
                            33.8442959996511
                        ],
                        [
                            -96.0040500000721,
                            33.8452369997557
                        ],
                        [
                            -96.0044729996714,
                            33.8468360001286
                        ],
                        [
                            -95.9983509998747,
                            33.8510499996803
                        ],
                        [
                            -95.9968329997582,
                            33.8538979998574
                        ],
                        [
                            -95.9948689995778,
                            33.8618240003381
                        ],
                        [
                            -95.9996940000891,
                            33.8625519998857
                        ],
                        [
                            -96.0028750000872,
                            33.866669999775
                        ],
                        [
                            -96.0045730001288,
                            33.8707119999246
                        ],
                        [
                            -96.0029250003159,
                            33.8735900000453
                        ],
                        [
                            -95.9849270002737,
                            33.8658050002972
                        ],
                        [
                            -95.9840590004355,
                            33.8601180001689
                        ],
                        [
                            -95.9860400003343,
                            33.8569310000992
                        ],
                        [
                            -95.9848560001286,
                            33.8524920002383
                        ],
                        [
                            -95.9746290004191,
                            33.8554639998682
                        ],
                        [
                            -95.9473269998589,
                            33.8589150000562
                        ],
                        [
                            -95.9523830003489,
                            33.8573099997838
                        ],
                        [
                            -95.9513820004413,
                            33.8573319996488
                        ],
                        [
                            -95.944140000013,
                            33.8599980002749
                        ],
                        [
                            -95.941795999891,
                            33.8616510002757
                        ],
                        [
                            -95.9364039998403,
                            33.8714240002682
                        ],
                        [
                            -95.9322090004142,
                            33.8838820000427
                        ],
                        [
                            -95.9250890001846,
                            33.8849359999622
                        ],
                        [
                            -95.8952149998968,
                            33.867580000109
                        ],
                        [
                            -95.8868329996437,
                            33.8649350003642
                        ],
                        [
                            -95.8671890003367,
                            33.8512899998259
                        ],
                        [
                            -95.8568320000326,
                            33.8501409997021
                        ],
                        [
                            -95.8439349997879,
                            33.8381979998022
                        ],
                        [
                            -95.8304059997065,
                            33.8347849999972
                        ],
                        [
                            -95.8236989999289,
                            33.8374059997851
                        ],
                        [
                            -95.819486000061,
                            33.8412960003521
                        ],
                        [
                            -95.8188809999886,
                            33.8466630002951
                        ],
                        [
                            -95.8209110002912,
                            33.8561719997802
                        ],
                        [
                            -95.815571000119,
                            33.8598529999916
                        ],
                        [
                            -95.8021840003279,
                            33.8615619996931
                        ],
                        [
                            -95.7976140000847,
                            33.860589999906
                        ],
                        [
                            -95.7702389997295,
                            33.8452019998613
                        ],
                        [
                            -95.7579590001512,
                            33.8495660003304
                        ],
                        [
                            -95.7521090003411,
                            33.8549529996874
                        ],
                        [
                            -95.7513419998869,
                            33.8602869997709
                        ],
                        [
                            -95.760056000401,
                            33.873817000223
                        ],
                        [
                            -95.7615200000909,
                            33.8731179998295
                        ],
                        [
                            -95.7624669995718,
                            33.8811439999317
                        ],
                        [
                            -95.7579409997095,
                            33.8907519996879
                        ],
                        [
                            -95.7521529998237,
                            33.8945769998557
                        ],
                        [
                            -95.7451310004018,
                            33.8966900000324
                        ],
                        [
                            -95.7331830001862,
                            33.8964470002869
                        ],
                        [
                            -95.7199799996197,
                            33.8909439999097
                        ],
                        [
                            -95.712183000246,
                            33.8855190000809
                        ],
                        [
                            -95.7006350000635,
                            33.8858450001515
                        ],
                        [
                            -95.684578999562,
                            33.890646000025
                        ],
                        [
                            -95.6654780004032,
                            33.9090040002605
                        ],
                        [
                            -95.6552320004272,
                            33.9099140001378
                        ],
                        [
                            -95.6387719996947,
                            33.9049380002999
                        ],
                        [
                            -95.6327739997466,
                            33.9048149998767
                        ],
                        [
                            -95.6273180001218,
                            33.9078060000058
                        ],
                        [
                            -95.6247980002727,
                            33.9156639998701
                        ],
                        [
                            -95.6163669996158,
                            33.9276259998089
                        ],
                        [
                            -95.6031380000084,
                            33.9292000002309
                        ],
                        [
                            -95.5984160003276,
                            33.9343799997766
                        ],
                        [
                            -95.5975219996517,
                            33.9423419999908
                        ],
                        [
                            -95.5940849995606,
                            33.9430570002911
                        ],
                        [
                            -95.5878699998776,
                            33.9408110000382
                        ],
                        [
                            -95.5818540003861,
                            33.9341520003484
                        ],
                        [
                            -95.5637840003738,
                            33.9329449998385
                        ],
                        [
                            -95.5577110000827,
                            33.9303510000003
                        ],
                        [
                            -95.5542100004174,
                            33.9269580001217
                        ],
                        [
                            -95.5491139997444,
                            33.9115340001013
                        ],
                        [
                            -95.5478319996294,
                            33.9018560001949
                        ],
                        [
                            -95.5512160000171,
                            33.8897129998492
                        ],
                        [
                            -95.5489609995836,
                            33.8850529997248
                        ],
                        [
                            -95.5441409999934,
                            33.8806029999804
                        ],
                        [
                            -95.5326220001232,
                            33.8815210001014
                        ],
                        [
                            -95.5185289999772,
                            33.8898769998416
                        ],
                        [
                            -95.5159789999909,
                            33.8939860002568
                        ],
                        [
                            -95.5095259997582,
                            33.8975860001059
                        ],
                        [
                            -95.5066190002948,
                            33.8967159998076
                        ],
                        [
                            -95.5050919999574,
                            33.8934059996752
                        ],
                        [
                            -95.5062100000409,
                            33.8778090001814
                        ],
                        [
                            -95.504999000071,
                            33.8759220003171
                        ],
                        [
                            -95.492039999902,
                            33.8749420001473
                        ],
                        [
                            -95.4835310003256,
                            33.8768250002446
                        ],
                        [
                            -95.4704700000494,
                            33.8815989998514
                        ],
                        [
                            -95.4649380002566,
                            33.8868910002658
                        ],
                        [
                            -95.4614400000661,
                            33.8871059998973
                        ],
                        [
                            -95.459730000329,
                            33.8866110000721
                        ],
                        [
                            -95.4643390000319,
                            33.8743889998956
                        ],
                        [
                            -95.4633359995762,
                            33.872395999781
                        ],
                        [
                            -95.453655000388,
                            33.8708619999628
                        ],
                        [
                            -95.4427459997148,
                            33.8665059999307
                        ],
                        [
                            -95.4383000002185,
                            33.8663419997714
                        ],
                        [
                            -95.4276840003466,
                            33.8704590001281
                        ],
                        [
                            -95.4052089999475,
                            33.8645750003451
                        ],
                        [
                            -95.3838259996927,
                            33.8686459998205
                        ],
                        [
                            -95.3625710003827,
                            33.8669020002355
                        ],
                        [
                            -95.3356959996198,
                            33.8742520000428
                        ],
                        [
                            -95.3304579995549,
                            33.8825320000247
                        ],
                        [
                            -95.3299360002214,
                            33.8863339997859
                        ],
                        [
                            -95.314427999891,
                            33.8816320003266
                        ],
                        [
                            -95.3063970001655,
                            33.8749060003317
                        ],
                        [
                            -95.288926000378,
                            33.8730700000406
                        ],
                        [
                            -95.2807249998748,
                            33.8754909999292
                        ],
                        [
                            -95.2781740000636,
                            33.878385999802
                        ],
                        [
                            -95.278163000193,
                            33.8903429999456
                        ],
                        [
                            -95.280691000438,
                            33.8960750000257
                        ],
                        [
                            -95.2845579998011,
                            33.8999170000427
                        ],
                        [
                            -95.2846289999462,
                            33.9054830001976
                        ],
                        [
                            -95.275959999638,
                            33.9112789996294
                        ],
                        [
                            -95.2686970001916,
                            33.9110899996733
                        ],
                        [
                            -95.2649970003348,
                            33.9045880001631
                        ],
                        [
                            -95.2666560000183,
                            33.8938999996508
                        ],
                        [
                            -95.2621200001103,
                            33.8878260000069
                        ],
                        [
                            -95.2577239999443,
                            33.8901219996296
                        ],
                        [
                            -95.2531870002114,
                            33.8948779999535
                        ],
                        [
                            -95.2485009996172,
                            33.9069739996475
                        ],
                        [
                            -95.247596999794,
                            33.91561300037
                        ],
                        [
                            -95.2508930003181,
                            33.9342549996917
                        ],
                        [
                            -95.2500010001905,
                            33.9388609998893
                        ],
                        [
                            -95.240604999611,
                            33.9472289996618
                        ],
                        [
                            -95.2328790003824,
                            33.9497360000484
                        ],
                        [
                            -95.2302580002509,
                            33.9559879997747
                        ],
                        [
                            -95.2304440000238,
                            33.9600429998538
                        ],
                        [
                            -95.2260410001851,
                            33.9622370002064
                        ],
                        [
                            -95.2169469997294,
                            33.9626740001171
                        ],
                        [
                            -95.2057609999452,
                            33.9586159997497
                        ],
                        [
                            -95.1981040003135,
                            33.9533519999625
                        ],
                        [
                            -95.1715030000853,
                            33.9467200000761
                        ],
                        [
                            -95.1569610004007,
                            33.9366450002353
                        ],
                        [
                            -95.1394839998671,
                            33.9347329996517
                        ],
                        [
                            -95.1301569997829,
                            33.9367989998072
                        ],
                        [
                            -95.1247000003332,
                            33.9346749996967
                        ],
                        [
                            -95.121183999701,
                            33.9313070000701
                        ],
                        [
                            -95.1217269998492,
                            33.9257620002
                        ],
                        [
                            -95.1316420002875,
                            33.9160270002974
                        ],
                        [
                            -95.1316439999373,
                            33.9139260001862
                        ],
                        [
                            -95.1273640001223,
                            33.907825999979
                        ],
                        [
                            -95.1208930003462,
                            33.9045390003723
                        ],
                        [
                            -95.1103879999041,
                            33.9054070002732
                        ],
                        [
                            -95.1051759997785,
                            33.9094640001921
                        ],
                        [
                            -95.0898010004158,
                            33.9140690001349
                        ],
                        [
                            -95.0831980003952,
                            33.9214170003422
                        ],
                        [
                            -95.0800600000548,
                            33.9209889998643
                        ],
                        [
                            -95.0779500002845,
                            33.9186959999317
                        ],
                        [
                            -95.0767819999723,
                            33.9135840003211
                        ],
                        [
                            -95.0797420000375,
                            33.9085950000856
                        ],
                        [
                            -95.0875829997921,
                            33.9030179999343
                        ],
                        [
                            -95.0920640003469,
                            33.9019449997649
                        ],
                        [
                            -95.091502999757,
                            33.9003289996876
                        ],
                        [
                            -95.0974079998187,
                            33.8988609998183
                        ],
                        [
                            -95.1003779999296,
                            33.8955950003214
                        ],
                        [
                            -95.0987090002003,
                            33.8906829998871
                        ],
                        [
                            -95.089607000247,
                            33.8808569996293
                        ],
                        [
                            -95.0824080003748,
                            33.8798819999645
                        ],
                        [
                            -95.079834000099,
                            33.8874789997692
                        ],
                        [
                            -95.078695999924,
                            33.9050400001162
                        ],
                        [
                            -95.0749879996713,
                            33.9088049998628
                        ],
                        [
                            -95.0739659998472,
                            33.9156359997975
                        ],
                        [
                            -95.06947599997,
                            33.9175860002182
                        ],
                        [
                            -95.0667600003023,
                            33.9171010002936
                        ],
                        [
                            -95.0634589997553,
                            33.9140399998362
                        ],
                        [
                            -95.0642350004303,
                            33.9101559997757
                        ],
                        [
                            -95.0669789996871,
                            33.9082529997034
                        ],
                        [
                            -95.0746289996461,
                            33.9072670002287
                        ],
                        [
                            -95.0764170000994,
                            33.9042980002606
                        ],
                        [
                            -95.075875999601,
                            33.9018460002542
                        ],
                        [
                            -95.0718009998254,
                            33.8955610001094
                        ],
                        [
                            -95.069952000171,
                            33.8955780002171
                        ],
                        [
                            -95.0735280001793,
                            33.9019660002111
                        ],
                        [
                            -95.0724129995706,
                            33.9026990002266
                        ],
                        [
                            -95.0622839997704,
                            33.9036180002202
                        ],
                        [
                            -95.0601050004032,
                            33.9018729997932
                        ],
                        [
                            -95.0590879997037,
                            33.8874909999299
                        ],
                        [
                            -95.0505719995564,
                            33.8782989997549
                        ],
                        [
                            -95.0507039998008,
                            33.8681790003161
                        ],
                        [
                            -95.0490250000258,
                            33.8640900002503
                        ],
                        [
                            -95.0465679999259,
                            33.8625650001276
                        ],
                        [
                            -95.015744999609,
                            33.8577330002995
                        ],
                        [
                            -95.0101850002271,
                            33.8587309999448
                        ],
                        [
                            -95.0021870001136,
                            33.8637129999116
                        ],
                        [
                            -94.9980189995533,
                            33.8605039999523
                        ],
                        [
                            -94.9926709998835,
                            33.8524550002303
                        ],
                        [
                            -94.988487000328,
                            33.8510000000314
                        ],
                        [
                            -94.9816340000622,
                            33.8523140000327
                        ],
                        [
                            -94.9758960004051,
                            33.8601879996562
                        ],
                        [
                            -94.9702049997053,
                            33.8618169996309
                        ],
                        [
                            -94.9657990003919,
                            33.8486959998331
                        ],
                        [
                            -94.964400999926,
                            33.8370209999445
                        ],
                        [
                            -94.9522979998997,
                            33.8349490003113
                        ],
                        [
                            -94.9438880000575,
                            33.830226000206
                        ],
                        [
                            -94.9448379999115,
                            33.8258400003607
                        ],
                        [
                            -94.9535180000904,
                            33.8164749998803
                        ],
                        [
                            -94.9478740001446,
                            33.8083559998103
                        ],
                        [
                            -94.9404359998978,
                            33.8056370003718
                        ],
                        [
                            -94.9232889999753,
                            33.8087419997883
                        ],
                        [
                            -94.9242659995935,
                            33.8124940001242
                        ],
                        [
                            -94.9230990000045,
                            33.8154559999461
                        ],
                        [
                            -94.9266159995633,
                            33.827556999657
                        ],
                        [
                            -94.9224389996805,
                            33.8291130001363
                        ],
                        [
                            -94.9180099999026,
                            33.8267829999045
                        ],
                        [
                            -94.9108820001754,
                            33.8159150002346
                        ],
                        [
                            -94.9144869995977,
                            33.8085160000396
                        ],
                        [
                            -94.916747999879,
                            33.8089689998729
                        ],
                        [
                            -94.9213139999243,
                            33.7984080002539
                        ],
                        [
                            -94.9215259996364,
                            33.7904719999262
                        ],
                        [
                            -94.9128680000971,
                            33.7794759998141
                        ],
                        [
                            -94.9041720000247,
                            33.7771979998564
                        ],
                        [
                            -94.904197999964,
                            33.773249000207
                        ],
                        [
                            -94.9012809995565,
                            33.7708500002314
                        ],
                        [
                            -94.8953029996999,
                            33.7684039998828
                        ],
                        [
                            -94.8869339998656,
                            33.76906699982
                        ],
                        [
                            -94.8856779996899,
                            33.7652339998593
                        ],
                        [
                            -94.8763720004204,
                            33.7573300000418
                        ],
                        [
                            -94.8738330003048,
                            33.7580589998143
                        ],
                        [
                            -94.8745639998756,
                            33.7696179999824
                        ],
                        [
                            -94.8717369998799,
                            33.770394999767
                        ],
                        [
                            -94.8681440001532,
                            33.768873999836
                        ],
                        [
                            -94.8659289999026,
                            33.7656369999362
                        ],
                        [
                            -94.8667359996414,
                            33.7618300001565
                        ],
                        [
                            -94.876123999825,
                            33.7555009999585
                        ],
                        [
                            -94.8770829998998,
                            33.752219999872
                        ],
                        [
                            -94.8746690003559,
                            33.7491639997162
                        ],
                        [
                            -94.8606869996397,
                            33.7419449999326
                        ],
                        [
                            -94.8493140002577,
                            33.7395869998227
                        ],
                        [
                            -94.8279479997213,
                            33.7408819998019
                        ],
                        [
                            -94.8283209999902,
                            33.7349470001029
                        ],
                        [
                            -94.8229280001146,
                            33.7327240001916
                        ],
                        [
                            -94.8204290001819,
                            33.7338070001788
                        ],
                        [
                            -94.8230419999174,
                            33.7475249996757
                        ],
                        [
                            -94.8308440002123,
                            33.7580789997524
                        ],
                        [
                            -94.8294990003481,
                            33.7641039996269
                        ],
                        [
                            -94.8233850000491,
                            33.7691789996655
                        ],
                        [
                            -94.8207070000163,
                            33.7689639997394
                        ],
                        [
                            -94.8173350002225,
                            33.7658419998134
                        ],
                        [
                            -94.8159369997565,
                            33.759786999959
                        ],
                        [
                            -94.8168310004323,
                            33.7542159996882
                        ],
                        [
                            -94.8152530000413,
                            33.7513820002753
                        ],
                        [
                            -94.8127640001543,
                            33.7521130000436
                        ],
                        [
                            -94.8121549998839,
                            33.7509359998405
                        ],
                        [
                            -94.8129120002923,
                            33.7454470003173
                        ],
                        [
                            -94.8170230000529,
                            33.7400069998785
                        ],
                        [
                            -94.8120209999896,
                            33.7352619996325
                        ],
                        [
                            -94.8034329998722,
                            33.7327389999081
                        ],
                        [
                            -94.7800679998936,
                            33.7444690002668
                        ],
                        [
                            -94.7763680000368,
                            33.756398999885
                        ],
                        [
                            -94.7729150000521,
                            33.7597329999217
                        ],
                        [
                            -94.769850000405,
                            33.761033999643
                        ],
                        [
                            -94.763034999774,
                            33.7597639996292
                        ],
                        [
                            -94.7578469999378,
                            33.7558890000928
                        ],
                        [
                            -94.7571890001618,
                            33.7530279996957
                        ],
                        [
                            -94.7589480003028,
                            33.7485319997254
                        ],
                        [
                            -94.7652559998723,
                            33.7460959998582
                        ],
                        [
                            -94.7768149999255,
                            33.7464370002188
                        ],
                        [
                            -94.7886369997444,
                            33.7356689998403
                        ],
                        [
                            -94.7824980002293,
                            33.7301250001073
                        ],
                        [
                            -94.7686949996133,
                            33.7269889998527
                        ],
                        [
                            -94.7606039996133,
                            33.72705400021
                        ],
                        [
                            -94.7518249997002,
                            33.7343259996279
                        ],
                        [
                            -94.7476679999089,
                            33.7251479999341
                        ],
                        [
                            -94.7577380001578,
                            33.7178170000867
                        ],
                        [
                            -94.755905000397,
                            33.7150730000818
                        ],
                        [
                            -94.7513660001159,
                            33.7135400000439
                        ],
                        [
                            -94.7458550002396,
                            33.7161579996859
                        ],
                        [
                            -94.7450889996103,
                            33.7229080001282
                        ],
                        [
                            -94.7420919997352,
                            33.7266920003178
                        ],
                        [
                            -94.7367390000426,
                            33.7271869997187
                        ],
                        [
                            -94.7334130002795,
                            33.7251909999719
                        ],
                        [
                            -94.7327859995674,
                            33.7200349996971
                        ],
                        [
                            -94.7361289999472,
                            33.7160799999603
                        ],
                        [
                            -94.7412370003158,
                            33.7133580000064
                        ],
                        [
                            -94.7530690001804,
                            33.7107530003262
                        ],
                        [
                            -94.7538009995762,
                            33.7086629998066
                        ],
                        [
                            -94.7519419998761,
                            33.704862000175
                        ],
                        [
                            -94.7409219997732,
                            33.7005549996483
                        ],
                        [
                            -94.7358560001357,
                            33.6916250002113
                        ],
                        [
                            -94.7195310000208,
                            33.688856999793
                        ],
                        [
                            -94.7159430003169,
                            33.6949129997575
                        ],
                        [
                            -94.7188720004199,
                            33.703349000198
                        ],
                        [
                            -94.7306909998658,
                            33.7073550002086
                        ],
                        [
                            -94.7336430004333,
                            33.7113059997257
                        ],
                        [
                            -94.7349179999773,
                            33.7147649997156
                        ],
                        [
                            -94.7339649997503,
                            33.7156870001789
                        ],
                        [
                            -94.7252949996171,
                            33.7146720003366
                        ],
                        [
                            -94.7060239996807,
                            33.7062929997038
                        ],
                        [
                            -94.7015599997427,
                            33.7028779999384
                        ],
                        [
                            -94.7010550001276,
                            33.7000530001635
                        ],
                        [
                            -94.703496000334,
                            33.6975379998836
                        ],
                        [
                            -94.714431999873,
                            33.6971890003114
                        ],
                        [
                            -94.7183379995942,
                            33.6886640002202
                        ],
                        [
                            -94.716187999641,
                            33.6879020001365
                        ],
                        [
                            -94.704113999927,
                            33.6879450001386
                        ],
                        [
                            -94.6847960001349,
                            33.6843529996485
                        ],
                        [
                            -94.6717730003918,
                            33.6933070001126
                        ],
                        [
                            -94.6668850001313,
                            33.6945829997516
                        ],
                        [
                            -94.6591670004002,
                            33.6921380002168
                        ],
                        [
                            -94.6514019999152,
                            33.6858430000458
                        ],
                        [
                            -94.6488040002483,
                            33.6865730003454
                        ],
                        [
                            -94.6470929997879,
                            33.6903459997732
                        ],
                        [
                            -94.6479020000749,
                            33.7005159998083
                        ],
                        [
                            -94.6437150001464,
                            33.7015220000414
                        ],
                        [
                            -94.6394500004,
                            33.6982589996433
                        ],
                        [
                            -94.6386460001359,
                            33.695803999945
                        ],
                        [
                            -94.6454919998307,
                            33.6859320002288
                        ],
                        [
                            -94.6503730004185,
                            33.6844380000472
                        ],
                        [
                            -94.64533700002,
                            33.6736440001474
                        ],
                        [
                            -94.6518499996289,
                            33.6704529996556
                        ],
                        [
                            -94.661806000075,
                            33.6751329997445
                        ],
                        [
                            -94.6688479995884,
                            33.6718780000166
                        ],
                        [
                            -94.6710479997704,
                            33.6683879998504
                        ],
                        [
                            -94.6705640000717,
                            33.6659339996622
                        ],
                        [
                            -94.666854999994,
                            33.6628079998618
                        ],
                        [
                            -94.6618289996412,
                            33.6604570001015
                        ],
                        [
                            -94.6549190003723,
                            33.6599250002823
                        ],
                        [
                            -94.6492340004186,
                            33.6626040002332
                        ],
                        [
                            -94.6459229998259,
                            33.6619450002868
                        ],
                        [
                            -94.6386789997478,
                            33.6639879998033
                        ],
                        [
                            -94.630919000184,
                            33.6734419998492
                        ],
                        [
                            -94.6287109996061,
                            33.6805320001302
                        ],
                        [
                            -94.6220430001865,
                            33.6825349999913
                        ],
                        [
                            -94.616417999609,
                            33.6801029996301
                        ],
                        [
                            -94.6118909999218,
                            33.6700400002218
                        ],
                        [
                            -94.6058229996535,
                            33.6641890002424
                        ],
                        [
                            -94.6011629998969,
                            33.6627609999059
                        ],
                        [
                            -94.5960190004417,
                            33.6638629997173
                        ],
                        [
                            -94.592050000073,
                            33.6670390002427
                        ],
                        [
                            -94.5919529999886,
                            33.6728629999221
                        ],
                        [
                            -94.5900270003414,
                            33.6758500003409
                        ],
                        [
                            -94.5930300000642,
                            33.6840190003544
                        ],
                        [
                            -94.5915849997426,
                            33.6858670001378
                        ],
                        [
                            -94.5879300000916,
                            33.6862549996991
                        ],
                        [
                            -94.5796199998085,
                            33.6776229997137
                        ],
                        [
                            -94.581250999903,
                            33.6736179998395
                        ],
                        [
                            -94.5792690001793,
                            33.6708170001988
                        ],
                        [
                            -94.5807839999228,
                            33.6663919996502
                        ],
                        [
                            -94.5778759997362,
                            33.665681999752
                        ],
                        [
                            -94.5636679999625,
                            33.6692719998558
                        ],
                        [
                            -94.5603389998264,
                            33.6665909997486
                        ],
                        [
                            -94.5648019999395,
                            33.6618039996756
                        ],
                        [
                            -94.5767650002237,
                            33.6587629997063
                        ],
                        [
                            -94.5869860000854,
                            33.6501799999975
                        ],
                        [
                            -94.5904780004281,
                            33.6450749996558
                        ],
                        [
                            -94.5800469996058,
                            33.6444999997066
                        ],
                        [
                            -94.5705660004342,
                            33.6522259999952
                        ],
                        [
                            -94.5481110001266,
                            33.6603300000163
                        ],
                        [
                            -94.5430220000246,
                            33.6551819998592
                        ],
                        [
                            -94.5430329998953,
                            33.6493219999252
                        ],
                        [
                            -94.5460060003792,
                            33.6459080003177
                        ],
                        [
                            -94.5590419996428,
                            33.6419710002655
                        ],
                        [
                            -94.5683860003437,
                            33.6372029997603
                        ],
                        [
                            -94.5699749997071,
                            33.6284699997077
                        ],
                        [
                            -94.5640760003915,
                            33.6262649996305
                        ],
                        [
                            -94.5533719997577,
                            33.6275840000844
                        ],
                        [
                            -94.5377499996245,
                            33.6382550003352
                        ],
                        [
                            -94.5281260003377,
                            33.6412479998582
                        ],
                        [
                            -94.5218370001365,
                            33.6407279998212
                        ],
                        [
                            -94.5185289999168,
                            33.6375700001843
                        ],
                        [
                            -94.5354249997691,
                            33.6242169996273
                        ],
                        [
                            -94.5346890001753,
                            33.6211930003473
                        ],
                        [
                            -94.52773999965,
                            33.6158980000072
                        ],
                        [
                            -94.5234980003682,
                            33.6158990002008
                        ],
                        [
                            -94.519837999796,
                            33.6180769999473
                        ],
                        [
                            -94.5179199996464,
                            33.623734000049
                        ],
                        [
                            -94.5202450004001,
                            33.6298049998269
                        ],
                        [
                            -94.5174679997347,
                            33.6330560002634
                        ],
                        [
                            -94.5115140001676,
                            33.6231049997946
                        ],
                        [
                            -94.5045250003576,
                            33.6207279997588
                        ],
                        [
                            -94.4883629995509,
                            33.6274060000655
                        ],
                        [
                            -94.487736999562,
                            33.6306340000261
                        ],
                        [
                            -94.4916699999457,
                            33.6364540001332
                        ],
                        [
                            -94.4780670002445,
                            33.6390069997795
                        ],
                        [
                            -94.4660749996479,
                            33.6362620003471
                        ],
                        [
                            -94.4614530004245,
                            33.6436160002029
                        ],
                        [
                            -94.4586299997284,
                            33.6452660001301
                        ],
                        [
                            -94.4486370003707,
                            33.642766000318
                        ],
                        [
                            -94.4468709996587,
                            33.6401780000464
                        ],
                        [
                            -94.448438000179,
                            33.6344980002767
                        ],
                        [
                            -94.4627360003644,
                            33.630909999809
                        ],
                        [
                            -94.4602740002417,
                            33.6244149999031
                        ],
                        [
                            -94.4544899996555,
                            33.6257420001434
                        ],
                        [
                            -94.4527110003213,
                            33.6226209998513
                        ],
                        [
                            -94.4527010002756,
                            33.6173729998082
                        ],
                        [
                            -94.4694509996397,
                            33.6073159997179
                        ],
                        [
                            -94.4724430003902,
                            33.6034689997538
                        ],
                        [
                            -94.4675869999167,
                            33.5988419999856
                        ],
                        [
                            -94.4623019999962,
                            33.5983790000775
                        ],
                        [
                            -94.45743899985,
                            33.6004120001635
                        ],
                        [
                            -94.4582320002434,
                            33.5982700001546
                        ],
                        [
                            -94.4531969996698,
                            33.5917800001072
                        ],
                        [
                            -94.4422140002751,
                            33.5912760002713
                        ],
                        [
                            -94.4389789998504,
                            33.5936199998798
                        ],
                        [
                            -94.4300409999286,
                            33.5911239998854
                        ],
                        [
                            -94.4131640003429,
                            33.5693589999974
                        ],
                        [
                            -94.4109259996278,
                            33.5683439997149
                        ],
                        [
                            -94.4006689997811,
                            33.5698030003465
                        ],
                        [
                            -94.3828980004179,
                            33.5832770003627
                        ],
                        [
                            -94.3781060004168,
                            33.5770150000696
                        ],
                        [
                            -94.3800799997447,
                            33.5689470000317
                        ],
                        [
                            -94.3880329996523,
                            33.5655179998393
                        ],
                        [
                            -94.3948509997581,
                            33.5665210001967
                        ],
                        [
                            -94.4004379998024,
                            33.5629350000527
                        ],
                        [
                            -94.4016160001604,
                            33.5593779998841
                        ],
                        [
                            -94.3867680001541,
                            33.5450090002303
                        ],
                        [
                            -94.3816639999835,
                            33.5440349999649
                        ],
                        [
                            -94.3698749997765,
                            33.5456610002405
                        ],
                        [
                            -94.3577910000168,
                            33.5430499997647
                        ],
                        [
                            -94.3535930002176,
                            33.5440049998548
                        ],
                        [
                            -94.3489450001565,
                            33.5483579998742
                        ],
                        [
                            -94.3472930001457,
                            33.5521519999399
                        ],
                        [
                            -94.3524180002327,
                            33.5621930000881
                        ],
                        [
                            -94.3440229995608,
                            33.5678239999329
                        ],
                        [
                            -94.3395619999959,
                            33.5676680001361
                        ],
                        [
                            -94.3355240000302,
                            33.5644840000309
                        ],
                        [
                            -94.3332030003727,
                            33.5553660002049
                        ],
                        [
                            -94.3312020003824,
                            33.552951000323
                        ],
                        [
                            -94.3212660000278,
                            33.5490299999847
                        ],
                        [
                            -94.3121499998308,
                            33.5506660003684
                        ],
                        [
                            -94.3064110003488,
                            33.5555980002761
                        ],
                        [
                            -94.3071820001026,
                            33.5597979998598
                        ],
                        [
                            -94.3010229995977,
                            33.57302099995
                        ],
                        [
                            -94.2949689995731,
                            33.5791249998486
                        ],
                        [
                            -94.2892150000225,
                            33.5821310003522
                        ],
                        [
                            -94.2835819999474,
                            33.5818910002564
                        ],
                        [
                            -94.2806050001638,
                            33.5749080001123
                        ],
                        [
                            -94.2901270002417,
                            33.5681110001999
                        ],
                        [
                            -94.2908970001706,
                            33.5599059996304
                        ],
                        [
                            -94.2859400003131,
                            33.5562289998575
                        ],
                        [
                            -94.2782600002169,
                            33.5575559998512
                        ],
                        [
                            -94.2713480003999,
                            33.5620940000556
                        ],
                        [
                            -94.265669000294,
                            33.5735889997921
                        ],
                        [
                            -94.2567940001214,
                            33.583605000247
                        ],
                        [
                            -94.2427770001434,
                            33.589708999909
                        ],
                        [
                            -94.236970999816,
                            33.5874069998648
                        ],
                        [
                            -94.2368329997237,
                            33.5809179996278
                        ],
                        [
                            -94.2388660003994,
                            33.5767479996981
                        ],
                        [
                            -94.2443499996133,
                            33.573570000261
                        ],
                        [
                            -94.2501929997507,
                            33.567459999719
                        ],
                        [
                            -94.2519179995564,
                            33.5594110001968
                        ],
                        [
                            -94.24750899987,
                            33.555619999702
                        ],
                        [
                            -94.2319699995775,
                            33.5520859997446
                        ],
                        [
                            -94.2192099996002,
                            33.5560960002674
                        ],
                        [
                            -94.2136250001039,
                            33.5631320002646
                        ],
                        [
                            -94.2056660003485,
                            33.5672189999876
                        ],
                        [
                            -94.2035940002131,
                            33.5665339996771
                        ],
                        [
                            -94.199792000249,
                            33.5563360002121
                        ],
                        [
                            -94.1961600001642,
                            33.5549840001562
                        ],
                        [
                            -94.19207000032,
                            33.5566330003059
                        ],
                        [
                            -94.1897129997792,
                            33.5624829998021
                        ],
                        [
                            -94.1941270003868,
                            33.573468999926
                        ],
                        [
                            -94.201587000375,
                            33.5773480000147
                        ],
                        [
                            -94.211244000172,
                            33.5737379998303
                        ],
                        [
                            -94.2162079997022,
                            33.5765690003376
                        ],
                        [
                            -94.2173409998543,
                            33.5800969998769
                        ],
                        [
                            -94.2130069996127,
                            33.5834819998752
                        ],
                        [
                            -94.2038429997351,
                            33.5797730003355
                        ],
                        [
                            -94.1964349996255,
                            33.5812929997834
                        ],
                        [
                            -94.1839120003729,
                            33.594685000282
                        ],
                        [
                            -94.176326999813,
                            33.5910769997167
                        ],
                        [
                            -94.1622660003523,
                            33.5889060001292
                        ],
                        [
                            -94.1610810003217,
                            33.5879729997831
                        ],
                        [
                            -94.1620060000614,
                            33.5808840001998
                        ],
                        [
                            -94.1567749996692,
                            33.5757619998784
                        ],
                        [
                            -94.1526260002737,
                            33.5759230003109
                        ],
                        [
                            -94.1460480003675,
                            33.5819750002162
                        ],
                        [
                            -94.1421600001897,
                            33.5813899998138
                        ],
                        [
                            -94.1430239998299,
                            33.5777249997415
                        ],
                        [
                            -94.1513440001587,
                            33.5713889999432
                        ],
                        [
                            -94.1515740003125,
                            33.5684060002285
                        ],
                        [
                            -94.1452180001641,
                            33.5649790003294
                        ],
                        [
                            -94.1351439997172,
                            33.571033000262
                        ],
                        [
                            -94.1311209998201,
                            33.5528090000875
                        ],
                        [
                            -94.127611999759,
                            33.5507210001909
                        ],
                        [
                            -94.1224230000979,
                            33.5546170000038
                        ],
                        [
                            -94.1208599997755,
                            33.5670509997085
                        ],
                        [
                            -94.1128430002937,
                            33.5669909997435
                        ],
                        [
                            -94.0974410002686,
                            33.5737440001831
                        ],
                        [
                            -94.0889369998168,
                            33.575333999639
                        ],
                        [
                            -94.0823520002378,
                            33.5757199998789
                        ],
                        [
                            -94.072825999962,
                            33.5722979996493
                        ],
                        [
                            -94.0720080003525,
                            33.5762950000038
                        ],
                        [
                            -94.0752119999168,
                            33.5831559999217
                        ],
                        [
                            -94.073635000249,
                            33.5847730003699
                        ],
                        [
                            -94.0713060001956,
                            33.5837069997652
                        ],
                        [
                            -94.0685310000784,
                            33.5711030000587
                        ],
                        [
                            -94.06627499982,
                            33.5687309997656
                        ],
                        [
                            -94.057085999828,
                            33.5680030002113
                        ],
                        [
                            -94.0565800003881,
                            33.5609319996979
                        ],
                        [
                            -94.0611780002204,
                            33.5591579999729
                        ],
                        [
                            -94.0666840000739,
                            33.5609529999288
                        ],
                        [
                            -94.0709599996909,
                            33.5601399996681
                        ],
                        [
                            -94.0738140003492,
                            33.5576989998895
                        ],
                        [
                            -94.0734000000724,
                            33.5551919999296
                        ],
                        [
                            -94.0614769999711,
                            33.5500010002205
                        ],
                        [
                            -94.0519180000833,
                            33.552640000046
                        ],
                        [
                            -94.0501859997065,
                            33.5510760003125
                        ],
                        [
                            -94.0434279998753,
                            33.5513779999911
                        ],
                        [
                            -94.0427779995969,
                            32.0248310001663
                        ],
                        [
                            -94.0427199998706,
                            31.9992709996433
                        ],
                        [
                            -94.0410450002935,
                            31.9963209999022
                        ],
                        [
                            -94.0422410001948,
                            31.9936089998811
                        ],
                        [
                            -94.0390989996565,
                            31.9922860002897
                        ],
                        [
                            -94.0286219997018,
                            31.9962460000276
                        ],
                        [
                            -94.0228930002655,
                            31.9904499998859
                        ],
                        [
                            -94.018513999818,
                            31.9924319997412
                        ],
                        [
                            -94.0165150003758,
                            31.9811739999202
                        ],
                        [
                            -94.0067949999312,
                            31.9734300002714
                        ],
                        [
                            -93.995712999904,
                            31.9541400001902
                        ],
                        [
                            -93.98232100009,
                            31.939764000112
                        ],
                        [
                            -93.9774610003169,
                            31.9264190001234
                        ],
                        [
                            -93.971710000241,
                            31.9203860001609
                        ],
                        [
                            -93.9690320002081,
                            31.920150999638
                        ],
                        [
                            -93.9629480000463,
                            31.92415600028
                        ],
                        [
                            -93.9597679998732,
                            31.9232259998866
                        ],
                        [
                            -93.9535459996191,
                            31.9105629998199
                        ],
                        [
                            -93.9375369998716,
                            31.9092219997519
                        ],
                        [
                            -93.9286509998284,
                            31.9126450001105
                        ],
                        [
                            -93.9215940002463,
                            31.9044209998844
                        ],
                        [
                            -93.9251569998358,
                            31.9024749999137
                        ],
                        [
                            -93.9321349997751,
                            31.8936719998665
                        ],
                        [
                            -93.931189999944,
                            31.8914949998827
                        ],
                        [
                            -93.9255259999068,
                            31.8946150000602
                        ],
                        [
                            -93.9245419997176,
                            31.8929289998943
                        ],
                        [
                            -93.9271720000699,
                            31.8908930001685
                        ],
                        [
                            -93.926209999622,
                            31.8886830002234
                        ],
                        [
                            -93.9209749999301,
                            31.8858779999855
                        ],
                        [
                            -93.9196929998151,
                            31.8866419998144
                        ],
                        [
                            -93.9207110003395,
                            31.8897310000734
                        ],
                        [
                            -93.9196810001196,
                            31.8909630002665
                        ],
                        [
                            -93.9168890002839,
                            31.8898469996875
                        ],
                        [
                            -93.9149989997234,
                            31.892948000345
                        ],
                        [
                            -93.9125340001258,
                            31.8924689997077
                        ],
                        [
                            -93.9125490001945,
                            31.8901450002226
                        ],
                        [
                            -93.9065389996525,
                            31.8925089998483
                        ],
                        [
                            -93.9061880000232,
                            31.8899279998772
                        ],
                        [
                            -93.9030799998201,
                            31.8939220000539
                        ],
                        [
                            -93.9038189997868,
                            31.8957689997924
                        ],
                        [
                            -93.9006160000475,
                            31.8967880000445
                        ],
                        [
                            -93.8971880001772,
                            31.891018999849
                        ],
                        [
                            -93.8964580004312,
                            31.8862860000597
                        ],
                        [
                            -93.9018880001167,
                            31.8800630000697
                        ],
                        [
                            -93.9016760004046,
                            31.8745700000748
                        ],
                        [
                            -93.8993449998031,
                            31.8738760001703
                        ],
                        [
                            -93.8969749997418,
                            31.875982000034
                        ],
                        [
                            -93.8921149999687,
                            31.8759169996704
                        ],
                        [
                            -93.8934380000916,
                            31.8717310003731
                        ],
                        [
                            -93.888738999977,
                            31.8694579999082
                        ],
                        [
                            -93.8854149998637,
                            31.8652570001654
                        ],
                        [
                            -93.8848790002865,
                            31.8693719999948
                        ],
                        [
                            -93.881678000197,
                            31.8685809996537
                        ],
                        [
                            -93.881894000107,
                            31.8648350001738
                        ],
                        [
                            -93.889395999928,
                            31.8559000003036
                        ],
                        [
                            -93.8828780002962,
                            31.8546720002331
                        ],
                        [
                            -93.8821119996669,
                            31.852319000272
                        ],
                        [
                            -93.8846370004372,
                            31.8501629999177
                        ],
                        [
                            -93.8841169998552,
                            31.8476059998468
                        ],
                        [
                            -93.8789559997832,
                            31.8447669999435
                        ],
                        [
                            -93.882079000055,
                            31.8440890000817
                        ],
                        [
                            -93.8819140001985,
                            31.8422160002921
                        ],
                        [
                            -93.8749639998483,
                            31.8408679999926
                        ],
                        [
                            -93.8728189999179,
                            31.8363010000123
                        ],
                        [
                            -93.877358000199,
                            31.8325279997508
                        ],
                        [
                            -93.8746450000061,
                            31.8298880002496
                        ],
                        [
                            -93.874761000357,
                            31.8216610002131
                        ],
                        [
                            -93.8709369997533,
                            31.8193830002095
                        ],
                        [
                            -93.8709169996618,
                            31.8168370001992
                        ],
                        [
                            -93.8732530002862,
                            31.8148860003
                        ],
                        [
                            -93.870838999844,
                            31.813783000048
                        ],
                        [
                            -93.8673189999122,
                            31.8153159996628
                        ],
                        [
                            -93.8613839997133,
                            31.8122970001173
                        ],
                        [
                            -93.8593159997758,
                            31.8091150001384
                        ],
                        [
                            -93.8533899997978,
                            31.8054670000589
                        ],
                        [
                            -93.8535289997149,
                            31.8026070000876
                        ],
                        [
                            -93.8514809998689,
                            31.8001499996219
                        ],
                        [
                            -93.8476320000492,
                            31.7996350000212
                        ],
                        [
                            -93.8461879995525,
                            31.8020209999234
                        ],
                        [
                            -93.8393429996825,
                            31.8003299997987
                        ],
                        [
                            -93.8356129996885,
                            31.7944889996961
                        ],
                        [
                            -93.8375559999524,
                            31.7897920001711
                        ],
                        [
                            -93.8358550004361,
                            31.7852160001315
                        ],
                        [
                            -93.8377779997103,
                            31.7837080002057
                        ],
                        [
                            -93.8376930002198,
                            31.7808170000958
                        ],
                        [
                            -93.8317320000816,
                            31.7809140001509
                        ],
                        [
                            -93.8308490001748,
                            31.7790940002375
                        ],
                        [
                            -93.8331199996035,
                            31.7775219999506
                        ],
                        [
                            -93.8274510004417,
                            31.7777409997897
                        ],
                        [
                            -93.823442999715,
                            31.7750980001868
                        ],
                        [
                            -93.8225980003413,
                            31.7735589997646
                        ],
                        [
                            -93.8253280002527,
                            31.767170000136
                        ],
                        [
                            -93.8295870001513,
                            31.7642039997953
                        ],
                        [
                            -93.8268100003842,
                            31.7637770002509
                        ],
                        [
                            -93.8264610004048,
                            31.7591929999238
                        ],
                        [
                            -93.8275409999551,
                            31.7608849998821
                        ],
                        [
                            -93.8298209996047,
                            31.7606189996347
                        ],
                        [
                            -93.8316219995785,
                            31.7545920000139
                        ],
                        [
                            -93.8368839999328,
                            31.7501709998517
                        ],
                        [
                            -93.8352570000363,
                            31.7472360003058
                        ],
                        [
                            -93.8316979997464,
                            31.7455139999549
                        ],
                        [
                            -93.8245790002401,
                            31.7343969997814
                        ],
                        [
                            -93.8149299999407,
                            31.7286880003466
                        ],
                        [
                            -93.8124770000387,
                            31.7152459998851
                        ],
                        [
                            -93.8166940001045,
                            31.7079170001961
                        ],
                        [
                            -93.811801999646,
                            31.707484999819
                        ],
                        [
                            -93.809798000181,
                            31.7030820001958
                        ],
                        [
                            -93.7974070002746,
                            31.7056149996856
                        ],
                        [
                            -93.794462000278,
                            31.703320000066
                        ],
                        [
                            -93.8051440002722,
                            31.6869569996511
                        ],
                        [
                            -93.8044620002068,
                            31.6840889999801
                        ],
                        [
                            -93.8162849998506,
                            31.6753599996918
                        ],
                        [
                            -93.8124329996578,
                            31.6702449997979
                        ],
                        [
                            -93.8166510004468,
                            31.6680099997376
                        ],
                        [
                            -93.8174249996754,
                            31.6721460001858
                        ],
                        [
                            -93.8220509999951,
                            31.67396699996
                        ],
                        [
                            -93.8264620002297,
                            31.6669189997678
                        ],
                        [
                            -93.8256610003386,
                            31.6610219997313
                        ],
                        [
                            -93.8194859999402,
                            31.6526499998918
                        ],
                        [
                            -93.8127880003833,
                            31.6504410001534
                        ],
                        [
                            -93.8115160003141,
                            31.6474350000941
                        ],
                        [
                            -93.8132340004472,
                            31.6464910003715
                        ],
                        [
                            -93.8167879998159,
                            31.6489489997281
                        ],
                        [
                            -93.8180370003189,
                            31.6478919997103
                        ],
                        [
                            -93.8163690004145,
                            31.6392579996549
                        ],
                        [
                            -93.8180620004333,
                            31.6355679999321
                        ],
                        [
                            -93.8159550001377,
                            31.6322789999459
                        ],
                        [
                            -93.8208480004211,
                            31.6290200000797
                        ],
                        [
                            -93.8203439997327,
                            31.6270890001766
                        ],
                        [
                            -93.8169870000075,
                            31.6251750001489
                        ],
                        [
                            -93.817155000237,
                            31.6218719999412
                        ],
                        [
                            -93.8271700002343,
                            31.620276000249
                        ],
                        [
                            -93.8187870001564,
                            31.6166029997261
                        ],
                        [
                            -93.8187169998362,
                            31.6145560000416
                        ],
                        [
                            -93.8270830001957,
                            31.6128569997275
                        ],
                        [
                            -93.8258539997841,
                            31.6159099999099
                        ],
                        [
                            -93.8278520002997,
                            31.6165509999995
                        ],
                        [
                            -93.8331029998851,
                            31.6090379998829
                        ],
                        [
                            -93.8380570002679,
                            31.6067949999777
                        ],
                        [
                            -93.8391300001455,
                            31.60354000034
                        ],
                        [
                            -93.8376060001812,
                            31.6018260003393
                        ],
                        [
                            -93.8394160003758,
                            31.599199999809
                        ],
                        [
                            -93.8349180001026,
                            31.5862119997197
                        ],
                        [
                            -93.830362999928,
                            31.5793550003564
                        ],
                        [
                            -93.8271500001428,
                            31.5784770001879
                        ],
                        [
                            -93.818433999979,
                            31.5709430001465
                        ],
                        [
                            -93.8191489996563,
                            31.5689299997902
                        ],
                        [
                            -93.8229580001914,
                            31.5681300001837
                        ],
                        [
                            -93.8215639999234,
                            31.5636959996195
                        ],
                        [
                            -93.8168599997859,
                            31.5630839996945
                        ],
                        [
                            -93.8183629998338,
                            31.5601639997906
                        ],
                        [
                            -93.8211069999889,
                            31.5604709999365
                        ],
                        [
                            -93.8185820001169,
                            31.5548259996765
                        ],
                        [
                            -93.813973000414,
                            31.5505559999032
                        ],
                        [
                            -93.8102500000926,
                            31.5498780000618
                        ],
                        [
                            -93.8083050001788,
                            31.5463830002074
                        ],
                        [
                            -93.7995360003114,
                            31.5429769999602
                        ],
                        [
                            -93.8033590001919,
                            31.5387869999263
                        ],
                        [
                            -93.7992059997002,
                            31.5380750003538
                        ],
                        [
                            -93.7980869997918,
                            31.5340440003387
                        ],
                        [
                            -93.7902979999157,
                            31.5307730000986
                        ],
                        [
                            -93.78768699983,
                            31.5273440002183
                        ],
                        [
                            -93.7808350002874,
                            31.5253839996337
                        ],
                        [
                            -93.7600620001279,
                            31.5239330003593
                        ],
                        [
                            -93.7538599999653,
                            31.5253310002668
                        ],
                        [
                            -93.7576130004239,
                            31.527553999783
                        ],
                        [
                            -93.7581110003663,
                            31.5298719997999
                        ],
                        [
                            -93.7547000002145,
                            31.5298649998171
                        ],
                        [
                            -93.7518890001122,
                            31.5274750002829
                        ],
                        [
                            -93.7474369998698,
                            31.5306819996278
                        ],
                        [
                            -93.7469010002926,
                            31.5279399999465
                        ],
                        [
                            -93.7426520004397,
                            31.5265599998662
                        ],
                        [
                            -93.7405290002507,
                            31.5181420000214
                        ],
                        [
                            -93.7444480003906,
                            31.5178529999937
                        ],
                        [
                            -93.7426930004476,
                            31.5156939997485
                        ],
                        [
                            -93.735237999584,
                            31.5134960003226
                        ],
                        [
                            -93.7147029999742,
                            31.5141560002411
                        ],
                        [
                            -93.7110459997751,
                            31.5121609997787
                        ],
                        [
                            -93.7138860001896,
                            31.5086399999014
                        ],
                        [
                            -93.7195669999453,
                            31.5069209997645
                        ],
                        [
                            -93.7174849997642,
                            31.5014959997018
                        ],
                        [
                            -93.7234469997272,
                            31.5009379999854
                        ],
                        [
                            -93.7240810001121,
                            31.4964009996621
                        ],
                        [
                            -93.7219410002046,
                            31.4942829996782
                        ],
                        [
                            -93.7225959996074,
                            31.4927689997439
                        ],
                        [
                            -93.7313460001065,
                            31.487274000216
                        ],
                        [
                            -93.7353149995769,
                            31.4890739999711
                        ],
                        [
                            -93.7371680003276,
                            31.4846219997603
                        ],
                        [
                            -93.7418940002064,
                            31.4835370001463
                        ],
                        [
                            -93.7451520001974,
                            31.4856380002905
                        ],
                        [
                            -93.7523870000546,
                            31.4827399996283
                        ],
                        [
                            -93.7513679997053,
                            31.4790719998408
                        ],
                        [
                            -93.7480029995842,
                            31.4816479998753
                        ],
                        [
                            -93.7455420001847,
                            31.480395999736
                        ],
                        [
                            -93.7494760003933,
                            31.4686899998127
                        ],
                        [
                            -93.7418640000691,
                            31.4658720001214
                        ],
                        [
                            -93.7315129996128,
                            31.4574739997458
                        ],
                        [
                            -93.7219310001588,
                            31.4534469999998
                        ],
                        [
                            -93.7167499999953,
                            31.4575480001347
                        ],
                        [
                            -93.7099700004227,
                            31.4527600000774
                        ],
                        [
                            -93.7091279996254,
                            31.4567459996996
                        ],
                        [
                            -93.7039339999415,
                            31.4554929997862
                        ],
                        [
                            -93.709511999765,
                            31.4420250002596
                        ],
                        [
                            -93.6932720000388,
                            31.4400779997132
                        ],
                        [
                            -93.6925260003994,
                            31.4382010003656
                        ],
                        [
                            -93.7046779999311,
                            31.4189000001519
                        ],
                        [
                            -93.7048789997726,
                            31.4108809999527
                        ],
                        [
                            -93.6958660004061,
                            31.4093919999702
                        ],
                        [
                            -93.6903260002174,
                            31.4065910003135
                        ],
                        [
                            -93.6820970001252,
                            31.3983320001877
                        ],
                        [
                            -93.674116999555,
                            31.3976809998513
                        ],
                        [
                            -93.6701820004199,
                            31.3871840003367
                        ],
                        [
                            -93.6690169995824,
                            31.3713790002065
                        ],
                        [
                            -93.6738470001166,
                            31.3706329999077
                        ],
                        [
                            -93.6709990002045,
                            31.3662210000794
                        ],
                        [
                            -93.6583429999842,
                            31.3668449997223
                        ],
                        [
                            -93.6482569998417,
                            31.3708550001735
                        ],
                        [
                            -93.6392219998356,
                            31.3720200003676
                        ],
                        [
                            -93.6441240003398,
                            31.3655280003778
                        ],
                        [
                            -93.6458659998641,
                            31.3590080003068
                        ],
                        [
                            -93.6684390001724,
                            31.3530120002881
                        ],
                        [
                            -93.6695470002102,
                            31.3505699997965
                        ],
                        [
                            -93.6690240001534,
                            31.3388289999977
                        ],
                        [
                            -93.6772769996367,
                            31.3304830000672
                        ],
                        [
                            -93.6735530003888,
                            31.3245419996235
                        ],
                        [
                            -93.6762630002086,
                            31.3213510003015
                        ],
                        [
                            -93.682940999674,
                            31.3189989997758
                        ],
                        [
                            -93.6839809999397,
                            31.3146210003366
                        ],
                        [
                            -93.6878509996758,
                            31.309834999901
                        ],
                        [
                            -93.6872780002887,
                            31.3060679998977
                        ],
                        [
                            -93.6835609998151,
                            31.3015070001889
                        ],
                        [
                            -93.6754399996779,
                            31.3010400002434
                        ],
                        [
                            -93.668927999894,
                            31.297974999824
                        ],
                        [
                            -93.658685000291,
                            31.2816240001271
                        ],
                        [
                            -93.648647999654,
                            31.2842300000639
                        ],
                        [
                            -93.6446330001529,
                            31.2777639999556
                        ],
                        [
                            -93.6425159998116,
                            31.2695079999896
                        ],
                        [
                            -93.6203430004347,
                            31.2710249999701
                        ],
                        [
                            -93.619924000135,
                            31.2651779998233
                        ],
                        [
                            -93.6139420000804,
                            31.2593749999619
                        ],
                        [
                            -93.615636999749,
                            31.2551269999824
                        ],
                        [
                            -93.6142879996868,
                            31.2516310003145
                        ],
                        [
                            -93.6217569998959,
                            31.249127000288
                        ],
                        [
                            -93.6163079999437,
                            31.2445950003675
                        ],
                        [
                            -93.6162739996086,
                            31.2368379998629
                        ],
                        [
                            -93.6181070002677,
                            31.2326849996176
                        ],
                        [
                            -93.6107050000059,
                            31.2321520003509
                        ],
                        [
                            -93.6081580003926,
                            31.2278350001494
                        ],
                        [
                            -93.5983910000923,
                            31.2310970001598
                        ],
                        [
                            -93.5962729999262,
                            31.2295100002583
                        ],
                        [
                            -93.5993529996419,
                            31.2249489996639
                        ],
                        [
                            -93.5952550003001,
                            31.2203139997361
                        ],
                        [
                            -93.6034960000879,
                            31.2167099999921
                        ],
                        [
                            -93.6035410002937,
                            31.2106729997005
                        ],
                        [
                            -93.6072429998004,
                            31.204805999869
                        ],
                        [
                            -93.6018569995975,
                            31.1993930002825
                        ],
                        [
                            -93.6024430003017,
                            31.1825409998651
                        ],
                        [
                            -93.6003080004171,
                            31.1761579998314
                        ],
                        [
                            -93.5891190002598,
                            31.1658889996357
                        ],
                        [
                            -93.578166000104,
                            31.1678550003703
                        ],
                        [
                            -93.569562999918,
                            31.1775740001878
                        ],
                        [
                            -93.54998400001,
                            31.186626999703
                        ],
                        [
                            -93.5443650001786,
                            31.1834779998423
                        ],
                        [
                            -93.5392800002745,
                            31.1835469999333
                        ],
                        [
                            -93.5350980003688,
                            31.1856120001431
                        ],
                        [
                            -93.5325450000095,
                            31.1833139996358
                        ],
                        [
                            -93.531318000146,
                            31.1798060001038
                        ],
                        [
                            -93.5328069999503,
                            31.1742649997534
                        ],
                        [
                            -93.5430610003222,
                            31.1706579997015
                        ],
                        [
                            -93.5379889999387,
                            31.1634270000792
                        ],
                        [
                            -93.5364809998679,
                            31.1584710000107
                        ],
                        [
                            -93.5401640000063,
                            31.1560539998324
                        ],
                        [
                            -93.5487000002451,
                            31.155838999716
                        ],
                        [
                            -93.5485150002972,
                            31.1482289997091
                        ],
                        [
                            -93.5447350000744,
                            31.1360660003336
                        ],
                        [
                            -93.537870000113,
                            31.1265700002004
                        ],
                        [
                            -93.5393659995899,
                            31.1152069998558
                        ],
                        [
                            -93.5459350001737,
                            31.1111970000403
                        ],
                        [
                            -93.5486960000472,
                            31.1060349996174
                        ],
                        [
                            -93.5490669997679,
                            31.1002190003362
                        ],
                        [
                            -93.558178999767,
                            31.0979830003685
                        ],
                        [
                            -93.5583160000343,
                            31.096481000188
                        ],
                        [
                            -93.554627999873,
                            31.0951879996589
                        ],
                        [
                            -93.5537220004,
                            31.0938690002016
                        ],
                        [
                            -93.5579410001156,
                            31.0958740002318
                        ],
                        [
                            -93.5593780000413,
                            31.0976780003172
                        ],
                        [
                            -93.560342000139,
                            31.0977439998489
                        ],
                        [
                            -93.5631819996552,
                            31.0969879997398
                        ],
                        [
                            -93.5637459997198,
                            31.0945219998156
                        ],
                        [
                            -93.5585810003482,
                            31.0915040002306
                        ],
                        [
                            -93.5558149995535,
                            31.0869650003725
                        ],
                        [
                            -93.5522789997282,
                            31.0883269998875
                        ],
                        [
                            -93.5507139997559,
                            31.0909850000199
                        ],
                        [
                            -93.5492619997616,
                            31.0904120000075
                        ],
                        [
                            -93.5486990004202,
                            31.0886370003416
                        ],
                        [
                            -93.5524670000491,
                            31.082489000357
                        ],
                        [
                            -93.5522050001083,
                            31.0789309997539
                        ],
                        [
                            -93.5401299996711,
                            31.0779399996387
                        ],
                        [
                            -93.5326270000252,
                            31.0745520002702
                        ],
                        [
                            -93.5276450000534,
                            31.074510000045
                        ],
                        [
                            -93.5287320001747,
                            31.0692139998401
                        ],
                        [
                            -93.5251310000521,
                            31.0665510002961
                        ],
                        [
                            -93.5201259996157,
                            31.0658160003133
                        ],
                        [
                            -93.518912000171,
                            31.0618629998708
                        ],
                        [
                            -93.5211449999649,
                            31.0572749999618
                        ],
                        [
                            -93.5301289999175,
                            31.0568139999014
                        ],
                        [
                            -93.5322150002966,
                            31.0552359999977
                        ],
                        [
                            -93.5316279997674,
                            31.0536270001497
                        ],
                        [
                            -93.5277429999627,
                            31.048316999948
                        ],
                        [
                            -93.5188989997522,
                            31.0437360002895
                        ],
                        [
                            -93.5199409996677,
                            31.0418759999727
                        ],
                        [
                            -93.5240480001287,
                            31.0415200000885
                        ],
                        [
                            -93.5247390004149,
                            31.0390070003142
                        ],
                        [
                            -93.5215979997015,
                            31.0363009996329
                        ],
                        [
                            -93.5090250002202,
                            31.0328909998009
                        ],
                        [
                            -93.5081409995901,
                            31.0302449999509
                        ],
                        [
                            -93.5223140001021,
                            31.0200979998485
                        ],
                        [
                            -93.5324570001459,
                            31.0197190002018
                        ],
                        [
                            -93.5391840000151,
                            31.0082119997347
                        ],
                        [
                            -93.5493770002877,
                            31.0101430001241
                        ],
                        [
                            -93.5559269997065,
                            31.0037439999981
                        ],
                        [
                            -93.56656100002,
                            31.0130569999683
                        ],
                        [
                            -93.5714650001741,
                            31.0095989998976
                        ],
                        [
                            -93.5734229996084,
                            31.0038580003039
                        ],
                        [
                            -93.5782070001119,
                            30.9999949996977
                        ],
                        [
                            -93.5733649998821,
                            30.9918280000427
                        ],
                        [
                            -93.5642989999138,
                            30.9896999996303
                        ],
                        [
                            -93.566858000121,
                            30.9794029996308
                        ],
                        [
                            -93.5692950001294,
                            30.9761910001612
                        ],
                        [
                            -93.5743320003528,
                            30.973707999821
                        ],
                        [
                            -93.5735370003095,
                            30.9712060001099
                        ],
                        [
                            -93.5677369998298,
                            30.9689710002218
                        ],
                        [
                            -93.5605370001327,
                            30.9712890002478
                        ],
                        [
                            -93.5535399999268,
                            30.9673740000673
                        ],
                        [
                            -93.5498409998948,
                            30.9671179997795
                        ],
                        [
                            -93.547314000373,
                            30.9647549997777
                        ],
                        [
                            -93.5463269998108,
                            30.9578019996727
                        ],
                        [
                            -93.5431220004216,
                            30.9567400002869
                        ],
                        [
                            -93.5317580003621,
                            30.9582170001228
                        ],
                        [
                            -93.5305339999734,
                            30.9545779998064
                        ],
                        [
                            -93.5323669997342,
                            30.9489770002828
                        ],
                        [
                            -93.5298140002732,
                            30.9421719999814
                        ],
                        [
                            -93.526013000134,
                            30.9375350003417
                        ],
                        [
                            -93.527836999674,
                            30.9330660000682
                        ],
                        [
                            -93.5264740002664,
                            30.9294639997063
                        ],
                        [
                            -93.5307030000278,
                            30.9267780001478
                        ],
                        [
                            -93.532007999709,
                            30.9237539997409
                        ],
                        [
                            -93.5358320003128,
                            30.9236909998716
                        ],
                        [
                            -93.5434229998222,
                            30.9201530003431
                        ],
                        [
                            -93.5420729999351,
                            30.9258489999538
                        ],
                        [
                            -93.5457180004387,
                            30.9270999997813
                        ],
                        [
                            -93.549282999678,
                            30.9243719999649
                        ],
                        [
                            -93.5520849995594,
                            30.9181190000619
                        ],
                        [
                            -93.5585620000816,
                            30.913238000123
                        ],
                        [
                            -93.5580869997055,
                            30.9107969998337
                        ],
                        [
                            -93.5530260000909,
                            30.9081000000895
                        ],
                        [
                            -93.5539950002115,
                            30.9027730001875
                        ],
                        [
                            -93.5531159996043,
                            30.9010529996679
                        ],
                        [
                            -93.5486230002522,
                            30.8998939996827
                        ],
                        [
                            -93.5492020003855,
                            30.8974389998849
                        ],
                        [
                            -93.5663159997977,
                            30.8948389996653
                        ],
                        [
                            -93.5740799995594,
                            30.8845289998156
                        ],
                        [
                            -93.5706040000085,
                            30.8751930000479
                        ],
                        [
                            -93.5632329997089,
                            30.8748669999953
                        ],
                        [
                            -93.5645039999532,
                            30.8686400002912
                        ],
                        [
                            -93.55861599961,
                            30.8694239999899
                        ],
                        [
                            -93.5621470003107,
                            30.8593320003272
                        ],
                        [
                            -93.5573140003018,
                            30.85208099998
                        ],
                        [
                            -93.5687169998211,
                            30.8441820000985
                        ],
                        [
                            -93.5580239999563,
                            30.8400749997814
                        ],
                        [
                            -93.5536260001405,
                            30.8351400001601
                        ],
                        [
                            -93.5533109995979,
                            30.83254199993
                        ],
                        [
                            -93.5573200001496,
                            30.8284189996515
                        ],
                        [
                            -93.5540570001357,
                            30.8249410002046
                        ],
                        [
                            -93.5544329998794,
                            30.8235240002333
                        ],
                        [
                            -93.5641640001946,
                            30.8175410002634
                        ],
                        [
                            -93.5637630003366,
                            30.8136059996513
                        ],
                        [
                            -93.560707000012,
                            30.8096390002753
                        ],
                        [
                            -93.5629799999889,
                            30.8060090000324
                        ],
                        [
                            -93.5746770001343,
                            30.8013730003085
                        ],
                        [
                            -93.5815050002858,
                            30.8031049997108
                        ],
                        [
                            -93.5837749998896,
                            30.8017789998944
                        ],
                        [
                            -93.5840939997319,
                            30.7966889998885
                        ],
                        [
                            -93.5813880001099,
                            30.7916270002257
                        ],
                        [
                            -93.5819210002124,
                            30.7889790002889
                        ],
                        [
                            -93.5893810002006,
                            30.7866759996917
                        ],
                        [
                            -93.5831649997943,
                            30.7785959997148
                        ],
                        [
                            -93.5838150000726,
                            30.7770520003376
                        ],
                        [
                            -93.5900490000222,
                            30.7776619997906
                        ],
                        [
                            -93.5927139996363,
                            30.7750950003482
                        ],
                        [
                            -93.5921010000662,
                            30.7636750002291
                        ],
                        [
                            -93.6036629995941,
                            30.7601149999837
                        ],
                        [
                            -93.6059409995939,
                            30.7645930001112
                        ],
                        [
                            -93.606496000336,
                            30.7587969999343
                        ],
                        [
                            -93.6091779996685,
                            30.7572600002788
                        ],
                        [
                            -93.6123720000853,
                            30.7605969999855
                        ],
                        [
                            -93.6143460003115,
                            30.7601949996226
                        ],
                        [
                            -93.6147770003067,
                            30.7560640003036
                        ],
                        [
                            -93.6084139995873,
                            30.7466640000981
                        ],
                        [
                            -93.6099459999476,
                            30.7446490003731
                        ],
                        [
                            -93.6177530002654,
                            30.7432520002575
                        ],
                        [
                            -93.6190310001824,
                            30.7403379997134
                        ],
                        [
                            -93.612819999799,
                            30.7367070000001
                        ],
                        [
                            -93.6091340001858,
                            30.7300389997817
                        ],
                        [
                            -93.610952999703,
                            30.7278680003051
                        ],
                        [
                            -93.6153070000361,
                            30.7298159999566
                        ],
                        [
                            -93.6177189999302,
                            30.728896999842
                        ],
                        [
                            -93.6148929997593,
                            30.726787999627
                        ],
                        [
                            -93.6098139997031,
                            30.727339000303
                        ],
                        [
                            -93.6095440002647,
                            30.7231389998291
                        ],
                        [
                            -93.6136440001546,
                            30.7221430000203
                        ],
                        [
                            -93.605390999773,
                            30.7171589999069
                        ],
                        [
                            -93.6087199999091,
                            30.7152350000168
                        ],
                        [
                            -93.610046000405,
                            30.7182929999246
                        ],
                        [
                            -93.6125980000411,
                            30.7165319998723
                        ],
                        [
                            -93.6144469996955,
                            30.7198700003727
                        ],
                        [
                            -93.6172290003837,
                            30.7185799997022
                        ],
                        [
                            -93.6164369998152,
                            30.7123879998483
                        ],
                        [
                            -93.6246759999531,
                            30.6999369997898
                        ],
                        [
                            -93.6204420001689,
                            30.6941419998638
                        ],
                        [
                            -93.6266469998062,
                            30.6883569997809
                        ],
                        [
                            -93.6298870002538,
                            30.6799349997271
                        ],
                        [
                            -93.6313450000959,
                            30.6778720001579
                        ],
                        [
                            -93.6360540002563,
                            30.6763200001551
                        ],
                        [
                            -93.6382150000802,
                            30.6730600001946
                        ],
                        [
                            -93.6453059999974,
                            30.673480999815
                        ],
                        [
                            -93.6485840000799,
                            30.6676470003859
                        ],
                        [
                            -93.6514729999999,
                            30.6699399996538
                        ],
                        [
                            -93.6549720000153,
                            30.6701850002239
                        ],
                        [
                            -93.6597449997498,
                            30.6620519996224
                        ],
                        [
                            -93.6657529997437,
                            30.6615809996727
                        ],
                        [
                            -93.670358000147,
                            30.6580070003048
                        ],
                        [
                            -93.6705360004222,
                            30.6526260003785
                        ],
                        [
                            -93.6738379998958,
                            30.6512969999936
                        ],
                        [
                            -93.6747609999856,
                            30.6476830001444
                        ],
                        [
                            -93.6829719996361,
                            30.6412510003826
                        ],
                        [
                            -93.6804149999771,
                            30.6348770001353
                        ],
                        [
                            -93.6842110000934,
                            30.6318810003635
                        ],
                        [
                            -93.6851209997645,
                            30.6252009999961
                        ],
                        [
                            -93.6819830003224,
                            30.618583999833
                        ],
                        [
                            -93.6857020004458,
                            30.616417999925
                        ],
                        [
                            -93.6891339996158,
                            30.6114059996797
                        ],
                        [
                            -93.6833719996692,
                            30.6082489998815
                        ],
                        [
                            -93.6787670001642,
                            30.598628999804
                        ],
                        [
                            -93.6789489997391,
                            30.5936369997676
                        ],
                        [
                            -93.6836489996787,
                            30.593520000307
                        ],
                        [
                            -93.6850660004112,
                            30.5920860002015
                        ],
                        [
                            -93.6849330003418,
                            30.5871449999834
                        ],
                        [
                            -93.6865729997588,
                            30.5857199999341
                        ],
                        [
                            -93.6922219997274,
                            30.58570600031
                        ],
                        [
                            -93.6917510004475,
                            30.5879589996948
                        ],
                        [
                            -93.6878039998202,
                            30.5901380002707
                        ],
                        [
                            -93.6928689996327,
                            30.5943820001176
                        ],
                        [
                            -93.7008919998606,
                            30.5916429997226
                        ],
                        [
                            -93.7039080000022,
                            30.5884410003488
                        ],
                        [
                            -93.7113179997617,
                            30.5889059997894
                        ],
                        [
                            -93.7137820004326,
                            30.5875839997744
                        ],
                        [
                            -93.7150679998472,
                            30.5790180002954
                        ],
                        [
                            -93.7179109997365,
                            30.5777670000233
                        ],
                        [
                            -93.7214929995926,
                            30.5805529998471
                        ],
                        [
                            -93.7278439997181,
                            30.5740699996459
                        ],
                        [
                            -93.7261649999431,
                            30.5695200000374
                        ],
                        [
                            -93.7278179997788,
                            30.5659190002604
                        ],
                        [
                            -93.7243000003952,
                            30.562014999895
                        ],
                        [
                            -93.7239449996696,
                            30.5592240003503
                        ],
                        [
                            -93.7314230000994,
                            30.5528610002373
                        ],
                        [
                            -93.7287600001352,
                            30.5452959998165
                        ],
                        [
                            -93.7402530000661,
                            30.5395689998393
                        ],
                        [
                            -93.7380529998842,
                            30.537309000135
                        ],
                        [
                            -93.7324159996111,
                            30.5363660001585
                        ],
                        [
                            -93.7338209997498,
                            30.5317350003146
                        ],
                        [
                            -93.732793000078,
                            30.5299599996167
                        ],
                        [
                            -93.7277220004177,
                            30.5256700002698
                        ],
                        [
                            -93.7204840001874,
                            30.52432799966
                        ],
                        [
                            -93.7164729999859,
                            30.5192309996738
                        ],
                        [
                            -93.7128700002134,
                            30.5186130003714
                        ],
                        [
                            -93.709155000288,
                            30.5218450000321
                        ],
                        [
                            -93.7076830002021,
                            30.5213370001057
                        ],
                        [
                            -93.711022000384,
                            30.5172749999895
                        ],
                        [
                            -93.7098919997066,
                            30.5138509999742
                        ],
                        [
                            -93.7117620001757,
                            30.5109149999267
                        ],
                        [
                            -93.7076530000649,
                            30.5110660000836
                        ],
                        [
                            -93.7068089996178,
                            30.507068999687
                        ],
                        [
                            -93.7106199998027,
                            30.5061980000448
                        ],
                        [
                            -93.7151449998401,
                            30.50126800005
                        ],
                        [
                            -93.714123999841,
                            30.4999969996419
                        ],
                        [
                            -93.7085359999718,
                            30.5003970003117
                        ],
                        [
                            -93.7086690000411,
                            30.4991240000607
                        ],
                        [
                            -93.7103949996719,
                            30.4974110000644
                        ],
                        [
                            -93.7153769996437,
                            30.4972700001538
                        ],
                        [
                            -93.7170689998376,
                            30.4944489999678
                        ],
                        [
                            -93.7102470004321,
                            30.4862529998119
                        ],
                        [
                            -93.7097629998352,
                            30.4826490002901
                        ],
                        [
                            -93.7117270000156,
                            30.4822989998158
                        ],
                        [
                            -93.7148300001959,
                            30.485234999735
                        ],
                        [
                            -93.7174210001901,
                            30.4838220001989
                        ],
                        [
                            -93.7168110000947,
                            30.4814949996624
                        ],
                        [
                            -93.7105190004187,
                            30.4754980000518
                        ],
                        [
                            -93.7118540002372,
                            30.4694370002391
                        ],
                        [
                            -93.7063769997977,
                            30.466904000143
                        ],
                        [
                            -93.6990359996352,
                            30.4588050002658
                        ],
                        [
                            -93.7003589997581,
                            30.4575319996149
                        ],
                        [
                            -93.7063390001629,
                            30.4581410002964
                        ],
                        [
                            -93.7069359998394,
                            30.4553409999314
                        ],
                        [
                            -93.7021029998305,
                            30.4497589999268
                        ],
                        [
                            -93.7074649997439,
                            30.4450449999754
                        ],
                        [
                            -93.7046329997253,
                            30.4431179997752
                        ],
                        [
                            -93.6983059998892,
                            30.4449279996311
                        ],
                        [
                            -93.6975779997931,
                            30.4422330000409
                        ],
                        [
                            -93.6989269998552,
                            30.437233999705
                        ],
                        [
                            -93.7041540000495,
                            30.4343530003172
                        ],
                        [
                            -93.7023289997863,
                            30.4298400000057
                        ],
                        [
                            -93.7082549997643,
                            30.4302520003703
                        ],
                        [
                            -93.7127470001898,
                            30.433174999835
                        ],
                        [
                            -93.7140390003505,
                            30.4297289998505
                        ],
                        [
                            -93.716904999806,
                            30.4276789997403
                        ],
                        [
                            -93.7163690002288,
                            30.4247549997706
                        ],
                        [
                            -93.7180510003769,
                            30.4225790000228
                        ],
                        [
                            -93.72276700021,
                            30.431982999754
                        ],
                        [
                            -93.7261060003918,
                            30.4307200000871
                        ],
                        [
                            -93.7268339995896,
                            30.4236419996327
                        ],
                        [
                            -93.7227439997455,
                            30.4213690003002
                        ],
                        [
                            -93.7227489997683,
                            30.4195569999297
                        ],
                        [
                            -93.7275480003404,
                            30.4187199997314
                        ],
                        [
                            -93.7287209997772,
                            30.4135470003082
                        ],
                        [
                            -93.7339170000092,
                            30.4149350002514
                        ],
                        [
                            -93.7367640000965,
                            30.4054779997716
                        ],
                        [
                            -93.7416790001212,
                            30.402975000172
                        ],
                        [
                            -93.7456810001019,
                            30.3965700001136
                        ],
                        [
                            -93.7510160002512,
                            30.3964840002063
                        ],
                        [
                            -93.7526149996602,
                            30.3927499996698
                        ],
                        [
                            -93.7576679997772,
                            30.3904409997156
                        ],
                        [
                            -93.7586119997834,
                            30.3861860001287
                        ],
                        [
                            -93.7548220004132,
                            30.3863129999365
                        ],
                        [
                            -93.7584660001936,
                            30.3840330001685
                        ],
                        [
                            -93.7552259997459,
                            30.378848000027
                        ],
                        [
                            -93.754856999675,
                            30.3660910001617
                        ],
                        [
                            -93.7564950003405,
                            30.3662529998776
                        ],
                        [
                            -93.7580510000919,
                            30.3696920002182
                        ],
                        [
                            -93.759216000031,
                            30.3680200001764
                        ],
                        [
                            -93.7576870000438,
                            30.3645809999397
                        ],
                        [
                            -93.7509790004412,
                            30.3598989996874
                        ],
                        [
                            -93.7553670002113,
                            30.3595820000623
                        ],
                        [
                            -93.756803000312,
                            30.3561580000628
                        ],
                        [
                            -93.7589299998008,
                            30.360964999933
                        ],
                        [
                            -93.7607790003534,
                            30.3598500000106
                        ],
                        [
                            -93.7609380003621,
                            30.3571120002911
                        ],
                        [
                            -93.758118000039,
                            30.3546860002905
                        ],
                        [
                            -93.7624799998698,
                            30.3530389999
                        ],
                        [
                            -93.7577180000059,
                            30.3499769996565
                        ],
                        [
                            -93.7636549998547,
                            30.3460399998575
                        ],
                        [
                            -93.7595330002234,
                            30.3423229997583
                        ],
                        [
                            -93.7643560001866,
                            30.344447999784
                        ],
                        [
                            -93.7656550000201,
                            30.3434090001167
                        ],
                        [
                            -93.7630890001402,
                            30.3394570001867
                        ],
                        [
                            -93.7586579998142,
                            30.3390189996185
                        ],
                        [
                            -93.7658290000973,
                            30.3327449999427
                        ],
                        [
                            -93.7629579997207,
                            30.3294479999211
                        ],
                        [
                            -93.7572739995919,
                            30.3323540002575
                        ],
                        [
                            -93.7555839999462,
                            30.3315050003784
                        ],
                        [
                            -93.7552440001876,
                            30.3283870002485
                        ],
                        [
                            -93.7516759996769,
                            30.3293470003844
                        ],
                        [
                            -93.7561980002396,
                            30.326004000387
                        ],
                        [
                            -93.7507109997543,
                            30.3217530000455
                        ],
                        [
                            -93.7510410003655,
                            30.3198519996343
                        ],
                        [
                            -93.7478150001616,
                            30.3187559998625
                        ],
                        [
                            -93.7501810000249,
                            30.3170099998918
                        ],
                        [
                            -93.7468679997823,
                            30.3153760001068
                        ],
                        [
                            -93.74860900038,
                            30.3119449996619
                        ],
                        [
                            -93.7456479995916,
                            30.3117439996962
                        ],
                        [
                            -93.7409180004131,
                            30.3072299996381
                        ],
                        [
                            -93.7443520001312,
                            30.3054509998304
                        ],
                        [
                            -93.7424239999358,
                            30.3011390001882
                        ],
                        [
                            -93.7385199998644,
                            30.3037450002579
                        ],
                        [
                            -93.7345460003712,
                            30.3016749997789
                        ],
                        [
                            -93.7375559997668,
                            30.2993690001208
                        ],
                        [
                            -93.7329060000559,
                            30.2989930001485
                        ],
                        [
                            -93.7290460003655,
                            30.2967050002285
                        ],
                        [
                            -93.7255110003651,
                            30.2982260001305
                        ],
                        [
                            -93.7242080003337,
                            30.2951119996885
                        ],
                        [
                            -93.7189819999643,
                            30.296245999874
                        ],
                        [
                            -93.7154129996287,
                            30.2927639996881
                        ],
                        [
                            -93.7155209995837,
                            30.295628999671
                        ],
                        [
                            -93.7122690003389,
                            30.2923309997005
                        ],
                        [
                            -93.7136529996628,
                            30.290002999978
                        ],
                        [
                            -93.7129079998483,
                            30.2879390003084
                        ],
                        [
                            -93.7104390000528,
                            30.2877649997247
                        ],
                        [
                            -93.7083540003969,
                            30.2925629999531
                        ],
                        [
                            -93.7045840002198,
                            30.2899490001904
                        ],
                        [
                            -93.7052539996913,
                            30.2861810003854
                        ],
                        [
                            -93.7090530001807,
                            30.2838740003046
                        ],
                        [
                            -93.7094189998786,
                            30.28191699972
                        ],
                        [
                            -93.7040600003382,
                            30.279417000372
                        ],
                        [
                            -93.7065259997606,
                            30.2735010003051
                        ],
                        [
                            -93.7104159995882,
                            30.2731720000523
                        ],
                        [
                            -93.707756999822,
                            30.2715690002284
                        ],
                        [
                            -93.7086659996681,
                            30.2674280001754
                        ],
                        [
                            -93.705452000058,
                            30.2677560002043
                        ],
                        [
                            -93.708818999829,
                            30.2659229999545
                        ],
                        [
                            -93.706217999789,
                            30.2654769996753
                        ],
                        [
                            -93.7079789995798,
                            30.2636859999584
                        ],
                        [
                            -93.7072659995523,
                            30.2614710002499
                        ],
                        [
                            -93.7029830002626,
                            30.2605169997685
                        ],
                        [
                            -93.7063009996297,
                            30.2529559998772
                        ],
                        [
                            -93.7089279996089,
                            30.2516530001691
                        ],
                        [
                            -93.7073259998268,
                            30.2496680001482
                        ],
                        [
                            -93.7090499998077,
                            30.2480479998744
                        ],
                        [
                            -93.7055419995714,
                            30.2465020002003
                        ],
                        [
                            -93.7095310000316,
                            30.2449319997758
                        ],
                        [
                            -93.7050019997963,
                            30.2423509998651
                        ],
                        [
                            -93.7030989997152,
                            30.2384589998242
                        ],
                        [
                            -93.7078120000736,
                            30.2396240000134
                        ],
                        [
                            -93.7094499998407,
                            30.2385140003735
                        ],
                        [
                            -93.7044899996102,
                            30.2337870002519
                        ],
                        [
                            -93.7095129995899,
                            30.2335480001324
                        ],
                        [
                            -93.7097489995915,
                            30.2355119997954
                        ],
                        [
                            -93.7110979996536,
                            30.2352470002024
                        ],
                        [
                            -93.7134960002023,
                            30.2288999997608
                        ],
                        [
                            -93.7095090002903,
                            30.2278339997436
                        ],
                        [
                            -93.7136419997922,
                            30.2246559997009
                        ],
                        [
                            -93.7111760003697,
                            30.2220700002883
                        ],
                        [
                            -93.7130579996361,
                            30.2186409997121
                        ],
                        [
                            -93.7160649995569,
                            30.2209339999308
                        ],
                        [
                            -93.7184630001056,
                            30.2199980002951
                        ],
                        [
                            -93.7206890002268,
                            30.2154810000631
                        ],
                        [
                            -93.7170200003321,
                            30.2161309998964
                        ],
                        [
                            -93.7195569998996,
                            30.2147510000629
                        ],
                        [
                            -93.7171190000663,
                            30.2120400003826
                        ],
                        [
                            -93.7212590001392,
                            30.2096109998559
                        ],
                        [
                            -93.7174379999085,
                            30.2062080002619
                        ],
                        [
                            -93.7141919996131,
                            30.2086590003849
                        ],
                        [
                            -93.7146169997605,
                            30.2023829999987
                        ],
                        [
                            -93.7179040000638,
                            30.2007229999568
                        ],
                        [
                            -93.7147549998528,
                            30.1974039997663
                        ],
                        [
                            -93.7176479999708,
                            30.1948879999502
                        ],
                        [
                            -93.7175230002973,
                            30.193084999843
                        ],
                        [
                            -93.7132269996905,
                            30.1932450003451
                        ],
                        [
                            -93.7091209999528,
                            30.197207999863
                        ],
                        [
                            -93.7054949997158,
                            30.1929210003786
                        ],
                        [
                            -93.7089920000813,
                            30.1909589999807
                        ],
                        [
                            -93.7125520001961,
                            30.1924559998724
                        ],
                        [
                            -93.7124230003247,
                            30.1871719997028
                        ],
                        [
                            -93.7087950004378,
                            30.1863530002415
                        ],
                        [
                            -93.7106840002752,
                            30.1798619997106
                        ],
                        [
                            -93.7056210001124,
                            30.1806489997447
                        ],
                        [
                            -93.7065380003545,
                            30.1769789999873
                        ],
                        [
                            -93.7042400002632,
                            30.1741849999128
                        ],
                        [
                            -93.6999529998772,
                            30.1755289998419
                        ],
                        [
                            -93.702999999981,
                            30.1716949997978
                        ],
                        [
                            -93.7026579996742,
                            30.1678240003582
                        ],
                        [
                            -93.7008170004158,
                            30.1662260001341
                        ],
                        [
                            -93.7030500002097,
                            30.1612309997569
                        ],
                        [
                            -93.7069960001139,
                            30.1574649997968
                        ],
                        [
                            -93.7061540002149,
                            30.1505960003054
                        ],
                        [
                            -93.7095079995671,
                            30.1466720002668
                        ],
                        [
                            -93.6977369998018,
                            30.1529460000816
                        ],
                        [
                            -93.693303000001,
                            30.1418990003371
                        ],
                        [
                            -93.6882049996782,
                            30.1413569997866
                        ],
                        [
                            -93.6928490004395,
                            30.1352130000858
                        ],
                        [
                            -93.6949519996388,
                            30.1351810002757
                        ],
                        [
                            -93.6982740001022,
                            30.1386189996465
                        ],
                        [
                            -93.7012099998779,
                            30.1374579998802
                        ],
                        [
                            -93.7008719997691,
                            30.1231140001615
                        ],
                        [
                            -93.7079540003638,
                            30.1200160002919
                        ],
                        [
                            -93.7034119997097,
                            30.1171849998996
                        ],
                        [
                            -93.7024359999164,
                            30.1127209996592
                        ],
                        [
                            -93.7042600003547,
                            30.110657000344
                        ],
                        [
                            -93.712260999943,
                            30.1090410003374
                        ],
                        [
                            -93.7154929999947,
                            30.100529000117
                        ],
                        [
                            -93.7237649997446,
                            30.0941299998858
                        ],
                        [
                            -93.7240049999441,
                            30.0826259996408
                        ],
                        [
                            -93.726858999704,
                            30.082268000204
                        ],
                        [
                            -93.7310790001429,
                            30.0895179998963
                        ],
                        [
                            -93.7340850002387,
                            30.0861299996316
                        ],
                        [
                            -93.7324909999542,
                            30.082589000202
                        ],
                        [
                            -93.7259099996749,
                            30.0784690001939
                        ],
                        [
                            -93.7170079997382,
                            30.0695829998461
                        ],
                        [
                            -93.703104999563,
                            30.0662609997283
                        ],
                        [
                            -93.700579999691,
                            30.063683000284
                        ],
                        [
                            -93.6995160000342,
                            30.0585780001891
                        ],
                        [
                            -93.7041509996765,
                            30.0542080002571
                        ],
                        [
                            -93.7110740002625,
                            30.0543910000077
                        ],
                        [
                            -93.7137720003868,
                            30.0656870003497
                        ],
                        [
                            -93.7178839999723,
                            30.0655539998291
                        ],
                        [
                            -93.7194530001425,
                            30.0638080001132
                        ],
                        [
                            -93.7208049996794,
                            30.0530430003866
                        ],
                        [
                            -93.7374460001619,
                            30.0372829999182
                        ],
                        [
                            -93.7410780002467,
                            30.0215710001384
                        ],
                        [
                            -93.7566300000597,
                            30.0141629997094
                        ],
                        [
                            -93.7672390002589,
                            30.003380000091
                        ],
                        [
                            -93.7705669996717,
                            29.996531999776
                        ],
                        [
                            -93.7808229996935,
                            29.9942760000601
                        ],
                        [
                            -93.7885990000492,
                            29.9888359999977
                        ],
                        [
                            -93.7944389998135,
                            29.974773000384
                        ],
                        [
                            -93.807814999734,
                            29.9545490000631
                        ],
                        [
                            -93.8172859997582,
                            29.9184219999709
                        ],
                        [
                            -93.8303739997986,
                            29.8943579998932
                        ],
                        [
                            -93.838373999562,
                            29.8828530001537
                        ],
                        [
                            -93.8551399997179,
                            29.8640990001343
                        ],
                        [
                            -93.8635699996516,
                            29.8571769997172
                        ],
                        [
                            -93.895574999626,
                            29.8402469998272
                        ],
                        [
                            -93.9136720003008,
                            29.8271920001291
                        ],
                        [
                            -93.9227440001169,
                            29.8188079997553
                        ],
                        [
                            -93.9279920002275,
                            29.8096400000304
                        ],
                        [
                            -93.9288080001872,
                            29.7970799997555
                        ],
                        [
                            -93.9265040002482,
                            29.7895599997335
                        ],
                        [
                            -93.922406999833,
                            29.7850479999584
                        ],
                        [
                            -93.8984700002922,
                            29.7715769998118
                        ],
                        [
                            -93.893863000239,
                            29.767294000125
                        ],
                        [
                            -93.8908210001581,
                            29.7616729998417
                        ],
                        [
                            -93.8938289999038,
                            29.7530329999048
                        ],
                        [
                            -93.8916370001178,
                            29.7446180002508
                        ],
                        [
                            -93.8888209999927,
                            29.7422340002389
                        ],
                        [
                            -93.8700200004096,
                            29.7354820000051
                        ],
                        [
                            -93.8632039999537,
                            29.7240590000639
                        ],
                        [
                            -93.8374120000124,
                            29.6898849997183
                        ],
                        [
                            -93.8287080004424,
                            29.6595350002561
                        ],
                        [
                            -93.8143509998075,
                            29.596575999859
                        ],
                        [
                            -93.8364909995724,
                            29.5968450002877
                        ],
                        [
                            -93.8485879997509,
                            29.6002600000042
                        ],
                        [
                            -93.8579829996072,
                            29.6050870000444
                        ],
                        [
                            -93.8665310004399,
                            29.6118340000968
                        ],
                        [
                            -93.8750059996811,
                            29.6214260002922
                        ],
                        [
                            -93.9060430002583,
                            29.6250009998384
                        ],
                        [
                            -94.0335650002139,
                            29.6250020001545
                        ],
                        [
                            -94.1251799997735,
                            29.5955590003185
                        ],
                        [
                            -94.3703509999776,
                            29.5002299997794
                        ],
                        [
                            -94.3751870003596,
                            29.500131000061
                        ],
                        [
                            -94.4192490003296,
                            29.4834519996434
                        ],
                        [
                            -94.4994520001491,
                            29.4523370003849
                        ],
                        [
                            -94.6251920003976,
                            29.3976620002931
                        ],
                        [
                            -94.6503109995959,
                            29.3828200001607
                        ],
                        [
                            -94.6435550003128,
                            29.378485999872
                        ],
                        [
                            -94.6360459999208,
                            29.3706359998082
                        ],
                        [
                            -94.6285850001077,
                            29.3582790002261
                        ],
                        [
                            -94.6238979996887,
                            29.3431429998195
                        ],
                        [
                            -94.6241689998503,
                            29.3351980001539
                        ],
                        [
                            -94.6290260001487,
                            29.3216400002983
                        ],
                        [
                            -94.6424210003358,
                            29.3001360002185
                        ],
                        [
                            -94.6503599999997,
                            29.2918899999514
                        ],
                        [
                            -94.6662199997843,
                            29.2810469998728
                        ],
                        [
                            -94.6802950003869,
                            29.2776030000595
                        ],
                        [
                            -94.6923089998265,
                            29.2770509997888
                        ],
                        [
                            -94.7133110003148,
                            29.2825209999564
                        ],
                        [
                            -94.7411660001707,
                            29.2595829997453
                        ],
                        [
                            -94.7507400001271,
                            29.2469280000853
                        ],
                        [
                            -94.7726859997233,
                            29.2317179999822
                        ],
                        [
                            -94.840151000205,
                            29.1983459998197
                        ],
                        [
                            -94.9121709999631,
                            29.1593180002112
                        ],
                        [
                            -94.9636610001343,
                            29.1252410002662
                        ],
                        [
                            -95.0002020000168,
                            29.1044520002106
                        ],
                        [
                            -95.0863979997615,
                            29.0682400002442
                        ],
                        [
                            -95.0916219995827,
                            29.062739000085
                        ],
                        [
                            -95.0872570002772,
                            29.0579960002991
                        ],
                        [
                            -95.0937990001984,
                            29.0333270003273
                        ],
                        [
                            -95.1065280002137,
                            29.0149349997732
                        ],
                        [
                            -95.1257699998378,
                            29.0029889996505
                        ],
                        [
                            -95.1394689997985,
                            28.9916969999512
                        ],
                        [
                            -95.1808350001923,
                            28.948931000328
                        ],
                        [
                            -95.2272279998656,
                            28.910378999692
                        ],
                        [
                            -95.2527910003763,
                            28.8925999998418
                        ],
                        [
                            -95.2908119998422,
                            28.8716430000912
                        ],
                        [
                            -95.3081210001464,
                            28.8588299998406
                        ],
                        [
                            -95.3101990001296,
                            28.8509649997276
                        ],
                        [
                            -95.3154220001259,
                            28.8422089996253
                        ],
                        [
                            -95.32211300001,
                            28.8344420001454
                        ],
                        [
                            -95.3298469996346,
                            28.8286990003791
                        ],
                        [
                            -95.3534710000792,
                            28.8178629997396
                        ],
                        [
                            -95.3779869997533,
                            28.810279000059
                        ],
                        [
                            -95.4011590002863,
                            28.8059689998203
                        ],
                        [
                            -95.4200710001975,
                            28.8056250001
                        ],
                        [
                            -95.4938879997314,
                            28.76534000025
                        ],
                        [
                            -95.5337839996893,
                            28.7502559997637
                        ],
                        [
                            -95.5320189997005,
                            28.7502559997637
                        ],
                        [
                            -95.7778450002059,
                            28.625266000044
                        ],
                        [
                            -95.8752400001537,
                            28.5827880003781
                        ],
                        [
                            -96.0002430000851,
                            28.5320710001053
                        ],
                        [
                            -96.0002430000851,
                            28.5334330000403
                        ],
                        [
                            -96.0783310001115,
                            28.5000010000937
                        ],
                        [
                            -96.1822420000412,
                            28.4520360003618
                        ],
                        [
                            -96.2354579998431,
                            28.4214690000721
                        ],
                        [
                            -96.258190999985,
                            28.4078720001503
                        ],
                        [
                            -96.2598060001859,
                            28.4006989999258
                        ],
                        [
                            -96.2670689996323,
                            28.3882289996196
                        ],
                        [
                            -96.2823069996259,
                            28.3750019999546
                        ],
                        [
                            -96.2941750003739,
                            28.3683050001802
                        ],
                        [
                            -96.3035330004203,
                            28.3650199997087
                        ],
                        [
                            -96.3207620003585,
                            28.3628679999656
                        ],
                        [
                            -96.3287400003805,
                            28.3520529997754
                        ],
                        [
                            -96.3418069996062,
                            28.3423810001254
                        ],
                        [
                            -96.3447309996863,
                            28.3259890001617
                        ],
                        [
                            -96.3526860001421,
                            28.3129229997242
                        ],
                        [
                            -96.3752519998794,
                            28.2907599998316
                        ],
                        [
                            -96.3956450000973,
                            28.2795240001032
                        ],
                        [
                            -96.4258070002652,
                            28.2686870002029
                        ],
                        [
                            -96.53100000031,
                            28.2211640000271
                        ],
                        [
                            -96.6407490003543,
                            28.155390000006
                        ],
                        [
                            -96.7462510001955,
                            28.0796220000899
                        ],
                        [
                            -96.8441959999642,
                            28.0002959997609
                        ],
                        [
                            -96.8752639996052,
                            27.9699200001341
                        ],
                        [
                            -96.9087430002137,
                            27.9340909996455
                        ],
                        [
                            -96.9385460003564,
                            27.9011989998557
                        ],
                        [
                            -96.9594950002429,
                            27.8753019997917
                        ],
                        [
                            -96.9842810002537,
                            27.8037829996363
                        ],
                        [
                            -96.9916800001425,
                            27.7971659996226
                        ],
                        [
                            -97.0088980002101,
                            27.786790000345
                        ],
                        [
                            -97.0218549998309,
                            27.7833639999916
                        ],
                        [
                            -97.0502210000479,
                            27.7503079998674
                        ],
                        [
                            -97.1252650003436,
                            27.6405969998914
                        ],
                        [
                            -97.165096999829,
                            27.5682999996063
                        ],
                        [
                            -97.1998159998214,
                            27.5003190000765
                        ],
                        [
                            -97.250264000077,
                            27.3923080001182
                        ],
                        [
                            -97.2883370003198,
                            27.2775840002549
                        ],
                        [
                            -97.2965670002369,
                            27.2503229999226
                        ],
                        [
                            -97.3093630001993,
                            27.1961089997199
                        ],
                        [
                            -97.3198240002605,
                            27.1100229996624
                        ],
                        [
                            -97.3231500000235,
                            27.0511459998444
                        ],
                        [
                            -97.3232189996205,
                            27.0102559998562
                        ],
                        [
                            -97.3211480002083,
                            27.0003299999663
                        ],
                        [
                            -97.3138790000158,
                            26.9131259999561
                        ],
                        [
                            -97.3004229997294,
                            26.8346130002419
                        ],
                        [
                            -97.2827250001611,
                            26.7634229996442
                        ],
                        [
                            -97.2517050002007,
                            26.6618659997575
                        ],
                        [
                            -97.2244289995798,
                            26.5976940000504
                        ],
                        [
                            -97.2140560002804,
                            26.5767659996959
                        ],
                        [
                            -97.2125480002096,
                            26.5616510001884
                        ],
                        [
                            -97.1845660001322,
                            26.4758520003919
                        ],
                        [
                            -97.1564960001912,
                            26.375348000107
                        ],
                        [
                            -97.1252479997268,
                            26.24828499964
                        ],
                        [
                            -97.1106990003696,
                            26.1453669999498
                        ],
                        [
                            -97.1083009998209,
                            26.1045099998343
                        ],
                        [
                            -97.0973860001983,
                            26.093507999637
                        ],
                        [
                            -97.0899889999593,
                            26.0753399998947
                        ],
                        [
                            -97.0893400004042,
                            26.0661150001647
                        ],
                        [
                            -97.0912869999678,
                            26.0515979996753
                        ],
                        [
                            -97.0941430002759,
                            26.0430439999025
                        ],
                        [
                            -97.0978580002014,
                            26.0386199998948
                        ],
                        [
                            -97.0911210002865,
                            25.9738400003307
                        ],
                        [
                            -97.1466540004462,
                            25.970969000114
                        ],
                        [
                            -97.1461049995519,
                            25.9566569998429
                        ],
                        [
                            -97.1472779998869,
                            25.9539909997386
                        ],
                        [
                            -97.156605999796,
                            25.949019999732
                        ],
                        [
                            -97.1604620001868,
                            25.9509850003899
                        ],
                        [
                            -97.1588160000237,
                            25.9622199996527
                        ],
                        [
                            -97.1669499996813,
                            25.9602249997503
                        ],
                        [
                            -97.1755839998295,
                            25.9655729996738
                        ],
                        [
                            -97.1770879997023,
                            25.9653460001816
                        ],
                        [
                            -97.1796739996737,
                            25.9607490002602
                        ],
                        [
                            -97.1870790003086,
                            25.9584829996479
                        ],
                        [
                            -97.1871429998827,
                            25.9552269996395
                        ],
                        [
                            -97.1895869995638,
                            25.9537949999239
                        ],
                        [
                            -97.197887999626,
                            25.9579259997877
                        ],
                        [
                            -97.2054809996835,
                            25.9582870000441
                        ],
                        [
                            -97.2065610001322,
                            25.9632309997632
                        ],
                        [
                            -97.2090209997068,
                            25.9637220000591
                        ],
                        [
                            -97.2209220000668,
                            25.9572719997586
                        ],
                        [
                            -97.2280260004028,
                            25.9589369996358
                        ],
                        [
                            -97.2398719996128,
                            25.9549750001563
                        ],
                        [
                            -97.2480329999329,
                            25.9480969998157
                        ],
                        [
                            -97.2608640000554,
                            25.9509329998848
                        ],
                        [
                            -97.2767010002738,
                            25.9521460003101
                        ],
                        [
                            -97.2821970000815,
                            25.9588069998431
                        ],
                        [
                            -97.284445999769,
                            25.959156000105
                        ],
                        [
                            -97.2896379998031,
                            25.9540279996052
                        ],
                        [
                            -97.2847410002201,
                            25.9494869997553
                        ],
                        [
                            -97.2785280001868,
                            25.9474650002916
                        ],
                        [
                            -97.2810370001652,
                            25.9434569997012
                        ],
                        [
                            -97.2774850004464,
                            25.939905000054
                        ],
                        [
                            -97.2766960002509,
                            25.9353389996981
                        ],
                        [
                            -97.2917860001065,
                            25.9363020003311
                        ],
                        [
                            -97.2961079997543,
                            25.9334249997758
                        ],
                        [
                            -97.2981499997525,
                            25.9338650001332
                        ],
                        [
                            -97.2996150001656,
                            25.9368649996788
                        ],
                        [
                            -97.3025409998956,
                            25.9372300002662
                        ],
                        [
                            -97.3045899995665,
                            25.9351560001952
                        ],
                        [
                            -97.304505000076,
                            25.9288380000725
                        ],
                        [
                            -97.3069029997263,
                            25.9270099999073
                        ],
                        [
                            -97.3108560002015,
                            25.9269029997767
                        ],
                        [
                            -97.3151770000243,
                            25.931467999732
                        ],
                        [
                            -97.3205670004252,
                            25.9298040001869
                        ],
                        [
                            -97.3249159998372,
                            25.9240359996407
                        ],
                        [
                            -97.3246510004216,
                            25.9185919997461
                        ],
                        [
                            -97.3260029999585,
                            25.9176890001537
                        ],
                        [
                            -97.3312410000234,
                            25.9185560003135
                        ],
                        [
                            -97.3336229997803,
                            25.9261120003056
                        ],
                        [
                            -97.3344830001209,
                            25.9202030002702
                        ],
                        [
                            -97.3378339999983,
                            25.9204770001964
                        ],
                        [
                            -97.336818999847,
                            25.9294490000198
                        ],
                        [
                            -97.3425070001737,
                            25.9289850002144
                        ],
                        [
                            -97.3482740001431,
                            25.9311430001022
                        ],
                        [
                            -97.3503939999591,
                            25.9252370000114
                        ],
                        [
                            -97.3554410002283,
                            25.919428000177
                        ],
                        [
                            -97.3615000002757,
                            25.9158680003137
                        ],
                        [
                            -97.3685169996748,
                            25.915326000288
                        ],
                        [
                            -97.3684199995904,
                            25.9096710000396
                        ],
                        [
                            -97.3734900003241,
                            25.90925200039
                        ],
                        [
                            -97.3748440004092,
                            25.90653599976
                        ],
                        [
                            -97.3659759999093,
                            25.9024470001505
                        ],
                        [
                            -97.3672620002223,
                            25.8938860003584
                        ],
                        [
                            -97.3662870002539,
                            25.8908199998966
                        ],
                        [
                            -97.3584670004157,
                            25.8896390000729
                        ],
                        [
                            -97.3566289997336,
                            25.8876810001817
                        ],
                        [
                            -97.3690420002797,
                            25.8840509997545
                        ],
                        [
                            -97.3741299996584,
                            25.8790390003299
                        ],
                        [
                            -97.363822999583,
                            25.8779169996111
                        ],
                        [
                            -97.3594969997373,
                            25.8796830002773
                        ],
                        [
                            -97.3591920001388,
                            25.8724260002251
                        ],
                        [
                            -97.3566930002061,
                            25.8673539997513
                        ],
                        [
                            -97.3587519999227,
                            25.8657080000741
                        ],
                        [
                            -97.3593639996679,
                            25.8692089999311
                        ],
                        [
                            -97.3614550000699,
                            25.8692999996176
                        ],
                        [
                            -97.3691440003869,
                            25.858453000033
                        ],
                        [
                            -97.3757719996236,
                            25.8564369997875
                        ],
                        [
                            -97.3765750000628,
                            25.8545779996807
                        ],
                        [
                            -97.3665829996316,
                            25.853563999943
                        ],
                        [
                            -97.3640999995925,
                            25.8500339999754
                        ],
                        [
                            -97.3707779999561,
                            25.8496560000871
                        ],
                        [
                            -97.3731870003755,
                            25.8399559999094
                        ],
                        [
                            -97.3820630003729,
                            25.8397599997977
                        ],
                        [
                            -97.3871380002312,
                            25.8438530000702
                        ],
                        [
                            -97.3890749997491,
                            25.8397490001737
                        ],
                        [
                            -97.3933660003331,
                            25.8373949997035
                        ],
                        [
                            -97.399654999636,
                            25.8392119997918
                        ],
                        [
                            -97.4055370001314,
                            25.8377539999273
                        ],
                        [
                            -97.4069330000493,
                            25.8400719998061
                        ],
                        [
                            -97.4022719995695,
                            25.840341000021
                        ],
                        [
                            -97.4004100003946,
                            25.8419729998622
                        ],
                        [
                            -97.4007760000925,
                            25.8461980000616
                        ],
                        [
                            -97.3987440001401,
                            25.8504900004014
                        ],
                        [
                            -97.4022530002012,
                            25.8519289999364
                        ],
                        [
                            -97.4057980002473,
                            25.8452920003225
                        ],
                        [
                            -97.4082469999513,
                            25.845632999702
                        ],
                        [
                            -97.4105640003092,
                            25.8499639997579
                        ],
                        [
                            -97.4081049996611,
                            25.8547290001191
                        ],
                        [
                            -97.4088059999931,
                            25.8621090001993
                        ],
                        [
                            -97.4113140001466,
                            25.8613420003973
                        ],
                        [
                            -97.4139260000572,
                            25.8565940000995
                        ],
                        [
                            -97.4145789998103,
                            25.8477290002511
                        ],
                        [
                            -97.4122229999927,
                            25.8426490000744
                        ],
                        [
                            -97.4130549998459,
                            25.8408809996309
                        ],
                        [
                            -97.4260900001829,
                            25.8409469999622
                        ],
                        [
                            -97.4360459997307,
                            25.84982700009
                        ],
                        [
                            -97.4433619997788,
                            25.8481799996557
                        ],
                        [
                            -97.4458490000159,
                            25.851372999601
                        ],
                        [
                            -97.4441159998142,
                            25.8547499998275
                        ],
                        [
                            -97.4451300001406,
                            25.8562239999526
                        ],
                        [
                            -97.4539850002217,
                            25.8540879999493
                        ],
                        [
                            -97.4517680003213,
                            25.8587930003113
                        ],
                        [
                            -97.4482709999557,
                            25.8594630000896
                        ],
                        [
                            -97.447679000302,
                            25.8632319998013
                        ],
                        [
                            -97.4446530001146,
                            25.8674399998565
                        ],
                        [
                            -97.4483210001844,
                            25.8721060003885
                        ],
                        [
                            -97.4502669999232,
                            25.8717470001868
                        ],
                        [
                            -97.453490999579,
                            25.8673669997994
                        ],
                        [
                            -97.4566900000187,
                            25.8683430000115
                        ],
                        [
                            -97.4547240001885,
                            25.8793360002337
                        ],
                        [
                            -97.4556010002476,
                            25.8839489997563
                        ],
                        [
                            -97.464180999969,
                            25.8817159998946
                        ],
                        [
                            -97.4701220000157,
                            25.8754130002154
                        ],
                        [
                            -97.4741030000799,
                            25.8765309996496
                        ],
                        [
                            -97.4746699996193,
                            25.8796809999189
                        ],
                        [
                            -97.4684599999591,
                            25.8837069996456
                        ],
                        [
                            -97.4677720000459,
                            25.8866419998522
                        ],
                        [
                            -97.469342000041,
                            25.8887950002526
                        ],
                        [
                            -97.48486500044,
                            25.8786999998944
                        ],
                        [
                            -97.4876399996589,
                            25.8790229997996
                        ],
                        [
                            -97.4862730000534,
                            25.8833340003004
                        ],
                        [
                            -97.4884720004105,
                            25.8853840003079
                        ],
                        [
                            -97.4928140001497,
                            25.8808060001491
                        ],
                        [
                            -97.4966870002589,
                            25.8796800001439
                        ],
                        [
                            -97.4976150003716,
                            25.8862549999686
                        ],
                        [
                            -97.496430000341,
                            25.8958050003226
                        ],
                        [
                            -97.4982039996523,
                            25.8990390000228
                        ],
                        [
                            -97.510768999636,
                            25.8875950002658
                        ],
                        [
                            -97.5217500002791,
                            25.886461999895
                        ],
                        [
                            -97.5233169999012,
                            25.8894069996627
                        ],
                        [
                            -97.5238619996992,
                            25.8990320003204
                        ],
                        [
                            -97.5273420003464,
                            25.9026130001848
                        ],
                        [
                            -97.5287990003636,
                            25.906875999799
                        ],
                        [
                            -97.5334819996864,
                            25.9083829998258
                        ],
                        [
                            -97.533757000046,
                            25.9119190000595
                        ],
                        [
                            -97.5301440002278,
                            25.9167560001231
                        ],
                        [
                            -97.5301819998626,
                            25.9198540001113
                        ],
                        [
                            -97.5429619999314,
                            25.9200310001149
                        ],
                        [
                            -97.5425300001113,
                            25.9223729996036
                        ],
                        [
                            -97.5373489999478,
                            25.9273960000391
                        ],
                        [
                            -97.5372290002972,
                            25.9305259999469
                        ],
                        [
                            -97.5439899996032,
                            25.9233529999437
                        ],
                        [
                            -97.5454769997576,
                            25.9251699997184
                        ],
                        [
                            -97.5460140000581,
                            25.9335199997351
                        ],
                        [
                            -97.5497399998542,
                            25.9362189998223
                        ],
                        [
                            -97.5559140004277,
                            25.9370180000826
                        ],
                        [
                            -97.5593549998185,
                            25.9314639999633
                        ],
                        [
                            -97.582864999562,
                            25.9378570001424
                        ],
                        [
                            -97.5734739999037,
                            25.9429699998762
                        ],
                        [
                            -97.566663000369,
                            25.9447140001927
                        ],
                        [
                            -97.5660619995961,
                            25.948192000058
                        ],
                        [
                            -97.5679570001795,
                            25.952350000182
                        ],
                        [
                            -97.5744060002144,
                            25.9538100000491
                        ],
                        [
                            -97.5732229998335,
                            25.9458550001206
                        ],
                        [
                            -97.5782359997675,
                            25.9420490003067
                        ],
                        [
                            -97.5830119998751,
                            25.9548280001412
                        ],
                        [
                            -97.5823730003657,
                            25.9624690001339
                        ],
                        [
                            -97.593888000038,
                            25.961574999868
                        ],
                        [
                            -97.5983109999681,
                            25.9570380001103
                        ],
                        [
                            -97.6025909997831,
                            25.9577349996696
                        ],
                        [
                            -97.6105720001782,
                            25.966303000213
                        ],
                        [
                            -97.6080770004434,
                            25.9712799999467
                        ],
                        [
                            -97.6079569998945,
                            25.9766350003784
                        ],
                        [
                            -97.6176659995701,
                            25.9799560002433
                        ],
                        [
                            -97.6201970001882,
                            25.9850140002714
                        ],
                        [
                            -97.6198470003838,
                            25.9874689997685
                        ],
                        [
                            -97.6217200003276,
                            25.989116999598
                        ],
                        [
                            -97.6315710002934,
                            25.988177999878
                        ],
                        [
                            -97.634435999924,
                            25.9902679998155
                        ],
                        [
                            -97.6311369999252,
                            25.9963309999368
                        ],
                        [
                            -97.6261109995724,
                            25.9952290000635
                        ],
                        [
                            -97.6230149999631,
                            25.9916130000997
                        ],
                        [
                            -97.6203090003412,
                            25.9923570001021
                        ],
                        [
                            -97.6178339997996,
                            25.9978949996455
                        ],
                        [
                            -97.6182820004116,
                            26.0020919997203
                        ],
                        [
                            -97.6198160004217,
                            26.0027029996354
                        ],
                        [
                            -97.6238159998542,
                            25.9970760003353
                        ],
                        [
                            -97.627704000032,
                            25.9968260000671
                        ],
                        [
                            -97.6269499999966,
                            26.0059679999984
                        ],
                        [
                            -97.6299770000089,
                            26.0054850002771
                        ],
                        [
                            -97.6322240000465,
                            26.0013350001943
                        ],
                        [
                            -97.6350719999587,
                            25.9998930002539
                        ],
                        [
                            -97.6493769998168,
                            26.0108620001334
                        ],
                        [
                            -97.6481769997175,
                            26.0126660000877
                        ],
                        [
                            -97.6412210004178,
                            26.0118280001829
                        ],
                        [
                            -97.6376090004245,
                            26.0141610000824
                        ],
                        [
                            -97.6373299998669,
                            26.0201650000883
                        ],
                        [
                            -97.6405779998122,
                            26.0245149997472
                        ],
                        [
                            -97.6452200000254,
                            26.0277580000622
                        ],
                        [
                            -97.6476829999731,
                            26.0262490000855
                        ],
                        [
                            -97.6505400001061,
                            26.0232159998871
                        ],
                        [
                            -97.6501850002788,
                            26.0179740001167
                        ],
                        [
                            -97.6514420002795,
                            26.0159809998982
                        ],
                        [
                            -97.658626999908,
                            26.0201149998973
                        ],
                        [
                            -97.6681299997193,
                            26.0194290002593
                        ],
                        [
                            -97.6674340003085,
                            26.0243880003112
                        ],
                        [
                            -97.6606990000435,
                            26.0312519997875
                        ],
                        [
                            -97.6624980003674,
                            26.0380050003
                        ],
                        [
                            -97.6641779999674,
                            26.0367850000638
                        ],
                        [
                            -97.6650519996534,
                            26.0307500003565
                        ],
                        [
                            -97.6676929998763,
                            26.0289540000363
                        ],
                        [
                            -97.6708589998058,
                            26.0338190000203
                        ],
                        [
                            -97.674547999792,
                            26.0330290003922
                        ],
                        [
                            -97.6761629999929,
                            26.0300160001456
                        ],
                        [
                            -97.6734180000129,
                            26.0279000002447
                        ],
                        [
                            -97.6757599995868,
                            26.0258220001439
                        ],
                        [
                            -97.6856690001772,
                            26.0267549999669
                        ],
                        [
                            -97.6933330003799,
                            26.0323369998144
                        ],
                        [
                            -97.6937049999256,
                            26.0270999997357
                        ],
                        [
                            -97.6901230000695,
                            26.0222819999999
                        ],
                        [
                            -97.6913690001995,
                            26.0189899996545
                        ],
                        [
                            -97.6974220003992,
                            26.0238360003519
                        ],
                        [
                            -97.6972869997817,
                            26.0309460002035
                        ],
                        [
                            -97.7025209996486,
                            26.0312080000969
                        ],
                        [
                            -97.7047080003101,
                            26.0361010003442
                        ],
                        [
                            -97.7072959999312,
                            26.0377560000626
                        ],
                        [
                            -97.7093000002946,
                            26.0347220002399
                        ],
                        [
                            -97.7096609999696,
                            26.0261940001424
                        ],
                        [
                            -97.7211129998927,
                            26.0233210000726
                        ],
                        [
                            -97.7182700000034,
                            26.0290360003614
                        ],
                        [
                            -97.7197639998305,
                            26.0310390002881
                        ],
                        [
                            -97.7348999997169,
                            26.0320180001948
                        ],
                        [
                            -97.7350710003195,
                            26.0283550002348
                        ],
                        [
                            -97.7277019996696,
                            26.0279850000842
                        ],
                        [
                            -97.7252760004301,
                            26.0248800003675
                        ],
                        [
                            -97.7264699997833,
                            26.0226560002715
                        ],
                        [
                            -97.7317000003506,
                            26.0233130003231
                        ],
                        [
                            -97.7371030002719,
                            26.0214930003697
                        ],
                        [
                            -97.738232000226,
                            26.0228820003025
                        ],
                        [
                            -97.7362710004187,
                            26.0281550003856
                        ],
                        [
                            -97.742404000086,
                            26.0298060003621
                        ],
                        [
                            -97.748699000135,
                            26.0263270001074
                        ],
                        [
                            -97.74866300015,
                            26.0300020001717
                        ],
                        [
                            -97.7520719997537,
                            26.03147499991
                        ],
                        [
                            -97.759422000137,
                            26.026130999809
                        ],
                        [
                            -97.7634249999426,
                            26.026819000106
                        ],
                        [
                            -97.7626709999072,
                            26.0409549998944
                        ],
                        [
                            -97.7680649996077,
                            26.0473210003318
                        ],
                        [
                            -97.7768279996272,
                            26.043971999979
                        ],
                        [
                            -97.7763489999515,
                            26.0405089997597
                        ],
                        [
                            -97.7693309998292,
                            26.0360649997387
                        ],
                        [
                            -97.7749360003152,
                            26.0329540002481
                        ],
                        [
                            -97.7768159999317,
                            26.029452000222
                        ],
                        [
                            -97.7791440001602,
                            26.0299310001632
                        ],
                        [
                            -97.7825259999997,
                            26.0338939996115
                        ],
                        [
                            -97.7902460002789,
                            26.0333589998111
                        ],
                        [
                            -97.7929550002738,
                            26.0345360001866
                        ],
                        [
                            -97.7972610000281,
                            26.0394410000205
                        ],
                        [
                            -97.7976490003656,
                            26.0426860001467
                        ],
                        [
                            -97.7951219999455,
                            26.0472070001375
                        ],
                        [
                            -97.789756999659,
                            26.0474249999592
                        ],
                        [
                            -97.789027999738,
                            26.0497370001964
                        ],
                        [
                            -97.7929810002131,
                            26.0532309996073
                        ],
                        [
                            -97.7984279996171,
                            26.0526920003864
                        ],
                        [
                            -97.8013170004354,
                            26.0601610001274
                        ],
                        [
                            -97.8109719996843,
                            26.0555119999183
                        ],
                        [
                            -97.8124279998766,
                            26.0503420001907
                        ],
                        [
                            -97.8111600000053,
                            26.0457420000862
                        ],
                        [
                            -97.8133889996013,
                            26.0441990000989
                        ],
                        [
                            -97.8164569996214,
                            26.0456369997927
                        ],
                        [
                            -97.8148899999994,
                            26.0534150000374
                        ],
                        [
                            -97.818054000279,
                            26.0563969997112
                        ],
                        [
                            -97.8259400002395,
                            26.0558779999975
                        ],
                        [
                            -97.8334560003042,
                            26.0458609999825
                        ],
                        [
                            -97.835982999826,
                            26.0475580000623
                        ],
                        [
                            -97.83660799999,
                            26.0516509999532
                        ],
                        [
                            -97.8393859995819,
                            26.0532040002742
                        ],
                        [
                            -97.8605040004212,
                            26.0529179998434
                        ],
                        [
                            -97.862293999626,
                            26.0604189998819
                        ],
                        [
                            -97.8593300002612,
                            26.0669559997608
                        ],
                        [
                            -97.8617759995922,
                            26.0699459999468
                        ],
                        [
                            -97.8640760002315,
                            26.0680239997413
                        ],
                        [
                            -97.8690609996781,
                            26.0569549998572
                        ],
                        [
                            -97.8769349999431,
                            26.0645820002964
                        ],
                        [
                            -97.887041000177,
                            26.0665540002783
                        ],
                        [
                            -97.8883929997139,
                            26.0624280001654
                        ],
                        [
                            -97.8906769995615,
                            26.0606719998089
                        ],
                        [
                            -97.9010599998048,
                            26.0610809998558
                        ],
                        [
                            -97.9112449996815,
                            26.0564539997915
                        ],
                        [
                            -97.9201230002271,
                            26.0579839998703
                        ],
                        [
                            -97.9271899998549,
                            26.0553159999747
                        ],
                        [
                            -97.931396999875,
                            26.0634199996199
                        ],
                        [
                            -97.9339240002951,
                            26.0648340002931
                        ],
                        [
                            -97.9369670002009,
                            26.0619260002585
                        ],
                        [
                            -97.931801000106,
                            26.055944000168
                        ],
                        [
                            -97.9342470003353,
                            26.0528260000814
                        ],
                        [
                            -97.9379239997276,
                            26.0535240002788
                        ],
                        [
                            -97.9452599998672,
                            26.0601880002784
                        ],
                        [
                            -97.9511749999746,
                            26.0619580000145
                        ],
                        [
                            -97.9570010003936,
                            26.0599630001744
                        ],
                        [
                            -97.9673350002331,
                            26.0517830001758
                        ],
                        [
                            -97.9787610002169,
                            26.0597560003427
                        ],
                        [
                            -97.9813389997923,
                            26.0672269996511
                        ],
                        [
                            -97.9873019995803,
                            26.0657220001101
                        ],
                        [
                            -97.9914929997069,
                            26.0585849997766
                        ],
                        [
                            -97.9972530000036,
                            26.0641409996031
                        ],
                        [
                            -98.0035160002655,
                            26.0644530002325
                        ],
                        [
                            -98.0073860000017,
                            26.0621599999876
                        ],
                        [
                            -98.0119779999862,
                            26.0562920000852
                        ],
                        [
                            -98.0108530002301,
                            26.0618920000038
                        ],
                        [
                            -98.0119319999555,
                            26.0658589996587
                        ],
                        [
                            -98.0142330004198,
                            26.066021999661
                        ],
                        [
                            -98.0160119997539,
                            26.061390000221
                        ],
                        [
                            -98.0207410000058,
                            26.0587389997868
                        ],
                        [
                            -98.0255370002048,
                            26.0659650002009
                        ],
                        [
                            -98.0278009999609,
                            26.0668490003163
                        ],
                        [
                            -98.0327169998105,
                            26.0621240001218
                        ],
                        [
                            -98.0344030001566,
                            26.0513749998879
                        ],
                        [
                            -98.0392389996402,
                            26.0412750002451
                        ],
                        [
                            -98.0462280003485,
                            26.0442299998608
                        ],
                        [
                            -98.0611690002413,
                            26.0462380000633
                        ],
                        [
                            -98.065094000229,
                            26.0448310000803
                        ],
                        [
                            -98.071197999584,
                            26.0370749997084
                        ],
                        [
                            -98.0745860001696,
                            26.0363380001535
                        ],
                        [
                            -98.077576000372,
                            26.0368229997519
                        ],
                        [
                            -98.0800030003347,
                            26.0419599996621
                        ],
                        [
                            -98.0723650000713,
                            26.0438839996329
                        ],
                        [
                            -98.0700290003452,
                            26.0479929996489
                        ],
                        [
                            -98.0700250001473,
                            26.0514660000514
                        ],
                        [
                            -98.0750730002414,
                            26.0598710002943
                        ],
                        [
                            -98.0765439996039,
                            26.0680419999794
                        ],
                        [
                            -98.0818949996467,
                            26.0711409998643
                        ],
                        [
                            -98.0858489999468,
                            26.0692079999147
                        ],
                        [
                            -98.0814000000774,
                            26.0662069999829
                        ],
                        [
                            -98.0818839997761,
                            26.0637240003352
                        ],
                        [
                            -98.0944320000413,
                            26.0586250002075
                        ],
                        [
                            -98.1055049998477,
                            26.0675369997364
                        ],
                        [
                            -98.1258439996389,
                            26.0617909998297
                        ],
                        [
                            -98.1292690000344,
                            26.0626920003915
                        ],
                        [
                            -98.1312499999333,
                            26.065381000255
                        ],
                        [
                            -98.128366999861,
                            26.0723990002099
                        ],
                        [
                            -98.1318290000665,
                            26.0741699999381
                        ],
                        [
                            -98.136012999622,
                            26.071021999819
                        ],
                        [
                            -98.1367959999698,
                            26.062319000137
                        ],
                        [
                            -98.1406559996602,
                            26.054965999779
                        ],
                        [
                            -98.1438280003357,
                            26.0503840000864
                        ],
                        [
                            -98.1465060003686,
                            26.049302999767
                        ],
                        [
                            -98.1493810000449,
                            26.0515059996841
                        ],
                        [
                            -98.1493719998241,
                            26.0557969997742
                        ],
                        [
                            -98.1516649998924,
                            26.0582079998668
                        ],
                        [
                            -98.159262000148,
                            26.0537430003684
                        ],
                        [
                            -98.1618799999063,
                            26.0545390002874
                        ],
                        [
                            -98.1620959998164,
                            26.0570890003267
                        ],
                        [
                            -98.1583340000353,
                            26.0605799996786
                        ],
                        [
                            -98.1588389996504,
                            26.0633090000487
                        ],
                        [
                            -98.1616840000878,
                            26.0642389999006
                        ],
                        [
                            -98.165337000089,
                            26.0625329999415
                        ],
                        [
                            -98.1688290004317,
                            26.0629869996652
                        ],
                        [
                            -98.1721259998824,
                            26.0711370000072
                        ],
                        [
                            -98.1771389998164,
                            26.0751200001057
                        ],
                        [
                            -98.1798019997806,
                            26.0729470000908
                        ],
                        [
                            -98.1804749996252,
                            26.0666669996038
                        ],
                        [
                            -98.1766729996611,
                            26.0628630002388
                        ],
                        [
                            -98.1769349996019,
                            26.0606609998234
                        ],
                        [
                            -98.1797209995898,
                            26.0600980002887
                        ],
                        [
                            -98.1836060002928,
                            26.0636579997055
                        ],
                        [
                            -98.1878350000542,
                            26.0640050002894
                        ],
                        [
                            -98.19230499984,
                            26.0534529999849
                        ],
                        [
                            -98.1939850003383,
                            26.053323999773
                        ],
                        [
                            -98.1984659999947,
                            26.0553969997235
                        ],
                        [
                            -98.2049590004105,
                            26.0664179997315
                        ],
                        [
                            -98.2127909999443,
                            26.0691539997298
                        ],
                        [
                            -98.216832000283,
                            26.074437000335
                        ],
                        [
                            -98.2206729997068,
                            26.0764670003334
                        ],
                        [
                            -98.2300969998754,
                            26.077154999728
                        ],
                        [
                            -98.2487369998001,
                            26.0720420003185
                        ],
                        [
                            -98.2509570000735,
                            26.0740890002595
                        ],
                        [
                            -98.2515470000774,
                            26.0790270000554
                        ],
                        [
                            -98.264513999744,
                            26.0855069999637
                        ],
                        [
                            -98.2683860000283,
                            26.0931380002737
                        ],
                        [
                            -98.2772179996448,
                            26.0988020002521
                        ],
                        [
                            -98.2842910000187,
                            26.1010900000103
                        ],
                        [
                            -98.2882460001437,
                            26.1047069999898
                        ],
                        [
                            -98.2865470002771,
                            26.106914000026
                        ],
                        [
                            -98.2811870000135,
                            26.1077300001309
                        ],
                        [
                            -98.2704819995548,
                            26.1066100001601
                        ],
                        [
                            -98.2718289999672,
                            26.1129960003554
                        ],
                        [
                            -98.2659740001342,
                            26.1171379998946
                        ],
                        [
                            -98.2662579998163,
                            26.1207220002606
                        ],
                        [
                            -98.275160999578,
                            26.1159179996605
                        ],
                        [
                            -98.2905950002885,
                            26.1206569996605
                        ],
                        [
                            -98.2961949998534,
                            26.1203210001264
                        ],
                        [
                            -98.2993449998893,
                            26.1180879996139
                        ],
                        [
                            -98.3029789996239,
                            26.110049999761
                        ],
                        [
                            -98.2980190002917,
                            26.1032980000395
                        ],
                        [
                            -98.3005790003238,
                            26.1021300002839
                        ],
                        [
                            -98.3059710003744,
                            26.1036820003835
                        ],
                        [
                            -98.310339000053,
                            26.1143960002064
                        ],
                        [
                            -98.320877999932,
                            26.1182379997127
                        ],
                        [
                            -98.3352039997065,
                            26.1376170002049
                        ],
                        [
                            -98.3345319996868,
                            26.14112099961
                        ],
                        [
                            -98.326916000063,
                            26.1427690003134
                        ],
                        [
                            -98.3284359998294,
                            26.144832999883
                        ],
                        [
                            -98.3356199996331,
                            26.1464010001285
                        ],
                        [
                            -98.3382440001375,
                            26.1499360003397
                        ],
                        [
                            -98.3380359997251,
                            26.1531200000066
                        ],
                        [
                            -98.3330279998139,
                            26.1601760001444
                        ],
                        [
                            -98.3356199996331,
                            26.165920000397
                        ],
                        [
                            -98.3429230001607,
                            26.1668959998462
                        ],
                        [
                            -98.3463250000917,
                            26.1655359998681
                        ],
                        [
                            -98.3487410001838,
                            26.1599520003128
                        ],
                        [
                            -98.3489810003832,
                            26.1531200000066
                        ],
                        [
                            -98.3523409995832,
                            26.1511200001552
                        ],
                        [
                            -98.3580210004123,
                            26.159231999841
                        ],
                        [
                            -98.3576849999533,
                            26.1688320002744
                        ],
                        [
                            -98.3606450000184,
                            26.1725440001359
                        ],
                        [
                            -98.3666000003088,
                            26.1687819998923
                        ],
                        [
                            -98.3647020002506,
                            26.160890999814
                        ],
                        [
                            -98.3672370001684,
                            26.1576799999437
                        ],
                        [
                            -98.3866939998776,
                            26.1578719999945
                        ],
                        [
                            -98.3935420001206,
                            26.168015999775
                        ],
                        [
                            -98.4022080000558,
                            26.1721699999259
                        ],
                        [
                            -98.4022490000636,
                            26.1795229998937
                        ],
                        [
                            -98.4044330003521,
                            26.1825639998344
                        ],
                        [
                            -98.4181199997189,
                            26.1846479998314
                        ],
                        [
                            -98.4251749996511,
                            26.1915349999891
                        ],
                        [
                            -98.4420400004396,
                            26.1987509999949
                        ],
                        [
                            -98.444072000392,
                            26.2008950003287
                        ],
                        [
                            -98.4442959997997,
                            26.2046710003438
                        ],
                        [
                            -98.4392030003981,
                            26.210923000244
                        ],
                        [
                            -98.4388219997332,
                            26.2142199999303
                        ],
                        [
                            -98.4402510001614,
                            26.2194419999081
                        ],
                        [
                            -98.4452220002625,
                            26.2245710000329
                        ],
                        [
                            -98.4500100000657,
                            26.2230190001109
                        ],
                        [
                            -98.454455999562,
                            26.2170619999677
                        ],
                        [
                            -98.4560719995879,
                            26.217597999827
                        ],
                        [
                            -98.4584020003645,
                            26.2253170002715
                        ],
                        [
                            -98.4612159999414,
                            26.2261200000562
                        ],
                        [
                            -98.4717819995846,
                            26.2201869999001
                        ],
                        [
                            -98.4815820003951,
                            26.2193789997311
                        ],
                        [
                            -98.4831289999257,
                            26.2153089999618
                        ],
                        [
                            -98.4784950001084,
                            26.2082920000143
                        ],
                        [
                            -98.4779450002874,
                            26.2039670002438
                        ],
                        [
                            -98.4789689997613,
                            26.2017749998355
                        ],
                        [
                            -98.4816090001593,
                            26.2012789996125
                        ],
                        [
                            -98.4831709997585,
                            26.2027010001125
                        ],
                        [
                            -98.4816169996569,
                            26.206469000271
                        ],
                        [
                            -98.4824729997996,
                            26.2096299997333
                        ],
                        [
                            -98.4869879997912,
                            26.2129229999452
                        ],
                        [
                            -98.4924280004208,
                            26.2129209996234
                        ],
                        [
                            -98.5037659996427,
                            26.2088000001178
                        ],
                        [
                            -98.5067690002639,
                            26.2094040002748
                        ],
                        [
                            -98.5071049998245,
                            26.2116750003726
                        ],
                        [
                            -98.5010809999372,
                            26.2213470003528
                        ],
                        [
                            -98.5020249999434,
                            26.2233440003767
                        ],
                        [
                            -98.514846999845,
                            26.2272180002458
                        ],
                        [
                            -98.52254000036,
                            26.2209960001899
                        ],
                        [
                            -98.5242779996862,
                            26.221245000146
                        ],
                        [
                            -98.5264350002105,
                            26.2277229998149
                        ],
                        [
                            -98.5217879999744,
                            26.2325300002965
                        ],
                        [
                            -98.5218139999137,
                            26.2356420000753
                        ],
                        [
                            -98.5244559999615,
                            26.2355650002834
                        ],
                        [
                            -98.5298410003395,
                            26.2279360003463
                        ],
                        [
                            -98.5352409998878,
                            26.2256769999453
                        ],
                        [
                            -98.5444790002835,
                            26.2437530002836
                        ],
                        [
                            -98.5545770001215,
                            26.2473800000224
                        ],
                        [
                            -98.5573489998657,
                            26.2474819998626
                        ],
                        [
                            -98.5590529997551,
                            26.244976000158
                        ],
                        [
                            -98.5523350001068,
                            26.2411919996311
                        ],
                        [
                            -98.5475990001822,
                            26.2411350002721
                        ],
                        [
                            -98.545922000057,
                            26.2370969996999
                        ],
                        [
                            -98.5487180000906,
                            26.2337139997011
                        ],
                        [
                            -98.5560020003517,
                            26.2321050003587
                        ],
                        [
                            -98.5591809998016,
                            26.2262740000106
                        ],
                        [
                            -98.5629590003746,
                            26.2249079998809
                        ],
                        [
                            -98.5647369998838,
                            26.2278179997521
                        ],
                        [
                            -98.5649849995809,
                            26.2386189996719
                        ],
                        [
                            -98.5673920003505,
                            26.241624000395
                        ],
                        [
                            -98.5741059998008,
                            26.2385850000966
                        ],
                        [
                            -98.5766430002667,
                            26.2338339999152
                        ],
                        [
                            -98.5798450001811,
                            26.2347760000734
                        ],
                        [
                            -98.5849819999637,
                            26.2443009999004
                        ],
                        [
                            -98.579958000159,
                            26.2494300000368
                        ],
                        [
                            -98.5795170001181,
                            26.2522449999728
                        ],
                        [
                            -98.5829170003993,
                            26.2564720003254
                        ],
                        [
                            -98.5875450003688,
                            26.2578160002534
                        ],
                        [
                            -98.5991539997524,
                            26.2576120001002
                        ],
                        [
                            -98.6129129999875,
                            26.2527269997312
                        ],
                        [
                            -98.616487000346,
                            26.2467610000119
                        ],
                        [
                            -98.6182049995808,
                            26.2475620002117
                        ],
                        [
                            -98.6216540002658,
                            26.2587739996637
                        ],
                        [
                            -98.6234669999351,
                            26.2596659998561
                        ],
                        [
                            -98.6322769998104,
                            26.2521400000092
                        ],
                        [
                            -98.6341799998914,
                            26.2426119999712
                        ],
                        [
                            -98.6440950003296,
                            26.2413559997371
                        ],
                        [
                            -98.6544509999106,
                            26.2359330001678
                        ],
                        [
                            -98.6646330003125,
                            26.2353510000601
                        ],
                        [
                            -98.671164000363,
                            26.2373669996041
                        ],
                        [
                            -98.6765620002615,
                            26.2415620000633
                        ],
                        [
                            -98.6788519999568,
                            26.2455759998709
                        ],
                        [
                            -98.6778450002014,
                            26.2569149998944
                        ],
                        [
                            -98.6810899997736,
                            26.2628030001536
                        ],
                        [
                            -98.6871560003921,
                            26.2651200001805
                        ],
                        [
                            -98.700737000352,
                            26.2659659998631
                        ],
                        [
                            -98.7194130002617,
                            26.272303999978
                        ],
                        [
                            -98.7197649997159,
                            26.2759110002154
                        ],
                        [
                            -98.715660999628,
                            26.2784230001717
                        ],
                        [
                            -98.7011590001264,
                            26.2780230002632
                        ],
                        [
                            -98.6998979999278,
                            26.280858000144
                        ],
                        [
                            -98.7018000001839,
                            26.2861630002286
                        ],
                        [
                            -98.7093530000585,
                            26.2891889998985
                        ],
                        [
                            -98.7161700003393,
                            26.2861970003738
                        ],
                        [
                            -98.7189510003043,
                            26.2882369997083
                        ],
                        [
                            -98.7219890001873,
                            26.2950460002864
                        ],
                        [
                            -98.7277650003775,
                            26.2987699998249
                        ],
                        [
                            -98.7348219999595,
                            26.2983519998865
                        ],
                        [
                            -98.7426209998814,
                            26.2941969999932
                        ],
                        [
                            -98.7509729999972,
                            26.2963210003157
                        ],
                        [
                            -98.7561230001986,
                            26.3042489996386
                        ],
                        [
                            -98.7542159999196,
                            26.3079279996633
                        ],
                        [
                            -98.7503529998561,
                            26.3076570002267
                        ],
                        [
                            -98.7436850004365,
                            26.3030090000642
                        ],
                        [
                            -98.7404190000496,
                            26.3027769998708
                        ],
                        [
                            -98.7372349996785,
                            26.3049599998441
                        ],
                        [
                            -98.736160999976,
                            26.309198999993
                        ],
                        [
                            -98.7384570004174,
                            26.3136540003114
                        ],
                        [
                            -98.7458210001461,
                            26.3168449997093
                        ],
                        [
                            -98.7493789997127,
                            26.3248310001367
                        ],
                        [
                            -98.7494690001244,
                            26.3299879998115
                        ],
                        [
                            -98.751126999983,
                            26.33158399962
                        ],
                        [
                            -98.7713210000093,
                            26.3250809996559
                        ],
                        [
                            -98.7770279997042,
                            26.3257290003997
                        ],
                        [
                            -98.7889550000034,
                            26.3304330003231
                        ],
                        [
                            -98.788313000121,
                            26.3383369996907
                        ],
                        [
                            -98.796251999785,
                            26.3491039999864
                        ],
                        [
                            -98.7982109999425,
                            26.3601659998866
                        ],
                        [
                            -98.8073480000559,
                            26.3694210002295
                        ],
                        [
                            -98.813999999582,
                            26.3662919996521
                        ],
                        [
                            -98.8204519999898,
                            26.3710019996339
                        ],
                        [
                            -98.8245650002985,
                            26.3708329997434
                        ],
                        [
                            -98.8329090000185,
                            26.36333799996
                        ],
                        [
                            -98.8441149998942,
                            26.3587300002012
                        ],
                        [
                            -98.847570000427,
                            26.3597899996903
                        ],
                        [
                            -98.8534150002142,
                            26.3650230000586
                        ],
                        [
                            -98.8619100004452,
                            26.3659899996972
                        ],
                        [
                            -98.8967340000195,
                            26.3533299997595
                        ],
                        [
                            -98.900893000359,
                            26.3594039996254
                        ],
                        [
                            -98.8995509999695,
                            26.3665880001641
                        ],
                        [
                            -98.9016769996333,
                            26.3712290003171
                        ],
                        [
                            -98.9110580001442,
                            26.3717670003045
                        ],
                        [
                            -98.9173769995843,
                            26.3744710001594
                        ],
                        [
                            -98.9234020001949,
                            26.3813710001982
                        ],
                        [
                            -98.9237770001136,
                            26.3920480003086
                        ],
                        [
                            -98.9258790003863,
                            26.3943569999887
                        ],
                        [
                            -98.9292829999672,
                            26.393360999803
                        ],
                        [
                            -98.9356359997425,
                            26.3850469999872
                        ],
                        [
                            -98.9343069997718,
                            26.377464000268
                        ],
                        [
                            -98.9351339996022,
                            26.3729710002465
                        ],
                        [
                            -98.9379980003062,
                            26.3704140003823
                        ],
                        [
                            -98.9421519997244,
                            26.3696870002665
                        ],
                        [
                            -98.9466940003786,
                            26.3700029996178
                        ],
                        [
                            -98.9560189999146,
                            26.3772549996971
                        ],
                        [
                            -98.9530770002911,
                            26.3863430003725
                        ],
                        [
                            -98.9545030003462,
                            26.3943669996336
                        ],
                        [
                            -98.9618860003415,
                            26.3983729999447
                        ],
                        [
                            -98.9700829997483,
                            26.3991929997668
                        ],
                        [
                            -98.97416400027,
                            26.4011479997305
                        ],
                        [
                            -98.9791529999145,
                            26.3999239998789
                        ],
                        [
                            -98.9890850000712,
                            26.3916669997018
                        ],
                        [
                            -99.0037830002897,
                            26.3934760002793
                        ],
                        [
                            -99.0106399998552,
                            26.3921349998147
                        ],
                        [
                            -99.0219350003177,
                            26.4079019997394
                        ],
                        [
                            -99.0320110004144,
                            26.4128209997833
                        ],
                        [
                            -99.0370579997853,
                            26.4136030000499
                        ],
                        [
                            -99.0454659999776,
                            26.4098159996785
                        ],
                        [
                            -99.0546139999617,
                            26.4012620003496
                        ],
                        [
                            -99.0621109997598,
                            26.3973849996358
                        ],
                        [
                            -99.0820060000352,
                            26.3966670003458
                        ],
                        [
                            -99.085125999934,
                            26.398782000014
                        ],
                        [
                            -99.08941300032,
                            26.4081000002067
                        ],
                        [
                            -99.0960110003177,
                            26.4118279998001
                        ],
                        [
                            -99.0995739999072,
                            26.4172479998163
                        ],
                        [
                            -99.1108540003011,
                            26.4262820003903
                        ],
                        [
                            -99.1135459996793,
                            26.4325300000236
                        ],
                        [
                            -99.113163000263,
                            26.4351800002706
                        ],
                        [
                            -99.1056560004192,
                            26.4386670001471
                        ],
                        [
                            -99.1023759997886,
                            26.4427259998331
                        ],
                        [
                            -99.1003570002549,
                            26.4587479999442
                        ],
                        [
                            -99.0919759998268,
                            26.4723760003487
                        ],
                        [
                            -99.0919559997353,
                            26.4779119998008
                        ],
                        [
                            -99.0954790000401,
                            26.481678000182
                        ],
                        [
                            -99.1048510003301,
                            26.5003829996893
                        ],
                        [
                            -99.1277819999404,
                            26.5251989996429
                        ],
                        [
                            -99.1436579996186,
                            26.5279289996643
                        ],
                        [
                            -99.166742000288,
                            26.5360789999197
                        ],
                        [
                            -99.1712299996172,
                            26.541964999628
                        ],
                        [
                            -99.1713800003033,
                            26.5492490002634
                        ],
                        [
                            -99.1674100001097,
                            26.5600009996791
                        ],
                        [
                            -99.1780639996164,
                            26.620547000295
                        ],
                        [
                            -99.2005220002971,
                            26.6564430001679
                        ],
                        [
                            -99.2099480001154,
                            26.6939380001975
                        ],
                        [
                            -99.2089070000249,
                            26.7247610000831
                        ],
                        [
                            -99.2400230002447,
                            26.7458510002754
                        ],
                        [
                            -99.2424440003596,
                            26.7882619999099
                        ],
                        [
                            -99.2622080002155,
                            26.8156679996988
                        ],
                        [
                            -99.2686129998694,
                            26.843213000225
                        ],
                        [
                            -99.2804709996733,
                            26.8580530002698
                        ],
                        [
                            -99.2951460003256,
                            26.8654400001338
                        ],
                        [
                            -99.3167529999881,
                            26.8658310000051
                        ],
                        [
                            -99.3258819997055,
                            26.8773759997497
                        ],
                        [
                            -99.3286190001879,
                            26.8785919997068
                        ],
                        [
                            -99.3218189996255,
                            26.9068460001032
                        ],
                        [
                            -99.3246840001544,
                            26.9159729996796
                        ],
                        [
                            -99.3372969998187,
                            26.9227590000562
                        ],
                        [
                            -99.373374000094,
                            26.9312560002071
                        ],
                        [
                            -99.3791489995611,
                            26.9344900003017
                        ],
                        [
                            -99.3882530000625,
                            26.9442170000754
                        ],
                        [
                            -99.393033000368,
                            26.957060000134
                        ],
                        [
                            -99.392953999827,
                            26.96380300006
                        ],
                        [
                            -99.3773119996023,
                            26.9738189996956
                        ],
                        [
                            -99.376592999727,
                            26.9777169996188
                        ],
                        [
                            -99.3784349997086,
                            26.9800339997746
                        ],
                        [
                            -99.3873669997826,
                            26.9823990000533
                        ],
                        [
                            -99.4036940004457,
                            26.9973559997786
                        ],
                        [
                            -99.4085070003632,
                            27.0095200003507
                        ],
                        [
                            -99.4154760000817,
                            27.0172399996483
                        ],
                        [
                            -99.4204470001829,
                            27.0165680000182
                        ],
                        [
                            -99.4293800000817,
                            27.010832999962
                        ],
                        [
                            -99.432155000199,
                            27.0106990002223
                        ],
                        [
                            -99.4387210004096,
                            27.0146299999503
                        ],
                        [
                            -99.4456609998158,
                            27.0217559996209
                        ],
                        [
                            -99.4442759997686,
                            27.0383950002999
                        ],
                        [
                            -99.4523159997149,
                            27.0626689996395
                        ],
                        [
                            -99.4502820001127,
                            27.0677050003792
                        ],
                        [
                            -99.4344700000086,
                            27.0785169999294
                        ],
                        [
                            -99.4292090003775,
                            27.0909819996082
                        ],
                        [
                            -99.4302749996842,
                            27.0948719996388
                        ],
                        [
                            -99.4376459999839,
                            27.1004419997423
                        ],
                        [
                            -99.4421249999905,
                            27.1068560000038
                        ],
                        [
                            -99.4411090000142,
                            27.1100420002352
                        ],
                        [
                            -99.4333700003668,
                            27.1192180003426
                        ],
                        [
                            -99.4305810000059,
                            27.1266119996608
                        ],
                        [
                            -99.4313550001328,
                            27.1375800002398
                        ],
                        [
                            -99.4382650003001,
                            27.1447919996957
                        ],
                        [
                            -99.4399709998393,
                            27.1510719999155
                        ],
                        [
                            -99.4379509995824,
                            27.1541210003194
                        ],
                        [
                            -99.4299840003293,
                            27.1591489999241
                        ],
                        [
                            -99.4263480000466,
                            27.1762619998112
                        ],
                        [
                            -99.4327750003401,
                            27.2018409996328
                        ],
                        [
                            -99.4325889996689,
                            27.2096980001541
                        ],
                        [
                            -99.4427550001773,
                            27.2187799996729
                        ],
                        [
                            -99.4452380002165,
                            27.2233409999647
                        ],
                        [
                            -99.4414069999401,
                            27.2367580001233
                        ],
                        [
                            -99.4415450000323,
                            27.2499150001597
                        ],
                        [
                            -99.452391000058,
                            27.2640459998074
                        ],
                        [
                            -99.4614760002928,
                            27.2681750001503
                        ],
                        [
                            -99.4658249997048,
                            27.2679700003574
                        ],
                        [
                            -99.4811919995699,
                            27.2598349998623
                        ],
                        [
                            -99.4890590001621,
                            27.2614770001532
                        ],
                        [
                            -99.4940219998674,
                            27.2662300001363
                        ],
                        [
                            -99.4966899998545,
                            27.2724350000849
                        ],
                        [
                            -99.4875920000992,
                            27.2896019999574
                        ],
                        [
                            -99.4879369998807,
                            27.2949410003142
                        ],
                        [
                            -99.4920200000522,
                            27.3006659996157
                        ],
                        [
                            -99.4957540002442,
                            27.3044439996119
                        ],
                        [
                            -99.5009510003012,
                            27.3060080000368
                        ],
                        [
                            -99.5210709998093,
                            27.3038240002048
                        ],
                        [
                            -99.5284730000712,
                            27.3054169999038
                        ],
                        [
                            -99.5359990001816,
                            27.3119899998136
                        ],
                        [
                            -99.538035000332,
                            27.3169609999186
                        ],
                        [
                            -99.5313760002349,
                            27.3238090003105
                        ],
                        [
                            -99.5235830001608,
                            27.3241430000834
                        ],
                        [
                            -99.5189119996353,
                            27.3263050001979
                        ],
                        [
                            -99.5045749999901,
                            27.3387409996802
                        ],
                        [
                            -99.5078310003313,
                            27.348637000353
                        ],
                        [
                            -99.5077789995544,
                            27.354247000175
                        ],
                        [
                            -99.4990759998093,
                            27.3731400001822
                        ],
                        [
                            -99.4921439999007,
                            27.3805169996713
                        ],
                        [
                            -99.4927089997903,
                            27.3919540003836
                        ],
                        [
                            -99.4896109996329,
                            27.3990409999033
                        ],
                        [
                            -99.4875209999541,
                            27.4115719999904
                        ],
                        [
                            -99.4956989999927,
                            27.4388839998002
                        ],
                        [
                            -99.4951039999659,
                            27.4515180002248
                        ],
                        [
                            -99.4854039996128,
                            27.4640670001894
                        ],
                        [
                            -99.4822820000643,
                            27.4763149999446
                        ],
                        [
                            -99.479250999854,
                            27.478635000076
                        ],
                        [
                            -99.4812879998293,
                            27.4884680002957
                        ],
                        [
                            -99.485806000194,
                            27.493187999606
                        ],
                        [
                            -99.496826000297,
                            27.5001059997821
                        ],
                        [
                            -99.5135759996611,
                            27.4994119999476
                        ],
                        [
                            -99.5198499997937,
                            27.4967630001254
                        ],
                        [
                            -99.5261370003451,
                            27.4965889998723
                        ],
                        [
                            -99.5283919998803,
                            27.498550000267
                        ],
                        [
                            -99.5253199996622,
                            27.5103950003244
                        ],
                        [
                            -99.5219189995561,
                            27.5440939996071
                        ],
                        [
                            -99.5188189997488,
                            27.5531939996644
                        ],
                        [
                            -99.5140159998771,
                            27.5568419999683
                        ],
                        [
                            -99.5110490001393,
                            27.5645069996632
                        ],
                        [
                            -99.5121320000626,
                            27.5682879998541
                        ],
                        [
                            -99.516405000205,
                            27.5724669996416
                        ],
                        [
                            -99.5297109998053,
                            27.5797610000505
                        ],
                        [
                            -99.53619699965,
                            27.5916819998252
                        ],
                        [
                            -99.5397219996046,
                            27.603280999903
                        ],
                        [
                            -99.554945000428,
                            27.6144559999878
                        ],
                        [
                            -99.561232999906,
                            27.6075690000318
                        ],
                        [
                            -99.5786259998757,
                            27.6022859999704
                        ],
                        [
                            -99.5848430001069,
                            27.6039029998322
                        ],
                        [
                            -99.5844320002032,
                            27.6062129997511
                        ],
                        [
                            -99.5799769995877,
                            27.6078230002517
                        ],
                        [
                            -99.5771820002773,
                            27.6112999998087
                        ],
                        [
                            -99.5776359998388,
                            27.618596999625
                        ],
                        [
                            -99.5847820000076,
                            27.6220069996265
                        ],
                        [
                            -99.5913719996094,
                            27.6274639997953
                        ],
                        [
                            -99.5940379999467,
                            27.6385729996025
                        ],
                        [
                            -99.5962309995576,
                            27.6398579997545
                        ],
                        [
                            -99.6035330002603,
                            27.6419919999678
                        ],
                        [
                            -99.6160689999317,
                            27.6376809997605
                        ],
                        [
                            -99.6213970004083,
                            27.6437630003404
                        ],
                        [
                            -99.6243110004427,
                            27.6440010003974
                        ],
                        [
                            -99.6270570002476,
                            27.6407780001611
                        ],
                        [
                            -99.6271230003698,
                            27.6384119999886
                        ],
                        [
                            -99.6232460000626,
                            27.6346439999069
                        ],
                        [
                            -99.6251120003337,
                            27.6313799997852
                        ],
                        [
                            -99.6389290002952,
                            27.6267580003431
                        ],
                        [
                            -99.6500570003532,
                            27.6305820002117
                        ],
                        [
                            -99.6543000003582,
                            27.6297780002987
                        ],
                        [
                            -99.6588529999847,
                            27.6311380001183
                        ],
                        [
                            -99.6661079999334,
                            27.6360880002095
                        ],
                        [
                            -99.6654219996701,
                            27.6402750000168
                        ],
                        [
                            -99.65949999989,
                            27.6452459999299
                        ],
                        [
                            -99.6582949997678,
                            27.6501580000821
                        ],
                        [
                            -99.6618449998369,
                            27.6557529997499
                        ],
                        [
                            -99.6689419996019,
                            27.6599740002123
                        ],
                        [
                            -99.6789490001016,
                            27.6589789999423
                        ],
                        [
                            -99.6858130002382,
                            27.6610149996985
                        ],
                        [
                            -99.6885690000888,
                            27.6631730002373
                        ],
                        [
                            -99.6901580003505,
                            27.6688639996049
                        ],
                        [
                            -99.6970600001218,
                            27.6624809996981
                        ],
                        [
                            -99.6993559996649,
                            27.6554169998164
                        ],
                        [
                            -99.7046010003008,
                            27.6549540003263
                        ],
                        [
                            -99.7115109995697,
                            27.6583649998528
                        ],
                        [
                            -99.7215189998944,
                            27.6661549999311
                        ],
                        [
                            -99.7239020003745,
                            27.6735589997464
                        ],
                        [
                            -99.7324479997607,
                            27.6854249996829
                        ],
                        [
                            -99.7576449996752,
                            27.712112000052
                        ],
                        [
                            -99.7585119996885,
                            27.717040999804
                        ],
                        [
                            -99.7616199998917,
                            27.7220629999191
                        ],
                        [
                            -99.7707400002866,
                            27.7321339997322
                        ],
                        [
                            -99.7749010002759,
                            27.7335400001455
                        ],
                        [
                            -99.7853659996368,
                            27.7303550001243
                        ],
                        [
                            -99.7888449995607,
                            27.7307179998051
                        ],
                        [
                            -99.7963420002571,
                            27.73558600017
                        ],
                        [
                            -99.8016509995688,
                            27.7417710001741
                        ],
                        [
                            -99.8056700001662,
                            27.7586880002573
                        ],
                        [
                            -99.8131179995605,
                            27.7739970000775
                        ],
                        [
                            -99.8190920001174,
                            27.776018999824
                        ],
                        [
                            -99.8221140001069,
                            27.7672740002185
                        ],
                        [
                            -99.8260220003762,
                            27.7643270002113
                        ],
                        [
                            -99.8330539998438,
                            27.7628900000927
                        ],
                        [
                            -99.838790999676,
                            27.764180999675
                        ],
                        [
                            -99.8429669997339,
                            27.7685180000362
                        ],
                        [
                            -99.8447369997456,
                            27.7788089996609
                        ],
                        [
                            -99.8508769999839,
                            27.7939740003723
                        ],
                        [
                            -99.8700659999046,
                            27.7946270001236
                        ],
                        [
                            -99.8767609999867,
                            27.7978450001663
                        ],
                        [
                            -99.8780239998351,
                            27.8005049997813
                        ],
                        [
                            -99.873785000028,
                            27.813011999691
                        ],
                        [
                            -99.8778940001388,
                            27.8253520000565
                        ],
                        [
                            -99.8760029997533,
                            27.8379680003822
                        ],
                        [
                            -99.8772000003778,
                            27.8421800000557
                        ],
                        [
                            -99.8820149999451,
                            27.850391999698
                        ],
                        [
                            -99.8936500001662,
                            27.856192999749
                        ],
                        [
                            -99.9014859998981,
                            27.8641619998912
                        ],
                        [
                            -99.9043849998638,
                            27.8752840003495
                        ],
                        [
                            -99.9012320003532,
                            27.884405999827
                        ],
                        [
                            -99.8940910002072,
                            27.8929500000596
                        ],
                        [
                            -99.8934559999975,
                            27.8992079996156
                        ],
                        [
                            -99.9000799999345,
                            27.9121420000835
                        ],
                        [
                            -99.9174610002087,
                            27.9179729996492
                        ],
                        [
                            -99.9260350000824,
                            27.9274840001492
                        ],
                        [
                            -99.9241300003515,
                            27.9313790003283
                        ],
                        [
                            -99.926554999766,
                            27.9357010001749
                        ],
                        [
                            -99.9328659997086,
                            27.9372700001953
                        ],
                        [
                            -99.9371420002239,
                            27.9405370003067
                        ],
                        [
                            -99.9385409996165,
                            27.95405899967
                        ],
                        [
                            -99.932161000077,
                            27.9677099998746
                        ],
                        [
                            -99.9318110002726,
                            27.9809679999464
                        ],
                        [
                            -99.9627679995855,
                            27.9835369999099
                        ],
                        [
                            -99.977280999856,
                            27.9895050001095
                        ],
                        [
                            -99.9849230003174,
                            27.9907289997384
                        ],
                        [
                            -99.991446999797,
                            27.9945599998281
                        ],
                        [
                            -100.008631000428,
                            28.0239640001902
                        ],
                        [
                            -100.012839000273,
                            28.0372030000558
                        ],
                        [
                            -100.017913000306,
                            28.0647880003544
                        ],
                        [
                            -100.028724000172,
                            28.0731190003324
                        ],
                        [
                            -100.046107000096,
                            28.0790690000142
                        ],
                        [
                            -100.053121999845,
                            28.0847310003563
                        ],
                        [
                            -100.056982000434,
                            28.0942080002382
                        ],
                        [
                            -100.055594999838,
                            28.1011419996753
                        ],
                        [
                            -100.056491999989,
                            28.1041869999915
                        ],
                        [
                            -100.067651000009,
                            28.1136029999163
                        ],
                        [
                            -100.07547400022,
                            28.12488200018
                        ],
                        [
                            -100.084327999578,
                            28.1448190003129
                        ],
                        [
                            -100.090288999716,
                            28.1483129996436
                        ],
                        [
                            -100.119627000427,
                            28.155587999729
                        ],
                        [
                            -100.14109900037,
                            28.1681480000688
                        ],
                        [
                            -100.160588999691,
                            28.1681600000959
                        ],
                        [
                            -100.168437000017,
                            28.1715419996402
                        ],
                        [
                            -100.174412000399,
                            28.1794479996455
                        ],
                        [
                            -100.185694000443,
                            28.1857809997808
                        ],
                        [
                            -100.196498999562,
                            28.1902179999536
                        ],
                        [
                            -100.208057999615,
                            28.1903829996148
                        ],
                        [
                            -100.212103999977,
                            28.1965099997851
                        ],
                        [
                            -100.21411199964,
                            28.2056939998871
                        ],
                        [
                            -100.213452000214,
                            28.2104160000137
                        ],
                        [
                            -100.217564999625,
                            28.2269340003189
                        ],
                        [
                            -100.220287999863,
                            28.2322100002136
                        ],
                        [
                            -100.223628999695,
                            28.235222999951
                        ],
                        [
                            -100.227572999949,
                            28.2358579997132
                        ],
                        [
                            -100.246205000376,
                            28.2340909997261
                        ],
                        [
                            -100.251635999887,
                            28.236179999775
                        ],
                        [
                            -100.267602999801,
                            28.2502690000174
                        ],
                        [
                            -100.283177000254,
                            28.2706340000967
                        ],
                        [
                            -100.291397000125,
                            28.2753970002251
                        ],
                        [
                            -100.294112999793,
                            28.2804040001012
                        ],
                        [
                            -100.294296000091,
                            28.2843800003145
                        ],
                        [
                            -100.287553000328,
                            28.3010949996431
                        ],
                        [
                            -100.286476000253,
                            28.3122950003445
                        ],
                        [
                            -100.288638999727,
                            28.3169759996464
                        ],
                        [
                            -100.314198999864,
                            28.3458590002346
                        ],
                        [
                            -100.317245000143,
                            28.3573809999188
                        ],
                        [
                            -100.320391999806,
                            28.3621170000583
                        ],
                        [
                            -100.34187100032,
                            28.3849529998005
                        ],
                        [
                            -100.34440200004,
                            28.3896619997907
                        ],
                        [
                            -100.349588000226,
                            28.4026039996949
                        ],
                        [
                            -100.343946999755,
                            28.4101190001302
                        ],
                        [
                            -100.337063000426,
                            28.4271519999701
                        ],
                        [
                            -100.336187000192,
                            28.430180999976
                        ],
                        [
                            -100.337798000195,
                            28.442960000246
                        ],
                        [
                            -100.341534000036,
                            28.4495710003588
                        ],
                        [
                            -100.350785999778,
                            28.459245999688
                        ],
                        [
                            -100.357497999578,
                            28.4636419996676
                        ],
                        [
                            -100.368288000426,
                            28.4771959999453
                        ],
                        [
                            -100.365981999938,
                            28.4811160003008
                        ],
                        [
                            -100.352235000297,
                            28.4826379997263
                        ],
                        [
                            -100.344181000107,
                            28.4862490001557
                        ],
                        [
                            -100.337140000419,
                            28.4917290002447
                        ],
                        [
                            -100.333813999757,
                            28.4992519997909
                        ],
                        [
                            -100.338517999895,
                            28.5018330001901
                        ],
                        [
                            -100.356037000261,
                            28.5048709998614
                        ],
                        [
                            -100.362148000187,
                            28.508399000114
                        ],
                        [
                            -100.388262000344,
                            28.514862999739
                        ],
                        [
                            -100.394988000388,
                            28.5283580003128
                        ],
                        [
                            -100.405057999739,
                            28.5357799996994
                        ],
                        [
                            -100.411269000122,
                            28.550704999766
                        ],
                        [
                            -100.40888800019,
                            28.5572810000219
                        ],
                        [
                            -100.405170999717,
                            28.5607119996572
                        ],
                        [
                            -100.39730099965,
                            28.5755779999969
                        ],
                        [
                            -100.396800000232,
                            28.5804009998085
                        ],
                        [
                            -100.398462000289,
                            28.5851689998789
                        ],
                        [
                            -100.429856000343,
                            28.5964410003579
                        ],
                        [
                            -100.431870999679,
                            28.5978410002164
                        ],
                        [
                            -100.433623000147,
                            28.6061170003778
                        ],
                        [
                            -100.44731999956,
                            28.6093250002414
                        ],
                        [
                            -100.448624000314,
                            28.617049999749
                        ],
                        [
                            -100.445528999632,
                            28.6371440002362
                        ],
                        [
                            -100.447091000129,
                            28.6421969999522
                        ],
                        [
                            -100.462866000423,
                            28.6413640002135
                        ],
                        [
                            -100.474494000073,
                            28.6470709999932
                        ],
                        [
                            -100.479635999879,
                            28.6552250001434
                        ],
                        [
                            -100.495863000085,
                            28.6585689996616
                        ],
                        [
                            -100.500353999787,
                            28.6619600001151
                        ],
                        [
                            -100.510054999965,
                            28.690722999831
                        ],
                        [
                            -100.511998000229,
                            28.705351999725
                        ],
                        [
                            -100.506700999714,
                            28.7167450002021
                        ],
                        [
                            -100.507612999934,
                            28.7405990003689
                        ],
                        [
                            -100.515155999762,
                            28.7429469997187
                        ],
                        [
                            -100.514399000252,
                            28.7502519996697
                        ],
                        [
                            -100.519226000413,
                            28.7561610001299
                        ],
                        [
                            -100.522482999681,
                            28.757786000131
                        ],
                        [
                            -100.528474999781,
                            28.7561059997673
                        ],
                        [
                            -100.533408000248,
                            28.7592070003324
                        ],
                        [
                            -100.530045999601,
                            28.7704230000111
                        ],
                        [
                            -100.537771999728,
                            28.7807760000677
                        ],
                        [
                            -100.532430999731,
                            28.7910630002515
                        ],
                        [
                            -100.535437999652,
                            28.8051949996397
                        ],
                        [
                            -100.542172999917,
                            28.8084659998097
                        ],
                        [
                            -100.545199999929,
                            28.8120820000293
                        ],
                        [
                            -100.547323999943,
                            28.825817000295
                        ],
                        [
                            -100.553130000271,
                            28.8282490002713
                        ],
                        [
                            -100.561443000029,
                            28.8291740000877
                        ],
                        [
                            -100.570474999662,
                            28.8260359998356
                        ],
                        [
                            -100.574727999713,
                            28.8287880002278
                        ],
                        [
                            -100.576845999879,
                            28.8361679996801
                        ],
                        [
                            -100.572878000233,
                            28.8482059999296
                        ],
                        [
                            -100.580502000253,
                            28.8560079999067
                        ],
                        [
                            -100.591040000307,
                            28.8630540003768
                        ],
                        [
                            -100.590768000321,
                            28.872696999953
                        ],
                        [
                            -100.596803999903,
                            28.8734020003313
                        ],
                        [
                            -100.598876999864,
                            28.8755909999972
                        ],
                        [
                            -100.597941000254,
                            28.8801459996281
                        ],
                        [
                            -100.591041000132,
                            28.8830079997283
                        ],
                        [
                            -100.589498999726,
                            28.8863580001803
                        ],
                        [
                            -100.592503999997,
                            28.8894479997065
                        ],
                        [
                            -100.599870000274,
                            28.8864689999242
                        ],
                        [
                            -100.603011000089,
                            28.8872269998721
                        ],
                        [
                            -100.603792999713,
                            28.8894580003473
                        ],
                        [
                            -100.601089999566,
                            28.9012879996763
                        ],
                        [
                            -100.614221999988,
                            28.9017180001245
                        ],
                        [
                            -100.618215999572,
                            28.9085219997988
                        ],
                        [
                            -100.622436000011,
                            28.9097730002679
                        ],
                        [
                            -100.624898999959,
                            28.9082699997572
                        ],
                        [
                            -100.625406999947,
                            28.9048979996687
                        ],
                        [
                            -100.631610999759,
                            28.9028389999138
                        ],
                        [
                            -100.63661899967,
                            28.9115079998789
                        ],
                        [
                            -100.640567999948,
                            28.9142119997705
                        ],
                        [
                            -100.63917000038,
                            28.9162889999406
                        ],
                        [
                            -100.631060999938,
                            28.9169149997529
                        ],
                        [
                            -100.629706000029,
                            28.9211460003524
                        ],
                        [
                            -100.630720000355,
                            28.9240059996442
                        ],
                        [
                            -100.638857000386,
                            28.9276219998035
                        ],
                        [
                            -100.643749999771,
                            28.937527999723
                        ],
                        [
                            -100.651511999883,
                            28.9434320000541
                        ],
                        [
                            -100.646992999693,
                            28.9570789996476
                        ],
                        [
                            -100.650319000354,
                            28.9623360000385
                        ],
                        [
                            -100.646636000216,
                            28.9669289997325
                        ],
                        [
                            -100.648653999925,
                            28.9774220002917
                        ],
                        [
                            -100.648104000104,
                            28.9838599998949
                        ],
                        [
                            -100.646040000364,
                            28.9879810003655
                        ],
                        [
                            -100.649163999563,
                            28.9962850003205
                        ],
                        [
                            -100.64866399997,
                            29.0002429999161
                        ],
                        [
                            -100.650946000168,
                            29.0082539998448
                        ],
                        [
                            -100.653758000095,
                            29.0153559996089
                        ],
                        [
                            -100.656109999715,
                            29.017224000173
                        ],
                        [
                            -100.660207999955,
                            29.0314970002562
                        ],
                        [
                            -100.663212000401,
                            29.0480420001581
                        ],
                        [
                            -100.662507999696,
                            29.0581069998361
                        ],
                        [
                            -100.66402099979,
                            29.0741640003686
                        ],
                        [
                            -100.675199999901,
                            29.1007410001262
                        ],
                        [
                            -100.683926999936,
                            29.1102389998668
                        ],
                        [
                            -100.69179000033,
                            29.1148539999601
                        ],
                        [
                            -100.709965999749,
                            29.1196839999394
                        ],
                        [
                            -100.727461999651,
                            29.1291230000628
                        ],
                        [
                            -100.737794999666,
                            29.1390789999447
                        ],
                        [
                            -100.739116000139,
                            29.1416580002103
                        ],
                        [
                            -100.737418999922,
                            29.1474429998237
                        ],
                        [
                            -100.739681000029,
                            29.1504859998112
                        ],
                        [
                            -100.746140000109,
                            29.1541489997706
                        ],
                        [
                            -100.759726000092,
                            29.1571499997366
                        ],
                        [
                            -100.763349999781,
                            29.1600840003611
                        ],
                        [
                            -100.763676000194,
                            29.1648450003503
                        ],
                        [
                            -100.77260400007,
                            29.1683689998897
                        ],
                        [
                            -100.775904999719,
                            29.1733440003575
                        ],
                        [
                            -100.766004000422,
                            29.1858469997905
                        ],
                        [
                            -100.767058999858,
                            29.1952869999993
                        ],
                        [
                            -100.769341000056,
                            29.1989110003865
                        ],
                        [
                            -100.776253999698,
                            29.2009289997038
                        ],
                        [
                            -100.777020000327,
                            29.2043290003272
                        ],
                        [
                            -100.774626999802,
                            29.2106339998823
                        ],
                        [
                            -100.785521000406,
                            29.2281369996676
                        ],
                        [
                            -100.791372000041,
                            29.2259449999887
                        ],
                        [
                            -100.795681000169,
                            29.2277300002559
                        ],
                        [
                            -100.797046000124,
                            29.2355859999007
                        ],
                        [
                            -100.794626999659,
                            29.2416120000799
                        ],
                        [
                            -100.79761900041,
                            29.2467759999838
                        ],
                        [
                            -100.806652999693,
                            29.2522639997706
                        ],
                        [
                            -100.816276999878,
                            29.2538640002198
                        ],
                        [
                            -100.816692999804,
                            29.2556389998961
                        ],
                        [
                            -100.813305999942,
                            29.2610569998066
                        ],
                        [
                            -100.813958999695,
                            29.2631370000775
                        ],
                        [
                            -100.816994000103,
                            29.2643320000028
                        ],
                        [
                            -100.823532999651,
                            29.2617419997681
                        ],
                        [
                            -100.834039999743,
                            29.2613999999627
                        ],
                        [
                            -100.839015999867,
                            29.263259000308
                        ],
                        [
                            -100.855840999575,
                            29.2755459999265
                        ],
                        [
                            -100.864658999846,
                            29.2760760000112
                        ],
                        [
                            -100.876048999844,
                            29.2795849997463
                        ],
                        [
                            -100.878883000411,
                            29.2821930000433
                        ],
                        [
                            -100.881958000104,
                            29.2990570003581
                        ],
                        [
                            -100.886842000167,
                            29.3078479998048
                        ],
                        [
                            -100.894930999618,
                            29.3103509998325
                        ],
                        [
                            -100.899033000057,
                            29.3096430000523
                        ],
                        [
                            -100.904733999904,
                            29.3121360003154
                        ],
                        [
                            -100.909457000308,
                            29.3176080000767
                        ],
                        [
                            -100.916627999693,
                            29.3192040003396
                        ],
                        [
                            -100.919499000069,
                            29.3253180003777
                        ],
                        [
                            -100.928504999763,
                            29.3269760000053
                        ],
                        [
                            -100.940616000185,
                            29.3331089998747
                        ],
                        [
                            -100.943197999959,
                            29.3419849997283
                        ],
                        [
                            -100.948974000149,
                            29.3472460003033
                        ],
                        [
                            -100.964325999945,
                            29.3473419997828
                        ],
                        [
                            -100.971744000101,
                            29.3513709999375
                        ],
                        [
                            -100.972917000436,
                            29.3545139997748
                        ],
                        [
                            -100.995607000022,
                            29.3634029996574
                        ],
                        [
                            -101.004206999835,
                            29.3647719998048
                        ],
                        [
                            -101.009976000352,
                            29.3682669998357
                        ],
                        [
                            -101.014273999711,
                            29.3741230000561
                        ],
                        [
                            -101.013272999803,
                            29.3803470000511
                        ],
                        [
                            -101.019258000231,
                            29.3909069999062
                        ],
                        [
                            -101.024015999896,
                            29.3936979998911
                        ],
                        [
                            -101.036604000345,
                            29.4061079997094
                        ],
                        [
                            -101.038600000312,
                            29.4107140003763
                        ],
                        [
                            -101.037642000062,
                            29.4146810003246
                        ],
                        [
                            -101.039460999579,
                            29.4165049998584
                        ],
                        [
                            -101.043363999826,
                            29.4298799998708
                        ],
                        [
                            -101.05695700038,
                            29.4407729999488
                        ],
                        [
                            -101.056844000402,
                            29.4475989997457
                        ],
                        [
                            -101.058149000083,
                            29.4478339999638
                        ],
                        [
                            -101.060150999898,
                            29.4586610002622
                        ],
                        [
                            -101.077184000018,
                            29.4641899999982
                        ],
                        [
                            -101.087148999786,
                            29.4694139997057
                        ],
                        [
                            -101.103699000032,
                            29.4705499999682
                        ],
                        [
                            -101.115253999888,
                            29.4684590001501
                        ],
                        [
                            -101.13003800032,
                            29.478420000172
                        ],
                        [
                            -101.137503000331,
                            29.4735420002018
                        ],
                        [
                            -101.14679799973,
                            29.4741869997114
                        ],
                        [
                            -101.151876999786,
                            29.477005000279
                        ],
                        [
                            -101.171664000106,
                            29.5039509998682
                        ],
                        [
                            -101.173824000105,
                            29.5145680001964
                        ],
                        [
                            -101.192715999925,
                            29.5202849999365
                        ],
                        [
                            -101.227417000374,
                            29.5223499999912
                        ],
                        [
                            -101.235273000197,
                            29.5248540003452
                        ],
                        [
                            -101.254894999763,
                            29.5203409999242
                        ],
                        [
                            -101.260838000358,
                            29.5299320001714
                        ],
                        [
                            -101.261175999568,
                            29.5367760002412
                        ],
                        [
                            -101.250384999794,
                            29.5566209996148
                        ],
                        [
                            -101.241030000121,
                            29.5650279999888
                        ],
                        [
                            -101.252297000096,
                            29.6041669996239
                        ],
                        [
                            -101.247332999668,
                            29.6184799998508
                        ],
                        [
                            -101.250383000144,
                            29.6241710003287
                        ],
                        [
                            -101.262232999552,
                            29.6306070003397
                        ],
                        [
                            -101.269101999712,
                            29.6301280001605
                        ],
                        [
                            -101.277709000096,
                            29.6210249998469
                        ],
                        [
                            -101.280494000259,
                            29.6151809998582
                        ],
                        [
                            -101.277438999759,
                            29.6065930002241
                        ],
                        [
                            -101.27787800015,
                            29.5908100000713
                        ],
                        [
                            -101.28533599959,
                            29.5820180002423
                        ],
                        [
                            -101.290961999993,
                            29.5715370003119
                        ],
                        [
                            -101.305532999989,
                            29.5779249996778
                        ],
                        [
                            -101.311997000093,
                            29.5860630003311
                        ],
                        [
                            -101.313372999919,
                            29.6022590000481
                        ],
                        [
                            -101.31170599984,
                            29.6153719997959
                        ],
                        [
                            -101.301427000252,
                            29.6346239997883
                        ],
                        [
                            -101.300027000136,
                            29.6407019997787
                        ],
                        [
                            -101.302516000023,
                            29.6504250002335
                        ],
                        [
                            -101.306943999976,
                            29.6557679999438
                        ],
                        [
                            -101.314135000351,
                            29.6590540003479
                        ],
                        [
                            -101.346017000301,
                            29.6622410002943
                        ],
                        [
                            -101.350327000254,
                            29.6606600003653
                        ],
                        [
                            -101.357140000336,
                            29.6492670003309
                        ],
                        [
                            -101.361312000196,
                            29.6500629999789
                        ],
                        [
                            -101.363217999752,
                            29.6526380003353
                        ],
                        [
                            -101.367105000105,
                            29.6708980002174
                        ],
                        [
                            -101.372696000347,
                            29.6780569998776
                        ],
                        [
                            -101.37277300034,
                            29.699428000278
                        ],
                        [
                            -101.388480999789,
                            29.7076289998252
                        ],
                        [
                            -101.396948000431,
                            29.7139469997458
                        ],
                        [
                            -101.398361999892,
                            29.716999999937
                        ],
                        [
                            -101.396293999955,
                            29.7270550000964
                        ],
                        [
                            -101.397008999632,
                            29.7339630002224
                        ],
                        [
                            -101.400635999694,
                            29.7380790002469
                        ],
                        [
                            -101.410023999877,
                            29.7414979998461
                        ],
                        [
                            -101.415584000158,
                            29.7465339997952
                        ],
                        [
                            -101.414588000273,
                            29.7524179996175
                        ],
                        [
                            -101.40392600037,
                            29.7575870001248
                        ],
                        [
                            -101.398982999858,
                            29.7621750002892
                        ],
                        [
                            -101.398672000412,
                            29.7668979996914
                        ],
                        [
                            -101.402318999667,
                            29.7711899998257
                        ],
                        [
                            -101.410932999723,
                            29.7701520003613
                        ],
                        [
                            -101.438145999697,
                            29.7509199996134
                        ],
                        [
                            -101.442588999719,
                            29.7497219998126
                        ],
                        [
                            -101.448881000293,
                            29.7506989996457
                        ],
                        [
                            -101.455224000022,
                            29.7718740000564
                        ],
                        [
                            -101.452806000281,
                            29.7837910002248
                        ],
                        [
                            -101.455798999958,
                            29.7880489998497
                        ],
                        [
                            -101.460905999603,
                            29.7896629999703
                        ],
                        [
                            -101.478864000361,
                            29.7840850000117
                        ],
                        [
                            -101.487089000255,
                            29.7738679997751
                        ],
                        [
                            -101.503222999676,
                            29.7645819996921
                        ],
                        [
                            -101.517580000311,
                            29.7650049997044
                        ],
                        [
                            -101.530255999724,
                            29.7595839996322
                        ],
                        [
                            -101.535997999579,
                            29.7589500003692
                        ],
                        [
                            -101.538637999977,
                            29.7605059998819
                        ],
                        [
                            -101.539432000196,
                            29.766421000316
                        ],
                        [
                            -101.537919999927,
                            29.7880649999911
                        ],
                        [
                            -101.53501000009,
                            29.7976790000703
                        ],
                        [
                            -101.541900999991,
                            29.8107810003408
                        ],
                        [
                            -101.544768999995,
                            29.811868000118
                        ],
                        [
                            -101.54738199973,
                            29.8107160002336
                        ],
                        [
                            -101.552485000076,
                            29.8042329998578
                        ],
                        [
                            -101.567749000009,
                            29.7987270001409
                        ],
                        [
                            -101.574119000401,
                            29.7882219997332
                        ],
                        [
                            -101.572222999993,
                            29.7733190001583
                        ],
                        [
                            -101.575807000397,
                            29.7693190001672
                        ],
                        [
                            -101.603680999621,
                            29.7743990002994
                        ],
                        [
                            -101.617906000011,
                            29.7699300002649
                        ],
                        [
                            -101.62690000001,
                            29.770761999631
                        ],
                        [
                            -101.630290000245,
                            29.7683350001432
                        ],
                        [
                            -101.635463999838,
                            29.7583600002097
                        ],
                        [
                            -101.644566999616,
                            29.7542330003101
                        ],
                        [
                            -101.650757999908,
                            29.7570929999039
                        ],
                        [
                            -101.654458999589,
                            29.7651210002715
                        ],
                        [
                            -101.659686999609,
                            29.7674110000599
                        ],
                        [
                            -101.661723999584,
                            29.7708570002184
                        ],
                        [
                            -101.670623000046,
                            29.7689279998197
                        ],
                        [
                            -101.674563999927,
                            29.7652319998614
                        ],
                        [
                            -101.675298999696,
                            29.7610839998601
                        ],
                        [
                            -101.677804000375,
                            29.7602919998051
                        ],
                        [
                            -101.681439999759,
                            29.7607840003631
                        ],
                        [
                            -101.68621900024,
                            29.7685899998149
                        ],
                        [
                            -101.689633999691,
                            29.7710349999519
                        ],
                        [
                            -101.698176999603,
                            29.765671999766
                        ],
                        [
                            -101.708872999841,
                            29.7616420002163
                        ],
                        [
                            -101.71278100011,
                            29.7636680003675
                        ],
                        [
                            -101.719729999737,
                            29.7757379998498
                        ],
                        [
                            -101.724956000106,
                            29.7758970001789
                        ],
                        [
                            -101.72828900044,
                            29.7726849997013
                        ],
                        [
                            -101.733351999705,
                            29.7711800002939
                        ],
                        [
                            -101.738691999877,
                            29.7729850002087
                        ],
                        [
                            -101.74133499975,
                            29.7782779999293
                        ],
                        [
                            -101.744314999906,
                            29.779195000292
                        ],
                        [
                            -101.753881000365,
                            29.7773269999972
                        ],
                        [
                            -101.773825000146,
                            29.7886750000666
                        ],
                        [
                            -101.784899999602,
                            29.7886479996499
                        ],
                        [
                            -101.785773000362,
                            29.783247000379
                        ],
                        [
                            -101.788530999862,
                            29.7792720000117
                        ],
                        [
                            -101.80511399972,
                            29.7803500000057
                        ],
                        [
                            -101.810810000443,
                            29.7836270000323
                        ],
                        [
                            -101.813977000197,
                            29.7923300001376
                        ],
                        [
                            -101.813461999638,
                            29.7944119997003
                        ],
                        [
                            -101.806514999661,
                            29.7992959996477
                        ],
                        [
                            -101.80361300022,
                            29.8034460001474
                        ],
                        [
                            -101.812515999982,
                            29.8097859998285
                        ],
                        [
                            -101.817776999613,
                            29.8116350003798
                        ],
                        [
                            -101.822558999568,
                            29.8103409999291
                        ],
                        [
                            -101.82560800022,
                            29.8070530001915
                        ],
                        [
                            -101.825532999877,
                            29.8039629999259
                        ],
                        [
                            -101.818693999855,
                            29.7915000003327
                        ],
                        [
                            -101.823655999735,
                            29.7873980001588
                        ],
                        [
                            -101.837594999896,
                            29.79336399965
                        ],
                        [
                            -101.84692199998,
                            29.8024419997368
                        ],
                        [
                            -101.848948000084,
                            29.8067429997961
                        ],
                        [
                            -101.852719999911,
                            29.807791000007
                        ],
                        [
                            -101.855861999551,
                            29.8072170001881
                        ],
                        [
                            -101.866501999713,
                            29.7980139996419
                        ],
                        [
                            -101.875771999895,
                            29.7937959998995
                        ],
                        [
                            -101.880588000186,
                            29.7957599997744
                        ],
                        [
                            -101.883984000269,
                            29.7935419999101
                        ],
                        [
                            -101.892882999833,
                            29.7976879999175
                        ],
                        [
                            -101.911594999728,
                            29.7980399997177
                        ],
                        [
                            -101.915723000105,
                            29.7952129997332
                        ],
                        [
                            -101.922249999958,
                            29.7860260001913
                        ],
                        [
                            -101.929323000331,
                            29.7825759999599
                        ],
                        [
                            -101.933527999803,
                            29.7851930002805
                        ],
                        [
                            -101.932671999661,
                            29.7932429996121
                        ],
                        [
                            -101.935605999787,
                            29.7995609997958
                        ],
                        [
                            -101.945890000296,
                            29.8017549998799
                        ],
                        [
                            -101.954090000076,
                            29.795776999638
                        ],
                        [
                            -101.957514999573,
                            29.7957660000476
                        ],
                        [
                            -101.963376999977,
                            29.8001490001799
                        ],
                        [
                            -101.965465000006,
                            29.8059950000907
                        ],
                        [
                            -101.964716999818,
                            29.8093140000004
                        ],
                        [
                            -101.966740000448,
                            29.8126980000824
                        ],
                        [
                            -101.976264000176,
                            29.8160399998136
                        ],
                        [
                            -101.98156699964,
                            29.8150199998413
                        ],
                        [
                            -101.983052000144,
                            29.8099429997845
                        ],
                        [
                            -101.981807999664,
                            29.8061670000556
                        ],
                        [
                            -101.984303000297,
                            29.7992620003338
                        ],
                        [
                            -101.987305000195,
                            29.7968419998245
                        ],
                        [
                            -101.991031999816,
                            29.7977229999221
                        ],
                        [
                            -101.994146999692,
                            29.8045160002052
                        ],
                        [
                            -101.997021000442,
                            29.8061089997923
                        ],
                        [
                            -102.015226999998,
                            29.7974910001312
                        ],
                        [
                            -102.017927999597,
                            29.7981269997121
                        ],
                        [
                            -102.021906000187,
                            29.8024670001124
                        ],
                        [
                            -102.028423999818,
                            29.8018800002152
                        ],
                        [
                            -102.035817999684,
                            29.8040629998702
                        ],
                        [
                            -102.039827000236,
                            29.8020469996773
                        ],
                        [
                            -102.041188999819,
                            29.7990580003107
                        ],
                        [
                            -102.038626000312,
                            29.7916189997055
                        ],
                        [
                            -102.041418000148,
                            29.7896559998743
                        ],
                        [
                            -102.046292999989,
                            29.7899400003691
                        ],
                        [
                            -102.049652000263,
                            29.7852760001876
                        ],
                        [
                            -102.051957999851,
                            29.7849770001435
                        ],
                        [
                            -102.055000999757,
                            29.7870300000424
                        ],
                        [
                            -102.07318700012,
                            29.7865239999147
                        ],
                        [
                            -102.077108999735,
                            29.7920509996919
                        ],
                        [
                            -102.084378999753,
                            29.7950639999449
                        ],
                        [
                            -102.09467800033,
                            29.7912449997523
                        ],
                        [
                            -102.10138299956,
                            29.7936119996892
                        ],
                        [
                            -102.108782000347,
                            29.7918450001295
                        ],
                        [
                            -102.112266000294,
                            29.7935569997555
                        ],
                        [
                            -102.11570599986,
                            29.7923010002868
                        ],
                        [
                            -102.117916000087,
                            29.7961609999601
                        ],
                        [
                            -102.117141000135,
                            29.8003630000532
                        ],
                        [
                            -102.119753999871,
                            29.8024789998539
                        ],
                        [
                            -102.128904000403,
                            29.7985300000617
                        ],
                        [
                            -102.143565999738,
                            29.80332000011
                        ],
                        [
                            -102.150892999657,
                            29.8115919996495
                        ],
                        [
                            -102.155610000213,
                            29.8120420001404
                        ],
                        [
                            -102.159796000317,
                            29.8146850003274
                        ],
                        [
                            -102.166445000368,
                            29.8253850001148
                        ],
                        [
                            -102.179964000404,
                            29.8263750003438
                        ],
                        [
                            -102.18130500007,
                            29.8311500003157
                        ],
                        [
                            -102.178509000037,
                            29.8394539999978
                        ],
                        [
                            -102.182280999864,
                            29.845888999979
                        ],
                        [
                            -102.187026999834,
                            29.848656000147
                        ],
                        [
                            -102.191654999803,
                            29.8466380000171
                        ],
                        [
                            -102.193357000043,
                            29.8393559998678
                        ],
                        [
                            -102.196086000129,
                            29.8365759998613
                        ],
                        [
                            -102.199613999559,
                            29.836870000353
                        ],
                        [
                            -102.209938000251,
                            29.8443590000077
                        ],
                        [
                            -102.223528000432,
                            29.8405300003025
                        ],
                        [
                            -102.231790999961,
                            29.8482509999774
                        ],
                        [
                            -102.241379999986,
                            29.847686000344
                        ],
                        [
                            -102.24489200042,
                            29.8489460000094
                        ],
                        [
                            -102.24322500034,
                            29.8584130002831
                        ],
                        [
                            -102.246458000217,
                            29.8641280001904
                        ],
                        [
                            -102.249774999759,
                            29.8632929999301
                        ],
                        [
                            -102.253178000414,
                            29.8530680003019
                        ],
                        [
                            -102.261495000369,
                            29.853240999887
                        ],
                        [
                            -102.264268999763,
                            29.8556299999529
                        ],
                        [
                            -102.261881999984,
                            29.8635699996462
                        ],
                        [
                            -102.264842000049,
                            29.8674960003088
                        ],
                        [
                            -102.268994999642,
                            29.8677649997287
                        ],
                        [
                            -102.276591000073,
                            29.8629539998865
                        ],
                        [
                            -102.280891999804,
                            29.8628189998725
                        ],
                        [
                            -102.283305000421,
                            29.864292
                        ],
                        [
                            -102.286874999684,
                            29.8712019999213
                        ],
                        [
                            -102.29587100023,
                            29.87361000023
                        ],
                        [
                            -102.300557999751,
                            29.8775969996406
                        ],
                        [
                            -102.312297999554,
                            29.877118999994
                        ],
                        [
                            -102.315939999684,
                            29.8799930000062
                        ],
                        [
                            -102.321186000145,
                            29.8786850000095
                        ],
                        [
                            -102.324340000379,
                            29.8737120001483
                        ],
                        [
                            -102.32346300032,
                            29.8639169997777
                        ],
                        [
                            -102.331930999889,
                            29.8628239997321
                        ],
                        [
                            -102.333310000088,
                            29.8680709999622
                        ],
                        [
                            -102.340920999689,
                            29.8693729999641
                        ],
                        [
                            -102.343090999734,
                            29.8652370002303
                        ],
                        [
                            -102.349677999861,
                            29.8626340002204
                        ],
                        [
                            -102.352272000228,
                            29.8527560000625
                        ],
                        [
                            -102.361236000089,
                            29.8491299996788
                        ],
                        [
                            -102.364556999829,
                            29.8453349998193
                        ],
                        [
                            -102.362929000108,
                            29.8314890000303
                        ],
                        [
                            -102.369642999558,
                            29.8206619998864
                        ],
                        [
                            -102.373195000175,
                            29.8078780000954
                        ],
                        [
                            -102.3775119998,
                            29.799997000327
                        ],
                        [
                            -102.377199999631,
                            29.7898890001337
                        ],
                        [
                            -102.390044000172,
                            29.7819210000655
                        ],
                        [
                            -102.386586000164,
                            29.7774459998452
                        ],
                        [
                            -102.385038999735,
                            29.7717439999343
                        ],
                        [
                            -102.387227000222,
                            29.7613920002704
                        ],
                        [
                            -102.389679000299,
                            29.7618799996102
                        ],
                        [
                            -102.394806999861,
                            29.7691630000759
                        ],
                        [
                            -102.398924000367,
                            29.7695220003221
                        ],
                        [
                            -102.404537000351,
                            29.7651810002969
                        ],
                        [
                            -102.408976000175,
                            29.7645309997967
                        ],
                        [
                            -102.41197300005,
                            29.7681679997954
                        ],
                        [
                            -102.421474000211,
                            29.7692579998406
                        ],
                        [
                            -102.433665999926,
                            29.7768529999198
                        ],
                        [
                            -102.439297000351,
                            29.7732590003288
                        ],
                        [
                            -102.447381999605,
                            29.777501999633
                        ],
                        [
                            -102.457692000053,
                            29.7745849999538
                        ],
                        [
                            -102.462193999626,
                            29.7812899996725
                        ],
                        [
                            -102.46545300034,
                            29.7833900003726
                        ],
                        [
                            -102.469728000133,
                            29.7807960001615
                        ],
                        [
                            -102.471994000437,
                            29.7763869997719
                        ],
                        [
                            -102.480665000395,
                            29.7760689996376
                        ],
                        [
                            -102.48228499972,
                            29.7779609999159
                        ],
                        [
                            -102.481771999709,
                            29.7828379998094
                        ],
                        [
                            -102.487251999624,
                            29.7865849998592
                        ],
                        [
                            -102.497987000219,
                            29.7814480002232
                        ],
                        [
                            -102.514939000148,
                            29.7842989999342
                        ],
                        [
                            -102.517616000356,
                            29.7835800001082
                        ],
                        [
                            -102.519186000351,
                            29.7803679996973
                        ],
                        [
                            -102.518382000087,
                            29.7786640002484
                        ],
                        [
                            -102.510727000105,
                            29.7738819998353
                        ],
                        [
                            -102.513503000047,
                            29.765439000075
                        ],
                        [
                            -102.520513999599,
                            29.7607390001272
                        ],
                        [
                            -102.534235000199,
                            29.7549379998828
                        ],
                        [
                            -102.540715000196,
                            29.7480689997723
                        ],
                        [
                            -102.547977999642,
                            29.7446440001518
                        ],
                        [
                            -102.554669000425,
                            29.7487279997676
                        ],
                        [
                            -102.555184000086,
                            29.7558620003527
                        ],
                        [
                            -102.562720000242,
                            29.7675089997403
                        ],
                        [
                            -102.567662999856,
                            29.7714629997597
                        ],
                        [
                            -102.570567999669,
                            29.7710049997473
                        ],
                        [
                            -102.57407699973,
                            29.7673579999063
                        ],
                        [
                            -102.572053999999,
                            29.7571889999764
                        ],
                        [
                            -102.573796000421,
                            29.7548960003326
                        ],
                        [
                            -102.580338000342,
                            29.7549910003671
                        ],
                        [
                            -102.589206999769,
                            29.7490690000635
                        ],
                        [
                            -102.597618000334,
                            29.7514149996823
                        ],
                        [
                            -102.612426000157,
                            29.7482720000822
                        ],
                        [
                            -102.622790000134,
                            29.7363390003736
                        ],
                        [
                            -102.630409999956,
                            29.7341810003843
                        ],
                        [
                            -102.645184000343,
                            29.7338390001479
                        ],
                        [
                            -102.660244999886,
                            29.7356290002982
                        ],
                        [
                            -102.66714800038,
                            29.739128999956
                        ],
                        [
                            -102.668402999833,
                            29.7459299998003
                        ],
                        [
                            -102.673997000448,
                            29.744573999683
                        ],
                        [
                            -102.677021000088,
                            29.7415229998389
                        ],
                        [
                            -102.68176299986,
                            29.7280729998109
                        ],
                        [
                            -102.691372999801,
                            29.7213719997482
                        ],
                        [
                            -102.689035000425,
                            29.7050379998358
                        ],
                        [
                            -102.697026999793,
                            29.6987759997111
                        ],
                        [
                            -102.699672000214,
                            29.6862869996877
                        ],
                        [
                            -102.693644000128,
                            29.6766849998994
                        ],
                        [
                            -102.696723000019,
                            29.6719580001265
                        ],
                        [
                            -102.704083000448,
                            29.6713399998631
                        ],
                        [
                            -102.70864600012,
                            29.666986999792
                        ],
                        [
                            -102.711283000145,
                            29.6604669997285
                        ],
                        [
                            -102.715208999958,
                            29.6564049999398
                        ],
                        [
                            -102.722827999955,
                            29.6514269996438
                        ],
                        [
                            -102.725740000339,
                            29.6514169998875
                        ],
                        [
                            -102.724065999689,
                            29.6485150003734
                        ],
                        [
                            -102.725906000021,
                            29.6432419998681
                        ],
                        [
                            -102.729784000153,
                            29.640652000345
                        ],
                        [
                            -102.736573999771,
                            29.6418250000849
                        ],
                        [
                            -102.741949000103,
                            29.6331930003797
                        ],
                        [
                            -102.741956999601,
                            29.630318999879
                        ],
                        [
                            -102.739052999612,
                            29.6255569999251
                        ],
                        [
                            -102.738557000218,
                            29.6181759999745
                        ],
                        [
                            -102.738790999672,
                            29.6088279999914
                        ],
                        [
                            -102.741564999964,
                            29.6028279997794
                        ],
                        [
                            -102.739721000332,
                            29.5999250003162
                        ],
                        [
                            -102.74054300014,
                            29.5974990002138
                        ],
                        [
                            -102.746331000026,
                            29.5926479997954
                        ],
                        [
                            -102.760322999889,
                            29.5986030000637
                        ],
                        [
                            -102.765374000357,
                            29.5977570003225
                        ],
                        [
                            -102.768301999736,
                            29.5952090000568
                        ],
                        [
                            -102.76226299978,
                            29.5797509999079
                        ],
                        [
                            -102.766201000187,
                            29.5722060002856
                        ],
                        [
                            -102.770649000231,
                            29.5738379998233
                        ],
                        [
                            -102.773866000214,
                            29.5731239998532
                        ],
                        [
                            -102.773422999625,
                            29.5668650002889
                        ],
                        [
                            -102.777766000088,
                            29.5609799996848
                        ],
                        [
                            -102.777339000291,
                            29.5547490002853
                        ],
                        [
                            -102.771228000365,
                            29.5483969999609
                        ],
                        [
                            -102.776808999663,
                            29.5470550001693
                        ],
                        [
                            -102.780265999845,
                            29.5424659999697
                        ],
                        [
                            -102.79145199963,
                            29.5428239998704
                        ],
                        [
                            -102.793026999648,
                            29.5399459997374
                        ],
                        [
                            -102.792007000372,
                            29.5330040001052
                        ],
                        [
                            -102.797180000139,
                            29.5293979996538
                        ],
                        [
                            -102.798442999988,
                            29.5266670003338
                        ],
                        [
                            -102.808319999893,
                            29.5229300003161
                        ],
                        [
                            -102.808738000368,
                            29.5199480003471
                        ],
                        [
                            -102.806388000398,
                            29.5153810000936
                        ],
                        [
                            -102.807473999796,
                            29.5038509999254
                        ],
                        [
                            -102.804337000179,
                            29.5011770003539
                        ],
                        [
                            -102.807267999932,
                            29.4942949996458
                        ],
                        [
                            -102.800673000307,
                            29.4862350001401
                        ],
                        [
                            -102.804209999957,
                            29.4837160001808
                        ],
                        [
                            -102.812565000446,
                            29.4836359997352
                        ],
                        [
                            -102.814250999894,
                            29.4823980003296
                        ],
                        [
                            -102.815527000161,
                            29.4760039999724
                        ],
                        [
                            -102.813997000349,
                            29.4714009999465
                        ],
                        [
                            -102.822185000433,
                            29.464558000166
                        ],
                        [
                            -102.825636999695,
                            29.4579449999029
                        ],
                        [
                            -102.823953999722,
                            29.4521120003685
                        ],
                        [
                            -102.819528000317,
                            29.4507200002626
                        ],
                        [
                            -102.831044999639,
                            29.4442539997768
                        ],
                        [
                            -102.830862000239,
                            29.4343529999417
                        ],
                        [
                            -102.832479999915,
                            29.4324309998484
                        ],
                        [
                            -102.826924999657,
                            29.4176999997164
                        ],
                        [
                            -102.833443000188,
                            29.4104979997168
                        ],
                        [
                            -102.832582000022,
                            29.4084450001832
                        ],
                        [
                            -102.826517999952,
                            29.4075629999535
                        ],
                        [
                            -102.825855000153,
                            29.4025440002872
                        ],
                        [
                            -102.819002999712,
                            29.4035989997071
                        ],
                        [
                            -102.813675999959,
                            29.4023259999243
                        ],
                        [
                            -102.812381000323,
                            29.4005249999494
                        ],
                        [
                            -102.815859000422,
                            29.3970160002803
                        ],
                        [
                            -102.820039999605,
                            29.398347999938
                        ],
                        [
                            -102.82561199958,
                            29.3969460002506
                        ],
                        [
                            -102.833059999873,
                            29.3880860000986
                        ],
                        [
                            -102.834023000146,
                            29.379056000277
                        ],
                        [
                            -102.84068999974,
                            29.3776410001612
                        ],
                        [
                            -102.845018000134,
                            29.3744409997965
                        ],
                        [
                            -102.845316999885,
                            29.3647320000912
                        ],
                        [
                            -102.840077999995,
                            29.3618509999941
                        ],
                        [
                            -102.839375000013,
                            29.3589220000895
                        ],
                        [
                            -102.862127000422,
                            29.3513429999008
                        ],
                        [
                            -102.87293200044,
                            29.3522590003532
                        ],
                        [
                            -102.877505000158,
                            29.3548909997455
                        ],
                        [
                            -102.884365000096,
                            29.347945999653
                        ],
                        [
                            -102.879875000219,
                            29.3391479996598
                        ],
                        [
                            -102.884736999642,
                            29.3259949998896
                        ],
                        [
                            -102.884449999587,
                            29.3206780002579
                        ],
                        [
                            -102.887433000116,
                            29.3187549998014
                        ],
                        [
                            -102.887337999682,
                            29.3116299997799
                        ],
                        [
                            -102.893041000077,
                            29.3089070001191
                        ],
                        [
                            -102.888177999931,
                            29.2933590003363
                        ],
                        [
                            -102.890465999976,
                            29.2879779999454
                        ],
                        [
                            -102.896514000153,
                            29.2848310001713
                        ],
                        [
                            -102.898669999954,
                            29.2807300000613
                        ],
                        [
                            -102.902978000257,
                            29.2792250003151
                        ],
                        [
                            -102.903065000295,
                            29.2760810000137
                        ],
                        [
                            -102.899286999722,
                            29.2690919997189
                        ],
                        [
                            -102.902249000336,
                            29.2648199998339
                        ],
                        [
                            -102.905478000014,
                            29.2636230001949
                        ],
                        [
                            -102.906384000386,
                            29.2600830003
                        ],
                        [
                            -102.90306600012,
                            29.2543349996695
                        ],
                        [
                            -102.896626000306,
                            29.2541140002826
                        ],
                        [
                            -102.887818000081,
                            29.2457960000337
                        ],
                        [
                            -102.880280000275,
                            29.246065999829
                        ],
                        [
                            -102.871749000059,
                            29.2417759998131
                        ],
                        [
                            -102.87059699964,
                            29.2361090000639
                        ],
                        [
                            -102.871716000447,
                            29.2317190002597
                        ],
                        [
                            -102.866762000064,
                            29.2277439999813
                        ],
                        [
                            -102.867474000267,
                            29.2238830002785
                        ],
                        [
                            -102.877285999874,
                            29.2170870000084
                        ],
                        [
                            -102.878820999709,
                            29.213627000241
                        ],
                        [
                            -102.895100999619,
                            29.2085210003036
                        ],
                        [
                            -102.899490999937,
                            29.2094600003871
                        ],
                        [
                            -102.908653000165,
                            29.2196629999234
                        ],
                        [
                            -102.912323999709,
                            29.2194180000175
                        ],
                        [
                            -102.916081000366,
                            29.2157430001097
                        ],
                        [
                            -102.916022999741,
                            29.2119600003108
                        ],
                        [
                            -102.912733999788,
                            29.2067300001308
                        ],
                        [
                            -102.914195000003,
                            29.2001430002269
                        ],
                        [
                            -102.91893599995,
                            29.1990300000992
                        ],
                        [
                            -102.91782299989,
                            29.1908869999124
                        ],
                        [
                            -102.932511000063,
                            29.1947350003117
                        ],
                        [
                            -102.938271000359,
                            29.1881979996925
                        ],
                        [
                            -102.941464999878,
                            29.1917349996266
                        ],
                        [
                            -102.943378000005,
                            29.1914100000738
                        ],
                        [
                            -102.945430999874,
                            29.1889049997485
                        ],
                        [
                            -102.945711000256,
                            29.1830750003172
                        ],
                        [
                            -102.950888000222,
                            29.1781619996498
                        ],
                        [
                            -102.950885999673,
                            29.1736200000873
                        ],
                        [
                            -102.957273999609,
                            29.179039999689
                        ],
                        [
                            -102.975526999919,
                            29.1862979996534
                        ],
                        [
                            -102.981148000299,
                            29.1857379998861
                        ],
                        [
                            -102.986044000057,
                            29.1832190003213
                        ],
                        [
                            -102.989984999938,
                            29.1834720001984
                        ],
                        [
                            -102.995618000013,
                            29.1792909996655
                        ],
                        [
                            -102.998121000144,
                            29.1737690003207
                        ],
                        [
                            -102.996268000292,
                            29.1602199997207
                        ],
                        [
                            -103.002506000439,
                            29.1499590001788
                        ],
                        [
                            -103.008107999654,
                            29.1483819996222
                        ],
                        [
                            -103.00879700029,
                            29.1375689996454
                        ],
                        [
                            -103.012086000243,
                            29.137179000136
                        ],
                        [
                            -103.015651000381,
                            29.1339949999402
                        ],
                        [
                            -103.012092999916,
                            29.1261870001866
                        ],
                        [
                            -103.014258999763,
                            29.1249779998894
                        ],
                        [
                            -103.020471999796,
                            29.1256280003224
                        ],
                        [
                            -103.027592000026,
                            29.116020999803
                        ],
                        [
                            -103.037013999646,
                            29.1129009998647
                        ],
                        [
                            -103.032108999667,
                            29.1068269998087
                        ],
                        [
                            -103.033878999679,
                            29.1009720002413
                        ],
                        [
                            -103.039319000308,
                            29.0970439999803
                        ],
                        [
                            -103.045426999861,
                            29.0954270003756
                        ],
                        [
                            -103.05424900033,
                            29.1007980000193
                        ],
                        [
                            -103.064178999939,
                            29.0914760003539
                        ],
                        [
                            -103.069988999566,
                            29.093707999854
                        ],
                        [
                            -103.075824000206,
                            29.091537000178
                        ],
                        [
                            -103.080182999664,
                            29.0874369996822
                        ],
                        [
                            -103.07663000012,
                            29.0797700001788
                        ],
                        [
                            -103.077019000282,
                            29.0760170000563
                        ],
                        [
                            -103.087425000092,
                            29.0671079996727
                        ],
                        [
                            -103.085506000117,
                            29.0599610003925
                        ],
                        [
                            -103.085900000303,
                            29.0537360003703
                        ],
                        [
                            -103.090534999945,
                            29.0540979999391
                        ],
                        [
                            -103.092390000345,
                            29.0647040000841
                        ],
                        [
                            -103.095480000107,
                            29.0647269999235
                        ],
                        [
                            -103.096891000093,
                            29.0607679996582
                        ],
                        [
                            -103.10062600011,
                            29.060323000096
                        ],
                        [
                            -103.100435000314,
                            29.051375000297
                        ],
                        [
                            -103.105045999667,
                            29.0444680002887
                        ],
                        [
                            -103.106506000057,
                            29.038178999695
                        ],
                        [
                            -103.097993000283,
                            29.026264000061
                        ],
                        [
                            -103.101503000169,
                            29.0180989999284
                        ],
                        [
                            -103.108483999583,
                            29.0133290003272
                        ],
                        [
                            -103.117191000425,
                            29.0004839998557
                        ],
                        [
                            -103.117477999581,
                            28.9977739996173
                        ],
                        [
                            -103.11381399971,
                            28.9886039998807
                        ],
                        [
                            -103.116813999958,
                            28.9844160000027
                        ],
                        [
                            -103.123581999835,
                            28.9843010000743
                        ],
                        [
                            -103.127006000405,
                            28.9820560001872
                        ],
                        [
                            -103.134551999709,
                            28.9837550003234
                        ],
                        [
                            -103.144407999698,
                            28.9774669999571
                        ],
                        [
                            -103.151021999589,
                            28.9754479996874
                        ],
                        [
                            -103.153896000339,
                            28.9716060001247
                        ],
                        [
                            -103.160319000434,
                            28.9738439998019
                        ],
                        [
                            -103.163934999727,
                            28.9722039999086
                        ],
                        [
                            -103.166083999855,
                            28.9743969998174
                        ],
                        [
                            -103.165775999884,
                            28.9774960003773
                        ],
                        [
                            -103.170321000013,
                            28.9804219998475
                        ],
                        [
                            -103.172435999806,
                            28.9806939998729
                        ],
                        [
                            -103.174459000436,
                            28.9777949997915
                        ],
                        [
                            -103.176281000326,
                            28.9778040001653
                        ],
                        [
                            -103.180842000348,
                            28.9823979997513
                        ],
                        [
                            -103.202412000201,
                            28.9871950001987
                        ],
                        [
                            -103.214782000191,
                            28.9877260001563
                        ],
                        [
                            -103.218980999815,
                            28.9856519997222
                        ],
                        [
                            -103.219847999828,
                            28.9833179999516
                        ],
                        [
                            -103.227887999775,
                            28.9918569999897
                        ],
                        [
                            -103.237634999983,
                            28.9818619997572
                        ],
                        [
                            -103.250415999877,
                            28.9804299996273
                        ],
                        [
                            -103.253392999661,
                            28.9870189999086
                        ],
                        [
                            -103.267334000369,
                            28.9960719997314
                        ],
                        [
                            -103.27138599968,
                            28.9853619999458
                        ],
                        [
                            -103.279337999763,
                            28.9770020003745
                        ],
                        [
                            -103.286986000072,
                            28.9781720002814
                        ],
                        [
                            -103.288304999997,
                            28.9813849999397
                        ],
                        [
                            -103.283009000206,
                            28.9829710001538
                        ],
                        [
                            -103.281553000014,
                            28.9876959999517
                        ],
                        [
                            -103.286940999866,
                            29.0012730000592
                        ],
                        [
                            -103.301661999651,
                            29.0084259997453
                        ],
                        [
                            -103.30866399988,
                            29.0034260001685
                        ],
                        [
                            -103.316162000401,
                            29.0106890002872
                        ],
                        [
                            -103.311218999889,
                            29.0139779997573
                        ],
                        [
                            -103.308627999895,
                            29.0178600002291
                        ],
                        [
                            -103.306709999745,
                            29.02277500027
                        ],
                        [
                            -103.307657000124,
                            29.0245369996884
                        ],
                        [
                            -103.314341000336,
                            29.0260639997956
                        ],
                        [
                            -103.320920999892,
                            29.0191029998512
                        ],
                        [
                            -103.326358999973,
                            29.0163150002496
                        ],
                        [
                            -103.33255100009,
                            29.0165900001595
                        ],
                        [
                            -103.338750999705,
                            29.0202770000715
                        ],
                        [
                            -103.342010000419,
                            29.02843599983
                        ],
                        [
                            -103.336107999832,
                            29.0273960003879
                        ],
                        [
                            -103.330109999884,
                            29.0295339997204
                        ],
                        [
                            -103.327247999728,
                            29.0327210002037
                        ],
                        [
                            -103.327492000125,
                            29.0399239997529
                        ],
                        [
                            -103.330612999849,
                            29.0432189996427
                        ],
                        [
                            -103.339281000332,
                            29.0437040002983
                        ],
                        [
                            -103.342294000101,
                            29.0461679996548
                        ],
                        [
                            -103.344333999551,
                            29.045950000389
                        ],
                        [
                            -103.347801999604,
                            29.0402590001595
                        ],
                        [
                            -103.353504000175,
                            29.0228919997215
                        ],
                        [
                            -103.359603000405,
                            29.0186439997509
                        ],
                        [
                            -103.374597000001,
                            29.0237029996677
                        ],
                        [
                            -103.382960999813,
                            29.024511999651
                        ],
                        [
                            -103.386505000034,
                            29.0216480001749
                        ],
                        [
                            -103.388120000235,
                            29.0237980000505
                        ],
                        [
                            -103.387049999832,
                            29.0274610000709
                        ],
                        [
                            -103.388644000116,
                            29.0339740000591
                        ],
                        [
                            -103.392261000133,
                            29.034955000166
                        ],
                        [
                            -103.394981999823,
                            29.0325750002254
                        ],
                        [
                            -103.401050000091,
                            29.0310560003802
                        ],
                        [
                            -103.405078999836,
                            29.0395769999597
                        ],
                        [
                            -103.415414000399,
                            29.0378809996236
                        ],
                        [
                            -103.41899899973,
                            29.0423239999046
                        ],
                        [
                            -103.432095000166,
                            29.0433560001091
                        ],
                        [
                            -103.428894000077,
                            29.0494549996797
                        ],
                        [
                            -103.433706999994,
                            29.0521980002316
                        ],
                        [
                            -103.435124999653,
                            29.0552159999653
                        ],
                        [
                            -103.43422099983,
                            29.057287000374
                        ],
                        [
                            -103.441076999571,
                            29.0579000003698
                        ],
                        [
                            -103.449667000236,
                            29.0650920003743
                        ],
                        [
                            -103.449930000002,
                            29.0727140001839
                        ],
                        [
                            -103.461694000094,
                            29.0726040001441
                        ],
                        [
                            -103.459683999883,
                            29.0670479998282
                        ],
                        [
                            -103.471350000067,
                            29.0667269997527
                        ],
                        [
                            -103.47393000019,
                            29.0703150000542
                        ],
                        [
                            -103.470038999639,
                            29.0791820000541
                        ],
                        [
                            -103.469935999707,
                            29.0853170002773
                        ],
                        [
                            -103.476202000342,
                            29.0878120000857
                        ],
                        [
                            -103.475256999613,
                            29.0903149998008
                        ],
                        [
                            -103.476495000245,
                            29.091670999806
                        ],
                        [
                            -103.480682999999,
                            29.0916970001237
                        ],
                        [
                            -103.483406000237,
                            29.0934960001859
                        ],
                        [
                            -103.493638999794,
                            29.1079530000245
                        ],
                        [
                            -103.498762000232,
                            29.1085390000901
                        ],
                        [
                            -103.500377000433,
                            29.1161960000193
                        ],
                        [
                            -103.505884000111,
                            29.1160480000845
                        ],
                        [
                            -103.512852000005,
                            29.1212550002925
                        ],
                        [
                            -103.519870000127,
                            29.1196759999651
                        ],
                        [
                            -103.523855000389,
                            29.1212469996556
                        ],
                        [
                            -103.522804000253,
                            29.1346460003048
                        ],
                        [
                            -103.52483500038,
                            29.1371229996302
                        ],
                        [
                            -103.53980600041,
                            29.1462239998936
                        ],
                        [
                            -103.547616000203,
                            29.1423940003038
                        ],
                        [
                            -103.550211000395,
                            29.1436389999117
                        ],
                        [
                            -103.552964999697,
                            29.1486559999461
                        ],
                        [
                            -103.550372000053,
                            29.1555120002402
                        ],
                        [
                            -103.553691999969,
                            29.1579960001334
                        ],
                        [
                            -103.55702000028,
                            29.1554509999106
                        ],
                        [
                            -103.565416999703,
                            29.1541630001298
                        ],
                        [
                            -103.568876000434,
                            29.1553159999653
                        ],
                        [
                            -103.573684000329,
                            29.1538230001683
                        ],
                        [
                            -103.580008999616,
                            29.1495239999103
                        ],
                        [
                            -103.592241000413,
                            29.1500689999278
                        ],
                        [
                            -103.596132000065,
                            29.1517009998552
                        ],
                        [
                            -103.600667000148,
                            29.1579600003253
                        ],
                        [
                            -103.606178999849,
                            29.1602749999989
                        ],
                        [
                            -103.608875000324,
                            29.1647989999135
                        ],
                        [
                            -103.612231000224,
                            29.1653690000512
                        ],
                        [
                            -103.618984999858,
                            29.1632609997415
                        ],
                        [
                            -103.628383999912,
                            29.1630669996779
                        ],
                        [
                            -103.633657000137,
                            29.1585800001395
                        ],
                        [
                            -103.640744999681,
                            29.161141999651
                        ],
                        [
                            -103.650585999601,
                            29.1564800003395
                        ],
                        [
                            -103.651974999846,
                            29.1579070000182
                        ],
                        [
                            -103.651286999933,
                            29.1619779999406
                        ],
                        [
                            -103.653632999705,
                            29.1634169996562
                        ],
                        [
                            -103.656711999596,
                            29.1693600003556
                        ],
                        [
                            -103.659618999957,
                            29.1705690001082
                        ],
                        [
                            -103.664631999891,
                            29.1709370003818
                        ],
                        [
                            -103.669306999717,
                            29.1736519996556
                        ],
                        [
                            -103.677740000023,
                            29.1738829998953
                        ],
                        [
                            -103.686279999562,
                            29.1763470001651
                        ],
                        [
                            -103.688567999607,
                            29.1785699998503
                        ],
                        [
                            -103.698164000203,
                            29.1774729996901
                        ],
                        [
                            -103.705162000234,
                            29.1849680000179
                        ],
                        [
                            -103.707344999799,
                            29.1837129999103
                        ],
                        [
                            -103.708965000023,
                            29.1852589998398
                        ],
                        [
                            -103.712957999783,
                            29.1852220002344
                        ],
                        [
                            -103.715558999823,
                            29.1811019996753
                        ],
                        [
                            -103.718560999721,
                            29.1808259996769
                        ],
                        [
                            -103.724947000006,
                            29.1919859999108
                        ],
                        [
                            -103.728724999681,
                            29.1928849997279
                        ],
                        [
                            -103.729791999711,
                            29.1974280001761
                        ],
                        [
                            -103.737055000056,
                            29.2018610002748
                        ],
                        [
                            -103.742545000016,
                            29.2090100001807
                        ],
                        [
                            -103.743181999875,
                            29.2150819999569
                        ],
                        [
                            -103.740874999563,
                            29.2187850003895
                        ],
                        [
                            -103.742246000265,
                            29.2216940000486
                        ],
                        [
                            -103.752873999832,
                            29.2194860003766
                        ],
                        [
                            -103.757365000433,
                            29.2206680001895
                        ],
                        [
                            -103.757656999612,
                            29.2230980000366
                        ],
                        [
                            -103.754261000428,
                            29.2268899999017
                        ],
                        [
                            -103.75854899974,
                            29.2329389998992
                        ],
                        [
                            -103.762781999699,
                            29.2327479999561
                        ],
                        [
                            -103.768393000033,
                            29.2275779998662
                        ],
                        [
                            -103.767532999693,
                            29.2250889999015
                        ],
                        [
                            -103.76970600011,
                            29.2202239998017
                        ],
                        [
                            -103.777146999832,
                            29.2205089999381
                        ],
                        [
                            -103.777955000294,
                            29.2238059996461
                        ],
                        [
                            -103.782029000245,
                            29.2272639999043
                        ],
                        [
                            -103.780590999596,
                            29.2361849998338
                        ],
                        [
                            -103.78168100009,
                            29.2412780002885
                        ],
                        [
                            -103.780716000168,
                            29.2438220003181
                        ],
                        [
                            -103.776253000055,
                            29.2434650000809
                        ],
                        [
                            -103.775072000222,
                            29.2479540001558
                        ],
                        [
                            -103.778476999628,
                            29.2520390002966
                        ],
                        [
                            -103.778731999896,
                            29.2561309998284
                        ],
                        [
                            -103.783626000004,
                            29.2653239996553
                        ],
                        [
                            -103.788612999999,
                            29.265378999969
                        ],
                        [
                            -103.79936399959,
                            29.2584369999944
                        ],
                        [
                            -103.806369000192,
                            29.2610890002879
                        ],
                        [
                            -103.806781999745,
                            29.2640429997804
                        ],
                        [
                            -103.809172999723,
                            29.2659390001257
                        ],
                        [
                            -103.807611999949,
                            29.2701589999109
                        ],
                        [
                            -103.809396000204,
                            29.2727129998093
                        ],
                        [
                            -103.81351700001,
                            29.274032000285
                        ],
                        [
                            -103.826206999667,
                            29.2692030000919
                        ],
                        [
                            -103.835491000094,
                            29.2726869998772
                        ],
                        [
                            -103.837637999674,
                            29.278251999768
                        ],
                        [
                            -103.854306999746,
                            29.2814719996934
                        ],
                        [
                            -103.86317799972,
                            29.2791970000624
                        ],
                        [
                            -103.865762999867,
                            29.2821549996269
                        ],
                        [
                            -103.870076000192,
                            29.2806839999279
                        ],
                        [
                            -103.87251499985,
                            29.2831160001858
                        ],
                        [
                            -103.880119999603,
                            29.2851490003338
                        ],
                        [
                            -103.884487000355,
                            29.2846980001276
                        ],
                        [
                            -103.885623999807,
                            29.2812960002808
                        ],
                        [
                            -103.889394999809,
                            29.2788240000781
                        ],
                        [
                            -103.899291999806,
                            29.2874099996701
                        ],
                        [
                            -103.912077999722,
                            29.2837440002945
                        ],
                        [
                            -103.918886999607,
                            29.2848440001604
                        ],
                        [
                            -103.918288000281,
                            29.2898199997774
                        ],
                        [
                            -103.925719999781,
                            29.2943720002931
                        ],
                        [
                            -103.937011000046,
                            29.2927120003732
                        ],
                        [
                            -103.950032999964,
                            29.2953470001768
                        ],
                        [
                            -103.952193999788,
                            29.2976160003759
                        ],
                        [
                            -103.954559999651,
                            29.2967130003118
                        ],
                        [
                            -103.967647999692,
                            29.3000289997673
                        ],
                        [
                            -103.970833999713,
                            29.296556000015
                        ],
                        [
                            -103.975808000187,
                            29.2962490001393
                        ],
                        [
                            -103.991658999751,
                            29.303806999749
                        ],
                        [
                            -104.000677999863,
                            29.3053369999033
                        ],
                        [
                            -104.016238000072,
                            29.3121390003544
                        ],
                        [
                            -104.020218000312,
                            29.3120479996532
                        ],
                        [
                            -104.03749699958,
                            29.3197070003122
                        ],
                        [
                            -104.05516499991,
                            29.3304420000578
                        ],
                        [
                            -104.056691000422,
                            29.3332650002583
                        ],
                        [
                            -104.056099999695,
                            29.3377270001072
                        ],
                        [
                            -104.066745999704,
                            29.3410049999178
                        ],
                        [
                            -104.073532000023,
                            29.3465570001461
                        ],
                        [
                            -104.07577900006,
                            29.3451359997397
                        ],
                        [
                            -104.082255999684,
                            29.346063999693
                        ],
                        [
                            -104.090909000099,
                            29.3535279998804
                        ],
                        [
                            -104.089487000242,
                            29.3559290003023
                        ],
                        [
                            -104.090025000367,
                            29.3592209999852
                        ],
                        [
                            -104.097753999969,
                            29.3616419997852
                        ],
                        [
                            -104.098200999858,
                            29.3665469998501
                        ],
                        [
                            -104.106578999913,
                            29.3733129996857
                        ],
                        [
                            -104.113371000079,
                            29.373622999893
                        ],
                        [
                            -104.117715000367,
                            29.3706610001385
                        ],
                        [
                            -104.121318000139,
                            29.3714230003729
                        ],
                        [
                            -104.122940999838,
                            29.3803059998068
                        ],
                        [
                            -104.129446999774,
                            29.3785890001389
                        ],
                        [
                            -104.131421999825,
                            29.3799789999974
                        ],
                        [
                            -104.131383000365,
                            29.3823459998478
                        ],
                        [
                            -104.134919000191,
                            29.3842199997328
                        ],
                        [
                            -104.137808999936,
                            29.3826550003568
                        ],
                        [
                            -104.138198999923,
                            29.3785640001901
                        ],
                        [
                            -104.141158000163,
                            29.3778230002341
                        ],
                        [
                            -104.143201999811,
                            29.3824240003385
                        ],
                        [
                            -104.143339000078,
                            29.3882680002755
                        ],
                        [
                            -104.148372999929,
                            29.3851010001432
                        ],
                        [
                            -104.151518999767,
                            29.3877379998947
                        ],
                        [
                            -104.151568000171,
                            29.3915419996658
                        ],
                        [
                            -104.155195000233,
                            29.3945690002734
                        ],
                        [
                            -104.156569000409,
                            29.3939360003524
                        ],
                        [
                            -104.156342999555,
                            29.3908099997283
                        ],
                        [
                            -104.160378000046,
                            29.3898280002876
                        ],
                        [
                            -104.167443000024,
                            29.3947439996516
                        ],
                        [
                            -104.168429999688,
                            29.3964889999905
                        ],
                        [
                            -104.164691000371,
                            29.3996450002745
                        ],
                        [
                            -104.164770999839,
                            29.4015169996434
                        ],
                        [
                            -104.170363999731,
                            29.4026460002893
                        ],
                        [
                            -104.169674000168,
                            29.4081610002721
                        ],
                        [
                            -104.179438000095,
                            29.4104000001357
                        ],
                        [
                            -104.180407000216,
                            29.4144699997485
                        ],
                        [
                            -104.183888999614,
                            29.417200000127
                        ],
                        [
                            -104.182824000133,
                            29.4214290003267
                        ],
                        [
                            -104.18090099996,
                            29.4225749999826
                        ],
                        [
                            -104.181064999992,
                            29.4264600000436
                        ],
                        [
                            -104.186113999911,
                            29.4282979997526
                        ],
                        [
                            -104.19292399962,
                            29.4352700001189
                        ],
                        [
                            -104.194142000161,
                            29.4416780002764
                        ],
                        [
                            -104.210077000289,
                            29.4488429999024
                        ],
                        [
                            -104.217704999608,
                            29.4565590002119
                        ],
                        [
                            -104.218262,
                            29.4581749999531
                        ],
                        [
                            -104.213582999977,
                            29.4615279999848
                        ],
                        [
                            -104.212433000107,
                            29.4649279996728
                        ],
                        [
                            -104.210954000348,
                            29.4769180000423
                        ],
                        [
                            -104.20892300022,
                            29.4807319998428
                        ],
                        [
                            -104.210043999779,
                            29.4822429996899
                        ],
                        [
                            -104.215528999716,
                            29.4847629996539
                        ],
                        [
                            -104.22523999994,
                            29.4820360002368
                        ],
                        [
                            -104.230698000112,
                            29.4786689998463
                        ],
                        [
                            -104.23352299956,
                            29.4856280002504
                        ],
                        [
                            -104.233819999661,
                            29.4936540000929
                        ],
                        [
                            -104.237133999728,
                            29.4975559999794
                        ],
                        [
                            -104.240317000275,
                            29.4973899996701
                        ],
                        [
                            -104.254294999895,
                            29.5067110002756
                        ],
                        [
                            -104.260659000439,
                            29.5066710001499
                        ],
                        [
                            -104.262175000007,
                            29.5135009997437
                        ],
                        [
                            -104.268849999998,
                            29.5135819999111
                        ],
                        [
                            -104.272244000431,
                            29.5163489999933
                        ],
                        [
                            -104.291928999746,
                            29.5194549998629
                        ],
                        [
                            -104.292895000392,
                            29.5222899996869
                        ],
                        [
                            -104.296789000418,
                            29.5241280003611
                        ],
                        [
                            -104.311475000042,
                            29.5232020000025
                        ],
                        [
                            -104.310946000138,
                            29.5287920002709
                        ],
                        [
                            -104.315476000198,
                            29.5327539999208
                        ],
                        [
                            -104.320895999838,
                            29.5295390003322
                        ],
                        [
                            -104.321485999842,
                            29.5218619999239
                        ],
                        [
                            -104.326598000408,
                            29.5232819996791
                        ],
                        [
                            -104.330726999712,
                            29.522656000364
                        ],
                        [
                            -104.334552999966,
                            29.5194119999034
                        ],
                        [
                            -104.338200999944,
                            29.5201820003389
                        ],
                        [
                            -104.362498000233,
                            29.5358849997844
                        ],
                        [
                            -104.370421000004,
                            29.5431409999444
                        ],
                        [
                            -104.37504399995,
                            29.5437479996493
                        ],
                        [
                            -104.378932000128,
                            29.5421030001447
                        ],
                        [
                            -104.381563000305,
                            29.5433689997061
                        ],
                        [
                            -104.394268000031,
                            29.5551799998561
                        ],
                        [
                            -104.393893000112,
                            29.5596939998532
                        ],
                        [
                            -104.398921000115,
                            29.5718969996924
                        ],
                        [
                            -104.452024999764,
                            29.6035250000802
                        ],
                        [
                            -104.456369000051,
                            29.6087339996612
                        ],
                        [
                            -104.463818999994,
                            29.6102400000392
                        ],
                        [
                            -104.466548999905,
                            29.609215999862
                        ],
                        [
                            -104.467590999821,
                            29.6129379999917
                        ],
                        [
                            -104.465429999997,
                            29.6155250001924
                        ],
                        [
                            -104.465397000385,
                            29.6182090001416
                        ],
                        [
                            -104.473403999821,
                            29.6178870002977
                        ],
                        [
                            -104.475990999617,
                            29.6279789999711
                        ],
                        [
                            -104.486141000232,
                            29.6286580001548
                        ],
                        [
                            -104.487178999949,
                            29.6336909997222
                        ],
                        [
                            -104.494934000389,
                            29.6338260002063
                        ],
                        [
                            -104.496750000431,
                            29.6357410002512
                        ],
                        [
                            -104.496230999674,
                            29.6390490001426
                        ],
                        [
                            -104.498003000234,
                            29.6396949997305
                        ],
                        [
                            -104.503198999568,
                            29.6370570002776
                        ],
                        [
                            -104.506922999714,
                            29.6370109998215
                        ],
                        [
                            -104.505633000101,
                            29.6340439998079
                        ],
                        [
                            -104.508461999747,
                            29.6326969998352
                        ],
                        [
                            -104.515890999773,
                            29.6409629998707
                        ],
                        [
                            -104.513194000372,
                            29.6409060002404
                        ],
                        [
                            -104.512636000155,
                            29.6431259997603
                        ],
                        [
                            -104.509877999756,
                            29.6442129997551
                        ],
                        [
                            -104.512430000291,
                            29.6467169997851
                        ],
                        [
                            -104.51759700021,
                            29.647390999647
                        ],
                        [
                            -104.518465999874,
                            29.6491500000629
                        ],
                        [
                            -104.516581000234,
                            29.6524199996146
                        ],
                        [
                            -104.52468200028,
                            29.6592360000561
                        ],
                        [
                            -104.525396999957,
                            29.6678430002425
                        ],
                        [
                            -104.528223999953,
                            29.6674220001908
                        ],
                        [
                            -104.52861399994,
                            29.669791999758
                        ],
                        [
                            -104.531201999561,
                            29.6707750001753
                        ],
                        [
                            -104.53338599985,
                            29.6686730003758
                        ],
                        [
                            -104.532656000104,
                            29.6671779998345
                        ],
                        [
                            -104.535575000161,
                            29.6665529997569
                        ],
                        [
                            -104.535863999866,
                            29.6743539998876
                        ],
                        [
                            -104.544098999806,
                            29.6806929997872
                        ],
                        [
                            -104.542272999718,
                            29.6840859998581
                        ],
                        [
                            -104.534298999894,
                            29.6858519997359
                        ],
                        [
                            -104.530209999875,
                            29.6904639997067
                        ],
                        [
                            -104.543831999842,
                            29.6962989997731
                        ],
                        [
                            -104.545931999567,
                            29.6999089997932
                        ],
                        [
                            -104.543381999581,
                            29.7040390002419
                        ],
                        [
                            -104.546249999584,
                            29.7089989997098
                        ],
                        [
                            -104.543908999835,
                            29.7111889996468
                        ],
                        [
                            -104.547535000072,
                            29.7142519999794
                        ],
                        [
                            -104.544881000329,
                            29.7160979997963
                        ],
                        [
                            -104.547143000435,
                            29.7176630003584
                        ],
                        [
                            -104.549997000195,
                            29.7161700002315
                        ],
                        [
                            -104.55228699989,
                            29.7173419998363
                        ],
                        [
                            -104.549158999596,
                            29.7201749997635
                        ],
                        [
                            -104.552448000447,
                            29.7237070000613
                        ],
                        [
                            -104.548905000051,
                            29.7274600002712
                        ],
                        [
                            -104.549326,
                            29.7305710001052
                        ],
                        [
                            -104.556247999863,
                            29.7318110000251
                        ],
                        [
                            -104.556392999628,
                            29.7354879999894
                        ],
                        [
                            -104.560434999792,
                            29.7351880003326
                        ],
                        [
                            -104.558934000292,
                            29.738060000006
                        ],
                        [
                            -104.550326999908,
                            29.7380079999032
                        ],
                        [
                            -104.549848000232,
                            29.7408949998785
                        ],
                        [
                            -104.551558999794,
                            29.7406530000116
                        ],
                        [
                            -104.553876999977,
                            29.7430539996259
                        ],
                        [
                            -104.555390000071,
                            29.7418709999964
                        ],
                        [
                            -104.557144000189,
                            29.7454739998679
                        ],
                        [
                            -104.569940999976,
                            29.7525969999612
                        ],
                        [
                            -104.569613999738,
                            29.7570889999963
                        ],
                        [
                            -104.567118000178,
                            29.7570619996414
                        ],
                        [
                            -104.565661000161,
                            29.7592079998153
                        ],
                        [
                            -104.566892000222,
                            29.7600919996993
                        ],
                        [
                            -104.563882999754,
                            29.7612829998056
                        ],
                        [
                            -104.565811999774,
                            29.7618589997322
                        ],
                        [
                            -104.566158000279,
                            29.7651309996284
                        ],
                        [
                            -104.568516999571,
                            29.7661770003052
                        ],
                        [
                            -104.565573000298,
                            29.7693810001161
                        ],
                        [
                            -104.56592899995,
                            29.7714680002914
                        ],
                        [
                            -104.570473000254,
                            29.7783419997094
                        ],
                        [
                            -104.574409999937,
                            29.779600999919
                        ],
                        [
                            -104.574713999711,
                            29.7817880003456
                        ],
                        [
                            -104.578920999731,
                            29.7828349996752
                        ],
                        [
                            -104.578233999642,
                            29.7877789999025
                        ],
                        [
                            -104.582463000302,
                            29.7925010000011
                        ],
                        [
                            -104.581505999877,
                            29.7951120001154
                        ],
                        [
                            -104.5890000002,
                            29.795661999679
                        ],
                        [
                            -104.590914999977,
                            29.7981150002281
                        ],
                        [
                            -104.586014000196,
                            29.802034999884
                        ],
                        [
                            -104.591346999797,
                            29.8098089998573
                        ],
                        [
                            -104.593977999974,
                            29.8094370001652
                        ],
                        [
                            -104.593950000385,
                            29.8082129998095
                        ],
                        [
                            -104.591642000248,
                            29.8084769996708
                        ],
                        [
                            -104.591885999747,
                            29.806888999926
                        ],
                        [
                            -104.594927999828,
                            29.8053309999713
                        ],
                        [
                            -104.601167999626,
                            29.8161829997062
                        ],
                        [
                            -104.603324000325,
                            29.8173220002579
                        ],
                        [
                            -104.605075999895,
                            29.8159980001714
                        ],
                        [
                            -104.607245000115,
                            29.819365000317
                        ],
                        [
                            -104.610082000156,
                            29.8193589999008
                        ],
                        [
                            -104.608486000222,
                            29.8279489997004
                        ],
                        [
                            -104.612842000205,
                            29.8273399997323
                        ],
                        [
                            -104.614775000423,
                            29.8297990000414
                        ],
                        [
                            -104.619636999846,
                            29.8302170003623
                        ],
                        [
                            -104.620256000162,
                            29.8330820000181
                        ],
                        [
                            -104.613939000372,
                            29.8339349998865
                        ],
                        [
                            -104.610498999908,
                            29.8362750001128
                        ],
                        [
                            -104.609445000297,
                            29.8397539997824
                        ],
                        [
                            -104.615371000275,
                            29.8465689996275
                        ],
                        [
                            -104.619109999592,
                            29.8445019998955
                        ],
                        [
                            -104.619487999883,
                            29.8417219997448
                        ],
                        [
                            -104.620523000126,
                            29.8432379998954
                        ],
                        [
                            -104.621912000371,
                            29.8418430003407
                        ],
                        [
                            -104.624750000238,
                            29.8427830002995
                        ],
                        [
                            -104.621324999842,
                            29.8475270003658
                        ],
                        [
                            -104.621585000133,
                            29.849565000208
                        ],
                        [
                            -104.629271000077,
                            29.8522010002158
                        ],
                        [
                            -104.630124999672,
                            29.8645200002565
                        ],
                        [
                            -104.633421000196,
                            29.8707579999366
                        ],
                        [
                            -104.637239000053,
                            29.8739640001406
                        ],
                        [
                            -104.641083999675,
                            29.8738209996173
                        ],
                        [
                            -104.646111999678,
                            29.8785420000236
                        ],
                        [
                            -104.646520000107,
                            29.8835369998269
                        ],
                        [
                            -104.652639000429,
                            29.8889369996536
                        ],
                        [
                            -104.650559999722,
                            29.8911270003892
                        ],
                        [
                            -104.651661999912,
                            29.8923839998286
                        ],
                        [
                            -104.655852000214,
                            29.889210999753
                        ],
                        [
                            -104.658257000435,
                            29.8907979999568
                        ],
                        [
                            -104.659008000098,
                            29.901190999757
                        ],
                        [
                            -104.65515699973,
                            29.9028080003553
                        ],
                        [
                            -104.655085999584,
                            29.9053980002096
                        ],
                        [
                            -104.6622989997,
                            29.905388000087
                        ],
                        [
                            -104.661824000223,
                            29.9021879996623
                        ],
                        [
                            -104.665589000377,
                            29.9017079997668
                        ],
                        [
                            -104.665606000095,
                            29.9088790003887
                        ],
                        [
                            -104.668568999635,
                            29.9112450000762
                        ],
                        [
                            -104.674529999773,
                            29.9108299998212
                        ],
                        [
                            -104.677166999798,
                            29.9130349996453
                        ],
                        [
                            -104.675861000292,
                            29.9155369996976
                        ],
                        [
                            -104.679920999999,
                            29.9211759998925
                        ],
                        [
                            -104.678500999792,
                            29.9266219996289
                        ],
                        [
                            -104.683271999876,
                            29.9291070002749
                        ],
                        [
                            -104.683297999816,
                            29.9315589997735
                        ],
                        [
                            -104.680471999645,
                            29.9304039998247
                        ],
                        [
                            -104.679300000033,
                            29.9328789997598
                        ],
                        [
                            -104.682628000344,
                            29.9353810000914
                        ],
                        [
                            -104.681275000084,
                            29.937962000009
                        ],
                        [
                            -104.682317999825,
                            29.939690999688
                        ],
                        [
                            -104.678949000404,
                            29.9381309997457
                        ],
                        [
                            -104.677790000312,
                            29.9404319996928
                        ],
                        [
                            -104.677971000062,
                            29.9436020003668
                        ],
                        [
                            -104.680735000309,
                            29.9473259997342
                        ],
                        [
                            -104.677109999897,
                            29.9503000001495
                        ],
                        [
                            -104.678167999706,
                            29.95169100017
                        ],
                        [
                            -104.674287999924,
                            29.950794999625
                        ],
                        [
                            -104.673179999886,
                            29.9549690000601
                        ],
                        [
                            -104.674174000121,
                            29.9567340002486
                        ],
                        [
                            -104.680058000266,
                            29.955414000039
                        ],
                        [
                            -104.680728999563,
                            29.9571240000165
                        ],
                        [
                            -104.685252999775,
                            29.9567309998912
                        ],
                        [
                            -104.681853000392,
                            29.9690669996968
                        ],
                        [
                            -104.685503000021,
                            29.9708429998698
                        ],
                        [
                            -104.680105999947,
                            29.9742239996733
                        ],
                        [
                            -104.680151999978,
                            29.9770030003543
                        ],
                        [
                            -104.687637999905,
                            29.973922000054
                        ],
                        [
                            -104.69173699997,
                            29.9739859996996
                        ],
                        [
                            -104.69312700004,
                            29.9755300000519
                        ],
                        [
                            -104.688352999583,
                            29.980050000067
                        ],
                        [
                            -104.68827200029,
                            29.984721000267
                        ],
                        [
                            -104.685735999649,
                            29.9871419996775
                        ],
                        [
                            -104.685670000425,
                            29.9904509997401
                        ],
                        [
                            -104.691552000022,
                            29.9912999997231
                        ],
                        [
                            -104.693182000292,
                            29.9935250001793
                        ],
                        [
                            -104.689087000425,
                            29.9939310000496
                        ],
                        [
                            -104.687533000323,
                            29.9956729998117
                        ],
                        [
                            -104.689824999668,
                            30.0008539998913
                        ],
                        [
                            -104.688797999821,
                            30.0022290000863
                        ],
                        [
                            -104.690614999689,
                            30.003398000004
                        ],
                        [
                            -104.691906000025,
                            30.0007810002542
                        ],
                        [
                            -104.694103999658,
                            30.0025159996507
                        ],
                        [
                            -104.69369399958,
                            30.0077399996945
                        ],
                        [
                            -104.690474999947,
                            30.0082290000927
                        ],
                        [
                            -104.689563999552,
                            30.0104550001988
                        ],
                        [
                            -104.689549000382,
                            30.0151049998743
                        ],
                        [
                            -104.693254000262,
                            30.0191280001134
                        ],
                        [
                            -104.702065999787,
                            30.021230000355
                        ],
                        [
                            -104.703818000255,
                            30.0234170001179
                        ],
                        [
                            -104.700900000023,
                            30.0356430001942
                        ],
                        [
                            -104.695675000377,
                            30.0376639998562
                        ],
                        [
                            -104.69768100039,
                            30.0412420001422
                        ],
                        [
                            -104.707263999669,
                            30.0498669998769
                        ],
                        [
                            -104.703629000109,
                            30.0550549997086
                        ],
                        [
                            -104.704790999675,
                            30.0583249999859
                        ],
                        [
                            -104.7022130001,
                            30.0601899998256
                        ],
                        [
                            -104.703661999721,
                            30.0641960001202
                        ],
                        [
                            -104.69754099975,
                            30.0652430001237
                        ],
                        [
                            -104.691027000316,
                            30.0704959997662
                        ],
                        [
                            -104.6932230003,
                            30.0716940001759
                        ],
                        [
                            -104.693158999827,
                            30.0740149999126
                        ],
                        [
                            -104.68798799971,
                            30.0737700003656
                        ],
                        [
                            -104.686600000188,
                            30.07658099981
                        ],
                        [
                            -104.689545000184,
                            30.0756490003628
                        ],
                        [
                            -104.690772000048,
                            30.0780149999433
                        ],
                        [
                            -104.685829000434,
                            30.0820630002123
                        ],
                        [
                            -104.686676000356,
                            30.0839499997362
                        ],
                        [
                            -104.684964999895,
                            30.0853010000839
                        ],
                        [
                            -104.687299999796,
                            30.0875000002745
                        ],
                        [
                            -104.687124000069,
                            30.0860140002443
                        ],
                        [
                            -104.689222999969,
                            30.0853840003113
                        ],
                        [
                            -104.689868000224,
                            30.0893419998337
                        ],
                        [
                            -104.685534999808,
                            30.093208999639
                        ],
                        [
                            -104.685067999827,
                            30.0998490003261
                        ],
                        [
                            -104.688950999982,
                            30.0952589996494
                        ],
                        [
                            -104.692152999897,
                            30.0960840001311
                        ],
                        [
                            -104.694404000132,
                            30.1019789998532
                        ],
                        [
                            -104.691741999993,
                            30.1087249998876
                        ],
                        [
                            -104.689954000438,
                            30.1078080002383
                        ],
                        [
                            -104.690254000014,
                            30.1063080000662
                        ],
                        [
                            -104.687130999742,
                            30.1058670000549
                        ],
                        [
                            -104.68546799986,
                            30.1100320003382
                        ],
                        [
                            -104.691573999764,
                            30.1112220003657
                        ],
                        [
                            -104.692092999622,
                            30.1149909999666
                        ],
                        [
                            -104.689946999867,
                            30.1170210000527
                        ],
                        [
                            -104.695077999802,
                            30.1178109997681
                        ],
                        [
                            -104.693901999992,
                            30.1215899998198
                        ],
                        [
                            -104.696706000422,
                            30.1344570003432
                        ],
                        [
                            -104.693033000329,
                            30.1354509997559
                        ],
                        [
                            -104.691900000177,
                            30.1388519996937
                        ],
                        [
                            -104.689068999983,
                            30.1377239999581
                        ],
                        [
                            -104.689628000025,
                            30.1409400003395
                        ],
                        [
                            -104.686287000193,
                            30.1414570001837
                        ],
                        [
                            -104.685312000225,
                            30.144732999879
                        ],
                        [
                            -104.689325999901,
                            30.1456349996185
                        ],
                        [
                            -104.688248000001,
                            30.147370999972
                        ],
                        [
                            -104.690113000447,
                            30.149225999925
                        ],
                        [
                            -104.686884999695,
                            30.1521389999923
                        ],
                        [
                            -104.689562999727,
                            30.15426900038
                        ],
                        [
                            -104.687529000125,
                            30.1595519998956
                        ],
                        [
                            -104.688530999858,
                            30.1656659997552
                        ],
                        [
                            -104.686332000399,
                            30.1732809998376
                        ],
                        [
                            -104.687977999664,
                            30.1763899996281
                        ],
                        [
                            -104.687076000389,
                            30.1793420002432
                        ],
                        [
                            -104.691511999839,
                            30.1846269997353
                        ],
                        [
                            -104.693147000132,
                            30.1892599998383
                        ],
                        [
                            -104.697138000242,
                            30.1915680001989
                        ],
                        [
                            -104.696117000243,
                            30.1955700003339
                        ],
                        [
                            -104.697041000157,
                            30.1978000000123
                        ],
                        [
                            -104.699807999879,
                            30.1987819998358
                        ],
                        [
                            -104.700326999737,
                            30.2053029999084
                        ],
                        [
                            -104.705066000035,
                            30.2083810002911
                        ],
                        [
                            -104.702475000041,
                            30.2127789998494
                        ],
                        [
                            -104.711269000023,
                            30.2230830003665
                        ],
                        [
                            -104.707899999703,
                            30.2262309997175
                        ],
                        [
                            -104.706255000264,
                            30.2348120002443
                        ],
                        [
                            -104.71319499967,
                            30.2376320003138
                        ],
                        [
                            -104.718949999944,
                            30.2470860001813
                        ],
                        [
                            -104.723998999863,
                            30.2496189996628
                        ],
                        [
                            -104.724146000176,
                            30.2530460003219
                        ],
                        [
                            -104.72751600032,
                            30.2544379999063
                        ],
                        [
                            -104.729739999893,
                            30.2584969997823
                        ],
                        [
                            -104.733721999782,
                            30.2613990000245
                        ],
                        [
                            -104.73750100018,
                            30.261284999883
                        ],
                        [
                            -104.736645000037,
                            30.2559050001909
                        ],
                        [
                            -104.74039199975,
                            30.2595820000845
                        ],
                        [
                            -104.750712000244,
                            30.2622290000876
                        ],
                        [
                            -104.75158599993,
                            30.2644190000534
                        ],
                        [
                            -104.749579999917,
                            30.2669719996581
                        ],
                        [
                            -104.747857999586,
                            30.2731589997438
                        ],
                        [
                            -104.748593000253,
                            30.2744069997889
                        ],
                        [
                            -104.75076800032,
                            30.2715429999608
                        ],
                        [
                            -104.751097000208,
                            30.273881000307
                        ],
                        [
                            -104.754725000095,
                            30.271740999975
                        ],
                        [
                            -104.757183999845,
                            30.2743429998344
                        ],
                        [
                            -104.758479000379,
                            30.2713930000133
                        ],
                        [
                            -104.760533999897,
                            30.2719829996223
                        ],
                        [
                            -104.760076000138,
                            30.2743590000209
                        ],
                        [
                            -104.763175999945,
                            30.2748359997276
                        ],
                        [
                            -104.758275000164,
                            30.2811579999088
                        ],
                        [
                            -104.758138999722,
                            30.2864119997811
                        ],
                        [
                            -104.761287999933,
                            30.3006110002731
                        ],
                        [
                            -104.767090999887,
                            30.3037130001605
                        ],
                        [
                            -104.766690000029,
                            30.3059999997248
                        ],
                        [
                            -104.77264500032,
                            30.3026910002802
                        ],
                        [
                            -104.775908000333,
                            30.3044710002434
                        ],
                        [
                            -104.779317999762,
                            30.3032689996697
                        ],
                        [
                            -104.779630999757,
                            30.305731000018
                        ],
                        [
                            -104.776849999792,
                            30.3073840001088
                        ],
                        [
                            -104.776807000134,
                            30.3102999999774
                        ],
                        [
                            -104.782824000348,
                            30.3160510001832
                        ],
                        [
                            -104.788494000234,
                            30.317892999832
                        ],
                        [
                            -104.788802000205,
                            30.3218430002049
                        ],
                        [
                            -104.791485000261,
                            30.3222859999996
                        ],
                        [
                            -104.791399000047,
                            30.3255179998031
                        ],
                        [
                            -104.79479699978,
                            30.3269599996167
                        ],
                        [
                            -104.797443999851,
                            30.3304369997757
                        ],
                        [
                            -104.807916999608,
                            30.3333140002726
                        ],
                        [
                            -104.811578000005,
                            30.3328110001559
                        ],
                        [
                            -104.809994999591,
                            30.3415110001716
                        ],
                        [
                            -104.815709999682,
                            30.3439809999453
                        ],
                        [
                            -104.818755999961,
                            30.3475270000717
                        ],
                        [
                            -104.819868000196,
                            30.3468600002373
                        ],
                        [
                            -104.822581000389,
                            30.3506239997764
                        ],
                        [
                            -104.814131000364,
                            30.3536909998155
                        ],
                        [
                            -104.813878999571,
                            30.3598959999452
                        ],
                        [
                            -104.810993000024,
                            30.362471999739
                        ],
                        [
                            -104.811355000422,
                            30.3667010002302
                        ],
                        [
                            -104.816170999814,
                            30.3658639996731
                        ],
                        [
                            -104.819674000028,
                            30.3687219999518
                        ],
                        [
                            -104.816422999709,
                            30.3751869999042
                        ],
                        [
                            -104.830564000434,
                            30.3728529996151
                        ],
                        [
                            -104.837437999718,
                            30.3738469999168
                        ],
                        [
                            -104.839384000355,
                            30.3764069997652
                        ],
                        [
                            -104.845613000282,
                            30.3792089999105
                        ],
                        [
                            -104.848504999677,
                            30.3835789998394
                        ],
                        [
                            -104.853813999887,
                            30.3853210001773
                        ],
                        [
                            -104.859296000349,
                            30.3902530000256
                        ],
                        [
                            -104.854280999867,
                            30.400366000301
                        ],
                        [
                            -104.857182000381,
                            30.4088710002827
                        ],
                        [
                            -104.851258000053,
                            30.4138289998448
                        ],
                        [
                            -104.847678999671,
                            30.4138049996523
                        ],
                        [
                            -104.848970999832,
                            30.4179859998095
                        ],
                        [
                            -104.847831000007,
                            30.4197749997173
                        ],
                        [
                            -104.849421999919,
                            30.4215490000438
                        ],
                        [
                            -104.850062999976,
                            30.4192619996193
                        ],
                        [
                            -104.852151000005,
                            30.4187389998313
                        ],
                        [
                            -104.853067000422,
                            30.4205120003322
                        ],
                        [
                            -104.856625999814,
                            30.4215169997349
                        ],
                        [
                            -104.856886000105,
                            30.424475000103
                        ],
                        [
                            -104.861304999837,
                            30.426120999628
                        ],
                        [
                            -104.858148999953,
                            30.432185999654
                        ],
                        [
                            -104.858333000076,
                            30.4376360001116
                        ],
                        [
                            -104.860220000264,
                            30.4399130001556
                        ],
                        [
                            -104.868046999775,
                            30.4414069999594
                        ],
                        [
                            -104.865458000329,
                            30.4449070003488
                        ],
                        [
                            -104.864444000002,
                            30.4505489998194
                        ],
                        [
                            -104.868382000409,
                            30.4541579996506
                        ],
                        [
                            -104.869636000036,
                            30.4591950000578
                        ],
                        [
                            -104.865880000103,
                            30.4668899996495
                        ],
                        [
                            -104.869383000316,
                            30.4741170001718
                        ],
                        [
                            -104.869153000163,
                            30.4787379999335
                        ],
                        [
                            -104.871667000164,
                            30.4803450002045
                        ],
                        [
                            -104.869503999792,
                            30.4846410001611
                        ],
                        [
                            -104.867173999914,
                            30.4850709997093
                        ],
                        [
                            -104.86663199959,
                            30.4944990002839
                        ],
                        [
                            -104.873920999874,
                            30.4952580003016
                        ],
                        [
                            -104.871955000044,
                            30.5110900003175
                        ],
                        [
                            -104.873719000208,
                            30.5137369999193
                        ],
                        [
                            -104.877040999773,
                            30.5112040003806
                        ],
                        [
                            -104.879257999673,
                            30.5178130002125
                        ],
                        [
                            -104.882884999735,
                            30.5167689998761
                        ],
                        [
                            -104.885701999685,
                            30.5182699996954
                        ],
                        [
                            -104.88459899967,
                            30.5203309997521
                        ],
                        [
                            -104.881022999662,
                            30.5209760000613
                        ],
                        [
                            -104.885884000158,
                            30.5245230000311
                        ],
                        [
                            -104.883714999939,
                            30.5261350003368
                        ],
                        [
                            -104.88140900035,
                            30.5239550002053
                        ],
                        [
                            -104.880559000055,
                            30.5289249996604
                        ],
                        [
                            -104.882359000204,
                            30.5322550002553
                        ],
                        [
                            -104.889271000021,
                            30.5353780000117
                        ],
                        [
                            -104.890785999764,
                            30.541009999993
                        ],
                        [
                            -104.887685000132,
                            30.5440890000709
                        ],
                        [
                            -104.887696999828,
                            30.5467269999816
                        ],
                        [
                            -104.890254000385,
                            30.5460369996578
                        ],
                        [
                            -104.889819000192,
                            30.549157000186
                        ],
                        [
                            -104.894061000372,
                            30.5509179997091
                        ],
                        [
                            -104.892374000201,
                            30.5512929998362
                        ],
                        [
                            -104.892898000083,
                            30.552924999621
                        ],
                        [
                            -104.897946000177,
                            30.5545479999832
                        ],
                        [
                            -104.895838000056,
                            30.562272000263
                        ],
                        [
                            -104.898834999932,
                            30.5704729996989
                        ],
                        [
                            -104.906888000297,
                            30.5771639997749
                        ],
                        [
                            -104.909552000086,
                            30.5851429999959
                        ],
                        [
                            -104.917643000086,
                            30.5850929999267
                        ],
                        [
                            -104.919931000131,
                            30.5872749996777
                        ],
                        [
                            -104.921378000103,
                            30.5900319998332
                        ],
                        [
                            -104.918550000282,
                            30.5970379999553
                        ],
                        [
                            -104.921777000311,
                            30.598465000186
                        ],
                        [
                            -104.922676999936,
                            30.6036930002076
                        ],
                        [
                            -104.926520999733,
                            30.605212000105
                        ],
                        [
                            -104.930974999625,
                            30.5990659998211
                        ],
                        [
                            -104.934584000144,
                            30.6000129996301
                        ],
                        [
                            -104.93841500042,
                            30.6036330000081
                        ],
                        [
                            -104.950821000395,
                            30.6040509996199
                        ],
                        [
                            -104.955030000065,
                            30.6093569998149
                        ],
                        [
                            -104.959831000287,
                            30.6105950003216
                        ],
                        [
                            -104.966902000113,
                            30.6078950000513
                        ],
                        [
                            -104.971663999977,
                            30.6100139998432
                        ],
                        [
                            -104.975507999773,
                            30.6142449998078
                        ],
                        [
                            -104.974403000109,
                            30.6167800001665
                        ],
                        [
                            -104.977663999575,
                            30.6181299998726
                        ],
                        [
                            -104.980212999736,
                            30.6219520003476
                        ],
                        [
                            -104.979496000409,
                            30.6285980000979
                        ],
                        [
                            -104.982140999931,
                            30.6302749999417
                        ],
                        [
                            -104.983900000072,
                            30.6350250001053
                        ],
                        [
                            -104.9861100003,
                            30.6627669997589
                        ],
                        [
                            -104.993052000254,
                            30.6649139998546
                        ],
                        [
                            -104.993845999574,
                            30.6685199996928
                        ],
                        [
                            -104.997758000042,
                            30.6689940000828
                        ],
                        [
                            -105.001541999564,
                            30.6727039999261
                        ],
                        [
                            -105.002042999879,
                            30.680877999645
                        ],
                        [
                            -105.005628000109,
                            30.6830759999167
                        ],
                        [
                            -105.006578999788,
                            30.6860600003131
                        ],
                        [
                            -105.013199000425,
                            30.6825619999154
                        ],
                        [
                            -105.016007999979,
                            30.6828780003364
                        ],
                        [
                            -105.017878000448,
                            30.685085999621
                        ],
                        [
                            -105.021270000333,
                            30.6815089999574
                        ],
                        [
                            -105.025217999887,
                            30.681286999654
                        ],
                        [
                            -105.027325000183,
                            30.6788809999816
                        ],
                        [
                            -105.031962000373,
                            30.6809900000369
                        ],
                        [
                            -105.036089000027,
                            30.6866580000276
                        ],
                        [
                            -105.040819000104,
                            30.6889349997416
                        ],
                        [
                            -105.042708999766,
                            30.6873529998347
                        ],
                        [
                            -105.045356999662,
                            30.6889569999657
                        ],
                        [
                            -105.047161000009,
                            30.6880739997771
                        ],
                        [
                            -105.044427999724,
                            30.6841829999224
                        ],
                        [
                            -105.045802999726,
                            30.6798989998566
                        ],
                        [
                            -105.048320999925,
                            30.6802849997856
                        ],
                        [
                            -105.048406000314,
                            30.6824579996485
                        ],
                        [
                            -105.05530700026,
                            30.6821559997539
                        ],
                        [
                            -105.054268999644,
                            30.6851039997499
                        ],
                        [
                            -105.056698000155,
                            30.6878640001472
                        ],
                        [
                            -105.059039999729,
                            30.6880149997837
                        ],
                        [
                            -105.059289000149,
                            30.6864519997794
                        ],
                        [
                            -105.061762000143,
                            30.68584500034
                        ],
                        [
                            -105.063388000214,
                            30.6889380001587
                        ],
                        [
                            -105.06168500015,
                            30.694659999816
                        ],
                        [
                            -105.062552000163,
                            30.6985020000237
                        ],
                        [
                            -105.066884999682,
                            30.6992559996336
                        ],
                        [
                            -105.068232000094,
                            30.7037019998201
                        ],
                        [
                            -105.07006200038,
                            30.7018849998432
                        ],
                        [
                            -105.080941000018,
                            30.6998460002989
                        ],
                        [
                            -105.080786000207,
                            30.7014409997496
                        ],
                        [
                            -105.082592000204,
                            30.7020880003606
                        ],
                        [
                            -105.079382999718,
                            30.7043430000689
                        ],
                        [
                            -105.080227000165,
                            30.707724000142
                        ],
                        [
                            -105.083348999714,
                            30.7099700001273
                        ],
                        [
                            -105.087462999847,
                            30.7098769996998
                        ],
                        [
                            -105.087614000359,
                            30.715488000144
                        ],
                        [
                            -105.091063000145,
                            30.7171770002321
                        ],
                        [
                            -105.098179000177,
                            30.7164790001813
                        ],
                        [
                            -105.09962199995,
                            30.721952999941
                        ],
                        [
                            -105.105547000103,
                            30.7238080000807
                        ],
                        [
                            -105.10331400031,
                            30.7263369998184
                        ],
                        [
                            -105.103371000211,
                            30.7291290000676
                        ],
                        [
                            -105.106614000133,
                            30.7322209999736
                        ],
                        [
                            -105.107097000007,
                            30.7301500002883
                        ],
                        [
                            -105.108805000094,
                            30.7300869998912
                        ],
                        [
                            -105.111825000434,
                            30.7332610002172
                        ],
                        [
                            -105.1098100002,
                            30.739564000348
                        ],
                        [
                            -105.112211000223,
                            30.7405020003488
                        ],
                        [
                            -105.110939000154,
                            30.7435519999484
                        ],
                        [
                            -105.113932999656,
                            30.7459800000742
                        ],
                        [
                            -105.116942000125,
                            30.7430090003247
                        ],
                        [
                            -105.12044299979,
                            30.7438530000762
                        ],
                        [
                            -105.120851000219,
                            30.7458939998268
                        ],
                        [
                            -105.118295000385,
                            30.7497260001967
                        ],
                        [
                            -105.127659000279,
                            30.7478450003764
                        ],
                        [
                            -105.129598000345,
                            30.7503760003358
                        ],
                        [
                            -105.140727000228,
                            30.7522139997311
                        ],
                        [
                            -105.143770999959,
                            30.7552049999854
                        ],
                        [
                            -105.151975999762,
                            30.7514619999252
                        ],
                        [
                            -105.156845999581,
                            30.7539720002176
                        ],
                        [
                            -105.161389999885,
                            30.7521329998957
                        ],
                        [
                            -105.161303999671,
                            30.7562629998878
                        ],
                        [
                            -105.157844000013,
                            30.7613049996192
                        ],
                        [
                            -105.156191000178,
                            30.7697729997015
                        ],
                        [
                            -105.162815000115,
                            30.7707189997787
                        ],
                        [
                            -105.167082000409,
                            30.7765940002026
                        ],
                        [
                            -105.173991999678,
                            30.7675310000088
                        ],
                        [
                            -105.176601000114,
                            30.7679749998142
                        ],
                        [
                            -105.182133999732,
                            30.7762719996786
                        ],
                        [
                            -105.184896000328,
                            30.7769600000743
                        ],
                        [
                            -105.184390999815,
                            30.781572999991
                        ],
                        [
                            -105.185999000343,
                            30.7845499999667
                        ],
                        [
                            -105.190737999743,
                            30.7856969998511
                        ],
                        [
                            -105.19519099981,
                            30.7923040000868
                        ],
                        [
                            -105.199792000015,
                            30.7877309996926
                        ],
                        [
                            -105.206549999847,
                            30.7863460000178
                        ],
                        [
                            -105.209090999612,
                            30.7826550002935
                        ],
                        [
                            -105.211686999629,
                            30.7817659996259
                        ],
                        [
                            -105.216743999944,
                            30.783384999995
                        ],
                        [
                            -105.219687000291,
                            30.786261999878
                        ],
                        [
                            -105.220047000141,
                            30.7888979997548
                        ],
                        [
                            -105.217635000247,
                            30.793166999868
                        ],
                        [
                            -105.212998999881,
                            30.7932320000645
                        ],
                        [
                            -105.209597999775,
                            30.7976279996476
                        ],
                        [
                            -105.213287999586,
                            30.8008060003315
                        ],
                        [
                            -105.21501699959,
                            30.8058930000305
                        ],
                        [
                            -105.218284999627,
                            30.8057589999206
                        ],
                        [
                            -105.223302999584,
                            30.8008719999987
                        ],
                        [
                            -105.227442999657,
                            30.799191000163
                        ],
                        [
                            -105.230018999582,
                            30.8038270002075
                        ],
                        [
                            -105.234490000091,
                            30.8026470002004
                        ],
                        [
                            -105.238178000253,
                            30.8036600002863
                        ],
                        [
                            -105.244172000003,
                            30.7998119998159
                        ],
                        [
                            -105.249928000101,
                            30.79923599968
                        ],
                        [
                            -105.252730999808,
                            30.795867000173
                        ],
                        [
                            -105.257159999586,
                            30.7943540002574
                        ],
                        [
                            -105.264147999571,
                            30.8003349997424
                        ],
                        [
                            -105.266834999824,
                            30.8084809998262
                        ],
                        [
                            -105.271693999773,
                            30.8093820001541
                        ],
                        [
                            -105.275798999685,
                            30.8070029997214
                        ],
                        [
                            -105.279400999633,
                            30.8156979998349
                        ],
                        [
                            -105.282513999859,
                            30.8182789998051
                        ],
                        [
                            -105.287952999765,
                            30.8178769998234
                        ],
                        [
                            -105.289258000345,
                            30.823779000011
                        ],
                        [
                            -105.291794999912,
                            30.8261930002534
                        ],
                        [
                            -105.300136000158,
                            30.8247709999139
                        ],
                        [
                            -105.300533999643,
                            30.819189999743
                        ],
                        [
                            -105.306146999626,
                            30.8098380001431
                        ],
                        [
                            -105.308862000367,
                            30.8110889996692
                        ],
                        [
                            -105.307868999957,
                            30.8147319998373
                        ],
                        [
                            -105.309362999784,
                            30.8170400001624
                        ],
                        [
                            -105.311952000129,
                            30.8133910003152
                        ],
                        [
                            -105.316868999803,
                            30.8109130001372
                        ],
                        [
                            -105.3190940001,
                            30.8122589997427
                        ],
                        [
                            -105.315958000307,
                            30.8187170001382
                        ],
                        [
                            -105.318895999733,
                            30.8240460001507
                        ],
                        [
                            -105.318179000406,
                            30.8278160002361
                        ],
                        [
                            -105.319453000125,
                            30.8290839998539
                        ],
                        [
                            -105.320678000338,
                            30.8273719998229
                        ],
                        [
                            -105.324591999557,
                            30.8283029997128
                        ],
                        [
                            -105.327042999809,
                            30.8247049996927
                        ],
                        [
                            -105.329297000418,
                            30.8249750003809
                        ],
                        [
                            -105.33019399967,
                            30.8261339999849
                        ],
                        [
                            -105.327825000332,
                            30.8310869996695
                        ],
                        [
                            -105.337191999701,
                            30.8334810002407
                        ],
                        [
                            -105.339293000149,
                            30.8404750001901
                        ],
                        [
                            -105.345754999704,
                            30.8406120000047
                        ],
                        [
                            -105.351964000438,
                            30.8355439996652
                        ],
                        [
                            -105.354441999556,
                            30.8377810002154
                        ],
                        [
                            -105.35318399973,
                            30.8420210002474
                        ],
                        [
                            -105.359719999803,
                            30.8459419997032
                        ],
                        [
                            -105.361591999922,
                            30.8505450003634
                        ],
                        [
                            -105.370497000232,
                            30.847500000015
                        ],
                        [
                            -105.375047000384,
                            30.8499650003306
                        ],
                        [
                            -105.377628000332,
                            30.8488139999623
                        ],
                        [
                            -105.377065999918,
                            30.8512620000055
                        ],
                        [
                            -105.379890000439,
                            30.8506150000504
                        ],
                        [
                            -105.383263000057,
                            30.8521059999144
                        ],
                        [
                            -105.384695999785,
                            30.8504970001959
                        ],
                        [
                            -105.387321999939,
                            30.8537340000937
                        ],
                        [
                            -105.391951999559,
                            30.8505939999954
                        ],
                        [
                            -105.394017999846,
                            30.8509129998208
                        ],
                        [
                            -105.394620000444,
                            30.8490210002885
                        ],
                        [
                            -105.399836999694,
                            30.852076999774
                        ],
                        [
                            -105.401639000391,
                            30.8546580003041
                        ],
                        [
                            -105.399270000155,
                            30.8577370002749
                        ],
                        [
                            -105.394945999959,
                            30.8597570001241
                        ],
                        [
                            -105.394403999636,
                            30.8631069998012
                        ],
                        [
                            -105.395992999897,
                            30.8643049999236
                        ],
                        [
                            -105.393639999555,
                            30.866929999909
                        ],
                        [
                            -105.394960000203,
                            30.8711940002304
                        ],
                        [
                            -105.391298999806,
                            30.8738530002822
                        ],
                        [
                            -105.393627999859,
                            30.8763810003293
                        ],
                        [
                            -105.399936000327,
                            30.8766740002677
                        ],
                        [
                            -105.40430300018,
                            30.8796759996908
                        ],
                        [
                            -105.399132000063,
                            30.8842549998066
                        ],
                        [
                            -105.400184999849,
                            30.88938200001
                        ],
                        [
                            -105.403760000032,
                            30.8921059997877
                        ],
                        [
                            -105.412092999881,
                            30.8885599999617
                        ],
                        [
                            -105.410552000199,
                            30.8920479998028
                        ],
                        [
                            -105.413801999794,
                            30.8995669999229
                        ],
                        [
                            -105.425816999956,
                            30.9003960000209
                        ],
                        [
                            -105.43013600013,
                            30.9056040001148
                        ],
                        [
                            -105.441157999882,
                            30.9084490001297
                        ],
                        [
                            -105.444773000249,
                            30.9133359999919
                        ],
                        [
                            -105.445559999896,
                            30.9161240001116
                        ],
                        [
                            -105.444319999614,
                            30.9172580001971
                        ],
                        [
                            -105.446379000229,
                            30.919354999973
                        ],
                        [
                            -105.451347999782,
                            30.915726999684
                        ],
                        [
                            -105.453152000129,
                            30.9162059999732
                        ],
                        [
                            -105.454394999886,
                            30.9193449999281
                        ],
                        [
                            -105.451453000262,
                            30.9210409998477
                        ],
                        [
                            -105.451603999875,
                            30.9248270001574
                        ],
                        [
                            -105.464826999634,
                            30.9258570003706
                        ],
                        [
                            -105.466770999723,
                            30.9242790003772
                        ],
                        [
                            -105.467552000421,
                            30.9296680001016
                        ],
                        [
                            -105.470788999598,
                            30.93123900001
                        ],
                        [
                            -105.47079399962,
                            30.9337120001488
                        ],
                        [
                            -105.475922999905,
                            30.9338170002063
                        ],
                        [
                            -105.479749000159,
                            30.9395359996281
                        ],
                        [
                            -105.486358000027,
                            30.9426250000839
                        ],
                        [
                            -105.488730999563,
                            30.9408150000656
                        ],
                        [
                            -105.491653999819,
                            30.9424070001393
                        ],
                        [
                            -105.491355000068,
                            30.9438059998839
                        ],
                        [
                            -105.488677000035,
                            30.9425150001033
                        ],
                        [
                            -105.488113999795,
                            30.9437249998608
                        ],
                        [
                            -105.493727999604,
                            30.9494609996503
                        ],
                        [
                            -105.498871999957,
                            30.9504179996401
                        ],
                        [
                            -105.496809999868,
                            30.9554199999511
                        ],
                        [
                            -105.493893000359,
                            30.9564239998993
                        ],
                        [
                            -105.501967999567,
                            30.9677369998134
                        ],
                        [
                            -105.515717999581,
                            30.9648570001098
                        ],
                        [
                            -105.516888000441,
                            30.9694280002703
                        ],
                        [
                            -105.521799000268,
                            30.97628600027
                        ],
                        [
                            -105.527083000364,
                            30.9781779996478
                        ],
                        [
                            -105.532676000256,
                            30.9846970003047
                        ],
                        [
                            -105.538042000367,
                            30.9857340001721
                        ],
                        [
                            -105.543017999593,
                            30.9846439999411
                        ],
                        [
                            -105.557226000265,
                            30.9896730002835
                        ],
                        [
                            -105.570095999847,
                            31.0082029999037
                        ],
                        [
                            -105.568756000006,
                            31.0146059997689
                        ],
                        [
                            -105.570531999865,
                            31.0180070001368
                        ],
                        [
                            -105.578007999747,
                            31.0200780000652
                        ],
                        [
                            -105.581245999646,
                            31.0266530003368
                        ],
                        [
                            -105.579524000214,
                            31.035695000193
                        ],
                        [
                            -105.584994000082,
                            31.0569639999492
                        ],
                        [
                            -105.596003000315,
                            31.06528499986
                        ],
                        [
                            -105.59836699963,
                            31.0743179996584
                        ],
                        [
                            -105.604466999685,
                            31.0837440003515
                        ],
                        [
                            -105.627101999918,
                            31.09840500031
                        ],
                        [
                            -105.641829000448,
                            31.0983329998511
                        ],
                        [
                            -105.64648800038,
                            31.1130800001616
                        ],
                        [
                            -105.648839000175,
                            31.115876999926
                        ],
                        [
                            -105.710525999918,
                            31.1369749999947
                        ],
                        [
                            -105.717097999977,
                            31.1415340000555
                        ],
                        [
                            -105.719273000044,
                            31.1488940000162
                        ],
                        [
                            -105.741929000193,
                            31.164602000352
                        ],
                        [
                            -105.763370000174,
                            31.1641270000502
                        ],
                        [
                            -105.772990000162,
                            31.1667020002115
                        ],
                        [
                            -105.775795000416,
                            31.1758289999804
                        ],
                        [
                            -105.780030000025,
                            31.182740000134
                        ],
                        [
                            -105.779477999656,
                            31.1902279998892
                        ],
                        [
                            -105.782797999571,
                            31.1974590000319
                        ],
                        [
                            -105.794287000203,
                            31.2022860000452
                        ],
                        [
                            -105.818381000102,
                            31.2301460000287
                        ],
                        [
                            -105.836391999665,
                            31.2477219997102
                        ],
                        [
                            -105.85033199965,
                            31.264967999936
                        ],
                        [
                            -105.853813999947,
                            31.2725210001098
                        ],
                        [
                            -105.86929399979,
                            31.2889549998027
                        ],
                        [
                            -105.876387000255,
                            31.2916679998254
                        ],
                        [
                            -105.894145999923,
                            31.2907489997635
                        ],
                        [
                            -105.907387999949,
                            31.3122140003276
                        ],
                        [
                            -105.931584999781,
                            31.3127809999127
                        ],
                        [
                            -105.937894000074,
                            31.3182229997411
                        ],
                        [
                            -105.947751999712,
                            31.3395890002571
                        ],
                        [
                            -105.945732000354,
                            31.3529499999079
                        ],
                        [
                            -105.953785999645,
                            31.36474899983
                        ],
                        [
                            -105.970046000361,
                            31.3659770002625
                        ],
                        [
                            -106.004920999989,
                            31.3924559998882
                        ],
                        [
                            -106.075307000178,
                            31.397615000293
                        ],
                        [
                            -106.081664999976,
                            31.3992230003724
                        ],
                        [
                            -106.108615000184,
                            31.422374999885
                        ],
                        [
                            -106.132290999609,
                            31.4250810001173
                        ],
                        [
                            -106.156934000403,
                            31.4373599996999
                        ],
                        [
                            -106.175625000381,
                            31.4552060000837
                        ],
                        [
                            -106.200119000314,
                            31.462411000185
                        ],
                        [
                            -106.207631000181,
                            31.4671040003565
                        ],
                        [
                            -106.219073999883,
                            31.4802799998297
                        ],
                        [
                            -106.224763999859,
                            31.5009290002839
                        ],
                        [
                            -106.237224000261,
                            31.5138580001497
                        ],
                        [
                            -106.246571000437,
                            31.5416610002867
                        ],
                        [
                            -106.254741999904,
                            31.5480430001006
                        ],
                        [
                            -106.280789999939,
                            31.5620960001861
                        ],
                        [
                            -106.288740000371,
                            31.5871569997641
                        ],
                        [
                            -106.300889000429,
                            31.6096240003706
                        ],
                        [
                            -106.303432999669,
                            31.6221930001632
                        ],
                        [
                            -106.334435000086,
                            31.6635550002352
                        ],
                        [
                            -106.349565000124,
                            31.6967120003712
                        ],
                        [
                            -106.372370000236,
                            31.7125689999574
                        ],
                        [
                            -106.380320999596,
                            31.7313780001932
                        ],
                        [
                            -106.415684000019,
                            31.7508930001415
                        ],
                        [
                            -106.435718000211,
                            31.7552750000199
                        ],
                        [
                            -106.451429999858,
                            31.7644269996882
                        ],
                        [
                            -106.467555999781,
                            31.759210000009
                        ],
                        [
                            -106.473207000298,
                            31.7504929996523
                        ],
                        [
                            -106.484641999604,
                            31.7478089999789
                        ],
                        [
                            -106.490549000214,
                            31.7489239998343
                        ],
                        [
                            -106.50581800017,
                            31.7604719999539
                        ],
                        [
                            -106.51006000035,
                            31.7612579997424
                        ],
                        [
                            -106.513687000412,
                            31.7707110002422
                        ],
                        [
                            -106.520988000392,
                            31.7738289996676
                        ],
                        [
                            -106.52849799971,
                            31.7814970002203
                        ],
                        [
                            -106.527402000266,
                            31.7900109999668
                        ],
                        [
                            -106.532795000142,
                            31.792444000296
                        ],
                        [
                            -106.536004999554,
                            31.7985059996985
                        ],
                        [
                            -106.544156999653,
                            31.8034700000011
                        ],
                        [
                            -106.547140000183,
                            31.8072989999523
                        ],
                        [
                            -106.562950999564,
                            31.8111050001232
                        ],
                        [
                            -106.563410000046,
                            31.8127360001732
                        ],
                        [
                            -106.566472000219,
                            31.8134329998794
                        ],
                        [
                            -106.570943999654,
                            31.8102059999611
                        ],
                        [
                            -106.577243999726,
                            31.8104060003369
                        ],
                        [
                            -106.589061000421,
                            31.8227079996558
                        ],
                        [
                            -106.593824999934,
                            31.8248980001305
                        ],
                        [
                            -106.602806000412,
                            31.8250179999413
                        ],
                        [
                            -106.605296999949,
                            31.8277200003175
                        ],
                        [
                            -106.601944000422,
                            31.8396010000041
                        ],
                        [
                            -106.602043000156,
                            31.8444060003357
                        ],
                        [
                            -106.606199999947,
                            31.8463260000859
                        ],
                        [
                            -106.614788000065,
                            31.8464129997768
                        ],
                        [
                            -106.625773000007,
                            31.8561649999517
                        ],
                        [
                            -106.628173000206,
                            31.861120000366
                        ],
                        [
                            -106.635920000249,
                            31.8662330001136
                        ],
                        [
                            -106.634874000136,
                            31.8744769996291
                        ],
                        [
                            -106.629190999832,
                            31.8836990003276
                        ],
                        [
                            -106.633921999734,
                            31.8891799997199
                        ],
                        [
                            -106.642900999663,
                            31.8929330002705
                        ],
                        [
                            -106.645645999643,
                            31.8956460001323
                        ],
                        [
                            -106.645478000312,
                            31.8986690001884
                        ],
                        [
                            -106.640061999972,
                            31.9052730002299
                        ],
                        [
                            -106.633667000364,
                            31.909793000381
                        ],
                        [
                            -106.625567999968,
                            31.912431999717
                        ],
                        [
                            -106.618750999687,
                            31.9177950001858
                        ],
                        [
                            -106.616146000348,
                            31.9173030001157
                        ],
                        [
                            -106.611847000266,
                            31.9200029996364
                        ],
                        [
                            -106.623931999851,
                            31.9253340000354
                        ],
                        [
                            -106.62865400043,
                            31.9236079996188
                        ],
                        [
                            -106.629742000376,
                            31.9265669997117
                        ],
                        [
                            -106.625338999639,
                            31.9300309997429
                        ],
                        [
                            -106.62252900026,
                            31.9348629999065
                        ],
                        [
                            -106.623659000039,
                            31.9455100001051
                        ],
                        [
                            -106.615253000395,
                            31.9489650000219
                        ],
                        [
                            -106.614321000084,
                            31.9516149998117
                        ],
                        [
                            -106.614374999613,
                            31.9559910003723
                        ],
                        [
                            -106.617707000122,
                            31.9560080000331
                        ],
                        [
                            -106.622817999965,
                            31.9528909997187
                        ],
                        [
                            -106.625122999729,
                            31.9545300002382
                        ],
                        [
                            -106.624298000447,
                            31.9610560002026
                        ],
                        [
                            -106.619370999828,
                            31.9647770001019
                        ],
                        [
                            -106.619569000195,
                            31.9715769999184
                        ],
                        [
                            -106.623186000211,
                            31.9729180003216
                        ],
                        [
                            -106.626522999845,
                            31.9706750003651
                        ],
                        [
                            -106.630102000226,
                            31.9712579996397
                        ],
                        [
                            -106.638193000226,
                            31.9768239997939
                        ],
                        [
                            -106.639536000441,
                            31.9803429997953
                        ],
                        [
                            -106.636496999834,
                            31.9857110002691
                        ],
                        [
                            -106.631185999974,
                            31.9898099999194
                        ],
                        [
                            -106.623567999803,
                            31.9909980003416
                        ],
                        [
                            -106.619448999646,
                            31.9947349998489
                        ],
                        [
                            -106.618486000272,
                            32.0004950002891
                        ],
                        [
                            -106.082896000038,
                            32.0026500003734
                        ],
                        [
                            -104.847739999771,
                            32.000415000253
                        ],
                        [
                            -104.772106000369,
                            31.9974859999823
                        ],
                        [
                            -104.754787000019,
                            31.9977729997087
                        ],
                        [
                            -104.754556000041,
                            32.0005270001316
                        ],
                        [
                            -103.980179000239,
                            32.0001249996323
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 31.4347032,
            "name": "Texas",
            "intptlon": -99.2818238,
            "geo_point_2d": [
                31.4472069363,
                -99.3171748956
            ],
            "geoid": "48",
            "mtfcc": "G4000",
            "region": 3
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -99.3171748956,
                31.4472069363
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "7a18f0c58ce0a2f2f722c2d6b1e99727303baaf6",
        "fields": {
            "arealand": "543558310",
            "objectid": 37,
            "basename": "Guam",
            "stusab": "GU",
            "statens": "01802705",
            "centlon": 144.7693797,
            "state": "66",
            "gid": 24,
            "centlat": 13.4427156,
            "division": 0,
            "areawater": "934334983",
            "oid": "-1337545049",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            144.564620999617,
                            13.435154999964
                        ],
                        [
                            144.563573999679,
                            13.4509870003661
                        ],
                        [
                            144.566342000124,
                            13.4636940001478
                        ],
                        [
                            144.570508000136,
                            13.4721409995963
                        ],
                        [
                            144.576681999811,
                            13.4810590003402
                        ],
                        [
                            144.588799000081,
                            13.4937979997296
                        ],
                        [
                            144.599019000118,
                            13.4999999996747
                        ],
                        [
                            144.611150999558,
                            13.5102430004257
                        ],
                        [
                            144.625000000205,
                            13.5158819999984
                        ],
                        [
                            144.637431000294,
                            13.5175529997612
                        ],
                        [
                            144.663123999603,
                            13.5152280003468
                        ],
                        [
                            144.676698999715,
                            13.5227869997311
                        ],
                        [
                            144.693007999937,
                            13.5290200003819
                        ],
                        [
                            144.709262999731,
                            13.5290400001028
                        ],
                        [
                            144.725468000196,
                            13.5317390002609
                        ],
                        [
                            144.737478000336,
                            13.5442800002334
                        ],
                        [
                            144.749999999763,
                            13.5518920002029
                        ],
                        [
                            144.764709999677,
                            13.5826440001664
                        ],
                        [
                            144.777425000347,
                            13.5986199997789
                        ],
                        [
                            144.781076999625,
                            13.6161599997698
                        ],
                        [
                            144.781110000135,
                            13.6249999997225
                        ],
                        [
                            144.789597999795,
                            13.6533119999362
                        ],
                        [
                            144.801780000363,
                            13.66796800038
                        ],
                        [
                            144.811923000407,
                            13.6844249995745
                        ],
                        [
                            144.827147999981,
                            13.696916999896
                        ],
                        [
                            144.838474000406,
                            13.7026939998873
                        ],
                        [
                            144.845293000337,
                            13.7050870000332
                        ],
                        [
                            144.860436999721,
                            13.7058749995677
                        ],
                        [
                            144.87500000022,
                            13.7033309999103
                        ],
                        [
                            144.889152999742,
                            13.6982220004364
                        ],
                        [
                            144.912553999706,
                            13.6830319998074
                        ],
                        [
                            144.923866999712,
                            13.6682080001962
                        ],
                        [
                            144.936916000292,
                            13.656078999676
                        ],
                        [
                            144.940513000217,
                            13.6505720000909
                        ],
                        [
                            144.961784000319,
                            13.6487720001021
                        ],
                        [
                            144.981576999589,
                            13.6412089996059
                        ],
                        [
                            144.998897999588,
                            13.6242570002256
                        ],
                        [
                            145.003107999982,
                            13.6181429999795
                        ],
                        [
                            145.008791000285,
                            13.5958930000377
                        ],
                        [
                            145.008209999604,
                            13.5862379997874
                        ],
                        [
                            145.003088999715,
                            13.5736209998126
                        ],
                        [
                            144.999378999812,
                            13.5696379995872
                        ],
                        [
                            144.998649000066,
                            13.5636280003153
                        ],
                        [
                            144.994791000026,
                            13.5544450004244
                        ],
                        [
                            144.98947199977,
                            13.5471880004195
                        ],
                        [
                            144.98475900031,
                            13.5294499996881
                        ],
                        [
                            144.97924699971,
                            13.5196469999472
                        ],
                        [
                            144.975823000038,
                            13.5068020003455
                        ],
                        [
                            144.972037999792,
                            13.4999999996747
                        ],
                        [
                            144.954052000344,
                            13.4789130002759
                        ],
                        [
                            144.944172000066,
                            13.4726890000158
                        ],
                        [
                            144.932281999577,
                            13.4693630003612
                        ],
                        [
                            144.917212999637,
                            13.4523750002168
                        ],
                        [
                            144.905267999795,
                            13.4447070003487
                        ],
                        [
                            144.898875999661,
                            13.442637999799
                        ],
                        [
                            144.882220999834,
                            13.4248610001038
                        ],
                        [
                            144.87500000022,
                            13.4195739996854
                        ],
                        [
                            144.870269000318,
                            13.4181940000659
                        ],
                        [
                            144.866444999715,
                            13.4125070002948
                        ],
                        [
                            144.857084000194,
                            13.4038339997992
                        ],
                        [
                            144.844550999997,
                            13.3969060003952
                        ],
                        [
                            144.83928199997,
                            13.3893879996978
                        ],
                        [
                            144.826593999963,
                            13.3814500000247
                        ],
                        [
                            144.822534000256,
                            13.354591999975
                        ],
                        [
                            144.822765000234,
                            13.3403609998705
                        ],
                        [
                            144.82082399962,
                            13.3336510002092
                        ],
                        [
                            144.821652999999,
                            13.2988029997523
                        ],
                        [
                            144.817986999579,
                            13.2804479997582
                        ],
                        [
                            144.81161600026,
                            13.2677819998935
                        ],
                        [
                            144.804682999628,
                            13.2601820002968
                        ],
                        [
                            144.802732999691,
                            13.251179999989
                        ],
                        [
                            144.79918699982,
                            13.2499999998219
                        ],
                        [
                            144.791758999619,
                            13.2409039997709
                        ],
                        [
                            144.783153999783,
                            13.2351050000526
                        ],
                        [
                            144.781065999754,
                            13.2291960001241
                        ],
                        [
                            144.770736999938,
                            13.2157259999222
                        ],
                        [
                            144.756487000331,
                            13.2032619999712
                        ],
                        [
                            144.740558000051,
                            13.1951909998844
                        ],
                        [
                            144.729643000429,
                            13.1926700000614
                        ],
                        [
                            144.719721000318,
                            13.1933810003289
                        ],
                        [
                            144.706158999726,
                            13.1907089998818
                        ],
                        [
                            144.688332000286,
                            13.1910349998111
                        ],
                        [
                            144.675005999696,
                            13.187752999891
                        ],
                        [
                            144.667866000274,
                            13.1884089998489
                        ],
                        [
                            144.656662999873,
                            13.1842010001269
                        ],
                        [
                            144.635795000177,
                            13.1824519999538
                        ],
                        [
                            144.625950000059,
                            13.1840700004097
                        ],
                        [
                            144.615217999836,
                            13.1889700003086
                        ],
                        [
                            144.600157000293,
                            13.2022510003387
                        ],
                        [
                            144.591864999553,
                            13.2174979995756
                        ],
                        [
                            144.590271999992,
                            13.2345419999388
                        ],
                        [
                            144.593417000005,
                            13.2499999998219
                        ],
                        [
                            144.594668000158,
                            13.2499999998219
                        ],
                        [
                            144.600039000292,
                            13.2609719999072
                        ],
                        [
                            144.608374999616,
                            13.2706119997609
                        ],
                        [
                            144.603971999777,
                            13.2809719999382
                        ],
                        [
                            144.602994000334,
                            13.2951489996013
                        ],
                        [
                            144.593448999792,
                            13.3016380003148
                        ],
                        [
                            144.582817999851,
                            13.3155499999513
                        ],
                        [
                            144.578573000196,
                            13.3285799996341
                        ],
                        [
                            144.577645999909,
                            13.3410049996049
                        ],
                        [
                            144.578184999859,
                            13.349289000323
                        ],
                        [
                            144.582001999892,
                            13.3606119996077
                        ],
                        [
                            144.584601000282,
                            13.3764100001131
                        ],
                        [
                            144.591277000097,
                            13.3865229998709
                        ],
                        [
                            144.593040000436,
                            13.3924230002917
                        ],
                        [
                            144.573904000219,
                            13.4111670002066
                        ],
                        [
                            144.568158999991,
                            13.4222569996277
                        ],
                        [
                            144.564620999617,
                            13.435154999964
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 13.4382886,
            "name": "Guam",
            "intptlon": 144.7729493,
            "geo_point_2d": [
                13.4427523818,
                144.769442514
            ],
            "geoid": "66",
            "mtfcc": "G4000",
            "region": 9
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                144.769442514,
                13.4427523818
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "f92a15a3ae8d8fd4634e21524054ab26d92ab053",
        "fields": {
            "arealand": "-1361459624",
            "objectid": 55,
            "basename": "Iowa",
            "stusab": "IA",
            "statens": "01779785",
            "centlon": -93.4958531,
            "state": "19",
            "gid": 21,
            "centlat": 42.0753593,
            "division": 4,
            "areawater": "1078023380",
            "oid": "279100047",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -96.4987760000524,
                            42.5610430003248
                        ],
                        [
                            -96.4861459997714,
                            42.5731490000398
                        ],
                        [
                            -96.4857969997919,
                            42.575019999881
                        ],
                        [
                            -96.4956539996055,
                            42.5795410000207
                        ],
                        [
                            -96.4954989997948,
                            42.5827919999368
                        ],
                        [
                            -96.4912709998584,
                            42.5868059998588
                        ],
                        [
                            -96.5010819996412,
                            42.5893110000963
                        ],
                        [
                            -96.5001829998409,
                            42.5941040002796
                        ],
                        [
                            -96.5046529996267,
                            42.6049979997903
                        ],
                        [
                            -96.5100150004384,
                            42.6132110000606
                        ],
                        [
                            -96.5176629998493,
                            42.6166469998856
                        ],
                        [
                            -96.5257179998642,
                            42.6092759996782
                        ],
                        [
                            -96.5299369995798,
                            42.6104710001516
                        ],
                        [
                            -96.5313119995812,
                            42.6166469998856
                        ],
                        [
                            -96.5281309995831,
                            42.6184399997475
                        ],
                        [
                            -96.522581000247,
                            42.6175760000565
                        ],
                        [
                            -96.5190389996756,
                            42.6189860001417
                        ],
                        [
                            -96.5151580000688,
                            42.6283699999768
                        ],
                        [
                            -96.5216010002558,
                            42.6348830002145
                        ],
                        [
                            -96.5251429999289,
                            42.6347460002725
                        ],
                        [
                            -96.5266300000833,
                            42.64145000006
                        ],
                        [
                            -96.537471999911,
                            42.6459510001919
                        ],
                        [
                            -96.5377670003621,
                            42.6554250001874
                        ],
                        [
                            -96.5412189996236,
                            42.659916000228
                        ],
                        [
                            -96.5463019998777,
                            42.6615339999416
                        ],
                        [
                            -96.5593880002683,
                            42.6578289997771
                        ],
                        [
                            -96.5598080003929,
                            42.6628680000949
                        ],
                        [
                            -96.5557700004272,
                            42.66420699985
                        ],
                        [
                            -96.5643889996084,
                            42.6713280001837
                        ],
                        [
                            -96.5669639997091,
                            42.6764169997194
                        ],
                        [
                            -96.5697070000393,
                            42.6749699999436
                        ],
                        [
                            -96.5715669995643,
                            42.6708960000529
                        ],
                        [
                            -96.5784740002568,
                            42.6719319999684
                        ],
                        [
                            -96.5743540002754,
                            42.6736770002722
                        ],
                        [
                            -96.5720529998111,
                            42.676703999978
                        ],
                        [
                            -96.5726229997235,
                            42.6789259997874
                        ],
                        [
                            -96.578757000114,
                            42.6781229999457
                        ],
                        [
                            -96.5749719998684,
                            42.6807299997637
                        ],
                        [
                            -96.5751800002808,
                            42.6827569997109
                        ],
                        [
                            -96.58618600014,
                            42.6831769998074
                        ],
                        [
                            -96.5863419997757,
                            42.6897939997313
                        ],
                        [
                            -96.589841999616,
                            42.6910409997253
                        ],
                        [
                            -96.5918519998271,
                            42.6905460001662
                        ],
                        [
                            -96.5922950004162,
                            42.6871600000231
                        ],
                        [
                            -96.5996390000534,
                            42.6868929997637
                        ],
                        [
                            -96.5993810003106,
                            42.6889040002284
                        ],
                        [
                            -96.5965590003377,
                            42.6882879998638
                        ],
                        [
                            -96.5936129996179,
                            42.6897939997313
                        ],
                        [
                            -96.5965390002462,
                            42.6919319999508
                        ],
                        [
                            -96.5939280001605,
                            42.6943270002282
                        ],
                        [
                            -96.5976239998194,
                            42.6956370002958
                        ],
                        [
                            -96.5996370004036,
                            42.6988540001056
                        ],
                        [
                            -96.6013809995777,
                            42.6990029998325
                        ],
                        [
                            -96.6056429998493,
                            42.6946830001878
                        ],
                        [
                            -96.6120860000363,
                            42.6955799998518
                        ],
                        [
                            -96.6096239999136,
                            42.6984249998435
                        ],
                        [
                            -96.6046029995836,
                            42.6984729997939
                        ],
                        [
                            -96.6055959999937,
                            42.7020200000226
                        ],
                        [
                            -96.6098010003639,
                            42.7023239999652
                        ],
                        [
                            -96.6151480002087,
                            42.6984330001679
                        ],
                        [
                            -96.6160779999712,
                            42.7004370001081
                        ],
                        [
                            -96.619193999672,
                            42.6998120002481
                        ],
                        [
                            -96.6239570002591,
                            42.7076000000434
                        ],
                        [
                            -96.6259799999907,
                            42.704041999812
                        ],
                        [
                            -96.6303529996921,
                            42.7056840002111
                        ],
                        [
                            -96.6293330004162,
                            42.7090149997811
                        ],
                        [
                            -96.6268550003999,
                            42.7099259999577
                        ],
                        [
                            -96.6244059997976,
                            42.7140110002987
                        ],
                        [
                            -96.624799000158,
                            42.7159549996837
                        ],
                        [
                            -96.6280110001182,
                            42.7177979998251
                        ],
                        [
                            -96.6245449997148,
                            42.7209440003189
                        ],
                        [
                            -96.6244140001936,
                            42.7252119998537
                        ],
                        [
                            -96.6341889999914,
                            42.724305999951
                        ],
                        [
                            -96.6347039996522,
                            42.7261220000807
                        ],
                        [
                            -96.6305219997465,
                            42.7302340002794
                        ],
                        [
                            -96.6367199997111,
                            42.7332309999743
                        ],
                        [
                            -96.6394839999577,
                            42.7369629999614
                        ],
                        [
                            -96.6305860002189,
                            42.7352860001389
                        ],
                        [
                            -96.6356119996734,
                            42.741893999874
                        ],
                        [
                            -96.6294289997774,
                            42.7436410001475
                        ],
                        [
                            -96.6321550003891,
                            42.7452100002232
                        ],
                        [
                            -96.6300029998877,
                            42.7471420000229
                        ],
                        [
                            -96.6300189997812,
                            42.750695999991
                        ],
                        [
                            -96.6192690000151,
                            42.7547519998173
                        ],
                        [
                            -96.620267000448,
                            42.757584999942
                        ],
                        [
                            -96.6228330003278,
                            42.7583980000426
                        ],
                        [
                            -96.6255659997139,
                            42.7544569997865
                        ],
                        [
                            -96.6272429998391,
                            42.7544130001132
                        ],
                        [
                            -96.628129000119,
                            42.7571850003072
                        ],
                        [
                            -96.6364889997325,
                            42.7647530002307
                        ],
                        [
                            -96.6319749995657,
                            42.7651439997264
                        ],
                        [
                            -96.6323350003142,
                            42.7680410002742
                        ],
                        [
                            -96.636516000395,
                            42.7693959999545
                        ],
                        [
                            -96.6335820002691,
                            42.7712620001797
                        ],
                        [
                            -96.6316330001573,
                            42.769612999734
                        ],
                        [
                            -96.6294310003255,
                            42.7719529997825
                        ],
                        [
                            -96.6263609997572,
                            42.7714029999775
                        ],
                        [
                            -96.6269850000963,
                            42.7753019997166
                        ],
                        [
                            -96.6219849996828,
                            42.7768299999343
                        ],
                        [
                            -96.6195030003668,
                            42.7840429997987
                        ],
                        [
                            -96.6155690001582,
                            42.7850159997548
                        ],
                        [
                            -96.6147099996425,
                            42.7822469999231
                        ],
                        [
                            -96.6125630000623,
                            42.7846199997689
                        ],
                        [
                            -96.6104890002771,
                            42.7825780002673
                        ],
                        [
                            -96.6079929998191,
                            42.7844340001499
                        ],
                        [
                            -96.6044400002753,
                            42.7831100001563
                        ],
                        [
                            -96.6023449996754,
                            42.7881129998431
                        ],
                        [
                            -96.6050069998147,
                            42.7916959998424
                        ],
                        [
                            -96.5952139995752,
                            42.7928960001198
                        ],
                        [
                            -96.5996830004344,
                            42.7984350002085
                        ],
                        [
                            -96.5981719999905,
                            42.7999659999671
                        ],
                        [
                            -96.5964499996594,
                            42.7975900000369
                        ],
                        [
                            -96.5940760002985,
                            42.7982349997945
                        ],
                        [
                            -96.5904650001301,
                            42.8082970001434
                        ],
                        [
                            -96.5956260002021,
                            42.8103430001455
                        ],
                        [
                            -96.5959450000444,
                            42.8149540002767
                        ],
                        [
                            -96.5907239996979,
                            42.8153829998122
                        ],
                        [
                            -96.5889429998156,
                            42.8184870001294
                        ],
                        [
                            -96.5841089999818,
                            42.8190049996889
                        ],
                        [
                            -96.5858079998483,
                            42.8237759999117
                        ],
                        [
                            -96.5805539998899,
                            42.8248800001582
                        ],
                        [
                            -96.5776540000992,
                            42.8277910003208
                        ],
                        [
                            -96.5823049996349,
                            42.8337499998737
                        ],
                        [
                            -96.5813780002455,
                            42.8378100002894
                        ],
                        [
                            -96.5783300003168,
                            42.8377989997006
                        ],
                        [
                            -96.5797170000138,
                            42.8356259998524
                        ],
                        [
                            -96.5781639997371,
                            42.8347270000945
                        ],
                        [
                            -96.5744239996973,
                            42.8379780001154
                        ],
                        [
                            -96.5683820002666,
                            42.83778100023
                        ],
                        [
                            -96.5696829997498,
                            42.8345000001166
                        ],
                        [
                            -96.5636039996109,
                            42.8281740002424
                        ],
                        [
                            -96.5615180001301,
                            42.829466999777
                        ],
                        [
                            -96.5630119999572,
                            42.8313559998227
                        ],
                        [
                            -96.5628620001694,
                            42.8358869997762
                        ],
                        [
                            -96.5609440000197,
                            42.839133999723
                        ],
                        [
                            -96.5585429999963,
                            42.8395120003056
                        ],
                        [
                            -96.5539860001719,
                            42.8361060002397
                        ],
                        [
                            -96.5503080000563,
                            42.83755500021
                        ],
                        [
                            -96.5485980003192,
                            42.8408909997184
                        ],
                        [
                            -96.5538050004219,
                            42.8431819999704
                        ],
                        [
                            -96.5560830004217,
                            42.8492309998221
                        ],
                        [
                            -96.5501950000784,
                            42.8475839996765
                        ],
                        [
                            -96.5494539995635,
                            42.8512079998132
                        ],
                        [
                            -96.5452619996121,
                            42.8498540002402
                        ],
                        [
                            -96.5414549996251,
                            42.856868000155
                        ],
                        [
                            -96.5426199995642,
                            42.8596880003057
                        ],
                        [
                            -96.5452120002817,
                            42.8570810002964
                        ],
                        [
                            -96.5468190000867,
                            42.8574359999967
                        ],
                        [
                            -96.5461000002114,
                            42.8611610001804
                        ],
                        [
                            -96.5504510001715,
                            42.8635479997501
                        ],
                        [
                            -96.5465730000394,
                            42.8639139996917
                        ],
                        [
                            -96.5461739998312,
                            42.8675519997994
                        ],
                        [
                            -96.549701000334,
                            42.8698800001486
                        ],
                        [
                            -96.5471460003248,
                            42.8739550001586
                        ],
                        [
                            -96.5376700002777,
                            42.8784620002809
                        ],
                        [
                            -96.5434849999277,
                            42.8837650002705
                        ],
                        [
                            -96.5376950003921,
                            42.8867479997543
                        ],
                        [
                            -96.5398379997743,
                            42.8898030002648
                        ],
                        [
                            -96.5272100000415,
                            42.890617000076
                        ],
                        [
                            -96.5252869998689,
                            42.8919099997131
                        ],
                        [
                            -96.5296920002557,
                            42.8942179997751
                        ],
                        [
                            -96.528872999923,
                            42.8979259998646
                        ],
                        [
                            -96.5361239996738,
                            42.9008789999308
                        ],
                        [
                            -96.5395309996277,
                            42.8999389996863
                        ],
                        [
                            -96.5429300000839,
                            42.9037560000851
                        ],
                        [
                            -96.542416000248,
                            42.9050540000905
                        ],
                        [
                            -96.5364669998055,
                            42.9056429998798
                        ],
                        [
                            -96.5385859997966,
                            42.907840000249
                        ],
                        [
                            -96.5378090001949,
                            42.9109540000133
                        ],
                        [
                            -96.5400999997152,
                            42.9123180000178
                        ],
                        [
                            -96.5377589999662,
                            42.9173180001413
                        ],
                        [
                            -96.5411640002703,
                            42.9194060001828
                        ],
                        [
                            -96.5412830000959,
                            42.9239269999414
                        ],
                        [
                            -96.5399259996379,
                            42.9250660000564
                        ],
                        [
                            -96.5364090000791,
                            42.9231060000704
                        ],
                        [
                            -96.534105999965,
                            42.9237100002012
                        ],
                        [
                            -96.5329519998965,
                            42.9247539997588
                        ],
                        [
                            -96.5340609997592,
                            42.9281659999995
                        ],
                        [
                            -96.5253119999833,
                            42.9356269999151
                        ],
                        [
                            -96.521704000188,
                            42.9351449998482
                        ],
                        [
                            -96.5197989995588,
                            42.9319759999474
                        ],
                        [
                            -96.516835000194,
                            42.932347000325
                        ],
                        [
                            -96.5164500002295,
                            42.9352000002252
                        ],
                        [
                            -96.5198580000083,
                            42.9379889997591
                        ],
                        [
                            -96.5198699997039,
                            42.939969000108
                        ],
                        [
                            -96.514198000169,
                            42.9448510000629
                        ],
                        [
                            -96.5092880001672,
                            42.9451530001217
                        ],
                        [
                            -96.507907000318,
                            42.9483909999086
                        ],
                        [
                            -96.5089190000963,
                            42.9537149997073
                        ],
                        [
                            -96.4988940000532,
                            42.958011000292
                        ],
                        [
                            -96.5018390000497,
                            42.9607769999387
                        ],
                        [
                            -96.5026819997736,
                            42.9676050001141
                        ],
                        [
                            -96.5051599997899,
                            42.971095000096
                        ],
                        [
                            -96.515773000187,
                            42.9728440002848
                        ],
                        [
                            -96.5208379999996,
                            42.9803140001686
                        ],
                        [
                            -96.516830999996,
                            42.9810200001499
                        ],
                        [
                            -96.5183640001812,
                            42.9851869998525
                        ],
                        [
                            -96.5153420001918,
                            42.9841410001288
                        ],
                        [
                            -96.5123820001266,
                            42.985657999853
                        ],
                        [
                            -96.5111629997608,
                            42.9903099998423
                        ],
                        [
                            -96.5128340000383,
                            42.9919120000648
                        ],
                        [
                            -96.5098599997294,
                            42.9952280000601
                        ],
                        [
                            -96.4969950001701,
                            42.9984780000309
                        ],
                        [
                            -96.4928109997163,
                            43.0056499999908
                        ],
                        [
                            -96.4919810004112,
                            43.0101969997588
                        ],
                        [
                            -96.4956720000472,
                            43.0158690002374
                        ],
                        [
                            -96.4995319997376,
                            43.0165480002446
                        ],
                        [
                            -96.499039999643,
                            43.0190730002105
                        ],
                        [
                            -96.5039370001244,
                            43.0203659996813
                        ],
                        [
                            -96.5120320003223,
                            43.0262119997507
                        ],
                        [
                            -96.5139710003883,
                            43.030323999794
                        ],
                        [
                            -96.5106659996434,
                            43.0320359997892
                        ],
                        [
                            -96.5091380003794,
                            43.036762999925
                        ],
                        [
                            -96.5110480001331,
                            43.0397269997944
                        ],
                        [
                            -96.5132330002464,
                            43.0403499997318
                        ],
                        [
                            -96.520085999614,
                            43.0377619999748
                        ],
                        [
                            -96.5213019996067,
                            43.0396600003075
                        ],
                        [
                            -96.5125210000438,
                            43.0410800000573
                        ],
                        [
                            -96.5148119995641,
                            43.0451739998935
                        ],
                        [
                            -96.5105230004265,
                            43.0499130001645
                        ],
                        [
                            -96.5018039998896,
                            43.0486580002911
                        ],
                        [
                            -96.4909300002749,
                            43.0508119996831
                        ],
                        [
                            -96.4869669997539,
                            43.0557790001431
                        ],
                        [
                            -96.4767400000445,
                            43.0626970001375
                        ],
                        [
                            -96.4727530001324,
                            43.063710999719
                        ],
                        [
                            -96.4674420002726,
                            43.0619470003018
                        ],
                        [
                            -96.4607420001677,
                            43.0643370001541
                        ],
                        [
                            -96.4605499996487,
                            43.0663240002755
                        ],
                        [
                            -96.4582330001892,
                            43.0675610002426
                        ],
                        [
                            -96.4593870002577,
                            43.0698429999899
                        ],
                        [
                            -96.4555550001564,
                            43.0743190001242
                        ],
                        [
                            -96.4557979998306,
                            43.0808450001494
                        ],
                        [
                            -96.4540799996975,
                            43.0838449997443
                        ],
                        [
                            -96.4554670002928,
                            43.088283000067
                        ],
                        [
                            -96.4630829999166,
                            43.089966999869
                        ],
                        [
                            -96.4623199996604,
                            43.092884999868
                        ],
                        [
                            -96.4602419996771,
                            43.0952840001358
                        ],
                        [
                            -96.4568459995939,
                            43.0959149998775
                        ],
                        [
                            -96.4570230000442,
                            43.0976470002183
                        ],
                        [
                            -96.4523800000061,
                            43.0981929999088
                        ],
                        [
                            -96.452672999909,
                            43.1005750002932
                        ],
                        [
                            -96.4504030003052,
                            43.1008499996866
                        ],
                        [
                            -96.4505360003746,
                            43.1037750000933
                        ],
                        [
                            -96.4469920001533,
                            43.1055139997434
                        ],
                        [
                            -96.4462680002552,
                            43.1097029997777
                        ],
                        [
                            -96.4398319997408,
                            43.1134229998426
                        ],
                        [
                            -96.4364730003658,
                            43.1202019997762
                        ],
                        [
                            -96.4415809998361,
                            43.1244960000196
                        ],
                        [
                            -96.440190999766,
                            43.1283170001605
                        ],
                        [
                            -96.4427600000189,
                            43.1286150002291
                        ],
                        [
                            -96.4431340001127,
                            43.1336360002512
                        ],
                        [
                            -96.4455989997103,
                            43.1365129997475
                        ],
                        [
                            -96.4485870002628,
                            43.136227999911
                        ],
                        [
                            -96.4470800000169,
                            43.1383380003233
                        ],
                        [
                            -96.4504300000694,
                            43.1423899998463
                        ],
                        [
                            -96.455754000348,
                            43.1442350001701
                        ],
                        [
                            -96.4604299999981,
                            43.1436300000891
                        ],
                        [
                            -96.4592480003405,
                            43.1474880000295
                        ],
                        [
                            -96.4621720004207,
                            43.149180000178
                        ],
                        [
                            -96.4651440001814,
                            43.1474750000307
                        ],
                        [
                            -96.4684990002567,
                            43.1518870002343
                        ],
                        [
                            -96.4649809999748,
                            43.1527259998689
                        ],
                        [
                            -96.463980999892,
                            43.1554889999896
                        ],
                        [
                            -96.4695560002408,
                            43.1583529996979
                        ],
                        [
                            -96.4659550001182,
                            43.1614219997916
                        ],
                        [
                            -96.4675440003799,
                            43.1637340002295
                        ],
                        [
                            -96.466986999988,
                            43.1683540001463
                        ],
                        [
                            -96.4691300002685,
                            43.1694209999217
                        ],
                        [
                            -96.4655700001537,
                            43.1713150000503
                        ],
                        [
                            -96.466870999637,
                            43.1742189998544
                        ],
                        [
                            -96.4648560003013,
                            43.1819600001656
                        ],
                        [
                            -96.4673069996551,
                            43.1847800000732
                        ],
                        [
                            -96.4725249996285,
                            43.1856009999619
                        ],
                        [
                            -96.4740090003082,
                            43.1896390002592
                        ],
                        [
                            -96.4721060002272,
                            43.1963420000806
                        ],
                        [
                            -96.4750069998428,
                            43.2025690000184
                        ],
                        [
                            -96.4707720002336,
                            43.205111999938
                        ],
                        [
                            -96.4706600000806,
                            43.2073259997194
                        ],
                        [
                            -96.4764689998828,
                            43.2129689999843
                        ],
                        [
                            -96.4749599999871,
                            43.2173959998896
                        ],
                        [
                            -96.4766539998308,
                            43.221941000254
                        ],
                        [
                            -96.4856529998519,
                            43.2242219998182
                        ],
                        [
                            -96.4966389996196,
                            43.2237159999876
                        ],
                        [
                            -96.5011979999922,
                            43.2205740001661
                        ],
                        [
                            -96.5085509998502,
                            43.221117000162
                        ],
                        [
                            -96.5090599996632,
                            43.2171600001947
                        ],
                        [
                            -96.5133090004144,
                            43.218773000188
                        ],
                        [
                            -96.5206920004097,
                            43.2179829997769
                        ],
                        [
                            -96.5216559996091,
                            43.2205610000104
                        ],
                        [
                            -96.5265239997781,
                            43.2240520002841
                        ],
                        [
                            -96.5359150003347,
                            43.2276340000683
                        ],
                        [
                            -96.5402460002033,
                            43.2256319998606
                        ],
                        [
                            -96.5552809998074,
                            43.2266659998485
                        ],
                        [
                            -96.5593260003441,
                            43.2229339999626
                        ],
                        [
                            -96.5652899999569,
                            43.2303700000859
                        ],
                        [
                            -96.5682890003802,
                            43.2313440002565
                        ],
                        [
                            -96.5690230003241,
                            43.2371890003105
                        ],
                        [
                            -96.5714860002718,
                            43.2388470001628
                        ],
                        [
                            -96.5651910002227,
                            43.244453999938
                        ],
                        [
                            -96.5611459996861,
                            43.2437790002943
                        ],
                        [
                            -96.5531030002651,
                            43.2475079996817
                        ],
                        [
                            -96.5491869995998,
                            43.2468139997555
                        ],
                        [
                            -96.5526889999883,
                            43.2520120002692
                        ],
                        [
                            -96.552659999676,
                            43.2581480000336
                        ],
                        [
                            -96.5546640000393,
                            43.2599170001618
                        ],
                        [
                            -96.5585560004151,
                            43.2589439997314
                        ],
                        [
                            -96.5648229999767,
                            43.2602270000019
                        ],
                        [
                            -96.5759469998367,
                            43.2678230003099
                        ],
                        [
                            -96.5835690002066,
                            43.2676620000876
                        ],
                        [
                            -96.5856599997103,
                            43.2693519997662
                        ],
                        [
                            -96.5861490003301,
                            43.2744799997821
                        ],
                        [
                            -96.5837650000252,
                            43.2766599996872
                        ],
                        [
                            -96.5831110004472,
                            43.2745490000488
                        ],
                        [
                            -96.5811959997723,
                            43.2746689998145
                        ],
                        [
                            -96.5775600003878,
                            43.2792120001072
                        ],
                        [
                            -96.5814159998803,
                            43.2879580003141
                        ],
                        [
                            -96.5791760004137,
                            43.2924729997866
                        ],
                        [
                            -96.5794729996163,
                            43.2956889998249
                        ],
                        [
                            -96.5880330001446,
                            43.2923790002302
                        ],
                        [
                            -96.5884919997289,
                            43.2961750000477
                        ],
                        [
                            -96.5737059996469,
                            43.2990260001639
                        ],
                        [
                            -96.5693189997018,
                            43.2955389999242
                        ],
                        [
                            -96.5664749999876,
                            43.296047999837
                        ],
                        [
                            -96.5643800002859,
                            43.294638999942
                        ],
                        [
                            -96.5547119997199,
                            43.2965090001954
                        ],
                        [
                            -96.5549379996757,
                            43.2940250002978
                        ],
                        [
                            -96.5520560003267,
                            43.2928500002131
                        ],
                        [
                            -96.5408150002908,
                            43.295532999842
                        ],
                        [
                            -96.5387640000718,
                            43.2985439997021
                        ],
                        [
                            -96.5303809999938,
                            43.2998230003211
                        ],
                        [
                            -96.5319049999581,
                            43.3042329997833
                        ],
                        [
                            -96.5269620003444,
                            43.3052290000884
                        ],
                        [
                            -96.5291540001304,
                            43.309078000167
                        ],
                        [
                            -96.5240590001806,
                            43.3113809997188
                        ],
                        [
                            -96.5281139998647,
                            43.3136309999566
                        ],
                        [
                            -96.5290110000152,
                            43.3163950000874
                        ],
                        [
                            -96.5265189997552,
                            43.3158629997927
                        ],
                        [
                            -96.5256390002215,
                            43.3186330001245
                        ],
                        [
                            -96.5309619997768,
                            43.3206690001164
                        ],
                        [
                            -96.5288479998086,
                            43.3243370002733
                        ],
                        [
                            -96.533188999723,
                            43.3287140001921
                        ],
                        [
                            -96.5314580000694,
                            43.3318829999597
                        ],
                        [
                            -96.5350869997811,
                            43.3365019998664
                        ],
                        [
                            -96.5316649997586,
                            43.3393350002627
                        ],
                        [
                            -96.5327589995527,
                            43.3427440001234
                        ],
                        [
                            -96.5284439995776,
                            43.3422459997731
                        ],
                        [
                            -96.5242790002886,
                            43.3474220000533
                        ],
                        [
                            -96.5277160003798,
                            43.3482950002992
                        ],
                        [
                            -96.5251139996166,
                            43.3535789998589
                        ],
                        [
                            -96.5277480001668,
                            43.3563250002508
                        ],
                        [
                            -96.5272610000951,
                            43.3615590002619
                        ],
                        [
                            -96.5294599995538,
                            43.3626360002185
                        ],
                        [
                            -96.5309609999519,
                            43.368432999875
                        ],
                        [
                            -96.5289109995578,
                            43.3693169997555
                        ],
                        [
                            -96.5267179999469,
                            43.3681470000939
                        ],
                        [
                            -96.5247620001624,
                            43.3698589997156
                        ],
                        [
                            -96.5249820002704,
                            43.3725800002446
                        ],
                        [
                            -96.5220590000152,
                            43.3721899996856
                        ],
                        [
                            -96.5213579996832,
                            43.3758739997772
                        ],
                        [
                            -96.52451699994,
                            43.379776000198
                        ],
                        [
                            -96.5219829998472,
                            43.3813799996884
                        ],
                        [
                            -96.5246059996285,
                            43.3822709997609
                        ],
                        [
                            -96.5216620003552,
                            43.3859049997094
                        ],
                        [
                            -96.5252419996631,
                            43.3960720001133
                        ],
                        [
                            -96.5295829995775,
                            43.3976239997493
                        ],
                        [
                            -96.5306399995616,
                            43.3955440001115
                        ],
                        [
                            -96.5355189996013,
                            43.3948360003063
                        ],
                        [
                            -96.5409099998271,
                            43.3974259998109
                        ],
                        [
                            -96.5444289999339,
                            43.3961890002179
                        ],
                        [
                            -96.5455999997209,
                            43.3972220000481
                        ],
                        [
                            -96.5448809998455,
                            43.4000070002412
                        ],
                        [
                            -96.5484080003484,
                            43.4036619998259
                        ],
                        [
                            -96.5572340001171,
                            43.4061660001183
                        ],
                        [
                            -96.5604390004046,
                            43.4124619999716
                        ],
                        [
                            -96.5646370002039,
                            43.4130939999387
                        ],
                        [
                            -96.568258000418,
                            43.4169030000954
                        ],
                        [
                            -96.5735550000341,
                            43.4189690000368
                        ],
                        [
                            -96.5699999999423,
                            43.4256330000494
                        ],
                        [
                            -96.5702889996472,
                            43.4285469997287
                        ],
                        [
                            -96.5761030003706,
                            43.4318740001041
                        ],
                        [
                            -96.5880470003883,
                            43.4314650002702
                        ],
                        [
                            -96.5941409996975,
                            43.4338239997117
                        ],
                        [
                            -96.5936610001968,
                            43.4369669999451
                        ],
                        [
                            -96.5974990001459,
                            43.4400399999734
                        ],
                        [
                            -96.5965020004363,
                            43.4422749997319
                        ],
                        [
                            -96.5994430002349,
                            43.442527000314
                        ],
                        [
                            -96.5990719996158,
                            43.4452729998937
                        ],
                        [
                            -96.6030510000302,
                            43.4508279999791
                        ],
                        [
                            -96.6000170003452,
                            43.4505769998079
                        ],
                        [
                            -96.6009489997575,
                            43.4559429996764
                        ],
                        [
                            -96.5938529998175,
                            43.4588519998197
                        ],
                        [
                            -96.5946529998836,
                            43.4622299997551
                        ],
                        [
                            -96.5905510003439,
                            43.4629029998089
                        ],
                        [
                            -96.5894359997351,
                            43.4664560002488
                        ],
                        [
                            -96.5870079999475,
                            43.4645020000871
                        ],
                        [
                            -96.5824190003361,
                            43.4667060002331
                        ],
                        [
                            -96.5852920003626,
                            43.4709739999264
                        ],
                        [
                            -96.5837409997357,
                            43.4751059997243
                        ],
                        [
                            -96.5868919995966,
                            43.4778729999074
                        ],
                        [
                            -96.5810819999695,
                            43.4814979998294
                        ],
                        [
                            -96.5824219998108,
                            43.4834179998306
                        ],
                        [
                            -96.5867870000146,
                            43.4838330000907
                        ],
                        [
                            -96.5870020000997,
                            43.4875790001502
                        ],
                        [
                            -96.5845509998476,
                            43.4890990002873
                        ],
                        [
                            -96.5869150000611,
                            43.4915140003019
                        ],
                        [
                            -96.5901560003336,
                            43.4919750000524
                        ],
                        [
                            -96.5902109996869,
                            43.4941229999317
                        ],
                        [
                            -96.5947969998237,
                            43.4931139999337
                        ],
                        [
                            -96.5990130000646,
                            43.4953480000079
                        ],
                        [
                            -96.5991910003398,
                            43.5004560002029
                        ],
                        [
                            -94.765747000142,
                            43.501052000133
                        ],
                        [
                            -93.2669870003336,
                            43.4993610001307
                        ],
                        [
                            -91.2177060004444,
                            43.5005500000797
                        ],
                        [
                            -91.2176150002077,
                            43.4910080002515
                        ],
                        [
                            -91.2152819999564,
                            43.484797999741
                        ],
                        [
                            -91.2160350001669,
                            43.4811419998452
                        ],
                        [
                            -91.2203989996475,
                            43.4713059998989
                        ],
                        [
                            -91.2245859995761,
                            43.4655250002099
                        ],
                        [
                            -91.2322409995579,
                            43.4600180001267
                        ],
                        [
                            -91.2333670000373,
                            43.4551679998861
                        ],
                        [
                            -91.2287499999384,
                            43.4455370002522
                        ],
                        [
                            -91.2071449999257,
                            43.4250310002074
                        ],
                        [
                            -91.200358999607,
                            43.4127009998384
                        ],
                        [
                            -91.1976699997036,
                            43.3953339998705
                        ],
                        [
                            -91.1989529996435,
                            43.3898350002184
                        ],
                        [
                            -91.2060720000481,
                            43.3749759999641
                        ],
                        [
                            -91.2149899998783,
                            43.3680060002787
                        ],
                        [
                            -91.2066200002192,
                            43.3525239997483
                        ],
                        [
                            -91.2018469995864,
                            43.3491029996736
                        ],
                        [
                            -91.1811150003331,
                            43.3459260002849
                        ],
                        [
                            -91.1548060001829,
                            43.3348259999954
                        ],
                        [
                            -91.1328129998327,
                            43.3280300002244
                        ],
                        [
                            -91.1072369998015,
                            43.3136449999018
                        ],
                        [
                            -91.0856519998803,
                            43.2918699996848
                        ],
                        [
                            -91.0737100004108,
                            43.2747460000955
                        ],
                        [
                            -91.0717239995908,
                            43.2713919997991
                        ],
                        [
                            -91.0726490002288,
                            43.2621290000198
                        ],
                        [
                            -91.0699369998607,
                            43.2602720002273
                        ],
                        [
                            -91.0597500003342,
                            43.2590739997603
                        ],
                        [
                            -91.0579180003983,
                            43.2553660000226
                        ],
                        [
                            -91.059684000212,
                            43.248566000069
                        ],
                        [
                            -91.0663979996624,
                            43.2392930000581
                        ],
                        [
                            -91.0874560002272,
                            43.2218909999556
                        ],
                        [
                            -91.1221700001967,
                            43.197255000236
                        ],
                        [
                            -91.1244280001049,
                            43.1878860002178
                        ],
                        [
                            -91.1386490002974,
                            43.1699930001915
                        ],
                        [
                            -91.1432830001147,
                            43.1564129998076
                        ],
                        [
                            -91.1461999996239,
                            43.1524049996772
                        ],
                        [
                            -91.1561999995526,
                            43.1429450002945
                        ],
                        [
                            -91.1752529999291,
                            43.1346649999959
                        ],
                        [
                            -91.1782509996291,
                            43.1249819999003
                        ],
                        [
                            -91.1751929996547,
                            43.1037709999086
                        ],
                        [
                            -91.1772639999652,
                            43.0729829997536
                        ],
                        [
                            -91.1794569995761,
                            43.06742700031
                        ],
                        [
                            -91.1778940001521,
                            43.0642059996809
                        ],
                        [
                            -91.1746920002376,
                            43.0387130001764
                        ],
                        [
                            -91.1574900000635,
                            42.9914749998379
                        ],
                        [
                            -91.1565619999509,
                            42.9782260003007
                        ],
                        [
                            -91.1480009995976,
                            42.9661550002244
                        ],
                        [
                            -91.1459350002083,
                            42.9607699998851
                        ],
                        [
                            -91.1455400001981,
                            42.9565100000035
                        ],
                        [
                            -91.1498800002875,
                            42.9419549999189
                        ],
                        [
                            -91.1438000003237,
                            42.9228770002958
                        ],
                        [
                            -91.1458680002612,
                            42.9149670003075
                        ],
                        [
                            -91.1456149996429,
                            42.9080060001371
                        ],
                        [
                            -91.1434909996289,
                            42.9046979997145
                        ],
                        [
                            -91.1174109998075,
                            42.8958370002869
                        ],
                        [
                            -91.100565000184,
                            42.8830780002169
                        ],
                        [
                            -91.0982379997804,
                            42.8757979998261
                        ],
                        [
                            -91.0976560001725,
                            42.8598710001365
                        ],
                        [
                            -91.0914020001313,
                            42.8498599999896
                        ],
                        [
                            -91.0951139996837,
                            42.8349660003135
                        ],
                        [
                            -91.0940600000727,
                            42.8308129998058
                        ],
                        [
                            -91.0901359999098,
                            42.8292370000261
                        ],
                        [
                            -91.082769999633,
                            42.829976999752
                        ],
                        [
                            -91.0786649997202,
                            42.8276779999353
                        ],
                        [
                            -91.0780970003559,
                            42.8065259999684
                        ],
                        [
                            -91.0708299998133,
                            42.7822250000685
                        ],
                        [
                            -91.0695490004215,
                            42.769628000113
                        ],
                        [
                            -91.0602609997971,
                            42.7618469996807
                        ],
                        [
                            -91.061431999584,
                            42.757974000014
                        ],
                        [
                            -91.0654920001893,
                            42.7570810001507
                        ],
                        [
                            -91.0650589996459,
                            42.7513380001675
                        ],
                        [
                            -91.0601720001086,
                            42.7504809999673
                        ],
                        [
                            -91.0562970003496,
                            42.747340999843
                        ],
                        [
                            -91.0537330001196,
                            42.7382380003222
                        ],
                        [
                            -91.0499720001633,
                            42.7369050002706
                        ],
                        [
                            -91.0441390000717,
                            42.7386050002548
                        ],
                        [
                            -91.0354179998849,
                            42.7373399999279
                        ],
                        [
                            -91.0320129995808,
                            42.7344840002126
                        ],
                        [
                            -91.0296919999234,
                            42.7267740001466
                        ],
                        [
                            -91.0267860002849,
                            42.7242279998871
                        ],
                        [
                            -91.0172390000927,
                            42.7195660002736
                        ],
                        [
                            -91.0095770004382,
                            42.7201229998299
                        ],
                        [
                            -91.0001280001553,
                            42.716188999763
                        ],
                        [
                            -90.9887759997913,
                            42.7087240001163
                        ],
                        [
                            -90.977734999772,
                            42.6968159999695
                        ],
                        [
                            -90.9650479995896,
                            42.6932329997361
                        ],
                        [
                            -90.9492100004446,
                            42.6855690003211
                        ],
                        [
                            -90.9370449995957,
                            42.6833989998717
                        ],
                        [
                            -90.9211549996739,
                            42.6854060001879
                        ],
                        [
                            -90.900261000039,
                            42.6762540000109
                        ],
                        [
                            -90.8874299999165,
                            42.6724700003124
                        ],
                        [
                            -90.8439100002697,
                            42.6630709998636
                        ],
                        [
                            -90.7694950003361,
                            42.6514429997789
                        ],
                        [
                            -90.7569460002459,
                            42.6477560001895
                        ],
                        [
                            -90.731131999665,
                            42.6434369997376
                        ],
                        [
                            -90.7178210000419,
                            42.6399140001719
                        ],
                        [
                            -90.7063029999966,
                            42.6341689998646
                        ],
                        [
                            -90.7026709999118,
                            42.630756000099
                        ],
                        [
                            -90.7000949999862,
                            42.6224610001319
                        ],
                        [
                            -90.6920309997504,
                            42.6103659997451
                        ],
                        [
                            -90.6869750001587,
                            42.5917740001689
                        ],
                        [
                            -90.6779350001297,
                            42.5800310002963
                        ],
                        [
                            -90.6615270001741,
                            42.5679989998069
                        ],
                        [
                            -90.6591269999756,
                            42.5578999998577
                        ],
                        [
                            -90.654126999562,
                            42.5498999999572
                        ],
                        [
                            -90.6446870003983,
                            42.5413169999123
                        ],
                        [
                            -90.6413730003308,
                            42.5285749997643
                        ],
                        [
                            -90.6359829999299,
                            42.518712000264
                        ],
                        [
                            -90.6359889997777,
                            42.515409000118
                        ],
                        [
                            -90.6496119995704,
                            42.500906000154
                        ],
                        [
                            -90.6564620003615,
                            42.490961999982
                        ],
                        [
                            -90.6563019996296,
                            42.483690999871
                        ],
                        [
                            -90.6518960003163,
                            42.4760480002446
                        ],
                        [
                            -90.6458260003981,
                            42.470929999713
                        ],
                        [
                            -90.6243279996173,
                            42.4589039997558
                        ],
                        [
                            -90.6063279999252,
                            42.4515050001924
                        ],
                        [
                            -90.5679679996272,
                            42.4403890000132
                        ],
                        [
                            -90.5604390000421,
                            42.4328969997384
                        ],
                        [
                            -90.558167999715,
                            42.4209840001165
                        ],
                        [
                            -90.5550179996791,
                            42.4161380000717
                        ],
                        [
                            -90.5068290000549,
                            42.3987919999694
                        ],
                        [
                            -90.500128000125,
                            42.3955390000893
                        ],
                        [
                            -90.4871539998875,
                            42.3851410001702
                        ],
                        [
                            -90.4772789996322,
                            42.3837939999581
                        ],
                        [
                            -90.4702730001038,
                            42.3783550002248
                        ],
                        [
                            -90.4626189999469,
                            42.3672530000237
                        ],
                        [
                            -90.4527239996001,
                            42.3593030002784
                        ],
                        [
                            -90.4438740004402,
                            42.3552180002216
                        ],
                        [
                            -90.4305460002003,
                            42.3368600000231
                        ],
                        [
                            -90.4190270003301,
                            42.3285050002622
                        ],
                        [
                            -90.4159369996703,
                            42.322699000037
                        ],
                        [
                            -90.4210469996887,
                            42.3161089996741
                        ],
                        [
                            -90.4189150001771,
                            42.3083279999038
                        ],
                        [
                            -90.424325999596,
                            42.2933259999778
                        ],
                        [
                            -90.4307350003462,
                            42.2842110001565
                        ],
                        [
                            -90.4308840003091,
                            42.2782300000141
                        ],
                        [
                            -90.4193260000808,
                            42.2544670001421
                        ],
                        [
                            -90.4006529996459,
                            42.2392930000768
                        ],
                        [
                            -90.3911080000018,
                            42.2254729999833
                        ],
                        [
                            -90.3751290003916,
                            42.2148110000805
                        ],
                        [
                            -90.3569639999448,
                            42.2054450000373
                        ],
                        [
                            -90.3282730000377,
                            42.2010469999991
                        ],
                        [
                            -90.3177740003417,
                            42.1937889999078
                        ],
                        [
                            -90.2984420003059,
                            42.1875759997689
                        ],
                        [
                            -90.2821730002674,
                            42.178846000238
                        ],
                        [
                            -90.2690800002041,
                            42.1744999998433
                        ],
                        [
                            -90.2501289999349,
                            42.1714689998495
                        ],
                        [
                            -90.2349190004286,
                            42.1654309997775
                        ],
                        [
                            -90.2094789999416,
                            42.1526800003038
                        ],
                        [
                            -90.2014039998352,
                            42.1309369997544
                        ],
                        [
                            -90.19734199958,
                            42.1281629998303
                        ],
                        [
                            -90.1904520004026,
                            42.1257789998693
                        ],
                        [
                            -90.1709699996807,
                            42.1251980002733
                        ],
                        [
                            -90.1688530002377,
                            42.123733000239
                        ],
                        [
                            -90.1628949995743,
                            42.1167180002949
                        ],
                        [
                            -90.161266000028,
                            42.1086470003162
                        ],
                        [
                            -90.1626150000901,
                            42.0909950000589
                        ],
                        [
                            -90.1683579997701,
                            42.0757790000488
                        ],
                        [
                            -90.1655550000638,
                            42.0626379998513
                        ],
                        [
                            -90.1668530000723,
                            42.0549100000957
                        ],
                        [
                            -90.1644849996609,
                            42.0421050000551
                        ],
                        [
                            -90.1509160002948,
                            42.029439999877
                        ],
                        [
                            -90.1480959999718,
                            42.0200139999144
                        ],
                        [
                            -90.1411670004363,
                            42.0089309997798
                        ],
                        [
                            -90.1406130004174,
                            41.9959990000399
                        ],
                        [
                            -90.1460330000572,
                            41.9881389998268
                        ],
                        [
                            -90.1462249996778,
                            41.9813290001004
                        ],
                        [
                            -90.1538279997811,
                            41.9741210000964
                        ],
                        [
                            -90.1641349998565,
                            41.9561779999787
                        ],
                        [
                            -90.1638469999764,
                            41.9449339999667
                        ],
                        [
                            -90.15160000001,
                            41.9310020001245
                        ],
                        [
                            -90.1535840002819,
                            41.9066139997959
                        ],
                        [
                            -90.1570189998249,
                            41.898018999752
                        ],
                        [
                            -90.1700409997431,
                            41.8764389997463
                        ],
                        [
                            -90.1732369998098,
                            41.8646099998412
                        ],
                        [
                            -90.1730090002041,
                            41.8573930002736
                        ],
                        [
                            -90.1819010000952,
                            41.8432160002538
                        ],
                        [
                            -90.1839730002306,
                            41.8330699999234
                        ],
                        [
                            -90.1818890003996,
                            41.827754999995
                        ],
                        [
                            -90.1806430002696,
                            41.8119789998453
                        ],
                        [
                            -90.1819730000652,
                            41.8070699997143
                        ],
                        [
                            -90.1879690003634,
                            41.8031629997764
                        ],
                        [
                            -90.2221610001011,
                            41.7931750000208
                        ],
                        [
                            -90.2632859995722,
                            41.7721119998235
                        ],
                        [
                            -90.2786330002441,
                            41.767358000092
                        ],
                        [
                            -90.3027820003953,
                            41.7500310001076
                        ],
                        [
                            -90.3108780004181,
                            41.7423250000995
                        ],
                        [
                            -90.3155490000454,
                            41.7344260000082
                        ],
                        [
                            -90.3176680000365,
                            41.7226889998146
                        ],
                        [
                            -90.3174210001643,
                            41.7183329997284
                        ],
                        [
                            -90.3128929997539,
                            41.7075280000899
                        ],
                        [
                            -90.3134350000772,
                            41.6980820001977
                        ],
                        [
                            -90.3173149998591,
                            41.6916699997613
                        ],
                        [
                            -90.3302220001495,
                            41.6839539996837
                        ],
                        [
                            -90.334525000429,
                            41.6795589997015
                        ],
                        [
                            -90.3367289999106,
                            41.6645319997175
                        ],
                        [
                            -90.3434519995818,
                            41.6469589997101
                        ],
                        [
                            -90.3395280003173,
                            41.5986330002881
                        ],
                        [
                            -90.3432280001741,
                            41.5878330002294
                        ],
                        [
                            -90.3641279996569,
                            41.5796330001845
                        ],
                        [
                            -90.3979300003055,
                            41.5722329999463
                        ],
                        [
                            -90.4128250001675,
                            41.5653289997992
                        ],
                        [
                            -90.4222300000695,
                            41.5542329999459
                        ],
                        [
                            -90.4327310003136,
                            41.5495330000397
                        ],
                        [
                            -90.445231,
                            41.5361330002853
                        ],
                        [
                            -90.4614320002664,
                            41.5235329997723
                        ],
                        [
                            -90.4743319999858,
                            41.519733000077
                        ],
                        [
                            -90.5006329997401,
                            41.5180330001344
                        ],
                        [
                            -90.5409349995789,
                            41.5261330002952
                        ],
                        [
                            -90.5562350003951,
                            41.5242320000256
                        ],
                        [
                            -90.5672360002315,
                            41.5175319998605
                        ],
                        [
                            -90.5910370002281,
                            41.5128320001931
                        ],
                        [
                            -90.6021369997987,
                            41.5060319997315
                        ],
                        [
                            -90.605936000288,
                            41.4942320002435
                        ],
                        [
                            -90.6185370002568,
                            41.4850320001836
                        ],
                        [
                            -90.6325380003412,
                            41.478732000008
                        ],
                        [
                            -90.6558389998474,
                            41.4621319998132
                        ],
                        [
                            -90.6764389997545,
                            41.4608319998861
                        ],
                        [
                            -90.701158999643,
                            41.4547429998195
                        ],
                        [
                            -90.7459460002345,
                            41.4497300002153
                        ],
                        [
                            -90.7734169999508,
                            41.4508820000565
                        ],
                        [
                            -90.7989310000576,
                            41.4540250002777
                        ],
                        [
                            -90.8465580001653,
                            41.4551409998269
                        ],
                        [
                            -90.860626000197,
                            41.451393000073
                        ],
                        [
                            -90.9004710001012,
                            41.4311540000177
                        ],
                        [
                            -90.9292540000698,
                            41.4215440003197
                        ],
                        [
                            -90.9497910002277,
                            41.4241629999317
                        ],
                        [
                            -90.974184999703,
                            41.4337120002729
                        ],
                        [
                            -90.9829129995624,
                            41.4341709999602
                        ],
                        [
                            -91.0058459997209,
                            41.426134999741
                        ],
                        [
                            -91.0277870001925,
                            41.4236029999651
                        ],
                        [
                            -91.0371309999951,
                            41.4200169997922
                        ],
                        [
                            -91.0458899998168,
                            41.4140850001856
                        ],
                        [
                            -91.0500669996996,
                            41.4014960001338
                        ],
                        [
                            -91.0515799997933,
                            41.3852829997294
                        ],
                        [
                            -91.066231999981,
                            41.366367000326
                        ],
                        [
                            -91.0715550004347,
                            41.3396480001834
                        ],
                        [
                            -91.0748410000148,
                            41.30557800033
                        ],
                        [
                            -91.0868799995687,
                            41.294370999811
                        ],
                        [
                            -91.092033999968,
                            41.286910999985
                        ],
                        [
                            -91.1011419997691,
                            41.2671690001306
                        ],
                        [
                            -91.1141860003268,
                            41.2500289996992
                        ],
                        [
                            -91.1136570004223,
                            41.2414010002252
                        ],
                        [
                            -91.1095819997484,
                            41.2365659999236
                        ],
                        [
                            -91.0729839999645,
                            41.2071509999498
                        ],
                        [
                            -91.055067999938,
                            41.1857890001077
                        ],
                        [
                            -91.0415570002983,
                            41.1661620002567
                        ],
                        [
                            -91.0272309996255,
                            41.1633829999561
                        ],
                        [
                            -91.0075939999912,
                            41.1661870000523
                        ],
                        [
                            -90.9949629998853,
                            41.1606300003113
                        ],
                        [
                            -90.9896620000712,
                            41.1557070001875
                        ],
                        [
                            -90.9708559995669,
                            41.1301089997637
                        ],
                        [
                            -90.9659079999302,
                            41.1195590000739
                        ],
                        [
                            -90.9572649995612,
                            41.1110669996737
                        ],
                        [
                            -90.9466250002982,
                            41.0966280002158
                        ],
                        [
                            -90.9493900003697,
                            41.0727830003
                        ],
                        [
                            -90.9455490000475,
                            41.0617300002808
                        ],
                        [
                            -90.9459990003093,
                            41.0563359997223
                        ],
                        [
                            -90.9422530004217,
                            41.0347020002981
                        ],
                        [
                            -90.9453239999166,
                            41.0192790002452
                        ],
                        [
                            -90.9459490000806,
                            41.0064950001903
                        ],
                        [
                            -90.9496339998688,
                            40.9952480000178
                        ],
                        [
                            -90.9581419996203,
                            40.9797670001989
                        ],
                        [
                            -90.9527150003078,
                            40.962086999871
                        ],
                        [
                            -90.952233000259,
                            40.9540469998092
                        ],
                        [
                            -90.959946999792,
                            40.9377360000757
                        ],
                        [
                            -90.9625600004259,
                            40.9258310001523
                        ],
                        [
                            -90.9653439998656,
                            40.921633000268
                        ],
                        [
                            -90.9854619997238,
                            40.9121410000517
                        ],
                        [
                            -90.9985000004338,
                            40.9081200002466
                        ],
                        [
                            -91.0095360004303,
                            40.9005650001802
                        ],
                        [
                            -91.0215619995654,
                            40.8840210002309
                        ],
                        [
                            -91.0274890002667,
                            40.8791729999023
                        ],
                        [
                            -91.0390969998254,
                            40.8735649998354
                        ],
                        [
                            -91.0446529999076,
                            40.8683559997115
                        ],
                        [
                            -91.056430000419,
                            40.8483870001353
                        ],
                        [
                            -91.0671590002688,
                            40.8419970002691
                        ],
                        [
                            -91.0929930000428,
                            40.8210790002962
                        ],
                        [
                            -91.0969459996197,
                            40.8114029999088
                        ],
                        [
                            -91.0976489996014,
                            40.8055750003052
                        ],
                        [
                            -91.0922559997258,
                            40.7929090002431
                        ],
                        [
                            -91.0917030004302,
                            40.7797080003254
                        ],
                        [
                            -91.098104999711,
                            40.7632329997648
                        ],
                        [
                            -91.1082000000743,
                            40.750934999969
                        ],
                        [
                            -91.1104239996474,
                            40.7455279999119
                        ],
                        [
                            -91.1157350004056,
                            40.7251679997493
                        ],
                        [
                            -91.1110949998421,
                            40.7082819996879
                        ],
                        [
                            -91.1119400001141,
                            40.6970180000096
                        ],
                        [
                            -91.1154070003425,
                            40.6918250002267
                        ],
                        [
                            -91.1208200003096,
                            40.6727769998939
                        ],
                        [
                            -91.1239279996144,
                            40.6691519996674
                        ],
                        [
                            -91.1380550000956,
                            40.6608930001284
                        ],
                        [
                            -91.1869800002118,
                            40.6372969999174
                        ],
                        [
                            -91.1979059997051,
                            40.6361070002497
                        ],
                        [
                            -91.2184370000152,
                            40.638437000196
                        ],
                        [
                            -91.2530739999918,
                            40.6379620001166
                        ],
                        [
                            -91.2649529997121,
                            40.6338930001657
                        ],
                        [
                            -91.306567999628,
                            40.6262189996837
                        ],
                        [
                            -91.3397190001734,
                            40.6134879998774
                        ],
                        [
                            -91.3487330002632,
                            40.6096949998596
                        ],
                        [
                            -91.3598730000167,
                            40.6018050000584
                        ],
                        [
                            -91.3797520003987,
                            40.5744500001306
                        ],
                        [
                            -91.4014820000848,
                            40.5594579997744
                        ],
                        [
                            -91.4052410003912,
                            40.5546410003255
                        ],
                        [
                            -91.406850999671,
                            40.5475569998156
                        ],
                        [
                            -91.4041249999575,
                            40.5391269997769
                        ],
                        [
                            -91.3880669998063,
                            40.5330690000484
                        ],
                        [
                            -91.3818570001461,
                            40.5282469997366
                        ],
                        [
                            -91.3690589996356,
                            40.5125319999497
                        ],
                        [
                            -91.3638790001953,
                            40.4980619997785
                        ],
                        [
                            -91.3639100001574,
                            40.4901220003406
                        ],
                        [
                            -91.3664629996185,
                            40.4788690001381
                        ],
                        [
                            -91.3781439998704,
                            40.4563939999653
                        ],
                        [
                            -91.3814679999836,
                            40.4460400001352
                        ],
                        [
                            -91.3809650000184,
                            40.4353949996867
                        ],
                        [
                            -91.3728260003379,
                            40.4142790001732
                        ],
                        [
                            -91.3729209998741,
                            40.3991080002648
                        ],
                        [
                            -91.3769110001592,
                            40.3907759997721
                        ],
                        [
                            -91.3916129996773,
                            40.3840380003295
                        ],
                        [
                            -91.4130110000008,
                            40.3822770001133
                        ],
                        [
                            -91.4194220004007,
                            40.3782640003266
                        ],
                        [
                            -91.4256620001982,
                            40.3824909999363
                        ],
                        [
                            -91.4412430003235,
                            40.3862550000441
                        ],
                        [
                            -91.4451680003112,
                            40.3824609997527
                        ],
                        [
                            -91.4466269999782,
                            40.3779179999121
                        ],
                        [
                            -91.45453499968,
                            40.3754400002187
                        ],
                        [
                            -91.46500900016,
                            40.3762229999797
                        ],
                        [
                            -91.465891000242,
                            40.378365000067
                        ],
                        [
                            -91.4635539997927,
                            40.3855469996863
                        ],
                        [
                            -91.4765509995965,
                            40.3820719999938
                        ],
                        [
                            -91.4831529997922,
                            40.3824919996685
                        ],
                        [
                            -91.4909120004294,
                            40.392980000091
                        ],
                        [
                            -91.4878290003406,
                            40.4038660003386
                        ],
                        [
                            -91.49809299986,
                            40.4019259999889
                        ],
                        [
                            -91.5052719996407,
                            40.4035119999479
                        ],
                        [
                            -91.5102879999478,
                            40.4072580001692
                        ],
                        [
                            -91.5223330002478,
                            40.4096479999165
                        ],
                        [
                            -91.5264249997418,
                            40.4134040001925
                        ],
                        [
                            -91.5265550003364,
                            40.4198720002216
                        ],
                        [
                            -91.5213880004167,
                            40.4264879999938
                        ],
                        [
                            -91.519133999808,
                            40.4328219996682
                        ],
                        [
                            -91.5291320000869,
                            40.4342719999476
                        ],
                        [
                            -91.5334710003515,
                            40.437714999865
                        ],
                        [
                            -91.5335550000171,
                            40.4405890001507
                        ],
                        [
                            -91.5240530000307,
                            40.4484369997833
                        ],
                        [
                            -91.5230720002146,
                            40.4522540003085
                        ],
                        [
                            -91.5250899999233,
                            40.4578450003409
                        ],
                        [
                            -91.5285999998094,
                            40.4590020000923
                        ],
                        [
                            -91.5526910002343,
                            40.4587690001496
                        ],
                        [
                            -91.5638440004066,
                            40.4609880001025
                        ],
                        [
                            -91.5747459996104,
                            40.4656640002793
                        ],
                        [
                            -91.5815279997312,
                            40.4728759999777
                        ],
                        [
                            -91.5868839997969,
                            40.4872330000601
                        ],
                        [
                            -91.5908170001805,
                            40.4922920002086
                        ],
                        [
                            -91.6128210004014,
                            40.5023769998144
                        ],
                        [
                            -91.6213530004423,
                            40.5100720001901
                        ],
                        [
                            -91.6221919999682,
                            40.5170389999025
                        ],
                        [
                            -91.6187930004102,
                            40.5262859997088
                        ],
                        [
                            -91.6180279996058,
                            40.5340299998135
                        ],
                        [
                            -91.6200710003273,
                            40.5408169997646
                        ],
                        [
                            -91.6327829997257,
                            40.5450290003243
                        ],
                        [
                            -91.6817139996897,
                            40.5530349997334
                        ],
                        [
                            -91.688700000025,
                            40.5573899999449
                        ],
                        [
                            -91.6908039999474,
                            40.5598929999247
                        ],
                        [
                            -91.6915610003559,
                            40.564867000101
                        ],
                        [
                            -91.6857230002414,
                            40.5767849998758
                        ],
                        [
                            -91.6863569997279,
                            40.5808750000442
                        ],
                        [
                            -91.6963590002047,
                            40.5881480003042
                        ],
                        [
                            -91.716769000141,
                            40.5985300000903
                        ],
                        [
                            -91.7291149998416,
                            40.6136399999538
                        ],
                        [
                            -91.9477090001069,
                            40.6054710002751
                        ],
                        [
                            -92.4852159997672,
                            40.5950850000356
                        ],
                        [
                            -92.6866930002473,
                            40.5898090000977
                        ],
                        [
                            -92.9434720000101,
                            40.5877619999698
                        ],
                        [
                            -93.2604289996205,
                            40.5808139998214
                        ],
                        [
                            -93.5475779999637,
                            40.5804070000321
                        ],
                        [
                            -94.2704560002722,
                            40.5715309998938
                        ],
                        [
                            -94.5941959998283,
                            40.5709599997203
                        ],
                        [
                            -95.7656450000952,
                            40.585208000144
                        ],
                        [
                            -95.7531479998836,
                            40.5928399999168
                        ],
                        [
                            -95.7502740000321,
                            40.5963169998598
                        ],
                        [
                            -95.7486260002192,
                            40.603354999833
                        ],
                        [
                            -95.7512709997418,
                            40.6090569998054
                        ],
                        [
                            -95.7689259996524,
                            40.6212640003269
                        ],
                        [
                            -95.7713250000259,
                            40.6393929997561
                        ],
                        [
                            -95.7762509999213,
                            40.6474630001064
                        ],
                        [
                            -95.7865680000425,
                            40.6572529998843
                        ],
                        [
                            -95.7954890002458,
                            40.6623839998727
                        ],
                        [
                            -95.8229130001064,
                            40.6672400000016
                        ],
                        [
                            -95.8428009998109,
                            40.6774960002524
                        ],
                        [
                            -95.8460339996875,
                            40.6826050001492
                        ],
                        [
                            -95.8479309999208,
                            40.6941969999501
                        ],
                        [
                            -95.8526149999668,
                            40.7022619998408
                        ],
                        [
                            -95.859377999821,
                            40.7080550002633
                        ],
                        [
                            -95.8831779999927,
                            40.7175789997292
                        ],
                        [
                            -95.8889070003273,
                            40.73185500016
                        ],
                        [
                            -95.8866900004269,
                            40.7421010000908
                        ],
                        [
                            -95.8790270000492,
                            40.7530810002722
                        ],
                        [
                            -95.8699819999973,
                            40.7596450000538
                        ],
                        [
                            -95.8466200003918,
                            40.7686190002231
                        ],
                        [
                            -95.8352320000428,
                            40.7791510000364
                        ],
                        [
                            -95.8345230002132,
                            40.7877779998127
                        ],
                        [
                            -95.8437449998171,
                            40.8037829999689
                        ],
                        [
                            -95.8453419995764,
                            40.8113239998466
                        ],
                        [
                            -95.8439210004425,
                            40.8176859997058
                        ],
                        [
                            -95.8386010003618,
                            40.8261749997894
                        ],
                        [
                            -95.8371860001774,
                            40.8353470001205
                        ],
                        [
                            -95.8413089996336,
                            40.8456040003096
                        ],
                        [
                            -95.8470839999989,
                            40.8541740001745
                        ],
                        [
                            -95.8485900004199,
                            40.8610609996897
                        ],
                        [
                            -95.8469380004091,
                            40.8657450002026
                        ],
                        [
                            -95.8425210003267,
                            40.8702659997407
                        ],
                        [
                            -95.8249890004398,
                            40.8750000003129
                        ],
                        [
                            -95.815932999619,
                            40.8798459997087
                        ],
                        [
                            -95.8107089997978,
                            40.8866810002924
                        ],
                        [
                            -95.8093790000022,
                            40.8932790002491
                        ],
                        [
                            -95.8143020004228,
                            40.9029359999053
                        ],
                        [
                            -95.8349059996296,
                            40.9195739997427
                        ],
                        [
                            -95.8397429998365,
                            40.9327800000676
                        ],
                        [
                            -95.840275000114,
                            40.9399419998885
                        ],
                        [
                            -95.8379510000835,
                            40.9506180003191
                        ],
                        [
                            -95.8298290001214,
                            40.9638570001097
                        ],
                        [
                            -95.8283290004465,
                            40.9723780002015
                        ],
                        [
                            -95.8297920003115,
                            40.9773439997297
                        ],
                        [
                            -95.8335370003742,
                            40.9826599997149
                        ],
                        [
                            -95.8389079996102,
                            40.9864840000358
                        ],
                        [
                            -95.8601159999628,
                            40.9952420002537
                        ],
                        [
                            -95.866950999787,
                            41.0010849998842
                        ],
                        [
                            -95.8695019995983,
                            41.0094290000668
                        ],
                        [
                            -95.8658860003053,
                            41.0174179997407
                        ],
                        [
                            -95.8599179995961,
                            41.0254029999032
                        ],
                        [
                            -95.8596540000055,
                            41.0356949998882
                        ],
                        [
                            -95.8617820002174,
                            41.0394270002765
                        ],
                        [
                            -95.8815879999061,
                            41.0543780000658
                        ],
                        [
                            -95.8823419999415,
                            41.0594189997622
                        ],
                        [
                            -95.881011000321,
                            41.0663030000017
                        ],
                        [
                            -95.8658350002517,
                            41.0800789998758
                        ],
                        [
                            -95.8624269995746,
                            41.0896869997548
                        ],
                        [
                            -95.8666610002571,
                            41.1049750002166
                        ],
                        [
                            -95.8672279997965,
                            41.1214930001758
                        ],
                        [
                            -95.8812220002082,
                            41.1416650000035
                        ],
                        [
                            -95.8831289995889,
                            41.1481960000547
                        ],
                        [
                            -95.8825400003083,
                            41.1558809997589
                        ],
                        [
                            -95.8773680003656,
                            41.1639140003131
                        ],
                        [
                            -95.8711100001265,
                            41.1676349999629
                        ],
                        [
                            -95.865928999963,
                            41.1681290001738
                        ],
                        [
                            -95.853451000018,
                            41.1655179999891
                        ],
                        [
                            -95.8469569997773,
                            41.1666039998083
                        ],
                        [
                            -95.8420390002779,
                            41.171366999733
                        ],
                        [
                            -95.8421880002408,
                            41.1774210002322
                        ],
                        [
                            -95.845432999813,
                            41.1819300000643
                        ],
                        [
                            -95.850373999777,
                            41.1850899999522
                        ],
                        [
                            -95.8637969995531,
                            41.1882450002408
                        ],
                        [
                            -95.8781330002717,
                            41.1879410002455
                        ],
                        [
                            -95.9005099998631,
                            41.184608999913
                        ],
                        [
                            -95.9161189995775,
                            41.1852790003008
                        ],
                        [
                            -95.9232050003702,
                            41.190282000065
                        ],
                        [
                            -95.9279749997316,
                            41.2000690001109
                        ],
                        [
                            -95.9261169998564,
                            41.2116190002126
                        ],
                        [
                            -95.914271999573,
                            41.2238579998771
                        ],
                        [
                            -95.9106289996175,
                            41.2345789998983
                        ],
                        [
                            -95.9118599996789,
                            41.24102300025
                        ],
                        [
                            -95.9190929998864,
                            41.2526979996774
                        ],
                        [
                            -95.9223040000217,
                            41.2630199998044
                        ],
                        [
                            -95.9214970002828,
                            41.2676039997512
                        ],
                        [
                            -95.914090999823,
                            41.2724279998961
                        ],
                        [
                            -95.9286459999263,
                            41.2813320001801
                        ],
                        [
                            -95.9296510000319,
                            41.2922620002166
                        ],
                        [
                            -95.9276419996457,
                            41.2984399999916
                        ],
                        [
                            -95.9202469999548,
                            41.301191000198
                        ],
                        [
                            -95.9058990004391,
                            41.3010379998699
                        ],
                        [
                            -95.9042220003138,
                            41.2975909999629
                        ],
                        [
                            -95.9061439997631,
                            41.2874879999021
                        ],
                        [
                            -95.9125810001024,
                            41.2795270000859
                        ],
                        [
                            -95.9028399997414,
                            41.2743739996944
                        ],
                        [
                            -95.8844950002678,
                            41.2806890001175
                        ],
                        [
                            -95.8760510000905,
                            41.2857390002985
                        ],
                        [
                            -95.8714420003875,
                            41.2934080001281
                        ],
                        [
                            -95.8722690002179,
                            41.3030699999188
                        ],
                        [
                            -95.8782920002803,
                            41.3130809998645
                        ],
                        [
                            -95.8892510002839,
                            41.319473999879
                        ],
                        [
                            -95.8999430003238,
                            41.3214809996938
                        ],
                        [
                            -95.9186510000205,
                            41.3200789997458
                        ],
                        [
                            -95.9293830002433,
                            41.3231300001875
                        ],
                        [
                            -95.9465319998156,
                            41.3331390002747
                        ],
                        [
                            -95.9534179996934,
                            41.3394700000763
                        ],
                        [
                            -95.9571369998168,
                            41.345902000008
                        ],
                        [
                            -95.9571300001441,
                            41.3495930001743
                        ],
                        [
                            -95.953953000344,
                            41.353775999963
                        ],
                        [
                            -95.9369229996991,
                            41.3592469997555
                        ],
                        [
                            -95.9296810001692,
                            41.3670579997154
                        ],
                        [
                            -95.9294860001755,
                            41.3753260000066
                        ],
                        [
                            -95.9364110004113,
                            41.3858760000125
                        ],
                        [
                            -95.9376940003513,
                            41.3947700001339
                        ],
                        [
                            -95.9296769999712,
                            41.4110710000678
                        ],
                        [
                            -95.9327779996033,
                            41.4308149997733
                        ],
                        [
                            -95.9208570000502,
                            41.4434280000008
                        ],
                        [
                            -95.919989000212,
                            41.4526039998113
                        ],
                        [
                            -95.9245100000514,
                            41.4589140000178
                        ],
                        [
                            -95.9363400002662,
                            41.465303999854
                        ],
                        [
                            -95.9450070000263,
                            41.4664850002663
                        ],
                        [
                            -95.9583720000762,
                            41.4632180002901
                        ],
                        [
                            -95.966688000207,
                            41.4634250002271
                        ],
                        [
                            -95.9842789996451,
                            41.4709390000534
                        ],
                        [
                            -96.004936000352,
                            41.4724130000326
                        ],
                        [
                            -96.0135849996704,
                            41.4784689996936
                        ],
                        [
                            -96.0185630003426,
                            41.4849909997844
                        ],
                        [
                            -96.0195430003339,
                            41.4915919997261
                        ],
                        [
                            -96.000648999966,
                            41.5025400002753
                        ],
                        [
                            -95.9961940002488,
                            41.5069599996897
                        ],
                        [
                            -95.9927770002492,
                            41.5145959998739
                        ],
                        [
                            -95.9943079998862,
                            41.5237430003302
                        ],
                        [
                            -95.9999660000757,
                            41.5394799999729
                        ],
                        [
                            -96.0051120000791,
                            41.5432500001707
                        ],
                        [
                            -96.0191499999735,
                            41.5451400003222
                        ],
                        [
                            -96.0266569998173,
                            41.5403660003182
                        ],
                        [
                            -96.0311269996031,
                            41.5230039997211
                        ],
                        [
                            -96.0344409996705,
                            41.5210509998656
                        ],
                        [
                            -96.034352999807,
                            41.5127609996862
                        ],
                        [
                            -96.0366030002177,
                            41.5090469997603
                        ],
                        [
                            -96.0406129996959,
                            41.5068930001759
                        ],
                        [
                            -96.0483110002338,
                            41.5072619998406
                        ],
                        [
                            -96.0550480001487,
                            41.5095079997357
                        ],
                        [
                            -96.0726510001807,
                            41.5230880003092
                        ],
                        [
                            -96.0840310001337,
                            41.5268750002472
                        ],
                        [
                            -96.0919079998734,
                            41.5340489998544
                        ],
                        [
                            -96.0965860000717,
                            41.5453969996675
                        ],
                        [
                            -96.0963130002602,
                            41.5509869998408
                        ],
                        [
                            -96.0922219996928,
                            41.5608859997755
                        ],
                        [
                            -96.082486000253,
                            41.5711449997857
                        ],
                        [
                            -96.0811880002445,
                            41.5742960000065
                        ],
                        [
                            -96.0822850004115,
                            41.5806829998594
                        ],
                        [
                            -96.0871860001926,
                            41.5865500000576
                        ],
                        [
                            -96.1013310002171,
                            41.5914110002651
                        ],
                        [
                            -96.109454999829,
                            41.5967750001057
                        ],
                        [
                            -96.1158109999773,
                            41.6056589999898
                        ],
                        [
                            -96.1182329999171,
                            41.6132909999381
                        ],
                        [
                            -96.1159950001004,
                            41.6220239997737
                        ],
                        [
                            -96.0977769999501,
                            41.6396250002428
                        ],
                        [
                            -96.0951309997043,
                            41.6477670002936
                        ],
                        [
                            -96.0961159997184,
                            41.6544720002631
                        ],
                        [
                            -96.1000549999498,
                            41.6610769997928
                        ],
                        [
                            -96.1111560002436,
                            41.6675469996932
                        ],
                        [
                            -96.1193469998027,
                            41.6747220002466
                        ],
                        [
                            -96.1226039999687,
                            41.6830399998578
                        ],
                        [
                            -96.1198609996386,
                            41.6926490003064
                        ],
                        [
                            -96.1134610000076,
                            41.6975690000997
                        ],
                        [
                            -96.1038140002562,
                            41.6994030001449
                        ],
                        [
                            -96.0846400004042,
                            41.6977089998682
                        ],
                        [
                            -96.0773310000288,
                            41.7002320000316
                        ],
                        [
                            -96.073225000291,
                            41.7040620000148
                        ],
                        [
                            -96.0733609998352,
                            41.7101989997327
                        ],
                        [
                            -96.0766420002907,
                            41.715656999796
                        ],
                        [
                            -96.0852720002409,
                            41.7213130002973
                        ],
                        [
                            -96.1022119995758,
                            41.7276410002397
                        ],
                        [
                            -96.1053289999998,
                            41.731504999755
                        ],
                        [
                            -96.1063540001968,
                            41.7380459997546
                        ],
                        [
                            -96.1048050001181,
                            41.7430160001662
                        ],
                        [
                            -96.1009810004127,
                            41.7475720003062
                        ],
                        [
                            -96.0873610000947,
                            41.7521759999211
                        ],
                        [
                            -96.0812109998107,
                            41.7570809999199
                        ],
                        [
                            -96.0784969997928,
                            41.762631999762
                        ],
                        [
                            -96.0790209996745,
                            41.7723500000172
                        ],
                        [
                            -96.0775460001139,
                            41.7778220002328
                        ],
                        [
                            -96.0663770000481,
                            41.7889810001289
                        ],
                        [
                            -96.0647900003345,
                            41.7944060000806
                        ],
                        [
                            -96.0675600004289,
                            41.80099299981
                        ],
                        [
                            -96.0753529996047,
                            41.807715999738
                        ],
                        [
                            -96.0974799998492,
                            41.8139169999197
                        ],
                        [
                            -96.106565000084,
                            41.8196130002283
                        ],
                        [
                            -96.1093839996838,
                            41.823134999921
                        ],
                        [
                            -96.1109620000749,
                            41.8288270001732
                        ],
                        [
                            -96.1076170000453,
                            41.8404000002327
                        ],
                        [
                            -96.1105479997981,
                            41.8491989997063
                        ],
                        [
                            -96.1165969997998,
                            41.8551030001891
                        ],
                        [
                            -96.134959999715,
                            41.8632520000525
                        ],
                        [
                            -96.1419080004154,
                            41.8689989998168
                        ],
                        [
                            -96.1459739999702,
                            41.8750930002329
                        ],
                        [
                            -96.1487830004226,
                            41.8882049999081
                        ],
                        [
                            -96.1617559999369,
                            41.9018200000821
                        ],
                        [
                            -96.1619879997405,
                            41.9055530000935
                        ],
                        [
                            -96.1590979999956,
                            41.910056999831
                        ],
                        [
                            -96.1422649998925,
                            41.9153790003129
                        ],
                        [
                            -96.1367430001455,
                            41.9208260000335
                        ],
                        [
                            -96.1366130004491,
                            41.9271669997793
                        ],
                        [
                            -96.1434929995808,
                            41.9373870001408
                        ],
                        [
                            -96.1445830000752,
                            41.9415440002974
                        ],
                        [
                            -96.1425970001535,
                            41.9459079999037
                        ],
                        [
                            -96.1333179997499,
                            41.9557320002855
                        ],
                        [
                            -96.1291860000729,
                            41.9651359996746
                        ],
                        [
                            -96.1295049999152,
                            41.9716729997602
                        ],
                        [
                            -96.1325369999503,
                            41.9746250002914
                        ],
                        [
                            -96.1412289998248,
                            41.9780629996913
                        ],
                        [
                            -96.152089999919,
                            41.9796609998445
                        ],
                        [
                            -96.1633379996276,
                            41.9798390001383
                        ],
                        [
                            -96.1772039999929,
                            41.9763080002866
                        ],
                        [
                            -96.1872189999902,
                            41.9779460002116
                        ],
                        [
                            -96.1906080004008,
                            41.9807290001542
                        ],
                        [
                            -96.1921409996876,
                            41.9844609997448
                        ],
                        [
                            -96.1842549997271,
                            41.9966339999412
                        ],
                        [
                            -96.1840020000071,
                            42.0030629997442
                        ],
                        [
                            -96.1859190003319,
                            42.0059139997388
                        ],
                        [
                            -96.1945559999547,
                            42.0086620002264
                        ],
                        [
                            -96.2060830002209,
                            42.0092669999863
                        ],
                        [
                            -96.2152250003571,
                            42.0067009999354
                        ],
                        [
                            -96.2218119995859,
                            41.9973819999116
                        ],
                        [
                            -96.2254629999372,
                            41.9947339998318
                        ],
                        [
                            -96.236488000063,
                            41.9964290001557
                        ],
                        [
                            -96.2420199998558,
                            42.0009109999432
                        ],
                        [
                            -96.2419200002967,
                            42.0069630003107
                        ],
                        [
                            -96.2388599997741,
                            42.0123150002148
                        ],
                        [
                            -96.2236109999098,
                            42.0226520002152
                        ],
                        [
                            -96.2217299995701,
                            42.0262050001286
                        ],
                        [
                            -96.223821999797,
                            42.0333460002668
                        ],
                        [
                            -96.2350340004189,
                            42.040136999773
                        ],
                        [
                            -96.2269769998558,
                            42.0373530002325
                        ],
                        [
                            -96.2282609996207,
                            42.0383019996677
                        ],
                        [
                            -96.2343559996531,
                            42.0407119998731
                        ],
                        [
                            -96.2468319999484,
                            42.0416159998304
                        ],
                        [
                            -96.2580670001364,
                            42.0382740002705
                        ],
                        [
                            -96.267195000029,
                            42.041189999813
                        ],
                        [
                            -96.2725450002469,
                            42.0462019997461
                        ],
                        [
                            -96.2780429997045,
                            42.0584120001431
                        ],
                        [
                            -96.2794789998053,
                            42.0708799999502
                        ],
                        [
                            -96.2767530000919,
                            42.0816959998992
                        ],
                        [
                            -96.2677390000021,
                            42.0970549998598
                        ],
                        [
                            -96.2669880003398,
                            42.1061720003227
                        ],
                        [
                            -96.2694589997851,
                            42.1142159999094
                        ],
                        [
                            -96.274583999872,
                            42.1203369998828
                        ],
                        [
                            -96.2830020001101,
                            42.1250309998221
                        ],
                        [
                            -96.3001540000555,
                            42.1276960000342
                        ],
                        [
                            -96.3097580001491,
                            42.132101000176
                        ],
                        [
                            -96.31404600036,
                            42.1364489997464
                        ],
                        [
                            -96.3207399997189,
                            42.1479279999691
                        ],
                        [
                            -96.3415709996046,
                            42.1590799999625
                        ],
                        [
                            -96.3496880004421,
                            42.1707020000479
                        ],
                        [
                            -96.3502680004003,
                            42.1779429999533
                        ],
                        [
                            -96.3472780001979,
                            42.1857739997667
                        ],
                        [
                            -96.3473739995591,
                            42.1930750002922
                        ],
                        [
                            -96.3495670000683,
                            42.1981410002969
                        ],
                        [
                            -96.3598699999458,
                            42.2105530002826
                        ],
                        [
                            -96.3565910000384,
                            42.2151820000994
                        ],
                        [
                            -96.3369979998867,
                            42.2174659998784
                        ],
                        [
                            -96.3237230002485,
                            42.2298870000135
                        ],
                        [
                            -96.3228679999308,
                            42.2336369998548
                        ],
                        [
                            -96.3300059997037,
                            42.2402249999376
                        ],
                        [
                            -96.3277059999626,
                            42.2499919999501
                        ],
                        [
                            -96.328896999841,
                            42.2547230002543
                        ],
                        [
                            -96.3359640003671,
                            42.264776999953
                        ],
                        [
                            -96.3413870003799,
                            42.2690869997993
                        ],
                        [
                            -96.3565500000305,
                            42.2765940000533
                        ],
                        [
                            -96.365750999718,
                            42.2858139997787
                        ],
                        [
                            -96.368467000284,
                            42.292619000086
                        ],
                        [
                            -96.3692120000985,
                            42.3083439997227
                        ],
                        [
                            -96.375307000131,
                            42.3183389999628
                        ],
                        [
                            -96.3841689998848,
                            42.3258739998023
                        ],
                        [
                            -96.4079980003689,
                            42.3374080000314
                        ],
                        [
                            -96.4138950000346,
                            42.3433930000489
                        ],
                        [
                            -96.4177859996872,
                            42.351448999957
                        ],
                        [
                            -96.4170929997511,
                            42.3614429996897
                        ],
                        [
                            -96.4084360000368,
                            42.3760919999947
                        ],
                        [
                            -96.4091530002623,
                            42.3814909998653
                        ],
                        [
                            -96.4149799996078,
                            42.3934419998707
                        ],
                        [
                            -96.4151860003705,
                            42.4042030000519
                        ],
                        [
                            -96.4103070003308,
                            42.4129650002233
                        ],
                        [
                            -96.3876079996257,
                            42.4324940002994
                        ],
                        [
                            -96.3807050000295,
                            42.4463929998841
                        ],
                        [
                            -96.3813050000791,
                            42.4616949999361
                        ],
                        [
                            -96.3860030003689,
                            42.474495999753
                        ],
                        [
                            -96.3961239997731,
                            42.4843610002942
                        ],
                        [
                            -96.4019619998876,
                            42.4864399998679
                        ],
                        [
                            -96.4479880000381,
                            42.4904389999787
                        ],
                        [
                            -96.4559409999458,
                            42.4925769996698
                        ],
                        [
                            -96.463512000262,
                            42.4904639999429
                        ],
                        [
                            -96.4695729999593,
                            42.4914060000015
                        ],
                        [
                            -96.4715050003526,
                            42.4892680000273
                        ],
                        [
                            -96.4763690003237,
                            42.4900430001676
                        ],
                        [
                            -96.4779080003567,
                            42.4946679997947
                        ],
                        [
                            -96.4733339999155,
                            42.5035360000175
                        ],
                        [
                            -96.4760479999334,
                            42.5077830001758
                        ],
                        [
                            -96.4790230000671,
                            42.5108429997052
                        ],
                        [
                            -96.4881629996552,
                            42.5112839999038
                        ],
                        [
                            -96.4937220001105,
                            42.516742000158
                        ],
                        [
                            -96.4920850001682,
                            42.5196249998915
                        ],
                        [
                            -96.4799489996316,
                            42.5242460000892
                        ],
                        [
                            -96.4790070001736,
                            42.5263940003028
                        ],
                        [
                            -96.4798130000875,
                            42.5296010000517
                        ],
                        [
                            -96.4765810000358,
                            42.5464670000504
                        ],
                        [
                            -96.4769600001525,
                            42.555971999926
                        ],
                        [
                            -96.4955399998027,
                            42.5564400002511
                        ],
                        [
                            -96.4985440002488,
                            42.5581160001837
                        ],
                        [
                            -96.4987760000524,
                            42.5610430003248
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 42.0700243,
            "name": "Iowa",
            "intptlon": -93.4933473,
            "geo_point_2d": [
                42.0747320767,
                -93.5003020206
            ],
            "geoid": "19",
            "mtfcc": "G4000",
            "region": 2
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -93.5003020206,
                42.0747320767
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "dfd95b6a7d3e9d6aae2c4e0cb352bfbe5bbd3292",
        "fields": {
            "arealand": "1483671958",
            "objectid": 2,
            "basename": "Florida",
            "stusab": "FL",
            "statens": "00294478",
            "centlon": -82.4244507,
            "state": "12",
            "gid": 36,
            "centlat": 28.4604055,
            "division": 5,
            "areawater": "1322840874",
            "oid": "267296340",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                -86.3886459996374,
                                30.994180999835
                            ],
                            [
                                -85.9986109995615,
                                30.9928700002008
                            ],
                            [
                                -85.5013029998078,
                                30.9968730002466
                            ],
                            [
                                -85.2436319999173,
                                31.00088399966
                            ],
                            [
                                -85.0019000003525,
                                31.0006809998956
                            ],
                            [
                                -85.0023809996781,
                                30.9878360002195
                            ],
                            [
                                -85.0059340001202,
                                30.9798040000179
                            ],
                            [
                                -85.0057340001036,
                                30.9759080002493
                            ],
                            [
                                -84.9999279997762,
                                30.9711860000845
                            ],
                            [
                                -84.9848270000499,
                                30.9674860003144
                            ],
                            [
                                -84.981127000193,
                                30.9633859996225
                            ],
                            [
                                -84.9795270000607,
                                30.955585999865
                            ],
                            [
                                -84.983026999901,
                                30.9425859996851
                            ],
                            [
                                -84.9831270003584,
                                30.9347859999398
                            ],
                            [
                                -84.9710259999819,
                                30.9281869996492
                            ],
                            [
                                -84.9667260000755,
                                30.9172869998782
                            ],
                            [
                                -84.9561250002722,
                                30.9075869999065
                            ],
                            [
                                -84.9425250000457,
                                30.8884879997283
                            ],
                            [
                                -84.934424,
                                30.8835879998483
                            ],
                            [
                                -84.9398240004467,
                                30.8725880002787
                            ],
                            [
                                -84.9323240002755,
                                30.8642880002499
                            ],
                            [
                                -84.9364240001655,
                                30.8597879998124
                            ],
                            [
                                -84.9361239996915,
                                30.8569879996784
                            ],
                            [
                                -84.9332239999008,
                                30.85148799981
                            ],
                            [
                                -84.9278240003525,
                                30.8478890002372
                            ],
                            [
                                -84.9256650001784,
                                30.8426849999672
                            ],
                            [
                                -84.9270839996625,
                                30.8390239998433
                            ],
                            [
                                -84.9321379996044,
                                30.8382960001139
                            ],
                            [
                                -84.9348149998123,
                                30.8358509998322
                            ],
                            [
                                -84.9374240002482,
                                30.820889000166
                            ],
                            [
                                -84.9283230001198,
                                30.8050899996167
                            ],
                            [
                                -84.9283230001198,
                                30.7930900001245
                            ],
                            [
                                -84.9180229997171,
                                30.7780899998965
                            ],
                            [
                                -84.9180229997171,
                                30.7720900002152
                            ],
                            [
                                -84.9201230003399,
                                30.7659900001636
                            ],
                            [
                                -84.9150219996441,
                                30.7611909997878
                            ],
                            [
                                -84.9143220000353,
                                30.7535909996338
                            ],
                            [
                                -84.9111219997707,
                                30.7511909998934
                            ],
                            [
                                -84.9002220002167,
                                30.751890999724
                            ],
                            [
                                -84.8961220003267,
                                30.7505910000695
                            ],
                            [
                                -84.8875219996155,
                                30.7417909996424
                            ],
                            [
                                -84.8838209999337,
                                30.7325909999952
                            ],
                            [
                                -84.8754210001373,
                                30.7274909999352
                            ],
                            [
                                -84.8697520000772,
                                30.7218969999763
                            ],
                            [
                                -84.8646930001124,
                                30.7115419999388
                            ],
                            [
                                -84.6443320002085,
                                30.7019639996806
                            ],
                            [
                                -84.4744089996162,
                                30.6927930002523
                            ],
                            [
                                -84.2503570000762,
                                30.6841330000235
                            ],
                            [
                                -83.4999250002108,
                                30.6456739998242
                            ],
                            [
                                -82.5957350000345,
                                30.5925300000496
                            ],
                            [
                                -82.2146770002384,
                                30.5685560000252
                            ],
                            [
                                -82.2143969998559,
                                30.566827000074
                            ],
                            [
                                -82.216920999903,
                                30.56531599983
                            ],
                            [
                                -82.2238620000324,
                                30.5652760001063
                            ],
                            [
                                -82.2341859998262,
                                30.5576500001671
                            ],
                            [
                                -82.2344350002465,
                                30.5487549999861
                            ],
                            [
                                -82.2388790000931,
                                30.5455049998881
                            ],
                            [
                                -82.2404030000574,
                                30.5377700001006
                            ],
                            [
                                -82.2373879997407,
                                30.5322159997566
                            ],
                            [
                                -82.2307589997808,
                                30.5265760002264
                            ],
                            [
                                -82.2293009999387,
                                30.5205869999853
                            ],
                            [
                                -82.2303040003945,
                                30.5173250001789
                            ],
                            [
                                -82.2250720001774,
                                30.5076900002428
                            ],
                            [
                                -82.2126319998672,
                                30.5005489997943
                            ],
                            [
                                -82.2115780002561,
                                30.4972889996486
                            ],
                            [
                                -82.206670999729,
                                30.493205999872
                            ],
                            [
                                -82.2012400002186,
                                30.4851139997144
                            ],
                            [
                                -82.2032060000488,
                                30.4821839997281
                            ],
                            [
                                -82.2014729998471,
                                30.4800630002666
                            ],
                            [
                                -82.2010350001791,
                                30.4746649996619
                            ],
                            [
                                -82.204374000361,
                                30.4710950002278
                            ],
                            [
                                -82.2044660004225,
                                30.4687560002389
                            ],
                            [
                                -82.2063719999782,
                                30.4685149996949
                            ],
                            [
                                -82.2061510000453,
                                30.4624149998151
                            ],
                            [
                                -82.207700000124,
                                30.4604069997753
                            ],
                            [
                                -82.2073789997336,
                                30.456980999885
                            ],
                            [
                                -82.2044949998365,
                                30.4553419996428
                            ],
                            [
                                -82.2040579999935,
                                30.4528640003476
                            ],
                            [
                                -82.2057379995934,
                                30.4510479998631
                            ],
                            [
                                -82.2037860000069,
                                30.4449840002767
                            ],
                            [
                                -82.2073510001445,
                                30.4391730001038
                            ],
                            [
                                -82.2064160003592,
                                30.4357250000103
                            ],
                            [
                                -82.2097200003809,
                                30.4330550001432
                            ],
                            [
                                -82.2090049998052,
                                30.4283369996991
                            ],
                            [
                                -82.2108600002057,
                                30.4253790002332
                            ],
                            [
                                -82.209198999974,
                                30.421329000226
                            ],
                            [
                                -82.2074619995743,
                                30.4211199998234
                            ],
                            [
                                -82.2080340000348,
                                30.4174669997793
                            ],
                            [
                                -82.2064330000776,
                                30.4171560000268
                            ],
                            [
                                -82.2083830000143,
                                30.4143400003497
                            ],
                            [
                                -82.2057409999665,
                                30.4119120003474
                            ],
                            [
                                -82.2053490003293,
                                30.4081990003628
                            ],
                            [
                                -82.2067279996304,
                                30.4067750001509
                            ],
                            [
                                -82.2037600000676,
                                30.4053109996469
                            ],
                            [
                                -82.2046110001874,
                                30.4025869999402
                            ],
                            [
                                -82.2028570000693,
                                30.4012559999805
                            ],
                            [
                                -82.2014000000521,
                                30.3953500001479
                            ],
                            [
                                -82.1981079997259,
                                30.3918430000086
                            ],
                            [
                                -82.1991089996336,
                                30.3896420000844
                            ],
                            [
                                -82.1965839997616,
                                30.3882779996378
                            ],
                            [
                                -82.1946919995512,
                                30.3894800003836
                            ],
                            [
                                -82.193354000258,
                                30.3880260003436
                            ],
                            [
                                -82.1917599999735,
                                30.3846120003067
                            ],
                            [
                                -82.1934220000301,
                                30.3833449996141
                            ],
                            [
                                -82.1928229998054,
                                30.3791290000749
                            ],
                            [
                                -82.1902979999334,
                                30.3762749999762
                            ],
                            [
                                -82.1871090004377,
                                30.378983999867
                            ],
                            [
                                -82.1847949995547,
                                30.378936000173
                            ],
                            [
                                -82.1834930002465,
                                30.3733380002226
                            ],
                            [
                                -82.1802400002784,
                                30.3725799999518
                            ],
                            [
                                -82.1809909999407,
                                30.3686110000644
                            ],
                            [
                                -82.1737450002128,
                                30.3650659997244
                            ],
                            [
                                -82.1701969997936,
                                30.3589719997736
                            ],
                            [
                                -82.1674130003539,
                                30.360002000083
                            ],
                            [
                                -82.1617769999057,
                                30.3571110003485
                            ],
                            [
                                -82.1578970001238,
                                30.3601219996469
                            ],
                            [
                                -82.1500990000268,
                                30.3618299999324
                            ],
                            [
                                -82.1485490001232,
                                30.3608980001972
                            ],
                            [
                                -82.1399619998307,
                                30.3649189998599
                            ],
                            [
                                -82.1369420003895,
                                30.3642239997759
                            ],
                            [
                                -82.1370639996899,
                                30.3625160002162
                            ],
                            [
                                -82.1338640003235,
                                30.3622370002553
                            ],
                            [
                                -82.1275640002517,
                                30.3654380001027
                            ],
                            [
                                -82.1286720002894,
                                30.3670829997713
                            ],
                            [
                                -82.1268539996989,
                                30.3682620000654
                            ],
                            [
                                -82.1181490003039,
                                30.3643580000705
                            ],
                            [
                                -82.1157750000447,
                                30.3680530000204
                            ],
                            [
                                -82.111521000169,
                                30.3675569998056
                            ],
                            [
                                -82.1103319999404,
                                30.3652089999289
                            ],
                            [
                                -82.1103999997125,
                                30.3667200003745
                            ],
                            [
                                -82.1052410001886,
                                30.3688960001501
                            ],
                            [
                                -82.1015589998751,
                                30.3666399996195
                            ],
                            [
                                -82.1023749998348,
                                30.3643789996314
                            ],
                            [
                                -82.1006280002877,
                                30.3644170000436
                            ],
                            [
                                -82.1010750001764,
                                30.3627909998432
                            ],
                            [
                                -82.0986990002674,
                                30.3640609999277
                            ],
                            [
                                -82.0947180002031,
                                30.3607030001495
                            ],
                            [
                                -82.0833180001586,
                                30.3603340000928
                            ],
                            [
                                -82.0811139997787,
                                30.3587679996568
                            ],
                            [
                                -82.0726679999515,
                                30.36141000001
                            ],
                            [
                                -82.0661839997566,
                                30.3557569997647
                            ],
                            [
                                -82.0649109998624,
                                30.3564329997366
                            ],
                            [
                                -82.0654210003987,
                                30.3585500001256
                            ],
                            [
                                -82.0628560003437,
                                30.3587549998477
                            ],
                            [
                                -82.0624210001506,
                                30.3606489997934
                            ],
                            [
                                -82.0580910001069,
                                30.3592140003484
                            ],
                            [
                                -82.0477709996127,
                                30.363485000157
                            ],
                            [
                                -82.0451169998693,
                                30.3663070002399
                            ],
                            [
                                -82.0421390002608,
                                30.3654090003575
                            ],
                            [
                                -82.0444869996824,
                                30.3676720002279
                            ],
                            [
                                -82.041757999596,
                                30.3676200002895
                            ],
                            [
                                -82.0407509998406,
                                30.3705559999527
                            ],
                            [
                                -82.0372180003883,
                                30.3718480000222
                            ],
                            [
                                -82.0381409995798,
                                30.3729240003594
                            ],
                            [
                                -82.0367649997534,
                                30.375109000169
                            ],
                            [
                                -82.0388119997745,
                                30.3746929997549
                            ],
                            [
                                -82.0366330004073,
                                30.378095999775
                            ],
                            [
                                -82.0381559996484,
                                30.3822090003341
                            ],
                            [
                                -82.0358349999909,
                                30.3850130000687
                            ],
                            [
                                -82.0400649995772,
                                30.3913849996308
                            ],
                            [
                                -82.0446850000491,
                                30.4039630002306
                            ],
                            [
                                -82.041288000141,
                                30.4066690003764
                            ],
                            [
                                -82.0438990002267,
                                30.4079899997329
                            ],
                            [
                                -82.043632000263,
                                30.4105570002363
                            ],
                            [
                                -82.0418680000991,
                                30.4113140001226
                            ],
                            [
                                -82.0433579997283,
                                30.4130500000067
                            ],
                            [
                                -82.0408470001,
                                30.4149379998537
                            ],
                            [
                                -82.0437619999593,
                                30.4148689996808
                            ],
                            [
                                -82.0409850001923,
                                30.416596999766
                            ],
                            [
                                -82.0395049997105,
                                30.4155030002653
                            ],
                            [
                                -82.0391390000127,
                                30.4170510000086
                            ],
                            [
                                -82.0405419996032,
                                30.4176389997399
                            ],
                            [
                                -82.0375520002991,
                                30.4175379998628
                            ],
                            [
                                -82.0340649999793,
                                30.420032000341
                            ],
                            [
                                -82.0360030002204,
                                30.4249670002016
                            ],
                            [
                                -82.033782999947,
                                30.427649999879
                            ],
                            [
                                -82.0353239996298,
                                30.4279920000188
                            ],
                            [
                                -82.0377270002013,
                                30.4354739999431
                            ],
                            [
                                -82.0394589996798,
                                30.4343719996962
                            ],
                            [
                                -82.0400010000031,
                                30.4366609996653
                            ],
                            [
                                -82.0360640003198,
                                30.4384630002697
                            ],
                            [
                                -82.0338740001836,
                                30.4423160003766
                            ],
                            [
                                -82.0324699998698,
                                30.44159899968
                            ],
                            [
                                -82.028144999849,
                                30.4472520001645
                            ],
                            [
                                -82.0296250003307,
                                30.4507429996371
                            ],
                            [
                                -82.026870000305,
                                30.4537219999641
                            ],
                            [
                                -82.0283199997512,
                                30.455391000201
                            ],
                            [
                                -82.0222719995744,
                                30.4613819996539
                            ],
                            [
                                -82.0241639997847,
                                30.4644789997863
                            ],
                            [
                                -82.0235949996973,
                                30.4672119996521
                            ],
                            [
                                -82.0169060003613,
                                30.4751109998016
                            ],
                            [
                                -82.0193480003926,
                                30.4789759999952
                            ],
                            [
                                -82.0174329997176,
                                30.4798749998542
                            ],
                            [
                                -82.0192179997979,
                                30.4829790001155
                            ],
                            [
                                -82.0155480000783,
                                30.4839580000591
                            ],
                            [
                                -82.0171430001877,
                                30.4855830001394
                            ],
                            [
                                -82.0164050000458,
                                30.488670000173
                            ],
                            [
                                -82.0180169998737,
                                30.4920749999703
                            ],
                            [
                                -82.0157170001327,
                                30.494821999617
                            ],
                            [
                                -82.017754000108,
                                30.4957919997669
                            ],
                            [
                                -82.015149999695,
                                30.4998919998515
                            ],
                            [
                                -82.0170870001112,
                                30.5020760001716
                            ],
                            [
                                -82.017020999989,
                                30.5071319998792
                            ],
                            [
                                -82.0145639998891,
                                30.51231199995
                            ],
                            [
                                -82.0155410004056,
                                30.5200980001296
                            ],
                            [
                                -82.0185850001363,
                                30.5194890001358
                            ],
                            [
                                -82.0162960002659,
                                30.521903999955
                            ],
                            [
                                -82.0186310001671,
                                30.5238340003734
                            ],
                            [
                                -82.0181809999053,
                                30.5275100000465
                            ],
                            [
                                -82.0164410000309,
                                30.5268989997986
                            ],
                            [
                                -82.0154340002755,
                                30.5285009997177
                            ],
                            [
                                -82.018303000104,
                                30.5285209998077
                            ],
                            [
                                -82.0176009999471,
                                30.532319999762
                            ],
                            [
                                -82.0190499995684,
                                30.5330120002064
                            ],
                            [
                                -82.0166090002603,
                                30.5347330001333
                            ],
                            [
                                -82.0157539999426,
                                30.539538999633
                            ],
                            [
                                -82.0134120003687,
                                30.542444000201
                            ],
                            [
                                -82.0131660003214,
                                30.5499459998026
                            ],
                            [
                                -82.0099500001633,
                                30.5504339999885
                            ],
                            [
                                -82.0111390003919,
                                30.5534729998681
                            ],
                            [
                                -82.0084229998259,
                                30.5545290000773
                            ],
                            [
                                -82.0095969999858,
                                30.5562060003266
                            ],
                            [
                                -82.0084680000317,
                                30.5580939998983
                            ],
                            [
                                -82.010024999608,
                                30.557872999718
                            ],
                            [
                                -82.0107109998714,
                                30.5607359997271
                            ],
                            [
                                -82.0080329998385,
                                30.5602529996401
                            ],
                            [
                                -82.0054009998364,
                                30.5636980000527
                            ],
                            [
                                -82.0084069999323,
                                30.5636579996619
                            ],
                            [
                                -82.005820999961,
                                30.5651330001663
                            ],
                            [
                                -82.0082300003803,
                                30.5697119999067
                            ],
                            [
                                -82.0065320003387,
                                30.572940999681
                            ],
                            [
                                -82.0078799996777,
                                30.5749690002436
                            ],
                            [
                                -82.0061830003593,
                                30.5749569998982
                            ],
                            [
                                -82.0080260001658,
                                30.5770299996402
                            ],
                            [
                                -82.0061259995595,
                                30.5771240002967
                            ],
                            [
                                -82.0089950002864,
                                30.5801489998395
                            ],
                            [
                                -82.0088780001105,
                                30.5829359999954
                            ],
                            [
                                -82.0107540004274,
                                30.5837169999653
                            ],
                            [
                                -82.010208999731,
                                30.5864190003047
                            ],
                            [
                                -82.0121579998428,
                                30.5862620002887
                            ],
                            [
                                -82.011202999966,
                                30.588991000028
                            ],
                            [
                                -82.0127930000525,
                                30.5903029999331
                            ],
                            [
                                -82.0122189999422,
                                30.5939100002794
                            ],
                            [
                                -82.0144490002614,
                                30.5962859997129
                            ],
                            [
                                -82.0150869999458,
                                30.6006020002239
                            ],
                            [
                                -82.0236549999717,
                                30.6067750001295
                            ],
                            [
                                -82.0272290003302,
                                30.6066610001761
                            ],
                            [
                                -82.0268090002056,
                                30.6108719996154
                            ],
                            [
                                -82.0288849996407,
                                30.6116849998191
                            ],
                            [
                                -82.0262829997758,
                                30.6133900002133
                            ],
                            [
                                -82.0282589996518,
                                30.6220109997033
                            ],
                            [
                                -82.0299829996327,
                                30.6219120000302
                            ],
                            [
                                -82.0295180002006,
                                30.6191579998107
                            ],
                            [
                                -82.0316460004125,
                                30.6195300001847
                            ],
                            [
                                -82.0316840000474,
                                30.6250159999612
                            ],
                            [
                                -82.0349589997568,
                                30.6268369998642
                            ],
                            [
                                -82.0331710002018,
                                30.6294930001675
                            ],
                            [
                                -82.0367299995933,
                                30.6299749997672
                            ],
                            [
                                -82.0358199999223,
                                30.6318529997692
                            ],
                            [
                                -82.0404899997246,
                                30.636050000175
                            ],
                            [
                                -82.0387680002919,
                                30.6386630003241
                            ],
                            [
                                -82.0402450004006,
                                30.6397090002048
                            ],
                            [
                                -82.038611999758,
                                30.6409469997528
                            ],
                            [
                                -82.0416410003184,
                                30.648981000359
                            ],
                            [
                                -82.0460759999441,
                                30.6520719996677
                            ],
                            [
                                -82.0443450002905,
                                30.6529409998064
                            ],
                            [
                                -82.0490229995904,
                                30.6552950001305
                            ],
                            [
                                -82.0493959998593,
                                30.6620070001447
                            ],
                            [
                                -82.0483439998981,
                                30.6629259999176
                            ],
                            [
                                -82.0464709999543,
                                30.6608059997324
                            ],
                            [
                                -82.0480590003911,
                                30.6650310003423
                            ],
                            [
                                -82.0459899997304,
                                30.6663699997859
                            ],
                            [
                                -82.0482789996008,
                                30.6689060002808
                            ],
                            [
                                -82.0502550003751,
                                30.6761150000151
                            ],
                            [
                                -82.0462950002272,
                                30.6811239999902
                            ],
                            [
                                -82.0484309999367,
                                30.6824319999505
                            ],
                            [
                                -82.0436859997914,
                                30.6839470002647
                            ],
                            [
                                -82.0429839996345,
                                30.6900650001361
                            ],
                            [
                                -82.0412370000874,
                                30.6910319997311
                            ],
                            [
                                -82.0416789999532,
                                30.6961520001404
                            ],
                            [
                                -82.0440370003189,
                                30.6973690001449
                            ],
                            [
                                -82.0412439997601,
                                30.699384999828
                            ],
                            [
                                -82.0429459999996,
                                30.7008859998492
                            ],
                            [
                                -82.0395659998099,
                                30.7006780000635
                            ],
                            [
                                -82.0397790002453,
                                30.7021920003346
                            ],
                            [
                                -82.0359270000524,
                                30.7062049998579
                            ],
                            [
                                -82.0368040001115,
                                30.7130330001207
                            ],
                            [
                                -82.0390389995552,
                                30.7149520000588
                            ],
                            [
                                -82.0388939997903,
                                30.7176510003756
                            ],
                            [
                                -82.037117000106,
                                30.718537999807
                            ],
                            [
                                -82.0395659998099,
                                30.7192990001652
                            ],
                            [
                                -82.0385130000238,
                                30.722636999864
                            ],
                            [
                                -82.045608000139,
                                30.7275519997202
                            ],
                            [
                                -82.0419309998483,
                                30.7300089998941
                            ],
                            [
                                -82.0440709997558,
                                30.7312629997408
                            ],
                            [
                                -82.0388939997903,
                                30.7361529998831
                            ],
                            [
                                -82.0404510002649,
                                30.7379720002531
                            ],
                            [
                                -82.0388560001554,
                                30.7436979997431
                            ],
                            [
                                -82.0397950001388,
                                30.7472969997476
                            ],
                            [
                                -82.0367730001494,
                                30.7544340003499
                            ],
                            [
                                -82.0345860003862,
                                30.7542890003365
                            ],
                            [
                                -82.0316790000245,
                                30.7503879997627
                            ],
                            [
                                -82.0276810002418,
                                30.7509350000346
                            ],
                            [
                                -82.0218439999522,
                                30.7536260000704
                            ],
                            [
                                -82.0213679997511,
                                30.7551929996149
                            ],
                            [
                                -82.0175890002516,
                                30.754991000229
                            ],
                            [
                                -82.0201640003523,
                                30.7578389999705
                            ],
                            [
                                -82.011602999999,
                                30.7618780003019
                            ],
                            [
                                -82.0151599997408,
                                30.7719710000463
                            ],
                            [
                                -82.0183650000283,
                                30.772610999819
                            ],
                            [
                                -82.0173800000142,
                                30.7757999998103
                            ],
                            [
                                -82.0236430002762,
                                30.7830820003729
                            ],
                            [
                                -82.0228800000199,
                                30.7877059999728
                            ],
                            [
                                -82.0169749999582,
                                30.7877979999901
                            ],
                            [
                                -82.0177149997499,
                                30.7905770003225
                            ],
                            [
                                -82.016327000228,
                                30.7915800000276
                            ],
                            [
                                -82.0129739998025,
                                30.7909309996882
                            ],
                            [
                                -82.0141839999475,
                                30.789209999951
                            ],
                            [
                                -82.0104459995575,
                                30.789979000099
                            ],
                            [
                                -82.0076739998133,
                                30.7930370001149
                            ],
                            [
                                -82.0058519999231,
                                30.7903860000798
                            ],
                            [
                                -82.0035870003422,
                                30.7916749997311
                            ],
                            [
                                -81.997406000096,
                                30.7863910001453
                            ],
                            [
                                -81.9955730003352,
                                30.786838999957
                            ],
                            [
                                -81.9887119996734,
                                30.7803629996215
                            ],
                            [
                                -81.9810199998816,
                                30.7769760002942
                            ],
                            [
                                -81.9780420002731,
                                30.7765520001283
                            ],
                            [
                                -81.9736400002594,
                                30.7787190003545
                            ],
                            [
                                -81.9701229998024,
                                30.7843569997443
                            ],
                            [
                                -81.9690239999854,
                                30.7912769999886
                            ],
                            [
                                -81.9621730002677,
                                30.7969779999639
                            ],
                            [
                                -81.9617230000059,
                                30.8009929999138
                            ],
                            [
                                -81.9650420000963,
                                30.806806999988
                            ],
                            [
                                -81.9619439999389,
                                30.80870799999
                            ],
                            [
                                -81.96388200018,
                                30.8129459996762
                            ],
                            [
                                -81.9612190002158,
                                30.8140369998352
                            ],
                            [
                                -81.9644849997044,
                                30.8172969998554
                            ],
                            [
                                -81.9496380004213,
                                30.8278449999954
                            ],
                            [
                                -81.9420240004473,
                                30.8255539997427
                            ],
                            [
                                -81.9428150002927,
                                30.8285039996906
                            ],
                            [
                                -81.9390789995525,
                                30.8272109998834
                            ],
                            [
                                -81.9388340002284,
                                30.8298489999107
                            ],
                            [
                                -81.9362940002878,
                                30.8277660000455
                            ],
                            [
                                -81.936064999959,
                                30.8244820001175
                            ],
                            [
                                -81.9335169996225,
                                30.8233760001365
                            ],
                            [
                                -81.9352570003952,
                                30.8210659997308
                            ],
                            [
                                -81.9298319998342,
                                30.8200719997421
                            ],
                            [
                                -81.9287410004133,
                                30.8175109998735
                            ],
                            [
                                -81.9186469998749,
                                30.8178179998456
                            ],
                            [
                                -81.9091109995533,
                                30.8156800001825
                            ],
                            [
                                -81.9042130001454,
                                30.818589999696
                            ],
                            [
                                -81.906968999996,
                                30.8264899997602
                            ],
                            [
                                -81.9012449996843,
                                30.8298800000074
                            ],
                            [
                                -81.8955379999893,
                                30.825289000215
                            ],
                            [
                                -81.8919750003999,
                                30.8248819999382
                            ],
                            [
                                -81.8914339999015,
                                30.8165860000991
                            ],
                            [
                                -81.8847189997279,
                                30.8114219997342
                            ],
                            [
                                -81.8845289997571,
                                30.8075979999349
                            ],
                            [
                                -81.8821410001525,
                                30.8048339996385
                            ],
                            [
                                -81.8826669996839,
                                30.8002819998691
                            ],
                            [
                                -81.8711389995929,
                                30.7980399998351
                            ],
                            [
                                -81.8685149999867,
                                30.7929060000968
                            ],
                            [
                                -81.8526910001872,
                                30.7948969996143
                            ],
                            [
                                -81.8458710004316,
                                30.7907499997219
                            ],
                            [
                                -81.8402709999684,
                                30.7925910000413
                            ],
                            [
                                -81.8418800003216,
                                30.7875690003122
                            ],
                            [
                                -81.8394769997501,
                                30.7865890001471
                            ],
                            [
                                -81.8300090000989,
                                30.789359999685
                            ],
                            [
                                -81.8237990004387,
                                30.7868119997001
                            ],
                            [
                                -81.8194879997632,
                                30.7898859997327
                            ],
                            [
                                -81.8074490002093,
                                30.7901189997673
                            ],
                            [
                                -81.8018870002793,
                                30.7868750000304
                            ],
                            [
                                -81.7971720002711,
                                30.7814690000112
                            ],
                            [
                                -81.795967000149,
                                30.7821290000533
                            ],
                            [
                                -81.7971260002404,
                                30.7850299999438
                            ],
                            [
                                -81.793074999856,
                                30.7872510000775
                            ],
                            [
                                -81.7913509998751,
                                30.7828040000251
                            ],
                            [
                                -81.7837899996046,
                                30.7733330000774
                            ],
                            [
                                -81.7866969999663,
                                30.7698019999971
                            ],
                            [
                                -81.7827600002829,
                                30.7666130003614
                            ],
                            [
                                -81.7826299996883,
                                30.7614539999942
                            ],
                            [
                                -81.7715679997526,
                                30.7641430001279
                            ],
                            [
                                -81.7735899996593,
                                30.7677079998116
                            ],
                            [
                                -81.7702639998963,
                                30.7736700001127
                            ],
                            [
                                -81.7707059997621,
                                30.7768199997733
                            ],
                            [
                                -81.7617200001598,
                                30.7756980002997
                            ],
                            [
                                -81.7559430001446,
                                30.769344000084
                            ],
                            [
                                -81.7407529998316,
                                30.7650069997816
                            ],
                            [
                                -81.7421949997802,
                                30.7592540001935
                            ],
                            [
                                -81.7318569997426,
                                30.7549949998464
                            ],
                            [
                                -81.7332150000256,
                                30.7512760002932
                            ],
                            [
                                -81.7320550001093,
                                30.7498150001781
                            ],
                            [
                                -81.7201300003582,
                                30.7447190000127
                            ],
                            [
                                -81.709113999555,
                                30.7479759999902
                            ],
                            [
                                -81.7020870001102,
                                30.7454700001597
                            ],
                            [
                                -81.6938930001781,
                                30.7484719997662
                            ],
                            [
                                -81.6891549997054,
                                30.7416319996797
                            ],
                            [
                                -81.6794200000905,
                                30.7415200001943
                            ],
                            [
                                -81.6724940000297,
                                30.7387900001693
                            ],
                            [
                                -81.6688379996555,
                                30.7425140000527
                            ],
                            [
                                -81.670836999996,
                                30.7473959998763
                            ],
                            [
                                -81.6734840000667,
                                30.7485500002581
                            ],
                            [
                                -81.6799079999871,
                                30.7446920002901
                            ],
                            [
                                -81.6827769998157,
                                30.7459680000982
                            ],
                            [
                                -81.6830959996579,
                                30.7484919999775
                            ],
                            [
                                -81.6790689995629,
                                30.7509060002309
                            ],
                            [
                                -81.669554999881,
                                30.7517009996224
                            ],
                            [
                                -81.6623230003968,
                                30.7541770001288
                            ],
                            [
                                -81.6597290000295,
                                30.7497160002197
                            ],
                            [
                                -81.6634819995898,
                                30.7458530000589
                            ],
                            [
                                -81.6630089997618,
                                30.743492000085
                            ],
                            [
                                -81.6608350004174,
                                30.7423399997834
                            ],
                            [
                                -81.6543040003669,
                                30.7443599998222
                            ],
                            [
                                -81.6518280000004,
                                30.7422080003812
                            ],
                            [
                                -81.6540900001066,
                                30.7330929998569
                            ],
                            [
                                -81.6521529996904,
                                30.7288700003187
                            ],
                            [
                                -81.6485359996742,
                                30.7274230001294
                            ],
                            [
                                -81.6435849996645,
                                30.7282029996546
                            ],
                            [
                                -81.6366190003191,
                                30.7339379999237
                            ],
                            [
                                -81.6326819997374,
                                30.7285960000951
                            ],
                            [
                                -81.6304320002251,
                                30.7280880000322
                            ],
                            [
                                -81.6288289997197,
                                30.7293070001682
                            ],
                            [
                                -81.6283309997773,
                                30.7353940003677
                            ],
                            [
                                -81.6243659996066,
                                30.7363400000544
                            ],
                            [
                                -81.6206809998184,
                                30.7309589997786
                            ],
                            [
                                -81.6234340001943,
                                30.7255549998321
                            ],
                            [
                                -81.6229780000847,
                                30.7234669998988
                            ],
                            [
                                -81.6141659996613,
                                30.7223529999567
                            ],
                            [
                                -81.6119300003927,
                                30.7172009996306
                            ],
                            [
                                -81.6098699999528,
                                30.7159329998042
                            ],
                            [
                                -81.6060479998972,
                                30.7186759997251
                            ],
                            [
                                -81.605833999637,
                                30.727263999624
                            ],
                            [
                                -81.601881999885,
                                30.7295859997904
                            ],
                            [
                                -81.5965340002153,
                                30.7274719999943
                            ],
                            [
                                -81.5973729997412,
                                30.7202259996875
                            ],
                            [
                                -81.596176000015,
                                30.7186659996145
                            ],
                            [
                                -81.592657999733,
                                30.7189079998362
                            ],
                            [
                                -81.5876840001571,
                                30.7236350000486
                            ],
                            [
                                -81.5742179998249,
                                30.7225039996799
                            ],
                            [
                                -81.5635070004168,
                                30.7175100002342
                            ],
                            [
                                -81.563461000386,
                                30.7134230001864
                            ],
                            [
                                -81.5614240004107,
                                30.7116720000861
                            ],
                            [
                                -81.5483770003782,
                                30.7182790001773
                            ],
                            [
                                -81.5460199998374,
                                30.7169420002713
                            ],
                            [
                                -81.5428310003417,
                                30.7111340000854
                            ],
                            [
                                -81.5383909997948,
                                30.7086559998977
                            ],
                            [
                                -81.5340110004207,
                                30.7114469999284
                            ],
                            [
                                -81.5344919997463,
                                30.7200740001842
                            ],
                            [
                                -81.5322949999374,
                                30.7240910003031
                            ],
                            [
                                -81.5098870003839,
                                30.7226140003214
                            ],
                            [
                                -81.4884639999461,
                                30.7263090003412
                            ],
                            [
                                -81.4848250001886,
                                30.7251129998539
                            ],
                            [
                                -81.4741130000572,
                                30.7139130001327
                            ],
                            [
                                -81.4512409999981,
                                30.7094840001921
                            ],
                            [
                                -81.403701999754,
                                30.7098139999572
                            ],
                            [
                                -81.404174999582,
                                30.7124389999371
                            ],
                            [
                                -81.3470150004241,
                                30.7124439999292
                            ],
                            [
                                -81.3463890004352,
                                30.7014379997212
                            ],
                            [
                                -81.351552999982,
                                30.6856659999396
                            ],
                            [
                                -81.3581690004214,
                                30.6759759997291
                            ],
                            [
                                -81.3733980001942,
                                30.6627790000424
                            ],
                            [
                                -81.3808670004031,
                                30.627345000163
                            ],
                            [
                                -81.3820149997255,
                                30.583578999847
                            ],
                            [
                                -81.3776739998112,
                                30.5444599996734
                            ],
                            [
                                -81.3750019996261,
                                30.5327580003632
                            ],
                            [
                                -81.354882999943,
                                30.4927539999814
                            ],
                            [
                                -81.3517940000064,
                                30.4734070001962
                            ],
                            [
                                -81.3538700003398,
                                30.4603030002963
                            ],
                            [
                                -81.3535740000638,
                                30.4466600000642
                            ],
                            [
                                -81.3322900004415,
                                30.4330359997331
                            ],
                            [
                                -81.3197990000776,
                                30.4164650000533
                            ],
                            [
                                -81.3168969997388,
                                30.4077209998687
                            ],
                            [
                                -81.3174609998034,
                                30.3940770002915
                            ],
                            [
                                -81.3215630002415,
                                30.3785740003331
                            ],
                            [
                                -81.3294399999812,
                                30.3667620000278
                            ],
                            [
                                -81.3353810000279,
                                30.3612920002756
                            ],
                            [
                                -81.335380000203,
                                30.3377710000824
                            ],
                            [
                                -81.3306269996616,
                                30.2994809999494
                            ],
                            [
                                -81.3179279997837,
                                30.252938999853
                            ],
                            [
                                -81.320184999867,
                                30.2529200001419
                            ],
                            [
                                -81.3186249999176,
                                30.246181000343
                            ],
                            [
                                -81.285314000437,
                                30.1252440000711
                            ],
                            [
                                -81.2551339998274,
                                30.0002449999311
                            ],
                            [
                                -81.2351680003051,
                                29.9422719998837
                            ],
                            [
                                -81.2268389997555,
                                29.9291170003511
                            ],
                            [
                                -81.2238529997511,
                                29.9215599999941
                            ],
                            [
                                -81.2092680004089,
                                29.8752469996634
                            ],
                            [
                                -81.206514000208,
                                29.8555670001242
                            ],
                            [
                                -81.2051769998415,
                                29.8224329997363
                            ],
                            [
                                -81.2004330004193,
                                29.8115119998007
                            ],
                            [
                                -81.1991999998097,
                                29.8011569999251
                            ],
                            [
                                -81.1863989998245,
                                29.7600800003353
                            ],
                            [
                                -81.1674030002477,
                                29.7231009996598
                            ],
                            [
                                -81.1616050003161,
                                29.7004429997805
                            ],
                            [
                                -81.1491440000895,
                                29.6661430003129
                            ],
                            [
                                -81.1237749997476,
                                29.6055100001292
                            ],
                            [
                                -81.1037089997679,
                                29.562658000163
                            ],
                            [
                                -81.0801859996056,
                                29.5183670002145
                            ],
                            [
                                -81.0419230002903,
                                29.4328929998094
                            ],
                            [
                                -81.0457059999878,
                                29.4324619998861
                            ],
                            [
                                -80.9997760000968,
                                29.3368200001004
                            ],
                            [
                                -80.9557850002719,
                                29.2502580002777
                            ],
                            [
                                -80.9401699998114,
                                29.2117259997294
                            ],
                            [
                                -80.9068359998662,
                                29.14470599985
                            ],
                            [
                                -80.8748149999982,
                                29.105967999782
                            ],
                            [
                                -80.7218340001872,
                                28.8749999999462
                            ],
                            [
                                -80.6355730001452,
                                28.7502680000451
                            ],
                            [
                                -80.5229729998157,
                                28.6082380001285
                            ],
                            [
                                -80.5113459999904,
                                28.5752330002379
                            ],
                            [
                                -80.5035849997034,
                                28.540362000369
                            ],
                            [
                                -80.4780539998781,
                                28.4924509997561
                            ],
                            [
                                -80.4686049995952,
                                28.4630779998456
                            ],
                            [
                                -80.4693899995927,
                                28.4530970003391
                            ],
                            [
                                -80.4723379999623,
                                28.4434100001254
                            ],
                            [
                                -80.4812089999369,
                                28.4255110001346
                            ],
                            [
                                -80.5194279997695,
                                28.391083999815
                            ],
                            [
                                -80.534170000369,
                                28.3357030003859
                            ],
                            [
                                -80.5439989996951,
                                28.2710920002497
                            ],
                            [
                                -80.534170000369,
                                28.2341730003416
                            ],
                            [
                                -80.4997729996936,
                                28.0772330000995
                            ],
                            [
                                -80.4290109997611,
                                27.9414819998682
                            ],
                            [
                                -80.3994379997721,
                                27.8914669997192
                            ],
                            [
                                -80.374770999587,
                                27.8405740001492
                            ],
                            [
                                -80.3325260003826,
                                27.7611569998254
                            ],
                            [
                                -80.298867999674,
                                27.6620940001217
                            ],
                            [
                                -80.2949269997927,
                                27.639277999709
                            ],
                            [
                                -80.2813540002287,
                                27.6211709997928
                            ],
                            [
                                -80.2685459996725,
                                27.5729030002655
                            ],
                            [
                                -80.2497700002037,
                                27.5189849997783
                            ],
                            [
                                -80.2458619999344,
                                27.5133549998368
                            ],
                            [
                                -80.2457930003374,
                                27.5101740003278
                            ],
                            [
                                -80.2400020000786,
                                27.5048920001892
                            ],
                            [
                                -80.2331300004445,
                                27.4947209996311
                            ],
                            [
                                -80.2292990001681,
                                27.485629000359
                            ],
                            [
                                -80.2279619998015,
                                27.4630040002053
                            ],
                            [
                                -80.2223949998486,
                                27.4469569998497
                            ],
                            [
                                -80.2133459995987,
                                27.4247190001976
                            ],
                            [
                                -80.189004000002,
                                27.3753190000139
                            ],
                            [
                                -80.1270970001506,
                                27.2393740003626
                            ],
                            [
                                -80.1106950000427,
                                27.2115580002619
                            ],
                            [
                                -80.1082179998513,
                                27.2020610003047
                            ],
                            [
                                -80.1007560002133,
                                27.1921649996711
                            ],
                            [
                                -80.0954110000183,
                                27.1806399997872
                            ],
                            [
                                -80.0940889997203,
                                27.1637360001559
                            ],
                            [
                                -80.0809549996492,
                                27.1253290000854
                            ],
                            [
                                -80.0633850001274,
                                27.0916029997536
                            ],
                            [
                                -80.0391020000819,
                                27.0298530000267
                            ],
                            [
                                -79.9939129998075,
                                26.8753369997428
                            ],
                            [
                                -79.9907989997566,
                                26.8565519996453
                            ],
                            [
                                -79.9754250002188,
                                26.8002500002409
                            ],
                            [
                                -79.9743060003104,
                                26.7924149997906
                            ],
                            [
                                -79.975556999565,
                                26.7649889999947
                            ],
                            [
                                -79.9796499997822,
                                26.7503410000783
                            ],
                            [
                                -79.9791529996647,
                                26.7267090002403
                            ],
                            [
                                -79.9747339999326,
                                26.7082310001218
                            ],
                            [
                                -79.9747280000848,
                                26.7025980002379
                            ],
                            [
                                -79.9765399999293,
                                26.6874789996285
                            ],
                            [
                                -79.979735999996,
                                26.6774640003385
                            ],
                            [
                                -79.9808790001938,
                                26.6636299998611
                            ],
                            [
                                -79.9806480002151,
                                26.5954470000612
                            ],
                            [
                                -79.9824759999531,
                                26.5692469997701
                            ],
                            [
                                -79.9858750004094,
                                26.5508869998245
                            ],
                            [
                                -79.9863099997042,
                                26.5370349997958
                            ],
                            [
                                -79.9885430003964,
                                26.5272639996025
                            ],
                            [
                                -79.9912690001099,
                                26.5230259999218
                            ],
                            [
                                -80.0061489999032,
                                26.4328090002027
                            ],
                            [
                                -80.0143620001019,
                                26.362235999798
                            ],
                            [
                                -80.0169039996923,
                                26.2579779999066
                            ],
                            [
                                -80.040924999797,
                                26.0924589998916
                            ],
                            [
                                -80.0522570000694,
                                25.9749559997268
                            ],
                            [
                                -80.0676850000339,
                                25.902395000043
                            ],
                            [
                                -80.0618710002088,
                                25.8793589999747
                            ],
                            [
                                -80.0560559996605,
                                25.8376880002404
                            ],
                            [
                                -80.0586540002257,
                                25.7943120003476
                            ],
                            [
                                -80.0668720004472,
                                25.7567329996901
                            ],
                            [
                                -80.0712679997149,
                                25.7503780001116
                            ],
                            [
                                -80.0764499997033,
                                25.7378949997669
                            ],
                            [
                                -80.0817920004236,
                                25.7303790002752
                            ],
                            [
                                -80.0912230002648,
                                25.7219029996544
                            ],
                            [
                                -80.0884830003077,
                                25.710355000211
                            ],
                            [
                                -80.0887999996018,
                                25.7020529998129
                            ],
                            [
                                -80.0902719996876,
                                25.6949219997814
                            ],
                            [
                                -80.0970859995954,
                                25.6820220003469
                            ],
                            [
                                -80.0942750003915,
                                25.6720530002012
                            ],
                            [
                                -80.0941439999719,
                                25.6630800003675
                            ],
                            [
                                -80.0964999997895,
                                25.652753000334
                            ],
                            [
                                -80.1035730001633,
                                25.6400859996445
                            ],
                            [
                                -80.1036800002935,
                                25.638171000106
                            ],
                            [
                                -80.0904099997799,
                                25.6382959996069
                            ],
                            [
                                -80.0805920003243,
                                25.6360539999548
                            ],
                            [
                                -80.0602379995662,
                                25.625383000223
                            ],
                            [
                                -80.0467489996678,
                                25.6063889998595
                            ],
                            [
                                -80.0435099999434,
                                25.5980509999843
                            ],
                            [
                                -80.043813999717,
                                25.5791189997586
                            ],
                            [
                                -80.0482299999745,
                                25.5668979996972
                            ],
                            [
                                -80.0567000000911,
                                25.5552120002088
                            ],
                            [
                                -80.065808999717,
                                25.548175999977
                            ],
                            [
                                -80.0797870002353,
                                25.5415330002704
                            ],
                            [
                                -80.091947000163,
                                25.5389879998649
                            ],
                            [
                                -80.1080349995532,
                                25.53930699991
                            ],
                            [
                                -80.1156710001668,
                                25.5413929997222
                            ],
                            [
                                -80.1180010000451,
                                25.5257479998642
                            ],
                            [
                                -80.1173490001169,
                                25.5127640001293
                            ],
                            [
                                -80.1191989995962,
                                25.5046150003292
                            ],
                            [
                                -80.1206410004431,
                                25.5003870002048
                            ],
                            [
                                -80.1224149997544,
                                25.5003849999525
                            ],
                            [
                                -80.1273619995661,
                                25.4928240001995
                            ],
                            [
                                -80.1247670002723,
                                25.4875859997542
                            ],
                            [
                                -80.1263470003131,
                                25.4745509997009
                            ],
                            [
                                -80.1375559996636,
                                25.4508639997678
                            ],
                            [
                                -80.1417569998359,
                                25.4347110003139
                            ],
                            [
                                -80.1247670002723,
                                25.4231199995976
                            ],
                            [
                                -80.1185759999803,
                                25.4155009996803
                            ],
                            [
                                -80.111750000377,
                                25.4008800001861
                            ],
                            [
                                -80.1106110003771,
                                25.3825639997394
                            ],
                            [
                                -80.1164560001643,
                                25.3669489997041
                            ],
                            [
                                -80.1226649999996,
                                25.3578150001319
                            ],
                            [
                                -80.1311100000019,
                                25.3511380002901
                            ],
                            [
                                -80.1333600004125,
                                25.3392920003293
                            ],
                            [
                                -80.1404189996444,
                                25.3265350002157
                            ],
                            [
                                -80.1476009997982,
                                25.3196500001044
                            ],
                            [
                                -80.1500129996923,
                                25.3144200003148
                            ],
                            [
                                -80.1562249999006,
                                25.3072969999797
                            ],
                            [
                                -80.1635929998273,
                                25.3011220002793
                            ],
                            [
                                -80.174650999565,
                                25.2949459996678
                            ],
                            [
                                -80.1885860004256,
                                25.2912669998661
                            ],
                            [
                                -80.1980540000768,
                                25.2911319999838
                            ],
                            [
                                -80.2095779999698,
                                25.2923799997915
                            ],
                            [
                                -80.2178620003136,
                                25.2952469999404
                            ],
                            [
                                -80.2262269999499,
                                25.2869750000527
                            ],
                            [
                                -80.243168000008,
                                25.2553750002389
                            ],
                            [
                                -80.2326609999161,
                                25.2402210002884
                            ],
                            [
                                -80.2292640000079,
                                25.2255009996154
                            ],
                            [
                                -80.2329770002836,
                                25.2052880003369
                            ],
                            [
                                -80.2379979997152,
                                25.1953240002571
                            ],
                            [
                                -80.2523119997941,
                                25.176851000363
                            ],
                            [
                                -80.2644609998512,
                                25.1679589998501
                            ],
                            [
                                -80.2818460003233,
                                25.1622779999876
                            ],
                            [
                                -80.2972459998004,
                                25.1621599996606
                            ],
                            [
                                -80.302898999967,
                                25.1449230001496
                            ],
                            [
                                -80.3333210004259,
                                25.099202000389
                            ],
                            [
                                -80.3418640003374,
                                25.0883999999423
                            ],
                            [
                                -80.3577800001985,
                                25.0729019999572
                            ],
                            [
                                -80.3762900004268,
                                25.0622190003341
                            ],
                            [
                                -80.390434999553,
                                25.0571889999326
                            ],
                            [
                                -80.3916239997816,
                                25.0393960001733
                            ],
                            [
                                -80.3965039996462,
                                25.0282100000938
                            ],
                            [
                                -80.4060330002951,
                                25.015692999716
                            ],
                            [
                                -80.4125450000791,
                                25.0102319996449
                            ],
                            [
                                -80.424145999965,
                                25.0036410000808
                            ],
                            [
                                -80.4391010001015,
                                24.9979889997136
                            ],
                            [
                                -80.440179000002,
                                24.9856740000108
                            ],
                            [
                                -80.4455219996489,
                                24.9734219999454
                            ],
                            [
                                -80.4533649999517,
                                24.9636980003195
                            ],
                            [
                                -80.4694980004461,
                                24.951754999791
                            ],
                            [
                                -80.4780809996422,
                                24.9484279999723
                            ],
                            [
                                -80.5143429998655,
                                24.940627999715
                            ],
                            [
                                -80.5252840003257,
                                24.9235340002975
                            ],
                            [
                                -80.5381639999537,
                                24.9127330003104
                            ],
                            [
                                -80.5591449996273,
                                24.9022659999383
                            ],
                            [
                                -80.5668699999293,
                                24.9005830001319
                            ],
                            [
                                -80.5747559998898,
                                24.8938919996826
                            ],
                            [
                                -80.5854510003027,
                                24.8893290002447
                            ],
                            [
                                -80.5967590002858,
                                24.8749989999928
                            ],
                            [
                                -80.6112950001225,
                                24.8651520001902
                            ],
                            [
                                -80.6198959997604,
                                24.8545860001371
                            ],
                            [
                                -80.6404279998955,
                                24.838433999989
                            ],
                            [
                                -80.6523419997759,
                                24.8314029998854
                            ],
                            [
                                -80.6748110003271,
                                24.8256110000883
                            ],
                            [
                                -80.6889549996284,
                                24.8156769996125
                            ],
                            [
                                -80.7088110004442,
                                24.8046880003633
                            ],
                            [
                                -80.7451149996019,
                                24.7888779999221
                            ],
                            [
                                -80.7564750003618,
                                24.7761530002943
                            ],
                            [
                                -80.7710920003894,
                                24.7659509998974
                            ],
                            [
                                -80.7833750003408,
                                24.7612600002089
                            ],
                            [
                                -80.7929710000385,
                                24.7597019998008
                            ],
                            [
                                -80.801369000185,
                                24.7593239997711
                            ],
                            [
                                -80.8124660002809,
                                24.7612109996004
                            ],
                            [
                                -80.8370389998563,
                                24.7500020002569
                            ],
                            [
                                -80.8522280003445,
                                24.7489310001882
                            ],
                            [
                                -80.8613539996889,
                                24.7396619997824
                            ],
                            [
                                -80.8684689998955,
                                24.7359069996432
                            ],
                            [
                                -80.8785659999086,
                                24.7251740003821
                            ],
                            [
                                -80.8932950000893,
                                24.7164529997159
                            ],
                            [
                                -80.9055870002614,
                                24.7134680000453
                            ],
                            [
                                -80.9189110003034,
                                24.713066000051
                            ],
                            [
                                -80.933921999618,
                                24.7022029999809
                            ],
                            [
                                -80.9452599997383,
                                24.6962299998247
                            ],
                            [
                                -80.9571130004176,
                                24.6928359999155
                            ],
                            [
                                -80.9673579996705,
                                24.6859159997845
                            ],
                            [
                                -80.9764920003091,
                                24.6823680002557
                            ],
                            [
                                -80.9892199996011,
                                24.6738240002138
                            ],
                            [
                                -80.9997919999904,
                                24.6689119997432
                            ],
                            [
                                -80.9997919999904,
                                24.6703639996944
                            ],
                            [
                                -81.0225829998586,
                                24.6652749999782
                            ],
                            [
                                -81.0301109996188,
                                24.6545679998574
                            ],
                            [
                                -81.0476480004269,
                                24.6402399997639
                            ],
                            [
                                -81.0597360003846,
                                24.635863999786
                            ],
                            [
                                -81.0762460004475,
                                24.6334150002551
                            ],
                            [
                                -81.0972239997481,
                                24.6330029997176
                            ],
                            [
                                -81.1247949999218,
                                24.6366049996203
                            ],
                            [
                                -81.1408510004233,
                                24.6430640001845
                            ],
                            [
                                -81.1476860002474,
                                24.649063999855
                            ],
                            [
                                -81.1582929998985,
                                24.6402070001903
                            ],
                            [
                                -81.1693930003674,
                                24.6346190002951
                            ],
                            [
                                -81.1840439998319,
                                24.6312050001263
                            ],
                            [
                                -81.2042589997745,
                                24.6311860000133
                            ],
                            [
                                -81.2206500000117,
                                24.6215489996104
                            ],
                            [
                                -81.2399140002754,
                                24.6170629996924
                            ],
                            [
                                -81.2497979998532,
                                24.606556000102
                            ],
                            [
                                -81.2646259997681,
                                24.5992340001228
                            ],
                            [
                                -81.2779399997643,
                                24.5963890003033
                            ],
                            [
                                -81.2934549997673,
                                24.5967220003386
                            ],
                            [
                                -81.3043679997402,
                                24.5926099999848
                            ],
                            [
                                -81.337607999974,
                                24.5847600002148
                            ],
                            [
                                -81.3461590002815,
                                24.580662000077
                            ],
                            [
                                -81.3558429998427,
                                24.5796530000401
                            ],
                            [
                                -81.3644569998994,
                                24.5744789996926
                            ],
                            [
                                -81.3748009997847,
                                24.5714880002762
                            ],
                            [
                                -81.3916699998728,
                                24.5696660001926
                            ],
                            [
                                -81.4206360001394,
                                24.5705719999968
                            ],
                            [
                                -81.4371660002938,
                                24.5609339999322
                            ],
                            [
                                -81.4516080004193,
                                24.5555259998437
                            ],
                            [
                                -81.4650880000968,
                                24.5528539997984
                            ],
                            [
                                -81.4833649997983,
                                24.5555480002878
                            ],
                            [
                                -81.494647999667,
                                24.5625259997881
                            ],
                            [
                                -81.4998050004394,
                                24.5615950003149
                            ],
                            [
                                -81.5146149999125,
                                24.5647570001454
                            ],
                            [
                                -81.5278519999158,
                                24.5532790001122
                            ],
                            [
                                -81.5193700001036,
                                24.5306120000345
                            ],
                            [
                                -81.5198809995665,
                                24.5125550002516
                            ],
                            [
                                -81.5256710000004,
                                24.5004259998593
                            ],
                            [
                                -81.5285580002706,
                                24.5004259998593
                            ],
                            [
                                -81.53992399998,
                                24.4860339999487
                            ],
                            [
                                -81.5514460002233,
                                24.4780309998937
                            ],
                            [
                                -81.564956999863,
                                24.4727780001876
                            ],
                            [
                                -81.5858260002819,
                                24.4710939996943
                            ],
                            [
                                -81.596837000164,
                                24.4610630001076
                            ],
                            [
                                -81.6102590001152,
                                24.4543080001466
                            ],
                            [
                                -81.6324220003447,
                                24.4511459997414
                            ],
                            [
                                -81.6372400002851,
                                24.4481220003739
                            ],
                            [
                                -81.660078000009,
                                24.4413450001414
                            ],
                            [
                                -81.6774190001002,
                                24.4391050001063
                            ],
                            [
                                -81.6971989998496,
                                24.4323139998086
                            ],
                            [
                                -81.7118309999459,
                                24.4297330003658
                            ],
                            [
                                -81.7301129996702,
                                24.4306980002543
                            ],
                            [
                                -81.7407479998087,
                                24.4340320002509
                            ],
                            [
                                -81.7498110003022,
                                24.4384540003095
                            ],
                            [
                                -81.7628700000303,
                                24.4496429999824
                            ],
                            [
                                -81.770205000345,
                                24.4632379999514
                            ],
                            [
                                -81.7743939999234,
                                24.4793030001973
                            ],
                            [
                                -81.7708070000445,
                                24.496871999792
                            ],
                            [
                                -81.7792830000089,
                                24.4948179996638
                            ],
                            [
                                -81.7923619998284,
                                24.4956129998144
                            ],
                            [
                                -81.7883239998628,
                                24.491300000257
                            ],
                            [
                                -81.7833079995557,
                                24.4786990001419
                            ],
                            [
                                -81.7818070000559,
                                24.4609270001971
                            ],
                            [
                                -81.7889850000118,
                                24.439482000066
                            ],
                            [
                                -81.8006970002258,
                                24.4271400001501
                            ],
                            [
                                -81.8135219996022,
                                24.417638999838
                            ],
                            [
                                -81.833113999929,
                                24.4099859999991
                            ],
                            [
                                -81.8497570000613,
                                24.4058750000301
                            ],
                            [
                                -81.8748130004088,
                                24.4031440002199
                            ],
                            [
                                -81.8942619997221,
                                24.4057440001929
                            ],
                            [
                                -81.9111290001604,
                                24.3977889996935
                            ],
                            [
                                -81.9205559998037,
                                24.3963080003242
                            ],
                            [
                                -81.9348110003314,
                                24.3969189997197
                            ],
                            [
                                -81.9464409996314,
                                24.399348000062
                            ],
                            [
                                -81.9584370004259,
                                24.4052270003333
                            ],
                            [
                                -81.9673180004462,
                                24.4119589999615
                            ],
                            [
                                -81.9744679999147,
                                24.4208219996635
                            ],
                            [
                                -81.9797679999039,
                                24.4316559999211
                            ],
                            [
                                -81.9820540002995,
                                24.4454480004038
                            ],
                            [
                                -81.9809250003454,
                                24.4569670000803
                            ],
                            [
                                -81.9744090003634,
                                24.4712090003412
                            ],
                            [
                                -81.9992349996589,
                                24.4765769999492
                            ],
                            [
                                -82.013803000181,
                                24.4761400003605
                            ],
                            [
                                -82.0234790002446,
                                24.4778640002996
                            ],
                            [
                                -82.0342719996685,
                                24.4824959996886
                            ],
                            [
                                -82.0446449998662,
                                24.4897819996484
                            ],
                            [
                                -82.0510739998095,
                                24.4968440004074
                            ],
                            [
                                -82.0581799997953,
                                24.5143639995952
                            ],
                            [
                                -82.0671710003188,
                                24.5237150001686
                            ],
                            [
                                -82.0793189996527,
                                24.5115949996607
                            ],
                            [
                                -82.087586000278,
                                24.5061239998634
                            ],
                            [
                                -82.1003329998366,
                                24.4999979996637
                            ],
                            [
                                -82.1056060000616,
                                24.5004280001089
                            ],
                            [
                                -82.120076999601,
                                24.4976739998369
                            ],
                            [
                                -82.1421090003092,
                                24.4989340001599
                            ],
                            [
                                -82.1517560000606,
                                24.4972430000409
                            ],
                            [
                                -82.1639140003385,
                                24.4976109999798
                            ],
                            [
                                -82.1767169999735,
                                24.4999999999201
                            ],
                            [
                                -82.1750430002214,
                                24.4999999999201
                            ],
                            [
                                -82.192292000251,
                                24.5076809999342
                            ],
                            [
                                -82.2055359999271,
                                24.5189910003613
                            ],
                            [
                                -82.2189639997261,
                                24.542209999795
                            ],
                            [
                                -82.2204759999949,
                                24.5509130001113
                            ],
                            [
                                -82.220286999849,
                                24.5651649998484
                            ],
                            [
                                -82.2150019999284,
                                24.5827839997842
                            ],
                            [
                                -82.2102390002397,
                                24.5908430003259
                            ],
                            [
                                -82.1917869997377,
                                24.6098179997592
                            ],
                            [
                                -82.1715480004039,
                                24.6249999999045
                            ],
                            [
                                -82.1535260000722,
                                24.6348650001903
                            ],
                            [
                                -82.137571999678,
                                24.6387149996473
                            ],
                            [
                                -82.1200230000726,
                                24.6402550000126
                            ],
                            [
                                -82.101675000226,
                                24.6394850001618
                            ],
                            [
                                -82.0817310004451,
                                24.6356339996665
                            ],
                            [
                                -82.0507409997235,
                                24.6174649996055
                            ],
                            [
                                -82.026642999626,
                                24.6076659999713
                            ],
                            [
                                -82.0101579996774,
                                24.6211380003595
                            ],
                            [
                                -81.9943380000758,
                                24.6288120002212
                            ],
                            [
                                -81.9878510004062,
                                24.6347699999297
                            ],
                            [
                                -81.9760310002371,
                                24.6399120000269
                            ],
                            [
                                -81.9617129999602,
                                24.6436650002668
                            ],
                            [
                                -81.9538250003499,
                                24.650125000033
                            ],
                            [
                                -81.9452439999052,
                                24.653954000015
                            ],
                            [
                                -81.9266910000191,
                                24.658202999642
                            ],
                            [
                                -81.9166469997095,
                                24.6584540000597
                            ],
                            [
                                -81.9083950000511,
                                24.6623830001044
                            ],
                            [
                                -81.8915700003439,
                                24.6658550002828
                            ],
                            [
                                -81.8664229997598,
                                24.6633050002633
                            ],
                            [
                                -81.8587629997551,
                                24.6693970002451
                            ],
                            [
                                -81.8460839999686,
                                24.6741049999373
                            ],
                            [
                                -81.8413439998461,
                                24.678378999699
                            ],
                            [
                                -81.8335780004345,
                                24.6821120003004
                            ],
                            [
                                -81.8257419998044,
                                24.6842960001723
                            ],
                            [
                                -81.8153019996596,
                                24.6921009998584
                            ],
                            [
                                -81.8063870002023,
                                24.6964719999294
                            ],
                            [
                                -81.7890899995937,
                                24.7000600002827
                            ],
                            [
                                -81.7712079999025,
                                24.7117949996675
                            ],
                            [
                                -81.7609979999114,
                                24.715892999736
                            ],
                            [
                                -81.741650999807,
                                24.7172539997131
                            ],
                            [
                                -81.7235280000913,
                                24.7255240001651
                            ],
                            [
                                -81.7183030004452,
                                24.7330210000834
                            ],
                            [
                                -81.7106829997252,
                                24.7401409999329
                            ],
                            [
                                -81.6988280002943,
                                24.7460349998684
                            ],
                            [
                                -81.6852470003344,
                                24.7499980003949
                            ],
                            [
                                -81.677312999795,
                                24.7558570001821
                            ],
                            [
                                -81.6605500000121,
                                24.7644739998333
                            ],
                            [
                                -81.6405009997508,
                                24.7729070000012
                            ],
                            [
                                -81.6281120003925,
                                24.7829260002301
                            ],
                            [
                                -81.6170909995664,
                                24.7871229998551
                            ],
                            [
                                -81.6027699998148,
                                24.7995589996698
                            ],
                            [
                                -81.5869619999087,
                                24.8050609997923
                            ],
                            [
                                -81.5832479998082,
                                24.807969000292
                            ],
                            [
                                -81.5673749996048,
                                24.8119570002725
                            ],
                            [
                                -81.5592629996885,
                                24.8166739997734
                            ],
                            [
                                -81.5419619997803,
                                24.8213849998837
                            ],
                            [
                                -81.5390150001339,
                                24.826197000185
                            ],
                            [
                                -81.5281730003062,
                                24.8354870003221
                            ],
                            [
                                -81.5122010003686,
                                24.8426949997379
                            ],
                            [
                                -81.4998079999141,
                                24.8510619999696
                            ],
                            [
                                -81.4840939997194,
                                24.8563459998129
                            ],
                            [
                                -81.4771810000774,
                                24.8568429999892
                            ],
                            [
                                -81.4588869997591,
                                24.8674500002583
                            ],
                            [
                                -81.44202900044,
                                24.8711400001187
                            ],
                            [
                                -81.4355800004052,
                                24.8706870000371
                            ],
                            [
                                -81.4238949999553,
                                24.8775709998186
                            ],
                            [
                                -81.4142940002347,
                                24.8807109996793
                            ],
                            [
                                -81.4024730002407,
                                24.8818270003356
                            ],
                            [
                                -81.3881580003369,
                                24.8798480001235
                            ],
                            [
                                -81.3691610000369,
                                24.8713020003871
                            ],
                            [
                                -81.3582179999268,
                                24.8617160003864
                            ],
                            [
                                -81.3459569997168,
                                24.8693110000507
                            ],
                            [
                                -81.3291029996973,
                                24.8730260003929
                            ],
                            [
                                -81.3125709998931,
                                24.873704999672
                            ],
                            [
                                -81.2936969996167,
                                24.8681499998632
                            ],
                            [
                                -81.2751160001416,
                                24.8782269997251
                            ],
                            [
                                -81.260166000028,
                                24.8809920000326
                            ],
                            [
                                -81.2529460002394,
                                24.8811450002723
                            ],
                            [
                                -81.220896000059,
                                24.8763369999139
                            ],
                            [
                                -81.2067920000424,
                                24.8708209998096
                            ],
                            [
                                -81.1974530002626,
                                24.8641019999142
                            ],
                            [
                                -81.1830649996656,
                                24.8432690001488
                            ],
                            [
                                -81.1592429997525,
                                24.8360179996296
                            ],
                            [
                                -81.1507380003741,
                                24.8303299997214
                            ],
                            [
                                -81.1422709997322,
                                24.8221500001714
                            ],
                            [
                                -81.1324289999872,
                                24.805834999668
                            ],
                            [
                                -81.1200320002331,
                                24.8093529998521
                            ],
                            [
                                -81.0966549996606,
                                24.8092560004065
                            ],
                            [
                                -81.0829099996692,
                                24.8039980004049
                            ],
                            [
                                -81.064469999761,
                                24.7911159999469
                            ],
                            [
                                -81.0508200002042,
                                24.794275000231
                            ],
                            [
                                -81.0405640001824,
                                24.7947739996198
                            ],
                            [
                                -81.0239129996542,
                                24.8047460001186
                            ],
                            [
                                -81.0115490004102,
                                24.8075840001004
                            ],
                            [
                                -81.0031849997005,
                                24.811312000088
                            ],
                            [
                                -80.9997939996402,
                                24.8118580002613
                            ],
                            [
                                -80.9997939996402,
                                24.8097700000588
                            ],
                            [
                                -80.9856439995928,
                                24.8117529996935
                            ],
                            [
                                -80.9754729999598,
                                24.81707099989
                            ],
                            [
                                -80.9642880000005,
                                24.8199069997247
                            ],
                            [
                                -80.9608309998178,
                                24.8263890002731
                            ],
                            [
                                -80.9531640001404,
                                24.8346159998378
                            ],
                            [
                                -80.9383239996317,
                                24.8431930003723
                            ],
                            [
                                -80.9228449996137,
                                24.847264999879
                            ],
                            [
                                -80.9083499997848,
                                24.8473610003341
                            ],
                            [
                                -80.8995979996359,
                                24.8511850004071
                            ],
                            [
                                -80.8913729997416,
                                24.8526839997216
                            ],
                            [
                                -80.8880210000394,
                                24.8590639997074
                            ],
                            [
                                -80.8994779999853,
                                24.8702239997781
                            ],
                            [
                                -80.9149829999426,
                                24.8615120002844
                            ],
                            [
                                -80.9247340003493,
                                24.8582190001769
                            ],
                            [
                                -80.9339459999075,
                                24.8561729997273
                            ],
                            [
                                -80.9487820002182,
                                24.8568130003714
                            ],
                            [
                                -80.9663299999987,
                                24.8629140001624
                            ],
                            [
                                -80.9795329996668,
                                24.8717029997107
                            ],
                            [
                                -80.9894470002801,
                                24.8864109998909
                            ],
                            [
                                -80.9933749997426,
                                24.9007539996215
                            ],
                            [
                                -80.9939759996171,
                                24.9104540000719
                            ],
                            [
                                -81.0084670001464,
                                24.9185730001711
                            ],
                            [
                                -81.0201299999566,
                                24.9308999999016
                            ],
                            [
                                -81.0293540001087,
                                24.9485420002259
                            ],
                            [
                                -81.0428859996647,
                                24.9537690002972
                            ],
                            [
                                -81.0515049997443,
                                24.9647069999679
                            ],
                            [
                                -81.0584109997136,
                                24.9801460003969
                            ],
                            [
                                -81.0616269998717,
                                25.0027930002454
                            ],
                            [
                                -81.069017000438,
                                25.0141410003892
                            ],
                            [
                                -81.0753760000611,
                                25.0331580000297
                            ],
                            [
                                -81.0869869999929,
                                25.0438460002728
                            ],
                            [
                                -81.0929730002454,
                                25.0517570001932
                            ],
                            [
                                -81.0984890001447,
                                25.0664709998052
                            ],
                            [
                                -81.1092949999873,
                                25.0688980000036
                            ],
                            [
                                -81.1201190002717,
                                25.0735379998542
                            ],
                            [
                                -81.135079000431,
                                25.0875060003028
                            ],
                            [
                                -81.1416770004287,
                                25.1003969998829
                            ],
                            [
                                -81.1495249998561,
                                25.1073840001127
                            ],
                            [
                                -81.1681289997957,
                                25.1124419999629
                            ],
                            [
                                -81.1844089997048,
                                25.1250009997403
                            ],
                            [
                                -81.1901770003975,
                                25.1335110001114
                            ],
                            [
                                -81.1956229999765,
                                25.147149999693
                            ],
                            [
                                -81.1964509996318,
                                25.1603180003171
                            ],
                            [
                                -81.1953290002487,
                                25.1688369999516
                            ],
                            [
                                -81.2002650001898,
                                25.1782409998716
                            ],
                            [
                                -81.2155070003814,
                                25.1893999999821
                            ],
                            [
                                -81.2207899997538,
                                25.1976290000516
                            ],
                            [
                                -81.2269419996877,
                                25.215368000073
                            ],
                            [
                                -81.2309090004065,
                                25.2222129998577
                            ],
                            [
                                -81.2340440003739,
                                25.2345220001587
                            ],
                            [
                                -81.232905000374,
                                25.2504000001415
                            ],
                            [
                                -81.2226550002,
                                25.269464999901
                            ],
                            [
                                -81.2224400001149,
                                25.277898000018
                            ],
                            [
                                -81.2153369996037,
                                25.2960719996466
                            ],
                            [
                                -81.2156689998648,
                                25.305381999773
                            ],
                            [
                                -81.2079689996771,
                                25.3240569996376
                            ],
                            [
                                -81.2068270002025,
                                25.3371209996086
                            ],
                            [
                                -81.1960770004363,
                                25.3570580000002
                            ],
                            [
                                -81.2059409999226,
                                25.3770200002663
                            ],
                            [
                                -81.205709000119,
                                25.4107509996288
                            ],
                            [
                                -81.2163629996257,
                                25.4241429998089
                            ],
                            [
                                -81.2217970004075,
                                25.441495999603
                            ],
                            [
                                -81.2305050001755,
                                25.4516530003777
                            ],
                            [
                                -81.2328429995514,
                                25.4518689998219
                            ],
                            [
                                -81.2462219998449,
                                25.464033999727
                            ],
                            [
                                -81.2591170004398,
                                25.4799560001945
                            ],
                            [
                                -81.2656459999423,
                                25.4936180001717
                            ],
                            [
                                -81.2660259998838,
                                25.5046629998213
                            ],
                            [
                                -81.2731760002506,
                                25.5191269999402
                            ],
                            [
                                -81.2760040000713,
                                25.5351179999752
                            ],
                            [
                                -81.2834230000516,
                                25.5439340002785
                            ],
                            [
                                -81.287546000406,
                                25.5527610001172
                            ],
                            [
                                -81.2921540002841,
                                25.5554670002843
                            ],
                            [
                                -81.3011750000465,
                                25.5655870001096
                            ],
                            [
                                -81.3049540004444,
                                25.5721619998623
                            ],
                            [
                                -81.3145889996018,
                                25.57915300036
                            ],
                            [
                                -81.3194030002425,
                                25.5850849997168
                            ],
                            [
                                -81.3268029999562,
                                25.6021669999348
                            ],
                            [
                                -81.3311599997641,
                                25.6182510002474
                            ],
                            [
                                -81.3378980004022,
                                25.6234030004042
                            ],
                            [
                                -81.3472310003342,
                                25.6367269999791
                            ],
                            [
                                -81.3613629999399,
                                25.6374009996465
                            ],
                            [
                                -81.3729980001611,
                                25.6405870003504
                            ],
                            [
                                -81.3867299997337,
                                25.6476830002207
                            ],
                            [
                                -81.3930010003916,
                                25.6528629997423
                            ],
                            [
                                -81.3991940003334,
                                25.6592520001089
                            ],
                            [
                                -81.4101959999946,
                                25.6772040001561
                            ],
                            [
                                -81.4179959997414,
                                25.6978429998785
                            ],
                            [
                                -81.4309549999104,
                                25.7084349998169
                            ],
                            [
                                -81.4357400002388,
                                25.7147209996086
                            ],
                            [
                                -81.4438160001701,
                                25.7314939997644
                            ],
                            [
                                -81.4451429995926,
                                25.7375759999142
                            ],
                            [
                                -81.4587899996748,
                                25.7418480001015
                            ],
                            [
                                -81.4657769998349,
                                25.7464199999066
                            ],
                            [
                                -81.4779409999606,
                                25.7486069998817
                            ],
                            [
                                -81.4877180003066,
                                25.7522849999482
                            ],
                            [
                                -81.5055010002637,
                                25.7623920003232
                            ],
                            [
                                -81.517484999566,
                                25.7759230001483
                            ],
                            [
                                -81.5261520002244,
                                25.7805600002383
                            ],
                            [
                                -81.5292150002217,
                                25.7838419999698
                            ],
                            [
                                -81.5622000001874,
                                25.7947459999506
                            ],
                            [
                                -81.5736949997682,
                                25.8050439996418
                            ],
                            [
                                -81.5880950000608,
                                25.8099219996266
                            ],
                            [
                                -81.5936129996099,
                                25.7968980003658
                            ],
                            [
                                -81.6020509999395,
                                25.7863469998928
                            ],
                            [
                                -81.6199040002167,
                                25.7747229998507
                            ],
                            [
                                -81.6286689998861,
                                25.7712609997518
                            ],
                            [
                                -81.6363330000888,
                                25.7702230002669
                            ],
                            [
                                -81.6708260001253,
                                25.7694520001954
                            ],
                            [
                                -81.6876130001977,
                                25.7709119999288
                            ],
                            [
                                -81.7008369997822,
                                25.776392999775
                            ],
                            [
                                -81.7181390004137,
                                25.7930329996347
                            ],
                            [
                                -81.7222869999842,
                                25.8000529997767
                            ],
                            [
                                -81.727377999736,
                                25.8150540003901
                            ],
                            [
                                -81.7372100003336,
                                25.8275509998376
                            ],
                            [
                                -81.7422190000696,
                                25.8438980003455
                            ],
                            [
                                -81.7516020002303,
                                25.8526930001694
                            ],
                            [
                                -81.7590079997918,
                                25.8660450000197
                            ],
                            [
                                -81.7752860000511,
                                25.8809470001442
                            ],
                            [
                                -81.7817860001395,
                                25.8940360002021
                            ],
                            [
                                -81.7848750000761,
                                25.913955999882
                            ],
                            [
                                -81.7967649996671,
                                25.9251249997052
                            ],
                            [
                                -81.8011359997187,
                                25.931726999618
                            ],
                            [
                                -81.8045619999391,
                                25.9414869999456
                            ],
                            [
                                -81.806218000148,
                                25.9537089997622
                            ],
                            [
                                -81.8010410001824,
                                25.9738279996892
                            ],
                            [
                                -81.8097170001633,
                                25.9913350000338
                            ],
                            [
                                -81.8212989997828,
                                26.0064889999782
                            ],
                            [
                                -81.8314359999788,
                                26.0236049997036
                            ],
                            [
                                -81.8503400003925,
                                26.0628720003513
                            ],
                            [
                                -81.8557590002073,
                                26.0817100003733
                            ],
                            [
                                -81.8592230000626,
                                26.1082419997246
                            ],
                            [
                                -81.8678820003251,
                                26.2143830000493
                            ],
                            [
                                -81.8769890003013,
                                26.2366710003843
                            ],
                            [
                                -81.8876289995643,
                                26.2872229996414
                            ],
                            [
                                -81.9027550003033,
                                26.324416000017
                            ],
                            [
                                -81.9187160003702,
                                26.3519599997082
                            ],
                            [
                                -81.9419490001043,
                                26.3753599997242
                            ],
                            [
                                -81.9671340003232,
                                26.3941550000575
                            ],
                            [
                                -81.9781259999387,
                                26.399692000074
                            ],
                            [
                                -82.0081750001287,
                                26.3953590000076
                            ],
                            [
                                -82.0528370001484,
                                26.3753660003372
                            ],
                            [
                                -82.0688979997744,
                                26.3713180002186
                            ],
                            [
                                -82.0919160003217,
                                26.3725049997315
                            ],
                            [
                                -82.1122209997777,
                                26.3777550001944
                            ],
                            [
                                -82.1204280001286,
                                26.3821339999989
                            ],
                            [
                                -82.1446690003413,
                                26.4002860000719
                            ],
                            [
                                -82.1598300003421,
                                26.4168540001987
                            ],
                            [
                                -82.1733290002862,
                                26.4378589997745
                            ],
                            [
                                -82.194376999907,
                                26.4490270001738
                            ],
                            [
                                -82.2161960001799,
                                26.4564669998278
                            ],
                            [
                                -82.2286609997061,
                                26.4676269997685
                            ],
                            [
                                -82.2364529999553,
                                26.4772999998046
                            ],
                            [
                                -82.2459549999417,
                                26.5003569997689
                            ],
                            [
                                -82.2579750001273,
                                26.5458119996274
                            ],
                            [
                                -82.2761930002776,
                                26.5752270001947
                            ],
                            [
                                -82.2816969995829,
                                26.593600000013
                            ],
                            [
                                -82.2818460004442,
                                26.6101899999497
                            ],
                            [
                                -82.2899969998203,
                                26.6197810002322
                            ],
                            [
                                -82.2960969998756,
                                26.6311069999847
                            ],
                            [
                                -82.3053629998604,
                                26.6397720002253
                            ],
                            [
                                -82.3165100001849,
                                26.6552010000276
                            ],
                            [
                                -82.3247149999877,
                                26.6729100002551
                            ],
                            [
                                -82.3252449997171,
                                26.6828699998656
                            ],
                            [
                                -82.3231540002134,
                                26.6961589999014
                            ],
                            [
                                -82.321522000294,
                                26.7033790003362
                            ],
                            [
                                -82.3178480003764,
                                26.7098310000552
                            ],
                            [
                                -82.3209669995519,
                                26.7198649996218
                            ],
                            [
                                -82.3179300003922,
                                26.7344019997889
                            ],
                            [
                                -82.3205940001814,
                                26.7474090002262
                            ],
                            [
                                -82.3195060002351,
                                26.7490339996684
                            ],
                            [
                                -82.3222000001615,
                                26.7696629996659
                            ],
                            [
                                -82.3332539997013,
                                26.7837019996262
                            ],
                            [
                                -82.3361660000858,
                                26.7905849996636
                            ],
                            [
                                -82.3383190004121,
                                26.8033179996465
                            ],
                            [
                                -82.3748179995635,
                                26.851821000312
                            ],
                            [
                                -82.394753000022,
                                26.8753419997937
                            ],
                            [
                                -82.4593200003629,
                                26.9799000001609
                            ],
                            [
                                -82.4688909999463,
                                27.0002290003399
                            ],
                            [
                                -82.4998199996701,
                                27.0437179996523
                            ],
                            [
                                -82.5127420000291,
                                27.0803470002969
                            ],
                            [
                                -82.522768999722,
                                27.0958240002663
                            ],
                            [
                                -82.5275499998525,
                                27.1192080003144
                            ],
                            [
                                -82.5343529998896,
                                27.1366330003179
                            ],
                            [
                                -82.5502069998264,
                                27.1654739997554
                            ],
                            [
                                -82.555490999922,
                                27.179266999855
                            ],
                            [
                                -82.5581339997948,
                                27.1943150003216
                            ],
                            [
                                -82.5647400001884,
                                27.2056000002432
                            ],
                            [
                                -82.5805940001252,
                                27.2219010000754
                            ],
                            [
                                -82.6056959996051,
                                27.2382020002677
                            ],
                            [
                                -82.6162649996214,
                                27.2494879997
                            ],
                            [
                                -82.6228700001901,
                                27.2607729996402
                            ],
                            [
                                -82.6248810002262,
                                27.2737809996757
                            ],
                            [
                                -82.6317870001954,
                                27.2950980000467
                            ],
                            [
                                -82.638394000414,
                                27.303876000149
                            ],
                            [
                                -82.6635000000919,
                                27.3276999996237
                            ],
                            [
                                -82.6782789996029,
                                27.3441429996885
                            ],
                            [
                                -82.6956149996712,
                                27.3699380002324
                            ],
                            [
                                -82.705438000048,
                                27.3896679999126
                            ],
                            [
                                -82.721286000137,
                                27.4019510001087
                            ],
                            [
                                -82.73515800035,
                                27.4158080001029
                            ],
                            [
                                -82.7543160003085,
                                27.4508529998751
                            ],
                            [
                                -82.7651949999462,
                                27.4773779998564
                            ],
                            [
                                -82.8153770000631,
                                27.5359579997995
                            ],
                            [
                                -82.8209049996579,
                                27.555517999861
                            ],
                            [
                                -82.8236689999044,
                                27.5750769997892
                            ],
                            [
                                -82.8236689999044,
                                27.5894210002741
                            ],
                            [
                                -82.8209049996579,
                                27.606372000089
                            ],
                            [
                                -82.8112300003175,
                                27.6363629997954
                            ],
                            [
                                -82.8153770000631,
                                27.6546180003246
                            ],
                            [
                                -82.8126129998165,
                                27.6754819997567
                            ],
                            [
                                -82.8043179996021,
                                27.6937370000002
                            ],
                            [
                                -82.8057000001745,
                                27.7093840000078
                            ],
                            [
                                -82.8750000000669,
                                27.7943429999106
                            ],
                            [
                                -82.8940380003749,
                                27.8243920001737
                            ],
                            [
                                -82.9013369998062,
                                27.839106999897
                            ],
                            [
                                -82.9081020002085,
                                27.8671270000293
                            ],
                            [
                                -82.9089089999474,
                                27.8900819998279
                            ],
                            [
                                -82.9072939997465,
                                27.9014900003484
                            ],
                            [
                                -82.8978970002404,
                                27.9443950001788
                            ],
                            [
                                -82.8882029997351,
                                27.9637130002292
                            ],
                            [
                                -82.8887159997461,
                                27.9685350000303
                            ],
                            [
                                -82.8849399997212,
                                27.9823479997381
                            ],
                            [
                                -82.8838109997671,
                                27.9941299999673
                            ],
                            [
                                -82.8865520004474,
                                28.013659999898
                            ],
                            [
                                -82.8824359997657,
                                28.0341289997775
                            ],
                            [
                                -82.878104999897,
                                28.0428199999456
                            ],
                            [
                                -82.8902520003043,
                                28.0536779999573
                            ],
                            [
                                -82.8960359999922,
                                28.0686819997423
                            ],
                            [
                                -82.8966329996687,
                                28.0802950001488
                            ],
                            [
                                -82.8919109999879,
                                28.1079689996333
                            ],
                            [
                                -82.9019619999702,
                                28.1210199998815
                            ],
                            [
                                -82.9039239996025,
                                28.1292050003824
                            ],
                            [
                                -82.9049859996095,
                                28.1413769999406
                            ],
                            [
                                -82.9019150001146,
                                28.1633810002406
                            ],
                            [
                                -82.9058950003539,
                                28.1779549999455
                            ],
                            [
                                -82.9079120002377,
                                28.2128119996827
                            ],
                            [
                                -82.9066359999705,
                                28.2196059997849
                            ],
                            [
                                -82.9006249996036,
                                28.2336269997854
                            ],
                            [
                                -82.8921500003624,
                                28.2452469997777
                            ],
                            [
                                -82.8789250000547,
                                28.2555279998107
                            ],
                            [
                                -82.8667819998454,
                                28.2596850002943
                            ],
                            [
                                -82.8399029997828,
                                28.263102000342
                            ],
                            [
                                -82.8195539999459,
                                28.2592529998225
                            ],
                            [
                                -82.8182989995951,
                                28.2673249999727
                            ],
                            [
                                -82.8127900002668,
                                28.2813799996922
                            ],
                            [
                                -82.8045450002811,
                                28.2928780003498
                            ],
                            [
                                -82.7952759999233,
                                28.2997639998515
                            ],
                            [
                                -82.792609999586,
                                28.3148659996553
                            ],
                            [
                                -82.7931779998485,
                                28.3200790001636
                            ],
                            [
                                -82.7997049997012,
                                28.3343140000978
                            ],
                            [
                                -82.8002679999408,
                                28.3547319997573
                            ],
                            [
                                -82.7948270003847,
                                28.3707379996998
                            ],
                            [
                                -82.7857420001498,
                                28.3809130000072
                            ],
                            [
                                -82.7852769998194,
                                28.38769699988
                            ],
                            [
                                -82.7813660000754,
                                28.3972450000683
                            ],
                            [
                                -82.7616420004024,
                                28.4173459999821
                            ],
                            [
                                -82.7516270004051,
                                28.4410739999407
                            ],
                            [
                                -82.7461950001714,
                                28.4493100001164
                            ],
                            [
                                -82.7482999999188,
                                28.4552439999422
                            ],
                            [
                                -82.7483710000639,
                                28.467515000285
                            ],
                            [
                                -82.7420199999384,
                                28.4858339999201
                            ],
                            [
                                -82.748290999698,
                                28.5015860003494
                            ],
                            [
                                -82.7457490001076,
                                28.5190459999033
                            ],
                            [
                                -82.7484949999125,
                                28.5284539997923
                            ],
                            [
                                -82.7481879997658,
                                28.5416869999271
                            ],
                            [
                                -82.742103999604,
                                28.5580620002308
                            ],
                            [
                                -82.7422900002751,
                                28.5671689997779
                            ],
                            [
                                -82.7389860002534,
                                28.5788379998799
                            ],
                            [
                                -82.7273920000401,
                                28.5955870002154
                            ],
                            [
                                -82.7404840002785,
                                28.62526799972
                            ],
                            [
                                -82.7559900000607,
                                28.6420479999168
                            ],
                            [
                                -82.7634590002697,
                                28.653263999942
                            ],
                            [
                                -82.7662069997245,
                                28.6596669997272
                            ],
                            [
                                -82.7683240000657,
                                28.6748019996083
                            ],
                            [
                                -82.7732139999761,
                                28.6818330002181
                            ],
                            [
                                -82.7769840001531,
                                28.6926619999437
                            ],
                            [
                                -82.7836380002274,
                                28.7007249998534
                            ],
                            [
                                -82.7880069997308,
                                28.7093020003013
                            ],
                            [
                                -82.7911249999797,
                                28.7251719999135
                            ],
                            [
                                -82.7875409995755,
                                28.7434570000725
                            ],
                            [
                                -82.8038499997969,
                                28.7672719998522
                            ],
                            [
                                -82.813037000139,
                                28.7912930001038
                            ],
                            [
                                -82.8136730001737,
                                28.8006220001255
                            ],
                            [
                                -82.8118040004278,
                                28.8211159999829
                            ],
                            [
                                -82.8057629999237,
                                28.8356229998233
                            ],
                            [
                                -82.796271999808,
                                28.8448509997211
                            ],
                            [
                                -82.794484000253,
                                28.8568889999157
                            ],
                            [
                                -82.8049369999183,
                                28.8743380001606
                            ],
                            [
                                -82.8082440003131,
                                28.8902269999432
                            ],
                            [
                                -82.8243750002593,
                                28.8950480001691
                            ],
                            [
                                -82.8410760001179,
                                28.9052679999097
                            ],
                            [
                                -82.849674000281,
                                28.916369000077
                            ],
                            [
                                -82.8530450002499,
                                28.9233659999237
                            ],
                            [
                                -82.857653000128,
                                28.9386159998344
                            ],
                            [
                                -82.856875999628,
                                28.9491270001511
                            ],
                            [
                                -82.8531959998626,
                                28.9607279998529
                            ],
                            [
                                -82.8447669997539,
                                28.9720839998927
                            ],
                            [
                                -82.851646999784,
                                28.9829250001839
                            ],
                            [
                                -82.8545829995596,
                                28.9935500003328
                            ],
                            [
                                -82.8548529998964,
                                29.0002500003461
                            ],
                            [
                                -82.8523280000244,
                                29.0002500003461
                            ],
                            [
                                -82.8531039998011,
                                29.0053959997071
                            ],
                            [
                                -82.851277999713,
                                29.0173890000117
                            ],
                            [
                                -82.8622919999681,
                                29.0307330000629
                            ],
                            [
                                -82.8665990004456,
                                29.0455240001843
                            ],
                            [
                                -82.8748340003856,
                                29.0449730000962
                            ],
                            [
                                -82.8950429995821,
                                29.0480040001449
                            ],
                            [
                                -82.9090550004356,
                                29.0545519996369
                            ],
                            [
                                -82.91386499998,
                                29.0586750000535
                            ],
                            [
                                -82.9335130003833,
                                29.064240000078
                            ],
                            [
                                -82.9500779997994,
                                29.0760590000646
                            ],
                            [
                                -82.9780809997933,
                                29.0692269997362
                            ],
                            [
                                -82.9937020001016,
                                29.0542719997308
                            ],
                            [
                                -82.9998399997918,
                                29.0507860002741
                            ],
                            [
                                -82.9998399997918,
                                29.0524330002881
                            ],
                            [
                                -83.0147229999582,
                                29.0463330003563
                            ],
                            [
                                -83.0242960000896,
                                29.0449940001435
                            ],
                            [
                                -83.0438080000505,
                                29.0471910000643
                            ],
                            [
                                -83.0631119995989,
                                29.0417140001141
                            ],
                            [
                                -83.0759999996228,
                                29.0418189996976
                            ],
                            [
                                -83.1110800001884,
                                29.0498680002068
                            ],
                            [
                                -83.1248440004464,
                                29.0581009998005
                            ],
                            [
                                -83.1440800002228,
                                29.0728400002708
                            ],
                            [
                                -83.1541089995655,
                                29.0900189998546
                            ],
                            [
                                -83.1578240003893,
                                29.1045619999897
                            ],
                            [
                                -83.1610289997785,
                                29.1094149997244
                            ],
                            [
                                -83.1666960001888,
                                29.125245000037
                            ],
                            [
                                -83.1672560000555,
                                29.1360580002753
                            ],
                            [
                                -83.165368999868,
                                29.1443570000035
                            ],
                            [
                                -83.1724320001961,
                                29.157104999996
                            ],
                            [
                                -83.1735190003174,
                                29.1760079999717
                            ],
                            [
                                -83.1718060002072,
                                29.1859210001057
                            ],
                            [
                                -83.181509000035,
                                29.1919760003143
                            ],
                            [
                                -83.1933569997932,
                                29.2038300000628
                            ],
                            [
                                -83.197650000027,
                                29.2123319997934
                            ],
                            [
                                -83.2137480003612,
                                29.2175739997289
                            ],
                            [
                                -83.2249330003206,
                                29.2251240001141
                            ],
                            [
                                -83.2338210000136,
                                29.2357280001298
                            ],
                            [
                                -83.2397670000832,
                                29.2502400001104
                            ],
                            [
                                -83.2413930001548,
                                29.2810730000287
                            ],
                            [
                                -83.238679000137,
                                29.3086099997372
                            ],
                            [
                                -83.2498520004008,
                                29.3167939998523
                            ],
                            [
                                -83.2593669999076,
                                29.326791000319
                            ],
                            [
                                -83.2792489997643,
                                29.3322769998019
                            ],
                            [
                                -83.2976889996724,
                                29.3460749998307
                            ],
                            [
                                -83.3057310001669,
                                29.3596019997771
                            ],
                            [
                                -83.3084109998495,
                                29.3686879999715
                            ],
                            [
                                -83.3084800003448,
                                29.3830179998853
                            ],
                            [
                                -83.3284359998214,
                                29.3882000003249
                            ],
                            [
                                -83.3433959999806,
                                29.3977599996313
                            ],
                            [
                                -83.3519439999151,
                                29.4081140003784
                            ],
                            [
                                -83.3602199998629,
                                29.4310830003348
                            ],
                            [
                                -83.3716700001362,
                                29.4385180003896
                            ],
                            [
                                -83.3891490003196,
                                29.441107999688
                            ],
                            [
                                -83.4105459999199,
                                29.4516059999935
                            ],
                            [
                                -83.4254440001549,
                                29.4557720000582
                            ],
                            [
                                -83.4391089997804,
                                29.4660590000708
                            ],
                            [
                                -83.4448570003815,
                                29.4739849999038
                            ],
                            [
                                -83.4494560000387,
                                29.483350999638
                            ],
                            [
                                -83.4635979996902,
                                29.5348010003482
                            ],
                            [
                                -83.4636959995995,
                                29.5463070002388
                            ],
                            [
                                -83.4623950001162,
                                29.5497419999691
                            ],
                            [
                                -83.4670299997585,
                                29.5612670003195
                            ],
                            [
                                -83.4685970002788,
                                29.576267000105
                            ],
                            [
                                -83.465879000063,
                                29.5838110001683
                            ],
                            [
                                -83.4685050002173,
                                29.5889170001056
                            ],
                            [
                                -83.4686009995785,
                                29.5991089999596
                            ],
                            [
                                -83.4645099999094,
                                29.6133189997638
                            ],
                            [
                                -83.4732219998753,
                                29.6205920003512
                            ],
                            [
                                -83.4812640003697,
                                29.630329000169
                            ],
                            [
                                -83.4883250001497,
                                29.6286970001275
                            ],
                            [
                                -83.5023780001127,
                                29.6310200000838
                            ],
                            [
                                -83.5210020001439,
                                29.6393189998387
                            ],
                            [
                                -83.5288680000129,
                                29.6452539999115
                            ],
                            [
                                -83.541525999883,
                                29.6629500002289
                            ],
                            [
                                -83.5443739997951,
                                29.6714529998881
                            ],
                            [
                                -83.5550979996221,
                                29.6742509998298
                            ],
                            [
                                -83.5669939999592,
                                29.6798710002539
                            ],
                            [
                                -83.5748270002162,
                                29.6857010002758
                            ],
                            [
                                -83.580282999841,
                                29.6858200003809
                            ],
                            [
                                -83.5971340003858,
                                29.6916579998442
                            ],
                            [
                                -83.6093079996589,
                                29.6984160002033
                            ],
                            [
                                -83.6194199997406,
                                29.709338000344
                            ],
                            [
                                -83.6248670000429,
                                29.7225070002412
                            ],
                            [
                                -83.6340879998218,
                                29.7320139997045
                            ],
                            [
                                -83.6389979998237,
                                29.7400449998836
                            ],
                            [
                                -83.6427050002516,
                                29.7631840000427
                            ],
                            [
                                -83.6460989997867,
                                29.7744599998343
                            ],
                            [
                                -83.6473109995815,
                                29.7971389999587
                            ],
                            [
                                -83.6572439995631,
                                29.8026710001997
                            ],
                            [
                                -83.6662579996528,
                                29.8118019999228
                            ],
                            [
                                -83.6732380001402,
                                29.8237650002664
                            ],
                            [
                                -83.6750540001826,
                                29.8302759997187
                            ],
                            [
                                -83.6869610003904,
                                29.8405670003494
                            ],
                            [
                                -83.6967639997773,
                                29.8586660002899
                            ],
                            [
                                -83.7101360003982,
                                29.866292000235
                            ],
                            [
                                -83.719169999681,
                                29.8761569999395
                            ],
                            [
                                -83.7315139997318,
                                29.881484999972
                            ],
                            [
                                -83.7452279997611,
                                29.8938199997074
                            ],
                            [
                                -83.7587059997888,
                                29.8978080000705
                            ],
                            [
                                -83.7764340003926,
                                29.8980519996875
                            ],
                            [
                                -83.8011579995808,
                                29.9020089999603
                            ],
                            [
                                -83.8204410001111,
                                29.9102580001234
                            ],
                            [
                                -83.8351069996442,
                                29.9209069997785
                            ],
                            [
                                -83.8479460001626,
                                29.9233799998421
                            ],
                            [
                                -83.8590390000605,
                                29.928390999993
                            ],
                            [
                                -83.867104999946,
                                29.9332350000373
                            ],
                            [
                                -83.8749969997543,
                                29.9410019998326
                            ],
                            [
                                -83.8841730002258,
                                29.9555529996644
                            ],
                            [
                                -83.9133029996256,
                                29.9587039997849
                            ],
                            [
                                -83.9241019997956,
                                29.9636389999203
                            ],
                            [
                                -83.9362850001879,
                                29.9744420002606
                            ],
                            [
                                -83.9459310001143,
                                29.9894019996264
                            ],
                            [
                                -83.9579150003149,
                                29.9929119997965
                            ],
                            [
                                -83.9697139996693,
                                30.0002100001954
                            ],
                            [
                                -83.9754000003462,
                                30.0071710000086
                            ],
                            [
                                -83.9911789999399,
                                30.0092500001679
                            ],
                            [
                                -84.0221579998924,
                                30.0187899997451
                            ],
                            [
                                -84.035685000324,
                                30.0289530003692
                            ],
                            [
                                -84.0434309996441,
                                30.0397549998859
                            ],
                            [
                                -84.0665350004051,
                                30.0350379996312
                            ],
                            [
                                -84.074213999778,
                                30.0354049997412
                            ],
                            [
                                -84.0933280002539,
                                30.0287999999817
                            ],
                            [
                                -84.10935200007,
                                30.0280840001388
                            ],
                            [
                                -84.1175960002308,
                                30.0128310002889
                            ],
                            [
                                -84.1237430001418,
                                30.0051559996274
                            ],
                            [
                                -84.1335999999554,
                                29.9969530001775
                            ],
                            [
                                -84.1504270002108,
                                29.9910520001415
                            ],
                            [
                                -84.1661310003597,
                                29.990685999828
                            ],
                            [
                                -84.1682150001907,
                                29.9887059999793
                            ],
                            [
                                -84.1846139999255,
                                29.9830689998752
                            ],
                            [
                                -84.2085349995728,
                                29.9842100003253
                            ],
                            [
                                -84.2268239998682,
                                29.9900790000662
                            ],
                            [
                                -84.2371290002938,
                                29.9958829998611
                            ],
                            [
                                -84.2513189996258,
                                29.9959690002939
                            ],
                            [
                                -84.2558880000441,
                                29.9873679999644
                            ],
                            [
                                -84.2652760002277,
                                29.9790449998278
                            ],
                            [
                                -84.2569059996702,
                                29.9617130000622
                            ],
                            [
                                -84.2563430003288,
                                29.9477559996443
                            ],
                            [
                                -84.259114000248,
                                29.9367659997752
                            ],
                            [
                                -84.2649609996851,
                                29.9267880002371
                            ],
                            [
                                -84.2758360000231,
                                29.9138590001081
                            ],
                            [
                                -84.2803789996038,
                                29.8961450001065
                            ],
                            [
                                -84.283305000232,
                                29.8904659999774
                            ],
                            [
                                -84.2918399997477,
                                29.8810629996867
                            ],
                            [
                                -84.2941930000905,
                                29.8749980002811
                            ],
                            [
                                -84.2931419999542,
                                29.8749980002811
                            ],
                            [
                                -84.2987959999457,
                                29.8671260000869
                            ],
                            [
                                -84.3112300004081,
                                29.8573210002834
                            ],
                            [
                                -84.3314539996733,
                                29.8485619998443
                            ],
                            [
                                -84.3468580002466,
                                29.8465589998381
                            ],
                            [
                                -84.3608550001331,
                                29.8474550000572
                            ],
                            [
                                -84.3765760000005,
                                29.8447570002444
                            ],
                            [
                                -84.3967120003004,
                                29.8468310001423
                            ],
                            [
                                -84.421932999606,
                                29.8556940000968
                            ],
                            [
                                -84.431457000232,
                                29.8554520003839
                            ],
                            [
                                -84.4468600000821,
                                29.8587319999151
                            ],
                            [
                                -84.4642470002041,
                                29.8428529998928
                            ],
                            [
                                -84.4733149998222,
                                29.8370620001116
                            ],
                            [
                                -84.4943390000518,
                                29.8318469997492
                            ],
                            [
                                -84.5128450000821,
                                29.8320420000272
                            ],
                            [
                                -84.5134839995915,
                                29.8240960001791
                            ],
                            [
                                -84.5225899997428,
                                29.7943579996163
                            ],
                            [
                                -84.5279669997248,
                                29.7840249996162
                            ],
                            [
                                -84.5422859998266,
                                29.7697970002138
                            ],
                            [
                                -84.5568130003409,
                                29.7630350001536
                            ],
                            [
                                -84.5716060000956,
                                29.7607450001598
                            ],
                            [
                                -84.5800080004401,
                                29.7524079997719
                            ],
                            [
                                -84.5956179999795,
                                29.7445000003154
                            ],
                            [
                                -84.606472000401,
                                29.74316800002
                            ],
                            [
                                -84.6210290001542,
                                29.737123000289
                            ],
                            [
                                -84.6392020000986,
                                29.7352140003384
                            ],
                            [
                                -84.6409960003997,
                                29.7332919996885
                            ],
                            [
                                -84.6499469998419,
                                29.7309329998591
                            ],
                            [
                                -84.6535699997059,
                                29.7269520002381
                            ],
                            [
                                -84.6672340004048,
                                29.7197449998801
                            ],
                            [
                                -84.6691199998691,
                                29.7157369997724
                            ],
                            [
                                -84.6836010003526,
                                29.6996419999055
                            ],
                            [
                                -84.6961730000089,
                                29.6934999997343
                            ],
                            [
                                -84.7008410001614,
                                29.6858640001742
                            ],
                            [
                                -84.7357500001246,
                                29.6563749998098
                            ],
                            [
                                -84.7499029996467,
                                29.6495009996783
                            ],
                            [
                                -84.7584449997333,
                                29.6474169997389
                            ],
                            [
                                -84.7634290002533,
                                29.6427280002323
                            ],
                            [
                                -84.773065000134,
                                29.6379289998906
                            ],
                            [
                                -84.7918730002882,
                                29.6337179996773
                            ],
                            [
                                -84.8017040001625,
                                29.6277369998333
                            ],
                            [
                                -84.811678000152,
                                29.6250009998384
                            ],
                            [
                                -84.8255910003729,
                                29.6236389997269
                            ],
                            [
                                -84.838325000411,
                                29.615766000356
                            ],
                            [
                                -84.847830999697,
                                29.6139669996353
                            ],
                            [
                                -84.8521130000601,
                                29.6101530003651
                            ],
                            [
                                -84.866203999658,
                                29.6050110000221
                            ],
                            [
                                -84.8776740000227,
                                29.5959099999706
                            ],
                            [
                                -84.8877850002795,
                                29.5922650003149
                            ],
                            [
                                -84.893876000114,
                                29.5870720002187
                            ],
                            [
                                -84.927476000198,
                                29.5696280000541
                            ],
                            [
                                -84.9561500003866,
                                29.5603370002526
                            ],
                            [
                                -84.967699000394,
                                29.5585000000025
                            ],
                            [
                                -84.9723390000591,
                                29.555189000337
                            ],
                            [
                                -84.9924910002525,
                                29.5498029998966
                            ],
                            [
                                -85.003591999648,
                                29.5485939999101
                            ],
                            [
                                -85.0120819998561,
                                29.5495180000857
                            ],
                            [
                                -85.0252369998437,
                                29.5414939999974
                            ],
                            [
                                -85.0364679998338,
                                29.5375800001754
                            ],
                            [
                                -85.0553939999887,
                                29.5359530000539
                            ],
                            [
                                -85.0704379998136,
                                29.5384749997715
                            ],
                            [
                                -85.0779699997718,
                                29.5413330001089
                            ],
                            [
                                -85.0899220001853,
                                29.5490580000664
                            ],
                            [
                                -85.1093620001762,
                                29.5693570003452
                            ],
                            [
                                -85.1234820000863,
                                29.5767040001339
                            ],
                            [
                                -85.1436939996559,
                                29.5794650002274
                            ],
                            [
                                -85.1684250003134,
                                29.5885349996389
                            ],
                            [
                                -85.1806399995944,
                                29.5953500002927
                            ],
                            [
                                -85.2093349996994,
                                29.6154510002695
                            ],
                            [
                                -85.2348460003316,
                                29.624998999987
                            ],
                            [
                                -85.246315999798,
                                29.6250020001545
                            ],
                            [
                                -85.2726719998038,
                                29.6311589997019
                            ],
                            [
                                -85.2920160004335,
                                29.6322730003804
                            ],
                            [
                                -85.3028940002462,
                                29.6196059999072
                            ],
                            [
                                -85.3101380003243,
                                29.6137369998782
                            ],
                            [
                                -85.3266419996411,
                                29.6059339997258
                            ],
                            [
                                -85.3360540001141,
                                29.6041689998886
                            ],
                            [
                                -85.3483870002942,
                                29.603626000373
                            ],
                            [
                                -85.3644040004376,
                                29.6056859998392
                            ],
                            [
                                -85.3816840004294,
                                29.6128529996145
                            ],
                            [
                                -85.4164340003839,
                                29.6494630000281
                            ],
                            [
                                -85.4340390000658,
                                29.676687000285
                            ],
                            [
                                -85.4474760000857,
                                29.7073740003833
                            ],
                            [
                                -85.4573809995798,
                                29.7352310003385
                            ],
                            [
                                -85.4692950003586,
                                29.7813169997349
                            ],
                            [
                                -85.4742160002311,
                                29.815377000119
                            ],
                            [
                                -85.4738780001223,
                                29.8527489997089
                            ],
                            [
                                -85.4698519998522,
                                29.8682839998381
                            ],
                            [
                                -85.4652359995782,
                                29.8789939998236
                            ],
                            [
                                -85.4589229999858,
                                29.8891910001521
                            ],
                            [
                                -85.4462890004052,
                                29.9010330000653
                            ],
                            [
                                -85.4589919995828,
                                29.906591000145
                            ],
                            [
                                -85.4817649999077,
                                29.9070830002861
                            ],
                            [
                                -85.5050810003808,
                                29.9117810000287
                            ],
                            [
                                -85.5184170001183,
                                29.9166920000817
                            ],
                            [
                                -85.5400310003518,
                                29.9280029996977
                            ],
                            [
                                -85.5650020003105,
                                29.9447249997853
                            ],
                            [
                                -85.5876540002616,
                                29.9625180002161
                            ],
                            [
                                -85.6227719995638,
                                30.0011729999348
                            ],
                            [
                                -85.6325370002142,
                                30.0142550002073
                            ],
                            [
                                -85.6512449999109,
                                30.0241690002932
                            ],
                            [
                                -85.6661370002981,
                                30.0270080000211
                            ],
                            [
                                -85.7057799996377,
                                30.0415280002964
                            ],
                            [
                                -85.737425999587,
                                30.0578149996824
                            ],
                            [
                                -85.767959000374,
                                30.0764040003559
                            ],
                            [
                                -85.794775999614,
                                30.102115000208
                            ],
                            [
                                -85.826187000285,
                                30.1251850000639
                            ],
                            [
                                -85.8851919997976,
                                30.1595470002127
                            ],
                            [
                                -85.9217519999467,
                                30.1768660000437
                            ],
                            [
                                -85.942526999756,
                                30.1891969996653
                            ],
                            [
                                -85.9998929996765,
                                30.2130899999479
                            ],
                            [
                                -86.1847599996363,
                                30.2779660003107
                            ],
                            [
                                -86.2795540004424,
                                30.3043180003314
                            ],
                            [
                                -86.3047510003569,
                                30.3098900003447
                            ],
                            [
                                -86.3975040000915,
                                30.3253540003478
                            ],
                            [
                                -86.4320570004025,
                                30.3288399999005
                            ],
                            [
                                -86.4869369999108,
                                30.331692999819
                            ],
                            [
                                -86.4999499996081,
                                30.3290290000344
                            ],
                            [
                                -86.5235000004329,
                                30.3303210003419
                            ],
                            [
                                -86.5384859996332,
                                30.336442000127
                            ],
                            [
                                -86.5645219999719,
                                30.3376199997306
                            ],
                            [
                                -86.5887220001768,
                                30.3419199999375
                            ],
                            [
                                -86.6458610003166,
                                30.3461730001988
                            ],
                            [
                                -86.6898849997535,
                                30.3432970001058
                            ],
                            [
                                -86.713366000083,
                                30.3433810001259
                            ],
                            [
                                -86.7698080000888,
                                30.3381180000443
                            ],
                            [
                                -86.8818760001407,
                                30.3246049998698
                            ],
                            [
                                -86.9999630000571,
                                30.303358999743
                            ],
                            [
                                -87.0645350004209,
                                30.294702000138
                            ],
                            [
                                -87.1107659997125,
                                30.2860820002563
                            ],
                            [
                                -87.1387639996834,
                                30.2784930003664
                            ],
                            [
                                -87.2212410002031,
                                30.2672880002078
                            ],
                            [
                                -87.2577260000091,
                                30.2641579997408
                            ],
                            [
                                -87.2871619997307,
                                30.2722159999245
                            ],
                            [
                                -87.2964570000278,
                                30.2726899998743
                            ],
                            [
                                -87.3207290002027,
                                30.2646319998033
                            ],
                            [
                                -87.3749740003996,
                                30.2537289996985
                            ],
                            [
                                -87.4406349996362,
                                30.24431699966
                            ],
                            [
                                -87.4769040004305,
                                30.236304999699
                            ],
                            [
                                -87.518346000094,
                                30.2295059997632
                            ],
                            [
                                -87.5183800004292,
                                30.2839010003228
                            ],
                            [
                                -87.4523779998124,
                                30.3002009996705
                            ],
                            [
                                -87.4500780000714,
                                30.3110999998076
                            ],
                            [
                                -87.4629779997908,
                                30.3077999997176
                            ],
                            [
                                -87.4758790002334,
                                30.3079000000837
                            ],
                            [
                                -87.4836789999802,
                                30.3048009999401
                            ],
                            [
                                -87.4999799998058,
                                30.3062009998368
                            ],
                            [
                                -87.5038799996792,
                                30.3080010000164
                            ],
                            [
                                -87.5057800002855,
                                30.3124999996952
                            ],
                            [
                                -87.5059429995938,
                                30.3193960000809
                            ],
                            [
                                -87.5025719996249,
                                30.3274050000238
                            ],
                            [
                                -87.4935800001748,
                                30.3305999998106
                            ],
                            [
                                -87.4718789999026,
                                30.3317999996941
                            ],
                            [
                                -87.4610780000828,
                                30.3353000000652
                            ],
                            [
                                -87.4510780001541,
                                30.3455989998811
                            ],
                            [
                                -87.4513779997297,
                                30.3671989998155
                            ],
                            [
                                -87.4386780000268,
                                30.3807980001169
                            ],
                            [
                                -87.4410780002253,
                                30.3876979998951
                            ],
                            [
                                -87.4406780001923,
                                30.3914979999591
                            ],
                            [
                                -87.4342779996629,
                                30.3974980002432
                            ],
                            [
                                -87.4295779997234,
                                30.4064979998909
                            ],
                            [
                                -87.422676999777,
                                30.4100979998996
                            ],
                            [
                                -87.4088770004323,
                                30.4087979997152
                            ],
                            [
                                -87.4029770003934,
                                30.4103980001057
                            ],
                            [
                                -87.395676000414,
                                30.4175970002639
                            ],
                            [
                                -87.3833759998458,
                                30.422097000025
                            ],
                            [
                                -87.3693830001573,
                                30.4319479999154
                            ],
                            [
                                -87.3665910003216,
                                30.4366480001512
                            ],
                            [
                                -87.3669389995778,
                                30.440479999716
                            ],
                            [
                                -87.3707680002044,
                                30.4468649999839
                            ],
                            [
                                -87.3811759996639,
                                30.4500970001353
                            ],
                            [
                                -87.3998769996879,
                                30.4509969999324
                            ],
                            [
                                -87.4046770000849,
                                30.4528970001747
                            ],
                            [
                                -87.4078770003495,
                                30.4563960002568
                            ],
                            [
                                -87.4146770000136,
                                30.4572959999575
                            ],
                            [
                                -87.4177769998209,
                                30.4610959996168
                            ],
                            [
                                -87.4250779998004,
                                30.4655959998741
                            ],
                            [
                                -87.4295779997234,
                                30.4705959998813
                            ],
                            [
                                -87.4305779998061,
                                30.4765960002924
                            ],
                            [
                                -87.4355780002196,
                                30.4804959998189
                            ],
                            [
                                -87.4328780004455,
                                30.4842960000501
                            ],
                            [
                                -87.4338779996299,
                                30.4876959996254
                            ],
                            [
                                -87.4283779996241,
                                30.4884959998398
                            ],
                            [
                                -87.4316780003462,
                                30.4928960003445
                            ],
                            [
                                -87.4309779998392,
                                30.4982950000282
                            ],
                            [
                                -87.4338779996299,
                                30.4994950002975
                            ],
                            [
                                -87.4396899998051,
                                30.5066490001999
                            ],
                            [
                                -87.4447139996098,
                                30.5074940002053
                            ],
                            [
                                -87.4477020001623,
                                30.5104579999195
                            ],
                            [
                                -87.4483549999154,
                                30.5137400003502
                            ],
                            [
                                -87.4503709999743,
                                30.5144620002233
                            ],
                            [
                                -87.444523999639,
                                30.5142479997396
                            ],
                            [
                                -87.4449859995963,
                                30.5154910002379
                            ],
                            [
                                -87.4472679997941,
                                30.5150440003422
                            ],
                            [
                                -87.4454529995766,
                                30.5176719997012
                            ],
                            [
                                -87.4472829998627,
                                30.5219730001735
                            ],
                            [
                                -87.4438059995886,
                                30.5239480001935
                            ],
                            [
                                -87.4459980002729,
                                30.5263770003099
                            ],
                            [
                                -87.4479709997758,
                                30.5249449999944
                            ],
                            [
                                -87.4479799999967,
                                30.5266070003206
                            ],
                            [
                                -87.4451029997722,
                                30.5289090001196
                            ],
                            [
                                -87.4436469995799,
                                30.5281379997291
                            ],
                            [
                                -87.4426189999081,
                                30.5297620001617
                            ],
                            [
                                -87.4439010000231,
                                30.5314810002274
                            ],
                            [
                                -87.4417030003893,
                                30.5297469997059
                            ],
                            [
                                -87.4389580004093,
                                30.530291000364
                            ],
                            [
                                -87.4405769999099,
                                30.5315950003609
                            ],
                            [
                                -87.4415689995967,
                                30.5374509996477
                            ],
                            [
                                -87.4353210003017,
                                30.542867000044
                            ],
                            [
                                -87.4367389999608,
                                30.5436889997039
                            ],
                            [
                                -87.4348829997354,
                                30.549434000217
                            ],
                            [
                                -87.4311820000537,
                                30.5500150002172
                            ],
                            [
                                -87.4275540001668,
                                30.5536809998802
                            ],
                            [
                                -87.4253590000078,
                                30.5603110000322
                            ],
                            [
                                -87.4243410003817,
                                30.5596309998974
                            ],
                            [
                                -87.4230080002131,
                                30.5614910000819
                            ],
                            [
                                -87.4219129996958,
                                30.5601270000291
                            ],
                            [
                                -87.4179169995629,
                                30.5622539997086
                            ],
                            [
                                -87.4184670002822,
                                30.5648090000041
                            ],
                            [
                                -87.4161480002745,
                                30.5662930003168
                            ],
                            [
                                -87.4185209998105,
                                30.5701339999516
                            ],
                            [
                                -87.4121909996014,
                                30.5737160002459
                            ],
                            [
                                -87.4126299999926,
                                30.576704999762
                            ],
                            [
                                -87.4080179999165,
                                30.5837010001746
                            ],
                            [
                                -87.4093550002831,
                                30.5858529997424
                            ],
                            [
                                -87.4073149999348,
                                30.5878960001189
                            ],
                            [
                                -87.407304999889,
                                30.5959839997539
                            ],
                            [
                                -87.4052960004011,
                                30.598692000135
                            ],
                            [
                                -87.4075869999213,
                                30.6008649997342
                            ],
                            [
                                -87.4057109996045,
                                30.6014759998183
                            ],
                            [
                                -87.4060860004215,
                                30.6032579996594
                            ],
                            [
                                -87.4035010002751,
                                30.6034170002196
                            ],
                            [
                                -87.4032860001899,
                                30.6053610003065
                            ],
                            [
                                -87.3987700003734,
                                30.6053960003829
                            ],
                            [
                                -87.3949130001577,
                                30.6142659996296
                            ],
                            [
                                -87.3964749997569,
                                30.6171019998417
                            ],
                            [
                                -87.3950660003186,
                                30.6218989997689
                            ],
                            [
                                -87.3960270000432,
                                30.6241349997152
                            ],
                            [
                                -87.3945750000489,
                                30.6242550001809
                            ],
                            [
                                -87.3932939997588,
                                30.627218000145
                            ],
                            [
                                -87.3934100001098,
                                30.6315340003486
                            ],
                            [
                                -87.3956549995992,
                                30.632984000132
                            ],
                            [
                                -87.3940720000837,
                                30.6335020002826
                            ],
                            [
                                -87.3952119999084,
                                30.6360690003347
                            ],
                            [
                                -87.3940240004031,
                                30.6418549998768
                            ],
                            [
                                -87.3957390001631,
                                30.6445620002451
                            ],
                            [
                                -87.3943359996743,
                                30.6456119999952
                            ],
                            [
                                -87.3969889995928,
                                30.6482729998024
                            ],
                            [
                                -87.3959080002175,
                                30.6501590002776
                            ],
                            [
                                -87.3968179998886,
                                30.6548499998834
                            ],
                            [
                                -87.4031029998918,
                                30.6574789997595
                            ],
                            [
                                -87.402688999615,
                                30.6648469998615
                            ],
                            [
                                -87.4057580003584,
                                30.6664389999822
                            ],
                            [
                                -87.405878999834,
                                30.6709109999113
                            ],
                            [
                                -87.40738400043,
                                30.6718159996853
                            ],
                            [
                                -87.4064739998607,
                                30.6751459998336
                            ],
                            [
                                -87.4167199998367,
                                30.6792980000584
                            ],
                            [
                                -87.4190410003925,
                                30.678528000275
                            ],
                            [
                                -87.4202299997228,
                                30.6809320000571
                            ],
                            [
                                -87.4266119998105,
                                30.6830530002126
                            ],
                            [
                                -87.4301359999402,
                                30.6882589996796
                            ],
                            [
                                -87.436400999852,
                                30.6884199996278
                            ],
                            [
                                -87.4403750002435,
                                30.6903619996492
                            ],
                            [
                                -87.4436759998922,
                                30.6945369999716
                            ],
                            [
                                -87.4486610002371,
                                30.696687999633
                            ],
                            [
                                -87.4495659998853,
                                30.6989599999728
                            ],
                            [
                                -87.4513059997597,
                                30.6995910002838
                            ],
                            [
                                -87.4580130004357,
                                30.6963980001906
                            ],
                            [
                                -87.4621900003185,
                                30.7001890003681
                            ],
                            [
                                -87.4680850003344,
                                30.7002080002778
                            ],
                            [
                                -87.4698059999423,
                                30.7050209999354
                            ],
                            [
                                -87.4758049997153,
                                30.7052480000894
                            ],
                            [
                                -87.4744930003614,
                                30.7066539999471
                            ],
                            [
                                -87.4751710002288,
                                30.7090600002128
                            ],
                            [
                                -87.4793120001266,
                                30.7095999997888
                            ],
                            [
                                -87.4802689996533,
                                30.7117869999812
                            ],
                            [
                                -87.4779920003768,
                                30.7132770000931
                            ],
                            [
                                -87.4812779999568,
                                30.7170019996748
                            ],
                            [
                                -87.4836229999037,
                                30.7153100000138
                            ],
                            [
                                -87.4846010002451,
                                30.717077999947
                            ],
                            [
                                -87.4887749997549,
                                30.7176420002577
                            ],
                            [
                                -87.4920930000203,
                                30.7203919999382
                            ],
                            [
                                -87.4975989998739,
                                30.7198550000062
                            ],
                            [
                                -87.5019439999862,
                                30.7212740001054
                            ],
                            [
                                -87.5059509999897,
                                30.7250640003343
                            ],
                            [
                                -87.5051369996798,
                                30.7262880001488
                            ],
                            [
                                -87.5087920002291,
                                30.7280850000604
                            ],
                            [
                                -87.5106330003859,
                                30.7329070002746
                            ],
                            [
                                -87.5149030001551,
                                30.7331520000812
                            ],
                            [
                                -87.5261459998408,
                                30.7404559997468
                            ],
                            [
                                -87.5299770001173,
                                30.7409519996411
                            ],
                            [
                                -87.5295910003279,
                                30.7425810001792
                            ],
                            [
                                -87.5325570002408,
                                30.7431989998161
                            ],
                            [
                                -87.5316619997401,
                                30.7460630000607
                            ],
                            [
                                -87.5353399998556,
                                30.7499990000175
                            ],
                            [
                                -87.5343020001381,
                                30.7538679997418
                            ],
                            [
                                -87.5359850001111,
                                30.7611920002068
                            ],
                            [
                                -87.5460469999641,
                                30.7720559998512
                            ],
                            [
                                -87.5454319998459,
                                30.7793889999541
                            ],
                            [
                                -87.5502849999463,
                                30.781250999926
                            ],
                            [
                                -87.5514709998019,
                                30.7859300000035
                            ],
                            [
                                -87.5590570001867,
                                30.7903750003533
                            ],
                            [
                                -87.5682520000264,
                                30.8001129999323
                            ],
                            [
                                -87.5717519998667,
                                30.8001990003437
                            ],
                            [
                                -87.5795669996821,
                                30.8108919998821
                            ],
                            [
                                -87.6001020001902,
                                30.8205080000088
                            ],
                            [
                                -87.6043140002331,
                                30.8314190001325
                            ],
                            [
                                -87.6152449997493,
                                30.8342099998998
                            ],
                            [
                                -87.6170719996623,
                                30.8403280003687
                            ],
                            [
                                -87.6269760002299,
                                30.8478259998107
                            ],
                            [
                                -87.6250899998673,
                                30.8542339997237
                            ],
                            [
                                -87.6260060002845,
                                30.857437999926
                            ],
                            [
                                -87.6347440001897,
                                30.8656100000312
                            ],
                            [
                                -87.6291029997186,
                                30.8804250003469
                            ],
                            [
                                -87.6202700002772,
                                30.8874040002521
                            ],
                            [
                                -87.621806999762,
                                30.8977229999401
                            ],
                            [
                                -87.6156859997903,
                                30.9016409997872
                            ],
                            [
                                -87.6070530003653,
                                30.9251210000671
                            ],
                            [
                                -87.601678999858,
                                30.9357489997921
                            ],
                            [
                                -87.5980049999404,
                                30.93839000036
                            ],
                            [
                                -87.5941860002579,
                                30.9498710001603
                            ],
                            [
                                -87.5909330002898,
                                30.953513000148
                            ],
                            [
                                -87.5888619999793,
                                30.9657900002209
                            ],
                            [
                                -87.5926499996997,
                                30.9726590000691
                            ],
                            [
                                -87.5935510000482,
                                30.9771030003838
                            ],
                            [
                                -87.5922370001461,
                                30.9799419996635
                            ],
                            [
                                -87.5965040004406,
                                30.9872290002278
                            ],
                            [
                                -87.598829000296,
                                30.9974550000079
                            ],
                            [
                                -87.3015279996881,
                                30.9984379999073
                            ],
                            [
                                -87.2524019997305,
                                30.997936000034
                            ],
                            [
                                -87.2376850001437,
                                30.9963929997555
                            ],
                            [
                                -87.140754999628,
                                30.9995319998086
                            ],
                            [
                                -87.0402289999109,
                                30.9995470003774
                            ],
                            [
                                -86.7235850002948,
                                30.9965689997027
                            ],
                            [
                                -86.7056109996437,
                                30.9944870001126
                            ],
                            [
                                -86.5634359996755,
                                30.9952229996992
                            ],
                            [
                                -86.5201960004112,
                                30.9932580000368
                            ],
                            [
                                -86.3886459996374,
                                30.994180999835
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -82.9862379999155,
                                24.6106500000795
                            ],
                            [
                                -82.9874770003728,
                                24.6254299996986
                            ],
                            [
                                -82.9855290000859,
                                24.6404849997897
                            ],
                            [
                                -82.9777360000118,
                                24.6564820002227
                            ],
                            [
                                -82.9640989999754,
                                24.6705960003087
                            ],
                            [
                                -82.9514360000825,
                                24.6781239996468
                            ],
                            [
                                -82.9328930002422,
                                24.6846680003553
                            ],
                            [
                                -82.8999280003679,
                                24.7177780001782
                            ],
                            [
                                -82.8656200002793,
                                24.726225000402
                            ],
                            [
                                -82.8001769997043,
                                24.7262819997332
                            ],
                            [
                                -82.7665089998482,
                                24.7017640001336
                            ],
                            [
                                -82.7666810002757,
                                24.6679400004049
                            ],
                            [
                                -82.8007990003935,
                                24.616934999782
                            ],
                            [
                                -82.8056330002274,
                                24.6145019998339
                            ],
                            [
                                -82.7980069996595,
                                24.6041649999233
                            ],
                            [
                                -82.8044099996636,
                                24.6042959997666
                            ],
                            [
                                -82.8361569998952,
                                24.5811409998507
                            ],
                            [
                                -82.849053000315,
                                24.5767480001052
                            ],
                            [
                                -82.8611889999533,
                                24.5752839997019
                            ],
                            [
                                -82.8780749997598,
                                24.5759140000439
                            ],
                            [
                                -82.9002780001723,
                                24.5832720001041
                            ],
                            [
                                -82.9282409999832,
                                24.5781380003958
                            ],
                            [
                                -82.9475050002469,
                                24.5793599998045
                            ],
                            [
                                -82.9581599995785,
                                24.5818280001213
                            ],
                            [
                                -82.9748169999545,
                                24.5896049998073
                            ],
                            [
                                -82.9806609999169,
                                24.5961920000296
                            ],
                            [
                                -82.9862379999155,
                                24.6106500000795
                            ]
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 28.4574302,
            "name": "Florida",
            "intptlon": -82.4091478,
            "geo_point_2d": [
                28.4773051103,
                -82.4664653131
            ],
            "geoid": "12",
            "mtfcc": "G4000",
            "region": 3
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -82.4664653131,
                28.4773051103
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "78d368785669f82af5bdf7ca56ade20400465f03",
        "fields": {
            "arealand": "-1862814329",
            "objectid": 24,
            "basename": "Utah",
            "stusab": "UT",
            "statens": "01455989",
            "centlon": -111.6703458,
            "state": "49",
            "gid": 2,
            "centlat": 39.3063102,
            "division": 8,
            "areawater": "-1590438778",
            "oid": "-1897836972",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -114.047025999935,
                            39.9060970002579
                        ],
                        [
                            -114.046098999647,
                            40.401848000238
                        ],
                        [
                            -114.042874000167,
                            40.7950709999927
                        ],
                        [
                            -114.039461000365,
                            41.7188740000175
                        ],
                        [
                            -114.041477000424,
                            41.9938729997
                        ],
                        [
                            -113.873348999958,
                            41.9881819998709
                        ],
                        [
                            -113.249781000272,
                            41.9959689998458
                        ],
                        [
                            -112.705684000111,
                            42.0001509997042
                        ],
                        [
                            -112.173147999654,
                            42.0015149999733
                        ],
                        [
                            -112.153848000304,
                            41.9972260002685
                        ],
                        [
                            -111.046713999584,
                            42.0017019999653
                        ],
                        [
                            -111.046814999867,
                            40.997875000101
                        ],
                        [
                            -110.274111999817,
                            40.9948170000923
                        ],
                        [
                            -110.086964000382,
                            40.9971279997827
                        ],
                        [
                            -109.543697000425,
                            40.9983960000515
                        ],
                        [
                            -109.232505000236,
                            41.0021069997687
                        ],
                        [
                            -109.050025999881,
                            41.000690999837
                        ],
                        [
                            -109.047767000148,
                            40.6098790001952
                        ],
                        [
                            -109.051245000247,
                            40.4415989999939
                        ],
                        [
                            -109.051165999706,
                            39.1426030000478
                        ],
                        [
                            -109.054022000014,
                            38.877161
                        ],
                        [
                            -109.059824000143,
                            38.7147459997869
                        ],
                        [
                            -109.060204000085,
                            38.2782379996979
                        ],
                        [
                            -109.042835000405,
                            38.1662679997321
                        ],
                        [
                            -109.042571999741,
                            38.1570159997971
                        ],
                        [
                            -109.041571999658,
                            37.5621380003295
                        ],
                        [
                            -109.045639999761,
                            37.359723000173
                        ],
                        [
                            -109.045171999956,
                            36.9989769999816
                        ],
                        [
                            -110.46973299974,
                            36.9976570000152
                        ],
                        [
                            -110.490299000211,
                            37.0036279997926
                        ],
                        [
                            -110.625691000233,
                            37.0037249997718
                        ],
                        [
                            -111.150576000008,
                            37.0009499998098
                        ],
                        [
                            -114.050052000122,
                            37.0001909998503
                        ],
                        [
                            -114.052541000009,
                            37.1128809997017
                        ],
                        [
                            -114.051626000315,
                            37.3039050000868
                        ],
                        [
                            -114.052867000423,
                            37.5882699999227
                        ],
                        [
                            -114.05165100043,
                            37.7516500000017
                        ],
                        [
                            -114.048302000202,
                            37.8094240001948
                        ],
                        [
                            -114.049612999731,
                            37.8235409997085
                        ],
                        [
                            -114.050499999836,
                            37.9533410001809
                        ],
                        [
                            -114.049324999851,
                            38.2660599998404
                        ],
                        [
                            -114.050520999752,
                            38.4961169998412
                        ],
                        [
                            -114.047025999935,
                            39.9060970002579
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 39.3349925,
            "name": "Utah",
            "intptlon": -111.6563326,
            "geo_point_2d": [
                39.3235972656,
                -111.678212123
            ],
            "geoid": "49",
            "mtfcc": "G4000",
            "region": 4
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -111.678212123,
                39.3235972656
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "3842af8138bdd516cd071ab6e9f5d2d0d9f76cc9",
        "fields": {
            "arealand": "1270849233",
            "objectid": 45,
            "basename": "Mississippi",
            "stusab": "MS",
            "statens": "01779790",
            "centlon": -89.6553191,
            "state": "28",
            "gid": 32,
            "centlat": 32.6974745,
            "division": 6,
            "areawater": "-364461467",
            "oid": "267651859",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -90.9559740001237,
                            34.1201249999502
                        ],
                        [
                            -90.9593169996052,
                            34.1303489998964
                        ],
                        [
                            -90.9542989996483,
                            34.1384960002503
                        ],
                        [
                            -90.9100070004228,
                            34.1655019998377
                        ],
                        [
                            -90.9020250002028,
                            34.1644180000921
                        ],
                        [
                            -90.8943850002896,
                            34.1609530001533
                        ],
                        [
                            -90.8830730001086,
                            34.1515020002
                        ],
                        [
                            -90.8645799995987,
                            34.1405550003658
                        ],
                        [
                            -90.8471680002607,
                            34.136884000012
                        ],
                        [
                            -90.836098999754,
                            34.1378759998513
                        ],
                        [
                            -90.8225930001371,
                            34.1440539998017
                        ],
                        [
                            -90.8131730001665,
                            34.1528580002305
                        ],
                        [
                            -90.807222999899,
                            34.1637450002661
                        ],
                        [
                            -90.8086849999391,
                            34.1758780001313
                        ],
                        [
                            -90.8123739999253,
                            34.180766999654
                        ],
                        [
                            -90.8165719997245,
                            34.1830229998636
                        ],
                        [
                            -90.8283890004189,
                            34.1847830000881
                        ],
                        [
                            -90.8381950001789,
                            34.183799999765
                        ],
                        [
                            -90.8555949998213,
                            34.1868819997478
                        ],
                        [
                            -90.8696629998529,
                            34.1829710001599
                        ],
                        [
                            -90.8738399997358,
                            34.1832249998378
                        ],
                        [
                            -90.8774829996912,
                            34.1856370000291
                        ],
                        [
                            -90.887886999851,
                            34.1819820001784
                        ],
                        [
                            -90.9117339998784,
                            34.1938640002617
                        ],
                        [
                            -90.9312750001516,
                            34.2130900000017
                        ],
                        [
                            -90.9352190004059,
                            34.2190430002076
                        ],
                        [
                            -90.937042000121,
                            34.2353409999102
                        ],
                        [
                            -90.9289979999767,
                            34.2445329998692
                        ],
                        [
                            -90.9208640003191,
                            34.2465679999969
                        ],
                        [
                            -90.9070809997945,
                            34.2444909999122
                        ],
                        [
                            -90.9042699996923,
                            34.2409699999051
                        ],
                        [
                            -90.9000879997866,
                            34.2296260001089
                        ],
                        [
                            -90.8944439998408,
                            34.2243260001609
                        ],
                        [
                            -90.8790850003717,
                            34.2154329997906
                        ],
                        [
                            -90.8670609999881,
                            34.212144000057
                        ],
                        [
                            -90.8567079998819,
                            34.2115979999356
                        ],
                        [
                            -90.847807999595,
                            34.2065300000545
                        ],
                        [
                            -90.8400089996731,
                            34.2230770001569
                        ],
                        [
                            -90.838807999749,
                            34.2420960000966
                        ],
                        [
                            -90.8329360001976,
                            34.2657370002509
                        ],
                        [
                            -90.829663000138,
                            34.2724240001344
                        ],
                        [
                            -90.8218709998888,
                            34.2774909998346
                        ],
                        [
                            -90.8029239998175,
                            34.2824650003199
                        ],
                        [
                            -90.7651650002923,
                            34.2805240003237
                        ],
                        [
                            -90.7526809996012,
                            34.2892659996931
                        ],
                        [
                            -90.7412529999676,
                            34.3055559997178
                        ],
                        [
                            -90.7406100002603,
                            34.3134690001419
                        ],
                        [
                            -90.7458270004088,
                            34.3267460000918
                        ],
                        [
                            -90.7501060003989,
                            34.3320649997659
                        ],
                        [
                            -90.767107999658,
                            34.3452640000601
                        ],
                        [
                            -90.768097999695,
                            34.3558840003014
                        ],
                        [
                            -90.7670609998024,
                            34.3602709996809
                        ],
                        [
                            -90.76414299957,
                            34.363396000287
                        ],
                        [
                            -90.7561970002334,
                            34.3672560000429
                        ],
                        [
                            -90.7501070002237,
                            34.3679190002992
                        ],
                        [
                            -90.724908999586,
                            34.3636589997586
                        ],
                        [
                            -90.7120880004076,
                            34.3638050002255
                        ],
                        [
                            -90.6766599996874,
                            34.3714109997882
                        ],
                        [
                            -90.68161599972,
                            34.3529039996321
                        ],
                        [
                            -90.6915510002497,
                            34.338617999685
                        ],
                        [
                            -90.6936860001344,
                            34.3296799997412
                        ],
                        [
                            -90.6931289997424,
                            34.3225699999658
                        ],
                        [
                            -90.6860029996651,
                            34.3157709996579
                        ],
                        [
                            -90.6780969996131,
                            34.3130309999165
                        ],
                        [
                            -90.6693429998144,
                            34.3130200000389
                        ],
                        [
                            -90.6629609997267,
                            34.3147789997809
                        ],
                        [
                            -90.6574880003835,
                            34.3222309997173
                        ],
                        [
                            -90.6573710002076,
                            34.327286999997
                        ],
                        [
                            -90.6668639999732,
                            34.3485509998511
                        ],
                        [
                            -90.6667869999803,
                            34.3558170001859
                        ],
                        [
                            -90.6553500001258,
                            34.3718400000808
                        ],
                        [
                            -90.6585400003447,
                            34.3757050002304
                        ],
                        [
                            -90.6349460000373,
                            34.3863620000452
                        ],
                        [
                            -90.613947999747,
                            34.3907210000708
                        ],
                        [
                            -90.5806809997489,
                            34.4105510003422
                        ],
                        [
                            -90.5711450003257,
                            34.4203219996649
                        ],
                        [
                            -90.5665049997623,
                            34.42952799965
                        ],
                        [
                            -90.5658090003515,
                            34.4354000002608
                        ],
                        [
                            -90.5732319996314,
                            34.4509290001949
                        ],
                        [
                            -90.58372100018,
                            34.4588249998827
                        ],
                        [
                            -90.5860109998753,
                            34.462345999929
                        ],
                        [
                            -90.5896599996785,
                            34.4801009999035
                        ],
                        [
                            -90.5889419996282,
                            34.4910969996305
                        ],
                        [
                            -90.5801150000345,
                            34.5145370003125
                        ],
                        [
                            -90.5693469998266,
                            34.5248669997859
                        ],
                        [
                            -90.5552760003203,
                            34.5310120000374
                        ],
                        [
                            -90.5457280003032,
                            34.5377500000199
                        ],
                        [
                            -90.5407360002855,
                            34.5480850002575
                        ],
                        [
                            -90.5458909996115,
                            34.5632569999908
                        ],
                        [
                            -90.5535640000349,
                            34.5728620001324
                        ],
                        [
                            -90.5701329996491,
                            34.5874569998568
                        ],
                        [
                            -90.5816930004255,
                            34.6042269998296
                        ],
                        [
                            -90.5872240003934,
                            34.6157320003251
                        ],
                        [
                            -90.5882550004382,
                            34.6266159999184
                        ],
                        [
                            -90.583019999848,
                            34.6426789998599
                        ],
                        [
                            -90.5863359995653,
                            34.6516890003384
                        ],
                        [
                            -90.5884189995714,
                            34.6709629997118
                        ],
                        [
                            -90.5787450000559,
                            34.6838439997649
                        ],
                        [
                            -90.573106000133,
                            34.6864250000016
                        ],
                        [
                            -90.5673140000493,
                            34.6935219996913
                        ],
                        [
                            -90.5571480004392,
                            34.6978229999887
                        ],
                        [
                            -90.5498569996071,
                            34.6954780002524
                        ],
                        [
                            -90.5402879996736,
                            34.6854390002543
                        ],
                        [
                            -90.5377989997866,
                            34.6749839997224
                        ],
                        [
                            -90.5394009995688,
                            34.6709109997406
                        ],
                        [
                            -90.550157999906,
                            34.6634449999126
                        ],
                        [
                            -90.5526419997701,
                            34.6597069998716
                        ],
                        [
                            -90.5551039998928,
                            34.6462359998981
                        ],
                        [
                            -90.5541289999245,
                            34.6408709998485
                        ],
                        [
                            -90.5502779995566,
                            34.6345380003617
                        ],
                        [
                            -90.5454040004381,
                            34.6303760001817
                        ],
                        [
                            -90.5371650003001,
                            34.627767000082
                        ],
                        [
                            -90.5278680003532,
                            34.627911999947
                        ],
                        [
                            -90.5194779997042,
                            34.6299010000226
                        ],
                        [
                            -90.5081000002993,
                            34.6366819999688
                        ],
                        [
                            -90.4944780003315,
                            34.6499339999445
                        ],
                        [
                            -90.4797180001887,
                            34.6599339997393
                        ],
                        [
                            -90.4660409999694,
                            34.6743119997985
                        ],
                        [
                            -90.4625519999997,
                            34.6875760001001
                        ],
                        [
                            -90.4686029996513,
                            34.6972550002345
                        ],
                        [
                            -90.4770309999351,
                            34.7011740000556
                        ],
                        [
                            -90.4932179999578,
                            34.7009410003494
                        ],
                        [
                            -90.5220360000864,
                            34.6966110002233
                        ],
                        [
                            -90.5389570000531,
                            34.6987769998086
                        ],
                        [
                            -90.5460529999932,
                            34.7020760000439
                        ],
                        [
                            -90.5656469999698,
                            34.721054999858
                        ],
                        [
                            -90.5680809996051,
                            34.7248020000269
                        ],
                        [
                            -90.567801000121,
                            34.7306899998886
                        ],
                        [
                            -90.5635439998722,
                            34.7386710003447
                        ],
                        [
                            -90.559895000069,
                            34.740788000175
                        ],
                        [
                            -90.5399949997706,
                            34.7448109997201
                        ],
                        [
                            -90.535407999809,
                            34.7500619999972
                        ],
                        [
                            -90.5462250004206,
                            34.7734430003274
                        ],
                        [
                            -90.5476120001176,
                            34.784588999727
                        ],
                        [
                            -90.5422600002499,
                            34.7936249996832
                        ],
                        [
                            -90.5365099999989,
                            34.7985720002841
                        ],
                        [
                            -90.5228920002291,
                            34.8022649997099
                        ],
                        [
                            -90.5147080003428,
                            34.8017659999385
                        ],
                        [
                            -90.5036799998439,
                            34.7801410000346
                        ],
                        [
                            -90.5009890002906,
                            34.7711920003657
                        ],
                        [
                            -90.505502999559,
                            34.7645639996313
                        ],
                        [
                            -90.5208410000101,
                            34.7555360002326
                        ],
                        [
                            -90.5245169995775,
                            34.7513679999632
                        ],
                        [
                            -90.5243180002842,
                            34.7391350000749
                        ],
                        [
                            -90.5182990004196,
                            34.7305810000794
                        ],
                        [
                            -90.501667000158,
                            34.7242360003248
                        ],
                        [
                            -90.4888650003479,
                            34.7237310000314
                        ],
                        [
                            -90.4698970003601,
                            34.727030000295
                        ],
                        [
                            -90.454968000163,
                            34.7355570002757
                        ],
                        [
                            -90.4517579998526,
                            34.741904000217
                        ],
                        [
                            -90.4517510001799,
                            34.7500990000758
                        ],
                        [
                            -90.4567120002354,
                            34.7607540000587
                        ],
                        [
                            -90.4704110001961,
                            34.7808769999021
                        ],
                        [
                            -90.4745899997287,
                            34.7931999998891
                        ],
                        [
                            -90.4722799999419,
                            34.8024649998953
                        ],
                        [
                            -90.4653670002999,
                            34.8105920001708
                        ],
                        [
                            -90.459023999672,
                            34.8144400000927
                        ],
                        [
                            -90.4569049996809,
                            34.823244999697
                        ],
                        [
                            -90.463783999886,
                            34.8349080002537
                        ],
                        [
                            -90.4819629996782,
                            34.8578160003637
                        ],
                        [
                            -90.4850380002694,
                            34.8692460002793
                        ],
                        [
                            -90.4839710002395,
                            34.8771760000086
                        ],
                        [
                            -90.4798639997786,
                            34.8832729999402
                        ],
                        [
                            -90.4661539999473,
                            34.8909889996565
                        ],
                        [
                            -90.4539120000038,
                            34.8911219998824
                        ],
                        [
                            -90.4412519995856,
                            34.8863150001506
                        ],
                        [
                            -90.4365600000419,
                            34.8827320001037
                        ],
                        [
                            -90.4289770000301,
                            34.8671599999509
                        ],
                        [
                            -90.4317250003832,
                            34.8589140000748
                        ],
                        [
                            -90.4294380001626,
                            34.84310199972
                        ],
                        [
                            -90.4223499997201,
                            34.8336730000598
                        ],
                        [
                            -90.4105510003657,
                            34.8320590000233
                        ],
                        [
                            -90.3798369998287,
                            34.8459309998437
                        ],
                        [
                            -90.3521739995935,
                            34.853195999776
                        ],
                        [
                            -90.340380000262,
                            34.8603569999599
                        ],
                        [
                            -90.3322599999497,
                            34.8525060000097
                        ],
                        [
                            -90.3230700001329,
                            34.8464330002786
                        ],
                        [
                            -90.3127019999582,
                            34.8440449997529
                        ],
                        [
                            -90.3073900002734,
                            34.846189999838
                        ],
                        [
                            -90.3039439999614,
                            34.8505169996356
                        ],
                        [
                            -90.3025229999293,
                            34.856470999962
                        ],
                        [
                            -90.313476000085,
                            34.8716980001168
                        ],
                        [
                            -90.3065550000471,
                            34.8775139999605
                        ],
                        [
                            -90.279363999815,
                            34.8900769996759
                        ],
                        [
                            -90.2500949995997,
                            34.9073200001296
                        ],
                        [
                            -90.2447310000364,
                            34.9210259997599
                        ],
                        [
                            -90.2444759997683,
                            34.9375960003134
                        ],
                        [
                            -90.2478319996685,
                            34.9479159999441
                        ],
                        [
                            -90.2539690004321,
                            34.9549879996557
                        ],
                        [
                            -90.2775019997419,
                            34.9647760003443
                        ],
                        [
                            -90.2872389999049,
                            34.9725310002275
                        ],
                        [
                            -90.296422000049,
                            34.9763460001564
                        ],
                        [
                            -90.3044250001854,
                            34.9849389997257
                        ],
                        [
                            -90.3092890001565,
                            34.9956940002954
                        ],
                        [
                            -89.8513840004412,
                            34.994187999801
                        ],
                        [
                            -89.6442820002819,
                            34.9952930002761
                        ],
                        [
                            -89.4349540000014,
                            34.9937539999593
                        ],
                        [
                            -88.8939009995718,
                            34.9959200001214
                        ],
                        [
                            -88.2000300004361,
                            34.9956400002642
                        ],
                        [
                            -88.1923279997003,
                            34.9815570000076
                        ],
                        [
                            -88.17253899973,
                            34.9569000002504
                        ],
                        [
                            -88.1614369996113,
                            34.9320440002347
                        ],
                        [
                            -88.1524130003741,
                            34.9197409996967
                        ],
                        [
                            -88.1319630002549,
                            34.9049580003041
                        ],
                        [
                            -88.0982190002309,
                            34.8927150002034
                        ],
                        [
                            -88.1184070004093,
                            34.7242920002829
                        ],
                        [
                            -88.1352599997056,
                            34.6168060003079
                        ],
                        [
                            -88.1514369996826,
                            34.5000959997784
                        ],
                        [
                            -88.1866670000361,
                            34.2209519998043
                        ],
                        [
                            -88.187621000088,
                            34.2047659997653
                        ],
                        [
                            -88.1906999999789,
                            34.1900189996828
                        ],
                        [
                            -88.2242809997962,
                            33.9270420003302
                        ],
                        [
                            -88.2430250003763,
                            33.7956790000812
                        ],
                        [
                            -88.312535000331,
                            33.2209230002765
                        ],
                        [
                            -88.3245689998621,
                            33.1332459999074
                        ],
                        [
                            -88.4038419999191,
                            32.4487730001084
                        ],
                        [
                            -88.4732270002003,
                            31.8938560002534
                        ],
                        [
                            -88.4649079996964,
                            31.7054760000187
                        ],
                        [
                            -88.4387810000194,
                            31.2526510003041
                        ],
                        [
                            -88.418632000199,
                            30.8665700002027
                        ],
                        [
                            -88.406919999985,
                            30.6302049999842
                        ],
                        [
                            -88.4049340000633,
                            30.5666329996794
                        ],
                        [
                            -88.4059860000245,
                            30.5649500001575
                        ],
                        [
                            -88.4041769996548,
                            30.559512000022
                        ],
                        [
                            -88.4053659998834,
                            30.5539409998488
                        ],
                        [
                            -88.4026119996826,
                            30.5397069998949
                        ],
                        [
                            -88.4034290003655,
                            30.5305130003691
                        ],
                        [
                            -88.3844310002405,
                            30.1585430001974
                        ],
                        [
                            -88.4041559997384,
                            30.1521759997972
                        ],
                        [
                            -88.4193839996863,
                            30.1492340000992
                        ],
                        [
                            -88.450028999728,
                            30.1460959998449
                        ],
                        [
                            -88.4612830001826,
                            30.1466859999628
                        ],
                        [
                            -88.479933999978,
                            30.1499209997289
                        ],
                        [
                            -88.5097859996262,
                            30.157943000015
                        ],
                        [
                            -88.526012000007,
                            30.1642660000353
                        ],
                        [
                            -88.5458550004039,
                            30.1766529996395
                        ],
                        [
                            -88.56787499962,
                            30.1704529999349
                        ],
                        [
                            -88.5866300000707,
                            30.1691089999012
                        ],
                        [
                            -88.6038809997502,
                            30.1706090003119
                        ],
                        [
                            -88.6373310000463,
                            30.1769360003676
                        ],
                        [
                            -88.6774300003938,
                            30.1797119996381
                        ],
                        [
                            -88.6910170002015,
                            30.1824660000458
                        ],
                        [
                            -88.7173859997278,
                            30.190874999896
                        ],
                        [
                            -88.7401039998011,
                            30.1880769997836
                        ],
                        [
                            -88.7652870003702,
                            30.1899410002831
                        ],
                        [
                            -88.7781589996023,
                            30.1932920000743
                        ],
                        [
                            -88.8006359996511,
                            30.2023849999341
                        ],
                        [
                            -88.8113839997675,
                            30.2087420001434
                        ],
                        [
                            -88.8213870000693,
                            30.2173700002839
                        ],
                        [
                            -88.8368340003003,
                            30.2025840002967
                        ],
                        [
                            -88.8503900001458,
                            30.1922820002104
                        ],
                        [
                            -88.8750270001937,
                            30.1789969999332
                        ],
                        [
                            -88.9003300004134,
                            30.1699799997218
                        ],
                        [
                            -88.889029999928,
                            30.1398450000754
                        ],
                        [
                            -89.0964760000438,
                            30.1658289999843
                        ],
                        [
                            -89.1836690003965,
                            30.2121550000719
                        ],
                        [
                            -89.2609770004482,
                            30.1819980001278
                        ],
                        [
                            -89.2883939997378,
                            30.1784699998626
                        ],
                        [
                            -89.3061349998621,
                            30.1779409996475
                        ],
                        [
                            -89.4167109997368,
                            30.1776389999761
                        ],
                        [
                            -89.4539539997762,
                            30.1662280000271
                        ],
                        [
                            -89.4949729998404,
                            30.1607500003257
                        ],
                        [
                            -89.5147700002066,
                            30.1617620003276
                        ],
                        [
                            -89.5279390004379,
                            30.1887700003633
                        ],
                        [
                            -89.5348350003614,
                            30.1953830002354
                        ],
                        [
                            -89.54483699994,
                            30.1942990002995
                        ],
                        [
                            -89.5546259999815,
                            30.1859109997859
                        ],
                        [
                            -89.5661080000418,
                            30.1812170003182
                        ],
                        [
                            -89.5709069997156,
                            30.1805190000087
                        ],
                        [
                            -89.5768050001045,
                            30.1832250003628
                        ],
                        [
                            -89.5856929997976,
                            30.1922409996474
                        ],
                        [
                            -89.5887829995591,
                            30.2004519998741
                        ],
                        [
                            -89.5965720003335,
                            30.2119650000404
                        ],
                        [
                            -89.601165000143,
                            30.216268000308
                        ],
                        [
                            -89.6110450004211,
                            30.2185699997337
                        ],
                        [
                            -89.6157369999647,
                            30.2233709999322
                        ],
                        [
                            -89.6169279998432,
                            30.2276720001668
                        ],
                        [
                            -89.614043999946,
                            30.2447729998233
                        ],
                        [
                            -89.6160340000657,
                            30.2475709996268
                        ],
                        [
                            -89.6267539996946,
                            30.2518710000985
                        ],
                        [
                            -89.6316140003661,
                            30.2570130000945
                        ],
                        [
                            -89.6310500003015,
                            30.2617960003625
                        ],
                        [
                            -89.6253050000733,
                            30.263531999933
                        ],
                        [
                            -89.6258919997042,
                            30.2659129997547
                        ],
                        [
                            -89.6323159996246,
                            30.2686039997796
                        ],
                        [
                            -89.630400999848,
                            30.2766790002273
                        ],
                        [
                            -89.6312939998006,
                            30.2797689996372
                        ],
                        [
                            -89.6375269999253,
                            30.2850829997798
                        ],
                        [
                            -89.6385040004418,
                            30.2881220002015
                        ],
                        [
                            -89.6433709998876,
                            30.2877190000359
                        ],
                        [
                            -89.6471779998746,
                            30.2893669996446
                        ],
                        [
                            -89.6469650003376,
                            30.291810000062
                        ],
                        [
                            -89.640434000287,
                            30.29550299985
                        ],
                        [
                            -89.6416469999068,
                            30.3038249996803
                        ],
                        [
                            -89.6403420002255,
                            30.3067810003847
                        ],
                        [
                            -89.6341619998042,
                            30.3083049996312
                        ],
                        [
                            -89.6340709995676,
                            30.3099949999222
                        ],
                        [
                            -89.6315990002974,
                            30.3093789998127
                        ],
                        [
                            -89.62618999963,
                            30.3142910002009
                        ],
                        [
                            -89.6339640003358,
                            30.321300000185
                        ],
                        [
                            -89.6295999999569,
                            30.3215630002991
                        ],
                        [
                            -89.6305919996437,
                            30.324797999962
                        ],
                        [
                            -89.6288140001345,
                            30.3264230000333
                        ],
                        [
                            -89.6326980001143,
                            30.3279600001028
                        ],
                        [
                            -89.629591999561,
                            30.3389599998347
                        ],
                        [
                            -89.6331399999802,
                            30.3402800002408
                        ],
                        [
                            -89.636299000237,
                            30.3439730001063
                        ],
                        [
                            -89.6369319998986,
                            30.3476179998397
                        ],
                        [
                            -89.6412960002775,
                            30.3461549999941
                        ],
                        [
                            -89.6417540000369,
                            30.348851999814
                        ],
                        [
                            -89.6452019999987,
                            30.3481249999879
                        ],
                        [
                            -89.6460260003543,
                            30.3520429997353
                        ],
                        [
                            -89.651518999789,
                            30.3561299998441
                        ],
                        [
                            -89.6532589996634,
                            30.3542900000173
                        ],
                        [
                            -89.6548460002752,
                            30.3565750002126
                        ],
                        [
                            -89.6572489999485,
                            30.3566320003348
                        ],
                        [
                            -89.6587749995626,
                            30.3633510002165
                        ],
                        [
                            -89.6611250004307,
                            30.3642249996457
                        ],
                        [
                            -89.6585379997362,
                            30.3672310000974
                        ],
                        [
                            -89.6587980000272,
                            30.369583000287
                        ],
                        [
                            -89.6626119996868,
                            30.3714400003762
                        ],
                        [
                            -89.6601789998764,
                            30.3734180001475
                        ],
                        [
                            -89.6645809998901,
                            30.374883000227
                        ],
                        [
                            -89.6634140003011,
                            30.3764990003113
                        ],
                        [
                            -89.6661979997409,
                            30.3772409999832
                        ],
                        [
                            -89.6641759998342,
                            30.3791689996134
                        ],
                        [
                            -89.6700590001545,
                            30.3822020002075
                        ],
                        [
                            -89.6737890001486,
                            30.3928579997595
                        ],
                        [
                            -89.6766810004417,
                            30.3938689999962
                        ],
                        [
                            -89.6791450002143,
                            30.402364000006
                        ],
                        [
                            -89.6838369997579,
                            30.4058200001423
                        ],
                        [
                            -89.6841730002169,
                            30.4086850000346
                        ],
                        [
                            -89.682661999773,
                            30.410387999933
                        ],
                        [
                            -89.6844550002492,
                            30.4122879996493
                        ],
                        [
                            -89.6823119999687,
                            30.4131430002773
                        ],
                        [
                            -89.6799920001361,
                            30.4116489998513
                        ],
                        [
                            -89.6785500001875,
                            30.4137949998286
                        ],
                        [
                            -89.6794730002773,
                            30.4167970003755
                        ],
                        [
                            -89.6774820003328,
                            30.4179609997698
                        ],
                        [
                            -89.6797939997694,
                            30.4186740003713
                        ],
                        [
                            -89.6810449999223,
                            30.4233910002307
                        ],
                        [
                            -89.678863000182,
                            30.4261560000081
                        ],
                        [
                            -89.6808919997614,
                            30.4272990000991
                        ],
                        [
                            -89.6803129996282,
                            30.433373999831
                        ],
                        [
                            -89.6839820004212,
                            30.433940000305
                        ],
                        [
                            -89.6850350002073,
                            30.4394509996536
                        ],
                        [
                            -89.6826009996737,
                            30.4426930000697
                        ],
                        [
                            -89.6826400000317,
                            30.4455280002279
                        ],
                        [
                            -89.6850119997428,
                            30.4466109997626
                        ],
                        [
                            -89.683403000288,
                            30.4515360000978
                        ],
                        [
                            -89.6863399998886,
                            30.4547509996137
                        ],
                        [
                            -89.6900780002786,
                            30.4532350003417
                        ],
                        [
                            -89.6900349997226,
                            30.4593330003607
                        ],
                        [
                            -89.6952839996581,
                            30.4628700001117
                        ],
                        [
                            -89.7013850004367,
                            30.4653640003767
                        ],
                        [
                            -89.703567000177,
                            30.464093999898
                        ],
                        [
                            -89.7058479996515,
                            30.4649519997339
                        ],
                        [
                            -89.7042919999001,
                            30.4668589998768
                        ],
                        [
                            -89.7050130003235,
                            30.4708669996497
                        ],
                        [
                            -89.7087630004091,
                            30.4732719998332
                        ],
                        [
                            -89.7081900001237,
                            30.4759360003758
                        ],
                        [
                            -89.7099070004318,
                            30.4782159996993
                        ],
                        [
                            -89.7155449996315,
                            30.4776849997797
                        ],
                        [
                            -89.7192000001808,
                            30.4830680002358
                        ],
                        [
                            -89.7220299996513,
                            30.4814349997775
                        ],
                        [
                            -89.72357899973,
                            30.482302999877
                        ],
                        [
                            -89.7208320001002,
                            30.4870159997685
                        ],
                        [
                            -89.7248220003853,
                            30.4921289996157
                        ],
                        [
                            -89.7264929997644,
                            30.4926490001139
                        ],
                        [
                            -89.7272030003172,
                            30.4905130000393
                        ],
                        [
                            -89.7296520000212,
                            30.4900210002709
                        ],
                        [
                            -89.7298269999234,
                            30.4919189999665
                        ],
                        [
                            -89.7334940001683,
                            30.4928110003265
                        ],
                        [
                            -89.7331019996328,
                            30.4960039997071
                        ],
                        [
                            -89.7346189999244,
                            30.4945969999001
                        ],
                        [
                            -89.7394369998648,
                            30.495792999837
                        ],
                        [
                            -89.7448990002357,
                            30.4923380002541
                        ],
                        [
                            -89.7473300003963,
                            30.4927219998273
                        ],
                        [
                            -89.7429459999259,
                            30.4951430000462
                        ],
                        [
                            -89.7421480004079,
                            30.4973140002412
                        ],
                        [
                            -89.7431009997366,
                            30.498923999636
                        ],
                        [
                            -89.7455250002245,
                            30.4982359997099
                        ],
                        [
                            -89.7466960000115,
                            30.502950000344
                        ],
                        [
                            -89.7473749997038,
                            30.5014550001444
                        ],
                        [
                            -89.74956499984,
                            30.5023769998362
                        ],
                        [
                            -89.7574840003108,
                            30.4995529998206
                        ],
                        [
                            -89.7572320004157,
                            30.5016760002147
                        ],
                        [
                            -89.752638999708,
                            30.5024329999591
                        ],
                        [
                            -89.7540129998845,
                            30.5041140002447
                        ],
                        [
                            -89.7528069999375,
                            30.5076959999297
                        ],
                        [
                            -89.7574990003794,
                            30.5050159999917
                        ],
                        [
                            -89.7585599996632,
                            30.5059960003045
                        ],
                        [
                            -89.7544509995524,
                            30.509773000101
                        ],
                        [
                            -89.7571329997832,
                            30.5093510000487
                        ],
                        [
                            -89.7606729998065,
                            30.5120199999841
                        ],
                        [
                            -89.7587730000985,
                            30.5129069999571
                        ],
                        [
                            -89.7574590001965,
                            30.5185990003518
                        ],
                        [
                            -89.7598800003113,
                            30.5176309998489
                        ],
                        [
                            -89.7603070001086,
                            30.5155560001242
                        ],
                        [
                            -89.7638160001698,
                            30.5156590002957
                        ],
                        [
                            -89.7678139999524,
                            30.513185000329
                        ],
                        [
                            -89.7687980001416,
                            30.5183730000865
                        ],
                        [
                            -89.7730939998501,
                            30.5207900001774
                        ],
                        [
                            -89.7701949998844,
                            30.5214819999411
                        ],
                        [
                            -89.7709270001785,
                            30.5237170002967
                        ],
                        [
                            -89.7694390001991,
                            30.5250510003573
                        ],
                        [
                            -89.7716820000388,
                            30.5263759997805
                        ],
                        [
                            -89.7690500000367,
                            30.5291779997394
                        ],
                        [
                            -89.7773660001675,
                            30.5329950001036
                        ],
                        [
                            -89.774084999712,
                            30.5377919998797
                        ],
                        [
                            -89.7771600003032,
                            30.5395159998554
                        ],
                        [
                            -89.7791589997454,
                            30.5440290001209
                        ],
                        [
                            -89.789680000081,
                            30.5446560002048
                        ],
                        [
                            -89.8039189998169,
                            30.5493490001212
                        ],
                        [
                            -89.8029859996813,
                            30.5509469999914
                        ],
                        [
                            -89.7996289999561,
                            30.5480339999415
                        ],
                        [
                            -89.7967219995945,
                            30.5494840001021
                        ],
                        [
                            -89.792204999953,
                            30.5483450003385
                        ],
                        [
                            -89.7917780001557,
                            30.5519879997174
                        ],
                        [
                            -89.7944560001886,
                            30.5565519999903
                        ],
                        [
                            -89.8030309998872,
                            30.5578929999475
                        ],
                        [
                            -89.8039770004415,
                            30.5605219998458
                        ],
                        [
                            -89.8016659999315,
                            30.563738999798
                        ],
                        [
                            -89.7967140000968,
                            30.5615190001317
                        ],
                        [
                            -89.791655999957,
                            30.562611999794
                        ],
                        [
                            -89.7868649997808,
                            30.5690530002554
                        ],
                        [
                            -89.7912299999846,
                            30.5699469997734
                        ],
                        [
                            -89.791435000024,
                            30.5654640001338
                        ],
                        [
                            -89.79301400024,
                            30.5641469998849
                        ],
                        [
                            -89.7955929996403,
                            30.5653200003429
                        ],
                        [
                            -89.7935709997336,
                            30.5676870003408
                        ],
                        [
                            -89.7953259996766,
                            30.5700949999732
                        ],
                        [
                            -89.8079519997597,
                            30.5676529998994
                        ],
                        [
                            -89.8068610003387,
                            30.5724519997008
                        ],
                        [
                            -89.8093410000048,
                            30.5800430000794
                        ],
                        [
                            -89.8130790003948,
                            30.5817730002654
                        ],
                        [
                            -89.8156960003283,
                            30.5855399998623
                        ],
                        [
                            -89.8101419998959,
                            30.5845199998442
                        ],
                        [
                            -89.8070219999971,
                            30.587098999788
                        ],
                        [
                            -89.8114309996836,
                            30.586641000317
                        ],
                        [
                            -89.8123470001007,
                            30.5921659999413
                        ],
                        [
                            -89.8187710000211,
                            30.5928800002028
                        ],
                        [
                            -89.8198010002411,
                            30.5967839998886
                        ],
                        [
                            -89.8144300001068,
                            30.5965040003222
                        ],
                        [
                            -89.8140409999444,
                            30.5985870003338
                        ],
                        [
                            -89.8225629999395,
                            30.6052429998234
                        ],
                        [
                            -89.8169469995828,
                            30.6051290003864
                        ],
                        [
                            -89.8139189997456,
                            30.6077170003467
                        ],
                        [
                            -89.8232569997004,
                            30.6077109998819
                        ],
                        [
                            -89.8214259995894,
                            30.6197939996577
                        ],
                        [
                            -89.8307109998408,
                            30.6172210001565
                        ],
                        [
                            -89.8301839995861,
                            30.6220810001764
                        ],
                        [
                            -89.8236690003274,
                            30.6222199998834
                        ],
                        [
                            -89.8202049995738,
                            30.629124999633
                        ],
                        [
                            -89.8200680002048,
                            30.6328079998137
                        ],
                        [
                            -89.8140939996478,
                            30.6364250002773
                        ],
                        [
                            -89.8140019995863,
                            30.6382460003498
                        ],
                        [
                            -89.8229520001019,
                            30.6390779997668
                        ],
                        [
                            -89.8235470001287,
                            30.642588999882
                        ],
                        [
                            -89.8206549998356,
                            30.6460930003688
                        ],
                        [
                            -89.8257439999376,
                            30.645273000377
                        ],
                        [
                            -89.8247070000449,
                            30.6490259997181
                        ],
                        [
                            -89.8303140001808,
                            30.6507659998365
                        ],
                        [
                            -89.8332059995756,
                            30.6575049999914
                        ],
                        [
                            -89.8359980003095,
                            30.6572810002604
                        ],
                        [
                            -89.8383480002793,
                            30.6529359998235
                        ],
                        [
                            -89.8407209998153,
                            30.6521700001612
                        ],
                        [
                            -89.840843000014,
                            30.6608159996566
                        ],
                        [
                            -89.8460839995537,
                            30.6599860001135
                        ],
                        [
                            -89.8522029998756,
                            30.6616840001023
                        ],
                        [
                            -89.8493189999785,
                            30.6654009999882
                        ],
                        [
                            -89.8464200000127,
                            30.6631829999611
                        ],
                        [
                            -89.8432459996873,
                            30.6637359996974
                        ],
                        [
                            -89.8378350002683,
                            30.6719169996491
                        ],
                        [
                            -89.8395919998612,
                            30.673956000368
                        ],
                        [
                            -89.8432180000982,
                            30.6689819997985
                        ],
                        [
                            -89.8472009998123,
                            30.6700379998019
                        ],
                        [
                            -89.8421269997789,
                            30.6764559999137
                        ],
                        [
                            -89.8371220002408,
                            30.677905000024
                        ],
                        [
                            -89.8376839997573,
                            30.6793680000152
                        ],
                        [
                            -89.8406499996702,
                            30.6789090000913
                        ],
                        [
                            -89.8422649998711,
                            30.680284000072
                        ],
                        [
                            -89.8373559996942,
                            30.6877980002574
                        ],
                        [
                            -89.8448869998275,
                            30.6870309999736
                        ],
                        [
                            -89.845019000072,
                            30.6906250000444
                        ],
                        [
                            -89.8367969996525,
                            30.6905729996324
                        ],
                        [
                            -89.8352939996045,
                            30.6917930000151
                        ],
                        [
                            -89.8383239999899,
                            30.6951209996842
                        ],
                        [
                            -89.8358529996463,
                            30.6990720002435
                        ],
                        [
                            -89.8380650004221,
                            30.7040360002426
                        ],
                        [
                            -89.8436049997125,
                            30.7025109998304
                        ],
                        [
                            -89.8466550001893,
                            30.7057260002767
                        ],
                        [
                            -89.8401109997199,
                            30.7118180002922
                        ],
                        [
                            -89.8425290003601,
                            30.7204180002214
                        ],
                        [
                            -89.8394339996774,
                            30.7206899996153
                        ],
                        [
                            -89.8370720000121,
                            30.7166019998566
                        ],
                        [
                            -89.8309810001776,
                            30.7155289997022
                        ],
                        [
                            -89.830007999859,
                            30.7168180001499
                        ],
                        [
                            -89.8312720004307,
                            30.7225879998582
                        ],
                        [
                            -89.8256340003327,
                            30.7273740002396
                        ],
                        [
                            -89.8270439995959,
                            30.7287790003251
                        ],
                        [
                            -89.8363310003955,
                            30.7271969996579
                        ],
                        [
                            -89.8358769999357,
                            30.7296340001256
                        ],
                        [
                            -89.8291890004246,
                            30.7305050002851
                        ],
                        [
                            -89.8277189999886,
                            30.7321280001579
                        ],
                        [
                            -89.8285840003521,
                            30.733215000019
                        ],
                        [
                            -89.834508999607,
                            30.7322649999796
                        ],
                        [
                            -89.8366979999183,
                            30.7350630002054
                        ],
                        [
                            -89.8329319999392,
                            30.7371020003029
                        ],
                        [
                            -89.8192029998413,
                            30.7368929999691
                        ],
                        [
                            -89.8160750004449,
                            30.7389079997822
                        ],
                        [
                            -89.8202899999626,
                            30.7406939998715
                        ],
                        [
                            -89.8264589996149,
                            30.7373620002147
                        ],
                        [
                            -89.8286920003072,
                            30.7380890002089
                        ],
                        [
                            -89.8251410004132,
                            30.7418540001086
                        ],
                        [
                            -89.8257740000748,
                            30.7473049996214
                        ],
                        [
                            -89.8224590001824,
                            30.7546280000955
                        ],
                        [
                            -89.8242269996459,
                            30.7551579997478
                        ],
                        [
                            -89.8293490002582,
                            30.7513179998181
                        ],
                        [
                            -89.8331670001158,
                            30.7533140002729
                        ],
                        [
                            -89.832649000082,
                            30.755993999646
                        ],
                        [
                            -89.8272520000084,
                            30.7559049999716
                        ],
                        [
                            -89.8256359999825,
                            30.7583849999256
                        ],
                        [
                            -89.8298730001398,
                            30.7609460002758
                        ],
                        [
                            -89.8351239997252,
                            30.7710920002848
                        ],
                        [
                            -89.8331620000929,
                            30.7716760001408
                        ],
                        [
                            -89.8315369998463,
                            30.7676100000788
                        ],
                        [
                            -89.8287559998813,
                            30.7687360002899
                        ],
                        [
                            -89.8274589996976,
                            30.7734669998457
                        ],
                        [
                            -89.8301130003394,
                            30.7813630002722
                        ],
                        [
                            -89.8273879995525,
                            30.7833400000045
                        ],
                        [
                            -89.8243840000048,
                            30.7796200002697
                        ],
                        [
                            -89.820086999573,
                            30.7823909999486
                        ],
                        [
                            -89.8190239997411,
                            30.7843709999214
                        ],
                        [
                            -89.821485000039,
                            30.791159999848
                        ],
                        [
                            -89.8191540003358,
                            30.7952329996541
                        ],
                        [
                            -89.8164179996783,
                            30.7960540001045
                        ],
                        [
                            -89.8128900002489,
                            30.7936490002085
                        ],
                        [
                            -89.813070000174,
                            30.7892319996694
                        ],
                        [
                            -89.8115699996007,
                            30.7874909997987
                        ],
                        [
                            -89.8034660000803,
                            30.7911410001216
                        ],
                        [
                            -89.8035439998982,
                            30.7945550002831
                        ],
                        [
                            -89.8057290000115,
                            30.7919369998567
                        ],
                        [
                            -89.8084470002273,
                            30.7918369999107
                        ],
                        [
                            -89.8081440002786,
                            30.7964299997528
                        ],
                        [
                            -89.815738000161,
                            30.8033529998219
                        ],
                        [
                            -89.8145849999175,
                            30.8041049999548
                        ],
                        [
                            -89.8103079995772,
                            30.8001800000889
                        ],
                        [
                            -89.8046319998444,
                            30.8025110002152
                        ],
                        [
                            -89.8040500002364,
                            30.8074440003602
                        ],
                        [
                            -89.7971559999627,
                            30.8117529996303
                        ],
                        [
                            -89.7999899996312,
                            30.8161959996805
                        ],
                        [
                            -89.7986539999878,
                            30.8208550000858
                        ],
                        [
                            -89.7957070003414,
                            30.8210889996678
                        ],
                        [
                            -89.7953270003998,
                            30.817226999782
                        ],
                        [
                            -89.7917449996455,
                            30.8203869999828
                        ],
                        [
                            -89.7862110002028,
                            30.8159859999861
                        ],
                        [
                            -89.7815020000425,
                            30.8189449998487
                        ],
                        [
                            -89.7815310003548,
                            30.8208489997528
                        ],
                        [
                            -89.7863819999071,
                            30.821240000358
                        ],
                        [
                            -89.7876409995576,
                            30.8234740001219
                        ],
                        [
                            -89.7829530002119,
                            30.8291450000478
                        ],
                        [
                            -89.7823499997892,
                            30.8325519997813
                        ],
                        [
                            -89.7880570003825,
                            30.8303749997214
                        ],
                        [
                            -89.7907489997607,
                            30.8317680002594
                        ],
                        [
                            -89.7874999999906,
                            30.8441120003451
                        ],
                        [
                            -89.7924700002669,
                            30.8503859997167
                        ],
                        [
                            -89.7895090003768,
                            30.8513820001932
                        ],
                        [
                            -89.7858089996216,
                            30.8490210002885
                        ],
                        [
                            -89.7842419999996,
                            30.845811000102
                        ],
                        [
                            -89.7816340002869,
                            30.845273000123
                        ],
                        [
                            -89.7803220000347,
                            30.8469930002205
                        ],
                        [
                            -89.7844160000769,
                            30.8537440000502
                        ],
                        [
                            -89.7833840002071,
                            30.856022000282
                        ],
                        [
                            -89.7799100003061,
                            30.8562839999496
                        ],
                        [
                            -89.7747390001883,
                            30.8532540001869
                        ],
                        [
                            -89.7717220002218,
                            30.8546769998061
                        ],
                        [
                            -89.7725819996641,
                            30.8596400001157
                        ],
                        [
                            -89.7677889998381,
                            30.8655769997049
                        ],
                        [
                            -89.7780049996769,
                            30.8734110001535
                        ],
                        [
                            -89.7792469996089,
                            30.8760069999587
                        ],
                        [
                            -89.7771100000745,
                            30.8810880002027
                        ],
                        [
                            -89.7711399997155,
                            30.8792219999035
                        ],
                        [
                            -89.7695839999641,
                            30.8811479997679
                        ],
                        [
                            -89.7735549999826,
                            30.8863689999212
                        ],
                        [
                            -89.7717980003897,
                            30.8922580002991
                        ],
                        [
                            -89.7734100002176,
                            30.8976929999577
                        ],
                        [
                            -89.7709310003764,
                            30.8994319998308
                        ],
                        [
                            -89.763693999971,
                            30.896667000146
                        ],
                        [
                            -89.7587189996719,
                            30.8973190003141
                        ],
                        [
                            -89.7566709998259,
                            30.9010690000231
                        ],
                        [
                            -89.7598030003185,
                            30.9062160000401
                        ],
                        [
                            -89.763622000001,
                            30.9077319997772
                        ],
                        [
                            -89.7642019999591,
                            30.9119060001146
                        ],
                        [
                            -89.7594030002854,
                            30.9151340003142
                        ],
                        [
                            -89.7540859996794,
                            30.9127999998438
                        ],
                        [
                            -89.7500729998281,
                            30.9129299999916
                        ],
                        [
                            -89.7447889997325,
                            30.9189329996339
                        ],
                        [
                            -89.7451610001765,
                            30.9224160003512
                        ],
                        [
                            -89.7507409996498,
                            30.923246000078
                        ],
                        [
                            -89.7528119999603,
                            30.9254130001157
                        ],
                        [
                            -89.7488970000183,
                            30.9338880000845
                        ],
                        [
                            -89.7558349997747,
                            30.9395430003391
                        ],
                        [
                            -89.7565129996421,
                            30.9428609999653
                        ],
                        [
                            -89.7511959999345,
                            30.9514390001529
                        ],
                        [
                            -89.7433009997531,
                            30.9556689997137
                        ],
                        [
                            -89.7431340002469,
                            30.959993000186
                        ],
                        [
                            -89.7356859999543,
                            30.966573000055
                        ],
                        [
                            -89.7270719998976,
                            30.9673949999311
                        ],
                        [
                            -89.7283819996017,
                            30.9711409996268
                        ],
                        [
                            -89.7371489998193,
                            30.9760810003223
                        ],
                        [
                            -89.7305009995928,
                            30.9797070001979
                        ],
                        [
                            -89.7306210001418,
                            30.9865089997177
                        ],
                        [
                            -89.7276979998865,
                            30.9933289996275
                        ],
                        [
                            -89.7348180001161,
                            30.9965040003245
                        ],
                        [
                            -89.7355400003644,
                            30.9997150002729
                        ],
                        [
                            -89.728126000407,
                            31.0009559999619
                        ],
                        [
                            -89.731673000103,
                            31.0048169997567
                        ],
                        [
                            -89.7355010000064,
                            31.0033730000785
                        ],
                        [
                            -89.7360669997208,
                            31.001226999873
                        ],
                        [
                            -89.7409950001643,
                            31.0035929998356
                        ],
                        [
                            -89.7504810002572,
                            30.9994200002096
                        ],
                        [
                            -89.752642000081,
                            31.0018529996468
                        ],
                        [
                            -89.8158310000475,
                            31.0020769999279
                        ],
                        [
                            -90.4518209996018,
                            30.9995389999717
                        ],
                        [
                            -91.6369420000652,
                            30.9994160000014
                        ],
                        [
                            -91.6251179996981,
                            31.0053740003817
                        ],
                        [
                            -91.5904630001782,
                            31.0172700000731
                        ],
                        [
                            -91.5784129998553,
                            31.0240300000833
                        ],
                        [
                            -91.5643969997022,
                            31.0389649997887
                        ],
                        [
                            -91.559906999825,
                            31.0541190000491
                        ],
                        [
                            -91.56414999983,
                            31.0668299999549
                        ],
                        [
                            -91.5946929997645,
                            31.0914439997242
                        ],
                        [
                            -91.6132080000157,
                            31.1035209999833
                        ],
                        [
                            -91.6268619997705,
                            31.1187710001219
                        ],
                        [
                            -91.6282979998713,
                            31.1246089998433
                        ],
                        [
                            -91.6242170002479,
                            31.1337289999164
                        ],
                        [
                            -91.5997319996377,
                            31.1595920003548
                        ],
                        [
                            -91.5915019997206,
                            31.1731179997243
                        ],
                        [
                            -91.589046000344,
                            31.1785860001559
                        ],
                        [
                            -91.5889390002138,
                            31.1889590001833
                        ],
                        [
                            -91.5950290002234,
                            31.2019689999841
                        ],
                        [
                            -91.6016160003505,
                            31.2085730001881
                        ],
                        [
                            -91.6251190004214,
                            31.2260710003694
                        ],
                        [
                            -91.6443560000226,
                            31.2344140000355
                        ],
                        [
                            -91.6520190004003,
                            31.242690999636
                        ],
                        [
                            -91.6549069995971,
                            31.2501750001991
                        ],
                        [
                            -91.6540270000634,
                            31.2557530000294
                        ],
                        [
                            -91.6486690003479,
                            31.2622380003401
                        ],
                        [
                            -91.6376719998111,
                            31.2676800000189
                        ],
                        [
                            -91.6213579995668,
                            31.2678109997558
                        ],
                        [
                            -91.5877490001603,
                            31.2625629999722
                        ],
                        [
                            -91.5641919996628,
                            31.2616329996742
                        ],
                        [
                            -91.537734000448,
                            31.2673690003605
                        ],
                        [
                            -91.5185780001393,
                            31.2752830001755
                        ],
                        [
                            -91.5156139998762,
                            31.2782099997673
                        ],
                        [
                            -91.5088579996948,
                            31.2916440002852
                        ],
                        [
                            -91.5086600002264,
                            31.3151309999726
                        ],
                        [
                            -91.5316569999589,
                            31.331800999712
                        ],
                        [
                            -91.5349199999728,
                            31.3380049999928
                        ],
                        [
                            -91.5482130000526,
                            31.346083999671
                        ],
                        [
                            -91.5489899996542,
                            31.3486349998338
                        ],
                        [
                            -91.54721100032,
                            31.3514270001288
                        ],
                        [
                            -91.5510009996903,
                            31.3636490002371
                        ],
                        [
                            -91.5500519996612,
                            31.3753800002033
                        ],
                        [
                            -91.5460589999014,
                            31.3822210003383
                        ],
                        [
                            -91.5556799997134,
                            31.3864129998523
                        ],
                        [
                            -91.5625549997206,
                            31.3832190003182
                        ],
                        [
                            -91.5689529997018,
                            31.3776290001121
                        ],
                        [
                            -91.5783340002126,
                            31.3990669997916
                        ],
                        [
                            -91.5762649995519,
                            31.4104979997905
                        ],
                        [
                            -91.5700919997016,
                            31.4194870002586
                        ],
                        [
                            -91.5651790002251,
                            31.423446999687
                        ],
                        [
                            -91.5484649999477,
                            31.4326680003243
                        ],
                        [
                            -91.5433659997999,
                            31.4326080001758
                        ],
                        [
                            -91.5406469997592,
                            31.4307580001308
                        ],
                        [
                            -91.5371369998731,
                            31.4241609996609
                        ],
                        [
                            -91.5394580004289,
                            31.4140209998337
                        ],
                        [
                            -91.5323359996513,
                            31.3902750000138
                        ],
                        [
                            -91.5218360001303,
                            31.375169999755
                        ],
                        [
                            -91.5163670000867,
                            31.3705029998748
                        ],
                        [
                            -91.504756000155,
                            31.3649799999117
                        ],
                        [
                            -91.4788479998628,
                            31.3649459999286
                        ],
                        [
                            -91.4748090000722,
                            31.368645999739
                        ],
                        [
                            -91.4709829998187,
                            31.3762439998267
                        ],
                        [
                            -91.4726520004463,
                            31.3848039996926
                        ],
                        [
                            -91.472066999567,
                            31.3970760003746
                        ],
                        [
                            -91.4776519999615,
                            31.3979039997117
                        ],
                        [
                            -91.480020000373,
                            31.4042039999604
                        ],
                        [
                            -91.499811999818,
                            31.420100000313
                        ],
                        [
                            -91.5151300001775,
                            31.4492059996182
                        ],
                        [
                            -91.518147999969,
                            31.4834830003036
                        ],
                        [
                            -91.5149169997422,
                            31.5101130000398
                        ],
                        [
                            -91.5206970001304,
                            31.5133879999757
                        ],
                        [
                            -91.5228620001523,
                            31.5174929998219
                        ],
                        [
                            -91.522535999739,
                            31.5220780003163
                        ],
                        [
                            -91.5134460003796,
                            31.5320310000883
                        ],
                        [
                            -91.4896179997205,
                            31.5342660003225
                        ],
                        [
                            -91.4797180002492,
                            31.5303660003246
                        ],
                        [
                            -91.4476160001903,
                            31.5406659998282
                        ],
                        [
                            -91.4149149999067,
                            31.5621660003144
                        ],
                        [
                            -91.4079150002261,
                            31.5693659996902
                        ],
                        [
                            -91.4054149995702,
                            31.5764660000168
                        ],
                        [
                            -91.4039149998952,
                            31.5897660002987
                        ],
                        [
                            -91.4130150001987,
                            31.5953649996942
                        ],
                        [
                            -91.418815999605,
                            31.59656499979
                        ],
                        [
                            -91.432615999848,
                            31.5960649997797
                        ],
                        [
                            -91.4553170002029,
                            31.588165999844
                        ],
                        [
                            -91.4663170002143,
                            31.5860660001173
                        ],
                        [
                            -91.4832180000895,
                            31.5857659996818
                        ],
                        [
                            -91.4929180004426,
                            31.5895650002824
                        ],
                        [
                            -91.5027829997538,
                            31.5957270003121
                        ],
                        [
                            -91.5165670001033,
                            31.6118179997813
                        ],
                        [
                            -91.517899000447,
                            31.6203750003748
                        ],
                        [
                            -91.5154620004386,
                            31.6303719997033
                        ],
                        [
                            -91.5073100003393,
                            31.6390680002704
                        ],
                        [
                            -91.4961300004028,
                            31.6453100001411
                        ],
                        [
                            -91.4927479996649,
                            31.6442790001987
                        ],
                        [
                            -91.4822180000068,
                            31.6310649996639
                        ],
                        [
                            -91.4743179998026,
                            31.6253650003774
                        ],
                        [
                            -91.4638169995584,
                            31.6203650001583
                        ],
                        [
                            -91.4362160001458,
                            31.612464999773
                        ],
                        [
                            -91.4211160002444,
                            31.6115649997049
                        ],
                        [
                            -91.4010150001046,
                            31.6203650001583
                        ],
                        [
                            -91.3983150003304,
                            31.6262650000927
                        ],
                        [
                            -91.3957150001154,
                            31.6441650003465
                        ],
                        [
                            -91.400114999581,
                            31.6872639998665
                        ],
                        [
                            -91.3979150002973,
                            31.7093639999076
                        ],
                        [
                            -91.369375000003,
                            31.7469030003345
                        ],
                        [
                            -91.3519290003299,
                            31.7499560001513
                        ],
                        [
                            -91.3209129996691,
                            31.750063999644
                        ],
                        [
                            -91.3069119995846,
                            31.7486639999894
                        ],
                        [
                            -91.2952990000031,
                            31.7452670001524
                        ],
                        [
                            -91.27711099999,
                            31.7448830002491
                        ],
                        [
                            -91.267410999637,
                            31.7504639996949
                        ],
                        [
                            -91.2631109997305,
                            31.7547639997565
                        ],
                        [
                            -91.2595110003311,
                            31.760163000378
                        ],
                        [
                            -91.2625109996809,
                            31.7665629998796
                        ],
                        [
                            -91.2704109998851,
                            31.7707629999509
                        ],
                        [
                            -91.2812119997049,
                            31.7722629996466
                        ],
                        [
                            -91.2874199997153,
                            31.7719639996604
                        ],
                        [
                            -91.3044200002229,
                            31.7659639997695
                        ],
                        [
                            -91.3456140001894,
                            31.758463000012
                        ],
                        [
                            -91.3558140001346,
                            31.7580630001506
                        ],
                        [
                            -91.3656140000468,
                            31.7607629996568
                        ],
                        [
                            -91.3637140003388,
                            31.7825630000299
                        ],
                        [
                            -91.3605140000742,
                            31.7957619999759
                        ],
                        [
                            -91.3452140001563,
                            31.8438610001836
                        ],
                        [
                            -91.3384139995939,
                            31.8512610001567
                        ],
                        [
                            -91.3338140001117,
                            31.8532609999454
                        ],
                        [
                            -91.3083130004236,
                            31.8562609999415
                        ],
                        [
                            -91.2940129996901,
                            31.8604600002012
                        ],
                        [
                            -91.2893119999256,
                            31.8468609999556
                        ],
                        [
                            -91.2894120003831,
                            31.8286610002358
                        ],
                        [
                            -91.2832119998703,
                            31.8157619997128
                        ],
                        [
                            -91.2767119997819,
                            31.8113619999836
                        ],
                        [
                            -91.2692119996107,
                            31.8091620001452
                        ],
                        [
                            -91.2620110000886,
                            31.8093619997278
                        ],
                        [
                            -91.254310999901,
                            31.8136620001397
                        ],
                        [
                            -91.2466180002843,
                            31.8234280002846
                        ],
                        [
                            -91.2449949996874,
                            31.8321879999405
                        ],
                        [
                            -91.2676120003767,
                            31.8523610002194
                        ],
                        [
                            -91.2690119995942,
                            31.8586610000112
                        ],
                        [
                            -91.2677119999358,
                            31.8626599996688
                        ],
                        [
                            -91.2647119996877,
                            31.8653599997363
                        ],
                        [
                            -91.2481439998985,
                            31.8698479997501
                        ],
                        [
                            -91.2348990003975,
                            31.8768630003458
                        ],
                        [
                            -91.2088100003554,
                            31.9004590002918
                        ],
                        [
                            -91.2028099998592,
                            31.9079590003215
                        ],
                        [
                            -91.1943099996053,
                            31.909359000298
                        ],
                        [
                            -91.1910459997666,
                            31.9140390001343
                        ],
                        [
                            -91.1822769998992,
                            31.9158140000423
                        ],
                        [
                            -91.1804099998032,
                            31.9185589997159
                        ],
                        [
                            -91.1849099997262,
                            31.9237590001492
                        ],
                        [
                            -91.1838100000844,
                            31.9335580000639
                        ],
                        [
                            -91.1847099997097,
                            31.9350580002636
                        ],
                        [
                            -91.191110000239,
                            31.9341580000315
                        ],
                        [
                            -91.1932099999635,
                            31.9356580003513
                        ],
                        [
                            -91.1895100001067,
                            31.9463579998739
                        ],
                        [
                            -91.1888099995996,
                            31.959357999833
                        ],
                        [
                            -91.1848100001671,
                            31.9655569996552
                        ],
                        [
                            -91.1757099998636,
                            31.9747570003661
                        ],
                        [
                            -91.1644100002765,
                            31.9825570002782
                        ],
                        [
                            -91.1174090001577,
                            31.9870570003599
                        ],
                        [
                            -91.1028080000236,
                            31.9909570000191
                        ],
                        [
                            -91.0901049999477,
                            32.0001570003658
                        ],
                        [
                            -91.0759080000446,
                            32.0168269999373
                        ],
                        [
                            -91.0799279995686,
                            32.018316000238
                        ],
                        [
                            -91.0844080002985,
                            32.0314560001165
                        ],
                        [
                            -91.0914079999791,
                            32.0360539999101
                        ],
                        [
                            -91.1451100000278,
                            32.0465540001176
                        ],
                        [
                            -91.1538100002982,
                            32.0506550002981
                        ],
                        [
                            -91.1593100003039,
                            32.0563549998449
                        ],
                        [
                            -91.162010000078,
                            32.0618549996296
                        ],
                        [
                            -91.161610000045,
                            32.0677539998609
                        ],
                        [
                            -91.1585100002377,
                            32.0728540002759
                        ],
                        [
                            -91.1488099998846,
                            32.0801539997224
                        ],
                        [
                            -91.1426090004453,
                            32.0817529998579
                        ],
                        [
                            -91.1357090003238,
                            32.0806540001978
                        ],
                        [
                            -91.1286090001857,
                            32.076554000054
                        ],
                        [
                            -91.1143090003505,
                            32.058254999711
                        ],
                        [
                            -91.1037079996489,
                            32.0502549998741
                        ],
                        [
                            -91.0987080001337,
                            32.0483549998719
                        ],
                        [
                            -91.0809079995598,
                            32.0478550002081
                        ],
                        [
                            -91.0791080003093,
                            32.0506550002981
                        ],
                        [
                            -91.0811079995764,
                            32.0783530003156
                        ],
                        [
                            -91.0352069999977,
                            32.100452999653
                        ],
                        [
                            -91.0305070000582,
                            32.1081529996836
                        ],
                        [
                            -91.0308910001977,
                            32.1205580002406
                        ],
                        [
                            -91.020005999814,
                            32.1235530001856
                        ],
                        [
                            -91.0158060003649,
                            32.1267529998099
                        ],
                        [
                            -91.0072059996537,
                            32.1384520003273
                        ],
                        [
                            -91.0041059998465,
                            32.1461519999983
                        ],
                        [
                            -91.0029059997472,
                            32.1620520002547
                        ],
                        [
                            -91.0166060004311,
                            32.160851999666
                        ],
                        [
                            -91.0250070000524,
                            32.1625510001389
                        ],
                        [
                            -91.0418069996453,
                            32.1745510002178
                        ],
                        [
                            -91.0583069996624,
                            32.1809509998141
                        ],
                        [
                            -91.0589069997121,
                            32.1712499997478
                        ],
                        [
                            -91.0557070003457,
                            32.163751999638
                        ],
                        [
                            -91.0497069998495,
                            32.15735199996
                        ],
                        [
                            -91.0485069997502,
                            32.150152000303
                        ],
                        [
                            -91.0512070004227,
                            32.1441519998274
                        ],
                        [
                            -91.0535549998443,
                            32.124411999922
                        ],
                        [
                            -91.0615549996077,
                            32.1269070002442
                        ],
                        [
                            -91.0690809997181,
                            32.1314780001703
                        ],
                        [
                            -91.0816299998082,
                            32.1339919997103
                        ],
                        [
                            -91.0916559996762,
                            32.1336039996791
                        ],
                        [
                            -91.1011810001271,
                            32.1311349999187
                        ],
                        [
                            -91.1109900002601,
                            32.1246439999776
                        ],
                        [
                            -91.1314029996712,
                            32.126212999642
                        ],
                        [
                            -91.1444000003733,
                            32.1303900002505
                        ],
                        [
                            -91.1628219998398,
                            32.1326940002524
                        ],
                        [
                            -91.165452000192,
                            32.1342900001037
                        ],
                        [
                            -91.1717020000352,
                            32.1442499996273
                        ],
                        [
                            -91.1745519995972,
                            32.1549779999356
                        ],
                        [
                            -91.1710459999091,
                            32.1765260000104
                        ],
                        [
                            -91.1641709999019,
                            32.1968880001465
                        ],
                        [
                            -91.1580259996407,
                            32.2019560000428
                        ],
                        [
                            -91.1335869999596,
                            32.2134309998718
                        ],
                        [
                            -91.1266120003934,
                            32.2124559999162
                        ],
                        [
                            -91.1172700002406,
                            32.2066680003432
                        ],
                        [
                            -91.1085089998708,
                            32.2081499999823
                        ],
                        [
                            -91.1004089996501,
                            32.2178500003344
                        ],
                        [
                            -91.0921079995878,
                            32.2238499996821
                        ],
                        [
                            -91.0837079997914,
                            32.2264500000834
                        ],
                        [
                            -91.0711079996477,
                            32.2260500001275
                        ],
                        [
                            -91.0614080001929,
                            32.2186499999723
                        ],
                        [
                            -91.0531070001307,
                            32.2279499996284
                        ],
                        [
                            -91.0503069998991,
                            32.2379489999545
                        ],
                        [
                            -91.0465069995848,
                            32.2411490001086
                        ],
                        [
                            -91.039007000312,
                            32.2423479998036
                        ],
                        [
                            -91.0215370003494,
                            32.236155999853
                        ],
                        [
                            -91.0096059998522,
                            32.226149000024
                        ],
                        [
                            -91.0029059997472,
                            32.223050000205
                        ],
                        [
                            -91.00500600037,
                            32.21284999988
                        ],
                        [
                            -91.0032060002212,
                            32.1980499996516
                        ],
                        [
                            -90.99969700016,
                            32.1911029996717
                        ],
                        [
                            -90.9949439996187,
                            32.1931409997843
                        ],
                        [
                            -90.9833810002659,
                            32.2117669997851
                        ],
                        [
                            -90.9831350002186,
                            32.2313710003192
                        ],
                        [
                            -90.9802899997812,
                            32.243600999755
                        ],
                        [
                            -90.9694029997476,
                            32.2525199999354
                        ],
                        [
                            -90.9713440003618,
                            32.257742000015
                        ],
                        [
                            -90.9829850004308,
                            32.2702939996932
                        ],
                        [
                            -90.9840769996767,
                            32.2799529996246
                        ],
                        [
                            -90.9794749996464,
                            32.29370200003
                        ],
                        [
                            -90.9746020003528,
                            32.2971220003115
                        ],
                        [
                            -90.9641489997892,
                            32.2968720000663
                        ],
                        [
                            -90.9554050000362,
                            32.2862400000823
                        ],
                        [
                            -90.9497810001819,
                            32.2830559999017
                        ],
                        [
                            -90.9339909998192,
                            32.2903430003221
                        ],
                        [
                            -90.9222309999246,
                            32.2986389999373
                        ],
                        [
                            -90.9118490004045,
                            32.3079010002429
                        ],
                        [
                            -90.902853999683,
                            32.3191219997082
                        ],
                        [
                            -90.9007220001714,
                            32.330379000236
                        ],
                        [
                            -90.8857210000042,
                            32.3508669998229
                        ],
                        [
                            -90.8756380002348,
                            32.3717290003209
                        ],
                        [
                            -90.8756209996181,
                            32.3735260002081
                        ],
                        [
                            -90.8786040001477,
                            32.3746359998456
                        ],
                        [
                            -90.8907120001969,
                            32.3722459998321
                        ],
                        [
                            -90.8971789997751,
                            32.3551780000238
                        ],
                        [
                            -90.9123630002403,
                            32.3394540001278
                        ],
                        [
                            -90.9307599995924,
                            32.3442539997241
                        ],
                        [
                            -90.9545830002287,
                            32.3458579997243
                        ],
                        [
                            -90.9936249996938,
                            32.3540469998532
                        ],
                        [
                            -91.0001060004139,
                            32.3576950003433
                        ],
                        [
                            -91.0045059998795,
                            32.3647439998084
                        ],
                        [
                            -91.0014060000723,
                            32.3794440000031
                        ],
                        [
                            -90.9946859998759,
                            32.3922770003653
                        ],
                        [
                            -90.9940799999785,
                            32.403861000092
                        ],
                        [
                            -90.9753420001445,
                            32.4109510002335
                        ],
                        [
                            -90.9659869995729,
                            32.4206540001164
                        ],
                        [
                            -90.9664410000327,
                            32.4338739998592
                        ],
                        [
                            -90.9695869998706,
                            32.4394919999725
                        ],
                        [
                            -90.980006999924,
                            32.4478420003482
                        ],
                        [
                            -90.9954869997669,
                            32.450885000101
                        ],
                        [
                            -91.0282059995939,
                            32.4337419999948
                        ],
                        [
                            -91.0514070004392,
                            32.4378419999918
                        ],
                        [
                            -91.0755070001866,
                            32.4474399999742
                        ],
                        [
                            -91.0934230002131,
                            32.4573859996521
                        ],
                        [
                            -91.1131080004263,
                            32.477738999702
                        ],
                        [
                            -91.1166080002667,
                            32.4859399998291
                        ],
                        [
                            -91.1167079998258,
                            32.5001379999701
                        ],
                        [
                            -91.1013040001508,
                            32.5255989999095
                        ],
                        [
                            -91.0979490000754,
                            32.537213999699
                        ],
                        [
                            -91.0978779999303,
                            32.5447509998503
                        ],
                        [
                            -91.0937420000553,
                            32.5491270001484
                        ],
                        [
                            -91.0748440003878,
                            32.5334889997029
                        ],
                        [
                            -91.0509069999487,
                            32.5001379999701
                        ],
                        [
                            -91.0381059999635,
                            32.490439999964
                        ],
                        [
                            -91.0209060003376,
                            32.4845399998754
                        ],
                        [
                            -91.000506000447,
                            32.4822389999746
                        ],
                        [
                            -90.9900889998685,
                            32.4885610001881
                        ],
                        [
                            -90.9872019995983,
                            32.4956630000421
                        ],
                        [
                            -90.9887840001872,
                            32.5008410000713
                        ],
                        [
                            -90.9948669996258,
                            32.5086109997822
                        ],
                        [
                            -91.0011290000629,
                            32.5129450002574
                        ],
                        [
                            -91.0184690003292,
                            32.5210190002362
                        ],
                        [
                            -91.0577059997879,
                            32.5337770002017
                        ],
                        [
                            -91.0754000000565,
                            32.5467430000718
                        ],
                        [
                            -91.0804110003407,
                            32.5564679996386
                        ],
                        [
                            -91.0361700002705,
                            32.5795550003708
                        ],
                        [
                            -91.0309909997568,
                            32.5833470001886
                        ],
                        [
                            -91.0249019995721,
                            32.5909039996244
                        ],
                        [
                            -91.0137230003589,
                            32.5984189996991
                        ],
                        [
                            -91.0082450000945,
                            32.6042390002666
                        ],
                        [
                            -91.0035450001549,
                            32.6122710001563
                        ],
                        [
                            -91.0029619998237,
                            32.6225550000175
                        ],
                        [
                            -91.0068199998643,
                            32.631261000247
                        ],
                        [
                            -91.0142859997002,
                            32.640482000321
                        ],
                        [
                            -91.0257689995855,
                            32.6465720002524
                        ],
                        [
                            -91.0301809996449,
                            32.6440499996438
                        ],
                        [
                            -91.0317609996858,
                            32.6403149996439
                        ],
                        [
                            -91.0371820000488,
                            32.6378610000553
                        ],
                        [
                            -91.0400419996565,
                            32.6335550003083
                        ],
                        [
                            -91.0432629998376,
                            32.6207870000802
                        ],
                        [
                            -91.0489029995854,
                            32.6135539997106
                        ],
                        [
                            -91.049802000284,
                            32.6071849996774
                        ],
                        [
                            -91.0795069996192,
                            32.6006759999795
                        ],
                        [
                            -91.1072350001517,
                            32.5902550001831
                        ],
                        [
                            -91.1156550000396,
                            32.5910029997417
                        ],
                        [
                            -91.1174600002114,
                            32.5860390002104
                        ],
                        [
                            -91.1198539996637,
                            32.5847950002894
                        ],
                        [
                            -91.1279120000517,
                            32.5864920003584
                        ],
                        [
                            -91.1427819997993,
                            32.5988990003433
                        ],
                        [
                            -91.1504540003979,
                            32.6134950003566
                        ],
                        [
                            -91.1535559998549,
                            32.6261809999937
                        ],
                        [
                            -91.1526989998874,
                            32.6407569996874
                        ],
                        [
                            -91.1443469997716,
                            32.6480290003016
                        ],
                        [
                            -91.1376380003441,
                            32.6506210002718
                        ],
                        [
                            -91.1262009995913,
                            32.6671350001695
                        ],
                        [
                            -91.1182579997294,
                            32.6740750001796
                        ],
                        [
                            -91.1044430003161,
                            32.6824340002553
                        ],
                        [
                            -91.0760610002055,
                            32.6937509998445
                        ],
                        [
                            -91.0639459995853,
                            32.7029250000169
                        ],
                        [
                            -91.0553259996809,
                            32.7171450001871
                        ],
                        [
                            -91.0544810003072,
                            32.7222589998279
                        ],
                        [
                            -91.0569989996082,
                            32.7255800003277
                        ],
                        [
                            -91.0905729997528,
                            32.7361000001387
                        ],
                        [
                            -91.1246449998399,
                            32.7431640001008
                        ],
                        [
                            -91.1502440003356,
                            32.7417850001967
                        ],
                        [
                            -91.1586099997968,
                            32.743448999956
                        ],
                        [
                            -91.1634200002396,
                            32.7470449998424
                        ],
                        [
                            -91.165889000035,
                            32.7556149996364
                        ],
                        [
                            -91.1643969998577,
                            32.7634910001454
                        ],
                        [
                            -91.1554759996544,
                            32.7799090000446
                        ],
                        [
                            -91.1643969998577,
                            32.785820999878
                        ],
                        [
                            -91.1609890000789,
                            32.8015289997617
                        ],
                        [
                            -91.1616689995962,
                            32.8124650002023
                        ],
                        [
                            -91.1577810003167,
                            32.8232550003506
                        ],
                        [
                            -91.1521740001808,
                            32.8266729999001
                        ],
                        [
                            -91.1426040004224,
                            32.8455280003757
                        ],
                        [
                            -91.1278860001124,
                            32.855057999947
                        ],
                        [
                            -91.1160910000577,
                            32.8556410000866
                        ],
                        [
                            -91.1056309998214,
                            32.8583959997363
                        ],
                        [
                            -91.0866829999252,
                            32.8733920003441
                        ],
                        [
                            -91.0706020002077,
                            32.8886579999751
                        ],
                        [
                            -91.0638090002163,
                            32.9037089998746
                        ],
                        [
                            -91.0639740000727,
                            32.9241130002335
                        ],
                        [
                            -91.0705469999561,
                            32.936036000043
                        ],
                        [
                            -91.0826230002182,
                            32.9462290002814
                        ],
                        [
                            -91.0826030001267,
                            32.9487319997124
                        ],
                        [
                            -91.0789040000948,
                            32.9518179996258
                        ],
                        [
                            -91.0791240002028,
                            32.9550699996304
                        ],
                        [
                            -91.0803550002642,
                            32.9627939997983
                        ],
                        [
                            -91.0868019997509,
                            32.976265999881
                        ],
                        [
                            -91.0969299997261,
                            32.9864119999053
                        ],
                        [
                            -91.1065809996754,
                            32.9889369998169
                        ],
                        [
                            -91.1251069997973,
                            32.9846679999445
                        ],
                        [
                            -91.1355169998048,
                            32.9796570001441
                        ],
                        [
                            -91.1358149997307,
                            32.9754159999414
                        ],
                        [
                            -91.138584999825,
                            32.9713519999727
                        ],
                        [
                            -91.1316710003581,
                            32.9652370001578
                        ],
                        [
                            -91.1307209996058,
                            32.9622569999605
                        ],
                        [
                            -91.1366280002156,
                            32.9573489999711
                        ],
                        [
                            -91.1378629995766,
                            32.9527559999255
                        ],
                        [
                            -91.131303999937,
                            32.9269180002811
                        ],
                        [
                            -91.1340410004194,
                            32.9176759996706
                        ],
                        [
                            -91.1473830000047,
                            32.9040909997695
                        ],
                        [
                            -91.1569340003948,
                            32.9004770001667
                        ],
                        [
                            -91.1674199996721,
                            32.8993780003224
                        ],
                        [
                            -91.1770739999944,
                            32.9003259998227
                        ],
                        [
                            -91.1889329996232,
                            32.9037750001677
                        ],
                        [
                            -91.1967850001469,
                            32.9067839998517
                        ],
                        [
                            -91.2070460001915,
                            32.9140910001129
                        ],
                        [
                            -91.2115970001682,
                            32.919623999741
                        ],
                        [
                            -91.2139720002523,
                            32.9271970001144
                        ],
                        [
                            -91.2128199998336,
                            32.9360760001254
                        ],
                        [
                            -91.2107050000405,
                            32.9398450003213
                        ],
                        [
                            -91.2022979996731,
                            32.9497030003207
                        ],
                        [
                            -91.1983430004464,
                            32.950206000223
                        ],
                        [
                            -91.201757000073,
                            32.9564520003284
                        ],
                        [
                            -91.2018419995635,
                            32.9612119997365
                        ],
                        [
                            -91.1869829996865,
                            32.9761940000507
                        ],
                        [
                            -91.1748629999418,
                            32.9844709998212
                        ],
                        [
                            -91.1689729999487,
                            32.9921309997866
                        ],
                        [
                            -91.1660389998229,
                            33.0031349999764
                        ],
                        [
                            -91.1667100000176,
                            33.0096639998284
                        ],
                        [
                            -91.1623630002555,
                            33.0196830003179
                        ],
                        [
                            -91.1497580000888,
                            33.0263109996521
                        ],
                        [
                            -91.1374380003275,
                            33.0287359998597
                        ],
                        [
                            -91.1290870000366,
                            33.0335540002919
                        ],
                        [
                            -91.1256549999683,
                            33.0382760003759
                        ],
                        [
                            -91.1261930000937,
                            33.0411900002201
                        ],
                        [
                            -91.1203180001692,
                            33.055322999865
                        ],
                        [
                            -91.1246389999921,
                            33.0641269999738
                        ],
                        [
                            -91.1498230003861,
                            33.0816029998169
                        ],
                        [
                            -91.1715139997142,
                            33.0878179996872
                        ],
                        [
                            -91.1808359997755,
                            33.0983630003559
                        ],
                        [
                            -91.2001669999864,
                            33.1069299996256
                        ],
                        [
                            -91.2014619996219,
                            33.1096370000352
                        ],
                        [
                            -91.2017799996392,
                            33.1251209999389
                        ],
                        [
                            -91.1931739999785,
                            33.1367330002561
                        ],
                        [
                            -91.1836619999464,
                            33.1416909998958
                        ],
                        [
                            -91.1616510000528,
                            33.1417810002476
                        ],
                        [
                            -91.1530150002549,
                            33.1350920000631
                        ],
                        [
                            -91.1503620003364,
                            33.1306950000743
                        ],
                        [
                            -91.1316589997642,
                            33.1291009996932
                        ],
                        [
                            -91.1043169999194,
                            33.1315969996331
                        ],
                        [
                            -91.091152999711,
                            33.1382599998335
                        ],
                        [
                            -91.0875890002966,
                            33.1451760002153
                        ],
                        [
                            -91.085562000367,
                            33.1558220003422
                        ],
                        [
                            -91.0843659995674,
                            33.1808559999145
                        ],
                        [
                            -91.0917109999278,
                            33.2208130003662
                        ],
                        [
                            -91.0810249997357,
                            33.2224679999107
                        ],
                        [
                            -91.0776730000334,
                            33.2274849998621
                        ],
                        [
                            -91.0706969997439,
                            33.2273020002181
                        ],
                        [
                            -91.0710790002337,
                            33.2300959998524
                        ],
                        [
                            -91.0691440003656,
                            33.232639000064
                        ],
                        [
                            -91.0656289995583,
                            33.2329819997334
                        ],
                        [
                            -91.0637599998125,
                            33.2376980003736
                        ],
                        [
                            -91.0506929996885,
                            33.250693999783
                        ],
                        [
                            -91.0445270004092,
                            33.267545999668
                        ],
                        [
                            -91.0436240004109,
                            33.2746359996488
                        ],
                        [
                            -91.0472199996124,
                            33.2818070001346
                        ],
                        [
                            -91.0539259995651,
                            33.2858980002808
                        ],
                        [
                            -91.0670350004202,
                            33.2871799999554
                        ],
                        [
                            -91.078530000001,
                            33.2833059996335
                        ],
                        [
                            -91.0845739999798,
                            33.2772560002239
                        ],
                        [
                            -91.0904530001022,
                            33.2567989997137
                        ],
                        [
                            -91.0947479999858,
                            33.2504980003506
                        ],
                        [
                            -91.096930999551,
                            33.2416280003172
                        ],
                        [
                            -91.1000999998536,
                            33.2381239999834
                        ],
                        [
                            -91.1105609999148,
                            33.2459299997714
                        ],
                        [
                            -91.118208000399,
                            33.2620709998281
                        ],
                        [
                            -91.1280779997331,
                            33.2685010002353
                        ],
                        [
                            -91.1255279997467,
                            33.2747320001219
                        ],
                        [
                            -91.1255389996174,
                            33.2802539998664
                        ],
                        [
                            -91.131011000034,
                            33.285260999675
                        ],
                        [
                            -91.1416910003783,
                            33.3003549997512
                        ],
                        [
                            -91.1414169998436,
                            33.315044000366
                        ],
                        [
                            -91.1436670002543,
                            33.3283979996931
                        ],
                        [
                            -91.1409680003051,
                            33.336491999728
                        ],
                        [
                            -91.1421259996732,
                            33.3494970002408
                        ],
                        [
                            -91.1204090004058,
                            33.3638089999213
                        ],
                        [
                            -91.1017900003976,
                            33.3868929997147
                        ],
                        [
                            -91.0752929999264,
                            33.4059659998753
                        ],
                        [
                            -91.068167999674,
                            33.4140079998455
                        ],
                        [
                            -91.0581519998517,
                            33.4287050003544
                        ],
                        [
                            -91.0576210002974,
                            33.4453410001154
                        ],
                        [
                            -91.059828000152,
                            33.4502570000115
                        ],
                        [
                            -91.067622999876,
                            33.4551039999166
                        ],
                        [
                            -91.0745549997845,
                            33.4558100003295
                        ],
                        [
                            -91.0864979999773,
                            33.4515759997678
                        ],
                        [
                            -91.0942790003558,
                            33.4426700001785
                        ],
                        [
                            -91.0967230000369,
                            33.4376030001181
                        ],
                        [
                            -91.0974739996992,
                            33.4317330002085
                        ],
                        [
                            -91.0949110001925,
                            33.4186149999919
                        ],
                        [
                            -91.0980789997718,
                            33.4095949998181
                        ],
                        [
                            -91.1067360003844,
                            33.3994750003034
                        ],
                        [
                            -91.1206780000194,
                            33.3885449997793
                        ],
                        [
                            -91.1476829995803,
                            33.3788759999318
                        ],
                        [
                            -91.1663040001367,
                            33.3797089996419
                        ],
                        [
                            -91.1769419997499,
                            33.3828410002753
                        ],
                        [
                            -91.1911269999574,
                            33.3896339999599
                        ],
                        [
                            -91.204758000146,
                            33.3988229996529
                        ],
                        [
                            -91.2081130002214,
                            33.4020059997482
                        ],
                        [
                            -91.2092200004342,
                            33.4062900002692
                        ],
                        [
                            -91.2052719999819,
                            33.4142309999387
                        ],
                        [
                            -91.2000489999856,
                            33.4181370000445
                        ],
                        [
                            -91.1919730000543,
                            33.4177270001767
                        ],
                        [
                            -91.1844269998524,
                            33.4195759998243
                        ],
                        [
                            -91.176279999776,
                            33.4169780001727
                        ],
                        [
                            -91.1535809999693,
                            33.4256009999158
                        ],
                        [
                            -91.1448770003993,
                            33.4277060001913
                        ],
                        [
                            -91.1385530000379,
                            33.427068000348
                        ],
                        [
                            -91.13188499972,
                            33.43006200008
                        ],
                        [
                            -91.1285890000942,
                            33.4362840000352
                        ],
                        [
                            -91.1210999997938,
                            33.4435620002007
                        ],
                        [
                            -91.1179749998722,
                            33.4538059998721
                        ],
                        [
                            -91.1251090003454,
                            33.4728420003711
                        ],
                        [
                            -91.1356979995548,
                            33.4806799998941
                        ],
                        [
                            -91.1646000002473,
                            33.4978410001048
                        ],
                        [
                            -91.1709949998555,
                            33.4975829998779
                        ],
                        [
                            -91.1765409998919,
                            33.4884550002372
                        ],
                        [
                            -91.1773529996536,
                            33.481956999942
                        ],
                        [
                            -91.1756350004189,
                            33.4717609998156
                        ],
                        [
                            -91.169359999563,
                            33.4526290003307
                        ],
                        [
                            -91.1772930002775,
                            33.4436380001248
                        ],
                        [
                            -91.1869790003869,
                            33.4385920003288
                        ],
                        [
                            -91.2068069998169,
                            33.4338459998777
                        ],
                        [
                            -91.2173740001833,
                            33.434698999765
                        ],
                        [
                            -91.2357999998477,
                            33.4397379996931
                        ],
                        [
                            -91.2334220002889,
                            33.4440379996931
                        ],
                        [
                            -91.2316609995998,
                            33.4570990000489
                        ],
                        [
                            -91.2233379997962,
                            33.4627639999369
                        ],
                        [
                            -91.2155079999122,
                            33.4647789998301
                        ],
                        [
                            -91.2085349999958,
                            33.4686059997695
                        ],
                        [
                            -91.1956339995531,
                            33.4884670000489
                        ],
                        [
                            -91.1833720004165,
                            33.4981570000437
                        ],
                        [
                            -91.1825649997793,
                            33.5010610001099
                        ],
                        [
                            -91.183420000097,
                            33.5050619999532
                        ],
                        [
                            -91.1873669998261,
                            33.5105509998759
                        ],
                        [
                            -91.2104210003584,
                            33.5255870002744
                        ],
                        [
                            -91.221560000287,
                            33.5350049998012
                        ],
                        [
                            -91.2310220000904,
                            33.5494619997023
                        ],
                        [
                            -91.2325979999333,
                            33.5552350003315
                        ],
                        [
                            -91.2314179999255,
                            33.5605930002907
                        ],
                        [
                            -91.224121000144,
                            33.5673689998001
                        ],
                        [
                            -91.1982849998218,
                            33.572060999764
                        ],
                        [
                            -91.178219999667,
                            33.5826069999645
                        ],
                        [
                            -91.1607549997272,
                            33.5813519996914
                        ],
                        [
                            -91.1521480002416,
                            33.5827210001203
                        ],
                        [
                            -91.1340430000692,
                            33.5944889999383
                        ],
                        [
                            -91.1304450003196,
                            33.6060339997015
                        ],
                        [
                            -91.1343889996756,
                            33.619511999787
                        ],
                        [
                            -91.1407249997325,
                            33.6268929999369
                        ],
                        [
                            -91.1745890003054,
                            33.6495259996349
                        ],
                        [
                            -91.1854550004226,
                            33.6536039997145
                        ],
                        [
                            -91.2112840001737,
                            33.658525999693
                        ],
                        [
                            -91.2190479999355,
                            33.6615029996605
                        ],
                        [
                            -91.2282279997066,
                            33.6713259998374
                        ],
                        [
                            -91.2284789997768,
                            33.6805099996774
                        ],
                        [
                            -91.2252790004104,
                            33.6877489996937
                        ],
                        [
                            -91.22057000025,
                            33.6929229996847
                        ],
                        [
                            -91.2078069998996,
                            33.7000950001018
                        ],
                        [
                            -91.1757299999551,
                            33.7031160002099
                        ],
                        [
                            -91.1613310003857,
                            33.7072010001472
                        ],
                        [
                            -91.1321600000796,
                            33.6757490002256
                        ],
                        [
                            -91.0940399999812,
                            33.6583509999267
                        ],
                        [
                            -91.0866150001531,
                            33.6572769999967
                        ],
                        [
                            -91.0785070004348,
                            33.658283000071
                        ],
                        [
                            -91.0591820000717,
                            33.6664000002454
                        ],
                        [
                            -91.0401110001518,
                            33.6700529997961
                        ],
                        [
                            -91.0329020002338,
                            33.6752470001201
                        ],
                        [
                            -91.030332000156,
                            33.6816220003586
                        ],
                        [
                            -91.0304019995779,
                            33.6877660000055
                        ],
                        [
                            -91.0361200000418,
                            33.6891130001727
                        ],
                        [
                            -91.0390249998554,
                            33.6965950003241
                        ],
                        [
                            -91.0467779997465,
                            33.7063129997544
                        ],
                        [
                            -91.0598909999012,
                            33.7148160002966
                        ],
                        [
                            -91.0682899998727,
                            33.7164770000197
                        ],
                        [
                            -91.0898730001441,
                            33.707363999859
                        ],
                        [
                            -91.1076460000554,
                            33.7046789997724
                        ],
                        [
                            -91.1177330000228,
                            33.7053419998808
                        ],
                        [
                            -91.1255269999218,
                            33.7087800001334
                        ],
                        [
                            -91.1319540002153,
                            33.7139020001051
                        ],
                        [
                            -91.1423659998727,
                            33.7240350002699
                        ],
                        [
                            -91.1463510001349,
                            33.7306089997154
                        ],
                        [
                            -91.146522999664,
                            33.7365030001814
                        ],
                        [
                            -91.1432870003127,
                            33.747140999686
                        ],
                        [
                            -91.1448730002013,
                            33.7509650002542
                        ],
                        [
                            -91.1407559996946,
                            33.7597349999513
                        ],
                        [
                            -91.144812000102,
                            33.7639959997301
                        ],
                        [
                            -91.1451119996776,
                            33.7673400000049
                        ],
                        [
                            -91.1382569997619,
                            33.7790220001514
                        ],
                        [
                            -91.1338539999233,
                            33.7828140000031
                        ],
                        [
                            -91.129733000117,
                            33.783600999915
                        ],
                        [
                            -91.117835999955,
                            33.779026000057
                        ],
                        [
                            -91.1073179999924,
                            33.7706190003206
                        ],
                        [
                            -91.0855099995901,
                            33.7764100002775
                        ],
                        [
                            -91.0538860002805,
                            33.7787009998406
                        ],
                        [
                            -91.026782000087,
                            33.7636420001512
                        ],
                        [
                            -91.0232849997214,
                            33.762990999786
                        ],
                        [
                            -91.0127700001318,
                            33.7646750002286
                        ],
                        [
                            -91.0001060004139,
                            33.7691650002529
                        ],
                        [
                            -90.9912200003707,
                            33.7767909996354
                        ],
                        [
                            -90.9884660001699,
                            33.7845300000174
                        ],
                        [
                            -90.989298999848,
                            33.7880160000867
                        ],
                        [
                            -90.9917469997271,
                            33.7925969997473
                        ],
                        [
                            -90.9997840001987,
                            33.7991400000297
                        ],
                        [
                            -91.0077799997641,
                            33.8025940000332
                        ],
                        [
                            -91.0251729997338,
                            33.8059530001095
                        ],
                        [
                            -91.0468489998916,
                            33.8153650002792
                        ],
                        [
                            -91.0566920003598,
                            33.8289349997319
                        ],
                        [
                            -91.067510999723,
                            33.8404429998542
                        ],
                        [
                            -91.0730109997287,
                            33.8574489999551
                        ],
                        [
                            -91.0708830004151,
                            33.8667139998979
                        ],
                        [
                            -91.0553089999624,
                            33.8830350001776
                        ],
                        [
                            -91.0263820000539,
                            33.9079800002118
                        ],
                        [
                            -91.0108310000658,
                            33.9255519997221
                        ],
                        [
                            -91.0103180000548,
                            33.9293520000603
                        ],
                        [
                            -91.0200970000506,
                            33.9371270001545
                        ],
                        [
                            -91.0359610000331,
                            33.9437579999157
                        ],
                        [
                            -91.084095000304,
                            33.9561789998607
                        ],
                        [
                            -91.0886959996111,
                            33.9613339997694
                        ],
                        [
                            -91.0897559999682,
                            33.9697210001276
                        ],
                        [
                            -91.0879209996593,
                            33.9753350000615
                        ],
                        [
                            -91.0792539998992,
                            33.9821010001012
                        ],
                        [
                            -91.0712030000822,
                            33.9844730000495
                        ],
                        [
                            -91.0454770002632,
                            33.9857949997075
                        ],
                        [
                            -91.0337650000491,
                            33.9953230000649
                        ],
                        [
                            -91.0188900002787,
                            34.0031510000264
                        ],
                        [
                            -91.0001080000638,
                            33.9664280000619
                        ],
                        [
                            -90.987652999685,
                            33.9607929998914
                        ],
                        [
                            -90.9708559995669,
                            33.961867999988
                        ],
                        [
                            -90.9637200003421,
                            33.9676879996375
                        ],
                        [
                            -90.9615510001223,
                            33.979946000365
                        ],
                        [
                            -90.9710179999486,
                            33.9919559999856
                        ],
                        [
                            -90.9798360002197,
                            33.9976650000545
                        ],
                        [
                            -90.9879480001361,
                            34.0190380003634
                        ],
                        [
                            -90.9707259998705,
                            34.0216200001376
                        ],
                        [
                            -90.9503109999114,
                            34.0178219998607
                        ],
                        [
                            -90.9426619997773,
                            34.0180499998857
                        ],
                        [
                            -90.9237449998432,
                            34.023143000119
                        ],
                        [
                            -90.914642000065,
                            34.0218849998216
                        ],
                        [
                            -90.8994669998206,
                            34.0237960002257
                        ],
                        [
                            -90.888956000429,
                            34.0297879999262
                        ],
                        [
                            -90.8869910004237,
                            34.0350940000883
                        ],
                        [
                            -90.8890579996379,
                            34.0463620002839
                        ],
                        [
                            -90.8878369996223,
                            34.0554030001791
                        ],
                        [
                            -90.8719400000278,
                            34.0763619997688
                        ],
                        [
                            -90.8704610002693,
                            34.0827389999415
                        ],
                        [
                            -90.8719230003093,
                            34.0866520001265
                        ],
                        [
                            -90.8789120001193,
                            34.0945730002402
                        ],
                        [
                            -90.8880850002177,
                            34.0978100003565
                        ],
                        [
                            -90.8935259997739,
                            34.0977950000451
                        ],
                        [
                            -90.9011299997021,
                            34.0946670002708
                        ],
                        [
                            -90.9140679999547,
                            34.0927569997361
                        ],
                        [
                            -90.921230000017,
                            34.0939480000412
                        ],
                        [
                            -90.9463230001744,
                            34.1093739999043
                        ],
                        [
                            -90.9559740001237,
                            34.1201249999502
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 32.6864714,
            "name": "Mississippi",
            "intptlon": -89.6561377,
            "geo_point_2d": [
                32.7129274935,
                -89.6533717564
            ],
            "geoid": "28",
            "mtfcc": "G4000",
            "region": 3
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -89.6533717564,
                32.7129274935
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "e856eeef2049a684f54cc07039c5c750388fa4ee",
        "fields": {
            "arealand": "-222385618",
            "objectid": 14,
            "basename": "California",
            "stusab": "CA",
            "statens": "01779778",
            "centlon": -119.527746,
            "state": "06",
            "gid": 48,
            "centlat": 37.1547352,
            "division": 9,
            "areawater": "-1011556647",
            "oid": "239405626",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                -124.136560999568,
                                41.4644520001748
                            ],
                            [
                                -124.144127999686,
                                41.4868809999681
                            ],
                            [
                                -124.151304999817,
                                41.4998419999228
                            ],
                            [
                                -124.151821000201,
                                41.5082140000472
                            ],
                            [
                                -124.157942999997,
                                41.5189619997364
                            ],
                            [
                                -124.160111000392,
                                41.5293859999524
                            ],
                            [
                                -124.160492999984,
                                41.5434089997226
                            ],
                            [
                                -124.172273999795,
                                41.5509789998463
                            ],
                            [
                                -124.179960999564,
                                41.5583250000071
                            ],
                            [
                                -124.18499000029,
                                41.5657689998144
                            ],
                            [
                                -124.188336000144,
                                41.5739960002055
                            ],
                            [
                                -124.187126999824,
                                41.5889699998552
                            ],
                            [
                                -124.189188000089,
                                41.5917800002224
                            ],
                            [
                                -124.190532999953,
                                41.5987400001524
                            ],
                            [
                                -124.190112999828,
                                41.6081900002046
                            ],
                            [
                                -124.201339999621,
                                41.6194039997555
                            ],
                            [
                                -124.209784999623,
                                41.6352049998289
                            ],
                            [
                                -124.21141900009,
                                41.6416880001727
                            ],
                            [
                                -124.210489000328,
                                41.6494630000949
                            ],
                            [
                                -124.211783999963,
                                41.661907000247
                            ],
                            [
                                -124.208546000064,
                                41.6738159996739
                            ],
                            [
                                -124.211706000145,
                                41.6811929999274
                            ],
                            [
                                -124.222604999875,
                                41.6852669998502
                            ],
                            [
                                -124.236856000204,
                                41.6946400002946
                            ],
                            [
                                -124.249473000067,
                                41.6969579996697
                            ],
                            [
                                -124.262421000365,
                                41.7018570003068
                            ],
                            [
                                -124.278061999866,
                                41.7096739997376
                            ],
                            [
                                -124.300301000264,
                                41.7241449999237
                            ],
                            [
                                -124.330609000022,
                                41.733792999931
                            ],
                            [
                                -124.369187999704,
                                41.7634119997706
                            ],
                            [
                                -124.376199999979,
                                41.7646280001714
                            ],
                            [
                                -124.392054999741,
                                41.7723770001241
                            ],
                            [
                                -124.41075699959,
                                41.7883120000338
                            ],
                            [
                                -124.41752600019,
                                41.8000170000359
                            ],
                            [
                                -124.429184999802,
                                41.8097259998921
                            ],
                            [
                                -124.435032999962,
                                41.8165809999269
                            ],
                            [
                                -124.438909000445,
                                41.8245039997768
                            ],
                            [
                                -124.440707000045,
                                41.8387400001338
                            ],
                            [
                                -124.435015000419,
                                41.8565069997977
                            ],
                            [
                                -124.426107999561,
                                41.868775999949
                            ],
                            [
                                -124.411317000354,
                                41.8820559998517
                            ],
                            [
                                -124.401242000083,
                                41.8855880001187
                            ],
                            [
                                -124.365199000143,
                                41.8865680001614
                            ],
                            [
                                -124.347647000164,
                                41.8831540002278
                            ],
                            [
                                -124.332984000106,
                                41.8769999998653
                            ],
                            [
                                -124.311935999587,
                                41.8587880002942
                            ],
                            [
                                -124.298003999997,
                                41.8606700000252
                            ],
                            [
                                -124.285030999585,
                                41.8594869999438
                            ],
                            [
                                -124.27964899958,
                                41.8748359998887
                            ],
                            [
                                -124.280732000402,
                                41.8748359998887
                            ],
                            [
                                -124.276120000326,
                                41.9030520002619
                            ],
                            [
                                -124.285686999711,
                                41.9129160000971
                            ],
                            [
                                -124.289938999937,
                                41.920057000303
                            ],
                            [
                                -124.291939000102,
                                41.9284980001635
                            ],
                            [
                                -124.289926000416,
                                41.9454319998878
                            ],
                            [
                                -124.284768999644,
                                41.9568339997963
                            ],
                            [
                                -124.288578000179,
                                41.9695639998386
                            ],
                            [
                                -124.301184000171,
                                41.9829840001788
                            ],
                            [
                                -124.306994999623,
                                41.992906000134
                            ],
                            [
                                -124.328834999812,
                                41.9983340000251
                            ],
                            [
                                -123.810907999885,
                                41.9951639998154
                            ],
                            [
                                -123.728169000323,
                                41.9970559998112
                            ],
                            [
                                -123.657212000396,
                                41.9950940002023
                            ],
                            [
                                -123.624022000391,
                                41.9997699998165
                            ],
                            [
                                -123.564204000394,
                                42.0007059999163
                            ],
                            [
                                -123.346954999993,
                                41.9992760001472
                            ],
                            [
                                -123.183923000199,
                                42.0057889998447
                            ],
                            [
                                -123.145735000329,
                                42.0087609998891
                            ],
                            [
                                -123.043996999918,
                                42.0030840000773
                            ],
                            [
                                -122.7027870004,
                                42.0042240002237
                            ],
                            [
                                -122.378049000411,
                                42.0095030001829
                            ],
                            [
                                -121.344911000224,
                                41.9962140001272
                            ],
                            [
                                -121.168476999672,
                                41.9971779999419
                            ],
                            [
                                -120.990083000037,
                                41.9930560001705
                            ],
                            [
                                -120.798116999764,
                                41.9939509996729
                            ],
                            [
                                -120.326386999691,
                                41.992586000032
                            ],
                            [
                                -119.999234000333,
                                41.9949440002954
                            ],
                            [
                                -119.998286999954,
                                41.6188180003087
                            ],
                            [
                                -119.999875000391,
                                40.9998940001065
                            ],
                            [
                                -119.995976000342,
                                40.57054999967
                            ],
                            [
                                -119.995705000181,
                                40.3758299997791
                            ],
                            [
                                -119.997706000171,
                                39.9999050001949
                            ],
                            [
                                -120.001133000217,
                                39.7522290002301
                            ],
                            [
                                -120.000820000222,
                                39.606828000071
                            ],
                            [
                                -120.002909000076,
                                39.4644019999406
                            ],
                            [
                                -120.006126999884,
                                39.3741060000853
                            ],
                            [
                                -120.006473000389,
                                39.2728780003376
                            ],
                            [
                                -120.001014000391,
                                38.9995739998671
                            ],
                            [
                                -119.453823000271,
                                38.6224329997542
                            ],
                            [
                                -119.125982000102,
                                38.3931700002632
                            ],
                            [
                                -118.714201000446,
                                38.1021049999393
                            ],
                            [
                                -118.052188999899,
                                37.6249300001613
                            ],
                            [
                                -117.904625000253,
                                37.5158359997378
                            ],
                            [
                                -117.875926999775,
                                37.497267000023
                            ],
                            [
                                -117.712357999681,
                                37.3749309998151
                            ],
                            [
                                -117.680609999624,
                                37.3533989997183
                            ],
                            [
                                -117.581417999901,
                                37.2789359998489
                            ],
                            [
                                -117.500117000089,
                                37.2203799998619
                            ],
                            [
                                -116.874253999787,
                                36.7520809998818
                            ],
                            [
                                -116.488233000286,
                                36.4590969997845
                            ],
                            [
                                -116.094166000092,
                                36.156262999646
                            ],
                            [
                                -115.695436999593,
                                35.8462579998167
                            ],
                            [
                                -115.259720000222,
                                35.503540999858
                            ],
                            [
                                -115.100957000373,
                                35.3756810000582
                            ],
                            [
                                -114.797340999625,
                                35.1349270001186
                            ],
                            [
                                -114.633487000024,
                                35.0018569999241
                            ],
                            [
                                -114.62944300021,
                                34.9918249999528
                            ],
                            [
                                -114.629129000391,
                                34.9861319998263
                            ],
                            [
                                -114.635236999944,
                                34.9651490001662
                            ],
                            [
                                -114.634953000262,
                                34.9589180001021
                            ],
                            [
                                -114.629768999725,
                                34.9430399999549
                            ],
                            [
                                -114.633252999672,
                                34.9246079996703
                            ],
                            [
                                -114.630876999763,
                                34.907263000249
                            ],
                            [
                                -114.636724999923,
                                34.8891070000245
                            ],
                            [
                                -114.634381999626,
                                34.8728899999581
                            ],
                            [
                                -114.630681999769,
                                34.8663519996439
                            ],
                            [
                                -114.623939000007,
                                34.8597380003241
                            ],
                            [
                                -114.600652999671,
                                34.8473610001277
                            ],
                            [
                                -114.586841999557,
                                34.8356720002071
                            ],
                            [
                                -114.576451999641,
                                34.8152999996909
                            ],
                            [
                                -114.57101000026,
                                34.7942939999411
                            ],
                            [
                                -114.55865299979,
                                34.7738519998061
                            ],
                            [
                                -114.552681999607,
                                34.7668710001902
                            ],
                            [
                                -114.529614999554,
                                34.750821999943
                            ],
                            [
                                -114.516618999575,
                                34.7367449998607
                            ],
                            [
                                -114.492016999687,
                                34.7257019999347
                            ],
                            [
                                -114.487508000442,
                                34.7166259999096
                            ],
                            [
                                -114.48277900019,
                                34.7145110003303
                            ],
                            [
                                -114.473682000259,
                                34.7139639998759
                            ],
                            [
                                -114.470476999972,
                                34.7113679996688
                            ],
                            [
                                -114.46524599958,
                                34.6912020001372
                            ],
                            [
                                -114.455536000079,
                                34.6773350002106
                            ],
                            [
                                -114.454305000018,
                                34.6712339998288
                            ],
                            [
                                -114.450506000427,
                                34.6668359996652
                            ],
                            [
                                -114.451785000169,
                                34.6638910002644
                            ],
                            [
                                -114.457701999926,
                                34.6593280003603
                            ],
                            [
                                -114.457984999783,
                                34.6571129998036
                            ],
                            [
                                -114.449549000002,
                                34.6514229997706
                            ],
                            [
                                -114.441464999674,
                                34.6425300001632
                            ],
                            [
                                -114.440293999888,
                                34.6382400002883
                            ],
                            [
                                -114.441397999727,
                                34.6301709997853
                            ],
                            [
                                -114.438738999961,
                                34.6214550000576
                            ],
                            [
                                -114.424202000299,
                                34.6104530003166
                            ],
                            [
                                -114.425337999926,
                                34.600841999967
                            ],
                            [
                                -114.436809999941,
                                34.5960740001477
                            ],
                            [
                                -114.422382000059,
                                34.5807109999993
                            ],
                            [
                                -114.405227999566,
                                34.5696369998726
                            ],
                            [
                                -114.380838000288,
                                34.5297240002774
                            ],
                            [
                                -114.378223000005,
                                34.5165210003569
                            ],
                            [
                                -114.37812400027,
                                34.5072880000412
                            ],
                            [
                                -114.382358000055,
                                34.4957579996309
                            ],
                            [
                                -114.381701000104,
                                34.4760400002075
                            ],
                            [
                                -114.387186999866,
                                34.4620210001845
                            ],
                            [
                                -114.386698999969,
                                34.4579109997009
                            ],
                            [
                                -114.373718999884,
                                34.4469379997967
                            ],
                            [
                                -114.342615000258,
                                34.4514419998156
                            ],
                            [
                                -114.335372000004,
                                34.4500379996415
                            ],
                            [
                                -114.330668999692,
                                34.4452950002357
                            ],
                            [
                                -114.326130000309,
                                34.4372510002063
                            ],
                            [
                                -114.312251000423,
                                34.4327259997448
                            ],
                            [
                                -114.301016000235,
                                34.4268070002088
                            ],
                            [
                                -114.294835999814,
                                34.4213889996615
                            ],
                            [
                                -114.292225999553,
                                34.4176059998756
                            ],
                            [
                                -114.291751000075,
                                34.4111039997171
                            ],
                            [
                                -114.288662999964,
                                34.4066230003178
                            ],
                            [
                                -114.280108000357,
                                34.403147000207
                            ],
                            [
                                -114.264317000169,
                                34.4013290001697
                            ],
                            [
                                -114.252738999849,
                                34.3901000000076
                            ],
                            [
                                -114.234274999652,
                                34.3766620000794
                            ],
                            [
                                -114.22968600004,
                                34.3689079997616
                            ],
                            [
                                -114.226106999659,
                                34.3659160003232
                            ],
                            [
                                -114.213774000377,
                                34.362459999954
                            ],
                            [
                                -114.199482000039,
                                34.3613729999983
                            ],
                            [
                                -114.176908999731,
                                34.3493059998711
                            ],
                            [
                                -114.168806999861,
                                34.3395129998878
                            ],
                            [
                                -114.157205999975,
                                34.3178620001229
                            ],
                            [
                                -114.138282000368,
                                34.3032299999102
                            ],
                            [
                                -114.139534000346,
                                34.2958439996355
                            ],
                            [
                                -114.136050000399,
                                34.2808330003012
                            ],
                            [
                                -114.136671000365,
                                34.2743769997511
                            ],
                            [
                                -114.131210999644,
                                34.2627299998709
                            ],
                            [
                                -114.131489000376,
                                34.2603869996707
                            ],
                            [
                                -114.135450000349,
                                34.2578860001442
                            ],
                            [
                                -114.144779000083,
                                34.2596230001518
                            ],
                            [
                                -114.153346000284,
                                34.2582890000047
                            ],
                            [
                                -114.164648000419,
                                34.2596989998628
                            ],
                            [
                                -114.163866999721,
                                34.2533490003366
                            ],
                            [
                                -114.166123999805,
                                34.2500149998975
                            ],
                            [
                                -114.174597000294,
                                34.2473029999875
                            ],
                            [
                                -114.178050000279,
                                34.2399690000097
                            ],
                            [
                                -114.190876000379,
                                34.230857999729
                            ],
                            [
                                -114.211760999793,
                                34.211538999889
                            ],
                            [
                                -114.225193999615,
                                34.2036419997349
                            ],
                            [
                                -114.224940999895,
                                34.1938959997231
                            ],
                            [
                                -114.227033999947,
                                34.1888659998951
                            ],
                            [
                                -114.229715000352,
                                34.1869280002699
                            ],
                            [
                                -114.240711999991,
                                34.1832320000931
                            ],
                            [
                                -114.244421000069,
                                34.1794030003606
                            ],
                            [
                                -114.257033999733,
                                34.1728369998884
                            ],
                            [
                                -114.268459999717,
                                34.1701770000703
                            ],
                            [
                                -114.275266999952,
                                34.1721489997966
                            ],
                            [
                                -114.28729399981,
                                34.1705290001584
                            ],
                            [
                                -114.29280600041,
                                34.1667249999847
                            ],
                            [
                                -114.312206000217,
                                34.1447760000247
                            ],
                            [
                                -114.320776999718,
                                34.138634999845
                            ],
                            [
                                -114.336111999796,
                                34.1340339998253
                            ],
                            [
                                -114.348050999791,
                                34.1344570003641
                            ],
                            [
                                -114.353030000288,
                                34.1331199998217
                            ],
                            [
                                -114.360402000413,
                                34.1235770001411
                            ],
                            [
                                -114.366516999638,
                                34.1185769998982
                            ],
                            [
                                -114.379223000087,
                                34.1159899999295
                            ],
                            [
                                -114.390565000405,
                                34.110084000353
                            ],
                            [
                                -114.401336000088,
                                34.1116380002988
                            ],
                            [
                                -114.411679999973,
                                34.1100309999868
                            ],
                            [
                                -114.420499000069,
                                34.1034660001573
                            ],
                            [
                                -114.428026000005,
                                34.0927869999101
                            ],
                            [
                                -114.434181000312,
                                34.0873789997436
                            ],
                            [
                                -114.439339999836,
                                34.0578929998373
                            ],
                            [
                                -114.434948999693,
                                34.0377840003627
                            ],
                            [
                                -114.438266000133,
                                34.0226089999267
                            ],
                            [
                                -114.44382100039,
                                34.0161760001066
                            ],
                            [
                                -114.450205999953,
                                34.0125739999092
                            ],
                            [
                                -114.454807000158,
                                34.0109680003066
                            ],
                            [
                                -114.463335999826,
                                34.0122589998225
                            ],
                            [
                                -114.465867000444,
                                34.0109869998183
                            ],
                            [
                                -114.467310000217,
                                34.0051899998307
                            ],
                            [
                                -114.459184000057,
                                34.0000159996746
                            ],
                            [
                                -114.458028000339,
                                33.9971580003412
                            ],
                            [
                                -114.459257999677,
                                33.9947110002695
                            ],
                            [
                                -114.467932000008,
                                33.9928770002875
                            ],
                            [
                                -114.471138000121,
                                33.9880400001696
                            ],
                            [
                                -114.481455000242,
                                33.9812609998295
                            ],
                            [
                                -114.484784000378,
                                33.9755190003477
                            ],
                            [
                                -114.499882999556,
                                33.9617890000441
                            ],
                            [
                                -114.50866900004,
                                33.9575249998096
                            ],
                            [
                                -114.515860000415,
                                33.9581059997912
                            ],
                            [
                                -114.522733999699,
                                33.9550390001604
                            ],
                            [
                                -114.528679999768,
                                33.9478170002516
                            ],
                            [
                                -114.535676000149,
                                33.9335519996424
                            ],
                            [
                                -114.534951000426,
                                33.925700000347
                            ],
                            [
                                -114.525360999678,
                                33.9222719997147
                            ],
                            [
                                -114.518433999792,
                                33.9175180001753
                            ],
                            [
                                -114.511511000105,
                                33.9110919998919
                            ],
                            [
                                -114.507920000028,
                                33.9038070003415
                            ],
                            [
                                -114.508708000398,
                                33.9006399996971
                            ],
                            [
                                -114.513714999586,
                                33.8979589996314
                            ],
                            [
                                -114.525872000039,
                                33.9010080000101
                            ],
                            [
                                -114.522768000034,
                                33.8925830000528
                            ],
                            [
                                -114.504339999822,
                                33.876881999907
                            ],
                            [
                                -114.503016999699,
                                33.867998000008
                            ],
                            [
                                -114.50563799983,
                                33.864275999828
                            ],
                            [
                                -114.514672999836,
                                33.858638000135
                            ],
                            [
                                -114.524291999998,
                                33.8601329999573
                            ],
                            [
                                -114.529883000241,
                                33.857562999755
                            ],
                            [
                                -114.52959700001,
                                33.8480629997453
                            ],
                            [
                                -114.519970000351,
                                33.8253810003531
                            ],
                            [
                                -114.522713999607,
                                33.8189789999425
                            ],
                            [
                                -114.528049999582,
                                33.8149629999611
                            ],
                            [
                                -114.518941999781,
                                33.7973019997072
                            ],
                            [
                                -114.516734000101,
                                33.7883450002618
                            ],
                            [
                                -114.504862999878,
                                33.7604650001888
                            ],
                            [
                                -114.504482999937,
                                33.7509979996312
                            ],
                            [
                                -114.512347999981,
                                33.7342139998181
                            ],
                            [
                                -114.50417599979,
                                33.728054999944
                            ],
                            [
                                -114.500788000103,
                                33.7222039997931
                            ],
                            [
                                -114.496565000189,
                                33.719154999932
                            ],
                            [
                                -114.494196999778,
                                33.7079220002624
                            ],
                            [
                                -114.496489000021,
                                33.696901000083
                            ],
                            [
                                -114.523958999912,
                                33.6858790001813
                            ],
                            [
                                -114.530347999673,
                                33.6792449996835
                            ],
                            [
                                -114.531522999658,
                                33.6751080001403
                            ],
                            [
                                -114.532122999707,
                                33.6697020001327
                            ],
                            [
                                -114.53026700038,
                                33.6668209997697
                            ],
                            [
                                -114.526438999579,
                                33.663880000266
                            ],
                            [
                                -114.517112000393,
                                33.6628770002753
                            ],
                            [
                                -114.514056999893,
                                33.6601789999654
                            ],
                            [
                                -114.514559000033,
                                33.6580139999723
                            ],
                            [
                                -114.518336999708,
                                33.6559269999056
                            ],
                            [
                                -114.530582999849,
                                33.6544609999774
                            ],
                            [
                                -114.533193999935,
                                33.651660000203
                            ],
                            [
                                -114.533214999852,
                                33.6484429996604
                            ],
                            [
                                -114.525394000188,
                                33.6406690001536
                            ],
                            [
                                -114.523801999554,
                                33.6347000000093
                            ],
                            [
                                -114.526369999982,
                                33.6302589997495
                            ],
                            [
                                -114.531033999936,
                                33.6282129998021
                            ],
                            [
                                -114.531214999686,
                                33.6239130002751
                            ],
                            [
                                -114.521844999944,
                                33.612544000031
                            ],
                            [
                                -114.529186000107,
                                33.6066500001862
                            ],
                            [
                                -114.540617000113,
                                33.591412000232
                            ],
                            [
                                -114.540299999921,
                                33.5806150001141
                            ],
                            [
                                -114.536784000187,
                                33.5709589997949
                            ],
                            [
                                -114.531613000069,
                                33.5617019998336
                            ],
                            [
                                -114.528220000359,
                                33.5593179998278
                            ],
                            [
                                -114.524215000006,
                                33.5530679997355
                            ],
                            [
                                -114.558898000013,
                                33.5318189997814
                            ],
                            [
                                -114.560962999577,
                                33.5167389999055
                            ],
                            [
                                -114.569533000151,
                                33.5092189998394
                            ],
                            [
                                -114.580467999865,
                                33.506465000001
                            ],
                            [
                                -114.592369000225,
                                33.4986750001603
                            ],
                            [
                                -114.601693999761,
                                33.4813959998256
                            ],
                            [
                                -114.612472000015,
                                33.4707680001652
                            ],
                            [
                                -114.622918000008,
                                33.4565609998138
                            ],
                            [
                                -114.622282999798,
                                33.4475580000116
                            ],
                            [
                                -114.629639999854,
                                33.42813699965
                            ],
                            [
                                -114.643302000005,
                                33.4167459996419
                            ],
                            [
                                -114.649540000152,
                                33.4136330002168
                            ],
                            [
                                -114.656734999827,
                                33.4128130002564
                            ],
                            [
                                -114.673690999953,
                                33.4191570002595
                            ],
                            [
                                -114.687949999781,
                                33.417933999699
                            ],
                            [
                                -114.695658000364,
                                33.4151279996741
                            ],
                            [
                                -114.69770799986,
                                33.41094199963
                            ],
                            [
                                -114.701787999658,
                                33.4083769998045
                            ],
                            [
                                -114.720065000258,
                                33.4078909999378
                            ],
                            [
                                -114.725282000407,
                                33.4050480001963
                            ],
                            [
                                -114.724249999639,
                                33.4004200001815
                            ],
                            [
                                -114.707308999581,
                                33.382540000008
                            ],
                            [
                                -114.707347999939,
                                33.3766269997882
                            ],
                            [
                                -114.699056000097,
                                33.3611479996339
                            ],
                            [
                                -114.698035000098,
                                33.3524420003696
                            ],
                            [
                                -114.700938000262,
                                33.3370140001415
                            ],
                            [
                                -114.70787000017,
                                33.3233160002414
                            ],
                            [
                                -114.723622999825,
                                33.3121090001766
                            ],
                            [
                                -114.72990399963,
                                33.3057449997852
                            ],
                            [
                                -114.731222999555,
                                33.3024330002204
                            ],
                            [
                                -114.721670000414,
                                33.2869820000651
                            ],
                            [
                                -114.694449000044,
                                33.279785000309
                            ],
                            [
                                -114.680507000409,
                                33.2735760001751
                            ],
                            [
                                -114.677031999785,
                                33.2701690002306
                            ],
                            [
                                -114.672088000346,
                                33.2584990001751
                            ],
                            [
                                -114.674491000019,
                                33.2555970000143
                            ],
                            [
                                -114.689540999692,
                                33.2464280002242
                            ],
                            [
                                -114.673625999656,
                                33.2231209998096
                            ],
                            [
                                -114.678749000093,
                                33.203448000124
                            ],
                            [
                                -114.675358999858,
                                33.185488000051
                            ],
                            [
                                -114.680889999826,
                                33.169074000152
                            ],
                            [
                                -114.679944999994,
                                33.1590590001759
                            ],
                            [
                                -114.687404999983,
                                33.1419830003167
                            ],
                            [
                                -114.696913999642,
                                33.1311190002023
                            ],
                            [
                                -114.703682000417,
                                33.1137680000752
                            ],
                            [
                                -114.70789600011,
                                33.097430999681
                            ],
                            [
                                -114.706487999598,
                                33.0881600000586
                            ],
                            [
                                -114.689020000184,
                                33.084035000087
                            ],
                            [
                                -114.689119999743,
                                33.0761209997059
                            ],
                            [
                                -114.686990999706,
                                33.0709680000729
                            ],
                            [
                                -114.674295000201,
                                33.0571690002503
                            ],
                            [
                                -114.675102999765,
                                33.0475299999263
                            ],
                            [
                                -114.673659000166,
                                33.0418959997568
                            ],
                            [
                                -114.665060000178,
                                33.0339060003692
                            ],
                            [
                                -114.662316999848,
                                33.0326700001964
                            ],
                            [
                                -114.657826999971,
                                33.0338239996895
                            ],
                            [
                                -114.649001000202,
                                33.0467619998621
                            ],
                            [
                                -114.645979000213,
                                33.0489019999723
                            ],
                            [
                                -114.639551999919,
                                33.0452900000327
                            ],
                            [
                                -114.628294000165,
                                33.03105000031
                            ],
                            [
                                -114.61878799998,
                                33.0272020001241
                            ],
                            [
                                -114.601014000244,
                                33.0254100000797
                            ],
                            [
                                -114.589778000231,
                                33.0262279999847
                            ],
                            [
                                -114.584765000297,
                                33.028230000122
                            ],
                            [
                                -114.579369999873,
                                33.0345630001578
                            ],
                            [
                                -114.575161000204,
                                33.0365410001176
                            ],
                            [
                                -114.538458999764,
                                33.0334219999004
                            ],
                            [
                                -114.520130000184,
                                33.0299839999246
                            ],
                            [
                                -114.511342999875,
                                33.0234549997461
                            ],
                            [
                                -114.502870000284,
                                33.0111539996917
                            ],
                            [
                                -114.499797000241,
                                33.0039049999807
                            ],
                            [
                                -114.492938000127,
                                32.9717809996967
                            ],
                            [
                                -114.488624999802,
                                32.9699460003102
                            ],
                            [
                                -114.476531999821,
                                32.9751739996663
                            ],
                            [
                                -114.470511000307,
                                32.9738580001708
                            ],
                            [
                                -114.468604999853,
                                32.9716490002934
                            ],
                            [
                                -114.46731500024,
                                32.9640600003178
                            ],
                            [
                                -114.46806099988,
                                32.9552600002288
                            ],
                            [
                                -114.470768000225,
                                32.9494239998677
                            ],
                            [
                                -114.480924999614,
                                32.9362760002656
                            ],
                            [
                                -114.476639999776,
                                32.9236280001936
                            ],
                            [
                                -114.464448000062,
                                32.9131279997402
                            ],
                            [
                                -114.46292900012,
                                32.9079439998112
                            ],
                            [
                                -114.468970999551,
                                32.8451550000409
                            ],
                            [
                                -114.494115999587,
                                32.8232869996422
                            ],
                            [
                                -114.510326999899,
                                32.8164879997865
                            ],
                            [
                                -114.522030999717,
                                32.801674999808
                            ],
                            [
                                -114.529632999996,
                                32.7954769997985
                            ],
                            [
                                -114.531669000146,
                                32.7911850001968
                            ],
                            [
                                -114.532432000402,
                                32.776921999978
                            ],
                            [
                                -114.528442999942,
                                32.7672760001137
                            ],
                            [
                                -114.526856000228,
                                32.7570940000453
                            ],
                            [
                                -114.539092000324,
                                32.7569490003357
                            ],
                            [
                                -114.53922399967,
                                32.7498120003363
                            ],
                            [
                                -114.564447000422,
                                32.7495539997746
                            ],
                            [
                                -114.564507999623,
                                32.7422740002121
                            ],
                            [
                                -114.581735999737,
                                32.7423200001372
                            ],
                            [
                                -114.581784000316,
                                32.7349460003693
                            ],
                            [
                                -114.615112000413,
                                32.7345150000883
                            ],
                            [
                                -114.615669999732,
                                32.728453999819
                            ],
                            [
                                -114.617395000436,
                                32.728256999714
                            ],
                            [
                                -114.688230000163,
                                32.7375299997095
                            ],
                            [
                                -114.701581999794,
                                32.7456320003384
                            ],
                            [
                                -114.714521999697,
                                32.7303899998469
                            ],
                            [
                                -114.717695000197,
                                32.7215470001937
                            ],
                            [
                                -114.720288999666,
                                32.7187009997836
                            ],
                            [
                                -115.465163999624,
                                32.667100000021
                            ],
                            [
                                -115.875842000163,
                                32.636423999857
                            ],
                            [
                                -116.390090999975,
                                32.5960200000485
                            ],
                            [
                                -117.1248560004,
                                32.534155999953
                            ],
                            [
                                -117.204916999929,
                                32.5288320002052
                            ],
                            [
                                -117.210025000298,
                                32.5546339999254
                            ],
                            [
                                -117.210025000298,
                                32.5728829997962
                            ],
                            [
                                -117.214111999769,
                                32.5947809997908
                            ],
                            [
                                -117.218195999765,
                                32.6102920001781
                            ],
                            [
                                -117.223301999586,
                                32.6212380003584
                            ],
                            [
                                -117.2335130003,
                                32.6166779997752
                            ],
                            [
                                -117.246786000288,
                                32.6148539997336
                            ],
                            [
                                -117.255092000373,
                                32.615473999919
                            ],
                            [
                                -117.268788999786,
                                32.6194149996532
                            ],
                            [
                                -117.276915999771,
                                32.6234739999694
                            ],
                            [
                                -117.297538000317,
                                32.6417690002424
                            ],
                            [
                                -117.309238999763,
                                32.6564010002564
                            ],
                            [
                                -117.320939000281,
                                32.6898469997929
                            ],
                            [
                                -117.325619999954,
                                32.731652999624
                            ],
                            [
                                -117.330299999802,
                                32.8048169996379
                            ],
                            [
                                -117.342000000321,
                                32.8403540001209
                            ],
                            [
                                -117.339660000397,
                                32.8591670003097
                            ],
                            [
                                -117.332639999726,
                                32.8821610000755
                            ],
                            [
                                -117.323279000205,
                                32.903064000146
                            ],
                            [
                                -117.375872000391,
                                33.0752170003395
                            ],
                            [
                                -117.435563000167,
                                33.177512000136
                            ],
                            [
                                -117.46787100009,
                                33.2124939998076
                            ],
                            [
                                -117.500875000323,
                                33.2421509997823
                            ],
                            [
                                -117.520999000029,
                                33.2688720001323
                            ],
                            [
                                -117.57153099995,
                                33.312302000346
                            ],
                            [
                                -117.625879999904,
                                33.3421089999768
                            ],
                            [
                                -117.636201000223,
                                33.3491280001061
                            ],
                            [
                                -117.647859999835,
                                33.3636349996577
                            ],
                            [
                                -117.653795000034,
                                33.3648179998206
                            ],
                            [
                                -117.713571000199,
                                33.4087769999106
                            ],
                            [
                                -117.735336000045,
                                33.4074360001597
                            ],
                            [
                                -117.741409000336,
                                33.4101179999561
                            ],
                            [
                                -117.765140000013,
                                33.4315390001269
                            ],
                            [
                                -117.790478000393,
                                33.4612410002927
                            ],
                            [
                                -117.793261999832,
                                33.4697820000905
                            ],
                            [
                                -117.811276999593,
                                33.4820190002314
                            ],
                            [
                                -117.816355999649,
                                33.4908720001188
                            ],
                            [
                                -117.934045999906,
                                33.5492540003575
                            ],
                            [
                                -118.000878999652,
                                33.5931650002907
                            ],
                            [
                                -118.033610999898,
                                33.6093539999289
                            ],
                            [
                                -118.04984800015,
                                33.6200149997678
                            ],
                            [
                                -118.075074000376,
                                33.639606000054
                            ],
                            [
                                -118.096472999626,
                                33.6536510001857
                            ],
                            [
                                -118.125900000026,
                                33.6971509996878
                            ],
                            [
                                -118.165830000319,
                                33.6915630001271
                            ],
                            [
                                -118.237008000178,
                                33.6905949996897
                            ],
                            [
                                -118.254528000369,
                                33.6750700002196
                            ],
                            [
                                -118.274238999623,
                                33.6634290000966
                            ],
                            [
                                -118.319134999995,
                                33.6595469999072
                            ],
                            [
                                -118.345414999833,
                                33.6634270000656
                            ],
                            [
                                -118.377171000285,
                                33.6789529997858
                            ],
                            [
                                -118.385930999932,
                                33.6867119997295
                            ],
                            [
                                -118.428637000167,
                                33.701266000009
                            ],
                            [
                                -118.466962000305,
                                33.7255240001781
                            ],
                            [
                                -118.485577000115,
                                33.7536639997548
                            ],
                            [
                                -118.487768000076,
                                33.7798650002011
                            ],
                            [
                                -118.484483000321,
                                33.8031540002871
                            ],
                            [
                                -118.469153000266,
                                33.8186790002285
                            ],
                            [
                                -118.450537999557,
                                33.8322650000146
                            ],
                            [
                                -118.443968000047,
                                33.8390570000781
                            ],
                            [
                                -118.447253999627,
                                33.8487599999109
                            ],
                            [
                                -118.476819999943,
                                33.8895129999226
                            ],
                            [
                                -118.500912000193,
                                33.9186210002555
                            ],
                            [
                                -118.508626999551,
                                33.9325169998918
                            ],
                            [
                                -118.523446999968,
                                33.9530490000191
                            ],
                            [
                                -118.533473999661,
                                33.961794000222
                            ],
                            [
                                -118.548417000102,
                                33.9799699999653
                            ],
                            [
                                -118.557355999849,
                                33.9876730000999
                            ],
                            [
                                -118.569687000379,
                                33.9888109997201
                            ],
                            [
                                -118.586577000383,
                                33.9870129999059
                            ],
                            [
                                -118.594079000204,
                                33.9880650000416
                            ],
                            [
                                -118.604388999754,
                                33.9862830003686
                            ],
                            [
                                -118.648347999792,
                                33.9867289996761
                            ],
                            [
                                -118.666514000064,
                                33.9805620001288
                            ],
                            [
                                -118.680814999724,
                                33.9791599996532
                            ],
                            [
                                -118.689144000274,
                                33.9799499998023
                            ],
                            [
                                -118.704573999888,
                                33.9778399997411
                            ],
                            [
                                -118.727459000366,
                                33.9803070000316
                            ],
                            [
                                -118.735527999726,
                                33.9770350000191
                            ],
                            [
                                -118.746391000369,
                                33.9755010000781
                            ],
                            [
                                -118.754411000223,
                                33.9677919999368
                            ],
                            [
                                -118.786528000351,
                                33.9514299997101
                            ],
                            [
                                -118.803613000349,
                                33.9472400002956
                            ],
                            [
                                -118.814752000278,
                                33.9472140002856
                            ],
                            [
                                -118.829381000001,
                                33.9503949997056
                            ],
                            [
                                -118.841115999781,
                                33.9553710001174
                            ],
                            [
                                -118.856845999869,
                                33.965969999758
                            ],
                            [
                                -118.873997999815,
                                33.9833139999482
                            ],
                            [
                                -118.88730099994,
                                33.987222999948
                            ],
                            [
                                -118.9524259996,
                                33.992958999701
                            ],
                            [
                                -118.964029000034,
                                33.9967540002338
                            ],
                            [
                                -118.985311999831,
                                34.0000079997095
                            ],
                            [
                                -119.000420999953,
                                34.0074670002028
                            ],
                            [
                                -119.027407000146,
                                34.0149190000577
                            ],
                            [
                                -119.116797000308,
                                34.0298229999627
                            ],
                            [
                                -119.15896299987,
                                34.040253000306
                            ],
                            [
                                -119.217993000395,
                                34.0624909998084
                            ],
                            [
                                -119.250942000376,
                                34.1062010002714
                            ],
                            [
                                -119.267733999572,
                                34.121251999847
                            ],
                            [
                                -119.285743000384,
                                34.1408049997677
                            ],
                            [
                                -119.2975690004,
                                34.1603160003674
                            ],
                            [
                                -119.32865399976,
                                34.2269059998049
                            ],
                            [
                                -119.334886999885,
                                34.2368650000482
                            ],
                            [
                                -119.350725999753,
                                34.240138999716
                            ],
                            [
                                -119.375935000261,
                                34.2498799996847
                            ],
                            [
                                -119.386332999675,
                                34.2570739998822
                            ],
                            [
                                -119.401994000166,
                                34.2722580000642
                            ],
                            [
                                -119.42861999961,
                                34.2777810000983
                            ],
                            [
                                -119.448981999866,
                                34.2846850003058
                            ],
                            [
                                -119.466994999977,
                                34.2936600001748
                            ],
                            [
                                -119.484223999915,
                                34.3067790003265
                            ],
                            [
                                -119.500953000261,
                                34.3269220003368
                            ],
                            [
                                -119.519471999812,
                                34.3360690002682
                            ],
                            [
                                -119.522909999728,
                                34.335919000033
                            ],
                            [
                                -119.527917999639,
                                34.338770999939
                            ],
                            [
                                -119.532653999564,
                                34.3387700000546
                            ],
                            [
                                -119.547849999725,
                                34.3443620002059
                            ],
                            [
                                -119.555828999572,
                                34.3450190002652
                            ],
                            [
                                -119.574544999664,
                                34.3538160003093
                            ],
                            [
                                -119.584794999838,
                                34.361660000205
                            ],
                            [
                                -119.590294000019,
                                34.3612089999686
                            ],
                            [
                                -119.602060999586,
                                34.3636540003374
                            ],
                            [
                                -119.617461999787,
                                34.3687300003399
                            ],
                            [
                                -119.62596200004,
                                34.3662159999753
                            ],
                            [
                                -119.651357000322,
                                34.3667080001047
                            ],
                            [
                                -119.666109999893,
                                34.3544640002056
                            ],
                            [
                                -119.697782999607,
                                34.3408669999531
                            ],
                            [
                                -119.716128999804,
                                34.3361079999739
                            ],
                            [
                                -119.732022999923,
                                34.3396959999077
                            ],
                            [
                                -119.79706700029,
                                34.3653310002106
                            ],
                            [
                                -119.805805000196,
                                34.3653400000265
                            ],
                            [
                                -119.823147999937,
                                34.35704700016
                            ],
                            [
                                -119.8379209996,
                                34.3543179997803
                            ],
                            [
                                -119.855270000087,
                                34.3543299998757
                            ],
                            [
                                -119.865897999654,
                                34.3571859996616
                            ],
                            [
                                -119.875969999553,
                                34.3556409997601
                            ],
                            [
                                -119.89152100044,
                                34.3574570003425
                            ],
                            [
                                -119.905420000417,
                                34.364370999712
                            ],
                            [
                                -119.916529000208,
                                34.3726099997721
                            ],
                            [
                                -119.923394999995,
                                34.3734529997509
                            ],
                            [
                                -120.009415999837,
                                34.4015350002315
                            ],
                            [
                                -120.024355999905,
                                34.4093430001782
                            ],
                            [
                                -120.036135999891,
                                34.4099480003642
                            ],
                            [
                                -120.04481400042,
                                34.4124540000469
                            ],
                            [
                                -120.056787999677,
                                34.4099350003083
                            ],
                            [
                                -120.076435000255,
                                34.4111159998292
                            ],
                            [
                                -120.090669999793,
                                34.4092859999044
                            ],
                            [
                                -120.10830899981,
                                34.4128909999176
                            ],
                            [
                                -120.1351200001,
                                34.4220609999332
                            ],
                            [
                                -120.147280000028,
                                34.422915999779
                            ],
                            [
                                -120.163636000105,
                                34.4203610001706
                            ],
                            [
                                -120.191753999727,
                                34.4199880003091
                            ],
                            [
                                -120.202828000256,
                                34.4222079997127
                            ],
                            [
                                -120.240604000398,
                                34.4174560003567
                            ],
                            [
                                -120.280221999624,
                                34.4162979997281
                            ],
                            [
                                -120.287125000118,
                                34.4176089996706
                            ],
                            [
                                -120.324511000273,
                                34.4097479996783
                            ],
                            [
                                -120.340002999811,
                                34.4094239996422
                            ],
                            [
                                -120.348871000311,
                                34.4073240002739
                            ],
                            [
                                -120.36733499961,
                                34.4084859998183
                            ],
                            [
                                -120.386921999914,
                                34.4056529998112
                            ],
                            [
                                -120.390591999634,
                                34.4032970001877
                            ],
                            [
                                -120.408527999752,
                                34.3986529997345
                            ],
                            [
                                -120.42603200005,
                                34.3967449999613
                            ],
                            [
                                -120.443640999929,
                                34.3919649997013
                            ],
                            [
                                -120.456015000117,
                                34.3914849996413
                            ],
                            [
                                -120.475218000282,
                                34.3966630001469
                            ],
                            [
                                -120.488091000237,
                                34.3982780003317
                            ],
                            [
                                -120.500999000352,
                                34.4031360002918
                            ],
                            [
                                -120.523646000281,
                                34.4195049997307
                            ],
                            [
                                -120.535571999857,
                                34.4395209997955
                            ],
                            [
                                -120.535663999918,
                                34.4526950001029
                            ],
                            [
                                -120.544585999946,
                                34.4602209996582
                            ],
                            [
                                -120.553104999568,
                                34.471009999929
                            ],
                            [
                                -120.559438999975,
                                34.4884659999047
                            ],
                            [
                                -120.581510000143,
                                34.4966320000224
                            ],
                            [
                                -120.594333999695,
                                34.5040720002043
                            ],
                            [
                                -120.60948100035,
                                34.5019099996324
                            ],
                            [
                                -120.642360000011,
                                34.5037650003149
                            ],
                            [
                                -120.656058000147,
                                34.508597999749
                            ],
                            [
                                -120.684528999946,
                                34.5271309997276
                            ],
                            [
                                -120.694986999634,
                                34.5406340001898
                            ],
                            [
                                -120.706452999801,
                                34.5519069999756
                            ],
                            [
                                -120.71275700007,
                                34.5682970002024
                            ],
                            [
                                -120.713328999633,
                                34.5749600001178
                            ],
                            [
                                -120.712133999556,
                                34.5842019998684
                            ],
                            [
                                -120.707088999835,
                                34.5975009996689
                            ],
                            [
                                -120.708370000125,
                                34.6098529999396
                            ],
                            [
                                -120.705314999626,
                                34.621408000331
                            ],
                            [
                                -120.701283000406,
                                34.6300069998426
                            ],
                            [
                                -120.686479999707,
                                34.6450229997271
                            ],
                            [
                                -120.664690999571,
                                34.6963439998306
                            ],
                            [
                                -120.66584499964,
                                34.6994299999166
                            ],
                            [
                                -120.677308000332,
                                34.7097429998362
                            ],
                            [
                                -120.684655000342,
                                34.7210779996624
                            ],
                            [
                                -120.693234000239,
                                34.7300420003251
                            ],
                            [
                                -120.698275999587,
                                34.7378320001768
                            ],
                            [
                                -120.70209299962,
                                34.7483289998645
                            ],
                            [
                                -120.702225999689,
                                34.7602150000993
                            ],
                            [
                                -120.699795000427,
                                34.7708609997465
                            ],
                            [
                                -120.692888999559,
                                34.7826640000808
                            ],
                            [
                                -120.684420000166,
                                34.8036570001997
                            ],
                            [
                                -120.673281000237,
                                34.8386240001337
                            ],
                            [
                                -120.68409799995,
                                34.8445260000055
                            ],
                            [
                                -120.695600000102,
                                34.8545849996534
                            ],
                            [
                                -120.712967000133,
                                34.8629020001035
                            ],
                            [
                                -120.720319000166,
                                34.8692410002627
                            ],
                            [
                                -120.730678999945,
                                34.8864270003272
                            ],
                            [
                                -120.734382000174,
                                34.9014990002576
                            ],
                            [
                                -120.733417000252,
                                34.9101609998775
                            ],
                            [
                                -120.727230000158,
                                34.9233160003237
                            ],
                            [
                                -120.728506000425,
                                34.9316469998929
                            ],
                            [
                                -120.726753000132,
                                34.9438050002255
                            ],
                            [
                                -120.720262000264,
                                34.9562930000118
                            ],
                            [
                                -120.714440000043,
                                34.9635409997476
                            ],
                            [
                                -120.694714999647,
                                35.0288389998469
                            ],
                            [
                                -120.691655999848,
                                35.0603560002992
                            ],
                            [
                                -120.687802000005,
                                35.0774880001424
                            ],
                            [
                                -120.689606000352,
                                35.0988280001127
                            ],
                            [
                                -120.707094999683,
                                35.1042899997176
                            ],
                            [
                                -120.725502999804,
                                35.113575999746
                            ],
                            [
                                -120.738496000308,
                                35.1096400001295
                            ],
                            [
                                -120.752427000072,
                                35.1094560003623
                            ],
                            [
                                -120.782920999603,
                                35.111978000133
                            ],
                            [
                                -120.800410999657,
                                35.1165769998577
                            ],
                            [
                                -120.808391000227,
                                35.1205450002019
                            ],
                            [
                                -120.821990999555,
                                35.1314409999701
                            ],
                            [
                                -120.830593999742,
                                35.1318690001384
                            ],
                            [
                                -120.839420000409,
                                35.134654000311
                            ],
                            [
                                -120.856926000356,
                                35.1428120002718
                            ],
                            [
                                -120.871703000217,
                                35.1537089998304
                            ],
                            [
                                -120.876013999995,
                                35.1597179998201
                            ],
                            [
                                -120.896839000033,
                                35.1713429999654
                            ],
                            [
                                -120.917792000117,
                                35.1873779996857
                            ],
                            [
                                -120.927919000267,
                                35.1994329996837
                            ],
                            [
                                -120.93020200029,
                                35.2046349996995
                            ],
                            [
                                -120.937349000284,
                                35.2080469997631
                            ],
                            [
                                -120.949032000185,
                                35.2210200003064
                            ],
                            [
                                -120.960247000282,
                                35.2436219999087
                            ],
                            [
                                -120.961253000213,
                                35.2499749999146
                            ],
                            [
                                -120.959375000246,
                                35.2654369996709
                            ],
                            [
                                -120.953330000442,
                                35.2848469997945
                            ],
                            [
                                -120.928602000158,
                                35.3307109997765
                            ],
                            [
                                -120.923435000238,
                                35.3484130001592
                            ],
                            [
                                -120.928284999965,
                                35.3609619998463
                            ],
                            [
                                -120.930797000317,
                                35.3749739999233
                            ],
                            [
                                -120.930244000123,
                                35.3830870000599
                            ],
                            [
                                -120.92828599979,
                                35.3868429998934
                            ],
                            [
                                -120.933292000052,
                                35.3927479998432
                            ],
                            [
                                -120.959669999799,
                                35.3964940002256
                            ],
                            [
                                -120.976827999592,
                                35.4035569998759
                            ],
                            [
                                -120.98963199995,
                                35.4111390001563
                            ],
                            [
                                -121.005555000382,
                                35.4095030000593
                            ],
                            [
                                -121.015004999592,
                                35.4106289996674
                            ],
                            [
                                -121.041635000132,
                                35.4199899998913
                            ],
                            [
                                -121.052307999906,
                                35.4285460000506
                            ],
                            [
                                -121.069057000343,
                                35.4472520001825
                            ],
                            [
                                -121.082953999772,
                                35.4556430002722
                            ],
                            [
                                -121.092238000199,
                                35.4666720001852
                            ],
                            [
                                -121.100138000403,
                                35.4708780003388
                            ],
                            [
                                -121.111207999836,
                                35.481322000226
                            ],
                            [
                                -121.118170999707,
                                35.4906039996358
                            ],
                            [
                                -121.12826600007,
                                35.4956089996833
                            ],
                            [
                                -121.151056000113,
                                35.5164120002193
                            ],
                            [
                                -121.177756000076,
                                35.5612549999146
                            ],
                            [
                                -121.187704999951,
                                35.5690770001227
                            ],
                            [
                                -121.201555000423,
                                35.5836899997679
                            ],
                            [
                                -121.220123000377,
                                35.5868740001768
                            ],
                            [
                                -121.231811000302,
                                35.5940390002194
                            ],
                            [
                                -121.246588999988,
                                35.6003629997705
                            ],
                            [
                                -121.259810000098,
                                35.6017559997835
                            ],
                            [
                                -121.270415999924,
                                35.606478999756
                            ],
                            [
                                -121.286036000407,
                                35.6084359997266
                            ],
                            [
                                -121.309979999621,
                                35.6170950003333
                            ],
                            [
                                -121.323512000075,
                                35.624969000172
                            ],
                            [
                                -121.339644999671,
                                35.6424280002096
                            ],
                            [
                                -121.345601999612,
                                35.6559640001563
                            ],
                            [
                                -121.347720999603,
                                35.6649839996761
                            ],
                            [
                                -121.366362000251,
                                35.6804189999952
                            ],
                            [
                                -121.378959999846,
                                35.7060259998182
                            ],
                            [
                                -121.377149999652,
                                35.7164699996864
                            ],
                            [
                                -121.379327000267,
                                35.7269590002184
                            ],
                            [
                                -121.385628999989,
                                35.7336879997855
                            ],
                            [
                                -121.393738000431,
                                35.7499679998911
                            ],
                            [
                                -121.393459999698,
                                35.7634009996815
                            ],
                            [
                                -121.399753999922,
                                35.7653420002152
                            ],
                            [
                                -121.428495999883,
                                35.7859050003346
                            ],
                            [
                                -121.450627000325,
                                35.8097839997634
                            ],
                            [
                                -121.456097000194,
                                35.8177130001298
                            ],
                            [
                                -121.481606000278,
                                35.8293539999589
                            ],
                            [
                                -121.501047000093,
                                35.8427449997689
                            ],
                            [
                                -121.519192000449,
                                35.8634000000102
                            ],
                            [
                                -121.532010999977,
                                35.884797999962
                            ],
                            [
                                -121.541827999608,
                                35.9016599996398
                            ],
                            [
                                -121.561460999943,
                                35.9438179997139
                            ],
                            [
                                -121.589277000339,
                                35.9592740002141
                            ],
                            [
                                -121.620365000071,
                                35.969109000268
                            ],
                            [
                                -121.631817999819,
                                35.978944999765
                            ],
                            [
                                -121.648179999744,
                                35.9986169996679
                            ],
                            [
                                -121.674359000198,
                                36.0646609999341
                            ],
                            [
                                -121.70871800034,
                                36.1025999997937
                            ],
                            [
                                -121.727811999826,
                                36.1290089998362
                            ],
                            [
                                -121.738109999681,
                                36.1357780002115
                            ],
                            [
                                -121.747331000358,
                                36.1459929997466
                            ],
                            [
                                -121.770692999964,
                                36.1620139996666
                            ],
                            [
                                -121.787967000108,
                                36.1684679999773
                            ],
                            [
                                -121.802353999982,
                                36.1782999997161
                            ],
                            [
                                -121.831680999923,
                                36.1845949996449
                            ],
                            [
                                -121.846404000256,
                                36.1903420003003
                            ],
                            [
                                -121.880192999588,
                                36.2103140000313
                            ],
                            [
                                -121.893520999828,
                                36.2242909997247
                            ],
                            [
                                -121.901164000114,
                                36.2396530000482
                            ],
                            [
                                -121.911266999975,
                                36.2487560001596
                            ],
                            [
                                -121.92036100043,
                                36.2550519998093
                            ],
                            [
                                -121.93066000011,
                                36.2595569999208
                            ],
                            [
                                -121.944201999712,
                                36.269598000073
                            ],
                            [
                                -121.957666000394,
                                36.2851169998803
                            ],
                            [
                                -121.96317700027,
                                36.2987660002555
                            ],
                            [
                                -121.964613000371,
                                36.3069809997061
                            ],
                            [
                                -121.962872999599,
                                36.3227330001331
                            ],
                            [
                                -121.967364000199,
                                36.3341680002547
                            ],
                            [
                                -121.971876999642,
                                36.3578309997054
                            ],
                            [
                                -121.972285999896,
                                36.3728479998338
                            ],
                            [
                                -121.970391000211,
                                36.3833680000001
                            ],
                            [
                                -121.975637999599,
                                36.3925700002751
                            ],
                            [
                                -121.981042000243,
                                36.419633999683
                            ],
                            [
                                -121.992920999964,
                                36.4333980000438
                            ],
                            [
                                -121.997117999938,
                                36.4410010000511
                            ],
                            [
                                -122.000836000236,
                                36.4556790001539
                            ],
                            [
                                -121.999472000106,
                                36.4664509997517
                            ],
                            [
                                -122.008970999719,
                                36.4753320000193
                            ],
                            [
                                -122.02199799966,
                                36.508908000049
                            ],
                            [
                                -122.022707000388,
                                36.5222270002467
                            ],
                            [
                                -122.020519999726,
                                36.531916000037
                            ],
                            [
                                -122.017455000079,
                                36.5374050001885
                            ],
                            [
                                -122.029033000399,
                                36.5484999999217
                            ],
                            [
                                -122.037459000135,
                                36.5603809998032
                            ],
                            [
                                -122.044049999561,
                                36.578249000104
                            ],
                            [
                                -122.044148000369,
                                36.5913539998173
                            ],
                            [
                                -122.040733000019,
                                36.6001200001425
                            ],
                            [
                                -122.034247999999,
                                36.609673000006
                            ],
                            [
                                -122.020127000264,
                                36.6227980003297
                            ],
                            [
                                -122.019350999589,
                                36.6277609997234
                            ],
                            [
                                -122.014172999799,
                                36.6366820003127
                            ],
                            [
                                -122.001124000117,
                                36.6477040003425
                            ],
                            [
                                -122.068316999713,
                                36.87495300025
                            ],
                            [
                                -122.076399000391,
                                36.9101989996802
                            ],
                            [
                                -122.103039999904,
                                36.9162550002936
                            ],
                            [
                                -122.130569000245,
                                36.9253380001534
                            ],
                            [
                                -122.172024000327,
                                36.9426810000653
                            ],
                            [
                                -122.20932199972,
                                36.9616059999779
                            ],
                            [
                                -122.251082000299,
                                36.9858330001852
                            ],
                            [
                                -122.261048999717,
                                36.9934520000644
                            ],
                            [
                                -122.265654000121,
                                37.0001199998834
                            ],
                            [
                                -122.276374999574,
                                37.0248339998708
                            ],
                            [
                                -122.29031299991,
                                37.0320899999378
                            ],
                            [
                                -122.313898999821,
                                37.0547640000667
                            ],
                            [
                                -122.336414000403,
                                37.0538579996533
                            ],
                            [
                                -122.348207999735,
                                37.0556719998531
                            ],
                            [
                                -122.36000299979,
                                37.058392999851
                            ],
                            [
                                -122.376086000055,
                                37.0656479999972
                            ],
                            [
                                -122.385738999654,
                                37.0758930001646
                            ],
                            [
                                -122.393126999672,
                                37.087164000065
                            ],
                            [
                                -122.396875000108,
                                37.1027639998855
                            ],
                            [
                                -122.396916000116,
                                37.10988799996
                            ],
                            [
                                -122.404395000371,
                                37.1139329999983
                            ],
                            [
                                -122.413361999707,
                                37.1217910001637
                            ],
                            [
                                -122.4213470003,
                                37.1350770002549
                            ],
                            [
                                -122.428466999631,
                                37.1377549999362
                            ],
                            [
                                -122.445631999995,
                                37.1500370000765
                            ],
                            [
                                -122.455426000059,
                                37.1666020000647
                            ],
                            [
                                -122.464117000109,
                                37.1778480000553
                            ],
                            [
                                -122.468052999967,
                                37.1879640000339
                            ],
                            [
                                -122.472234999873,
                                37.212214000289
                            ],
                            [
                                -122.47907100042,
                                37.2216820000589
                            ],
                            [
                                -122.481703000423,
                                37.2291470001287
                            ],
                            [
                                -122.484045999821,
                                37.2499439997846
                            ],
                            [
                                -122.481773999669,
                                37.271088000275
                            ],
                            [
                                -122.465868999679,
                                37.3412399996997
                            ],
                            [
                                -122.467005000204,
                                37.3498889998749
                            ],
                            [
                                -122.4874539996,
                                37.3883279999969
                            ],
                            [
                                -122.503542999714,
                                37.4119019997425
                            ],
                            [
                                -122.50773399984,
                                37.4227060000002
                            ],
                            [
                                -122.509888999816,
                                37.4415539998735
                            ],
                            [
                                -122.527595999605,
                                37.4460959998346
                            ],
                            [
                                -122.537337999791,
                                37.4507380003471
                            ],
                            [
                                -122.546996000311,
                                37.457857000118
                            ],
                            [
                                -122.568094999986,
                                37.4792139997329
                            ],
                            [
                                -122.572777000382,
                                37.4891820003453
                            ],
                            [
                                -122.57496300032,
                                37.4999429998837
                            ],
                            [
                                -122.579562999802,
                                37.5458749999176
                            ],
                            [
                                -122.586453999703,
                                37.5619130001405
                            ],
                            [
                                -122.588176999859,
                                37.5794080000458
                            ],
                            [
                                -122.586453999703,
                                37.5925289997036
                            ],
                            [
                                -122.567496999586,
                                37.6391850001535
                            ],
                            [
                                -122.581280999936,
                                37.7602030000046
                            ],
                            [
                                -122.584728000072,
                                37.7791560002602
                            ],
                            [
                                -122.588174000384,
                                37.7893619997579
                            ],
                            [
                                -122.612285000002,
                                37.8152240003521
                            ],
                            [
                                -122.626105000337,
                                37.8215970002912
                            ],
                            [
                                -122.632472000356,
                                37.8278070000463
                            ],
                            [
                                -122.643162999673,
                                37.8304509998495
                            ],
                            [
                                -122.667424999802,
                                37.8417260000369
                            ],
                            [
                                -122.675653999894,
                                37.8485169996871
                            ],
                            [
                                -122.691987000405,
                                37.8450060002627
                            ],
                            [
                                -122.704678999712,
                                37.8442959998115
                            ],
                            [
                                -122.715845000303,
                                37.8456250002499
                            ],
                            [
                                -122.730665999647,
                                37.8500599999636
                            ],
                            [
                                -122.740258000045,
                                37.8551740000585
                            ],
                            [
                                -122.752465999653,
                                37.8576989999704
                            ],
                            [
                                -122.766771000409,
                                37.8658039998437
                            ],
                            [
                                -122.779141000399,
                                37.8761320003153
                            ],
                            [
                                -122.785472000433,
                                37.8852769997712
                            ],
                            [
                                -122.789026999627,
                                37.8937630000949
                            ],
                            [
                                -122.814134000028,
                                37.9010839997164
                            ],
                            [
                                -122.829803999842,
                                37.9103250001687
                            ],
                            [
                                -122.842133999649,
                                37.9233170003399
                            ],
                            [
                                -122.850459999826,
                                37.9455850000165
                            ],
                            [
                                -122.876103999629,
                                37.9663419998407
                            ],
                            [
                                -122.879057000021,
                                37.9665780001948
                            ],
                            [
                                -122.889291000302,
                                37.9741090001132
                            ],
                            [
                                -122.902283999908,
                                37.9773860001685
                            ],
                            [
                                -122.909235999908,
                                37.9646439996999
                            ],
                            [
                                -122.919169999714,
                                37.9548350000862
                            ],
                            [
                                -122.931927000217,
                                37.9466720000679
                            ],
                            [
                                -122.953224000258,
                                37.9410930003496
                            ],
                            [
                                -122.97642799968,
                                37.940148999794
                            ],
                            [
                                -123.022507000432,
                                37.9420649998324
                            ],
                            [
                                -123.050083999555,
                                37.9480160002248
                            ],
                            [
                                -123.06796500032,
                                37.9586689997387
                            ],
                            [
                                -123.07938999958,
                                37.9719369998272
                            ],
                            [
                                -123.083880000356,
                                37.9833660002023
                            ],
                            [
                                -123.085129999785,
                                38.0084329996828
                            ],
                            [
                                -123.081199999775,
                                38.0189710002929
                            ],
                            [
                                -123.07470100041,
                                38.0271989999601
                            ],
                            [
                                -123.063796999759,
                                38.0364870001776
                            ],
                            [
                                -123.023699000135,
                                38.1249229999341
                            ],
                            [
                                -123.01481700029,
                                38.1491320003297
                            ],
                            [
                                -123.027210999671,
                                38.1641699997095
                            ],
                            [
                                -123.030482999906,
                                38.1724579999564
                            ],
                            [
                                -123.031335999675,
                                38.1814619998136
                            ],
                            [
                                -123.037896999863,
                                38.1924910000791
                            ],
                            [
                                -123.038942999977,
                                38.1975280001147
                            ],
                            [
                                -123.045824999656,
                                38.2049260000874
                            ],
                            [
                                -123.049616999575,
                                38.214285999678
                            ],
                            [
                                -123.100962999806,
                                38.2613580001773
                            ],
                            [
                                -123.110968999582,
                                38.2672629997799
                            ],
                            [
                                -123.119591000035,
                                38.2771029998695
                            ],
                            [
                                -123.123773999766,
                                38.2857869997116
                            ],
                            [
                                -123.134522999707,
                                38.2962629999602
                            ],
                            [
                                -123.141329000117,
                                38.3113380001974
                            ],
                            [
                                -123.141001000054,
                                38.3274540003401
                            ],
                            [
                                -123.13431199982,
                                38.3457859999524
                            ],
                            [
                                -123.155742999755,
                                38.3683960000821
                            ],
                            [
                                -123.159846999843,
                                38.3749170002616
                            ],
                            [
                                -123.164756999845,
                                38.3883370000952
                            ],
                            [
                                -123.171711000393,
                                38.3940790000271
                            ],
                            [
                                -123.188464999955,
                                38.4028600001615
                            ],
                            [
                                -123.196159000295,
                                38.4101079998603
                            ],
                            [
                                -123.204071000195,
                                38.4239029998396
                            ],
                            [
                                -123.220219999684,
                                38.4419540001859
                            ],
                            [
                                -123.228012999758,
                                38.4442279997309
                            ],
                            [
                                -123.236390999813,
                                38.4504280003478
                            ],
                            [
                                -123.251115999796,
                                38.4520780002509
                            ],
                            [
                                -123.269188000357,
                                38.4575690002803
                            ],
                            [
                                -123.289685000332,
                                38.4675799997402
                            ],
                            [
                                -123.306741999842,
                                38.4786810000889
                            ],
                            [
                                -123.317345000194,
                                38.4886379997852
                            ],
                            [
                                -123.323810000122,
                                38.4986140001285
                            ],
                            [
                                -123.330725000312,
                                38.499912999851
                            ],
                            [
                                -123.330921000131,
                                38.502420999942
                            ],
                            [
                                -123.348529000186,
                                38.5042409998633
                            ],
                            [
                                -123.360929000313,
                                38.5102019997562
                            ],
                            [
                                -123.384567000103,
                                38.5370220001861
                            ],
                            [
                                -123.394156999953,
                                38.5539419997849
                            ],
                            [
                                -123.425388999625,
                                38.5828440002953
                            ],
                            [
                                -123.430490000321,
                                38.5916720000664
                            ],
                            [
                                -123.448391000279,
                                38.6062919997879
                            ],
                            [
                                -123.456159000239,
                                38.6147879997706
                            ],
                            [
                                -123.470520999998,
                                38.6385779998002
                            ],
                            [
                                -123.477373000439,
                                38.6422259999205
                            ],
                            [
                                -123.48854300033,
                                38.6514660001234
                            ],
                            [
                                -123.495084000426,
                                38.6606620001719
                            ],
                            [
                                -123.498668999757,
                                38.6689430000206
                            ],
                            [
                                -123.510580000163,
                                38.6808900000726
                            ],
                            [
                                -123.531888999899,
                                38.693420999873
                            ],
                            [
                                -123.549462999619,
                                38.7001350003332
                            ],
                            [
                                -123.566089000033,
                                38.7090210000917
                            ],
                            [
                                -123.578004999563,
                                38.7175829999753
                            ],
                            [
                                -123.586914999896,
                                38.728075000292
                            ],
                            [
                                -123.591962000165,
                                38.74022700003
                            ],
                            [
                                -123.607061000242,
                                38.7485650002947
                            ],
                            [
                                -123.615204999945,
                                38.7499120001446
                            ],
                            [
                                -123.626122999941,
                                38.7546459999644
                            ],
                            [
                                -123.641252999979,
                                38.7643940002099
                            ],
                            [
                                -123.652651000374,
                                38.7749669999802
                            ],
                            [
                                -123.660036000019,
                                38.7842579998591
                            ],
                            [
                                -123.682698000016,
                                38.7970149999477
                            ],
                            [
                                -123.692595000012,
                                38.8103189997025
                            ],
                            [
                                -123.706514000081,
                                38.8177240002537
                            ],
                            [
                                -123.717806999996,
                                38.8279209998924
                            ],
                            [
                                -123.725299999596,
                                38.8441170003194
                            ],
                            [
                                -123.737920999656,
                                38.8574409997749
                            ],
                            [
                                -123.767127999947,
                                38.8778960002746
                            ],
                            [
                                -123.784675999727,
                                38.8937279999777
                            ],
                            [
                                -123.798450999856,
                                38.927466999773
                            ],
                            [
                                -123.806505000046,
                                38.9404570002622
                            ],
                            [
                                -123.809860000121,
                                38.9612190001029
                            ],
                            [
                                -123.806068000203,
                                38.9770420000861
                            ],
                            [
                                -123.79721700032,
                                38.9898629999402
                            ],
                            [
                                -123.78542300009,
                                38.9999080001531
                            ],
                            [
                                -123.776609000017,
                                39.0050179998977
                            ],
                            [
                                -123.763147999708,
                                39.0086900002192
                            ],
                            [
                                -123.761177999679,
                                39.0150460000604
                            ],
                            [
                                -123.761965000225,
                                39.0410910002394
                            ],
                            [
                                -123.768343000115,
                                39.0502860000667
                            ],
                            [
                                -123.772642000196,
                                39.0631850003415
                            ],
                            [
                                -123.778308999708,
                                39.0697300001157
                            ],
                            [
                                -123.78610599998,
                                39.0921409999357
                            ],
                            [
                                -123.807457000448,
                                39.1272310003169
                            ],
                            [
                                -123.809209999843,
                                39.1410430000944
                            ],
                            [
                                -123.840771000302,
                                39.1873349998189
                            ],
                            [
                                -123.840127999696,
                                39.1902080002274
                            ],
                            [
                                -123.846017999689,
                                39.1998000000757
                            ],
                            [
                                -123.85009199964,
                                39.2313960003039
                            ],
                            [
                                -123.863409999834,
                                39.2547630001976
                            ],
                            [
                                -123.870212000046,
                                39.2806579996992
                            ],
                            [
                                -123.876127999979,
                                39.290448000009
                            ],
                            [
                                -123.880182999663,
                                39.3041980003069
                            ],
                            [
                                -123.881600000395,
                                39.3102079996883
                            ],
                            [
                                -123.881017000064,
                                39.3193460002258
                            ],
                            [
                                -123.886416999612,
                                39.3250270000537
                            ],
                            [
                                -123.89308499993,
                                39.3374940002162
                            ],
                            [
                                -123.894936000133,
                                39.3483489997023
                            ],
                            [
                                -123.894340000281,
                                39.3569150002006
                            ],
                            [
                                -123.896809000077,
                                39.3748930002148
                            ],
                            [
                                -123.895871999743,
                                39.3800969996626
                            ],
                            [
                                -123.890446000256,
                                39.3900979996778
                            ],
                            [
                                -123.892448999896,
                                39.4070370002543
                            ],
                            [
                                -123.887102999876,
                                39.425757999983
                            ],
                            [
                                -123.889266000248,
                                39.434620000332
                            ],
                            [
                                -123.888894999629,
                                39.4439390000057
                            ],
                            [
                                -123.887401999626,
                                39.4530359998431
                            ],
                            [
                                -123.874383999906,
                                39.4825619997454
                            ],
                            [
                                -123.875126000246,
                                39.4941789998087
                            ],
                            [
                                -123.87251500016,
                                39.5023859999117
                            ],
                            [
                                -123.867826000092,
                                39.5083049997242
                            ],
                            [
                                -123.857139000075,
                                39.5141410002702
                            ],
                            [
                                -123.852648000372,
                                39.5148490001963
                            ],
                            [
                                -123.834648999607,
                                39.5491940003196
                            ],
                            [
                                -123.839652000393,
                                39.5564709999752
                            ],
                            [
                                -123.849387000008,
                                39.5782899999477
                            ],
                            [
                                -123.852516000128,
                                39.5923710001973
                            ],
                            [
                                -123.853426999624,
                                39.6035489998956
                            ],
                            [
                                -123.851918999553,
                                39.6427629997189
                            ],
                            [
                                -123.8560009999,
                                39.6570510001178
                            ],
                            [
                                -123.857182999557,
                                39.6746929996644
                            ],
                            [
                                -123.880305999686,
                                39.6890860002424
                            ],
                            [
                                -123.890366999714,
                                39.7000090000333
                            ],
                            [
                                -123.895741000222,
                                39.711406000116
                            ],
                            [
                                -123.904017999995,
                                39.7570830001858
                            ],
                            [
                                -123.900970000066,
                                39.7804660000721
                            ],
                            [
                                -123.910528999954,
                                39.799574999718
                            ],
                            [
                                -123.938591000397,
                                39.8149439998525
                            ],
                            [
                                -123.953600000062,
                                39.8269910001078
                            ],
                            [
                                -123.973476999896,
                                39.8535620001556
                            ],
                            [
                                -123.98294600027,
                                39.8767780002252
                            ],
                            [
                                -124.005454000281,
                                39.8892859996713
                            ],
                            [
                                -124.009637000012,
                                39.8937490002384
                            ],
                            [
                                -124.0161980002,
                                39.9074009999512
                            ],
                            [
                                -124.035355000333,
                                39.9310729999194
                            ],
                            [
                                -124.04768500014,
                                39.9409600002089
                            ],
                            [
                                -124.078596000321,
                                39.972262999842
                            ],
                            [
                                -124.096382999577,
                                39.9750180002228
                            ],
                            [
                                -124.108581000038,
                                39.9788449999695
                            ],
                            [
                                -124.122772000093,
                                39.9887359999691
                            ],
                            [
                                -124.133254999896,
                                39.9998650000655
                            ],
                            [
                                -124.141085999605,
                                40.0109709998314
                            ],
                            [
                                -124.14410500012,
                                40.0181719999212
                            ],
                            [
                                -124.145425999694,
                                40.0289979996911
                            ],
                            [
                                -124.144099000272,
                                40.052400999861
                            ],
                            [
                                -124.153761999917,
                                40.063544000041
                            ],
                            [
                                -124.168922999917,
                                40.0703819997621
                            ],
                            [
                                -124.217952999616,
                                40.0864470001856
                            ],
                            [
                                -124.231203999863,
                                40.0933819999373
                            ],
                            [
                                -124.257708000007,
                                40.1215139997834
                            ],
                            [
                                -124.272099000078,
                                40.1336500001785
                            ],
                            [
                                -124.287833000365,
                                40.1376829998079
                            ],
                            [
                                -124.309927999924,
                                40.1536070002522
                            ],
                            [
                                -124.333688999737,
                                40.1671749997995
                            ],
                            [
                                -124.368674999693,
                                40.1932280002428
                            ],
                            [
                                -124.379252000106,
                                40.2028699999297
                            ],
                            [
                                -124.392776999989,
                                40.2097989997774
                            ],
                            [
                                -124.411026000101,
                                40.2252749998616
                            ],
                            [
                                -124.421287000146,
                                40.2371020001156
                            ],
                            [
                                -124.426725000227,
                                40.2498579999768
                            ],
                            [
                                -124.428222000428,
                                40.2581209997527
                            ],
                            [
                                -124.429149999642,
                                40.2669150000461
                            ],
                            [
                                -124.427899000387,
                                40.2888039997303
                            ],
                            [
                                -124.418276000027,
                                40.3142889996812
                            ],
                            [
                                -124.418542000166,
                                40.3187530003378
                            ],
                            [
                                -124.427945000418,
                                40.3388229998521
                            ],
                            [
                                -124.428055000023,
                                40.3617849999501
                            ],
                            [
                                -124.429461999812,
                                40.3649060000584
                            ],
                            [
                                -124.444708000201,
                                40.3764120002106
                            ],
                            [
                                -124.47243500001,
                                40.4128200000627
                            ],
                            [
                                -124.478624999579,
                                40.422102000276
                            ],
                            [
                                -124.480542999729,
                                40.4284929998255
                            ],
                            [
                                -124.482003000119,
                                40.4403179996895
                            ],
                            [
                                -124.479157999682,
                                40.4526360001432
                            ],
                            [
                                -124.475893999843,
                                40.4597619996599
                            ],
                            [
                                -124.465785000134,
                                40.4700120000174
                            ],
                            [
                                -124.455951999712,
                                40.4840469999089
                            ],
                            [
                                -124.452559999826,
                                40.4930839998098
                            ],
                            [
                                -124.451873999563,
                                40.515610000028
                            ],
                            [
                                -124.447676999589,
                                40.52970900015
                            ],
                            [
                                -124.427191000383,
                                40.5620849997803
                            ],
                            [
                                -124.41200299972,
                                40.5970220003345
                            ],
                            [
                                -124.368878000083,
                                40.6697060001095
                            ],
                            [
                                -124.342900000369,
                                40.708831999813
                            ],
                            [
                                -124.32742499965,
                                40.7277240001938
                            ],
                            [
                                -124.299332999968,
                                40.7684810002669
                            ],
                            [
                                -124.296989999671,
                                40.7809629997377
                            ],
                            [
                                -124.292662000176,
                                40.7880940000658
                            ],
                            [
                                -124.286579999664,
                                40.7958969999122
                            ],
                            [
                                -124.280180999857,
                                40.7999320001827
                            ],
                            [
                                -124.246314999635,
                                40.8469639998653
                            ],
                            [
                                -124.23262200042,
                                40.8692859998609
                            ],
                            [
                                -124.214964000136,
                                40.9064040003205
                            ],
                            [
                                -124.189793000161,
                                40.9740479996756
                            ],
                            [
                                -124.182693999848,
                                41.0013130001572
                            ],
                            [
                                -124.196390000334,
                                41.0075279996781
                            ],
                            [
                                -124.204984000299,
                                41.013638000022
                            ],
                            [
                                -124.212339999632,
                                41.0238040001
                            ],
                            [
                                -124.215104999703,
                                41.0314170001959
                            ],
                            [
                                -124.227078999858,
                                41.0466219998412
                            ],
                            [
                                -124.229931999793,
                                41.0546050002857
                            ],
                            [
                                -124.231202000213,
                                41.0655009999468
                            ],
                            [
                                -124.237206999834,
                                41.0738420001496
                            ],
                            [
                                -124.239565000199,
                                41.0828629999557
                            ],
                            [
                                -124.247430000243,
                                41.0922239997471
                            ],
                            [
                                -124.249587999694,
                                41.0990250001876
                            ],
                            [
                                -124.250636000356,
                                41.1102879997039
                            ],
                            [
                                -124.24916400027,
                                41.1201569997514
                            ],
                            [
                                -124.25247400014,
                                41.1340749999736
                            ],
                            [
                                -124.247236000075,
                                41.1528170002262
                            ],
                            [
                                -124.237625000308,
                                41.1659590003189
                            ],
                            [
                                -124.219418000029,
                                41.1776870001887
                            ],
                            [
                                -124.206688000189,
                                41.182409000308
                            ],
                            [
                                -124.195531999643,
                                41.1845819999101
                            ],
                            [
                                -124.187789999623,
                                41.209562000227
                            ],
                            [
                                -124.176367000012,
                                41.2371479998724
                            ],
                            [
                                -124.17331599971,
                                41.2609950001265
                            ],
                            [
                                -124.160703999871,
                                41.2892929998476
                            ],
                            [
                                -124.163397999797,
                                41.2931689999688
                            ],
                            [
                                -124.177983000038,
                                41.2912209997583
                            ],
                            [
                                -124.192328000079,
                                41.29199000002
                            ],
                            [
                                -124.204919999827,
                                41.2955720002236
                            ],
                            [
                                -124.220032000322,
                                41.3029940000365
                            ],
                            [
                                -124.231393000009,
                                41.3123129999356
                            ],
                            [
                                -124.238210000289,
                                41.3214460002061
                            ],
                            [
                                -124.242163999691,
                                41.3326160000741
                            ],
                            [
                                -124.242419999784,
                                41.3433000002145
                            ],
                            [
                                -124.238191000023,
                                41.3587050001072
                            ],
                            [
                                -124.231086999687,
                                41.3688560000122
                            ],
                            [
                                -124.219219999662,
                                41.3782979997185
                            ],
                            [
                                -124.206514999936,
                                41.3847179998706
                            ],
                            [
                                -124.188603999933,
                                41.3892670000797
                            ],
                            [
                                -124.176556000158,
                                41.3903520000163
                            ],
                            [
                                -124.154194999562,
                                41.3876279997291
                            ],
                            [
                                -124.139973000443,
                                41.3818209997787
                            ],
                            [
                                -124.133230999607,
                                41.4323109997136
                            ],
                            [
                                -124.136906000247,
                                41.4468230003305
                            ],
                            [
                                -124.136560999568,
                                41.4644520001748
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -119.468063999657,
                                34.0631389997285
                            ],
                            [
                                -119.437318000231,
                                34.0677159996331
                            ],
                            [
                                -119.409352000047,
                                34.0667890001078
                            ],
                            [
                                -119.397006000346,
                                34.0637920000307
                            ],
                            [
                                -119.367876999873,
                                34.0704160002934
                            ],
                            [
                                -119.35638800014,
                                34.0708079998925
                            ],
                            [
                                -119.342462000399,
                                34.0689639999632
                            ],
                            [
                                -119.330925999912,
                                34.0650640002198
                            ],
                            [
                                -119.312086999796,
                                34.0543880001667
                            ],
                            [
                                -119.305153000237,
                                34.0474490000457
                            ],
                            [
                                -119.297642000195,
                                34.0365829997209
                            ],
                            [
                                -119.293112000135,
                                34.0201520003256
                            ],
                            [
                                -119.296237999882,
                                33.9972400000915
                            ],
                            [
                                -119.304086000207,
                                33.9865390000101
                            ],
                            [
                                -119.318442999944,
                                33.9748990001258
                            ],
                            [
                                -119.332793999833,
                                33.967721000305
                            ],
                            [
                                -119.35185600043,
                                33.9644540002508
                            ],
                            [
                                -119.362828999779,
                                33.9587619996862
                            ],
                            [
                                -119.380456000101,
                                33.953611000345
                            ],
                            [
                                -119.402928000127,
                                33.9537850001363
                            ],
                            [
                                -119.411343999817,
                                33.9554149999247
                            ],
                            [
                                -119.416996999983,
                                33.9537709996609
                            ],
                            [
                                -119.432961000423,
                                33.9559049997636
                            ],
                            [
                                -119.472647000319,
                                33.9706469996495
                            ],
                            [
                                -119.492289999801,
                                33.9832149996748
                            ],
                            [
                                -119.500952000436,
                                33.9714839996878
                            ],
                            [
                                -119.512243999628,
                                33.9621990002377
                            ],
                            [
                                -119.523312000309,
                                33.9549250002445
                            ],
                            [
                                -119.538948999613,
                                33.9476560001214
                            ],
                            [
                                -119.550466999658,
                                33.9449529996472
                            ],
                            [
                                -119.563291000108,
                                33.944954999816
                            ],
                            [
                                -119.57244699959,
                                33.9411379997055
                            ],
                            [
                                -119.590689999854,
                                33.93957799991
                            ],
                            [
                                -119.602084999876,
                                33.9367680001771
                            ],
                            [
                                -119.625957000018,
                                33.9360359997418
                            ],
                            [
                                -119.636730000248,
                                33.9382769999434
                            ],
                            [
                                -119.68759600008,
                                33.9156390003382
                            ],
                            [
                                -119.707491000356,
                                33.9103879997877
                            ],
                            [
                                -119.731418999676,
                                33.9102739997258
                            ],
                            [
                                -119.74134400016,
                                33.9126189999527
                            ],
                            [
                                -119.758558000029,
                                33.9107980000451
                            ],
                            [
                                -119.772828999552,
                                33.9124139999775
                            ],
                            [
                                -119.778315000213,
                                33.91061199974
                            ],
                            [
                                -119.789372000126,
                                33.9101310001191
                            ],
                            [
                                -119.810223999928,
                                33.9013239997811
                            ],
                            [
                                -119.823208000211,
                                33.8993900003207
                            ],
                            [
                                -119.834419999935,
                                33.9007880003462
                            ],
                            [
                                -119.855219000033,
                                33.9074870003211
                            ],
                            [
                                -119.884053000056,
                                33.925984000121
                            ],
                            [
                                -119.896243999945,
                                33.9357420002576
                            ],
                            [
                                -119.904761999743,
                                33.9396739998629
                            ],
                            [
                                -119.911023000355,
                                33.9238980000531
                            ],
                            [
                                -119.918930000232,
                                33.9143540003595
                            ],
                            [
                                -119.93919000038,
                                33.8986430003449
                            ],
                            [
                                -119.950294000149,
                                33.894376000217
                            ],
                            [
                                -119.962680999857,
                                33.8924399998531
                            ],
                            [
                                -119.980842000106,
                                33.8923170000423
                            ],
                            [
                                -120.000959999964,
                                33.8852480002156
                            ],
                            [
                                -120.01541100031,
                                33.8751480001263
                            ],
                            [
                                -120.019338999773,
                                33.8705549996321
                            ],
                            [
                                -120.027809999714,
                                33.8665119999943
                            ],
                            [
                                -120.034538000307,
                                33.8644759999805
                            ],
                            [
                                -120.047909000204,
                                33.8635920003008
                            ],
                            [
                                -120.070649999844,
                                33.8564900000242
                            ],
                            [
                                -120.0801059998,
                                33.8505060001366
                            ],
                            [
                                -120.104088000445,
                                33.8423959996405
                            ],
                            [
                                -120.117265999998,
                                33.8415799999344
                            ],
                            [
                                -120.13992700017,
                                33.844798999788
                            ],
                            [
                                -120.149551000355,
                                33.8479620001756
                            ],
                            [
                                -120.157079000115,
                                33.8534250000225
                            ],
                            [
                                -120.170320000317,
                                33.8574159998276
                            ],
                            [
                                -120.182999999928,
                                33.866287000307
                            ],
                            [
                                -120.188216000252,
                                33.8663599999953
                            ],
                            [
                                -120.193390999669,
                                33.868527999703
                            ],
                            [
                                -120.199514000189,
                                33.8750020000549
                            ],
                            [
                                -120.221128000423,
                                33.8841870000577
                            ],
                            [
                                -120.238719999686,
                                33.9041060002812
                            ],
                            [
                                -120.2509690002,
                                33.9084359998889
                            ],
                            [
                                -120.264494999908,
                                33.9189160003406
                            ],
                            [
                                -120.273096000445,
                                33.9295770003641
                            ],
                            [
                                -120.280033000376,
                                33.9539389999973
                            ],
                            [
                                -120.298972999876,
                                33.9684570001485
                            ],
                            [
                                -120.31738600002,
                                33.9663709999308
                            ],
                            [
                                -120.332253000293,
                                33.9684250003201
                            ],
                            [
                                -120.34687399962,
                                33.9634939996827
                            ],
                            [
                                -120.36707299967,
                                33.9610360001603
                            ],
                            [
                                -120.395636000428,
                                33.9674270000024
                            ],
                            [
                                -120.411765999651,
                                33.9770450000716
                            ],
                            [
                                -120.432180999611,
                                33.9747030001917
                            ],
                            [
                                -120.446191999741,
                                33.9773730002496
                            ],
                            [
                                -120.466776999579,
                                33.9761160002994
                            ],
                            [
                                -120.480765999968,
                                33.9802620000227
                            ],
                            [
                                -120.488150999613,
                                33.9842829998094
                            ],
                            [
                                -120.497726000293,
                                33.9911990001969
                            ],
                            [
                                -120.500991999781,
                                33.9963030002815
                            ],
                            [
                                -120.506202000257,
                                33.9999959997606
                            ],
                            [
                                -120.518783999959,
                                34.014080000342
                            ],
                            [
                                -120.523139000117,
                                34.022818999826
                            ],
                            [
                                -120.52585200031,
                                34.0329940000794
                            ],
                            [
                                -120.52645200036,
                                34.0414550000395
                            ],
                            [
                                -120.523386999815,
                                34.0511330001479
                            ],
                            [
                                -120.531062999713,
                                34.0516869998262
                            ],
                            [
                                -120.550050999792,
                                34.0572859998834
                            ],
                            [
                                -120.566995000223,
                                34.0686380003366
                            ],
                            [
                                -120.573808000306,
                                34.0759659997052
                            ],
                            [
                                -120.581547999778,
                                34.0914879998207
                            ],
                            [
                                -120.582264000179,
                                34.10751700005
                            ],
                            [
                                -120.575088999698,
                                34.1264739998703
                            ],
                            [
                                -120.567847000168,
                                34.1356179996961
                            ],
                            [
                                -120.5609980001,
                                34.1406640000056
                            ],
                            [
                                -120.538117999645,
                                34.150344000239
                            ],
                            [
                                -120.516038999979,
                                34.1527980001106
                            ],
                            [
                                -120.497378000138,
                                34.1498920003453
                            ],
                            [
                                -120.481428999767,
                                34.1421679997111
                            ],
                            [
                                -120.471653999969,
                                34.1341019998437
                            ],
                            [
                                -120.449073999988,
                                34.1505929997755
                            ],
                            [
                                -120.432719999561,
                                34.1574779996531
                            ],
                            [
                                -120.418787000147,
                                34.1605690000758
                            ],
                            [
                                -120.401169000046,
                                34.1587290003115
                            ],
                            [
                                -120.368745999597,
                                34.1510410000877
                            ],
                            [
                                -120.355161000337,
                                34.1450209997857
                            ],
                            [
                                -120.350489999812,
                                34.1414500002509
                            ],
                            [
                                -120.342711999806,
                                34.1315019996883
                            ],
                            [
                                -120.338561999688,
                                34.1216870002297
                            ],
                            [
                                -120.324337000195,
                                34.113799999721
                            ],
                            [
                                -120.321086999702,
                                34.1104500001506
                            ],
                            [
                                -120.311711000112,
                                34.1095060001098
                            ],
                            [
                                -120.297787000021,
                                34.1041159998847
                            ],
                            [
                                -120.2846510003,
                                34.0947500000686
                            ],
                            [
                                -120.274284999775,
                                34.0817990001357
                            ],
                            [
                                -120.271579000153,
                                34.0741409999441
                            ],
                            [
                                -120.252846000342,
                                34.0580309999081
                            ],
                            [
                                -120.22487799961,
                                34.0613120000824
                            ],
                            [
                                -120.210382999781,
                                34.060852999872
                            ],
                            [
                                -120.197575999948,
                                34.0581499996432
                            ],
                            [
                                -120.194113999742,
                                34.0587209998691
                            ],
                            [
                                -120.185461000226,
                                34.0662200002087
                            ],
                            [
                                -120.171127999881,
                                34.0733179998391
                            ],
                            [
                                -120.135006000298,
                                34.078809999783
                            ],
                            [
                                -120.120132000352,
                                34.0789420000419
                            ],
                            [
                                -120.102917999584,
                                34.0744939997873
                            ],
                            [
                                -120.0802849999,
                                34.0864009999173
                            ],
                            [
                                -120.063327000123,
                                34.0897139997762
                            ],
                            [
                                -120.045154000179,
                                34.0906059996429
                            ],
                            [
                                -120.019502999804,
                                34.0877180002349
                            ],
                            [
                                -120.010210000055,
                                34.0840430000288
                            ],
                            [
                                -120.000963000337,
                                34.0786710000715
                            ],
                            [
                                -120.000963000337,
                                34.0768190000043
                            ],
                            [
                                -119.991642999926,
                                34.0648680000099
                            ],
                            [
                                -119.981946999771,
                                34.0893770003263
                            ],
                            [
                                -119.974386000398,
                                34.0967430003396
                            ],
                            [
                                -119.972361000119,
                                34.1033499999784
                            ],
                            [
                                -119.965471000043,
                                34.1125600002599
                            ],
                            [
                                -119.947835000399,
                                34.1249990001849
                            ],
                            [
                                -119.928795000441,
                                34.1299020000513
                            ],
                            [
                                -119.913364000104,
                                34.1301479999441
                            ],
                            [
                                -119.894177999658,
                                34.1257720001842
                            ],
                            [
                                -119.875984999622,
                                34.1262340000359
                            ],
                            [
                                -119.852087000439,
                                34.1209570001092
                            ],
                            [
                                -119.829220000403,
                                34.1187020002167
                            ],
                            [
                                -119.792600999804,
                                34.1063749998238
                            ],
                            [
                                -119.769031999611,
                                34.1119430003391
                            ],
                            [
                                -119.750980999865,
                                34.1125820003476
                            ],
                            [
                                -119.725973000097,
                                34.1020900001792
                            ],
                            [
                                -119.709463000034,
                                34.1006630000777
                            ],
                            [
                                -119.693007000398,
                                34.0948540002112
                            ],
                            [
                                -119.654930999782,
                                34.071217999985
                            ],
                            [
                                -119.645169000403,
                                34.0800549996752
                            ],
                            [
                                -119.614268000268,
                                34.0994319999898
                            ],
                            [
                                -119.599892000265,
                                34.1040069999813
                            ],
                            [
                                -119.581972999865,
                                34.1057320003285
                            ],
                            [
                                -119.574116000217,
                                34.1081130001647
                            ],
                            [
                                -119.548250999583,
                                34.1077830001533
                            ],
                            [
                                -119.538418999883,
                                34.1054130001871
                            ],
                            [
                                -119.512106000434,
                                34.0934600002518
                            ],
                            [
                                -119.497490000231,
                                34.0827549998953
                            ],
                            [
                                -119.488752000326,
                                34.0791950000251
                            ],
                            [
                                -119.4773079999,
                                34.0718259999393
                            ],
                            [
                                -119.468063999657,
                                34.0631389997285
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -118.661749999652,
                                33.4563169996488
                            ],
                            [
                                -118.665919000037,
                                33.4673339998556
                            ],
                            [
                                -118.668202999885,
                                33.4859370003374
                            ],
                            [
                                -118.663709999635,
                                33.5000249998627
                            ],
                            [
                                -118.655734999986,
                                33.5108180003667
                            ],
                            [
                                -118.644057999932,
                                33.521284000193
                            ],
                            [
                                -118.625915999949,
                                33.5289780001375
                            ],
                            [
                                -118.605013999918,
                                33.5322060002271
                            ],
                            [
                                -118.589719000023,
                                33.5319779996749
                            ],
                            [
                                -118.579144000159,
                                33.5284099997325
                            ],
                            [
                                -118.567188000446,
                                33.5294819997142
                            ],
                            [
                                -118.557750000034,
                                33.5283280002597
                            ],
                            [
                                -118.546601999884,
                                33.5308409996711
                            ],
                            [
                                -118.529382000167,
                                33.5311819998517
                            ],
                            [
                                -118.50675699998,
                                33.5249110001217
                            ],
                            [
                                -118.493335000029,
                                33.5157259997976
                            ],
                            [
                                -118.48261700005,
                                33.5160590000912
                            ],
                            [
                                -118.468960999747,
                                33.5129990003195
                            ],
                            [
                                -118.456825000109,
                                33.506874999849
                            ],
                            [
                                -118.436835000297,
                                33.4894430001478
                            ],
                            [
                                -118.427094999761,
                                33.4871609999168
                            ],
                            [
                                -118.42084099972,
                                33.4817820000458
                            ],
                            [
                                -118.40323000019,
                                33.4792670002948
                            ],
                            [
                                -118.359393000351,
                                33.4649219996525
                            ],
                            [
                                -118.350918000211,
                                33.4578180000423
                            ],
                            [
                                -118.332772999856,
                                33.4480439996989
                            ],
                            [
                                -118.316350999657,
                                33.4341370003482
                            ],
                            [
                                -118.31094699991,
                                33.4251370000801
                            ],
                            [
                                -118.306590999927,
                                33.4071550000209
                            ],
                            [
                                -118.30410200004,
                                33.4036319998842
                            ],
                            [
                                -118.294666000176,
                                33.397592999724
                            ],
                            [
                                -118.277209999559,
                                33.3807429997495
                            ],
                            [
                                -118.26274800024,
                                33.3704400002795
                            ],
                            [
                                -118.251379000158,
                                33.3533969997112
                            ],
                            [
                                -118.245101999652,
                                33.3383409996895
                            ],
                            [
                                -118.241286000343,
                                33.3230229998579
                            ],
                            [
                                -118.241051000166,
                                33.3130290001741
                            ],
                            [
                                -118.245940000252,
                                33.2910610001113
                            ],
                            [
                                -118.250902999957,
                                33.2804650003314
                            ],
                            [
                                -118.269178000009,
                                33.2656799997598
                            ],
                            [
                                -118.292457999598,
                                33.2540780000185
                            ],
                            [
                                -118.318621999984,
                                33.2484720000559
                            ],
                            [
                                -118.343772000043,
                                33.2500300001581
                            ],
                            [
                                -118.363310999768,
                                33.255073999878
                            ],
                            [
                                -118.381487000085,
                                33.2622980000141
                            ],
                            [
                                -118.385588999625,
                                33.2653579996781
                            ],
                            [
                                -118.402557000345,
                                33.2679370000106
                            ],
                            [
                                -118.412049000286,
                                33.266275000053
                            ],
                            [
                                -118.437653999731,
                                33.2670179996256
                            ],
                            [
                                -118.455071999815,
                                33.2690189996272
                            ],
                            [
                                -118.486687999627,
                                33.2769050002433
                            ],
                            [
                                -118.510385999692,
                                33.2896590001868
                            ],
                            [
                                -118.534884999648,
                                33.3151759999192
                            ],
                            [
                                -118.54747599957,
                                33.340480000235
                            ],
                            [
                                -118.551193000044,
                                33.3693519998233
                            ],
                            [
                                -118.547929000205,
                                33.3767989996546
                            ],
                            [
                                -118.553375999609,
                                33.3802260001074
                            ],
                            [
                                -118.573197000265,
                                33.3834790002006
                            ],
                            [
                                -118.591079999781,
                                33.3908719999024
                            ],
                            [
                                -118.607228000344,
                                33.3982939999935
                            ],
                            [
                                -118.618573999962,
                                33.4061910003603
                            ],
                            [
                                -118.625916999774,
                                33.4142649996437
                            ],
                            [
                                -118.63073399989,
                                33.4232029998198
                            ],
                            [
                                -118.655057000118,
                                33.4453100003487
                            ],
                            [
                                -118.661749999652,
                                33.4563169996488
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -118.674842999715,
                                33.012143000104
                            ],
                            [
                                -118.678550999968,
                                33.026355999793
                            ],
                            [
                                -118.678203999638,
                                33.039726000311
                            ],
                            [
                                -118.675605000147,
                                33.0493490003481
                            ],
                            [
                                -118.663846000077,
                                33.066846000081
                            ],
                            [
                                -118.654363000357,
                                33.0749519996464
                            ],
                            [
                                -118.641301000256,
                                33.0818820000904
                            ],
                            [
                                -118.6196209999,
                                33.0867190002064
                            ],
                            [
                                -118.578337000421,
                                33.0862430002193
                            ],
                            [
                                -118.552626999597,
                                33.0786329999592
                            ],
                            [
                                -118.542102999786,
                                33.0737879996925
                            ],
                            [
                                -118.523727000351,
                                33.0599720001308
                            ],
                            [
                                -118.514965999981,
                                33.0498890001357
                            ],
                            [
                                -118.507663000352,
                                33.0378829997167
                            ],
                            [
                                -118.495582000067,
                                33.0263360002354
                            ],
                            [
                                -118.491132000372,
                                33.017923000117
                            ],
                            [
                                -118.48949700008,
                                33.0106299998774
                            ],
                            [
                                -118.44886299998,
                                32.9617500000783
                            ],
                            [
                                -118.423338000002,
                                32.9464829996774
                            ],
                            [
                                -118.390787000405,
                                32.9178410002977
                            ],
                            [
                                -118.362519000097,
                                32.9001170002654
                            ],
                            [
                                -118.337456999902,
                                32.8797480001018
                            ],
                            [
                                -118.328898000097,
                                32.8750370002638
                            ],
                            [
                                -118.317390000097,
                                32.8637649996902
                            ],
                            [
                                -118.310342999663,
                                32.8595049997979
                            ],
                            [
                                -118.2972499996,
                                32.8450939998228
                            ],
                            [
                                -118.289894000267,
                                32.8293829997615
                            ],
                            [
                                -118.288783999681,
                                32.8208290001554
                            ],
                            [
                                -118.289905000137,
                                32.8105300001555
                            ],
                            [
                                -118.295841000161,
                                32.7982560000806
                            ],
                            [
                                -118.302848000413,
                                32.7888459998177
                            ],
                            [
                                -118.323819000041,
                                32.7735140000476
                            ],
                            [
                                -118.343192000084,
                                32.7670620000506
                            ],
                            [
                                -118.353494000137,
                                32.7658070001549
                            ],
                            [
                                -118.378377000232,
                                32.7702319997293
                            ],
                            [
                                -118.392557000417,
                                32.7585010000384
                            ],
                            [
                                -118.408321999767,
                                32.7519140000834
                            ],
                            [
                                -118.419798999804,
                                32.7500400000526
                            ],
                            [
                                -118.436538000196,
                                32.7500400000526
                            ],
                            [
                                -118.453808000142,
                                32.7551110003724
                            ],
                            [
                                -118.464711999894,
                                32.7601760001462
                            ],
                            [
                                -118.473905999909,
                                32.7668239996868
                            ],
                            [
                                -118.484377000016,
                                32.7786189998229
                            ],
                            [
                                -118.500909999645,
                                32.7920259996447
                            ],
                            [
                                -118.507649999933,
                                32.7968770001739
                            ],
                            [
                                -118.515812999903,
                                32.7995709999692
                            ],
                            [
                                -118.535089999687,
                                32.8105809999318
                            ],
                            [
                                -118.546725999733,
                                32.8206810000892
                            ],
                            [
                                -118.553185999638,
                                32.8287970002198
                            ],
                            [
                                -118.562527999791,
                                32.8494380001775
                            ],
                            [
                                -118.596929999591,
                                32.8904660000751
                            ],
                            [
                                -118.605289000278,
                                32.9085400002335
                            ],
                            [
                                -118.605470999853,
                                32.9182040002929
                            ],
                            [
                                -118.608801999639,
                                32.9276900002148
                            ],
                            [
                                -118.622117000358,
                                32.9414339997379
                            ],
                            [
                                -118.635521999693,
                                32.9656000000862
                            ],
                            [
                                -118.644731999601,
                                33.0000330000544
                            ],
                            [
                                -118.670172999913,
                                33.0000339998034
                            ],
                            [
                                -118.671015999637,
                                33.0058509997161
                            ],
                            [
                                -118.674842999715,
                                33.012143000104
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -119.634719000212,
                                33.2654420000098
                            ],
                            [
                                -119.636068000274,
                                33.2807129997988
                            ],
                            [
                                -119.629076000091,
                                33.2993369999028
                            ],
                            [
                                -119.621652999913,
                                33.3092519998289
                            ],
                            [
                                -119.609294999619,
                                33.3182290000189
                            ],
                            [
                                -119.588154000112,
                                33.3269709997436
                            ],
                            [
                                -119.564931000423,
                                33.3276369999111
                            ],
                            [
                                -119.543544999795,
                                33.3341589997379
                            ],
                            [
                                -119.5253130003,
                                33.3348620003075
                            ],
                            [
                                -119.49512000017,
                                33.3271539999925
                            ],
                            [
                                -119.429996000335,
                                33.3010309998479
                            ],
                            [
                                -119.379729999654,
                                33.26763200032
                            ],
                            [
                                -119.375937999736,
                                33.2647560001945
                            ],
                            [
                                -119.362124999972,
                                33.2452210000244
                            ],
                            [
                                -119.360133000203,
                                33.234872999796
                            ],
                            [
                                -119.36070200029,
                                33.2214520002632
                            ],
                            [
                                -119.368450999984,
                                33.2025960000697
                            ],
                            [
                                -119.375936999911,
                                33.1921860003415
                            ],
                            [
                                -119.394535999828,
                                33.1808360001504
                            ],
                            [
                                -119.433717000108,
                                33.1669870003171
                            ],
                            [
                                -119.444967000365,
                                33.1652699998145
                            ],
                            [
                                -119.476486000093,
                                33.1634929996531
                            ],
                            [
                                -119.491417999765,
                                33.1670120000243
                            ],
                            [
                                -119.510250000208,
                                33.167417000154
                            ],
                            [
                                -119.574192000385,
                                33.1864839999418
                            ],
                            [
                                -119.605281999767,
                                33.206919000058
                            ],
                            [
                                -119.61147100041,
                                33.2127329997769
                            ],
                            [
                                -119.627828000312,
                                33.2369460003598
                            ],
                            [
                                -119.631282999946,
                                33.2449739996582
                            ],
                            [
                                -119.631045000295,
                                33.2554929999966
                            ],
                            [
                                -119.634719000212,
                                33.2654420000098
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -123.169984999864,
                                37.7559300000438
                            ],
                            [
                                -123.172925999663,
                                37.763600000049
                            ],
                            [
                                -123.173825000361,
                                37.7757270003373
                            ],
                            [
                                -123.16910699998,
                                37.792365000163
                            ],
                            [
                                -123.160935999614,
                                37.8020020000818
                            ],
                            [
                                -123.147209000064,
                                37.8119509999922
                            ],
                            [
                                -123.13227200037,
                                37.8196760002345
                            ],
                            [
                                -123.118288999828,
                                37.8230579998201
                            ],
                            [
                                -123.095261000134,
                                37.821876999696
                            ],
                            [
                                -123.081645999839,
                                37.8181510000344
                            ],
                            [
                                -123.07049400039,
                                37.813830000245
                            ],
                            [
                                -123.048817000407,
                                37.7998159997655
                            ],
                            [
                                -123.038726000242,
                                37.7888809997272
                            ],
                            [
                                -123.033841000354,
                                37.7783750001889
                            ],
                            [
                                -123.01058799963,
                                37.7749260002984
                            ],
                            [
                                -122.997538000123,
                                37.7707839996951
                            ],
                            [
                                -122.981428999918,
                                37.7589159999003
                            ],
                            [
                                -122.973255999902,
                                37.7486519996982
                            ],
                            [
                                -122.964098999697,
                                37.7442809999932
                            ],
                            [
                                -122.949984999635,
                                37.7321869999786
                            ],
                            [
                                -122.942293999668,
                                37.7210569999624
                            ],
                            [
                                -122.935864999725,
                                37.7024590000728
                            ],
                            [
                                -122.935709999914,
                                37.6976180002318
                            ],
                            [
                                -122.941363999906,
                                37.6765800002025
                            ],
                            [
                                -122.949068000291,
                                37.6633769998544
                            ],
                            [
                                -122.963005999728,
                                37.6519839999368
                            ],
                            [
                                -122.973012000403,
                                37.6465810001869
                            ],
                            [
                                -122.986747000349,
                                37.6417830001991
                            ],
                            [
                                -123.001109999933,
                                37.6398299998241
                            ],
                            [
                                -123.001109999933,
                                37.6417749999151
                            ],
                            [
                                -123.01160500033,
                                37.6415060000901
                            ],
                            [
                                -123.027152999945,
                                37.6452210003497
                            ],
                            [
                                -123.04069600027,
                                37.6503280001404
                            ],
                            [
                                -123.063578000375,
                                37.6673159999654
                            ],
                            [
                                -123.06858899976,
                                37.6730410002143
                            ],
                            [
                                -123.074920999619,
                                37.6900659997239
                            ],
                            [
                                -123.086057000073,
                                37.7007499999195
                            ],
                            [
                                -123.092850000065,
                                37.7136880003217
                            ],
                            [
                                -123.104921000304,
                                37.7126480000321
                            ],
                            [
                                -123.115574999811,
                                37.7140450000063
                            ],
                            [
                                -123.13259700006,
                                37.7193929999598
                            ],
                            [
                                -123.162750999832,
                                37.7435330003298
                            ],
                            [
                                -123.169984999864,
                                37.7559300000438
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -119.107214999956,
                                33.5000229997867
                            ],
                            [
                                -119.118948999911,
                                33.5000219997486
                            ],
                            [
                                -119.111656000327,
                                33.5154710000355
                            ],
                            [
                                -119.098699999633,
                                33.5264419999106
                            ],
                            [
                                -119.083736000174,
                                33.5329069999517
                            ],
                            [
                                -119.07014299962,
                                33.5364160001433
                            ],
                            [
                                -119.056554999988,
                                33.536026000209
                            ],
                            [
                                -119.030737000107,
                                33.540115999996
                            ],
                            [
                                -119.008066999714,
                                33.5375550001654
                            ],
                            [
                                -119.000932000315,
                                33.5358950002164
                            ],
                            [
                                -118.990046000106,
                                33.5302889997784
                            ],
                            [
                                -118.982149000275,
                                33.524259999871
                            ],
                            [
                                -118.972885999765,
                                33.513835000124
                            ],
                            [
                                -118.966018000329,
                                33.5000229997867
                            ],
                            [
                                -118.962758999614,
                                33.4852050001312
                            ],
                            [
                                -118.9637510002,
                                33.4668300002826
                            ],
                            [
                                -118.978481000205,
                                33.4311060001506
                            ],
                            [
                                -118.988726000356,
                                33.4234409998762
                            ],
                            [
                                -119.000930999591,
                                33.4184929998664
                            ],
                            [
                                -119.000930999591,
                                33.4213019997139
                            ],
                            [
                                -119.009523999732,
                                33.4167670003748
                            ],
                            [
                                -119.027043999923,
                                33.4128740002064
                            ],
                            [
                                -119.053071000041,
                                33.4110240000162
                            ],
                            [
                                -119.071893000439,
                                33.4137990001463
                            ],
                            [
                                -119.09412799974,
                                33.4268720000704
                            ],
                            [
                                -119.100760999898,
                                33.4352420001049
                            ],
                            [
                                -119.108878999662,
                                33.4511509996701
                            ],
                            [
                                -119.109569000123,
                                33.4742760001549
                            ],
                            [
                                -119.107214999956,
                                33.5000229997867
                            ]
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 37.1551773,
            "name": "California",
            "intptlon": -119.5434183,
            "geo_point_2d": [
                37.2153570137,
                -119.663749804
            ],
            "geoid": "06",
            "mtfcc": "G4000",
            "region": 4
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -119.663749804,
                37.2153570137
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "be10420055e55e9cb2b4c9277d9e30cdcc53ee6e",
        "fields": {
            "arealand": "-503786026",
            "objectid": 17,
            "basename": "Oregon",
            "stusab": "OR",
            "statens": "01155107",
            "centlon": -120.6109266,
            "state": "41",
            "gid": 51,
            "centlat": 43.9396009,
            "division": 9,
            "areawater": "1900089675",
            "oid": "-1879153023",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -124.065448000006,
                            45.7830539997317
                        ],
                        [
                            -124.06205600012,
                            45.7897410002239
                        ],
                        [
                            -124.054118000281,
                            45.7975299998505
                        ],
                        [
                            -124.05602700021,
                            45.8065719998302
                        ],
                        [
                            -124.055646000444,
                            45.8164859999843
                        ],
                        [
                            -124.05139199967,
                            45.826844999865
                        ],
                        [
                            -124.03770399958,
                            45.8416139998162
                        ],
                        [
                            -124.041995000164,
                            45.84855300009
                        ],
                        [
                            -124.043353000447,
                            45.8870860000542
                        ],
                        [
                            -124.045818999869,
                            45.8905959997637
                        ],
                        [
                            -124.066417000126,
                            45.8981249998773
                        ],
                        [
                            -124.08015799992,
                            45.908263999795
                        ],
                        [
                            -124.08650800022,
                            45.9163259997195
                        ],
                        [
                            -124.0905710003,
                            45.9257519999953
                        ],
                        [
                            -124.092434000198,
                            45.9342610002223
                        ],
                        [
                            -124.091920000363,
                            45.9434549997717
                        ],
                        [
                            -124.084898000042,
                            45.9601369998641
                        ],
                        [
                            -124.07081900014,
                            45.9739389998341
                        ],
                        [
                            -124.05117599976,
                            45.983005999779
                        ],
                        [
                            -124.039543999912,
                            45.9859380001059
                        ],
                        [
                            -124.01916800031,
                            46.0037949998501
                        ],
                        [
                            -124.008124999743,
                            46.0099399998108
                        ],
                        [
                            -124.006794000122,
                            46.0178519998227
                        ],
                        [
                            -124.002782999921,
                            46.027544000246
                        ],
                        [
                            -124.005125000393,
                            46.0573829999277
                        ],
                        [
                            -124.008265000383,
                            46.0742929999071
                        ],
                        [
                            -124.00979600002,
                            46.0819730003106
                        ],
                        [
                            -124.023063999986,
                            46.1162720000326
                        ],
                        [
                            -124.048349999589,
                            46.1603590000771
                        ],
                        [
                            -124.0584509998,
                            46.1738339999156
                        ],
                        [
                            -124.067312999554,
                            46.1838490000756
                        ],
                        [
                            -124.088524000279,
                            46.1878779996969
                        ],
                        [
                            -124.103742000181,
                            46.1931139998825
                        ],
                        [
                            -124.111280999812,
                            46.1958979997704
                        ],
                        [
                            -124.126264000436,
                            46.2051019999159
                        ],
                        [
                            -124.137877999843,
                            46.2162420000727
                        ],
                        [
                            -124.1470290002,
                            46.2288570001726
                        ],
                        [
                            -124.159545999605,
                            46.2611320000612
                        ],
                        [
                            -124.035951000185,
                            46.2641829999265
                        ],
                        [
                            -124.03492000014,
                            46.264835000111
                        ],
                        [
                            -124.035680000023,
                            46.271473999856
                        ],
                        [
                            -124.033550999986,
                            46.2759109999348
                        ],
                        [
                            -124.038866999869,
                            46.2845600002884
                        ],
                        [
                            -124.035598999832,
                            46.2968429999735
                        ],
                        [
                            -124.024876999655,
                            46.299098999978
                        ],
                        [
                            -124.014875000077,
                            46.2931650002111
                        ],
                        [
                            -124.003615999599,
                            46.2747090000673
                        ],
                        [
                            -123.985991999651,
                            46.2727550001518
                        ],
                        [
                            -123.973346000374,
                            46.2685010000389
                        ],
                        [
                            -123.957618999761,
                            46.2601550000092
                        ],
                        [
                            -123.946544000305,
                            46.2498239999959
                        ],
                        [
                            -123.930476999933,
                            46.238805000048
                        ],
                        [
                            -123.872629999788,
                            46.2349870000523
                        ],
                        [
                            -123.815135999821,
                            46.2498269997645
                        ],
                        [
                            -123.779364999867,
                            46.2538839997202
                        ],
                        [
                            -123.741448999984,
                            46.269154999963
                        ],
                        [
                            -123.688115000181,
                            46.2501049997688
                        ],
                        [
                            -123.648774999892,
                            46.2588030002184
                        ],
                        [
                            -123.586954000254,
                            46.2558900000846
                        ],
                        [
                            -123.543929000177,
                            46.2595650002495
                        ],
                        [
                            -123.501246000406,
                            46.2710139999217
                        ],
                        [
                            -123.479643999868,
                            46.2691309997106
                        ],
                        [
                            -123.468742999591,
                            46.264531000125
                        ],
                        [
                            -123.447592000038,
                            46.2498319997927
                        ],
                        [
                            -123.42762899999,
                            46.2293480002815
                        ],
                        [
                            -123.430987000439,
                            46.1815710002401
                        ],
                        [
                            -123.390404000393,
                            46.1544280000901
                        ],
                        [
                            -123.379524000032,
                            46.1509930000893
                        ],
                        [
                            -123.371433000032,
                            46.1463719999496
                        ],
                        [
                            -123.280165999728,
                            46.1448430001193
                        ],
                        [
                            -123.213054000322,
                            46.1725409999894
                        ],
                        [
                            -123.166416000427,
                            46.1889789997242
                        ],
                        [
                            -123.115903999699,
                            46.1852680002546
                        ],
                        [
                            -123.106594000231,
                            46.178308999846
                        ],
                        [
                            -123.051064000445,
                            46.1535990003018
                        ],
                        [
                            -123.041297000144,
                            46.1463509999579
                        ],
                        [
                            -123.004233000205,
                            46.1338230000682
                        ],
                        [
                            -122.962681000038,
                            46.1048169998962
                        ],
                        [
                            -122.904119000217,
                            46.0837339997166
                        ],
                        [
                            -122.884478000384,
                            46.0602800000648
                        ],
                        [
                            -122.878091000274,
                            46.0312800001235
                        ],
                        [
                            -122.856158000198,
                            46.0144690000206
                        ],
                        [
                            -122.837637999924,
                            45.9808210002691
                        ],
                        [
                            -122.813999000309,
                            45.9609830001385
                        ],
                        [
                            -122.806186999968,
                            45.9324159999548
                        ],
                        [
                            -122.811500000376,
                            45.9127239999235
                        ],
                        [
                            -122.798090999945,
                            45.8843329997
                        ],
                        [
                            -122.78502600037,
                            45.8676989998541
                        ],
                        [
                            -122.785695999841,
                            45.8442160002868
                        ],
                        [
                            -122.795962999734,
                            45.8250239998821
                        ],
                        [
                            -122.795605000432,
                            45.8099999997518
                        ],
                        [
                            -122.769532000283,
                            45.7805829997803
                        ],
                        [
                            -122.761451000329,
                            45.7591629999581
                        ],
                        [
                            -122.760108000114,
                            45.7344130000214
                        ],
                        [
                            -122.772510999716,
                            45.6996369997144
                        ],
                        [
                            -122.774510999882,
                            45.6804370000223
                        ],
                        [
                            -122.763809999621,
                            45.6571379999313
                        ],
                        [
                            -122.738108999916,
                            45.6441380002232
                        ],
                        [
                            -122.713308999662,
                            45.6374380002609
                        ],
                        [
                            -122.691009999888,
                            45.6247389999614
                        ],
                        [
                            -122.675001000141,
                            45.6180250000668
                        ],
                        [
                            -122.643906999662,
                            45.6097389998131
                        ],
                        [
                            -122.602605999566,
                            45.6076389999293
                        ],
                        [
                            -122.550325000448,
                            45.5972690001067
                        ],
                        [
                            -122.523668000143,
                            45.5896320002253
                        ],
                        [
                            -122.47931499992,
                            45.5797610002042
                        ],
                        [
                            -122.453891000224,
                            45.5673120001749
                        ],
                        [
                            -122.438674000147,
                            45.5635849997856
                        ],
                        [
                            -122.410706000313,
                            45.5676330000615
                        ],
                        [
                            -122.3918019999,
                            45.5745410000088
                        ],
                        [
                            -122.380302000296,
                            45.5759410001222
                        ],
                        [
                            -122.352802000267,
                            45.5694409999586
                        ],
                        [
                            -122.331501999853,
                            45.5482410001735
                        ],
                        [
                            -122.294900999696,
                            45.5435410001285
                        ],
                        [
                            -122.266701000059,
                            45.5438410002664
                        ],
                        [
                            -122.248993000445,
                            45.547745000282
                        ],
                        [
                            -122.200791000402,
                            45.5651110002272
                        ],
                        [
                            -122.182863999607,
                            45.5767810001626
                        ],
                        [
                            -122.141612999739,
                            45.5840029997227
                        ],
                        [
                            -122.112316999759,
                            45.5813370002335
                        ],
                        [
                            -122.101750000291,
                            45.583168999962
                        ],
                        [
                            -122.044298999982,
                            45.6095350002307
                        ],
                        [
                            -122.021053999654,
                            45.6147799998055
                        ],
                        [
                            -122.001987999757,
                            45.6164750001766
                        ],
                        [
                            -121.983007000249,
                            45.622888000271
                        ],
                        [
                            -121.963080000186,
                            45.6328910000622
                        ],
                        [
                            -121.955775000009,
                            45.643585999879
                        ],
                        [
                            -121.951691000012,
                            45.6448360002074
                        ],
                        [
                            -121.934986999781,
                            45.6442309997731
                        ],
                        [
                            -121.908133999657,
                            45.6545580002291
                        ],
                        [
                            -121.900766999555,
                            45.6620160000705
                        ],
                        [
                            -121.901744999897,
                            45.6706690002725
                        ],
                        [
                            -121.867020999882,
                            45.6933909999427
                        ],
                        [
                            -121.811183999948,
                            45.7067149998986
                        ],
                        [
                            -121.735072999623,
                            45.6940019999024
                        ],
                        [
                            -121.70729499976,
                            45.694835999947
                        ],
                        [
                            -121.668405999557,
                            45.7051139997424
                        ],
                        [
                            -121.63132300025,
                            45.7046969998168
                        ],
                        [
                            -121.532934000067,
                            45.7265039996882
                        ],
                        [
                            -121.499097999982,
                            45.7207469997063
                        ],
                        [
                            -121.46279499975,
                            45.7012820001269
                        ],
                        [
                            -121.423961999625,
                            45.694074000144
                        ],
                        [
                            -121.401739999844,
                            45.6928930001233
                        ],
                        [
                            -121.372572999736,
                            45.7030329998166
                        ],
                        [
                            -121.337712000351,
                            45.7048390003001
                        ],
                        [
                            -121.312296000154,
                            45.6998389999491
                        ],
                        [
                            -121.287295999883,
                            45.6868670000826
                        ],
                        [
                            -121.215768000219,
                            45.6712280001876
                        ],
                        [
                            -121.200351000126,
                            45.649838999933
                        ],
                        [
                            -121.195184000206,
                            45.6295610002865
                        ],
                        [
                            -121.19645899975,
                            45.6166100000218
                        ],
                        [
                            -121.183683999704,
                            45.6064500002092
                        ],
                        [
                            -121.167852000407,
                            45.6060980002066
                        ],
                        [
                            -121.145582999872,
                            45.6079449999419
                        ],
                        [
                            -121.139385999732,
                            45.6120449997672
                        ],
                        [
                            -121.131832999858,
                            45.6097530001392
                        ],
                        [
                            -121.11717700037,
                            45.6182009999902
                        ],
                        [
                            -121.120109999773,
                            45.6231650002289
                        ],
                        [
                            -121.084933000021,
                            45.6478929998915
                        ],
                        [
                            -121.064460000336,
                            45.6524910001578
                        ],
                        [
                            -121.033822999792,
                            45.6509630001734
                        ],
                        [
                            -121.007369999701,
                            45.6531970000234
                        ],
                        [
                            -120.98349400026,
                            45.6483580000953
                        ],
                        [
                            -120.953115000357,
                            45.6567419997031
                        ],
                        [
                            -120.944018000427,
                            45.6563920000368
                        ],
                        [
                            -120.913607999663,
                            45.6399920001475
                        ],
                        [
                            -120.895822000231,
                            45.6429229999217
                        ],
                        [
                            -120.855414999912,
                            45.6717290000274
                        ],
                        [
                            -120.78857399977,
                            45.6864240002501
                        ],
                        [
                            -120.728626000076,
                            45.7053190001917
                        ],
                        [
                            -120.689535000207,
                            45.7157270002157
                        ],
                        [
                            -120.669280000081,
                            45.7298830001529
                        ],
                        [
                            -120.635050999635,
                            45.7457110002643
                        ],
                        [
                            -120.591144000374,
                            45.7465089997147
                        ],
                        [
                            -120.55928699964,
                            45.7383149999633
                        ],
                        [
                            -120.522734999886,
                            45.7102870002192
                        ],
                        [
                            -120.505742999775,
                            45.6999489998135
                        ],
                        [
                            -120.482614999622,
                            45.6944069997418
                        ],
                        [
                            -120.40365100026,
                            45.6994440001068
                        ],
                        [
                            -120.326332000338,
                            45.7114199999691
                        ],
                        [
                            -120.283431000109,
                            45.7211650000006
                        ],
                        [
                            -120.210773999593,
                            45.725915000129
                        ],
                        [
                            -120.170603000174,
                            45.7619799999427
                        ],
                        [
                            -120.141480000447,
                            45.7732080000238
                        ],
                        [
                            -120.070235999567,
                            45.7851559997646
                        ],
                        [
                            -120.0148370002,
                            45.8071199999526
                        ],
                        [
                            -119.965772000342,
                            45.8243759997313
                        ],
                        [
                            -119.907244999782,
                            45.8281760000308
                        ],
                        [
                            -119.849343000284,
                            45.8401799997242
                        ],
                        [
                            -119.802705000388,
                            45.8476300003082
                        ],
                        [
                            -119.772853999667,
                            45.8457270000383
                        ],
                        [
                            -119.669973999782,
                            45.8569080001653
                        ],
                        [
                            -119.623627999964,
                            45.905601999769
                        ],
                        [
                            -119.600543000368,
                            45.9195490000733
                        ],
                        [
                            -119.571602000216,
                            45.9254779998565
                        ],
                        [
                            -119.524519000081,
                            45.9085669998883
                        ],
                        [
                            -119.488082999781,
                            45.9063610002849
                        ],
                        [
                            -119.450397999875,
                            45.9173809997935
                        ],
                        [
                            -119.364498000407,
                            45.9215470000912
                        ],
                        [
                            -119.322573999796,
                            45.9330719999318
                        ],
                        [
                            -119.25707199967,
                            45.9398669997824
                        ],
                        [
                            -119.195519999646,
                            45.9279000002656
                        ],
                        [
                            -119.169191000302,
                            45.9277030000952
                        ],
                        [
                            -119.12632199986,
                            45.9327840002727
                        ],
                        [
                            -119.093294000237,
                            45.942723000017
                        ],
                        [
                            -119.061496999777,
                            45.9585420000924
                        ],
                        [
                            -119.027214999627,
                            45.9691120001559
                        ],
                        [
                            -119.008650999871,
                            45.9792230003059
                        ],
                        [
                            -118.98725799957,
                            45.9998549997806
                        ],
                        [
                            -118.725241999567,
                            46.0011570002748
                        ],
                        [
                            -117.919176000158,
                            46.0004210002613
                        ],
                        [
                            -117.405800000207,
                            45.9984830001529
                        ],
                        [
                            -117.355951000176,
                            45.9965679999581
                        ],
                        [
                            -117.337908999753,
                            45.9985749997002
                        ],
                        [
                            -117.126147999662,
                            45.9978989998391
                        ],
                        [
                            -116.916071000268,
                            45.9953570002034
                        ],
                        [
                            -116.911469000237,
                            45.9890490002265
                        ],
                        [
                            -116.893605999914,
                            45.977580000195
                        ],
                        [
                            -116.892934999719,
                            45.9743959998224
                        ],
                        [
                            -116.894905999573,
                            45.96892200014
                        ],
                        [
                            -116.891297999777,
                            45.9603270000378
                        ],
                        [
                            -116.880506000178,
                            45.9585079997436
                        ],
                        [
                            -116.875980000316,
                            45.9547749998784
                        ],
                        [
                            -116.875705999781,
                            45.9450079999796
                        ],
                        [
                            -116.87048399961,
                            45.9324080002196
                        ],
                        [
                            -116.86965500013,
                            45.9237989997691
                        ],
                        [
                            -116.86502899981,
                            45.9144979999768
                        ],
                        [
                            -116.857254000178,
                            45.904158999931
                        ],
                        [
                            -116.848421999663,
                            45.8981610002923
                        ],
                        [
                            -116.843550000194,
                            45.8922730001999
                        ],
                        [
                            -116.815917999921,
                            45.8789979999987
                        ],
                        [
                            -116.804902999841,
                            45.8658150000324
                        ],
                        [
                            -116.796458999664,
                            45.8612239997679
                        ],
                        [
                            -116.787791999903,
                            45.8442669998282
                        ],
                        [
                            -116.789065999623,
                            45.8334710000535
                        ],
                        [
                            -116.782138999737,
                            45.8251160000804
                        ],
                        [
                            -116.774224000362,
                            45.8233690001032
                        ],
                        [
                            -116.763401999728,
                            45.8165800002418
                        ],
                        [
                            -116.75978899991,
                            45.8161669999722
                        ],
                        [
                            -116.749342999917,
                            45.8192550001575
                        ],
                        [
                            -116.735778999676,
                            45.8263640000783
                        ],
                        [
                            -116.709749999908,
                            45.825728000006
                        ],
                        [
                            -116.703417000224,
                            45.8217039997508
                        ],
                        [
                            -116.697191999597,
                            45.8201349998048
                        ],
                        [
                            -116.693424999793,
                            45.8116400000591
                        ],
                        [
                            -116.68700699972,
                            45.806318999975
                        ],
                        [
                            -116.680817999976,
                            45.7943879998751
                        ],
                        [
                            -116.665267999813,
                            45.782058999816
                        ],
                        [
                            -116.65962899989,
                            45.7800160000823
                        ],
                        [
                            -116.646341999658,
                            45.7798150001575
                        ],
                        [
                            -116.632031999777,
                            45.7849789998617
                        ],
                        [
                            -116.626971000163,
                            45.7841259998887
                        ],
                        [
                            -116.623741999586,
                            45.7818129998088
                        ],
                        [
                            -116.616935000249,
                            45.7824639998469
                        ],
                        [
                            -116.594209999605,
                            45.7790800001074
                        ],
                        [
                            -116.575128999639,
                            45.7658629999393
                        ],
                        [
                            -116.567338999938,
                            45.7630039997543
                        ],
                        [
                            -116.559443999757,
                            45.7551890000756
                        ],
                        [
                            -116.547240000346,
                            45.7516769997623
                        ],
                        [
                            -116.535698000012,
                            45.7342309997897
                        ],
                        [
                            -116.538201000142,
                            45.7161610000811
                        ],
                        [
                            -116.535395999888,
                            45.6917340000618
                        ],
                        [
                            -116.523960999683,
                            45.6776390001719
                        ],
                        [
                            -116.517674999855,
                            45.6750390002308
                        ],
                        [
                            -116.505428999714,
                            45.6619140000524
                        ],
                        [
                            -116.488818000267,
                            45.6507990002343
                        ],
                        [
                            -116.48726300034,
                            45.6478100001351
                        ],
                        [
                            -116.48773399962,
                            45.643955999928
                        ],
                        [
                            -116.48249499973,
                            45.6399159998414
                        ],
                        [
                            -116.482806000075,
                            45.6335950001006
                        ],
                        [
                            -116.472099999791,
                            45.6292010002089
                        ],
                        [
                            -116.471241000174,
                            45.6276690002531
                        ],
                        [
                            -116.472877000291,
                            45.6248769998576
                        ],
                        [
                            -116.463504000176,
                            45.6157850000808
                        ],
                        [
                            -116.463407000092,
                            45.6038570002985
                        ],
                        [
                            -116.471325999664,
                            45.5939339998708
                        ],
                        [
                            -116.482163000367,
                            45.5866819998191
                        ],
                        [
                            -116.483611999989,
                            45.5835109996935
                        ],
                        [
                            -116.481207999592,
                            45.5805970003001
                        ],
                        [
                            -116.482416000087,
                            45.5774490002137
                        ],
                        [
                            -116.490278999583,
                            45.5744989996988
                        ],
                        [
                            -116.502755999704,
                            45.5666079999956
                        ],
                        [
                            -116.50344899964,
                            45.5616949998934
                        ],
                        [
                            -116.506966999922,
                            45.5584270001287
                        ],
                        [
                            -116.511749999702,
                            45.5564450000984
                        ],
                        [
                            -116.5207400004,
                            45.5562009999682
                        ],
                        [
                            -116.528319000214,
                            45.551512999947
                        ],
                        [
                            -116.528571000109,
                            45.5501490000739
                        ],
                        [
                            -116.52417199957,
                            45.548564999713
                        ],
                        [
                            -116.52343900035,
                            45.5465559999593
                        ],
                        [
                            -116.52657900034,
                            45.5444860000178
                        ],
                        [
                            -116.527208999628,
                            45.5407019998167
                        ],
                        [
                            -116.531726999993,
                            45.5366849999558
                        ],
                        [
                            -116.535482000102,
                            45.5250789997574
                        ],
                        [
                            -116.540402999974,
                            45.5209250002487
                        ],
                        [
                            -116.543836999692,
                            45.5141929997312
                        ],
                        [
                            -116.555487999807,
                            45.5074699997444
                        ],
                        [
                            -116.557462999858,
                            45.5041730002217
                        ],
                        [
                            -116.55713200032,
                            45.5020170000364
                        ],
                        [
                            -116.553471999748,
                            45.4991079999369
                        ],
                        [
                            -116.553089000332,
                            45.4962099999116
                        ],
                        [
                            -116.558806999897,
                            45.4811890002206
                        ],
                        [
                            -116.554980999644,
                            45.4727979999563
                        ],
                        [
                            -116.554830000031,
                            45.4629300001588
                        ],
                        [
                            -116.563972999992,
                            45.460228999919
                        ],
                        [
                            -116.568251000157,
                            45.4558200000495
                        ],
                        [
                            -116.576811999612,
                            45.4520190001831
                        ],
                        [
                            -116.588194999938,
                            45.4429199997488
                        ],
                        [
                            -116.597446999679,
                            45.4127699999373
                        ],
                        [
                            -116.623385000109,
                            45.3939629999168
                        ],
                        [
                            -116.623047,
                            45.3919419999282
                        ],
                        [
                            -116.632343999947,
                            45.377415000114
                        ],
                        [
                            -116.641250999906,
                            45.3677929998214
                        ],
                        [
                            -116.673793000181,
                            45.3215110002356
                        ],
                        [
                            -116.674647999601,
                            45.31434199977
                        ],
                        [
                            -116.672162999912,
                            45.2889379996975
                        ],
                        [
                            -116.67449299979,
                            45.2763489998759
                        ],
                        [
                            -116.691387999817,
                            45.2637390002415
                        ],
                        [
                            -116.698764999965,
                            45.2451819998135
                        ],
                        [
                            -116.703696999708,
                            45.2400990002385
                        ],
                        [
                            -116.702709000219,
                            45.2379099997015
                        ],
                        [
                            -116.704110999985,
                            45.2281539999543
                        ],
                        [
                            -116.709749999908,
                            45.2172430000035
                        ],
                        [
                            -116.708545999611,
                            45.2073559997883
                        ],
                        [
                            -116.709535999648,
                            45.2030150003118
                        ],
                        [
                            -116.724204999554,
                            45.1715010001164
                        ],
                        [
                            -116.724187999835,
                            45.1629239999579
                        ],
                        [
                            -116.728018000287,
                            45.1542369998395
                        ],
                        [
                            -116.72960699965,
                            45.1420910002371
                        ],
                        [
                            -116.733295999636,
                            45.1384860001838
                        ],
                        [
                            -116.745535000105,
                            45.1330380002005
                        ],
                        [
                            -116.746859999878,
                            45.1242230000653
                        ],
                        [
                            -116.752838999559,
                            45.1217679997589
                        ],
                        [
                            -116.754642999906,
                            45.1139719999244
                        ],
                        [
                            -116.774846999978,
                            45.105535999901
                        ],
                        [
                            -116.783537000203,
                            45.093605000285
                        ],
                        [
                            -116.783709999557,
                            45.0769719998127
                        ],
                        [
                            -116.790878000365,
                            45.0699470001119
                        ],
                        [
                            -116.79732900005,
                            45.0602670002561
                        ],
                        [
                            -116.808575999934,
                            45.0506520000898
                        ],
                        [
                            -116.814320000337,
                            45.0483309999662
                        ],
                        [
                            -116.820914000137,
                            45.0431179998557
                        ],
                        [
                            -116.825132999852,
                            45.0378400000801
                        ],
                        [
                            -116.841314000027,
                            45.0309069997511
                        ],
                        [
                            -116.847943999812,
                            45.0226019999313
                        ],
                        [
                            -116.844796000324,
                            45.0153119999862
                        ],
                        [
                            -116.847190999601,
                            45.011495999936
                        ],
                        [
                            -116.845112999618,
                            45.0079530001252
                        ],
                        [
                            -116.844624999721,
                            45.0014349999877
                        ],
                        [
                            -116.856753999687,
                            44.9842980001004
                        ],
                        [
                            -116.857982000274,
                            44.9773200002257
                        ],
                        [
                            -116.852020000311,
                            44.9720300000687
                        ],
                        [
                            -116.848573999999,
                            44.9717160002237
                        ],
                        [
                            -116.843550000194,
                            44.9754080000518
                        ],
                        [
                            -116.839744000032,
                            44.9830630002255
                        ],
                        [
                            -116.832075999631,
                            44.9844540000179
                        ],
                        [
                            -116.826893999643,
                            44.9838779997765
                        ],
                        [
                            -116.826271000027,
                            44.9824930000589
                        ],
                        [
                            -116.836449000231,
                            44.9692390001034
                        ],
                        [
                            -116.847044000187,
                            44.9653600003031
                        ],
                        [
                            -116.850984000243,
                            44.9615560001143
                        ],
                        [
                            -116.850737000371,
                            44.958113000177
                        ],
                        [
                            -116.846460999855,
                            44.9515210000053
                        ],
                        [
                            -116.835701999868,
                            44.940632999712
                        ],
                        [
                            -116.831990000316,
                            44.9330070000885
                        ],
                        [
                            -116.83846699994,
                            44.9236010002283
                        ],
                        [
                            -116.838955999661,
                            44.9178260000118
                        ],
                        [
                            -116.842108000245,
                            44.9149219997206
                        ],
                        [
                            -116.842921999657,
                            44.908907000297
                        ],
                        [
                            -116.848892999841,
                            44.9007189999514
                        ],
                        [
                            -116.85051200024,
                            44.8935240000294
                        ],
                        [
                            -116.85237299959,
                            44.8919190002138
                        ],
                        [
                            -116.852427000016,
                            44.8875779999344
                        ],
                        [
                            -116.865337999606,
                            44.8706030002921
                        ],
                        [
                            -116.877136000034,
                            44.8660489999237
                        ],
                        [
                            -116.879374999676,
                            44.8617930001325
                        ],
                        [
                            -116.887747999708,
                            44.8557989997393
                        ],
                        [
                            -116.8963960001,
                            44.8556820000831
                        ],
                        [
                            -116.898619999673,
                            44.853744000214
                        ],
                        [
                            -116.896248999787,
                            44.8483299999536
                        ],
                        [
                            -116.900179999622,
                            44.8398589997818
                        ],
                        [
                            -116.900146000186,
                            44.8366719999266
                        ],
                        [
                            -116.90577199969,
                            44.8347930001693
                        ],
                        [
                            -116.922099000353,
                            44.8118800000463
                        ],
                        [
                            -116.931099000199,
                            44.8047810000728
                        ],
                        [
                            -116.933798999973,
                            44.7967810002023
                        ],
                        [
                            -116.930699999991,
                            44.789881000063
                        ],
                        [
                            -116.934700000322,
                            44.7838809996907
                        ],
                        [
                            -116.943300000134,
                            44.7816809999982
                        ],
                        [
                            -116.949000999982,
                            44.7779809999967
                        ],
                        [
                            -116.97090200027,
                            44.7738809999563
                        ],
                        [
                            -116.986732999743,
                            44.7622360002385
                        ],
                        [
                            -116.998902999716,
                            44.7563820001717
                        ],
                        [
                            -117.013801999776,
                            44.7568409998808
                        ],
                        [
                            -117.019999999741,
                            44.7510189999784
                        ],
                        [
                            -117.029419999711,
                            44.7511020003155
                        ],
                        [
                            -117.038269999769,
                            44.7481790002749
                        ],
                        [
                            -117.04782100016,
                            44.7415270002916
                        ],
                        [
                            -117.048177999637,
                            44.7359159999587
                        ],
                        [
                            -117.050204999566,
                            44.7325650001933
                        ],
                        [
                            -117.058302000312,
                            44.7304819999599
                        ],
                        [
                            -117.062273000331,
                            44.7271429998291
                        ],
                        [
                            -117.060453999915,
                            44.721668000092
                        ],
                        [
                            -117.062686999709,
                            44.711678999807
                        ],
                        [
                            -117.06179899978,
                            44.7066540001396
                        ],
                        [
                            -117.063824000059,
                            44.7036230001281
                        ],
                        [
                            -117.072221000381,
                            44.7005169996882
                        ],
                        [
                            -117.074136999982,
                            44.6963739998078
                        ],
                        [
                            -117.079119999779,
                            44.6921750002658
                        ],
                        [
                            -117.08077199979,
                            44.6841610002681
                        ],
                        [
                            -117.078574000156,
                            44.6803279997498
                        ],
                        [
                            -117.08079600008,
                            44.6737679998639
                        ],
                        [
                            -117.091222999806,
                            44.6688069997097
                        ],
                        [
                            -117.095868000392,
                            44.6647369996878
                        ],
                        [
                            -117.096790999583,
                            44.6573849997235
                        ],
                        [
                            -117.094967999868,
                            44.6520110001057
                        ],
                        [
                            -117.098205999768,
                            44.6476509999754
                        ],
                        [
                            -117.098220999836,
                            44.640688999919
                        ],
                        [
                            -117.108230999811,
                            44.6271100002659
                        ],
                        [
                            -117.114754000364,
                            44.6248829999924
                        ],
                        [
                            -117.120522000158,
                            44.6146579998333
                        ],
                        [
                            -117.125267000304,
                            44.5938179999486
                        ],
                        [
                            -117.124754000293,
                            44.5838340001495
                        ],
                        [
                            -117.133963000376,
                            44.5752399999271
                        ],
                        [
                            -117.146032000067,
                            44.5686029997331
                        ],
                        [
                            -117.148254999815,
                            44.5643709999644
                        ],
                        [
                            -117.147934000323,
                            44.5621430002093
                        ],
                        [
                            -117.142929999712,
                            44.5572360002528
                        ],
                        [
                            -117.144160999773,
                            44.5456469997107
                        ],
                        [
                            -117.149242000377,
                            44.5361510000852
                        ],
                        [
                            -117.152405999759,
                            44.5318020002532
                        ],
                        [
                            -117.161033000234,
                            44.5251660000855
                        ],
                        [
                            -117.167186999818,
                            44.523430999683
                        ],
                        [
                            -117.181582999913,
                            44.522960000189
                        ],
                        [
                            -117.189759000301,
                            44.513384999703
                        ],
                        [
                            -117.192494000236,
                            44.503272000197
                        ],
                        [
                            -117.200237000081,
                            44.4920270003042
                        ],
                        [
                            -117.208935999628,
                            44.485660999797
                        ],
                        [
                            -117.216372000225,
                            44.4861600000465
                        ],
                        [
                            -117.225075999795,
                            44.4823460000995
                        ],
                        [
                            -117.225371000246,
                            44.4754760001072
                        ],
                        [
                            -117.221548000366,
                            44.4701459999946
                        ],
                        [
                            -117.220383000427,
                            44.4630369998438
                        ],
                        [
                            -117.217014999933,
                            44.4590419997855
                        ],
                        [
                            -117.215254000142,
                            44.4521520003055
                        ],
                        [
                            -117.214637000374,
                            44.4480300000887
                        ],
                        [
                            -117.216562000196,
                            44.4422760001447
                        ],
                        [
                            -117.215071999669,
                            44.4271619999341
                        ],
                        [
                            -117.219291000282,
                            44.4192359999842
                        ],
                        [
                            -117.222974000421,
                            44.4164570000399
                        ],
                        [
                            -117.227259000259,
                            44.4052359999969
                        ],
                        [
                            -117.242872999996,
                            44.395556999884
                        ],
                        [
                            -117.242754999995,
                            44.3901940002362
                        ],
                        [
                            -117.237829999925,
                            44.3823859997663
                        ],
                        [
                            -117.235116999732,
                            44.3738529997404
                        ],
                        [
                            -117.216911000175,
                            44.3601629999592
                        ],
                        [
                            -117.2069620003,
                            44.3552059998205
                        ],
                        [
                            -117.202665999694,
                            44.3498129997627
                        ],
                        [
                            -117.19733899994,
                            44.3474059997294
                        ],
                        [
                            -117.189769000347,
                            44.3365850000667
                        ],
                        [
                            -117.191546000031,
                            44.3296209997729
                        ],
                        [
                            -117.196336000383,
                            44.3266040000616
                        ],
                        [
                            -117.203322999645,
                            44.3130240000862
                        ],
                        [
                            -117.216794999825,
                            44.3082360000387
                        ],
                        [
                            -117.222740000069,
                            44.2983259997373
                        ],
                        [
                            -117.216973999925,
                            44.2883569997667
                        ],
                        [
                            -117.207505000449,
                            44.282722000266
                        ],
                        [
                            -117.198147000402,
                            44.2738280000463
                        ],
                        [
                            -117.170341000052,
                            44.2588889998601
                        ],
                        [
                            -117.14621799984,
                            44.2580269998099
                        ],
                        [
                            -117.136766999907,
                            44.2603909998386
                        ],
                        [
                            -117.130866000043,
                            44.2694920003043
                        ],
                        [
                            -117.119861000009,
                            44.2782720002544
                        ],
                        [
                            -117.111616000023,
                            44.2806659998003
                        ],
                        [
                            -117.104207000089,
                            44.2799420001407
                        ],
                        [
                            -117.09456899966,
                            44.2709759999004
                        ],
                        [
                            -117.089503000023,
                            44.2582340002561
                        ],
                        [
                            -117.07834999985,
                            44.2498850000564
                        ],
                        [
                            -117.067283999717,
                            44.2440100001525
                        ],
                        [
                            -117.059351999725,
                            44.2372439997851
                        ],
                        [
                            -117.056509999661,
                            44.2308740001789
                        ],
                        [
                            -117.051430999605,
                            44.2287479998985
                        ],
                        [
                            -117.046148000232,
                            44.2306689998901
                        ],
                        [
                            -117.042282999621,
                            44.2427750000089
                        ],
                        [
                            -117.031861999743,
                            44.2486350001911
                        ],
                        [
                            -117.025277000164,
                            44.2485049997302
                        ],
                        [
                            -117.016916999652,
                            44.2453899997569
                        ],
                        [
                            -117.005961000021,
                            44.246497999958
                        ],
                        [
                            -116.986837000398,
                            44.2454690002305
                        ],
                        [
                            -116.975984999626,
                            44.2428750002296
                        ],
                        [
                            -116.97195800043,
                            44.2356770001373
                        ],
                        [
                            -116.973945000176,
                            44.2259320001836
                        ],
                        [
                            -116.970245000319,
                            44.2208429997042
                        ],
                        [
                            -116.973700999779,
                            44.208016999779
                        ],
                        [
                            -116.971674999674,
                            44.1972559998663
                        ],
                        [
                            -116.965497999626,
                            44.1941260000721
                        ],
                        [
                            -116.94759099982,
                            44.1912640003097
                        ],
                        [
                            -116.935442999588,
                            44.1939620000706
                        ],
                        [
                            -116.925391999606,
                            44.191544000146
                        ],
                        [
                            -116.922952000122,
                            44.1873580000842
                        ],
                        [
                            -116.915738000182,
                            44.1831520000009
                        ],
                        [
                            -116.902752000248,
                            44.1794669999802
                        ],
                        [
                            -116.895756999692,
                            44.1712669999143
                        ],
                        [
                            -116.894201999766,
                            44.1623169997636
                        ],
                        [
                            -116.89593099977,
                            44.1542949998591
                        ],
                        [
                            -116.901497999723,
                            44.1492550002137
                        ],
                        [
                            -116.902406999569,
                            44.1451679997882
                        ],
                        [
                            -116.911218000167,
                            44.1399919999969
                        ],
                        [
                            -116.914235999959,
                            44.1273039998811
                        ],
                        [
                            -116.928561999733,
                            44.115158999953
                        ],
                        [
                            -116.929525000006,
                            44.1130589999727
                        ],
                        [
                            -116.927868999797,
                            44.1103099999307
                        ],
                        [
                            -116.928304999815,
                            44.1073269997264
                        ],
                        [
                            -116.933701000064,
                            44.100043000031
                        ],
                        [
                            -116.943126999882,
                            44.0940629997847
                        ],
                        [
                            -116.967203000238,
                            44.0909359997339
                        ],
                        [
                            -116.974253000148,
                            44.088295000233
                        ],
                        [
                            -116.977351000305,
                            44.0853640001793
                        ],
                        [
                            -116.975956000212,
                            44.0828999998647
                        ],
                        [
                            -116.973185000293,
                            44.0494250000227
                        ],
                        [
                            -116.95667500023,
                            44.0441489998808
                        ],
                        [
                            -116.952925999969,
                            44.0400219996906
                        ],
                        [
                            -116.942002999951,
                            44.0345990002943
                        ],
                        [
                            -116.936133999874,
                            44.0271400001576
                        ],
                        [
                            -116.934498999582,
                            44.02121799981
                        ],
                        [
                            -116.93686199997,
                            44.0105039998031
                        ],
                        [
                            -116.944939999551,
                            44.0027789997087
                        ],
                        [
                            -116.935301000196,
                            43.9886160001227
                        ],
                        [
                            -116.940023999702,
                            43.9844449999414
                        ],
                        [
                            -116.960832000021,
                            43.9828230001149
                        ],
                        [
                            -116.966414999868,
                            43.9772959999115
                        ],
                        [
                            -116.972925000002,
                            43.9775219998364
                        ],
                        [
                            -116.976044999901,
                            43.9750579997702
                        ],
                        [
                            -116.970879999631,
                            43.968301999692
                        ],
                        [
                            -116.964413999877,
                            43.9688119998042
                        ],
                        [
                            -116.95920599995,
                            43.9663599999235
                        ],
                        [
                            -116.959807999649,
                            43.9638149997321
                        ],
                        [
                            -116.972431000258,
                            43.9579870002622
                        ],
                        [
                            -116.960477000194,
                            43.9551029998153
                        ],
                        [
                            -116.962540000109,
                            43.9469929998733
                        ],
                        [
                            -116.968214000192,
                            43.9418809998225
                        ],
                        [
                            -116.969138999931,
                            43.9388649999007
                        ],
                        [
                            -116.967640999906,
                            43.9347920000749
                        ],
                        [
                            -116.961022999817,
                            43.932366000206
                        ],
                        [
                            -116.959333999996,
                            43.9293249996849
                        ],
                        [
                            -116.961535000003,
                            43.9183879997297
                        ],
                        [
                            -116.979579999901,
                            43.9152530002556
                        ],
                        [
                            -116.981144999873,
                            43.9136930000087
                        ],
                        [
                            -116.97735399978,
                            43.9058159999905
                        ],
                        [
                            -116.976025999634,
                            43.8955479998518
                        ],
                        [
                            -116.983857000241,
                            43.8807950001429
                        ],
                        [
                            -116.982939999999,
                            43.867710000012
                        ],
                        [
                            -116.991415000139,
                            43.8638639998885
                        ],
                        [
                            -116.997391000346,
                            43.8648740000302
                        ],
                        [
                            -117.00718899971,
                            43.8636409998557
                        ],
                        [
                            -117.020034999901,
                            43.8589559999596
                        ],
                        [
                            -117.021721000247,
                            43.8561820002392
                        ],
                        [
                            -117.020116000092,
                            43.8539579999809
                        ],
                        [
                            -117.010701000144,
                            43.8497539998101
                        ],
                        [
                            -117.011582000401,
                            43.8410449999034
                        ],
                        [
                            -117.014611999888,
                            43.8396420001759
                        ],
                        [
                            -117.02515599979,
                            43.841333999925
                        ],
                        [
                            -117.031886999857,
                            43.8341559998854
                        ],
                        [
                            -117.032578000143,
                            43.8301809998057
                        ],
                        [
                            -117.023576999574,
                            43.8238109998111
                        ],
                        [
                            -117.021549999644,
                            43.8194020001263
                        ],
                        [
                            -117.026633999723,
                            43.8081100001716
                        ],
                        [
                            -117.026832999915,
                            43.7336699997392
                        ],
                        [
                            -117.02619699988,
                            41.9998900000167
                        ],
                        [
                            -118.223188999668,
                            41.9972490000088
                        ],
                        [
                            -118.707887999956,
                            41.9920819997804
                        ],
                        [
                            -119.33677800027,
                            41.9939560003099
                        ],
                        [
                            -119.826835000273,
                            41.9974770001489
                        ],
                        [
                            -120.326386999691,
                            41.992586000032
                        ],
                        [
                            -121.022056000224,
                            41.9933019997855
                        ],
                        [
                            -121.168476999672,
                            41.9971779999419
                        ],
                        [
                            -121.344911000224,
                            41.9962140001272
                        ],
                        [
                            -122.378049000411,
                            42.0095030001829
                        ],
                        [
                            -122.7027870004,
                            42.0042240002237
                        ],
                        [
                            -123.043996999918,
                            42.0030840000773
                        ],
                        [
                            -123.145735000329,
                            42.0087609998891
                        ],
                        [
                            -123.183923000199,
                            42.0057889998447
                        ],
                        [
                            -123.346954999993,
                            41.9992760001472
                        ],
                        [
                            -123.564204000394,
                            42.0007059999163
                        ],
                        [
                            -123.624022000391,
                            41.9997699998165
                        ],
                        [
                            -123.657212000396,
                            41.9950940002023
                        ],
                        [
                            -123.728169000323,
                            41.9970559998112
                        ],
                        [
                            -123.810907999885,
                            41.9951639998154
                        ],
                        [
                            -124.328834999812,
                            41.9983340000251
                        ],
                        [
                            -124.338659000014,
                            42.0032559997471
                        ],
                        [
                            -124.3466029997,
                            42.0107800001754
                        ],
                        [
                            -124.349172999778,
                            42.016386000002
                        ],
                        [
                            -124.36091500013,
                            42.0203480002109
                        ],
                        [
                            -124.375046999735,
                            42.0292299998372
                        ],
                        [
                            -124.386185999664,
                            42.0423609998098
                        ],
                        [
                            -124.390350000026,
                            42.0501730001181
                        ],
                        [
                            -124.402428999763,
                            42.0574010002309
                        ],
                        [
                            -124.41250300021,
                            42.068489000073
                        ],
                        [
                            -124.418240999867,
                            42.0795659998376
                        ],
                        [
                            -124.420646000088,
                            42.0981640000172
                        ],
                        [
                            -124.42440600022,
                            42.1069480001826
                        ],
                        [
                            -124.42598799991,
                            42.117912000071
                        ],
                        [
                            -124.432455000387,
                            42.1318479997587
                        ],
                        [
                            -124.435325999865,
                            42.1498649998715
                        ],
                        [
                            -124.433514000021,
                            42.1648319999198
                        ],
                        [
                            -124.441232999577,
                            42.1766970000222
                        ],
                        [
                            -124.447366000142,
                            42.1815039999242
                        ],
                        [
                            -124.451960999602,
                            42.1878439998369
                        ],
                        [
                            -124.463306000293,
                            42.1949869998571
                        ],
                        [
                            -124.471356000285,
                            42.2037229999558
                        ],
                        [
                            -124.482030999708,
                            42.2241000002408
                        ],
                        [
                            -124.485882000076,
                            42.2474710002791
                        ],
                        [
                            -124.482173999823,
                            42.2682070002407
                        ],
                        [
                            -124.478258000056,
                            42.2780020001811
                        ],
                        [
                            -124.484788000282,
                            42.2832420000825
                        ],
                        [
                            -124.490334000318,
                            42.2906460001849
                        ],
                        [
                            -124.494117999841,
                            42.3017679999575
                        ],
                        [
                            -124.495344999704,
                            42.3156490002233
                        ],
                        [
                            -124.494070999985,
                            42.3258750000104
                        ],
                        [
                            -124.48654400005,
                            42.3382780002638
                        ],
                        [
                            -124.496624000345,
                            42.35219299984
                        ],
                        [
                            -124.499353000431,
                            42.3609469998483
                        ],
                        [
                            -124.500843999885,
                            42.3748349998957
                        ],
                        [
                            -124.49869999978,
                            42.3864500002028
                        ],
                        [
                            -124.494153999826,
                            42.3960860001999
                        ],
                        [
                            -124.527093999586,
                            42.4055519999509
                        ],
                        [
                            -124.542699999825,
                            42.4152519996718
                        ],
                        [
                            -124.549664000419,
                            42.4220600001044
                        ],
                        [
                            -124.55581499963,
                            42.4324870001119
                        ],
                        [
                            -124.561755999677,
                            42.438282999749
                        ],
                        [
                            -124.569508999568,
                            42.4545859998064
                        ],
                        [
                            -124.570438000404,
                            42.4633790001717
                        ],
                        [
                            -124.567221000421,
                            42.4777910000993
                        ],
                        [
                            -124.554651000414,
                            42.4941419997778
                        ],
                        [
                            -124.547516000116,
                            42.4998330001267
                        ],
                        [
                            -124.544309000179,
                            42.4998330001267
                        ],
                        [
                            -124.535229999792,
                            42.5049149999208
                        ],
                        [
                            -124.518687000117,
                            42.5110759996963
                        ],
                        [
                            -124.512165000287,
                            42.5243359998478
                        ],
                        [
                            -124.501219999629,
                            42.5353880001944
                        ],
                        [
                            -124.489838000026,
                            42.542901000109
                        ],
                        [
                            -124.46789200043,
                            42.5508199999033
                        ],
                        [
                            -124.465350999766,
                            42.5530330000506
                        ],
                        [
                            -124.474072999778,
                            42.5628049999589
                        ],
                        [
                            -124.479422000171,
                            42.5739510000727
                        ],
                        [
                            -124.48083800018,
                            42.5815770003255
                        ],
                        [
                            -124.479941000029,
                            42.5914809998349
                        ],
                        [
                            -124.472912999861,
                            42.6107520002236
                        ],
                        [
                            -124.473553999919,
                            42.6147340001832
                        ],
                        [
                            -124.492547999846,
                            42.6154139998369
                        ],
                        [
                            -124.508492000194,
                            42.6195639999888
                        ],
                        [
                            -124.519550999757,
                            42.6248320003281
                        ],
                        [
                            -124.530312000292,
                            42.632279999972
                        ],
                        [
                            -124.540590000055,
                            42.6444919998995
                        ],
                        [
                            -124.545721999815,
                            42.6571659999371
                        ],
                        [
                            -124.547427000427,
                            42.6724000002095
                        ],
                        [
                            -124.544129000254,
                            42.683817000029
                        ],
                        [
                            -124.56123299962,
                            42.6927550002492
                        ],
                        [
                            -124.584688999835,
                            42.7193019996971
                        ],
                        [
                            -124.60161599965,
                            42.716626999909
                        ],
                        [
                            -124.620624999645,
                            42.7176649999804
                        ],
                        [
                            -124.642130000099,
                            42.716112999896
                        ],
                        [
                            -124.664637000285,
                            42.7204819999237
                        ],
                        [
                            -124.682414000394,
                            42.7301850001194
                        ],
                        [
                            -124.690712000083,
                            42.7382539999439
                        ],
                        [
                            -124.700342000116,
                            42.7525259997485
                        ],
                        [
                            -124.703540999658,
                            42.7703689999412
                        ],
                        [
                            -124.700282999667,
                            42.7840280002739
                        ],
                        [
                            -124.695813999706,
                            42.7911250000837
                        ],
                        [
                            -124.680631999789,
                            42.8042510002916
                        ],
                        [
                            -124.677920000319,
                            42.8143199996923
                        ],
                        [
                            -124.671761999639,
                            42.8255820002803
                        ],
                        [
                            -124.655124000428,
                            42.8400199999312
                        ],
                        [
                            -124.652458000091,
                            42.8506920002039
                        ],
                        [
                            -124.645153999738,
                            42.8618920003133
                        ],
                        [
                            -124.626225000108,
                            42.8757909999865
                        ],
                        [
                            -124.622126000043,
                            42.8771979998422
                        ],
                        [
                            -124.615955999668,
                            42.8928820002277
                        ],
                        [
                            -124.606859999562,
                            42.9035249997512
                        ],
                        [
                            -124.591834000179,
                            42.9147149997983
                        ],
                        [
                            -124.57235300018,
                            42.9225139997851
                        ],
                        [
                            -124.564750000077,
                            42.9330180000915
                        ],
                        [
                            -124.558973000062,
                            42.9455620002304
                        ],
                        [
                            -124.553514000064,
                            42.9517100000583
                        ],
                        [
                            -124.53060600002,
                            42.9998299996858
                        ],
                        [
                            -124.532991999975,
                            42.9998299996858
                        ],
                        [
                            -124.524993999861,
                            43.0164199998134
                        ],
                        [
                            -124.505331000288,
                            43.0679530001754
                        ],
                        [
                            -124.507933000152,
                            43.0732950002935
                        ],
                        [
                            -124.509199000374,
                            43.0854149998786
                        ],
                        [
                            -124.514420999647,
                            43.0942619998112
                        ],
                        [
                            -124.516361000436,
                            43.1025420001269
                        ],
                        [
                            -124.51502400007,
                            43.1144970002216
                        ],
                        [
                            -124.515840000029,
                            43.1248300001232
                        ],
                        [
                            -124.505968999972,
                            43.143379000218
                        ],
                        [
                            -124.49392600022,
                            43.153541999901
                        ],
                        [
                            -124.481613000132,
                            43.1594400002801
                        ],
                        [
                            -124.475816000025,
                            43.178858999988
                        ],
                        [
                            -124.465235000313,
                            43.2003079999717
                        ],
                        [
                            -124.469449999831,
                            43.2083089996995
                        ],
                        [
                            -124.471261999675,
                            43.2195140001796
                        ],
                        [
                            -124.470326000065,
                            43.2454960000768
                        ],
                        [
                            -124.465005999984,
                            43.2574660001371
                        ],
                        [
                            -124.45549800015,
                            43.2682329996925
                        ],
                        [
                            -124.463670000341,
                            43.2747500002093
                        ],
                        [
                            -124.474848999554,
                            43.2885660002827
                        ],
                        [
                            -124.478001999963,
                            43.3034590001714
                        ],
                        [
                            -124.476948000352,
                            43.32937100026
                        ],
                        [
                            -124.471636999594,
                            43.3391510000305
                        ],
                        [
                            -124.461424000128,
                            43.3501169998256
                        ],
                        [
                            -124.446699000146,
                            43.3581570000101
                        ],
                        [
                            -124.441075000291,
                            43.37288400021
                        ],
                        [
                            -124.425643000129,
                            43.3858859999598
                        ],
                        [
                            -124.40780499992,
                            43.3936969998076
                        ],
                        [
                            -124.39218600016,
                            43.3965439997609
                        ],
                        [
                            -124.38027399993,
                            43.4028459997065
                        ],
                        [
                            -124.360319000278,
                            43.4343170002977
                        ],
                        [
                            -124.351466999672,
                            43.4542490002264
                        ],
                        [
                            -124.343835999979,
                            43.4650119997961
                        ],
                        [
                            -124.328714000336,
                            43.4998349996825
                        ],
                        [
                            -124.296768999738,
                            43.5859579997264
                        ],
                        [
                            -124.280333000193,
                            43.6483940000994
                        ],
                        [
                            -124.284926000003,
                            43.6598740000928
                        ],
                        [
                            -124.285295999899,
                            43.6727260001266
                        ],
                        [
                            -124.279635000234,
                            43.6871459998611
                        ],
                        [
                            -124.265705000295,
                            43.7015580001621
                        ],
                        [
                            -124.243659000241,
                            43.7916639997835
                        ],
                        [
                            -124.230231000442,
                            43.8633599998958
                        ],
                        [
                            -124.207893000311,
                            44.0021329998784
                        ],
                        [
                            -124.212541000372,
                            44.0141220000361
                        ],
                        [
                            -124.212721000297,
                            44.0245670001358
                        ],
                        [
                            -124.205171999722,
                            44.0406359999008
                        ],
                        [
                            -124.195376999833,
                            44.0974619999835
                        ],
                        [
                            -124.199320000262,
                            44.1112539997634
                        ],
                        [
                            -124.199173999774,
                            44.1271999996997
                        ],
                        [
                            -124.200983999968,
                            44.139523999965
                        ],
                        [
                            -124.195546999712,
                            44.1548290000516
                        ],
                        [
                            -124.189008000164,
                            44.1642229997297
                        ],
                        [
                            -124.186756000103,
                            44.1764809999741
                        ],
                        [
                            -124.188234999862,
                            44.191527999952
                        ],
                        [
                            -124.185965000258,
                            44.1990020001605
                        ],
                        [
                            -124.185591999989,
                            44.2215989999272
                        ],
                        [
                            -124.18283199994,
                            44.2309519999222
                        ],
                        [
                            -124.186792999913,
                            44.2446580002771
                        ],
                        [
                            -124.184317000445,
                            44.2579839996817
                        ],
                        [
                            -124.188112999663,
                            44.2709729998502
                        ],
                        [
                            -124.186517999554,
                            44.2843560002456
                        ],
                        [
                            -124.187649000056,
                            44.2885589998512
                        ],
                        [
                            -124.180580999705,
                            44.3077720001514
                        ],
                        [
                            -124.181651999933,
                            44.3168040000817
                        ],
                        [
                            -124.163519999996,
                            44.3748409998814
                        ],
                        [
                            -124.158588000253,
                            44.4060799999435
                        ],
                        [
                            -124.153428000006,
                            44.4650360001314
                        ],
                        [
                            -124.163914000181,
                            44.4884050000704
                        ],
                        [
                            -124.164556999889,
                            44.4998399997644
                        ],
                        [
                            -124.159672000001,
                            44.5234100000722
                        ],
                        [
                            -124.155530000278,
                            44.5310239999572
                        ],
                        [
                            -124.146087999668,
                            44.540220000149
                        ],
                        [
                            -124.142662000346,
                            44.5632689997606
                        ],
                        [
                            -124.138011999737,
                            44.5776419998096
                        ],
                        [
                            -124.134312999705,
                            44.5831820000488
                        ],
                        [
                            -124.14839300033,
                            44.598345000178
                        ],
                        [
                            -124.151221000151,
                            44.6029109999119
                        ],
                        [
                            -124.152328000364,
                            44.609342999687
                        ],
                        [
                            -124.149077999871,
                            44.6248379999723
                        ],
                        [
                            -124.137440000175,
                            44.6410020002601
                        ],
                        [
                            -124.147849000357,
                            44.6548889998575
                        ],
                        [
                            -124.152005000324,
                            44.6632029999279
                        ],
                        [
                            -124.153961999933,
                            44.6753320001614
                        ],
                        [
                            -124.151041000226,
                            44.6903230001377
                        ],
                        [
                            -124.144408000068,
                            44.6993179998265
                        ],
                        [
                            -124.139118000125,
                            44.7105359997244
                        ],
                        [
                            -124.140369000278,
                            44.7176250003178
                        ],
                        [
                            -124.146792000373,
                            44.73082000003
                        ],
                        [
                            -124.148302999919,
                            44.7403950000538
                        ],
                        [
                            -124.145306999869,
                            44.7575139999193
                        ],
                        [
                            -124.147757000296,
                            44.7735730001786
                        ],
                        [
                            -124.143715000132,
                            44.8032219997695
                        ],
                        [
                            -124.139553000318,
                            44.8115779997906
                        ],
                        [
                            -124.141562999631,
                            44.8334390002645
                        ],
                        [
                            -124.139020000216,
                            44.8441729999284
                        ],
                        [
                            -124.136209000113,
                            44.8492410000142
                        ],
                        [
                            -124.13042600025,
                            44.8557200000029
                        ],
                        [
                            -124.116574000129,
                            44.8663350001571
                        ],
                        [
                            -124.097931999656,
                            44.9296570001268
                        ],
                        [
                            -124.099505000024,
                            44.9369009998692
                        ],
                        [
                            -124.098482000375,
                            44.9454240001095
                        ],
                        [
                            -124.090627000377,
                            44.9659069999888
                        ],
                        [
                            -124.089439999798,
                            44.989327999696
                        ],
                        [
                            -124.086205000272,
                            45.002863999709
                        ],
                        [
                            -124.092214999915,
                            45.011407999738
                        ],
                        [
                            -124.097474999722,
                            45.0273579998546
                        ],
                        [
                            -124.096724000059,
                            45.0404659997726
                        ],
                        [
                            -124.093560999604,
                            45.0541660000857
                        ],
                        [
                            -124.081424999966,
                            45.0854570000775
                        ],
                        [
                            -124.073333999966,
                            45.0995920001781
                        ],
                        [
                            -124.065244999616,
                            45.1076679999701
                        ],
                        [
                            -124.055807999927,
                            45.1248260000621
                        ],
                        [
                            -124.050131000369,
                            45.1406880003129
                        ],
                        [
                            -124.044792000022,
                            45.165999000173
                        ],
                        [
                            -124.045079999902,
                            45.1797950001449
                        ],
                        [
                            -124.051129999729,
                            45.18711300015
                        ],
                        [
                            -124.057042000362,
                            45.1988399998003
                        ],
                        [
                            -124.059486999868,
                            45.2066549997205
                        ],
                        [
                            -124.059826999626,
                            45.2155990001689
                        ],
                        [
                            -124.054837000157,
                            45.2302039997878
                        ],
                        [
                            -124.043321999586,
                            45.2451740001953
                        ],
                        [
                            -124.040256999939,
                            45.2688280001923
                        ],
                        [
                            -124.042355999839,
                            45.2821760001977
                        ],
                        [
                            -124.046551999988,
                            45.2931679999339
                        ],
                        [
                            -124.061240000161,
                            45.3041600001241
                        ],
                        [
                            -124.073829000434,
                            45.3175090000098
                        ],
                        [
                            -124.076976999922,
                            45.3300710000469
                        ],
                        [
                            -124.076976999922,
                            45.3418460002087
                        ],
                        [
                            -124.072781999597,
                            45.3559809997722
                        ],
                        [
                            -124.066488000271,
                            45.3669729997371
                        ],
                        [
                            -124.058163999745,
                            45.3764139997701
                        ],
                        [
                            -124.043308000241,
                            45.3840610001247
                        ],
                        [
                            -124.036497999633,
                            45.4133359999687
                        ],
                        [
                            -124.03581899994,
                            45.4220510000315
                        ],
                        [
                            -124.043459999678,
                            45.4266920001314
                        ],
                        [
                            -124.053160000031,
                            45.4357439998953
                        ],
                        [
                            -124.058948999742,
                            45.4420080001116
                        ],
                        [
                            -124.063953000354,
                            45.4515119997311
                        ],
                        [
                            -124.064549000205,
                            45.4675669997496
                        ],
                        [
                            -124.059367000217,
                            45.4812149997031
                        ],
                        [
                            -124.054780000255,
                            45.4886999998403
                        ],
                        [
                            -124.056433999916,
                            45.5021459998067
                        ],
                        [
                            -124.053114999826,
                            45.5142249999048
                        ],
                        [
                            -124.039103999695,
                            45.5302429999822
                        ],
                        [
                            -124.026660000086,
                            45.5378770002439
                        ],
                        [
                            -124.026926000224,
                            45.5460129998156
                        ],
                        [
                            -124.036229999844,
                            45.5630319999868
                        ],
                        [
                            -124.037111999926,
                            45.5717240001691
                        ],
                        [
                            -124.035537999733,
                            45.5807219997118
                        ],
                        [
                            -124.030366999615,
                            45.5924920001571
                        ],
                        [
                            -124.03107299997,
                            45.6019230002388
                        ],
                        [
                            -124.029323999875,
                            45.6124609999292
                        ],
                        [
                            -124.02133900018,
                            45.6248249998182
                        ],
                        [
                            -124.019071000226,
                            45.6260339997227
                        ],
                        [
                            -124.016647999563,
                            45.6321189999867
                        ],
                        [
                            -124.015867999588,
                            45.6609640002035
                        ],
                        [
                            -124.011657000269,
                            45.6767170002722
                        ],
                        [
                            -124.010805000324,
                            45.7082849998874
                        ],
                        [
                            -124.019116000432,
                            45.7127319997903
                        ],
                        [
                            -124.024804999685,
                            45.7202329998376
                        ],
                        [
                            -124.030450000354,
                            45.7209889999277
                        ],
                        [
                            -124.043798999612,
                            45.7273070000431
                        ],
                        [
                            -124.060015999772,
                            45.739540999883
                        ],
                        [
                            -124.067967999855,
                            45.7503150000619
                        ],
                        [
                            -124.070394999818,
                            45.7627399997944
                        ],
                        [
                            -124.065448000006,
                            45.7830539997317
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 43.9717125,
            "name": "Oregon",
            "intptlon": -120.6229578,
            "geo_point_2d": [
                43.9403606958,
                -120.605143691
            ],
            "geoid": "41",
            "mtfcc": "G4000",
            "region": 4
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -120.605143691,
                43.9403606958
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "c3d44d4bd823ebaf3c14623acdebb7a23228d786",
        "fields": {
            "arealand": "1793942590",
            "objectid": 39,
            "basename": "New York",
            "stusab": "NY",
            "statens": "01779796",
            "centlon": -75.5942811,
            "state": "36",
            "gid": 26,
            "centlat": 42.9197932,
            "division": 2,
            "areawater": "2063248491",
            "oid": "-1984827836",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                -79.312135999579,
                                42.6868050002553
                            ],
                            [
                                -78.9997600000824,
                                42.8043679999167
                            ],
                            [
                                -78.9305589999242,
                                42.8501050000145
                            ],
                            [
                                -78.9057579998449,
                                42.8999570001239
                            ],
                            [
                                -78.9056590001106,
                                42.9233569999487
                            ],
                            [
                                -78.9091589999509,
                                42.9332569999776
                            ],
                            [
                                -78.918859000304,
                                42.9468569997201
                            ],
                            [
                                -78.932359999898,
                                42.955856999723
                            ],
                            [
                                -78.9617610003578,
                                42.957755999789
                            ],
                            [
                                -78.9750619999352,
                                42.9687560002358
                            ],
                            [
                                -79.0115629996348,
                                42.9852559999945
                            ],
                            [
                                -79.0199640001544,
                                42.9947559997831
                            ],
                            [
                                -79.0232559995823,
                                43.0163560001546
                            ],
                            [
                                -79.0117469997578,
                                43.0289560002766
                            ],
                            [
                                -79.0051389997143,
                                43.0470560002558
                            ],
                            [
                                -78.9994350003924,
                                43.0560570001435
                            ],
                            [
                                -79.0071299996589,
                                43.0657570002811
                            ],
                            [
                                -79.0744589996983,
                                43.0778630002242
                            ],
                            [
                                -79.0753669997195,
                                43.0813550003009
                            ],
                            [
                                -79.0659609999926,
                                43.0910519998309
                            ],
                            [
                                -79.0579610002292,
                                43.1069570003102
                            ],
                            [
                                -79.0601999998709,
                                43.1136039998929
                            ],
                            [
                                -79.0695560002674,
                                43.1202549999417
                            ],
                            [
                                -79.0567669999778,
                                43.1268550002501
                            ],
                            [
                                -79.0494600001505,
                                43.1350500000428
                            ],
                            [
                                -79.0440570002291,
                                43.1380489999048
                            ],
                            [
                                -79.0423569996394,
                                43.143653999981
                            ],
                            [
                                -79.0465379997202,
                                43.1623200000857
                            ],
                            [
                                -79.0529249998307,
                                43.1734600002809
                            ],
                            [
                                -79.0525669996304,
                                43.1836550001395
                            ],
                            [
                                -79.0486580004345,
                                43.1996550001538
                            ],
                            [
                                -79.0570580002309,
                                43.2106539996761
                            ],
                            [
                                -79.0528579998835,
                                43.2220550001524
                            ],
                            [
                                -79.0558580001317,
                                43.2385540002464
                            ],
                            [
                                -79.056059999798,
                                43.2541560000392
                            ],
                            [
                                -79.0704519996948,
                                43.2624550001368
                            ],
                            [
                                -79.2009360002637,
                                43.450422000325
                            ],
                            [
                                -79.0637189999778,
                                43.5000199998864
                            ],
                            [
                                -78.7497609998923,
                                43.609257999727
                            ],
                            [
                                -78.6882950000818,
                                43.6347419998841
                            ],
                            [
                                -78.1247420000371,
                                43.6311750001096
                            ],
                            [
                                -77.4997249998317,
                                43.6320839998767
                            ],
                            [
                                -77.1247100001896,
                                43.63111799987
                            ],
                            [
                                -76.9997340000223,
                                43.6292040001022
                            ],
                            [
                                -76.7762330001282,
                                43.6295290000401
                            ],
                            [
                                -76.7535379996211,
                                43.6842749997726
                            ],
                            [
                                -76.4989270002533,
                                44.0000589999149
                            ],
                            [
                                -76.4382870004482,
                                44.0941720002747
                            ],
                            [
                                -76.3526790001592,
                                44.1347430001002
                            ],
                            [
                                -76.3126390002613,
                                44.1990450002501
                            ],
                            [
                                -76.2865460000212,
                                44.2037670000753
                            ],
                            [
                                -76.245486999774,
                                44.2036640001109
                            ],
                            [
                                -76.2067740001969,
                                44.2145380002549
                            ],
                            [
                                -76.1913239995929,
                                44.2212390001427
                            ],
                            [
                                -76.1642579999325,
                                44.2396029999197
                            ],
                            [
                                -76.1618250001221,
                                44.2807759999059
                            ],
                            [
                                -76.1308839998044,
                                44.2966350002085
                            ],
                            [
                                -76.1291980003566,
                                44.2951700003075
                            ],
                            [
                                -76.1181360004209,
                                44.2948500000364
                            ],
                            [
                                -76.1119309998853,
                                44.2980310000046
                            ],
                            [
                                -76.0973509996676,
                                44.2995410000015
                            ],
                            [
                                -76.0452239996368,
                                44.3317200002347
                            ],
                            [
                                -76.0009979996353,
                                44.3475340000403
                            ],
                            [
                                -75.9782760002623,
                                44.3468729999971
                            ],
                            [
                                -75.9701779996914,
                                44.3428280000436
                            ],
                            [
                                -75.9495400001494,
                                44.3491289999729
                            ],
                            [
                                -75.9294649999489,
                                44.3596029998298
                            ],
                            [
                                -75.9217189997305,
                                44.3688859997113
                            ],
                            [
                                -75.9129850000232,
                                44.3680840001292
                            ],
                            [
                                -75.8714959996057,
                                44.3948389998192
                            ],
                            [
                                -75.8208189999195,
                                44.4322390000466
                            ],
                            [
                                -75.8077650002143,
                                44.4716410001073
                            ],
                            [
                                -75.7662259995681,
                                44.5158470002884
                            ],
                            [
                                -75.7270519998585,
                                44.5428119997136
                            ],
                            [
                                -75.6965830004423,
                                44.5675809999003
                            ],
                            [
                                -75.6623730002629,
                                44.5919270001711
                            ],
                            [
                                -75.6183639999963,
                                44.6196370000741
                            ],
                            [
                                -75.5059019997591,
                                44.7050810002794
                            ],
                            [
                                -75.4776410000224,
                                44.7202240001263
                            ],
                            [
                                -75.4239380001488,
                                44.7563269997757
                            ],
                            [
                                -75.4137919997319,
                                44.7725339996961
                            ],
                            [
                                -75.3970080000326,
                                44.773471000267
                            ],
                            [
                                -75.387367000129,
                                44.780026000163
                            ],
                            [
                                -75.3699530002429,
                                44.7828829998479
                            ],
                            [
                                -75.3698290003943,
                                44.7880649997498
                            ],
                            [
                                -75.3445379998702,
                                44.8091389999474
                            ],
                            [
                                -75.3337429998982,
                                44.8063750002757
                            ],
                            [
                                -75.301975999575,
                                44.8266369999435
                            ],
                            [
                                -75.307621000244,
                                44.8368099997026
                            ],
                            [
                                -75.2831280001362,
                                44.8491479997757
                            ],
                            [
                                -75.2555159999546,
                                44.8576549997232
                            ],
                            [
                                -75.2412939999372,
                                44.8669479997241
                            ],
                            [
                                -75.2286379997169,
                                44.8678910002531
                            ],
                            [
                                -75.2185759998639,
                                44.8775690000746
                            ],
                            [
                                -75.2030120003554,
                                44.8775479999333
                            ],
                            [
                                -75.1940719998855,
                                44.8826249999264
                            ],
                            [
                                -75.188277000327,
                                44.8832120000831
                            ],
                            [
                                -75.1651230002356,
                                44.8933240000469
                            ],
                            [
                                -75.1398660000466,
                                44.8966839997099
                            ],
                            [
                                -75.1344160002696,
                                44.9151019998727
                            ],
                            [
                                -75.1175749997706,
                                44.9213420000655
                            ],
                            [
                                -75.1050859999549,
                                44.91954100013
                            ],
                            [
                                -75.0966590003943,
                                44.9270669997371
                            ],
                            [
                                -75.064825999949,
                                44.929448999845
                            ],
                            [
                                -75.0599660001758,
                                44.934570000079
                            ],
                            [
                                -75.0271250001501,
                                44.946568000275
                            ],
                            [
                                -75.0051510000665,
                                44.9584060002646
                            ],
                            [
                                -74.9992579997004,
                                44.9661110000584
                            ],
                            [
                                -74.9992700002943,
                                44.9716379998297
                            ],
                            [
                                -74.9927269996499,
                                44.9774689998999
                            ],
                            [
                                -74.9724179999959,
                                44.983414000174
                            ],
                            [
                                -74.9475059995884,
                                44.9845829998612
                            ],
                            [
                                -74.9079479997393,
                                44.9833690000237
                            ],
                            [
                                -74.9007249995776,
                                44.992761000114
                            ],
                            [
                                -74.8878369995538,
                                45.0000460002952
                            ],
                            [
                                -74.8772319995525,
                                45.0013620002194
                            ],
                            [
                                -74.8666399999701,
                                45.0005359998933
                            ],
                            [
                                -74.8626430000123,
                                45.0045909997462
                            ],
                            [
                                -74.8564219995832,
                                45.0045239998597
                            ],
                            [
                                -74.8346740003537,
                                45.0146979997196
                            ],
                            [
                                -74.8265759997827,
                                45.0158649998094
                            ],
                            [
                                -74.8171950001702,
                                45.011728000214
                            ],
                            [
                                -74.8132629996114,
                                45.013542999974
                            ],
                            [
                                -74.8016380003343,
                                45.014568999711
                            ],
                            [
                                -74.7998850000411,
                                45.0107069997839
                            ],
                            [
                                -74.7931489999511,
                                45.0046449998727
                            ],
                            [
                                -74.7634069999078,
                                45.0056130003034
                            ],
                            [
                                -74.7602169996889,
                                44.9949449999873
                            ],
                            [
                                -74.7446399997615,
                                44.9905619999448
                            ],
                            [
                                -74.731300999651,
                                44.9904189998674
                            ],
                            [
                                -74.7225729997915,
                                44.9980640002285
                            ],
                            [
                                -74.7020189999151,
                                45.0033220002232
                            ],
                            [
                                -74.6869320004325,
                                45.0000980000028
                            ],
                            [
                                -74.6784020000415,
                                45.0000479999184
                            ],
                            [
                                -74.6730469998007,
                                45.0009430002287
                            ],
                            [
                                -74.6702969997979,
                                45.0061939997647
                            ],
                            [
                                -74.661479000425,
                                44.9995930000035
                            ],
                            [
                                -74.4575299997764,
                                44.9970330002598
                            ],
                            [
                                -74.3351839999614,
                                44.9919049998067
                            ],
                            [
                                -74.1502349999859,
                                44.9913569997167
                            ],
                            [
                                -73.8745970000153,
                                45.001223000212
                            ],
                            [
                                -73.639717999818,
                                45.0034640001106
                            ],
                            [
                                -73.3431240002882,
                                45.0108399999989
                            ],
                            [
                                -73.3546330001126,
                                44.9873520001485
                            ],
                            [
                                -73.3502179996801,
                                44.9762220001631
                            ],
                            [
                                -73.3382440004236,
                                44.9647510000861
                            ],
                            [
                                -73.3396029996331,
                                44.9433709997122
                            ],
                            [
                                -73.3389790001924,
                                44.9176809999156
                            ],
                            [
                                -73.3411059996811,
                                44.914631999774
                            ],
                            [
                                -73.3506520000484,
                                44.9096339998912
                            ],
                            [
                                -73.3562180001764,
                                44.9044919998408
                            ],
                            [
                                -73.360328000112,
                                44.8972360000828
                            ],
                            [
                                -73.3691039996521,
                                44.8666809998313
                            ],
                            [
                                -73.3719680003561,
                                44.86241499983
                            ],
                            [
                                -73.3798219996312,
                                44.8570380002391
                            ],
                            [
                                -73.3795449996218,
                                44.8516509999239
                            ],
                            [
                                -73.381589999993,
                                44.8493539998212
                            ],
                            [
                                -73.3803210002968,
                                44.8471729998786
                            ],
                            [
                                -73.3813360004481,
                                44.844683000293
                            ],
                            [
                                -73.3794469997125,
                                44.843461000098
                            ],
                            [
                                -73.3794519997354,
                                44.8380100001916
                            ],
                            [
                                -73.3753459999976,
                                44.8363069998952
                            ],
                            [
                                -73.3682750001719,
                                44.828007000089
                            ],
                            [
                                -73.3534720003715,
                                44.8203859997252
                            ],
                            [
                                -73.3350440001589,
                                44.8041090001295
                            ],
                            [
                                -73.3331539995983,
                                44.7887590000772
                            ],
                            [
                                -73.3357129998055,
                                44.7820859998717
                            ],
                            [
                                -73.3470850002609,
                                44.7729800000534
                            ],
                            [
                                -73.3543610001261,
                                44.7552959997836
                            ],
                            [
                                -73.365561999979,
                                44.7417860000555
                            ],
                            [
                                -73.3659770000807,
                                44.6975589997867
                            ],
                            [
                                -73.3613080001033,
                                44.6945239998961
                            ],
                            [
                                -73.3652969996651,
                                44.6875479998151
                            ],
                            [
                                -73.3701420002679,
                                44.6848550000944
                            ],
                            [
                                -73.367208999967,
                                44.6785140000284
                            ],
                            [
                                -73.3718429997843,
                                44.6769569997252
                            ],
                            [
                                -73.3710100001062,
                                44.6727629998659
                            ],
                            [
                                -73.3727190000184,
                                44.6687390000569
                            ],
                            [
                                -73.3696679997167,
                                44.6634780001492
                            ],
                            [
                                -73.3745769998936,
                                44.6618900001269
                            ],
                            [
                                -73.3790729996187,
                                44.6567720001265
                            ],
                            [
                                -73.3783169999334,
                                44.652164999699
                            ],
                            [
                                -73.384483000111,
                                44.6466830002878
                            ],
                            [
                                -73.3785599996077,
                                44.6414760002184
                            ],
                            [
                                -73.3867829998521,
                                44.6363699999932
                            ],
                            [
                                -73.3864969996218,
                                44.6269250003094
                            ],
                            [
                                -73.3902309998139,
                                44.6183539997159
                            ],
                            [
                                -73.3829320003825,
                                44.6121839997604
                            ],
                            [
                                -73.3807249996295,
                                44.6052400000214
                            ],
                            [
                                -73.3768490000456,
                                44.599598999864
                            ],
                            [
                                -73.3768060003879,
                                44.5954560001715
                            ],
                            [
                                -73.3818489995608,
                                44.589316000005
                            ],
                            [
                                -73.375667000388,
                                44.5820380000689
                            ],
                            [
                                -73.3749500001625,
                                44.575816999964
                            ],
                            [
                                -73.3567889999137,
                                44.5579190000975
                            ],
                            [
                                -73.3440010003472,
                                44.5525089998563
                            ],
                            [
                                -73.3387520004117,
                                44.5480460002115
                            ],
                            [
                                -73.3390059999566,
                                44.5433009999509
                            ],
                            [
                                -73.3316079998927,
                                44.5359199999644
                            ],
                            [
                                -73.3297459998195,
                                44.5295650002126
                            ],
                            [
                                -73.3220389999592,
                                44.5252859999063
                            ],
                            [
                                -73.3208460004309,
                                44.5136299999069
                            ],
                            [
                                -73.306713000102,
                                44.5003349997885
                            ],
                            [
                                -73.3044290002544,
                                44.4857390001303
                            ],
                            [
                                -73.2991610000523,
                                44.4729099997527
                            ],
                            [
                                -73.30012500015,
                                44.4547109999022
                            ],
                            [
                                -73.2952250001939,
                                44.4458829998479
                            ],
                            [
                                -73.2936269997114,
                                44.4389030001971
                            ],
                            [
                                -73.2960520000242,
                                44.4283340000149
                            ],
                            [
                                -73.309598999649,
                                44.4045050002349
                            ],
                            [
                                -73.3150400001034,
                                44.3885260002459
                            ],
                            [
                                -73.333613000081,
                                44.3722999999638
                            ],
                            [
                                -73.3349500004476,
                                44.3644400001339
                            ],
                            [
                                -73.3346480003238,
                                44.3568790000231
                            ],
                            [
                                -73.327353000192,
                                44.3443600001457
                            ],
                            [
                                -73.3240209996829,
                                44.33383500018
                            ],
                            [
                                -73.3241949997602,
                                44.3100330001025
                            ],
                            [
                                -73.3201240001826,
                                44.2959089997128
                            ],
                            [
                                -73.3122099997346,
                                44.2800689996869
                            ],
                            [
                                -73.3109140002743,
                                44.2742979997269
                            ],
                            [
                                -73.3127410001873,
                                44.2654109997532
                            ],
                            [
                                -73.3196029997757,
                                44.2499090000554
                            ],
                            [
                                -73.323066999631,
                                44.2478960000465
                            ],
                            [
                                -73.3237150002595,
                                44.244159999845
                            ],
                            [
                                -73.3303750001815,
                                44.2443119998811
                            ],
                            [
                                -73.3364030002668,
                                44.2397810000092
                            ],
                            [
                                -73.3433760001832,
                                44.2381900000811
                            ],
                            [
                                -73.3428349996848,
                                44.2344459996885
                            ],
                            [
                                -73.350478999796,
                                44.2298929997056
                            ],
                            [
                                -73.3513790003196,
                                44.2259799997602
                            ],
                            [
                                -73.3553770001023,
                                44.2231570000069
                            ],
                            [
                                -73.3562240000241,
                                44.218956000262
                            ],
                            [
                                -73.3622200003224,
                                44.2124609999997
                            ],
                            [
                                -73.3622660003531,
                                44.208518000234
                            ],
                            [
                                -73.3708650003412,
                                44.2045270002352
                            ],
                            [
                                -73.3748020000245,
                                44.2002430000895
                            ],
                            [
                                -73.3819349997745,
                                44.1977339999172
                            ],
                            [
                                -73.3845819998452,
                                44.1931929997103
                            ],
                            [
                                -73.3906249999992,
                                44.1910680000836
                            ],
                            [
                                -73.3898240001081,
                                44.1817569998518
                            ],
                            [
                                -73.3971710001183,
                                44.1738380001054
                            ],
                            [
                                -73.3958129998354,
                                44.1662659999144
                            ],
                            [
                                -73.3988569995661,
                                44.1619149999182
                            ],
                            [
                                -73.4002910000171,
                                44.1543630002558
                            ],
                            [
                                -73.4028769999884,
                                44.1504830000886
                            ],
                            [
                                -73.4030450002179,
                                44.1449839998595
                            ],
                            [
                                -73.411972000269,
                                44.1377799998965
                            ],
                            [
                                -73.4161380002812,
                                44.1323550000925
                            ],
                            [
                                -73.4120269996223,
                                44.1196310000426
                            ],
                            [
                                -73.4115119999614,
                                44.1119519999121
                            ],
                            [
                                -73.4152150001913,
                                44.1013439998535
                            ],
                            [
                                -73.4297180004161,
                                44.0785860002712
                            ],
                            [
                                -73.4311219998316,
                                44.0677660001159
                            ],
                            [
                                -73.4379049997772,
                                44.0451249998152
                            ],
                            [
                                -73.4367289999674,
                                44.0426330002555
                            ],
                            [
                                -73.4228819998687,
                                44.0325479998618
                            ],
                            [
                                -73.4125120000442,
                                44.0283460001078
                            ],
                            [
                                -73.4076449997,
                                44.021110999851
                            ],
                            [
                                -73.4060089995827,
                                44.0114170000561
                            ],
                            [
                                -73.4125100003943,
                                43.9822160000184
                            ],
                            [
                                -73.412048000437,
                                43.9776039998826
                            ],
                            [
                                -73.4070689999398,
                                43.9681719999391
                            ],
                            [
                                -73.4057359997712,
                                43.9559319998957
                            ],
                            [
                                -73.4056460002578,
                                43.9480689997177
                            ],
                            [
                                -73.4085540004444,
                                43.9318940000691
                            ],
                            [
                                -73.3961790004316,
                                43.9032640002188
                            ],
                            [
                                -73.3836939999155,
                                43.8912839998241
                            ],
                            [
                                -73.3741619997919,
                                43.8762409998069
                            ],
                            [
                                -73.3823089998683,
                                43.8560579997472
                            ],
                            [
                                -73.3802490003268,
                                43.8519230002051
                            ],
                            [
                                -73.3725890003221,
                                43.845353999756
                            ],
                            [
                                -73.3766630002728,
                                43.839434000084
                            ],
                            [
                                -73.3881920001887,
                                43.8326549998341
                            ],
                            [
                                -73.3925170002095,
                                43.8213400002634
                            ],
                            [
                                -73.389564999642,
                                43.8170400001332
                            ],
                            [
                                -73.3807220001548,
                                43.8108190000372
                            ],
                            [
                                -73.3767929999691,
                                43.799157999853
                            ],
                            [
                                -73.3581539998693,
                                43.7864749999809
                            ],
                            [
                                -73.3553620000337,
                                43.7779270001341
                            ],
                            [
                                -73.3507619996532,
                                43.7713869999626
                            ],
                            [
                                -73.3699439999012,
                                43.7438549998807
                            ],
                            [
                                -73.3706119997229,
                                43.7253290001587
                            ],
                            [
                                -73.3858830002268,
                                43.7113370000024
                            ],
                            [
                                -73.3955169995593,
                                43.6968309997114
                            ],
                            [
                                -73.4047390000616,
                                43.6902139999514
                            ],
                            [
                                -73.4041270003164,
                                43.6813399999708
                            ],
                            [
                                -73.4080620003498,
                                43.6694379997329
                            ],
                            [
                                -73.4145459996464,
                                43.6582089998516
                            ],
                            [
                                -73.4155130001172,
                                43.6524510001084
                            ],
                            [
                                -73.4185739995663,
                                43.6480509999249
                            ],
                            [
                                -73.4264629998999,
                                43.642598999684
                            ],
                            [
                                -73.4284189996844,
                                43.635452999691
                            ],
                            [
                                -73.4176689999182,
                                43.6216870000582
                            ],
                            [
                                -73.4237089996991,
                                43.6123569998244
                            ],
                            [
                                -73.4216159996473,
                                43.6030230000217
                            ],
                            [
                                -73.4312289999617,
                                43.58828500017
                            ],
                            [
                                -73.4286370001426,
                                43.583994000103
                            ],
                            [
                                -73.420383999761,
                                43.581494999718
                            ],
                            [
                                -73.4169650001115,
                                43.5777310000341
                            ],
                            [
                                -73.4039110004063,
                                43.5703310002307
                            ],
                            [
                                -73.3957679996295,
                                43.5680869998738
                            ],
                            [
                                -73.3951690003031,
                                43.5696189999687
                            ],
                            [
                                -73.3916729997624,
                                43.5701199997152
                            ],
                            [
                                -73.3907940000536,
                                43.5741830002447
                            ],
                            [
                                -73.3849350000227,
                                43.5751140003045
                            ],
                            [
                                -73.3832849996616,
                                43.5772000001245
                            ],
                            [
                                -73.3827109995513,
                                43.5791959999382
                            ],
                            [
                                -73.3852510003901,
                                43.5832629997594
                            ],
                            [
                                -73.3824650004023,
                                43.586577999871
                            ],
                            [
                                -73.3862530001227,
                                43.5884109999489
                            ],
                            [
                                -73.3869009998529,
                                43.5907700000667
                            ],
                            [
                                -73.3821380001641,
                                43.5918239997619
                            ],
                            [
                                -73.383489999701,
                                43.5973660002122
                            ],
                            [
                                -73.3773699995542,
                                43.5983979998231
                            ],
                            [
                                -73.377635999693,
                                43.6002280000635
                            ],
                            [
                                -73.3724689997732,
                                43.6048479998368
                            ],
                            [
                                -73.3731630004324,
                                43.6072479998217
                            ],
                            [
                                -73.3763860002633,
                                43.6088240002653
                            ],
                            [
                                -73.3742260002643,
                                43.6101970000307
                            ],
                            [
                                -73.375595000418,
                                43.6133510001536
                            ],
                            [
                                -73.3698400001441,
                                43.619392000312
                            ],
                            [
                                -73.3724870002148,
                                43.6227520001373
                            ],
                            [
                                -73.3718899996399,
                                43.6244900000551
                            ],
                            [
                                -73.3655629998039,
                                43.623440999721
                            ],
                            [
                                -73.3542699998895,
                                43.6261899997657
                            ],
                            [
                                -73.3487530001653,
                                43.6214579996864
                            ],
                            [
                                -73.3445100001603,
                                43.6262770000902
                            ],
                            [
                                -73.3400679999635,
                                43.6246469996901
                            ],
                            [
                                -73.3341870001913,
                                43.6279940002978
                            ],
                            [
                                -73.3277020001715,
                                43.6259139999207
                            ],
                            [
                                -73.3178500003808,
                                43.6284990001282
                            ],
                            [
                                -73.311172999842,
                                43.6240919998058
                            ],
                            [
                                -73.3064400002905,
                                43.6283329996915
                            ],
                            [
                                -73.3025529999376,
                                43.6257089997198
                            ],
                            [
                                -73.3029899997806,
                                43.6205239998791
                            ],
                            [
                                -73.30037799987,
                                43.6178030000616
                            ],
                            [
                                -73.3027720002207,
                                43.6097770000237
                            ],
                            [
                                -73.2980200004026,
                                43.6100280000243
                            ],
                            [
                                -73.2910189999988,
                                43.5992350000242
                            ],
                            [
                                -73.2969440001519,
                                43.5873140000647
                            ],
                            [
                                -73.2921089995948,
                                43.5845439998036
                            ],
                            [
                                -73.2973240000935,
                                43.5793729997671
                            ],
                            [
                                -73.2952920001411,
                                43.5767820000085
                            ],
                            [
                                -73.2941280000268,
                                43.5784740000185
                            ],
                            [
                                -73.2900399998324,
                                43.5776730003012
                            ],
                            [
                                -73.2849120002707,
                                43.5792720001454
                            ],
                            [
                                -73.2812960000795,
                                43.5775790001891
                            ],
                            [
                                -73.2808580004115,
                                43.5735780001161
                            ],
                            [
                                -73.2778070001098,
                                43.5746289999373
                            ],
                            [
                                -73.2679319998545,
                                43.5722499997432
                            ],
                            [
                                -73.2674580002016,
                                43.5708730001902
                            ],
                            [
                                -73.2689880000137,
                                43.5699290000331
                            ],
                            [
                                -73.2641630004007,
                                43.5691359997246
                            ],
                            [
                                -73.2620779998465,
                                43.5657009998309
                            ],
                            [
                                -73.2607389998301,
                                43.5665349997769
                            ],
                            [
                                -73.2586309997096,
                                43.5649500000448
                            ],
                            [
                                -73.2526019997994,
                                43.5568520001165
                            ],
                            [
                                -73.2544899998118,
                                43.555595999743
                            ],
                            [
                                -73.2486409998266,
                                43.5538580001629
                            ],
                            [
                                -73.2486070003897,
                                43.5512059998239
                            ],
                            [
                                -73.2504080003635,
                                43.5504259997279
                            ],
                            [
                                -73.2498559999945,
                                43.546126999708
                            ],
                            [
                                -73.2478149998212,
                                43.545054999856
                            ],
                            [
                                -73.2499360003604,
                                43.5448690001749
                            ],
                            [
                                -73.2501330000039,
                                43.543429000195
                            ],
                            [
                                -73.2465860003079,
                                43.5418550002505
                            ],
                            [
                                -73.2435309998082,
                                43.5354079999879
                            ],
                            [
                                -73.2415790002217,
                                43.5349599997734
                            ],
                            [
                                -73.2427440001609,
                                43.5339910001413
                            ],
                            [
                                -73.2413909999007,
                                43.5323459996902
                            ],
                            [
                                -73.2418920002162,
                                43.5294189999847
                            ],
                            [
                                -73.2468209995863,
                                43.5257810001889
                            ],
                            [
                                -73.2476979996453,
                                43.5231739998121
                            ],
                            [
                                -73.246501999744,
                                43.5214599997233
                            ],
                            [
                                -73.2480680004395,
                                43.515993999988
                            ],
                            [
                                -73.2467160000043,
                                43.515332000096
                            ],
                            [
                                -73.2564749999086,
                                43.2600930000494
                            ],
                            [
                                -73.2697770002092,
                                43.0359759999116
                            ],
                            [
                                -73.2783059998771,
                                42.8375580000233
                            ],
                            [
                                -73.2786730002982,
                                42.8334099998131
                            ],
                            [
                                -73.2853879995735,
                                42.8340930000216
                            ],
                            [
                                -73.2870630000489,
                                42.8201399996984
                            ],
                            [
                                -73.2836949995547,
                                42.8138560000015
                            ],
                            [
                                -73.2909439996557,
                                42.8019200002254
                            ],
                            [
                                -73.2764210002377,
                                42.7460189999895
                            ],
                            [
                                -73.2648270000244,
                                42.7458509997267
                            ],
                            [
                                -73.5082099997752,
                                42.0861339997249
                            ],
                            [
                                -73.4970180001432,
                                42.0496240001605
                            ],
                            [
                                -73.487318999615,
                                42.0495250000787
                            ],
                            [
                                -73.4896150000564,
                                42.0000919999094
                            ],
                            [
                                -73.510960999603,
                                41.7587490000802
                            ],
                            [
                                -73.5210409998977,
                                41.6197730001553
                            ],
                            [
                                -73.5509620000412,
                                41.2954209999985
                            ],
                            [
                                -73.4826949998434,
                                41.2127720002509
                            ],
                            [
                                -73.7277749998109,
                                41.1006960002942
                            ],
                            [
                                -73.6552549995624,
                                41.0122460000961
                            ],
                            [
                                -73.6602680003947,
                                41.0004840000058
                            ],
                            [
                                -73.6595849996061,
                                40.9941399999662
                            ],
                            [
                                -73.6572279999636,
                                40.9909140001282
                            ],
                            [
                                -73.6596709998198,
                                40.9879090003188
                            ],
                            [
                                -73.6128849997861,
                                40.9509429998257
                            ],
                            [
                                -73.2495570002437,
                                41.0258660000926
                            ],
                            [
                                -72.9995470001829,
                                41.0871079999568
                            ],
                            [
                                -72.0818470001918,
                                41.2622240001306
                            ],
                            [
                                -72.019213999894,
                                41.2905940000935
                            ],
                            [
                                -71.9294510003618,
                                41.3103869999966
                            ],
                            [
                                -71.9072579999951,
                                41.3044830002895
                            ],
                            [
                                -71.7909720000524,
                                41.1841010001959
                            ],
                            [
                                -71.7906349997686,
                                41.1018369997934
                            ],
                            [
                                -71.781871999749,
                                41.09619699967
                            ],
                            [
                                -71.7774909996517,
                                41.0771619996963
                            ],
                            [
                                -71.7774909996517,
                                41.0672920002573
                            ],
                            [
                                -71.7827479999831,
                                41.0553070003269
                            ],
                            [
                                -71.7941389998068,
                                41.0369770002384
                            ],
                            [
                                -71.8370879997163,
                                41.0108919997968
                            ],
                            [
                                -71.8701389998042,
                                40.9969709999698
                            ],
                            [
                                -71.8847750000984,
                                40.9922690001346
                            ],
                            [
                                -71.8999069997869,
                                40.9904960002087
                            ],
                            [
                                -71.9316980003994,
                                40.9824969997878
                            ],
                            [
                                -71.9995160001603,
                                40.9560190002632
                            ],
                            [
                                -72.2495250003963,
                                40.8667650001615
                            ],
                            [
                                -72.3745290001526,
                                40.8185950000974
                            ],
                            [
                                -72.6245390002135,
                                40.7455669997404
                            ],
                            [
                                -72.7495439997947,
                                40.713405000222
                            ],
                            [
                                -72.8286069997894,
                                40.6869009999204
                            ],
                            [
                                -72.8745419997033,
                                40.6750189997962
                            ],
                            [
                                -73.0140040003769,
                                40.6250990002892
                            ],
                            [
                                -73.0640929997091,
                                40.6116669998301
                            ],
                            [
                                -73.124557999612,
                                40.5983659998874
                            ],
                            [
                                -73.2496419997342,
                                40.5625649997978
                            ],
                            [
                                -73.2740250002372,
                                40.5513140002944
                            ],
                            [
                                -73.2914409997731,
                                40.5513140002944
                            ],
                            [
                                -73.3262719999185,
                                40.5569160002543
                            ],
                            [
                                -73.3785189995998,
                                40.5457129999565
                            ],
                            [
                                -73.4063840003997,
                                40.5373100002271
                            ],
                            [
                                -73.4412149996467,
                                40.5317089997232
                            ],
                            [
                                -73.4864959999826,
                                40.5205050000313
                            ],
                            [
                                -73.5178440000061,
                                40.5233059997082
                            ],
                            [
                                -73.5526740003265,
                                40.5289089998578
                            ],
                            [
                                -73.624574000434,
                                40.5345109997499
                            ],
                            [
                                -73.7687810003547,
                                40.5337469996712
                            ],
                            [
                                -73.7839690001196,
                                40.5323870001347
                            ],
                            [
                                -73.8261580001459,
                                40.5228739999836
                            ],
                            [
                                -73.8548430002051,
                                40.511999999856
                            ],
                            [
                                -73.8700290003202,
                                40.5024859997238
                            ],
                            [
                                -73.8767779999307,
                                40.5011269998027
                            ],
                            [
                                -73.886652000361,
                                40.4897939997489
                            ],
                            [
                                -73.9499119995743,
                                40.5255400000137
                            ],
                            [
                                -74.2281530001329,
                                40.4773989999546
                            ],
                            [
                                -74.2533130002375,
                                40.4873860002587
                            ],
                            [
                                -74.2590900002527,
                                40.4972070001451
                            ],
                            [
                                -74.2582910000114,
                                40.507905000053
                            ],
                            [
                                -74.2548100004377,
                                40.515344000085
                            ],
                            [
                                -74.2460690001594,
                                40.5209519999443
                            ],
                            [
                                -74.2506090002654,
                                40.5418509997425
                            ],
                            [
                                -74.2474149998485,
                                40.5492000001608
                            ],
                            [
                                -74.2316930001562,
                                40.5584569996986
                            ],
                            [
                                -74.2183980004266,
                                40.5569959999623
                            ],
                            [
                                -74.2152779996295,
                                40.5602410000571
                            ],
                            [
                                -74.2089680004102,
                                40.5765630003251
                            ],
                            [
                                -74.206297999875,
                                40.5885419997228
                            ],
                            [
                                -74.1995199999522,
                                40.5975390000503
                            ],
                            [
                                -74.1994079997992,
                                40.6002010002228
                            ],
                            [
                                -74.203813000186,
                                40.6059610002614
                            ],
                            [
                                -74.2018640000742,
                                40.6185570000057
                            ],
                            [
                                -74.203737000018,
                                40.6242269999222
                            ],
                            [
                                -74.2022470003889,
                                40.6309029998973
                            ],
                            [
                                -74.1856360000436,
                                40.6459949999445
                            ],
                            [
                                -74.1432550003967,
                                40.6421490001348
                            ],
                            [
                                -74.1255689996257,
                                40.6440229998283
                            ],
                            [
                                -74.1099759998048,
                                40.6480110001747
                            ],
                            [
                                -74.0937460001244,
                                40.6482390002257
                            ],
                            [
                                -74.0868059998199,
                                40.651596000196
                            ],
                            [
                                -74.0557390000039,
                                40.6517599999186
                            ],
                            [
                                -74.0262840000157,
                                40.6999019998685
                            ],
                            [
                                -74.0137840003293,
                                40.7566010002803
                            ],
                            [
                                -73.9631820000879,
                                40.8269000002793
                            ],
                            [
                                -73.9483850001352,
                                40.8584710001824
                            ],
                            [
                                -73.9298210003785,
                                40.8886820002368
                            ],
                            [
                                -73.91790499995,
                                40.9175769997719
                            ],
                            [
                                -73.8964790000374,
                                40.9817010000933
                            ],
                            [
                                -73.8939790002798,
                                40.997204999822
                            ],
                            [
                                -73.9027149996369,
                                40.9973270003253
                            ],
                            [
                                -74.1517380000338,
                                41.1081099997862
                            ],
                            [
                                -74.3306749998176,
                                41.1854569999981
                            ],
                            [
                                -74.4578620000374,
                                41.2484270001601
                            ],
                            [
                                -74.6963980004339,
                                41.3573389999427
                            ],
                            [
                                -74.6897560000553,
                                41.3615590002348
                            ],
                            [
                                -74.6911150001632,
                                41.367343000291
                            ],
                            [
                                -74.7084589997291,
                                41.3789020002422
                            ],
                            [
                                -74.7159799998166,
                                41.3925840001903
                            ],
                            [
                                -74.7336399997501,
                                41.3969749997159
                            ],
                            [
                                -74.7409630003692,
                                41.405120000063
                            ],
                            [
                                -74.7410830000198,
                                41.4114149999755
                            ],
                            [
                                -74.7386850003694,
                                41.4134629999497
                            ],
                            [
                                -74.7347319998943,
                                41.4226999998832
                            ],
                            [
                                -74.7355200002649,
                                41.4274660000841
                            ],
                            [
                                -74.7384560000406,
                                41.430642000059
                            ],
                            [
                                -74.743822000152,
                                41.430635999683
                            ],
                            [
                                -74.7585870003176,
                                41.423286999931
                            ],
                            [
                                -74.773238999607,
                                41.4263519998549
                            ],
                            [
                                -74.79041700039,
                                41.4216599997107
                            ],
                            [
                                -74.7953959999888,
                                41.4239799999254
                            ],
                            [
                                -74.7995460001074,
                                41.4312899996859
                            ],
                            [
                                -74.8012249998825,
                                41.438100000245
                            ],
                            [
                                -74.8075819998558,
                                41.4428470001613
                            ],
                            [
                                -74.8121229997867,
                                41.4429820002611
                            ],
                            [
                                -74.8179950002364,
                                41.440505000227
                            ],
                            [
                                -74.8228800001239,
                                41.4367920002204
                            ],
                            [
                                -74.8260309999847,
                                41.4317360003024
                            ],
                            [
                                -74.8306709996498,
                                41.4305029998495
                            ],
                            [
                                -74.8369149996452,
                                41.4316250001787
                            ],
                            [
                                -74.8486019997449,
                                41.4401790000222
                            ],
                            [
                                -74.8585780002825,
                                41.4444269998235
                            ],
                            [
                                -74.8801740000743,
                                41.4396479998555
                            ],
                            [
                                -74.8926250002552,
                                41.438617000229
                            ],
                            [
                                -74.8960249996381,
                                41.4399869997634
                            ],
                            [
                                -74.8963989997319,
                                41.4421790001435
                            ],
                            [
                                -74.8949309998441,
                                41.4460989996975
                            ],
                            [
                                -74.8890750001862,
                                41.4512449998024
                            ],
                            [
                                -74.8903580001261,
                                41.4553240001848
                            ],
                            [
                                -74.8950689999363,
                                41.4581899996729
                            ],
                            [
                                -74.9068869995573,
                                41.4611309998881
                            ],
                            [
                                -74.9091810003489,
                                41.4724360000124
                            ],
                            [
                                -74.9129909998106,
                                41.4759009996981
                            ],
                            [
                                -74.9240920001044,
                                41.4771380002057
                            ],
                            [
                                -74.9325849997872,
                                41.4823230001365
                            ],
                            [
                                -74.9417980000686,
                                41.4835419999716
                            ],
                            [
                                -74.9456340003678,
                                41.483212999912
                            ],
                            [
                                -74.9480799996988,
                                41.4806249999278
                            ],
                            [
                                -74.9582599995526,
                                41.4763960002052
                            ],
                            [
                                -74.9825130003592,
                                41.480318999739
                            ],
                            [
                                -74.9855949997247,
                                41.4858629999914
                            ],
                            [
                                -74.9821679996794,
                                41.498485999688
                            ],
                            [
                                -74.9843720000593,
                                41.506610999906
                            ],
                            [
                                -74.9876450001189,
                                41.5087379996684
                            ],
                            [
                                -75.0031509999011,
                                41.5081010003047
                            ],
                            [
                                -75.0037059997449,
                                41.5111180000643
                            ],
                            [
                                -75.0009110004345,
                                41.5192919999806
                            ],
                            [
                                -75.0038499996849,
                                41.5240520000848
                            ],
                            [
                                -75.0149190001917,
                                41.5313989997456
                            ],
                            [
                                -75.0230179996892,
                                41.5331470000222
                            ],
                            [
                                -75.0247569997387,
                                41.5350990000007
                            ],
                            [
                                -75.0247979997466,
                                41.539800999924
                            ],
                            [
                                -75.0161440004053,
                                41.5442460000134
                            ],
                            [
                                -75.018523999614,
                                41.5518019998077
                            ],
                            [
                                -75.02734299971,
                                41.5635410001093
                            ],
                            [
                                -75.033164999931,
                                41.5650930001573
                            ],
                            [
                                -75.0404900002,
                                41.5696879996749
                            ],
                            [
                                -75.0435809997864,
                                41.5738729999515
                            ],
                            [
                                -75.0467600001346,
                                41.5832580001113
                            ],
                            [
                                -75.0600120002066,
                                41.5908129998302
                            ],
                            [
                                -75.0669549999858,
                                41.5994280000714
                            ],
                            [
                                -75.0746130003407,
                                41.6057110002607
                            ],
                            [
                                -75.0746250000362,
                                41.607904999907
                            ],
                            [
                                -75.0716669996209,
                                41.6095010002682
                            ],
                            [
                                -75.0597250001514,
                                41.6108009996674
                            ],
                            [
                                -75.0616750000881,
                                41.6154680002272
                            ],
                            [
                                -75.0600980004203,
                                41.6174820001131
                            ],
                            [
                                -75.053850000227,
                                41.6186549997877
                            ],
                            [
                                -75.04729800026,
                                41.6157910001665
                            ],
                            [
                                -75.044224000392,
                                41.6179779997202
                            ],
                            [
                                -75.0435620004181,
                                41.6236400003161
                            ],
                            [
                                -75.0486580001928,
                                41.6337810002243
                            ],
                            [
                                -75.0492809998087,
                                41.6418619998193
                            ],
                            [
                                -75.0476830002245,
                                41.6453559998215
                            ],
                            [
                                -75.0491150001273,
                                41.6484999997046
                            ],
                            [
                                -75.0499200002163,
                                41.6625560001132
                            ],
                            [
                                -75.053990999794,
                                41.6681940000281
                            ],
                            [
                                -75.0584299996176,
                                41.6696529998032
                            ],
                            [
                                -75.059331999791,
                                41.6723199997565
                            ],
                            [
                                -75.0587650002517,
                                41.6744119999973
                            ],
                            [
                                -75.051285000172,
                                41.6799610000321
                            ],
                            [
                                -75.0527360003414,
                                41.6883930002699
                            ],
                            [
                                -75.0598289999085,
                                41.6997160001879
                            ],
                            [
                                -75.0688299995794,
                                41.7081610000812
                            ],
                            [
                                -75.0666300002958,
                                41.7125879998192
                            ],
                            [
                                -75.0522259998052,
                                41.7113960001326
                            ],
                            [
                                -75.0498619995917,
                                41.7133090002474
                            ],
                            [
                                -75.0548179996243,
                                41.735168000153
                            ],
                            [
                                -75.0528080003115,
                                41.7447249997393
                            ],
                            [
                                -75.0532269997128,
                                41.7516620000464
                            ],
                            [
                                -75.0603720000567,
                                41.7642000000334
                            ],
                            [
                                -75.064901000292,
                                41.7666859998392
                            ],
                            [
                                -75.0769969997473,
                                41.7716490002105
                            ],
                            [
                                -75.0928099996762,
                                41.7683609997711
                            ],
                            [
                                -75.0999750001116,
                                41.7689009998311
                            ],
                            [
                                -75.1034919996704,
                                41.7712379999085
                            ],
                            [
                                -75.1046399998911,
                                41.7742029999383
                            ],
                            [
                                -75.101463000091,
                                41.787940999724
                            ],
                            [
                                -75.0928759997985,
                                41.7963860002225
                            ],
                            [
                                -75.0883280001949,
                                41.797533999885
                            ],
                            [
                                -75.0814149996546,
                                41.796483000312
                            ],
                            [
                                -75.0768889997923,
                                41.7985090001293
                            ],
                            [
                                -75.0721760003322,
                                41.808318000153
                            ],
                            [
                                -75.0721720001343,
                                41.8137319999612
                            ],
                            [
                                -75.0780629999523,
                                41.8151120001141
                            ],
                            [
                                -75.0857890000792,
                                41.8116260001525
                            ],
                            [
                                -75.0894839999132,
                                41.8115760000747
                            ],
                            [
                                -75.1013020004325,
                                41.8189639997628
                            ],
                            [
                                -75.1133340003137,
                                41.8227820001263
                            ],
                            [
                                -75.115146999983,
                                41.8272850001486
                            ],
                            [
                                -75.1133689995755,
                                41.8406979998892
                            ],
                            [
                                -75.1171029997675,
                                41.8454629997124
                            ],
                            [
                                -75.1309830003765,
                                41.8451449997715
                            ],
                            [
                                -75.1409380000994,
                                41.8521729997103
                            ],
                            [
                                -75.1548289996806,
                                41.8482919996658
                            ],
                            [
                                -75.1628539995583,
                                41.8505820000376
                            ],
                            [
                                -75.1684060003409,
                                41.858146000001
                            ],
                            [
                                -75.1685019997021,
                                41.868929000083
                            ],
                            [
                                -75.1725320001701,
                                41.8722390003188
                            ],
                            [
                                -75.1776699997776,
                                41.871384000231
                            ],
                            [
                                -75.1822709999829,
                                41.8621979998726
                            ],
                            [
                                -75.1852539996143,
                                41.8599300001413
                            ],
                            [
                                -75.1888880002472,
                                41.8612640000596
                            ],
                            [
                                -75.1943820004052,
                                41.867287000321
                            ],
                            [
                                -75.2040020003923,
                                41.8698669999136
                            ],
                            [
                                -75.2149699997184,
                                41.8674490002897
                            ],
                            [
                                -75.2237339995629,
                                41.8574560000975
                            ],
                            [
                                -75.2316120000258,
                                41.8594590001755
                            ],
                            [
                                -75.2411340001036,
                                41.8671179998335
                            ],
                            [
                                -75.2511969997816,
                                41.8620400000721
                            ],
                            [
                                -75.2578249999165,
                                41.8621540002807
                            ],
                            [
                                -75.2605270002388,
                                41.8638000000638
                            ],
                            [
                                -75.2628019998655,
                                41.8662129999334
                            ],
                            [
                                -75.263815000367,
                                41.8707570001226
                            ],
                            [
                                -75.2575639998006,
                                41.8771079997557
                            ],
                            [
                                -75.2606229996,
                                41.8837830001439
                            ],
                            [
                                -75.2712929998985,
                                41.8873579997753
                            ],
                            [
                                -75.2727780004031,
                                41.8971120001574
                            ],
                            [
                                -75.2677729999667,
                                41.9019710002003
                            ],
                            [
                                -75.2675620000795,
                                41.9070539998283
                            ],
                            [
                                -75.2765519998798,
                                41.9222080001717
                            ],
                            [
                                -75.277243000166,
                                41.9335980001152
                            ],
                            [
                                -75.2790940003685,
                                41.9389170003186
                            ],
                            [
                                -75.2893830000023,
                                41.9428910002001
                            ],
                            [
                                -75.2917539998884,
                                41.9470820003118
                            ],
                            [
                                -75.2914300000234,
                                41.9524769999868
                            ],
                            [
                                -75.293713000046,
                                41.9545930002368
                            ],
                            [
                                -75.300408999953,
                                41.9538710002436
                            ],
                            [
                                -75.3012330003086,
                                41.9489000003276
                            ],
                            [
                                -75.3030099999929,
                                41.9482859997714
                            ],
                            [
                                -75.3103579998281,
                                41.9490120002179
                            ],
                            [
                                -75.3181679996206,
                                41.9542360002594
                            ],
                            [
                                -75.3200399997395,
                                41.9608669999055
                            ],
                            [
                                -75.3289810000343,
                                41.9684199999232
                            ],
                            [
                                -75.3394880001263,
                                41.9707860002182
                            ],
                            [
                                -75.3422039997939,
                                41.9728719998952
                            ],
                            [
                                -75.3376019997637,
                                41.9866999999535
                            ],
                            [
                                -75.3428979995549,
                                41.9937749997011
                            ],
                            [
                                -75.3595790002203,
                                41.9994449999737
                            ],
                            [
                                -75.7611359996412,
                                41.9977899998921
                            ],
                            [
                                -75.9802499995901,
                                41.9990349998275
                            ],
                            [
                                -76.3469499998247,
                                41.9983199998714
                            ],
                            [
                                -76.8351819995643,
                                42.0021340002624
                            ],
                            [
                                -77.1268459998991,
                                41.9994140000273
                            ],
                            [
                                -77.5053079996781,
                                42.0000699998928
                            ],
                            [
                                -77.8283960002044,
                                41.9982590002622
                            ],
                            [
                                -78.1247310001664,
                                42.000450999987
                            ],
                            [
                                -78.2712040003913,
                                41.998967999945
                            ],
                            [
                                -78.596650000385,
                                41.9998770002396
                            ],
                            [
                                -78.8747589998008,
                                41.9975589999937
                            ],
                            [
                                -79.1247680000368,
                                41.9995069998212
                            ],
                            [
                                -79.7613130003332,
                                41.9988079999798
                            ],
                            [
                                -79.7624179999979,
                                42.5160720003242
                            ],
                            [
                                -79.312135999579,
                                42.6868050002553
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -74.0465700001034,
                                40.6896390002554
                            ],
                            [
                                -74.0472489997958,
                                40.690999000282
                            ],
                            [
                                -74.0460699996129,
                                40.6911849997857
                            ],
                            [
                                -74.0435129999539,
                                40.6892739998036
                            ],
                            [
                                -74.0465700001034,
                                40.6896390002554
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -74.040690999981,
                                40.7000500002441
                            ],
                            [
                                -74.038936000038,
                                40.6996550001751
                            ],
                            [
                                -74.0383719999734,
                                40.6986689999997
                            ],
                            [
                                -74.0399310000978,
                                40.6985900000339
                            ],
                            [
                                -74.040690999981,
                                40.7000500002441
                            ]
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 42.9133974,
            "name": "New York",
            "intptlon": -75.5962723,
            "geo_point_2d": [
                42.9212247761,
                -75.5965163716
            ],
            "geoid": "36",
            "mtfcc": "G4000",
            "region": 1
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -75.5965163716,
                42.9212247761
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "84e096c28749fd3a65f3a62e99bed7ecdc4c133e",
        "fields": {
            "arealand": "-1270384539",
            "objectid": 52,
            "basename": "Massachusetts",
            "stusab": "MA",
            "statens": "00606926",
            "centlon": -71.4975238,
            "state": "25",
            "gid": 18,
            "centlat": 42.161849,
            "division": 1,
            "areawater": "-1459335740",
            "oid": "-1882034592",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -71.4982590001295,
                            42.0172719998823
                        ],
                        [
                            -71.5277020004221,
                            42.0150949999646
                        ],
                        [
                            -71.7991879997258,
                            42.0080529997619
                        ],
                        [
                            -71.8006709996823,
                            42.0235140003054
                        ],
                        [
                            -72.0718260003466,
                            42.0276369996794
                        ],
                        [
                            -72.1431509996205,
                            42.0305220000485
                        ],
                        [
                            -72.5315489997541,
                            42.0345780002288
                        ],
                        [
                            -72.5727779998806,
                            42.0302190001131
                        ],
                        [
                            -72.5821629996911,
                            42.0247409997585
                        ],
                        [
                            -72.6071689998099,
                            42.0251480001561
                        ],
                        [
                            -72.6079780000969,
                            42.0310830001198
                        ],
                        [
                            -72.6401909995856,
                            42.0320480002329
                        ],
                        [
                            -72.6993299998908,
                            42.0369569998856
                        ],
                        [
                            -72.7558940000953,
                            42.0363490002855
                        ],
                        [
                            -72.7573009998838,
                            42.0333809997234
                        ],
                        [
                            -72.7525530002637,
                            42.0312490002311
                        ],
                        [
                            -72.752061999994,
                            42.0290250003208
                        ],
                        [
                            -72.7573410000668,
                            42.0209789998813
                        ],
                        [
                            -72.7605670002707,
                            42.0226500001952
                        ],
                        [
                            -72.7634870001529,
                            42.0211839999834
                        ],
                        [
                            -72.7652520001417,
                            42.0223660000544
                        ],
                        [
                            -72.7644770001898,
                            42.0208729999502
                        ],
                        [
                            -72.7658060001605,
                            42.0195680000881
                        ],
                        [
                            -72.7629470003777,
                            42.0196829997021
                        ],
                        [
                            -72.7589500004199,
                            42.0154690003262
                        ],
                        [
                            -72.7629769996166,
                            42.0134659998403
                        ],
                        [
                            -72.7615010002312,
                            42.009302000017
                        ],
                        [
                            -72.7662530000493,
                            42.007870999957
                        ],
                        [
                            -72.7667330004483,
                            42.0032660002259
                        ],
                        [
                            -72.8170529997591,
                            41.9976850001029
                        ],
                        [
                            -72.8137839998975,
                            42.0367440002959
                        ],
                        [
                            -73.0368019999179,
                            42.0392939998041
                        ],
                        [
                            -73.4285149999438,
                            42.050476000308
                        ],
                        [
                            -73.4970180001432,
                            42.0496240001605
                        ],
                        [
                            -73.5082099997752,
                            42.0861339997249
                        ],
                        [
                            -73.2648270000244,
                            42.7458509997267
                        ],
                        [
                            -72.8091130002702,
                            42.7365809997488
                        ],
                        [
                            -71.6965050003828,
                            42.7057220002706
                        ],
                        [
                            -71.294636999719,
                            42.6970419997386
                        ],
                        [
                            -71.2789490003619,
                            42.7113500001148
                        ],
                        [
                            -71.2678730001824,
                            42.7260260000064
                        ],
                        [
                            -71.2454260002708,
                            42.7425459997377
                        ],
                        [
                            -71.2238299995806,
                            42.746483999792
                        ],
                        [
                            -71.1817860002177,
                            42.7373410001663
                        ],
                        [
                            -71.1861399996525,
                            42.790935000103
                        ],
                        [
                            -71.1666469999582,
                            42.8088810002302
                        ],
                        [
                            -71.1328050000249,
                            42.8214759998798
                        ],
                        [
                            -71.0644159996284,
                            42.8062749997989
                        ],
                        [
                            -71.054003999971,
                            42.8332849997371
                        ],
                        [
                            -71.0449199995611,
                            42.8486669999432
                        ],
                        [
                            -71.0312010004073,
                            42.8590869998415
                        ],
                        [
                            -70.9670180002059,
                            42.8688000001669
                        ],
                        [
                            -70.9295940004165,
                            42.8850319999391
                        ],
                        [
                            -70.9146309998842,
                            42.8866479999702
                        ],
                        [
                            -70.9035329999634,
                            42.8867780003161
                        ],
                        [
                            -70.8856019998684,
                            42.8828339998974
                        ],
                        [
                            -70.8477179997714,
                            42.8608669997241
                        ],
                        [
                            -70.8202379998343,
                            42.8717869998011
                        ],
                        [
                            -70.7350050003624,
                            42.8746850002148
                        ],
                        [
                            -70.7488299998215,
                            42.8588309997528
                        ],
                        [
                            -70.7455219996017,
                            42.844442999991
                        ],
                        [
                            -70.7390380003052,
                            42.8358180001912
                        ],
                        [
                            -70.7348739999428,
                            42.8244769999026
                        ],
                        [
                            -70.733654999577,
                            42.8036590000583
                        ],
                        [
                            -70.7372249997375,
                            42.7879559998839
                        ],
                        [
                            -70.7318390004329,
                            42.778356999796
                        ],
                        [
                            -70.7258480001575,
                            42.7590199998342
                        ],
                        [
                            -70.7192700002513,
                            42.7500949997928
                        ],
                        [
                            -70.7143240002644,
                            42.7319110001266
                        ],
                        [
                            -70.7025800002633,
                            42.7241619998579
                        ],
                        [
                            -70.6829300002102,
                            42.7288300001715
                        ],
                        [
                            -70.6613140003269,
                            42.7366099997525
                        ],
                        [
                            -70.6416640002738,
                            42.7397230003175
                        ],
                        [
                            -70.6259430004064,
                            42.7397239998576
                        ],
                        [
                            -70.5493059996511,
                            42.7241640000998
                        ],
                        [
                            -70.5296549997731,
                            42.7070479999741
                        ],
                        [
                            -70.5139339999057,
                            42.6805940002467
                        ],
                        [
                            -70.5021430000489,
                            42.6354710001442
                        ],
                        [
                            -70.5021430000489,
                            42.6245789997201
                        ],
                        [
                            -70.5119680000755,
                            42.6059070003281
                        ],
                        [
                            -70.521793000102,
                            42.5934590003015
                        ],
                        [
                            -70.5453739999907,
                            42.5701190000589
                        ],
                        [
                            -70.5827090000916,
                            42.5530039997101
                        ],
                        [
                            -70.6062899999802,
                            42.5312200002648
                        ],
                        [
                            -70.6903929996731,
                            42.2789989998625
                        ],
                        [
                            -70.6887039998523,
                            42.2634680000636
                        ],
                        [
                            -70.6840609998142,
                            42.2538360001762
                        ],
                        [
                            -70.6486220001216,
                            42.2373600000176
                        ],
                        [
                            -70.6401129996469,
                            42.2283719999445
                        ],
                        [
                            -70.6363299999494,
                            42.2163859999978
                        ],
                        [
                            -70.6363299999494,
                            42.2029030000567
                        ],
                        [
                            -70.6429460003888,
                            42.1781849997824
                        ],
                        [
                            -70.6083810003822,
                            42.1384790000596
                        ],
                        [
                            -70.27743000036,
                            42.1143450000495
                        ],
                        [
                            -70.2494719996737,
                            42.1269259998918
                        ],
                        [
                            -70.2291749997153,
                            42.1311889998466
                        ],
                        [
                            -70.2062209996404,
                            42.1330540001349
                        ],
                        [
                            -70.1953999997291,
                            42.1315490001428
                        ],
                        [
                            -70.1725419999137,
                            42.1345150001353
                        ],
                        [
                            -70.1206910000675,
                            42.1251010002563
                        ],
                        [
                            -70.0670979997988,
                            42.1053269997947
                        ],
                        [
                            -70.0403019995769,
                            42.0916340002055
                        ],
                        [
                            -70.0115909995784,
                            42.0733779998879
                        ],
                        [
                            -69.9962789999649,
                            42.0596870000531
                        ],
                        [
                            -69.9694819999181,
                            42.0292540002582
                        ],
                        [
                            -69.9482099999913,
                            42.0001059996733
                        ],
                        [
                            -69.9291089999342,
                            41.9784759998115
                        ],
                        [
                            -69.9091779996737,
                            41.9420620001113
                        ],
                        [
                            -69.8953049996357,
                            41.9067530000365
                        ],
                        [
                            -69.8800180001366,
                            41.8619780002724
                        ],
                        [
                            -69.8723790000483,
                            41.8315860002394
                        ],
                        [
                            -69.8665250000403,
                            41.8170109997305
                        ],
                        [
                            -69.8655950002778,
                            41.790979999688
                        ],
                        [
                            -69.8600579995638,
                            41.7585029999617
                        ],
                        [
                            -69.8589500004244,
                            41.706915999792
                        ],
                        [
                            -69.862876000237,
                            41.6731530002414
                        ],
                        [
                            -69.8681250001726,
                            41.6567909997122
                        ],
                        [
                            -69.8744589996814,
                            41.6450260000928
                        ],
                        [
                            -69.8890120001349,
                            41.6251959998133
                        ],
                        [
                            -69.8916619996803,
                            41.6171860001007
                        ],
                        [
                            -69.8985619998018,
                            41.6060960001879
                        ],
                        [
                            -69.9153990001029,
                            41.5892430001949
                        ],
                        [
                            -69.9215169997016,
                            41.5709000003103
                        ],
                        [
                            -69.9213450001724,
                            41.5622819997448
                        ],
                        [
                            -69.9246319995774,
                            41.5446459998445
                        ],
                        [
                            -69.9289940003065,
                            41.5321519999962
                        ],
                        [
                            -69.9364009996929,
                            41.5219360003196
                        ],
                        [
                            -69.946613000232,
                            41.5121520002017
                        ],
                        [
                            -69.9674299998741,
                            41.5001159998395
                        ],
                        [
                            -69.9883059999657,
                            41.4944959996932
                        ],
                        [
                            -69.9981689996271,
                            41.4933989996874
                        ],
                        [
                            -69.9994580003132,
                            41.4905269999489
                        ],
                        [
                            -70.0138619999054,
                            41.4774389999746
                        ],
                        [
                            -70.0236769998862,
                            41.4719479996861
                        ],
                        [
                            -70.0403130003459,
                            41.4659599997881
                        ],
                        [
                            -70.0554369996384,
                            41.4641099998664
                        ],
                        [
                            -70.0737419998273,
                            41.4650200003073
                        ],
                        [
                            -70.0849040002204,
                            41.4674930002806
                        ],
                        [
                            -70.1015079999947,
                            41.4744439998587
                        ],
                        [
                            -70.1143940003687,
                            41.484082999772
                        ],
                        [
                            -70.1225949999735,
                            41.494498999956
                        ],
                        [
                            -70.1266770003201,
                            41.5036980000416
                        ],
                        [
                            -70.1285539995635,
                            41.5247320001484
                        ],
                        [
                            -70.1225589999885,
                            41.5378330000932
                        ],
                        [
                            -70.1167190002241,
                            41.5449930000805
                        ],
                        [
                            -70.1020290004016,
                            41.5563520000718
                        ],
                        [
                            -70.0803160004339,
                            41.5644540001525
                        ],
                        [
                            -70.0735860001917,
                            41.5777080000079
                        ],
                        [
                            -70.0633519999112,
                            41.5901729998858
                        ],
                        [
                            -70.068046000003,
                            41.6029539998221
                        ],
                        [
                            -70.0685690000597,
                            41.6106969999004
                        ],
                        [
                            -70.0779339997787,
                            41.6125280000851
                        ],
                        [
                            -70.1269519997814,
                            41.6002029999392
                        ],
                        [
                            -70.1384909997431,
                            41.5986920000406
                        ],
                        [
                            -70.1603569998717,
                            41.5993699999063
                        ],
                        [
                            -70.181499999927,
                            41.5935469996933
                        ],
                        [
                            -70.2080400000558,
                            41.5841500000635
                        ],
                        [
                            -70.2255579996991,
                            41.5685609998918
                        ],
                        [
                            -70.2494630003511,
                            41.5601459998555
                        ],
                        [
                            -70.267428999708,
                            41.5583729998819
                        ],
                        [
                            -70.2788980002478,
                            41.5593159998274
                        ],
                        [
                            -70.2899650002064,
                            41.5616390001618
                        ],
                        [
                            -70.3112539998516,
                            41.5710619998069
                        ],
                        [
                            -70.3315490001602,
                            41.5678980001613
                        ],
                        [
                            -70.346336999892,
                            41.5684529996758
                        ],
                        [
                            -70.3642060000628,
                            41.5621959999925
                        ],
                        [
                            -70.395164000099,
                            41.5567220002936
                        ],
                        [
                            -70.4238960000139,
                            41.5287319999004
                        ],
                        [
                            -70.4485670003969,
                            41.5130929997579
                        ],
                        [
                            -70.4664359996694,
                            41.5061599996814
                        ],
                        [
                            -70.4897400004469,
                            41.5018560001525
                        ],
                        [
                            -70.4982089998403,
                            41.5025649998046
                        ],
                        [
                            -70.5086029999544,
                            41.4989649998411
                        ],
                        [
                            -70.4994719996888,
                            41.4882329997196
                        ],
                        [
                            -70.4949419996285,
                            41.4796320002276
                        ],
                        [
                            -70.4896890003933,
                            41.4629769999277
                        ],
                        [
                            -70.4639500001555,
                            41.4680000002764
                        ],
                        [
                            -70.436449000302,
                            41.4689190002762
                        ],
                        [
                            -70.4210869995615,
                            41.4644159998375
                        ],
                        [
                            -70.4059280001089,
                            41.4570130000839
                        ],
                        [
                            -70.392891999947,
                            41.4454800002398
                        ],
                        [
                            -70.3858739998247,
                            41.4337210000735
                        ],
                        [
                            -70.3808070003623,
                            41.4119960002151
                        ],
                        [
                            -70.3806929996612,
                            41.3853609997335
                        ],
                        [
                            -70.3834490004102,
                            41.3699329999605
                        ],
                        [
                            -70.3701119999494,
                            41.3687689997282
                        ],
                        [
                            -70.3530849996776,
                            41.3814549999708
                        ],
                        [
                            -70.3382890004481,
                            41.3885500002178
                        ],
                        [
                            -70.3235929998795,
                            41.3916070000998
                        ],
                        [
                            -70.3024380001286,
                            41.3896250000119
                        ],
                        [
                            -70.2832140000478,
                            41.3928850000952
                        ],
                        [
                            -70.2672830001182,
                            41.3934259999915
                        ],
                        [
                            -70.2476890001415,
                            41.389890999694
                        ],
                        [
                            -70.2381419999493,
                            41.3862049997726
                        ],
                        [
                            -70.2216219998407,
                            41.3717690003342
                        ],
                        [
                            -70.2151150000795,
                            41.3602750000297
                        ],
                        [
                            -70.2082969999738,
                            41.3580249997331
                        ],
                        [
                            -70.1983830002588,
                            41.3590249997287
                        ],
                        [
                            -70.1749060001272,
                            41.3572900001144
                        ],
                        [
                            -70.1599919999987,
                            41.3522420002442
                        ],
                        [
                            -70.1486990000843,
                            41.3452159999198
                        ],
                        [
                            -70.1379400000973,
                            41.3463710001891
                        ],
                        [
                            -70.1193350003327,
                            41.3526380000249
                        ],
                        [
                            -70.0978440001228,
                            41.3535839998647
                        ],
                        [
                            -70.1113279999984,
                            41.370389000087
                        ],
                        [
                            -70.1164399996666,
                            41.3861649997657
                        ],
                        [
                            -70.1165409999489,
                            41.3951320002785
                        ],
                        [
                            -70.1127339999619,
                            41.4081259999002
                        ],
                        [
                            -70.1058189997718,
                            41.4198659996915
                        ],
                        [
                            -70.0944120000546,
                            41.430810000259
                        ],
                        [
                            -70.0651739998014,
                            41.4426870000534
                        ],
                        [
                            -70.0442719997705,
                            41.4438010000985
                        ],
                        [
                            -70.0259020001826,
                            41.4410790000613
                        ],
                        [
                            -70.0064670002146,
                            41.4338980001632
                        ],
                        [
                            -69.9817749999152,
                            41.4152079996695
                        ],
                        [
                            -69.969634000254,
                            41.4035110001303
                        ],
                        [
                            -69.902947999922,
                            41.3052069998523
                        ],
                        [
                            -69.8948850004095,
                            41.2903720002629
                        ],
                        [
                            -69.892445000028,
                            41.2790899999616
                        ],
                        [
                            -69.8922090000265,
                            41.2713229997718
                        ],
                        [
                            -69.8944719999576,
                            41.2592400003069
                        ],
                        [
                            -69.8990100004138,
                            41.2455380002415
                        ],
                        [
                            -69.9103190002217,
                            41.226848999808
                        ],
                        [
                            -69.9229109999696,
                            41.2158399999086
                        ],
                        [
                            -69.9420079998287,
                            41.2045200001556
                        ],
                        [
                            -69.9731819997749,
                            41.1935449998242
                        ],
                        [
                            -70.0124829997061,
                            41.1870529999862
                        ],
                        [
                            -70.0726659995766,
                            41.1916339997843
                        ],
                        [
                            -70.1096840003834,
                            41.1898029999494
                        ],
                        [
                            -70.1788510002065,
                            41.204530999762
                        ],
                        [
                            -70.2170170003357,
                            41.2181759996645
                        ],
                        [
                            -70.2399820002812,
                            41.2284630002031
                        ],
                        [
                            -70.2857360004451,
                            41.2423460000546
                        ],
                        [
                            -70.294484000396,
                            41.2464989998386
                        ],
                        [
                            -70.3142979995823,
                            41.2605319998795
                        ],
                        [
                            -70.3350900000084,
                            41.2727789997228
                        ],
                        [
                            -70.3440830001817,
                            41.2761460000465
                        ],
                        [
                            -70.3744660002826,
                            41.2694579998283
                        ],
                        [
                            -70.3967769997518,
                            41.2730140000332
                        ],
                        [
                            -70.4087330003633,
                            41.2772939998218
                        ],
                        [
                            -70.4290749996292,
                            41.2686510000126
                        ],
                        [
                            -70.4447579998618,
                            41.2655130002192
                        ],
                        [
                            -70.4598510000905,
                            41.2652530001518
                        ],
                        [
                            -70.4756349997071,
                            41.2702199997325
                        ],
                        [
                            -70.4933080000593,
                            41.2799749997263
                        ],
                        [
                            -70.5121990000541,
                            41.2983130000821
                        ],
                        [
                            -70.5718479999973,
                            41.3000939997336
                        ],
                        [
                            -70.6066719995716,
                            41.2994100001341
                        ],
                        [
                            -70.685019999889,
                            41.2931759997995
                        ],
                        [
                            -70.6928740000624,
                            41.289638999857
                        ],
                        [
                            -70.7031059997948,
                            41.2890400002654
                        ],
                        [
                            -70.7073620002186,
                            41.280218000118
                        ],
                        [
                            -70.716499000332,
                            41.2694520001075
                        ],
                        [
                            -70.7348429999807,
                            41.2592309999923
                        ],
                        [
                            -70.7336670001709,
                            41.2501159998612
                        ],
                        [
                            -70.734752999569,
                            41.2462600001305
                        ],
                        [
                            -70.7443070003322,
                            41.2260299998857
                        ],
                        [
                            -70.7541880004353,
                            41.2160909998136
                        ],
                        [
                            -70.7678370001672,
                            41.2064899998415
                        ],
                        [
                            -70.7835820003241,
                            41.2012189999531
                        ],
                        [
                            -70.8059499996947,
                            41.198741999749
                        ],
                        [
                            -70.8236449997882,
                            41.2006489998182
                        ],
                        [
                            -70.8477460002589,
                            41.2058140000951
                        ],
                        [
                            -70.8663429996275,
                            41.2153970000656
                        ],
                        [
                            -70.8744739998104,
                            41.2213809999223
                        ],
                        [
                            -70.8849730004047,
                            41.2337840000353
                        ],
                        [
                            -70.8905410001825,
                            41.2430910002012
                        ],
                        [
                            -70.8942030004045,
                            41.2594390002671
                        ],
                        [
                            -70.893594999959,
                            41.2679940002766
                        ],
                        [
                            -70.890336999968,
                            41.2773619999349
                        ],
                        [
                            -70.8856830000592,
                            41.285066000258
                        ],
                        [
                            -70.8781820000632,
                            41.2921469997483
                        ],
                        [
                            -70.8700269995908,
                            41.2970679999487
                        ],
                        [
                            -70.8844720000893,
                            41.3101620000409
                        ],
                        [
                            -70.8977219996131,
                            41.3274110001119
                        ],
                        [
                            -70.9010049997184,
                            41.3346130001678
                        ],
                        [
                            -70.9038149999957,
                            41.3467269999578
                        ],
                        [
                            -70.9037389998278,
                            41.35508000006
                        ],
                        [
                            -70.901865999884,
                            41.3617700002809
                        ],
                        [
                            -70.8960169998988,
                            41.3701480001995
                        ],
                        [
                            -70.9183990004114,
                            41.3630330000731
                        ],
                        [
                            -70.9386639996845,
                            41.3539860002861
                        ],
                        [
                            -70.9557520000557,
                            41.3501609998456
                        ],
                        [
                            -70.9705210004192,
                            41.3491869999533
                        ],
                        [
                            -70.9907480000575,
                            41.3512890001241
                        ],
                        [
                            -71.0147990002994,
                            41.3620199998079
                        ],
                        [
                            -71.0261030000844,
                            41.3716929996753
                        ],
                        [
                            -71.0327010000822,
                            41.3802730000702
                        ],
                        [
                            -71.0399180003961,
                            41.398258999969
                        ],
                        [
                            -71.0336040000805,
                            41.4140850001856
                        ],
                        [
                            -71.0534509997771,
                            41.4169589999607
                        ],
                        [
                            -71.0724039996962,
                            41.4255700000244
                        ],
                        [
                            -71.0850380001752,
                            41.4334670002087
                        ],
                        [
                            -71.0885709996274,
                            41.4313149997622
                        ],
                        [
                            -71.1211160002754,
                            41.4927870000876
                        ],
                        [
                            -71.1198740003434,
                            41.4946040003201
                        ],
                        [
                            -71.1224369998502,
                            41.5220750003012
                        ],
                        [
                            -71.1316199999943,
                            41.5937159998945
                        ],
                        [
                            -71.1403079996708,
                            41.6048499999872
                        ],
                        [
                            -71.1419580000318,
                            41.612532999684
                        ],
                        [
                            -71.1401849996472,
                            41.6238689997119
                        ],
                        [
                            -71.1353070003307,
                            41.6284880000023
                        ],
                        [
                            -71.1325570003278,
                            41.6603630002315
                        ],
                        [
                            -71.1761199996323,
                            41.6682479999186
                        ],
                        [
                            -71.1757840000716,
                            41.671493000104
                        ],
                        [
                            -71.1954790003306,
                            41.6750900002277
                        ],
                        [
                            -71.2610669997723,
                            41.7520820001837
                        ],
                        [
                            -71.3284719999796,
                            41.7806940001221
                        ],
                        [
                            -71.3303909999541,
                            41.7853810002203
                        ],
                        [
                            -71.3294569999937,
                            41.7883270000824
                        ],
                        [
                            -71.3327429995738,
                            41.7901599996962
                        ],
                        [
                            -71.3346449998299,
                            41.7948350001564
                        ],
                        [
                            -71.3404810002946,
                            41.7970330000113
                        ],
                        [
                            -71.339332000249,
                            41.8085270002287
                        ],
                        [
                            -71.3475450004476,
                            41.8232460001313
                        ],
                        [
                            -71.3451979999526,
                            41.8276760001097
                        ],
                        [
                            -71.3354170003069,
                            41.8356450001951
                        ],
                        [
                            -71.3425500000569,
                            41.844655999714
                        ],
                        [
                            -71.3340399997574,
                            41.8626039999997
                        ],
                        [
                            -71.3408899996502,
                            41.8818040001622
                        ],
                        [
                            -71.3387869995526,
                            41.8985930000188
                        ],
                        [
                            -71.3814629996506,
                            41.8935460000175
                        ],
                        [
                            -71.3814009997263,
                            42.0187979997801
                        ],
                        [
                            -71.4982590001295,
                            42.0172719998823
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 42.1565196,
            "name": "Massachusetts",
            "intptlon": -71.4895915,
            "geo_point_2d": [
                42.1601422413,
                -71.5040599745
            ],
            "geoid": "25",
            "mtfcc": "G4000",
            "region": 1
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -71.5040599745,
                42.1601422413
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "2b0515c4f86c463ca8df038b865b3b3cf1edad6c",
        "fields": {
            "arealand": "197759069",
            "objectid": 42,
            "basename": "American Samoa",
            "stusab": "AS",
            "statens": "01802701",
            "centlon": -170.0822667,
            "state": "60",
            "gid": 29,
            "centlat": -13.9638307,
            "division": 0,
            "areawater": "1307243753",
            "oid": "-1625689740",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                -170.797156000366,
                                -14.24663699997
                            ],
                            [
                                -170.750000000436,
                                -14.2412350002824
                            ],
                            [
                                -170.745979000189,
                                -14.2337609999474
                            ],
                            [
                                -170.741597000267,
                                -14.2292950002387
                            ],
                            [
                                -170.737232000063,
                                -14.2258309996739
                            ],
                            [
                                -170.726287000303,
                                -14.2226830002871
                            ],
                            [
                                -170.72032500034,
                                -14.2147260002265
                            ],
                            [
                                -170.703919999859,
                                -14.2033659997919
                            ],
                            [
                                -170.702795000103,
                                -14.196862999768
                            ],
                            [
                                -170.687091999779,
                                -14.1822399997878
                            ],
                            [
                                -170.665199999711,
                                -14.1759470003991
                            ],
                            [
                                -170.641660999655,
                                -14.1769360003314
                            ],
                            [
                                -170.628084999718,
                                -14.1828349997281
                            ],
                            [
                                -170.602228000378,
                                -14.2003699996932
                            ],
                            [
                                -170.581657999709,
                                -14.1963999996184
                            ],
                            [
                                -170.559218000369,
                                -14.1983849996467
                            ],
                            [
                                -170.53677800013,
                                -14.2043399998296
                            ],
                            [
                                -170.510597999851,
                                -14.2321299998308
                            ],
                            [
                                -170.506456999953,
                                -14.2542999996763
                            ],
                            [
                                -170.494719999625,
                                -14.265964000071
                            ],
                            [
                                -170.490341000076,
                                -14.2824750000642
                            ],
                            [
                                -170.491495000144,
                                -14.3016909999627
                            ],
                            [
                                -170.500000000421,
                                -14.3176160002133
                            ],
                            [
                                -170.50943899976,
                                -14.3287629996297
                            ],
                            [
                                -170.51967499969,
                                -14.3331560002113
                            ],
                            [
                                -170.559541000409,
                                -14.3380459996497
                            ],
                            [
                                -170.581420000058,
                                -14.3348239996067
                            ],
                            [
                                -170.586501999589,
                                -14.3310339996932
                            ],
                            [
                                -170.602384999838,
                                -14.3343130002452
                            ],
                            [
                                -170.616450000395,
                                -14.3342159998721
                            ],
                            [
                                -170.637462999856,
                                -14.3369140002079
                            ],
                            [
                                -170.642786000309,
                                -14.3403209997015
                            ],
                            [
                                -170.648175999812,
                                -14.3474510000126
                            ],
                            [
                                -170.654492999602,
                                -14.3642519997981
                            ],
                            [
                                -170.660390000166,
                                -14.3713810004158
                            ],
                            [
                                -170.725755000025,
                                -14.4159420001146
                            ],
                            [
                                -170.739019999618,
                                -14.4199499996414
                            ],
                            [
                                -170.759852000227,
                                -14.4181360000888
                            ],
                            [
                                -170.774343999683,
                                -14.4131419996093
                            ],
                            [
                                -170.781144000245,
                                -14.407460000089
                            ],
                            [
                                -170.785869000299,
                                -14.4005239998844
                            ],
                            [
                                -170.803957999679,
                                -14.3957470003036
                            ],
                            [
                                -170.812268999787,
                                -14.3887749996204
                            ],
                            [
                                -170.820745999577,
                                -14.3774639998142
                            ],
                            [
                                -170.82309899992,
                                -14.3717870000655
                            ],
                            [
                                -170.838100999912,
                                -14.3702250003163
                            ],
                            [
                                -170.8523339998,
                                -14.3663509997669
                            ],
                            [
                                -170.865943000247,
                                -14.3585759996706
                            ],
                            [
                                -170.883697999717,
                                -14.3426940003804
                            ],
                            [
                                -170.889252000149,
                                -14.3309019997843
                            ],
                            [
                                -170.890290999692,
                                -14.3171939999081
                            ],
                            [
                                -170.881844999864,
                                -14.2984839997307
                            ],
                            [
                                -170.874999999994,
                                -14.2875969997759
                            ],
                            [
                                -170.86253299992,
                                -14.2750440003454
                            ],
                            [
                                -170.840270999956,
                                -14.2580179996808
                            ],
                            [
                                -170.829501999924,
                                -14.257686999748
                            ],
                            [
                                -170.823257999928,
                                -14.250000000024
                            ],
                            [
                                -170.816777999931,
                                -14.2476540003467
                            ],
                            [
                                -170.797156000366,
                                -14.24663699997
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -169.629823999707,
                                -14.2295149998124
                            ],
                            [
                                -169.631351000044,
                                -14.2279659998651
                            ],
                            [
                                -169.64672700013,
                                -14.2325750000101
                            ],
                            [
                                -169.660610000214,
                                -14.2338270001046
                            ],
                            [
                                -169.672671000407,
                                -14.232886000157
                            ],
                            [
                                -169.680352000329,
                                -14.2305970004246
                            ],
                            [
                                -169.692840000319,
                                -14.2216039999515
                            ],
                            [
                                -169.714193000437,
                                -14.2007329999277
                            ],
                            [
                                -169.722804000121,
                                -14.1893699998647
                            ],
                            [
                                -169.725796999798,
                                -14.1809719998829
                            ],
                            [
                                -169.725894999707,
                                -14.166149999913
                            ],
                            [
                                -169.722572000317,
                                -14.1436649998633
                            ],
                            [
                                -169.717321999658,
                                -14.1333069998212
                            ],
                            [
                                -169.702316000366,
                                -14.1203449996623
                            ],
                            [
                                -169.678020999727,
                                -14.1115219998021
                            ],
                            [
                                -169.665746999997,
                                -14.109369999875
                            ],
                            [
                                -169.630488000229,
                                -14.113714999792
                            ],
                            [
                                -169.624999999919,
                                -14.1104609998386
                            ],
                            [
                                -169.609890999797,
                                -14.1059409997009
                            ],
                            [
                                -169.599242000313,
                                -14.1053919998433
                            ],
                            [
                                -169.590009999765,
                                -14.1075229999242
                            ],
                            [
                                -169.57571199958,
                                -14.1135859996593
                            ],
                            [
                                -169.562365999796,
                                -14.1250000001643
                            ],
                            [
                                -169.554715999837,
                                -14.1425809996652
                            ],
                            [
                                -169.554272000322,
                                -14.1516910001244
                            ],
                            [
                                -169.547614999874,
                                -14.1635840003149
                            ],
                            [
                                -169.548255999932,
                                -14.1780069995899
                            ],
                            [
                                -169.547008999977,
                                -14.1843329996202
                            ],
                            [
                                -169.542553000435,
                                -14.1797789995791
                            ],
                            [
                                -169.523797999984,
                                -14.1747180002093
                            ],
                            [
                                -169.506057999685,
                                -14.1668739998456
                            ],
                            [
                                -169.50000000036,
                                -14.1660519995955
                            ],
                            [
                                -169.460450000009,
                                -14.169501000001
                            ],
                            [
                                -169.458064999879,
                                -14.1672789998882
                            ],
                            [
                                -169.432118999952,
                                -14.1712559996198
                            ],
                            [
                                -169.415916999861,
                                -14.1685949998527
                            ],
                            [
                                -169.395182000234,
                                -14.1700069997709
                            ],
                            [
                                -169.385643000438,
                                -14.1746060002146
                            ],
                            [
                                -169.377205000109,
                                -14.1812560004064
                            ],
                            [
                                -169.369200000322,
                                -14.1937770001986
                            ],
                            [
                                -169.366896000383,
                                -14.2020750002807
                            ],
                            [
                                -169.367518000174,
                                -14.2613029999652
                            ],
                            [
                                -169.370674000058,
                                -14.2795250002407
                            ],
                            [
                                -169.374999999904,
                                -14.2884860003121
                            ],
                            [
                                -169.380242000167,
                                -14.2948160000594
                            ],
                            [
                                -169.39739800031,
                                -14.3071789998606
                            ],
                            [
                                -169.419497999892,
                                -14.311415000002
                            ],
                            [
                                -169.430213000396,
                                -14.3098589998112
                            ],
                            [
                                -169.445972999723,
                                -14.3048320003387
                            ],
                            [
                                -169.453771999645,
                                -14.3095189999903
                            ],
                            [
                                -169.473242999598,
                                -14.3163589996544
                            ],
                            [
                                -169.482691999881,
                                -14.3172489996855
                            ],
                            [
                                -169.502365000399,
                                -14.3121000001297
                            ],
                            [
                                -169.513397000197,
                                -14.3077850002979
                            ],
                            [
                                -169.518883999784,
                                -14.3018020003077
                            ],
                            [
                                -169.527691000185,
                                -14.2817140001249
                            ],
                            [
                                -169.532901999587,
                                -14.2740070000245
                            ],
                            [
                                -169.539188000314,
                                -14.2690020003575
                            ],
                            [
                                -169.553376999821,
                                -14.2639119995913
                            ],
                            [
                                -169.558871999804,
                                -14.2589169996212
                            ],
                            [
                                -169.563813999593,
                                -14.250000000024
                            ],
                            [
                                -169.566847000351,
                                -14.2214789996839
                            ],
                            [
                                -169.588331999815,
                                -14.2337699998909
                            ],
                            [
                                -169.601624999895,
                                -14.2370640003717
                            ],
                            [
                                -169.615629000352,
                                -14.2377209996213
                            ],
                            [
                                -169.624999999919,
                                -14.2344449998493
                            ],
                            [
                                -169.629823999707,
                                -14.2295149998124
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -171.141181000184,
                                -11.0466079997494
                            ],
                            [
                                -171.137453999665,
                                -11.0300909997116
                            ],
                            [
                                -171.125765999741,
                                -11.0155970001996
                            ],
                            [
                                -171.111399999783,
                                -11.0037919997426
                            ],
                            [
                                -171.094879999674,
                                -10.9978020001777
                            ],
                            [
                                -171.07463200012,
                                -10.997202999611
                            ],
                            [
                                -171.036337999944,
                                -11.0029109997433
                            ],
                            [
                                -171.019278999885,
                                -11.01224899993
                            ],
                            [
                                -171.014070999957,
                                -11.0237009999721
                            ],
                            [
                                -171.011157999748,
                                -11.0602239996183
                            ],
                            [
                                -171.011377999856,
                                -11.0781430002105
                            ],
                            [
                                -171.019632999887,
                                -11.0946309998851
                            ],
                            [
                                -171.032172999757,
                                -11.1050879998446
                            ],
                            [
                                -171.048865000293,
                                -11.109294000115
                            ],
                            [
                                -171.074412000012,
                                -11.1101300000983
                            ],
                            [
                                -171.098344000428,
                                -11.1078689996712
                            ],
                            [
                                -171.120632000331,
                                -11.1009129997671
                            ],
                            [
                                -171.133870999984,
                                -11.090533999644
                            ],
                            [
                                -171.141146000024,
                                -11.073818999665
                            ],
                            [
                                -171.141181000184,
                                -11.0466079997494
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -168.226000000097,
                                -14.5470370000642
                            ],
                            [
                                -168.224528999836,
                                -14.5245100002881
                            ],
                            [
                                -168.218475999636,
                                -14.5118370003156
                            ],
                            [
                                -168.209822000295,
                                -14.5000860003841
                            ],
                            [
                                -168.199526999915,
                                -14.4901260003729
                            ],
                            [
                                -168.186781000181,
                                -14.4832499996474
                            ],
                            [
                                -168.173299999781,
                                -14.4796930002434
                            ],
                            [
                                -168.157486000027,
                                -14.4778940004206
                            ],
                            [
                                -168.15222799987,
                                -14.4778479999094
                            ],
                            [
                                -168.134892999627,
                                -14.4845740002022
                            ],
                            [
                                -168.123336999947,
                                -14.4917290003779
                            ],
                            [
                                -168.111165000323,
                                -14.5081250001885
                            ],
                            [
                                -168.104693999649,
                                -14.5248189996128
                            ],
                            [
                                -168.101612000283,
                                -14.5379360001926
                            ],
                            [
                                -168.102229000052,
                                -14.5540340002116
                            ],
                            [
                                -168.105926000434,
                                -14.566553999889
                            ],
                            [
                                -168.110398999694,
                                -14.5791659996097
                            ],
                            [
                                -168.116143999922,
                                -14.5870500002051
                            ],
                            [
                                -168.129102000266,
                                -14.5972590002709
                            ],
                            [
                                -168.135803000196,
                                -14.5996790000299
                            ],
                            [
                                -168.157851999725,
                                -14.6018130003451
                            ],
                            [
                                -168.17353800033,
                                -14.6013389996791
                            ],
                            [
                                -168.195114000031,
                                -14.5970709995747
                            ],
                            [
                                -168.206756999749,
                                -14.5900919996758
                            ],
                            [
                                -168.217986999915,
                                -14.5751300002534
                            ],
                            [
                                -168.223480000248,
                                -14.5613430002763
                            ],
                            [
                                -168.226000000097,
                                -14.5470370000642
                            ]
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": -14.2668475,
            "name": "American Samoa",
            "intptlon": -170.6671854,
            "geo_point_2d": [
                -13.9662650627,
                -170.079669261
            ],
            "geoid": "60",
            "mtfcc": "G4000",
            "region": 9
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -170.079669261,
                -13.9662650627
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "2730da2e1897b940f4cd0145701e654e6c12ecd4",
        "fields": {
            "arealand": "1959052846",
            "objectid": 46,
            "basename": "Missouri",
            "stusab": "MO",
            "statens": "01779791",
            "centlon": -92.4580695,
            "state": "29",
            "gid": 33,
            "centlat": 38.3569742,
            "division": 4,
            "areawater": "-1807454608",
            "oid": "267864621",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -94.6080329998812,
                            38.847207000312
                        ],
                        [
                            -94.607132000431,
                            39.1006749997677
                        ],
                        [
                            -94.608519000128,
                            39.1162439998017
                        ],
                        [
                            -94.6051160003721,
                            39.1232590002943
                        ],
                        [
                            -94.590287999559,
                            39.138121000174
                        ],
                        [
                            -94.5883870000261,
                            39.1488679997178
                        ],
                        [
                            -94.5906609998279,
                            39.1547669998254
                        ],
                        [
                            -94.6086570002203,
                            39.1610440003462
                        ],
                        [
                            -94.6355520001764,
                            39.154082000177
                        ],
                        [
                            -94.6506470000549,
                            39.1540919998588
                        ],
                        [
                            -94.6557870002105,
                            39.155680000263
                        ],
                        [
                            -94.6592100000579,
                            39.1578410002073
                        ],
                        [
                            -94.6613969998211,
                            39.162742000334
                        ],
                        [
                            -94.6591620003774,
                            39.1741260001636
                        ],
                        [
                            -94.6615769997462,
                            39.1773589999823
                        ],
                        [
                            -94.6691100004276,
                            39.1822039998811
                        ],
                        [
                            -94.6782399999699,
                            39.1841690001333
                        ],
                        [
                            -94.6887880000698,
                            39.183227999689
                        ],
                        [
                            -94.7141370003202,
                            39.1704030000326
                        ],
                        [
                            -94.7236369997585,
                            39.1690030001262
                        ],
                        [
                            -94.7407770000082,
                            39.1699869998665
                        ],
                        [
                            -94.7522859998327,
                            39.1732499997999
                        ],
                        [
                            -94.762858000222,
                            39.17999099978
                        ],
                        [
                            -94.7702500004381,
                            39.1898269998321
                        ],
                        [
                            -94.776398000174,
                            39.201810000245
                        ],
                        [
                            -94.7829509999659,
                            39.2071620000742
                        ],
                        [
                            -94.8115430001387,
                            39.2069059997831
                        ],
                        [
                            -94.8219349997046,
                            39.2096689997826
                        ],
                        [
                            -94.8316790004386,
                            39.2159380002556
                        ],
                        [
                            -94.834729999842,
                            39.2207549998907
                        ],
                        [
                            -94.8348960004217,
                            39.2238420001529
                        ],
                        [
                            -94.8277910002608,
                            39.2340009999887
                        ],
                        [
                            -94.8256630000489,
                            39.2417290000234
                        ],
                        [
                            -94.8274790000912,
                            39.2498959997671
                        ],
                        [
                            -94.8305529999592,
                            39.2551349999354
                        ],
                        [
                            -94.8463199998574,
                            39.2684809999892
                        ],
                        [
                            -94.8840929996262,
                            39.284511999931
                        ],
                        [
                            -94.8985160003834,
                            39.2984500001641
                        ],
                        [
                            -94.9061260001593,
                            39.3147210003047
                        ],
                        [
                            -94.9103530002709,
                            39.3447350003366
                        ],
                        [
                            -94.9100169998119,
                            39.352542999778
                        ],
                        [
                            -94.9024970004476,
                            39.3603829999577
                        ],
                        [
                            -94.8871449997528,
                            39.3654309999077
                        ],
                        [
                            -94.8813600002401,
                            39.3703829998673
                        ],
                        [
                            -94.8790880000881,
                            39.3757029997299
                        ],
                        [
                            -94.8792810004319,
                            39.3797800002468
                        ],
                        [
                            -94.8850259997617,
                            39.3898010001049
                        ],
                        [
                            -94.8918449996924,
                            39.3933130002025
                        ],
                        [
                            -94.9001320004092,
                            39.3933330001254
                        ],
                        [
                            -94.9158590001244,
                            39.3863480000409
                        ],
                        [
                            -94.9229940004225,
                            39.3848830002542
                        ],
                        [
                            -94.9336520001272,
                            39.3855460002104
                        ],
                        [
                            -94.9420390004032,
                            39.3894989998811
                        ],
                        [
                            -94.9462270001567,
                            39.3956479998422
                        ],
                        [
                            -94.9462930002789,
                            39.405646000023
                        ],
                        [
                            -94.9478640000989,
                            39.4086040002224
                        ],
                        [
                            -94.9548169999238,
                            39.4138439998205
                        ],
                        [
                            -94.9644889997895,
                            39.4167179999034
                        ],
                        [
                            -94.9729520002334,
                            39.4217049998493
                        ],
                        [
                            -94.9766060000595,
                            39.4267010001412
                        ],
                        [
                            -94.9787979998456,
                            39.4362410002315
                        ],
                        [
                            -94.9847880002961,
                            39.4427029996742
                        ],
                        [
                            -94.9928509998086,
                            39.4472720001712
                        ],
                        [
                            -95.0220249995894,
                            39.455453000016
                        ],
                        [
                            -95.0334079999155,
                            39.4608759999738
                        ],
                        [
                            -95.0407800000401,
                            39.4663869997783
                        ],
                        [
                            -95.0476709999407,
                            39.477535999993
                        ],
                        [
                            -95.0492209998444,
                            39.4918920003143
                        ],
                        [
                            -95.0521769997115,
                            39.4999959997277
                        ],
                        [
                            -95.0593000003141,
                            39.5064729998846
                        ],
                        [
                            -95.0827139997982,
                            39.5167120000278
                        ],
                        [
                            -95.0974129998416,
                            39.5282540002483
                        ],
                        [
                            -95.1065959999857,
                            39.5376569998076
                        ],
                        [
                            -95.1131169999906,
                            39.5521100000155
                        ],
                        [
                            -95.1132840003951,
                            39.5581520003265
                        ],
                        [
                            -95.109843999931,
                            39.5678229997035
                        ],
                        [
                            -95.1074739998697,
                            39.5737430003398
                        ],
                        [
                            -95.1032280003899,
                            39.5777830002193
                        ],
                        [
                            -95.0957359997164,
                            39.5806179996956
                        ],
                        [
                            -95.0895150001855,
                            39.5810280001228
                        ],
                        [
                            -95.0752100003274,
                            39.5764290002428
                        ],
                        [
                            -95.0670950000381,
                            39.5765019998657
                        ],
                        [
                            -95.0568969997426,
                            39.5805669999322
                        ],
                        [
                            -95.050589999998,
                            39.5875629997899
                        ],
                        [
                            -95.0471649996025,
                            39.595116999788
                        ],
                        [
                            -95.0464449999022,
                            39.6016060001644
                        ],
                        [
                            -95.0542360003265,
                            39.6172909997088
                        ],
                        [
                            -95.0542670002886,
                            39.6277450002411
                        ],
                        [
                            -95.0495179999453,
                            39.6378760002062
                        ],
                        [
                            -95.0245949996672,
                            39.6684849998953
                        ],
                        [
                            -95.0122449997687,
                            39.675122000307
                        ],
                        [
                            -94.981643000283,
                            39.6786089998988
                        ],
                        [
                            -94.9759680003751,
                            39.6816349998573
                        ],
                        [
                            -94.9712180002068,
                            39.6865330001158
                        ],
                        [
                            -94.968981000215,
                            39.6929540000495
                        ],
                        [
                            -94.9684530001353,
                            39.7074019998261
                        ],
                        [
                            -94.9712059996129,
                            39.7293049997846
                        ],
                        [
                            -94.969189999554,
                            39.7339290000184
                        ],
                        [
                            -94.9626619998765,
                            39.741337000292
                        ],
                        [
                            -94.9552859995539,
                            39.745689000107
                        ],
                        [
                            -94.9475410000587,
                            39.7453369997547
                        ],
                        [
                            -94.9418040002265,
                            39.7433670001252
                        ],
                        [
                            -94.9183420001636,
                            39.7287969999665
                        ],
                        [
                            -94.90104399973,
                            39.7240579997906
                        ],
                        [
                            -94.8813429996233,
                            39.7278939998431
                        ],
                        [
                            -94.8650479996456,
                            39.7385909998808
                        ],
                        [
                            -94.8590349996289,
                            39.7541159997548
                        ],
                        [
                            -94.8606389999591,
                            39.7629829997541
                        ],
                        [
                            -94.8671430002455,
                            39.7716939997774
                        ],
                        [
                            -94.8711450002262,
                            39.772979999942
                        ],
                        [
                            -94.8821610001311,
                            39.7710429997473
                        ],
                        [
                            -94.8982629997651,
                            39.7616219998377
                        ],
                        [
                            -94.9109490001226,
                            39.7591780001164
                        ],
                        [
                            -94.926228999949,
                            39.7664899998318
                        ],
                        [
                            -94.9345059997217,
                            39.7728620001914
                        ],
                        [
                            -94.9352999999401,
                            39.7756139998208
                        ],
                        [
                            -94.9349969999914,
                            39.783691999912
                        ],
                        [
                            -94.9275579999197,
                            39.7892099998179
                        ],
                        [
                            -94.8931800004092,
                            39.7909149999878
                        ],
                        [
                            -94.8822890001777,
                            39.7958039998819
                        ],
                        [
                            -94.8762440003739,
                            39.8073940000953
                        ],
                        [
                            -94.8766229995923,
                            39.8222269998038
                        ],
                        [
                            -94.8782930000448,
                            39.8260260002116
                        ],
                        [
                            -94.8836580003313,
                            39.8316650002228
                        ],
                        [
                            -94.8887929995657,
                            39.8341349998431
                        ],
                        [
                            -94.9063400004196,
                            39.8337360002895
                        ],
                        [
                            -94.9167660003207,
                            39.8358069999963
                        ],
                        [
                            -94.9398540002898,
                            39.8520439997437
                        ],
                        [
                            -94.9425669995844,
                            39.8570339999212
                        ],
                        [
                            -94.9423780003369,
                            39.8622539998289
                        ],
                        [
                            -94.9284659999409,
                            39.8763439997907
                        ],
                        [
                            -94.9278969998534,
                            39.8861120002499
                        ],
                        [
                            -94.9349069995797,
                            39.893784000151
                        ],
                        [
                            -94.9541549999499,
                            39.9011379997806
                        ],
                        [
                            -94.9621270001242,
                            39.9012630001845
                        ],
                        [
                            -94.9870589997248,
                            39.8963200002626
                        ],
                        [
                            -95.003819000033,
                            39.9004010003161
                        ],
                        [
                            -95.0151350004119,
                            39.8996429997431
                        ],
                        [
                            -95.0252399999226,
                            39.8896999999699
                        ],
                        [
                            -95.0249009999889,
                            39.877254999687
                        ],
                        [
                            -95.0267470001685,
                            39.8722730003257
                        ],
                        [
                            -95.0328609995693,
                            39.8673209998627
                        ],
                        [
                            -95.0394030003888,
                            39.8648319996734
                        ],
                        [
                            -95.0850029996686,
                            39.8618829996893
                        ],
                        [
                            -95.1087109997789,
                            39.8704020000268
                        ],
                        [
                            -95.1286090004274,
                            39.8743350003267
                        ],
                        [
                            -95.1347470001176,
                            39.8768519999617
                        ],
                        [
                            -95.1406010001256,
                            39.881688000294
                        ],
                        [
                            -95.1432540000441,
                            39.8877750000207
                        ],
                        [
                            -95.1428380001175,
                            39.9004020002758
                        ],
                        [
                            -95.1496570000482,
                            39.9059479998707
                        ],
                        [
                            -95.1598340004272,
                            39.9069840001758
                        ],
                        [
                            -95.1743030003168,
                            39.9009910002266
                        ],
                        [
                            -95.1812219998066,
                            39.8999960002521
                        ],
                        [
                            -95.1937929996381,
                            39.9009719997737
                        ],
                        [
                            -95.2027449998035,
                            39.9050760000747
                        ],
                        [
                            -95.206063000069,
                            39.9120609999844
                        ],
                        [
                            -95.2005820003298,
                            39.9251239998765
                        ],
                        [
                            -95.2003820003133,
                            39.9310309997358
                        ],
                        [
                            -95.2019239998209,
                            39.9361219999244
                        ],
                        [
                            -95.2052660003759,
                            39.9397499997955
                        ],
                        [
                            -95.2164399995662,
                            39.9439530002111
                        ],
                        [
                            -95.2367609998141,
                            39.9439309999293
                        ],
                        [
                            -95.2502539999104,
                            39.9486439999423
                        ],
                        [
                            -95.2576519999743,
                            39.9548860003206
                        ],
                        [
                            -95.2698860004202,
                            39.9693959996576
                        ],
                        [
                            -95.2954030000018,
                            39.9806629998813
                        ],
                        [
                            -95.3058029999637,
                            39.9878989996605
                        ],
                        [
                            -95.3080879996361,
                            39.9924780002628
                        ],
                        [
                            -95.3093320001163,
                            40.0044730002434
                        ],
                        [
                            -95.3152709996148,
                            40.0120700002605
                        ],
                        [
                            -95.3337309996144,
                            40.0178319999569
                        ],
                        [
                            -95.3465729996076,
                            40.0282719998007
                        ],
                        [
                            -95.3515069998989,
                            40.030232000043
                        ],
                        [
                            -95.3639830001941,
                            40.0314980000917
                        ],
                        [
                            -95.3829570000296,
                            40.0271120001379
                        ],
                        [
                            -95.3915269997053,
                            40.0270579997775
                        ],
                        [
                            -95.4026659996339,
                            40.0305670002042
                        ],
                        [
                            -95.4124370001321,
                            40.0372109997973
                        ],
                        [
                            -95.419322000185,
                            40.0484420002203
                        ],
                        [
                            -95.4213399998938,
                            40.0607459997853
                        ],
                        [
                            -95.4188960002126,
                            40.0659580000774
                        ],
                        [
                            -95.4093669995638,
                            40.0749980002762
                        ],
                        [
                            -95.4083890001207,
                            40.0797219998952
                        ],
                        [
                            -95.410426000096,
                            40.0879480003088
                        ],
                        [
                            -95.4100999996828,
                            40.0939750003154
                        ],
                        [
                            -95.4065980001927,
                            40.0991059997471
                        ],
                        [
                            -95.3942159996088,
                            40.1082630002878
                        ],
                        [
                            -95.3928099996452,
                            40.1129289997378
                        ],
                        [
                            -95.3933469999456,
                            40.1192119998833
                        ],
                        [
                            -95.3965489998601,
                            40.1247009996978
                        ],
                        [
                            -95.4002660003337,
                            40.1272929996685
                        ],
                        [
                            -95.4076910001618,
                            40.129839000048
                        ],
                        [
                            -95.4195499997906,
                            40.1310489999903
                        ],
                        [
                            -95.427408999987,
                            40.1346079999606
                        ],
                        [
                            -95.432165000003,
                            40.1410250002315
                        ],
                        [
                            -95.4338220000367,
                            40.1529349999149
                        ],
                        [
                            -95.4363479997336,
                            40.1587199997988
                        ],
                        [
                            -95.4428179996848,
                            40.1632610000806
                        ],
                        [
                            -95.4638310000438,
                            40.1712010003398
                        ],
                        [
                            -95.479192999886,
                            40.1856520000733
                        ],
                        [
                            -95.4825399995654,
                            40.1922829999616
                        ],
                        [
                            -95.4823189996325,
                            40.2006670000785
                        ],
                        [
                            -95.4723630000847,
                            40.2153349997866
                        ],
                        [
                            -95.469900000137,
                            40.2227000002896
                        ],
                        [
                            -95.4703489996756,
                            40.2308190002321
                        ],
                        [
                            -95.4762410002168,
                            40.2412570003373
                        ],
                        [
                            -95.4807100001777,
                            40.2450799998485
                        ],
                        [
                            -95.488998999646,
                            40.2487390002654
                        ],
                        [
                            -95.5210349995826,
                            40.2500829997325
                        ],
                        [
                            -95.5437369997624,
                            40.2606870000523
                        ],
                        [
                            -95.5524730000178,
                            40.261903999863
                        ],
                        [
                            -95.5563250002106,
                            40.2677140000013
                        ],
                        [
                            -95.5562749999819,
                            40.2707610003062
                        ],
                        [
                            -95.5514880000037,
                            40.281060999752
                        ],
                        [
                            -95.5516200002482,
                            40.288665999721
                        ],
                        [
                            -95.5555529997335,
                            40.2932729996884
                        ],
                        [
                            -95.562156999579,
                            40.2973589998619
                        ],
                        [
                            -95.5706929998179,
                            40.299425999972
                        ],
                        [
                            -95.581787000439,
                            40.2995800002479
                        ],
                        [
                            -95.5901649995957,
                            40.3031990002197
                        ],
                        [
                            -95.598657000352,
                            40.3098089998864
                        ],
                        [
                            -95.6104389999879,
                            40.3139700000746
                        ],
                        [
                            -95.6179309997631,
                            40.3137280000528
                        ],
                        [
                            -95.6453289996845,
                            40.3056929996971
                        ],
                        [
                            -95.6515069995576,
                            40.3066839999772
                        ],
                        [
                            -95.6573279999537,
                            40.3108560000483
                        ],
                        [
                            -95.6577639999718,
                            40.3157879997593
                        ],
                        [
                            -95.6537290003792,
                            40.3225820002551
                        ],
                        [
                            -95.6276479998347,
                            40.3324120003006
                        ],
                        [
                            -95.624014999925,
                            40.3362259997818
                        ],
                        [
                            -95.6226840003045,
                            40.3423230002657
                        ],
                        [
                            -95.627123999953,
                            40.3527999999482
                        ],
                        [
                            -95.6410270001282,
                            40.3663989999363
                        ],
                        [
                            -95.6439350003148,
                            40.3868510001891
                        ],
                        [
                            -95.6606019998382,
                            40.412408999822
                        ],
                        [
                            -95.6607099997933,
                            40.4188269997805
                        ],
                        [
                            -95.655963999823,
                            40.4298730001032
                        ],
                        [
                            -95.6565749997432,
                            40.438701000296
                        ],
                        [
                            -95.6677139996719,
                            40.4534630002802
                        ],
                        [
                            -95.6875319999545,
                            40.4652060003397
                        ],
                        [
                            -95.6946510003591,
                            40.4714519998188
                        ],
                        [
                            -95.6967560001065,
                            40.4788489999611
                        ],
                        [
                            -95.6948229998882,
                            40.4947049998023
                        ],
                        [
                            -95.6999689998916,
                            40.5052749999994
                        ],
                        [
                            -95.6616809995637,
                            40.5173119997571
                        ],
                        [
                            -95.655674000293,
                            40.523557000114
                        ],
                        [
                            -95.652268999989,
                            40.5381080002805
                        ],
                        [
                            -95.6558480003703,
                            40.54660899984
                        ],
                        [
                            -95.6621070004343,
                            40.5499670000353
                        ],
                        [
                            -95.6654950001216,
                            40.5566969999247
                        ],
                        [
                            -95.671775999927,
                            40.5626279998474
                        ],
                        [
                            -95.6787150004065,
                            40.5625609999768
                        ],
                        [
                            -95.6870209995933,
                            40.5606689998416
                        ],
                        [
                            -95.6941469996706,
                            40.5569420002552
                        ],
                        [
                            -95.6973770000725,
                            40.5413889998076
                        ],
                        [
                            -95.6954949999079,
                            40.5320209997556
                        ],
                        [
                            -95.6972020001703,
                            40.5284750001535
                        ],
                        [
                            -95.7085910003442,
                            40.5215510000246
                        ],
                        [
                            -95.7128619999383,
                            40.5263840002032
                        ],
                        [
                            -95.7175369997636,
                            40.5277830002899
                        ],
                        [
                            -95.725214000385,
                            40.527772999981
                        ],
                        [
                            -95.7372499995658,
                            40.5239300000853
                        ],
                        [
                            -95.7571099996812,
                            40.5259900001187
                        ],
                        [
                            -95.7669199996392,
                            40.5315629999313
                        ],
                        [
                            -95.7692810003779,
                            40.5366559998156
                        ],
                        [
                            -95.7633660002705,
                            40.5507970002607
                        ],
                        [
                            -95.7650299999769,
                            40.5569210002815
                        ],
                        [
                            -95.7717760001127,
                            40.5661330001567
                        ],
                        [
                            -95.7747040003908,
                            40.5735740001705
                        ],
                        [
                            -95.773548999599,
                            40.5782050002475
                        ],
                        [
                            -95.7685270003425,
                            40.5832959999269
                        ],
                        [
                            -95.7656450000952,
                            40.585208000144
                        ],
                        [
                            -95.6479730002805,
                            40.5843980002736
                        ],
                        [
                            -94.5941959998283,
                            40.5709599997203
                        ],
                        [
                            -94.2704560002722,
                            40.5715309998938
                        ],
                        [
                            -93.5475779999637,
                            40.5804070000321
                        ],
                        [
                            -93.2604289996205,
                            40.5808139998214
                        ],
                        [
                            -92.9434720000101,
                            40.5877619999698
                        ],
                        [
                            -92.6866930002473,
                            40.5898090000977
                        ],
                        [
                            -92.4852159997672,
                            40.5950850000356
                        ],
                        [
                            -91.9477090001069,
                            40.6054710002751
                        ],
                        [
                            -91.7291149998416,
                            40.6136399999538
                        ],
                        [
                            -91.716769000141,
                            40.5985300000903
                        ],
                        [
                            -91.6963590002047,
                            40.5881480003042
                        ],
                        [
                            -91.6863569997279,
                            40.5808750000442
                        ],
                        [
                            -91.6857230002414,
                            40.5767849998758
                        ],
                        [
                            -91.6915610003559,
                            40.564867000101
                        ],
                        [
                            -91.6908039999474,
                            40.5598929999247
                        ],
                        [
                            -91.688700000025,
                            40.5573899999449
                        ],
                        [
                            -91.6817139996897,
                            40.5530349997334
                        ],
                        [
                            -91.6327829997257,
                            40.5450290003243
                        ],
                        [
                            -91.6200710003273,
                            40.5408169997646
                        ],
                        [
                            -91.6180279996058,
                            40.5340299998135
                        ],
                        [
                            -91.6187930004102,
                            40.5262859997088
                        ],
                        [
                            -91.6221919999682,
                            40.5170389999025
                        ],
                        [
                            -91.6213530004423,
                            40.5100720001901
                        ],
                        [
                            -91.6128210004014,
                            40.5023769998144
                        ],
                        [
                            -91.5908170001805,
                            40.4922920002086
                        ],
                        [
                            -91.5868839997969,
                            40.4872330000601
                        ],
                        [
                            -91.5815279997312,
                            40.4728759999777
                        ],
                        [
                            -91.5747459996104,
                            40.4656640002793
                        ],
                        [
                            -91.5638440004066,
                            40.4609880001025
                        ],
                        [
                            -91.5526910002343,
                            40.4587690001496
                        ],
                        [
                            -91.5285999998094,
                            40.4590020000923
                        ],
                        [
                            -91.5250899999233,
                            40.4578450003409
                        ],
                        [
                            -91.5232710004062,
                            40.4500610001265
                        ],
                        [
                            -91.531912000227,
                            40.4427300000023
                        ],
                        [
                            -91.5336229997891,
                            40.4383200001532
                        ],
                        [
                            -91.5291320000869,
                            40.4342719999476
                        ],
                        [
                            -91.519133999808,
                            40.4328219996682
                        ],
                        [
                            -91.5194920000083,
                            40.4299510001951
                        ],
                        [
                            -91.5270430002331,
                            40.4182140001118
                        ],
                        [
                            -91.5246120000724,
                            40.4107649997486
                        ],
                        [
                            -91.5139929998275,
                            40.4085370001907
                        ],
                        [
                            -91.5052719996407,
                            40.4035119999479
                        ],
                        [
                            -91.49809299986,
                            40.4019259999889
                        ],
                        [
                            -91.4884810002687,
                            40.4043169996779
                        ],
                        [
                            -91.4909120004294,
                            40.392980000091
                        ],
                        [
                            -91.4852550000648,
                            40.3846570001955
                        ],
                        [
                            -91.4802510003516,
                            40.3817829997038
                        ],
                        [
                            -91.4635539997927,
                            40.3855469996863
                        ],
                        [
                            -91.465891000242,
                            40.378365000067
                        ],
                        [
                            -91.4638950002745,
                            40.3756589997128
                        ],
                        [
                            -91.4487419997714,
                            40.3768039999374
                        ],
                        [
                            -91.4453709998025,
                            40.3793879999484
                        ],
                        [
                            -91.4451680003112,
                            40.3824609997527
                        ],
                        [
                            -91.4412430003235,
                            40.3862550000441
                        ],
                        [
                            -91.4256620001982,
                            40.3824909999363
                        ],
                        [
                            -91.4194220004007,
                            40.3782640003266
                        ],
                        [
                            -91.4266320001436,
                            40.3719880001532
                        ],
                        [
                            -91.4448619999895,
                            40.3631430000951
                        ],
                        [
                            -91.4621400003315,
                            40.3424139999318
                        ],
                        [
                            -91.4666030004446,
                            40.3344610002286
                        ],
                        [
                            -91.4718260004409,
                            40.3173400001736
                        ],
                        [
                            -91.4824499998104,
                            40.300583000174
                        ],
                        [
                            -91.4909709999806,
                            40.2833639998151
                        ],
                        [
                            -91.4930609996594,
                            40.2752619997159
                        ],
                        [
                            -91.4905240000919,
                            40.2594979999636
                        ],
                        [
                            -91.4981039997306,
                            40.2474219996762
                        ],
                        [
                            -91.5032310003658,
                            40.2434739996977
                        ],
                        [
                            -91.5065010000523,
                            40.2363039998996
                        ],
                        [
                            -91.5042890001748,
                            40.2317120002679
                        ],
                        [
                            -91.5042819996038,
                            40.2242989998993
                        ],
                        [
                            -91.5072690003314,
                            40.209338000139
                        ],
                        [
                            -91.5044769995974,
                            40.1982619999288
                        ],
                        [
                            -91.5110729999453,
                            40.1887940002148
                        ],
                        [
                            -91.5130789999585,
                            40.1785370000918
                        ],
                        [
                            -91.5082240002083,
                            40.1576649998988
                        ],
                        [
                            -91.5115900001543,
                            40.1492689998231
                        ],
                        [
                            -91.5108389995936,
                            40.1261369996846
                        ],
                        [
                            -91.5014339996916,
                            40.0958519998463
                        ],
                        [
                            -91.4976629996897,
                            40.0782569996716
                        ],
                        [
                            -91.4896060000249,
                            40.0574349997096
                        ],
                        [
                            -91.494878000425,
                            40.0364530002442
                        ],
                        [
                            -91.4692470001422,
                            39.9953269999104
                        ],
                        [
                            -91.4653149995835,
                            39.9839949998242
                        ],
                        [
                            -91.4588520002033,
                            39.979014999847
                        ],
                        [
                            -91.4415599996176,
                            39.9512989996562
                        ],
                        [
                            -91.4257819998488,
                            39.9377650002898
                        ],
                        [
                            -91.4193599995781,
                            39.9277169998634
                        ],
                        [
                            -91.4188070002825,
                            39.9221260000598
                        ],
                        [
                            -91.4208779996947,
                            39.9148650003031
                        ],
                        [
                            -91.4435129999273,
                            39.8935830002523
                        ],
                        [
                            -91.4469220004293,
                            39.8830339998657
                        ],
                        [
                            -91.4478439997959,
                            39.877950999813
                        ],
                        [
                            -91.4466849997046,
                            39.871641999668
                        ],
                        [
                            -91.4413429998826,
                            39.8619830000638
                        ],
                        [
                            -91.4365290001402,
                            39.8470780000221
                        ],
                        [
                            -91.4337980004039,
                            39.8418649999639
                        ],
                        [
                            -91.4284690001024,
                            39.837625999971
                        ],
                        [
                            -91.3764879996616,
                            39.8100330000881
                        ],
                        [
                            -91.3679659996664,
                            39.8004030002937
                        ],
                        [
                            -91.3623879998429,
                            39.7904820000941
                        ],
                        [
                            -91.3617440003107,
                            39.7850789996658
                        ],
                        [
                            -91.3656940004128,
                            39.774910000081
                        ],
                        [
                            -91.365280000136,
                            39.7577220000193
                        ],
                        [
                            -91.3699530003114,
                            39.7450420000228
                        ],
                        [
                            -91.3693950000945,
                            39.73116299999
                        ],
                        [
                            -91.3472529997814,
                            39.7104799998074
                        ],
                        [
                            -91.3178139996868,
                            39.6925909996652
                        ],
                        [
                            -91.3053480003356,
                            39.6839569999529
                        ],
                        [
                            -91.3016030002729,
                            39.6790679997885
                        ],
                        [
                            -91.2761400002196,
                            39.6657590000874
                        ],
                        [
                            -91.2604750004287,
                            39.6490240002323
                        ],
                        [
                            -91.2487790001082,
                            39.6408800001904
                        ],
                        [
                            -91.2412250004087,
                            39.6300670000449
                        ],
                        [
                            -91.2293170003761,
                            39.6208530001609
                        ],
                        [
                            -91.1819360003157,
                            39.602676999889
                        ],
                        [
                            -91.1746510002297,
                            39.5933130002963
                        ],
                        [
                            -91.1684189999299,
                            39.5649279996813
                        ],
                        [
                            -91.153627999825,
                            39.5482480002082
                        ],
                        [
                            -91.1465960003573,
                            39.5454859999129
                        ],
                        [
                            -91.1003070004411,
                            39.5386950002371
                        ],
                        [
                            -91.07976899956,
                            39.50772799978
                        ],
                        [
                            -91.0643049996105,
                            39.4946429996587
                        ],
                        [
                            -91.0624140001234,
                            39.4741219997812
                        ],
                        [
                            -91.0594389999896,
                            39.4688600003262
                        ],
                        [
                            -91.038269999995,
                            39.4484350002176
                        ],
                        [
                            -91.0236100003097,
                            39.43869399981
                        ],
                        [
                            -90.9937889997253,
                            39.422958999954
                        ],
                        [
                            -90.9776179995961,
                            39.4182900003158
                        ],
                        [
                            -90.9674799995751,
                            39.4119480000152
                        ],
                        [
                            -90.9482990000504,
                            39.40750199972
                        ],
                        [
                            -90.9407660002673,
                            39.4039839997263
                        ],
                        [
                            -90.9374189996895,
                            39.4008029996924
                        ],
                        [
                            -90.9340069997128,
                            39.3921269998699
                        ],
                        [
                            -90.9287450002567,
                            39.3875439997616
                        ],
                        [
                            -90.9209759995738,
                            39.3836870000604
                        ],
                        [
                            -90.9048620002443,
                            39.379403000178
                        ],
                        [
                            -90.9000950003576,
                            39.3723539998751
                        ],
                        [
                            -90.893776999844,
                            39.3673430003002
                        ],
                        [
                            -90.8474999996234,
                            39.3452719999429
                        ],
                        [
                            -90.8168510002821,
                            39.3204960000123
                        ],
                        [
                            -90.7934610001892,
                            39.3094979999913
                        ],
                        [
                            -90.7906750002013,
                            39.3029079997154
                        ],
                        [
                            -90.7756730002092,
                            39.2928110001498
                        ],
                        [
                            -90.7676480003315,
                            39.2800249996866
                        ],
                        [
                            -90.7515990004011,
                            39.2654320002169
                        ],
                        [
                            -90.7390870001208,
                            39.2618930002452
                        ],
                        [
                            -90.7299600000532,
                            39.2558940001562
                        ],
                        [
                            -90.7269809997215,
                            39.2511730000926
                        ],
                        [
                            -90.7215929998687,
                            39.2327300001859
                        ],
                        [
                            -90.7218349997181,
                            39.2241080001047
                        ],
                        [
                            -90.7171130000372,
                            39.2139120002099
                        ],
                        [
                            -90.7174040002903,
                            39.1974139997111
                        ],
                        [
                            -90.7104799998794,
                            39.1763660003361
                        ],
                        [
                            -90.707902000304,
                            39.1508600001438
                        ],
                        [
                            -90.7029229998068,
                            39.1387490000118
                        ],
                        [
                            -90.686051000244,
                            39.1177850001616
                        ],
                        [
                            -90.6810859999906,
                            39.1005900003334
                        ],
                        [
                            -90.6827439998492,
                            39.0883480002303
                        ],
                        [
                            -90.6982820003168,
                            39.0746489998775
                        ],
                        [
                            -90.7021360001595,
                            39.0655679998059
                        ],
                        [
                            -90.7125410001442,
                            39.0570639996715
                        ],
                        [
                            -90.7136290000904,
                            39.0539769998358
                        ],
                        [
                            -90.7115800004195,
                            39.046797999801
                        ],
                        [
                            -90.6924030001944,
                            39.0166560002613
                        ],
                        [
                            -90.6877190001484,
                            39.0053739999624
                        ],
                        [
                            -90.6781929998726,
                            38.9918510002683
                        ],
                        [
                            -90.6757149998563,
                            38.9810659999149
                        ],
                        [
                            -90.675949000208,
                            38.9621400003472
                        ],
                        [
                            -90.6680890001868,
                            38.9430029999392
                        ],
                        [
                            -90.6634240004073,
                            38.9355510002783
                        ],
                        [
                            -90.6630179996281,
                            38.926283000329
                        ],
                        [
                            -90.647290000088,
                            38.9120950001198
                        ],
                        [
                            -90.6369939998833,
                            38.9051229997821
                        ],
                        [
                            -90.6284850003069,
                            38.8916169998095
                        ],
                        [
                            -90.623356000022,
                            38.8871490000788
                        ],
                        [
                            -90.5833880000941,
                            38.8690299998933
                        ],
                        [
                            -90.5610209996501,
                            38.8694759998762
                        ],
                        [
                            -90.546831000318,
                            38.8740089999617
                        ],
                        [
                            -90.516962999878,
                            38.8988179998843
                        ],
                        [
                            -90.5074509998459,
                            38.9027669999649
                        ],
                        [
                            -90.487057999628,
                            38.9258619999408
                        ],
                        [
                            -90.4827250001096,
                            38.9347119999068
                        ],
                        [
                            -90.4824189997878,
                            38.9444599998914
                        ],
                        [
                            -90.4721219997581,
                            38.9588379996635
                        ],
                        [
                            -90.4677840002168,
                            38.9618090000183
                        ],
                        [
                            -90.4508130000215,
                            38.9677690001559
                        ],
                        [
                            -90.4392029999147,
                            38.9672870001811
                        ],
                        [
                            -90.429779999571,
                            38.9637780001614
                        ],
                        [
                            -90.4063669999119,
                            38.9625540002151
                        ],
                        [
                            -90.3831259997818,
                            38.9554529999547
                        ],
                        [
                            -90.3094540000129,
                            38.9241200000494
                        ],
                        [
                            -90.2774709997797,
                            38.9237160002197
                        ],
                        [
                            -90.2627919998278,
                            38.9203439997417
                        ],
                        [
                            -90.2502479997605,
                            38.9193440002194
                        ],
                        [
                            -90.2230409996349,
                            38.9073889997132
                        ],
                        [
                            -90.197610000267,
                            38.8876479998369
                        ],
                        [
                            -90.1869090000063,
                            38.8850480002202
                        ],
                        [
                            -90.1664089996583,
                            38.8763480003126
                        ],
                        [
                            -90.1515079999485,
                            38.8671479999424
                        ],
                        [
                            -90.1133269997507,
                            38.8493059999505
                        ],
                        [
                            -90.110206999852,
                            38.8447479998035
                        ],
                        [
                            -90.1091070002102,
                            38.8374480002152
                        ],
                        [
                            -90.114706999775,
                            38.8150479998291
                        ],
                        [
                            -90.1177070000231,
                            38.805748000048
                        ],
                        [
                            -90.1231069995714,
                            38.7980479999995
                        ],
                        [
                            -90.1663640003508,
                            38.7726750001447
                        ],
                        [
                            -90.1749769996842,
                            38.7601289996501
                        ],
                        [
                            -90.175903000147,
                            38.7550289997848
                        ],
                        [
                            -90.1791129995591,
                            38.7502690002427
                        ],
                        [
                            -90.1977590002299,
                            38.7394820002086
                        ],
                        [
                            -90.2034049998255,
                            38.7348809998971
                        ],
                        [
                            -90.2099099999368,
                            38.7260499996848
                        ],
                        [
                            -90.2129799996068,
                            38.711988000327
                        ],
                        [
                            -90.2102399996497,
                            38.7044010002491
                        ],
                        [
                            -90.2060209999341,
                            38.6974040001915
                        ],
                        [
                            -90.1941000003809,
                            38.6859369996671
                        ],
                        [
                            -90.1878060001568,
                            38.6775899998708
                        ],
                        [
                            -90.179309000276,
                            38.6524399997133
                        ],
                        [
                            -90.1778969995664,
                            38.6337759998207
                        ],
                        [
                            -90.1852560001705,
                            38.6108150000408
                        ],
                        [
                            -90.1958069997451,
                            38.5945270003016
                        ],
                        [
                            -90.2031849997175,
                            38.5881259999229
                        ],
                        [
                            -90.2230599999015,
                            38.5757320002926
                        ],
                        [
                            -90.2323200000385,
                            38.5667199998508
                        ],
                        [
                            -90.2412720002039,
                            38.5531910000678
                        ],
                        [
                            -90.2471870003114,
                            38.5485570002833
                        ],
                        [
                            -90.2640560003995,
                            38.5210250002864
                        ],
                        [
                            -90.2785310001369,
                            38.4776820001483
                        ],
                        [
                            -90.2840429998382,
                            38.4516740001617
                        ],
                        [
                            -90.291084000425,
                            38.4308899998028
                        ],
                        [
                            -90.2968699997627,
                            38.4248079999974
                        ],
                        [
                            -90.3111699995979,
                            38.4156790003439
                        ],
                        [
                            -90.3258390004024,
                            38.4009630001206
                        ],
                        [
                            -90.3414459995686,
                            38.3882979997272
                        ],
                        [
                            -90.3506200003902,
                            38.3747620000363
                        ],
                        [
                            -90.3559420001207,
                            38.3604600001781
                        ],
                        [
                            -90.3643610001837,
                            38.3475139998785
                        ],
                        [
                            -90.3708190004393,
                            38.3335540001427
                        ],
                        [
                            -90.3726260002609,
                            38.316161999794
                        ],
                        [
                            -90.371515999675,
                            38.3061909999677
                        ],
                        [
                            -90.3724220000464,
                            38.2837249999881
                        ],
                        [
                            -90.36694799998,
                            38.2483439999776
                        ],
                        [
                            -90.3586279996512,
                            38.2219809998858
                        ],
                        [
                            -90.3523619999145,
                            38.2118279997596
                        ],
                        [
                            -90.330170000271,
                            38.1865230003139
                        ],
                        [
                            -90.3203810002294,
                            38.1808199997684
                        ],
                        [
                            -90.3003289995951,
                            38.1751389996983
                        ],
                        [
                            -90.2906290001403,
                            38.1703630002023
                        ],
                        [
                            -90.2748429999756,
                            38.1575590001297
                        ],
                        [
                            -90.2501180000642,
                            38.1250540003235
                        ],
                        [
                            -90.2425740004105,
                            38.1121250003184
                        ],
                        [
                            -90.2232880004054,
                            38.0977990000377
                        ],
                        [
                            -90.1760400004144,
                            38.0706599997142
                        ],
                        [
                            -90.1722200000086,
                            38.0696360001918
                        ],
                        [
                            -90.1634129996081,
                            38.0743459996914
                        ],
                        [
                            -90.1582730003508,
                            38.0749500000018
                        ],
                        [
                            -90.1295999999872,
                            38.0614359999185
                        ],
                        [
                            -90.1263019998132,
                            38.0544320003477
                        ],
                        [
                            -90.1261960004063,
                            38.0407060003213
                        ],
                        [
                            -90.1105209996714,
                            38.0265489997577
                        ],
                        [
                            -90.0882600004309,
                            38.0157720003245
                        ],
                        [
                            -90.0722829995721,
                            38.0170009996808
                        ],
                        [
                            -90.0593669999592,
                            38.0155429998241
                        ],
                        [
                            -90.0553990003137,
                            38.0119519997716
                        ],
                        [
                            -90.0519410003062,
                            38.0054319999219
                        ],
                        [
                            -90.0317949999605,
                            37.9951259998906
                        ],
                        [
                            -90.0079360002375,
                            37.9701099999651
                        ],
                        [
                            -89.9968480003626,
                            37.9632190003263
                        ],
                        [
                            -89.9881079999092,
                            37.9617490000717
                        ],
                        [
                            -89.9650459998793,
                            37.9643929999938
                        ],
                        [
                            -89.9406869996658,
                            37.9708740000968
                        ],
                        [
                            -89.935884999619,
                            37.9595809999041
                        ],
                        [
                            -89.9245969997275,
                            37.9598489996542
                        ],
                        [
                            -89.9248529998205,
                            37.954905999708
                        ],
                        [
                            -89.9312669996952,
                            37.9482770001559
                        ],
                        [
                            -89.9373199998949,
                            37.946662999889
                        ],
                        [
                            -89.9473220003718,
                            37.9402829998158
                        ],
                        [
                            -89.9597719998294,
                            37.9400829996829
                        ],
                        [
                            -89.9620570004002,
                            37.9339179999707
                        ],
                        [
                            -89.9746540001709,
                            37.9270740001409
                        ],
                        [
                            -89.9737620000432,
                            37.9180160003483
                        ],
                        [
                            -89.9663289998193,
                            37.9067310003259
                        ],
                        [
                            -89.9652850002539,
                            37.9023030001564
                        ],
                        [
                            -89.9605419997583,
                            37.8973690000322
                        ],
                        [
                            -89.9530080001503,
                            37.8840369996846
                        ],
                        [
                            -89.9477580003899,
                            37.8798470002814
                        ],
                        [
                            -89.9376899997908,
                            37.8750979998422
                        ],
                        [
                            -89.9386079998577,
                            37.8743039999922
                        ],
                        [
                            -89.9233749998869,
                            37.8706970002057
                        ],
                        [
                            -89.9023769995966,
                            37.8697399996572
                        ],
                        [
                            -89.8796879998356,
                            37.8807360003372
                        ],
                        [
                            -89.8618770002894,
                            37.8976839999366
                        ],
                        [
                            -89.8570710000447,
                            37.8992039998366
                        ],
                        [
                            -89.8505240001005,
                            37.9041720002996
                        ],
                        [
                            -89.8426380001401,
                            37.9051969998045
                        ],
                        [
                            -89.8136359998884,
                            37.8876959996798
                        ],
                        [
                            -89.7993290003805,
                            37.8815159999491
                        ],
                        [
                            -89.7976959997379,
                            37.8743919998865
                        ],
                        [
                            -89.8003529998543,
                            37.8686259998379
                        ],
                        [
                            -89.7975419997521,
                            37.8621279997208
                        ],
                        [
                            -89.7937370003133,
                            37.8570629996782
                        ],
                        [
                            -89.7863259998306,
                            37.8516889996624
                        ],
                        [
                            -89.781990999764,
                            37.8550530000035
                        ],
                        [
                            -89.77430499982,
                            37.8521270002357
                        ],
                        [
                            -89.765200000392,
                            37.8517629999484
                        ],
                        [
                            -89.7540990000982,
                            37.8463530000448
                        ],
                        [
                            -89.739875000431,
                            37.8469210001476
                        ],
                        [
                            -89.7294310000882,
                            37.835113999777
                        ],
                        [
                            -89.7174419998647,
                            37.8257229996574
                        ],
                        [
                            -89.6696440000528,
                            37.7999219998894
                        ],
                        [
                            -89.6603799997178,
                            37.7862960000788
                        ],
                        [
                            -89.6611899998297,
                            37.7757319997394
                        ],
                        [
                            -89.6679929998669,
                            37.7594840002749
                        ],
                        [
                            -89.6655459998127,
                            37.7520950001305
                        ],
                        [
                            -89.6633520003769,
                            37.7500519998822
                        ],
                        [
                            -89.6495300003925,
                            37.7454959997653
                        ],
                        [
                            -89.633370000134,
                            37.7457820000889
                        ],
                        [
                            -89.6263319999202,
                            37.7490460000324
                        ],
                        [
                            -89.6172779996475,
                            37.7497199998579
                        ],
                        [
                            -89.6157439996374,
                            37.7427249998166
                        ],
                        [
                            -89.5974810001796,
                            37.7334769998516
                        ],
                        [
                            -89.5833160000636,
                            37.7132610000982
                        ],
                        [
                            -89.5667039998935,
                            37.7071890002682
                        ],
                        [
                            -89.5314269996843,
                            37.7003340002885
                        ],
                        [
                            -89.5164690000731,
                            37.6925389997501
                        ],
                        [
                            -89.5120090003331,
                            37.6855250001897
                        ],
                        [
                            -89.5120400002952,
                            37.6809850002003
                        ],
                        [
                            -89.5161460000329,
                            37.6679750003036
                        ],
                        [
                            -89.5158599998027,
                            37.6455550000684
                        ],
                        [
                            -89.5177179996779,
                            37.6412170002108
                        ],
                        [
                            -89.5156489999155,
                            37.6364450003282
                        ],
                        [
                            -89.5105260003766,
                            37.6317540002814
                        ],
                        [
                            -89.5065629998557,
                            37.6250500002313
                        ],
                        [
                            -89.4857920002444,
                            37.6071569997342
                        ],
                        [
                            -89.4783990002034,
                            37.5988689998193
                        ],
                        [
                            -89.4759320000578,
                            37.592998000082
                        ],
                        [
                            -89.4775480000836,
                            37.5858850001479
                        ],
                        [
                            -89.4860619996828,
                            37.5808530000116
                        ],
                        [
                            -89.4940509995755,
                            37.5801159996697
                        ],
                        [
                            -89.5139430003763,
                            37.5848150000318
                        ],
                        [
                            -89.519808000255,
                            37.5827469999016
                        ],
                        [
                            -89.5212739995947,
                            37.5789700003346
                        ],
                        [
                            -89.5219249996979,
                            37.560735000136
                        ],
                        [
                            -89.5170509996811,
                            37.5372779998306
                        ],
                        [
                            -89.5029170004255,
                            37.5178700000796
                        ],
                        [
                            -89.4920510003084,
                            37.4940079996763
                        ],
                        [
                            -89.475525000352,
                            37.4713879999944
                        ],
                        [
                            -89.4509689995967,
                            37.450069000059
                        ],
                        [
                            -89.4397689995687,
                            37.4371999997878
                        ],
                        [
                            -89.4265220004179,
                            37.40899799967
                        ],
                        [
                            -89.4209389996732,
                            37.3939519999058
                        ],
                        [
                            -89.4205420000132,
                            37.3880069999287
                        ],
                        [
                            -89.4281850002994,
                            37.3561579998683
                        ],
                        [
                            -89.4328359998352,
                            37.3470560002541
                        ],
                        [
                            -89.4360400002978,
                            37.3444410003111
                        ],
                        [
                            -89.447555999795,
                            37.3404749997787
                        ],
                        [
                            -89.4745689997519,
                            37.3381650002847
                        ],
                        [
                            -89.4890050000295,
                            37.3333679996826
                        ],
                        [
                            -89.4951600003365,
                            37.324795000293
                        ],
                        [
                            -89.51075999983,
                            37.3127439997175
                        ],
                        [
                            -89.5183930000706,
                            37.2893539997462
                        ],
                        [
                            -89.5176699999973,
                            37.2831720002288
                        ],
                        [
                            -89.5092619998049,
                            37.2713710001826
                        ],
                        [
                            -89.4899149997005,
                            37.2513150000772
                        ],
                        [
                            -89.4705249999384,
                            37.2533570001477
                        ],
                        [
                            -89.4626599998943,
                            37.2515199998515
                        ],
                        [
                            -89.4588269999681,
                            37.2486610001411
                        ],
                        [
                            -89.4583020002615,
                            37.2403679998296
                        ],
                        [
                            -89.467499999576,
                            37.2218439998232
                        ],
                        [
                            -89.4676309999955,
                            37.2182000000914
                        ],
                        [
                            -89.4618620003763,
                            37.1995169999657
                        ],
                        [
                            -89.4561049995543,
                            37.1881199999209
                        ],
                        [
                            -89.4382749997416,
                            37.1612869997507
                        ],
                        [
                            -89.4352019996985,
                            37.152090000102
                        ],
                        [
                            -89.4255800000615,
                            37.1382350002705
                        ],
                        [
                            -89.4117299995898,
                            37.1225070002876
                        ],
                        [
                            -89.388049999967,
                            37.1074810001558
                        ],
                        [
                            -89.3787100003623,
                            37.0945859998576
                        ],
                        [
                            -89.3756149996796,
                            37.0859360002228
                        ],
                        [
                            -89.375711999764,
                            37.0805049997254
                        ],
                        [
                            -89.3854339998584,
                            37.055130000086
                        ],
                        [
                            -89.3839369996582,
                            37.0464410002232
                        ],
                        [
                            -89.378276999819,
                            37.0396050000563
                        ],
                        [
                            -89.3623969999429,
                            37.0301559998574
                        ],
                        [
                            -89.3459959996599,
                            37.0255210000918
                        ],
                        [
                            -89.322982000209,
                            37.0160899998987
                        ],
                        [
                            -89.2921299995797,
                            36.9921890000073
                        ],
                        [
                            -89.2786280001608,
                            36.9886699999575
                        ],
                        [
                            -89.2695639998424,
                            36.9934010002639
                        ],
                        [
                            -89.2635270004345,
                            37.0000499999421
                        ],
                        [
                            -89.2576080001292,
                            37.0154960002138
                        ],
                        [
                            -89.2600030003048,
                            37.023287999841
                        ],
                        [
                            -89.2777150001168,
                            37.0361400001324
                        ],
                        [
                            -89.2911849997486,
                            37.0404080000901
                        ],
                        [
                            -89.3047520003631,
                            37.0475649997291
                        ],
                        [
                            -89.3108189999082,
                            37.057897000206
                        ],
                        [
                            -89.3082899998383,
                            37.0683709997056
                        ],
                        [
                            -89.3077259997736,
                            37.0696540000608
                        ],
                        [
                            -89.2803620001875,
                            37.0652219999298
                        ],
                        [
                            -89.2599349996344,
                            37.0640699998297
                        ],
                        [
                            -89.2549300000963,
                            37.0720140003566
                        ],
                        [
                            -89.2340530001798,
                            37.0372769999839
                        ],
                        [
                            -89.205052999578,
                            37.0200570003475
                        ],
                        [
                            -89.2007929998545,
                            37.0161639996525
                        ],
                        [
                            -89.1950290002581,
                            37.0000510000339
                        ],
                        [
                            -89.1950390003038,
                            36.9897680003519
                        ],
                        [
                            -89.1920969997821,
                            36.9799949996853
                        ],
                        [
                            -89.1854910002867,
                            36.9735179999985
                        ],
                        [
                            -89.1700089998956,
                            36.9702979997375
                        ],
                        [
                            -89.1498819998165,
                            36.9776359997738
                        ],
                        [
                            -89.1253519998988,
                            36.9835290002458
                        ],
                        [
                            -89.1134940000949,
                            36.9794279997562
                        ],
                        [
                            -89.102541999764,
                            36.9690979998404
                        ],
                        [
                            -89.0998029996318,
                            36.9646689999823
                        ],
                        [
                            -89.0989830003725,
                            36.9582220001787
                        ],
                        [
                            -89.1016439997885,
                            36.9407280000687
                        ],
                        [
                            -89.1088779998209,
                            36.9216100002603
                        ],
                        [
                            -89.117115000309,
                            36.8886849999798
                        ],
                        [
                            -89.1267739997559,
                            36.871899999763
                        ],
                        [
                            -89.131934000003,
                            36.8575960001486
                        ],
                        [
                            -89.1381090004014,
                            36.8473229998293
                        ],
                        [
                            -89.1526810002232,
                            36.8471050002542
                        ],
                        [
                            -89.1590049996862,
                            36.8427289997103
                        ],
                        [
                            -89.1665030002075,
                            36.8428940003578
                        ],
                        [
                            -89.1719000002811,
                            36.84110600013
                        ],
                        [
                            -89.1779199999705,
                            36.8352189999606
                        ],
                        [
                            -89.1792010002606,
                            36.8298959996729
                        ],
                        [
                            -89.1791329995902,
                            36.8108929997608
                        ],
                        [
                            -89.1705629999145,
                            36.7975379997026
                        ],
                        [
                            -89.1571369997653,
                            36.7895689998205
                        ],
                        [
                            -89.132186999723,
                            36.7882569999521
                        ],
                        [
                            -89.1243800003035,
                            36.7858259997262
                        ],
                        [
                            -89.1203969996911,
                            36.7817330002924
                        ],
                        [
                            -89.1162430002728,
                            36.7732920003055
                        ],
                        [
                            -89.1182020004303,
                            36.762325999803
                        ],
                        [
                            -89.1217750000656,
                            36.7556700000649
                        ],
                        [
                            -89.125967000017,
                            36.751809000305
                        ],
                        [
                            -89.1357889996705,
                            36.7517769996987
                        ],
                        [
                            -89.1425020001943,
                            36.7555029998502
                        ],
                        [
                            -89.1585539995995,
                            36.7562290000734
                        ],
                        [
                            -89.1666110001625,
                            36.7596699997359
                        ],
                        [
                            -89.172886999945,
                            36.7587710000217
                        ],
                        [
                            -89.1853199996842,
                            36.7533489996729
                        ],
                        [
                            -89.1978560002539,
                            36.7397720002117
                        ],
                        [
                            -89.2013999995768,
                            36.7258810000856
                        ],
                        [
                            -89.1993109997229,
                            36.7156240003514
                        ],
                        [
                            -89.182952999996,
                            36.6995520000944
                        ],
                        [
                            -89.1704619996321,
                            36.6900480000458
                        ],
                        [
                            -89.1681100000125,
                            36.6857289998941
                        ],
                        [
                            -89.1679710000954,
                            36.6790889996876
                        ],
                        [
                            -89.1718840003875,
                            36.6725980001854
                        ],
                        [
                            -89.1591179996641,
                            36.6663539998087
                        ],
                        [
                            -89.1747270002768,
                            36.6504749998381
                        ],
                        [
                            -89.1868560002424,
                            36.6420220002535
                        ],
                        [
                            -89.1977530003217,
                            36.6289709999555
                        ],
                        [
                            -89.2012649998576,
                            36.6165449999948
                        ],
                        [
                            -89.2027710002786,
                            36.6016649997396
                        ],
                        [
                            -89.2111839995954,
                            36.584058000146
                        ],
                        [
                            -89.2190350002942,
                            36.5750570002031
                        ],
                        [
                            -89.2270459999282,
                            36.5695370000424
                        ],
                        [
                            -89.236307999715,
                            36.5668810002663
                        ],
                        [
                            -89.2565379997262,
                            36.5649609999616
                        ],
                        [
                            -89.2634899997263,
                            36.5662690003318
                        ],
                        [
                            -89.2779429997224,
                            36.5765879999592
                        ],
                        [
                            -89.2946750004415,
                            36.5937789999395
                        ],
                        [
                            -89.3146569998573,
                            36.6218439996642
                        ],
                        [
                            -89.3246369996945,
                            36.6310229997461
                        ],
                        [
                            -89.3320339999335,
                            36.6328810000169
                        ],
                        [
                            -89.3439019997832,
                            36.6318880002906
                        ],
                        [
                            -89.3657970002241,
                            36.6251259997737
                        ],
                        [
                            -89.3719149998228,
                            36.6201960000666
                        ],
                        [
                            -89.3761439995841,
                            36.6141319999296
                        ],
                        [
                            -89.3827099997948,
                            36.5837219999881
                        ],
                        [
                            -89.3958329999953,
                            36.5593249999186
                        ],
                        [
                            -89.3986259996559,
                            36.5422759999338
                        ],
                        [
                            -89.4172749998014,
                            36.4990110000652
                        ],
                        [
                            -89.4328480004291,
                            36.4791870001434
                        ],
                        [
                            -89.4535139995601,
                            36.4617890000602
                        ],
                        [
                            -89.464433000279,
                            36.4577470001844
                        ],
                        [
                            -89.4766430004354,
                            36.4583930001424
                        ],
                        [
                            -89.4893609996817,
                            36.4645610003136
                        ],
                        [
                            -89.4939469998184,
                            36.4735590002455
                        ],
                        [
                            -89.493376999906,
                            36.4789590001873
                        ],
                        [
                            -89.4866869998468,
                            36.4949920002989
                        ],
                        [
                            -89.4732559996747,
                            36.5124159998464
                        ],
                        [
                            -89.4659129998624,
                            36.5300150002719
                        ],
                        [
                            -89.4655349995707,
                            36.5359889998362
                        ],
                        [
                            -89.4678200001414,
                            36.5469739996394
                        ],
                        [
                            -89.4728609996645,
                            36.558839999655
                        ],
                        [
                            -89.4799299998404,
                            36.5690869997394
                        ],
                        [
                            -89.4881060002292,
                            36.5729250001844
                        ],
                        [
                            -89.5168520003878,
                            36.5773969997326
                        ],
                        [
                            -89.5306380003871,
                            36.5817099999773
                        ],
                        [
                            -89.540853000401,
                            36.5810169998743
                        ],
                        [
                            -89.5511189995702,
                            36.5780740002014
                        ],
                        [
                            -89.5596339998926,
                            36.5727899998702
                        ],
                        [
                            -89.5660029995615,
                            36.5663989998039
                        ],
                        [
                            -89.5705100000555,
                            36.5583930001082
                        ],
                        [
                            -89.5712010003417,
                            36.551429000269
                        ],
                        [
                            -89.5604120002175,
                            36.5244060001512
                        ],
                        [
                            -89.5345240000168,
                            36.4914319999867
                        ],
                        [
                            -89.5226739997105,
                            36.4813050000071
                        ],
                        [
                            -89.5199220000578,
                            36.477052999825
                        ],
                        [
                            -89.5197200003914,
                            36.4670019999501
                        ],
                        [
                            -89.523426999921,
                            36.4565720002158
                        ],
                        [
                            -89.5272740000909,
                            36.4515450002008
                        ],
                        [
                            -89.543405999862,
                            36.4387700003507
                        ],
                        [
                            -89.545061000246,
                            36.4349149997603
                        ],
                        [
                            -89.5452550004147,
                            36.427079000283
                        ],
                        [
                            -89.5423370001824,
                            36.4201030000283
                        ],
                        [
                            -89.5139559998967,
                            36.3848910002174
                        ],
                        [
                            -89.5103799998884,
                            36.378355999696
                        ],
                        [
                            -89.5097220001125,
                            36.3736260001334
                        ],
                        [
                            -89.5131779995719,
                            36.3598969998308
                        ],
                        [
                            -89.5189999997929,
                            36.3485999999969
                        ],
                        [
                            -89.5318219996945,
                            36.3392460001634
                        ],
                        [
                            -89.5450059999944,
                            36.3368089997813
                        ],
                        [
                            -89.5604389999817,
                            36.3377459998622
                        ],
                        [
                            -89.5816359995654,
                            36.3423570002973
                        ],
                        [
                            -89.605668000439,
                            36.3422340002686
                        ],
                        [
                            -89.6106889998706,
                            36.3404419999384
                        ],
                        [
                            -89.6158409997218,
                            36.3360850001595
                        ],
                        [
                            -89.6198000000447,
                            36.3295460003538
                        ],
                        [
                            -89.6202550003294,
                            36.3230060000856
                        ],
                        [
                            -89.6118189996497,
                            36.3090880000642
                        ],
                        [
                            -89.58982000035,
                            36.2968139997317
                        ],
                        [
                            -89.5784920002755,
                            36.288316999728
                        ],
                        [
                            -89.5542960002686,
                            36.2777530000388
                        ],
                        [
                            -89.544796999757,
                            36.2804580003384
                        ],
                        [
                            -89.5394869997221,
                            36.2773680003541
                        ],
                        [
                            -89.5364410003415,
                            36.2728269999102
                        ],
                        [
                            -89.5346760003527,
                            36.2527709998054
                        ],
                        [
                            -89.5392289999792,
                            36.2488210002333
                        ],
                        [
                            -89.5447430002286,
                            36.2474840002193
                        ],
                        [
                            -89.5535630001496,
                            36.2503409996923
                        ],
                        [
                            -89.5622059996203,
                            36.2509090000381
                        ],
                        [
                            -89.5775440000713,
                            36.2422619998458
                        ],
                        [
                            -89.5873260004402,
                            36.2394840000614
                        ],
                        [
                            -89.6023739995647,
                            36.2381060002447
                        ],
                        [
                            -89.6111449999802,
                            36.2392709997977
                        ],
                        [
                            -89.6421819996591,
                            36.2494859998313
                        ],
                        [
                            -89.651406999636,
                            36.2506829997701
                        ],
                        [
                            -89.6780430000243,
                            36.2482870001655
                        ],
                        [
                            -89.6952350001526,
                            36.2527659998872
                        ],
                        [
                            -89.7018209995565,
                            36.2460040001694
                        ],
                        [
                            -89.7053279999678,
                            36.2398979998218
                        ],
                        [
                            -89.70525000015,
                            36.2365680000942
                        ],
                        [
                            -89.6926299999147,
                            36.2249590001768
                        ],
                        [
                            -89.6368940002638,
                            36.1889510002935
                        ],
                        [
                            -89.6182280003998,
                            36.1799660000141
                        ],
                        [
                            -89.6070040000824,
                            36.1711789999761
                        ],
                        [
                            -89.5935829999561,
                            36.1540050001142
                        ],
                        [
                            -89.5916320001945,
                            36.1421350003317
                        ],
                        [
                            -89.5935489996209,
                            36.1281379996703
                        ],
                        [
                            -89.6019359998968,
                            36.1194699996745
                        ],
                        [
                            -89.6283050003214,
                            36.1068529997718
                        ],
                        [
                            -89.666597999774,
                            36.0958020002264
                        ],
                        [
                            -89.6724629996527,
                            36.0918369999119
                        ],
                        [
                            -89.6788210003492,
                            36.0846360003597
                        ],
                        [
                            -89.6885769998805,
                            36.0292379999638
                        ],
                        [
                            -89.6924369995709,
                            36.0205069998186
                        ],
                        [
                            -89.7035710003749,
                            36.0080399996589
                        ],
                        [
                            -89.7069320002981,
                            36.0009810002107
                        ],
                        [
                            -90.3778900002651,
                            35.9956830002956
                        ],
                        [
                            -90.3643250001987,
                            36.014078999997
                        ],
                        [
                            -90.3569770003635,
                            36.0184860003039
                        ],
                        [
                            -90.3518089997205,
                            36.0250819997732
                        ],
                        [
                            -90.3517130003594,
                            36.0280059997468
                        ],
                        [
                            -90.3541970002234,
                            36.0304670002012
                        ],
                        [
                            -90.3513059997553,
                            36.031074999978
                        ],
                        [
                            -90.3482750004433,
                            36.0350509997741
                        ],
                        [
                            -90.3493089999629,
                            36.0392949999507
                        ],
                        [
                            -90.3482079995979,
                            36.0415730002335
                        ],
                        [
                            -90.3436599999943,
                            36.0430699998636
                        ],
                        [
                            -90.3435570000621,
                            36.044984999735
                        ],
                        [
                            -90.3407550001807,
                            36.0471379996495
                        ],
                        [
                            -90.3368810002466,
                            36.047368000154
                        ],
                        [
                            -90.3377159995745,
                            36.0532549997647
                        ],
                        [
                            -90.3334459998053,
                            36.0567300002832
                        ],
                        [
                            -90.3362960002656,
                            36.0606679999901
                        ],
                        [
                            -90.3332190000246,
                            36.0677009997303
                        ],
                        [
                            -90.3279800001348,
                            36.0685520003448
                        ],
                        [
                            -90.3236750002054,
                            36.0716380001725
                        ],
                        [
                            -90.3204199996892,
                            36.0714819999692
                        ],
                        [
                            -90.3207679998437,
                            36.0732250003372
                        ],
                        [
                            -90.3182379999489,
                            36.0761339998703
                        ],
                        [
                            -90.3202370002894,
                            36.0810700001581
                        ],
                        [
                            -90.3188820003794,
                            36.0845240002908
                        ],
                        [
                            -90.3206480001931,
                            36.0870520002321
                        ],
                        [
                            -90.3188770003566,
                            36.0903089999087
                        ],
                        [
                            -90.3164360001502,
                            36.0906409997939
                        ],
                        [
                            -90.3117269999898,
                            36.094748999779
                        ],
                        [
                            -90.3069059996764,
                            36.0948019997554
                        ],
                        [
                            -90.3002509997773,
                            36.0977310002389
                        ],
                        [
                            -90.297916999701,
                            36.1029930000666
                        ],
                        [
                            -90.2981839996647,
                            36.1073119999243
                        ],
                        [
                            -90.2938500003214,
                            36.1099390000066
                        ],
                        [
                            -90.2950670001391,
                            36.1126249998041
                        ],
                        [
                            -90.2933010003254,
                            36.1143050002141
                        ],
                        [
                            -90.2788750000935,
                            36.1174769998068
                        ],
                        [
                            -90.2729590001611,
                            36.1169310000901
                        ],
                        [
                            -90.2723080000579,
                            36.1184460003188
                        ],
                        [
                            -90.2665200001721,
                            36.1201079999628
                        ],
                        [
                            -90.2608619999826,
                            36.1272570000845
                        ],
                        [
                            -90.2531919999322,
                            36.1272919997724
                        ],
                        [
                            -90.2453700004441,
                            36.1335540000819
                        ],
                        [
                            -90.2455560002169,
                            36.1361879996718
                        ],
                        [
                            -90.2353720001651,
                            36.1394800001231
                        ],
                        [
                            -90.2350599999956,
                            36.1450739996419
                        ],
                        [
                            -90.2315059997286,
                            36.1467829997768
                        ],
                        [
                            -90.2312630000544,
                            36.1538210002445
                        ],
                        [
                            -90.2333689996267,
                            36.1547869996398
                        ],
                        [
                            -90.2328349996993,
                            36.1563230001318
                        ],
                        [
                            -90.2352680004081,
                            36.1596170001569
                        ],
                        [
                            -90.2312749997499,
                            36.1636530002759
                        ],
                        [
                            -90.2295599999899,
                            36.1731930003445
                        ],
                        [
                            -90.2251579999762,
                            36.1768729997928
                        ],
                        [
                            -90.2204870003489,
                            36.1844370000555
                        ],
                        [
                            -90.2165990001711,
                            36.1835590001103
                        ],
                        [
                            -90.2153050003605,
                            36.1846740000451
                        ],
                        [
                            -90.2114699998861,
                            36.1830439998607
                        ],
                        [
                            -90.2101030002806,
                            36.1857649999021
                        ],
                        [
                            -90.2044259998246,
                            36.1867119999727
                        ],
                        [
                            -90.2041930001961,
                            36.1897060001778
                        ],
                        [
                            -90.2007469998841,
                            36.1921280002539
                        ],
                        [
                            -90.2015379997295,
                            36.1960459998188
                        ],
                        [
                            -90.1964560001985,
                            36.1963720002103
                        ],
                        [
                            -90.1952140002664,
                            36.2003479999283
                        ],
                        [
                            -90.1901519999286,
                            36.2013930001682
                        ],
                        [
                            -90.1881840004486,
                            36.2054950000349
                        ],
                        [
                            -90.1830429995698,
                            36.2049840002904
                        ],
                        [
                            -90.1810779995644,
                            36.2087290000735
                        ],
                        [
                            -90.17783899984,
                            36.207130999925
                        ],
                        [
                            -90.1758460002456,
                            36.2105740002795
                        ],
                        [
                            -90.1729799998918,
                            36.2104199996904
                        ],
                        [
                            -90.1695699995649,
                            36.2141370000846
                        ],
                        [
                            -90.1677950004287,
                            36.2131810001369
                        ],
                        [
                            -90.1610430004452,
                            36.2156580000584
                        ],
                        [
                            -90.1555020004316,
                            36.2138810001186
                        ],
                        [
                            -90.152153000204,
                            36.2157469997821
                        ],
                        [
                            -90.1490490001988,
                            36.2216209997511
                        ],
                        [
                            -90.1456029998869,
                            36.2211409996585
                        ],
                        [
                            -90.1459130004066,
                            36.223152999965
                        ],
                        [
                            -90.144134999999,
                            36.2234510000325
                        ],
                        [
                            -90.1422379997658,
                            36.227521999701
                        ],
                        [
                            -90.1331030002005,
                            36.2261110002368
                        ],
                        [
                            -90.1311819996778,
                            36.228693999729
                        ],
                        [
                            -90.1263360001484,
                            36.2291230000513
                        ],
                        [
                            -90.1265289995939,
                            36.2331979998869
                        ],
                        [
                            -90.1247350001912,
                            36.2352929998542
                        ],
                        [
                            -90.127705000302,
                            36.2370259998324
                        ],
                        [
                            -90.1277699997011,
                            36.2396680001452
                        ],
                        [
                            -90.1309480002244,
                            36.2413769997331
                        ],
                        [
                            -90.1297160003381,
                            36.2432349998633
                        ],
                        [
                            -90.1236279999783,
                            36.2434219999764
                        ],
                        [
                            -90.1246339999088,
                            36.2444750000209
                        ],
                        [
                            -90.1219070003705,
                            36.2467850000915
                        ],
                        [
                            -90.1223049998554,
                            36.2492560001148
                        ],
                        [
                            -90.1183740000199,
                            36.2532730002479
                        ],
                        [
                            -90.1198939997863,
                            36.2546559997641
                        ],
                        [
                            -90.118076999919,
                            36.255596000206
                        ],
                        [
                            -90.1192860002391,
                            36.257715000203
                        ],
                        [
                            -90.1174280003639,
                            36.2582760002845
                        ],
                        [
                            -90.1160230002252,
                            36.2616010000033
                        ],
                        [
                            -90.1168009996518,
                            36.263770999759
                        ],
                        [
                            -90.1104110000665,
                            36.2668339998384
                        ],
                        [
                            -90.1065379999573,
                            36.2658589998357
                        ],
                        [
                            -90.1028129999861,
                            36.268981000004
                        ],
                        [
                            -90.0995039999415,
                            36.2689470000768
                        ],
                        [
                            -90.0960049999261,
                            36.2713420003214
                        ],
                        [
                            -90.0835289996309,
                            36.2721220002691
                        ],
                        [
                            -90.079836000345,
                            36.2785240003179
                        ],
                        [
                            -90.0757270002343,
                            36.281065999727
                        ],
                        [
                            -90.0790510003475,
                            36.289851000194
                        ],
                        [
                            -90.0714209995817,
                            36.293174000144
                        ],
                        [
                            -90.0693180003824,
                            36.2985880000806
                        ],
                        [
                            -90.064169000006,
                            36.302526000116
                        ],
                        [
                            -90.0695490003611,
                            36.313261000245
                        ],
                        [
                            -90.0766129996158,
                            36.319213000332
                        ],
                        [
                            -90.0798499996904,
                            36.3183889996693
                        ],
                        [
                            -90.0824070002477,
                            36.3219489998708
                        ],
                        [
                            -90.0798580000863,
                            36.3234950003538
                        ],
                        [
                            -90.081912999605,
                            36.3264570002429
                        ],
                        [
                            -90.0790699997157,
                            36.327737000318
                        ],
                        [
                            -90.0755740000734,
                            36.3342139997158
                        ],
                        [
                            -90.0782860004414,
                            36.3367199997528
                        ],
                        [
                            -90.0771700000077,
                            36.3411490001545
                        ],
                        [
                            -90.0742099999426,
                            36.3425169998262
                        ],
                        [
                            -90.0780429998688,
                            36.3491409997384
                        ],
                        [
                            -90.0758100000749,
                            36.3498349997282
                        ],
                        [
                            -90.0705119997356,
                            36.3562670003094
                        ],
                        [
                            -90.0680580000087,
                            36.3565650002911
                        ],
                        [
                            -90.0660519999955,
                            36.3599439997777
                        ],
                        [
                            -90.0663599999671,
                            36.3651339998523
                        ],
                        [
                            -90.0634080002979,
                            36.3670160003314
                        ],
                        [
                            -90.0647260003979,
                            36.3689879999274
                        ],
                        [
                            -90.062738999753,
                            36.3772600003172
                        ],
                        [
                            -90.0667449999316,
                            36.3780060002861
                        ],
                        [
                            -90.0633970004272,
                            36.3839569996969
                        ],
                        [
                            -90.0681259997808,
                            36.3851490003395
                        ],
                        [
                            -90.0657690001383,
                            36.387463000146
                        ],
                        [
                            -90.068961999832,
                            36.3882970002032
                        ],
                        [
                            -90.0703529997269,
                            36.3934669999698
                        ],
                        [
                            -90.0742450001027,
                            36.3930660001869
                        ],
                        [
                            -90.081407000165,
                            36.4010740001991
                        ],
                        [
                            -90.0983470003982,
                            36.4040220003478
                        ],
                        [
                            -90.1015829997496,
                            36.4020250002903
                        ],
                        [
                            -90.1045920002185,
                            36.4055050002851
                        ],
                        [
                            -90.1093520004326,
                            36.4037839999526
                        ],
                        [
                            -90.1108139995743,
                            36.4047850002088
                        ],
                        [
                            -90.1108069999016,
                            36.4077789999114
                        ],
                        [
                            -90.1156900001393,
                            36.4059060002365
                        ],
                        [
                            -90.1135569999045,
                            36.409509000085
                        ],
                        [
                            -90.1154709998562,
                            36.4079900001758
                        ],
                        [
                            -90.1183870004387,
                            36.4094130003469
                        ],
                        [
                            -90.118917999993,
                            36.4125379999467
                        ],
                        [
                            -90.12155099982,
                            36.4108349999201
                        ],
                        [
                            -90.1285909996836,
                            36.4114250002594
                        ],
                        [
                            -90.1265960004394,
                            36.4155919997024
                        ],
                        [
                            -90.1274900002169,
                            36.4166670000825
                        ],
                        [
                            -90.1339219996349,
                            36.4138570001002
                        ],
                        [
                            -90.1385739998939,
                            36.4138170002336
                        ],
                        [
                            -90.1350330000457,
                            36.4168159998151
                        ],
                        [
                            -90.1343099999725,
                            36.4228000000954
                        ],
                        [
                            -90.1386040000312,
                            36.4211540000215
                        ],
                        [
                            -90.1442770002892,
                            36.4259179999205
                        ],
                        [
                            -90.1429240000291,
                            36.430957000168
                        ],
                        [
                            -90.1377000002079,
                            36.4318380001887
                        ],
                        [
                            -90.138861999774,
                            36.4351159997236
                        ],
                        [
                            -90.1344029998589,
                            36.4359840000702
                        ],
                        [
                            -90.1372650000147,
                            36.4503100000734
                        ],
                        [
                            -90.1339390002517,
                            36.4535969997771
                        ],
                        [
                            -90.1409620003968,
                            36.4585380002054
                        ],
                        [
                            -90.1404319997691,
                            36.4645689999717
                        ],
                        [
                            -90.1424939998588,
                            36.4679059998831
                        ],
                        [
                            -90.1445889995605,
                            36.4675890001614
                        ],
                        [
                            -90.1446200004209,
                            36.462788999693
                        ],
                        [
                            -90.1507000003848,
                            36.4655109996477
                        ],
                        [
                            -90.1558039996571,
                            36.4635539998117
                        ],
                        [
                            -90.1528899996227,
                            36.4709309997565
                        ],
                        [
                            -90.1459159998813,
                            36.4689539996658
                        ],
                        [
                            -90.142412999668,
                            36.4715010000063
                        ],
                        [
                            -90.1438119999588,
                            36.4759069997354
                        ],
                        [
                            -90.1499220000599,
                            36.4765650002349
                        ],
                        [
                            -90.1596270004359,
                            36.4803019997107
                        ],
                        [
                            -90.1571579997421,
                            36.4844009997162
                        ],
                        [
                            -90.1601360002489,
                            36.4870339999385
                        ],
                        [
                            -90.1564859997224,
                            36.4877039997202
                        ],
                        [
                            -90.1561509999867,
                            36.4890969999826
                        ],
                        [
                            -90.1600070003775,
                            36.4942570003581
                        ],
                        [
                            -90.1534500003876,
                            36.4930480003001
                        ],
                        [
                            -90.1547340001525,
                            36.4967389997923
                        ],
                        [
                            -90.1522800004256,
                            36.4979500003182
                        ],
                        [
                            -90.818314999972,
                            36.4986889997449
                        ],
                        [
                            -91.4071379997261,
                            36.4970810000618
                        ],
                        [
                            -91.6077230000786,
                            36.4995700002154
                        ],
                        [
                            -92.3751589997513,
                            36.4971989997664
                        ],
                        [
                            -92.5168359997772,
                            36.4987379998015
                        ],
                        [
                            -92.7900600002039,
                            36.4976449997916
                        ],
                        [
                            -94.6179190000071,
                            36.4994140002771
                        ],
                        [
                            -94.6176689997619,
                            37.776192999823
                        ],
                        [
                            -94.6141110001953,
                            37.9737970000539
                        ],
                        [
                            -94.612474000253,
                            38.2210399997308
                        ],
                        [
                            -94.6133560003349,
                            38.4579670000888
                        ],
                        [
                            -94.6080329998812,
                            38.847207000312
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 38.35075,
            "name": "Missouri",
            "intptlon": -92.4567826,
            "geo_point_2d": [
                38.3676961633,
                -92.4774706827
            ],
            "geoid": "29",
            "mtfcc": "G4000",
            "region": 2
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -92.4774706827,
                38.3676961633
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "bb00e0f96ec6fcb7a6ccb3867e05a90bd87c29c3",
        "fields": {
            "arealand": "-576269925",
            "objectid": 30,
            "basename": "Tennessee",
            "stusab": "TN",
            "statens": "01325873",
            "centlon": -86.3503468,
            "state": "47",
            "gid": 8,
            "centlat": 35.8581413,
            "division": 6,
            "areawater": "-1940028667",
            "oid": "272048646",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.2220640004316,
                            36.1569600001855
                        ],
                        [
                            -82.2242749995859,
                            36.1508379997828
                        ],
                        [
                            -82.2295369999403,
                            36.1445879996805
                        ],
                        [
                            -82.2418829996408,
                            36.1371250001759
                        ],
                        [
                            -82.2450970001492,
                            36.1312489999762
                        ],
                        [
                            -82.2510719996327,
                            36.1320510000648
                        ],
                        [
                            -82.2543279999739,
                            36.1360580002518
                        ],
                        [
                            -82.2603040001807,
                            36.133532999915
                        ],
                        [
                            -82.2639250003948,
                            36.1272969997265
                        ],
                        [
                            -82.2664129995586,
                            36.1269669998935
                        ],
                        [
                            -82.2734480002976,
                            36.1293170001274
                        ],
                        [
                            -82.2800119999601,
                            36.1290940000562
                        ],
                        [
                            -82.2885719995901,
                            36.1357269997909
                        ],
                        [
                            -82.2904560003029,
                            36.1354750000425
                        ],
                        [
                            -82.3024809996131,
                            36.1314649997299
                        ],
                        [
                            -82.3205310004322,
                            36.1197300002791
                        ],
                        [
                            -82.3233919998648,
                            36.1201809997017
                        ],
                        [
                            -82.3363979998894,
                            36.1146240002189
                        ],
                        [
                            -82.3526460000115,
                            36.1164860000421
                        ],
                        [
                            -82.3645409996254,
                            36.1075159997762
                        ],
                        [
                            -82.3752169997717,
                            36.1058620002119
                        ],
                        [
                            -82.3787259998329,
                            36.1032530000622
                        ],
                        [
                            -82.3804749999281,
                            36.0993470000261
                        ],
                        [
                            -82.3892179998562,
                            36.0970969998263
                        ],
                        [
                            -82.4097979996718,
                            36.0830189996775
                        ],
                        [
                            -82.4605830002114,
                            36.0077240000905
                        ],
                        [
                            -82.4704159997355,
                            36.0028870002634
                        ],
                        [
                            -82.4759400000307,
                            35.9982209996458
                        ],
                        [
                            -82.4815909996491,
                            35.9980290001966
                        ],
                        [
                            -82.4841699999478,
                            35.9933009998416
                        ],
                        [
                            -82.4999639996101,
                            35.982440999809
                        ],
                        [
                            -82.5033239997084,
                            35.9821580000063
                        ],
                        [
                            -82.5051840001317,
                            35.9775469998288
                        ],
                        [
                            -82.510298000348,
                            35.9772920003056
                        ],
                        [
                            -82.5128490001592,
                            35.9753009998189
                        ],
                        [
                            -82.5170770000957,
                            35.9758250000885
                        ],
                        [
                            -82.5221209999919,
                            35.9740120001698
                        ],
                        [
                            -82.5250309998283,
                            35.9710550003443
                        ],
                        [
                            -82.5306930002157,
                            35.9722259996839
                        ],
                        [
                            -82.5496860003178,
                            35.964049000069
                        ],
                        [
                            -82.5579880002049,
                            35.9538729999751
                        ],
                        [
                            -82.5761689996469,
                            35.958719999857
                        ],
                        [
                            -82.5777980000916,
                            35.9643340002904
                        ],
                        [
                            -82.5804619998807,
                            35.9652479996434
                        ],
                        [
                            -82.5919819995758,
                            35.9662889998594
                        ],
                        [
                            -82.600106000086,
                            35.9645940003478
                        ],
                        [
                            -82.6107029996914,
                            35.9672029998485
                        ],
                        [
                            -82.6109389996929,
                            35.9743800003559
                        ],
                        [
                            -82.6044129996652,
                            35.9874630001985
                        ],
                        [
                            -82.6071280004063,
                            35.9923599996922
                        ],
                        [
                            -82.6128970000255,
                            35.9936060002316
                        ],
                        [
                            -82.6149280001531,
                            36.0020459997009
                        ],
                        [
                            -82.6138250001382,
                            36.004917000324
                        ],
                        [
                            -82.6068759996129,
                            36.0104300001368
                        ],
                        [
                            -82.6094449998658,
                            36.0110379997546
                        ],
                        [
                            -82.6077610000679,
                            36.015753999796
                        ],
                        [
                            -82.6028390003705,
                            36.0177560000545
                        ],
                        [
                            -82.6029230000361,
                            36.0233709999938
                        ],
                        [
                            -82.5909949999119,
                            36.0315739997978
                        ],
                        [
                            -82.5914469998235,
                            36.0355679999027
                        ],
                        [
                            -82.6016590003627,
                            36.0360540003053
                        ],
                        [
                            -82.6018760000977,
                            36.0386530000219
                        ],
                        [
                            -82.6086590000433,
                            36.0444109999574
                        ],
                        [
                            -82.6183839996125,
                            36.0469160003379
                        ],
                        [
                            -82.6170420001213,
                            36.0537679999817
                        ],
                        [
                            -82.6179540003405,
                            36.055909999759
                        ],
                        [
                            -82.632054999984,
                            36.0640209997607
                        ],
                        [
                            -82.632608000178,
                            36.0659109999854
                        ],
                        [
                            -82.6372300002998,
                            36.0658200002378
                        ],
                        [
                            -82.6515329996097,
                            36.0573680001923
                        ],
                        [
                            -82.6592679999575,
                            36.0571170003216
                        ],
                        [
                            -82.6663460003542,
                            36.0530359997409
                        ],
                        [
                            -82.6705619996968,
                            36.0529760002051
                        ],
                        [
                            -82.6748509997326,
                            36.0488329998641
                        ],
                        [
                            -82.6840730002348,
                            36.045794999932
                        ],
                        [
                            -82.6859870001865,
                            36.0414210001059
                        ],
                        [
                            -82.689585999761,
                            36.038157000048
                        ],
                        [
                            -82.6972240000244,
                            36.0357830001682
                        ],
                        [
                            -82.6981899997719,
                            36.0342000001022
                        ],
                        [
                            -82.7012279996549,
                            36.0343590002989
                        ],
                        [
                            -82.7017959999174,
                            36.032649999968
                        ],
                        [
                            -82.7086399999625,
                            36.0297209998395
                        ],
                        [
                            -82.7151409998758,
                            36.028641999946
                        ],
                        [
                            -82.7164699998465,
                            36.0224100001534
                        ],
                        [
                            -82.7254639998447,
                            36.0181610002616
                        ],
                        [
                            -82.7391169997747,
                            36.0149789997372
                        ],
                        [
                            -82.740284000262,
                            36.0121320002407
                        ],
                        [
                            -82.7507870001559,
                            36.0088709997965
                        ],
                        [
                            -82.7502210004415,
                            36.0059809997678
                        ],
                        [
                            -82.7525680000382,
                            36.0062019997063
                        ],
                        [
                            -82.7545509995869,
                            36.0043459998466
                        ],
                        [
                            -82.7625699996168,
                            36.0048439996637
                        ],
                        [
                            -82.7720730003264,
                            36.0007040000656
                        ],
                        [
                            -82.7757580001146,
                            36.0010510000934
                        ],
                        [
                            -82.779213999574,
                            35.9922889999817
                        ],
                        [
                            -82.7852759999945,
                            35.9885390000532
                        ],
                        [
                            -82.7844759999283,
                            35.9818839999032
                        ],
                        [
                            -82.7856900002713,
                            35.9778409997531
                        ],
                        [
                            -82.7815870000083,
                            35.9742970000509
                        ],
                        [
                            -82.776504999579,
                            35.9740579998325
                        ],
                        [
                            -82.775086000095,
                            35.9718289998636
                        ],
                        [
                            -82.7780379997642,
                            35.9667729997683
                        ],
                        [
                            -82.785043000366,
                            35.9624830000989
                        ],
                        [
                            -82.787559999842,
                            35.9520129999118
                        ],
                        [
                            -82.7913750002249,
                            35.9509969996709
                        ],
                        [
                            -82.7945450003524,
                            35.9469329996773
                        ],
                        [
                            -82.7997270003408,
                            35.9446609997477
                        ],
                        [
                            -82.8061679999797,
                            35.9377580003196
                        ],
                        [
                            -82.802674999812,
                            35.9295709998226
                        ],
                        [
                            -82.8050850000563,
                            35.9271369998976
                        ],
                        [
                            -82.8150859998099,
                            35.9255469996926
                        ],
                        [
                            -82.8219480002966,
                            35.9218300002738
                        ],
                        [
                            -82.8280979996823,
                            35.932412999799
                        ],
                        [
                            -82.8324059999847,
                            35.932813000309
                        ],
                        [
                            -82.8334720001897,
                            35.9355980002637
                        ],
                        [
                            -82.8386380002846,
                            35.9372709998468
                        ],
                        [
                            -82.8411160003009,
                            35.9417409998771
                        ],
                        [
                            -82.844545999821,
                            35.9419869998562
                        ],
                        [
                            -82.8460370001734,
                            35.9461690001553
                        ],
                        [
                            -82.8517329999977,
                            35.9493439997396
                        ],
                        [
                            -82.86064699963,
                            35.947479000201
                        ],
                        [
                            -82.8742929998872,
                            35.9528299999448
                        ],
                        [
                            -82.8782229998978,
                            35.950116999805
                        ],
                        [
                            -82.8889860000828,
                            35.9483439998283
                        ],
                        [
                            -82.8926619996502,
                            35.9450029997698
                        ],
                        [
                            -82.8981819997474,
                            35.9452300001963
                        ],
                        [
                            -82.901783999695,
                            35.9378519998115
                        ],
                        [
                            -82.8988859995541,
                            35.9298759998567
                        ],
                        [
                            -82.90181999968,
                            35.9269600001715
                        ],
                        [
                            -82.9059470002324,
                            35.9261969998379
                        ],
                        [
                            -82.9106210002327,
                            35.9275050002667
                        ],
                        [
                            -82.9121950004258,
                            35.9295940002334
                        ],
                        [
                            -82.919935999723,
                            35.9287980002758
                        ],
                        [
                            -82.9192410001371,
                            35.9258920001609
                        ],
                        [
                            -82.9135350002671,
                            35.9242609998746
                        ],
                        [
                            -82.9116239997902,
                            35.9168080002293
                        ],
                        [
                            -82.9143870002118,
                            35.9151500000882
                        ],
                        [
                            -82.9097340001279,
                            35.9109680001941
                        ],
                        [
                            -82.9080319998883,
                            35.911313999911
                        ],
                        [
                            -82.9069799999271,
                            35.9074290002239
                        ],
                        [
                            -82.9081089998812,
                            35.9042040000843
                        ],
                        [
                            -82.9010430000783,
                            35.8927909998834
                        ],
                        [
                            -82.9068250001164,
                            35.8839080003383
                        ],
                        [
                            -82.9036270003999,
                            35.8804579997705
                        ],
                        [
                            -82.8982860004028,
                            35.8812580001421
                        ],
                        [
                            -82.896081000198,
                            35.879543999665
                        ],
                        [
                            -82.9008909997424,
                            35.8729089998361
                        ],
                        [
                            -82.9065549997796,
                            35.8701800003459
                        ],
                        [
                            -82.9107740003936,
                            35.8685729999146
                        ],
                        [
                            -82.9208310002237,
                            35.8681779996863
                        ],
                        [
                            -82.9214320000983,
                            35.863104999974
                        ],
                        [
                            -82.918212999567,
                            35.8592720000034
                        ],
                        [
                            -82.921033999715,
                            35.8509450001616
                        ],
                        [
                            -82.9163429999963,
                            35.8416629996654
                        ],
                        [
                            -82.9202110000826,
                            35.8393849997029
                        ],
                        [
                            -82.9340699998769,
                            35.8366619996602
                        ],
                        [
                            -82.9333429996056,
                            35.8305289999663
                        ],
                        [
                            -82.9360349998822,
                            35.8255169998872
                        ],
                        [
                            -82.9458470003883,
                            35.8243780002596
                        ],
                        [
                            -82.9481959996348,
                            35.8177840000278
                        ],
                        [
                            -82.9517640001455,
                            35.8166079998539
                        ],
                        [
                            -82.9618109999299,
                            35.8003659996813
                        ],
                        [
                            -82.9628999997011,
                            35.7949240000207
                        ],
                        [
                            -82.9617540000285,
                            35.7909590000575
                        ],
                        [
                            -82.9630250002729,
                            35.7899939996495
                        ],
                        [
                            -82.9696990004386,
                            35.7896350001499
                        ],
                        [
                            -82.9839249997556,
                            35.7780929996919
                        ],
                        [
                            -82.9959890003222,
                            35.7730819996474
                        ],
                        [
                            -83.0012919997861,
                            35.7737790002321
                        ],
                        [
                            -83.005988000426,
                            35.778496000178
                        ],
                        [
                            -83.0109029995524,
                            35.7787449997485
                        ],
                        [
                            -83.0160250001647,
                            35.7820490000816
                        ],
                        [
                            -83.0203479996374,
                            35.7804019996848
                        ],
                        [
                            -83.0238860000108,
                            35.7834199998565
                        ],
                        [
                            -83.0360419997406,
                            35.7875039998409
                        ],
                        [
                            -83.043062000411,
                            35.7854070000851
                        ],
                        [
                            -83.0528210003153,
                            35.7897049998893
                        ],
                        [
                            -83.0637469998086,
                            35.7869139996947
                        ],
                        [
                            -83.0742010001971,
                            35.7901369998734
                        ],
                        [
                            -83.0785029997534,
                            35.7896749999045
                        ],
                        [
                            -83.0852649997827,
                            35.785843999866
                        ],
                        [
                            -83.0893019999234,
                            35.7814910001452
                        ],
                        [
                            -83.0945800001713,
                            35.7796000002225
                        ],
                        [
                            -83.096754000414,
                            35.7763509996472
                        ],
                        [
                            -83.1046029996662,
                            35.7743769997981
                        ],
                        [
                            -83.111204000037,
                            35.7706239998515
                        ],
                        [
                            -83.1182389998777,
                            35.7697839999025
                        ],
                        [
                            -83.1206080001141,
                            35.7664309999274
                        ],
                        [
                            -83.1274639998547,
                            35.7680859998497
                        ],
                        [
                            -83.1364710002717,
                            35.765139000327
                        ],
                        [
                            -83.1595850001801,
                            35.7646509997365
                        ],
                        [
                            -83.164971000383,
                            35.7596800000026
                        ],
                        [
                            -83.1647899997347,
                            35.7539880003643
                        ],
                        [
                            -83.1683230000853,
                            35.750757999955
                        ],
                        [
                            -83.1702849997176,
                            35.7460300000827
                        ],
                        [
                            -83.1777919995614,
                            35.7443829996447
                        ],
                        [
                            -83.1859060000259,
                            35.7300970002282
                        ],
                        [
                            -83.1977420000885,
                            35.7252529999631
                        ],
                        [
                            -83.207382999992,
                            35.7261910000828
                        ],
                        [
                            -83.2141960000749,
                            35.7241130000679
                        ],
                        [
                            -83.2180819997046,
                            35.7259580002841
                        ],
                        [
                            -83.2408270004404,
                            35.7268560001545
                        ],
                        [
                            -83.2432490003802,
                            35.7225200002819
                        ],
                        [
                            -83.2515069998864,
                            35.7197519998367
                        ],
                        [
                            -83.2553559997061,
                            35.7152439998862
                        ],
                        [
                            -83.2515679999857,
                            35.7089739996608
                        ],
                        [
                            -83.2574300003897,
                            35.7051200000819
                        ],
                        [
                            -83.2543249996613,
                            35.6954449997346
                        ],
                        [
                            -83.2576119999646,
                            35.6939690003208
                        ],
                        [
                            -83.2592909997396,
                            35.6903709999157
                        ],
                        [
                            -83.2706799999135,
                            35.686826999664
                        ],
                        [
                            -83.2711660001603,
                            35.6831679999683
                        ],
                        [
                            -83.274466999809,
                            35.6790759998753
                        ],
                        [
                            -83.2813419998161,
                            35.6776990003546
                        ],
                        [
                            -83.2839889998868,
                            35.6744629998392
                        ],
                        [
                            -83.2903049998523,
                            35.6734980003446
                        ],
                        [
                            -83.2935450002999,
                            35.6618790001046
                        ],
                        [
                            -83.2982440004145,
                            35.6572550000173
                        ],
                        [
                            -83.3120760004446,
                            35.6546120002996
                        ],
                        [
                            -83.3216160000658,
                            35.6630539999452
                        ],
                        [
                            -83.3346560004256,
                            35.6656619997648
                        ],
                        [
                            -83.3387210001554,
                            35.6636339997535
                        ],
                        [
                            -83.3410639995542,
                            35.664589000123
                        ],
                        [
                            -83.3450069999836,
                            35.6612090000203
                        ],
                        [
                            -83.3508159997859,
                            35.6605419999101
                        ],
                        [
                            -83.3559690003603,
                            35.6543050003247
                        ],
                        [
                            -83.3559330003752,
                            35.6507569997494
                        ],
                        [
                            -83.3582730002993,
                            35.6469979998269
                        ],
                        [
                            -83.3660560003276,
                            35.6410279999606
                        ],
                        [
                            -83.367797000027,
                            35.6378410000402
                        ],
                        [
                            -83.3718280003199,
                            35.6391629999912
                        ],
                        [
                            -83.3780870003839,
                            35.6345620002795
                        ],
                        [
                            -83.388725999822,
                            35.6336220002843
                        ],
                        [
                            -83.3916709998185,
                            35.6265640000729
                        ],
                        [
                            -83.3961470003504,
                            35.6228660002175
                        ],
                        [
                            -83.4055329999859,
                            35.6205119998453
                        ],
                        [
                            -83.4150340001473,
                            35.6145040000862
                        ],
                        [
                            -83.4201349999448,
                            35.6135169996737
                        ],
                        [
                            -83.4220380000259,
                            35.6110490002305
                        ],
                        [
                            -83.4323030002685,
                            35.6099900000301
                        ],
                        [
                            -83.4456759998159,
                            35.6117469997808
                        ],
                        [
                            -83.4516479998248,
                            35.603527999945
                        ],
                        [
                            -83.4550110002961,
                            35.6020220003578
                        ],
                        [
                            -83.4565889997887,
                            35.597194000133
                        ],
                        [
                            -83.4614519999349,
                            35.5952860001324
                        ],
                        [
                            -83.4626920002171,
                            35.5925700002976
                        ],
                        [
                            -83.4712960002281,
                            35.59026800024
                        ],
                        [
                            -83.4728539996293,
                            35.5862520001347
                        ],
                        [
                            -83.4790719996854,
                            35.5832179999604
                        ],
                        [
                            -83.4786309996444,
                            35.579190000318
                        ],
                        [
                            -83.4853569996887,
                            35.568471000288
                        ],
                        [
                            -83.4933170001673,
                            35.5668300000051
                        ],
                        [
                            -83.4984029998963,
                            35.5629319997572
                        ],
                        [
                            -83.5165640001451,
                            35.5624380000337
                        ],
                        [
                            -83.5212950000468,
                            35.5655920002232
                        ],
                        [
                            -83.5319839997137,
                            35.5644850003178
                        ],
                        [
                            -83.540716999596,
                            35.5658650000671
                        ],
                        [
                            -83.551042999938,
                            35.5634539996737
                        ],
                        [
                            -83.5649789997251,
                            35.56609099989
                        ],
                        [
                            -83.5665250003291,
                            35.5642940001959
                        ],
                        [
                            -83.5726630000193,
                            35.5653810002875
                        ],
                        [
                            -83.5843740004084,
                            35.5626980001524
                        ],
                        [
                            -83.5961169996863,
                            35.5749990001673
                        ],
                        [
                            -83.5983019997996,
                            35.5749999996951
                        ],
                        [
                            -83.6017740000509,
                            35.578919000269
                        ],
                        [
                            -83.6083360000636,
                            35.5800150002357
                        ],
                        [
                            -83.618571000169,
                            35.5722540000082
                        ],
                        [
                            -83.621307999753,
                            35.5726969998469
                        ],
                        [
                            -83.6293130004375,
                            35.5681710000483
                        ],
                        [
                            -83.633861999866,
                            35.5706719999426
                        ],
                        [
                            -83.635328999929,
                            35.5680959998129
                        ],
                        [
                            -83.6401300001509,
                            35.566165000172
                        ],
                        [
                            -83.6451520003057,
                            35.5659129996625
                        ],
                        [
                            -83.6560140002249,
                            35.5700130003087
                        ],
                        [
                            -83.6610020000445,
                            35.5683389998825
                        ],
                        [
                            -83.6638049997508,
                            35.5697560000524
                        ],
                        [
                            -83.6797569995969,
                            35.5703509998401
                        ],
                        [
                            -83.6876969999841,
                            35.568197000211
                        ],
                        [
                            -83.6956099997088,
                            35.5703220001567
                        ],
                        [
                            -83.701241999959,
                            35.5674760000491
                        ],
                        [
                            -83.7069899996618,
                            35.5685509996751
                        ],
                        [
                            -83.712474999599,
                            35.5664020002499
                        ],
                        [
                            -83.7140069999592,
                            35.5641829997131
                        ],
                        [
                            -83.7227719996286,
                            35.5622960001355
                        ],
                        [
                            -83.7361369996785,
                            35.5655209998116
                        ],
                        [
                            -83.7471579996063,
                            35.5603079999893
                        ],
                        [
                            -83.7549919996883,
                            35.5646149997196
                        ],
                        [
                            -83.7595510000608,
                            35.5625729997087
                        ],
                        [
                            -83.7718890002638,
                            35.5613900001209
                        ],
                        [
                            -83.7726659998654,
                            35.5574319996751
                        ],
                        [
                            -83.7801819999301,
                            35.550634000228
                        ],
                        [
                            -83.7946459997968,
                            35.5456490001452
                        ],
                        [
                            -83.7987850000448,
                            35.5416410001327
                        ],
                        [
                            -83.8028039997439,
                            35.5414869998627
                        ],
                        [
                            -83.8080379996108,
                            35.5370930001736
                        ],
                        [
                            -83.8098930000113,
                            35.5329960000851
                        ],
                        [
                            -83.8204670000504,
                            35.5256519999357
                        ],
                        [
                            -83.8321430002794,
                            35.5245739999597
                        ],
                        [
                            -83.8491439997137,
                            35.5186319996514
                        ],
                        [
                            -83.8571810001853,
                            35.5215730003592
                        ],
                        [
                            -83.8680690000437,
                            35.5200080003031
                        ],
                        [
                            -83.8732359999635,
                            35.5208559999155
                        ],
                        [
                            -83.8798480002049,
                            35.5190499999133
                        ],
                        [
                            -83.8899269997764,
                            35.5046630002798
                        ],
                        [
                            -83.9014659997381,
                            35.4965520001095
                        ],
                        [
                            -83.9003650002713,
                            35.4930310001029
                        ],
                        [
                            -83.9057809997132,
                            35.488689000283
                        ],
                        [
                            -83.9118749999207,
                            35.4760680003249
                        ],
                        [
                            -83.9166209998911,
                            35.4736490001581
                        ],
                        [
                            -83.9241410001537,
                            35.4743110000888
                        ],
                        [
                            -83.9367229998558,
                            35.4715530000516
                        ],
                        [
                            -83.9419700001415,
                            35.4673119996491
                        ],
                        [
                            -83.9428789999876,
                            35.4643980003
                        ],
                        [
                            -83.9496490004128,
                            35.4609410001797
                        ],
                        [
                            -83.9529640003052,
                            35.4605429997792
                        ],
                        [
                            -83.9557720000344,
                            35.4636450001392
                        ],
                        [
                            -83.9610800004195,
                            35.46413799992
                        ],
                        [
                            -83.9627809999358,
                            35.4566159999074
                        ],
                        [
                            -83.9656930003204,
                            35.4562379997254
                        ],
                        [
                            -83.9646239997424,
                            35.4541119999876
                        ],
                        [
                            -83.9718550003,
                            35.4548489998151
                        ],
                        [
                            -83.9710960002417,
                            35.4530070001868
                        ],
                        [
                            -83.9732889998527,
                            35.4527619999736
                        ],
                        [
                            -83.9726550003662,
                            35.4472979996983
                        ],
                        [
                            -83.9751280003596,
                            35.4480110001752
                        ],
                        [
                            -83.9760940001071,
                            35.4465370002389
                        ],
                        [
                            -83.9797570001541,
                            35.4482600002535
                        ],
                        [
                            -83.9844329998042,
                            35.4401499997799
                        ],
                        [
                            -83.9879829998732,
                            35.4409209998836
                        ],
                        [
                            -83.9889190003818,
                            35.4390870000806
                        ],
                        [
                            -83.9920040001204,
                            35.4405229999898
                        ],
                        [
                            -83.9947120002905,
                            35.435180000266
                        ],
                        [
                            -83.99959299998,
                            35.43389300011
                        ],
                        [
                            -83.9993899995904,
                            35.4312409999778
                        ],
                        [
                            -83.9979550002128,
                            35.4307319999633
                        ],
                        [
                            -84.001280000151,
                            35.423390999799
                        ],
                        [
                            -84.0144409999863,
                            35.4123489998023
                        ],
                        [
                            -84.0234249999388,
                            35.4115810002634
                        ],
                        [
                            -84.0187119995805,
                            35.399995999958
                        ],
                        [
                            -84.0138669998759,
                            35.3978849997773
                        ],
                        [
                            -84.0125129997909,
                            35.3921919999597
                        ],
                        [
                            -84.0082220001053,
                            35.3905739996774
                        ],
                        [
                            -84.0113960004307,
                            35.3846010000797
                        ],
                        [
                            -84.008300999748,
                            35.3787660001575
                        ],
                        [
                            -84.0087419997889,
                            35.3728179999505
                        ],
                        [
                            -84.0073939995517,
                            35.3716489998225
                        ],
                        [
                            -84.0150569999294,
                            35.3649809999023
                        ],
                        [
                            -84.0233339997022,
                            35.3543119999196
                        ],
                        [
                            -84.0380829999743,
                            35.3511799997992
                        ],
                        [
                            -84.0388810003906,
                            35.3482080001003
                        ],
                        [
                            -84.0340009996277,
                            35.3432759998003
                        ],
                        [
                            -84.0361949999619,
                            35.3376580001166
                        ],
                        [
                            -84.0314740001059,
                            35.3365290003663
                        ],
                        [
                            -84.0292570002055,
                            35.3330999999924
                        ],
                        [
                            -84.0344919998974,
                            35.3225570001484
                        ],
                        [
                            -84.0358839996173,
                            35.3132149999869
                        ],
                        [
                            -84.0341150003289,
                            35.3111830001383
                        ],
                        [
                            -84.0269690001601,
                            35.3096719998487
                        ],
                        [
                            -84.0262979999653,
                            35.3063209997903
                        ],
                        [
                            -84.0214189999256,
                            35.3014009999068
                        ],
                        [
                            -84.0233780000831,
                            35.2963079999451
                        ],
                        [
                            -84.0362390003428,
                            35.2884180003024
                        ],
                        [
                            -84.0408170000836,
                            35.2796000003372
                        ],
                        [
                            -84.0468870000018,
                            35.2742499999367
                        ],
                        [
                            -84.0510079998081,
                            35.2733379997029
                        ],
                        [
                            -84.0525359999704,
                            35.2701890001571
                        ],
                        [
                            -84.0808519999587,
                            35.2612520001685
                        ],
                        [
                            -84.0825299999088,
                            35.2562880000158
                        ],
                        [
                            -84.0923180001255,
                            35.2493740002672
                        ],
                        [
                            -84.0975040003118,
                            35.2475979998145
                        ],
                        [
                            -84.1025630002766,
                            35.2483650001068
                        ],
                        [
                            -84.1064770003937,
                            35.2514910003042
                        ],
                        [
                            -84.1135540000672,
                            35.2489810000393
                        ],
                        [
                            -84.119926999934,
                            35.2520489999703
                        ],
                        [
                            -84.1252200002505,
                            35.2498710000527
                        ],
                        [
                            -84.1267190001005,
                            35.2463049996691
                        ],
                        [
                            -84.1257209996676,
                            35.2421209997932
                        ],
                        [
                            -84.1271109997377,
                            35.2407700003655
                        ],
                        [
                            -84.133619000222,
                            35.2423769998669
                        ],
                        [
                            -84.1394000004351,
                            35.2460969999338
                        ],
                        [
                            -84.1477609998735,
                            35.2468639997527
                        ],
                        [
                            -84.1592289996901,
                            35.2457199999248
                        ],
                        [
                            -84.1614609996591,
                            35.2435229998624
                        ],
                        [
                            -84.1700260002102,
                            35.246459999728
                        ],
                        [
                            -84.1785389999846,
                            35.2406380000367
                        ],
                        [
                            -84.1835129995605,
                            35.2399959999762
                        ],
                        [
                            -84.188957000388,
                            35.2402170002096
                        ],
                        [
                            -84.1933750002953,
                            35.2447790002487
                        ],
                        [
                            -84.1995199996582,
                            35.2438540002552
                        ],
                        [
                            -84.2029819998636,
                            35.2552910002899
                        ],
                        [
                            -84.2113950000788,
                            35.2656210001094
                        ],
                        [
                            -84.2241220004442,
                            35.2689849998104
                        ],
                        [
                            -84.232503999799,
                            35.2642450001985
                        ],
                        [
                            -84.2425210003445,
                            35.2534869999902
                        ],
                        [
                            -84.2508319995541,
                            35.2507199999477
                        ],
                        [
                            -84.2583710000833,
                            35.2456629997203
                        ],
                        [
                            -84.2604089998835,
                            35.2418329996614
                        ],
                        [
                            -84.275908999818,
                            35.2342039998267
                        ],
                        [
                            -84.2832969998361,
                            35.2264590002573
                        ],
                        [
                            -84.2881030000809,
                            35.2243459999896
                        ],
                        [
                            -84.2902139996761,
                            35.2254319997533
                        ],
                        [
                            -84.3218210001656,
                            34.9884079997862
                        ],
                        [
                            -84.8915150002736,
                            34.9882430001005
                        ],
                        [
                            -85.4667129996869,
                            34.9829720000974
                        ],
                        [
                            -85.824023000088,
                            34.9881309997765
                        ],
                        [
                            -86.3749659999432,
                            34.9917379996971
                        ],
                        [
                            -86.4681150004112,
                            34.9904799997232
                        ],
                        [
                            -86.6695640004039,
                            34.9923390000331
                        ],
                        [
                            -86.8621470004449,
                            34.9919569999865
                        ],
                        [
                            -87.4188599997442,
                            35.0026679998649
                        ],
                        [
                            -88.2029589996408,
                            35.00802799984
                        ],
                        [
                            -88.200063999873,
                            34.9956339997679
                        ],
                        [
                            -88.8939009995718,
                            34.9959200001214
                        ],
                        [
                            -89.4349540000014,
                            34.9937539999593
                        ],
                        [
                            -89.6442820002819,
                            34.9952930002761
                        ],
                        [
                            -90.1483610002856,
                            34.9947140001529
                        ],
                        [
                            -90.3092890001565,
                            34.9956940002954
                        ],
                        [
                            -90.3100580002605,
                            35.0008659997947
                        ],
                        [
                            -90.3098719995893,
                            35.0097490003089
                        ],
                        [
                            -90.3068970003539,
                            35.0181939996421
                        ],
                        [
                            -90.297295999735,
                            35.0378930001134
                        ],
                        [
                            -90.2919959997458,
                            35.041792999819
                        ],
                        [
                            -90.2652930003086,
                            35.0402939997739
                        ],
                        [
                            -90.2623919997947,
                            35.0363939998636
                        ],
                        [
                            -90.2564900001061,
                            35.0344940002398
                        ],
                        [
                            -90.2306100003013,
                            35.031319999692
                        ],
                        [
                            -90.2143820002707,
                            35.025795000087
                        ],
                        [
                            -90.2093969999258,
                            35.0265460003647
                        ],
                        [
                            -90.2000449997273,
                            35.0328929999985
                        ],
                        [
                            -90.1958169997908,
                            35.0377880000063
                        ],
                        [
                            -90.1972210001045,
                            35.0483810002563
                        ],
                        [
                            -90.1958729998673,
                            35.0602079999161
                        ],
                        [
                            -90.1813870002592,
                            35.0914010000451
                        ],
                        [
                            -90.1768429999553,
                            35.1120880002499
                        ],
                        [
                            -90.173603000406,
                            35.1180730001042
                        ],
                        [
                            -90.1600580004311,
                            35.1288299999091
                        ],
                        [
                            -90.1427940003328,
                            35.1350909998266
                        ],
                        [
                            -90.1093930004404,
                            35.1188909996361
                        ],
                        [
                            -90.1005929997126,
                            35.1166909999278
                        ],
                        [
                            -90.0906100004006,
                            35.1182870003259
                        ],
                        [
                            -90.0834209996758,
                            35.1216689997666
                        ],
                        [
                            -90.0711920001511,
                            35.131591000189
                        ],
                        [
                            -90.0647680002307,
                            35.1389400002529
                        ],
                        [
                            -90.0669580003669,
                            35.1518389997326
                        ],
                        [
                            -90.0705259999793,
                            35.1536439997781
                        ],
                        [
                            -90.0919989997475,
                            35.1563719999572
                        ],
                        [
                            -90.1041700004442,
                            35.169011999721
                        ],
                        [
                            -90.1087570004058,
                            35.1752819999294
                        ],
                        [
                            -90.1094860003269,
                            35.17886499998
                        ],
                        [
                            -90.1142139998555,
                            35.1816910000093
                        ],
                        [
                            -90.1173930002038,
                            35.1878900000935
                        ],
                        [
                            -90.1161820002339,
                            35.1984980000222
                        ],
                        [
                            -90.109076000248,
                            35.199105000228
                        ],
                        [
                            -90.0964660000585,
                            35.1948480002065
                        ],
                        [
                            -90.0885969998164,
                            35.2123759998207
                        ],
                        [
                            -90.0800849998671,
                            35.2076880000456
                        ],
                        [
                            -90.0743920004158,
                            35.2105889997374
                        ],
                        [
                            -90.0742619998211,
                            35.2183159998541
                        ],
                        [
                            -90.0793450000753,
                            35.2288380002134
                        ],
                        [
                            -90.0979470003652,
                            35.249982999842
                        ],
                        [
                            -90.1050929996356,
                            35.2542880002279
                        ],
                        [
                            -90.1164929996802,
                            35.2557879997438
                        ],
                        [
                            -90.1403940001343,
                            35.2522890001125
                        ],
                        [
                            -90.1520939997545,
                            35.2559890000478
                        ],
                        [
                            -90.1588650000045,
                            35.2625770001718
                        ],
                        [
                            -90.1689240003828,
                            35.2802800001742
                        ],
                        [
                            -90.1690790001935,
                            35.2845639999834
                        ],
                        [
                            -90.1638119998163,
                            35.296114999734
                        ],
                        [
                            -90.1589129996851,
                            35.3006370002211
                        ],
                        [
                            -90.1494490002319,
                            35.3033019996851
                        ],
                        [
                            -90.1388640003221,
                            35.2991139998684
                        ],
                        [
                            -90.123706999621,
                            35.3045299998037
                        ],
                        [
                            -90.1161139995635,
                            35.3034119997107
                        ],
                        [
                            -90.1090929999665,
                            35.3049870003379
                        ],
                        [
                            -90.1068240001875,
                            35.3246179997427
                        ],
                        [
                            -90.1038619995743,
                            35.3324049997845
                        ],
                        [
                            -90.1102930000657,
                            35.3427880000287
                        ],
                        [
                            -90.1073120000842,
                            35.3431429999746
                        ],
                        [
                            -90.0968250000837,
                            35.3572160001604
                        ],
                        [
                            -90.0879030000555,
                            35.3632700000136
                        ],
                        [
                            -90.0749919995671,
                            35.3841519998463
                        ],
                        [
                            -90.0799300000564,
                            35.3852720001743
                        ],
                        [
                            -90.0966500001816,
                            35.3952569997578
                        ],
                        [
                            -90.1048419995655,
                            35.4014870003429
                        ],
                        [
                            -90.1125040001183,
                            35.4101529999112
                        ],
                        [
                            -90.129492999857,
                            35.4137590002237
                        ],
                        [
                            -90.1351250001072,
                            35.4129769998085
                        ],
                        [
                            -90.140921000389,
                            35.4092250001599
                        ],
                        [
                            -90.1441650001363,
                            35.4055540002652
                        ],
                        [
                            -90.1461910002409,
                            35.3994679999066
                        ],
                        [
                            -90.1434749996749,
                            35.3876020001256
                        ],
                        [
                            -90.1355100000717,
                            35.3766679997786
                        ],
                        [
                            -90.1449240001945,
                            35.3746329997337
                        ],
                        [
                            -90.16624600035,
                            35.3747449997637
                        ],
                        [
                            -90.1783409999804,
                            35.3820919997073
                        ],
                        [
                            -90.1792649998951,
                            35.385193999949
                        ],
                        [
                            -90.1706759999528,
                            35.4105299996636
                        ],
                        [
                            -90.1705119999213,
                            35.4187970002032
                        ],
                        [
                            -90.1688819996517,
                            35.4222059996978
                        ],
                        [
                            -90.1294469998263,
                            35.4419299999227
                        ],
                        [
                            -90.1237560000248,
                            35.459112999973
                        ],
                        [
                            -90.1189280000388,
                            35.4683280003639
                        ],
                        [
                            -90.1078369997907,
                            35.4768360000455
                        ],
                        [
                            -90.1017719998955,
                            35.4768309999472
                        ],
                        [
                            -90.0987340000125,
                            35.4785540000427
                        ],
                        [
                            -90.0836500000047,
                            35.4782970000804
                        ],
                        [
                            -90.0741320001248,
                            35.472545999647
                        ],
                        [
                            -90.068155999918,
                            35.4667799997889
                        ],
                        [
                            -90.0672289996302,
                            35.4609519996426
                        ],
                        [
                            -90.0740629996295,
                            35.4396109998964
                        ],
                        [
                            -90.0740459999111,
                            35.4340430002881
                        ],
                        [
                            -90.0705490004438,
                            35.4232910001488
                        ],
                        [
                            -90.0620180002278,
                            35.4151799999946
                        ],
                        [
                            -90.0694339998351,
                            35.4081630002331
                        ],
                        [
                            -90.0543220002381,
                            35.3892770001504
                        ],
                        [
                            -90.044581999702,
                            35.3930730002377
                        ],
                        [
                            -90.0415630000857,
                            35.3966200002484
                        ],
                        [
                            -90.0567819998127,
                            35.4038629998753
                        ],
                        [
                            -90.046597999761,
                            35.4129659996465
                        ],
                        [
                            -90.0426400001613,
                            35.4212369998146
                        ],
                        [
                            -90.0315840000733,
                            35.4276619998141
                        ],
                        [
                            -90.0310209998336,
                            35.4330539997828
                        ],
                        [
                            -90.0265839996598,
                            35.4401030000938
                        ],
                        [
                            -90.0266039997513,
                            35.4477879997185
                        ],
                        [
                            -90.0189160001575,
                            35.4662309996885
                        ],
                        [
                            -90.0203329999917,
                            35.4702910002407
                        ],
                        [
                            -90.0337900001031,
                            35.4794109998313
                        ],
                        [
                            -90.0434619999688,
                            35.4923390000974
                        ],
                        [
                            -90.0498099997212,
                            35.510454000226
                        ],
                        [
                            -90.0495400002828,
                            35.5210490000949
                        ],
                        [
                            -90.0419609995706,
                            35.537467000233
                        ],
                        [
                            -90.0395500003998,
                            35.5485569998391
                        ],
                        [
                            -90.0351180002488,
                            35.5525119998285
                        ],
                        [
                            -90.0261130003799,
                            35.556176000034
                        ],
                        [
                            -90.017582000164,
                            35.5560099998088
                        ],
                        [
                            -90.007993000139,
                            35.5600910002819
                        ],
                        [
                            -89.9992440003631,
                            35.5611930002688
                        ],
                        [
                            -89.9894989998042,
                            35.5601359998114
                        ],
                        [
                            -89.9789170002674,
                            35.5553280002864
                        ],
                        [
                            -89.9588489997396,
                            35.5419160002969
                        ],
                        [
                            -89.9556900003812,
                            35.5337879997665
                        ],
                        [
                            -89.9577149997626,
                            35.5271920001611
                        ],
                        [
                            -89.948010000285,
                            35.5200900002189
                        ],
                        [
                            -89.9336140001903,
                            35.5185379998524
                        ],
                        [
                            -89.9193310000735,
                            35.5138700002248
                        ],
                        [
                            -89.9144839998208,
                            35.5156919996687
                        ],
                        [
                            -89.9090220003483,
                            35.5205479997059
                        ],
                        [
                            -89.9038820001927,
                            35.5341750003641
                        ],
                        [
                            -89.9064580001182,
                            35.5371940001641
                        ],
                        [
                            -89.9124019996397,
                            35.5379509996993
                        ],
                        [
                            -89.9097400003987,
                            35.5419599998556
                        ],
                        [
                            -89.9075789996765,
                            35.5421210002444
                        ],
                        [
                            -89.9088709998372,
                            35.5473779997255
                        ],
                        [
                            -89.9124789996326,
                            35.5494319998331
                        ],
                        [
                            -89.9286849999219,
                            35.551453999818
                        ],
                        [
                            -89.9418750000695,
                            35.5568590001347
                        ],
                        [
                            -89.9544719998402,
                            35.5766080001325
                        ],
                        [
                            -89.9578770001443,
                            35.58536900003
                        ],
                        [
                            -89.9574949996546,
                            35.5885300002917
                        ],
                        [
                            -89.9536940004137,
                            35.59462399991
                        ],
                        [
                            -89.9467109995532,
                            35.6007890000516
                        ],
                        [
                            -89.9195529998313,
                            35.6121800000893
                        ],
                        [
                            -89.9110819998898,
                            35.617555999828
                        ],
                        [
                            -89.8997520001655,
                            35.6150250000394
                        ],
                        [
                            -89.8943450000462,
                            35.6155350000581
                        ],
                        [
                            -89.8778629995724,
                            35.6260040002644
                        ],
                        [
                            -89.8570650001969,
                            35.6341519998545
                        ],
                        [
                            -89.8518239997589,
                            35.6442610003553
                        ],
                        [
                            -89.8511579995871,
                            35.6574239996727
                        ],
                        [
                            -89.8579420002559,
                            35.6683120003279
                        ],
                        [
                            -89.8647390004453,
                            35.6703880001562
                        ],
                        [
                            -89.8771579999408,
                            35.6661360003596
                        ],
                        [
                            -89.8849319997483,
                            35.6551069997997
                        ],
                        [
                            -89.894566999804,
                            35.6515139998436
                        ],
                        [
                            -89.9042300003472,
                            35.6509969998607
                        ],
                        [
                            -89.9154270000021,
                            35.6527819997329
                        ],
                        [
                            -89.9310359997165,
                            35.6600440000173
                        ],
                        [
                            -89.9373829996441,
                            35.6657109999367
                        ],
                        [
                            -89.94270000025,
                            35.675120999858
                        ],
                        [
                            -89.9557530001303,
                            35.6906209996729
                        ],
                        [
                            -89.9588789998768,
                            35.7238349999952
                        ],
                        [
                            -89.9562519998976,
                            35.7333880003109
                        ],
                        [
                            -89.9502770004141,
                            35.7384939997654
                        ],
                        [
                            -89.9191069997675,
                            35.7530250000032
                        ],
                        [
                            -89.9098249998892,
                            35.759387000298
                        ],
                        [
                            -89.9063619998588,
                            35.7595620001419
                        ],
                        [
                            -89.8881629999751,
                            35.7500770000229
                        ],
                        [
                            -89.8835350000055,
                            35.7449840002374
                        ],
                        [
                            -89.87725599985,
                            35.7413689997149
                        ],
                        [
                            -89.8764619996317,
                            35.7380259999282
                        ],
                        [
                            -89.8656309996746,
                            35.7465769998356
                        ],
                        [
                            -89.8471270001924,
                            35.7554989998716
                        ],
                        [
                            -89.8368000000255,
                            35.7549410002589
                        ],
                        [
                            -89.8208759997685,
                            35.7568679999846
                        ],
                        [
                            -89.8128910000738,
                            35.7611540001451
                        ],
                        [
                            -89.8048629998231,
                            35.7715159996495
                        ],
                        [
                            -89.7992490000145,
                            35.775438999979
                        ],
                        [
                            -89.7970530000306,
                            35.7826479999668
                        ],
                        [
                            -89.7993310000303,
                            35.7885030001334
                        ],
                        [
                            -89.7963240001095,
                            35.7928069998658
                        ],
                        [
                            -89.7811759996292,
                            35.8052959998136
                        ],
                        [
                            -89.76545700031,
                            35.809512999738
                        ],
                        [
                            -89.7654380000434,
                            35.8112129997233
                        ],
                        [
                            -89.7433190001948,
                            35.8057809999703
                        ],
                        [
                            -89.734706999788,
                            35.8060469996775
                        ],
                        [
                            -89.7238330001732,
                            35.809246999983
                        ],
                        [
                            -89.7043870003346,
                            35.8196070002743
                        ],
                        [
                            -89.7015229996306,
                            35.8249149996771
                        ],
                        [
                            -89.7011910002679,
                            35.8299120002226
                        ],
                        [
                            -89.7028829995634,
                            35.8341529996855
                        ],
                        [
                            -89.7063520003399,
                            35.8372020000929
                        ],
                        [
                            -89.729517000302,
                            35.847632000314
                        ],
                        [
                            -89.7643430004244,
                            35.8583129997283
                        ],
                        [
                            -89.7724670000363,
                            35.8650979997542
                        ],
                        [
                            -89.7728550003739,
                            35.8762440000246
                        ],
                        [
                            -89.7656890001136,
                            35.8912989999551
                        ],
                        [
                            -89.7426060001673,
                            35.9066529996905
                        ],
                        [
                            -89.7149339997112,
                            35.9062470000979
                        ],
                        [
                            -89.6881369996644,
                            35.896945000091
                        ],
                        [
                            -89.675084999609,
                            35.8843639998003
                        ],
                        [
                            -89.6661009996565,
                            35.882866000246
                        ],
                        [
                            -89.6483649995551,
                            35.8937669996475
                        ],
                        [
                            -89.6453459999387,
                            35.8914189997462
                        ],
                        [
                            -89.6443950002598,
                            35.894781999771
                        ],
                        [
                            -89.6465560000837,
                            35.899801999665
                        ],
                        [
                            -89.6448379999506,
                            35.9043510002339
                        ],
                        [
                            -89.6531399998377,
                            35.9226179998816
                        ],
                        [
                            -89.6869240000447,
                            35.9477160002529
                        ],
                        [
                            -89.7145649996403,
                            35.9630340002498
                        ],
                        [
                            -89.7196789998566,
                            35.9709390000179
                        ],
                        [
                            -89.7191680003938,
                            35.985975999916
                        ],
                        [
                            -89.7284419998762,
                            35.9935680003498
                        ],
                        [
                            -89.7330949999601,
                            36.0006079997882
                        ],
                        [
                            -89.7069320002981,
                            36.0009810002107
                        ],
                        [
                            -89.7035710003749,
                            36.0080399996589
                        ],
                        [
                            -89.6924369995709,
                            36.0205069998186
                        ],
                        [
                            -89.6885769998805,
                            36.0292379999638
                        ],
                        [
                            -89.6788210003492,
                            36.0846360003597
                        ],
                        [
                            -89.6724629996527,
                            36.0918369999119
                        ],
                        [
                            -89.666597999774,
                            36.0958020002264
                        ],
                        [
                            -89.6283050003214,
                            36.1068529997718
                        ],
                        [
                            -89.6005009996209,
                            36.1205150002158
                        ],
                        [
                            -89.5930699999451,
                            36.129699000072
                        ],
                        [
                            -89.5922060003048,
                            36.1501199998752
                        ],
                        [
                            -89.6008710004151,
                            36.1645580003422
                        ],
                        [
                            -89.6129829997639,
                            36.1759750001802
                        ],
                        [
                            -89.6226770002692,
                            36.1825779998312
                        ],
                        [
                            -89.6368940002638,
                            36.1889510002935
                        ],
                        [
                            -89.6548760004125,
                            36.2015300002649
                        ],
                        [
                            -89.6795479997221,
                            36.2159110002374
                        ],
                        [
                            -89.6996770003493,
                            36.230821000004
                        ],
                        [
                            -89.70525000015,
                            36.2365680000942
                        ],
                        [
                            -89.7055249996113,
                            36.2389830000286
                        ],
                        [
                            -89.6985679995884,
                            36.25059100007
                        ],
                        [
                            -89.6952350001526,
                            36.2527659998872
                        ],
                        [
                            -89.6780430000243,
                            36.2482870001655
                        ],
                        [
                            -89.6525199996966,
                            36.2506920001523
                        ],
                        [
                            -89.6421819996591,
                            36.2494859998313
                        ],
                        [
                            -89.6111449999802,
                            36.2392709997977
                        ],
                        [
                            -89.6023739995647,
                            36.2381060002447
                        ],
                        [
                            -89.5873260004402,
                            36.2394840000614
                        ],
                        [
                            -89.5775440000713,
                            36.2422619998458
                        ],
                        [
                            -89.5622059996203,
                            36.2509090000381
                        ],
                        [
                            -89.5535630001496,
                            36.2503409996923
                        ],
                        [
                            -89.5447430002286,
                            36.2474840002193
                        ],
                        [
                            -89.5392289999792,
                            36.2488210002333
                        ],
                        [
                            -89.5347449999497,
                            36.2525730000484
                        ],
                        [
                            -89.5345070002983,
                            36.2618029998204
                        ],
                        [
                            -89.5364410003415,
                            36.2728269999102
                        ],
                        [
                            -89.5394869997221,
                            36.2773680003541
                        ],
                        [
                            -89.544796999757,
                            36.2804580003384
                        ],
                        [
                            -89.5542960002686,
                            36.2777530000388
                        ],
                        [
                            -89.5784920002755,
                            36.288316999728
                        ],
                        [
                            -89.58982000035,
                            36.2968139997317
                        ],
                        [
                            -89.6118189996497,
                            36.3090880000642
                        ],
                        [
                            -89.6202550003294,
                            36.3230060000856
                        ],
                        [
                            -89.6198000000447,
                            36.3295460003538
                        ],
                        [
                            -89.6158409997218,
                            36.3360850001595
                        ],
                        [
                            -89.6106889998706,
                            36.3404419999384
                        ],
                        [
                            -89.605668000439,
                            36.3422340002686
                        ],
                        [
                            -89.5816359995654,
                            36.3423570002973
                        ],
                        [
                            -89.5604389999817,
                            36.3377459998622
                        ],
                        [
                            -89.5450059999944,
                            36.3368089997813
                        ],
                        [
                            -89.5318219996945,
                            36.3392460001634
                        ],
                        [
                            -89.5189999997929,
                            36.3485999999969
                        ],
                        [
                            -89.5131779995719,
                            36.3598969998308
                        ],
                        [
                            -89.5097220001125,
                            36.3736260001334
                        ],
                        [
                            -89.5103799998884,
                            36.378355999696
                        ],
                        [
                            -89.5252930001921,
                            36.4004459997604
                        ],
                        [
                            -89.5423370001824,
                            36.4201030000283
                        ],
                        [
                            -89.5452550004147,
                            36.427079000283
                        ],
                        [
                            -89.545061000246,
                            36.4349149997603
                        ],
                        [
                            -89.543405999862,
                            36.4387700003507
                        ],
                        [
                            -89.5251900002599,
                            36.4539909999172
                        ],
                        [
                            -89.5197200003914,
                            36.4670019999501
                        ],
                        [
                            -89.520641999758,
                            36.4786679999244
                        ],
                        [
                            -89.5345240000168,
                            36.4914319999867
                        ],
                        [
                            -89.5392320003523,
                            36.4979339999487
                        ],
                        [
                            -89.4854270003714,
                            36.4974909999145
                        ],
                        [
                            -89.493376999906,
                            36.4789590001873
                        ],
                        [
                            -89.4930820003533,
                            36.4705000002609
                        ],
                        [
                            -89.4861930001024,
                            36.4621140002899
                        ],
                        [
                            -89.471894999917,
                            36.4575599997246
                        ],
                        [
                            -89.460766999859,
                            36.4586859998257
                        ],
                        [
                            -89.4489640003067,
                            36.4648820003093
                        ],
                        [
                            -89.4300719995886,
                            36.4821009998405
                        ],
                        [
                            -89.4182199996325,
                            36.4990289997789
                        ],
                        [
                            -89.3910569998878,
                            36.4992159998474
                        ],
                        [
                            -89.301022000369,
                            36.5072329999293
                        ],
                        [
                            -88.9597459998297,
                            36.5020760001394
                        ],
                        [
                            -88.5418740003397,
                            36.502175999753
                        ],
                        [
                            -88.216645000081,
                            36.4998269997338
                        ],
                        [
                            -88.0532929997222,
                            36.497057999768
                        ],
                        [
                            -88.039069000055,
                            36.510784000243
                        ],
                        [
                            -88.0374320001127,
                            36.5228580000517
                        ],
                        [
                            -88.0346829999348,
                            36.5278449996655
                        ],
                        [
                            -88.0326750002718,
                            36.5369850001342
                        ],
                        [
                            -88.0326490003325,
                            36.5420949996722
                        ],
                        [
                            -88.0350930000136,
                            36.5601889999794
                        ],
                        [
                            -88.0404969997599,
                            36.576823999771
                        ],
                        [
                            -88.0448619999637,
                            36.6030699996432
                        ],
                        [
                            -88.0556250001487,
                            36.6303400002476
                        ],
                        [
                            -88.0570160000436,
                            36.6403429999449
                        ],
                        [
                            -88.0668360000473,
                            36.6558559997368
                        ],
                        [
                            -88.070540999927,
                            36.6782550000303
                        ],
                        [
                            -88.0122030004112,
                            36.6771900003587
                        ],
                        [
                            -87.9268749996064,
                            36.6689359998071
                        ],
                        [
                            -87.8495439999885,
                            36.6636949998913
                        ],
                        [
                            -87.8533190001884,
                            36.6331600000416
                        ],
                        [
                            -87.4746869996318,
                            36.6404149997715
                        ],
                        [
                            -87.0704909996379,
                            36.6427299996558
                        ],
                        [
                            -86.5899060003825,
                            36.6524430002861
                        ],
                        [
                            -86.5639090004018,
                            36.6335619996496
                        ],
                        [
                            -86.5512220002194,
                            36.6379980002808
                        ],
                        [
                            -86.5499429995791,
                            36.6447590003053
                        ],
                        [
                            -86.5433520001524,
                            36.6438549998067
                        ],
                        [
                            -86.5438219996074,
                            36.6404269997361
                        ],
                        [
                            -86.5077810002153,
                            36.6523789998674
                        ],
                        [
                            -86.4934279997784,
                            36.6522400003515
                        ],
                        [
                            -86.3321259998138,
                            36.6487010002252
                        ],
                        [
                            -86.2361550002712,
                            36.6418779999329
                        ],
                        [
                            -86.0248190003277,
                            36.6302929997492
                        ],
                        [
                            -85.8324320001053,
                            36.6221000001221
                        ],
                        [
                            -85.7888580000319,
                            36.6220550003567
                        ],
                        [
                            -85.676529999689,
                            36.618222999676
                        ],
                        [
                            -85.5015070000223,
                            36.6148550002275
                        ],
                        [
                            -85.3247910003369,
                            36.6245079997247
                        ],
                        [
                            -85.300415000405,
                            36.624448999707
                        ],
                        [
                            -85.2939040004459,
                            36.6263549998507
                        ],
                        [
                            -85.1862700001948,
                            36.6252079996645
                        ],
                        [
                            -85.0268610002654,
                            36.6192989999816
                        ],
                        [
                            -84.8300350002194,
                            36.6045359998007
                        ],
                        [
                            -84.5404739999822,
                            36.5963040000978
                        ],
                        [
                            -84.4488960002325,
                            36.5962109997332
                        ],
                        [
                            -84.2715450003374,
                            36.5917029999358
                        ],
                        [
                            -83.9999429997844,
                            36.5898569996559
                        ],
                        [
                            -83.6907890002937,
                            36.5825960002864
                        ],
                        [
                            -83.6750139999997,
                            36.6008100002783
                        ],
                        [
                            -83.622000999689,
                            36.5979739999654
                        ],
                        [
                            -83.5569220000604,
                            36.5973849998572
                        ],
                        [
                            -83.2762999995698,
                            36.5981870002347
                        ],
                        [
                            -83.2610990002844,
                            36.5938869999873
                        ],
                        [
                            -83.2489329996106,
                            36.5938269999794
                        ],
                        [
                            -82.6634909998711,
                            36.5936440000278
                        ],
                        [
                            -82.487237999968,
                            36.5958209997755
                        ],
                        [
                            -82.4665760001366,
                            36.5944800000806
                        ],
                        [
                            -82.2226150000775,
                            36.5960300003309
                        ],
                        [
                            -82.1737870000456,
                            36.5946070000493
                        ],
                        [
                            -81.9341440003346,
                            36.5942129999361
                        ],
                        [
                            -81.9226439998327,
                            36.6162130002575
                        ],
                        [
                            -81.6468999995569,
                            36.6119180002695
                        ],
                        [
                            -81.6800109999193,
                            36.5858179996454
                        ],
                        [
                            -81.6769789998841,
                            36.5811239999424
                        ],
                        [
                            -81.6792140002262,
                            36.5786679997141
                        ],
                        [
                            -81.6770469996562,
                            36.5707549999169
                        ],
                        [
                            -81.6800720000187,
                            36.56867199995
                        ],
                        [
                            -81.6912860002903,
                            36.5677160001577
                        ],
                        [
                            -81.6918779999441,
                            36.5659409999698
                        ],
                        [
                            -81.6895480000657,
                            36.5614409999225
                        ],
                        [
                            -81.6909980004103,
                            36.5553900002694
                        ],
                        [
                            -81.6902209999103,
                            36.5516860000305
                        ],
                        [
                            -81.6933609999005,
                            36.5509990001271
                        ],
                        [
                            -81.6937900002459,
                            36.5479770000695
                        ],
                        [
                            -81.6997680001025,
                            36.5403159999463
                        ],
                        [
                            -81.6997949998667,
                            36.5367270001968
                        ],
                        [
                            -81.7080209995858,
                            36.5358299999513
                        ],
                        [
                            -81.7080979995787,
                            36.5241139997382
                        ],
                        [
                            -81.702970000017,
                            36.5211410002483
                        ],
                        [
                            -81.6983439996973,
                            36.5097170000587
                        ],
                        [
                            -81.698398000124,
                            36.5064359998167
                        ],
                        [
                            -81.6970909998946,
                            36.5059199996449
                        ],
                        [
                            -81.6974239999805,
                            36.5021549997366
                        ],
                        [
                            -81.7002540003493,
                            36.4984570002495
                        ],
                        [
                            -81.6972449998804,
                            36.4964239999371
                        ],
                        [
                            -81.69594900042,
                            36.4916190003452
                        ],
                        [
                            -81.6979239995727,
                            36.4889489999225
                        ],
                        [
                            -81.696350000278,
                            36.4755370003509
                        ],
                        [
                            -81.6946079998554,
                            36.473372000358
                        ],
                        [
                            -81.6961789996754,
                            36.4697450002226
                        ],
                        [
                            -81.6950289998049,
                            36.4674580001919
                        ],
                        [
                            -81.6990009996483,
                            36.4640680000074
                        ],
                        [
                            -81.7081179996702,
                            36.4622259996866
                        ],
                        [
                            -81.7096679995739,
                            36.4593789997039
                        ],
                        [
                            -81.7167970000242,
                            36.456669000139
                        ],
                        [
                            -81.7137869997304,
                            36.44717299992
                        ],
                        [
                            -81.7178260004192,
                            36.4450230001924
                        ],
                        [
                            -81.7144980001081,
                            36.4414800001571
                        ],
                        [
                            -81.7148029997066,
                            36.4372270001804
                        ],
                        [
                            -81.7213170000387,
                            36.4339000003547
                        ],
                        [
                            -81.7178470003356,
                            36.429856999665
                        ],
                        [
                            -81.7207270000348,
                            36.4226510002782
                        ],
                        [
                            -81.7271519997802,
                            36.4198690000698
                        ],
                        [
                            -81.7294409996506,
                            36.4158949996947
                        ],
                        [
                            -81.7336500002188,
                            36.4146839996491
                        ],
                        [
                            -81.734518000057,
                            36.4124970003087
                        ],
                        [
                            -81.737659999697,
                            36.4135180000179
                        ],
                        [
                            -81.7417050002337,
                            36.4124219996805
                        ],
                        [
                            -81.7400220002607,
                            36.4083019997255
                        ],
                        [
                            -81.7414059995846,
                            36.4030639998354
                        ],
                        [
                            -81.7382699997924,
                            36.4008340002437
                        ],
                        [
                            -81.7393679997844,
                            36.3955910000337
                        ],
                        [
                            -81.731958000025,
                            36.3949290001743
                        ],
                        [
                            -81.7288149996617,
                            36.3915500001619
                        ],
                        [
                            -81.7305129997033,
                            36.3882850001305
                        ],
                        [
                            -81.729497999552,
                            36.3854389997744
                        ],
                        [
                            -81.7346600003472,
                            36.3794420000313
                        ],
                        [
                            -81.7260660003821,
                            36.368785000245
                        ],
                        [
                            -81.7271159997952,
                            36.365586000115
                        ],
                        [
                            -81.7228829998358,
                            36.3612369997277
                        ],
                        [
                            -81.7225199996127,
                            36.3586400002506
                        ],
                        [
                            -81.7243730003633,
                            36.357694999999
                        ],
                        [
                            -81.7215210002532,
                            36.3513040003035
                        ],
                        [
                            -81.716798999674,
                            36.347595000241
                        ],
                        [
                            -81.7082420004171,
                            36.3463329998194
                        ],
                        [
                            -81.706111999657,
                            36.3437239997573
                        ],
                        [
                            -81.7053230003598,
                            36.3414149999425
                        ],
                        [
                            -81.7074749999629,
                            36.3352249997168
                        ],
                        [
                            -81.7184940002409,
                            36.3361219999975
                        ],
                        [
                            -81.7278380000435,
                            36.3413829997549
                        ],
                        [
                            -81.7360289996026,
                            36.3429719996975
                        ],
                        [
                            -81.7396060003341,
                            36.3397569996892
                        ],
                        [
                            -81.7457330001536,
                            36.3375920002677
                        ],
                        [
                            -81.7544700002339,
                            36.3369990002825
                        ],
                        [
                            -81.7611319998057,
                            36.3402539999174
                        ],
                        [
                            -81.7649539998613,
                            36.3387219996576
                        ],
                        [
                            -81.7691700001022,
                            36.3424630002662
                        ],
                        [
                            -81.774076999731,
                            36.3438240001882
                        ],
                        [
                            -81.7788889998236,
                            36.34856600004
                        ],
                        [
                            -81.7897299998263,
                            36.3483279999264
                        ],
                        [
                            -81.7907559998483,
                            36.355641999893
                        ],
                        [
                            -81.7935879998669,
                            36.3589579997784
                        ],
                        [
                            -81.7930849999017,
                            36.3624079996442
                        ],
                        [
                            -81.7972800002262,
                            36.3585449996914
                        ],
                        [
                            -81.8009320004025,
                            36.3579989997807
                        ],
                        [
                            -81.8102760002051,
                            36.3518500001487
                        ],
                        [
                            -81.8330489996316,
                            36.347331999645
                        ],
                        [
                            -81.8519110002125,
                            36.3370119996448
                        ],
                        [
                            -81.8549879995552,
                            36.337211000188
                        ],
                        [
                            -81.8655089998908,
                            36.3291930002165
                        ],
                        [
                            -81.8666279997992,
                            36.3262229999518
                        ],
                        [
                            -81.879520000021,
                            36.3137889997085
                        ],
                        [
                            -81.8872360001022,
                            36.3092380000287
                        ],
                        [
                            -81.8983459997185,
                            36.3072519996457
                        ],
                        [
                            -81.9081670004454,
                            36.3019659999487
                        ],
                        [
                            -81.9390080003057,
                            36.2560330000674
                        ],
                        [
                            -81.9622159999254,
                            36.2249489996805
                        ],
                        [
                            -82.0262009997601,
                            36.1312079996502
                        ],
                        [
                            -82.0300670001966,
                            36.1233930001085
                        ],
                        [
                            -82.0338390000235,
                            36.1204750000205
                        ],
                        [
                            -82.0389239999275,
                            36.1215670000662
                        ],
                        [
                            -82.0447550003693,
                            36.1255560002165
                        ],
                        [
                            -82.0543169997319,
                            36.1266239998432
                        ],
                        [
                            -82.0618689997815,
                            36.1130619997449
                        ],
                        [
                            -82.0800280003806,
                            36.1064329998392
                        ],
                        [
                            -82.0810789996186,
                            36.1031699997524
                        ],
                        [
                            -82.0865319997686,
                            36.1060020000051
                        ],
                        [
                            -82.1003100002703,
                            36.105670999817
                        ],
                        [
                            -82.1054179997406,
                            36.1078369999648
                        ],
                        [
                            -82.1163519996298,
                            36.1043009998071
                        ],
                        [
                            -82.1302249996677,
                            36.104559000284
                        ],
                        [
                            -82.1378560002584,
                            36.1195099996561
                        ],
                        [
                            -82.136986999697,
                            36.1290390003512
                        ],
                        [
                            -82.1410449997541,
                            36.1387789999589
                        ],
                        [
                            -82.144451999708,
                            36.1404059996721
                        ],
                        [
                            -82.1443680000424,
                            36.1441380001213
                        ],
                        [
                            -82.1486889998653,
                            36.1499029996916
                        ],
                        [
                            -82.1656500000149,
                            36.1449719999714
                        ],
                        [
                            -82.1685089997977,
                            36.14671399996
                        ],
                        [
                            -82.172934000276,
                            36.146500000037
                        ],
                        [
                            -82.1772169995657,
                            36.1423730002261
                        ],
                        [
                            -82.1836260003159,
                            36.1439110001056
                        ],
                        [
                            -82.1874690002878,
                            36.1509349999058
                        ],
                        [
                            -82.1929430003543,
                            36.1491090001732
                        ],
                        [
                            -82.2041049998491,
                            36.1572520001507
                        ],
                        [
                            -82.2136400003458,
                            36.1590510001679
                        ],
                        [
                            -82.2220640004316,
                            36.1569600001855
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 35.8585557,
            "name": "Tennessee",
            "intptlon": -86.349358,
            "geo_point_2d": [
                35.8430403639,
                -86.3432418457
            ],
            "geoid": "47",
            "mtfcc": "G4000",
            "region": 3
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -86.3432418457,
                35.8430403639
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "08705077e0104833054661631c99601b7e8073e3",
        "fields": {
            "arealand": "-1938121641",
            "objectid": 31,
            "basename": "Wyoming",
            "stusab": "WY",
            "statens": "01779807",
            "centlon": -107.5514552,
            "state": "56",
            "gid": 9,
            "centlat": 42.9960991,
            "division": 8,
            "areawater": "1861259579",
            "oid": "-1857883199",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -111.044893000417,
                            43.3157190000423
                        ],
                        [
                            -111.046540000405,
                            43.9141070000882
                        ],
                        [
                            -111.047348999794,
                            43.9999210000068
                        ],
                        [
                            -111.049076999973,
                            44.0201299998467
                        ],
                        [
                            -111.048974000041,
                            44.4740719997397
                        ],
                        [
                            -111.052433999698,
                            44.4784110002825
                        ],
                        [
                            -111.054556000062,
                            45.0009549996844
                        ],
                        [
                            -110.790118999944,
                            45.0008829997368
                        ],
                        [
                            -110.704476000394,
                            44.9921740000481
                        ],
                        [
                            -110.445742999598,
                            44.9936819997972
                        ],
                        [
                            -110.407116000235,
                            44.9994960002789
                        ],
                        [
                            -110.325197999757,
                            44.9996770000252
                        ],
                        [
                            -110.284773999719,
                            44.997359000155
                        ],
                        [
                            -110.183221000155,
                            44.996953000271
                        ],
                        [
                            -110.105775999836,
                            45.0035330001032
                        ],
                        [
                            -109.825663000057,
                            45.0032240000688
                        ],
                        [
                            -109.761105999762,
                            45.0018020001719
                        ],
                        [
                            -109.103228000338,
                            45.0058150000639
                        ],
                        [
                            -109.080700000235,
                            44.9994400001012
                        ],
                        [
                            -107.476010000065,
                            45.0014239997849
                        ],
                        [
                            -107.138121000286,
                            45.0001280000325
                        ],
                        [
                            -107.084886000218,
                            44.9966000002652
                        ],
                        [
                            -106.126207000345,
                            44.993267999834
                        ],
                        [
                            -105.928173999629,
                            44.9934869999385
                        ],
                        [
                            -105.913149000071,
                            45.000238000007
                        ],
                        [
                            -105.856984000074,
                            45.0003660000927
                        ],
                        [
                            -104.889161000416,
                            45.0000260002486
                        ],
                        [
                            -104.057878999927,
                            44.9976049997522
                        ],
                        [
                            -104.055913000097,
                            44.8749860003098
                        ],
                        [
                            -104.055099999612,
                            43.5650150002317
                        ],
                        [
                            -104.052584999786,
                            42.6501939999332
                        ],
                        [
                            -104.053249000308,
                            41.0014059997388
                        ],
                        [
                            -104.625519000342,
                            41.0014279999778
                        ],
                        [
                            -104.855272999554,
                            40.9980480002953
                        ],
                        [
                            -105.729406000042,
                            40.9968220001816
                        ],
                        [
                            -106.195191999695,
                            40.9975780002449
                        ],
                        [
                            -106.455714999696,
                            41.0021180001111
                        ],
                        [
                            -107.000606000075,
                            41.0034439997708
                        ],
                        [
                            -108.046539000399,
                            41.0020639997069
                        ],
                        [
                            -108.526029000036,
                            40.9995919998175
                        ],
                        [
                            -109.173904999881,
                            41.0008749999698
                        ],
                        [
                            -109.232505000236,
                            41.0021069997687
                        ],
                        [
                            -109.543697000425,
                            40.9983960000515
                        ],
                        [
                            -110.086964000382,
                            40.9971279997827
                        ],
                        [
                            -110.274111999817,
                            40.9948170000923
                        ],
                        [
                            -111.046814999867,
                            40.997875000101
                        ],
                        [
                            -111.045788999845,
                            41.5652080000763
                        ],
                        [
                            -111.047234000166,
                            42.4690960000418
                        ],
                        [
                            -111.043494999951,
                            42.7475519999091
                        ],
                        [
                            -111.044893000417,
                            43.3157190000423
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 42.9918024,
            "name": "Wyoming",
            "intptlon": -107.5419255,
            "geo_point_2d": [
                42.9995710878,
                -107.551330644
            ],
            "geoid": "56",
            "mtfcc": "G4000",
            "region": 4
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -107.551330644,
                42.9995710878
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "91a604e58bd249f88f68fa81f81a71e134fa19d6",
        "fields": {
            "arealand": "-1677576109",
            "objectid": 33,
            "basename": "North Dakota",
            "stusab": "ND",
            "statens": "01779797",
            "centlon": -100.4661129,
            "state": "38",
            "gid": 11,
            "centlat": 47.4504454,
            "division": 4,
            "areawater": "104843764",
            "oid": "272545829",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -104.045370999845,
                            47.3301280001904
                        ],
                        [
                            -104.043661999933,
                            47.7325560002486
                        ],
                        [
                            -104.04141599972,
                            47.8626850001936
                        ],
                        [
                            -104.045530999679,
                            48.1283339997655
                        ],
                        [
                            -104.048689000111,
                            48.9995929999751
                        ],
                        [
                            -102.21403099957,
                            48.9985529997173
                        ],
                        [
                            -97.2287219998135,
                            49.0005620000818
                        ],
                        [
                            -97.2335239998604,
                            48.9981710001653
                        ],
                        [
                            -97.2342009999029,
                            48.9962859999561
                        ],
                        [
                            -97.2275130003918,
                            48.994561000059
                        ],
                        [
                            -97.2266460003785,
                            48.9929900001928
                        ],
                        [
                            -97.234119999712,
                            48.9889730000004
                        ],
                        [
                            -97.2382609996098,
                            48.9826499999662
                        ],
                        [
                            -97.2386120001374,
                            48.9665000002683
                        ],
                        [
                            -97.2296499999262,
                            48.9642259999533
                        ],
                        [
                            -97.2274980003232,
                            48.9623419997464
                        ],
                        [
                            -97.2316860000766,
                            48.9561599998941
                        ],
                        [
                            -97.2339959998635,
                            48.9474339998402
                        ],
                        [
                            -97.2266239997388,
                            48.9458259999721
                        ],
                        [
                            -97.2270530000843,
                            48.9392259998617
                        ],
                        [
                            -97.224614000426,
                            48.9337979997217
                        ],
                        [
                            -97.2144060000848,
                            48.9316410002227
                        ],
                        [
                            -97.2198059996331,
                            48.9221459997858
                        ],
                        [
                            -97.2107759996499,
                            48.916026999909
                        ],
                        [
                            -97.2126539996165,
                            48.9089560001274
                        ],
                        [
                            -97.211399000164,
                            48.9056020000067
                        ],
                        [
                            -97.2073999996581,
                            48.9025259998301
                        ],
                        [
                            -97.1932660004025,
                            48.8983360001668
                        ],
                        [
                            -97.190017999559,
                            48.8955160000156
                        ],
                        [
                            -97.1901880003366,
                            48.8929570000895
                        ],
                        [
                            -97.1919659998459,
                            48.8917510001531
                        ],
                        [
                            -97.2016269998409,
                            48.8919320000419
                        ],
                        [
                            -97.1973279997593,
                            48.8873479999757
                        ],
                        [
                            -97.1997930002551,
                            48.8832769997507
                        ],
                        [
                            -97.199394000047,
                            48.8811130000968
                        ],
                        [
                            -97.1902589995834,
                            48.876725000157
                        ],
                        [
                            -97.1808550004047,
                            48.8772259998425
                        ],
                        [
                            -97.1778140001487,
                            48.8756389998434
                        ],
                        [
                            -97.1793399997628,
                            48.8731350002853
                        ],
                        [
                            -97.1893709996537,
                            48.8667499998119
                        ],
                        [
                            -97.1905730003011,
                            48.8642789997131
                        ],
                        [
                            -97.1791659996856,
                            48.8628440001679
                        ],
                        [
                            -97.1788340003229,
                            48.8566610000556
                        ],
                        [
                            -97.174136999858,
                            48.8533299999502
                        ],
                        [
                            -97.1730990001405,
                            48.8506249999371
                        ],
                        [
                            -97.17527199966,
                            48.8486660000958
                        ],
                        [
                            -97.1829230003421,
                            48.8469120000092
                        ],
                        [
                            -97.1757860003942,
                            48.8448319997599
                        ],
                        [
                            -97.173884000138,
                            48.8379930000544
                        ],
                        [
                            -97.176593000133,
                            48.8355250001885
                        ],
                        [
                            -97.1841639995509,
                            48.833902000221
                        ],
                        [
                            -97.1846459995998,
                            48.8314420000952
                        ],
                        [
                            -97.1787770004214,
                            48.8263809999065
                        ],
                        [
                            -97.1779930002488,
                            48.8228350001498
                        ],
                        [
                            -97.1808210000695,
                            48.8191690000149
                        ],
                        [
                            -97.1887680001293,
                            48.8168980000634
                        ],
                        [
                            -97.190273999652,
                            48.815134999969
                        ],
                        [
                            -97.1806820001523,
                            48.8151710000169
                        ],
                        [
                            -97.1720610004229,
                            48.8115200001213
                        ],
                        [
                            -97.1636459996596,
                            48.8119700002536
                        ],
                        [
                            -97.1581180000648,
                            48.810318999957
                        ],
                        [
                            -97.1575070001445,
                            48.8079810002393
                        ],
                        [
                            -97.1592379997981,
                            48.8061650000385
                        ],
                        [
                            -97.179049000408,
                            48.801100999847
                        ],
                        [
                            -97.1816400004022,
                            48.7985170001456
                        ],
                        [
                            -97.1775730001242,
                            48.797072000117
                        ],
                        [
                            -97.1693919997126,
                            48.8013100000994
                        ],
                        [
                            -97.1632609996951,
                            48.8005790001295
                        ],
                        [
                            -97.1616659995857,
                            48.7983259999448
                        ],
                        [
                            -97.1624670003751,
                            48.7966570002857
                        ],
                        [
                            -97.171360000091,
                            48.7965330000521
                        ],
                        [
                            -97.1740409995985,
                            48.7938789997759
                        ],
                        [
                            -97.1674619998674,
                            48.791252000283
                        ],
                        [
                            -97.1582429997383,
                            48.7912050001685
                        ],
                        [
                            -97.1568779997826,
                            48.7895779998253
                        ],
                        [
                            -97.1595990003714,
                            48.7854660001151
                        ],
                        [
                            -97.1589239999787,
                            48.7832570002028
                        ],
                        [
                            -97.1474689996826,
                            48.7814409997944
                        ],
                        [
                            -97.1485700000477,
                            48.7792249998367
                        ],
                        [
                            -97.1589830004283,
                            48.775711000287
                        ],
                        [
                            -97.1577409995979,
                            48.7733649999308
                        ],
                        [
                            -97.1517329996041,
                            48.7719320002509
                        ],
                        [
                            -97.1454559999968,
                            48.7647899999621
                        ],
                        [
                            -97.1467930003633,
                            48.7618659999013
                        ],
                        [
                            -97.1544390001243,
                            48.7582120002093
                        ],
                        [
                            -97.1537949996938,
                            48.7539930000191
                        ],
                        [
                            -97.1491500000058,
                            48.75035500006
                        ],
                        [
                            -97.1404699998269,
                            48.7509230000578
                        ],
                        [
                            -97.1375380002492,
                            48.7496440002557
                        ],
                        [
                            -97.1366650003881,
                            48.7425150001113
                        ],
                        [
                            -97.1421329997084,
                            48.7361720000791
                        ],
                        [
                            -97.1388339997096,
                            48.7342839999979
                        ],
                        [
                            -97.1302730002547,
                            48.7342309997579
                        ],
                        [
                            -97.1283400000364,
                            48.7321359998618
                        ],
                        [
                            -97.1298880002902,
                            48.7297319997705
                        ],
                        [
                            -97.1363740001349,
                            48.7285000000733
                        ],
                        [
                            -97.1374240004464,
                            48.7265659999517
                        ],
                        [
                            -97.1255180000636,
                            48.7204510002564
                        ],
                        [
                            -97.1193999995665,
                            48.7116249999987
                        ],
                        [
                            -97.1101119998404,
                            48.7079779998588
                        ],
                        [
                            -97.1113019998939,
                            48.7057299999337
                        ],
                        [
                            -97.1202919996942,
                            48.7039990002057
                        ],
                        [
                            -97.119904000255,
                            48.7012480001495
                        ],
                        [
                            -97.1086769995645,
                            48.6909909999515
                        ],
                        [
                            -97.093364999951,
                            48.6865839999362
                        ],
                        [
                            -97.0903129998244,
                            48.6844039999439
                        ],
                        [
                            -97.0924140002721,
                            48.6821169999811
                        ],
                        [
                            -97.0984139998701,
                            48.6834060001705
                        ],
                        [
                            -97.1012760000259,
                            48.6822990001578
                        ],
                        [
                            -97.1005620001735,
                            48.6684059998269
                        ],
                        [
                            -97.1092289999336,
                            48.6642499998686
                        ],
                        [
                            -97.1079600002373,
                            48.6626699997603
                        ],
                        [
                            -97.1007239996569,
                            48.6627280000256
                        ],
                        [
                            -97.0986909998795,
                            48.6609950001489
                        ],
                        [
                            -97.1059020003456,
                            48.6535619997592
                        ],
                        [
                            -97.1079240002523,
                            48.6479219997493
                        ],
                        [
                            -97.1156920002121,
                            48.6445609997624
                        ],
                        [
                            -97.1098580002955,
                            48.6384769998506
                        ],
                        [
                            -97.1094499998665,
                            48.6324689998686
                        ],
                        [
                            -97.1146839997334,
                            48.6303419997964
                        ],
                        [
                            -97.1259919997165,
                            48.6341200002403
                        ],
                        [
                            -97.131140000268,
                            48.6314729998118
                        ],
                        [
                            -97.1296470002658,
                            48.6279270001792
                        ],
                        [
                            -97.1201419999064,
                            48.6216800000767
                        ],
                        [
                            -97.1194950000011,
                            48.6185599999945
                        ],
                        [
                            -97.1237370001812,
                            48.6181020001762
                        ],
                        [
                            -97.129633999847,
                            48.6223900001053
                        ],
                        [
                            -97.1338590003087,
                            48.6227779997127
                        ],
                        [
                            -97.1353149996027,
                            48.6209099999639
                        ],
                        [
                            -97.1295540003794,
                            48.6168009997485
                        ],
                        [
                            -97.1295760001207,
                            48.6136930002574
                        ],
                        [
                            -97.1437939999401,
                            48.6147659997048
                        ],
                        [
                            -97.1506489998558,
                            48.6116759998751
                        ],
                        [
                            -97.1502909996555,
                            48.609667999863
                        ],
                        [
                            -97.1476760002702,
                            48.6081820000414
                        ],
                        [
                            -97.1374910003935,
                            48.6088750000647
                        ],
                        [
                            -97.1342910001289,
                            48.6073999999801
                        ],
                        [
                            -97.1438220004275,
                            48.5983590000782
                        ],
                        [
                            -97.1513679997311,
                            48.5980100001666
                        ],
                        [
                            -97.1540270003957,
                            48.5954059999287
                        ],
                        [
                            -97.1523379996766,
                            48.5938379998843
                        ],
                        [
                            -97.1452240001931,
                            48.5940680000824
                        ],
                        [
                            -97.1422790001966,
                            48.5926129998931
                        ],
                        [
                            -97.1438910000245,
                            48.582111999802
                        ],
                        [
                            -97.1473309995904,
                            48.5809640000855
                        ],
                        [
                            -97.1563639999467,
                            48.5827080001587
                        ],
                        [
                            -97.1606310002412,
                            48.5809360000856
                        ],
                        [
                            -97.1587249997871,
                            48.5782209997191
                        ],
                        [
                            -97.1487089999648,
                            48.5763669999925
                        ],
                        [
                            -97.1478469999744,
                            48.5730290002815
                        ],
                        [
                            -97.1491700000973,
                            48.5700140001849
                        ],
                        [
                            -97.154053000335,
                            48.5667550000172
                        ],
                        [
                            -97.1719710000113,
                            48.5649500000387
                        ],
                        [
                            -97.175423000171,
                            48.5619569997705
                        ],
                        [
                            -97.1732189997911,
                            48.5596090002728
                        ],
                        [
                            -97.166199000019,
                            48.557244000074
                        ],
                        [
                            -97.1448319996577,
                            48.5572380002755
                        ],
                        [
                            -97.1389509998854,
                            48.5551200002058
                        ],
                        [
                            -97.138529999936,
                            48.5524329999166
                        ],
                        [
                            -97.1415850004357,
                            48.5512709998133
                        ],
                        [
                            -97.1555050003293,
                            48.55112299979
                        ],
                        [
                            -97.1639369999127,
                            48.5539830002531
                        ],
                        [
                            -97.1659649996673,
                            48.5526480000836
                        ],
                        [
                            -97.1659839999338,
                            48.5489319999541
                        ],
                        [
                            -97.1610250004265,
                            48.5420089999931
                        ],
                        [
                            -97.1456080003327,
                            48.5380999999127
                        ],
                        [
                            -97.1413579997566,
                            48.5354520002899
                        ],
                        [
                            -97.1421760002645,
                            48.532239999976
                        ],
                        [
                            -97.1535970002254,
                            48.5295079999048
                        ],
                        [
                            -97.1547730000352,
                            48.524163000258
                        ],
                        [
                            -97.1524230000654,
                            48.5203640002486
                        ],
                        [
                            -97.145405999768,
                            48.5187349998636
                        ],
                        [
                            -97.1307770000448,
                            48.5226680000386
                        ],
                        [
                            -97.1267580003458,
                            48.5201570002221
                        ],
                        [
                            -97.1281140000806,
                            48.5177549997789
                        ],
                        [
                            -97.1366190003573,
                            48.5121830000514
                        ],
                        [
                            -97.1502670002644,
                            48.5115999999795
                        ],
                        [
                            -97.1491900001888,
                            48.5008399997256
                        ],
                        [
                            -97.145337999996,
                            48.4978780002032
                        ],
                        [
                            -97.1345880002298,
                            48.4942379997872
                        ],
                        [
                            -97.1316110004462,
                            48.4915519999009
                        ],
                        [
                            -97.1315000001181,
                            48.4890059998701
                        ],
                        [
                            -97.1351050004387,
                            48.487728999747
                        ],
                        [
                            -97.1399550001661,
                            48.4919630001682
                        ],
                        [
                            -97.1430170003385,
                            48.4923339997576
                        ],
                        [
                            -97.1393390002229,
                            48.4872769998887
                        ],
                        [
                            -97.1402909997268,
                            48.4847219997799
                        ],
                        [
                            -97.1592050001861,
                            48.4811069998045
                        ],
                        [
                            -97.1626680002165,
                            48.4777829997439
                        ],
                        [
                            -97.1609819998705,
                            48.4758050000387
                        ],
                        [
                            -97.1549160001503,
                            48.4749110001841
                        ],
                        [
                            -97.1346009997502,
                            48.4775360001653
                        ],
                        [
                            -97.1308819996268,
                            48.4768549999712
                        ],
                        [
                            -97.1281629995861,
                            48.4743619997226
                        ],
                        [
                            -97.1306039997925,
                            48.471215000242
                        ],
                        [
                            -97.1407300001179,
                            48.4746910002721
                        ],
                        [
                            -97.147417999629,
                            48.4727530000368
                        ],
                        [
                            -97.1474839997512,
                            48.4684000001108
                        ],
                        [
                            -97.1430170003385,
                            48.4638229997888
                        ],
                        [
                            -97.1306320002799,
                            48.4634200000803
                        ],
                        [
                            -97.1285440002509,
                            48.4603009999592
                        ],
                        [
                            -97.130691999656,
                            48.4522070002829
                        ],
                        [
                            -97.1377829995732,
                            48.4476789997967
                        ],
                        [
                            -97.148110999565,
                            48.4440519999993
                        ],
                        [
                            -97.1506329999623,
                            48.4408159999405
                        ],
                        [
                            -97.1498110001548,
                            48.439152999764
                        ],
                        [
                            -97.1451709995914,
                            48.4380780001941
                        ],
                        [
                            -97.141086999595,
                            48.4385839999537
                        ],
                        [
                            -97.132823000241,
                            48.4437999998976
                        ],
                        [
                            -97.1280410002857,
                            48.4437079997949
                        ],
                        [
                            -97.1248240003026,
                            48.4418959997712
                        ],
                        [
                            -97.1236669998611,
                            48.4360339999117
                        ],
                        [
                            -97.1278179998046,
                            48.4312559998367
                        ],
                        [
                            -97.141242000304,
                            48.4337710000785
                        ],
                        [
                            -97.1458729997483,
                            48.4311479999028
                        ],
                        [
                            -97.1425970002139,
                            48.4276500000919
                        ],
                        [
                            -97.1246639995707,
                            48.4222160002607
                        ],
                        [
                            -97.1225830001127,
                            48.4179209998203
                        ],
                        [
                            -97.126043999595,
                            48.4161199997869
                        ],
                        [
                            -97.1335580000098,
                            48.4209160000822
                        ],
                        [
                            -97.1383960000417,
                            48.42164699975
                        ],
                        [
                            -97.1539939998854,
                            48.4181209999453
                        ],
                        [
                            -97.1550840003798,
                            48.4142900000688
                        ],
                        [
                            -97.1505300000301,
                            48.4127419999264
                        ],
                        [
                            -97.1467110003476,
                            48.4139409997044
                        ],
                        [
                            -97.1434790002959,
                            48.4180350000356
                        ],
                        [
                            -97.139879999823,
                            48.4184870002352
                        ],
                        [
                            -97.138453999768,
                            48.414547999883
                        ],
                        [
                            -97.1322819997426,
                            48.4103619999432
                        ],
                        [
                            -97.1311750004281,
                            48.4072810002681
                        ],
                        [
                            -97.1456929998232,
                            48.39743099973
                        ],
                        [
                            -97.1586479997942,
                            48.3963440001712
                        ],
                        [
                            -97.1633760002212,
                            48.3928570001623
                        ],
                        [
                            -97.1567599997818,
                            48.3908870002608
                        ],
                        [
                            -97.1576920000925,
                            48.3864860000563
                        ],
                        [
                            -97.1519929998951,
                            48.3828800001797
                        ],
                        [
                            -97.1454829997609,
                            48.3814090000984
                        ],
                        [
                            -97.1362469999133,
                            48.3839329999942
                        ],
                        [
                            -97.1333410002749,
                            48.382196000077
                        ],
                        [
                            -97.1413510000839,
                            48.3784579999577
                        ],
                        [
                            -97.1373039998974,
                            48.3729969999356
                        ],
                        [
                            -97.1378529998934,
                            48.3712240001429
                        ],
                        [
                            -97.1524559996774,
                            48.3709740002684
                        ],
                        [
                            -97.1548059996471,
                            48.3699699997058
                        ],
                        [
                            -97.1562610000145,
                            48.3655779997754
                        ],
                        [
                            -97.1554810000399,
                            48.3637680002245
                        ],
                        [
                            -97.1468610001354,
                            48.3595530002667
                        ],
                        [
                            -97.1435339996491,
                            48.3542700002722
                        ],
                        [
                            -97.1362030004307,
                            48.3521839999464
                        ],
                        [
                            -97.1361359995852,
                            48.3502279997378
                        ],
                        [
                            -97.1408039997378,
                            48.3467309999182
                        ],
                        [
                            -97.1400840000375,
                            48.3445909999715
                        ],
                        [
                            -97.1297540003959,
                            48.3434699997881
                        ],
                        [
                            -97.1259199997464,
                            48.3405790002425
                        ],
                        [
                            -97.1290410003684,
                            48.3328900000724
                        ],
                        [
                            -97.1400299996109,
                            48.3298469999255
                        ],
                        [
                            -97.1420249997534,
                            48.3280389997254
                        ],
                        [
                            -97.141715000132,
                            48.3257290001959
                        ],
                        [
                            -97.1390110001599,
                            48.3250990000301
                        ],
                        [
                            -97.1343420001825,
                            48.3276770001892
                        ],
                        [
                            -97.1292060002248,
                            48.3281310001154
                        ],
                        [
                            -97.120300999915,
                            48.3270979998527
                        ],
                        [
                            -97.115580999884,
                            48.3239280001763
                        ],
                        [
                            -97.1197350002006,
                            48.320787999778
                        ],
                        [
                            -97.132598999935,
                            48.3191449999436
                        ],
                        [
                            -97.1379259996884,
                            48.3119839997829
                        ],
                        [
                            -97.1377490001364,
                            48.3097290000439
                        ],
                        [
                            -97.1343349996115,
                            48.3088259999021
                        ],
                        [
                            -97.1276129997652,
                            48.3118059999081
                        ],
                        [
                            -97.1241659996283,
                            48.311141999824
                        ],
                        [
                            -97.1222020003462,
                            48.3087019998663
                        ],
                        [
                            -97.1266870002006,
                            48.3040040002757
                        ],
                        [
                            -97.1122309998315,
                            48.2964720001841
                        ],
                        [
                            -97.1138009998266,
                            48.2942269998939
                        ],
                        [
                            -97.1191900004026,
                            48.2942419998161
                        ],
                        [
                            -97.1241539999328,
                            48.2983570002982
                        ],
                        [
                            -97.127621999986,
                            48.2989930001319
                        ],
                        [
                            -97.1373340000347,
                            48.2917569998151
                        ],
                        [
                            -97.1383829996229,
                            48.28865499973
                        ],
                        [
                            -97.1355459995814,
                            48.2870590000637
                        ],
                        [
                            -97.1280420001106,
                            48.2915190000637
                        ],
                        [
                            -97.1231429999794,
                            48.2908089999004
                        ],
                        [
                            -97.116570000096,
                            48.2796610002277
                        ],
                        [
                            -97.1198010003228,
                            48.2768499998123
                        ],
                        [
                            -97.1251230000533,
                            48.2766739998137
                        ],
                        [
                            -97.1237380000062,
                            48.272047999979
                        ],
                        [
                            -97.1253449998112,
                            48.2704209999796
                        ],
                        [
                            -97.1391500000771,
                            48.2712269998266
                        ],
                        [
                            -97.1450319996742,
                            48.2687659999021
                        ],
                        [
                            -97.1438640002603,
                            48.2657490001448
                        ],
                        [
                            -97.1262729999239,
                            48.264369999787
                        ],
                        [
                            -97.1229539998335,
                            48.2616660000999
                        ],
                        [
                            -97.1306020001427,
                            48.258982000257
                        ],
                        [
                            -97.1248729998081,
                            48.2531640002327
                        ],
                        [
                            -97.1251079999847,
                            48.2499830001963
                        ],
                        [
                            -97.1355749998937,
                            48.2494879998055
                        ],
                        [
                            -97.1467439999595,
                            48.2444840000194
                        ],
                        [
                            -97.1459299996497,
                            48.2416199998493
                        ],
                        [
                            -97.1375490001198,
                            48.2413030002856
                        ],
                        [
                            -97.1337349995618,
                            48.2390750001695
                        ],
                        [
                            -97.1358350001847,
                            48.2369189999941
                        ],
                        [
                            -97.1430080001177,
                            48.236060000189
                        ],
                        [
                            -97.1446450000599,
                            48.2335620000621
                        ],
                        [
                            -97.1393109997355,
                            48.2301870001128
                        ],
                        [
                            -97.1244469998357,
                            48.2283869997625
                        ],
                        [
                            -97.1206900000775,
                            48.2247539999834
                        ],
                        [
                            -97.1245079999351,
                            48.2231740001737
                        ],
                        [
                            -97.1332879996731,
                            48.2269429997568
                        ],
                        [
                            -97.1371909999195,
                            48.2269509997199
                        ],
                        [
                            -97.149657000169,
                            48.2231490002355
                        ],
                        [
                            -97.1521900004369,
                            48.219636000299
                        ],
                        [
                            -97.1482880000154,
                            48.2183170002991
                        ],
                        [
                            -97.1436039999693,
                            48.2215230001057
                        ],
                        [
                            -97.1390549996425,
                            48.2219519998953
                        ],
                        [
                            -97.1228840004116,
                            48.2164939998782
                        ],
                        [
                            -97.1178379999673,
                            48.2097639997698
                        ],
                        [
                            -97.1197739996603,
                            48.2075040002632
                        ],
                        [
                            -97.1236889996024,
                            48.2076019998236
                        ],
                        [
                            -97.1278620001855,
                            48.2144169998715
                        ],
                        [
                            -97.1313629998508,
                            48.2171819997038
                        ],
                        [
                            -97.1392959996669,
                            48.2167549998338
                        ],
                        [
                            -97.1382570001245,
                            48.2130609998196
                        ],
                        [
                            -97.1301550002539,
                            48.2107649999214
                        ],
                        [
                            -97.1292770003699,
                            48.2083770001018
                        ],
                        [
                            -97.140910000043,
                            48.2045199999987
                        ],
                        [
                            -97.1412399997559,
                            48.2029530002041
                        ],
                        [
                            -97.1356719999781,
                            48.1971259999516
                        ],
                        [
                            -97.1409040001952,
                            48.1944430000786
                        ],
                        [
                            -97.1429219999039,
                            48.1909960001443
                        ],
                        [
                            -97.1497550000783,
                            48.1858240002832
                        ],
                        [
                            -97.1498100003299,
                            48.1834979998739
                        ],
                        [
                            -97.146768000249,
                            48.182057999863
                        ],
                        [
                            -97.1383859999959,
                            48.1823039999921
                        ],
                        [
                            -97.1366799995584,
                            48.1808659999754
                        ],
                        [
                            -97.1360100000869,
                            48.1767399998257
                        ],
                        [
                            -97.1389500000605,
                            48.1746559997487
                        ],
                        [
                            -97.1452429995614,
                            48.1740460001625
                        ],
                        [
                            -97.1475110004137,
                            48.1724070002352
                        ],
                        [
                            -97.1475099996905,
                            48.1705719999979
                        ],
                        [
                            -97.1419500003087,
                            48.1602020001589
                        ],
                        [
                            -97.1363759997848,
                            48.1597710000252
                        ],
                        [
                            -97.125350999659,
                            48.1628200002087
                        ],
                        [
                            -97.1214669996791,
                            48.1614779997235
                        ],
                        [
                            -97.1209679999118,
                            48.159551999703
                        ],
                        [
                            -97.1244810001709,
                            48.1565499997354
                        ],
                        [
                            -97.1402949999247,
                            48.1508939999991
                        ],
                        [
                            -97.145886999992,
                            48.1451040002107
                        ],
                        [
                            -97.1465049995849,
                            48.1422200000941
                        ],
                        [
                            -97.1435689998092,
                            48.1409969999445
                        ],
                        [
                            -97.1367680003202,
                            48.1432919999074
                        ],
                        [
                            -97.1228760000157,
                            48.1397500000489
                        ],
                        [
                            -97.1230440002452,
                            48.1369960001412
                        ],
                        [
                            -97.1252669999934,
                            48.1360990001033
                        ],
                        [
                            -97.1334679995982,
                            48.1371009999171
                        ],
                        [
                            -97.129453000097,
                            48.1331330001958
                        ],
                        [
                            -97.1282789999371,
                            48.1271849997401
                        ],
                        [
                            -97.120701999773,
                            48.1149870001019
                        ],
                        [
                            -97.123202000429,
                            48.1066500002068
                        ],
                        [
                            -97.1197729998354,
                            48.1053810000948
                        ],
                        [
                            -97.1114700001234,
                            48.1059130000156
                        ],
                        [
                            -97.1095350002553,
                            48.1047230001215
                        ],
                        [
                            -97.108448000134,
                            48.0998669997694
                        ],
                        [
                            -97.1018760000755,
                            48.0958959997068
                        ],
                        [
                            -97.1098569995723,
                            48.0913949999378
                        ],
                        [
                            -97.1021649997805,
                            48.0891220001066
                        ],
                        [
                            -97.0997980000923,
                            48.0858840001089
                        ],
                        [
                            -97.1001549995694,
                            48.0787569999972
                        ],
                        [
                            -97.1044829999633,
                            48.0724279999288
                        ],
                        [
                            -97.0977719999876,
                            48.0710799998958
                        ],
                        [
                            -97.0869860002364,
                            48.0582219998888
                        ],
                        [
                            -97.0756410004435,
                            48.0527249997731
                        ],
                        [
                            -97.0722570000558,
                            48.0480680000741
                        ],
                        [
                            -97.0687129998346,
                            48.0276940002117
                        ],
                        [
                            -97.0722389996142,
                            48.0191069998413
                        ],
                        [
                            -97.0692839995719,
                            48.0161759997755
                        ],
                        [
                            -97.0632889999968,
                            48.0149890002425
                        ],
                        [
                            -97.0630119999874,
                            48.0131789999113
                        ],
                        [
                            -97.066762000073,
                            48.0095579997258
                        ],
                        [
                            -97.063936999727,
                            47.9976600000533
                        ],
                        [
                            -97.0549450002769,
                            47.9929239997896
                        ],
                        [
                            -97.0530890000516,
                            47.9904440000438
                        ],
                        [
                            -97.0592550002291,
                            47.9753240001203
                        ],
                        [
                            -97.0573579999959,
                            47.9696639998585
                        ],
                        [
                            -97.0618310001547,
                            47.9647309997363
                        ],
                        [
                            -97.0524510003671,
                            47.9574219998588
                        ],
                        [
                            -97.0553410001121,
                            47.9475150000892
                        ],
                        [
                            -97.0482219997074,
                            47.9420360001141
                        ],
                        [
                            -97.0364729996835,
                            47.9396309999539
                        ],
                        [
                            -97.0364280003759,
                            47.9307810002511
                        ],
                        [
                            -97.0178539996752,
                            47.9199109998518
                        ],
                        [
                            -97.0184420000292,
                            47.9181530001498
                        ],
                        [
                            -97.0237629999348,
                            47.9156139997595
                        ],
                        [
                            -97.0168350002242,
                            47.9126160002941
                        ],
                        [
                            -97.0153320001763,
                            47.9067089998909
                        ],
                        [
                            -97.0186560002895,
                            47.9056520002555
                        ],
                        [
                            -97.0232299998324,
                            47.9084069999684
                        ],
                        [
                            -97.0252290001728,
                            47.9077280000375
                        ],
                        [
                            -97.019846000343,
                            47.9013359999723
                        ],
                        [
                            -97.0246290001232,
                            47.8975349998256
                        ],
                        [
                            -97.0250140000877,
                            47.8952030002927
                        ],
                        [
                            -97.0187819997879,
                            47.8905419997389
                        ],
                        [
                            -97.024634999971,
                            47.8872219999558
                        ],
                        [
                            -97.0254219996184,
                            47.8849259998474
                        ],
                        [
                            -97.0180140004071,
                            47.8786510000124
                        ],
                        [
                            -97.0231419999688,
                            47.8740630002098
                        ],
                        [
                            -97.0176720001003,
                            47.8715530002356
                        ],
                        [
                            -97.0054469998752,
                            47.8702400001796
                        ],
                        [
                            -97.0018280002092,
                            47.8681340002671
                        ],
                        [
                            -97.0018580003465,
                            47.8665080000774
                        ],
                        [
                            -97.0059070001828,
                            47.86449599971
                        ],
                        [
                            -96.9983280003689,
                            47.8591909999253
                        ],
                        [
                            -96.9967719997192,
                            47.8540779997114
                        ],
                        [
                            -96.9974329998682,
                            47.84881699989
                        ],
                        [
                            -96.9939740000358,
                            47.8459739999477
                        ],
                        [
                            -96.9983369996915,
                            47.841931999879
                        ],
                        [
                            -96.9927210002331,
                            47.8427909999477
                        ],
                        [
                            -96.9939649998149,
                            47.8384079997915
                        ],
                        [
                            -96.9862760003962,
                            47.8371129998728
                        ],
                        [
                            -96.9815499996191,
                            47.8295579997992
                        ],
                        [
                            -96.9871049998764,
                            47.8255500002041
                        ],
                        [
                            -96.9777259999137,
                            47.8254840001314
                        ],
                        [
                            -96.9741280001641,
                            47.8234740001607
                        ],
                        [
                            -96.9752860004306,
                            47.8221460002294
                        ],
                        [
                            -96.980500000206,
                            47.8218560002804
                        ],
                        [
                            -96.9800740002337,
                            47.8151090000339
                        ],
                        [
                            -96.9779190002575,
                            47.8116539999511
                        ],
                        [
                            -96.9787000000571,
                            47.8098920001665
                        ],
                        [
                            -96.9895770000449,
                            47.8098940001125
                        ],
                        [
                            -96.9915780000353,
                            47.8081869997884
                        ],
                        [
                            -96.9722610000681,
                            47.8027870000602
                        ],
                        [
                            -96.9764699997379,
                            47.8013659998678
                        ],
                        [
                            -96.9762539998279,
                            47.7977680002364
                        ],
                        [
                            -96.9664589999386,
                            47.7995690001649
                        ],
                        [
                            -96.9648489997605,
                            47.7955280000526
                        ],
                        [
                            -96.9570509996635,
                            47.7910070000657
                        ],
                        [
                            -96.9634560002157,
                            47.7873870002161
                        ],
                        [
                            -96.9651030002037,
                            47.7835120001679
                        ],
                        [
                            -96.9617160003413,
                            47.783263999842
                        ],
                        [
                            -96.9579760003015,
                            47.7856580002529
                        ],
                        [
                            -96.959094000385,
                            47.7805849997462
                        ],
                        [
                            -96.9523769996632,
                            47.779440999816
                        ],
                        [
                            -96.956345000207,
                            47.7750920001391
                        ],
                        [
                            -96.9467729999004,
                            47.775090999798
                        ],
                        [
                            -96.9451240002626,
                            47.7721650001929
                        ],
                        [
                            -96.9348830003095,
                            47.767209000296
                        ],
                        [
                            -96.9382719998217,
                            47.7630940001359
                        ],
                        [
                            -96.9382529995551,
                            47.7608130002118
                        ],
                        [
                            -96.9288540003992,
                            47.7552959999361
                        ],
                        [
                            -96.9380869998738,
                            47.756397999792
                        ],
                        [
                            -96.9396850003563,
                            47.754787999782
                        ],
                        [
                            -96.9291520003251,
                            47.750375999711
                        ],
                        [
                            -96.928672999751,
                            47.7438119997497
                        ],
                        [
                            -96.9327760000139,
                            47.7400650000964
                        ],
                        [
                            -96.9328300004406,
                            47.7375229998614
                        ],
                        [
                            -96.9251729999106,
                            47.7300610001264
                        ],
                        [
                            -96.9241050000559,
                            47.7262420002992
                        ],
                        [
                            -96.9184130004295,
                            47.7240409997866
                        ],
                        [
                            -96.9211009996098,
                            47.7213750000688
                        ],
                        [
                            -96.9300860002855,
                            47.7181179997207
                        ],
                        [
                            -96.9309730003903,
                            47.7155470001254
                        ],
                        [
                            -96.9278090001107,
                            47.7143150000062
                        ],
                        [
                            -96.9231319997373,
                            47.7178030001172
                        ],
                        [
                            -96.9197990003016,
                            47.7169280002247
                        ],
                        [
                            -96.9199270003481,
                            47.7103770002931
                        ],
                        [
                            -96.9127540004151,
                            47.7083190002964
                        ],
                        [
                            -96.9118680001352,
                            47.7068170000522
                        ],
                        [
                            -96.9144359996649,
                            47.7036530000549
                        ],
                        [
                            -96.9203790002597,
                            47.7018150003006
                        ],
                        [
                            -96.920874999654,
                            47.6999759998557
                        ],
                        [
                            -96.9178829998018,
                            47.6992260000984
                        ],
                        [
                            -96.9140170002636,
                            47.701802999855
                        ],
                        [
                            -96.9120170000982,
                            47.701465000278
                        ],
                        [
                            -96.9070190002328,
                            47.6944259997505
                        ],
                        [
                            -96.9097630003879,
                            47.6917090000945
                        ],
                        [
                            -96.9095270003863,
                            47.689217999942
                        ],
                        [
                            -96.9061310003031,
                            47.6887480001027
                        ],
                        [
                            -96.9020420002838,
                            47.69171400014
                        ],
                        [
                            -96.8992370000293,
                            47.6895790000073
                        ],
                        [
                            -96.898955999822,
                            47.6849149998917
                        ],
                        [
                            -96.8959880002592,
                            47.6781809999686
                        ],
                        [
                            -96.8962460000021,
                            47.6741939997707
                        ],
                        [
                            -96.8928890002769,
                            47.6730289998374
                        ],
                        [
                            -96.8851160002943,
                            47.6738079999564
                        ],
                        [
                            -96.8899369997093,
                            47.6697349999721
                        ],
                        [
                            -96.885419000243,
                            47.6638840002401
                        ],
                        [
                            -96.8875180001426,
                            47.6579350000301
                        ],
                        [
                            -96.8866159999692,
                            47.6527519999021
                        ],
                        [
                            -96.8822120003056,
                            47.6493890001084
                        ],
                        [
                            -96.8823510002228,
                            47.6426560000304
                        ],
                        [
                            -96.8882100002537,
                            47.6389109998783
                        ],
                        [
                            -96.8824680003987,
                            47.6336990000173
                        ],
                        [
                            -96.8809019997032,
                            47.6294590000585
                        ],
                        [
                            -96.8743730002008,
                            47.627823999984
                        ],
                        [
                            -96.8804869996015,
                            47.626015999919
                        ],
                        [
                            -96.8758600003552,
                            47.6234490002137
                        ],
                        [
                            -96.8797259998934,
                            47.6193359997389
                        ],
                        [
                            -96.8710099997295,
                            47.6180740001268
                        ],
                        [
                            -96.8736409999067,
                            47.6135460000536
                        ],
                        [
                            -96.8690609996177,
                            47.6148479999068
                        ],
                        [
                            -96.8659230001757,
                            47.612560999712
                        ],
                        [
                            -96.8570820003383,
                            47.6107449997124
                        ],
                        [
                            -96.854821000057,
                            47.6068620002185
                        ],
                        [
                            -96.8568120000015,
                            47.6018320000422
                        ],
                        [
                            -96.8510209997427,
                            47.5985439997633
                        ],
                        [
                            -96.8527499997464,
                            47.5961689997636
                        ],
                        [
                            -96.8589369998404,
                            47.5957419997068
                        ],
                        [
                            -96.8498969998114,
                            47.5908259998888
                        ],
                        [
                            -96.8537389999585,
                            47.5788500002039
                        ],
                        [
                            -96.8604759998734,
                            47.5750220002732
                        ],
                        [
                            -96.8523620003072,
                            47.5710310002435
                        ],
                        [
                            -96.8515680000888,
                            47.5686550001458
                        ],
                        [
                            -96.8584630001875,
                            47.5671459998901
                        ],
                        [
                            -96.8658179996954,
                            47.5696610002052
                        ],
                        [
                            -96.8689340002944,
                            47.5670019997647
                        ],
                        [
                            -96.863087999784,
                            47.562485999865
                        ],
                        [
                            -96.8474990001611,
                            47.5662259998175
                        ],
                        [
                            -96.8476149996137,
                            47.564650999806
                        ],
                        [
                            -96.8539869996556,
                            47.5604589997582
                        ],
                        [
                            -96.8601439996124,
                            47.560636000168
                        ],
                        [
                            -96.8617489997676,
                            47.5592789999305
                        ],
                        [
                            -96.8579250000621,
                            47.5554470000699
                        ],
                        [
                            -96.8498349998871,
                            47.553162999744
                        ],
                        [
                            -96.8538129995783,
                            47.5492019999322
                        ],
                        [
                            -96.8604280001928,
                            47.5459469998472
                        ],
                        [
                            -96.8485920001302,
                            47.5425770000357
                        ],
                        [
                            -96.8453230002685,
                            47.5387519997657
                        ],
                        [
                            -96.8488239999338,
                            47.5372020000672
                        ],
                        [
                            -96.855397999642,
                            47.5420849999967
                        ],
                        [
                            -96.8623060001595,
                            47.5411950001387
                        ],
                        [
                            -96.8635880002745,
                            47.5395489999943
                        ],
                        [
                            -96.8620099998835,
                            47.5378190002146
                        ],
                        [
                            -96.8544660002297,
                            47.5382610002541
                        ],
                        [
                            -96.8535339999191,
                            47.5362130002397
                        ],
                        [
                            -96.8609250003103,
                            47.5293649999419
                        ],
                        [
                            -96.8692010002581,
                            47.5288810001739
                        ],
                        [
                            -96.8711720001112,
                            47.5272680001116
                        ],
                        [
                            -96.8667199998688,
                            47.5253509998839
                        ],
                        [
                            -96.8633100004401,
                            47.5171599999938
                        ],
                        [
                            -96.8608919998,
                            47.5154650001026
                        ],
                        [
                            -96.8542920001525,
                            47.5144000001624
                        ],
                        [
                            -96.8464320001312,
                            47.5169149997527
                        ],
                        [
                            -96.8423680002263,
                            47.5161159998161
                        ],
                        [
                            -96.8438999996882,
                            47.5126010002767
                        ],
                        [
                            -96.8510850002151,
                            47.5135450002325
                        ],
                        [
                            -96.8536250001557,
                            47.511505999827
                        ],
                        [
                            -96.8518729996874,
                            47.5096999998124
                        ],
                        [
                            -96.8450560003049,
                            47.5104590001363
                        ],
                        [
                            -96.8422800003627,
                            47.5086359999785
                        ],
                        [
                            -96.846990000348,
                            47.5064950000648
                        ],
                        [
                            -96.8525360003845,
                            47.5074909997146
                        ],
                        [
                            -96.853494999561,
                            47.5015709999255
                        ],
                        [
                            -96.8487929999716,
                            47.4980509999123
                        ],
                        [
                            -96.8565579995583,
                            47.4935509999478
                        ],
                        [
                            -96.8650400002688,
                            47.4916509999337
                        ],
                        [
                            -96.8635139997563,
                            47.4897110001268
                        ],
                        [
                            -96.8521580000927,
                            47.486351000258
                        ],
                        [
                            -96.8551809999071,
                            47.4832060000897
                        ],
                        [
                            -96.8629749998061,
                            47.4839880001773
                        ],
                        [
                            -96.8685190001927,
                            47.4784730001171
                        ],
                        [
                            -96.8651580002696,
                            47.4768220001778
                        ],
                        [
                            -96.8605429998205,
                            47.4806559999171
                        ],
                        [
                            -96.8569149999337,
                            47.4812430000532
                        ],
                        [
                            -96.8519460003806,
                            47.4790549999938
                        ],
                        [
                            -96.8537900000121,
                            47.4759050001282
                        ],
                        [
                            -96.8610020003032,
                            47.4725880002185
                        ],
                        [
                            -96.8592149996748,
                            47.4662300002423
                        ],
                        [
                            -96.8539080000129,
                            47.4604030002278
                        ],
                        [
                            -96.8636190002366,
                            47.4570100002015
                        ],
                        [
                            -96.8622280003417,
                            47.4554839999175
                        ],
                        [
                            -96.8542980000002,
                            47.4545750001957
                        ],
                        [
                            -96.8550820001729,
                            47.4529639999706
                        ],
                        [
                            -96.8592580002308,
                            47.4517279997452
                        ],
                        [
                            -96.8570120000181,
                            47.4490359999111
                        ],
                        [
                            -96.8595610001795,
                            47.4457560002824
                        ],
                        [
                            -96.8558670001704,
                            47.4392209999847
                        ],
                        [
                            -96.8628590003534,
                            47.4364029998785
                        ],
                        [
                            -96.8528159998687,
                            47.4320299999381
                        ],
                        [
                            -96.8577509999849,
                            47.4322440000929
                        ],
                        [
                            -96.8647149996805,
                            47.4280940001625
                        ],
                        [
                            -96.8629520002398,
                            47.4264929999804
                        ],
                        [
                            -96.8555709998944,
                            47.4267189999831
                        ],
                        [
                            -96.8543249997644,
                            47.4255919998574
                        ],
                        [
                            -96.8552799996413,
                            47.4243280002461
                        ],
                        [
                            -96.87176299994,
                            47.4190060000358
                        ],
                        [
                            -96.8610109996257,
                            47.4176899999196
                        ],
                        [
                            -96.8616779996224,
                            47.4143940003027
                        ],
                        [
                            -96.8568179998493,
                            47.409669000215
                        ],
                        [
                            -96.8420729997752,
                            47.411738000089
                        ],
                        [
                            -96.8428979999557,
                            47.409872000133
                        ],
                        [
                            -96.8527139997614,
                            47.4060939998996
                        ],
                        [
                            -96.8391329998015,
                            47.3993790000032
                        ],
                        [
                            -96.8480110003471,
                            47.395658999817
                        ],
                        [
                            -96.8489810002926,
                            47.3936220001656
                        ],
                        [
                            -96.8440029996204,
                            47.3921449997938
                        ],
                        [
                            -96.8404509999015,
                            47.3932490001443
                        ],
                        [
                            -96.8374649998971,
                            47.389526999931
                        ],
                        [
                            -96.8421699998596,
                            47.3861689997487
                        ],
                        [
                            -96.8381489996123,
                            47.3832900000967
                        ],
                        [
                            -96.8392440001296,
                            47.3811649997289
                        ],
                        [
                            -96.8428200001379,
                            47.3807469999346
                        ],
                        [
                            -96.8477290003148,
                            47.3827509998441
                        ],
                        [
                            -96.8500459997743,
                            47.3816079999696
                        ],
                        [
                            -96.8439039998862,
                            47.3777130002139
                        ],
                        [
                            -96.8436879999761,
                            47.3757510000047
                        ],
                        [
                            -96.8546219998653,
                            47.3737799998558
                        ],
                        [
                            -96.84727599968,
                            47.3702880000403
                        ],
                        [
                            -96.8396609998811,
                            47.3735320002965
                        ],
                        [
                            -96.8368570003499,
                            47.3728670001027
                        ],
                        [
                            -96.837141000032,
                            47.3712910000876
                        ],
                        [
                            -96.8440160000392,
                            47.368021000007
                        ],
                        [
                            -96.8528290002875,
                            47.3697280000575
                        ],
                        [
                            -96.8582060002695,
                            47.3675299997252
                        ],
                        [
                            -96.8565120004259,
                            47.3661750000994
                        ],
                        [
                            -96.850613000212,
                            47.3663489997792
                        ],
                        [
                            -96.8483619999764,
                            47.3646199999463
                        ],
                        [
                            -96.8481960002951,
                            47.3627520001577
                        ],
                        [
                            -96.8523139997283,
                            47.3595250002114
                        ],
                        [
                            -96.8505400004171,
                            47.3570909997008
                        ],
                        [
                            -96.837361999965,
                            47.3555360001548
                        ],
                        [
                            -96.8381069997796,
                            47.3534889997965
                        ],
                        [
                            -96.8445879996014,
                            47.3542710002735
                        ],
                        [
                            -96.8429149996741,
                            47.3507779997602
                        ],
                        [
                            -96.8470389998535,
                            47.3497130000955
                        ],
                        [
                            -96.8471140001966,
                            47.3477109997141
                        ],
                        [
                            -96.8403749997336,
                            47.3409350001885
                        ],
                        [
                            -96.8341809999669,
                            47.3409130000956
                        ],
                        [
                            -96.8306429995934,
                            47.3387859999378
                        ],
                        [
                            -96.8344710003951,
                            47.3327019997243
                        ],
                        [
                            -96.8439780004043,
                            47.3316930001986
                        ],
                        [
                            -96.8460060001589,
                            47.3300750000349
                        ],
                        [
                            -96.8457860000508,
                            47.3280370002426
                        ],
                        [
                            -96.8407580000482,
                            47.3266589998635
                        ],
                        [
                            -96.834584000373,
                            47.3309530000027
                        ],
                        [
                            -96.8287639998018,
                            47.3279949998166
                        ],
                        [
                            -96.8309499997401,
                            47.3258290002505
                        ],
                        [
                            -96.8369000000076,
                            47.3248220000114
                        ],
                        [
                            -96.8346620001908,
                            47.3206720000194
                        ],
                        [
                            -96.8354510003863,
                            47.3190599999136
                        ],
                        [
                            -96.8426660001521,
                            47.3182709999678
                        ],
                        [
                            -96.8469760001043,
                            47.3158340001268
                        ],
                        [
                            -96.8423630002034,
                            47.3134010001728
                        ],
                        [
                            -96.8439280001756,
                            47.3102449997741
                        ],
                        [
                            -96.8430450002688,
                            47.3088269998762
                        ],
                        [
                            -96.8365259999138,
                            47.3114639998394
                        ],
                        [
                            -96.8306600002102,
                            47.3060659998109
                        ],
                        [
                            -96.8437880004335,
                            47.2931130001377
                        ],
                        [
                            -96.8400900002265,
                            47.2755760002051
                        ],
                        [
                            -96.8476780002612,
                            47.2712470000486
                        ],
                        [
                            -96.844826999976,
                            47.2687529997577
                        ],
                        [
                            -96.8361920000029,
                            47.2688759999107
                        ],
                        [
                            -96.8347049998485,
                            47.266154999804
                        ],
                        [
                            -96.8376559996928,
                            47.2646080002728
                        ],
                        [
                            -96.8447709998995,
                            47.2653620000109
                        ],
                        [
                            -96.8474480001074,
                            47.261933000009
                        ],
                        [
                            -96.8457890004239,
                            47.2607980003033
                        ],
                        [
                            -96.8418159998572,
                            47.2626450001157
                        ],
                        [
                            -96.8357629996575,
                            47.2622310001931
                        ],
                        [
                            -96.8372680002536,
                            47.2584820001534
                        ],
                        [
                            -96.8452499995753,
                            47.2584709997064
                        ],
                        [
                            -96.8385419999727,
                            47.2562249999121
                        ],
                        [
                            -96.8444129996992,
                            47.251155000014
                        ],
                        [
                            -96.8412180003558,
                            47.2494960000623
                        ],
                        [
                            -96.839408999986,
                            47.2525820000035
                        ],
                        [
                            -96.8373059998885,
                            47.2530219997952
                        ],
                        [
                            -96.8321930003953,
                            47.2468299999921
                        ],
                        [
                            -96.8416400001301,
                            47.243063000003
                        ],
                        [
                            -96.8437209995881,
                            47.2397879999079
                        ],
                        [
                            -96.840835999866,
                            47.2389160001301
                        ],
                        [
                            -96.839080999923,
                            47.2418649999378
                        ],
                        [
                            -96.8365350001346,
                            47.2423869997575
                        ],
                        [
                            -96.8286719997403,
                            47.2332860002492
                        ],
                        [
                            -96.8309609996108,
                            47.2312759998222
                        ],
                        [
                            -96.8366389998917,
                            47.2349520001955
                        ],
                        [
                            -96.8414690004259,
                            47.2332079997334
                        ],
                        [
                            -96.8427869996276,
                            47.2310039997534
                        ],
                        [
                            -96.8414409999385,
                            47.2295080000426
                        ],
                        [
                            -96.8348570001845,
                            47.2292820001247
                        ],
                        [
                            -96.8314540004286,
                            47.2267110002959
                        ],
                        [
                            -96.8433030000117,
                            47.2228150001277
                        ],
                        [
                            -96.8457879997006,
                            47.2201100001012
                        ],
                        [
                            -96.8423930003406,
                            47.2177490002403
                        ],
                        [
                            -96.8404149999165,
                            47.2187100001163
                        ],
                        [
                            -96.838972999968,
                            47.2227130003026
                        ],
                        [
                            -96.8336400003668,
                            47.2215770001962
                        ],
                        [
                            -96.8331559997698,
                            47.2192509999449
                        ],
                        [
                            -96.8371979999334,
                            47.2159489998731
                        ],
                        [
                            -96.8303100004058,
                            47.2102829996978
                        ],
                        [
                            -96.8371639995982,
                            47.2073240001232
                        ],
                        [
                            -96.830044000267,
                            47.2043010002543
                        ],
                        [
                            -96.8271670000426,
                            47.2013159998416
                        ],
                        [
                            -96.8292910000565,
                            47.2001340000019
                        ],
                        [
                            -96.8315730002542,
                            47.2032499998141
                        ],
                        [
                            -96.8339719997295,
                            47.2035479999819
                        ],
                        [
                            -96.8448470000675,
                            47.193579999956
                        ],
                        [
                            -96.8432059999273,
                            47.1912239998025
                        ],
                        [
                            -96.8402490002352,
                            47.1910139997345
                        ],
                        [
                            -96.8403360002738,
                            47.195533999799
                        ],
                        [
                            -96.8380390000075,
                            47.1964810000106
                        ],
                        [
                            -96.826422000228,
                            47.1914810000863
                        ],
                        [
                            -96.826638999963,
                            47.188802999713
                        ],
                        [
                            -96.8354799998003,
                            47.1862600002876
                        ],
                        [
                            -96.8345609999085,
                            47.18367799984
                        ],
                        [
                            -96.8241669997944,
                            47.1858800000648
                        ],
                        [
                            -96.8215549998838,
                            47.1843140000814
                        ],
                        [
                            -96.8228599995651,
                            47.1823240002845
                        ],
                        [
                            -96.8274990003036,
                            47.1820049997841
                        ],
                        [
                            -96.8244649997203,
                            47.179873999993
                        ],
                        [
                            -96.8250139997163,
                            47.1778589997659
                        ],
                        [
                            -96.8334839998329,
                            47.1766439997203
                        ],
                        [
                            -96.8304729997141,
                            47.1742530001942
                        ],
                        [
                            -96.8224839998214,
                            47.1730789999492
                        ],
                        [
                            -96.8251519998085,
                            47.1674510001276
                        ],
                        [
                            -96.8193549997019,
                            47.1653879998942
                        ],
                        [
                            -96.8192969999756,
                            47.1637919997924
                        ],
                        [
                            -96.8226019998222,
                            47.1614599999036
                        ],
                        [
                            -96.8304390002772,
                            47.1608279996972
                        ],
                        [
                            -96.8319900000058,
                            47.1594309997561
                        ],
                        [
                            -96.8306369997456,
                            47.1582389998774
                        ],
                        [
                            -96.8236089995776,
                            47.158301999909
                        ],
                        [
                            -96.822359000148,
                            47.1563409998737
                        ],
                        [
                            -96.8281289995921,
                            47.1546429998256
                        ],
                        [
                            -96.8362050004217,
                            47.1566479998366
                        ],
                        [
                            -96.8363309999201,
                            47.1532600001395
                        ],
                        [
                            -96.8412469997697,
                            47.1518559999996
                        ],
                        [
                            -96.8369560000841,
                            47.1486000002775
                        ],
                        [
                            -96.8320899995649,
                            47.1496870001042
                        ],
                        [
                            -96.8286480003492,
                            47.1464190000503
                        ],
                        [
                            -96.8346360002515,
                            47.1438860000164
                        ],
                        [
                            -96.8290569997048,
                            47.14055499993
                        ],
                        [
                            -96.8263300001665,
                            47.1349410001192
                        ],
                        [
                            -96.8305809996692,
                            47.1311619997639
                        ],
                        [
                            -96.8304169996376,
                            47.1292240000426
                        ],
                        [
                            -96.8272759998225,
                            47.1285100002935
                        ],
                        [
                            -96.8207230000306,
                            47.1303689997183
                        ],
                        [
                            -96.824470999568,
                            47.1271829999595
                        ],
                        [
                            -96.8258089997596,
                            47.1231010001674
                        ],
                        [
                            -96.8349190001088,
                            47.1237640000877
                        ],
                        [
                            -96.8375639996313,
                            47.1219800000085
                        ],
                        [
                            -96.8283870002333,
                            47.1207690001684
                        ],
                        [
                            -96.8211919996607,
                            47.1157539999834
                        ],
                        [
                            -96.822094999659,
                            47.1088920002253
                        ],
                        [
                            -96.8161910003206,
                            47.1084809997784
                        ],
                        [
                            -96.8159340004026,
                            47.1041389999373
                        ],
                        [
                            -96.8210200001316,
                            47.1008939998623
                        ],
                        [
                            -96.8172900001375,
                            47.0980599998206
                        ],
                        [
                            -96.8172509997794,
                            47.0920879998644
                        ],
                        [
                            -96.8263629997784,
                            47.0889199997545
                        ],
                        [
                            -96.8194919999692,
                            47.0897389998269
                        ],
                        [
                            -96.8159729998623,
                            47.0884660001395
                        ],
                        [
                            -96.8203859997467,
                            47.0830410000045
                        ],
                        [
                            -96.8157510001045,
                            47.0789870001432
                        ],
                        [
                            -96.8292759999879,
                            47.0746200002236
                        ],
                        [
                            -96.8267779998801,
                            47.0725979999865
                        ],
                        [
                            -96.8236909995934,
                            47.0759470002837
                        ],
                        [
                            -96.8196300000615,
                            47.0753420002494
                        ],
                        [
                            -96.8202240002633,
                            47.0734779997521
                        ],
                        [
                            -96.8245459999111,
                            47.0715339999028
                        ],
                        [
                            -96.8234999997977,
                            47.0659669999258
                        ],
                        [
                            -96.8197389998414,
                            47.0617290001038
                        ],
                        [
                            -96.8303859996755,
                            47.0608229997372
                        ],
                        [
                            -96.8274280001585,
                            47.0591220000192
                        ],
                        [
                            -96.8222379997741,
                            47.059539999868
                        ],
                        [
                            -96.8216949996259,
                            47.0582390001291
                        ],
                        [
                            -96.8250630001201,
                            47.0555640001335
                        ],
                        [
                            -96.8177079997139,
                            47.0523089999433
                        ],
                        [
                            -96.8195589999164,
                            47.0494929997379
                        ],
                        [
                            -96.8275589996797,
                            47.04633199994
                        ],
                        [
                            -96.8263979999385,
                            47.0452430001519
                        ],
                        [
                            -96.820455000242,
                            47.0475769999212
                        ],
                        [
                            -96.8178650000727,
                            47.0470769999813
                        ],
                        [
                            -96.822715999625,
                            47.0369370002114
                        ],
                        [
                            -96.8203940001427,
                            47.0355969996996
                        ],
                        [
                            -96.8160769996194,
                            47.039632999791
                        ],
                        [
                            -96.8126589997949,
                            47.0386339997008
                        ],
                        [
                            -96.8171419999994,
                            47.0333249999485
                        ],
                        [
                            -96.8240360002732,
                            47.0337469998591
                        ],
                        [
                            -96.8272680003249,
                            47.0320950001115
                        ],
                        [
                            -96.8260120001491,
                            47.0300709999362
                        ],
                        [
                            -96.8204619999147,
                            47.030346999832
                        ],
                        [
                            -96.8147929998546,
                            47.0244010001629
                        ],
                        [
                            -96.8209470003366,
                            47.0244020001626
                        ],
                        [
                            -96.8295160001874,
                            47.0215500001598
                        ],
                        [
                            -96.8309680001817,
                            47.0185200000171
                        ],
                        [
                            -96.8347759999936,
                            47.0174459998182
                        ],
                        [
                            -96.8323030000002,
                            47.0151840002192
                        ],
                        [
                            -96.8339390001176,
                            47.0127620000545
                        ],
                        [
                            -96.8335039999244,
                            47.0101099999356
                        ],
                        [
                            -96.8395479999032,
                            47.0067279998404
                        ],
                        [
                            -96.8348389997428,
                            47.0072540003058
                        ],
                        [
                            -96.8329669996239,
                            47.0041699998123
                        ],
                        [
                            -96.8287920002892,
                            47.0050610001619
                        ],
                        [
                            -96.8274890002578,
                            47.0016110000048
                        ],
                        [
                            -96.8209759997506,
                            47.0033260000978
                        ],
                        [
                            -96.8233130001999,
                            47.0003300000201
                        ],
                        [
                            -96.8194779997255,
                            46.9973729999459
                        ],
                        [
                            -96.82392599977,
                            46.9974369998135
                        ],
                        [
                            -96.8245979997897,
                            46.9933089999594
                        ],
                        [
                            -96.8223449999043,
                            46.9905910002727
                        ],
                        [
                            -96.8263800003952,
                            46.987917999733
                        ],
                        [
                            -96.8226040003703,
                            46.985913999928
                        ],
                        [
                            -96.8201129999353,
                            46.9793779999612
                        ],
                        [
                            -96.8226639997465,
                            46.9700199997163
                        ],
                        [
                            -96.8195549997184,
                            46.967415000076
                        ],
                        [
                            -96.8140499996898,
                            46.966602000224
                        ],
                        [
                            -96.8097859997684,
                            46.9638350001495
                        ],
                        [
                            -96.807600999655,
                            46.9646359998188
                        ],
                        [
                            -96.8065239995794,
                            46.9676359999605
                        ],
                        [
                            -96.8019809999987,
                            46.9641279998545
                        ],
                        [
                            -96.8015900001865,
                            46.9682869999227
                        ],
                        [
                            -96.7950449998922,
                            46.9658380000656
                        ],
                        [
                            -96.7992059998815,
                            46.9597240002348
                        ],
                        [
                            -96.8037500001854,
                            46.9581720000809
                        ],
                        [
                            -96.7996059999146,
                            46.9543160000965
                        ],
                        [
                            -96.7989649998571,
                            46.9511479997536
                        ],
                        [
                            -96.7995160004012,
                            46.9493340002859
                        ],
                        [
                            -96.8041400001728,
                            46.9469110002614
                        ],
                        [
                            -96.7979739999952,
                            46.9441859997688
                        ],
                        [
                            -96.7953579998866,
                            46.9469639998345
                        ],
                        [
                            -96.7926559995644,
                            46.9467640002685
                        ],
                        [
                            -96.792157999622,
                            46.9405640000385
                        ],
                        [
                            -96.7900500003998,
                            46.9376820002769
                        ],
                        [
                            -96.7917579995889,
                            46.9284650001357
                        ],
                        [
                            -96.7904579999305,
                            46.9273639997006
                        ],
                        [
                            -96.7859560003577,
                            46.9301629998985
                        ],
                        [
                            -96.7857589998159,
                            46.9249650002976
                        ],
                        [
                            -96.7837570000006,
                            46.9246630000482
                        ],
                        [
                            -96.7802589998102,
                            46.9282620002945
                        ],
                        [
                            -96.7766569998626,
                            46.9263630000798
                        ],
                        [
                            -96.7751570001877,
                            46.9308629999221
                        ],
                        [
                            -96.7632569996527,
                            46.9350629999332
                        ],
                        [
                            -96.7617569999778,
                            46.9346630000735
                        ],
                        [
                            -96.7612570003856,
                            46.932162999968
                        ],
                        [
                            -96.7574570000713,
                            46.930763000216
                        ],
                        [
                            -96.7568570000217,
                            46.9284630002256
                        ],
                        [
                            -96.7590570002036,
                            46.9277630001226
                        ],
                        [
                            -96.7625570000439,
                            46.9294629999279
                        ],
                        [
                            -96.7650569998015,
                            46.9290629999563
                        ],
                        [
                            -96.7652569998181,
                            46.9275629999904
                        ],
                        [
                            -96.7532569997239,
                            46.9245629998098
                        ],
                        [
                            -96.7537570002144,
                            46.9230630002153
                        ],
                        [
                            -96.7613569999447,
                            46.9243630000001
                        ],
                        [
                            -96.7632569996527,
                            46.9233630001812
                        ],
                        [
                            -96.7612690000811,
                            46.91954700012
                        ],
                        [
                            -96.7534299999763,
                            46.9146650000448
                        ],
                        [
                            -96.7556870000597,
                            46.9137639997204
                        ],
                        [
                            -96.7595960001539,
                            46.9164349998271
                        ],
                        [
                            -96.7638220004405,
                            46.9130050000035
                        ],
                        [
                            -96.7635400004082,
                            46.9094559998079
                        ],
                        [
                            -96.7656180003914,
                            46.9050099997382
                        ],
                        [
                            -96.7704580000731,
                            46.9067629998958
                        ],
                        [
                            -96.7735579998803,
                            46.90356299978
                        ],
                        [
                            -96.7706580000896,
                            46.9002629999758
                        ],
                        [
                            -96.7747579999795,
                            46.8993629998747
                        ],
                        [
                            -96.7767129999391,
                            46.8956190000538
                        ],
                        [
                            -96.7701579995991,
                            46.8936629999516
                        ],
                        [
                            -96.7695580004478,
                            46.8919629999153
                        ],
                        [
                            -96.7746580004204,
                            46.8886630001438
                        ],
                        [
                            -96.7735579998803,
                            46.8847630001925
                        ],
                        [
                            -96.7677790002153,
                            46.8847350000978
                        ],
                        [
                            -96.7684289995953,
                            46.87950799979
                        ],
                        [
                            -96.771296999599,
                            46.8774349999682
                        ],
                        [
                            -96.7716580001723,
                            46.8810629998059
                        ],
                        [
                            -96.7755179998627,
                            46.8791290002084
                        ],
                        [
                            -96.7811840004481,
                            46.8792759997855
                        ],
                        [
                            -96.7792410001841,
                            46.8759570000049
                        ],
                        [
                            -96.7758529995985,
                            46.8742809999183
                        ],
                        [
                            -96.7822589999756,
                            46.8708089997969
                        ],
                        [
                            -96.7803669997652,
                            46.8683590000413
                        ],
                        [
                            -96.783958999667,
                            46.8616629997293
                        ],
                        [
                            -96.7772579997372,
                            46.8572629997741
                        ],
                        [
                            -96.7760579996379,
                            46.8544630002705
                        ],
                        [
                            -96.7854590002402,
                            46.8548629998358
                        ],
                        [
                            -96.7841589996836,
                            46.8519629999907
                        ],
                        [
                            -96.7813590003503,
                            46.851163000142
                        ],
                        [
                            -96.7769589999864,
                            46.8524630001418
                        ],
                        [
                            -96.7731579998472,
                            46.8514630000994
                        ],
                        [
                            -96.7738580003543,
                            46.8499630001428
                        ],
                        [
                            -96.7805590002841,
                            46.8477630002437
                        ],
                        [
                            -96.781658999926,
                            46.845663000093
                        ],
                        [
                            -96.7750590002784,
                            46.8403630002897
                        ],
                        [
                            -96.7753589998541,
                            46.8388630000582
                        ],
                        [
                            -96.7871589999317,
                            46.8410630000767
                        ],
                        [
                            -96.7867589998986,
                            46.8387630001516
                        ],
                        [
                            -96.7827589995678,
                            46.8370630003017
                        ],
                        [
                            -96.7845260001047,
                            46.8333770000728
                        ],
                        [
                            -96.7872340002748,
                            46.832618000158
                        ],
                        [
                            -96.7926649997852,
                            46.8344609998313
                        ],
                        [
                            -96.7939589995958,
                            46.8330640000836
                        ],
                        [
                            -96.7802710004041,
                            46.8257629997957
                        ],
                        [
                            -96.7813769998937,
                            46.8247420002404
                        ],
                        [
                            -96.7915590002956,
                            46.8278640002707
                        ],
                        [
                            -96.7925590003783,
                            46.8232640001507
                        ],
                        [
                            -96.7979599997515,
                            46.8223639998131
                        ],
                        [
                            -96.8001599999334,
                            46.8196639999928
                        ],
                        [
                            -96.7993359995778,
                            46.8154359999879
                        ],
                        [
                            -96.8025440002384,
                            46.8115210001426
                        ],
                        [
                            -96.7957560002699,
                            46.8077950002817
                        ],
                        [
                            -96.803144000288,
                            46.805098999787
                        ],
                        [
                            -96.7978049999408,
                            46.8045400000953
                        ],
                        [
                            -96.7958170003693,
                            46.8016969999817
                        ],
                        [
                            -96.7962349999457,
                            46.7999150001128
                        ],
                        [
                            -96.8019140000516,
                            46.7969649999336
                        ],
                        [
                            -96.7949279997163,
                            46.7957760001142
                        ],
                        [
                            -96.794397000162,
                            46.7941079998941
                        ],
                        [
                            -96.7969589998439,
                            46.7924400002502
                        ],
                        [
                            -96.7961949997627,
                            46.7898810002434
                        ],
                        [
                            -96.7943300002148,
                            46.788555000247
                        ],
                        [
                            -96.789568000351,
                            46.7895619999114
                        ],
                        [
                            -96.7902329997997,
                            46.7872970002558
                        ],
                        [
                            -96.7871360003655,
                            46.7856510002834
                        ],
                        [
                            -96.7967560003526,
                            46.7769989998576
                        ],
                        [
                            -96.7921550001472,
                            46.7759300001698
                        ],
                        [
                            -96.7872090001604,
                            46.7783560001655
                        ],
                        [
                            -96.7837929999857,
                            46.7782470000883
                        ],
                        [
                            -96.7842940003011,
                            46.7743120001132
                        ],
                        [
                            -96.7900789998139,
                            46.7745690000233
                        ],
                        [
                            -96.7892169998234,
                            46.771209999997
                        ],
                        [
                            -96.7860590002899,
                            46.7691299998892
                        ],
                        [
                            -96.7754529995654,
                            46.7667510001442
                        ],
                        [
                            -96.7783959999121,
                            46.7645060000213
                        ],
                        [
                            -96.7895189999472,
                            46.766024000108
                        ],
                        [
                            -96.7864939995847,
                            46.7636010000803
                        ],
                        [
                            -96.7796450004152,
                            46.7630509997297
                        ],
                        [
                            -96.7778500002892,
                            46.7615880002271
                        ],
                        [
                            -96.7803450000239,
                            46.7601950002544
                        ],
                        [
                            -96.7863949998505,
                            46.7618640002487
                        ],
                        [
                            -96.7872939996509,
                            46.7603559999861
                        ],
                        [
                            -96.7836039998398,
                            46.7590760000272
                        ],
                        [
                            -96.7829400002161,
                            46.7567450001175
                        ],
                        [
                            -96.7876600002471,
                            46.757933999869
                        ],
                        [
                            -96.7897890002839,
                            46.7567009996942
                        ],
                        [
                            -96.7828520003525,
                            46.7542419999009
                        ],
                        [
                            -96.7831390004077,
                            46.7527689997095
                        ],
                        [
                            -96.789376999657,
                            46.7519990001586
                        ],
                        [
                            -96.7832890001955,
                            46.7509090001225
                        ],
                        [
                            -96.7829970001174,
                            46.7488349998268
                        ],
                        [
                            -96.7876679997447,
                            46.7482389997476
                        ],
                        [
                            -96.7803629995672,
                            46.7454640000429
                        ],
                        [
                            -96.7811380004174,
                            46.7440210002493
                        ],
                        [
                            -96.7858419996566,
                            46.7446459997635
                        ],
                        [
                            -96.7873530001004,
                            46.7431399996944
                        ],
                        [
                            -96.7804510003291,
                            46.7417689997701
                        ],
                        [
                            -96.782828999888,
                            46.7405879997678
                        ],
                        [
                            -96.7809430004237,
                            46.737937999705
                        ],
                        [
                            -96.7831560001261,
                            46.7361379999725
                        ],
                        [
                            -96.7807679996232,
                            46.7338069998169
                        ],
                        [
                            -96.784551000219,
                            46.7318780001076
                        ],
                        [
                            -96.783016000384,
                            46.7289939999694
                        ],
                        [
                            -96.7796160001028,
                            46.7299510002857
                        ],
                        [
                            -96.7791359997038,
                            46.7266420003035
                        ],
                        [
                            -96.7820299996467,
                            46.7251930001877
                        ],
                        [
                            -96.7798100002716,
                            46.7236879999913
                        ],
                        [
                            -96.7810999998842,
                            46.7220480001771
                        ],
                        [
                            -96.7911969998973,
                            46.718873999858
                        ],
                        [
                            -96.7901690002255,
                            46.7174529997262
                        ],
                        [
                            -96.7821200000584,
                            46.7168880002316
                        ],
                        [
                            -96.7874679997282,
                            46.7139980002583
                        ],
                        [
                            -96.7835739997026,
                            46.7114230000996
                        ],
                        [
                            -96.7895530002824,
                            46.7095569998505
                        ],
                        [
                            -96.7838270003209,
                            46.7059770000238
                        ],
                        [
                            -96.79120399957,
                            46.7037470001844
                        ],
                        [
                            -96.7842149997601,
                            46.701448000214
                        ],
                        [
                            -96.7880119997013,
                            46.7006290002058
                        ],
                        [
                            -96.7849250003129,
                            46.6943720003077
                        ],
                        [
                            -96.7872920000011,
                            46.6932289998198
                        ],
                        [
                            -96.7860950002749,
                            46.6914630000746
                        ],
                        [
                            -96.79062600016,
                            46.6891330001697
                        ],
                        [
                            -96.7847029996567,
                            46.6873840000145
                        ],
                        [
                            -96.7840659997971,
                            46.6852699998559
                        ],
                        [
                            -96.7876069996453,
                            46.6852029999728
                        ],
                        [
                            -96.788434000374,
                            46.6816910001121
                        ],
                        [
                            -96.7947989998449,
                            46.6832970002242
                        ],
                        [
                            -96.7875299996524,
                            46.6771030002685
                        ],
                        [
                            -96.7921450001015,
                            46.6765550001335
                        ],
                        [
                            -96.7947670000578,
                            46.6795489998821
                        ],
                        [
                            -96.7989840001237,
                            46.6784529999078
                        ],
                        [
                            -96.798271999921,
                            46.673659000129
                        ],
                        [
                            -96.7931769999713,
                            46.6739880002061
                        ],
                        [
                            -96.7914689998839,
                            46.6725250001729
                        ],
                        [
                            -96.7946370003615,
                            46.6714150002814
                        ],
                        [
                            -96.7936749999136,
                            46.6692890002432
                        ],
                        [
                            -96.7951360001288,
                            46.667507000092
                        ],
                        [
                            -96.7991199996678,
                            46.668902000114
                        ],
                        [
                            -96.8020410002732,
                            46.6678510000436
                        ],
                        [
                            -96.7958099997983,
                            46.6667019999395
                        ],
                        [
                            -96.7985670003721,
                            46.665090000183
                        ],
                        [
                            -96.7975780001601,
                            46.661992999808
                        ],
                        [
                            -96.8000059999477,
                            46.6608469997519
                        ],
                        [
                            -96.7970350000119,
                            46.6590039999156
                        ],
                        [
                            -96.7990369998271,
                            46.6575960000703
                        ],
                        [
                            -96.7989499997885,
                            46.6541120002199
                        ],
                        [
                            -96.7906529999242,
                            46.6490660000761
                        ],
                        [
                            -96.7941579997874,
                            46.6464470001059
                        ],
                        [
                            -96.7901909999669,
                            46.6419079998414
                        ],
                        [
                            -96.7858149998924,
                            46.6405229997623
                        ],
                        [
                            -96.7907819997956,
                            46.6386690002397
                        ],
                        [
                            -96.7923009997371,
                            46.6348229998759
                        ],
                        [
                            -96.796039999952,
                            46.6331800002104
                        ],
                        [
                            -96.7981949999281,
                            46.6295730002035
                        ],
                        [
                            -96.7959889998984,
                            46.6288779997625
                        ],
                        [
                            -96.7954249998338,
                            46.6315620001843
                        ],
                        [
                            -96.7888550003235,
                            46.62921200019
                        ],
                        [
                            -96.7821789996096,
                            46.6314120002145
                        ],
                        [
                            -96.7851169999335,
                            46.6301380003009
                        ],
                        [
                            -96.7862800002228,
                            46.6269149997238
                        ],
                        [
                            -96.7913059996773,
                            46.6249709998638
                        ],
                        [
                            -96.7874030003291,
                            46.6238869997773
                        ],
                        [
                            -96.778332000338,
                            46.6254279999596
                        ],
                        [
                            -96.7862979997661,
                            46.6224209997937
                        ],
                        [
                            -96.7872590003891,
                            46.6194390000295
                        ],
                        [
                            -96.7851770002079,
                            46.6190310000137
                        ],
                        [
                            -96.7835610001821,
                            46.6221529996956
                        ],
                        [
                            -96.7798780000437,
                            46.6194849998082
                        ],
                        [
                            -96.7755320001064,
                            46.6212009998186
                        ],
                        [
                            -96.7749980001791,
                            46.617522999939
                        ],
                        [
                            -96.7808310002707,
                            46.6172469999352
                        ],
                        [
                            -96.7812379999765,
                            46.6153820001353
                        ],
                        [
                            -96.7778829999011,
                            46.6138219996998
                        ],
                        [
                            -96.7735250002683,
                            46.6163930001471
                        ],
                        [
                            -96.7708430000375,
                            46.6152700000065
                        ],
                        [
                            -96.7746230002603,
                            46.6120279997061
                        ],
                        [
                            -96.7725109999419,
                            46.6092929999892
                        ],
                        [
                            -96.7788319999302,
                            46.6074600000268
                        ],
                        [
                            -96.7764450001505,
                            46.6054269997458
                        ],
                        [
                            -96.7682460001955,
                            46.606324000162
                        ],
                        [
                            -96.7722359995823,
                            46.6038299997641
                        ],
                        [
                            -96.7701040000707,
                            46.6023800000145
                        ],
                        [
                            -96.7725290003836,
                            46.6007179998054
                        ],
                        [
                            -96.7715789996313,
                            46.5962129998829
                        ],
                        [
                            -96.7701180003144,
                            46.5955639999368
                        ],
                        [
                            -96.7694870003027,
                            46.5978450000303
                        ],
                        [
                            -96.7670539995939,
                            46.5988870002324
                        ],
                        [
                            -96.7630270003972,
                            46.5941230000145
                        ],
                        [
                            -96.7559840001606,
                            46.595379000193
                        ],
                        [
                            -96.7561909998498,
                            46.592288000269
                        ],
                        [
                            -96.7622139999122,
                            46.5919669998421
                        ],
                        [
                            -96.7626080000976,
                            46.589323999902
                        ],
                        [
                            -96.7574930000563,
                            46.5868820003076
                        ],
                        [
                            -96.7525400003968,
                            46.5882929998086
                        ],
                        [
                            -96.7507870001036,
                            46.5851850002983
                        ],
                        [
                            -96.7603829998013,
                            46.5835409998833
                        ],
                        [
                            -96.7609009998351,
                            46.5818430000142
                        ],
                        [
                            -96.7504039997889,
                            46.58380800008
                        ],
                        [
                            -96.7472660003468,
                            46.5820220001281
                        ],
                        [
                            -96.7484060001716,
                            46.5805919998528
                        ],
                        [
                            -96.7521029996554,
                            46.5820840001574
                        ],
                        [
                            -96.7532430003785,
                            46.5786609999875
                        ],
                        [
                            -96.757499999729,
                            46.5778299997271
                        ],
                        [
                            -96.7558400002205,
                            46.5743129998929
                        ],
                        [
                            -96.7524120003502,
                            46.5763280000374
                        ],
                        [
                            -96.7466150002436,
                            46.5755459996985
                        ],
                        [
                            -96.7466060000228,
                            46.5736530002495
                        ],
                        [
                            -96.7516099997359,
                            46.5733940000896
                        ],
                        [
                            -96.7527589997816,
                            46.5713839998933
                        ],
                        [
                            -96.74539000003,
                            46.5708329999409
                        ],
                        [
                            -96.7474470000968,
                            46.5674519997098
                        ],
                        [
                            -96.7436700002471,
                            46.567079000101
                        ],
                        [
                            -96.7417500004476,
                            46.5639969997583
                        ],
                        [
                            -96.7455609997342,
                            46.565258000302
                        ],
                        [
                            -96.7470720001781,
                            46.5624180000853
                        ],
                        [
                            -96.7519679999362,
                            46.5626099997054
                        ],
                        [
                            -96.7467850001229,
                            46.5588490000027
                        ],
                        [
                            -96.754397000447,
                            46.5568180003
                        ],
                        [
                            -96.7519069998369,
                            46.5555410002625
                        ],
                        [
                            -96.7460429997831,
                            46.5560839997748
                        ],
                        [
                            -96.7492120000856,
                            46.5495850002746
                        ],
                        [
                            -96.7452140003029,
                            46.5505980002499
                        ],
                        [
                            -96.7405689997166,
                            46.5468379997963
                        ],
                        [
                            -96.7418659999002,
                            46.5453470001325
                        ],
                        [
                            -96.744949999814,
                            46.5482250002078
                        ],
                        [
                            -96.7505440004293,
                            46.5441310000337
                        ],
                        [
                            -96.7387150000394,
                            46.5432280001726
                        ],
                        [
                            -96.7411960004287,
                            46.5406709997856
                        ],
                        [
                            -96.7451359995868,
                            46.5420459997217
                        ],
                        [
                            -96.7501159999088,
                            46.5407589999496
                        ],
                        [
                            -96.7468019998414,
                            46.5388589997288
                        ],
                        [
                            -96.7438579996697,
                            46.5403680002997
                        ],
                        [
                            -96.7382599997547,
                            46.5392940000655
                        ],
                        [
                            -96.7426550000957,
                            46.5381040001895
                        ],
                        [
                            -96.7380110002327,
                            46.5346020001898
                        ],
                        [
                            -96.7461649999818,
                            46.5348809999088
                        ],
                        [
                            -96.7432440002747,
                            46.533623000056
                        ],
                        [
                            -96.7420500000233,
                            46.5292909998893
                        ],
                        [
                            -96.7453349997784,
                            46.524982000138
                        ],
                        [
                            -96.7385200000457,
                            46.5254709999238
                        ],
                        [
                            -96.7367409998132,
                            46.5241300001957
                        ],
                        [
                            -96.7376010001539,
                            46.5218610000283
                        ],
                        [
                            -96.7452169997776,
                            46.5201750000981
                        ],
                        [
                            -96.7399810002609,
                            46.5200470000695
                        ],
                        [
                            -96.737443999795,
                            46.5174920001449
                        ],
                        [
                            -96.7376019999788,
                            46.5144269998986
                        ],
                        [
                            -96.7342159999413,
                            46.5121250000271
                        ],
                        [
                            -96.7352589996817,
                            46.5107230001851
                        ],
                        [
                            -96.7404449998681,
                            46.5111040000582
                        ],
                        [
                            -96.7343790001479,
                            46.5069829997092
                        ],
                        [
                            -96.7334819999974,
                            46.5034670002206
                        ],
                        [
                            -96.7359590001887,
                            46.5050959998458
                        ],
                        [
                            -96.7424149998962,
                            46.4999759999327
                        ],
                        [
                            -96.7370060001271,
                            46.5012119997537
                        ],
                        [
                            -96.7385769999471,
                            46.4980390000189
                        ],
                        [
                            -96.7333930003089,
                            46.496983000296
                        ],
                        [
                            -96.7349480002354,
                            46.4949600000703
                        ],
                        [
                            -96.7332049999879,
                            46.4926860001143
                        ],
                        [
                            -96.7369759999899,
                            46.4915609999319
                        ],
                        [
                            -96.734149999819,
                            46.4885910001289
                        ],
                        [
                            -96.7396790001371,
                            46.4867470002942
                        ],
                        [
                            -96.7324629996481,
                            46.4829360000536
                        ],
                        [
                            -96.736822999829,
                            46.4814120002658
                        ],
                        [
                            -96.7388749998729,
                            46.4787059999521
                        ],
                        [
                            -96.7360010000215,
                            46.4774970002798
                        ],
                        [
                            -96.7356040003615,
                            46.4802489998816
                        ],
                        [
                            -96.7335539999674,
                            46.4804720002025
                        ],
                        [
                            -96.7324939996102,
                            46.4782399998293
                        ],
                        [
                            -96.7305699996128,
                            46.4787560002566
                        ],
                        [
                            -96.7267900002883,
                            46.4762780002063
                        ],
                        [
                            -96.7271469997654,
                            46.4722820002544
                        ],
                        [
                            -96.7235549998636,
                            46.4708679998602
                        ],
                        [
                            -96.721599999904,
                            46.4708030001796
                        ],
                        [
                            -96.7197420000288,
                            46.4733840001278
                        ],
                        [
                            -96.7146060000711,
                            46.4722609999599
                        ],
                        [
                            -96.7146489997289,
                            46.4705530000186
                        ],
                        [
                            -96.7192249998198,
                            46.4709010001918
                        ],
                        [
                            -96.722517000146,
                            46.469168999888
                        ],
                        [
                            -96.7230829998604,
                            46.4667170000948
                        ],
                        [
                            -96.7214159997809,
                            46.4652220000139
                        ],
                        [
                            -96.7185200001882,
                            46.4678140001182
                        ],
                        [
                            -96.713184000214,
                            46.4664860000144
                        ],
                        [
                            -96.718714000357,
                            46.461860000028
                        ],
                        [
                            -96.7116109998459,
                            46.4597830000737
                        ],
                        [
                            -96.7122200001164,
                            46.4554459998975
                        ],
                        [
                            -96.7166820004045,
                            46.4559570000692
                        ],
                        [
                            -96.7175929999005,
                            46.4543649999159
                        ],
                        [
                            -96.7137039998977,
                            46.4522579999517
                        ],
                        [
                            -96.7131020001983,
                            46.4501989997254
                        ],
                        [
                            -96.7218290002328,
                            46.44880300014
                        ],
                        [
                            -96.7178160003815,
                            46.4460330002535
                        ],
                        [
                            -96.7133470004207,
                            46.4487609999953
                        ],
                        [
                            -96.7113880002631,
                            46.4464639999146
                        ],
                        [
                            -96.7163619998391,
                            46.4446269999789
                        ],
                        [
                            -96.712302999957,
                            46.4412879997568
                        ],
                        [
                            -96.718273000316,
                            46.4418249997927
                        ],
                        [
                            -96.721852999624,
                            46.4398290002131
                        ],
                        [
                            -96.7179269998113,
                            46.4393740001694
                        ],
                        [
                            -96.7154179998329,
                            46.4339769998715
                        ],
                        [
                            -96.7137499999285,
                            46.4349550002329
                        ],
                        [
                            -96.7157300000024,
                            46.4373180001452
                        ],
                        [
                            -96.7124800004074,
                            46.4378839998204
                        ],
                        [
                            -96.7122400002078,
                            46.4360620001421
                        ],
                        [
                            -96.7104500001047,
                            46.4356049999316
                        ],
                        [
                            -96.7049419997031,
                            46.4382460002685
                        ],
                        [
                            -96.7032689997758,
                            46.4372949997607
                        ],
                        [
                            -96.707654999896,
                            46.4297870002234
                        ],
                        [
                            -96.7057090001573,
                            46.4288139997809
                        ],
                        [
                            -96.7012309999756,
                            46.4302360002423
                        ],
                        [
                            -96.7047049998766,
                            46.4264060001229
                        ],
                        [
                            -96.6988550000665,
                            46.4263830000864
                        ],
                        [
                            -96.7019869996608,
                            46.4253150000896
                        ],
                        [
                            -96.704581000028,
                            46.4179949998239
                        ],
                        [
                            -96.693313000228,
                            46.422085000029
                        ],
                        [
                            -96.692247999848,
                            46.4161109997592
                        ],
                        [
                            -96.6989379999072,
                            46.4172140002334
                        ],
                        [
                            -96.7015170002058,
                            46.4164829998882
                        ],
                        [
                            -96.7015499998178,
                            46.4150630002883
                        ],
                        [
                            -96.6971970002079,
                            46.4125759999165
                        ],
                        [
                            -96.6853550002975,
                            46.4142119999746
                        ],
                        [
                            -96.684970000333,
                            46.4123639999363
                        ],
                        [
                            -96.6898810001598,
                            46.4096720000973
                        ],
                        [
                            -96.6891129998806,
                            46.4068340001768
                        ],
                        [
                            -96.6874840003343,
                            46.4055639997339
                        ],
                        [
                            -96.6805049996718,
                            46.4071079997395
                        ],
                        [
                            -96.6724859996419,
                            46.3952800002967
                        ],
                        [
                            -96.6750780003593,
                            46.3909619997498
                        ],
                        [
                            -96.6677089997094,
                            46.3912439999327
                        ],
                        [
                            -96.6710269999748,
                            46.3894940002501
                        ],
                        [
                            -96.6681780002378,
                            46.3876179997976
                        ],
                        [
                            -96.6696369999048,
                            46.3843790000786
                        ],
                        [
                            -96.666818000305,
                            46.3798889999301
                        ],
                        [
                            -96.6671349995991,
                            46.3755790002552
                        ],
                        [
                            -96.6578040002152,
                            46.3744189997245
                        ],
                        [
                            -96.6603790003159,
                            46.3715630000028
                        ],
                        [
                            -96.6567139997208,
                            46.3696210001306
                        ],
                        [
                            -96.6549770002195,
                            46.3659370001271
                        ],
                        [
                            -96.6431849996395,
                            46.3619349998976
                        ],
                        [
                            -96.6484500003669,
                            46.3580810001773
                        ],
                        [
                            -96.6451940000257,
                            46.3506689998136
                        ],
                        [
                            -96.6425660002216,
                            46.3500399999624
                        ],
                        [
                            -96.6294029998381,
                            46.3543189998254
                        ],
                        [
                            -96.6278439997137,
                            46.3494170001096
                        ],
                        [
                            -96.6166739998229,
                            46.3473880002624
                        ],
                        [
                            -96.6162019998198,
                            46.3458950002527
                        ],
                        [
                            -96.6202449998083,
                            46.3402430000308
                        ],
                        [
                            -96.6096139998678,
                            46.3361930002442
                        ],
                        [
                            -96.6061590002334,
                            46.3311909997917
                        ],
                        [
                            -96.5993789997625,
                            46.3299840001915
                        ],
                        [
                            -96.606138000317,
                            46.3266039998134
                        ],
                        [
                            -96.600027000391,
                            46.3268169997705
                        ],
                        [
                            -96.6012040000257,
                            46.3194979998389
                        ],
                        [
                            -96.5967669998519,
                            46.3170039998372
                        ],
                        [
                            -96.6002750000881,
                            46.3088379999515
                        ],
                        [
                            -96.6001810003768,
                            46.3052929999426
                        ],
                        [
                            -96.6035859997825,
                            46.3025300001348
                        ],
                        [
                            -96.5972980003045,
                            46.2975870003033
                        ],
                        [
                            -96.6056939999029,
                            46.2937229999354
                        ],
                        [
                            -96.5963790004127,
                            46.291193999928
                        ],
                        [
                            -96.5963790004127,
                            46.2880010000313
                        ],
                        [
                            -96.601554999655,
                            46.2873380003086
                        ],
                        [
                            -96.6017649997172,
                            46.286018999772
                        ],
                        [
                            -96.5947549999909,
                            46.2859649998207
                        ],
                        [
                            -96.6003200002939,
                            46.2811310001256
                        ],
                        [
                            -96.5963340002068,
                            46.2779350002747
                        ],
                        [
                            -96.5950439996959,
                            46.2742840000877
                        ],
                        [
                            -96.5973459999851,
                            46.2728130001688
                        ],
                        [
                            -96.5960399995806,
                            46.2715109999786
                        ],
                        [
                            -96.5976120001238,
                            46.2700060001791
                        ],
                        [
                            -96.5968990000963,
                            46.2678050000203
                        ],
                        [
                            -96.6004020003097,
                            46.2617499997688
                        ],
                        [
                            -96.5963049998945,
                            46.2605250001247
                        ],
                        [
                            -96.5929280000778,
                            46.2573129998505
                        ],
                        [
                            -96.595375000132,
                            46.2533980001958
                        ],
                        [
                            -96.5923000004391,
                            46.2503379997885
                        ],
                        [
                            -96.5837149997964,
                            46.2498070002685
                        ],
                        [
                            -96.5860530000707,
                            46.2470250002498
                        ],
                        [
                            -96.5907370001167,
                            46.2450920002238
                        ],
                        [
                            -96.5939060004192,
                            46.2455239999987
                        ],
                        [
                            -96.5987290003824,
                            46.2423170002697
                        ],
                        [
                            -96.5990499998745,
                            46.2357229996914
                        ],
                        [
                            -96.5969390002793,
                            46.232363999779
                        ],
                        [
                            -96.5977920000489,
                            46.2288800001416
                        ],
                        [
                            -96.5947130001581,
                            46.2235069999968
                        ],
                        [
                            -96.5957769998149,
                            46.2197879998139
                        ],
                        [
                            -96.5917420002223,
                            46.2181150000101
                        ],
                        [
                            -96.5927919996354,
                            46.2139140001172
                        ],
                        [
                            -96.5884399998504,
                            46.2128790001008
                        ],
                        [
                            -96.5911139996853,
                            46.2086899999903
                        ],
                        [
                            -96.5870559996281,
                            46.2099670001718
                        ],
                        [
                            -96.5837609998272,
                            46.2080590001137
                        ],
                        [
                            -96.5875949995784,
                            46.2069670001118
                        ],
                        [
                            -96.5890270003795,
                            46.2049460002187
                        ],
                        [
                            -96.5842579999447,
                            46.2040939997473
                        ],
                        [
                            -96.5873490004294,
                            46.2016250000191
                        ],
                        [
                            -96.5836739997886,
                            46.2011629997067
                        ],
                        [
                            -96.5846289996654,
                            46.1975110001738
                        ],
                        [
                            -96.5912259998382,
                            46.1957470000364
                        ],
                        [
                            -96.5850770002774,
                            46.1946239999099
                        ],
                        [
                            -96.587801000341,
                            46.1938309999308
                        ],
                        [
                            -96.5874590000342,
                            46.1920669998145
                        ],
                        [
                            -96.5905379999251,
                            46.190474000042
                        ],
                        [
                            -96.5874130000035,
                            46.1886549997372
                        ],
                        [
                            -96.5896409997745,
                            46.1869179998056
                        ],
                        [
                            -96.5874280000721,
                            46.1858009999429
                        ],
                        [
                            -96.5915720003429,
                            46.184682000248
                        ],
                        [
                            -96.5861509999799,
                            46.1818119999093
                        ],
                        [
                            -96.5892330002438,
                            46.1813950001162
                        ],
                        [
                            -96.5883870001469,
                            46.179083999815
                        ],
                        [
                            -96.5916650002294,
                            46.1784960000751
                        ],
                        [
                            -96.5926820000305,
                            46.1752110000853
                        ],
                        [
                            -96.591059000332,
                            46.1745440002129
                        ],
                        [
                            -96.588086999673,
                            46.1764169999598
                        ],
                        [
                            -96.5856270000983,
                            46.1754169998964
                        ],
                        [
                            -96.5856189997024,
                            46.1773439997611
                        ],
                        [
                            -96.5806319997077,
                            46.1773169997705
                        ],
                        [
                            -96.5791410002536,
                            46.1768319997536
                        ],
                        [
                            -96.5835220003509,
                            46.1726630002772
                        ],
                        [
                            -96.5766359995748,
                            46.1739439997119
                        ],
                        [
                            -96.5770110003918,
                            46.171546000056
                        ],
                        [
                            -96.5838620001095,
                            46.1705989998215
                        ],
                        [
                            -96.5752809996649,
                            46.1690109999631
                        ],
                        [
                            -96.5806080003165,
                            46.1676479998543
                        ],
                        [
                            -96.5770199997144,
                            46.1660360000933
                        ],
                        [
                            -96.5772890002262,
                            46.1628989999375
                        ],
                        [
                            -96.5799129998324,
                            46.1611510002033
                        ],
                        [
                            -96.576264999854,
                            46.1595820002324
                        ],
                        [
                            -96.5802729996825,
                            46.1510759997078
                        ],
                        [
                            -96.5792809999957,
                            46.1474409998501
                        ],
                        [
                            -96.5727850001052,
                            46.1404900000606
                        ],
                        [
                            -96.5717780003498,
                            46.1349230000706
                        ],
                        [
                            -96.5687050003068,
                            46.133156999894
                        ],
                        [
                            -96.5713790001416,
                            46.1311009998866
                        ],
                        [
                            -96.568579999735,
                            46.1303520001779
                        ],
                        [
                            -96.5700690004375,
                            46.1285769998493
                        ],
                        [
                            -96.5696789995519,
                            46.1260609997287
                        ],
                        [
                            -96.5719990002827,
                            46.1245670002977
                        ],
                        [
                            -96.568398999985,
                            46.1232940001357
                        ],
                        [
                            -96.5668990003101,
                            46.1195159999106
                        ],
                        [
                            -96.5625700000913,
                            46.1195149998979
                        ],
                        [
                            -96.5602340003652,
                            46.1165679998904
                        ],
                        [
                            -96.5669200002265,
                            46.1147500001413
                        ],
                        [
                            -96.5579339997259,
                            46.1025209998419
                        ],
                        [
                            -96.5587070000279,
                            46.1004980000468
                        ],
                        [
                            -96.5562719996692,
                            46.0940090000326
                        ],
                        [
                            -96.557351000293,
                            46.0877410000862
                        ],
                        [
                            -96.5544110003193,
                            46.0840790002195
                        ],
                        [
                            -96.5572919998434,
                            46.0776269999056
                        ],
                        [
                            -96.556798000099,
                            46.0647490001919
                        ],
                        [
                            -96.5584860000949,
                            46.0631999997806
                        ],
                        [
                            -96.5591079998858,
                            46.0585230003017
                        ],
                        [
                            -96.5663210000018,
                            46.0515030001304
                        ],
                        [
                            -96.5778819997048,
                            46.0271769996894
                        ],
                        [
                            -96.5742429999473,
                            46.0165359997434
                        ],
                        [
                            -96.5758690000189,
                            46.007999000099
                        ],
                        [
                            -96.5736050002629,
                            46.0023089997135
                        ],
                        [
                            -96.5724910003773,
                            45.9770500000099
                        ],
                        [
                            -96.5703499997466,
                            45.9635949999555
                        ],
                        [
                            -96.5681539997626,
                            45.9587960000551
                        ],
                        [
                            -96.5653340003379,
                            45.9573259998818
                        ],
                        [
                            -96.5648029998852,
                            45.9503479996892
                        ],
                        [
                            -96.5613340000071,
                            45.9456549999239
                        ],
                        [
                            -96.5619829995622,
                            45.9405279997431
                        ],
                        [
                            -96.5636720002813,
                            45.9352449998413
                        ],
                        [
                            -96.5656959998378,
                            45.9352270001041
                        ],
                        [
                            -97.4168369997185,
                            45.9361389998466
                        ],
                        [
                            -97.4819530000555,
                            45.9350720000774
                        ],
                        [
                            -98.3114880000986,
                            45.9361200001872
                        ],
                        [
                            -99.0472619999286,
                            45.9399189997927
                        ],
                        [
                            -100.721111000424,
                            45.9440440000991
                        ],
                        [
                            -104.045546999572,
                            45.9453079998037
                        ],
                        [
                            -104.046827999862,
                            46.0177810002295
                        ],
                        [
                            -104.045481000348,
                            46.1690799997587
                        ],
                        [
                            -104.046627999846,
                            46.9085920000797
                        ],
                        [
                            -104.044959000117,
                            47.0786030001751
                        ],
                        [
                            -104.045370999845,
                            47.3301280001904
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 47.4421698,
            "name": "North Dakota",
            "intptlon": -100.4608163,
            "geo_point_2d": [
                47.4462222144,
                -100.469360338
            ],
            "geoid": "38",
            "mtfcc": "G4000",
            "region": 2
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -100.469360338,
                47.4462222144
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    },
    {
        "datasetid": "us-state-boundaries",
        "recordid": "2bdf8db3378dbf868b9dadf4b71cc70ad6d4b507",
        "fields": {
            "arealand": "1367194945",
            "objectid": 9,
            "basename": "North Carolina",
            "stusab": "NC",
            "statens": "01027616",
            "centlon": -79.1299556,
            "state": "37",
            "gid": 43,
            "centlat": 35.5401695,
            "division": 5,
            "areawater": "585215759",
            "oid": "239560386",
            "funcstat": "A",
            "st_asgeojson": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.4167379999763,
                            36.0728270002645
                        ],
                        [
                            -82.4097979996718,
                            36.0830189996775
                        ],
                        [
                            -82.4025250001797,
                            36.0891450002189
                        ],
                        [
                            -82.3979549999365,
                            36.0904069996621
                        ],
                        [
                            -82.3892179998562,
                            36.0970969998263
                        ],
                        [
                            -82.3804749999281,
                            36.0993470000261
                        ],
                        [
                            -82.3787259998329,
                            36.1032530000622
                        ],
                        [
                            -82.3748579997465,
                            36.1060540002587
                        ],
                        [
                            -82.3645409996254,
                            36.1075159997762
                        ],
                        [
                            -82.3553609998543,
                            36.1153729997757
                        ],
                        [
                            -82.3502030001553,
                            36.117035999856
                        ],
                        [
                            -82.3474449997565,
                            36.1151380000008
                        ],
                        [
                            -82.3361390003217,
                            36.1146729998251
                        ],
                        [
                            -82.3318059999049,
                            36.1174519998083
                        ],
                        [
                            -82.3231940003964,
                            36.1202410002116
                        ],
                        [
                            -82.3203539999818,
                            36.1198009997615
                        ],
                        [
                            -82.3024809996131,
                            36.1314649997299
                        ],
                        [
                            -82.2898200002683,
                            36.1355959998672
                        ],
                        [
                            -82.2800119999601,
                            36.1290940000562
                        ],
                        [
                            -82.2734480002976,
                            36.1293170001274
                        ],
                        [
                            -82.2667769996066,
                            36.1270009998612
                        ],
                        [
                            -82.2639250003948,
                            36.1272969997265
                        ],
                        [
                            -82.2603040001807,
                            36.133532999915
                        ],
                        [
                            -82.2543279999739,
                            36.1360580002518
                        ],
                        [
                            -82.2510719996327,
                            36.1320510000648
                        ],
                        [
                            -82.2450970001492,
                            36.1312489999762
                        ],
                        [
                            -82.2418829996408,
                            36.1371250001759
                        ],
                        [
                            -82.2295369999403,
                            36.1445879996805
                        ],
                        [
                            -82.2242749995859,
                            36.1508379997828
                        ],
                        [
                            -82.2221449997242,
                            36.1569220000952
                        ],
                        [
                            -82.2141149998236,
                            36.1590189999159
                        ],
                        [
                            -82.2017330001381,
                            36.1563840000467
                        ],
                        [
                            -82.1995570002455,
                            36.1528289999735
                        ],
                        [
                            -82.1927459998125,
                            36.1490379996384
                        ],
                        [
                            -82.1875830000907,
                            36.1509950001163
                        ],
                        [
                            -82.1836260003159,
                            36.1439110001056
                        ],
                        [
                            -82.1772169995657,
                            36.1423730002261
                        ],
                        [
                            -82.172934000276,
                            36.146500000037
                        ],
                        [
                            -82.1685089997977,
                            36.14671399996
                        ],
                        [
                            -82.1656500000149,
                            36.1449719999714
                        ],
                        [
                            -82.1485809999103,
                            36.1498590002831
                        ],
                        [
                            -82.1443680000424,
                            36.1441380001213
                        ],
                        [
                            -82.144451999708,
                            36.1404059996721
                        ],
                        [
                            -82.1409910002258,
                            36.1386749999567
                        ],
                        [
                            -82.1367759998098,
                            36.1281000001277
                        ],
                        [
                            -82.1378560002584,
                            36.1195099996561
                        ],
                        [
                            -82.1291979998209,
                            36.1041000000129
                        ],
                        [
                            -82.1161230001992,
                            36.1043319997403
                        ],
                        [
                            -82.1054179997406,
                            36.1078369999648
                        ],
                        [
                            -82.1003100002703,
                            36.105670999817
                        ],
                        [
                            -82.0865319997686,
                            36.1060020000051
                        ],
                        [
                            -82.0810789996186,
                            36.1031699997524
                        ],
                        [
                            -82.0800280003806,
                            36.1064329998392
                        ],
                        [
                            -82.0618689997815,
                            36.1130619997449
                        ],
                        [
                            -82.0541410000047,
                            36.1266629999541
                        ],
                        [
                            -82.0447550003693,
                            36.1255560002165
                        ],
                        [
                            -82.0389239999275,
                            36.1215670000662
                        ],
                        [
                            -82.0338390000235,
                            36.1204750000205
                        ],
                        [
                            -82.0290510002204,
                            36.124667000191
                        ],
                        [
                            -82.0262009997601,
                            36.1312079996502
                        ],
                        [
                            -81.9081670004454,
                            36.3019659999487
                        ],
                        [
                            -81.8983459997185,
                            36.3072519996457
                        ],
                        [
                            -81.8872360001022,
                            36.3092380000287
                        ],
                        [
                            -81.879520000021,
                            36.3137889997085
                        ],
                        [
                            -81.8666279997992,
                            36.3262229999518
                        ],
                        [
                            -81.8655089998908,
                            36.3291930002165
                        ],
                        [
                            -81.8549879995552,
                            36.337211000188
                        ],
                        [
                            -81.8519110002125,
                            36.3370119996448
                        ],
                        [
                            -81.8330489996316,
                            36.347331999645
                        ],
                        [
                            -81.8102760002051,
                            36.3518500001487
                        ],
                        [
                            -81.8009320004025,
                            36.3579989997807
                        ],
                        [
                            -81.7972800002262,
                            36.3585449996914
                        ],
                        [
                            -81.7930849999017,
                            36.3624079996442
                        ],
                        [
                            -81.7935879998669,
                            36.3589579997784
                        ],
                        [
                            -81.7907559998483,
                            36.355641999893
                        ],
                        [
                            -81.7897299998263,
                            36.3483279999264
                        ],
                        [
                            -81.7788889998236,
                            36.34856600004
                        ],
                        [
                            -81.774076999731,
                            36.3438240001882
                        ],
                        [
                            -81.7691700001022,
                            36.3424630002662
                        ],
                        [
                            -81.7649539998613,
                            36.3387219996576
                        ],
                        [
                            -81.7611319998057,
                            36.3402539999174
                        ],
                        [
                            -81.7544700002339,
                            36.3369990002825
                        ],
                        [
                            -81.7457330001536,
                            36.3375920002677
                        ],
                        [
                            -81.7396060003341,
                            36.3397569996892
                        ],
                        [
                            -81.7360289996026,
                            36.3429719996975
                        ],
                        [
                            -81.7278380000435,
                            36.3413829997549
                        ],
                        [
                            -81.7184940002409,
                            36.3361219999975
                        ],
                        [
                            -81.7074749999629,
                            36.3352249997168
                        ],
                        [
                            -81.7053230003598,
                            36.3414149999425
                        ],
                        [
                            -81.706111999657,
                            36.3437239997573
                        ],
                        [
                            -81.7082420004171,
                            36.3463329998194
                        ],
                        [
                            -81.716798999674,
                            36.347595000241
                        ],
                        [
                            -81.7215210002532,
                            36.3513040003035
                        ],
                        [
                            -81.7243730003633,
                            36.357694999999
                        ],
                        [
                            -81.7225199996127,
                            36.3586400002506
                        ],
                        [
                            -81.7228829998358,
                            36.3612369997277
                        ],
                        [
                            -81.7271159997952,
                            36.365586000115
                        ],
                        [
                            -81.7260660003821,
                            36.368785000245
                        ],
                        [
                            -81.7346600003472,
                            36.3794420000313
                        ],
                        [
                            -81.729497999552,
                            36.3854389997744
                        ],
                        [
                            -81.7305129997033,
                            36.3882850001305
                        ],
                        [
                            -81.7288149996617,
                            36.3915500001619
                        ],
                        [
                            -81.731958000025,
                            36.3949290001743
                        ],
                        [
                            -81.7393679997844,
                            36.3955910000337
                        ],
                        [
                            -81.7382699997924,
                            36.4008340002437
                        ],
                        [
                            -81.7414059995846,
                            36.4030639998354
                        ],
                        [
                            -81.7400289999334,
                            36.4084549997907
                        ],
                        [
                            -81.7419289996414,
                            36.4112489998087
                        ],
                        [
                            -81.7403190003616,
                            36.413121000269
                        ],
                        [
                            -81.734518000057,
                            36.4124970003087
                        ],
                        [
                            -81.7336500002188,
                            36.4146839996491
                        ],
                        [
                            -81.7294409996506,
                            36.4158949996947
                        ],
                        [
                            -81.7271519997802,
                            36.4198690000698
                        ],
                        [
                            -81.7207270000348,
                            36.4226510002782
                        ],
                        [
                            -81.7178470003356,
                            36.429856999665
                        ],
                        [
                            -81.7213170000387,
                            36.4339000003547
                        ],
                        [
                            -81.7148029997066,
                            36.4372270001804
                        ],
                        [
                            -81.7144980001081,
                            36.4414800001571
                        ],
                        [
                            -81.7178260004192,
                            36.4450230001924
                        ],
                        [
                            -81.7137869997304,
                            36.44717299992
                        ],
                        [
                            -81.7167970000242,
                            36.456669000139
                        ],
                        [
                            -81.7096679995739,
                            36.4593789997039
                        ],
                        [
                            -81.7081179996702,
                            36.4622259996866
                        ],
                        [
                            -81.6990009996483,
                            36.4640680000074
                        ],
                        [
                            -81.6950289998049,
                            36.4674580001919
                        ],
                        [
                            -81.6961789996754,
                            36.4697450002226
                        ],
                        [
                            -81.6946079998554,
                            36.473372000358
                        ],
                        [
                            -81.696350000278,
                            36.4755370003509
                        ],
                        [
                            -81.6979239995727,
                            36.4889489999225
                        ],
                        [
                            -81.69594900042,
                            36.4916190003452
                        ],
                        [
                            -81.6972449998804,
                            36.4964239999371
                        ],
                        [
                            -81.7002540003493,
                            36.4984570002495
                        ],
                        [
                            -81.6974239999805,
                            36.5021549997366
                        ],
                        [
                            -81.6970909998946,
                            36.5059199996449
                        ],
                        [
                            -81.698398000124,
                            36.5064359998167
                        ],
                        [
                            -81.6983439996973,
                            36.5097170000587
                        ],
                        [
                            -81.702970000017,
                            36.5211410002483
                        ],
                        [
                            -81.7080979995787,
                            36.5241139997382
                        ],
                        [
                            -81.7080750000125,
                            36.5356870002477
                        ],
                        [
                            -81.6997949998667,
                            36.5367270001968
                        ],
                        [
                            -81.6997680001025,
                            36.5403159999463
                        ],
                        [
                            -81.6937900002459,
                            36.5479770000695
                        ],
                        [
                            -81.6933609999005,
                            36.5509990001271
                        ],
                        [
                            -81.6902209999103,
                            36.5516860000305
                        ],
                        [
                            -81.6909980004103,
                            36.5553900002694
                        ],
                        [
                            -81.6895480000657,
                            36.5614409999225
                        ],
                        [
                            -81.6918779999441,
                            36.5659409999698
                        ],
                        [
                            -81.6912860002903,
                            36.5677160001577
                        ],
                        [
                            -81.6800720000187,
                            36.56867199995
                        ],
                        [
                            -81.6770469996562,
                            36.5707549999169
                        ],
                        [
                            -81.6792140002262,
                            36.5786679997141
                        ],
                        [
                            -81.6769789998841,
                            36.5811239999424
                        ],
                        [
                            -81.6801199996992,
                            36.5856199996583
                        ],
                        [
                            -81.6774900002453,
                            36.5881370000227
                        ],
                        [
                            -81.6004550000051,
                            36.5869480003139
                        ],
                        [
                            -81.5203469997218,
                            36.5805210000958
                        ],
                        [
                            -81.3860970000721,
                            36.5749380003275
                        ],
                        [
                            -81.3074369995852,
                            36.5749609998328
                        ],
                        [
                            -81.1769329998232,
                            36.5718850003008
                        ],
                        [
                            -81.0038080002147,
                            36.5636559999024
                        ],
                        [
                            -80.9329380003272,
                            36.5629640001542
                        ],
                        [
                            -80.8377710001504,
                            36.5591380000809
                        ],
                        [
                            -80.7998259999541,
                            36.5610989998164
                        ],
                        [
                            -80.7735979999948,
                            36.5603740000134
                        ],
                        [
                            -80.704601000051,
                            36.5623019999865
                        ],
                        [
                            -80.294876999564,
                            36.5440530001802
                        ],
                        [
                            -80.1382760002622,
                            36.5426320002568
                        ],
                        [
                            -79.5433869998895,
                            36.5408560001517
                        ],
                        [
                            -78.9369800003699,
                            36.5421900001614
                        ],
                        [
                            -78.510897000331,
                            36.5410489999758
                        ],
                        [
                            -78.2497170003794,
                            36.5444849998284
                        ],
                        [
                            -77.2049699999105,
                            36.5446010003184
                        ],
                        [
                            -77.1696600000894,
                            36.5473149999565
                        ],
                        [
                            -77.1529199998727,
                            36.5441299996612
                        ],
                        [
                            -76.9165100000383,
                            36.5438810001629
                        ],
                        [
                            -76.9170980003924,
                            36.5508569998099
                        ],
                        [
                            -76.915899000118,
                            36.5521490001173
                        ],
                        [
                            -76.7489540000326,
                            36.5507479999891
                        ],
                        [
                            -75.7974969995986,
                            36.5509159998209
                        ],
                        [
                            -75.7905580000174,
                            36.500155999842
                        ],
                        [
                            -75.7496350002126,
                            36.3536060002301
                        ],
                        [
                            -75.7113190002956,
                            36.2501629999152
                        ],
                        [
                            -75.6750839998365,
                            36.1667260002406
                        ],
                        [
                            -75.6531950001417,
                            36.125165999992
                        ],
                        [
                            -75.5816080000286,
                            36.0001649999014
                        ],
                        [
                            -75.5660469999948,
                            35.9756139996589
                        ],
                        [
                            -75.5115139999178,
                            35.8751750002612
                        ],
                        [
                            -75.4805009996301,
                            35.8142069998032
                        ],
                        [
                            -75.4662369997799,
                            35.8013149998512
                        ],
                        [
                            -75.4611240002868,
                            35.7901710001436
                        ],
                        [
                            -75.460814999592,
                            35.784524000023
                        ],
                        [
                            -75.451629999798,
                            35.7715569997601
                        ],
                        [
                            -75.437617000018,
                            35.7469600002988
                        ],
                        [
                            -75.4178199996518,
                            35.6860819997648
                        ],
                        [
                            -75.4068149996175,
                            35.6369719998122
                        ],
                        [
                            -75.4003899998721,
                            35.5935120000429
                        ],
                        [
                            -75.4008490003547,
                            35.5587180000688
                        ],
                        [
                            -75.4024219998246,
                            35.5442100003615
                        ],
                        [
                            -75.4150889999155,
                            35.5001799997281
                        ],
                        [
                            -75.4234960002829,
                            35.4276520000921
                        ],
                        [
                            -75.4261349999577,
                            35.3869230000655
                        ],
                        [
                            -75.447750999841,
                            35.3097940002054
                        ],
                        [
                            -75.4593340001836,
                            35.2465520002666
                        ],
                        [
                            -75.4684770001448,
                            35.2151900001981
                        ],
                        [
                            -75.4745999997664,
                            35.2020289999861
                        ],
                        [
                            -75.4845710002811,
                            35.187577000031
                        ],
                        [
                            -75.5050590000352,
                            35.174426000174
                        ],
                        [
                            -75.5192130002806,
                            35.1690830002127
                        ],
                        [
                            -75.5321759997492,
                            35.1671490002139
                        ],
                        [
                            -75.5480029999218,
                            35.1681839997426
                        ],
                        [
                            -75.5575839995509,
                            35.1705350001654
                        ],
                        [
                            -75.5693250000773,
                            35.1755329996775
                        ],
                        [
                            -75.5811080004364,
                            35.1837839999397
                        ],
                        [
                            -75.5953689999136,
                            35.1828270002209
                        ],
                        [
                            -75.6362149997255,
                            35.1722049998769
                        ],
                        [
                            -75.6723790000395,
                            35.1602100001212
                        ],
                        [
                            -75.7073700000184,
                            35.1461109999682
                        ],
                        [
                            -75.7362140000863,
                            35.1407050000697
                        ],
                        [
                            -75.7498469999247,
                            35.1362119997083
                        ],
                        [
                            -75.7948830000382,
                            35.1192289997826
                        ],
                        [
                            -75.8120450000293,
                            35.1142440000456
                        ],
                        [
                            -75.8839600002053,
                            35.0839679999137
                        ],
                        [
                            -75.9085880000324,
                            35.0715219996916
                        ],
                        [
                            -75.9281030003663,
                            35.059523999728
                        ],
                        [
                            -75.9387329995835,
                            35.0514490003395
                        ],
                        [
                            -75.9500730002519,
                            35.0396170002437
                        ],
                        [
                            -75.9670929999528,
                            35.0286000002362
                        ],
                        [
                            -75.985351000286,
                            35.0139770000483
                        ],
                        [
                            -75.9996200001591,
                            35.0070650000236
                        ],
                        [
                            -76.024429999561,
                            35.0053639997592
                        ],
                        [
                            -76.1429809999829,
                            34.9188329997067
                        ],
                        [
                            -76.219247999944,
                            34.8564219996928
                        ],
                        [
                            -76.2496319998698,
                            34.834631000187
                        ],
                        [
                            -76.3157309996726,
                            34.7760300003237
                        ],
                        [
                            -76.3746380001743,
                            34.7159909996886
                        ],
                        [
                            -76.4390010003007,
                            34.6402880001316
                        ],
                        [
                            -76.4663380001227,
                            34.595738000145
                        ],
                        [
                            -76.4774600003328,
                            34.566203999884
                        ],
                        [
                            -76.4831829999213,
                            34.5563600002285
                        ],
                        [
                            -76.4919910001467,
                            34.5465320000589
                        ],
                        [
                            -76.5042129999987,
                            34.5395960001724
                        ],
                        [
                            -76.5243779997127,
                            34.532155000346
                        ],
                        [
                            -76.5316440004304,
                            34.5317360000363
                        ],
                        [
                            -76.5478740001108,
                            34.532838999751
                        ],
                        [
                            -76.5625089996818,
                            34.5369120003249
                        ],
                        [
                            -76.5720659999197,
                            34.5425440002948
                        ],
                        [
                            -76.5837200004075,
                            34.5526250000101
                        ],
                        [
                            -76.5907450002024,
                            34.5631300003495
                        ],
                        [
                            -76.595485000325,
                            34.5778429997502
                        ],
                        [
                            -76.6123440003673,
                            34.6001280003168
                        ],
                        [
                            -76.6147219999262,
                            34.6182780002188
                        ],
                        [
                            -76.6772270001774,
                            34.6343040001247
                        ],
                        [
                            -76.6927999999068,
                            34.6429500000952
                        ],
                        [
                            -76.7421810001326,
                            34.6476539998756
                        ],
                        [
                            -76.7817930004084,
                            34.645832000303
                        ],
                        [
                            -76.8189369998153,
                            34.6432530003459
                        ],
                        [
                            -76.9509679999148,
                            34.6251619998664
                        ],
                        [
                            -76.9996660002503,
                            34.6170089999525
                        ],
                        [
                            -77.0792079999208,
                            34.5959739999544
                        ],
                        [
                            -77.0943110001952,
                            34.5885909996682
                        ],
                        [
                            -77.1229989997292,
                            34.5843749999678
                        ],
                        [
                            -77.1605759996794,
                            34.5690260003083
                        ],
                        [
                            -77.2191760000344,
                            34.5396599997024
                        ],
                        [
                            -77.2602439996041,
                            34.5110180000173
                        ],
                        [
                            -77.2950770002976,
                            34.4889680001371
                        ],
                        [
                            -77.3412709997792,
                            34.4716630000692
                        ],
                        [
                            -77.4188319995509,
                            34.435923000256
                        ],
                        [
                            -77.5161620000997,
                            34.3824429997443
                        ],
                        [
                            -77.556731999727,
                            34.3536509998754
                        ],
                        [
                            -77.59487500029,
                            34.3237369999422
                        ],
                        [
                            -77.6085890003193,
                            34.3101359998267
                        ],
                        [
                            -77.6235789997175,
                            34.3031619997225
                        ],
                        [
                            -77.6558289999144,
                            34.2739989998458
                        ],
                        [
                            -77.6684530003476,
                            34.2583019998243
                        ],
                        [
                            -77.6803210001973,
                            34.2501689999253
                        ],
                        [
                            -77.6910929997048,
                            34.2457439996426
                        ],
                        [
                            -77.7008259996699,
                            34.2359990000294
                        ],
                        [
                            -77.7092880002889,
                            34.2218130003667
                        ],
                        [
                            -77.7229529999143,
                            34.2099970003278
                        ],
                        [
                            -77.7307560000342,
                            34.1897879998775
                        ],
                        [
                            -77.7398279998502,
                            34.1805419999762
                        ],
                        [
                            -77.7474200000829,
                            34.1755940001182
                        ],
                        [
                            -77.7471210003321,
                            34.1710209997303
                        ],
                        [
                            -77.7497020002806,
                            34.1612139998014
                        ],
                        [
                            -77.7537910002999,
                            34.1550229998898
                        ],
                        [
                            -77.7749660001422,
                            34.1386650001074
                        ],
                        [
                            -77.78555500025,
                            34.1251710000948
                        ],
                        [
                            -77.8002529995701,
                            34.0907120001264
                        ],
                        [
                            -77.830206000399,
                            34.0302829997374
                        ],
                        [
                            -77.8420409997384,
                            34.0001729997669
                        ],
                        [
                            -77.8409360000737,
                            34.0001729997669
                        ],
                        [
                            -77.8552519998024,
                            33.9600950002227
                        ],
                        [
                            -77.8621689996424,
                            33.9486090002675
                        ],
                        [
                            -77.8635339995981,
                            33.9346700001613
                        ],
                        [
                            -77.8845190003679,
                            33.9036700000113
                        ],
                        [
                            -77.8861089995562,
                            33.891195000181
                        ],
                        [
                            -77.8942459995869,
                            33.8786550003469
                        ],
                        [
                            -77.8956459997027,
                            33.8639079999345
                        ],
                        [
                            -77.8989699998159,
                            33.8546389997898
                        ],
                        [
                            -77.8989749998388,
                            33.8485280001468
                        ],
                        [
                            -77.895306999769,
                            33.8353050003288
                        ],
                        [
                            -77.8967820002278,
                            33.8197099998125
                        ],
                        [
                            -77.8936859997202,
                            33.8047020002163
                        ],
                        [
                            -77.8966590002042,
                            33.7875470003202
                        ],
                        [
                            -77.9024360002193,
                            33.7785199996932
                        ],
                        [
                            -77.9175050001586,
                            33.7638199997172
                        ],
                        [
                            -77.9292840003198,
                            33.7579840001914
                        ],
                        [
                            -77.9430610000983,
                            33.753769999804
                        ],
                        [
                            -77.9538479996744,
                            33.7528779997974
                        ],
                        [
                            -77.9732450000075,
                            33.75474300022
                        ],
                        [
                            -77.9997099997933,
                            33.7671300003348
                        ],
                        [
                            -78.0090269998318,
                            33.781728999744
                        ],
                        [
                            -78.0114279998551,
                            33.7888949999396
                        ],
                        [
                            -78.012859999758,
                            33.803863000093
                        ],
                        [
                            -78.0409809997526,
                            33.8187739996423
                        ],
                        [
                            -78.0528949996331,
                            33.8274319996941
                        ],
                        [
                            -78.0627559996446,
                            33.8392029996817
                        ],
                        [
                            -78.0665810000733,
                            33.8479849999404
                        ],
                        [
                            -78.1453529998148,
                            33.8627340001082
                        ],
                        [
                            -78.1691639998572,
                            33.8640889999628
                        ],
                        [
                            -78.2237730001022,
                            33.8624750001912
                        ],
                        [
                            -78.2623350000665,
                            33.8631630001634
                        ],
                        [
                            -78.3412080000905,
                            33.8552929996386
                        ],
                        [
                            -78.3545060001932,
                            33.8522519997398
                        ],
                        [
                            -78.3671239998803,
                            33.8515970000456
                        ],
                        [
                            -78.3990019996332,
                            33.843262000238
                        ],
                        [
                            -78.4993009995696,
                            33.8128519998785
                        ],
                        [
                            -78.5509449997266,
                            33.8597049999372
                        ],
                        [
                            -78.7027709996441,
                            33.9892680003252
                        ],
                        [
                            -79.2356700003246,
                            34.4377029999455
                        ],
                        [
                            -79.3582909996009,
                            34.5456250002769
                        ],
                        [
                            -79.6752999999882,
                            34.8047440002625
                        ],
                        [
                            -79.7628020001374,
                            34.8060580001192
                        ],
                        [
                            -79.8748550001207,
                            34.8054099997161
                        ],
                        [
                            -80.4997850002874,
                            34.8172650003266
                        ],
                        [
                            -80.797491000053,
                            34.8197519996535
                        ],
                        [
                            -80.7820420001721,
                            34.9357849999432
                        ],
                        [
                            -80.8872729998517,
                            35.0561960001661
                        ],
                        [
                            -80.9349500001881,
                            35.1074089999194
                        ],
                        [
                            -80.9578740001258,
                            35.0926359998138
                        ],
                        [
                            -81.041488999922,
                            35.0447029997382
                        ],
                        [
                            -81.0576480003556,
                            35.0624330002923
                        ],
                        [
                            -81.0572359997287,
                            35.08612900033
                        ],
                        [
                            -81.0500779998643,
                            35.0988169998614
                        ],
                        [
                            -81.0373659995675,
                            35.1025419997598
                        ],
                        [
                            -81.0328050004435,
                            35.1080490002623
                        ],
                        [
                            -81.0330049995617,
                            35.1137469998437
                        ],
                        [
                            -81.038968000248,
                            35.1262989998324
                        ],
                        [
                            -81.0504200001711,
                            35.1310479997465
                        ],
                        [
                            -81.0512040003437,
                            35.1332370003172
                        ],
                        [
                            -81.0478259998038,
                            35.1437430002749
                        ],
                        [
                            -81.0443910002608,
                            35.1479180001108
                        ],
                        [
                            -81.0331960002557,
                            35.1491970000611
                        ],
                        [
                            -81.1092809997436,
                            35.1541179997473
                        ],
                        [
                            -81.2642729995906,
                            35.1610620001418
                        ],
                        [
                            -81.7241220002932,
                            35.1791389998201
                        ],
                        [
                            -82.1811209996371,
                            35.194067000346
                        ],
                        [
                            -82.2749200003834,
                            35.2000710000455
                        ],
                        [
                            -82.2884529997644,
                            35.1986040000089
                        ],
                        [
                            -82.2953539997108,
                            35.1949650002957
                        ],
                        [
                            -82.314863000197,
                            35.1910890003212
                        ],
                        [
                            -82.323350000032,
                            35.1847890001029
                        ],
                        [
                            -82.3305489999043,
                            35.1867670001807
                        ],
                        [
                            -82.330779000058,
                            35.189031999782
                        ],
                        [
                            -82.332975000042,
                            35.1906449998363
                        ],
                        [
                            -82.3395079997424,
                            35.18893000019
                        ],
                        [
                            -82.3445540001867,
                            35.1931149999289
                        ],
                        [
                            -82.3500859999795,
                            35.1928580002035
                        ],
                        [
                            -82.3515959997001,
                            35.190101000075
                        ],
                        [
                            -82.3593040002837,
                            35.19231800008
                        ],
                        [
                            -82.3645680002879,
                            35.1891339999802
                        ],
                        [
                            -82.3625589999016,
                            35.1860299998812
                        ],
                        [
                            -82.3720580004133,
                            35.1803389997619
                        ],
                        [
                            -82.3839439998063,
                            35.1910069996836
                        ],
                        [
                            -82.3828829996242,
                            35.1943470001593
                        ],
                        [
                            -82.378500999702,
                            35.1953490001005
                        ],
                        [
                            -82.3785390002351,
                            35.1968330000781
                        ],
                        [
                            -82.3805240003319,
                            35.2022760003251
                        ],
                        [
                            -82.3851690000199,
                            35.2062660002454
                        ],
                        [
                            -82.3840290001951,
                            35.2105419999868
                        ],
                        [
                            -82.3873719996766,
                            35.2118309999036
                        ],
                        [
                            -82.390253000099,
                            35.2155399997923
                        ],
                        [
                            -82.3956970000282,
                            35.2132140000644
                        ],
                        [
                            -82.3994250003725,
                            35.2091789997873
                        ],
                        [
                            -82.3982589997101,
                            35.2066389998418
                        ],
                        [
                            -82.4002110001949,
                            35.2050890001799
                        ],
                        [
                            -82.4071530001492,
                            35.2023900000023
                        ],
                        [
                            -82.4113009997197,
                            35.2024830003545
                        ],
                        [
                            -82.4151169999276,
                            35.1992299998522
                        ],
                        [
                            -82.4172340002688,
                            35.2002770000447
                        ],
                        [
                            -82.4197440000722,
                            35.1986129996621
                        ],
                        [
                            -82.4244609997302,
                            35.1930920000723
                        ],
                        [
                            -82.4232940001412,
                            35.1907779998905
                        ],
                        [
                            -82.4260979996724,
                            35.1892989997273
                        ],
                        [
                            -82.4277840000185,
                            35.1844719999541
                        ],
                        [
                            -82.4279410003773,
                            35.1830880000604
                        ],
                        [
                            -82.4243150001403,
                            35.1814530003097
                        ],
                        [
                            -82.431313999996,
                            35.1771389999541
                        ],
                        [
                            -82.4314810004006,
                            35.1731869996701
                        ],
                        [
                            -82.4347539995619,
                            35.1707720001146
                        ],
                        [
                            -82.4346759997441,
                            35.1682789999368
                        ],
                        [
                            -82.4401480001607,
                            35.1656840003112
                        ],
                        [
                            -82.4504650002819,
                            35.1661180000544
                        ],
                        [
                            -82.4571279996786,
                            35.1639499999241
                        ],
                        [
                            -82.4574299998024,
                            35.167385000303
                        ],
                        [
                            -82.4516240003732,
                            35.172494999849
                        ],
                        [
                            -82.4546520002104,
                            35.177560000225
                        ],
                        [
                            -82.4600919999417,
                            35.1781429999978
                        ],
                        [
                            -82.467991000321,
                            35.1746329999375
                        ],
                        [
                            -82.4749490001688,
                            35.1740429999881
                        ],
                        [
                            -82.4777540004233,
                            35.1760880001311
                        ],
                        [
                            -82.482328999791,
                            35.1738800003125
                        ],
                        [
                            -82.486652999987,
                            35.1744630000427
                        ],
                        [
                            -82.4872480000137,
                            35.169727999833
                        ],
                        [
                            -82.4907660002957,
                            35.1697150000369
                        ],
                        [
                            -82.4955060004182,
                            35.164730999867
                        ],
                        [
                            -82.4945640000618,
                            35.1631599999186
                        ],
                        [
                            -82.4957099997344,
                            35.1620609999185
                        ],
                        [
                            -82.5045799998841,
                            35.1658510003325
                        ],
                        [
                            -82.507914000043,
                            35.1624060002881
                        ],
                        [
                            -82.5164269998174,
                            35.1633639997607
                        ],
                        [
                            -82.5190909996065,
                            35.1620830002305
                        ],
                        [
                            -82.5199259998328,
                            35.1591049996835
                        ],
                        [
                            -82.5274600003391,
                            35.1558870000098
                        ],
                        [
                            -82.5364740004288,
                            35.1547829999441
                        ],
                        [
                            -82.5367470002403,
                            35.1600910001286
                        ],
                        [
                            -82.546198000173,
                            35.1637300001171
                        ],
                        [
                            -82.5499070002507,
                            35.1615839999347
                        ],
                        [
                            -82.5532699998237,
                            35.161652999796
                        ],
                        [
                            -82.5561649995915,
                            35.1578400003048
                        ],
                        [
                            -82.5538449997589,
                            35.1528749998066
                        ],
                        [
                            -82.5574450000567,
                            35.1507689997643
                        ],
                        [
                            -82.5626100003267,
                            35.153058000285
                        ],
                        [
                            -82.5644459995622,
                            35.1522380002212
                        ],
                        [
                            -82.5683000003032,
                            35.1483030000312
                        ],
                        [
                            -82.568270000166,
                            35.1456820000335
                        ],
                        [
                            -82.576909000337,
                            35.1447329999568
                        ],
                        [
                            -82.5786860000213,
                            35.1412600001858
                        ],
                        [
                            -82.5881579998704,
                            35.142927999847
                        ],
                        [
                            -82.5918500002297,
                            35.1412160000473
                        ],
                        [
                            -82.5924300001878,
                            35.1390020001712
                        ],
                        [
                            -82.5981400002558,
                            35.1377289997616
                        ],
                        [
                            -82.6130819999735,
                            35.1397259997506
                        ],
                        [
                            -82.6149049996885,
                            35.1363910001404
                        ],
                        [
                            -82.6241480001071,
                            35.1333860003003
                        ],
                        [
                            -82.6245749999044,
                            35.1305679997215
                        ],
                        [
                            -82.628837000176,
                            35.1257849996605
                        ],
                        [
                            -82.6362360000648,
                            35.1254949999618
                        ],
                        [
                            -82.6364770000893,
                            35.130356999882
                        ],
                        [
                            -82.6397180003618,
                            35.1297999998206
                        ],
                        [
                            -82.6415129995895,
                            35.1323419999561
                        ],
                        [
                            -82.6452950003604,
                            35.1268070000197
                        ],
                        [
                            -82.652191000284,
                            35.1260470002993
                        ],
                        [
                            -82.65196299978,
                            35.1229729997863
                        ],
                        [
                            -82.6566610000698,
                            35.1187979999172
                        ],
                        [
                            -82.6666269996633,
                            35.118174999655
                        ],
                        [
                            -82.6694300002679,
                            35.1206030000752
                        ],
                        [
                            -82.673272000415,
                            35.1174799998113
                        ],
                        [
                            -82.6744570004456,
                            35.1184740001384
                        ],
                        [
                            -82.6730820004442,
                            35.1215020001781
                        ],
                        [
                            -82.6746149997311,
                            35.1264349999212
                        ],
                        [
                            -82.6814429998826,
                            35.1279180000064
                        ],
                        [
                            -82.6831369997262,
                            35.1259529999763
                        ],
                        [
                            -82.6870840003536,
                            35.1251159999772
                        ],
                        [
                            -82.6867379998489,
                            35.1197900002629
                        ],
                        [
                            -82.6924450004421,
                            35.1186689998943
                        ],
                        [
                            -82.6945660000831,
                            35.1153249997561
                        ],
                        [
                            -82.6932570002039,
                            35.1130479999772
                        ],
                        [
                            -82.687281999822,
                            35.1106670002096
                        ],
                        [
                            -82.686484000304,
                            35.1081639999463
                        ],
                        [
                            -82.6979450004479,
                            35.0953589998496
                        ],
                        [
                            -82.6993980002671,
                            35.0959700001216
                        ],
                        [
                            -82.700272999778,
                            35.1008510002381
                        ],
                        [
                            -82.7152970004097,
                            35.0929429998748
                        ],
                        [
                            -82.7270100004487,
                            35.0941419998029
                        ],
                        [
                            -82.729516999879,
                            35.0905900000234
                        ],
                        [
                            -82.7295830000012,
                            35.0876259997613
                        ],
                        [
                            -82.7343349998193,
                            35.0870479998309
                        ],
                        [
                            -82.7361390001662,
                            35.0813979996387
                        ],
                        [
                            -82.7388849999711,
                            35.0810639997496
                        ],
                        [
                            -82.7387569999245,
                            35.078200000279
                        ],
                        [
                            -82.7510659998152,
                            35.0814810002486
                        ],
                        [
                            -82.7521830000738,
                            35.0797650000294
                        ],
                        [
                            -82.7512650000068,
                            35.0734630000607
                        ],
                        [
                            -82.7566489996616,
                            35.0672870002033
                        ],
                        [
                            -82.7645520002389,
                            35.0681699997577
                        ],
                        [
                            -82.7708549997855,
                            35.067414999983
                        ],
                        [
                            -82.7750710000264,
                            35.0646620002163
                        ],
                        [
                            -82.7820360004452,
                            35.0650189996331
                        ],
                        [
                            -82.7793940003974,
                            35.0703119998896
                        ],
                        [
                            -82.7804730001228,
                            35.0724029999886
                        ],
                        [
                            -82.7775840002028,
                            35.0754309999894
                        ],
                        [
                            -82.7762700003007,
                            35.0808340002194
                        ],
                        [
                            -82.7789469996103,
                            35.083973999807
                        ],
                        [
                            -82.7848380003266,
                            35.0856990000857
                        ],
                        [
                            -83.1083050000712,
                            35.0006630001397
                        ],
                        [
                            -83.2968500001465,
                            34.9962750002584
                        ],
                        [
                            -83.6200509997524,
                            34.992031999961
                        ],
                        [
                            -83.6199500003683,
                            34.9866690003375
                        ],
                        [
                            -84.3218210001656,
                            34.9884079997862
                        ],
                        [
                            -84.2902139996761,
                            35.2254319997533
                        ],
                        [
                            -84.2881030000809,
                            35.2243459999896
                        ],
                        [
                            -84.2832969998361,
                            35.2264590002573
                        ],
                        [
                            -84.275908999818,
                            35.2342039998267
                        ],
                        [
                            -84.2604089998835,
                            35.2418329996614
                        ],
                        [
                            -84.2583710000833,
                            35.2456629997203
                        ],
                        [
                            -84.2508319995541,
                            35.2507199999477
                        ],
                        [
                            -84.2425210003445,
                            35.2534869999902
                        ],
                        [
                            -84.232503999799,
                            35.2642450001985
                        ],
                        [
                            -84.2241220004442,
                            35.2689849998104
                        ],
                        [
                            -84.2113950000788,
                            35.2656210001094
                        ],
                        [
                            -84.2029819998636,
                            35.2552910002899
                        ],
                        [
                            -84.1995199996582,
                            35.2438540002552
                        ],
                        [
                            -84.1933750002953,
                            35.2447790002487
                        ],
                        [
                            -84.188957000388,
                            35.2402170002096
                        ],
                        [
                            -84.1835129995605,
                            35.2399959999762
                        ],
                        [
                            -84.1785389999846,
                            35.2406380000367
                        ],
                        [
                            -84.1700260002102,
                            35.246459999728
                        ],
                        [
                            -84.1614609996591,
                            35.2435229998624
                        ],
                        [
                            -84.1592289996901,
                            35.2457199999248
                        ],
                        [
                            -84.1477609998735,
                            35.2468639997527
                        ],
                        [
                            -84.1394000004351,
                            35.2460969999338
                        ],
                        [
                            -84.133619000222,
                            35.2423769998669
                        ],
                        [
                            -84.1271109997377,
                            35.2407700003655
                        ],
                        [
                            -84.1257209996676,
                            35.2421209997932
                        ],
                        [
                            -84.1267190001005,
                            35.2463049996691
                        ],
                        [
                            -84.1252200002505,
                            35.2498710000527
                        ],
                        [
                            -84.119926999934,
                            35.2520489999703
                        ],
                        [
                            -84.1135540000672,
                            35.2489810000393
                        ],
                        [
                            -84.1064770003937,
                            35.2514910003042
                        ],
                        [
                            -84.1025630002766,
                            35.2483650001068
                        ],
                        [
                            -84.0975040003118,
                            35.2475979998145
                        ],
                        [
                            -84.0923180001255,
                            35.2493740002672
                        ],
                        [
                            -84.0825299999088,
                            35.2562880000158
                        ],
                        [
                            -84.0808519999587,
                            35.2612520001685
                        ],
                        [
                            -84.0525359999704,
                            35.2701890001571
                        ],
                        [
                            -84.0510079998081,
                            35.2733379997029
                        ],
                        [
                            -84.0468870000018,
                            35.2742499999367
                        ],
                        [
                            -84.0408170000836,
                            35.2796000003372
                        ],
                        [
                            -84.0362390003428,
                            35.2884180003024
                        ],
                        [
                            -84.0233780000831,
                            35.2963079999451
                        ],
                        [
                            -84.0214189999256,
                            35.3014009999068
                        ],
                        [
                            -84.0262979999653,
                            35.3063209997903
                        ],
                        [
                            -84.0269690001601,
                            35.3096719998487
                        ],
                        [
                            -84.0341150003289,
                            35.3111830001383
                        ],
                        [
                            -84.0358839996173,
                            35.3132149999869
                        ],
                        [
                            -84.0344919998974,
                            35.3225570001484
                        ],
                        [
                            -84.0292570002055,
                            35.3330999999924
                        ],
                        [
                            -84.0314740001059,
                            35.3365290003663
                        ],
                        [
                            -84.0361949999619,
                            35.3376580001166
                        ],
                        [
                            -84.0340009996277,
                            35.3432759998003
                        ],
                        [
                            -84.0388810003906,
                            35.3482080001003
                        ],
                        [
                            -84.0380829999743,
                            35.3511799997992
                        ],
                        [
                            -84.0233339997022,
                            35.3543119999196
                        ],
                        [
                            -84.0150569999294,
                            35.3649809999023
                        ],
                        [
                            -84.0073939995517,
                            35.3716489998225
                        ],
                        [
                            -84.0087419997889,
                            35.3728179999505
                        ],
                        [
                            -84.008300999748,
                            35.3787660001575
                        ],
                        [
                            -84.0113960004307,
                            35.3846010000797
                        ],
                        [
                            -84.0082220001053,
                            35.3905739996774
                        ],
                        [
                            -84.0125129997909,
                            35.3921919999597
                        ],
                        [
                            -84.0138669998759,
                            35.3978849997773
                        ],
                        [
                            -84.0187119995805,
                            35.399995999958
                        ],
                        [
                            -84.0234249999388,
                            35.4115810002634
                        ],
                        [
                            -84.0144409999863,
                            35.4123489998023
                        ],
                        [
                            -84.001280000151,
                            35.423390999799
                        ],
                        [
                            -83.9979550002128,
                            35.4307319999633
                        ],
                        [
                            -83.9993899995904,
                            35.4312409999778
                        ],
                        [
                            -83.99959299998,
                            35.43389300011
                        ],
                        [
                            -83.9947120002905,
                            35.435180000266
                        ],
                        [
                            -83.9920040001204,
                            35.4405229999898
                        ],
                        [
                            -83.9889190003818,
                            35.4390870000806
                        ],
                        [
                            -83.9879829998732,
                            35.4409209998836
                        ],
                        [
                            -83.9844329998042,
                            35.4401499997799
                        ],
                        [
                            -83.9797570001541,
                            35.4482600002535
                        ],
                        [
                            -83.9760940001071,
                            35.4465370002389
                        ],
                        [
                            -83.9751280003596,
                            35.4480110001752
                        ],
                        [
                            -83.9726550003662,
                            35.4472979996983
                        ],
                        [
                            -83.9732889998527,
                            35.4527619999736
                        ],
                        [
                            -83.9710960002417,
                            35.4530070001868
                        ],
                        [
                            -83.9718550003,
                            35.4548489998151
                        ],
                        [
                            -83.9646239997424,
                            35.4541119999876
                        ],
                        [
                            -83.9656930003204,
                            35.4562379997254
                        ],
                        [
                            -83.9627809999358,
                            35.4566159999074
                        ],
                        [
                            -83.9610800004195,
                            35.46413799992
                        ],
                        [
                            -83.9557720000344,
                            35.4636450001392
                        ],
                        [
                            -83.9529640003052,
                            35.4605429997792
                        ],
                        [
                            -83.9496490004128,
                            35.4609410001797
                        ],
                        [
                            -83.9428789999876,
                            35.4643980003
                        ],
                        [
                            -83.9419700001415,
                            35.4673119996491
                        ],
                        [
                            -83.9367229998558,
                            35.4715530000516
                        ],
                        [
                            -83.9241410001537,
                            35.4743110000888
                        ],
                        [
                            -83.9166209998911,
                            35.4736490001581
                        ],
                        [
                            -83.9118749999207,
                            35.4760680003249
                        ],
                        [
                            -83.9057809997132,
                            35.488689000283
                        ],
                        [
                            -83.9003650002713,
                            35.4930310001029
                        ],
                        [
                            -83.9014659997381,
                            35.4965520001095
                        ],
                        [
                            -83.8899269997764,
                            35.5046630002798
                        ],
                        [
                            -83.8802149997277,
                            35.5188420002825
                        ],
                        [
                            -83.8732359999635,
                            35.5208559999155
                        ],
                        [
                            -83.8680690000437,
                            35.5200080003031
                        ],
                        [
                            -83.8571810001853,
                            35.5215730003592
                        ],
                        [
                            -83.8491439997137,
                            35.5186319996514
                        ],
                        [
                            -83.8321430002794,
                            35.5245739999597
                        ],
                        [
                            -83.8204670000504,
                            35.5256519999357
                        ],
                        [
                            -83.8098930000113,
                            35.5329960000851
                        ],
                        [
                            -83.8080379996108,
                            35.5370930001736
                        ],
                        [
                            -83.8028039997439,
                            35.5414869998627
                        ],
                        [
                            -83.7987850000448,
                            35.5416410001327
                        ],
                        [
                            -83.7946459997968,
                            35.5456490001452
                        ],
                        [
                            -83.7801819999301,
                            35.550634000228
                        ],
                        [
                            -83.7726659998654,
                            35.5574319996751
                        ],
                        [
                            -83.7718890002638,
                            35.5613900001209
                        ],
                        [
                            -83.7595510000608,
                            35.5625729997087
                        ],
                        [
                            -83.7549919996883,
                            35.5646149997196
                        ],
                        [
                            -83.7471579996063,
                            35.5603079999893
                        ],
                        [
                            -83.7361369996785,
                            35.5655209998116
                        ],
                        [
                            -83.7227719996286,
                            35.5622960001355
                        ],
                        [
                            -83.7140069999592,
                            35.5641829997131
                        ],
                        [
                            -83.712474999599,
                            35.5664020002499
                        ],
                        [
                            -83.7069899996618,
                            35.5685509996751
                        ],
                        [
                            -83.701241999959,
                            35.5674760000491
                        ],
                        [
                            -83.6956099997088,
                            35.5703220001567
                        ],
                        [
                            -83.6876969999841,
                            35.568197000211
                        ],
                        [
                            -83.6797569995969,
                            35.5703509998401
                        ],
                        [
                            -83.6638049997508,
                            35.5697560000524
                        ],
                        [
                            -83.6610020000445,
                            35.5683389998825
                        ],
                        [
                            -83.6560140002249,
                            35.5700130003087
                        ],
                        [
                            -83.6451520003057,
                            35.5659129996625
                        ],
                        [
                            -83.6401300001509,
                            35.566165000172
                        ],
                        [
                            -83.635328999929,
                            35.5680959998129
                        ],
                        [
                            -83.633861999866,
                            35.5706719999426
                        ],
                        [
                            -83.6293130004375,
                            35.5681710000483
                        ],
                        [
                            -83.621307999753,
                            35.5726969998469
                        ],
                        [
                            -83.618571000169,
                            35.5722540000082
                        ],
                        [
                            -83.6083360000636,
                            35.5800150002357
                        ],
                        [
                            -83.6017740000509,
                            35.578919000269
                        ],
                        [
                            -83.5983019997996,
                            35.5749999996951
                        ],
                        [
                            -83.5961169996863,
                            35.5749990001673
                        ],
                        [
                            -83.5843740004084,
                            35.5626980001524
                        ],
                        [
                            -83.5726630000193,
                            35.5653810002875
                        ],
                        [
                            -83.5665250003291,
                            35.5642940001959
                        ],
                        [
                            -83.5649789997251,
                            35.56609099989
                        ],
                        [
                            -83.551042999938,
                            35.5634539996737
                        ],
                        [
                            -83.540716999596,
                            35.5658650000671
                        ],
                        [
                            -83.5319839997137,
                            35.5644850003178
                        ],
                        [
                            -83.5212950000468,
                            35.5655920002232
                        ],
                        [
                            -83.5165640001451,
                            35.5624380000337
                        ],
                        [
                            -83.4984029998963,
                            35.5629319997572
                        ],
                        [
                            -83.4933170001673,
                            35.5668300000051
                        ],
                        [
                            -83.4853569996887,
                            35.568471000288
                        ],
                        [
                            -83.4786309996444,
                            35.579190000318
                        ],
                        [
                            -83.4790719996854,
                            35.5832179999604
                        ],
                        [
                            -83.4728539996293,
                            35.5862520001347
                        ],
                        [
                            -83.4712960002281,
                            35.59026800024
                        ],
                        [
                            -83.4626920002171,
                            35.5925700002976
                        ],
                        [
                            -83.4614519999349,
                            35.5952860001324
                        ],
                        [
                            -83.4565889997887,
                            35.597194000133
                        ],
                        [
                            -83.4550110002961,
                            35.6020220003578
                        ],
                        [
                            -83.4516479998248,
                            35.603527999945
                        ],
                        [
                            -83.4456759998159,
                            35.6117469997808
                        ],
                        [
                            -83.4323030002685,
                            35.6099900000301
                        ],
                        [
                            -83.4220380000259,
                            35.6110490002305
                        ],
                        [
                            -83.4201349999448,
                            35.6135169996737
                        ],
                        [
                            -83.4150340001473,
                            35.6145040000862
                        ],
                        [
                            -83.4055329999859,
                            35.6205119998453
                        ],
                        [
                            -83.3961470003504,
                            35.6228660002175
                        ],
                        [
                            -83.3916709998185,
                            35.6265640000729
                        ],
                        [
                            -83.388725999822,
                            35.6336220002843
                        ],
                        [
                            -83.3780870003839,
                            35.6345620002795
                        ],
                        [
                            -83.3718280003199,
                            35.6391629999912
                        ],
                        [
                            -83.367797000027,
                            35.6378410000402
                        ],
                        [
                            -83.3660560003276,
                            35.6410279999606
                        ],
                        [
                            -83.3582730002993,
                            35.6469979998269
                        ],
                        [
                            -83.3559330003752,
                            35.6507569997494
                        ],
                        [
                            -83.3559690003603,
                            35.6543050003247
                        ],
                        [
                            -83.3508159997859,
                            35.6605419999101
                        ],
                        [
                            -83.3450069999836,
                            35.6612090000203
                        ],
                        [
                            -83.3410639995542,
                            35.664589000123
                        ],
                        [
                            -83.3387210001554,
                            35.6636339997535
                        ],
                        [
                            -83.3346560004256,
                            35.6656619997648
                        ],
                        [
                            -83.3216160000658,
                            35.6630539999452
                        ],
                        [
                            -83.3120760004446,
                            35.6546120002996
                        ],
                        [
                            -83.2982440004145,
                            35.6572550000173
                        ],
                        [
                            -83.2935450002999,
                            35.6618790001046
                        ],
                        [
                            -83.2903049998523,
                            35.6734980003446
                        ],
                        [
                            -83.2839889998868,
                            35.6744629998392
                        ],
                        [
                            -83.2813419998161,
                            35.6776990003546
                        ],
                        [
                            -83.274466999809,
                            35.6790759998753
                        ],
                        [
                            -83.2711660001603,
                            35.6831679999683
                        ],
                        [
                            -83.2706799999135,
                            35.686826999664
                        ],
                        [
                            -83.2592909997396,
                            35.6903709999157
                        ],
                        [
                            -83.2576119999646,
                            35.6939690003208
                        ],
                        [
                            -83.2543249996613,
                            35.6954449997346
                        ],
                        [
                            -83.2574300003897,
                            35.7051200000819
                        ],
                        [
                            -83.2515679999857,
                            35.7089739996608
                        ],
                        [
                            -83.2553559997061,
                            35.7152439998862
                        ],
                        [
                            -83.2515069998864,
                            35.7197519998367
                        ],
                        [
                            -83.2432490003802,
                            35.7225200002819
                        ],
                        [
                            -83.2408270004404,
                            35.7268560001545
                        ],
                        [
                            -83.2180819997046,
                            35.7259580002841
                        ],
                        [
                            -83.2141960000749,
                            35.7241130000679
                        ],
                        [
                            -83.207382999992,
                            35.7261910000828
                        ],
                        [
                            -83.1977420000885,
                            35.7252529999631
                        ],
                        [
                            -83.1859060000259,
                            35.7300970002282
                        ],
                        [
                            -83.1777919995614,
                            35.7443829996447
                        ],
                        [
                            -83.1702849997176,
                            35.7460300000827
                        ],
                        [
                            -83.1683230000853,
                            35.750757999955
                        ],
                        [
                            -83.1647899997347,
                            35.7539880003643
                        ],
                        [
                            -83.164971000383,
                            35.7596800000026
                        ],
                        [
                            -83.1595850001801,
                            35.7646509997365
                        ],
                        [
                            -83.1364710002717,
                            35.765139000327
                        ],
                        [
                            -83.1274639998547,
                            35.7680859998497
                        ],
                        [
                            -83.1206080001141,
                            35.7664309999274
                        ],
                        [
                            -83.1182389998777,
                            35.7697839999025
                        ],
                        [
                            -83.111204000037,
                            35.7706239998515
                        ],
                        [
                            -83.1046029996662,
                            35.7743769997981
                        ],
                        [
                            -83.096754000414,
                            35.7763509996472
                        ],
                        [
                            -83.0945800001713,
                            35.7796000002225
                        ],
                        [
                            -83.0893019999234,
                            35.7814910001452
                        ],
                        [
                            -83.0852649997827,
                            35.785843999866
                        ],
                        [
                            -83.0785029997534,
                            35.7896749999045
                        ],
                        [
                            -83.0742010001971,
                            35.7901369998734
                        ],
                        [
                            -83.0637469998086,
                            35.7869139996947
                        ],
                        [
                            -83.0528210003153,
                            35.7897049998893
                        ],
                        [
                            -83.043062000411,
                            35.7854070000851
                        ],
                        [
                            -83.0360419997406,
                            35.7875039998409
                        ],
                        [
                            -83.0238860000108,
                            35.7834199998565
                        ],
                        [
                            -83.0203479996374,
                            35.7804019996848
                        ],
                        [
                            -83.0160250001647,
                            35.7820490000816
                        ],
                        [
                            -83.0109029995524,
                            35.7787449997485
                        ],
                        [
                            -83.005988000426,
                            35.778496000178
                        ],
                        [
                            -83.0012919997861,
                            35.7737790002321
                        ],
                        [
                            -82.9959890003222,
                            35.7730819996474
                        ],
                        [
                            -82.9839249997556,
                            35.7780929996919
                        ],
                        [
                            -82.9696990004386,
                            35.7896350001499
                        ],
                        [
                            -82.9630250002729,
                            35.7899939996495
                        ],
                        [
                            -82.9617540000285,
                            35.7909590000575
                        ],
                        [
                            -82.9628999997011,
                            35.7949240000207
                        ],
                        [
                            -82.9618109999299,
                            35.8003659996813
                        ],
                        [
                            -82.9517640001455,
                            35.8166079998539
                        ],
                        [
                            -82.9481959996348,
                            35.8177840000278
                        ],
                        [
                            -82.9458470003883,
                            35.8243780002596
                        ],
                        [
                            -82.9360349998822,
                            35.8255169998872
                        ],
                        [
                            -82.9333429996056,
                            35.8305289999663
                        ],
                        [
                            -82.9340699998769,
                            35.8366619996602
                        ],
                        [
                            -82.9202110000826,
                            35.8393849997029
                        ],
                        [
                            -82.9163429999963,
                            35.8416629996654
                        ],
                        [
                            -82.921033999715,
                            35.8509450001616
                        ],
                        [
                            -82.918212999567,
                            35.8592720000034
                        ],
                        [
                            -82.9214320000983,
                            35.863104999974
                        ],
                        [
                            -82.9208310002237,
                            35.8681779996863
                        ],
                        [
                            -82.9107740003936,
                            35.8685729999146
                        ],
                        [
                            -82.9065549997796,
                            35.8701800003459
                        ],
                        [
                            -82.9008909997424,
                            35.8729089998361
                        ],
                        [
                            -82.896081000198,
                            35.879543999665
                        ],
                        [
                            -82.8982860004028,
                            35.8812580001421
                        ],
                        [
                            -82.9036270003999,
                            35.8804579997705
                        ],
                        [
                            -82.9068250001164,
                            35.8839080003383
                        ],
                        [
                            -82.9010430000783,
                            35.8927909998834
                        ],
                        [
                            -82.9081089998812,
                            35.9042040000843
                        ],
                        [
                            -82.9069799999271,
                            35.9074290002239
                        ],
                        [
                            -82.9080319998883,
                            35.911313999911
                        ],
                        [
                            -82.9097340001279,
                            35.9109680001941
                        ],
                        [
                            -82.9143870002118,
                            35.9151500000882
                        ],
                        [
                            -82.9116239997902,
                            35.9168080002293
                        ],
                        [
                            -82.9135350002671,
                            35.9242609998746
                        ],
                        [
                            -82.9192410001371,
                            35.9258920001609
                        ],
                        [
                            -82.919935999723,
                            35.9287980002758
                        ],
                        [
                            -82.9121950004258,
                            35.9295940002334
                        ],
                        [
                            -82.9106210002327,
                            35.9275050002667
                        ],
                        [
                            -82.9059470002324,
                            35.9261969998379
                        ],
                        [
                            -82.90181999968,
                            35.9269600001715
                        ],
                        [
                            -82.8988859995541,
                            35.9298759998567
                        ],
                        [
                            -82.901783999695,
                            35.9378519998115
                        ],
                        [
                            -82.8981819997474,
                            35.9452300001963
                        ],
                        [
                            -82.8926619996502,
                            35.9450029997698
                        ],
                        [
                            -82.8889860000828,
                            35.9483439998283
                        ],
                        [
                            -82.8782229998978,
                            35.950116999805
                        ],
                        [
                            -82.8742929998872,
                            35.9528299999448
                        ],
                        [
                            -82.86064699963,
                            35.947479000201
                        ],
                        [
                            -82.8517329999977,
                            35.9493439997396
                        ],
                        [
                            -82.8460370001734,
                            35.9461690001553
                        ],
                        [
                            -82.844545999821,
                            35.9419869998562
                        ],
                        [
                            -82.8411160003009,
                            35.9417409998771
                        ],
                        [
                            -82.8386380002846,
                            35.9372709998468
                        ],
                        [
                            -82.8334720001897,
                            35.9355980002637
                        ],
                        [
                            -82.8324059999847,
                            35.932813000309
                        ],
                        [
                            -82.8280979996823,
                            35.932412999799
                        ],
                        [
                            -82.8219480002966,
                            35.9218300002738
                        ],
                        [
                            -82.8150859998099,
                            35.9255469996926
                        ],
                        [
                            -82.8050850000563,
                            35.9271369998976
                        ],
                        [
                            -82.802674999812,
                            35.9295709998226
                        ],
                        [
                            -82.8061679999797,
                            35.9377580003196
                        ],
                        [
                            -82.7997270003408,
                            35.9446609997477
                        ],
                        [
                            -82.7945450003524,
                            35.9469329996773
                        ],
                        [
                            -82.7913750002249,
                            35.9509969996709
                        ],
                        [
                            -82.787559999842,
                            35.9520129999118
                        ],
                        [
                            -82.785043000366,
                            35.9624830000989
                        ],
                        [
                            -82.7780379997642,
                            35.9667729997683
                        ],
                        [
                            -82.775086000095,
                            35.9718289998636
                        ],
                        [
                            -82.776504999579,
                            35.9740579998325
                        ],
                        [
                            -82.7815870000083,
                            35.9742970000509
                        ],
                        [
                            -82.7856900002713,
                            35.9778409997531
                        ],
                        [
                            -82.7844759999283,
                            35.9818839999032
                        ],
                        [
                            -82.7852759999945,
                            35.9885390000532
                        ],
                        [
                            -82.779213999574,
                            35.9922889999817
                        ],
                        [
                            -82.7757580001146,
                            36.0010510000934
                        ],
                        [
                            -82.7720730003264,
                            36.0007040000656
                        ],
                        [
                            -82.7625699996168,
                            36.0048439996637
                        ],
                        [
                            -82.7545509995869,
                            36.0043459998466
                        ],
                        [
                            -82.7525680000382,
                            36.0062019997063
                        ],
                        [
                            -82.7502210004415,
                            36.0059809997678
                        ],
                        [
                            -82.7507870001559,
                            36.0088709997965
                        ],
                        [
                            -82.740284000262,
                            36.0121320002407
                        ],
                        [
                            -82.7391169997747,
                            36.0149789997372
                        ],
                        [
                            -82.7254639998447,
                            36.0181610002616
                        ],
                        [
                            -82.7164699998465,
                            36.0224100001534
                        ],
                        [
                            -82.7151409998758,
                            36.028641999946
                        ],
                        [
                            -82.7086399999625,
                            36.0297209998395
                        ],
                        [
                            -82.7017959999174,
                            36.032649999968
                        ],
                        [
                            -82.7012279996549,
                            36.0343590002989
                        ],
                        [
                            -82.6981899997719,
                            36.0342000001022
                        ],
                        [
                            -82.6972240000244,
                            36.0357830001682
                        ],
                        [
                            -82.689585999761,
                            36.038157000048
                        ],
                        [
                            -82.6859870001865,
                            36.0414210001059
                        ],
                        [
                            -82.6840730002348,
                            36.045794999932
                        ],
                        [
                            -82.6748509997326,
                            36.0488329998641
                        ],
                        [
                            -82.6705619996968,
                            36.0529760002051
                        ],
                        [
                            -82.6663460003542,
                            36.0530359997409
                        ],
                        [
                            -82.6592679999575,
                            36.0571170003216
                        ],
                        [
                            -82.6515329996097,
                            36.0573680001923
                        ],
                        [
                            -82.6372300002998,
                            36.0658200002378
                        ],
                        [
                            -82.632608000178,
                            36.0659109999854
                        ],
                        [
                            -82.632054999984,
                            36.0640209997607
                        ],
                        [
                            -82.6179540003405,
                            36.055909999759
                        ],
                        [
                            -82.6170420001213,
                            36.0537679999817
                        ],
                        [
                            -82.6183839996125,
                            36.0469160003379
                        ],
                        [
                            -82.6086590000433,
                            36.0444109999574
                        ],
                        [
                            -82.6018760000977,
                            36.0386530000219
                        ],
                        [
                            -82.6016590003627,
                            36.0360540003053
                        ],
                        [
                            -82.5914469998235,
                            36.0355679999027
                        ],
                        [
                            -82.5909949999119,
                            36.0315739997978
                        ],
                        [
                            -82.6029230000361,
                            36.0233709999938
                        ],
                        [
                            -82.6028390003705,
                            36.0177560000545
                        ],
                        [
                            -82.6077610000679,
                            36.015753999796
                        ],
                        [
                            -82.6094449998658,
                            36.0110379997546
                        ],
                        [
                            -82.6068759996129,
                            36.0104300001368
                        ],
                        [
                            -82.6138250001382,
                            36.004917000324
                        ],
                        [
                            -82.6149280001531,
                            36.0020459997009
                        ],
                        [
                            -82.6128970000255,
                            35.9936060002316
                        ],
                        [
                            -82.6071280004063,
                            35.9923599996922
                        ],
                        [
                            -82.6044129996652,
                            35.9874630001985
                        ],
                        [
                            -82.6109389996929,
                            35.9743800003559
                        ],
                        [
                            -82.6107029996914,
                            35.9672029998485
                        ],
                        [
                            -82.600106000086,
                            35.9645940003478
                        ],
                        [
                            -82.5919819995758,
                            35.9662889998594
                        ],
                        [
                            -82.5804619998807,
                            35.9652479996434
                        ],
                        [
                            -82.5777980000916,
                            35.9643340002904
                        ],
                        [
                            -82.5761689996469,
                            35.958719999857
                        ],
                        [
                            -82.5579880002049,
                            35.9538729999751
                        ],
                        [
                            -82.5496860003178,
                            35.964049000069
                        ],
                        [
                            -82.5306930002157,
                            35.9722259996839
                        ],
                        [
                            -82.5250309998283,
                            35.9710550003443
                        ],
                        [
                            -82.5221209999919,
                            35.9740120001698
                        ],
                        [
                            -82.5170770000957,
                            35.9758250000885
                        ],
                        [
                            -82.5128490001592,
                            35.9753009998189
                        ],
                        [
                            -82.510298000348,
                            35.9772920003056
                        ],
                        [
                            -82.5051840001317,
                            35.9775469998288
                        ],
                        [
                            -82.5033239997084,
                            35.9821580000063
                        ],
                        [
                            -82.4999639996101,
                            35.982440999809
                        ],
                        [
                            -82.4841699999478,
                            35.9933009998416
                        ],
                        [
                            -82.4815909996491,
                            35.9980290001966
                        ],
                        [
                            -82.4759400000307,
                            35.9982209996458
                        ],
                        [
                            -82.4704159997355,
                            36.0028870002634
                        ],
                        [
                            -82.4605830002114,
                            36.0077240000905
                        ],
                        [
                            -82.4167379999763,
                            36.0728270002645
                        ]
                    ]
                ]
            },
            "lsadc": "00",
            "intptlat": 35.53971,
            "name": "North Carolina",
            "intptlon": -79.1308636,
            "geo_point_2d": [
                35.5259774335,
                -79.1308481139
            ],
            "geoid": "37",
            "mtfcc": "G4000",
            "region": 3
        },
        "geometry": {
            "type": "Point",
            "coordinates": [
                -79.1308481139,
                35.5259774335
            ]
        },
        "record_timestamp": "2018-03-09T15:51:54.081-08:00"
    }
]


export default stateBoundaries