/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App'
// redux
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";

import menuCollapsed from "./components/redux/store/menuCollapsed";
import counter from "./components/redux/store/counter";
import updateUserProfile from "./components/redux/store/updateUserProfile";
import getCurrentUser from './components/redux/store/getCurrentUser'
import badPickUpGoogleSheet from './components/redux/store/badPickUpGoogleSheet'
import badPickupReport from './components/redux/store/badPickupReport'
import locationsApi from './components/redux/store/locationsApi'
import locationSelectedGraphBar from './components/redux/store/locationSelectedGraphBar';
import selectedPickupReport from './components/redux/store/selectedPickupReport'

import AuthStateApp from './AuthStateApp'

import Amplify, { Auth } from 'aws-amplify';

import awsconfig from './aws-exports'

// Amplify.configure(awsconfig_dev);
// >>New - Configuring Auth Module.

Auth.configure(awsconfig);

const combinedReducers = combineReducers({
  counter,
  menuCollapsed,
  updateUserProfile,
  getCurrentUser,
  badPickUpGoogleSheet,
  badPickupReport,
  locationsApi,
  locationSelectedGraphBar,
  selectedPickupReport
});

const store = createStore(
  combinedReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
