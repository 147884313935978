/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import mapsStying from './mapsStying'
import MapsDisplay from './mapDisplay';

class GoogleMaps extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const { selectedLocation } = this.props

        return (
            <div >
                <div>
                    <div>
                        <MapsDisplay selectedLocation={selectedLocation} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        badPickUpGoogleSheet: state.badPickUpGoogleSheet,
        badPickupReport: state.badPickupReport,
        locationsApi: state.locationsApi,
        selectedLocation: state.locationSelectedGraphBar
    };
};

export default connect(mapStateToProps)(GoogleMaps);

