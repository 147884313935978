/* eslint-disable */
import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import { PieChart, Pie, Cell } from 'recharts';

const RADIAN = Math.PI / 180;

class StaticPieChartContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {}
    }
    // https://medium.com/@dynamicsa420/react-recharts-for-beginners-24b0c8bfb676

    renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, index,
    }) => {

        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    render() {
        const { pickupIssues } = this.props

        let dataArr = []

        for (let key in pickupIssues) {
            if (key !== 'total' && key !== 'avg' && key !== 'locationReportTracker') {
                dataArr.push({
                    name: key,
                    value: parseFloat(pickupIssues[key])
                })
            }
        }

        const COLORS = ['rgba(252, 200, 22, 0.8)', "rgba(252, 200, 22, 0.6)", "#FCC816"];

        return (
            <div>
                <PieChart width={400} height={400}>
                    <Pie
                        data={dataArr}
                        cx={125}
                        cy={150}
                        labelLine={false}
                        label={this.renderCustomizedLabel}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {
                            dataArr.map((entry, index) =>
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                />)
                        }
                    </Pie>
                </PieChart>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedPickupReport: state.selectedPickupReport
    };
};

export default (connect(mapStateToProps)(StaticPieChartContainer));


// interactive pie
// https://codesandbox.io/s/so-answer-61684688-0q4un?file=/src/App.js:0-1755

