import { getCoordinates } from '../../helpers/getCoordinates/getCoordinates'

const initialState = {
  state: {
    name: '',
    lat: '',
    lng: ''
  },
  city: ''
}

const locationSelectedGraphBar = (state = initialState, action) => {
  const newState = { ...state }

  if (action.type === 'STATE_SELECTED') {
    if (action.data.length > 0) {
      let statesCoordinates = getCoordinates(action.data)

      if (statesCoordinates) {
        if (statesCoordinates.latitude && statesCoordinates.longitude) {
          newState.state = {
            name: action.data,
            lat: statesCoordinates.latitude,
            lng: statesCoordinates.longitude
          }
        }
      }

    }

    else {

      newState.state = {
        name: '',
        lat: '',
        lng: ''
      }

    }
  }

  if (action.type === "CITY_SELECTED") {
    newState.city = action.data
  }

  return newState
};


export default locationSelectedGraphBar
