const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: 150,
    justifyContent: "center",
    alignItems: "center",
  },
  home_title_font: {
    fontSize: "50px",
    color: "#2A9B8D",
    font: "Quicksand sans-serif",
    fontWeight: "bold",
  },
  left_side_welcome_page: {
    // position: "absolute",
    width: "720px",
    height: "1024px",
    left: "0px",
    top: "0px",
    background: "rgba(42, 155, 141, 0.6)",
  },
  center_auth_form: {
    paddingTop: "15%",
    height: "100vh",
  },
  input: {
    height: 45,
    marginTop: 8,
    marginBottom: 8,
    width: 400,
    maxWidth: 400,
    padding: "0px 8px",
    fontSize: 16,
    backgroundColor: "white",
    border: "none",
    borderBottom: "2px solid rgba(208, 208, 208, 1)",
  },
  button: {
    width: "220px",
    height: "35px",
    left: "978px",
    top: "510px",
    color: "white",
    marginBottom: "8px",
    background: "#2A9B8D",
    borderRadius: "20px",
    border: "#2A9B8D",
    fontWeight: "bold",
  },
  button_google: {
    width: "220px",
    height: "35px",
    color: "#878787",
    // marginBottom: "8px",
    background: "white",
    borderRadius: "20px",
    borderColor: "#D0D0D0",
  },
  google_icon: {
    width: "20px",
    height: "19.74px",
    left: "1001px",
    top: "559px",
    // paddingRight: '1px'
  },
  recyclops_menu_logo: {
    maxWidth: "90%",
    height: "auto",
    paddingLeft: '9%'
  },
  footer: {
    fontWeight: "600",
    padding: "0px 25px",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.6)",
  },
  anchor: {
    color: "#2A9B8D",
    cursor: "pointer",
    fontWeight: "Bold",
  },
  signin_link: {
    color: "#878787",
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    transform: "translate(1%, -1240%)",
    fontWeight: "Normal",
  },
  signup_link: {
    color: "#878787",
    // // position: "absolute",
    // top: "50%",
    // left: "50%",
    transform: "translate(0%, -1610%)",
    fontWeight: "Normal",
  },
  centereds: {
    height: "100vh" /* Magic here */,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default styles
