/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import GraphLayout from '../graph/graphLayout'
import badPickupReportStying from './badPickupReportStying'
import LocationLayout from '../locationReport/locationLayout'

class BadPickupReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <div
                    className="content-wrapper"
                    style={badPickupReportStying.page_background_color}
                >
                    <section className="content">
                        <div className="container-fluid">
                            <br />
                            <div className="row" >
                                <section className="col-lg-7 connectedSortable">
                                    <div className="card" style={badPickupReportStying.page_box_layout}>
                                        <div className="card-body" >
                                            <div style={badPickupReportStying.page_title_Font}>
                                                <GraphLayout />
                                                <br />
                                                <div style={badPickupReportStying.pageDivider}></div>
                                                <br />
                                                <LocationLayout />
                                            </div>

                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </section>

                </div>
                <br />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        getCurrentUser: state.getCurrentUser.userData,
        getLocations: state.getLocations,
        badPickUpGoogleSheet: state.badPickUpGoogleSheet,
        badPickupReport: state.badPickupReport,
        locationsApi: state.locationsApi
    };
};

export default connect(mapStateToProps)(BadPickupReport);
