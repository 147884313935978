/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import locationsStying from './locationStyling'

class StateStatusBar extends React.Component {
    constructor() {
        super();
        this.state = {
            WeeksPickupIssues: [],
            todaysPickupIssues: [],
            currentPage: 1,
            todosPerPage: 9,

            pageLoading: true,

            hoveredState: '',

        };
    }

    async componentDidMount() {
        const badPickupReport = await this.props.badPickupReport
        const { pageLoading } = this.state

        if (badPickupReport) {

            let weeksPickupIssues = badPickupReport.WeeksPickupIssues.weeksLocationReportTracker
            let daysPickupIssues = badPickupReport.todaysPickupIssues.todaysLocationReportTracker

            let weeksArr = [...this.state.WeeksPickupIssues]
            let daysArr = [...this.state.todaysPickupIssues]

            for (let key in weeksPickupIssues) {
                weeksArr.push(weeksPickupIssues[key])
            }

            for (let key in daysPickupIssues) {
                daysArr.push(daysPickupIssues[key])
            }

            this.setState({
                WeeksPickupIssues: weeksArr,
                todaysPickupIssues: daysArr,
                pageLoading: !pageLoading
            })
        }

    }

    stateSelectHandle = (stateSelected) => {
        this.props.stateSelected(stateSelected)
    }

    onMouseEnterHandle = (stateHovered) => {
        this.setState({
            hoveredState: stateHovered
        })
    }

    onMouseLeaveHandle = () => {
        this.setState({
            hoveredState: ''
        })
    }

    paginationHandleClick = (event) => {
        this.setState({
            currentPage: Number(event.target.id)
        });
    }

    render() {
        const { toggleStateOrCity, dailyReportSelected } = this.props
        const { hoveredState, todaysPickupIssues, WeeksPickupIssues, currentPage, todosPerPage, pageLoading } = this.state

        // Logic for displaying current PickupIssues
        const indexOfLastStatus = currentPage * todosPerPage;
        const indexOfFirstStatus = indexOfLastStatus - todosPerPage;

        const weeksStatus = WeeksPickupIssues.slice(indexOfFirstStatus, indexOfLastStatus);
        const todaysStatus = todaysPickupIssues.slice(indexOfFirstStatus, indexOfLastStatus);

        const renderTodos = weeksStatus.map((data, i) => {
            return <div key={i} onMouseLeave={() => this.onMouseLeaveHandle()} onMouseEnter={() => this.onMouseEnterHandle(data.name)}>
                <div className="row" style={locationsStying.containerPadding} onClick={toggleStateOrCity} onClick={() => this.stateSelectHandle(data.name)}>
                    <div className="col-3" style={locationsStying.fontSize} onClick={toggleStateOrCity}>
                        <span style={hoveredState !== '' ? data.name === hoveredState ? locationsStying.hoveredState : locationsStying.hoveredState1 : locationsStying.noHoveredState}>
                            {`${data.name}`}
                        </span>
                    </div>
                    <div className="col-7 text-justify" onClick={toggleStateOrCity}>
                        <div
                            style={{
                                width: `${data.value / 5}%`,
                                backgroundColor: '#FCC816',
                                height: '40%'
                            }}>
                        </div>
                    </div>
                    <div className="col-2 text-center" style={locationsStying.fontSize} >
                        <span style={hoveredState !== '' ? data.name === hoveredState ? locationsStying.hoveredState : locationsStying.hoveredState1 : locationsStying.noHoveredState}>
                            {`${data.value}`}
                        </span>
                    </div>
                </div>

            </div>
        })

        const renderTodos2 = todaysStatus.map((data, i) => {
            return <div key={i} onMouseLeave={() => this.onMouseLeaveHandle()} onMouseEnter={() => this.onMouseEnterHandle(data.name)}>
                <div className="row" style={locationsStying.containerPadding} onClick={toggleStateOrCity} onClick={() => this.stateSelectHandle(data.name)}>
                    <div className="col-3" style={locationsStying.fontSize} onClick={toggleStateOrCity}>
                        <span style={hoveredState !== '' ? data.name === hoveredState ? locationsStying.hoveredState : locationsStying.hoveredState1 : locationsStying.noHoveredState}>
                            {`${data.name}`}
                        </span>
                    </div>
                    <div className="col-7 text-justify" onClick={toggleStateOrCity}>
                        <div
                            style={{
                                width: `${data.value / 5}%`,
                                backgroundColor: '#FCC816',
                                height: '40%'
                            }}>
                        </div>
                    </div>
                    <div className="col-2 text-center" style={locationsStying.fontSize} >
                        <span style={hoveredState !== '' ? data.name === hoveredState ? locationsStying.hoveredState : locationsStying.hoveredState1 : locationsStying.noHoveredState}>
                            {`${data.value}`}
                        </span>
                    </div>
                </div>

            </div>
        })

        // Logic for displaying page numbers
        const pageNumbers = [];

        if (!dailyReportSelected) {
            for (let i = 1; i <= Math.ceil(WeeksPickupIssues.length / todosPerPage); i++) {
                pageNumbers.push(i);
            }
        } else {
            for (let i = 1; i <= Math.ceil(todaysPickupIssues.length / todosPerPage); i++) {
                pageNumbers.push(i);
            }
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <div
                    className='page-item page-link'
                    key={number}
                    id={number}
                    style={locationsStying.paginationButton}
                    onClick={this.paginationHandleClick}
                >
                    {number}
                </div>
            );
        });

        return (
            <div className='container'>
                <div> {
                    pageLoading
                        ? 'loading...'
                        : !dailyReportSelected
                            ? <div>
                                <div>
                                    {renderTodos}
                                </div>
                                <div className='pagination'
                                    style={locationsStying.paginationLayout}>
                                    {renderPageNumbers}
                                </div>
                            </div>
                            : <div >
                                <div>
                                    {renderTodos2}
                                </div>

                                <div >
                                    <div className='pagination'
                                        style={locationsStying.paginationLayout}>
                                        {renderPageNumbers}
                                    </div>
                                </div>
                            </div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        getCurrentUser: state.getCurrentUser.userData,
        getLocations: state.getLocations,
        badPickUpGoogleSheet: state.badPickUpGoogleSheet,
        badPickupReport: state.badPickupReport,
        locationsApi: state.locationsApi,
        locationSelected: state.locationSelectedGraphBar
    };
};

const mapDispachToProps = (dispach) => {
    return {
        stateSelected: (state) => { dispach({ type: 'STATE_SELECTED', data: state }) },
        citySelected: (state) => { dispach({ type: 'CITY_SELECTED', data: state }) }
    };
}

export default connect(mapStateToProps, mapDispachToProps)(StateStatusBar)
