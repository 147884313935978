/* eslint-disable */
import { badPickUpGoogleSheet } from "../../helpers/api_services/googlesheets/badPickups/badPickUpGoogleSheet";

const initialState = {
    data: {
        'dataTime': '',
        'driver name': '',
        'customerAddress': '',
        'photo': '',
        'driverNotes': '',
        'pickupIssues': {
            'noRecyclingOut': '',
            'cardboardNotFlattened': '',
            'contaminated': '',
            'bagsNotTied': '',
            'glassNotSeparated': '',
            'other': '',
        },
        'recyclopsNotes': '',
    }
}

const getBadPickUpGoogleSheet = async (state = initialState, action) => {
    try {
        const rows = await badPickUpGoogleSheet();
        let dataArray = [...rows.data]

        if (rows) {
            initialState.data = [...dataArray]
        }
        return initialState.data

    } catch (e) {
        return null;
    }
}

export default getBadPickUpGoogleSheet;
