const graphLayoutStyling = {
    toggleButtonColorGreen: {
        background: "#2A9B8D",
        borderColor: "#2A9B8D"
    },
    toggleButtonColorWhite: {
        background: "white",
        borderColor: "#2A9B8D"
    },
    buttonLocation: {
        // paddingLeft: '40%',
        // background: "blue"
        // display: 'flex',
        // justifyContent: 'center',
        // border: '3px solid blue'
    },
    rectangle: {
        display: 'inline-block',
        width: '100px',
        height: '50px',
        background: 'black'
    },
    BadPickupReportTitleDashboard: {
        fontSize: '1vw',
        fontWeight: "bold"
    },
    BadPickupReportTitle: {
        fontSize: '1.3vw',
        fontWeight: "bold"
    },
    angWeeklyDailyTitleFont: {
        fontSize: '.7vw',
        color: "grey",
        fontWeight: "bold"
    },
    avgOutputReport: {
        fontSize: '1.3vw',
        color: "#2A9B8D",
        fontWeight: "bold"
    },
    centerContainer: {
        display: 'flex',
        justifyContent: 'center'
    }
};

export default graphLayoutStyling