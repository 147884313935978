/* eslint-disable */
import React, { Component } from "react";
import buttonStying from './toggleButton'

export class ToggleButton extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { selected, toggleSelected } = this.props;
        return (
            <div className="container-sm" style={buttonStying.buttonBorder}>
                <div className="row" >
                    <div onClick={toggleSelected} className="col text-center" style={selected ? buttonStying.greenButton : buttonStying.whiteButton}>
                        <span style={selected ? buttonStying.whiteFont : buttonStying.greyFont}>
                            {` Day `}
                        </span>
                    </div>
                    <div onClick={toggleSelected} className="col text-center" style={selected ? buttonStying.whiteButton : buttonStying.greenButton}>
                        <span style={selected ? buttonStying.greyFont : buttonStying.whiteFont}>
                            {`Week`}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
