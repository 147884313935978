const locationsStying = {
    StateOrCityTitleFont: {
        fontSize: '1.1vw',
        fontWeight: "bold"
    },
    centerEverything: {
        display: 'flex',
        justifyContent: 'center'
    },
    containerPadding: {
        padding: '.5%'
    },
    fontSize: {
        fontSize: '.9vw',
        color: '#8D8D8D',
        fontWeight: 'normal'
    },
    borderVisual: {
        border: '3px solid green',
        height: '100%',
        width: '100%'
    },
    googleMapsMapSize: {
        paddingBottom: '1%'
    },
    buttonBorder: {
        borderStyle: 'solid',
        borderColor: 'grey ',
        width: '30%',
    },
    buttonText: {
        color: 'grey',
        fontSize: '.6vw'
    },
    spaceBetweenIcon: {
        paddingLeft: '15%'
    },
    hoveredState: {
        color: 'orange'
    },
    hoveredState1: {
        color: 'grey'
    },
    noHoveredState: {
        color: 'grey'
    },
    paginationLayout: {
        display: 'flex',
        justifyContent: 'center',
    },
    paginationButton: {
        color: '#2A9B8D', border: '1px solid #8D8D8D'
    },
    selectedBarChartReport: {
        fontSize: '85%', color: '#8D8D8D'
    }
};

export default locationsStying