import listOfStates from '../../dashboard/display/googleMaps/listOfStates'

export function getCoordinates(location) {
    let locationSelected = location.charAt(0).toLowerCase() + location.slice(1)

    for (let i = 0; i < listOfStates.length; i++) {
        let stateSelected = listOfStates[i].state.charAt(0).toLowerCase() + listOfStates[i].state.slice(1)
        if (locationSelected === stateSelected) {
            return listOfStates[i]
        }
    }
}
