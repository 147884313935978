/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import locationsStying from './locationStyling'
import dummyStateData from './dummyStateData'
import dummyCityData from './dummyCityData';

class CityStatusBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummyCityDataSorted: []
        }
    }

    componentDidMount = () => {
        let sortedData = dummyCityData.sort((a, b) => (a.value < b.lvalue) ? 1 : ((b.value < a.value) ? -1 : 0))
        this.setState({
            dummyCityDataSorted: sortedData
        })
    }

    citySelectHandle = (citySelected) => {
        this.props.citySelected(citySelected)
    }

    render() {
        const { dummyCityDataSorted } = this.state
        return (
            <div className=''>
                <br />
                <div className="">
                    {dummyCityDataSorted.map((data, i) => {
                        return <div key={i} className="row" style={locationsStying.containerPadding} onClick={() => this.citySelectHandle(data.name)}>
                            <div className="col-3" style={locationsStying.fontSize} >
                                {`${data.name}`}
                            </div>
                            <div className="col-7 text-justify" >
                                <div style={{
                                    width: `${data.value}%`,
                                    backgroundColor: '#FCC816',
                                    height: '40%'
                                }}>
                                </div>
                            </div>
                            <div className="col-2 text-center" style={locationsStying.fontSize} >
                                {`${data.value}`}
                            </div>
                        </div>
                    })
                    }
                </div>

                <br />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        getCurrentUser: state.getCurrentUser.userData,
        getLocations: state.getLocations,
        badPickUpGoogleSheet: state.badPickUpGoogleSheet,
        badPickupReport: state.badPickupReport,
        locationsApi: state.locationsApi
    };
};

const mapDispachToProps = (dispach) => {
    return {
        // stateSelected: (state) => { dispach({ type: 'STATE_SELECTED', data: state }) },
        citySelected: (state) => { dispach({ type: 'CITY_SELECTED', data: state }) }
    };
}

export default connect(mapStateToProps, mapDispachToProps)(CityStatusBar);

