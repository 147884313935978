/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import dashboardStyles from "./DashboardStyling";
import CompanyStatus from './display/template/companyStatus';
import DashboardHeader from './dashboardHeader'
import TopStatusBoxes from './display/statusBoxes/topStatusBoxes'
import BottomStatusBoxes from './display/statusBoxes/bottomStatusBoxes';
import GraphLayoutDashBoard from './display/graph/graphLayoutDashBoardDisplay'

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date().toLocaleString().split(',')[0],
      listOfstateArr: [],
      numberOfStates: 0
    };
  }

  async componentDidMount() { // api call for location & badPickUpGoogleSheet
    const fetchApi = await this.props.locationsApi

    this.setState({
      numberOfStates: fetchApi.locations.length
    });
  }

  render() {
    const { numberOfStates, date } = this.state

    return (
      <div style={dashboardStyles.dashboard_padding_Left}>
        <div
          className="content-wrapper"
          style={dashboardStyles.dashboard_background_color}
        >
          <section>
            <div>
              <DashboardHeader date={date} getCurrentUser={this.props.getCurrentUser} />

              <TopStatusBoxes numberOfStates={numberOfStates} />
              <br />

              <div className='conatiner'>
                <div className="row">

                  <section className="col-6">
                    <div className="card container" style={dashboardStyles.graphTable} >
                      <div >
                        <br />
                        <GraphLayoutDashBoard />
                      </div>
                    </div>
                  </section>

                  <section className="col-3" >
                    <div className="card" style={dashboardStyles.statusTable}>
                      <div className="card-header border-0" >
                        <CompanyStatus />
                      </div>
                      <div className="card-body"></div>
                    </div>
                  </section>

                  <section className="col-3">
                    <div className="card" style={dashboardStyles.statusTable}>
                      <div className="card-header border-0" >
                        <CompanyStatus />
                      </div>
                      <div className="card-body"></div>
                    </div>
                  </section>

                </div>
              </div>
            </div>

            <div className='conatiner' style={dashboardStyles.BottomStatusBoxesContainer}>
              <BottomStatusBoxes />
            </div>

          </section>
        </div>
        <br />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    getCurrentUser: state.getCurrentUser.userData,
    getLocations: state.getLocations,
    badPickUpGoogleSheet: state.badPickUpGoogleSheet,
    locationsApi: state.locationsApi
  };
};

const mapDispachToProps = (dispach) => {
  return {
    menuCollapsedBool: () => dispach({ type: "MENU_COLLAPSE" }),
  };
};

export default connect(mapStateToProps, mapDispachToProps)(Dashboard)
