/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import stateBoundaries from './us-state-boundaries'

import { GoogleMap, LoadScript, Polygon } from '@react-google-maps/api';

const containerStyle = {
    width: '650px',
    height: '400px'
};

const options = {
    strokeColor: "#4B0082",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
}

const onLoad = polygon => {
    // console.log('!')
}

class MapsDisplay extends Component {
    render() {
        const { locationSelected, } = this.props

        let stateName = locationSelected.state.name ? locationSelected.state.name : 'Utah'

        const paths = []
        const latAndLng = []

        // determine lat/lng and outline of location
        for (let i = 0; i < stateBoundaries.length; i++) {
            if (stateBoundaries[i].fields.basename === stateName) {

                let coordinates = stateBoundaries[i].fields.st_asgeojson.coordinates
                let coors = stateBoundaries[i].geometry.coordinates

                latAndLng.push({
                    lat: coors[1], lng: coors[0]
                })

                for (let j = 0; j < coordinates.length; j++) {
                    let newArr = coordinates[j]


                    for (let x = 0; x < newArr.length; x++) {
                        let innerArr = newArr[x]

                        // checking to see if there are nested arrays. If so, loop through
                        if (innerArr.filter(Array.isArray).length) {
                            for (let h = 0; h < innerArr.length; h++) {
                                let loco = innerArr[h]
                                paths.push({
                                    lat: loco[1], lng: loco[0]
                                })
                            }
                        } else {
                            paths.push({
                                lat: newArr[x][1], lng: newArr[x][0]
                            })
                        }

                    }
                }

            }

        }

        return (
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>

                <GoogleMap
                    id="marker-example"
                    mapContainerStyle={containerStyle}
                    zoom={5}
                    center={latAndLng[0]}
                >
                    <Polygon
                        onLoad={onLoad}
                        paths={paths}
                        options={options}
                    />
                </GoogleMap>
            </LoadScript >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        locationSelected: state.locationSelectedGraphBar
    };
};

const mapDispachToProps = (dispach) => {
    return {
        stateSelected: (state) => { dispach({ type: 'STATE_SELECTED', data: state }) },
        citySelected: (state) => { dispach({ type: 'CITY_SELECTED', data: state }) }
    };
}

export default (connect(mapStateToProps, mapDispachToProps)(MapsDisplay));
