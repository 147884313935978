/* eslint-disable */
import getBadPickUpGoogleSheet from './badPickUpGoogleSheet'
import listOfStates from '../.././dashboard/display/googleMaps/listOfStates'

const initialState = {
  todaysPickupIssues: {
    noRecyclingOut: '',
    cardboardNotFlattened: '',
    contaminated: '',
    bagsNotTied: '',
    glassNotSeparated: '',
    other: '',
  },
  WeeksPickupIssues: {
    noRecyclingOut: '',
    cardboardNotFlattened: '',
    contaminated: '',
    bagsNotTied: '',
    glassNotSeparated: '',
    other: '',
  },
  datesOfCurrentWeek: [],
}

// Pull raw data for all days within current week
function getDates(startDate, endDate) {
  const dates = []
  let currentDate = startDate

  const addDays = function (days) {
    const date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }

  while (currentDate <= endDate) {
    dates.push(currentDate)
    currentDate = addDays.call(currentDate, 1)
  }

  return dates
}

function endOfWeek(date) {

  var lastday = date.getDate() - (date.getDay() - 1) + 6;
  return new Date(date.setDate(lastday));

}

const badPickupReport = async (state = initialState, action) => {
  let rows = await getBadPickUpGoogleSheet()
  try {
    const newState = { ...state }
    let dataArray = [...rows]

    let months = {
      'Jan': '01',
      'Feb': '02',
      'Mar': '03',
      'Apr': '04',
      'May': '05',
      'Jun': '06',
      'Jul': '07',
      'Aug': '08',
      'Sep': '09',
      'Oct': '10',
      'Nov': '11',
      'Dec': '12'
    }

    let curr = new Date; // get current date
    let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    let last = first + 6; // last day is the first day + 6

    let firstday = new Date(curr.setDate(first)).toString()
    // let lastday = new Date(curr.setDate(last)).toString()
    let dt = new Date();
    let lastday = endOfWeek(dt).toString()

    let firstDaysMonth = ''
    let lastDaysMonth = ''

    // let firstDaysDate = ''
    // let lastDaysDate = ''

    // convert Month (ex: April) into number (ex: 04)
    for (let key in months) {
      if (key === firstday.split(' ')[1]) {
        firstDaysMonth = months[key]
      }
      if (key === lastday.split(' ')[1]) {
        lastDaysMonth = months[key]
      }
    }

    let firstDay = firstday.split(' ')[2]
    let firstYear = firstday.split(' ')[3]

    let lastDay = lastday.split(' ')[2]
    let lastYear = lastday.split(' ')[3]

    // firstDaysDate = `${firstDaysMonth}/${firstDay}/${firstYear}`
    // lastDaysDate = `${lastDaysMonth}/${lastDay}/${lastYear}`

    // let dateFrom = firstDaysDate;
    // let dateTo = lastDaysDate;

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0').replace(/^0+/, '')
    let mm = String(today.getMonth() + 1).padStart(2, '0').replace(/^0+/, '') //January is 0!
    let yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;

    // Pull all days within current week
    let datesOfCurrentWeek = []

    let monthFromStartingDate = firstDaysMonth - 1
    let monthFromEndingDate = lastDaysMonth - 1

    const dates = getDates(new Date(firstYear, monthFromStartingDate, firstDay), new Date(lastYear, monthFromEndingDate, lastDay))

    dates.forEach(function (date) {
      let month = ''
      for (let key in months) {
        if (key === date.toString().split(' ')[1]) {
          month = months[key]
        }
      }
      datesOfCurrentWeek.push(`${month.replace(/^0+/, '')}/${date.toString().split(' ')[2].replace(/^0+/, '')}/${date.toString().split(' ')[3]}`)
    })

    newState.datesOfCurrentWeek = datesOfCurrentWeek

    // checking today's / daily data!
    let noRecyclingOut = 0
    let cardboardNotFlattened = 0
    let contaminated = 0
    let bagsNotTied = 0
    let glassNotSeparated = 0
    let other = 0

    let listOfLocations = []

    for (let i = 0; i < dataArray.length; i++) {
      if (dataArray[i].dataTime.split(' ')[0] === today) {

        let locations = dataArray[i].customerAddress

        listOfLocations.push(locations)

        if (dataArray[i].pickupIssues.noRecyclingOut !== '') {
          noRecyclingOut++
        }

        if (dataArray[i].pickupIssues.cardboardNotFlattened !== '') {
          cardboardNotFlattened++
        }

        if (dataArray[i].pickupIssues.contaminated !== '') {
          contaminated++
        }

        if (dataArray[i].pickupIssues.bagsNotTied !== '') {
          bagsNotTied++
        }

        if (dataArray[i].pickupIssues.glassNotSeparated !== '') {
          glassNotSeparated++
        }

        if (dataArray[i].pickupIssues.other !== '') {
          other++
        }

      }

    }

    // track daily report for state
    let locationReport = []

    // today's reported addresses
    for (let l = 0; l < listOfLocations.length; l++) {
      let loco = listOfLocations[l].split(", ");
      locationReport.push(loco)
    }

    let todaysLocationReportTracker = {}

    for (let c = 0; c < locationReport.length; c++) {
      for (let key in locationReport[c]) {
        let addressStrSpaced = locationReport[c][key].split(' ')
        for (let k = 0; k < addressStrSpaced.length; k++) {
          let addressSpacedOut = addressStrSpaced[k]
          for (let g = 0; g < listOfStates.length; g++) {
            if (listOfStates[g].state === addressSpacedOut || listOfStates[g].abbreviation === addressSpacedOut) {
              // console.log('Able to read address format')
              if (todaysLocationReportTracker[listOfStates[g].state]) {
                todaysLocationReportTracker[listOfStates[g].state] += 1
              } else {
                todaysLocationReportTracker[listOfStates[g].state] = 1
              }
            } else {
              // console.log(`Unable to read address format`, givenAddress)
            }
          }

        }
      }

    }

    // organize data
    let todaysArr = []
    for (let z in todaysLocationReportTracker) {
      todaysArr.push({
        name: z,
        value: todaysLocationReportTracker[z]
      })
    }

    let daysCount = 0
    let todaysReportedTotal = locationReport.length

    for (let key in todaysLocationReportTracker) {
      daysCount += todaysLocationReportTracker[key]
    }

    let todaysInvalidAddressCount = todaysReportedTotal - daysCount

    let todaysPickupReport = {
      todaysReportedTotal: todaysReportedTotal,
      todaysInvalidAddressCount: todaysInvalidAddressCount,
      validAddressCount: daysCount
    }

    let total = noRecyclingOut + cardboardNotFlattened + contaminated + bagsNotTied + glassNotSeparated + other

    newState.todaysPickupIssues = {
      total: total,
      avg: `${Math.ceil((total / 6))}`,
      todaysLocationReportTracker: todaysArr,
      noRecyclingOut: `${Math.floor((noRecyclingOut / total) * 100)}%`,
      cardboardNotFlattened: `${Math.floor((cardboardNotFlattened / total) * 100)}%`,
      contaminated: `${Math.floor((contaminated / total) * 100)}%`,
      bagsNotTied: `${Math.floor((bagsNotTied / total) * 100)}%`,
      glassNotSeparated: `${Math.floor((glassNotSeparated / total) * 100)}%`,
      other: `${Math.floor((other / total) * 100)}%`,
      todaysPickupReport: todaysPickupReport
    }

    // calculate weekly status
    let currentWeek = datesOfCurrentWeek
    let badPickups = [...dataArray]

    // checking current week's data
    let noRecyclingOutWeekly = 0
    let cardboardNotFlattenedWeekly = 0
    let contaminatedWeekly = 0
    let bagsNotTiedWeekly = 0
    let glassNotSeparatedWeekly = 0
    let otherWeekly = 0
    let listOfLocationsWeekly = []

    for (let x = 0; x < badPickups.length; x++) {
      let badPickupStatus = badPickups[x]
      for (let j = 0; j < currentWeek.length; j++) {
        if (currentWeek[j] === badPickupStatus.dataTime.split(' ')[0]) {

          let locations = badPickupStatus.customerAddress

          listOfLocationsWeekly.push(locations)

          if (badPickupStatus.pickupIssues.noRecyclingOut !== '') {
            noRecyclingOutWeekly++
          }
          if (badPickupStatus.pickupIssues.cardboardNotFlattened !== '') {
            cardboardNotFlattenedWeekly++
          }
          if (badPickupStatus.pickupIssues.contaminated !== '') {
            contaminatedWeekly++
          }
          if (badPickupStatus.pickupIssues.bagsNotTied !== '') {
            bagsNotTiedWeekly++
          }
          if (badPickupStatus.pickupIssues.other !== '') {
            otherWeekly++
          }
        }
      }
    }

    // track weekly report for state
    let locationReportWeekly = []

    // week's reported addresses
    for (let l = 0; l < listOfLocationsWeekly.length; l++) {
      let loco = listOfLocationsWeekly[l].split(", ");
      locationReportWeekly.push(loco)
    }
    // console.log('locationReportWeekly', locationReportWeekly)
    let weeksLocationReportTracker = {}

    for (let c = 0; c < locationReportWeekly.length; c++) {
      for (let key in locationReportWeekly[c]) {
        let addressStrSpaced = locationReportWeekly[c][key].split(' ')
        for (let k = 0; k < addressStrSpaced.length; k++) {
          let addressSpacedOut = addressStrSpaced[k]
          for (let g = 0; g < listOfStates.length; g++) {
            if (listOfStates[g].state === addressSpacedOut || listOfStates[g].abbreviation === addressSpacedOut) {
              // console.log('Able to read address format')
              if (weeksLocationReportTracker[listOfStates[g].state]) {
                weeksLocationReportTracker[listOfStates[g].state] += 1
              } else {
                weeksLocationReportTracker[listOfStates[g].state] = 1
              }
            } else {
              // console.log(`Unable to read address format`, givenAddress)
            }
          }

        }
      }

    }

    // organize data
    let weeksArr = []
    for (let z in weeksLocationReportTracker) {
      weeksArr.push({
        name: z,
        value: weeksLocationReportTracker[z]
      })
    }

    let weeksCount = 0
    let weeksReportedTotal = locationReportWeekly.length

    for (let key in weeksLocationReportTracker) {
      weeksCount += weeksLocationReportTracker[key]
    }

    let weeksInvalidAddressCount = weeksReportedTotal - weeksCount

    let weeksPickupReport = {
      weeksReportedTotal: weeksReportedTotal,
      weeksInvalidAddressCount: weeksInvalidAddressCount,
      validAddressCount: weeksCount
    }

    let totalWeekly = noRecyclingOutWeekly + cardboardNotFlattenedWeekly + contaminatedWeekly + bagsNotTiedWeekly + glassNotSeparatedWeekly + otherWeekly

    newState.WeeksPickupIssues = {
      total: totalWeekly,
      avg: `${Math.ceil((totalWeekly / 6))}`,
      weeksLocationReportTracker: weeksArr,
      noRecyclingOut: `${Math.floor((noRecyclingOutWeekly / totalWeekly) * 100)}%`,
      cardboardNotFlattened: `${Math.floor((cardboardNotFlattenedWeekly / totalWeekly) * 100)}%`,
      contaminated: `${Math.floor((contaminatedWeekly / totalWeekly) * 100)}%`,
      bagsNotTied: `${Math.floor((bagsNotTiedWeekly / totalWeekly) * 100)}%`,
      glassNotSeparated: `${Math.floor((glassNotSeparatedWeekly / totalWeekly) * 100)}%`,
      other: `${Math.floor((otherWeekly / totalWeekly) * 100)}%`,
      weeksPickupReport: weeksPickupReport
    }

    // const dummyData = {
    //   WeeksPickupIssues: {
    //     avg: "111",
    //     bagsNotTied: "1%",
    //     cardboardNotFlattened: "2%",
    //     contaminated: "0%",
    //     glassNotSeparated: "0%",
    //     noRecyclingOut: "90%",
    //     other: "4%",
    //     total: 664
    //   },
    //   weeksLocationReportTracker: [
    //     { name: 'Georgia', value: 3 },
    //     { name: 'Texas', value: 55 },
    //     { name: 'Arizona', value: 11 },
    //     { name: 'Tennessee', value: 51 },
    //     { name: 'Utah', value: 148 },
    //     { name: 'Wyoming', value: 9 },
    //     { name: 'Virginia', value: 18 },
    //     { name: 'South Carolina', value: 1 },
    //     { name: 'Kentucky', value: 1 },
    //     { name: 'Mississippi', value: 10 },
    //     { name: 'Kansas', value: 4 },
    //     { name: 'Oklahoma', value: 3 },
    //     { name: 'Oregon', value: 1 },
    //     { name: 'Nebraska', value: 2 },
    //     { name: 'Florida', value: 2 },
    //     { name: 'Louisiana', value: 1 },
    //     { name: 'Connecticut', value: 4 }
    //   ],
    //   weeksPickupReport: {
    //     validAddressCount: 324,
    //     weeksInvalidAddressCount: 485,
    //     weeksReportedTotal: 809,
    //   },
    //   datesOfCurrentWeek: [
    //     "11/7/2021",
    //     "11/8/2021",
    //     "11/9/2021",
    //     "11/10/2021",
    //     "11/11/2021",
    //     "11/12/2021",
    //     "11/13/2021",
    //     "11/14/2021"
    //   ],
    //   todaysPickupIssues: {
    //     avg: "40",
    //     bagsNotTied: "4%",
    //     cardboardNotFlattened: "4%",
    //     contaminated: "0%",
    //     glassNotSeparated: "1%",
    //     noRecyclingOut: "83%",
    //     other: "5%",
    //     todaysLocationReportTracker: [
    //       { name: 'Utah', value: 70 },
    //       { name: 'Nebraska', value: 2 },
    //       { name: 'Florida', value: 2 },
    //       { name: 'Kansas', value: 2 },
    //       { name: 'Texas', value: 19 },
    //       { name: 'Louisiana', value: 1 },
    //       { name: 'Tennessee', value: 21 },
    //       { name: 'Arizona', value: 5 },
    //       { name: 'Wyoming', value: 1 },
    //       { name: 'Connecticut', value: 4 }
    //     ],
    //     todaysPickupReport: {
    //       todaysInvalidAddressCount: 195,
    //       todaysReportedTotal: 322,
    //       validAddressCount: 127
    //     },
    //     total: 237
    //   }
    // }

    // console.log('newState', newState)

    return newState
    // return dummyData

  } catch (e) {
    return null;
  }
};


export default badPickupReport
